import React, { useRef, useState, useEffect, useCallback } from "react";

import { FormSidebar } from "../components/_commons/FormSidebar";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { Button } from "../components/_commons/Button";
import HelpIcon from "../components/_commons/HelpIcon";
import { Topbar } from "../components/_commons/Topbar";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import HolidaySchedule from "../components/TimingGroupEdit/HolidaySchedule";

// actions
import {
	fetchHolidayScheduleDetail,
	parseTimingGroupData,
	getStartEndTimes,
	checkForEmptyDetails,
	fetchHolidayScheduleList
} from "../actions/timingGroups";
import { ActionTypes } from "../actions/_types";

// utils
import history from "../history";
import { store } from "../store/configureStore";
import { removeProp, adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// constant
import { NESTED_ENTITY_TYPES } from "../client-config";
import { SLOT_INITIAL_STATE } from "./TimingGroupCreate";

// graphql
import { EDIT_HOLIDAY_SCHEDULE_DETAIL } from "../graphql/timingGroups";
import { client } from "../client";
import LocationAssociation from "../components/TimingGroupEdit/LocationAssociation";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const FORM_TABS = [
	{
		label: "Schedule",
		value: "schedule"
	},
	{
		label: "Locations",
		value: "location"
	}
];

const HolidayScheduleEdit = ({
	holidayScheduleDetails,
	access,
	match,
	isNested = false,
	closeNestedContainer,
	connectedRef,
	configItems
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isModalBusy, setModalBusy] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isFormTouched, setFormTouched] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [archRestData, setArchRestData] = useState([]);
	const [expandedSections, setExpandedSections] = useState({});
	const { loading, data, timeSlots, error } = holidayScheduleDetails;

	const [isClosedAllDay, setIsClosedAllDay] = useState(true);
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);

	const nestedRef = useRef();
	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			store.dispatch({
				type: ActionTypes.RESET_HOLIDAY_SCHEDULE_DETAIL
			});
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchHolidayScheduleList(true, "", configItems?.brands?.selectedBrand?.id, 10, 0, {
						field: "title",
						order: "ASC"
					});
					history.push("/timing-groups");
				}
			}, 100);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		try {
			const variables = {
				id: parseInt(match.params.id),
				title: sanitisedData?.title,
				crmTitle: sanitisedData?.crmTitle,
				date: sanitisedData?.date?.format("YYYY-MM-DD"),
				isActive: true
			};
			if (!isClosedAllDay && checkForEmptyDetails([sanitisedData?.slots])) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please select both Close from and Close till for a time slot",
						timeout: 3000,
						error: true
					}
				});

				return;
			}
			const daySlots = [];
			if (isClosedAllDay) {
				const daySlot = {
					day: sanitisedData?.date?.format("dddd").toUpperCase(),
					slots: [
						{
							startTime: "00:00",
							endTime: "23:59"
						}
					]
				};
				daySlots.push(daySlot);
			} else {
				const formattedSlots = sanitisedData?.slots?.map((slot) => getStartEndTimes(slot));
				const daySlot = {
					day: sanitisedData?.date?.format("dddd").toUpperCase(),
					slots: formattedSlots
				};
				daySlots.push(daySlot);
			}

			variables.daySlots = daySlots;
			const resp = await client.mutate({
				mutation: EDIT_HOLIDAY_SCHEDULE_DETAIL,
				variables
			});
			if (resp.data.saveHolidayHour.status.success) {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Schedule saved!",
						timeout: 5000,
						error: false
					}
				});
				setFormTouched(false);
				handleFetchHolidayDetail();
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message:
							resp?.data?.saveHolidayHour?.status?.messages?.[0]?.message ||
							error.message ||
							"Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleArchRestData = useCallback(() => {
		const variables = parseTimingGroupData("server", data, timeSlots);
		setArchRestData(variables);
		setArchiveRestore(true);
	}, [data, timeSlots]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "schedules"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				store.dispatch({
					type: ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const handleForm = (field, value) => {
		if (field === "addSlot") {
			store.dispatch({
				type: ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL,
				payload: {
					slots: [...data?.slots, SLOT_INITIAL_STATE]
				}
			});
		} else {
			store.dispatch({
				type: ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		}
	};
	const handleSectionExpansion = (field) => {
		setExpandedSections((current) => {
			const updatedExpandedSections = {
				...current
			};
			if (current[field]) {
				delete updatedExpandedSections[field];
			} else {
				updatedExpandedSections[field] = true;
			}

			return updatedExpandedSections;
		});
	};
	const handleSectionOutsideClick = (field) => {
		setExpandedSections((current) => {
			const updatedExpandedSections = {
				...current
			};
			if (current[field]) {
				delete updatedExpandedSections[field];
			}
			return updatedExpandedSections;
		});
	};

	const handleDateSelection = (type, value) => {
		if (type === "date") {
			store.dispatch({
				type: ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL,
				payload: {
					date: value
				}
			});
			if (expandedSections?.date) {
				handleSectionExpansion("date");
			}
			if (!isFormTouched) {
				setFormTouched(true);
			}
			return;
		}
	};
	const handleClosedDay = () => {
		setIsClosedAllDay(!isClosedAllDay);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};
	const handleTimeSlots = (slotdata, _, updatedSlotIndex) => {
		const updatedSlots = data?.slots.map((slot, slotIndex) => {
			if (slotIndex === updatedSlotIndex) {
				const updatedSlotDetails = {
					...slot,
					...slotdata
				};
				return updatedSlotDetails;
			}
			return slot;
		});
		store.dispatch({
			type: ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL,
			payload: {
				slots: updatedSlots
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};
	const handleDeleteTimeSlot = (deleteSlotIndex) => {
		const filteredSlots = data?.slots?.filter((slot, slotIndex) => slotIndex !== deleteSlotIndex);
		store.dispatch({
			type: ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL,
			payload: {
				slots: filteredSlots
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};
	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);
	const handleFetchHolidayDetail = async () => {
		if (configItems?.brands?.selectedBrand?.id) {
			await fetchHolidayScheduleDetail(parseInt(match.params.id), configItems?.brands?.selectedBrand?.id);
		} else {
			await fetchHolidayScheduleDetail(parseInt(match.params.id));
		}
	};
	useEffect(() => {
		handleFetchHolidayDetail();
	}, [match.params.id]);

	useEffect(() => {
		const hasTimeSlots = data?.timeSlots && data.timeSlots.length > 0;

		if (hasTimeSlots) {
			const firstTimeSlot = data.timeSlots[0];
			const isClosed = firstTimeSlot.starttime === "00:00" && firstTimeSlot.endtime === "23:59";
			setIsClosedAllDay(isClosed);
		}
	}, [data]);

	const handleRefetchLocationAssociation = async (brandId = "") => {
		if (brandId && brandId !== "") {
			await fetchHolidayScheduleDetail(parseInt(match.params.id), brandId);
		} else {
			await fetchHolidayScheduleDetail(parseInt(match.params.id));
		}
		setFormTab(FORM_TABS[1].value);
	};

	return (
		<div className="holiday-schedule-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Timing Group"}
				subTitle="Edit this schedule"
				submitTitle="Save"
				loading={loading}
				disabled={!(data?.title?.length > 0) || !isFormTouched}
				hideActions={formTab === FORM_TABS[1].value || !isFormTouched}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && (
							<Button
								classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
								clickHandler={handleArchRestData}
							>
								{data.isActive ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				{formTab === FORM_TABS[0].value && (
					<div className="holiday-schedule-wrapper">
						<HolidaySchedule
							handleForm={handleForm}
							readOnly={!access.isCatalogueManagement}
							expandedSections={expandedSections}
							holidaySlotData={data}
							handleSectionExpansion={handleSectionExpansion}
							handleSectionOutsideClick={handleSectionOutsideClick}
							handleDateSelection={handleDateSelection}
							handleClosedDay={handleClosedDay}
							isClosedAllDay={isClosedAllDay}
							error={error}
							handleTimeSlots={handleTimeSlots}
							handleDeleteTimeSlot={handleDeleteTimeSlot}
						/>
					</div>
				)}
				{formTab === FORM_TABS[1].value && (
					<LocationAssociation
						entityId={parseInt(match.params.id)}
						entityType={"HOLIDAY_HOUR"}
						associatedLocations={holidayScheduleDetails?.data?.holidaysRelatedLocations?.objects}
						refetchAssociations={handleRefetchLocationAssociation}
					/>
				)}
				<ArchiveRestore
					isOpen={archiveRestore}
					close={handleArchiveRestore}
					entityType={CATALOGUE_ENTITY_TYPES[19]}
					entityName={data?.title}
					object={archRestData}
					mode={data.isActive ? "archive" : "restore"}
				/>
				<NestedEntityContainer
					show={nestedEntity.show}
					type={nestedEntity.type}
					id={nestedEntity.id}
					closeNestedContainer={() => handleNestedEntity(false)}
					nestedRef={nestedRef}
				/>
			</FormSidebar>
		</div>
	);
};

const mapStateToProps = (store) => ({
	holidayScheduleDetails: store?.holidayScheduleDetails,
	access: store.login.loginDetail.access,
	configItems: store.configItems
});
export default connect(mapStateToProps)(HolidayScheduleEdit);
