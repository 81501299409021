import React, { useMemo } from "react";
import { useTable } from "react-table";
import SpecificLocationPlatformCell from "../../../components/Periscope/ItemAvailability/SpecificLocationPlatformCell";
import SpecificLocationBrandItem from "../../../components/Periscope/ItemAvailability/SpecificLocationBrandItem";
import ItemTableLoading from "../../../components/Periscope/ItemAvailability/ItemTableLoading";
import { connect } from "react-redux";
import { getPlatformDetailViaName } from "../../../helpers/periscope";
import { Paginator } from "../../../components/_commons/Paginator";
import { store } from "../../../store/configureStore";
import { lS, scroll } from "../../../atlas-utils";
import { ActionTypes } from "../../../actions/_types";
import { PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";
import Popover from "../../../components/_commons/Popover";
import SelectedItemTooltip from "../../../components/Periscope/ItemAvailability/SelectedItemTooltip";

const SelectedLocationTable = ({
	periscopeSpecificItemAvailabilityLocationData,
	defaultPlatforms,
	periscopeSpecificItemAvailabilityLocationState,
	configItems,
	tableRef,
	refreshBtn,
	isMultibrandEnabled,
	login
}) => {
	const data = periscopeSpecificItemAvailabilityLocationData?.data?.objects?.data;
	const loading = periscopeSpecificItemAvailabilityLocationData?.loading;
	const error = periscopeSpecificItemAvailabilityLocationData?.error;
	const appliedFilters = periscopeSpecificItemAvailabilityLocationState?.appliedFilters;
	const platformNames = appliedFilters?.platform_names;
	let tableData = platformNames?.[0] == "all" ? defaultPlatforms : platformNames;
	const limit = periscopeSpecificItemAvailabilityLocationState?.limit;
	const offset = periscopeSpecificItemAvailabilityLocationState?.offset;
	const dataCount = periscopeSpecificItemAvailabilityLocationData?.data?.objects?.meta?.totalCount;

	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { offset: offset }
		});

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { limit: size.value, offset: 0 }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const columns = useMemo(() => {
		return Object.keys(data?.[0] || {}).map((column) => ({
			Header: column,
			accessor: column,
			Cell: (props) => {
				if (props.column.id == "brandItem") {
					return (
						<>
							<SpecificLocationBrandItem props={props} />
						</>
					);
				} else {
					return (
						<>
							<SpecificLocationPlatformCell props={props} />
						</>
					);
				}
			}
		}));
	}, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});

	const getPlatformData = (table) => {
		return getPlatformDetailViaName(table, bizPlatforms);
	};

	const dispatchSortFunction = (sort = null) => {
		if (sort == null) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE_SORT
			});
		} else {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
				payload: sort
			});
		}
	};

	let displaySortImages = tableData && tableData.length > 1;
	const handleClickSort = (column) => {
		if (displaySortImages) {
			let sort = null;
			let order = "";
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
				payload: { offset: 0 }
			});
			const currentSort = periscopeSpecificItemAvailabilityLocationState?.sort;
			const isSameColumn = currentSort?.field === column;
			const isAscending = currentSort?.order === "asc";
			if (isSameColumn) {
				order = isAscending ? "desc" : null;
			} else {
				order = "asc";
			}
			sort = order ? { sort: { field: column, order } } : null;
			dispatchSortFunction(sort);
		}
	};
	const renderPopover = (obj) => {
		return <div className="info-popover">{obj?.item}</div>;
	};

	return (
		<div className="mainlocation-table">
			{tableData && tableData.length > 0 && bizPlatforms.length > 0 ? (
				<>
					<table {...getTableProps()} className="selected-location-table-display specific-item">
						<thead className="specificlocation-thead">
							<tr>
								<td className="table-header-text brandItem " rowSpan={2}>
									{isMultibrandEnabled ? "Brand-Item" : "Item"}
								</td>
								<td className="table-header-text location-text " colSpan={4}>
									Locations with item
								</td>
							</tr>
							<tr style={{ border: "1px solid #EAEAEA" }}>
								{tableData.map((table) => {
									const platformDetail = getPlatformData(table);
									const platformImage = platformDetail?.image;
									const name = platformDetail?.platformName;
									return (
										<td
											key={table}
											className="tableheader-selectedlocation"
											style={{ width: `${100 / tableData.length}%`, cursor: "pointer" }}
											onClick={() => handleClickSort(table)}
										>
											<div className="platform-display-selectedLocation">
												<img src={platformImage} className="plt-image" />
												<span className="table-header-text">{name}</span>
												{displaySortImages ? (
													<>
														<PlatfromSortedImages
															tableName={table}
															periscopeSpecificItemAvailabilityLocationState={
																periscopeSpecificItemAvailabilityLocationState
															}
														/>
													</>
												) : (
													<></>
												)}
											</div>
										</td>
									);
								})}
							</tr>
						</thead>

						{loading || error ? (
							<>
								<ItemTableLoading tableData={tableData} error={error} refreshBtn={refreshBtn} />
							</>
						) : (
							<>
								<tbody {...getTableBodyProps()} style={{ borderBottom: "1px solid #EAEAEA" }}>
									{rows.map((row, i) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()} className="specific-location-tbody">
												{row.cells.map((cell, index) => {
													const total = cell?.value?.total;
													const available = cell?.value?.available;
													let classname = "";
													if (cell.value && cell.value.total >= 0) {
														if (total == available && total !== 0 && available !== 0) {
															classname = "specificitem-online";
														} else if (total == 0) {
															classname = "specificitem-partially-offline";
														} else {
															classname = "specificitem-offline";
														}
													}
													return (
														<td
															key={index}
															{...cell.getCellProps()}
															className={` ${
																cell.column.Header === "brandItem"
																	? "selecteditem-branditem-display"
																	: `${classname} selecteditem-platform-display `
															}  ${index !== 0 && "tooltip-item"}`}
														>
															{index == 0 ? (
																<>
																	{cell.column.Header === "brandItem" &&
																	(cell.value.item || "").length >= 25 ? (
																		<Popover
																			data={cell.value}
																			renderPopover={renderPopover}
																			position={"down-left"}
																			showOnHover={true}
																			showOnClick
																			classes="fit-content"
																		>
																			{cell.render("Cell")}
																		</Popover>
																	) : (
																		cell.render("Cell")
																	)}
																</>
															) : (
																<>
																	<SelectedItemTooltip
																		cell={cell}
																		view={"location"}
																	/>
																	{cell.render("Cell")}
																</>
															)}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan="100%" className="paginator-location">
											<Paginator
												limit={limit}
												offset={offset}
												count={dataCount || 0}
												showPageSize={true}
												goToPage={handlePagination}
												setPageSize={handlePageSize}
											/>
										</td>
									</tr>
								</tfoot>
							</>
						)}
					</table>
				</>
			) : (
				<></>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	periscopeSpecificItemAvailabilityLocationData: state.periscopeSpecificItemAvailabilityLocationData,
	periscopeSpecificItemAvailabilityLocationState: state.periscopeSpecificItemAvailabilityLocationState,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled,
	login: state.login
});
export default connect(mapStateToProps)(SelectedLocationTable);

const PlatfromSortedImages = ({ tableName, periscopeSpecificItemAvailabilityLocationState }) => {
	let sortedPlatformName = "";
	let orderStatus = "";

	const locationState = periscopeSpecificItemAvailabilityLocationState?.sort;

	if (locationState) {
		sortedPlatformName = locationState?.field;
		orderStatus = locationState?.order;
	}

	const topImage = <img src="/assets/periscope/top-face.svg" />;
	const bottomFacedImage = <img src="/assets/periscope/down-face.svg" />;
	const topBlueImage = <img src="/assets/periscope/topBlue.svg" />;
	const bottomFacedBlueImage = <img src="/assets/periscope/bottomFacedBlue.svg" />;

	return (
		<>
			<span style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
				{sortedPlatformName === tableName ? (
					orderStatus === "asc" ? (
						<>
							{topBlueImage}
							{bottomFacedImage}
						</>
					) : orderStatus === "desc" ? (
						<>
							{topImage}

							{bottomFacedBlueImage}
						</>
					) : (
						<>
							{topImage}
							{bottomFacedImage}
						</>
					)
				) : (
					<>
						{topImage}
						{bottomFacedImage}
					</>
				)}
			</span>
		</>
	);
};
