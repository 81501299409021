import React from "react";

// components
import AnalyticsGraph from "../Dashboard/AnalyticsGraph";

export const ReferralsGraph = ({ data = {}, loading = false, error = false, appliedFilter = {} }) => {
	let modifiedData = {
		current: [...data.claimed],
		previous: [...data.unclaimed]
	};
	return (
		<div className="trends">
			{!error && modifiedData.current && modifiedData.current.length ? (
				<AnalyticsGraph
					duration={appliedFilter.dateFilter}
					anaGraphData={{ data: { ...modifiedData } }}
					yAxisLabel="VALUE"
					margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
					previousLegend="Unclaimed"
					currentLegend="Claimed"
				/>
			) : loading ? (
				<div className="no-items-placeholder">Fetching data..</div>
			) : (
				<div className="no-items-placeholder">No data available!</div>
			)}
		</div>
	);
};
