import React from "react";
import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";

const PosMenuErrorDrawer = ({ handleDrawerClose, isOpen, report = {} }) => {
	const handleClose = () => {
		handleDrawerClose && handleDrawerClose();
	};
	const columns = [
		{
			title: "NAME",
			dataIndex: "name",
			render: (name) => {
				return <p className="name">{name}</p>;
			}
		},
		{
			title: "ISSUE",
			dataIndex: "issue",
			render: (issue) => (
				<div className="fix-error-issue-list-data">
					<img
						src={
							issue?.type === "ERROR"
								? "/assets/icons/error-red-icon.svg"
								: "/assets/icons/warning-icon.svg"
						}
					/>
					<p>{issue?.message}</p>
				</div>
			)
		}
	];
	let styleInfo = {
		backgroundColor: "#FFF5F5",
		borderColor: "#D64949",
		icon: "/assets/icons/error-red-icon.svg"
	};
	if (!report?.structuredIssues.some((structuredIssue) => structuredIssue?.issue?.type === "ERROR")) {
		styleInfo.backgroundColor = "#FEF6E9";
		styleInfo.borderColor = "#E5B917";
		styleInfo.icon = "/assets/icons/warning-icon.svg";
	}
	return (
		<FormSidebar
			close={handleClose}
			isOpen={isOpen}
			title={`Menu Pull Issues`}
			hideSubmitAction
			cancelTitle="Close"
			cancel={handleClose}
			subTitle="Make your menu compliant with platform rules to avoid publish failures"
		>
			<div
				className="fix-error-msg-wrapper"
				style={{
					borderLeft: `6px solid ${styleInfo.borderColor}`,
					backgroundColor: styleInfo.backgroundColor
				}}
			>
				<img src={styleInfo?.icon} />
				<p>
					{`${report?.structuredIssues?.filter((issue) => issue?.issue?.type === "ERROR").length} Errors, ${
						report?.structuredIssues?.filter((issue) => issue.issue.type === "WARNING").length
					} Warnings to Fix`}
				</p>
			</div>
			<FormTable columns={columns} dataSource={report?.structuredIssues} />
		</FormSidebar>
	);
};

export default PosMenuErrorDrawer;
