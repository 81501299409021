import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import ModifierGroupEntityAssociations from "../EntityAssociations/ModifierGroupEntityAssociations";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_MODIFIER_MODIFIER_GROUPS, GET_MODIFIER_MODIFIER_GROUPS_MENU_SERVICE } from "../../graphql/modifiers";

// actions
import { ActionTypes } from "../../actions/_types";

// constant
import { NESTED_ENTITY_TYPES, MODIFIER_GROUP_ENTITY_ASSOCIATION_TYPES } from "../../client-config";
import { clientMenu } from "../../client-menu";

const ModifierGroups = ({
	modifierId,
	modifierName,
	handleNestedEntity,
	setModalBusy,
	readOnly = true,
	isViewedFromMenuSection,
	menuId
}) => {
	const [loading, setLoading] = useState(false);
	const [modifierGroups, setModifierGroups] = useState([]);
	const [nestedModifierGroups, setNestedModifierGroups] = useState([]);
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const fetchModifierGroups = useCallback(
		async (filters = {}) => {
			try {
				setLoading(true);
				const variables = {
					id: parseInt(modifierId),
					limit: 100,
					offset: 0
				};
				if (isViewedFromMenuSection) {
					const resp = await clientMenu.query({
						query: GET_MODIFIER_MODIFIER_GROUPS_MENU_SERVICE,
						variables: {
							menuId,
							optionId: String(modifierId),
							limit: 100,
							offset: 0
						},
						fetchPolicy: "no-cache"
					});
					setModifierGroups(resp.data.option.parentOptionGroups.objects);
				} else {
					const resp = await client.query({
						query: GET_MODIFIER_MODIFIER_GROUPS,
						variables,
						fetchPolicy: "no-cache"
					});
					setModifierGroups(resp.data.modifier.modifierGroups.objects);
					setNestedModifierGroups(resp.data.modifier.nestedOptionGroups.map((og) => og.id));
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[modifierId]
	);

	useEffect(() => {
		fetchModifierGroups();
	}, [fetchModifierGroups]);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchModifierGroups();
		}
	};

	const generateCurrentOptionGroupMap = () => {
		const ogMap = {};

		modifierGroups.forEach((optionGroup) => {
			ogMap[optionGroup.id] = true;
		});

		return {
			...ogMap
		};
	};

	if (loading) {
		return (
			<div className="P(10px 0)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"associated-modifier-groups-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">{modifierGroups.length} Parent Modifier Groups</div>
					<div className="header-subtext">Manage modifier groups associated with this modifier</div>
				</div>
				{!readOnly && (
					<div>
						<Button clickHandler={openAssociationSidebar}>Update</Button>
					</div>
				)}
			</div>
			{modifierGroups.length !== 0 && (
				<div className="modifier-list">
					{modifierGroups.map((mg, i) => (
						<ModifierGroup
							key={i}
							{...mg}
							handleNestedEntity={handleNestedEntity}
							isViewedFromMenuSection={isViewedFromMenuSection}
						/>
					))}
				</div>
			)}
			<ModifierGroupEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityId={modifierId}
				entityType={MODIFIER_GROUP_ENTITY_ASSOCIATION_TYPES[2]}
				nestedModifierGroups={nestedModifierGroups}
				modifier={modifierName}
				enabledModifierGroups={generateCurrentOptionGroupMap()}
				isFromMenuSection={isViewedFromMenuSection}
			/>
		</div>
	);
};
export default ModifierGroups;

const ModifierGroup = ({
	id,
	optionGroupTitle,
	name,
	description,
	maxSelectable,
	optionGroupDesc,
	optionGroupMaxSelectable,
	isVariant,
	handleNestedEntity,
	isViewedFromMenuSection
}) => {
	return (
		<div className="header-container">
			<div>
				<div className="header-text">{isViewedFromMenuSection ? name : optionGroupTitle}</div>
				<div className="header-subtext">
					{isViewedFromMenuSection
						? description && description.length > 85
							? description.slice(0, 85) + "..."
							: description || "--"
						: optionGroupDesc && optionGroupDesc.length > 85
						? optionGroupDesc.slice(0, 85) + "..."
						: optionGroupDesc || "--"}
				</div>
				<div className="header-subtext">
					{!isVariant
						? `Up to ${
								isViewedFromMenuSection
									? maxSelectable === -1
										? "any number"
										: maxSelectable
									: optionGroupMaxSelectable === -1
									? `any number`
									: optionGroupMaxSelectable
						  }`
						: "Variant"}
				</div>
			</div>
			<div>
				<a role="button" onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[5], id)}>
					<Button>View</Button>
				</a>
			</div>
		</div>
	);
};
