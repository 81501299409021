import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";
import moment from "moment";
import { animated as a, useSpring } from "react-spring";

// components
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// actions
import { fetchTimingGroupsDebounced } from "../../actions/actions";

// utils
import history from "../../history";

// clients
import { client } from "../../client";

// graphql
import { GET_HOLIDAY_SCHEDULE_LIST_FOR_LOCATION_TAB } from "../../graphql/timingGroups";
import { capitaliseText, getHolidayHoursPlatforms } from "../../atlas-utils";

const LOADING_INIT_STATE = {
	locationPlatformTimingGroupsStatusFetching: false,
	brandLocationPlatformTimingGroupsStatusFetching: false,
	updatingPlatformIds: {},
	updatingBrandPlatformIds: {}
};
function findNextHoliday(holidays = []) {
	const today = new Date();

	// Find the first upcoming holiday using reduce
	const nextHoliday = holidays.reduce((acc, holiday) => {
		const holidayDate = new Date(holiday.date);
		// Check if the holiday date is after today's date
		if (holidayDate > today && (!acc || holidayDate < new Date(acc.date))) {
			return holiday;
		}
		return acc;
	}, null);

	return nextHoliday;
}
const Timings = ({
	timingGroups,
	data,
	handleForm,
	orderingSlotChanged = false,
	holidayHoursChanged = false,
	brandHolidayHoursChanged = false,
	brandOrderingSlotChanged = false,
	resetOrderingSlots,
	handleSubmit,
	isMultibrandEnabled = false,
	readOnly = false,
	handleBrandsLabelOption,
	handleBrandLocation,
	platforms,
	handleCreateNewClick
}) => {
	const holidayHourEnabledPlatforms = getHolidayHoursPlatforms(platforms);

	const [holidayHoursList, setHolidayHoursList] = useState([]);
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const updateOrderingSlots = async (brand = false) => {
		const resp = await handleSubmit(undefined, brand);
	};

	const updateHolidayHours = async (brand = false) => {
		handleSubmit(undefined, brand, true);
	};
	const fetchHolidayHoursList = async () => {
		try {
			const variables = {};
			let filtersObject = [];
			filtersObject.push({
				field: "is_active",
				value: "true"
			});
			variables.filters = filtersObject;
			// if (isMultibrandEnabled && data?.selectedBrandLocation?.brandId) {
			// 	variables.brand = data?.selectedBrandLocation?.brandId?.toString();
			// }
			const resp = await client.query({
				query: GET_HOLIDAY_SCHEDULE_LIST_FOR_LOCATION_TAB,
				variables,
				fetchPolicy: "no-cache"
			});
			setHolidayHoursList(resp?.data?.holidayHours?.objects || []);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchHolidayHoursList();
		return () => {
			resetOrderingSlots();
		};
	}, []);
	const associatedHolidayList = isMultibrandEnabled
		? data?.selectedBrandLocation?.holidayHours?.objects || data?.selectedBrandLocation?.holidayHours
		: data?.holidayHours?.objects || data?.holidayHours;

	const upcomingHoliday = findNextHoliday(associatedHolidayList || []) || {};

	return (
		<div className="timings-container">
			<div className="brand-filter-upcoming-holiday-wrapper">
				<div>
					{isMultibrandEnabled && data?.brandLocations?.length > 0 && (
						<SelectFilterCustom
							options={data.brandLocations || []}
							field="selectedBrandLocation"
							currValue={data.selectedBrandLocation}
							setFilter={(f, value) => handleBrandLocation(value)}
							labelKey={"brandName"}
							valueKey={"brandId"}
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							isClearable={false}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							classes="brand-filter-custom"
						/>
					)}
				</div>

				{holidayHourEnabledPlatforms && holidayHourEnabledPlatforms?.length > 0 && upcomingHoliday?.title && (
					<div className="upcoming-holiday">
						<img src="/assets/icons/calendar.svg" />
						<p>
							Upcoming: {upcomingHoliday?.title} {moment(upcomingHoliday?.date).format("DD MMM YYYY")}
						</p>
					</div>
				)}
			</div>

			<div className="regular-schedule-wrapper">
				<div>
					<p className="schedule-header">Regular Schedule</p>
					<p className="schedule-header--description">Your store's operational timing details</p>
				</div>
				<Button
					type="secondary"
					clickHandler={() => {
						handleCreateNewClick("regular");
					}}
				>
					+ Create New
				</Button>
			</div>

			<div className="form-row row-half upcoming-dropdown">
				<UpdatableDropdown
					brand={isMultibrandEnabled}
					timingGroups={timingGroups}
					data={data}
					fetchTimingGroupsDebounced={fetchTimingGroupsDebounced}
					contentProps={contentProps}
					resetOrderingSlots={resetOrderingSlots}
					handleSubmit={updateOrderingSlots}
					readOnly={readOnly}
					handleForm={
						isMultibrandEnabled
							? (field, value) => handleForm(field, value, data?.selectedBrandLocation?.brandId)
							: handleForm
					}
					orderingSlotChanged={isMultibrandEnabled ? brandOrderingSlotChanged : orderingSlotChanged}
				/>
			</div>
			{holidayHourEnabledPlatforms && holidayHourEnabledPlatforms?.length > 0 && (
				<>
					<div className="regular-schedule-wrapper">
						<div>
							<p className="schedule-header">Holiday Schedule</p>
							<p className="schedule-header--description">
								These schedules override regular schedules for special events{" "}
							</p>
						</div>
						<Button
							type="secondary"
							clickHandler={() => {
								handleCreateNewClick("holiday");
							}}
						>
							+ Create New
						</Button>
					</div>

					<div className="holiday-hour-banner">
						<img src="/assets/icons/info.png" alt="info" />
						<p>
							Supported for{" "}
							{holidayHourEnabledPlatforms.length === 1
								? capitaliseText(holidayHourEnabledPlatforms[0]?.platform?.platformName)
								: holidayHourEnabledPlatforms
										.map((plt) => capitaliseText(plt?.platform?.platformName))
										.join(", ")}
						</p>
					</div>
					<div className="holiday-schedule-selector">
						<SelectFilter
							title="Schedule"
							options={holidayHoursList}
							readOnly={readOnly}
							field="holidayHours"
							currValue={associatedHolidayList}
							setFilter={
								isMultibrandEnabled
									? (field, value) => handleForm(field, value, data?.selectedBrandLocation?.brandId)
									: handleForm
							}
							labelKey="title"
							valueKey="id"
							isClearable={true}
							multi={true}
							placeholder="Select Holiday Schedules"
						/>
						{(isMultibrandEnabled ? brandHolidayHoursChanged : holidayHoursChanged) && (
							<a.div className="action-buttons-container" style={contentProps}>
								<Button type="secondary" clickHandler={() => resetOrderingSlots(true)}>
									Cancel
								</Button>
								<Button clickHandler={() => updateHolidayHours(isMultibrandEnabled)}>Update</Button>
							</a.div>
						)}
					</div>
				</>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(Timings);

const UpdatableDropdown = ({
	timingGroups,
	data,
	handleForm,
	fetchTimingGroupsDebounced,
	contentProps,
	orderingSlotChanged,
	handleSubmit,
	resetOrderingSlots,
	brand = false,
	readOnly
}) => (
	<div className="dropdown-container">
		<SelectFilter
			title={"Schedule"}
			requiredLabel
			options={brand ? [{ id: null, title: "Default" }, ...timingGroups.items] : timingGroups.items}
			isLoading={timingGroups.isLoading}
			field="orderingSlots"
			currValue={
				brand
					? data?.selectedBrandLocation?.orderingSlots || { id: null, title: "Default" }
					: data.orderingSlots
			}
			setFilter={handleForm}
			labelKey="title"
			valueKey="id"
			isClearable={!brand}
			isAsync={true}
			handleSearch={fetchTimingGroupsDebounced}
			readOnly={readOnly}
		/>
		{orderingSlotChanged && (
			<a.div className="action-buttons-container" style={contentProps}>
				<Button type="secondary" clickHandler={() => resetOrderingSlots(true)}>
					Cancel
				</Button>
				<Button clickHandler={() => handleSubmit(brand)}>Update</Button>
			</a.div>
		)}
	</div>
);
