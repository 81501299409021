import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";

// third party
import { connect } from "react-redux";

const BasicInfo = ({
	data = {},
	handleForm,
	validations,
	listGroups,
	locations,
	readOnly = false,
	createMode = false
}) => {
	return (
		<React.Fragment>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.fullName}
					onChange={(e) => handleForm("fullName", e.target.value)}
					validationMessage={validations.fullName || ""}
					requiredLabel={true}
					readOnly={readOnly}
				>
					Name
				</InputWithLabel>
			</div>
			{!createMode && (
				<div className="form-row row-half">
					<InputWithLabel
						value={data.username}
						onChange={(e) => handleForm("username", e.target.value)}
						validationMessage={validations.username || ""}
						requiredLabel={true}
						readOnly={true}
						showCopyToClipboard={true}
						showIcon={true}
						classes="at-input--copy"
					>
						Username
					</InputWithLabel>
					<InputWithLabel
						value={data.apiKey}
						onChange={(e) => handleForm("apiKey", e.target.value)}
						validationMessage={validations.apiKey || ""}
						readOnly={true}
						showCopyToClipboard={true}
						showIcon={true}
						classes="at-input--copy"
					>
						API key
					</InputWithLabel>
				</div>
			)}
			<div className="form-row row-half">
				<SelectFilter
					title="Role"
					options={listGroups.items}
					readOnly={readOnly}
					isLoading={listGroups.isLoading}
					field="groups"
					currValue={data.groups}
					setFilter={handleForm}
					requiredLabel={true}
					multi={true}
					labelKey="name"
					valueKey="id"
					validationMessage={validations.roles || ""}
				/>
				<SelectFilter
					title="Locations"
					options={locations.items}
					readOnly={readOnly}
					isLoading={locations.isLoading}
					field="locations"
					currValue={data.locations}
					setFilter={handleForm}
					requiredLabel={false}
					multi={true}
					labelKey="name"
					valueKey="id"
					validationMessage={validations.locations || ""}
				/>
			</div>
		</React.Fragment>
	);
};
export default connect((store) => ({
	listGroups: store.configItems.listGroups,
	locations: store.configItems.stores
}))(BasicInfo);
