import React from "react";
import { Route } from "react-router-dom";
import GetStarted from "./GetStarted.js";
import EarlyAccess from "./EarlyAccess.js";
import ShowLocations from "./ShowLocations.js";
import Checkout from "./Checkout.js";

const UserEarlyAccess = ({ waitlist, periscopeFeatureFlag, showWaitlistCTA = false }) => {
	return (
		<>
			<Route
				exact
				path="/periscope/early-access"
				render={() => (
					<EarlyAccess
						access={waitlist}
						periscopeFeatureFlag={periscopeFeatureFlag}
						showWaitlistCTA={showWaitlistCTA}
					/>
				)}
			/>

			{!waitlist && (
				<>
					<Route path="/periscope/early-access/get-started" component={GetStarted} />
					<Route exact path="/periscope/early-access/get-started/select-location" component={ShowLocations} />
					<Route exact path="/periscope/early-access/checkout" component={Checkout} />
				</>
			)}
		</>
	);
};

export default UserEarlyAccess;
