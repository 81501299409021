import { ActionTypes } from "../actions/_types";

const BRANDS_LIST_INIT_STATE = {
	loading: false,
	data: {},
	error: false,
	searchQuery: ""
};

export const brandsListState = (state = BRANDS_LIST_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_BRANDS_LIST_REQUEST:
			return {
				...state,
				loading: true
			};

		case ActionTypes.GET_BRANDS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...action.payload }
			};

		case ActionTypes.GET_BRANDS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};
		case ActionTypes.UPDATE_BRANDS_SEARCH_TEXT:
			return {
				...state,
				searchQuery: action.payload
			};
		default:
			return state;
	}
};

const BRANDS_CREATE_ASSOCIATED_LOCATIONS_INIT_STATE = {
	currentAssociatedLocationsCount: 0,
	totalAssociatedLocationsCount: 0,
	status: ""
};

export const brandsCreationLocationAssociationState = (
	state = BRANDS_CREATE_ASSOCIATED_LOCATIONS_INIT_STATE,
	action
) => {
	switch (action.type) {
		case ActionTypes.UPDATE_ASSOCIATED_BRAND_LOCATIONS_STATE:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
