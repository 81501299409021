import gql from "graphql-tag";

export const GET_ITEM_LOCATIONS = gql`
	query getItemLocations($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		item(id: $id) {
			id
			associatedItemLocations(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						value
						valueForDisplay
					}
				}
				objects {
					location {
						id
						name
						city
						tags
					}
					price
					externalPrice
				}
			}
		}
	}
`;

export const GET_MODIFIER_LOCATIONS = gql`
	query fetchModifier($id: Int, $name: String, $limit: Int, $offset: Int, $city: String) {
		modifier(id: $id) {
			associatedLocations(name: $name, limit: $limit, offset: $offset, city: $city) {
				location {
					id
					name
					city
					tags
				}
				price
			}
			associatedCities
			numAssociatedLocations
		}
	}
`;

export const CASCADE_ITEM_PRICE = gql`
	mutation editItem(
		$id: Int
		$itemPrice: Float
		$locationsToUpdate: [Int]
		$cascadeAll: Boolean
		$filters: [ListFilterArgument]
	) {
		saveItem(
			input: {
				id: $id
				itemPrice: $itemPrice
				locationsToUpdate: $locationsToUpdate
				cascadeAll: $cascadeAll
				filters: $filters
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const CASCADE_MODIFIER_PRICE = gql`
	mutation editModifier(
		$id: Int
		$optionPrice: Float
		$locationsToUpdate: [Int]
		$cascadeAll: Boolean
		$filters: [ListFilterArgument]
	) {
		saveModifier(
			input: {
				id: $id
				optionPrice: $optionPrice
				locationsToUpdate: $locationsToUpdate
				cascadeAll: $cascadeAll
				filters: $filters
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
