import React, { useState, useImperativeHandle } from "react";

// components
import { OutsideClick } from "./OutsideClick";

// third party
import { debounce } from "lodash";

const Popover = ({
	showOnHover = false,
	showOnClick = false,
	renderPopover,
	children,
	data = null,
	nodeRef,
	showCustomTooltip = false,
	open = false,
	onOpen,
	showClose = false,
	onClose,
	position = "down-right",
	tooltipInfo = "",
	tooltipPosition = "down-right",
	classes = "",
	...rest
}) => {
	const [isOpen, setIsOpen] = useState(open);

	const handlePopoverOnHover = debounce((state) => {
		setIsOpen(state);
		if (state) {
			if (typeof onOpen === "function") {
				onOpen();
			}
		} else {
			if (typeof onClose === "function") {
				onClose();
			}
		}
	}, 200);

	const handlePopoverOnClick = (e, state) => {
		setTimeout(() => {
			setIsOpen(state);
			if (state) {
				if (typeof onOpen === "function") {
					onOpen();
				}
			} else {
				if (typeof onClose === "function") {
					onClose();
				}
			}
		}, 200);
	};

	const handleOutsideClick = () => {
		if (!showClose && showOnClick) {
			setIsOpen(false);
			if (typeof onClose === "function") {
				onClose();
			}
		}
	};

	const handlePopoverClose = () => {
		setIsOpen(false);
		if (typeof onClose === "function") {
			onClose();
		}
	};

	useImperativeHandle(
		nodeRef,
		() => ({
			handleOutsideClick
		}),
		[handleOutsideClick]
	);

	return (
		<div className={`popover-wrapper-container ${classes} ` + position}>
			<div
				className="popover-wrapper--content"
				onClick={showOnClick ? (e) => handlePopoverOnClick(e, !isOpen) : () => {}}
				onMouseEnter={showOnHover ? () => handlePopoverOnHover(true) : () => {}}
				onMouseLeave={showOnHover ? () => handlePopoverOnHover(false) : () => {}}
			>
				{showCustomTooltip && !isOpen ? (
					<div aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
						{children}
					</div>
				) : (
					children
				)}
			</div>
			{isOpen && (
				<div
					ref={nodeRef}
					className="popover-container"
					onClick={(e) => e.stopPropagation()}
					onMouseEnter={showOnHover ? () => handlePopoverOnHover(true) : () => {}}
					onMouseLeave={showOnHover ? () => handlePopoverOnHover(false) : () => {}}
				>
					{showClose && (
						<div className="close-popover" onClick={handlePopoverClose}>
							<img src="/assets/icons/cancel.png" alt="" />
						</div>
					)}
					{renderPopover(data, rest)}
				</div>
			)}
		</div>
	);
};
export default OutsideClick(Popover);
