import React, { useState, useCallback } from "react";

// third party
import Select from "react-select-animated-v2";

export const MultiSelectFilter = ({
	title,
	options,
	field,
	currValue,
	isLoading = false,
	isAsync = false,
	labelKey,
	valueKey,
	asyncLookup,
	updateLookup,
	handleSearch,
	setFilter,
	showCustomTooltip = false,
	tooltipInfo,
	tooltipPosition = "up-left"
}) => {
	const [focused, setFocus] = useState(false);
	const value = currValue.value ? currValue.value : "";
	const filterApplied = value ? true : false;
	const selectedOptions = value ? value.split(",") : [];

	const handleSelect = useCallback(
		(opt) => {
			const values = value ? value.split(",") : [];
			if (opt && opt[valueKey] && values.indexOf(String(opt[valueKey])) === -1) {
				setFilter(field, {
					field,
					value: values.concat(opt[valueKey]).join(",")
				});
				if (isAsync) {
					updateLookup(opt[valueKey], opt[labelKey]);
				}
			}
		},
		[value, valueKey, labelKey, field, isAsync, setFilter]
	);

	const handleRemove = useCallback(
		(opt) => {
			setFilter(field, {
				field,
				value: value
					.split(",")
					.filter((val) => val != opt)
					.join(",")
			});
		},
		[field, value, setFilter]
	);

	const handleSearchInput = useCallback(
		(inputValue) => {
			if (isAsync) {
				handleSearch(inputValue);
			}
		},
		[isAsync, handleSearch]
	);

	return (
		<div
			className={
				"multi-select-filter-container filter-section-item " +
				(filterApplied ? "filter-applied" : "") +
				(focused ? " active" : "")
			}
		>
			<div className="meta-info">
				<span>{title}</span>
				{showCustomTooltip && tooltipInfo && (
					<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
						<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
					</span>
				)}
			</div>
			<div className="selected-items">
				{selectedOptions.map((opt, i) => {
					if (opt === "") {
						return null;
					}
					const title = isAsync
						? asyncLookup?.[opt]
						: options.find((o) => opt == o[valueKey])
						? options.find((o) => opt == o[valueKey])?.[labelKey]
						: undefined;
					return (
						<div title={title ? title : opt} className="selected-item" key={i}>
							<div className="item-remove" onClick={() => handleRemove(opt)}>
								x
							</div>
							<div className="item-title">{title ? title : opt}</div>
						</div>
					);
				})}
			</div>
			<Select
				value={value}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				isSearchable={true}
				className="at--dropdown"
				classNamePrefix="react-select"
				isClearable={false}
				options={options ? options.filter((opt) => !selectedOptions.includes(String(opt[valueKey]))) : []}
				onChange={handleSelect}
				onInputChange={handleSearchInput}
				isLoading={isLoading}
				labelKey={labelKey}
				valueKey={valueKey}
				getOptionLabel={(option) => option[labelKey]}
				getOptionValue={(option) => option[valueKey]}
				closeMenuOnSelect={false}
				placeholder="Search to find more"
				noOptionsMessage={() => "No results found"}
			/>
		</div>
	);
};
