// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_EIS_CONFIG, EDIT_EIS_CONFIG } from "../graphql/customIntegrations";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

const parseErrorMessages = (messages = []) => {
	const error = {
		message: "",
		fields: {}
	};
	if (messages.length === 0) {
		error.message = "Something went wrong, please try again.";
	} else {
		messages.forEach((msg) => {
			error.fields[msg.field] = msg.message;
		});
	}
	return error;
};

const parseCustomIntegrationsData = (data, to = "form") => {
	let result = data;
	switch (to) {
		case "form":
			if (result) {
				if (result["keys"] && result["keys"].length > 0) {
					result["keys"] = result.keys.map((key) => {
						if (key.type === "dict") {
							key.value = JSON.parse(key.value) ? JSON.parse(key.value) : {};
						}
						return key;
					});
				}

				if (result["keysV2"] && result["keysV2"].length > 0) {
					result["keysV2"] = result.keysV2.map((group) => {
						if (group.groupKeys && group.groupKeys.length > 0) {
							group.groupKeys = group.groupKeys.map((key) => {
								if (key.type === "dict") {
									key.value = JSON.parse(key.value) ? JSON.parse(key.value) : {};
								}
								return key;
							});
						}
						return group;
					});
				}
			}
			break;
		case "server":
			result = [];
			data.keysV2.map((group) => {
				if (group.groupKeys && group.groupKeys.length > 0) {
					group.groupKeys = group.groupKeys.map((key) => {
						if (key.type === "boolean") {
							result.push({
								key: key.key,
								value: key.value === null ? "false" : key.value
							});
						} else if (key.type === "dict") {
							result.push({
								key: key.key,
								value: JSON.stringify(key.value)
							});
						} else if (key.type === "float") {
							result.push({
								key: key.key,
								value: String(key.value)
							});
						} else {
							result.push({
								key: key.key,
								value: key.value === null ? "" : key.value
							});
						}
						return key;
					});
					return group;
				}
			});

			break;
		default:
			break;
	}
	return result;
};

export const fetchCustomIntegrations = async (location, brandId = null) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CUSTOM_INTEGRATIONS_REQUEST
	});

	try {
		const variables = {
			brandId: brandId === "default" ? null : brandId
		};
		if (location?.id !== "default") {
			variables.locationId = location?.id || null;
		}

		const resp = await client.query({
			query: GET_EIS_CONFIG,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_CUSTOM_INTEGRATIONS_SUCCESS,
			payload: parseCustomIntegrationsData(resp.data.eisConfig, "form")
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CUSTOM_INTEGRATIONS_FAILURE,
			error: {
				message: "There was an error while fetching your integrations"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const editCustomIntegrations = async (data, location, brandId = null) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.EDIT_CUSTOM_INTEGRATIONS_REQUEST
	});
	try {
		const variables = {
			configurationsV2: parseCustomIntegrationsData(data, "server")
		};
		if (location.id !== "default") {
			variables.locationId = location.id;
		}
		variables.brandId = brandId === "default" ? null : brandId;
		const resp = await client.mutate({
			mutation: EDIT_EIS_CONFIG,
			variables: {
				input: variables
			}
		});
		if (resp.data.saveEisConfig.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_CUSTOM_INTEGRATIONS_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "POS configuration details saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch(toggleGlobalLoader(false));
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_CUSTOM_INTEGRATIONS_FAILURE,
				error: parseErrorMessages(resp.data.saveEisConfig.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_CUSTOM_INTEGRATIONS_FAILURE,
			error: {
				message: "There was an error while saving POS configuration details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
