import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { ItemGroupsList } from "./ItemGroupsList";
import ItemGroupCreate from "./ItemGroupCreate";
import ItemGroupEdit from "./ItemGroupEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/ItemGroupsList/Header";

const ItemGroupsContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="entity-groups item-groups">
			{restrictView ? (
				<Route
					path="/item-groups"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/item-groups" component={ItemGroupsList} />
					<Route
						exact
						path="/item-groups/new"
						render={() =>
							hasAccess ? <ItemGroupCreate hasAccess={hasAccess} /> : <Redirect to="/item-groups" />
						}
					/>
					<Route exact path="/item-groups/edit/:id" component={ItemGroupEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default ItemGroupsContainer;
