import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { LocationTable } from "./LocationTable";

//components
import { InputField } from "../_commons/InputField";

// constants
import { PRESET_TYPES } from "../_commons/DateFilter";

// third party
import _ from "lodash";
import moment from "moment";

import { fetchOperationalEfficiencyListView } from "../../actions/operationalEfficiency";
import { InputWithLabel } from "../_commons/InputWithLabel";

const LocationView = ({
	operationalEfficiencyState,
	operationalEfficiencyMetricsBizLocationLevel,
	configItems,
	access,
	sortHandler,
	searchHandler
}) => {
	const { appliedDateFilter } = operationalEfficiencyState;
	const [searchKW, setSearchKW] = useState("");
	const performSearch = useCallback(
		_.debounce((searchInput) => searchHandler(searchInput), 1000),
		[]
	);
	const handleSearchOnEnter = () => {
		performSearch(searchKW);
	};
	const handleSearchKW = (searchInput) => {
		setSearchKW(searchInput);
		performSearch(searchInput);
	};
	const dismissSearch = () => {
		setSearchKW("");
		performSearch("");
	};
	const { data, loading, error } = operationalEfficiencyMetricsBizLocationLevel;
	return (
		<div>
			<Header
				appliedDateFilter={appliedDateFilter}
				searchKW={searchKW}
				handleSearchKW={handleSearchKW}
				dismissSearch={dismissSearch}
				handleSearchOnEnter={handleSearchOnEnter}
			/>
			<LocationTable
				loading={loading}
				data={data.rows || []}
				uniqueId={"bizLocationId"}
				dimensions={configItems.dimensions}
				sortHandler={sortHandler}
				hasAccess={!access.isNonHqAnalytics}
			/>
		</div>
	);
};

export default connect((store, props) => ({
	operationalEfficiencyState: store.operationalEfficiencyState,
	operationalEfficiencyMetricsBizLocationLevel: store.operationalEfficiencyMetricsBizLocationLevel,
	configItems: store.configItems,
	sortHandler: props.sortHandler,
	access: store.login.loginDetail.access
}))(LocationView);

export const Header = (props) => {
	const { appliedDateFilter } = props;
	let isCustomDateRange = false;
	let isComparing = false;
	const currentDates = appliedDateFilter.current.dateFilter.split(",");
	const compareDates = appliedDateFilter.compare.dateFilter.split(",");
	let currentStart = "";
	let currentEnd = "";
	let compareStart = "";
	let compareEnd = "";
	let presetDateFilterLabel = "";
	if (currentDates.length === 2) {
		isCustomDateRange = true;
		currentStart = moment(currentDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		currentEnd = moment(currentDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	} else {
		presetDateFilterLabel = PRESET_TYPES.find((pt) => pt.value === appliedDateFilter.current.dateFilter).label;
	}
	if (compareDates.length === 2) {
		isComparing = true;
		compareStart = moment(compareDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		compareEnd = moment(compareDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	}
	return (
		<div className="operational-efficiency-header credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Locations</div>
				<div className="subtitle">
					<span>
						Order status update analysis, location wise
						{isCustomDateRange ? " from " : " for "}
					</span>
					{isCustomDateRange ? (
						<React.Fragment>
							<span className="highlight">{currentStart}</span>
							<span> to </span>
							<span className="highlight">{currentEnd}</span>
						</React.Fragment>
					) : (
						<span className="highlight">{presetDateFilterLabel}</span>
					)}
					{isComparing && (
						<React.Fragment>
							<span> (compared with </span>
							<span className="highlight">{compareStart}</span>
							<span> to </span>
							<span className="highlight">{compareEnd}</span>
							<span>)</span>
						</React.Fragment>
					)}
				</div>
			</div>
			<div className="header-action-button">
				<div className="filter-buttons">
					<div className="search-filter">
						{/* <InputField
							classes="search-input"
							type="text"
							placeholder="Search"
							image={false}
							showLabel = {false}
							imageSrc="/assets/header-icons/icon-search.svg"
							value={props.searchKW}
							onChange={(e) => props.handleSearchKW(e.target.value)}
							onEnter={() => props.handleSearchOnEnter()}
						/> */}
						<InputWithLabel
							placeholder="Search"
							classes="at-input--label"
							showLabel={true}
							image={true}
							imageSrc="/assets/header-icons/icon-search.svg"
							value={props.searchKW}
							onChange={(e) => props.handleSearchKW(e.target.value)}
							onEnter={() => props.handleSearchOnEnter()}
						/>
						{props.searchKW ? (
							<div onClick={props.dismissSearch} className="dismiss-search">
								<img className="" src="/assets/icons/cancel.png" />
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};
