import React from "react";
import moment from "moment";
import { Button } from "../_commons/Button";
import DatePicker from "../_commons/DatePicker";
import DropdownCustom from "../_commons/DropdownCustom";
import { InputWithLabel } from "../_commons/InputWithLabel";
import TimeSlotFilter from "./TimeSlotFilter";
import { CheckBox } from "../_commons/CheckBox";
import { formatDate } from "../../atlas-utils";

const HolidaySchedule = ({
	handleForm,
	readOnly,
	expandedSections,
	holidaySlotData,
	handleSectionExpansion,
	handleSectionOutsideClick,
	handleDateSelection,
	handleClosedDay,
	isClosedAllDay,
	error,
	handleTimeSlots,
	handleDeleteTimeSlot
}) => {
	return (
		<div className="form-content">
			<div className="form-row row-half">
				<InputWithLabel
					value={holidaySlotData?.title}
					onChange={(e) => handleForm("title", e.target.value)}
					validationMessage={(error.fields || {})?.title || ""}
					readOnly={readOnly}
				>
					Name
				</InputWithLabel>
				<InputWithLabel
					value={holidaySlotData?.crmTitle}
					onChange={(e) => handleForm("crmTitle", e.target.value)}
					readOnly={readOnly}
					tooltipInfo="This is an internal reference name and will not reflect on the ordering platform."
					showCustomTooltip
				>
					Internal Name
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<DropdownCustom
					title="Date"
					selected={formatDate(holidaySlotData?.date, "DD MMM YYYY")}
					isOpen={expandedSections?.date}
					handleClick={() => handleSectionExpansion("date")}
					handleOutsideClick={() => handleSectionOutsideClick("date")}
					clickEvent="mousedown"
					showArrow
				>
					<DatePicker
						inline
						onChange={(date) => handleDateSelection("date", date)}
						selected={holidaySlotData?.date}
						minDate={moment().add(1, "days")}
					/>
				</DropdownCustom>
			</div>

			<CheckBox checked={isClosedAllDay} clickHandler={handleClosedDay}>
				<span className="closed-all-day">Closed all day</span>
			</CheckBox>

			{!isClosedAllDay && (
				<div className="holiday-timeslots">
					{holidaySlotData?.slots &&
						holidaySlotData.slots.map((slot, slotIndex) => (
							<div className="timing-slot-wrapper" key={slotIndex}>
								<TimeSlotFilter
									slot={slot}
									label={"Close from"}
									hoursField="startHours"
									minutesField="startMinutes"
									timeslotIndex={0}
									slotIndex={slotIndex}
									handleTimeSlots={handleTimeSlots}
								/>
								<TimeSlotFilter
									slot={slot}
									label={"Close till"}
									hoursField="endHours"
									minutesField="endMinutes"
									timeslotIndex={0}
									slotIndex={slotIndex}
									handleTimeSlots={handleTimeSlots}
								/>
								{holidaySlotData.slots?.length > 1 && !readOnly && (
									<img
										alt="del"
										className="cancel-timeslot"
										onClick={() => handleDeleteTimeSlot(slotIndex)}
										src="/assets/icons/cancel-light.svg"
									/>
								)}
							</div>
						))}
					<div className="add-slot">
						<Button type="secondary" clickHandler={() => handleForm("addSlot", null)}>
							+ Add Timings
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default HolidaySchedule;
