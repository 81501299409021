import gql from "graphql-tag";

const SEGMENT_GROUPS = gql`
	query getSegmentGroups {
		segmentGroups {
			objects
		}
	}
`;

const SUGGESTED_SEGMENTS = gql`
	query suggestedSegmentList($suggested: Boolean, $filters: [ListFilterArgument], $limit: Int) {
		segments(suggested: $suggested, filters: $filters, sort: { field: "created", order: DESC }, limit: $limit) {
			objects {
				id
				created
				title
				created
				filters
				description
				group
				isActive
				isImplicit
			}
		}
	}
`;

const FILTERED_USERS_PREVIEW = gql`
	query getFilteredUsersPreview($medium: CommunicationMedium, $segmentId: Int, $filters: String, $campaignId: Int) {
		filteredUsersPreview(medium: $medium, segmentId: $segmentId, filters: $filters, campaignId: $campaignId) {
			total
			targeted
			percentageTargeted
			creditsRequired
		}
	}
`;

const FETCH_SEGMENT_DETAILS = gql`
	query fetchSegmentDetails($id: Int!) {
		segment(id: $id) {
			id
			title
			description
			isImplicit
			isActive
			filters
			usersPreview {
				total
				targeted
				percentageTargeted
			}
		}
	}
`;

const SAVE_SEGMENT = gql`
	mutation saveSegment(
		$id: ID
		$title: String!
		$description: String
		$isImplicit: Boolean!
		$isActive: Boolean!
		$filters: String!
		$medium: CommunicationMedium
		$campaignId: Int
	) {
		saveSegment(
			input: {
				id: $id
				title: $title
				description: $description
				isImplicit: $isImplicit
				isActive: $isActive
				filters: $filters
			}
		) {
			object {
				id
				title
				description
				isImplicit
				isActive
				filters
				usersPreview(medium: $medium, campaignId: $campaignId) {
					total
					targeted
					percentageTargeted
					creditsRequired
				}
			}
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

const DEACTIVE_SEGMENT = gql`
	mutation saveSegment($id: ID, $filters: String!, $title: String!, $isImplicit: Boolean!, $isActive: Boolean!) {
		saveSegment(
			input: { id: $id, title: $title, filters: $filters, isImplicit: $isImplicit, isActive: $isActive }
		) {
			object {
				id
			}
			errors {
				field
				messages
			}
		}
	}
`;

export {
	SAVE_SEGMENT,
	SUGGESTED_SEGMENTS,
	FILTERED_USERS_PREVIEW,
	FETCH_SEGMENT_DETAILS,
	DEACTIVE_SEGMENT,
	SEGMENT_GROUPS
};
