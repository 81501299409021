import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";
import { useTrail, animated } from "react-spring";

// components
import { Modal } from "../_commons/Modal";
import { SelectFilter } from "../_commons/SelectFilter";
import TimelineTrail from "../_commons/TimelineTrail";

// utils
import { printCurrency } from "../SiteComp";
import { capitaliseTextStrict, commifyNumbers, formatDate, trackEvent } from "../../atlas-utils";
import { ActionTypes } from "../../actions/_types";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_PAYOUT_ORDER, UPDATE_RECONCILIATION_STATUS } from "../../graphql/transactions";

// constants
import {
	ORDER_STATUS_MAP,
	RECONCILIATION_STATUS_BACKGROUND_COLOR_MAP,
	RECONCILIATION_STATUS_TEXT_COLOR_DEFAULT,
	RECONCILIATION_STATUS_TEXT_COLOR_MAP
} from "../../client-config";

const RESOLVE_ACTION_STATES = [
	{
		value: "settled_by_aggregator",
		valueForDisplay: "Received payment from Aggregator"
	},
	{
		value: "misidentified_by_system",
		valueForDisplay: "System misidentified the order as inconsistent/missing"
	},
	{
		value: "aggregator_unresponsive",
		valueForDisplay: "Aggregator is not responding"
	},
	{
		value: "other",
		valueForDisplay: "Others"
	}
];

const FINAL_STATES = {
	reconciled: true,
	closed: true,
	resolved: true
};

const RECONCILIATION_STATES_MAP = {
	resolved: "Manually Reconciled",
	closed: "Manually Reconciled"
};

const AGGREGATOR_DEDUCTIONS_MAP = {
	"Convenience Fee": "Payment Mechanism Fees"
};

const Reconciliation = ({
	biz,
	orderId,
	isModalOpen,
	isNested,
	updateReconciliationActionVisibility,
	updateModalState,
	channel = "",
	handleCancel,
	setReconStatusActionTriggered,
	fetchPayoutSheetDetails
}) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [actionNotes, setActionNotes] = useState("");
	const [emptyData, setEmptyData] = useState(false);
	const [updatingReconStatus, setUpdatingReconStatus] = useState(false);
	const [successData, setSuccessData] = useState("");
	const [selectedReason, setSelectedReason] = useState(undefined);

	const isZomatov2 = data.platformManager === "zomatov2";

	const cancellationRefund = data?.orderAdjustmentSubfields?.filter((data) => data.key === "Cancellation Refund");

	const handleNotesUpdate = (e) => {
		setActionNotes(e.target.value);
	};

	const config = { mass: 5, tension: 2000, friction: 500 };
	const reconciliationStatusItems = data.statusUpdates
		? [
				...data?.statusUpdates?.map((status, i) => (
					<TimelineTrail
						key={i}
						id={status.id}
						headNodeBackgroundColorDark={
							RECONCILIATION_STATUS_TEXT_COLOR_MAP[status.reconciliationState] ??
							RECONCILIATION_STATUS_TEXT_COLOR_DEFAULT
						}
						headNodeBackgroundColorLight={
							RECONCILIATION_STATUS_BACKGROUND_COLOR_MAP[status.reconciliationState] ??
							RECONCILIATION_STATUS_BACKGROUND_COLOR_MAP
						}
						title={RECONCILIATION_STATES_MAP[status.reconciliationState] || status.reconciliationState}
						isFinal={FINAL_STATES[status.reconciliationState]}
						status={status}
						isLatest={i === data.statusUpdates.length - 1}
					/>
				))
		  ]
		: [];
	const trailReconciliationStatus = useTrail(reconciliationStatusItems.length, {
		config,
		from: { opacity: 0, height: 0 },
		to: { opacity: 1, height: "auto" }
	});

	const handleClose = () => {
		if (!updatingReconStatus) {
			updateModalState(false);
			if (successData === "success") {
				handleCancel();
				if (isNested) {
					setReconStatusActionTriggered((prev) => prev + 1);
				}
			}
			setSuccessData("");
			setActionNotes("");
		}
	};

	const handleReconciliationStatusUpdateRequest = async (status) => {
		setUpdatingReconStatus(true);
		try {
			const variables = {
				id: parseInt(orderId),
				reconciliationStatus: status,
				reason: selectedReason.value || "",
				notes: actionNotes
			};

			const resp = await client.mutate({
				mutation: UPDATE_RECONCILIATION_STATUS,
				variables,
				fetchPolicy: "no-cache"
			});

			if (resp.data.updateReconciliationStatus.status.success) {
				setSuccessData("success");
				fetchPayoutSheetDetails();
				const eventName = "manual_reconciliation_action";
				const eventMeta = {
					resolutionNotes: actionNotes,
					selectedReason: selectedReason?.valueForDisplay
				};
				trackEvent(eventName, eventMeta);
			} else {
				setSuccessData("failure");
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Failed to update order status.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			setSuccessData("failure");
		}
		setUpdatingReconStatus(false);
	};

	const fetchPayoutOrderDetails = async () => {
		setLoading(true);
		try {
			const variables = {
				id: parseInt(orderId)
			};
			const resp = await client.query({
				query: GET_PAYOUT_ORDER,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.payoutOrder !== null) {
				setData(resp.data.payoutOrder);
				if (
					resp.data.payoutOrder.reconciliationStatus === "missing" ||
					resp.data.payoutOrder.reconciliationStatus === "inconsistent"
				) {
					updateReconciliationActionVisibility(true);
				}
			} else {
				setEmptyData(true);
			}
			trackEvent("reconciliation_order_specific_payout_details", { orderId: orderId });
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Failed to fetch payout orders.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			setEmptyData(true);
		}
		setLoading(false);
	};

	const handleReasonSelection = (key, value) => {
		if (value === null) {
			setSelectedReason(undefined);
			return;
		}
		setSelectedReason({
			...value
		});
		trackEvent("manual_reconciliation_reason_selection", {
			selectedReason: value?.valueForDisplay
		});
	};

	const calculateTotalAmountFromArray = (contentArray) =>
		contentArray.reduce((accumulator, current) => {
			if (current?.key === "Cancellation Refund") {
				return accumulator;
			} else {
				return accumulator + current.value;
			}
		}, 0);

	useEffect(() => {
		fetchPayoutOrderDetails();
	}, []);

	return (
		<React.Fragment>
			{loading ? (
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			) : emptyData ? (
				<div className="form-row order-payout-info">
					<div className="empty-data">Reconciliation data not available.</div>
				</div>
			) : (
				<div className="form-row order-payout-info">
					<div className="payout-breakdown">
						<div className="border-block">PAYOUT BREAKDOWN</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">Order Total</div>
								<div className="header-value">
									{(data?.orderTotal === 0 || data?.orderTotal) && printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.orderTotal, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>Subtotal</div>
								<div>
									{(data?.orderSubtotal === 0 || data?.orderSubtotal) &&
										printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.orderSubtotal, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>Charges</div>
								<div>
									{(data?.totalCharges === 0 || data?.totalCharges) &&
										printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.totalCharges, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>Discount</div>
								<div>
									{(data?.discount === 0 || data?.discount) && (
										<span>
											{data?.discount > 0 && "-"} {printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers(data?.discount, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>Taxes</div>
								<div>
									{(data?.totalTaxes === 0 || data?.totalTaxes) && printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.totalTaxes, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
						</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">
									{channel ? capitaliseTextStrict(channel) : "Aggregator"} Deductions
								</div>
								<div className="header-value">
									{(data?.aggregatorCommissionTotal === 0 || data?.aggregatorCommissionTotal) && (
										<span>
											{(data?.aggregatorCommissionTotal || data?.aggregatorCommissionTotal > 0) &&
												"-"}{" "}
											{printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers(data?.aggregatorCommissionTotal, null, {
										minimumFractionDigits: 2
									}) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>
									Commission{" "}
									{data?.aggregatorCommissionPercentage
										? `@ ${commifyNumbers(data.aggregatorCommissionPercentage)} %`
										: ""}
								</div>
								<div>
									{(data?.aggregatorCommissionValue === 0 || data?.aggregatorCommissionValue) && (
										<span>
											{(data?.aggregatorCommissionValue || data?.aggregatorCommissionValue > 0) &&
												"-"}{" "}
											{printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers(data?.aggregatorCommissionValue, null, {
										minimumFractionDigits: 2
									}) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>Taxes on Commission</div>
								<div>
									{(data?.aggregatorCommissionTax === 0 || data?.aggregatorCommissionTax) && (
										<span>
											{(data?.aggregatorCommissionTax || data?.aggregatorCommissionTax > 0) &&
												"-"}{" "}
											{printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers(data?.aggregatorCommissionTax, null, {
										minimumFractionDigits: 2
									}) || "--"}
								</div>
							</div>
							{data?.aggregatorDeductionSubfields?.map((subfield, i) => (
								<div className="breakdown-types-values" key={i}>
									<div>{AGGREGATOR_DEDUCTIONS_MAP[subfield.key] || subfield.key}</div>
									<div>
										{(subfield.value === 0 || subfield.value) && (
											<span>
												{(subfield.value || subfield.value > 0) && "-"}{" "}
												{printCurrency(biz.currencySymbol)}
											</span>
										)}
										&nbsp;
										{commifyNumbers(subfield.value, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							))}
						</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">Order Level Adjustments</div>
								<div className="header-value">
									{(isZomatov2 ||
										data?.orderLevelAdjustments === 0 ||
										data?.orderLevelAdjustments) && (
										<span>
											{isZomatov2
												? calculateTotalAmountFromArray(data.orderAdjustmentSubfields) > 0 &&
												  "-"
												: data?.orderLevelAdjustments > 0 && "-"}{" "}
											{printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers(
										isZomatov2
											? calculateTotalAmountFromArray(data.orderAdjustmentSubfields)
											: data?.orderLevelAdjustments,
										null,
										{ minimumFractionDigits: 2 }
									) || "--"}
								</div>
							</div>
							{data?.orderAdjustmentSubfields
								?.filter((subfield) => subfield.key !== "Cancellation Refund")
								?.map((subfield, i) => (
									<div className="breakdown-types-values" key={i}>
										<div>{subfield.key}</div>
										<div>
											{(subfield.value === 0 || subfield.value) && (
												<span>
													{(subfield.value || subfield.value > 0) && "-"}{" "}
													{printCurrency(biz.currencySymbol)}
												</span>
											)}
											&nbsp;
											{commifyNumbers(subfield.value, null, { minimumFractionDigits: 2 }) || "--"}
										</div>
									</div>
								))}
						</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">Tax Liabilities</div>
								<div className="header-value">
									{(data?.tcs === 0 || data?.tds === 0 || data?.tcs || data?.tds) && (
										<span>
											{data?.tcs + data?.tds > 0 && "-"} {printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers((data?.tcs || 0) + (data?.tds || 0), null, {
										minimumFractionDigits: 2
									}) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>TCS</div>
								<div>
									{(data?.tcs === 0 || data?.tcs) && (
										<span>
											{data?.tcs > 0 && "-"} {printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers(data?.tcs, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>TDS</div>
								<div>
									{(data?.tds === 0 || data?.tds) && (
										<span>
											{data?.tds > 0 && "-"} {printCurrency(biz.currencySymbol)}
										</span>
									)}
									&nbsp;
									{commifyNumbers(data?.tds, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
						</div>
						{isZomatov2 && (
							<div className="breakdown-types">
								<div className="breakdown-types-header-with-value">
									<div className="header-text">Cancellation Refund</div>
									<div className="header-value">
										{(cancellationRefund?.[0]?.value === 0 || cancellationRefund?.[0]?.value) && (
											<span>{printCurrency(biz.currencySymbol)}</span>
										)}
										&nbsp;
										{commifyNumbers(cancellationRefund?.[0]?.value, null, {
											minimumFractionDigits: 2
										}) || "--"}
									</div>
								</div>
							</div>
						)}
						<div className="border-block border-block--end">
							<div>
								<strong>Final Payment</strong>
							</div>
							<div>
								<strong>
									{(data?.netPayable === 0 || data?.netPayable) && printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.netPayable, null, { minimumFractionDigits: 2 }) || "--"}
								</strong>
							</div>
						</div>
					</div>
					<div className="payout-breakdown">
						<div className="border-block">HISTORY</div>
						<div className="reconciliation-status-updates">
							{data.statusUpdates &&
								trailReconciliationStatus.map((styleProps, i) => (
									<animated.div
										key={i}
										style={{
											...styleProps
										}}
									>
										{reconciliationStatusItems[i]}
									</animated.div>
								))}
						</div>
					</div>
				</div>
			)}
			<Modal
				isOpen={isModalOpen}
				close={handleClose}
				showSubmitAction={!updatingReconStatus}
				submitTitle={successData !== "" ? "Close" : "Reconcile"}
				submitAction={
					successData !== "" ? handleClose : () => handleReconciliationStatusUpdateRequest("resolved")
				}
				cancelAction={handleClose}
				cancelTitle={"Cancel"}
				showCancelAction={true}
				disabled={!selectedReason}
			>
				{updatingReconStatus ? (
					<div className="modal-content">
						<div className="wobbling-bar-loader">
							<div></div>
							<div></div>
							<div></div>
						</div>
						<div className="update-message">Updating reconciliation status.</div>
					</div>
				) : successData === "success" ? (
					<div className="modal-content">
						<div className="success-message">Reconciliation status updated successfully.</div>
					</div>
				) : successData === "failure" ? (
					<div className="modal-content">
						<div className="failure-message">Failed to update reconciliation status.</div>
					</div>
				) : (
					<div className="modal-content">
						<SelectFilter
							title={"Reason"}
							requiredLabel={true}
							currValue={selectedReason}
							options={RESOLVE_ACTION_STATES}
							labelKey="valueForDisplay"
							valueKey="value"
							setFilter={handleReasonSelection}
						/>
						<div className="label">Notes: </div>
						<textarea className="text-area" value={actionNotes} onChange={handleNotesUpdate}></textarea>
					</div>
				)}
			</Modal>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	tags: store.configItems.tags,
	bizPlatforms: store.configItems.bizPlatforms,
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(Reconciliation);

const TimelineItem = ({
	hasNext,
	currentColor,
	nextColor,
	id,
	title,
	isFinal,
	status,
	clickable = false,
	openStatusUpdate
}) => {
	const circleBackground = {
		backgroundColor: currentColor
	};
	const barBackground = {
		background: `linear-gradient(to bottom, ${currentColor} 0%, ${nextColor} 100%)`
	};
	if (!hasNext && !isFinal) {
		barBackground.background = `linear-gradient(to bottom, ${currentColor} 0%, white 100%)`;
	}
	const statusTitleStyle = {
		backgroundColor: currentColor,
		border: `1px solid ${currentColor}`
	};
	if (clickable) {
		statusTitleStyle.cursor = "pointer";
	}
	let updatedBy = "";
	if (status.updatedBy) {
		updatedBy = status.updatedBy.firstName || status.updatedBy.username;
	}

	return (
		<div className="status-item-container">
			<div className="timeline-container">
				<div style={circleBackground} className="timeline-circle" />
				{!isFinal && <div style={barBackground} className="timeline-bar" />}
			</div>
			<div className="status-info">
				<div
					style={statusTitleStyle}
					className="status-title"
					onClick={clickable ? () => openStatusUpdate(id) : () => {}}
				>
					{title}
				</div>
				<div className="status-update-time">
					{updatedBy ? (
						<span>
							<span>Updated by </span>
							<span className="highlight">{updatedBy}</span>
							<span> at {formatDate(status.created, "DD MMM, YYYY hh:mm:ss A")}</span>
						</span>
					) : (
						<span>At {formatDate(status.created, "DD MMM, YYYY hh:mm:ss A")}</span>
					)}
				</div>
				{status.message && status.message.trim() && (
					<div className="status-message">Message: {status.message}</div>
				)}
				{status.comments && status.comments.trim() && (
					<div className="status-message">Comments: {status.comments}</div>
				)}
			</div>
		</div>
	);
};
