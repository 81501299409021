import React, { useEffect, useState } from "react";

// components
import Image from "./Image";
import { ButtonIcon } from "./ButtonIcon";

const StatusInfo = ({ status, icon, color, isClosable = false, header, description }) => {
	const [isOpen, setIsOpen] = useState(true);

	useEffect(() => setIsOpen(true), [status]);

	return (
		<>
			{isOpen ? (
				<div className={`status-info-wrapper ${status}`}>
					<div className="details-wrapper">
						{icon && <ButtonIcon classes="icon" icon={icon} color={color} />}
						<div className="details">
							<p className="detail-header">{header}</p>
							<p className="detail-description">{description}</p>
						</div>
					</div>
					{isClosable && (
						<Image
							className="close-icon"
							src="/assets/icons/cancel.png"
							alt="remove rule attribute"
							onClick={() => setIsOpen(false)}
						/>
					)}
				</div>
			) : null}
		</>
	);
};
export default StatusInfo;
