import React from "react";

// component
import { Button } from "../_commons/Button";
import Image from "../_commons/Image";
import { InputWithLabel } from "../_commons/InputWithLabel";
import MonogramGenerator from "../_commons/MonogramGenerator";

const DetailsEntry = ({
	brandName,
	setBrandName,
	files,
	setFiles,
	inputRef,
	previewCanvasRef,
	onFileChange,
	imgSrc,
	setImgSrc,
	imgRenderRef,
	hideTitle = false
}) => {
	const getFile = () => {
		inputRef.current.click();
	};

	const handleImageRemoval = () => {
		setImgSrc("");
	};

	return (
		<div className="brand-creation-flow--details-entry brand-creation-flow--container">
			{hideTitle ? null : <div className="title">Fill in details</div>}
			<div className="brand-details-container">
				<div className="brand-logo-selector-container">
					<div className="header">Logo</div>
					<div className="logo-image-container" onClick={getFile}>
						{imgRenderRef.current ? (
							<Image src={imgRenderRef.current} className="img-preview" alt={brandName} />
						) : imgSrc ? (
							<canvas
								ref={previewCanvasRef}
								style={{
									objectFit: "contain",
									width: "240px",
									height: "240px",
									borderRadius: "2px"
								}}
							/>
						) : !brandName ? (
							<img
								className="empty-img-placeholder"
								src="/assets/icons/icon-empty-img.svg"
								alt="empty logo"
							/>
						) : (
							<MonogramGenerator value={brandName} size="xlarge" />
						)}
					</div>
					<input
						className="D(n)"
						ref={inputRef}
						type="file"
						onChange={onFileChange}
						accept="image/*"
						onClick={() => {
							inputRef.current.value = null;
						}}
					/>
					{!imgSrc ? (
						<Button clickHandler={getFile}>Upload Image</Button>
					) : (
						<>
							<Button type="secondary" clickHandler={getFile}>
								Replace Image
							</Button>
							<div className="remove-logo-text" onClick={handleImageRemoval}>
								Remove
							</div>
						</>
					)}
				</div>
				<div className="brand-name-entry">
					<InputWithLabel
						onChange={(e) => setBrandName(e.target.value)}
						value={brandName}
						placeholder="Enter brand name"
					>
						Name
					</InputWithLabel>
				</div>
			</div>
		</div>
	);
};
export default DetailsEntry;
