import React, { useState, useEffect, useCallback } from "react";

// component
import { FormContainer } from "../../containers/Meraki";

// third party
import { connect } from "react-redux";
import ReactJson from "react-json-view";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchMerakiWebConfig, editMerakiWebConfig } from "../../actions/merakiWeb";

const RawConfig = ({ biz, merakiWebWorkspace: { data: WorkspaceData } = {} }) => {
	const [loading, setLoading] = useState(false);
	const [config, setConfig] = useState({});
	const [configCustomized, setConfigCustomized] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);

	const fetchData = useCallback(async () => {
		setLoading(true);
		try {
			const data = await fetchMerakiWebConfig(WorkspaceData.theme.name);
			setConfig(data);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "There was an error while fetching the config file",
					timeout: 2000,
					error: true
				}
			});
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	const saveConfig = async () => {
		setLoading(true);
		try {
			await editMerakiWebConfig(configCustomized);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Config saved!",
					timeout: 2000,
					error: false
				}
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const handleSubmit = async () => {
		await saveConfig();
		setFormTouched(false);
	};

	const handleEdit = (obj) => {
		setConfigCustomized(obj.updated_src);
		if (!isFormTouched) {
			setFormTouched(true);
		}
		return true;
	};

	const handleAdd = (obj) => {
		const namespace = obj.namespace.join(".");
		// allow adding fields to theme page customization object manually
		if (namespace.includes("theme.settings.page") && obj.name === "customizations") {
			return true;
		}
		// allow adding fields to theme global object manually
		if (namespace.includes("theme.settings") && obj.name === "global") {
			return true;
		}
		// allow adding fields to flags object manually
		if (namespace === "" && obj.name === "flags") {
			return true;
		}
		// allow adding fields to guestCheckout object manually
		if (namespace.includes("flags") && obj.name === "guestCheckout") {
			return true;
		}
		return false;
	};

	const handleCancel = async () => {
		setConfigCustomized({});
		const configBackup = { ...config };
		setConfig({});
		setConfig(configBackup);
		setFormTouched(false);
	};

	if (loading && !config.theme) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"raw-config-container " + (loading && config.theme ? "disabled" : "")}>
			<FormContainer cancel={handleCancel} submit={handleSubmit} submitTitle="Save" hideActions={!isFormTouched}>
				<div className="form-content">
					<ReactJson src={config} name={false} onEdit={handleEdit} onAdd={handleAdd} />
				</div>
			</FormContainer>
		</div>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail,
	merakiWebWorkspace: store.merakiWebWorkspace
}))(RawConfig);
