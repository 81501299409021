import React, { useState, useEffect, useCallback, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import BasicInfo from "../components/ReconciliationPayoutDetail/BasicInfo";
import Orders from "../components/ReconciliationPayoutDetail/Orders";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import HoverContentDisplayGrid from "../components/_commons/HoverContentDisplayGrid";

// third party
import { connect } from "react-redux";

// utils
import history from "../history";
import { adjustNestedContainer, scroll, formatDate, lS, trackEvent, sleep } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// actions
import { fetchPayoutSheetDetails, fetchPayoutSheetsList } from "../actions/reconciliation";
import { store } from "../store/configureStore";

// constants
const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Orders",
		value: "orders"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: 52652
};
const TRANSLATION_FIELDS_MAP = {
	bizLocationNickname: "biz_location_nickname"
};
const DOWNLOAD_DOCUMENTS_INIT_STATE = {
	payoutSheet: false,
	report: false
};

const MAX_EXPONENTIAL_BACKOFF_LIMIT = 7;

const ReconciliationPayoutDetail = ({
	match,
	biz,
	access = {},
	supportedLanguages = [],
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef,
	reconciliationPayoutSheetDetails,
	currencySymbol,
	selectedLocationPayoutStatus,
	reconciliationPayoutsList
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [reconStatusActionTriggered, setReconStatusActionTriggered] = useState(1);
	const exponentialBackoffIndexRef = useRef(0);
	const [isHoverComponentVisible, setHoverComponentVisibility] = useState(false);
	const [currSelectedLang, setCurrSelectedLang] = useState({
		bizLocationNickname: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		}
	});

	const { loading, data } = reconciliationPayoutSheetDetails;
	let processingStatus = {};
	if (data.processingStatus) {
		try {
			processingStatus = JSON.parse(data.processingStatus);
			const orderTotal = Object.keys(processingStatus).reduce(
				(accumulator, status) => accumulator + processingStatus[status],
				0
			);
			processingStatus = {
				...processingStatus,
				orderTotal
			};
		} catch {
			processingStatus = {};
		}
	}

	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isModalBusy, setModalBusy] = useState(false);
	const nestedRef = useRef();
	const [downloading, setDownloading] = useState(DOWNLOAD_DOCUMENTS_INIT_STATE);
	const [showOverflowMenu, setShowOverflowMenu] = useState({ show: false, data: [] });
	const REACT_APP_API_URL_PAYOUT_SHEET = process.env.REACT_APP_API_URL_PAYOUT_SHEET;

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		if (!selectedLocationPayoutStatus && data?.id) {
			const targetReconciliationStatusValue = reconciliationPayoutsList.find((payout) => payout.id === data.id);
			if (targetReconciliationStatusValue) {
				let parsedStatusValues = null;
				try {
					parsedStatusValues = JSON.parse(targetReconciliationStatusValue?.processingStatus);
					store.dispatch({
						type: ActionTypes.SET_SELECTED_LOCATION_PAYOUT_STATUS,
						payload: parsedStatusValues
					});
				} catch {
					store.dispatch({
						type: ActionTypes.SET_SELECTED_LOCATION_PAYOUT_STATUS,
						payload: null
					});
				}
			}
		}
	}, [reconciliationPayoutsList, data]);

	useEffect(() => {
		fetchPayoutSheetDetails(match.params.id);
	}, [match.params.id, fetchPayoutSheetDetails]);

	const handleCancel = async () => {
		if (nestedEntity.show) {
			nestedRef.current.getWrappedInstance().handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(async () => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					history.push("/payout-sheets");
					await fetchPayoutSheetsList();
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value) => {
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleDownload = async () => {
		setDownloading((current) => ({ ...current, report: true }));
		const auth = lS.get("auth");
		const headers = { "Content-Type": "application/json", Authorization: auth && `Bearer ${auth.token}` };
		try {
			const res = await fetch(
				`${REACT_APP_API_URL_PAYOUT_SHEET.replace("upload", "report")}${match.params.id}/`,
				{ headers }
			);
			const data = await res.json();
			if (data.status === "AVAILABLE") {
				await fetch(
					data.sheet && data.sheet.includes("http://")
						? data.sheet.replace("http://", "https://")
						: data.sheet,
					{
						method: "GET",
						cache: "no-cache"
					}
				)
					.then((resp) => resp.blob())
					.then((blob) => {
						// process to auto download it
						const fileURL = URL.createObjectURL(blob);
						const link = document.createElement("a");
						link.href = fileURL;
						link.download = data.sheet.replace(/^.*[\\\/]/, "");
						link.click();
					});
				exponentialBackoffIndexRef.current = 0;
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Report downloaded successfully!",
						timeout: 2000,
						error: false
					}
				});
				trackEvent("reconciliation_report_download", { id: match.params.id });
			} else if (data.status === "FAILED") {
				exponentialBackoffIndexRef.current = 0;
				throw new Error("Failed to obtain required file info.");
			} else if (exponentialBackoffIndexRef.current <= MAX_EXPONENTIAL_BACKOFF_LIMIT) {
				await sleep(Math.pow(2, exponentialBackoffIndexRef.current) * 1000);
				exponentialBackoffIndexRef.current++;
				handleDownload();
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error?.message || "Downloading report failed. Kindly Retry",
					timeout: 2000,
					error: true
				}
			});
		}
		setDownloading((current) => ({ ...current, report: false }));
	};

	const handleDownloadPayoutSheets = async () => {
		setDownloading((current) => ({ ...current, payoutSheet: true }));
		let url = data.filePath;
		if (url) {
			try {
				const link = document.createElement("a");
				link.href = url;
				link.download = url.replace(/^.*[\\\/]/, "");
				link.click();
			} catch (e) {
				console.log(e);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Failed to download payout sheet. Kindly Retry",
						timeout: 2000,
						error: true
					}
				});
			}
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Failed to download payout sheet. Kindly Retry",
					timeout: 2000,
					error: true
				}
			});
		}
		setDownloading((current) => ({ ...current, payoutSheet: false }));
	};

	const scrollDown = () => {
		setTimeout(() => {
			const formContainer = document.getElementsByClassName("form-container")[0];
			if (formContainer) {
				scroll({ top: formContainer.scrollHeight, left: 0 }, formContainer);
			}
		}, 275);
	};

	const handleMouseEnter = () => {
		setHoverComponentVisibility(true);
	};
	const handleMouseLeave = () => {
		setHoverComponentVisibility(false);
	};

	return (
		<React.Fragment>
			<div className="payout-details-container">
				<FormSidebar
					isOpen={isFormOpen}
					close={handleCancel}
					submit={() => {
						console.log("submit function to be defined.");
					}}
					title={
						formatDate(data.startDate, "DD MMM, YYYY") + " - " + formatDate(data.endDate, "DD MMM, YYYY") ||
						"---"
					}
					subTitle={<span>{data?.bizLocationName || ""}</span>}
					submitTitle="Save"
					loading={loading}
					disabled={!isFormTouched}
					hideActions={
						(formTab === FORM_TABS[0].value && !isFormTouched) ||
						(formTab === FORM_TABS[1].value && !isFormTouched) ||
						formTab === FORM_TABS[2].value ||
						formTab === FORM_TABS[3].value ||
						formTab === FORM_TABS[4].value
					}
					isNested={isNested}
					formSidebarClasses={
						formTab === FORM_TABS[1].value && isHoverComponentVisible && "form-container-non-sticky-top-bar"
					}
					headerRight={
						<React.Fragment>
							{downloading.report ? (
								<div className="spinner"></div>
							) : (
								<Button type="secondary" clickHandler={handleDownload}>
									Download Report
								</Button>
							)}
							{downloading.payoutSheet ? (
								<div className="spinner"></div>
							) : (
								<Button type="secondary" clickHandler={handleDownloadPayoutSheets}>
									Download Payout File(s)
								</Button>
							)}
						</React.Fragment>
					}
					headerRightClasses="header-right-payout-details"
				>
					<Topbar
						tabs={FORM_TABS}
						selectedTab={formTab}
						switchTab={(tab) => setFormTab(tab.value)}
						isStickyOnTop={true}
						hiddenTabs={[
							!access.isHubManagement ? "hub" : "",
							!access.isHubManagement ? "logs" : "",
							!biz?.modulesEnabled?.includes("Meraki") ? "meraki" : ""
						]}
					/>
					<div className="form-content">
						{formTab === FORM_TABS[0].value && (
							<BasicInfo
								data={data}
								handleForm={handleForm}
								readOnly={!access.isAdmin}
								scrollDown={scrollDown}
								showLanguages={true}
								currSelectedLang={currSelectedLang}
								loading={loading}
								selectedLocationPayoutStatus={selectedLocationPayoutStatus}
							/>
						)}
						{formTab === FORM_TABS[1].value && (
							<Orders
								readOnly={!access.isAdmin}
								setModalBusy={setModalBusy}
								id={match.params.id}
								setShowOverflowMenu={setShowOverflowMenu}
								handleNestedEntity={handleNestedEntity}
								currencySymbol={currencySymbol}
								reconStatusActionTriggered={reconStatusActionTriggered}
								selectedLocationPayoutStatus={selectedLocationPayoutStatus}
								handleMouseEnter={handleMouseEnter}
								handleMouseLeave={handleMouseLeave}
								processingStatus={processingStatus}
							/>
						)}
						<NestedEntityContainer
							show={nestedEntity.show}
							type={nestedEntity.type}
							id={nestedEntity.id}
							closeNestedContainer={() => handleNestedEntity(false)}
							nestedRef={nestedRef}
							setReconStatusActionTriggered={setReconStatusActionTriggered}
							fromReconciliationPayoutSheet={true}
							fetchPayoutSheetDetails={fetchPayoutSheetDetails}
							payoutId={match.params.id}
						/>
						<HoverContentDisplayGrid
							show={isHoverComponentVisible}
							position={formTab === FORM_TABS[0].value ? { x: 550, y: 240 } : { x: 550, y: 105 }}
							classes={"recon-status-messages-container"}
						>
							<div className="reconciled">Reconciled: {processingStatus?.reconciled_orders || 0}</div>
							<div className="open">Missing in Atlas: {processingStatus?.open_orders || 0}</div>
						</HoverContentDisplayGrid>
					</div>
				</FormSidebar>
			</div>
		</React.Fragment>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail,
	reconciliationPayoutSheetDetails: store.reconciliationPayoutSheetDetails,
	selectedLocationPayoutStatus: store.reconciliationPayoutsList.selectedLocationPayoutStatus,
	reconciliationPayoutsList: store.reconciliationPayoutsList.data.objects,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(ReconciliationPayoutDetail);
