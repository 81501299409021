import React, { useRef, useEffect, useState } from "react";

// components
import { Loading } from "./Loading";

// third party
import Compressor from "compressorjs";

// utils
import { store } from "../../store/configureStore";

// actions
import { createGallery } from "../../actions/gallery";

const UploaderV2 = ({
	classes = "",
	onChange,
	children,
	file,
	url,
	showView = false,
	showDownload = false,
	showDelete = false,
	handleDelete,
	useCompressor = false,
	aspectRatio = "",
	description = "",
	uploadToGallery = false,
	showCustomTooltip = false,
	tooltipInfo = "",
	tooltipPosition = "up-left",
	imageSizeLimit = 10
}) => {
	const inputRef = useRef(null);
	const [showUploadHover, setUploadHover] = useState(false);
	const [imageBase64, setImageBase64] = useState("#");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				setImageBase64(e.target.result);
			};
			reader.readAsDataURL(file);
		}
	}, [file]);

	const calculateGCD = (w, h) => {
		if (h < 0.0000001) {
			return w;
		}
		return calculateGCD(h, Math.floor(w % h));
	};

	const checkAspectRatio = (w, h) => {
		// calculate GCD (Greatest Common Divisor)
		let gcd = calculateGCD(w, h);

		// Divide both width and height by GCD to get the aspect ratio
		if (gcd !== 0) {
			return `${Math.floor(w / gcd)}:${Math.floor(h / gcd)}`;
		}
	};

	const handleImageFile = (files) => {
		const imgFile = files[0];
		if (imgFile) {
			const image = new Image();
			image.src = window.URL.createObjectURL(imgFile);

			// proceed only if the uploaded file is a valid image
			image.onload = async function () {
				if (aspectRatio) {
					// get width and height of uploaded image
					let w = this?.width ? this.width : 0;
					let h = this?.height ? this.height : 0;
					const aspRatio = checkAspectRatio(w, h);
					if (aspRatio !== aspectRatio) {
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: `Image should have specified aspect ratio of ${aspectRatio}`,
								timeout: 3000,
								error: true
							}
						});
						return;
					}
				}
				if (useCompressor) {
					if (imgFile.size < 1047520) {
						if (uploadToGallery) {
							setLoading(true);
							const imageUrl = (await createGallery(imgFile)) || "";
							onChange(imageUrl);
						} else {
							onChange(imgFile);
						}
						setLoading(false);
					} else {
						setLoading(true);
						const result = await new Promise((resolve, reject) => {
							new Compressor(imgFile, {
								quality: 0.6,
								maxWidth: 4000,
								success: resolve,
								error: reject
							});
						});
						if (result && result.size < 1047520) {
							if (uploadToGallery) {
								const imageUrl = (await createGallery(result)) || "";
								onChange(imageUrl);
							} else {
								onChange(result);
							}
						} else {
							store.dispatch({
								type: "SHOW_GLOBAL_MESSAGE",
								payload: {
									message: "Image size is too large, please keep it below 1 MB",
									timeout: 3000,
									error: true
								}
							});
						}
						setLoading(false);
					}
				} else {
					onChange(files);
				}
			};

			// handle error if uploaded file is not an image
			image.onerror = function () {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Please upload a valid image.",
						timeout: 3000,
						error: true
					}
				});
			};
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Something went wrong.",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleUploadFile = (e) => {
		const files = e.target.files;
		handleImageFile(files);
	};

	const handleClick = () => {
		inputRef.current.click();
	};

	const handleOpenUrl = (e) => {
		e.stopPropagation();
		if (url) {
			window.open(url, "_blank").focus();
		}
	};

	const handleDownload = async (e) => {
		e.stopPropagation();
		if (url) {
			await fetch(url.includes("https://") ? url : url.replace("http://", "https://"))
				.then((resp) => resp.blob())
				.then((blob) => {
					// process to auto download it
					const fileURL = URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = fileURL;
					link.download = url.replace(/^.*[\\\/]/, "");
					link.click();
				})
				.catch((error) => {
					console.log(error);
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Something went wrong. Please try again later.",
							timeout: 3000,
							error: true
						}
					});
				});
		}
	};

	const handleOnDrop = (e) => {
		e.stopPropagation();
		e.preventDefault();
		const files = e.dataTransfer.files;
		handleImageFile(files);
	};

	return (
		<div className="at-uploader-container at-uploader-container-v2">
			{children && (
				<div className="field-label">
					{children}
					{showCustomTooltip && tooltipInfo && (
						<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
							<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
						</span>
					)}
					{description && <div className="description">{description}</div>}
				</div>
			)}
			<div
				className={"uploader-area-container " + (classes || "")}
				onClick={handleClick}
				onMouseEnter={() => setUploadHover(true)}
				onMouseLeave={() => setUploadHover(false)}
				onDragOver={(e) => e.preventDefault()}
				onDragEnter={(e) => e.preventDefault()}
				onDragLeave={(e) => e.preventDefault()}
				onDrop={handleOnDrop}
			>
				{file ? (
					<img className="preview-image" src={imageBase64} />
				) : url ? (
					<img className="preview-image" src={url} />
				) : (
					<React.Fragment>
						<img className="upload-icon" src="/assets/icons/icon-empty-img.svg" />
						<div className="at-uploader-label">Click here to upload an image</div>
						<div className="img-size-limit-label">Image size should not exceed {imageSizeLimit} MB</div>
					</React.Fragment>
				)}
				{loading && <Loading />}
				<input
					accept="image/*"
					className="D(n)"
					ref={inputRef}
					type="file"
					onChange={handleUploadFile}
					onClick={() => {
						inputRef.current.value = null;
					}}
				/>
				{showUploadHover && (file || url) && (
					<div className="hover-icons">
						<img title="Reupload" className="icon-edit" src={`/assets/icons/icon-edit-light.svg`} />
						{showView && !file && (
							<img
								title="View"
								className="icon-view"
								src="/assets/icons/icon-view.svg"
								onClick={handleOpenUrl}
							/>
						)}
						{showDownload && !file && (
							<img
								title="Download"
								className="icon-download"
								src="/assets/icons/icon-download.svg"
								onClick={handleDownload}
							/>
						)}
						{showDelete && (
							<img
								title="Delete"
								className="icon-delete"
								src="/assets/icons/icon-delete.svg"
								onClick={handleDelete}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default UploaderV2;
