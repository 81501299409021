import React from "react";

// third party
import he from "he";

// utils
import { printCurrency, commifyNumbers, formatDate } from "../../atlas-utils";

export const chartTooltip = (props, chart = "", type = "") => {
	let tooltipInfo = {};

	switch (chart) {
		case "line":
			const { point, yScaleStacked, lineWidth, data } = props;
			tooltipInfo = {
				// check for compare value in 'point.data.z' to show in tooltip
				index: point.data.z ? point.data.z : point.data.x,
				showRange: props.metric === "avg_order_value",
				label: point.serieId?.replace("*", ""),
				value: yScaleStacked ? point.data.yStacked : point.data.y,
				highlight: {
					background: point.serieColor,
					width: lineWidth + 1
				}
			};
			if (props.showCompareInTooltip) {
				let compareLine = undefined;
				let comparePoint = undefined;
				if (data.length > 2) {
					compareLine = data.find((line) =>
						point.serieId.includes("*")
							? point.serieId.includes(line.id) && !line.id.includes("*")
							: line.id.includes(point.serieId) && line.id.includes("*")
					);
					comparePoint = compareLine ? compareLine.data.find((pt) => pt.x === point.data.x) : undefined;
				} else {
					compareLine = data.find((line) =>
						point.serieId.includes("*") ? !line.id.includes("*") : line.id.includes("*")
					);
					comparePoint = compareLine ? compareLine.data.find((pt) => pt.x === point.data.x) : undefined;
				}
				tooltipInfo.index = point.serieId.includes("*")
					? comparePoint?.z
						? comparePoint?.z
						: comparePoint?.x
					: point.data.z
					? point.data.z
					: point.data.x;
				tooltipInfo.label =
					props.metric === "avg_order_value"
						? point.serieId.includes("*")
							? `${commifyNumbers(Math.round(comparePoint.y))} Orders`
							: `${commifyNumbers(Math.round(point.data.y))} Orders`
						: point.serieId?.replace("*", "");
				tooltipInfo.value = point.serieId.includes("*")
					? props.metric === "avg_order_value"
						? `${comparePoint.percent.toFixed(2)}%`
						: comparePoint?.y
					: yScaleStacked
					? point.data.yStacked
					: props.metric === "avg_order_value"
					? `${point.data.percent.toFixed(2)}%`
					: point.data.y;
				tooltipInfo.renderValue = props.metric === "avg_order_value";
				tooltipInfo.compareIndex = point.serieId.includes("*")
					? point.data.z
						? point.data.z
						: point.data.x
					: comparePoint?.z
					? comparePoint?.z
					: comparePoint?.x;
				tooltipInfo.compareLabel =
					props.metric === "avg_order_value"
						? point.serieId.includes("*")
							? `${commifyNumbers(Math.round(point.data.y))} Orders`
							: `${commifyNumbers(Math.round(comparePoint?.y))} Orders`
						: point.serieId?.replace("*", "");
				tooltipInfo.compareValue = point.serieId.includes("*")
					? yScaleStacked
						? point.data.yStacked
						: props.metric === "avg_order_value"
						? `${point.data.percent.toFixed(2)}%`
						: point.data.y
					: props.metric === "avg_order_value"
					? `${comparePoint?.percent ? comparePoint?.percent?.toFixed(2) : "--"}%`
					: comparePoint?.y;
			}
			if (props.metric === "avg_order_value") {
				data.forEach((obj) => {
					if (obj.id === point.serieId) {
						// find index of tooltip point
						let i = obj.data.findIndex((pt) => pt.x === point.data.x);
						tooltipInfo.index =
							String(Math.round(obj.data[i]?.x)) +
							(i === obj.data.length - 1 ? "+" : "") +
							(obj.data?.[i + 1]?.x ? `-${Math.round(obj.data[i + 1]?.x)}` : "");
						if (props.showCompareInTooltip) {
							tooltipInfo.compareIndex = tooltipInfo.index;
						}
					}
				});
			}
			break;

		case "bar":
			const { id, value, indexValue, color, units } = props;
			if (type === "grouped-stacked") {
				return `
					<div class="nivo-chart-tooltip ${chart} ${type}">
						<div class="current">
							${
								props.fill
									? `<svg width="3" height="65">
									<defs aria-hidden="true">
										<pattern id="bar-lines-pattern-tooltip" width="11.313708498984761" height="11.31370849898476" patternUnits="userSpaceOnUse">
											<rect width="11.313708498984761" height="11.31370849898476" fill="#ffffff" stroke="rgba(255, 0, 0, 0.1)" stroke-width="0"></rect>
											<path d="M -11.313708498984761 11.31370849898476 L 11.313708498984761 -11.31370849898476 M -11.313708498984761 22.62741699796952 L 22.627416997969522 -11.31370849898476 M 0 22.62741699796952 L 22.627416997969522 0" stroke-width="5" stroke="${props.color}" stroke-linecap="square"></path>
										</pattern>
									</defs>
									<rect width="3" height="65" fill="url(#bar-lines-pattern-tooltip)"></rect>
								</svg>`
									: `<div class="highlight" style="background: ${color};"></div>`
							}
							<div class="info">
								<div class="index">${indexValue}</div>
								<div class="label">${id}</div>
								<div class="value">
									${props.currency ? he.unescape(props.currency) : ""}${value === null ? "N.A." : commifyNumbers(Math.round(value))}${
										value !== null ? units : ""
									}
								</div>
							</div>
						</div>
					</div>
				`;
			}

			tooltipInfo = {
				index: indexValue,
				label: id?.replace("*", ""),
				value: value,
				highlight: { background: color }
			};
			if (props.metric === "order_completion_time") {
				tooltipInfo.value =
					value && value > 1 ? `${Math.round(value)} min` : `${Math.round((value || 0) * 60)} sec`;
				tooltipInfo.renderValue = true;
			}
			break;

		case "pie":
			const { datum } = props;
			tooltipInfo = {
				index: null,
				label: datum.label || datum.id,
				value: datum.value,
				highlight: { background: datum.color }
			};
			break;

		case "sankey":
			const { node, link, showPercentValue } = props;
			if (type === "node" && showPercentValue) {
				const links = node.targetLinks.length > 0 ? node.targetLinks : node.sourceLinks;
				node.count = links.reduce((acc, curr) => acc + curr.count, 0);
			}
			tooltipInfo = {
				index: null,
				label: type === "node" ? node.id : `${link.source.id} — ${link.target.id}`,
				value: showPercentValue
					? type === "node"
						? node.count
						: link.count
					: type === "node"
					? node.value
					: link.value,
				highlight: type === "node" ? { background: node.color } : { background: link.source.color }
			};
			break;

		case "heatmap":
			const { cell, tooltipLabel, showZeroLegend } = props;
			tooltipInfo = {
				index: cell?.id?.split(".")?.join(" — "),
				label: commifyNumbers(cell?.value),
				value: cell?.data?.percent || tooltipLabel || null,
				renderValue: true,
				highlight: { background: showZeroLegend && cell?.value === 0 ? "#D0D0D0" : cell.color }
			};
			break;

		default:
			break;
	}

	return (
		<div className={`nivo-chart-tooltip ${chart} ${type}`}>
			<div className="current">
				{chart === "bar" && props.fill ? (
					<svg width="3" height="65">
						<defs aria-hidden="true">
							<pattern
								id="bar-lines-pattern-tooltip"
								width="11.313708498984761"
								height="11.31370849898476"
								patternUnits="userSpaceOnUse"
							>
								<rect
									width="11.313708498984761"
									height="11.31370849898476"
									fill="#ffffff"
									stroke="rgba(255, 0, 0, 0.1)"
									strokeWidth="0"
								></rect>
								<path
									d="M -11.313708498984761 11.31370849898476 L 11.313708498984761 -11.31370849898476 M -11.313708498984761 22.62741699796952 L 22.627416997969522 -11.31370849898476 M 0 22.62741699796952 L 22.627416997969522 0"
									strokeWidth="5"
									stroke={props.color}
									strokeLinecap="square"
								></path>
							</pattern>
						</defs>
						<rect width="3" height="65" fill="url(#bar-lines-pattern-tooltip)"></rect>
					</svg>
				) : (
					<div className="highlight" style={tooltipInfo.highlight}></div>
				)}
				<div className="info">
					{tooltipInfo.index !== null && (
						<div className="index">
							{props.hasDate ? (
								formatDate(tooltipInfo.index, "DD MMM, YYYY")
							) : props.currencyXValues && props.currency ? (
								<span>
									{he.unescape(props.currency)}
									<bdi>
										{tooltipInfo.showRange ? tooltipInfo.index : Math.round(tooltipInfo.index)}
									</bdi>
								</span>
							) : (
								tooltipInfo.index
							)}
						</div>
					)}
					{(props.renderCustomLabel || tooltipInfo.label !== null) && (
						<div className="label">
							{props.renderCustomLabel ? props.renderCustomLabel(props) : tooltipInfo.label}
						</div>
					)}
					<div className={"value" + (chart === "sankey" && type === "link" ? " right" : "")}>
						{props.currencySymbol ? printCurrency(props.currencySymbol) : ""}
						{tooltipInfo.value === null
							? "N.A."
							: tooltipInfo.renderValue
							? tooltipInfo.value
							: commifyNumbers(Math.round(tooltipInfo.value))}
						{tooltipInfo.value !== null ? props.units : ""}
					</div>
					{props.infoTip && <div className="info-tip">{props.infoTip}</div>}
				</div>
				{chart === "sankey" && type === "link" && (
					<div className="highlight" style={{ background: props.link.target.color }}></div>
				)}
			</div>
			{props.showCompareInTooltip && (
				<div className="compare">
					<svg
						width={tooltipInfo.highlight.width}
						height={chart === "line" && props.metric === "avg_order_value" ? 60 : 40}
					>
						<defs aria-hidden="true">
							<pattern id="tooltip-lines-pattern" width="10" height="10" patternUnits="userSpaceOnUse">
								<rect
									width="10"
									height="10"
									fill="#ffffff"
									stroke="rgba(255, 0, 0, 0.1)"
									strokeWidth="0"
								></rect>
								<path
									d="M 0 0 L 10 0 M 0 10 L 10 10"
									strokeWidth="6"
									stroke={tooltipInfo.highlight.background}
									strokeLinecap="square"
								></path>
							</pattern>
						</defs>
						<rect
							width={tooltipInfo.highlight.width}
							height={chart === "line" && props.metric === "avg_order_value" ? 60 : 40}
							fill="url(#tooltip-lines-pattern)"
						></rect>
					</svg>
					<div className="info">
						{tooltipInfo.compareIndex !== null && (
							<div className="compare-index">
								{props.hasDate ? (
									formatDate(tooltipInfo.compareIndex, "DD MMM, YYYY")
								) : props.currencyXValues && props.currency ? (
									<span>
										{he.unescape(props.currency)}
										<bdi>
											{tooltipInfo.showRange
												? tooltipInfo.compareIndex
												: Math.round(tooltipInfo.compareIndex)}
										</bdi>
									</span>
								) : (
									tooltipInfo.compareIndex
								)}
							</div>
						)}
						{chart === "line" && props.metric === "avg_order_value" && (
							<div className="compare-label">{tooltipInfo.compareLabel}</div>
						)}
						<div className="compare-value">
							{props.currencySymbol ? printCurrency(props.currencySymbol) : ""}
							{tooltipInfo.compareValue === null
								? "N.A."
								: tooltipInfo.renderValue
								? tooltipInfo.compareValue
								: commifyNumbers(Math.round(tooltipInfo.compareValue))}
							{tooltipInfo.compareValue !== null ? props.units : ""}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
