import React from "react";
import { connect } from "react-redux";
import { getPlatformDetailViaName } from "../../../helpers/periscope";

const TablePlatform = ({ periscopeItemAvailabilityState, tableName, configItems }) => {
	let sortingData = periscopeItemAvailabilityState?.sort;
	const bizPlatforms = configItems.bizPlatforms?.items;
	let sortedPlatformName = "";
	let orderStatus = "";
	if (sortingData) {
		sortedPlatformName = sortingData.field;
		orderStatus = sortingData.order;
	}
	const topImage = <img src="/assets/periscope/top-face.svg" />;
	const bottomFacedImage = <img src="/assets/periscope/down-face.svg" />;
	const topBlueImage = <img src="/assets/periscope/topBlue.svg" />;
	const bottomFacedBlueImage = <img src="/assets/periscope/bottomFacedBlue.svg" />;

	const currentPlatform = getPlatformDetailViaName(tableName, bizPlatforms);
	return (
		<>
			<div className="item-platform-head">
				{currentPlatform?.image ? <img src={currentPlatform?.image} className="table-platform-img" /> : ""}
				<span> {currentPlatform?.platformName}</span>
				<span style={{ display: "flex", flexDirection: "column" }}>
					{sortedPlatformName === tableName ? (
						orderStatus === "asc" ? (
							<>
								{topBlueImage}
								{bottomFacedImage}
							</>
						) : orderStatus === "desc" ? (
							<>
								{topImage}

								{bottomFacedBlueImage}
							</>
						) : (
							<>
								{topImage}
								{bottomFacedImage}
							</>
						)
					) : (
						<>
							{topImage}
							{bottomFacedImage}
						</>
					)}
				</span>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	periscopeItemAvailabilityState: state.periscopeItemAvailabilityState,
	configItems: state.configItems
});
export default connect(mapStateToProps)(TablePlatform);
