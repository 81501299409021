import gql from "graphql-tag";

export const GET_PENDING_INVITES_LIST = gql`
	query getPendingIvitesList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		userInvites(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				email
				roles
				uuid
				isAccepted
				isCancelled
				createdAt
				acceptedAt
			}
		}
	}
`;

export const RESEND_USER_INVITE = gql`
	mutation resendUserInvite($id: Int!) {
		userInviteResend(input: { id: $id }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const CANCEL_USER_INVITE = gql`
	mutation cancelUserInvite($uuid: String!) {
		userInviteCancel(input: { uuid: $uuid }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				email
				roles
				uuid
				isAccepted
				isCancelled
				createdAt
				acceptedAt
			}
		}
	}
`;
