import React, { useState, useEffect, useCallback, useRef, useImperativeHandle } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Paginator } from "../_commons/Paginator";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";
import CouponActionEntityAssociation from "../EntityAssociations/CouponActionEntityAssociation";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";
import { connect } from "react-redux";

// utils
import { client } from "../../client";
import { printCurrency, adjustNestedContainer, scroll } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ITEMS_LIST } from "../../graphql/items";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchCiModifiersDebounced } from "../../actions/actions";

// config
import { FOOD_TYPE_MAP, NESTED_ENTITY_TYPES } from "../../client-config";

// constants
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const FILTER_INITIAL_STATE = {
	title: "",
	crm_title: "",
	item_discrete_category_id: undefined,
	tags: undefined
};
const ITEMS_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const AssociatedEntities = ({
	isOpen,
	close,
	submit,
	couponId,
	pActionId,
	entityType = {},
	data,
	setModalBusy,
	isModalBusy,
	fetchCouponActions,
	isActionsFormTouched = false,
	saveActionsForm,
	currencySymbol,
	modifiers,
	isNested = true,
	closeNestedContainer,
	connectedRef
}) => {
	const [isFormTouched, setFormTouched] = useState(false);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [itemsData, setItemsData] = useState(ITEMS_INITIAL_DATA);
	const [categoryfilters, setCategoryfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [includeItemOptions, setIncludeItemOptions] = useState({});
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const fetchItemsList = useCallback(async () => {
		if (data && data !== "[]") {
			try {
				setLoading(true);
				const variables = {
					limit,
					offset,
					sort: {
						field: "sort_order",
						order: "ASC"
					}
				};
				// sidebar filters
				let filtersObject = [];
				if (entityType.action === "isp") {
					filtersObject.push({
						field: "id",
						value: data
					});
				} else if (entityType.action === "bxgy") {
					filtersObject.push({
						field: "id",
						value: JSON.parse(data)
							.map((value) => value.id)
							.join(",")
					});
				}
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				});
				if (filtersObject.length > 0) {
					variables.filters = filtersObject;
				}
				const resp = await client.query({
					query: GET_ITEMS_LIST,
					variables,
					fetchPolicy: "no-cache"
				});
				setItemsData(resp.data.items);
				setCategoryfilters(resp.data.items.filters.find((fl) => fl.field === "item_discrete_category_id"));
				setTagfilters(resp.data.items.filters.find((fl) => fl.field === "tags"));

				// set included item options
				if (entityType.action === "bxgy") {
					const parsedData = JSON.parse(data);
					let itemOptions = { ...includeItemOptions };
					parsedData.forEach((item) => {
						let filteredItemOptions = includeItemOptions[item.id]
							? includeItemOptions[item.id]?.filter((option) =>
									option?.id ? !item?.options.includes(option?.id) : !item?.options.includes(option)
							  )
							: [];
						itemOptions[item.id] = [...(item.options || []), ...filteredItemOptions];
					});
					setIncludeItemOptions(itemOptions);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		} else {
			setLoading(false);
			setItemsData(ITEMS_INITIAL_DATA);
			setCategoryfilters({});
			setTagfilters({});
		}
	}, [limit, offset, data, appliedFilters]);

	useEffect(() => {
		if (isOpen && entityType.entity === "items") {
			fetchItemsList();
		}
	}, [fetchItemsList, limit, offset, isOpen, entityType, data]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			// scroll to top
			const formContainer = document.querySelector(".associated-entities-container .form-container");
			const formContent = formContainer?.querySelector(".form-content");
			if (formContainer && formContent) {
				window.requestAnimationFrame(() => {
					scroll({ top: formContent?.offsetTop - 180, left: 0 }, formContainer);
				});
			}
		},
		[limit]
	);

	const handleClose = useCallback(() => {
		if (nestedEntity.show) {
			handleNestedEntity(false);
		} else {
			// reset state before closing
			setItemsData(ITEMS_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setIncludeItemOptions({});
			setFormTouched(false);
			close();
		}
	}, [close]);

	const handleOptions = (id, value) => {
		setIncludeItemOptions({
			...includeItemOptions,
			[id]: value.map((opt) => parseInt(opt.id))
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = useCallback(async () => {
		setConfirmLoading(true);
		const resp = await submit(entityType, includeItemOptions);
		if (resp) {
			setFormTouched(false);
		}
		setConfirmLoading(false);
	}, [submit, entityType, includeItemOptions]);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchCouponActions();
		}
	};

	return (
		<div className="associated-entities-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSubmit}
				loading={confirmLoading}
				isNested={isNested}
				hideHeader={true}
				hideActions={!isFormTouched}
			>
				<div className="form-content">
					<div className="header-container">
						<div>
							<div className="header-text">{entityType.headerText || ""}</div>
							<div className="header-subtext">{entityType.subHeaderText || ""}</div>
						</div>
						<div>
							<div>
								<Button clickHandler={openAssociationSidebar}>Update</Button>
							</div>
						</div>
					</div>
					{entityType.entity === "items" && (
						<React.Fragment>
							<div className="search-container">
								<InputWithLabel
									value={currFilters.title}
									onChange={(e) => setFilter("title", e.target.value)}
									placeholder="Enter Name"
								>
									Name
								</InputWithLabel>
								<SelectFilter
									title="Category"
									options={categoryfilters.values || []}
									field={categoryfilters.field || ""}
									currValue={currFilters.item_discrete_category_id || ""}
									setFilter={setFilter}
									labelKey="valueForDisplay"
									valueKey="value"
								/>
								<SelectFilter
									title="Tags"
									options={tagfilters.values || []}
									field={tagfilters.field || ""}
									currValue={currFilters.tags || ""}
									setFilter={setFilter}
									labelKey="valueForDisplay"
									valueKey="value"
								/>
							</div>
							<div className="crm-title-search-container">
								<InputWithLabel
									value={currFilters.crm_title}
									onChange={(e) => setFilter("crm_title", e.target.value)}
									placeholder="Enter CRM Title"
								>
									CRM Title
								</InputWithLabel>
							</div>
						</React.Fragment>
					)}
					<Table
						data={itemsData.objects}
						loading={loading}
						entityType={entityType}
						currencySymbol={currencySymbol}
						modifiers={modifiers}
						includeItemOptions={includeItemOptions}
						handleOptions={handleOptions}
						handleNestedEntity={handleNestedEntity}
					/>
					<Paginator limit={limit} offset={offset} count={itemsData.count || 0} goToPage={handlePagination} />
					<CouponActionEntityAssociation
						couponId={couponId}
						pActionId={pActionId}
						isActionsFormTouched={isActionsFormTouched}
						saveActionsForm={saveActionsForm}
						isOpen={associationSidebar}
						close={closeAssociationSidebar}
						currencySymbol={currencySymbol}
						entityType={entityType}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	modifiers: store.configItems.modifiers
}))(AssociatedEntities);

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	entityType = {},
	currencySymbol,
	modifiers,
	includeItemOptions,
	handleOptions,
	handleNestedEntity
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				"custom-table-container bordered associated-entities-list-table-container " +
				(data.length > 0 && loading ? "disabled " : "") +
				entityType.action
			}
		>
			<TableHeader
				sortList={sortList}
				sortedField={sortedField}
				headerFields={entityType.tableFields}
				entityType={entityType}
			/>
			{trails.map(({ rotate }, i) => (
				<TableList
					key={data[i].id}
					currencySymbol={currencySymbol}
					handleNestedEntity={handleNestedEntity}
					entityType={entityType}
					modifiers={modifiers}
					includeItemOptions={includeItemOptions}
					handleOptions={handleOptions}
					style={{
						transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
					}}
					{...data[i]}
				/>
			))}
			{data.length === 0 && !loading && <div className="no-items-placeholder">No Items found!</div>}
			{data.length === 0 && loading && (
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			)}
		</div>
	);
};

const TableHeader = ({ sortList, headerFields = [], entityType }) => (
	<div className="table-row-header">
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`table-cell table-header ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
		{entityType.action === "bxgy" && (
			<div className="table-cell table-header modifiers">
				<span>Included Modifiers</span>
			</div>
		)}
	</div>
);

export const TableList = ({
	id,
	itemTitle,
	category,
	itemPrice,
	crmTitle,
	foodType,
	tags,
	style,
	currencySymbol,
	handleNestedEntity,
	handleOptions,
	entityType,
	modifiers,
	includeItemOptions
}) => {
	return (
		<animated.div
			// style={style}
			className="table-row-list"
		>
			<div className="table-cell table-list name" title={itemTitle}>
				<div className="item-desc">
					<div className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<a
						role="button"
						className="link-text"
						onClick={(e) => handleNestedEntity(e, NESTED_ENTITY_TYPES[1], id)}
					>
						{itemTitle || id}
					</a>
				</div>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
				<div className="text--light item_tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										(entityType.action === "bxgy" ? i < 2 : i < 3) && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{(entityType.action === "bxgy" ? tags.length > 2 : tags.length > 3) && (
									<span
										className="tag-item more-tags"
										title={
											entityType.action === "bxgy"
												? tags.slice(2).join(", ")
												: tags.slice(3).join(", ")
										}
									>
										+{entityType.action === "bxgy" ? tags.length - 2 : tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="table-cell table-list category" title={category || null}>
				{category || "--"}
			</div>
			<div className="table-cell table-list price">
				{printCurrency(currencySymbol)}
				{itemPrice || 0}
			</div>
			{entityType.action === "bxgy" && (
				<div className="table-cell table-list modifiers">
					<SelectFilter
						options={modifiers?.items || []}
						isLoading={modifiers.isLoading}
						field="itemOptions"
						currValue={includeItemOptions[id] || []}
						setFilter={(field, value) => handleOptions(id, value)}
						labelKey="optionTitle"
						valueKey="id"
						isAsync={true}
						handleSearch={fetchCiModifiersDebounced}
						multi={true}
					/>
				</div>
			)}
		</animated.div>
	);
};
