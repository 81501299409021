import gql from "graphql-tag";

export const GET_REPORT_GROUPS_LIST = gql`
	query getReportGroupsList {
		reportGroups {
			group
			reports {
				id
				title
				description
				filters
			}
		}
	}
`;

export const GET_REPORT_FILTERS = gql`
	query getReportFilters {
		reportFilters {
			allowedMonths
			filters {
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
		}
	}
`;

export const GET_BRAND_LOCATIONS = gql`
	query getBrandLocations($brandIds: [Int]!) {
		reportStoresList(brandIds: $brandIds) {
			bizLocationId
			bizLocationNickname
			brandId
		}
	}
`;

export const GENERATE_REPORT = gql`
	mutation generateReport($input: GenerateReportInput) {
		generateReport(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_REPORT_GROUPS_NEW_LIST = gql`
	query getReportGroupsList {
		reportV2Groups {
			objects {
				reportGroup
				reports {
					id
					name
					description
				}
			}
		}
	}
`;

export const GET_REPORT_DETAIL = gql`
	query getReportDetail($reportId: String!) {
		reportDetail(reportId: $reportId) {
			id
			name
			description
			group
			previewAvailable
			emailAvailable
			downloadAvailable
			exports {
				id
				name
				icon
			}
			filters {
				hide
				field
				valueForDisplay
				type
				custom
				required
				values {
					valueForDisplay
					value
					additionals {
						key
						value
					}
				}
				default {
					valueForDisplay
					value
				}
			}
		}
	}
`;

export const GENERATE_REPORT_V2 = gql`
	mutation generateReport($input: ReportGenerationInput!) {
		generateReportV2(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_REPORTS_TASK_LIST = gql`
	query getReportsTaskList($limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		reportTaskList(limit: $limit, offset: $offset, filters: $filters) {
			count
			objects {
				status
				name
				jobId
				message
				downloadUrl
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
		}
	}
`;

export const GET_REPORTS_EXPORT_HISTORY = gql`
	query getReportsExportHistory(
		$limit: Int
		$prevId: String
		$nextId: String
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		reportHistory(limit: $limit, prevId: $prevId, nextId: $nextId, filters: $filters, search: $search) {
			hasMore
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				report {
					typeId
					name
					group
				}
				reportId
				status
				createdAt
				processedAt
				requestedUserEmail
				requestedUser {
					id
					fullName
				}
				requestedForEmails
				reportSize
				downloadLink
				exportFormat
				filtersApplied {
					id
					title
					type
					values
				}
				scheduleType
				showDataFor
			}
			searchKeywords {
				key
				valueForDisplay
			}
		}
	}
`;

export const GET_REPORT_PREVIEW = gql`
	query getReportPreview($limit: Int, $filters: ReportV2PreviewInputType!) {
		reportPreview(limit: $limit, filters: $filters) {
			message
			columns {
				key
				display
				dataType
			}
			rows {
				key
				value
			}
		}
	}
`;

export const GET_SCHEDULE_REPORTS_LIST = gql`
	query getScheduleReportsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		reportScheduleList(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				report {
					typeId
					name
					group
				}
				filtersAdded {
					id
					title
					type
					values
				}
				scheduleType
				showDataFor
				status
				emails
				exportFormat
			}
		}
	}
`;

export const CREATE_SCHEDULE_REPORT = gql`
	mutation createScheduleReport($input: ReportScheduleInput) {
		editReportSchedule(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
			filters {
				hide
				field
				valueForDisplay
				type
				custom
				required
				values {
					valueForDisplay
					value
					additionals {
						key
						value
					}
				}
				default {
					valueForDisplay
					value
				}
			}
		}
	}
`;

export const GET_SCHEDULE_REPORT_DETAIL = gql`
	query getScheduleReportDetail($id: ID) {
		reportScheduleDetail(id: $id) {
			id
			report {
				typeId
				name
				group
			}
			filtersAdded {
				id
				title
				type
				values
			}
			scheduleType
			showDataFor
			status
			emails
			exportFormat
		}
	}
`;

export const EDIT_SCHEDULE_REPORT = gql`
	mutation editScheduleReport($input: ReportScheduleInput) {
		editReportSchedule(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
			filters {
				hide
				field
				valueForDisplay
				type
				custom
				required
				values {
					valueForDisplay
					value
					additionals {
						key
						value
					}
				}
				default {
					valueForDisplay
					value
				}
			}
		}
	}
`;
