import React, { Component } from "react";

// third party
import Select from "react-select-animated";
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";

// clients
import { store } from "../../store/configureStore";

const MAX_DATE_RANGE_ALLOWED = 365;
export const DATE_TYPES = [
	{ label: "Presets", value: "preset_duration" },
	{ label: "Custom", value: "range" }
];
export const COMPARE_DATE_TYPES = [
	{ label: "Not specified", value: "not_specified" },
	{ label: "Default", value: "default" },
	{ label: "Custom", value: "range" }
];
export const PRESET_TYPES = [
	{ label: "Today", value: "TODAY" },
	{ label: "Yesterday", value: "YESTERDAY" },
	{ label: "This week", value: "THIS_WEEK" },
	{ label: "This month", value: "THIS_MONTH" },
	{ label: "Last 7 days", value: "LAST_7_DAYS" },
	{ label: "Last 30 days", value: "LAST_30_DAYS" },
	{ label: "Last 90 days", value: "LAST_90_DAYS" },
	{ label: "This year", value: "THIS_YEAR" }
];
const DATE_FORMAT = "DD MMM, YYYY";
const CALENDER_DATE_FORMAT = "DD MMMM";

@connect((store) => ({
	dimensions: store.configItems.dimensions
}))
export class DateCompareFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCalender: ""
		};
		this.dateFilterContainer = React.createRef();
	}

	componentDidMount() {
		this.dateFilterContainer.current.focus();
	}

	changeCurrentDateType = (dateTypeSelected) => {
		const { currentDateFilter } = this.props;
		switch (dateTypeSelected.value) {
			case DATE_TYPES[0].value:
				this.props.updateState({
					currentDateFilter: {
						...currentDateFilter,
						current: {
							...currentDateFilter.current,
							dateTypeSelected,
							presetTypeSelected: PRESET_TYPES[0],
							dateFilter: PRESET_TYPES[0].value
						}
					}
				});
				if (this.state.showCalender === "currentStart" || this.state.showCalender === "currentEnd") {
					this.handleShowCalender("");
				}
				break;
			case DATE_TYPES[1].value:
				const rangeStartDate = moment().subtract(45, "d");
				const rangeEndDate = moment();
				const start = rangeStartDate.format("YYYY-MM-DD");
				const end = rangeEndDate.format("YYYY-MM-DD");
				this.props.updateState({
					currentDateFilter: {
						...currentDateFilter,
						current: {
							...currentDateFilter.current,
							dateTypeSelected,
							rangeStartDate,
							rangeEndDate,
							dateFilter: `${start},${end}`
						}
					}
				});
				this.handleShowCalender("currentStart");
				break;
			default:
				break;
		}
		if (currentDateFilter.compare.dateTypeSelected.value !== COMPARE_DATE_TYPES[0].value) {
			this.setCompareDateRange();
		}
	};

	changeCompareDateType = (dateTypeSelected) => {
		const { currentDateFilter } = this.props;
		if (dateTypeSelected.value === COMPARE_DATE_TYPES[0].value) {
			this.props.updateState({
				currentDateFilter: {
					...currentDateFilter,
					compare: {
						...currentDateFilter.compare,
						dateTypeSelected,
						dateFilter: "",
						rangeStartDate: undefined,
						rangeEndDate: undefined
					}
				}
			});
			this.handleShowCalender("");
		} else {
			const [rangeStartDate, rangeEndDate] = this.getDefaultCompareStartEndDateRange();
			const start = rangeStartDate.format("YYYY-MM-DD");
			const end = rangeEndDate.format("YYYY-MM-DD");
			this.props.updateState({
				currentDateFilter: {
					...currentDateFilter,
					compare: {
						...currentDateFilter.compare,
						dateTypeSelected,
						rangeStartDate,
						rangeEndDate,
						dateFilter: `${start},${end}`
					}
				}
			});
			this.handleShowCalender("compareStart");
		}
	};

	changeCurrentPresetType = (presetTypeSelected) => {
		const { currentDateFilter } = this.props;
		this.props.updateState({
			currentDateFilter: {
				...currentDateFilter,
				current: {
					...currentDateFilter.current,
					presetTypeSelected,
					dateFilter: presetTypeSelected.value
				}
			}
		});
		if (currentDateFilter.compare.dateTypeSelected.value !== COMPARE_DATE_TYPES[0].value) {
			this.setCompareDateRange();
		}
	};

	handleCurrentStartEndDate = (e, whichDate) => {
		let { currentDateFilter } = this.props;
		const { rangeEndDate } = currentDateFilter.current;
		if (whichDate === "rangeStartDate" && rangeEndDate && rangeEndDate.diff(e, "d") > MAX_DATE_RANGE_ALLOWED) {
			currentDateFilter.current.rangeEndDate = undefined;
		}
		this.props.updateState({
			currentDateFilter: {
				...currentDateFilter,
				current: {
					...currentDateFilter.current,
					[whichDate]: e
				}
			}
		});
		this.setCurrentDateRange();
		if (currentDateFilter.compare.dateTypeSelected.value !== COMPARE_DATE_TYPES[0].value) {
			this.setCompareDateRange();
		}
	};

	handleCompareStartDate = (rangeStartDate) => {
		let { currentDateFilter } = this.props;
		const currentRangeDiff = this.getDefaultCompareStartEndDateRange()[2];
		const rangeEndDate = rangeStartDate
			.clone()
			.add(currentRangeDiff > 0 ? currentRangeDiff - 1 : currentRangeDiff, "d");
		const start = rangeStartDate ? rangeStartDate.format("YYYY-MM-DD") : "";
		const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
		this.props.updateState({
			currentDateFilter: {
				...currentDateFilter,
				compare: {
					...currentDateFilter.compare,
					dateTypeSelected: COMPARE_DATE_TYPES[2],
					rangeStartDate,
					rangeEndDate,
					dateFilter: `${start},${end}`
				}
			}
		});
	};

	setCurrentDateRange = () => {
		const { currentDateFilter } = store.getState()[this.props.storeRootField];
		const { rangeStartDate, rangeEndDate } = currentDateFilter.current;
		const start = rangeStartDate ? rangeStartDate.format("YYYY-MM-DD") : "";
		const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
		this.props.updateState({
			currentDateFilter: {
				...currentDateFilter,
				current: {
					...currentDateFilter.current,
					dateFilter: `${start},${end}`
				}
			}
		});
	};

	setCompareDateRange = () => {
		const { currentDateFilter } = store.getState()[this.props.storeRootField];
		const [rangeStartDate, rangeEndDate] = this.getDefaultCompareStartEndDateRange();
		const start = rangeStartDate ? rangeStartDate.format("YYYY-MM-DD") : "";
		const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
		this.props.updateState({
			currentDateFilter: {
				...currentDateFilter,
				compare: {
					...currentDateFilter.compare,
					rangeStartDate,
					rangeEndDate,
					dateFilter: `${start},${end}`
				}
			}
		});
	};

	// Based on current start/end date or preset selected
	// this function returns default compare start/end date and diff in number
	// of days.
	getDefaultCompareStartEndDateRange = () => {
		const { current } = store.getState()[this.props.storeRootField].currentDateFilter;
		let diff = 0;
		let defaultCompareStart = moment();
		let defaultCompareEnd = moment();
		if (current.dateTypeSelected.value === DATE_TYPES[0].value) {
			switch (current.dateFilter) {
				case "TODAY":
					diff = 0;
					defaultCompareStart = moment().subtract(1, "d");
					defaultCompareEnd = moment().subtract(1, "d");
					break;
				case "YESTERDAY":
					diff = 0;
					defaultCompareStart = moment().subtract(2, "d");
					defaultCompareEnd = moment().subtract(2, "d");
					break;
				case "THIS_WEEK":
					diff = moment().diff(moment().day("Monday"), "d");
					defaultCompareStart = moment().subtract(1, "w").day("Monday");
					defaultCompareEnd = defaultCompareStart.clone().add(diff, "d");
					break;
				case "THIS_MONTH":
					diff = moment().date();
					defaultCompareStart = moment().subtract(1, "M").date(1);
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "LAST_7_DAYS":
					diff = 7;
					defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "LAST_30_DAYS":
					diff = 30;
					defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "LAST_90_DAYS":
					diff = 90;
					defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "THIS_YEAR":
					diff = moment().dayOfYear();
					defaultCompareStart = moment().subtract(1, "y").dayOfYear(1);
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				default:
					break;
			}
		} else {
			if (current.rangeStartDate && current.rangeEndDate) {
				diff = current.rangeEndDate.diff(current.rangeStartDate, "d") + 1;
				defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
				defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
			}
		}
		return [defaultCompareStart, defaultCompareEnd, diff];
	};

	handleShowCalender = (showCalender) => {
		this.setState({
			showCalender
		});
	};

	handleKeyboardEvent = (e) => {
		e.stopPropagation();
		if (e.key === "Escape") {
			this.props.resetDateRange();
		}
	};

	renderCalenderInput = () => {
		const { currentDateFilter } = this.props;
		const { current, compare } = currentDateFilter;
		let rangeEndDateMax;
		if (this.state.showCalender === "currentEnd" && current.dateTypeSelected.value === DATE_TYPES[1].value) {
			let rangeEndDateMaxTemp = current.rangeStartDate.clone().add(MAX_DATE_RANGE_ALLOWED, "d");
			rangeEndDateMax = moment.min(rangeEndDateMaxTemp, moment());
		}
		const currentRangeDiff = this.getDefaultCompareStartEndDateRange()[2];
		const compareRangeStartDateMax = moment().subtract(currentRangeDiff, "d");

		switch (this.state.showCalender) {
			case "currentStart":
				return (
					<DatePicker
						inline
						fixedHeight
						selected={current.rangeStartDate}
						selectsStart
						startDate={current.rangeStartDate}
						endDate={current.rangeEndDate}
						onChange={(e) => this.handleCurrentStartEndDate(e, "rangeStartDate")}
						dateFormat={DATE_FORMAT}
						dateFormatCalendar={CALENDER_DATE_FORMAT}
						showYearDropdown
						scrollableYearDropdown
						yearDropdownItemNumber={10}
						maxDate={current.rangeEndDate || moment()}
					/>
				);
			case "currentEnd":
				return (
					<DatePicker
						inline
						fixedHeight
						openToDate={current.rangeEndDate || rangeEndDateMax || moment()}
						selected={current.rangeEndDate}
						selectsEnd
						startDate={current.rangeStartDate}
						endDate={current.rangeEndDate}
						minDate={current.rangeStartDate}
						onChange={(e) => this.handleCurrentStartEndDate(e, "rangeEndDate")}
						dateFormat={DATE_FORMAT}
						dateFormatCalendar={CALENDER_DATE_FORMAT}
						showYearDropdown
						scrollableYearDropdown
						yearDropdownItemNumber={10}
						maxDate={rangeEndDateMax || moment()}
					/>
				);
			case "compareStart":
				return (
					<DatePicker
						inline
						fixedHeight
						selected={compare.rangeStartDate}
						selectsStart
						startDate={compare.rangeStartDate}
						endDate={compare.rangeEndDate}
						onChange={(date) => this.handleCompareStartDate(date)}
						dateFormat={DATE_FORMAT}
						dateFormatCalendar={CALENDER_DATE_FORMAT}
						showYearDropdown
						scrollableYearDropdown
						yearDropdownItemNumber={10}
						maxDate={compareRangeStartDateMax}
					/>
				);
			default:
				return null;
		}
	};

	render() {
		const { currentDateFilter, dimensions, hideComparison = false } = this.props;
		const { current, compare } = currentDateFilter;

		let dateTypes = DATE_TYPES;
		if (dimensions.width <= 768) {
			dateTypes[1].disabled = true;
		} else {
			dateTypes[1].disabled = false;
		}

		return (
			<div
				className="date-compare-filter-container"
				onKeyDown={(e) => this.handleKeyboardEvent(e)}
				ref={this.dateFilterContainer}
				tabIndex="0"
			>
				<div className="date-calender-input">{this.renderCalenderInput()}</div>
				<div className="date-control-ui">
					<div className="date-filter-section date-current">
						<div className="date-section-title">Date Range</div>
						<div className="date-section-column">
							<div className="type-selector date-section-control-row">
								<Select
									searchable={false}
									className="at-dropdown dropdown-first"
									options={dateTypes}
									value={current.dateTypeSelected}
									clearable={false}
									onChange={(m) => this.changeCurrentDateType(m)}
								/>
								{current.dateTypeSelected.value === DATE_TYPES[0].value && (
									<Select
										searchable={false}
										className="at-dropdown"
										options={PRESET_TYPES}
										value={current.presetTypeSelected}
										clearable={false}
										onChange={(m) => this.changeCurrentPresetType(m)}
									/>
								)}
							</div>
							{current.dateTypeSelected.value === DATE_TYPES[1].value && (
								<div className="date-range-input date-section-control-row">
									<div className="range-first">
										<InputWithLabel
											classes={
												"inline-input-date " +
												(this.state.showCalender === "currentStart" ? "active" : "")
											}
											readOnly={true}
											onClick={() => this.handleShowCalender("currentStart")}
											value={
												current.rangeStartDate
													? moment(current.rangeStartDate).format(DATE_FORMAT)
													: ""
											}
										/>
									</div>
									<div className="seperator">To</div>
									<div className="range-second">
										<InputWithLabel
											classes={
												"inline-input-date " +
												(this.state.showCalender === "currentEnd" ? "active" : "")
											}
											readOnly={true}
											onClick={() => this.handleShowCalender("currentEnd")}
											value={
												current.rangeEndDate
													? moment(current.rangeEndDate).format(DATE_FORMAT)
													: ""
											}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
					{!hideComparison && (
						<div className="date-filter-section date-compare">
							<div className="date-section-title">Comparison</div>
							<div className="date-section-column">
								<div className="type-selector date-section-control-row">
									<Select
										searchable={false}
										className="at-dropdown dropdown-first"
										options={COMPARE_DATE_TYPES}
										value={compare.dateTypeSelected}
										clearable={false}
										onChange={(m) => this.changeCompareDateType(m)}
									/>
								</div>
								{compare.dateTypeSelected.value !== COMPARE_DATE_TYPES[0].value && (
									<div className="date-range-input date-section-control-row">
										<div className="range-first">
											<InputWithLabel
												classes={
													"inline-input-date " +
													(this.state.showCalender === "compareStart" ? "active" : "")
												}
												readOnly={true}
												onClick={() => this.handleShowCalender("compareStart")}
												value={
													compare.rangeStartDate
														? moment(compare.rangeStartDate).format(DATE_FORMAT)
														: ""
												}
											/>
										</div>
										<div className="seperator">To</div>
										<div className="range-second">
											<InputWithLabel
												classes="disabled inline-input-date"
												readOnly={true}
												value={
													compare.rangeEndDate
														? moment(compare.rangeEndDate).format(DATE_FORMAT)
														: ""
												}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
					<div className="date-filter-action">
						<div className="btn-apply" onClick={this.props.applyDateRange}>
							Apply
						</div>
						<div
							className="btn-reset"
							onClick={() => {
								this.handleShowCalender("");
								this.props.resetDateRange();
							}}
						>
							Reset
						</div>
						<div className="btn-cancel" onClick={this.props.handleDropdown}>
							Cancel
						</div>
					</div>
				</div>
			</div>
		);
	}
}
