import React, { useState, useRef, useEffect } from "react";

// third party
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Compressor from "compressorjs";

// components
import DetailsEntry from "../BrandsCreate/DetailsEntry";
import ImageSizeWarningModal from "../_commons/ImageSizeWarningModal";
import CropModal from "../_commons/CropModal";

// utils
import { canvasPreview } from "../../atlas-utils";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
	return centerCrop(
		makeAspectCrop(
			{
				unit: "%",
				width: 90
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
};

function CreateBrand({
	setOrigImgFile,
	origImgFile,
	brandName,
	setBrandName,
	canvasSaveRef,
	previewCanvasRef,
	imgRenderRef
}) {
	const [file, setFile] = useState(null);
	const [isImageSizeLarge, setIsImageSizeLarge] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [crop, setCrop] = useState();
	const [imgSrc, setImgSrc] = useState("");
	const [completedCrop, setCompletedCrop] = useState();
	const inputRef = useRef(null);
	const imgRef = useRef();
	const aspect = 1;
	const scale = 1;
	const rotate = 0;
	const exponentialBackOffTimeoutRef = useRef();
	const delayedStatusCheckApiCallRef = useRef();

	useEffect(() => {
		if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
			canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
		}
	}, [completedCrop]);

	const clearTimers = () => {
		if (exponentialBackOffTimeoutRef.current) {
			clearTimeout(exponentialBackOffTimeoutRef.current);
		}

		if (delayedStatusCheckApiCallRef.current) {
			clearTimeout(delayedStatusCheckApiCallRef.current);
		}
	};

	useEffect(() => {
		return () => clearTimers();
	}, []);

	const handleFileSizeAndUpdate = async (file) => {
		if (!file) {
			return;
		}
		setOrigImgFile(file);
		if (file && file.size > 1047520) {
			const result = await new Promise((resolve, reject) => {
				new Compressor(file, {
					quality: 0.6,
					maxWidth: 4000,
					success: resolve,
					error: reject
				});
			});
			if (result && result.size < 1047520) {
				file = result;
			} else {
				setIsImageSizeLarge(true);
				return;
			}
		}
		setFile(file);
		imgRenderRef.current = null;
		setCrop(undefined); // Makes crop preview update between images.
		const reader = new FileReader();
		reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
		reader.readAsDataURL(file);
		setModalOpen(true);
	};

	const onFileChange = (e) => {
		handleFileSizeAndUpdate(e?.target?.files[0]);
	};

	const onImageLoad = (e) => {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	};

	const handleImageSizeModalClose = () => {
		setIsImageSizeLarge(false);
	};

	const handleCropModalClose = () => {
		setModalOpen(false);
		setImgSrc("");
	};

	const handleImageChange = () => {
		setIsImageSizeLarge(false);
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	return (
		<React.Fragment>
			<div className="onboarding-create-brand">
				<DetailsEntry
					hideTitle
					brandName={brandName}
					setBrandName={setBrandName}
					files={file}
					setFiles={setFile}
					inputRef={inputRef}
					previewCanvasRef={previewCanvasRef}
					onFileChange={onFileChange}
					imgSrc={imgSrc}
					setImgSrc={setImgSrc}
					imgRenderRef={imgRenderRef}
				/>
			</div>
			<ImageSizeWarningModal
				isOpen={isImageSizeLarge}
				handleSubmit={handleImageChange}
				close={handleImageSizeModalClose}
			/>
			<CropModal
				isOpen={isModalOpen}
				title="Crop & Adjust"
				classes="brand-creation-flow--modal"
				submitAction={() => {
					setModalOpen(false);
				}}
				cancelAction={handleCropModalClose}
				close={handleCropModalClose}
				imgRef={imgRef}
				imgSrc={imgSrc}
				onImageLoad={onImageLoad}
				crop={crop}
				setCrop={setCrop}
				setCompletedCrop={setCompletedCrop}
				aspect={aspect}
			/>
		</React.Fragment>
	);
}

export default CreateBrand;
