import React from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";

// assets
import CreateIcon from "../_commons/CreateIcon";
import BulkActionIcon from "../_commons/BulkActionIcon";
import HelpIcon from "../_commons/HelpIcon";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Modifiers</div>
				<div className="subtitle">Central repository for all your modifiers</div>
			</div>
			{!props.hideHeaderSectionRight && (
				<div className="header-action-button">
					<div className="filter-buttons">
						{props.dimensions.width > 768 && (
							<div
								className={
									(props.filterActive ? "active" : "") + " filter-in-header campaign-list-filter"
								}
							>
								<div className="container" onClick={props.flipShowFilters}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{props.filterCount > 0 && (
											<span className="filter-count">{props.filterCount}</span>
										)}
									</div>
								</div>
							</div>
						)}
						{props.isCatalogueManagement && (
							<div className="bulk-actions-container">
								<Link to="/bulk/modifiers">
									<Button type="secondary">
										<BulkActionIcon />
										<span>Bulk Actions</span>
									</Button>
								</Link>
							</div>
						)}
						{props.isCatalogueManagement && (
							<div className="action-buttons-container">
								<Link to="/modifiers/new">
									<Button>
										<CreateIcon />
										<span>Add Modifier</span>
									</Button>
								</Link>
							</div>
						)}
						{props.searchKeywords.length > 1
							? props.dimensions.width > 768 && (
									<div className="search-input-container">
										<SelectFilter
											options={props.searchKeywords}
											field="searchFieldSelected"
											currValue={props.searchFieldSelected}
											setFilter={props.handleSearchField}
											labelKey="valueForDisplay"
											valueKey="key"
											isSearchable={false}
											isClearable={false}
										/>
										<SearchFilter
											filterOption={{ field: "searchFieldValue" }}
											value={props.searchFieldValue}
											setFilter={props.setFilter}
											placeholder="Search"
										/>
									</div>
							  )
							: props.dimensions.width > 768 && (
									<SearchFilter
										filterOption={{ field: "searchFieldValue" }}
										value={props.searchFieldValue}
										setFilter={props.setFilter}
										placeholder="Search"
									/>
							  )}
						<div className="help-btn-container">
							<Button clickHandler={props.handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
