import gql from "graphql-tag";

export const SALES_ANALYTICS_TOPICS = gql`
	query getAnalyticsTopic(
		$productType: ProductType!
		$duration: Duration!
		$topic: Topic
		$groupBy: Criteria
		$isAnalytics: Boolean
		$comparisonDuration: CustomDateDuration
		$filters: [ListFilterArgument]
		$brand: String
	) {
		analytics {
			topics(
				productType: $productType
				duration: $duration
				topic: $topic
				groupBy: $groupBy
				isAnalytics: $isAnalytics
				comparisonDuration: $comparisonDuration
				filters: $filters
				brand: $brand
			) {
				displayName
				value
				comparisonValue
				percentChange
				topic
				criteria
				group
			}
			filters {
				field
				valueForDisplay
				values {
					valueForDisplay
					value
				}
				type
			}
		}
	}
`;

export const SALES_ANALYTICS_OVERVIEW_DATASET_GRAPH = gql`
	query getAnalyticsDataset(
		$productType: ProductType!
		$duration: Duration!
		$topic: Topic!
		$criteria: Criteria
		$criteriaValue: String
		$dataFormat: DataFormat!
		$isAnalytics: Boolean
		$isThumbnail: Boolean
		$comparisonDuration: CustomDateDuration
		$filters: [ListFilterArgument]
		$brand: String
	) {
		analytics {
			dataset(
				productType: $productType
				duration: $duration
				topic: $topic
				criteria: $criteria
				criteriaValue: $criteriaValue
				dataFormat: $dataFormat
				isAnalytics: $isAnalytics
				isThumbnail: $isThumbnail
				comparisonDuration: $comparisonDuration
				filters: $filters
				brand: $brand
			) {
				... on GraphDataset {
					current {
						timestamp
						value
					}
					previous {
						timestamp
						value
					}
					timeUnit
				}
			}
		}
	}
`;

export const SALES_ANALYTICS_PERFORMANCE_DATASET_TAB = gql`
	query getAnalyticsDataset(
		$productType: ProductType!
		$duration: Duration!
		$topic: Topic!
		$criteria: Criteria
		$criteriaValue: String
		$dataFormat: DataFormat!
		$isAnalytics: Boolean
		$comparisonDuration: CustomDateDuration
		$metricTopic: Topic
		$channels: [ChannelType]
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$search: String
		$brand: String
	) {
		analytics {
			dataset(
				productType: $productType
				duration: $duration
				topic: $topic
				criteria: $criteria
				criteriaValue: $criteriaValue
				dataFormat: $dataFormat
				isAnalytics: $isAnalytics
				comparisonDuration: $comparisonDuration
				metricTopic: $metricTopic
				channels: $channels
				limit: $limit
				offset: $offset
				filters: $filters
				search: $search
				brand: $brand
			) {
				... on TabularDataset {
					count
					columns {
						key
						displayName
					}
					rows {
						key
						value
						comparisonValue
					}
				}
			}
		}
	}
`;

export const GET_BIZ_CHANNELS = gql`
	query getBizChannels($includeHubChannels: Boolean, $includeUrbanpiper: Boolean, $includeInternalChannels: Boolean) {
		bizChannels(
			includeHubChannels: $includeHubChannels
			includeUrbanpiper: $includeUrbanpiper
			includeInternalChannels: $includeInternalChannels
		) {
			valueForDisplay
			value
		}
	}
`;

export const GET_ITEM_IMAGES = gql`
	query getItemImages($limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		items(limit: $limit, offset: $offset, filters: $filters) {
			objects {
				id
				imageUrl
			}
		}
	}
`;
