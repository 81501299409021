import React, { useEffect, useState } from "react";

// components
import { FormContainer } from "../../containers/Meraki";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Switch } from "../_commons/Switch";
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";
import { Textarea } from "../_commons/Textarea";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchMerakiWebApps, editMerakiWebApps } from "../../actions/merakiWeb";

export const Apps = () => {
	const [loading, setLoading] = useState(true);
	const [appsConfig, setAppsConfig] = useState(undefined);
	const [isFormTouched, setFormTouched] = useState(false);

	const fetchAppsConfig = async () => {
		setLoading(true);
		try {
			const appsConfig = await fetchMerakiWebApps();
			setAppsConfig(appsConfig?.data);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchAppsConfig();
	}, []);

	const handleCancel = async () => {
		await fetchAppsConfig();
		setFormTouched(false);
	};

	const handleForm = (field, value, parent, child) => {
		const updatedAppsConfig = { ...appsConfig };
		if (child) {
			updatedAppsConfig[parent][child] = updatedAppsConfig[parent][child] || {};
			updatedAppsConfig[parent][child][field] = value;
		} else {
			updatedAppsConfig[parent][field] = value;
		}
		setAppsConfig(updatedAppsConfig);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			await editMerakiWebApps(appsConfig);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Apps saved!",
					timeout: 2000,
					error: false
				}
			});
			setFormTouched(false);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	if (loading && !appsConfig) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"apps-container" + (loading ? " disabled" : "")}>
			<FormContainer cancel={handleCancel} submit={handleSubmit} submitTitle="Save" hideActions={!isFormTouched}>
				<div className="form-content">
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/google-analytics-4.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Google Analytics 4</div>
									<div className="header-subtext">Configurations of Google Analytics</div>
								</div>
							</div>
							<Switch
								title={appsConfig?.analytics?.ga?.enable && "Enabled"}
								checked={appsConfig?.analytics?.ga?.enable}
								clickHandler={() =>
									handleForm("enable", !appsConfig?.analytics?.ga?.enable, "analytics", "ga")
								}
							/>
						</div>
						{appsConfig?.analytics?.ga?.enable && (
							<div className="form-container">
								<div className="form-row row-full">
									<InputWithLabel
										value={appsConfig?.analytics?.ga?.id}
										onChange={(e) => handleForm("id", e.target.value, "analytics", "ga")}
									>
										ID
									</InputWithLabel>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/icon-facebook.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Facebook</div>
									<div className="header-subtext">Configurations of your Facebook account</div>
								</div>
							</div>
							<Switch
								title={appsConfig?.socialLogin?.facebook?.enable && "Enabled"}
								checked={appsConfig?.socialLogin?.facebook?.enable}
								clickHandler={() =>
									handleForm(
										"enable",
										!appsConfig?.socialLogin?.facebook?.enable,
										"socialLogin",
										"facebook"
									)
								}
							/>
						</div>
						{appsConfig?.socialLogin?.facebook?.enable && (
							<div className="form-container">
								<div className="form-row row-full">
									<InputWithLabel
										value={appsConfig?.socialLogin?.facebook?.appID}
										onChange={(e) => handleForm("appID", e.target.value, "socialLogin", "facebook")}
									>
										App ID
									</InputWithLabel>
								</div>
								<div className="form-row row-full">
									<Switch
										title="Enable Pixel analytics"
										checked={!!appsConfig?.socialLogin?.facebook?.pixelAnalytics}
										clickHandler={() =>
											handleForm(
												"pixelAnalytics",
												!appsConfig?.socialLogin?.facebook?.pixelAnalytics,
												"socialLogin",
												"facebook"
											)
										}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/icon-google-config.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Google</div>
									<div className="header-subtext">Configurations of your Google account</div>
								</div>
							</div>
							<Switch
								title={appsConfig?.socialLogin?.google?.enable && "Enabled"}
								checked={appsConfig?.socialLogin?.google?.enable}
								clickHandler={() =>
									handleForm(
										"enable",
										!appsConfig?.socialLogin?.google?.enable,
										"socialLogin",
										"google"
									)
								}
							/>
						</div>
						{appsConfig?.socialLogin?.google?.enable && (
							<div className="form-container">
								<div className="form-row row-full">
									<InputWithLabel
										value={appsConfig?.socialLogin?.google?.clientID}
										onChange={(e) =>
											handleForm("clientID", e.target.value, "socialLogin", "google")
										}
									>
										Client ID
									</InputWithLabel>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/icon-mixpanel.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Mixpanel</div>
									<div className="header-subtext">Configurations of Mixpanel</div>
								</div>
							</div>
							<Switch
								title={appsConfig?.analytics?.mixpanel?.enable && "Enabled"}
								checked={appsConfig?.analytics?.mixpanel?.enable}
								clickHandler={() =>
									handleForm(
										"enable",
										!appsConfig?.analytics?.mixpanel?.enable,
										"analytics",
										"mixpanel"
									)
								}
							/>
						</div>
						{appsConfig?.analytics?.mixpanel?.enable && (
							<div className="form-container">
								<div className="form-row row-full">
									<TagsInputWrapper
										tags={appsConfig?.analytics?.mixpanel?.tokens}
										onChange={(val) => handleForm("tokens", val, "analytics", "mixpanel")}
									>
										Tokens
									</TagsInputWrapper>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/icon-google-tag-manager.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Google Tag Manager</div>
									<div className="header-subtext">Configurations of Google Tag Manager</div>
								</div>
							</div>
							<Switch
								title={appsConfig?.analytics?.gtm?.enable && "Enabled"}
								checked={appsConfig?.analytics?.gtm?.enable}
								clickHandler={() =>
									handleForm("enable", !appsConfig?.analytics?.gtm?.enable, "analytics", "gtm")
								}
							/>
						</div>
						{appsConfig?.analytics?.gtm?.enable && (
							<div className="form-container">
								<div className="form-row row-full">
									<InputWithLabel
										value={appsConfig?.analytics?.gtm?.id}
										onChange={(e) => handleForm("id", e.target.value, "analytics", "gtm")}
									>
										ID
									</InputWithLabel>
								</div>
								<div className="form-row row-full">
									<Switch
										title="Enable Google Analytics 4"
										checked={!!appsConfig?.analytics?.gtm?.ga4Enable}
										clickHandler={() =>
											handleForm(
												"ga4Enable",
												!appsConfig?.analytics?.gtm?.ga4Enable,
												"analytics",
												"gtm"
											)
										}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/icon-freshchat.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Freshchat</div>
									<div className="header-subtext">Configurations of Freshchat</div>
								</div>
							</div>
							<Switch
								title={appsConfig?.chat?.freshchat?.enable && "Enabled"}
								checked={appsConfig?.chat?.freshchat?.enable}
								clickHandler={() =>
									handleForm("enable", !appsConfig?.chat?.freshchat?.enable, "chat", "freshchat")
								}
							/>
						</div>
						{appsConfig?.chat?.freshchat?.enable && (
							<div className="form-container">
								<div className="form-row row-full">
									<InputWithLabel
										value={appsConfig?.chat?.freshchat?.token}
										onChange={(e) => handleForm("token", e.target.value, "chat", "freshchat")}
									>
										Token
									</InputWithLabel>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/icon-branch.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Referral</div>
									<div className="header-subtext">Configurations of Referral via Branch.io</div>
								</div>
							</div>
							<Switch
								title={appsConfig?.referral?.enable && "Enabled"}
								checked={appsConfig?.referral?.enable}
								clickHandler={() => handleForm("enable", !appsConfig?.referral?.enable, "referral")}
							/>
						</div>
						{appsConfig?.misc && (
							<div className="form-container">
								<div className="form-row row-full">
									<InputWithLabel
										value={appsConfig?.referral?.branchKey}
										onChange={(e) => handleForm("branchKey", e.target.value, "referral")}
									>
										Branch key
									</InputWithLabel>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/icons/icon-google-maps.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Google Maps</div>
									<div className="header-subtext">Configurations of Google Maps</div>
								</div>
							</div>
						</div>
						{appsConfig?.misc && (
							<div className="form-container">
								<div className="form-row row-full">
									<InputWithLabel
										value={appsConfig?.misc?.googleMapKey}
										onChange={(e) => handleForm("googleMapKey", e.target.value, "misc")}
									>
										Google maps key
									</InputWithLabel>
								</div>
							</div>
						)}
					</div>
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src="/assets/header-icons/settings.svg" alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">Universal Link</div>
									<div className="header-subtext">
										Setup Link between your website and mobile apps
									</div>
								</div>
							</div>
						</div>
						{appsConfig?.universalLink && (
							<div className="form-container">
								<div className="form-row row-full">
									<Textarea
										value={appsConfig?.universalLink?.android}
										onChange={(e) => handleForm("android", e.target.value, "universalLink")}
									>
										Android - assetlinks.json
									</Textarea>
								</div>
								<div className="form-row row-full">
									<Textarea
										value={appsConfig?.universalLink?.ios}
										onChange={(e) => handleForm("ios", e.target.value, "universalLink")}
									>
										iOS - apple-app-site-association
									</Textarea>
								</div>
							</div>
						)}
					</div>
				</div>
			</FormContainer>
		</div>
	);
};
