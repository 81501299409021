import React, { useState, useEffect, useCallback, useRef } from "react";

// third party
import { connect } from "react-redux";
import moment from "moment";

// clients
import { store } from "../store/configureStore";

// actions
import {
	fetchTopics,
	fetchTrendsChannels,
	fetchTrendsGraph,
	fetchPerformance,
	fetchItemImages
} from "../actions/salesAnalytics";
import { toggleGlobalLoader, fetchBrands } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// components
import Overview from "../components/SalesAnalytics/Overview";
import Trends from "../components/SalesAnalytics/Trends";
import Performance from "../components/SalesAnalytics/Performance";
import { Paginator } from "../components/_commons/Paginator";
import { Filters } from "../components/_commons/Filters";
import { Topbar } from "../components/_commons/Topbar";

// utils
import { trackEvent, triggerSurvey, lS } from "../atlas-utils";

const FORM_TABS = [
	{
		label: "Trends",
		value: "trends"
	},
	{
		label: "Performance",
		value: "performance"
	}
];

const SalesAnalytics = ({
	selectedModule,
	salesAnalyticsState,
	salesAnalyticsPerformance,
	brands,
	isMultibrandEnabled = false
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const { productType, productTypeOptions } = selectedModule;
	const [selectedBrand, setSelectedBrand] = useState(
		isMultibrandEnabled
			? lS.get("brand")
				? lS.get("brand")
				: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }
			: null
	);
	const [itemImages, setItemImages] = useState({});
	const {
		currentDateFilter,
		appliedDateFilter,
		appliedFilters,
		analyticsTopic,
		performanceTopic,
		performanceChannels,
		criteria,
		criteriaValue,
		limit,
		offset,
		search
	} = salesAnalyticsState;
	const [dateDropdownOpen, setDateDropdown] = useState(false);
	let fetchPolicy = "cache-first";
	const isFirstRunFT = useRef(true);
	const isFirstRunFTC = useRef(true);
	const isFirstRunFTG = useRef(true);
	const isFirstRunFPRF = useRef(true);

	useEffect(() => {
		triggerSurvey("survey analytics module");
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	useEffect(() => {
		if (isFirstRunFT.current) {
			isFirstRunFT.current = false;
			return;
		}
		fetchTopics({
			productType,
			appliedDateFilter,
			appliedFilters,
			selectedBrand
		});
	}, [productType, appliedDateFilter, selectedBrand]);

	useEffect(() => {
		if (isFirstRunFTC.current) {
			isFirstRunFTC.current = false;
			return;
		}
		if (productType === "ORDERING") {
			fetchTrendsChannels({
				productType,
				appliedDateFilter,
				analyticsTopic,
				criteria,
				appliedFilters,
				selectedBrand
			});
		}
	}, [productType, appliedDateFilter, analyticsTopic, criteria, selectedBrand]);

	useEffect(() => {
		if (isFirstRunFTG.current) {
			isFirstRunFTG.current = false;
			return;
		}
		fetchTrendsGraph({
			productType,
			appliedDateFilter,
			analyticsTopic,
			criteria,
			criteriaValue,
			appliedFilters,
			selectedBrand
		});
	}, [productType, appliedDateFilter, analyticsTopic, criteria, criteriaValue, selectedBrand]);

	useEffect(() => {
		// if (isFirstRunFPRF.current) {
		// 	isFirstRunFPRF.current = false;
		// 	return;
		// }
		const fetchPerformanceData = async () => {
			const itemIds = await fetchPerformance({
				productType,
				appliedDateFilter,
				performanceTopic,
				analyticsTopic,
				performanceChannels,
				limit,
				offset,
				appliedFilters,
				search,
				selectedBrand
			});
			if (performanceTopic === "ITEMS_TOP_PERFORMING" && itemIds?.length) {
				const variables = {
					limit: limit,
					offset: 0,
					filters: [{ field: "id", value: itemIds?.filter((id) => id !== "N/A")?.join(",") }]
				};
				const itemImages = await fetchItemImages(variables);
				let imgMap = {};
				if (itemImages?.length) {
					itemImages.forEach((item) => {
						imgMap[item.id] = item.imageUrl;
					});
				}
				setItemImages(imgMap);
			}
		};
		fetchPerformanceData();
	}, [
		productType,
		appliedDateFilter,
		performanceTopic,
		analyticsTopic,
		performanceChannels,
		limit,
		offset,
		appliedFilters,
		search,
		selectedBrand
	]);

	const getData = useCallback(async () => {
		const {
			selectedModule: { productType },
			salesAnalyticsState
		} = store.getState();
		const {
			appliedDateFilter,
			analyticsTopic,
			criteria,
			criteriaValue,
			performanceChannels,
			appliedFilters,
			search
		} = salesAnalyticsState;
		store.dispatch(toggleGlobalLoader(true));
		let dataFetchPromises = [];
		const ftpc = fetchTopics(
			{
				productType,
				appliedDateFilter,
				appliedFilters,
				selectedBrand
			},
			fetchPolicy
		);
		dataFetchPromises.push(ftpc);
		const ftgraph = fetchTrendsGraph(
			{
				productType,
				appliedDateFilter,
				analyticsTopic,
				criteria,
				criteriaValue,
				appliedFilters,
				selectedBrand
			},
			fetchPolicy
		);
		dataFetchPromises.push(ftgraph);
		const ftprf = fetchPerformance(
			{
				productType,
				appliedDateFilter,
				performanceTopic,
				analyticsTopic,
				performanceChannels,
				appliedFilters,
				limit,
				offset,
				search,
				selectedBrand
			},
			fetchPolicy
		);
		dataFetchPromises.push(ftprf);
		if (productType === "ORDERING") {
			const ftchn = fetchTrendsChannels(
				{
					productType,
					appliedDateFilter,
					analyticsTopic,
					criteria,
					appliedFilters,
					selectedBrand
				},
				fetchPolicy
			);
			dataFetchPromises.push(ftchn);
		}

		// set tracking related info
		const eventName = "sales_analytics_default_view";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		try {
			await Promise.all(dataFetchPromises);
		} catch (error) {
			console.log(error);
		}
		store.dispatch(toggleGlobalLoader(false));

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		trackEvent(eventName, {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		});
	}, [selectedBrand]);

	useEffect(() => {
		getData();
	}, [fetchPolicy]);

	const handlePagination = useCallback(
		(page) => {
			updateSalesAnalyticsState({
				offset: (page - 1) * limit
			});
		},
		[limit]
	);

	const handlePageSize = useCallback(
		async (field, size) => {
			// set new limit
			if (size && size?.value !== limit) {
				updateSalesAnalyticsState({
					[field]: size.value
				});
			}
		},
		[limit]
	);

	const updateSalesAnalyticsState = useCallback((payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SA_STATE,
			payload
		});
	}, []);

	const handleStoreType = useCallback(
		(storeType) => {
			// keep the date filter same for both the types of stores
			const payload = {
				currentDateFilter,
				appliedDateFilter
			};
			store.dispatch({
				type: ActionTypes.RESET_SA_STATE
			});
			updateSalesAnalyticsState(payload);
			// change store type
			store.dispatch({
				type: "SET_APP_MODULE",
				payload: storeType
			});
		},
		[currentDateFilter, appliedDateFilter]
	);

	const [filterCount, setFilterCount] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [currentFilters, setCurrentFilters] = useState({});
	let filtersOptions = salesAnalyticsState.filters || [];

	const applyFilters = useCallback(
		async (currentFilters) => {
			setFilterCount(Object.entries(currentFilters).length || 0);
			setShowFilters(false);
			updateSalesAnalyticsState({
				appliedFilters: {
					...currentFilters
				}
			});
			fetchPolicy = "network-only";
			getData();
		},
		[appliedFilters]
	);

	const setFilter = useCallback(
		(field, value) => {
			const f = {
				...currentFilters,
				[field]: value
			};
			if (value.value.length === 0) {
				delete f[field];
			}
			setCurrentFilters(f);
		},
		[currentFilters]
	);

	const flipShowFilters = useCallback(() => {
		setShowFilters(!showFilters);
	}, [showFilters]);

	const filterSidebarCloseHandler = useCallback(() => {
		setShowFilters(false);
	}, [showFilters]);

	const clearFilters = useCallback(() => {
		setShowFilters(false);
		setCurrentFilters({});
		applyFilters({});
	}, [applyFilters]);

	const searchHandler = useCallback(async (searchInput) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SA_STATE,
			payload: { offset: 0, search: searchInput }
		});
	}, []);

	const handleBrand = (brand) => {
		setSelectedBrand(brand);
		lS.set("brand", brand);
	};

	return (
		<div className="sales-analytics-container">
			<div className="section-container-common">
				<Filters
					isOpen={showFilters}
					close={filterSidebarCloseHandler}
					apply={() => applyFilters(currentFilters)}
					clear={clearFilters}
					options={filtersOptions}
					currentFilters={currentFilters}
					setFilter={setFilter}
				/>
				<Overview
					updateSalesAnalyticsState={updateSalesAnalyticsState}
					filterCount={filterCount}
					flipShowFilters={flipShowFilters}
					filterActive={showFilters}
					handleStoreType={handleStoreType}
					isMultibrandEnabled={isMultibrandEnabled}
					selectedBrand={selectedBrand}
					handleBrand={handleBrand}
					brands={brands}
				/>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				{formTab === FORM_TABS[0].value && <Trends updateSalesAnalyticsState={updateSalesAnalyticsState} />}
				{formTab === FORM_TABS[1].value && (
					<Performance
						itemImages={itemImages}
						updateSalesAnalyticsState={updateSalesAnalyticsState}
						searchHandler={searchHandler}
						isMultibrandEnabled={isMultibrandEnabled}
						paginator={
							<Paginator
								limit={limit}
								offset={offset}
								count={salesAnalyticsPerformance.data ? salesAnalyticsPerformance.data.count : 0}
								goToPage={handlePagination}
								setPageSize={handlePageSize}
								showPageSize={true}
							/>
						}
					/>
				)}
			</div>
		</div>
	);
};
export default connect((store) => ({
	selectedModule: store.selectedModule,
	salesAnalyticsState: store.salesAnalyticsState,
	salesAnalyticsPerformance: store.salesAnalyticsPerformance,
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(SalesAnalytics);
