import React from "react";
import { Route } from "react-router-dom";
import ItemIssues from "./ItemIssues";
import SpecificItemIssue from "./SpecificItemIssue";

const ItemIssuesRoutes = () => {
	return (
		<>
			<Route exact path="/periscope/item-issues" render={() => <ItemIssues />} />
			<Route exact path="/periscope/item-issues/:itemName" component={SpecificItemIssue} />
		</>
	);
};

export default ItemIssuesRoutes;
