import React from "react";
import { connect } from "react-redux";
import { printCurrency } from "../../../SiteComp";
import { commifyNumbers } from "../../../../atlas-utils";

const RowData = React.memo(
	({ currency = false, rowTitle = "", currIndex = 1, metric = "", login, compareAnalytics }) => {
		const columnData = compareAnalytics.columnData?.[metric].data?.[currIndex];
		const tableData = compareAnalytics.tableData?.[metric]?.[columnData?.valueForDisplay];
		const currencySymbol = login.loggedInbizDetail?.currencySymbol;
		const data = tableData?.[rowTitle];

		return (
			<div className="compare-loading">
				<div style={{ color: "white" }}>
					{data?.loading ? <div className={`shimmer single-bar`} /> : <></>}
					{data?.error ? <div className="single-bar error" /> : ""}
				</div>

				{data?.["value"] >= 0 && !data?.loading && !data?.error && (
					<div className="mini-header title-dark text-end">
						{currencySymbol && currency ? printCurrency(currencySymbol) : ""}
						{commifyNumbers(data?.["value"])}
					</div>
				)}
			</div>
		);
	}
);

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	configItems: store.configItems,
	isMultibrandEnabled: store.login?.loggedInbizDetail?.isMultibrandEnabled,
	login: store.login,
	compareAnalytics: store.compareAnalytics
}))(RowData);
