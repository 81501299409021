import React from "react";

// third party
import { Redirect, Route, useLocation } from "react-router-dom";

// components
import MenuCreate from "./MenuCreate";
import MenusList from "./MenusList";
import MenuEdit from "./MenuEdit";

const MenusContainer = ({ hasAccess = true }) => {
	const location = useLocation();

	return (
		<div className="menus-container">
			<Route exact={location?.pathname.includes("/menus/edit")} path="/menus" component={MenusList} />
			<Route path="/menus/new" component={MenuCreate} />
			<Route path="/menus/edit/:id" component={MenuEdit} />
		</div>
	);
};

export default MenusContainer;
