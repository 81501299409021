import gql from "graphql-tag";

export const GET_CATEGORIES_LIST = gql`
	query getCategoriesList(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$brand: String
	) {
		categories(limit: $limit, offset: $offset, sort: $sort, filters: $filters, brand: $brand) {
			count
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
			objects {
				id
				name
				description
				subcategories {
					id
					name
					description
					sortOrder
					merchantRefId
				}
				sortOrder
				merchantRefId
			}
		}
	}
`;

export const GET_CATEGORY = gql`
	query getCategory($id: Int, $brand: String) {
		category(id: $id) {
			id
			name
			description
			sortOrder
			parent
			timingsGroup {
				id
				title
			}
			merchantRefId
			image
			isActive
			subcategories {
				id
				name
				paginatedItems(brand: $brand) {
					count
				}
			}
			parentCategory {
				id
				name
			}
			paginatedItems(brand: $brand) {
				count
			}
		}
	}
`;

export const GET_CATEGORY_ITEMS = gql`
	query getCategoryItems(
		$id: Int
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$brand: String
	) {
		category(id: $id) {
			id
			paginatedItems(limit: $limit, offset: $offset, sort: $sort, filters: $filters, brand: $brand) {
				limit
				offset
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					itemTitle
					itemDesc
					crmTitle
					imageUrl
					itemPrice
					category
					tags
					foodType
					locationsCount
					imageUrl
					brandsData {
						id
						name
						image
					}
				}
			}
		}
	}
`;

export const CREATE_CATEGORY = gql`
	mutation createCategory(
		$name: String!
		$description: String
		$sortOrder: Int!
		$parent: Int
		$timingsGroup: Int
		$merchantRefId: String
		$image: Upload
	) {
		saveCategory(
			input: {
				name: $name
				description: $description
				sortOrder: $sortOrder
				parent: $parent
				timingsGroup: $timingsGroup
				merchantRefId: $merchantRefId
			}
			image: $image
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				name
				description
				sortOrder
			}
		}
	}
`;

export const EDIT_CATEGORY = gql`
	mutation createCategory(
		$id: Int
		$name: String!
		$description: String
		$sortOrder: Int!
		$parent: Int
		$timingsGroup: Int
		$merchantRefId: String
		$image: Upload
		$deleteImage: Boolean
		$isActive: Boolean
	) {
		saveCategory(
			input: {
				id: $id
				name: $name
				description: $description
				sortOrder: $sortOrder
				parent: $parent
				timingsGroup: $timingsGroup
				merchantRefId: $merchantRefId
				deleteImage: $deleteImage
				isActive: $isActive
			}
			image: $image
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
			}
		}
	}
`;

export const GET_SUBCATEGORIES_LIST = gql`
	query getCategoriesList(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$parent: Int!
	) {
		unassociatedSubCategories(limit: $limit, offset: $offset, sort: $sort, filters: $filters, parent: $parent) {
			count
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
			objects {
				id
				name
				description
				sortOrder
				parent
				parentCategory {
					name
				}
			}
		}
	}
`;

export const ASSOCIATE_DISOCIATE_SUBCATEGORIES = gql`
	mutation associateSubcategories(
		$parentCategoryId: Int!
		$subCategoryIdsToAssociate: [Int]
		$subCategoryIdsToDisassociate: [Int]
	) {
		updateSubcategoryAssociation(
			input: {
				parentCategoryId: $parentCategoryId
				subCategoryIdsToAssociate: $subCategoryIdsToAssociate
				subCategoryIdsToDisassociate: $subCategoryIdsToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

// export const UNASSOCIATED_SUBCATEGORIES = gql`
// 	query unassociatedSubcategories (
// 		$parent: Int!,
// 		$limit: Int,
// 		$offset: Int,
// 		$sort: SortInput,
// 		$filters: [ListFilterArgument]
// 	) {
// 		unassociatedSubCategories (
// 			$parent: ,
// 			$limit: ,
// 			$offset: Int,
// 			$sort: SortInput,
// 			$filters:
// 		) {}
// 	}
