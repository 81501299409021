import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Textarea } from "../_commons/Textarea";
import { Loading } from "../_commons/Loading";

// utils
import { printCurrency } from "../../atlas-utils";

// constant
import { RELOAD_TYPES } from "../../containers/CustomerDetails";

const Wallet = ({ walletBalance, data = {}, loading = false, validations = {}, handleForm, currencySymbol }) => {
	return (
		<div className="customer-wallet">
			{loading && <Loading />}
			<div className="wallet-balance">
				Wallet Balance:{" "}
				<span>
					{printCurrency(currencySymbol)} {walletBalance || 0}
				</span>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.amount}
					type="number"
					onChange={(e) => handleForm("amount", e.target.value ? Number(e.target.value) : null)}
					validationMessage={validations.amount || ""}
					requiredLabel={true}
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={currencySymbol}
				>
					Reload Amount
				</InputWithLabel>
				<SelectFilter
					title="Reload Type"
					options={RELOAD_TYPES}
					field="reloadType"
					currValue={data.reloadType}
					setFilter={handleForm}
					labelKey="label"
					valueKey="value"
					requiredLabel={true}
					isClearable={false}
					validationMessage={validations.reloadType || ""}
				/>
			</div>
			<div className="form-row row-full">
				<Textarea value={data.comments} onChange={(e) => handleForm("comments", e.target.value)}>
					Comments
				</Textarea>
			</div>
		</div>
	);
};
export default Wallet;
