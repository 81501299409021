import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ENTITY_USERS, UPDATE_ENTITY_USERS } from "../../graphql/userEntityAssociations";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
const FILTER_INITIAL_STATE = {
	username: "",
	email: ""
};
const USERS_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const UserEntityAssociations = ({ entityId, isOpen, close, newUsers = false }) => {
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [usersData, setUsersData] = useState(USERS_INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [usersUpdates, setUsersUpdates] = useState({});
	const tableRef = useRef();
	const totalChanges = Object.keys(usersUpdates).length;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				entityId,
				limit,
				offset,
				sort: {
					field: "first_name",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_ENTITY_USERS,
				variables,
				fetchPolicy: "no-cache"
			});
			setUsersData(resp.data.groupUserAssociations);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [entityId, limit, offset, appliedFilters]);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const syncData = useCallback(() => {
		setUsersData({
			...usersData,
			objects: usersData.objects.map((user) => {
				if (usersUpdates[user.id] !== undefined) {
					return {
						...user,
						isAssociated: usersUpdates[user.id]
					};
				}
				return user;
			})
		});
		setUsersUpdates({});
	}, [usersData, usersUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setUsersData(USERS_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setUsersUpdates({});
			setIsCheckedAll(false);
			close(refresh);
		},
		[close]
	);

	const handleUpdate = useCallback(async () => {
		try {
			setConfirmLoading(true);
			const variables = {
				entityId,
				usersToAssociate: [],
				usersToDisassociate: []
			};
			for (let id in usersUpdates) {
				if (usersUpdates[id]) {
					variables.usersToAssociate.push(parseInt(id));
				} else {
					variables.usersToDisassociate.push(parseInt(id));
				}
			}
			const resp = await client.mutate({
				mutation: UPDATE_ENTITY_USERS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateGroupUsers.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "User association updated!",
						timeout: 2000,
						error: false
					}
				});
				syncData();
				handleClose(true);
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateGroupUsers.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [entityId, usersUpdates, fetchData, syncData, handleClose]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setUsersUpdates({
			...usersUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		usersData.objects.forEach((loc) => {
			updates[loc.id] = toCheckAll;
		});
		setUsersUpdates({
			...usersUpdates,
			...updates
		});
	};

	return (
		<div className="user-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Users"
				subTitle={`Associate/disassociate users for this Role`}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
						{totalChanges === 0 ? "Update" : `Update ${totalChanges} User(s)`}
					</Button>
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-container">
						{!newUsers && (
							<InputWithLabel
								value={currFilters.username}
								onChange={(e) => setFilter("username", e.target.value)}
								placeholder="Enter Username"
							>
								Username
							</InputWithLabel>
						)}
						<InputWithLabel
							value={currFilters.email}
							onChange={(e) => setFilter("email", e.target.value)}
							placeholder="Enter Email"
						>
							Email
						</InputWithLabel>
					</div>
					<Table
						data={usersData.objects}
						loading={loading}
						handleCheck={handleCheck}
						usersUpdates={usersUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						newUsers={newUsers}
					/>
					<Paginator limit={limit} offset={offset} count={usersData.count} goToPage={handlePagination} />
				</div>
			</FormSidebar>
		</div>
	);
};
export default UserEntityAssociations;

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	usersUpdates,
	newUsers
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container users-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						newUsers={newUsers}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							usersUpdates={usersUpdates}
							newUsers={newUsers}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Users found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, newUsers }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Name">
				Name
			</CheckBox>
		</div>
		<div className={`at-table-cell at-table-header at-header-text email`}>Email</div>
		{!newUsers && <div className={`at-table-cell at-table-header at-header-text username`}>Username</div>}
	</div>
);

export const TableList = ({
	id,
	fullName,
	isAssociated,
	username,
	email,
	style,
	handleCheck,
	usersUpdates,
	newUsers
}) => {
	const status = usersUpdates[id] === undefined ? isAssociated : usersUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name">
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} title={fullName || "--"}>
					{fullName || "--"}
				</CheckBox>
			</div>
			<div className="at-table-cell at-cell-text email">{email}</div>
			{!newUsers && <div className="at-table-cell at-cell-text username">{username}</div>}
		</animated.div>
	);
};
