import React from "react";
import { Modal } from "./Modal";

const ImageSizeWarningModal = ({ isOpen, close, handleSubmit }) => {
	return (
		<Modal
			isOpen={isOpen}
			close={close}
			submitAction={handleSubmit}
			showCancelAction
			showSubmitAction
			submitTitle="Try a Different Image"
			classes="image-size-warning--modal"
			title="Oops!"
			submitButtonWidth="200"
		>
			<div className="warning-message">The image is greater than 1MB. Please try a smaller image.</div>
		</Modal>
	);
};
export default ImageSizeWarningModal;
