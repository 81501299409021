import React from "react";
import SkeletonLoader from "../SkeletonLoader";

const ActivityLogTableLoading = ({ columns, refreshBtn, error = false }) => {
	const displayErrorDiv = (
		<div className="error-state">
			<div className="load-chart-div">
				<img className="load-chart-error-icon" src="/assets/periscope/loading-error.svg" />
				<div className="load-chart-info">
					<div className="chart-info">
						<span className="chart-main-text">Could not load this chart!</span>
						<span className="chart-para-info">
							We are facing some issues in fetching this data for you.
						</span>
					</div>
					<div className="try-again">
						<img className="load-again-img" src="/assets/periscope/load-again.svg" />
						<span className="try-again-text" onClick={() => refreshBtn()}>
							Try Again
						</span>
					</div>
				</div>
			</div>
		</div>
	);
	return (
		<tbody className={`sample-table  ${error == true ? "stop-moving" : ""}`} style={{ position: "relative" }}>
			{error ? displayErrorDiv : ""}
			{[...Array(7)].map((row, index) => {
				return (
					<tr key={index}>
						{columns.map((column) => {
							return (
								<th key={column.Header} style={{ padding: "16px 24px" }}>
									<SkeletonLoader />
								</th>
							);
						})}
					</tr>
				);
			})}
		</tbody>
	);
};

export default ActivityLogTableLoading;
