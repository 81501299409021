import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import ResetPass from "./ResetPass";

// constants
const ULE_LOGIN_URL = process.env.REACT_APP_ULE_LOGIN;

const LoginForm = ({
	data = {},
	handleChange,
	removeValidationState,
	handleLogin,
	loading = false,
	setLoading,
	resetPass = false,
	handleResetPass,
	phoneNumberValidation
}) => {
	return (
		<div className="login-form">
			{!resetPass ? (
				<React.Fragment>
					<div className="form-header">Login</div>
					<div className="form-container">
						<InputWithLabel
							classes="login-text-input"
							placeholder="hello@example.com / +919876543210"
							value={data?.email}
							name="email"
							onChange={(e) => handleChange("email", e.target.value)}
							onFocus={(e) => removeValidationState(e, "email_validation_message")}
							validationMessage={
								data?.email_validation_message ||
								(phoneNumberValidation?.endResultType === "failure"
									? "Enter a valid phone number including ISD code"
									: "")
							}
							showLoading={phoneNumberValidation?.loading}
							showEndResult={phoneNumberValidation?.showPhoneNumberValidation}
							endResultType={phoneNumberValidation?.endResultType}
						>
							Email/Phone
						</InputWithLabel>
						<div className="password-container">
							<InputWithLabel
								classes="login-text-input Pos(r)"
								placeholder="Password"
								value={data?.password}
								name="password"
								type="password"
								onChange={(e) => handleChange("password", e.target.value)}
								onFocus={(e) => removeValidationState(e, "password_validation_message")}
								validationMessage={data?.password_validation_message || ""}
								onEnter={() => handleLogin()}
							>
								Password
							</InputWithLabel>
							<div className="forgot-pass font--small" onClick={() => handleResetPass(true)}>
								Forgot password?
							</div>
						</div>
						<div
							className="g-recaptcha"
							data-callback="handleRecaptcha"
							data-expired-callback="handleRecaptchaExpiry"
							data-sitekey={window.RECAPTCHA_SITE_KEY}
						></div>
						<div className="required-label">
							<div>{data?.recaptcha_validation_message || ""}</div>
						</div>
						<Button
							clickHandler={() => handleLogin()}
							classes={"login-submit" + (loading ? " disabled" : "")}
							loading={loading}
						>
							Login
						</Button>
					</div>
					<div className="divider">
						<span>or</span>
					</div>
					<a href={ULE_LOGIN_URL}>
						<Button type="secondary" classes={(loading ? "disabled" : "") + " try-new-login"}>
							Try New Login Experience
						</Button>
					</a>
				</React.Fragment>
			) : (
				<ResetPass
					switchTab={() => handleResetPass(false)}
					data={data}
					handleChange={handleChange}
					loading={loading}
					setLoading={setLoading}
					phoneNumberValidation={phoneNumberValidation}
				/>
			)}
		</div>
	);
};
export default LoginForm;
