import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/CategoryEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, parseSuperstructErrorMessages, scroll, trackEvent } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// third party
import { type, string, number, min, validate } from "superstruct/umd/superstruct";
import Compressor from "compressorjs";
import { connect } from "react-redux";

// actions
import {
	fetchTimingGroups,
	fetchParentCategories,
	resetParentCategories,
	fetchParentCategoryIds
} from "../actions/actions";
import { fetchCategoriesList } from "../actions/categories";

// graphql
import { CREATE_CATEGORY } from "../graphql/categories";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../client-config";

export const CATEGORY_FORM_SCHEMA = type({
	name: string(),
	sortOrder: min(number(), 0),
	merchantRefId: string()
});

const CategoryCreate = ({ hasAccess = false, selectedCategory, brands }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [image, setImage] = useState(undefined);
	const [error, setError] = useState({});
	const [data, setData] = useState({
		name: "",
		description: "",
		merchantRefId: "",
		sortOrder: 0,
		parent: undefined,
		timingsGroup: undefined,
		image: undefined
	});

	useEffect(() => {
		fetchTimingGroups();
		fetchParentCategories();
		setTimeout(() => setFormState(true), 50);
		return () => {
			resetParentCategories();
		};
	}, []);

	const handleCancel = () => {
		setFormState(false);
		if (selectedCategory) {
			fetchCategoriesList(selectedCategory.id, brands.selectedBrand);
		} else {
			fetchCategoriesList(null, brands.selectedBrand);
		}
		fetchParentCategoryIds();
		setTimeout(() => {
			history.push("/categories");
		}, 100);
	};

	const handleForm = (field, value) => {
		const newData = {
			...data,
			[field]: value
		};
		setData(newData);
		validateData(newData);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const validateData = (data) => {
		const [error] = validate(data, CATEGORY_FORM_SCHEMA);
		if (error) {
			setError(parseSuperstructErrorMessages(error));
			return false;
		}
		setError({});
		return true;
	};

	const handleSubmit = async () => {
		if (!validateData(data)) {
			return;
		}
		setConfirmLoading(true);
		try {
			const variables = {
				name: data.name,
				description: data.description,
				sortOrder: data.sortOrder,
				merchantRefId: data.merchantRefId || "-1"
			};
			if (image) {
				variables.image = image;
			}
			if (data.parent) {
				variables.parent = parseInt(data.parent.id);
			}
			if (data.timingsGroup) {
				variables.timingsGroup = parseInt(data.timingsGroup.id);
			}
			const resp = await client.mutate({
				mutation: CREATE_CATEGORY,
				variables
			});
			if (resp.data.saveCategory.status.success) {
				trackEvent(TRACKING_EVENT_NAMES.NEW_CATEGORY_CREATION, {
					status: TRACKING_STATUS.SUCCESS
				});

				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Category created",
						timeout: 5000,
						error: false
					}
				});
				history.push(`/categories/edit/${resp.data.saveCategory.object.id}?reloadCl=true`);
			} else {
				trackEvent(TRACKING_EVENT_NAMES.NEW_CATEGORY_CREATION, {
					status: TRACKING_STATUS.FAILURE
				});

				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveCategory.status.messages));
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.NEW_CATEGORY_CREATION, {
				status: TRACKING_STATUS.FAILURE
			});

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	const handleImage = (files) => {
		const imgFile = files[0];
		if (imgFile) {
			const image = new Image();
			image.src = window.URL.createObjectURL(imgFile);

			// proceed only if the uploaded file is a valid image
			image.onload = async function () {
				if (imgFile.size < 2097152) {
					setImage(imgFile);
					if (!isFormTouched) {
						setFormTouched(true);
					}
				} else {
					setConfirmLoading(true);
					const result = await new Promise((resolve, reject) => {
						new Compressor(imgFile, {
							quality: 0.6,
							maxWidth: 4000,
							success: resolve,
							error: reject
						});
					});
					if (result && result.size < 10475200) {
						setImage(result);
						setConfirmLoading(false);
						if (!isFormTouched) {
							setFormTouched(true);
						}
					} else {
						setConfirmLoading(false);
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Image size is too large, please keep it below 10 MB",
								timeout: 3000,
								error: true
							}
						});
					}
				}
			};

			// handle error if uploaded file is not an image
			image.onerror = function () {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Please upload a valid image.",
						timeout: 3000,
						error: true
					}
				});
			};
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Upload an image from your current device",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleDeleteImage = (e) => {
		e.stopPropagation();
		setImage(undefined);
	};

	const scrollDown = () => {
		setTimeout(() => {
			const formContainer = document.getElementsByClassName("form-container")[0];
			if (formContainer) {
				scroll({ top: formContainer.scrollHeight, left: 0 }, formContainer);
			}
		}, 275);
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			disabled={!isFormTouched}
			title="Category"
			subTitle="Create a new category"
			loading={confirmLoading}
			submitTitle="Create"
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<BasicInfo
					data={data}
					handleForm={handleForm}
					validations={error.fields || {}}
					image={image}
					showDelete={true}
					handleDeleteImage={handleDeleteImage}
					handleImage={handleImage}
					scrollDown={scrollDown}
					readOnly={!hasAccess}
					showParentField={true}
				/>
			</div>
		</FormSidebar>
	);
};
export default connect((store) => ({
	selectedCategory: store?.categoriesList?.selectedCategory,
	brands: store.configItems.brands
}))(CategoryCreate);
