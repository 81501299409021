import { useState, useEffect } from "react";

// actions
import { fetchMerakiWebVersions, restoreMerakiWebVersion } from "../actions/merakiWeb";

export const useMerakiWebVersions = () => {
	const [loading, setLoading] = useState(true);
	const [versions, setVersions] = useState([]);
	const [limit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [count, setCount] = useState(0);

	const fetchData = async () => {
		setLoading(true);
		try {
			const params = {
				limit,
				offset
			};
			const data = await fetchMerakiWebVersions(params);
			setVersions(data.data);
			setCount(data.total);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const restoreVersion = async (number) => {
		setLoading(true);
		try {
			await restoreMerakiWebVersion(number);
			await fetchData();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [offset]);

	return [loading, versions, count, restoreVersion, limit, offset, setOffset];
};
