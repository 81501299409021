import gql from "graphql-tag";

export const GET_ITEM_RULES_LIST = gql`
	query getItemRulesList($menuId: String!, $itemId: String!) {
		item(menuId: $menuId, itemId: $itemId) {
			applicableRules {
				id
				entityId
				entityName
				locations {
					locationId
					locationName
				}
				name
				operations {
					operator
					input {
						value
						type
						format
					}
					field
				}
				platforms
				status
			}
		}
	}
`;

export const GET_OPTION_RULES_LIST = gql`
	query getOptionRulesList($menuId: String!, $optionId: String!) {
		option(menuId: $menuId, optionId: $optionId) {
			applicableRules {
				id
				entityId
				entityName
				locations {
					locationId
					locationName
				}
				name
				operations {
					operator
					input {
						value
						type
						format
					}
					field
				}
				platforms
				status
			}
		}
	}
`;
