import React from "react";

export const Switch = ({
	classes,
	leftlabel,
	checked,
	clickHandler,
	rightlabel,
	requiredLabel,
	title,
	validationMessage,
	readOnly = false,
	tooltip = null,
	showCustomTooltip = false,
	tooltipInfo = "",
	tooltipPosition = "up-left"
}) => (
	<div className={"at-switch-cnt " + (classes || "")} title={tooltip}>
		{title && (
			<div className={"meta-info"}>
				<span className={requiredLabel ? " required-sign" : ""}>{title}</span>
				{showCustomTooltip && tooltipInfo && (
					<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
						<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
					</span>
				)}
			</div>
		)}
		<div>
			<span className={leftlabel ? "leftlabel" : ""}>{leftlabel}</span>
			<div
				className={"at-switch " + (checked ? "active " : "") + (readOnly ? "read-only " : "")}
				onClick={clickHandler || void 0}
			>
				<div className="switch-rail"></div>
				<div className="switch-ball" style={readOnly ? { backgroundColor: "#eaeaea" } : {}}></div>
			</div>
			<span className={rightlabel ? "rightlabel" : ""}>{rightlabel}</span>
		</div>
		{validationMessage && <div className="validation-message">{validationMessage}</div>}
	</div>
);
