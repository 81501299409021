import React from "react";

// third party
import { Redirect, Route, useLocation } from "react-router-dom";

// components
import SectionList from "./SectionList";
import CategoryEdit from "./CategoryEdit";

const SectionsContainer = ({ hasAccess = true }) => {
	const location = useLocation();

	return (
		<div className="menu-sections-container">
			<Route path="/sections" component={SectionList} />
			<Route exact path="/sections/edit/:id" component={CategoryEdit} />
		</div>
	);
};

export default SectionsContainer;
