import React, { Component } from "react";

// components
import { Header } from "../components/CatalogueVerificationList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { Topbar } from "../components/_commons/Topbar";
import { BackNavigation } from "../components/_commons/BackNavigation";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// clients
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchCatalogueVerificationList } from "../actions/catalogueVerification";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll, adjustNestedContainer, msaagesArrayToHtml, formatDate } from "../atlas-utils";

// config
import { NESTED_ENTITY_TYPES } from "../client-config";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
export const ENTITY_TYPE_MAP = {
	BizItem: "item",
	BizItemCategory: "category",
	BizItemOptionGroup: "modifier group",
	BizItemOption: "modifier",
	BizItemTag: "tag",
	Tax: "tax",
	Charge: "charge"
};

export const messageArrayToText = (mArray, entityId = "", expandedDesc = {}, handleReadMore, sliceLength = 120) => {
	if (!mArray || mArray.length === 0) {
		return "--";
	}
	return mArray.map((message, i) => (
		<div key={i}>
			{message.length > sliceLength ? (
				<React.Fragment>
					<span>
						{!expandedDesc[String(entityId) + String(i)] ? message.slice(0, sliceLength) + "... " : message}
					</span>
					<span
						className="read-more"
						onClick={() =>
							handleReadMore(
								String(entityId) + String(i),
								expandedDesc[String(entityId) + String(i)] ? false : true
							)
						}
					>
						{expandedDesc[String(entityId) + String(i)] ? "Read less" : "Read more"}
					</span>
				</React.Fragment>
			) : (
				message
			)}
		</div>
	));
};

const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, a, cs, handleNestedEntity) => (
			<div className="at-table-cell at-cell-text name" title={record.name} key={i}>
				{record.entityId ? (
					<a
						role="button"
						className="link-text"
						onClick={() =>
							handleNestedEntity(
								true,
								NESTED_ENTITY_TYPES[
									NESTED_ENTITY_TYPES.indexOf(ENTITY_TYPE_MAP[record.entity].split(" ").join("-"))
								],
								parseInt(record.entityId)
							)
						}
					>
						{record.name || record.entityId}
					</a>
				) : (
					<div>
						<b>{record.name || "--"}</b>
					</div>
				)}
				<div className="text--light desc-text" title={record.crmTitle}>
					{record.crmTitle && `CRM Title: ${record.crmTitle}`}
				</div>
			</div>
		)
	},
	{
		name: "Type",
		field: "type",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text type" key={i}>
				{record.entityId ? ENTITY_TYPE_MAP[record.entity] || record.entity || "--" : "Misc"}
			</div>
		)
	},
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text platform" key={i}>
				{record.platformName || "--"}
			</div>
		)
	},
	{
		name: "Description",
		field: "description",
		render: (record, i, ar, cs, ht, cb, rest) => (
			<div className="at-table-cell at-cell-text description" key={i}>
				{messageArrayToText(record.messages, record.entityId, rest.expandedDesc, rest.handleReadMore)}
			</div>
		)
	}
];

@connect((store) => ({
	catalogueVerificationList: store.catalogueVerificationList,
	catalogueVerificationListState: store.catalogueVerificationListState,
	configItems: store.configItems,
	access: store.login.loginDetail.access,
	isPrismEnabled: store.login.loggedInbizDetail.modulesEnabled.includes("PRISM")
}))
export class CatalogueVerificationList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			expandedDesc: {},
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		const data = this.props.catalogueVerificationList.data;
		if ((!data.location.id || !data.platform) && this.props.isPrismEnabled) {
			this.props.history.push("/locations");
			return;
		}
		// set tracking related info
		const eventName = "catalogue_verification_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch Catalogue verification list
		await fetchCatalogueVerificationList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatecatalogueVerificationListState = (payload) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatecatalogueVerificationListState({
			currentFilters: this.props.catalogueVerificationListState.appliedFilters
		});
	};

	handleFormTab = async (tab) => {
		if (this.props.catalogueVerificationList.data.selectedTab !== tab.value) {
			store.dispatch({
				type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
				payload: { selectedTab: tab.value }
			});
			this.setFilter("error_status", tab.value);
			setTimeout(() => this.applyFilters(), 100);
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
			fetchCatalogueVerificationList();
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.catalogueVerificationListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatecatalogueVerificationListState({
			currentFilters
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchCatalogueVerificationList(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatecatalogueVerificationListState({
			appliedFilters: {
				...this.props.catalogueVerificationListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "catalogue_verification_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchCatalogueVerificationList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().catalogueVerificationListState;
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updatecatalogueVerificationListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				store.dispatch({
					type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
					payload: { selectedTab: "error" }
				});
				await fetchCatalogueVerificationList();
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.catalogueVerificationListState;
		const offset = (page - 1) * limit;
		this.updatecatalogueVerificationListState({
			offset
		});
		// fetch new Catalogue verification list
		fetchCatalogueVerificationList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.catalogueVerificationListState;
		if (size && size?.value !== limit) {
			this.updatecatalogueVerificationListState({
				[field]: size.value
			});
			// fetch new Catalogue verification list
			await fetchCatalogueVerificationList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatecatalogueVerificationListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchCatalogueVerificationList();
	};

	updateStoresLookup = (id, title) => {
		this.setState({
			storesLookup: {
				...this.state.storesLookup,
				[id]: title
			}
		});
	};

	handleReadMore = (id, state = false) => {
		this.setState({
			expandedDesc: {
				...this.state.expandedDesc,
				[id]: state
			}
		});
	};

	renderTab = (tab) => {
		return (
			<React.Fragment>
				<div className={"count " + tab.value}>{tab.count}</div>
				<div className="text">{tab.label}</div>
			</React.Fragment>
		);
	};

	renderCustomPlaceholder = () => {
		return (
			<React.Fragment>
				<img src="/assets/icons/catalogue-verification.svg" />
				<div className="placeholder-text">
					Catalogue verification successful.{" "}
					{`No ${this.props.catalogueVerificationList.data.selectedTab}s found.`} You are all set!
				</div>
			</React.Fragment>
		);
	};

	render() {
		const {
			catalogueVerificationList,
			catalogueVerificationListState,
			configItems,
			access = {},
			isPrismEnabled = false
		} = this.props;
		const { limit, offset, currentFilters, appliedFilters, sortedField } = catalogueVerificationListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (f === "only_platform_messages" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f]?.value && currentFilters[f]?.value !== "") {
					filterCount++;
				}
			}
		}

		const filterOptions = catalogueVerificationList.data.filters.filter((f) => f.field !== "error_status");

		const currLocation = {
			pathname: this.props.location.pathname,
			title: "Catalogue Verification"
		};
		const prevLocation = {
			pathname: "/locations",
			title: "Locations"
		};

		return (
			<div>
				<BackNavigation prevLocation={prevLocation} currLocation={currLocation} />
				<div
					className="catalogue-verification-section section-container-common"
					ref={(ref) => (this.tableRef = ref)}
				>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						searchKeywords={catalogueVerificationList.data.searchKeywords}
						searchFieldSelected={catalogueVerificationList.data.searchFieldSelected}
						searchFieldValue={catalogueVerificationList.data.searchFieldValue}
						handleSearchField={this.handleSearchField}
						setFilter={this.setSearchFilter}
						applySearchFilter={this.applySearchFilter}
					/>
					{isPrismEnabled && (
						<div className="info-text">
							Displaying <span>{catalogueVerificationList.data.platform}</span> catalogue verification
							issues, for <span>{catalogueVerificationList.data.location.name || "location"}</span>{" "}
							{catalogueVerificationList.data.location.publish ? (
								<React.Fragment>
									for publish requested on{" "}
									<span>
										{formatDate(catalogueVerificationList.data.location.publish, "DD MMM, YYYY")}
									</span>{" "}
									at{" "}
									<span>
										{formatDate(catalogueVerificationList.data.location.publish, "hh:mm A")}
									</span>
									.
								</React.Fragment>
							) : (
								""
							)}
						</div>
					)}
					<Topbar
						tabs={catalogueVerificationList.data.formTabs}
						selectedTab={catalogueVerificationList.data.selectedTab}
						switchTab={(tab) => this.handleFormTab(tab)}
						renderTab={this.renderTab}
						isStickyOnTop={true}
						classes="custom-topbar"
					/>
					<CommonTable
						loading={catalogueVerificationList.loading}
						data={catalogueVerificationList.data.objects || []}
						columns={columns}
						sortList={this.sortList}
						sortedField={sortedField}
						handleTask={this.handleNestedEntity}
						expandedDesc={this.state.expandedDesc}
						handleReadMore={this.handleReadMore}
						customPlaceholder={
							appliedFilters["channel"] &&
							!appliedFilters["entity_type"]?.value &&
							!appliedFilters["only_platform_messages"]
								? !catalogueVerificationList.data.errorCount &&
								  !catalogueVerificationList.data.warningCount
									? this.renderCustomPlaceholder()
									: ""
								: catalogueVerificationList.data.status !== "warning" &&
								  catalogueVerificationList.data.status !== "error"
								? this.renderCustomPlaceholder()
								: ""
						}
						classes="catalogue-verification-list-table-container"
						placeholderClasses="placeholder-img"
						content={
							catalogueVerificationList.data.selectedTab === "error"
								? "Catalogue errors"
								: "Catalogue warnings"
						}
						hideColumns={isPrismEnabled ? ["platform"] : []}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={catalogueVerificationList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
					<NestedEntityContainer
						show={this.state.nestedEntity.show}
						type={this.state.nestedEntity.type}
						id={this.state.nestedEntity.id}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
						isNested={false}
						isForeignSource={true}
					/>
				</div>
			</div>
		);
	}
}
