import React from "react";

export const OutsideClick = (ComposedComponent) => {
	return class extends React.Component {
		constructor(props) {
			super(props);
			this.nodeRef = React.createRef();
		}

		handleClick = (e) => {
			if (this.nodeRef?.current && this.nodeRef?.current?.handleOutsideClick) {
				if (typeof this.nodeRef.current.handleOutsideClick === "function") {
					this.nodeRef.current.handleOutsideClick();
				}
			} else if (this.nodeRef.current && !this.nodeRef.current.contains(e.target)) {
				if (typeof this.props.handleOutsideClick === "function") {
					this.props.handleOutsideClick();
				}
			}
		};

		componentDidMount() {
			const { clickEvent = "click" } = this.props;
			window.addEventListener(clickEvent, this.handleClick, false);
		}

		componentWillUnmount() {
			const { clickEvent = "click" } = this.props;
			window.removeEventListener(clickEvent, this.handleClick, false);
		}

		render() {
			const { clickEvent, handleOutsideClick, ...rest } = this.props;
			return <ComposedComponent {...rest} nodeRef={this.nodeRef} />;
		}
	};
};
