import React, { useRef } from "react";
import { Button } from "../_commons/Button";
import Image from "../_commons/Image";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import MonogramGenerator from "../_commons/MonogramGenerator";

const BasicInfo = ({
	basicInfo,
	setBasicInfo,
	setFormTouched,
	imageBase64,
	removeImageData,
	onImageChange,
	inputRef,
	previewCanvasRef,
	imgSrc,
	isCropModalOpen,
	readOnly = true
}) => {
	const handleNameChange = (e) => {
		const value = e.target.value;
		// return
		setBasicInfo((current) => ({
			...current,
			name: value
		}));
		setFormTouched(true);
	};

	const handleImageReplace = () => {
		inputRef.current.click();
	};

	// const handleFileChange = e => {
	// 	setFile(e.target.files[0])
	// 	setFormTouched(true)
	// }

	return (
		<div className="basic-info">
			<div className="biz-logo-container">
				<div className="logo">
					<div className="logo-header">Logo</div>
					{isCropModalOpen || previewCanvasRef.current ? (
						<canvas
							ref={previewCanvasRef}
							style={{
								objectFit: "contain",
								width: "160px",
								height: "160px",
								borderRadius: "2px"
							}}
						/>
					) : !imageBase64 && !basicInfo.image ? (
						basicInfo.name ? (
							<MonogramGenerator value={basicInfo.name} size="large" />
						) : (
							<div className="empty-logo-container">
								<img
									src="/assets/icons/icon-empty-img.svg"
									alt="add image"
									className="empty-biz-logo"
								/>
							</div>
						)
					) : (
						<Image
							src={imageBase64 ? imageBase64 : basicInfo.image}
							className="biz-logo"
							alt="Business name"
						/>
					)}
				</div>
				<Button clickHandler={handleImageReplace} classes={readOnly ? "disabled" : ""}>
					{basicInfo.image ? "Replace" : "Upload Image"}
				</Button>
				{basicInfo && basicInfo?.image && !readOnly && (
					<Button clickHandler={removeImageData} type="secondary" classes="no-border">
						Remove
					</Button>
				)}
				<input type="file" accept="image/*" className="D(n)" ref={inputRef} onChange={onImageChange} />
			</div>
			<div className="biz-name">
				<InputWithLabel value={basicInfo?.name} onChange={handleNameChange} readOnly={readOnly}>
					Name
				</InputWithLabel>
			</div>
		</div>
	);
};
export default BasicInfo;
