import React, { useEffect, useState } from "react";

// store
import { store } from "../store/configureStore";
import { closeOnboardingWsLink } from "../client-onboarding";

// third party
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

// components
import Onboardingnextup, { NextUpStates } from "@urbanpiper-engineering/horizon/dist/cells/onboardingnextup";
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import StatusCard, { StatusCardState } from "@urbanpiper-engineering/horizon/dist/cells/statuscard";
import Button, { ButtonVariant, ButtonSize } from "@urbanpiper-engineering/horizon/dist/atoms/button";
import { HorizonButton } from "@urbanpiper-engineering/horizon";
import ProgressBar from "@urbanpiper-engineering/horizon/dist/atoms/progressbar";
import OnboardingSetupHeader from "../components/OnboardingCommon/OnboardingSetupHeader";

// utils
import history from "../history";

// services
import NotificationServices from "../services/NotificationService";

// actions
import { ActionTypes } from "../actions/_types";
import { handleOnboardingFlowAction } from "../actions/actions";
import { fetchOnboardingFlows, unsubscribeToFlowSteps } from "../actions/onboarding";

// constants
import { ATLAS_ONBOARDING_STEPS } from "../client-config";

const ALL_STEPS_LIST = [
	{
		id: ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS,
		state: StatusCardState.UnChecked,
		title: "Complete company details",
		subTitle: "Add your restaurant's basic information",
		onCtaClick: () => {
			history.push("/profile");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.CREATE_BRANDS,
		state: StatusCardState.UnChecked,
		title: "Create brands",
		subTitle: "Add all your restaurant's brands",
		onCtaClick: () => {
			history.push("/onboarding/brands");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS,
		state: StatusCardState.UnChecked,
		title: "Configure locations",
		subTitle: "Add the locations where your restaurant operates",
		onCtaClick: () => {
			history.push("/onboarding/locations");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS,
		state: StatusCardState.UnChecked,
		title: "Configure menus",
		subTitle: "Create menus for your locations",
		onCtaClick: () => {
			history.push("/onboarding/menu");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.TAXES_CHARGES,
		state: StatusCardState.UnChecked,
		title: "Configure taxes and charges",
		subTitle: "Configure all taxes and charges that are applicable on your menu items",
		onCtaClick: () => {
			history.push("/taxes/new");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS,
		state: StatusCardState.UnChecked,
		title: "Go live on all locations",
		subTitle: "Go live on ordering platforms with all your locations",
		onCtaClick: () => {
			history.push("/hub/integrations");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.INVITE_USERS,
		state: StatusCardState.UnChecked,
		title: "Invite users",
		subTitle: "Invite staff to your account",
		onCtaClick: () => {
			history.push("/unified-access/invite");
		}
	}
];

const ONBOARDING_HELPER_LIST = [
	{
		title: "How does DSP sync and POS services work?",
		ctaText: "Watch video",
		ctaUrl: "/"
	},
	{
		title: "How to set up locations and menus manually?",
		ctaText: "Watch video",
		ctaUrl: "/"
	},
	{
		title: "View the list of all our Frequently Asked Questions",
		ctaText: "View FAQs",
		ctaUrl: "/piper-academy"
	}
];

function Onboarding({ isLoading, flowData, flowSteps }) {
	const [loading, setLoading] = useState(false);
	const [allStepsList, setAllStepsList] = useState([]);
	const onboardingSteps = (flowSteps ?? [])?.filter((step) => ATLAS_ONBOARDING_STEPS[step?.step?.name]);
	const completedSteps = onboardingSteps?.filter((step) => step?.status === "complete")?.length || 0;
	const totalSteps = allStepsList.length;
	const [nextUpState, setNextUpState] = useState({
		state: NextUpStates.COMPLETE_COMPANY_DETAILS,
		onCTAClick: () => {}
	});
	const showFullNav = flowSteps?.find((step) => step?.step?.name === "SHOW_FULL_NAV") ? true : false;
	const firstActiveStep = onboardingSteps?.find((step) => step.status === "inprogress");

	useEffect(() => {
		if (firstActiveStep?.step?.name) {
			setNextUpState({
				state: NextUpStates[firstActiveStep.step.name],
				onCTAClick: ALL_STEPS_LIST.find((step) => step.id === firstActiveStep.step.name)?.onCtaClick
			});
		}
	}, [firstActiveStep]);

	useEffect(() => {
		if (flowData?.steps?.length) {
			let _allStepsList = cloneDeep(ALL_STEPS_LIST);
			const validSteps = flowData?.steps?.map((step) => step.name) || [];
			_allStepsList = _allStepsList.filter((step) => validSteps.includes(step.id));
			setAllStepsList(_allStepsList);
		}
	}, [flowData]);

	const getStepState = (stepId) => {
		if (flowSteps?.length) {
			const step = flowSteps.find((step) => step?.step?.name === stepId);
			if (step) {
				return step.status === "active"
					? StatusCardState.UnChecked
					: step.status === "inprogress"
					? StatusCardState.Pending
					: StatusCardState.Checked;
			}
		}
		return StatusCardState.UnChecked;
	};

	const getInfoComponent = (stepId) => {
		if (flowSteps?.length) {
			const step = flowSteps.find((step) => step?.step?.name === stepId);
			switch (step?.step?.name) {
				case ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS:
					return step?.status === "complete" ? (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-complete-chip" variant={TextVariant.BODY1}>
								Completed
							</Text>
						</div>
					) : null;

				case ATLAS_ONBOARDING_STEPS.CREATE_BRANDS:
					const numberOfBrandsCreated = step?.metadata?.completed || 0;
					return numberOfBrandsCreated ? (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
								{/* <div className="step-progress-indicator">indicator empty div</div>{" "} */}
								{numberOfBrandsCreated} {numberOfBrandsCreated === 1 ? "brand" : "brands"} created
							</Text>
						</div>
					) : null;

				case ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS:
					const numberOfTotalLocations = step?.metadata?.total || 0;
					const numberOfCompletedLocations = step?.metadata?.completed || 0;
					const completedPercentage =
						numberOfTotalLocations > 0
							? Math.round((numberOfCompletedLocations / numberOfTotalLocations) * 100)
							: 0;

					if (["inprogress", "complete"].includes(step?.status) && numberOfTotalLocations > 0) {
						if (numberOfTotalLocations === numberOfCompletedLocations && step?.status === "complete") {
							return (
								<div className="onboarding-step__info-component">
									<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
										{numberOfCompletedLocations}{" "}
										{numberOfCompletedLocations === 1 ? "location" : "locations"} created
									</Text>
								</div>
							);
						}
						return (
							<div className="onboarding-step__info-component">
								<div className="onboarding-step__progress-bar-wrapper">
									<ProgressBar
										className="progress-bar"
										height={7}
										progress={100}
										width={completedPercentage}
									/>
									<ProgressBar
										className="progress-bar"
										height={7}
										progress={0}
										width={100 - completedPercentage}
									/>
									<Text
										className="onboarding-step-locations-text"
										variant={TextVariant.BODY1}
									>{`${numberOfCompletedLocations} of ${numberOfTotalLocations} locations created`}</Text>
								</div>
							</div>
						);
					}
					return null;

				case ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS:
					const pull_total = step?.metadata?.pull_total || 0;
					const manual_total = step?.metadata?.manual_total || 0;
					const pull_completed = step?.metadata?.pull_completed || 0;
					const manual_completed = step?.metadata?.manual_completed || 0;

					const numberOfCompletedMenus = manual_completed + pull_completed;
					const numberOfMenuPullsInProgress = pull_total - pull_completed;
					const numberOfMenuCreationInProgress = manual_total - manual_completed;

					return (
						<div className="onboarding-step__info-component">
							{numberOfCompletedMenus > 0 && (
								<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
									{/* <div className="step-progress-indicator">indicator empty div</div>{" "} */}
									{numberOfCompletedMenus} {numberOfCompletedMenus === 1 ? "menu" : "menus"} created
								</Text>
							)}
							{numberOfMenuPullsInProgress > 0 && (
								<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
									{numberOfMenuPullsInProgress} {numberOfMenuPullsInProgress === 1 ? "menu" : "menus"}{" "}
									import in progress
								</Text>
							)}
							{numberOfMenuCreationInProgress > 0 && (
								<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
									{numberOfMenuCreationInProgress}{" "}
									{numberOfMenuCreationInProgress === 1 ? "menu" : "menus"} creation in progress
								</Text>
							)}
						</div>
					);

				case ATLAS_ONBOARDING_STEPS.TAXES_CHARGES:
					const numberOfTaxesCompleted = step?.metadata?.taxes_completed || 0;
					const numberOfChargesCompleted = step?.metadata?.charges_completed || 0;
					if (["inprogress", "complete"].includes(step?.status)) {
						return (
							<div className="onboarding-step__info-component">
								{numberOfTaxesCompleted > 0 && (
									<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
										{numberOfTaxesCompleted} {numberOfTaxesCompleted === 1 ? "tax" : "taxes"}{" "}
										created
									</Text>
								)}
								{numberOfChargesCompleted > 0 && (
									<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
										{numberOfChargesCompleted}{" "}
										{numberOfChargesCompleted === 1 ? "charge" : "charges"} created
									</Text>
								)}
							</div>
						);
					}
					return null;

				case ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS:
					const numberOfTotalLocationsToGoLive = step?.metadata?.total || 0;
					const numberOfLocationsLive = step?.metadata?.completed || 0;
					const goLivePercentage =
						numberOfTotalLocationsToGoLive > 0
							? Math.round((numberOfLocationsLive / numberOfTotalLocationsToGoLive) * 100)
							: 0;
					if (["inprogress", "complete"].includes(step?.status)) {
						if (numberOfTotalLocationsToGoLive === numberOfLocationsLive && step?.status === "complete") {
							return (
								<div className="onboarding-step__info-component">
									<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
										{numberOfLocationsLive} {numberOfLocationsLive === 1 ? "location" : "locations"}{" "}
										live
									</Text>
								</div>
							);
						}
						if (numberOfTotalLocationsToGoLive > 0) {
							return (
								<div className="onboarding-step__info-component">
									<div className="onboarding-step__progress-bar-wrapper">
										<ProgressBar
											className="progress-bar"
											height={7}
											progress={100}
											width={goLivePercentage}
										/>
										<ProgressBar
											className="progress-bar"
											height={7}
											progress={0}
											width={100 - goLivePercentage}
										/>
										<Text
											className="onboarding-step-locations-text"
											variant={TextVariant.BODY1}
										>{`${numberOfLocationsLive} of ${numberOfTotalLocationsToGoLive} locations live`}</Text>
									</div>
								</div>
							);
						}
					}
					return null;

				case ATLAS_ONBOARDING_STEPS.INVITE_USERS:
					const numberOfInvites = step?.metadata?.total || 0;
					return (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
								{/* <div className="step-progress-indicator">indicator empty div</div>{" "} */}
								{numberOfInvites} {numberOfInvites === 1 ? "invite" : "invites"} sent
							</Text>
						</div>
					);

				default:
					return null;
			}
		}
	};

	const handleStatusCardOnClick = (step) => {
		if (getStepState(step.id) !== StatusCardState.UnChecked) {
			step.onCtaClick();
			return;
		}

		// show toast message to complete the current step
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Complete the current step to proceed",
				timeout: 3000,
				error: true
			}
		});
	};

	const handleOnboardingFlowComplete = async () => {
		setLoading(true);
		const resp = await handleOnboardingFlowAction("flow_completed");
		if (resp?.success) {
			NotificationServices.pushNotification({
				message: "Setup complete!",
				timeout: 5000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});

			// cleanup
			setTimeout(() => {
				// redirect to dashboard (orders)
				history.push("/");

				// reset onboarding state
				store.dispatch({
					type: ActionTypes.RESET_ATLAS_ONBOARDING_STATE
				});

				// unsubscribe and close websocket connection
				unsubscribeToFlowSteps();
				closeOnboardingWsLink();
			}, 500);
		}
		setLoading(false);
	};

	return (
		<div className="onboarding-container section-container-common">
			{isLoading ? (
				<div className="onboarding-loader">
					<div className="P(10px)">
						<div className="shimmer H(100px) Mb(20px)" />
						<div className="shimmer H(200px) Mb(10px)" />
					</div>
				</div>
			) : (
				<React.Fragment>
					<OnboardingSetupHeader
						title="Setup"
						description="Complete your restaurant setup on UrbanPiper"
						hideBreadcrumbs
						rightHeader={
							<Button
								onClick={() => history.push("/unified-access/invite")}
								className="onboarding-helper-card-cta"
								variant={ButtonVariant.Secondary}
								size={ButtonSize.MD}
							>
								Invite users
							</Button>
						}
					/>
					<div className="onboarding-step">
						<div className="header">
							<Text className="onboarding-step-heading" variant={TextVariant.HEADING4}>
								Let's get your restaurant ready to operate
							</Text>
						</div>
						<Onboardingnextup
							className="onboarding-nextup-card"
							currentState={nextUpState.state}
							onCtaClick={nextUpState.onCTAClick || (() => {})}
							isComplete={totalSteps === completedSteps}
						/>
						{showFullNav && (
							<div className="flow-complete">
								<HorizonButton
									loading={loading}
									onClick={handleOnboardingFlowComplete}
									classNames="onboarding-flow-complete-cta"
									type="primary"
									size="middle"
								>
									Mark Setup as Completed
								</HorizonButton>
							</div>
						)}
					</div>
					<div className="onboarding-all-steps">
						<Text className="onboarding-all-steps-heading" variant={TextVariant.HEADING4}>
							{`${completedSteps} of ${totalSteps} steps completed`}
						</Text>
						<div className="onboarding-all-steps-list">
							{allStepsList.map((step) => {
								return (
									<StatusCard
										key={step.title}
										className="onboarding-status-card"
										state={getStepState(step.id)}
										title={step.title}
										subTitle={step.subTitle}
										onCtaClick={() => handleStatusCardOnClick(step)}
										infoComponent={getInfoComponent(step.id)}
									/>
								);
							})}
						</div>
					</div>

					<div className="onboarding-helper">
						<Text className="onboarding-helper-heading" variant={TextVariant.HEADING4}>
							Don’t know where to start?
						</Text>

						<div className="onboarding-helper-list">
							{ONBOARDING_HELPER_LIST.map((helper) => {
								return (
									<div key={helper.title} className="onboarding-helper-card">
										<img
											className="onboarding-helper-card-icon"
											src="/assets/icons/icon-question-two.svg"
										/>
										<Text className="onboarding-helper-card-title" variant={TextVariant.BODY1}>
											{helper.title}
										</Text>
										<Button
											onClick={() => history.push(helper.ctaUrl)}
											className="onboarding-helper-card-cta"
											variant={ButtonVariant.Link}
											size={ButtonSize.MD}
											rightIcon={
												<img
													className="onboarding-helper-card-cta-icon"
													src="/assets/icons/icon-right-two.svg"
												/>
											}
										>
											{helper.ctaText}
										</Button>
									</div>
								);
							})}
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}

const mapStateToProps = (store) => ({
	isLoading: store.atlasOnboardingState.isLoading,
	flowData: store.atlasOnboardingState.flowData,
	flowSteps: store.atlasOnboardingState.flowSteps
});

export default connect(mapStateToProps)(Onboarding);
