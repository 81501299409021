import React, { useCallback } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";

// third party
import moment from "moment";
import Select from "react-select-animated";

// utils
import { commifyNumbers, printCurrency, capitaliseText } from "../../atlas-utils";

// actions
import { fetchTagsDebounced } from "../../actions/actions";

// constants
import { ORDER_CHANNEL_MAP, EXTERNAL_PLATFORMS_LOGO } from "../../client-config";

export const Summary = ({
	loading,
	id,
	fullName,
	phone,
	email,
	signupChannel,
	socialConnect,
	dateJoined,
	lastTransactionDate,
	currentPoints,
	userAppVersions = [],
	currentPrepaidBalance,
	totalSpent,
	totalTransactions,
	tags = [],
	currencySymbol,
	productTypeOptions,
	configItems,
	handleCustomerUpdate,
	trackEvent
}) => {
	const productTypeOptionsFlat = {};
	productTypeOptions.forEach((pt) => (productTypeOptionsFlat[pt.value] = pt));

	const addTag = useCallback((tag) => {
		if (tag !== undefined) {
			handleCustomerUpdate("tags", [tag.name]);
			// track this event
			trackEvent("customer_create_tag", { tag: tag.name });
		}
	}, []);

	return (
		<div className="customer-summary">
			<div className="form-row row-half">
				<InputWithLabel
					value={ORDER_CHANNEL_MAP[signupChannel] || signupChannel}
					classes="at-input--label"
					showLabel={true}
					image={true}
					imageSrc={EXTERNAL_PLATFORMS_LOGO[signupChannel]}
					readOnly={true}
				>
					Signup Channel
				</InputWithLabel>
				<InputWithLabel
					value={(dateJoined && moment(dateJoined).format("DD MMM, YYYY")) || "--"}
					readOnly={true}
				>
					Signup Date
				</InputWithLabel>
			</div>
			{userAppVersions.length > 0 && (
				<div className="form-row row-half">
					{userAppVersions.map((userApp, i) => (
						<InputWithLabel key={i} value={userApp.version} readOnly={true}>
							{capitaliseText(userApp.platform)} app version
						</InputWithLabel>
					))}
				</div>
			)}
			<div className="form-row row-half">
				<InputWithLabel
					value={(lastTransactionDate && moment(lastTransactionDate).format("DD MMM, YYYY")) || "--"}
					readOnly={true}
				>
					Last Transaction
				</InputWithLabel>
				{!productTypeOptionsFlat["LOYALTY"].disabled && (
					<InputWithLabel value={commifyNumbers(currentPoints) || "--"} readOnly={true}>
						Loyalty Points
					</InputWithLabel>
				)}
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={commifyNumbers(currentPrepaidBalance) || "--"}
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={currencySymbol}
					readOnly={true}
				>
					Wallet Balance
				</InputWithLabel>
				<InputWithLabel
					value={commifyNumbers(totalSpent) || "--"}
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={currencySymbol}
					readOnly={true}
				>
					Total Spent
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<InputWithLabel value={commifyNumbers(totalTransactions) || "--"} readOnly={true}>
					Total Transactions
				</InputWithLabel>
				<InputWithLabel value={socialConnect || "--"} readOnly={true}>
					Social connect
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Tags"
					options={configItems.tags.items}
					isLoading={configItems.tags.isLoading}
					field="tags"
					currValue={tags}
					setFilter={(field, value) => addTag(value[value.length - 1])}
					handleSearch={fetchTagsDebounced}
					labelKey="name"
					valueKey="id"
					isClearable={false}
					multi={true}
					creatable={true}
					placeholder="Create tags by pressing Tab/Enter"
				/>
			</div>
		</div>
	);
};

const AddTag = ({ options, isLoading = false, labelKey, valueKey, handleSelect, handleSearch, placeholder }) => {
	// handle enter key press
	const onInputKeyDown = useCallback((e) => {
		if (e.keyCode == 13) {
			handleSelect({
				name: e.target.value
			});
			e.preventDefault();
		}
	}, []);
	return (
		<div className="add-tags-control">
			<Select
				searchable={true}
				autosize={false}
				className="at-dropdown"
				clearable={true}
				options={options}
				onChange={handleSelect}
				onInputChange={handleSearch}
				onInputKeyDown={onInputKeyDown}
				isLoading={isLoading}
				labelKey={labelKey}
				valueKey={valueKey}
				placeholder={placeholder}
				noResultsText="Enter to create a new tag"
			/>
		</div>
	);
};

const OrdersInfoItem = ({ title, value, logo, classes = "", isCurrency, currencySymbol, loading }) => (
	<div className="info-item">
		<div className="title">{title}</div>
		{!loading ? (
			<div className={"value " + classes}>
				{logo && <img className="logo" src={logo} alt="signup-channel-logo" />}
				<span>
					{value && value !== "--" && isCurrency && printCurrency(currencySymbol)}
					{value ? value : "--"}
				</span>
			</div>
		) : (
			<div className="shimmer W(50px) H(20px)"></div>
		)}
	</div>
);
