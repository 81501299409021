import React, { Component } from "react";

// components
import { Button } from "../../components/_commons/Button";

// clients
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";

@connect((store) => ({
	login: store.login,
	dimensions: store.configItems.dimensions
}))
export class CreditTariff extends Component {
	constructor(props) {
		super(props);
	}

	buyCredit = () => {
		store.dispatch({
			type: "CREDITS_STATE_UPDATE",
			payload: {
				showBuyCreditsFlow: true,
				source: "piper_credits_ledger"
			}
		});

		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'credit_purchase_start',
		// });
	};

	render() {
		const isMobile = this.props.dimensions.width <= 768;
		return (
			<div className="settings-section">
				<CreditTariffHeader />
				<CampaignTariff buyCredit={this.buyCredit} isMobile={isMobile} {...this.props.login} />
			</div>
		);
	}
}

const CreditTariffHeader = () => (
	<div className="campaign-tariff-header">
		<div className="header">
			<div className="subheader-text">
				<span>Use Piper Credits to run campaigns and buy reports</span>
			</div>
		</div>
	</div>
);

const CampaignTariff = (props) => {
	let costMap = props.creditsMapping.objects;
	let campaignTypeLabelMapping = {
		EMAIL: "Email",
		SMS: "SMS",
		APP_PUSH_NOTIFICATION: "App push notification",
		WEB_PUSH_NOTIFICATION: "Web push notification"
	};
	let nodes = costMap.map((i, d) => {
		return (
			<div className="tariff-details-item" key={d}>
				<div className="tariff-source">
					{Math.floor(1 / i.typesCreditsRatio)} {campaignTypeLabelMapping[i.typeName]}
				</div>
				<div className="seperator">:</div>
				<div className="tariff-credit">1 Piper Credit</div>
			</div>
		);
	});
	return (
		<div className="campaign-tariff">
			<div className="tariff-details-container">{nodes}</div>
			<div>
				{!props.isMobile ? (
					<div className="header-action-button">
						<div onClick={props.buyCredit} className="button-container Cur(p)">
							<div>
								<img src="/assets/icons/icon-buy-credits.svg" />
							</div>
							<div className="button-title">BUY CREDITS</div>
						</div>
						<div className="conversion-text">
							1 Piper Credit = {props.creditsCost.amountPerCredits} {props.loggedInbizDetail.currency}
						</div>
					</div>
				) : null}
				<div className="Cf"></div>
			</div>
			{props.isMobile ? (
				<div className="Mt(10px) Mb(10px)">
					<Button classes="W(100%)" clickHandler={props.buyCredit}>
						Buy credits
					</Button>
					<div className="conversion-text Mt(5px) Fz(12px) C(#979797) Ta(c)">
						1 Piper Credit = {props.creditsCost.amountPerCredits} {props.loggedInbizDetail.currency}
					</div>
				</div>
			) : null}
		</div>
	);
};
