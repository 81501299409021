import React, { Component } from "react";

// components
import { Header } from "../components/UsersList/Header";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { CommonTable } from "../components/_commons/CommonTable";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchUsersList } from "../actions/users";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES } from "../client-config";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Name",
		field: "name",
		sortKey: "full_name",
		render: (record, i, archived, curSym, extractInitials) => (
			<div className={"at-table-cell at-cell-text name"} title={record.fullName} key={i}>
				<div className="name-initials">
					<span>
						{record.fullName
							? extractInitials(record.fullName.split(" "))
							: extractInitials(record.username.split(" "))}
					</span>
				</div>
				<div className="name-desc">
					<Link className={"link-text " + archived} to={`/access/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">{record.fullName || record.id}</span>
					</Link>
					<div className="text--light desc-text">{record.username || ""}</div>
				</div>
			</div>
		)
	},
	{
		name: "Roles",
		field: "roles",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text roles"} key={i}>
				{record.groups.length > 0 && (
					<div className="tags-container table-mode">
						<div className="list">
							{record.groups.map((role, i) => (
								<span key={i} className="tag-item" title={role.name}>
									{role.name}
								</span>
							))}
						</div>
					</div>
				)}
			</div>
		)
	},
	{
		name: "Contact Info",
		field: "email",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text email"} key={i}>
				{record.email || "--"}
			</div>
		)
	}
];

@connect((store) => ({
	usersList: store.usersList,
	usersListState: store.usersListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems
}))
export class UsersList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			search: "",
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "users_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.usersListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Users list
		await fetchUsersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updateusersListState = (payload) => {
		store.dispatch({
			type: ActionTypes.USERS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateusersListState({
			currentFilters: this.props.usersListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.usersListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateusersListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.usersListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updateusersListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateusersListState({
			appliedFilters: {
				...this.props.usersListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "users_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchUsersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}

		const { appliedFilters } = store.getState().usersListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updateusersListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchUsersList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.usersListState;
		const offset = (page - 1) * limit;
		this.updateusersListState({
			offset
		});
		// fetch new Timing Groups list
		fetchUsersList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.usersListState;
		if (size && size?.value !== limit) {
			this.updateusersListState({
				[field]: size.value
			});
			// fetch new Timing Groups list
			await fetchUsersList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateusersListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.USERS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchUsersList();
	};

	extractInitials = (name) => {
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(name[0].length - 1);
		}
		return initials.toUpperCase();
	};

	render() {
		const { usersList, usersListState, configItems } = this.props;
		const { limit, offset, currentFilters, sortedField } = usersListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f === "is_active" && currentFilters[f]) {
				filterCount++;
			} else if (f !== "search") {
				if (currentFilters[f].value && currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}

		const filterOptions = usersList.data.filters.filter((f) => f.field !== "search");
		const searchFilterOption = usersList.data.filters.find((fl) => fl.field === "search");

		return (
			<div>
				<div ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						filterOption={searchFilterOption}
						setFilter={this.setSearchFilter}
						value={this.state.search}
						handlePiperAcademy={this.handlePiperAcademy}
						bizName={this.props.biz.name}
					/>
					<CommonTable
						loading={usersList.loading}
						data={usersList.data.objects || []}
						columns={columns}
						handleTask={this.extractInitials}
						sortList={this.sortList}
						sortedField={sortedField}
						archived={this.state.archivedFilter ? "archived" : ""}
						classes="users-list-table-container"
						content="Users"
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={usersList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
					<NestedEntityContainer
						show={this.state.nestedEntity.show}
						type={this.state.nestedEntity.type}
						id={this.state.nestedEntity.id}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
						isNested={false}
						isForeignSource={true}
					/>
				</div>
			</div>
		);
	}
}
