// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_CATEGORIES_LIST, GET_CATEGORY, EDIT_CATEGORY } from "../graphql/categories";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchCategoriesList = async (id, brand) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CATEGORIES_LIST_REQUEST
	});
	try {
		const { limit, offset, sort, appliedFilters } = store.getState().categoriesListState;
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(appliedFilters[f]) === "true" ? "false" : "true"
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: "true"
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		variables.brand = !brand ? null : String(brand?.id);
		const resp = await client.query({
			query: GET_CATEGORIES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		let requiredIndex = 0;
		if (id) {
			requiredIndex = resp?.data?.categories?.objects.findIndex((category) => category.id === id);
		}
		store.dispatch({
			type: ActionTypes.GET_CATEGORIES_LIST_SUCCESS,
			payload: {
				data: resp.data.categories.objects,
				selectedCategory: resp.data.categories.objects[!isNaN(requiredIndex) ? requiredIndex : 0],
				filters: resp.data.categories.filters
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CATEGORIES_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseCategoryData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			let temp = result.parent;
			result.parent = result.parentCategory;
			result.parentCategory = temp;
			break;
		case "server":
			if (result.parent && result.parent.id) {
				result.parent = parseInt(result.parent.id);
			}
			if (result.timingsGroup && result.timingsGroup.id) {
				result.timingsGroup = parseInt(result.timingsGroup.id);
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchCategoryDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_CATEGORY_DETAIL_REQUEST
	});
	try {
		const brand = store.getState().configItems?.brands?.selectedBrand?.id || null;
		const variables = {
			id,
			brand
		};
		const resp = await client.query({
			query: GET_CATEGORY,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_CATEGORY_DETAIL_SUCCESS,
			payload: parseCategoryData(resp.data.category, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_CATEGORY_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching category details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editCategory = async (variables, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_CATEGORY_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_CATEGORY,
			variables: parseCategoryData(variables, "server")
		});
		if (resp.data.saveCategory.status.success) {
			dispatch({
				type: ActionTypes.EDIT_CATEGORY_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Category saved!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			if (
				resp.data.saveCategory.status?.messages.length > 0 &&
				resp.data.saveCategory.status?.messages[0].field === "parent"
			) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.saveCategory.status?.messages[0].message || "Something went wrong.",
						timeout: 2000,
						error: true
					}
				});
			} else {
				dispatch({
					type: ActionTypes.EDIT_CATEGORY_DETAIL_FAILURE,
					error: parseErrorMessages(resp.data.saveCategory.status.messages)
				});
			}
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_CATEGORY_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Category details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreCategory = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_CATEGORY,
			variables: parseCategoryData(variables, "server")
		});
		if (resp.data.saveCategory.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveCategory.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
