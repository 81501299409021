import React, { Component } from "react";

// components
import { Header } from "../components/DiscountsList/Header";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { CommonTable } from "../components/_commons/CommonTable";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";
import moment from "moment";

// graphql
import { GET_PLATFORMS_DISCOUNTS } from "../graphql/discounts";

// actions
import { toggleGlobalLoader, fetchBizPlatforms } from "../actions/actions";
import { fetchDiscountsList } from "../actions/discounts";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll } from "../atlas-utils";
import history from "../history";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";

const columns = [
	{
		name: "Discount Name",
		field: "title",
		sortKey: "title",
		render: (record, i, archived) => (
			<div className={"at-table-cell at-cell-text title"} title={record.title} key={i}>
				<div className="title-desc">
					<Link className={"link-text " + archived} to={`/discounts/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">{record.title || record.id}</span>
					</Link>
					{record.isExpired && <span className="badge">Expired</span>}
					<div className="text--light desc-text">{record.desc || ""}</div>
				</div>
			</div>
		)
	},
	{
		name: "Type",
		field: "type",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text type"} key={i}>
				{record.zomatoDiscountType || record.uberEatsDiscountType || "--"}
			</div>
		)
	},
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text platform"} key={i}>
				{record.platformName || ""}
			</div>
		)
	},
	{
		name: "Starts On",
		field: "starts",
		sortKey: "starts_on",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text starts"} key={i}>
				{record.startsOn ? moment(record.startsOn).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	},
	{
		name: "Ends On",
		field: "expires",
		sortKey: "expires_on",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text expires"} key={i}>
				{record.expiresOn ? moment(record.expiresOn).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	}
];

@connect((store) => ({
	discountsList: store.discountsList,
	discountsListState: store.discountsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems
}))
export class DiscountsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: {
				starts_on: false,
				expires_on: false
			},
			showFilters: false,
			title: "",
			platformsList: [],
			archivedFilter: false
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "discounts_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.discountsListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// the below code preloads biz platforms list
		const { configItems } = this.props;
		if (!configItems.bizPlatforms.items.length) {
			await fetchBizPlatforms();
		}

		// fetch platforms list
		const selectedPlatform = await this.fetchPlatformsList();

		// fetch Discounts list
		if (selectedPlatform) {
			await fetchDiscountsList(selectedPlatform.value);
		}

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	fetchPlatformsList = async () => {
		store.dispatch(toggleGlobalLoader(true));
		store.dispatch({
			type: ActionTypes.DISCOUNTS_LIST_STATE_SELECTED_PLATFORM,
			payload: { loading: true }
		});
		try {
			let resp = await client.query({
				query: GET_PLATFORMS_DISCOUNTS,
				fetchPolicy: "no-cache"
			});
			const platformsList = resp.data.discountPlatforms.filter(
				(plf) =>
					this.props.configItems.bizPlatforms.items.find(
						(p) => p.platformName.toLowerCase() === plf.valueForDisplay.toLowerCase()
					) !== undefined
			);
			this.setState({
				platformsList
			});
			store.dispatch({
				type: ActionTypes.DISCOUNTS_LIST_STATE_SELECTED_PLATFORM,
				payload: {
					loading: false,
					selectedPlatform: platformsList.length ? platformsList[0] : ""
				}
			});
			store.dispatch(toggleGlobalLoader(false));
			return platformsList.length ? platformsList[0] : "";
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		store.dispatch({
			type: ActionTypes.DISCOUNTS_LIST_STATE_SELECTED_PLATFORM,
			payload: { loading: false }
		});
		store.dispatch(toggleGlobalLoader(false));
	};

	handleDropdown = (e, field) => {
		e.stopPropagation();
		if (!this.state.isOpen[field]) {
			let { isOpen } = this.state;
			Object.keys(isOpen).map((df) => {
				isOpen[df] = false;
			});
			isOpen[field] = true;
			this.setState({ isOpen });
		} else {
			this.closeDropdown(field);
		}
	};

	closeDropdown = (field) => {
		if (this.state.isOpen[field]) {
			const { currentDateFilter, appliedDateFilter } = this.props.discountsListState;
			this.updatediscountsListState({
				currentDateFilter: {
					...currentDateFilter,
					[field]: appliedDateFilter[field]
				}
			});
			this.setState({
				isOpen: {
					...this.state.isOpen,
					[field]: false
				}
			});
		}
	};

	applyDateRange = (field) => {
		this.setState({
			isOpen: {
				...this.state.isOpen,
				[field]: false
			}
		});
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatediscountsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.DISCOUNTS_LIST_STATE_CHANGE,
			payload
		});
	};

	updateDateFilter = (payload, field) => {
		if (this.state.isOpen[field]) {
			const { currentDateFilter } = this.props.discountsListState;
			store.dispatch({
				type: ActionTypes.DISCOUNTS_LIST_STATE_CHANGE,
				payload: {
					currentDateFilter: {
						...currentDateFilter,
						[field]: payload.currentDateFilter
					}
				}
			});
		}
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatediscountsListState({
			currentFilters: this.props.discountsListState.appliedFilters,
			currentDateFilter: this.props.discountsListState.appliedDateFilter
		});
	};

	setPlatform = (field, value) => {
		store.dispatch({
			type: ActionTypes.DISCOUNTS_LIST_STATE_SELECTED_PLATFORM,
			payload: { [field]: value }
		});
		// fetch new Discounts List
		fetchDiscountsList(value?.value);
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.discountsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatediscountsListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.discountsListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updatediscountsListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatediscountsListState({
			appliedFilters: {
				...this.props.discountsListState.currentFilters
			},
			appliedDateFilter: {
				...this.props.discountsListState.currentDateFilter
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "discounts_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchDiscountsList(this.props.discountsList.selectedPlatform.value);

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}

		const { appliedFilters } = store.getState().discountsListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				store.dispatch({
					type: ActionTypes.DISCOUNTS_LIST_STATE_RESET
				});
				await fetchDiscountsList(this.props.discountsList.selectedPlatform.value);
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.discountsListState;
		const offset = (page - 1) * limit;
		this.updatediscountsListState({
			offset
		});
		// fetch new Discounts list
		fetchDiscountsList(this.props.discountsList.selectedPlatform.value);
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.discountsListState;
		if (size && size?.value !== limit) {
			this.updatediscountsListState({
				[field]: size.value
			});
			// fetch new Discounts list
			await fetchDiscountsList(this.props.discountsList.selectedPlatform.value);
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatediscountsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.DISCOUNTS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchDiscountsList(this.props.discountsList.selectedPlatform.value);
	};

	render() {
		const { discountsList, discountsListState, configItems } = this.props;
		const { limit, offset, currentFilters, currentDateFilter, sortedField } = discountsListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (f === "is_active" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f].value && currentFilters[f].value !== "") {
					filterCount++;
				}
			}
		}
		for (let f in currentDateFilter) {
			if (currentDateFilter[f].dateFilter) {
				filterCount++;
			}
		}

		const filterOptions = discountsList.data.filters.filter((f) => f.field !== "title");
		const searchFilterOption = discountsList.data.filters.find((fl) => fl.field === "title");

		const placeholderContent = {
			placeholderText: "No discounts added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-discount.svg",
			placeholderSubtext:
				"Manage all your discounts here and ensure you are providing the best deals to your customers while growing your business",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add your first Discount</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/discounts/new");
			},
			size: "medium"
		};

		return (
			<div>
				<div className="discounts-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions || []}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
							isDropdownOpen={this.state.isOpen}
							handleDropdown={this.handleDropdown}
							closeDropdown={this.closeDropdown}
							applyDateRange={this.applyDateRange}
							currentDateFilter={currentDateFilter}
							updateListState={this.updateDateFilter}
							storeRootField="discountsListState"
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						filterOption={searchFilterOption}
						setFilter={this.setSearchFilter}
						value={this.state.title}
						platformsList={this.state.platformsList}
						selectedPlatform={discountsList.selectedPlatform}
						setPlatform={this.setPlatform}
					/>
					<CommonTable
						loading={discountsList.loading}
						data={discountsList.data.objects || []}
						columns={columns}
						sortList={this.sortList}
						sortedField={sortedField}
						archived={this.state.archivedFilter ? "archived" : ""}
						classes="discounts-list-table-container"
						content={this.state.platformsList.length ? "Discounts" : ""}
						customPlaceholder={
							this.state.platformsList.length === 0
								? "External platform association not enabled. Please contact Account Manager for more details."
								: ""
						}
						showPlaceholder
						placeholderContent={placeholderContent}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={discountsList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
				</div>
			</div>
		);
	}
}
