import React, { useEffect } from "react";

// components
import Placeholder from "../_commons/Placeholder";
import { ButtonIcon } from "../_commons/ButtonIcon";

// third party
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import history from "../../history";
import moment from "moment";

// utils
import { trackEvent } from "../../atlas-utils";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
export const REPORTS_GROUP_MAP = {
	general: {
		name: "Miscellaneous",
		description:
			"Reports related to sign ups, feedback and payments on channels other than delivery service platforms.",
		icon: "/assets/icons/icon-misc-reports.svg"
	},
	loyalty: {
		name: "Loyalty",
		description: "Reports related to loyalty point transactions and wallet balances on the loyalty product.",
		icon: "/assets/icons/icon-loyalty-reports.svg"
	},
	ordering: {
		name: "Ordering",
		description: "Reports related to online ordering workflows on delivery service platforms and Meraki.",
		icon: "/assets/icons/icon-ordering-reports.svg"
	}
};
const PLACEHOLDER = {
	placeholderText: "No results found",
	placeholderImageUrl: "/assets/empty_states/graphics-empty-search.svg",
	placeholderSubtext: "The report you are searching for is not available",
	size: "medium"
};

const Overview = ({
	reports = [],
	loading = false,
	searchKeyword = "",
	userInfo,
	isRowClickable = false,
	rowClickHandler
}) => {
	useEffect(() => {
		// track event
		trackEvent("reports_list_view_visits", {});
	}, []);

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload: {
				data: payload
			}
		});
	};

	const handleRowClick = (report, reportGroup) => {
		if (rowClickHandler) {
			rowClickHandler(report, reportGroup);
			return;
		}

		history.push(`/reports/list/overview/${report.id?.replace("codex##", "")}`);
	};

	return (
		<div className="reports-overview">
			{reports
				?.filter((g) => g?.reports?.length !== 0)
				?.map((g, i) => (
					<div className="group-container" key={i}>
						<div className="group-header">
							<div className="group-name">
								<img src={REPORTS_GROUP_MAP[g.reportGroup]?.icon} alt="" />
								<div>{REPORTS_GROUP_MAP[g.reportGroup]?.name}</div>
							</div>
							<div className="group-description">{REPORTS_GROUP_MAP[g.reportGroup]?.description}</div>
						</div>
						<div className="reports-list">
							{g.reports.map((report, j) => (
								<div
									className={"report" + (isRowClickable ? " clickable" : "")}
									key={j}
									onClick={isRowClickable ? () => handleRowClick(report, g.reportGroup) : void 0}
								>
									<div className="detail">
										{isRowClickable ? (
											<div className="hyperlink hyperlink--black-color">
												<Highlighter
													className="name"
													highlightClassName="highlight"
													searchWords={[searchKeyword]}
													autoEscape={true}
													textToHighlight={report.name}
												/>
											</div>
										) : (
											<Link
												className="hyperlink hyperlink--black-color"
												to={`/reports/list/overview/${report.id?.replace("codex##", "")}`}
												onClick={() =>
													updateReportDetail({
														name: report.name,
														description: report.description,
														group: g.reportGroup
													})
												}
											>
												<Highlighter
													className="name"
													highlightClassName="highlight"
													searchWords={[searchKeyword]}
													autoEscape={true}
													textToHighlight={report.name}
												/>
											</Link>
										)}
										<Highlighter
											className="description"
											highlightClassName="highlight"
											searchWords={[searchKeyword]}
											autoEscape={true}
											textToHighlight={report.description}
										/>
									</div>
									{/* <div className="user-download">
                                    <div className="last-download">
                                        Last downloaded: <span>{moment()?.subtract(1, 'day')?.format("DD MMM, YYYY - hh:mm A")}</span> 
                                    </div>
                                    <div className="user">By {userInfo?.fullName || userInfo?.email}</div>
                                </div> */}
									{isRowClickable && <ButtonIcon icon="chevron" color="#aaaaaa" />}
								</div>
							))}
						</div>
					</div>
				))}
			{reports.filter((g) => g?.reports?.length !== 0)?.length === 0 && !loading && (
				<Placeholder {...PLACEHOLDER} />
			)}
			{reports?.length === 0 && loading && (
				<div className="group-container">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			)}
		</div>
	);
};
export default connect((store) => ({
	userInfo: store.login.loginDetail
}))(Overview);
