import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { ItemsList } from "./ItemsList";
import ItemCreate from "./ItemCreate";
import ItemEdit from "./ItemEdit";
import { Header } from "../components/ItemsList/Header";
import BackupStatus from "../components/_commons/BackupStatus";

const ItemsContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="items-container">
			{restrictView ? (
				<Route
					path="/items"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/items" component={ItemsList} />
					<Route
						exact
						path="/items/new"
						render={() => (hasAccess ? <ItemCreate hasAccess={hasAccess} /> : <Redirect to="/items" />)}
					/>
					<Route exact path="/items/edit/:id" component={ItemEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default ItemsContainer;
