import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";

const BasicInfo = ({
	data,
	modifierGroupType,
	options,
	handleForm,
	handleModifierGroupType,
	validations,
	bizPlatforms = [],
	showLanguages = false,
	currSelectedLang = {},
	handleLanguage,
	handleTranslation,
	saveTranslation,
	readOnly = true,
	isViewedFromMenuSection,
	menuSectionInfo
}) => {
	return (
		<React.Fragment>
			<div className="form-description">
				<div className="header-text">Select modifier group type</div>
				<div className="subtitle">
					<span className="highlight">Add-On:</span>
					More than one modifiers can be selected with the item (Eg: Pizza Toppings)
				</div>
				<div className="subtitle">
					<span className="highlight">Variant:</span>
					Only one modifier can be selected with the item (Eg: Size of pizza)
				</div>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={
						isViewedFromMenuSection
							? menuSectionInfo?.name
							: currSelectedLang.optionGroupTitle
							? currSelectedLang.optionGroupTitle.lang === "en"
								? data.optionGroupTitle
								: currSelectedLang.optionGroupTitle.value
							: data.optionGroupTitle
					}
					onChange={(e) =>
						isViewedFromMenuSection
							? handleForm("name", e.target.value)
							: showLanguages
							? handleTranslation("optionGroupTitle", e.target.value)
							: handleForm("optionGroupTitle", e.target.value)
					}
					requiredLabel={!showLanguages}
					showLanguages={showLanguages}
					currSelectedLang={
						(currSelectedLang.optionGroupTitle && currSelectedLang.optionGroupTitle.lang) || ""
					}
					handleLanguage={(lang) => handleLanguage(lang, "optionGroupTitle")}
					showLoading={showLanguages}
					showActionBtn={currSelectedLang.optionGroupTitle && currSelectedLang.optionGroupTitle.showActionBtn}
					action={() =>
						saveTranslation(
							"optionGroupTitle",
							currSelectedLang.optionGroupTitle
								? currSelectedLang.optionGroupTitle.lang === "en"
									? data.optionGroupTitle
									: currSelectedLang.optionGroupTitle.value
								: data.optionGroupTitle
						)
					}
					field="option_group_title"
					validationMessage={validations.optionGroupTitle || ""}
					readOnly={isViewedFromMenuSection ? false : readOnly}
					classes={
						currSelectedLang.optionGroupTitle && currSelectedLang.optionGroupTitle.showActionBtn
							? "at-input--save"
							: ""
					}
				>
					Title
				</InputWithLabel>
				<SelectFilter
					title="Modifier Group Type"
					options={options}
					readOnly={readOnly}
					field="modifierGroupType"
					currValue={modifierGroupType}
					setFilter={handleModifierGroupType}
					isSearchable={false}
					isClearable={false}
					labelKey="label"
					valueKey="value"
					requiredLabel={true}
				/>
			</div>
			{modifierGroupType?.value === "Add On" && (
				<div className="form-row row-half">
					<InputWithLabel
						value={isViewedFromMenuSection ? menuSectionInfo?.minSelectable : data.optionGroupMinSelectable}
						type="number"
						onChange={(e) =>
							isViewedFromMenuSection
								? handleForm("minSelectable", e.target.value ? Number(e.target.value) : null)
								: handleForm("optionGroupMinSelectable", e.target.value ? Number(e.target.value) : null)
						}
						requiredLabel={true}
						validationMessage={validations.optionGroupMinSelectable || ""}
						readOnly={isViewedFromMenuSection ? false : readOnly}
					>
						Min. Range of Modifiers
					</InputWithLabel>
					<InputWithLabel
						value={isViewedFromMenuSection ? menuSectionInfo?.maxSelectable : data.optionGroupMaxSelectable}
						type="number"
						onChange={(e) =>
							isViewedFromMenuSection
								? handleForm("maxSelectable", e.target.value ? Number(e.target.value) : null)
								: handleForm("optionGroupMaxSelectable", e.target.value ? Number(e.target.value) : null)
						}
						requiredLabel={true}
						validationMessage={validations.optionGroupMaxSelectable || ""}
						readOnly={isViewedFromMenuSection ? false : readOnly}
					>
						Max. Range of Modifiers
					</InputWithLabel>
				</div>
			)}
			<div className="form-row row-half">
				<InputWithLabel
					value={data.sortOrder}
					type="number"
					onChange={(e) => handleForm("sortOrder", e.target.value ? parseInt(e.target.value) : null)}
					requiredLabel={true}
					showCustomTooltip={true}
					tooltipInfo="Control ordering of modifier groups as they are displayed to your customers. Values closer to zero will push the modifier group to top while higher values will push it towards bottom"
					validationMessage={validations.sortOrder || ""}
					readOnly={readOnly}
				>
					Sort Order
				</InputWithLabel>
				<Switch
					title="Is Default"
					checked={data.isDefault}
					clickHandler={() => handleForm("isDefault", !data.isDefault)}
					showCustomTooltip={true}
					tooltipInfo="All modifiers in the group will be selected by default. User can modify their choice based on exclusion instead of inclusion (Reflects only on platforms that support this feature)"
					tooltipPosition="up"
					validationMessage={validations.isDefault || ""}
					readOnly={readOnly}
				/>
			</div>
			<div className="form-row row-half">
				{modifierGroupType?.value !== "Variant" && (
					<Switch
						title="Multi-Modifiers Enabled"
						checked={data.multiOptionsEnabled}
						clickHandler={() => handleForm("multiOptionsEnabled", !data.multiOptionsEnabled)}
						validationMessage={validations.multiOptionsEnabled || ""}
						readOnly={readOnly}
					/>
				)}
				{modifierGroupType?.value === "Variant" &&
					bizPlatforms?.length > 0 &&
					bizPlatforms.find((plf) => plf.platformName.toLowerCase() === "swiggy") && (
						<Switch
							title="Mark as Add-On"
							checked={data.treatAsAddon}
							clickHandler={() => handleForm("treatAsAddon", !data.treatAsAddon)}
							validationMessage={validations.treatAsAddon || ""}
							readOnly={readOnly}
						/>
					)}
			</div>
			<div className="form-row row-full">
				<Textarea
					value={
						isViewedFromMenuSection
							? menuSectionInfo?.description
							: currSelectedLang.optionGroupDesc
							? currSelectedLang.optionGroupDesc.lang === "en"
								? data.optionGroupDesc
								: currSelectedLang.optionGroupDesc.value
							: data.optionGroupDesc
					}
					onChange={(e) =>
						isViewedFromMenuSection
							? handleForm("description", e.target.value)
							: showLanguages
							? handleTranslation("optionGroupDesc", e.target.value)
							: handleForm("optionGroupDesc", e.target.value)
					}
					showLanguages={showLanguages}
					currSelectedLang={(currSelectedLang.optionGroupDesc && currSelectedLang.optionGroupDesc.lang) || ""}
					handleLanguage={(lang) => handleLanguage(lang, "optionGroupDesc")}
					showLoading={true}
					showActionBtn={currSelectedLang.optionGroupDesc && currSelectedLang.optionGroupDesc.showActionBtn}
					action={() =>
						saveTranslation(
							"optionGroupDesc",
							currSelectedLang.optionGroupDesc
								? currSelectedLang.optionGroupDesc.lang === "en"
									? data.optionGroupDesc
									: currSelectedLang.optionGroupDesc.value
								: data.optionGroupDesc
						)
					}
					field="option_group_desc"
					readOnly={isViewedFromMenuSection ? false : readOnly}
					classes={
						currSelectedLang.optionGroupDesc && currSelectedLang.optionGroupDesc.showActionBtn
							? "at-textarea--save"
							: ""
					}
				>
					Description
				</Textarea>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={isViewedFromMenuSection ? data?.crmName : data.crmTitle}
					onChange={(e) => handleForm("crmTitle", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Short text to easily identify and lookup the modifier group"
					readOnly={isViewedFromMenuSection ? true : readOnly}
				>
					CRM Title
				</InputWithLabel>
				<InputWithLabel
					value={data.merchantRefId === "-1" ? "" : data.merchantRefId}
					onChange={(e) => handleForm("merchantRefId", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
					tooltipPosition="up"
					validationMessage={validations.merchantRefId || ""}
					readOnly={readOnly}
				>
					POS ID
				</InputWithLabel>
			</div>
		</React.Fragment>
	);
};
export default BasicInfo;
