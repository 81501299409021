import React, { Component } from "react";

// components
import { DownArrow } from "../_commons/DownArrow";
import { Button } from "../_commons/Button";
import { ButtonIcon } from "../_commons/ButtonIcon";

// third party
import moment from "moment";
import { Transition, config, animated } from "react-spring/renderprops";

// utils
import { trackEvent } from "../../atlas-utils";

// constants
import { ORDER_STATUS_PRESENT_TENSE_MAP, STORE_TYPES } from "../../client-config";

export class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showActions: false,
			orderUpdateMessage: ""
		};
	}

	componentDidMount() {
		window.addEventListener("click", this.closeActions, false);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.closeActions, false);
	}

	flipActions = (e, showActions) => {
		e.stopPropagation();
		this.setState({
			showActions
		});
	};

	closeActions = () => {
		if (this.state.showActions) {
			this.setState({
				showActions: false
			});
		}
	};

	updateOrderState = (nextState) => {
		this.props.updateOrderState(nextState, this.state.orderUpdateMessage);
	};

	handleReconcileButtonClick = () => {
		trackEvent("manual_reconciliation_btn_click", {});
		this.props.updateModalState(true);
	};

	render() {
		const { showActions } = this.state;
		const { loading, data, storeType, selectedTab, showReconciliationActions, readOnly } = this.props;
		const { id, billDump, updated, nextState } = data;
		let nextStates = [];
		if (nextState) {
			nextStates.push(nextState);
			nextState !== "COMPLETED" && nextStates.push("COMPLETED");
			nextState !== "CANCELLED" && nextStates.push("CANCELLED");
		}
		if (!id && loading) {
			return (
				<div>
					<div className="shimmer H(50px)"></div>
				</div>
			);
		}

		return (
			<div className="transaction-details-header">
				<div className="header-meta">
					<div className="order-id">
						{storeType === STORE_TYPES[0].type ? `Order: #${id}` : `Purchase: #${id}`}
					</div>
					<div className="order-update-time">
						Last updated at {moment(updated?.split("+")?.[0]).format("DD, MMM YYYY - hh:mm:ss A")}
					</div>
				</div>
				{selectedTab !== "Reconciliation" &&
				(storeType === STORE_TYPES[0].type || (storeType === STORE_TYPES[1].type && billDump))
					? !readOnly && (
							<div className="header-actions">
								<div
									className="action-title-container"
									onClick={(e) => this.flipActions(e, !showActions)}
								>
									<span className="action-title">Actions</span>
									<DownArrow
										handleClick={() => null}
										expanded={showActions}
										title="Actions"
										color="black"
										classes="edit-clone--arrow"
									/>
								</div>
								{showActions && (
									<Transition
										native
										items={showActions}
										from={{
											opacity: 0,
											transform: "perspective(800px) rotateX(-90deg)",
											transformOrigin: "top"
										}}
										enter={{
											opacity: 1,
											transform: "perspective(800px) rotateX(0deg)",
											transformOrigin: "top"
										}}
										leave={{
											opacity: 0,
											transform: "perspective(800px) rotateX(-90deg)",
											transformOrigin: "top"
										}}
										config={config.stiff}
									>
										{(showActions) =>
											showActions &&
											((props) => (
												<animated.div style={props} className="header-actions-list">
													<div className="action-item" onClick={this.props.printInvoice}>
														Print Invoice
													</div>
													{nextStates.map((nextState, i) => (
														<div
															key={i}
															className="action-item"
															onClick={() => this.props.changeNextStatus(nextState)}
														>
															{ORDER_STATUS_PRESENT_TENSE_MAP[nextState]
																? ORDER_STATUS_PRESENT_TENSE_MAP[nextState]
																: nextState}
														</div>
													))}
												</animated.div>
											))
										}
									</Transition>
								)}
							</div>
					  )
					: showReconciliationActions && (
							<Button type="secondary" clickHandler={this.handleReconcileButtonClick}>
								<ButtonIcon icon="resolve" />
								<span>Reconcile</span>
							</Button>
					  )}
			</div>
		);
	}
}
