// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import { msaagesArrayToHtml } from "../atlas-utils";

// graphql
import { GET_PENDING_INVITES_LIST, USER_INVITE_ACTIONS, GET_INVITE } from "../graphql/unifiedInvites";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchPendingInvitesList = async () => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().unifiedInvitesListState;
	const token = store.getState().login.loginDetail.token;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().unifiedInvitesList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_UNIFIED_INVITES_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset,
			clientToken: token
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "roles" && appliedFilters[f] !== null && appliedFilters[f]?.value !== "") {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// date filter
		Object.keys(appliedDateFilter).forEach((f) => {
			if (appliedDateFilter[f].dateFilter) {
				filtersObject.push({
					field: f,
					value: appliedDateFilter[f].dateFilter
				});
			}
		});
		// // sort
		// if(sort.field !== '' && sort.order !== '') {
		// 	variables.sort = sort;
		// }
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_PENDING_INVITES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_UNIFIED_INVITES_LIST_SUCCESS,
			payload: { ...resp.data.authServiceInvitesList }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_UNIFIED_INVITES_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const userInviteActions = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: USER_INVITE_ACTIONS,
			variables
		});
		if (resp.data.authServiceActionInvite.status.success) {
			// store.dispatch({
			// 	type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			// 	payload: {
			// 		message: variables.action === 'resend' ? "Invite re-sent successfully!" : "Invite deleted successfully!",
			// 		timeout: 3000,
			// 		error: false,
			// 	}
			// });
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp?.data?.authServiceActionInvite?.status?.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchInviteDetail = async (uuid, dispatch) => {
	dispatch({
		type: ActionTypes.GET_INVITE_DETAIL_REQUEST
	});
	const token = store.getState().login.loginDetail.token;
	try {
		const variables = {
			uuid,
			clientToken: token
		};
		const resp = await client.query({
			query: GET_INVITE,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_INVITE_DETAIL_SUCCESS,
			payload: resp.data.authServiceInvite?.object
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_INVITE_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Invite details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};
