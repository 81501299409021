// action types
import { ActionTypes } from "../actions/_types";

export const DEVELOPER_TOOLS_INITIAL_STATE = {
	loading: false,
	data: null,
	initFetchData: null,
	error: {},
	testRequestProcessing: false,
	triggerResponse: {
		error: false,
		message: "",
		success: false
	}
};

export const developerTools = (state = DEVELOPER_TOOLS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_AGGREGATOR_TEST_CONFIG_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_AGGREGATOR_TEST_CONFIG_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				initFetchData: action.payload
			};
		case ActionTypes.GET_AGGREGATOR_TEST_CONFIG_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case ActionTypes.UPDATE_AGGREGATOR_TEST_CONFIG_DATA:
			return {
				...state,
				data: action.payload
			};
		case ActionTypes.RESET_AGGREGATOR_TEST_CONFIG_DATA:
			return {
				...state,
				data: state.initFetchData,
				error: {},
				loading: false,
				testRequestProcessing: false,
				triggerResponse: {
					error: false,
					message: "",
					success: false
				}
			};
		case ActionTypes.TRIGGER_AGGREGATOR_TEST_CONFIG_REQUEST:
			return {
				...state,
				testRequestProcessing: true
			};
		case ActionTypes.TRIGGER_AGGREGATOR_TEST_CONFIG_SUCCESS:
			return {
				...state,
				testRequestProcessing: false,
				triggerResponse: {
					error: false,
					message: "The triggered request was processed successfully!",
					success: true
				}
			};
		case ActionTypes.TRIGGER_AGGREGATOR_TEST_CONFIG_FAILURE:
			return {
				...state,
				testRequestProcessing: false,
				triggerResponse: {
					...action.payload
				}
			};
		default:
			return state;
	}
};
