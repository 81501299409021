import React, { Component } from "react";

// components
import { SelectFilter } from "../_commons/SelectFilter";
import { CommonTable } from "../_commons/CommonTable";
import { Paginator } from "../_commons/Paginator";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { SearchFilter } from "../_commons/SearchFilter";
import Replies from "./Replies";

// clients
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";
import PubSub from "pubsub-js";
import moment from "moment";

// actions
import { fetchCustomerFeedbackList } from "../../actions/customerFeedback";
import { ActionTypes } from "../../actions/_types";

// utils
import { scroll, adjustNestedContainer } from "../../atlas-utils";

// config
import { NESTED_ENTITY_TYPES } from "../../client-config";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const REPLIES_INITIAL_STATE = {
	isOpen: false,
	id: null,
	name: null,
	comments: null,
	rating: null,
	replies: []
};

const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, a, cs, handleNestedEntity) => (
			<div className="at-table-cell at-cell-text name" title={record.itemTitle} key={i}>
				<div className="name-desc">
					{record.customer ? (
						<a
							role="button"
							className="link-text"
							onClick={() =>
								handleNestedEntity(true, NESTED_ENTITY_TYPES[12], parseInt(record.customer.id))
							}
						>
							{record.customer.fullName || record.customer.id}
						</a>
					) : (
						"--"
					)}
					<div className="text--light desc-text">{record.customer ? record.customer.phone : ""}</div>
				</div>
			</div>
		)
	},
	{
		name: "Feedback Time",
		field: "created_at",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text created_at" key={i}>
				{record.created ? moment(record.created).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	},
	{
		name: "Comments",
		field: "comments",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text comments" key={i}>
				<div className="choice-text">{record.choiceText || "--"}</div>
				<div className="comments-text" title={record.comments}>
					{record.comments && record.comments.length > 70
						? record.comments.slice(0, 70) + "..."
						: record.comments || ""}
				</div>
			</div>
		)
	},
	{
		name: "Rating",
		field: "rating",
		render: (record, i, ar, cs, ht, cb, rest) => (
			<div className="at-table-cell at-cell-text rating" key={i}>
				<div className="rating-info">
					{record.rating && (
						<div className="rating-icon">
							<img
								src={`/assets/icons/icon-${
									record.rating >= 0 && record.rating <= 6
										? "sad"
										: record.rating >= 7 && record.rating <= 8
										? "neutral"
										: "happy"
								}-face.svg`}
							/>
						</div>
					)}
					<div>{record.rating || "N/A"}</div>
				</div>
				<div
					className="reply-icon"
					title="Reply"
					onClick={() =>
						rest.openRepliesSidebar(
							record.id,
							record.customer ? record.customer.fullName : null,
							record.comments,
							record.rating,
							record.replies
						)
					}
				>
					<img src="/assets/icons/icon-reply.svg" />
				</div>
			</div>
		)
	},
	{
		name: "Order ID",
		field: "order",
		render: (record, i, a, cs, handleNestedEntity) => (
			<div className="at-table-cell at-cell-text order" key={i}>
				<div className="order-desc">
					{record.objectId ? (
						<a
							role="button"
							className="link-text"
							onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[11], parseInt(record.objectId))}
						>
							{record.objectId}
						</a>
					) : (
						"--"
					)}
					<div className="type">{record.contentType || ""}</div>
				</div>
			</div>
		)
	},
	{
		name: "Location",
		field: "location",
		render: (record, i, a, cs, handleNestedEntity) => (
			<div className="at-table-cell at-cell-text location" key={i}>
				{record.bizLocation ? (
					<a
						role="button"
						className="link-text"
						onClick={() =>
							handleNestedEntity(true, NESTED_ENTITY_TYPES[2], parseInt(record.bizLocation.id))
						}
					>
						{record.bizLocation.name || record.bizLocation.id}
					</a>
				) : (
					"--"
				)}
			</div>
		)
	}
];

@connect((store) => ({
	customerFeedbackList: store.customerFeedbackList,
	customerFeedbackListState: store.customerFeedbackListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class List extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			replies: REPLIES_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "customer_feedback_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.customerFeedbackListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Items list
		await fetchCustomerFeedbackList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	}

	async componentWillReceiveProps(newProps) {
		if (
			this.props.customerFeedbackListState.appliedDateFilter.current.dateFilter !==
			newProps.customerFeedbackListState.appliedDateFilter.current.dateFilter
		) {
			// fetch data with updated applied filters
			await fetchCustomerFeedbackList();
		}
	}

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatecustomerFeedbackListState = (payload) => {
		store.dispatch({
			type: ActionTypes.CUSTOMER_FEEDBACK_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatecustomerFeedbackListState({
			currentFilters: this.props.customerFeedbackListState.appliedFilters
		});
	};

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
			fetchCustomerFeedbackList();
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.customerFeedbackListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatecustomerFeedbackListState({
			currentFilters
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.CUSTOMER_FEEDBACK_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.CUSTOMER_FEEDBACK_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchCustomerFeedbackList(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatecustomerFeedbackListState({
			appliedFilters: {
				...this.props.customerFeedbackListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "customer_feedback_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchCustomerFeedbackList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().customerFeedbackListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updatecustomerFeedbackListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchCustomerFeedbackList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.customerFeedbackListState;
		const offset = (page - 1) * limit;
		this.updatecustomerFeedbackListState({
			offset
		});
		// fetch new Items list
		fetchCustomerFeedbackList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.customerFeedbackListState;
		if (size && size?.value !== limit) {
			this.updatecustomerFeedbackListState({
				[field]: size.value
			});
			// fetch new Items list
			await fetchCustomerFeedbackList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatecustomerFeedbackListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.CUSTOMER_FEEDBACK_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchCustomerFeedbackList();
	};

	openRepliesSidebar = (id, name, comments, rating, replies = []) => {
		this.setState({
			replies: {
				isOpen: true,
				id,
				name,
				comments,
				rating,
				replies
			}
		});
	};

	closeRepliesSidebar = (refresh = false) => {
		this.setState({ replies: REPLIES_INITIAL_STATE });
		fetchCustomerFeedbackList();
	};

	render() {
		const {
			customerFeedbackList,
			customerFeedbackListState,
			searchKeywords,
			configItems,
			searchFieldSelected,
			handleSearchField,
			searchFieldValue,
			setSearchFilter,
			showFilters,
			setShowFilters,
			filterCount
		} = this.props;
		const { limit, offset, sortedField, currentDateFilter, appliedDateFilter } = customerFeedbackListState;

		const placeholderContent = {
			placeholderText: "No feedback yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-feedback.svg",
			placeholderSubtext:
				"View customer ratings and reviews from your website and apps helping you gain actionable insights to grow your business",
			size: "medium"
		};

		return (
			<div className="customer-feedback-list-container" ref={(ref) => (this.tableRef = ref)}>
				<div className="filters">
					<NewDateCompareFilter
						showDropdown={true}
						loading={customerFeedbackList.loading}
						currentDateFilter={currentDateFilter}
						appliedDateFilter={appliedDateFilter}
						updateState={this.updatecustomerFeedbackListState}
						includeAllTime={true}
						hidePresetTypes={["15 D"]}
					/>
					{configItems.dimensions.width > 768 && (
						<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={() => setShowFilters(!showFilters)}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
					)}
					<div className="header-actions">
						{searchKeywords && searchKeywords.length > 1
							? configItems.dimensions.width > 768 && (
									<div className="search-input-container">
										<SelectFilter
											options={searchKeywords}
											field="searchFieldSelected"
											currValue={searchFieldSelected}
											setFilter={handleSearchField}
											labelKey="valueForDisplay"
											valueKey="key"
											isSearchable={false}
											isClearable={false}
										/>
										<SearchFilter
											filterOption={{ field: "searchFieldValue" }}
											value={searchFieldValue}
											setFilter={setSearchFilter}
											placeholder="Search"
										/>
									</div>
							  )
							: configItems.dimensions.width > 768 && (
									<SearchFilter
										filterOption={{ field: "searchFieldValue" }}
										value={searchFieldValue}
										setFilter={setSearchFilter}
										placeholder="Search"
									/>
							  )}
					</div>
				</div>
				<CommonTable
					loading={customerFeedbackList.loading}
					data={customerFeedbackList.data.objects || []}
					columns={columns}
					sortList={this.sortList}
					sortedField={sortedField}
					handleTask={this.handleNestedEntity}
					openRepliesSidebar={this.openRepliesSidebar}
					classes="customer-feedback-list-table-container"
					content="Feedback"
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={customerFeedbackList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
				<Replies
					isOpen={this.state.replies.isOpen}
					close={this.closeRepliesSidebar}
					data={this.state.replies}
				/>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		);
	}
}
