import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { ModifiersList } from "./ModifiersList";
import ModifierCreate from "./ModifierCreate";
import ModifierEdit from "./ModifierEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/ModifiersList/Header";

const ModifiersContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="modifiers-container">
			{restrictView ? (
				<Route
					path="/modifiers"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/modifiers" component={ModifiersList} />
					<Route
						exact
						path="/modifiers/new"
						render={() =>
							hasAccess ? <ModifierCreate hasAccess={hasAccess} /> : <Redirect to="/modifiers" />
						}
					/>
					<Route exact path="/modifiers/edit/:id" component={ModifierEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default ModifiersContainer;
