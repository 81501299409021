import React, { Component } from "react";

// components
import { Header } from "../components/CouponsList/Header";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { CommonTable } from "../components/_commons/CommonTable";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";
import moment from "moment";

// actions
import { fetchCouponsList } from "../actions/coupons";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll } from "../atlas-utils";
import history from "../history";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import CreateIcon from "../components/_commons/CreateIcon";

const columns = [
	{
		name: "Coupon Name",
		field: "title",
		sortKey: "title",
		render: (record, i, archived) => (
			<div className={"at-table-cell at-cell-text title"} title={record.title} key={i}>
				<div className="title-desc">
					<Link className={"link-text " + archived} to={`/coupons/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">{record.title || record.id}</span>
					</Link>
					{record.isExpired && <span className="badge">Expired</span>}
				</div>
				<div className="text--light desc-text">
					{(record.rewardDesc && record.rewardDesc.length > 50
						? record.rewardDesc.slice(0, 50) + "..."
						: record.rewardDesc) || ""}
				</div>
			</div>
		)
	},
	{
		name: "Type",
		field: "type",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text type"} key={i}>
				{record.rewardTypeDisplay || "--"}
			</div>
		)
	},
	{
		name: "Starts On",
		field: "starts",
		sortKey: "starts_on",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text starts"} key={i}>
				{record.startsOn ? moment(record.startsOn).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	},
	{
		name: "Ends On",
		field: "expires",
		sortKey: "expires_on",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text expires"} key={i}>
				{record.expiresOn ? moment(record.expiresOn).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	}
];

@connect((store) => ({
	couponsList: store.couponsList,
	couponsListState: store.couponsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems
}))
export class CouponsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: {
				starts_on: false,
				expires_on: false
			},
			showFilters: false,
			title: "",
			archivedFilter: false
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "coupons_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.couponsListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Coupons list
		await fetchCouponsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	handleDropdown = (e, field) => {
		e.stopPropagation();
		if (!this.state.isOpen[field]) {
			let { isOpen } = this.state;
			Object.keys(isOpen).map((df) => {
				isOpen[df] = false;
			});
			isOpen[field] = true;
			this.setState({ isOpen });
		} else {
			this.closeDropdown(field);
		}
	};

	closeDropdown = (field) => {
		if (this.state.isOpen[field]) {
			const { currentDateFilter, appliedDateFilter } = this.props.couponsListState;
			this.updatecouponsListState({
				currentDateFilter: {
					...currentDateFilter,
					[field]: appliedDateFilter[field]
				}
			});
			this.setState({
				isOpen: {
					...this.state.isOpen,
					[field]: false
				}
			});
		}
	};

	applyDateRange = (field) => {
		this.setState({
			isOpen: {
				...this.state.isOpen,
				[field]: false
			}
		});
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatecouponsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.COUPONS_LIST_STATE_CHANGE,
			payload
		});
	};

	updateDateFilter = (payload, field) => {
		if (this.state.isOpen[field]) {
			const { currentDateFilter } = this.props.couponsListState;
			store.dispatch({
				type: ActionTypes.COUPONS_LIST_STATE_CHANGE,
				payload: {
					currentDateFilter: {
						...currentDateFilter,
						[field]: payload.currentDateFilter
					}
				}
			});
		}
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatecouponsListState({
			currentFilters: this.props.couponsListState.appliedFilters,
			currentDateFilter: this.props.couponsListState.appliedDateFilter
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.couponsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatecouponsListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.couponsListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updatecouponsListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatecouponsListState({
			appliedFilters: {
				...this.props.couponsListState.currentFilters
			},
			appliedDateFilter: {
				...this.props.couponsListState.currentDateFilter
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "coupons_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchCouponsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}

		const { appliedFilters } = store.getState().couponsListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				store.dispatch({
					type: ActionTypes.COUPONS_LIST_STATE_RESET
				});
				await fetchCouponsList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.couponsListState;
		const offset = (page - 1) * limit;
		this.updatecouponsListState({
			offset
		});
		// fetch new Coupons list
		fetchCouponsList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.couponsListState;
		if (size && size?.value !== limit) {
			this.updatecouponsListState({
				[field]: size.value
			});
			// fetch new Coupons list
			await fetchCouponsList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatecouponsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.COUPONS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchCouponsList();
	};

	render() {
		const { couponsList, couponsListState, configItems } = this.props;
		const { limit, offset, currentFilters, currentDateFilter, sortedField } = couponsListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (f === "is_active" && currentFilters[f]) {
					filterCount++;
				}
				if (f === "is_expired") {
					filterCount++;
				} else if (currentFilters[f].value && currentFilters[f].value !== "") {
					filterCount++;
				}
			}
		}
		for (let f in currentDateFilter) {
			if (currentDateFilter[f].dateFilter) {
				filterCount++;
			}
		}

		const filterOptions = couponsList.data.filters.filter((f) => f.field !== "title");
		const searchFilterOption = couponsList.data.filters.find((fl) => fl.field === "title");

		const placeholderContent = {
			placeholderText: "No coupons added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-discount.svg",
			placeholderSubtext:
				"Manage all your coupons here and ensure you are providing the best deals to your customers while growing your business",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add your first Coupon</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/coupons/new");
			},
			size: "medium"
		};

		return (
			<div>
				<div className="coupons-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions || []}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
							isDropdownOpen={this.state.isOpen}
							handleDropdown={this.handleDropdown}
							closeDropdown={this.closeDropdown}
							applyDateRange={this.applyDateRange}
							currentDateFilter={currentDateFilter}
							updateListState={this.updateDateFilter}
							storeRootField="couponsListState"
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						filterOption={searchFilterOption}
						setFilter={this.setSearchFilter}
						value={this.state.title}
					/>
					<CommonTable
						loading={couponsList.loading}
						data={couponsList.data.objects || []}
						columns={columns}
						sortList={this.sortList}
						sortedField={sortedField}
						archived={this.state.archivedFilter ? "archived" : ""}
						classes="coupons-list-table-container"
						content="Coupons"
						showPlaceholder
						placeholderContent={placeholderContent}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={couponsList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
				</div>
			</div>
		);
	}
}
