import React, { useEffect, useState } from "react";

// third party
import moment from "moment";
import { Transition, config, animated } from "react-spring/renderprops";
import { debounce } from "lodash";

// component
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import Image from "../_commons/Image";

// utils
import { fixedToTwoDecimal, formatDate, printCurrency, trackEvent } from "../../atlas-utils";

// services
import NotificationServices from "../../services/NotificationService";

// constants
import { CATALOGUE_PLATFORMS_LOGO, FOODTYPE_MAPS, TRACKING_EVENT_NAMES } from "../../client-config";
import { clientMenu } from "../../client-menu";
import {
	GET_ITEMS_LOCATION_MENU_DETAILS,
	GET_LOCATION_MENUS_LIST,
	GET_MODIFIERS_LOCATION_MENU_DETAILS
} from "../../graphql/locations";
import history from "../../history";
import { FormSidebar } from "../_commons/FormSidebar";
import Placeholder from "../_commons/Placeholder";
import { GET_PARENT_SECTIONS_LIST } from "../../graphql/menus";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import { connect } from "react-redux";
import ListShimmer from "../_commons/ListShimmer";
import { Button } from "../_commons/Button";
import ContextMenu from "../_commons/ContextMenu";
import { Topbar } from "../_commons/Topbar";
import { CommonTable } from "../_commons/CommonTable";
import CreateIcon from "../_commons/CreateIcon";
import { client } from "../../client";
import { Paginator } from "../_commons/Paginator";
import { SelectFilter } from "../_commons/SelectFilter";
import { store } from "../../store/configureStore";
import { SearchFilter } from "../_commons/SearchFilter";

const Menu = ({ handleBrandLocation, handleBrandsLabelOption, isMultibrandEnabled, data, currencySymbol }) => {
	const [locationMenusListData, setLocationMenusListData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [menuView, setMenuView] = useState({ isOpen: false, menuId: null, name: "", description: "" });
	const [menuDetailView, setMenuDetailView] = useState({ isOpen: false, menuId: null });
	const [platformMenuIdMap, setPlatformMenuIdMap] = useState({});

	const locationId = locationMenusListData?.id || data?.id;
	const handleMenuViewClose = () => {
		setMenuView({
			isOpen: false,
			menuId: null,
			name: "",
			description: ""
		});
	};
	const handleMenuDetailViewClose = () => {
		setMenuDetailView({
			isOpen: false
		});
	};

	const createMenuPlatformMap = (locationObj) => {
		const menuIdPlatformsMap = {};
		if (locationObj.publishedMenus) {
			locationObj.publishedMenus.forEach((menu) => {
				const menuId = menu.menuDetails.menuId;
				const platforms = menu.platforms;
				if (!menuIdPlatformsMap[menuId]) {
					menuIdPlatformsMap[menuId] = [];
				}
				menuIdPlatformsMap[menuId] = menuIdPlatformsMap[menuId].concat(platforms);
			});
		}
		if (locationObj.scheduledPublishes) {
			locationObj.scheduledPublishes.forEach((menu) => {
				const menuId = menu.menuDetails.menuId;
				const platforms = menu.platforms;
				if (!menuIdPlatformsMap[menuId]) {
					menuIdPlatformsMap[menuId] = [];
				}
				menuIdPlatformsMap[menuId] = menuIdPlatformsMap[menuId].concat(platforms);
			});
		}
		setPlatformMenuIdMap(menuIdPlatformsMap);
	};

	const fetchLocationMenusList = async () => {
		try {
			setIsLoading(true);
			const variables = {
				id: parseInt(data?.id),
				brandId: isMultibrandEnabled ? String(data?.selectedBrandLocation?.brand?.id) : ""
			};
			const menusListResp = await clientMenu.query({
				query: GET_LOCATION_MENUS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			if (menusListResp?.data?.location) {
				createMenuPlatformMap(menusListResp.data.location);
				setLocationMenusListData(menusListResp.data.location);
			} else {
				NotificationServices.pushNotification({
					message: "Failed to fetch menus list",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			console.log(e);
			NotificationServices.pushNotification({
				message: "Failed to fetch menus list",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};

	useEffect(() => {
		fetchLocationMenusList();
		trackEvent(TRACKING_EVENT_NAMES.LOCATION_DETAILS_MENU_TAB_VIEWS, {});
	}, [data?.selectedBrandLocation]);

	if (isLoading) {
		return (
			<div className="menus-association-container">
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}

	if (
		locationMenusListData?.publishedMenus?.length === 0 &&
		locationMenusListData?.scheduledPublishes?.length === 0
	) {
		return (
			<div className="menus-association-container">
				{isMultibrandEnabled && (
					<div className="brand-selector-container">
						<SelectFilterCustom
							options={data?.brandLocations || []}
							field="selectedBrandLocation"
							currValue={data?.selectedBrandLocation || null}
							setFilter={(f, value) => handleBrandLocation(value)}
							labelKey={"brandName"}
							valueKey={"brandId"}
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							isClearable={false}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							classes="brand-selector"
						/>
					</div>
				)}
				<Placeholder
					placeholderText="No menus are associated to this location!"
					placeholderImageUrl="/assets/empty_states/graphics-empty-catalogue.svg"
					size="medium"
				/>
			</div>
		);
	}

	return (
		<div className="menus-association-container">
			{isMultibrandEnabled && (
				<div className="brand-selector-container">
					<SelectFilterCustom
						options={data?.brandLocations || []}
						field="selectedBrandLocation"
						currValue={data?.selectedBrandLocation || null}
						setFilter={(f, value) => handleBrandLocation(value)}
						labelKey={"brandName"}
						valueKey={"brandId"}
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						isClearable={false}
						renderLabel={handleBrandsLabelOption}
						renderOptions={handleBrandsLabelOption}
						placeholder="Select brand"
						classes="brand-selector"
					/>
				</div>
			)}
			{!!locationMenusListData?.publishedMenus?.length && (
				<Header
					header="Current Menus"
					subHeader="The menus which are published and active on your brand location"
				/>
			)}
			{(locationMenusListData?.publishedMenus ?? []).map((publishedMenu, i) => (
				<MenuInfo key={i} setMenuView={setMenuView} {...publishedMenu} setMenuDetailView={setMenuDetailView} />
			))}
			{!!locationMenusListData?.scheduledPublishes?.length && (
				<Header
					header="Scheduled Menus"
					subHeader="The menus which are scheduled to replace your current menus"
				/>
			)}
			{(locationMenusListData?.scheduledPublishes ?? []).map((publishedMenu, i) => (
				<MenuInfo key={i} setMenuView={setMenuView} {...publishedMenu} setMenuDetailView={setMenuDetailView} />
			))}
			<MenuView
				menuId={menuView?.menuId}
				isOpen={menuView?.isOpen}
				name={menuView?.name}
				description={menuView?.description}
				close={handleMenuViewClose}
			/>
			{menuDetailView?.isOpen && (
				<MenuDetails
					isOpen={menuDetailView?.isOpen}
					close={handleMenuDetailViewClose}
					menuData={{
						locationId,
						menuId: menuDetailView?.menuId,
						name: menuDetailView?.name,
						platforms: platformMenuIdMap[menuDetailView?.menuId] || []
					}}
					currencySymbol={currencySymbol}
					location={locationMenusListData?.name}
				/>
			)}
		</div>
	);
};
export default connect((store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(Menu);

const Header = ({ header, subHeader }) => (
	<div className="header-container">
		<div className="header">{header}</div>
		<div className="sub-header">{subHeader}</div>
	</div>
);

const MenuInfo = ({ menuDetails, platforms = [], publishedAt, publishedBy, setMenuView, setMenuDetailView }) => {
	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
	const openContextMenu = () => {
		setIsContextMenuOpen(!isContextMenuOpen);
	};
	const renderMenuItems = () => {
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={() =>
						setMenuDetailView({
							isOpen: true,
							menuId: menuDetails?.menuId,
							name: menuDetails?.name
						})
					}
				>
					<Image src="/assets/icons/icon-detail.svg" alt="view detail" title="view detail" />
					View Details
				</div>
				<div
					className="action-item"
					onClick={() =>
						setMenuView({
							isOpen: true,
							menuId: menuDetails?.menuId,
							name: menuDetails?.name,
							description: menuDetails?.description
						})
					}
				>
					<Image src="/assets/icons/icon-eye.svg" alt="view menu" title="view menu" />
					View Menu
				</div>
				<div className="action-item" onClick={() => history.push(`/menus/edit/${menuDetails?.menuId}`)}>
					<Image src="/assets/icons/icon-edit-dark--light.svg" alt="edit menu" title="edit menu" />
					Edit Menu
				</div>
			</React.Fragment>
		);
	};
	return (
		<div className="menu-info-container">
			<div className="menu-image-container"></div>
			<div className="menu-metadata-container">
				<div className="title-desc-actions-container">
					<div className="title-desc-container">
						<div className="header">
							{menuDetails?.name}
							{/* <span className='version'>v8</span> */}
						</div>
						<div className="sub-header">{menuDetails?.description}</div>
					</div>
					<div className="actions-container">
						<ContextMenu
							isOpen={isContextMenuOpen}
							data={{
								menuId: menuDetails?.menuId
							}}
							renderMenuItems={renderMenuItems}
							handleOpenMenu={(e) => {
								e.stopPropagation();
								openContextMenu();
							}}
							handleOutsideClick={() => setIsContextMenuOpen(false)}
						/>
					</div>
				</div>
				<div className="item-sections-count-container">
					<Image src="/assets/icons/icon-fork-spoon-mini.svg" className="icon" />
					{menuDetails?.metadata?.categoryCount} sections, {menuDetails?.metadata?.itemCount} items
				</div>
				<div className="platforms-publish-details-container">
					<div className="platforms-container">
						{platforms.map((plf, i) => (
							<Image
								key={i}
								src={CATALOGUE_PLATFORMS_LOGO[plf.toLowerCase()]}
								alt={plf}
								className="platform-image"
							/>
						))}
					</div>
					<div className="publish-info">
						<span className="status">Published</span> &nbsp; on{" "}
						{formatDate(moment(publishedAt), "DD MMM YYYY, HH:MM A")} by {publishedBy}
					</div>
				</div>
			</div>
		</div>
	);
};

const MenuView = ({ menuId = "", isOpen, close = () => {}, name, description }) => {
	const [sectionsData, setSectionsData] = useState([]);
	const [selectedParentSection, setSelectedParentSection] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const updateSelectedSection = (id) => {
		setSelectedParentSection(id);
	};

	const fetchMenuSections = async () => {
		if (!isOpen || !menuId) {
			return;
		}
		setIsLoading(true);
		try {
			const variables = {
				menuId,
				offset: 0,
				limit: 100,
				searchKeyword: ""
			};
			const respParentSectionsDetails = await clientMenu.query({
				query: GET_PARENT_SECTIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			if (respParentSectionsDetails?.data?.categories) {
				const categories = respParentSectionsDetails?.data?.categories;
				let subCategories = {};
				(categories?.objects ?? []).forEach((parentCategory) => {
					parentCategory.subCategories.forEach((subCategory) => {
						subCategories[subCategory?.id] = true;
					});
				});
				const restructuredParentSections = (categories?.objects ?? []).filter(
					(section) => !subCategories[section?.id]
				);
				setSectionsData(respParentSectionsDetails?.data?.categories);
				if (restructuredParentSections?.length > 0) {
					updateSelectedSection(restructuredParentSections?.[0]?.id);
				}
			} else {
				NotificationServices.pushNotification({
					message: "Failed to get menu section details",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchMenuSections();
	}, [menuId, isOpen]);

	let subCategories = {};
	(sectionsData?.objects ?? []).forEach((parentCategory) => {
		parentCategory.subCategories.forEach((subCategory) => {
			subCategories[subCategory?.id] = true;
		});
	});

	const restructuredParentSections = (sectionsData?.objects ?? []).filter((section) => !subCategories[section?.id]);
	const selectedParentSectionData = sectionsData?.objects?.find((section) => section?.id === selectedParentSection);

	const renderMenuDetails = () => {
		if (!!sectionsData?.length) {
			return <Placeholder />;
		}

		return (
			<div className="categories-container">
				<div className="parent-categories-list">
					<div className="parent-sections-list-container">
						{restructuredParentSections.map((val) => (
							<React.Fragment>
								<div
									key={val?.id}
									className={`parent-category ${
										val?.id === selectedParentSection
											? `parent-category--selected ${
													!!val?.subCategories?.length
														? "parent-category--selected-includes-subsection"
														: ""
											  }`
											: ""
									}`}
									onClick={() => updateSelectedSection(val?.id)}
								>
									<span className="parent-category--name">{val?.name}</span>
								</div>
								<Transition
									native
									from={{ height: 0, opacity: 0 }}
									enter={{ height: "auto", opacity: 1 }}
									leave={{ height: 0, opacity: 0, delay: 0 }}
									items={selectedParentSection === val?.id && !!val?.subCategories?.length}
									config={{ ...config.stiff, ...{ duration: 100 } }}
								>
									{(isOpen) =>
										isOpen &&
										((props) => (
											<animated.div className={"sub-sections-list"} style={props}>
												{(val?.subCategories ?? []).map((subCategory, i) => (
													<div className="sub-section" key={subCategory?.id}>
														{subCategory?.name}
														<span
															className={`line--vertical ${
																i === val?.subCategories.length - 1 ? "half" : ""
															}`}
														></span>
													</div>
												))}
											</animated.div>
										))
									}
								</Transition>
							</React.Fragment>
						))}
					</div>
				</div>
				<div
					className={`selected-section-content--container ${
						!!selectedParentSectionData?.items.length || !!selectedParentSectionData?.subCategories.length
							? "selected-section-content--container-flex-start"
							: ""
					}`}
				>
					{selectedParentSectionData?.items.length === 0 &&
					selectedParentSectionData?.subCategories.length === 0 ? (
						<Placeholder
							size="small"
							placeholderText="No items or sub-sections found!"
							placeholderImageUrl="/assets/empty_states/graphics-empty-catalogue.svg"
						/>
					) : !!selectedParentSectionData?.items?.length ? (
						<>
							<div className="header-container">
								<div className="title">{selectedParentSectionData?.name}</div>
								<div className="sub-title">{selectedParentSectionData?.description}</div>
							</div>
							{
								<div className="preview-items-container">
									{selectedParentSectionData?.items?.length === 0 ? (
										<Placeholder placeholderText="No items found!" />
									) : (
										selectedParentSectionData.items.map((item) => (
											<PreviewItem
												key={item?.id}
												imageUrl={item?.imageUrl}
												title={item?.name}
												foodType={item?.type}
												actualPrice={item?.price}
											/>
										))
									)}
								</div>
							}
						</>
					) : (
						<>
							{selectedParentSectionData?.subCategories.map((subCategory) => {
								const subCategoryData = sectionsData?.objects?.find(
									(section) => section?.id === subCategory?.id
								);
								return (
									<>
										<div className="header-container">
											<div className="title">{selectedParentSectionData?.name}</div>
											<div className="sub-title">
												{selectedParentSectionData?.description || "--"}
											</div>
										</div>
										<div className="preview-items-container">
											{subCategoryData?.items?.length === 0 ? (
												<Placeholder placeholderText="No items found!" />
											) : (
												subCategoryData.items.map((item) => (
													<PreviewItem
														key={item?.id}
														imageUrl={item?.imageUrl}
														title={item?.name}
														foodType={item?.type}
														actualPrice={item?.price}
													/>
												))
											)}
										</div>
									</>
								);
							})}
						</>
					)}
				</div>
			</div>
		);
	};

	if (isLoading) {
		return (
			<FormSidebar isOpen={isOpen} isNested close={close} hideActions title={name} subTitle={description}>
				<div className="menu-view-container">
					<ListShimmer />
				</div>
			</FormSidebar>
		);
	}

	return (
		<FormSidebar
			isOpen={isOpen}
			isNested
			close={close}
			hideActions
			title={name}
			subTitle={description}
			headerRight={<Button onClick={() => history.push(`/menus/edit/${menuId}`)}>Configure</Button>}
		>
			<div className="menu-view-container">{renderMenuDetails()}</div>
		</FormSidebar>
	);
};
const PreviewItem = connect((store) => ({
	biz: store.login.loggedInbizDetail
}))(({
	foodType,
	title,
	desc,
	contents,
	imageUrl,
	actualPrice,
	markupPrice,
	isCustomisable,
	showHideCustomisableItemDrawer,
	biz
}) => {
	return (
		<div className="preview-item">
			<div className="item-info">
				<div className="foodtype-title">
					<FoodTypeIndicator foodType={FOODTYPE_MAPS[foodType]} />
					<div className="food-title">{title}</div>
				</div>
				<div className="item-description">{desc}</div>
				<div className="prices-container">
					{markupPrice && <div className="markup-price">{markupPrice}</div>}
					<div className="actual-price">
						{printCurrency(biz.currencySymbol)}
						{fixedToTwoDecimal(actualPrice)}
					</div>
				</div>
				{/* <div className='food-contents'>
					Contents: &nbsp;
					{
						contents.map((content, i) => <span className='content'>{content}{i !== (contents.length - 1) ? ', ' : ''}</span>)
					}
				</div> */}
			</div>
			<div className="item-actions">
				<Image
					src={imageUrl || "/assets/empty-dish.jpg"}
					alt={title}
					className="item-image"
					errorOverrideImage="/assets/empty-dish.jpg"
				/>
			</div>
		</div>
	);
});
const MENU_ITEMS = [
	{
		label: "Items",
		value: "items"
	},
	{
		label: "Modifiers",
		value: "modifiers"
	}
];

const getStatusTag = (available) => {
	if (available) {
		return <div className="available">AVAILABLE</div>;
	} else {
		return <div className="unavailable">UNAVAILABLE</div>;
	}
};

const MenuDetails = ({ isOpen, close, menuData, currencySymbol, location }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(MENU_ITEMS[0].value);
	const [itemsList, setItemsList] = useState({
		count: 0,
		objects: [],
		loading: true
	});
	const [modifiersList, setModifiersList] = useState({
		count: 0,
		objects: [],
		loading: true
	});
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [status, setStatus] = useState({ value: "all", valueForDisplay: "All" });
	const [searchQuery, setSearchQuery] = useState("");

	const bizPlatforms = store.getState().configItems.bizPlatforms?.items || [];
	const filteredPlatforms = bizPlatforms.filter((item) =>
		menuData.platforms.includes(item.platformName.toLowerCase())
	);
	const [selectedPlatform, setSelectedPlatform] = useState(filteredPlatforms?.[0] || {});

	const isItemsTab = selectedTab === MENU_ITEMS[0].value;

	const placeholderContent = {
		placeholderText: isItemsTab ? "No items added!" : "No modifiers added!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
		redirectionLink: "/piper-academy/items",
		redirectionLinkText: "learn more about items",
		size: "medium"
	};
	const getStructuredData = (type) => {
		if (type === "item") {
			return itemsList?.objects?.map((item) => {
				return {
					id: item?.id,
					imageUrl: item?.imageUrl,
					title: item?.itemTitle,
					price: item?.itemPrice,
					available: item?.available,
					foodType: item?.foodType,
					crmTitle: item?.crmTitle
				};
			});
		} else {
			return modifiersList?.objects?.map((modifier) => {
				return {
					id: modifier?.id,
					imageUrl: modifier?.platformImages?.[0]?.image,
					title: modifier?.optionTitle,
					price: modifier?.price,
					available: modifier?.availableAtLocation,
					foodType: modifier?.foodType,
					crmTitle: modifier?.crmTitle
				};
			});
		}
	};
	const fetchModifiersLocationMenuDetail = async (
		limit = 10,
		offset = 0,
		search = "",
		platform,
		availableForLocationSelection
	) => {
		setIsLoading(true);
		try {
			const variables = {
				menuId: menuData?.menuId,
				limit,
				offset,
				locationId: menuData?.locationId
			};
			if (search !== "") {
				variables.search = [{ key: "default", value: search }];
			}
			if (platform?.platformName) {
				variables.platform = platform.platformName;
			}
			if (availableForLocationSelection?.value !== "all" && availableForLocationSelection?.value) {
				if (availableForLocationSelection?.value === "available") {
					variables.filters = [
						{
							field: "location_data__available",
							value: true
						}
					];
				} else {
					variables.filters = [
						{
							field: "location_data__available",
							value: false
						}
					];
				}
			}
			const resp = await client.query({
				query: GET_MODIFIERS_LOCATION_MENU_DETAILS,
				variables,
				fetchPolicy: "no-cache"
			});
			setModifiersList({
				loading: resp?.loading,
				objects: resp?.data?.modifiers?.objects || [],
				count: resp?.data?.modifiers?.count || 0
			});
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			NotificationServices.pushNotification({
				message: error.message || "Something went wrong.",
				timeout: 5000,
				type: "error",
				theme: "dark"
			});
		}
	};

	const fetchItemsLocationMenuDetail = async (
		limit = 10,
		offset = 0,
		search = "",
		platform,
		availableForLocationSelection
	) => {
		try {
			setIsLoading(true);
			const variables = {
				menuId: menuData?.menuId,
				limit,
				offset,
				locationId: menuData?.locationId
			};
			if (search !== "") {
				variables.search = [{ key: "default", value: search }];
			}
			if (platform?.platformName) {
				variables.platform = platform.platformName;
			}
			if (availableForLocationSelection?.value !== "all" && availableForLocationSelection?.value) {
				if (availableForLocationSelection?.value === "available") {
					variables.filters = [
						{
							field: "related_location_associations__available",
							value: true
						}
					];
				} else {
					variables.filters = [
						{
							field: "related_location_associations__available",
							value: false
						}
					];
				}
			}
			const resp = await client.query({
				query: GET_ITEMS_LOCATION_MENU_DETAILS,
				variables,
				fetchPolicy: "no-cache"
			});
			setItemsList({
				loading: resp?.loading,
				objects: resp?.data?.items?.objects || [],
				count: resp?.data?.items?.count || 0
			});
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			NotificationServices.pushNotification({
				message: error.message || "Something went wrong.",
				timeout: 5000,
				type: "error",
				theme: "dark"
			});
		}
	};
	const fetchModifiersLocationMenuDetailDebounced = debounce(
		(limit = 10, offset = 0, search = [], platform, status) =>
			fetchModifiersLocationMenuDetail(limit, offset, search, platform, status),
		500
	);
	const fetchItemsLocationMenuDetailDebounced = debounce(
		(limit = 10, offset = 0, search = [], platform, status) =>
			fetchItemsLocationMenuDetail(limit, offset, search, platform, status),
		500
	);
	const handlePageSize = async (field, size) => {
		setLimit(size?.value);
		if (isItemsTab) {
			await fetchItemsLocationMenuDetail(size?.value, offset, searchQuery, selectedPlatform, status);
		} else {
			await fetchModifiersLocationMenuDetail(size?.value, offset, searchQuery, selectedPlatform, status);
		}
	};
	const handlePagination = async (page) => {
		const newOffset = (page - 1) * limit;
		setOffset(newOffset);
		if (isItemsTab) {
			await fetchItemsLocationMenuDetail(limit, newOffset, searchQuery, selectedPlatform, status);
		} else {
			await fetchModifiersLocationMenuDetail(limit, newOffset, searchQuery, selectedPlatform, status);
		}
	};

	useEffect(() => {
		if (isItemsTab) {
			fetchItemsLocationMenuDetail(10, 0, "", selectedPlatform, status);
		} else {
			fetchModifiersLocationMenuDetail(10, 0, "", selectedPlatform, status);
		}
	}, [selectedTab]);
	useEffect(() => {
		if (isItemsTab) {
			fetchItemsLocationMenuDetailDebounced(limit, offset, searchQuery, selectedPlatform, status);
		} else {
			fetchModifiersLocationMenuDetailDebounced(limit, offset, searchQuery, selectedPlatform, status);
		}
	}, [searchQuery, selectedPlatform, status]);
	const columns = [
		{
			name: "Items",
			field: "item",
			render: (record) => {
				return (
					<div className="at-table-cell at-cell-text item">
						<div className="item-wrapper">
							<Image
								src={record?.imageUrl || "/assets/empty-dish.jpg"}
								alt={record?.title}
								className={!record?.available ? "item-img-unavailable" : ""}
								errorOverrideImage="/assets/empty-dish.jpg"
							/>
							<FoodTypeIndicator foodType={FOODTYPE_MAPS[record?.foodType]} />
							<div className="item-details">
								<p className="title">{record?.title}</p>
								{record?.crmTitle && <p className="crm-title">CRM Title: {record?.crmTitle}</p>}
							</div>
						</div>
					</div>
				);
			}
		},
		{
			name: "Price",
			field: "price",
			render: (record) => {
				return (
					<div className="at-table-cell at-cell-text price">
						{printCurrency(currencySymbol)}
						{record?.price}
					</div>
				);
			}
		},
		{
			name: "Status",
			field: "status",
			render: (record) => {
				return <div className="at-table-cell at-cell-text status">{getStatusTag(record?.available)}</div>;
			}
		}
	];
	const handleStatusFilter = (field, value) => {
		setStatus(value);
	};
	const handlePlatform = (value) => {
		setSelectedPlatform(value);
	};
	const handleEntitySearch = (field, value) => {
		setSearchQuery(value);
	};
	const handlePlatformsLabelOption = (plf) => {
		return (
			<React.Fragment>
				<div className="logo">
					<img
						src={
							plf?.logo ||
							CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
							"/assets/icons/icons8-globe-40.png"
						}
						alt=""
					/>
				</div>
				<div title={plf.platformName}>
					{plf.platformName && plf.platformName.length > 25
						? plf.platformName.slice(0, 25) + "..."
						: plf.platformName}
				</div>
			</React.Fragment>
		);
	};

	return (
		<FormSidebar
			loading={isLoading}
			isOpen={isOpen}
			isNested
			close={close}
			hideActions
			title={menuData?.name}
			subTitle="Check availability of items and modifiers"
			breadcrumbs={[
				{
					title: location,
					onClick: () => {}
				}
			]}
		>
			<div className="menu-detail-container">
				<div className="filters-wrapper">
					<div className="filters-wrapper-left">
						<SelectFilter
							options={[
								{ value: "all", valueForDisplay: "All" },
								{ value: "available", valueForDisplay: "Available" },
								{ value: "unavailable", valueForDisplay: "Unavailable" }
							]}
							field="available"
							currValue={{
								value: status?.value,
								valueForDisplay: (
									<span>
										<span style={{ color: "#979797" }}>Status </span>
										{status?.valueForDisplay}
									</span>
								)
							}}
							labelKey="valueForDisplay"
							valueKey="value"
							setFilter={handleStatusFilter}
							isSearchable={false}
							isClearable={false}
							classes="status-filter"
						/>
						{filteredPlatforms?.length > 0 && (
							<div className="platform-selector-wrapper">
								<SelectFilterCustom
									options={filteredPlatforms}
									// isLoading={isLoading}
									field="platforms"
									currValue={selectedPlatform || null}
									setFilter={(f, value) => handlePlatform(value)}
									labelKey={"platformName"}
									valueKey={"id"}
									isSearchable={false}
									customLabel={true}
									customOptions={true}
									renderLabel={handlePlatformsLabelOption}
									renderOptions={handlePlatformsLabelOption}
									placeholder="Select platform"
								/>
							</div>
						)}
					</div>
					<SearchFilter
						placeholder="Search"
						filterOption={{
							field: "default"
						}}
						setFilter={handleEntitySearch}
						value={searchQuery}
					/>
				</div>
				<Topbar
					tabs={MENU_ITEMS}
					selectedTab={selectedTab}
					switchTab={(tab) => {
						setSelectedTab(tab.value);
					}}
				/>

				<div className="table-wrapper">
					<CommonTable
						loading={isItemsTab ? itemsList?.loading : modifiersList?.loading}
						data={isItemsTab ? getStructuredData("item") : getStructuredData()}
						columns={columns}
						classes="menu-detail-list-table-container"
						showPlaceholder
						placeholderContent={placeholderContent}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={itemsList?.count}
						goToPage={handlePagination}
						setPageSize={handlePageSize}
						showPageSize={true}
					/>
				</div>
			</div>
		</FormSidebar>
	);
};
