import React from "react";

// components
import { Button } from "../_commons/Button";

// third party
import { useTrail, config, animated } from "react-spring";

export const BulkOperationSuccess = ({
	title,
	subTitle,
	uploadedFileName = "",
	saveChanges,
	data = [],
	loading,
	willCreate,
	willUpdate,
	disabled = false
}) => {
	return (
		<div className="bulk-operation-success">
			<Header title={title} subTitle={subTitle} />
			<div className="uploaded-file">
				<div className="header">
					<div className="header-text">Uploaded File</div>
					<div className="subheader-text">
						<span className="uploaded-file-icon">
							<img src="/assets/icons/paper-clip.svg" alt="" />
						</span>
						<span>{uploadedFileName || "--"}</span>
						<span className="uploaded-file-status success">Uploaded successfully</span>
					</div>
				</div>
				<Table loading={loading} data={data} />
			</div>
			{/* <div className="import-files">
				<div className="header">
					<div className="header-text">
						Ready to Import Files
					</div>
					<div className="subheader-text">
						Everything looks good. Click "Save Changes" to persist all your updates.
						<span className="header-action-button">
							<div className="action-buttons-container">
								<Button
									clickHandler={saveChanges}
									classes={disabled ? "disabled" : ""}
								>
									Save Changes
								</Button>
							</div>
						</span>
					</div>
				</div>
				<div className="label-types">
					<div className="label-types-container will-create">
						<span>Will Create</span>
						<span>{willCreate || 0}</span>
					</div>
					<div className="label-types-container will-update">
						<span>Will Update</span>
						<span>{willUpdate || 0}</span>
					</div>
				</div>
			</div> */}
		</div>
	);
};

const Header = ({ title, subTitle }) => (
	<div className="credits-section-header transactions-list-header">
		<div className="header-text">
			<div className="title" title={title || ""}>
				{title || "--"}
			</div>
			<div className="subtitle" title={subTitle || ""}>
				{subTitle || "--"}
			</div>
		</div>
	</div>
);

const Table = ({ data, loading }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container template-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={i}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Updates found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = () => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text message`}>Message</div>
	</div>
);

const TableList = ({ message, style }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text message" title={message || ""}>
				{message || "--"}
			</div>
		</animated.div>
	);
};
