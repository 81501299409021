export const syllabus = {
	courses: [
		{
			title: "Catalogue Management",
			code: "catalogue",
			paths: [
				"catalogue",
				"categories",
				"items",
				"modifier-groups",
				"modifiers",
				"taxes",
				"charges",
				"item-groups",
				"location-groups",
				"schedules",
				"locations"
			]
		},
		{
			title: "Hub Management",
			code: "hub",
			paths: ["locations"]
		},
		{
			title: "Access Management",
			code: "access",
			paths: ["access"]
		},
		{
			title: "App Management",
			code: "app",
			paths: ["app"]
		},
		{
			title: "Analytics",
			code: "analytics",
			paths: ["analytics"]
		},
		{
			title: "Meraki Management",
			code: "meraki",
			paths: ["meraki"]
		},
		{
			title: "Reconciliation",
			code: "reconciliation",
			paths: ["reconciliation"]
		},
		{
			title: "Brand Management",
			code: "multi-brand",
			paths: ["multi-brand"]
		},
		{
			title: "Food Preparation Time",
			code: "food-prep-time",
			paths: ["food-prep-time"]
		}
	],
	lessons: [
		{
			id: "catalogue-management",
			title: "Intro to Catalogue Management",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:17": "Customer journey",
				"01:19": "Catalogue sections on Atlas"
			},
			courses: ["catalogue"],
			path: "catalogue",
			url: "https://urbanpiper.portal.trainn.co/share/fea766fc-d6c8-4f4a-b0f7-03a71f5152cc/embed",
			searchKeywords: ["catalogue"]
		},
		{
			id: "categories",
			title: "Categories",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:18": "Navigation",
				"01:09": "Create category",
				"02:25": "Associate items to a category",
				"03:30": "Sub-categories",
				"06:46": "Archive/Restore",
				"09:29": "Bulk actions",
				"10:32": "Summary"
			},
			courses: ["catalogue"],
			path: "categories",
			url: "https://urbanpiper.portal.trainn.co/share/5db29175-a223-4ae2-9485-72a7fd860a79/embed",
			searchKeywords: ["add category", "categories", "sub-categories"]
		},
		{
			id: "items",
			title: "Items",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:18": "Navigation",
				"00:49": "Create item",
				"03:41": "Images",
				"04:59": "Additional info for Meraki",
				"05:23": "Taxes & charges",
				"05:58": "Associate locations to items",
				"07:05": "Update price of an item at a specific location",
				"07:57": "Associate platforms to an item",
				"08:29": "Associate modifier groups to an item",
				"09:25": "Recommended items for Meraki",
				"10:08": "Archive/Restore",
				"10:26": "Bulk actions",
				"11:30": "Summary"
			},
			courses: ["catalogue"],
			path: "items",
			url: "https://urbanpiper.portal.trainn.co/share/c7830d3f-6fce-436c-a63c-1663ca1ca924/embed",
			searchKeywords: ["add item", "items", "images", "meraki"]
		},
		{
			id: "modifier-groups",
			title: "Modifier Groups",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:14": "Navigation",
				"00:41": "Variants & Add-ons",
				"01:38": "Create modifier group",
				"02:32": "Associate modifiers to a modifier group",
				"03:17": "Associate items to a modifier group",
				"03:45": "Min and max range for add-ons",
				"05:24": "Archive/Restore",
				"05:37": "Bulk actions",
				"06:31": "Summary"
			},
			courses: ["catalogue"],
			path: "modifier-groups",
			url: "https://urbanpiper.portal.trainn.co/share/a612a39c-ae99-4854-9499-a7afdc33b1b1/embed",
			searchKeywords: ["add modifier group", "modifier groups", "variants", "add-on"]
		},
		{
			id: "modifiers",
			title: "Modifiers",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:13": "Navigation",
				"00:33": "Create modifier",
				"01:38": "Associate modifier groups to a modifier",
				"02:08": "Associate locations to a modifier",
				"02:56": "Update price of a modifier at a specific location",
				"03:09": "Archive/Restore",
				"03:27": "Bulk actions",
				"04:27": "Summary"
			},
			courses: ["catalogue"],
			path: "modifiers",
			url: "https://urbanpiper.portal.trainn.co/share/be686732-1b40-43f9-9ccd-1eb67bf9fee2/embed",
			searchKeywords: ["add modifier", "modifiers"]
		},
		{
			id: "taxes",
			title: "Taxes",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:11": "Navigation",
				"00:26": "Create tax",
				"01:05": "Restrict tax applicability, based on items and locations",
				"02:06": "Archive/Restore",
				"02:15": "Summary"
			},
			courses: ["catalogue"],
			path: "taxes",
			url: "https://urbanpiper.portal.trainn.co/share/e5e6fed6-9da2-4704-97d9-9b2ec2db5603/embed",
			searchKeywords: ["add tax", "taxes"]
		},
		{
			id: "charges",
			title: "Charges",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:11": "Navigation",
				"00:28": "Create charge",
				"01:13": "Restrict a charge for specific items and locations",
				"02:17": "Summary"
			},
			courses: ["catalogue"],
			path: "charges",
			url: "https://urbanpiper.portal.trainn.co/share/dd6411f3-465e-4899-aa5f-bf4fe058e790/embed",
			searchKeywords: ["add charge", "charges"]
		},
		{
			id: "item-groups",
			title: "Item Groups",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:12": "Navigation",
				"00:42": "Create item group",
				"00:58": "Associate items to an item group",
				"01:46": "Delete item group",
				"02:09": "Summary"
			},
			courses: ["catalogue"],
			path: "item-groups",
			url: "https://urbanpiper.portal.trainn.co/share/85701973-e9ef-4bc6-8436-4f6eeaad70f8/embed",
			searchKeywords: ["add item group", "item groups"]
		},
		{
			id: "location-groups",
			title: "Location Groups",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:13": "Navigation",
				"00:38": "Create location group",
				"00:51": "Associate locations to a location group",
				"01:22": "Delete location group",
				"01:43": "Summary"
			},
			courses: ["catalogue"],
			path: "location-groups",
			url: "https://urbanpiper.portal.trainn.co/share/6bc22eb3-367f-43e3-b737-cfb9346255d7/embed",
			searchKeywords: ["add location group", "location groups"]
		},
		{
			id: "schedules",
			title: "Schedules",
			description: "",
			chapters: {
				"00:10": "Use of schedules",
				"00:43": "Create a Schedule",
				"01:35": "Associate Locations",
				"01:57": "Sync Schedule",
				"02:17": "Holiday Schedules",
				"03:28": "Archive/Restore Schedules"
			},
			courses: ["catalogue"],
			path: "schedules",
			url: "https://urbanpiper.portal.trainn.co/share/CgCsldD877QRiIN4JZyTFA/embed",
			searchKeywords: ["schedules"]
		},
		{
			id: "user-access",
			title: "User Access",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:13": "Navigation",
				"00:34": "Invite a user",
				"01:25": "Accepting a user invite",
				"02:38": "Resend or cancel pending invites",
				"03:17": "User roles",
				"04:03": "Update role of a user",
				"04:59": "Summary"
			},
			courses: ["access"],
			path: "access",
			url: "https://urbanpiper.portal.trainn.co/share/c3251255-c367-4f98-a9a7-6296044446e5/embed",
			searchKeywords: ["add user", "invite", "roles", "users"],
			newVersion: {
				id: "user-access",
				title: "User Access and Invites",
				description: "",
				chapters: {
					"00:00": "Navigation to users section",
					"00:28": "Users section",
					"00:53": "Inviting a new user",
					"02:16": "Accepting an invite",
					"03:22": "Pending invites",
					"03:43": "Resending and deleting pending invites",
					"04:05": "Atlas roles",
					"05:11": "Updating roles of users"
				},
				courses: ["access"],
				path: "access",
				url: "https://urbanpiper.portal.trainn.co/share/LxkYlUuPxApxCsWZj54DOQ/embed",
				searchKeywords: ["add user", "invite", "roles", "users"]
			}
		},
		{
			id: "app-switcher",
			title: "App switcher on Atlas and Prime",
			description: "",
			chapters: {
				"00:00": "Switching an app from Atlas",
				"00:56": "Switching an app from Prime"
			},
			courses: ["app"],
			path: "app",
			url: "https://urbanpiper.portal.trainn.co/share/VUl0rG7RtMewMZommKUAdQ/embed",
			searchKeywords: ["switch app", "atlas", "prime"]
		},
		{
			id: "publish-catalogue",
			title: "Publish Catalogue",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:17": "Publish catalogue for single location",
				"00:58": "Publish catalogue across multiple locations",
				"01:53": "Publish logs"
			},
			courses: ["hub", "catalogue"],
			path: "locations",
			url: "https://urbanpiper.portal.trainn.co/share/4153fbac-c111-403b-8952-81ddb4e85c75/embed",
			searchKeywords: ["locations", "stores", "publish catalogue", "logs"]
		},
		{
			id: "store-toggle",
			title: "Store Toggle",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:32": "Store toggle at single location",
				"01:17": "Store toggle at multiple locations, across multiple platforms",
				"02:19": "Logs",
				"02:58": "Quick glance of store status",
				"03:26": "Summary"
			},
			courses: ["hub"],
			path: "locations",
			url: "https://urbanpiper.portal.trainn.co/share/1656ef8e-a78f-4ebd-b41e-26582b4bcfdc/embed",
			searchKeywords: ["locations", "stores", "aggregators", "channels", "platforms"]
		},
		{
			id: "analytics",
			title: "Analytics",
			description: "",
			chapters: {
				"00:16": "Navigation",
				"00:43": "Customise view",
				"01:10": "Revenue & Orders",
				"02:46": "Operations",
				"03:36": "Catalogue",
				"03:51": "Item analytics",
				"04:15": "Location & item performance"
			},
			courses: ["analytics"],
			path: "analytics",
			url: "https://urbanpiper.portal.trainn.co/share/Ttfec1GOWc1QSObZ7iAISQ/embed",
			searchKeywords: ["analytics"]
		},
		{
			id: "meraki-web-editor",
			title: "Intro to Meraki Web Editor",
			description: "",
			chapters: {
				"00:00": "Intro",
				"00:16": "Navigation",
				"00:20": "Customize Workspace",
				"01:20": "Customize Top Bar",
				"01:35": "Customize Header",
				"01:37": "Customize Brand Logo",
				"01:56": "Customize Font & Colors",
				"03:06": "Create Custom Page",
				"05:00": "Customize Page Settings",
				"05:27": "Add SEO Settings Page-wise",
				"05:42": "Customize display Labels",
				"06:17": "Handle third party Apps plugins",
				"06:37": "More module Settings",
				"06:52": "Publish the site",
				"07:08": "View your store",
				"07:15": "Preview Versions",
				"07:28": "Add live Domain",
				"07:55": "Your online store is now Live!"
			},
			courses: ["meraki"],
			path: "meraki",
			url: "https://urbanpiper.portal.trainn.co/share/723be9e1-8762-4bca-bdaa-1fc075f5e661/embed",
			searchKeywords: ["meraki", "web", "website", "editor"]
		},
		{
			id: "reconciliation-intro",
			title: "Introduction to Reconciliation",
			description: "",
			chapters: {
				"00:00": "Why automate reconciliation?",
				"00:43": "UrbanPiper's Reconciliation tool"
			},
			courses: ["reconciliation"],
			path: "reconciliation",
			url: "https://urbanpiper.portal.trainn.co/share/T5xI68UxoAeO5nW4pBAciA/embed",
			searchKeywords: ["reconciliation"]
		},
		{
			id: "download-swiggy-payouts",
			title: "Checking payouts - Swiggy",
			description: "",
			chapters: {},
			courses: ["reconciliation"],
			path: "reconciliation",
			url: "https://www.youtube.com/embed/VQtACCtC1wg",
			searchKeywords: ["reconciliation"],
			isExternalUrl: true
		},
		{
			id: "download-zomato-payouts",
			title: "Checking payouts - Zomato",
			description: "",
			chapters: {},
			courses: ["reconciliation"],
			path: "reconciliation",
			url: "https://www.youtube.com/embed/khF4QOpxgDw",
			searchKeywords: ["reconciliation"],
			isExternalUrl: true
		},
		{
			id: "reconciliation-walkthrough",
			title: "Reconciliation walkthrough",
			description: "",
			chapters: {
				"00:06": "Navigating to reconciliation payouts",
				"00:22": "Upload a sheet",
				"01:36": "Checking uploaded payout sheets",
				"02:32": "Order-wise Reconciliation",
				"03:18": "Resolution"
			},
			courses: ["reconciliation"],
			path: "reconciliation",
			url: "https://urbanpiper.portal.trainn.co/share/hNeDJqS6ga6GIscJMEGhMw/embed",
			searchKeywords: ["reconciliation"]
		},
		{
			id: "autoforwarding-payouts-swiggy",
			title: "Setting up Auto-forwarding of Swiggy payouts",
			description: "",
			chapters: {
				"00:00": "Setting auto-forwarding address",
				"00:49": "Confirmation of auto-forwarding",
				"01:15": "Setting filters for auto-forwarding",
				"01:45": "Final checks"
			},
			courses: ["reconciliation"],
			path: "reconciliation",
			url: "https://urbanpiper.portal.trainn.co/share/qAU8BZ6Pr7KC4wKNqHnD0w/embed",
			searchKeywords: ["reconciliation"]
		},
		{
			id: "autoforwarding-payouts-zomato",
			title: "Setting up Auto-forwarding of Zomato payouts",
			description: "",
			chapters: {
				"00:00": "Setting auto-forwarding address",
				"00:50": "Confirmation of auto-forwarding",
				"01:12": "Setting filters for auto-forwarding",
				"01:50": "Final checks"
			},
			courses: ["reconciliation"],
			path: "reconciliation",
			url: "https://urbanpiper.portal.trainn.co/share/gLjDfSbf5QIQoJUwPxsk1g/embed",
			searchKeywords: ["reconciliation"]
		},
		{
			id: "create-brand",
			title: "Create Brand",
			description: "",
			chapters: {
				"00:29": "Navigation",
				"00:40": "Create Brand",
				"00:45": "Add Brand Details",
				"01:01": "Associate Locations",
				"01:44": "Summary"
			},
			courses: ["multi-brand"],
			path: "multi-brand",
			url: "https://urbanpiper.portal.trainn.co/share/eqPoaI8uNe7EST104lcScw/embed",
			searchKeywords: ["multi-brand"]
		},
		{
			id: "create-location",
			title: "Create Location",
			description: "",
			chapters: {
				"00:10": "Navigation",
				"00:17": "Create Location",
				"00:32": "Add Location Details",
				"00:58": "Associate Brands",
				"01:11": "Copy Catalogue",
				"01:37": "Associate Platforms",
				"02:02": "Location Detail View",
				"02:24": "Manage Brands",
				"02:48": "Manage Catalogue",
				"02:59": "View Logs",
				"03:22": "Brand and Platform Filters"
			},
			courses: ["multi-brand"],
			path: "multi-brand",
			url: "https://urbanpiper.portal.trainn.co/share/anIlB1eiQBrRCP6aAN7PBw/embed",
			searchKeywords: ["multi-brand"]
		},
		{
			id: "Introduction-to-Busy-Mode",
			title: " Introduction to Food Preparation Time",
			description: "",
			chapters: {
				"00:00": "Why use Busy Mode?",
				"00:21": "Setting up preparation times on Atlas",
				"0:34": "Toggling between preparation modes on Prime",
				"01:12": "Platforms which support food order preparation time"
			},
			courses: ["food-prep-time"],
			path: "food-prep-time",
			url: "https://urbanpiper.portal.trainn.co/share/BOX9Uuoic7cf8gVEFbS9bw/embed",
			searchKeywords: ["busy prep time", "time", "hub", "location"]
		},
		{
			id: "food-preparation-time-at-order-level",
			title: "Food preparation time at order level (on Prime)",
			description: "",
			chapters: {
				"00:11": "Setting order level food preparation time",
				"00:35": "Directly acknowledging an order",
				"00:43": "Platforms which support order level preparation time",
				"00:50": "Toggling between preparation modes"
			},
			courses: ["food-prep-time"],
			path: "food-prep-time",
			url: "https://urbanpiper.portal.trainn.co/share/1pvZZX5ev6cG9OQZoHzWnw/embed",
			searchKeywords: ["busy prep time", "time", "hub", "prime"]
		},
		// placeholder lesson
		{
			id: "",
			title: "",
			description: "",
			chapters: {},
			courses: [""],
			path: "",
			url: "",
			searchKeywords: []
		}
	]
};
