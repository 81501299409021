import React, { Component } from "react";

// third party
import { connect } from "react-redux";

// constants
const CANNY_ATLAS_BOARD_TOKEN = process.env.REACT_APP_CANNY_ATLAS_BOARD_TOKEN;

@connect((store) => ({
	login: store.login
}))
export default class Feedback extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeout: 10000
		};
	}

	componentDidMount() {
		// check if canny sdk is loaded
		this.checkCannyExists();
	}

	checkCannyExists = () => {
		if (window.Canny) {
			// canny is loaded, proceed to render canny widget
			// also cancel any timer if it exists
			if (this.cannyTimeout) {
				clearTimeout(this.cannyTimeout);
			}
			this.renderCanny();
		} else {
			// canny is not loaded yet, now check if its timedout
			if (this.state.timeout <= 0) {
				// if timedout then return and dont do anything
				return;
			} else {
				// if not timedout then reduce the available time for timeout and
				// set another timer to check canny
				this.setState({
					timeout: this.state.timeout - 1000
				});
				this.cannyTimeout = setTimeout(this.checkCannyExists, 1000);
			}
		}
	};

	renderCanny = () => {
		const {
			loginDetail: { cannySsoToken }
		} = this.props.login;
		window.Canny("render", {
			boardToken: CANNY_ATLAS_BOARD_TOKEN,
			basePath: "/feedback",
			ssoToken: cannySsoToken
		});
	};

	render() {
		return (
			<div className="feedback-section section-container-common">
				<div data-canny />
			</div>
		);
	}
}
