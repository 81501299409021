import React, { useState, useEffect } from "react";

// third party
import { connect } from "react-redux";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { InputWithLabel } from "../components/_commons/InputWithLabel";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// graphql
import { ADD_NEW_USER } from "../graphql/customers";

const CustomerCreate = ({ bizDetail }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [data, setData] = useState({
		phoneNumber: ""
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	const handleCancel = () => {
		setFormState(false);
		setTimeout(() => {
			history.push("/customers");
		}, 100);
		setFormTouched(false);
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		if (data.phoneNumber.length !== 0) {
			setConfirmLoading(true);
			try {
				const variables = {
					phone: data.phoneNumber
				};
				const resp = await client.mutate({
					mutation: ADD_NEW_USER,
					variables
				});
				if (resp.data.saveOfflineUser.status.success) {
					setConfirmLoading(false);
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Customer added",
							timeout: 5000,
							error: false
						}
					});
					setFormTouched(false);
					history.push(`/customers/${resp.data.saveOfflineUser.object.id}`);
				} else {
					setConfirmLoading(false);
					setError(parseErrorMessages(resp.data.saveOfflineUser.status.messages));
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
				setConfirmLoading(false);
			}
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Phone number field cannot be empty",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const validations = error.fields || {};
	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Customer"
			subTitle="Add a new customer"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content customer-create-container">
				<div className="form-row row-full">
					<InputWithLabel
						classes="user-text-input at-input--label"
						value={data.phoneNumber}
						onChange={(e) => {
							handleForm("phoneNumber", e.target.value);
						}}
						field="phoneNumber"
						type="number"
						requiredLabel={true}
						validationMessage={validations?.phoneNumber || ""}
						showLabel={true}
						symbol={bizDetail?.bizIsdCode || ""}
					>
						Phone Number
					</InputWithLabel>
				</div>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	bizDetail: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(CustomerCreate);
