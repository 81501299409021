import React, { useState, useEffect, useCallback, useImperativeHandle } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CustomerInfoItem } from "./Summary";

// utils
import { client } from "../../client";
import { formatDate } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { STATUS_UPDATE } from "../../graphql/transactions";

// actions
import { ActionTypes } from "../../actions/_types";

const StatusUpdate = ({ isOpen = false, close, id, nestedRef }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});

	const fetchStatusUpdate = useCallback(async () => {
		try {
			setLoading(true);
			const variables = { id: parseInt(id) };
			const resp = await client.query({
				query: STATUS_UPDATE,
				variables,
				fetchPolicy: "no-cache"
			});
			setData(resp.data.orderStatusUpdate);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [id]);

	useEffect(() => {
		if (isOpen) {
			fetchStatusUpdate();
		}
	}, [fetchStatusUpdate, isOpen]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setLoading(false);
			setData({});
			close(refresh);
		},
		[close]
	);

	useImperativeHandle(
		nestedRef,
		() => ({
			handleClose
		}),
		[handleClose]
	);

	const renderSubTitle = () => {
		if (data.externalLogs && data.externalLogs.length > 0) {
			return (
				<div className="description">
					{data.externalLogs[0].responseStatusCode && (
						<div
							className={
								"status-code " +
								(data?.externalLogs?.[0]?.responseStatusCode === 200 ? "success" : "error")
							}
						>
							Status code: <span>{data?.externalLogs?.[0]?.responseStatusCode}</span>
						</div>
					)}
					{data.externalLogs[0].elapsedTime && (
						<div className="elapsed-time">
							Elapsed time: <span>{data?.externalLogs?.[0]?.elapsedTime}ms</span>
						</div>
					)}
					{data.externalLogs[0].created && (
						<div className="logged-time">
							Logged at:{" "}
							<span>
								{formatDate(
									data?.externalLogs?.[0]?.created.split("+")[0],
									"DD MMM, YYYY - hh:mm:ss A"
								)}
							</span>
						</div>
					)}
				</div>
			);
		}
		return <div className="description">View status updates for this order</div>;
	};

	return (
		<div className="status-update-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				title="External Status Update Details"
				subTitle={renderSubTitle()}
				hideActions={true}
				isNested={true}
			>
				{loading && (
					<div className="P(25px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{!loading && data.externalLogs && data.externalLogs.length === 0 && (
					<div className="no-items-placeholder">No Status updates found</div>
				)}
				<div className="details-field-grid status-updates-info">
					<CustomerInfoItem
						label="URL"
						value={data.externalLogs && data.externalLogs.length > 0 ? data.externalLogs[0].requestUrl : ""}
						isFullRow={true}
					/>
				</div>
				{data.externalLogs && data.externalLogs.length > 0 && (
					<div className="response">
						<div className="label">Response</div>
						<div className="json-container headers">
							{data.externalLogs[0].responseBody && (
								<pre>{JSON.stringify(JSON.parse(data.externalLogs[0].responseBody), null, 4)}</pre>
							)}
						</div>
					</div>
				)}
			</FormSidebar>
		</div>
	);
};
export default StatusUpdate;
