import React from "react";
import FilterIcon from "../_icons/FilterIcon";

export const Button = ({
	clickHandler = () => {},
	classes,
	type = "primary",
	children,
	loading = false,
	showCustomTooltip = false,
	tooltipInfo = "",
	tooltipPosition = "down-right",
	size = "",
	persistChildrenWhileLoading = false,
	filterCount = 0,
	...rest
}) =>
	showCustomTooltip ? (
		<div
			onClick={clickHandler || void 0}
			className={
				`at-btn ${type === "secondary" ? "at-btn--secondary " : ""}` +
				(classes || "") +
				(loading ? " no-click" : "")
			}
			aria-label={tooltipInfo}
			data-balloon-pos={tooltipPosition}
		>
			{loading ? (
				<div className="loading-container">
					<div className="at-button-loading"></div>
				</div>
			) : (
				children
			)}
		</div>
	) : (
		<div
			onClick={() => clickHandler() || void 0}
			className={
				`at-btn ${size} ${type === "secondary" ? "at-btn--secondary " : ""} ${
					type === "filter" ? "at-btn--secondary at-btn--filter " : ""
				} ${filterCount > 0 ? "filter-applied " : ""}` +
				(classes || "") +
				(loading ? " no-click" : "")
			}
			{...rest}
		>
			{loading ? (
				<div className={"base-row base-gap-normal"}>
					<div className={`${persistChildrenWhileLoading ? "" : "loading-container"}`}>
						<div className="at-button-loading"></div>
					</div>
				</div>
			) : type === "filter" ? (
				<span>
					<FilterIcon />
					{children}
					{filterCount > 0 && <span className="filter-count">{filterCount > 9 ? "9+" : filterCount}</span>}
				</span>
			) : (
				children
			)}
		</div>
	);
