import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, extractInitials } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_NON_ASSOCIATED_BRANDS_LIST, ASSOCIATE_BRANDS_LOCATIONS } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { BRAND_COLORS } from "../../client-config";
const FILTER_INITIAL_STATE = {
	name: ""
};
const INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const AssociateBrands = ({ locationId, isOpen, close }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [brandsData, setBrandsData] = useState(INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [brandsUpdates, setBrandsUpdates] = useState({});
	const [associateAll, setAssociateAll] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const tableRef = useRef();
	const totalChanges = Object.keys(brandsUpdates).filter((key) => brandsUpdates[key]).length;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				locationId,
				limit,
				offset,
				filters: [
					{
						field: "is_active",
						value: true
					}
				]
			};
			const resp = await client.query({
				query: GET_NON_ASSOCIATED_BRANDS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			setBrandsData({
				...resp.data.nonAssociatedBrands,
				objects: resp.data.nonAssociatedBrands.objects.map((brand) => ({
					...brand,
					color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
				}))
			});
			setAllSelected(false);
			setAssociateAll(false);
			setShowAllFieldSelector(false);
			setIsCheckedAll(false);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [locationId, limit, offset, appliedFilters]);

	const applyFilter = useCallback(
		debounce((filters, field) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters, field);
		},
		[currFilters, applyFilter]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setBrandsData(INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setBrandsUpdates({});
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
			setAllSelected(false);
			setAssociateAll(false);
			close(refresh);
		},
		[close]
	);

	const handleUpdate = useCallback(async () => {
		try {
			setConfirmLoading(true);
			const variables = {
				brandIds: [],
				locationId
			};
			for (let id in brandsUpdates) {
				if (brandsUpdates[id]) {
					variables.brandIds.push(parseInt(id));
				}
			}
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.mutate({
				mutation: ASSOCIATE_BRANDS_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.associateBrands.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Associated brand(s) successfully",
						timeout: 2000,
						error: false
					}
				});
				setTimeout(() => {
					handleClose(true);
				}, 300);
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.associateBrands.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [locationId, brandsUpdates, handleClose]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, isChecked) => {
		setBrandsUpdates({
			...brandsUpdates,
			[id]: isChecked
		});
		if (!isChecked) {
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		brandsData.objects.forEach((brand) => {
			updates[brand.id] = toCheckAll;
		});
		setBrandsUpdates({
			...brandsUpdates,
			...updates
		});
	};

	const handleCompleteSelection = (associateAll) => {
		setAllSelected(associateAll);
		handleCheckAll(associateAll);
		setAssociateAll(associateAll);
		if (!associateAll) {
			setShowAllFieldSelector(false);
		}
	};

	return (
		<div className="locations-brands-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Brands"
				subTitle={`Associate brands to this location`}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
						{totalChanges === 0
							? "Associate"
							: `Associate ${allSelected ? brandsData.count : totalChanges} Brand(s)`}
					</Button>
				}
			>
				<div className="form-content" ref={tableRef}>
					{/* <div className="search-container">
						<InputWithLabel
							value={currFilters.name}
							onChange={(e) => setFilter('name', e.target.value)}
							placeholder="Enter Name"
						>
							Name
						</InputWithLabel>
					</div> */}
					{showAllFieldSelector && (
						<SelectAllFields
							allSelected={allSelected}
							handleCompleteSelection={handleCompleteSelection}
							mainMessage={
								<span>
									{allSelected ? "All " : ""}
									<strong>
										{allSelected
											? brandsData.count
											: brandsData.objects.filter((loc) => loc?.isAssociated).length}{" "}
									</strong>{" "}
									brands(s){" "}
									{allSelected ? "on all pages are selected." : "on this page are selected."}
								</span>
							}
							linkMessage={
								allSelected ? (
									" Clear selection"
								) : (
									<span>
										{" "}
										Select <strong>{brandsData.count}</strong> brands from all pages
									</span>
								)
							}
						/>
					)}
					<Table
						data={brandsData.objects}
						loading={loading}
						handleCheck={handleCheck}
						brandsUpdates={brandsUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						allSelected={allSelected}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={brandsData.count}
						goToPage={handlePagination}
						readOnly={allSelected}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default AssociateBrands;

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	brandsUpdates,
	allSelected
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && (loading || allSelected) ? "disabled" : "") +
				" transaction-table-holder common-table-container brands-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							brandsUpdates={brandsUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Brands found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)}>
				Name
			</CheckBox>
		</div>
	</div>
);

export const TableList = ({ id, name, image, color, style, handleCheck, brandsUpdates }) => {
	const status = brandsUpdates[id] === undefined ? false : brandsUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name" title={name}>
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)}>
					<div className="brand-info" title={name}>
						<div className={"brand-initials " + color}>
							{image ? <img src={image} alt="" /> : extractInitials(name?.split(" "))}
						</div>
						<div className="brand-name">{name || id}</div>
					</div>
				</CheckBox>
			</div>
		</animated.div>
	);
};
