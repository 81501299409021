import React from "react";

const ListShimmer = () => (
	<div className="list-shimmer">
		<div className="list-shimmer__body">
			<div className="list-component list-loader"></div>
		</div>
	</div>
);

export default ListShimmer;
