import React, { useCallback, useState, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Paginator } from "../_commons/Paginator";
import ItemEntityAssociations from "../EntityAssociations/ItemEntityAssociations";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { fixedToTwoDecimal, printCurrency } from "../../atlas-utils";

// graphql
import { GET_MODIFIER_GROUP_ITEMS, GET_MODIFIER_GROUP_ITEMS_FROM_MENU_SERVICE } from "../../graphql/modifierGroups";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { ITEM_ENTITY_ASSOCIATION_TYPES, NESTED_ENTITY_TYPES, FOOD_TYPE_MAP } from "../../client-config";
import { clientMenu } from "../../client-menu";
const MODIFIER_GROUPS_ASSOCIATED_ITEMS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "item-title" },
	{ label: "Category", value: "category" },
	{ label: "Price", value: "price" }
];
const FILTER_INITIAL_STATE = {
	title: "",
	item_discrete_category_id: undefined,
	tags: undefined,
	crm_title: ""
};

const AssociatedItems = ({
	modifierGroupId,
	setModalBusy,
	currencySymbol,
	handleNestedEntity,
	readOnly = true,
	isViewedFromMenuSection,
	menuId
}) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [categoryfilters, setCategoryfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [itemsList, setItemsList] = useState({
		objects: [],
		count: 0
	});
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const fetchModifierGroupItems = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(modifierGroupId),
				limit,
				offset,
				sort: {
					field: "sort_order",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			if (isViewedFromMenuSection) {
				const resp = await clientMenu.query({
					query: GET_MODIFIER_GROUP_ITEMS_FROM_MENU_SERVICE,
					variables: {
						menuId: menuId,
						optionGroupId: String(modifierGroupId),
						limit,
						offset,
						filters: {
							name: filtersObject.find((filter) => filter.field === "title")?.value ?? "",
							crmName: filtersObject.find((filter) => filter.field === "crm_title")?.value ?? ""
						}
					},
					fetchPolicy: "no-cache"
				});
				setItemsList(resp.data.optionGroup.items);
			} else {
				const resp = await client.query({
					query: GET_MODIFIER_GROUP_ITEMS,
					variables,
					fetchPolicy: "no-cache"
				});
				setItemsList(resp.data.modifierGroup.paginatedItems);
				setCategoryfilters(
					resp.data.modifierGroup.paginatedItems.filters.find(
						(fl) => fl.field === "item_discrete_category_id"
					)
				);
				setTagfilters(resp.data.modifierGroup.paginatedItems.filters.find((fl) => fl.field === "tags"));
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [modifierGroupId, limit, offset, appliedFilters]);

	useEffect(() => {
		fetchModifierGroupItems();
	}, [fetchModifierGroupItems]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchModifierGroupItems();
		}
	};

	return (
		<div className={"associated-items-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Associated Items</div>
					<div className="header-subtext">
						{itemsList.count} item(s) are associated to this modifier group
					</div>
				</div>
				{!readOnly && (
					<div>
						<Button clickHandler={openAssociationSidebar}>Update</Button>
					</div>
				)}
			</div>
			<div className="search-container">
				<InputWithLabel
					value={currFilters.title}
					onChange={(e) => setFilter("title", e.target.value)}
					placeholder="Enter Name"
				>
					Name
				</InputWithLabel>
				{!isViewedFromMenuSection && (
					<SelectFilter
						title="Category"
						options={categoryfilters.values || []}
						field={categoryfilters.field || ""}
						currValue={currFilters.item_discrete_category_id || ""}
						setFilter={setFilter}
						labelKey="valueForDisplay"
						valueKey="value"
					/>
				)}
				{!isViewedFromMenuSection && (
					<SelectFilter
						title="Tags"
						options={tagfilters.values || []}
						field={tagfilters.field || ""}
						currValue={currFilters.tags || ""}
						setFilter={setFilter}
						labelKey="valueForDisplay"
						valueKey="value"
					/>
				)}
				{isViewedFromMenuSection && (
					<InputWithLabel
						value={currFilters.crm_title}
						onChange={(e) => setFilter("crm_title", e.target.value)}
						placeholder="Enter CRM Title"
					>
						CRM Title
					</InputWithLabel>
				)}
			</div>
			<div className="crm-title-search-container">
				{!isViewedFromMenuSection && (
					<InputWithLabel
						value={currFilters.crm_title}
						onChange={(e) => setFilter("crm_title", e.target.value)}
						placeholder="Enter CRM Title"
					>
						CRM Title
					</InputWithLabel>
				)}
			</div>
			<Table
				data={itemsList.objects}
				loading={loading}
				currencySymbol={currencySymbol}
				handleNestedEntity={handleNestedEntity}
				isViewedFromMenuSection={isViewedFromMenuSection}
			/>
			<Paginator limit={limit} offset={offset} count={itemsList.count || 0} goToPage={handlePagination} />
			<ItemEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={ITEM_ENTITY_ASSOCIATION_TYPES[1]}
				entityId={parseInt(modifierGroupId)}
				currencySymbol={currencySymbol}
				isViewedFromMenuSection={isViewedFromMenuSection}
			/>
		</div>
	);
};
export default AssociatedItems;

export const Table = ({
	data,
	loading,
	currencySymbol,
	sortList,
	sortedField,
	handleNestedEntity,
	isViewedFromMenuSection
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container modifier-groups-edit-associated-items-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={MODIFIER_GROUPS_ASSOCIATED_ITEMS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							currencySymbol={currencySymbol}
							handleNestedEntity={handleNestedEntity}
							isViewedFromMenuSection={isViewedFromMenuSection}
						/>
					))}
					{data.length === 0 && !loading && (
						<div className="no-items-placeholder">No Associated Items found!</div>
					)}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	itemTitle,
	name,
	foodType,
	category,
	tags,
	itemPrice,
	price,
	crmTitle,
	crmName,
	currencySymbol,
	style,
	handleNestedEntity
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text item-title" title={itemTitle}>
				<div className="item-desc">
					<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<a
						className="link-text"
						role="button"
						onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[1], id)}
					>
						{name || itemTitle || id}
					</a>
				</div>
				{(crmTitle || crmName) && <div className="crm-title">{`CRM Title: ${crmTitle || crmName}`}</div>}
				<div className="text--light item_tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 3 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 3 && (
									<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
										+{tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text category">{category?.name || category || "--"}</div>
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{fixedToTwoDecimal(price) || fixedToTwoDecimal(itemPrice) || 0}
			</div>
		</animated.div>
	);
};
