import React, { Component } from "react";

// components
import InvoiceDetailView from "../components/Invoices/InvoiceDetailView";

export default class InvoiceDetail extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { id } = this.props.match.params;
		return (
			<div>
				<InvoiceDetailView id={id} />
			</div>
		);
	}
}
