import React, { useState, useCallback, useImperativeHandle } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CustomerInfoItem } from "./Summary";
import { JsonInput } from "../_commons/JsonInput";

// utils
import { jsonParser } from "../../atlas-utils";

// constant
import {
	PAYMENT_STATUS_MAP,
	PAYMENT_TRANSACTION_COLOR_MAP,
	PAYMENT_TRANSACTION_COLOR_DEFAULT
} from "../../client-config";

const PaymentGateway = ({ isOpen = false, close, data = {}, nestedRef }) => {
	const [loading, setLoading] = useState(false);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setLoading(false);
			close(refresh);
		},
		[close]
	);

	useImperativeHandle(
		nestedRef,
		() => ({
			handleClose
		}),
		[handleClose]
	);

	const getStatusColor = (state) => {
		return (state && PAYMENT_TRANSACTION_COLOR_MAP[state]) || PAYMENT_TRANSACTION_COLOR_DEFAULT;
	};

	return (
		<div className="payment-gateway-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				title="Transaction Details"
				subTitle="Info received from payment gateway"
				loading={loading}
				hideActions={true}
				isNested={true}
			>
				<div className="details-field-grid payment-transaction-info">
					<CustomerInfoItem label="Transaction ID" value={data ? data.txnId : ""} showCopyBtn={true} />
					<CustomerInfoItem label="Transaction Value" value={data ? data.amount : ""} />
					<CustomerInfoItem label="Payment Method" value={data ? data.paymentMethod : ""} />
					<CustomerInfoItem
						label="State"
						value={data ? PAYMENT_STATUS_MAP[data.state] || data.state : ""}
						metaClass="value-status"
						style={{
							backgroundColor: data ? getStatusColor(data.state) : "#000",
							border: 0,
							color: "white"
						}}
					/>
					<CustomerInfoItem
						label="Gateway ID"
						value={data ? data.gwTxnId : ""}
						showCopyBtn={true}
						isFullRow={true}
					/>
					<CustomerInfoItem
						label="Notes"
						value={data ? data.comments : ""}
						showCopyBtn={true}
						isFullRow={true}
					/>
				</div>
				{data.history && data.history !== "[]" && (
					<JsonInput
						title="Details"
						value={jsonParser(data.history)}
						setFilter={false}
						collapsed={2}
						displayObjectSize={false}
					/>
				)}
				{data && Object.keys(data).length === 0 && (
					<div className="no-items-placeholder">No Transaction Details found</div>
				)}
			</FormSidebar>
		</div>
	);
};
export default PaymentGateway;
