import React, { useState, useEffect, useRef } from "react";

const MetricCard = ({ metric = "", tabMetric = "", children, cardRef, handleCardRef, setCurrTab, classes = "" }) => {
	const [metricCardInViewport, setMetricCardInViewport] = useState(false);
	const tabTimerRef = useRef();

	const handleCardIntersection = (entries) => {
		entries.forEach((e) => {
			if (e.isIntersecting) {
				setMetricCardInViewport(true);
			} else {
				setMetricCardInViewport(false);
			}
		});
	};

	const handleCurrTabIntersection = (entries) => {
		entries.forEach((e) => {
			if (e.isIntersecting && setCurrTab) {
				// clear previous timer
				clearTimeout(tabTimerRef.current);
				setMetricCardInViewport(true);
				tabTimerRef.current = setTimeout(() => {
					setCurrTab(tabMetric ? tabMetric : metric);
				}, 300);
			} else if (tabTimerRef.current) {
				clearTimeout(tabTimerRef.current);
			}
		});
	};

	useEffect(() => {
		const cardObserver = new IntersectionObserver(handleCardIntersection, {
			root: null,
			threshold: 0.2,
			rootMargin: "-25% 0px 1000px 0px"
		});
		const currTabObserver = new IntersectionObserver(handleCurrTabIntersection, {
			root: null,
			threshold: 0,
			rootMargin: "-50% 0px -50% 0px"
		});

		if (handleCardRef && cardRef) {
			cardObserver.observe(cardRef);
			currTabObserver.observe(cardRef);
		} else if (cardRef?.current) {
			cardObserver.observe(cardRef.current);
			currTabObserver.observe(cardRef.current);
		}

		return () => {
			cardObserver.disconnect();
			currTabObserver.disconnect();
		};
	}, [cardRef]);

	if (!children) {
		return null;
	}
	return (
		<div
			className={"metric-card " + (metric?.split("_")?.join("-") + (classes ? ` ${classes}` : ""))}
			ref={handleCardRef ? handleCardRef : cardRef}
		>
			{children(metric, metricCardInViewport)}
		</div>
	);
};
export default MetricCard;
