//utils
import { getSortOrder } from "../atlas-utils";

// action types
import { ActionTypes } from "../actions/_types";

// constant
import { PRESET_TYPES, DATE_TYPES, COMPARE_DATE_TYPES } from "../components/_commons/NewDateCompareFilter";

const LIST_INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[9].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[9],
		customTypeSelected: { label: "Between", value: "between" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: { label: "Not specified", value: "not_specified" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};

export const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "referrer",
		order: "ASC"
	},
	currentFilters: {},
	appliedFilters: {},
	sortedField: "",
	currentDateFilter: LIST_INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: LIST_INITIAL_DATE_FILTER_STATE
};

export const referralsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.REFERRALS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.REFERRALS_LIST_CHANGE_SORT_ORDER:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.REFERRALS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export const REFERRALS_LIST_INIT_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	error: false
};
export const referralsList = (state = REFERRALS_LIST_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_REFERRALS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_REFERRALS_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.GET_REFERRALS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.REFERRALS_LIST_SEARCH:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		default:
			return state;
	}
};

export const REFERRALS_STATS_INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[6].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[6],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const REFERRALS_STATS_DATE_INITIAL_STATE = {
	currentDateFilter: REFERRALS_STATS_INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: REFERRALS_STATS_INITIAL_DATE_FILTER_STATE
};
export const referralStatsState = (state = REFERRALS_STATS_DATE_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.REFERRALS_STATS_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.REFERRALS_STATS_STATE_RESET:
			return REFERRALS_STATS_DATE_INITIAL_STATE;
		default:
			return state;
	}
};

const REFERRALS_STATS_INITIAL_STATE = {
	loading: false,
	data: {
		referralStats: {
			claimed: [],
			unclaimed: []
		}
	},
	error: false
};
export const referralStats = (state = REFERRALS_STATS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_REFERRALS_STATS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_REFERRALS_STATS_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.GET_REFERRALS_STATS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
