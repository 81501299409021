import React, { useEffect } from "react";

// components
import { StatusMetrics } from "./StatusMetrics";
import { Trends } from "./Trends";
import { Frequency } from "./Frequency";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";

// client
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchCustomerFeedbackNps } from "../../actions/customerFeedback";

const Nps = ({ customerFeedbackNps, customerFeedbackNpsState }) => {
	const { loading, data, error } = customerFeedbackNps;
	const { currentDateFilter, appliedDateFilter } = customerFeedbackNpsState;
	const { npsStatusMetrics, npsTrendsDataset, ratingFrequencyDataset } = data;

	useEffect(() => {
		fetchCustomerFeedbackNps();
	}, [appliedDateFilter]);

	const updatecustomerFeedbackNpsState = (payload) => {
		store.dispatch({
			type: ActionTypes.CUSTOMER_FEEDBACK_NPS_STATE_CHANGE,
			payload
		});
	};

	return (
		<div className="customer-feedback-nps-container">
			<NewDateCompareFilter
				showDropdown={true}
				loading={loading}
				currentDateFilter={currentDateFilter}
				appliedDateFilter={appliedDateFilter}
				updateState={updatecustomerFeedbackNpsState}
				hideComparison={true}
				hidePresetTypes={["15 D"]}
			/>
			<StatusMetrics
				loading={loading}
				data={npsStatusMetrics}
				error={error}
				appliedFilter={appliedDateFilter.current || {}}
			/>
			<Trends
				loading={loading}
				data={npsTrendsDataset}
				error={error}
				appliedFilter={appliedDateFilter.current || {}}
			/>
			<Frequency
				loading={loading}
				data={ratingFrequencyDataset}
				error={error}
				appliedFilter={appliedDateFilter.current || {}}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	customerFeedbackNps: store.customerFeedbackNps,
	customerFeedbackNpsState: store.customerFeedbackNpsState
});
export default connect(mapStateToProps)(Nps);
