import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import MonogramGenerator from "../_commons/MonogramGenerator";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { PLATFORM_MAP } from "../Hub/Integrations";

function ChooseMenuPullSource({ selectedBrand, bizPlatforms, handlePlatformSelection = () => {}, onBackToSetup }) {
	return (
		<div className="onboarding-menus__choose-menu-pull-source">
			{selectedBrand?.id ? (
				<div className="selected-brand">
					<Text variant={TextVariant.HEADING4} className="">
						{`Select a service that best represents your menu for `}
						<span className="selected-brand__display">
							{selectedBrand.image ? (
								<img src={selectedBrand.image} alt={selectedBrand?.name} className="logo" />
							) : (
								<MonogramGenerator value={selectedBrand?.name} className="logo" />
							)}
							<Text variant={TextVariant.BODY1} className="brand-title">
								{" "}
								{selectedBrand.name && selectedBrand.name.length > 25
									? selectedBrand.name.slice(0, 25) + "..."
									: selectedBrand.name}
							</Text>
						</span>
					</Text>
				</div>
			) : null}

			<div className="food-delivery-platform">
				<Text variant={TextVariant.HEADING4} className="food-delivery-platform__heading">
					Online Ordering Platform
				</Text>
				{bizPlatforms?.items?.length ? (
					<React.Fragment>
						<div className="food-delivery-platform__list">
							{bizPlatforms.items.map((plf) => {
								return (
									<DspPlatformCard
										name={PLATFORM_MAP[plf?.platformName] || plf?.platformName}
										handlePlatformSelection={() => handlePlatformSelection(plf)}
									/>
								);
							})}
						</div>
					</React.Fragment>
				) : (
					<Text variant={TextVariant.BODY1}>
						Sorry we currently do not support your online platforms for importing menu. Please{" "}
						<span onClick={onBackToSetup} className="hyperlink hyperlink--black-color">
							create your menu manually
						</span>
						.
					</Text>
				)}
			</div>
		</div>
	);
}

function DspPlatformCard({ name = "", handlePlatformSelection = () => {} }) {
	return (
		<div onClick={handlePlatformSelection} className="dsp-platform-card">
			<img
				className="dsp-platform-card__image"
				src={CATALOGUE_PLATFORMS_LOGO[name] || "/assets/icons/icons8-globe-40.png"}
				alt=""
			/>
			<Text variant={TextVariant.BODY1} className="dsp-platform-card__name">
				{name}
			</Text>
			<img src="/assets/icons/icon-right-angled-arrow.svg" alt="right arrow" className="right-arrow-icon" />
		</div>
	);
}

export default ChooseMenuPullSource;
