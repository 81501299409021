import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import ILGPSEntityAssociations from "../EntityAssociations/ILGPSEntityAssociation";

// third party
import { useTrail, config, animated } from "react-spring";
import { Link } from "react-router-dom";

// utils
import { msaagesArrayToHtml } from "../../atlas-utils";
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_TAX_ILGPS } from "../../graphql/taxes";
import { UPDATE_ENTITY_ILGPS } from "../../graphql/ilgpsEntityAssociation";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { ILGP_ENTITY_ASSOCIATION_TYPES, NESTED_ENTITY_TYPES } from "../../client-config";

const TABLE_FIELDS = [
	{ label: "Item Group", value: "item-group" },
	{ label: "Location Group", value: "location-group" }
];

const ItemsAndLocations = ({ entityId, setModalBusy, setConfirmLoading, handleNestedEntity, readOnly = true }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: entityId
			};
			const resp = await client.query({
				query: GET_TAX_ILGPS,
				variables,
				fetchPolicy: "no-cache"
			});
			setData(resp.data.tax.ilgps);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [entityId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const deleteILGPA = useCallback(
		async (itemGroupId, locationGroupId) => {
			try {
				setConfirmLoading(true);
				const variables = {
					entityId: entityId,
					entityType: ILGP_ENTITY_ASSOCIATION_TYPES[0].value,
					ilgps: [
						{
							itemGroupId: parseInt(itemGroupId),
							locationGroupId: parseInt(locationGroupId),
							isDeleted: true
						}
					]
				};
				const resp = await client.mutate({
					mutation: UPDATE_ENTITY_ILGPS,
					variables,
					fetchPolicy: "no-cache"
				});
				if (resp.data.updateEntityIlgps.status.success) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Item-Location Group pair deleted successfully!",
							timeout: 2000,
							error: false
						}
					});
					fetchData();
				} else {
					// handle error message
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: msaagesArrayToHtml(resp.data.updateEntityIlgps.status.messages),
							timeout: 5000,
							error: true
						}
					});
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setConfirmLoading(false);
		},
		[fetchData]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchData();
		}
	};

	return (
		<div className={"items-and-locations-container" + (loading ? " no-click" : "")}>
			<div className="related-entities-header-container">
				<div>
					<div className="header-text">Selected Items & Locations</div>
					<div className="header-subtext">Item groups and Location groups added to this Tax</div>
				</div>
				{!readOnly && (
					<div>
						<Button clickHandler={openAssociationSidebar}>Add New Pair</Button>
					</div>
				)}
			</div>
			<Table
				deleteILGPA={deleteILGPA}
				data={data}
				loading={loading}
				handleNestedEntity={handleNestedEntity}
				readOnly={readOnly}
			/>
			<ILGPSEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={ILGP_ENTITY_ASSOCIATION_TYPES[0]}
				entityId={entityId}
			/>
		</div>
	);
};
export default ItemsAndLocations;

export const Table = ({ data, loading, sortList, sortedField, deleteILGPA, handleNestedEntity, readOnly }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container items-and-locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader sortList={sortList} sortedField={sortedField} headerFields={TABLE_FIELDS} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							deleteILGPA={deleteILGPA}
							handleNestedEntity={handleNestedEntity}
							readOnly={readOnly}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No ILGP found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({ style, itemGroup, locationGroup, deleteILGPA, handleNestedEntity, readOnly }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text item-group">
				<a
					role="button"
					className="link-text"
					onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[7], itemGroup.id)}
				>
					{itemGroup.title || itemGroup.id}
				</a>
			</div>
			<div className="at-table-cell at-cell-text location-group">
				<a
					role="button"
					className="link-text"
					onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[8], locationGroup.id)}
				>
					{locationGroup.title || locationGroup.id}
				</a>
				{!readOnly && (
					<img
						className="delete-icon"
						onClick={() => deleteILGPA(itemGroup.id, locationGroup.id)}
						src="/assets/icons/delete.svg"
					/>
				)}
			</div>
		</animated.div>
	);
};
