import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/WebhookEdit/BasicInfo";
import Headers from "../components/WebhookEdit/Headers";
import History from "../components/WebhookEdit/History";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { removeProp, adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// graphql
import { WEBHOOK_EVENT_TYPES, WEBHOOK_RETRY_MODES } from "../graphql/webhooks";

// actions
import { fetchWebhooksList, fetchWebhookDetail, editWebhook } from "../actions/webhooks";
import { ActionTypes } from "../actions/_types";

// reducers
import { webhookDetailsReducer, WEBHOOK_DETAILS_INITIAL_STATE } from "../reducers/webhooks";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Headers",
		value: "headers"
	},
	{
		label: "History",
		value: "history"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const WebhookEdit = ({
	match,
	access,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const [webhookEventTypes, setWebhookEventTypes] = useState({
		loading: false,
		data: []
	});
	const [webhookRetryModes, setWebhookRetryModes] = useState({
		loading: false,
		data: []
	});
	const [isFormOpen, setFormState] = useState(false);
	const [webhookDetails, dispatch] = useReducer(webhookDetailsReducer, WEBHOOK_DETAILS_INITIAL_STATE);
	const { loading, data, error } = webhookDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const isPrimeWebhook = (data?.url && data?.url?.includes("prime.urbanpiper.com")) || false;
	const isEisWebhook =
		(data?.url && (data?.url?.includes("eis.upipr.priv") || data?.url?.includes("eis-api.urbanpiper.com"))) ||
		false;

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchWebhookDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	useEffect(() => {
		fetchWebhookEventTypes();
		fetchWebhookRetryModes();
	}, []);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchWebhooksList();
					history.push("/webhooks");
				}
			}, 100);
		} else {
			nestedRef.current.closeHistoryDetailView();
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const fetchWebhookEventTypes = async () => {
		try {
			setWebhookEventTypes({
				...webhookEventTypes,
				loading: true
			});
			const resp = await client.query({
				query: WEBHOOK_EVENT_TYPES,
				fetchPolicy: "no-cache"
			});
			setWebhookEventTypes({
				loading: false,
				data: resp.data.webhookEventTypes
			});
		} catch (error) {
			console.log(error);
			setWebhookEventTypes({
				...webhookEventTypes,
				loading: false
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const fetchWebhookRetryModes = async () => {
		try {
			setWebhookRetryModes({
				...webhookRetryModes,
				loading: true
			});
			const resp = await client.query({
				query: WEBHOOK_RETRY_MODES,
				fetchPolicy: "no-cache"
			});
			setWebhookRetryModes({
				loading: false,
				data: resp.data.webhookRetryModes.filter((wrm) => wrm.value !== "NONE")
			});
		} catch (error) {
			console.log(error);
			setWebhookRetryModes({
				...webhookRetryModes,
				loading: false
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleForm = (formData) => {
		dispatch({
			type: ActionTypes.UPDATE_WEBHOOK_DETAIL,
			payload: {
				...formData
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleAddHeader = (isEisEnvField = false) => {
		dispatch({
			type: ActionTypes.UPDATE_WEBHOOK_DETAIL,
			payload: {
				...data,
				headers: [...data.headers, { key: isEisEnvField ? "environment" : "", value: "" }]
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleHeadersForm = (field, value, index) => {
		dispatch({
			type: ActionTypes.UPDATE_WEBHOOK_DETAIL,
			payload: {
				...data,
				headers: data.headers.map((header, i) => {
					if (i === index) {
						header[field] = value;
					}
					return header;
				})
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleDeleteHeader = (index) => {
		dispatch({
			type: ActionTypes.UPDATE_WEBHOOK_DETAIL,
			payload: {
				...data,
				headers: data.headers.filter((h, i) => i !== index)
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		// check all key-value pairs are filled in Headers tab
		let checkPassed = true;
		const webhookHeaders = data.headers ? data.headers : [];
		webhookHeaders.forEach((header) => {
			if (checkPassed && (header.key === "" || header.value === "")) {
				checkPassed = false;
			}
		});
		if (checkPassed) {
			const sanitisedData = removeProp(data, "__typename");
			const resp = await editWebhook(sanitisedData, dispatch);
			if (resp) {
				setFormTouched(false);
			}
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please fill all the key-value pairs in Headers",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_WEBHOOK_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	const switchTab = (tab) => {
		setFormTab(tab.value);
	};

	return (
		<div className="webhook-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title="Webhook"
				subTitle="Edit this webhook"
				submitTitle="Save"
				loading={loading}
				isNested={isNested}
				hideActions={
					(formTab === FORM_TABS[0].value && !isFormTouched) ||
					(formTab === FORM_TABS[1].value && !isFormTouched) ||
					formTab === FORM_TABS[2].value
				}
				headerRight={
					access.isAdmin &&
					!isPrimeWebhook &&
					!isEisWebhook && (
						<Button
							classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
							clickHandler={() => setArchiveRestore(true)}
						>
							{data.isActive ? "Archive" : "Restore"}
						</Button>
					)
				}
			>
				<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							webhookEventTypes={webhookEventTypes}
							webhookRetryModes={webhookRetryModes}
							readOnly={!access.isAdmin || isPrimeWebhook || isEisWebhook}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<Headers
							data={data.headers || []}
							handleAddHeader={handleAddHeader}
							handleHeadersForm={handleHeadersForm}
							handleDeleteHeader={handleDeleteHeader}
							readOnly={!access.isAdmin || isPrimeWebhook || isEisWebhook}
							isEisWebhook={isEisWebhook}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<History
							webhookId={parseInt(match.params.id)}
							webhookUrl={data.url || ""}
							setModalBusy={setModalBusy}
							nestedRef={nestedRef}
							readOnly={!access.isAdmin || isPrimeWebhook || isEisWebhook}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[14]}
						entityName={data.url}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						fieldName="isActive"
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access
}))(WebhookEdit);
