import gql from "graphql-tag";

export const GET_LOCATIONS_LIST = gql`
	query getLocationsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$brand: String
	) {
		stores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search, brand: $brand) {
			count
			objects {
				id
				title
			}
		}
	}
`;

export const GET_BIZ_LOCATION_PLATFORMS_MINI = gql`
	query getBizLocationPlatforms($id: Int, $brandId: Int) {
		store(id: $id, brandId: $brandId) {
			associatedPlatforms {
				id
				platform {
					id
					platformName
				}
				externalId
			}
			brandLocations {
				id
				name
				isActive
				associatedPlatforms {
					id
					platform {
						id
						platformName
					}
					externalId
				}
				brand {
					id
					name
				}
			}
		}
	}
`;

export const GET_AGGREGATOR_TEST_ORDER_CONFIG = gql`
	query fetchAggregatorTestOrderConfig {
		aggregatorTestOrderConfig {
			message
			success
			keys {
				key
				shortDesc
				title
				value
				readonly
				required
				encrypted
				type
				selectedValue
			}
		}
	}
`;

export const TRIGGER_AGGREGATOR_TEST_ORDER = gql`
	mutation triggerAggregatorTestRequest(
		$bizId: Int!
		$storeId: Int!
		$brandId: Int
		$hasAddon: Boolean
		$addOnQuantity: Int
		$hasNestedModifier: Boolean
		$storePlatformId: String
		$fixedDiscount: Float
		$lineItemCount: Int
		$packagingCharge: Float
		$channel: String
		$itemQuantity: Int!
		$addonCount: Int!
		$deliveryCharge: Float
		$serviceCharge: Float
		$surcharge: Float
		$driverTip: Float
		$fulfillmentType: String!
		$deliveryType: String!
		$isFutureOrder: Boolean
		$hasVariant: Boolean!
		$paymentType: String!
		$discountType: String!
		$orderInstructions: String
	) {
		placeAggregatorTestOrder(
			input: {
				bizId: $bizId
				storeId: $storeId
				brandId: $brandId
				hasAddon: $hasAddon
				addOnQuantity: $addOnQuantity
				hasNestedModifier: $hasNestedModifier
				storePlatformId: $storePlatformId
				fixedDiscount: $fixedDiscount
				lineItemCount: $lineItemCount
				packagingCharge: $packagingCharge
				channel: $channel
				itemQuantity: $itemQuantity
				addonCount: $addonCount
				deliveryCharge: $deliveryCharge
				serviceCharge: $serviceCharge
				surcharge: $surcharge
				driverTip: $driverTip
				fulfillmentType: $fulfillmentType
				deliveryType: $deliveryType
				isFutureOrder: $isFutureOrder
				hasVariant: $hasVariant
				paymentType: $paymentType
				discountType: $discountType
				orderInstructions: $orderInstructions
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
