import React, { Component } from "react";

// third party
import { XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart, Legend } from "recharts";
import moment from "moment";

// utils
import {
	timeStampToDurPreset,
	commifyNumbers,
	formatDate,
	MONTHS,
	nthFyDate,
	getSuffixedNumber
} from "../../atlas-utils";

export default class AnalyticsGraph extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0
		};
	}

	componentDidMount() {
		this.setState({
			width: this.graphContainer.clientWidth
		});
	}

	render() {
		const { anaGraphData, duration, ...rest } = this.props;
		return (
			<div
				className={anaGraphData.loading ? "disabled" : ""}
				ref={(graphContainer) => (this.graphContainer = graphContainer)}
			>
				<AnalyticsLineChart
					durationPreset={duration}
					data={anaGraphData.data}
					width={this.state.width}
					{...rest}
				/>
			</div>
		);
	}
}

const prepareData = (data) => {
	let { current, previous } = data;
	let formattedData = [];
	// assuming both the array are identical
	for (let x in current) {
		formattedData.push({
			currentTimestamp: current[x].timestamp,
			previousTimestamp: previous[x] ? previous[x].timestamp : null,
			currentValue: current[x].value,
			previousValue: previous[x] != undefined && previous[x].value >= 0 ? previous[x].value : null
		});
	}
	return formattedData;
};

const getLegendLabel = (durationPreset, formattedData, currentLegend = "", previousLegend = "") => {
	let legendLabels = {};
	if (currentLegend && previousLegend) {
		return {
			current: currentLegend,
			previous: previousLegend
		};
	}
	legendLabels = {
		current: "Current",
		previous: "Previous"
	};
	let preStartTimestamp;
	let preEndTimestamp;

	// depending on current server implementation
	// if there is timestamp on previous period for one data point
	// then there will be timestamp for every data point
	if (formattedData.length > 0 && formattedData[0].previousTimestamp) {
		preStartTimestamp = formattedData[0].previousTimestamp;
		preEndTimestamp = formattedData[formattedData.length - 1].previousTimestamp;
	}

	let preStartDate;
	let preEndDate;
	let preStartDateString;
	let preEndDateString;

	if (preStartTimestamp && preEndTimestamp) {
		preStartDate = new Date(preStartTimestamp);
		preEndDate = new Date(preEndTimestamp);
		preStartDateString = `${preStartDate.getDate()} ${
			MONTHS[preStartDate.getMonth()]
		} ${preStartDate.getFullYear()}`;
		preEndDateString = `${preEndDate.getDate()} ${MONTHS[preEndDate.getMonth()]} ${preEndDate.getFullYear()}`;
	}

	switch (durationPreset) {
		case "TODAY":
			legendLabels.current = "Today";
			legendLabels.previous = "Yesterday";
			break;
		case "YESTERDAY":
			legendLabels.current = "Yesterday";
			if (preStartTimestamp && preEndTimestamp) {
				legendLabels.previous = `${nthFyDate(preStartDate.getDate())} ${MONTHS[preStartDate.getMonth()]}`;
			}
			break;
		case "LAST_7_DAYS":
			legendLabels.current = "Last 7 days";
			if (preStartTimestamp && preEndTimestamp) {
				legendLabels.previous = `${preStartDateString} — ${preEndDateString}`;
			}
			break;
		case "THIS_WEEK":
			legendLabels.current = "This week";
			if (preStartTimestamp && preEndTimestamp) {
				legendLabels.previous = `${preStartDateString} — ${preEndDateString}`;
			}
			break;
		case "LAST_30_DAYS":
			legendLabels.current = "Last 30 days";
			if (preStartTimestamp && preEndTimestamp) {
				legendLabels.previous = `${preStartDateString} — ${preEndDateString}`;
			}
			break;
		case "THIS_MONTH":
			legendLabels.current = "This month";
			if (preStartTimestamp && preEndTimestamp) {
				legendLabels.previous = `${preStartDateString} — ${preEndDateString}`;
			}
			break;
		case "LAST_90_DAYS":
			legendLabels.current = "Last 90 days";
			if (preStartTimestamp && preEndTimestamp) {
				legendLabels.previous = `${preStartDateString} — ${preEndDateString}`;
			}
			break;
		case "THIS_YEAR":
			legendLabels.current = "This year";
			if (preStartTimestamp && preEndTimestamp) {
				legendLabels.previous = `${preStartDateString} — ${preEndDateString}`;
			}
			break;
		default:
			break;
	}

	return legendLabels;
};

const AnalyticsLineChart = ({
	showXaxis = true,
	showYaxis = true,
	margin = { top: 5, right: 0, left: -20, bottom: 5 },
	...props
}) => {
	const formattedData = props.data ? prepareData(props.data) : props.data;
	const { durationPreset, xAxisLabel = "", yAxisLabel = "" } = props;
	const legendLabels = getLegendLabel(durationPreset, formattedData, props.currentLegend, props.previousLegend);
	const toShowPrevious = formattedData.length > 0 && formattedData[0].previousTimestamp ? true : false;

	return (
		<div>
			<AreaChart
				width={props.width}
				height={window.isMobile ? 320 : props.width * 0.45}
				data={formattedData}
				margin={margin}
			>
				<Legend
					iconType="line"
					wrapperStyle={{
						paddingTop: "30px",
						fontSize: "12px"
					}}
				/>
				{showXaxis && (
					<XAxis
						label={{ value: xAxisLabel, position: "bottom" }}
						domain={["dataMin", "dataMax"]}
						type="number"
						dataKey="currentTimestamp"
						scale="time"
						tick={<CustomizedXTick durationPreset={durationPreset} />}
					/>
				)}
				{showYaxis && (
					<YAxis
						label={{ value: yAxisLabel, angle: -90, position: "insideLeft" }}
						tick={<CustomizedYTick />}
						tickLine={false}
					/>
				)}
				<defs>
					<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
						<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#bdc3c7" stopOpacity={1} />
						<stop offset="95%" stopColor="#bdc3c7" stopOpacity={0} />
					</linearGradient>
				</defs>
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip content={<CustomTooltip durationPreset={durationPreset} />} />
				<Area
					name={legendLabels.current}
					type="monotone"
					dataKey="currentValue"
					stroke="#2f58f2"
					strokeWidth={1.2}
					fill="url(#colorUv)"
					fillOpacity={0.5}
					activeDot={{ r: 5 }}
				/>
				{toShowPrevious && (
					<Area
						name={legendLabels.previous}
						type="monotone"
						dataKey="previousValue"
						stroke="#bdc3c7"
						strokeWidth={1.2}
						fill="url(#colorPv)"
						fillOpacity={0.5}
						activeDot={{ r: 5 }}
					/>
				)}
			</AreaChart>
		</div>
	);
};

const getReadableTimestamp = (timestamp, durationPreset) => {
	let period = "";
	if (durationPreset == "THIS_YEAR") {
		period = "month";
	} else if (durationPreset == "LAST_90_DAYS" || durationPreset == "LAST_30_DAYS" || durationPreset == "THIS_MONTH") {
		period = "90day";
	} else if (durationPreset == "TODAY" || durationPreset == "YESTERDAY") {
		period = "hour";
	} else {
		period = "day";
	}
	return timeStampToDurPreset(timestamp, period);
};

const CustomizedXTick = ({ payload, x, y, durationPreset }) => (
	<text x={x} y={y + 15} textAnchor="middle" fontSize={window.isMobile ? "12" : "14"}>
		{getReadableTimestamp(payload.value, durationPreset)}
	</text>
);

const CustomizedYTick = ({ payload, x, y }) => (
	<text x={x} y={y + 5} textAnchor="end" fontSize={window.isMobile ? "12" : "14"}>
		{payload.value == 0 ? null : getSuffixedNumber(payload.value)}
	</text>
);

const CustomTooltip = ({ payload, label, durationPreset, active }) => {
	if (active) {
		if (!payload) {
			return null;
		}
		return (
			<div className="custom-tooltip custom-tooltip--analytics">
				<div>{formatDate(label)}</div>
				<hr />
				<div>Current: {commifyNumbers(payload[0] ? payload[0].value : null)}</div>
				<div className="previous-value">
					{payload[1] && payload[1].payload.previousTimestamp
						? `${getReadableTooltipTime(payload[1].payload.previousTimestamp, durationPreset)}: `
						: "Previous: "}
					{commifyNumbers(payload[1] ? payload[1].value : null)}
				</div>
			</div>
		);
	}
	return null;
};

const getReadableTooltipTime = (timestamp, durationPreset) => {
	switch (durationPreset) {
		case "TODAY":
			return moment(timestamp).format("DD MMM h A");
		case "YESTERDAY":
			return moment(timestamp).format("DD MMM h A");
		case "LAST_7_DAYS":
			return moment(timestamp).format("DD MMM");
		case "THIS_WEEK":
			return moment(timestamp).format("DD MMM");
		case "LAST_30_DAYS":
			return moment(timestamp).format("DD MMM");
		case "THIS_MONTH":
			return moment(timestamp).format("DD MMM");
		case "LAST_90_DAYS":
			return moment(timestamp).format("DD MMM");
		case "THIS_YEAR":
			return moment(timestamp).format("DD MMM YYYY");
	}
};
