import React from "react";

export const CheckBox = ({
	clickHandler,
	classes,
	title,
	checked,
	readOnly = false,
	children,
	isPartiallyChecked = false,
	isFullyChecked = false,
	...rest
}) => (
	<div
		onClick={clickHandler || void 0}
		className={
			"at-checkbox " +
			(readOnly ? "read-only " : "") +
			(classes || "") +
			(!isFullyChecked && isPartiallyChecked ? " at-checkbox--partial" : "")
		}
		title={title ? title : ""}
	>
		<input
			type="checkbox"
			checked={checked || false}
			onClick={(e) => {
				e.stopPropagation();
				clickHandler ? clickHandler() : void 0;
			}}
			value={checked}
			{...rest}
		/>
		<span className="checkmark"></span>
		{children}
	</div>
);
