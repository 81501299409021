import React from "react";

const FoodTypeIndicator = ({ foodType = "veg" }) =>
	foodType !== "not-applicable" ? (
		<div className={`at-foodtype-indicator at-foodtype-indicator--${foodType || ""}`}></div>
	) : (
		<div className="at-foodtype-indicator"></div>
	);

export default FoodTypeIndicator;
