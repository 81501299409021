// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import { trackEvent, getDurationObject } from "../atlas-utils";

// graphql
import {
	PAYOUT_SHEET_DETAIL,
	PAYOUT_SHEET_LISTS,
	GET_RECONCILIATION_PAYOUT_STATUS,
	GET_RECONCILIATION_REVENUE_BREAKDOWN
} from "../graphql/reconciliation";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchPayoutSheetsList = async (platform = "") => {
	store.dispatch(toggleGlobalLoader(true));
	const variables = {
		platform: store.getState().reconciliationPayoutsList?.selectedPlatform?.value || platform,
		limit: store.getState().reconciliationPayoutsState?.limit || 10,
		offset: store.getState().reconciliationPayoutsState?.offset || 0,
		sort: store.getState().reconciliationPayoutsState.sort
	};

	// sidebar filters
	let filters = [];
	const appliedFilters = store.getState().reconciliationPayoutsState.appliedFilters;

	Object.keys(appliedFilters).forEach((f) => {
		if (typeof appliedFilters[f] === "object") {
			if (appliedFilters[f].value) {
				filters.push(appliedFilters[f]);
			}
		} else {
			filters.push({
				field: f,
				value: appliedFilters[f]
			});
		}
	});

	// date filter
	const appliedDateFilter = store.getState().reconciliationPayoutsState.appliedDateFilter;
	if (appliedDateFilter.current.dateFilter) {
		filters.push({
			field: "created",
			value: appliedDateFilter.current.dateFilter
		});
	}
	if (filters.length > 0) {
		variables.filters = filters;
	}
	store.dispatch({
		type: ActionTypes.GET_PAYOUT_SHEETS_LIST_REQUEST
	});
	try {
		const resp = await client.query({
			query: PAYOUT_SHEET_LISTS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_PAYOUT_SHEETS_LIST_SUCCESS,
			payload: resp.data.payoutSheets
		});
		trackEvent("reconciliation_payouts_list", variables);
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_PAYOUT_SHEETS_LIST_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Something went wrong. Failed to fetch payout sheet lists.",
				timeout: 3000,
				error: true
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchPayoutSheetDetails = async (id) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_PAYOUT_SHEET_DETAILS_REQUEST
	});
	try {
		let variables = { id };
		const resp = await client.query({
			query: PAYOUT_SHEET_DETAIL,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_PAYOUT_SHEET_DETAILS_SUCCESS,
			payload: resp?.data?.payoutSheet || {}
		});
		trackEvent("reconciliation_payout_details", variables);
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_PAYOUT_SHEET_DETAILS_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Something went wrong. Failed to fetch payout sheet lists.",
				timeout: 3000,
				error: true
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReconciliationPayoutStatus = async (topic = "ORDER_COUNT") => {
	const { appliedDateFilter, appliedFilters } = store.getState().reconciliationStatsState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_RECONCILIATION_STATS_REQUEST
	});
	try {
		const durationObject = getDurationObject(appliedDateFilter);
		const variables = {
			...durationObject,
			topic
		};

		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "biz_platform_id" && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		variables.filters = filtersObject;

		const resp = await client.query({
			query: GET_RECONCILIATION_PAYOUT_STATUS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_RECONCILIATION_STATS_SUCCESS,
			payload: resp.data.reconciliationAnalytics
		});
		store.dispatch({
			type: ActionTypes.RECONCILIATION_STATS_STATE_CHANGE,
			payload: { loadedDateFilter: appliedDateFilter }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_RECONCILIATION_STATS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReconciliationRevenueBreakdown = async (topic = "ORDER_COUNT") => {
	const { appliedDateFilter, appliedFilters } = store.getState().reconciliationStatsState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_RECONCILIATION_STATS_REQUEST
	});
	try {
		const durationObject = getDurationObject(appliedDateFilter);
		const variables = {
			...durationObject,
			topic
		};

		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "biz_platform_id" && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		variables.filters = filtersObject;

		const resp = await client.query({
			query: GET_RECONCILIATION_REVENUE_BREAKDOWN,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_RECONCILIATION_STATS_SUCCESS,
			payload: resp.data.reconciliationAnalytics
		});
		store.dispatch({
			type: ActionTypes.RECONCILIATION_STATS_STATE_CHANGE,
			payload: { loadedDateFilter: appliedDateFilter }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_RECONCILIATION_STATS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
