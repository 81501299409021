import React from "react";

// third party
import ReactDatePicker from "react-datepicker";

const DatePicker = ({ ...rest }) => {
	return <ReactDatePicker {...rest} />;
};

export default DatePicker;
