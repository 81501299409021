import React from "react";

const Store = ({ variations = {} }) => {
	return (
		<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.5 4.5C4.9475 4.5 4.5 4.9475 4.5 5.5L4.5 7.5L2 10L2 12L5 12L5 10L7 7.5L8.5 7.5L7 10L7 12L10 12L10 10L11 7.5L12.5 7.5L12 10L12 12L15 12L15 10L15 7.5L17 7.5L17 10L17 12L20 12L20 10L19.5 7.5L21 7.5L22 10L22 12L25 12L25 10L23.5 7.5L25 7.5L27 10L27 12L30 12L30 10L27.5 7.5L27.5 5.5C27.5 4.9475 27.0525 4.5 26.5 4.5L5.5 4.5ZM3.5 13.5L3.5 28L18 28L18 17L24 17L24 22L22 22L22 23C22 23.276 22.224 23.5 22.5 23.5L24 23.5L24 28L28.5 28L28.5 14L3.5 13.5ZM8 17L15 17L15 24.5L8 24.5L8 17Z"
				fill={"#363636"}
				{...variations}
			/>
		</svg>
	);
};
export default React.memo(Store);
