// action types
import { ActionTypes } from "../actions/_types";

// utils
import { getSortOrder } from "../atlas-utils";

const INITIAL_STATE = {
	limit: 100,
	offset: 0,
	sort: {
		field: "sort_order",
		order: "ASC"
	},
	sortedField: "sort_order",
	currentFilters: {
		is_active: false
	},
	appliedFilters: {
		is_active: false
	}
};
export const categoriesListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CATEGORIES_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.CATEGORIES_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.CATEGORIES_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const CATEGORIES_LIST_INITIAL_STATE = {
	loading: false,
	data: [],
	error: false,
	selectedCategory: undefined
};
export const categoriesList = (state = CATEGORIES_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CATEGORIES_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CATEGORIES_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				...action.payload
			};
		case ActionTypes.GET_CATEGORIES_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CATEGORIES_LIST_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const CATEGORY_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	backupData: {},
	error: {}
};
export const categoryDetailsReducer = (state = CATEGORY_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CATEGORY_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_CATEGORY_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				backupData: action.payload
			};
		case ActionTypes.GET_CATEGORY_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CATEGORY_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_CATEGORY_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_CATEGORY_DETAIL_SUCCESS:
			return {
				...state,
				backupData: state.data,
				loading: false
			};
		case ActionTypes.EDIT_CATEGORY_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

const CATEGORY_ITEMS_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: []
	},
	sort: {
		field: "sort_order",
		order: "ASC"
	},
	sortedField: "sort_order",
	error: false,
	filtersApplied: {},
	limit: 5,
	offset: 0
};
export const categoryItems = (state = CATEGORY_ITEMS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CATEGORY_ITEMS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CATEGORY_ITEMS_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case ActionTypes.GET_CATEGORY_ITEMS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.CATEGORY_ITEMS_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.CATEGORY_ITEMS_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.CATEGORY_ITEMS_STATE_RESET:
			return CATEGORY_ITEMS_INITIAL_STATE;
		default:
			return state;
	}
};
