import React, { useState, useEffect } from "react";

// components
import Popover from "../_commons/Popover";
import { Switch } from "../_commons/Switch";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

const BasicInfo = ({ data = {}, loading = false, extractInitials }) => {
	// placeholder
	if (loading && Object.keys(data).length === 0) {
		return (
			<div className="user-info-and-roles">
				<div className="card-container">
					<div className="card-header">
						<div className="product">
							<div className="shimmer H(20px) W(100px)" />
						</div>
					</div>
					<div className="card-content">
						<div className="form-row row-half">
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
						</div>
					</div>
				</div>
				<div className="card-container">
					<div className="card-header">
						<div className="product">
							<div className="shimmer H(20px) W(100px)" />
						</div>
					</div>
					<div className="card-content">
						<div className="form-row row-half">
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
						</div>
						<div className="form-row row-half">
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="user-info-and-roles">
			{(data.email || data.phone) && (
				<div className="card-container">
					<div className="card-header">
						<div className="product">
							<div className="logo">
								<img className="user" src="/assets/icons/icon-user.svg" alt="" />
							</div>
							<div className="name">User Info</div>
						</div>
						<div
							className={"status " + (data.status === "CREATED" ? "pending" : data.status.toLowerCase())}
						>
							{data.status === "CREATED" ? "PENDING" : data.status}
						</div>
					</div>
					<div className="card-content">
						<div className="form-row row-half">
							{data.email && (
								<div className="email">
									<div className="meta-info">Email</div>
									<div className="info">{data.email}</div>
								</div>
							)}
							{data.phone && (
								<div className="phone">
									<div className="meta-info">Phone Number</div>
									<div className="info">
										{data?.phone?.includes("+")
											? data.phone
											: `${data.isdCode || ""} ${data.phone}`}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{data?.invitedApps?.map((app, i) => (
				<App key={i} app={app} extractInitials={extractInitials} />
			))}
		</div>
	);
};
export default BasicInfo;

export const App = ({
	app,
	extractInitials,
	status,
	editMode = false,
	handleDropdownLabel = () => null,
	handleDropdownOption = () => null
}) => {
	const [optionUpdates, setOptionUpdates] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState({});
	const showLocations =
		app.appName === "prime" ||
		app?.roles?.find((role) => role?.id === 53 || role?.id === 55 || role?.id === 66) !== undefined
			? true
			: false;

	useEffect(() => {
		if (editMode) {
			let optUpdates = {
				roles: {},
				locations: {}
			};
			let checkedAllUpdates = {
				locations: app.allLocations
			};
			if (app?.roles?.length) {
				app.roles.forEach((role) => {
					optUpdates.roles[role.id] = true;
				});
			}
			if (app?.locations?.length) {
				app.locations.forEach((location) => {
					optUpdates.locations[location.id] = true;
				});
			}
			setOptionUpdates(optUpdates);
			setIsCheckedAll(checkedAllUpdates);
		}
	}, [editMode]);

	const renderPopover = (locations = []) => {
		return (
			<div className="locations-list">
				{locations?.length > 0 &&
					locations?.map((location, i) => (
						<div className="name" key={i}>
							{location.name}
							{location?.brandName ? ` (${location?.brandName})` : ""}
						</div>
					))}
			</div>
		);
	};

	return (
		<div className={"card-container" + (editMode ? " read-only" : "")}>
			<div className="card-header">
				<div className="product">
					<div className="logo">
						<img src={`/assets/icons/${app.appName}-logo.svg`} alt="" />
					</div>
					<div className="name">{app.appName}</div>
				</div>
				<div className="invite-status">
					{status && (
						<div className={"status " + (status === "CREATED" ? "pending" : status.toLowerCase())}>
							{status === "CREATED" ? "PENDING" : status}
						</div>
					)}
					{editMode && <Switch title="Access" checked={true} clickHandler={() => {}} readOnly={true} />}
				</div>
			</div>
			{editMode && app.appName === "atlas" ? (
				<div className="description">
					Comprehensive tool that empowers you to manage restaurant operations efficiently and provides
					valuable insights through detailed analytics.
				</div>
			) : editMode && app.appName === "prime" ? (
				<div className="description">
					Dedicated tool that helps you manage store operations like POS, stock management, online orders, and
					customer service.
				</div>
			) : null}
			<div className="card-content">
				{editMode && (
					<div className="biz-info">
						<div className="logo">
							{app?.appBizLogo ? (
								<img src={app?.appBizLogo} alt="" />
							) : (
								<div className="initials">{extractInitials(app?.appBizName)}</div>
							)}
						</div>
						<div className="name">{app?.appBizName}</div>
					</div>
				)}
				{editMode && (
					<div className="form-row row-full">
						{app?.appName === "atlas" ? (
							<SelectFilterCustom
								title="Roles"
								options={[]}
								field="roles"
								currValue={app?.roles || []}
								labelKey={"name"}
								valueKey={"id"}
								customLabel={true}
								renderLabel={handleDropdownLabel}
								ellipsizedLength={100}
								multi={true}
								requiredLabel={true}
								showCheckBox={true}
								showSelectAllOption={false}
								optionUpdates={optionUpdates}
								handleCheck={() => {}}
								isCheckedAll={isCheckedAll}
								handleCheckAll={() => {}}
								placeholder="Select Roles"
								readOnly={true}
							/>
						) : (
							<SelectFilter
								title="Roles"
								options={[]}
								field="roles"
								requiredLabel={true}
								currValue={app.roles || null}
								setFilter={() => {}}
								labelKey="name"
								valueKey="id"
								readOnly={true}
							/>
						)}
					</div>
				)}
				{editMode && showLocations && (
					<div className="form-row row-full">
						<SelectFilterCustom
							title="Locations"
							options={[]}
							field="locations"
							currValue={app.locations || []}
							labelKey={"name"}
							valueKey={"id"}
							customLabel={true}
							renderLabel={handleDropdownLabel}
							customOptions={app.appName === "atlas"}
							renderOptions={handleDropdownOption}
							customSearchLabel={app.appName === "atlas"}
							handleCustomSearchLabel={(option, props) =>
								`${option?.[props?.labelKey]}${option?.brandName ? ` (${option?.brandName})` : ""}`
							}
							ellipsizedLength={100}
							multi={true}
							requiredLabel={true}
							showCheckBox={true}
							showSelectAllOption={app.appName === "prime"}
							optionUpdates={optionUpdates}
							handleCheck={() => {}}
							isCheckedAll={isCheckedAll}
							handleCheckAll={() => {}}
							placeholder="Select Locations"
							readOnly={true}
						/>
					</div>
				)}
				{!editMode && (
					<div className="form-row row-half">
						<div className="business">
							<div className="meta-info">Business</div>
							<div className="info">
								<div className="logo">
									{app?.appBizLogo ? (
										<img src={app?.appBizLogo} alt="" />
									) : (
										<div className="initials">{extractInitials(app?.appBizName)}</div>
									)}
								</div>
								<div className="name">{app?.appBizName}</div>
							</div>
						</div>
					</div>
				)}
				{!editMode && (
					<div className="form-row row-half">
						{(app?.appName === "atlas" ? app?.roles?.length > 0 : app?.roles) && (
							<div className="roles">
								<div className="meta-info">Roles</div>
								<div className="info">
									{app?.roles?.map((role, i) => (
										<span key={i} className="role" title={role.name}>
											{role.name}
											{i !== app?.roles?.length - 1 ? ", " : ""}
										</span>
									))}
								</div>
							</div>
						)}
						{(app?.locations?.length > 0 || app?.allLocations) && (
							<div className="locations">
								<div className="meta-info">Locations</div>
								<div className="info">
									{app?.allLocations
										? "All Locations"
										: app?.locations?.map((location, i) => {
												if (i < 8) {
													return (
														<span key={i} className="location" title={location.name}>
															{location.name}
															{location?.brandName ? ` (${location?.brandName})` : ""}
															{i !== app?.locations?.length - 1 && i !== 7 ? ", " : ""}
														</span>
													);
												}
										  })}
									{!app?.allLocations && app?.locations?.length > 8 && <span>...</span>}
									{app?.locations?.length > 8 && (
										<Popover
											showOnClick={true}
											renderPopover={() => renderPopover(app?.locations)}
											position="down-left"
										>
											<div className="view-all">View All</div>
										</Popover>
									)}
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
