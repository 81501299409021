import { ActionTypes } from "../actions/_types";

// constant
import {
	PRESET_TYPES,
	DATE_TYPES,
	COMPARE_DATE_TYPES,
	CUSTOM_TYPES
} from "../components/_commons/NewDateCompareFilter";

// third party
import moment from "moment";

const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[0].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[0],
		customTypeSelected: CUSTOM_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: { label: "Not specified", value: "not_specified" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "start_date",
		order: "DESC"
	},
	sortedField: "",
	currentFilters: {},
	appliedFilters: {},
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE
};

const getSortOrder = (currSort, newSort) => {
	return currSort.order === "" || currSort.field !== newSort.field
		? "ASC"
		: currSort.order === "ASC"
		? "DESC"
		: "ASC";
};

export const reconciliationPayoutsState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.RECONCILIATION_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RECONCILIATION_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.LOCATIONS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const RECONCILIATION_LIST_INIT_STATE = {
	firstLoad: true,
	loading: false,
	selectedPlatform: { value: "", valueForDisplay: "" },
	availablePlatforms: [
		{ value: "swiggy", valueForDisplay: "Swiggy" },
		{ value: "zomato", valueForDisplay: "Zomato" }
	],
	data: {
		objects: [],
		filters: []
	},
	selectedLocationPayoutStatus: null
};

export const reconciliationPayoutsList = (state = RECONCILIATION_LIST_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SET_FIRST_LOAD_STATE:
			return {
				...state,
				firstLoad: action?.payload || false
			};

		case ActionTypes.SET_SELECTED_PLATFORM:
			return {
				...state,
				selectedPlatform: { ...action.payload }
			};

		case ActionTypes.GET_PAYOUT_SHEETS_LIST_REQUEST:
			return {
				...state,
				loading: true
			};

		case ActionTypes.GET_PAYOUT_SHEETS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					...(action.payload ?? {})
				}
			};

		case ActionTypes.GET_PAYOUT_SHEETS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					...action.payload
				}
			};

		case ActionTypes.SET_SELECTED_LOCATION_PAYOUT_STATUS:
			return {
				...state,
				selectedLocationPayoutStatus: action.payload
			};
		default:
			return state;
	}
};

const RECONCILIATION_PAYOUT_SHEET_DETAILS_STATE = {
	loading: false,
	data: {}
};

export const reconciliationPayoutSheetDetails = (state = RECONCILIATION_PAYOUT_SHEET_DETAILS_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_PAYOUT_SHEET_DETAILS_REQUEST:
			return {
				...state,
				loading: true
			};

		case ActionTypes.GET_PAYOUT_SHEET_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...action.payload }
			};

		case ActionTypes.GET_PAYOUT_SHEET_DETAILS_FAILURE:
			return {
				...state,
				loading: false
			};

		default:
			return state;
	}
};

export const RECONCILIATION_STATS_DATE_FILTER_STATE = {
	// testing on local
	// current: {
	// 	dateFilter: moment('2022-05-01').format("YYYY-MM-DD")+","+moment('2022-05-07').format("YYYY-MM-DD"),
	// 	dateTypeSelected: DATE_TYPES[1],
	// 	customTypeSelected: CUSTOM_TYPES[0],
	// 	presetTypeSelected: PRESET_TYPES[9],
	// 	rangeStartDate: moment('2022-05-01'),
	// 	rangeEndDate: moment('2022-05-07'),
	// },
	current: {
		dateFilter: PRESET_TYPES[9].value,
		dateTypeSelected: DATE_TYPES[0],
		customTypeSelected: CUSTOM_TYPES[0],
		presetTypeSelected: PRESET_TYPES[9],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const RECONCILIATION_STATS_STATE = {
	currentDateFilter: RECONCILIATION_STATS_DATE_FILTER_STATE,
	appliedDateFilter: RECONCILIATION_STATS_DATE_FILTER_STATE,
	loadedDateFilter: RECONCILIATION_STATS_DATE_FILTER_STATE,
	currentFilters: {},
	appliedFilters: {}
};
export const reconciliationStatsState = (state = RECONCILIATION_STATS_STATE, action) => {
	switch (action.type) {
		case ActionTypes.RECONCILIATION_STATS_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RECONCILIATION_STATS_STATE_RESET:
			return RECONCILIATION_STATS_STATE;
		default:
			return state;
	}
};

const RECONCILIATION_STATS_INITIAL_STATE = {
	loading: false,
	data: {
		dataset: {
			dataset: []
		},
		sankeyChart: null,
		filters: []
	},
	error: false
};
export const reconciliationStats = (state = RECONCILIATION_STATS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_RECONCILIATION_STATS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_RECONCILIATION_STATS_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.GET_RECONCILIATION_STATS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
