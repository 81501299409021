import React from "react";

// component
import { Button } from "../_commons/Button";

const Footer = ({ currentProgressLevel, primaryAction, secondaryAction, isDisabled }) => {
	return (
		<div className="brand-creation-flow--footer">
			<Button clickHandler={primaryAction} classes={`${isDisabled ? "disabled" : ""}`}>
				{currentProgressLevel == 1 ? "Create" : "Next"}
			</Button>
			{currentProgressLevel !== 0 && (
				<Button type="secondary" clickHandler={secondaryAction}>
					Previous
				</Button>
			)}
		</div>
	);
};

export default Footer;
