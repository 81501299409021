import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Paginator } from "../_commons/Paginator";
import ItemEntityAssociations from "../EntityAssociations/ItemEntityAssociations";

// third party
import { useTrail, config, animated } from "react-spring";
import { connect } from "react-redux";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { printCurrency, parseFilters } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ITEM_GROUP_ITEMS } from "../../graphql/itemGroups";

// actions
import { ActionTypes } from "../../actions/_types";

// config
import { FOOD_TYPE_MAP, ITEM_ENTITY_ASSOCIATION_TYPES, NESTED_ENTITY_TYPES } from "../../client-config";

const TABLE_FIELDS = [
	{ label: "Name", value: "item-title" },
	{ label: "Category", value: "category" },
	{ label: "Price", value: "price" }
];

const FILTER_INITIAL_STATE = {
	title: "",
	item_discrete_category_id: undefined,
	tags: undefined,
	crm_title: ""
};

const Items = ({
	groupId,
	currencySymbol,
	setModalBusy,
	handleNestedEntity,
	includesAll,
	isDeleted = false,
	readOnly = true
}) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [categoryfilters, setCategoryfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [data, setData] = useState({
		objects: [],
		count: 0
	});

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(groupId),
				limit,
				offset,
				sort: {
					field: "sort_order",
					order: "ASC"
				}
			};
			// sidebar filters
			const filtersObject = parseFilters(appliedFilters);
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_ITEM_GROUP_ITEMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setData(resp.data.itemGroup.items);
			setCategoryfilters(
				resp.data.itemGroup.items.filters.find((fl) => fl.field === "item_discrete_category_id")
			);
			setTagfilters(resp.data.itemGroup.items.filters.find((fl) => fl.field === "tags"));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [groupId, limit, offset, appliedFilters]);

	useEffect(() => {
		if (!isDeleted) {
			fetchData();
		}
	}, [fetchData, isDeleted]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchData();
		}
	};

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			if (!isDeleted) {
				applyFilter(newFilters);
			}
		},
		[currFilters, applyFilter]
	);

	return (
		<div className={loading ? "no-click" : ""}>
			<div className="related-entities-header-container">
				<div>
					<div className="header-text">Associated Items</div>
					<div className="header-subtext">
						{includesAll
							? "All items are associated to this group"
							: `${data.count} item(s) are associated to this Item group`}
					</div>
				</div>
				<div>
					{!includesAll && !isDeleted && !readOnly && (
						<Button clickHandler={openAssociationSidebar}>Update</Button>
					)}
				</div>
			</div>
			<div className="search-container">
				<InputWithLabel
					value={currFilters.title}
					onChange={(e) => setFilter("title", e.target.value)}
					placeholder="Enter Name"
				>
					Name
				</InputWithLabel>
				<SelectFilter
					title="Category"
					options={categoryfilters.values || []}
					field={categoryfilters.field || ""}
					currValue={currFilters.item_discrete_category_id || ""}
					setFilter={setFilter}
					labelKey="valueForDisplay"
					valueKey="value"
				/>
				<SelectFilter
					title="Tags"
					options={tagfilters.values || []}
					field={tagfilters.field || ""}
					currValue={currFilters.tags || ""}
					setFilter={setFilter}
					labelKey="valueForDisplay"
					valueKey="value"
				/>
			</div>
			<div className="crm-title-search-container">
				<InputWithLabel
					value={currFilters.crm_title}
					onChange={(e) => setFilter("crm_title", e.target.value)}
					placeholder="Enter CRM Title"
				>
					CRM Title
				</InputWithLabel>
			</div>
			<Table
				data={data.objects}
				loading={loading}
				currencySymbol={currencySymbol}
				handleNestedEntity={handleNestedEntity}
			/>
			<Paginator limit={limit} offset={offset} count={data.count || 0} goToPage={handlePagination} />
			<ItemEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={ITEM_ENTITY_ASSOCIATION_TYPES[2]}
				entityId={parseInt(groupId)}
				currencySymbol={currencySymbol}
			/>
		</div>
	);
};
export default connect((store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(Items);

export const Table = ({ data, loading, sortList, sortedField, currencySymbol, handleNestedEntity }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container item-group-items-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader sortList={sortList} sortedField={sortedField} headerFields={TABLE_FIELDS} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							currencySymbol={currencySymbol}
							handleNestedEntity={handleNestedEntity}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Items found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	itemTitle,
	category,
	itemPrice,
	style,
	foodType,
	crmTitle,
	tags,
	currencySymbol,
	handleNestedEntity
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text item-title" title={itemTitle}>
				<div className="item-desc">
					<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<a
						role="button"
						className="link-text"
						onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[1], id)}
					>
						{itemTitle || id}
					</a>
				</div>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
				<div className="text--light item_tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 3 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 3 && (
									<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
										+{tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text category">{category || "--"}</div>
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{itemPrice || 0}
			</div>
		</animated.div>
	);
};
