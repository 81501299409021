import React, { useMemo, useCallback } from "react";

// components
import { Summary } from "./Summary";
import { Switch } from "../_commons/Switch";
import { InputWithLabel } from "../_commons/InputWithLabel";

// utils
import { generateReadableAddress, isPrimaryAddress } from "../../atlas-utils";

// third party
import moment from "moment";

export const Basic = ({
	id,
	loading,
	gender,
	signupStore,
	birthdate,
	anniversary,
	isActive,
	emailNotificationEnabled,
	smsNotificationEnabled,
	addresses = [],
	handleCustomerUpdate,
	trackEvent,
	currencySymbol,
	productTypeOptions,
	configItems,
	...rest
}) => {
	const signupLocation = signupStore && signupStore.title ? signupStore.title : "--";

	const primaryAddresses = useMemo(() => {
		return addresses.filter((address) => isPrimaryAddress(address));
	}, [addresses]);

	const nonPrimaryAddresses = useMemo(() => {
		return addresses.filter((address) => !isPrimaryAddress(address));
	}, [addresses]);

	const handleCustomerEngagement = useCallback(
		(field, value) => {
			handleCustomerUpdate(field, value);
			// const eventMeta = {
			// 	sms: (field === 'smsNotificationEnabled') ? value : smsNotificationEnabled,
			// 	email: (field === 'emailNotificationEnabled') ? value : emailNotificationEnabled,
			// 	block: (field === 'isActive') ? !value : isActive,
			// }
			// trackEvent('customer_engagement_update', eventMeta);
		},
		[smsNotificationEnabled, emailNotificationEnabled, isActive]
	);

	return (
		<div className="customer-basic customer-details-tab">
			<div className="form-row row-half">
				<InputWithLabel value={gender || "--"} readOnly={true}>
					Gender
				</InputWithLabel>
				<InputWithLabel value={(birthdate && moment(birthdate).format("DD MMM, YYYY")) || "--"} readOnly={true}>
					Birthday
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={(anniversary && moment(anniversary).format("DD MMM, YYYY")) || "--"}
					readOnly={true}
				>
					Anniversary
				</InputWithLabel>
				<InputWithLabel value={signupLocation || "--"} readOnly={true}>
					Signup Location
				</InputWithLabel>
			</div>
			<Summary
				loading={loading}
				currencySymbol={currencySymbol}
				productTypeOptions={productTypeOptions}
				configItems={configItems}
				handleCustomerUpdate={handleCustomerUpdate}
				trackEvent={trackEvent}
				id={id}
				{...rest}
			/>
			<div className="form-row row-half">
				<Switch
					title="Promo SMS Enabled"
					checked={smsNotificationEnabled}
					clickHandler={() => handleCustomerEngagement("smsNotificationEnabled", !smsNotificationEnabled)}
					showCustomTooltip={true}
					tooltipInfo={`Customer ${smsNotificationEnabled ? "will" : "won't"} receive SMS Campaigns`}
				/>
				<Switch
					title="Block Customer"
					checked={isActive !== undefined ? !isActive : false}
					clickHandler={() => handleCustomerEngagement("isActive", !isActive)}
					showCustomTooltip={true}
					tooltipInfo={`Customer ${isActive ? "will" : "won't"} receive Loyalty points.
					This is irreversible after you navigate away from this page`}
					tooltipPosition="up"
				/>
			</div>
			<div className="form-row row-half">
				<Switch
					title="Promo E-Mails Enabled"
					checked={emailNotificationEnabled}
					clickHandler={() => handleCustomerEngagement("emailNotificationEnabled", !emailNotificationEnabled)}
					showCustomTooltip={true}
					tooltipInfo={`Customer ${emailNotificationEnabled ? "will" : "won't"} receive E-Mail Campaigns`}
				/>
			</div>
			{addresses.length > 0 && (
				<div className="section customer-addresses">
					<div className="title">Saved Addresses</div>
					<div className="addresses">
						{primaryAddresses.map((address, i) => (
							<AddressItem key={i} address={address} />
						))}
						{nonPrimaryAddresses.map((address, i) => (
							<AddressItem key={i} address={address} />
						))}
					</div>
				</div>
			)}
		</div>
	);
};

const PersonalInfoItem = ({ title, value, classes = "", loading }) => (
	<div className="info-item">
		<div className="title">{title}</div>
		{!loading ? (
			<div className={"value " + classes}>{value ? value : "--"}</div>
		) : (
			<div className="shimmer W(50px) H(16px)"></div>
		)}
	</div>
);

const AddressItem = ({ address }) => {
	let tag;
	let addressIcon = "/assets/icons/address.png";
	if (address.tag && address.tag.toLowerCase().indexOf("guest") < 0) {
		tag = address.tag.toLowerCase();
		if (tag == "home") {
			addressIcon = "/assets/icons/home.png";
		} else if (tag == "work") {
			addressIcon = "/assets/icons/work.png";
		}
	}
	const readableAddress = generateReadableAddress(address);
	return (
		<div className="address-item">
			<img className="address-icon" src={addressIcon} alt="address-tag-icon" />
			<div className="address-details">
				<div className="tag">{tag || "Other"}</div>
				<div className="longform">{readableAddress}</div>
			</div>
		</div>
	);
};
