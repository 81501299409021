import React from "react";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import camelcase from "camelcase";
import moment from "moment";

// graphql
import {
	GET_REPORT_GROUPS_LIST,
	GET_REPORT_GROUPS_NEW_LIST,
	GET_REPORT_DETAIL,
	GENERATE_REPORT,
	GENERATE_REPORT_V2,
	GET_REPORTS_TASK_LIST,
	GET_REPORTS_EXPORT_HISTORY,
	GET_REPORT_PREVIEW,
	GET_SCHEDULE_REPORTS_LIST,
	CREATE_SCHEDULE_REPORT,
	GET_SCHEDULE_REPORT_DETAIL,
	EDIT_SCHEDULE_REPORT
} from "../graphql/reports";

// utils
import { msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// services
import NotificationServices from "../services/NotificationService";

export const fetchReportsList = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REPORTS_LIST_REQUEST
	});
	try {
		const resp = await client.query({
			query: GET_REPORT_GROUPS_LIST,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_REPORTS_LIST_SUCCESS,
			payload: resp.data.reportGroups
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORTS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReportsNewList = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REPORTS_NEW_LIST_REQUEST
	});
	try {
		const resp = await client.query({
			query: GET_REPORT_GROUPS_NEW_LIST,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_REPORTS_NEW_LIST_SUCCESS,
			payload: resp.data.reportV2Groups?.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORTS_NEW_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const generateReport = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: GENERATE_REPORT,
			variables: { input: variables }
		});
		if (resp.data.generateReport.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.generateReport.status.messages),
					timeout: 5000,
					error: false
				}
			});
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.generateReport.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
		return resp.data.generateReport.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchReportDetail = async (id, showPreselectedOptions = true) => {
	const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	store.dispatch({
		type: ActionTypes.GET_REPORT_DETAIL_REQUEST
	});
	try {
		const variables = {
			reportId: id
		};
		const resp = await client.query({
			query: GET_REPORT_DETAIL,
			variables,
			fetchPolicy: "no-cache"
		});
		const reportDetail = resp.data.reportDetail;
		const isCheckedAll = {};
		const optionUpdates = {};
		let locationsList = [];

		reportDetail.filters = reportDetail.filters.map((f) => {
			// update locations filter's valueForDisplay for multi brand enabled biz
			if (isMultibrandEnabled && f.field === "locations") {
				f.values = f.values.map((val) => ({
					value: val.value,
					valueForDisplay: `${val.valueForDisplay} (Brand: ${
						val.additionals.find((obj) => obj.key === "brand_name")?.value || "--"
					})`,
					...val.additionals.reduce((obj, val) => {
						obj[camelcase(val.key)] = val.value;
						return obj;
					}, {})
				}));
				// save a copy of locations list
				locationsList = [...f.values];
			}

			// update brands field in filters
			if (f.field === "brand_id") {
				f.field = "brands";
			}

			// show pre-selected options for all filters based on default values
			if (showPreselectedOptions) {
				isCheckedAll[f?.field?.replace(/([-_]\w)/g, (f) => f[1]?.toUpperCase())] =
					f?.default?.length === f?.values?.length;
				optionUpdates[f?.field?.replace(/([-_]\w)/g, (f) => f[1]?.toUpperCase())] = f.default.reduce(
					(obj, val) => {
						obj[val.value] = true;
						return obj;
					},
					{}
				);
			}

			return f;
		});

		// update report detail state
		store.dispatch({
			type: ActionTypes.GET_REPORT_DETAIL_SUCCESS,
			payload: {
				...reportDetail,
				locationsList
			}
		});
		if (showPreselectedOptions) {
			store.dispatch({
				type: ActionTypes.UPDATE_REPORT_DETAIL,
				payload: {
					isCheckedAll,
					optionUpdates
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORT_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching report details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const generateReportV2 = async (exportType) => {
	try {
		const { data, optionUpdates, appliedDateFilter, emails } = store.getState().reportDetail;
		const variables = {
			id: data.id,
			name: data.name,
			exportFormat: exportType.id,
			emails,
			filters: {}
		};
		for (let filter in optionUpdates) {
			if (filter !== "brands") {
				variables.filters[filter] =
					data?.filters?.find((filter) => filter.field === "time_period") && filter === "timePeriod"
						? appliedDateFilter?.current?.dateFilter
						: [
								...(variables.filters[filter] ?? []),
								...Object.keys(optionUpdates[filter]).filter((key) => optionUpdates[filter][key])
						  ];
			}
		}
		const resp = await client.mutate({
			mutation: GENERATE_REPORT_V2,
			variables: { input: variables }
		});
		if (resp.data.generateReportV2.status.success) {
			// show global notification using NotificationServices
			NotificationServices.pushNotification({
				message: resp.data.generateReportV2.status.messages[0]?.message,
				timeout: 3000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.generateReportV2.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
		return resp.data.generateReportV2.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchPendingReportsTaskList = async () => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const resp = await client.query({
			query: GET_REPORTS_TASK_LIST,
			variables: {
				limit: 100,
				offset: 0,
				filters: [
					{
						field: "status",
						value: "pending"
					}
				]
			},
			fetchPolicy: "no-cache"
		});

		// add pending report download tasks to ongoing tasks list
		const pendingReportTasks = resp.data?.reportTaskList?.objects || [];
		pendingReportTasks.forEach((task) => {
			store.dispatch({
				type: "ADD_TASK_TO_ONGOING_TASKS_LIST",
				payload: {
					id: task.jobId,
					type: "export-report",
					status: task.status,
					name: `Downloading ${task.name}`
				}
			});
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReportsTaskList = async (ongoingTasks = []) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const resp = await client.query({
			query: GET_REPORTS_TASK_LIST,
			variables: {
				limit: 100,
				offset: 0,
				filters: [
					{
						field: "status",
						value: "completed,failed"
					}
				]
			},
			fetchPolicy: "no-cache"
		});

		// update ongoing report download tasks status to completed/failed
		ongoingTasks.forEach((task) => {
			const report = resp.data.reportTaskList?.objects?.find((r) => r.jobId === task.id);
			if (report) {
				// download generated report
				if (report.status === "completed" && report.downloadUrl) {
					const link = document.createElement("a");
					link.href = report.downloadUrl;
					link.click();
				}

				// show global notification using NotificationServices
				setTimeout(() => {
					NotificationServices.pushNotification({
						message:
							report.status === "failed" ? "Report generation failed" : "Report generated successfully",
						timeout: 3000,
						type: report.status === "failed" ? "error" : "success",
						isClosable: true,
						theme: "dark"
					});

					// update task in ongoing tasks list
					store.dispatch({
						type: ActionTypes.UPDATE_TASK_IN_ONGOING_TASKS_LIST,
						payload: {
							id: report.jobId,
							status: report.status
						}
					});
				}, 1000);
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReportsExportHistory = async (search = "") => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_EXPORT_REPORT_HISTORY_REQUEST
	});
	const { limit, offset, prevId, nextId, appliedFilters, appliedDateFilter, data } =
		store.getState().exportReportHistory;
	try {
		const variables = { limit };
		if (nextId) {
			variables.nextId = nextId;
		}
		if (prevId) {
			variables.prevId = prevId;
		}
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (
				(appliedFilters[f] && appliedFilters[f]?.value !== "all") ||
				(f === "downloaded_by" && appliedFilters[f]?.value)
			) {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]?.value
				});
			}
		});
		// date filter
		if (appliedDateFilter.current.dateFilter) {
			filtersObject.push({
				field: "time_period",
				value: appliedDateFilter.current.dateFilter
			});
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (data.searchFieldSelected && search) {
			variables.search = [{ key: data.searchFieldSelected.key, value: search }];
		}
		const resp = await client.query({
			query: GET_REPORTS_EXPORT_HISTORY,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_EXPORT_REPORT_HISTORY_SUCCESS,
			payload: {
				...(resp.data.reportHistory ?? {}),
				searchFieldSelected: resp.data.reportHistory?.searchKeywords?.[0],
				hasPrevious: !!offset
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_EXPORT_REPORT_HISTORY_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

// get tabular data for report preview
export const getTabularData = (dataset) => {
	const tabularData = {};

	// count
	tabularData.count = dataset?.rows?.length || 0;

	// column value data type map
	const columnValueTypeMap = {};

	// add serial no. column and row values
	if (dataset?.columns?.length > 0 && dataset?.rows?.length > 0) {
		dataset.columns = [{ key: "sr_no", display: "Sr No", dataType: "char" }, ...(dataset.columns ?? [])];
		dataset.rows = dataset?.rows?.map((row, i) => [{ key: "sr_no", value: `${i + 1}` }, ...(row ?? [])]);
	}

	// fields
	tabularData.fields =
		dataset.columns?.map((col) => {
			if (col.dataType) {
				columnValueTypeMap[col.key] = col.dataType;
			}
			return col;
		}) || [];

	// columns
	tabularData.columns =
		dataset.columns?.map((col) => ({
			name: col.display,
			field: col.key,
			classes: col.dataType === "float" ? "align-right" : "",
			render: (record, i, rest) => (
				<div
					className={`table-cell ${col.key?.toLowerCase()} ${col.dataType === "float" ? "align-right" : ""}`}
					title={record[col.key] && record[col.key] !== "--" ? record[col.key] : null}
					style={
						rest?.tableOverflow && rest?.columnWidths?.[col.key]
							? { minWidth: rest?.columnWidths?.[col.key] }
							: {}
					}
					key={i}
				>
					<div className="primary">
						{col.dataType === "date"
							? moment(record[col.key]).format("DD MMM, YYYY - hh:mm A")
							: col.dataType === "float"
							? parseFloat(record[col.key].toFixed(2))
							: record[col.key]}
					</div>
				</div>
			)
		})) || [];

	// rows
	tabularData.rows =
		dataset.rows?.map((row) => {
			const rowData = {};
			row.forEach((obj) => {
				rowData[obj.key] = obj.value === null ? "--" : obj.value;
			});
			return rowData;
		}) || [];

	return tabularData;
};

export const fetchReportPreview = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REPORT_PREVIEW_REQUEST
	});
	try {
		const { data, optionUpdates, appliedDateFilter } = store.getState().reportDetail;
		const variables = {
			limit: 20,
			filters: {
				reportTypeId: data.id
			}
		};
		for (let filter in optionUpdates) {
			if (filter !== "brands") {
				variables.filters[filter] =
					data?.filters?.find((filter) => filter.field === "time_period") && filter === "timePeriod"
						? appliedDateFilter?.current?.dateFilter
						: [
								...(variables.filters[filter] ?? []),
								...Object.keys(optionUpdates[filter]).filter((key) => optionUpdates[filter][key])
						  ];
			}
		}
		const resp = await client.query({
			query: GET_REPORT_PREVIEW,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_REPORT_PREVIEW_SUCCESS,
			payload: {
				...(resp?.data?.reportPreview || {}),
				tabularData: getTabularData(resp?.data?.reportPreview || {})
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORT_PREVIEW_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchScheduleReportsList = async (search = "") => {
	const { limit, offset, sort, appliedFilters, data } = store.getState().scheduleReportsList;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_SCHEDULE_REPORTS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};

		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (
				(appliedFilters[f] && appliedFilters[f]?.value !== "all") ||
				(f === "report_user" && appliedFilters[f]?.value)
			) {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]?.value
				});
			}
		});
		// set filter
		variables.filters = filtersObject;

		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}

		// search filter
		if (data.searchFieldSelected && search) {
			variables.search = [{ key: data.searchFieldSelected.key, value: search }];
		}
		const resp = await client.query({
			query: GET_SCHEDULE_REPORTS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_SCHEDULE_REPORTS_LIST_SUCCESS,
			payload: { ...resp.data.reportScheduleList }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_SCHEDULE_REPORTS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const createScheduleReport = async (variables = {}) => {
	store.dispatch({
		type: ActionTypes.SCHEDULE_REPORT_CREATE_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: CREATE_SCHEDULE_REPORT,
			variables: { input: variables }
		});
		if (resp.data.editReportSchedule.status.success) {
			// show global notification using NotificationServices
			NotificationServices.pushNotification({
				// message: resp.data.editReportSchedule.status.messages[0]?.message,
				message: "Report Scheduled Successfully",
				timeout: 3000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
		}
		if (!resp.data.editReportSchedule.status.success && variables?.reportId) {
			// handle error
			store.dispatch({
				type: ActionTypes.SCHEDULE_REPORT_CREATE_FAILURE,
				error: {}
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.editReportSchedule.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
		if (
			!resp.data.editReportSchedule.status.success &&
			resp.data.editReportSchedule.status.messages[0]?.message === "Input is invalid" &&
			!variables?.reportId
		) {
			store.dispatch({
				type: ActionTypes.SCHEDULE_REPORT_CREATE_SUCCESS,
				payload: {
					scheduleReportFilters:
						resp?.data?.editReportSchedule?.filters?.map((f) => ({
							...f,
							field: camelcase(f.field)
						})) || []
				}
			});
		}
		return resp.data.editReportSchedule.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SCHEDULE_REPORT_CREATE_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchScheduleReportDetail = async (id) => {
	store.dispatch({
		type: ActionTypes.SCHEDULE_REPORT_DETAIL_REQUEST
	});
	try {
		const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
		const resp = await client.query({
			query: GET_SCHEDULE_REPORT_DETAIL,
			variables: { id },
			fetchPolicy: "no-cache"
		});

		const reportScheduleDetail = resp.data.reportScheduleDetail;
		const { data } = store.getState().reportDetail;
		const isCheckedAll = {};
		const optionUpdates = {};

		data.filters.forEach((f) => {
			// find filter in report schedule detail
			const scheduleFilter = reportScheduleDetail?.filtersAdded?.find((fltr) => fltr.title === f.field);

			if (scheduleFilter) {
				// show pre-selected options for all filters based on schedule filter values
				isCheckedAll[camelcase(f?.field)] = scheduleFilter?.values?.length === f?.values?.length;
				optionUpdates[camelcase(f?.field)] = {};
				f.values.forEach((val) => {
					if (scheduleFilter?.values?.includes(val?.value)) {
						optionUpdates[camelcase(f?.field)][val.value] = true;
					}
				});
			}
		});

		// if biz is a multi-brand and if brands & locations filters exist in data
		// show pre-selected options for brands filter based on location filter values
		const locationsFilter = data?.filters?.find((f) => f.field === "locations");
		const brandsFilter = data?.filters?.find((f) => f.field === "brands");
		if (isMultibrandEnabled && locationsFilter && brandsFilter) {
			const scheduleFilter = reportScheduleDetail?.filtersAdded?.find((fltr) => fltr.title === "locations");

			let brandsSelected = {};
			scheduleFilter.values.forEach((val) => {
				const location = locationsFilter.values.find((loc) => loc.value === val);
				if (location) {
					brandsSelected[location.brandId] = true;
				}
			});

			isCheckedAll["brands"] = brandsFilter?.values?.length === Object.keys(brandsSelected)?.length;
			optionUpdates["brands"] = { ...brandsSelected };
		}

		const currentFilters = {
			showDataFor: {
				valueForDisplay: reportScheduleDetail.showDataFor,
				value: reportScheduleDetail.showDataFor
			},
			scheduleFor: {
				valueForDisplay: reportScheduleDetail.scheduleType,
				value: reportScheduleDetail.scheduleType
			},
			exportFormat:
				data.exports
					?.map((exp) => ({ ...exp, name: exp.name.replace("Download ", "") }))
					?.find((exp) => exp.id === reportScheduleDetail.exportFormat) || null
		};

		// update report detail state
		store.dispatch({
			type: ActionTypes.SCHEDULE_REPORT_DETAIL_SUCCESS,
			payload: {
				...reportScheduleDetail
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload: {
				isCheckedAll,
				optionUpdates,
				currentFilters,
				appliedFilters: currentFilters,
				emails: reportScheduleDetail.emails
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SCHEDULE_REPORT_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching schedule report details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

const parseScheduleReportData = (data, from = "detail") => {
	let result = {};
	switch (from) {
		case "list":
			result.id = data?.id;
			result.name = data?.report?.name;
			result.reportId = data?.report?.typeId;
			result.group = data?.report?.group;
			result.filters = {};
			(data.filtersAdded ?? []).forEach((f) => {
				result.filters[camelcase(f.title)] = f.values;
			});
			result.emails = data.emails;
			result.scheduleFor = data.scheduleType;
			result.showDataFor = data.showDataFor;
			result.exportFormat = data.exportFormat;
			result.status = data.status;
			break;
		case "detail":
			result = { ...data };
			break;
		default:
			break;
	}
	return result;
};

export const editScheduleReport = async (data = {}, fromListView = false) => {
	store.dispatch({
		type: ActionTypes.SCHEDULE_REPORT_DETAIL_REQUEST
	});
	try {
		const variables = parseScheduleReportData(data, fromListView ? "list" : "detail");

		const resp = await client.mutate({
			mutation: EDIT_SCHEDULE_REPORT,
			variables: { input: variables }
		});
		if (resp.data.editReportSchedule.status?.success) {
			// show global notification using NotificationServices
			NotificationServices.pushNotification({
				// message: resp.data.editReportSchedule.status.messages[0]?.message,
				message: fromListView ? `Report schedule is now ${variables.status}` : `Saved Report schedule details`,
				timeout: 3000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
			store.dispatch({
				type: ActionTypes.SCHEDULE_REPORT_DETAIL_SUCCESS,
				payload: {}
			});
			store.dispatch({
				type: ActionTypes.SCHEDULE_REPORT_CREATE_SUCCESS,
				payload: {
					scheduleReportFilters:
						resp?.data?.editReportSchedule?.filters?.map((f) => ({
							...f,
							field: camelcase(f.field)
						})) || []
				}
			});
		} else {
			// handle error
			store.dispatch({
				type: ActionTypes.SCHEDULE_REPORT_DETAIL_FAILURE,
				error: {}
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.editReportSchedule.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
		return resp.data.editReportSchedule.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SCHEDULE_REPORT_DETAIL_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchScheduleReportHistory = async (schedule_id) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_SCHEDULE_REPORT_HISTORY_REQUEST
	});
	const { limit, offset, prevId, nextId, appliedFilters } = store.getState().scheduleReportHistory;
	try {
		const variables = { limit };
		if (nextId) {
			variables.nextId = nextId;
		}
		if (prevId) {
			variables.prevId = prevId;
		}
		// filters
		let filtersObject = [
			{
				field: "schedule_id",
				value: schedule_id
			}
		];
		Object.keys(appliedFilters).forEach((f) => {
			if (
				(appliedFilters[f] && appliedFilters[f]?.value !== "all") ||
				(f === "downloaded_by" && appliedFilters[f]?.value)
			) {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]?.value
				});
			}
		});
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_REPORTS_EXPORT_HISTORY,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_SCHEDULE_REPORT_HISTORY_SUCCESS,
			payload: {
				...(resp.data.reportHistory ?? {}),
				hasPrevious: !!offset
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_SCHEDULE_REPORT_HISTORY_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
