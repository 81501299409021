import React from "react";

// third party
import moment from "moment";
import { useTrail, config, animated } from "react-spring";

// constants
import {
	OPERATIONAL_EFFICIENCY_ORDERS_HEADER_FIELDS,
	EXTERNAL_PLATFORMS_LOGO,
	NESTED_ENTITY_TYPES
} from "../../client-config";

export const Table = ({ data, loading, dimensions, uniqueId, bizLocationId, sortHandler, handleNestedEntity }) => {
	let orders = data.orders || [];
	const trails = useTrail(orders.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div className={(orders.length > 0 && loading ? "disabled" : "") + " transaction-table-holder"}>
			<div className="transactions-list-table">
				<div className="at-table-row-based">
					<TableHeader headerFields={OPERATIONAL_EFFICIENCY_ORDERS_HEADER_FIELDS} sortHandler={sortHandler} />
					{trails.map(({ rotate }, i) => (
						<TableListResponsive
							key={uniqueId ? orders[i][uniqueId] : orders[i].id}
							dimensions={dimensions}
							bizLocationId={bizLocationId}
							handleNestedEntity={handleNestedEntity}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...orders[i]}
						/>
					))}
					{orders.length === 0 && !loading && (
						<div className="no-items-placeholder">No Transactions history!</div>
					)}
					{orders.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ headerFields, sortHandler }) => (
	<div className={`at-table-row transaction-header-row`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`${field.value} at-table-cell at-table-header at-header-text ${
						field.value !== "order_id" ? "clickable" : ""
					}`}
					style={field.value === "order_id" ? { width: "9%" } : { width: "5%" }}
					onClick={field.value === "order_id" ? undefined : () => sortHandler(field.value)}
				>
					{field.label}
					{field.value !== "order_id" && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

const TableListResponsive = ({ style, dimensions, ...rest }) => (
	<React.Fragment>
		<TableList {...rest} dimensions={dimensions} style={style} />
	</React.Fragment>
);
const parseTimeStamp = (timeStamp) => {
	return moment(timeStamp).isValid()
		? [moment(timeStamp).format("DD MMM, YYYY"), moment(timeStamp).format("hh:mm A")]
		: ["-", ""];
};
const TableList = ({
	orderChannel,
	orderId,
	platformOrderId,
	placed,
	acknowledged,
	foodReady,
	dispatched,
	completed,
	dimensions,
	style,
	bizLocationId,
	handleNestedEntity
}) => {
	const extPlatLogo = EXTERNAL_PLATFORMS_LOGO[orderChannel.toUpperCase()];
	const placedDisplay = parseTimeStamp(placed);
	const acknowledgedDisplay = parseTimeStamp(acknowledged);
	const foodReadyDisplay = parseTimeStamp(foodReady);
	const dispatchedDisplay = parseTimeStamp(dispatched);
	const completedDisplay = parseTimeStamp(completed);
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text id" title={orderId}>
				<a
					role="button"
					className="link-text"
					onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[11], orderId)}
				>
					{orderId}
				</a>
				{extPlatLogo && dimensions.width > 768 && <img src={extPlatLogo} className="ext-plt-icon" />}
				{orderChannel && (
					<div className="external-id" title={orderChannel}>
						{orderChannel}
						{platformOrderId && <span>:&nbsp;{platformOrderId}</span>}
					</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{placedDisplay[0]}</div>
				<div className="date-time">{placedDisplay[1]}</div>
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{acknowledgedDisplay[0]}</div>
				<div className="date-time">{acknowledgedDisplay[1]}</div>
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{foodReadyDisplay[0]}</div>
				<div className="date-time">{foodReadyDisplay[1]}</div>
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{dispatchedDisplay[0]}</div>
				<div className="date-time">{dispatchedDisplay[1]}</div>
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{completedDisplay[0]}</div>
				<div className="date-time">{completedDisplay[1]}</div>
			</div>
		</animated.div>
	);
};
