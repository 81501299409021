import React, { useState, useEffect } from "react";

export const ProgressBar = ({
	maximum = 100,
	currProgress = 0,
	animated = false,
	showProgress = false,
	inPercentage = false,
	entity = ""
}) => {
	// const [progress, setProgress] = useState(currProgress);
	// console.log(progress)
	// useEffect(() => {
	// 	if (progress < maximum) {
	// 		setTimeout(() => {
	// 			setProgress(progress + 1);
	// 		}, 1000);
	// 	}
	// }, [progress]);

	return (
		<div className="progress-bar-container">
			<div className={"progress-bar" + (animated ? " animated" : "")}>
				<span style={{ width: `${(currProgress / maximum) * 100}%` }}></span>
			</div>
			{showProgress && (
				<div className="progress">
					{inPercentage
						? `${Math.ceil((currProgress / maximum) * 100)}%`
						: `${currProgress} of ${maximum} ${entity}`}
				</div>
			)}
		</div>
	);
};
