import React, { useState } from "react";

// component
import { Button } from "../_commons/Button";
import { ButtonIcon } from "../_commons/ButtonIcon";
import CreateIcon from "../_commons/CreateIcon";
import { Filters } from "../_commons/Filters";
import FormTable from "../_commons/FormTable";
import { Paginator } from "../_commons/Paginator";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// client
import history from "../../history";

const columns = [
	{
		title: "Name",
		dataIndex: "name",
		render: (data) => data
	},
	{
		title: "Location ID",
		dataIndex: "id",
		render: (data) => data
	},
	{
		title: "city",
		dataIndex: "city",
		render: (data) => data
	},
	{
		title: "Address",
		dataIndex: "address",
		render: (data) => data
	}
];

const LocationsAssocation = ({
	associatedLocationsData,
	currentFilters,
	setShowFilters,
	cityFilterOptions,
	setAndApplyCityFilter,
	cityFilter,
	debouncedNameSearch,
	handlePagination,
	offset,
	allSelected,
	selectedLocations,
	handleAllFieldSelection,
	handleSingleFieldSelection,
	locationName,
	setLocationName
}) => {
	const handlePlaceholderButtonClick = () => {
		history.push("/locations/new");
	};

	const openFiltersSidebar = () => {
		setShowFilters(true);
	};

	const placeholderContent = {
		placeholderText: "No locations added yet!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-location.svg",
		placeholderButtonContent: (
			<>
				<CreateIcon />
				<span>Add Your First Location</span>
			</>
		),
		placeholderButtonClickAction: handlePlaceholderButtonClick,
		// redirectionLink: 'www.google.com',
		redirectionLinkText: "learn more about locations"
	};

	if (associatedLocationsData.loading) {
		return (
			<div className="brand-creation-flow--locations-association brand-creation-flow--container">
				<div className="title">Associate Locations</div>
				<InlineFilters openFiltersSidebar={openFiltersSidebar} />
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}

	return (
		<React.Fragment>
			<div className="brand-creation-flow--locations-association brand-creation-flow--container">
				<div className="title">Associate Locations</div>
				{associatedLocationsData?.data?.objects.length !== 0 && (
					<InlineFilters
						openFiltersSidebar={openFiltersSidebar}
						cityFilterOptions={cityFilterOptions}
						setAndApplyCityFilter={setAndApplyCityFilter}
						cityFilter={cityFilter}
						debouncedNameSearch={debouncedNameSearch}
						currentFilters={currentFilters}
						locationName={locationName}
						setLocationName={setLocationName}
					/>
				)}
				<FormTable
					placeholderContent={placeholderContent}
					columns={columns}
					dataSource={associatedLocationsData?.data?.objects || []}
					contentTableClass="brand-creation-loc-assoc-table"
					isSelectionEnabled
					handleAllFieldsSelection={handleAllFieldSelection}
					isAllFieldSelected={allSelected}
					selectedFields={selectedLocations}
					handleSingleFieldSelection={handleSingleFieldSelection}
				/>
				<Paginator
					count={associatedLocationsData?.data?.count || 0}
					limit={associatedLocationsData?.data?.limit || 0}
					goToPage={handlePagination}
					offset={offset}
				/>
			</div>
		</React.Fragment>
	);
};
export default LocationsAssocation;

const InlineFilters = ({ cityFilterOptions, setAndApplyCityFilter, setLocationName }) => {
	const [currentCity, setCurrentCity] = useState();
	const [searchLocation, setSearchLocation] = useState("");
	const handleNameChange = (field, value) => {
		setSearchLocation(value);
		if (typeof setLocationName === "function") {
			setLocationName(value);
		}
	};

	const setCityFilter = (field, value) => {
		setCurrentCity(value);
		setAndApplyCityFilter(field, value);
	};

	return (
		<div className="filters">
			<div className="section-1">
				<SelectFilter
					placeholder="City"
					options={cityFilterOptions?.[0]?.values || []}
					labelKey="valueForDisplay"
					valueKey="value"
					field="city"
					currValue={currentCity}
					setFilter={setCityFilter}
				/>
				{/* <Button type='secondary' clickHandler={openFiltersSidebar}><ButtonIcon icon='filter'/>Filters</Button> */}
			</div>
			<div className="section-2">
				<SearchFilter
					placeholder="Search"
					filterOption={{ field: "name" }}
					setFilter={handleNameChange}
					value={searchLocation}
				/>
			</div>
		</div>
	);
};
