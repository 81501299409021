import gql from "graphql-tag";

export const GET_REFERRALS_LIST = gql`
	query listReferrals(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		referrals(limit: $limit, offset: $offset, sort: $sort, filters: $filters, search: $search) {
			objects {
				id
				referrer {
					id
					phone
					fullName
				}
				referred {
					id
					phone
					fullName
				}
				sharedOn
				codeOrLink
				status
				channel
				platform
			}
			count
			searchKeywords {
				key
				valueForDisplay
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
		}
	}
`;

export const GET_REFERRALS_STATS = gql`
	query referralStats($duration: Duration!) {
		referralStats(duration: $duration) {
			claimed {
				timestamp
				value
			}
			unclaimed {
				timestamp
				value
			}
			timeUnit
		}
	}
`;
