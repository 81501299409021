import React, { useState, useEffect } from "react";

// components
import Carousel, { CarouselItem } from "../_commons/Carousel";
import { Button } from "../_commons/Button";

// constants
const UP_BLOGS_URL = process.env.REACT_APP_API_URL_UP_BLOG_POSTS;
const BLOGS_URL = "https://www.urbanpiper.com/blog/"; // urbanpiper blogs url

const Announcements = () => {
	const [loading, setLoading] = useState(true);
	const [blogs, setBlogs] = useState([]);

	const fetchBlogs = async () => {
		setLoading(true);
		try {
			const response = await fetch(UP_BLOGS_URL, {
				method: "GET"
			});
			const blogPosts = await response.json();
			if (blogPosts?.status) {
				setBlogs(blogPosts?.data?.items);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchBlogs();
	}, []);

	return (
		<div className="blogs">
			{loading ? (
				<Placeholder />
			) : blogs.length > 0 ? (
				<Carousel>
					{blogs.map((blog, i) => (
						<CarouselItem key={i}>
							<Blog {...blog} />
						</CarouselItem>
					))}
				</Carousel>
			) : (
				<div className="no-data-placeholder">
					<img src="/assets/icons/icon-no-data.svg" alt="" />
					<div className="message--large">We are unable to fetch our latest blog posts at the moment</div>
					<div className="message--small">
						No worries, you can check them out{" "}
						<a
							className="link-text"
							href="https://www.urbanpiper.com/blog"
							target="_blank"
							rel="noopener noreferrer"
						>
							here
						</a>
						!
					</div>
				</div>
			)}
		</div>
	);
};
export default Announcements;

const Placeholder = () => (
	<div className="blog-container placeholder">
		<div className="shimmer img H(405px) W(100%)" />
		<div className="info">
			<div className="shimmer title H(20px) W(100%)" />
			<div className="shimmer desc H(30px) W(100%)" />
			<div className="shimmer btn H(25px) W(100px)" />
		</div>
	</div>
);

const Blog = ({ name, slug, thumb, "title-tag": title, "short-info": description, "featured-post-image": image }) => {
	return (
		<div className="blog-container">
			<img
				className="feature-image"
				src={image?.url || thumb?.url || "/assets/blog.jpg"}
				onError={(e) => (e.target.src = "/assets/blog.jpg")}
			/>
			<img
				className="background-image"
				src={image?.url || thumb?.url || "/assets/blog.jpg"}
				onError={(e) => (e.target.src = "/assets/blog.jpg")}
			/>
			<div className="info">
				<div className="text link" title={name || title || "--"}>
					<a target="_blank" rel="noopener noreferrer" href={`${BLOGS_URL}${slug}`}>
						{name || title || ""}
					</a>
				</div>
				<div className="text description" title={description}>
					{description || ""}
				</div>
				<div className="read-more">
					<a target="_blank" rel="noopener noreferrer" href={`${BLOGS_URL}${slug}`} className="link">
						<Button classes={"read-more-btn"}>Read more</Button>
					</a>
				</div>
			</div>
		</div>
	);
};
