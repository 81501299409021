import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/CategoryEdit/BasicInfo";
import AssociatedItems from "../components/CategoryEdit/AssociatedItems";
import AssociatedSubCategories from "../components/CategoryEdit/AssociatedSubcategories";
import { Modal } from "../components/_commons/Modal";
import { Loading } from "../components/_commons/Loading";
import { CheckBox } from "../components/_commons/CheckBox";

// utils
import {
	removeProp,
	parseSuperstructErrorMessages,
	scroll,
	adjustNestedContainer,
	findChangedKeysInTwoObjects,
	trackEvent
} from "../atlas-utils";
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import { validate } from "superstruct";
import Compressor from "compressorjs";

// actions
import {
	fetchTimingGroups,
	fetchParentCategories,
	resetParentCategories,
	fetchTranslation,
	updateTranslation,
	fetchParentCategoryIds
} from "../actions/actions";
import { fetchCategoryDetail, editCategory, fetchCategoriesList } from "../actions/categories";
import { ActionTypes } from "../actions/_types";
import { ASSOCIATE_DISOCIATE_SUBCATEGORIES } from "../graphql/categories";
import { client } from "../client";

// reducer
import { categoryDetailsReducer, CATEGORY_DETAILS_INITIAL_STATE } from "../reducers/categories";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constants
import {
	CATEGORIES_EDIT_ITEMS_LIST,
	NESTED_ENTITY_TYPES,
	TRACKING_EVENT_NAMES,
	TRACKING_SOURCE,
	TRACKING_STATUS
} from "../client-config";
import { CATEGORY_FORM_SCHEMA } from "./CategoryCreate";
import { fetchMenuCategoriesList } from "../actions/menus";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Associated Items",
		value: "associated_items"
	},
	{
		label: "Sub-categories",
		value: "subcategories"
	}
];

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const TRANSLATION_FIELDS_MAP = {
	name: "name",
	description: "description"
};

const CategoryEdit = ({
	match,
	access,
	readOnly = false,
	supportedLanguages = [],
	history,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef,
	selectedCategory,
	categoriesList,
	brands,
	biz
}) => {
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [currSelectedLang, setCurrSelectedLang] = useState({
		name: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		},
		description: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		}
	});
	const [categoryDetails, dispatch] = useReducer(categoryDetailsReducer, CATEGORY_DETAILS_INITIAL_STATE);
	const { loading, data, error, backupData } = categoryDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [image, setImage] = useState(undefined);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const [itemsCount, setItemsCount] = useState(null);
	const [categoriesRemoveModal, setCategoriesRemoveModalState] = useState({ isOpen: false, data: [] });

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		fetchTimingGroups();
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchCategoryDetail(parseInt(match.params.id), dispatch);
		fetchParentCategories(parseInt(match.params.id));
		return () => {
			resetParentCategories();
		};
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			if (isMenuOverCatalogueEnabled) {
				fetchMenuCategoriesList(0, categoriesList?.data?.searchFieldValue);
				history.push("/sections");
			} else {
				setTimeout(() => {
					if (isNested || isForeignSource) {
						closeNestedContainer();
					} else {
						if (selectedCategory) {
							fetchCategoriesList(
								data?.parent?.id ? data.parent.id : selectedCategory.id,
								brands.selectedBrand
							);
						} else {
							fetchCategoriesList(undefined, brands.selectedBrand);
						}
						fetchParentCategoryIds();
						history.push("/categories");
					}
				}, 100);
			}
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const setError = (error) => {
		dispatch({
			type: ActionTypes.EDIT_CATEGORY_DETAIL_FAILURE,
			error
		});
	};

	const validateData = (data) => {
		const [error] = validate(data, CATEGORY_FORM_SCHEMA);
		if (error) {
			setError(parseSuperstructErrorMessages(error));
			return false;
		}
		setError({});
		return true;
	};

	const handleForm = (field, value) => {
		dispatch({
			type: ActionTypes.UPDATE_CATEGORY_DETAIL,
			payload: {
				[field]: value
			}
		});
		const newData = {
			...data,
			[field]: value
		};
		validateData(newData);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleImage = (files) => {
		const imgFile = files[0];
		if (imgFile) {
			const image = new Image();
			image.src = window.URL.createObjectURL(imgFile);

			// proceed only if the uploaded file is a valid image
			image.onload = async function () {
				if (imgFile.size < 2097152) {
					setImage(imgFile);
					dispatch({
						type: ActionTypes.UPDATE_CATEGORY_DETAIL,
						payload: {
							deleteImage: false
						}
					});
					if (!isFormTouched) {
						setFormTouched(true);
					}
				} else {
					setConfirmLoading(true);
					const result = await new Promise((resolve, reject) => {
						new Compressor(imgFile, {
							quality: 0.6,
							maxWidth: 4000,
							success: resolve,
							error: reject
						});
					});
					if (result && result.size < 10475200) {
						setImage(result);
						setConfirmLoading(false);
						dispatch({
							type: ActionTypes.UPDATE_CATEGORY_DETAIL,
							payload: {
								deleteImage: false
							}
						});
						if (!isFormTouched) {
							setFormTouched(true);
						}
					} else {
						setConfirmLoading(false);
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Image size is too large, please keep it below 10 MB",
								timeout: 3000,
								error: true
							}
						});
					}
				}
			};

			// handle error if uploaded file is not an image
			image.onerror = function () {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Please upload a valid image.",
						timeout: 3000,
						error: true
					}
				});
			};
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Upload an image from your current device",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleDeleteImage = (e) => {
		e.stopPropagation();
		setImage(undefined);
		dispatch({
			type: ActionTypes.UPDATE_CATEGORY_DETAIL,
			payload: {
				image: "",
				deleteImage: true
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp({ ...data }, "__typename");
		if (!validateData(sanitisedData)) {
			return;
		}
		const fieldsUpdated = findChangedKeysInTwoObjects(sanitisedData, backupData);
		const eventMeta = {
			fields_updated: fieldsUpdated,
			num_of_fields_updated: fieldsUpdated.length
		};

		if (sanitisedData.timingsGroup && sanitisedData.timingsGroup.id) {
			sanitisedData.timingsGroup = parseInt(sanitisedData.timingsGroup.id);
		}
		if (image) {
			sanitisedData.image = image;
		}
		if (!sanitisedData.deleteImage) {
			sanitisedData.deleteImage = false;
		}
		if (data.merchantRefId === "") {
			sanitisedData.merchantRefId = "-1";
		}

		const resp = await editCategory(sanitisedData, dispatch);
		if (resp) {
			eventMeta.status = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.CATEGORY_BASIC_INFO_UPDATE, eventMeta);

			if (fieldsUpdated.includes("parent")) {
				trackEvent(TRACKING_EVENT_NAMES.CATEGORY_SUBCATEGORY_UPDATE, {
					source: TRACKING_SOURCE.BASIC_INFORMATION_TAB,
					status: TRACKING_STATUS.SUCCESS
				});
			}

			setFormTouched(false);
		} else {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.CATEGORY_BASIC_INFO_UPDATE, eventMeta);

			if (fieldsUpdated.includes("parent")) {
				trackEvent(TRACKING_EVENT_NAMES.CATEGORY_SUBCATEGORY_UPDATE, {
					source: TRACKING_SOURCE.BASIC_INFORMATION_TAB,
					status: TRACKING_STATUS.FAILURE
				});
			}
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_CATEGORY_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const handleLanguage = async (lang, field) => {
		const resp = await fetchTranslation(
			parseInt(match.params.id),
			"CATEGORY",
			TRANSLATION_FIELDS_MAP[field],
			lang.value
		);
		if (resp) {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					lang: lang.value,
					value: resp.value,
					showActionBtn: false
				}
			});
		}
	};

	const handleTranslation = (field, value) => {
		if (currSelectedLang[field].lang === "en") {
			dispatch({
				type: ActionTypes.UPDATE_CATEGORY_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					...currSelectedLang[field],
					value: value,
					showActionBtn: true
				}
			});
		}
	};

	const saveTranslation = async (field, value) => {
		const resp = await updateTranslation(
			parseInt(match.params.id),
			"CATEGORY",
			TRANSLATION_FIELDS_MAP[field],
			currSelectedLang[field].lang,
			value
		);
		if (resp && !resp.status) {
			dispatch({
				type: ActionTypes.EDIT_CATEGORY_DETAIL_FAILURE,
				error: resp.error
			});
		} else if (resp && resp.status) {
			setTimeout(() => {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						...currSelectedLang[field],
						showActionBtn: false
					}
				});
				store.dispatch({
					type: "RESET_TRANSLATION"
				});
			}, 3000);
		}
	};

	const handleCloseRemoveCategoriesModal = (success) => {
		if (success) {
			fetchCategoryDetail(parseInt(match.params.id), dispatch);
		}
		setCategoriesRemoveModalState({});
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "categories"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const scrollDown = () => {
		setTimeout(() => {
			const formContainer = document.getElementsByClassName("form-container")[0];
			if (formContainer) {
				scroll({ top: formContainer.scrollHeight, left: 0 }, formContainer);
			}
		}, 275);
	};

	const canAssociateSubcategories =
		data?.subcategories?.length >= 0 && data?.paginatedItems?.count === 0 && !data.parent;
	const canAssociateItems = data?.subcategories?.length === 0 && data?.paginatedItems?.count >= 0;

	return (
		<div className="category-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				disabled={!isFormTouched}
				title={data.name || (isMenuOverCatalogueEnabled ? "Section" : "Category")}
				subTitle={`Edit this ${isMenuOverCatalogueEnabled ? "section" : "category"}`}
				submitTitle="Save"
				loading={loading || confirmLoading}
				showMoreBtn={formTab === FORM_TABS[0].value}
				numRows={3}
				hideActions={
					(formTab === FORM_TABS[0].value && !isFormTouched) ||
					formTab === FORM_TABS[1].value ||
					formTab === FORM_TABS[2].value
				}
				isNested={isNested}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && !readOnly && (
							<Button
								classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
								clickHandler={() => setArchiveRestore(true)}
							>
								{data.isActive ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={isMenuOverCatalogueEnabled ? [FORM_TABS[0]] : FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							image={image}
							handleImage={handleImage}
							handleDeleteImage={handleDeleteImage}
							showView={true}
							showDownload={true}
							showDelete={true}
							scrollDown={scrollDown}
							showLanguages={true}
							currSelectedLang={currSelectedLang}
							handleLanguage={handleLanguage}
							handleTranslation={handleTranslation}
							saveTranslation={saveTranslation}
							readOnly={!access.isCatalogueManagement || readOnly}
							isMenuOverCatalogueEnabled={isMenuOverCatalogueEnabled}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<AssociatedItems
							headerFields={CATEGORIES_EDIT_ITEMS_LIST}
							categoryId={match.params.id}
							classes="categories-edit-items-table-container"
							setModalBusy={setModalBusy}
							blockAssociation={data.subcategories && data.subcategories.length > 0 ? true : false}
							openNestedEntity={(e, type, id) => handleNestedEntity(true, type, id)}
							readOnly={!access.isCatalogueManagement || readOnly}
							setItemsCount={setItemsCount}
							canAssociateItems={canAssociateItems}
							fromEditSection
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<AssociatedSubCategories
							subcategories={data?.subcategories || []}
							readOnly={!access.isCatalogueManagement || readOnly}
							categoryId={data?.id}
							fetchCategoryDetail={fetchCategoryDetail}
							dispatch={dispatch}
							match={match}
							setCategoriesRemoveModalState={setCategoriesRemoveModalState}
							canAssociateSubcategories={canAssociateSubcategories}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[1]}
						entityName={data.name}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						sourceOfTrigger={TRACKING_SOURCE.DETAIL_VIEW}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={!access.isCatalogueManagement || readOnly}
					/>
				</div>
			</FormSidebar>
			<RemoveCategory
				isOpen={!!categoriesRemoveModal?.isOpen}
				close={handleCloseRemoveCategoriesModal}
				categoryData={categoriesRemoveModal?.data}
				categoryId={match.params.id}
			/>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	selectedCategory: store?.categoriesList?.selectedCategory,
	categoriesList: store?.menuCategoriesList,
	brands: store.configItems.brands,
	biz: store.login.loggedInbizDetail
}))(CategoryEdit);

const RemoveCategory = ({ isOpen, close, categoryData = [], categoryId }) => {
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [respState, setRespState] = useState("");

	const handleClose = () => {
		if (respState === "success") {
			close(true);
			return;
		}
		close();
	};

	const handleDeleteCategory = async () => {
		try {
			setIsLoading(true);
			const variables = {
				parentCategoryId: parseInt(categoryId),
				subCategoryIdsToAssociate: [],
				subCategoryIdsToDisassociate: categoryData.map((category) => parseInt(category?.id))
			};
			const resp = await client.mutate({
				mutation: ASSOCIATE_DISOCIATE_SUBCATEGORIES,
				variables
			});
			if (resp?.data?.updateSubcategoryAssociation?.status?.success) {
				setRespState("success");
			} else {
				setRespState("failure");
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			setRespState("failure");
		}
	};

	const isMulti = categoryData.length > 1;

	return (
		<Modal
			isOpen={isOpen}
			classes="remove-categories-modal"
			title="Remove Location?"
			showCancelAction
			cancelTitle="Close"
			disabled={!isChecked}
			close={handleClose}
			hideActions={isLoading}
			showDeleteAction={respState !== "success"}
			deleteAction={handleDeleteCategory}
			deleteTitle="Remove"
		>
			{isLoading ? (
				<Loading />
			) : respState !== "" ? (
				<div className="remove-category-details">
					<div className={`end-result ${respState}`}>
						{respState === "success"
							? `Successfully removed ${isMulti ? "categories" : "category"}`
							: `Failed to remove ${isMulti ? "categories" : "category"}`}
					</div>
				</div>
			) : (
				<div className="remove-category-details">
					<div className="category-info">
						Selected {isMulti ? "categories are" : "category is"} associated with{" "}
						{categoryData.reduce((acc, curr) => acc + (curr?.paginatedItems?.count || 0), 0)} item(s).
					</div>
					<div className="warning">
						If you remove {isMulti ? "these categories, they" : "this category, it"} will not be a part of
						your customer-facing catalogue on your apps and website.
					</div>
					<div className="confirm-process">
						<CheckBox
							checked={isChecked}
							clickHandler={() => {
								setIsChecked((current) => !current);
							}}
						/>
						Proceed with removing {isMulti ? "categories" : "category"}
					</div>
				</div>
			)}
		</Modal>
	);
};
