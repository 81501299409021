import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { CheckBox } from "../_commons/CheckBox";
import { Switch } from "../_commons/Switch";
import { Textarea } from "../_commons/Textarea";

export const Parameters = ({
	index,
	parameter = {},
	paramValue = {},
	handleForm,
	validations = {},
	useCheckbox = false,
	useSwitch = false
}) => {
	const capitalise = (val) => {
		val = String(val).charAt(0).toUpperCase() + String(val).slice(1);
		return val;
	};
	return (
		<React.Fragment>
			{parameter.inputType === "list" && (
				<SelectFilter
					title={parameter.uiText}
					options={parameter.choices}
					field={parameter.name}
					currValue={paramValue ? paramValue.paramValue : null}
					setFilter={(field, value) => handleForm(field, value, index)}
					labelKey="valueForDisplay"
					valueKey="value"
					requiredLabel={parameter.required}
					multi={parameter.multiple}
					validationMessage={validations[`actions.parameters.${parameter.name}`] || ""}
					showCustomTooltip={parameter.name === "PA_COUPON_CATEGORIES_LIST"}
					tooltipInfo="Overwrites included/excluded items and item options"
					order={parameter.multiple}
				/>
			)}
			{useCheckbox && parameter.inputType === "boolean" && (
				<CheckBox
					checked={paramValue ? String(paramValue.paramValue).toLowerCase() === "true" : false}
					clickHandler={() =>
						handleForm(
							parameter.name,
							capitalise(!(String(paramValue.paramValue).toLowerCase() === "true")),
							index
						)
					}
				>
					{parameter.uiText}
				</CheckBox>
			)}
			{useSwitch && parameter.inputType === "boolean" && (
				<Switch
					title={parameter.uiText}
					checked={paramValue ? String(paramValue.paramValue).toLowerCase() === "true" : false}
					clickHandler={() =>
						handleForm(
							parameter.name,
							capitalise(!(String(paramValue.paramValue).toLowerCase() === "true")),
							index
						)
					}
					requiredLabel={parameter.required}
					validationMessage={validations[`actions.parameters.${parameter.name}`] || ""}
				/>
			)}
			{parameter.name === "PA_ERR_MSG" && (
				<Textarea
					value={paramValue ? paramValue.paramValue : ""}
					onChange={(e) => handleForm(parameter.name, e.target.value, index)}
					requiredLabel={parameter.required}
				>
					{parameter.uiText}
				</Textarea>
			)}
			{parameter.inputType === "string" && parameter.name !== "PA_ERR_MSG" && (
				<InputWithLabel
					value={paramValue ? paramValue.paramValue : ""}
					requiredLabel={parameter.required}
					onChange={(e) => handleForm(parameter.name, e.target.value, index)}
					validationMessage={validations[`actions.parameters.${parameter.name}`] || ""}
					placeholder={parameter.description || ""}
				>
					{parameter.uiText}
				</InputWithLabel>
			)}
			{parameter.inputType === "int" && (
				<InputWithLabel
					type="number"
					value={paramValue ? paramValue.paramValue : ""}
					requiredLabel={parameter.required}
					onChange={(e) =>
						handleForm(parameter.name, e.target.value ? parseInt(e.target.value) : null, index)
					}
					validationMessage={validations[`actions.parameters.${parameter.name}`] || ""}
				>
					{parameter.uiText}
				</InputWithLabel>
			)}
			{parameter.inputType === "decimal" && (
				<InputWithLabel
					type="number"
					value={paramValue ? paramValue.paramValue : ""}
					requiredLabel={parameter.required}
					onChange={(e) => handleForm(parameter.name, e.target.value ? Number(e.target.value) : null, index)}
					validationMessage={validations[`actions.parameters.${parameter.name}`] || ""}
				>
					{parameter.uiText}
				</InputWithLabel>
			)}
		</React.Fragment>
	);
};
