// action types
import { ActionTypes } from "../actions/_types";

// utils
import { getSortOrder } from "../atlas-utils";

const ITEM_TAGS_INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "title",
		order: "ASC"
	},
	sortedField: "title",
	currentFilters: {},
	appliedFilters: {}
};
export const itemTagsListState = (state = ITEM_TAGS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.ITEM_TAGS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.ITEM_TAGS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.ITEM_TAGS_LIST_STATE_RESET:
			return ITEM_TAGS_INITIAL_STATE;
		default:
			return state;
	}
};

const ITEM_TAGS_LIST_INITIAL_STATE = {
	loading: false,
	objects: [],
	filters: [],
	count: 0,
	error: false
};
export const itemTagsList = (state = ITEM_TAGS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_ITEM_TAGS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_ITEM_TAGS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				...action.payload
			};
		case ActionTypes.GET_ITEM_TAGS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_ITEM_TAGS_LIST:
			let foundInList = false;
			const updatedItemTags = state.objects.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedItemTags.unshift(action.payload);
			}
			return {
				...state,
				objects: updatedItemTags,
				count: state.count + 1
			};
		case ActionTypes.RESET_ITEM_TAGS_LIST:
			return ITEM_TAGS_LIST_INITIAL_STATE;
		default:
			return state;
	}
};

export const ITEM_TAG_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	backupData: {},
	error: {}
};
export const itemTagDetailsReducer = (state = ITEM_TAG_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_ITEM_TAG_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_ITEM_TAG_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				backupData: action.payload
			};
		case ActionTypes.GET_ITEM_TAG_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_ITEM_TAG_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_ITEM_TAG_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_ITEM_TAG_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				backupData: state.data
			};
		case ActionTypes.EDIT_ITEM_TAG_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

const TAG_GROUPS_INITIAL_STATE = {
	limit: 50,
	offset: 0,
	sort: {
		field: "title",
		order: "ASC"
	},
	sortedField: "title",
	currentFilters: {},
	appliedFilters: {}
};
export const tagGroupsListState = (state = TAG_GROUPS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.TAG_GROUPS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.TAG_GROUPS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.TAG_GROUPS_LIST_STATE_RESET:
			return TAG_GROUPS_INITIAL_STATE;
		default:
			return state;
	}
};

const TAG_GROUPS_LIST_INITIAL_STATE = {
	loading: true,
	tagGroups: [],
	error: false,
	selectedTagGroup: "",
	formTab: "active"
};
export const tagGroupsList = (state = TAG_GROUPS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_TAG_GROUPS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_TAG_GROUPS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				...action.payload
			};
		case ActionTypes.GET_TAG_GROUPS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_TAG_GROUPS_LIST:
			let foundInList = false;
			const updatedTagGroups = state.tagGroups.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedTagGroups.unshift(action.payload);
			}
			return {
				...state,
				tagGroups: updatedTagGroups,
				selectedTagGroup: action.payload,
				formTab: action.payload.isEnabled ? "active" : "archived"
			};
		case ActionTypes.UPDATE_TAG_GROUPS_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const TAG_GROUP_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	backupData: {},
	error: {}
};
export const tagGroupDetailsReducer = (state = TAG_GROUP_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_TAG_GROUP_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_TAG_GROUP_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				backupData: action.payload
			};
		case ActionTypes.GET_TAG_GROUP_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_TAG_GROUP_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_TAG_GROUP_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_TAG_GROUP_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				backupData: state.data
			};
		case ActionTypes.EDIT_TAG_GROUP_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
