import React from "react";

// component
import { CheckBox } from "../_commons/CheckBox";
import { Button } from "../_commons/Button";

// configs
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";

const AssociatedPlatforms = ({
	platforms = [],
	handleItemPlatformAssociation,
	itemPlatformsAssociation,
	readOnly = true
}) => {
	const directChannels = platforms.find((pf) => pf.platformName === "Urbanpiper");
	let isDCAssociated = false;
	if (directChannels) {
		if (itemPlatformsAssociation[directChannels.id] === undefined) {
			isDCAssociated = directChannels.isItemAssociated;
		} else {
			isDCAssociated = itemPlatformsAssociation[directChannels.id];
		}
	}
	platforms = platforms.filter((pf) => pf.platformName !== "Urbanpiper");

	return (
		<div className="associated-platforms-tab">
			<div className="channel-type">
				<span>Meraki Channels</span>
				<span
					aria-label="Select whether the item is available on your Meraki apps and websites"
					data-balloon-pos="right"
				>
					<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
				</span>
			</div>
			<div className="web-channel-associations-container">
				<div className="channel-type-container direct-channels">
					<div className="channel-row">
						<img className="channel-icon" src="/assets/icons/platform-apps.svg" />
						<div className="channel-label">App</div>
					</div>
					<div className="channel-row">
						<img className="channel-icon" src="/assets/icons/platform-web.svg" />
						<div className="channel-label">Web</div>
					</div>
				</div>
				{directChannels ? (
					<CheckBox
						checked={isDCAssociated}
						clickHandler={() => handleItemPlatformAssociation(directChannels.id, !isDCAssociated)}
						readOnly={readOnly}
					>
						Associated
					</CheckBox>
				) : (
					<div className="meraki-info">
						<span>Take control of your online business with self-branded apps & websites</span>
						<a href="https://www.urbanpiper.com/contact" target="_blank">
							<Button>Try Free Demo</Button>
						</a>
					</div>
				)}
			</div>
			<div className="channel-type">
				<span>Hub Platforms</span>
				<span
					aria-label="Select whether the item is available on following aggregator platforms under Hub"
					data-balloon-pos="right"
				>
					<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
				</span>
			</div>
			<div className="channel-type-container aggregator-channels">
				{platforms.length === 0 && <div className="no-items-placeholder">No aggregator platforms found</div>}
				{platforms.length > 0 &&
					platforms.map((plat, i) => {
						const isAssociated =
							itemPlatformsAssociation[plat.id] === undefined
								? plat.isItemAssociated
								: itemPlatformsAssociation[plat.id];
						return (
							<div key={i} className="aggregator-channel-row">
								<div className="channel-row">
									<img
										className="aggregator-icon"
										src={
											plat?.logo ||
											CATALOGUE_PLATFORMS_LOGO[plat.platformName.toLowerCase()] ||
											"/assets/icons/icons8-globe-40.png"
										}
									/>
									<div className="aggregator-label">{plat.platformName}</div>
								</div>
								<CheckBox
									checked={isAssociated}
									clickHandler={() => handleItemPlatformAssociation(plat.id, !isAssociated)}
									readOnly={readOnly}
								>
									Associated
								</CheckBox>
							</div>
						);
					})}
			</div>
		</div>
	);
};
export default AssociatedPlatforms;
