// action types
import { ActionTypes } from "../actions/_types";

// utils
import { lS, removeProp } from "../atlas-utils";
//import NotificationServices from "../services/NotificationService";

// client
import { store } from "../store/configureStore";

const MENUS_LIST_INIT_STATE = {
	isLoading: true,
	menusListData: [],
	isActive: true,
	selectedBrand: lS.get("brand") ? lS.get("brand") : null
};

export const menusListState = (state = MENUS_LIST_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENUS_LIST_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.GET_MENUS_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				menusListData: action.payload
			};

		case ActionTypes.GET_MENUS_LIST_FAILURE:
			return {
				...state,
				isLoading: false
			};

		case ActionTypes.UPDATE_MENUS_LIST_DATA:
			return {
				...state,
				[action.payload.key]: action.payload.value
			};
		default:
			return state;
	}
};

const MENU_DETAILS_STATE = {
	isLoading: true,
	isGlobalLoading: false,
	menuDetailsData: {
		brand: "",
		categories: [],
		createdAt: "",
		description: "",
		imageUrl: "",
		itemCount: 0,
		items: [],
		ocationCount: 0,
		name: "",
		optionGroups: [],
		options: [],
		status: "",
		updatedAt: "",
		metadata: {
			lockStatus: "INACTIVE",
			pendingChangesCount: 0
		}
	}
};

export const menuDetailsState = (state = MENU_DETAILS_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENU_DETAILS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.GET_MENU_DETAILS_SUCCESS:
			return {
				...state,
				isLoading: false,
				menuDetailsData: { ...action.payload }
			};

		case ActionTypes.GET_MENU_DETAILS_FAILURE:
			return {
				...state,
				isLoading: false
			};

		case ActionTypes.UPDATE_MENU_DETAILS_DATA:
			return {
				...state,
				[action.payload.key]: action.payload.value
			};

		case ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE:
			return {
				...state,
				menuDetailsData: { ...removeProp(action.payload, "__typename") }
			};

		case ActionTypes.HANDLE_DESTRUCTIVE_SAVE_REQUEST:
			return {
				...state,
				isGlobalLoading: true
			};

		case ActionTypes.HANDLE_DESTRUCTIVE_SAVE_SUCCESS:
			return {
				...state,
				isGlobalLoading: false,
				menuDetailsData: { ...removeProp(action.payload, "__typename") }
			};

		case ActionTypes.HANDLE_DESTRUCTIVE_SAVE_FAILURE:
			return {
				...state,
				isGlobalLoading: false
			};

		case ActionTypes.UPDATE_META_DATA:
			return {
				...state,
				menuDetailsData: {
					...state.menuDetailsData,
					metadata: {
						...(state?.menuDetailsData?.metadata ?? {}),
						lockStatus: action.payload.lockStatus,
						pendingChangesCount: action.payload.pendingChangesCount
					}
				}
			};
		default:
			return state;
	}
};

const PARENT_SECTIONS_STATE = {
	isLoading: true,
	parentSectionDetailsData: {},
	selectedParentSection: null
};

export const parentSectionsState = (state = PARENT_SECTIONS_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_PARENT_SECTIONS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.GET_PARENT_SECTIONS_SUCCESS:
			let subCategories = {};
			(action.payload?.objects ?? []).forEach((parentCategory) => {
				parentCategory.subCategories.forEach((subCategory) => {
					subCategories[subCategory?.id] = true;
				});
			});
			const restructuredParentSections = (action.payload?.objects ?? []).filter(
				(section) => !subCategories[section?.id]
			);
			const previouslyChosenParentSection = restructuredParentSections.find(
				(parentSection) => parentSection?.id === store.getState().parentSectionsState.selectedParentSection
			);
			return {
				...state,
				isLoading: false,
				parentSectionDetailsData: action.payload,
				selectedParentSection: previouslyChosenParentSection
					? previouslyChosenParentSection?.id
					: restructuredParentSections?.[0]?.id || null
			};

		case ActionTypes.GET_PARENT_SECTIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				parentSectionDetailsData: {}
			};

		case ActionTypes.UPDATE_SELECTED_PARENT_SECTION:
			return {
				...state,
				selectedParentSection: action.payload
			};

		case ActionTypes.RESET_PARENT_SECTIONS_STATE:
			return {
				...PARENT_SECTIONS_STATE
			};

		default:
			return state;
	}
};

const PUBLISHED_VERSIONS_LIST_STATE = {
	isLoading: true,
	publishHistoryData: {},
	selectedPublishedVersion: null,
	selectedPublishedVersionDetails: {}
};

export const publishHistoryListsData = (state = PUBLISHED_VERSIONS_LIST_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_PUBLISHED_VERSIONS_LIST_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.GET_PUBLISHED_VERSIONS_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				publishHistoryData: action.payload
			};

		case ActionTypes.GET_PUBLISHED_VERSIONS_LIST_FAILURE:
			return {
				...state,
				isLoading: false
			};

		case ActionTypes.UPDATE_PUBLISHED_VERSIONS_STATE:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};

const MENU_PREVIEW_PLATFORMS_LOCATIONS_LIST_STATE = {
	isLocationsLoading: false,
	isPlatformsLoading: false,
	locationsData: {
		count: 0,
		objects: [],
		filters: []
	},
	platformsData: {},
	selectedPlatform: null,
	selectedLocation: null
};

export const menuPreviewPlatformsLocationsList = (state = MENU_PREVIEW_PLATFORMS_LOCATIONS_LIST_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENU_PREVIEW_LOCATIONS_REQUEST:
			return {
				...state,
				isLocationsLoading: true
			};

		case ActionTypes.GET_MENU_PREVIEW_LOCATIONS_SUCCESS:
			return {
				...state,
				isLocationsLoading: false,
				locationsData: {
					...action.payload
				}
			};

		case ActionTypes.GET_MENU_PREVIEW_LOCATIONS_FAILURE:
			return {
				...state,
				isLocationsLoading: false
			};

		case ActionTypes.GET_MENU_PREVIEW_PLATFORMS_REQUEST:
			return {
				...state,
				isPlatformsLoading: true
			};

		case ActionTypes.GET_MENU_PREVIEW_PLATFORMS_SUCCESS:
			return {
				...state,
				isPlatformsLoading: false,
				platformsData: {
					...action.payload
				}
			};

		case ActionTypes.GET_MENU_PREVIEW_PLATFORMS_FAILURE:
			return {
				...state,
				isPlatformsLoading: false
			};

		case ActionTypes.UPDATE_MENU_PREVIEW_LOCATION:
			return {
				...state,
				selectedLocation: action.payload
			};

		case ActionTypes.UPDATE_MENU_PREVIEW_PLATFORM:
			return {
				...state,
				selectedPlatform: action.payload
			};

		default:
			return state;
	}
};

const MENU_PENDING_CHANGES_WARNING_STATES = {
	targetPath: "",
	isPendingChangesWarningModalOpen: false
};

export const menuPendingChangesWarningStates = (state = MENU_PENDING_CHANGES_WARNING_STATES, action) => {
	switch (action.type) {
		case ActionTypes.MENU_PENDING_CHANGES_MODAL_STATE:
			return {
				...state,
				isPendingChangesWarningModalOpen: action.payload
			};

		case ActionTypes.MENU_PENDING_CHANGES_TARGET_PATH_UPDATE:
			return {
				...state,
				targetPath: action.payload
			};

		default:
			return state;
	}
};

const MENU_PREVIEW_STATES = {
	isLoading: false,
	menuPreviewData: {},
	selectedParentSection: null
};

export const menuPreviewStates = (state = MENU_PREVIEW_STATES, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENU_PREVIEW_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.GET_MENU_PREVIEW_SUCCESS:
			let subCategories = {};
			(action.payload?.objects ?? []).forEach((parentCategory) => {
				parentCategory.subCategories.forEach((subCategory) => {
					subCategories[subCategory?.id] = true;
				});
			});
			const restructuredParentSections = (action.payload?.objects ?? []).filter(
				(section) => !subCategories[section?.id]
			);
			const previouslyChosenParentSection = restructuredParentSections.find(
				(parentSection) => parentSection?.id === store.getState().menuPreviewStates.selectedParentSection
			);

			return {
				...state,
				isLoading: false,
				menuPreviewData: action.payload,
				selectedParentSection: previouslyChosenParentSection
					? previouslyChosenParentSection?.id
					: restructuredParentSections?.[0]?.id || null
			};

		case ActionTypes.GET_MENU_PREVIEW_FAILURE:
			return {
				...state,
				isLoading: false
			};

		case ActionTypes.UPDATE_MENU_PREVIEW_SELECTED_SECTION:
			return {
				...state,
				selectedParentSection: action.payload
			};

		default:
			return state;
	}
};

const MENU_PREVIEW_VALIDATION_STATUS = {
	isLoading: false,
	menuValidationStatusData: {},
	totalErrors: 0,
	totalWarnings: 0,
	status: "",
	menuId: null
};

export const menuPreviewValidationStatus = (state = MENU_PREVIEW_VALIDATION_STATUS, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENU_PREVIEW_VALIDATION_STATUS_REQUEST:
			return {
				...state,
				isLoading: true,
				menuId: action?.payload?.menuId
			};

		case ActionTypes.GET_MENU_PREVIEW_VALIDATION_STATUS_SUCCESS:
			let totalErrorsInMenu = 0;
			let totalWarningsInMenu = 0;
			if (action.payload && action.payload.report) {
				action.payload.report.forEach((reportItem) => {
					if (reportItem?.errors && reportItem?.errors?.length) {
						totalErrorsInMenu += reportItem.errors.length;
					}
					if (reportItem.warnings && reportItem?.warnings?.length) {
						totalWarningsInMenu += reportItem.warnings.length;
					}
				});
			}
			const resultArray = [];
			if (action.payload && action.payload.report) {
				action.payload.report.forEach((reportItem) => {
					const item = {
						itemName: reportItem.entityName,
						itemType: reportItem.entityType,
						itemId: reportItem.entityId
					};

					if (reportItem.errors && reportItem.errors.length > 0) {
						reportItem.errors.forEach((error) => {
							resultArray.push({
								item: {
									...item,
									errorMsg: error.message
								},
								issue: {
									type: "ERROR",
									message: error.message
								},
								platforms: error.platforms
							});
						});
					}

					if (reportItem.warnings && reportItem.warnings.length > 0) {
						reportItem.warnings.forEach((warning) => {
							resultArray.push({
								item: {
									...item,
									warningMsg: warning.message
								},
								issue: {
									type: "WARNING",
									message: warning.message
								},
								platforms: warning.platforms
							});
						});
					}
				});
			}
			return {
				...state,
				isLoading: false,
				menuValidationStatusData: action.payload,
				itemValidationDataStructured: resultArray,
				totalErrors: totalErrorsInMenu,
				totalWarnings: totalWarningsInMenu,
				status: action.payload.status,
				menuId: action.payload.menuId
			};

		case ActionTypes.GET_MENU_PREVIEW_VALIDATION_STATUS_FAILURE:
			return {
				...state,
				isLoading: false,
				menuValidationStatusData: {},
				totalErrors: 0,
				totalWarnings: 0,
				status: ""
			};
		case ActionTypes.RESET_MENU_PREVIEW_VALIDATION_STATUS:
			return {
				...state,
				isLoading: false,
				status: ""
			};
		default:
			return state;
	}
};

const MENU_PUBLISH_VALIDATION_STATUS = {
	isLoading: false,
	menuPublishValidationStatusData: {},
	totalErrors: 0,
	totalWarnings: 0,
	isMenuTriggeredAleastOnce: false,
	status: "",
	menuId: null
};
export const menuPublishValidationStatus = (state = MENU_PUBLISH_VALIDATION_STATUS, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENU_PUBLISH_VALIDATION_STATUS_REQUEST:
			return {
				...state,
				isLoading: true,
				isMenuTriggeredAleastOnce: true,
				menuId: action?.payload?.menuId
			};

		case ActionTypes.GET_MENU_PUBLISH_VALIDATION_STATUS_SUCCESS:
			let totalErrorsInMenu = 0;
			let totalWarningsInMenu = 0;
			if (action.payload && action.payload.report) {
				action.payload.report.forEach((reportItem) => {
					if (reportItem?.errors && reportItem?.errors?.length) {
						totalErrorsInMenu += reportItem.errors.length;
					}
					if (reportItem.warnings && reportItem?.warnings?.length) {
						totalWarningsInMenu += reportItem.warnings.length;
					}
				});
			}
			const resultArray = [];
			if (action.payload && action.payload.report) {
				action.payload.report.forEach((reportItem) => {
					const item = {
						itemName: reportItem.entityName,
						itemType: reportItem.entityType,
						itemId: reportItem.entityId
					};

					if (reportItem.errors && reportItem.errors.length > 0) {
						reportItem.errors.forEach((error) => {
							resultArray.push({
								item: {
									...item,
									errorMsg: error.message
								},
								issue: {
									type: "ERROR",
									message: error.message
								},
								platforms: error.platforms
							});
						});
					}

					if (reportItem.warnings && reportItem.warnings.length > 0) {
						reportItem.warnings.forEach((warning) => {
							resultArray.push({
								item: {
									...item,
									warningMsg: warning.message
								},
								issue: {
									type: "WARNING",
									message: warning.message
								},
								platforms: warning.platforms
							});
						});
					}
				});
			}
			return {
				...state,
				isLoading: false,
				menuPublishValidationStatusData: action.payload,
				itemValidationDataStructured: resultArray,
				totalErrors: totalErrorsInMenu,
				totalWarnings: totalWarningsInMenu,
				status: action.payload.status,
				menuId: action.payload.menuId
			};

		case ActionTypes.GET_MENU_PUBLISH_VALIDATION_STATUS_FAILURE:
			return {
				...state,
				isLoading: false,
				menuPublishValidationStatusData: {},
				itemValidationDataStructured: [],
				totalErrors: 0,
				totalWarnings: 0,
				isMenuTriggeredAleastOnce: false,
				status: ""
			};
		case ActionTypes.RESET_MENU_PUBLISH_VALIDATION_STATUS:
			return {
				...state,
				isLoading: false,
				status: ""
			};

		default:
			return state;
	}
};

const MENU_CATEGORIES_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: [],
		searchFieldValue: ""
	},
	error: false
};

export const menuCategoriesList = (state = MENU_CATEGORIES_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENU_CATEGORIES_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_MENU_CATEGORIES_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.GET_MENU_CATEGORIES_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

const MENU_TEST_CART = {
	loading: false,
	data: {},
	error: null
};

export const menuTestCart = (state = MENU_TEST_CART, action) => {
	switch (action.type) {
		case ActionTypes.CREATE_TEST_CART:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.RESET_TEST_CART:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
					cartId: null,
					cartObject: {}
				},
				loading: false
			};
		case ActionTypes.PLACE_TEST_ORDER:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};

		case ActionTypes.ADD_ITEMS_TEST_CART:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.UPDATE_ITEMS_TEST_CART:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.REMOVE_ITEMS_TEST_CART:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		default:
			return state;
	}
};
