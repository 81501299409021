// constants
import { CUSTOMERS_LIST_SEARCH_FIELDS } from "../client-config";
import { ActionTypes } from "../actions/_types";
import { PRESET_TYPES, DATE_TYPES, CUSTOM_TYPES } from "../components/_commons/NewDateCompareFilter";

const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[0].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[0],
		customTypeSelected: CUSTOM_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: { label: "Not specified", value: "not_specified" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "date_joined",
		order: "DESC"
	},
	sortedField: "date_joined",
	searchKW: "",
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE,
	dateTypeSelected: { label: "Preset", value: "preset_duration" },
	presetTypeSelected: { label: "Today", value: "TODAY" },
	customTypeSelected: { label: "Is between", value: "between" },
	rangeStartDate: undefined,
	rangeEndDate: undefined,
	currentFilters: {},
	appliedFilters: {},
	searchFieldSelected: CUSTOMERS_LIST_SEARCH_FIELDS[0]
};
export const customersListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "CUSTOMERS_LIST_STATE_CHANGE":
			return {
				...state,
				...action.payload
			};
		case "CUSTOMERS_LIST_STATE_CHANGE_SORT":
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case "RESET_CUSTOMERS_LIST_STATE":
			return INITIAL_STATE;
		default:
			return state;
	}
};

const getSortOrder = (currSort, newSort) => {
	return currSort.order === "" || currSort.field !== newSort.field
		? "DESC"
		: currSort.order === "ASC"
		? "DESC"
		: "ASC";
};

const CUSTOMERS_LIST_INITIAL_STATE = {
	loading: true,
	data: {
		objects: [],
		filters: []
	},
	error: false
};
export const customersList = (state = CUSTOMERS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case "GET_CUSTOMERS_LIST_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "GET_CUSTOMERS_LIST_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "GET_CUSTOMERS_LIST_FAILURE":
			return {
				...state,
				loading: false,
				error: action.error
			};
		case "RESET_CUSTOMERS_LIST":
			return CUSTOMERS_LIST_INITIAL_STATE;
		default:
			return state;
	}
};

export const customerDetails = (
	state = {
		loading: false,
		data: null,
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_CUSTOMER_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
				error: false
			};
		case ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case ActionTypes.GET_CUSTOMER_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST:
			return {
				...state,
				data: action.payload,
				loading: true,
				error: null
			};
		case ActionTypes.UPDATE_CUSTOMER_DETAILS_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case ActionTypes.UPDATE_CUSTOMER_DETAILS_FAILURE:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		default:
			return state;
	}
};

export const customerFeedbacks = (
	state = {
		loading: false,
		limit: 5,
		offset: 0,
		sort: {
			field: "created",
			order: "DESC"
		},
		data: {
			count: 0,
			objects: []
		}
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_CUSTOMER_FEEDBACKS_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.GET_CUSTOMER_FEEDBACKS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_CUSTOMER_FEEDBACKS_FAILURE:
			return {
				...state,
				loading: false
			};
		case ActionTypes.SORT_CUSTOMER_FEEDBACKS:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				}
			};
		case ActionTypes.UPDATE_CUSTOMER_FEEDBACKS_LIST:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const customerNotes = (
	state = {
		loading: false,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_CUSTOMER_NOTES_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CUSTOMER_NOTES_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_CUSTOMER_NOTES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CUSTOMER_NOTES_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.UPDATE_CUSTOMER_NOTES_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.UPDATE_CUSTOMER_NOTES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const customerTimeline = (
	state = {
		loading: false,
		limit: 5,
		offset: 0,
		data: {
			count: 0,
			objects: []
		},
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.UPDATE_CUSTOMER_TIMELINE_LIST_STATE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.GET_CUSTOMER_TIMELINE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CUSTOMER_TIMELINE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_CUSTOMER_TIMELINE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
