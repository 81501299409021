import React, { useRef, useEffect, useState } from "react";

// constants
import { DEFAULT_LOCATION } from "../../client-config";
const GMAP_KEY = process.env.REACT_APP_GMAP_KEY;

export const CaptureLocation = ({
	setLocation,
	latLong = {},
	setLocationDetails = () => {},
	fromLocationCreateWizard = false,
	height = "250px"
}) => {
	const [isVisible, setVisible] = useState(false);
	const gmap = useRef();
	const marker = useRef();
	const gmapRef = useRef();

	useEffect(() => {
		setTimeout(() => {
			setVisible(true);
		}, 2000);
	}, []);

	const createGoogleMap = () => {
		gmap.current = new window.google.maps.Map(gmapRef.current, {
			zoom: 16,
			center: latLong.lat && latLong.lng ? latLong : DEFAULT_LOCATION,
			disableDefaultUI: true,
			zoomControl: true,
			fullscreenControl: true
		});
	};

	// This event handler takes care of missing out search suggestions
	document.onfullscreenchange = function (event) {
		let target = event.target;
		let pacContainerElements = document.getElementsByClassName("pac-container");
		if (pacContainerElements.length > 0) {
			let pacContainer = document.getElementsByClassName("pac-container")[0];
			if (pacContainer.parentElement === target) {
				document.getElementsByTagName("body")[0].appendChild(pacContainer);
			} else {
				target.appendChild(pacContainer);
			}
		}
	};

	const createMarker = () => {
		marker.current = new window.google.maps.Marker({
			position: fromLocationCreateWizard
				? latLong.lat && latLong.lng
					? latLong
					: null
				: latLong.lat && latLong.lng
				? latLong
				: DEFAULT_LOCATION,
			map: gmap.current
		});
	};

	const initializeMap = () => {
		createGoogleMap();
		createMarker();
		gmap.current.addListener("click", (mapsMouseEvent) => {
			const latLongNew = mapsMouseEvent.latLng.toJSON();
			marker.current.setPosition(latLongNew);
			marker.current.setVisible(true);
			setLocation(latLongNew);
		});

		const input = document.getElementById("pac-input");
		gmap.current.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
		const autocomplete = new window.google.maps.places.Autocomplete(input);
		// Bind the map's bounds (viewport) property to the autocomplete object,
		// so that the autocomplete requests use the current map bounds for the
		// bounds option in the request.
		autocomplete.bindTo("bounds", gmap.current);
		// Set the data fields to return when the user selects a place.
		autocomplete.setFields(["address_components", "geometry", "icon", "name"]);

		autocomplete.addListener("place_changed", () => {
			marker.current.setVisible(false);
			const place = autocomplete.getPlace();
			if (!place.geometry) {
				// User entered the name of a Place that was not suggested and
				// pressed the Enter key, or the Place Details request failed.
				window.alert("No details available for input: '" + place.name + "'");
				return;
			}
			const { address_components } = place;
			let placesAvailableForPrinting = address_components?.length > 2;
			setLocationDetails(
				placesAvailableForPrinting
					? {
							locationName: place.name,
							city: `${address_components[0].short_name}, ${address_components[1].short_name}`
					  }
					: { locationName: "", city: "" }
			);
			if (place.geometry.viewport) {
				gmap.current.fitBounds(place.geometry.viewport);
			} else {
				gmap.current.setCenter(place.geometry.location);
			}
			marker.current.setPosition(place.geometry.location);
			marker.current.setVisible(true);
			setLocation({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng()
			});
		});
	};

	useEffect(() => {
		if (!window.google) {
			const gMapScript = document.createElement("script");
			gMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GMAP_KEY}&libraries=places,drawing`;
			window.document.body.appendChild(gMapScript);
			gMapScript.addEventListener("load", () => {
				initializeMap();
			});
		} else {
			initializeMap();
		}
	}, []);

	return (
		<div className="capture-location">
			<div className="gmap-container">
				<input
					style={{ visibility: isVisible ? "visible" : "hidden" }}
					id="pac-input"
					className="controls"
					type="text"
					placeholder="Search location"
				/>
				<div
					ref={gmapRef}
					style={{
						width: "100%",
						height: height
					}}
				/>
			</div>
		</div>
	);
};
