import gql from "graphql-tag";

export const GET_BRANDS_LIST = gql`
	query getBrandsList(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		brands(limit: $limit, offset: $offset, sort: $sort, filters: $filters, search: $search) {
			limit
			offset
			count
			hasNext
			hasPrevious
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
			objects {
				id
				name
				isActive
				brandLocationsCount
				brandItemsCount
				image
				associatedLocations {
					isAssociated
					city
				}
			}
		}
	}
`;

export const CREATE_BRAND = gql`
	mutation createBrand(
		$name: String!
		$locationIds: [Int]
		$image: Upload
		$base64Image: String
		$escPosImage: String
		$starLineImage: String
	) {
		saveBrand(
			input: {
				name: $name
				locationIds: $locationIds
				base64Image: $base64Image
				escPosImage: $escPosImage
				starLineImage: $starLineImage
			}
			image: $image
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			referenceId
			object {
				id
				name
			}
		}
	}
`;

export const GET_ASSOCIATED_BRAND_LOCATIONS = gql`
	query associatedLocations(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		stores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			limit
			offset
			objects {
				id
				merchantRefId
				name
				address
				city
			}
		}
	}
`;

export const SAVE_BRAND = gql`
	mutation saveBrand(
		$name: String!
		$id: Int
		$image: Upload
		$base64Image: String
		$escPosImage: String
		$starLineImage: String
	) {
		saveBrand(
			input: {
				name: $name
				id: $id
				base64Image: $base64Image
				escPosImage: $escPosImage
				starLineImage: $starLineImage
			}
			image: $image
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				name
			}
		}
	}
`;

export const GET_BRAND_DETAILS = gql`
	query getBrand($id: Int!) {
		brand(id: $id) {
			name
			image
			id
		}
	}
`;

export const GET_ASSOCIATED_BRAND_SPECIFIC_LOCATIONS = gql`
	query associatedLocations(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$brand: String
	) {
		stores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search, brand: $brand) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			limit
			offset
			objects {
				id
				name
				city
				ilaCount
				associatedPlatforms {
					platformName
					id
					state
					platform {
						logo
						featureFlags {
							name
							value
						}
					}
				}
				locationPlatforms {
					platformName
					id
					state
					logo
				}
			}
		}
	}
`;

export const GET_ASSOCIATED_BRAND_SPECIFIC_LOCATIONS_NAME = gql`
	query associatedLocations(
		$limit: Int
		$offset: Int
		$search: [SearchArgument]
		$filters: [ListFilterArgument]
		$brand: String
	) {
		stores(limit: $limit, offset: $offset, search: $search, filters: $filters, brand: $brand) {
			count
			limit
			offset
			objects {
				id
				name
			}
		}
	}
`;

export const GET_NON_ASSOCIATED_BRAND_LOCATIONS = gql`
	query getNonAssociatedBrandLocations(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$brandId: Int!
	) {
		nonAssociatedBrandLocations(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			search: $search
			brandId: $brandId
		) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			limit
			offset
			objects {
				id
				name
				city
			}
		}
	}
`;

export const ASSOCIATE_BRAND_LOCATIONS = gql`
	mutation saveBrand($name: String!, $id: Int, $locationIds: [Int], $image: Upload) {
		saveBrand(input: { name: $name, id: $id, locationIds: $locationIds }, image: $image) {
			status {
				success
				messages {
					field
					message
				}
			}
			referenceId
		}
	}
`;

export const REMOVE_BRANDS_LOCATIONS = gql`
	mutation removeBrandsLocations($isActive: Boolean!, $brandIds: [Int], $locationIds: [Int]) {
		removeBrandsLocations(input: { isActive: $isActive, brandIds: $brandIds, locationIds: $locationIds }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
export const ARCHIVE_RESTORE_BRANDS = gql`
	mutation archiveRestoreBrands($isActive: Boolean!, $brandIds: [Int]) {
		removeBrandsLocations(input: { isActive: $isActive, brandIds: $brandIds }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_BRAND_PUBLISH_LOGS = gql`
	query getBrandLocationPublishLogs(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$brandId: Int
		$locationIds: [Int]
	) {
		locationPlatformActionLogs(
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
			brandId: $brandId
			locationIds: $locationIds
		) {
			count
			objects {
				id
				platformName
				action
				src
				finalStatus
				updated
				location
				locationName
				result
				verificationStatus
				locationPlatform {
					id
					location {
						bizLocationId
						name
					}
					platform {
						id
						platformName
						logo
					}
				}
				user {
					username
					fullName
					email
					phone
					isdCode
					uleEmail
					ulePhone
					uleIsdCode
				}
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
		}
	}
`;

// export const GET_BRAND_LOCATION_CATALOGUE = gql`
// 	query getLocationCatalogue(
// 		$id: Int,
// 		$filters: [ListFilterArgument],
// 		$platforms: [Int],
// 		$available: Boolean,
// 		$brand: Int,
// 	) {
// 		store(
// 			id: $id,
// 		) {
// 			id
// 			catalogue(
// 				filters: $filters,
// 				platforms: $platforms,
// 				available: $available,
// 				brand: $brand,
// 			) {
// 				filters {
// 					hide
// 					field
// 					valueForDisplay
// 					type
// 					values {
// 						value
// 						valueForDisplay
// 					}
// 				}
// 				categories {
// 					name
// 					sortOrder
// 					items {
// 						id
// 						itemTitle
// 						itemDesc
// 						imageUrl
// 						foodType
// 						itemPrice
// 						markupPrice
// 						isRecommended
// 						available
// 						locationPlatforms {
// 							id
// 							ilpaId
// 							platformName
// 							isEnabled
// 							isItemAssociated
// 						}
// 					}
// 					subcategories {
// 						name
// 						sortOrder
// 						items {
// 							id
// 							itemTitle
// 							itemDesc
// 							imageUrl
// 							foodType
// 							itemPrice
// 							markupPrice
// 							isRecommended
// 							available
// 							locationPlatforms {
// 								id
// 								ilpaId
// 								platformName
// 								isEnabled
// 								isItemAssociated
// 							}
// 						}
// 						subcategories {
// 							name
// 							sortOrder
// 							items {
// 								id
// 								itemTitle
// 								itemDesc
// 								imageUrl
// 								foodType
// 								itemPrice
// 								markupPrice
// 								isRecommended
// 								available
// 								locationPlatforms {
// 									id
// 									ilpaId
// 									platformName
// 									isEnabled
// 									isItemAssociated
// 								}
// 							}
// 						}
// 					}
// 				}
// 				items {
// 					id
// 					itemTitle
// 					itemDesc
// 					imageUrl
// 					foodType
// 					itemPrice
// 					markupPrice
// 					isRecommended
// 					available
// 					locationPlatforms {
// 						id
// 						ilpaId
// 						platformName
// 						isEnabled
// 						isItemAssociated
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

export const GET_BRAND_LOCATION_CATALOGUE = gql`
	query getLocationCatalogue(
		$filters: [ListFilterArgument]
		$platforms: [Int]
		$available: Boolean
		$brandId: Int
		$locations: [Int]
	) {
		locationCatalogue(
			filters: $filters
			platforms: $platforms
			available: $available
			brandId: $brandId
			locations: $locations
		) {
			categories {
				name
				sortOrder
				items {
					id
					itemTitle
					itemDesc
					imageUrl
					foodType
					itemPrice
					markupPrice
					isRecommended
					available
					associatedLocationsCount
					locationPlatforms {
						id
						ilpaId
						platformName
						logo
						isEnabled
						isItemAssociated
					}
				}
				subcategories {
					name
					sortOrder
					items {
						id
						itemTitle
						itemDesc
						imageUrl
						foodType
						itemPrice
						markupPrice
						isRecommended
						associatedLocationsCount
						available
						locationPlatforms {
							id
							ilpaId
							platformName
							logo
							isEnabled
							isItemAssociated
						}
					}
					subcategories {
						name
						sortOrder
						items {
							id
							itemTitle
							itemDesc
							imageUrl
							foodType
							itemPrice
							markupPrice
							isRecommended
							available
							locationPlatforms {
								id
								ilpaId
								platformName
								logo
								isEnabled
								isItemAssociated
							}
						}
					}
				}
			}
			items {
				id
				itemTitle
				itemDesc
				imageUrl
				foodType
				itemPrice
				markupPrice
				isRecommended
				available
				locationPlatforms {
					id
					ilpaId
					platformName
					logo
					isEnabled
					isItemAssociated
				}
			}
		}
	}
`;

export const GET_ILPA_IDS = gql`
	query getIlpaIds(
		$limit: Int
		$offset: Int
		$brandId: Int
		$itemId: Int
		$locationId: Int
		$filters: [ListFilterArgument]
	) {
		ilpaIds(
			limit: $limit
			offset: $offset
			brandId: $brandId
			itemId: $itemId
			locationId: $locationId
			filters: $filters
		) {
			count
			objects {
				id
				bizLocation {
					name
					id
				}
				bizPlatform {
					ilpaId
					platformName
				}
			}
		}
	}
`;
