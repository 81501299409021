import React, { Component, useEffect, useRef, useState } from "react";

// components
import { CopyToClipboard } from "../_commons/CopyToClipboard";
import MonogramGenerator from "../_commons/MonogramGenerator";
import Image from "../_commons/Image";
import { Button } from "../_commons/Button";
import { ButtonIcon } from "../_commons/ButtonIcon";

// third party
import { connect } from "react-redux";
import moment from "moment";

// utils
import {
	printCurrency,
	setFloatingPrecision,
	commifyNumbers,
	generateReadableAddress,
	formatDate,
	capitaliseText,
	capitaliseTextStrict,
	openExternalLink
} from "../../atlas-utils";

// constants
import {
	ORDER_BILL_COMPONENTS,
	ORDER_STATUS_MAP,
	DELIVERY_STATUS_MAP,
	PAYMENT_MODE_MAP,
	ORDER_CHANNEL_MAP,
	CATALOGUE_PLATFORMS_LOGO,
	NESTED_ENTITY_TYPES,
	ORDER_STATUS_BACKGROUND_COLOR_MAP,
	ORDER_STATUS_TEXT_COLOR_MAP,
	ORDER_STATUS_BACKGROUND_COLOR_DEFAULT,
	ORDER_STATUS_TEXT_COLOR_DEFAULT,
	DELIVERY_STATUS_TEXT_COLOR_MAP,
	DELIVERY_STATUS_TEXT_COLOR_DEFAULT,
	DELIVERY_STATUS_BACKGROUND_COLOR_MAP,
	DELIVERY_STATUS_BACKGROUND_COLOR_DEFAULT,
	PLATFORM_NAME_MAP
} from "../../client-config";
const MINIMUM_QUANTITY = 2;

@connect((store) => ({
	biz: store.login.loggedInbizDetail
}))
export class Summary extends Component {
	trackCustomerDetailEvent = () => {
		const { data, trackEvent } = this.props;
		// trackEvent(
		// 	'transaction_detail_customer_view',
		// 	{
		// 		customer_id: (data.customer && data.customer.id) ? data.customer.id : ''
		// 	},
		// );
	};

	render() {
		const { data, loading, trackEvent, handleNestedEntity, openSidebar, bizChannels } = this.props;
		const items = data.items || [];
		const storeTitle = data.store && data.store.title ? data.store.title : "";
		const firstName = data.customer && data.customer.firstName ? data.customer.firstName : "";
		const phone = data.customer && data.customer.phone ? data.customer.phone : "";
		const email = data.customer && data.customer.email ? data.customer.email : "";
		const customerId = data.customer && data.customer.id ? data.customer.id : "";
		const deliveryState = data.delivery && data.delivery.currentState ? data.delivery.currentState : "";
		const deliveryPersonName =
			data.delivery && data.delivery.deliveryPerson && data.delivery.deliveryPerson.name
				? data.delivery.deliveryPerson.name
				: "";
		let deliveryPersonPhone =
			data.delivery && data.delivery.deliveryPerson && data.delivery.deliveryPerson.phone
				? data.delivery.deliveryPerson.phone
				: "";
		const deliveryPersonAltPhone =
			data.delivery && data.delivery.deliveryPerson && data.delivery.deliveryPerson.altPhone
				? data.delivery.deliveryPerson.altPhone
				: "";
		if (deliveryPersonAltPhone) {
			deliveryPersonPhone = `${deliveryPersonPhone} (${deliveryPersonAltPhone})`;
		}
		let {
			id,
			payableAmount,
			status,
			type,
			paymentMode,
			paymentTransaction,
			merchantRefId,
			address,
			timeSlotStart,
			timeSlotEnd,
			channel,
			channelLogo,
			externalPlatform,
			instructions,
			deliveryDate,
			statusUpdates,
			childOrderId,
			store,
			complaints = []
		} = data;

		status = childOrderId && status === "CANCELLED" ? "CANCELLED_FOR_MODIFICATION" : status;
		const statusColor =
			(status && ORDER_STATUS_BACKGROUND_COLOR_MAP[status]) || ORDER_STATUS_BACKGROUND_COLOR_DEFAULT;
		const deliveryStateConfig =
			Object.keys(DELIVERY_STATUS_MAP).find((status) => DELIVERY_STATUS_MAP[status] === deliveryState) || "";
		const deliveryStatusColor =
			deliveryStateConfig &&
			(DELIVERY_STATUS_BACKGROUND_COLOR_MAP[deliveryStateConfig] ?? DELIVERY_STATUS_BACKGROUND_COLOR_DEFAULT);
		const plfLogo = channelLogo
			? channelLogo
			: channel && CATALOGUE_PLATFORMS_LOGO[(PLATFORM_NAME_MAP[channel] || channel).toLowerCase()];
		const parentOrderId = data.parentOrder && data.parentOrder.id ? data.parentOrder.id : "";

		// get order placed time from status updates
		let orderPlacedTime = "";
		if (statusUpdates && statusUpdates.find((st) => st.status === "PLACED")) {
			orderPlacedTime = formatDate(
				statusUpdates.find((st) => st.status === "PLACED").created?.split?.("+")?.[0],
				"DD MMM, YYYY - hh:mm:ss A"
			);
		}

		// format deliverTime according to timeStart/timeEnd/deliveyDate availability
		let deliveryTimeslot = "";
		if ((timeSlotStart && timeSlotEnd) || timeSlotEnd) {
			deliveryTimeslot =
				(timeSlotStart ? timeSlotStart : "") +
				(timeSlotStart && timeSlotEnd ? " - " : "") +
				(timeSlotEnd ? timeSlotEnd : "");
		} else {
			deliveryTimeslot = formatDate(deliveryDate?.split?.("+")?.[0], "DD MMM, YYYY - hh:mm:ss A");
		}
		// format deliveryDate
		const dateOfDelivery = formatDate(deliveryDate?.split?.("+")?.[0], "DD MMM, YYYY");

		const expiredRefundComplaints = complaints.filter((complaint) =>
			moment().isAfter(moment.unix(complaint.expiredAt))
		);
		const nonExpiredRefundComplaints = complaints
			.filter((complaint) => moment().isBefore(moment.unix(complaint.expiredAt)))
			.sort((a, b) => parseInt(a.expiredAt) - parseInt(b.expiredAt));
		const combinedRefundComplaints = [...nonExpiredRefundComplaints, ...expiredRefundComplaints];

		if (!id && loading) {
			return (
				<div className="transaction-details-summary">
					<div className="order-details">
						<div className="shimmer H(80px) Mb(15px)"></div>
						<div className="shimmer H(80px) Mb(15px)"></div>
						<div className="shimmer H(80px)"></div>
					</div>
					<div className="customer-details">
						<div className="shimmer H(60px) Mb(10px)"></div>
						<div className="shimmer H(60px) Mb(10px)"></div>
						<div className="shimmer H(60px) Mb(10px)"></div>
						<div className="shimmer H(60px)"></div>
					</div>
				</div>
			);
		}
		return (
			<div className="transaction-details-summary">
				{(combinedRefundComplaints ?? []).length > 0 &&
					combinedRefundComplaints.map((complaint) => (
						<OrderRefundDetails
							{...complaint}
							channel={(PLATFORM_NAME_MAP[channel] || channel).toUpperCase()}
						/>
					))}
				<div className="order-details">
					<div className="order-details-header">{items.length} item(s) ordered</div>
					{items.map((item, i) => (
						<OrderItem key={i} currencySymbol={this.props.biz.currencySymbol} store={store} {...item} />
					))}
					<div className="order-bill">
						<div className="bill-breakup">
							{ORDER_BILL_COMPONENTS.map((billC, i) => (
								<OrderBillComponent
									key={i}
									data={data}
									currencySymbol={this.props.biz.currencySymbol}
									{...billC}
								/>
							))}
						</div>
						<div className="bill-total">
							<div className="bill-component">
								<div className="dummy"></div>
								<div className="type total">Payable amount</div>
								<div className="value total">
									{printCurrency(this.props.biz.currencySymbol)}
									{setFloatingPrecision(commifyNumbers((payableAmount || 0).toFixed(2)))}
								</div>
							</div>
						</div>
					</div>
					<div className="order-instructions">
						<div className="title">Special instructions</div>
						<div className="value">{instructions && instructions.trim() ? instructions : "N/A"}</div>
					</div>
				</div>
				{(deliveryState || deliveryPersonName || deliveryPersonPhone) && (
					<div className="delivery-details">
						<div className="section-title">
							<img className="icon-delivery" src="/assets/icons/icon-order-delivery.svg" />
							<div>Delivery Info</div>
						</div>
						<div className="details-field-grid">
							<CustomerInfoItem
								label="Delivery Status"
								value={deliveryState}
								metaClass="value-status"
								style={{
									backgroundColor: deliveryStatusColor,
									border: 0,
									color:
										DELIVERY_STATUS_TEXT_COLOR_MAP[
											deliveryState?.split(" ")?.join("_")?.toUpperCase()
										] ?? DELIVERY_STATUS_TEXT_COLOR_DEFAULT
								}}
							/>
							<CustomerInfoItem label="Delivery Person" value={deliveryPersonName} />
							<CustomerInfoItem label="Delivery Person's Phone" value={deliveryPersonPhone} />
						</div>
						<CustomerInfoItem label="Delivery Address" value={generateReadableAddress(address)} />
					</div>
				)}
				<div className="customer-details">
					<div className="section-title">
						<img className="icon-customer" src="/assets/icons/users-dark.svg" />
						<div>Customer Info</div>
					</div>
					<div className="details-field-grid">
						<CustomerInfoItem
							label="Status"
							value={ORDER_STATUS_MAP[status] || status}
							metaClass="value-status"
							style={{
								backgroundColor: statusColor,
								border: 0,
								color: ORDER_STATUS_TEXT_COLOR_MAP[status] ?? ORDER_STATUS_TEXT_COLOR_DEFAULT
							}}
						/>
						<CustomerInfoItem
							label="Channel"
							value={ORDER_CHANNEL_MAP[channel] || PLATFORM_NAME_MAP[channel] || channel}
							icon={plfLogo}
						/>
						<CustomerInfoItem label="Delivery Address" value={generateReadableAddress(address)} isFullRow />
						<CustomerInfoItem
							label="Child Order"
							value={childOrderId}
							metaClass="value-name"
							trackEvent={() => {}}
							isLink={true}
							entityId={childOrderId}
							entityType="11"
							handleNestedEntity={handleNestedEntity}
						/>
						<CustomerInfoItem
							label="Parent Order"
							value={parentOrderId}
							metaClass="value-name"
							trackEvent={() => {}}
							isLink={true}
							entityId={parentOrderId}
							entityType="11"
							handleNestedEntity={handleNestedEntity}
						/>
						<CustomerInfoItem label="Outlet" value={storeTitle} />
						<CustomerInfoItem label="POS ID" value={merchantRefId} />
						{externalPlatform && externalPlatform.id && (
							<CustomerInfoItem
								label={`${
									bizChannels[channel.toUpperCase()] !== undefined
										? bizChannels[channel.toUpperCase()]
										: ORDER_CHANNEL_MAP[channel.toUpperCase()] !== undefined
										? ORDER_CHANNEL_MAP[channel.toUpperCase()]
										: capitaliseText(PLATFORM_NAME_MAP[channel] || channel)
								} ID`}
								value={externalPlatform.id}
							/>
						)}
						<CustomerInfoItem
							label="Payment Mode"
							value={PAYMENT_MODE_MAP[paymentMode] || paymentMode}
							metaClass={
								(paymentMode === "PAYMENT_GATEWAY" || paymentMode === "PAYTM") && paymentTransaction
									? "value-name"
									: ""
							}
							isLink={
								(paymentMode === "PAYMENT_GATEWAY" || paymentMode === "PAYTM") && paymentTransaction
									? true
									: false
							}
							trackEvent={() => {}}
							entityId={customerId}
							handleNestedEntity={openSidebar}
						/>
						<CustomerInfoItem
							label="Customer Name"
							value={address && address?.name ? address?.name : firstName ? firstName : phone}
							metaClass="value-name"
							isLink={true}
							trackEvent={this.trackCustomerDetailEvent}
							entityId={customerId}
							entityType="12"
							handleNestedEntity={handleNestedEntity}
						/>
						<CustomerInfoItem label="Customer Email" value={email} />
						<CustomerInfoItem label="Customer Phone" value={phone} />
						<CustomerInfoItem
							label="Fulfilment Mode"
							value={
								type === "delivery" && externalPlatform?.deliveryType
									? `${type} (${capitaliseTextStrict(externalPlatform?.deliveryType)})`
									: type
							}
							style={{ textTransform: "capitalize" }}
						/>
					</div>
					<div className="details-field-grid">
						<CustomerInfoItem label="Order Time" value={orderPlacedTime} />
						<CustomerInfoItem
							label="Delivery Time/Time-Slot"
							value={
								deliveryTimeslot +
								((timeSlotStart && timeSlotEnd) || timeSlotEnd ? ` (${dateOfDelivery})` : "")
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const OrderItem = ({
	title,
	price,
	quantity,
	optionsToAdd,
	currencySymbol,
	taxes,
	charges,
	discount,
	discountCode,
	instructions = null,
	store
}) => (
	<div className="order-item">
		<div className="item-info">
			<div className="name">{title}</div>
			<div className="brands">
				{store?.brand?.image ? (
					<>
						<Image src={store?.brand?.image} className="brand-image" />
						<span>{store?.brand?.name || ""}</span>
					</>
				) : (
					<>
						<MonogramGenerator size="xsmall" value={store?.brand?.name || ""} />
						<span>{store?.brand?.name || ""}</span>
					</>
				)}
			</div>
			{optionsToAdd.map((option, i) => (
				<div key={i} className="additions">
					{" "}
					{option?.quantity && option.quantity >= MINIMUM_QUANTITY ? `${option.quantity} x` : null}{" "}
					{option.title}{" "}
				</div>
			))}
			{instructions && (
				<div className="instructions">
					<span>Notes: </span>
					{instructions}
				</div>
			)}
		</div>
		<div className="item-quantity">
			{quantity} x {printCurrency(currencySymbol)}
			{setFloatingPrecision(commifyNumbers(price.toFixed(2)))}
		</div>
		<div className="item-price">
			<div className="total">
				{printCurrency(currencySymbol)}
				{setFloatingPrecision(commifyNumbers((price * quantity).toFixed(2)))}
			</div>
			{optionsToAdd.map((option, i) => (
				<div key={i} className="tax">
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers(option.priceAtLocation.toFixed(2)))}
				</div>
			))}
			{taxes.map((tax, i) => (
				<div className="tax" key={i}>
					<span>
						{tax.title}@{tax.rate}%:
					</span>
					&nbsp;&nbsp;
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers(tax.value.toFixed(2)))}
				</div>
			))}
			{charges.map((charge, i) => (
				<div className="tax" key={i}>
					<span>{charge.title}:</span>&nbsp;&nbsp;
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers(charge.value.toFixed(2)))}
				</div>
			))}
			{discount > 0 && (
				<div className="tax">
					<span>Discount:</span>&nbsp;&nbsp;
					<span> - </span>
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers(discount.toFixed(2)))}
				</div>
			)}
			{discountCode && (
				<div className="tax">
					<span>Discount Code:</span>&nbsp;&nbsp;
					<span>{discountCode}</span>
				</div>
			)}
		</div>
	</div>
);

const OrderBillComponent = ({ field, label, type, currency = true, isNumber = true, data, currencySymbol }) => {
	if (!data || !data[field]) {
		return null;
	}
	return (
		<div className="bill-component">
			<div className="dummy"></div>
			<div className="type">{label}</div>
			<div className="value">
				{type === "credit" ? "-" : ""}
				{currency && printCurrency(currencySymbol)}
				{isNumber ? setFloatingPrecision(commifyNumbers(data[field].toFixed(2))) : data[field]}
			</div>
		</div>
	);
};

export const CustomerInfoItem = ({
	label,
	value,
	isFullRow = false,
	metaClass,
	style = {},
	icon,
	isCurrency,
	currencySymbol,
	entityId,
	entityType = "12",
	isLink,
	showCopyBtn = false,
	trackEvent,
	handleNestedEntity
}) => {
	if (!value) {
		return null;
	}
	return (
		<div className={"detail-field" + (isFullRow ? " full-row" : "")}>
			<div className="type">{label}</div>
			{isLink && entityId ? (
				<a
					role="button"
					className={"value " + (metaClass ? metaClass : "")}
					style={style}
					onClick={(e) => {
						trackEvent();
						handleNestedEntity(e, NESTED_ENTITY_TYPES[entityType], entityId);
					}}
				>
					{value}
				</a>
			) : (
				<div style={style} className={"value " + (metaClass ? metaClass : "")}>
					{icon && <img src={icon} className="detail-icon" />}
					{isCurrency && printCurrency(currencySymbol)}
					{value}
					{showCopyBtn && <CopyToClipboard content={value} showIcon={true} />}
				</div>
			)}
		</div>
	);
};

const OrderRefundDetails = ({
	created = "",
	complaintMessage,
	complaintDesc,
	channel,
	customerComplaintsCount,
	expiredAt,
	items = [],
	repeatCustomerCount
}) => {
	const now = moment();
	const expiryTime = moment.unix(expiredAt);
	const [durationDifference, setDurationDifference] = useState(expiredAt ? moment.utc(expiryTime.diff(now)) : null);
	const timerRef = useRef();
	const isNotExpired = now.isBefore(expiryTime);
	const expiryTimeFromNow = expiryTime.fromNow(true);
	const dayDiff = expiryTime.diff(now, "days");

	let durationDifferenceHours = "--";
	let durationDifferenceMinutes = "--";
	let durationDifferenceSeconds = "--";
	if (durationDifference) {
		durationDifferenceHours = durationDifference.format("HH");
		durationDifferenceMinutes = durationDifference.format("mm");
		durationDifferenceSeconds = durationDifference.format("ss");
	}

	const handleOpenExternalLink = (e, channel) => {
		if (channel === "SWIGGY") {
			openExternalLink("https://partner.swiggy.com/complaints/");
		} else {
			openExternalLink("https://www.zomato.com/partners/onlineordering/customerIssues/");
		}
	};

	useEffect(() => {
		if (isNotExpired && dayDiff < 1) {
			timerRef.current = setInterval(() => {
				setDurationDifference(moment.utc(expiryTime.diff(moment())));
			}, 1000);
		} else {
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		}

		return () => {
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		};
	}, []);

	return (
		<div className="order-refund-details">
			<div className="header-container">
				<div className="header-text">
					<img src="/assets/icons/icon-alert-red.svg" alt="Refund alert" />
					<div className="title">Refund Request</div>
				</div>
				<div className="header-actions">
					{isNotExpired ? (
						<React.Fragment>
							{dayDiff > 1 ? (
								<div className="resolution-expiry-timestamp">
									Resolve within &nbsp;
									{expiryTimeFromNow}
								</div>
							) : (
								<div className="resolution-expiry-timestamp">
									Resolve within &nbsp;
									{durationDifferenceHours}:{durationDifferenceMinutes}:{durationDifferenceSeconds}
								</div>
							)}
							<Button
								classes="resolve-action-button"
								clickHandler={(e) => handleOpenExternalLink(e, channel)}
							>
								<span>Resolve</span>
								<ButtonIcon icon="external-link" />
							</Button>
						</React.Fragment>
					) : (
						<div className="resolution-expired">Expired</div>
					)}
				</div>
			</div>
			<div className="refund-reasons">
				<div className="title">{complaintDesc || "Reason"}</div>
				<div className="text-content">{complaintMessage || "--"}</div>
			</div>
			<div className="refund-details details-field-grid">
				<CustomerInfoItem label="Complaint Timestamp" value={formatDate(moment.unix(created))} />
				<CustomerInfoItem label="Repeat Customer Count" value={repeatCustomerCount} />
				<CustomerInfoItem label="Customer Complaint Count" value={customerComplaintsCount} />
			</div>
		</div>
	);
};
