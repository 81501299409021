import React from "react";
import { connect } from "react-redux";

const ProgressBar = ({ rowTitle, currIndex, metric, login, compareAnalytics, status }) => {
	const columnData = compareAnalytics.columnData?.[metric].data?.[currIndex];
	const tableData = compareAnalytics.tableData?.[metric]?.[columnData?.valueForDisplay];
	if (!tableData) {
		return null;
	}
	const data = tableData?.[rowTitle];
	const loading = data?.loading;
	const error = data?.error;
	let value = tableData?.[rowTitle]?.["value"];
	let fullValue = Math.floor(value / 10);
	let remaining = Math.floor(value % 10);
	return (
		<div className="progress-bar">
			<div className="bar compare-loading">
				{Array.from({ length: 10 }).map((_, index) => {
					return (
						<div
							className={`individual-bar   ${
								!loading && !error && fullValue >= index + 1 ? "color-fill" : "color-grey"
							}  
                                ${loading && "shimmer  W100% h(16px)"} ${error && "error  W100% h(16px)"}    
                       
                             `}
							key={index}
						>
							<div
								style={{
									...(!loading &&
										!error &&
										fullValue + 1 == index + 1 &&
										remaining && {
											width: `${remaining * 10}%`,
											background: "#2543B6",
											height: "100%"
										}),
									...((loading || error) && {})
								}}
							></div>
						</div>
					);
				})}
			</div>
			{loading || error ? (
				<div
					className={`${loading && "shimmer  W100% h(22px)"} ${error && " color-grey "} percent-available `}
				></div>
			) : (
				<div className="percent-display">{value}% Available</div>
			)}
		</div>
	);
};

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	configItems: store.configItems,
	isMultibrandEnabled: store.login?.loggedInbizDetail?.isMultibrandEnabled,
	login: store.login,
	compareAnalytics: store.compareAnalytics
}))(ProgressBar);
