// action types
import { ActionTypes } from "../actions/_types";

// utils
import { getSortOrder } from "../atlas-utils";

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "",
		order: ""
	},
	sortedField: "",
	currentFilters: {
		roles: { valueForDisplay: "All Roles", value: "" }
	},
	appliedFilters: {
		roles: { valueForDisplay: "All Roles", value: "" }
	}
};
export const unifiedUsersListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNIFIED_USERS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.UNIFIED_USERS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.UNIFIED_USERS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const UNIFIED_USERS_LIST_INITIAL_STATE = {
	userActionsLoading: false,
	loading: false,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	error: false
};
export const unifiedUsersList = (state = UNIFIED_USERS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_UNIFIED_USERS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_UNIFIED_USERS_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
					searchFieldSelected:
						action.payload?.searchKeywords?.length && state.data.searchFieldSelected.key === "default"
							? action.payload?.searchKeywords?.[0]
							: state.data.searchFieldSelected
				},
				loading: false
			};
		case ActionTypes.GET_UNIFIED_USERS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_UNIFIED_USERS_LIST:
			let foundInList = false;
			const updatedObjects = state.data.objects.map((obj) => {
				if (obj.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return obj;
			});
			if (!foundInList) {
				updatedObjects.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedObjects,
					count: state.data.count + 1
				}
			};
		case ActionTypes.UNIFIED_USERS_LIST_SEARCH:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.ULE_USER_ACTION_REQUEST:
			return {
				...state,
				userActionsLoading: true,
				error: false
			};
		case ActionTypes.ULE_USER_ACTION_SUCCESS:
			return {
				...state,
				userActionsLoading: false
			};
		case ActionTypes.ULE_USER_ACTION_FAILURE:
			return {
				...state,
				userActionsLoading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const UNIFIED_USER_DETAILS_INITIAL_STATE = {
	loading: false,
	confirmLoading: false,
	data: {},
	error: {}
};
export const unifiedUserDetailsReducer = (state = UNIFIED_USER_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_UNIFIED_USER_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_UNIFIED_USER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_UNIFIED_USER_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.RESET_UNIFIED_USER_DETAIL:
			return UNIFIED_USER_DETAILS_INITIAL_STATE;
		case ActionTypes.UPDATE_UNIFIED_USER_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_UNIFIED_USER_DETAIL_REQUEST:
			return {
				...state,
				confirmLoading: true,
				error: {}
			};
		case ActionTypes.EDIT_UNIFIED_USER_DETAIL_SUCCESS:
			return {
				...state,
				confirmLoading: false
			};
		case ActionTypes.EDIT_UNIFIED_USER_DETAIL_FAILURE:
			return {
				...state,
				confirmLoading: false,
				error: action.error
			};
		default:
			return state;
	}
};
