import React, { Component } from "react";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// components
import { Button } from "../components/_commons/Button";
import { Paginator } from "../components/_commons/Paginator";
import { BackNavigation } from "../components/_commons/BackNavigation";
import { GlobalConfirmModal } from "../components/SiteComp";
import { InfoSidebar } from "../components/CampaignDetail/InfoSidebar";
import {
	StatusProgressBar,
	CampaignReport,
	PlaceHolder,
	CampaignHighLightBox,
	Header
} from "../components/Campaign/CampaignStatus";

// utils
import { scroll } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { CAMPAIGN_STATUS_LABELS, MEDIUM_OPTIONS } from "../client-config";

// actions
import { toggleGlobalLoader } from "../actions/actions";

// graphql
import { GET_CAMPAIGN_STATUS, GET_CAMPAIGN_PROGRESS, STOP_CAMPAIGN } from "../graphql/campaigns";

@connect((store) => ({
	campaignStatus: store.campaignStatus,
	login: store.login,
	dimensions: store.configItems.dimensions
}))
export default class CampaignDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 15,
			offset: 0,
			stopModalShow: false,
			stopModalBusy: false,
			showCampaignDetail: false,
			fetchingCampaignStatus: false
		};
		this.defaultPrevLocation = {
			pathname: "/campaigns",
			title: "Campaigns"
		};
	}

	componentDidMount() {
		this.fetchCampaignStatus(this.props.match.params.id);
	}

	componentWillUnmount() {
		clearInterval(this.poller);
	}

	pollProgress = async (id) => {
		try {
			const resp = await client.query({
				query: GET_CAMPAIGN_PROGRESS,
				variables: {
					id: Number(id)
				},
				fetchPolicy: "network-only"
			});
			store.dispatch({
				type: "UPDATE_REPORT_REQUEST_SUCCESS",
				payload: {
					...this.props.campaignStatus.data,
					...resp.data.campaign
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	componentWillReceiveProps(newProps) {
		if (this.props.match.params.id != newProps.match.params.id) {
			this.fetchCampaignStatus(newProps.match.params.id);
		}

		if (
			this.props.campaignStatus.data &&
			this.props.campaignStatus.data.status != newProps.campaignStatus.data.status
		) {
			this.fetchCampaignStatus(newProps.match.params.id);
		}
	}

	fetchCampaignStatus = async (id) => {
		this.setState({
			fetchingCampaignStatus: true
		});
		store.dispatch(toggleGlobalLoader(true));
		store.dispatch({
			type: "GET_CAMPAIGN_STATUS_REQUEST"
		});
		try {
			const resp = await client.query({
				query: GET_CAMPAIGN_STATUS,
				variables: {
					id: Number(id),
					limit: this.state.limit,
					offset: this.state.offset
				},
				fetchPolicy: "network-only"
			});
			store.dispatch({
				type: "GET_CAMPAIGN_STATUS_SUCCESS",
				payload: resp.data.campaign
			});
			if (resp.data.campaign.status == "COMPLETED" || resp.data.campaign.status == "CANCELLED") {
				clearInterval(this.poller);
			} else {
				clearInterval(this.poller);
				this.poller = setInterval(() => {
					this.pollProgress(this.props.match.params.id);
				}, 5000);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_CAMPAIGN_STATUS_FAILURE"
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		this.setState({
			fetchingCampaignStatus: false
		});
		store.dispatch(toggleGlobalLoader(false));
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.state;
		const offset = (page - 1) * limit;
		this.setState(
			{
				offset
			},
			() => this.fetchCampaignStatus(this.props.match.params.id)
		);
		// scroll to top of the list
		scroll({ top: this.tableRef.offsetTop - 57, left: 0 });
	};

	stopCampaign = async () => {
		store.dispatch(toggleGlobalLoader(true));
		this.setState({
			stopModalBusy: true
		});
		try {
			const resp = await client.mutate({
				mutation: STOP_CAMPAIGN,
				variables: {
					id: Number(this.props.campaignStatus.data.id),
					status: "CANCELLED"
				}
			});
			if (resp.data.saveCampaign.status.success) {
				store.dispatch({
					type: "UPDATE_REPORT_REQUEST_SUCCESS",
					payload: {
						...this.props.campaignStatus.data,
						status: "CANCELLED"
					}
				});
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Campaign successfully stopped",
						timeout: 5000,
						error: false
					}
				});
				clearInterval(this.poller);
				this.setState({
					stopModalBusy: false,
					stopModalShow: false
				});
			} else {
				this.setState({
					stopModalBusy: false
				});
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Sorry, this campaign can not be stopped",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "There was an error",
					timeout: 5000,
					error: true,
					errorObj: error
				}
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	};

	hideStopModal = () => {
		this.setState({
			stopModalShow: false
		});
	};

	openStopModal = () => {
		this.setState({
			stopModalShow: true
		});
	};

	handleShowCampaignDetail = (showCampaignDetail) => {
		this.setState({
			showCampaignDetail
		});
		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'campaign_info_view',
		// });
	};

	render() {
		const { fetchingCampaignStatus } = this.state;
		const { campaignStatus, dimensions } = this.props;
		let currencySymbol = this.props.login.loggedInbizDetail.currencySymbol;
		let progress = 0;
		let status = "--";
		let mediumStr = "";
		let currLocationTitle = `Campaign - ${this.props.match.params.id}`;

		if (campaignStatus.data) {
			// set status of campaign
			status = CAMPAIGN_STATUS_LABELS[campaignStatus.data.status]
				? CAMPAIGN_STATUS_LABELS[campaignStatus.data.status]
				: campaignStatus.data.status;
			// calculate progress
			let { totalSent, totalTargeted } = campaignStatus.data;
			progress = Math.floor((totalSent / totalTargeted) * 100);
			if (!progress) {
				progress = 0;
			}
			// set medium
			const mediumObj = MEDIUM_OPTIONS.find((m) => m.value === campaignStatus.data.medium);
			mediumStr = mediumObj ? mediumObj.label || campaignStatus.data.medium : "";
			// set campaign title
			currLocationTitle = campaignStatus.data.name;
		}

		if (campaignStatus.error) {
			return (
				<div className="campaign-status-page section-container-common">
					<div className="C(#c0392b) no-items-placeholder error">
						Failed to fetch "Campaign details", refresh the page.
					</div>
				</div>
			);
		}

		const currLocation = {
			pathname: this.props.location.pathname,
			title: currLocationTitle
		};

		return (
			<React.Fragment>
				<BackNavigation
					prevLocation={this.props.location.state || this.defaultPrevLocation}
					currLocation={currLocation}
				/>
				<div
					className={
						"campaign-status-page section-container-common " + (fetchingCampaignStatus ? "disabled" : "")
					}
				>
					{this.props.campaignStatus.data && (
						<InfoSidebar
							data={this.props.campaignStatus.data}
							showCampaignDetail={this.state.showCampaignDetail}
							handleShowCampaignDetail={this.handleShowCampaignDetail}
						/>
					)}
					{this.props.campaignStatus.loading && !this.props.campaignStatus.data ? (
						<div className="P(20px)">
							<PlaceHolder />
							<PlaceHolder />
						</div>
					) : (
						<div>
							<div className="section-1">
								<div className="Fl(start)">
									<Header {...this.props.campaignStatus.data} />
								</div>
								<div
									onClick={() => this.handleShowCampaignDetail(!this.state.showCampaignDetail)}
									className="Fl(end) view-info-section"
								>
									<div>
										<img height={20} src="/assets/icons/info.png" />
									</div>
									<div>View info</div>
								</div>
								<div className="Cf"></div>
								<div className="Mt(10px) Mb(10px)">
									{status && (
										<div className={status.toLowerCase() + " Fl(end) Mb(10px) campaign-status"}>
											{status}
										</div>
									)}
									<div className="Cf"></div>
									{this.props.campaignStatus.data.status != "RUNNING" &&
										this.props.campaignStatus.data.status != "COMPLETED" &&
										this.props.campaignStatus.data.status != "CANCELLED" && (
											<div className="Fl(end) Mb(20px)">
												<Button clickHandler={this.openStopModal}>Stop Campaign</Button>
											</div>
										)}
								</div>
							</div>
							{this.props.campaignStatus.data.status == "COMPLETED" ? null : (
								<div className="section-2">
									<div>
										<StatusProgressBar width={progress} {...this.props.campaignStatus.data} />
									</div>
								</div>
							)}
							<div className="section-3">
								<div className="campaign-status-box-container">
									<CampaignHighLightBox
										count={this.props.campaignStatus.data.totalSent}
										medium={mediumStr}
										icon="/assets/icons/message.svg"
										title="SENT"
									/>
									<CampaignHighLightBox
										count={this.props.campaignStatus.data.conversionCount}
										icon="/assets/icons/users.svg"
										title="CONVERSION"
										uniqueCount={this.props.campaignStatus.data.uniqueConversionCount}
									/>
									<CampaignHighLightBox
										currency={currencySymbol}
										count={this.props.campaignStatus.data.revenue}
										icon="/assets/icons/revenue.svg"
										title="REVENUE"
									/>
								</div>
							</div>
							<div className="section-4" ref={(ref) => (this.tableRef = ref)}>
								<Header
									name="Campaign report"
									description="List of all the customers who recieved your message."
								/>
							</div>
							<div className="section-5">
								<CampaignReport
									currency={currencySymbol}
									fetchingCampaignStatus={fetchingCampaignStatus}
									updating={this.props.campaignStatus.updating}
									clickHandler={this.fetchMore}
									currLocation={currLocation}
									dimensions={dimensions}
									{...this.props.campaignStatus.data}
								/>
							</div>
							<Paginator
								limit={this.state.limit}
								offset={this.state.offset}
								count={this.props?.campaignStatus?.data?.reports?.count || 0}
								goToPage={this.handlePagination}
							/>
						</div>
					)}
					<GlobalConfirmModal
						backdropHandler={() => {
							return;
						}}
						show={this.state.stopModalShow}
						confirmBtnEnabled={true}
						confirmHandler={this.stopCampaign}
						cancelHandler={this.hideStopModal}
						modalBusy={this.state.stopModalBusy}
					>
						<div className="discard-confirm-modal">
							<div className="title">Stop campaign</div>
							<div className="modal-text">Are you sure ?</div>
						</div>
					</GlobalConfirmModal>
				</div>
			</React.Fragment>
		);
	}
}
