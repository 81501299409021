import React from "react";

// components
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";

export const Header = (props) => {
	return (
		<div className="filters">
			<div className="filter-buttons">
				<SelectFilter
					options={[
						{ valueForDisplay: "All Roles", value: "" },
						...(props?.filters?.find((filter) => filter.field === "roles")?.values ?? [])
					]}
					field="roles"
					currValue={props.appliedFilters?.roles || null}
					setFilter={props.handleFilters}
					labelKey="valueForDisplay"
					valueKey="value"
					isClearable={false}
					isSearchable={true}
					placeholder="Select Roles"
				/>
				{props.dimensions.width > 768 && (
					<div className={(props.filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
						<div className="container" onClick={props.flipShowFilters}>
							<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
							<div className="filter-title">
								Filter
								{props.filterCount > 0 && <span className="filter-count">{props.filterCount}</span>}
							</div>
						</div>
					</div>
				)}
			</div>
			{props.searchKeywords && props.searchKeywords.length > 1
				? props.dimensions.width > 768 && (
						<div className="search-input-container">
							<SelectFilter
								options={props.searchKeywords}
								field="searchFieldSelected"
								currValue={props.searchFieldSelected}
								setFilter={props.handleSearchField}
								labelKey="valueForDisplay"
								valueKey="key"
								isSearchable={false}
								isClearable={false}
							/>
							<SearchFilter
								filterOption={{ field: "searchFieldValue" }}
								value={props.searchFieldValue}
								setFilter={props.setFilter}
								placeholder="Search"
							/>
						</div>
				  )
				: props.dimensions.width > 768 && (
						<SearchFilter
							filterOption={{ field: "searchFieldValue" }}
							value={props.searchFieldValue}
							setFilter={props.setFilter}
							placeholder="Search"
						/>
				  )}
		</div>
	);
};
