// client
import { client } from "../client";
import { clientMenu } from "../client-menu";
import { store } from "../store/configureStore";

// third party
import { debounce } from "lodash";
import moment from "moment";
import history from "../history";

// utils
import { parseErrorMessages, lS, isLoggedin, getPrinterCompatibleImages } from "../atlas-utils";

// graphql
import {
	GET_COUPONS_LIST,
	GET_ITEMS_LIST,
	GET_STORES_LIST,
	GET_BRANDS_LIST,
	GET_MENUS_LIST,
	GET_TAGS_LIST,
	GET_FULFILLMENT_MODES_LIST,
	GET_TIMING_GROUPS_LIST,
	GET_CITIES_LIST,
	GET_ITEM_CATEGORIES,
	GET_PARENT_CATEGORIES,
	GET_PAYMENT_MODES_LIST,
	GET_BIZ_PLATFORMS,
	GET_CI_ITEM_GROUPS_LIST,
	GET_CI_LOCATION_GROUPS_LIST,
	GET_CI_CHARGES_LIST,
	GET_CI_MODIFIER_GROUPS_LIST,
	GET_CI_MODIFIERS_LIST,
	GET_CI_LIST_GROUPS,
	GET_TRANSLATION,
	UPDATE_TRANSLATION,
	GET_PARENT_CATEGORY_IDS,
	UPLOAD_IMAGE
} from "../graphql/misc";
import { AUTH_USER_LOGOUT } from "../graphql/unifiedUsers";

// actions
import { ActionTypes } from "./_types";
import { getAndSetBizInfo, updateGetAndSetBizInfo } from "../actions/updateBizInfo";
import { fetchCatalogueVerificationList } from "../actions/catalogueVerification";
import { fetchMerakiWebSubscriptionDetails } from "../actions/merakiWeb";
import { fetchCatalogueBackupsStatus } from "./catalogueBackups";
import { fetchNotifications, fetchPeriscopeData as fetchPeriscopeRefreshStatus } from "./notifications";
import { subscribeToFlowSteps } from "./onboarding";

// constants
import { PRESET_TYPES } from "../components/_commons/DateFilter";
import { GET_ASSOCIATED_BRAND_SPECIFIC_LOCATIONS_NAME } from "../graphql/brands";
import {
	BRAND_COLORS,
	CATALOGUE_PLATFORMS_LOGO,
	MIN_PIPER_CREDITS_BALANCE,
	PIPER_CREDITS_BALANCE_LIMIT,
	ONBOARDING_FLOWS
} from "../client-config";
import { periscopeBizSubscriptionQuery, periscopeCheckUserWaitList, visitHistory } from "./periscope";
import { fetchCompareUserStatus } from "./analytics";

export const toggleGlobalLoader = (d) => ({
	type: "TOGGLE_GLOBAL_LOADER",
	payload: d
});

// auth user logout
export const handleAuthUserLogout = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: AUTH_USER_LOGOUT,
			variables
		});
		return resp.data.authServiceLogout;
	} catch (error) {
		console.log(error);
	}
};

// configItems: coupons
export const fetchCoupons = async (searchText) => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_COUPONS_LIST_REQUEST"
		});
		try {
			const resp = await client.query({
				query: GET_COUPONS_LIST,
				variables: {
					limit: 10,
					sort: {
						field: "title",
						order: "ASC"
					},
					filters: [
						{
							field: "title",
							value: searchText
						}
					]
				}
			});
			store.dispatch({
				type: "GET_COUPONS_LIST_SUCCESS",
				payload: resp.data.coupons.objects
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_COUPONS_LIST_FAILURE"
			});
		}
	}
};
export const fetchCouponsDebounced = debounce((searchText) => fetchCoupons(searchText), 300);

// configItems: items
export const fetchItems = async (searchText) => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_ITEMS_LIST_REQUEST"
		});
		try {
			const resp = await client.query({
				query: GET_ITEMS_LIST,
				variables: {
					limit: 50,
					sort: {
						field: "title",
						order: "ASC"
					},
					filters: [
						{
							field: "title",
							value: searchText
						}
					]
				}
			});
			store.dispatch({
				type: "GET_ITEMS_LIST_SUCCESS",
				payload: resp.data.items.objects
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_ITEMS_LIST_FAILURE"
			});
		}
	}
};
export const fetchItemsDebounced = debounce((searchText) => fetchItems(searchText), 300);

// configItems: stores
export const fetchStores = async (
	searchText,
	limit = 50,
	brandId = null,
	includeAll = false,
	locationIds = [],
	fetchPolicy = "cache-first"
) => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_STORES_LIST_REQUEST"
		});
		try {
			const variables = {
				limit: limit,
				sort: {
					field: "name",
					order: "ASC"
				}
			};
			if (brandId) {
				variables.brand = String(brandId);
			} else {
				variables.brand = null;
			}
			let filters = [
				{
					field: "is_active",
					value: "true"
				}
			];
			if (searchText) {
				filters.push({ field: "name", value: searchText });
			}
			if (locationIds.length > 0) {
				filters.push({
					field: "biz_location_id",
					value: String(locationIds.join(","))
				});
			}
			variables.filters = filters;
			const resp = await client.query({
				query: GET_STORES_LIST,
				variables,
				fetchPolicy
			});
			let stores = resp?.data?.stores?.objects || [];
			if (includeAll) {
				stores = [{ id: "all", name: "All Locations" }, ...stores];
			}
			store.dispatch({
				type: "GET_STORES_LIST_SUCCESS",
				payload: stores
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_STORES_LIST_FAILURE"
			});
		}
	}
};
export const fetchStoresDebounced = debounce(
	(searchText, limit, brandId, includeAll = false, locationIds, fetchPolicy) =>
		fetchStores(searchText, limit, brandId, includeAll, locationIds, fetchPolicy),
	300
);

// configItems: brands
export const fetchBrands = async (searchText, includeAll = false, limit = 50) => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_BRAND_LOCATIONS_LIST_REQUEST"
		});
		try {
			const variables = {
				limit: limit
			};
			let filters = [
				{
					field: "is_active",
					value: "true"
				}
			];
			variables.filters = filters;
			if (searchText) {
				variables.search = [{ key: "default", value: searchText }];
			}
			const resp = await client.query({
				query: GET_BRANDS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			let brands =
				resp?.data?.brands?.objects?.length > 0
					? resp.data.brands.objects.map((brand) => ({
							...brand,
							color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
					  }))
					: [];
			if (includeAll) {
				brands = [{ id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }, ...brands];
			}
			store.dispatch({
				type: "GET_BRAND_LOCATIONS_LIST_SUCCESS",
				payload: brands || []
			});
			return brands;
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_BRAND_LOCATIONS_LIST_FAILURE"
			});
		}
	}
};
export const fetchBrandsDebounced = debounce((searchText) => fetchBrands(searchText), 300);

// configItems: menus
export const fetchMenus = async (searchText, brandId = null, fetchPolicy = "cache-first") => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_CI_MENUS_LIST_REQUEST"
		});
		try {
			const variables = {
				limit: 50,
				offset: 0,
				brands: [],
				status: "active",
				publishStatus: "",
				searchKeyword: searchText
			};
			if (brandId) {
				variables.brands = [String(brandId)];
			}
			const resp = await clientMenu.query({
				query: GET_MENUS_LIST,
				variables,
				fetchPolicy
			});
			store.dispatch({
				type: "GET_CI_MENUS_LIST_SUCCESS",
				payload: resp?.data?.menus?.objects || []
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_CI_MENUS_LIST_FAILURE"
			});
		}
	}
};
export const fetchMenusDebounced = debounce(
	(searchText, brandId, fetchPolicy) => fetchMenus(searchText, brandId, fetchPolicy),
	300
);

// configItems: tags
export const fetchTags = async (searchText) => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_TAGS_LIST_REQUEST"
		});
		try {
			const variables = {
				limit: 50,
				offset: 0
			};
			if (searchText !== "") {
				variables.search = [
					{
						key: "name",
						value: searchText
					}
				];
			}
			const resp = await client.query({
				query: GET_TAGS_LIST,
				variables
			});
			store.dispatch({
				type: "GET_TAGS_LIST_SUCCESS",
				payload: resp.data.tags.objects
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_TAGS_LIST_FAILURE"
			});
		}
	}
};
export const fetchTagsDebounced = debounce((searchText) => fetchTags(searchText), 300);

// configItems: fulfillmentModes
export const fetchFulfillmentModes = async () => {
	store.dispatch({
		type: "GET_FULFILLMENT_MODES_LIST_REQUEST"
	});
	try {
		const variables = {
			limit: 50,
			offset: 0
		};
		const resp = await client.query({
			query: GET_FULFILLMENT_MODES_LIST,
			variables
		});
		store.dispatch({
			type: "GET_FULFILLMENT_MODES_LIST_SUCCESS",
			payload: resp.data.fulfillmentModes.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_FULFILLMENT_MODES_LIST_FAILURE"
		});
	}
};

// configItems: timing groups
export const fetchTimingGroups = async (searchText = "") => {
	store.dispatch({
		type: "GET_TIMING_LIST_REQUEST"
	});
	try {
		const variables = {
			limit: 50,
			offset: 0,
			sort: {
				field: "title",
				order: "ASC"
			},
			filters: [
				{
					field: "is_active",
					value: "true"
				}
			]
		};
		if (searchText) {
			variables.filters.push({
				field: "title",
				value: searchText
			});
		}
		const resp = await client.query({
			query: GET_TIMING_GROUPS_LIST,
			variables
		});
		store.dispatch({
			type: "GET_TIMING_LIST_SUCCESS",
			payload: resp.data.timingsGroup.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_TIMING_LIST_FAILURE"
		});
	}
};
export const fetchTimingGroupsDebounced = debounce((searchText) => fetchTimingGroups(searchText), 300);

// configItems: cities
export const fetchCities = async (searchText, includeAll = false) => {
	store.dispatch({
		type: "GET_CITIES_LIST_REQUEST"
	});
	try {
		const resp = await client.query({
			query: GET_CITIES_LIST
		});
		let allCities = [...resp.data.cities.objects];
		if (includeAll) {
			allCities.unshift({ value: "all", valueForDisplay: "All Cities", __typename: "CityType" });
		}
		store.dispatch({
			type: "GET_CITIES_LIST_SUCCESS",
			payload: allCities
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_CITIES_LIST_FAILURE"
		});
	}
};

// configItems: itemCategories
export const fetchItemCategories = async (includeAll = false) => {
	store.dispatch({
		type: "GET_ITEM_CATEGORIES_LIST_REQUEST"
	});
	try {
		const resp = await client.query({
			query: GET_ITEM_CATEGORIES,
			fetchPolicy: "no-cache"
		});
		let categories = resp?.data?.itemCategories || [];
		if (includeAll) {
			categories = [{ id: "all", name: "All" }, ...categories];
		}
		store.dispatch({
			type: "GET_ITEM_CATEGORIES_LIST_SUCCESS",
			payload: categories
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_ITEM_CATEGORIES_LIST_FAILURE"
		});
	}
};

// configItems: parentCategories
export const fetchParentCategories = async (subcategory) => {
	store.dispatch({
		type: "GET_PARENT_CATEGORIES_LIST_REQUEST"
	});
	try {
		const variables = {};
		if (subcategory) {
			variables.subcategory = subcategory;
		}
		const resp = await client.query({
			query: GET_PARENT_CATEGORIES,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "GET_PARENT_CATEGORIES_LIST_SUCCESS",
			payload: resp.data.parentCategories
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_PARENT_CATEGORIES_LIST_FAILURE"
		});
	}
};

export const fetchParentCategoryIds = async () => {
	store.dispatch({
		type: "GET_PARENT_CATEGORY_IDS_REQUEST"
	});
	try {
		const variables = {};
		const resp = await client.query({
			query: GET_PARENT_CATEGORY_IDS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "GET_PARENT_CATEGORY_IDS_SUCCESS",
			payload: resp.data.parentCategories
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_PARENT_CATEGORY_IDS_FAILURE"
		});
	}
};

export const resetParentCategories = () => {
	store.dispatch({
		type: "RESET_PARENT_CATEGORIES_LIST"
	});
};

// configItems: paymentModes
export const fetchPaymentModes = async () => {
	store.dispatch({
		type: "GET_PAYMENT_MODES_LIST_REQUEST"
	});
	try {
		const variables = {
			limit: 50,
			offset: 0
		};
		const resp = await client.query({
			query: GET_PAYMENT_MODES_LIST,
			variables
		});
		store.dispatch({
			type: "GET_PAYMENT_MODES_LIST_SUCCESS",
			payload: resp.data.paymentModes.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_PAYMENT_MODES_LIST_FAILURE"
		});
	}
};

// configItems: biz platforms
export const fetchBizPlatforms = async (
	includeUrbanpiper = false,
	showMeraki = false,
	includeAll = false,
	filters = []
) => {
	store.dispatch({
		type: "GET_BIZ_PLATFORMS_LIST_REQUEST"
	});
	try {
		const variables = {
			limit: 30,
			offset: 0,
			includeUrbanpiper: includeUrbanpiper,
			filters: [
				...filters,
				{
					field: "is_enabled",
					value: "true"
				}
			]
		};
		const resp = await client.query({
			query: GET_BIZ_PLATFORMS,
			variables,
			fetchPolicy: "no-cache"
		});
		let bizPlatforms = resp.data?.bizPlatforms?.objects || [];
		if (includeAll) {
			bizPlatforms = [{ id: "all", platformName: "All Platforms" }, ...bizPlatforms];
		}
		store.dispatch({
			type: "GET_BIZ_PLATFORMS_LIST_SUCCESS",
			payload: bizPlatforms.map((plf) => {
				if (showMeraki && plf.platformName.toLowerCase() === "urbanpiper") {
					plf.platformName = "Meraki";
				}
				// attach platform logos
				plf.image =
					plf?.logo ||
					CATALOGUE_PLATFORMS_LOGO[plf?.platformName?.toLowerCase()] ||
					"/assets/icons/icons8-globe-40.png";
				return plf;
			})
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_BIZ_PLATFORMS_LIST_FAILURE"
		});
	}
};

// configItems: itemGroups
export const fetchCiItemGroups = async (searchQuery = "", includesAll = undefined) => {
	store.dispatch({
		type: "GET_CI_ITEM_GROUPS_LIST_REQUEST"
	});
	try {
		const variables = {
			limit: 50,
			offset: 0,
			sort: {
				field: "title",
				order: "ASC"
			},
			filters: [
				{
					field: "title",
					value: searchQuery
				}
			]
		};
		if (includesAll !== undefined) {
			variables.filters.push({
				field: "includes_all",
				value: String(includesAll)
			});
		}
		const resp = await client.query({
			query: GET_CI_ITEM_GROUPS_LIST,
			variables
		});
		store.dispatch({
			type: "GET_CI_ITEM_GROUPS_LIST_SUCCESS",
			payload: resp.data.itemGroups.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_CI_ITEM_GROUPS_LIST_FAILURE"
		});
	}
};

export const fetchCiItemGroupsDebounced = debounce(
	(searchText, includesAll) => fetchCiItemGroups(searchText, includesAll),
	300
);

// configItems: locationGroups
export const fetchCiLocationGroups = async (searchQuery = "", includesAll = undefined) => {
	store.dispatch({
		type: "GET_CI_LOCATION_GROUPS_LIST_REQUEST"
	});
	try {
		const variables = {
			limit: 50,
			offset: 0,
			sort: {
				field: "title",
				order: "ASC"
			},
			filters: [
				{
					field: "title",
					value: searchQuery
				}
			]
		};
		if (includesAll !== undefined) {
			variables.filters.push({
				field: "includes_all",
				value: String(includesAll)
			});
		}
		const resp = await client.query({
			query: GET_CI_LOCATION_GROUPS_LIST,
			variables
		});
		store.dispatch({
			type: "GET_CI_LOCATION_GROUPS_LIST_SUCCESS",
			payload: resp.data.locationGroups.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_CI_LOCATION_GROUPS_LIST_FAILURE"
		});
	}
};

export const fetchCiLocationGroupsDebounced = debounce(
	(searchText, includesAll) => fetchCiLocationGroups(searchText, includesAll),
	300
);

// configItems: charges
export const fetchCiCharges = async () => {
	store.dispatch({
		type: "GET_CI_CHARGES_LIST_REQUEST"
	});
	try {
		const variables = {
			limit: 50,
			offset: 0,
			sort: {
				field: "title",
				order: "ASC"
			}
		};
		const resp = await client.query({
			query: GET_CI_CHARGES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "GET_CI_CHARGES_LIST_SUCCESS",
			payload: resp.data.charges.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_CI_CHARGES_LIST_FAILURE"
		});
	}
};

// configItems: modifierGroups
export const fetchCiModifierGroups = async (searchText = "") => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_CI_MODIFIER_GROUPS_LIST_REQUEST"
		});
		try {
			const variables = {
				limit: 100,
				offset: 0,
				filters: [
					{
						field: "is_enabled",
						value: "true"
					}
				]
			};
			if (searchText !== "") {
				variables.search = [
					{
						key: "default",
						value: searchText
					}
				];
			}
			const resp = await client.query({
				query: GET_CI_MODIFIER_GROUPS_LIST,
				variables
			});
			store.dispatch({
				type: "GET_CI_MODIFIER_GROUPS_LIST_SUCCESS",
				payload: resp.data.modifierGroups.objects.map((mg) => ({
					id: mg.id,
					optionGroupTitle: `${mg.optionGroupTitle} (${mg.crmTitle || mg.id})`
				}))
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_CI_MODIFIER_GROUPS_LIST_FAILURE"
			});
		}
	}
};
export const fetchCiModifierGroupsDebounced = debounce((searchText) => fetchCiModifierGroups(searchText), 300);

// configItems: modifiers
export const fetchCiModifiers = async (searchText = "") => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_CI_MODIFIERS_LIST_REQUEST"
		});
		try {
			const variables = {
				limit: 100,
				offset: 0,
				filters: [
					{
						field: "is_enabled",
						value: "true"
					}
				]
			};
			if (searchText !== "") {
				variables.search = [
					{
						key: "default",
						value: searchText
					}
				];
			}
			const resp = await client.query({
				query: GET_CI_MODIFIERS_LIST,
				variables
			});
			store.dispatch({
				type: "GET_CI_MODIFIERS_LIST_SUCCESS",
				payload: resp.data.modifiers.objects.map((mod) => ({
					...mod,
					optionCrmTitle: `${mod.optionTitle} (${mod.crmTitle || mod.id})`
				}))
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_CI_MODIFIERS_LIST_FAILURE"
			});
		}
	}
};
export const fetchCiModifiersDebounced = debounce((searchText) => fetchCiModifiers(searchText), 300);

// configItems: listGroups
export const fetchCiListGroups = async (
	opt = {
		api: false
	}
) => {
	store.dispatch({
		type: "GET_CI_LIST_GROUPS_REQUEST"
	});
	try {
		const variables = {
			limit: 100,
			offset: 0,
			api: opt.api
		};
		const resp = await client.query({
			query: GET_CI_LIST_GROUPS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "GET_CI_LIST_GROUPS_SUCCESS",
			payload: resp.data.groups.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_CI_LIST_GROUPS_FAILURE"
		});
	}
};

export const updateBizLogo = async () => {
	const biz = store.getState().login?.loggedInbizDetail;
	const bizLogo = biz?.logo;
	if (bizLogo) {
		// get logo's uploaded date
		const regex = /\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])*/g;
		const date = bizLogo.match(regex);
		if (date?.length > 0) {
			// check if logo's uploaded date is before a given date
			if (moment(moment(date?.[0]).format("YYYY-MM-DD")).isBefore("2023-11-07")) {
				await fetch(bizLogo.includes("https://") ? bizLogo : bizLogo.replace("http://", "https://"), {
					method: "GET",
					cache: "no-cache"
				})
					.then((resp) => resp.blob())
					.then(async (blob) => {
						// create a file from blob with name and type
						const imgFile = new File([blob], bizLogo.replace(/^.*[\\\/]/, ""), {
							type: blob.type
						});
						// get image formats to support printer compatibility
						const { base64Image, starLineImage, escPosImage } = await getPrinterCompatibleImages(imgFile);
						if (base64Image && starLineImage && escPosImage) {
							try {
								store.dispatch(toggleGlobalLoader(true));
								const galleryVariables = {
									image: imgFile
								};
								const galleryResp = await client.mutate({
									mutation: UPLOAD_IMAGE,
									variables: galleryVariables
								});
								store.dispatch(toggleGlobalLoader(false));
								let imageUrl = galleryResp.data.uploadImage.image.replace("http://", "https://");
								let urlToBeSaved = imageUrl.match(/(gallery_images).*/g);
								if (urlToBeSaved.length > 0) {
									const bizVariables = {
										id: biz.id,
										logo: urlToBeSaved[0],
										base64Image,
										starLineImage,
										escPosImage
									};
									await updateGetAndSetBizInfo(bizVariables, false, true);
								}
							} catch (error) {
								store.dispatch(toggleGlobalLoader(false));
								store.dispatch({
									type: ActionTypes.SHOW_GLOBAL_MESSAGE,
									payload: {
										message: error.message || "Image upload failed. Try again.",
										timeout: 5000,
										error: true
									}
								});
							}
						} else {
							store.dispatch({
								type: "SHOW_GLOBAL_MESSAGE",
								payload: {
									message: "Oops, something went wrong. Unable to update Biz Logo",
									timeout: 3000,
									error: true
								}
							});
						}
					})
					.catch((error) => {
						console.log(error);
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Something went wrong. Unable to fetch Biz Logo",
								timeout: 3000,
								error: true
							}
						});
					});
			}
		}
	}
};

export const getLowPiperCreditsBalanceAlert = (creditBalance) => {
	const alert = {
		topic: "low_piper_credits_balance",
		icon: "",
		message: "",
		creditBalance,
		links: [
			{
				text: "Recharge Now",
				url: "/recharge",
				type: "page-redirect",
				icon: "arrow-right"
			}
		],
		classes: "",
		isDismissible: true,
		trackEvent: false
	};
	if (creditBalance <= -2000) {
		return {
			...alert,
			icon: "triangle-alert",
			message:
				"Alert: Your account has reached the -2000 Piper credits limit, resulting in the suspension of all SMS services. To reactivate services, please recharge immediately.",
			classes: "error",
			isDismissible: false
		};
	}
	if (creditBalance < 0) {
		return {
			...alert,
			icon: "triangle-alert",
			message: `Your Piper credits balance is ${creditBalance}. Essential services like OTP delivery and order updates will continue until you reach a 2000 credit deficit.`,
			classes: "error"
		};
	}
	if (creditBalance === 0) {
		return {
			...alert,
			icon: "triangle-alert",
			message:
				"You are out of Piper credits. Recharge today to continue sending E-bill and promotional campaigns.",
			classes: "error"
		};
	}
	if (creditBalance < 200) {
		return {
			...alert,
			icon: "info",
			message: `Only ${creditBalance} Piper credits left. Recharge today to continue sending important updates to your customers.`,
			classes: "warning"
		};
	}
};

export const setCustomBannerMessage = (biz = {}, loginDetail = {}) => {
	// show/hide banner
	let showCustomBannerMessage = false;
	const defaultBannerMessageDismissed = { state: false, timeStamp: null };
	let isBannerMessageDismissed = lS.get("isBannerMessageDismissed") || defaultBannerMessageDismissed;
	let banner = lS.get("banner") || {};
	let updatedBanner = {};

	// show important biz level alerts/notifications regarding any scheduled restrictions on actions like menu publish
	if (biz.scheduledActionsAndNotifications?.notifications?.length > 0) {
		const notification = biz.scheduledActionsAndNotifications?.notifications?.[0];
		showCustomBannerMessage = notification?.message ? notification?.applicable : false;
		isBannerMessageDismissed =
			lS.get("isBannerMessageDismissed") === undefined ||
			banner?.topic !== notification?.action ||
			banner?.message !== notification?.message
				? defaultBannerMessageDismissed
				: lS.get("isBannerMessageDismissed");
		updatedBanner = {
			topic: notification?.action,
			icon: "info",
			message: notification?.message,
			classes: notification?.type === "info" ? "default" : notification?.type,
			isDismissible: notification?.dismissible,
			trackEvent: false
		};
	}

	// check for low piper credits balance
	else if (
		(biz.credits < MIN_PIPER_CREDITS_BALANCE && (loginDetail?.access?.isAdmin || loginDetail?.access?.isBilling)) ||
		biz.credits <= PIPER_CREDITS_BALANCE_LIMIT
	) {
		const creditCheckpoints = [0, -1000, -1500];

		// update banner
		updatedBanner = getLowPiperCreditsBalanceAlert(biz.credits);

		// display banner if it's not dismissed or after 24 hours from when it was dismissed
		if (
			!isBannerMessageDismissed?.state ||
			(isBannerMessageDismissed?.timeStamp &&
				moment.duration(moment().diff(isBannerMessageDismissed?.timeStamp))?.asHours() >= 24)
		) {
			showCustomBannerMessage = true;
			isBannerMessageDismissed = defaultBannerMessageDismissed;
		}
		// check if piper credits balance changed and crossed any checkpoints. If yes, show banner even if it was dismissed
		else if (banner?.creditBalance !== undefined && biz.credits !== banner?.creditBalance) {
			let i = 0;
			while (i < creditCheckpoints.length) {
				if (banner?.creditBalance > creditCheckpoints[i] && biz.credits <= creditCheckpoints[i]) {
					showCustomBannerMessage = true;
					isBannerMessageDismissed = defaultBannerMessageDismissed;
				}
				i += 1;
			}
		}
	}

	// update local storage
	lS.set("showCustomBannerMessage", showCustomBannerMessage);
	lS.set("isBannerMessageDismissed", isBannerMessageDismissed);
	lS.set("banner", updatedBanner);

	// update store
	store.dispatch({
		type: ActionTypes.UPDATE_LOGIN_STATE,
		payload: {
			showCustomBannerMessage,
			isBannerMessageDismissed
		}
	});
	store.dispatch({
		type: ActionTypes.UPDATE_BANNER_STATE,
		payload: { ...updatedBanner }
	});
};

// update biz state
export const updateBizState = async (login) => {
	const biz = await getAndSetBizInfo();
	// set/update banner
	setCustomBannerMessage(biz, login?.loginDetail);
	await fetchMerakiWebSubscriptionDetails();

	// close any existing websocket connection
	if (window?.webSocket) {
		window.webSocket.forceClose = true;
		window.webSocket.close();
	}

	// check catalogue backup status and initialize websocket connection
	if (isLoggedin()) {
		const bizId = login?.loggedInbizDetail?.id;
		fetchNotifications(parseInt(bizId));
		fetchPeriscopeRefreshStatus(bizId);
		fetchCompareUserStatus({ bizId: String(bizId) });

		// check periscope biz subscription status
		periscopeBizSubscriptionQuery({ bizId: String(bizId) });
		periscopeCheckUserWaitList({ bizId: String(bizId) });
		visitHistory({ bizId: String(bizId) }); // periscope-coach-mark

		// update notifications state, showing 10 latest notifications only
		const notifications = lS.get("notifications") ? (lS.get("notifications")?.[bizId] || [])?.slice(0, 10) : [];
		let count = 0;
		notifications.forEach((notification) => {
			if (!notification?.isRead && !notification?.hide) {
				count += 1;
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_NOTIFICATIONS_LIST,
			payload: {
				objects: notifications,
				count
			}
		});
		// check for catalogue backup status
		await fetchBackupsStatus();

		// check periscope biz subdcription status
		periscopeBizSubscriptionQuery({ bizId: String(bizId) });
		periscopeCheckUserWaitList({ bizId: String(bizId) });

		// // start subscription for onboarding flow steps if data is available, and redirect to onboarding page
		// const atlasOnboardingState = store.getState()?.atlasOnboardingState;
		// if (atlasOnboardingState?.flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW) {
		// 	subscribeToFlowSteps();

		// 	// if user is not already on onboarding, redirect to onboarding
		// 	if (!window?.location?.pathname.includes("/onboarding")) {
		// 		history.push("/onboarding");
		// 	}
		// }

		// // start subscription for onboarding flow steps if data is available, and redirect to onboarding page
		// if (!["/login", "/temp-login", "/auth-service"]?.includes(window?.location?.pathname) &&
		// 	((this.props.login?.loginDetail?.success) ||
		// 	(this.props.login?.loggedInbizDetail?.id !== prevProps?.login?.loggedInbizDetail?.id)) &&
		// 	(this.props.atlasOnboardingflowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW)) {
		// 	subscribeToFlowSteps();

		// 	// if user is not already on onboarding, redirect to onboarding
		// 	if (
		// 		this.props.atlasOnboardingflowData?.name !== prevProps.atlasOnboardingflowData?.name &&
		// 		!window?.location?.pathname.includes("/onboarding")
		// 	) {
		// 		history.push("/onboarding");
		// 	}
		// }
	}

	// initialize the Headway widget
	initializeHeadway();
};

export const fetchTranslation = async (entityId, entityType, field, language) => {
	store.dispatch({
		type: "TRANSLATION_REQUEST",
		payload: field
	});
	try {
		const variables = {
			entityId,
			entityType,
			field,
			language
		};
		const resp = await client.query({
			query: GET_TRANSLATION,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "GET_TRANSLATION_SUCCESS"
		});
		return resp.data.translation;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "TRANSLATION_FAILURE"
		});
	}
};

export const updateTranslation = async (entityId, entityType, field, language, value) => {
	store.dispatch({
		type: "TRANSLATION_REQUEST",
		payload: field
	});
	try {
		const variables = {
			entityId,
			entityType,
			field,
			language,
			value
		};
		const resp = await client.mutate({
			mutation: UPDATE_TRANSLATION,
			variables
		});
		if (resp.data.saveTranslation.status.success) {
			store.dispatch({
				type: "UPDATE_TRANSLATION_SUCCESS",
				payload: field
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Translation for the selected language is saved!",
					timeout: 3000,
					error: false
				}
			});
			return { status: true, error: {} };
		} else {
			// handle error message
			store.dispatch({
				type: "TRANSLATION_FAILURE"
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "There was an error while saving the translation",
					timeout: 3000,
					error: true
				}
			});
			return { status: false, error: parseErrorMessages(resp.data.saveTranslation.status.messages) };
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "TRANSLATION_FAILURE"
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

// date filter label
export const getDateFilterLabel = (currentDateFilter) => {
	let dateFilterLabel = "";
	const dates = currentDateFilter.dateFilter.split(",");
	if (dates.length === 1) {
		dateFilterLabel = PRESET_TYPES.find((pt) => pt.value === currentDateFilter.dateFilter).label;
	} else {
		const start = dates[0];
		const end = dates[1];
		if (start && end) {
			const startDateFormat = moment(start, "YYYY-MM-DD").format("DD MMM, YYYY");
			const endDateFormat = moment(end, "YYYY-MM-DD").format("DD MMM, YYYY");
			dateFilterLabel = `${startDateFormat} — ${endDateFormat}`;
		} else if (start && !end) {
			const startDateFormat = moment(start, "YYYY-MM-DD").format("DD MMM, YYYY");
			dateFilterLabel = `After ${startDateFormat}`;
		} else if (!start && end) {
			const endDateFormat = moment(end, "YYYY-MM-DD").format("DD MMM, YYYY");
			dateFilterLabel = `Before ${endDateFormat}`;
		}
	}
	return dateFilterLabel;
};

export const getDateCompareFilterLabel = (currentDateFilter) => {
	let dateFilterLabel = "";
	const dates = currentDateFilter.current.dateFilter.split(",");
	if (dates.length === 1) {
		dateFilterLabel = PRESET_TYPES.find((pt) => pt.value === currentDateFilter.current.dateFilter).label;
	} else {
		const start = dates[0];
		const end = dates[1];
		if (start && end) {
			const startDateFormat = moment(start, "YYYY-MM-DD").format("DD MMM, YYYY");
			const endDateFormat = moment(end, "YYYY-MM-DD").format("DD MMM, YYYY");
			dateFilterLabel = `${startDateFormat} — ${endDateFormat}`;
		} else if (start && !end) {
			const startDateFormat = moment(start, "YYYY-MM-DD").format("DD MMM, YYYY");
			dateFilterLabel = `After ${startDateFormat}`;
		} else if (!start && end) {
			const endDateFormat = moment(end, "YYYY-MM-DD").format("DD MMM, YYYY");
			dateFilterLabel = `Before ${endDateFormat}`;
		}
	}
	return dateFilterLabel;
};

export const fetchBackupsStatus = async (action) => {
	const data = await fetchCatalogueBackupsStatus(action);
	// if (data?.inProcess) {
	// 	setTimeout(() => {
	// 		fetchBackupsStatus(data.status === 'RESTORING' ? 'restore' : 'create');
	// 	}, action === 'restore' ? 120000 : 60000);
	// }
};

export const handleBulkExportDownload = async (modelName, emails, entity) => {
	try {
		const token = store.getState().login.loginDetail.token;
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		};
		const res = await fetch(
			`${process.env.REACT_APP_API_URL_CONFIG_SHEET}?model-name=${modelName}&emails=${emails}`,
			{ headers }
		);
		const resp = await res.json();
		if (resp.status === "success") {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: `${entity} config-sheet generation is queued and will be emailed to the recipient(s) shortly.`,
					timeout: 3000,
					error: false
				}
			});
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 2000,
					error: true
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const downloadTemplate = async (modelName) => {
	const auth = lS.get("auth");
	const headers = { "Content-Type": "application/json", Authorization: auth && `Bearer ${auth.token}` };
	await fetch(
		`${process.env.REACT_APP_API_URL.replace("graphql", "api")}/download?type=config-sheet&model-name=${modelName}`,
		{ headers }
	)
		.then((res) => res.blob())
		.then((res) => {
			// set the blob type to final csv
			const file = new Blob([res], { type: "text/csv" });
			// process to auto download it
			const fileURL = URL.createObjectURL(file);
			const link = document.createElement("a");
			link.href = fileURL;
			try {
				link.download = res.headers.get("Content-Disposition").split("=")[1];
			} catch (error) {
				console.log(error);
				link.download = `${modelName}-template.csv`;
			}
			link.click();
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Template downloaded successfully.",
					timeout: 2000,
					error: false
				}
			});
		})
		.catch((err) => {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Downloading template failed. Kindly Retry",
					timeout: 2000,
					error: true
				}
			});
		});
};

export const initializeHeadway = () => {
	// see https://docs.headwayapp.co/widget for more configuration options.
	const config = {
		selector: ".meraki-changelog", // CSS selector where to inject the badge
		account: "x9bqE7"
	};
	if (window.Headway) {
		window.Headway.init(config);
	}
};

// fetch associated brand locations
export const fetchAssociatedBrandLocations = async (brandId, searchText = "", limit = 50, offset = 0) => {
	try {
		store.dispatch({
			type: "GET_ASSOCIATED_BRAND_LOCATION_REQUEST"
		});
		const variables = {};
		variables.brand = String(brandId);
		variables.limit = limit;
		variables.offset = offset;
		variables.filters = [
			{
				field: "is_active",
				value: "true"
			}
		];
		variables.sort = { field: "name", order: "ASC" };
		variables.search = [
			{
				key: "default",
				value: searchText
			}
		];
		const resp = await client.query({
			query: GET_ASSOCIATED_BRAND_SPECIFIC_LOCATIONS_NAME,
			variables,
			fetchPolicy: "no-cache"
		});
		if (resp.data.stores.objects) {
			store.dispatch({
				type: "GET_ASSOCIATED_BRAND_LOCATION_SUCCESS",
				payload: resp.data.stores.objects
			});
		} else {
			store.dispatch({
				type: "GET_ASSOCIATED_BRAND_LOCATION_FAILURE"
			});
		}
	} catch (e) {
		console.log(e);
		store.dispatch({
			type: "GET_ASSOCIATED_BRAND_LOCATION_FAILURE"
		});
	}
};
export const fetchAssociatedBrandLocationsDebounced = debounce(
	(brandId, searchText = "", limit = 50, offset = 0) =>
		fetchAssociatedBrandLocations(brandId, searchText, limit, offset),
	300
);

export const handleOnboardingFlowAction = async (action) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(process.env.REACT_APP_API_ONBOARDING_ACTION, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ action })
		});
		if (resp.ok) {
			store.dispatch(toggleGlobalLoader(false));
			const respJson = await resp.json();
			return respJson;
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
