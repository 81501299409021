import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { CategoriesList } from "./CategoriesList";
import CategoryCreate from "./CategoryCreate";
import CategoryEdit from "./CategoryEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/CategoriesList/Header";

const CategoriesContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="categories-container">
			{restrictView ? (
				<Route
					path="/categories"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/categories" component={CategoriesList} />
					<Route
						exact
						path="/categories/new"
						render={() =>
							hasAccess ? <CategoryCreate hasAccess={hasAccess} /> : <Redirect to="/categories" />
						}
					/>
					<Route exact path="/categories/edit/:id" component={CategoryEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default CategoriesContainer;
