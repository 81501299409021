// constants
import { ONLINE_TRANSACTIONS_LIST_SEARCH_FIELDS } from "../client-config";

const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: "TODAY",
		dateTypeSelected: { label: "Preset", value: "preset_duration" },
		presetTypeSelected: { label: "Today", title: "Today", value: "TODAY" },
		customTypeSelected: { label: "Between", value: "between" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: { label: "Not specified", value: "not_specified" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "id",
		order: "DESC"
	},
	sortedField: "id",
	searchKW: "",
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE,
	dateTypeSelected: { label: "Preset", value: "preset_duration" },
	presetTypeSelected: { label: "Today", value: "TODAY" },
	customTypeSelected: { label: "Is between", value: "between" },
	rangeStartDate: undefined,
	rangeEndDate: undefined,
	currentFilters: {},
	appliedFilters: {},
	searchFieldSelected: ONLINE_TRANSACTIONS_LIST_SEARCH_FIELDS[0]
};
export const transactionsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "TRANSACTIONS_LIST_STATE_CHANGE":
			return {
				...state,
				...action.payload
			};
		case "TRANSACTIONS_LIST_STATE_CHANGE_SORT":
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case "RESET_TRANSACTIONS_LIST_STATE":
			return INITIAL_STATE;
		default:
			return state;
	}
};

const getSortOrder = (currSort, newSort) => {
	return currSort.order === "" || currSort.field !== newSort.field
		? "DESC"
		: currSort.order === "ASC"
		? "DESC"
		: "ASC";
};

const TRANSACTIONS_LIST_INITIAL_STATE = {
	loading: true,
	data: {
		objects: [],
		filters: []
	},
	error: false
};
export const transactionsList = (state = TRANSACTIONS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case "GET_TRANSACTIONS_LIST_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "GET_TRANSACTIONS_LIST_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "GET_TRANSACTIONS_LIST_FAILURE":
			return {
				...state,
				loading: false,
				error: action.error
			};
		case "RESET_TRANSACTIONS_LIST":
			return TRANSACTIONS_LIST_INITIAL_STATE;

		case "UPDATE_TRANSACTION_LIST":
			const updatedList = state?.data?.objects?.map((transaction) => {
				if (transaction?.id === action?.payload?.orderId) {
					transaction.status = action.payload?.status;
				}
				return transaction;
			});
			return {
				...state,
				data: {
					...state?.data,
					objects: updatedList || []
				}
			};
		default:
			return state;
	}
};

export const transactionDetails = (
	state = {
		loading: true,
		data: null,
		error: false
	},
	action
) => {
	switch (action.type) {
		case "GET_TRANSACTION_DETAILS_REQUEST":
			return {
				...state,
				loading: true,
				data: null,
				error: false
			};
		case "GET_TRANSACTION_DETAILS_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "GET_TRANSACTION_DETAILS_FAILURE":
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
