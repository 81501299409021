import React, { useState, useCallback, useEffect } from "react";

// component
import { SelectFilter } from "../_commons/SelectFilter";
import { FormSidebar } from "../_commons/FormSidebar";

// third party
import { connect } from "react-redux";

// utils
import { client } from "../../client";
import { getTrackingSource, msaagesArrayToHtml, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { UPDATE_ENTITY_ILGPS } from "../../graphql/ilgpsEntityAssociation";

// actions
import {
	fetchCiItemGroups,
	fetchCiItemGroupsDebounced,
	fetchCiLocationGroups,
	fetchCiLocationGroupsDebounced
} from "../../actions/actions";
import { ActionTypes } from "../../actions/_types";

// constants
import { TRACKING_STATUS, TRACKING_TYPE } from "../../client-config";
const PAIR_INITIAL_STATE = {
	itemGroup: undefined,
	locationGroup: undefined
};

const ILGPSEntityAssociations = ({ entityType, entityId, isOpen, close, itemGroups, locationGroups }) => {
	const [currPair, setCurrPair] = useState(PAIR_INITIAL_STATE);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [pairs, setPairs] = useState([]);

	const setFilter = (field, value) => {
		setCurrPair({
			...currPair,
			[field]: value
		});
	};

	const addPair = () => {
		if (!currPair.itemGroup || !currPair.locationGroup) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please select both Item and Location Group to create a pair",
					timeout: 2000,
					error: true
				}
			});
			return;
		}
		setPairs([...pairs, currPair]);
		setCurrPair(PAIR_INITIAL_STATE);
	};

	const updatePair = (field, value, index) => {
		const updatedPairs = pairs.map((pair, i) => {
			if (i === index) {
				return {
					...pair,
					[field]: value
				};
			}
			return pair;
		});
		setPairs(updatedPairs);
	};

	const deletePair = (index) => {
		setPairs(pairs.filter((pair, i) => i !== index));
	};

	useEffect(() => {
		fetchCiItemGroups();
		fetchCiLocationGroups();
	}, []);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setCurrPair(PAIR_INITIAL_STATE);
			setPairs([]);
			close(refresh);
		},
		[close]
	);

	const isValid = useCallback((currPair, pairs) => {
		if (currPair.itemGroup && currPair.locationGroup) {
			return true;
		}
		if (pairs.length === 0) {
			if (!currPair.itemGroup || !currPair.locationGroup) {
				return false;
			} else {
				return true;
			}
		} else {
			if (!currPair.itemGroup && !currPair.locationGroup) {
				return true;
			} else {
				return false;
			}
		}
	}, []);

	const getIlgps = useCallback((currPair, pairs) => {
		let ilgps = pairs.map((pair) => ({
			itemGroupId: parseInt(pair.itemGroup.id),
			locationGroupId: parseInt(pair.locationGroup.id)
		}));
		if (currPair.itemGroup && currPair.locationGroup) {
			ilgps.push({
				itemGroupId: parseInt(currPair.itemGroup.id),
				locationGroupId: parseInt(currPair.locationGroup.id)
			});
		}
		return ilgps;
	}, []);

	const handleSubmit = useCallback(async () => {
		const eventMeta = {
			source: getTrackingSource(),
			type: TRACKING_TYPE.NEW_PAIR_CREATED
		};
		if (!isValid(currPair, pairs)) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please select valid Item-Location Group pair",
					timeout: 2000,
					error: true
				}
			});
			return;
		}
		try {
			setConfirmLoading(true);
			const variables = {
				entityId,
				entityType: entityType.value,
				ilgps: getIlgps(currPair, pairs)
			};
			const resp = await client.mutate({
				mutation: UPDATE_ENTITY_ILGPS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateEntityIlgps.status.success) {
				if (entityType.event) {
					eventMeta.status = TRACKING_STATUS.SUCCESS;
					trackEvent(entityType.event, eventMeta);
				}

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Item-Location Group pair associated successfully!",
						timeout: 2000,
						error: false
					}
				});
				handleClose(true);
			} else {
				if (entityType.event) {
					eventMeta.status = TRACKING_STATUS.FAILURE;
					trackEvent(entityType.event, eventMeta);
				}

				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateEntityIlgps.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			if (entityType.event) {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(entityType.event, eventMeta);
			}

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [entityId, entityType, pairs, handleClose, currPair, getIlgps, isValid]);

	return (
		<div className="ilgps-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSubmit}
				title="Associate new Pair(s)"
				subTitle="Select Item and Location groups to pair them together"
				submitTitle="Add"
				loading={confirmLoading}
				isNested={true}
			>
				<div className="form-content">
					<div className="pair-input-container">
						<SelectFilter
							title="Item Group"
							options={itemGroups.items}
							isLoading={itemGroups.isLoading}
							field="itemGroup"
							currValue={currPair.itemGroup}
							setFilter={setFilter}
							labelKey="title"
							valueKey="id"
							placeholder="Select"
							isAsync={true}
							handleSearch={fetchCiItemGroupsDebounced}
						/>
						<SelectFilter
							title="Location Group"
							options={locationGroups.items}
							isLoading={locationGroups.isLoading}
							field="locationGroup"
							currValue={currPair.locationGroup}
							setFilter={setFilter}
							labelKey="title"
							valueKey="id"
							placeholder="Select"
							isAsync={true}
							handleSearch={fetchCiLocationGroupsDebounced}
						/>
						<div className="action-icon">
							<img onClick={addPair} src="/assets/icons/icon-upload.svg" />
						</div>
					</div>
					{pairs.map((pair, i) => (
						<ILGPair
							index={i}
							itemGroups={itemGroups}
							locationGroups={locationGroups}
							updatePair={updatePair}
							deletePair={deletePair}
							pair={pair}
						/>
					))}
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	itemGroups: store.configItems.itemGroups,
	locationGroups: store.configItems.locationGroups
}))(ILGPSEntityAssociations);

const ILGPair = ({ index, pair, itemGroups, locationGroups, updatePair, deletePair }) => {
	return (
		<div className="pair-input-container">
			<SelectFilter
				title="Item Group"
				options={itemGroups.items}
				isLoading={itemGroups.isLoading}
				field="itemGroup"
				currValue={pair.itemGroup}
				setFilter={(field, value) => updatePair(field, value, index)}
				isClearable={false}
				labelKey="title"
				valueKey="id"
				placeholder="Select"
			/>
			<SelectFilter
				title="Location Group"
				options={locationGroups.items}
				isLoading={locationGroups.isLoading}
				field="locationGroup"
				currValue={pair.locationGroup}
				setFilter={(field, value) => updatePair(field, value, index)}
				isClearable={false}
				labelKey="title"
				valueKey="id"
				placeholder="Select"
			/>
			<div className="action-icon">
				<img onClick={() => deletePair(index)} src="/assets/icons/delete.svg" />
			</div>
		</div>
	);
};
