// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// graphql
import {
	GET_CUSTOMER,
	UPDATE_CUSTOMER,
	GET_CUSTOMER_FEEDBACKS,
	FEEDBACK_REPLY,
	GET_CUSTOMER_NOTES,
	UPDATE_CUSTOMER_NOTE,
	DELETE_CUSTOMER_NOTE,
	GET_CUSTOMER_TIMELINE,
	UPDATE_WALLET_BALANCE,
	GET_CUSTOMERS_LIST
} from "../graphql/customers";

// actions
import { ActionTypes } from "./_types";
import { toggleGlobalLoader } from "./actions";

export const fetchCustomer = async (id, fetchPolicy = "no-cache") => {
	store.dispatch({
		type: ActionTypes.GET_CUSTOMER_DETAILS_REQUEST
	});
	try {
		const resp = await client.query({
			query: GET_CUSTOMER,
			variables: {
				id
			},
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS,
			payload: resp.data.customer
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_DETAILS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateCustomer = async (variables, fetchPolicy = "no-cache") => {
	const customerDetails = store.getState().customerDetails.data;
	let tempCustomerDetails = {};
	if (variables.tags) {
		tempCustomerDetails = {
			...customerDetails,
			tags: customerDetails.tags.slice().concat({
				id: "tempId",
				name: variables.tags[0]
			})
		};
	} else {
		tempCustomerDetails = {
			...customerDetails,
			...variables
		};
	}
	store.dispatch({
		type: ActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST,
		payload: tempCustomerDetails
	});
	try {
		const resp = await client.mutate({
			mutation: UPDATE_CUSTOMER,
			variables,
			fetchPolicy
		});
		if (resp.data.customer && resp.data.customer.status.success) {
			store.dispatch({
				type: ActionTypes.UPDATE_CUSTOMER_DETAILS_SUCCESS,
				payload: {
					...customerDetails,
					...resp.data.customer.obj
				}
			});
		} else {
			updateCustomerFailure(
				undefined,
				resp.data.customer ? resp.data.customer.status.messages : [],
				customerDetails
			);
		}
	} catch (error) {
		console.log(error);
		updateCustomerFailure(error, undefined, customerDetails);
	}
};

const updateCustomerFailure = (error, messages, customerDetails) => {
	store.dispatch({
		type: ActionTypes.UPDATE_CUSTOMER_DETAILS_FAILURE,
		payload: customerDetails
	});
	const errorMessage = error ? error.message : msaagesArrayToHtml(messages || []);
	store.dispatch({
		type: ActionTypes.SHOW_GLOBAL_MESSAGE,
		payload: {
			message: errorMessage || "Something went wrong.",
			timeout: 5000,
			error: true,
			errObject: error
		}
	});
};

export const fetchCustomerFeedbacks = async (customerId, fetchPolicy = "no-cache") => {
	store.dispatch({
		type: ActionTypes.GET_CUSTOMER_FEEDBACKS_REQUEST
	});
	try {
		const { limit, offset, sort } = store.getState().customerFeedbacks;
		const variables = {
			customerId,
			limit,
			offset,
			sort
		};
		const resp = await client.query({
			query: GET_CUSTOMER_FEEDBACKS,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_FEEDBACKS_SUCCESS,
			payload: resp.data.customerFeedbacks
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_FEEDBACKS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateFeedbacksListState = (payload) => {
	store.dispatch({
		type: ActionTypes.UPDATE_CUSTOMER_FEEDBACKS_LIST,
		payload
	});
};

export const feedbackReply = async (variables, fetchPolicy = "no-cache") => {
	try {
		const resp = await client.mutate({
			mutation: FEEDBACK_REPLY,
			variables,
			fetchPolicy
		});
		if (resp.data.feedbackReply && resp.data.feedbackReply.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Feedback reply sent!",
					timeout: 5000,
					error: false
				}
			});
		} else {
			feedbackReplyFailure(undefined, resp.data.feedbackReply ? resp.data.feedbackReply.status.messages : []);
		}
	} catch (error) {
		console.log(error);
		feedbackReplyFailure(error);
	}
};

const feedbackReplyFailure = (error, messages) => {
	const errorMessage = error ? error.message : msaagesArrayToHtml(messages || []);
	store.dispatch({
		type: ActionTypes.SHOW_GLOBAL_MESSAGE,
		payload: {
			message: errorMessage || "Something went wrong.",
			timeout: 5000,
			error: true,
			errObject: error
		}
	});
};

export const fetchCustomerNotes = async (id, fetchPolicy = "cache-first") => {
	store.dispatch({
		type: ActionTypes.GET_CUSTOMER_NOTES_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_CUSTOMER_NOTES,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_NOTES_SUCCESS,
			payload: resp.data.customer.notes
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_NOTES_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateCustomerNote = async (variables, type, fetchPolicy = "no-cache") => {
	store.dispatch({
		type: ActionTypes.UPDATE_CUSTOMER_NOTES_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: UPDATE_CUSTOMER_NOTE,
			variables,
			fetchPolicy,
			refetchQueries: [
				{
					query: GET_CUSTOMER_NOTES,
					variables: {
						id: variables.id
					}
				}
			]
		});
		if (resp.data.customer && resp.data.customer.status.success) {
			let successMessage = "";
			if (type === "create") {
				successMessage = "Note created successfully!";
			} else if (type === "edit") {
				successMessage = "Note edited successfully!";
			}
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: successMessage,
					timeout: 5000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_CUSTOMER_NOTES_SUCCESS,
				payload: resp.data.customer.obj.notes
			});
		} else {
			updateCustomerNoteFailure(undefined, resp.data.customer ? resp.data.customer.status.messages : []);
		}
	} catch (error) {
		console.log(error);
		updateCustomerNoteFailure(error, undefined);
	}
};

const updateCustomerNoteFailure = (error, messages) => {
	const errorMessage = error ? error.message : msaagesArrayToHtml(messages || []);
	store.dispatch({
		type: ActionTypes.SHOW_GLOBAL_MESSAGE,
		payload: {
			message: errorMessage || "Something went wrong.",
			timeout: 5000,
			error: true,
			errObject: error
		}
	});
};

export const deleteCustomerNote = async (variables, customerId, fetchPolicy = "no-cache") => {
	store.dispatch({
		type: ActionTypes.UPDATE_CUSTOMER_NOTES_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: DELETE_CUSTOMER_NOTE,
			variables,
			fetchPolicy,
			refetchQueries: [
				{
					query: GET_CUSTOMER_NOTES,
					variables: {
						id: customerId
					}
				}
			]
		});
		if (resp.data.deleteCustomerNote && resp.data.deleteCustomerNote.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Note deleted successfully",
					timeout: 5000,
					error: false
				}
			});
		} else {
			deleteCustomerNoteFailure(
				undefined,
				resp.data.deleteCustomerNote ? resp.data.deleteCustomerNote.status.messages : []
			);
		}
	} catch (error) {
		console.log(error);
		deleteCustomerNoteFailure(error, undefined);
	}
};

const deleteCustomerNoteFailure = (error, messages) => {
	const errorMessage = error ? error.message : msaagesArrayToHtml(messages || []);
	store.dispatch({
		type: ActionTypes.SHOW_GLOBAL_MESSAGE,
		payload: {
			message: errorMessage || "Something went wrong.",
			timeout: 5000,
			error: true,
			errObject: error
		}
	});
};

export const fetchCustomerTimeline = async (id, fetchPolicy = "cache-first") => {
	store.dispatch({
		type: ActionTypes.GET_CUSTOMER_TIMELINE_REQUEST
	});
	try {
		const { limit, offset } = store.getState().customerTimeline;
		const variables = {
			id,
			limit,
			offset
		};
		const resp = await client.query({
			query: GET_CUSTOMER_TIMELINE,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_TIMELINE_SUCCESS,
			payload: resp.data.customer.timeline
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_TIMELINE_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateTimelineListState = (payload) => {
	store.dispatch({
		type: ActionTypes.UPDATE_CUSTOMER_TIMELINE_LIST_STATE,
		payload
	});
};

export const updateWalletBalance = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: UPDATE_WALLET_BALANCE,
			variables
		});
		if (resp.data.reloadWallet.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Wallet balance updated!",
					timeout: 3000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			if (
				resp.data.reloadWallet.status.messages.length &&
				resp.data.reloadWallet.status.messages[0].field === null
			) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.reloadWallet.status.messages[0].message,
						timeout: 3000,
						error: true
					}
				});
			} else {
				return parseErrorMessages(resp.data.reloadWallet.status.messages);
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCustomersList = async () => {
	const { limit, offset, sort, appliedDateFilter, searchKW, appliedFilters, searchFieldSelected } =
		store.getState().customersListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: "GET_CUSTOMERS_LIST_REQUEST"
	});
	try {
		const variables = {
			limit,
			offset
		};
		// date filter
		let filtersObject = [];
		if (appliedDateFilter.current.dateFilter) {
			filtersObject.push({
				field: "date_joined",
				value: appliedDateFilter.current.dateFilter
			});
		}
		// sidebar filters
		Object.keys(appliedFilters).forEach((f) => {
			if (appliedFilters[f].value) {
				filtersObject.push(appliedFilters[f]);
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// search filter
		let searchObject = [];
		if (searchKW) {
			searchObject.push({
				key: searchFieldSelected.value,
				value: searchKW
			});
		}
		// set search and filter
		variables.filters = filtersObject;
		variables.search = searchObject;
		const resp = await client.query({
			query: GET_CUSTOMERS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "GET_CUSTOMERS_LIST_SUCCESS",
			payload: resp.data.customers ? { ...resp.data.customers } : {}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_CUSTOMERS_LIST_FAILURE",
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
