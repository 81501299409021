import React, { Component } from "react";

// components
import { CreditTariff } from "../components/CreditSettings/CreditTariff";
import { Ledger } from "../components/CreditSettings/Ledger";
import { BuyCreditsFlow } from "../components/_commons/BuyCreditsFlow";

export default class CreditSettings extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const currLocation = {
			pathname: this.props.location.pathname,
			title: "Piper Credits"
		};
		return (
			<div>
				<CreditTariff />
				<Ledger currLocation={currLocation} />
				<BuyCreditsFlow renderRechargeDrawer={this.props.renderRechargeDrawer} />
			</div>
		);
	}
}
