import React, { useEffect, useState } from "react";

// components
import { Button } from "../_commons/Button";
import { Switch } from "../_commons/Switch";
import ContextMenu from "../_commons/ContextMenu";
import TextHighlightChip from "../_commons/TextHighlightChip";
import Image from "../_commons/Image";
import Placeholder from "../_commons/Placeholder";
import RulesCreation from "../MenuEdit/RulesCreation";
import ArchiveRestoreModal from "../_commons/ArchiveRestoreModal";
import ConflictingRuleModal from "../MenuEdit/ConflictingRuleModal";
import ResolveConflictDrawer from "../MenuEdit/ResolveConflictDrawer";

// graphql
import { UPDATE_RULE_STATUS, GET_CONFLICTS_LIST } from "../../graphql/menus";
import { GET_ITEM_RULES_LIST, GET_OPTION_RULES_LIST } from "../../graphql/entityRulesAssociationList";

// client
import { clientMenu } from "../../client-menu";

// utils
import { capitaliseText, removeProp } from "../../atlas-utils";

// constants
const CONTEXT_MENU_INITIAL_STATE = {
	contextId: null
};

const OPERATION_UI_LABEL_MAP = {
	price: "Default price",
	description: "Description",
	image_url: "Image",
	markup_price: "Markup price",
	name: "Name"
};

const EntityRulesAssociationList = ({ isOpen, entityType, menuId, entityId, entityName, brandId }) => {
	const [isRulesListLoading, setIsRulesListLoading] = useState(false);
	const [statusUpdatingRuleIds, setStatusUpdatingRuleIds] = useState({});
	const [rulesList, setRulesList] = useState({});
	const [contextMenuData, setContextMenuData] = useState(CONTEXT_MENU_INITIAL_STATE);
	const [ruleCreateEditForm, setRuleCreateEditForm] = useState({
		isOpen: false,
		isEditMode: false,
		ruleId: null
	});
	const [deleteModalStates, setDeleteModalStates] = useState({
		isOpen: false,
		ruleId: null
	});
	const [isConflictingRuleModalOpen, setConflictingRuleModalOpen] = useState(false);
	const [latestModifiedRuleDetails, setLatestModifiedRuleDetails] = useState({});
	const [isResolveConflictDrawerOpen, setResolveConflictDrawerOpen] = useState(false);
	const [conflictingRuleDetails, setConflictingRuledetails] = useState([]);

	const fetchRules = async () => {
		const GQL_RULES_QUERY = {
			item: GET_ITEM_RULES_LIST,
			option: GET_OPTION_RULES_LIST
		};

		setIsRulesListLoading(true);
		try {
			const variables = { menuId };

			if (entityType === "item") {
				variables.itemId = entityId;
			} else if (entityType === "option") {
				variables.optionId = entityId;
			}

			const resp = await clientMenu.query({
				query: GQL_RULES_QUERY[entityType],
				variables,
				fetchPolicy: "no-cache"
			});

			const rulesArray = resp?.data?.[entityType]?.applicableRules ?? [];
			const rulesMap = {};

			rulesArray.forEach((rule) => {
				if (rulesMap[rule?.entityId]) {
					rulesMap[rule?.entityId].push({ ...rule });
				} else {
					rulesMap[rule?.entityId] = [{ ...rule }];
				}
			});

			setRulesList(rulesMap);
			setIsRulesListLoading(false);
		} catch (e) {
			setIsRulesListLoading(false);
			console.log(e);
		}
	};

	const updateRuleStatus = (entityId, ruleId, newStatus) => {
		setRulesList((current) => {
			const updateRulesList = {
				...current
			};

			updateRulesList[entityId] = [
				...updateRulesList[entityId].map((rule) =>
					rule?.id === ruleId ? { ...rule, status: newStatus } : rule
				)
			];

			return updateRulesList;
		});
	};

	const handleRuleStatusUpdate = async (entityId, ruleId, newStatus) => {
		try {
			updateRuleStatus(entityId, ruleId, newStatus);
			const variables = {
				menuId,
				ruleId,
				newStatus
			};
			setStatusUpdatingRuleIds((current) => ({
				...current,
				[ruleId]: true
			}));

			const ruleStatusUpdateResp = await clientMenu.mutate({
				mutation: UPDATE_RULE_STATUS,
				variables,
				fetchPolicy: "no-cache"
			});

			if (!ruleStatusUpdateResp?.data?.updateRuleStatusV2?.success) {
				updateRuleStatus(entityId, ruleId, newStatus === "active" ? "disabled" : "active");
				setLatestModifiedRuleDetails({
					ruleObject: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.ruleObject,
					error: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.error,
					success: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.success,
					conflicts: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.conflicts
				});
				if (
					ruleStatusUpdateResp?.data?.updateRuleStatusV2?.error === "CONFLICT_DETECTED" &&
					ruleStatusUpdateResp?.data?.updateRuleStatusV2?.conflicts?.length > 0
				) {
					setConflictingRuleModalOpen && setConflictingRuleModalOpen(true);
				}
			}
			setStatusUpdatingRuleIds((current) => ({
				...current,
				[ruleId]: false
			}));
		} catch (e) {
			updateRuleStatus(entityId, ruleId, newStatus === "active" ? "disabled" : "active");
			setStatusUpdatingRuleIds((current) => ({
				...current,
				[ruleId]: false
			}));
			console.log(e);
		}
	};

	const showRulesCreationDrawer = (isOpen = true, isEditMode = false, ruleId = null) => {
		setRuleCreateEditForm({
			isOpen,
			isEditMode,
			ruleId
		});
	};

	const closeRulesCreationDrawer = (refresh) => {
		setRuleCreateEditForm({
			isOpen: false,
			isEditMode: false,
			ruleId: null
		});
		if (refresh) {
			fetchRules();
		}
	};

	const openDeleteModal = (record) => {
		setDeleteModalStates((current) => ({
			...current,
			isOpen: true,
			ruleId: record?.ruleId
		}));
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
		setConflictingRuleModalOpen(false);
	};

	const closeDeleteModal = (refresh) => {
		setDeleteModalStates((current) => ({
			...current,
			isOpen: false,
			ruleId: null
		}));

		if (refresh) {
			fetchRules();
		}
	};

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => showRulesCreationDrawer(true, true, record?.ruleId)}>
					Edit Rule
				</div>
				<div className="action-item action-item--archive" onClick={() => openDeleteModal(record)}>
					Delete Rule
				</div>
			</React.Fragment>
		);
	};

	const handleClickOutsideContextMenu = () => {
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
	};

	const openContextMenu = (id) => {
		setContextMenuData((current) => ({ ...current, contextId: id }));
	};

	const rulesObjectKeysArray = Object.keys(rulesList);

	useEffect(() => {
		if (isOpen) {
			fetchRules();
		}
	}, [isOpen]);

	if (isRulesListLoading && rulesObjectKeysArray.length === 0) {
		return (
			<div className="menu-rules-container P(10px)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}
	if (rulesObjectKeysArray.length === 0) {
		return (
			<div className="entity-rules-association-container">
				<Header showRulesCreationDrawer={showRulesCreationDrawer} />
				<Placeholder
					placeholderImageUrl="/assets/empty_states/graphics-empty-rules.svg"
					placeholderText="No rules associated!"
					placeholderSubtext={`Associate rules to this ${entityType}`}
					size="medium"
					placeholderButtonContent={<span>+ Add Rule</span>}
					placeholderButtonClickAction={showRulesCreationDrawer}
				/>
				<RulesCreation
					isOpen={ruleCreateEditForm?.isOpen}
					close={closeRulesCreationDrawer}
					brandId={brandId}
					menuId={menuId}
					isNested
					isForOptionFixed
					forOptionFixedValue={{
						id: entityId,
						name: entityName
					}}
					entityType={entityType}
				/>
				<ArchiveRestoreModal
					isOpen={deleteModalStates?.isOpen}
					mode="delete"
					entityType="menuRule"
					entityName="Rule"
					dataObject={{ menuId, ruleId: deleteModalStates?.ruleId }}
					close={closeDeleteModal}
				/>
			</div>
		);
	}

	const handleReviewConflicts = async () => {
		const ruleIds = latestModifiedRuleDetails?.conflicts?.map((ct) => ct?.ruleId) || [];
		const resp = await fetchConflictsList(ruleIds);
		if (resp?.data?.rules?.length) {
			setResolveConflictDrawerOpen(true);
		}
		setConflictingRuleModalOpen(false);
	};

	const fetchConflictsList = async (ruleIds = []) => {
		try {
			let resp = {};
			if (ruleIds?.length > 0) {
				resp = await clientMenu.query({
					query: GET_CONFLICTS_LIST,
					variables: { menuId, filterOptions: { ruleIds } },
					fetchPolicy: "no-cache"
				});
				if (resp?.data?.rules?.length) {
					setConflictingRuledetails(resp?.data?.rules);
				}
			} else {
				setConflictingRuledetails([]);
			}
			return resp;
		} catch (e) {
			console.log(e);
		}
	};

	function extractConflictingValues(conflicts = []) {
		const OPERATION_LABEL_MAP = {
			change_default_price: "Default price",
			change_description: "Description",
			change_image: "Image",
			change_markup_price: "Markup price",
			change_name: "Name"
		};
		return conflicts.reduce((conflictingValues, conflict) => {
			const { conflictingLocations, conflictingPlatforms, conflictingOperations } = conflict;

			const locationValues = conflictingLocations.reduce((values, location) => {
				values.add(location);
				return values;
			}, new Set(conflictingValues));

			const platformValues = conflictingPlatforms.reduce((values, platform) => {
				values.add(platform);
				return values;
			}, locationValues);

			const operationValues = conflictingOperations.reduce((values, operation) => {
				const label = OPERATION_LABEL_MAP[operation];
				values.add(label || operation);
				return values;
			}, platformValues);

			return Array.from(operationValues);
		}, []);
	}

	let conflictingValues = [];

	if (latestModifiedRuleDetails) {
		conflictingValues = extractConflictingValues(latestModifiedRuleDetails?.conflicts);
	}
	const handleResolveConflictEditAction = (record) => {
		setResolveConflictDrawerOpen(false);
		showRulesCreationDrawer(true, true, record?.ruleId);
	};
	const handleUpdateRuleResolveSuccess = (updateRuleResp) => {
		setLatestModifiedRuleDetails({
			ruleObject: updateRuleResp?.data?.updateRuleV2?.ruleObject,
			error: updateRuleResp?.data?.updateRuleV2?.error,
			success: updateRuleResp?.data?.updateRuleV2?.success,
			conflicts: updateRuleResp?.data?.updateRuleV2?.conflicts
		});
		const conflictRuleIds = updateRuleResp?.data?.updateRuleV2?.conflicts?.map((ct) => ct?.ruleId) || [];
		fetchConflictsList(conflictRuleIds);
		setResolveConflictDrawerOpen(true);
	};

	return (
		<div className={`entity-rules-association-container ${isRulesListLoading ? "disabled" : ""}`}>
			<Header showRulesCreationDrawer={showRulesCreationDrawer} />
			<div className="entity-rules-container">
				{rulesObjectKeysArray.map((id) => (
					<RuleInfo
						entityId={id}
						title={rulesList[id]?.[0]?.entityName}
						rulesCount={rulesList[id]?.length}
						rulesInfoArray={rulesList[id]}
						key={id}
						handleRuleStatusUpdate={handleRuleStatusUpdate}
						statusUpdatingRuleIds={statusUpdatingRuleIds}
						contextMenuData={contextMenuData}
						renderMenuItems={renderMenuItems}
						handleClickOutsideContextMenu={handleClickOutsideContextMenu}
						openContextMenu={openContextMenu}
					/>
				))}
				<RulesCreation
					isOpen={ruleCreateEditForm?.isOpen}
					isEditMode={ruleCreateEditForm?.isEditMode}
					close={closeRulesCreationDrawer}
					brandId={brandId}
					menuId={menuId}
					isNested
					isForOptionFixed
					ruleId={ruleCreateEditForm?.ruleId}
					forOptionFixedValue={{
						id: entityId,
						name: entityName
					}}
					entityType={entityType}
					latestModifiedRuleDetails={latestModifiedRuleDetails}
					setLatestModifiedRuleDetails={setLatestModifiedRuleDetails}
					setConflictingRuleModalOpen={setConflictingRuleModalOpen}
					handleUpdateRuleResolveSuccess={handleUpdateRuleResolveSuccess}
				/>
				<ConflictingRuleModal
					isOpen={isConflictingRuleModalOpen}
					handleDiscardNewRule={() => {
						openDeleteModal({
							ruleId: latestModifiedRuleDetails?.ruleObject?.id
						});
					}}
					handleReviewConflicts={handleReviewConflicts}
					latestModifiedRuleDetails={latestModifiedRuleDetails}
				/>
				<ResolveConflictDrawer
					isNested
					isOpen={isResolveConflictDrawerOpen}
					newRuleDetails={latestModifiedRuleDetails}
					conflictingRuleDetails={conflictingRuleDetails}
					conflictingValues={conflictingValues}
					menuId={menuId}
					setResolveConflictDrawerOpen={setResolveConflictDrawerOpen}
					handleNewRuleEditAction={handleResolveConflictEditAction}
					fetchRules={fetchRules}
					setLatestModifiedRuleDetails={setLatestModifiedRuleDetails}
				/>
				<ArchiveRestoreModal
					isOpen={deleteModalStates?.isOpen}
					mode="delete"
					entityType="menuRule"
					entityName="Rule"
					dataObject={{ menuId, ruleId: deleteModalStates?.ruleId }}
					close={closeDeleteModal}
				/>
			</div>
		</div>
	);
};

export default EntityRulesAssociationList;

const Header = ({ showRulesCreationDrawer }) => (
	<div className="header-container">
		<div className="text-container">
			<div className="title">Associated Rules</div>
			<div className="sub-title">Rules affect item appearence in different locations and platforms</div>
		</div>
		<div className="actions-container">
			<Button clickHandler={showRulesCreationDrawer}>Add Rule</Button>
		</div>
	</div>
);

const RuleInfo = ({
	rulesInfoArray,
	statusUpdatingRuleIds,
	handleRuleStatusUpdate,
	entityId,
	contextMenuData,
	renderMenuItems,
	handleClickOutsideContextMenu,
	openContextMenu
}) => (
	<React.Fragment>
		{rulesInfoArray.map((ruleInfo) => (
			<div className="rule-info" key={ruleInfo?.id}>
				<div className="header-actions-container">
					<div className="rule-title">{ruleInfo?.name || "--"}</div>
					<div className="actions-container">
						<Switch
							classes={statusUpdatingRuleIds[ruleInfo?.id] ? "disabled" : ""}
							checked={ruleInfo?.status === "active"}
							clickHandler={() =>
								handleRuleStatusUpdate(
									entityId,
									ruleInfo?.id,
									ruleInfo.status === "active" ? "disabled" : "active"
								)
							}
						/>
						<ContextMenu
							isOpen={contextMenuData?.contextId === ruleInfo?.id}
							data={{
								ruleId: ruleInfo?.id
							}}
							renderMenuItems={renderMenuItems}
							handleOpenMenu={(e) => {
								e.stopPropagation();
								openContextMenu(ruleInfo?.id);
							}}
							handleOutsideClick={
								contextMenuData?.contextId === ruleInfo?.id
									? () => handleClickOutsideContextMenu()
									: () => {}
							}
						/>
					</div>
				</div>
				<div className="rule-description">
					When
					{!!ruleInfo?.locations?.length && (
						<>
							<TextHighlightChip content={"Location"} />
							is
							<TextHighlightChip content={ruleInfo?.locations?.map((loc) => loc?.locationName)} />
						</>
					)}
					{!!ruleInfo?.locations?.length && !!ruleInfo?.platforms?.length && "+"}
					{!!ruleInfo?.platforms?.length && (
						<>
							<TextHighlightChip content={"Platform"} />
							is
							<TextHighlightChip content={ruleInfo?.platforms?.map((plf) => capitaliseText(plf))} />
						</>
					)}
					<Image alt="right arrow" src="/assets/icons/icon-right-arrow-tailed.svg" />
					{ruleInfo?.operations?.some((op) => op.field === "sold_at") ? (
						<>&ensp; Do not sell </>
					) : (
						<>
							&ensp; Change
							<TextHighlightChip
								content={(ruleInfo?.operations ?? [])?.map(
									(operation) => OPERATION_UI_LABEL_MAP[operation?.field]
								)}
							/>
						</>
					)}
				</div>
			</div>
		))}
	</React.Fragment>
);
