import React, { Component } from "react";

// components
import DateFilter from "./DateFilter";
import CheckboxGroupFilter from "./CheckboxGroupFilter";
import NumberRangeFilter from "./NumberRangeFilter";
import TimeRangeFilter from "./TimeRangeFilter";
import MultiSelectFilter from "./MultiSelectFilter";

// graphql
import { ORDER_CHANNELS, FULFILLMENT_MODES, PAYMENT_MODES } from "../../client-config";

// third party
import { connect } from "react-redux";

// actions
import { fetchCouponsDebounced, fetchItemsDebounced, fetchStoresDebounced } from "../../actions/actions";

@connect((store) => ({
	configItems: store.configItems
}))
export default class CampaignOrder extends Component {
	render() {
		const { filter, configItems } = this.props;
		const order = filter.order || {};
		const { coupons, items, stores } = configItems;

		return (
			<div className="filter-section-container">
				<DateFilter
					title="Placed on"
					root="order"
					field="placed_on"
					value={order.placed_on}
					setFilter={this.props.setFilter}
				/>
				<DateFilter
					title="Not Placed in"
					root="order"
					field="inactive_on"
					value={order.inactive_on}
					setFilter={this.props.setFilter}
				/>
				<TimeRangeFilter
					title="Time of day"
					root="order"
					field="time_of_day"
					value={order.time_of_day}
					setFilter={this.props.setFilter}
				/>
				<CheckboxGroupFilter
					title="Channel"
					root="order"
					options={ORDER_CHANNELS}
					field="channel"
					value={order.channel}
					setFilter={this.props.setFilter}
				/>
				<CheckboxGroupFilter
					title="Fulfillment Mode"
					root="order"
					options={FULFILLMENT_MODES}
					field="fulfillment_mode"
					value={order.fulfillment_mode}
					setFilter={this.props.setFilter}
				/>
				<NumberRangeFilter
					title="Order value"
					root="order"
					field="order_value"
					value={order.order_value}
					setFilter={this.props.setFilter}
				/>
				<NumberRangeFilter
					title="Number of orders"
					root="order"
					field="number_of_orders"
					value={order.number_of_orders}
					setFilter={this.props.setFilter}
				/>
				<CheckboxGroupFilter
					title="Payment Mode"
					root="order"
					options={PAYMENT_MODES}
					field="payment_mode"
					value={order.payment_mode}
					setFilter={this.props.setFilter}
				/>
				<MultiSelectFilter
					title="Coupon Applied"
					root="order"
					options={coupons.items}
					field="coupons"
					value={order.coupons}
					setFilter={this.props.setFilter}
					isLoading={coupons.isLoading}
					handleSearch={fetchCouponsDebounced}
					labelKey="title"
					valueKey="redemptionCode"
				/>
				<MultiSelectFilter
					title="Items"
					root="order"
					options={items.items}
					field="items"
					value={order.items}
					setFilter={this.props.setFilter}
					isLoading={items.isLoading}
					handleSearch={fetchItemsDebounced}
					labelKey="title"
					valueKey="id"
					excludable={true}
				/>
				<MultiSelectFilter
					title="Stores"
					root="order"
					options={stores.items}
					field="stores"
					value={order.stores}
					setFilter={this.props.setFilter}
					isLoading={stores.isLoading}
					handleSearch={fetchStoresDebounced}
					labelKey="name"
					valueKey="id"
					excludable={true}
				/>
			</div>
		);
	}
}
