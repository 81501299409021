import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { Filters } from "../components/_commons/Filters";
import { CommonTable } from "../components/_commons/CommonTable";
import { Paginator } from "../components/_commons/Paginator";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SearchFilter } from "../components/_commons/SearchFilter";
import { NewDateCompareFilter } from "../components/_commons/NewDateCompareFilter";

// third party
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { debounce } from "lodash";

// utils
import { trackEvent, scroll, capitaliseText, capitaliseTextStrict } from "../atlas-utils";

// client
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_BRAND_LOCATIONS } from "../graphql/reports";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBrands, fetchStoresDebounced } from "../actions/actions";
import { fetchAuditEventsList, fetchRelatedAuditEventsList } from "../actions/auditEvents";

// assets
import CopyIcon from "../components/_commons/CopyIcon";
import { ButtonIcon } from "../components/_commons/ButtonIcon";

// constants
import { PRESET_TYPES, DATE_TYPES, CUSTOM_TYPES } from "../components/_commons/NewDateCompareFilter";
export const EVENT_SOURCE_DESTINATION_MAP = {
	urbanpiper: "UrbanPiper",
	prime: "Prime",
	eis: "EIS",
	unknown: "Unknown",
	pos: "POS",
	eateasy: "Smiles"
};

const columns = [
	{
		name: "Activity",
		field: "event",
		render: (record, i, ar, cs, handleTask, cb, rest) => {
			let event = rest.renderEventPurpose(
				(record?.data?.purpose || record?.data?.additional_info?.purpose || record?.event)?.replace(
					/[.\/_-]/g,
					" "
				)
			);
			const {
				relatedEvents: { loading }
			} = record;
			const { expandedRows, handleExpandRow, accordion, handleViewEvent } = rest;
			const isExpanded = accordion ? expandedRows?.[record.id] || false : false;
			return (
				<div className={"at-table-cell at-cell-text event"} title={event} key={i}>
					{accordion && (
						<ButtonIcon
							icon="dropdown-arrow"
							clickHandler={() => handleExpandRow(record.id, !isExpanded)}
							classes={`${loading ? "disabled" : ""} ${record.relatedCount === 0 ? "hidden" : ""}`}
						/>
					)}
					<div className="event-title">
						<Link
							className="link-text"
							to={`/activity-history/preview/${record.id}`}
							onClick={() => handleViewEvent(record.id)}
						>
							<span className="hyperlink hyperlink--black-color">{event || record.id}</span>
						</Link>
						{accordion && (
							<div className="info" title={`Trace ID: ${record.traceId}`}>
								<div className="trace-id-container">
									<div className="trace-id">{record.traceId || ""}</div>
									<div
										title="Copy Trace ID"
										className="at-copy-icon"
										onClick={(e) => handleTask(e, record.traceId)}
									>
										<CopyIcon />
									</div>
								</div>
								{record.relatedCount > 0 && (
									<div className="events-count">
										({record.relatedCount} {record.relatedCount === 1 ? "event" : "events"})
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			);
		}
	},
	{
		name: "Timestamp",
		field: "time-stamp",
		render: (record, i) => {
			return (
				<div className={"at-table-cell at-cell-text time-stamp"} key={i}>
					<div>
						{record.timestamp
							? moment(parseInt(record.timestamp)).format("DD MMM, YYYY - hh:mm:ss A")
							: "--"}
					</div>
				</div>
			);
		}
	},
	{
		name: "Brand",
		field: "brand",
		render: (record, i) => {
			let info = record?.data?.additional_info;
			return (
				<div className={"at-table-cell at-cell-text brand"} key={i}>
					{info?.brand_name || "N.A."}
				</div>
			);
		}
	},
	{
		name: "Location",
		field: "location",
		render: (record, i) => {
			let info = record?.data?.additional_info;
			return (
				<div className={"at-table-cell at-cell-text location"} key={i}>
					{info?.store_name || "N.A."}
				</div>
			);
		}
	},
	{
		name: "User",
		field: "done-by",
		render: (record, i) => {
			let source = record?.data?.source
				? EVENT_SOURCE_DESTINATION_MAP[record?.data?.source] || record?.data?.source
				: undefined;
			source = source?.replace(/[._-]/g, " ") || "POS";
			const name =
				record.data?.user_full_name ||
				(record.data?.user === "SYSTEM" ? "System" : source ? `${source} User` : "");
			const username = record.data?.user === "SYSTEM" ? "" : record.data?.user || "";
			return (
				<div className={"at-table-cell at-cell-text done-by"} key={i}>
					<div className="name" title={capitaliseTextStrict(name, true)}>
						{name}
					</div>
					<div className="username" title={username}>
						{username}
					</div>
				</div>
			);
		}
	},
	{
		name: "Source",
		field: "source",
		render: (record, i) => {
			const source = record?.data?.source
				? EVENT_SOURCE_DESTINATION_MAP[record?.data?.source] || record?.data?.source
				: undefined;
			return (
				<div
					className={"at-table-cell at-cell-text source"}
					title={capitaliseText(source?.replace(/[._-]/g, " ") || "POS")}
					key={i}
				>
					{source?.replace(/[._-]/g, " ") || "POS"}
				</div>
			);
		}
	},
	{
		name: "Destination",
		field: "destination",
		render: (record, i) => {
			const destination = record?.data?.destination
				? EVENT_SOURCE_DESTINATION_MAP[record?.data?.destination] || record?.data?.destination
				: undefined;
			return (
				<div
					className={"at-table-cell at-cell-text destination"}
					title={capitaliseText(destination?.replace(/[._-]/g, " ") || "POS")}
					key={i}
				>
					{destination?.replace(/[._-]/g, " ") || "POS"}
				</div>
			);
		}
	},
	{
		name: "Status",
		field: "status",
		render: (record, i) => {
			let status = undefined;
			if (record.data?.content) {
				try {
					status = JSON.parse(record.data?.content)?.["status"] || undefined;
				} catch {
					status = undefined;
				}
			}
			if (!status && record.data?.status_code) {
				status =
					String(record.data?.status_code)?.match(/^2.*/g) !== null
						? "success"
						: String(record.data?.status_code)?.match(/^5.*|^4.*/g) !== null
						? "failure"
						: undefined;
			}
			return (
				<div className={"at-table-cell at-cell-text status"} title={status} key={i}>
					<span className={status || ""}>{status || "--"}</span>
				</div>
			);
		}
	}
];

const AuditEventsList = ({
	auditEventsList,
	auditEventsListState,
	configItems,
	brands,
	stores,
	isMultibrandEnabled = false
}) => {
	const { data, loading } = auditEventsList;
	const {
		limit,
		offset,
		prevOffset,
		currPage,
		sortedField,
		currentFilters,
		appliedFilters,
		currentDateFilter,
		appliedDateFilter
	} = auditEventsListState;
	const [showFilters, setShowFilters] = useState(false);
	const [expandedRows, setExpandedRows] = useState({});
	const [locationsLookup, setLocationsLookup] = useState({});
	const tableRef = useRef();

	useEffect(() => {
		fetchAuditEventsList();
		// reset expanded accordion rows
		setExpandedRows({});
	}, [appliedFilters, appliedDateFilter]);

	useEffect(() => {
		// track visits to event trail list view
		trackEvent("event_trail_list_view", {});
		fetchStoresDebounced("");
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	const updateAuditEventsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.AUDIT_EVENTS_LIST_STATE_CHANGE,
			payload
		});
	};

	const sortList = async (field) => {
		const sort = {
			field
		};
		updateAuditEventsListState({
			offset: 0,
			currPage: 1
		});
		store.dispatch({
			type: ActionTypes.AUDIT_EVENTS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		await fetchAuditEventsList();
		// reset expanded accordion rows
		setExpandedRows({});
	};

	const handlePagination = async (action) => {
		// set new offset based on action
		let newOffset = undefined;
		let newCurrPage = undefined;
		let newPrevOffset = { ...prevOffset };
		switch (action) {
			case "first":
				newOffset = 0;
				newCurrPage = 1;
				break;
			case "prev":
				newCurrPage = currPage - 1;
				newOffset = prevOffset[newCurrPage];
				break;
			case "next":
				newCurrPage = currPage + 1;
				newOffset = data.nextOffset;
				newPrevOffset[newCurrPage] = data.nextOffset;
				break;
			default:
				break;
		}
		updateAuditEventsListState({
			prevOffset: newPrevOffset,
			offset: newOffset,
			currPage: newCurrPage
		});
		// fetch new audit events list
		await fetchAuditEventsList();
		// reset expanded accordion rows
		setExpandedRows({});
		// scroll to top of the list
		if (tableRef) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateAuditEventsListState({
				[field]: size.value,
				offset: 0,
				currPage: 1
			});
			// fetch new audit events list
			await fetchAuditEventsList();
			// reset expanded accordion rows
			setExpandedRows({});
		}
		// scroll to top of the list
		if (tableRef) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const setFilter = useCallback(
		(field, value) => {
			let updatedCurrentFilters = {
				...currentFilters
			};
			updatedCurrentFilters[field] = value;
			updateAuditEventsListState({
				currentFilters: updatedCurrentFilters
			});
		},
		[currentFilters]
	);

	const trackSearchFilterEvent = useCallback(
		debounce(() => {
			const { searchFieldSelected } = store.getState().auditEventsList.data;
			// track search event
			const eventMeta = {
				search: searchFieldSelected.valueForDisplay
			};
			trackEvent("event_trail_search_filter", eventMeta);
		}, 500),
		[]
	);

	const applySearchFilter = useCallback(
		debounce(() => {
			fetchAuditEventsList();
			// reset expanded accordion rows
			setExpandedRows({});
		}, 500),
		[]
	);

	const handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.AUDIT_EVENTS_LIST_SEARCH,
			payload: { [field]: value }
		});
		if (data.searchFieldValue) {
			updateAuditEventsListState({
				offset: 0,
				currPage: 1
			});
			applySearchFilter();
			trackSearchFilterEvent();
		}
	};

	const updateToLastThirtyDaysFilter = () => {
		const {
			current: { dateFilter, dateTypeSelected }
		} = currentDateFilter;
		let updatedCurrentDateFilter = {};
		let updatedDateFilter = false;
		if (dateFilter !== "LAST_30_DAYS" || dateTypeSelected.value !== "preset_duration") {
			updatedCurrentDateFilter = {
				...currentDateFilter,
				current: {
					...currentDateFilter?.current,
					dateFilter: PRESET_TYPES[8].value,
					dateTypeSelected: DATE_TYPES[0],
					presetTypeSelected: PRESET_TYPES[8],
					customTypeSelected: CUSTOM_TYPES[0]
				},
				compare: { ...appliedDateFilter?.compare }
			};
			updatedDateFilter = true;
		}
		// update state
		if (updatedDateFilter) {
			updateAuditEventsListState({
				currentDateFilter: { ...updatedCurrentDateFilter },
				appliedDateFilter: { ...updatedCurrentDateFilter },
				offset: 0,
				currPage: 1
			});
		}
	};

	const setSearchFilter = useCallback(
		(field, value) => {
			// change date filter to 'Last 30 days' for search
			const {
				current: { dateFilter, dateTypeSelected }
			} = currentDateFilter;
			let updatedCurrentDateFilter = {};
			let updatedDateFilter = false;
			if ((dateFilter !== "LAST_30_DAYS" || dateTypeSelected.value !== "preset_duration") && value) {
				updatedCurrentDateFilter = {
					...currentDateFilter,
					current: {
						...currentDateFilter?.current,
						dateFilter: PRESET_TYPES[8].value,
						dateTypeSelected: DATE_TYPES[0],
						presetTypeSelected: PRESET_TYPES[8],
						customTypeSelected: CUSTOM_TYPES[0]
					},
					compare: { ...appliedDateFilter?.compare }
				};
				updatedDateFilter = true;
			}
			// update search input
			store.dispatch({
				type: ActionTypes.AUDIT_EVENTS_LIST_SEARCH,
				payload: { [field]: value }
			});
			// update state
			if (updatedDateFilter) {
				updateAuditEventsListState({
					currentDateFilter: { ...updatedCurrentDateFilter },
					appliedDateFilter: { ...updatedCurrentDateFilter },
					offset: 0,
					currPage: 1
				});
			} else {
				updateAuditEventsListState({
					offset: 0,
					currPage: 1
				});
				applySearchFilter();
			}
			if (value) {
				trackSearchFilterEvent();
			}
		},
		[currentDateFilter, appliedDateFilter]
	);

	const applyFilters = useCallback(() => {
		setShowFilters(false);
		// reset expanded accordion rows
		setExpandedRows({});
		updateAuditEventsListState({
			appliedFilters: currentFilters,
			appliedDateFilter: currentDateFilter,
			offset: 0,
			currPage: 1
		});
		// track applied filters
		const eventMeta = {
			filters: Object.keys(currentFilters)
		};
		trackEvent("event_trail_list_view_filters", eventMeta);
	}, [currentFilters, currentDateFilter]);

	const clearFilters = () => {
		setShowFilters(false);
		// reset expanded accordion rows
		setExpandedRows({});
		updateAuditEventsListState({
			currentFilters: {},
			appliedFilters: {},
			offset: 0,
			currPage: 1
		});
	};

	const closeFilterSidebar = useCallback(() => {
		setShowFilters(false);
		updateAuditEventsListState({
			currentFilters: appliedFilters,
			currentDateFilter: appliedDateFilter
		});
	}, [appliedFilters, appliedDateFilter]);

	const copyTextToClipboard = (e, text) => {
		e.stopPropagation();
		navigator.clipboard.writeText(text);
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: `Copied to clipboard`,
				timeout: 2000,
				error: false
			}
		});
		// track event
		const eventMeta = {
			item: "Trace ID",
			from: "List"
		};
		trackEvent("event_trail_copy_item", eventMeta);
	};

	const handleViewEvent = (id) => {
		// update event state to "Viewing now"
		setTimeout(() => {
			store.dispatch({
				type: ActionTypes.AUDIT_EVENTS_LIST_SEARCH,
				payload: { currEvent: { id, state: "Viewing now" } }
			});
		}, 100);
	};

	const handleExpandRow = (id, state = false) => {
		setExpandedRows({
			...expandedRows,
			[id]: state
		});
	};

	const renderEventPurpose = (event) => {
		if (/^webhook/g.test(String(event?.toLowerCase()))) {
			event = event?.toLowerCase()?.replace("webhook", "webhook - ");
		} else if (/^callback/g.test(String(event?.toLowerCase()))) {
			event = event?.toLowerCase()?.replace("callback", "callback - ");
		}
		return capitaliseTextStrict(event, true);
	};

	const handleAccordionOpen = (data, next) => {
		fetchRelatedAuditEventsList(
			data.traceId,
			data.id,
			data.relatedEvents.limit,
			data.relatedEvents.offset + (next ? 10 : 0)
		);
		// track event
		trackEvent("event_trail_expand_event_accordion", {
			purpose: renderEventPurpose(
				(data?.data?.purpose || data?.data?.additional_info?.purpose || data?.event)?.replace(/[.\/_-]/g, " ")
			)
		});
	};

	const handleAccordionClose = (data) => {
		const {
			relatedEvents: { id, traceId }
		} = data;
		store.dispatch({
			type: ActionTypes.UPDATE_RELATED_AUDIT_EVENTS_LIST,
			payload: {
				id,
				traceId,
				limit: 10,
				offset: 0,
				objects: [],
				count: 0
			}
		});
	};

	const renderCustomPlaceholder = () => {
		return (
			<React.Fragment>
				<div className="illustration">
					<img src="/assets/empty-states/activity-history.svg" alt="" />
				</div>
				<div className="message">No events found!</div>
				{appliedDateFilter.current.dateFilter !== "LAST_30_DAYS" && (
					<div className="action">
						Try searching for <span onClick={updateToLastThirtyDaysFilter}>last 30 days</span> instead?
					</div>
				)}
			</React.Fragment>
		);
	};

	let filterCount = 0;
	for (let f in appliedFilters) {
		if (appliedFilters[f].value && appliedFilters[f].value != "") {
			filterCount++;
		}
	}

	const filterOptions = data.filters.filter(
		(filter) => filter.field !== "timestamp" && filter.field !== "location_ids" && filter.field !== "brand_ids"
	);

	if (isMultibrandEnabled) {
		const options = [
			{
				field: "brand_ids",
				valueForDisplay: "Brands",
				type: "MULTIPLE",
				isLoading: brands.isLoading,
				values: brands.items?.filter((brand) => brand.id !== "all") || [],
				hide: null,
				labelKey: "name",
				valueKey: "id"
			},
			{
				field: "location_ids",
				valueForDisplay: "Locations",
				type: "MULTIPLE",
				values: null,
				hide: null,
				isAsync: true,
				asyncOptions: stores,
				asyncLookup: locationsLookup,
				updateAsyncLookup: (id, title) =>
					setLocationsLookup({
						...locationsLookup,
						[id]: title
					}),
				handleAsyncSearch: fetchStoresDebounced,
				labelKey: "name",
				valueKey: "id"
			}
		];
		filterOptions.push(...options);
	} else {
		filterOptions.push({
			field: "location_ids",
			valueForDisplay: "Locations",
			type: "MULTIPLE",
			values: null,
			hide: null,
			isAsync: true,
			asyncOptions: stores,
			asyncLookup: locationsLookup,
			updateAsyncLookup: (id, title) =>
				setLocationsLookup({
					...locationsLookup,
					[id]: title
				}),
			handleAsyncSearch: fetchStoresDebounced,
			labelKey: "name",
			valueKey: "id"
		});
	}

	return (
		<div className="audit-events-list-container section-container-common" ref={tableRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Activity History</div>
					<div className="subheader-text">Keep a track of your actions done over the last 30 days</div>
				</div>
			</div>
			<div className="note">
				<ButtonIcon icon="alert" color="#C2831C" />
				<div>Your activity data can change real-time. Please search using Trace ID for updated results.</div>
			</div>
			{configItems.dimensions.width > 768 && (
				<Filters
					isOpen={showFilters}
					close={closeFilterSidebar}
					apply={applyFilters}
					clear={clearFilters}
					options={filterOptions}
					currentFilters={currentFilters}
					setFilter={setFilter}
				/>
			)}
			<div className="filters">
				<NewDateCompareFilter
					showDropdown={true}
					loading={loading}
					currentDateFilter={currentDateFilter}
					appliedDateFilter={appliedDateFilter}
					updateState={(payload) => updateAuditEventsListState({ ...payload, currPage: 1 })}
					includeAllTime={true}
					hideComparison={true}
					showTime={true}
					hidePresetTypes={["All time", "This year", "90 D"]}
					defaultCustomRange={30}
					minDate={moment().subtract(30, "days")}
				/>
				{configItems.dimensions.width > 768 && (
					<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
						<div className="container" onClick={() => setShowFilters(true)}>
							<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
							<div className="filter-title">
								Filter
								{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
							</div>
						</div>
					</div>
				)}
				{configItems.dimensions.width > 768 && (
					<div className="search-input-container">
						<SelectFilter
							options={data.searchKeywords}
							field="searchFieldSelected"
							currValue={data.searchFieldSelected}
							setFilter={handleSearchField}
							labelKey="valueForDisplay"
							valueKey="key"
							isSearchable={false}
							isClearable={false}
						/>
						<SearchFilter
							filterOption={{ field: "searchFieldValue" }}
							value={data.searchFieldValue}
							setFilter={setSearchFilter}
							placeholder="Search"
						/>
					</div>
				)}
			</div>
			<CommonTable
				loading={loading}
				data={data.objects || []}
				currView={data.currEvent}
				columns={columns}
				sortList={sortList}
				sortedField={sortedField}
				handleTask={copyTextToClipboard}
				accordion={true}
				expandedRows={expandedRows}
				handleExpandRow={handleExpandRow}
				handleViewEvent={handleViewEvent}
				renderEventPurpose={renderEventPurpose}
				accordionContent="relatedEvents"
				onAccordionOpen={handleAccordionOpen}
				onAccordionClose={handleAccordionClose}
				infinteScroll={true}
				accordionContentColumns={columns}
				classes="audit-events-list-table-container"
				content="Events"
				customPlaceholder={renderCustomPlaceholder()}
				hideColumns={!isMultibrandEnabled ? ["brand"] : []}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				currPage={currPage}
				count={data.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
				restrictPagination={true}
				loading={loading}
				hasNext={data.hasNext}
				hasPrevious={data.hasPrevious}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	auditEventsList: store.auditEventsList,
	auditEventsListState: store.auditEventsListState,
	configItems: store.configItems,
	brands: store.configItems.brands,
	stores: store.configItems.stores,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(AuditEventsList);
