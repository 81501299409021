import React from "react";

const Loader = () => {
	return (
		<div className="nivo-chart-loader">
			<div className="loading"></div>
		</div>
	);
};
export default Loader;
