import React from "react";

// third party
import { useSpring, animated as a } from "react-spring";
import { trackEvent } from "../../atlas-utils";

export const LocationCountSelection = ({ handleSelectedTabUpdate, handleFormData }) => {
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const handleClick = (type) => {
		handleFormData("locationAddType", type);
		handleSelectedTabUpdate(2);
		trackEvent("wiz_loc_count_selection", { type: type });
	};
	return (
		<a.div className="common-box-structure" style={contentProps}>
			<div className="header">How many locations are you planning to add ?</div>
			<div className="selectables">
				<div
					onClick={() => {
						handleClick("single");
					}}
					className="selection-value"
				>
					<img src="/assets/icons/single-location-selector.svg" />
					<div className="caption">One</div>
				</div>
				<div
					onClick={() => {
						handleClick("multi");
					}}
					className="selection-value"
				>
					<img src="/assets/icons/multi-location-selector.svg" />
					<div className="caption">More than one</div>
				</div>
			</div>
		</a.div>
	);
};
