import React from "react";

// components
import { Button } from "../_commons/Button";

// utils
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";
import { fetchMerakiWebVersionData } from "../../actions/merakiWeb";

// constants
import { LANDING_PAGE_VARIANTS } from "./editorConfig";

const LandingPageSelector = ({ landingPage, handleLandingPageSelector, workspace, previewUrl }) => {
	const [showSelector, setShowSelector] = React.useState(true);
	const [lastPublishedConfig, setLastPublishedConfig] = React.useState({});

	const flipSelector = () => {
		setShowSelector(!showSelector);
	};

	React.useEffect(() => {
		if (landingPage?.enabled) {
			setShowSelector(true);
		} else {
			setShowSelector(false);
		}
	}, [landingPage.enabled]);

	const fetchLastPublishedConfig = React.useCallback(async () => {
		// if no version is published, then return
		if (!workspace.lastPublish?.version) {
			return;
		}
		try {
			const data = await fetchMerakiWebVersionData(workspace.lastPublish.version);
			setLastPublishedConfig(data.data);
		} catch (error) {
			console.log(error);
		}
	}, [workspace]);

	const lastPublishedLandingPageVariant = React.useMemo(() => {
		const lastPublishedLandingPage = lastPublishedConfig?.theme?.pages?.find((pg) => pg._id === "landing_screen");
		if (lastPublishedLandingPage?.enabled) {
			// if landing page was enabeled in last publish and variant is not selected
			// then assume that it is the first variant
			// to make it backward compatible
			return lastPublishedLandingPage.variant || LANDING_PAGE_VARIANTS[0].variant;
		}
		return null;
	}, [lastPublishedConfig]);

	React.useEffect(() => {
		fetchLastPublishedConfig();
	}, []);

	React.useEffect(() => {
		// if landing page is enabled but variant is not selected,
		// then show a message saying that the landing page has been revamped
		// and then selected first variant by default
		if (landingPage && landingPage?.enabled && !landingPage?.variant) {
			handleLandingPageSelector(LANDING_PAGE_VARIANTS[0].variant);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message:
						"Welcome back! Say hello to three new landing screen designs: Hubble, Webb, & Grace. Your current screen has been upgraded to the new Hubble design.",
					timeout: 5000,
					error: false
				}
			});
		}
	}, [landingPage]);

	const selectedVariantPage =
		LANDING_PAGE_VARIANTS.find((lp) => lp.variant === landingPage?.variant) || LANDING_PAGE_VARIANTS[0];

	return (
		<div className="landing-page-selector">
			<div className="landing-page-actions">
				{!showSelector ? (
					<div className="selector-message">
						{`You have selected "${selectedVariantPage.label}" for your websites landing screen`}
					</div>
				) : (
					<div className="selector-message">Designs for your website's landing screen:</div>
				)}
				<div className="selector-buttons">
					{/* <Button>Preview</Button> */}
					<Button clickHandler={flipSelector} type="secondary">
						{showSelector ? "Hide" : "Change"}
					</Button>
				</div>
			</div>
			{showSelector && (
				<div className="landing-page-variants-list">
					{LANDING_PAGE_VARIANTS.map((lp, i) => {
						const isSelected = landingPage?.variant === lp.variant;
						return (
							<div
								className={"landing-page-variant " + (isSelected ? "selected" : "")}
								key={i}
								onClick={() => handleLandingPageSelector(lp.variant)}
							>
								<div className="variant-info">
									<a
										className="variant-label"
										aria-label="Click to open preview"
										data-balloon-pos="up-left"
										href={`${previewUrl}/landing-page-demo/${lp.variant}?variant=default`}
										target="_blank noreferrer"
										onClick={(e) => e.stopPropagation()}
									>
										<span>{lp.label}</span>
										<img className="info-icon" src="/assets/icons/icon-view-eye.svg" />
									</a>
									{lp.variant === lastPublishedLandingPageVariant && (
										<div className="published-tag">Published</div>
									)}
								</div>
								<img src={lp.screenshot} />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
export default LandingPageSelector;
