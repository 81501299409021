import React, { useState, useEffect, useCallback, useRef } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/CatalogueBackups/BasicInfo";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BackupActions from "../components/CatalogueBackups/BackupActions";

// third party
import { connect } from "react-redux";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, adjustNestedContainer, trackEvent } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchCatalogueBackupsList, fetchManualBackupsList } from "../actions/catalogueBackups";

// graphql
import { CREATE_CATALOGUE_BACKUP } from "../graphql/catalogueBackups";

// constants
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const CATALOGUE_BACKUP_ACTION_INIT_STATE = {
	id: null,
	name: "",
	action: "delete",
	from: "Create"
};

const CatalogueBackupCreate = ({ isNested = false, closeNestedContainer, userInfo }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [manualBackups, setManualBackups] = useState([]);
	const [conditionalCreateBackup, setConditionalCreateBackup] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isModalBusy, setModalBusy] = useState(false);
	const [isBackupActionsOpen, setBackupActionsOpen] = useState(false);
	const [backupActionData, setBackupActionData] = useState(CATALOGUE_BACKUP_ACTION_INIT_STATE);
	const [data, setData] = useState({
		title: "",
		description: "",
		deleteBackup: {},
		creatorEmail: ""
	});
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const fetchData = async () => {
		const data = await fetchManualBackupsList();
		if (data?.objects?.length > 4) {
			setConditionalCreateBackup(true);
			setManualBackups(data.objects);
		}
	};

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchData();
		trackEvent("catalogue_backups_backup_now", {});
	}, []);

	const handleClose = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchCatalogueBackupsList();
					history.push("/backups");
				}
			}, 100);
		} else {
			closeBackupActions();
		}
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handlePreviewBackup = (e, type, id) => {
		e.stopPropagation();
		handleNestedEntity(true, type, id);
	};

	const handleSubmit = useCallback(async () => {
		setConfirmLoading(true);
		try {
			const variables = { ...data };
			if (variables.deleteBackup.id !== undefined) {
				variables.deletableIds = [parseInt(variables.deleteBackup.id)];
				delete variables.deleteBackup;
			} else {
				variables.deletableIds = [];
				delete variables.deleteBackup;
			}
			if (userInfo?.username?.includes("tmp_")) {
				variables.creatorEmail = userInfo?.email;
			}
			const resp = await client.mutate({
				mutation: CREATE_CATALOGUE_BACKUP,
				variables
			});
			if (resp.data.saveCatalogBackup.status.success) {
				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Your backup is currently being processed.",
						timeout: 3000,
						error: false
					}
				});
				// track event
				const eventMeta = {
					action: "Create",
					from: "List"
				};
				trackEvent("catalogue_backups_actions", eventMeta);
				handleClose();
			} else {
				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveCatalogBackup.status.messages));
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	}, [handleClose, userInfo]);

	const openBackupActions = useCallback(() => {
		setBackupActionData({
			...backupActionData,
			...data.deleteBackup,
			action: "delete"
		});
		setBackupActionsOpen(true);
		setModalBusy(true);
	}, [data, backupActionData]);

	const closeBackupActions = (refresh = false) => {
		setBackupActionsOpen(false);
		setModalBusy(false);
		setBackupActionData(CATALOGUE_BACKUP_ACTION_INIT_STATE);
		if (refresh) {
			setTimeout(() => {
				handleSubmit();
			}, 100);
		}
	};

	return (
		<div className="create-backup-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleClose}
				submit={conditionalCreateBackup ? openBackupActions : handleSubmit}
				title="Take a backup now!"
				subTitle="Take a backup of your entire catalogue across all locations and aggregators"
				loading={confirmLoading}
				submitTitle="Create"
				disabled={!isFormTouched || (conditionalCreateBackup && data.deleteBackup.id === undefined)}
				hideActions={!isFormTouched}
				isNested={false}
			>
				<div className="form-content">
					<BasicInfo
						handleForm={handleForm}
						data={data}
						manualBackups={manualBackups}
						handlePreviewBackup={handlePreviewBackup}
						validations={error?.fields || {}}
					/>
					<BackupActions
						isOpen={isBackupActionsOpen}
						isNested={true}
						data={backupActionData}
						close={closeBackupActions}
						createWorkflow={true}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						backupActions={false}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	userInfo: store.login.loginDetail
});
export default connect(mapStateToProps)(CatalogueBackupCreate);
