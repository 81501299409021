// action types
import { ActionTypes } from "../actions/_types";

// utils
import { lS } from "../atlas-utils";

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "name",
		order: "ASC"
	},
	sortedField: "name",
	currentFilters: {},
	appliedFilters: {}
};
export const locationsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.LOCATIONS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.LOCATIONS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.LOCATIONS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const getSortOrder = (currSort, newSort) => {
	return currSort.order === "" || currSort.field !== newSort.field
		? "DESC"
		: currSort.order === "ASC"
		? "DESC"
		: "ASC";
};

const LOCATIONS_LIST_INITIAL_STATE = {
	loading: true,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: "",
		ilpaLogs: {},
		ilpaActionStatusLogs: {}
	},
	selectedPlatform: "allPlatforms",
	selectedBrand: lS.get("brand")?.id ? lS.get("brand")?.id : null,
	error: false,
	ilpaLogsLoading: true,
	ilpaActionStatusLoading: true,
	verificationStatusLoading: true
};
export const locationsList = (state = LOCATIONS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_LOCATIONS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_LOCATIONS_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
					searchFieldSelected:
						(action.payload.searchKeywords.length && action.payload.searchKeywords[0]) || null
				},
				selectedBrand: action.selectedBrand || null,
				loading: false
			};
		case ActionTypes.GET_LOCATIONS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_LOCATIONS_LIST:
			let foundInList = false;
			const updatedLocations = state.data.objects.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedLocations.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedLocations,
					count: state.data.count + 1
				}
			};
		case ActionTypes.LOCATIONS_LIST_SEARCH:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.UPDATE_SELECTED_PLATFORM:
			return {
				...state,
				selectedPlatform: action.payload
			};
		case ActionTypes.SET_ILPA_LOGS_LOADING:
			return {
				...state,
				ilpaLogsLoading: action.payload
			};
		case ActionTypes.SET_ILPA_ACTION_LOGS_LOADING:
			return {
				...state,
				ilpaActionStatusLoading: action.payload
			};
		case ActionTypes.SET_VERIFICATION_STATUS_LOADING:
			return {
				...state,
				verificationStatusLoading: action.payload
			};
		default:
			return state;
	}
};

export const LOCATION_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	error: {},
	backupData: {}
};
export const locationDetailsReducer = (state = LOCATION_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_LOCATION_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_LOCATION_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				backupData: action.payload
			};
		case ActionTypes.GET_LOCATION_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_LOCATION_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.UPDATE_LOCATION_BACKUP_DATA:
			return {
				...state,
				backupData: {
					...action.payload
				}
			};
		case ActionTypes.EDIT_LOCATION_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_LOCATION_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_LOCATION_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.REDIRECT_TO_LOCATION_DETAIL:
			return {
				...state,
				redirectionId: action.payload.redirectionId
			};
		case ActionTypes.CLEAR_REDIRECTION:
			return {
				...state,
				redirectionId: null
			};
		default:
			return state;
	}
};

export const CREATE_LOCATION_STATUS_INITIAL_STATE = {
	status: null,
	reference_id: "",
	total_locations: 0,
	processed_locations: 0,
	created_locations: [],
	message: "",
	success: true,
	restrict: false

	// for dev testing
	// status: 'PROCESSING',
	// reference_id: '556546935aee490fb5995344c4265de1',
	// total_locations: 0,
	// processed_locations: 0,
	// created_locations: [],
	// message: '',
	// success: true,
	// restrict: true,
};
export const createLocationStatus = (state = CREATE_LOCATION_STATUS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_CREATE_LOCATION_STATUS:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
