import React from "react";

// components
import { SelectFilter } from "./SelectFilter";

// utils
import { commifyNumbers } from "../../atlas-utils";

// assets
import { ButtonIcon } from "./ButtonIcon";

// constants
const PAGE_SIZES = [
	{ label: 10, value: 10 },
	{ label: 25, value: 25 },
	{ label: 50, value: 50 }
];

export const Paginator = ({
	limit = 10,
	offset = 0,
	count = 0,
	delta = 2,
	goToPage,
	setPageSize,
	showPageSize = false,
	showTotalPages = true,
	restrictPagination = false,
	readOnly = false,
	loading = false,
	currPage,
	hasNext,
	hasPrevious
}) => {
	const currentPage = parseInt(offset / limit) + 1;
	const totalPages = Math.ceil(count / limit);
	const pageArray = pagination(currentPage, totalPages, delta);
	const pages = pageArray.map((page, i) => {
		if (page === "...") {
			return (
				<div className="at-paginator-item" key={i}>
					{page}
				</div>
			);
		} else {
			return (
				<div
					className={"at-paginator-item paginator-number " + (page === currentPage ? "active" : "")}
					onClick={() => goToPage(page)}
					key={i}
				>
					{page}
				</div>
			);
		}
	});

	let start, end;
	if (count > 0) {
		start = offset + 1;
		end = offset + limit <= count ? offset + limit : count;
	}

	return (count > 0 && count > limit) || showPageSize ? (
		<div className={"at-paginator" + (readOnly ? " disabled" : "")} data-testid="at-paginator">
			<div className="page-size-container">
				{count > 0 && showPageSize && <span>Show</span>}
				{count > 0 && showPageSize && (
					<SelectFilter
						options={PAGE_SIZES}
						field="limit"
						currValue={{ label: limit, value: limit }}
						setFilter={setPageSize}
						isSearchable={false}
						isClearable={false}
						labelKey="label"
						valueKey="value"
					/>
				)}
				{count > 0 && showPageSize && <span>results per page</span>}
			</div>
			<div className={"page-items-container" + (restrictPagination ? " restrict" : "")}>
				{count > 0 && count > limit && restrictPagination && (
					<div className="page-items">
						<div
							className={
								"at-paginator-item paginator-number first" +
								(loading || offset === 0 ? " disabled" : "")
							}
							title="First"
							onClick={() => goToPage("first")}
						>
							<ButtonIcon icon="first" />
						</div>
						<div
							className={
								"at-paginator-item paginator-number previous" +
								(loading || offset === 0 || !hasPrevious ? " disabled" : "")
							}
							title="Previous"
							onClick={() => goToPage("prev")}
						>
							<ButtonIcon icon="previous" />
							<div>Prev</div>
						</div>
						<div className="info">
							Page {currPage} {showTotalPages ? `of ${totalPages < 700 ? totalPages : "many"}` : null}
						</div>
						<div
							className={
								"at-paginator-item paginator-number next" + (loading || !hasNext ? " disabled" : "")
							}
							title="Next"
							onClick={() => goToPage("next")}
						>
							<div>Next</div>
							<ButtonIcon icon="next" />
						</div>
					</div>
				)}
				{count > 0 && count > limit && !restrictPagination && <div className="page-items">{pages}</div>}
				{count > 0 && count > limit && !restrictPagination && (
					<div className="pagination-info">
						<span>Showing </span>
						<span className="number">{commifyNumbers(start)} </span>
						<span>to </span>
						<span className="number">{commifyNumbers(end)} </span>
						<span>of </span>
						<span className="number">{count > 9000 ? "many" : commifyNumbers(count)}</span>
					</div>
				)}
			</div>
		</div>
	) : null;
};

const pagination = (current, last, delta) => {
	let left = current - delta;
	let right = current + delta + 1;
	let range = [];
	let rangeWithDots = [];
	let l;

	for (let i = 1; i <= last; i++) {
		if (i == 1 || i == last || (i >= left && i < right)) {
			range.push(i);
		}
	}

	for (let i of range) {
		if (l) {
			if (i - l === 2) {
				rangeWithDots.push(l + 1);
			} else if (i - l !== 1) {
				rangeWithDots.push("...");
			}
		}
		rangeWithDots.push(i);
		l = i;
	}

	return rangeWithDots;
};
