// actions
import { ActionTypes } from "../actions/_types";

// third party
import moment from "moment";

const ONGOING_TASKS_INITIAL_STATE = {
	isExpanded: false,
	isHidden: true,
	data: {
		objects: [],
		count: 0
	}
};

// for dev: sample format for objects
// objects: [
//     {
//         id: "123",
//		   type: "task-type",
//         status: "pending", // pending, completed, failed
//         name: "Downloading Customer-Wise Order Summary report PDF",
//         description: "",
//         dateTime: moment()
//     }
// ]

export const ongoingTasks = (state = ONGOING_TASKS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.ADD_TASK_TO_ONGOING_TASKS_LIST:
			// check if the task is already present in the list
			let updatedObjects = [...state.data.objects];
			let updatedCount = state.data.count;
			const taskFound = state.data.objects.find((task) => task?.id === action?.payload?.id);
			if (taskFound) {
				updatedObjects = updatedObjects.map((task) => {
					if (task?.id === action?.payload?.id) {
						return {
							...task,
							...action?.payload
						};
					}
					return task;
				});
			} else {
				updatedObjects.push({
					...action.payload,
					dateTime: moment()
				});
				updatedCount += 1;
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedObjects,
					count: updatedCount
				}
			};
		case ActionTypes.UPDATE_TASK_IN_ONGOING_TASKS_LIST:
			return {
				...state,
				data: {
					...state.data,
					objects: state.data.objects.map((obj) => {
						let updatedObj = { ...obj };
						if (obj.id === action.payload.id) {
							updatedObj = {
								...updatedObj,
								...action.payload
							};
						}
						return updatedObj;
					})
				}
			};
		case ActionTypes.REMOVE_COMPLETED_TASK_FROM_ONGOING_TASKS_LIST:
			return {
				...state,
				data: {
					...state.data,
					objects: state.data.objects.filter((obj) => obj.id !== action.payload.id),
					count: state.data.count - 1
				}
			};
		case ActionTypes.UPDATE_ONGOING_TASKS_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
