import React from "react";

// components
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Catalogue Verification</div>
				<div className="subtitle">
					Make sure your catalogue is compliant with rules set by aggregator platforms to avoid publish
					failures
				</div>
			</div>
			<div className="header-action-button">
				<div className="filter-buttons">
					{props.dimensions.width > 768 && (
						<div
							className={(props.filterActive ? "active" : "") + " filter-in-header campaign-list-filter"}
						>
							<div className="container" onClick={props.flipShowFilters}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{props.filterCount > 0 && <span className="filter-count">{props.filterCount}</span>}
								</div>
							</div>
						</div>
					)}
					{props.searchKeywords.length > 1
						? props.dimensions.width > 768 && (
								<div className="search-input-container">
									<SelectFilter
										options={props.searchKeywords}
										field="searchFieldSelected"
										currValue={props.searchFieldSelected}
										setFilter={props.handleSearchField}
										labelKey="valueForDisplay"
										valueKey="key"
										isSearchable={false}
										isClearable={false}
									/>
									<SearchFilter
										filterOption={{ field: "searchFieldValue" }}
										value={props.searchFieldValue}
										setFilter={props.setFilter}
										placeholder="Search"
									/>
								</div>
						  )
						: props.dimensions.width > 768 && (
								<SearchFilter
									filterOption={{ field: "searchFieldValue" }}
									value={props.searchFieldValue}
									setFilter={props.setFilter}
									placeholder="Search"
								/>
						  )}
				</div>
			</div>
		</div>
	);
};
