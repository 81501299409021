import React from "react";

export default React.memo(({ color = "#FFFFFF" }) => (
	<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Desktop-HD" transform="translate(-714.000000, -506.000000)" stroke={color}>
				<g id="Add" transform="translate(714.000000, 506.000000)">
					<line x1="6" y1="0" x2="6" y2="12" id="Path"></line>
					<line x1="0" y1="6" x2="12" y2="6" id="Path"></line>
				</g>
			</g>
		</g>
	</svg>
));
