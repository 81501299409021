import gql from "graphql-tag";

export const GET_MERAKI_SETTINGS = gql`
	query getMerakiSettings {
		bizSettings {
			orderingSlots {
				id
				title
			}
			isItemPreOrderEnabled
			isOrderPickupEnabled
			bizMinOrderTotal
			orderDeliveryRadius
			maxOrderPendingTime
			minimumOrderDeliveryTime
			minimumOrderPickupTime
			orderMsgNoStores
			orderMsgStoreClosed
			orderMsgOrderingDisabled
			orderingUsePointOfDelivery
		}
	}
`;

export const EDIT_MERAKI_SETTINGS = gql`
	mutation editMerakiSettings(
		$orderingSlots: Int
		$isItemPreOrderEnabled: Boolean
		$isOrderPickupEnabled: Boolean
		$bizMinOrderTotal: Float
		$orderDeliveryRadius: Int
		$maxOrderPendingTime: Int
		$minimumOrderDeliveryTime: Int
		$minimumOrderPickupTime: Int
		$orderMsgNoStores: String
		$orderMsgStoreClosed: String
		$orderMsgOrderingDisabled: String
		$orderingUsePointOfDelivery: Boolean
	) {
		saveBizSettings(
			input: {
				orderingSlots: $orderingSlots
				isItemPreOrderEnabled: $isItemPreOrderEnabled
				isOrderPickupEnabled: $isOrderPickupEnabled
				bizMinOrderTotal: $bizMinOrderTotal
				orderDeliveryRadius: $orderDeliveryRadius
				maxOrderPendingTime: $maxOrderPendingTime
				minimumOrderDeliveryTime: $minimumOrderDeliveryTime
				minimumOrderPickupTime: $minimumOrderPickupTime
				orderMsgNoStores: $orderMsgNoStores
				orderMsgStoreClosed: $orderMsgStoreClosed
				orderMsgOrderingDisabled: $orderMsgOrderingDisabled
				orderingUsePointOfDelivery: $orderingUsePointOfDelivery
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				orderingSlots {
					id
					title
				}
				isItemPreOrderEnabled
				isOrderPickupEnabled
				bizMinOrderTotal
				orderDeliveryRadius
				maxOrderPendingTime
				minimumOrderDeliveryTime
				minimumOrderPickupTime
				orderMsgNoStores
				orderMsgStoreClosed
				orderMsgOrderingDisabled
				orderingUsePointOfDelivery
			}
		}
	}
`;

export const GET_MERAKI_APP_SETTINGS = gql`
	query getMerakiAppSettings($locationId: Int, $brandId: Int) {
		bizAppSettings(locationId: $locationId, brandId: $brandId) {
			isPaymentsEnabled
			isPrepaidCreditAllowed
			isOrderPayByPpcEnabled
			minimumWalletCreditThreshold
			maxPrepaidTransactionAmt
			maxAllowedPrepaidBalance
			bypassOtpCheckForPrepaid
			isLoyaltyEnabled
			pointUseThreshold
			pointBalanceMapping
			loyaltyPointsExpirationEnabled
			defaultPointsExpiryDuration
			referralValidity
			referralUiLabel
			referralShareLabel
			smsSenderId
			smsKeyword
			isPaytmEnabled
			isSimplEnabled
			isPaypalEnabled
			faq
			tos
			privacyPolicy
			paymentGateway
			configurations {
				name
				keys {
					key
					value
					shortDesc
					description
					placeholder
					type
					choices
				}
			}
		}
	}
`;

export const EDIT_MERAKI_APP_SETTINGS = gql`
	mutation editMerakiAppSettings(
		$isPaymentsEnabled: Boolean!
		$isPrepaidCreditAllowed: Boolean
		$isOrderPayByPpcEnabled: Boolean
		$minimumWalletCreditThreshold: Float
		$maxPrepaidTransactionAmt: Float
		$maxAllowedPrepaidBalance: Float
		$bypassOtpCheckForPrepaid: Boolean
		$isLoyaltyEnabled: Boolean!
		$pointBalanceMapping: Float
		$pointUseThreshold: Float
		$loyaltyPointsExpirationEnabled: Boolean
		$defaultPointsExpiryDuration: Int
		$referralValidity: Int!
		$referralUiLabel: String
		$referralShareLabel: String
		$smsSenderId: String!
		$smsKeyword: String
		$isPaytmEnabled: Boolean
		$isSimplEnabled: Boolean
		$isPaypalEnabled: Boolean
		$faq: String
		$tos: String
		$privacyPolicy: String
		$paymentGateway: String
		$configurations: [ConfigurationKeyInput]!
		$locationId: Int
		$pgServiceConfigurations: [PaymentGatewayUpdateConfig]
		$brandId: Int
	) {
		saveBizAppSettings(
			input: {
				isPaymentsEnabled: $isPaymentsEnabled
				isPrepaidCreditAllowed: $isPrepaidCreditAllowed
				isOrderPayByPpcEnabled: $isOrderPayByPpcEnabled
				minimumWalletCreditThreshold: $minimumWalletCreditThreshold
				maxPrepaidTransactionAmt: $maxPrepaidTransactionAmt
				maxAllowedPrepaidBalance: $maxAllowedPrepaidBalance
				bypassOtpCheckForPrepaid: $bypassOtpCheckForPrepaid
				isLoyaltyEnabled: $isLoyaltyEnabled
				pointUseThreshold: $pointUseThreshold
				pointBalanceMapping: $pointBalanceMapping
				loyaltyPointsExpirationEnabled: $loyaltyPointsExpirationEnabled
				defaultPointsExpiryDuration: $defaultPointsExpiryDuration
				referralValidity: $referralValidity
				referralUiLabel: $referralUiLabel
				referralShareLabel: $referralShareLabel
				smsSenderId: $smsSenderId
				smsKeyword: $smsKeyword
				isPaytmEnabled: $isPaytmEnabled
				isSimplEnabled: $isSimplEnabled
				isPaypalEnabled: $isPaypalEnabled
				faq: $faq
				tos: $tos
				privacyPolicy: $privacyPolicy
				paymentGateway: $paymentGateway
				configurations: $configurations
				locationId: $locationId
				pgServiceConfigurations: $pgServiceConfigurations
				brandId: $brandId
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				isPaymentsEnabled
				isPrepaidCreditAllowed
				isOrderPayByPpcEnabled
				minimumWalletCreditThreshold
				maxPrepaidTransactionAmt
				maxAllowedPrepaidBalance
				bypassOtpCheckForPrepaid
				isLoyaltyEnabled
				pointUseThreshold
				pointBalanceMapping
				loyaltyPointsExpirationEnabled
				defaultPointsExpiryDuration
				referralValidity
				referralUiLabel
				referralShareLabel
				smsSenderId
				smsKeyword
				isPaytmEnabled
				isSimplEnabled
				isPaypalEnabled
				faq
				tos
				privacyPolicy
				paymentGateway
				configurations {
					name
					keys {
						key
						value
						shortDesc
						description
						placeholder
						type
						choices
					}
				}
			}
		}
	}
`;

export const GET_PAYMENT_GATEWAYS_LIST = gql`
	query gateways {
		paymentGateways {
			value
			valueForDisplay
			forDropdown
			kvConfigName
			supportLocationWiseConfig
		}
	}
`;

export const GET_CONFIGURATION_VALUES = gql`
	query getConfigurationValues($locationId: Int, $keys: [String], $brandId: Int) {
		configurationValues(locationId: $locationId, keys: $keys, brandId: $brandId) {
			key
			value
		}
	}
`;

export const SAVE_CONFIGURATION_VALUES = gql`
	mutation saveConfigurationValues($locationId: Int, $configurations: [ConfigurationKeyInput]!, $brandId: Int) {
		saveConfigurationValues(
			input: { locationId: $locationId, configurations: $configurations, brandId: $brandId }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
