import React, { useState, useCallback, useEffect, useMemo } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { CustomTable } from "../_commons/CustomTable";
import { CheckBox } from "../_commons/CheckBox";
import { NumericalStepDisplay } from "../_commons/NumericalStepDisplay";
import Placeholder from "../_commons/Placeholder";

// utils
import { extractInitials, validatePlatformUrl, decideMethodAndValidatePlatformId, trackEvent } from "../../atlas-utils";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import camelcase from "camelcase";
import SatismeterService from "../../services/SatismeterService";

// graphql
import {
	GET_BIZ_PLATFORMS,
	GET_LOCATION_PLATFORM_ASSOCIATIONS,
	UPDATE_LOCATION_PLATFORM_ASSOCIATIONS
} from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchBrands } from "../../actions/actions";

// constants
import {
	CATALOGUE_PLATFORMS_LOGO,
	TRACKING_EVENT_NAMES,
	TRACKING_SOURCE,
	businessBasedDisabledFields
} from "../../client-config";

const columns = [
	{
		name: "Location",
		field: "location",
		render: (record, i) => (
			<div className="table-cell location" key={i}>
				<div className="name">{record.name || record.id || ""}</div>
			</div>
		)
	},
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div className="table-cell platform" key={i}>
				{record.platforms &&
					Object.keys(record.platforms)?.map((plfId, j) => (
						<div className="plf-container" key={j}>
							<img
								src={
									record.platforms?.[plfId]?.logo ||
									CATALOGUE_PLATFORMS_LOGO[record.platforms?.[plfId]?.platformName?.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
								}
								alt=""
							/>
							<div className="name">{record.platforms?.[plfId]?.platformName}</div>
						</div>
					))}
			</div>
		)
	},
	{
		name: "Platform Id",
		field: "platform-id",
		isRequired: true,
		render: (record, i, rest) => (
			<div className="table-cell platform-id" key={i}>
				{record.platforms &&
					Object.keys(record.platforms)?.map((plfId, j) => (
						<div className="plf-container" key={j}>
							<InputWithLabel
								value={record.platforms?.[plfId]?.externalId}
								onChange={(e) =>
									rest.handleChange(
										"externalId",
										e.target.value,
										plfId,
										record.platforms?.[plfId]?.locationId,
										rest?.brand?.id
									)
								}
								validationMessage={record.platforms?.[plfId]?.validations?.externalId || ""}
							/>
						</div>
					))}
			</div>
		)
	},
	{
		name: "Platform URL",
		field: "platform-url",
		isRequired: true,
		render: (record, i, rest) => (
			<div className="table-cell platform-url" key={i}>
				{record.platforms &&
					Object.keys(record.platforms)?.map((plfId, j) => (
						<div className="plf-container" key={j}>
							<InputWithLabel
								value={record.platforms?.[plfId]?.outletUrl}
								onChange={(e) =>
									rest.handleChange(
										"outletUrl",
										e.target.value,
										plfId,
										record.platforms?.[plfId]?.locationId,
										rest?.brand?.id
									)
								}
								validationMessage={record.platforms?.[plfId]?.validations?.outletUrl || ""}
							/>
						</div>
					))}
			</div>
		)
	}
];

const locationColumns = [
	{
		name: "Location",
		field: "location",
		render: (record, i) => (
			<div className="table-cell location" key={i}>
				{record.name}
			</div>
		)
	},
	{
		name: "City",
		field: "city",
		render: (record, i) => (
			<div className="table-cell city" key={i}>
				{record.city}
			</div>
		)
	}
];

const GoLive = ({
	isNested = false,
	locations = [],
	isOpen = false,
	close,
	brands = [],
	selectedBrand = null,
	isMultibrandEnabled = false
}) => {
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [bizPlatformsList, setBizPlatformsList] = useState([]);
	const [locationPlatformUpdates, setLocationPlatformUpdates] = useState({});
	const [publishResponse, setPublishResponse] = useState({});
	const [selectedTab, setSelectedTab] = useState(1);
	const [stepCount, setStepCount] = useState(3);
	const [brandUpdates, setBrandUpdates] = useState({});
	const [platformUpdates, setPlatformUpdates] = useState({});
	const storesCount = useMemo(() => locations.length, [locations]);

	useEffect(() => {
		if (isOpen) {
			fetchBizPlatformsList();
			setStepCount(
				locations.length > 1
					? selectedBrand === null || selectedBrand?.id !== "all"
						? 3
						: 4
					: selectedBrand === null || selectedBrand?.id !== "all"
					? 2
					: 3
			);
			if (isMultibrandEnabled && selectedBrand?.id === "all") {
				fetchBrands("", true);
			}
		}
	}, [isOpen]);

	const fetchBizPlatformsList = async () => {
		try {
			setLoading(true);
			const variables = {
				limit: 30,
				offset: 0
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizPlatformsList(resp.data.bizPlatforms.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const handleChange = (field, value, platform, location, brand = null) => {
		if (brand) {
			let updates = { ...locationPlatformUpdates };
			updates[brand] = updates[brand].map((loc) => {
				let updatedLocation = { ...loc };
				if (updatedLocation.id === location) {
					updatedLocation.platforms = {
						...(updatedLocation.platforms ?? {}),
						[platform]: {
							...(updatedLocation.platforms?.[platform] ?? {}),
							[field]: value,
							validations: {
								...(updatedLocation.platforms?.[platform]?.validations ?? {}),
								[field]: ""
							}
						}
					};
				}
				return updatedLocation;
			});
			setLocationPlatformUpdates(updates);
		} else {
			let updates = locationPlatformUpdates.map((loc) => {
				let updatedLocation = { ...loc };
				if (updatedLocation.id === location) {
					updatedLocation.platforms = {
						...(updatedLocation.platforms ?? {}),
						[platform]: {
							...(updatedLocation.platforms?.[platform] ?? {}),
							[field]: value,
							validations: {
								...(updatedLocation.platforms?.[platform]?.validations ?? {}),
								[field]: ""
							}
						}
					};
				}
				return updatedLocation;
			});
			setLocationPlatformUpdates(updates);
		}
	};

	const handleSelectBrand = (brand, isSelected) => {
		setBrandUpdates({
			...brandUpdates,
			[brand.id]: isSelected
		});
		if (isSelected) {
			const updatedToggleStatusUpdates = { ...locationPlatformUpdates };
			updatedToggleStatusUpdates[brand.id] = {};
			for (let platformId in platformUpdates) {
				if (platformUpdates[platformId]) {
					updatedToggleStatusUpdates[brand.id][platformId] = null;
				}
			}
			setLocationPlatformUpdates(updatedToggleStatusUpdates);
		} else {
			const updatedToggleStatusUpdates = { ...locationPlatformUpdates };
			delete updatedToggleStatusUpdates[brand.id];
			setLocationPlatformUpdates(updatedToggleStatusUpdates);
		}
	};

	const handleSelectPlatform = (platform, isSelected) => {
		setPlatformUpdates({
			...platformUpdates,
			[platform.id]: isSelected
		});
		if (selectedBrand === null) {
			let updatedLocationPlatformUpdates = { ...locationPlatformUpdates };
			if (isSelected) {
				updatedLocationPlatformUpdates[platform.id] = null;
			} else {
				delete updatedLocationPlatformUpdates[platform.id];
			}
			setLocationPlatformUpdates(updatedLocationPlatformUpdates);
		} else if (selectedBrand?.id === "all") {
			let updatedLocationPlatformUpdates = { ...locationPlatformUpdates };
			if (isSelected) {
				for (let brandId in updatedLocationPlatformUpdates) {
					updatedLocationPlatformUpdates[brandId][platform.id] = null;
				}
			} else {
				for (let brandId in updatedLocationPlatformUpdates) {
					delete updatedLocationPlatformUpdates[brandId][platform.id];
				}
			}
			setLocationPlatformUpdates(updatedLocationPlatformUpdates);
		} else {
			let updatedLocationPlatformUpdates = { ...locationPlatformUpdates };
			if (isSelected) {
				updatedLocationPlatformUpdates[selectedBrand.id] = {
					...(updatedLocationPlatformUpdates[selectedBrand.id] ?? {}),
					[platform.id]: null
				};
			} else {
				delete updatedLocationPlatformUpdates[selectedBrand.id][platform.id];
			}
			setLocationPlatformUpdates(updatedLocationPlatformUpdates);
		}
	};

	const handleClose = useCallback(
		(success = false) => {
			// reset state before closing
			setBizPlatformsList([]);
			setLocationPlatformUpdates({});
			setBrandUpdates({});
			setPlatformUpdates({});
			setPublishResponse({});
			setSelectedTab(1);
			close(success);
		},
		[close]
	);

	const fetchLocationPlatformAssociations = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				locationIds: locations.map((loc) => loc.id)
			};
			if (selectedBrand?.id === "all") {
				variables.brandIds = Object.keys(brandUpdates).filter((key) => brandUpdates[key]);
			} else if (selectedBrand?.id) {
				variables.brandIds = [selectedBrand?.id];
			}
			const resp = await client.query({
				query: GET_LOCATION_PLATFORM_ASSOCIATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			let locationPlatformAssociations = resp.data.locationPlatformAssociations;
			// find unique locations
			let locationsFound = {};
			locationPlatformAssociations.forEach((locPlf) => {
				if (!locationsFound[locPlf.location.id]) {
					locationsFound[locPlf.location.id] = locPlf.location;
				}
			});
			// check if the selected platforms are in location platform associations
			Object.keys(platformUpdates)
				.filter((plf) => platformUpdates[plf])
				.forEach((plf) => {
					let platformFound = false;
					locationPlatformAssociations.some((locPlf) => {
						if (locPlf.platform.id === plf) {
							platformFound = true;
						}
						return platformFound;
					});
					if (!platformFound) {
						// add new location platform associations
						const platform = bizPlatformsList.find((platform) => platform.id === plf);
						const locPlfAssociation = {
							id: null,
							externalId: null,
							outletUrl: null,
							testId: null,
							platform,
							platformName: platform?.platformName?.toLowerCase(),
							logo: platform?.logo
						};
						Object.keys(locationsFound).forEach((loc) => {
							locationPlatformAssociations.push({
								...locPlfAssociation,
								location: locationsFound[loc]
							});
						});
					}
				});
			// set location platform association updates
			let updates = selectedBrand === null ? [] : {};
			locationPlatformAssociations.forEach((locPlf) => {
				if (platformUpdates[locPlf.platform.id]) {
					if (selectedBrand === null) {
						if (updates?.length > 0) {
							if (updates.find((loc) => loc.id === locPlf.location.id)) {
								updates = updates.map((loc) => {
									let location = { ...loc };
									if (location.id === locPlf.location.id) {
										location.platforms = {
											...(location.platforms ?? {}),
											[locPlf.platform.id]: {
												id: locPlf.id || null,
												externalId: locPlf.externalId || null,
												outletUrl: locPlf.outletUrl || null,
												testId: "",
												locationId: locPlf.location.id,
												bizPlatformId: locPlf.platform.id,
												platformName: locPlf.platformName,
												logo: locPlf?.logo,
												validations: {}
											}
										};
									}
									return location;
								});
							} else {
								updates = [
									...updates,
									{
										id: locPlf.location.id,
										name: locPlf.location.name,
										platforms: {
											[locPlf.platform.id]: {
												id: locPlf.id || null,
												externalId: locPlf.externalId || null,
												outletUrl: locPlf.outletUrl || null,
												testId: "",
												locationId: locPlf.location.id,
												bizPlatformId: locPlf.platform.id,
												platformName: locPlf.platformName,
												logo: locPlf?.logo,
												validations: {}
											}
										}
									}
								];
							}
						} else {
							updates = [
								{
									id: locPlf.location.id,
									name: locPlf.location.name,
									platforms: {
										[locPlf.platform.id]: {
											id: locPlf.id || null,
											externalId: locPlf.externalId || null,
											outletUrl: locPlf.outletUrl || null,
											testId: locPlf.testId || null,
											locationId: locPlf.location.id,
											bizPlatformId: locPlf.platform.id,
											platformName: locPlf.platformName,
											logo: locPlf?.logo,
											validations: {}
										}
									}
								}
							];
						}
					} else {
						if (updates[locPlf.location.brand.id]?.length > 0) {
							if (updates[locPlf.location.brand.id].find((loc) => loc.id === locPlf.location.id)) {
								updates[locPlf.location.brand.id] = updates[locPlf.location.brand.id].map((loc) => {
									let location = { ...loc };
									if (location.id === locPlf.location.id) {
										location.platforms = {
											...(location.platforms ?? {}),
											[locPlf.platform.id]: {
												id: locPlf.id || null,
												externalId: locPlf.externalId || null,
												outletUrl: locPlf.outletUrl || null,
												testId: "",
												locationId: locPlf.location.id,
												bizPlatformId: locPlf.platform.id,
												platformName: locPlf.platformName,
												logo: locPlf?.logo,
												validations: {}
											}
										};
									}
									return location;
								});
							} else {
								updates[locPlf.location.brand.id] = [
									...(updates[locPlf.location.brand.id] ?? []),
									{
										id: locPlf.location.id,
										name: locPlf.location.name,
										platforms: {
											[locPlf.platform.id]: {
												id: locPlf.id || null,
												externalId: locPlf.externalId || null,
												outletUrl: locPlf.outletUrl || null,
												testId: "",
												locationId: locPlf.location.id,
												bizPlatformId: locPlf.platform.id,
												platformName: locPlf.platformName,
												logo: locPlf?.logo,
												validations: {}
											}
										}
									}
								];
							}
						} else {
							updates[locPlf.location.brand.id] = [
								{
									id: locPlf.location.id,
									name: locPlf.location.name,
									platforms: {
										[locPlf.platform.id]: {
											id: locPlf.id || null,
											externalId: locPlf.externalId || null,
											outletUrl: locPlf.outletUrl || null,
											testId: locPlf.testId || null,
											locationId: locPlf.location.id,
											bizPlatformId: locPlf.platform.id,
											platformName: locPlf.platformName,
											logo: locPlf?.logo,
											validations: {}
										}
									}
								}
							];
						}
					}
				}
			});
			setLocationPlatformUpdates(updates);
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 3000,
					error: true
				}
			});
		}
		setConfirmLoading(false);
	};

	const handleNextTab = () => {
		setSelectedTab(selectedTab + 1);
		if (selectedTab === stepCount - 1) {
			fetchLocationPlatformAssociations();
		}
	};

	const handlePreviousTab = () => {
		setSelectedTab(selectedTab - 1);
	};

	const handleGoLive = useCallback(async () => {
		// validate filled details
		let updates = selectedBrand === null ? [...locationPlatformUpdates] : { ...locationPlatformUpdates };
		let uniquePlfIds = {};
		let isValidated = true;
		if (selectedBrand === null) {
			updates = updates.map((loc) => {
				let location = { ...loc };
				Object.keys(location.platforms).forEach((plf) => {
					const { externalId, outletUrl, platformName } = location.platforms[plf];
					let isPlatformIdUnique = !(
						uniquePlfIds[externalId] && uniquePlfIds[externalId]?.includes(platformName)
					);
					if (isPlatformIdUnique) {
						uniquePlfIds[externalId] = [...(uniquePlfIds[externalId] ?? []), platformName];
					}
					let platformUrlValidation = businessBasedDisabledFields.platformUrl[platformName.toLowerCase()]
						? true
						: validatePlatformUrl(platformName.toLowerCase(), outletUrl);
					let platformIdValidation = businessBasedDisabledFields.platformId[platformName.toLowerCase()]
						? true
						: decideMethodAndValidatePlatformId(platformName.toLowerCase(), externalId);
					if (!platformIdValidation || !platformUrlValidation || !isPlatformIdUnique) {
						location.platforms[plf].validations = {
							externalId: !isPlatformIdUnique
								? "You have entered the same platform ID for multiple locations"
								: !platformIdValidation
								? "Invalid platform ID"
								: "",
							outletUrl: !platformUrlValidation ? "Invalid platform URL" : ""
						};
						isValidated = false;
					}
				});
				return location;
			});
		} else {
			for (let brandId in updates) {
				for (let i = 0; i < updates[brandId].length; i++) {
					let location = { ...updates[brandId][i] };
					for (let plf in location.platforms) {
						const { externalId, outletUrl, platformName } = location.platforms[plf];
						let isPlatformIdUnique = !(
							uniquePlfIds[externalId] && uniquePlfIds[externalId]?.includes(platformName)
						);
						if (isPlatformIdUnique) {
							uniquePlfIds[externalId] = [...(uniquePlfIds[externalId] ?? []), platformName];
						}
						let platformUrlValidation = businessBasedDisabledFields.platformUrl[platformName.toLowerCase()]
							? true
							: validatePlatformUrl(platformName.toLowerCase(), outletUrl);
						let platformIdValidation = businessBasedDisabledFields.platformId[platformName.toLowerCase()]
							? true
							: decideMethodAndValidatePlatformId(platformName.toLowerCase(), externalId);
						if (!platformIdValidation || !platformUrlValidation || !isPlatformIdUnique) {
							location.platforms[plf].validations = {
								externalId: !isPlatformIdUnique
									? "You have entered the same platform ID for multiple locations"
									: !platformIdValidation
									? "Invalid platform ID"
									: "",
								outletUrl: !platformUrlValidation ? "Invalid platform URL" : ""
							};
							isValidated = false;
						}
					}
					updates[brandId][i] = { ...location };
				}
			}
		}
		if (!isValidated) {
			setLocationPlatformUpdates(updates);
		} else {
			let selectedPlatforms = {};
			if (selectedBrand === null) {
				locationPlatformUpdates.forEach((loc) => {
					Object.keys(loc?.platforms ?? {}).forEach((plf) => {
						selectedPlatforms[loc?.platforms[plf]?.platformName.toLowerCase()] = true;
					});
				});
			} else {
				for (let brandId in locationPlatformUpdates) {
					locationPlatformUpdates[brandId].forEach((loc) => {
						Object.keys(loc?.platforms ?? {}).forEach((plf) => {
							selectedPlatforms[loc?.platforms[plf]?.platformName.toLowerCase()] = true;
						});
					});
				}
			}
			const eventMeta = {
				eventTriggerSection: "locations_list_View",
				platforms: Object.keys(selectedPlatforms),
				number_of_locations: locations.length,
				source: TRACKING_SOURCE.LIST_VIEW_BULK
			};

			try {
				setLoading(true);
				let variables = { data: [] };
				if (selectedBrand === null) {
					locationPlatformUpdates.forEach((loc) => {
						Object.keys(loc.platforms).forEach((plf) => {
							let locPlfAssociation = { ...loc.platforms[plf] };
							delete locPlfAssociation.validations;
							variables.data.push(locPlfAssociation);
						});
					});
				} else {
					for (let brandId in locationPlatformUpdates) {
						locationPlatformUpdates[brandId].forEach((loc) => {
							Object.keys(loc.platforms).forEach((plf) => {
								let locPlfAssociation = { ...loc.platforms[plf] };
								delete locPlfAssociation.validations;
								variables.data.push(locPlfAssociation);
							});
						});
					}
				}

				trackEvent(TRACKING_EVENT_NAMES.RTGL_START, eventMeta);

				// satismeter event
				SatismeterService.requestToGoLive();

				const resp = await client.mutate({
					mutation: UPDATE_LOCATION_PLATFORM_ASSOCIATIONS,
					variables,
					fetchPolicy: "no-cache"
				});

				if (resp?.data?.saveLocationPlatformAssociations?.status?.success) {
					trackEvent(TRACKING_EVENT_NAMES.RTGL_SUCCESS, eventMeta);
					// handle success message
					const pubResp = {
						status: true,
						message: resp.data.saveLocationPlatformAssociations.status.messages[0].message
					};
					setPublishResponse(pubResp);
					setSelectedTab(selectedTab + 1);
				} else {
					trackEvent(TRACKING_EVENT_NAMES.RTGL_FAILURE, eventMeta);
					let updates =
						selectedBrand === null ? [...locationPlatformUpdates] : { ...locationPlatformUpdates };
					let foundErrors = false;
					if (resp.data.saveLocationPlatformAssociations.status.messages.length > 0) {
						resp.data.saveLocationPlatformAssociations.status.messages.forEach((error) => {
							if (error.field) {
								const field = error.field.split("#");
								let index = 0;
								if (selectedBrand === null) {
									updates = updates.map((loc) => {
										let location = { ...loc };
										Object.keys(location.platforms).forEach((plf) => {
											if (parseInt(field[0]) === index) {
												location.platforms[plf].validations = {
													...(location.platforms[plf].validations ?? {}),
													[camelcase(field[1])]: error.message
												};
											}
											index += 1;
										});
										return location;
									});
									foundErrors = true;
								} else {
									for (let brandId in updates) {
										for (let i = 0; i < updates[brandId].length; i++) {
											let location = { ...updates[brandId][i] };
											for (let plf in location.platforms) {
												if (parseInt(field[0]) === index) {
													location.platforms[plf].validations = {
														...(location.platforms[plf].validations ?? {}),
														[camelcase(field[1])]: error.message
													};
												}
												index += 1;
											}
											updates[brandId][i] = { ...location };
										}
									}
									foundErrors = true;
								}
							}
						});
					}
					if (foundErrors) {
						setLocationPlatformUpdates(updates);
						store.dispatch({
							type: ActionTypes.SHOW_GLOBAL_MESSAGE,
							payload: {
								message: "Errors found, please fix them and try again.",
								timeout: 3000,
								error: true
							}
						});
					} else {
						// handle error message
						const pubResp = {
							status: false,
							message: resp.data.saveLocationPlatformAssociations.status.messages[0].message
						};
						setPublishResponse(pubResp);
						setSelectedTab(selectedTab + 1);
					}
				}
			} catch (error) {
				trackEvent(TRACKING_EVENT_NAMES.RTGL_FAILURE, eventMeta);
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
		}
		setLoading(false);
	}, [locations, locationPlatformUpdates]);

	const checkAllAssociations = () => {
		let isDisabled = false;
		if (selectedBrand === null) {
			if (locationPlatformUpdates.length > 0) {
				locationPlatformUpdates.forEach((loc) => {
					Object.keys(loc.platforms).forEach((plfId) => {
						if (!loc.platforms[plfId].externalId || !loc.platforms[plfId].outletUrl) {
							isDisabled = true;
						}
					});
				});
			} else {
				isDisabled = true;
			}
		} else {
			if (Object.keys(locationPlatformUpdates).length > 0) {
				for (let brandId in locationPlatformUpdates) {
					if (locationPlatformUpdates?.[brandId]?.length > 0) {
						for (let i = 0; i < locationPlatformUpdates[brandId].length; i++) {
							let loc = { ...locationPlatformUpdates[brandId][i] };
							for (let plfId in loc.platforms) {
								if (!loc.platforms[plfId].externalId || !loc.platforms[plfId].outletUrl) {
									isDisabled = true;
								}
							}
						}
					}
				}
			} else {
				isDisabled = true;
			}
		}
		return isDisabled;
	};

	const handleDisabled = () => {
		let disabled = false;
		switch (selectedTab) {
			case 1:
				if (locations.length > 1) {
					disabled = false;
				} else if (selectedBrand === null || selectedBrand?.id !== "all") {
					disabled = Object.values(platformUpdates).filter((val) => val === true).length === 0;
				} else {
					disabled = Object.values(brandUpdates).filter((val) => val === true).length === 0;
				}
				break;
			case 2:
				if (locations.length > 1 && (selectedBrand === null || selectedBrand?.id !== "all")) {
					disabled = Object.values(platformUpdates).filter((val) => val === true).length === 0;
				} else if (locations.length > 1 && selectedBrand?.id === "all") {
					disabled = Object.values(brandUpdates).filter((val) => val === true).length === 0;
				} else if (locations.length === 1 && selectedBrand?.id === "all") {
					disabled = Object.values(platformUpdates).filter((val) => val === true).length === 0;
				} else {
					disabled = checkAllAssociations();
				}
				break;
			case 3:
				if (locations.length > 1 && (selectedBrand === null || selectedBrand?.id !== "all")) {
					disabled = checkAllAssociations();
				} else if (locations.length > 1 && selectedBrand?.id === "all") {
					disabled = Object.values(platformUpdates).filter((val) => val === true).length === 0;
				} else if (locations.length === 1 && selectedBrand?.id === "all") {
					disabled = checkAllAssociations();
				}
				break;
			case 4:
				if (locations.length > 1 && selectedBrand?.id === "all") {
					disabled = checkAllAssociations();
				}
				break;
			default:
				break;
		}
		return disabled;
	};

	const placeholderContent = {
		placeholderText: publishResponse?.status
			? "Your request to go live has been registered successfully"
			: "There was an error",
		placeholderImageUrl: publishResponse?.status
			? "/assets/icons/graphics-success-request.svg"
			: "/assets/icons/icon-request-failed.svg",
		placeholderSubtext: publishResponse?.status
			? "Your request is under verification, once it's completed we will notify you."
			: "Your request to go live has failed. Please check your internet connectivity and try again.",
		placeholderButtonContent: !publishResponse?.status ? "Try again" : "",
		placeholderButtonClickAction: !publishResponse?.status
			? () => setSelectedTab((current) => current - 1)
			: () => {},
		secondaryActionTitle: "Dismiss",
		secondaryAction: () => handleClose(publishResponse?.status),
		placeholderCustomClasses: !publishResponse?.status ? "go-live-placeholder" : "",
		size: "medium"
	};

	return (
		<div className="locations-go-live-container">
			<FormSidebar
				isOpen={isOpen}
				loading={loading}
				submit={selectedTab === stepCount ? handleGoLive : handleNextTab}
				close={() => handleClose(publishResponse.status)}
				cancel={selectedTab > stepCount ? () => handleClose(publishResponse.status) : handlePreviousTab}
				title="Request to go live"
				subTitle={`Register a request to take ${storesCount > 1 ? storesCount : ``} ${
					storesCount > 1 ? `selected locations` : `${locations?.[0]?.name || "selected location"}`
				} live on new platform(s)`}
				submitTitle={selectedTab === stepCount ? "Request to Go Live" : "Next"}
				cancelTitle={selectedTab > stepCount ? "Close" : "Previous"}
				submitButtonWidth={selectedTab === stepCount ? 150 : 100}
				hideSubmitAction={selectedTab > stepCount}
				hideCancelAction={selectedTab === 1}
				disabled={handleDisabled()}
				hideActions={confirmLoading}
				isNested={isNested}
			>
				{isOpen && selectedTab <= stepCount && (
					<NumericalStepDisplay count={stepCount} selectedValue={selectedTab} showProgressBar={true} />
				)}
				{locations.length > 1 && selectedTab === 1 && <Locations locations={locations} />}
				{((locations.length === 1 && selectedBrand?.id === "all" && selectedTab === 1) ||
					(locations.length > 1 && selectedBrand?.id === "all" && selectedTab === 2)) && (
					<Brands
						brands={brands.items.filter((brand) => brand.id !== "all")}
						loading={brands.isLoading}
						brandUpdates={brandUpdates}
						handleSelect={handleSelectBrand}
					/>
				)}
				{((locations.length === 1 &&
					(selectedBrand === null || selectedBrand?.id !== "all") &&
					selectedTab === 1) ||
					(locations.length > 1 &&
						(selectedBrand === null || selectedBrand?.id !== "all") &&
						selectedTab === 2) ||
					(locations.length === 1 && selectedBrand?.id === "all" && selectedTab === 2) ||
					(locations.length > 1 && selectedBrand?.id === "all" && selectedTab === 3)) && (
					<Platforms
						platforms={bizPlatformsList}
						loading={loading}
						platformUpdates={platformUpdates}
						handleSelect={handleSelectPlatform}
					/>
				)}
				{((locations.length === 1 &&
					(selectedBrand === null || selectedBrand?.id !== "all") &&
					selectedTab === 2) ||
					(locations.length > 1 &&
						(selectedBrand === null || selectedBrand?.id !== "all") &&
						selectedTab === 3) ||
					(locations.length === 1 && selectedBrand?.id === "all" && selectedTab === 3) ||
					(locations.length > 1 && selectedBrand?.id === "all" && selectedTab === 4)) && (
					<div className="toggle-status">
						<div className="section-title">Fill in details</div>
						{confirmLoading ? (
							<div className="P(25px)">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						) : (
							<React.Fragment>
								{selectedBrand &&
									selectedBrand?.id === "all" &&
									brands.items
										?.filter((brand) => brandUpdates[brand.id])
										?.map((brand, i) => (
											<div className="brand-platform-container" key={i}>
												<div className="brand">
													<div className={"brand-initials " + brand.color}>
														{brand.image ? (
															<img src={brand.image} alt="" />
														) : (
															extractInitials(brand.name?.split(" "))
														)}
													</div>
													<div className="brand-name">{brand.name || ""}</div>
												</div>
												<CustomTable
													columns={columns}
													data={locationPlatformUpdates[brand.id]}
													brand={brand}
													handleChange={handleChange}
													storesCount={storesCount}
													content="locations"
													classes="locations-list-table"
												/>
											</div>
										))}
								{selectedBrand &&
									selectedBrand?.id !== "all" &&
									[selectedBrand]?.map((brand, i) => (
										<div className="brand-platform-container" key={i}>
											<div className="brand">
												<div className={"brand-initials " + brand.color}>
													{brand.image ? (
														<img src={brand.image} alt="" />
													) : (
														extractInitials(brand.name?.split(" "))
													)}
												</div>
												<div className="brand-name">{brand.name || ""}</div>
											</div>
											<CustomTable
												columns={columns}
												data={locationPlatformUpdates[brand.id]}
												brand={brand}
												handleChange={handleChange}
												storesCount={storesCount}
												content="locations"
												classes="locations-list-table"
											/>
										</div>
									))}
								{!selectedBrand && (
									<div className="brand-platform-container">
										<CustomTable
											columns={columns}
											data={locationPlatformUpdates}
											brand={null}
											handleChange={handleChange}
											storesCount={storesCount}
											content="locations"
											classes="locations-list-table"
										/>
									</div>
								)}
							</React.Fragment>
						)}
					</div>
				)}
				{selectedTab === stepCount + 1 && publishResponse && <Placeholder {...placeholderContent} />}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(GoLive);

const Locations = ({ locations }) => {
	return (
		<div className="selected-locations">
			<div className="section-title">Your selected Location(s)</div>
			<CustomTable
				columns={locationColumns}
				data={locations}
				content="locations"
				classes="locations-list-table"
			/>
		</div>
	);
};

const Brands = ({ brands = [], loading = false, handleSelect, brandUpdates = {} }) => {
	return (
		<div className={"brands-selection" + (loading ? " disabled" : "")}>
			<div className="section-title">Select Brand(s)</div>
			<div className="brands-list">
				{brands.map((brand, i) => (
					<div
						key={i}
						className={"brand-container" + (brandUpdates[brand.id] ? " selected" : "")}
						onClick={() => handleSelect(brand, !brandUpdates[brand.id])}
					>
						<CheckBox
							checked={brandUpdates[brand.id]}
							clickHandler={(e) => {
								handleSelect(brand, !brandUpdates[brand.id]);
								e.stopPropagation();
							}}
						/>
						<div className={"brand-initials " + brand.color}>
							{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
						</div>
						<div className="brand-name" title={brand.name}>
							{brand.name}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const Platforms = ({ platforms = [], loading = false, handleSelect, platformUpdates = {} }) => {
	return (
		<div className={"platforms-selection" + (loading && platforms.length !== 0 ? " disabled" : "")}>
			<div className="section-title">Select Platform(s)</div>
			<div className="platforms-list">
				{platforms.length > 0 &&
					platforms.map((platform, i) => (
						<div
							key={i}
							className={"platform-container" + (platformUpdates[platform.id] ? " selected" : "")}
							onClick={() => handleSelect(platform, !platformUpdates[platform.id])}
						>
							<CheckBox
								checked={platformUpdates[platform.id]}
								clickHandler={(e) => {
									handleSelect(platform, !platformUpdates[platform.id]);
									e.stopPropagation();
								}}
							/>
							<div className="platform-logo">
								<img
									src={
										platform?.logo ||
										CATALOGUE_PLATFORMS_LOGO[platform.platformName.toLowerCase()] ||
										"/assets/icons/icons8-globe-40.png"
									}
									alt=""
								/>
							</div>
							<div className="platform-name" title={platform.platformName}>
								{platform.platformName}
							</div>
						</div>
					))}
				{loading && platforms.length === 0 && (
					<div className="loading-shimmer">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{!loading && platforms.length === 0 && <div className="no-items-placeholder">No platforms found!</div>}
			</div>
		</div>
	);
};
