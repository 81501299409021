import React, { useEffect, useState } from "react";

// components
import { Switch } from "../components/_commons/Switch";
import { Button } from "../components/_commons/Button";

// third party
import { connect } from "react-redux";

// actions
import { createMerakiWebSubscription, fetchMerakiWebSubscriptionCustomerPortal } from "../actions/merakiWeb";

const MerakiPlansBilling = ({ merakiWebSubscription }) => {
	const [planTerm, setPlanTerm] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(undefined);
	const { data, loading } = merakiWebSubscription;

	useEffect(() => {
		if (
			data?.subscription &&
			!["cancelled", "not-subscribed", "legacy-billing"].includes(data?.subscription?.status)
		) {
			redirectToCustomerPortal();
		} else {
			setConfirmLoading(false);
		}
	}, [data.subscription]);

	const handleSubscribe = async (plan) => {
		const term = planTerm ? "yearly" : "monthly";
		setButtonLoading(plan);
		try {
			const resp = await createMerakiWebSubscription(plan, term);
			if (resp.redirect_url) {
				window.open(resp.redirect_url, "_self");
			}
		} catch (error) {
			console.log(error);
			setButtonLoading(undefined);
		}
	};

	const redirectToCustomerPortal = async () => {
		setConfirmLoading(true);
		try {
			const resp = await fetchMerakiWebSubscriptionCustomerPortal();
			if (resp.redirect_url) {
				window.open(resp.redirect_url, "_self");
			}
		} catch (error) {
			console.log(error);
			setConfirmLoading(false);
		}
	};

	if (loading || confirmLoading) {
		return (
			<div className="section-container-common P(25px) H(255px)">
				<div className="P(10px 0)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}

	return (
		<div className="meraki-plans-billing section-container-common">
			<div className="header">Choose a plan</div>
			<div className="description">
				We have a pricing model that suits everyone and provides the best value for money
			</div>
			<div className="plan-type">
				<div className={!planTerm ? "selected" : "type"}>Monthly</div>
				<Switch checked={planTerm} clickHandler={() => setPlanTerm(!planTerm)} />
				<div className={planTerm ? "selected" : "type"}>Yearly</div>
				<div className="offer">
					17% off
					<Arrow />
				</div>
			</div>
			<div className="plans-table-container">
				<div className="table-row">
					<div className="features text--header">Features</div>
					<div className="basic text--header">Basic</div>
					<div className="pro text--header">Pro</div>
				</div>
				<div className="table-row">
					<div className="features"></div>
					<div className="basic">
						<div className="subscription">{planTerm ? "$49 /mo" : "$59 /mo"}</div>
						<div className="commission">or 2% commission</div>
						<div className="text--small">(whichever is higher)</div>
					</div>
					<div className="pro">
						<div className="subscription">{planTerm ? "$119 /mo" : "$139 /mo"}</div>
						<div className="commission">or 2% commission</div>
						<div className="text--small">(whichever is higher)</div>
					</div>
				</div>
				<div className="table-row bordered">
					<div className="features">Catalogue Management</div>
					<div className="basic">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
					<div className="pro">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
				</div>
				<div className="table-row bordered">
					<div className="features">Website Editor</div>
					<div className="basic">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
					<div className="pro">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
				</div>
				<div className="table-row bordered">
					<div className="features">Order Tracker</div>
					<div className="basic">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
					<div className="pro">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
				</div>
				<div className="table-row bordered">
					<div className="features">Website</div>
					<div className="basic">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
					<div className="pro">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
				</div>
				<div className="table-row">
					<div className="features">Mobile Apps</div>
					<div className="basic">
						<img src="/assets/icons/icon-cross.svg" alt="" />
					</div>
					<div className="pro">
						<img src="/assets/icons/icon-check-blue.svg" alt="" />
					</div>
				</div>
				<div className="table-row">
					<div className="features"></div>
					<div className="basic">
						<Button
							classes={"W(150px)" + (buttonLoading ? " disabled" : "")}
							clickHandler={() => handleSubscribe("basic")}
							loading={buttonLoading === "basic"}
						>
							Subscribe
						</Button>
					</div>
					<div className="pro">
						<Button
							classes={"W(150px)" + (buttonLoading ? " disabled" : "")}
							clickHandler={() => handleSubscribe("pro")}
							loading={buttonLoading === "pro"}
						>
							Subscribe
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	merakiWebSubscription: store.merakiWebSubscription
});
export default connect(mapStateToProps)(MerakiPlansBilling);

const Arrow = () => {
	return (
		<svg
			className="arrow"
			width="60"
			height="37"
			viewBox="0 0 60 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="path"
				d="M58.5857 1.41953C57.4611 3.68342 56.3021 5.94731 55.0253 8.1285C54.0066 9.90503 52.8841 11.6193 51.6638 13.2624C47.1212 19.2801 35.8961 32.2722 27.6962 24.3325C22.4428 19.2468 24.8724 7.93964 31.9048 5.7387C42.9199 2.28978 50.1328 15.056 45.9254 24.3942C44.9138 26.6396 43.4086 28.6949 41.7094 30.4576C27.5722 45.1223 10.2171 24.8794 3.49902 12.5822"
				stroke="#18D0B2"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				className="head"
				d="M3.00782 24C2.68492 20.68 2.4283 17.3522 2.12538 14.0296C2.09909 13.7401 1.92659 11.0756 2.03597 11.0522C4.90629 10.4578 11.2843 15.1016 14 16.7928"
				stroke="#18D0B2"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
