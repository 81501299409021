// action types
import { ActionTypes } from "../actions/_types";

export const MERAKI_INITIAL_STATE = {
	loading: true,
	settingsData: {},
	featuresData: {},
	appsData: {},
	error: {}
};
export const merakiDetails = (state = MERAKI_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MERAKI_SETTINGS_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_MERAKI_SETTINGS_DETAIL_SUCCESS:
			if (action.payload.settingsData) {
				return {
					...state,
					loading: false,
					settingsData: action.payload.settingsData
				};
			} else if (action.payload.appsData) {
				return {
					...state,
					loading: false,
					appsData: action.payload.appsData
				};
			} else if (action.payload.featuresData) {
				return {
					...state,
					loading: false,
					featuresData: action.payload.featuresData
				};
			}
		case ActionTypes.GET_MERAKI_SETTINGS_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_MERAKI_SETTINGS_DETAIL:
			return {
				...state,
				settingsData: {
					...state.settingsData,
					...action.payload.settingsData
				},
				featuresData: {
					...state.featuresData,
					...action.payload.featuresData
				},
				appsData: {
					...state.appsData,
					...action.payload.appsData
				}
			};
		case ActionTypes.GET_MERAKI_APPS_CONFIGURATIONS:
			return {
				...state,
				loading: false,
				appsData: {
					...state.appsData,
					businessConfigurations: action.payload.appsData.businessConfigurations
				}
			};
		case ActionTypes.UPDATE_MERAKI_APPS_CONFIGURATIONS:
			const businessConfigurations = state.appsData["businessConfigurations"];
			return {
				...state,
				appsData: {
					...state.appsData,
					bussinessConfigurations: businessConfigurations.map((platform) => {
						if (platform.name === action.payload.platform) {
							platform.keys.map((config) => {
								if (config.key === action.payload.field) {
									config.value = action.payload.value;
								}
								return config;
							});
						}
						return platform;
					})
				}
			};
		case ActionTypes.RESET_MERAKI_SETTINGS_DETAIL:
			return MERAKI_INITIAL_STATE;
		case ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
