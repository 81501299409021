import React from "react";

// third party
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// components
import { LocationsList } from "./LocationsList";
import LocationCreate from "./LocationCreate";
import LocationEdit from "./LocationEdit";
import LocationCreateWizard from "./LocationCreateWizard";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/LocationsList/Header";

// constants
import { LOCATION_CREATION_DISABLED_COUNTRIES } from "../components/LocationsList/Header";

const ACCESS_ALLOWED_BIZ_IDS = {
	76720224: true,
	44007425: true,
	18480538: true,
	46759187: true,
	71574355: true,
	22694411: true,
	85772719: true,
	25651076: true,
	90718154: true,
	87361915: true
};

const LocationsContainer = ({ hasAccess = false, bizId, bizCountry, isInternalUser = false, restrictView = false }) => {
	const location = useLocation();
	return (
		<div className="locations-container">
			{restrictView ? (
				<Route
					path="/locations"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} isLocations={true} />}
				/>
			) : (
				<React.Fragment>
					<Route
						exact={location?.pathname === "/locations/new"}
						path="/locations"
						component={LocationsList}
					/>
					<Route
						exact
						path="/locations/new"
						render={() =>
							hasAccess &&
							(isInternalUser || !LOCATION_CREATION_DISABLED_COUNTRIES.includes(bizCountry)) ? (
								<LocationCreate hasAccess={hasAccess} />
							) : (
								<Redirect to="/locations" />
							)
						}
					/>
					<Route exact path="/locations/edit/:id" component={LocationEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	bizId: store?.login?.loggedInbizDetail?.id,
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(LocationsContainer);
