// clients
import { client } from "../client";
import { store } from "../store/configureStore";
import moment from "moment";
// graphql
import {
	OPERATIONAL_EFFICIENCY_METRICS,
	OPERATIONAL_EFFICIENCY_LIST_VIEW,
	OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL
} from "../graphql/operationalEfficiency";

// actions
import { ActionTypes } from "./_types";

export const getDurationObject = (appliedDateFilter) => {
	const { dateFilter: currDateFilter } = appliedDateFilter.current;
	const { dateFilter: compDateFilter } = appliedDateFilter.compare;
	const currDates = currDateFilter.split(",");
	const compDates = compDateFilter.split(",");
	const obj = {};

	// for current date range
	if (currDates.length === 1) {
		obj.duration = {
			preset: currDateFilter
		};
	} else if (currDates[0] && currDates[1]) {
		obj.duration = {
			custom: {
				start: currDates[0],
				end: currDates[1]
			}
		};
	}

	// for comparison date range
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		obj.comparisonDuration = {
			start: compDates[0],
			end: compDates[1]
		};
	}

	return obj;
};

export const fetchOperationalEfficiencyMetrics = async (props, fetchPolicy = "cache-first", brand = null) => {
	store.dispatch({
		type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_REQUEST
	});
	try {
		const { appliedDateFilter, appliedFilters } = props;
		const durationObject = getDurationObject(appliedDateFilter);
		const filters = appliedFilters ? Object.values(appliedFilters) : [];
		const variables = {
			...durationObject,
			filters,
			limit: 10,
			offset: 0,
			sortKey: "percentage_cancelled"
		};
		if (brand !== null) {
			variables.brand = brand.id;
		}
		const resp = await client.query({
			query: OPERATIONAL_EFFICIENCY_METRICS,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_SUCCESS,
			payload: resp.data.orderStatusMetrics.bizLocationLevel
		});
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_SUCCESS,
			payload: resp.data.orderStatusMetrics.bizLevel
		});
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_FILTERS_SUCCESS,
			payload: resp.data.orderStatusMetrics.filters
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_FILTERS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOperationalEfficiencyMetricsLocationLevel = async (
	props,
	fetchPolicy = "cache-first",
	brand = null
) => {
	store.dispatch({
		type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_REQUEST
	});
	try {
		const { appliedDateFilter, appliedFilters, limit, offset, sortKey, search } = props;
		const durationObject = getDurationObject(appliedDateFilter);
		const filters = appliedFilters ? Object.values(appliedFilters) : [];
		const variables = {
			...durationObject,
			filters,
			limit,
			offset,
			sortKey,
			search
		};
		if (brand !== null) {
			variables.brand = brand.id;
		}
		const resp = await client.query({
			query: OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_SUCCESS,
			payload: resp.data.orderStatusMetrics.bizLocationLevel
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOperationalEfficiencyListView = async (props, fetchPolicy = "cache-first") => {
	store.dispatch({
		type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_REQUEST
	});
	try {
		const { appliedDateFilter, limit, offset, appliedFilters, bizLocationId, sortKey } = props;
		const durationObject = getDurationObject(appliedDateFilter);
		const filters = appliedFilters ? Object.values(appliedFilters) : [];
		const variables = {
			...durationObject,
			limit,
			offset,
			filters,
			bizLocationId,
			sortKey
		};
		const resp = await client.query({
			query: OPERATIONAL_EFFICIENCY_LIST_VIEW,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_SUCCESS,
			payload: resp.data.orderStatusList
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};
