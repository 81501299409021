import React, { useState } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";

// graphql
import { PASS_RESET_TKN, CHANGE_PASS } from "../../graphql/auth";

// store
import { store } from "../../store/configureStore";
import { msaagesArrayToHtml } from "../../atlas-utils";

// third party
import { client } from "../../client";
import PubSub from "pubsub-js";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";
import { ActionTypes } from "../../actions/_types";

// constants
const RESET_PASS_TABS = ["sendOTP", "changePass", "success"];

const ResetPass = ({ data = {}, loading = false, switchTab, handleChange, setLoading, phoneNumberValidation }) => {
	const [activeTab, setActiveTab] = useState(RESET_PASS_TABS[0]);

	return (
		<React.Fragment>
			<div className="form-header">Password Reset</div>
			{activeTab === RESET_PASS_TABS[0] && (
				<GetToken
					loading={loading}
					setLoading={setLoading}
					email={data.email}
					handleChange={handleChange}
					switchTab={(tab) => setActiveTab(tab)}
					tabs={RESET_PASS_TABS}
					switchParentTab={switchTab}
					phoneNumberValidation={phoneNumberValidation}
				/>
			)}
			{activeTab === RESET_PASS_TABS[1] && (
				<ChangePass
					loading={loading}
					setLoading={setLoading}
					email={data.email}
					token={data.token}
					newPassword={data.newPassword}
					newPasswordConfirm={data.newPasswordConfirm}
					handleChange={handleChange}
					switchTab={(tab) => setActiveTab(tab)}
					tabs={RESET_PASS_TABS}
					switchParentTab={switchTab}
					phoneNumberValidation={phoneNumberValidation}
				/>
			)}
			{activeTab === RESET_PASS_TABS[2] && (
				<ResetSuccess switchTab={switchTab} phoneNumberValidation={phoneNumberValidation} />
			)}
		</React.Fragment>
	);
};
export default ResetPass;

const ResetSuccess = ({ switchTab }) => (
	<React.Fragment>
		<div className="form-container">
			<div className="reset-success">
				<img className="icon" src="/assets/icons/icon-check.svg" />
				<div className="msg">Your password has been reset successfully!</div>
			</div>
			<Button classes="login-submit" clickHandler={() => switchTab()}>
				Back to Login
			</Button>
		</div>
	</React.Fragment>
);

class GetToken extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email_validation_message: false
		};
	}

	removeValidationState() {
		this.setState({
			email_validation_message: false
		});
	}

	async getToken() {
		let { email, switchTab, tabs } = this.props;
		let variables = { username: email };
		let _state = {};
		let _error = false;
		if (!this.props.email) {
			_error = true;
			_state.email_validation_message = "Enter valid email/phone";
		}
		this.setState(_state);
		if (_error) {
			return;
		}

		// do network call to get token
		store.dispatch({
			type: "LOGIN_FORM_LOAD_INIT",
			payload: null
		});
		this.props.setLoading(true);
		try {
			const resp = await client.mutate({
				mutation: PASS_RESET_TKN,
				variables: variables
			});
			if (resp && resp.data.sendPasswordResetToken.status.success) {
				// track this event
				PubSub.publish(TRACK_EVENT, {
					tracker: "mixpanel",
					eventName: "forgot_password",
					eventMeta: {
						user_name: email
					}
				});

				// change view since token is sent
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Token sent successfully",
						timeout: 5000,
						error: false
					}
				});
				switchTab(tabs[1]);
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.sendPasswordResetToken.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
		} catch (error) {
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		this.props.setLoading(false);
	}

	render() {
		let { email, handleChange, switchTab, tabs, switchParentTab, loading, phoneNumberValidation } = this.props;
		return (
			<React.Fragment>
				<div className="form-container">
					<InputWithLabel
						classes="login-text-input"
						placeholder="hello@example.com / +919876543210"
						value={email}
						name="email"
						onChange={(e) => handleChange("email", e.target.value)}
						onFocus={(e) => {
							this.removeValidationState(e);
						}}
						validationMessage={
							this.state.email_validation_message ||
							(phoneNumberValidation?.endResultType === "failure" ? "Enter a valid phone number" : "")
						}
						onEnter={() => this.getToken()}
						showLoading={phoneNumberValidation?.loading}
						showEndResult={phoneNumberValidation?.showPhoneNumberValidation}
						endResultType={phoneNumberValidation?.endResultType}
					>
						Email/Phone
					</InputWithLabel>
					<div className="btn-actions">
						<Button
							classes={(loading ? "disabled" : "") + " login-submit"}
							clickHandler={() => this.getToken()}
							loading={loading}
						>
							Send Token
						</Button>
						<Button
							classes={(loading ? "disabled" : "") + " login-submit"}
							type="secondary"
							clickHandler={() => switchTab(tabs[1])}
						>
							I have a Token
						</Button>
					</div>
					<div className="back-to-login font--small" onClick={() => switchParentTab()}>
						Back to Login
					</div>
				</div>
			</React.Fragment>
		);
	}
}

class ChangePass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email_validation_message: false,
			token_validation_message: false,
			newPassword_validation_message: false,
			newPasswordConfirm_valiadtion_message: false
		};
	}

	removeValidationState(e, str) {
		this.setState({
			[str]: false
		});
	}

	async resendToken() {
		let { email } = this.props;
		let variables = { username: email };
		let _state = {};
		let _error = false;
		if (!this.props.email) {
			_error = true;
			_state.email_validation_message = "Enter valid email/username";
		}
		this.setState(_state);
		if (_error) {
			return;
		}

		store.dispatch({
			type: "LOGIN_FORM_LOAD_INIT",
			payload: null
		});
		try {
			const resp = await client.mutate({
				mutation: PASS_RESET_TKN,
				variables: variables
			});
			if (resp && resp.data.sendPasswordResetToken.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Token sent successfully",
						timeout: 5000,
						error: false
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.sendPasswordResetToken.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
		} catch (error) {
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	}

	handleSubmit = async () => {
		const { email, token, newPassword, newPasswordConfirm, switchTab, tabs } = this.props;

		// Validate info and generate error message if any
		let _state = {};
		let error = false;
		if (!email) {
			error = true;
			_state.email_validation_message = "Enter valid email/username";
		} else if (!token) {
			error = true;
			_state.token_validation_message = "Token can't be blank";
		} else if (!newPassword) {
			error = true;
			_state.newPassword_validation_message = "Password can't be blank";
		} else if (!newPasswordConfirm) {
			error = true;
			_state.newPasswordConfirm_valiadtion_message = "Password can't be blank";
		} else if (newPassword != newPasswordConfirm) {
			error = true;
			_state.newPasswordConfirm_valiadtion_message = "Passwords didn't match";
		}
		this.setState(_state);
		if (error) {
			return;
		}

		store.dispatch({
			type: "LOGIN_FORM_LOAD_INIT",
			payload: null
		});
		this.props.setLoading(true);
		try {
			const variables = {
				username: email,
				token,
				newPassword
			};
			const resp = await client.mutate({
				mutation: CHANGE_PASS,
				variables: variables
			});
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
			if (resp.data.changePassword.status.success) {
				switchTab(tabs[2]);
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.changePassword.status.messages) || "Something went wrong",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		this.props.setLoading(false);
	};

	render() {
		const { email, token, newPassword, newPasswordConfirm, handleChange, loading, switchParentTab } = this.props;
		return (
			<React.Fragment>
				<div className="form-container">
					<InputWithLabel
						classes="login-text-input"
						placeholder="hello@example.com"
						value={email}
						name="email"
						type="email"
						onFocus={(e) => this.removeValidationState(e, "email_validation_message")}
						validationMessage={this.state.email_validation_message}
						onChange={(e) => handleChange("email", e.target.value)}
					>
						Email/Username
					</InputWithLabel>
					<InputWithLabel
						classes="login-text-input Pos(r)"
						value={token}
						name="token"
						type="text"
						onFocus={(e) => this.removeValidationState(e, "token_validation_message")}
						validationMessage={this.state.token_validation_message}
						onChange={(e) => handleChange("token", e.target.value)}
					>
						Token
					</InputWithLabel>
					<InputWithLabel
						classes="login-text-input"
						value={newPassword}
						name="newPassword"
						type="password"
						onFocus={(e) => this.removeValidationState(e, "newPassword_validation_message")}
						validationMessage={this.state.newPassword_validation_message}
						onChange={(e) => handleChange("newPassword", e.target.value)}
					>
						New Password
					</InputWithLabel>
					<InputWithLabel
						classes="login-text-input"
						value={newPasswordConfirm}
						name="newPasswordConfirm"
						type="password"
						onFocus={(e) => this.removeValidationState(e, "newPasswordConfirm_valiadtion_message")}
						validationMessage={this.state.newPasswordConfirm_valiadtion_message}
						onChange={(e) => handleChange("newPasswordConfirm", e.target.value)}
						onEnter={() => this.handleSubmit()}
					>
						Confirm New Password
					</InputWithLabel>
					<div className="btn-actions">
						<Button
							classes={(loading ? "disabled" : null) + " login-submit"}
							clickHandler={() => this.handleSubmit()}
							loading={loading}
						>
							Reset Password
						</Button>
						<Button
							classes={(loading ? "disabled" : null) + " login-submit"}
							type="secondary"
							clickHandler={() => this.resendToken()}
						>
							Resend Token
						</Button>
					</div>
					<div className="back-to-login font--small" onClick={() => switchParentTab()}>
						Back to Login
					</div>
				</div>
			</React.Fragment>
		);
	}
}
