import React, { Component } from "react";

// components
import { Header } from "../components/WebhooksList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";
import moment from "moment";

// actions
import { fetchWebhooksList } from "../actions/webhooks";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll } from "../atlas-utils";

// constant
import { TRACK_EVENT } from "../atlas-utils/tracking";

const columns = [
	{
		name: "URL",
		field: "url",
		render: (record, i, archived) => {
			const regex = /(https?:\/\/[\w.-]+)/g;
			const match = record.url ? regex.exec(record.url) : null;
			return (
				<div className="at-table-cell at-cell-text url" title={record.url} key={i}>
					<Link className={"link-text " + archived} to={`/webhooks/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">
							{(match && match[0]) || record.url || record.id}
						</span>
					</Link>
				</div>
			);
		}
	},
	{
		name: "Event",
		field: "event",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text event" key={i}>
				{record.eventTypeDisplayName || "--"}
			</div>
		)
	},
	{
		name: "Created at",
		field: "created_at",
		sortKey: "created",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text created_at" key={i}>
				{record.created ? moment(record.created).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	}
];

@connect((store) => ({
	webhooksList: store.webhooksList,
	webhooksListState: store.webhooksListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class WebhooksList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: {
				created: false
			},
			showFilters: false,
			storesLookup: {},
			archivedFilter: false
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "webhooks_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.webhooksListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Webhooks list
		await fetchWebhooksList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatewebhooksListState = (payload) => {
		store.dispatch({
			type: ActionTypes.WEBHOOKS_LIST_STATE_CHANGE,
			payload
		});
	};

	updateDateFilter = (payload, field) => {
		if (this.state.isOpen[field]) {
			const { currentDateFilter } = this.props.webhooksListState;
			store.dispatch({
				type: ActionTypes.WEBHOOKS_LIST_STATE_CHANGE,
				payload: {
					currentDateFilter: {
						...currentDateFilter,
						[field]: payload.currentDateFilter
					}
				}
			});
		}
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatewebhooksListState({
			currentFilters: this.props.webhooksListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.webhooksListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatewebhooksListState({
			currentFilters
		});
	};

	handleDropdown = (e, field) => {
		e.stopPropagation();
		if (!this.state.isOpen[field]) {
			let { isOpen } = this.state;
			Object.keys(isOpen).map((df) => {
				isOpen[df] = false;
			});
			isOpen[field] = true;
			this.setState({ isOpen });
		} else {
			this.closeDropdown(field);
		}
	};

	closeDropdown = (field) => {
		if (this.state.isOpen[field]) {
			const { currentDateFilter, appliedDateFilter } = this.props.webhooksListState;
			this.updatewebhooksListState({
				currentDateFilter: {
					...currentDateFilter,
					[field]: appliedDateFilter[field]
				}
			});
			this.setState({
				isOpen: {
					...this.state.isOpen,
					[field]: false
				}
			});
		}
	};

	applyDateRange = (field) => {
		this.setState({
			isOpen: {
				...this.state.isOpen,
				[field]: false
			}
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.WEBHOOKS_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.WEBHOOKS_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.updatewebhooksListState({
			offset: 0
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchWebhooksList(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatewebhooksListState({
			appliedFilters: {
				...this.props.webhooksListState.currentFilters
			},
			appliedDateFilter: {
				...this.props.webhooksListState.currentDateFilter
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "webhooks_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchWebhooksList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().webhooksListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				store.dispatch({
					type: ActionTypes.WEBHOOKS_LIST_STATE_RESET
				});
				await fetchWebhooksList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.webhooksListState;
		const offset = (page - 1) * limit;
		this.updatewebhooksListState({
			offset
		});
		// fetch new Webhooks list
		fetchWebhooksList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.webhooksListState;
		if (size && size?.value !== limit) {
			this.updatewebhooksListState({
				[field]: size.value
			});
			// fetch new Webhooks list
			await fetchWebhooksList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatewebhooksListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.WEBHOOKS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchWebhooksList();
	};

	updateStoresLookup = (id, title) => {
		this.setState({
			storesLookup: {
				...this.state.storesLookup,
				[id]: title
			}
		});
	};

	render() {
		const { webhooksList, webhooksListState, configItems, access = {} } = this.props;
		const { limit, offset, currentFilters, currentDateFilter, sortedField } = webhooksListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (f === "is_active" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f].value && currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}
		for (let f in currentDateFilter) {
			if (currentDateFilter[f].dateFilter) {
				filterCount++;
			}
		}

		const filterOptions = webhooksList.data.filters.filter((f) => f.field !== "title");

		return (
			<div className="webhooks-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={filterOptions}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
						isDropdownOpen={this.state.isOpen}
						handleDropdown={this.handleDropdown}
						closeDropdown={this.closeDropdown}
						applyDateRange={this.applyDateRange}
						currentDateFilter={currentDateFilter}
						updateListState={this.updateDateFilter}
						storeRootField="webhooksListState"
					/>
				)}
				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
					searchKeywords={webhooksList.data.searchKeywords}
					searchFieldSelected={webhooksList.data.searchFieldSelected}
					searchFieldValue={webhooksList.data.searchFieldValue}
					handleSearchField={this.handleSearchField}
					setFilter={this.setSearchFilter}
					applySearchFilter={this.applySearchFilter}
				/>
				<CommonTable
					loading={webhooksList.loading}
					data={webhooksList.data.objects || []}
					columns={columns}
					sortList={this.sortList}
					sortedField={sortedField}
					archived={this.state.archivedFilter ? "archived" : ""}
					classes="webhooks-list-table-container"
					content="Webhooks"
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={webhooksList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
			</div>
		);
	}
}
