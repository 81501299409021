import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/CouponEdit/BasicInfo";
import Rules from "../components/CouponEdit/Rules";
import Actions from "../components/CouponEdit/Actions";
import BulkCoupons from "../components/CouponEdit/BulkCoupons";

// third party
import { connect } from "react-redux";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { removeProp, adjustNestedContainer } from "../atlas-utils";

// graphql
import {
	GET_COUPON_TYPES,
	GET_ORDERING_RULES,
	GET_COUPON_RULES,
	GET_PIPER_ACTIONS,
	GET_COUPON_ACTIONS
} from "../graphql/coupons";

// actions
import {
	fetchCouponsList,
	fetchCouponDetail,
	editCouponDetail,
	updateCouponRules,
	updateCouponActions,
	updateCouponActionIncludeOption,
	generateBulkCoupons
} from "../actions/coupons";
import { fetchCiModifiers } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// reducers
import { couponDetailsReducer, COUPON_DETAILS_INITIAL_STATE } from "../reducers/coupons";

// constants
const INPUT_TYPE_MAP = {
	boolean: "False",
	string: "",
	list: [],
	int: "",
	decimal: ""
};

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Rules",
		value: "rules"
	},
	{
		label: "Actions",
		value: "actions"
	},
	{
		label: "Bulk Coupons",
		value: "bulk"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const CouponEdit = ({ biz, match, isNested = false, closeNestedContainer, connectedRef }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isRulesFormTouched, setRulesFormTouched] = useState(false);
	const [isActionsFormTouched, setActionsFormTouched] = useState(false);
	const [isBulkFormTouched, setBulkFormTouched] = useState(false);
	const [isFormOpen, setFormState] = useState(false);
	const [couponDetails, dispatch] = useReducer(couponDetailsReducer, COUPON_DETAILS_INITIAL_STATE);
	const { loading, data, error } = couponDetails;
	const [couponTypes, setCouponTypes] = useState([]);
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [orderingRules, setOrderingRules] = useState([]);
	const [couponRules, setCouponRules] = useState([]);
	const [rulesToDelete, setRulesToDelete] = useState([]);
	const [piperActions, setPiperActions] = useState([]);
	const [couponActions, setCouponActions] = useState({});
	const [bulkCoupons, setBulkCoupons] = useState({
		generateCouponCodes: true,
		maxUsage: "",
		numCodes: "",
		staticCodes: [],
		codesValidationMsg: "",
		readOnly: false
	});
	const nestedRef = useRef();
	const bottomRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiModifiers("");
	}, []);

	useEffect(() => {
		fetchCouponTypes();
	}, []);

	useEffect(() => {
		if (couponTypes.length) {
			fetchCouponDetail(parseInt(match.params.id), couponTypes, dispatch);
		}
	}, [match.params.id, couponTypes]);

	const fetchCouponTypes = async () => {
		try {
			const resp = await client.query({
				query: GET_COUPON_TYPES,
				fetchPolicy: "no-cache"
			});
			setCouponTypes(resp.data.couponTypes);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const fetchOrderingRules = async () => {
		try {
			const resp = await client.query({
				query: GET_ORDERING_RULES,
				fetchPolicy: "no-cache"
			});
			setOrderingRules(resp.data.orderingRules);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	useEffect(() => {
		fetchOrderingRules();
	}, []);

	const fetchCouponRules = useCallback(async () => {
		try {
			const variables = {
				id: parseInt(match.params.id)
			};
			const resp = await client.query({
				query: GET_COUPON_RULES,
				variables,
				fetchPolicy: "no-cache"
			});
			if (orderingRules.length) {
				const sanitisedData = resp.data.coupon.rules.map((rule) => {
					rule.isNewRule = false;
					if (rule.parameters.length === 0) {
						rule.parameters = orderingRules
							.find((or) => or.ruleName === rule.ruleName)
							.parameters.map((param) => ({
								paramName: param.name,
								paramValue: INPUT_TYPE_MAP[param.inputType],
								multiple: param.multiple
							}));
					} else {
						rule.parameters = rule.parameters
							.filter(
								(param) =>
									orderingRules
										.find((or) => or.ruleName === rule.ruleName)
										.parameters.find((p) => p.name === param.paramName) !== undefined
							)
							.map((param) => {
								const parameter = orderingRules
									.find((or) => or.ruleName === rule.ruleName)
									.parameters.find((p) => p.name === param.paramName);
								if (parameter && parameter.multiple) {
									if (param.paramValue && param.paramValue.includes("[]")) {
										param.multiple = true;
										param.paramValue = [];
									} else if (param.paramValue) {
										param.multiple = true;
										param.paramValue = param.paramValue.split(",").map((val) => {
											val = parameter.choices.find((choice) => choice.value === val);
											return val;
										});
									} else {
										param.multiple = true;
										param.paramValue = [];
									}
								}
								return param;
							});
					}
					return rule;
				});
				setCouponRules(sanitisedData);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	}, [match.params.id, orderingRules]);

	useEffect(() => {
		fetchCouponRules();
	}, [fetchCouponRules]);

	const fetchPiperActions = async () => {
		try {
			const resp = await client.query({
				query: GET_PIPER_ACTIONS,
				fetchPolicy: "no-cache"
			});
			setPiperActions(resp.data.piperActions);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	useEffect(() => {
		fetchPiperActions();
	}, []);

	const fetchCouponActions = useCallback(async () => {
		if (piperActions.length) {
			try {
				const variables = {
					id: parseInt(match.params.id)
				};
				let resp = await client.query({
					query: GET_COUPON_ACTIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				if (resp.data.coupon.actions.length === 0) {
					const newCouponActions = { ...piperActions[0] };
					newCouponActions.parameters = newCouponActions.parameters.map((param) => ({
						paramName: param.name,
						paramValue:
							param.inputType === "list"
								? param.multiple
									? INPUT_TYPE_MAP[param.inputType]
									: ""
								: INPUT_TYPE_MAP[param.inputType],
						multiple: param.inputType === "list" ? true : param.multiple
					}));
					newCouponActions.parameters.push({
						paramName: "PA_COUPON_ITEMS_LIST",
						paramValue: ""
					});
					newCouponActions.parameters.push({
						paramName: "PA_COUPON_IGNORE_ITEM_OPTIONS",
						paramValue: ""
					});
					setCouponActions(newCouponActions);
				} else if (resp.data.coupon.actions.length) {
					resp.data.coupon.actions.map((action) => {
						if (action.parameters.length === 0) {
							action.parameters = piperActions
								.find((pa) => pa.actionName === action.actionName)
								.parameters.map((param) => ({
									paramName: param.name,
									paramValue:
										param.inputType === "list"
											? param.multiple
												? INPUT_TYPE_MAP[param.inputType]
												: ""
											: INPUT_TYPE_MAP[param.inputType],
									multiple: param.inputType === "list" ? true : param.multiple
								}));
							if (action.actionName === 29) {
								action.parameters.push({
									paramName: "PA_COUPON_ITEMS_LIST",
									paramValue: ""
								});
							} else if (action.actionName === 31) {
								action.parameters.push({
									paramName: "PA_COUPON_FREEBIE_ITEMS_TO_CHECK",
									paramValue: ""
								});
								action.parameters.push({
									paramName: "PA_COUPON_FREEBIE_ITEMS_TO_DISCOUNT",
									paramValue: ""
								});
							}
							action.parameters.push({
								paramName: "PA_COUPON_IGNORE_ITEM_OPTIONS",
								paramValue: ""
							});
						} else {
							let missingParams = {
								29: ["PA_COUPON_ITEMS_LIST", "PA_COUPON_IGNORE_ITEM_OPTIONS"],
								31: [
									"PA_COUPON_FREEBIE_ITEMS_TO_CHECK",
									"PA_COUPON_FREEBIE_ITEMS_TO_DISCOUNT",
									"PA_COUPON_IGNORE_ITEM_OPTIONS"
								]
							};
							action.parameters.map((param) => {
								const parameter = piperActions
									.find((pa) => pa.actionName === action.actionName)
									.parameters.find((p) => p.name === param.paramName);
								if (parameter && parameter.multiple) {
									if (param.paramValue && param.paramValue.includes("[]")) {
										param.multiple = true;
										param.paramValue = [];
									} else if (param.paramValue) {
										param.multiple = true;
										param.paramValue = param.paramValue.split(",").map((val) => {
											val = parameter.choices.find((choice) => choice.value === val);
											return val;
										});
									} else {
										param.multiple = true;
										param.paramValue = [];
									}
								} else if (parameter && !parameter.multiple && parameter.choices.length) {
									if (String(param.paramValue) !== "") {
										param.multiple = true;
										param.paramValue = parameter.choices.find(
											(choice) => choice.value === String(param.paramValue).toLowerCase()
										);
									} else {
										param.multiple = true;
										param.paramValue = "";
									}
								}
								if (missingParams[action.actionName].includes(param.paramName)) {
									missingParams[action.actionName] = missingParams[action.actionName].filter(
										(field) => field !== param.paramName
									);
								}
								return param;
							});
							missingParams[action.actionName].map((param) => {
								action.parameters.push({
									paramName: param,
									paramValue: ""
								});
							});
						}
						return action;
					});
					setCouponActions(resp.data.coupon.actions[0]);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
		}
	}, [match.params.id, piperActions]);

	useEffect(() => {
		fetchCouponActions();
	}, [fetchCouponActions]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchCouponsList();
					history.push("/coupons");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value, index, parameter) => {
		if (formTab === FORM_TABS[0].value) {
			dispatch({
				type: ActionTypes.UPDATE_COUPON_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		} else if (formTab === FORM_TABS[1].value) {
			if (field === "ruleName") {
				if (
					value &&
					couponRules.filter((cr) => cr.ruleName && cr.ruleName.ruleName === value.ruleName).length
				) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "The selected rule has already been added",
							timeout: 3000,
							error: true
						}
					});
				} else {
					const updatedCouponRules = couponRules.map((cr, i) => {
						if (i === index) {
							cr.ruleName = value;
							if (value) {
								cr.parameters = value.parameters.map((param) => ({
									paramName: param.name,
									paramValue: INPUT_TYPE_MAP[param.inputType],
									multiple: param.multiple
								}));
							} else {
								cr.parameters = [];
							}
						}
						return cr;
					});
					setCouponRules(updatedCouponRules);
				}
			} else {
				const updatedCouponRules = couponRules.map((cr, i) => {
					if (i === index) {
						if (cr.parameters.find((param) => param.paramName === field)) {
							cr.parameters.find((param) => param.paramName === field).paramValue = value;
						} else {
							cr.parameters = [
								...cr.parameters,
								{
									paramName: field,
									paramValue: value
								}
							];
						}
					}
					return cr;
				});
				setCouponRules(updatedCouponRules);
			}
			if (!isRulesFormTouched) {
				setRulesFormTouched(true);
			}
		} else if (formTab === FORM_TABS[2].value) {
			if (field === "action") {
				const couponActions = { ...value };
				couponActions.parameters = couponActions.parameters.map((param) => ({
					paramName: param.name,
					paramValue:
						param.inputType === "list"
							? param.multiple
								? INPUT_TYPE_MAP[param.inputType]
								: ""
							: INPUT_TYPE_MAP[param.inputType],
					multiple: param.inputType === "list" ? true : param.multiple
				}));
				if (couponActions.actionName === 29) {
					couponActions.parameters.push({
						paramName: "PA_COUPON_ITEMS_LIST",
						paramValue: ""
					});
				} else if (couponActions.actionName === 31) {
					couponActions.parameters.push({
						paramName: "PA_COUPON_FREEBIE_ITEMS_TO_CHECK",
						paramValue: ""
					});
					couponActions.parameters.push({
						paramName: "PA_COUPON_FREEBIE_ITEMS_TO_DISCOUNT",
						paramValue: ""
					});
				}
				couponActions.parameters.push({
					paramName: "PA_COUPON_IGNORE_ITEM_OPTIONS",
					paramValue: ""
				});
				setCouponActions(couponActions);
			} else {
				const updatedCouponActions = { ...couponActions };
				let flag = true;
				updatedCouponActions.parameters.map((param) => {
					if (param.paramName === field) {
						param.paramValue = value;
						flag = false;
					}
					return param;
				});
				if (flag) {
					updatedCouponActions.parameters.push({
						paramName: field,
						paramValue: value,
						multiple: parameter.inputType === "list" ? true : parameter.multiple
					});
				}
				setCouponActions(updatedCouponActions);
			}
			if (!isActionsFormTouched) {
				setActionsFormTouched(true);
			}
		} else if (formTab === FORM_TABS[3].value) {
			setBulkCoupons({
				...bulkCoupons,
				[field]: value
			});
			if (!isBulkFormTouched) {
				setBulkFormTouched(true);
			}
		}
	};

	const addNewRule = () => {
		const newRule = {
			ruleName: null,
			parameters: [],
			isNewRule: true
		};
		setCouponRules([...couponRules, newRule]);
		// scroll to the bottom
		setTimeout(() => {
			bottomRef.current.scrollIntoView({ behavior: "smooth" });
		}, 100);
	};

	const handleDeleteRule = (index, ruleId) => {
		let updatedRulesToDelete = rulesToDelete;
		if (ruleId) {
			updatedRulesToDelete.push(ruleId);
			setRulesToDelete(updatedRulesToDelete);
		}
		const updatedCouponRules = couponRules.filter((cr, i) => i !== index);
		setCouponRules(updatedCouponRules);
		if (!isRulesFormTouched) {
			setRulesFormTouched(true);
		}
	};

	const handleSubmit = async (entityType, itemOptions = undefined) => {
		if (formTab === FORM_TABS[0].value) {
			const sanitisedData = removeProp(data, "__typename");
			if (sanitisedData.merchantRefId === "") {
				sanitisedData.merchantRefId = "-1";
			}
			const resp = await editCouponDetail(sanitisedData, dispatch);
			if (resp) {
				setFormTouched(false);
			}
		} else if (formTab === FORM_TABS[1].value) {
			setConfirmLoading(true);
			const sanitisedData = removeProp(
				couponRules.filter((rule) => rule.ruleName !== null),
				"__typename"
			);
			const resp = await updateCouponRules(parseInt(match.params.id), sanitisedData, rulesToDelete);
			if (resp) {
				setRulesFormTouched(false);
				setRulesToDelete([]);
				fetchCouponRules();
			}
			setConfirmLoading(false);
		} else if (formTab === FORM_TABS[2].value) {
			if (itemOptions) {
				let variables = {
					couponId: parseInt(match.params.id),
					pActionId: couponActions.actionName,
					paramType: entityType.paramType,
					paramValue: Object.keys(itemOptions).map((itemId) => ({
						id: parseInt(itemId),
						options: itemOptions[itemId]
					}))
				};
				const resp = await updateCouponActionIncludeOption(variables);
				if (resp) {
					setActionsFormTouched(false);
					fetchCouponActions();
					return true;
				}
			} else {
				setConfirmLoading(true);
				let sanitisedData = removeProp(couponActions, "__typename");
				const resp = await updateCouponActions(parseInt(match.params.id), sanitisedData, dispatch);
				if (resp) {
					setActionsFormTouched(false);
					fetchCouponActions();
				}
				setConfirmLoading(false);
			}
		} else if (formTab === FORM_TABS[3].value) {
			setConfirmLoading(true);
			let variables = {
				couponId: parseInt(match.params.id),
				maxUsage: bulkCoupons.maxUsage
			};
			if (bulkCoupons.generateCouponCodes) {
				variables.numCodes = bulkCoupons.numCodes;
			} else {
				variables.staticCodes = bulkCoupons.staticCodes.join(",");
			}
			const resp = await generateBulkCoupons(variables);
			if (resp) {
				setBulkFormTouched(false);
				setBulkCoupons({
					...bulkCoupons,
					readOnly: true
				});
				if (couponTypes.length) {
					fetchCouponDetail(parseInt(match.params.id), couponTypes, dispatch);
				}
			}
			setConfirmLoading(false);
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_COUPON_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	return (
		<div className="coupon-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.rewardTitle || "Coupon"}
				subTitle="Edit this coupon"
				submitTitle={formTab === FORM_TABS[3].value ? "Generate" : "Save"}
				loading={formTab === FORM_TABS[2].value && isModalBusy ? false : loading || confirmLoading}
				isNested={isNested}
				hideActions={
					(formTab === FORM_TABS[0].value && !isFormTouched) ||
					(formTab === FORM_TABS[1].value && !isRulesFormTouched) ||
					(formTab === FORM_TABS[2].value && !isActionsFormTouched) ||
					(formTab === FORM_TABS[3].value && !isBulkFormTouched)
				}
				headerRight={
					<Button
						classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
						clickHandler={() => setArchiveRestore(true)}
					>
						{data.isActive ? "Archive" : "Restore"}
					</Button>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
					hiddenTabs={data?.rewardType?.value === "CASH_DISCOUNT" ? ["actions"] : []}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							couponTypes={couponTypes}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<Rules
							couponRules={couponRules}
							orderingRules={orderingRules}
							handleForm={handleForm}
							addNewRule={addNewRule}
							handleDeleteRule={handleDeleteRule}
							loading={loading}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<Actions
							couponId={parseInt(match.params.id)}
							couponActions={couponActions}
							piperActions={piperActions}
							handleForm={handleForm}
							handleSubmit={handleSubmit}
							loading={loading}
							setModalBusy={setModalBusy}
							isModalBusy={isModalBusy}
							fetchCouponActions={fetchCouponActions}
							isActionsFormTouched={isActionsFormTouched}
							saveActionsForm={handleSubmit}
							handleNestedEntity={handleNestedEntity}
							currencySymbol={biz.currencySymbol}
							validations={error.fields || {}}
						/>
					)}
					{formTab === FORM_TABS[3].value && (
						<BulkCoupons
							couponId={parseInt(match.params.id)}
							data={bulkCoupons}
							handleForm={handleForm}
							showDownloadCsv={data.hasValidationCodes || false}
						/>
					)}
					<div ref={bottomRef}></div>
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[13]}
						entityName={data.rewardTitle}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						fieldName="isActive"
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail
}))(CouponEdit);
