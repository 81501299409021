import gql from "graphql-tag";

export const GET_CATALOGUE_BACKUPS_LIST = gql`
	query getCatalogueBackupsList($limit: Int, $offset: Int, $sort: SortInput, $filters: [ListFilterArgument]) {
		catalogBackups(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
			count
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
			objects {
				id
				title
				description
				takenBy
				created
				creatorEmail
				updatedBy {
					id
					username
					fullName
					email
				}
				itemsCount
				optionGroupsCount
				optionsCount
				categoriesCount
			}
		}
	}
`;

export const CREATE_CATALOGUE_BACKUP = gql`
	mutation createCatalogueBackup($title: String!, $description: String, $deletableIds: [Int], $creatorEmail: String) {
		saveCatalogBackup(
			input: {
				title: $title
				description: $description
				deletableIds: $deletableIds
				creatorEmail: $creatorEmail
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_CATALOGUE_BACKUP = gql`
	query fetchCatalogueBackup($id: Int) {
		catalogBackup(id: $id) {
			id
			title
			description
			takenBy
			created
			creatorEmail
			updatedBy {
				id
				username
				fullName
				email
			}
			itemsCount
			optionGroupsCount
			optionsCount
			categoriesCount
			taxesCount
			chargesCount
			itemGroupsCount
			locationGroupsCount
			timingGroupsCount
		}
	}
`;

export const GET_CATEGORIES_LIST_FROM_BACKUP = gql`
	query getCategoriesListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			categories(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					name
					description
					subCategories {
						id
						name
						description
					}
				}
			}
		}
	}
`;

export const GET_ITEMS_LIST_FROM_BACKUP = gql`
	query getItemsListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			items(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					description
					foodType
					price
					category {
						id
						name
					}
				}
			}
		}
	}
`;

export const GET_MODIFIER_GROUPS_LIST_FROM_BACKUP = gql`
	query getModifierGroupsListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			optionGroups(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					isVariant
				}
			}
		}
	}
`;

export const GET_MODIFIERS_LIST_FROM_BACKUP = gql`
	query getModifiersListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			options(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					foodType
					price
				}
			}
		}
	}
`;

export const GET_TAXES_LIST_FROM_BACKUP = gql`
	query getTaxesListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			taxes(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					applicableOn
				}
			}
		}
	}
`;

export const GET_CHARGES_LIST_FROM_BACKUP = gql`
	query getChargesListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			charges(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					applicableOn
				}
			}
		}
	}
`;

export const GET_ITEM_GROUPS_LIST_FROM_BACKUP = gql`
	query getItemGroupsListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			itemGroups(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					description
				}
			}
		}
	}
`;

export const GET_LOCATION_GROUPS_LIST_FROM_BACKUP = gql`
	query getLocationGroupsListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			locationGroups(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					description
				}
			}
		}
	}
`;

export const GET_TIMING_GROUPS_LIST_FROM_BACKUP = gql`
	query getTimingGroupsListFromBackup($id: Int, $limit: Int, $offset: Int, $search: String) {
		catalogBackup(id: $id) {
			timingGroups(limit: $limit, offset: $offset, search: $search) {
				count
				json
				objects {
					id
					title
					description
				}
			}
		}
	}
`;

export const EDIT_CATALOGUE_BACKUP = gql`
	mutation editCatalogueBackup($id: Int, $title: String!, $description: String) {
		saveCatalogBackup(input: { id: $id, title: $title, description: $description }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const RESTORE_CATALOGUE_BACKUP = gql`
	mutation restoreCatalogueBackup($id: Int!) {
		restoreCatalogBackup(input: { id: $id }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DELETE_CATALOGUE_BACKUP = gql`
	mutation deleteCatalogueBackup($ids: [Int]!) {
		deleteCatalogBackups(input: { ids: $ids }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_CATALOGUE_BACKUP_CONFIG = gql`
	query getCatalogueBackupConfig($keys: [String]) {
		configurationValues(keys: $keys) {
			key
			value
		}
	}
`;

export const SAVE_CATALOGUE_BACKUP_CONFIG = gql`
	mutation saveCatalogueBackupConfig($configurations: [ConfigurationKeyInput]!) {
		saveConfigurationValues(input: { configurations: $configurations }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
