import React from "react";
import { formatDate } from "../../atlas-utils";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";

const TimelineTrail = ({
	hasNext,
	id,
	title,
	isFinal,
	status,
	parent,
	child,
	handleNestedEntity,
	clickable = false,
	openStatusUpdate,
	headNodeBackgroundColorLight,
	headNodeBackgroundColorDark,
	isLatest
}) => {
	let updatedBy = "";
	if (status.updatedBy) {
		updatedBy = status.updatedBy.firstName || status.updatedBy.username;
	}
	return (
		<div className="timeline-trail-container">
			<div className="trail-pointer-container">
				<div className={`head-pointer-container ${isLatest && "animated-circles"}`}>
					<div className="parent-circle" style={{ backgroundColor: headNodeBackgroundColorLight }}>
						<div className="child-circle" style={{ backgroundColor: headNodeBackgroundColorDark }}></div>
					</div>
				</div>
				{!isFinal && (
					<div className="trail-container">
						<div className="trail"></div>
					</div>
				)}
			</div>
			<div className="info-section">
				<div
					className="status-tag"
					style={{
						backgroundColor: headNodeBackgroundColorLight,
						color: headNodeBackgroundColorDark,
						cursor: clickable ? "pointer" : "default"
					}}
					onClick={() => clickable && openStatusUpdate(id)}
				>
					{title}
				</div>
				<div className="status-update-info">
					{updatedBy ? (
						<span>
							<span>Updated by </span>
							<span className="highlight">{updatedBy}</span>
							<span> at {formatDate(status.created, "DD MMM, YYYY - hh:mm:ss A")}</span>
						</span>
					) : (
						<span>At {formatDate(status.created, "DD MMM, YYYY - hh:mm:ss A")}</span>
					)}
				</div>
				{status.message && status?.message.trim() && (
					<div className="status-update-message-comments">Message: {status.message}</div>
				)}
				{child && isFinal && (
					<div className="modified-order">
						Modified Child Order:{" "}
						<a
							role="button"
							className="link-text"
							onClick={(e) => handleNestedEntity(e, NESTED_ENTITY_TYPES[11], child)}
						>
							{child}
						</a>
					</div>
				)}
				{parent && parent.id && title === "Placed" && (
					<div className="modified-order">
						Modified from Parent Order:{" "}
						<a
							role="button"
							className="link-text"
							onClick={(e) => handleNestedEntity(e, NESTED_ENTITY_TYPES[11], parent?.id)}
						>
							{parent?.id}
						</a>
					</div>
				)}
				{status.comments && status?.comments.trim() && (
					<div className="status-update-message-comments">Message: {status.comments}</div>
				)}
			</div>
		</div>
	);
};
export default TimelineTrail;
