import React from "react";
import { CONTENT_MODES, CUSTOM_PAGE_TYPES } from ".";
import GenericPageEditor from "../../components/MerakiWebEditor/GenericPageEditor";
import LandingPageSelector from "../../components/MerakiWebEditor/LandingPageSelector";
import RawHTMLEditor from "../../components/MerakiWebEditor/RawHTMLEditor";
import Preview from "./Preview";

const Content = ({
	contentMode,
	previewMode,
	tinymceRef,
	selectedPage,
	handleEditorChange,
	handlePreviewPageSelected,
	rawHTMLContent,
	previewUrl,
	config,
	workspace,
	handleLandingPageSelector
}) => {
	const isLandingPage = React.useMemo(
		() => selectedPage?._id === "landing_screen" && !!config,
		[config, selectedPage]
	);

	return (
		<div className="page-container">
			<React.Fragment>
				{isLandingPage && (
					<LandingPageSelector
						landingPage={selectedPage}
						workspace={workspace}
						handleLandingPageSelector={handleLandingPageSelector}
						previewUrl={previewUrl}
					/>
				)}

				<div className={"content-container " + `preview-mode-${previewMode}`}>
					{contentMode.value === CONTENT_MODES.preview.value && (
						<Preview
							key={previewMode}
							url={previewUrl}
							config={config}
							selectedPage={selectedPage}
							handlePageSelected={handlePreviewPageSelected}
						/>
					)}

					{contentMode.value === CONTENT_MODES.customPageEditor.value &&
						selectedPage.contentType === CUSTOM_PAGE_TYPES[0].value && (
							<GenericPageEditor
								tinymceRef={tinymceRef}
								value={selectedPage.content}
								onEditorChange={handleEditorChange}
							/>
						)}

					{contentMode.value === CONTENT_MODES.customPageEditor.value &&
						selectedPage.contentType === CUSTOM_PAGE_TYPES[1].value && (
							<RawHTMLEditor rawHTMLContent={rawHTMLContent} setRawHTMLContent={handleEditorChange} />
						)}
				</div>
			</React.Fragment>
		</div>
	);
};

export default React.memo(Content);
