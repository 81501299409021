import React from "react";

// component
import CreateIcon from "../_commons/CreateIcon";
import Placeholder from "../_commons/Placeholder";

// utils
import { commifyNumbers, printCurrency } from "../../atlas-utils";
import history from "../../history";

// third party
import moment from "moment";
import { useTrail, config, animated } from "react-spring";
import { Link } from "react-router-dom";

// constants
import {
	CUSTOMERS_LIST_HEADER_FIELDS,
	CUSTOMERS_LIST_HEADER_FIELDS_MOBILE,
	EXTERNAL_PLATFORMS_LOGO,
	ORDER_CHANNEL_MAP
} from "../../client-config";

export const Table = ({ currencySymbol, data, loading, sortList, sortedField, productTypeOptions, dimensions }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	const productTypeOptionsFlat = {};
	productTypeOptions.forEach((pt) => (productTypeOptionsFlat[pt.value] = pt));

	const placeholderContent = {
		placeholderText: "No customers yet!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-customers.svg",
		placeholderSubtext:
			"Manage list of all of your customers, their details and preferences helping you to stay on top of your customer service game",
		size: "medium",
		placeholderButtonContent: (
			<span>
				{" "}
				<CreateIcon /> Add a customer
			</span>
		),
		placeholderButtonClickAction: () => history.push("/customers/new")
	};

	if (data.length === 0 && !loading) {
		return (
			<div className="transactions-list-table">
				<Placeholder {...placeholderContent} />
			</div>
		);
	}
	return (
		<div className={(data.length > 0 && loading ? "disabled" : "") + " transaction-table-holder"}>
			<div className="transactions-list-table">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						productTypeOptionsFlat={productTypeOptionsFlat}
						headerFields={
							dimensions.width > 768 ? CUSTOMERS_LIST_HEADER_FIELDS : CUSTOMERS_LIST_HEADER_FIELDS_MOBILE
						}
					/>
					{trails.map(({ rotate }, i) => (
						<TableListResponsive
							key={data[i].id}
							dimensions={dimensions}
							currencySymbol={currencySymbol}
							productTypeOptionsFlat={productTypeOptionsFlat}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No customers history!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, sortedField, productTypeOptionsFlat, headerFields }) => (
	<div className={`at-table-row transaction-header-row customers`}>
		{headerFields.map((field, i) => {
			if (field.value === "points" && productTypeOptionsFlat["LOYALTY"].disabled) {
				return null;
			} else {
				return (
					<div
						key={i}
						className={
							`at-table-cell at-table-header at-header-text ${field.value}` +
							(field.sortKey ? " clickable" : "") +
							(sortedField === field.sortKey ? " active" : "")
						}
						onClick={field.sortKey && (() => sortList(field.sortKey))}
					>
						<span>{field.label}</span>
						{field.sortKey && (
							<span>
								&nbsp;&nbsp;
								<img src="/assets/icons/icon-sort.svg" alt="" />
							</span>
						)}
					</div>
				);
			}
		})}
	</div>
);

const TableListResponsive = ({ dimensions, ...rest }) => (
	<React.Fragment>{dimensions.width > 768 ? <TableList {...rest} /> : <TableListMobile {...rest} />}</React.Fragment>
);

const TableList = ({
	id,
	currentPrepaidBalance,
	currentPoints,
	dateJoined,
	fullName,
	phone,
	totalTransactions,
	totalSpent,
	email,
	signupChannel,
	signupStore,
	style,
	currencySymbol,
	productTypeOptionsFlat,
	last30DaysTransactionsCount
}) => {
	const extPlatLogo = EXTERNAL_PLATFORMS_LOGO[signupChannel];
	const signupChannelLabel = ORDER_CHANNEL_MAP[signupChannel];
	const storeTitle = signupStore && signupStore.title;
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows customers"
		>
			<div className="at-table-cell at-cell-text customers-details" title={fullName}>
				<Link className="upiper-id customer-id" to={`/customers/${id}`}>
					<span className="hyperlink hyperlink--black-color">{fullName ? fullName : id}</span>
				</Link>
				{extPlatLogo && <img src={extPlatLogo} className="ext-plt-icon" />}
				{phone && (
					<div className="external-id" title={phone}>
						{phone}
					</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text email">{email || "--"}</div>
			<div className="at-table-cell at-cell-text signup-date">
				<div className="date-date">{moment(dateJoined).format("DD MMM, YYYY")}</div>
				<div className="date-time">{moment(dateJoined).format("hh:mm A")}</div>
			</div>
			<div className="at-table-cell at-cell-text outlet" title={storeTitle}>
				{productTypeOptionsFlat["LOYALTY"].disabled
					? signupChannelLabel || storeTitle
					: storeTitle || signupChannelLabel}
			</div>
			<div className="at-table-cell at-cell-text num-orders">
				{totalTransactions !== null ? totalTransactions : "N/A"}
				<span className="recent-orders">
					Past 30 D: {last30DaysTransactionsCount !== null ? last30DaysTransactionsCount : "--"}
				</span>
			</div>
			<div className="at-table-cell at-cell-text orders-value">
				{totalSpent !== null ? (
					<div>
						{printCurrency(currencySymbol)}
						{commifyNumbers(totalSpent)}
					</div>
				) : (
					"N/A"
				)}
			</div>
		</animated.div>
	);
};

const TableListMobile = ({
	id,
	currentPrepaidBalance,
	currentPoints,
	dateJoined,
	fullName,
	phone,
	totalTransactions,
	totalSpent,
	email,
	style,
	currencySymbol,
	productTypeOptionsFlat
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows customers"
		>
			<div className="at-table-cell at-cell-text customers-details" title={fullName}>
				<Link className="upiper-id customer-id" to={`/customers/${id}`}>
					{fullName ? fullName : id}
				</Link>
				{phone && (
					<div className="external-id" title={phone}>
						{phone}
					</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text email">{email || "--"}</div>
			<div className="at-table-cell at-cell-text signup-date">
				<div className="date-date">{moment(dateJoined).format("DD MMM, YYYY")}</div>
				<div className="date-time">{moment(dateJoined).format("hh:mm A")}</div>
			</div>
			<div className="at-table-cell at-cell-text num-orders">
				{totalTransactions !== null ? totalTransactions : "N/A"}
			</div>
			<div className="at-table-cell at-cell-text orders-value">
				{totalSpent !== null ? (
					<div>
						{printCurrency(currencySymbol)}
						{commifyNumbers(totalSpent)}
					</div>
				) : (
					"N/A"
				)}
			</div>
		</animated.div>
	);
};
