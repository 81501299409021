import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import moment from "moment";
import PubSub from "pubsub-js";

// actions
import { saveCampaign } from "../../actions/campaign";
import { ActionTypes } from "../../actions/_types";

// client
import { store } from "../../store/configureStore";
import { client } from "../../client";

// components
import { GlobalConfirmModal } from "../../components/SiteComp";

// graphql
import { RUN_CAMPAIGN } from "../../graphql/campaigns";

// utils
import { msaagesArrayToHtml, formatDate, commifyNumbers, getReadableCron } from "../../atlas-utils";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";
import { CONVERSION_TRACKING_TYPE, MEDIUM_OPTIONS } from "../../client-config";
const FILTERS_PREVIEW = [
	{
		groupTitle: "User",
		groupName: "user",
		filters: [
			{ fieldTitle: "Signup date", fieldName: "signup_date" },
			{ fieldTitle: "Signup channel", fieldName: "signup_channel" },
			{ fieldTitle: "Gender", fieldName: "gender" },
			{ fieldTitle: "Birthday", fieldName: "birthday" },
			{ fieldTitle: "Anniversary", fieldName: "anniversary" },
			{ fieldTitle: "Wallet balance", fieldName: "wallet_balance" },
			{ fieldTitle: "Loyalty points", fieldName: "loyalty_points" }
		]
	},
	{
		groupTitle: "Order",
		groupName: "order",
		filters: [
			{ fieldTitle: "Date", fieldName: "placed_on" },
			{ fieldTitle: "Inactive", fieldName: "inactive_on" },
			{ fieldTitle: "Time", fieldName: "time_of_day" },
			{ fieldTitle: "Channel", fieldName: "channel" },
			{ fieldTitle: "Fulfillment mode", fieldName: "fulfillment_mode" },
			{ fieldTitle: "Order value", fieldName: "order_value" },
			{ fieldTitle: "Number of orders", fieldName: "number_of_orders" },
			{ fieldTitle: "Payment mode", fieldName: "payment_mode" },
			{ fieldTitle: "Coupons", fieldName: "coupons", objNestedField: "value", secondaryNestedField: "title" },
			{ fieldTitle: "Items", fieldName: "items", objNestedField: "title", secondaryNestedField: "value" },
			{ fieldTitle: "Stores", fieldName: "stores", objNestedField: "title", secondaryNestedField: "value" }
		]
	},
	{
		groupTitle: "In store",
		groupName: "in_store_purchase",
		filters: [
			{ fieldTitle: "Purchase date", fieldName: "purchased_on" },
			{ fieldTitle: "Inactive", fieldName: "inactive_on" },
			{ fieldTitle: "Time", fieldName: "time_of_day" },
			{ fieldTitle: "Amount", fieldName: "purchase_amount" },
			{ fieldTitle: "Number of purchases", fieldName: "number_of_purchases" },
			{ fieldTitle: "Stores", fieldName: "stores", objNestedField: "title", secondaryNestedField: "title" }
		]
	},
	{
		groupTitle: "Feedback",
		groupName: "feedback",
		filters: [
			{ fieldTitle: "Given on", fieldName: "given_on" },
			{ fieldTitle: "Rating", fieldName: "rating" },
			{ fieldTitle: "Type", fieldName: "choice_text" }
		]
	}
];

// create flatmap for conversion tracking value and labels
let conversionTypeMap = {};
CONVERSION_TRACKING_TYPE.forEach((conv) => (conversionTypeMap[conv.value] = conv.title));

@connect((store) => ({
	createCampaign: store.createCampaign,
	createSegment: store.createSegment
}))
export default class CreateCampaignPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignTitle: this.props.createCampaign.name,
			modalBusy: false
		};
	}

	changeCampaignTitle(e) {
		this.setState({
			campaignTitle: e.target.value
		});
	}

	saveCampaignTitleAndProceed = async () => {
		this.setState({
			modalBusy: true
		});

		// NOTE: noSave:true flag is used in middleware to determine whether to save or not
		// storing campaign title to redux store
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				name: this.state.campaignTitle,
				noSave: true
			}
		});

		// saving all the data once again to server in case something was missed between due to debounce
		const variables = store.getState().createCampaign;
		try {
			await saveCampaign(variables);
			let resp = await client.mutate({
				mutation: RUN_CAMPAIGN,
				variables: {
					id: variables.id
				}
			});
			this.setState({
				modalBusy: false
			});
			if (resp.data.runCampaign.status.success) {
				// track this event
				// PubSub.publish(TRACK_EVENT, {
				// 	tracker: 'mixpanel',
				// 	eventName: 'campaign_save',
				// 	eventMeta: {
				// 		medium: variables.medium,
				// 		type: variables.messageType,
				// 		segment: this.props.createSegment.segmentType,
				// 		schedule: (variables.campaignExecutionType === 'RECURRING') ? 'RECURRING' : (variables.scheduledTime ? 'LATER' : 'NOW'),
				// 		tracking_time: variables.conversionConfig.days,
				// 		tracking_event: variables.conversionConfig.event,
				// 	}
				// });

				// disable confirm prompt and navigate to details page.
				window.lS.remove("savedCampaign");
				this.props.disableNavigationBlock(variables.id);
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.runCampaign.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			this.setState({
				modalBusy: false
			});
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	render() {
		let { isFetchingDetails, createCampaign, switchTab, createSegment } = this.props;
		let readableCron = "";
		if (createCampaign.cronExpression) {
			readableCron = getReadableCron(createCampaign.cronExpression);
		}
		const filters = JSON.parse(createSegment.filters);
		let { medium, scheduledTime, startAt, endAt } = createCampaign;
		const mediumObj = MEDIUM_OPTIONS.find((m) => m.value === medium);
		const mediumStr = (mediumObj && mediumObj.value) || medium;

		return (
			<div className={"create-campaign-section " + (isFetchingDetails ? "disabled" : "")}>
				<GlobalConfirmModal
					backdropHandler={() => {
						return;
					}}
					show={this.props.showSaveCampaignModal}
					confirmBtnEnabled={this.state.campaignTitle.trim()}
					confirmHandler={this.saveCampaignTitleAndProceed}
					cancelHandler={this.props.handleSaveCampaignModal}
					modalBusy={this.state.modalBusy}
				>
					<div className="discard-confirm-modal">
						<div className="title">Save campaign</div>
						<div className="modal-text">Want to give your campaign a nice title ?</div>
						<div className="save-campaign-title">
							<input
								onChange={(e) => {
									this.changeCampaignTitle(e);
								}}
								value={this.state.campaignTitle}
								type="text"
								placeholder="Enter a campaign title"
							/>
						</div>
					</div>
				</GlobalConfirmModal>
				<div className="create-campaign-forms-container create-campaign-forms-container--preview">
					<PreviewBlock
						counter={1}
						title={"Who are you sending this campaign to?"}
						switchTab={switchTab}
						section="Who"
					>
						<div className="preview-content-row">
							<span className="preview-content-label W(240px) D(ib)">Number of target customers:</span>
							&nbsp; {commifyNumbers(this.props.createSegment.usersPreview.targeted)}
						</div>
						<div className="preview-content-row">
							<span className="preview-content-label W(240px) D(ib)">Percentage of total customers:</span>
							&nbsp; {commifyNumbers(this.props.createSegment.usersPreview.percentageTargeted)}%
						</div>
						<FiltersPreview filters={filters} />
					</PreviewBlock>
					<PreviewBlock counter={2} title={"What are you sending?"} switchTab={switchTab} section="What">
						<div className="preview-content-row">
							<span className="preview-content-label">Channel:</span> {mediumStr}
						</div>
						<Subpreview title={"Content of your message"}>
							{medium == "EMAIL" ? (
								<div>Email (Preview not available)</div>
							) : (
								<div className="preview-message-wrap">{this.props.createCampaign.message || "--"}</div>
							)}
						</Subpreview>
					</PreviewBlock>
					<PreviewBlock counter={3} title={"When are you sending?"} switchTab={switchTab} section="When">
						{startAt && endAt ? (
							<div className="preview-content-row">
								<div className="preview-content-row">
									<span className="preview-content-label D(ib) W(60px)">Starts at :</span>{" "}
									{formatDate(startAt)}
								</div>
								<div className="preview-content-row">
									<span className="preview-content-label D(ib) W(60px)">Ends at :</span>{" "}
									{formatDate(endAt)}
								</div>
								<div>
									<span className="preview-content-label D(ib) W(60px)">Runs at :</span>{" "}
									{readableCron}
								</div>
							</div>
						) : (
							<div className="preview-content-row">
								<span className="preview-content-label">Scheduled Time:&nbsp;</span>
								{scheduledTime
									? scheduledTime < new Date().getTime()
										? "Now"
										: formatDate(scheduledTime)
									: "Now"}
							</div>
						)}
					</PreviewBlock>
					<PreviewBlock counter={4} title={"How are you tracking?"} switchTab={switchTab} section="Track">
						<div className="preview-content-row">
							<div className="preview-content-row">
								<span className="preview-content-label D(ib) W(150px)">Event:</span>{" "}
								{conversionTypeMap[createCampaign.conversionConfig.event]}
							</div>
							<div className="preview-content-row">
								<span className="preview-content-label D(ib) W(150px)">For how many days:</span>{" "}
								{createCampaign.conversionConfig.days}
							</div>
							{createCampaign.conversionConfig.event === "COUPON_USED" && (
								<div className="preview-content-row">
									<span className="preview-content-label D(ib) W(150px)">Coupon code:</span>{" "}
									{createCampaign.conversionConfig.value}
								</div>
							)}
						</div>
					</PreviewBlock>
				</div>
			</div>
		);
	}
}

const PreviewBlock = (props) => {
	return (
		<div className="campaign-preview-block">
			<div className="campaign-preview-block-container">
				<div className="counter">{props.counter}</div>
				<div
					onClick={(e) => {
						props.switchTab(props.section);
					}}
					className="campaign-edit-section campaign-edit"
				>
					<span>
						<img src="/assets/icons/edit.svg" />
					</span>
					<div className="Mt(5px)">Edit</div>
				</div>
				<div className="campaign-preview-block-content">
					<div className="campaign-preview-block-content-title">{props.title}</div>
					{props.children}
				</div>
			</div>
		</div>
	);
};

const Subpreview = (props) => {
	return (
		<div className="sub-content-preview">
			<div className="sub-content-preview--title">{props.title}</div>
			<div className="sub-content-preview--content">{props.children}</div>
		</div>
	);
};

function FiltersParser(filters) {
	this.filters = filters;
	this.getFilterValue = (root, field) => {
		if (
			this.filters.groups &&
			this.filters.groups[root] &&
			this.filters.groups[root][field] &&
			this.filters.groups[root][field].values &&
			this.filters.groups[root][field].values.length
		) {
			return this.filters.groups[root][field];
		}
		return false;
	};
}

export class FiltersPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAll: false
		};
	}

	flipShowAll = () => {
		this.setState({
			showAll: !this.state.showAll
		});
	};

	render() {
		const { filters, initialFiltersCount } = this.props;
		const filtersCount = initialFiltersCount ? initialFiltersCount : 2;
		const { showAll } = this.state;
		const fp = new FiltersParser(filters);
		let filtersPreview = [];
		FILTERS_PREVIEW.forEach((fGroup, i) => {
			fGroup.filters.forEach((fItem, j) => {
				let fGroupItem = (
					<FiltersPreviewItem
						key={fItem.fieldName + j + i}
						groupTitle={fGroup.groupTitle}
						fieldTitle={fItem.fieldTitle}
						fieldValue={fp.getFilterValue(fGroup.groupName, fItem.fieldName)}
						fieldName={fItem.fieldName}
						objNestedField={fItem.objNestedField}
						secondaryNestedField={fItem.secondaryNestedField}
					/>
				);
				if (fGroupItem.props.fieldValue) {
					filtersPreview.push(fGroupItem);
				}
			});
		});
		let filtersPreviewTemp = filtersPreview.slice();
		if (!showAll) {
			filtersPreviewTemp = filtersPreview.slice(0, filtersCount);
		}
		if (filtersPreview.length < 1) {
			return null;
		}

		return (
			<Subpreview title={"Filters applied to segment customers"}>
				{filtersPreviewTemp}
				{!showAll && filtersPreview.length > filtersCount && (
					<div onClick={this.flipShowAll} className="show-more-filters-preview">
						{`+${filtersPreview.length - filtersCount} More`}
					</div>
				)}
				{showAll && filtersPreview.length > filtersCount && (
					<div onClick={this.flipShowAll} className="show-more-filters-preview">
						Collapse
					</div>
				)}
			</Subpreview>
		);
	}
}

const FiltersPreviewItem = ({
	groupTitle,
	fieldTitle,
	fieldValue,
	objNestedField,
	fieldName,
	secondaryNestedField
}) => {
	if (fieldValue && fieldValue.values.length > 0) {
		let operatorString = "";
		let operatorEndString = "";
		switch (fieldValue.operator) {
			case "in":
				break;
			case "not in":
				operatorString = "not in";
				break;
			case "between":
				operatorString = "is between";
				break;
			case "equal":
				if (fieldValue.type === "last_n_days") {
					operatorString = "in last";
					operatorEndString = "days";
				} else if (fieldValue.type === "absolute") {
					operatorString = "is equal to";
				}
				break;
			case "greater_than":
				operatorString = "is greater than";
				break;
			case "less_than":
				operatorString = "is less than";
				break;
			default:
				break;
		}
		return (
			<div className="segment-filters-preview-item">
				<div className="preview-item-title">{`${groupTitle}:`}</div>
				<div className="preview-item-value">
					{`${fieldTitle} ${operatorString} `}
					<span className="filters-preview-highlight">
						(
						{fieldValue.values.map((val, i) => {
							let valAux;
							if (objNestedField) {
								valAux = val[objNestedField] ? val[objNestedField] : val[secondaryNestedField];
							} else {
								valAux = val;
							}
							// checking if the filter value is of date type
							// if yes then format it
							if (
								dateTypeFieldNames.indexOf(fieldName) !== -1 &&
								dateFilterType.indexOf(fieldValue.type) !== -1
							) {
								// the below code is for values of date type
								// so we seperate them by to
								valAux = moment(valAux, "YYYY-MM-DD").format("DD MMM, YYYY");
								if (i !== fieldValue.values.length - 1) {
									return <span key={i}>{`${valAux} TO `}</span>;
								} else {
									return <span key={i}>{valAux}</span>;
								}
							} else {
								// the below code if for values  which are
								// not of date type so we seperate them by comma
								if (i !== fieldValue.values.length - 1) {
									return <span key={i}>{`${valAux}, `}</span>;
								} else {
									return <span key={i}>{valAux}</span>;
								}
							}
						})}
						)
					</span>
					{` ${operatorEndString}`}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const dateTypeFieldNames = ["signup_date", "birthday", "anniversary", "placed_on", "purchased_on", "given_on"];

const dateFilterType = ["absolute", "range"];
