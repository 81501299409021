import React, { useState } from "react";

// components
import { InputWithLabel } from "./InputWithLabel";
import { SelectFilter } from "./SelectFilter";
import { Button } from "./Button";
import { Uploader } from "./Uploader";
import { Switch } from "./Switch";

// utils
import { getRandomChars } from "../../atlas-utils";

const MODES = {
	create: "create",
	edit: "edit"
};

export const ArrayOfObject = ({ classes = "", onChange, children, objects = [], objectStructure }) => {
	const [selectedObject, setSelectedObject] = useState(objects.length ? objects[0] : undefined);
	const [newObject, setNewObject] = useState({});
	const [formTouched, setFormTouched] = useState(false);
	const [mode, setMode] = useState(MODES.edit);

	const handleObjectEdit = (field, value) => {
		setSelectedObject({
			...selectedObject,
			[field]: value
		});
		setFormTouched(true);
	};

	const handleObjectCreateField = (field, value) => {
		setNewObject({
			...newObject,
			[field]: value
		});
		setFormTouched(true);
	};

	const handleSelectObject = (object) => {
		setSelectedObject(object);
		setFormTouched(false);
		setMode(MODES.edit);
	};

	const setModeCreate = () => {
		const baseObject = {
			id: getRandomChars(4)
		};
		objectStructure.schema.forEach((field) => {
			switch (field.type) {
				case "text":
					baseObject[field.key] = "";
				case "image":
					baseObject[field.key] = "";
				default:
					break;
			}
		});
		setNewObject(baseObject);
		setFormTouched(false);
		setMode(MODES.create);
	};

	const setModeEdit = () => {
		setMode(MODES.edit);
	};

	const handleCreate = () => {
		let updatedObjects = objects.slice();
		updatedObjects.push(newObject);
		onChange(updatedObjects);
		handleSelectObject(newObject);
	};

	const handleSave = () => {
		const updatedObjects = objects.map((obj) => {
			if (obj.id === selectedObject.id) {
				return selectedObject;
			}
			return obj;
		});
		onChange(updatedObjects);
	};

	const handleDelete = () => {
		const updatedObjects = objects.filter((obj) => {
			if (obj.id === selectedObject.id) {
				return false;
			}
			return true;
		});
		onChange(updatedObjects);
		if (updatedObjects.length > 0) {
			handleSelectObject(updatedObjects[0]);
		} else {
			setSelectedObject(undefined);
		}
	};

	return (
		<div className="array-of-object">
			{children && <div className="field-label">{children}</div>}
			<div className={"array-of-object-area" + (classes || "")}>
				{objects.length > 0 && (
					<SelectFilter
						options={objects}
						field="id"
						currValue={selectedObject}
						setFilter={(field, value) => handleSelectObject(value)}
						isSearchable={false}
						isClearable={false}
						labelKey={objectStructure.labelKey}
						valueKey={objectStructure.valueKey}
					/>
				)}
				{mode === MODES.edit && selectedObject && (
					<RenderObject
						mode={mode}
						selectedObject={selectedObject}
						objectStructure={objectStructure}
						formTouched={formTouched}
						handleObjectEdit={handleObjectEdit}
						handleSave={handleSave}
						handleDelete={handleDelete}
					/>
				)}
				{mode === MODES.create && newObject && (
					<RenderObject
						mode={mode}
						selectedObject={newObject}
						objectStructure={objectStructure}
						formTouched={formTouched}
						handleObjectEdit={handleObjectCreateField}
						handleCreate={handleCreate}
						setModeEdit={setModeEdit}
					/>
				)}
				{objectStructure.allowCreate && mode !== MODES.create && (
					<div className="link-text" onClick={setModeCreate}>
						+ Create
					</div>
				)}
			</div>
		</div>
	);
};

const RenderObject = ({
	selectedObject,
	mode,
	objectStructure,
	handleObjectEdit,
	formTouched,
	handleSave,
	handleDelete,
	handleCreate,
	setModeEdit
}) => {
	return (
		<div className="object-fields-container">
			<div>
				{objectStructure.schema.map((field, i) => (
					<div className="object-field-item">
						{field.type === "text" && (
							<InputWithLabel
								value={selectedObject[field.key]}
								onChange={(e) => handleObjectEdit(field.key, e.target.value)}
							>
								{field.label}
							</InputWithLabel>
						)}
						{field.type === "dropdown" && (
							<SelectFilter
								options={field.options}
								currValue={selectedObject[field.key]}
								setFilter={(_, value) => handleObjectEdit(field.key, value.value)}
								isSearchable={false}
								isClearable={false}
								title={field.label}
							/>
						)}
						{field.type === "boolean" && (
							<Switch
								title={field.label}
								checked={selectedObject[field.key] || false}
								clickHandler={() => handleObjectEdit(field.key, !selectedObject[field.key])}
							/>
						)}
						{field.type === "image" && (
							<Uploader
								useCompressor={true}
								uploadToGallery={true}
								onChange={(file) => handleObjectEdit(field.key, file)}
								file={
									typeof selectedObject[field.key] !== "string"
										? selectedObject[field.key]
										: undefined
								}
								url={typeof selectedObject[field.key] === "string" ? selectedObject[field.key] : ""}
								showDelete={true}
								handleDelete={(e) => {
									e.stopPropagation();
									handleObjectEdit(field.key, "");
								}}
								aspectRatio={field.aspectRatio}
								description={field.description}
							>
								{field.label}
							</Uploader>
						)}
					</div>
				))}
			</div>
			<div className="object-action-buttons">
				{mode === MODES.edit && (
					<React.Fragment>
						<Button clickHandler={handleSave} classes={!formTouched ? "disabled" : ""}>
							Update
						</Button>
						{objectStructure.allowDelete && (
							<Button clickHandler={handleDelete} classes="at-btn--danger">
								Delete
							</Button>
						)}
					</React.Fragment>
				)}
				{mode === MODES.create && (
					<React.Fragment>
						<Button clickHandler={handleCreate} classes={!formTouched ? "disabled" : ""}>
							Create
						</Button>
						<Button clickHandler={setModeEdit} type="secondary">
							Cancel
						</Button>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};
