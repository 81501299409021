import { store } from "../store/configureStore";

export const initUtility = () => {
	// set isMobile
	window.isMobile = window.innerWidth < 768;

	// check app version
	const oldVersion = localStorage.getItem("appVersion");
	const newVersion = window.appVersion;
	const authData = localStorage.getItem("AT");
	if (!oldVersion) {
		localStorage.setItem("appVersion", newVersion);
		if (authData) {
			localStorage.removeItem("AT");
			window.location.reload(true);
		}
	}
	if (cmpVersions(newVersion, oldVersion) > 0) {
		localStorage.setItem("appVersion", newVersion);
		if (authData) {
			localStorage.removeItem("AT");
			window.location.reload(true);
		}
	}

	// add toFixed method on String prototype
	String.prototype.toFixed = function (precision) {
		return Number(this).toFixed(precision);
	};

	// load smoothScrollPollyfill
	// http://iamdustan.com/smoothscroll/
	loadSmoothscrollPolyfill();

	// attach loadFreshchat function in window, it will be called by
	// freshchat after onload event
	// window.loadFreshchat = loadFreshchat;
};

const loadSmoothscrollPolyfill = () => {
	if (!document.documentElement.scrollIntoView || !("scrollBehavior" in document.documentElement.style)) {
		const script = document.createElement("script");
		script.src = "https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js";
		document.body.appendChild(script);
	}
};

// export const loadFreshchat = async () => {
// 	if(window.fcWidget) {
// 		window.fcWidget.init({
// 			token: "9b144c05-fe93-4f71-8228-d6219450d03c",
// 			host: "https://wchat.freshchat.com",
// 		});
// 		if(isLoggedin()) {
// 			const { loginDetail, loggedInbizDetail } = store.getState().login;
// 			const { name, email } = loginDetail;
// 			const { name: bizName, id: bizId } = loggedInbizDetail;
// 			window.fcWidget.setExternalId(email);
// 			window.fcWidget.user.setFirstName(name);
// 			window.fcWidget.user.setEmail(email);
// 			window.fcWidget.user.setProperties({
// 				bizId,
// 				bizName,
// 			});
// 		}
// 	}
// }

const cmpVersions = (a, b) => {
	var i,
		cmp,
		len,
		re = /(\.0)+[^\.]*$/;
	a = (a + "").replace(re, "").split(".");
	b = (b + "").replace(re, "").split(".");
	len = Math.min(a.length, b.length);
	for (i = 0; i < len; i++) {
		cmp = parseInt(a[i], 10) - parseInt(b[i], 10);
		if (cmp !== 0) {
			return cmp;
		}
	}
	return a.length - b.length;
};

function setRestoreIdByExternalId(externalId, restoreId) {
	// externalId(user ULE id) and restoreID(sent by freshChat) are saved in an individual cookie so that any ".urbanpiper.com" domain can access this info.
	const expirationDate = new Date();
	expirationDate.setDate(expirationDate.getDate() + 7);
	const expirationDateString = expirationDate.toUTCString();
	const cookieString = `fc_${externalId}=${restoreId}; expires=${expirationDateString}; path=/; domain=.urbanpiper.com`;
	document.cookie = cookieString;
}

function getRestoreIdByExternalId(externalId) {
	try {
		const cookies = document.cookie.split(";");

		for (const cookie of cookies) {
			const [cookieName, value] = cookie.trim().split("=");
			if (cookieName.trim() === `fc_${externalId}`) {
				return value || null;
			}
		}
	} catch {
		return null;
	}

	return null;
}

export function initFreshChat({ bizName, bizId, firstName, email, phone, mode, companyCountry, externalId }) {
	setTimeout(() => {
		const mainAppComponent = document.getElementsByClassName("root");
		const restoreId = externalId ? getRestoreIdByExternalId(externalId) : null;

		const closeFreshChatWidget = () => {
			if (window.fcWidget) {
				window.fcWidget.close();
			}
		};

		const handleKeyDown = (e) => {
			if (e.keyCode === 27 && window.fcWidget) {
				window.fcWidget.close();
			}
		};

		if (window.fcWidget) {
			window.fcWidget.init({
				token: process.env.REACT_APP_FRESHCHAT_TOKEN,
				host: "https://wchat.in.freshchat.com",
				siteId: bizId,
				externalId: externalId,
				restoreId: restoreId,
				config: {
					cssNames: {
						widget: "custom_fc_frame"
					},
					headerProperty: {
						direction: "ltr",
						hideChatButton: true
					}
				}
			});

			window.fcWidget.user.setProperties({
				firstName: firstName,
				email: email,
				phone: phone,
				bizName: bizName,
				bizId: bizId,
				mode: mode,
				companyCountry: companyCountry
			});

			window.fcWidget.on("user:created", (resp) => {
				if (resp && resp?.status === 200 && resp?.data?.restoreId) {
					setRestoreIdByExternalId(externalId, resp.data.restoreId);
				}
			});

			window.fcWidget.on("widget:loaded", function (resp) {
				store.dispatch({
					type: "FRESH_WIDGET_INIT"
				});
			});

			window.fcWidget.on("widget:closed", function (resp) {
				mainAppComponent && document.removeEventListener("click", closeFreshChatWidget);
				mainAppComponent && document.removeEventListener("keydown", handleKeyDown);
				store.dispatch({
					type: "TOGGLE_FRESHCHAT_STATE",
					payload: false
				});
			});

			window.fcWidget.on("widget:opened", function (resp) {
				setTimeout(() => {
					mainAppComponent && document.addEventListener("click", closeFreshChatWidget);
					mainAppComponent && document.addEventListener("keydown", handleKeyDown);
				}, 300);
				store.dispatch({
					type: "TOGGLE_FRESHCHAT_STATE",
					payload: true
				});
			});
		}
	}, 3000);
}
