import React, { useCallback, useEffect, useState } from "react";

// components
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Loading } from "../_commons/Loading";

// clients
import { store } from "../../store/configureStore";
import { client } from "../../client";

// third party
import moment from "moment";
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../../actions/_types";

// utils
import { commifyNumbers, lengthAdjust, lS, printCurrency, trackEvent } from "../../atlas-utils";

// graphql
import { GET_LOCATION_NAME } from "../../graphql/reconciliation";

const Upload = ({
	setSubmitTitle,
	uploading,
	setUploading,
	files,
	setFiles,
	inputRef,
	successData,
	setSuccessData,
	locationName,
	setLocationName,
	error,
	setError,
	selectedPlatform,
	platforms,
	biz,
	userInfo
}) => {
	const auth = lS.get("auth");
	const handleClick = () => {
		inputRef.current.click();
	};

	const [multiFileUploadStatus, setMultiFileUploadStatus] = useState([]);
	const [processingIndex, setProcessingIndex] = useState(0);

	const handleUpdatePlatform = (field, value) => {
		store.dispatch({
			type: ActionTypes.SET_SELECTED_PLATFORM,
			payload: value
		});
	};

	const getLocationName = useCallback(
		async (locationId, isCalledDirectly = false) => {
			let id = locationId || "";
			if (id !== "") {
				try {
					const variables = {
						id: parseInt(id)
					};
					const resp = await client.query({
						query: GET_LOCATION_NAME,
						variables,
						fetchPolicy: "no-cache"
					});
					if (resp.data.store.name) {
						if (isCalledDirectly) {
							return resp.data.store.name;
						} else {
							setLocationName(resp.data.store.name);
						}
					} else {
						return null;
					}
				} catch (error) {
					console.log(error);
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Failed to fetch location",
							timeout: 2000,
							error: true,
							errObject: error
						}
					});
					return null;
				}
			}
		},
		[successData]
	);

	const uploadFile = async (index) => {
		const userName = userInfo.username;
		const email = userInfo.email;
		let formData = new FormData();
		formData.append("sheet", files[index]);
		formData.append("platform", selectedPlatform?.value.toLowerCase());
		formData.append("user_email", userName.startsWith("tmp_") ? email : userName);
		try {
			const res = await fetch(process.env.REACT_APP_API_URL_PAYOUT_SHEET, {
				method: "POST",
				body: formData,
				headers: { Authorization: auth && `Bearer ${auth.token}` }
			});
			const status = res?.status || "";
			if (status === 200) {
				let data = await res.json();
				if (files.length === 1) {
					setSuccessData(data);
					setSubmitTitle("Upload more payouts");
					const eventMeta = {
						numberOfFilesUploadedSuccessfully: 1,
						numberOfFilesUploaded: 1
					};
					trackEvent("payout_sheets_upload_process", eventMeta);
				} else {
					const targetLocationName = await getLocationName(data?.data?.location_id, true);
					setMultiFileUploadStatus((current) => [
						...current.map((status, i) =>
							i === index
								? {
										...status,
										completed: true,
										success: true,
										processing: false,
										resultData: { ...data, locationName: targetLocationName }
								  }
								: status
						)
					]);
				}
			} else {
				let data = await res.json();
				if (data.errors) {
					if (files.length === 1) {
						setError(data.errors);
						setSubmitTitle("Try again");
						const eventMeta = {
							numberOfFilesUploadedSuccessfully: 0,
							numberOfFilesUploaded: 1
						};
						trackEvent("payout_sheets_upload_process", eventMeta);
					} else {
						setMultiFileUploadStatus((current) => [
							...current.map((status, i) =>
								i === index
									? {
											...status,
											completed: true,
											success: false,
											processing: false,
											resultData: { ...data.errors }
									  }
									: status
							)
						]);
					}
				} else if (data.message) {
					if (files.length === 1) {
						setError({
							message: data.message
						});
					} else {
						setMultiFileUploadStatus((current) => [
							...current.map((status, i) =>
								i === index
									? {
											...status,
											completed: true,
											success: false,
											processing: false,
											resultData: {
												message: data.message
											}
									  }
									: status
							)
						]);
					}
				} else {
					if (files.length === 1) {
						setError({
							couldntCatch: "Something is wrong with the file."
						});
					} else {
						setMultiFileUploadStatus((current) => [
							...current.map((status, i) =>
								i === index
									? {
											...status,
											completed: true,
											success: false,
											processing: false,
											resultData: {
												couldntCatch: "Something is wrong with the file."
											}
									  }
									: status
							)
						]);
					}
				}
			}
			if (index !== files.length - 1) {
				uploadFile(index + 1);
				setProcessingIndex(index + 1);
			} else {
				setSubmitTitle("Upload more payouts");
				setProcessingIndex(index + 1);
			}
		} catch (err) {
			if (files.length === 1) {
				setError({
					couldntCatch: "Something is wrong with the file or internet connectivity."
				});
			} else {
				setMultiFileUploadStatus((current) => [
					...current.map((status, i) =>
						i === index
							? {
									...status,
									completed: true,
									success: false,
									resultData: {
										couldntCatch: "Something is wrong with the file."
									}
							  }
							: status
					)
				]);
			}

			if (index !== files.length - 1) {
				uploadFile(index + 1);
				setProcessingIndex(index + 1);
			} else {
				setProcessingIndex(index + 1);
				setSubmitTitle("Upload more payouts");
			}
		}
	};

	const handleFilesUpload = () => {
		setUploading(true);
		setMultiFileUploadStatus([
			...new Array(files.length).fill({
				completed: false
			})
		]);
		uploadFile(0);
	};

	const handleOnDrop = (e) => {
		try {
			e.stopPropagation();
			e.preventDefault();
			const files = e.dataTransfer.files;
			const filteredFiles = [...files].filter(
				(file) => file.name.includes(".xlsx") || file.name.includes(".csv")
			);
			setFiles(filteredFiles.length === 0 ? undefined : filteredFiles);
			trackEvent("recon_upload_files_drag_n_drop", {
				numberOfFiles: filteredFiles?.length || 0
			});
		} catch (e) {
			console.log(e);
		}
	};

	const handleFilesChange = (e) => {
		setFiles(e.target.files);
		trackEvent("recon_upload_files_selector_click", {
			numberOfFiles: e.target?.files?.length || 0
		});
	};

	useEffect(() => {
		getLocationName(successData?.data?.location_id);
	}, [successData]);

	useEffect(() => {
		if (!(multiFileUploadStatus?.length <= 1) && processingIndex === multiFileUploadStatus?.length) {
			let numberOfFilesUploaded = multiFileUploadStatus.length;
			let numberOfFilesUploadedSuccessfully = 0;
			multiFileUploadStatus.forEach((fileStatus) => {
				if (fileStatus.success) {
					numberOfFilesUploadedSuccessfully++;
				}
			});
			const eventMeta = {
				numberOfFilesUploadedSuccessfully,
				numberOfFilesUploaded
			};
			trackEvent("payout_sheets_upload_process", eventMeta);
		}
	}, [processingIndex]);

	return error ? (
		<div className="form-row uploader-section">
			<div className={"error-container"}>
				<div className={"error-message"}>
					<strong>Oops! Error occurred while reading the file.</strong>
					<br />
					<br />
					{error[Object.keys(error)[0]]}
				</div>
			</div>
		</div>
	) : successData ? (
		<React.Fragment>
			<div className="form-row uploader-section">
				<div className={"success-container"}>
					<div className={"success-message"}>
						Following are the basic <strong>details</strong> that we were able to extract from the sheet.{" "}
						<br />
						It may take anywhere from few minutes to couple of hours, to complete reconciliation.
					</div>
				</div>
			</div>
			<div className="form-row row-half">
				<InputWithLabel value={locationName} readOnly={true} loading={true}>
					Location Name
				</InputWithLabel>
				<InputWithLabel value={successData?.data?.platform || "---"} readOnly={true} loading={true}>
					Platform Name
				</InputWithLabel>
			</div>
			<div className="form-row row-half recon-upload-success-info">
				<InputWithLabel value={successData?.data?.order_count || "---"} readOnly={true} loading={true}>
					Number of orders
				</InputWithLabel>
				<InputWithLabel
					value={commifyNumbers(successData?.data?.payout_amount)}
					readOnly={true}
					loading={true}
					showLabel
					currency
					currencySymbol={biz.currencySymbol}
					classes={"payout-amount-field"}
				>
					Payout Amount
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={
						(successData?.data?.start_date &&
							moment(successData?.data?.start_date, "YYYY-MM-DD").format("DD MMM, YYYY")) ||
						"---"
					}
					readOnly={true}
					loading={true}
				>
					Start date
				</InputWithLabel>
				<InputWithLabel
					value={
						(successData?.data?.end_date &&
							moment(successData?.data?.end_date, "YYYY-MM-DD").format("DD MMM, YYYY")) ||
						"---"
					}
					readOnly={true}
					loading={true}
				>
					End date
				</InputWithLabel>
			</div>
		</React.Fragment>
	) : uploading ? (
		<div className="form-row row-full uploader-section">
			{files.length === 1 && <Loading />}
			{files.length === 1 && <div class="upload-processing-message">Uploading file & reading information</div>}
			{files.length > 1 && (
				<div className="upload-processing-details ">
					<div className="file-details">
						{[...new Array(files.length)].map((file, i) => (
							<div
								className={`single-file-detail`}
								style={{
									height: multiFileUploadStatus[i]?.completed ? "120px" : "40px"
								}}
								key={i}
							>
								{!multiFileUploadStatus[i]?.completed && (
									<React.Fragment>
										<div className="file-name">{files[i].name}</div>
										{processingIndex === i && <Loading />}
									</React.Fragment>
								)}
								<div className="upload-info">
									{multiFileUploadStatus[i].completed ? (
										!multiFileUploadStatus[i].success ? (
											<React.Fragment>
												<div className="info-block-failure">
													<div className="basic-info">
														<div className="info">
															<div className="label">File name</div>
															<div className={`value`}>{files[i]?.name || "--"}</div>
														</div>
														<div className="info">
															<div className="label">Status</div>
															<div className={`value failure`}>Failure</div>
														</div>
													</div>
													<div className="error-info">
														<div className="label">Error message</div>
														<div className={`value`}>
															{multiFileUploadStatus[i]?.resultData
																? multiFileUploadStatus[i].resultData[
																		Object.keys(
																			multiFileUploadStatus[i]?.resultData
																		)[0]
																  ]
																: "--"}
														</div>
													</div>
												</div>
											</React.Fragment>
										) : (
											<React.Fragment>
												<div className="info-block">
													<div className="info">
														<div className="label">Payout Period</div>
														<div className="value">
															{(multiFileUploadStatus[i]?.resultData?.data?.start_date &&
																moment(
																	multiFileUploadStatus[i]?.resultData?.data
																		?.start_date,
																	"YYYY-MM-DD"
																).format("DD MMM, YYYY")) ||
																"---"}{" "}
															-{" "}
															{(multiFileUploadStatus[i]?.resultData?.data?.end_date &&
																moment(
																	multiFileUploadStatus[i]?.resultData?.data
																		?.end_date,
																	"YYYY-MM-DD"
																).format("DD MMM, YYYY")) ||
																"---"}
														</div>
													</div>
													<div className="info">
														<div className="label">Location Name</div>
														<div className="value">
															{multiFileUploadStatus[i]?.resultData?.locationName || "--"}
														</div>
													</div>
												</div>
												<div className="info-block">
													<div className="info">
														<div className="label">Order Count</div>
														<div className="value">
															{commifyNumbers(
																multiFileUploadStatus[i]?.resultData?.data
																	?.order_count || "--"
															)}
														</div>
													</div>
													<div className="info">
														<div className="label">Payout Amount</div>
														<div className="value">
															{printCurrency(biz.currencySymbol)}{" "}
															{commifyNumbers(
																multiFileUploadStatus[i]?.resultData?.data
																	?.payout_amount
															)}
														</div>
													</div>
												</div>
												<div className="info-block">
													<div className="info">
														<div className="label">File name</div>
														<div className="value">{files[i]?.name || "--"}</div>
													</div>
													<div className="info">
														<div className="label">Status</div>
														<div className={`value upload-status success-status`}>
															<strong>Queued successfully</strong>
															<span
																aria-label={
																	"It may take few minutes to few hours to generate results."
																}
																data-balloon-pos={"up-right"}
															>
																<img
																	className="info"
																	src="/assets/icons/info.png"
																	onClick={(e) => e.stopPropagation()}
																/>
															</span>
														</div>
													</div>
												</div>
											</React.Fragment>
										)
									) : null}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	) : (
		<React.Fragment>
			<div className="form-row uploader-section">
				<div className="csv-upload-container">
					{/* <SelectFilter
						title="Platforms"
						options={platforms}
						field="platform"
						currValue={selectedPlatform}
						isSearchable={false}
						setFilter={handleUpdatePlatform}
						placeholder="Select"
						// multi={true}
						labelKey="valueForDisplay"
						valueKey="value"
					/> */}
					<div
						className={`csv-drop-area ${selectedPlatform ? "" : "disabled"}`}
						onClick={handleClick}
						onDragOver={(e) => e.preventDefault()}
						onDragEnter={(e) => e.preventDefault()}
						onDragLeave={(e) => e.preventDefault()}
						onDrop={handleOnDrop}
					>
						<img alt="upload" class="upload-icon" src="/assets/icons/icon-upload.svg" />
						{selectedPlatform && (
							<div>
								Drag and drop your <strong>payouts</strong> or click to{" "}
								<span className="highlighted">Browse</span>
							</div>
						)}
						<input
							className="D(n)"
							ref={inputRef}
							type="file"
							accept={".xlsx,.csv"}
							onChange={handleFilesChange}
							onClick={() => {
								setFiles(undefined);
								inputRef.current.value = null;
							}}
							multiple
						/>
						<br />
						{files && (
							<>
								<strong>
									<span className="clip-icon-container">
										<img src="/assets/icons/paper-clip.svg" alt="" />
									</span>
									{files.length > 1
										? files.length + " files selected"
										: lengthAdjust(files[0]?.name, 40, 20)}
								</strong>
							</>
						)}
					</div>
				</div>
				<Button
					clickHandler={handleFilesUpload}
					classes={`upload-button ${files && selectedPlatform ? "" : "disabled"}`}
				>
					Upload
				</Button>
			</div>
		</React.Fragment>
	);
};

export default connect((store) => ({
	biz: store.login.loggedInbizDetail,
	userInfo: store.login.loginDetail,
	selectedPlatform: store.reconciliationPayoutsList.selectedPlatform,
	platforms: store.reconciliationPayoutsList.availablePlatforms
}))(Upload);
