import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { getBrandDetail, getPlatformsDetailsViaId } from "../../../helpers/periscope";
import { connect } from "react-redux";
import { extractInitials, lS, scroll } from "../../../atlas-utils";
import moment from "moment";
import ActivityLogTableLoading from "../../../components/Periscope/ActivityLog/ActivityLogTableLoading";
import { Paginator } from "../../../components/_commons/Paginator";
import { store } from "../../../store/configureStore";
import { ActionTypes } from "../../../actions/_types";
import { PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";
import SideBarActivityLog from "../../../components/Periscope/ActivityLog/SideBarActivityLog";

const ActivityLogTable = ({
	fetchData,
	configItems,
	periscopeActivityLogData,
	periscopeActivityLogState,
	tableRef,
	selectedBrand,
	login,
	isMultibrandEnabled,
	tableName = "location"
}) => {
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});
	const brands = configItems?.brands?.items;
	const loading = periscopeActivityLogData?.loading;
	const error = periscopeActivityLogData?.error;
	const dataCount = periscopeActivityLogData?.data?.objects?.meta?.totalCount || 0;

	const data = useMemo(
		() =>
			Array.isArray(periscopeActivityLogData?.data?.objects?.data)
				? periscopeActivityLogData.data.objects.data
				: [],
		[periscopeActivityLogData?.data?.objects?.data]
	);
	const tableColumnWidthMeta = {
		location: {
			location: "22%",
			brand: "22%",
			platform: "13%",
			status: "13%",
			timestamp: "13%",
			previousTimestamp: "15%"
		},
		item: {
			item: "22%",
			location: "22%",
			brand: "13%",
			platform: "7%",
			status: "7%",
			timestamp: "14%",
			previousTimestamp: "14%"
		}
		// item: { item: "25%", location: "22%", brand: "16%", platform: "10%", status: "13%", timestamp: "14%" }
		// offers: { offers: "20%", location: "20%", brand: "20%", platform: "10%", status: "16%", timestamp: "16%" }
	};

	const platform = {
		Header: "Platform",
		accessor: "platform",
		Cell: (props) => {
			const platformDetails = getPlatformsDetailsViaId(props?.value?.id, bizPlatforms);
			return (
				<div style={{}}>
					{platformDetails?.image ? (
						<img
							src={platformDetails?.image}
							style={{ width: "28px", height: "28px", backgroundSize: "contain" }}
						/>
					) : (
						""
					)}
				</div>
			);
		}
	};
	const brand = {
		Header: "Brand",
		accessor: "brand",
		Cell: (props) => {
			const brandDetails = getBrandDetail(props.value.id, brands);
			return (
				<div className=" medium-bold-text" style={{ display: "flex", gap: "12px" }}>
					<div style={{ width: "20px", height: "20px" }}>
						{brandDetails?.image ? (
							<img
								src={brandDetails?.image}
								style={{ width: "20px", height: "20px", borderRadius: "2px" }}
							/>
						) : (
							<div
								className={`text-logo ${brandDetails?.color}`}
								style={{ textAlign: "center", fontWeight: "600", padding: "0 2px" }}
							>
								{extractInitials(brandDetails?.name?.split(" "))}
							</div>
						)}
					</div>

					{props?.value?.name}
				</div>
			);
		}
	};
	const status = {
		Header: "Status",
		accessor: "status",
		Cell: (props) => {
			const wentOnline = <div className="went-online">Went online</div>;
			const wentOffline = <div className="went-offline">Went offline</div>;
			const online = <div className="went-online"> Online</div>;
			const offline = <div className="went-offline">Offline</div>;
			const removed = <div className="removed">Removed</div>;
			const dictStatus = {
				offline,
				online,
				went_online: wentOnline,
				went_offline: wentOffline,
				removed: removed
			};
			return <div className="status-log">{dictStatus[props.value]}</div>;
		}
	};
	const location = {
		Header: "Location",
		accessor: "location",
		Cell: (props) => {
			return (
				<div className={tableName == "location" ? "first-col-bold-text" : "medium-bold-text"}>
					{props?.value?.name}
				</div>
			);
		}
	};

	const previousTimestamp = {
		Header: "Time since status change",
		accessor: "previousTimestamp",
		Cell: (props) => {
			const currentTime = moment.utc(moment(), "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss");
			const duration = moment.duration(moment.utc(props?.value, "YYYY-MM-DD HH:mm:ss").diff(currentTime));
			const days = Math.abs(Math.floor(duration.asDays()));
			const hours = Math.abs(duration.hours());
			const minutes = Math.abs(duration.minutes());
			let value = "";
			if (days) {
				value += `${days}d `;
			}

			if (hours) {
				value += `${hours}hr `;
			}
			if (minutes) {
				value += `${minutes}min`;
			}

			return <div className="medium-bold-text">{value}</div>;
		}
	};
	const timestamp = {
		Header: "Time Updated",
		accessor: "timestamp",
		Cell: (props) => {
			return (
				<div className="medium-bold-text" style={{ cursor: "pointer" }}>
					{moment.utc(props?.value, "YYYY-MM-DD HH:mm:ss").format("D MMM, h:mm A")}
				</div>
			);
		}
	};

	const item = {
		Header: "Item",
		accessor: "item",
		Cell: (props) => {
			return (
				<div
					style={{ flexGrow: "1" }}
					className={tableName == "item" ? "first-col-bold-text" : "medium-bold-text"}
				>
					{props.value?.name}
				</div>
			);
		}
	};
	const offers = {
		Header: "Offers",
		accessor: "offers",
		Cell: (props) => {
			return <div> offers </div>;
		}
	};

	const getColumns = (tableName) => {
		if (tableName == "location") {
			if (selectedBrand?.id !== "all" || !isMultibrandEnabled) {
				return [location, platform, status, timestamp, previousTimestamp];
			}
			return [location, brand, platform, status, timestamp, previousTimestamp];
		} else if (tableName == "item") {
			if (selectedBrand?.id !== "all" || !isMultibrandEnabled) {
				return [item, location, platform, status, timestamp, previousTimestamp];
			}
			return [item, location, brand, platform, status, timestamp, previousTimestamp];
		} else if (tableName == "offers") {
			if (!isMultibrandEnabled) {
				return [offers, location, platform, status, timestamp];
			}
			return [offers, location, brand, platform, status, timestamp];
		}
	};

	const columns = useMemo(() => getColumns(tableName), [data, brands, tableName]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

	const limit = periscopeActivityLogState?.limit;
	const offset = periscopeActivityLogState?.offset;

	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_STATE_FILTERS,
			payload: { offset: offset }
		});

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};
	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_STATE_FILTERS,
			payload: { limit: size.value }
		});
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_STATE_FILTERS,
			payload: { offset: 0 }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const [formState, setFormState] = useState(false);
	const [formData, setFormData] = useState({});
	const [timeOption, setTimeOption] = useState("last_1_hour");
	const handleOpen = (data = {}) => {
		const formData = {};
		formData["location_id"] = data?.location?.id;
		formData["platform_id"] = data?.platform?.id;
		formData["location"] = data?.location?.name;
		formData["platform"] = data?.platform?.name;
		formData["brand_id"] =
			isMultibrandEnabled && selectedBrand?.id == "all" && data?.brand?.id
				? String(data?.brand?.id)
				: String(selectedBrand?.id);
		formData["brand"] =
			isMultibrandEnabled && selectedBrand?.id == "all" && data?.brand?.id
				? data?.brand?.name
				: selectedBrand?.name;
		if (tableName == "item") {
			formData["item_id"] = data?.item?.id;
			formData["item"] = data?.item?.name;
		}
		setFormData(formData);
		setTimeOption("last_1_hour");
		setFormState(true);
	};
	const handleTimeFilter = (val) => {
		if (val?.value) {
			setTimeOption(val.value);
		}
	};

	return (
		<div className="activity-log-table-div" data-testid="activity-log-data">
			<SideBarActivityLog
				tableName={tableName}
				isFormOpen={formState}
				setFormState={setFormState}
				formData={formData}
				isMultibrandEnabled={isMultibrandEnabled}
				bizId={String(login?.loggedInbizDetail?.id)}
				periscopeActivityLogData={periscopeActivityLogData}
				handleTimeFilter={handleTimeFilter}
				timeOption={timeOption}
			/>
			<table className="activity-log-table" {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()} className="log-thead">
							{headerGroup.headers.map((column) => {
								const widthPercentage = tableColumnWidthMeta[tableName][column.id];
								return (
									<th
										{...column.getHeaderProps()}
										style={{
											width: `${widthPercentage}`,
											...(column.id == "item" || column.id == "offers" ? { flexGrow: "1" } : {})
										}}
										className="table-header-log"
									>
										{column.render("Header")}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				{loading || error ? (
					<ActivityLogTableLoading refreshBtn={fetchData} columns={columns} error={error} />
				) : (
					<>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return (
												<td
													{...cell.getCellProps()}
													style={{
														borderBottom: "1px solid #EAEAEA",
														padding: "16px 6px 16px 24px",
														cursor: "pointer"
													}}
													onClick={() => {
														handleOpen(cell?.row.values);
													}}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="100%" style={{ textAlign: "right" }} className="paginator-location">
									<Paginator
										limit={limit}
										offset={offset}
										count={dataCount || 0}
										showPageSize={true}
										goToPage={handlePagination}
										setPageSize={handlePageSize}
									/>
								</td>
							</tr>
						</tfoot>
					</>
				)}
			</table>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	periscopeActivityLogData: state.periscopeActivityLogData,
	periscopeActivityLogState: state.periscopeActivityLogState,
	login: state.login,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(ActivityLogTable);
