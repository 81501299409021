import React, { Component } from "react";

// components
import { Header } from "../components/ModifiersList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchModifiersList } from "../actions/modifiers";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll, printCurrency, adjustNestedContainer, fixedToTwoDecimal } from "../atlas-utils";
import history from "../history";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES } from "../client-config";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Title",
		field: "name",
		sortKey: "option_title",
		render: (record, i, archived) => (
			<div className={"at-table-cell at-cell-text name"} title={record.optionTitle} key={i}>
				<div className="name-desc">
					<Link className={"link-text " + archived} to={`/modifiers/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">{record.optionTitle || record.id}</span>
					</Link>
					<div className="text--light item_tags">
						{record?.tags?.length > 0 && (
							<div className="tags-container table-mode">
								<div className="list">
									{record.tags.map(
										(tag, i) =>
											i < 3 && (
												<span key={i} className="tag-item" title={tag}>
													{tag}
												</span>
											)
									)}
									{record.tags.length > 3 && (
										<span className="tag-item more-tags" title={record.tags.slice(3).join(", ")}>
											+{record.tags.length - 3} more
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	},
	{
		name: "CRM Title",
		field: "crm-title",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text crm-title"} key={i}>
				<div>{record.crmTitle || "--"}</div>
			</div>
		)
	},
	{
		name: "Modifier Groups",
		field: "associated-modifier-groups",
		sortKey: "modifier_groups_count",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text associated-modifier-groups"} key={i}>
				{record.modifierGroupsCount || 0}
			</div>
		)
	},
	{
		name: "Locations",
		field: "associated-locations",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text associated-locations"} key={i}>
				{record.numAssociatedLocations || 0}
			</div>
		)
	},
	{
		name: "Price",
		field: "price",
		sortKey: "option_price",
		render: (record, i, archived, currencySymbol) => (
			<div className={"at-table-cell at-cell-text price"} key={i}>
				{printCurrency(currencySymbol)}
				{fixedToTwoDecimal(record.optionPrice) || 0}
			</div>
		)
	}
];

@connect((store) => ({
	modifiersList: store.modifiersList,
	modifiersListState: store.modifiersListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class ModifiersList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "modifiers_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.modifiersListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Modifiers list
		await fetchModifiersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "modifiers"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatemodifiersListState = (payload) => {
		store.dispatch({
			type: ActionTypes.MODIFIERS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatemodifiersListState({
			currentFilters: this.props.modifiersListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.modifiersListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatemodifiersListState({
			currentFilters
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.MODIFIERS_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.MODIFIERS_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.updatemodifiersListState({
			offset: 0
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchModifiersList(), 500);

	updateItemsLookup = (id, title) => {
		this.setState({
			itemsLookup: {
				...this.state.itemsLookup,
				[id]: title
			}
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatemodifiersListState({
			appliedFilters: {
				...this.props.modifiersListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "modifiers_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchModifiersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().modifiersListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updatemodifiersListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchModifiersList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.modifiersListState;
		const offset = (page - 1) * limit;
		this.updatemodifiersListState({
			offset
		});
		// fetch new Modifiers list
		fetchModifiersList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.modifiersListState;
		if (size && size?.value !== limit) {
			this.updatemodifiersListState({
				[field]: size.value
			});
			// fetch new Modifiers list
			await fetchModifiersList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatemodifiersListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.MODIFIERS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchModifiersList();
	};

	render() {
		const { modifiersList, modifiersListState, configItems, access, biz } = this.props;
		const { limit, offset, currentFilters, sortedField } = modifiersListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "option_title") {
				if (f === "is_enabled" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f].value && currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}
		const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;

		const filterOptions = isMenuOverCatalogueEnabled
			? modifiersList.data.filters.filter(
					(f) =>
						f.field !== "option_title" && f.field !== "modifier_groups_count" && f.field !== "option_groups"
			  )
			: modifiersList.data.filters.filter((f) => f.field !== "option_title");

		const placeholderContent = {
			placeholderText: "No modifiers added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
			placeholderSubtext:
				"Manage all modifiers here enabling customisation of items like adding variations or choosing between meal sizes",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add a new Modifier</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/modifiers/new");
			},
			redirectionLink: "/piper-academy/modifiers",
			redirectionLinkText: "learn more about modifiers",
			size: "medium"
		};

		return (
			<div className="modifiers-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={filterOptions}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
					/>
				)}
				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
					searchKeywords={modifiersList.data.searchKeywords}
					searchFieldSelected={modifiersList.data.searchFieldSelected}
					searchFieldValue={modifiersList.data.searchFieldValue}
					handleSearchField={this.handleSearchField}
					setFilter={this.setSearchFilter}
					applySearchFilter={this.applySearchFilter}
					handlePiperAcademy={this.handlePiperAcademy}
					isCatalogueManagement={access.isCatalogueManagement}
				/>
				<CommonTable
					loading={modifiersList.loading}
					data={modifiersList.data.objects || []}
					columns={
						isMenuOverCatalogueEnabled
							? columns?.filter(
									(col) => !["associated-modifier-groups", "associated-locations"].includes(col.field)
							  )
							: columns
					}
					sortList={this.sortList}
					sortedField={sortedField}
					currencySymbol={this.props.biz.currencySymbol}
					archived={this.state.archivedFilter ? "archived" : ""}
					classes="modifiers-list-table-container"
					content="Modifiers"
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={modifiersList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		);
	}
}
