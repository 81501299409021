import React, { useState, useCallback, useEffect } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import Placeholder from "../_commons/Placeholder";

// graphql
import {
	GET_BIZ_PLATFORMS,
	GET_LOCATION_PLATFORM_ASSOCIATIONS_IDS,
	VERIFY_CATALOGUE_REQUEST
} from "../../graphql/locations";

// utils
import { extractInitials, trackEvent } from "../../atlas-utils";
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchBrands } from "../../actions/actions";

// constants
import { CATALOGUE_PLATFORMS_LOGO, TRACKING_EVENT_NAMES, TRACKING_SOURCE } from "../../client-config";

const VerifyCatalogue = ({
	isNested = false,
	locations = [],
	isOpen = false,
	close,
	brands = [],
	action = "disable",
	selectedBrand = null,
	fromBrands = false,
	isMultibrandEnabled = false
}) => {
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [bizPlatformsList, setBizPlatformsList] = useState([]);
	const [publishResponse, setPublishResponse] = useState(null);
	const [brand, setBrand] = useState(selectedBrand);
	const [platformUpdates, setPlatformUpdates] = useState({});

	useEffect(() => {
		if (isOpen) {
			fetchBizPlatformsList();
			if (isMultibrandEnabled) {
				fetchBrands("", true);
			}
		}
	}, [isOpen]);

	useEffect(() => {
		setBrand(selectedBrand);
	}, [selectedBrand]);

	const fetchBizPlatformsList = async () => {
		try {
			setLoading(true);
			const variables = {
				limit: 30,
				offset: 0
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizPlatformsList(resp.data.bizPlatforms.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const handleSelectBrand = (brand) => {
		setBrand(brand);
	};

	const handleSelectPlatform = (platform, isSelected) => {
		setPlatformUpdates({
			...platformUpdates,
			[platform.id]: isSelected
		});
	};

	const handleSelectAllPlatforms = () => {
		let updates = {};
		bizPlatformsList.forEach((plf) => {
			updates[plf.id] = true;
		});
		setPlatformUpdates(updates);
	};

	const handleClose = useCallback(
		(success = false) => {
			// reset state before closing
			setBizPlatformsList([]);
			setPlatformUpdates({});
			setPublishResponse(null);
			setBrand(selectedBrand);
			close(success);
		},
		[close, publishResponse, selectedBrand]
	);

	const handleDisabled = () => {
		let disabled = false;
		if (isMultibrandEnabled && brand === null) {
			disabled = true;
		}
		disabled = Object.values(platformUpdates).filter((val) => val === true).length === 0;
		return confirmLoading || disabled;
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 85 ? brand.name.slice(0, 85) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleCatalogueVerificationRequest = async () => {
		setConfirmLoading(true);
		const selectedPlatforms = bizPlatformsList.filter((platform) => platformUpdates[platform.id]);

		try {
			const locationPlatformAssociationsResp = await client.query({
				query: GET_LOCATION_PLATFORM_ASSOCIATIONS_IDS,
				variables: {
					locationIds: locations.map((loc) => loc.id),
					brandIds: selectedBrand?.id ? [selectedBrand?.id] : null
				},
				fetchPolicy: "no-cache"
			});

			const locationPlatformAssociations = locationPlatformAssociationsResp?.data?.locationPlatformAssociations;

			if (!locationPlatformAssociations) {
				setPublishResponse({ status: "fail" });
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Oops! Something went wrong",
						timeout: 5000,
						error: true
					}
				});
				return;
			}
			const catalogueVerificationData = [];
			selectedPlatforms.forEach((platform) => {
				locations.forEach((loc) => {
					locationPlatformAssociations.forEach((assoc) => {
						if (isMultibrandEnabled) {
							if (
								assoc?.location?.id === loc?.brandLocation?.id &&
								platform.platformName.toLowerCase() === assoc.platformName.toLowerCase()
							) {
								catalogueVerificationData.push({
									locationId: assoc?.location?.id,
									bizPlatformId: platform?.id,
									platformName: assoc?.platformName,
									id: assoc?.id
								});
							}
						} else {
							if (
								assoc?.location?.id === loc?.id &&
								platform.platformName.toLowerCase() === assoc.platformName.toLowerCase()
							) {
								catalogueVerificationData.push({
									locationId: assoc?.location?.id,
									bizPlatformId: platform?.id,
									platformName: assoc?.platformName,
									id: assoc?.id
								});
							}
						}
					});
				});
			});

			const variables = {
				data: [...catalogueVerificationData],
				action: "verify"
			};

			const verifyCatalogueResp = await client.mutate({
				mutation: VERIFY_CATALOGUE_REQUEST,
				variables
			});

			if (verifyCatalogueResp?.data?.saveLocationPlatformAssociations?.status?.success) {
				const eventMeta = {
					platforms: selectedPlatforms.map((platform) => platform.platformName.toLowerCase()),
					number_of_locations: locations.length,
					source: TRACKING_SOURCE.LIST_VIEW_BULK
				};
				trackEvent(TRACKING_EVENT_NAMES.ON_DEMAND_VERIFICATION, eventMeta);
				setPublishResponse({ status: "success" });
			}
		} catch (e) {
			console.log(e);
			setPublishResponse({ status: "fail" });
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Oops! Something went wrong",
					timeout: 5000,
					error: true
				}
			});
		}
		setConfirmLoading(false);
	};

	const placeholderContent = {
		placeholderText:
			publishResponse?.status === "success"
				? "Verification request registered successfully"
				: "There was an error",
		placeholderImageUrl:
			publishResponse?.status === "success"
				? "/assets/icons/graphics-success-request.svg"
				: "/assets/icons/icon-request-failed.svg",
		placeholderSubtext:
			publishResponse?.status === "success"
				? "Your request is under verification for the selected location(s), once it's completed we will notify you."
				: "Your request to verify catalogue has failed. Please check your internet connectivity and try again.",
		placeholderButtonContent: publishResponse?.status === "fail" ? "Try again" : "",
		placeholderButtonClickAction: publishResponse?.status === "fail" ? () => setPublishResponse(null) : () => {},
		secondaryActionTitle: "Dismiss",
		secondaryAction: () => handleClose(publishResponse?.status),
		placeholderCustomClasses: publishResponse?.status === "fail" ? "menu-verification-placeholder" : ""
	};

	return (
		<div className="locations-store-toggle-container locations-verify-catalogue-container">
			<FormSidebar
				isOpen={isOpen}
				loading={loading}
				submit={handleCatalogueVerificationRequest}
				close={() => handleClose(publishResponse?.status)}
				title="Verify Catalogue"
				subTitle={`Select brand(s) and platform(s) you want to request for verification`}
				submitTitle="Verify"
				cancelTitle={publishResponse !== null ? "Close" : "Cancel"}
				hideSubmitAction={publishResponse !== null}
				disabled={handleDisabled()}
				isNested={isNested}
				overlaySidebarClasses="medium"
			>
				{!confirmLoading && !publishResponse && (
					<React.Fragment>
						{isMultibrandEnabled && (
							<div className="section brand-selection">
								<div className="section-header">Brands</div>
								<SelectFilterCustom
									options={brands.items}
									isLoading={brands.isLoading}
									field="brands"
									currValue={brand}
									setFilter={(f, value) => handleSelectBrand(value)}
									labelKey={"name"}
									valueKey={"id"}
									ellipsizedLength={200}
									isSearchable={false}
									customLabel={true}
									customOptions={true}
									isClearable={false}
									renderLabel={handleBrandsLabelOption}
									renderOptions={handleBrandsLabelOption}
									placeholder="Select brand"
									classes={fromBrands ? "brands disabled" : "brands"}
								/>
							</div>
						)}
						<div className="section platforms-selection">
							<div className="section-header">
								<div>Platforms</div>
								{bizPlatformsList.length > 0 && (
									<div className="select-all" onClick={handleSelectAllPlatforms}>
										Select All
									</div>
								)}
							</div>
							{loading && bizPlatformsList.length === 0 && (
								<div>
									<div className="shimmer H(25px) Mb(10px)" />
									<div className="shimmer H(25px) Mb(10px)" />
									<div className="shimmer H(25px) Mb(10px)" />
								</div>
							)}
							{!loading && bizPlatformsList.length === 0 && (
								<div className="no-items-placeholder">No platforms found</div>
							)}
							{!loading &&
								bizPlatformsList.map((plf, i) => (
									<CheckBox
										key={i}
										checked={platformUpdates[plf.id]}
										clickHandler={() => handleSelectPlatform(plf, !platformUpdates[plf.id])}
									>
										<div className="platform-info">
											<div className="platform-name" title={plf.platformName}>
												{plf.platformName}
											</div>
											<div className="platform-logo">
												<img
													src={
														plf?.logo ||
														CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
														"/assets/icons/icons8-globe-40.png"
													}
													alt=""
												/>
											</div>
										</div>
									</CheckBox>
								))}
						</div>
					</React.Fragment>
				)}
				{confirmLoading && (
					<div className="P(25px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{!confirmLoading && publishResponse && <Placeholder {...placeholderContent} />}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(VerifyCatalogue);
