import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";

// actions
import { fetchStoresDebounced } from "../../actions/actions";

// third party
import { connect } from "react-redux";

const BasicInfo = ({
	data = {},
	handleForm,
	validations,
	listGroups,
	access,
	loggedInUsername,
	readOnly = true,
	locationsList
}) => {
	const isAdminEnabled = data?.groups?.find((role) => role.id === "71") !== undefined ? true : false;
	const showLocations =
		data?.groups?.find((role) => role.id === "53" || role.id === "55" || role.id === "66") !== undefined
			? true
			: false;

	return (
		<React.Fragment>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.firstName}
					onChange={(e) => handleForm("firstName", e.target.value)}
					validationMessage={validations.firstName || ""}
					requiredLabel={true}
					readOnly={readOnly || (loggedInUsername === data.username && data.isActive ? false : true)}
				>
					First Name
				</InputWithLabel>
				<InputWithLabel
					value={data.lastName}
					onChange={(e) => handleForm("lastName", e.target.value)}
					validationMessage={validations.lastName || ""}
					requiredLabel={true}
					readOnly={readOnly || (loggedInUsername === data.username && data.isActive ? false : true)}
				>
					Last Name
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.username}
					onChange={(e) => handleForm("username", e.target.value)}
					validationMessage={validations.username || ""}
					requiredLabel={true}
					readOnly={readOnly}
				>
					Username
				</InputWithLabel>
				<InputWithLabel
					value={data.email}
					onChange={(e) => handleForm("email", e.target.value)}
					validationMessage={validations.email || ""}
					readOnly={true}
				>
					Email
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Role"
					options={listGroups.items}
					readOnly={!(!readOnly && access.isAdmin && data.isActive)}
					isLoading={listGroups.isLoading}
					field="groups"
					currValue={data.groups}
					setFilter={handleForm}
					requiredLabel={true}
					multi={true}
					labelKey="name"
					valueKey="id"
					validationMessage={
						validations.groups !== "" && validations.groups !== undefined
							? validations.groups
							: isAdminEnabled
							? "Admin role gives unrestricted access throughout the application"
							: ""
					}
					positiveValidationMessage={
						validations.groups !== "" && validations.groups !== undefined
							? false
							: isAdminEnabled
							? true
							: false
					}
				/>
				{showLocations && (
					<SelectFilter
						title="Locations"
						options={locationsList.items}
						readOnly={!(!readOnly && access.isAdmin && data.isActive)}
						isLoading={locationsList.isLoading}
						field="locations"
						currValue={data.locations}
						setFilter={handleForm}
						isAsync={true}
						isSearchable={true}
						handleSearch={fetchStoresDebounced}
						multi={true}
						labelKey="name"
						valueKey="id"
					/>
				)}
			</div>
		</React.Fragment>
	);
};
export default connect((store) => ({
	listGroups: store.configItems.listGroups,
	access: store.login.loginDetail.access,
	loggedInUsername: store.login.loginDetail.username,
	locationsList: store.configItems.stores
}))(BasicInfo);
