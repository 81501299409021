import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import AuditEventsList from "./AuditEventsList";
import AuditEventsPreview from "./AuditEventsPreview";

const AuditEventsContainer = ({ hasAccess = false }) => {
	return (
		<div className="audit-events-container">
			<Route path="/activity-history" component={AuditEventsList} />
			<Route exact path="/activity-history/preview/:id" component={AuditEventsPreview} />
		</div>
	);
};
export default AuditEventsContainer;
