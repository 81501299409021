import React, { Component } from "react";

// components
import { Header } from "../components/ReconciliationPayoutsList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import WelcomeBoard from "../components/ReconciliationPayoutsList/WelcomeBoard";
import ExportReport from "../components/ReconciliationPayoutsList/ExportReport";
import { NewDateCompareFilter } from "../components/_commons/NewDateCompareFilter";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import DropdownCustom from "../components/_commons/DropdownCustom";
import HoverContentDisplayGrid from "../components/_commons/HoverContentDisplayGrid";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { Link } from "react-router-dom";

// actions
import { fetchStoresDebounced } from "../actions/actions";
import { fetchPayoutSheetsList } from "../actions/reconciliation";
import { ActionTypes } from "../actions/_types";

// utils
import {
	scroll,
	trackEvent,
	capitaliseText,
	formatDate,
	commifyNumbers,
	printCurrency,
	adjustNestedContainer
} from "../atlas-utils";
import history from "../history";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES, CATALOGUE_PLATFORMS_LOGO } from "../client-config";

const CSS_CLASS_KEY_VALUE_PAIRS = {
	queued: "queued"
};

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const RECON_STATUS_INFO = {
	show: false,
	content: {
		reconciled: 0,
		inconsistent: 0,
		missing: 0
	},
	position: {
		x: 0,
		y: 0
	}
};

const columns = [
	{
		name: "Payout Period",
		field: "payout-period",
		sortKey: "start_date",
		render: (record, i, ar, cs, ht, cb, rest) => {
			const formattedPayoutPeriod =
				formatDate(record.startDate, "DD MMM, YYYY") + " - " + formatDate(record.endDate, "DD MMM, YYYY") ||
				"---";
			return (
				<div className="at-table-cell at-cell-text payout-period" title={formattedPayoutPeriod} key={i}>
					<Link
						to={`/payout-sheets/details/${record.id}`}
						className={"link-text "}
						onClick={() => rest.savePayoutStatuses(record.processingStatus)}
					>
						<span className="hyperlink hyperlink--black-color">{formattedPayoutPeriod}</span>
					</Link>
				</div>
			);
		}
	},
	{
		name: "Location",
		field: "loc-info",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text loc-info " title={record.bizLocationName} key={i}>
				<div>{record.bizLocationName || "--"}</div>
			</div>
		)
	},
	{
		name: "Order Count",
		field: "order-count",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text order-count" key={i}>
				<div>{record.orderCount || "--"}</div>
			</div>
		)
	},
	{
		name: "Status",
		field: "verification",
		render: (record, i, ar, cs, ht, cb, rest) => {
			let renderStatus = undefined;
			let isObject = false;
			let statusType = "";
			try {
				renderStatus = JSON.parse(record.processingStatus);
				isObject = true;
			} catch {
				renderStatus = record.processingStatus;
			}
			if (renderStatus.reconciled_orders && !renderStatus.inconsistent_orders && !renderStatus.missing_orders) {
				statusType = "no-discrepancies-processed";
			} else if (renderStatus.inconsistent_orders || renderStatus.missing_orders) {
				statusType = "discrepancies-processed";
			} else {
				statusType = "processing";
			}

			return (
				<div className={"at-table-cell at-cell-text verification "} key={i}>
					<span
						id={`recon-status-container-${rest.rowIndex}`}
						className={`status-value status-${statusType}`}
						onMouseEnter={(e) =>
							rest.handleMouseEnter(e, renderStatus, `recon-status-container-${rest.rowIndex}`)
						}
						onMouseLeave={rest.handleMouseLeave}
					>
						{statusType === "processing"
							? "Processing"
							: statusType === "no-discrepancies-processed"
							? "Processed without Discrepancies"
							: "Processed with Discrepancies"}
					</span>
				</div>
			);
		}
	},
	{
		name: "receivable",
		field: "receivable",
		render: (record, i, ar, cs) => (
			<div className="at-table-cell at-cell-text receivable " key={i}>
				<div>
					{record?.payoutAmount && printCurrency(cs)}&nbsp;
					{commifyNumbers(record?.payoutAmount, null, { minimumFractionDigits: 2 }) || "--"}
				</div>
			</div>
		)
	},
	{
		name: "BANK Transactions",
		field: "utr-transaction",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text utr-transaction " title={record.bizLocationId} key={i}>
				<div>{record.bizLocationId || "--"}</div>
			</div>
		)
	}
];

@connect((store) => ({
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access,
	reconciliationPayoutsList: store.reconciliationPayoutsList,
	reconciliationPayoutsState: store.reconciliationPayoutsState
}))
export class ReconciliationPayoutsList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			showFilters: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			reconStatusInfo: RECON_STATUS_INFO,
			storesLookup: {},
			isOpen: false,
			isExportReportsOpen: false
		};
	}

	async componentDidMount() {
		// the below code preloads the stores dropdown
		if (!this.props.configItems.stores.items.length) {
			fetchStoresDebounced("");
		}

		// set tracking related info
		const eventName = "payouts_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	}

	async componentWillReceiveProps(newProps) {
		if (
			this.props.reconciliationPayoutsState.appliedDateFilter.current.dateFilter !==
			newProps.reconciliationPayoutsState.appliedDateFilter.current.dateFilter
		) {
			// fetch data with updated applied filters
			await fetchPayoutSheetsList();
		}
	}

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updateReconciliationPayoutsState = (payload) => {
		store.dispatch({
			type: ActionTypes.RECONCILIATION_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateReconciliationPayoutsState({
			currentFilters: this.props.reconciliationPayoutsState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.reconciliationPayoutsState.currentFilters
		};
		currentFilters[field] = value;
		this.updateReconciliationPayoutsState({
			currentFilters
		});
		this.setState({
			filtersUsed: {
				...this.state.filtersUsed,
				[field]: true
			}
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateReconciliationPayoutsState({
			appliedFilters: {
				...this.props.reconciliationPayoutsState.currentFilters
			},
			offset: 0
		});

		// apply filters
		await fetchPayoutSheetsList();
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updateReconciliationPayoutsState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchPayoutSheetsList();
			}
		);
	};

	handlePlatformChange = async (value, field) => {
		store.dispatch({
			type: ActionTypes.SET_SELECTED_PLATFORM,
			payload: value
		});
		this.setState({ isOpen: !this.state.isOpen });
		await fetchPayoutSheetsList();
	};

	openAssociationSidebar = () => {
		this.setState({
			associationSidebar: true
		});
	};

	openToggleSidebar = () => {
		this.setState({
			toggleSidebar: true
		});
	};

	handlePagination = async (page) => {
		// set new offset
		const { limit } = this.props.reconciliationPayoutsState;
		const offset = (page - 1) * limit;
		this.updateReconciliationPayoutsState({
			offset
		});
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
		await fetchPayoutSheetsList();
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.reconciliationPayoutsState;
		if (size && size?.value !== limit) {
			this.updateReconciliationPayoutsState({
				[field]: size.value
			});
			await fetchPayoutSheetsList();
		}

		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	updateStoresLookup = (id, title) => {
		this.setState({
			storesLookup: {
				...this.state.storesLookup,
				[id]: title
			}
		});
	};

	handleOpenUrl = (url) => {
		if (url) {
			window.open(url, "_blank").focus();
		}
	};

	sortList = async (field) => {
		const sort = {
			field
		};
		store.dispatch({
			type: ActionTypes.RECONCILIATION_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		await fetchPayoutSheetsList();
	};

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	savePayoutStatuses = (status) => {
		let parsedStatusValues = null;
		try {
			parsedStatusValues = JSON.parse(status);
			store.dispatch({
				type: ActionTypes.SET_SELECTED_LOCATION_PAYOUT_STATUS,
				payload: parsedStatusValues
			});
		} catch {
			store.dispatch({
				type: ActionTypes.SET_SELECTED_LOCATION_PAYOUT_STATUS,
				payload: null
			});
		}
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "reconciliation"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	setReconStatusInfo = (value) => {
		this.setState({ reconStatusInfo: { ...value } });
	};

	handleMouseLeave = () => {
		this.setReconStatusInfo({ ...RECON_STATUS_INFO });
	};

	handleMouseEnter = (e, renderStatus, targetContainerId) => {
		const targetContainerElementPos = document.getElementById(targetContainerId)?.getBoundingClientRect();
		e.preventDefault();
		e.stopPropagation();
		const { clientX, clientY } = e;
		if (targetContainerElementPos) {
			if (!this.state.reconStatusInfo.show) {
				this.setReconStatusInfo({
					show: true,
					position: {
						x: targetContainerElementPos.x,
						y: targetContainerElementPos.y - targetContainerElementPos.height - 110
					},
					content: {
						reconciled: renderStatus?.reconciled_orders || 0,
						missing: renderStatus?.missing_orders || 0,
						inconsistent: renderStatus?.inconsistent_orders || 0,
						manuallyReconciled: renderStatus?.resolved_orders || 0,
						missingInAtlas: renderStatus?.open_orders || 0
					}
				});
			}
		} else {
			if (!this.state.reconStatusInfo.show) {
				this.setReconStatusInfo({
					show: true,
					position: { x: clientX, y: clientY },
					content: {
						reconciled: renderStatus?.reconciled_orders || 0,
						missing: renderStatus?.missing_orders || 0,
						inconsistent: renderStatus?.inconsistent_orders || 0,
						manuallyReconciled: renderStatus?.resolved_orders || 0,
						missingInAtlas: renderStatus?.open_orders || 0
					}
				});
			}
		}
	};

	handleExportReport = () => {
		this.setState({ isExportReportsOpen: !this.state.isExportReportsOpen });
	};

	getDropdownLabel = (platformName) => {
		if (platformName) {
			return (
				<div className="platform-detail">
					{platformName && (
						<div className="logo">
							<img
								src={
									CATALOGUE_PLATFORMS_LOGO[platformName.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
								}
								alt=""
							/>
						</div>
					)}
					<div>
						{platformName && platformName.length > 16 ? platformName.slice(0, 16) + "..." : platformName}
					</div>
				</div>
			);
		}
		return <div className="placeholder">Select platform</div>;
	};

	render() {
		const { configItems, reconciliationPayoutsState, reconciliationPayoutsList, biz, access = {} } = this.props;
		const { limit, offset, currentFilters, appliedFilters, sortedField, currentDateFilter, appliedDateFilter } =
			reconciliationPayoutsState;

		const { reconStatusInfo, isOpen, isExportReportsOpen } = this.state;

		let filterCount = 0;
		for (let f in appliedFilters) {
			if (appliedFilters[f].value && appliedFilters[f].value !== "") {
				filterCount++;
			}
		}

		const filterOptions = reconciliationPayoutsList.data.filters.map((f, i) => {
			if (f.field === "biz_location_id") {
				f = {
					...f,
					isAsync: true,
					asyncOptions: this.props.configItems.stores,
					asyncLookup: this.state.storesLookup,
					updateAsyncLookup: this.updateStoresLookup,
					handleAsyncSearch: fetchStoresDebounced,
					labelKey: "name",
					valueKey: "id"
				};
			}
			return f;
		});

		const placeholderContent = {
			placeholderText: "No payouts uploaded yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
			placeholderSubtext:
				"Reconcile payouts from online platforms with data from your Point of Sale & Bank and find discrepancies",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Upload your Payout</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/payout-sheets/upload");
			},
			redirectionLink: "/piper-academy/reconciliation-intro",
			redirectionLinkText: "learn more about Reconciliation tool",
			size: "medium"
		};

		return reconciliationPayoutsList.firstLoad ? (
			<WelcomeBoard />
		) : (
			<React.Fragment>
				<div className="locations-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						setFilter={this.setSearchFilter}
						applySearchFilter={this.applySearchFilter}
						isAdmin={access.isAdmin}
						isHubManagement={access.isHubManagement}
						handlePiperAcademy={this.handlePiperAcademy}
						handleExportReport={this.handleExportReport}
					/>
					<div className="filters">
						<div className="platform-dropdown-container">
							<DropdownCustom
								selected={this.getDropdownLabel(
									reconciliationPayoutsList?.selectedPlatform?.valueForDisplay
								)}
								isOpen={isOpen}
								handleClick={() => this.setState({ isOpen: !isOpen })}
								handleOutsideClick={() => this.setState({ isOpen: false })}
								clickEvent="mousedown"
								classes={reconciliationPayoutsList.loading ? "no-click" : ""}
							>
								<div className="dropdown-options">
									{reconciliationPayoutsList.availablePlatforms.length > 0 &&
										reconciliationPayoutsList.availablePlatforms?.map((plf, i) => (
											<div
												key={i}
												className={
													"option platform-detail " +
													(plf?.value === reconciliationPayoutsList.selectedPlatform?.value
														? "selected"
														: "")
												}
												onClick={() => this.handlePlatformChange(plf)}
											>
												<div className="logo">
													<img
														src={
															CATALOGUE_PLATFORMS_LOGO[plf?.value?.toLowerCase()] ||
															"/assets/icons/icons8-globe-40.png"
														}
														alt=""
													/>
												</div>
												<div>
													{plf.valueForDisplay && plf.valueForDisplay.length > 16
														? plf.valueForDisplay.slice(0, 16) + "..."
														: plf.valueForDisplay}
												</div>
											</div>
										))}
									{reconciliationPayoutsList.availablePlatforms.length === 0 && (
										<div className="option platform-detail no-hover">No results found</div>
									)}
								</div>
							</DropdownCustom>
						</div>
						<NewDateCompareFilter
							showDropdown={true}
							loading={reconciliationPayoutsList.loading}
							currentDateFilter={currentDateFilter}
							appliedDateFilter={appliedDateFilter}
							updateState={this.updateReconciliationPayoutsState}
							includeAllTime={true}
							hidePresetTypes={["Today", "Yesterday", "15 D"]}
						/>
						{configItems.dimensions.width > 768 && (
							<div
								className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}
							>
								<div className="container" onClick={this.flipShowFilters}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
									</div>
								</div>
							</div>
						)}
					</div>
					<CommonTable
						loading={reconciliationPayoutsList.loading}
						data={reconciliationPayoutsList.data.objects || []}
						columns={columns}
						classes={"payout-list-table-container"}
						content="Payouts"
						currencySymbol={this.props.biz.currencySymbol}
						sortList={this.sortList}
						sortedField={sortedField}
						setReconStatusInfo={this.setReconStatusInfo}
						reconStatusInfo={reconStatusInfo}
						handleMouseEnter={this.handleMouseEnter}
						handleMouseLeave={this.handleMouseLeave}
						savePayoutStatuses={this.savePayoutStatuses}
						showPlaceholder
						placeholderContent={placeholderContent}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={reconciliationPayoutsList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
					<ExportReport
						isOpen={isExportReportsOpen}
						close={this.handleExportReport}
						availablePlatforms={reconciliationPayoutsList.availablePlatforms}
						selectedPlatform={reconciliationPayoutsList.selectedPlatform}
					/>
					<NestedEntityContainer
						show={this.state.nestedEntity.show}
						type={this.state.nestedEntity.type}
						id={this.state.nestedEntity.id}
						showCourses={this.state.nestedEntity.showCourses}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
						isNested={false}
						isForeignSource={true}
					/>
				</div>
				{
					<HoverContentDisplayGrid
						show={reconStatusInfo.show}
						position={reconStatusInfo.position}
						classes={"recon-status-messages-container"}
					>
						<div className="reconciled">Reconciled: {reconStatusInfo.content.reconciled}</div>
						<div className="missing">Missing in Payout: {reconStatusInfo.content.missing}</div>
						<div className="inconsistent">Inconsistent: {reconStatusInfo.content.inconsistent}</div>
						<div className="resolved">
							Manually Reconciled: {reconStatusInfo.content.manuallyReconciled}
						</div>
						<div className="open">Missing in Atlas: {reconStatusInfo.content.missingInAtlas}</div>
					</HoverContentDisplayGrid>
				}
			</React.Fragment>
		);
	}
}
