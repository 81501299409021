import React, { useState, useCallback } from "react";

// third party
import moment from "moment";
import Lottie from "lottie-react";

// component
import { Modal } from "./Modal";
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";

// utils
import { store } from "../../store/configureStore";
import { trackEvent } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";
import { archiveRestoreLocation } from "../../actions/locations";
import { archiveRestoreCategory } from "../../actions/categories";
import { archiveRestoreItem } from "../../actions/items";
import { archiveRestoreModifierGroup } from "../../actions/modifierGroups";
import { archiveRestoreModifier } from "../../actions/modifiers";
import { archiveRestoreTax } from "../../actions/taxes";
import { archiveRestoreCharge } from "../../actions/charges";
import { archiveRestoreHolidayHour, archiveRestoreTimingGroup } from "../../actions/timingGroups";
import { deleteItemGroup } from "../../actions/itemGroups";
import { deleteLocationGroup } from "../../actions/locationGroups";
import { archiveRestoreTagGroup, archiveRestoreItemTag } from "../../actions/itemTags";
import { archiveRestoreDiscount } from "../../actions/discounts";
import { archiveRestoreCoupon } from "../../actions/coupons";
import { archiveRestoreWebhook } from "../../actions/webhooks";
import { deleteGallery, editGalleryDetail } from "../../actions/gallery";
import { archiveRestoreUser } from "../../actions/users";
import { archiveRestoreUnifiedUser } from "../../actions/unifiedUsers";

// lottie
import arrowRightAnimation from "../../lottie/arrowRight.json";

// constant
import { TRACKING_EVENT_NAMES, TRACKING_ACTION, TRACKING_STATUS } from "../../client-config";
export const CATALOGUE_ENTITY_TYPES = [
	"Location",
	"Category",
	"Item",
	"Modifier Group",
	"Modifier",
	"Tax",
	"Charge",
	"Item Group",
	"Location Group",
	"Timing Group",
	"Tag Group",
	"Tag",
	"Discount",
	"Coupon",
	"Webhook",
	"Gallery",
	"User",
	"Unified User",
	"LocationList",
	"HolidayHour"
];

export const ArchiveRestore = ({
	isOpen,
	close,
	mode = "archive",
	entity = "",
	entityName = "",
	entityType,
	fieldName = "isActive",
	object = {},
	data = [],
	isNested = true,
	brand = {},
	fromListView = false,
	showInModal = false,
	sourceOfTrigger = null,
	overlaySidebarClasses = ""
}) => {
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [confirmInput, setConfirmInput] = useState("");
	const [status, setStatus] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const HANDLER_FUNCS = {
		[CATALOGUE_ENTITY_TYPES[0]]: archiveRestoreLocation,
		[CATALOGUE_ENTITY_TYPES[1]]: archiveRestoreCategory,
		[CATALOGUE_ENTITY_TYPES[2]]: archiveRestoreItem,
		[CATALOGUE_ENTITY_TYPES[3]]: archiveRestoreModifierGroup,
		[CATALOGUE_ENTITY_TYPES[4]]: archiveRestoreModifier,
		[CATALOGUE_ENTITY_TYPES[5]]: archiveRestoreTax,
		[CATALOGUE_ENTITY_TYPES[6]]: archiveRestoreCharge,
		[CATALOGUE_ENTITY_TYPES[7]]: deleteItemGroup,
		[CATALOGUE_ENTITY_TYPES[8]]: deleteLocationGroup,
		[CATALOGUE_ENTITY_TYPES[9]]: archiveRestoreTimingGroup,
		[CATALOGUE_ENTITY_TYPES[10]]: archiveRestoreTagGroup,
		[CATALOGUE_ENTITY_TYPES[11]]: archiveRestoreItemTag,
		[CATALOGUE_ENTITY_TYPES[12]]: archiveRestoreDiscount,
		[CATALOGUE_ENTITY_TYPES[13]]: archiveRestoreCoupon,
		[CATALOGUE_ENTITY_TYPES[14]]: archiveRestoreWebhook,
		[CATALOGUE_ENTITY_TYPES[15]]: mode === "replace" ? editGalleryDetail : deleteGallery,
		[CATALOGUE_ENTITY_TYPES[16]]: archiveRestoreUser,
		[CATALOGUE_ENTITY_TYPES[17]]: archiveRestoreUnifiedUser,
		[CATALOGUE_ENTITY_TYPES[19]]: archiveRestoreHolidayHour
	};

	const handleClose = useCallback(
		(success = false) => {
			setConfirmInput("");
			setStatus(false);
			setIsSuccess(false);
			setConfirmLoading(false);
			close(isSuccess ? true : success);
		},
		[close, isSuccess]
	);

	const handleSubmit = useCallback(async () => {
		setConfirmLoading(true);
		let success;
		let entityData;
		try {
			if (fromListView) {
				entityData = {
					data: [...data],
					[fieldName]: mode === "delete" ? true : mode === "archive" ? false : true
				};
				success = await HANDLER_FUNCS[entityType](entityData, fromListView, brand);
			} else {
				entityData = {
					...object,
					[fieldName]: mode === "delete" ? true : mode === "archive" ? false : true
				};
				success = await HANDLER_FUNCS[entityType](entityData, fromListView);
			}
			if (success) {
				if (showInModal) {
					setIsSuccess(true);
					setConfirmLoading(false);
				} else {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: `"${entityName}" ${
								mode === "replace"
									? "Replaced"
									: mode === "delete"
									? "Deleted"
									: mode === "archive"
									? "Archived"
									: mode === "reactivate"
									? "Reactivated"
									: "Restored"
							}`,
							timeout: 2000,
							error: false
						}
					});
					setConfirmLoading(false);
					handleClose(true);
				}
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		// track event
		const eventName = TRACKING_EVENT_NAMES.ARCHIVE_RESTORE;
		const eventMeta = {
			action: entityData.isActive ? TRACKING_ACTION.RESTORE : TRACKING_ACTION.ARCHIVE,
			entity: entityType.toLowerCase(),
			num_of_entities: data.length || 1,
			source: sourceOfTrigger,
			status: success ? TRACKING_STATUS.SUCCESS : TRACKING_STATUS.FAILURE
		};
		trackEvent(eventName, eventMeta);

		setConfirmLoading(false);
	}, [mode, object, data, entityName, entityType, fieldName, handleClose, fromListView, showInModal]);

	const renderMessageArchive = () => {
		switch (entityType) {
			case CATALOGUE_ENTITY_TYPES[0]:
				let itemsCount = 0;
				let brandsCount = 0;
				data.forEach((loc) => {
					itemsCount += loc.ilaCount;
					brandsCount += loc.associatedBrandCount;
				});
				return (
					<React.Fragment>
						{fromListView ? (
							<div className="message-item-container">
								{data.length > 1 ? (
									<div className="sub-header">
										<span>
											{data.length} selected locations are currently associated with{" "}
											<span>{brandsCount}</span> Brand(s).
										</span>
									</div>
								) : (
									<div className="sub-header">
										<span>"{data[0]?.name || "Selected location"}"</span> is currently associated
										with <span>{data[0]?.associatedBrandCount || brandsCount || 0} Brand(s).</span>
									</div>
								)}
								{data.length > 1 && itemsCount > 0 ? (
									<div>
										They are also associated with <span>{itemsCount} Item(s).</span>
									</div>
								) : itemsCount > 0 ? (
									<div>
										It is also associated with{" "}
										<span>{data[0]?.ilaCount || itemsCount || 0} Item(s).</span>
									</div>
								) : null}
								{data.length > 1 ? (
									<div className="disclaimer">
										If you archive these {data.length} locations, they will not be part of customer
										facing catalogue on your apps and website.
									</div>
								) : (
									<div className="disclaimer">
										If you archive this location, it will not be part of customer facing catalogue
										on your apps and website.
									</div>
								)}
							</div>
						) : (
							<div className="message-item-container">
								<div>
									<span>"{entityName}"</span> is associated with <span>{object.ilaCount} Items</span>
								</div>
								<ul>
									<li>
										<span>"{entityName}"</span> will not be part of customer facing catalogue on
										your apps and website.
									</li>
								</ul>
							</div>
						)}
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[1]:
				if (object.paginatedItems && object.paginatedItems.count > 0) {
					return (
						<React.Fragment>
							<div className="message-item-container">
								<div>
									<span>"{entityName}"</span> contains {object.paginatedItems.count} Items
								</div>
								<ul>
									<li>
										These {object.paginatedItems.count} Items will become category less and hence
										will not be part of catalog on your apps and website.
									</li>
									<li>
										After you publish the catalog, same effect will take place on aggregator
										platforms.
									</li>
									<li>You can either re-assign these items to another category or archive them.</li>
									<li>
										In future, if you restore <span>"{entityName}"</span> you can choose to restore
										associations with these n items, unless any of these items were also archived or
										reassigned to another category.
									</li>
								</ul>
							</div>
						</React.Fragment>
					);
				}
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> contains{" "}
								{object.subcategories ? object.subcategories.length : 0} subcategories
							</div>
							<ul>
								<li>
									These {object.subcategories ? object.subcategories.length : 0} subcategories will
									become root level categories.
								</li>
								<li>
									Changes will take effect on your apps and website right away. After you publish the
									catalog, they will reflect on aggregator platforms.
								</li>
								<li>
									You can leave them to stay at the root level or assign another parent category to
									them.
								</li>
								<li>You can also archive them in-turn.</li>
							</ul>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[2]:
				return (
					<React.Fragment>
						{object.locations && (
							<div className="message-item-container">
								<div>
									<span>1. "{entityName}"</span> is associated with{" "}
									<span>{object.locations.count} Locations</span>
								</div>
								{/* <ul>
								<li>
									<span>"{entityName}"</span> will not be part of customer facing catalog on your apps and website.
								</li>
								<li>
									After you publish the catalog  <span>"{entityName}"</span> will be stocked out at all the locations right away and also not be part of your catalog on aggregator platforms.
								</li>
								<li>
									In the future, if you restore <span>"{entityName}"</span> its associations with these <span>{object.locations.count} Locations</span> will also be restored, unless any of these locations were also archived.
								</li>
							</ul> */}
							</div>
						)}
						{object.optionGroups && (
							<div className="message-item-container">
								<div>
									<span>2. "{entityName}"</span> is associated with{" "}
									<span>{object.optionGroups.count} Modifier groups</span>
								</div>
								{/* <ul>
								<li>
									If these modifier groups are associated with any other item on your catalog, those associations will remain untouched.
								</li>
								<li>
									In future, if you restore <span>"{entityName}"</span> you can choose to restore associations with these <span>{object.optionGroups.count} Modifier groups</span>, unless any of these modifier groups were also archived.
								</li>
							</ul> */}
							</div>
						)}
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[3]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1. "{entityName}"</span> is associated with <span>{object.itemsCount} Items</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will not be displayed in conjunction with these <span>{object.itemsCount} items</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog <span>"{entityName}"</span> will not be displayed in conjunction with these <span>{object.itemsCount} items</span> on aggregator platforms.
								</li>
								<li>
									In future, if you restore <span>"{entityName}"</span> you can choose to restore the associations with these <span>{object.itemsCount} items</span>, unless any of these items were also archived.
								</li>
							</ul> */}
						</div>
						<div className="message-item-container">
							<div>
								<span>2. "{entityName}"</span> is associated with{" "}
								<span>{object.modifiersCount} Modifiers</span>
							</div>
							{/* <ul>
								<li>
									If these <span>{object.modifiersCount} modifiers</span> are associated with any other modifier group on your catalog, those associations will remain untouched.
								</li>
								<li>
									In future, if you restore <span>"{entityName}"</span> you can choose to restore the associations with these <span>{object.modifiersCount} modifiers</span>, unless any of these modifiers were also archived.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[4]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1. "{entityName}"</span> is associated with{" "}
								<span>{object.modifierGroupsCount} Modifier groups</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will not be displayed in conjunction with these <span>{object.modifierGroupsCount} modifier groups</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog  same effect will take place on aggregator platforms
								</li>
								<li>
									In future, if you restore <span>"{entityName}"</span> you can choose to restore the associations with these <span>{object.modifierGroupsCount} modifier groups</span>, unless any of these modifiers were also archived.
								</li>
							</ul> */}
						</div>
						<div className="message-item-container">
							<div>
								<span>2. "{entityName}"</span> is associated with{" "}
								<span>{object.numAssociatedLocations} Locations</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span>  will be stocked out at all locations on your apps and website
								</li>
								<li>
									After you publish the catalog  same effect will take place on aggregator platforms
								</li>
								<li>
									In future, if you restore <span>"{entityName}"</span> you can choose to restore the customisations with these <span>{object.numAssociatedLocations} locations</span>, unless any of these locations were also archived.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[5]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>{object.ilgps ? object.ilgps.length : 0} Item & Location group pairs</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will not be applied on any of your items, through out all locations on your apps and website.
								</li>
								<li>
									After you publish the catalog  same effect will take place on aggregator platforms
								</li>
								<li>
									In future, if you restore <span>"{entityName}"</span>, you can choose to restore the associations with these <span>{object.ilgps ? object.ilgps.length : 0} item & location groups</span>, unless any of these groups were also archived.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[6]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>{object.ilgps ? object.ilgps.length : 0} Item & Location group pairs</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will not be applied on any of your items, through out all locations on your apps and website.
								</li>
								<li>
									After you publish the catalog  same effect will take place on aggregator platforms
								</li>
								<li>
									In future, if you restore <span>"{entityName}"</span>, you can choose to restore the associations with these <span>{object.ilgps ? object.ilgps.length : 0} item & location groups</span>, unless any of these groups were also archived.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[7]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1. "{entityName}"</span> is associated with{" "}
								<span>
									{object.numAssociatedTaxes || 0} Taxes and {object.numAssociatedCharges || 0}{" "}
									Charges
								</span>
								.
							</div>
						</div>
						<div className="message-item-container">
							<div>
								<span>2. "{entityName}"</span> is associated with{" "}
								<span>{object.items ? object.items.count : 0} Items</span>.
							</div>
						</div>
						<div className="message-item-container">
							<div>
								<span>3. </span>This action is irreversible.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[8]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1. "{entityName}"</span> is associated with{" "}
								<span>
									{object.numAssociatedTaxes || 0} Taxes and {object.numAssociatedCharges || 0}{" "}
									Charges
								</span>
								.
							</div>
						</div>
						<div className="message-item-container">
							<div>
								<span>2. "{entityName}"</span> is associated with{" "}
								<span>{object.locations ? object.locations.count : 0} Locations</span>.
							</div>
						</div>
						<div className="message-item-container">
							<div>
								<span>3. </span>This action is irreversible.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[9]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1.</span> Any Store schedules or Ordering slots that you may have configured using
								this schedule, will now default to entire day.
							</div>
						</div>
						<div className="message-item-container">
							<div>
								<span>2.</span> Any Category schedule that you may have configured using this schedule,
								will now default to store schedule.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[10]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>{object.tags ? object.tags.count : 0} tags</span>.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[11]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with <span>{object.numItems || 0} Items</span>
								.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[12]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>
									{object.items ? object.items.count : 0} Items at{" "}
									{object.locations ? object.locations.count : 0} Locations
								</span>
								.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[13]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> will not be listed and usable for your customer.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[14]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> will not be listed and usable for your customer.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[15]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1. </span>This action is irreversible.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[16]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> will be archived !!
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[17]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<ul>
									<li>
										Archived users will not be able to perform any of their previously assigned
										roles or log into their UrbanPiper account.
									</li>
									<li>You can reactivate their account anytime.</li>
								</ul>
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[19]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<ul>
									<li>
										This schedule will be disassociated from all stores currently associated with
										it.
									</li>
								</ul>
							</div>
						</div>
					</React.Fragment>
				);
			default:
				break;
		}
		return null;
	};

	const renderMessageRestore = () => {
		switch (entityType) {
			case CATALOGUE_ENTITY_TYPES[0]:
				let itemsCount = 0;
				let brandsCount = 0;
				data.forEach((loc) => {
					itemsCount += loc.ilaCount;
					brandsCount += loc.associatedBrandCount;
				});
				return (
					<React.Fragment>
						{fromListView ? (
							<div className="message-item-container">
								{data.length > 1 ? (
									<div className="sub-header">
										<span>
											{data.length} selected locations are currently associated with{" "}
											<span>{brandsCount}</span> Brand(s).
										</span>
									</div>
								) : (
									<div className="sub-header">
										<span>"{data[0]?.name || "Selected location"}"</span> is currently associated
										with <span>{data[0]?.associatedBrandCount || brandsCount || 0} Brand(s).</span>
									</div>
								)}
								{data.length > 1 && itemsCount > 0 ? (
									<div>
										They are also associated with <span>{itemsCount} Item(s).</span>
									</div>
								) : itemsCount > 0 ? (
									<div>
										It is also associated with{" "}
										<span>{data[0]?.ilaCount || itemsCount || 0} Item(s).</span>
									</div>
								) : null}
								{data.length > 1 ? (
									<div className="disclaimer">
										If you restore these {data.length} locations, they will be part of customer
										facing catalogue on your apps and website.
									</div>
								) : (
									<div className="disclaimer">
										If you restore this location, it will be part of customer facing catalogue on
										your apps and website.
									</div>
								)}
							</div>
						) : (
							<div className="message-item-container">
								<div>
									<span>"{entityName}"</span> is associated with <span>{object.ilaCount} Items</span>
								</div>
								<ul>
									<li>
										If you wish to update associations of <span>"{entityName}"</span> with these{" "}
										{object.ilaCount} items before restoration, click cancel. Open "Locations" tab
										and update the list of locations. Then click "Restore" again.
									</li>
									<li>
										After you publish the catalog, same effect will take place on aggregator
										platforms.
									</li>
								</ul>
							</div>
						)}
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[1]:
				if (object.paginatedItems && object.paginatedItems.count > 0) {
					return (
						<React.Fragment>
							<div className="message-item-container">
								<div>
									<span>"{entityName}"</span> contains {object.paginatedItems.count} Items
								</div>
								<ul>
									<li>
										These {object.paginatedItems.count} Items will become part of the catalog, under{" "}
										<span>"{entityName}"</span> in your apps and website.
									</li>
									<li>
										After you publish the catalog, same effect will take place on aggregator
										platforms.
									</li>
									<li>
										If you wish to update these items in <span>"{entityName}"</span> before
										restoration, click cancel. Open "Associated items" tab and update the list of
										items. Then proceed with "Restore" again.
									</li>
									<li>
										Make sure to mark these items as stocked in, in order for your customers to
										place orders
									</li>
								</ul>
							</div>
						</React.Fragment>
					);
				}
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> Does not contain any Item or Subcategory
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[2]:
				return (
					<React.Fragment>
						{object.locations && (
							<div className="message-item-container">
								<div>
									<span>1. "{entityName}"</span> is associated with{" "}
									<span>{object.locations.count} Locations</span>
								</div>
								{/* <ul>
								<li>
									On your apps and website <span>"{entityName}"</span> will be part of your catalog at these <span>{object.locations.count} Locations</span>
								</li>
								<li>
									After you publish the catalog, same effect will take place on aggregator platforms.
								</li>
								<li>
									If you wish to update associations of <span>"{entityName}"</span> with these <span>{object.locations.count} Locations</span> before restoration, click cancel. Open "Locations" tab and update the list of locations. Then click "Restore" again.
								</li>
								<li>
									Make sure to mark these items as stocked in, in order for your customers to place orders.
								</li>
							</ul> */}
							</div>
						)}
						{object.optionGroups && (
							<div className="message-item-container">
								<div>
									<span>2. "{entityName}"</span> is associated with{" "}
									<span>{object.optionGroups.count} Modifier groups.</span>
								</div>
								{/* <ul>
								<li>
									These <span>{object.optionGroups.count} Modifier groups</span> will be displayed in conjunction with <span>"{entityName}"</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog, same effect will take place on aggregator platforms.
								</li>
								<li>
									If you wish to update associations of <span>"{entityName}"</span> with these <span>{object.optionGroups.count} Modifier groups</span> before restoration, click cancel. Open "Locations" tab and update the list of Modifier groups. Then click "Restore" again.
								</li>
							</ul> */}
							</div>
						)}
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[3]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1. "{entityName}"</span> is associated with <span>{object.itemsCount} Items</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will be displayed in conjunction with these <span>{object.itemsCount} items</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog same effect will take place on aggregator platforms.
								</li>
								<li>
									If you wish to update associations of <span>"{entityName}"</span> with these <span>{object.itemsCount} items</span>, before restoration, close this screen. Open "Associated items" tab and update the list of items. Then click "Restore" again.
								</li>
							</ul> */}
						</div>
						<div className="message-item-container">
							<div>
								<span>2. "{entityName}"</span> is associated with{" "}
								<span>{object.modifiersCount} Modifiers</span>
							</div>
							{/* <ul>
								<li>
									These <span>{object.modifiersCount} modifiers</span> will be displayed in conjunction with <span>"{entityName}"</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog same effect will take place on aggregator platforms.
								</li>
								<li>
									If you wish to update associations of <span>"{entityName}"</span> with these <span>{object.modifiersCount} modifiers</span>, before restoration, close this screen. Open "modifiers" tab and update the list of modifiers. Then click "Restore" again.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[4]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>1. "{entityName}"</span> is associated with{" "}
								<span>{object.modifierGroupsCount} Modifier groups</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will be displayed in conjunction with these <span>{object.modifierGroupsCount} modifier groups</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog same effect will take place on aggregator platforms.
								</li>
								<li>
									If you wish to update associations of <span>"{entityName}"</span> with these <span>{object.modifierGroupsCount} modifier groups</span>, before restoration, close this screen. Open "Modifier groups" tab and update the list of modifiers. Then click "Restore" again.
								</li>
							</ul> */}
						</div>
						<div className="message-item-container">
							<div>
								<span>2. "{entityName}"</span> is associated with{" "}
								<span>{object.numAssociatedLocations} Locations</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> is customised/associated for these <span>{object.numAssociatedLocations} locations</span>, and displayed as such on your apps and website.
								</li>
								<li>
									After you publish the catalog same effect will take place on aggregator platforms.
								</li>
								<li>
									If you wish to update associations/customizations of <span>"{entityName}"</span> with these <span>{object.numAssociatedLocations} locations</span>, before restoration, close this screen. Open "locations" tab and update the list of locations. Then click "Restore" again.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[5]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>{object.ilgps ? object.ilgps.length : 0} Item & Location group pairs</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will be applied based on these <span>{object.ilgps ? object.ilgps.length : 0} item & location groups</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog  same effect will take place on aggregator platforms
								</li>
								<li>
									If you wish to update associations of <span>"{entityName}"</span> with these these <span>{object.ilgps ? object.ilgps.length : 0} item & location groups</span>, before restoration, close this screen. Open "Items & locations" tab and update the list of group pairs. Then click "Restore" again.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[6]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>{object.ilgps ? object.ilgps.length : 0} Item & Location group pairs</span>
							</div>
							{/* <ul>
								<li>
									<span>"{entityName}"</span> will be applied based on these <span>{object.ilgps ? object.ilgps.length : 0} item & location groups</span> on your apps and website.
								</li>
								<li>
									After you publish the catalog  same effect will take place on aggregator platforms
								</li>
								<li>
									If you wish to update associations of <span>"{entityName}"</span> with these these <span>{object.ilgps ? object.ilgps.length : 0} item & location groups</span>, before restoration, close this screen. Open "Items & locations" tab and update the list of group pairs. Then click "Restore" again.
								</li>
							</ul> */}
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[9]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								You can now start to configure store timings, ordering timings, or category timings
								using this schedule.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[10]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>{object.tags ? object.tags.count : 0} tags</span>.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[11]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with <span>{object.numItems || 0} Items</span>
								.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[12]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> is associated with{" "}
								<span>
									{object.items ? object.items.count : 0} Items at{" "}
									{object.locations ? object.locations.count : 0} Locations
								</span>
								.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[13]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> will now be listed and usable for your customer.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[14]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> will now be listed and usable for your customer.
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[16]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<span>"{entityName}"</span> will be made active !!
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[17]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<ul>
									<li>
										Reactivating this user will give them access to all old permissions and roles.
										They will receive an email notification for reactivation.
									</li>
									<li>Changes to role and permissions can be made post reactivation.</li>
								</ul>
							</div>
						</div>
					</React.Fragment>
				);
			case CATALOGUE_ENTITY_TYPES[19]:
				return (
					<React.Fragment>
						<div className="message-item-container">
							<div>
								<ul>
									<li>
										You can now associate stores with this schedule and use it for configuring
										holiday timings.
									</li>
								</ul>
							</div>
						</div>
					</React.Fragment>
				);
			default:
				break;
		}
		return null;
	};

	const renderMessage = useCallback(() => {
		return mode === "delete"
			? renderMessageArchive()
			: mode === "archive"
			? renderMessageArchive()
			: renderMessageRestore();
	}, [mode, renderMessageArchive, renderMessageRestore]);

	const handleCheck = () => {
		setStatus(!status);
	};

	const renderAlertMessage = () => {
		if (mode === "replace") {
			return (
				<div className="alert">
					<img src="/assets/icons/alert.svg" />
					<span>
						<strong>Before you replace this image, please consider:</strong>
						<ul>
							<li>
								The image is associated with <strong>{object?.imgType.length || "0"}</strong> type(s).
							</li>
							{object?.supportedLanguages && object.supportedLanguages.length > 1 && (
								<li>
									The image is associated with{" "}
									<strong>{object?.supportedLanguages.length || "0"}</strong> language(s).
								</li>
							)}
						</ul>
					</span>
				</div>
			);
		}

		if (!showInModal) {
			return (
				<div className="alert">
					<img src="/assets/icons/alert.svg" />
					<span>Unexpected things can happen if you don't read this!</span>
				</div>
			);
		}

		return null;
	};

	return (
		<div className={"archive-restore-container" + (showInModal ? " in-modal" : "")}>
			{showInModal ? (
				<Modal
					isOpen={isOpen}
					title={mode === "archive" ? `Archive ${entityType}?` : `Restore ${entityType}?`}
					showCancelAction
					cancelTitle={isSuccess ? "Close" : "Cancel"}
					close={handleClose}
					showSubmitAction={mode === "restore" && !isSuccess}
					submitTitle="Restore"
					submitAction={handleSubmit}
					submitClass={"at-btn--success"}
					showDeleteAction={mode === "archive" && !isSuccess}
					deleteTitle="Archive"
					deleteAction={handleSubmit}
					disabled={!status || confirmLoading}
					loading={confirmLoading}
				>
					<div className="form-content">
						{!confirmLoading ? renderAlertMessage() : null}
						{mode !== "replace" && !confirmLoading && !isSuccess && !fromListView && (
							<div className="entity-title">
								Before you {mode} <span>"{entityName}"</span> please consider:
							</div>
						)}
						{!confirmLoading && !isSuccess ? renderMessage() : null}
						{/* <div className="text-input-message">
							Please type <span>"{entityName}"</span> to confirm:
						</div>
						<InputWithLabel
							value={confirmInput}
							onChange={(e) => setConfirmInput(e.target.value)}
							onPaste={(e) => e.preventDefault()}
							tabIndex={!isOpen ? '-1' : ''}
						/> */}
						{!confirmLoading && !isSuccess && (
							<CheckBox checked={status} clickHandler={handleCheck} tabIndex={!isOpen ? "-1" : ""}>
								{fromListView ? (
									<span className="input-message">
										Proceed with{" "}
										{mode === "delete"
											? "deleting"
											: mode === "archive"
											? `archiving `
											: mode === "replace"
											? `replacing `
											: `restoring `}{" "}
										{data.length > 1 ? (
											`${data.length} selected locations`
										) : (
											<span>"{data[0]?.name || "selected location"}"</span>
										)}
									</span>
								) : (
									<span className="input-message">
										Proceed with{" "}
										{mode === "delete"
											? "deleting"
											: mode === "archive"
											? `archiving `
											: mode === "replace"
											? `replacing `
											: `restoring `}{" "}
										<span>"{entityName}"</span>
									</span>
								)}
							</CheckBox>
						)}
						{isSuccess && (
							<div className="success-message">
								Successfully{" "}
								{mode === "replace"
									? "replaced"
									: mode === "delete"
									? "deleted"
									: mode === "archive"
									? "archived"
									: "restored"}{" "}
								selected {entity}
							</div>
						)}
					</div>
				</Modal>
			) : (
				<FormSidebar
					isOpen={isOpen}
					close={handleClose}
					submit={handleSubmit}
					title={mode === "replace" ? <span>Replace {entityName || "--"}</span> : "Read this Carefully!"}
					subTitle={
						mode === "replace" && (
							<span>Image uploaded on {moment(object?.updated).format("DD MMM, YYYY - hh:mm A")}</span>
						)
					}
					submitTitle={
						mode === "replace"
							? "Replace"
							: mode === "delete"
							? "Delete"
							: mode === "archive"
							? "Archive"
							: mode === "reactivate"
							? "Reactivate"
							: "Restore"
					}
					submitClass={
						mode === "delete"
							? "at-btn--danger"
							: mode === "archive"
							? "at-btn--danger"
							: mode === "reactivate"
							? ""
							: "at-btn--success"
					}
					loading={confirmLoading}
					isNested={isNested}
					disabled={!status}
					overlaySidebarClasses={overlaySidebarClasses}
				>
					<div className="form-content">
						{renderAlertMessage()}
						{mode !== "replace" && (
							<div className="entity-title">
								Before you {mode} <span>"{entityName}"</span> please consider:
							</div>
						)}
						{renderMessage()}
						{/* <div className="text-input-message">
							Please type <span>"{entityName}"</span> to confirm:
						</div>
						<InputWithLabel
							value={confirmInput}
							onChange={(e) => setConfirmInput(e.target.value)}
							onPaste={(e) => e.preventDefault()}
							tabIndex={!isOpen ? '-1' : ''}
						/> */}
						<CheckBox checked={status} clickHandler={handleCheck} tabIndex={!isOpen ? "-1" : ""}>
							<span className="input-message">
								Proceed with{" "}
								{mode === "delete"
									? "deleting"
									: mode === "archive"
									? `archiving `
									: mode === "replace"
									? `replacing `
									: mode === "reactivate"
									? `reactivating`
									: `restoring `}{" "}
								<span>"{entityName}"</span>
							</span>
						</CheckBox>
						{mode === "replace" && (
							<div className="replace-img-container">
								<Lottie
									animationData={arrowRightAnimation}
									style={{
										position: "absolute",
										height: "80px",
										width: "100px",
										top: "40px",
										left: "260px",
										transform: "rotate(-200deg)"
									}}
									loop={false}
								/>
								<img src={object?.image} className="compare-img" alt="Current image" />
								<img
									src={URL.createObjectURL(object?.replacementImageContent?.file)}
									className="compare-img"
									alt="New image"
								/>
							</div>
						)}
					</div>
				</FormSidebar>
			)}
		</div>
	);
};
