import gql from "graphql-tag";

export const GET_TAG_GROUPS_LIST = gql`
	query fetchTagGroups($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		itemTagGroups(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			objects {
				id
				title
				isSystem
				isEnabled
			}
			count
			hasPrevious
			hasNext
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const GET_ITEM_TAGS_LIST = gql`
	query getItemTags($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput, $tagGroup: Int) {
		itemTags(limit: $limit, offset: $offset, filters: $filters, sort: $sort, tagGroup: $tagGroup) {
			objects {
				id
				title
				numItems
				numOptions
				crmTitle
			}
			count
			hasPrevious
			hasNext
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const CREATE_TAG_GROUP = gql`
	mutation createTagGroup($title: String!, $crmTitle: String, $isSearchable: Boolean, $isFilterable: Boolean) {
		saveItemTagGroup(
			input: { title: $title, crmTitle: $crmTitle, isSearchable: $isSearchable, isFilterable: $isFilterable }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				isSystem
				isEnabled
			}
		}
	}
`;

export const GET_TAG_GROUP = gql`
	query fetchTagGroup($id: Int) {
		itemTagGroup(id: $id) {
			id
			title
			crmTitle
			isFilterable
			isSearchable
			isSystem
			isEnabled
			tags {
				count
			}
		}
	}
`;

export const EDIT_TAG_GROUP = gql`
	mutation editTagGroup(
		$id: Int
		$title: String!
		$crmTitle: String
		$isSearchable: Boolean
		$isFilterable: Boolean
		$isEnabled: Boolean
	) {
		saveItemTagGroup(
			input: {
				id: $id
				title: $title
				crmTitle: $crmTitle
				isSearchable: $isSearchable
				isFilterable: $isFilterable
				isEnabled: $isEnabled
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				isSystem
				isEnabled
			}
		}
	}
`;

export const GET_TAG_GROUP_ITEM_TAGS = gql`
	query getTagGroupItemTags($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		itemTagGroup(id: $id) {
			id
			tags(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					title
					crmTitle
					numItems
					numOptions
				}
			}
		}
	}
`;

export const CREATE_ITEM_TAG = gql`
	mutation createItemTag(
		$title: String!
		$crmTitle: String
		$tagGroup: Int
		$isSearchable: Boolean
		$isFilterable: Boolean
	) {
		saveItemTag(
			input: {
				title: $title
				crmTitle: $crmTitle
				tagGroup: $tagGroup
				isSearchable: $isSearchable
				isFilterable: $isFilterable
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				numItems
				numOptions
				crmTitle
			}
		}
	}
`;

export const GET_ITEM_TAG = gql`
	query fetchItemTag($id: Int) {
		itemTag(id: $id) {
			id
			title
			crmTitle
			tagGroup {
				id
				title
				isSystem
				isEnabled
			}
			isFilterable
			isSearchable
			isSystem
			isEnabled
			numItems
			numOptions
		}
	}
`;

export const EDIT_ITEM_TAG = gql`
	mutation editItemTag(
		$id: Int
		$title: String!
		$crmTitle: String
		$tagGroup: Int
		$isSearchable: Boolean
		$isFilterable: Boolean
		$isEnabled: Boolean
	) {
		saveItemTag(
			input: {
				id: $id
				title: $title
				crmTitle: $crmTitle
				tagGroup: $tagGroup
				isSearchable: $isSearchable
				isFilterable: $isFilterable
				isEnabled: $isEnabled
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				numItems
				numOptions
				crmTitle
			}
		}
	}
`;

export const GET_ITEM_TAG_ITEMS = gql`
	query getItemTagItems($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		itemTag(id: $id) {
			id
			items(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					itemTitle
					itemPrice
					crmTitle
					category
					foodType
					tags
				}
			}
		}
	}
`;

export const GET_ITEM_TAG_MODIFIERS = gql`
	query getItemTagModifiers($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		itemTag(id: $id) {
			id
			options(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					title
					price
					foodType
					crmTitle
				}
			}
		}
	}
`;
