import React from "react";

export const DownArrow = ({ expanded, hover = true, color = "white", classes = "", handleClick }) => {
	const borderStyle = {
		borderColor: color
	};
	return (
		<div
			className={`down-arrow-global ${!hover ? "no-hover" : ""} ${classes} ` + (expanded ? "up" : "")}
			onClick={handleClick && handleClick}
		>
			<div style={borderStyle} className="edge-1" />
			<div style={borderStyle} className="edge-2" />
		</div>
	);
};
