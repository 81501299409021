import React, { useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { CaptureLocation } from "../_commons/CaptureLocation";
import { CaptureLocationCircle } from "../_commons/CaptureLocationCircle";
import ManagePolygons from "./ManagePolygons";

// third party
import { connect } from "react-redux";

const SelfDelivery = ({ data, handleForm, setModalBusy, readOnly = true, handleSubmit, validations }) => {
	const [showLocationMap, setLocationMap] = useState(false);
	const [showCircleMap, setCircleMap] = useState(false);
	const [openPolygons, setOpenPolygons] = useState(false);
	const [enableDeliveryCharge] = useState(false);

	const handleLatLong = (location) => {
		handleForm("locLatitude", location.lat);
		handleForm("locLongitude", location.lng);
	};

	const handleRadius = (radius) => {
		handleForm("orderDeliveryRadius", radius);
	};

	const handlePolygons = (polygons) => {
		handleForm("polygons", polygons);
	};

	const getPolygonsFieldText = () => {
		if (!data.polygons || data.polygons.length === 0) {
			return "No polygons";
		} else if (data.polygons.length === 1) {
			return "1 polygon set!";
		}
		return `${data.polygons.length} polygons set!`;
	};

	const openPolygonsSidebar = () => {
		setOpenPolygons(true);
		setModalBusy(true);
	};

	const closePolygonsSidebar = (refresh = false) => {
		setOpenPolygons(false);
		setModalBusy(false);
	};

	const savePolygonsDataToServer = (polygons) => {
		handleSubmit(polygons);
	};

	return (
		<div className="self-delivery-tab">
			<div className="form-row row-half">
				<InputWithLabel
					value={data.locLatitude}
					onChange={(e) => handleForm("locLatitude", e.target.value ? Number(e.target.value) : null)}
					type="number"
					readOnly={readOnly}
				>
					Latitude
				</InputWithLabel>
				<InputWithLabel
					value={data.locLongitude}
					onChange={(e) => handleForm("locLongitude", e.target.value ? Number(e.target.value) : null)}
					type="number"
					readOnly={readOnly}
				>
					Longitude
				</InputWithLabel>
			</div>
			{!readOnly && (
				<div className="form-row-action-tooltip" onClick={() => setLocationMap(!showLocationMap)}>
					{!showLocationMap ? "Show Map" : "Close Map"}
				</div>
			)}
			{showLocationMap && (
				<CaptureLocation
					setLocation={handleLatLong}
					latLong={{
						lat: data.locLatitude,
						lng: data.locLongitude
					}}
				/>
			)}
			<div className="form-row row-half">
				<InputWithLabel
					classes="self-delivery-input"
					value={data.orderDeliveryRadius}
					onChange={(e) => handleForm("orderDeliveryRadius", e.target.value ? Number(e.target.value) : null)}
					type="number"
					tooltipInfo="Set the maximum delivery radius for this location"
					showCustomTooltip
					readOnly={readOnly}
					requiredLabel
					validationMessage={validations.orderDeliveryRadius || ""}
				>
					Max distance for delivery (in meters)
				</InputWithLabel>

				<div className="form-column">
					<InputWithLabel
						classes="self-delivery-input"
						value={getPolygonsFieldText()}
						disabled={true}
						readOnly={readOnly}
						tooltipInfo="Define delivery area using polygons. Meraki users can create multiple polygons. Each polygon can have its own delivery charge"
						showCustomTooltip
						requiredLabel
						validationMessage={validations.polygons || ""}
					>
						Polygon for delivery
					</InputWithLabel>
				</div>
			</div>
			<div className="form-row row-half form-row-mb-0">
				{!readOnly && (
					<div className="form-row-action-tooltip" onClick={() => setCircleMap(!showCircleMap)}>
						{!showCircleMap ? "Show Map" : "Close Map"}
					</div>
				)}
				{!readOnly && (
					<div className="form-row-action-tooltip" onClick={openPolygonsSidebar}>
						Manage Polygons
					</div>
				)}
			</div>

			{showCircleMap && (
				<CaptureLocationCircle
					setRadius={handleRadius}
					radius={Number(data.orderDeliveryRadius)}
					latLong={{
						lat: data.locLatitude,
						lng: data.locLongitude
					}}
				/>
			)}

			<div className="form-row row-half">
				<InputWithLabel
					classes="self-delivery-input"
					value={Math.round(data.minimumOrderDeliveryTime)}
					onChange={(e) =>
						handleForm("minimumOrderDeliveryTime", e.target.value ? parseInt(e.target.value) : null)
					}
					type="number"
					readOnly={readOnly}
					tooltipInfo="Set the minimum delivery time for this location. Overrides the business-level setting"
					showCustomTooltip
					requiredLabel
					validationMessage={validations.minimumOrderDeliveryTime || ""}
				>
					Minimum delivery time (in minutes)
				</InputWithLabel>
				<InputWithLabel
					value={Math.round(data.minimumOrderPickupTime)}
					onChange={(e) =>
						handleForm("minimumOrderPickupTime", e.target.value ? parseInt(e.target.value) : null)
					}
					type="number"
					tooltipInfo="Set the minimum time you require to prepare an order for pickup for this location. This field is required for Meraki only and overrides business level setting"
					showCustomTooltip
					readOnly={readOnly}
				>
					Minimum pickup time (in minutes)
				</InputWithLabel>
			</div>

			<div className="form-row row-half">
				<InputWithLabel
					value={data.minOrderTotal}
					onChange={(e) => handleForm("minOrderTotal", e.target.value ? Number(e.target.value) : null)}
					type="number"
					readOnly={readOnly}
					tooltipInfo="Set the minimum order value for this location. This field is required for Meraki only and overrides business level setting"
					showCustomTooltip
				>
					Minimum order value
				</InputWithLabel>
			</div>

			<ManagePolygons
				isOpen={openPolygons}
				close={closePolygonsSidebar}
				data={data}
				handlePolygons={handlePolygons}
				enableDeliveryCharge={enableDeliveryCharge}
				savePolygonsDataToServer={savePolygonsDataToServer}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	paymentModes: store.configItems.paymentModes
});
export default connect(mapStateToProps)(SelfDelivery);
