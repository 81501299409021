import React, { useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Textarea } from "../_commons/Textarea";
import { Uploader } from "../_commons/Uploader";

// third party
import { connect } from "react-redux";

// actions
import { fetchTimingGroupsDebounced } from "../../actions/actions";

const BasicInfo = ({
	data,
	handleForm,
	validations,
	timingGroups,
	parentCategories,
	image,
	handleImage,
	handleDeleteImage,
	showView = false,
	showDownload = false,
	showDelete = false,
	scrollDown,
	showLanguages = false,
	currSelectedLang = {},
	handleLanguage,
	handleTranslation,
	saveTranslation,
	readOnly = true,
	showParentField = false,
	isMenuOverCatalogueEnabled = false
}) => {
	const legalParent = parentCategories?.items.find((item) => item.id === data.parent?.id);
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<InputWithLabel
					value={
						currSelectedLang.name
							? currSelectedLang.name.lang === "en"
								? data.name
								: currSelectedLang.name.value
							: data.name
					}
					onChange={(e) =>
						showLanguages ? handleTranslation("name", e.target.value) : handleForm("name", e.target.value)
					}
					requiredLabel={!showLanguages}
					showLanguages={showLanguages}
					currSelectedLang={(currSelectedLang.name && currSelectedLang.name.lang) || ""}
					handleLanguage={(lang) => handleLanguage(lang, "name")}
					showLoading={showLanguages}
					showActionBtn={currSelectedLang.name && currSelectedLang.name.showActionBtn}
					action={() =>
						saveTranslation(
							"name",
							currSelectedLang.name
								? currSelectedLang.name.lang === "en"
									? data.name
									: currSelectedLang.name.value
								: data.name
						)
					}
					field="name"
					validationMessage={validations.name || ""}
					readOnly={readOnly}
					classes={currSelectedLang.name && currSelectedLang.name.showActionBtn ? "at-input--save" : ""}
				>
					Name
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.sortOrder}
					type="number"
					onChange={(e) => handleForm("sortOrder", e.target.value ? parseInt(e.target.value) : null)}
					requiredLabel={true}
					showCustomTooltip={true}
					tooltipInfo="Control ordering of categories as they are displayed to your customers. Values closer to zero will push the category to top while higher values will push it towards bottom"
					validationMessage={validations.sortOrder || ""}
					readOnly={readOnly}
				>
					Sort Order
				</InputWithLabel>
				{(showParentField ||
					(data !== undefined && data?.subcategories?.length === 0 && !isMenuOverCatalogueEnabled)) && (
					<SelectFilter
						title="Parent Category"
						options={parentCategories.items}
						readOnly={readOnly}
						archived={data.parent && !legalParent}
						isLoading={parentCategories.isLoading}
						field="parent"
						currValue={data.parent}
						setFilter={handleForm}
						labelKey="name"
						valueKey="id"
						showCustomTooltip={true}
						tooltipInfo="Make this category a sub category of another category by selecting it as parent (Only empty categories, or categories with other sub-categories can be selected)"
						tooltipPosition="up"
						validationMessage={validations?.parent}
					/>
				)}
			</div>
			<div className="form-row row-full">
				<Textarea
					value={
						currSelectedLang.description
							? currSelectedLang.description.lang === "en"
								? data.description
								: currSelectedLang.description.value
							: data.description
					}
					onChange={(e) =>
						showLanguages
							? handleTranslation("description", e.target.value)
							: handleForm("description", e.target.value)
					}
					showLanguages={showLanguages}
					currSelectedLang={(currSelectedLang.description && currSelectedLang.description.lang) || ""}
					handleLanguage={(lang) => handleLanguage(lang, "description")}
					showLoading={true}
					showActionBtn={currSelectedLang.description && currSelectedLang.description.showActionBtn}
					action={() =>
						saveTranslation(
							"description",
							currSelectedLang.description
								? currSelectedLang.description.lang === "en"
									? data.description
									: currSelectedLang.description.value
								: data.description
						)
					}
					field="description"
					readOnly={readOnly}
					classes={
						currSelectedLang.description && currSelectedLang.description.showActionBtn
							? "at-textarea--save"
							: ""
					}
				>
					Description
				</Textarea>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.merchantRefId === "-1" ? "" : data.merchantRefId}
					onChange={(e) => handleForm("merchantRefId", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
					validationMessage={validations.merchantRefId || ""}
					readOnly={readOnly}
				>
					POS ID
				</InputWithLabel>
				<SelectFilter
					title="Schedule association"
					options={timingGroups.items}
					readOnly={readOnly}
					isLoading={timingGroups.isLoading}
					field="timingsGroup"
					currValue={data.timingsGroup}
					setFilter={handleForm}
					labelKey="title"
					valueKey="id"
					isAsync={true}
					handleSearch={fetchTimingGroupsDebounced}
					showCustomTooltip={true}
					tooltipInfo="Time duration during which the items of this category will be available. - Eg breakfast between 7 AM to 10 AM. Leave it empty if its available through out the store timings"
					tooltipPosition="up"
				/>
			</div>
			<div className="form-row row-half">
				<Uploader
					onChange={handleImage}
					file={image}
					url={data.image}
					showView={showView}
					showDownload={showDownload}
					showDelete={showDelete}
					handleDelete={handleDeleteImage}
					classes={readOnly ? "read-only" : ""}
				>
					Image
				</Uploader>
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	timingGroups: store.configItems.timingGroups,
	parentCategories: store.configItems.parentCategories
});
export default connect(mapStateToProps)(BasicInfo);
