import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { ItemTagsList } from "./ItemTagsList";
import ItemTagGroupCreate from "./ItemTagGroupCreate";
import ItemTagGroupEdit from "./ItemTagGroupEdit";
import ItemTagCreate from "./ItemTagCreate";
import ItemTagEdit from "./ItemTagEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/ItemTagsList/Header";

const ItemTagsContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="item-tags-container">
			{restrictView ? (
				<Route path="/tags" render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />} />
			) : (
				<React.Fragment>
					<Route path="/tags" component={ItemTagsList} />
					<Route
						exact
						path="/tags/group/new"
						render={() =>
							hasAccess ? <ItemTagGroupCreate hasAccess={hasAccess} /> : <Redirect to="/tags" />
						}
					/>
					<Route exact path="/tags/group/edit/:id" component={ItemTagGroupEdit} />
					<Route
						exact
						path="/tags/tag/new"
						render={() => (hasAccess ? <ItemTagCreate hasAccess={hasAccess} /> : <Redirect to="/tags" />)}
					/>
					<Route exact path="/tags/tag/edit/:id" component={ItemTagEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default ItemTagsContainer;
