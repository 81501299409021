import React from "react";

// components
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { Parameters } from "./Parameters";

const Rules = ({ couponRules = [], orderingRules = [], handleForm, addNewRule, handleDeleteRule, loading }) => {
	return (
		<div className={"coupon-rules-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Rules for coupon</div>
					<div className="header-subtext">Create and update the rules by which the coupon will be valid</div>
				</div>
				<div>
					<Button
						classes={couponRules.length >= orderingRules.length ? "disabled" : ""}
						clickHandler={addNewRule}
					>
						Add Rule
					</Button>
				</div>
			</div>
			<div className="rules-section">
				{couponRules.map((rule, i) => (
					<div className="rule-container" key={i}>
						<div className="rule-header">
							{rule.isNewRule ? (
								<SelectFilter
									options={
										couponRules.length
											? orderingRules.filter(
													(or) =>
														couponRules.filter(
															(cr) => !cr.isNewRule && or.ruleName === cr.ruleName
														).length === 0
											  )
											: orderingRules
									}
									field="ruleName"
									currValue={rule.ruleName ? rule.ruleName : null}
									setFilter={(field, value) => handleForm(field, value, i)}
									labelKey="uiText"
									valueKey="ruleName"
								/>
							) : (
								<div className="header-text">
									{orderingRules.find((or) => rule.ruleName === or.ruleName).uiText}
								</div>
							)}
							<img
								className="delete-rule-icon"
								onClick={() => handleDeleteRule(i, rule.isNewRule ? undefined : parseInt(rule.id))}
								src="/assets/icons/delete.svg"
							/>
						</div>
						{rule.isNewRule && rule.ruleName && (
							<div className="parameters">
								<div className="form-row row-half">
									{orderingRules
										.find((or) => rule.ruleName.ruleName === or.ruleName)
										.parameters.map((param) => (
											<Parameters
												index={i}
												parameter={param}
												paramValue={rule.parameters.find((rp) => rp.paramName === param.name)}
												handleForm={handleForm}
												useCheckbox={true}
											/>
										))}
								</div>
							</div>
						)}
						{!rule.isNewRule && rule.ruleName && (
							<div className="parameters">
								<div className="form-row row-half">
									{orderingRules
										.find((or) => rule.ruleName === or.ruleName)
										.parameters.map((param) => (
											<Parameters
												index={i}
												parameter={param}
												paramValue={rule.parameters.find((rp) => rp.paramName === param.name)}
												handleForm={handleForm}
												useCheckbox={true}
											/>
										))}
								</div>
							</div>
						)}
					</div>
				))}
				{couponRules.length === 0 && !loading && <div className="no-items-placeholder">No Rules found!</div>}
				{couponRules.length === 0 && loading && (
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
			</div>
		</div>
	);
};
export default Rules;
