import React, { useState, useRef } from "react";

// components
import { Topbar } from "../../components/_commons/Topbar";
import APIAccounts from "../../containers/APIAccounts";
import APIRoles from "../../containers/APIRoles";

// utils
import { scroll } from "../../atlas-utils";

const FORM_TABS = [
	{
		label: "API accounts",
		value: "apiAccounts"
	},
	{
		label: "Roles",
		value: "roles"
	}
];

const APIAccessManagementContainer = () => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const topRef = useRef();

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	return (
		<div className="api-access-management-section section-container-common" ref={topRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">API access</div>
					<div className="subheader-text">Manage your API accounts and their roles</div>
				</div>
			</div>
			<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
			<div className="billing-container">
				{formTab === FORM_TABS[0].value && <APIAccounts />}
				{formTab === FORM_TABS[1].value && <APIRoles />}
			</div>
		</div>
	);
};
export default APIAccessManagementContainer;
