import React, { useEffect, useState } from "react";

// component
import { FormSidebar } from "../../_commons/FormSidebar";
import FormTable from "../../_commons/FormTable";
import { Paginator } from "../../_commons/Paginator";
import Placeholder from "../../_commons/Placeholder";
import { SearchFilter } from "../../_commons/SearchFilter";
import { SelectFilter } from "../../_commons/SelectFilter";

// actions
import { client } from "../../../client";
import { GET_ILPA_IDS } from "../../../graphql/brands";

const IlpaIdsViewSidebar = ({ isOpen, close, data, brandId, selectedLocation }) => {
	const limit = 10;
	const [offset, setOffset] = useState(0);
	const [ilpaData, setIlpaData] = useState({});
	const [isLoading, setLoading] = useState(false);

	const sanitiseData = (data) => {
		const currentData = {
			...data
		};

		const objectifiedContent = {};
		if (!currentData.objects) {
			return {};
		}
		currentData.objects.forEach((ilpaIds) => {
			objectifiedContent[ilpaIds?.bizLocation?.id] = {
				locationName: ilpaIds?.bizLocation?.name,
				platforms: [
					...(objectifiedContent[ilpaIds?.bizLocation?.id]?.platforms ?? []),
					{ ilpaId: ilpaIds.id, platformName: ilpaIds?.bizPlatform?.platformName }
				]
			};
		});
		return { ...currentData, objectifiedContent };
	};

	const fetchIlpaIds = async () => {
		try {
			setLoading(true);
			const variables = {};
			variables.limit = 10;
			variables.offset = 0;
			variables.brandId = parseInt(brandId);
			variables.itemId = data?.itemId;
			variables.locationId = parseInt(selectedLocation?.id);
			variables.filters = [];
			const resp = await client.query({
				query: GET_ILPA_IDS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.ilpaIds) {
				const sanitisedData = sanitiseData(resp.data.ilpaIds);
				setIlpaData(sanitisedData);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};

	useEffect(() => {
		if (isOpen) {
			fetchIlpaIds();
		}
	}, [isOpen]);

	return (
		<FormSidebar
			isOpen={isOpen}
			isNested
			hideActions
			close={close}
			title={data?.itemTitle || "Item Name"}
			subTitle="View an item's various ILPA IDs"
		>
			<div className="item-ilpa-ids">
				{/* <Filters /> */}
				{isLoading && (
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{!isLoading && <IlpaIdsViewTable tableData={ilpaData?.objectifiedContent} />}

				<Paginator />
			</div>
		</FormSidebar>
	);
};

export default IlpaIdsViewSidebar;

// const Filters = () => {
// 	return(
// 		<div className="item-ilpa-ids--filters">

// 		</div>
// 	);
// }

const IlpaIdsViewTable = ({ tableData }) => {
	if (tableData && Object.keys(tableData).length == 0) {
		return <Placeholder />;
	}
	return (
		<div className="random-table">
			<div className="table-header">
				<div className="locationName">LOCATION</div>
				<div className="platform">PLATFORM</div>
				<div className="ilpaId">ILPA ID</div>
			</div>

			{tableData &&
				Object.keys(tableData).map(
					(data) => (
						<div className="table-list">
							<div className="list-item locationName">{tableData[data].locationName}</div>
							<div className="list-item value-set-container">
								{tableData[data].platforms.map((platform) => (
									<div className="values-container">
										<div className="platform">{platform.platformName || "--"}</div>
										<div className="ilpaId">{platform.ilpaId || "--"}</div>
									</div>
								))}
							</div>
						</div>
					)
					// <div className='list-item value-set-container'>
					// 	<div className='list-item platform'>
					// 	{
					// 		tableData[data].platforms.map(platform => <div className='list-entity'>{platform.platformName}</div>)

					// 	}
					// 	</div>
					// 	<div className='list-item ilpaId'>
					// 		{
					// 			tableData[data].platforms.map(platform => <div className='list-entity'>{platform.ilpaId}</div>)
					// 		}
					// 	</div>
					// </div>
				)}
		</div>
	);
};
