export const suggestedSegmentList = (
	state = {
		loading: false,
		data: {
			objects: []
		},
		inactiveSegments: {
			objects: []
		},
		error: false,
		loadingSegmentGroups: false,
		segmentGroups: []
	},
	action
) => {
	switch (action.type) {
		case "GET_SUGG_SEGMENT_LIST_REQUEST":
			return {
				...state,
				loading: true,
				data: {
					objects: []
				},
				error: false
			};
		case "GET_SUGG_SEGMENT_LIST_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "GET_SUGG_SEGMENT_LIST_FAILURE":
			return {
				...state,
				loading: false,
				error: action.error
			};
		case "GET_INACTIVE_SEGMENT_LIST_REQUEST":
			return {
				...state,
				inactiveSegments: {
					objects: []
				},
				error: false
			};
		case "GET_INACTIVE_SEGMENT_LIST_SUCCESS":
			return {
				...state,
				inactiveSegments: action.payload
			};
		case "GET_INACTIVE_SEGMENT_LIST_FAILURE":
			return {
				...state,
				error: action.error
			};
		case "GET_SEGMENT_GROUPS_REQUEST":
			return {
				...state,
				loadingSegmentGroups: true
			};
		case "GET_SEGMENT_GROUPS_SUCCESS":
			return {
				...state,
				loadingSegmentGroups: false,
				segmentGroups: action.payload
			};
		case "GET_SEGMENT_GROUPS_FAILURE":
			return {
				...state,
				loadingSegmentGroups: false
			};
		default:
			return state;
	}
};

const CREATE_SEGMENT_INITIAL = {
	id: undefined,
	title: "",
	description: "",
	isImplicit: true,
	isActive: true,
	filters: JSON.stringify({}),
	usersPreview: {
		total: 0,
		targeted: 0,
		percentageTargeted: 100
	},
	segmentType: "all"
};

export const createSegment = (state = CREATE_SEGMENT_INITIAL, action) => {
	switch (action.type) {
		case "SEGMENT_RESET":
			return CREATE_SEGMENT_INITIAL;
		case "SEGMENT_UPDATE":
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
