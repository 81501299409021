import React from "react";

const TopPerformingSidebar = ({ topics, topic, clickHandler }) => {
	return (
		<div className="dashboard-side-bar dashboard-side-bar--analytics">
			{topics.map((t, i) => (
				<div
					className={(topic == t.topic ? "selected" : "") + " selectable sidebar-row sidebar-row--analytics"}
					key={i}
					onClick={() => clickHandler(t)}
				>
					<div className="text text--small">{t.displayName}</div>
				</div>
			))}
		</div>
	);
};
export default TopPerformingSidebar;
