import React, { useState, useEffect, useRef, useCallback } from "react";

// components
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { OutsideClick } from "../components/_commons/OutsideClick";
import Settings from "../components/Meraki/Settings";
import Apps from "../components/Meraki/Apps";
import Templates from "../components/Meraki/Templates";
import Messages from "../components/Meraki/Messages";
import Announcement from "../components/Meraki/Announcement";
import { validators } from "../atlas-utils/index";
import { Modal } from "../components/_commons/Modal";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// utils
import { client } from "../client";
import { store } from "../store/configureStore";
import { scroll, removeProp } from "../atlas-utils";

// graphql
import { GET_PAYMENT_GATEWAYS_LIST, GET_CONFIGURATION_VALUES } from "../graphql/meraki";
import { GET_LOCATIONS_LIST } from "../graphql/hub";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchTimingGroups } from "../actions/actions";
import {
	fetchMerakiSettings,
	editMerakiSettings,
	fetchMerakiAppSettings,
	editMerakiAppSettings,
	editConfigurationValues
} from "../actions/meraki";

import {
	createAnnouncements,
	fetchAnnouncements,
	putAnnouncement,
	deleteAnnouncements,
	checkBeforeCreateAnnouncements
} from "../actions/merakiWeb";

const FORM_TABS = [
	{
		label: "General",
		value: "general"
	},
	{
		label: "Apps",
		value: "apps"
	},
	{
		label: "Templates",
		value: "templates"
	},
	{
		label: "Display Messages",
		value: "messages"
	},
	{
		label: "Announcements",
		value: "announcement"
	}
];

const MERAKI_APPS_LIST = [
	{
		label: "Prepaid Wallet",
		value: "prepaidWallet",
		imgSrc: "/assets/icons/icon-wallet.svg"
	},
	{
		label: "Loyalty",
		value: "loyalty",
		imgSrc: "/assets/icons/icon-loyalty.svg"
	},
	{
		label: "Referral",
		value: "referral",
		imgSrc: "/assets/icons/icon-referrals.svg"
	},
	{
		label: "SMS Gateway",
		value: "smsGateway",
		imgSrc: "/assets/icons/icon-sms-gateway.svg"
	}
];

const MERAKI_TEMPLATES_LIST = [
	{
		label: "Frequently Asked Questions",
		value: "faq",
		imgSrc: "/assets/icons/icon-faq.svg"
	},
	{
		label: "Terms of Service",
		value: "tos",
		imgSrc: "/assets/icons/icon-terms-of-service.svg"
	},
	{
		label: "Privacy Policy",
		value: "privacyPolicy",
		imgSrc: "/assets/icons/icon-privacy-policy.svg"
	}
];

const MerakiContainer = ({ merakiDetails, biz }) => {
	const defaultBackgroundColor = "#C2831C";
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isSettingsFormTouched, setSettingsFormTouched] = useState(false);
	const [isAppsFormTouched, setAppsFormTouched] = useState(false);
	const [isTemplatesFormTouched, setTemplatesFormTouched] = useState(false);
	const [isAnnouncementFormTouched, setAnnouncementFormTouched] = useState(false);
	const [locationsList, setLocationsList] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState({
		id: "default",
		title: "Default"
	});
	const { loading, settingsData, appsData, error } = merakiDetails;
	const [paymentConfigList, setPaymentConfigList] = useState([]);
	const [configurationValues, setConfigurationValues] = useState({});
	const [selectedPaymentConfig, setSelectedPaymentConfig] = useState({});
	const [showPaymentGatewayContainer, setPaymentGatewayContainerState] = useState(false);
	const [merakiNavOpen, setMerakiNav] = useState(false);
	const [isConfirmationModal, setConfirmationModal] = useState(false);
	const [isDisableModal, setIsDisableModal] = useState(false);

	const [announcementBox, setAnnouncementBox] = useState(false);
	const [colorDropDown, setColorDropDown] = useState(defaultBackgroundColor);
	const [isSwitch, setIsSwitch] = useState(true);
	const [announcementData, setAnnouncementData] = useState({
		id: "",
		title: "",
		announcement: "",
		url: "",
		styles: "",
		locationGroup: [],
		enabled: true
	});
	const [editAnnouncementId, setEditAnnouncementId] = useState(0);
	const [validationMessage, setValidationMessage] = useState({});
	const [announcementDataList, setAnnouncementDataList] = useState([]);
	const [editIdValue, setEditIdValue] = useState();
	const [merakiNavList, setMerakiNavList] = useState({
		apps: MERAKI_APPS_LIST,
		templates: MERAKI_TEMPLATES_LIST
	});
	const [searchLocation, setSearchLocation] = useState({
		key: "default",
		value: ""
	});
	const [searchingLocation, setSearchingLocation] = useState(false);
	const [selectedNavItem, setSelectedNavItem] = useState(undefined);
	const [announcementAll, setAnnouncementAll] = useState([]);
	const [collisionValue, setCollsionvalue] = useState();
	const [disableDeleteTitle, setDisableDeleteTitle] = useState();
	const [enableCreateTitle, setEnableCreateTitle] = useState();
	const [realEdit, setRealEdit] = useState();
	const topRef = useRef();
	const formRef = useRef(null);
	const [deleteModal, setDeleteModal] = useState(false);

	useEffect(() => {
		fetchTimingGroups();
	}, []);

	useEffect(() => {
		fetchMerakiSettings();
	}, []);

	useEffect(() => {
		const fetchMerakiApps = async () => {
			const resp = await fetchMerakiAppSettings();
			if (resp !== "") {
				getPaymentGatewaysList(resp);
			}
		};
		fetchMerakiApps();
	}, []);

	const getPaymentGatewaysList = async (paymentGateway = null) => {
		try {
			let resp = await client.query({
				query: GET_PAYMENT_GATEWAYS_LIST,
				fetchPolicy: "no-cache"
			});
			setPaymentConfigList(resp.data.paymentGateways.filter((pg) => pg.forDropdown === true));
			if (paymentGateway) {
				setSelectedPaymentConfig(
					resp.data.paymentGateways.find((pg) => pg.value?.toLowerCase() === paymentGateway?.toLowerCase())
				);
				setPaymentGatewayContainerState(true);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const fetchConfigurationValues = async () => {
		try {
			let resp = await client.query({
				query: GET_CONFIGURATION_VALUES,
				variables: {
					keys: [
						"business_info_config_cashback_credit_limit",
						"business_info_config_cod_max_order_value",
						"business_info_config_campaign_email",
						"business_info_config_enable_polygon_based_delivery_charge"
					]
				},
				fetchPolicy: "no-cache"
			});
			let configurationValues = {};
			resp.data.configurationValues.forEach((config) => {
				configurationValues[config.key] = config.value === "1" ? true : config.value;
			});
			setConfigurationValues(configurationValues);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	useEffect(() => {
		fetchConfigurationValues();
	}, []);

	const fetchLocationsList = useCallback(async () => {
		try {
			const variables = {
				limit: 50,
				offset: 0,
				filters: [
					{
						field: "is_active",
						value: "true"
					}
				],
				sort: {
					field: "name",
					order: "ASC"
				},
				search: [searchLocation]
			};
			let resp = await client.query({
				query: GET_LOCATIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			resp.data.stores.objects.unshift({ id: "default", title: "Default" });
			setLocationsList(resp.data.stores.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setSearchingLocation(false);
	}, [searchLocation]);

	useEffect(() => {
		fetchLocationsList();
	}, [searchLocation]);

	const switchTab = (tab) => {
		setFormTab(tab.value);
		if (merakiNavList[tab.value]) {
			setSelectedNavItem(merakiNavList[tab.value][0].value);
		}
		// scroll to the top
		if (topRef?.current) {
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		}
	};

	const handleLocation = async (f, location) => {
		if (location === null) {
			return;
		}
		setSelectedLocation(location);
		const resp = await fetchMerakiAppSettings(location.id, true);
		if (resp) {
			setPaymentGatewayContainerState(true);
			setSelectedPaymentConfig(paymentConfigList.find((pg) => pg.value === resp));
		} else {
			setPaymentGatewayContainerState(false);
			setSelectedPaymentConfig({});
		}
	};

	const handleLocationSearch = debounce((searchQuery) => {
		if (searchQuery !== searchLocation.value) {
			setSearchingLocation(true);
			setSearchLocation({
				...searchLocation,
				value: searchQuery
			});
		}
	}, 300);

	const handleCancel = async () => {
		if (formTab === FORM_TABS[0].value || formTab === FORM_TABS[3].value) {
			fetchMerakiSettings();
			fetchConfigurationValues();
			setSettingsFormTouched(false);
		} else if (formTab === FORM_TABS[1].value) {
			setSelectedLocation({ id: "default", title: "Default" });
			const resp = await fetchMerakiAppSettings();
			if (resp) {
				setPaymentGatewayContainerState(true);
				setSelectedPaymentConfig(paymentConfigList.find((pg) => pg.value === resp));
			} else {
				setPaymentGatewayContainerState(false);
				setSelectedPaymentConfig({});
			}
			setAppsFormTouched(false);
			setSelectedNavItem(merakiNavList[formTab][0].value);
		} else if (formTab === FORM_TABS[2].value) {
			setSelectedLocation({ id: "default", title: "Default" });
			const resp = await fetchMerakiAppSettings();
			if (resp) {
				setPaymentGatewayContainerState(true);
				setSelectedPaymentConfig(paymentConfigList.find((pg) => pg.value === resp));
			} else {
				setPaymentGatewayContainerState(false);
				setSelectedPaymentConfig({});
			}
			setTemplatesFormTouched(false);
			setSelectedNavItem(merakiNavList[formTab][0].value);
		} else if (formTab === FORM_TABS[4].value) {
			setAnnouncementBox(false);
			setAnnouncementFormTouched(false);
			setAnnouncementData({
				title: "",
				announcement: "",
				url: "",
				styles: "",
				locationGroup: []
			});
		}
		// scroll to the top
		if (topRef?.current) {
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		}
	};

	const handlePaymentConfig = (f, config) => {
		if (config === null) {
			return;
		}
		setSelectedPaymentConfig(config);
	};

	const handleForm = (field, value) => {
		if (formTab === FORM_TABS[0].value || formTab === FORM_TABS[3].value) {
			// enforce positive value for these fields
			if (
				[
					"bizMinOrderTotal",
					"orderDeliveryRadius",
					"maxOrderPendingTime",
					"minimumOrderDeliveryTime",
					"minimumOrderPickupTime"
				].includes(field)
			) {
				if (value && value < 0) {
					value = 0;
				}
			}
			store.dispatch({
				type: ActionTypes.UPDATE_MERAKI_SETTINGS_DETAIL,
				payload: {
					settingsData: {
						[field]: value
					}
				}
			});
			if (!isSettingsFormTouched) {
				setSettingsFormTouched(true);
			}
		} else if (formTab === FORM_TABS[1].value) {
			// enforce positive value for these fields
			if (
				[
					"minimumWalletCreditThreshold",
					"maxPrepaidTransactionAmt",
					"maxAllowedPrepaidBalance",
					"pointBalanceMapping",
					"pointUseThreshold",
					"defaultPointsExpiryDuration",
					"referralValidity"
				].includes(field)
			) {
				if (value && value < 0) {
					value = 0;
				}
			}
			store.dispatch({
				type: ActionTypes.UPDATE_MERAKI_SETTINGS_DETAIL,
				payload: {
					appsData: {
						[field]: value
					}
				}
			});
			if (!isAppsFormTouched) {
				setAppsFormTouched(true);
			}
		} else if (formTab === FORM_TABS[2].value) {
			store.dispatch({
				type: ActionTypes.UPDATE_MERAKI_SETTINGS_DETAIL,
				payload: {
					appsData: {
						[field]: value
					}
				}
			});
			if (!isTemplatesFormTouched) {
				setTemplatesFormTouched(true);
			}
		}
	};

	const handleConfigValues = (field, value) => {
		setConfigurationValues({
			...configurationValues,
			[field]: value
		});
		if (!isSettingsFormTouched) {
			setSettingsFormTouched(true);
		}
	};

	const handleConfigForm = (field, value, platform) => {
		store.dispatch({
			type: ActionTypes.UPDATE_MERAKI_APPS_CONFIGURATIONS,
			payload: {
				platform: platform,
				field: field,
				value: value
			}
		});
		if (!isAppsFormTouched) {
			setAppsFormTouched(true);
		}
	};

	const handleAnnouncementForm = (field, value, wordCountLimit = null) => {
		if (wordCountLimit && value.length - 1 >= wordCountLimit) {
			return;
		}
		setAnnouncementData((current) => ({
			...current,
			[field]: value,
			id: Date.now(),
			location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
			styles: { color: `${colorDropDown}` }
		}));
		setAnnouncementFormTouched(true);
	};
	function validateAnnouncementData(announcementData) {
		let validationMessage = {};
		Object.keys(announcementData).forEach((value) => {
			switch (value) {
				case "title":
					if (announcementData[value]?.length === 0) {
						validationMessage[value] = "Please enter a title";
					}
					break;
				case "announcement":
					if (announcementData[value]?.length === 0) {
						validationMessage[value] = "Please enter an announcement";
					}
					break;
				case "url":
					if (!(announcementData[value]?.length === 0) && !isUrlValid(announcementData[value])) {
						validationMessage[value] = "Invalid URL";
					}
					break;
				case "locationGroup":
					if (announcementData[value]?.length == 0) {
						validationMessage[value] = "Enter atleast 1 location group";
					}
					break;
				default:
					break;
			}
		});
		return validationMessage;
	}

	const handleSubmit = async () => {
		if (formTab === FORM_TABS[0].value || formTab === FORM_TABS[3].value) {
			const configurations = [];
			Object.keys(configurationValues).forEach((config) =>
				configurations.push({
					key: config,
					value:
						typeof configurationValues[config] === "boolean"
							? configurationValues[config]
								? "1"
								: ""
							: configurationValues[config]
				})
			);
			const variables = { configurations };
			const saveConfigValues = await editConfigurationValues(variables);
			const sanitisedData = removeProp(settingsData, "__typename");
			const resp = await editMerakiSettings(sanitisedData);
			if (resp) {
				setSettingsFormTouched(false);
				// scroll to the top
				if (topRef?.current) {
					scroll({ top: topRef?.current?.offset - 57, left: 0 });
				}
			}
		} else if (formTab === FORM_TABS[1].value) {
			let sanitisedData = removeProp(appsData, "__typename");
			sanitisedData.paymentGateway = selectedPaymentConfig?.value
				? selectedPaymentConfig.value?.toLowerCase()
				: null;

			const pgConfigs = [];

			(sanitisedData?.businessConfigurations ?? []).forEach((config) => {
				if (!sanitisedData.paymentGateway) {
					pgConfigs.push({
						gatewayName: config?.name,
						configData: [
							...config?.keys?.map((configKey) => ({
								key: configKey?.key,
								value: configKey.key === "is_pg_disabled" ? true : configKey?.value,
								type: configKey.type
							}))
						]
					});
				}
			});

			sanitisedData.pgServiceConfigurations = [...pgConfigs];

			const resp = await editMerakiAppSettings(sanitisedData, selectedLocation.id);
			if (resp) {
				setAppsFormTouched(false);
				// scroll to the top
				if (topRef?.current) {
					scroll({ top: topRef?.current?.offset - 57, left: 0 });
				}
			}
		} else if (formTab === FORM_TABS[2].value) {
			let sanitisedData = removeProp(appsData, "__typename");
			sanitisedData.paymentGateway = selectedPaymentConfig?.value
				? selectedPaymentConfig.value?.toLowerCase()
				: null;

			const pgConfigs = [];

			(sanitisedData?.businessConfigurations ?? []).forEach((config) => {
				if (!sanitisedData.paymentGateway) {
					pgConfigs.push({
						gatewayName: config?.name,
						configData: [
							...config?.keys?.map((configKey) => ({
								key: configKey?.key,
								value: configKey.key === "is_pg_disabled" ? true : configKey?.value,
								type: configKey.type
							}))
						]
					});
				}
			});
			sanitisedData.pgServiceConfigurations = [...pgConfigs];

			const resp = await editMerakiAppSettings(sanitisedData);
			if (resp) {
				setTemplatesFormTouched(false);
				// scroll to the top
				if (topRef?.current) {
					scroll({ top: topRef?.current?.offset - 57, left: 0 });
				}
			}
		} else if (formTab === FORM_TABS[4].value) {
			validateData();
		}
	};

	const handleMerakiNavigation = (navItem) => {
		// set navItem
		setSelectedNavItem(navItem);

		// perform scroll
		const cardContainer = document.querySelector(`.card-container[${formTab}="${navItem}"]`);
		if (cardContainer) {
			window.requestAnimationFrame(() => {
				scroll({ top: cardContainer?.offsetTop - 135, left: 0 });
			});
		}
		window.requestAnimationFrame(() => {
			setMerakiNav(false);
		});
	};

	const handleAnnouncementDelete = async () => {
		deleteAnnouncements(editIdValue)
			.then((res) => {
				fetchAnnouncements()
					.then((res) => {
						setAnnouncementAll(res.data);
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
		setDeleteModal(false);
	};

	const handleAnnouncementDisable = async () => {
		if (editAnnouncementId) {
			if (disableDeleteTitle == "Disable") {
				await putAnnouncement({
					_id: editIdValue,
					title: announcementData.title,
					description: [{ lang: "en", desc: announcementData.description[0].desc }],
					styles: announcementData.styles,
					location_groups: announcementData.locationGroup,
					url: announcementData.url,
					enabled: !announcementData.enabled
				})
					.then((res) => {
						fetchAnnouncements()
							.then((res) => {
								setAnnouncementAll(res.data);
							})
							.catch((err) => console.log(err));
					})
					.catch((err) => {
						console.log(err);
					});
				setIsDisableModal(false);
				setEditAnnouncementId(false);
			} else {
				await deleteAnnouncements(editIdValue)
					.then((res) => {
						fetchAnnouncements()
							.then((res) => {
								setAnnouncementAll(res.data);
							})
							.catch((err) => console.log(err));
					})
					.catch((err) => console.log(err));
			}

			setIsDisableModal(false);
			setEditAnnouncementId(false);
			setAnnouncementFormTouched(false);
		} else {
			setAnnouncementData({
				title: "",
				announcement: "",
				url: "",
				styles: "",
				locationGroup: []
			});

			setIsDisableModal(false);
			setIsSwitch(false);
		}
	};
	const handleAnnouncementConfirmation = async () => {
		if (realEdit) {
			await putAnnouncement({
				_id: editIdValue,
				title: announcementData.title,
				description: [{ lang: "en", desc: announcementData.announcement }],
				styles: announcementData.styles,
				location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
				url: announcementData.url,
				enabled: announcementData.enabled
			})
				.then((res) => {
					fetchAnnouncements()
						.then((res) => {
							setAnnouncementAll(res.data);
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					console.log(err);
				});

			setAnnouncementData({
				title: "",
				announcement: "",
				url: "",
				styles: "",
				locationGroup: []
			});
			setConfirmationModal(false);
			setAnnouncementFormTouched(false);
			setAnnouncementBox(false);
			setRealEdit(false);
		} else if (editAnnouncementId) {
			await putAnnouncement({
				_id: editIdValue,
				title: announcementData.title,
				description: [{ lang: "en", desc: announcementData.description[0].desc }],
				styles: announcementData.styles,
				location_groups: announcementData.locationGroup,
				url: announcementData.url,
				enabled: announcementData.enabled
			})
				.then((res) => {
					fetchAnnouncements()
						.then((res) => {
							setAnnouncementAll(res.data);
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					console.log(err);
				});

			setEditAnnouncementId(false);
			setAnnouncementData({
				title: "",
				announcement: "",
				url: "",
				styles: "",
				locationGroup: []
			});
			setConfirmationModal(false);
			setAnnouncementFormTouched(false);
			setAnnouncementBox(false);
			setEditAnnouncementId(false);
		} else if (announcementData != {}) {
			await createAnnouncements(
				JSON.stringify({
					title: announcementData.title,
					description: [{ lang: "en", desc: announcementData.announcement }],
					styles: { color: `${colorDropDown}` },
					location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
					url: announcementData.url
				})
			)
				.then((res) => {
					fetchAnnouncements()
						.then((res) => {
							setAnnouncementAll(res.data);
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					console.log(err);
				});

			setAnnouncementData({
				title: "",
				announcement: "",
				url: "",
				styles: "",
				locationGroup: []
			});
			setConfirmationModal(false);
			setAnnouncementFormTouched(false);
			setAnnouncementBox(false);
		}
	};
	function isUrlValid(url) {
		const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
		return urlRegex.test(url);
	}

	useEffect(() => {
		setAnnouncementData({
			...announcementData,
			location_groups: announcementData.locationGroup?.map((item) => parseInt(item.id, 10)),
			styles: { color: `${colorDropDown}` }
		});
	}, [colorDropDown]);

	const validateData = async () => {
		if (realEdit) {
			let validationMessage = validateAnnouncementData(announcementData);
			if (!!Object.keys(validationMessage).length) {
				setValidationMessage({
					...validationMessage
				});
				return;
			}
			if (announcementData.enabled == false) {
				await putAnnouncement({
					_id: editIdValue,
					title: announcementData.title,
					description: [{ lang: "en", desc: announcementData.announcement }],
					styles: announcementData.styles,
					location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
					url: announcementData.url,
					enabled: announcementData.enabled
				})
					.then((res) => {
						fetchAnnouncements()
							.then((res) => {
								setAnnouncementAll(res.data);
							})
							.catch((err) => console.log(err));
					})
					.catch((err) => {
						console.log(err);
					});

				setAnnouncementData({
					title: "",
					announcement: "",
					url: "",
					styles: "",
					locationGroup: [],
					enabled: ""
				});
				setAnnouncementFormTouched(false);
				setAnnouncementBox(false);
				setRealEdit(false);
			} else {
				await checkBeforeCreateAnnouncements(
					JSON.stringify({
						_id: editIdValue,
						title: announcementData.title,
						description: [{ lang: "en", desc: announcementData.announcement }],
						styles: { color: `${colorDropDown}` },

						location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
						url: announcementData.url,
						enabled: announcementData.enabled
					})
				)
					.then((res) => {
						if (res.data.valid) {
							putAnnouncement({
								_id: editIdValue,
								title: announcementData.title,
								description: [{ lang: "en", desc: announcementData.announcement }],
								styles: announcementData.styles,
								location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
								url: announcementData.url,
								enabled: announcementData.enabled
							})
								.then((res) => {
									fetchAnnouncements()
										.then((res) => {
											setAnnouncementAll(res.data);
										})
										.catch((err) => console.log(err));
								})
								.catch((err) => {
									console.log(err);
								});

							setRealEdit(false);
							setAnnouncementData({
								title: "",
								announcement: "",
								url: "",
								styles: "",
								locationGroup: [],
								enabled: ""
							});
						} else {
							setCollsionvalue(res.data.overlapping_store_ids.length);
							setConfirmationModal(true);
						}
					})
					.catch((err) => {
						console.log(err);
					});

				setAnnouncementFormTouched(false);
				setAnnouncementBox(false);
			}
		} else if (editAnnouncementId) {
			let validationMessage = validateAnnouncementData(announcementData);
			if (!!Object.keys(validationMessage).length) {
				setValidationMessage({
					...validationMessage
				});
				return;
			}
			await checkBeforeCreateAnnouncements(
				JSON.stringify({
					_id: editIdValue,
					title: announcementData.title,
					description: [{ lang: "en", desc: announcementData.announcement }],
					styles: { color: `${colorDropDown}` },
					location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
					url: announcementData.url
				})
			)
				.then((res) => {
					if (res.data.valid) {
						putAnnouncement(
							JSON.stringify({
								_id: editIdValue,
								title: announcementData.title,
								description: [{ lang: "en", desc: announcementData.announcement }],
								styles: announcementData.styles,
								location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
								url: announcementData.url
							})
						)
							.then((res) => {
								fetchAnnouncements()
									.then((res) => {
										setAnnouncementAll(res.data);
									})
									.catch((err) => console.log(err));
							})
							.catch((err) => {
								console.log(err);
							});

						setEditAnnouncementId(false);
					} else {
						setCollsionvalue(res.data.overlapping_store_ids.length);
						setConfirmationModal(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
			setAnnouncementData({
				title: "",
				announcement: "",
				url: "",
				styles: "",
				locationGroup: []
			});

			setAnnouncementFormTouched(false);
			setAnnouncementBox(false);
		} else {
			let validationMessage = validateAnnouncementData(announcementData);
			if (!!Object.keys(validationMessage).length) {
				setValidationMessage({
					...validationMessage
				});

				return;
			}

			await checkBeforeCreateAnnouncements(
				JSON.stringify({
					_id: editIdValue,
					title: announcementData.title,
					description: [{ lang: "en", desc: announcementData.announcement }],
					styles: { color: `${colorDropDown}` },
					location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
					url: announcementData.url
				})
			)
				.then((res) => {
					if (res.data.valid) {
						createAnnouncements(
							JSON.stringify({
								title: announcementData.title,
								description: [{ lang: "en", desc: announcementData.announcement }],
								styles: { color: `${colorDropDown}` },
								location_groups: announcementData.locationGroup.map((item) => parseInt(item.id, 10)),
								url: announcementData.url
							})
						)
							.then((res) => {
								fetchAnnouncements()
									.then((res) => {
										setAnnouncementAll(res.data);
									})
									.catch((err) => console.log(err));
							})
							.catch((err) => {
								console.log(err);
							});
						setAnnouncementData({
							title: "",
							announcement: "",
							url: "",
							styles: "",
							locationGroup: []
						});

						setAnnouncementFormTouched(false);
						setAnnouncementBox(false);
					} else {
						setCollsionvalue(res.data.overlapping_store_ids.length);
						setConfirmationModal(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div className="meraki-section section-container-common" ref={topRef}>
			<div className="settings-header no-border">
				<div className="header-text--container">
					<div className="header-text">Meraki</div>
					<div className="subheader-text">
						Manage default Meraki configurations for your business from here
					</div>
				</div>
				{formTab === FORM_TABS[4].value && (
					<div className="header-action--container">
						{announcementAll.length > 0 && (
							<div className="announcement-create-button">
								<Button
									clickHandler={() => {
										setAnnouncementBox(true);
										setAnnouncementData({
											title: "",
											announcement: "",
											url: "",
											styles: "",
											locationGroup: []
										});
										setValidationMessage({});
										setAnnouncementFormTouched(true);
										setRealEdit(false);
										setColorDropDown(defaultBackgroundColor);
									}}
								>
									+ Create Announcement
								</Button>
							</div>
						)}
					</div>
				)}
			</div>

			<div className="meraki-container">
				<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
				<FormContainer
					ref={formRef}
					cancel={handleCancel}
					submit={handleSubmit}
					submitTitle="Save"
					hideActions={
						(formTab === FORM_TABS[0].value && !isSettingsFormTouched) ||
						(formTab === FORM_TABS[1].value && !isAppsFormTouched) ||
						(formTab === FORM_TABS[2].value && !isTemplatesFormTouched) ||
						(formTab === FORM_TABS[3].value && !isSettingsFormTouched) ||
						(formTab === FORM_TABS[4].value && !isAnnouncementFormTouched)
					}
				>
					<div className="form-content">
						{formTab === FORM_TABS[0].value && (
							<Settings
								data={settingsData}
								configValues={configurationValues}
								handleForm={handleForm}
								handleConfigValues={handleConfigValues}
								validations={error.fields || {}}
							/>
						)}
						{formTab === FORM_TABS[1].value && (
							<Apps
								data={appsData}
								loading={loading}
								handleForm={handleForm}
								validations={error.fields || {}}
								paymentConfigList={paymentConfigList}
								selectedPaymentConfig={selectedPaymentConfig}
								handlePaymentConfig={handlePaymentConfig}
								handleConfigForm={handleConfigForm}
								locationsList={locationsList}
								selectedLocation={selectedLocation}
								handleLocation={handleLocation}
								searchingLocation={searchingLocation}
								handleLocationSearch={handleLocationSearch}
								showPaymentGatewayContainer={showPaymentGatewayContainer}
								togglePaymentGateway={(state) => {
									setPaymentGatewayContainerState(state);
									if (!isAppsFormTouched) {
										setAppsFormTouched(true);
									}
								}}
							/>
						)}
						{formTab === FORM_TABS[2].value && (
							<Templates data={appsData} handleForm={handleForm} validations={error.fields || {}} />
						)}
						{formTab === FORM_TABS[4].value && (
							<Announcement
								announcementBox={announcementBox}
								setAnnouncementBox={setAnnouncementBox}
								data={appsData}
								loading={loading}
								handleForm={handleForm}
								validations={error.fields || {}}
								paymentConfigList={paymentConfigList}
								selectedPaymentConfig={selectedPaymentConfig}
								handlePaymentConfig={handlePaymentConfig}
								handleConfigForm={handleConfigForm}
								locationsList={locationsList}
								selectedLocation={selectedLocation}
								handleLocation={handleLocation}
								searchingLocation={searchingLocation}
								handleLocationSearch={handleLocationSearch}
								announcementData={announcementData}
								setAnnouncementData={setAnnouncementData}
								handleAnnouncementForm={handleAnnouncementForm}
								announcementDataList={announcementDataList}
								setAnnouncementDataList={setAnnouncementDataList}
								validationMessage={validationMessage}
								colorDropDown={colorDropDown}
								setColorDropDown={setColorDropDown}
								editAnnouncementId={editAnnouncementId}
								setEditAnnouncementId={setEditAnnouncementId}
								isDisableModal={isDisableModal}
								setIsDisableModal={setIsDisableModal}
								isSwitch={isSwitch}
								setIsSwitch={setIsSwitch}
								announcementAll={announcementAll}
								setAnnouncementAll={setAnnouncementAll}
								setEditIdValue={setEditIdValue}
								setCollsionvalue={setCollsionvalue}
								setConfirmationModal={setConfirmationModal}
								enableCreateTitle={enableCreateTitle}
								setEnableCreateTitle={setEnableCreateTitle}
								disableDeleteTitle={disableDeleteTitle}
								setDisableDeleteTitle={setDisableDeleteTitle}
								setRealEdit={setRealEdit}
								formRef={formRef}
								setValidationMessage={setValidationMessage}
								setAnnouncementFormTouched={setAnnouncementFormTouched}
								setDeleteModal={setDeleteModal}
								defaultBackgroundColor={defaultBackgroundColor}
							/>
						)}
						{formTab === FORM_TABS[3].value && (
							<Messages data={settingsData} handleForm={handleForm} validations={error.fields || {}} />
						)}
					</div>
					{merakiNavList && (formTab === FORM_TABS[1].value || formTab === FORM_TABS[2].value) && (
						<MerakiNavigation
							merakiNavOpen={merakiNavOpen}
							setMerakiNav={setMerakiNav}
							handleMerakiNavigation={handleMerakiNavigation}
							handleOutsideClick={() => setMerakiNav(false)}
							merakiNavList={merakiNavList[formTab]}
							selectedNavItem={selectedNavItem}
							currTab={formTab}
						/>
					)}
				</FormContainer>
			</div>

			<Modal
				title={`Publish Announcement?`}
				isOpen={isConfirmationModal}
				close={() => {
					setConfirmationModal(false);
				}}
				submitTitle="Replace & Publish"
				showCancelAction
				showSubmitAction
				submitAction={handleAnnouncementConfirmation}
				cancelTitle="Cancel"
				classes="anouncement-confirmation--modal"
				submitButtonWidth="200"
			>
				<div className="modal-main-content">
					<div className="main-content">
						{`${announcementData.title} announcement has ${collisionValue} location that overlap with other published announcement`}
					</div>
					<div className="sub-content">
						{"Publishing now will display the new message at these locations"}
					</div>
				</div>
			</Modal>

			{deleteModal && (
				<Modal
					title={`${announcementData.title}`}
					isOpen={deleteModal}
					close={() => {
						setDeleteModal(false);
					}}
					submitTitle={`Delete`}
					showCancelAction
					showSubmitAction
					submitAction={handleAnnouncementDelete}
					cancelTitle="Cancel"
					classes="anouncement-confirmation--modal"
					submitButtonWidth="200"
				>
					<div className="modal-main-content">
						<div className="main-content">
							{`Are you sure you want to Delete ${announcementData.title}?`}
						</div>
					</div>
				</Modal>
			)}

			{isDisableModal && (
				<div>
					<Modal
						title={`${disableDeleteTitle} Announcement?`}
						isOpen={isDisableModal}
						close={() => {
							setIsDisableModal(false);
						}}
						submitTitle={`${disableDeleteTitle}`}
						showCancelAction
						showSubmitAction
						submitAction={handleAnnouncementDisable}
						cancelTitle="Cancel"
						classes="anouncement-confirmation--modal"
						submitButtonWidth="200"
					>
						<div className="modal-main-content">
							<div className="main-content">
								{`${
									disableDeleteTitle == "Disable" ? "Disabling" : "Deleting"
								} this announcement will cause ${collisionValue} locations to revert back to the previously enabled announcement associated with them.`}
							</div>
						</div>
					</Modal>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (store) => ({
	merakiDetails: store.merakiDetails,
	biz: store.login.loggedInbizDetail
});

export default connect(mapStateToProps)(MerakiContainer);

export const FormContainer = ({
	children,
	submit,
	cancel,
	classes,
	footerClass = "",
	submitTitle = "Submit",
	submitClass = "",
	submitLoading = false,
	cancelTitle = "Cancel",
	disabled = false,
	disableSubmit = false,
	hideActions = false,
	hideCancelAction = false,
	hideSubmitAction = false
}) => {
	return (
		<div className={"form-container " + classes}>
			{children}
			{!hideActions && (
				<div className={"footer " + footerClass}>
					{!hideCancelAction && (
						<Button
							clickHandler={() => cancel()}
							classes={"W(100px) Mend(10px) " + (disabled || submitLoading ? "disabled" : "")}
							type="secondary"
						>
							{cancelTitle}
						</Button>
					)}
					{!hideSubmitAction && (
						<Button
							clickHandler={submit}
							classes={`W(100px) ${submitClass} ` + (disabled || disableSubmit ? "disabled" : "")}
							loading={submitLoading}
						>
							{submitTitle}
						</Button>
					)}
				</div>
			)}
		</div>
	);
};

const MerakiNavigation = OutsideClick(
	({ merakiNavOpen, setMerakiNav, handleMerakiNavigation, merakiNavList, selectedNavItem, currTab, nodeRef }) => {
		const formTabs = {
			apps: {
				label: "Apps",
				imgSrc: "/assets/icons/icon-apps-navigation.svg"
			},
			templates: {
				label: "Templates",
				imgSrc: "/assets/icons/icon-templates-navigation.svg"
			}
		};
		return (
			<div ref={nodeRef} className="meraki-navigation" onClick={() => setMerakiNav(!merakiNavOpen)}>
				<img src={formTabs[currTab].imgSrc} />
				<span>{formTabs[currTab].label}</span>
				{merakiNavOpen && (
					<div className="nav-list" onClick={(e) => e.preventDefault()}>
						{merakiNavList.map((item, i) => (
							<div
								className="nav-list-item"
								data-active={item.value === selectedNavItem ? true : false}
								key={i}
								onClick={() => handleMerakiNavigation(item.value)}
							>
								{item.label}
								<img src={item.imgSrc} alt="" />
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
);
