import React from "react";

// components
import ReportsList from "./ReportsList";
import ReportDownload from "./ReportDownload";
import ReportPreview from "./ReportPreview";
import ScheduleReportCreate from "./ScheduleReportCreate";
import ScheduleReportEdit from "./ScheduleReportEdit";
import ScheduleReportHistory from "../components/Reports/ScheduleReportHistory";

// third party
import { Route, Redirect } from "react-router-dom";

const ReportsNew = () => {
	return (
		<div className="reports-container">
			<Route path="/reports/list/:tab" component={ReportsList} />
			<Route exact path="/reports/list/overview/:id" component={ReportDownload} />
			<Route exact path="/reports/list/schedules/new" component={ScheduleReportCreate} />
			<Route exact path="/reports/list/schedules/edit/:id/:reportId" component={ScheduleReportEdit} />
			<Route exact path="/reports/history/schedules/:id" component={ScheduleReportHistory} />
			<Route exact path="/reports/preview/:id" component={ReportPreview} />
			<Route exact path="/reports" render={() => <Redirect to="/reports/list/overview" />} />
		</div>
	);
};
export default ReportsNew;
