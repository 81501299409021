import gql from "graphql-tag";

export const GET_ENTITY_ITEM_TAGS = gql`
	query entityItemTagsAssociations(
		$entityId: Int!
		$entityType: BizItemTagAssociationEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		entityItemTagsAssociations(
			entityId: $entityId
			entityType: $entityType
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			objects {
				id
				title
				crmTitle
				tagGroup {
					title
				}
				numItems
				isAssociated
			}
		}
	}
`;

export const UPDATE_ENTITY_ITEM_TAGS = gql`
	mutation updateEntityItemTags($tagGroupId: Int!, $tagsToAssociate: [Int], $tagsToDisassociate: [Int]) {
		updateItemTagGroupTags(
			input: {
				tagGroupId: $tagGroupId
				tagsToAssociate: $tagsToAssociate
				tagsToDisassociate: $tagsToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
