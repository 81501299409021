import React, { useState, useRef } from "react";

// components
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";
import { SelectFilter } from "../_commons/SelectFilter";

// utils
import { lS } from "../../atlas-utils";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

const SetupSchedule = ({
	createView = false,
	reportDetail,
	scheduleReportCreate,
	isFormTouched = false,
	setFormTouched,
	isMultibrandEnabled = false,
	bizId
}) => {
	const [currEmail, setCurrEmail] = useState("");
	const tagsRef = useRef();
	const { loading, data, currentFilters, validations, emails } = reportDetail;
	const { scheduleReportData } = scheduleReportCreate;

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload
		});
	};

	const setFilter = (field, value) => {
		const filters = {
			...currentFilters,
			[field]: value
		};
		updateReportDetail({
			currentFilters: filters,
			appliedFilters: filters,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const setEmailFilter = (tags) => {
		updateReportDetail({
			emails: tags
		});
		// update emails in local storage
		if (createView) {
			lS.set("report_emails", { ...lS.get("report_emails"), [bizId]: [...tags] });
		}
	};

	const handleTagInput = (val) => {
		setCurrEmail(val);
		if (validations?.emails) {
			updateReportDetail({
				validations: {
					...validations,
					emails: ""
				}
			});
		}
	};

	if (loading) {
		return null;
	}

	return (
		<div className="setup-schedule">
			<div className="note">
				{currentFilters.showDataFor?.value === "Previous Day"
					? "Scheduled for every day, by 8 AM"
					: currentFilters.showDataFor?.value === "Previous Week"
					? "Scheduled for every Monday, by 8 AM"
					: "Scheduled for the 1st of every month, by 8 AM"}
			</div>
			{/* <div className="filters-container">
                <ReportFilters
                    options={[
                        ...(scheduleReportData?.scheduleReportFilters?.filter((f) => f.field === "scheduleFor") ?? []),
                    ]}
                    filtersToDisplay={[
                        "scheduleFor"
                    ]}
                    setFilter={setFilter}
                    currentFilters={currentFilters}
                    validations={validations}
                    showFields={true}
                    isMultibrandEnabled={isMultibrandEnabled}
                />
            </div> */}
			<div className="sub-title">Schedule delivery preferences</div>
			<div className="filters-container">
				<TagsInputWrapper
					ref={tagsRef}
					tags={emails}
					onChange={setEmailFilter}
					tagInput={currEmail}
					onChangeInput={handleTagInput}
					requiredLabel={true}
					placeholder={"Enter an email"}
					validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
					onValidationReject={() =>
						updateReportDetail({
							validations: {
								...validations,
								emails: "Please enter a valid email"
							}
						})
					}
					validationMessage={validations?.emails || ""}
					showDropdown={true}
				>
					Recipient Email IDs
				</TagsInputWrapper>
				<SelectFilter
					title="Report Format"
					requiredLabel={true}
					options={
						data.exports?.map((exp) => ({
							...exp,
							name: exp.name.replace("Download ", "")
						})) || []
					}
					field="exportFormat"
					currValue={currentFilters["exportFormat"]}
					setFilter={setFilter}
					isClearable={false}
					labelKey="name"
					valueKey="id"
					validationMessage={validations?.exportFormat || ""}
				/>
			</div>
		</div>
	);
};
export default SetupSchedule;
