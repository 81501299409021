import gql from "graphql-tag";

export const GET_ENTITY_ITEMS = gql`
	query entityItemAssociations(
		$entityId: Int!
		$entityType: ItemAssociationEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$brandId: Int
	) {
		entityItemAssociations(
			entityId: $entityId
			entityType: $entityType
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
			brandId: $brandId
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			objects {
				id
				itemTitle
				itemPrice
				crmTitle
				foodType
				isAssociated
				category
				tags
			}
		}
	}
`;

export const UPDATE_ENTITY_ITEMS = gql`
	mutation updateEntityItems(
		$entityId: Int!
		$entityType: ItemAssociationEntityType!
		$itemsToAssociate: [Int]
		$itemsToDisassociate: [Int]
		$associateAll: Boolean
		$disassociateAll: Boolean
		$filters: [ListFilterArgument]
	) {
		updateEntityItems(
			input: {
				entityId: $entityId
				entityType: $entityType
				itemsToAssociate: $itemsToAssociate
				itemsToDisassociate: $itemsToDisassociate
				associateAll: $associateAll
				disassociateAll: $disassociateAll
				filters: $filters
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
