import gql from "graphql-tag";

export const GET_TRANSACTIONS_ONLINE_LIST = gql`
	query getTransactionsList(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
		$brand: String
	) {
		orders(limit: $limit, offset: $offset, sort: $sort, filters: $filters, search: $search, brand: $brand) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				merchantRefId
				created
				channel
				channelLogo
				store {
					brand {
						id
						name
						image
					}
					title
				}
				customer {
					id
					firstName
					lastName
					fullName
					phone
				}
				address {
					id
					name
				}
				externalPlatform {
					id
					bizPlatform {
						platform {
							name
						}
					}
				}
				paymentMode
				payableAmount
				status
				childOrderId
				complaints {
					expiredAt
				}
			}
		}
	}
`;

export const GET_TRANSACTIONS_IN_STORE_LIST = gql`
	query getTransactionsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		purchases(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				billNumber
				created
				user {
					firstName
					lastName
					fullName
					phone
				}
				store {
					title
				}
				amount
			}
		}
	}
`;

export const GET_TRANSACTION_ONLINE = gql`
	query getTransaction($id: Int!) {
		order(id: $id) {
			id
			type
			merchantRefId
			deliveryDate
			created
			updated
			timeSlotStart
			timeSlotEnd
			discount
			totalTaxes
			totalCharges
			subtotal
			payableAmount
			walletCreditApplied
			paymentMode
			channel
			channelLogo
			status
			instructions
			nextStates
			nextState
			couponText
			aggregatorPayload
			externalPlatform {
				id
				deliveryType
				bizPlatform {
					platform {
						name
					}
				}
			}
			taxes {
				id
				title
				value
				rate
			}
			charges {
				id
				title
				value
				rate
			}
			address {
				id
				name
				address1
				address2
				city
				pin
				subLocality
			}
			store {
				title
				brand {
					id
					name
					image
				}
			}
			customer {
				id
				firstName
				lastName
				phone
				email
			}
			items {
				id
				title
				price
				quantity
				totalCharge
				totalTax
				total
				discount
				discountCode
				instructions
				taxes {
					id
					title
					value
					rate
				}
				charges {
					id
					title
					value
					rate
				}
				optionsToAdd {
					id
					title
					price
					priceAtLocation
					weight
					description
				}
				orderItemOptions {
					id
					quantity
				}
			}
			statusUpdates {
				id
				status
				prevStatus
				message
				updatedBy {
					username
					firstName
					lastName
				}
				created
			}
			delivery {
				id
				currentState
				deliveryPerson {
					name
					phone
					altPhone
				}
				statusUpdates {
					id
					state
					created
					comments
				}
			}
			paymentTransaction {
				txnId
				amount
				gwTxnId
				amount
				state
				comments
				history
				paymentMethod
			}
			parentOrder {
				id
			}
			childOrderId
			complaints {
				created
				expiredAt
				complaintMessage
				customerComplaintsCount
				complaintDesc
				repeatCustomerCount
				items {
					total
					quantity
					title
				}
			}
		}
	}
`;

export const GET_WEBHOOK_DELIVERIES = gql`
	query getWebhookDeliveries($id: Int) {
		order(id: $id) {
			webhookDeliveries {
				id
				url
				statusCode
				retriesCount
				lastExecuted
				eventDetails
				webhook {
					eventTypeDisplayName
				}
			}
		}
	}
`;

export const GET_TRANSACTION_IN_STORE = gql`
	query getTransactionsList($id: Int) {
		purchase(id: $id) {
			id
			billNumber
			billDump
			created
			user {
				firstName
				lastName
				fullName
				phone
			}
			store {
				title
			}
			amount
		}
	}
`;

export const UPDATE_ORDER_STATUS = gql`
	mutation updateOrderStatus(
		$orderId: Int
		$newStatus: Status
		$message: String
		$unavailableItemIds: [Int]
		$unavailableOptionIds: [Int]
	) {
		updateOrderStatus(
			orderId: $orderId
			newStatus: $newStatus
			message: $message
			unavailableItemIds: $unavailableItemIds
			unavailableOptionIds: $unavailableOptionIds
		) {
			status {
				success
			}
		}
	}
`;

export const STATUS_UPDATE = gql`
	query statusUpdate($id: Int) {
		orderStatusUpdate(id: $id) {
			id
			externalLogs {
				requestUrl
				requestBody
				responseStatusCode
				responseBody
				elapsedTime
				created
			}
		}
	}
`;

export const FETCH_EXTERNAL_LOGS = gql`
	query fetchExternalLogs($id: Int) {
		order(id: $id) {
			id
			externalLogs {
				requestUrl
				requestBody
				responseStatusCode
				responseBody
				purpose
				created
			}
		}
	}
`;

export const GET_BIZ_CHANNELS = gql`
	query getBizChannels($includeHubChannels: Boolean, $includeUrbanpiper: Boolean, $includeInternalChannels: Boolean) {
		bizChannels(
			includeHubChannels: $includeHubChannels
			includeUrbanpiper: $includeUrbanpiper
			includeInternalChannels: $includeInternalChannels
		) {
			valueForDisplay
			value
		}
	}
`;

export const GET_PAYOUT_ORDER = gql`
	query getPayoutOrder($id: Int) {
		payoutOrder(id: $id) {
			id
			upId
			externalId
			reconciliationStatus
			inconsistencies {
				key
				value
			}
			statusUpdates {
				created
				reconciliationState
				message
			}
			orderSubtotal
			orderTotal
			totalTaxes
			totalCharges
			discount
			aggregatorCommissionPercentage
			aggregatorCommission
			aggregatorCommissionTax
			aggregatorCommissionTotal
			orderLevelAdjustments
			tcs
			tds
			netPayable
			aggregatorDeductionSubfields {
				key
				value
			}
			orderAdjustmentSubfields {
				key
				value
			}
			platformManager
			aggregatorCommissionValue
		}
	}
`;

export const UPDATE_RECONCILIATION_STATUS = gql`
	mutation updateReconciliationStatus($id: Int!, $reconciliationStatus: String!, $reason: String!, $notes: String) {
		updateReconciliationStatus(
			input: { id: $id, reconciliationStatus: $reconciliationStatus, reason: $reason, notes: $notes }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
