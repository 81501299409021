import React, { useEffect, useState } from "react";
import { client } from "../../client";
import { ASSOCIATE_DISOCIATE_SUBCATEGORIES, GET_SUBCATEGORIES_LIST } from "../../graphql/categories";
import { store } from "../../store/configureStore";
import { Button } from "../_commons/Button";

import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";
import { Paginator } from "../_commons/Paginator";
import { TRACKING_EVENT_NAMES, TRACKING_SOURCE, TRACKING_STATUS } from "../../client-config";
import { trackEvent } from "../../atlas-utils";

const columns = [
	{
		title: "Name",
		dataIndex: "name",
		render: (data) => data
	},
	{
		title: "Category",
		dataIndex: "parentCategory",
		render: (data) => data.name || "--"
	}
];

const SubcategoriesAssociationDrawer = ({ isOpen, close, categoryId }) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedCategories, setSelectedCategories] = useState({});
	const [allFieldsSelected, setAllFieldsSelected] = useState(false);
	const [offset, setOffset] = useState(0);

	const fetchSubcategoriesList = async () => {
		if (!categoryId) {
			return;
		}

		try {
			setLoading(true);
			const variables = {
				parent: parseInt(categoryId),
				limit: 10,
				offset
			};
			const resp = await client.query({
				query: GET_SUBCATEGORIES_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			if (resp?.data?.unassociatedSubCategories) {
				setData(resp?.data?.unassociatedSubCategories);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};

	const updateSubcategories = async () => {
		const eventMeta = {
			source: TRACKING_SOURCE.SUB_CATEGORIES_TAB
		};

		try {
			const variables = {
				parentCategoryId: parseInt(categoryId),
				subCategoryIdsToAssociate: Object.keys(selectedCategories).map((id) => parseInt(id)),
				subCategoryIdsToDisassociate: []
			};

			const resp = await client.mutate({
				mutation: ASSOCIATE_DISOCIATE_SUBCATEGORIES,
				variables
			});

			if (resp?.data?.updateSubcategoryAssociation?.status?.success) {
				eventMeta.success = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.CATEGORY_SUBCATEGORY_UPDATE, eventMeta);

				handleClose(true);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Successfully associated categories",
						timeout: 3000,
						error: false
					}
				});
			} else {
				eventMeta.success = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.CATEGORY_SUBCATEGORY_UPDATE, eventMeta);

				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Failed to associate categories",
						timeout: 3000,
						error: true
					}
				});
			}
		} catch (e) {
			eventMeta.success = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.CATEGORY_SUBCATEGORY_UPDATE, eventMeta);

			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Failed to associate categories",
					timeout: 3000,
					error: true
				}
			});
			console.log(e);
		}
	};

	const handleAllFieldsSelection = (state) => {
		if (!state) {
			setSelectedCategories({});
			setAllFieldsSelected(false);
			return;
		}

		if (!data?.objects) {
			return;
		}

		const updatedSelectedCategories = {};
		data.objects.forEach((category) => {
			updatedSelectedCategories[category?.id] = true;
		});
		setAllFieldsSelected(true);
		setSelectedCategories(updatedSelectedCategories);
	};

	const handleSingleFieldSelection = (id) => {
		const updatedSelectedCategories = {
			...selectedCategories
		};

		if (selectedCategories[id]) {
			delete updatedSelectedCategories[id];
			setSelectedCategories(updatedSelectedCategories);
			if (allFieldsSelected) {
				setAllFieldsSelected(false);
			}
			return;
		}

		updatedSelectedCategories[id] = true;
		setSelectedCategories(updatedSelectedCategories);
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
	};

	const handleClose = (success = false) => {
		setData(null);
		setOffset(0);
		setSelectedCategories({});
		close(success);
	};

	useEffect(() => {
		if (isOpen) {
			fetchSubcategoriesList();
		}
	}, [isOpen, offset]);

	return (
		<FormSidebar
			isOpen={isOpen}
			isNested
			close={handleClose}
			hideActions
			title="Associate Subcategories"
			subTitle={`Associate subcategories to this category`}
			formSidebarClasses="subcategories-association--sidebar"
			headerRight={
				<Button
					classes={!!!Object.keys(selectedCategories).length ? "disabled" : ""}
					loading={loading}
					clickHandler={updateSubcategories}
				>
					{Object.keys(selectedCategories).length > 0
						? `Update ${Object.keys(selectedCategories).length} ${
								Object.keys(selectedCategories).length > 1 ? "sub-categories" : "sub-category"
						  }`
						: "Update"}
				</Button>
			}
		>
			<FormTable
				columns={columns}
				isLoading={loading}
				dataSource={data?.objects}
				isSelectionEnabled
				handleAllFieldsSelection={handleAllFieldsSelection}
				handleSingleFieldSelection={handleSingleFieldSelection}
				selectedFields={selectedCategories}
				isAllFieldSelected={allFieldsSelected}
			/>
			<Paginator count={data?.count} goToPage={handlePagination} limit={10} offset={offset} readOnly={loading} />
		</FormSidebar>
	);
};
export default SubcategoriesAssociationDrawer;
