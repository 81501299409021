import gql from "graphql-tag";

export const GET_USER_INVITE_DETAILS = gql`
	query getUserInviteDetails($uuid: String!) {
		userInviteDetails(inviteUuid: $uuid) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				email
				roles
				bizIsdCodes {
					code
					name
					default
				}
			}
		}
	}
`;

export const USER_INVITE_OTP_GENERATION = gql`
	mutation otpGeneration($uuid: String!, $field: String!, $value: String!) {
		userInviteOtpGeneration(input: { uuid: $uuid, field: $field, value: $value }) {
			otp
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const USER_INVITE_OTP_VALIDATION = gql`
	mutation otpValidation($uuid: String!, $field: String!, $value: String!, $otp: String!) {
		userInviteOtpValidation(input: { uuid: $uuid, field: $field, value: $value, otp: $otp }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const SAVE_USER_PROFILE = gql`
	mutation saveUserProfile(
		$uuid: String!
		$firstName: String!
		$lastName: String
		$username: String!
		$password: String!
		$confirmPassword: String!
	) {
		saveUser(
			input: {
				uuid: $uuid
				firstName: $firstName
				lastName: $lastName
				username: $username
				password: $password
				confirmPassword: $confirmPassword
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const VERIFY_EXISTING_USER_PROFILE = gql`
	mutation verifyExistingUserProfile($input: ExistingUserInput) {
		updateExistingUser(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
