// third party
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { withScope, captureException } from "@sentry/browser";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";

// clients
import { store } from "./store/configureStore";

// utils
import { lS } from "./atlas-utils";
import { logout } from "./components/SiteComp";

// actions
import { updateBizState } from "./actions/actions";

// constants
const API_URL = process.env.REACT_APP_API_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const ENV = process.env.NODE_ENV;

// initial state for apollo cache
const defaults = {};

// setup network links
const uploadLink = createUploadLink({ uri: API_URL });
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	// also update biz state if biz ids don't match in local storage and redux store
	let auth = lS.get("auth");
	const login = store.getState().login;
	if (auth && login?.loggedInbizDetail && auth?.biz?.id !== login?.loggedInbizDetail?.id) {
		updateBizState(login);
	}
	return {
		headers: {
			...headers,
			Authorization: auth && `Bearer ${auth.token}`
		}
	};
});
const errorLink = onError((response) => {
	console.log(response);
	if (
		response.graphQLErrors &&
		response.graphQLErrors.length > 0 &&
		(response.graphQLErrors[0].message == "Invalid Token" ||
			response.graphQLErrors[0].message == "Token Expired" ||
			response.graphQLErrors[0].message == "User Is Not A Sub-Administrator Of Any Biz")
	) {
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: "Your session has been expired!",
				timeout: 5000,
				error: true
			}
		});
		logout();
	} else if (
		response.graphQLErrors &&
		response.graphQLErrors.length > 0 &&
		response.graphQLErrors[0].message == "Not Allowed"
	) {
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: "You do not have permission/access for this action/view.",
				timeout: 5000,
				error: true
			}
		});
	} else if (
		ENV === "production" &&
		response.graphQLErrors &&
		response.graphQLErrors?.length > 0 &&
		response.graphQLErrors?.[0]?.message !== "Object Does Not Exist"
	) {
		// add graphql query, variables and biz info under "Additional Data" section in Sentry
		const login = store.getState().login;
		withScope((scope) => {
			scope.setExtra("Graphql query", response.operation.query.loc.source.body);
			scope.setExtra("Variables", response.operation.variables);
			if (login?.loggedInbizDetail?.id) {
				scope.setExtra("Biz", {
					id: login?.loggedInbizDetail?.id,
					name: login?.loggedInbizDetail?.name
				});
			}
			captureException(response.graphQLErrors);
		});
	}
});

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	clientState: {
		defaults
	},
	link: ApolloLink.from([authLink, errorLink, uploadLink])
});

window.client = client;
window.API_URL = API_URL;
window.RECAPTCHA_SITE_KEY = RECAPTCHA_SITE_KEY;
