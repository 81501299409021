import { store } from "../store/configureStore";
import { client } from "../client";

// graphql
import { GET_BIZ_INFO, UPDATE_BIZ_INFO, UPDATE_BIZ_LOGO } from "../graphql/misc";
import { ActionTypes } from "./_types";
import { toggleGlobalLoader } from "../actions/actions";

// actions
import { setCustomBannerMessage } from "../actions/actions";
import { lS, removeProp, snakiseObject } from "../atlas-utils";

export const getAndSetBizInfo = async (updateBanner = false) => {
	try {
		let resp = await client.query({
			query: GET_BIZ_INFO,
			fetchPolicy: "network-only"
		});
		store.dispatch({
			type: ActionTypes.GET_OUTSTANDING_INVOICES_REQUEST
		});
		// need to update both localstorage and store
		let loginDataInStore = { ...store.getState().login };
		loginDataInStore.loggedInbizDetail = resp.data.biz;

		let loginDataInStorage = window.lS.get("auth");
		loginDataInStorage.biz = resp.data.biz;
		window.lS.set("auth", loginDataInStorage);
		store.dispatch({
			type: ActionTypes.LOGIN_SUCCESS,
			payload: loginDataInStore
		});
		store.dispatch({
			type: ActionTypes.GET_OUTSTANDING_INVOICES_SUCESS,
			payload: resp.data?.invoices || {}
		});

		if (updateBanner) {
			setCustomBannerMessage(resp.data.biz, loginDataInStore?.loginDetail);
		}
		return resp.data.biz;
	} catch (err) {
		console.log(err);
		store.dispatch({
			type: ActionTypes.GET_OUTSTANDING_INVOICES_FAILURE
		});
	}
};

export const updateGetAndSetBizInfo = async (props, showGlobalMessage = true, logo = false) => {
	store.dispatch({
		type: ActionTypes.UPDATE_LOGGEDIN_BIZ_REQUEST
	});
	store.dispatch(toggleGlobalLoader(true));
	try {
		let resp = await client.mutate({
			mutation: logo ? UPDATE_BIZ_LOGO : UPDATE_BIZ_INFO,
			variables: props,
			fetchPolicy: "no-cache"
		});
		let status = resp.data.saveBiz.status;
		let biz = resp.data.saveBiz.object;
		if (status.success) {
			//need to update both localstorage and store
			let loginDataInStore = { ...store.getState().login };
			loginDataInStore.loggedInbizDetail = biz;
			store.dispatch({
				type: ActionTypes.UPDATE_LOGGEDIN_BIZ_SUCCESS,
				payload: loginDataInStore
			});
			let loginDataInStorage = window.lS.get("auth");
			loginDataInStorage.biz = biz;
			window.lS.set("auth", loginDataInStorage);
			store.dispatch(toggleGlobalLoader(false));
			if (showGlobalMessage) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Saved changes",
						timeout: 5000,
						error: false
					}
				});
			}
			return true;
		} else {
			store.dispatch(toggleGlobalLoader(false));
			store.dispatch({
				type: ActionTypes.UPDATE_LOGGEDIN_BIZ_FAILURE,
				error: true
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Oops! Something went wrong",
					timeout: 3000,
					error: true
				}
			});
		}
	} catch (err) {
		store.dispatch(toggleGlobalLoader(false));
		console.log(err);
		store.dispatch({
			type: ActionTypes.UPDATE_LOGGEDIN_BIZ_FAILURE,
			error: true
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Oops! Something went wrong",
				timeout: 3000,
				error: true
			}
		});
	}
};
