import React from "react";

// component
import { Button } from "./Button";

// utils
import history from "../../history";
import MultiActionsButton from "./MultiActionsButton";

const Placeholder = ({
	placeholderImageUrl,
	placeholderText,
	placeholderButtonClickAction,
	placeholderButtonContent,
	redirectionLink,
	redirectionLinkText,
	placeholderSubtext = "",
	size = "small",
	secondaryActionTitle = "",
	secondaryAction = () => {},
	placeholderCustomClasses = "",
	secondaryButtonContent = "",
	secondaryButtonClickAction = () => {},
	readOnly = false,
	placeholderMultiActionButtonTitle,
	placeholderMultiActionButtonActions,
	placeholderMultiActionButtonInstantAction = () => {}
}) => {
	const handleRedirectionClick = () => {
		history.push(redirectionLink);
	};
	return (
		<div className={`placeholder-main ${size} ${placeholderCustomClasses}`}>
			{placeholderImageUrl && (
				<div className="placeholder--image-container">
					<img src={placeholderImageUrl} alt={placeholderText || "No entries found"} />
				</div>
			)}
			<div className="placeholder--text">{placeholderText || "No entries found!"}</div>
			{placeholderSubtext && <div className="placeholder--sub-text">{placeholderSubtext}</div>}
			{placeholderButtonContent && (
				<Button
					clickHandler={() => placeholderButtonClickAction()}
					classes={readOnly ? "placeholder--action-btn disabled" : "placeholder--action-btn"}
				>
					{placeholderButtonContent}
				</Button>
			)}
			{placeholderMultiActionButtonTitle && (
				<MultiActionsButton
					classes={readOnly ? "placeholder--action-btn disabled" : "placeholder--action-btn"}
					title={placeholderMultiActionButtonTitle}
					actions={placeholderMultiActionButtonActions}
					instantAction={() => placeholderMultiActionButtonInstantAction()}
				/>
			)}
			{secondaryButtonContent && (
				<Button
					clickHandler={() => secondaryButtonClickAction()}
					classes="placeholder--action-btn"
					type="secondary"
				>
					{secondaryButtonContent}
				</Button>
			)}
			{redirectionLink && redirectionLinkText && (
				<div className="placeholder--redirection-link">
					<img src="/assets/icons/icon-learn-more.svg" className="learn-more-img" />
					<div className="placeholder--link-content">
						Have questions?&nbsp;
						<span onClick={handleRedirectionClick} className="placeholder--link-text">
							{redirectionLinkText || "Learn more"}
						</span>
					</div>
				</div>
			)}
			{secondaryActionTitle && (
				<div className="placeholder--redirection-link">
					<span className="placeholder--link-text" onClick={secondaryAction}>
						{secondaryActionTitle}
					</span>
				</div>
			)}
		</div>
	);
};
export default Placeholder;
