import React, { useEffect, useState } from "react";

// third party
// import { useSwipeable } from "react-swipeable";

const Carousel = ({ children }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [paused, setPaused] = useState(false);

	const handleCarousel = (newIndex) => {
		if (newIndex < 0) {
			newIndex = React.Children.count(children) - 1;
		} else if (newIndex >= React.Children.count(children)) {
			newIndex = 0;
		}
		setActiveIndex(newIndex);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (!paused) {
				handleCarousel(activeIndex + 1);
			}
		}, 5000);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	});

	// const handlers = useSwipeable({
	// 	onSwipedLeft: () => handleCarousel(activeIndex + 1),
	// 	onSwipedRight: () => handleCarousel(activeIndex - 1),
	// });

	return (
		<div
			// {...handlers}
			className="carousel-container"
			onMouseEnter={() => setPaused(true)}
			onMouseLeave={() => setPaused(false)}
		>
			<div className="btn-icon prev" onClick={() => handleCarousel(activeIndex - 1)}>
				<img src="/assets/icons/icon-left-arrow.svg" alt="" />
			</div>
			<div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
				{React.Children.map(children, (child, index) => {
					return React.cloneElement(child, { width: "100%" });
				})}
			</div>
			<div className="btn-icon next" onClick={() => handleCarousel(activeIndex + 1)}>
				<img src="/assets/icons/icon-right-arrow.svg" alt="" />
			</div>
			<div className="carousel-indicator-container">
				{React.Children.map(children, (child, index) => {
					return (
						<div
							className={"indicator" + (index === activeIndex ? " active" : "")}
							onClick={() => handleCarousel(index)}
						></div>
					);
				})}
			</div>
		</div>
	);
};
export default Carousel;

export const CarouselItem = ({ children }) => {
	return <div className="carousel-item-container">{children}</div>;
};
