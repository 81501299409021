import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import { Loading } from "../_commons/Loading";
import { ProgressBar } from "../_commons/ProgressBar";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import camelcase from "camelcase";

// utils
import { lS, trackEvent } from "../../atlas-utils";

// graphql
import { CREATE_LOCATION } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";
import { handleNotification } from "../../actions/notifications";

// constants
import { CREATE_LOCATION_STATUS_INITIAL_STATE } from "../../reducers/locations";
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../../client-config";
const LOCATION_CREATE_STATUS_URL = process.env.REACT_APP_LOCATION_CREATE_WIZARD_URL;

export const FORM_FIELDS_MAP = {
	externalId: "platformId",
	outletUrl: "platformUrl"
};

const LocationCreateSuccess = ({
	formData,
	validations = {},
	bizId,
	setValidations,
	resetCreateLocation,
	isMulti = false,
	referenceId = "",
	setReferenceId,
	handleFixErrors,
	createLocationStatus,
	isMultibrandEnabled = false
}) => {
	const [loading, setLoading] = useState(false);
	const [errorsFound, setErrorsFound] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const { status, reference_id, total_locations, processed_locations, created_locations, restrict } =
		createLocationStatus;
	const exponentialBackOffTimeoutRef = useRef();
	const maxTimeoutLimit = 128;
	let timeout = 1;

	const checkAndUpdateLocationCreateStatus = async (referenceId) => {
		try {
			const url = `${LOCATION_CREATE_STATUS_URL}/?reference_id=${referenceId}`;
			const token = store.getState().login.loginDetail.token;
			const resp = await fetch(url, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			const data = await resp.json();
			const bizId = store.getState().login?.loggedInbizDetail?.id;
			if (data.status === "SUCCESS") {
				// get last saved notification from local storage
				const lastNotification = lS.get("notifications")
					? (lS.get("notifications")?.[bizId] || [])?.slice(0, 1)
					: [];
				const payload = lastNotification?.[0]?.payload;
				const locations =
					(data.locations_data ?? [])?.map((location) => ({
						id: location.biz_location_id,
						name: location.biz_location_nickname
					})) || [];
				// simulate ws notification
				const newNotification = {
					payload: {
						entity: "create-root-location",
						status: data.status,
						reference_id: referenceId,
						total_locations:
							lS.get("create_location_wizard")[bizId]?.data?.locationAddType === "single"
								? 1
								: lS.get("create_location_wizard")[bizId]?.data?.multiLocationDetails?.length || 0,
						processed_locations: data?.locations_data?.length || 0,
						created_locations: locations || []
					},
					message: "Location(s) has been created successfully",
					success: true,
					overrideHide:
						payload?.entity === "create-root-location" &&
						payload?.reference_id === referenceId &&
						payload?.status === "SUCCESS"
							? true
							: false
				};
				handleNotification(newNotification);
			} else if (data.status === "QUEUED" || data.status === "PROCESSING") {
				if (timeout <= maxTimeoutLimit && data.status === "QUEUED") {
					// simulate ws notification
					const newNotification = {
						payload: {
							entity: "create-root-location",
							status: data.status,
							reference_id: referenceId
						},
						message: "RootLocation rqst queued",
						success: true
					};
					handleNotification(newNotification);

					exponentialBackOffTimeoutRef.current = setTimeout(() => {
						checkAndUpdateLocationCreateStatus(referenceId);
					}, timeout * 1000);
					timeout *= 2;
				} else if (timeout <= maxTimeoutLimit && data.status === "PROCESSING") {
					if (data.locations_data) {
						const locations =
							(data.locations_data ?? [])?.map((location) => ({
								id: location.biz_location_id,
								name: location.biz_location_nickname
							})) || [];
						const lastLocationCreated = locations?.slice(-1) || [];
						// simulate ws notification
						const newNotification = {
							payload: {
								entity: "create-root-location",
								status: data.status,
								reference_id: referenceId,
								id: lastLocationCreated?.[0]?.id,
								name: lastLocationCreated?.[0]?.name,
								total_locations:
									lS.get("create_location_wizard")[bizId]?.data?.locationAddType === "single"
										? 1
										: lS.get("create_location_wizard")[bizId]?.data?.multiLocationDetails?.length ||
										  0,
								processed_locations: data?.locations_data?.length || 0,
								created_locations: locations || []
							},
							message: "BizLocation is created",
							success: true
						};
						handleNotification(newNotification);
					}

					exponentialBackOffTimeoutRef.current = setTimeout(() => {
						checkAndUpdateLocationCreateStatus(referenceId);
					}, timeout * 1000);
					timeout *= 2;
				} else {
					const body = {
						reference_id: referenceId,
						action: "CANCEL"
					};
					const resp = fetch(LOCATION_CREATE_STATUS_URL, {
						method: "POST",
						body: JSON.stringify(body),
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					const data = resp.json();
					if (data.status === "PROCESSING") {
						timeout = 1;
						exponentialBackOffTimeoutRef.current = setTimeout(() => {
							checkAndUpdateLocationCreateStatus(referenceId);
						}, timeout * 1000);
					} else if (data.status === "SUCCESS") {
						// get last saved notification from local storage
						const lastNotification = lS.get("notifications")
							? (lS.get("notifications")?.[bizId] || [])?.slice(0, 1)
							: [];
						const payload = lastNotification?.[0]?.payload;
						const locations =
							(data.locations_data ?? [])?.map((location) => ({
								id: location.biz_location_id,
								name: location.biz_location_nickname
							})) || [];
						// simulate ws notification
						const newNotification = {
							payload: {
								entity: "create-root-location",
								status: data.status,
								reference_id: referenceId,
								total_locations:
									lS.get("create_location_wizard")[bizId]?.data?.locationAddType === "single"
										? 1
										: lS.get("create_location_wizard")[bizId]?.data?.multiLocationDetails?.length ||
										  0,
								processed_locations: data?.locations_data?.length || 0,
								created_locations: locations || []
							},
							message: "Location(s) has been created successfully",
							success: true,
							overrideHide:
								payload?.entity === "create-root-location" &&
								payload?.reference_id === referenceId &&
								payload?.status === "SUCCESS"
									? true
									: false
						};
						handleNotification(newNotification);
					} else {
						// simulate ws notification
						const newNotification = {
							payload: {
								entity: "create-root-location",
								status: "FAILED",
								reference_id: referenceId
							},
							message: "Location(s) create failed",
							success: true
						};
						handleNotification(newNotification);
					}
				}
			} else {
				// simulate ws notification
				const newNotification = {
					payload: {
						entity: "create-root-location",
						status: "FAILED",
						reference_id: referenceId
					},
					message: "Location(s) create failed",
					success: true
				};
				handleNotification(newNotification);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong, failed to add new locations.",
					timeout: 3000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleSubmit = useCallback(async () => {
		setLoading(true);
		const variables = { bizLocation: [] };
		if (!isMultibrandEnabled) {
			variables.copyCatalogue = formData?.copyCatalogue?.id
				? { fromLocation: parseInt(formData?.copyCatalogue?.id) }
				: null;
		}
		if (isMulti) {
			formData.multiLocationDetails.forEach((loc) => {
				const {
					bizLocationNickname,
					city,
					bizAddress,
					primaryContactPhone,
					primaryContactEmail,
					sortOrder,
					merchantBizLocationId,
					brands,
					platforms,
					locLatitude,
					locLongitude,
					orderDeliveryRadius,
					polygons,
					isMerakiDetailsSet
				} = loc;
				const location = {
					bizLocationNickname,
					city: city.value,
					bizAddress,
					primaryContactPhone,
					primaryContactEmail,
					sortOrder,
					merchantBizLocationId
				};
				if (isMerakiDetailsSet) {
					location.locLatitude = locLatitude;
					location.locLongitude = locLongitude;
					location.orderDeliveryRadius = orderDeliveryRadius;
					location.polygons = polygons;
				}
				if (isMultibrandEnabled) {
					location.brands = brands.map((brnd) => {
						const brand = {};
						brand.brandId = parseInt(brnd.id);
						brand.copyCatalogue = brnd?.copyCatalogue?.id
							? { fromLocation: parseInt(brnd.copyCatalogue.id) }
							: null;
						brand.platforms = brnd.platforms.map((plf) => ({
							platformName: plf.platformName?.split(" ")?.join("")?.toLowerCase(),
							externalId: plf.platformId,
							outletUrl: plf.platformUrl
						}));
						return brand;
					});
				} else {
					location.platforms = platforms.map((plf) => ({
						platformName:
							plf.platformName?.toLowerCase() === "meraki"
								? "urbanpiper"
								: plf.platformName?.split(" ")?.join("")?.toLowerCase(),
						externalId: plf.platformId || null,
						outletUrl: plf.platformUrl || null
					}));
				}
				variables.bizLocation.push(location);
			});
		} else {
			const {
				bizLocationNickname,
				city,
				bizAddress,
				primaryContactPhone,
				primaryContactEmail,
				sortOrder,
				merchantBizLocationId,
				brands,
				platforms,
				locLatitude,
				locLongitude,
				orderDeliveryRadius,
				polygons,
				isMerakiDetailsSet
			} = formData;
			const location = {
				bizLocationNickname,
				city: city.value,
				bizAddress,
				primaryContactPhone,
				primaryContactEmail,
				sortOrder,
				merchantBizLocationId
			};
			if (isMerakiDetailsSet) {
				location.locLatitude = locLatitude;
				location.locLongitude = locLongitude;
				location.orderDeliveryRadius = orderDeliveryRadius;
				location.polygons = polygons;
			}
			if (isMultibrandEnabled) {
				location.brands = brands.map((brnd) => {
					const brand = {};
					brand.brandId = parseInt(brnd.id);
					brand.copyCatalogue = brnd?.copyCatalogue?.id
						? { fromLocation: parseInt(brnd.copyCatalogue.id) }
						: null;
					brand.platforms = brnd.platforms.map((plf) => ({
						platformName: plf.platformName?.split(" ")?.join("")?.toLowerCase(),
						externalId: plf.platformId,
						outletUrl: plf.platformUrl
					}));
					return brand;
				});
			} else {
				location.platforms = platforms.map((plf) => ({
					platformName:
						plf.platformName?.toLowerCase() === "meraki"
							? "urbanpiper"
							: plf.platformName?.split(" ")?.join("")?.toLowerCase(),
					externalId: plf.platformId || null,
					outletUrl: plf.platformUrl || null
				}));
			}
			variables.bizLocation.push(location);
		}
		try {
			// update total_locations in create location status state
			store.dispatch({
				type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
				payload: {
					total_locations: formData.locationAddType === "single" ? 1 : formData.multiLocationDetails.length
				}
			});
			const resp = await client.mutate({
				mutation: CREATE_LOCATION,
				variables
			});
			if (resp.data.createBrandLocations?.status?.success) {
				// update create location status
				trackEvent(TRACKING_EVENT_NAMES.NEW_LOCATIONS_CREATED, {
					status: TRACKING_STATUS.SUCCESS
				});

				store.dispatch({
					type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
					payload: {
						status: "QUEUED",
						reference_id: resp.data.createBrandLocations?.referenceId,
						total_locations:
							formData.locationAddType === "single" ? 1 : formData.multiLocationDetails.length,
						processed_locations: 0,
						restrict: true
					}
				});
				setReferenceId(resp.data.createBrandLocations?.referenceId);
				// update reference id in local storage
				lS.set("create_location_wizard", {
					...(lS.get("create_location_wizard") ?? {}),
					[bizId]: {
						...(lS.get("create_location_wizard")?.[bizId] ?? {}),
						referenceId: resp.data.createBrandLocations?.referenceId
					}
				});
				checkAndUpdateLocationCreateStatus(resp.data.createBrandLocations?.referenceId);
			} else {
				trackEvent(TRACKING_EVENT_NAMES.NEW_LOCATIONS_CREATED, {
					status: TRACKING_STATUS.SUCCESS
				});

				if (resp.data.createBrandLocations?.status?.messages.length) {
					const errors = resp.data.createBrandLocations?.status?.messages;
					let updatedValidations = { ...validations };
					let foundErrors = true;
					errors.forEach((error) => {
						if (error.field) {
							let field = error.field.split("#");
							if (field.length === 2) {
								const f = FORM_FIELDS_MAP[camelcase(field[1])] || camelcase(field[1]);
								if (isMulti) {
									updatedValidations[field[0]][f] = error.message;
								} else {
									updatedValidations[f] = error.message;
								}
							} else if (field.length === 3) {
								const f = FORM_FIELDS_MAP[camelcase(field[2])] || camelcase(field[2]);
								if (isMulti) {
									updatedValidations = {
										...updatedValidations,
										[field[0]]: {
											...(updatedValidations?.[field[0]] ?? {}),
											platforms: {
												...(updatedValidations?.[field[0]]?.platforms ?? {}),
												[formData?.multiLocationDetails?.[field[0]]?.platforms?.[field[1]]?.id]:
													{
														...(updatedValidations?.[field[0]]?.platforms?.[
															formData?.multiLocationDetails?.[field[0]]?.platforms?.[
																field[1]
															]?.id
														] ?? {}),
														[f]: error.message
													}
											}
										}
									};
								} else {
									updatedValidations.platforms = {
										...(updatedValidations?.platforms ?? {}),
										[formData?.platforms?.[field[1]]?.id]: {
											...(updatedValidations?.platforms?.[formData?.platforms?.[field[1]]?.id] ??
												{}),
											[f]: error.message
										}
									};
								}
							} else if (field.length === 4) {
								const f = FORM_FIELDS_MAP[camelcase(field[3])] || camelcase(field[3]);
								if (isMulti) {
									updatedValidations = {
										...updatedValidations,
										[field[0]]: {
											...(updatedValidations?.[field[0]] ?? {}),
											platforms: {
												...(updatedValidations?.[field[0]]?.platforms ?? {}),
												[formData?.multiLocationDetails?.[field[0]]?.brands?.[field[1]]?.id]: {
													...(updatedValidations?.[field[0]]?.platforms?.[
														formData?.multiLocationDetails?.[field[0]]?.brands?.[field[1]]
															?.id
													] ?? {}),
													[formData?.multiLocationDetails?.[field[0]]?.brands?.[field[1]]
														?.platforms?.[field[2]]?.id]: {
														...(updatedValidations?.[field[0]]?.platforms?.[
															formData?.multiLocationDetails?.[field[0]]?.brands?.[
																field[1]
															]?.platforms?.[field[2]]?.id
														] ?? {}),
														[f]: error.message
													}
												}
											}
										}
									};
								} else {
									updatedValidations.platforms = {
										...(updatedValidations?.platforms ?? {}),
										[formData?.brands?.[field[1]]?.id]: {
											...(updatedValidations?.platforms?.[formData?.brands?.[field[1]]?.id] ??
												{}),
											[formData?.brands?.[field[1]]?.platforms?.[field[2]]?.id]: {
												...(updatedValidations?.platforms?.[
													formData?.brands?.[field[1]]?.platforms?.[field[2]]?.id
												] ?? {}),
												[f]: error.message
											}
										}
									};
								}
							}
							foundErrors = true;
						}
					});
					if (foundErrors) {
						setValidations(updatedValidations);
						setErrorsFound(true);
					}
				}
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.NEW_LOCATIONS_CREATED, {
				status: TRACKING_STATUS.SUCCESS
			});

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong, failed to add new locations.",
					timeout: 3000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [formData, validations, setValidations, isMulti, setReferenceId, bizId, isMultibrandEnabled]);

	const handleReset = useCallback(() => {
		const { status } = store.getState().createLocationStatus;
		if (status === "SUCCESS" || status === "FAILED") {
			// reset create location status
			store.dispatch({
				type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
				payload: { ...CREATE_LOCATION_STATUS_INITIAL_STATE }
			});
			setSelectedLocation(null);
			resetCreateLocation();
		}
	}, [status, resetCreateLocation]);

	useEffect(() => {
		if (!referenceId) {
			handleSubmit();
		} else {
			checkAndUpdateLocationCreateStatus(referenceId);
		}
		return () => {
			handleReset();
		};
	}, []);

	useEffect(() => {
		if (status === "SUCCESS" && total_locations > 1) {
			setSelectedLocation(created_locations[0]);
		}
	}, [status]);

	if (loading) {
		return (
			<div className="location-create-queued">
				<Loading />
				<div className="message">Checking your request and validating filled details...</div>
			</div>
		);
	}
	if (!loading && errorsFound) {
		return (
			<div className="location-create-errors">
				<img className="icon-alert" src="/assets/icons/icon-red-alert.svg" alt="" />
				<div className="message">Errors found, please fix them and try again.</div>
				<div className="actions">
					<Button clickHandler={handleFixErrors}>Fix Errors</Button>
				</div>
			</div>
		);
	}
	if (!loading && restrict && status === "QUEUED") {
		return (
			<div className="location-create-processing">
				<div className="message">
					Your request to create new {total_locations > 1 ? "locations" : "location"} is queued.
				</div>
				<ProgressBar
					maximum={total_locations}
					currProgress={processed_locations}
					animated={true}
					showProgress={true}
					entity="Locations created"
				/>
			</div>
		);
	}
	if (!loading && restrict && status === "PROCESSING") {
		return (
			<div className="location-create-processing">
				<div className="message">Creating your {total_locations > 1 ? "locations" : "location"}...</div>
				<ProgressBar
					maximum={total_locations}
					currProgress={processed_locations}
					animated={true}
					showProgress={true}
					entity="Locations created"
				/>
			</div>
		);
	}
	if (!loading && !restrict && status === "SUCCESS") {
		return (
			<div className="location-create-success">
				<img className="success-gif" src="/assets/success-popup.gif" />
				<img className="icon-check" src="/assets/icons/icon-check.svg" alt="" />
				<div className="message">
					{total_locations > 1
						? "You have successfully added new locations!"
						: "You have successfully added a new location!"}
				</div>
				{total_locations > 1 ? (
					<div className="select-location">
						<SelectFilter
							options={created_locations}
							currValue={selectedLocation}
							field="selectedLocation"
							setFilter={(field, value) => setSelectedLocation(value)}
							labelKey="name"
							valueKey="id"
							isSearchable={true}
							isClearable={false}
						/>
						<div className="actions">
							<Link to={`/locations/edit/${selectedLocation?.id}`}>
								<Button>Go to Location</Button>
							</Link>
							<Link to="/locations">
								<Button type="secondary">Close</Button>
							</Link>
						</div>
					</div>
				) : (
					<div className="actions">
						<Link to={`/locations/edit/${created_locations[0]?.id}`}>
							<Button>Go to Location</Button>
						</Link>
						<Link to="/locations">
							<Button type="secondary">Close</Button>
						</Link>
					</div>
				)}
			</div>
		);
	}
	if (!loading && !restrict && status === "FAILED") {
		return (
			<div className="location-create-failed">
				<img className="icon-cross" src="/assets/icons/icon-cross.svg" alt="" />
				<div className="message">Failed to add new {total_locations > 1 ? "locations" : "location"}.</div>
				<div className="actions">
					<Link to="/locations">
						<Button>Close</Button>
					</Link>
				</div>
			</div>
		);
	}
	return (
		<div className="location-create-failed">
			<img className="icon-cross" src="/assets/icons/icon-cross.svg" alt="" />
			<div className="message">Oops! Something went wrong.</div>
			<div className="actions">
				<Link to="/locations">
					<Button>Close</Button>
				</Link>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	createLocationStatus: store.createLocationStatus
});
export default connect(mapStateToProps)(LocationCreateSuccess);
