import React, { useState, useEffect, useCallback } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";
import { Switch } from "../components/_commons/Switch";
import { Button } from "../components/_commons/Button";
import { CheckBox } from "../components/_commons/CheckBox";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isValidPhoneNumber } from "libphonenumber-js";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, trackEvent } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchUsersList } from "../actions/unifiedUsers";
import { fetchPendingInvitesList } from "../actions/unifiedInvites";

// graphql
import { GET_INVITE_DETAILS, INVITE_USER } from "../graphql/unifiedUsers";

// assets
import CreateIcon from "../components/_commons/CreateIcon";
import { ButtonIcon } from "../components/_commons/ButtonIcon";

const UnifiedUserInvite = ({ user, biz, currTab }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isInviteSuccess, setInviteSuccess] = useState({
		uuid: "",
		status: false
	});
	const [bizIsdCodes, setBizIsdCodes] = useState([]);
	const [emailPhoneValidation, setEmailPhoneValidation] = useState("");
	const [data, setData] = useState({
		invitedThrough: "email",
		value: "",
		isdCode: null,
		inviteData: {
			appsData: []
		}
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchInviteDetails();
	}, []);

	const fetchInviteDetails = async () => {
		setLoading(true);
		try {
			const resp = await client.query({
				query: GET_INVITE_DETAILS,
				variables: { api: false },
				fetchPolicy: "no-cache"
			});
			setData({
				...data,
				invitedThrough: "email",
				value: "",
				isdCode:
					resp.data.inviteClientDetails.bizIsdCodes.find((isd) => isd.default) ||
					resp.data.inviteClientDetails?.bizIsdCodes?.[0] ||
					null,
				inviteData: {
					appsData: resp.data.inviteClientDetails.objects.map((client) => ({
						...client,
						rolesOptions: client.roles,
						locationsOptions: client.locations,
						roles: client.appName === "atlas" ? [] : null,
						locations: [],
						rolesValidation: "",
						locationsValidation: "",
						allLocations: false,
						isActive: false
					}))
				}
			});
			setBizIsdCodes(
				resp.data.inviteClientDetails.bizIsdCodes.map((isd) => ({
					...isd,
					name: `${isd.name} (${isd?.code})`,
					country: isd.name
				}))
			);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const handleReset = async (reset = false) => {
		if (reset) {
			await fetchInviteDetails();
		}
		setFormTouched(false);
		setInviteSuccess({
			status: false,
			uuid: ""
		});
		setError({});
	};

	const handleCancel = () => {
		handleReset(false);
		setFormState(false);
		if (currTab === "users") {
			fetchUsersList();
		} else if (currTab === "invites") {
			fetchPendingInvitesList();
		}
		setTimeout(() => {
			history.push("/unified-access");
		}, 100);
	};

	const handleForm = (field, value, index = undefined) => {
		if (index !== undefined) {
			const updatedAppsData = data.inviteData.appsData.map((app, i) => {
				let appData = { ...app };
				if (i === index) {
					appData[field] = value;
					if (field === "isActive") {
						appData.rolesValidation = "";
						appData.locationsValidation = "";
					}
					if (field === "roles") {
						appData.rolesValidation = "";
					}
					if (field === "locations" || field === "allLocations") {
						appData.locationsValidation = "";
					}
				}
				return appData;
			});
			setData({
				...data,
				inviteData: {
					appsData: updatedAppsData
				}
			});
		} else {
			let updatedData = { ...data };
			updatedData[field] = value;
			if (field === "value") {
				if (value.includes("@") || value.slice(0, 2).length <= 1 || isNaN(value)) {
					updatedData.invitedThrough = "email";
				} else {
					updatedData.invitedThrough = "phone";
				}
				setEmailPhoneValidation("");
			}
			setData(updatedData);
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleEmailPhoneValidation = () => {
		let isValid = false;
		if (data.value) {
			if (data.invitedThrough === "phone") {
				isValid = isValidPhoneNumber(`${data?.isdCode?.code}${data?.value}`);
			} else {
				const emailRegex = /^\S+@\S+\.\S+$/;
				isValid = emailRegex.test(data.value);
			}
		}
		return isValid;
	};

	const handleOnBlur = () => {
		const isValid = handleEmailPhoneValidation();
		if (isValid) {
			setEmailPhoneValidation("");
		} else {
			setEmailPhoneValidation("Please enter valid email or mobile number");
		}
	};

	useEffect(() => {
		if (data?.isdCode?.code && data.value) {
			setTimeout(() => {
				handleOnBlur();
			}, 500);
		}
	}, [data.isdCode]);

	const handleSubmit = async () => {
		let activePlatforms = 0;
		let emptyRolesExist = false;
		let emptyLocationsExist = false;
		const updatedAppsData = data.inviteData.appsData.map((app) => {
			if (app.isActive) {
				activePlatforms += 1;
				if (app.appName === "atlas") {
					// handle roles validations
					if (app.roles.length === 0) {
						emptyRolesExist = true;
						app.rolesValidation = "This field is required.";
					}
					// handle locations validations
					if (
						app?.roles?.find((role) => role?.id === 53 || role?.id === 55 || role?.id === 66) !==
							undefined &&
						app.locations.length === 0 &&
						!app.allLocations
					) {
						emptyLocationsExist = true;
						app.locationsValidation = "This field is required.";
					}
				}
				if (app.appName === "prime") {
					// handle roles validations
					if (!app.roles) {
						emptyRolesExist = true;
						app.rolesValidation = "This field is required.";
					}
					// handle locations validations
					if (app.locations.length === 0 && !app.allLocations) {
						emptyLocationsExist = true;
						app.locationsValidation = "This field is required.";
					}
				}
			}
			return app;
		});
		setData({
			...data,
			inviteData: {
				appsData: updatedAppsData
			}
		});
		if (emptyRolesExist || emptyLocationsExist) {
			return;
		}
		if (activePlatforms > 0 && !emailPhoneValidation) {
			setConfirmLoading(true);
			setError({});
			try {
				const variables = {
					...data,
					clientToken: user.token,
					bizId: biz.id,
					isdCode: data?.isdCode?.code,
					inviteData: {
						bizId: biz.id,
						status: "CREATED",
						appUserId: user.id,
						invitedBy: {
							id: user.id,
							name: user.name
						},
						appsData: data.inviteData.appsData
							.filter((app) => app.isActive)
							.map((app) => ({
								appName: app.appName,
								appBizId: app.appBizId,
								roles:
									app.appName === "prime"
										? [
												{
													id: app.roles.id,
													name: app.roles.name
												}
										  ]
										: app.roles.map((role) => ({
												id: role.id,
												name: role.name
										  })),
								locations: app.locations.map((location) => ({
									id: location.id,
									name: location.name
								})),
								allLocations: app.allLocations
							}))
					}
				};
				const resp = await client.mutate({
					mutation: INVITE_USER,
					variables
				});
				if (resp.data.authServiceInvite.status.success) {
					setConfirmLoading(false);
					setInviteSuccess({
						status: true,
						uuid: resp.data.authServiceInvite.uuid
					});
					// track event
					const eventName = "user_invite";
					const eventMeta = {};
					trackEvent(eventName, eventMeta);
				} else {
					setConfirmLoading(false);
					const messages = resp.data.authServiceInvite.status.messages;
					// handle error message
					if (messages.length && (messages[0].field === null || messages[0].field === "uuid")) {
						store.dispatch({
							type: ActionTypes.SHOW_GLOBAL_MESSAGE,
							payload: {
								message: messages[0].message,
								timeout: 3000,
								error: true
							}
						});
					} else {
						setError(parseErrorMessages(messages));
					}
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
				setConfirmLoading(false);
			}
		} else if (activePlatforms === 0) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please provide access to at least one platform!",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	const extractInitials = (name) => {
		if (!name) {
			return "";
		}
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(1);
		}
		return initials.toUpperCase();
	};

	const handleDropdownLabel = (currValue, handleCheck, field, props) => {
		return (
			<React.Fragment>
				{currValue?.map?.((option, i) => (
					<div className="option-tag" key={i}>
						<div className="option">
							{option?.[props.labelKey]}
							{option?.brandName ? ` (${option?.brandName})` : ""}
						</div>
						<ButtonIcon
							icon="cross"
							clickHandler={(e) => {
								handleCheck(false, field, option, props);
								e.stopPropagation();
							}}
						/>
					</div>
				))}
			</React.Fragment>
		);
	};

	const handleDropdownOption = (option, field, handleCheck, optionUpdates, props) => {
		const isChecked = optionUpdates[field] && optionUpdates[field]?.[option?.[props?.valueKey]] ? true : false;
		const title = `${option?.[props?.labelKey]}${option?.brandName ? ` (${option?.brandName})` : ""}`;
		return (
			<React.Fragment>
				<CheckBox
					checked={isChecked}
					clickHandler={() => handleCheck(!isChecked, field, option, props)}
					readOnly={option.readOnly || false}
					title={title}
				>
					{title}
				</CheckBox>
			</React.Fragment>
		);
	};

	const validations = error.fields || {};

	return (
		<div className="unified-user-invite-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title="Invite New User"
				subTitle={
					"Bring new users onboard and assign them roles to streamline your operations on Atlas and Prime"
				}
				loading={confirmLoading}
				submitTitle="Invite User"
				disabled={!isFormTouched}
				hideSubmitAction={isInviteSuccess.status}
				cancelTitle={isInviteSuccess.status ? "Dismiss" : "Cancel"}
				hideActions={!isFormTouched}
			>
				{!isInviteSuccess.status && loading && (
					<div className="Pstart(25px) Pend(25px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{!isInviteSuccess.status && !loading && (
					<div className="form-content">
						<div className="form-row row-full">
							<InputWithLabel
								value={data.value}
								onChange={(e) => handleForm("value", e.target.value)}
								onBlur={handleOnBlur}
								requiredLabel={true}
								showAction={true}
								showDropdown={data.invitedThrough === "phone"}
								dropdownOptions={bizIsdCodes}
								dropdownField="isdCode"
								dropdownCurrVal={data.isdCode}
								customDropdownLabel={data?.isdCode?.code || null}
								handleDropdown={handleForm}
								labelKey="name"
								valueKey="code"
								isClearable={false}
								dropdownPlaceholder="ISD"
								validationMessage={
									validations["0#value"] ||
									validations["#tempAccessInvite"] ||
									emailPhoneValidation ||
									""
								}
								classes={data.invitedThrough === "phone" ? "at-input--dropdown" : ""}
							>
								Email/ Mobile Number
							</InputWithLabel>
						</div>
						{data.inviteData?.appsData?.length > 0 && (
							<div className="sub-heading">Select platforms & roles for new user</div>
						)}
						{data.inviteData?.appsData?.length > 0 &&
							data.inviteData?.appsData?.map((app, i) => (
								<App
									key={i}
									index={i}
									app={app}
									data={data}
									extractInitials={extractInitials}
									handleData={(data) => setData(data)}
									handleForm={handleForm}
									handleDropdownLabel={handleDropdownLabel}
									handleDropdownOption={handleDropdownOption}
								/>
							))}
						{data.inviteData?.appsData?.length === 0 && (
							<div className="no-items-placeholder">No platforms found</div>
						)}
					</div>
				)}
				{isInviteSuccess.status && (
					<div className="user-invite-success">
						<div className="illustration">
							<img src="/assets/empty-states/user-invite.svg" alt="" />
						</div>
						<div className="message">User Invited Successfully</div>
						<div className="description">
							<div>
								Invite sent to{" "}
								<span>
									{data.invitedThrough === "phone" ? `${data?.isdCode?.code || ""} ` : ""}
									{data.value}
								</span>
							</div>
							<div>New user will join your team once they finish setting up their account</div>
						</div>
						<Button clickHandler={() => handleReset(true)}>
							<CreateIcon />
							<span>Invite Another User</span>
						</Button>
						<Link className="link-text" to={`/unified-access/preview/${isInviteSuccess.uuid}`}>
							View Invite
						</Link>
					</div>
				)}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	user: store.login.loginDetail,
	biz: store.login.loggedInbizDetail
}))(UnifiedUserInvite);

export const App = ({
	app,
	index,
	data,
	handleData,
	handleForm,
	extractInitials,
	handleDropdownLabel,
	handleDropdownOption
}) => {
	const [optionUpdates, setOptionUpdates] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState({});
	const showLocations =
		app.appName === "prime" ||
		app?.roles?.find((role) => role?.id === 53 || role?.id === 55 || role?.id === 66) !== undefined
			? true
			: false;

	const handleCheck = useCallback(
		(state, field, option, props) => {
			setOptionUpdates({
				...optionUpdates,
				[field]: {
					...optionUpdates[field],
					[option[props.valueKey]]: state
				}
			});
			setIsCheckedAll({
				...isCheckedAll,
				[field]: false
			});
			// update field in app data
			const updatedAppsData = data.inviteData.appsData.map((app, i) => {
				let appData = { ...app };
				if (i === index) {
					let updates = [...(app[field] ?? [])];
					if (state) {
						updates.push({ ...option });
					} else {
						updates = [...updates].filter((opt) => opt[props.valueKey] !== option[props.valueKey]);
					}
					appData[field] = updates;
					appData.allLocations = false;
					// handle validations
					if (field === "roles") {
						appData.rolesValidation = "";
					}
					if (field === "locations" || field === "allLocations") {
						appData.locationsValidation = "";
					}
				}
				return appData;
			});
			handleData({
				...data,
				inviteData: {
					appsData: updatedAppsData
				}
			});
		},
		[optionUpdates, isCheckedAll, data, handleData, index]
	);

	const handleCheckAll = useCallback(
		(state, field, options, props) => {
			const filterOptions = {};
			options = options.forEach((opt) => {
				filterOptions[opt[props.valueKey]] = false;
			});
			setOptionUpdates({
				...optionUpdates,
				[field]: {
					...optionUpdates[field],
					...filterOptions
				}
			});
			setIsCheckedAll({
				...isCheckedAll,
				[field]: state
			});
			// update field in app data
			const updatedAppsData = data.inviteData.appsData.map((app, i) => {
				let appData = { ...app };
				if (i === index) {
					appData[field] = [];
					appData.allLocations = state;
					// handle validations
					if (field === "roles") {
						appData.rolesValidation = "";
					}
					if (field === "locations" || field === "allLocations") {
						appData.locationsValidation = "";
					}
				}
				return appData;
			});
			handleData({
				...data,
				inviteData: {
					appsData: updatedAppsData
				}
			});
		},
		[optionUpdates, isCheckedAll, data, handleData, index]
	);

	return (
		<div className="card-container">
			<div className="card-header">
				<div className="product">
					<div className="logo">
						<img src={`/assets/icons/${app.appName}-logo.svg`} alt="" />
					</div>
					<div className="name">
						<span>{app.appName}</span>
						{!app.isActive && <span> - {app.appBizName}</span>}
					</div>
				</div>
				<Switch
					title="Access"
					checked={app.isActive}
					clickHandler={() => handleForm("isActive", !app.isActive, index)}
				/>
			</div>
			{app.appName === "atlas" ? (
				<div className="description">
					Comprehensive tool that empowers you to manage restaurant operations efficiently and provides
					valuable insights through detailed analytics.
				</div>
			) : (
				<div className="description">
					Dedicated tool that helps you manage store operations like POS, stock management, online orders, and
					customer service.
				</div>
			)}
			{app.isActive && (
				<div className="card-content">
					<div className="biz-info">
						<div className="logo">
							{app.appBizLogo ? (
								<img src={app.appBizLogo} alt="" />
							) : (
								<div className="initials">{extractInitials(app.appBizName)}</div>
							)}
						</div>
						<div className="name">{app.appBizName}</div>
					</div>
					<div className="form-row row-full">
						{app.appName === "atlas" ? (
							<SelectFilterCustom
								title="Roles"
								options={app.rolesOptions}
								field="roles"
								currValue={app.roles || []}
								labelKey={"name"}
								valueKey={"id"}
								customLabel={true}
								renderLabel={handleDropdownLabel}
								ellipsizedLength={100}
								multi={true}
								showCheckBox={true}
								requiredLabel={true}
								showSelectAllOption={false}
								optionUpdates={optionUpdates}
								handleCheck={handleCheck}
								isCheckedAll={isCheckedAll}
								handleCheckAll={handleCheckAll}
								placeholder="Select Roles"
								validationMessage={app.rolesValidation || ""}
							/>
						) : (
							<SelectFilter
								title="Roles"
								options={app.rolesOptions}
								field="roles"
								currValue={app.roles || null}
								setFilter={(field, value) => handleForm(field, value, index)}
								labelKey="name"
								valueKey="id"
								requiredLabel={true}
								validationMessage={app.rolesValidation || ""}
							/>
						)}
					</div>
					{showLocations && (
						<div className="form-row row-full">
							<SelectFilterCustom
								title="Locations"
								options={app.locationsOptions}
								field="locations"
								currValue={app.locations || []}
								labelKey={"name"}
								valueKey={"id"}
								customLabel={true}
								renderLabel={handleDropdownLabel}
								customOptions={app.appName === "atlas"}
								renderOptions={handleDropdownOption}
								customSearchLabel={app.appName === "atlas"}
								handleCustomSearchLabel={(option, props) =>
									`${option?.[props?.labelKey]}${option?.brandName ? ` (${option?.brandName})` : ""}`
								}
								ellipsizedLength={100}
								multi={true}
								requiredLabel={true}
								showCheckBox={true}
								showSelectAllOption={app.appName === "prime"}
								optionUpdates={optionUpdates}
								handleCheck={handleCheck}
								isCheckedAll={isCheckedAll}
								handleCheckAll={handleCheckAll}
								placeholder="Select Locations"
								validationMessage={app.locationsValidation || ""}
							/>
						</div>
					)}
				</div>
			)}
			{/* {
				(app.appName === 'prime') &&
				<div className="card-footer">
					<img src="/assets/icons/icon-question.svg" alt="" />
					<div className="text">Want access to multiple business on Prime?</div>
					<div className="link-text">Contact Support</div>
				</div>
			} */}
		</div>
	);
};
