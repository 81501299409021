import React from "react";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Gallery</div>
				<div className="subtitle">Manage all your gallery images here</div>
			</div>
			<div className="header-action-button">
				<div className="filter-buttons">
					{props.dimensions.width > 768 && (
						<div
							className={(props.filterActive ? "active" : "") + " filter-in-header campaign-list-filter"}
						>
							<div className="container" onClick={props.flipShowFilters}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{props.filterCount > 0 && <span className="filter-count">{props.filterCount}</span>}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
