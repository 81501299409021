import React, { useEffect } from "react";

// third party
import { Transition, config, animated } from "react-spring/renderprops";

// components
import { DownArrow } from "./DownArrow";

export const Accordion = ({
	title = "",
	info = "",
	id = "",
	isExpanded = false,
	handleExpand,
	allowRowExpansion = false,
	inTable = false,
	tableRow = null,
	record = {},
	onAccordionOpen,
	onAccordionClose,
	loading = false,
	currView = {},
	showDropdownArrow = false,
	children
}) => {
	useEffect(() => {
		if (isExpanded && onAccordionOpen) {
			onAccordionOpen();
		} else if (!isExpanded && onAccordionClose) {
			onAccordionClose();
		}
	}, [isExpanded]);

	return (
		<div
			className={
				"at-accordion-container" +
				(isExpanded ? " expanded" : "") +
				(currView?.id === record?.id ? " view" : "")
			}
		>
			{inTable ? (
				<div
					className={"accordion-row" + (isExpanded ? " expanded" : "")}
					onClick={allowRowExpansion ? () => handleExpand(id, !isExpanded) : () => {}}
				>
					{tableRow}
					{allowRowExpansion && <DownArrow expanded={isExpanded} />}
				</div>
			) : (
				<div className="header" onClick={() => handleExpand(id, !isExpanded)}>
					<div className="title">{title}</div>
					{info && <div className="info">{info}</div>}
					{showDropdownArrow ? (
						<div className={"dropdown-arrow" + (isExpanded ? " is-open" : "")}>
							<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
								<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
							</svg>
						</div>
					) : (
						<DownArrow expanded={isExpanded} />
					)}
				</div>
			)}
			<Transition
				native
				from={{ height: 0, opacity: 0 }}
				enter={{ height: "auto", opacity: 1 }}
				leave={{ height: 0, opacity: 0, delay: 0 }}
				items={isExpanded}
				config={{ ...config.stiff, ...{ duration: 100 } }}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div
							className={"contents" + (loading ? " loading" : "") + (isExpanded ? " expanded" : "")}
							style={props}
						>
							{children}
						</animated.div>
					))
				}
			</Transition>
		</div>
	);
};
