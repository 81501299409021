import React, { useState, useEffect, useReducer, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import BasicInfo from "../components/APIAccountEdit/BasicInfo";
import ArchiveRestoreModal from "../components/_commons/ArchiveRestoreModal";

// utils
import history from "../history";
import { removeProp } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchCiListGroups, fetchStores } from "../actions/actions";
import { fetchAPIAccountsList, fetchAPIAccountDetail, editAPIAccountDetail } from "../actions/apiAccounts";
import { ActionTypes } from "../actions/_types";

// reducers
import { apiAccountDetailsReducer, API_ACCOUNT_DETAIL_INITIAL_STATE } from "../reducers/apiAccounts";
import ContextMenu from "../components/_commons/ContextMenu";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	}
];

const ARCHIVE_RESTORE_INIT_STATE = { isOpen: false, mode: null };

const APIAccountEdit = ({ access, match, isNested = false, closeNestedContainer, connectedRef }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [apiAccountDetails, dispatch] = useReducer(apiAccountDetailsReducer, API_ACCOUNT_DETAIL_INITIAL_STATE);
	const { loading, data, error } = apiAccountDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [isContextMenuRendered, setIsContextMenuRendered] = useState(false);
	const [archiveRestoreState, setArchiveRestoreState] = useState(ARCHIVE_RESTORE_INIT_STATE);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiListGroups({ api: true });
		fetchStores("", 100);
	}, []);

	useEffect(() => {
		fetchAPIAccountDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const handleCancel = () => {
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchAPIAccountsList();
					history.push("/api-access");
				}
			}, 100);
		}
	};

	const handleForm = (field, value) => {
		dispatch({
			type: ActionTypes.UPDATE_API_ACCOUNT_DETAIL,
			payload: {
				[field]: value
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		const resp = await editAPIAccountDetail(sanitisedData, dispatch);
		if (resp) {
			setFormTouched(false);
		}
	};

	const openContextMenu = () => {
		setIsContextMenuRendered(true);
	};

	const closeContextMenu = () => {
		setIsContextMenuRendered(false);
	};

	const renderArchiveRestoreModal = (mode) => {
		setIsContextMenuRendered(false);
		setArchiveRestoreState({
			isOpen: true,
			mode
		});
	};

	const closeArchiveRestoreModal = (refresh) => {
		setArchiveRestoreState(ARCHIVE_RESTORE_INIT_STATE);
		if (refresh) {
			fetchAPIAccountDetail(parseInt(match.params.id), dispatch);
		}
	};

	const renderMenuItems = () => {
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={() => renderArchiveRestoreModal(data?.isActive ? "archive" : "restore")}
					style={{ color: data?.isActive ? "#ff425c" : "#2ecc71" }}
				>
					{data?.isActive ? "Archive" : "Restore"}
				</div>
			</React.Fragment>
		);
	};

	const renderHeaderRightContent = () => {
		return (
			<ContextMenu
				handleOutsideClick={closeContextMenu}
				renderMenuItems={renderMenuItems}
				isOpen={isContextMenuRendered}
				handleOpenMenu={openContextMenu}
			/>
		);
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	return (
		<div className="api-account-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.firstName || "API account"}
				subTitle="Edit this API account"
				submitTitle="Save"
				loading={loading}
				disabled={!isFormTouched}
				isNested={isNested}
				hideActions={formTab === FORM_TABS[0].value && !isFormTouched}
				headerRight={renderHeaderRightContent()}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo data={data} handleForm={handleForm} validations={error.fields || {}} />
					)}
				</div>
			</FormSidebar>
			<ArchiveRestoreModal
				isOpen={archiveRestoreState?.isOpen}
				mode={archiveRestoreState?.mode}
				entityName="API Access"
				entityType="apiAccess"
				dataObject={data}
				close={closeArchiveRestoreModal}
			/>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access
}))(APIAccountEdit);
