import React, { Component } from "react";

// components
import Placeholder from "../_commons/Placeholder";

// third party
import { connect } from "react-redux";
import { commifyNumbers, makeFlatObject, printCurrency, extractInitials } from "../../atlas-utils";

// constants
import { AVOID_TABLE_COLUMNS } from "../SalesAnalytics/Performance";
import { BRAND_COLORS } from "../../client-config";

const TOP_PERF_TABLE_KEYS = ["name", "count", "sales", "percentChange"];

@connect((store) => ({
	topPerfData: store.topPerfData,
	login: store.login
}))
export default class TopPerformingTable extends Component {
	render() {
		const {
			handleNestedEntity,
			topic,
			isMultibrandEnabled,
			topPerfData: { loading, data }
		} = this.props;

		const placeholderContent = {
			placeholderText: "Crunching your numbers!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-dashboard.svg",
			placeholderSubtext:
				"View insights about your restaurant performance with metrics like sales, revenue, and trends helping you to take data driven decisions for your business",
			size: "medium"
		};

		return (
			<div
				className={
					(loading ? "disabled" : "") +
					` top-perf-table-container ${
						(data?.rows ?? []).length === 0 ? "top-perf-table-container--empty" : ""
					}`
				}
			>
				{loading && !data ? (
					<div>
						<div className="shimmer H(83px)"></div>
						<div className="shimmer H(83px) Mt(20px)"></div>
					</div>
				) : data && data.rows.length > 0 ? (
					<div className={"at-table at-table--4cols" + (!isMultibrandEnabled ? " single-brand" : "")}>
						<TableHeader data={data} isMultibrandEnabled={isMultibrandEnabled} />
						<TableList
							data={data}
							currencySymbol={this.props.login.loggedInbizDetail.currencySymbol}
							performanceTopic={topic}
							handleNestedEntity={handleNestedEntity}
							isMultibrandEnabled={isMultibrandEnabled}
						/>
					</div>
				) : (
					<Placeholder {...placeholderContent} />
				)}
			</div>
		);
	}
}

const getValue = (row, currencySymbol, flatRow = {}, performanceTopic, handleNestedEntity) => {
	let val = "";
	switch (row.key) {
		case "name":
			if (flatRow[AVOID_TABLE_COLUMNS[performanceTopic]]) {
				return (
					<a
						role="button"
						className="link-text"
						onClick={() =>
							handleNestedEntity(
								true,
								AVOID_TABLE_COLUMNS[performanceTopic].split("_")[1],
								flatRow[AVOID_TABLE_COLUMNS[performanceTopic]]?.value
							)
						}
						title={row.value}
					>
						{row.value}
					</a>
				);
			}
			return `${row.value}`;
		case "brands":
			let brands = [];
			try {
				brands = JSON.parse(row.value);
			} catch (error) {
				brands = [];
			}
			brands = brands.map((brand) => ({
				...brand,
				color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
			}));
			return (
				<div className="brands-list">
					{brands?.length > 0
						? brands?.map((brand, j) => (
								<div key={j} className={"brand-initials " + brand.color} title={brand.name}>
									{brand.image ? (
										<img src={brand.image} alt="" />
									) : (
										extractInitials(brand.name?.split(" "))
									)}
								</div>
						  ))
						: "--"}
				</div>
			);
		case "count":
			val = row.value;
			return `${commifyNumbers(val)}`;
		case "percentChange":
			val = Number(row.value);
			return <span className={(val < 0 ? "negative" : "positive") + " val-container"}>{val} %</span>;
		case "sales":
			return (
				<span>
					{printCurrency(currencySymbol)}
					{commifyNumbers(row.value)}
				</span>
			);
		default:
			return row.value;
	}
};

const makeFlat = (arr) => {
	let flatObj = {};
	arr.forEach((obj) => {
		flatObj[obj.key] = obj;
	});
	return flatObj;
};

const TableHeader = ({ data, isMultibrandEnabled = false }) => {
	let flatCol = makeFlatObject(data.columns);
	return (
		<React.Fragment>
			{(isMultibrandEnabled ? [...TOP_PERF_TABLE_KEYS, "brands"] : TOP_PERF_TABLE_KEYS).map((key, i) => (
				<div key={i} className={flatCol[key].key + " at-table-cell at-table-header at-header-text"}>
					{flatCol[key].displayName}
				</div>
			))}
		</React.Fragment>
	);
};

const TableList = ({ data, currencySymbol, performanceTopic, handleNestedEntity, isMultibrandEnabled = false }) => {
	let stripeClass = "striped";
	return (
		<React.Fragment>
			{data.rows.map((colRows, index) => {
				stripeClass = stripeClass === "striped" ? "" : "striped";
				return (
					<TableListItem
						row={colRows}
						key={index}
						stripeClass={stripeClass}
						currencySymbol={currencySymbol}
						performanceTopic={performanceTopic}
						handleNestedEntity={handleNestedEntity}
						isMultibrandEnabled={isMultibrandEnabled}
					/>
				);
			})}
		</React.Fragment>
	);
};

const TableListItem = ({
	row,
	stripeClass,
	currencySymbol,
	performanceTopic,
	handleNestedEntity,
	isMultibrandEnabled = false
}) => {
	let flatRow = makeFlatObject(row);
	return (
		<React.Fragment>
			{(isMultibrandEnabled ? [...TOP_PERF_TABLE_KEYS, "brands"] : TOP_PERF_TABLE_KEYS).map((key, i) => (
				<div
					key={i}
					className={
						flatRow[key].key +
						" at-table-cell at-cell-text " +
						stripeClass +
						(AVOID_TABLE_COLUMNS[performanceTopic] ? " link-text" : "")
					}
				>
					{getValue(flatRow[key], currencySymbol, flatRow, performanceTopic, handleNestedEntity)}
				</div>
			))}
		</React.Fragment>
	);
};
