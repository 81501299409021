import React, { useEffect, useState } from "react";
import Star from "../../_icons/Star";
import CreateIcon from "../../_commons/CreateIcon";
import { fetchCompareUserStatus, updateUserVisitOnCompare } from "../../../actions/analytics";
import { connect } from "react-redux";

const CompareIntro = ({ login }) => {
	const bizId = login?.loggedInbizDetail?.id;
	const [showbanner, setShowBanner] = useState(true);

	const updateUserStatus = async (dismiss = false) => {
		updateUserVisitOnCompare({ bizId: String(bizId), dismiss });
	};
	useEffect(() => {
		fetchCompareUserStatus({ bizId: String(bizId) });
		updateUserStatus();
	}, []);

	const dismissIntro = () => {
		updateUserStatus(true);
		setShowBanner(false);
	};

	if (!showbanner) {
		return <></>;
	}

	return (
		<div className="compare-intro">
			<div
				className="main-close-icon"
				onClick={() => {
					dismissIntro();
				}}
			>
				<CreateIcon color={"#363636"} />
			</div>

			<img src="/assets/analytics/compare/stars_bg.svg" className="star-img" />
			<img src="/assets/analytics/compare/ellipse_left.svg" className="ellipse_left" />
			<img src="/assets/analytics/compare/ellipse_right.svg" className="ellipse_right" />

			<div className="all-content">
				<div className="main-info">
					<div className="heading">Unleash the power of side-by-side comparisons</div>
					<div className="key-points">
						<div className="key-info">
							<span className="img-span">
								<img src="/assets/analytics/compare/compare-slide.svg" className="compare-slide" />
							</span>
							<span>Compare locations, platforms, items, and brands across 12 key metrics</span>
						</div>
						<div className="key-info">
							<span className="img-span">
								<Star styles={{ height: "16px", width: "16px" }} variations={{ fill: "#363636" }} />
							</span>
							<span>Spot top performers</span>
						</div>
						<div className="key-info">
							<span className="img-span">
								<img src="/assets/analytics/compare/filter-icon.svg" className="filter-icon" />
							</span>
							<span>Filter & customise views</span>
						</div>

						<div className="key-info">
							<span className="img-span">
								<img src="/assets/analytics/compare/icon-download.svg" className="icon-download" />
							</span>
							<span>Save comparisons for easy collaboration</span>
						</div>
					</div>
				</div>
				<div className="sty-img">
					<div className="left-bin">
						<div className="zoma-bng">
							<div className="zomato">
								<img src="/assets/icons/platform-icon-zomato.svg" className="zomato-img" />
							</div>
							<div className="bangalore border-background">
								<img src="/assets/analytics/compare/location.svg" className="location-img" />
								<span className="bng">Bangalore</span>
							</div>
						</div>
						<div className="total-revenue">
							<span className="total">Total Revenue</span>
							<span className="rupeee"> ₹5,27,093</span>
						</div>
						<div className="top-items border-background">
							<div className="star-items">
								<Star
									styles={{ height: "10.53px", width: "10.53px" }}
									variations={{ fill: "#E5B917" }}
								/>
								<span className="best-selling">Top Selling Items</span>
							</div>
							<div className="item">Veg Extravaganza Pizza</div>
							<div className="item">Baked Mac & Cheese</div>
							<div className="empty-item"></div>
						</div>
					</div>
					<div className="right-bin">
						<div className="swiggy">
							<img src="/assets/icons/swiggy-tiny.png" className="swiggy-img border-background" />
						</div>
						<div className="london border-background ">
							<img src="/assets/analytics/compare/location.svg" className="location-img" />
							<span className="text">London</span>
						</div>
						<div className="locations-available border-background">
							<div className="bar">
								<div className="more"></div>
								<div className="small"></div>
							</div>
							<div className="available">Location Availability 80%</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect((store) => ({
	login: store.login
}))(CompareIntro);
