import React from "react";

const InfoIcon = ({ styles = {}, variations = {}, fill = "#3B5FEB" }) => {
	return (
		<>
			<svg
				style={{ ...styles }}
				width="20"
				height="22"
				viewBox="0 0 20 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...variations}
			>
				<path
					d="M9.99935 2.66602C5.40685 2.66602 1.66602 6.40685 1.66602 10.9993C1.66602 15.5919 5.40685 19.3327 9.99935 19.3327C14.5919 19.3327 18.3327 15.5919 18.3327 10.9993C18.3327 6.40685 14.5919 2.66602 9.99935 2.66602ZM9.99935 4.33268C13.6911 4.33268 16.666 7.30758 16.666 10.9993C16.666 14.6911 13.6911 17.666 9.99935 17.666C6.30758 17.666 3.33268 14.6911 3.33268 10.9993C3.33268 7.30758 6.30758 4.33268 9.99935 4.33268ZM9.16602 6.83268V8.49935H10.8327V6.83268H9.16602ZM9.16602 10.166V15.166H10.8327V10.166H9.16602Z"
					fill={fill}
				/>
			</svg>
		</>
	);
};
export default React.memo(InfoIcon);
