import React, { useEffect, useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Textarea } from "../_commons/Textarea";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// constants
import { countrySpecificPopularCities } from "../../client-config";

// utils
import { capitaliseTextStrict } from "../../atlas-utils";

// actions
import { fetchCitiesList } from "../../actions/locations";
import Popover from "../_commons/Popover";

const BasicInfo = ({
	bizPlatforms,
	data,
	handleForm,
	readOnly = true,
	validations,
	scrollDown,
	showLanguages = false,
	currSelectedLang = {},
	handleLanguage,
	handleTranslation,
	saveTranslation,
	highlightField = false,
	bizCountry,
	fetchItemTags,
	isMultibrandEnabled = false,
	handleBrandLocation,
	handleBrandsLabelOption,
	showPopover = false,
	renderPopover,
	handlePopoverClose,
	fulfillmentModes
}) => {
	const country = bizCountry.toLowerCase() === "united kingdom" ? "uk" : bizCountry.toLowerCase();
	const INIT_CITY_ARRAY_VALUES = [...(countrySpecificPopularCities[country] ?? [])];
	const [citiesListStates, setCitiesListStates] = useState({
		loading: false,
		values: INIT_CITY_ARRAY_VALUES,
		selectedCity: undefined
	});

	useEffect(() => {
		if (data.city) {
			setCitiesListStates((current) => ({
				...current,
				selectedCity: {
					value: capitaliseTextStrict(data.city),
					valueForDisplay: capitaliseTextStrict(data.city)
				}
			}));
		} else {
			setCitiesListStates((current) => ({ ...current, selectedCity: null }));
		}
	}, [data]);

	const searchCities = async (searchCity) => {
		if (searchCity === "") {
			setCitiesListStates((current) => ({ ...current, values: INIT_CITY_ARRAY_VALUES }));
			return;
		}
		setCitiesListStates((current) => ({ ...current, loading: true }));
		const citySearchResult = await fetchCitiesList(searchCity);

		if (Array.isArray(citySearchResult?.data)) {
			const updatedCitiesList = [...citySearchResult.data];
			setCitiesListStates((current) => ({
				...current,
				loading: false,
				values: [
					...updatedCitiesList.map((city) => ({
						value: `${city.name}`,
						valueForDisplay: `${city.name}, ${city.state_name}, ${city.country_name}`
					}))
				]
			}));
		} else {
			setCitiesListStates((current) => ({ ...current, loading: false }));
		}
	};
	const searchCitiesDebounced = debounce((searchCity) => searchCities(searchCity), 500);

	const handleCityUpdate = (key, val) => {
		if (!val) {
			setCitiesListStates((current) => ({ ...current, selectedCity: null }));
			handleForm("city", "");
			return;
		}
		setCitiesListStates((current) => ({ ...current, selectedCity: { ...val } }));
		handleForm("city", val.value);
	};

	return (
		<div className="basic-info-tab">
			<div className="form-row row-half">
				<InputWithLabel
					value={
						currSelectedLang.bizLocationNickname
							? currSelectedLang.bizLocationNickname.lang === "en"
								? data.bizLocationNickname
								: currSelectedLang.bizLocationNickname.value
							: data.bizLocationNickname
					}
					onChange={(e) =>
						showLanguages
							? handleTranslation("bizLocationNickname", e.target.value)
							: handleForm("bizLocationNickname", e.target.value)
					}
					requiredLabel={!showLanguages}
					showLanguages={showLanguages}
					currSelectedLang={
						(currSelectedLang.bizLocationNickname && currSelectedLang.bizLocationNickname.lang) || ""
					}
					handleLanguage={(lang) => handleLanguage(lang, "bizLocationNickname")}
					showLoading={showLanguages}
					showActionBtn={
						currSelectedLang.bizLocationNickname && currSelectedLang.bizLocationNickname.showActionBtn
					}
					action={() =>
						saveTranslation(
							"bizLocationNickname",
							currSelectedLang.bizLocationNickname
								? currSelectedLang.bizLocationNickname.lang === "en"
									? data.bizLocationNickname
									: currSelectedLang.bizLocationNickname.value
								: data.bizLocationNickname
						)
					}
					field="biz_location_nickname"
					validationMessage={validations.bizLocationNickname || ""}
					readOnly={readOnly}
					classes={
						currSelectedLang.bizLocationNickname && currSelectedLang.bizLocationNickname.showActionBtn
							? "at-input--save"
							: ""
					}
				>
					Name
				</InputWithLabel>
				<InputWithLabel
					value={data.bizAddress || ""}
					onChange={(e) => handleForm("bizAddress", e.target.value)}
					validationMessage={validations.bizAddress || ""}
					readOnly={readOnly}
				>
					Address
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title={"City"}
					requiredLabel
					currValue={citiesListStates.selectedCity}
					options={citiesListStates.values}
					labelKey="valueForDisplay"
					valueKey="value"
					setFilter={handleCityUpdate}
					isAsync
					isLoading={citiesListStates.loading}
					handleSearch={searchCitiesDebounced}
					validationMessage={validations.city || ""}
					readOnly={readOnly}
				/>
				<InputWithLabel
					value={data.sortOrder}
					type="number"
					onChange={(e) => handleForm("sortOrder", e.target.value ? parseInt(e.target.value) : null)}
					validationMessage={validations.sortOrder || ""}
					requiredLabel={true}
					readOnly={readOnly}
				>
					Sort Order
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Fulfillment Modes"
					options={fulfillmentModes?.items}
					readOnly={readOnly}
					isLoading={fulfillmentModes.isLoading}
					field="fulfillmentModesList"
					currValue={data.fulfillmentModesList}
					setFilter={handleForm}
					labelKey="nameForDisplay"
					valueKey="id"
					multi={true}
					validationMessage={validations.fulfillmentModesList || ""}
				/>
				<InputWithLabel
					value={data.contactPhone || ""}
					onChange={(e) => handleForm("contactPhone", e.target.value)}
					validationMessage={validations.contactPhone || ""}
					readOnly={readOnly}
				>
					Contact Phone
				</InputWithLabel>
			</div>
			{!isMultibrandEnabled && (
				<div className="form-row row-full">
					<Textarea
						value={data.description}
						onChange={(e) => handleForm("description", e.target.value)}
						readOnly={readOnly}
					>
						Description
					</Textarea>
				</div>
			)}
			{!isMultibrandEnabled && (
				<div className="form-row row-half">
					<InputWithLabel
						value={data.merchantBizLocationId === "-1" ? "" : data.merchantBizLocationId}
						onChange={(e) => handleForm("merchantBizLocationId", e.target.value)}
						validationMessage={validations.merchantBizLocationId || ""}
						readOnly={readOnly}
					>
						POS ID
					</InputWithLabel>
					<InputWithLabel
						value={data.gstin || ""}
						onChange={(e) => handleForm("gstin", e.target.value)}
						validationMessage={validations.gstin || ""}
						readOnly={readOnly}
					>
						GSTIN
					</InputWithLabel>
				</div>
			)}
			<div className="form-row row-half">
				{!isMultibrandEnabled && (
					<SelectFilter
						title="Excluded Platforms"
						options={bizPlatforms.items}
						isLoading={bizPlatforms.isLoading}
						readOnly={readOnly}
						field="excludedPlatforms"
						currValue={data.excludedPlatforms}
						setFilter={handleForm}
						labelKey="platformName"
						valueKey="id"
						multi={true}
						validationMessage={validations.excludedPlatforms || ""}
						highlight={highlightField}
						showCustomTooltip={true}
						tooltipInfo="Select aggregator platforms to disconnect this location from them"
					/>
				)}
				<SelectFilter
					title="Tags"
					loadOptions={fetchItemTags}
					readOnly={readOnly}
					field="tags"
					currValue={data.tags}
					setFilter={handleForm}
					labelKey="titleForDisplay"
					valueKey="title"
					validationMessage={validations.tags || ""}
					isClearable={false}
					multi={true}
					asyncCreatable={true}
					ellipsized={true}
					placeholder="Create tags by pressing Tab/Enter"
					// scrollDown={scrollDown}
				/>
				{isMultibrandEnabled && (
					<InputWithLabel
						value={data.gstin || ""}
						onChange={(e) => handleForm("gstin", e.target.value)}
						validationMessage={validations.gstin || ""}
						readOnly={readOnly}
					>
						GSTIN
					</InputWithLabel>
				)}
			</div>
			{!isMultibrandEnabled && (
				<div className="form-row row-full">
					<Textarea
						value={data.seoDescription}
						onChange={(e) => handleForm("seoDescription", e.target.value)}
						readOnly={readOnly}
					>
						SEO Description
					</Textarea>
				</div>
			)}
			{isMultibrandEnabled && data?.brandLocations?.length > 0 && (
				<div className="brand-locations">
					<div className="form-row row-half">
						<Popover
							open={showPopover}
							renderPopover={renderPopover}
							showClose={true}
							onClose={handlePopoverClose}
							position="down-left"
						>
							<SelectFilterCustom
								options={data.brandLocations || []}
								field="selectedBrandLocation"
								currValue={data.selectedBrandLocation}
								setFilter={(f, value) => handleBrandLocation(value)}
								labelKey={"brandName"}
								valueKey={"brandId"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								isClearable={false}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								classes="brand-selector"
							/>
							{data.selectedBrandLocation && (
								<div className="basic-info-brand-id">
									Brand Location ID: {data.selectedBrandLocation?.id}
								</div>
							)}
						</Popover>
					</div>
					<div className="form-row row-half">
						<SelectFilter
							title="Excluded Platforms"
							options={bizPlatforms.items}
							isLoading={bizPlatforms.isLoading}
							readOnly={readOnly}
							field="excludedPlatforms"
							currValue={data?.selectedBrandLocation?.excludedPlatforms || []}
							setFilter={(f, v) => handleForm(f, v, data?.selectedBrandLocation?.brandId)}
							labelKey="platformName"
							valueKey="id"
							multi={true}
							highlight={highlightField}
							showCustomTooltip={true}
							tooltipInfo="Select aggregator platforms to disconnect this location from them"
						/>
						<InputWithLabel
							value={
								data?.selectedBrandLocation?.merchantBizLocationId === "-1"
									? ""
									: data?.selectedBrandLocation?.merchantBizLocationId
							}
							onChange={(e) =>
								handleForm(
									"merchantBizLocationId",
									e.target.value,
									data?.selectedBrandLocation?.brandId
								)
							}
							readOnly={readOnly}
						>
							POS ID
						</InputWithLabel>
					</div>
					<div className="form-row row-full">
						<Textarea
							value={data?.selectedBrandLocation?.description}
							onChange={(e) =>
								handleForm("description", e.target.value, data?.selectedBrandLocation?.brandId)
							}
							readOnly={readOnly}
						>
							Description
						</Textarea>
					</div>
					<div className="form-row row-full">
						<Textarea
							value={data?.selectedBrandLocation?.seoDescription}
							onChange={(e) =>
								handleForm("seoDescription", e.target.value, data?.selectedBrandLocation?.brandId)
							}
							readOnly={readOnly}
						>
							SEO Description
						</Textarea>
					</div>
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	bizPlatforms: store.configItems.bizPlatforms,
	bizCountry: store.login.loggedInbizDetail.country,
	fulfillmentModes: store.configItems.fulfillmentModes
});
export default connect(mapStateToProps)(BasicInfo);
