import React, { useState, useEffect, useCallback } from "react";

// third party
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTrail, config, animated } from "react-spring";

// actions
import { fetchCustomerNotes, updateCustomerNote, deleteCustomerNote } from "../../actions/customers";

// components
import { GlobalConfirmModal } from "../SiteComp";

// utils
import { formatDate } from "../../atlas-utils";

const Notes = ({ customerNotes, customerId, trackEvent }) => {
	const { loading, data } = customerNotes;

	useEffect(() => {
		fetchCustomerNotes(customerId);
	}, [customerId]);

	const [editNoteId, setEditNoteId] = useState(null);
	const [showNoteModal, setNoteModal] = useState(false);
	const [modalBusy, setModalBusy] = useState(false);
	const [subject, setSubject] = useState("");
	const [description, setDescription] = useState("");

	const handleAction = useCallback(
		async (action, noteId) => {
			switch (action) {
				case "create":
					setEditNoteId(null);
					setSubject("");
					setDescription("");
					setNoteModal(true);
					break;
				case "edit":
					setEditNoteId(noteId);
					setNoteModal(true);
					const editNoteObj = data.find((note) => note.id === noteId);
					if (editNoteObj) {
						setSubject(editNoteObj.title || "");
						setDescription(editNoteObj.text || "");
					}
					break;
				case "delete":
					const variables = {
						ids: [noteId]
					};
					await deleteCustomerNote(variables, customerId);
					fetchCustomerNotes(customerId, "no-cache");
					break;
				default:
					break;
			}
		},
		[customerId, data]
	);

	const cancelNoteModal = useCallback(() => {
		if (!modalBusy) {
			setNoteModal(false);
		}
	}, []);

	const handleNoteModal = useCallback(async () => {
		setModalBusy(true);
		const variables = {
			id: customerId,
			customerNotes: [
				{
					title: subject,
					text: description
				}
			]
		};
		if (editNoteId) {
			variables.customerNotes[0].id = editNoteId;
		}
		await updateCustomerNote(variables, editNoteId ? "edit" : "create");
		setModalBusy(false);
		setNoteModal(false);
		setSubject("");
		setDescription("");
		setEditNoteId(null);

		// track this event
		trackEvent("customer_create_note");
	}, [customerId, subject, description, editNoteId]);

	const handleReplyInput = useCallback((e) => {
		switch (e.target.name) {
			case "subject":
				setSubject(e.target.value);
				break;
			case "description":
				setDescription(e.target.value);
				break;
			default:
				break;
		}
	}, []);

	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});

	return (
		<div
			className={
				(data.length > 0 && !modalBusy && loading ? "disabled" : "") + " customer-notes customer-details-tab"
			}
		>
			<div className="subtitle">Add notes to customer profile for future reference.</div>
			<div className={"add-note " + (loading ? "disabled" : "")} onClick={() => handleAction("create")}>
				+ Add a new note
			</div>
			{trails.map(({ rotate }, i) => (
				<Note
					key={data[i].id}
					style={{
						transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
					}}
					handleAction={handleAction}
					{...data[i]}
				/>
			))}
			{data.length === 0 && !loading && <div className="no-items-placeholder">No notes found!</div>}
			{data.length === 0 && loading && !modalBusy && (
				<div className="P(10px 0)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			)}
			<GlobalConfirmModal
				backdropHandler={cancelNoteModal}
				show={showNoteModal}
				confirmBtnEnabled={subject.trim() && description.trim()}
				confirmHandler={handleNoteModal}
				cancelHandler={cancelNoteModal}
				modalBusy={modalBusy}
				customButtons={!showNoteModal}
			>
				<div className="discard-confirm-modal" style={{ display: showNoteModal ? "block" : "none" }}>
					<div className="title">Note</div>
					<div className="modal-text C(#ccc)">Subject</div>
					<div className="save-campaign-title">
						<input
							onChange={handleReplyInput}
							name="subject"
							value={subject}
							type="text"
							placeholder="Subject"
						/>
					</div>
					<div className="modal-text Mt(15px) C(#ccc)">Description</div>
					<div className="segment-description">
						<input
							onChange={handleReplyInput}
							name="description"
							value={description}
							type="text"
							placeholder="Description"
						/>
					</div>
				</div>
			</GlobalConfirmModal>
		</div>
	);
};
export default withRouter(
	connect((store) => ({
		customerNotes: store.customerNotes
	}))(Notes)
);

const Note = ({ id, created, title, text, user = {}, handleAction, style }) => (
	<animated.div style={style} className="note-item">
		<div className="subject">
			<div className="subject-text">{title || "--"}</div>
			<div className="subject-text">
				{formatDate(created, "DD MMM YYYY")} <span>at</span> {formatDate(created, "hh:mm A")} <span>by</span>{" "}
				{user?.username || ""}
			</div>
		</div>
		<div className="description">{text}</div>
		<div className="note-actions">
			<div className="delete" onClick={() => handleAction("delete", id)}>
				Delete
			</div>
			<div className="edit" onClick={() => handleAction("edit", id)}>
				Edit
			</div>
		</div>
	</animated.div>
);
