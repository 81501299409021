import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// components
import { Basic } from "../components/CustomerDetails/Basic";
import Feedback from "../components/CustomerDetails/Feedback";
import Notes from "../components/CustomerDetails/Notes";
import Timeline from "../components/CustomerDetails/Timeline";
import Wallet from "../components/CustomerDetails/Wallet";
import { InfiniteTopBar } from "../components/SiteComp";
import { FormSidebar } from "../components/_commons/FormSidebar";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// utils
import history from "../history";
import { adjustNestedContainer } from "../atlas-utils";

// actions
import { fetchCustomer, fetchCustomersList, updateCustomer, updateWalletBalance } from "../actions/customers";
import { fetchTags } from "../actions/actions";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
export const RELOAD_TYPES = [
	{ label: "Promotional", value: "PROMOTIONAL" },
	{ label: "Refund", value: "REFUND" }
];
const TABS = ["Basic", "Timeline", "Wallet", "Feedback", "Notes"];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null,
	params: { type: "online" }
};

@connect(
	(store) => ({
		customerDetails: store.customerDetails,
		biz: store.login.loggedInbizDetail,
		productTypeOptions: store.selectedModule.productTypeOptions,
		configItems: store.configItems
	}),
	null,
	null,
	{ withRef: true }
)
export class CustomerDetails extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			selectedTab: TABS[0],
			isFormOpen: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			isWalletFormTouched: false,
			walletFormData: {
				amount: null,
				reloadType: RELOAD_TYPES[0],
				comments: ""
			},
			confirmLoading: false,
			error: {}
		};
		this.defaultPrevLocation = {
			pathname: "/customers",
			title: "Customers"
		};
	}

	componentDidMount() {
		setTimeout(() => this.setState({ isFormOpen: true }), 60);
		fetchCustomer(this.props.match.params.id);
		if (!this.props.configItems.tags.items.length) {
			fetchTags("");
		}

		// track this event
		// this.trackEvent('customer_detail_view');
	}

	handleCancel = () => {
		if (this.state.nestedEntity.show) {
			if (this.state.nestedEntity.type === "location") {
				this.nestedRef.current.handleCancel();
			} else {
				this.nestedRef.current.getWrappedInstance().handleCancel();
			}
			return;
		} else {
			this.setState({ isFormOpen: false });
			setTimeout(async () => {
				if (this.props.isNested || this.props.isForeignSource) {
					this.props.closeNestedContainer();
				} else {
					await fetchCustomersList();
					history.push("/customers");
				}
			}, 100);
		}
	};

	handleViewOrderDetail = (toOpen = false, type, id, orderType = "online") => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id,
					params: { type: orderType }
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewOrderDetail.bind(this);

	trackEvent = (eventName, meta = {}) => {
		if (eventName) {
			const eventMeta = {
				...meta,
				customer_id: this.props.match.params.id
			};
			PubSub.publish(TRACK_EVENT, {
				tracker: "mixpanel",
				eventName,
				eventMeta
			});
		}
	};

	switchTab = (selectedTab) => {
		this.setState({
			selectedTab
		});
	};

	handleCustomerUpdate = (field, value) => {
		const variables = {
			id: this.props.match.params.id,
			[field]: value
		};
		updateCustomer(variables);
	};

	handleForm = (field, value) => {
		this.setState({
			walletFormData: {
				...this.state.walletFormData,
				[field]: value
			},
			isWalletFormTouched: true
		});
	};

	handleSubmit = async () => {
		this.setState({
			confirmLoading: true,
			error: {}
		});
		let variables = {
			...this.state.walletFormData,
			customerId: this.props.match.params.id
		};
		if (variables.reloadType) {
			variables.reloadType = variables.reloadType.value;
		}
		const resp = await updateWalletBalance(variables);
		if (resp === true) {
			this.setState({
				walletFormData: {
					amount: "",
					reloadType: RELOAD_TYPES[0],
					comments: ""
				},
				confirmLoading: false,
				isWalletFormTouched: false
			});
			await fetchCustomer(this.props.match.params.id);
		} else {
			this.setState({
				confirmLoading: false,
				error: resp || {}
			});
		}
	};

	renderTitle = () => {
		return (
			<div className="personal-info">
				<img src="/assets/icons/user.png" alt="" />
				{this.props.customerDetails &&
				this.props.customerDetails.data &&
				(this.props.customerDetails.data.id || !this.props.customerDetails.loading) ? (
					<div>
						<div className="name">{this.props.customerDetails.data.fullName}</div>
						<div className="contact">
							<span>{this.props.customerDetails.data.phone || "--"}</span>
							{this.props.customerDetails.data.email && (
								<span> | {this.props.customerDetails.data.email}</span>
							)}
						</div>
					</div>
				) : (
					<div>
						<div className="name">Customer</div>
						<div className="contact">
							<span>Phone | Email</span>
						</div>
					</div>
				)}
			</div>
		);
	};

	render() {
		const { selectedTab, isFormOpen, nestedEntity, isWalletFormTouched, walletFormData, confirmLoading, error } =
			this.state;
		const { productTypeOptions, customerDetails, biz, isNested = false, match } = this.props;
		const { loading, data = {} } = customerDetails;

		return (
			<div className="customer-details-container">
				<FormSidebar
					isOpen={isFormOpen}
					close={this.handleCancel}
					submit={this.handleSubmit}
					title={this.renderTitle()}
					loading={loading}
					hideActions={selectedTab === TABS[2] ? !isWalletFormTouched : true}
					isNested={isNested}
				>
					<div>
						<Topbar tabs={TABS} selectedTab={selectedTab} switchTab={this.switchTab} isStickyOnTop={true} />
						{selectedTab === TABS[0] && (
							<Basic
								loading={loading}
								handleCustomerUpdate={this.handleCustomerUpdate}
								trackEvent={this.trackEvent}
								currencySymbol={biz.currencySymbol}
								productTypeOptions={productTypeOptions}
								configItems={this.props.configItems}
								{...data}
							/>
						)}
						{selectedTab === TABS[1] && (
							<Timeline
								customerId={parseInt(match.params.id)}
								handleNestedEntity={this.handleNestedEntity}
							/>
						)}
						{selectedTab === TABS[2] && (
							<Wallet
								customerId={parseInt(match.params.id)}
								walletBalance={data && data.walletBalance}
								data={walletFormData}
								loading={confirmLoading}
								validations={error && error.fields ? error.fields : {}}
								handleForm={this.handleForm}
								currencySymbol={biz.currencySymbol}
							/>
						)}
						{selectedTab === TABS[3] && (
							<Feedback
								customerId={parseInt(match.params.id)}
								customerName={customerDetails.data && customerDetails.data.fullName}
								handleNestedEntity={this.handleNestedEntity}
							/>
						)}
						{selectedTab === TABS[4] && (
							<Notes customerId={parseInt(match.params.id)} trackEvent={this.trackEvent} />
						)}
					</div>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						params={nestedEntity.params}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
					/>
				</FormSidebar>
			</div>
		);
	}
}

const Topbar = ({ tabs, selectedTab, switchTab, isStickyOnTop }) => {
	let index = tabs.indexOf(selectedTab);
	return (
		<InfiniteTopBar
			clickHandler={(e, i) => switchTab(tabs[i])}
			tabs={tabs}
			selected={index}
			isStickyOnTop={isStickyOnTop}
		/>
	);
};
