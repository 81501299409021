import React, { Component } from "react";

// components
import { CommonTable } from "../_commons/CommonTable";
import { Paginator } from "../_commons/Paginator";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";

// clients
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";
import PubSub from "pubsub-js";
import moment from "moment";

// actions
import { fetchReferralsList } from "../../actions/referrals";
import { ActionTypes } from "../../actions/_types";

// utils
import { scroll, adjustNestedContainer, capitaliseText } from "../../atlas-utils";

// config
import { NESTED_ENTITY_TYPES } from "../../client-config";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const REPLIES_INITIAL_STATE = {
	isOpen: false,
	id: null,
	name: null,
	comments: null,
	rating: null,
	replies: []
};

const extractReferralCode = (urlString) => {
	let splitWords = urlString.split("/");
	return splitWords.length !== 0 ? splitWords[splitWords.length - 1] : "";
};
const formatText = (text) => (text.toLowerCase() === "ios" ? "iOS" : capitaliseText(text));

const columns = [
	{
		name: "Referrer",
		field: "referrer",
		sortKey: "referrer",
		render: (record, i, a, cs, handleNestedEntity) => (
			<div className="at-table-cell at-cell-text referrer" title={record.itemTitle} key={i}>
				<div className="referrer-desc">
					{record.referrer ? (
						<a
							role="button"
							className="link-text"
							onClick={() =>
								handleNestedEntity(true, NESTED_ENTITY_TYPES[12], parseInt(record.referrer.id))
							}
						>
							<span className="hyperlink hyperlink--black-color">
								{record?.referrer?.fullName || record?.referrer?.id}
							</span>
						</a>
					) : (
						"--"
					)}
					<div className="text--light desc-text">{record?.referrer ? record?.referrer?.phone : ""}</div>
				</div>
			</div>
		)
	},
	{
		name: "Referred",
		field: "referred",
		sortKey: "referred",
		render: (record, i, a, cs, handleNestedEntity) => (
			<div className="at-table-cell at-cell-text referrer" title={record.itemTitle} key={i}>
				<div className="referred-desc">
					{record.referred ? (
						<a
							role="button"
							className="link-text"
							onClick={() =>
								handleNestedEntity(true, NESTED_ENTITY_TYPES[12], parseInt(record.referred.id))
							}
						>
							{record?.referred?.fullName || record?.referred?.id}
						</a>
					) : (
						"--"
					)}
					<div className="text--light desc-text">{record?.referred ? record?.referred?.phone : ""}</div>
				</div>
			</div>
		)
	},
	{
		name: "Date & Time",
		field: "sharedOn",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text sharedOn" key={i}>
				{record.sharedOn ? moment(record.sharedOn).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	},
	{
		name: "Referral Link / Code",
		field: "codeOrLink",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text codeOrLink" key={i}>
				<div title={record?.codeOrLink} className="text">
					{record.codeOrLink ? extractReferralCode(record?.codeOrLink) : "--"}
				</div>
				{record?.codeOrLink && (
					<img
						src="/assets/icons/icon-external-link.svg"
						alt=""
						onClick={() => {
							if (record?.codeOrLink) {
								window.open(record?.codeOrLink, "_blank").focus();
							}
						}}
					/>
				)}
			</div>
		)
	},
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text platform" key={i}>
				<div title={record?.platform} className="text">
					{record.platform ? formatText(record.platform) : "--"}
				</div>
			</div>
		)
	},
	{
		name: "Channel",
		field: "channel",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text channel" key={i}>
				<div title={record?.channel} className="text">
					{record.channel ? capitaliseText(record.channel) : "--"}
				</div>
			</div>
		)
	},
	{
		name: "Status",
		field: "status",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text status" key={i}>
				<div title={record?.status} className="text">
					<div
						className={`${"status-block"} ${
							record?.status.toLowerCase() === "unclaimed" ? "unclaimed" : "claimed"
						}`}
					>
						{record.status ? record.status : "--"}
					</div>
				</div>
			</div>
		)
	}
];

@connect((store) => ({
	referralsList: store.referralsList,
	referralsListState: store.referralsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class List extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			replies: REPLIES_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "customer_feedback_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.referralsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Items list
		await fetchReferralsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	}

	async componentWillReceiveProps(newProps) {
		if (
			this.props.referralsListState.appliedDateFilter.current.dateFilter !==
			newProps.referralsListState.appliedDateFilter.current.dateFilter
		) {
			// fetch data with updated applied filters
			await fetchReferralsList();
		}
	}

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updateReferralsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.REFERRALS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateReferralsListState({
			currentFilters: this.props.referralsListState.appliedFilters
		});
	};

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
			fetchReferralsList();
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.referralsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateReferralsListState({
			currentFilters
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.REFERRALS_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.REFERRALS_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchReferralsList(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateReferralsListState({
			appliedFilters: {
				...this.props.referralsListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "referrals_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchReferralsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().referralsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updateReferralsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchReferralsList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.referralsListState;
		const offset = (page - 1) * limit;
		this.updateReferralsListState({
			offset
		});
		// fetch new Items list
		fetchReferralsList();
		// scroll to top of the list
		scroll({ top: this.tableRef.offsetTop - 57, left: 0 });
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.referralsListState;
		if (size.value !== limit) {
			this.updateReferralsListState({
				[field]: size.value
			});
			// fetch new Items list
			await fetchReferralsList();
		}
		// scroll to top of the list
		scroll({ top: this.tableRef.offsetTop - 57, left: 0 });
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateReferralsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.REFERRALS_LIST_CHANGE_SORT_ORDER,
			payload: {
				sort
			}
		});
		fetchReferralsList();
	};

	openRepliesSidebar = (id, name, comments, rating, replies = []) => {
		this.setState({
			replies: {
				isOpen: true,
				id,
				name,
				comments,
				rating,
				replies
			}
		});
	};

	closeRepliesSidebar = (refresh = false) => {
		this.setState({ replies: REPLIES_INITIAL_STATE });
		fetchReferralsList();
	};

	render() {
		const {
			referralsList,
			referralsListState,
			searchKeywords,
			configItems,
			searchFieldSelected,
			handleSearchField,
			searchFieldValue,
			setSearchFilter,
			showFilters,
			setShowFilters,
			filterCount
		} = this.props;
		const { limit, offset, sortedField, currentDateFilter, appliedDateFilter } = referralsListState;

		const placeholderContent = {
			placeholderText: "No referrals yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-referrals.svg",
			placeholderSubtext:
				"View all customer referrals from your websites and apps here to keep a track of your customer base",
			size: "medium"
		};

		return (
			<div className="referrals-list-container" ref={(ref) => (this.tableRef = ref)}>
				<div className="filters">
					<NewDateCompareFilter
						showDropdown={true}
						loading={referralsList.loading}
						currentDateFilter={currentDateFilter}
						appliedDateFilter={appliedDateFilter}
						updateState={this.updateReferralsListState}
						includeAllTime={true}
						hidePresetTypes={["15 D"]}
					/>
					{configItems.dimensions.width > 768 && (
						<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={() => setShowFilters(!showFilters)}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
					)}
					<div className="header-actions">
						{searchKeywords && searchKeywords.length > 1
							? configItems.dimensions.width > 768 && (
									<div className="search-input-container">
										<SelectFilter
											options={searchKeywords}
											field="searchFieldSelected"
											currValue={searchFieldSelected}
											setFilter={handleSearchField}
											labelKey="valueForDisplay"
											valueKey="key"
											isSearchable={false}
											isClearable={false}
										/>
										<SearchFilter
											filterOption={{ field: "searchFieldValue" }}
											value={searchFieldValue}
											setFilter={setSearchFilter}
											placeholder="Search"
										/>
									</div>
							  )
							: configItems.dimensions.width > 768 && (
									<SearchFilter
										filterOption={{ field: "searchFieldValue" }}
										value={searchFieldValue}
										setFilter={setSearchFilter}
										placeholder="Search"
									/>
							  )}
					</div>
				</div>
				<CommonTable
					loading={referralsList.loading}
					data={referralsList?.data?.objects || []}
					columns={columns}
					sortList={this.sortList}
					sortedField={sortedField}
					handleTask={this.handleNestedEntity}
					openRepliesSidebar={this.openRepliesSidebar}
					classes="referrals-list-table-container"
					content="Referrals"
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={referralsList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		);
	}
}
