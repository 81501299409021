import React, { Component } from "react";

// third party
import { connect } from "react-redux";

// components
import { CustomerInfoItem } from "./Summary";

@connect((store) => ({
	biz: store.login.loggedInbizDetail
}))
export class InStoreSummary extends Component {
	render() {
		const { data, loading, biz } = this.props;
		const { billNumber, billDump, store, user, amount, id } = data;

		if (!id && loading) {
			return (
				<div className="transaction-details-summary">
					<div className="order-details">
						<div className="shimmer H(255px) Mb(15px)"></div>
					</div>
					<div className="customer-details">
						<div className="shimmer H(60px) Mb(10px)"></div>
						<div className="shimmer H(60px) Mb(10px)"></div>
						<div className="shimmer H(60px) Mb(10px)"></div>
						<div className="shimmer H(60px)"></div>
					</div>
				</div>
			);
		}
		return (
			<div className="transaction-details-summary">
				<div className="order-details">
					{billDump ? (
						<div className="order-details-bill-dump">{billDump}</div>
					) : (
						<div className="no-items-placeholder Pb(50px)">No Bill data available!</div>
					)}
				</div>
				<div className="customer-details">
					<div className="details-field-grid in-store">
						<CustomerInfoItem label="Outlet" value={store && store.title} />
						<CustomerInfoItem label="POS ID" value={billNumber} />
						<CustomerInfoItem label="Customer Name" value={user && user.firstName} metaClass="value-name" />
						<CustomerInfoItem label="Customer Phone" value={user && user.phone} />
						<CustomerInfoItem
							label="Purchase amount"
							value={amount}
							isCurrency={true}
							currencySymbol={biz.currencySymbol}
						/>
						<CustomerInfoItem label="Loyalty points earned" value={user && user.currentPoints} />
					</div>
				</div>
			</div>
		);
	}
}
