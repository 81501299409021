import gql from "graphql-tag";

export const GET_ENTITY_MODIFIER_GROUPS = gql`
	query entityOptionGroupAssociations(
		$entityId: Int!
		$entityType: OptionGroupAssociationEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$nested: Boolean
		$menuId: String
		$search: [SearchArgument]
	) {
		entityOptionGroupAssociations(
			entityId: $entityId
			entityType: $entityType
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
			nested: $nested
			menuId: $menuId
			search: $search
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			objects {
				id
				optionGroupTitle
				optionGroupDesc
				crmTitle
				optionGroupMaxSelectable
				merchantRefId
				isAssociated
				itemsCount
				modifiersCount
				isVariant
			}
		}
	}
`;

export const UPDATE_ENTITY_MODIFIER_GROUPS = gql`
	mutation updateItemModifierGroups(
		$itemId: Int!
		$modifierGroupsToAssociate: [Int]
		$modifierGroupsToDisassociate: [Int]
	) {
		updateItemModifierGroups(
			input: {
				itemId: $itemId
				modifierGroupsToAssociate: $modifierGroupsToAssociate
				modifierGroupsToDisassociate: $modifierGroupsToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_OPTION_OPTION_GROUPS = gql`
	mutation updateBizItemOptionOptionGroups(
		$optionId: Int!
		$optionGroupsToAssociate: [Int]
		$optionGroupsToDisassociate: [Int]
		$nestedOptionGroupsToAssociate: [Int]
		$nestedOptionGroupsToDisassociate: [Int]
	) {
		updateBizItemOptionOptionGroups(
			input: {
				optionId: $optionId
				optionGroupsToAssociate: $optionGroupsToAssociate
				optionGroupsToDisassociate: $optionGroupsToDisassociate
				nestedOptionGroupsToAssociate: $nestedOptionGroupsToAssociate
				nestedOptionGroupsToDisassociate: $nestedOptionGroupsToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
