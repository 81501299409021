import React, { useEffect } from "react";

// third party
import { connect } from "react-redux";

// component
import { ProgressBar } from "../_commons/ProgressBar";
import EndResult from "./EndResult";

const BrandsCreationProgress = ({
	endResult,
	createBrand,
	brandsCreationLocationAssociationState,
	totalLocationsSelected,
	isLocationAssociationInProgress,
	setLocationAssociationInProgress,
	successfullyAssociatedLocationsCount,
	exponentialBackOffTimeoutRef
}) => {
	const { currentAssociatedLocationsCount, status } = brandsCreationLocationAssociationState;

	const handleLocationAssociationProgress = () => {
		if (status === "PROCESSED") {
			if (exponentialBackOffTimeoutRef.current) {
				clearTimeout(exponentialBackOffTimeoutRef.current);
			}
			setTimeout(() => {
				setLocationAssociationInProgress(false);
			}, 500);
		}
	};

	useEffect(handleLocationAssociationProgress, [status]);

	if (endResult === "failure") {
		return <EndResult endResult={endResult} createBrand={createBrand} />;
	}

	if (isLocationAssociationInProgress && totalLocationsSelected !== 0) {
		return (
			<div className="brand-creation-flow--progress">
				<div>Creating brand & associating locations</div>
				<ProgressBar
					maximum={totalLocationsSelected}
					currProgress={
						successfullyAssociatedLocationsCount > (currentAssociatedLocationsCount || 0)
							? successfullyAssociatedLocationsCount
							: currentAssociatedLocationsCount || 0
					}
					animated
				/>
			</div>
		);
	}

	return <EndResult endResult={endResult} createBrand={createBrand} />;
};
export default BrandsCreationProgress;
