import React, { useState, useEffect, useCallback } from "react";

// components
import { FormSidebar } from "../../_commons/FormSidebar";
import { ButtonIcon } from "../../_commons/ButtonIcon";
import { CustomTable } from "../../_commons/CustomTable";

// third party
import { connect } from "react-redux";
import moment from "moment";

// store
import { store } from "../../../store/configureStore";
import { client } from "../../../client";

// utils
import { getSortOrder, extractInitials, getDurationAsString } from "../../../atlas-utils";

// graphql
import {
	GET_AVAILABILITY_HEATMAP_DETAIL_DATA,
	GET_AVAILABILITY_HEATMAP_LOCATION_DETAIL_DATA
} from "../../../graphql/analytics";

// actions
import { ActionTypes } from "../../../actions/_types";
import { getAllAppliedFilters } from "../../../actions/analytics";

// constants
import { CATALOGUE_PLATFORMS_LOGO, BRAND_COLORS } from "../../../client-config";
const INIT_AVAILABILITY_DATA = {
	sort: {
		field: "availability_percentage",
		order: "ASC"
	},
	sortedField: "availability_percentage",
	objects: []
};
const INIT_LOCATION_AVAILABILITY_DATA = {
	objects: {
		timingGroup: [],
		locationData: []
	}
};
const availabilityColumns = [
	{
		name: "Location",
		field: "location",
		render: (record, i) => (
			<div className="table-cell location" title={record?.locationName} key={i}>
				<b>{record?.locationName}</b>
			</div>
		)
	},
	{
		name: "Brand",
		field: "brand",
		render: (record, i) => (
			<div className="table-cell brand" key={i}>
				{record?.brandCount}
			</div>
		)
	},
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div className="table-cell platform" key={i}>
				{record?.platformCount}
			</div>
		)
	},
	{
		name: "Total Offline Time",
		field: "offline-time",
		sortKey: "offline_seconds",
		render: (record, i) => (
			<div className="table-cell offline-time" key={i}>
				{record?.offlineSeconds
					? getDurationAsString(Math.round(record?.offlineSeconds) || 0, "minutes")
					: `0 min`}
			</div>
		)
	},
	{
		name: "Availability",
		field: "availability",
		sortKey: "availability_percentage",
		render: (record, i) => (
			<div className="table-cell availability" key={i}>
				{record?.availabilityPercentage?.toFixed(1)}%
			</div>
		)
	}
];
const locationAvailabilityColumns = [
	{
		name: "Brand",
		field: "brand",
		render: (record, i) => (
			<div className="table-cell brand" title={record?.brandName} key={i}>
				<div className={"logo " + BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]}>
					{record?.brandLogo ? (
						<img src={record?.brandLogo} alt="" />
					) : (
						extractInitials(record?.brandName?.split(" "))
					)}
				</div>
				<div className="name">{record?.brandName || "HOUSE OF FALAFEL"}</div>
			</div>
		)
	},
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div className="table-cell platform" title={record?.platform} key={i}>
				<img
					className="logo"
					src={
						CATALOGUE_PLATFORMS_LOGO[record?.platform?.toLowerCase()] || CATALOGUE_PLATFORMS_LOGO["default"]
					}
					alt=""
				/>
				<div className="name">{record?.platform}</div>
			</div>
		)
	},
	{
		name: "Total Offline Time",
		field: "offline-time",
		render: (record, i) => (
			<div className="table-cell offline-time" key={i}>
				{record?.offlineSeconds
					? getDurationAsString(Math.round(record?.offlineSeconds) || 0, "minutes")
					: `0 min`}
			</div>
		)
	},
	{
		name: "Expected Online Time",
		field: "online-time",
		render: (record, i) => (
			<div className="table-cell online-time" key={i}>
				{record?.expectedAvailability
					? getDurationAsString(Math.round(record?.expectedAvailability) || 0, "minutes")
					: `0 min`}
			</div>
		)
	},
	{
		name: "Availability",
		field: "availability",
		render: (record, i) => (
			<div className="table-cell availability" key={i}>
				{record?.availabilityPercentage?.toFixed(1)}%
			</div>
		)
	}
];

const RestaurantAvailability = ({ isOpen = false, close, data = {}, isMultibrandEnabled = false }) => {
	const [isFormOpen, setFormOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [showAvailabilityInfo, setShowAvailabilityInfo] = useState(true);
	const [showLocationAvailabilityInfo, setShowLocationAvailabilityInfo] = useState(true);
	const [selectedLocation, setSelectedLocation] = useState(undefined);
	const [availabilityData, setAvailabilityData] = useState(INIT_AVAILABILITY_DATA);
	const [locationAvailabilityData, setLocationAvailabilityData] = useState(INIT_LOCATION_AVAILABILITY_DATA);

	const getAvailabilityDetails = useCallback(async () => {
		setLoading(true);
		try {
			const { durationObject, filters, bizId } = getAllAppliedFilters();
			const variables = {
				filters: [
					...filters,
					{
						field: "group_by",
						value: data?.compareFilter?.value
					},
					{
						field: "DURATION_START_DATETIME",
						value: data?.data?.timestamp
					},
					{
						field: "DURATION_END_DATETIME",
						value: moment(data?.data?.timestamp)
							?.add(1, data.compareFilter?.type)
							?.format("YYYY-MM-DD HH:mm:ss")
					}
				],
				requiredFilters: {
					bizId,
					duration: durationObject.duration
				},
				query: "operation_location_availability_detail_heatmap",
				sort: availabilityData.sort
			};

			// if viewing selected location availability: add location_id filter, update query and delete sort
			if (selectedLocation?.locationId) {
				variables.filters.push({
					field: "location_id",
					value: selectedLocation?.locationId
				});
				variables.query = "operation_location_availability_location_detail_heatmap";
				delete variables.sort;
			}

			const resp = await client.query({
				query: selectedLocation?.locationId
					? GET_AVAILABILITY_HEATMAP_LOCATION_DETAIL_DATA
					: GET_AVAILABILITY_HEATMAP_DETAIL_DATA,
				variables,
				fetchPolicy: "cache-first"
			});

			if (selectedLocation?.locationId) {
				setLocationAvailabilityData(resp?.data?.getAvailabilityHeatmapLocationDetailData);
			} else {
				setAvailabilityData({
					...availabilityData,
					objects: resp?.data?.getAvailabilityHeatmapDetailData?.objects || []
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [data, availabilityData, selectedLocation]);

	const handleClose = () => {
		// reset state
		setFormOpen(false);
		setLoading(false);
		setSelectedLocation(undefined);
		setBreadcrumbs([]);
		setShowAvailabilityInfo(true);
		setShowLocationAvailabilityInfo(true);
		setAvailabilityData(INIT_AVAILABILITY_DATA);
		setLocationAvailabilityData(INIT_LOCATION_AVAILABILITY_DATA);
		close();
	};

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => setFormOpen(true), 60);
			getAvailabilityDetails();
		}
	}, [isOpen, selectedLocation, availabilityData?.sort]);

	const handleSort = (field) => {
		setAvailabilityData({
			...availabilityData,
			sort: {
				field: field,
				order: getSortOrder(availabilityData?.sort, { field })
			},
			sortedField: field
		});
	};

	const handleBreadcrumb = () => {
		setSelectedLocation(undefined);
		setLocationAvailabilityData(INIT_LOCATION_AVAILABILITY_DATA);
		setShowLocationAvailabilityInfo(true);
		setBreadcrumbs([]);
	};

	const handleTableRowClick = (data) => {
		setSelectedLocation(data);
		setBreadcrumbs([
			{
				title: "Restaurant Availability",
				onClick: handleBreadcrumb
			}
		]);
		setShowAvailabilityInfo(true);
	};

	const getSubTitle = () => {
		let dateTime = "";

		if (data?.data?.timestamp) {
			const startDate = moment(data?.data?.timestamp)?.format("DD MMM YYYY");
			const startTime = moment(data?.data?.timestamp)?.format("hA");
			const endDate = moment(data?.data?.timestamp)?.add(1, data?.compareFilter?.type)?.format("DD MMM YYYY");
			const endTime = moment(data?.data?.timestamp)?.add(1, data?.compareFilter?.type)?.format("hA");
			dateTime = `${startDate}, ${startTime} — ${startDate !== endDate ? `${endDate}, ` : ""}${endTime}`;
			return dateTime;
		}

		if (data?.serieId) {
			dateTime = `${data?.serieId}, ${data?.data?.x}`;
			return dateTime;
		}

		return dateTime;
	};

	return (
		<div className="restaurant-availability-detail">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleClose}
				title={selectedLocation?.locationName || "Restaurant Availability"}
				subTitle={getSubTitle()}
				isNested={false}
				hideActions={true}
				breadcrumbs={breadcrumbs}
			>
				<div className="form-content">
					{showAvailabilityInfo && !selectedLocation?.locationId && (
						<div className="info-card-container">
							<div className="icon">
								<ButtonIcon icon="info" color="#2543B6" />
							</div>
							<div className="info-content">
								<div className="title">
									{data?.compareFilter?.label === "Hours"
										? "Offline minutes may exceed 60 due to cumulative unavailability"
										: "Offline time may exceed standard periods"}
								</div>
								<div className="description">
									Total offline time is calculated by adding the unavailable time of multiple
									combinations of brands and platforms for each location.
								</div>
							</div>
							<div className="close" onClick={() => setShowAvailabilityInfo(false)}>
								<img src="/assets/icons/cancel.png" alt="" />
							</div>
						</div>
					)}
					{showLocationAvailabilityInfo &&
						selectedLocation?.locationId &&
						locationAvailabilityData?.objects?.timingGroup?.length > 0 && (
							<div className="info-card-container">
								<div className="icon">
									<ButtonIcon icon="info" color="#2543B6" />
								</div>
								<div className="info-content">
									<div className="title">Different Operational Hours</div>
									{locationAvailabilityData?.objects?.timingGroup?.map((grp) =>
										isMultibrandEnabled && grp?.brandName && grp?.slots?.[0]?.start ? (
											<div className="description">
												Your brand <span>"{grp.brandName}"</span> is operational starting from{" "}
												<span>{moment(grp.slots[0].start, "HH:mm")?.format("hh:mm A")}</span>
											</div>
										) : grp?.slots?.[0]?.start ? (
											<div className="description">
												Your location is operational starting from{" "}
												<span>{moment(grp.slots[0].start, "HH:mm")?.format("hh:mm A")}</span>
											</div>
										) : null
									)}
								</div>
								<div className="close" onClick={() => setShowLocationAvailabilityInfo(false)}>
									<img src="/assets/icons/cancel.png" alt="" />
								</div>
							</div>
						)}
					<CustomTable
						bordered={true}
						loading={loading}
						data={
							selectedLocation?.locationId
								? locationAvailabilityData?.objects?.locationData
								: availabilityData?.objects
						}
						columns={selectedLocation?.locationId ? locationAvailabilityColumns : availabilityColumns}
						sortList={handleSort}
						sortedField={availabilityData?.sortedField}
						sortedOrder={availabilityData?.sort?.order}
						classes={
							selectedLocation?.locationId ? "location-table-container" : "availability-table-container"
						}
						customPlaceholder={
							selectedLocation?.locationId
								? "No data found"
								: Number(data?.value) === 0
								? "All your locations were online"
								: "No locations found"
						}
						isRowClickable={!selectedLocation}
						rowClickHandler={(rowData) => handleTableRowClick(rowData)}
						hideColumns={!isMultibrandEnabled ? ["brand"] : []}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(RestaurantAvailability);
