import React from "react";

// components
import { OutsideClick } from "./OutsideClick";

// assets
import TripleDotIcon from "../_icons/TripleDotIcon";

const ContextMenu = ({
	isOpen = false,
	data,
	handleOpenMenu,
	disableContextMenu = false,
	renderMenuItems,
	nodeRef,
	appliedInlineStyles = {},
	children,
	classes = "",
	rest
}) => {
	return (
		<div
			style={{ ...(appliedInlineStyles ?? {}) }}
			className={
				"context-menu " +
				(isOpen ? "open " : "") +
				(disableContextMenu ? "disabled " : "") +
				(children ? "custom " : "") +
				classes
			}
			ref={nodeRef}
		>
			{children ? (
				<div className="custom-btn" onClick={handleOpenMenu}>
					{children}
				</div>
			) : (
				<div className="icon-dots" onClick={handleOpenMenu}>
					<TripleDotIcon />
				</div>
			)}
			<div className="menu">{isOpen ? renderMenuItems(data, rest) : null}</div>
		</div>
	);
};
export default OutsideClick(ContextMenu);
