// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_API_ACCOUNTS_LIST, GET_API_ACCOUNT, EDIT_API_ACCOUNT } from "../graphql/apiAccounts";

// utils
import { parseErrorMessages } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchAPIAccountsList = async () => {
	const { limit, offset, sort, appliedFilters, isActive } = store.getState().apiAccountsListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_API_ACCOUNTS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [
			{
				field: "is_active",
				value: String(isActive)
			}
		];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_API_ACCOUNTS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_API_ACCOUNTS_LIST_SUCCESS,
			payload: { ...resp.data.users }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_API_ACCOUNTS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			break;
		case "server":
			const output = {
				id: result.id,
				name: result.fullName
			};
			if (result["groups"]) {
				const groups = result["groups"].map((grp) => parseInt(grp.id));
				output["roles"] = groups;
			}
			if (result["locations"]) {
				const locations = result["locations"].map((location) => parseInt(location.id));
				output["locations"] = locations;
			}
			result = output;
		default:
			break;
	}
	return result;
};

export const fetchAPIAccountDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_API_ACCOUNT_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_API_ACCOUNT,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_API_ACCOUNT_DETAIL_SUCCESS,
			payload: parseData(resp.data.user, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_API_ACCOUNT_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching API account details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editAPIAccountDetail = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_API_ACCOUNT_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_API_ACCOUNT,
			variables: parseData(data, "server")
		});
		if (resp.data.saveApiUser.status.success) {
			dispatch({
				type: ActionTypes.EDIT_API_ACCOUNT_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "API account details saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_API_ACCOUNTS_LIST,
				payload: resp.data.saveApiUser.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_API_ACCOUNT_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveApiUser.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_API_ACCOUNT_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving API account details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreApiAccess = async (isActive, data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_API_ACCOUNT,
			variables: { ...parseData(data, "server"), isActive }
		});
		if (resp.data.saveApiUser.status.success) {
			return "success";
		} else {
			return "failure";
		}
	} catch (e) {
		console.log(e);
		return "failure";
	}
};
