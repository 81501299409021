import React from "react";

// components
import CategoryEdit from "../../containers/CategoryEdit";
import ItemEdit from "../../containers/ItemEdit";
import LocationEdit from "../../containers/LocationEdit";
import TaxEdit from "../../containers/TaxEdit";
import ChargeEdit from "../../containers/ChargeEdit";
import ModifierGroupEdit from "../../containers/ModifierGroupEdit";
import ModifierEdit from "../../containers/ModifierEdit";
import ItemGroupEdit from "../../containers/ItemGroupEdit";
import LocationGroupEdit from "../../containers/LocationGroupEdit";
import UserEdit from "../../containers/UserEdit";
import ItemTagEdit from "../../containers/ItemTagEdit";
import { TransactionDetails } from "../../containers/TransactionDetails";
import { CustomerDetails } from "../../containers/CustomerDetails";
import PiperAcademy from "../../containers/PiperAcademy";
import PayoutUploadHelp from "../../containers/PayoutUploadHelp";
import TimingGroupCreate from "../../containers/TimingGroupCreate";
import TimingGroupEdit from "../../containers/TimingGroupEdit";
import CatalogueBackupPreview from "../../containers/CatalogueBackupPreview";
import UnifiedUserEdit from "../../containers/UnifiedUserEdit";
import MenuCreate from "../../containers/MenuCreate";
import CatalogueVerification from "../LocationsList/CatalogueVerification";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";

export const NestedEntityContainer = ({
	show = false,
	type,
	id,
	params = {},
	closeNestedContainer,
	nestedRef,
	isNested = true,
	isForeignSource = false,
	tab = null,
	...rest
}) => {
	if (show) {
		let NestedComponent = null;
		let props = {
			match: {
				params: {
					id: id,
					...params
				}
			},
			isNested: isNested,
			closeNestedContainer: closeNestedContainer,
			connectedRef: nestedRef,
			isForeignSource: isForeignSource,
			ref: nestedRef,
			id: id,
			tab: tab
		};
		switch (type) {
			case NESTED_ENTITY_TYPES[0]:
				NestedComponent = CategoryEdit;
				break;
			case NESTED_ENTITY_TYPES[1]:
				NestedComponent = ItemEdit;
				break;
			case NESTED_ENTITY_TYPES[2]:
				NestedComponent = LocationEdit;
				break;
			case NESTED_ENTITY_TYPES[3]:
				NestedComponent = TaxEdit;
				break;
			case NESTED_ENTITY_TYPES[4]:
				NestedComponent = ChargeEdit;
				break;
			case NESTED_ENTITY_TYPES[5]:
				NestedComponent = ModifierGroupEdit;
				break;
			case NESTED_ENTITY_TYPES[6]:
				NestedComponent = ModifierEdit;
				break;
			case NESTED_ENTITY_TYPES[7]:
				NestedComponent = ItemGroupEdit;
				break;
			case NESTED_ENTITY_TYPES[8]:
				NestedComponent = LocationGroupEdit;
				break;
			case NESTED_ENTITY_TYPES[9]:
				NestedComponent = UserEdit;
				break;
			case NESTED_ENTITY_TYPES[10]:
				NestedComponent = ItemTagEdit;
				break;
			case NESTED_ENTITY_TYPES[11]:
				NestedComponent = TransactionDetails;
				break;
			case NESTED_ENTITY_TYPES[12]:
				NestedComponent = CustomerDetails;
				break;
			case NESTED_ENTITY_TYPES[13]:
				NestedComponent = PiperAcademy;
				break;
			case NESTED_ENTITY_TYPES[14]:
				NestedComponent = PayoutUploadHelp;
				break;
			case NESTED_ENTITY_TYPES[15]:
				NestedComponent = TimingGroupCreate;
				break;
			case NESTED_ENTITY_TYPES[16]:
				NestedComponent = TimingGroupEdit;
				break;
			case NESTED_ENTITY_TYPES[17]:
				NestedComponent = CatalogueBackupPreview;
				break;
			case NESTED_ENTITY_TYPES[18]:
				NestedComponent = UnifiedUserEdit;
				break;
			case NESTED_ENTITY_TYPES[19]:
				NestedComponent = MenuCreate;
				break;
			case NESTED_ENTITY_TYPES[20]:
				NestedComponent = CatalogueVerification;
				break;
			default:
				break;
		}
		if (NestedComponent) {
			return <NestedComponent {...props} {...rest} />;
		}
	}
	return null;
};
