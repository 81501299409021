import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// third party
import moment from "moment";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";

//actions
import { checkForEmptyDetails, fetchHolidayScheduleList, getStartEndTimes } from "../actions/timingGroups";

// utils
import HolidaySchedule from "../components/TimingGroupEdit/HolidaySchedule";
import history from "../history";
import { SLOT_INITIAL_STATE } from "./TimingGroupCreate";
import { store } from "../store/configureStore";
import { CREATE_HOLIDAY_TIMING_GROUP } from "../graphql/timingGroups";
import { ActionTypes } from "../actions/_types";
import { client } from "../client";
import { parseErrorMessages } from "../atlas-utils";

export const HOLIDAY_TIME_SLOT_INITIAL_STATE = {
	title: "",
	isClosedAllDay: null,
	date: moment().add(1, "days"),
	slots: [SLOT_INITIAL_STATE]
};

const HolidayScheduleCreate = ({ hasAccess = false, configItems, locationDetails }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);

	const [expandedSections, setExpandedSections] = useState({});
	const [error, setError] = useState({});
	const [isClosedAllDay, setIsClosedAllDay] = useState(true);
	const [holidaySlotData, setHolidaySlotData] = useState(HOLIDAY_TIME_SLOT_INITIAL_STATE);

	const handleDateSelection = (type, value) => {
		if (type === "date") {
			setHolidaySlotData({
				...holidaySlotData,
				date: value
			});
			if (expandedSections?.date) {
				handleSectionExpansion("date");
			}
			return;
		}
	};

	const handleSectionExpansion = (field) => {
		setExpandedSections((current) => {
			const updatedExpandedSections = {
				...current
			};
			if (current[field]) {
				delete updatedExpandedSections[field];
			} else {
				updatedExpandedSections[field] = true;
			}

			return updatedExpandedSections;
		});
	};

	const handleSectionOutsideClick = (field) => {
		setExpandedSections((current) => {
			const updatedExpandedSections = {
				...current
			};
			if (current[field]) {
				delete updatedExpandedSections[field];
			}
			return updatedExpandedSections;
		});
	};

	const handleForm = (field, value) => {
		if (field === "addSlot") {
			setHolidaySlotData({
				...holidaySlotData,
				slots: [...holidaySlotData?.slots, SLOT_INITIAL_STATE]
			});
		} else {
			setHolidaySlotData({
				...holidaySlotData,
				[field]: value
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCancel = () => {
		setFormState(false);
		fetchHolidayScheduleList(true, "", configItems?.brands?.selectedBrand?.id, 10, 0, {
			field: "title",
			order: "ASC"
		});
		setTimeout(() => {
			history.push("/timing-groups");
		}, 100);
	};

	const handleClosedDay = () => {
		setIsClosedAllDay(!isClosedAllDay);
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				title: holidaySlotData?.title,
				crmTitle: holidaySlotData?.crmTitle,
				date: holidaySlotData?.date?.format("YYYY-MM-DD")
			};
			if (!isClosedAllDay && checkForEmptyDetails([holidaySlotData?.slots])) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Timings are incomplete, please add the closed timings",
						timeout: 3000,
						error: true
					}
				});
				setConfirmLoading(false);
				return;
			}
			const daySlots = [];
			if (isClosedAllDay) {
				const daySlot = {
					day: holidaySlotData?.date?.format("dddd").toUpperCase(),
					slots: [
						{
							startTime: "00:00",
							endTime: "23:59"
						}
					]
				};
				daySlots.push(daySlot);
			} else {
				const formattedSlots = holidaySlotData?.slots?.map((slot) => getStartEndTimes(slot));
				const daySlot = {
					day: holidaySlotData?.date?.format("dddd").toUpperCase(),
					slots: formattedSlots
				};
				daySlots.push(daySlot);
			}

			variables.daySlots = daySlots;
			const resp = await client.mutate({
				mutation: CREATE_HOLIDAY_TIMING_GROUP,
				variables
			});
			if (resp.data.saveHolidayHour.status.success) {
				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Holiday Schedule created successfully",
						timeout: 5000,
						error: false
					}
				});
				if (locationDetails?.redirectionId) {
					const id = locationDetails?.redirectionId;
					store.dispatch({
						type: ActionTypes.CLEAR_REDIRECTION
					});
					history.push(`/locations/edit/${id}`);
				} else {
					history.push(`/timing-groups/holiday-edit/${resp.data.saveHolidayHour.object.id}`);
				}
			} else {
				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveHolidayHour.status.messages));
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message:
							resp?.data?.saveHolidayHour?.status?.messages?.[0]?.message ||
							error.message ||
							"Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};
	const handleTimeSlots = (data, _, updatedSlotIndex) => {
		const updatedSlots = holidaySlotData.slots.map((slot, slotIndex) => {
			if (slotIndex === updatedSlotIndex) {
				const updatedSlotDetails = {
					...slot,
					...data
				};
				return updatedSlotDetails;
			}
			return slot;
		});
		setHolidaySlotData({
			...holidaySlotData,
			slots: updatedSlots
		});
	};

	const handleDeleteTimeSlot = (deleteSlotIndex) => {
		const updatedTimeSlots = holidaySlotData?.slots?.filter((slot, slotIndex) => slotIndex !== deleteSlotIndex);
		setHolidaySlotData({
			...holidaySlotData,
			slots: updatedTimeSlots
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Create Holiday Schedule"
			subTitle="Enter details for this schedule"
			loading={confirmLoading}
			submitTitle={"Save"}
			disabled={holidaySlotData?.title?.length === 0}
			hideActions={!isFormTouched}
		>
			<div className="holiday-schedule-wrapper">
				<HolidaySchedule
					handleForm={handleForm}
					readOnly={!hasAccess}
					expandedSections={expandedSections}
					holidaySlotData={holidaySlotData}
					handleSectionExpansion={handleSectionExpansion}
					handleSectionOutsideClick={handleSectionOutsideClick}
					handleDateSelection={handleDateSelection}
					handleClosedDay={handleClosedDay}
					isClosedAllDay={isClosedAllDay}
					error={error}
					handleTimeSlots={handleTimeSlots}
					handleDeleteTimeSlot={handleDeleteTimeSlot}
				/>
			</div>
		</FormSidebar>
	);
};

const mapStateToProps = (store) => ({
	configItems: store.configItems,
	locationDetails: store.locationDetailsReducer
});
export default connect(mapStateToProps)(HolidayScheduleCreate);
