import React, { useRef, useEffect, useState, useCallback, useImperativeHandle } from "react";

// components
import { Button } from "../_commons/Button";

// utils
import { generateUniqueId } from "../../atlas-utils";

// third party
import { debounce } from "lodash";

// constants
import { DEFAULT_LOCATION } from "../../client-config";
const GMAP_KEY = process.env.REACT_APP_GMAP_KEY;

const defPaths = [
	{
		lat: 12.971320102946944,
		lng: 77.73009437086986
	},
	{
		lat: 12.970755528879595,
		lng: 77.7345146513264
	},
	{
		lat: 12.96866450265437,
		lng: 77.73131745818065
	}
];

export const CaptureLocationPolygon = ({
	latLong = {},
	setPolygons,
	foreignPresetPolygons = [],
	presetPolygons = [],
	close,
	locationName = "",
	city = "",
	fromLocationCreateWizard = false,
	fromManagePolygons = false,
	updateState,
	handleSelectPolygon,
	savePolygonsRef = null,
	nearbyLocationsArray = [],
	height = "250px",
	handleFormSubmission = () => {}
}) => {
	const [isVisible, setVisible] = useState(false);
	const [mapTouched, setMapTouched] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const gmap = useRef();
	const marker = useRef();
	const drawingManager = useRef();
	const gmapRef = useRef();
	const drawnPolygons = useRef([]);
	const currentPresetPolygons = useRef([]);
	const selectedPolygon = useRef();

	useEffect(() => {
		setTimeout(() => {
			setVisible(true);
		}, 2000);
	}, []);

	const getCoordsFromPolygon = useCallback((polygon) => {
		let coords = "";
		const path = polygon.getPath();
		for (let i = 0; i < path.getLength(); i++) {
			const vertices = path.getAt(i);
			coords += `${vertices.lat()},${vertices.lng()} `;
		}
		return coords;
	}, []);

	const savePolygons = useCallback(
		(isFormSubmission = false) => {
			let polygons = [];
			drawnPolygons.current.forEach((polygon) => {
				if (polygon.strokeColor === "#000") {
					return;
				} else {
					const polygonObj = {
						coordinates: getCoordsFromPolygon(polygon)
					};
					if (polygon.polygonId) {
						polygonObj.id = parseInt(polygon.polygonId);
					}
					if (polygon.deliveryCharge) {
						polygonObj.deliveryCharge = polygon.deliveryCharge;
					}
					polygons.push(polygonObj);
				}
			});
			setPolygons(polygons);
			if (isFormSubmission) {
				handleFormSubmission(polygons);
			}
			close();
		},
		[setPolygons, getCoordsFromPolygon, close]
	);

	const editPolygon = () => {
		// update polygons state
		if (typeof updateState === "function") {
			updateState(selectedPolygon.current);
		}
	};

	const deletePolygon = useCallback(() => {
		selectedPolygon.current.setMap(null);

		// delete polygon
		for (let i = 0; i < drawnPolygons.current.length; i++) {
			if (selectedPolygon.current.uniqueId === drawnPolygons.current[i].uniqueId) {
				drawnPolygons.current.splice(i, 1);
			}
		}
		for (let i = 0; i < currentPresetPolygons.current.length; i++) {
			if (selectedPolygon.current.uniqueId === currentPresetPolygons.current[i].uniqueId) {
				currentPresetPolygons.current.splice(i, 1);
			}
		}
		selectedPolygon.current = null;
		setShowDelete(false);
		// update polygons state
		if (typeof updateState === "function") {
			updateState();
		}
	}, []);

	const clearSelection = () => {
		if (selectedPolygon.current) {
			selectedPolygon.current.set("strokeColor", "#18d0b2");
			selectedPolygon.current.set("fillColor", "#18d0b2");
			selectedPolygon.current.setEditable(false);
			selectedPolygon.current.setDraggable(false);
			selectedPolygon.current = null;
			// clear polygon
			if (typeof handleSelectPolygon === "function") {
				handleSelectPolygon(null);
			}
		}
		setShowDelete(false);
	};

	const setSelection = (polygon) => {
		clearSelection();
		if (polygon.strokeColor === "#000") {
			return;
		}
		selectedPolygon.current = polygon;
		selectedPolygon.current.set("strokeColor", "#2f58f2");
		selectedPolygon.current.set("fillColor", "#2f58f2");
		selectedPolygon.current.setEditable(true);
		selectedPolygon.current.setDraggable(true);
		setShowDelete(true);
		// create bounds object to set correct viewport bounds for selected polygon
		const bounds = new window.google.maps.LatLngBounds();
		let newCoords = [];
		let coordinates = polygon.coordinates;
		if (coordinates) {
			coordinates.split(" ").forEach((coords) => {
				coords = coords.trim();
				if (coords === "") {
					return;
				}
				let latLng = coords.split(",").filter((num) => num !== "None");
				const lat = latLng[0];
				const lng = latLng[1];
				const point = new window.google.maps.LatLng(lat, lng);
				newCoords.push(point);

				// extend bound to fit this polygon
				bounds.extend(point);
			});
			// fit maps bound
			if (!bounds.isEmpty()) {
				gmap.current.fitBounds(bounds);
			}
		}
		// select polygon
		if (typeof handleSelectPolygon === "function") {
			handleSelectPolygon(polygon.uniqueId);
		}
	};

	const createGoogleMap = () => {
		// create google map instance
		gmap.current = new window.google.maps.Map(gmapRef.current, {
			zoom: fromLocationCreateWizard ? 16 : 12,
			center: latLong.lat && latLong.lng ? latLong : DEFAULT_LOCATION,
			disableDefaultUI: true,
			zoomControl: true,
			fullscreenControl: true
		});
		// create drwaing manager instance
		drawingManager.current = new window.google.maps.drawing.DrawingManager({
			drawingMode: fromLocationCreateWizard ? window.google.maps.drawing.OverlayType.POLYGON : null,
			drawingControl: true,
			drawingControlOptions: {
				drawingModes: ["polygon"]
			},
			polygonOptions: {
				editable: true,
				fillColor: "#2f58f2",
				strokeColor: "#2f58f2",
				draggable: true
			}
		});
		// add drawing manager to map
		drawingManager.current.setMap(gmap.current);
		// add event listener on drawing manager
		window.google.maps.event.addListener(drawingManager.current, "polygoncomplete", function (polygon) {
			// switch to non-drawing mode after drawing a polygon
			drawingManager.current.setDrawingMode(null);

			// get polygon coordinates
			let coordinates = "";
			polygon
				.getPath()
				.getArray()
				.forEach((coord) => {
					coordinates = `${coord.lat()},${coord.lng()} ${coordinates}`;
				});

			// set custom properties (options) for new polygon
			polygon.setOptions({
				uniqueId: generateUniqueId(),
				deliveryCharge: null,
				coordinates
			});

			// set this newly drawn polygon to array
			drawnPolygons.current.push(polygon);
			// set this newly drawn polygon to current preset polygons too
			currentPresetPolygons.current.push(polygon);

			// add event handler for this new polygon
			window.google.maps.event.addListener(polygon, "click", () => {
				setSelection(polygon);
			});
			window.google.maps.event.addListener(
				polygon.getPath(),
				"set_at",
				debounce(() => {
					fromLocationCreateWizard ? savePolygons() : editPolygon();
				}, 300)
			);
			window.google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
				fromLocationCreateWizard ? savePolygons() : editPolygon();
			});

			// update polygons state
			if (typeof updateState === "function") {
				updateState(polygon);
			}

			// set button states
			if (!mapTouched) {
				setMapTouched(true);
			}
		});

		// reset current selection when map is clicked or drawing mode is changed
		window.google.maps.event.addListener(drawingManager.current, "drawingmode_changed", clearSelection);
		window.google.maps.event.addListener(gmap.current, "click", clearSelection);
		// update polygons state
		if (typeof updateState === "function") {
			updateState();
		}
	};

	const generateInfoWindowContent = (city = "", locationName = "") =>
		'<div id="content">' +
		'<div id="siteNotice">' +
		"</div>" +
		`<h1 id="firstHeading" class="firstHeading"><img src="/assets/icons/icon-meraki.svg" alt= "location logo">${
			locationName || "location"
		}</h1>` +
		'<div id="bodyContent">' +
		`<p>${city}</p>` +
		"</div>" +
		"</div>";

	const createMarkerAndPolygons = () => {
		// create marker for actual location
		marker.current = new window.google.maps.Marker({
			position: latLong.lat && latLong.lng ? latLong : DEFAULT_LOCATION,
			map: gmap.current,
			animation: window.google.maps.Animation.DROP,
			icon: {
				url: "/assets/icons/icon-gmap-current.png",
				scaledSize: new window.google.maps.Size(40, 40)
			}
		});
		const contentString = generateInfoWindowContent(city, locationName);

		const infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		marker.current.addListener("click", () => {
			infowindow.open({
				anchor: marker.current,
				map: gmap.current,
				shouldFocus: true
			});
		});

		// marking the nearby locations
		nearbyLocationsArray.forEach((nearbyLocation) => {
			if (nearbyLocation.latitude && nearbyLocation.longitude) {
				const infowindow = new window.google.maps.InfoWindow({
					content: generateInfoWindowContent(nearbyLocation.city, nearbyLocation.name)
				});
				const nearbyLocationMarker = new window.google.maps.Marker({
					position: { lat: nearbyLocation.latitude, lng: nearbyLocation.longitude },
					map: gmap.current,
					icon: {
						url: "/assets/icons/icon-gmap-nearby.png",
						scaledSize: new window.google.maps.Size(30, 30)
					}
				});
				nearbyLocationMarker.addListener("click", () => {
					infowindow.open({
						anchor: nearbyLocationMarker,
						map: gmap.current,
						shouldFocus: true
					});
				});
			}
		});

		// create bounds object to set correct viewport bounds
		const bounds = new window.google.maps.LatLngBounds();

		// create the preloaded polygons
		presetPolygons.forEach((polygon) => {
			let newCoords = [];
			let coordinates = polygon.coordinates;
			if (coordinates) {
				coordinates.split(" ").forEach((coords) => {
					coords = coords.trim();
					if (coords === "") {
						return;
					}
					let latLng = coords.split(",").filter((num) => num !== "None");
					const lat = latLng[0];
					const lng = latLng[1];
					const point = new window.google.maps.LatLng(lat, lng);
					newCoords.push(point);

					// extend bound to fit this polygon
					bounds.extend(point);
				});
			}

			const drawnPolygon = new window.google.maps.Polygon({
				uniqueId: generateUniqueId(),
				polygonId: polygon.id || undefined,
				deliveryCharge: polygon.deliveryCharge || null,
				path: newCoords,
				coordinates: polygon.coordinates,
				editable: false,
				draggable: false,
				map: gmap.current,
				strokeColor: "#18d0b2",
				fillColor: "#18d0b2",
				zIndex: 10
			});
			drawnPolygons.current.push(drawnPolygon);
			// current preset polygons
			currentPresetPolygons.current.push(drawnPolygon);
			// set event handler
			window.google.maps.event.addListener(drawnPolygon, "click", () => {
				setSelection(drawnPolygon);
			});
			window.google.maps.event.addListener(
				drawnPolygon.getPath(),
				"set_at",
				debounce(() => {
					fromLocationCreateWizard ? savePolygons() : editPolygon();
				}, 300)
			);
			window.google.maps.event.addListener(drawnPolygon.getPath(), "insert_at", () => {
				fromLocationCreateWizard ? savePolygons() : editPolygon();
			});
			window.google.maps.event.addListener(drawnPolygon, "mouseover", function () {
				this.map &&
					this.map.getDiv().setAttribute("title", `Polygon set for ${locationName || "current"} location`);
			});
			window.google.maps.event.addListener(drawnPolygon, "mouseout", function () {
				this.map && this.map.getDiv().removeAttribute("title");
			});
		});
		// create the nearby preloaded polygons (serves the purpose of overlap prevention for user)
		foreignPresetPolygons.forEach((polygon) => {
			let newCoords = [];
			let coordinates = polygon.coordinates;
			if (coordinates) {
				coordinates.split(" ").forEach((coords) => {
					coords = coords.trim();
					if (coords === "") {
						return;
					}
					let latLng = coords.split(",").filter((num) => num !== "None");
					const lat = latLng[0];
					const lng = latLng[1];
					const point = new window.google.maps.LatLng(lat, lng);
					newCoords.push(point);
				});
			}
			const drawnPolygon = new window.google.maps.Polygon({
				uniqueId: generateUniqueId(),
				path: newCoords,
				editable: false,
				draggable: false,
				map: gmap.current,
				strokeColor: "#000",
				fillColor: "#000",
				cursor: "default"
			});
			drawnPolygons.current.push(drawnPolygon);

			// set event handler
			window.google.maps.event.addListener(drawnPolygon, "click", () => {
				setSelection(drawnPolygon);
			});
			window.google.maps.event.addListener(drawnPolygon, "mouseover", function () {
				this.map && this.map.getDiv().setAttribute("title", "Polygon set for a nearby location");
			});
			window.google.maps.event.addListener(drawnPolygon, "mouseout", function () {
				this.map && this.map.getDiv().removeAttribute("title");
			});
		});

		// fit maps bound
		if (!bounds.isEmpty()) {
			gmap.current.fitBounds(bounds);
		}
		// update polygons state
		if (typeof updateState === "function") {
			updateState();
		}
	};

	// This event handler takes care of missing out search suggestions
	document.onfullscreenchange = function (event) {
		let target = event.target;
		let pacContainerElements = document.getElementsByClassName("pac-container");
		if (pacContainerElements.length > 0) {
			let pacContainer = document.getElementsByClassName("pac-container")[0];
			if (pacContainer.parentElement === target) {
				document.getElementsByTagName("body")[0].appendChild(pacContainer);
			} else {
				target.appendChild(pacContainer);
			}
		}
	};

	const createSearchInput = () => {
		const input = document.getElementById("pac-input");
		gmap.current.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
		const autocomplete = new window.google.maps.places.Autocomplete(input);
		// Bind the map's bounds (viewport) property to the autocomplete object,
		// so that the autocomplete requests use the current map bounds for the
		// bounds option in the request.
		autocomplete.bindTo("bounds", gmap.current);
		// Set the data fields to return when the user selects a place.
		autocomplete.setFields(["address_components", "geometry", "icon", "name"]);

		autocomplete.addListener("place_changed", () => {
			const place = autocomplete.getPlace();
			if (!place.geometry) {
				// User entered the name of a Place that was not suggested and
				// pressed the Enter key, or the Place Details request failed.
				window.alert("No details available for input: '" + place.name + "'");
				return;
			}

			const { address_components } = place;
			let placesAvailableForPrinting = address_components?.length > 2;

			const addressMarkup = () =>
				placesAvailableForPrinting
					? `<p>${address_components[0].short_name}, ${address_components[1].short_name}</p>`
					: "<p></p>";

			const contentString =
				'<div id="content">' +
				'<div id="siteNotice">' +
				"</div>" +
				`<h1 id="firstHeading" class="firstHeading"><img src= ${place?.icon} alt= "location logo">${place.name}</h1>` +
				'<div id="bodyContent">' +
				addressMarkup() +
				"</div>" +
				"</div>";

			const infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			// If the place has a geometry, then present it on a map.
			const marker = new window.google.maps.Marker({
				position: place.geometry.location,
				map: gmap.current,
				title: place.name,
				icon: {
					url: "/assets/icons/icon-gmap-search.png",
					scaledSize: new window.google.maps.Size(40, 40)
				}
			});
			// maintaining the infoWindow open by default
			infowindow.open({
				anchor: marker,
				map: gmap.current,
				shouldFocus: true
			});

			marker.addListener("click", () => {
				infowindow.open({
					anchor: marker,
					map: gmap.current,
					shouldFocus: true
				});
			});

			if (place.geometry.viewport) {
				gmap.current.fitBounds(place.geometry.viewport);
			} else {
				gmap.current.setCenter(place.geometry.location);
			}
		});
	};

	const updatePolygons = (polygons) => {
		currentPresetPolygons.current = polygons;
	};

	const initializeMap = () => {
		createGoogleMap();
		createMarkerAndPolygons();
		createSearchInput();
	};

	useEffect(() => {
		if (!window.google) {
			const gMapScript = document.createElement("script");
			gMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GMAP_KEY}&libraries=places,drawing`;
			window.document.body.appendChild(gMapScript);
			gMapScript.addEventListener("load", () => {
				initializeMap();
			});
		} else {
			initializeMap();
		}
	}, []);

	useImperativeHandle(savePolygonsRef, () => ({
		savePolygons: (isFormSubmission = false) => savePolygons(isFormSubmission),
		deletePolygon: () => deletePolygon(),
		setSelection: (polygon) => setSelection(polygon),
		updatePolygons: (polygons) => updatePolygons(polygons),
		presetPolygons: currentPresetPolygons.current || [],
		selectedPolygon: selectedPolygon.current || undefined
	}));

	return (
		<div className="capture-location-polygons">
			<div className="gmap-container">
				<input
					style={{ visibility: isVisible ? "visible" : "hidden" }}
					id="pac-input"
					className="controls"
					type="text"
					placeholder="Search locations"
				/>
				<div
					ref={gmapRef}
					style={{
						width: "100%",
						height: height
					}}
				/>
			</div>
			<div className="action-buttons">
				{
					!fromLocationCreateWizard && !fromManagePolygons && (
						<Button clickHandler={() => savePolygons(true)} classes="polygon-save">
							Save and close
						</Button>
					)
					// :
					// <Button
					// 	classes={"save-btn"}
					// 	clickHandler={() => savePolygons(true)}
					// >
					// 	Save
					// </Button>
				}
				{showDelete && !fromManagePolygons && (
					<Button classes="at-btn--danger polygon-delete" clickHandler={deletePolygon}>
						Delete polygon
					</Button>
				)}
			</div>
		</div>
	);
};
