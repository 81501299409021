import React, { useEffect } from "react";

// third party
import { connect } from "react-redux";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";
import { Textarea } from "../_commons/Textarea";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

const General = ({
	loginDetail,
	showLanguages = false,
	currSelectedLang = {},
	handleLanguage,
	handleTranslation,
	saveTranslation,
	isMultibrandEnabled = false,
	renderEnableServiceConfirmationModal,
	...props
}) => {
	const {
		biz,
		bizForm,
		loading,
		configValues,
		updateBizForm,
		handleConfigValues,
		locationsList,
		handleLocationSearch,
		currLocation,
		handleCurrLocation,
		handleBrandsLabelOption,
		brands,
		handleBrand,
		loggedInbizDetail
	} = props;

	useEffect(() => {
		if (biz) {
			const isEisEnabled = biz.modulesEnabled.includes("EIS") ? true : false;
			if (isEisEnabled !== bizForm.isEisEnabled) {
				updateBizForm({
					...bizForm,
					isEisEnabled
				});
			}
		}
	}, [biz]);

	const handleChange = (field, value) => {
		updateBizForm({ [field]: value });
	};

	const isInternalUser = loginDetail?.email?.includes("@urbanpiper.com") || false;
	const mocSupportedCountries = ["UK", "United Kingdom", "India", "USA", "United States", "Canada"];

	return (
		<div className="bizprofile-container">
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-business-configurations.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Business Configurations</div>
							<div className="header-subtext">Configure basic settings of your business</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={
								currSelectedLang
									? currSelectedLang.lang === "en"
										? bizForm.name
										: currSelectedLang.value
									: bizForm.name
							}
							onChange={(e) =>
								showLanguages
									? handleTranslation("name", e.target.value)
									: handleChange("name", e.target.value)
							}
							requiredLabel={!showLanguages}
							showLanguages={showLanguages}
							currSelectedLang={(currSelectedLang && currSelectedLang.lang) || ""}
							handleLanguage={(lang) => handleLanguage(lang, "name")}
							showLoading={true}
							showActionBtn={currSelectedLang && currSelectedLang.showActionBtn}
							action={() =>
								saveTranslation(
									"name",
									currSelectedLang
										? currSelectedLang.lang === "en"
											? bizForm.name
											: currSelectedLang.value
										: bizForm.name
								)
							}
							field="biz_name"
							classes={currSelectedLang && currSelectedLang.showActionBtn ? "at-input--save" : ""}
						>
							Business display name
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.id}
							onChange={(e) => handleChange("id", e.target.value)}
							readOnly={true}
							showCopyToClipboard={true}
							showIcon={true}
							classes="at-input--copy"
						>
							Business ID
						</InputWithLabel>
						{isInternalUser && (
							<Switch
								title="Integrated with POS"
								checked={bizForm.isInventoryIntegrationEnabled}
								clickHandler={() =>
									handleChange(
										"isInventoryIntegrationEnabled",
										!bizForm.isInventoryIntegrationEnabled
									)
								}
								readOnly={true}
							/>
						)}
					</div>
					{isInternalUser && (
						<div className="form-row row-half">
							<Switch
								title="Enable Hub"
								checked={bizForm.isHubEnabled}
								clickHandler={() => handleChange("isHubEnabled", !bizForm.isHubEnabled)}
							/>
							<Switch
								title="Enable Meraki"
								checked={bizForm.isOrderingEnabled}
								clickHandler={() => handleChange("isOrderingEnabled", !bizForm.isOrderingEnabled)}
							/>
							<Switch
								title="Enable Prime"
								checked={bizForm.isPrimeEnabled}
								clickHandler={() => handleChange("isPrimeEnabled", !bizForm.isPrimeEnabled)}
							/>
						</div>
					)}
					{isInternalUser && (
						<div className="form-row row-half">
							<Switch
								title="Enable Comet"
								checked={bizForm.isCometEnabled}
								clickHandler={() => handleChange("isCometEnabled", !bizForm.isCometEnabled)}
							/>
							<Switch
								title="Enable EIS"
								checked={bizForm.isEisEnabled}
								clickHandler={() => handleChange("isEisEnabled", !bizForm.isEisEnabled)}
								showCustomTooltip={!bizForm.eisSupportEnabled ? !bizForm.isEisEnabled : false}
							/>
							<Switch
								title="Enable Prism"
								checked={bizForm.isPrismEnabled}
								clickHandler={() => handleChange("isPrismEnabled", !bizForm.isPrismEnabled)}
							/>
						</div>
					)}
					{isInternalUser && (
						<div className="form-row row-half">
							<Switch
								title="Enforce location for Option"
								checked={configValues.business_info_config_enforce_opt_location || false}
								clickHandler={() =>
									handleConfigValues(
										"business_info_config_enforce_opt_location",
										configValues.business_info_config_enforce_opt_location ? false : true
									)
								}
							/>
							<Switch
								title="Same Option can be selected for ordering"
								checked={configValues.business_info_config_multi_option || false}
								clickHandler={() =>
									handleConfigValues(
										"business_info_config_multi_option",
										configValues.business_info_config_multi_option ? false : true
									)
								}
							/>
							<Switch
								title="Enable Reconciliation"
								checked={bizForm.isReconEnabled}
								clickHandler={() => handleChange("isReconEnabled", !bizForm.isReconEnabled)}
							/>
						</div>
					)}
					{isInternalUser && (
						<div className="form-row row-half">
							<Switch
								title="Enable Auto Backup"
								checked={configValues.enable_auto_catalog_backup || false}
								clickHandler={() =>
									handleConfigValues(
										"enable_auto_catalog_backup",
										configValues.enable_auto_catalog_backup ? false : true
									)
								}
							/>
							<Switch
								title="Enable Multibrand"
								checked={bizForm.isMultibrandEnabled}
								clickHandler={() =>
									renderEnableServiceConfirmationModal(
										"isMultibrandEnabled",
										!bizForm.isMultibrandEnabled,
										"multibrand"
									)
								}
								classes={bizForm.isMultibrandEnabled ? "disabled" : ""}
							/>
							<Switch
								title="Enable PG Service"
								checked={bizForm.isPgServiceEnabled}
								clickHandler={() => handleChange("isPgServiceEnabled", !bizForm.isPgServiceEnabled)}
							/>
						</div>
					)}

					<div className="form-row row-half">
						{isInternalUser && mocSupportedCountries?.includes(loggedInbizDetail?.country) && (
							<div className="form-row row-half">
								<Switch
									title="Enable Menu V2"
									checked={bizForm.isMenuOverCatalogueEnabled}
									clickHandler={() =>
										renderEnableServiceConfirmationModal(
											"isMenuOverCatalogueEnabled",
											!bizForm.isMenuOverCatalogueEnabled,
											"moc"
										)
									}
								/>
							</div>
						)}

						{/* 2 way toggle */}
						{/* {isInternalUser && (isIndiaBiz || isUKBiz) && (
							<div className="form-row row-half">
								<Switch
									title="Enable Menu V2"
									checked={bizForm.isMenuOverCatalogueEnabled}
									clickHandler={() =>
										handleChange("isMenuOverCatalogueEnabled", !bizForm.isMenuOverCatalogueEnabled)
									}
								/>
							</div>
						)} */}

						{isInternalUser && (
							<Switch
								title="Enable Base Validation"
								checked={bizForm?.isBaseMenuValidationEnabled}
								clickHandler={() =>
									handleChange("isBaseMenuValidationEnabled", !bizForm?.isBaseMenuValidationEnabled)
								}
							/>
						)}
					</div>

					<div className="form-row row-full">
						<Textarea
							value={bizForm.description}
							onChange={(e) => handleChange("description", e.target.value)}
						>
							Description
						</Textarea>
					</div>
					<div className="form-row row-full">
						<Textarea
							value={bizForm.seoDescription}
							onChange={(e) => handleChange("seoDescription", e.target.value)}
						>
							SEO Description
						</Textarea>
					</div>
				</div>
			</div>
			{isInternalUser && (
				<div className="card-container">
					<div className="card-header">
						<div className="header-container">
							<div className="app-icon">
								<img src="/assets/icons/icon-config.svg" alt="" />
							</div>
							<div className="app-header">
								<div className="header-text">Dash Configurations</div>
								<div className="header-subtext">Manage your dash configurations here</div>
							</div>
						</div>
						<div className="actions">
							{isMultibrandEnabled && (
								<SelectFilterCustom
									options={brands.items}
									isLoading={brands.isLoading}
									field="brands"
									currValue={brands.selectedBrand}
									setFilter={(f, value) => handleBrand(value)}
									labelKey="name"
									valueKey="id"
									isSearchable={false}
									customLabel={true}
									customOptions={true}
									// isClearable={true}
									renderLabel={handleBrandsLabelOption}
									renderOptions={handleBrandsLabelOption}
									placeholder="Select brand"
									handleBrand={handleBrand}
								/>
							)}
							<SelectFilter
								options={locationsList.data}
								isLoading={locationsList.isLoading}
								field="currLocation"
								currValue={currLocation}
								setFilter={handleCurrLocation}
								labelKey="title"
								valueKey="id"
								isClearable={false}
								isAsync={true}
								handleSearch={handleLocationSearch}
							/>
						</div>
					</div>
					<div className={"form-container" + (loading ? " disabled" : "")}>
						{(!isMultibrandEnabled && currLocation?.id === "default") ||
						(isMultibrandEnabled && currLocation === null) ? (
							<div className="form-row row-half">
								<Switch
									title="Enable Dash"
									checked={configValues.dash_enabled || false}
									clickHandler={() =>
										handleConfigValues("dash_enabled", configValues.dash_enabled ? false : true)
									}
								/>
								<Switch
									title="Auto Assignment"
									checked={configValues.dash_enable_auto_assign || false}
									clickHandler={() =>
										handleConfigValues(
											"dash_enable_auto_assign",
											configValues.dash_enable_auto_assign ? false : true
										)
									}
								/>
							</div>
						) : (
							<div className="form-row row-half">
								<div>
									{typeof configValues.dash_enabled !== "boolean" ? (
										<React.Fragment>
											<div className="custom-meta-info">Enable Dash</div>
											<div className="default">Default</div>
											<div
												className="link-text hover Mt(10px)"
												onClick={() => handleConfigValues("dash_enabled", false)}
											>
												Update
											</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Switch
												title="Enable Dash"
												checked={configValues.dash_enabled || false}
												clickHandler={() =>
													handleConfigValues(
														"dash_enabled",
														configValues.dash_enabled ? false : true
													)
												}
											/>
											{!loading && (
												<div
													className="link-text hover Mt(10px)"
													onClick={() => handleConfigValues("dash_enabled", "")}
												>
													Reset to Default
												</div>
											)}
										</React.Fragment>
									)}
								</div>
								<div>
									{typeof configValues.dash_enable_auto_assign !== "boolean" ? (
										<React.Fragment>
											<div className="custom-meta-info">Auto Assignment</div>
											<div className="default">Default</div>
											<div
												className="link-text hover Mt(10px)"
												onClick={() => handleConfigValues("dash_enable_auto_assign", false)}
											>
												Update
											</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Switch
												title="Auto Assignment"
												checked={configValues.dash_enable_auto_assign || false}
												clickHandler={() =>
													handleConfigValues(
														"dash_enable_auto_assign",
														configValues.dash_enable_auto_assign ? false : true
													)
												}
											/>
											{!loading && (
												<div
													className="link-text hover Mt(10px)"
													onClick={() => handleConfigValues("dash_enable_auto_assign", "")}
												>
													Reset to Default
												</div>
											)}
										</React.Fragment>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-contact-info.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Contact Info</div>
							<div className="header-subtext">Configure contact info for your customers to reach you</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={bizForm.contactPersonName || ""}
							onChange={(e) => handleChange("contactPersonName", e.target.value)}
						>
							Contact person name
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.bizWebsite || ""}
							onChange={(e) => handleChange("bizWebsite", e.target.value)}
						>
							Website URL
						</InputWithLabel>
					</div>
					<div className="form-row row-full">
						<TagsInputWrapper
							tags={bizForm.contactEmails ? bizForm.contactEmails.split(",") : []}
							onChange={(tags) => handleChange("contactEmails", tags.join(","))}
							placeholder={"Enter email"}
							validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
							onValidationReject={() => alert("Please enter valid email")}
						>
							Contact mail IDs
						</TagsInputWrapper>
					</div>
					<div className="form-row row-full">
						<TagsInputWrapper
							tags={bizForm.billingEmails ? bizForm.billingEmails.split(",") : []}
							onChange={(tags) => handleChange("billingEmails", tags.join(","))}
							placeholder={"Enter email"}
							validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
							onValidationReject={() => alert("Please enter valid email")}
						>
							Billing Contact mail IDs
						</TagsInputWrapper>
					</div>
					<div className="form-row row-full">
						<TagsInputWrapper
							tags={bizForm.billingPhones ? bizForm.billingPhones.split(",") : []}
							onChange={(tags) => handleChange("billingPhones", tags.join(","))}
							placeholder={"Enter phone"}
							validationRegex={/^\d{10}$/}
							onValidationReject={() => alert("Please enter valid 10 digit phone number")}
						>
							Contact phones
						</TagsInputWrapper>
					</div>
				</div>
			</div>
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-localisation.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Localisation</div>
							<div className="header-subtext">
								Manage settings based on your region/country of business
							</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={bizForm.timezone || "--"}
							onChange={(e) => handleChange("timezone", e.target.value)}
							readOnly={true}
						>
							Timezone
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.bizIsdCode || "--"}
							onChange={(e) => handleChange("bizIsdCode", e.target.value)}
							readOnly={true}
						>
							ISD Code
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.currency || "--"}
							onChange={(e) => handleChange("currency", e.target.value)}
							readOnly={true}
						>
							Currency
						</InputWithLabel>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	loginDetail: store.login.loginDetail,
	loggedInbizDetail: store.login.loggedInbizDetail,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(General);
