import React from "react";
import { connect } from "react-redux";
import { getPlatformDetailViaName } from "../../../helpers/periscope";

const TablePlatforms = ({ tableName, periscopeLocationAvailabilityState, textRight = false, configItems }) => {
	const bizPlatforms = configItems.bizPlatforms.items;
	const platformDetail = getPlatformDetailViaName(tableName, bizPlatforms);

	const locationState = periscopeLocationAvailabilityState.sort;
	let sortedPlatformName = "";
	let orderStatus = "";
	let platformName = platformDetail?.platformName;

	if (locationState) {
		sortedPlatformName = locationState?.field;
		orderStatus = locationState?.order;
	}

	const topImage = <img src="/assets/periscope/top-face.svg" />;
	const bottomFacedImage = <img src="/assets/periscope/down-face.svg" />;
	const topBlueImage = <img src="/assets/periscope/topBlue.svg" />;
	const bottomFacedBlueImage = <img src="/assets/periscope/bottomFacedBlue.svg" />;

	const platformImage = platformDetail?.image;

	return (
		<div
			className="table-heading-platforms"
			style={{ display: "flex", margin: "16px", ...(textRight ? { justifyContent: "right" } : {}) }}
		>
			<span>
				<img src={platformImage} className="icon-platforms" />
			</span>
			<span className="display-platform header-text-location ">{platformName}</span>
			<span style={{ display: "flex" }} className="sort-arrows-location">
				{sortedPlatformName === tableName ? (
					orderStatus === "asc" ? (
						<>
							{topBlueImage}
							{bottomFacedImage}
						</>
					) : orderStatus === "desc" ? (
						<>
							{topImage}

							{bottomFacedBlueImage}
						</>
					) : (
						<>
							{topImage}
							{bottomFacedImage}
						</>
					)
				) : (
					<>
						{topImage}
						{bottomFacedImage}
					</>
				)}
			</span>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeLocationAvailabilityState: state.periscopeLocationAvailabilityState,
	configItems: state.configItems
});
export default connect(mapStateToProps)(TablePlatforms);
