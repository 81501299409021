import React, { useState, useCallback, useEffect } from "react";

// component
import { Switch } from "../_commons/Switch";
import { Button } from "../_commons/Button";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
const MODES = [
	{
		label: "Pickup",
		value: "pickup"
	},
	{
		label: "Delivery",
		value: "delivery"
	}
];

const Fulfillment = ({ platform, platformId, locationId, brandId = null }) => {
	// for handling error responses, there are 2 requests but chances of having different outputs
	// i.e. error & success is very less, thus for now having common actionResponse
	const [actionResponse, setActionResponse] = useState(undefined);
	const [isFormUntouched, setFormUntouched] = useState(true);
	const [loading, setLoading] = useState(false);
	// for showing toggle only if data loaded
	const [dataLoadPickupSuccess, setDataLoadPickupSuccess] = useState(false);
	const [dataLoadDeliverySuccess, setDataLoadDeliverySuccess] = useState(false);

	// for storing the status after fetching
	const [origPickupStatus, setOrigPickupStatus] = useState(false);
	const [origDeliveryStatus, setOrigDeliveryStatus] = useState(false);
	// for status that is to be altered, need 2 states as two seperate request
	// based on the changed values have to be made.
	const [showPickupStatus, setShowPickupStatus] = useState(false);
	const [showDeliveryStatus, setShowDeliveryStatus] = useState(false);

	const resetValues = () => {
		setFormUntouched(true);
		setShowPickupStatus(origPickupStatus);
		setShowDeliveryStatus(origDeliveryStatus);
	};

	const fetchData = useCallback(
		async (mode) => {
			setLoading(true);
			try {
				setActionResponse(null);

				const token = store.getState().login.loginDetail.token;
				const headers = {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				};
				let url =
					process.env.REACT_APP_API_URL_FULFILLMENT +
					`?location_id=${locationId}&platform_id=${platformId}&mode=${mode}`;
				if (brandId) {
					url = `${url}&brand_id=${brandId}`;
				}
				const res = await fetch(url, {
					method: "GET",
					headers: headers
				});
				if (res.status == 200) {
					const data = await res.json();
					if (mode == MODES[0].value) {
						setOrigPickupStatus(data.data.enabled);
						setShowPickupStatus(data.data.enabled);
						setDataLoadPickupSuccess(true);
					} else {
						setOrigDeliveryStatus(data.data.enabled);
						setShowDeliveryStatus(data.data.enabled);
						setDataLoadDeliverySuccess(true);
					}
				} else {
					const msg = await res.json();
					setActionResponse({
						status: false,
						message: msg.message
					});
				}
			} catch (error) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[locationId, platformId]
	);

	useEffect(() => {
		MODES.map((mode) => {
			fetchData(mode["value"]);
		});
	}, [fetchData]);

	const updateValues = () => {
		if (!isFormUntouched) {
			if (showPickupStatus !== origPickupStatus) {
				updateFulfillment(MODES[0].value, showPickupStatus);
			}
			if (showDeliveryStatus !== origDeliveryStatus) {
				updateFulfillment(MODES[1].value, showDeliveryStatus);
			}
		}
	};

	const updateFulfillment = useCallback(async (mode, status) => {
		setLoading(true);
		try {
			setActionResponse(null);

			const token = store.getState().login.loginDetail.token;
			const headers = {
				Authorization: `Bearer ${token}`
			};
			var formData = new FormData();
			formData.append("platform_id", platformId);
			formData.append("location_id", locationId);
			formData.append("mode", mode);
			formData.append("status", status);
			if (brandId) {
				formData.append("brand_id", brandId);
			}

			let url = process.env.REACT_APP_API_URL_FULFILLMENT;
			const res = await fetch(url, {
				method: "POST",
				headers: headers,
				body: formData
			});
			if (res.status == 200) {
				setFormUntouched(true);
				const msg = await res.json();
				if (mode == MODES[0].value) {
					setOrigPickupStatus(status);
					setShowPickupStatus(status);
				} else {
					setOrigDeliveryStatus(status);
					setShowDeliveryStatus(status);
				}
				setActionResponse({
					status: true,
					message: msg.message
				});
			} else {
				const msg = await res.json();
				resetValues();
				setActionResponse({
					status: false,
					message: msg.message
				});
			}
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	});

	return (
		<div>
			{loading && !dataLoadPickupSuccess && !dataLoadDeliverySuccess && (
				<div className="Pstart(25px) Pend(25px)">
					<div className="shimmer H(100px)" />
				</div>
			)}
			<div className={"form-row row-half " + (loading ? "disabled" : "")}>
				{dataLoadPickupSuccess && (
					<Switch
						title="Pickup Enabled"
						checked={showPickupStatus}
						clickHandler={() => {
							if (isFormUntouched) {
								setFormUntouched(false);
							}
							setShowPickupStatus(!showPickupStatus);
						}}
					/>
				)}
				{dataLoadDeliverySuccess && (
					<Switch
						title="Delivery Enabled"
						checked={showDeliveryStatus}
						clickHandler={() => {
							if (isFormUntouched) {
								setFormUntouched(false);
							}
							setShowDeliveryStatus(!showDeliveryStatus);
						}}
					/>
				)}
			</div>
			{actionResponse && (
				<div className="fulfillment-action-message">
					<div
						onClick={() => {
							setActionResponse(null);
						}}
						className="close"
					>
						&times;
					</div>
					<div className={"fulfillment-response " + (actionResponse.status ? "success" : "fail")}>
						{actionResponse.message}
					</div>
				</div>
			)}
			{!isFormUntouched && <FulfillmentActions updateValues={updateValues} resetValues={resetValues} />}
		</div>
	);
};

const FulfillmentActions = ({ updateValues, resetValues }) => {
	return (
		<div className="form-action Mstart(10px) Mt(30px)">
			<Button clickHandler={updateValues} classes={"W(100px)"}>
				Save
			</Button>
			<Button clickHandler={resetValues} classes={"W(100px)"} type="secondary">
				Cancel
			</Button>
		</div>
	);
};

export default Fulfillment;
