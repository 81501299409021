import React, { useEffect, useState } from "react";

// components
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// third party
import { connect } from "react-redux";
import moment from "moment";
import { useTrail, config, animated } from "react-spring";
import { XAxis, YAxis, CartesianGrid, Bar, BarChart, LabelList, ResponsiveContainer, Legend } from "recharts";

// utils
import { extractInitials } from "../../atlas-utils";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchBrands } from "../../actions/actions";

// constants
import { PRESET_TYPES } from "../_commons/DateFilter";
import { healthMetricsdisplayNameMapping, timeSpentDisplayNameMapping } from "../../client-config";

const Overview = ({
	operationalEfficiencyState,
	operationalEfficiencyMetricsBizLevel,
	filterCount,
	filterActive,
	flipShowFilters,
	updateOperationsState,
	isMultibrandEnabled = false,
	brands,
	handleBrandChange
}) => {
	const { currentDateFilter, appliedDateFilter } = operationalEfficiencyState;
	let isCustomDateRange = false;
	let isComparing = false;
	const currentDates = appliedDateFilter.current.dateFilter.split(",");
	const compareDates = appliedDateFilter.compare.dateFilter.split(",");
	let currentStart = "";
	let currentEnd = "";
	let compareStart = "";
	let compareEnd = "";
	let presetDateFilterLabel = "";
	if (currentDates.length === 2) {
		isCustomDateRange = true;
		currentStart = moment(currentDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		currentEnd = moment(currentDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	} else {
		presetDateFilterLabel = PRESET_TYPES.find((pt) => pt.value === appliedDateFilter.current.dateFilter).label;
	}
	if (compareDates.length === 2) {
		isComparing = true;
		compareStart = moment(compareDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		compareEnd = moment(compareDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	}
	const { data, loading, error } = operationalEfficiencyMetricsBizLevel;
	const [metrics, setMetrics] = useState({});
	const [timeSpent, setTimeSpent] = useState({});
	useEffect(() => {
		if (!Array.isArray(data)) {
			setMetrics(
				Object.entries(healthMetricsdisplayNameMapping).map(([k, v]) => {
					return {
						name: k,
						displayName: v,
						displayValue: data["current"][k],
						previousValue: data["previous"] && data["previous"][k]
					};
				})
			);
			setTimeSpent(
				Object.entries(timeSpentDisplayNameMapping).map(([k, v]) => {
					return {
						name: k,
						displayName: v,
						displayValue: data["current"][k],
						previousValue: data["previous"] && data["previous"][k],
						labelValue:
							data["current"][k] > 1
								? Math.floor(data["current"][k]) + " mins"
								: Math.floor(data["current"][k] * 60) + " secs",
						previousLabelValue:
							data["previous"] &&
							(data["previous"][k] > 1
								? Math.floor(data["previous"][k]) + " mins"
								: Math.floor(data["previous"][k] * 60) + " secs")
					};
				})
			);
		}
	}, [data]);
	const trails = useTrail(metrics.length, {
		config: config.stiff,
		from: {
			opacity: 0
		},
		opacity: 1
	});

	const handleBrand = (brand) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: brand
		});
		handleBrandChange();
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	return (
		<div className="operational-efficiency-overview">
			<div className="credits-section-header transactions-list-header">
				<div className="header-text">
					<div className="title">Operations Health Index</div>
					<div className="subtitle">View order status update analysis</div>
				</div>
			</div>
			<div className="brands-date-filter-container">
				{isMultibrandEnabled && (
					<SelectFilterCustom
						options={brands.items}
						isLoading={brands.isLoading}
						field="brands"
						currValue={brands.selectedBrand}
						setFilter={(f, value) => handleBrand(value)}
						labelKey="name"
						valueKey="id"
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						isClearable={true}
						renderLabel={handleBrandsLabelOption}
						renderOptions={handleBrandsLabelOption}
						placeholder="Select brand"
						classes="brand-selection"
					/>
				)}
				<NewDateCompareFilter
					loading={loading}
					currentDateFilter={currentDateFilter}
					appliedDateFilter={appliedDateFilter}
					updateState={updateOperationsState}
					hidePresetTypes={["15 D"]}
					showDropdown
				/>
				<div className="filter-buttons">
					<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
						<div className="container" onClick={flipShowFilters}>
							<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
							<div className="filter-title">
								Filter
								{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="operational-efficiency-block-container">
				<div className="subtitle">
					<div className="highlight">Rate Index</div>
				</div>
				{!error && !loading ? (
					<React.Fragment>
						{trails.map(({ opacity }, i) => (
							<HealthBlockPure
								key={i}
								style={{
									opacity
								}}
								{...metrics[i]}
							/>
						))}
					</React.Fragment>
				) : loading ? (
					<HealthBlockPlaceholder />
				) : (
					<div className="no-items-placeholder error">
						An error occurred while fetching the data, Please reload.
					</div>
				)}
			</div>
			<div className="operational-efficiency-chart-container">
				<div className="subtitle">
					<div className="highlight">Time Index</div>
				</div>
				{timeSpent.length > 0 && !error && !loading ? (
					<div className="operational-efficiency-chart">
						<ResponsiveContainer width="95%" height={250}>
							<BarChart width={800} height={250} data={timeSpent} layout="vertical">
								<CartesianGrid horizontal={false} strokeDasharray="3 2" />
								<XAxis
									type="number"
									unit=" min"
									axisLine={false}
									tickLine={false}
									tick={{ stroke: "#7B8A9A", strokeWidth: 0.1, fontSize: 12 }}
								/>
								<YAxis
									type="category"
									strokeDasharray="3 2"
									tick={{ stroke: "#7B8A9A", strokeWidth: 0.1, fontSize: 12 }}
									dataKey="displayName"
									width={150}
								/>
								<defs>
									<linearGradient
										id="colorUv"
										x1="0"
										y1="100%"
										x2="100%"
										y2="0"
										spreadMethod="reflect"
									>
										<stop offset="25%" stopColor="#8884d8" stopOpacity={0.7} />
										<stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
									</linearGradient>
									<linearGradient
										id="colorPv"
										x1="0"
										y1="100%"
										x2="100%"
										y2="0"
										spreadMethod="reflect"
									>
										<stop offset="25%" stopColor="#d0dadd" stopOpacity={0.7} />
										<stop offset="95%" stopColor="#d0dadd" stopOpacity={1} />
									</linearGradient>
								</defs>
								<Bar
									name={
										isCustomDateRange ? currentStart + " to " + currentEnd : presetDateFilterLabel
									}
									dataKey="displayValue"
									fill="url(#colorUv)"
									maxBarSize={30}
									animationBegin={400}
									animationDuration={400}
									animationEasing="linear"
								>
									<LabelList dataKey="labelValue" content={renderCustomizedLabel} />
								</Bar>
								{isComparing && (
									<Bar
										name={compareStart + " to " + compareEnd}
										dataKey="previousValue"
										fill="url(#colorPv)"
										maxBarSize={30}
										animationBegin={400}
										animationDuration={400}
										animationEasing="linear"
									>
										<LabelList dataKey="previousLabelValue" content={renderCustomizedLabel} />
									</Bar>
								)}
								{isComparing && <Legend />}
							</BarChart>
						</ResponsiveContainer>
					</div>
				) : loading ? (
					<TimeSpentBlockPlaceHolder />
				) : (
					<div className="no-items-placeholder error">
						An error occurred while fetching the data, Please reload.
					</div>
				)}
			</div>
		</div>
	);
};
export default connect((store, props) => ({
	operationalEfficiencyState: store.operationalEfficiencyState,
	operationalEfficiencyMetricsBizLevel: store.operationalEfficiencyMetricsBizLevel,
	filterCount: props.filterCount,
	flipShowFilters: props.flipShowFilters,
	filterActive: props.showFilters,
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(Overview);

const HealthBlockPure = ({ displayName, displayValue, previousValue, style }) => (
	<animated.div style={style} className="operational-efficiency-block">
		<div className="display-name">{displayName}</div>
		<div className="value">{Math.floor(displayValue)}%</div>
		{previousValue && <div className="previous-value">vs &nbsp;{Math.floor(previousValue)}%</div>}
	</animated.div>
);

export const HealthBlockPlaceholder = () => {
	return (
		<div className="operational-efficiency-block-container">
			<div className="shimmer H(83px)" />
		</div>
	);
};

export const TimeSpentBlockPlaceHolder = () => {
	return (
		<div className="operational-efficiency-chart">
			<div className="shimmer H(83px)" />
		</div>
	);
};

const renderCustomizedLabel = (props) => {
	const { x, y, width, height, value } = props;
	return (
		<g>
			<text x={x + width + height / 20} y={y + height / 2} fill="#000" dominantBaseline="middle">
				<tspan fontSize={13}>{value.split(" ")[0]} </tspan>
				<tspan fontSize={9}>{value.split(" ")[1]}</tspan>
			</text>
		</g>
	);
};
const YAxisLabel = (props) => {
	const { x, y, width, height, value } = props;
	return (
		<g>
			<text x={x + width + height / 20} y={y + height / 2} fill="#000" dominantBaseline="middle">
				Hello World
			</text>
		</g>
	);
};
