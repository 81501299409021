import React, { useState } from "react";

// components
import { SearchFilter } from "../_commons/SearchFilter";
import CategoriesSection from "./CategoriesSection";
import { Loading } from "../_commons/Loading";
import ItemCreate from "../../containers/ItemCreate";
import ModifierGroupEntityAssociations from "../EntityAssociations/ModifierGroupEntityAssociations";

// constants
import { MODIFIER_GROUP_ENTITY_ASSOCIATION_TYPES } from "../../client-config";

const MenuConfiguration = ({
	isLoading = false,
	showHideCreateSection,
	showHideMenuReorderDrawer,
	showHideAddItemsDrawer,
	parentSections = [],
	selectedSection,
	currClickedSection,
	setCurrClickedSection,
	updatedSelectedSection,
	filteredParentSections,
	parentSectionSearchKey,
	handleParentSectionsSearch,
	handleItemDetailsClick,
	handleEntityDeletionForm,
	handleDetailsEditSection,
	selectedParentSectionData,
	isSectionsLoading,
	metaData = {},
	isMenuLocked,
	showHideCustomisableItemDrawer,
	showCopyMenuSection,
	temporaryPersistedData = {},
	setTemporaryPersistedData = () => {},
	handleItemAdditionToSelectedSection = () => {}
}) => {
	const [bulkItemModifierGroupMap, setBulkItemModifierGroupMap] = React.useState({});
	const [isModifierGroupEnitityAssociationDrawerOpen, setIsModifierGroupEnitityAssociationDrawerOpen] =
		React.useState(false);
	const [isItemCreateFormOpen, setIsItemCreateFormOpen] = useState(false);
	const handleClose = (refresh, success) => {
		setIsModifierGroupEnitityAssociationDrawerOpen(false);
		if (success) {
			setBulkItemModifierGroupMap({});
		}
	};

	const getNumberOfItemsInSearchedResults = () => {
		return parentSections.reduce((acc, curr) => {
			return acc + curr?.items?.length || 0;
		}, 0);
	};

	const getItemIdsToAssociate = () => {
		const itemIdsToAssociate = [];
		for (let categoryId in bulkItemModifierGroupMap) {
			if (bulkItemModifierGroupMap[categoryId]) {
				for (let itemId in bulkItemModifierGroupMap[categoryId]) {
					if (bulkItemModifierGroupMap[categoryId][itemId]) {
						itemIdsToAssociate.push(itemId);
					}
				}
			}
		}
		return itemIdsToAssociate;
	};
	const openItemCreationForm = (categoryId) => {
		setTemporaryPersistedData({
			addItemsTempData: {
				categoryId: categoryId,
				categoryDetail: parentSections.find((category) => category.id === categoryId)
			}
		});
		setIsItemCreateFormOpen(true);
	};
	const closeItemCreationForm = (refresh = false, createdItemId) => {
		if (refresh) {
			const itemIds =
				temporaryPersistedData?.addItemsTempData?.categoryDetail?.items.map((item) => item.id) || [];
			handleItemAdditionToSelectedSection([...itemIds, createdItemId?.toString()]);
		}
		setIsItemCreateFormOpen(false);
	};

	if (isLoading) {
		return (
			<div className="menu-configurations-container">
				<div className="shimmer-container">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}

	return (
		<div className="menu-configurations-container">
			<HeaderSection
				parentSectionSearchKey={parentSectionSearchKey}
				handleParentSectionsSearch={handleParentSectionsSearch}
				metaData={metaData}
				isSectionsLoading={isSectionsLoading}
				numberOfSearchResults={getNumberOfItemsInSearchedResults()}
				numberOfItemsSelected={getItemIdsToAssociate()?.length || 0}
				setIsModifierGroupEnitityAssociationDrawerOpen={setIsModifierGroupEnitityAssociationDrawerOpen}
			/>
			<CategoriesSection
				showHideCreateSection={showHideCreateSection}
				showHideMenuReorderDrawer={showHideMenuReorderDrawer}
				showHideAddItemsDrawer={showHideAddItemsDrawer}
				parentSections={parentSections}
				selectedSection={selectedSection}
				currClickedSection={currClickedSection}
				setCurrClickedSection={setCurrClickedSection}
				updatedSelectedSection={updatedSelectedSection}
				selectedSectionContent={selectedParentSectionData}
				filteredParentSections={filteredParentSections}
				handleItemDetailsClick={handleItemDetailsClick}
				handleEntityDeletionForm={handleEntityDeletionForm}
				handleDetailsEditSection={handleDetailsEditSection}
				parentSectionSearchKey={parentSectionSearchKey}
				isSectionsLoading={isSectionsLoading}
				isMenuLocked={isMenuLocked}
				showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
				bulkItemModifierGroupMap={bulkItemModifierGroupMap}
				setBulkItemModifierGroupMap={setBulkItemModifierGroupMap}
				numberOfItemsSelected={getItemIdsToAssociate()?.length || 0}
				showCopyMenuSection={showCopyMenuSection}
				openItemCreationForm={openItemCreationForm}
			/>
			<ModifierGroupEntityAssociations
				isOpen={isModifierGroupEnitityAssociationDrawerOpen}
				close={handleClose}
				entityId={-1} // to get all modifier groups without any pre-associations
				entityType={MODIFIER_GROUP_ENTITY_ASSOCIATION_TYPES[0]}
				isFromMenuSection={true}
				enabledModifierGroups={{}}
				entitesToBulkAssociate={getItemIdsToAssociate()}
				isBulkAssociation={true}
			/>
			{isItemCreateFormOpen && (
				<ItemCreate
					isNested
					isViewedFromMenu
					isOpen={isItemCreateFormOpen}
					hasAccess
					close={closeItemCreationForm}
					temporaryPersistedData={temporaryPersistedData}
				/>
			)}
		</div>
	);
};
export default MenuConfiguration;

const HeaderSection = ({
	parentSectionSearchKey,
	handleParentSectionsSearch,
	metaData,
	isSectionsLoading,
	numberOfSearchResults,
	numberOfItemsSelected,
	setIsModifierGroupEnitityAssociationDrawerOpen
}) => (
	<div className={`menu-config-header ${isSectionsLoading ? "disabled" : ""}`}>
		<div className="menu-config-header-top">
			<div className="category-items-count">
				{isSectionsLoading ? (
					<Loading />
				) : parentSectionSearchKey ? (
					`${numberOfSearchResults} search results`
				) : (
					<React.Fragment>
						{isSectionsLoading ? <Loading /> : metaData?.categoryCount} sections,{" "}
						{isSectionsLoading ? <Loading /> : metaData?.itemCount} items
					</React.Fragment>
				)}
			</div>
			<SearchFilter
				placeholder="Search"
				setFilter={handleParentSectionsSearch}
				filterOption={{ field: "itemName" }}
				value={parentSectionSearchKey}
			/>
		</div>

		{numberOfItemsSelected !== 0 ? (
			<div className="menu-config-header-bottom">
				<div className="selected-items-count">{numberOfItemsSelected} item(s) selected</div>
				<span
					className="hyperlink"
					onClick={() => {
						setIsModifierGroupEnitityAssociationDrawerOpen(true);
					}}
				>
					+ Associate Modifier Groups
				</span>
			</div>
		) : null}
	</div>
);
