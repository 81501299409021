import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../../actions/_types";

// store
import { store } from "../../store/configureStore";

// utils
import { trackEvent } from "../../atlas-utils";

// components
import ParentCategoriesList from "./ParentCategoriesList";
import { PreviewSections } from "./CategoriesSection";
import { LocationPlatformSelector } from "./LocationPlatformSelector";

// constants
import { TRACKING_EVENT_NAMES } from "../../client-config";

const VersionPreview = ({
	showHideCustomisableItemDrawer,
	handleVersionPreviewLoad,
	handleMenuPreviewLocationSelection,
	handleMenuPreviewPlatformSelection,
	menuPreviewPlatformsLocationsList,
	selectedPublishedVersionDetails,
	menuPreviewStates,
	setCurrClickedSection,
	currClickedSection
}) => {
	const { menuPreviewData, isLoading: isMenuVersionPreviewLoading, selectedParentSection } = menuPreviewStates;

	let subCategories = {};
	(menuPreviewData?.objects ?? []).forEach((parentCategory) => {
		parentCategory.subCategories.forEach((subCategory) => {
			subCategories[subCategory?.id] = true;
		});
	});

	const restructuredParentSections = (menuPreviewData?.objects ?? []).filter(
		(section) => !subCategories[section?.id]
	);

	const selectedParentSectionData = menuPreviewData?.objects?.find(
		(section) => section?.id === selectedParentSection
	);
	const updatedSelectedSection = (id) => {
		store.dispatch({
			type: ActionTypes.UPDATE_MENU_PREVIEW_SELECTED_SECTION,
			payload: id
		});
	};

	useEffect(() => {
		handleVersionPreviewLoad && handleVersionPreviewLoad();
		trackEvent(TRACKING_EVENT_NAMES.MENU_PREVIEW, {
			platform: menuPreviewPlatformsLocationsList?.selectedPlatform?.platformName?.toLowerCase(),
			type: "old_version"
		});
	}, []);

	if (isMenuVersionPreviewLoading) {
		return (
			<div className="menu-configurations-container">
				<div className="shimmer-container">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}
	return (
		<div className="version-preview-container">
			<LocationPlatformSelector
				selectedLocation={menuPreviewPlatformsLocationsList?.selectedLocation}
				selectedPlatform={menuPreviewPlatformsLocationsList?.selectedPlatform}
				locationsData={{
					...menuPreviewPlatformsLocationsList?.locationsData,
					objects: menuPreviewPlatformsLocationsList?.locationsData?.objects?.filter((loc) =>
						selectedPublishedVersionDetails?.associatedLocations?.includes(loc?.name?.toLowerCase())
					)
				}}
				platformsData={{
					...menuPreviewPlatformsLocationsList?.platformsData,
					objects: menuPreviewPlatformsLocationsList?.platformsData?.objects?.filter((plf) =>
						selectedPublishedVersionDetails?.associatedPlatforms?.includes(plf?.platformName?.toLowerCase())
					)
				}}
				handleMenuPreviewLocationSelection={handleMenuPreviewLocationSelection}
				handleMenuPreviewPlatformSelection={handleMenuPreviewPlatformSelection}
				isLoading={isMenuVersionPreviewLoading}
			/>
			<div className={`preview-cart-container`}>
				<ParentCategoriesList
					isOnPreviewMode
					parentSections={restructuredParentSections}
					selectedSection={selectedParentSection}
					updatedSelectedSection={updatedSelectedSection}
					setCurrClickedSection={setCurrClickedSection}
					isMenuLocked
				/>
				<PreviewSections
					showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
					selectedParentSectionData={selectedParentSectionData}
					parentSections={menuPreviewData?.objects}
					filteredParentSections={restructuredParentSections}
					updatedSelectedSection={updatedSelectedSection}
					currClickedSection={currClickedSection}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	menuPreviewStates: store.menuPreviewStates,
	menuPreviewPlatformsLocationsList: store.menuPreviewPlatformsLocationsList
});
export default connect(mapStateToProps)(VersionPreview);
