import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";

// third party
import { connect } from "react-redux";

// actions
import { fetchTimingGroupsDebounced } from "../../actions/actions";

const Settings = ({ biz, timingGroups, data = {}, configValues = {}, handleForm, handleConfigValues, validations }) => {
	return (
		<div className="settings">
			<div className="form-row row-half">
				<SelectFilter
					title="Ordering timeslots"
					options={timingGroups.items}
					isLoading={timingGroups.isLoading}
					field="orderingSlots"
					currValue={data.orderingSlots}
					setFilter={handleForm}
					labelKey="title"
					valueKey="id"
					isAsync={true}
					showCustomTooltip
					tooltipInfo="Choose the ordering time slots for your business to be shown on website and app. This value will apply to all locations if no specific location-level slots are configured"
					handleSearch={fetchTimingGroupsDebounced}
				/>
				{/* <Switch
					title="Enable pre-ordering"
					checked={data.isItemPreOrderEnabled}
					clickHandler={() => handleForm('isItemPreOrderEnabled', !data.isItemPreOrderEnabled)}
					validationMessage={validations.isItemPreOrderEnabled || ''}
				/> */}
				<Switch
					title="Is order pickup enabled"
					tooltipInfo="Enable customers to place pick up orders on the website and app"
					showCustomTooltip
					checked={data.isOrderPickupEnabled}
					clickHandler={() => handleForm("isOrderPickupEnabled", !data.isOrderPickupEnabled)}
					validationMessage={validations.isOrderPickupEnabled || ""}
				/>
			</div>
			<div className="form-row row-half">
				{/* <Switch
					title="Integrated with PoS"
					checked={data.isInventoryIntegrationEnabled}
					clickHandler={() => handleForm('isInventoryIntegrationEnabled', !data.isInventoryIntegrationEnabled)}
					validationMessage={validations.isInventoryIntegrationEnabled || ''}
				/> */}
				<InputWithLabel
					value={data.bizMinOrderTotal}
					type="number"
					showCustomTooltip
					tooltipInfo="Set a minimum order value for customers to proceed with checkout on website and apps. This value will apply to all locations where no location-level value is set"
					onChange={(e) => handleForm("bizMinOrderTotal", e.target.value ? Number(e.target.value) : null)}
					requiredLabel={true}
					validationMessage={validations.bizMinOrderTotal || ""}
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={biz.currencySymbol}
				>
					Min. order value
				</InputWithLabel>
				<InputWithLabel
					value={data.orderDeliveryRadius}
					type="number"
					tooltipInfo="Set the maximum delivery distance. This value will apply to all locations if no specific location-level value is configured"
					showCustomTooltip
					onChange={(e) => handleForm("orderDeliveryRadius", e.target.value ? Number(e.target.value) : null)}
					requiredLabel={true}
					validationMessage={validations.orderDeliveryRadius || ""}
				>
					Order delivery radius (in meters)
				</InputWithLabel>
				<InputWithLabel
					value={data.maxOrderPendingTime}
					type="number"
					tooltipInfo="Define the maximum duration for order processing for all locations"
					showCustomTooltip
					onChange={(e) =>
						handleForm("maxOrderPendingTime", e.target.value ? parseInt(e.target.value) : null)
					}
					requiredLabel={true}
					validationMessage={validations.maxOrderPendingTime || ""}
				>
					Maximum pending time (in minutes)
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.minimumOrderDeliveryTime}
					type="number"
					onChange={(e) =>
						handleForm("minimumOrderDeliveryTime", e.target.value ? parseInt(e.target.value) : null)
					}
					requiredLabel={true}
					tooltipInfo="Set the minimum time required to deliver an order. This value will apply to all locations if no specific location-level value is configured"
					showCustomTooltip
					validationMessage={validations.minimumOrderDeliveryTime || ""}
				>
					Minimum delivery time (in minutes)
				</InputWithLabel>
				{/* <Switch
					title="Rely on point of delivery address"
					checked={data.orderingUsePointOfDelivery}
					clickHandler={() => handleForm('orderingUsePointOfDelivery', !data.orderingUsePointOfDelivery)}
					validationMessage={validations.orderingUsePointOfDelivery || ''}
				/> */}
				<InputWithLabel
					value={data.minimumOrderPickupTime}
					type="number"
					tooltipInfo="Set the minimum time required to prepare an order for pickup. This value will apply to all locations if no specific location-level value is configured"
					showCustomTooltip
					onChange={(e) =>
						handleForm("minimumOrderPickupTime", e.target.value ? parseInt(e.target.value) : null)
					}
					requiredLabel={true}
					validationMessage={validations.minimumOrderPickupTime || ""}
				>
					Min. time to prepare pickup order (in minutes)
				</InputWithLabel>
				<InputWithLabel
					value={configValues.business_info_config_cashback_credit_limit}
					type="number"
					tooltipInfo="Set the maximum cashback amount that can be credited to a customer's account. This value applies to website and app, for all locations"
					showCustomTooltip
					onChange={(e) =>
						handleConfigValues(
							"business_info_config_cashback_credit_limit",
							e.target.value ? Number(e.target.value) : ""
						)
					}
				>
					Maximum cashback credit
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				{/* <Switch
					title="Rely on point of delivery landmarks"
					checked={!data.orderingUsePointOfDelivery}
					clickHandler={() => handleForm('orderingUsePointOfDelivery', !data.orderingUsePointOfDelivery)}
					validationMessage={validations.orderingUsePointOfDelivery || ''}
				/> */}

				<InputWithLabel
					value={configValues.business_info_config_cod_max_order_value}
					type="number"
					tooltipInfo="Set the maximum order value allowed with Cash On Delivery as a payment mode. This value is applicable for website and apps across all locations"
					showCustomTooltip
					onChange={(e) =>
						handleConfigValues(
							"business_info_config_cod_max_order_value",
							e.target.value ? Number(e.target.value) : ""
						)
					}
				>
					Maximum order value for COD
				</InputWithLabel>
				<InputWithLabel
					value={configValues.business_info_config_campaign_email}
					onChange={(e) => handleConfigValues("business_info_config_campaign_email", e.target.value)}
				>
					Verified email ID to be used to send campaign emails
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				{/* <Switch
					title="Enable delivery charge for polygons"
					checked={configValues.business_info_config_enable_polygon_based_delivery_charge || false}
					clickHandler={() => handleConfigValues('business_info_config_enable_polygon_based_delivery_charge', configValues.business_info_config_enable_polygon_based_delivery_charge ? false : true)}
				/> */}
				{/* <Switch
					title="Enable category location association"
					checked={data.enableCategoryLocationAssociation}
					clickHandler={() => handleForm('enableCategoryLocationAssociation', !data.enableCategoryLocationAssociation)}
					validationMessage={validations.enableCategoryLocationAssociation || ''}
				/> */}
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(Settings);
