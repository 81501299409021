import React, { useState } from "react";

// component
import { Modal } from "../_commons/Modal";
import { Loading } from "../_commons/Loading";
import { CheckBox } from "../_commons/CheckBox";

// actions
import { fetchParentSectionsList } from "../../actions/menus";

// graphql
import { UPDATE_MENU } from "../../graphql/menus";

// services
import NotificationServices from "../../services/NotificationService";

// client
import { clientMenu } from "../../client-menu";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

const EntityDeleteConfirmationModal = ({
	entityType,
	isOpen,
	close = () => {},
	loading = true,
	menuDetailsData,
	menuId,
	id,
	deletionInfo = null
}) => {
	const [isChecked, setIsChecked] = useState(false);
	const [respState, setRespState] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const RENDER_ENTITY_WARNING_MESSAGE = {
		item: () => {
			return "Are you sure you want to remove this item from the section ?";
		},
		subSection: () => {
			return "Are you sure you want to remove this sub section ?";
		},
		section: () => {
			return "Are you sure you want to remove this section ?";
		}
	};

	const isItemInMultipleCategories = (itemId) => {
		const categories = menuDetailsData?.categories;
		let count = 0;
		categories.forEach((category) => {
			if (category?.items.includes(itemId)) {
				count++;
			}
		});
		return count > 1;
	};

	const removeCategoryItems = (menuUpdationVariables, categoryId) => {
		let updatedMenuUpdationVariables = { ...menuUpdationVariables };
		const itemIdsMapping = {};

		/**
		 * this removes the items from the category
		 * and creates map of items that are deleted
		 */
		updatedMenuUpdationVariables.menuData.categories = updatedMenuUpdationVariables.menuData.categories.map(
			(category) => {
				if (category?.id === categoryId) {
					category.items.forEach((itemId) => {
						itemIdsMapping[itemId] = 0;
					});

					return {
						...category,
						items: []
					};
				}

				return category;
			}
		);

		/**
		 * checking if the item exists in multiple categories
		 */
		updatedMenuUpdationVariables.menuData.categories.forEach((category) => {
			if (category?.id !== categoryId) {
				category.items.forEach((itemId) => {
					// if item exists in multiple categories
					if (itemIdsMapping[itemId] >= 0) {
						itemIdsMapping[itemId]++;
					}
				});
			}
		});

		updatedMenuUpdationVariables.menuData.items = updatedMenuUpdationVariables.menuData.items.filter(
			// remove items that are present only once in category
			(item) => itemIdsMapping[item?.id] !== 0
		);

		return updatedMenuUpdationVariables;
	};

	const handleDeleteEntity = async () => {
		setIsLoading(true);
		try {
			let menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: ""
						}
					})),
					optionGroups: menuDetailsData.optionGroups,
					items: menuDetailsData.items.map((item) => ({
						...item,
						overrides: { ...item.overrides, image: "" }
					})),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: ""
						}
					}))
				}
			};

			if (entityType === "item") {
				// check if item exists in multiple categories
				if (!isItemInMultipleCategories(id)) {
					menuUpdationVariables.menuData.items = [
						...menuUpdationVariables.menuData.items.filter((item) => item?.id !== id)
					];
				}
				const categoryId = deletionInfo?.subCategoryId || deletionInfo?.categoryId;

				menuUpdationVariables.menuData.categories = [
					...menuUpdationVariables.menuData.categories.map((category) => {
						const isCurrentCategory = category.id === categoryId;
						const updatedItems = isCurrentCategory
							? category.items.filter((itemId) => itemId !== id)
							: category.items;

						return {
							...category,
							items: updatedItems
						};
					})
				];
			}

			if (entityType === "section") {
				const targettedSectionInfo = menuUpdationVariables.menuData.categories.find(
					(category) => category?.id === id
				);
				if (!!targettedSectionInfo?.items.length) {
					menuUpdationVariables = removeCategoryItems(menuUpdationVariables, targettedSectionInfo?.id);
					menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.filter(
						(category) => category?.id !== id
					);

					// checking if the chosen section was sub section or not and removing the entry from its parent if found to be a subcategory
					menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.map(
						(category) => ({
							...category,
							subCategories: [...category.subCategories.filter((subCat) => subCat !== id)]
						})
					);
				}

				if (!!targettedSectionInfo?.subCategories.length) {
					const removableSections = {};
					targettedSectionInfo.subCategories.forEach((subCategory) => {
						removableSections[subCategory] = true;
						menuUpdationVariables = removeCategoryItems(menuUpdationVariables, subCategory);
					});

					menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.filter(
						(category) => category?.id !== id
					);
					menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.filter(
						(category) => !removableSections[category?.id]
					);
				}

				if (targettedSectionInfo?.subCategories.length === 0 && targettedSectionInfo?.items.length === 0) {
					menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.filter(
						(category) => category?.id !== id
					);

					// checking if the chosen section was sub section or not and removing the entry from its parent if found to be a subcategory
					menuUpdationVariables.menuData.categories = menuUpdationVariables.menuData.categories.map(
						(category) => ({
							...category,
							subCategories: [...category.subCategories.filter((subCat) => subCat !== id)]
						})
					);
				}
			}

			const entityDeleteResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (entityDeleteResp?.data?.updateMenuV2) {
				setRespState("success");
				NotificationServices.pushNotification({
					message: "Successfully removed!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: entityDeleteResp?.data?.updateMenuV2
				});
				fetchParentSectionsList(menuId);
			} else {
				setRespState("failure");
				NotificationServices.pushNotification({
					message: "Failed to remove entity",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			setRespState("failure");
			NotificationServices.pushNotification({
				message: "Failed to remove entity",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
		}

		setIsLoading(false);
	};

	const handleClose = () => {
		if (isLoading) {
			return;
		}
		setRespState("");
		setIsChecked(false);
		close();
	};

	return (
		<Modal
			isOpen={isOpen}
			classes="menu-entity-deletion--modal"
			close={handleClose}
			showDeleteAction={respState === "" || respState === "failure"}
			disabled={!isChecked}
			deleteAction={handleDeleteEntity}
			deleteTitle="Remove"
			showCancelAction
			cancelTitle="Close"
			title={`Remove ${entityType}`}
		>
			{isLoading ? (
				<div className="entity-details">
					<Loading />
				</div>
			) : respState !== "" ? (
				<div className="entity-details">
					<div className={`end-result ${respState}`}>
						{respState === "success" ? `Successfully removed` : `Failed to remove`}
					</div>
				</div>
			) : (
				<div className="entity-details">
					<div className="warning">{RENDER_ENTITY_WARNING_MESSAGE[entityType ?? "item"]()}</div>
					<div className="confirm-process">
						<CheckBox
							checked={isChecked}
							clickHandler={() => {
								setIsChecked((current) => !current);
							}}
						/>
						Proceed with removing {entityType ?? "item"}
					</div>
				</div>
			)}
		</Modal>
	);
};

export default EntityDeleteConfirmationModal;
