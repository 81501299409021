import React from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";

// assets
import CreateIcon from "../_commons/CreateIcon";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Tags</div>
				<div className="subtitle">Manage tags and their tag groups</div>
			</div>
			{!props.hideHeaderSectionRight && (
				<div className="header-action-button">
					<div className="filter-buttons">
						{props.isCatalogueManagement && (
							<div className="action-buttons-container">
								<Link to="/tags/tag/new">
									<Button>
										<CreateIcon />
										<span>Add Tag</span>
									</Button>
								</Link>
							</div>
						)}
						{props.isCatalogueManagement && (
							<div className="action-buttons-container">
								<Link to="/tags/group/new">
									<Button>
										<CreateIcon />
										<span>Add Tag Group</span>
									</Button>
								</Link>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
