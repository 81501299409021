import React, { Component } from "react";

// components
import DateFilter from "./DateFilter";
import CheckboxGroupFilter from "./CheckboxGroupFilter";
import NumberRangeFilter from "./NumberRangeFilter";
import { SIGNUP_CHANNELS } from "../../client-config";

const GENDERS = [
	{ label: "Male", value: "male" },
	{ label: "Female", value: "female" }
];

export default class CampaignUser extends Component {
	render() {
		const { filter } = this.props;
		const user = filter.user || {};

		return (
			<div className="filter-section-container">
				<CheckboxGroupFilter
					title="Signup Channel"
					root="user"
					options={SIGNUP_CHANNELS}
					field="signup_channel"
					value={user.signup_channel}
					setFilter={this.props.setFilter}
				/>
				<DateFilter
					title="Signup Date"
					root="user"
					field="signup_date"
					value={user.signup_date}
					setFilter={this.props.setFilter}
				/>
				<CheckboxGroupFilter
					title="Gender"
					root="user"
					options={GENDERS}
					field="gender"
					value={user.gender}
					setFilter={this.props.setFilter}
				/>
				<DateFilter
					title="Birthday"
					root="user"
					field="birthday"
					value={user.birthday}
					setFilter={this.props.setFilter}
					ignoreYear={true}
				/>
				<DateFilter
					title="Anniversary"
					root="user"
					field="anniversary"
					value={user.anniversary}
					setFilter={this.props.setFilter}
					ignoreYear={true}
				/>
				<NumberRangeFilter
					title="Wallet balance"
					root="user"
					field="wallet_balance"
					value={user.wallet_balance}
					setFilter={this.props.setFilter}
				/>
				<NumberRangeFilter
					title="Loyalty points"
					root="user"
					field="loyalty_points"
					value={user.loyalty_points}
					setFilter={this.props.setFilter}
				/>
			</div>
		);
	}
}
