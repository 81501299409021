import React, { useState, useCallback } from "react";

// third party
import Select, { components, Creatable, AsyncCreatable } from "react-select-animated-v2";
import { makeAnimated } from "react-select-animated-v2";

export const SelectFilter = ({
	title,
	options,
	field,
	description,
	currValue,
	customDropdownLabel,
	customMultiValueLabel,
	isLoading = false,
	labelKey = "label",
	valueKey = "value",
	setFilter,
	requiredLabel = false,
	validationMessage,
	positiveValidationMessage = false,
	classes = "",
	containerClasses = "",
	filterClass = false,
	ellipsized = false,
	archived = false,
	isNull = false,
	isSearchable = true,
	isClearable = true,
	placeholder = "Type to search",
	noOptionsMessage = "No results found",
	multi = false,
	creatable = false,
	asyncCreatable = false,
	openMenuOnClick = true,
	loadOptions,
	scrollDown,
	handleSearch,
	isAsync = false,
	order = false,
	renderCustomOption,
	renderCustomMenuListTop,
	renderCustomMenuListBottom,
	showCustomTooltip = false,
	tooltipInfo = "",
	tooltipPosition = "up-left",
	highlight = false,
	readOnly = false,
	onMenuOpen,
	onMenuClose
}) => {
	const [focused, setFocus] = useState(false);

	const handleSelect = useCallback(
		(opt) => {
			setFilter(field, opt);
		},
		[field, setFilter]
	);

	if (focused && typeof scrollDown === "function") {
		scrollDown();
	}

	const handleSearchInput = useCallback(
		(inputValue) => {
			if (isAsync) {
				handleSearch(inputValue);
			}
		},
		[isAsync, handleSearch]
	);

	const CustomOptionContainer = ({ children, ...props }) => {
		return (
			<components.Option {...props}>
				{renderCustomOption ? renderCustomOption(children, { ...props }) : children}
			</components.Option>
		);
	};

	const CustomValueContainer = ({ children, ...props }) => {
		return <components.SingleValue {...props}>{customDropdownLabel || children}</components.SingleValue>;
	};

	const CustomMultiValueContainer = ({ children, ...props }) => {
		return (
			<components.MultiValueLabel {...props}>
				{customMultiValueLabel(children, props) || children}
			</components.MultiValueLabel>
		);
	};

	const CustomMenuListContainer = ({ children, ...props }) => {
		return (
			<components.MenuList {...props}>
				{renderCustomMenuListTop || null}
				{children}
				{renderCustomMenuListBottom || null}
			</components.MenuList>
		);
	};

	return (
		<div
			order={`${order}`}
			className={
				`multi-select-filter-container ${containerClasses}` +
				(focused || currValue ? " active" : "") +
				(highlight ? " highlight" : "") +
				(filterClass ? " filter-section-item" : "") +
				(multi && ellipsized ? " ellipsized" : "") +
				(archived ? " archived" : "") +
				(isNull ? " null" : "")
			}
		>
			{title && (
				<div className={"meta-info"}>
					<span className={requiredLabel ? " required-sign" : ""}>{title}</span>
					{showCustomTooltip && tooltipInfo && (
						<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
							<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
						</span>
					)}
				</div>
			)}
			{description && <div className="select-description">{description}</div>}
			{!creatable && !asyncCreatable ? (
				<Select
					value={currValue}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					openMenuOnClick={openMenuOnClick}
					onMenuOpen={onMenuOpen}
					onMenuClose={onMenuClose}
					isSearchable={isSearchable}
					className={"at--dropdown " + classes}
					classNamePrefix="react-select"
					isClearable={isClearable}
					options={options}
					onChange={handleSelect}
					onInputChange={handleSearchInput}
					isLoading={isLoading}
					getOptionLabel={(option) => option[labelKey]}
					getOptionValue={(option) => option[valueKey]}
					labelKey={labelKey}
					valueKey={valueKey}
					isDisabled={readOnly}
					placeholder={placeholder}
					components={
						multi && !customMultiValueLabel
							? makeAnimated()
							: customDropdownLabel
							? { SingleValue: CustomValueContainer }
							: customMultiValueLabel
							? { MultiValueLabel: CustomMultiValueContainer }
							: renderCustomOption
							? { Option: CustomOptionContainer }
							: renderCustomMenuListTop || renderCustomMenuListBottom
							? { MenuList: CustomMenuListContainer }
							: {}
					}
					isMulti={multi}
					closeMenuOnSelect={!multi}
					noOptionsMessage={() => (isLoading ? "Loading..." : noOptionsMessage)}
				/>
			) : asyncCreatable ? (
				<AsyncCreatable
					value={currValue}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					openMenuOnClick={openMenuOnClick}
					className={"at--dropdown " + classes}
					classNamePrefix="react-select"
					formatCreateLabel={(val) => `Create option "${val}"`}
					isClearable={isClearable}
					loadOptions={loadOptions}
					cacheOptions
					defaultOptions
					onChange={handleSelect}
					labelKey={labelKey}
					valueKey={valueKey}
					getOptionLabel={(option) => (option["__isNew__"] ? option.label : option[labelKey])}
					getOptionValue={(option) => (option["__isNew__"] ? option.value : option[valueKey])}
					createOptionPosition="first"
					placeholder={placeholder}
					components={multi ? makeAnimated() : {}}
					isMulti={multi}
					isDisabled={readOnly}
					closeMenuOnSelect={!multi}
					noOptionsMessage={() => (isLoading ? "Loading..." : noOptionsMessage)}
				/>
			) : (
				<Creatable
					value={currValue}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					openMenuOnClick={openMenuOnClick}
					isSearchable={isSearchable}
					className={"at--dropdown " + classes}
					classNamePrefix="react-select"
					isClearable={isClearable}
					options={options}
					onChange={handleSelect}
					isLoading={isLoading}
					labelKey={labelKey}
					valueKey={valueKey}
					getOptionLabel={(option) => option[labelKey]}
					getOptionValue={(option) => option[valueKey]}
					isDisabled={readOnly}
					placeholder={placeholder}
					components={multi ? makeAnimated() : {}}
					isMulti={multi}
					closeMenuOnSelect={!multi}
					noOptionsMessage={() => (isLoading ? "Loading..." : noOptionsMessage)}
				/>
			)}
			{validationMessage && (
				<div className={`validation-message${positiveValidationMessage ? " validation-message-positive" : ""}`}>
					{validationMessage}
				</div>
			)}
		</div>
	);
};
