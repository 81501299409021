// action types
import { ActionTypes } from "../actions/_types";

export const invoiceListViewState = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_INVOICES_LIST_VIEW_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_INVOICES_LIST_VIEW_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_INVOICES_LIST_VIEW_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const invoiceDetailViewState = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_INVOICE_DETAIL_VIEW_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_INVOICE_DETAIL_VIEW_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_INVOICE_DETAIL_VIEW_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const outstandingInvoicesState = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_OUTSTANDING_INVOICES_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_OUTSTANDING_INVOICES_SUCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_OUTSTANDING_INVOICES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
