import React, { useEffect, useRef, useState } from "react";

// third party
import { useDrag, useDrop } from "react-dnd";
import { MENU_REORDER_TYPES } from "../../client-config";

const DragDropWrapper = ({
	id,
	children,
	entityFields = {},
	handleOnDragInfo = () => {},
	handleDrop = () => {},
	inlineStyles = {},
	isOverStyles = {},
	entityDependencyContent,
	isDragOnly,
	isCentralNodeVisible = false,
	centralNodeId,
	setCentralNodeId = () => {},
	onDragClasses = ""
}) => {
	const dragRef = useRef();

	const [{ isOver, handlerId }, drop] = useDrop(
		() => ({
			accept: MENU_REORDER_TYPES.SECTIONSLIST,
			drop: (item) => {
				handleDrop(item, entityFields, !!centralNodeId);
				setCentralNodeId(undefined);
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				handlerId: monitor.getHandlerId()
			}),
			hover(item, monitor) {
				// Preventing for the dragged element
				if (item.id === id) {
					return;
				}
				if (!dragRef.current) {
					return;
				}

				if (!isCentralNodeVisible) {
					return;
				}
				// // Determine rectangle on screen
				const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
				// // Get vertical middle
				const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
				// // Determine mouse position
				const clientOffset = monitor.getClientOffset();
				// // Get pixels to the top
				const hoverClientY = clientOffset.y - hoverBoundingRect.top;
				if (hoverClientY > hoverMiddleY && clientOffset.y < hoverBoundingRect.bottom) {
					setCentralNodeId(id);
				} else if (clientOffset.y > hoverBoundingRect.bottom) {
					setCentralNodeId(undefined);
				} else if (hoverClientY < hoverMiddleY) {
					setCentralNodeId(undefined);
				}
			}
		}),
		[entityDependencyContent]
	);

	const [{ isDragging, draggingItem }, drag] = useDrag(
		() => ({
			type: MENU_REORDER_TYPES.SECTIONSLIST,
			item: { ...entityFields },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
				draggingItem: monitor.getItem()
			})
		}),
		[entityDependencyContent]
	);

	useEffect(() => {
		handleOnDragInfo(isDragging, draggingItem);
	}, [isDragging, draggingItem]);

	drag(drop(dragRef));

	const combinedInlineStyles = {
		...inlineStyles,
		cursor: isDragging ? "grabbing" : "grab",
		opacity: isDragging ? 0.5 : 1,
		...(isOver ? isOverStyles : {})
	};

	return (
		<div className="drag-drop-wrapper">
			<div
				className={`drag-drop-child ${isDragging ? onDragClasses : ""}`}
				ref={isDragOnly ? drag : dragRef}
				data-handler-id={handlerId}
				id={id}
				style={{ ...combinedInlineStyles }}
			>
				{children}
			</div>
			{isCentralNodeVisible && isOver && <div className="drag-drop-central-node"></div>}
		</div>
	);
};
export default DragDropWrapper;
