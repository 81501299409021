// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_MODIFIER_GROUPS_LIST,
	GET_MODIFIER_GROUP,
	EDIT_MODIFIER_GROUP,
	GET_MENU_SERVICE_MODIFIER_GROUP_DETAILS
} from "../graphql/modifierGroups";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";
import { clientMenu } from "../client-menu";

export const fetchModifierGroupsList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().modifierGroupsListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().modifierGroupsList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MODIFIER_GROUPS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_enabled") {
					// this value is being reversed because the behaviour of
					// is_enabled filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(!appliedFilters[f])
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_enabled",
				value: "true"
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_MODIFIER_GROUPS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_MODIFIER_GROUPS_LIST_SUCCESS,
			payload: { ...resp.data.modifierGroups }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MODIFIER_GROUPS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchModifierGroupDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_MODIFIER_GROUP_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_MODIFIER_GROUP,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_MODIFIER_GROUP_DETAIL_SUCCESS,
			payload: resp.data.modifierGroup
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_MODIFIER_GROUP_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Modifier Group details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchMenuServiceModifierGroupDetail = async (dispatch, menuId, optionGroupId) => {
	dispatch({
		type: ActionTypes.GET_MODIFIER_GROUP_DETAIL_REQUEST
	});
	try {
		const variables = {
			menuId,
			optionGroupId
		};
		const resp = await clientMenu.query({
			query: GET_MENU_SERVICE_MODIFIER_GROUP_DETAILS,
			variables,
			fetchPolicy: "no-cache"
		});

		if (resp.data.optionGroup) {
			dispatch({
				type: ActionTypes.GET_MODIFIER_GROUP_DETAIL_SUCCESS,
				payload: resp.data.optionGroup
			});
		} else {
			dispatch({
				type: ActionTypes.GET_MODIFIER_GROUP_DETAIL_FAILURE,
				error: {
					message: "There was an error while fetching Modifier Group details"
				}
			});
		}
	} catch (e) {
		console.log(e);
		dispatch({
			type: ActionTypes.GET_MODIFIER_GROUP_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Modifier Group details"
			}
		});
	}
};

export const editModifierGroup = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_MODIFIER_GROUP_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_MODIFIER_GROUP,
			variables: data
		});
		if (resp.data.saveModifierGroup.status.success) {
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_GROUP_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Modifier Group saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_MODIFIER_GROUPS_LIST,
				payload: resp.data.saveModifierGroup.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_GROUP_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveModifierGroup.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_MODIFIER_GROUP_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Modifier Group details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreModifierGroup = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_MODIFIER_GROUP,
			variables
		});
		if (resp.data.saveModifierGroup.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveModifierGroup.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
