import React from "react";

const SpecificItemIssueLocationCell = ({ value }) => {
	const rating = value.rating?.toFixed(2).replace(/(\.0+|(\.[1-9]*[1-9])0+)$/, "$2");

	return (
		<div className="specificissue-location-cell">
			<span className="specificissue-location-text">{value.location_name}</span>
			<div className="specificissue-rating-display">
				<span className="specificissue-ratings-text">{rating}</span>
				<img src="/assets/periscope/star-rating.svg" className="star" />
			</div>
		</div>
	);
};

export default SpecificItemIssueLocationCell;
