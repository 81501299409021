import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import ErrorPage from "./ErrorPage";
import BulkCategories from "../components/BulkOperations/BulkCategories";
import BulkLocations from "../components/BulkOperations/BulkLocations";
import BulkItems from "../components/BulkOperations/BulkItems";
import BulkModifierGroups from "../components/BulkOperations/BulkModifierGroups";
import BulkModifiers from "../components/BulkOperations/BulkModifiers";
import BulkCustomers from "../components/BulkOperations/BulkCustomers";

// constants
import { LOCATION_CREATION_DISABLED_COUNTRIES } from "../components/LocationsList/Header";

const BulkOperationsContainer = ({ bizCountry, isInternalUser = false }) => {
	return (
		<div className="bulk-operations-container">
			<Route exact path="/bulk" component={ErrorPage} />
			<Route exact path="/bulk/categories" component={BulkCategories} />
			<Route
				exact
				path="/bulk/locations"
				render={() =>
					isInternalUser || !LOCATION_CREATION_DISABLED_COUNTRIES.includes(bizCountry) ? (
						<BulkLocations />
					) : (
						<Redirect to="/locations" />
					)
				}
			/>
			<Route exact path="/bulk/items" component={BulkItems} />
			<Route exact path="/bulk/modifier-groups" component={BulkModifierGroups} />
			<Route exact path="/bulk/modifiers" component={BulkModifiers} />
			<Route exact path="/bulk/customers" component={BulkCustomers} />
		</div>
	);
};
export default BulkOperationsContainer;
