import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/GalleryEdit/BasicInfo";

// third party
import { connect } from "react-redux";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";

// actions
import { fetchGalleriesList } from "../actions/gallery";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_GALLERY, GET_LANGUAGES_LIST } from "../graphql/gallery";

const GalleryCreate = ({ hasAccess = false, file = undefined }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(file ? true : false);
	const [languagesList, setLanguagesList] = useState([]);
	const [imageBase64, setImageBase64] = useState("#");
	const [data, setData] = useState({
		imgType: [],
		markups: "",
		supportedLanguages: [],
		locationGroups: []
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				setImageBase64(e.target.result);
			};
			reader.readAsDataURL(file);
		}
	}, [file]);

	const getLanguagesList = async () => {
		try {
			const resp = await client.query({
				query: GET_LANGUAGES_LIST,
				fetchPolicy: "no-cache"
			});
			setLanguagesList(resp.data.languages);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	useEffect(() => {
		getLanguagesList();
	}, []);

	const handleCancel = () => {
		setFormState(false);
		store.dispatch({
			type: ActionTypes.UPLOAD_GALLERY_IMAGE,
			payload: { image: undefined }
		});
		fetchGalleriesList();
		setTimeout(() => {
			history.push("/gallery");
		}, 100);
	};

	const handleForm = (formData) => {
		setData({
			...data,
			...formData
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		if (file) {
			setConfirmLoading(true);
			try {
				const variables = {
					...data
				};
				if (file) {
					variables.image = file;
				}
				if (variables.imgType.length) {
					variables.imgType = variables.imgType.map((type) => type.value);
				}
				if (variables.supportedLanguages.length) {
					variables.supportedLanguages = variables.supportedLanguages.map((lang) => lang.value);
				}
				if (variables.locationGroups.length > 0) {
					variables.locationGroupIds = variables.locationGroups.map((group) => group.id);
				}
				const resp = await client.mutate({
					mutation: CREATE_GALLERY,
					variables
				});
				if (resp.data.saveGallery.status.success) {
					setConfirmLoading(false);
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Gallery image created",
							timeout: 5000,
							error: false
						}
					});
					store.dispatch({
						type: ActionTypes.UPDATE_GALLERIES_LIST,
						payload: resp.data.saveGallery.object
					});
					history.push(`/gallery/edit/${resp.data.saveGallery.object.id}`);
				} else {
					setConfirmLoading(false);
					setError(parseErrorMessages(resp.data.saveGallery.status.messages));
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
				setConfirmLoading(false);
			}
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please upload an image",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Image"
			subTitle="Create a new gallery image"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content gallery-create-container">
				<BasicInfo
					data={data}
					languagesList={languagesList}
					handleForm={handleForm}
					file={file}
					imageBase64={imageBase64}
					validations={error.fields || {}}
					readOnly={!hasAccess}
				/>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	file: store.galleriesList.data.image
});
export default connect(mapStateToProps)(GalleryCreate);
