import React, { Component } from "react";

// components
import { Header } from "../components/GalleriesList/Header";
import { GalleryImages } from "../components/GalleriesList/GalleryImages";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { Uploader } from "../components/_commons/Uploader";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import Compressor from "compressorjs";

// actions
import { fetchGalleriesList } from "../actions/gallery";
import { fetchCiLocationGroups } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// utils
import history from "../history";
import { getFileExtension, scroll } from "../atlas-utils";

// constant
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { PageSwitch } from "../components/GalleriesList/PageSwitch";

@connect((store) => ({
	galleriesList: store.galleriesList,
	galleriesListState: store.galleriesListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class GalleriesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			showFilters: false
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "galleries_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch Webhooks list
		await fetchGalleriesList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
		fetchCiLocationGroups();
	}

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updategalleriesListState = (payload) => {
		store.dispatch({
			type: ActionTypes.GALLERIES_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updategalleriesListState({
			currentFilters: this.props.galleriesListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.galleriesListState.currentFilters
		};
		currentFilters[field] = value;
		this.updategalleriesListState({
			currentFilters
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updategalleriesListState({
			appliedFilters: {
				...this.props.galleriesListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "galleries_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchGalleriesList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().galleriesListState;
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				store.dispatch({
					type: ActionTypes.GALLERIES_LIST_STATE_RESET
				});
				await fetchGalleriesList();
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.galleriesListState;
		const offset = (page - 1) * limit;
		this.updategalleriesListState({
			offset
		});
		// fetch new Webhooks list
		fetchGalleriesList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.galleriesListState;
		if (size && size?.value !== limit) {
			this.updategalleriesListState({
				[field]: size.value
			});
			// fetch new Webhooks list
			await fetchGalleriesList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updategalleriesListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.GALLERIES_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchGalleriesList();
	};

	handleImage = async (files) => {
		if (files[0] && files[0].size < 2097152) {
			store.dispatch({
				type: ActionTypes.UPLOAD_GALLERY_IMAGE,
				payload: { image: files[0] }
			});
			setTimeout(() => {
				history.push("/gallery/new");
			}, 100);
		} else if (getFileExtension(files[0]?.name) !== "gif") {
			const result = await new Promise((resolve, reject) => {
				new Compressor(files[0], {
					quality: 0.6,
					maxWidth: 4000,
					success: resolve,
					error: reject
				});
			});
			if (result && result.size < 10475200) {
				store.dispatch({
					type: ActionTypes.UPLOAD_GALLERY_IMAGE,
					payload: { image: result }
				});
				setTimeout(() => {
					history.push("/gallery/new");
				}, 100);
			} else {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Image size is too large, please keep it below 10 MB",
						timeout: 3000,
						error: true
					}
				});
			}
		} else if (getFileExtension(files[0]?.name) === "gif" && files[0]?.size < 10475200) {
			store.dispatch({
				type: ActionTypes.UPLOAD_GALLERY_IMAGE,
				payload: { image: files[0] }
			});
			setTimeout(() => {
				history.push("/gallery/new");
			}, 100);
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Image size is too large, please keep it below 10 MB",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	handleDeleteImage = (e) => {
		e.stopPropagation();
		store.dispatch({
			type: ActionTypes.UPLOAD_GALLERY_IMAGE,
			payload: { image: undefined }
		});
	};

	render() {
		const { galleriesList, galleriesListState, configItems, access = {} } = this.props;
		const { limit, offset, currentFilters } = galleriesListState;

		const { locationGroups } = configItems;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (currentFilters[f].value && currentFilters[f].value != "") {
				filterCount++;
			}
		}

		return (
			<div className="gallery-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={galleriesList.data.filters}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
					/>
				)}

				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
				/>
				<div
					className={
						"galleries-list-container " +
						(galleriesList.data.objects.length > 0 && galleriesList.loading ? "disabled" : "")
					}
				>
					<Uploader
						onChange={this.handleImage}
						showDelete={true}
						handleDelete={this.handleDeleteImage}
						classes={!access.isCatalogueManagement ? "read-only" : ""}
					/>
					<GalleryImages
						data={galleriesList.data.objects || []}
						loading={galleriesList.loading}
						handlePagination={this.handlePagination}
						limit={limit}
						offset={offset}
						count={galleriesList.data.count || 0}
					/>
				</div>
				{galleriesList.data.objects.length === 0 && !galleriesList.loading && (
					<div className="no-items-placeholder">No Images found!</div>
				)}
				<Paginator
					limit={limit}
					offset={offset}
					count={galleriesList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
			</div>
		);
	}
}
