import gql from "graphql-tag";

export const GET_CUSTOMERS_LIST = gql`
	query getCustomersList(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		customers(limit: $limit, offset: $offset, sort: $sort, filters: $filters, search: $search) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				currentPrepaidBalance
				currentPoints
				dateJoined
				firstName
				fullName
				phone
				email
				totalTransactions
				totalSpent
				signupChannel
				signupStore {
					id
					title
				}
				last30DaysTransactionsCount
			}
		}
	}
`;

export const GET_CUSTOMER = gql`
	query getCustomer($id: Int!) {
		customer(id: $id) {
			id
			socialConnect
			currentPrepaidBalance
			currentPoints
			lastTransactionDate
			totalTransactions
			totalSpent
			dateJoined
			firstName
			fullName
			phone
			email
			gender
			birthdate
			anniversary
			signupChannel
			isActive
			emailNotificationEnabled
			smsNotificationEnabled
			walletBalance
			userAppVersions {
				version
				platform
			}
			signupStore {
				id
				title
			}
			addresses {
				id
				address1
				address2
				city
				pin
				subLocality
				tag
			}
			tags {
				id
				name
			}
		}
	}
`;

export const UPDATE_CUSTOMER = gql`
	mutation updateCustomer(
		$id: Int!
		$emailNotificationEnabled: Boolean
		$smsNotificationEnabled: Boolean
		$isActive: Boolean
		$tags: [String]
	) {
		customer(
			id: $id
			emailNotificationEnabled: $emailNotificationEnabled
			smsNotificationEnabled: $smsNotificationEnabled
			isActive: $isActive
			tags: $tags
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			obj {
				isActive
				emailNotificationEnabled
				smsNotificationEnabled
				tags {
					id
					name
				}
			}
		}
	}
`;

export const GET_CUSTOMER_FEEDBACKS = gql`
	query getCustomerFeedbacks($customerId: Int!, $limit: Int, $offset: Int, $sort: SortInput) {
		customerFeedbacks(customerId: $customerId, limit: $limit, offset: $offset, sort: $sort) {
			count
			objects {
				id
				rating
				choiceText
				comments
				created
				replies {
					subject
					body
					channel
					created
				}
				objectId
				contentType
				bizLocation {
					id
					name
				}
			}
		}
	}
`;

export const FEEDBACK_REPLY = gql`
	mutation feedbackReply($feedbackId: Int!, $medium: FeedbackReplyChannel!, $subject: String, $message: String!) {
		feedbackReply(feedbackId: $feedbackId, medium: $medium, subject: $subject, message: $message) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_CUSTOMER_NOTES = gql`
	query getCustomerNotes($id: Int!) {
		customer(id: $id) {
			id
			notes {
				id
				created
				title
				text
				user {
					username
					firstName
					lastName
				}
			}
		}
	}
`;
export const UPDATE_CUSTOMER_NOTE = gql`
	mutation updateCustomer($id: Int!, $customerNotes: [GenericAnnotationInputType]) {
		customer(id: $id, customerNotes: $customerNotes) {
			status {
				success
				messages {
					field
					message
				}
			}
			obj {
				notes {
					id
					created
					title
					text
					user {
						username
						firstName
						lastName
					}
				}
			}
		}
	}
`;

export const DELETE_CUSTOMER_NOTE = gql`
	mutation deleteCustomerNote($ids: [Int]!) {
		deleteCustomerNote(ids: $ids) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_CUSTOMER_TIMELINE = gql`
	query getCustomerTimeline($id: Int!, $limit: Int, $offset: Int) {
		customer(id: $id) {
			id
			timeline(limit: $limit, offset: $offset) {
				count
				objects {
					id
					event
					title
					description
					extra
					created
					relatedObjId
				}
			}
		}
	}
`;

export const UPDATE_WALLET_BALANCE = gql`
	mutation updateWalletBalance($customerId: Int, $amount: Float, $reloadType: WalletReloadTypes, $comments: String) {
		reloadWallet(
			input: { customerId: $customerId, reloadType: $reloadType, amount: $amount, comments: $comments }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			obj {
				walletBalance
			}
		}
	}
`;

export const ADD_NEW_USER = gql`
	mutation saveUser($phone: String!) {
		saveOfflineUser(input: { phone: $phone }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
			}
		}
	}
`;
