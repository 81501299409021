// third party
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { withScope, captureException } from "@sentry/browser";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split } from "apollo-link";

// utils
import { lS } from "./atlas-utils";
import { logout } from "./components/SiteComp";

// client state
import { store } from "./store/configureStore";

// actions
import { updateBizState } from "./actions/actions";

// constants
const API_URL = process.env.REACT_APP_ONBOARDING_API_URL;
const WEB_SOCKET_API_URL = process.env.REACT_APP_ONBOARDING_WEB_SOCKET_API_URL;
const ONBOARDING_API_SECRET = process.env.REACT_APP_ONBOARDING_API_SECRET;
const ENV = process.env.NODE_ENV;

// initial state for apollo cache
const defaults = {};
// get the authentication token from local storage if it exists
// also update biz state if biz ids don't match in local storage and redux store
let auth = lS.get("auth");

// setup network links
// const uploadLink = createUploadLink({ uri: API_URL });
// const authLink = setContext((_, { headers }) => {
// 	const { loggedInbizDetail } = store.getState().login;
// 	if (auth && loggedInbizDetail && auth?.biz?.id !== loggedInbizDetail?.id) {
// 		const isPrismEnabled = auth?.biz?.modulesEnabled?.includes("PRISM");
// 		updateBizState(isPrismEnabled, auth?.biz?.id);
// 	}
// 	return {
// 		headers: {
// 			...headers,
// 			Authorization: auth && `Bearer ${auth.token}`
// 			// "x-hasura-admin-secret": ONBOARDING_API_SECRET
// 		}
// 	};
// });

// setup websocket link
let wsLink;
const createWsLink = () => {
	return new WebSocketLink({
		uri: WEB_SOCKET_API_URL,
		options: {
			lazy: true,
			reconnect: true,
			connectionParams: {
				headers: {
					// "x-hasura-admin-secret": ONBOARDING_API_SECRET,
					Authorization: auth && `Bearer ${auth.token}`
				}
			}
		}
	});
};

export const closeOnboardingWsLink = () => {
	if (wsLink?.subscriptionClient) {
		// disable reconnection
		wsLink.subscriptionClient.reconnect = false;

		wsLink.subscriptionClient.close(false, false); // close the connection without reconnecting
	}
};

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
// 	// split based on operation type
// 	({ query }) => {
// 		const definition = getMainDefinition(query);
// 		return definition.kind === "OperationDefinition" && definition.operation === "subscription";
// 	},
// 	wsLink,
// 	// authLink
// );

const errorLink = onError((response) => {
	console.log(response);
	if (
		response.graphQLErrors &&
		response.graphQLErrors.length > 0 &&
		(response.graphQLErrors[0].message == "Invalid Token" ||
			response.graphQLErrors[0].message == "Token Expired" ||
			response.graphQLErrors[0].message == "User Is Not A Sub-Administrator Of Any Biz")
	) {
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: "Your session has been expired!",
				timeout: 5000,
				error: true
			}
		});
		logout();
	} else if (
		ENV === "production" &&
		response.graphQLErrors &&
		response.graphQLErrors?.length > 0 &&
		response.graphQLErrors?.[0]?.message !== "Object Does Not Exist"
	) {
		// add graphql query, variables and biz info under "Additional Data" section in Sentry
		const login = store.getState().login;
		withScope((scope) => {
			scope.setExtra("Graphql query", response.operation.query.loc.source.body);
			scope.setExtra("Variables", response.operation.variables);
			if (login?.loggedInbizDetail?.id) {
				scope.setExtra("Biz", {
					id: login?.loggedInbizDetail?.id,
					name: login?.loggedInbizDetail?.name
				});
			}
			captureException(response.graphQLErrors);
		});
	}
});

export let clientOnboarding;

export const resetClientOnboarding = async () => {
	auth = lS.get("auth");

	// close the existing WebSocket connection
	closeOnboardingWsLink();

	// recreate the WebSocketLink
	wsLink = createWsLink();

	// reset the Apollo Client
	clientOnboarding = new ApolloClient({
		cache: new InMemoryCache(),
		clientState: {
			defaults
		},
		link: ApolloLink.from([wsLink, errorLink])
	});
	window.clientOnboarding = clientOnboarding;
};
