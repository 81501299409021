import React, { useState, useCallback, useEffect } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { Paginator } from "../_commons/Paginator";
import ItemTagEntityAssociation from "../EntityAssociations/ItemTagEntityAssociation";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_TAG_GROUP_ITEM_TAGS } from "../../graphql/itemTags";

// actions
import { ActionTypes } from "../../actions/_types";

// config
import { TAG_GROUP_ITEM_TAGS_LIST_HEADER_FIELDS, NESTED_ENTITY_TYPES } from "../../client-config";

// constant
const FILTER_INITIAL_STATE = {
	title: "",
	crm_title: ""
};

const ItemTags = ({ tagGroupId, isSystem, setModalBusy, nestedEntity, handleNestedEntity, readOnly = true }) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [itemTagsList, setItemTagslist] = useState({
		objects: [],
		count: 0
	});
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const fetchItemTags = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(tagGroupId),
				limit,
				offset,
				sort: {
					field: "title",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_TAG_GROUP_ITEM_TAGS,
				variables,
				fetchPolicy: "no-cache"
			});
			setItemTagslist(resp.data.itemTagGroup.tags);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [tagGroupId, limit, offset, appliedFilters]);

	useEffect(() => {
		if (!nestedEntity.show) {
			fetchItemTags();
		}
	}, [nestedEntity, fetchItemTags]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchItemTags();
		}
	};

	return (
		<div className={"item-tags-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Associated Tags</div>
					<div className="header-subtext">{`${itemTagsList.count} tag(s) are associated to this tag group`}</div>
				</div>
				<div>{!isSystem && !readOnly && <Button clickHandler={openAssociationSidebar}>Update</Button>}</div>
			</div>
			<div className="search-container">
				<InputWithLabel
					value={currFilters.title}
					onChange={(e) => setFilter("title", e.target.value)}
					placeholder="Enter Title"
				>
					Title
				</InputWithLabel>
				<InputWithLabel
					value={currFilters.crm_title}
					onChange={(e) => setFilter("crm_title", e.target.value)}
					placeholder="Enter CRM Title"
				>
					CRM Title
				</InputWithLabel>
			</div>
			<Table data={itemTagsList.objects} loading={loading} handleNestedEntity={handleNestedEntity} />
			<Paginator limit={limit} offset={offset} count={itemTagsList.count || 0} goToPage={handlePagination} />
			<ItemTagEntityAssociation
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={{ label: "Tag group", value: "TAG_GROUP" }}
				entityId={tagGroupId}
			/>
		</div>
	);
};
export default ItemTags;

export const Table = ({ data, loading, sortList, sortedField, handleNestedEntity }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container tag-group-item-tags-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={TAG_GROUP_ITEM_TAGS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleNestedEntity={handleNestedEntity}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Tags found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row item-tags-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({ id, title, numItems, numOptions, crmTitle, style, handleNestedEntity }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows item-tags-list-table"
		>
			<div className="at-table-cell at-cell-text title" title={title}>
				<div className="title-desc">
					<a
						role="button"
						className="link-text"
						onClick={(e) => handleNestedEntity(e, NESTED_ENTITY_TYPES[10], id)}
					>
						{title || id}
					</a>
				</div>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
			</div>
			<div className="at-table-cell at-cell-text num-items">{numItems || 0}</div>
			<div className="at-table-cell at-cell-text num-modifiers">{numOptions || 0}</div>
		</animated.div>
	);
};
