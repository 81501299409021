import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import ModifierCreate from "../../containers/ModifierCreate";
import ModifierEdit from "../../containers/ModifierEdit";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce, uniqBy } from "lodash";
import { connect } from "react-redux";

// client
import { clientMenu } from "../../client-menu";

// utils
import { client } from "../../client";
import {
	scroll,
	msaagesArrayToHtml,
	printCurrency,
	trackEvent,
	getTrackingSource,
	fixedToTwoDecimal,
	findChangedKeysInTwoObjects
} from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ENTITY_MODIFIERS, UPDATE_ENTITY_MODIFIERS } from "../../graphql/modifierEntityAssociations";
import { UPDATE_MENU } from "../../graphql/menus";

// actions
import { ActionTypes } from "../../actions/_types";

// services
import NotificationServices from "../../services/NotificationService";

// constants
import { FOOD_TYPE_MAP, TRACKING_ENTITY, TRACKING_STATUS } from "../../client-config";

const FILTER_INITIAL_STATE = {
	option_title: "",
	crm_title: ""
};
const ITEMS_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const SORT_INIT_STATE = {
	field: "option_order",
	order: "ASC"
};

const ModifierEntityAssociations = ({
	entityId,
	entityType,
	currencySymbol,
	isOpen,
	close,
	isViewedFromMenuSection,
	menuDetailsData,
	selectedModifiers = {}
}) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [modifiersData, setModifiersData] = useState(ITEMS_INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [modifiersUpdates, setModifiersUpdates] = useState({});
	const [isCreateModifierOpen, setCreateModifierOpen] = useState(false);
	const [isModifierEditOpen, setModifierEditOpen] = useState(null);
	const [eventTracking, setEventTracking] = useState({
		type: `${entityType.label} first`,
		filters: {}
	});
	const [sortInfo, setSortInfo] = useState(SORT_INIT_STATE);
	const tableRef = useRef();
	const totalChanges = findChangedKeysInTwoObjects(modifiersUpdates, selectedModifiers).length;
	const [preSelectionModifiers, setPreSelectionModifiers] = useState([]);
	const [search, setSearch] = useState("");

	const fetchData = useCallback(
		async (searchValue = "") => {
			try {
				let filtersObject = [];
				setLoading(true);
				const variables = {
					entityId,
					entityType: entityType.value,
					limit,
					offset,
					sort: sortInfo
				};

				if (menuDetailsData?.menuId) {
					variables.menuId = menuDetailsData.menuId;
					variables.sort = {
						field: "associated",
						order: "ASC"
					};
					if (preSelectionModifiers.length) {
						filtersObject.push({
							field: "pre-selected",
							value: String(preSelectionModifiers.join(","))
						});
					}
				}
				if (isViewedFromMenuSection) {
					variables.search = [{ key: "default", value: searchValue || search }];
				}

				// sidebar filters
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				});
				if (filtersObject.length > 0) {
					variables.filters = filtersObject;
				}
				const resp = await client.query({
					query: GET_ENTITY_MODIFIERS,
					variables,
					fetchPolicy: "no-cache"
				});

				// show menu names in the table if available
				const optionsInMenu = menuDetailsData?.options || [];
				if (optionsInMenu?.length && resp?.data?.entityOptionAssociations?.objects?.length) {
					resp.data.entityOptionAssociations.objects.forEach((modifier) => {
						const targetModifier = optionsInMenu.find(
							(option) => String(option.id) === String(modifier.id)
						);
						if (targetModifier) {
							modifier.titleInMenu = targetModifier?.overrides?.name || "";
						}
					});
				}

				setModifiersData(resp.data.entityOptionAssociations);
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[entityId, entityType, limit, offset, appliedFilters, sortInfo, preSelectionModifiers]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	useEffect(() => {
		// reset state when the drawer is open/closed
		setPreSelectionModifiers([]);
	}, [isOpen]);

	useEffect(() => {
		if (preSelectionModifiers.length) {
			// redirect user to first page if a new modifier group is created
			setOffset(0);
			setCurrFilters(FILTER_INITIAL_STATE);
			fetchData();
		}
	}, [preSelectionModifiers]);

	const applyFilter = useCallback(
		debounce((filters, field) => {
			setAppliedFilters(filters);
			// track filters which are being used
			setEventTracking({
				...eventTracking,
				filters: {
					...eventTracking["filters"],
					[field]: true
				}
			});
			setOffset(0);
		}, 500),
		[eventTracking]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters, field);
		},
		[currFilters, applyFilter]
	);
	const fetchDataDebounce = useCallback(
		debounce((searchValue) => fetchData(searchValue), 500),
		[fetchData]
	);
	const handleSearch = (value) => {
		setSearch(value);
		fetchDataDebounce(value);
	};

	const syncData = useCallback(() => {
		setModifiersData({
			...modifiersData,
			objects: modifiersData.objects.map((loc) => {
				if (modifiersUpdates[loc.id] !== undefined) {
					return {
						...loc,
						isAssociated: modifiersUpdates[loc.id]
					};
				}
				return loc;
			})
		});
		setModifiersUpdates({});
	}, [modifiersData, modifiersUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setModifiersData(ITEMS_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setModifiersUpdates({});
			setIsCheckedAll(false);
			setSortInfo(SORT_INIT_STATE);
			setEventTracking({
				type: `${entityType.label} first`,
				filters: {}
			});
			setSearch("");
			close(refresh);
		},
		[close, entityType]
	);

	const handleUpdateModifierEntityAssociationInMenuService = async () => {
		const menuId = menuDetailsData?.menuId;
		const strigifiedOptionGroupId = String(entityId);
		const updatedModifiers = [];
		Object.keys(modifiersUpdates).forEach((optionId) => {
			if (modifiersUpdates[optionId]) {
				updatedModifiers.push({
					id: String(optionId),
					nestedOptionGroups: [],
					overrides: {
						name: null,
						description: null,
						price: null,
						image: null,
						imageUrl: null,
						isRecommended: null
					}
				});
			}
		});
		try {
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: (
						uniqBy([...menuDetailsData.options, ...updatedModifiers], (option) => option.id) || []
					).map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: null
						}
					})),
					optionGroups: menuDetailsData.optionGroups.map((optionGroup) =>
						optionGroup?.id === strigifiedOptionGroupId
							? {
									...optionGroup,
									options: updatedModifiers.map((modifier) => modifier.id)
							  }
							: optionGroup
					),
					items: menuDetailsData.items.map((item) => ({
						...item,
						overrides: { ...item.overrides, image: "" }
					})),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: ""
						}
					}))
				}
			};

			const modifierOptionGroupAssocResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (modifierOptionGroupAssocResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Associated modifier group and modifiers successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: modifierOptionGroupAssocResp?.data?.updateMenuV2
				});
				setConfirmLoading(false);
				// fetchMenuDetails(menuId)
				// fetchParentSectionsList(menuId)
				handleClose(true);
				return true;
			} else {
				setConfirmLoading(false);
				NotificationServices.pushNotification({
					message: "Failed to associate modifier groups to modifiers",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			setConfirmLoading(false);
			NotificationServices.pushNotification({
				message: "Failed to associate modifier groups to modifiers",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
		}
	};

	const handleUpdate = useCallback(async () => {
		const eventMeta = {};
		if (entityType.value === "TAGS") {
			eventMeta.entity = TRACKING_ENTITY.MODIFIER;
		}

		if (entityType.value === "OPTION_GROUP") {
			eventMeta.source = getTrackingSource();
		}

		try {
			setConfirmLoading(true);
			const variables = {
				entityId,
				entityType: entityType.value,
				optionsToAssociate: [],
				optionsToDisassociate: []
			};
			for (let id in modifiersUpdates) {
				if (modifiersUpdates[id]) {
					variables.optionsToAssociate.push(parseInt(id));
				} else {
					variables.optionsToDisassociate.push(parseInt(id));
				}
			}

			if (entityType.changeInAssociatedEntity) {
				eventMeta.change_in_associated_entity =
					variables.optionsToAssociate.length - variables.optionsToDisassociate.length;
			}
			if (isViewedFromMenuSection) {
				handleUpdateModifierEntityAssociationInMenuService();
				return;
			}
			const resp = await client.mutate({
				mutation: UPDATE_ENTITY_MODIFIERS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateEntityOptions.status.success) {
				if (entityType.event) {
					eventMeta.status = TRACKING_STATUS.SUCCESS;
					trackEvent(entityType.event, eventMeta);
				}

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Modifier association updated!",
						timeout: 2000,
						error: false
					}
				});
				syncData();
				handleClose(true);
			} else {
				if (entityType.event) {
					eventMeta.status = TRACKING_STATUS.FAILURE;
					trackEvent(entityType.event, eventMeta);
				}

				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateEntityOptions.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			if (entityType.event) {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(entityType.event, eventMeta);
			}

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [entityId, entityType, modifiersUpdates, fetchData, syncData, handleClose, eventTracking]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setModifiersUpdates({
			...modifiersUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		modifiersData.objects.forEach((loc) => {
			updates[loc.id] = toCheckAll;
		});
		setModifiersUpdates({
			...modifiersUpdates,
			...updates
		});
	};

	const handleSort = (e) => {
		e.stopPropagation();
		setOffset(0);
		setModifiersUpdates({});
		if (sortInfo?.field === "option_order") {
			setSortInfo({ field: "associated", order: "ASC" });
		} else if (sortInfo?.field === "associated" && sortInfo?.order === "ASC") {
			setSortInfo({ field: "associated", order: "DESC" });
		} else {
			setSortInfo({ field: "associated", order: "ASC" });
		}
	};

	const handleModifierCreationDrawerClose = (success = null) => {
		setCreateModifierOpen(false);
		if (isViewedFromMenuSection && success) {
			setModifiersUpdates((current) => ({
				...current,
				[success]: true
			}));
			setPreSelectionModifiers((current) => [...current, success]);
		}
		if (success) {
			setModifierEditOpen(success);
		}
	};

	useEffect(() => {
		if (isOpen && isViewedFromMenuSection) {
			setModifiersUpdates({
				...selectedModifiers
			});
		}
	}, [isOpen]);

	return (
		<div className="modifier-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Modifiers"
				subTitle={`Manage modifiers where this ${entityType.label} is present`}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<>
						{isViewedFromMenuSection && (
							<Button
								type="secondary"
								clickHandler={() => {
									setCreateModifierOpen(true);
								}}
								classes={"Mstart(10px)"}
							>
								Create Modifier
							</Button>
						)}
						<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
							{totalChanges === 0
								? "Update"
								: isViewedFromMenuSection
								? "Update"
								: `Update ${totalChanges} Modifier(s)`}
						</Button>
					</>
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-container">
						{isViewedFromMenuSection ? (
							<InputWithLabel
								value={search}
								onChange={(e) => handleSearch(e.target.value)}
								placeholder="Search by name"
							>
								Name
							</InputWithLabel>
						) : (
							<>
								<InputWithLabel
									value={currFilters.name}
									onChange={(e) => setFilter("option_title", e.target.value)}
									placeholder="Enter Name"
								>
									Name
								</InputWithLabel>
								<InputWithLabel
									value={currFilters.crm_title}
									onChange={(e) => setFilter("crm_title", e.target.value)}
									placeholder="Enter CRM Title"
								>
									CRM Title
								</InputWithLabel>
							</>
						)}
					</div>
					<Table
						data={modifiersData.objects}
						loading={loading}
						handleCheck={handleCheck}
						modifiersUpdates={modifiersUpdates}
						currencySymbol={currencySymbol}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						sortInfo={sortInfo}
						handleSort={handleSort}
						isViewedFromMenuSection={isViewedFromMenuSection}
					/>
					<Paginator limit={limit} offset={offset} count={modifiersData.count} goToPage={handlePagination} />
				</div>
				{isViewedFromMenuSection && (
					<ModifierCreate
						isFromMenuSection={isViewedFromMenuSection}
						isOpen={isCreateModifierOpen}
						isNested
						close={handleModifierCreationDrawerClose}
						hasAccess={true}
					/>
				)}
				{isViewedFromMenuSection && (
					<ModifierEdit
						isOpen={!!isModifierEditOpen}
						isFromMenuSection={isViewedFromMenuSection}
						isNested
						isMenuFlowIgnored
						match={{
							params: {
								id: isModifierEditOpen
							}
						}}
						closeNestedContainer={() => setModifierEditOpen(null)}
					/>
				)}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	menuDetailsData: store.menuDetailsState.menuDetailsData
}))(ModifierEntityAssociations);

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	currencySymbol,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	modifiersUpdates,
	sortInfo,
	handleSort,
	isViewedFromMenuSection
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container modifiers-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						sortInfo={sortInfo}
						handleSort={handleSort}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							modifiersUpdates={modifiersUpdates}
							currencySymbol={currencySymbol}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							isViewedFromMenuSection={isViewedFromMenuSection}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Modifiers found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, sortInfo, handleSort }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<div className={"checkbox-sort" + (sortInfo?.field === "associated" ? " selected" : "")}>
				<img onClick={handleSort} src="/assets/icons/icon-sort.svg" className="sort-button" alt="" />
				<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Name">
					Title
				</CheckBox>
			</div>
		</div>
		<div className={`at-table-cell at-table-header at-header-text crm-title`}>CRM Title</div>
		<div className={`at-table-cell at-table-header at-header-text price`}>Price</div>
	</div>
);

export const TableList = ({
	id,
	optionTitle,
	titleInMenu = "",
	isAssociated,
	optionPrice,
	crmTitle,
	foodType,
	currencySymbol,
	style,
	handleCheck,
	modifiersUpdates,
	isViewedFromMenuSection
}) => {
	const status = isViewedFromMenuSection
		? !!modifiersUpdates[id]
		: modifiersUpdates[id] === undefined
		? isAssociated
		: modifiersUpdates[id];

	let primaryTitle = "";
	let secondaryTitle = "";
	if (titleInMenu && titleInMenu !== optionTitle) {
		primaryTitle = titleInMenu;
		secondaryTitle = optionTitle;
	} else {
		primaryTitle = optionTitle;
	}

	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name  name-field-spacing">
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} title={primaryTitle || "--"}>
					<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<span>{primaryTitle || id}</span>
				</CheckBox>
				{secondaryTitle && <div className="handle">{secondaryTitle}</div>}
			</div>

			<div className="at-table-cell at-cell-text crm-title">
				{crmTitle ? <div className="handle">{crmTitle}</div> : "--"}
			</div>
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{fixedToTwoDecimal(optionPrice) || 0}
			</div>
		</animated.div>
	);
};
