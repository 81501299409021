import gql from "graphql-tag";

export const GET_USERS_LIST = gql`
	query getUsersList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		users(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				fullName
				username
				email
				isActive
				groups {
					id
					name
				}
			}
		}
	}
`;

export const GET_USER = gql`
	query getUser($id: Int) {
		user(id: $id) {
			id
			firstName
			lastName
			username
			email
			isActive
			groups {
				name
				id
			}
			locations {
				id
				name
			}
		}
	}
`;

export const CREATE_USER = gql`
	mutation createUser(
		$firstName: String!
		$lastName: String!
		$username: String!
		$email: String!
		$password1: String!
		$password2: String!
		$groups: [Int]
	) {
		saveUser(
			input: {
				firstName: $firstName
				lastName: $lastName
				username: $username
				email: $email
				password1: $password1
				password2: $password2
				groups: $groups
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				fullName
				username
				email
				groups {
					id
					name
				}
			}
		}
	}
`;

export const EDIT_USER = gql`
	mutation editUser(
		$id: Int!
		$firstName: String!
		$lastName: String
		$groups: [Int]
		$isActive: Boolean
		$locations: [Int]
	) {
		updateUser(
			input: {
				id: $id
				firstName: $firstName
				lastName: $lastName
				groups: $groups
				isActive: $isActive
				locations: $locations
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				fullName
				username
				email
				isActive
				groups {
					id
					name
				}
				locations {
					id
					title
				}
			}
		}
	}
`;

export const INVITE_USER = gql`
	mutation inviteUser($email: String!, $roles: [String]!, $message: String, $locations: [Int]) {
		userInvite(input: { email: $email, roles: $roles, message: $message, locations: $locations }) {
			uuid
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
