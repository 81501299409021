import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, printCurrency } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ACTION_ITEMS, UPDATE_ACTION_ITEMS } from "../../graphql/couponActionEntityAssociation";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { FOOD_TYPE_MAP } from "../../client-config";
const FILTER_INITIAL_STATE = {
	item_title: ""
};
const ENTITY_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const CouponActionEntityAssociation = ({
	couponId,
	pActionId,
	isOpen,
	close,
	entityType = {},
	isActionsFormTouched = false,
	saveActionsForm,
	currencySymbol
}) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [entityData, setEntityData] = useState(ENTITY_INITIAL_DATA);
	const [categoryfilters, setCategoryfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [itemsUpdates, setItemsUpdates] = useState({});
	const tableRef = useRef();
	const totalChanges = Object.keys(itemsUpdates).length;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				couponId,
				pActionId,
				paramType: entityType.paramType,
				limit,
				offset,
				sort: {
					field: "sort_order",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_ACTION_ITEMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setEntityData(resp.data.couponActionItemAssociations);
			setCategoryfilters(
				resp.data.couponActionItemAssociations.filters.find((fl) => fl.field === "item_discrete_category_id")
			);
			setTagfilters(resp.data.couponActionItemAssociations.filters.find((fl) => fl.field === "tags"));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [couponId, pActionId, entityType, limit, offset, appliedFilters]);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const syncData = useCallback(() => {
		setEntityData({
			...entityData,
			objects: entityData.objects.map((loc) => {
				if (itemsUpdates[loc.id] !== undefined) {
					return {
						...loc,
						isAssociated: itemsUpdates[loc.id]
					};
				}
				return loc;
			})
		});
		setItemsUpdates({});
	}, [entityData, itemsUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setEntityData(ENTITY_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setItemsUpdates({});
			setIsCheckedAll(false);
			close(refresh);
		},
		[close]
	);

	const handleUpdate = useCallback(async () => {
		if (isActionsFormTouched) {
			await saveActionsForm();
		}
		try {
			setConfirmLoading(true);
			const variables = {
				couponId,
				pActionId,
				paramType: entityType.paramType,
				itemsToAssociate: [],
				itemsToDisassociate: []
			};
			for (let id in itemsUpdates) {
				if (itemsUpdates[id]) {
					variables.itemsToAssociate.push(parseInt(id));
				} else {
					variables.itemsToDisassociate.push(parseInt(id));
				}
			}
			const resp = await client.mutate({
				mutation: UPDATE_ACTION_ITEMS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateCouponActionItems.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Item association updated!",
						timeout: 2000,
						error: false
					}
				});
				syncData();
				handleClose(true);
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateCouponActionItems.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [
		couponId,
		pActionId,
		entityType,
		itemsUpdates,
		fetchData,
		syncData,
		handleClose,
		isActionsFormTouched,
		saveActionsForm
	]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setItemsUpdates({
			...itemsUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		entityData.objects.forEach((loc) => {
			updates[loc.id] = toCheckAll;
		});
		setItemsUpdates({
			...itemsUpdates,
			...updates
		});
	};

	return (
		<div className="item-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title={entityType.headerText}
				subTitle={`Manage ${entityType.entity} while applying this coupon`}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
						{totalChanges === 0 ? "Update" : `Update ${totalChanges} Item(s)`}
					</Button>
				}
			>
				<div className="form-content" ref={tableRef}>
					{entityType.entity === "items" && (
						<React.Fragment>
							<div className="search-filter-container">
								<InputWithLabel
									value={currFilters.name}
									onChange={(e) => setFilter("item_title", e.target.value)}
									placeholder="Enter Name"
								>
									Name
								</InputWithLabel>
								<SelectFilter
									title="Category"
									options={categoryfilters.values || []}
									field={categoryfilters.field || ""}
									currValue={currFilters.item_discrete_category_id || ""}
									setFilter={setFilter}
									labelKey="valueForDisplay"
									valueKey="value"
								/>
								<SelectFilter
									title="Tags"
									options={tagfilters.values || []}
									field={tagfilters.field || ""}
									currValue={currFilters.tags || ""}
									setFilter={setFilter}
									labelKey="valueForDisplay"
									valueKey="value"
								/>
							</div>
							<div className="search-filter-container">
								<InputWithLabel
									value={currFilters.crm_title}
									onChange={(e) => setFilter("crm_title", e.target.value)}
									placeholder="Enter CRM Title"
								>
									CRM Title
								</InputWithLabel>
							</div>
						</React.Fragment>
					)}
					<Table
						data={entityData.objects}
						loading={loading}
						currencySymbol={currencySymbol}
						handleCheck={handleCheck}
						itemsUpdates={itemsUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						entityType={entityType}
					/>
					<Paginator limit={limit} offset={offset} count={entityData.count} goToPage={handlePagination} />
				</div>
			</FormSidebar>
		</div>
	);
};
export default CouponActionEntityAssociation;

export const Table = ({
	data,
	loading,
	currencySymbol,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	entityType = {},
	itemsUpdates
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container items-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						headerFields={entityType.tableFields}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							itemsUpdates={itemsUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							currencySymbol={currencySymbol}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Items found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// const TableHeader = ({
// 	isCheckedAll,
// 	handleCheckAll,
// }) => (
// 	<div className={`at-table-row transaction-header-row`}>
// 		<div
// 			className={
// 				(`at-table-cell at-table-header at-header-text title`)
// 			}
// 		>
// 			<CheckBox
// 				checked={isCheckedAll}
// 				clickHandler={() => handleCheckAll(!isCheckedAll)}
// 				title="Name"
// 			>
// 				Name
// 			</CheckBox>
// 		</div>
// 		<div
// 			className={
// 				(`at-table-cell at-table-header at-header-text category`)
// 			}
// 		>
// 			Category
// 		</div>
// 		<div
// 			className={
// 				(`at-table-cell at-table-header at-header-text price`)
// 			}
// 		>
// 			Price
// 		</div>
// 	</div>
// );

const TableHeader = ({ sortList, headerFields = [], isCheckedAll, handleCheckAll }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					{field.label === "Name" ? (
						<CheckBox
							checked={isCheckedAll}
							clickHandler={() => handleCheckAll(!isCheckedAll)}
							title="Name"
						>
							{field.label}
						</CheckBox>
					) : (
						<span>{field.label}</span>
					)}
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	itemTitle,
	isAssociated,
	itemPrice,
	crmTitle,
	foodType,
	category,
	tags,
	currencySymbol,
	style,
	handleCheck,
	itemsUpdates
}) => {
	const status = itemsUpdates[id] === undefined ? isAssociated : itemsUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text title">
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} title={itemTitle || "--"}>
					<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<span>{itemTitle || id}</span>
				</CheckBox>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
				<div className="text--light item_tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 2 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 2 && (
									<span className="tag-item more-tags" title={tags.slice(2).join(", ")}>
										+{tags.length - 2} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text category">{category || "--"}</div>
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{itemPrice || 0}
			</div>
		</animated.div>
	);
};
