import React, { useRef, useState } from "react";
import { InputWithLabel } from "./InputWithLabel";

export const ColorPicker = ({
	classes = "",
	onChange,
	children,
	value = "#543cb1",
	showCustomTooltip,
	tooltipInfo,
	tooltipPosition = "up-left"
}) => {
	const inputRef = useRef(null);
	const handleColorPicker = (e) => {
		const color = e.target.value;
		onChange(color);
	};

	const handleClick = () => {
		inputRef.current.click();
	};

	return (
		<div className="at-color-picker">
			{children && (
				<div className="field-label">
					{children}
					{showCustomTooltip && tooltipInfo && (
						<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
							<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
						</span>
					)}
				</div>
			)}
			<div className={"color-picker-area-container " + (classes || "")}>
				<div
					style={{
						backgroundColor: value
					}}
					className="color-preview"
					onClick={handleClick}
				/>
				<InputWithLabel value={value} onChange={handleColorPicker} />
				<input value={value} ref={inputRef} type="color" onChange={handleColorPicker} />
			</div>
		</div>
	);
};
