import React from "react";

// component
import GlobalNotifications from "../../components/_commons/GlobalNotifications";

// client
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

const renderGlobalNotificationsContainer = <GlobalNotifications />;

const pushNotification = (notificationProps) => {
	store.dispatch({
		type: ActionTypes.PUSH_NOTIFICATIONS,
		payload: {
			...notificationProps
		}
	});
};

const NotificationServices = {
	renderGlobalNotificationsContainer,
	pushNotification
};

export default NotificationServices;
