import React from "react";

// third party
import { Route, Switch } from "react-router-dom";

// components
import { CustomersList } from "./CustomersList";
import { CustomerDetails } from "./CustomerDetails";
import CustomerCreate from "./CustomerCreate";

const CustomersContainer = () => {
	return (
		<React.Fragment>
			<Route path="/customers" component={CustomersList} />
			<Switch>
				<Route exact path="/customers/new" component={CustomerCreate} />
				<Route path="/customers/:id" component={CustomerDetails} />
			</Switch>
		</React.Fragment>
	);
};
export default CustomersContainer;
