import React, { useState, useEffect, useMemo } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Switch } from "../_commons/Switch";
import { FormContainer } from "../../containers/Meraki";
import { PageSelector } from "./PageSelector";

// actions
import { fetchMerakiWebPagesList, editMerakiWebPage } from "../../actions/merakiWeb";
import { connect } from "react-redux";

const PagesComponent = ({ merakiWebWorkspace: { data: WorkspaceData } = {} }) => {
	const [loading, setLoading] = useState(false);
	const [pages, setPages] = useState([]);
	const [selectedPage, setSelectedPage] = useState(undefined);
	const [isFormTouched, setFormTouched] = useState(false);

	const fetchPagesList = async () => {
		setLoading(true);
		try {
			const pages = await fetchMerakiWebPagesList(WorkspaceData.theme.name);
			const themePages = pages.theme.map((page) => {
				page.type = "theme";
				return page;
			});
			const customPages = pages.custom.map((page) => {
				page.type = "custom";
				return page;
			});
			const allPages = themePages.concat(customPages);
			allPages.sort((pg1, pg2) => (pg1._id === "landing_screen" ? -1 : 1));
			setPages(allPages);
			if (!selectedPage) {
				// check if landing page enabled, otherwise set menu page as selected
				const landingPage = allPages.find((pg) => pg._id === "landing_screen");
				const menuPage = allPages.find((pg) => pg._id === "menu");
				if (landingPage?.enabled) {
					setSelectedPage(landingPage);
				} else {
					setSelectedPage(menuPage);
				}
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const savePage = async () => {
		setLoading(true);
		try {
			await editMerakiWebPage(selectedPage.type, selectedPage, WorkspaceData.theme.name);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchPagesList();
	}, []);

	const handleSelectPage = (page) => {
		setSelectedPage(page);
	};

	const handleCancel = async () => {
		await fetchPagesList();
		setFormTouched(false);
	};

	const handleSubmit = async () => {
		await savePage();
		setFormTouched(false);
	};

	const handlePageSEOCustomization = (pageId, field, value) => {
		let updatedPages = pages.slice();
		const page = updatedPages.find((page) => page._id === pageId);
		page.seo.en[field] = value;
		setPages(updatedPages);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handlePageEnabled = (pageId, value) => {
		let updatedPages = pages.slice();
		const page = updatedPages.find((page) => page._id === pageId);
		page.enabled = value;
		setPages(updatedPages);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	if (loading && pages.length === 0) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"pages-container " + (loading && pages.length > 0 ? "disabled" : "")}>
			<FormContainer cancel={handleCancel} submit={handleSubmit} submitTitle="Save" hideActions={!isFormTouched}>
				<div className="form-content">
					<div className="select-page-text">Select page</div>
					<div className="select-page-dropdown">
						<PageSelector pages={pages} selectedPage={selectedPage} handleSelectPage={handleSelectPage} />
						{["landing_screen", "page_not_found"].includes(selectedPage?._id) && (
							<Switch
								title="Enabled"
								checked={selectedPage?.enabled}
								clickHandler={() => handlePageEnabled(selectedPage?._id, !selectedPage?.enabled)}
							/>
						)}
					</div>
					<PageDetails page={selectedPage} handlePageSEOCustomization={handlePageSEOCustomization} />
				</div>
			</FormContainer>
		</div>
	);
};

const mapStateToProps = (store) => ({
	merakiWebWorkspace: store.merakiWebWorkspace
});

export const Pages = connect(mapStateToProps)(PagesComponent);

const PageDetails = ({ page, handlePageSEOCustomization }) => {
	if (!page) {
		return null;
	}
	return (
		<div className="page-details theme-page">
			<div className="section-highlight">SEO settings</div>
			<div className="page-seo-input">
				<InputWithLabel
					value={page.seo.en.title}
					onChange={(e) => handlePageSEOCustomization(page._id, "title", e.target.value)}
				>
					Page title
				</InputWithLabel>
				<InputWithLabel
					value={page.seo.en.description}
					onChange={(e) => handlePageSEOCustomization(page._id, "description", e.target.value)}
				>
					Page description
				</InputWithLabel>
				<InputWithLabel
					value={page.seo.en.keywords}
					onChange={(e) => handlePageSEOCustomization(page._id, "keywords", e.target.value)}
				>
					Meta keywords
				</InputWithLabel>
			</div>
		</div>
	);
};
