import React, { useState, useEffect } from "react";

// third party
import { XAxis, YAxis, CartesianGrid, Bar, BarChart, LabelList, ResponsiveContainer, Cell } from "recharts";
import moment from "moment";

// constant
import { PRESET_TYPES } from "../_commons/DateCompareFilter";

export const Frequency = ({ data = {}, loading = false, error = false, appliedFilter = {} }) => {
	const [ratingFrequency, setRatingFrequency] = useState({});

	let isCustomDateRange = false;
	const currentDates = appliedFilter.dateFilter.split(",");
	let currentStart = "";
	let currentEnd = "";
	let presetDateFilterLabel = "";
	if (currentDates.length === 2) {
		isCustomDateRange = true;
		currentStart = moment(currentDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		currentEnd = moment(currentDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	} else {
		presetDateFilterLabel = PRESET_TYPES.find((pt) => pt.value === appliedFilter.dateFilter).label;
	}

	useEffect(() => {
		if (!Array.isArray(data)) {
			setRatingFrequency(
				data.current &&
					data.current
						.slice()
						.reverse()
						.map((obj, i) => {
							return {
								name: "rating",
								displayName: obj["rating"],
								displayValue: obj["value"],
								labelValue: obj["value"]
							};
						})
			);
		}
	}, [data]);

	const renderCustomizedLabel = (props) => {
		const { x, y, width, height, value } = props;
		return (
			<g>
				<text x={x + width + height / 20} y={y + height / 2} fill="#000" dominantBaseline="middle">
					<tspan fontSize={13}> {value}</tspan>
				</text>
			</g>
		);
	};

	return (
		<div className="frequency">
			<div className="sub-title">
				<div className="highlight">Frequency</div>
			</div>
			{ratingFrequency.length > 0 && !error && !loading ? (
				<div className="rating-frequency-chart">
					<ResponsiveContainer width="95%" height={450}>
						<BarChart width={800} height={450} data={ratingFrequency} layout="vertical">
							<CartesianGrid horizontal={false} strokeDasharray="3 2" />
							<XAxis
								type="number"
								axisLine={false}
								tickLine={false}
								allowDecimals={false}
								tick={{ stroke: "#7B8A9A", strokeWidth: 0.1, fontSize: 12 }}
							/>
							<YAxis
								type="category"
								strokeDasharray="3 2"
								tick={{ stroke: "#7B8A9A", strokeWidth: 0.1, fontSize: 12 }}
								dataKey="displayName"
								width={60}
							/>
							<defs>
								<linearGradient id="rating" x1="0" y1="100%" x2="100%" y2="0" spreadMethod="reflect">
									<stop offset="25%" stopColor="#8884d8" stopOpacity={0.7} />
									<stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
								</linearGradient>
								{data.current &&
									data.current
										.slice()
										.reverse()
										.map((obj, i) => (
											<linearGradient
												id={`color${i}`}
												x1="0"
												y1="100%"
												x2="100%"
												y2="0"
												spreadMethod="reflect"
											>
												<stop
													offset="25%"
													stopColor={`hsl(${110 - i * 10}, 100%, 45%)`}
													stopOpacity={0.5}
												/>
												<stop
													offset="95%"
													stopColor={`hsl(${110 - i * 10}, 100%, 45%)`}
													stopOpacity={0.75}
												/>
											</linearGradient>
										))}
							</defs>
							<Bar
								name={isCustomDateRange ? currentStart + " to " + currentEnd : presetDateFilterLabel}
								dataKey="displayValue"
								fill="url(#rating)"
								maxBarSize={30}
								animationBegin={400}
								animationDuration={400}
								animationEasing="linear"
							>
								{data.current &&
									data.current
										.slice()
										.reverse()
										.map((obj, i) => <Cell key={`cell-${i}`} fill={`url(#color${i})`} />)}
								<LabelList dataKey="labelValue" content={renderCustomizedLabel} />
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			) : loading ? (
				<FrequencyBlockPlaceHolder />
			) : (
				<div className="no-items-placeholder error">
					An error occurred while fetching the data, Please reload.
				</div>
			)}
		</div>
	);
};

export const FrequencyBlockPlaceHolder = () => {
	return (
		<div className="frequency-block-container">
			<div className="shimmer H(120px)" />
		</div>
	);
};
