import React from "react";
import { connect } from "react-redux";
import { getPlatformDetailViaName } from "../../../helpers/periscope";

const TableHeaderDisplay = ({ index, table, textRight = false, periscopeItemAvailabilityState, configItems }) => {
	const sortingData = periscopeItemAvailabilityState?.sort;
	let sortedPlatformName = "";
	let orderStatus = "";
	const bizPlatforms = configItems.bizPlatforms?.items;

	if (sortingData) {
		sortedPlatformName = sortingData.field;
		orderStatus = sortingData.order;
	}
	const topImage = <img src="/assets/periscope/top-face.svg" />;
	const bottomFacedImage = <img src="/assets/periscope/down-face.svg" />;
	const topBlueImage = <img src="/assets/periscope/topBlue.svg" />;
	const bottomFacedBlueImage = <img src="/assets/periscope/bottomFacedBlue.svg" />;

	const currentPlatform = getPlatformDetailViaName(table, bizPlatforms);
	const platformName = currentPlatform?.platformName;
	const platformImage = currentPlatform?.image;
	return (
		<div
			className="table-heading-platforms"
			style={{ display: "flex", margin: "16px", ...(textRight ? { justifyContent: "right" } : {}) }}
		>
			<span>{platformImage ? <img src={platformImage} className="icon-platforms" /> : ""}</span>
			<span className="display-platform header-text-location ">{platformName}</span>
			<span style={{ display: "flex" }} className="sort-arrows-location">
				{sortedPlatformName === table ? (
					orderStatus === "asc" ? (
						<>
							{topBlueImage}
							{bottomFacedImage}
						</>
					) : orderStatus === "desc" ? (
						<>
							{topImage}

							{bottomFacedBlueImage}
						</>
					) : (
						<>
							{topImage}
							{bottomFacedImage}
						</>
					)
				) : (
					<>
						{topImage}
						{bottomFacedImage}
					</>
				)}
			</span>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeItemAvailabilityState: state.periscopeItemAvailabilityState,
	configItems: state.configItems
});

export default connect(mapStateToProps)(TableHeaderDisplay);
