// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// graphql
import { GET_AGGREGATOR_TEST_ORDER_CONFIG, TRIGGER_AGGREGATOR_TEST_ORDER } from "../graphql/developerTools";

const sanitiseFetchedData = (data) => {
	let sanitisedData = { ...data };
	sanitisedData.keys = [
		...sanitisedData.keys.map((value) =>
			value.type === "list"
				? {
						...value,
						selectedValue: typeof value.selectedValue === "string" ? JSON.parse(value.selectedValue) : null,
						value: typeof value.value === "string" ? JSON.parse(value.value) : null
				  }
				: value
		)
	];
	return { ...sanitisedData };
};

export const fetchAggregatorTestOrderConfig = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_AGGREGATOR_TEST_CONFIG_REQUEST
	});
	try {
		const resp = await client.query({
			query: GET_AGGREGATOR_TEST_ORDER_CONFIG,
			fetchPolicy: "no-cache"
		});
		if (resp.data.aggregatorTestOrderConfig) {
			store.dispatch({
				type: ActionTypes.GET_AGGREGATOR_TEST_CONFIG_SUCCESS,
				payload: sanitiseFetchedData(resp.data.aggregatorTestOrderConfig)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_AGGREGATOR_TEST_CONFIG_FAILURE,
			error: {
				message: "There was an error while fetching test configurations."
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const triggerTestRequest = async (config) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.TRIGGER_AGGREGATOR_TEST_CONFIG_REQUEST
	});
	try {
		const storeId = config?.selectedLocation?.id || null;
		const fixedDiscount = config.keys.find((target) => target.key === "fixed_discount")?.value || 0;
		const packagingCharge = Number(config.keys.find((target) => target.key === "packaging_charge")?.value) || 0;
		const hasAddonValue = config.keys.find((target) => target.key === "has_addon")?.value || null;
		const hasAddon = hasAddonValue === "True" || hasAddonValue === true || false;
		const hasNestedModifierValue =
			config.keys.find((target) => target.key === "has_nested_modifier")?.value || null;
		const hasNestedModifier = hasNestedModifierValue === "True" || hasNestedModifierValue === true || false;
		const storePlatformId = config?.platformSelected?.externalId
			? String(config?.platformSelected?.externalId)
			: "";
		const channel = config.platformSelected?.platformName?.toLowerCase();
		const addOnQuantity = Number(config.keys.find((target) => target.key === "addon_quantity")?.value || 0) || 0;
		const lineItemCount = Number(config.keys.find((target) => target.key === "line_item_count")?.value) || 0;
		const itemQuantity = Number(config.keys.find((target) => target.key === "item_quantity")?.value || 0) || 0;
		const addonCount = Number(config.keys.find((target) => target.key === "addon_count")?.value || 0) || 0;
		const deliveryCharge = Number(config.keys.find((target) => target.key === "delivery_charge")?.value || 0) || 0;
		const serviceCharge = Number(config.keys.find((target) => target.key === "service_charge")?.value || 0) || 0;
		const surcharge = Number(config.keys.find((target) => target.key === "surcharge")?.value || 0) || 0;
		const driverTip = Number(config.keys.find((target) => target.key === "driver_tip")?.value || 0) || 0;
		const fulfillmentType =
			config.keys.find((target) => target.key === "fulfillment_type")?.selectedValue?.valueKey || null;
		const deliveryType =
			config.keys.find((target) => target.key === "delivery_type")?.selectedValue?.valueKey || null;
		const isFutureOrderValue = config.keys.find((target) => target.key === "is_future_order")?.value || null;
		const isFutureOrder = isFutureOrderValue === "True" || isFutureOrderValue === true || false;
		const hasVariantValue = config.keys.find((target) => target.key === "has_variant")?.value;
		const hasVariant = hasVariantValue === "True" || hasVariantValue === true || false;
		const paymentType =
			config.keys.find((target) => target.key === "payment_type")?.selectedValue?.valueKey || null;
		const discountType =
			config.keys.find((target) => target.key === "discount_type" || target.key === "Discount Type")
				?.selectedValue?.valueKey || null;
		const orderInstructions = config.keys.find((target) => target.key === "order_instructions")?.value || "";

		const variables = {
			bizId: parseInt(store.getState().login.loggedInbizDetail.id),
			storeId,
			fixedDiscount,
			packagingCharge,
			hasAddon,
			hasNestedModifier,
			addOnQuantity,
			storePlatformId,
			channel,
			lineItemCount,
			itemQuantity,
			addonCount,
			deliveryCharge,
			serviceCharge,
			surcharge,
			driverTip,
			fulfillmentType,
			deliveryType,
			isFutureOrder,
			hasVariant,
			paymentType,
			discountType,
			orderInstructions
		};
		if (config.selectedBrand?.id) {
			variables.brandId = config.selectedBrand?.id;
		}
		const resp = await client.mutate({
			mutation: TRIGGER_AGGREGATOR_TEST_ORDER,
			fetchPolicy: "no-cache",
			variables
		});

		if (resp?.data?.placeAggregatorTestOrder?.status?.success) {
			store.dispatch({
				type: ActionTypes.TRIGGER_AGGREGATOR_TEST_CONFIG_SUCCESS
			});
		} else {
			store.dispatch({
				type: ActionTypes.TRIGGER_AGGREGATOR_TEST_CONFIG_FAILURE,
				payload: {
					error: true,
					message:
						resp?.data?.placeAggregatorTestOrder?.status?.messages[0]?.message || "Something went wrong",
					success: false
				}
			});
		}
	} catch (error) {
		store.dispatch({
			type: ActionTypes.TRIGGER_AGGREGATOR_TEST_CONFIG_FAILURE,
			error: {
				message: "There was an error while triggerring test requests."
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
