import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/WebhookEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";

// actions
import { fetchWebhooksList } from "../actions/webhooks";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_WEBHOOK, WEBHOOK_EVENT_TYPES, WEBHOOK_RETRY_MODES } from "../graphql/webhooks";

const WebhookCreate = ({ hasAccess = false }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [webhookEventTypes, setWebhookEventTypes] = useState({
		loading: false,
		data: []
	});
	const [webhookRetryModes, setWebhookRetryModes] = useState({
		loading: false,
		data: []
	});
	const [data, setData] = useState({
		url: "",
		eventType: null,
		retry: false,
		retriesMode: null,
		headers: {}
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchWebhookEventTypes();
		fetchWebhookRetryModes();
	}, []);

	const handleCancel = () => {
		setFormState(false);
		fetchWebhooksList();
		setTimeout(() => {
			history.push("/webhooks");
		}, 100);
	};

	const fetchWebhookEventTypes = async () => {
		try {
			setWebhookEventTypes({
				...webhookEventTypes,
				loading: true
			});
			const resp = await client.query({
				query: WEBHOOK_EVENT_TYPES,
				fetchPolicy: "no-cache"
			});
			setWebhookEventTypes({
				loading: false,
				data: resp.data.webhookEventTypes
			});
		} catch (error) {
			console.log(error);
			setWebhookEventTypes({
				...webhookEventTypes,
				loading: false
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const fetchWebhookRetryModes = async () => {
		try {
			setWebhookRetryModes({
				...webhookRetryModes,
				loading: true
			});
			const resp = await client.query({
				query: WEBHOOK_RETRY_MODES,
				fetchPolicy: "no-cache"
			});
			setWebhookRetryModes({
				loading: false,
				data: resp.data.webhookRetryModes.filter((wrm) => wrm.value !== "NONE")
			});
		} catch (error) {
			console.log(error);
			setWebhookRetryModes({
				...webhookRetryModes,
				loading: false
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleForm = (formData) => {
		setData({
			...data,
			...formData
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			if (variables.eventType) {
				variables.eventType = variables.eventType.value;
			}
			if (variables.retry && variables.retriesMode) {
				variables.retriesMode = variables.retriesMode.value;
			} else {
				variables.retriesMode = "NONE";
			}
			variables.headers = JSON.stringify(variables.headers);
			const resp = await client.mutate({
				mutation: CREATE_WEBHOOK,
				variables
			});
			if (resp.data.saveWebhook.status.success) {
				setConfirmLoading(false);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Webhook created",
						timeout: 5000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_WEBHOOKS_LIST,
					payload: resp.data.saveWebhook.object
				});
				history.push(`/webhooks/edit/${resp.data.saveWebhook.object.id}`);
			} else {
				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveWebhook.status.messages));
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Webhook"
			subTitle="Create a new webhook"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<BasicInfo
					data={data}
					handleForm={handleForm}
					validations={error.fields || {}}
					webhookEventTypes={webhookEventTypes}
					webhookRetryModes={webhookRetryModes}
					readOnly={!hasAccess}
				/>
			</div>
		</FormSidebar>
	);
};
export default WebhookCreate;
