import React from "react";

// components
import { OutsideClick } from "./OutsideClick";

// assets
import TripleDotIcon from "../_icons/TripleDotIcon";
import { Button } from "./Button";

const ContextMenu = ({
	isOpen = false,
	data,
	handleOpenMenu,
	disableContextMenu = false,
	renderMenuItems,
	nodeRef,
	appliedInlineStyles = {},
	children,
	classes = "",
	rest
}) => {
	return (
		<div
			style={{ ...(appliedInlineStyles ?? {}) }}
			className={
				"context-menu context-menu-button " +
				(isOpen ? "open " : "") +
				(disableContextMenu ? "disabled " : "") +
				(children ? "custom " : "") +
				classes
			}
			ref={nodeRef}
		>
			<Button type="secondary" onClick={handleOpenMenu}>
				{children || <TripleDotIcon />}
			</Button>
			<div className="menu">{isOpen ? renderMenuItems(data, rest) : null}</div>
		</div>
	);
};
export default OutsideClick(ContextMenu);
