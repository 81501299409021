import gql from "graphql-tag";

export const GET_LOCATIONS_LIST = gql`
	query getLocationsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$platform: Int
		$brand: String
	) {
		stores(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			search: $search
			platform: $platform
			brand: $brand
		) {
			limit
			offset
			count
			searchKeywords {
				key
				valueForDisplay
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				bizLocationId
				title
				merchantRefId
				name
				city
				ilaCount
				associatedBrandCount
				tags
				isActive
				sortOrder
				locationPlatforms {
					id
					platformName
					logo
					state
				}
				associatedBrandsData {
					id
					name
					image
				}
				brandLocation {
					id
					merchantRefId
				}
			}
		}
	}
`;

export const GET_PUBLISH_FAILURE_LOCATIONS_LIST = gql`
	query getPublishFailureLocationsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$platforms: [Int]
	) {
		publishFailureStores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, platforms: $platforms) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				bizLocationId
				title
				merchantRefId
				address
				latitude
				longitude
				city
				associatedItemsCount
				tags
				itemTags
				associatedPlatforms {
					id
					state
					outletUrl
					externalId
					platform {
						id
						platformName
					}
				}
			}
		}
	}
`;

// generic query to get biz platforms, filters define if we get inventory or online ordering biz platforms
export const GET_BIZ_PLATFORMS = gql`
	query getBizPlatforms(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$includeUrbanpiper: Boolean
	) {
		bizPlatforms(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			includeUrbanpiper: $includeUrbanpiper
		) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				platformName
				logo
				isEnabled
				isItemAssociated
				featureFlags {
					name
					value
				}
			}
		}
	}
`;

// gets both inventory and online ordering biz platforms in single query
export const GET_INVENTORY_AND_ONLINE_ORDERING_BIZ_PLATFORMS = gql`
	query getInventoryAndOnlineOrderingBizPlatforms(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$includeUrbanpiper: Boolean
	) {
		onlineOrderingBizPlatforms: bizPlatforms(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			includeUrbanpiper: $includeUrbanpiper
		) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				platformName
				logo
				isEnabled
				isItemAssociated
				platform {
					id
					name
				}
			}
		}
		inventoryBizPlatforms: bizPlatforms(
			limit: $limit
			offset: $offset
			filters: [{ field: "platform__kind", value: "inventory" }]
			sort: $sort
			includeUrbanpiper: $includeUrbanpiper
		) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				platformName
				logo
				isEnabled
				isItemAssociated
				platform {
					name
					id
				}
			}
		}
	}
`;

export const GET_BIZ_PLATFORMS_MINI = gql`
	query getBizPlatforms($limit: Int, $offset: Int, $filters: [ListFilterArgument], $includeUrbanpiper: Boolean) {
		bizPlatforms(limit: $limit, offset: $offset, filters: $filters, includeUrbanpiper: $includeUrbanpiper) {
			objects {
				id
				platformName
				platform {
					name
					id
				}
				featureFlags {
					name
					value
				}
				logo
			}
		}
	}
`;

export const GET_BIZ_PLATFORM_STATUS = gql`
	query fetchCities($id: Int, $filters: [ListFilterArgument]) {
		bizPlatform(id: $id) {
			id
			platformName
			locationCounts(filters: $filters) {
				notLive
				enabled
				disabled
				disconnected
			}
		}
	}
`;

export const GET_PRIME_VERIFICATION_STATUS = gql`
	query getPrismVerificationStatus($locationIds: [Int]!, $platformName: String!) {
		prismVerificationStatus(locationIds: $locationIds, platformName: $platformName) {
			status
			bizId
			bizLocationId
		}
	}
`;

export const GET_LOCATION = gql`
	query getLocation($id: Int) {
		store(id: $id) {
			id
			name
			merchantRefId
			address
			latitude
			longitude
			orderDeliveryRadius
			city
			associatedItemsCount
			tags
			sortOrder
			contactPhone
			gstin
			minOrderTotal
			minimumOrderDeliveryTime
			minimumOrderPickupTime
			isActive
			primaryContactPhone
			primaryContactEmail
			hideNameForUi
			isEnabledForOrdering
			orderingDisabledTemporary
			description
			seoDescription
			orderPrepTime
			busyOrderPrepTime
			excludedPlatforms {
				id
				platformName
			}
			fulfillmentModesList {
				id
				name
				nameForDisplay
			}
			paymentModesList {
				id
				name
				nameForDisplay
			}
			polygons {
				id
				coordinates
				deliveryCharge
			}
			otherPolygons {
				id
				coordinates
			}
			orderingSlots {
				id
				title
			}
			associatedPlatforms {
				id
				platform {
					id
					platformName
					logo
					featureFlags {
						name
						value
					}
				}
				state
				outletUrl
				externalId
				testId
			}
			holidayHours {
				objects {
					id
					title
					date
				}
			}
			nearByLocations {
				id
				name
				city
				latitude
				longitude
			}
			brandLocations {
				id
				name
				description
				seoDescription
				merchantRefId
				orderingSlots {
					id
					title
				}
				holidayHours {
					objects {
						id
						title
						date
					}
				}
				isActive
				associatedPlatforms {
					id
					platform {
						id
						platformName
						logo
						featureFlags {
							name
							value
						}
					}
					state
					outletUrl
					externalId
					testId
				}
				excludedPlatforms {
					id
					platformName
				}
				brand {
					id
					name
					image
				}
			}
		}
	}
`;

export const GET_LOCATION_BRANDS_LIST = gql`
	query getLocationBrandsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$locationId: Int
	) {
		brandLocations(limit: $limit, offset: $offset, filters: $filters, sort: $sort, locationId: $locationId) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				name
				associatedItemsCount
				ilaCount
				associatedPlatforms {
					id
					platformName
					state
					platform {
						logo
						featureFlags {
							name
							value
						}
					}
				}
				brand {
					id
					name
					image
				}
			}
		}
	}
`;

export const GET_NON_ASSOCIATED_BRANDS_LIST = gql`
	query getNonAssociatedBrandsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$locationId: Int!
	) {
		nonAssociatedBrands(limit: $limit, offset: $offset, filters: $filters, sort: $sort, locationId: $locationId) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				name
				image
			}
		}
	}
`;

export const ASSOCIATE_BRANDS_LOCATIONS = gql`
	mutation associateBrands($brandIds: [Int]!, $locationId: Int!) {
		associateBrands(input: { brandIds: $brandIds, locationId: $locationId }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const REMOVE_BRANDS_LOCATIONS = gql`
	mutation removeBrandsLocations($isActive: Boolean!, $brandIds: [Int], $locationIds: [Int]) {
		removeBrandsLocations(input: { isActive: $isActive, brandIds: $brandIds, locationIds: $locationIds }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_LPA_LOGS = gql`
	query getLpaLogs($platform: Int!, $locations: [Int]!, $action: String, $brand: String) {
		lpaLogs(platform: $platform, locations: $locations, action: $action, brand: $brand) {
			id
			created
			updated
			action
			finalStatus
			result
			location
			locationPlatform {
				id
				platformName
				outletUrl
				externalId
				state
			}
			verificationStatus
			brand {
				id
				name
				image
			}
		}
	}
`;

export const CREATE_LOCATION = gql`
	mutation createLocation(
		$bizLocation: [CreateBrandLocationBizLocationInput]
		$copyCatalogue: CreateLocationCopyCatalogueInput
	) {
		createBrandLocations(input: { bizLocation: $bizLocation, copyCatalogue: $copyCatalogue }) {
			referenceId
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const EDIT_LOCATION = gql`
	mutation editStore(
		$id: Int
		$bizLocationNickname: String
		$city: String
		$merchantBizLocationId: String
		$bizAddress: String
		$sortOrder: Int
		$fulfillmentModes: [Int]
		$contactPhone: String
		$gstin: String
		$tags: [String]
		$excludedPlatforms: [String]
		$isActive: Boolean
		$locationGroups: [Int]
		$description: String
		$seoDescription: String
		$orderPrepTime: Int
		$busyOrderPrepTime: Int
		$brandLocations: [BrandLocationsInput]
		$holidayHourIds: [Int]
	) {
		store(
			input: {
				id: $id
				bizLocationNickname: $bizLocationNickname
				city: $city
				merchantBizLocationId: $merchantBizLocationId
				bizAddress: $bizAddress
				sortOrder: $sortOrder
				contactPhone: $contactPhone
				gstin: $gstin
				fulfillmentModes: $fulfillmentModes
				tags: $tags
				excludedPlatforms: $excludedPlatforms
				isActive: $isActive
				locationGroups: $locationGroups
				description: $description
				seoDescription: $seoDescription
				orderPrepTime: $orderPrepTime
				busyOrderPrepTime: $busyOrderPrepTime
				brandLocations: $brandLocations
				holidayHourIds: $holidayHourIds
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				bizLocationId
				title
				merchantRefId
				name
				city
				ilaCount
				associatedBrandCount
				tags
				isActive
				sortOrder
				locationPlatforms {
					id
					platformName
					state
				}
				associatedBrandsData {
					id
					name
					image
				}
				brandLocation {
					id
					merchantRefId
				}
			}
		}
	}
`;
export const EDIT_LOCATION_SCHEDULES = gql`
	mutation editStoreSchedules($id: Int, $orderingSlots: Int, $holidayHourIds: [Int]) {
		store(input: { id: $id, orderingSlots: $orderingSlots, holidayHourIds: $holidayHourIds }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				bizLocationId
				title
				merchantRefId
				name
				city
				ilaCount
				associatedBrandCount
				tags
				isActive
				sortOrder
				locationPlatforms {
					id
					platformName
					state
				}
				associatedBrandsData {
					id
					name
					image
				}
				orderingSlots {
					id
					title
				}
				brandLocation {
					id
					merchantRefId
					orderingSlots {
						id
						title
					}
				}
			}
		}
	}
`;

export const EDIT_LOCATION_SCHEDULES_MULTI = gql`
	mutation editStoreSchedules(
		$id: Int
		$brandLocations: [BrandLocationsInput]
		$orderingSlots: Int
		$holidayHourIds: [Int]
	) {
		store(
			input: {
				id: $id
				brandLocations: $brandLocations
				orderingSlots: $orderingSlots
				holidayHourIds: $holidayHourIds
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				bizLocationId
				title
				merchantRefId
				name
				city
				ilaCount
				associatedBrandCount
				tags
				isActive
				sortOrder
				locationPlatforms {
					id
					platformName
					state
				}
				associatedBrandsData {
					id
					name
					image
				}
				orderingSlots {
					id
					title
				}
				brandLocation {
					id
					merchantRefId
					orderingSlots {
						id
						title
					}
				}
			}
		}
	}
`;

export const EDIT_LOCATION_MERAKI = gql`
	mutation editStoreMeraki(
		$id: Int
		$merchantBizLocationId: String
		$orderingSlots: Int
		$primaryContactPhone: String
		$primaryContactEmail: String
		$hideNameForUi: Boolean
		$isEnabledForOrdering: Boolean
		$orderingDisabledTemporary: Boolean
		$paymentModes: [Int]
		$isActive: Boolean
		$orderPrepTime: Int
		$busyOrderPrepTime: Int
		$holidayHourIds: [Int]
	) {
		store(
			input: {
				id: $id
				merchantBizLocationId: $merchantBizLocationId
				orderingSlots: $orderingSlots
				primaryContactPhone: $primaryContactPhone
				primaryContactEmail: $primaryContactEmail
				hideNameForUi: $hideNameForUi
				isEnabledForOrdering: $isEnabledForOrdering
				orderingDisabledTemporary: $orderingDisabledTemporary
				paymentModes: $paymentModes
				isActive: $isActive
				orderPrepTime: $orderPrepTime
				busyOrderPrepTime: $busyOrderPrepTime
				holidayHourIds: $holidayHourIds
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				bizLocationId
				title
				merchantRefId
				name
				city
				ilaCount
				associatedBrandCount
				tags
				isActive
				sortOrder
				locationPlatforms {
					id
					platformName
					state
				}
				associatedBrandsData {
					id
					name
					image
				}
				brandLocation {
					id
					merchantRefId
				}
			}
		}
	}
`;

export const EDIT_LOCATION_SELF_DELIVERY = gql`
	mutation editStoreSelfDelivery(
		$id: Int
		$merchantBizLocationId: String
		$minOrderTotal: Float
		$minimumOrderPickupTime: Int
		$minimumOrderDeliveryTime: Int
		$orderDeliveryRadius: Int
		$locLatitude: Float
		$locLongitude: Float
		$polygons: [SinglePolygonInput]
		$isActive: Boolean
	) {
		store(
			input: {
				id: $id
				merchantBizLocationId: $merchantBizLocationId
				minOrderTotal: $minOrderTotal
				minimumOrderPickupTime: $minimumOrderPickupTime
				minimumOrderDeliveryTime: $minimumOrderDeliveryTime
				orderDeliveryRadius: $orderDeliveryRadius
				locLatitude: $locLatitude
				locLongitude: $locLongitude
				polygons: $polygons
				isActive: $isActive
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				bizLocationId
				title
				merchantRefId
				name
				city
				ilaCount
				associatedBrandCount
				tags
				isActive
				sortOrder
				locationPlatforms {
					id
					platformName
					state
				}
				associatedBrandsData {
					id
					name
					image
				}
				brandLocation {
					id
					merchantRefId
				}
			}
		}
	}
`;

export const EDIT_LOCATION_PLATFORM = gql`
	mutation saveLocationPlatform($id: Int!, $externalId: String, $outletUrl: String, $testId: String) {
		saveLocationPlatform(input: { id: $id, externalId: $externalId, outletUrl: $outletUrl, testId: $testId }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
			}
		}
	}
`;

export const GET_LOCATION_CATEGORIES_LIST = gql`
	query getLocationCategoriesList(
		$id: Int
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
	) {
		store(id: $id) {
			id
			name
			categories(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				limit
				offset
				count
				objects {
					id
					name
					description
				}
			}
		}
	}
`;

export const GET_LOCATION_LOCATION_GROUPS = gql`
	query getLocationGroups(
		$id: Int
		$brandId: Int
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
	) {
		store(id: $id, brandId: $brandId) {
			locationGroups(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				objects {
					id
					title
					numAssociatedLocations
				}
			}
		}
	}
`;

export const GET_LOCATION_CATALOGUE = gql`
	query getLocationCatalogue(
		$locations: [Int]
		$platforms: [Int]
		$brandId: Int
		$available: Boolean
		$filters: [ListFilterArgument]
	) {
		locationCatalogue(
			locations: $locations
			platforms: $platforms
			brandId: $brandId
			available: $available
			filters: $filters
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			categories {
				name
				sortOrder
				items {
					id
					itemTitle
					itemDesc
					imageUrl
					foodType
					itemPrice
					markupPrice
					isRecommended
					available
					locationPlatforms {
						id
						ilpaId
						platformName
						isEnabled
						isItemAssociated
					}
				}
				subcategories {
					name
					sortOrder
					items {
						id
						itemTitle
						itemDesc
						imageUrl
						foodType
						itemPrice
						markupPrice
						isRecommended
						available
						locationPlatforms {
							id
							ilpaId
							platformName
							isEnabled
							isItemAssociated
						}
					}
					subcategories {
						name
						sortOrder
						items {
							id
							itemTitle
							itemDesc
							imageUrl
							foodType
							itemPrice
							markupPrice
							isRecommended
							available
							locationPlatforms {
								id
								ilpaId
								platformName
								isEnabled
								isItemAssociated
							}
						}
					}
				}
			}
			items {
				id
				itemTitle
				itemDesc
				imageUrl
				foodType
				itemPrice
				markupPrice
				isRecommended
				available
				locationPlatforms {
					id
					ilpaId
					platformName
					isEnabled
					isItemAssociated
				}
			}
		}
	}
`;

export const GET_LOCATION_PUBLISH_LOGS = gql`
	query getLocationPublishLogs(
		$id: Int
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		store(id: $id) {
			id
			publishLogs(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
				count
				objects {
					id
					platformName
					action
					src
					finalStatus
					updated
					result
					user {
						username
					}
				}
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						value
						valueForDisplay
					}
				}
			}
		}
	}
`;

export const UPDATE_LOCATION_ITEMS = gql`
	mutation updateLocationItems(
		$locationIds: [Int]!
		$itemsToAssociate: [Int]
		$itemsToDisassociate: [Int]
		$associateAll: Boolean
		$disassociateAll: Boolean
		$filters: [ListFilterArgument]
		$brand: String
	) {
		updateLocationItems(
			input: {
				locationIds: $locationIds
				itemsToAssociate: $itemsToAssociate
				itemsToDisassociate: $itemsToDisassociate
				associateAll: $associateAll
				disassociateAll: $disassociateAll
				filters: $filters
				brand: $brand
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_LOCATION_MODIFIERS = gql`
	mutation updateLocationModifiers(
		$locationIds: [Int]!
		$optionsToAssociate: [Int]
		$optionsToDisassociate: [Int]
		$associateAll: Boolean
		$disassociateAll: Boolean
		$filters: [ListFilterArgument]
		$brand: String
	) {
		updateLocationModifiers(
			input: {
				locationIds: $locationIds
				optionsToAssociate: $optionsToAssociate
				optionsToDisassociate: $optionsToDisassociate
				associateAll: $associateAll
				disassociateAll: $disassociateAll
				filters: $filters
				brand: $brand
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const LOCATION_ACTION = gql`
	mutation locationAction(
		$locationIds: [Int]!
		$platformIds: [Int]!
		$brand: String
		$action: String
		$snoozeDatetime: String
	) {
		locationAction(
			locationIds: $locationIds
			platformIds: $platformIds
			brand: $brand
			action: $action
			snoozeDatetime: $snoozeDatetime
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_LOCATION_PLATFORMS_TURN_ON_TIMES = gql`
	query getLocationPlatformsTurnOnTimes($brandId: Int, $locationId: Int!, $platforms: [String]) {
		locationPlatformsTurnOnTimes(brandId: $brandId, locationId: $locationId, platforms: $platforms) {
			snoozedAt
			snoozedUntil
			platform
		}
	}
`;

export const GET_ITEM_LOCATION_DETAILS = gql`
	query getItemLocationPrice($itemId: Int, $locationId: Int, $filters: [ListFilterArgument]) {
		item(id: $itemId) {
			id
			itemTitle
			itemDesc
			imageUrl
			foodType
			associatedItemLocations(filters: $filters) {
				objects {
					id
					price
					externalPrice
					sortOrder
					currentStock
					itemRefId
					isRecommended
					associatedItemLocationPlatforms {
						id
						price
						bizPlatform {
							id
							platformName
						}
						actualPrice
					}
				}
			}
			optionGroups(limit: 100, offset: 0) {
				objects {
					id
					optionGroupTitle
					isVariant
					modifiers(locationId: $locationId) {
						id
						optionTitle
						priceAtLocation
						currentStockAtLocation
						availableAtLocation
					}
				}
			}
		}
	}
`;

export const GET_ILP_ACTION_LOGS = gql`
	query getIlpActionLogs($ilpaIds: [String]!, $limit: Int, $offset: Int) {
		itemLocationPlatformActionLogs(ilpaIds: $ilpaIds, limit: $limit, offset: $offset) {
			objects {
				ilpaId
				action
				src
				finalStatus
				created
				user {
					username
				}
			}
		}
	}
`;

export const COPY_CATALOGUE = gql`
	mutation copyCatalogue($fromLocation: Int!, $toLocation: Int, $brandId: Int) {
		copyCatalogue(input: { fromLocation: $fromLocation, toLocation: $toLocation, brandId: $brandId }) {
			toLocation
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const ARCHIVE_RESTORE_LOCATION = gql`
	mutation archiveRestoreLocation($locationIds: [Int]!, $brand: String, $isActive: Boolean!) {
		archiveRestoreLocations(locationIds: $locationIds, brand: $brand, isActive: $isActive) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const CREATE_LOCATION_WIZARD = gql`
	mutation createlocation(
		$bizLocation: [CreateLocationBizLocationInput]
		$copyCatalogue: CreateLocationCopyCatalogueInput
	) {
		createLocation(input: { bizLocation: $bizLocation, copyCatalogue: $copyCatalogue }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_LOCATION_PLATFORM_ASSOCIATIONS = gql`
	query locationPlatformAssociations($locationIds: [String]!, $brandIds: [String]) {
		locationPlatformAssociations(locationIds: $locationIds, brandIds: $brandIds) {
			id
			outletUrl
			externalId
			testId
			platformName
			platform {
				id
				platformName
				logo
			}
			location {
				id
				name
				brand {
					id
					name
					image
				}
			}
			state
			verificationStatus
		}
	}
`;

export const UPDATE_LOCATION_PLATFORM_ASSOCIATIONS = gql`
	mutation saveLocationsPlatform($data: [LocationPlatformNonReqAssociationInput]) {
		saveLocationPlatformAssociations(input: { data: $data }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const LOCATION_PLATFORM_TIMINGS_GROUP_STATUS = gql`
	query locationPlatformTimingsGroupStatus($locationId: Int, $brandId: Int) {
		locationPlatformTimingsGroupStatus(locationId: $locationId, brandId: $brandId) {
			objects {
				platform {
					id
					platformName
				}
				lastUpdated
				status
			}
		}
	}
`;

export const UPDATE_LOCATION_PLATFORM_TIMINGS_GROUP_STATUS = gql`
	mutation updateLocationTimingsGroup($locationId: Int!, $platformIds: [Int]!, $orderingSlot: Int!, $brandId: Int) {
		updateLocationTimingsGroup(
			input: {
				locationId: $locationId
				platformIds: $platformIds
				orderingSlot: $orderingSlot
				brandId: $brandId
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				bizLocationId
			}
		}
	}
`;
export const UPDATE_LOCATION_PLATFORM_HOLIDAY_HOURS_STATUS = gql`
	mutation updateLocationHolidayHours($locationId: Int!, $platformIds: [Int]!, $holidayHours: [Int]!, $brandId: Int) {
		updateLocationHolidayHours(
			input: {
				locationId: $locationId
				platformIds: $platformIds
				holidayHours: $holidayHours
				brandId: $brandId
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				bizLocationId
			}
		}
	}
`;

export const VERIFY_CATALOGUE_REQUEST = gql`
	mutation saveLocationsPlatform($data: [LocationPlatformNonReqAssociationInput], $action: String) {
		saveLocationPlatformAssociations(input: { data: $data, action: $action }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_LOCATION_PLATFORM_ASSOCIATIONS_IDS = gql`
	query locationPlatformAssociations($locationIds: [String]!, $brandIds: [String]) {
		locationPlatformAssociations(locationIds: $locationIds, brandIds: $brandIds) {
			id
			platformName
			location {
				id
			}
		}
	}
`;

export const GET_LOCATION_MENUS_LIST = gql`
	query fetchLocationMenusList($id: Int!, $brandId: String) {
		location(id: $id, brandId: $brandId) {
			city
			id
			name
			publishedMenus {
				platforms
				publishedAt
				publishedBy
				menuDetails {
					menuId
					name
					imageUrl
					description
					metadata {
						itemCount
						categoryCount
					}
				}
			}
			scheduledPublishes {
				platforms
				publishedAt
				publishedBy
				menuDetails {
					menuId
					name
					imageUrl
					description
					metadata {
						itemCount
						categoryCount
					}
				}
			}
		}
	}
`;

export const GET_LOCATIONS_LIST_FOR_TIMING_GROUP = gql`
	query getLocationsListForTG(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$platform: Int
		$brand: String
	) {
		stores(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			search: $search
			platform: $platform
			brand: $brand
		) {
			limit
			offset
			count
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				bizLocationId
				title
				merchantRefId
				name
				city
				isActive
				sortOrder
			}
		}
	}
`;
export const GET_ITEMS_LOCATION_MENU_DETAILS = gql`
	query getItemsForLocationMenuDetails(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$locationId: Int
		$menuId: String
		$brand: String
		$platform: String
	) {
		items(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			search: $search
			locationId: $locationId
			menuId: $menuId
			brand: $brand
			platform: $platform
		) {
			count
			objects {
				id
				itemTitle
				itemPrice
				foodType
				available
				crmTitle
				imageUrl
			}
		}
	}
`;

export const GET_MODIFIERS_LOCATION_MENU_DETAILS = gql`
	query getModifiersForLocationMenuDetails(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$locationId: Int
		$menuId: String
		$brand: String
		$platform: String
	) {
		modifiers(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			search: $search
			locationId: $locationId
			menuId: $menuId
			brand: $brand
			platform: $platform
		) {
			count
			objects {
				id
				optionTitle
				price
				foodType
				availableAtLocation
				crmTitle
				platformImages {
					id
					image
				}
			}
		}
	}
`;
