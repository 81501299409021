import React from "react";

const EmptyImportantItemState = ({ setItemDropdown }) => {
	return (
		<div className="important-items ">
			<img src="/assets/periscope/painter-painting.svg" className="image-painter" />
			<div className="important-info">
				<span className="imp-items-data">No items marked important yet!</span>
				<span className="imp-description-info">
					Click on the
					<img src="/assets/periscope/brandLabel.svg" className="label-img" />
					icon in the table to mark an item as important.
				</span>
			</div>
			<div className="clickable-buttons">
				<button
					className="btn-all-items"
					onClick={() => {
						setItemDropdown("all");
					}}
				>
					View All items
				</button>
				<div className="end-tag">
					<img className="questions" src="/assets/periscope/any-questions.svg" />

					<span>Have questions?</span>
					<span className="learn-more-imp">Learn more about Important Items</span>
				</div>
			</div>
		</div>
	);
};
export default EmptyImportantItemState;
