import React, { useState } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { JsonInput } from "../_commons/JsonInput";
import { Textarea } from "../_commons/Textarea";

export const Configurations = ({
	index,
	config = {},
	configValue,
	handleForm,
	validations = {},
	selectedLocation,
	locationsList = [],
	handleLocationSearch,
	handleLocationSelection,
	platformsList,
	handlePlatformSelection,
	platformSelected,
	searchingLocation,
	isMultibrandEnabled = false
}) => {
	const setFilter = (field, value) => {
		handleForm(config.key, value);
	};

	return (
		<React.Fragment>
			{config.type === "location" && (
				<SelectFilter
					title="Location"
					options={locationsList}
					isLoading={searchingLocation}
					field="selectedLocation"
					currValue={selectedLocation}
					setFilter={handleLocationSelection}
					labelKey="title"
					valueKey="id"
					isClearable={false}
					isAsync={true}
					handleSearch={handleLocationSearch}
					validationMessage={validations[config.key]}
					requiredLabel={config?.required}
					tooltipInfo="A brand has to be selected to view associated locations."
					showCustomTooltip={isMultibrandEnabled}
				/>
			)}
			{config.type === "list" && (
				<SelectFilter
					title={config.title || ""}
					options={config?.value}
					valueKey="valueKey"
					labelKey="labelKey"
					currValue={config?.selectedValue}
					setFilter={setFilter}
					validationMessage={validations[config.key]}
					requiredLabel={config?.required}
				/>
			)}
			{config.type === "platform" && (
				<SelectFilter
					title="Platform"
					options={platformsList.values}
					valueKey="id"
					labelKey="platformName"
					currValue={platformSelected}
					setFilter={handlePlatformSelection}
					validationMessage={validations[config.key]}
					requiredLabel={config?.required}
					isLoading={platformsList.loading}
					tooltipInfo="A location has to be selected to view associated platforms."
					showCustomTooltip
				/>
			)}
			{(config.type === "boolean" || config.type === "bool") && (
				<Switch
					key={index}
					title={config.title}
					checked={configValue === "True" || configValue === true}
					clickHandler={() => handleForm(config.key, !(configValue === "True" || configValue === true))}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				/>
			)}
			{config.type === "char" && (
				<InputWithLabel
					key={index}
					value={configValue || ""}
					type={config.encrypted ? "password" : "text"}
					onChange={(e) => handleForm(config.key, e.target.value)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</InputWithLabel>
			)}
			{config.type === "float" && (
				<InputWithLabel
					key={index}
					type={config.encrypted ? "password" : "number"}
					value={config.value}
					onChange={(e) => handleForm(config.key, e.target.value ? Number(e.target.value) : null)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</InputWithLabel>
			)}
			{config.type === "int" && (
				<InputWithLabel
					key={index}
					type={config.encrypted ? "password" : "number"}
					value={config.value}
					onChange={(e) => handleForm(config.key, e.target.value ? parseInt(e.target.value) : null)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</InputWithLabel>
			)}
			{config.type === "dict" && (
				<JsonInput
					key={index}
					title={config.title}
					field={config.key}
					value={config.value || {}}
					setFilter={(field, value) => handleForm(field, value)}
					requiredLabel={config.required}
					collapsed={true}
					readOnly={config.readonly}
					displayDataTypes={true}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				/>
			)}
			{config.type === "text" && (
				<Textarea
					key={index}
					value={configValue || ""}
					onChange={(e) => handleForm(config.key, e.target.value)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</Textarea>
			)}
		</React.Fragment>
	);
};
