import React, { useEffect, useState } from "react";

// component
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import RangeSlider from "../_commons/RangeSlider";

const OrderPreparationTime = ({
	orderPrepTime,
	busyOrderPrepTime,
	isLoading,
	setFormTouched,
	isFormTouched,
	handlePrepTimesSubmit,
	updatingValues
}) => {
	const [prepTimes, setPrepTimes] = useState({
		orderPrepTime: orderPrepTime,
		busyOrderPrepTime: busyOrderPrepTime
	});

	const validationMessage = {
		busyOrderPrepTime:
			Math.floor(prepTimes?.orderPrepTime / 60) > 0 && prepTimes?.orderPrepTime >= prepTimes?.busyOrderPrepTime
				? "Busy preparation time cannot be lesser than or equal to normal preparation time"
				: ""
	};

	const handlePrepTimeChange = (field, value) => {
		setPrepTimes((current) => ({
			...current,
			[field]: value * 60
		}));
		setFormTouched(true);
	};

	const submitValues = () => {
		handlePrepTimesSubmit(prepTimes?.orderPrepTime, prepTimes?.busyOrderPrepTime);
	};

	const restorePreviousValues = () => {
		setPrepTimes((current) => ({
			...current,
			orderPrepTime: orderPrepTime,
			busyOrderPrepTime: busyOrderPrepTime
		}));
	};

	useEffect(() => {
		setPrepTimes((current) => ({
			...current,
			orderPrepTime: orderPrepTime,
			busyOrderPrepTime: busyOrderPrepTime
		}));
	}, [orderPrepTime, busyOrderPrepTime]);

	if (isLoading) {
		return (
			<div className="order-preparation-time card-container">
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}

	return (
		<div className="order-preparation-time card-container">
			<ActionInfoAndButtons
				isFormTouched={isFormTouched}
				restorePreviousValues={restorePreviousValues}
				submitValues={submitValues}
				updatingValues={updatingValues}
			/>
			<div className="prep-time-container">
				<PreparationTimeSelector
					header="Normal Preparation Time"
					min={0}
					max={90}
					value={Math.floor(prepTimes?.orderPrepTime / 60)}
					field="orderPrepTime"
					handlePrepTimeChange={handlePrepTimeChange}
					validationMessage={validationMessage}
					updatingValues={updatingValues}
				/>
				<PreparationTimeSelector
					header="Busy Preparation Time"
					min={0}
					max={180}
					value={Math.floor(prepTimes?.busyOrderPrepTime / 60)}
					field="busyOrderPrepTime"
					handlePrepTimeChange={handlePrepTimeChange}
					validationMessage={validationMessage}
					updatingValues={updatingValues}
				/>
			</div>
		</div>
	);
};
export default OrderPreparationTime;

const ActionInfoAndButtons = ({ isFormTouched, submitValues, restorePreviousValues, updatingValues }) => {
	return (
		<div className="preparation-time-actions">
			<div className="action-info">
				<img className="preparation-time-icon" src="/assets/icons/icon-fork-spoon.svg" />
				<div className="info-text-section">
					<div className="header-text">Food Preparation Time</div>
					<div className="body-text">
						Set normal and busy preparation time for all orders to automatically be marked as "Food Ready".
						This time might also be send to aggregators to help them manage their delivery fleet.
					</div>
				</div>
			</div>
			<div className="action-buttons">
				<Button
					classes={`${!isFormTouched ? "disabled" : ""}`}
					clickHandler={submitValues}
					loading={updatingValues}
				>
					Set
				</Button>
				<Button
					type="secondary"
					classes={`${!isFormTouched || updatingValues ? "disabled" : ""}`}
					clickHandler={restorePreviousValues}
				>
					Cancel
				</Button>
			</div>
		</div>
	);
};

const PreparationTimeSelector = ({
	header = "",
	min,
	max,
	value,
	field,
	handlePrepTimeChange,
	validationMessage = {},
	updatingValues
}) => {
	const handleInputFieldValueChange = (e) => {
		if (!e?.target?.value) {
			handlePrepTimeChange(field, 0);
			return;
		}
		handlePrepTimeChange(field, e.target.value);
	};
	return (
		<div className={`prep-time-selector ${updatingValues ? "disabled" : ""}`}>
			<div className="header">{header}</div>
			<InputWithLabel
				isValueUnitRequired
				valueUnit="Minutes"
				type="number"
				value={value}
				onChange={handleInputFieldValueChange}
				validationMessage={validationMessage?.[field]}
			>
				Enter time
			</InputWithLabel>
			<RangeSlider min={min} max={max} value={value} setValue={(value) => handlePrepTimeChange(field, value)} />
		</div>
	);
};
