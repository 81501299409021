import React, { useEffect, useState } from "react";

// third party
import { useSpring, animated as a } from "react-spring";

// components
import { CaptureLocation } from "../_commons/CaptureLocation";
import { CaptureLocationCircle } from "../_commons/CaptureLocationCircle";
import { CaptureLocationPolygon } from "../_commons/CaptureLocationPolygon";
import { Button } from "../_commons/Button";
import { RadioButton } from "../_commons/RadioButton";
import { trackEvent } from "../../atlas-utils";

export const MerakiDetails = ({
	locationLatLng,
	polygon,
	radius,
	handleSelectedTabUpdate,
	handleFormData,
	radiusMode,
	setRadiusMode,
	renderFromModal = false,
	locationCaptured,
	setLocationCaptured,
	showNext = false,
	multiLocationDetails,
	currentIndex,
	savePolygonsRef,
	platformsSelectedCount
}) => {
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const [locationDetails, setLocationDetails] = useState("");

	const handleLocUpdate = (location) => {
		if (renderFromModal) {
			let updatedMultiLocationDetails = [...multiLocationDetails];
			updatedMultiLocationDetails[currentIndex] = {
				...updatedMultiLocationDetails[currentIndex],
				locationLatLng: { lat: location.lat, lng: location.lng }
			};
			handleFormData("multiLocationDetails", updatedMultiLocationDetails);
		} else {
			handleFormData("locationLatLng", { lat: location.lat, lng: location.lng });
		}
	};

	const handlePolygonUpdate = (polygon) => {
		if (renderFromModal) {
			let updatedMultiLocationDetails = [...multiLocationDetails];
			updatedMultiLocationDetails[currentIndex] = {
				...updatedMultiLocationDetails[currentIndex],
				locationPolygon: polygon
			};
			handleFormData("multiLocationDetails", updatedMultiLocationDetails);
		} else {
			handleFormData("locationPolygon", polygon);
		}
	};

	const handleRadiusUpdate = (radius) => {
		if (renderFromModal) {
			let updatedMultiLocationDetails = [...multiLocationDetails];
			updatedMultiLocationDetails[currentIndex] = {
				...updatedMultiLocationDetails[currentIndex],
				locationRadius: radius
			};
			handleFormData("multiLocationDetails", updatedMultiLocationDetails);
		} else {
			handleFormData("locationRadius", radius);
		}
	};

	const handlePrevClick = () => {
		if (locationCaptured) {
			setLocationCaptured(false);
		} else {
			handleSelectedTabUpdate(platformsSelectedCount === 1 ? 5 : 6);
		}
	};

	const handleNextClick = () => {
		if (!locationCaptured) {
			setLocationCaptured(true);
		} else {
			if (!radiusMode) {
				savePolygonsRef.current.savePolygons();
			}
			handleSelectedTabUpdate(platformsSelectedCount === 1 ? 7 : 8);
			trackEvent("wiz_loc_meraki_details_entry", {});
		}
	};

	const handleRadioButtonClick = (mode) => {
		if (mode === "radius") {
			savePolygonsRef.current.savePolygons();
			setRadiusMode(true);
		} else {
			setRadiusMode(false);
		}
	};

	useEffect(() => {
		return () => {
			setRadiusMode(false);
			setLocationCaptured(false);
		};
	}, []);

	return (
		<a.div className="common-box-structure " style={contentProps}>
			{!renderFromModal && (
				<div className="header">
					{!locationCaptured ? "Drop a pin at your store location" : "Let's set your delivery area"}
				</div>
			)}
			<div className="details-entry">
				{!locationCaptured ? (
					<a.div className="input">
						<div className="label label-store-location">Drop a pin at your store location</div>
						<CaptureLocation
							latLong={locationLatLng}
							setLocation={handleLocUpdate}
							setLocationDetails={setLocationDetails}
							fromLocationCreateWizard={true}
						/>
					</a.div>
				) : (
					<a.div className="input input-map" style={contentProps}>
						<div className="label label-polygon">Draw delivery area</div>
						<div className="switch-mode">
							<div className="radio-div" onClick={() => handleRadioButtonClick("polygon")}>
								<RadioButton checked={!radiusMode}>Polygon</RadioButton>
							</div>
							<div className="radio-div" onClick={() => handleRadioButtonClick("radius")}>
								<RadioButton checked={radiusMode}>Radius</RadioButton>
							</div>
						</div>
						<div className="gmap-container-meraki">
							{radiusMode ? (
								<CaptureLocationCircle
									setRadius={handleRadiusUpdate}
									radius={radius}
									latLong={locationLatLng}
								/>
							) : (
								<CaptureLocationPolygon
									latLong={locationLatLng}
									setPolygons={handlePolygonUpdate}
									close={() => {}}
									locationName={locationDetails.locationName}
									city={locationDetails.city}
									fromLocationCreateWizard={true}
									presetPolygons={polygon}
									savePolygonsRef={savePolygonsRef}
								/>
							)}
						</div>
					</a.div>
				)}
			</div>
			{!renderFromModal && (
				<div className="button-div">
					<Button clickHandler={handlePrevClick} type="secondary">
						<span>Previous</span>
					</Button>
					<Button clickHandler={handleNextClick}>
						<span>Next </span>
					</Button>
				</div>
			)}
		</a.div>
	);
};
