import React, { useState } from "react";
import StackProgressBar from "../Dashboard/StackProgressBar";
import { connect } from "react-redux";
import { store } from "../../../store/configureStore";
import SkeletonLoader from "../SkeletonLoader";
import { roundAndFormatNumber } from "../../../helpers/periscope";

const ItemIssuesStackedbar = ({ issueTypeOptions, periscopeItemIssuesState, periscopeItemIssuesData }) => {
	const getItemsIssuesMetrics = periscopeItemIssuesData?.getItemsIssuesMetrics;
	const issueTypeValue = periscopeItemIssuesState?.appliedFilters?.issueType?.value || "all";
	const loading = getItemsIssuesMetrics?.loading;
	const error = getItemsIssuesMetrics?.error;
	const data = getItemsIssuesMetrics?.data;

	const itemStackProgressBarData = [
		{
			missingImageAndDescriptionPercentage: roundAndFormatNumber(data?.missingImageAndDescriptionPercentage, 2),
			missingImagePercentage: roundAndFormatNumber(data?.missingImagePercentage, 2),
			missingDescriptionPercentage: roundAndFormatNumber(data?.missingDescriptionPercentage, 2),
			noMissingDetailsPercentage: roundAndFormatNumber(data?.noMissingDetailsPercentage, 2)
		}
	];

	const progressBarKeys = [
		"missingImageAndDescriptionPercentage",
		"missingImagePercentage",
		"missingDescriptionPercentage",
		"noMissingDetailsPercentage"
	];

	const keysColorMapping = {
		missingImageAndDescriptionPercentage: "#B02B2B",
		missingImagePercentage: "#FF7557",
		missingDescriptionPercentage: "#FD9D53",
		noMissingDetailsPercentage: "#C5D1FC"
	};

	const changeIssueFilter = (value) => {
		if (value == issueTypeValue) {
			store.dispatch({
				type: "ITEM_ISSUES_FILTERS_CHANGE",
				payload: {
					issueType: null
				}
			});
		} else {
			if (value == "no_missing_details") {
				store.dispatch({
					type: "ITEM_ISSUES_FILTERS_CHANGE",
					payload: {
						issueType: {
							valueForDisplay: "No Missing Details",
							value: "no_missing_details"
						}
					}
				});
			} else {
				let index = issueTypeOptions.filter((issue) => {
					return issue.value == value;
				});
				store.dispatch({
					type: "ITEM_ISSUES_FILTERS_CHANGE",
					payload: { issueType: index[0] }
				});
				store.dispatch({
					type: "ITEM_ISSUES_STATE_FILTERS",
					payload: { offset: 0 }
				});
			}
		}
	};

	return (
		<div className="item-issues-stackedbar">
			<div className="item-issues-stackedbar-div">
				<div className="issues-stackedbar-firstdiv">
					<div className="item-issues-all-info">
						<div
							className={`item-issues-graph-info ${
								issueTypeValue == "missing_image_and_description" ? "issue-filter-selected" : ""
							} `}
							onClick={() => changeIssueFilter("missing_image_and_description")}
						>
							<span className="color-info item-issues-red"></span>
							<div className="issues-graph-display">
								<span className="issue-text-title">Missing image & description</span>
								<div
									className="issue-percentage-display"
									data-testid="missingImageAndDescriptionPercentage"
								>
									{loading || error ? (
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									) : (
										<>{itemStackProgressBarData[0].missingImageAndDescriptionPercentage}%</>
									)}
								</div>
							</div>
						</div>
						<div
							className={`item-issues-graph-info ${
								issueTypeValue == "missing_image" ? "issue-filter-selected" : ""
							} `}
							onClick={() => changeIssueFilter("missing_image")}
						>
							<span className="color-info item-issues-orange"></span>
							<div className="issues-graph-display">
								<span className="issue-text-title">Missing image</span>
								<div className="issue-percentage-display" data-testid="missingImagePercentage">
									{loading || error ? (
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									) : (
										<>{itemStackProgressBarData[0].missingImagePercentage}%</>
									)}
								</div>
							</div>
						</div>
						<div
							className={`item-issues-graph-info ${
								issueTypeValue == "missing_description" ? "issue-filter-selected" : ""
							} `}
							onClick={() => changeIssueFilter("missing_description")}
						>
							<span className="color-info item-issues-yellow"></span>
							<div className="issues-graph-display">
								<span className="issue-text-title">Missing description</span>
								<div className="issue-percentage-display">
									<div
										className="issue-percentage-display"
										data-testid="missingDescriptionPercentage"
									>
										{loading || error ? (
											<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
										) : (
											<>{itemStackProgressBarData[0].missingDescriptionPercentage}%</>
										)}
									</div>
								</div>
							</div>
						</div>
						<div
							className={`item-issues-graph-info ${
								issueTypeValue == "no_missing_details" ? "issue-filter-selected" : ""
							} `}
							onClick={() => changeIssueFilter("no_missing_details")}
						>
							<span className="color-info item-issues-blue"></span>
							<div className="issues-graph-display">
								<span className="issue-text-title">No Missing details</span>
								<div className="issue-percentage-display" data-testid="noMissingDetailsPercentage">
									{loading || error ? (
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									) : (
										<>{itemStackProgressBarData[0].noMissingDetailsPercentage}%</>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="total-issue-count">
						<span className="issue-text-title ">Total Items With Issues</span>
						{loading || error ? (
							<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
						) : (
							<span className="issue-total-items"> {data?.totalItemsWithIssues} </span>
						)}
					</div>
				</div>
				<div>
					{loading || error ? (
						<>
							<div style={{ margin: "0px 3px 0 10px", display: "flex" }}>
								<SkeletonLoader
									classname={` loading-stackedbar ${error == true ? "stop-moving" : ""}  `}
									style={{ width: "33%" }}
								/>
								<SkeletonLoader
									classname={` loading-stackedbar space-middle ${
										error == true ? "stop-moving" : ""
									}  `}
								/>
								<SkeletonLoader
									classname={` loading-stackedbar ${error == true ? "stop-moving" : ""}  `}
								/>
							</div>
						</>
					) : (
						<>
							<StackProgressBar
								outerDivClass={"bar-graph"}
								data={itemStackProgressBarData}
								keysColorMapping={keysColorMapping}
								keys={progressBarKeys}
								padding={1}
								borderRadius={1.5}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeItemIssuesState: state.periscopeItemIssuesState,
	periscopeItemIssuesData: state.periscopeItemIssuesData
});
export default connect(mapStateToProps)(ItemIssuesStackedbar);
