export const anaSummary = (
	state = {
		loading: true,
		data: false,
		error: false
	},
	action
) => {
	switch (action.type) {
		case "ANA_SUMMARY_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "ANA_SUMMARY_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "ANA_SUMMARY_FAILURE":
			return {
				...state,
				loading: false,
				error: true
			};
		default:
			return state;
	}
};

export const anaOrderChannels = (
	state = {
		loading: false,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case "ANA_ORDER_CHANNELS_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "ANA_ORDER_CHANNELS_SUCCESS":
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case "ANA_ORDER_CHANNELS_FAILURE":
			return {
				...state,
				loading: false,
				error: true
			};
		default:
			return state;
	}
};

export const anaGraphData = (
	state = {
		loading: true,
		data: {
			current: [],
			previous: []
		},
		error: false
	},
	action
) => {
	switch (action.type) {
		case "ANA_GRAPH_DATA_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "ANA_GRAPH_DATA_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "ANA_GRAPH_DATA_FAILURE":
			return {
				...state,
				data: {
					current: [],
					previous: []
				},
				loading: false,
				error: true
			};
		default:
			return state;
	}
};
