import React, { useState, useRef } from "react";

// third party
import { Route } from "react-router-dom";
import { connect } from "react-redux";

// components
import { Topbar } from "../../components/_commons/Topbar";
import CreditSettings from "../../containers/CreditSettings";
import InvoiceList from "../../containers/InvoiceList";
import InvoiceDetail from "../../containers/InvoiceDetail";

// utils
import { scroll } from "../../atlas-utils";

const FORM_TABS = [
	{
		label: "Invoices & Payments",
		value: "invoicesAndPayments"
	},
	{
		label: "Piper Credits",
		value: "piperCredits"
	}
];

const BillingContainer = ({ biz, renderRechargeDrawer }) => {
	return (
		<div>
			<Route
				path="/billing"
				render={(props) => (
					<BillingSettings location={props.location} biz={biz} renderRechargeDrawer={renderRechargeDrawer} />
				)}
			/>
			<Route exact path="/billing/:id" component={InvoiceDetail} />
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(BillingContainer);

export const BillingSettings = ({ location, biz = {}, renderRechargeDrawer = false }) => {
	const query = new URLSearchParams(location.search);
	const [formTab, setFormTab] = useState(
		renderRechargeDrawer || query.get("tab") === FORM_TABS[1].value ? FORM_TABS[1].value : FORM_TABS[0].value
	);
	const topRef = useRef();

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const bizState = biz.currentState;
	const isBizDeactivated = bizState === "inactive" || bizState === "pending_deactivation";

	return (
		<div className="billing-section section-container-common" ref={topRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Billing</div>
					<div className="subheader-text">Manage your billing information and view your invoices</div>
				</div>
			</div>
			<Topbar
				tabs={FORM_TABS}
				selectedTab={formTab}
				switchTab={switchTab}
				isStickyOnTop={true}
				hiddenTabs={isBizDeactivated ? ["piperCredits"] : []}
			/>
			<div className="billing-container">
				{formTab === FORM_TABS[0].value && <InvoiceList />}
				{formTab === FORM_TABS[1].value && (
					<CreditSettings location={location} renderRechargeDrawer={renderRechargeDrawer} />
				)}
			</div>
		</div>
	);
};
