import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { SearchFilter } from "../../components/_commons/SearchFilter";
import SectionHeader from "../../components/Analytics/Common/SectionHeader";
import { NestedEntityContainer } from "../../components/_commons/NestedEntityContainer";

// third party
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { connect } from "react-redux";

// store
import { store } from "../../store/configureStore";

// utils
import { scroll, trackEvent, adjustNestedContainer } from "../../atlas-utils";

// actions
import { getEncodedAnalyticsFilters } from "../../actions/analytics";

// config
import { ANALYTICS_SECTIONS, NESTED_ENTITY_TYPES } from "../../client-config";
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const Home = ({ access = {} }) => {
	const [analyticsSections, setAnalyticsSections] = useState(ANALYTICS_SECTIONS);
	const [searchValue, setSearchValue] = useState("");
	const [searchResults, setSearchResults] = useState(0);
	const [userRoles, setUserRoles] = useState(Object.keys(access)?.filter((role) => access[role]));
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const topRef = useRef();

	useEffect(() => {
		// scroll to the top
		if (topRef?.current) {
			scroll({ top: topRef?.current?.offset - 57, left: 0 }, window, "instant");
		}
		// track event
		trackEvent("analytics_module", { module: "Home" });
	}, []);

	const handleSearch = useCallback((value) => {
		if (value) {
			const regex = new RegExp(value?.toLowerCase(), "g");
			let filteredAnalyticsSections = {};
			let results = 0;
			Object.keys(ANALYTICS_SECTIONS)
				.filter(
					(sec) =>
						!ANALYTICS_SECTIONS[sec].hidden &&
						analyticsSections[sec]?.roles?.some((role) => userRoles?.includes(role))
				)
				.forEach((sec) => {
					let section = { ...ANALYTICS_SECTIONS[sec] };
					let match = false;
					if (String(section.topic?.toLowerCase()).match(regex) !== null) {
						match = true;
						results += section.tabs.length;
					}
					if (!match) {
						const tabs = section.tabs.filter((tab) => {
							let tabInfoMatch = false;
							if (!tab?.hidden) {
								if (String(tab.label?.toLowerCase()).match(regex) !== null) {
									tabInfoMatch = true;
									match = true;
								}
								if (String(tab.description?.toLowerCase()).match(regex) !== null) {
									tabInfoMatch = true;
									match = true;
								}
								if (tabInfoMatch) {
									results += 1;
								}
							}
							return tabInfoMatch;
						});
						section["tabs"] = tabs;
					}
					if (match) {
						filteredAnalyticsSections[sec] = section;
					}
				});
			setSearchResults(results);
			setAnalyticsSections(filteredAnalyticsSections);
			trackEvent("analytics_home_search", { query: value });
		} else {
			setSearchResults(0);
			setAnalyticsSections(ANALYTICS_SECTIONS);
		}
	}, []);

	const applySearch = useCallback(
		debounce((value) => {
			handleSearch(value);
		}, 500),
		[handleSearch]
	);

	const setFilter = (field, value) => {
		setSearchValue(value);
		applySearch(value);
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "analytics",
				start: "00:00"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="analytics-home section-container-common" ref={topRef}>
			<SectionHeader
				title="Analytics Home"
				showHelpBtn={true}
				handlePiperAcademy={handlePiperAcademy}
				headerRight={
					<SearchFilter
						filterOption={{ field: "searchFieldValue" }}
						value={searchValue}
						setFilter={setFilter}
						placeholder="Search"
					/>
				}
			/>
			{searchValue && searchResults > 0 && (
				<div className="search-results">Showing Results ({searchResults})</div>
			)}
			<div className="topics">
				{Object.keys(analyticsSections).map((section, i) =>
					!analyticsSections[section].hidden &&
					analyticsSections[section]?.roles?.some((role) => userRoles?.includes(role)) ? (
						<div className="topic-container" key={i}>
							<div className="topic-header">
								{analyticsSections[section].topic}
								{analyticsSections[section]?.comingSoon && (
									<span className="coming-soon">Coming Soon</span>
								)}
							</div>
							<div className={"tabs" + (analyticsSections[section]?.comingSoon ? " disabled" : "")}>
								{analyticsSections[section].tabs
									.filter((tab) => !tab.hidden)
									?.map((tab, j) => (
										<Link
											to={`${tab?.link}&filters=${getEncodedAnalyticsFilters()}`}
											onClick={() =>
												trackEvent("analytics_home_card_clicked", {
													card: tab.value
												})
											}
										>
											<div className="tab-container" key={j}>
												<div className="icon">
													<img src={tab.icon} alt="" />
												</div>
												<div className="tab-info">
													<div className="tab-header">
														<div className="hyperlink hyperlink--black-color">
															{tab.label}
														</div>
													</div>
													<div className="tab-description">{tab.description}</div>
												</div>
											</div>
										</Link>
									))}
							</div>
						</div>
					) : null
				)}
				{Object.keys(analyticsSections).length === 0 && (
					<div className="empty-search-placeholder">
						<img src="/assets/empty-states/analytics-search.svg" alt="" />
						<div className="message">No results found!</div>
						<div className="info">Try looking for a different keyword or metric type</div>
						<div className="learn-more">
							<img src="/assets/icons/icon-learn-more.svg" />
							<div>Have questions?</div>
							<div className="hyperlink" onClick={handlePiperAcademy}>
								Learn more about Analytics
							</div>
						</div>
					</div>
				)}
			</div>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access
}))(Home);
