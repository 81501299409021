import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";

// components
import { FormContainer } from "./Meraki";
import { NumericalStepDisplay } from "../components/_commons/NumericalStepDisplay";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import LocationCountSelection from "../components/LocationCreate/LocationCountSelection";
import LocationDetails from "../components/LocationCreate/LocationDetails";
import MultiLocationDetails from "../components/LocationCreate/MultiLocationDetails";
import BrandsSelection from "../components/LocationCreate/BrandsSelection";
import BrandCatalogue from "../components/LocationCreate/BrandCatalogue";
import PlatformAssociation from "../components/LocationCreate/PlatformAssociation";
import LocationCreateSuccess from "../components/LocationCreate/LocationCreateSuccess";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// store
import { store } from "../store/configureStore";

// utils
import {
	adjustNestedContainer,
	validators,
	validatePlatformUrl,
	decideMethodAndValidatePlatformId,
	lS
} from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBrands } from "../actions/actions";
import { fetchCitiesList } from "../actions/locations";

// assets
import { ButtonIcon } from "../components/_commons/ButtonIcon";

// constants
import { countrySpecificPopularCities, businessBasedDisabledFields } from "../client-config";
const CREATE_LOCATION_STATUS_API_URL = process.env.REACT_APP_LOCATION_CREATE_WIZARD_URL;
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const INIT_STATE = {
	locationAddType: "",
	city: null,
	bizLocationNickname: "",
	bizAddress: "",
	storeTimings: null,
	primaryContactPhone: null,
	primaryContactEmail: "",
	sortOrder: 0,
	merchantBizLocationId: "-1",
	locLatitude: null,
	locLongitude: null,
	orderDeliveryRadius: null,
	polygons: [],
	isMerakiDetailsSet: false,
	copyCatalogue: null,
	brands: [],
	platforms: [],
	multiLocationDetails: []
};

const LocationCreate = ({ bizId, bizCountry, isMultibrandEnabled = false }) => {
	const [selectedTab, setSelectedTab] = useState(
		lS.get("create_location_wizard") ? lS.get("create_location_wizard")[bizId]?.selectedTab || 1 : 1
	);
	const [stepCount, setStepCount] = useState(isMultibrandEnabled ? 5 : 4);
	const [isFormTouched, setFormTouched] = useState(
		lS.get("create_location_wizard")
			? lS.get("create_location_wizard")?.[bizId]?.data?.locationAddType
				? true
				: false
			: false
	);
	const [submitTitle, setSubmitTitle] = useState("Next");
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [formData, setFormData] = useState(
		lS.get("create_location_wizard") ? lS.get("create_location_wizard")[bizId]?.data || INIT_STATE : INIT_STATE
	);
	const [formValidations, setFormValidations] = useState({});
	const [multiFormValidations, setMultiFormValidations] = useState({});
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [loading, setLoading] = useState(false);
	const [referenceId, setReferenceId] = useState(
		lS.get("create_location_wizard") ? lS.get("create_location_wizard")[bizId]?.referenceId || "" : ""
	);
	const country = bizCountry.toLowerCase() === "united kingdom" ? "uk" : bizCountry.toLowerCase();
	const INIT_CITY_ARRAY_VALUES = [...(countrySpecificPopularCities[country] ?? [])];
	const [citiesList, setCitiesList] = useState({
		isLoading: false,
		items: INIT_CITY_ARRAY_VALUES
	});
	const isMulti = useMemo(() => formData.locationAddType === "multi", [formData.locationAddType]);
	const nestedRef = useRef();

	useEffect(() => {
		if (referenceId) {
			checkCreateLocationStatus(referenceId);
		}
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	const checkCreateLocationStatus = async (referenceId) => {
		if (referenceId) {
			setLoading(true);
			try {
				const url = `${CREATE_LOCATION_STATUS_API_URL}/?reference_id=${referenceId}`;
				const token = store.getState().login.loginDetail.token;
				const resp = await fetch(url, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				const data = await resp.json();
				if (data.status === "QUEUED" || data.status === "PROCESSING") {
					store.dispatch({
						type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
						payload: {
							status: data.status,
							reference_id: referenceId,
							total_locations:
								lS.get("create_location_wizard")[bizId]?.data?.locationAddType === "single"
									? 1
									: lS.get("create_location_wizard")[bizId]?.data?.multiLocationDetails?.length || 0,
							processed_locations: data?.locations_data?.length || 0,
							created_locations: data?.locations_data || [],
							message: "",
							success: true,
							restrict: true
						}
					});
					setReferenceId(referenceId);
					setFormTouched(false);
					setDisableSubmit(false);
					setSubmitTitle("Finish");
					setSelectedTab(isMultibrandEnabled ? 6 : 5);
				} else {
					resetCreateLocation();
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Failed to add new locations.",
						timeout: 3000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		}
	};

	const searchCities = async (searchCity) => {
		if (searchCity === "") {
			setCitiesList({
				isLoading: false,
				items: INIT_CITY_ARRAY_VALUES
			});
			return;
		}
		setCitiesList({
			...citiesList,
			isLoading: true
		});
		const citySearchResult = await fetchCitiesList(searchCity);

		if (Array.isArray(citySearchResult?.data)) {
			const updatedCitiesList = [...citySearchResult.data];
			setCitiesList({
				isLoading: false,
				items: [
					...updatedCitiesList.map((city) => ({
						value: city.name,
						valueForDisplay: `${city.name}, ${city.state_name}, ${city.country_name}`
					}))
				]
			});
		} else {
			setCitiesList({
				...citiesList,
				isLoading: false
			});
		}
	};
	const searchCitiesDebounced = debounce((searchCity) => searchCities(searchCity), 300);

	const validateFormData = () => {
		const { city, bizLocationNickname, brands, platforms, copyCatalogue, multiLocationDetails } = formData;

		if (!isMulti) {
			switch (selectedTab) {
				case 2:
					if (city && bizLocationNickname) {
						setDisableSubmit(false);
					} else {
						setDisableSubmit(true);
					}
					break;
				case 3:
					if (isMultibrandEnabled) {
						if (brands?.length) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					} else {
						setSubmitTitle("Next");
						if (disableSubmit) {
							setDisableSubmit(false);
						}
					}
					break;
				case 4:
					if (isMultibrandEnabled) {
						setSubmitTitle("Next");
						if (disableSubmit) {
							setDisableSubmit(false);
						}
					} else {
						let allFieldsFilled = true;
						platforms.some((plf) => {
							if (plf.platformName.toLowerCase() !== "meraki" && (!plf.platformId || !plf.platformUrl)) {
								allFieldsFilled = false;
							}
							return !allFieldsFilled;
						});
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					}
					break;
				case 5:
					if (isMultibrandEnabled) {
						let allFieldsFilled = true;
						brands.some((brand) => {
							brand.platforms.some((plf) => {
								if (!plf.platformId || !plf.platformUrl) {
									allFieldsFilled = false;
								}
								return !allFieldsFilled;
							});
							return !allFieldsFilled;
						});
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					}
					break;
				default:
					break;
			}
		} else {
			switch (selectedTab) {
				case 2:
					let goNext = false;
					let count = 0;
					multiLocationDetails.some((loc) => {
						let checkFailed = false;
						if (!loc.bizLocationNickname && !loc.city) {
							goNext = true;
						} else if (loc.bizLocationNickname && loc.city) {
							count += 1;
							goNext = true;
						} else {
							checkFailed = true;
							goNext = false;
						}
						return checkFailed;
					});
					if (goNext && count > 0) {
						setDisableSubmit(false);
					} else {
						setDisableSubmit(true);
					}
					break;
				case 3:
					if (isMultibrandEnabled) {
						let emptyBrandsFound = false;
						multiLocationDetails.some((loc) => {
							let checkFailed = false;
							if (loc.brands.length > 0) {
								emptyBrandsFound = false;
							} else {
								emptyBrandsFound = true;
								checkFailed = true;
							}
							return checkFailed;
						});
						if (emptyBrandsFound) {
							setDisableSubmit(true);
						} else {
							setDisableSubmit(false);
						}
					} else {
						if (copyCatalogue) {
							setSubmitTitle("Next");
						} else {
							setSubmitTitle("Skip");
						}
					}
					break;
				case 4:
					if (isMultibrandEnabled) {
						setSubmitTitle("Next");
					} else {
						let allFieldsFilled = true;
						multiLocationDetails.some((loc) => {
							loc.platforms.some((plf) => {
								if (
									plf.platformName.toLowerCase() !== "meraki" &&
									(!plf.platformId || !plf.platformUrl)
								) {
									allFieldsFilled = false;
								}
								return !allFieldsFilled;
							});
							return !allFieldsFilled;
						});
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					}
					break;
				case 5:
					if (isMultibrandEnabled) {
						let allFieldsFilled = true;
						multiLocationDetails.some((loc) => {
							loc.brands.some((brand) => {
								brand.platforms.some((plf) => {
									if (!plf.platformId || !plf.platformUrl) {
										allFieldsFilled = false;
									}
									return !allFieldsFilled;
								});
								return !allFieldsFilled;
							});
							return !allFieldsFilled;
						});
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					}
					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		validateFormData();
	}, [formData]);

	const handleFormData = useCallback(
		(data = {}) => {
			setFormData({
				...formData,
				...data
			});
			// update data in local storage
			lS.set("create_location_wizard", {
				...(lS.get("create_location_wizard") ?? {}),
				[bizId]: {
					...(lS.get("create_location_wizard")?.[bizId] ?? {}),
					data: {
						...(lS.get("create_location_wizard")?.[bizId]?.data ?? formData),
						...data
					}
				}
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		},
		[formData, isFormTouched, disableSubmit, selectedTab, bizId]
	);

	const handleNextTab = () => {
		let isValidated = true;
		const {
			city,
			bizLocationNickname,
			primaryContactEmail,
			primaryContactPhone,
			brands,
			platforms,
			copyCatalogue,
			multiLocationDetails
		} = formData;

		if (!isMulti) {
			switch (selectedTab) {
				case 1:
					if (!bizLocationNickname || !city) {
						setDisableSubmit(true);
					}
					break;
				case 2:
					if (isMultibrandEnabled) {
						let isEmailValid =
							primaryContactEmail?.length > 0 ? validators.emailValidator(primaryContactEmail) : true;
						let isPhoneValid =
							primaryContactPhone?.length > 0
								? validators.phoneNumberValidator(primaryContactPhone)
								: true;
						if (!isEmailValid || !isPhoneValid) {
							isValidated = false;
							setFormValidations({
								...formValidations,
								primaryContactEmail: !isEmailValid ? "Invalid email address" : "",
								primaryContactPhone: !isPhoneValid ? "Invalid phone number" : ""
							});
						} else if (!brands || brands?.length === 0) {
							setDisableSubmit(true);
						}
					} else {
						let isEmailValid =
							primaryContactEmail?.length > 0 ? validators.emailValidator(primaryContactEmail) : true;
						let isPhoneValid =
							primaryContactPhone?.length > 0
								? validators.phoneNumberValidator(primaryContactPhone)
								: true;
						if (!isEmailValid || !isPhoneValid) {
							isValidated = false;
							setFormValidations({
								...formValidations,
								primaryContactEmail: !isEmailValid ? "Invalid email address" : "",
								primaryContactPhone: !isPhoneValid ? "Invalid phone number" : ""
							});
						} else {
							if (copyCatalogue) {
								setSubmitTitle("Next");
							} else {
								setSubmitTitle("Skip");
							}
						}
					}
					break;
				case 3:
					if (isMultibrandEnabled) {
						// check if catalogue is added from an existing location for any brand
						let isAdded = false;
						brands.forEach((brand) => {
							if (brand?.copyCatalogue) {
								isAdded = true;
							}
						});
						if (isAdded) {
							setDisableSubmit(false);
							setSubmitTitle("Next");
						} else {
							setSubmitTitle("Skip");
						}
					} else {
						let allFieldsFilled = true;
						(platforms ?? []).some((plf) => {
							if (plf.platformName.toLowerCase() !== "meraki" && (!plf.platformId || !plf.platformUrl)) {
								allFieldsFilled = false;
							}
							return !allFieldsFilled;
						});
						setSubmitTitle("Finish");
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					}
					break;
				case 4:
					if (isMultibrandEnabled) {
						let allFieldsFilled = true;
						brands.some((brand) => {
							brand.platforms.some((plf) => {
								if (!plf.platformId || !plf.platformUrl) {
									allFieldsFilled = false;
								}
								return !allFieldsFilled;
							});
							return !allFieldsFilled;
						});
						setSubmitTitle("Finish");
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					} else {
						let updatedFormValidations = { ...formValidations };
						let uniquePlfIds = {};
						platforms.forEach((plf) => {
							if (plf.platformName.toLowerCase() !== "meraki") {
								let isPlatformIdUnique = !(
									uniquePlfIds[plf.platformId] &&
									uniquePlfIds[plf.platformId]?.includes(plf.platformName)
								);
								if (isPlatformIdUnique) {
									uniquePlfIds[plf.platformId] = [
										...(uniquePlfIds[plf.platformId] ?? []),
										plf.platformName
									];
								}
								let platformUrlValidation = businessBasedDisabledFields.platformUrl[
									plf?.platformName?.toLowerCase()
								]
									? true
									: validatePlatformUrl(plf.platformName.toLowerCase(), plf.platformUrl);
								let platformIdValidation = businessBasedDisabledFields.platformId[
									plf?.platformName?.toLowerCase()
								]
									? true
									: decideMethodAndValidatePlatformId(plf.platformName.toLowerCase(), plf.platformId);
								if (!platformIdValidation || !platformUrlValidation || !isPlatformIdUnique) {
									updatedFormValidations.platforms = {
										...(updatedFormValidations.platforms ?? {}),
										[plf.id]: {
											...(updatedFormValidations.platforms?.[plf.id] ?? {}),
											platformId: !isPlatformIdUnique
												? "You have entered the same platform ID for multiple locations"
												: !platformIdValidation
												? "Invalid platform ID"
												: "",
											platformUrl: !platformUrlValidation ? "Invalid platform URL" : ""
										}
									};
									isValidated = false;
								}
							}
						});
						setFormValidations(updatedFormValidations);
						if (isValidated) {
							setFormTouched(false);
						}
					}
					break;
				case 5:
					if (isMultibrandEnabled) {
						let updatedFormValidations = { ...formValidations };
						let uniquePlfIds = {};
						brands.forEach((brand) => {
							brand.platforms.forEach((plf) => {
								let isPlatformIdUnique = !(
									uniquePlfIds[plf.platformId] &&
									uniquePlfIds[plf.platformId]?.includes(plf.platformName)
								);
								if (isPlatformIdUnique) {
									uniquePlfIds[plf.platformId] = [
										...(uniquePlfIds[plf.platformId] ?? []),
										plf.platformName
									];
								}
								let platformUrlValidation = businessBasedDisabledFields.platformUrl[
									plf?.platformName?.toLowerCase()
								]
									? true
									: validatePlatformUrl(plf.platformName.toLowerCase(), plf.platformUrl);
								let platformIdValidation = businessBasedDisabledFields.platformId[
									plf?.platformName?.toLowerCase()
								]
									? true
									: decideMethodAndValidatePlatformId(plf.platformName.toLowerCase(), plf.platformId);
								if (!platformIdValidation || !platformUrlValidation || !isPlatformIdUnique) {
									updatedFormValidations.platforms = {
										...(updatedFormValidations.platforms ?? {}),
										[brand.id]: {
											...(updatedFormValidations.platforms?.[brand.id] ?? {}),
											[plf.id]: {
												...(updatedFormValidations.platforms?.[brand.id]?.[plf.id] ?? {}),
												platformId: !isPlatformIdUnique
													? "You have entered the same platform ID for multiple locations"
													: !platformIdValidation
													? "Invalid platform ID"
													: "",
												platformUrl: !platformUrlValidation ? "Invalid platform URL" : ""
											}
										}
									};
									isValidated = false;
								}
							});
						});
						setFormValidations(updatedFormValidations);
						if (isValidated) {
							setFormTouched(false);
						}
					}
					break;
				default:
					break;
			}
		} else {
			switch (selectedTab) {
				case 1:
					let goNext = false;
					let count = 0;
					(multiLocationDetails ?? []).some((loc) => {
						let checkFailed = false;
						if (!loc.bizLocationNickname && !loc.city) {
							goNext = true;
						} else if (loc.bizLocationNickname && loc.city) {
							count += 1;
							goNext = true;
						} else {
							checkFailed = true;
							goNext = false;
						}
						return checkFailed;
					});
					if (goNext && multiLocationDetails.length && count > 0) {
						setDisableSubmit(false);
					} else {
						setDisableSubmit(true);
					}
					break;
				case 2:
					if (isMultibrandEnabled) {
						let emptyBrandsFound = false;
						let updatedMultiFormValidations = { ...multiFormValidations };
						const updatedMultiLocationDetails = multiLocationDetails
							.filter((loc) => loc.bizLocationNickname && loc.city.value)
							.map((loc, i) => {
								let isEmailValid =
									loc.primaryContactEmail?.length > 0
										? validators.emailValidator(loc.primaryContactEmail)
										: true;
								let isPhoneValid =
									loc.primaryContactPhone?.length > 0
										? validators.phoneNumberValidator(loc.primaryContactPhone)
										: true;
								if (!isEmailValid || !isPhoneValid) {
									isValidated = false;
									updatedMultiFormValidations[i] = {
										primaryContactEmail: !isEmailValid ? "Invalid email address" : "",
										primaryContactPhone: !isPhoneValid ? "Invalid phone number" : ""
									};
								}
								if (loc?.brands?.length === 0) {
									emptyBrandsFound = true;
								}
								return loc;
							});
						// update multiLocationDetails
						handleFormData({ multiLocationDetails: updatedMultiLocationDetails });
						// set validations
						setMultiFormValidations(updatedMultiFormValidations);
						if (emptyBrandsFound) {
							setDisableSubmit(true);
						} else {
							setDisableSubmit(false);
						}
					} else {
						let updatedMultiFormValidations = { ...multiFormValidations };
						const updatedMultiLocationDetails = multiLocationDetails
							.filter((loc) => loc.bizLocationNickname && loc.city.value)
							.map((loc, i) => {
								let isEmailValid =
									loc.primaryContactEmail?.length > 0
										? validators.emailValidator(loc.primaryContactEmail)
										: true;
								let isPhoneValid =
									loc.primaryContactPhone?.length > 0
										? validators.phoneNumberValidator(loc.primaryContactPhone)
										: true;
								if (!isEmailValid || !isPhoneValid) {
									isValidated = false;
									updatedMultiFormValidations[i] = {
										primaryContactEmail: !isEmailValid ? "Invalid email address" : "",
										primaryContactPhone: !isPhoneValid ? "Invalid phone number" : ""
									};
								}
								return loc;
							});
						// update multiLocationDetails
						handleFormData({ multiLocationDetails: updatedMultiLocationDetails });
						// set validations
						setMultiFormValidations(updatedMultiFormValidations);
						if (isValidated) {
							if (copyCatalogue) {
								setSubmitTitle("Next");
							} else {
								setSubmitTitle("Skip");
							}
						}
					}
					break;
				case 3:
					if (isMultibrandEnabled) {
						let copiedCatalogue = false;
						multiLocationDetails.some((loc) => {
							let checkPassed = false;
							loc.brands.some((brand) => {
								if (brand?.copyCatalogue?.id) {
									copiedCatalogue = true;
									checkPassed = true;
								} else {
									copiedCatalogue = false;
								}
								return checkPassed;
							});
							return checkPassed;
						});
						if (copiedCatalogue) {
							setSubmitTitle("Next");
						} else {
							setSubmitTitle("Skip");
						}
					} else {
						let allFieldsFilled = true;
						multiLocationDetails.some((loc) => {
							loc.platforms.some((plf) => {
								if (
									plf.platformName.toLowerCase() !== "meraki" &&
									(!plf.platformId || !plf.platformUrl)
								) {
									allFieldsFilled = false;
								}
								return !allFieldsFilled;
							});
							return !allFieldsFilled;
						});
						setSubmitTitle("Finish");
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					}
					break;
				case 4:
					if (isMultibrandEnabled) {
						let allFieldsFilled = true;
						multiLocationDetails.some((loc) => {
							loc.brands.some((brand) => {
								brand.platforms.some((plf) => {
									if (!plf.platformId || !plf.platformUrl) {
										allFieldsFilled = false;
									}
									return !allFieldsFilled;
								});
								return !allFieldsFilled;
							});
							return !allFieldsFilled;
						});
						setSubmitTitle("Finish");
						if (allFieldsFilled) {
							setDisableSubmit(false);
						} else {
							setDisableSubmit(true);
						}
					} else {
						let updatedValidations = { ...multiFormValidations };
						let uniquePlfIds = {};
						multiLocationDetails.forEach((loc, i) => {
							loc.platforms.forEach((plf) => {
								if (plf.platformName.toLowerCase() !== "meraki") {
									let isPlatformIdUnique = !(
										uniquePlfIds[plf.platformId] &&
										uniquePlfIds[plf.platformId]?.includes(plf.platformName)
									);
									if (isPlatformIdUnique) {
										uniquePlfIds[plf.platformId] = [
											...(uniquePlfIds[plf.platformId] ?? []),
											plf.platformName
										];
									}
									let platformUrlValidation = businessBasedDisabledFields.platformUrl[
										plf?.platformName?.toLowerCase()
									]
										? true
										: validatePlatformUrl(plf.platformName.toLowerCase(), plf.platformUrl);
									let platformIdValidation = businessBasedDisabledFields.platformId[
										plf?.platformName?.toLowerCase()
									]
										? true
										: decideMethodAndValidatePlatformId(
												plf.platformName.toLowerCase(),
												plf.platformId
										  );
									if (!platformIdValidation || !platformUrlValidation || !isPlatformIdUnique) {
										updatedValidations[i] = {
											...(updatedValidations[i] ?? {}),
											platforms: {
												...(updatedValidations[i]?.platforms ?? {}),
												[plf.id]: {
													platformId: !isPlatformIdUnique
														? "You have entered the same platform ID for multiple locations"
														: !platformIdValidation
														? "Invalid platform ID"
														: "",
													platformUrl: !platformUrlValidation ? "Invalid platform URL" : ""
												}
											}
										};
										isValidated = false;
									}
								}
							});
						});
						setMultiFormValidations(updatedValidations);
						if (isValidated) {
							setFormTouched(false);
						}
					}
					break;
				case 5:
					if (isMultibrandEnabled) {
						let updatedValidations = { ...multiFormValidations };
						let uniquePlfIds = {};
						multiLocationDetails.forEach((loc, i) => {
							loc.brands.forEach((brand) => {
								brand.platforms.forEach((plf) => {
									let isPlatformIdUnique = !(
										uniquePlfIds[plf.platformId] &&
										uniquePlfIds[plf.platformId]?.includes(plf.platformName)
									);
									if (isPlatformIdUnique) {
										uniquePlfIds[plf.platformId] = [
											...(uniquePlfIds[plf.platformId] ?? []),
											plf.platformName
										];
									}
									let platformUrlValidation = businessBasedDisabledFields.platformUrl[
										plf?.platformName?.toLowerCase()
									]
										? true
										: validatePlatformUrl(plf.platformName.toLowerCase(), plf.platformUrl);
									let platformIdValidation = businessBasedDisabledFields.platformId[
										plf?.platformName?.toLowerCase()
									]
										? true
										: decideMethodAndValidatePlatformId(
												plf.platformName.toLowerCase(),
												plf.platformId
										  );
									if (!platformIdValidation || !platformUrlValidation || !isPlatformIdUnique) {
										updatedValidations[i] = {
											...(updatedValidations[i] ?? {}),
											platforms: {
												...(updatedValidations[i]?.platforms ?? {}),
												[brand.id]: {
													...(updatedValidations[i]?.platforms?.[brand.id] ?? {}),
													[plf.id]: {
														platformId: !isPlatformIdUnique
															? "You have entered the same platform ID for multiple locations"
															: !platformIdValidation
															? "Invalid platform ID"
															: "",
														platformUrl: !platformUrlValidation
															? "Invalid platform URL"
															: ""
													}
												}
											}
										};
										isValidated = false;
									}
								});
							});
						});
						setMultiFormValidations(updatedValidations);
						if (isValidated) {
							setFormTouched(false);
						}
					}
					break;
				default:
					break;
			}
		}
		// switch to next tab
		if (selectedTab <= stepCount && isValidated) {
			setSelectedTab((prev) => prev + 1);
		}
	};

	const handlePreviousTab = () => {
		// switch to previous tab
		if (selectedTab > 1) {
			setSelectedTab((prev) => prev - 1);
		}
		if (!isMulti) {
			if (selectedTab === 3) {
				if (!isMultibrandEnabled) {
					setSubmitTitle("Next");
				}
			}
			if (selectedTab === 4) {
				if (isMultibrandEnabled) {
					setSubmitTitle("Next");
				} else {
					// check if catalogue is added from an existing location
					if (formData.copyCatalogue) {
						setSubmitTitle("Next");
					} else {
						setSubmitTitle("Skip");
					}
				}
			}
			if (selectedTab === 5) {
				if (isMultibrandEnabled) {
					// check if catalogue is added from an existing location for any brand
					let isAdded = false;
					formData.brands.forEach((brand) => {
						if (brand?.copyCatalogue) {
							isAdded = true;
						}
					});
					if (isAdded) {
						setSubmitTitle("Next");
					} else {
						setSubmitTitle("Skip");
					}
				}
			}
		} else {
			if (selectedTab === 3) {
				if (!isMultibrandEnabled) {
					setSubmitTitle("Next");
				}
			}
			if (selectedTab === 4) {
				if (isMultibrandEnabled) {
					setSubmitTitle("Next");
				} else {
					// check if catalogue is added from an existing location
					if (formData.copyCatalogue) {
						setSubmitTitle("Next");
					} else {
						setSubmitTitle("Skip");
					}
				}
			}
			if (selectedTab === 5) {
				if (isMultibrandEnabled) {
					// check if catalogue is added from an existing location for any brand
					let copiedCatalogue = false;
					formData.multiLocationDetails.some((loc) => {
						let checkPassed = false;
						loc.brands.some((brand) => {
							if (brand?.copyCatalogue?.id) {
								copiedCatalogue = true;
								checkPassed = true;
							} else {
								copiedCatalogue = false;
							}
							return checkPassed;
						});
						return checkPassed;
					});
					if (copiedCatalogue) {
						setSubmitTitle("Next");
					} else {
						setSubmitTitle("Skip");
					}
				}
			}
		}
		setDisableSubmit(false);
	};

	const handleNestedEntity = useCallback((toOpen, type) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id: null
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handleFixErrors = () => {
		setSelectedTab(1);
		setFormTouched(true);
		setDisableSubmit(false);
		setSubmitTitle("Next");
	};

	const resetCreateLocation = () => {
		setFormData(INIT_STATE);
		setReferenceId("");
		setSelectedTab(1);
		setFormTouched(false);
		setDisableSubmit(false);
		setSubmitTitle("Next");
		// reset data in local storage
		lS.set("create_location_wizard", {
			...(lS.get("create_location_wizard") ?? {}),
			[bizId]: {
				...(lS.get("create_location_wizard")?.[bizId] ?? {}),
				data: INIT_STATE,
				referenceId: ""
			}
		});
	};

	const renderTitle = () => {
		return (
			<React.Fragment>
				<div className="div">Create Location</div>
				<ButtonIcon icon="refresh" title="Reset all data" clickHandler={resetCreateLocation} />
			</React.Fragment>
		);
	};

	return (
		<div className="location-create-container section-container-common">
			{selectedTab <= stepCount && (
				<NumericalStepDisplay
					count={stepCount}
					selectedValue={selectedTab}
					showProgressBar={true}
					title={renderTitle()}
				/>
			)}
			<FormContainer
				hideCancelAction={selectedTab === 1}
				cancelTitle="Previous"
				cancel={handlePreviousTab}
				submit={handleNextTab}
				submitTitle={submitTitle}
				hideActions={!isFormTouched || loading}
				disableSubmit={disableSubmit}
			>
				<div className="form-content">
					{loading ? (
						<div className="P(25px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					) : (
						<React.Fragment>
							{selectedTab === 1 && (
								<LocationCountSelection handleFormData={handleFormData} formData={formData} />
							)}
							{selectedTab === 2 && !isMulti && (
								<LocationDetails
									handleFormData={handleFormData}
									formData={formData}
									handleNestedEntity={handleNestedEntity}
									citiesList={citiesList}
									searchCitiesDebounced={searchCitiesDebounced}
									validations={formValidations}
									setFormValidations={setFormValidations}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							)}
							{selectedTab === 2 && isMulti && (
								<MultiLocationDetails
									handleFormData={handleFormData}
									formData={formData}
									citiesList={citiesList}
									validations={multiFormValidations}
									setMultiFormValidations={setMultiFormValidations}
									searchCitiesDebounced={searchCitiesDebounced}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							)}
							{isMultibrandEnabled && selectedTab === 3 && (
								<BrandsSelection
									handleFormData={handleFormData}
									formData={formData}
									isMulti={isMulti}
									validations={isMulti ? multiFormValidations : formValidations}
									setValidations={isMulti ? setMultiFormValidations : setFormValidations}
								/>
							)}
							{((!isMultibrandEnabled && selectedTab === 3) ||
								(isMultibrandEnabled && selectedTab === 4)) && (
								<BrandCatalogue
									handleFormData={handleFormData}
									formData={formData}
									isMulti={isMulti}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							)}
							{((!isMultibrandEnabled && selectedTab === 4) ||
								(isMultibrandEnabled && selectedTab === 5)) && (
								<PlatformAssociation
									handleFormData={handleFormData}
									formData={formData}
									isMulti={isMulti}
									validations={isMulti ? multiFormValidations : formValidations}
									setValidations={isMulti ? setMultiFormValidations : setFormValidations}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							)}
							{((!isMultibrandEnabled && selectedTab === 5) ||
								(isMultibrandEnabled && selectedTab === 6)) && (
								<LocationCreateSuccess
									formData={formData}
									isMulti={isMulti}
									setReferenceId={setReferenceId}
									referenceId={referenceId}
									bizId={bizId}
									validations={isMulti ? multiFormValidations : formValidations}
									setValidations={isMulti ? setMultiFormValidations : setFormValidations}
									handleFixErrors={handleFixErrors}
									resetCreateLocation={resetCreateLocation}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							)}
						</React.Fragment>
					)}
					<NestedEntityContainer
						show={nestedEntity?.show}
						type={nestedEntity?.type}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						isNested={false}
						hasAccess={true}
						fromLocationCreateWizard={true}
						presetTitle={!isMulti ? formData.bizLocationNickname : ""}
					/>
				</div>
			</FormContainer>
		</div>
	);
};
const mapStateToProps = (store) => ({
	bizId: store?.login?.loggedInbizDetail?.id,
	bizCountry: store.login.loggedInbizDetail.country,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(LocationCreate);
