import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/ModifierEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, parseSuperstructErrorMessages, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";
import { type, number, min, validate } from "superstruct/umd/superstruct";

// actions
import { fetchModifiersList } from "../actions/modifiers";
import { fetchCiModifierGroups } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_MODIFIER } from "../graphql/modifiers";
import { GET_ITEM_TAGS } from "../graphql/items";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../client-config";
const INIT_STATE = {
	optionTitle: "",
	foodType: undefined,
	nestedOptionGroups: [],
	optionPrice: 0,
	optionOrder: 0,
	tags: [],
	crmTitle: "",
	merchantRefId: "",
	optionWeight: null,
	optionDesc: ""
};
export const MODIFIER_FORM_SCHEMA = type({
	optionOrder: min(number(), 0),
	optionPrice: min(number(), 0)
});

const ModifierCreate = ({
	currencySymbol,
	hasAccess = false,
	isFromMenuSection = false,
	isOpen = false,
	isNested = false,
	close = () => {}
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [data, setData] = useState(INIT_STATE);

	useEffect(() => {
		setTimeout(() => {
			if (!isFromMenuSection) {
				setFormState(true);
			}
		}, 60);
		fetchCiModifierGroups();
	}, []);

	const fetchItemTags = async (searchText = "") => {
		try {
			const variables = {
				limit: 200,
				offset: 0,
				sort: { field: "title", order: "ASC" }
			};
			if (searchText !== "") {
				variables.search = [{ key: "default", value: searchText }];
			}
			const resp = await client.query({
				query: GET_ITEM_TAGS,
				variables
			});
			// get unique tag groups and its tags
			let tagGroups = {};
			resp.data.itemTags.objects.forEach((tag) => {
				tag = {
					...tag,
					titleForDisplay: tag?.tagGroup ? `${tag.title} (${tag?.tagGroup?.title})` : `${tag.title} (General)`
				};
				if (tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"]) {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"].push(tag);
				} else {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"] = [tag];
				}
			});
			// combine all tag groups' tags using concat
			// return { options: [].concat.apply([], Object.values(tagGroups)) };
			return [].concat.apply([], Object.values(tagGroups));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			return { options: [] };
		}
	};

	const handleCancel = () => {
		setFormState(false);
		close();
		if (!isFromMenuSection) {
			fetchModifiersList();
		} else {
			setData(INIT_STATE);
		}
		setTimeout(() => {
			if (!isFromMenuSection) {
				history.push("/modifiers");
			}
		}, 100);
	};

	const validateData = (data) => {
		const [error] = validate(data, MODIFIER_FORM_SCHEMA);
		if (error) {
			setError(parseSuperstructErrorMessages(error));
			return false;
		}
		setError({});
		return true;
	};

	const handleForm = (field, value) => {
		const newData = {
			...data,
			[field]: value
		};
		setData(newData);
		validateData(newData);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		if (!validateData(data)) {
			return;
		}
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			if (variables.foodType) {
				variables.foodType = variables.foodType.value;
			}
			if (variables.merchantRefId === "") {
				variables.merchantRefId = "-1";
			}
			if (!variables.optionWeight) {
				variables.optionWeight = null;
			}
			if (variables.tags.length) {
				variables.tags = variables.tags.map((tag) => tag.title);
			}
			if (variables.nestedOptionGroups.length) {
				variables.nestedOptionGroupIds = variables.nestedOptionGroups.map((og) => og.id);
			}
			const resp = await client.mutate({
				mutation: CREATE_MODIFIER,
				variables
			});
			if (resp.data.saveModifier.status.success) {
				trackEvent(TRACKING_EVENT_NAMES.NEW_MODIFIER_CREATION, {
					status: TRACKING_STATUS.SUCCESS
				});

				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Modifier created",
						timeout: 5000,
						error: false
					}
				});
				if (isFromMenuSection) {
					close(resp.data.saveModifier.object.id);
				} else {
					store.dispatch({
						type: ActionTypes.UPDATE_MODIFIERS_LIST,
						payload: resp.data.saveModifier.object
					});
					history.push(`/modifiers/edit/${resp.data.saveModifier.object.id}`);
				}
			} else {
				trackEvent(TRACKING_EVENT_NAMES.NEW_MODIFIER_CREATION, {
					status: TRACKING_STATUS.FAILURE
				});

				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveModifier.status.messages));
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.NEW_MODIFIER_CREATION, {
				status: TRACKING_STATUS.FAILURE
			});

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	return (
		<FormSidebar
			isOpen={isFromMenuSection ? isOpen : isFormOpen}
			isNested={isNested}
			close={handleCancel}
			submit={handleSubmit}
			disabled={!isFormTouched}
			title="Modifier"
			subTitle="Create a new modifier"
			loading={confirmLoading}
			submitTitle="Create"
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<BasicInfo
					data={data}
					handleForm={handleForm}
					validations={error.fields || {}}
					currencySymbol={currencySymbol}
					readOnly={!hasAccess}
					fetchItemTags={fetchItemTags}
					showCascade={false}
				/>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
export default connect(mapStateToProps)(ModifierCreate);
