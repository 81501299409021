import React, { useState, useCallback, useEffect, useRef } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { CaptureLocationPolygon } from "../_commons/CaptureLocationPolygon";
import { Modal } from "../_commons/Modal";

// constant
const INIT_STATE = {
	title: "",
	polygon: null
};

const ManagePolygons = ({
	isOpen = false,
	close,
	data,
	handlePolygons,
	enableDeliveryCharge = false,
	savePolygonsDataToServer
}) => {
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [polygons, setPolygons] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedPolygon, setSelectedPolygon] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [deletePolygon, setDeletePolygon] = useState(INIT_STATE);
	const polygonsRef = useRef();

	useEffect(() => {
		if (selectedPolygon !== null) {
			const polygonSelected = document.querySelector(".table-body .selected");
			if (polygonSelected) {
				polygonSelected.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
			}
		}
	}, [selectedPolygon]);

	const handleForm = (polygon, value) => {
		const updatedPolygons = polygons.map((poly) => {
			if (poly.uniqueId === polygon.uniqueId) {
				poly.deliveryCharge = value;
			}
			return poly;
		});
		setPolygons(updatedPolygons);
		polygonsRef.current.updatePolygons(updatedPolygons);
		if (!isFormTouched) {
			setIsFormTouched(true);
		}
	};

	const handleSelectPolygon = (polygon) => {
		if (polygon) {
			polygonsRef.current.setSelection(polygon);
		}
		return true;
	};

	const handleDeletePolygon = () => {
		if (deletePolygon.polygon) {
			polygonsRef.current.deletePolygon();
			if (!isFormTouched) {
				setIsFormTouched(true);
			}
		}
		handleModalClose();
	};

	const updatePolygonsState = (polygon) => {
		const polygons = polygonsRef?.current?.presetPolygons;
		setPolygons([...polygons]);
		if (loading) {
			setLoading(false);
		}
		if (polygon) {
			handleSelectPolygon(polygon);
			if (!isFormTouched) {
				setIsFormTouched(true);
			}
		}
	};

	const handleClose = useCallback(
		(refresh = false) => {
			setIsFormTouched(false);
			setPolygons([]);
			setSelectedPolygon(null);
			close(refresh);
		},
		[close]
	);

	const handleSubmit = () => {
		polygonsRef.current.savePolygons(true);
	};

	const handleModalOpen = (title, polygon) => {
		handleSelectPolygon(polygon);
		setDeletePolygon({
			title,
			polygon
		});
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setDeletePolygon(INIT_STATE);
	};

	return (
		<div className="manage-polygons-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSubmit}
				title="Manage Polygons"
				subTitle="Create, edit and assign delivery charge on polygons"
				submitTitle="Save changes"
				disabled={!isFormTouched}
				submitButtonWidth="150"
				isNested={true}
				hideActions={!isFormTouched}
			>
				<div className="form-content">
					{isOpen && (
						<CaptureLocationPolygon
							setPolygons={handlePolygons}
							latLong={{
								lat: data.locLatitude,
								lng: data.locLongitude
							}}
							presetPolygons={data.polygons}
							foreignPresetPolygons={data.otherPolygons}
							locationName={data.bizLocationNickname}
							city={data.city}
							close={() => handleClose(true)}
							savePolygonsRef={polygonsRef}
							handleSelectPolygon={setSelectedPolygon}
							nearbyLocationsArray={data.nearByLocations || []}
							updateState={updatePolygonsState}
							fromManagePolygons={true}
							handleFormSubmission={savePolygonsDataToServer}
						/>
					)}
					{loading && <div className="map-loading shimmer H(250px) Mb(30px)" />}
					{isOpen && (
						<div className="polygons-table-container">
							<div className="table-header">
								<div className="table-row">
									<div className="table-cell name">Name</div>
									{
										// enableDeliveryCharge &&
										<div className="table-cell delivery-charge">Delivery Charge</div>
									}
								</div>
							</div>
							<div className="table-body">
								{polygons.map((polygon, i) => {
									const { uniqueId, deliveryCharge } = polygon;
									return (
										<div
											className={"table-row" + (uniqueId === selectedPolygon ? " selected" : "")}
											key={i}
										>
											<div
												className="table-cell name"
												onClick={() => handleSelectPolygon(polygon)}
											>
												<span>Polygon {i + 1}</span>
												{/* {
														!enableDeliveryCharge &&
														<div className="delete-polygon" onClick={() => handleModalOpen(`Polygon ${i+1}`, polygon)}>
															<img src="/assets/icons/delete.svg" alt="" />
														</div>
													} */}
											</div>
											{
												// enableDeliveryCharge &&
												<div className="table-cell delivery-charge">
													<InputWithLabel
														value={deliveryCharge}
														type="number"
														onChange={(e) =>
															handleForm(
																polygon,
																e.target.value ? parseFloat(e.target.value) : null
															)
														}
														onFocus={() => handleSelectPolygon(polygon)}
													/>
													<div
														className="delete-polygon"
														onClick={() => handleModalOpen(`Polygon ${i + 1}`, polygon)}
													>
														<img src="/assets/icons/delete.svg" alt="" />
													</div>
												</div>
											}
										</div>
									);
								})}
								{polygons.length === 0 && !loading && (
									<div className="no-items-placeholder">
										<img src="/assets/icons/icon-polygon-location.svg" alt="" />
										<div className="message">Use the map above to create polygons</div>
									</div>
								)}
								{polygons.length === 0 && loading && (
									<div className="P(10px)">
										<div className="shimmer H(60px) Mb(10px)" />
										<div className="shimmer H(60px) Mb(10px)" />
									</div>
								)}
							</div>
						</div>
					)}
					<Modal
						isOpen={modalOpen}
						close={handleModalClose}
						title={deletePolygon.title}
						subTitle=""
						showSubmitAction={false}
						showCancelAction={true}
						showDeleteAction={true}
						deleteAction={handleDeletePolygon}
					>
						<div className="modal-message">Are you sure you want to delete {deletePolygon.title}?</div>
					</Modal>
				</div>
			</FormSidebar>
		</div>
	);
};
export default ManagePolygons;
