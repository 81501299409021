import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { CatalogueVerificationList } from "./CatalogueVerificationList";

const CatalogueVerificationContainer = ({ hasAccess = false }) => {
	return (
		<div className="catalogue-verification-container">
			<Route path="/verification" component={CatalogueVerificationList} />
		</div>
	);
};
export default CatalogueVerificationContainer;
