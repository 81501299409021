import React, { useState, useCallback, useEffect, useRef } from "react";

// components;
import { SelectFilter } from "../_commons/SelectFilter";
import { Paginator } from "../_commons/Paginator";
import OrderClassificationComponent from "./OrderClassificationComponent";

// third party
import { useTrail, config } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { capitaliseText, commifyNumbers, lengthAdjust, printCurrency, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { PAYOUT_SHEET_ORDERS } from "../../graphql/reconciliation";

// actions
import { ActionTypes } from "../../actions/_types";

// config
import { RECONCILIATION_ORDERS_HEADER_FIELDS } from "../../client-config";

// constant
const FILTER_INITIAL_STATE = {
	reconciliationStatus: [],
	orderStatus: ""
};
const RECONCILIATION_STATUS_MAPS = {
	open: "Missing in Atlas",
	resolved: "Manually Reconciled",
	closed: "Manually Reconciled",
	missing: "Missing in Payout",
	inconsistent: "Inconsistent,"
};

const FILTER_OPTIONS = {
	reconciliationStatus: [
		{
			value: "reconciled",
			valueForDisplay: "Reconciled"
		},
		{
			value: "resolved",
			valueForDisplay: "Manually Reconciled"
		},
		{
			value: "missing",
			valueForDisplay: "Missing in Payout"
		},
		{
			value: "inconsistent",
			valueForDisplay: "Inconsistent"
		},
		{
			value: "open",
			valueForDisplay: "Missing in Atlas"
		}
	],
	orderStatus: [
		{
			value: "delivered",
			valueForDisplay: "Delivered"
		},
		{
			value: "cancelled",
			valueForDisplay: "Cancelled"
		}
	]
};

const Orders = ({
	currencySymbol = "&#36;",
	id,
	setShowOverflowMenu,
	handleNestedEntity,
	reconStatusActionTriggered,
	handleMouseEnter,
	handleMouseLeave,
	processingStatus
}) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const limit = 10;
	const [offset, setOffset] = useState(0);
	const [categoryfilters, setCategoryfilters] = useState(FILTER_OPTIONS);
	const [data, setData] = useState({ objects: [], count: 0 });

	const fetchPayoutOrders = useCallback(async () => {
		setLoading(true);
		const variables = {
			id,
			limit: limit,
			offset: offset,
			filters: []
		};
		if (currFilters?.reconciliationStatus?.length > 0) {
			let reconStatusString = "";
			currFilters.reconciliationStatus.forEach((status, i) => {
				if (i === 0) {
					reconStatusString += status.value;
				} else {
					reconStatusString += "," + status.value;
				}
			});
			variables.filters.push({ field: "reconciliation_status", value: reconStatusString });
		}
		if (currFilters?.orderStatus?.value) {
			variables.filters.push({
				field: "order_status",
				value: currFilters?.orderStatus && currFilters.orderStatus?.value
			});
		}
		try {
			const resp = await client.query({
				query: PAYOUT_SHEET_ORDERS,
				variables,
				fetchPolicy: "no-cache"
			});

			if (resp?.data?.payoutSheet?.orders?.objects) {
				setData({
					...data,
					objects: resp?.data?.payoutSheet?.orders?.objects,
					count: resp?.data?.payoutSheet?.orders?.count || 0
				});
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			setLoading(false);
		}
	}, [currFilters, offset, reconStatusActionTriggered]);

	useEffect(() => {
		fetchPayoutOrders();
	}, [currFilters, offset, reconStatusActionTriggered]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	return (
		<div className={"order-details-tab" + (loading ? " no-click" : "")}>
			<OrderClassificationComponent
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				processingStatus={processingStatus}
			/>
			<div className="search-container">
				<SelectFilter
					title="Order State"
					options={categoryfilters.orderStatus || []}
					field={"orderStatus"}
					currValue={currFilters.orderStatus || ""}
					setFilter={setFilter}
					labelKey="valueForDisplay"
					valueKey="value"
				/>
				<SelectFilter
					title="Reconciliation Status"
					options={categoryfilters.reconciliationStatus || []}
					field={"reconciliationStatus"}
					currValue={currFilters.reconciliationStatus || ""}
					setFilter={setFilter}
					labelKey="valueForDisplay"
					valueKey="value"
					multi={true}
				/>
			</div>
			<Table
				data={data?.objects}
				loading={loading}
				currencySymbol={currencySymbol}
				setShowOverflowMenu={setShowOverflowMenu}
				handleNestedEntity={handleNestedEntity}
			/>
			<Paginator limit={limit} offset={offset} count={data?.count} goToPage={handlePagination} />
		</div>
	);
};
export default Orders;

export const Table = ({ data, loading, sortList, sortedField, currencySymbol, handleNestedEntity }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	const [zIndex, setZIndex] = useState(1);
	return (
		<React.Fragment>
			<div
				className={
					(data.length > 0 && loading ? "disabled" : "") +
					" transaction-table-holder common-table-container items-edit-related-table-container"
				}
			>
				<div className="transactions-list-table bordered">
					<div className="at-table-row-based">
						<TableHeader
							sortList={sortList}
							sortedField={sortedField}
							headerFields={RECONCILIATION_ORDERS_HEADER_FIELDS}
							zIndex={zIndex}
						/>
						{trails.map(({ rotate }, i) => (
							<TableList
								i={i}
								setZIndex={setZIndex}
								key={i}
								currencySymbol={currencySymbol}
								handleNestedEntity={handleNestedEntity}
								style={{
									transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
								}}
								{...data[i]}
							/>
						))}
						{data.length === 0 && !loading && (
							<div className="no-items-placeholder">No Payout orders found!</div>
						)}
						{data.length === 0 && loading && (
							<div className="P(10px)">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const TableHeader = ({ sortList, headerFields, zIndex }) => (
	<div className={`at-table-row transaction-header-row items-list-table`} style={{ zIndex: zIndex }}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	handleNestedEntity,
	setZIndex,
	id,
	upId,
	externalId,
	orderStatus,
	orderTotal,
	reconciliationStatus,
	itemTitle,
	currencySymbol,
	inconsistencies = []
}) => {
	const [show, setShow] = useState(false);
	const timerRef = useRef(null);

	const handleMouseOver = () => {
		if (timerRef?.current) {
			clearInterval(timerRef.current);
		}
		setShow(true);
		setZIndex(0);
		trackEvent("reconciliation_orders_list_view_more_hover", {});
	};

	const handleMouseOut = () => {
		timerRef.current = setTimeout(() => {
			setShow(false);
			setZIndex(1);
		}, 300);
	};

	return (
		<React.Fragment>
			<div className="at-table-row transaction-rows items-list-table">
				<div className="at-table-cell at-cell-text title" title={itemTitle}>
					<div className="order-id">
						<a
							role="button"
							className={upId ? "link-text" : "link-text-non-clickable"}
							onClick={
								upId
									? () => {
											handleNestedEntity(true, "order", parseInt(upId));
									  }
									: () => {}
							}
						>
							{lengthAdjust(upId || "--", 15)}
						</a>
						<div className="external-id">{externalId || "--"}</div>
					</div>
				</div>
				<div className="at-table-cell at-cell-text title" title={itemTitle}>
					<div className="order-status">
						<a role="button">{orderStatus}</a>
					</div>
				</div>
				<div className="at-table-cell at-cell-text category">
					{printCurrency(currencySymbol) || ""} &nbsp;
					{commifyNumbers(orderTotal) || 0}
				</div>
				<div className="at-table-cell at-cell-text price reconciliation-status">
					<span className={` ${reconciliationStatus}`}>
						{(reconciliationStatus &&
							capitaliseText(RECONCILIATION_STATUS_MAPS[reconciliationStatus] || reconciliationStatus)) ||
							"--"}
					</span>
					<div className="inconsistencies"></div>
					{inconsistencies.length > 0 && (
						<span className="overflow-handler" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
							<span className="overflow-icon">View more</span>
							{
								<div
									className="overflow-content"
									style={{ display: show ? "block" : "none" }}
									onMouseOver={handleMouseOver}
								>
									{inconsistencies.map((inconsistency, i) => (
										<>
											<div key={i}>{inconsistency?.value}</div>
											<br />
										</>
									))}
								</div>
							}
						</span>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};
