import gql from "graphql-tag";

export const UPDATE_ENTITY_ILGPS = gql`
	mutation updateEntityILGPS($entityId: Int!, $entityType: ILGPAssociationEntityType!, $ilgps: [ILGPInput]!) {
		updateEntityIlgps(input: { entityId: $entityId, entityType: $entityType, ilgps: $ilgps }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
