import React from "react";

const SpecificItemPlatfromCell = ({ props }) => {
	const total = props.value.total;
	const available = props.value.available;
	let displayImage;
	if (total == available && total !== 0 && available !== 0) {
		displayImage = (
			<>
				<span className="specific-item-online-img">
					<img src="/assets/periscope/online.svg" className="online-image" />
				</span>
				<span className="specific-item-online-green-img">
					<img src="/assets/periscope/online-green.svg" className="online-image" />
				</span>
			</>
		);
	} else if (total == 0) {
		displayImage = <img src="/assets/periscope/partiallyOffline.svg" className="specific-item-partially-offline" />;
	} else {
		displayImage = <img src="/assets/periscope/offline.svg" className="specific-item-offline-img" />;
	}
	return <div style={{ display: "flex", justifyContent: "end" }}>{displayImage}</div>;
};

export default SpecificItemPlatfromCell;
