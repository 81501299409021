// utils
import { getSortOrder } from "../atlas-utils";

// action types
import { ActionTypes } from "../actions/_types";

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "title",
		order: "ASC"
	},
	sortedField: "title",
	currentFilters: {},
	appliedFilters: {}
};

export const chargesListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CHARGES_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.CHARGES_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.CHARGES_LIST_STATE_RESET:
			return INITIAL_STATE;
		case ActionTypes.CHARGES_LIST_UPDATE_ITEMS_LOOKUP:
			return {
				...state,
				itemsLookup: {
					...action.payload
				}
			};
		case ActionTypes.CHARGES_LIST_UPDATE_STORES_LOOKUP:
			return {
				...state,
				storesLookup: {
					...action.payload
				}
			};
		default:
			return state;
	}
};

const CHARGES_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: []
	},
	error: false
};
export const chargesList = (state = CHARGES_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CHARGES_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CHARGES_LIST_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case ActionTypes.GET_CHARGES_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CHARGES_LIST:
			let foundInList = false;
			const updatedCharges = state.data.objects.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedCharges.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedCharges,
					count: state.data.count + 1
				}
			};
		default:
			return state;
	}
};

export const CHARGE_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	backupData: {},
	error: {}
};
export const chargeDetailsReducer = (state = CHARGE_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CHARGE_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_CHARGE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				backupData: action.payload
			};
		case ActionTypes.GET_CHARGE_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CHARGE_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_CHARGE_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_CHARGE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				backupData: state.data
			};
		case ActionTypes.EDIT_CHARGE_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
