import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import Loadable from "react-loadable";

// constants
import { MESSAGE_TYPES } from "../../client-config";
import { ActionTypes } from "../../actions/_types";

// clients
import { store } from "../../store/configureStore";
import { client } from "../../client";

// components
import Select from "react-select-animated";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import OverlaySidebar from "../_commons/OverlaySidebar";
import { LoadingShimmer } from "../_commons/LoadingShimmer";

// graphql
import { SAVE_EMAIL_TEMPLATE } from "../../graphql/misc";

// Lazy loadable components
const TemplateEditor = Loadable({
	loader: () => import("./TemplateEditor"),
	render(loaded, props) {
		let Component = loaded.TemplateEditor;
		return <Component {...props} />;
	},
	loading: LoadingShimmer
});

@connect((store) => ({
	createCampaign: store.createCampaign,
	emailTemplate: store.emailTemplate
}))
export default class CampaignEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadHTML: false,
			templateEditor: false,
			showOverlaySidebar: false,
			htmlLoaded: false,
			emailsaving: false
		};
	}

	changeMType = (messageType) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				messageType: messageType.value
			}
		});
	};

	handleSubjectChange = (e) => {
		store.dispatch({
			type: "EMAIL_TEMPLATE_UPDATE",
			payload: {
				subject: e.target.value
			}
		});
	};

	openTestEMAILOverlay() {
		const {
			createCampaign: { message }
		} = this.props;
		store.dispatch({
			type: "SET_OVERLAY_SIDEBAR_STATE",
			payload: {
				active: true,
				medium: "EMAIL",
				formattedSMS: message
			}
		});
	}

	handleHTMLEmail = async () => {
		this.setState({
			emailsaving: true
		});
		let doc = document.getElementById("FileFrame").contentWindow.document;
		let htmlToSave = doc.getElementsByTagName("html")[0].innerHTML;
		store.dispatch({
			type: "EMAIL_TEMPLATE_UPDATE",
			payload: {
				html: htmlToSave
			}
		});
		await this.saveEmailTemplate(htmlToSave);
		this.setState({
			uploadHTML: false,
			showOverlaySidebar: false,
			emailsaving: false
		});
	};

	templateEditorSave = async (htmlToSave) => {
		this.setState({
			emailsaving: true
		});
		store.dispatch({
			type: "EMAIL_TEMPLATE_UPDATE",
			payload: {
				html: htmlToSave
			}
		});
		await this.saveEmailTemplate(htmlToSave);
		this.setState({
			templateEditor: false,
			showOverlaySidebar: false,
			emailsaving: false
		});
	};

	handleEmailFile = (e) => {
		var file = e.target.files[0];

		var reader = new FileReader();

		reader.onloadend = () => {
			let data = reader.result;
			// console.log(data)
			var doc = document.getElementById("FileFrame").contentWindow.document;
			doc.open();
			doc.write(data);
			doc.body.contentEditable = true;
			doc.close();
			this.setState({
				htmlLoaded: true
			});
		};

		// Read in the image file as a data URL.
		reader.readAsText(file);
	};

	handleHTMLopt = (opt) => {
		if (this.props.emailTemplate && this.props.emailTemplate.subject) {
			if (opt === "uploadHTML") {
				this.setState({
					uploadHTML: true,
					templateEditor: false,
					showOverlaySidebar: true
				});
			} else if (opt === "templateEditor") {
				this.setState({
					uploadHTML: false,
					templateEditor: true,
					showOverlaySidebar: true
				});
			}
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please enter email subject",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	closeSidebar = () => {
		this.setState({
			showOverlaySidebar: false,
			uploadHTML: false,
			templateEditor: false
		});
	};

	componentDidMount() {
		if (this.props.emailTemplate.html) {
			this.setState({
				htmlLoaded: true
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.emailTemplate.html) {
			this.setState({
				htmlLoaded: true
			});
		}
	}

	saveEmailTemplate = async (html) => {
		try {
			const { emailTemplate } = this.props;
			const variables = {
				html: html !== undefined ? html : emailTemplate.html,
				subject: emailTemplate.subject,
				isEditable: emailTemplate.isEditable
			};
			if (emailTemplate.id) {
				variables.id = emailTemplate.id;
			}
			const resp = await client.mutate({
				mutation: SAVE_EMAIL_TEMPLATE,
				variables
			});
			store.dispatch({
				type: "EMAIL_TEMPLATE_UPDATE",
				payload: resp.data.saveEmailTemplate.object
			});
			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					emailTemplateId: Number(resp.data.saveEmailTemplate.object.id)
				}
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Email template saved successfully",
					timeout: 5000,
					error: false
				}
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	render() {
		const { uploadHTML, templateEditor, showOverlaySidebar } = this.state;
		const {
			createCampaign: { messageType, emailTemplateId },
			emailTemplate: { subject, html }
		} = this.props;

		return (
			<div className="campaign-email">
				<div className="meta-info">Message Type</div>
				<Select
					searchable={false}
					className="at-dropdown"
					options={MESSAGE_TYPES}
					value={messageType}
					clearable={false}
					onChange={(m) => this.changeMType(m)}
				/>
				<InputWithLabel
					value={subject}
					onChange={this.handleSubjectChange}
					classes="subject-input"
					name="subject"
					placeholder="Type your email subject here."
				>
					Subject
				</InputWithLabel>
				<div className="meta-info">Email content</div>
				<div className="template-opts ">
					<span
						className="campaign-block-footer-text-container"
						onClick={() => this.handleHTMLopt("uploadHTML")}
					>
						{this.state.htmlLoaded ? <span>Preview / change email content</span> : <span>Upload HTML</span>}
					</span>
					{/* <span>{' '}|{' '}</span>
					<span
						className="campaign-block-footer-text-container"
						onClick={() => this.handleHTMLopt('templateEditor')}
					>
						Template editor
					</span> */}
				</div>
				<div className={(!emailTemplateId ? "disabled" : "") + " sms-send-test"}>
					<img src="/assets/icons/icon-sms.svg" style={{ transform: "translateY(2px)" }} />
					<span onClick={(e) => this.openTestEMAILOverlay(e)} className="Cur(p)">
						Send a test email
					</span>
				</div>
				<OverlaySidebar
					classes={templateEditor ? "template-editor-container" : "large"}
					showOverlaySidebar={showOverlaySidebar}
					close={this.closeSidebar}
				>
					{uploadHTML && (
						<UploadEmailHTML
							emailsaving={this.state.emailsaving}
							handleHTMLEmail={this.handleHTMLEmail}
							htmlLoaded={this.state.htmlLoaded}
							handleEmailFile={this.handleEmailFile}
							messageContent={html}
						/>
					)}
					{templateEditor && (
						<TemplateEditor
							emailsaving={this.state.emailsaving}
							templateEditorSave={this.templateEditorSave}
							messageContent={html}
							close={this.closeSidebar}
						/>
					)}
				</OverlaySidebar>
			</div>
		);
	}
}

class UploadEmailHTML extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		let frame = document.getElementById("FileFrame");
		if (frame) {
			var doc = frame.contentWindow.document;
			doc.open();
			if (this.props.messageContent) {
				doc.write(this.props.messageContent);
				doc.body.contentEditable = true;
			}
			doc.close();
		}
	}
	render() {
		return (
			<div className="overlay-sidebar-content">
				<div className="title">Preview</div>
				<div className="subtitle">Upload a HTML file to see preview.</div>
				<div className="Mt(10px)">
					<input type="file" onChange={this.props.handleEmailFile} />
				</div>
				<div className="main-content">
					<div
						style={{
							background: "#f2f2f2",
							border: "1px solid #ddd",
							height: "400px"
						}}
					>
						<iframe
							style={{
								height: "100%",
								width: "100%",
								border: "0px"
							}}
							id="FileFrame"
							src="about:blank"
						></iframe>
					</div>
					<div className="Mt(20px) Ta(c)">
						{this.props.emailsaving ? (
							<Button classes="disabled">Uploading..</Button>
						) : (
							<Button
								clickHandler={this.props.handleHTMLEmail}
								classes={this.props.htmlLoaded ? "" : "disabled"}
							>
								Save and continue
							</Button>
						)}
					</div>
				</div>
			</div>
		);
	}
}
