import React, { useState, useEffect } from "react";

// third party
import { useSpring, animated as a } from "react-spring";

// graphql
import { CREATE_LOCATION_WIZARD } from "../../graphql/locations";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// components
import { SuccessMessage } from ".";

// utils
import { trackEvent, triggerSurvey } from "../../atlas-utils";

// constants
import { ActionTypes } from "../../actions/_types";
import {
	CATALOGUE_PLATFORMS_LOGO,
	inspirationalQuotesWithAuthorName,
	funnyQuotesWithAuthor
} from "../../client-config";
const LOCATION_CREATE_STATUS_CHECK_API_URL = process.env.REACT_APP_LOCATION_CREATE_WIZARD_URL;

export const SendValuesAndAwaitResponse = ({
	locationAddType,
	city,
	nickName,
	address,
	phone,
	email,
	catalogueSettingMode,
	copyLocation,
	locationPolygon,
	locationRadius,
	locationLatLng,
	selectedPlatformAndUrl,
	storeTimings,
	platformLocationLinks,
	locationDetailsArray,
	handleSelectedTabUpdate,
	initialiseData,
	revertBackToLocationsList,
	handleFormData,
	referenceId,
	platforms,
	businessBasedDisabledFields,
	openLocationDetails
}) => {
	const [successStatus, setSuccessStatus] = useState(false);
	const [loading, setLoading] = useState(true);
	const [showStatusContainer, setShowStatusContainer] = useState(true);
	const [requestQueued, setRequestQueued] = useState(false);
	const [percentCompleted, setPercentCompleted] = useState(0);
	const [addedLocationsData, setAddedLocationsData] = useState([]);
	const [quotes, setQuotes] = useState([]);

	const totalNumberOfLocations = locationAddType === "single" ? 1 : locationDetailsArray.length;
	const totalNumberOfPlatforms = Object.keys(platforms).length;
	const LPAsCount = totalNumberOfPlatforms * totalNumberOfLocations;
	const platformImages = Object.keys(platformLocationLinks);

	const maxLimit = 128;
	let timeout = 1;
	let exponentialBackOffIntervalId = null;
	let perfStart = 0;
	let perfEnd = 0;

	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const statusCheckAddLocations = async (referenceId) => {
		try {
			const url = `${LOCATION_CREATE_STATUS_CHECK_API_URL}/?reference_id=${referenceId}`;
			const token = store.getState().login.loginDetail.token;
			const resp = await fetch(url, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			const data = await resp.json();
			if (data.status === "SUCCESS") {
				setPercentCompleted(100);
				setTimeout(() => {
					setAddedLocationsData([...data.locations_data]);
					setLoading(false);
					setSuccessStatus(true);
					handleFormData("referenceId", "");
				}, 1000);
				trackEvent("wiz_loc_creation_status", {
					status: "SUCCESS",
					totalNumberOfLocations,
					totalNumberOfPlatforms,
					LPAsCount
				});
				triggerSurvey("survey location creation wizard");
			} else if (data.status === "QUEUED" || data.status === "PROCESSING") {
				if (timeout <= maxLimit) {
					if (data.locations_data) {
						setPercentCompleted((data.locations_data.length / totalNumberOfLocations) * 100);
					}
					exponentialBackOffIntervalId = setTimeout(() => {
						statusCheckAddLocations(referenceId);
					}, timeout * 1000);
					timeout *= 2;
				} else {
					// we're considering the status to be changed from prcoessing to success with the set `maxLimit`
					// request to convey to the BE that FE has stopped the exponential backoff operation if it's still in queued state & cancel the operation
					const resp = fetch(`${LOCATION_CREATE_STATUS_CHECK_API_URL}`, {
						method: "POST",
						body: JSON.stringify({
							reference_id: referenceId,
							action: "CANCEL"
						}),
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					const data = resp.json();
					if (data.status === "PROCESSING") {
						timeout = 1;
						exponentialBackOffIntervalId = setTimeout(() => {
							statusCheckAddLocations(referenceId);
						}, timeout * 1000);
					} else if (data.status === "SUCCESS") {
						setPercentCompleted(100);
						setTimeout(() => {
							setLoading(false);
							setSuccessStatus(true);
							handleFormData("referenceId", "");
							clearInterval(exponentialBackOffIntervalId);
						}, 1000);
						trackEvent("wiz_loc_creation_status", {
							status: "SUCCESS",
							totalNumberOfLocations,
							totalNumberOfPlatforms,
							LPAsCount
						});
						triggerSurvey("survey location creation wizard");
					} else {
						setLoading(false);
						clearInterval(exponentialBackOffIntervalId);
						setSuccessStatus(false);
						trackEvent("wiz_loc_creation_status", {
							status: "FAILURE",
							totalNumberOfLocations,
							totalNumberOfPlatforms,
							LPAsCount
						});
						triggerSurvey("survey location creation wizard");
					}
				}
			} else {
				clearInterval(exponentialBackOffIntervalId);
				setLoading(false);
				setSuccessStatus(false);
				trackEvent("wiz_loc_creation_status", {
					status: "FAILURE",
					totalNumberOfLocations,
					totalNumberOfPlatforms,
					LPAsCount
				});
				triggerSurvey("survey location creation wizard");
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			setSuccessStatus(false);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Failed to add new locations.",
					timeout: 3000,
					error: true,
					errObject: error
				}
			});
			trackEvent("wiz_loc_creation_status", {
				status: "FAILURE",
				totalNumberOfLocations,
				totalNumberOfPlatforms,
				LPAsCount
			});
			triggerSurvey("survey location creation wizard");
		}
	};

	const addNewLocations = async () => {
		setShowStatusContainer(true);
		setLoading(true);
		const variables = {
			bizLocation: []
		};
		if (window.performance) {
			perfStart = window.performance.now();
		}
		if (locationAddType === "single") {
			let input = {
				bizLocationNickname: nickName,
				city: city,
				bizAddress: address,
				primaryContactPhone: phone ? String(phone) : null,
				primaryContactEmail: email,
				platforms: Object.keys(platformLocationLinks)
					.filter((platform) =>
						businessBasedDisabledFields.platformId[platform] ||
						businessBasedDisabledFields.platformUrl[platform]
							? platforms[platform]
							: platforms[platform] &&
							  platformLocationLinks[platform].id !== "" &&
							  platformLocationLinks[platform].url !== ""
					)
					.map((platform) => ({
						platformName: platform,
						outletUrl: businessBasedDisabledFields.platformUrl[platform]
							? ""
							: platformLocationLinks[platform].url,
						externalId: businessBasedDisabledFields.platformId[platform]
							? ""
							: platformLocationLinks[platform].id,
						testId: null
					})),
				sortOrder: 0,
				merchantBizLocationId: "-1",
				orderingSlots: Number(storeTimings?.id) || null,
				locLatitude: locationLatLng.lat || null,
				locLongitude: locationLatLng.lng || null,
				orderDeliveryRadius: locationRadius || null,
				polygons: [...(locationPolygon ?? [])]
			};
			if (platformLocationLinks.urbanpiper) {
				input.platforms.push({
					platformName: "urbanpiper",
					outletUrl: "",
					externalId: null,
					testId: null
				});
			}
			variables.bizLocation.push(input);
			if (copyLocation?.id) {
				variables.copyCatalogue = {
					fromLocation: copyLocation.id
				};
			}
		} else {
			locationDetailsArray.forEach((location, i) => {
				let input = {
					bizLocationNickname: location.locationName,
					city: location.city,
					bizAddress: location.address,
					primaryContactPhone: location.phone ? location.phone : null,
					primaryContactEmail: location.email,
					platforms: Object.keys(location.platformLocationLinks)
						.filter((platform) =>
							businessBasedDisabledFields.platformId[platform] ||
							businessBasedDisabledFields.platformUrl[platform]
								? platforms[platform]
								: platforms[platform] &&
								  location.platformLocationLinks[platform].id !== "" &&
								  location.platformLocationLinks[platform].url !== ""
						)
						.map((platform) => {
							return {
								platformName: platform,
								outletUrl: businessBasedDisabledFields.platformUrl[platform]
									? ""
									: location.platformLocationLinks[platform].url,
								externalId: businessBasedDisabledFields.platformId[platform]
									? ""
									: location.platformLocationLinks[platform].id,
								testId: null
							};
						}),
					sortOrder: 0,
					merchantBizLocationId: "-1",
					orderingSlots: Number(location?.storeTimings?.id) || null,
					locLatitude: location.locationLatLng.lat || null,
					locLongitude: location.locationLatLng.lng || null,
					orderDeliveryRadius: location.locationRadius || null,
					polygons: [...(location.locationPolygon ?? [])]
				};
				if (platformLocationLinks.urbanpiper) {
					input.platforms.push({
						platformName: "urbanpiper",
						outletUrl: null,
						externalId: null,
						testId: null
					});
				}
				variables.bizLocation.push(input);
			});
			if (copyLocation?.id) {
				variables.copyCatalogue = {
					fromLocation: copyLocation.id
				};
			}
		}
		try {
			const resp = await client.mutate({
				mutation: CREATE_LOCATION_WIZARD,
				variables
			});
			if (resp.data.createLocation.status.success) {
				const referenceIdReceived = resp?.data?.createLocation?.status?.messages[0]?.message?.split(": ")[1];
				if (referenceIdReceived) {
					statusCheckAddLocations(referenceIdReceived);
					handleFormData("referenceId", referenceIdReceived);
				}
				setRequestQueued(true);
			} else if (!resp.data.createLocation.status.success) {
				setLoading(false);
				setSuccessStatus(false);
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			setSuccessStatus(false);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong, failed to add new locations.",
					timeout: 3000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const repeatProcess = () => {
		setShowStatusContainer(true);
		setLoading(true);
		addNewLocations();
	};

	useEffect(() => {
		if (referenceId) {
			setShowStatusContainer(true);
			setLoading(true);
			setRequestQueued(true);
			statusCheckAddLocations(referenceId);
		} else {
			addNewLocations();
		}

		return () => {
			clearInterval(exponentialBackOffIntervalId);
		};
	}, []);

	// Fisher yates shuffle algorithm
	const randomize = (arr, n) => {
		for (let i = n - 1; i > 0; i--) {
			let j = Math.floor(Math.random() * (i + 1));
			[arr[i], arr[j]] = [arr[j], arr[i]];
		}
	};

	useEffect(() => {
		randomize(funnyQuotesWithAuthor, funnyQuotesWithAuthor.length);
		randomize(inspirationalQuotesWithAuthorName, inspirationalQuotesWithAuthorName.length);
		const finalQuotesArray = [];
		let indexInspirational = 0;
		let indexFunny = 0;
		for (let i = 0; i < 20; i++) {
			if (i === 0) {
				finalQuotesArray.push(inspirationalQuotesWithAuthorName[indexInspirational]);
				indexInspirational++;
			} else if (i % 2 === 0 && indexFunny < 5) {
				finalQuotesArray.push(funnyQuotesWithAuthor[indexFunny]);
				indexFunny++;
			} else {
				finalQuotesArray.push(inspirationalQuotesWithAuthorName[indexInspirational]);
				indexInspirational++;
			}
		}
		setQuotes([...finalQuotesArray]);
	}, []);

	return (
		<React.Fragment>
			{loading ? (
				<a.div className="common-box-structure " style={contentProps}>
					<div className="header width-adjust">
						<div className="quotes_container">
							{quotes.map((quote, i) => (
								<div className="quote_content" key={i}>
									<div className="quote">&#8220;{quote?.quote}&#8221;</div>
									<div className="author">-{quote?.author}</div>
								</div>
							))}
						</div>
					</div>
					<div className="progressbar-container">
						<div className="progress-bar">
							<div className="filler" style={{ width: `${percentCompleted}%` }}></div>
						</div>
					</div>
					<div className="processing-request">
						<div className="processing-anime">
							<div className="cubeDiv">
								<div className="cubeSpinnerContainer">
									<div className="shadowContainer">
										<div className="shadow">
											<div>&nbsp;</div>
										</div>
									</div>
									<div className="actualCubeContainer">
										<div className="cubeFaces">
											<div className="cubeFace cubeFaceFront">
												<img
													src={
														platformImages.length > 2
															? CATALOGUE_PLATFORMS_LOGO[platformImages[2]]
															: "/assets/icons/platform-swiggy.svg"
													}
													alt={`${
														platformImages.length > 2 ? platformImages[2] : "swiggy"
													} logo`}
													className="logo-size-adjust"
												/>
											</div>
											<div className="cubeFace cubeFaceBack">
												<img
													src={
														platformImages.length > 1
															? CATALOGUE_PLATFORMS_LOGO[platformImages[1]]
															: "/assets/icons/atlas-logo.svg"
													}
													alt={`${
														platformImages.length > 1 ? platformImages[1] : "atlas"
													} logo`}
													className="logo-size-adjust"
												/>
											</div>
											<div className="cubeFace cubeFaceTop">&nbsp;</div>
											<div className="cubeFace cubeFaceBottom">&nbsp;</div>
											<div className="cubeFace cubeFaceLeft">
												<img
													src={
														platformImages.length > 0
															? CATALOGUE_PLATFORMS_LOGO[platformImages[0]]
															: "/assets/left-nav-selected/icon-meraki.svg"
													}
													alt={`${
														platformImages.length > 0 ? platformImages[0] : "meraki"
													} logo`}
													className="logo-size-adjust"
												/>
											</div>
											<div className="cubeFace cubeFaceRight">
												<img
													src={
														platformImages.length > 3
															? CATALOGUE_PLATFORMS_LOGO[platformImages[3]]
															: "/assets/icons/platform-icon-zomato.svg"
													}
													alt={`${
														platformImages.length > 3 ? platformImages[3] : "zomato"
													} logo`}
													className="logo-size-adjust"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a.div>
			) : showStatusContainer ? (
				<SuccessMessage
					handleSelectedTabUpdate={handleSelectedTabUpdate}
					initialiseData={initialiseData}
					revertBackToLocationsList={revertBackToLocationsList}
					locationAddType={locationAddType}
					successStatus={successStatus}
					repeatProcess={repeatProcess}
					addedLocationsData={addedLocationsData}
					openLocationDetails={openLocationDetails}
				/>
			) : null}
		</React.Fragment>
	);
};
