import React, { useEffect } from "react";
import { ButtonIcon } from "../_commons/ButtonIcon";
import LightningIcon from "../_icons/LightningIcon";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "../_commons/Button";
import posthog from "posthog-js";
import InfoIcon from "../_icons/InfoIcon";
import { isFeatureFlagEnabled, scroll, trackEvent } from "../../atlas-utils";
import MailIcon from "../_icons/MailIcon";
import CalendarTime from "../_icons/CalendarTime";

const ScheduleFreeTrial = () => {
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (location?.pathname == "/reports/list/schedules/start-free-trial") {
			if (isFeatureFlagEnabled("schedule-reports-free-trial")) {
				history.push("/reports/list/schedules");
			}
		}
	}, [location?.pathname]);

	const handleSelect = () => {
		history.push("/reports/list/schedules/free-trial-plan");
		trackEvent("schedule_reports_free_trial_confirm");
		posthog.updateEarlyAccessFeatureEnrollment("schedule-reports-free-trial", true);
	};

	const handleBack = () => {
		history.push("/reports/list/schedules");
	};
	useEffect(() => {
		scroll();
	}, []);

	return (
		<div className="schedule-report-intro">
			<div className="free-trail-plan-wrap">
				<div className="free-trial-intro">
					<div className="heading">Get free trial</div>
					<div className="modal-plan">
						<div className="free-trail">
							<LightningIcon fillColor="#6D7AA8" />
							<div>
								<span className="your-plan">Your plan</span> (Free Trial)
							</div>
						</div>

						<div className="all-plans">
							<div className="plan-benefits">YOUR PLAN BENEFITS</div>

							<div className="plans-info">
								<div className="plans">
									<div className="svg">
										<CalendarTime styles={{ height: "20px", width: "20px" }} color={"#6D7AA8"} />
									</div>
									<div className="text">
										Create schedule <span className="text-bold">Unlimited</span>
									</div>
								</div>
								<div className="plans">
									<div className="svg">
										<MailIcon variations={{ width: "18px", height: "14px" }} color={"#6D7AA8"} />
									</div>
									<div className="text">
										Email notification recipient <span className="text-bold">Multiple</span>
									</div>
								</div>
								<div className="plans">
									<ButtonIcon classes="svg" icon="resume" color="#6D7AA8" />
									<div className="text">
										Pause / Resume schedules at <span className="text-bold">No Cost </span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="info-div">
						<InfoIcon fill="#3B5FEB" />
						Free trial access for limited time.
					</div>

					<div className="btns">
						<Button onClick={() => handleBack()} type="secondary">
							Back
						</Button>

						<Button onClick={handleSelect}>Confirm free trial</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ScheduleFreeTrial;
