import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import TimingGroupsList from "./TimingGroupsList";
import TimingGroupCreate from "./TimingGroupCreate";
import HolidayScheduleCreate from "./HolidayScheduleCreate";
import TimingGroupEdit from "./TimingGroupEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/TimingGroupsList/Header";
import HolidayScheduleEdit from "./HolidayScheduleEdit";

const TimingGroupsContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="entity-groups timing-groups">
			{restrictView ? (
				<Route
					path="/timing-groups"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/timing-groups" component={TimingGroupsList} />
					<Route
						exact
						path="/timing-groups/new"
						render={() =>
							hasAccess ? <TimingGroupCreate hasAccess={hasAccess} /> : <Redirect to="/timing-groups" />
						}
					/>
					<Route
						exact
						path="/timing-groups/holiday-new"
						render={() =>
							hasAccess ? (
								<HolidayScheduleCreate hasAccess={hasAccess} />
							) : (
								<Redirect to="/timing-groups" />
							)
						}
					/>
					<Route exact path="/timing-groups/edit/:id" component={TimingGroupEdit} />
					<Route exact path="/timing-groups/holiday-edit/:id" component={HolidayScheduleEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default TimingGroupsContainer;
