import React, { useState } from "react";

// components
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { Parameters } from "./Parameters";
import AssociatedEntities from "./AssociatedEntities";

// utils
import { adjustNestedContainer } from "../../atlas-utils";

// constants
const ENTITY_TYPES = {
	ispIncludeItems: {
		action: "isp",
		entity: "items",
		field: "PA_COUPON_ITEMS_LIST",
		paramType: "ITEMS_LIST",
		headerText: "Included Items",
		subHeaderText: "View and update items to be included while applying this coupon",
		tableFields: [
			{ label: "Name", value: "name" },
			{ label: "Category", value: "category" },
			{ label: "Price", value: "price" }
		]
	},
	ispExcludeItems: {
		action: "isp",
		entity: "items",
		field: "PA_COUPON_ITEMS_LIST",
		paramType: "ITEMS_LIST",
		headerText: "Excluded Items",
		subHeaderText: "View and update items to be excluded while applying this coupon",
		tableFields: [
			{ label: "Name", value: "name" },
			{ label: "Category", value: "category" },
			{ label: "Price", value: "price" }
		]
	},
	ispExcludeModifiers: {
		action: "isp",
		entity: "modifiers",
		field: "PA_COUPON_IGNORE_ITEM_OPTIONS",
		paramType: "",
		headerText: "Excluded Modifiers",
		subHeaderText: "View and update modifiers to be excluded while applying this coupon",
		tableFields: [
			{ label: "Name", value: "name" },
			{ label: "Price", value: "price" }
		]
	},
	bxgyItemsToCheck: {
		action: "bxgy",
		entity: "items",
		field: "PA_COUPON_FREEBIE_ITEMS_TO_CHECK",
		paramType: "ITEMS_TO_CHECK",
		headerText: "Items To Check",
		subHeaderText: '"Buy X" part of the BXGY coupon',
		tableFields: [
			{ label: "Name", value: "name" },
			{ label: "Category", value: "category" },
			{ label: "Price", value: "price" }
		]
	},
	bxgyItemsToDiscount: {
		action: "bxgy",
		entity: "items",
		field: "PA_COUPON_FREEBIE_ITEMS_TO_DISCOUNT",
		paramType: "ITEMS_TO_DISCOUNT",
		headerText: "Items To Discount",
		subHeaderText: '"Get Y" part of the BXGY coupon',
		tableFields: [
			{ label: "Name", value: "name" },
			{ label: "Category", value: "category" },
			{ label: "Price", value: "price" }
		]
	},
	bxgyExcludedModifiers: {
		action: "bxgy",
		entity: "modifiers",
		field: "PA_COUPON_IGNORE_ITEM_OPTIONS",
		paramType: "",
		headerText: "Excluded Modifiers",
		subHeaderText: "View and update modifiers to be excluded while applying this coupon",
		tableFields: [
			{ label: "Name", value: "name" },
			{ label: "Price", value: "price" }
		]
	}
};
const ENTITY_ASSOCIATIONS = {
	"Item Specific": [
		{
			condition: true,
			value: "false",
			headerText: "Included Items",
			subHeaderText: "Select the items to be included while applying this coupon",
			entityType: "ispIncludeItems"
		},
		{
			condition: true,
			value: "true",
			headerText: "Excluded Items",
			subHeaderText: "Select the items to be excluded while applying this coupon",
			entityType: "ispExcludeItems"
		}
		// {
		// 	condition: false,
		// 	headerText: 'Excluded Modifiers',
		// 	subHeaderText: 'Select the modifiers to be excluded while applying this coupon',
		// 	entityType: 'ispExcludeModifiers',
		// },
	],
	"Buy X Get Y": [
		{
			condition: false,
			headerText: "Items To Check",
			subHeaderText: "Select the items for Buy X part of BXGY coupon",
			entityType: "bxgyItemsToCheck"
		},
		{
			condition: false,
			headerText: "Items To Discount",
			subHeaderText: "Select the items for Get Y part of BXGY coupon",
			entityType: "bxgyItemsToDiscount"
		}
		// {
		// 	condition: false,
		// 	headerText: 'Excluded Modifiers',
		// 	subHeaderText: 'Select the modifiers to be excluded while applying this coupon',
		// 	entityType: 'bxgyExcludedModifiers',
		// },
	]
};

const Actions = ({
	couponId,
	couponActions = {},
	piperActions = [],
	handleForm,
	handleSubmit,
	loading,
	setModalBusy,
	isModalBusy,
	fetchCouponActions,
	isActionsFormTouched = false,
	saveActionsForm,
	currencySymbol,
	validations
}) => {
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [entityType, setEntityType] = useState(undefined);
	const openAssociationSidebar = (entityType) => {
		setEntityType(ENTITY_TYPES[entityType]);
		setAssociationSidebar(true);
		adjustNestedContainer(true);
	};

	const closeAssociationSidebar = () => {
		setEntityType(undefined);
		setAssociationSidebar(false);
		adjustNestedContainer(false);
	};

	const itemAssociation =
		Object.keys(couponActions).length &&
		couponActions.parameters.find((param) => param.paramName === "PA_COUPON_EXCLUDE_ITEMS")
			? couponActions.parameters.find((param) => param.paramName === "PA_COUPON_EXCLUDE_ITEMS").paramValue
			: undefined;

	return (
		<div className={"coupon-actions-tab" + (loading ? " no-click" : "")}>
			<div className="parameters form-row">
				<SelectFilter
					title="Action"
					options={piperActions}
					field="action"
					currValue={couponActions || null}
					setFilter={(field, value) => handleForm(field, value)}
					labelKey="uiText"
					valueKey="actionName"
					isClearable={false}
				/>
				{Object.keys(couponActions).length &&
					piperActions
						.find((pa) => pa.actionName === couponActions.actionName)
						.parameters.map((param, i) => (
							<Parameters
								key={i}
								index={i}
								parameter={param}
								paramValue={couponActions.parameters.find(
									(caParam) => param.name === caParam.paramName
								)}
								handleForm={(f, v, i) => handleForm(f, v, i, param)}
								useSwitch={true}
								validations={validations}
							/>
						))}
				{Object.keys(couponActions).length &&
					ENTITY_ASSOCIATIONS[couponActions.uiText]
						.filter((entity) => entity.condition === true)
						.map(
							(entity) =>
								itemAssociation &&
								entity.value === itemAssociation.value && (
									<div className="header-container">
										<div>
											<div className="header-text">{entity.headerText}</div>
											<div className="header-subtext">{entity.subHeaderText}</div>
										</div>
										<div>
											<div>
												<Button clickHandler={() => openAssociationSidebar(entity.entityType)}>
													View & Update
												</Button>
											</div>
										</div>
									</div>
								)
						)}
				{Object.keys(couponActions).length &&
					ENTITY_ASSOCIATIONS[couponActions.uiText]
						.filter((entity) => entity.condition === false)
						.map((entity) => (
							<div className="header-container">
								<div>
									<div className="header-text">{entity.headerText}</div>
									<div className="header-subtext">{entity.subHeaderText}</div>
								</div>
								<div>
									<div>
										<Button clickHandler={() => openAssociationSidebar(entity.entityType)}>
											View & Update
										</Button>
									</div>
								</div>
							</div>
						))}
			</div>
			<AssociatedEntities
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				submit={handleSubmit}
				couponId={couponId}
				pActionId={parseInt(couponActions.actionName)}
				entityType={entityType}
				data={
					entityType
						? couponActions.parameters.find((param) => param.paramName === entityType.field).paramValue
						: undefined
				}
				setModalBusy={setModalBusy}
				isModalBusy={isModalBusy}
				fetchCouponActions={fetchCouponActions}
				isActionsFormTouched={isActionsFormTouched}
				saveActionsForm={saveActionsForm}
				currencySymbol={currencySymbol}
			/>
		</div>
	);
};
export default Actions;
