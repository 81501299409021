import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { SelectFilter } from "../_commons/SelectFilter";

const BasicInfo = ({ data, options, handleForm, validations, charges, readOnly = true }) => {
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<InputWithLabel
					value={data.title}
					onChange={(e) => handleForm("title", e.target.value)}
					requiredLabel={true}
					validationMessage={validations.title || ""}
					readOnly={readOnly}
				>
					Name
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Applicable on"
					options={options}
					readOnly={readOnly}
					field="applicableOn"
					currValue={data.applicableOn}
					setFilter={handleForm}
					isSearchable={false}
					isClearable={false}
					labelKey="label"
					valueKey="value"
					requiredLabel={true}
					validationMessage={validations.applicableOn || ""}
				/>
				{data.applicableOn && data.applicableOn.value === options[1].value ? (
					<SelectFilter
						title="Charge"
						options={charges.items}
						readOnly={readOnly}
						isLoading={charges.isLoading}
						field="applicableCharge"
						currValue={charges.items.find((val) => val.id === data.applicableCharge) || null}
						setFilter={(field, value) => handleForm(field, value?.id || null)}
						labelKey="title"
						valueKey="id"
						requiredLabel={true}
						validationMessage={validations.applicableCharge || ""}
					/>
				) : (
					<InputWithLabel
						value={data.value}
						type="number"
						onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
						requiredLabel={true}
						validationMessage={validations.value || ""}
						showLabel={true}
						classes="at-input--label"
						symbol="%"
						readOnly={readOnly}
					>
						Tax Percentage
					</InputWithLabel>
				)}
			</div>
			{data.applicableOn && data.applicableOn.value === options[1].value && (
				<div className="form-row row-half">
					<InputWithLabel
						value={data.value}
						type="number"
						onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
						requiredLabel={true}
						validationMessage={validations.value || ""}
						showLabel={true}
						classes="at-input--label"
						symbol="%"
						readOnly={readOnly}
					>
						Tax Percentage
					</InputWithLabel>
				</div>
			)}
			<div className="form-row row-full">
				<Textarea
					value={data.description}
					onChange={(e) => handleForm("description", e.target.value)}
					readOnly={readOnly}
				>
					Description
				</Textarea>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.merchantRefId == "-1" ? "" : data.merchantRefId}
					onChange={(e) => handleForm("merchantRefId", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
					validationMessage={validations.merchantRefId || ""}
					readOnly={readOnly}
				>
					POS ID
				</InputWithLabel>
			</div>
		</React.Fragment>
	);
};
export default BasicInfo;
