import React, { useState, useEffect, useCallback } from "react";

// components
import Header from "./Header";
import Overview from "./Overview";

// third party
import { debounce } from "lodash";

// utils
import { lS } from "../../atlas-utils";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchReportDetail } from "../../actions/reports";

const ChooseReport = ({ reportsList, setSelectedStep, bizId }) => {
	const [currSearchFilter, setCurrSearchFilter] = useState("");
	const [applSearchFilter, setApplSearchFilter] = useState("");
	const [reports, setReports] = useState({
		count: 0,
		objects: []
	});
	const { loading, data } = reportsList;

	const handleReports = useCallback(() => {
		let count = data.reduce((val, g) => g?.reports?.length + val, 0);
		let reports = JSON.parse(JSON.stringify(data));
		if (applSearchFilter) {
			count = 0;
			const regex = new RegExp(applSearchFilter, "gi");
			reports = reports.map((g) => {
				const filteredRepors = g.reports?.filter(
					(r) => r.name.match(regex) !== null || r.description.match(regex) !== null
				);
				count += filteredRepors?.length;
				g.reports = filteredRepors;
				return g;
			});
		}
		setReports({
			count,
			objects: reports
		});
	}, [data, applSearchFilter]);

	useEffect(() => {
		handleReports();
	}, [handleReports]);

	const applySearchFilter = useCallback(
		debounce((searchKeywords) => {
			setApplSearchFilter(searchKeywords);
		}, 500),
		[]
	);

	const handleSearch = useCallback(
		(field, value) => {
			setCurrSearchFilter(value);
			applySearchFilter(value);
		},
		[applySearchFilter]
	);

	const handleSelectReport = (report, reportGroup) => {
		// update report detail state with selected report
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload: {
				data: {
					name: report.name,
					description: report.description,
					group: reportGroup
				},
				currentFilters: {
					showDataFor: {
						valueForDisplay: "Previous Day",
						value: "Previous Day"
					},
					scheduleFor: {
						valueForDisplay: "Every Day",
						value: "Every Day"
					}
				},
				appliedFilters: {
					showDataFor: {
						valueForDisplay: "Previous Day",
						value: "Previous Day"
					},
					scheduleFor: {
						valueForDisplay: "Every Day",
						value: "Every Day"
					}
				},
				emails: lS.get("report_emails") ? lS.get("report_emails")?.[bizId] || [] : []
			}
		});

		// fetch details of selected report
		fetchReportDetail(report.id);

		// go to next tab
		setSelectedStep((curr) => curr + 1);
	};

	return (
		<div className="choose-report">
			<Header
				title="Choose a report"
				showSearchFilter={true}
				currSearchFilter={currSearchFilter}
				handleSearch={handleSearch}
			/>
			<Overview
				reports={reports?.objects}
				loading={loading}
				searchKeyword={applSearchFilter}
				isRowClickable={true}
				rowClickHandler={handleSelectReport}
			/>
		</div>
	);
};
export default ChooseReport;
