import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/TaxEdit/BasicInfo";
import ItemsAndLocations from "../components/TaxEdit/ItemsAndLocations";

// client
import { store } from "../store/configureStore";

// utils
import history from "../history";
import { removeProp, adjustNestedContainer, findChangedKeysInTwoObjects, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchTaxesList, fetchTaxDetail, editTax } from "../actions/taxes";
import { fetchCiCharges } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// reducers
import { taxDetailsReducer, TAX_DETAILS_INITIAL_STATE } from "../reducers/taxes";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constants
import { NESTED_ENTITY_TYPES, TRACKING_EVENT_NAMES, TRACKING_SOURCE, TRACKING_STATUS } from "../client-config";
import { APPLICABLE_ON_OPTIONS } from "../containers/TaxCreate";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Items & Locations",
		value: "items-locations"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const TaxEdit = ({
	match,
	charges,
	access,
	readOnly = false,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [taxDetails, dispatch] = useReducer(taxDetailsReducer, TAX_DETAILS_INITIAL_STATE);
	const { loading, data, error, backupData } = taxDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiCharges();
	}, []);

	useEffect(() => {
		fetchTaxDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchTaxesList();
					history.push("/taxes");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value) => {
		dispatch({
			type: ActionTypes.UPDATE_TAX_DETAIL,
			payload: {
				[field]: value
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const setError = (error) => {
		dispatch({
			type: ActionTypes.EDIT_TAX_DETAIL_FAILURE,
			error
		});
	};

	const validateData = (applicableOnOption, data) => {
		if (applicableOnOption.value === APPLICABLE_ON_OPTIONS[1].value && !data.applicableCharge) {
			setError({
				fields: {
					applicableCharge: "This field is mandatory for taxes applicable on charge"
				}
			});
			return false;
		}
		return true;
	};

	const handleSubmit = async () => {
		const isValid = validateData(data.applicableOn, data);
		if (!isValid) {
			return;
		}
		const fieldsChanged = findChangedKeysInTwoObjects(data, backupData);
		const eventMeta = {
			fields_updated: fieldsChanged,
			num_of_fields_updated: fieldsChanged.length
		};

		const sanitisedData = removeProp(data, "__typename");
		const resp = await editTax(sanitisedData, dispatch);
		if (resp) {
			eventMeta.status = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.TAXES_BASIC_INFO_UPDATE, eventMeta);
			setFormTouched(false);
		} else {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.TAXES_BASIC_INFO_UPDATE, eventMeta);
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_TAX_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "taxes"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="tax-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Tax Rate"}
				subTitle="Edit this tax rate"
				submitTitle="Save"
				loading={loading || confirmLoading}
				showMoreBtn={formTab === FORM_TABS[0].value}
				numRows={data?.applicableOn?.value === APPLICABLE_ON_OPTIONS[1].value ? 4 : 3}
				hideActions={(formTab === FORM_TABS[0].value && !isFormTouched) || formTab === FORM_TABS[1].value}
				disabled={!isFormTouched}
				isNested={isNested}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && !readOnly && (
							<Button
								classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
								clickHandler={() => setArchiveRestore(true)}
							>
								{data.isActive ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							options={APPLICABLE_ON_OPTIONS}
							handleForm={handleForm}
							validations={error.fields || {}}
							charges={charges}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<ItemsAndLocations
							entityId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							setConfirmLoading={setConfirmLoading}
							handleNestedEntity={handleNestedEntity}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[5]}
						entityName={data.title}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						sourceOfTrigger={TRACKING_SOURCE.DETAIL_VIEW}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={!access.isCatalogueManagement || readOnly}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	charges: store.configItems.charges,
	access: store.login.loginDetail.access
});
export default connect(mapStateToProps)(TaxEdit);
