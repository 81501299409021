import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, printCurrency, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ENTITY_ITEMS } from "../../graphql/itemEntityAssociations";
import { UPDATE_LOCATION_ITEMS } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { FOOD_TYPE_MAP } from "../../client-config";
const FILTER_INITIAL_STATE = {
	item_title: "",
	item_discrete_category_id: undefined,
	tags: undefined,
	crm_title: ""
};
const ITEMS_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const LocationItemsAssociation = ({ entityId, entityType, isOpen, close, currencySymbol }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [categoryfilters, setCategoryfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [itemsData, setItemsData] = useState(ITEMS_INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [itemsUpdates, setItemsUpdates] = useState({});
	const [associateAll, setAssociateAll] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const [eventTracking, setEventTracking] = useState({
		type: "Location first",
		filters: {}
	});
	const tableRef = useRef();
	const totalChanges = Object.keys(itemsUpdates).length;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				entityId,
				entityType: entityType.value,
				limit,
				offset,
				sort: {
					field: "sort_order",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_ENTITY_ITEMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setItemsData(resp.data.entityItemAssociations);
			setCategoryfilters(
				resp.data.entityItemAssociations.filters.find((fl) => fl.field === "item_discrete_category_id")
			);
			setTagfilters(resp.data.entityItemAssociations.filters.find((fl) => fl.field === "tags"));
			setAllSelected(false);
			setAssociateAll(false);
			setShowAllFieldSelector(false);
			setIsCheckedAll(false);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [entityId, entityType, limit, offset, appliedFilters]);

	const applyFilter = useCallback(
		debounce((filters, field) => {
			setAppliedFilters(filters);
			// track filters which are being used
			setEventTracking({
				...eventTracking,
				filters: {
					...eventTracking["filters"],
					[field]: true
				}
			});
			setOffset(0);
		}, 500),
		[eventTracking]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters, field);
		},
		[currFilters, applyFilter]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setItemsData(ITEMS_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setItemsUpdates({});
			setIsCheckedAll(false);
			setEventTracking({
				type: "Location first",
				filters: {}
			});
			setShowAllFieldSelector(false);
			setAllSelected(false);
			setAssociateAll(false);
			close(refresh);
		},
		[close]
	);

	const handleUpdate = useCallback(async () => {
		try {
			setConfirmLoading(true);
			const variables = {
				locationIds: [entityId],
				itemsToAssociate: [],
				itemsToDisassociate: [],
				associateAll: associateAll
			};
			if (!associateAll) {
				for (let id in itemsUpdates) {
					if (itemsUpdates[id]) {
						variables.itemsToAssociate.push(parseInt(id));
					} else {
						variables.itemsToDisassociate.push(parseInt(id));
					}
				}
				// track location-items association
				const eventMeta = {
					...eventTracking,
					created: variables.itemsToAssociate.length,
					removed: variables.itemsToDisassociate.length
				};
				trackEvent("item_location_association", eventMeta);
			} else {
				// track location-items association
				const eventMeta = {
					...eventTracking,
					createdAll: itemsData.count
				};
				trackEvent("item_location_association", eventMeta);
			}
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			variables.filters = filtersObject;
			const resp = await client.mutate({
				mutation: UPDATE_LOCATION_ITEMS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateLocationItems.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Item association updated!",
						timeout: 2000,
						error: false
					}
				});
				handleClose(true);
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateLocationItems.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [entityId, itemsUpdates, handleClose, eventTracking]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setItemsUpdates({
			...itemsUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		itemsData.objects.forEach((item) => {
			updates[item.id] = toCheckAll;
		});
		setItemsUpdates({
			...itemsUpdates,
			...updates
		});
		let updatedItemsData = { ...itemsData };
		updatedItemsData.objects = updatedItemsData?.objects.map((item) => ({ ...item, isAssociated: toCheckAll }));
		setItemsData(updatedItemsData);
		if (toCheckAll && Math.ceil(itemsData.count / limit) > 1) {
			setShowAllFieldSelector(true);
		}
		if (!toCheckAll) {
			setShowAllFieldSelector(false);
		}
	};

	const handleCompleteSelection = (associateAll) => {
		setAllSelected(associateAll);
		handleCheckAll(associateAll);
		setAssociateAll(associateAll);
		if (!associateAll) {
			setShowAllFieldSelector(false);
		}
	};

	return (
		<div className="locations-items-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Items"
				subTitle={`Associate/ disassociate items to this location`}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
						{totalChanges === 0
							? "Update"
							: `Update ${allSelected ? itemsData.count : totalChanges} Item(s)`}
					</Button>
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-container">
						<InputWithLabel
							value={currFilters.item_title}
							onChange={(e) => setFilter("item_title", e.target.value)}
							placeholder="Enter Name"
						>
							Name
						</InputWithLabel>
						<SelectFilter
							title="Category"
							options={categoryfilters.values || []}
							field={categoryfilters.field || ""}
							currValue={currFilters.item_discrete_category_id || ""}
							setFilter={setFilter}
							labelKey="valueForDisplay"
							valueKey="value"
						/>
						<SelectFilter
							title="Tags"
							options={tagfilters.values || []}
							field={tagfilters.field || ""}
							currValue={currFilters.tags || ""}
							setFilter={setFilter}
							labelKey="valueForDisplay"
							valueKey="value"
						/>
					</div>
					<div className="search-container">
						<InputWithLabel
							value={currFilters.crm_title}
							onChange={(e) => setFilter("crm_title", e.target.value)}
							placeholder="Enter CRM Title"
						>
							CRM Title
						</InputWithLabel>
					</div>
					{showAllFieldSelector && (
						<SelectAllFields
							allSelected={allSelected}
							handleCompleteSelection={handleCompleteSelection}
							mainMessage={
								<span>
									{allSelected ? "All " : ""}
									<strong>
										{allSelected
											? itemsData.count
											: itemsData.objects.filter((loc) => loc?.isAssociated).length}{" "}
									</strong>{" "}
									item(s) {allSelected ? "on all pages are selected." : "on this page are selected."}
								</span>
							}
							linkMessage={
								allSelected ? (
									" Clear selection"
								) : (
									<span>
										{" "}
										Select <strong>{itemsData.count}</strong> items from all pages
									</span>
								)
							}
						/>
					)}
					<Table
						data={itemsData.objects}
						loading={loading}
						handleCheck={handleCheck}
						itemsUpdates={itemsUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						currencySymbol={currencySymbol}
						allSelected={allSelected}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={itemsData.count}
						goToPage={handlePagination}
						readOnly={allSelected}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default LocationItemsAssociation;

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	itemsUpdates,
	currencySymbol,
	allSelected
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && (loading || allSelected) ? "disabled" : "") +
				" transaction-table-holder common-table-container locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							itemsUpdates={itemsUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							currencySymbol={currencySymbol}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Items found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)}>
				Name
			</CheckBox>
		</div>
		<div className={`at-table-cell at-table-header at-header-text category`}>Category</div>
		<div className={`at-table-cell at-table-header at-header-text city`}>Price</div>
	</div>
);

export const TableList = ({
	id,
	itemTitle,
	foodType,
	crmTitle,
	tags,
	category,
	itemPrice,
	isAssociated,
	currencySymbol,
	style,
	handleCheck,
	itemsUpdates
}) => {
	const status = itemsUpdates[id] === undefined ? isAssociated : itemsUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name" title={itemTitle}>
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} title={itemTitle || "--"}>
					<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<span>{itemTitle || id}</span>
				</CheckBox>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
				<div className="text--light item_tags">
					{tags.length > 0 ? (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 3 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 3 && (
									<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
										+{tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text category">{category || "--"}</div>
			<div className="at-table-cell at-cell-text city">
				{printCurrency(currencySymbol)}
				{itemPrice || 0}
			</div>
		</animated.div>
	);
};
