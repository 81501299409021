import React from "react";

// components
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";

const BrandFilters = ({ handleActiveFilter, isActive, handleSearch, searchQuery }) => {
	return (
		<div className="brand-filters">
			<SelectFilter
				options={[
					{ value: true, valueForDisplay: "Active" },
					{ value: false, valueForDisplay: <span style={{ color: "#ff425c" }}>Archived</span> }
				]}
				field={"brand-state"}
				currValue={{
					value: "active",
					valueForDisplay: (
						<span>
							<span style={{ color: "#979797" }}>Status </span>
							{isActive ? "Active" : <span style={{ color: "#ff425c" }}>Archived</span>}
						</span>
					)
				}}
				labelKey="valueForDisplay"
				valueKey="value"
				setFilter={handleActiveFilter}
				isSearchable={false}
				isClearable={false}
			/>
			<SearchFilter
				placeholder="Search"
				filterOption={{
					field: "name"
				}}
				setFilter={handleSearch}
				value={searchQuery}
			/>
		</div>
	);
};
export default BrandFilters;
