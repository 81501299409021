import React, { useCallback, useState, useEffect, useRef } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";

import Placeholder from "../_commons/Placeholder";

import { Switch } from "../_commons/Switch";
import { SelectFilter } from "../_commons/SelectFilter";
import DropdownCustom from "../_commons/DropdownCustom";
// third party
import { connect } from "react-redux";
import { fetchCiLocationGroups, fetchCiLocationGroupsDebounced } from "../../actions/actions";
import { fetchAnnouncements, checkBeforeCreateAnnouncements, putAnnouncement } from "../../actions/merakiWeb.js";

const paymentGatewayMap = {
	paytm: "PayTM",
	paypal: "PayPal",
	simpl: "Simpl"
};

const Announcement = ({
	paymentConfigList = [],
	selectedLocation = {},
	locationGroups,
	announcementBox,
	setAnnouncementBox,
	announcementData,
	setAnnouncementData,
	handleAnnouncementForm,
	validationMessage,
	colorDropDown,
	setColorDropDown,
	setEditAnnouncementId,
	setIsDisableModal,
	setConfirmationModal,
	announcementAll,
	setAnnouncementAll,
	setEditIdValue,
	setCollsionvalue,
	setEnableCreateTitle,
	setDisableDeleteTitle,
	setRealEdit,
	setValidationMessage,
	setAnnouncementFormTouched,
	setDeleteModal,
	defaultBackgroundColor
}) => {
	const [filteredPaymentConfigList, setFilteredPaymentConfigList] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [announcementTextColor, setAnnoncementTextColor] = useState("#ffffff");
	const [shimmer, setShimmer] = useState();

	const getData = async () => {
		setShimmer(true);
		const resp = await fetchAnnouncements();
		if (!resp?.success) {
			setShimmer(false);
			return;
		}
		setAnnouncementAll(resp.data);
		setShimmer(false);
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		setFilteredPaymentConfigList(
			paymentConfigList.filter((pg) => selectedLocation.id === "default" || pg.supportLocationWiseConfig === true)
		);
	}, [selectedLocation]);
	useEffect(() => {
		fetchCiLocationGroups();
	}, []);
	useEffect(() => {
		if (announcementBox) setAnnouncementFormTouched(true);
	}, [colorDropDown]);
	useEffect(() => {
		const hexCode = colorDropDown;
		const red = parseInt(hexCode.slice(1, 3), 16);
		const green = parseInt(hexCode.slice(3, 5), 16);
		const blue = parseInt(hexCode.slice(5, 7), 16);
		const brightness = Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue));
		if (brightness > 127.5) {
			setAnnoncementTextColor("#000000");
		} else {
			setAnnoncementTextColor("#ffffff");
		}
	}, [colorDropDown]);

	const handleSupportedLocations = useCallback(() => {
		const supportedLocations = locationGroups.items.map((loc) => ({
			...loc,
			valueForDisplay: locationGroups.items.find((l) => l.value === loc.value)
				? locationGroups.items.find((l) => l.value === loc.value).valueForDisplay
				: loc.valueForDisplay
		}));
		return supportedLocations;
	}, [locationGroups]);

	const handleEdit = async (announcementValue) => {
		setValidationMessage({});
		setColorDropDown(announcementValue.styles.color);
		const currentLocation = await handleSupportedLocations();
		const displayLocation = [];
		for (let i = 0; i < announcementValue.location_groups.length; i++) {
			for (let j = 0; j < currentLocation.length; j++) {
				if (announcementValue.location_groups[i] == currentLocation[j].id) {
					displayLocation.push(currentLocation[j]);
				}
			}
		}

		setEditIdValue(announcementValue._id);
		setAnnouncementData({
			title: announcementValue.title,
			announcement: announcementValue.description[0].desc,
			url: announcementValue.url,
			styles: { color: `${colorDropDown}` },
			locationGroup: displayLocation,
			enabled: announcementValue.enabled
		});
		setRealEdit(true);
		setAnnouncementBox(true);
		setAnnouncementFormTouched(true);
		setEnableCreateTitle("Update");
	};

	const dropdownColorsOptions = [
		{
			id: "1",
			color: "#C2831C"
		},
		{
			id: "2",
			color: "#175751"
		},
		{
			id: "3",
			color: "#5C441D"
		},
		{
			id: "4",
			color: "#374F78"
		}
	];
	const colorPickerRef = React.createRef();
	function handleIconClick() {
		colorPickerRef.current.click();
	}
	const disableEnableDeleteModal = async (isEnable, type, announcementValue) => {
		if (type == "delete") {
			//for delete
			setAnnouncementData({
				title: announcementValue.title
			});
			await checkBeforeCreateAnnouncements(
				JSON.stringify({
					_id: announcementValue._id,
					title: announcementValue.title,
					description: [{ lang: "en", desc: announcementValue.description[0].desc }],
					styles: { color: `${colorDropDown}` },
					location_groups: announcementValue.location_groups,
					url: announcementValue.url
				})
			)
				.then(async (res) => {
					if (res.data.valid || !announcementValue.enabled) {
						setAnnouncementData({
							title: announcementValue.title
						});
						setDeleteModal(true);
						setEditIdValue(announcementValue._id);
						setAnnouncementBox(false);
						setAnnouncementFormTouched(false);
						return;
					} else {
						setCollsionvalue(res.data.overlapping_store_ids.length);
						setIsDisableModal(true);
						setDisableDeleteTitle("Delete");
						setEditAnnouncementId(true);
						setEditIdValue(announcementValue._id);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (type == "disable") {
			//for disable
			await checkBeforeCreateAnnouncements(
				JSON.stringify({
					_id: announcementValue._id,
					title: announcementValue.title,
					description: [{ lang: "en", desc: announcementValue.description[0].desc }],
					styles: { color: `${colorDropDown}` },
					location_groups: announcementValue.location_groups,
					url: announcementValue.url
				})
			)
				.then(async (res) => {
					if (res.data.valid) {
						await putAnnouncement({
							_id: announcementValue._id,
							title: announcementValue.title,
							description: [{ lang: "en", desc: announcementValue.description[0].desc }],
							styles: { color: `${colorDropDown}` },
							location_groups: announcementValue.location_groups,
							url: announcementValue.url,
							enabled: !announcementValue.enabled
						})
							.then(async (res) => {
								await fetchAnnouncements()
									.then((res) => {
										setAnnouncementAll(res.data);
									})
									.catch((err) => console.log(err));
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						setCollsionvalue(res.data.overlapping_store_ids.length);
						setAnnouncementData({
							id: announcementValue._id,
							title: announcementValue.title,
							description: [{ lang: "en", desc: announcementValue.description[0].desc }],
							styles: { color: `${colorDropDown}` },
							locationGroup: announcementValue.location_groups,
							url: announcementValue.url,
							enabled: announcementValue.enabled
						});
						setIsDisableModal(true);
						setDisableDeleteTitle("Disable");
						setEditAnnouncementId(true);
						setEditIdValue(announcementValue._id);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			//for enable
			await checkBeforeCreateAnnouncements(
				JSON.stringify({
					_id: announcementValue._id,
					title: announcementValue.title,
					description: [{ lang: "en", desc: announcementValue.description[0].desc }],
					styles: { color: `${colorDropDown}` },
					location_groups: announcementValue.location_groups,
					url: announcementValue.url
				})
			)
				.then(async (res) => {
					if (res.data.valid) {
						await putAnnouncement({
							_id: announcementValue._id,
							title: announcementValue.title,
							description: [{ lang: "en", desc: announcementValue.description[0].desc }],
							styles: { color: `${colorDropDown}` },
							location_groups: announcementValue.location_groups,
							url: announcementValue.url,
							enabled: !announcementValue.enabled
						})
							.then(async (res) => {
								await fetchAnnouncements()
									.then((res) => {
										setAnnouncementAll(res.data);
									})
									.catch((err) => console.log(err));
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						setEnableCreateTitle("enable");
						setCollsionvalue(res.data.overlapping_store_ids.length);
						setEditIdValue(announcementValue._id);
						setAnnouncementData({
							id: announcementValue._id,
							title: announcementValue.title,
							description: [{ lang: "en", desc: announcementValue.description[0].desc }],
							styles: { color: `${colorDropDown}` },
							locationGroup: announcementValue.location_groups,
							url: announcementValue.url,
							enabled: true
						});
						setEditAnnouncementId(true);
						setConfirmationModal(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}

		setAnnouncementBox(false);
	};

	if (shimmer) {
		return (
			<div className="announcement">
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}
	const colorDropdownComponent = () => {};
	return (
		<div className="announcement">
			{announcementAll.length === 0 && !announcementBox && (
				<Placeholder
					placeholderImageUrl="/assets/icons/empty-state-illustration.svg"
					placeholderText={"No Announcement yet"}
					placeholderButtonClickAction={() => {
						setAnnouncementBox(true);
						setAnnouncementData({
							title: "",
							announcement: "",
							url: "",
							styles: "",
							locationGroup: []
						});
						setAnnouncementFormTouched(true);
						setValidationMessage({});
						setColorDropDown(defaultBackgroundColor);
					}}
					placeholderButtonContent={"+ Create your first announcement"}
					//redirectionLink={''} needs to be added
					redirectionLinkText={"Learn more about announcements"}
					placeholderSubText={"View and manage all your announcements here"}
					size="medium"
				/>
			)}
			<Header announcementAll={announcementAll} />
			{announcementBox && (
				<AnnoumcementForm
					announcementData={announcementData}
					handleAnnouncementForm={handleAnnouncementForm}
					validationMessage={validationMessage}
					handleSupportedLocations={handleSupportedLocations}
					locationGroups={locationGroups}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					colorDropDown={colorDropDown}
					announcementTextColor={announcementTextColor}
					dropdownColorsOptions={dropdownColorsOptions}
					setColorDropDown={setColorDropDown}
					colorPickerRef={colorPickerRef}
					handleIconClick={handleIconClick}
				/>
			)}

			{announcementAll.map((announcementValue, index) => (
				<div announcement="" className="card-container">
					<div className="card-header">
						<div className="header-container">
							<div className="app-header">
								<div className="header-text">{announcementValue.title}</div>
								<div className="header-subtext">{announcementValue.description[0].desc}</div>
							</div>
						</div>
						<div className="announcementRight">
							<img
								src="/assets/icons/icon-edit-box.svg"
								alt=""
								onClick={() => {
									handleEdit(announcementValue);
								}}
							/>
							<img
								src="/assets/icons/icon-delete-box.svg"
								alt=""
								onClick={() => {
									disableEnableDeleteModal(announcementValue.enabled, "delete", announcementValue);
								}}
							/>
							<Switch
								checked={announcementValue.enabled}
								clickHandler={() =>
									announcementValue.enabled
										? disableEnableDeleteModal(
												announcementValue.enabled,
												"disable",
												announcementValue
										  )
										: disableEnableDeleteModal(
												announcementValue.enabled,
												"enable",
												announcementValue
										  )
								}
							/>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
const mapStateToProps = (store) => ({
	locationGroups: store.configItems.locationGroups
});
export default connect(mapStateToProps)(Announcement);

const Header = ({ announcementAll }) => {
	if (announcementAll.length === 0) {
		return null;
	}
	return (
		<div className="announcement-top-bar">
			<div className="all-announcement">All announcements</div>
			<div className="caution">
				<div className="caution-icon">
					<img src="/assets/icons/icon-caution.svg" alt="" />
				</div>
				<div className="caution-statement">
					If multiple announcements are configured for a location, only the latest will be displayed
				</div>
			</div>
		</div>
	);
};

const AnnoumcementForm = ({
	announcementData,
	handleAnnouncementForm,
	validationMessage,
	handleSupportedLocations,
	locationGroups,
	isOpen,
	setIsOpen,
	colorDropDown,
	announcementTextColor,
	dropdownColorsOptions,
	setColorDropDown,
	colorPickerRef,
	handleIconClick
}) => {
	return (
		<div announcement="form--info" className="card-container">
			<div className="form-container">
				<div className="form-row row-half">
					<InputWithLabel
						value={announcementData?.title}
						onChange={(e) => handleAnnouncementForm("title", e.target.value, 30)}
						requiredLabel={true}
						validationMessage={validationMessage?.title}
						characterLimit={30}
						showCharacterLimit={true}
						placeholder={"write the title"}
					>
						Title
					</InputWithLabel>

					<SelectFilter
						title="Location Groups"
						options={handleSupportedLocations()}
						isLoading={locationGroups.isLoading}
						field="locationGroup"
						currValue={announcementData?.locationGroup || []}
						setFilter={(field, value) => handleAnnouncementForm(field, value)}
						labelKey="title"
						valueKey="id"
						placeholder="Select location groups"
						isAsync={true}
						multi={true}
						handleSearch={fetchCiLocationGroupsDebounced}
						validationMessage={validationMessage?.locationGroup}
						requiredLabel
					/>
				</div>

				<div className="form-row row-full">
					<InputWithLabel
						value={announcementData?.announcement}
						onChange={(e) => handleAnnouncementForm("announcement", e.target.value, 80)}
						requiredLabel={true}
						validationMessage={validationMessage?.announcement}
						characterLimit={80}
						showCharacterLimit={true}
						placeholder="write your announcements here.."
					>
						Announcement
					</InputWithLabel>
				</div>

				<div className="form-row row-half bottom-row">
					<DropdownCustom
						title={"Styles"}
						clickEvent="mousedown"
						isOpen={isOpen}
						handleClick={() => setIsOpen(!isOpen)}
						handleOutsideClick={() => setIsOpen(false)}
						selected={announcementData?.announcement || "Your announcement will look like this"}
						align="left"
						selectedValueInlineStyles={{
							backgroundColor: colorDropDown,
							color: announcementTextColor,
							overflowX: "hidden"
						}}
					>
						<div className="dropdown-options-box ">
							<div className="default-color">DEFAULT STYLES</div>

							<div className="dropdown-options ">
								{dropdownColorsOptions.map((color, index) => (
									<div
										key={color.id}
										className="dropdown-option"
										style={{
											backgroundColor: color.color,
											paddingRight: "5px",
											overflow: "hidden",
											cursor: "pointer"
										}}
										onClick={() => {
											setColorDropDown(color.color);
											setIsOpen(false);
										}}
									>
										<div styles={{ backgroundColor: "blue" }}>
											{announcementData?.announcement || "Your announcement will look like this"}
										</div>
									</div>
								))}
							</div>
							<div className="custom-color">CUSTOM STYLE</div>
							<div className="add-color-icon">
								<input
									type="color"
									id="color-picker"
									name="color-picker"
									ref={colorPickerRef}
									value={colorDropDown}
									onChange={(e) => {
										setColorDropDown(`${e.target.value}`);
									}}
									style={{ height: "1px", width: "1px" }}
								/>
								<button
									type="button"
									onClick={handleIconClick}
									style={{
										background: "white",
										border: "none",
										cursor: "pointer",
										marginLeft: "-15px"
									}}
								>
									<img src="/assets/icons/icon-add-color.svg" />
								</button>

								<div className="add-color">ADD COLOR</div>
							</div>
						</div>
					</DropdownCustom>

					<InputWithLabel
						value={announcementData.url}
						onChange={(e) => handleAnnouncementForm("url", e.target.value)}
						validationMessage={validationMessage?.url}
						placeholder="https://www.example.mysite.com/offers"
					>
						URL
					</InputWithLabel>
				</div>
			</div>
		</div>
	);
};
