import React from "react";

// components
import { Textarea } from "../_commons/Textarea";

// third party
import { connect } from "react-redux";
import { getMerakiV2Flag } from "../../atlas-utils";

const Messages = ({ data = {}, handleForm, validations, biz }) => {
	const isMerakiV2Enabled = getMerakiV2Flag(biz);
	return (
		<div className="messages">
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-no-stores-around.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">No stores around</div>
							<div className="header-subtext">
								Message to be displayed for 'No stores around' scenario
							</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-full">
						<Textarea
							value={data.orderMsgNoStores}
							onChange={(e) => handleForm("orderMsgNoStores", e.target.value)}
							validationMessage={validations.orderMsgNoStores || ""}
						/>
					</div>
				</div>
			</div>
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-nearest-store-is-closed.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Nearest store is closed</div>
							<div className="header-subtext">
								Message to be displayed for 'Nearest store is closed' scenario
							</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-full">
						<Textarea
							value={data.orderMsgStoreClosed}
							onChange={(e) => handleForm("orderMsgStoreClosed", e.target.value)}
							validationMessage={validations.orderMsgStoreClosed || ""}
						/>
					</div>
				</div>
			</div>
			{!isMerakiV2Enabled && (
				<div className="card-container">
					<div className="card-header">
						<div className="header-container">
							<div className="app-icon">
								<img src="/assets/icons/icon-temporary-shutdown.svg" alt="" />
							</div>
							<div className="app-header">
								<div className="header-text">Temporary shutdown</div>
								<div className="header-subtext">
									Message to be displayed for 'Temporary shutdown' scenario
								</div>
							</div>
						</div>
					</div>
					<div className="form-container">
						<div className="form-row row-full">
							<Textarea
								value={data.orderMsgOrderingDisabled}
								onChange={(e) => handleForm("orderMsgOrderingDisabled", e.target.value)}
								validationMessage={validations.orderMsgOrderingDisabled || ""}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(Messages);
