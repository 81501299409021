import React from "react";
import { animated as a, useTrail, config } from "react-spring";

export const NumericalStepDisplay = ({
	count = 6,
	selectedValue = 3,
	vertical = false,
	hideCount = false,
	hideProgress = false,
	showProgressBar = false,
	title = "",
	renderContentRight = null
}) => {
	const trails = useTrail(count, {
		config: config.stiff,
		delay: (i) => i * 200,
		from: {
			opacity: 0
		},
		opacity: 1
	});

	if (showProgressBar) {
		return (
			<a.div className="progress-bar-display">
				<a.div className="info">
					<a.div className="title">{title}</a.div>
					{!hideProgress && (
						<a.div className="progress">
							{selectedValue} of {count}
						</a.div>
					)}
					{renderContentRight}
				</a.div>
				<a.div className="bars">
					{trails.map(({ opacity }, i) => (
						<React.Fragment key={i}>
							<a.div className="progress-bar--content" style={{ opacity }}>
								<div
									className="progress-bar--loader"
									style={{ width: i + 1 <= selectedValue ? "100%" : "0" }}
								></div>
							</a.div>
						</React.Fragment>
					))}
				</a.div>
			</a.div>
		);
	}
	return (
		<a.div className={"numerical-display" + (vertical ? " vertical" : "")}>
			{!hideCount && (
				<a.div className="step">
					Step {selectedValue}/{count}
				</a.div>
			)}
			<a.div className="display">
				{selectedValue <= count ? (
					trails.map(({ opacity }, i) => (
						<React.Fragment key={i}>
							<a.span
								className={`numerical-display--value ${
									selectedValue === i + 1 ? "selected" : selectedValue > i + 1 ? "previous" : ""
								}`}
								style={{ opacity }}
							>
								{!hideCount ? (
									<a.span style={{ opacity }} className="fill"></a.span>
								) : (
									<a.span style={{ opacity }}>{i + 1}</a.span>
								)}
							</a.span>
							{i !== count - 1 && (
								<a.span
									className={`numerical-display--divider ${selectedValue > i + 1 ? "passed" : ""}`}
									style={{ opacity }}
								></a.span>
							)}
						</React.Fragment>
					))
				) : (
					<></>
				)}
			</a.div>
		</a.div>
	);
};
