import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { LocationGroupsList } from "./LocationGroupsList";
import LocationGroupCreate from "./LocationGroupCreate";
import LocationGroupEdit from "./LocationGroupEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/LocationGroupsList/Header";

const LocationGroupsContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="entity-groups location-groups">
			{restrictView ? (
				<Route
					path="/location-groups"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/location-groups" component={LocationGroupsList} />
					<Route
						exact
						path="/location-groups/new"
						render={() =>
							hasAccess ? (
								<LocationGroupCreate hasAccess={hasAccess} />
							) : (
								<Redirect to="/location-groups" />
							)
						}
					/>
					<Route exact path="/location-groups/edit/:id" component={LocationGroupEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default LocationGroupsContainer;
