import React, { useState, useCallback, useEffect } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { FormSidebar } from "../_commons/FormSidebar";
import { Switch } from "../_commons/Switch";

// third party
import { connect } from "react-redux";
import { useTrail, config, animated } from "react-spring";
import { cloneDeep } from "lodash";
import SatismeterService from "../../services/SatismeterService";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { trackEvent } from "../../atlas-utils";

// graphql
import { GET_ITEM_LOCATION_DETAILS } from "../../graphql/locations";
import { UPDATE_MODIFIER_LOCATION_FIELDS } from "../../graphql/modifiers";
import { UPDATE_ITEM_LOCATION_FIELDS, UPDATE_ITEM_LOCATION_PLATFORM_FIELDS } from "../../graphql/items";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { FOOD_TYPE_MAP, NESTED_ENTITY_TYPES, TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../../client-config";

const ItemLocationCustomization = ({
	itemId,
	locationId,
	info,
	isOpen,
	close,
	biz,
	isInternationalMerchant = false,
	handleNestedEntity
}) => {
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [data, setData] = useState({});
	const [itemLocationFields, setItemLocationFields] = useState({ id: null });
	const [modifierLocationFields, setModifierLocationFields] = useState({});
	const [itemLocationPlatformFields, setItemLocationPlatformFields] = useState({});
	const [hasPriceChanged, setHasPriceChanged] = useState(false);
	const showSubmitAction =
		itemLocationFields.id ||
		Object.keys(modifierLocationFields).length > 0 ||
		Object.keys(itemLocationPlatformFields).length > 0;
	const [changedFields, setChangedFields] = useState([]);
	const priceFields = ["price", "externalPrice", "priceAtLocation"];

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				itemId,
				locationId: parseInt(locationId),
				filters: [
					{
						field: "location",
						value: locationId
					}
				]
			};
			const resp = await client.query({
				query: GET_ITEM_LOCATION_DETAILS,
				variables,
				fetchPolicy: "no-cache"
			});
			setData(resp.data.item);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [itemId, locationId]);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setData({});
			setItemLocationFields({ id: null });
			setModifierLocationFields({});
			setItemLocationPlatformFields({});
			close(refresh);
		},
		[close]
	);

	const sanitizeChangedFields = (field) => {
		if (!changedFields.includes(field)) {
			setChangedFields([...changedFields, field]);
		}
	};

	const handleItemLocationFields = (field, value) => {
		sanitizeChangedFields(field);
		// enforce positive value for these fields
		if (["sortOrder", "price", "externalPrice"].includes(field)) {
			if (value && value < 0) {
				value = 0;
			}
		}
		// for price fields, check if price has changed
		if (priceFields.includes(field)) {
			setHasPriceChanged(true);
		}

		const updatedPrice = {
			...data.associatedItemLocations.objects[0],
			[field]: value
		};
		setItemLocationFields(updatedPrice);
		const updatedData = cloneDeep(data);
		updatedData.associatedItemLocations.objects[0] = updatedPrice;
		setData(updatedData);
	};

	const handleModifierLocationFields = (id, field, value, modifierGroupId) => {
		sanitizeChangedFields(field);
		// for price fields, check if price has changed
		if (priceFields.includes(field)) {
			setHasPriceChanged(true);
		}
		let updatedModifier = {};
		const updatedData = cloneDeep(data);
		updatedData.optionGroups.objects = updatedData.optionGroups.objects.map((og) => {
			if (og.id === modifierGroupId) {
				og.modifiers = og.modifiers.map((modifier) => {
					if (modifier.id === id) {
						updatedModifier = {
							...modifier,
							[field]: value
						};
						return updatedModifier;
					}
					return modifier;
				});
				return og;
			}
			return og;
		});
		setData(updatedData);
		setModifierLocationFields({
			...modifierLocationFields,
			[id]: updatedModifier
		});
	};

	const handleItemLocationPlatformFields = (id, field, value) => {
		sanitizeChangedFields(field);
		// enforce positive value for these fields
		if (value && value < 0) {
			value = 0;
		}

		// for price fields, check if price has changed
		if (priceFields.includes(field)) {
			setHasPriceChanged(true);
		}

		const updatedData = cloneDeep(data);
		updatedData.associatedItemLocations.objects[0].associatedItemLocationPlatforms =
			updatedData.associatedItemLocations.objects[0].associatedItemLocationPlatforms.map((ilpa) => {
				if (ilpa.id === id) {
					ilpa[field] = value;
				}
				return ilpa;
			});
		setData(updatedData);
		setItemLocationPlatformFields({
			...itemLocationPlatformFields,
			[id]: value
		});
	};

	const saveModifierLocationFields = useCallback(
		async (modifierId, price, currentStock) => {
			try {
				const variables = {
					optionId: modifierId,
					olas: [
						{
							locationId,
							price,
							currentStock
						}
					]
				};
				const resp = await client.mutate({
					mutation: UPDATE_MODIFIER_LOCATION_FIELDS,
					variables
				});
				return Promise.resolve(resp);
			} catch (error) {
				throw error;
			}
		},
		[locationId]
	);

	const saveItemLocationFields = useCallback(async (object) => {
		const ilas = { ...object };
		delete ilas.associatedItemLocationPlatforms;
		try {
			const variables = {
				ilas: [ilas]
			};
			const resp = await client.mutate({
				mutation: UPDATE_ITEM_LOCATION_FIELDS,
				variables
			});
			return Promise.resolve(resp);
		} catch (error) {
			throw error;
		}
	}, []);

	const saveItemLocationPlatformFields = useCallback(async (object) => {
		try {
			const ilpas = Object.keys(object).map((id) => ({
				id: parseInt(id),
				price: parseFloat(object[id])
			}));
			const resp = await client.mutate({
				mutation: UPDATE_ITEM_LOCATION_PLATFORM_FIELDS,
				variables: { ilpas }
			});
			return Promise.resolve(resp);
		} catch (error) {
			throw error;
		}
	}, []);

	const handleSave = useCallback(async () => {
		setConfirmLoading(true);

		const eventMeta = {
			fields_updated: changedFields,
			num_of_fields_updated: changedFields.length
		};

		try {
			const requests = [];
			if (itemLocationFields.id) {
				if (!itemLocationFields.itemRefId) {
					itemLocationFields.itemRefId = -1;
				}
				if (itemLocationFields.__typename) {
					delete itemLocationFields.__typename;
				}
				requests.push(saveItemLocationFields(itemLocationFields));
			}
			if (Object.keys(modifierLocationFields).length > 0) {
				Object.keys(modifierLocationFields).forEach((modifierId) => {
					requests.push(
						saveModifierLocationFields(
							modifierId,
							modifierLocationFields[modifierId].priceAtLocation,
							modifierLocationFields[modifierId].currentStockAtLocation
						)
					);
				});
			}
			if (Object.keys(itemLocationPlatformFields).length > 0) {
				requests.push(saveItemLocationPlatformFields(itemLocationPlatformFields));
			}
			if (requests.length > 0) {
				const resp = await Promise.all(requests);
				let status = true;
				resp.forEach((res) => {
					const key = Object.keys(res?.data)?.[0];
					if (res?.data?.[key]?.status?.success === false && status) {
						status = false;
						store.dispatch({
							type: ActionTypes.SHOW_GLOBAL_MESSAGE,
							payload: {
								message:
									res?.data[key].status.messages[0].message ||
									"There was an error while saving Item customizations.",
								timeout: 3000,
								error: true
							}
						});
					}
				});
				if (status) {
					eventMeta.status = TRACKING_STATUS.SUCCESS;
					trackEvent(TRACKING_EVENT_NAMES.LOCATION_CATALOGUE_CUSTOMIZE, eventMeta);

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Item customizations saved!",
							timeout: 2000,
							error: false
						}
					});
					// reset state after successfully saving prices
					setItemLocationFields({ id: null });
					setModifierLocationFields({});
					setItemLocationPlatformFields({});

					if (hasPriceChanged) {
						// track price change event
						SatismeterService.menuPublish();
						setHasPriceChanged(false);
					}
				} else {
					eventMeta.status = TRACKING_STATUS.FAILURE;
					trackEvent(TRACKING_EVENT_NAMES.LOCATION_CATALOGUE_CUSTOMIZE, eventMeta);
				}
			}
		} catch (error) {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.LOCATION_CATALOGUE_CUSTOMIZE, eventMeta);

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setChangedFields([]);
		setConfirmLoading(false);
	}, [
		itemLocationFields,
		modifierLocationFields,
		itemLocationPlatformFields,
		saveItemLocationFields,
		saveModifierLocationFields,
		saveItemLocationPlatformFields
	]);

	const renderTitle = () => {
		if (info.source === "location" && data.itemTitle) {
			return (
				<div className="item-location-custom-title">
					<div className={`food-type ${FOOD_TYPE_MAP[data.foodType]}`} />
					<div className="item-title">{data.itemTitle}</div>
				</div>
			);
		} else if (info.source === "item") {
			return info.name;
		}
		return info.source === "item" ? "Location" : "Item";
	};

	const renderSubTitle = () => {
		if (info.source === "location") {
			return (
				<div>
					Customise this item for <b>{info.name}</b>
				</div>
			);
		} else if (info.source === "item" && data.itemTitle) {
			return (
				<div>
					Customise <b>{data.itemTitle}</b> for this location
				</div>
			);
		}
		return "Customise item for this location";
	};

	return (
		<div className="item-location-customization-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSave}
				title={renderTitle()}
				subTitle={renderSubTitle()}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={!showSubmitAction}
				isNested={true}
			>
				<div className="form-content">
					{!data.id && loading ? (
						<div className="P(10px 0)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					) : !data.id && !loading ? (
						<div className="no-items-placeholder">There was an error, Please try again!</div>
					) : (
						<React.Fragment>
							<ItemLocationFields
								currencySymbol={biz.currencySymbol}
								isInternationalMerchant={isInternationalMerchant}
								handleForm={handleItemLocationFields}
								handleIlpa={handleItemLocationPlatformFields}
								{...data.associatedItemLocations.objects[0]}
							/>
							{data.optionGroups.objects.map((modifierGroup, i) => (
								<ModifierPriceCustomization
									key={i}
									currencySymbol={biz.currencySymbol}
									handleNestedEntity={handleNestedEntity}
									handleForm={handleModifierLocationFields}
									{...modifierGroup}
								/>
							))}
						</React.Fragment>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail
}))(ItemLocationCustomization);

const ModifierPriceCustomization = ({
	id,
	isVariant,
	modifiers = [],
	optionGroupTitle,
	handleNestedEntity,
	currencySymbol,
	handleForm
}) => {
	const tableHeaderFields = [
		{ label: optionGroupTitle, value: "name" },
		{ label: "Stock count", value: "currentStockAtLocation" },
		{ label: "Price at location", value: "priceAtLocation" }
	];
	return (
		<div className="modifier-group-location-container">
			<div className="modifier-group-type">{`Customization (${isVariant ? "Variants" : "Add-ons"})`}</div>
			<div className="modifier-group-title">{optionGroupTitle}</div>
			<Table
				data={modifiers}
				handleNestedEntity={handleNestedEntity}
				currencySymbol={currencySymbol}
				handleForm={handleForm}
				headerFields={tableHeaderFields}
				modifierGroupId={id}
			/>
		</div>
	);
};

export const Table = ({
	data,
	currencySymbol,
	handleForm,
	sortList,
	sortedField,
	handleNestedEntity,
	headerFields,
	modifierGroupId
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div className="transaction-table-holder common-table-container modifier-location-price-table-container">
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader sortList={sortList} sortedField={sortedField} headerFields={headerFields} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							currencySymbol={currencySymbol}
							{...data[i]}
							handleForm={handleForm}
							handleNestedEntity={handleNestedEntity}
							modifierGroupId={modifierGroupId}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	optionTitle,
	priceAtLocation,
	currencySymbol,
	handleForm,
	style,
	handleNestedEntity,
	modifierGroupId,
	currentStockAtLocation,
	availableAtLocation
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name">
				<a
					role="button"
					className="link-text"
					onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[6], id)}
				>
					{optionTitle || id}
				</a>
				<div
					className="availableAtLocation"
					data-is-available={availableAtLocation}
					title={availableAtLocation ? "Available" : "Not available"}
				>
					{availableAtLocation ? "Available" : "Not available"}
				</div>
			</div>
			<div className="at-table-cell at-cell-text currentStockAtLocation">
				<InputWithLabel
					value={currentStockAtLocation}
					onChange={(e) =>
						handleForm(
							id,
							"currentStockAtLocation",
							e.target.value ? parseInt(e.target.value) : null,
							modifierGroupId
						)
					}
					type="number"
					placeholder="Stock count"
				/>
			</div>
			<div className="at-table-cell at-cell-text priceAtLocation">
				<InputWithLabel
					value={priceAtLocation}
					onChange={(e) =>
						handleForm(
							id,
							"priceAtLocation",
							e.target.value ? Number(e.target.value) : null,
							modifierGroupId
						)
					}
					type="number"
					placeholder="Enter Price"
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={currencySymbol}
				/>
			</div>
		</animated.div>
	);
};

const ItemLocationFields = ({
	isRecommended,
	price,
	externalPrice,
	itemRefId,
	handleForm,
	handleIlpa,
	currencySymbol,
	isInternationalMerchant,
	sortOrder,
	currentStock,
	associatedItemLocationPlatforms = []
}) => {
	return (
		<div className="item-details-container">
			<div className="dynamic-form-row row-half">
				<InputWithLabel
					value={price}
					onChange={(e) => handleForm("price", e.target.value ? Number(e.target.value) : null)}
					type="number"
					placeholder="Enter Price"
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={currencySymbol}
					requiredLabel={true}
				>
					Meraki price
				</InputWithLabel>
				{isInternationalMerchant ? (
					<React.Fragment>
						{associatedItemLocationPlatforms?.length > 0 &&
							associatedItemLocationPlatforms?.map((ilpa) => (
								<InputWithLabel
									value={ilpa.actualPrice}
									onChange={(e) =>
										handleIlpa(
											ilpa.id,
											"actualPrice",
											e.target.value ? Number(e.target.value) : null
										)
									}
									type="number"
									placeholder="Enter Price"
									showLabel={true}
									classes="at-input--label"
									currency={true}
									currencySymbol={currencySymbol}
								>
									{ilpa.bizPlatform.platformName} price
								</InputWithLabel>
							))}
					</React.Fragment>
				) : (
					<InputWithLabel
						value={externalPrice}
						onChange={(e) => handleForm("externalPrice", e.target.value ? Number(e.target.value) : null)}
						type="number"
						placeholder="Enter Price"
						showLabel={true}
						classes="at-input--label"
						currency={true}
						currencySymbol={currencySymbol}
						requiredLabel={true}
					>
						Hub price
					</InputWithLabel>
				)}
				<InputWithLabel
					value={sortOrder}
					type="number"
					onChange={(e) => handleForm("sortOrder", e.target.value ? parseInt(e.target.value) : null)}
				>
					Sort Order
				</InputWithLabel>
				<InputWithLabel
					value={currentStock}
					type="number"
					onChange={(e) => handleForm("currentStock", e.target.value ? parseInt(e.target.value) : null)}
				>
					Stock count
				</InputWithLabel>
				{/* <InputWithLabel
					value={itemRefId === '-1' ? '' : itemRefId}
					onChange={(e) => handleForm('itemRefId', e.target.value)}
				>
					POS ID
				</InputWithLabel> */}
				<Switch
					title="Is Recommended"
					checked={isRecommended}
					clickHandler={() => handleForm("isRecommended", !isRecommended)}
				/>
			</div>
		</div>
	);
};
