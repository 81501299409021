import React, { useState, useEffect, useCallback } from "react";

// components
import { CheckBox } from "../_commons/CheckBox";
import { CustomTable } from "../_commons/CustomTable";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// store
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../../actions/_types";

// contants
const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, rest) => (
			<div className="table-cell name" key={i}>
				{record.bizLocationNickname || ""}
			</div>
		)
	},
	{
		name: "City",
		field: "city",
		render: (record, i, rest) => (
			<div className="table-cell city" key={i}>
				{record.city.value || ""}
			</div>
		)
	},
	{
		name: "Brands",
		field: "brand",
		isRequired: true,
		render: (record, i, rest) => (
			<div className="table-cell brand" key={i}>
				<SelectFilterCustom
					options={rest.brands?.items?.filter((brand) => brand.id !== "all") || []}
					field="brands"
					currValue={record.brands || []}
					labelKey={"name"}
					valueKey={"id"}
					customLabel={true}
					renderLabel={rest.customMultiValueLabel}
					multi={true}
					showCheckBox={true}
					showSelectAllOption={false}
					optionUpdates={record.brandUpdates}
					handleCheck={(state, field, option, props) =>
						rest.handleCheck(state, field, option, props, rest.rowIndex)
					}
					placeholder="Select brands"
				/>
			</div>
		)
	}
];

const BrandsSelection = ({
	formData = {},
	handleFormData,
	isMulti = false,
	brands,
	validations = {},
	setValidations
}) => {
	const [brandUpdates, setBrandUpdates] = useState({});
	const [contextMenu, setContextMenu] = useState({
		id: null,
		data: {},
		props: {}
	});

	useEffect(() => {
		if (formData?.brands && formData?.brands?.length > 0) {
			const selectedBrands = {};
			formData.brands.forEach((brand) => {
				selectedBrands[brand.id] = true;
			});
			setBrandUpdates({
				...brandUpdates,
				...selectedBrands
			});
		}
	}, []);

	const handleSelect = useCallback(
		(brand, isSelected = false) => {
			setBrandUpdates({
				...brandUpdates,
				[brand.id]: isSelected
			});
			// update brands in formData
			let brands = formData.brands ? [...formData.brands] : [];
			if (isSelected) {
				brands.push({
					...brand,
					copyCatalogue: null,
					platforms: formData.platforms?.map((plf) => ({ ...plf, platformId: "", platformUrl: "" })) || []
				});
			} else {
				brands = [...brands].filter((brnd) => brnd.id !== brand.id);
			}
			handleFormData({ brands: brands });
			// remove brand in validations
			if (!isSelected) {
				let updatedValidations = { ...validations };
				if (updatedValidations.platforms) {
					delete updatedValidations.platforms[brand.id];
				}
				setValidations(updatedValidations);
			}
		},
		[handleFormData, formData, brandUpdates, validations, setValidations]
	);

	const handleCheck = useCallback(
		(state, field, option, props, index) => {
			const multiLocationDetails = formData.multiLocationDetails.map((loc, i) => {
				let location = { ...loc };
				if (i === index) {
					// update brands in location
					let brands = location.brands ? [...location.brands] : [];
					if (state) {
						brands.push({
							...option,
							copyCatalogue: null,
							platforms:
								formData.platforms?.map((plf) => ({ ...plf, platformId: "", platformUrl: "" })) || []
						});
					} else {
						brands = [...brands].filter((brand) => brand[props.valueKey] !== option[props.valueKey]);
					}
					location.brands = brands;
					if (location?.brandUpdates?.brands) {
						location["brandUpdates"] = {
							...location.brandUpdates,
							[field]: { ...location.brandUpdates[field], [option[props.valueKey]]: state }
						};
					} else {
						location["brandUpdates"] = { [field]: { [option[props.valueKey]]: state } };
					}
				}
				return location;
			});
			handleFormData({ multiLocationDetails: multiLocationDetails });
			// remove brand in validations
			if (!state) {
				let updatedValidations = { ...validations };
				Object.keys(updatedValidations).forEach((locId, i) => {
					if (i === index && updatedValidations?.[locId]?.platforms) {
						delete updatedValidations[locId].platforms[option[props.valueKey]];
					}
				});
				setValidations(updatedValidations);
			}
		},
		[formData, handleFormData, validations, setValidations]
	);

	const extractInitials = (name) => {
		if (!name) {
			return "";
		}
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(1);
		}
		return initials.toUpperCase();
	};

	const renderMultiValueLabel = (currValue) => {
		return (
			<React.Fragment>
				{currValue?.map?.((brand, i) => (
					<div className="brand-tag" key={i}>
						<div className={"brand-initials " + brand.color}>
							{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
						</div>
						<div className="brand-name">{brand?.name}</div>
					</div>
				))}
			</React.Fragment>
		);
	};

	const copyToAllLocations = useCallback(
		(data) => {
			if (data.brands.length === 0) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please select brand(s) first",
						timeout: 3000,
						error: true
					}
				});
				closeContextMenu();
				return;
			}
			const multiLocationDetails = formData.multiLocationDetails.map((loc) => {
				let location = { ...loc };
				// update brands in location
				location.brands = [...data.brands];
				// update brandUpdates in location
				let selectedBrands = {};
				data.brands.forEach((brand) => (selectedBrands[brand.id] = true));
				location.brandUpdates = { brands: { ...selectedBrands } };
				return location;
			});
			handleFormData({ multiLocationDetails: multiLocationDetails });

			// reset validations
			let updatedValidations = { ...validations };
			Object.keys(updatedValidations).forEach((locId) => {
				updatedValidations[locId].platforms = {};
			});
			setValidations(updatedValidations);

			closeContextMenu();
		},
		[formData, handleFormData, validations, setValidations]
	);

	const renderMenuItems = (data) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => copyToAllLocations(data)}>
					Copy to all locations
				</div>
			</React.Fragment>
		);
	};

	const openContextMenu = (data, props) => {
		setContextMenu({
			id: contextMenu.id === props.rowIndex ? null : props.rowIndex,
			data,
			props
		});
	};

	const closeContextMenu = () => {
		setContextMenu({
			id: null,
			data: {},
			props: {}
		});
	};

	return (
		<div className="brands-selection">
			<div className="section-title">Associate brands</div>
			{isMulti ? (
				<CustomTable
					columns={columns}
					data={formData?.multiLocationDetails || []}
					handleCheck={handleCheck}
					brands={brands}
					customMultiValueLabel={renderMultiValueLabel}
					showContextMenu={true}
					renderMenuItems={renderMenuItems}
					openContextMenu={openContextMenu}
					closeContextMenu={closeContextMenu}
					contextMenuId={contextMenu.id}
					classes="locations-brands-list-table-container"
				/>
			) : (
				<div className="brands">
					{brands.items.length > 0 &&
						brands.items
							?.filter((brand) => brand.id !== "all")
							?.map((brand, i) => (
								<div
									key={i}
									className={"brand-container" + (brandUpdates[brand.id] ? " selected" : "")}
									onClick={() => handleSelect(brand, !brandUpdates[brand.id])}
								>
									<CheckBox
										checked={brandUpdates[brand.id]}
										clickHandler={(e) => {
											handleSelect(brand, !brandUpdates[brand.id]);
											e.stopPropagation();
										}}
									/>
									<div className={"brand-initials " + brand.color}>
										{brand.image ? (
											<img src={brand.image} alt="" />
										) : (
											extractInitials(brand?.name?.split(" "))
										)}
									</div>
									<div className="brand-name" title={brand.name}>
										{brand.name}
									</div>
								</div>
							))}
					{brands.items.length === 0 && !brands.isLoading && (
						<div className="no-items-placeholder">No brands found</div>
					)}
					{brands.isLoading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	brands: store.configItems.brands
});
export default connect(mapStateToProps)(BrandsSelection);
