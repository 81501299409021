// action types
import { ActionTypes } from "../actions/_types";

export const HUB_CONFIGURATIONS_INITIAL_STATE = {
	loading: true,
	data: [],
	error: {},
	bizHubConfigurations: null
};
export const hubConfigurations = (state = HUB_CONFIGURATIONS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_HUB_CONFIGURATIONS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_HUB_CONFIGURATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				bizHubConfigurations: action?.isBizHubConfig ? action.payload : state.bizHubConfigurations
			};
		case ActionTypes.GET_HUB_CONFIGURATIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_HUB_CONFIGURATIONS:
			return {
				...state,
				data: state.data.map((platform) => {
					if (platform.name === action.payload.platform) {
						platform.keys.map((config) => {
							if (config.key === action.payload.field) {
								config.value = action.payload.value;
							}
							return config;
						});
					}
					return platform;
				})
			};
		case ActionTypes.EDIT_HUB_CONFIGURATIONS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_HUB_CONFIGURATIONS_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_HUB_CONFIGURATIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
