import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { APIAccountsList } from "./APIAccountsList";
import APIAccountEdit from "./APIAccountEdit";
import APIAccountCreate from "./APIAccountCreate";

const APIAccountsContainer = () => {
	return (
		<div className="api-access-management users">
			<Route path="/api-access" component={APIAccountsList} />
			<Route exact path="/api-access/new" component={APIAccountCreate} />
			<Route exact path="/api-access/edit/:id" component={APIAccountEdit} />
		</div>
	);
};
export default APIAccountsContainer;
