import React from "react";

const SkeletonLoader = ({ classname }) => {
	return (
		<div className={`shimmer-container ${classname || ""}`}>
			<div className="shimmer " />
		</div>
	);
};

export default SkeletonLoader;
