// constant
import {
	PRESET_TYPES,
	DATE_TYPES,
	COMPARE_DATE_TYPES,
	CUSTOM_TYPES
} from "../components/_commons/NewDateCompareFilter";

const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[0].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[0],
		customTypeSelected: CUSTOM_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};

const INITIAL_STATE = {
	showLowCreditsBar: false,
	showBuyCreditsFlow: false,
	showCreditsReloadSuccess: false,
	source: "campaign_list_view",
	isPreProcessing: false,
	preProcess: {
		invoiceId: null,
		grossAmount: 0,
		totalTax: 0,
		taxes: [],
		netAmount: 0
	},
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE,
	isRechargeDrawerRenderedInitially: false
};

export const creditsState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "CREDITS_STATE_UPDATE":
			return {
				...state,
				...action.payload
			};
		case "CREDITS_PRE_PROCESS_REQUEST":
			return {
				...state,
				isPreProcessing: true
			};
		case "CREDITS_PRE_PROCESS_SUCCESS":
			return {
				...state,
				isPreProcessing: false,
				...action.payload
			};
		case "CREDITS_PRE_PROCESS_FAILURE":
			return {
				...state,
				isPreProcessing: false
			};
		default:
			return state;
	}
};
