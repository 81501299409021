import React, { useState, useEffect } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { Textarea } from "../components/_commons/Textarea";

// third party
import { connect } from "react-redux";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchCiListGroups } from "../actions/actions";
import { fetchUsersList } from "../actions/users";
import { fetchPendingInvitesList } from "../actions/pendingInvites";
import { fetchStores, fetchStoresDebounced } from "../actions/actions";

// graphql
import { INVITE_USER } from "../graphql/users";

const UserInvite = ({ listGroups, user, currTab, locationsList }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({
		message: "",
		fields: {}
	});
	const [isInviteSuccess, setInviteSuccess] = useState(false);
	const [data, setData] = useState({
		email: "",
		roles: [],
		message: "",
		locations: []
	});
	const showLocations =
		data?.roles?.find((role) => role.id === "53" || role.id === "55" || role.id === "66") !== undefined
			? true
			: false;
	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiListGroups();
		fetchStores("", 50);
	}, []);

	const handleCancel = () => {
		setFormState(false);
		setFormTouched(false);
		if (currTab === "users") {
			fetchUsersList();
		} else {
			fetchPendingInvitesList();
		}
		setTimeout(() => {
			history.push("/access");
		}, 100);
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		// remove validation
		if (error?.fields?.[field]) {
			setError({
				...error,
				fields: {
					...(error.fields ?? {}),
					[field]: ""
				}
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		// check if any roles are added
		if (data?.roles?.length === 0) {
			setError({
				...error,
				fields: {
					...(error.fields ?? {}),
					roles: "This field is required."
				}
			});
			return;
		}
		setConfirmLoading(true);
		setError({});
		try {
			const variables = {
				...data
			};
			if (variables.roles) {
				const roles = variables.roles.map((grp) => "atlas_" + grp.name.toLowerCase().split(" ").join("_"));
				variables.roles = roles;
			}
			if (!showLocations) {
				variables.locations = [];
			} else {
				variables.locations = variables.locations.map((loc) => loc.id);
			}
			const resp = await client.mutate({
				mutation: INVITE_USER,
				variables
			});
			if (resp.data.userInvite.status.success) {
				setConfirmLoading(false);
				setInviteSuccess(true);
			} else {
				setConfirmLoading(false);
				// handle error message
				if (
					resp.data.userInvite.status.messages.length &&
					resp.data.userInvite.status.messages[0].field === null
				) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: resp.data.userInvite.status.messages[0].message,
							timeout: 3000,
							error: true
						}
					});
				} else {
					setError(parseErrorMessages(resp.data.userInvite.status.messages));
				}
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	const renderTitle = () => {
		if (isInviteSuccess) {
			const roles = data.roles.map((grp) => grp.name);
			return (
				<div className="header-container">
					<div>
						<div>{data.email}</div>
						<div className="subtitle">{roles.length ? roles.join(", ") : "No roles assigned"}</div>
					</div>
				</div>
			);
		}
		return "User Invite";
	};

	const validations = error.fields || {};

	return (
		<div className="user-invite-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={renderTitle()}
				subTitle={!isInviteSuccess ? "Create a new user invite" : ""}
				loading={confirmLoading}
				submitTitle="Invite"
				disabled={!isFormTouched}
				hideSubmitAction={isInviteSuccess}
				cancelTitle={isInviteSuccess ? "Dismiss" : "Cancel"}
				hideActions={!isFormTouched}
			>
				{!isInviteSuccess && (
					<div className="form-content">
						<div className="form-row row-half">
							<InputWithLabel
								value={data.email}
								onChange={(e) => handleForm("email", e.target.value)}
								requiredLabel={true}
								validationMessage={validations.email || ""}
							>
								Email ID
							</InputWithLabel>
							<SelectFilter
								title="Roles"
								options={listGroups.items}
								isLoading={listGroups.isLoading}
								field="roles"
								currValue={data.roles}
								setFilter={handleForm}
								multi={true}
								labelKey="name"
								valueKey="id"
								requiredLabel={true}
								validationMessage={validations.roles || ""}
							/>
						</div>
						{showLocations && (
							<div className="form-row row-half">
								<SelectFilter
									title="Locations"
									options={locationsList.items}
									isLoading={locationsList.isLoading}
									field="locations"
									currValue={data.locations}
									setFilter={handleForm}
									isAsync={true}
									isSearchable={true}
									handleSearch={fetchStoresDebounced}
									multi={true}
									labelKey="name"
									valueKey="id"
								/>
							</div>
						)}
						{user.email.includes("@urbanpiper.com") && (
							<div className="form-row row-full">
								<Textarea
									value={data.message}
									onChange={(e) => handleForm("message", e.target.value)}
									requiredLabel={true}
									validationMessage={validations.message || ""}
								>
									Purpose
								</Textarea>
							</div>
						)}
					</div>
				)}
				{isInviteSuccess && (
					<div className="form-content">
						<div className="success-message">Invite has been sent successfully.</div>
					</div>
				)}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	listGroups: store.configItems.listGroups,
	user: store.login.loginDetail,
	locationsList: store.configItems.stores
}))(UserInvite);
