import React, { useState, useEffect, useCallback } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CustomTable } from "../_commons/CustomTable";

// third party
import { connect } from "react-redux";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { printCurrency } from "../../atlas-utils";

// graphql
import { GET_LOCATION_CATALOGUE } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { FOOD_TYPE_MAP } from "../../client-config";
const columns = [
	{
		name: "Item",
		field: "item",
		render: (record, i) => (
			<div className="table-cell item" title={record.name} key={i}>
				<div className="item-image">
					<img
						src={record.imageUrl || "/assets/empty-dish.jpg"}
						alt="item image"
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
					/>
				</div>
				<div className="item-info">
					<span className={`food-type ${FOOD_TYPE_MAP[record.foodType]}`} />
					<span className="item-title">{record.itemTitle || record.id}</span>
				</div>
			</div>
		)
	},
	{
		name: "Price",
		field: "price",
		render: (record, i, rest) => (
			<div className="table-cell price" key={i}>
				{record.markupPrice > record.itemPrice && (
					<div className="markup-price">
						{printCurrency(rest.currencySymbol)}
						{record.markupPrice || 0}
					</div>
				)}
				<div>
					{printCurrency(rest.currencySymbol)}
					{record.itemPrice || 0}
				</div>
			</div>
		)
	}
];

const PreviewCatalogue = ({ isOpen = false, brand = {}, close, currencySymbol }) => {
	const { id, copyCatalogue } = brand;
	const [loading, setLoading] = useState(false);
	const [rootCategories, setRootCategories] = useState([]);
	const [selectedCat, setSelectedCat] = useState(undefined);

	const fetchData = useCallback(
		async (filters = {}) => {
			try {
				setLoading(true);
				const variables = {
					locations: [parseInt(copyCatalogue?.id)],
					available: true
				};
				if (id) {
					variables.brandId = id;
				}
				// handle filter on item name
				let filtersObject = [];
				if (filters.item_title) {
					filtersObject.push({
						field: "item_title",
						value: filters.item_title
					});
				}
				variables.filters = filtersObject;
				if (filters.available !== undefined) {
					variables.available = filters.available;
				}
				const resp = await client.query({
					query: GET_LOCATION_CATALOGUE,
					variables,
					fetchPolicy: "no-cache"
				});
				let rootCategories = resp.data.locationCatalogue.categories;
				setRootCategories(rootCategories);
				if (rootCategories.length > 0) {
					setSelectedCat(rootCategories[0].name);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[copyCatalogue, id]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const handleClose = useCallback(() => {
		setRootCategories([]);
		close();
	}, [close]);

	return (
		<div className="preview-catalogue">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				title={`${copyCatalogue?.name} Catalogue Preview` || "Catalogue Preview"}
				subTitle={`${rootCategories.length} categories` || ""}
				hideActions={true}
				isNested={false}
			>
				{rootCategories.length === 0 && !loading && (
					<div className="no-items-placeholder">No catalogue found!</div>
				)}
				{rootCategories.length === 0 && loading && (
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{rootCategories.map((category, i) => (
					<Catalogue key={i} currencySymbol={currencySymbol} {...category} />
				))}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(PreviewCatalogue);

const Catalogue = ({ name, items = [], subcategories = [], parentCategories = [], currencySymbol }) => {
	const renderCategoryName = useCallback(() => {
		if (parentCategories.length === 0) {
			return <span className="root-category">{name}</span>;
		}
		return (
			<React.Fragment>
				{parentCategories.map((category, i) => (
					<React.Fragment key={i}>
						<span className={i === 0 ? "root-category" : ""}>{category}</span>
						<img src="/assets/icons/icon-right-arrow.svg" />
					</React.Fragment>
				))}
				<span>{name}</span>
			</React.Fragment>
		);
	}, [parentCategories, name]);

	return (
		<React.Fragment>
			{subcategories.length === 0 && (
				<React.Fragment>
					<div className="category-container">
						<div className="name">{renderCategoryName()}</div>
						<div className="item-count">{items.length} Item(s)</div>
					</div>
					<CustomTable
						columns={columns}
						data={items || []}
						currencySymbol={currencySymbol}
						classes="items-list-table-container"
						content="Items"
					/>
				</React.Fragment>
			)}
			{subcategories.map((category, i) => (
				<Catalogue
					key={i}
					parentCategories={parentCategories.concat(name)}
					currencySymbol={currencySymbol}
					{...category}
				/>
			))}
		</React.Fragment>
	);
};
