import React, { useState, useEffect, useCallback, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import BasicInfo from "../components/ItemGroupEdit/BasicInfo";
import Items from "../components/ItemGroupEdit/Items";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";

// utils
import history from "../history";
import { store } from "../store/configureStore";
import { removeProp, adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchItemGroupsList, fetchItemGroupDetail, editItemGroup } from "../actions/itemGroups";
import { ActionTypes } from "../actions/_types";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// contant
import { NESTED_ENTITY_TYPES } from "../client-config";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Items",
		value: "items"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const ItemGroupEdit = ({
	itemGroupDetails,
	access,
	readOnly = false,
	match,
	isNested = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const { loading, data, error } = itemGroupDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [isDeleted, setIsDeleted] = useState(false);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchItemGroupDetail(parseInt(match.params.id));
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			store.dispatch({
				type: ActionTypes.RESET_ITEM_GROUPS_DETAIL
			});
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchItemGroupsList();
					history.push("/item-groups");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_ITEM_GROUPS_DETAIL,
			payload: {
				[field]: value
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		const resp = await editItemGroup(sanitisedData);
		if (resp) {
			setFormTouched(false);
		}
	};

	const handleArchiveRestore = useCallback((success) => {
		if (success) {
			setIsDeleted(true);
		}
		setArchiveRestore(false);
	}, []);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "item-groups"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="item-groups-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Item Group"}
				subTitle="Edit this item group"
				submitTitle="Save"
				loading={loading}
				disabled={!isFormTouched}
				isNested={isNested}
				hideActions={(formTab === FORM_TABS[0].value && !isFormTouched) || formTab === FORM_TABS[1].value}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && !data.includesAll && !isDeleted && !readOnly && (
							<Button classes="at-btn--danger" clickHandler={() => setArchiveRestore(true)}>
								Delete
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							isDeleted={isDeleted}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<Items
							groupId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							handleNestedEntity={handleNestedEntity}
							includesAll={data.includesAll}
							isDeleted={isDeleted}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[7]}
						entityName={data.title}
						object={data}
						mode="delete"
						fieldName="isDeleted"
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={!access.isCatalogueManagement || readOnly}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	itemGroupDetails: store.itemGroupDetails,
	access: store.login.loginDetail.access
});
export default connect(mapStateToProps)(ItemGroupEdit);
