import React from "react";

// components
import { chartTooltip } from "./Tooltip";
import Loader from "./Loader";

// third party
import { ResponsiveSankey } from "@nivo/sankey";

// utils
import { printCurrency, commifyNumbers } from "../../atlas-utils";

// constants
import { ANALYTICS_SANKEY_COLORS } from "../../client-config";

const Sankey = ({
	height = 600,
	data = {},
	sort = "input",
	maxValue,
	loading = false,
	colors,
	marginTop = 20,
	marginBottom = 120,
	marginLeft = 10,
	marginRight = 10,
	nodeThickness = 20,
	nodeSpacing = 70,
	linkContract = 2,
	enableLinkGradient = true,
	enableLegends = true,
	legendItemWidth = 150,
	labelPositions = [],
	currencySymbol,
	units = "",
	showPercentValue = false,
	placeholder = "No data available"
}) => {
	const customLegendSymbolShape = (props) => {
		const { x, y, fill, borderWidth, borderColor } = props;
		return (
			<rect
				x={x}
				y={y}
				rx={2}
				ry={2}
				fill={fill}
				strokeWidth={borderWidth}
				stroke={borderColor}
				width={15}
				height={15}
			/>
		);
	};

	const handleLabels = (props) => {
		const { nodes } = props;
		if (nodes?.length) {
			return nodes.map((node, i) => (
				<foreignObject
					key={`${node.id}_${node.color}`}
					x={node.x1 + (labelPositions?.[i]?.x || 0)}
					y={
						node.y1 +
						(labelPositions?.[i]?.position === "top" ? -node.height : 0) +
						(labelPositions?.[i]?.y || 0)
					}
					style={{ width: 300, height: 60 }}
				>
					<div className="label-container-sankey" style={{ textAlign: labelPositions?.[i]?.align || "left" }}>
						<div className="id">{node.id}</div>
						<div className={"value " + labelPositions?.[i]?.position}>
							{currencySymbol ? printCurrency(currencySymbol) : ""}
							{commifyNumbers(node.value.toFixed(2))}
							{showPercentValue ? "%" : ""}
							{units}
						</div>
					</div>
				</foreignObject>
			));
		}
		return null;
	};

	return (
		<div
			className={
				"nivo-chart-sankey" + (data.nodes?.length > 0 && data.links?.length > 0 && loading ? " disabled" : "")
			}
			style={{ height: height }}
		>
			{data.nodes?.length > 0 && data.links?.length > 0 && maxValue > 0 && (
				<ResponsiveSankey
					data={data}
					align="end"
					sort={sort}
					margin={{
						top: marginTop,
						right: marginRight,
						bottom: enableLegends ? marginBottom : marginBottom - 60,
						left: marginLeft
					}}
					colors={colors?.length ? colors : Object.values(ANALYTICS_SANKEY_COLORS)}
					enableLinkGradient={enableLinkGradient}
					nodeThickness={nodeThickness}
					nodeOpacity={1}
					nodeHoverOthersOpacity={0.4}
					nodeSpacing={nodeSpacing}
					nodeBorderWidth={0}
					nodeBorderColor={{
						from: "color",
						modifiers: [["darker", 0.8]]
					}}
					nodeBorderRadius={1}
					linkOpacity={0.3}
					linkHoverOpacity={0.5}
					linkHoverOthersOpacity={0.2}
					linkContract={linkContract}
					linkBlendMode="normal"
					labelPosition="inside"
					labelTextColor={{
						from: "color",
						modifiers: [["darker", 1]]
					}}
					nodeTooltip={(props) =>
						chartTooltip({ ...props, currencySymbol, units, showPercentValue }, "sankey", "node")
					}
					linkTooltip={(props) =>
						chartTooltip({ ...props, currencySymbol, units, showPercentValue }, "sankey", "link")
					}
					legends={
						enableLegends
							? [
									{
										anchor: "bottom",
										direction: "row",
										justify: false,
										translateX: 0,
										translateY: 100,
										itemDirection: "left-to-right",
										itemWidth: legendItemWidth,
										itemHeight: 15,
										itemOpacity: 1,
										itemsSpacing: 10,
										symbolSize: 15,
										symbolShape: customLegendSymbolShape
									}
							  ]
							: []
					}
					theme={{
						legends: {
							// legends info of sankey
							text: {
								fontSize: 12
							}
						}
					}}
					layers={[handleLabels, "legends", "links", "nodes"]}
				/>
			)}
			{((data.nodes?.length === 0 && data.links?.length === 0) || !maxValue) && loading && <Loader />}
			{((data.nodes?.length === 0 && data.links?.length === 0) || !maxValue) && !loading && (
				<div className="no-items-placeholder">{placeholder}</div>
			)}
		</div>
	);
};
export default Sankey;
