import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/ItemTagGroupEdit/BasicInfo";
import ItemTags from "../components/ItemTagGroupEdit/ItemTags";

// utils
import { store } from "../store/configureStore";
import history from "../history";
import { removeProp, adjustNestedContainer, findChangedKeysInTwoObjects, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchItemTagsList, fetchTagGroupDetail, editTagGroupDetail } from "../actions/itemTags";
import { ActionTypes } from "../actions/_types";

// reducers
import { tagGroupDetailsReducer, TAG_GROUP_DETAILS_INITIAL_STATE } from "../reducers/itemTags";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../client-config";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Tags",
		value: "itemTags"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const ItemTagGroupEdit = ({
	selectedTagGroup,
	access,
	match,
	isNested = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isFormOpen, setFormState] = useState(false);
	const [itemDetails, dispatch] = useReducer(tagGroupDetailsReducer, TAG_GROUP_DETAILS_INITIAL_STATE);
	const { loading, data, error, backupData } = itemDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchTagGroupDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					if (selectedTagGroup) {
						fetchItemTagsList(selectedTagGroup);
					}
					history.push("/tags");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value) => {
		dispatch({
			type: ActionTypes.UPDATE_TAG_GROUP_DETAIL,
			payload: {
				[field]: value
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const fieldsUpdated = findChangedKeysInTwoObjects(data, backupData);
		const eventMeta = {
			fields_updated: fieldsUpdated,
			num_of_fields_updated: fieldsUpdated.length
		};

		const sanitisedData = removeProp(data, "__typename");
		const resp = await editTagGroupDetail(sanitisedData, dispatch);
		if (resp) {
			eventMeta.status = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.TAG_GROUP_BASIC_INFO_UPDATE, eventMeta);

			setFormTouched(false);
		} else {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.TAG_GROUP_BASIC_INFO_UPDATE, eventMeta);
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_TAG_GROUP_DETAIL,
					payload: {
						isEnabled: !data.isEnabled
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_TAG_GROUPS_LIST,
					payload: {
						...data,
						isEnabled: !data.isEnabled
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	return (
		<div className="tag-group-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Tag Group"}
				subTitle="Edit this tag group"
				submitTitle="Save"
				loading={loading}
				isNested={isNested}
				hideActions={formTab === FORM_TABS[1].value || !isFormTouched}
				headerRight={
					access.isCatalogueManagement &&
					!data.isSystem && (
						<Button
							classes={data.isEnabled ? "at-btn--danger" : "at-btn--success"}
							clickHandler={() => setArchiveRestore(true)}
						>
							{data.isEnabled ? "Archive" : "Restore"}
						</Button>
					)
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							readOnly={!access.isCatalogueManagement}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<ItemTags
							tagGroupId={parseInt(match.params.id)}
							isSystem={data.isSystem}
							nestedEntity={nestedEntity}
							handleNestedEntity={handleNestedEntity}
							setModalBusy={setModalBusy}
							readOnly={!access.isCatalogueManagement}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[10]}
						entityName={data.title}
						object={data}
						mode={data.isEnabled ? "archive" : "restore"}
						fieldName="isEnabled"
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	selectedTagGroup: store.tagGroupsList.selectedTagGroup.id
}))(ItemTagGroupEdit);
