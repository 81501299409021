// utils
import { lS, isLoggedin } from "../atlas-utils";

// types
import { ActionTypes } from "../actions/_types";

const INITIAL_STATE = isLoggedin()
	? lS.get("banner") || {}
	: {
			topic: "",
			icon: "",
			message: "",
			links: [],
			classes: "default",
			isDismissible: false,
			trackEvent: false
	  };

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_BANNER_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
