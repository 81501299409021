import React, { useCallback, useEffect, useState } from "react";

// components
import { ItemsTable } from "./ItemsTable";
import { Paginator } from "../_commons/Paginator";
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import ItemEntityAssociations from "../EntityAssociations/ItemEntityAssociations";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// clients
import { store } from "../../store/configureStore";
import { client } from "../../client";

// utils
import { extractInitials, getSortOrder } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";

// graphql
import { GET_CATEGORY_ITEMS } from "../../graphql/categories";

// constants
import { ITEM_ENTITY_ASSOCIATION_TYPES } from "../../client-config";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
const FILTER_INITIAL_STATE = {
	title: "",
	tags: undefined
};

const AssociatedItems = ({
	categoryId,
	showActions = true,
	headerFields,
	classes = "",
	currencySymbol,
	RenderTable,
	archived,
	underRootCategory,
	emptyScreenType,
	categoryName,
	showTableHeader,
	setModalBusy,
	blockAssociation = false,
	openNestedEntity,
	readOnly = true,
	refresh = false,
	brands,
	setItemsCount,
	canAssociateItems,
	fromEditSection = false
}) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [sort, setSort] = useState({ field: "sort_order", order: "ASC" });
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [data, setData] = useState({
		objects: [],
		count: undefined
	});
	const brandsWithoutAll = brands?.items.filter((brand) => brand.id !== "all");
	const [selectedBrand, setSelectedBrand] = useState(
		brands.selectedBrand?.id === "all" ? brandsWithoutAll[0] || null : brands.selectedBrand
	);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				limit,
				offset,
				id: parseInt(categoryId),
				sort
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			variables.brand = selectedBrand?.id ? String(selectedBrand?.id) : null;
			const resp = await client.query({
				query: GET_CATEGORY_ITEMS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (typeof setItemsCount === "function") {
				setItemsCount(resp?.data?.category?.paginatedItems?.count || 0);
			}
			setData(resp.data.category.paginatedItems);
			setTagfilters(resp.data.category.paginatedItems.filters.find((fl) => fl.field === "tags"));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [limit, offset, sort, categoryId, appliedFilters, refresh, selectedBrand]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const sortList = (field) => {
		const updatedSort = {
			field: field,
			order: getSortOrder(sort, { field })
		};
		setOffset(0);
		setSort(updatedSort);
	};

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openAssociationSidebar = () => {
		if (blockAssociation) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "You cannot associate items to a category containing sub-categories",
					timeout: 2000,
					error: true
				}
			});
			return;
		}
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			setOffset(0);
			fetchData();
		}
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrand = (value) => {
		setSelectedBrand(value);
	};

	return (
		<div className="associated-items-tab">
			{fromEditSection && !canAssociateItems && (
				<div className="alert">
					<img src="/assets/icons/alert.svg" />
					<span>Categories with subcategories cannot have items under them.</span>
				</div>
			)}
			{canAssociateItems && showActions && (
				<div>
					<div className="header-container">
						<div>
							<div className="header-text">Associated Items</div>
							<div className="header-subtext">
								{`${data.count || 0} item(s) are associated to this category`}
							</div>
						</div>
						{!readOnly && (
							<div>
								<Button clickHandler={openAssociationSidebar}>Update</Button>
							</div>
						)}
					</div>
					<div className="search-container">
						<div className="section-1">
							<SelectFilterCustom
								options={brandsWithoutAll}
								isLoading={brands.isLoading}
								field="brands"
								currValue={selectedBrand}
								setFilter={(f, value) => handleBrand(value)}
								labelKey="name"
								valueKey="id"
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
							/>
							<SelectFilter
								// title="Tags"
								options={tagfilters.values || []}
								field={tagfilters.field || ""}
								currValue={currFilters.tags || ""}
								setFilter={setFilter}
								labelKey="valueForDisplay"
								valueKey="value"
								placeholder="Tags"
							/>
						</div>
						<div className="section-2">
							<InputWithLabel
								value={currFilters.title}
								onChange={(e) => setFilter("title", e.target.value)}
								placeholder="Name"
							></InputWithLabel>
						</div>
						{/* <InputWithLabel
							value={currFilters.crm_title}
							onChange={(e) => setFilter('crm_title', e.target.value)}
							placeholder="CRM Title"
						>
						</InputWithLabel> */}
					</div>
				</div>
			)}
			{fromEditSection && !canAssociateItems ? null : (
				<>
					<div>
						<ItemsTable
							headerFields={headerFields}
							sortList={sortList}
							sortedField={sort.field}
							RenderTable={RenderTable}
							archived={archived}
							data={data.objects}
							loading={loading}
							classes={classes}
							currencySymbol={currencySymbol}
							underRootCategory={underRootCategory}
							emptyScreenType={emptyScreenType}
							categoryName={categoryName}
							showTableHeader={showTableHeader}
							openNestedEntity={openNestedEntity}
						/>
					</div>
					<Paginator limit={limit} offset={offset} count={data.count || 0} goToPage={handlePagination} />
					<ItemEntityAssociations
						isOpen={associationSidebar}
						close={closeAssociationSidebar}
						entityType={ITEM_ENTITY_ASSOCIATION_TYPES[0]}
						entityId={parseInt(categoryId)}
						currencySymbol={currencySymbol}
						selectedBrand={brands.selectedBrand}
					/>
				</>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	brands: store.configItems.brands
});
export default connect(mapStateToProps)(AssociatedItems);
