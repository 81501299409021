import React from "react";

// third party
import { Link } from "react-router-dom";

const Breadcrumbs = ({ connectedLinks = [] }) => {
	if (connectedLinks.length === 0) {
		return null;
	}
	return (
		<div className="at-breadcrumbs-container">
			{connectedLinks.map((link, i) => (
				<React.Fragment key={i}>
					{link?.to ? (
						<Link
							to={link?.to}
							className="at-breadcrumb-link"
							onClick={link.onClick ? () => link.onClick(link, i) : () => {}}
						>
							<span className="hyperlink">{link?.title || ""}</span>
						</Link>
					) : (
						<div
							className="at-breadcrumb-link"
							onClick={link.onClick ? () => link.onClick(link, i) : () => {}}
						>
							<span className="hyperlink">{link?.title || ""}</span>
						</div>
					)}
					<img
						src="/assets/icons/icon-right-angled-arrow.svg"
						alt="right arrow"
						className="right-arrow-icon"
					/>
				</React.Fragment>
			))}
		</div>
	);
};
export default Breadcrumbs;
