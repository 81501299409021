import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/ItemTagEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchItemTagsList } from "../actions/itemTags";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_ITEM_TAG } from "../graphql/itemTags";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../client-config";

const ItemTagCreate = ({ tagGroups, selectedTagGroup, hasAccess = false }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [data, setData] = useState({
		title: "",
		crmTitle: "",
		tagGroup: null,
		isFilterable: true,
		isSearchable: true
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	const handleCancel = async () => {
		setFormState(false);
		if (selectedTagGroup) {
			fetchItemTagsList(selectedTagGroup.id);
		}
		setTimeout(() => {
			history.push("/tags");
		}, 100);
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			if (variables.tagGroup) {
				variables.tagGroup = parseInt(variables.tagGroup.id);
			}
			let resp = await client.mutate({
				mutation: CREATE_ITEM_TAG,
				variables
			});
			if (resp.data.saveItemTag.status.success) {
				trackEvent(TRACKING_EVENT_NAMES.NEW_TAG_CREATION, {
					status: TRACKING_STATUS.SUCCESS
				});

				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Tag created",
						timeout: 5000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_ITEM_TAGS_LIST,
					payload: resp.data.saveItemTag.object
				});
				history.push(`/tags/tag/edit/${resp.data.saveItemTag.object.id}`);
			} else {
				trackEvent(TRACKING_EVENT_NAMES.NEW_TAG_CREATION, {
					status: TRACKING_STATUS.FAILURE
				});

				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveItemTag.status.messages));
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.NEW_TAG_CREATION, {
				status: TRACKING_STATUS.FAILURE
			});

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Tag"
			subTitle="Create a new tag"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<BasicInfo
					data={data}
					tagGroups={tagGroups || []}
					handleForm={handleForm}
					validations={error.fields || {}}
					readOnly={!hasAccess}
				/>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	tagGroups: store.tagGroupsList.tagGroups,
	selectedTagGroup: store.tagGroupsList.selectedTagGroup
});
export default connect(mapStateToProps)(ItemTagCreate);
