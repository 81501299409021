import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";

// third party
import { connect } from "react-redux";

const BasicInfo = ({
	data,
	fulfillmentModes,
	options,
	currencySymbol,
	handleForm,
	handleAddRange,
	handleDeleteRange,
	validations,
	readOnly = true
}) => {
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<InputWithLabel
					value={data.title}
					onChange={(e) => handleForm("title", e.target.value)}
					requiredLabel={true}
					validationMessage={validations.title || ""}
					readOnly={readOnly}
				>
					Name
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Applicable on"
					options={options}
					readOnly={readOnly}
					field="applicableOn"
					currValue={data.applicableOn}
					setFilter={handleForm}
					isSearchable={false}
					isClearable={false}
					labelKey="label"
					valueKey="value"
					requiredLabel={true}
					validationMessage={validations.applicableOn || ""}
				/>
				{data?.applicableOn?.value !== "order.order_subtotal" && (
					<InputWithLabel
						value={data.value}
						type="number"
						onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
						requiredLabel={true}
						validationMessage={validations.value || ""}
						showLabel={data.applicableOn && true}
						classes={data.applicableOn ? "at-input--label" : ""}
						currency={
							data.applicableOn &&
							(data.applicableOn.value === "item.quantity" ||
								data.applicableOn.value === "flat_charge") &&
							true
						}
						symbol={
							data.applicableOn &&
							(data.applicableOn.value === "order.order_subtotal" ||
								data.applicableOn.value === "item.price")
								? "%"
								: ""
						}
						currencySymbol={currencySymbol}
						readOnly={readOnly}
					>
						{data.applicableOn && data.applicableOn.value === "item.quantity" ? (
							<span>Amount per quantity</span>
						) : data.applicableOn && data.applicableOn.value === "flat_charge" ? (
							<span>Fixed Value</span>
						) : (
							<span>Percentage</span>
						)}
					</InputWithLabel>
				)}
				{data?.applicableOn?.value === "order.order_subtotal" && (
					<SelectFilter
						title="Type"
						options={data?.applicableOn?.types || []}
						field="fieldType"
						currValue={data.fieldType}
						setFilter={handleForm}
						isSearchable={false}
						isClearable={false}
						labelKey="label"
						valueKey="value"
					/>
				)}
			</div>
			{data?.applicableOn?.value === "order.order_subtotal" && data?.fieldType?.value === "RANGE" && (
				<div className="card-container">
					{data?.rangeValues?.length > 0 &&
						data?.rangeValues?.map((range, i) => (
							<div className="form-row row-half">
								<div className="min-max-range">
									<InputWithLabel
										value={range.min}
										type="number"
										onChange={(e) =>
											handleForm(
												"min",
												e.target.value ? parseFloat(e.target.value) : null,
												i,
												true
											)
										}
										validationMessage={validations.min || ""}
									>
										Min
									</InputWithLabel>
									<InputWithLabel
										value={range.max}
										type="number"
										onChange={(e) =>
											handleForm(
												"max",
												e.target.value ? parseFloat(e.target.value) : null,
												i,
												true
											)
										}
										validationMessage={validations.max || ""}
									>
										Max
									</InputWithLabel>
								</div>
								<div className="value-range">
									<InputWithLabel
										value={range.value}
										type="number"
										onChange={(e) =>
											handleForm(
												"value",
												e.target.value ? parseFloat(e.target.value) : null,
												i,
												true
											)
										}
										validationMessage={validations.value || ""}
									>
										Value
									</InputWithLabel>
									{data?.rangeValues?.length > 1 && (
										<div className="delete-range">
											<img
												className="delete-icon"
												onClick={() => handleDeleteRange(i)}
												src="/assets/icons/delete.svg"
											/>
										</div>
									)}
								</div>
							</div>
						))}
					<div className="add-another-range">
						<Button type="secondary" clickHandler={handleAddRange}>
							Add Range
						</Button>
					</div>
				</div>
			)}
			{data?.applicableOn?.value === "order.order_subtotal" && data?.fieldType?.value === "PERCENTAGE" && (
				<div className="form-row row-half">
					<InputWithLabel
						value={data.value}
						type="number"
						onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
						requiredLabel={true}
						validationMessage={validations.value || ""}
						showLabel={true}
						classes="at-input--label"
						symbol="%"
					>
						Percentage
					</InputWithLabel>
				</div>
			)}
			<div className="form-row row-full">
				<Textarea
					value={data.description}
					onChange={(e) => handleForm("description", e.target.value)}
					readOnly={readOnly}
				>
					Description
				</Textarea>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Excluded Platforms"
					options={data.platforms}
					readOnly={readOnly}
					field="excludedPlatforms"
					currValue={data.excludedPlatforms}
					setFilter={handleForm}
					labelKey="platformName"
					valueKey="platformName"
					validationMessage={validations.excludedPlatforms || ""}
					multi={true}
					isSearchable={false}
					isClearable={false}
					showCustomTooltip={true}
					tooltipInfo="This charge will not be applied to orders placed through platforms selected here"
					placeholder="Select"
				/>
				<SelectFilter
					title="Fulfillment Modes"
					options={fulfillmentModes.items}
					readOnly={readOnly}
					isLoading={fulfillmentModes.isLoading}
					field="fulfillmentModes"
					currValue={data.fulfillmentModes}
					setFilter={handleForm}
					labelKey="nameForDisplay"
					valueKey="id"
					validationMessage={validations.fulfillmentModes || ""}
					multi={true}
					isSearchable={false}
				/>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.merchantRefId == "-1" ? "" : data.merchantRefId}
					onChange={(e) => handleForm("merchantRefId", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
					validationMessage={validations.merchantRefId || ""}
					readOnly={readOnly}
				>
					POS ID
				</InputWithLabel>
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	fulfillmentModes: store.configItems.fulfillmentModes
});
export default connect(mapStateToProps)(BasicInfo);
