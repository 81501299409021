import React, { memo, useEffect, useMemo, useState } from "react";
import { FormSidebar } from "../../../components/_commons/FormSidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import {
	calculateContinuousPercentages,
	minutesToTime,
	timeToMinutes,
	transformDataa
} from "../../../helpers/periscope";
import {
	fetchActivityLogItemAvailabilityTimeSeries,
	fetchActivityLogLocationAvailabilityTimeSeries
} from "../../../actions/periscope";
import { connect } from "react-redux";
import GraphPeriscope from "./GraphPeriscope";
import Popover from "../../_commons/Popover";

const SideBarActivityLog = memo(
	({
		isFormOpen,
		setFormState,
		formData = {},
		tableName = "location",
		isMultibrandEnabled,
		bizId,
		periscopeActivityLogData,
		handleTimeFilter,
		timeOption
	}) => {
		const data = useMemo(() => {
			return periscopeActivityLogData?.graph?.data?.data || [];
		}, [periscopeActivityLogData]);

		const loading = periscopeActivityLogData?.graph?.loading;
		const error = periscopeActivityLogData?.graph?.error;
		const offlineDuration = periscopeActivityLogData?.graph?.data?.offlineDuration;
		const offlineFrequency = periscopeActivityLogData?.graph?.data?.offlineFrequency;

		const values = (data || []).map((chunk, index) => {
			const chunkData = [...chunk.data];
			const percentages = calculateContinuousPercentages(chunkData, index, timeOption, data.length) || [];
			return percentages.reverse() || [];
		});

		let intervalScoring =
			(values || []).length > 0 &&
			(values || []).length < 2 &&
			(timeOption === "last_6_hours" ||
				timeOption === "last_2_hours" ||
				timeOption === "last_1_hour" ||
				timeOption === "last_12_hours")
				? values[0]?.[0]?.["end_time"]
				: false;

		const splitInterval = {
			last_6_hours: {
				interval: 12,
				value: 30
			},
			last_2_hours: {
				interval: 4,
				value: 30
			},
			last_1_hour: {
				interval: 4,
				value: 15
			},
			last_12_hours: {
				interval: 12,
				value: 60
			}
		};

		let timeIntervals = [];
		if (
			(data || []).length == 1 &&
			(timeOption === "last_12_hours" ||
				timeOption === "last_6_hours" ||
				timeOption === "last_2_hours" ||
				timeOption === "last_1_hour")
		) {
			let endTimeRange = timeToMinutes(intervalScoring);
			timeIntervals.push({ endTimeRange, time: intervalScoring });
			for (let i = splitInterval[timeOption]?.["interval"]; i > 0; i--) {
				endTimeRange -= splitInterval[timeOption]?.["value"];
				timeIntervals.push({ endTimeRange, time: minutesToTime(endTimeRange) });
			}
		}

		const [result, keys] = transformDataa(values);
		const processedData = result;

		let headingStr = "";
		if (tableName == "location") {
			headingStr = `${formData?.location ? `${formData?.location} |` : ""}   ${
				isMultibrandEnabled && formData?.brand ? `${formData?.brand} | ` : ""
			}   ${formData?.platform ? `${formData?.platform}` : ""}`;
		}
		if (tableName == "item") {
			headingStr = ` ${formData?.item ? `${formData?.item} |` : ""}    ${
				formData?.location ? `${formData?.location} |` : ""
			}   ${isMultibrandEnabled && formData?.brand ? `${formData?.brand} | ` : ""}   ${
				formData?.platform ? `${formData?.platform}` : ""
			}`;
		}

		const fetchGraph = (variables) => {
			let condition =
				variables.bizId && variables.brandId && variables.locationId && variables.platform && variables.time;
			if (tableName == "location") {
				if (condition) {
					fetchActivityLogLocationAvailabilityTimeSeries(variables);
				}
			} else if (tableName == "item") {
				if (condition && variables.itemId) {
					fetchActivityLogItemAvailabilityTimeSeries(variables);
				}
			}
		};

		useEffect(() => {
			if (isFormOpen) {
				if (tableName == "location") {
					const variables = {
						bizId,
						brandId: formData.brand_id,
						locationId: formData?.location_id,
						platform: formData?.platform,
						time: timeOption
					};
					fetchGraph(variables);
				} else {
					const variables = {
						bizId,
						brandId: formData.brand_id,
						locationId: formData?.location_id,
						platform: formData?.platform,
						time: timeOption,
						itemId: formData?.item_id
					};
					fetchGraph(variables);
				}
			}
		}, [JSON.stringify(formData), timeOption, isFormOpen]);

		const history = useHistory();
		const handleCancel = () => {
			setFormState(false);
			history.push("/periscope/activity-log");
		};
		const [isFormTouched, setFormTouched] = useState(false);

		const handleSubmit = () => {};
		const timeStatus = {
			last_30_days: "Last 30 Days",
			last_14_days: "Last 14 Days",
			last_7_days: "Last 7 Days",
			last_24_hours: "Last 24 Hours",
			last_12_hours: "Last 12 Hours",
			last_6_hours: "Last 6 Hours",
			last_2_hours: "Last 2 Hours",
			last_1_hour: "Last 1 Hour"
		};

		const sideBarHeader = (
			<>
				<div className="header-sidebar">
					<div className="heading-text">
						<div className="link-text" onClick={() => handleCancel()}>
							<span className="link">View {tableName} Activity</span>{" "}
							<img className="link-img" src="/assets/periscope/right-arrow-action.svg" />{" "}
						</div>
						<div className="title"> {headingStr} </div>
					</div>
					<div style={{}}>
						<div></div>
						{/* <div>periscrope fetch</div> */}
					</div>
				</div>
			</>
		);
		const otherParamters = {};

		if (processedData.length > 15) {
			otherParamters["width"] = data.length * 48;
		}
		if (processedData.length == 15) {
			otherParamters["width"] = data.length * 42;
		}
		const numbers =
			(data || []).length == 1 &&
			(timeOption == "last_1_hour" || timeOption == "last_2_hours" || timeOption == "last_12_hours")
				? Array.from({ length: 5 }, () => data.length)
				: Array.from({ length: 25 }, () => data.length);

		const formatTime = (hour) => {
			let period = hour >= 12 ? "PM" : "AM";
			let displayHour = hour % 12;
			displayHour = displayHour === 0 ? 12 : displayHour;
			return `${displayHour}:00 ${period}`;
		};

		function getDayOfWeeks(dateString) {
			const date = new Date(dateString);
			const options = { weekday: "long" };
			return date.toLocaleDateString("en-US", options);
		}

		const renderPopover = (desc = "werfdwerf") => {
			return <div className="description">{desc}</div>;
		};

		return (
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				loading={false}
				disabled={!isFormTouched}
				hideSubmitAction={true}
				cancelTitle={"close"}
				cancelClass={"close-btn"}
			>
				<div className="sidebar-activity-log">
					{sideBarHeader}
					<div className="sidebar-content">
						<SelectFilter
							classes="time-dropdown"
							options={[
								{ value: "last_1_hour", valueForDisplay: "Last 1 Hour" },
								{ value: "last_2_hours", valueForDisplay: "Last 2 Hours" },
								{ value: "last_6_hours", valueForDisplay: "Last 6 Hours" },
								{ value: "last_12_hours", valueForDisplay: "Last 12 Hours" },
								{ value: "last_24_hours", valueForDisplay: "Last 24 Hours" },
								{ value: "last_7_days", valueForDisplay: "Last 7 Days" },
								{ value: "last_14_days", valueForDisplay: "Last 14 Days" },
								{ value: "last_30_days", valueForDisplay: "Last 30 Days" }
							]}
							field="timeOption"
							currValue={{
								value: timeOption,
								valueForDisplay: (
									<span className="status-main">
										<span className="status-heading">Time</span>
										<span className="status-display">{timeStatus[timeOption]}</span>
									</span>
								)
							}}
							labelKey="valueForDisplay"
							valueKey="value"
							setFilter={(f, v) => handleTimeFilter(v)}
							isSearchable={false}
							isClearable={false}
						/>
						<div className="metrics-info">
							<div className="metrics-display">
								<div className="icon-text">
									<span className="icon-heading">Offline frequency</span>

									<Popover
										data={"Number of times store was Offline"}
										showOnHover={true}
										renderPopover={renderPopover}
										position={"down-left"}
									>
										<img
											style={{ cursor: "pointer" }}
											className="icon-img"
											src="../../../../assets/periscope/tooltip-icon.svg"
										/>
									</Popover>
								</div>
								{loading || error ? (
									<div className={`${loading ? "loading" : ""} ${error ? "error" : ""}`}>
										<div className="shimmer H(30px) Mb(10px)" />
									</div>
								) : (
									<div className="count">
										<span>{offlineFrequency}</span>
										<span>
											{String(offlineFrequency) &&
												(Number(offlineFrequency) == 1 ? "time" : "times")}
										</span>
									</div>
								)}
							</div>
							<div className="metrics-display">
								<div className="icon-text">
									<span className="icon-heading">Offline duration</span>
									<Popover
										data={"Duration when store was Offline "}
										showOnHover={true}
										renderPopover={renderPopover}
										position={"down-left"}
									>
										<img
											style={{ cursor: "pointer" }}
											className="icon-img"
											src="../../../../assets/periscope/tooltip-icon.svg"
										/>
									</Popover>
								</div>
								{loading || error ? (
									<div className={`${loading ? "loading" : ""} ${error ? "error" : ""}`}>
										<div className="shimmer H(30px) Mb(10px)" />
									</div>
								) : (
									<div className="count"> {offlineDuration} </div>
								)}
							</div>
						</div>

						<div className={`graph-div   ${loading ? `loading-div` : ``}  ${error ? " error" : "flex"} `}>
							{loading || error ? (
								<>
									<div className="shimmer H(100px)  " />
									<div className="shimmer H(100px) Mt(5px) " />
									<div className="shimmer H(100px) Mt(5px) " />
								</>
							) : (
								(data || []).length !== 0 && (
									<>
										<div
											className={`time-series flex ${
												timeOption == "last_1_hour" && data.length == 1 ? "mt-70" : ""
											}`}
										>
											<div
												className={`time-text ${
													!intervalScoring ? "justify-center" : "justify-between"
												}`}
											>
												{!intervalScoring
													? numbers.map((num, index) => {
															return (
																<div className="time">
																	{index == 0 ||
																	index == 6 ||
																	index == 12 ||
																	index == 18 ||
																	index == 24
																		? index == 24
																			? "11:59PM"
																			: formatTime(index)
																		: ""}
																</div>
															);
													  })
													: timeIntervals.reverse().map((time) => {
															return (
																<div className={`${time?.time ? "time" : ""}`}>
																	{time?.time}
																</div>
															);
													  })}
											</div>
											<div
												className={`border-interval ${
													timeOption == "last_1_hour" ||
													timeOption == "last_2_hours" ||
													timeOption == "last_6_hours"
														? "justify-between"
														: ""
												}`}
											>
												{numbers.map((num, index) => {
													return (
														<div
															className={`indicator ${
																timeOption == "last_1_hour" && data.length == 1
																	? "justify-between"
																	: "h-13"
															} `}
														>
															<div className="div-with"></div>
														</div>
													);
												})}
											</div>
										</div>
										<div
											className={`graph-content   ${
												processedData.length > 15 ? "scroll-x-hidden" : ""
											}`}
										>
											<div
												className={`date-display 
													${processedData.length <= 14 ? "len-four-teen" : ""}
													${processedData.length === 15 ? "len-five-teen" : ""}
													${processedData.length >= 30 ? "len-three-ty" : ""}
												  `}
											>
												{processedData.map((num, index) => {
													return (
														<div
															className={`date-val ${
																processedData.length > 15 ? "len-greater-five-teen" : ""
															}
														${processedData.length > 15 ? "len-greater-five-teen" : "less-five-teen"}
														${processedData.length == 15 ? "len-eq-five-teen" : ""}
														${index == 0 && processedData.length !== 15 ? "index-zero" : ""}
														${processedData.length == 31 ? "len-three-one" : ""}
														${index == 0 && processedData.length == 31 ? "index-zero-three-one" : ""}`}
															style={{
																...(processedData.length > 15
																	? {}
																	: {
																			width: "100%",
																			flexBasis: `${100 / processedData}%`
																	  })
															}}
														>
															<div className="date-txt">
																<div className="week">
																	{processedData.length > 7
																		? getDayOfWeeks(
																				processedData[index]?.["day"]
																		  ).slice(0, 3)
																		: getDayOfWeeks(processedData[index]?.["day"])}
																</div>
																<div className="date">
																	{processedData[index]?.day?.split("-")[2]}
																</div>
															</div>
														</div>
													);
												})}
											</div>
											<GraphPeriscope
												otherParamters={otherParamters}
												modified={processedData}
												keys={keys}
											/>
										</div>
									</>
								)
							)}
						</div>
					</div>
				</div>
			</FormSidebar>
		);
	}
);

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	periscopeActivityLogData: state.periscopeActivityLogData,
	periscopeActivityLogState: state.periscopeActivityLogState,
	login: state.login,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled
});

export default connect(mapStateToProps)(SideBarActivityLog);
