import React, { Component } from "react";

// components
import { Topbar } from "../components/_commons/Topbar";
import Analytics from "../components/Dashboard/Analytics";
import TopPerforming from "../components/Dashboard/TopPerforming";
import { Modal } from "../components/_commons/Modal";
import { Button } from "../components/_commons/Button";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";
import { NewDateCompareFilter } from "../components/_commons/NewDateCompareFilter";

// third party
import { connect } from "react-redux";
import Lottie from "lottie-react";
import moment from "moment";

// client
import { store } from "../store/configureStore";
import { lS, extractInitials, openExternalLink, trackEvent } from "../atlas-utils";

// actions
import { fetchBrands } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// lottie
import chatBotSupportAnimation from "../lottie/chatBotSupport.json";

// constant
import {
	FRESH_CHAT_ENABLED_BIZ_IDS,
	WELCOME_BANNER_DISPLAY_ID,
	WELCOME_BANNER_DISPLAY_ID_CHATBOT,
	WELCOME_BANNER_DISPLAY_ID_CUSTOMER_SUPPORT
} from "../client-config";
import history from "../history";
import { ButtonIcon } from "../components/_commons/ButtonIcon";
const FORM_TABS = [
	{
		label: "Trends",
		value: "trends"
	},
	{
		label: "Performance",
		value: "performance"
	}
];

@connect((store) => ({
	dashboardState: store.dashboardState,
	selectedModule: store.selectedModule,
	configItems: store.configItems,
	userFullName: store.login.loginDetail.name,
	showCustomMessage: store.login.showCustomMessage,
	customMessageDisplayDate: store.login.customMessageDisplayDate,
	welcomeBannerDisplayedDate: store.login.welcomeBannerDisplayedDate,
	biz: store.login.loggedInbizDetail,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	brands: store.configItems.brands,
	anaSummary: store.anaSummary,
	access: store.login.loginDetail.access
}))
export default class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.delayedEventTrackingRef = React.createRef();
		this.state = {
			selectedBrand: this.props.isMultibrandEnabled
				? lS.get("brand")
					? lS.get("brand")
					: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }
				: null,
			formTab: FORM_TABS[0].value
		};
		this.bannerName = "displayedWelcomeBannerId" + "_" + this.props.biz?.id;
	}

	updateDimensions = () => {
		store.dispatch({
			type: "UPDATE_DIMENSIONS",
			payload: {
				height: window.innerHeight,
				width: window.innerWidth
			}
		});
	};

	async componentDidMount() {
		this.updateDimensions();
		if (this.props.isMultibrandEnabled) {
			await fetchBrands("", true);
			store.dispatch({
				type: ActionTypes.UPDATE_SELECTED_BRAND,
				payload: this.state.selectedBrand
			});
		}

		this.delayedEventTrackingRef.current = setTimeout(() => {
			if (this.props.showCustomMessage && lS.get(this.bannerName) !== WELCOME_BANNER_DISPLAY_ID) {
				trackEvent("login_popup_impressions", {
					bannerPurpose: "food preparation time banner",
					country: this.props?.biz?.country?.toLowerCase()
				});
			}
		}, 5000);
	}

	componentWillUnmount() {
		if (this.delayedEventTrackingRef.current) {
			clearTimeout(this.delayedEventTrackingRef.current);
		}
	}

	changeModule = (payload) => {
		store.dispatch({
			type: "SET_APP_MODULE",
			payload
		});
	};

	updateDashboardState = (payload) => {
		store.dispatch({
			type: "DASHBOARD_STATE_CHANGE",
			payload
		});
	};

	handleClose = () => {
		if (this.delayedEventTrackingRef.current) {
			clearTimeout(this.delayedEventTrackingRef.current);
		}

		store.dispatch({
			type: "UPDATE_LOGIN_STATE",
			payload: {
				showCustomMessage: false
			}
		});

		lS.set(this.bannerName, WELCOME_BANNER_DISPLAY_ID);

		// if(FRESH_CHAT_ENABLED_BIZ_IDS[this.props.biz?.id]) {
		// 	const currentWelcomeBannerSettings = lS.get('welcomeBannerSettings')
		// 	lS.set('welcomeBannerSettings', {
		// 		showedTimes: currentWelcomeBannerSettings?.showedTimes ? currentWelcomeBannerSettings.showedTimes + 1 : 1,
		// 		showedDate: moment().format("YYYY-MM-DD"),
		// 	})
		// }
	};

	handleFormTab = (tab) => {
		this.setState({
			formTab: tab.value
		});
	};

	handleBrand = async (brand) => {
		this.setState({
			selectedBrand: brand
		});
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: brand
		});
	};

	handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	render() {
		const { selectedBrand, formTab } = this.state;
		const { isMultibrandEnabled, brands, anaSummary, selectedModule, dashboardState } = this.props;
		const { loading, data } = anaSummary;
		const { productType, productTypeOptions } = selectedModule;
		const { currentDateFilter, appliedDateFilter, criteria, analyticsTopic } = dashboardState;
		const today = moment().format("YYYY-MM-DD");
		const welcomeBannerSettings = lS.get("welcomeBannerSettings");
		const nonFreshchatSupportBannerImgUrl = {
			"united kingdom": "url('/assets/icons/icon-support24x7-uk.png')",
			uk: "url('/assets/icons/icon-support24x7-uk.png')",
			ireland: "url('/assets/icons/icon-support24x7-uk.png')",
			uae: "url('/assets/icons/icon-support24x7-mena.png')",
			oman: "url('/assets/icons/icon-support24x7-mena.png')",
			egypt: "url('/assets/icons/icon-support24x7-mena.png')",
			"saudi arabia": "url('/assets/icons/icon-support24x7-mena.png')"
		};
		const country = this.props?.biz?.country?.toLowerCase();

		const openTargettedLocation = () => {
			history.push("/piper-academy/Introduction-to-Busy-Mode");
			trackEvent("login_popup_clicks", {
				bannerPurpose: "food preparation time banner",
				country: this.props?.biz?.country?.toLowerCase()
			});
			this.handleClose();
		};

		const handleRedirect = () => {
			// need to remove in next release
			const isAdvancedAnalyticsAccessible =
				(this.props.access.isSalesAnalysis || this.props.access.isOperationsAnalysis) &&
				(this.props.access.analyticsAccess || this.props.access.tempAnalyticsAccess);

			history.push(isAdvancedAnalyticsAccessible ? "/analytics/home" : "/summary");
		};

		return (
			<div>
				<div className="dashboard-section dashboard-section--analytics section-container-common">
					<div className="credits-section-header transactions-list-header">
						<div className="header-text">
							<div className="title">Business Overview</div>
							<div className="subtitle">
								Performance indicators of your{" "}
								<span className="highlight">{productType === "ORDERING" ? "online" : "in-store"}</span>{" "}
								business
							</div>
						</div>
					</div>

					{/* need to remove in next release */}
					<div className="deprecate-dashboard-banner">
						<div>
							<img className="deprecate-warning-icon" src="/assets/icons/warning-icon.svg" alt="" />
						</div>
						<div>
							<div className="deprecate-warning-message">
								Effective March 15th, this screen will be discontinued. Access all analytics directly
								from the Analytics tab.
							</div>
							<div onClick={handleRedirect} className="deprecate-warning-cta">
								<div className="deprecate-warning-cta-text">Go to Analytics</div>
								<ButtonIcon icon="arrow-right" color="#3B5FEB" />
							</div>
						</div>
					</div>
					<div className="dashboard-filters">
						{isMultibrandEnabled && (
							<SelectFilterCustom
								options={brands.items}
								isLoading={brands.isLoading}
								field="brands"
								currValue={selectedBrand}
								setFilter={(f, value) => this.handleBrand(value)}
								labelKey={"name"}
								valueKey={"id"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								isClearable={true}
								renderLabel={this.handleBrandsLabelOption}
								renderOptions={this.handleBrandsLabelOption}
								placeholder="Select brand"
								classes="brand-filter"
							/>
						)}
						<NewDateCompareFilter
							showDropdown={true}
							loading={loading}
							currentDateFilter={currentDateFilter}
							appliedDateFilter={appliedDateFilter}
							updateState={this.updateDashboardState}
							hideCustomDate={true}
							hideComparison={true}
							hidePresetTypes={["15 D"]}
						/>
						{/* <SelectFilter
							options={productTypeOptions}
							currValue={productTypeOptions.find((opt) => opt.value === productType)}
							setFilter={(field, value) => this.changeModule(value.value)}
							isClearable={false}
						/> */}
					</div>
					<Topbar
						tabs={FORM_TABS}
						selectedTab={formTab}
						switchTab={this.handleFormTab}
						isStickyOnTop={true}
					/>
					{formTab === FORM_TABS[0].value && (
						<Analytics
							productType={productType}
							productTypeOptions={productTypeOptions}
							changeModule={this.changeModule}
							updateDashboardState={this.updateDashboardState}
							selectedBrand={selectedBrand}
							isMultibrandEnabled={isMultibrandEnabled}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<TopPerforming
							productType={productType}
							selectedBrand={selectedBrand}
							isMultibrandEnabled={isMultibrandEnabled}
						/>
					)}
				</div>
				<div className="dashboard-welcome-message">
					{this.props.configItems.dimensions.width > 768 && (
						<Modal
							isOpen={
								this.props.showCustomMessage && lS.get(this.bannerName) !== WELCOME_BANNER_DISPLAY_ID
							}
							close={this.handleClose}
							overlayOpacity="0.5"
							modalContainerStyles={{
								backgroundImage: "url('/assets/icons/banner-prep-time-feature-v2.svg')",
								backgroundSize: "cover",
								height: "450px",
								width: "800px"
							}}
							hideActions
						>
							<Button
								type="secondary"
								classes="whats-app-support-btn"
								clickHandler={openTargettedLocation}
							>
								Know more
								<span>
									<img src="/assets/icons/icon-right-arrow-violet.png" className="right-arrow-icon" />
								</span>
							</Button>
						</Modal>
					)}
				</div>
			</div>
		);
	}
}
