import React, { Component } from "react";

// components
import CreateCampaignWho from "../components/CreateCampaign/CreateCampaignWho";
import { BackNavigation } from "../components/_commons/BackNavigation";

// graphql
import { SAVE_SEGMENT, FETCH_SEGMENT_DETAILS, FILTERED_USERS_PREVIEW } from "../graphql/segments";

// client
import { client } from "../client";
import { store } from "../store/configureStore";
import { lS } from "../atlas-utils";
import { toggleGlobalLoader } from "../actions/actions";
import history from "../history";

// utils
import { ActionTypes } from "../actions/_types";

export default class CreateSegment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetchingDetails: false
		};
		this.defaultPrevLocation = {
			pathname: "/segments",
			title: "Segments"
		};
	}

	async componentDidMount() {
		const savedSegment = lS.get("savedSegment");
		const segmentId = savedSegment ? savedSegment.object.id : null;
		store.dispatch(toggleGlobalLoader(true));
		try {
			this.setState({
				isFetchingDetails: true
			});
			if (segmentId) {
				// segment already exists, fetch its details
				await this.fetchSegmentDetails(segmentId);
			} else {
				// segment doesn't exist, fetch users preview to show
				await this.fetchUsersPreview();
			}
			this.setState({
				isFetchingDetails: false
			});
		} catch (err) {
			console.log(err);
			alert("Something went horribly wrong, try agian.");
			history.push("/segments/");
		}
		store.dispatch(toggleGlobalLoader(false));
	}

	componentWillUnmount() {
		// clear out the current segment
		lS.remove("savedSegment");
		store.dispatch({
			type: "SEGMENT_RESET"
		});
	}

	fetchUsersPreview = async (filters = JSON.stringify({})) => {
		try {
			const resp = await client.query({
				query: FILTERED_USERS_PREVIEW,
				variables: {
					filters
				},
				fetchPolicy: "network-only"
			});
			const usersPreview = {
				total: resp.data.filteredUsersPreview.total,
				targeted: resp.data.filteredUsersPreview.targeted,
				percentageTargeted: resp.data.filteredUsersPreview.percentageTargeted
			};
			store.dispatch({
				type: "SEGMENT_UPDATE",
				payload: {
					usersPreview
				}
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	fetchSegmentDetails = async (id) => {
		const resp = await client.query({
			query: FETCH_SEGMENT_DETAILS,
			variables: {
				id: Number(id)
			},
			fetchPolicy: "network-only"
		});
		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload: {
				...resp.data.segment
			}
		});
	};

	createSegmentAndSave = async (filters = JSON.stringify({})) => {
		const resp = await client.mutate({
			mutation: SAVE_SEGMENT,
			variables: {
				title: store.getState().createSegment.title || "segment-custom",
				isImplicit: false,
				isActive: true,
				description: store.getState().createSegment.description || "",
				filters
			}
		});
		let segmentId = resp.data.saveSegment.object.id;

		lS.set("savedSegment", resp.data.saveSegment);

		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload: {
				id: segmentId,
				title: resp.data.saveSegment.object.title,
				isImplicit: resp.data.saveSegment.object.isImplicit,
				isActive: resp.data.saveSegment.object.isActive,
				usersPreview: resp.data.saveSegment.object.usersPreview
			}
		});

		return segmentId;
	};

	render() {
		const { isFetchingDetails } = this.state;
		const currLocation = {
			pathname: this.props.location.pathname,
			title: "Create Segment"
		};
		return (
			<React.Fragment>
				<BackNavigation
					prevLocation={this.props.location.state || this.defaultPrevLocation}
					currLocation={currLocation}
				/>
				<div className="campaign-section section-container-common">
					<CreateCampaignWho
						isFetchingDetails={isFetchingDetails}
						isSegmentWithCampaign={false}
						createSegmentAndSave={this.createSegmentAndSave}
						fetchUsersPreview={this.fetchUsersPreview}
						location={this.props.location}
					/>
				</div>
			</React.Fragment>
		);
	}
}
