import gql from "graphql-tag";

export const GET_LOCATION_GROUPS_LIST = gql`
	query getLocationGroupsList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		locationGroups(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				title
				description
				numAssociatedLocations
			}
		}
	}
`;

export const GET_LOCATION_GROUP = gql`
	query getLocationGroup($id: Int) {
		locationGroup(id: $id) {
			id
			title
			description
			includesAll
			numAssociatedTaxes
			numAssociatedCharges
			locations {
				count
			}
			isUsedInDataFilter
			isUserGenerated
		}
	}
`;

export const CREATE_LOCATION_GROUP = gql`
	mutation createLocationGroup($title: String!, $description: String, $isUsedInDataFilter: Boolean) {
		saveLocationGroup(
			input: { title: $title, description: $description, isUsedInDataFilter: $isUsedInDataFilter }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
				isUsedInDataFilter
				isUserGenerated
			}
		}
	}
`;

export const EDIT_LOCATION_GROUP = gql`
	mutation editLocationGroup(
		$id: Int
		$title: String!
		$description: String
		$isDeleted: Boolean
		$isUsedInDataFilter: Boolean
	) {
		saveLocationGroup(
			input: {
				id: $id
				title: $title
				description: $description
				isDeleted: $isDeleted
				isUsedInDataFilter: $isUsedInDataFilter
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
				isUsedInDataFilter
				isUserGenerated
			}
		}
	}
`;

export const GET_LOCATION_GROUP_LOCATIONS = gql`
	query getLocationGroupLocations(
		$id: Int
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$brandId: Int
	) {
		locationGroup(id: $id) {
			id
			locations(limit: $limit, offset: $offset, sort: $sort, filters: $filters, brandId: $brandId) {
				count
				objects {
					id
					title
					city
					address
					tags
				}
			}
		}
	}
`;
