import gql from "graphql-tag";

export const GET_ENTITY_USERS = gql`
	query userAssociations(
		$entityId: Int!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		groupUserAssociations(entityId: $entityId, limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			hasPrevious
			hasNext
			objects {
				id
				username
				email
				fullName
				isAssociated
			}
		}
	}
`;

export const UPDATE_ENTITY_USERS = gql`
	mutation associateDisassociateUsers($entityId: Int!, $usersToAssociate: [Int], $usersToDisassociate: [Int]) {
		updateGroupUsers(
			input: {
				groupId: $entityId
				usersToAssociate: $usersToAssociate
				usersToDisassociate: $usersToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
