import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ItemAvailability from "./ItemAvailability";
import SpecificLocationTable from "./SpecificLocationTable";
import SpecificItemTable from "./SpecificItemTable";

const ItemAvailabilityRoutes = () => {
	return (
		<>
			<Route exact path="/periscope/item-availability" render={() => <ItemAvailability />} />
			<Route exact path="/periscope/item-availability/location/:locationName" component={SpecificLocationTable} />
			<Route exact path="/periscope/item-availability/item/:itemName" component={SpecificItemTable} />
		</>
	);
};

export default ItemAvailabilityRoutes;
