import React, { useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { Modal } from "../_commons/Modal";
import { CommonTable } from "../_commons/CommonTable";

// actions
import {
	addMerakiWebWorkspaceDomain,
	fetchMerakiWebWorkspace,
	deleteMerakiWebWorkspaceDomain,
	fetchMerakiWebWorkspaceDomain
} from "../../actions/merakiWeb";
import { CopyToClipboard } from "../_commons/CopyToClipboard";

const columns = [
	{
		name: "Domain Name",
		field: "domain",
		sortKey: "",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text domain"} key={i}>
				{record.url || "--"}
			</div>
		)
	},
	{
		name: "Status",
		field: "verified",
		sortKey: "",
		render: (record, i, _, _1, handleTask) => (
			<div className={"at-table-cell at-cell-text verified"} key={i}>
				{record.verified ? (
					<span className="domain-verified">Connected</span>
				) : (
					<span className="domain-not-verified" onClick={() => handleTask("verify", record)}>
						Check DNS Configuration
					</span>
				)}
			</div>
		)
	},
	{
		name: "Action",
		field: "action",
		sortKey: "",
		render: (record, i, _, _1, handleTask) => (
			<div className={"at-table-cell at-cell-text action"} key={i}>
				<span className="action-item" onClick={() => handleTask("delete", record)}>
					DELETE
				</span>
				{record.type === "Alias" && (
					<span className="action-item" onClick={() => handleTask("primary", record)}>
						SET PRIMARY
					</span>
				)}
			</div>
		)
	}
];

const DOMAIN_FORM_INITIAL_STATE = {
	url: "",
	type: "Alias"
};
export const Domains = ({ workspace = {}, loading }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [domainVerification, setDomainVerification] = useState(false);
	const [domainForm, setDomainForm] = useState(DOMAIN_FORM_INITIAL_STATE);
	const [loadinDomainForm, setLoadingDomainForm] = useState(false);

	if (loading && !workspace.netlify) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	const handleAddDomain = () => {
		setModalOpen(true);
		setDomainForm(DOMAIN_FORM_INITIAL_STATE);
	};

	const handleClose = () => {
		setModalOpen(false);
		setDomainForm(DOMAIN_FORM_INITIAL_STATE);
	};

	const handleDomainVerification = (domain) => {
		setDomainVerification(true);
		fetchMerakiWebWorkspaceDomain(domain);
	};

	const handleCloseDomainVerification = () => {
		setDomainVerification(false);
	};

	const handleDomainForm = (field, value) => {
		const update = {
			...domainForm,
			[field]: value
		};
		setDomainForm(update);
	};

	const handleDomainFormSubmit = async () => {
		setLoadingDomainForm(true);
		try {
			if (workspace.netlify.domains.length === 0) {
				domainForm.type = "Primary";
			}
			await addMerakiWebWorkspaceDomain(domainForm);
			handleClose();
			fetchMerakiWebWorkspace();
			handleDomainVerification(domainForm);
		} catch (error) {
			console.log(error);
		}
		setLoadingDomainForm(false);
	};

	const handleDomainDelete = async (domain) => {
		setLoadingDomainForm(true);
		try {
			await deleteMerakiWebWorkspaceDomain(domain);
			fetchMerakiWebWorkspace();
		} catch (error) {
			console.log(error);
		}
		setLoadingDomainForm(false);
	};

	const handleDomainActions = (type, domain) => {
		if (type === "delete") {
			handleDomainDelete(domain);
		} else if (type === "verify") {
			handleDomainVerification(domain);
		}
	};

	return (
		<div className={"domains-container " + (loading && workspace.netlify ? "disabled" : "")}>
			<div className="domain-header">
				<div className="title">Manage your domains here</div>
				<div className="action">
					<Button clickHandler={handleAddDomain}>Add domain</Button>
				</div>
			</div>
			<CommonTable
				loading={loading}
				data={workspace.netlify.domains || []}
				columns={columns}
				classes="domains-list-table-container"
				content="Domains"
				handleTask={handleDomainActions}
			/>
			<Modal
				isOpen={isModalOpen}
				loading={loadinDomainForm}
				close={handleClose}
				title="Add domain"
				subTitle="Enter the domain you want to connect"
				showSubmitAction={true}
				submitTitle="Add"
				submitAction={handleDomainFormSubmit}
				showCancelAction={true}
				cancelTitle="Close"
				disabled={domainForm.url === "" || loadinDomainForm ? true : false}
			>
				<div className="add-domain-container">
					<InputWithLabel
						value={domainForm.url}
						onChange={(e) => handleDomainForm("url", e.target.value)}
						placeholder="e.g. order.urbanpiper.com"
					/>
				</div>
			</Modal>
			<Modal
				isOpen={domainVerification}
				loading={loadinDomainForm}
				close={handleCloseDomainVerification}
				title="Configure DNS"
				//subTitle="Set this CNAME in your DNS configuration"
				showSubmitAction={false}
				showCancelAction={true}
				cancelTitle="Close"
			>
				<div className="cname-container">
					<p className="cname-header">CNAME</p>
					<p className="cname-sub-header">Set this CNAME in your DNS configuration</p>
					<div className="cname-url">
						{workspace.netlify.cname}
						<CopyToClipboard content={workspace.netlify.cname} showIcon={true} />
					</div>
					<p className="a-records-header">A records</p>
					<p className="cname-sub-header">Add A records to your DNS settings for reliability</p>
					{workspace?.netlify?.aRecords?.length > 0 && (
						<>
							{workspace.netlify.aRecords.map((rec, i) => (
								<div className="cname-url a-record">
									{rec}
									<CopyToClipboard content={rec} showIcon={true} />
								</div>
							))}
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};
