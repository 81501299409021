// clients
import { client } from "../client";
import { clientMenu } from "../client-menu";
import { store } from "../store/configureStore";

// graphql
import {
	GET_ITEMS_LIST,
	GET_ITEM,
	EDIT_ITEM,
	VALIDATE_ITEM_HANDLE,
	UPDATE_ITEM_LOCATION_FIELDS,
	UPDATE_ITEM_PLATFORMS,
	GET_ITEM_MENU_SERVICE
} from "../graphql/items";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// constants
import { FOOD_TYPES, FOOD_TYPE_MAP } from "../client-config";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchItemsList = async (brand = null) => {
	const { limit, offset, sort, appliedFilters } = store.getState().itemsListState;
	const {
		searchFieldSelected = {},
		searchFieldValue = "",
		item_discrete_category_id = null
	} = store.getState().itemsList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CATALOGUE_ITEMS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset,
			brand: brand !== null ? String(brand) : brand
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_enabled") {
					// this value is being reversed because the behaviour of
					// is_enabled filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(!appliedFilters[f])
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_enabled",
				value: "true"
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}

		if (item_discrete_category_id) {
			filtersObject.push({
				field: "item_discrete_category_id",
				value: item_discrete_category_id?.value
			});
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_ITEMS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_ITEMS_LIST_SUCCESS,
			payload: { ...resp.data.items }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_ITEMS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const verifyHandle = async (value = "") => {
	try {
		const variables = {};
		let filtersObject = [{ field: "merchant_ref_id", value: value }];
		variables.filters = filtersObject;

		const resp = await client.query({
			query: VALIDATE_ITEM_HANDLE,
			variables,
			fetchPolicy: "no-cache"
		});
		return resp.data.items.count;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

const parseItemData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			const foodObject = FOOD_TYPES.find((fd) => fd.label === FOOD_TYPE_MAP[result["foodType"]]);
			if (foodObject) {
				result["foodType"] = foodObject;
			}
			// if (result['fulfillmentModes'].length) {
			// 	result.fulfillmentModes = result.fulfillmentModes.map((fl) => parseInt(fl.id));
			// }
			if (result["tags"].length) {
				result.tags = result.tags.map((tag) => ({
					title: tag,
					titleForDisplay: tag
				}));
			}
			result.currentItemPrice = result?.itemPrice;
			break;
		case "server":
			if (result["foodType"]) {
				result["foodType"] = result["foodType"].value;
			}
			if (result["itemDiscreteCategory"]) {
				result["itemDiscreteCategory"] = parseInt(result["itemDiscreteCategory"].id);
			}
			if (result?.["fulfillmentModes"]?.length) {
				result.fulfillmentModes = result.fulfillmentModes.map((fl) => {
					if (fl.id) {
						return parseInt(fl.id);
					}
					return parseInt(fl);
				});
			}
			if (result["tags"]) {
				// pick tag's value if newly created else pick tag's title
				result.tags = result.tags.map((tag) => (tag["__isNew__"] ? tag.value : tag.title));
			}
			if (result["timingsGroup"]) {
				result["timingsGroup"] = parseInt(result["timingsGroup"].id);
			}
			if (result["currentItemPrice"]) {
				delete result["currentItemPrice"];
			}
			if (!result["serves"]) {
				result["serves"] = null;
			}
			if (result["itemGroups"]) {
				result.itemGroups = result?.itemGroups?.objects.map((itemGroup) => parseInt(itemGroup.id)) || [];
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchItemDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_CATALOGUE_ITEM_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_ITEM,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_CATALOGUE_ITEM_DETAIL_SUCCESS,
			payload: parseItemData(resp.data.item, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_CATALOGUE_ITEM_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Item details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchMenuServiceItemDetail = async (dispatch, menuId, categoryId, itemId) => {
	store.dispatch({
		type: ActionTypes.GET_CATALOGUE_ITEM_DETAIL_REQUEST
	});
	try {
		const variables = {
			menuId,
			categoryId,
			itemId
		};

		const resp = await clientMenu.query({
			query: GET_ITEM_MENU_SERVICE,
			variables,
			fetchPolicy: "no-cache"
		});
		if (resp?.data?.item) {
			const parsedData = parseItemData(resp.data.item, "form");
			dispatch({
				type: ActionTypes.GET_CATALOGUE_ITEM_DETAIL_SUCCESS,
				payload: {
					...parsedData,
					itemDiscreteCategory: {
						id: parsedData?.category.id,
						name: parsedData?.category.name,
						isActive: true
					}
				}
			});
		} else {
			dispatch({
				type: ActionTypes.GET_CATALOGUE_ITEM_DETAIL_FAILURE,
				error: {
					message: "There was an error while fetching Item details"
				}
			});
		}
	} catch (e) {
		console.log(e);
		dispatch({
			type: ActionTypes.GET_CATALOGUE_ITEM_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Item details"
			}
		});
	}
};

export const editItem = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_ITEM,
			variables: parseItemData(data, "server")
		});
		if (resp.data.saveItem.status.success) {
			dispatch({
				type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Item saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_CATALOGUE_ITEMS_LIST,
				payload: resp.data.saveItem.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveItem.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Item details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreItem = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_ITEM,
			variables: parseItemData(data, "server")
		});
		if (resp.data.saveItem.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveItem.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const updateItemLocationFields = async (variables, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: UPDATE_ITEM_LOCATION_FIELDS,
			variables
		});
		if (resp.data.updateItemLocationFields.status.success) {
			dispatch({
				type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Item customizations saved!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.updateItemLocationFields.status.messages),
					timeout: 5000,
					error: true
				}
			});
			dispatch({
				type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_FAILURE,
				error: {}
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Item's Price at Locations",
				fields: error
			}
		});
	}
};

export const updateItemPlatforms = async (variables, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: UPDATE_ITEM_PLATFORMS,
			variables
		});
		if (resp.data.updateItemPlatforms.status.success) {
			dispatch({
				type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Item's platform association has been updated!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.updateItemPlatforms.status.messages),
					timeout: 5000,
					error: true
				}
			});
			dispatch({
				type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_FAILURE,
				error: {}
			});
			return false;
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Item's Platform association",
				fields: error
			}
		});
		return false;
	}
};
