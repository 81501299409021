import React, { useState, useEffect } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import { SelectFilter } from "../_commons/SelectFilter";
import { Filters } from "./LocationsAndMenu";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { Loading } from "../_commons/Loading";
import Popover from "../_commons/Popover";

// third party
import { connect } from "react-redux";

// utils
import { capitaliseText } from "../../atlas-utils";
import { PLATFORM_MAP } from "../Hub/Integrations";

// actions
import { fetchMenusDebounced } from "../../actions/actions";
import { handleDspActivateStoresAndPublishMenu } from "../../actions/dspIntegration";

// constants
const columns = [
	{
		name: <div className="name">Store Name</div>,
		field: "up-stores",
		render: (record, i, rest) => (
			<div className="table-cell up-stores" key={i}>
				<div className="name">
					{rest?.associationUpdates?.[record?.id]?.location?.name || record?.location?.name}
				</div>
				<div className="description">
					{PLATFORM_MAP[record?.platformName] || capitaliseText(record?.platformName)}: {record?.locationName}
				</div>
			</div>
		)
	},
	{
		name: <div className="name">Menu</div>,
		field: "menu",
		render: (record, i, rest) => {
			const currValue = rest?.associationUpdates?.[record?.id]?.associatedMenu
				? rest?.associationUpdates?.[record?.id]?.associatedMenu
				: record?.associatedMenu || null;
			return (
				<div className="table-cell menu" key={i}>
					<SelectFilter
						options={rest?.menus?.items || []}
						isLoading={rest?.menus?.isLoading}
						field="associatedMenu"
						currValue={currValue}
						setFilter={(field, value) => rest.handleAssociationUpdates(record, field, value)}
						isAsync={true}
						isSearchable={true}
						isClearable={false}
						readOnly={record?.dspSuccess}
						handleSearch={(search) => fetchMenusDebounced(search)}
						labelKey="name"
						valueKey="menuId"
						placeholder="Associate Menu"
					/>
					{record?.dspErrorMessage && (
						<Popover
							showOnHover={true}
							renderPopover={() => rest.renderPopover(record, rest)}
							position="down-right"
						>
							<ButtonIcon icon="error-circle" color="#D64949" />
						</Popover>
					)}
				</div>
			);
		}
	},
	{
		name: <div className="name">Status</div>,
		field: "status",
		render: (record, i, rest) => (
			<div className="table-cell status" key={i}>
				{rest?.loading ? (
					<Loading circular />
				) : (
					<div className={"tag " + (record?.dspSuccess ? "success" : "failed")}>
						{record?.dspSuccess ? "Success" : "Failed"}
					</div>
				)}
			</div>
		)
	}
];

const MenuStatus = ({
	isOpen = false,
	close,
	isNested = false,
	dspIntegration = {},
	fetchDspPlatformLocationsList,
	handleSearchFilter,
	handleStatusFilter,
	handlePagination,
	handlePageSize,
	handleAssociationUpdates,
	updateDspIntegrationState,
	selectedMenuStatusType = {},
	menus
}) => {
	const [isLoading, setLoading] = useState(false);
	const { limit, offset, loading, data, platform, associationUpdates, searchFieldValue, appliedFilters } =
		dspIntegration;

	useEffect(() => {
		if (isOpen) {
			fetchDspPlatformLocationsList(true);
		}
	}, [isOpen]);

	const handleCancel = () => {
		close();
	};

	const renderTitle = () => {
		return selectedMenuStatusType.name || "Menu and Integration Status";
	};

	const renderPopover = (record, rest) => {
		return (
			<div className="error-message">
				{record?.dspStatus && <div className="title-text">{record.dspStatus}</div>}
				<div className="message">{record.dspErrorMessage || "--"}</div>
			</div>
		);
	};

	const handleRetryAll = async () => {
		setLoading(true);
		await handleDspActivateStoresAndPublishMenu(
			true,
			data?.objects?.filter((loc) => !loc?.dspSuccess)?.map((loc) => loc?.location?.id)
		);
		await fetchDspPlatformLocationsList(true);
		setLoading(false);
	};

	return (
		<div className="dsp-menu-status">
			<FormSidebar
				isOpen={isOpen}
				close={handleCancel}
				title={renderTitle()}
				hideActions={true}
				isNested={isNested}
			>
				<div className="form-content">
					<Filters
						searchFieldValue={searchFieldValue}
						handleSearchFilter={handleSearchFilter}
						statusFilter={data?.filters?.find((f) => f.field === "store_status") || {}}
						handleStatusFilter={handleStatusFilter}
						statusValue={appliedFilters["store_status"] || null}
						showStoreStatus={false}
						// showActivationStatus={true}
					/>
					<div className="retry-all" onClick={handleRetryAll}>
						<img src="/assets/icons/icon-blue-reset.svg" alt="" />
						<div>Retry All</div>
					</div>
					<CustomTable
						bordered={true}
						loading={loading || isLoading}
						data={data?.objects || []}
						columns={columns}
						classes="locations-table-container"
						content="locations"
						menus={menus}
						dsp={platform}
						associationUpdates={associationUpdates}
						handleAssociationUpdates={handleAssociationUpdates}
						renderPopover={renderPopover}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={data?.count || 0}
						goToPage={handlePagination}
						setPageSize={handlePageSize}
						showPageSize={true}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	stores: store.configItems.stores,
	menus: store.configItems.menus
});
export default connect(mapStateToProps)(MenuStatus);
