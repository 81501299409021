import React from "react";

// components
import { ButtonIcon } from "../../_commons/ButtonIcon";

// third party
import { Link } from "react-router-dom";

const ActionButton = ({
	variant,
	href = "",
	clickHandler = () => {},
	color = "#2f58f2",
	showArrow = true,
	children
}) => {
	return (
		<div className="action-btn" onClick={clickHandler}>
			{variant === "link" && href ? (
				<Link to={href}>
					{children}
					{showArrow && (
						<span>
							<ButtonIcon icon="arrow-right" color={color} />
						</span>
					)}
				</Link>
			) : (
				<div>
					{children}
					{showArrow && (
						<span>
							<ButtonIcon icon="arrow-right" color={color} />
						</span>
					)}
				</div>
			)}
		</div>
	);
};
export default ActionButton;
