import React from "react";

// third party
import { Link } from "react-router-dom";

export const BackNavigation = ({ prevLocation, currLocation }) => {
	const location = {
		pathname: prevLocation.pathname,
		state: currLocation
	};
	return (
		<div className="back-navigation-arrow">
			<Link to={location}>
				<img src="/assets/icons/ic_left_arrow_blue.svg" />
				<span className="navigation-title">{prevLocation.title}</span>
			</Link>
		</div>
	);
};
