import React, { useState, useEffect, useCallback } from "react";

// components
import { Button } from "../_commons/Button";
import { CustomTable } from "../_commons/CustomTable";
import { CheckBox } from "../_commons/CheckBox";
import { Paginator } from "../_commons/Paginator";
import AssociateBrands from "./AssociateBrands";
import RemoveBrands from "./RemoveBrands";

// third party
import { connect } from "react-redux";
import { Transition, animated, config } from "react-spring/renderprops";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { extractInitials, capitaliseText, msaagesArrayToHtml } from "../../atlas-utils";

// graphql
import { GET_LOCATION_BRANDS_LIST, REMOVE_BRANDS_LOCATIONS } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchLocationDetail } from "../../actions/locations";

// constants
import { CATALOGUE_PLATFORMS_LOGO, BRAND_COLORS } from "../../client-config";
const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, rest) => {
			const status =
				rest?.brands?.[record?.brand?.id] === undefined ? rest?.toCheck : rest?.brands?.[record?.brand?.id];
			return (
				<div className="table-cell name" key={i}>
					{rest.checkbox && (
						<CheckBox
							checked={status}
							clickHandler={(e) => rest.handleCheck(e, record?.brand?.id, !status)}
						/>
					)}
					<div className="brand-info" title={record.brand.name}>
						<div className={"brand-initials " + record.brand.color}>
							{record.brand.image ? (
								<img src={record.brand.image} alt="" />
							) : (
								rest.extractInitials(record.brand.name?.split(" "))
							)}
						</div>
						<div className="brand-name">{record.brand.name || ""}</div>
					</div>
				</div>
			);
		}
	},
	{
		name: "Location ID",
		field: "location-id",
		render: (record, i) => (
			<div className="table-cell location-id" key={i}>
				{record.id || "--"}
			</div>
		)
	},
	{
		name: "Assoc. Items",
		field: "items-count",
		render: (record, i) => (
			<div className="table-cell items-count" key={i}>
				{record.ilaCount || 0}
			</div>
		)
	},
	{
		name: "Assoc. Platforms",
		field: "assoc-platforms",
		render: (record, i) => (
			<div className="table-cell assoc-platforms" key={i}>
				{record.associatedPlatforms.map((plf, j) => (
					<div className="platform" key={j}>
						<img
							title={`${capitaliseText(plf.platformName)}: ${plf.state}`}
							src={
								plf?.platform?.logo ||
								CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
								"/assets/icons/icons8-globe-40.png"
							}
							alt=""
						/>
						<div className={"state " + plf.state?.toLowerCase()?.split(" ")?.join("-")}></div>
					</div>
				))}
			</div>
		)
	}
];

const Brands = ({ locationId, readOnly, handleReloadData }) => {
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [brands, setBrands] = useState({});
	const [brandsData, setBrandsData] = useState([]);
	const [contextMenu, setContextMenu] = useState({ id: null, record: null });
	const [addBrandsOpen, setAddBrandsOpen] = useState(false);
	const [removeBrandsOpen, setRemoveBrandsOpen] = useState(false);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [brandsList, setBrandsList] = useState({
		count: 0,
		objects: []
	});
	let brandsCount = Object.values(brands).filter((val) => val === true).length;

	const fetchAssociatedBrands = async () => {
		setLoading(true);
		try {
			const variables = {
				limit,
				offset,
				filters: [
					{
						field: "is_active",
						value: "true"
					}
				],
				locationId
			};
			const resp = await client.query({
				query: GET_LOCATION_BRANDS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			setBrandsList({
				...resp.data.brandLocations,
				objects: resp.data.brandLocations.objects.map((loc) => ({
					...loc,
					brand: {
						...loc.brand,
						color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
					}
				}))
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchAssociatedBrands();
	}, [offset]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
		},
		[limit]
	);

	const handleRemoveBrand = useCallback(
		async (record) => {
			closeContextMenu();
			setLoading(true);
			try {
				const variables = {
					brandIds: [record?.brand?.id],
					locationIds: [locationId],
					isActive: false
				};
				const resp = await client.mutate({
					mutation: REMOVE_BRANDS_LOCATIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				if (resp.data.removeBrandsLocations.status.success) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: `Removed brand successfully`,
							timeout: 2000,
							error: false
						}
					});
					if (offset === 0) {
						fetchAssociatedBrands();
					} else {
						setOffset(0);
					}
					setBrands({});
					setBrandsData([]);
				} else {
					// handle error message
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: msaagesArrayToHtml(resp.data.removeBrandsLocations.status.messages),
							timeout: 5000,
							error: true
						}
					});
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[locationId, offset]
	);

	const handleBulkRemoveBrand = useCallback(async () => {
		setLoading(true);
		try {
			const variables = {
				brandIds: brandsData.map((loc) => loc?.brand?.id),
				locationIds: [locationId],
				isActive: false
			};
			const resp = await client.mutate({
				mutation: REMOVE_BRANDS_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.removeBrandsLocations.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: `Removed ${brandsCount > 1 ? "brands" : "brand"} successfully`,
						timeout: 2000,
						error: false
					}
				});
				if (offset === 0) {
					fetchAssociatedBrands();
				} else {
					setOffset(0);
				}
				setBrands({});
				setBrandsData([]);
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.removeBrandsLocations.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [locationId, brandsData, brandsCount, offset]);

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => setRemoveBrandsOpen(true)} style={{ color: "#FF425C" }}>
					Remove Brand
				</div>
			</React.Fragment>
		);
	};

	const openContextMenu = (record = {}) => {
		setContextMenu({
			id: contextMenu?.id && contextMenu?.id === record?.id ? null : record?.id,
			record
		});
		setBrandsData([record]);
	};

	const closeContextMenu = () => {
		setContextMenu({
			id: null,
			record: null
		});
	};

	const handleCheck = (e, id, toCheck) => {
		e.stopPropagation();
		let brandUpdates = brandsData.filter((loc) => brands[loc?.brand?.id]);
		if (toCheck) {
			brandUpdates.push(brandsList.objects.find((loc) => loc?.brand?.id === id));
		} else {
			brandUpdates = brandUpdates.filter((loc) => loc?.brand?.id !== id);
		}
		setBrands({
			...brands,
			[id]: toCheck
		});
		setBrandsData(brandUpdates);
		if (!toCheck) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (e, toCheckAll) => {
		e.stopPropagation();
		if (toCheckAll) {
			let updates = {};
			brandsList.objects.forEach((loc) => {
				updates[loc?.brand?.id] = toCheckAll;
			});
			let brandUpdates = brandsList.objects.filter((loc) => !brands[loc?.brand?.id]);
			setIsCheckedAll(toCheckAll);
			setBrands({
				...brands,
				...updates
			});
			setBrandsData([...brandsData.filter((loc) => brands[loc?.brand?.id]), ...brandUpdates]);
		} else {
			let updates = {};
			brandsList.objects.forEach((loc) => {
				updates[loc?.brand?.id] = toCheckAll;
			});
			let brandUpdates = brandsData.filter((loc) => !(loc?.brand?.id in updates));
			setIsCheckedAll(toCheckAll);
			setBrands({
				...brands,
				...updates
			});
			setBrandsData([...brandUpdates]);
		}
	};

	const clearSelection = () => {
		setBrands({});
		setBrandsData([]);
	};

	const openAssociationSidebar = () => {
		setAddBrandsOpen(true);
	};

	const closeAssociationSidebar = async (success = false) => {
		setAddBrandsOpen(false);
		setRemoveBrandsOpen(false);
		if (success) {
			if (offset === 0) {
				await fetchAssociatedBrands();
			} else {
				setOffset(0);
			}
			setBrands({});
			setBrandsData([]);
			setIsCheckedAll(false);
			setTimeout(async () => {
				await handleReloadData();
			}, 100);
		}
	};

	return (
		<div className="associated-brands-container">
			<div className="header-container">
				<div>
					<div className="header-text">Associated Brands</div>
					<div className="header-subtext">Manage brands that are available at this location</div>
				</div>
				<div>
					{!readOnly && (
						<div>
							<Button clickHandler={openAssociationSidebar}>Associate Brands</Button>
						</div>
					)}
				</div>
			</div>
			<Transition
				native
				items={brandsCount > 0}
				from={{ opacity: 0, height: 0 }}
				enter={{ opacity: 1, height: "auto" }}
				leave={{ opacity: 0, height: 0 }}
				config={config.stiff}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div style={props}>
							<div className="bulk-actions">
								<div className="count">
									{brandsCount} brands(s) selected
									<span onClick={clearSelection}>Clear selection</span>
								</div>
								<div className="actions-container">
									<div
										className="action archive"
										onClick={() => setRemoveBrandsOpen(true)}
									>{`Remove ${brandsCount > 1 ? "Brands" : "Brand"}`}</div>
								</div>
							</div>
						</animated.div>
					))
				}
			</Transition>
			<CustomTable
				columns={columns}
				data={brandsList.objects}
				bordered={true}
				loading={loading}
				extractInitials={extractInitials}
				brands={brands}
				checkbox={!readOnly}
				disableContextMenu={brandsCount > 0}
				toCheck={false}
				handleCheck={handleCheck}
				handleCheckAll={handleCheckAll}
				isCheckedAll={isCheckedAll}
				showContextMenu={!readOnly}
				contextMenuId={contextMenu.id}
				renderMenuItems={renderMenuItems}
				openContextMenu={openContextMenu}
				closeContextMenu={closeContextMenu}
				classes="associated-brands-list-container"
				content="Brands"
			/>
			<Paginator limit={limit} offset={offset} count={brandsList.count} goToPage={handlePagination} />
			<AssociateBrands isOpen={addBrandsOpen} close={closeAssociationSidebar} locationId={locationId} />
			<RemoveBrands
				isOpen={removeBrandsOpen}
				close={closeAssociationSidebar}
				locationId={locationId}
				data={brandsData}
			/>
		</div>
	);
};
export default Brands;
