import React, { useEffect } from "react";

// components
import Header from "./Header";
import ReportFilters from "./ReportFilters";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

const SelectFilters = ({
	reportDetail,
	scheduleReportCreate,
	isFormTouched = false,
	setFormTouched,
	isMultibrandEnabled = false
}) => {
	const { loading, data, currentFilters, validations, optionUpdates, isCheckedAll } = reportDetail;
	const { scheduleReportData } = scheduleReportCreate;

	useEffect(() => {
		if (isMultibrandEnabled && data?.filters?.find((f) => f.field === "locations")) {
			handleLocationsFilter();
		}
	}, [isMultibrandEnabled, optionUpdates.brands]);

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload
		});
	};

	const handleLocationsFilter = () => {
		// custom logic to handle locations filter for multi brand biz based on brands selected
		const updates = { ...optionUpdates };
		const isCheckedAllUpdates = { ...isCheckedAll };
		const selectedBrands = Object.keys(updates.brands)?.filter((key) => updates?.brands?.[key]);
		const selectedLocations = Object.keys(updates.locations)?.filter((key) => updates?.locations?.[key]);

		const updatedLocationsList = data.locationsList.filter((loc) => {
			const isBrandSelected = selectedBrands.includes(loc?.brandId);
			if (!isBrandSelected) {
				updates.locations = {
					...(updates?.locations ?? {}),
					[loc.value]: false
				};
			}
			return isBrandSelected;
		});

		if (
			!isCheckedAllUpdates.brands ||
			(isCheckedAllUpdates.brands && selectedLocations?.length !== data.locationsList?.length)
		) {
			isCheckedAllUpdates["locations"] = false;
		}

		updateReportDetail({
			data: {
				...data,
				filters: data.filters.map((f) => {
					if (f.field === "locations") {
						f.values = updatedLocationsList;
					}
					return f;
				})
			},
			optionUpdates: updates,
			isCheckedAll: isCheckedAllUpdates
		});
	};

	const setFilter = (field, value) => {
		const filters = {
			...currentFilters,
			[field]: value
		};
		if (field === "showDataFor") {
			const scheduleForFilter = scheduleReportData?.scheduleReportFilters?.find((f) => f.field === "scheduleFor");
			filters["scheduleFor"] =
				value.value === "Previous Day"
					? scheduleForFilter?.values?.[0]
					: value.value === "Previous Week"
					? scheduleForFilter?.values?.[1]
					: scheduleForFilter?.values?.[2];
		}
		updateReportDetail({
			currentFilters: filters,
			appliedFilters: filters,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCheck = (state, field, option, props) => {
		const updates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[option[props.valueKey]]: state
			}
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: false
		};
		updateReportDetail({
			optionUpdates: updates,
			isCheckedAll: isCheckedAllUpdates,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCheckAll = (state, field, options, props) => {
		const filterOptions = {};
		options.forEach((opt) => {
			filterOptions[opt[props.valueKey]] = state;
		});
		const updates = {
			...optionUpdates,
			[field]: filterOptions
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: state
		};
		updateReportDetail({
			optionUpdates: updates,
			isCheckedAll: isCheckedAllUpdates,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	return (
		<div className="select-report-filters">
			<Header loading={loading} title={data?.name || ""} description={data?.description || ""} />
			{!data?.filters?.length && loading ? (
				<div className="filters-container">
					<div className="shimmer W(330px) H(60px)"></div>
					<div className="shimmer W(330px) H(60px)"></div>
					<div className="shimmer W(330px) H(60px)"></div>
					<div className="shimmer W(330px) H(60px)"></div>
				</div>
			) : (
				<div className="filters-container">
					<ReportFilters
						options={[
							...(scheduleReportData?.scheduleReportFilters?.filter((f) => f.field === "showDataFor") ??
								[]),
							...(data?.filters ?? [])
						]}
						filtersToDisplay={["showDataFor", ...(data.filters?.map((f) => f.field) ?? [])]}
						setFilter={setFilter}
						currentFilters={currentFilters}
						optionUpdates={optionUpdates}
						handleCheck={handleCheck}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						validations={validations}
						showFields={true}
						isMultibrandEnabled={isMultibrandEnabled}
					/>
				</div>
			)}
		</div>
	);
};
export default SelectFilters;
