import React from "react";

export const PageSwitch = ({ handlePagination, offset, limit, count }) => {
	const currentPage = parseInt(offset / limit) + 1;
	const totalPages = Math.ceil(count / limit);
	return (
		<div className="at-page-switch-container">
			<div className="page-switch-area-container">
				{currentPage === 1 ? (
					<img className="nav-icon--disabled" src="/assets/icons/icon-circular-left-arrow.svg" />
				) : (
					<img
						className="nav-icon"
						src="/assets/icons/icon-circular-left-arrow.svg"
						onClick={() => handlePagination(currentPage - 1)}
					/>
				)}
				<div className="count-span">
					<span>{currentPage}</span>
					<span className="count-caption">Page</span>
				</div>
				{currentPage === totalPages ? (
					<img className="nav-icon--disabled" src="/assets/icons/icon-circular-right-arrow.svg" />
				) : (
					<img
						className="nav-icon"
						src="/assets/icons/icon-circular-right-arrow.svg"
						onClick={() => handlePagination(currentPage + 1)}
					/>
				)}
			</div>
		</div>
	);
};
