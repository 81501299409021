// clients
import { client } from "../client";
import { store } from "../store/configureStore";
import { clientMenu } from "../client-menu";

// third party
import { debounce } from "lodash";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// graphql
import { GET_BIZ_PLATFORMS_MINI } from "../graphql/locations";
import {
	ARCHIVE_MENU,
	CANCEL_SCHEDULED_PUBLISH,
	DELETE_RULE,
	GET_MENU,
	GET_MENUS_LIST,
	GET_MENU_METADATA,
	GET_MENU_VERSIONS,
	GET_PARENT_SECTIONS_LIST,
	GET_PUBLISH_LOCATIONS_LIST,
	PREVIEW_MENU,
	RESTORE_MENU,
	UPDATE_MENU,
	MENU_PREVIEW_VALIDATION_STATUS,
	MENU_PUBLISH_VALIDATION_STATUS,
	UPDATE_MENU_V2,
	UPDATE_RULE_STATUS,
	MENU_CATEGORIES_LIST,
	CREATE_TEST_CART,
	ADD_ITEMS_TEST_CART,
	RESET_CART,
	PLACE_TEST_ORDER,
	UPDATE_TEST_CART,
	REMOVE_ITEMS_TEST_CART
} from "../graphql/menus";

// utils
import { removeProp } from "../atlas-utils";

// services
import NotificationServices from "../services/NotificationService";

export const fetchMenusList = async (brandId, isActive = true, publishStatus = "", offset = 0, searchQuery = "") => {
	const { login } = store.getState();

	const { loggedInbizDetail } = login;
	const bizId = loggedInbizDetail?.id;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MENUS_LIST_REQUEST
	});

	try {
		const variables = {
			bizId: String(bizId),
			brands: brandId ? (brandId === "all" ? [] : [String(brandId)]) : [],
			status: isActive ? "active" : "archived",
			publishStatus,
			limit: 10,
			offset: offset,
			searchKeyword: searchQuery
		};

		const respMenu = await clientMenu.query({
			query: GET_MENUS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});

		if (respMenu?.data?.menus) {
			store.dispatch({
				type: ActionTypes.GET_MENUS_LIST_SUCCESS,
				payload: respMenu?.data?.menus
			});
		} else {
			store.dispatch({
				type: ActionTypes.GET_MENUS_LIST_FAILURE
			});
		}
	} catch (e) {
		NotificationServices.pushNotification({
			message: "Failed to fetch menus list",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});

		store.dispatch({
			type: ActionTypes.GET_MENUS_LIST_FAILURE
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
export const fetchMenusListDebounced = debounce(
	(brandId, isActive = true, publishStatus = "", offset = 0, searchQuery = "") =>
		fetchMenusList(brandId, isActive, publishStatus, offset, searchQuery),
	500
);

export const fetchMenuDetails = async (menuId) => {
	if (!menuId) {
		store.dispatch({
			type: ActionTypes.GET_MENU_DETAILS_FAILURE
		});
		NotificationServices.pushNotification({
			message: "Failed to fetch menu details",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
		return;
	}
	try {
		store.dispatch(toggleGlobalLoader(true));
		store.dispatch({
			type: ActionTypes.GET_MENU_DETAILS_REQUEST
		});
		const variables = {
			id: String(menuId)
		};
		const respMenu = await clientMenu.query({
			query: GET_MENU,
			variables,
			fetchPolicy: "no-cache"
		});

		if (respMenu?.data?.menuV2) {
			store.dispatch({
				type: ActionTypes.GET_MENU_DETAILS_SUCCESS,
				payload: removeProp(respMenu?.data?.menuV2, "__typename")
			});

			return respMenu?.data?.menuV2;
		} else {
			NotificationServices.pushNotification({
				message: "Failed to fetch menu details",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			store.dispatch({
				type: ActionTypes.GET_MENU_DETAILS_FAILURE
			});
		}
	} catch (e) {
		console.log(e);
		NotificationServices.pushNotification({
			message: "Failed to fetch menu details",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
		store.dispatch({
			type: ActionTypes.GET_MENU_DETAILS_FAILURE
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const handleDestructiveSave = async (menuId, action, parentSectionSearchKey = "") => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		store.dispatch({
			type: ActionTypes.HANDLE_DESTRUCTIVE_SAVE_REQUEST
		});
		const variables = {
			id: String(menuId),
			menuData: {}
		};

		if (action === "SAVE") {
			variables.savePendingChanges = true;
		} else if (action === "DISCARD") {
			variables.discardPendingChanges = true;
		} else {
			store.dispatch({
				type: ActionTypes.HANDLE_DESTRUCTIVE_SAVE_FAILURE
			});
			NotificationServices.pushNotification({
				message: "Failed to save/discard pending changes",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}

		const respSaveDiscardAction = await clientMenu.mutate({
			mutation: UPDATE_MENU,
			variables: variables
		});

		if (respSaveDiscardAction?.data?.updateMenuV2) {
			store.dispatch({
				type: ActionTypes.HANDLE_DESTRUCTIVE_SAVE_SUCCESS,
				payload: respSaveDiscardAction?.data?.updateMenuV2
			});
			NotificationServices.pushNotification({
				message: `Pending changes ${action === "DISCARD" ? "discarded" : "saved"} successfully!`,
				timeout: 5000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
			fetchParentSectionsList(menuId, parentSectionSearchKey);
		} else {
			NotificationServices.pushNotification({
				message: "Failed to save/discard pending changes",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	} catch (e) {
		NotificationServices.pushNotification({
			message: "Failed to save/discard pending changes",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const archiveRestoreMenu = async (isToEnable, data) => {
	if (!data?.menuId) {
		return;
	}
	try {
		const respMenuArchiveRestore = await clientMenu.mutate({
			mutation: isToEnable ? RESTORE_MENU : ARCHIVE_MENU,
			variables: {
				id: data?.menuId
			}
		});
		if (isToEnable && respMenuArchiveRestore?.data?.restoreMenu) {
			return "success";
		}
		if (!isToEnable && respMenuArchiveRestore?.data?.archiveMenu) {
			return "success";
		}

		return "failure";
	} catch (e) {
		console.log(e);
	}
};

export const fetchParentSectionsList = async (menuId, searchKey = "", version = null) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		store.dispatch({
			type: ActionTypes.GET_PARENT_SECTIONS_REQUEST
		});
		const variables = {
			menuId,
			offset: 0,
			limit: 100,
			searchKeyword: searchKey,
			version
		};
		const respParentSectionsDetails = await clientMenu.query({
			query: GET_PARENT_SECTIONS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_PARENT_SECTIONS_SUCCESS,
			payload: removeProp(respParentSectionsDetails?.data?.categories, "__typename")
		});
	} catch (e) {
		console.log(e);
		store.dispatch({
			type: ActionTypes.GET_PARENT_SECTIONS_FAILURE
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
export const fetchParentSectionsListDebounced = debounce(
	(menuId, searchText) => fetchParentSectionsList(menuId, searchText),
	500
);

export const fetchMenuVersions = async (menuId, offset = 0) => {
	store.dispatch({
		type: ActionTypes.GET_PUBLISHED_VERSIONS_LIST_REQUEST
	});

	try {
		const menuVersionsResp = await clientMenu.query({
			query: GET_MENU_VERSIONS,
			variables: {
				menuId,
				limit: 10,
				offset
			},
			fetchPolicy: "no-cache"
		});

		if (menuVersionsResp?.data?.menu?.getVersionList) {
			store.dispatch({
				type: ActionTypes.GET_PUBLISHED_VERSIONS_LIST_SUCCESS,
				payload: menuVersionsResp?.data?.menu?.getVersionList
			});
		} else {
			store.dispatch({
				type: ActionTypes.GET_PUBLISHED_VERSIONS_LIST_FAILURE
			});
			NotificationServices.pushNotification({
				message: "Failed to get published versions list",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	} catch (e) {
		console.log(e);
		store.dispatch({
			type: ActionTypes.GET_PUBLISHED_VERSIONS_LIST_FAILURE
		});
		NotificationServices.pushNotification({
			message: "Failed to get published versions list",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
	}
};

export const cancelScheduledPublish = async (menuId) => {
	try {
		const respCancelScheduledPublish = await clientMenu.mutate({
			mutation: CANCEL_SCHEDULED_PUBLISH,
			variables: {
				menuId
			}
		});

		if (respCancelScheduledPublish?.data?.cancelScheduledPublish?.status?.success) {
			return "success";
		} else {
			return "failure";
		}
	} catch (e) {
		console.log(e);
		NotificationServices.pushNotification({
			message: "Failed to get cancel scheduled publish",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});

		return "failure";
	}
};

export const fetchLocationsList = async (offset = 0, searchKeyword = "", brandId, menuId) => {
	try {
		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_LOCATIONS_REQUEST
		});
		const variables = {
			limit: 50,
			offset: offset,
			filters: {
				brand: brandId ? String(brandId) : null
			},
			searchKeyword: searchKeyword || null,
			menuId,
			sortOptions: {
				name: null,
				showAssociatedLocationsFirst: null
			}
		};
		const respLocation = await clientMenu.query({
			query: GET_PUBLISH_LOCATIONS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});

		if (respLocation?.data?.locations) {
			store.dispatch({
				type: ActionTypes.GET_MENU_PREVIEW_LOCATIONS_SUCCESS,
				payload: respLocation.data.locations
			});
		} else {
			store.dispatch({
				type: ActionTypes.GET_MENU_PREVIEW_LOCATIONS_FAILURE
			});
		}
	} catch (e) {
		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_LOCATIONS_FAILURE
		});
		console.log(e);
	}
};

export const fetchPlatformsList = async () => {
	try {
		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_LOCATIONS_REQUEST
		});
		const variables = {
			filters: [
				{
					field: "is_enabled",
					value: true
				}
			],
			includeUrbanpiper: false
		};
		const respPlatforms = await client.query({
			query: GET_BIZ_PLATFORMS_MINI,
			variables,
			fetchPolicy: "no-cache"
		});
		if (respPlatforms?.data?.bizPlatforms) {
			store.dispatch({
				type: ActionTypes.GET_MENU_PREVIEW_PLATFORMS_SUCCESS,
				payload: respPlatforms?.data?.bizPlatforms
			});
		} else {
			store.dispatch({
				type: ActionTypes.GET_MENU_PREVIEW_PLATFORMS_FAILURE
			});
		}
	} catch (e) {
		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_PLATFORMS_FAILURE
		});
		console.log(e);
	}
};

export const deleteRule = async (mode, ruleData) => {
	try {
		const variables = {
			menuId: ruleData?.menuId,
			ruleId: ruleData?.ruleId
		};
		const deleteRuleResp = await clientMenu.mutate({
			mutation: DELETE_RULE,
			variables
		});

		if (deleteRuleResp?.data?.deleteRule?.success) {
			return "success";
		} else {
			return "failure";
		}
	} catch (e) {
		console.log(e);
		return "failure";
	}
};

export const getMenuPreview = async (
	menuId,
	locationId = null,
	platform = null,
	version = null,
	limit = 100,
	offset = 0
) => {
	try {
		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_REQUEST
		});
		const variables = {
			menuId,
			locationId: String(locationId),
			platform,
			version,
			limit,
			offset
		};

		const resp = await clientMenu.query({
			query: PREVIEW_MENU,
			variables,
			fetchPolicy: "no-cache"
		});

		if (resp?.data?.menuPreview) {
			store.dispatch({
				type: ActionTypes.GET_MENU_PREVIEW_SUCCESS,
				payload: resp?.data?.menuPreview
			});
		} else {
			NotificationServices.pushNotification({
				message: "Failed to fetch menu preview",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	} catch (e) {
		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_FAILURE
		});
		NotificationServices.pushNotification({
			message: "Failed to fetch menu preview",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
		console.log(e);
	}
};

export const getMenuMetaData = async (id) => {
	try {
		const respMetaData = await clientMenu.query({
			query: GET_MENU_METADATA,
			variables: {
				id
			},
			fetchPolicy: "no-cache"
		});

		if (respMetaData?.data?.menuV2) {
			store.dispatch({
				type: ActionTypes.UPDATE_META_DATA,
				payload: {
					lockStatus: respMetaData?.data?.menuV2?.metadata?.lockStatus,
					pendingChangesCount: respMetaData?.data?.menuV2?.metadata?.pendingChangesCount
				}
			});
		}
	} catch (e) {
		console.log(e);
	}
};

export const getMenuPreviewValidationStatus = async (menuId, locationId = null, platform = null) => {
	try {
		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_VALIDATION_STATUS_REQUEST,
			payload: { menuId }
		});
		const variables = {
			menuId,
			locationId: String(locationId),
			platform: platform?.split(" ")?.join("")
		};
		const resp = await clientMenu.query({
			query: MENU_PREVIEW_VALIDATION_STATUS,
			variables,
			fetchPolicy: "no-cache"
		});
		if (resp?.data?.fetchPreviewValidation) {
			store.dispatch({
				type: ActionTypes.GET_MENU_PREVIEW_VALIDATION_STATUS_SUCCESS,
				payload: { ...resp?.data?.fetchPreviewValidation, menuId }
			});
			return resp?.data?.fetchPreviewValidation;
		}
	} catch (e) {
		NotificationServices.pushNotification({
			message: e.graphQLErrors?.[0]?.message || "Failed to load menu preview",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});

		store.dispatch({
			type: ActionTypes.GET_MENU_PREVIEW_VALIDATION_STATUS_FAILURE
		});
	}
};

export const getMenuPublishValidationStatus = async (menuId, prePublish = false, publishId = null) => {
	try {
		store.dispatch({
			type: ActionTypes.GET_MENU_PUBLISH_VALIDATION_STATUS_REQUEST,
			payload: { menuId }
		});
		let variables = {
			menuId,
			prePublish
		};
		if (publishId !== null) variables = { ...variables, publishId: String(publishId) };

		const resp = await clientMenu.query({
			query: MENU_PUBLISH_VALIDATION_STATUS,
			variables,
			fetchPolicy: "no-cache"
		});

		if (resp?.data?.fetchPublishValidation) {
			store.dispatch({
				type: ActionTypes.GET_MENU_PUBLISH_VALIDATION_STATUS_SUCCESS,
				payload: { ...resp?.data?.fetchPublishValidation, menuId }
			});
			return resp?.data?.fetchPublishValidation;
		}
	} catch (e) {
		NotificationServices.pushNotification({
			message: e.graphQLErrors?.[0]?.message || "Failed to get menu publish validation status",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
		store.dispatch({
			type: ActionTypes.GET_MENU_PUBLISH_VALIDATION_STATUS_FAILURE
		});
		console.log(e);
	}
};
export const resetMenuPublishValidationStatus = () => {
	return store.dispatch({
		type: ActionTypes.RESET_MENU_PUBLISH_VALIDATION_STATUS
	});
};
export const resetMenuPreviewValidationStatus = () => {
	return store.dispatch({
		type: ActionTypes.RESET_MENU_PREVIEW_VALIDATION_STATUS
	});
};

export const fetchMenuCategoriesList = async (offset = 0, searchKeyword = "") => {
	try {
		store.dispatch({
			type: ActionTypes.GET_MENU_CATEGORIES_LIST_REQUEST
		});
		const variables = {
			offset,
			limit: 10,
			searchKeyword
		};
		const resp = await clientMenu.query({
			query: MENU_CATEGORIES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});

		if (resp?.data?.bizCategories) {
			store.dispatch({
				type: ActionTypes.GET_MENU_CATEGORIES_LIST_SUCCESS,
				payload: resp?.data?.bizCategories
			});
			return resp?.data?.bizCategories;
		}
	} catch (e) {
		store.dispatch({
			type: ActionTypes.GET_MENU_CATEGORIES_LIST_FAILURE
		});
		console.log(e);
	}
};

export const createTestCart = async ({ menuId, items, bizLocationId, platform, version }) => {
	try {
		const variables = {
			menuId,
			items,
			bizLocationId,
			platform,
			version
		};
		const res = await clientMenu.mutate({
			mutation: CREATE_TEST_CART,
			variables,
			fetchPolicy: "no-cache"
		});

		if (res?.data?.createCart) {
			store.dispatch({
				type: ActionTypes.CREATE_TEST_CART,
				payload: res?.data?.createCart
			});
			if (res?.data?.createCart?.success) {
				NotificationServices.pushNotification({
					message: "Item added to cart successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
			} else {
				NotificationServices.pushNotification({
					message: res?.data?.createCart?.error || "Something went wrong",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			return res.data.createCart;
		}
	} catch (e) {
		console.log(e);
	}
};
export const resetTestCart = async ({ cartId }) => {
	try {
		const variables = {
			cartId
		};
		const res = await clientMenu.mutate({
			mutation: RESET_CART,
			variables,
			fetchPolicy: "no-cache"
		});

		if (res?.data?.resetCart) {
			store.dispatch({
				type: ActionTypes.RESET_TEST_CART,
				payload: res?.data?.resetCart
			});
			return res.data.resetCart;
		}
	} catch (e) {
		console.log(e);
	}
};
export const addItemsTestCart = async ({ cartId, items }) => {
	try {
		const variables = {
			cartId,
			items
		};
		const res = await clientMenu.mutate({
			mutation: ADD_ITEMS_TEST_CART,
			variables,
			fetchPolicy: "no-cache"
		});

		if (res?.data?.addItemsCart) {
			store.dispatch({
				type: ActionTypes.CREATE_TEST_CART,
				payload: res?.data?.addItemsCart
			});
			if (res?.data?.addItemsCart?.success) {
				NotificationServices.pushNotification({
					message: "Item added to cart successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
			} else {
				NotificationServices.pushNotification({
					message: res?.data?.addItemsCart?.error || "Something went wrong",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			return res.data.addItemsCart;
		}
	} catch (e) {
		console.log(e);
	}
};

export const addItemsTestCartDebounced = debounce(({ cartId, items }) => addItemsTestCart({ cartId, items }), 1000);

export const placeTestCart = async ({ cartId }) => {
	try {
		const variables = {
			cartId
		};
		const res = await clientMenu.mutate({
			mutation: PLACE_TEST_ORDER,
			variables,
			fetchPolicy: "no-cache"
		});

		if (res?.data?.placeOrder) {
			store.dispatch({
				type: ActionTypes.PLACE_TEST_ORDER,
				payload: res.data.placeOrder
			});
			return res.data.placeOrder;
		}
	} catch (e) {
		console.log(e);
	}
};

export const updateItemsTestCart = async ({ cartId, items }) => {
	try {
		const variables = {
			cartId,
			items
		};
		const res = await clientMenu.mutate({
			mutation: UPDATE_TEST_CART,
			variables,
			fetchPolicy: "no-cache"
		});

		if (res?.data?.updateItemsCart) {
			if (res.data.updateItemsCart?.success) {
				NotificationServices.pushNotification({
					message: "Item quantity updated successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.UPDATE_ITEMS_TEST_CART,
					payload: res?.data?.updateItemsCart
				});
			} else {
				NotificationServices.pushNotification({
					message: res.data.updateItemsCart?.error || "Something went wrong",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			return res.data.updateItemsCart;
		}
	} catch (e) {
		console.log(e);
		NotificationServices.pushNotification({
			message: e.graphQLErrors?.[0]?.message || "Something went wrong",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
	}
};
export const updateItemsTestCartDebounced = debounce(
	({ cartId, items }) => updateItemsTestCart({ cartId, items }),
	1000
);

export const removeItemsTestCart = async ({ cartId, items }) => {
	try {
		const variables = {
			cartId,
			items
		};
		const res = await clientMenu.mutate({
			mutation: REMOVE_ITEMS_TEST_CART,
			variables,
			fetchPolicy: "no-cache"
		});

		if (res?.data?.removeItemsCart) {
			store.dispatch({
				type: ActionTypes.UPDATE_ITEMS_TEST_CART,
				payload: res?.data?.removeItemsCart
			});
			if (res.data.removeItemsCart?.success) {
				NotificationServices.pushNotification({
					message: "Item removed successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
			} else {
				NotificationServices.pushNotification({
					message: res.data.removeItemsCart?.error || "Something went wrong",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			return res.data.removeItemsCart;
		}
	} catch (e) {
		console.log(e);
	}
};
export const removeItemsTestCartDebounced = debounce(
	({ cartId, items }) => removeItemsTestCart({ cartId, items }),
	1000
);
