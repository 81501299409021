export default (
	state = {
		loading: false,
		disabled: false
	},
	action
) => {
	switch (action.type) {
		case "LOGIN_FORM_LOAD_INIT":
			return {
				...state,
				loading: true
			};
		case "LOGIN_FORM_LOAD_DONE":
			return {
				...state,
				loading: false
			};
		case "LOGIN_FORM_DISABLED":
			return {
				...state,
				disabled: true
			};
		case "LOGIN_FORM_RESET":
			return {
				...state,
				disabled: false,
				loading: false
			};
		default:
			return state;
	}
};
