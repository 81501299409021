import React from "react";
import { useHistory } from "react-router-dom";

//components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import Button, { ButtonVariant, ButtonSize } from "@urbanpiper-engineering/horizon/dist/atoms/button";

function BrandCreationSuccess() {
	const history = useHistory();
	return (
		<div className="onboarding-brand-creation-success">
			<img className="success-icon" src="/assets/icons/success-green-two-icon.svg" alt="" />
			<Text className="success-heading" variant={TextVariant.HEADING3}>
				You have successfully created new brand(s)
			</Text>

			<Button
				className="continue-with-setup--cta"
				variant={ButtonVariant.Primary}
				size={ButtonSize.LG}
				onClick={() => history.push("/onboarding")}
			>
				Continue with Setup
			</Button>

			<Button
				className="view-brands--cta"
				variant={ButtonVariant.Secondary}
				size={ButtonSize.LG}
				onClick={() => history.push("/brands")}
			>
				View Brands
			</Button>
		</div>
	);
}

export default BrandCreationSuccess;
