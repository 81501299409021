import React from "react";

export const SelectAllFields = ({ allSelected, mainMessage, linkMessage, handleCompleteSelection }) => {
	return (
		<div className="select-all">
			{mainMessage}
			<span className="hyperlink" onClick={() => handleCompleteSelection(!allSelected)}>
				{linkMessage}
			</span>
		</div>
	);
};
