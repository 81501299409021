import React, { Component } from "react";

// components
import { dynamicTextParser } from "./dynamicTextParser";

export default class InlineSuggestions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSuggestions: false,
			selectedVar: 0,
			cursorPos: 0,
			hasEdited: false
		};
		this.suggestionRefs = {};
		this.dynamicVarsHTMLString = dynamicTextParser(this.props.content, this.props.vars);
		if (this.props.content) {
			const contentObj = this.getDynamicStringInfo(this.props.content, this.props.vars);
			this.props.updateContent(this.props.content, contentObj.length, contentObj.hasDynamicVars);
		}
	}

	componentDidMount() {
		window.addEventListener("click", this.closeSuggestions, false);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.closeSuggestions, false);
	}

	componentDidUpdate() {
		if (this.state.showSuggestions) {
			window.requestAnimationFrame(() => {
				this.suggestionRefs[this.state.selectedVar].focus();
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!this.state.hasEdited && this.props.content !== nextProps.content && nextProps.content !== null) {
			this.dynamicVarsHTMLString = dynamicTextParser(nextProps.content, nextProps.vars);
			const contentObj = this.getDynamicStringInfo(nextProps.content, this.props.vars);
			this.props.updateContent(nextProps.content, contentObj.length, contentObj.hasDynamicVars);
		}
	}

	getDynamicStringInfo = (text, vars) => {
		let length = 0;
		let flatVars = {};
		let hasDynamicVars = false;
		vars.forEach(({ value, name, length }, i) => {
			flatVars[value] = {
				name,
				length
			};
		});
		const rawTextArray = text.split(" ");
		rawTextArray.forEach((t, i) => {
			if (t[0] === "{" && t[t.length - 1] === "}") {
				if (flatVars[t]) {
					length += flatVars[t].length;
					hasDynamicVars = true;
				} else {
					length += t.length;
				}
			} else if (t === "\n") {
				length += 1;
			} else {
				length += t.length;
			}
			// if its not the last item of text array then increase length
			if (i != rawTextArray.length - 1) {
				length++;
			}
		});
		return {
			length,
			hasDynamicVars
		};
	};

	updateContent = () => {
		const contentText = this.getContentText(this.contentTextRef);
		const contentObj = this.getDynamicStringInfo(contentText, this.props.vars);
		//console.log(contentText, contentObj);
		this.props.updateContent(contentText, contentObj.length, contentObj.hasDynamicVars);
	};

	parseContent = (e) => {
		if (e.keyCode === 219) {
			// encountered "{" key, show suggestions and set cursor position
			this.setState({
				hasEdited: true,
				showSuggestions: true,
				cursorPos: this.getCaretCharacterOffsetWithin(this.contentTextRef)
			});
		} else {
			// any other key, set cursor position and update content in the parent
			this.setState(
				{
					hasEdited: true,
					cursorPos: this.getCaretCharacterOffsetWithin(this.contentTextRef)
				},
				() => {
					this.updateContent();
				}
			);
		}
	};

	/*
		1. On hitting enter key, default behaviour of browser is to insert DIV, which creates
		extra new line. Below function is to override that behaviuour.
		2. We are inserting two <br> tags because with one, the cursor wont go down to the next line.
	*/
	preventDiv = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			document.execCommand("insertHTML", false, "<br><br>");
			return false;
		}
	};

	// find the current cursor position in an editable div
	getCaretCharacterOffsetWithin = (element) => {
		let caretOffset = 0;
		let doc = element.ownerDocument || element.document;
		let win = doc.defaultView || doc.parentWindow;
		let sel;
		if (typeof win.getSelection != "undefined") {
			sel = win.getSelection();
			if (sel.rangeCount > 0) {
				let range = win.getSelection().getRangeAt(0);
				let preCaretRange = range.cloneRange();
				preCaretRange.selectNodeContents(element);
				preCaretRange.setEnd(range.endContainer, range.endOffset);
				caretOffset = preCaretRange.toString().length;
			}
		} else if ((sel = doc.selection) && sel.type != "Control") {
			var textRange = sel.createRange();
			var preCaretTextRange = doc.body.createTextRange();
			preCaretTextRange.moveToElementText(element);
			preCaretTextRange.setEndPoint("EndToEnd", textRange);
			caretOffset = preCaretTextRange.text.length;
		}
		return caretOffset;
	};

	// Move caret to a specific point in a DOM element
	setCursorPosition = (element, pos) => {
		// Loop through all child nodes
		for (let node of element.childNodes) {
			if (node.nodeType == 3) {
				// we have a text node
				if (node.length >= pos) {
					// finally add our range
					let range = document.createRange(),
						sel = window.getSelection();
					range.setStart(node, pos);
					range.collapse(true);
					sel.removeAllRanges();
					sel.addRange(range);
					return -1; // we are done
				} else {
					pos -= node.length;
				}
			} else {
				pos = this.setCursorPosition(node, pos);
				if (pos == -1) {
					return -1; // no need to finish the for loop
				}
			}
		}
		return pos; // needed because of recursion stuff
	};

	handleSuggestions = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.keyCode == 8) {
			this.closeSuggestions();
		} else if (e.keyCode === 40 || e.keyCode === 9) {
			// encountered DOWN-Arrow/TAB key, cycle through available options
			this.setState({
				selectedVar: this.state.selectedVar < this.props.vars.length - 1 ? this.state.selectedVar + 1 : 0
			});
		} else if (e.keyCode === 38) {
			// encountered UP-Arrow key, cycle through available options
			this.setState({
				selectedVar: this.state.selectedVar > 0 ? this.state.selectedVar - 1 : this.props.vars.length - 1
			});
		} else if (e.keyCode === 13) {
			// encoutered ENTER key, selecting the highlighted variable
			let selectedObj = this.props.vars[this.state.selectedVar];
			this.selectSuggestion(selectedObj, false, null);
		} else if (e.keyCode === 27) {
			// encountered ESC key, close suggestions and reset focus
			this.closeSuggestions();
		}
	};

	closeSuggestions = () => {
		if (this.state.showSuggestions) {
			this.setState({
				showSuggestions: false
			});
			this.contentTextRef.focus();
			this.setCursorPosition(this.contentTextRef, this.state.cursorPos);
			document.execCommand("delete", false, null);
		}
	};

	selectSuggestion = (s, isFromClick, e) => {
		if (e) {
			e.stopPropagation();
		}
		// const selectedSuggestion = _.find(this.state.vars, { value:s.value})
		// const selectedVar = this.state.vars[s].value;
		// span is for the highlighted content
		// &nbsp; is there so the cursor is still active and visible on the editable div
		// contentEditable=false in the first span makes it possible to delete the whole word, instead of individual characters
		// DO NOT FORMAT the html string below! formatting it across multiple lines will create extra white space
		let varHTMLstring = `<span class="dynamic-variables" contentEditable="false" data-val=${s.value}>${s.name}</span>&nbsp;`;

		// if a suggestion is selected by clicking on it then the focus is lost on
		// editable div, so we need to focus again and move cursor to the current position
		if (isFromClick) {
			// focus on the editable div and set cursor to the current position
			this.contentTextRef.focus();
			this.setCursorPosition(this.contentTextRef, this.state.cursorPos);
		}

		// requesting frame from browser because the below operations
		// are done directly on browser document
		window.requestAnimationFrame(() => {
			// delete the opening curly brace("{") entered by user since its a dynamic var
			document.execCommand("delete", false, null);

			// then insert the html string which we created above
			document.execCommand("insertHTML", false, varHTMLstring);

			// hide and reset the suggestions list
			this.setState(
				{
					showSuggestions: false,
					selectedVar: 0
				},
				() => {
					this.updateContent();
				}
			);
		});
	};

	getContentText = (el) => {
		let contentText = "";
		let val = "";
		// regular expression to check for &nbsp character
		const re = new RegExp(String.fromCharCode(160), "g");
		el.childNodes.forEach((node) => {
			if (node.nodeName === "SPAN") {
				val = node.getAttribute("data-val") ? node.getAttribute("data-val").trim() : node.textContent;
				// replace &nbsp character with space literal
				val = val.replace(re, " ");
				contentText += val != "" ? `${val}` : "";
			} else if (node.nodeName === "#text" || node.nodeName === "G") {
				val = node.textContent;
				// replace &nbsp character with space literal
				val = val.replace(re, " ");
				contentText += val != "" ? `${val}` : "";
			} else if (node.nodeName === "BR") {
				val = "\n";
				contentText += val;
			} else if (node.nodeName === "DIV") {
				val = this.getContentText(node);
				contentText += val != "" ? `${val} ` : "";
			} else {
				val = node.textContent;
				// replace &nbsp character with space literal
				val = val.replace(re, " ");
				contentText += val != "" ? `${val}` : "";
			}
		});
		return contentText;
	};

	handlePaste = (e) => {
		e.preventDefault();

		// get the text content from clipboard
		let text = "";
		if (e.clipboardData && e.clipboardData.getData) {
			text = e.clipboardData.getData("text/plain");
		}

		// then insert the text into editable div
		document.execCommand("insertHTML", false, text);

		// set cursor position and update content in the parent
		this.setState(
			{
				hasEdited: true,
				cursorPos: this.getCaretCharacterOffsetWithin(this.contentTextRef)
			},
			() => {
				this.updateContent();
			}
		);
	};

	render() {
		const { showSuggestions } = this.state;
		const { vars } = this.props;

		return (
			<div className="inline-sugg-input-container">
				<div
					tabIndex="1"
					className="input-text"
					contentEditable="true"
					onKeyUp={this.parseContent}
					onKeyDown={this.preventDiv}
					onPaste={this.handlePaste}
					ref={(ref) => (this.contentTextRef = ref)}
					role="textbox"
					dangerouslySetInnerHTML={this.dynamicVarsHTMLString}
				></div>
				<div className="input-suggestion" data-visible={showSuggestions}>
					<div>
						{vars.map((currVar, i) => (
							<div
								key={i}
								ref={(ref) => (this.suggestionRefs[i] = ref)}
								tabIndex={i + 1}
								className="suggestion-option"
								onKeyUp={this.handleSuggestions}
								onKeyDown={(e) => e.preventDefault()}
								onClick={(e) => this.selectSuggestion(currVar, true, e)}
							>
								{`${currVar.name}`}
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}
