import React from "react";

const SelectedItemTooltip = ({ cell, view }) => {
	const total = cell?.value?.total;
	const available = cell?.value?.available;

	const getToolTipText = (total, available) => {
		if (total === 0) {
			return <span> The storefront does not exist </span>;
		}

		if (total === available) {
			return <span>This item is online</span>;
		}

		if (total > available) {
			return (
				<span>
					This item is
					<span style={{ color: "#B02B2B" }}> Offline </span>
				</span>
			);
		}
	};
	return (
		<div className="tooltiptext-item">
			<div className="tooltip-description-item">{getToolTipText(total, available)}</div>
		</div>
	);
};

export default SelectedItemTooltip;
