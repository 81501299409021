import gql from "graphql-tag";

export const GET_ENTITY_LOCATIONS = gql`
	query entityLocationAssociations(
		$entityId: Int!
		$entityType: LocationAssociationEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$brandId: Int
	) {
		entityLocationAssociations(
			entityId: $entityId
			entityType: $entityType
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
			brandId: $brandId
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			objects {
				bizLocationId
				bizLocationNickname
				city
				isAssociated
				tags
			}
		}
	}
`;

export const UPDATE_ENTITY_LOCATIONS = gql`
	mutation updateEntityLocationstItem(
		$entityId: Int!
		$entityType: LocationAssociationEntityType!
		$locationsToAssociate: [Int]
		$locationsToDisassociate: [Int]
		$associateAll: Boolean
		$disassociateAll: Boolean
		$filters: [ListFilterArgument]
		$brandId: Int
	) {
		updateEntityLocations(
			input: {
				entityId: $entityId
				entityType: $entityType
				locationsToAssociate: $locationsToAssociate
				locationsToDisassociate: $locationsToDisassociate
				associateAll: $associateAll
				disassociateAll: $disassociateAll
				filters: $filters
				brandId: $brandId
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
