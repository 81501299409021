// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_HUB_CONFIGURATIONS, EDIT_HUB_CONFIGURATIONS } from "../graphql/hub";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

const parseErrorMessages = (messages = []) => {
	const error = {
		message: "",
		fields: {}
	};
	if (messages.length === 0) {
		error.message = "Something went wrong, please try again.";
	} else {
		messages.forEach((msg) => {
			error.fields[msg.field] = msg.message;
		});
	}
	return error;
};

const parseHubConfigData = (data, to = "form") => {
	let result = data;
	switch (to) {
		case "form":
			result.map((platform) => {
				platform.keys.map((config) => {
					if (config.type === "choice") {
						if (config.choices) {
							config.choices = config.choices.map((option) => ({
								label: option,
								value: option
							}));
						}
						if (config.value) {
							config.value = { label: config.value, value: config.value };
						}
					}
					// handle this justeat config: convert config type 'char' to 'boolean' and update config value
					if (config.key === "justeat_support_order_relay_w_item_injection") {
						config.type = "boolean";
						config.value = config.value === "1" ? true : false;
					}
				});
			});
			break;
		case "server":
			result = [];
			data.map((platform) => {
				platform.keys.map((config) => {
					if (config.type === "choice") {
						result.push({
							key: config.key,
							value: config.value === null ? "" : config.value.value
						});
					} else if (config.key === "justeat_support_order_relay_w_item_injection") {
						// handle this justeat config by updating config value to '1' or '0'.
						result.push({
							key: config.key,
							value: config.value ? "1" : "0"
						});
					} else {
						result.push({
							key: config.key,
							value: config.value === null ? "" : config.value
						});
					}
				});
			});
			break;
		default:
			break;
	}
	return result;
};

export const fetchHubConfigurations = async (location, brand) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_HUB_CONFIGURATIONS_REQUEST
	});
	try {
		const variables = {};
		if (location?.id !== "default" && location) {
			variables.locationId = location?.id ? parseInt(location?.id) : null;
		}
		if (brand?.id !== "default") {
			variables.brandId = !brand || brand?.id === "all" || brand?.id === "default" ? null : brand?.id;
		}
		const resp = await client.query({
			query: GET_HUB_CONFIGURATIONS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_HUB_CONFIGURATIONS_SUCCESS,
			payload: parseHubConfigData(resp.data.configurations, "form"),
			isBizHubConfig: Object?.values(variables)?.every((val) => val === null || val === undefined)
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_HUB_CONFIGURATIONS_FAILURE,
			error: {
				message: "There was an error while fetching Hub configurations"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const editHubConfigurations = async (data, location, brand) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.EDIT_HUB_CONFIGURATIONS_REQUEST
	});
	try {
		const variables = {
			configurations: parseHubConfigData(data, "server")
		};
		if (location?.id !== "default") {
			variables.locationId = location?.id || null;
		}
		variables.brandId = !brand || brand?.id === "all" || brand?.id === "default" ? null : brand?.id;
		const resp = await client.mutate({
			mutation: EDIT_HUB_CONFIGURATIONS,
			variables
		});
		if (resp.data.saveConfigurations.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_HUB_CONFIGURATIONS_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Hub Configurations saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch(toggleGlobalLoader(false));
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_HUB_CONFIGURATIONS_FAILURE,
				error: parseErrorMessages(resp.data.saveConfigurations.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_HUB_CONFIGURATIONS_FAILURE,
			error: {
				message: "There was an error while saving Hub Configurations details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
