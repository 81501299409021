import gql from "graphql-tag";

export const GET_PENDING_INVITES_LIST = gql`
	query getPendingIvitesList(
		$clientToken: String!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		authServiceInvitesList(
			clientToken: $clientToken
			limit: $limit
			offset: $offset
			filters: $filters
			search: $search
		) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				uuid
				email
				phone
				isdCode
				status
				createdAt
				invitedApps {
					appName
					appBizId
					appBizName
					appBizLogo
					locations {
						id
						name
					}
					roles {
						id
						name
					}
					allLocations
				}
			}
		}
	}
`;

export const USER_INVITE_ACTIONS = gql`
	mutation userInviteActions($action: String!, $uuid: String!, $clientToken: String!, $bizId: Int!) {
		authServiceActionInvite(input: { action: $action, uuid: $uuid, clientToken: $clientToken, bizId: $bizId }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_INVITE = gql`
	query getInvite($clientToken: String!, $uuid: String!) {
		authServiceInvite(clientToken: $clientToken, uuid: $uuid) {
			object {
				id
				uuid
				bizId
				email
				phone
				status
				isdCode
				createdAt
				invitedApps {
					appName
					appBizId
					appBizName
					appBizLogo
					locations {
						id
						name
						brandName
					}
					roles {
						id
						name
					}
					allLocations
				}
			}
		}
	}
`;
