import React, { useEffect, useCallback } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { SelectFilter } from "../_commons/SelectFilter";

// third party
import { connect } from "react-redux";

// actions
import { fetchTimingGroups, fetchTimingGroupsDebounced } from "../../actions/actions";

// contants
import { NESTED_ENTITY_TYPES } from "../../client-config";

const LocationDetails = ({
	formData = {},
	validations = {},
	handleFormData,
	setFormValidations,
	timingGroups,
	handleNestedEntity,
	citiesList,
	searchCitiesDebounced
}) => {
	useEffect(() => {
		fetchTimingGroups();
	}, []);

	const handleChange = useCallback(
		(field, value) => {
			handleFormData({ [field]: value });
			// reset validations
			setFormValidations({
				...validations,
				[field]: ""
			});
		},
		[handleFormData, validations, setFormValidations]
	);

	return (
		<div className="location-details">
			<div className="section-title">Fill in details</div>
			<div className="form-row row-full">
				<InputWithLabel
					value={formData.bizLocationNickname}
					onChange={(e) => handleChange("bizLocationNickname", e.target.value)}
					requiredLabel={true}
					validationMessage={validations.bizLocationNickname || ""}
					placeholder={"Ex: Indiranagar Store"}
				>
					Location Name
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={formData.bizAddress}
					onChange={(e) => handleChange("bizAddress", e.target.value)}
					placeholder={"Ex: Unit#123, Forum Mall, Indiranagar"}
				>
					Address
				</Textarea>
			</div>
			<div className="form-row row-full timing-group">
				<SelectFilter
					title="City"
					options={citiesList.items}
					isLoading={citiesList.isLoading}
					field="city"
					currValue={formData.city}
					setFilter={handleChange}
					labelKey="valueForDisplay"
					valueKey="value"
					isAsync={true}
					requiredLabel={true}
					handleSearch={searchCitiesDebounced}
				/>
				{/* <div className="store-timings">
					<SelectFilter
						title="Store timings"
						options={timingGroups?.items}
						isLoading={timingGroups?.isLoading}
						field="storeTimings"
						currValue={formData.storeTimings || null}
						setFilter={handleChange}
						labelKey="title"
						valueKey="id"
						isAsync={true}
						handleSearch={fetchTimingGroupsDebounced}
					/>
					<div className="add-timing-group" onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[15])}>&#xFF0B; Add new</div>
				</div> */}
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={formData.primaryContactPhone}
					classes={"at-input--label"}
					showLabel={true}
					image={true}
					imageSrc={"/assets/icons/icon-phone-small.svg"}
					onChange={(e) => handleChange("primaryContactPhone", e.target.value || null)}
					validationMessage={validations.primaryContactPhone}
					placeholder={"Ex: 9876543210"}
				>
					Phone
				</InputWithLabel>
				<InputWithLabel
					value={formData.primaryContactEmail}
					type={"email"}
					classes={"at-input--label"}
					showLabel={true}
					image={true}
					imageSrc={"/assets/icons/icon-mail.svg"}
					onChange={(e) => handleChange("primaryContactEmail", e.target.value)}
					validationMessage={validations.primaryContactEmail}
					placeholder={"Ex: hello@gmail.com"}
				>
					Email
				</InputWithLabel>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(LocationDetails);
