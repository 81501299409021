import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// third party
import moment from "moment";
import Compressor from "compressorjs";
import { connect } from "react-redux";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/GalleryEdit/BasicInfo";

// utils
import { client } from "../client";
import { store } from "../store/configureStore";
import history from "../history";
import { removeProp, adjustNestedContainer, getFileExtension } from "../atlas-utils";

// graphql
import { GET_LANGUAGES_LIST } from "../graphql/gallery";

// actions
import { fetchGalleriesList, fetchGalleryDetail, editGalleryDetail } from "../actions/gallery";
import { ActionTypes } from "../actions/_types";

// reducers
import { galleryDetailsReducer, GALLERY_DETAILS_INITIAL_STATE } from "../reducers/gallery";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const REPLACE_IMAGE_INIT_STATE = {
	isReplacementDrawerOpen: false,
	file: null
};

const GalleryEdit = ({
	match,
	access,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef,
	biz
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isFormOpen, setFormState] = useState(false);
	const [galleryDetails, dispatch] = useReducer(galleryDetailsReducer, GALLERY_DETAILS_INITIAL_STATE);
	const { loading, data, error } = galleryDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isDeleted, setIsDeleted] = useState(false);
	const [languagesList, setLanguagesList] = useState([]);
	const [replacementImageContent, setReplacementImageContent] = useState(REPLACE_IMAGE_INIT_STATE);
	const nestedRef = useRef();
	const inputRef = useRef(null);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		adjustNestedContainer(false);
	}, []);

	useEffect(() => {
		fetchGalleryDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const getLanguagesList = async () => {
		try {
			const resp = await client.query({
				query: GET_LANGUAGES_LIST,
				fetchPolicy: "no-cache"
			});
			setLanguagesList(resp.data.languages);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	useEffect(() => {
		getLanguagesList();
	}, []);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchGalleriesList();
					history.push("/gallery");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (formData) => {
		dispatch({
			type: ActionTypes.UPDATE_GALLERY_DETAIL,
			payload: {
				...formData
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		if (replacementImageContent.file) {
			sanitisedData.image = replacementImageContent.file;
		}
		const resp = await editGalleryDetail(sanitisedData);
		if (resp) {
			setFormTouched(false);
		}
	};

	const handleArchiveRestore = useCallback((success) => {
		if (success) {
			setIsDeleted(true);
			handleCancel();
		}
		setArchiveRestore(false);
	}, []);

	const switchTab = (tab) => {
		setFormTab(tab.value);
	};

	const handleImageReplacement = (success) => {
		setReplacementImageContent({ ...REPLACE_IMAGE_INIT_STATE });
		if (success) {
			fetchGalleryDetail(parseInt(match.params.id), dispatch);
			setFormTouched(false);
		}
	};

	const handleImage = async (files) => {
		try {
			if (files[0] && files[0].size < 2097152) {
				setReplacementImageContent((current) => ({
					...current,
					isReplacementDrawerOpen: true,
					file: files[0]
				}));
			} else if (getFileExtension(files[0].name) !== "gif") {
				const result = await new Promise((resolve, reject) => {
					new Compressor(files[0], {
						quality: 0.6,
						maxWidth: 4000,
						success: resolve,
						error: reject
					});
				});
				if (result && result.size < 10475200) {
					setReplacementImageContent((current) => ({
						...current,
						isReplacementDrawerOpen: true,
						file: result
					}));
				} else {
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Image size is too large, please keep it below 10 MB",
							timeout: 3000,
							error: true
						}
					});
				}
			} else if (getFileExtension(files[0].name) === "gif" && files[0].size < 10475200) {
				setReplacementImageContent((current) => ({
					...current,
					isReplacementDrawerOpen: true,
					file: files[0]
				}));
			} else {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Image size is too large, please keep it below 10 MB",
						timeout: 3000,
						error: true
					}
				});
			}
		} catch {}
	};

	return (
		<div className="gallery-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title="Image"
				subTitle={`Make changes to image uploaded on ${
					data?.updated ? moment(data?.updated).format("DD MMM, YYYY - hh:mm A") : "--"
				}`}
				submitTitle="Save"
				loading={loading}
				isNested={isNested}
				hideActions={!isFormTouched}
				headerRight={
					access.isCatalogueManagement &&
					!isDeleted && (
						<Button classes="at-btn--danger" clickHandler={() => setArchiveRestore(true)}>
							Delete
						</Button>
					)
				}
			>
				{/* <Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={switchTab}
					isStickyOnTop={true}
				/> */}
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							languagesList={languagesList}
							handleForm={handleForm}
							validations={error.fields || {}}
							isDeleted={isDeleted}
							readOnly={!access.isAdmin}
							inputRef={inputRef}
							handleImage={handleImage}
						/>
					)}
					<ArchiveRestore
						isOpen={replacementImageContent?.isReplacementDrawerOpen || archiveRestore}
						close={
							replacementImageContent?.isReplacementDrawerOpen
								? handleImageReplacement
								: handleArchiveRestore
						}
						entityType={CATALOGUE_ENTITY_TYPES[15]}
						entityName={data.image ? data.image.split("/").slice(-1)[0] : "this image"}
						object={{
							...data,
							replacementImageContent: { ...replacementImageContent },
							supportedLanguages: biz?.supportedLanguages
						}}
						mode={replacementImageContent?.isReplacementDrawerOpen ? "replace" : "delete"}
						fieldName={replacementImageContent?.isReplacementDrawerOpen ? "isReplace" : "isDelete"}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	biz: store.login.loggedInbizDetail
}))(GalleryEdit);
