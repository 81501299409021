import React, { useState, useCallback, useEffect } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// graphql
import { GET_BIZ_PLATFORMS, LOCATION_ACTION } from "../../graphql/locations";

// utils
import { trackEvent, triggerSurvey, extractInitials } from "../../atlas-utils";
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import moment from "moment";
import SatismeterService from "../../services/SatismeterService";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchBrands } from "../../actions/actions";

// constants
import {
	CATALOGUE_PLATFORMS_LOGO,
	TRACKING_ACTION,
	TRACKING_ENTITY,
	TRACKING_EVENT_NAMES,
	TRACKING_MISC,
	TRACKING_SOURCE
} from "../../client-config";
import { TIME_PRESET_TYPES, TIME_TYPES } from "../_commons/NewDateCompareFilter";
const INITIAL_DATE_FILTER_STATE = {
	timeTypeSelected: TIME_TYPES[0],
	presetTypeSelected: TIME_PRESET_TYPES[0],
	date: moment().add(1, "day"),
	time: "00:00:00"
};

const StoreToggle = ({
	isNested = false,
	locations = [],
	isOpen = false,
	close,
	brands = [],
	action = "disable",
	selectedBrand = null,
	fromBrands = false,
	isMultibrandEnabled = false
}) => {
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [bizPlatformsList, setBizPlatformsList] = useState([]);
	const [publishResponse, setPublishResponse] = useState(null);
	const [brand, setBrand] = useState(selectedBrand);
	const [platformUpdates, setPlatformUpdates] = useState({});
	const [dateTimeFilter, setDateTimeFilter] = useState({
		currentDateFilter: INITIAL_DATE_FILTER_STATE,
		appliedDateFilter: INITIAL_DATE_FILTER_STATE
	});

	useEffect(() => {
		if (isOpen) {
			fetchBizPlatformsList();
			if (isMultibrandEnabled) {
				fetchBrands("", true);
			}
		}
	}, [isOpen]);

	useEffect(() => {
		setBrand(selectedBrand);
	}, [selectedBrand]);

	const fetchBizPlatformsList = async () => {
		try {
			setLoading(true);
			const variables = {
				limit: 30,
				offset: 0
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizPlatformsList(resp.data.bizPlatforms.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const handleSelectBrand = (brand) => {
		setBrand(brand);
	};

	const handleSelectPlatform = (platform, isSelected) => {
		setPlatformUpdates({
			...platformUpdates,
			[platform.id]: isSelected
		});
	};

	const handleSelectAllPlatforms = () => {
		let updates = {};
		bizPlatformsList.forEach((plf) => {
			updates[plf.id] = true;
		});
		setPlatformUpdates(updates);
	};

	const handleClose = useCallback(
		(success = false) => {
			// reset state before closing
			if (publishResponse?.status) {
				triggerSurvey("survey toggle store status");
			}
			setBizPlatformsList([]);
			setPlatformUpdates({});
			setPublishResponse(null);
			setBrand(selectedBrand);
			setDateTimeFilter({
				currentDateFilter: INITIAL_DATE_FILTER_STATE,
				appliedDateFilter: INITIAL_DATE_FILTER_STATE
			});
			close(success);
		},
		[close, publishResponse, selectedBrand]
	);

	const updateDateTimeFilter = (payload) => {
		setDateTimeFilter({
			...dateTimeFilter,
			...payload
		});
	};

	const handleToggle = useCallback(async () => {
		try {
			setConfirmLoading(true);
			const variables = {
				locationIds: locations.map((loc) => parseInt(loc.id)),
				platformIds: Object.keys(platformUpdates).filter((platform) => platformUpdates[platform]),
				action
			};
			if (brand) {
				variables.brand = brand?.id;
			}
			let customSnoozeDuration = "";
			if (action === "disable") {
				if (dateTimeFilter?.appliedDateFilter?.timeTypeSelected?.value === "range") {
					variables.snoozeDatetime = `${moment(dateTimeFilter?.appliedDateFilter?.date).format(
						"YYYY-MM-DD"
					)} ${dateTimeFilter?.appliedDateFilter?.time}`;
					customSnoozeDuration = moment(
						dateTimeFilter?.appliedDateFilter?.date,
						dateTimeFilter?.appliedDateFilter?.time
					)?.diff(moment(), "minutes");
				} else {
					variables.snoozeDatetime = dateTimeFilter?.appliedDateFilter?.presetTypeSelected?.value;
				}
			}

			// track store toggle event
			const eventMeta = {
				source: TRACKING_SOURCE.LIST_VIEW_BULK,
				entity: TRACKING_ENTITY.LOCATION,
				num_locations: locations.length,
				platforms: bizPlatformsList.filter((plf) => platformUpdates[plf.id]).map((plf) => plf.platformName),
				action: action === "enable" ? TRACKING_ACTION.SET_ONLINE : TRACKING_ACTION.SET_OFFLINE,
				snoozed_until:
					action === "disable"
						? customSnoozeDuration
							? `${customSnoozeDuration}_MINUTES`
							: variables.snoozeDatetime
							? variables.snoozeDatetime
							: TRACKING_MISC.INDEFINITELY
						: null,
				is_duration_custom: action === "disable" ? (customSnoozeDuration ? true : false) : null
			};
			trackEvent(TRACKING_EVENT_NAMES.AVAILABILITY, eventMeta);

			// satismeter event
			SatismeterService.locationToggle();

			const resp = await client.mutate({
				mutation: LOCATION_ACTION,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.locationAction.status.success) {
				// handle success message
				const pubResp = {
					status: true,
					message: resp.data.locationAction.status.messages[0].message
				};
				setPublishResponse(pubResp);
			} else {
				// handle error message
				const pubResp = {
					status: false,
					message: resp.data.locationAction.status.messages[0].message
				};
				setPublishResponse(pubResp);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [locations, brand, platformUpdates, dateTimeFilter, action]);

	const handleDisabled = () => {
		let disabled = false;
		if (isMultibrandEnabled && brand === null) {
			disabled = true;
		}
		disabled = Object.values(platformUpdates).filter((val) => val === true).length === 0;
		return confirmLoading || disabled;
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 85 ? brand.name.slice(0, 85) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="locations-store-toggle-container">
			<FormSidebar
				isOpen={isOpen}
				loading={loading}
				submit={handleToggle}
				close={() => handleClose(publishResponse?.status)}
				title="Toggle Status"
				subTitle={`Select which ${isMultibrandEnabled ? "brand(s) and" : ""} platform(s) you want to set ${
					action === "enable" ? "online" : "offline"
				}`}
				submitTitle={`Set ${action === "enable" ? "Online" : "Offline"}`}
				cancelTitle={publishResponse !== null ? "Close" : "Cancel"}
				hideSubmitAction={publishResponse !== null}
				disabled={handleDisabled()}
				isNested={isNested}
				overlaySidebarClasses="medium"
			>
				{!confirmLoading && !publishResponse && (
					<React.Fragment>
						{isMultibrandEnabled && (
							<div className="section brand-selection">
								<div className="section-header">Brands</div>
								<SelectFilterCustom
									options={brands.items}
									isLoading={brands.isLoading}
									field="brands"
									currValue={brand}
									setFilter={(f, value) => handleSelectBrand(value)}
									labelKey={"name"}
									valueKey={"id"}
									ellipsizedLength={200}
									isSearchable={false}
									customLabel={true}
									customOptions={true}
									isClearable={false}
									renderLabel={handleBrandsLabelOption}
									renderOptions={handleBrandsLabelOption}
									placeholder="Select brand"
									classes={fromBrands ? "brands disabled" : "brands"}
								/>
							</div>
						)}
						<div className="section platforms-selection">
							<div className="section-header">
								<div>Platforms</div>
								{bizPlatformsList.length > 0 && (
									<div className="select-all" onClick={handleSelectAllPlatforms}>
										Select All
									</div>
								)}
							</div>
							{loading && bizPlatformsList.length === 0 && (
								<div>
									<div className="shimmer H(25px) Mb(10px)" />
									<div className="shimmer H(25px) Mb(10px)" />
									<div className="shimmer H(25px) Mb(10px)" />
								</div>
							)}
							{!loading && bizPlatformsList.length === 0 && (
								<div className="no-items-placeholder">No platforms found</div>
							)}
							{!loading &&
								bizPlatformsList.map((plf, i) => (
									<CheckBox
										key={i}
										checked={platformUpdates[plf.id]}
										clickHandler={() => handleSelectPlatform(plf, !platformUpdates[plf.id])}
									>
										<div className="platform-info">
											<div className="platform-name" title={plf.platformName}>
												{plf.platformName}
											</div>
											<div className="platform-logo">
												<img
													src={
														plf?.logo ||
														CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
														"/assets/icons/icons8-globe-40.png"
													}
													alt=""
												/>
											</div>
										</div>
									</CheckBox>
								))}
						</div>
						{action === "disable" && (
							<div className="section snooze">
								<div className="section-header">
									Set Offline{" "}
									{dateTimeFilter?.appliedDateFilter?.timeTypeSelected?.value === "range"
										? "Till"
										: "For"}
								</div>
								<NewDateCompareFilter
									showDropdown={true}
									monthsShown={1}
									isRange={false}
									loading={loading}
									currentDateFilter={dateTimeFilter?.currentDateFilter}
									appliedDateFilter={dateTimeFilter?.appliedDateFilter}
									updateState={updateDateTimeFilter}
									hideComparison={true}
									hideDatePresets={true}
									showTimePresets={true}
									showTime={true}
									hideFooter={true}
									customMessage={
										dateTimeFilter?.appliedDateFilter?.timeTypeSelected?.value === "range"
											? `Will be set ${action === "enable" ? "Online" : "Offline"} till ${moment(
													dateTimeFilter?.appliedDateFilter?.date
											  ).format("DD MMM YYYY")}, ${moment(
													dateTimeFilter?.appliedDateFilter?.time,
													"hh:mm:ss"
											  ).format("hh:mm:ss A")}`
											: `Will be set ${action === "enable" ? "Online" : "Offline"} for ${
													dateTimeFilter?.appliedDateFilter?.presetTypeSelected?.label
											  }`
									}
								/>
							</div>
						)}
					</React.Fragment>
				)}
				{confirmLoading && (
					<div className="P(25px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{!confirmLoading && publishResponse && (
					<div className="publish-response-section">
						<div className={"publish-response " + (publishResponse?.status ? "success" : "fail")}>
							{publishResponse?.message}
						</div>
					</div>
				)}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(StoreToggle);
