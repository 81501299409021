import React, { Component } from "react";

// third party
import Select from "react-select-animated";
import DatePicker from "react-datepicker";
import moment from "moment";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";

export default class DateFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dateTypes: [
				{ label: "Not specified", value: "--" },
				{ label: "In last", value: "last_n_days" },
				{ label: "Presets", value: "preset_duration" },
				{ label: "Custom", value: "range" }
			],
			presetTypes: [
				{ label: "This week", value: "this_week" },
				{ label: "Last week", value: "last_week" },
				{ label: "This month", value: "this_month" },
				{ label: "Last month", value: "last_month" },
				{ label: "This year", value: "this_year" },
				{ label: "Last year", value: "last_year" }
			],
			customTypes: [
				{ label: "Is between", value: "between" },
				{ label: "Is after", value: "greater_than" },
				{ label: "Is before", value: "less_than" }
			],
			focused: false
		};
	}

	changeDateType = (dateTypeSelected) => {
		let newValue = {};
		const { dateTypes } = this.state;
		switch (dateTypeSelected.value) {
			case dateTypes[1].value:
				newValue.type = "last_n_days";
				newValue.operator = "equal";
				newValue.values = ["100"];
				break;
			case dateTypes[2].value:
				newValue.type = "preset_duration";
				newValue.operator = "equal";
				newValue.values = [this.state.presetTypes[0].value];
				break;
			case dateTypes[3].value:
				newValue.type = "range";
				newValue.operator = "between";
				newValue.values = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
				break;
			default:
				break;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	changePresetType = (presetTypeSelected) => {
		let newValue = {
			type: "preset_duration",
			operator: "equal",
			values: [presetTypeSelected.value]
		};
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	changeCustomType = (customTypeSelected) => {
		let newValue = {
			operator: customTypeSelected.value
		};
		const { customTypes } = this.state;
		switch (customTypeSelected.value) {
			case customTypes[0].value:
				newValue.type = "range";
				newValue.values = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
				break;
			case customTypes[1].value:
				newValue.type = "absolute";
				newValue.values = [moment().format("YYYY-MM-DD")];
				break;
			case customTypes[2].value:
				newValue.type = "absolute";
				newValue.values = [moment().format("YYYY-MM-DD")];
				break;
			default:
				return;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	handleInlastDays = (e) => {
		if (e.target.value >= 0 && e.target.value !== "") {
			let value = {
				type: "last_n_days",
				operator: "equal",
				values: [e.target.value]
			};
			this.props.setFilter(this.props.root, this.props.field, value);
		} else if (e.target.value === "") {
			let value = {
				type: "last_n_days",
				operator: "equal",
				values: []
			};
			this.props.setFilter(this.props.root, this.props.field, value);
		}
	};

	handleStartEndDate = (e, whichDate) => {
		let newValue = { ...this.props.value };
		switch (whichDate) {
			case "rangeStartDate":
				newValue.values[0] = e.format("YYYY-MM-DD");
				break;
			case "rangeEndDate":
				newValue.values[1] = e.format("YYYY-MM-DD");
				break;
			default:
				return;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	setFocus = (focused) => {
		this.setState({
			focused
		});
	};

	render() {
		const { title, value, ignoreYear, horizontalPosition } = this.props;
		const filterApplied = value && value.values && value.values.length > 0 ? true : false;
		const { dateTypes, presetTypes, customTypes, focused } = this.state;

		// set default value for date-picker formatting
		let dateFormat = "DD MMM, YYYY";
		let dateFormatCalender = "MMM YYYY";

		// chnage date-picker formatting based on props
		if (ignoreYear) {
			dateFormat = "DD MMM";
			dateFormatCalender = "MMM";
		}

		// set default values
		let dateTypeSelected = dateTypes[0].value;
		let presetTypeSelected = presetTypes[0].value;
		let customTypeSelected = customTypes[0].value;
		let inLastDays = 100;
		let rangeStartDate = moment();
		let rangeEndDate = moment();

		// set current values according to the props received
		if (value && value.values) {
			dateTypeSelected = value.type;
			switch (value.type) {
				case "last_n_days":
					inLastDays = value.values[0] || "";
					break;
				case "preset_duration":
					presetTypeSelected = value.values[0];
					break;
				case "range":
					customTypeSelected = value.operator;
					rangeStartDate = moment(value.values[0], "YYYY-MM-DD");
					rangeEndDate = moment(value.values[1], "YYYY-MM-DD");
					break;
				case "absolute":
					dateTypeSelected = "range";
					customTypeSelected = value.operator;
					rangeStartDate = moment(value.values[0], "YYYY-MM-DD");
					break;
				default:
					break;
			}
		}

		return (
			<div
				className={
					"date-filter-container filter-section-item" +
					(filterApplied ? " filter-applied" : "") +
					(horizontalPosition ? " data-filter-horizontal" : "") +
					(focused ? " active" : "")
				}
			>
				<div className="meta-info">{title}</div>
				<div className="type-selector">
					<Select
						onFocus={() => this.setFocus(true)}
						onBlur={() => this.setFocus(false)}
						searchable={false}
						className="at-dropdown"
						options={dateTypes}
						value={dateTypeSelected}
						clearable={false}
						onChange={(m) => this.changeDateType(m)}
					/>
					<div className="type-selected">
						{dateTypeSelected === dateTypes[1].value && (
							<React.Fragment>
								<InputWithLabel
									value={inLastDays}
									type="number"
									classes="inline-input"
									onChange={this.handleInlastDays}
								/>
								{inLastDays == 1 && <span className="meta-info">Day</span>}
								{inLastDays > 1 && <span className="meta-info">Days</span>}
							</React.Fragment>
						)}
						{dateTypeSelected === dateTypes[2].value && (
							<Select
								searchable={false}
								className="at-dropdown"
								options={presetTypes}
								value={presetTypeSelected}
								clearable={false}
								onChange={(m) => this.changePresetType(m)}
							/>
						)}
						{dateTypeSelected === dateTypes[3].value && (
							<Select
								searchable={false}
								className="at-dropdown"
								options={customTypes}
								value={customTypeSelected}
								clearable={false}
								onChange={(m) => this.changeCustomType(m)}
							/>
						)}
					</div>
					{dateTypeSelected === dateTypes[3].value && customTypeSelected !== customTypes[0].value && (
						<div className="date-range-container">
							<div className="range-first">
								<DatePicker
									customInput={<CustomDatePickerInput classes="inline-input-date" />}
									selected={rangeStartDate}
									onChange={(e) => this.handleStartEndDate(e, "rangeStartDate")}
									dateFormat={dateFormat}
									dateFormatCalendar={dateFormatCalender}
								/>
							</div>
						</div>
					)}
					{dateTypeSelected === dateTypes[3].value && customTypeSelected === customTypes[0].value && (
						<div className="date-range-container">
							<div className="range-first">
								<DatePicker
									customInput={<CustomDatePickerInput classes="inline-input-date" />}
									selected={rangeStartDate}
									selectsStart
									startDate={rangeStartDate}
									endDate={rangeEndDate}
									onChange={(e) => this.handleStartEndDate(e, "rangeStartDate")}
									dateFormat={dateFormat}
									dateFormatCalendar={dateFormatCalender}
								/>
							</div>
							<span>to</span>
							<div className="range-second">
								<DatePicker
									customInput={<CustomDatePickerInput classes="inline-input-date" />}
									selected={rangeEndDate}
									selectsEnd
									startDate={rangeStartDate}
									endDate={rangeEndDate}
									minDate={rangeStartDate}
									onChange={(e) => this.handleStartEndDate(e, "rangeEndDate")}
									dateFormat={dateFormat}
									dateFormatCalendar={dateFormatCalender}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const CustomDatePickerInput = ({ classes, onClick, value }) => (
	<InputWithLabel classes={classes} readOnly={true} onClick={onClick} value={value} />
);
