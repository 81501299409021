import React, { useEffect, useRef, useState } from "react";

// actions
import { ActionTypes } from "../../actions/_types";
import { client } from "../../client";

// graphql
import { PAYOUT_SHEETS_COUNT, PAYOUT_SHEET_LISTS } from "../../graphql/reconciliation";

// utils
import { capitaliseTextStrict } from "../../atlas-utils";
import history from "../../history";

// clients
import { store } from "../../store/configureStore";

//components
import { Button } from "../_commons/Button";
import { Loading } from "../_commons/Loading";

const WelcomeBoard = () => {
	const [graphicLensPostion, setGraphicLensPosition] = useState({
		x: "-48px",
		y: "96px",
		focus: false
	});
	const lensGraphicsBoundRef = useRef();
	const moneyBagGraphicsBoundRef = useRef();
	const [loading, setLoading] = useState(true);
	const [videoLoading, setVideoLoading] = useState(true);

	const fetchPayoutSheetValuesAndCheck = async () => {
		try {
			const platforms = ["swiggy", "zomato"];
			const resp = await client.query({
				query: PAYOUT_SHEETS_COUNT,
				variables: { platforms },
				fetchPolicy: "no-cache"
			});
			let targetPlatformName = "";
			const respArray = resp?.data?.platformPayoutSheets || [];
			for (let i = 0; i < respArray.length; i++) {
				if (respArray[i].count > 0) {
					targetPlatformName = respArray[i].platform;
					break;
				}
			}
			if (targetPlatformName !== "") {
				store.dispatch({
					type: ActionTypes.SET_SELECTED_PLATFORM,
					payload: { value: targetPlatformName, valueForDisplay: capitaliseTextStrict(targetPlatformName) }
				});
				const variables = {
					platform: targetPlatformName,
					limit: 10,
					offset: 0,
					sort: {
						field: "start_date",
						order: "DESC"
					}
				};
				const resp = await client.query({
					query: PAYOUT_SHEET_LISTS,
					variables,
					fetchPolicy: "no-cache"
				});
				store.dispatch({
					type: ActionTypes.GET_PAYOUT_SHEETS_LIST_SUCCESS,
					payload: resp.data.payoutSheets
				});
				store.dispatch({
					type: ActionTypes.SET_FIRST_LOAD_STATE,
					payload: false
				});
			} else {
				store.dispatch({
					type: ActionTypes.SET_SELECTED_PLATFORM,
					payload: { value: "swiggy", valueForDisplay: "Swiggy" }
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.GET_PAYOUT_SHEETS_LIST_FAILURE
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong. Failed to fetch payout sheet lists.",
					timeout: 3000,
					error: true
				}
			});
		}
		setLoading(false);
	};

	const openUploadSheet = (platform = { value: "swiggy", valueForDisplay: "Swiggy" }) => {
		// disable the welcome screen & take user to the list view
		store.dispatch({
			type: ActionTypes.SET_FIRST_LOAD_STATE,
			payload: false
		});
		history.push("/payout-sheets/upload");
	};

	useEffect(() => {
		setLoading(true);
		fetchPayoutSheetValuesAndCheck();
	}, []);

	const testMouseEnter = () => {
		setGraphicLensPosition({
			x: "-12px",
			y: "196px",
			focus: true
		});
	};
	const testMouseLeave = () => {
		setGraphicLensPosition({
			x: "-48px",
			y: "96px",
			focus: false
		});
	};

	return loading ? (
		<div className="welcomeboard">
			<div className="main-body">
				<div className="section-container-common section-container-size-adjust">
					<div className="shimmers">
						<div className="P(10px)">
							<div className="shimmer H(100px) Mb(10px)" />
							<div className="shimmer H(100px) Mb(10px)" />
							<div className="shimmer H(100px) Mb(10px)" />
							<div className="shimmer H(100px) Mb(10px)" />
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="welcomeboard">
			<div className="main-body">
				<div className="section-container-common section-container-size-adjust">
					<div className="info-graphics-container">
						<div className="video-container">
							<div className="recon-intro-video">
								<iframe
									src="https://urbanpiper.portal.trainn.co/share/T5xI68UxoAeO5nW4pBAciA/embed"
									title="Piper Academy"
									class="trainn-iframe"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
									onLoad={() => {
										setVideoLoading(false);
									}}
								></iframe>
								{videoLoading && <Loading />}
							</div>
						</div>
						<div
							className="info-details-section"
							onMouseEnter={testMouseEnter}
							onMouseLeave={testMouseLeave}
						>
							<div className="header">Are you leaving money on the table?</div>
							<div className="write-up">
								We have built a tool for you to do <strong>reconciliation</strong> automatically and{" "}
								<strong>detect leakages</strong> arising out of missing orders and inconsistencies in
								payout and commission calculations.
							</div>
							<div>
								<Button clickHandler={openUploadSheet}>Try now for Free!</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default WelcomeBoard;
