import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";

const BasicInfo = ({ data = {}, tagGroups = [], handleForm, validations, readOnly = true }) => {
	return (
		<React.Fragment>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.title || ""}
					onChange={(e) => handleForm("title", e.target.value)}
					requiredLabel={true}
					validationMessage={validations.title || ""}
					readOnly={data.isSystem || readOnly}
				>
					Title
				</InputWithLabel>
				<InputWithLabel
					value={data.crmTitle || ""}
					onChange={(e) => handleForm("crmTitle", e.target.value)}
					validationMessage={validations.crmTitle || ""}
					readOnly={data.isSystem || readOnly}
				>
					CRM Title
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Tag Group"
					options={tagGroups.filter((tg) => tg.isSystem === false && tg.isEnabled === true && tg.id !== -1)}
					readOnly={data.isSystem || readOnly}
					archived={data.tagGroup && !data.tagGroup.isEnabled}
					field="tagGroup"
					currValue={data.tagGroup}
					setFilter={handleForm}
					labelKey="title"
					valueKey="id"
					validationMessage={validations.tagGroup || ""}
				/>
				<Switch
					title="Is Searchable"
					checked={data.isSearchable}
					clickHandler={() => handleForm("isSearchable", !data.isSearchable)}
					validationMessage={validations.isSearchable || ""}
					readOnly={data.isSystem || readOnly}
				/>
			</div>
			<div className="form-row row-half">
				<Switch
					title="Is Filterable"
					checked={data.isFilterable}
					clickHandler={() => handleForm("isFilterable", !data.isFilterable)}
					validationMessage={validations.isFilterable || ""}
					readOnly={data.isSystem || readOnly}
				/>
			</div>
		</React.Fragment>
	);
};
export default BasicInfo;
