import gql from "graphql-tag";

export const GET_LOCATION_NAME = gql`
	query getLocation($id: Int) {
		store(id: $id) {
			name
		}
	}
`;

export const PAYOUT_SHEETS_COUNT = gql`
	query payoutSheetsCount($platforms: [String]) {
		platformPayoutSheets(platforms: $platforms) {
			platform
			count
		}
	}
`;

export const PAYOUT_SHEET_LISTS = gql`
	query listPayoutSheets(
		$platform: String
		$filters: [ListFilterArgument]
		$sort: SortInput
		$limit: Int
		$offset: Int
	) {
		payoutSheets(platform: $platform, filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
			count
			objects {
				id
				startDate
				endDate
				processingStatus
				bizLocationName
				orderCount
				payoutAmount
			}
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
				custom
			}
		}
	}
`;

export const PAYOUT_SHEET_DETAIL = gql`
	query payoutSheetDetails($id: String) {
		payoutSheet(id: $id) {
			id
			startDate
			endDate
			payoutAmount
			orderCount
			uploadedBy
			bizId
			bizName
			bizLocationId
			bizLocationName
			bizPlatformName
			platformName
			processingStatus
			orderSubtotal
			totalTaxes
			totalCharges
			packingCharge
			deliveryCharge
			discount
			orderTotal
			aggregatorCommissionPercentage
			aggregatorCommission
			aggregatorCommissionTax
			aggregatorCommissionTotal
			orderLevelAdjustments
			tcs
			tds
			gst
			netPayable
			aggregatorDeductionSubfields {
				key
				value
			}
			orderAdjustmentSubfields {
				key
				value
			}
			filePath
			chargesRefundTotal
			chargesRefundSubFields
			serviceFee
			otherSpends
			totalTaxLiabilites
			platformManager
			misc
			miscSubFields {
				key
				value
			}
			commissionableAmount
			growthServices
			growthServicesSubFields {
				key
				value
			}
			hyperpure
		}
	}
`;

export const PAYOUT_SHEET_ORDERS = gql`
	query payoutSheetMissingOrders($id: String, $limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		payoutSheet(id: $id) {
			orders(limit: $limit, offset: $offset, filters: $filters) {
				limit
				offset
				count
				hasNext
				hasPrevious
				objects {
					id
					upId
					externalId
					orderStatus
					orderTotal
					reconciliationStatus
					inconsistencies {
						key
						value
					}
				}
			}
		}
	}
`;

export const GET_RECONCILIATION_PAYOUT_STATUS = gql`
	query getReconciliationStats($duration: Duration!, $topic: ReconciliationTopic!, $filters: [ListFilterArgument]) {
		reconciliationAnalytics {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			dataset(duration: $duration, topic: $topic, filters: $filters) {
				timeUnit
				dataset {
					category
					current {
						key
						value
					}
				}
			}
		}
	}
`;

export const GET_RECONCILIATION_REVENUE_BREAKDOWN = gql`
	query getReconciliationStats($duration: Duration!, $filters: [ListFilterArgument]) {
		reconciliationAnalytics {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			sankeyChart(duration: $duration, filters: $filters) {
				nodes {
					name
				}
				links {
					source
					target
					value
				}
			}
		}
	}
`;
