import React, { useState, useCallback, useEffect, useMemo } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { Switch } from "../_commons/Switch";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { Loading } from "../_commons/Loading";
import { Topbar } from "../../components/_commons/Topbar";
import { OutsideClick } from "../_commons/OutsideClick";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import ContextMenu from "../_commons/ContextMenu";
import Popover from "../_commons/Popover";
import { Modal } from "../_commons/Modal";
import DisintegrateLpa from "./DisintegrateLpa";
import Logistics from "./Logistics";
import Fulfillment from "./Fulfillment";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import {
	decideMethodAndValidatePlatformId,
	isHolidayHourEnabledPlatform,
	scroll,
	trackEvent,
	validatePlatformUrl
} from "../../atlas-utils";
import {
	TRACKING_ACTION,
	TRACKING_ENTITY,
	TRACKING_EVENT_NAMES,
	TRACKING_MISC,
	TRACKING_SOURCE,
	TRACKING_STATUS,
	businessBasedDisabledFields
} from "../../client-config";

// third party
import moment from "moment";
import { connect } from "react-redux";
import camelcase from "camelcase";
import SatismeterService from "../../services/SatismeterService";

// graphql
import {
	EDIT_LOCATION_PLATFORM,
	LOCATION_ACTION,
	GET_LOCATION_PLATFORMS_TURN_ON_TIMES,
	GET_LOCATION_PLATFORM_ASSOCIATIONS_IDS,
	VERIFY_CATALOGUE_REQUEST,
	UPDATE_LOCATION_PLATFORM_TIMINGS_GROUP_STATUS,
	UPDATE_LOCATION_PLATFORM_HOLIDAY_HOURS_STATUS
} from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";

// config
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";

// constants
import { TIME_PRESET_TYPES, TIME_TYPES } from "../_commons/NewDateCompareFilter";
const DISINTEGRATE_LPA_INITIAL_STATE = {
	isOpen: false,
	location: "",
	platform: "",
	platformId: ""
};
const FORM_TABS = [
	{
		label: "Basic Configs",
		value: "basic_config"
	},
	{
		label: "Logistics",
		value: "logistics"
	},
	{
		label: "Fulfillment",
		value: "fulfillment"
	}
];
const INITIAL_DATE_FILTER_STATE = {
	timeTypeSelected: TIME_TYPES[0],
	presetTypeSelected: undefined,
	date: moment().add(1, "day"),
	time: "00:00:00"
};
const VALIDATIONS_INIT_STATE = {
	platformName: "",
	outletUrl: "",
	externalId: "",
	testId: ""
};

const AggrPlatform = ({
	data,
	biz = {},
	platforms = [],
	locationId,
	updateAggrPlatformData,
	isAdmin = false,
	fetchData,
	locationName,
	isMultibrandEnabled = false,
	handleBrandLocation,
	handleBrandsLabelOption,
	showPopover,
	renderPopover,
	handlePopoverClose,
	isSelfDeliveryEnabled
}) => {
	let channelsInfoArray = useMemo(
		() =>
			(isMultibrandEnabled ? data?.selectedBrandLocation?.associatedPlatforms || [] : platforms)?.map(
				(platform) => ({
					id: platform?.id,
					name: platform?.platform?.platformName,
					logo: platform?.logo || null
				})
			),
		[data?.selectedBrandLocation?.associatedPlatforms, platforms]
	);

	const [hubNavOpen, setHubNav] = useState(false);
	const [channelsList, setChannelsList] = useState(channelsInfoArray?.length > 0 ? [...channelsInfoArray] : []);
	const [selectedChannel, setSelectedChannel] = useState(
		channelsInfoArray?.length > 0 ? channelsInfoArray[0].name.toLowerCase() : undefined
	);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState({});
	const [turnOnTimes, setTurnOnTimes] = useState({});
	const isPrismEnabled = biz?.modulesEnabled?.includes("PRISM");

	const fetchLocationPlatformTurnOnTimes = useCallback(async () => {
		if (channelsInfoArray.length > 0) {
			try {
				const variables = {
					brandId: data?.selectedBrandLocation?.brandId || null,
					locationId,
					platforms: channelsInfoArray.map((plf) => plf.name.toLowerCase())
				};
				const resp = await client.query({
					query: GET_LOCATION_PLATFORMS_TURN_ON_TIMES,
					variables,
					fetchPolicy: "no-cache"
				});
				let platforms = {};
				resp.data.locationPlatformsTurnOnTimes.forEach((plf) => {
					platforms[plf.platform] = plf;
				});
				setTurnOnTimes(platforms);
			} catch (error) {
				console.log(error);
			}
		}
	}, [channelsInfoArray, locationId, data?.selectedBrandLocation]);

	useEffect(() => {
		fetchLocationPlatformTurnOnTimes();
	}, [channelsInfoArray, locationId, data?.selectedBrandLocation?.brandId]);

	const handleHubNavigation = (platformName) => {
		// set channel
		setSelectedChannel(platformName);
		// perform scroll
		const formContainer = document.querySelector(".location-edit-container .form-container");
		const categoryContainer = formContainer.querySelector(`.platform-card[platform-name="${platformName}"]`);
		window.requestAnimationFrame(() => {
			scroll({ top: categoryContainer.offsetTop - 180, left: 0 }, formContainer);
		});

		window.requestAnimationFrame(() => {
			setHubNav(false);
		});
	};

	const handleModal = () => {
		setIsModalOpen(false);
		setModalContent({});
	};

	if (
		isMultibrandEnabled
			? data?.selectedBrandLocation === null || data?.selectedBrandLocation?.associatedPlatforms?.length === 0
			: platforms.length === 0
	) {
		return (
			<div className="aggr-platform-tab">
				{isMultibrandEnabled && data?.brandLocations?.length > 0 && data?.selectedBrandLocation && (
					<div className="form-row row-half">
						<Popover
							open={showPopover}
							renderPopover={renderPopover}
							showClose={true}
							onClose={handlePopoverClose}
							position="down-left"
						>
							<SelectFilterCustom
								options={data.brandLocations || []}
								field="selectedBrandLocation"
								currValue={data.selectedBrandLocation}
								setFilter={(f, value) => handleBrandLocation(value)}
								labelKey={"brandName"}
								valueKey={"brandId"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								isClearable={false}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								classes="brand-selector"
							/>
						</Popover>
					</div>
				)}
				<div className="no-items-placeholder">No associated platforms found</div>
			</div>
		);
	}
	return (
		<div className="aggr-platform-tab">
			{isMultibrandEnabled && (
				<div className="form-row row-half">
					<Popover
						open={showPopover}
						renderPopover={renderPopover}
						showClose={true}
						onClose={handlePopoverClose}
						position="down-left"
					>
						<SelectFilterCustom
							options={data.brandLocations || []}
							field="selectedBrandLocation"
							currValue={data.selectedBrandLocation}
							setFilter={(f, value) => handleBrandLocation(value)}
							labelKey={"brandName"}
							valueKey={"brandId"}
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							isClearable={false}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							classes="brand-selector"
						/>
					</Popover>
				</div>
			)}
			{isMultibrandEnabled &&
				data?.selectedBrandLocation?.associatedPlatforms?.map((plat, i) => (
					<Platform
						key={i}
						locationId={locationId}
						locationName={locationName}
						fetchData={fetchData}
						updateAggrPlatformData={updateAggrPlatformData}
						isAdmin={isAdmin}
						biz={biz}
						brandId={data?.selectedBrandLocation?.brandId}
						isMultibrandEnabled={isMultibrandEnabled}
						turnOnTimes={turnOnTimes}
						fetchLocationPlatformTurnOnTimes={fetchLocationPlatformTurnOnTimes}
						brandLocationId={data?.selectedBrandLocation?.id}
						isPrismEnabled={isPrismEnabled}
						isSelfDeliveryEnabled={isSelfDeliveryEnabled}
						setIsModalOpen={setIsModalOpen}
						setModalContent={setModalContent}
						orderingSlots={data.selectedBrandLocation?.orderingSlots || data?.orderingSlots}
						holidayHours={data.selectedBrandLocation?.holidayHours?.objects || []}
						{...plat}
					/>
				))}
			{!isMultibrandEnabled &&
				platforms.map((plat, i) => (
					<Platform
						key={i}
						locationId={locationId}
						locationName={locationName}
						fetchData={fetchData}
						updateAggrPlatformData={updateAggrPlatformData}
						isAdmin={isAdmin}
						biz={biz}
						turnOnTimes={turnOnTimes}
						fetchLocationPlatformTurnOnTimes={fetchLocationPlatformTurnOnTimes}
						brandLocationId={data?.selectedBrandLocation?.id}
						isPrismEnabled={isPrismEnabled}
						isSelfDeliveryEnabled={isSelfDeliveryEnabled}
						setIsModalOpen={setIsModalOpen}
						setModalContent={setModalContent}
						orderingSlots={data.orderingSlots}
						holidayHours={data?.holidayHours?.objects || []}
						{...plat}
					/>
				))}
			{channelsList.length > 2 && (
				<HubNavigation
					hubNavOpen={hubNavOpen}
					setHubNav={setHubNav}
					handleHubNavigation={handleHubNavigation}
					handleOutsideClick={() => setHubNav(false)}
					channelsList={channelsList}
					selectedChannel={selectedChannel}
				/>
			)}
			<Modal
				isOpen={isModalOpen}
				close={handleModal}
				title={modalContent.title}
				subTitle=""
				showSubmitAction={false}
				showCancelAction={true}
				cancelTitle="Dismiss"
			>
				{modalContent.message || ""}
			</Modal>
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(AggrPlatform);

const Platform = ({
	id,
	state,
	platform,
	latestToggleAction,
	latestPublishAction,
	latestAction,
	locationId,
	locationName,
	updateAggrPlatformData,
	isAdmin = false,
	fetchData,
	biz,
	brandId = null,
	isMultibrandEnabled = false,
	turnOnTimes = {},
	fetchLocationPlatformTurnOnTimes,
	brandLocationId,
	isPrismEnabled,
	isSelfDeliveryEnabled,
	setIsModalOpen,
	setModalContent,
	orderingSlots,
	holidayHours = [],
	...props
}) => {
	const [externalId, setExternalId] = useState(props.externalId || "");
	const [outletUrl, setOutletUrl] = useState(props.outletUrl || "");
	const [testId, setTestId] = useState(props.testId || "");
	const [isFormUntouched, setFormUntouched] = useState(true);
	const [loading, setLoading] = useState(false);
	const [actionResponse, setActionResponse] = useState(undefined);
	const [disintegrateLpa, setDisintegrateLpa] = useState(DISINTEGRATE_LPA_INITIAL_STATE);
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [currState, setCurrState] = useState(state || "");
	const [validations, setValidations] = useState(VALIDATIONS_INIT_STATE);
	const [isContextMenuOpen, setContextMenuOpen] = useState(false);
	const [dateTimeFilter, setDateTimeFilter] = useState({
		currentDateFilter: INITIAL_DATE_FILTER_STATE,
		appliedDateFilter: INITIAL_DATE_FILTER_STATE
	});
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;

	const saveLocationPlatform = useCallback(async () => {
		let outletUrlValidation = true;
		let externalIdValidation = true;

		if (platform?.platformName?.toLowerCase()) {
			outletUrlValidation = businessBasedDisabledFields.platformUrl[platform?.platformName?.toLowerCase()]
				? true
				: validatePlatformUrl(platform.platformName.toLowerCase(), outletUrl);
			externalIdValidation =
				!externalId || businessBasedDisabledFields.platformId[platform?.platformName?.toLowerCase()]
					? true
					: decideMethodAndValidatePlatformId(platform.platformName.toLowerCase(), externalId);
			if (!(outletUrlValidation && externalIdValidation)) {
				setValidations((current) => ({
					...current,
					platformName: platform.platformName.toLowerCase(),
					externalId: !externalIdValidation ? "Invalid platform ID" : "",
					outletUrl: !outletUrlValidation ? "Invalid platform URL" : ""
				}));
				return;
			} else {
				setValidations({
					...VALIDATIONS_INIT_STATE
				});
			}
		}

		const changedFields = [];
		if (externalId && String(props.externalId) !== String(externalId)) {
			changedFields.push("externalId");
		}
		if (outletUrl && props.outletUrl !== outletUrl) {
			changedFields.push("outletUrl");
		}
		if (testId && String(props.testId) !== String(testId)) {
			changedFields.push("testId");
		}

		const eventMeta = {
			fields_updated: changedFields,
			num_of_fields_updated: changedFields.length
		};

		try {
			setLoading(true);
			const variables = {
				id: parseInt(id),
				outletUrl,
				externalId,
				testId
			};

			const resp = await client.mutate({
				mutation: EDIT_LOCATION_PLATFORM,
				variables
			});
			if (resp.data.saveLocationPlatform.status.success) {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_HUB_UPDATE, eventMeta);

				const actionResp = {
					status: true,
					message: "Platform details updated!"
				};
				setActionResponse(actionResp);
				updateAggrPlatformData(id, outletUrl, externalId, testId, currState, brandId);
				setFormUntouched(true);
			} else {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_HUB_UPDATE, eventMeta);

				const messages = resp.data.saveLocationPlatform.status.messages;
				if (messages[0].field) {
					setValidations((current) => ({
						...current,
						platformName: platform.platformName.toLowerCase(),
						[camelcase(messages[0].field)]: messages[0].message
					}));
				} else {
					const actionResp = {
						status: false,
						message: resp.data.saveLocationPlatform.status.messages[0].message
					};
					setActionResponse(actionResp);
				}
			}
		} catch (error) {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.LOCATION_HUB_UPDATE, eventMeta);

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [id, outletUrl, externalId, testId, updateAggrPlatformData, brandId, currState]);

	const resetForm = () => {
		setExternalId(props.externalId || "");
		setOutletUrl(props.outletUrl || "");
		setTestId(props.testId || "");
		setCurrState(state || "");
		setFormUntouched(true);
		setActionResponse(undefined);
		setFormTab(FORM_TABS[0].value);
		setValidations(VALIDATIONS_INIT_STATE);
		setDisintegrateLpa(DISINTEGRATE_LPA_INITIAL_STATE);
		setLoading(false);
	};

	useEffect(() => {
		resetForm();
	}, [brandId]);

	const handleToggle = useCallback(
		async (action, snoozeDatetime = "") => {
			if (action === "enable") {
				setCurrState("Enabled");
			} else if (action === "disable") {
				setCurrState("Disabled");
			}
			setActionResponse(undefined);

			if (["enable", "disable"].includes(action)) {
				const customSnoozeDuration = moment(snoozeDatetime)?.diff(moment(), "minutes");
				const storeToggleEventMeta = {
					source: TRACKING_SOURCE.DETAIL_VIEW,
					entity: TRACKING_ENTITY.LOCATION,
					num_locations: 1,
					platforms: [platform?.platformName.toLowerCase()],
					action: action === "enable" ? TRACKING_ACTION.SET_ONLINE : TRACKING_ACTION.SET_OFFLINE,
					snoozed_until:
						action === "disable"
							? customSnoozeDuration
								? `${customSnoozeDuration}_MINUTES`
								: snoozeDatetime
								? snoozeDatetime
								: TRACKING_MISC.INDEFINITELY
							: null,
					is_duration_custom: action === "disable" ? (customSnoozeDuration ? true : false) : null
				};
				trackEvent(TRACKING_EVENT_NAMES.AVAILABILITY, storeToggleEventMeta);
			}

			const rtglEventMeta = {
				eventTriggerSection: "location_details_View",
				platforms: [platform?.platformName.toLowerCase()],
				number_of_locations: 1,
				source: TRACKING_SOURCE.DETAIL_VIEW
			};

			try {
				if (action === "integrate") {
					// track event for request to go live
					trackEvent(TRACKING_EVENT_NAMES.RTGL_START, rtglEventMeta);
				}
				if (!disintegrateLpa.isOpen) {
					setLoading(true);
				}
				const variables = {
					locationIds: [parseInt(locationId)],
					platformIds: [parseInt(platform.id)],
					action,
					snoozeDatetime
				};
				if (isMultibrandEnabled) {
					variables.brand = brandId;
				}
				const resp = await client.mutate({
					mutation: LOCATION_ACTION,
					variables
				});
				if (resp.data.locationAction.status.success) {
					if (action === "integrate") {
						trackEvent(TRACKING_EVENT_NAMES.RTGL_SUCCESS, rtglEventMeta);
					}
					// handle success message
					const actionResp = {
						status: true,
						message: resp.data.locationAction.status.messages[0].message
					};
					setActionResponse(actionResp);
					updateAggrPlatformData(
						id,
						isFormUntouched ? outletUrl : props.outletUrl,
						isFormUntouched ? externalId : props.externalId,
						isFormUntouched ? testId : props.testId,
						action === "enable" ? "Enabled" : action === "disable" ? "Disabled" : currState,
						brandId
					);
					if (action === "disable") {
						fetchLocationPlatformTurnOnTimes();
					}
					if (["enable", "disable", "integrate"].includes(action)) {
						// satismeter event
						SatismeterService.locationToggle();
					}
				} else {
					if (action === "integrate") {
						trackEvent(TRACKING_EVENT_NAMES.RTGL_FAILURE, rtglEventMeta);
					}
					// handle error message
					const actionResp = {
						status: false,
						message: resp.data.locationAction.status.messages[0].message
					};
					setActionResponse(actionResp);
				}
			} catch (error) {
				if (action === "integrate") {
					trackEvent(TRACKING_EVENT_NAMES.RTGL_FAILURE, rtglEventMeta);
				}
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[
			locationId,
			platform,
			disintegrateLpa,
			isMultibrandEnabled,
			brandId,
			outletUrl,
			externalId,
			testId,
			currState,
			isFormUntouched
		]
	);

	const handlePublish = useCallback(async () => {
		// check if publish action is disabled for the biz
		if (biz?.scheduledActionsAndNotifications?.actions?.length > 0) {
			const action = biz?.scheduledActionsAndNotifications?.actions?.find(
				(action) => action.action === "deny_menu_publish"
			);
			if (action && action?.applicable) {
				setModalContent({
					title: "Publish Catalogue",
					message: action.message
				});
				setIsModalOpen(true);
				return;
			}
		}
		// handle publish
		setActionResponse(undefined);
		try {
			setLoading(true);
			const variables = {
				locationIds: [parseInt(locationId)],
				platformIds: [parseInt(platform.id)],
				action: "publish"
			};
			if (isMultibrandEnabled) {
				variables.brand = brandId;
			}

			// track publish catalogue event
			const eventMeta = {
				num_locations: 1,
				platforms: [platform?.platformName.toLowerCase()],
				num_of_entities: 1,
				source: TRACKING_SOURCE.DETAIL_VIEW
			};
			trackEvent(TRACKING_EVENT_NAMES.PUBLISH_CATALOGUE, eventMeta);
			// satismeter event
			SatismeterService.menuPublish();

			const resp = await client.mutate({
				mutation: LOCATION_ACTION,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.locationAction.status.success) {
				// handle success message
				const actionResp = {
					status: true,
					message: resp.data.locationAction.status.messages[0].message
				};
				setActionResponse(actionResp);
			} else {
				// handle error message
				const actionResp = {
					status: false,
					message: resp.data.locationAction.status.messages[0].message
				};
				setActionResponse(actionResp);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [locationId, platform, isMultibrandEnabled, brandId]);

	const handleUpdateDeliveryArea = useCallback(
		async (platform) => {
			setActionResponse(undefined);
			try {
				setLoading(true);
				const variables = {
					locationIds: [parseInt(locationId)],
					platformIds: [parseInt(platform.id)],
					action: "publish_store_delivery_area"
				};
				if (isMultibrandEnabled) {
					variables.brand = brandId;
				}
				const resp = await client.mutate({
					mutation: LOCATION_ACTION,
					variables,
					fetchPolicy: "no-cache"
				});
				if (resp.data.locationAction.status.success) {
					// handle success message
					const actionResp = {
						status: true,
						message: resp.data.locationAction.status.messages[0].message
					};
					setActionResponse(actionResp);
				} else {
					// handle error message
					const actionResp = {
						status: false,
						message: resp.data.locationAction.status.messages[0].message
					};
					setActionResponse(actionResp);
				}
			} catch (error) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[locationId, platform, isMultibrandEnabled, brandId]
	);

	const getReadableActionString = useCallback((actionLog) => {
		if (!actionLog) {
			return null;
		}
		let result = "--";
		const date = actionLog.created ? moment(actionLog.created).format("DD MMM, YYYY hh:mm A") : "--";
		switch (actionLog.action) {
			case "PUBLISH":
				result = `Last published at ${date}`;
				break;
			case "ENABLE":
				result = `Last enabled at ${date}`;
				break;
			case "DISABLE":
				result = `Last disabled at ${date}`;
				break;
			case "LIVE":
				result = `Taken Live at ${date}`;
				break;
			case "DISCONNECT":
				result = `Disconnected at ${date}`;
				break;
			case "VERIFY":
				result = `Last verified at ${date}`;
				break;
			default:
				break;
		}
		return result;
	}, []);

	const openDisintegrateLpaSidebar = (platformName, platformId) => {
		setDisintegrateLpa({
			...disintegrateLpa,
			isOpen: true,
			location: locationName,
			platform: platformName,
			platformId,
			brandId
		});
	};

	const closeDisintegrateLpaSidebar = (refresh = false) => {
		setDisintegrateLpa(DISINTEGRATE_LPA_INITIAL_STATE);
		if (refresh) {
			fetchData();
		}
	};

	const updateDateTimeFilter = (payload) => {
		if (!payload.appliedDateFilter) {
			setDateTimeFilter({
				...dateTimeFilter,
				...payload
			});
		} else {
			const { date, time, presetTypeSelected, timeTypeSelected } = payload.appliedDateFilter;
			let snoozeDatetime = "";
			if (timeTypeSelected?.value === "range") {
				snoozeDatetime = `${moment(date).format("YYYY-MM-DD")} ${time}`;
			} else {
				snoozeDatetime = presetTypeSelected?.value;
			}
			handleToggle("disable", snoozeDatetime);
		}
	};

	const triggerCatalogueVerificationRequest = async (platform) => {
		setLoading(true);
		setContextMenuOpen((current) => !current);

		try {
			const catalogueVerificationData = [];
			const locationPlatformAssociationsResp = await client.query({
				query: GET_LOCATION_PLATFORM_ASSOCIATIONS_IDS,
				variables: {
					locationIds: [locationId],
					brandIds: isMultibrandEnabled ? [String(brandId)] : null
				},
				fetchPolicy: "no-cache"
			});

			const locationPlatformAssociations = locationPlatformAssociationsResp?.data?.locationPlatformAssociations;

			if (!locationPlatformAssociations) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Oops! Something went wrong",
						timeout: 5000,
						error: true
					}
				});
				return;
			}

			locationPlatformAssociations.forEach((lpa) => {
				if (platform?.platformName?.toLowerCase() === lpa.platformName?.toLowerCase()) {
					catalogueVerificationData.push({
						locationId: locationId,
						bizPlatformId: platform?.id,
						platformName: platform?.platformName?.toLowerCase(),
						id: lpa?.id
					});
				}
			});

			const variables = {
				data: [...catalogueVerificationData],
				action: "verify"
			};

			const verifyCatalogueResp = await client.mutate({
				mutation: VERIFY_CATALOGUE_REQUEST,
				variables
			});

			if (verifyCatalogueResp?.data?.saveLocationPlatformAssociations?.status?.success) {
				const eventMeta = {
					platforms: [platform?.platformName.toLowerCase()],
					number_of_locations: 1,
					source: TRACKING_SOURCE.DETAIL_VIEW
				};
				trackEvent(TRACKING_EVENT_NAMES.ON_DEMAND_VERIFICATION, eventMeta);
			}
		} catch (e) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Oops! Something went wrong",
					timeout: 5000,
					error: true
				}
			});
		}
		setLoading(false);
	};
	const updateLocationPlatformHolidayHours = async () => {
		try {
			const variables = {
				locationId,
				platformIds: [parseInt(platform.id)]
			};

			variables.holidayHours = holidayHours.map((obj) => parseInt(obj.id));

			if (brandId) {
				variables.brandId = brandId;
			}

			const resp = await client.query({
				query: UPDATE_LOCATION_PLATFORM_HOLIDAY_HOURS_STATUS,
				variables,
				fetchPolicy: "no-cache"
			});

			if (resp?.data?.updateLocationHolidayHours?.status?.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Sync request queued",
						timeout: 5000,
						error: false
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (e) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	const updateLocationPlatformTimingsGroups = async () => {
		const eventMeta = {
			platform: platform?.platformName
		};
		try {
			const variables = {
				locationId,
				platformIds: [parseInt(platform.id)]
			};
			if (orderingSlots?.id) {
				variables.orderingSlot = parseInt(orderingSlots.id);
			} else if (!orderingSlots?.id) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "No schedule associated!",
						timeout: 5000,
						error: true
					}
				});
				return;
			}
			if (brandId) {
				variables.brandId = brandId;
			}
			const resp = await client.query({
				query: UPDATE_LOCATION_PLATFORM_TIMINGS_GROUP_STATUS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp?.data?.updateLocationTimingsGroup?.status?.success) {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_TIMINGS_UPSTREAM_CALL, eventMeta);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Sync request queued",
						timeout: 5000,
						error: false
					}
				});
			} else {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_TIMINGS_UPSTREAM_CALL, eventMeta);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (e) {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.LOCATION_TIMINGS_UPSTREAM_CALL, eventMeta);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	const renderMenuItems = (platform, state) => {
		const isHolidayHourEnabled = isHolidayHourEnabledPlatform(platform);

		return (
			<React.Fragment>
				{isPrismEnabled && (
					<div
						className="action-item"
						onClick={() => {
							triggerCatalogueVerificationRequest(platform);
							setContextMenuOpen(false);
						}}
					>
						Verify
					</div>
				)}
				{isSelfDeliveryEnabled && (
					<div className="action-item" onClick={() => handleUpdateDeliveryArea(platform)}>
						Update Delivery Area
					</div>
				)}
				<div
					className="action-item"
					onClick={() => {
						updateLocationPlatformTimingsGroups();
						setContextMenuOpen(false);
					}}
				>
					Sync regular schedule
				</div>
				{isHolidayHourEnabled && (
					<div
						className="action-item"
						onClick={() => {
							updateLocationPlatformHolidayHours();
							setContextMenuOpen(false);
						}}
					>
						Sync holiday schedule
					</div>
				)}
				{(state !== "Not live" || currState !== "Not live") && (
					<div
						className="action-item danger"
						onClick={() => openDisintegrateLpaSidebar(platform.platformName, platform.id)}
					>
						Disconnect
					</div>
				)}
			</React.Fragment>
		);
	};

	return (
		<div
			platform-name={platform.platformName.toLowerCase()}
			className={"platform-card" + (loading ? " no-click" : "")}
		>
			<div className="platform-header">
				<div className="platform-info">
					<img
						src={
							platform?.logo ||
							CATALOGUE_PLATFORMS_LOGO[platform.platformName.toLowerCase()] ||
							"/assets/icons/icons8-globe-40.png"
						}
					/>
					<span className="name">
						<div>{platform.platformName}</div>
						{turnOnTimes[platform.platformName.toLowerCase()] &&
							turnOnTimes[platform.platformName.toLowerCase()]?.snoozedUntil !== 0 &&
							currState !== "Enabled" && (
								<div className="turn-on-time">
									Will be set online on{" "}
									{moment(turnOnTimes[platform.platformName.toLowerCase()]?.snoozedUntil).format(
										"DD MMM YYYY"
									)}
									,{" "}
									{moment(turnOnTimes[platform.platformName.toLowerCase()]?.snoozedUntil).format(
										"hh:mm:ss A"
									)}
								</div>
							)}
					</span>
				</div>
				<div className={"platform-action" + (state === "Disconnected" ? " Top(0px)" : "")}>
					{state === "Disconnected" || currState === "Disconnected" ? (
						isAdmin ? (
							<Button clickHandler={() => handleToggle("integrate")} classes={"at-btn--success"}>
								<ButtonIcon icon="live" />
								Request To Go Live
							</Button>
						) : (
							<div className="status">{state}</div>
						)
					) : (
						<React.Fragment>
							{isAdmin && (state !== "Not live" || currState !== "Not live" || isPrismEnabled) && (
								<ContextMenu
									isOpen={isContextMenuOpen}
									renderMenuItems={() => renderMenuItems(platform, state)}
									handleOpenMenu={() => setContextMenuOpen(!isContextMenuOpen)}
									handleOutsideClick={() => setContextMenuOpen(false)}
									clickEvent="mousedown"
								/>
							)}
							{(state !== "Not live" || currState !== "Not live") && !isMenuOverCatalogueEnabled && (
								<Button clickHandler={handlePublish} classes={"W(100px)"} type="secondary">
									Publish
								</Button>
							)}
							{(currState === "Enabled" || currState === "Disabled" || currState === "Not live") && (
								<div className="toggle-status">
									<Switch
										title={currState !== "Enabled" ? "Offline" : ""}
										checked={currState === "Enabled"}
										clickHandler={() =>
											handleToggle(currState === "Enabled" ? "disable" : "enable")
										}
									/>
									{currState === "Enabled" && (
										<NewDateCompareFilter
											showDropdown={true}
											monthsShown={1}
											isRange={false}
											loading={loading}
											currentDateFilter={dateTimeFilter?.currentDateFilter}
											appliedDateFilter={dateTimeFilter?.appliedDateFilter}
											updateState={updateDateTimeFilter}
											hideComparison={true}
											hideDatePresets={true}
											showTimePresets={true}
											customLabel={currState === "Enabled" ? "Online" : "Offline"}
											showTime={true}
											hideFooter={true}
											position="right"
											customMessage={
												dateTimeFilter?.currentDateFilter?.timeTypeSelected?.value === "range"
													? `Will be set ${
															currState === "Enabled" ? "Offline" : "Online"
													  } till ${moment(dateTimeFilter?.currentDateFilter?.date).format(
															"DD MMM YYYY"
													  )}, ${moment(
															dateTimeFilter?.currentDateFilter?.time,
															"hh:mm:ss"
													  ).format("hh:mm:ss A")}`
													: dateTimeFilter?.currentDateFilter?.presetTypeSelected?.label
													? `Will be set ${
															currState === "Enabled" ? "Offline" : "Online"
													  } for ${
															dateTimeFilter?.currentDateFilter?.presetTypeSelected?.label
													  }`
													: ""
											}
										/>
									)}
								</div>
							)}
						</React.Fragment>
					)}
				</div>
			</div>
			{/* currently logistics & fulfillment support is only for zomato */}
			{platform.platformName === "Zomato" && (
				<React.Fragment>
					<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={(tab) => setFormTab(tab.value)} />
					{formTab == FORM_TABS[0].value && (
						<React.Fragment>
							<div className="form-row row-full">
								<InputWithLabel
									value={externalId || ""}
									onChange={(e) => {
										setExternalId(e.target.value);
										if (isFormUntouched) {
											setFormUntouched(false);
										}
									}}
									validationMessage={
										validations.platformName.toLowerCase() === platform.platformName?.toLowerCase()
											? validations.externalId
											: ""
									}
								>
									{`${platform.platformName} Platform ID`}
								</InputWithLabel>
							</div>
							<div className="form-row row-full">
								<InputWithLabel
									value={outletUrl || ""}
									onChange={(e) => {
										setOutletUrl(e.target.value);
										if (isFormUntouched) {
											setFormUntouched(false);
										}
									}}
									showCopyToClipboard={true}
									showIcon={true}
									classes="at-input--copy"
									validationMessage={
										validations.platformName.toLowerCase() === platform.platformName.toLowerCase()
											? validations.outletUrl
											: ""
									}
								>
									{`${platform.platformName} Platform URL`}
								</InputWithLabel>
								{outletUrl && (
									<a
										href={
											["http://", "https://"].some((protocol) => outletUrl.includes(protocol))
												? outletUrl
												: `http://${outletUrl}`
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src="/assets/icons/icon-external-link.svg" alt="" />
									</a>
								)}
							</div>
							{platform.platformName === "Zomato" && (
								<div className="form-row row-full">
									<InputWithLabel
										value={testId || ""}
										onChange={(e) => {
											setTestId(e.target.value);
											if (isFormUntouched) {
												setFormUntouched(false);
											}
										}}
									>
										{`${platform.platformName} Test ID`}
									</InputWithLabel>
								</div>
							)}
						</React.Fragment>
					)}
					{formTab == FORM_TABS[1].value && (
						<Logistics
							platform={platform}
							platformId={platform.id}
							locationId={locationId}
							brandId={brandId}
						/>
					)}
					{formTab == FORM_TABS[2].value && (
						<Fulfillment
							platform={platform}
							platformId={platform.id}
							locationId={locationId}
							brandId={brandId}
						/>
					)}
				</React.Fragment>
			)}
			{platform.platformName !== "Zomato" && (
				<React.Fragment>
					<div className="form-row row-full">
						<InputWithLabel
							disabled={platform.platformName === "Meraki"}
							value={externalId || ""}
							onChange={(e) => {
								setExternalId(e.target.value);
								if (isFormUntouched) {
									setFormUntouched(false);
								}
							}}
							validationMessage={
								validations.platformName.toLowerCase() === platform.platformName.toLowerCase()
									? validations.externalId
									: ""
							}
						>
							{`${platform.platformName} Platform ID`}
						</InputWithLabel>
					</div>
					<div className="form-row row-full">
						<InputWithLabel
							disabled={platform.platformName === "Meraki"}
							value={outletUrl || ""}
							onChange={(e) => {
								setOutletUrl(e.target.value);
								if (isFormUntouched) {
									setFormUntouched(false);
								}
							}}
							showCopyToClipboard={true}
							showIcon={true}
							classes="at-input--copy"
							validationMessage={
								validations.platformName.toLowerCase() === platform.platformName.toLowerCase()
									? validations.outletUrl
									: ""
							}
						>
							{`${platform.platformName} Platform URL`}
						</InputWithLabel>
						{outletUrl && (
							<a
								href={
									["http://", "https://"].some((protocol) => outletUrl.includes(protocol))
										? outletUrl
										: `http://${outletUrl}`
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src="/assets/icons/icon-external-link.svg" alt="" />
							</a>
						)}
					</div>
					{["Ubereats"].includes(platform.platformName) && (
						<div className="form-row row-full">
							<InputWithLabel
								value={testId || ""}
								onChange={(e) => {
									setTestId(e.target.value);
									if (isFormUntouched) {
										setFormUntouched(false);
									}
								}}
							>
								{`${platform.platformName} Test ID`}
							</InputWithLabel>
						</div>
					)}
				</React.Fragment>
			)}
			{actionResponse && (
				<div className="platform-action-message">
					<div onClick={() => setActionResponse(null)} className="close">
						&times;
					</div>
					<div className={"publish-response " + (actionResponse.status ? "success" : "fail")}>
						{actionResponse.message}
					</div>
				</div>
			)}
			{!isFormUntouched && <PlatformActions saveLocationPlatform={saveLocationPlatform} resetForm={resetForm} />}
			{loading && <Loading />}
			<DisintegrateLpa
				isOpen={disintegrateLpa.isOpen}
				close={closeDisintegrateLpaSidebar}
				data={disintegrateLpa}
				submit={() => handleToggle("disconnect")}
			/>
		</div>
	);
};

const PlatformActions = ({ saveLocationPlatform, resetForm }) => {
	return (
		<div className="form-action">
			<Button clickHandler={saveLocationPlatform} classes={"W(100px)"}>
				Save
			</Button>
			<Button clickHandler={resetForm} classes={"W(100px)"} type="secondary">
				Cancel
			</Button>
		</div>
	);
};

const HubNavigation = OutsideClick(
	({ hubNavOpen, setHubNav, handleHubNavigation, channelsList, selectedChannel, nodeRef }) => {
		return (
			<div ref={nodeRef} className="hub-channels-navigation" onClick={() => setHubNav(!hubNavOpen)}>
				<img src="/assets/icons/icon-channels-navigation.svg" />
				<span>Channels</span>
				{hubNavOpen && (
					<div className="channels-list" onClick={(e) => e.preventDefault()}>
						{channelsList.map((channel, i) => (
							<div
								className="channel-list-item"
								data-active={channel.name.toLowerCase() === selectedChannel ? true : false}
								key={i}
								onClick={() => handleHubNavigation(channel.name.toLowerCase())}
							>
								{channel.name}
								<img
									src={
										channel?.logo ||
										CATALOGUE_PLATFORMS_LOGO[channel.name.toLowerCase()] ||
										"/assets/icons/icons8-globe-40.png"
									}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
);
