import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import LocationEntityAssociations from "../EntityAssociations/LocationEntityAssociations";
import FormFilters from "../_commons/FormFilters";

// third party
import { useTrail, config, animated } from "react-spring";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { parseFilters, printCurrency } from "../../atlas-utils";

// graphql
import { GET_DISCOUNT_LOCATIONS } from "../../graphql/discounts";

// actions
import { ActionTypes } from "../../actions/_types";

// config
import { NESTED_ENTITY_TYPES } from "../../client-config";
const DISCOUNT_ASSOCIATED_LOCATIONS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "name" },
	{ label: "city", value: "city" }
];

const AssociatedLocations = ({ discountId, entityType, setModalBusy, handleNestedEntity, currencySymbol }) => {
	const [loading, setLoading] = useState(false);
	const [locationsList, setLocationsList] = useState([]);
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const fetchDiscountLocations = useCallback(
		async (filters = {}) => {
			try {
				setLoading(true);
				const variables = {
					id: parseInt(discountId),
					entityType: entityType.value,
					limit: 100,
					offset: 0
				};
				// sidebar filters
				const filtersObject = parseFilters(filters);
				if (filtersObject.length > 0) {
					variables.filters = filtersObject;
				}
				const resp = await client.query({
					query: GET_DISCOUNT_LOCATIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				setLocationsList(resp.data.platformDiscount.locations.objects);
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[discountId]
	);

	useEffect(() => {
		fetchDiscountLocations();
	}, [fetchDiscountLocations]);

	const applyFilter = useCallback(
		(filters) => {
			fetchDiscountLocations(filters);
		},
		[fetchDiscountLocations]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchDiscountLocations();
		}
	};

	return (
		<div className={"associated-locations-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Associated Locations</div>
					<div className="header-subtext">
						Locations where this discount will be applied for selected items
					</div>
				</div>
				<div>
					<Button clickHandler={openAssociationSidebar}>Update</Button>
				</div>
			</div>
			<FormFilters
				enableName={true}
				nameConfig={{
					field: "name",
					placeholder: "Enter Name"
				}}
				enableCity={true}
				cityConfig={{
					field: "city"
				}}
				enableTags={true}
				apply={applyFilter}
			/>
			<Table
				data={locationsList}
				loading={loading}
				handleNestedEntity={handleNestedEntity}
				currencySymbol={currencySymbol}
			/>
			<LocationEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={{ label: "Discount", value: `${entityType.value}_DISCOUNT` }}
				entityId={discountId}
			/>
		</div>
	);
};
export default AssociatedLocations;

export const Table = ({ data, loading, sortList, sortedField, handleNestedEntity, currencySymbol }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container discount-edit-locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={DISCOUNT_ASSOCIATED_LOCATIONS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							handleNestedEntity={handleNestedEntity}
							currencySymbol={currencySymbol}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Locations found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.subText && <span className="at-header-subtext">{field.subText}</span>}
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({ id, style, handleNestedEntity, title, city, tags }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text name" title={title}>
				<a
					role="button"
					className="link-text"
					onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[2], id)}
					title={title}
				>
					{title || id}
				</a>
				<div className="tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 3 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 3 && (
									<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
										+{tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text city">{city || "--"}</div>
		</animated.div>
	);
};
