import React, { Component } from "react";

// third party
import Select from "react-select-animated";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";

export default class NumberRangeFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rangeTypes: [
				{ label: "Not specified", value: "--" },
				{ label: "Is equal to", value: "equal" },
				{ label: "Is between", value: "between" },
				{ label: "Is greater than", value: "greater_than" },
				{ label: "Is less than", value: "less_than" }
			],
			tempRangeStart: "100",
			tempRangeEnd: "200",
			focused: false
		};
	}

	changeRangeType = (rangeTypeSelected) => {
		let newValue = {};
		const { rangeTypes } = this.state;
		switch (rangeTypeSelected.value) {
			case rangeTypes[1].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [this.state.tempRangeStart];
				break;
			case rangeTypes[2].value:
				newValue.type = "range";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [this.state.tempRangeStart, this.state.tempRangeEnd];
				break;
			case rangeTypes[3].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [this.state.tempRangeStart];
				break;
			case rangeTypes[4].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [this.state.tempRangeStart];
				break;
			default:
				break;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	handleRangeInput = (e, whichInput) => {
		const { value } = this.props;
		let newValue = { ...value };
		if (e.target.value >= 0 && e.target.value !== "") {
			if (whichInput === "rangeStart") {
				if (newValue.type === "absolute") {
					newValue.values = [e.target.value];
				} else {
					newValue.values = [e.target.value, this.state.tempRangeEnd];
				}
				this.setState({
					tempRangeStart: e.target.value
				});
			} else if (whichInput === "rangeEnd") {
				newValue.values = [this.state.tempRangeStart, e.target.value];
				this.setState({
					tempRangeEnd: e.target.value
				});
			}
			this.props.setFilter(this.props.root, this.props.field, newValue);
		} else if (e.target.value === "") {
			newValue.values = [];
			if (whichInput === "rangeStart") {
				this.setState({
					tempRangeStart: ""
				});
			} else {
				this.setState({
					tempRangeEnd: ""
				});
			}
			this.props.setFilter(this.props.root, this.props.field, newValue);
		}
	};

	setFocus = (focused) => {
		this.setState({
			focused
		});
	};

	render() {
		const { title, value } = this.props;
		const filterApplied = value && value.values && value.values.length > 0 ? true : false;
		const { rangeTypes, focused } = this.state;

		// set default values
		let rangeTypeSelected = rangeTypes[0].value;
		let rangeStart = 0;
		let rangeEnd = 0;

		// set current values according to the props received
		if (value && value.values) {
			rangeTypeSelected = value.operator;
			if (value.type === "range") {
				rangeStart = value.values[0]
					? value.values[0]
					: this.state.tempRangeStart
					? this.state.tempRangeStart
					: "";
				rangeEnd = value.values[1] ? value.values[1] : this.state.tempRangeEnd ? this.state.tempRangeEnd : "";
			} else if (value.type === "absolute") {
				rangeStart = value.values[0] ? value.values[0] : "";
			}
		}

		return (
			<div
				className={
					"number-range-filter-container filter-section-item" +
					(filterApplied ? " filter-applied" : "") +
					(focused ? " active" : "")
				}
			>
				<div className="meta-info">{title}</div>
				<div className="type-selector">
					<Select
						onFocus={() => this.setFocus(true)}
						onBlur={() => this.setFocus(false)}
						searchable={false}
						className="at-dropdown"
						options={rangeTypes}
						value={rangeTypeSelected}
						clearable={false}
						onChange={(m) => this.changeRangeType(m)}
					/>
					<div className="type-selected">
						{rangeTypeSelected !== rangeTypes[0].value && (
							<div>
								<InputWithLabel
									type="number"
									classes="inline-input"
									value={rangeStart}
									onChange={(e) => this.handleRangeInput(e, "rangeStart")}
								/>
								{rangeTypeSelected === rangeTypes[2].value && (
									<React.Fragment>
										<span className="seperator">to</span>
										<InputWithLabel
											type="number"
											classes="inline-input range-second"
											value={rangeEnd}
											onChange={(e) => this.handleRangeInput(e, "rangeEnd")}
										/>
									</React.Fragment>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
