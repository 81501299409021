import React, { useEffect, useRef, useState } from "react";

// third party
import { useSpring, animated as a } from "react-spring";
import { connect } from "react-redux";

// actions
import { fetchTimingGroups, fetchTimingGroupsDebounced } from "../../actions/actions";

// utils
import { formatDate, trackEvent } from "../../atlas-utils";

// components
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";

// action
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

const valueEntryPrototype = { city: "", gstIn: "", address: "" };

const initArrayValue = [];

for (let i = 0; i < 100; i++) {
	initArrayValue.push({ ...valueEntryPrototype });
}

const DetailsEntry = ({
	handleSelectedTabUpdate,
	email,
	phone,
	nickName,
	address,
	handleFormData,
	locationAddType,
	merakiEnabled,
	timingGroups,
	handleNestedEntity,
	locationDetailsArray,
	storeTimings,
	multiLocationDetails,
	emailValidator,
	phoneNumberValidator,
	adjustStringLength
}) => {
	const [scrollClass, setScrollClass] = useState("");
	const scrollRef = useRef(0);
	const [multiLocationValidations, setMultiLocationValidations] = useState([
		...locationDetailsArray.map(() => ({ phone: true, email: true }))
	]);
	const [singleLocationValidations, setSingleLocationValidations] = useState({ email: "", phone: "" });
	const [showIncompletionError, setShowIncompletionError] = useState(false);
	const disabledCheck = Boolean(nickName && (merakiEnabled ? storeTimings : true));

	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const handleChange = (e, field) => {
		handleFormData(field, e.target.value);
	};

	const listenToScroll = (e) => {
		if (scrollRef.current?.scrollTop === 0) {
			setScrollClass("");
		} else {
			setScrollClass("show-border-bottom");
		}
	};

	const handleValueUpdate = (val, i, key) => {
		let updatedArray = [...multiLocationDetails];
		updatedArray[i][key] = val;
		handleFormData("multiLocationDetails", updatedArray);
	};

	useEffect(() => {
		if (merakiEnabled) {
			fetchTimingGroups();
		}
	}, [merakiEnabled]);

	const setFilter = (opt, val) => {
		handleFormData("storeTimings", val ? { ...val } : null);
	};

	const handleNextClick = () => {
		if (locationAddType === "single") {
			let emailValidationResult = email.length > 0 ? emailValidator(email) : true;
			let phoneNumberValidationResult = phone.length > 0 ? phoneNumberValidator(phone) : true;
			if (!emailValidationResult || !phoneNumberValidationResult) {
				setSingleLocationValidations({
					...singleLocationValidations,
					email: !emailValidationResult ? "* Invalid email address" : "",
					phone: !phoneNumberValidationResult ? "* Invalid phone number" : ""
				});
			} else {
				handleSelectedTabUpdate(5);
				trackEvent("wiz_loc_details_entry", {});
			}
		} else {
			let currentLocationDetailsArray = [...locationDetailsArray];
			let validationsFailed = false;
			let multiLocationValidationsUpdate = [...multiLocationValidations];
			for (let i = 0; i < currentLocationDetailsArray.length; i++) {
				if (currentLocationDetailsArray[i].phone) {
					let validPhone = phoneNumberValidator(currentLocationDetailsArray[i].phone);
					multiLocationValidationsUpdate[i].phone = validPhone;
					if (!validPhone) {
						validationsFailed = true;
					}
				}
				if (currentLocationDetailsArray[i].email) {
					let validEmail = emailValidator(currentLocationDetailsArray[i].email);
					multiLocationValidationsUpdate[i].email = validEmail;
					if (!validEmail) {
						validationsFailed = true;
					}
				}
			}
			if (validationsFailed) {
				setMultiLocationValidations([...multiLocationValidationsUpdate]);
				return;
			}
			if (merakiEnabled) {
				handleSelectedTabUpdate(5);
			} else {
				handleSelectedTabUpdate(5);
			}
			trackEvent("wiz_loc_details_entry_tabular", {});
		}
	};

	const addressErrorMultiFormValidation = (i, type) => {
		const currentMutiLocationValidations = [...multiLocationValidations];
		currentMutiLocationValidations[i][type] = true;
		setMultiLocationValidations([...currentMutiLocationValidations]);
	};

	const handlePasteOperation = (pastedText, startIndex) => {
		const checkForNewLine = pastedText.includes("\n");

		if (!checkForNewLine) {
			const newLocationDetailsArray = [...locationDetailsArray];
			newLocationDetailsArray[startIndex] = { ...newLocationDetailsArray[startIndex], phone: pastedText };
			handleFormData("multiLocationDetails", [...newLocationDetailsArray]);
			return;
		}

		const rowWiseDataDistribution = pastedText.split("\n");
		let stopOperation = false;
		for (let i = 0; i < rowWiseDataDistribution.length; i++) {
			rowWiseDataDistribution[i] = rowWiseDataDistribution[i].split("\t");
			if (rowWiseDataDistribution[i].length !== 2) {
				stopOperation = true;
			}
		}

		if (!stopOperation) {
			const newLocationDetailsArray = [...locationDetailsArray];
			for (let i = 0; i < rowWiseDataDistribution.length; i++) {
				if (startIndex + i === newLocationDetailsArray.length) {
					break;
				}
				newLocationDetailsArray[startIndex + i] = {
					...newLocationDetailsArray[startIndex + i],
					phone: rowWiseDataDistribution[i][0],
					email: rowWiseDataDistribution[i][1]
				};
			}
			handleFormData("multiLocationDetails", [...newLocationDetailsArray]);
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Expected a tabular data with 2 columns.",
					timeout: 5000,
					error: true
				}
			});
			handleValueUpdate("", 0, "phone");
		}
	};

	return locationAddType === "single" ? (
		<React.Fragment>
			<a.div className="common-box-structure " style={contentProps}>
				<div className="header">Just a few more details</div>
				<div className="details-entry">
					<div className="input">
						<div className="label label-correction">
							Location name <span className="required">*</span>
						</div>
						<InputWithLabel
							value={nickName}
							onChange={(e) => handleChange(e, "nickName")}
							placeholder={"eg: Indiranagar Store"}
						/>
					</div>
					<div className="input">
						<div className="label">Address</div>
						<textarea
							value={address}
							placeholder={"eg: Unit #123, Forum Mall, Indiranagar"}
							onChange={(e) => handleChange(e, "address")}
						/>
					</div>
					{merakiEnabled && (
						<div className="input timing-group">
							<div className="label label-correction timing-group">Select store timings</div>
							<SelectFilter
								options={timingGroups?.items}
								isLoading={timingGroups?.isLoading}
								field="orderingSlots"
								currValue={storeTimings}
								setFilter={setFilter}
								labelKey="title"
								valueKey="id"
								isAsync={true}
								handleSearch={fetchTimingGroupsDebounced}
							/>
							<div className="add-timing-group" onClick={() => handleNestedEntity(true)}>
								+ Add new
							</div>
						</div>
					)}
					<div className="input">
						<div className="label">Where should we send critical notifications ?</div>
						<div className="critical-notifications-container">
							<InputWithLabel
								placeholder={"Phone"}
								classes={"at-input--label"}
								showLabel={true}
								image={true}
								imageSrc={"/assets/icons/icon-phone-small.svg"}
								value={phone}
								onChange={(e) => handleChange(e, "phone")}
								validationMessage={singleLocationValidations.phone}
							></InputWithLabel>
							<InputWithLabel
								placeholder={"Email"}
								classes={"at-input--label"}
								showLabel={true}
								image={true}
								imageSrc={"/assets/icons/icon-mail.svg"}
								value={email}
								type={"email"}
								onChange={(e) => handleChange(e, "email")}
								validationMessage={singleLocationValidations.email}
							></InputWithLabel>
						</div>
					</div>
				</div>
				<div className="button-div">
					<Button clickHandler={() => handleSelectedTabUpdate(3)} type="secondary">
						<span>Previous</span>
					</Button>
					<Button classes={!disabledCheck && "disabled"} clickHandler={handleNextClick}>
						<span>Next</span>
					</Button>
				</div>
			</a.div>
		</React.Fragment>
	) : (
		<a.div className="common-box-structure " style={contentProps}>
			<div className="header">Please add contact details for locations</div>
			{locationDetailsArray.length > 0 && (
				<div
					className="links-entry-table multi-city-table multi-city-details-table"
					ref={scrollRef}
					onScroll={listenToScroll}
					id="style-scrollbar"
				>
					<div className={`row ${scrollClass}`}>
						<div className="col">Name</div>
						<div className="col">City</div>
						<div className="col">Phone</div>
						<div className="col">Email Address</div>
					</div>
					{locationDetailsArray.map((details, i) => (
						<a.div className="row" style={contentProps}>
							<div className="col" title={details?.locationName}>
								<div title={details?.locationName}>
									{adjustStringLength(details?.locationName || "--")}
								</div>
							</div>
							<div className="col">
								<div>{adjustStringLength(details?.city || "--")}</div>
							</div>
							<div className={`col ${!multiLocationValidations[i].phone ? "error-evaluator" : ""}`}>
								<input
									value={details?.phone}
									type="phone"
									onChange={(e) => handleValueUpdate(e.target.value, i, "phone")}
									onFocus={
										!multiLocationValidations[i].phone
											? () => {
													addressErrorMultiFormValidation(i, "phone");
											  }
											: () => {}
									}
									placeholder={i === 0 && "eg: 9876543210"}
									onPaste={(e) => {
										handlePasteOperation(e.clipboardData.getData("Text"), i);
									}}
								/>
								<span
									className={`error-evaluator-content ${
										!multiLocationValidations[i].phone ? "visible" : ""
									}`}
									onClick={() => {
										addressErrorMultiFormValidation(i, "phone");
										handleValueUpdate("", i, "phone");
									}}
								>
									Invalid Phone No.
								</span>
							</div>
							<div className={`col ${!multiLocationValidations[i].email ? "error-evaluator" : ""}`}>
								<input
									value={details.email}
									onChange={(e) => handleValueUpdate(e.target.value, i, "email")}
									placeholder={i === 0 && "eg: johnwatson@urbanpiper.com"}
									onFocus={
										!multiLocationValidations[i].email
											? () => {
													addressErrorMultiFormValidation(i, "email");
											  }
											: () => {}
									}
								/>
								<span
									className={`error-evaluator-content  ${
										!multiLocationValidations[i].email ? "visible" : ""
									}`}
									onClick={() => {
										addressErrorMultiFormValidation(i, "email");
										handleValueUpdate("", i, "email");
									}}
								>
									Invalid email
								</span>
							</div>
						</a.div>
					))}
					<div className={`error ${showIncompletionError ? "visible" : ""}`}>
						* Warning: All the fields must be filled before proceeding further.
					</div>
				</div>
			)}
			<div className="button-div">
				<Button clickHandler={() => handleSelectedTabUpdate(3)} type="secondary">
					<span>Previous</span>
				</Button>
				<Button clickHandler={handleNextClick}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
const mapStateToProps = (store) => ({
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(DetailsEntry);
