import React, { useState, useEffect, useCallback } from "react";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import moment from "moment";
import { connect } from "react-redux";

// utils
import { extractInitials } from "../../atlas-utils";

// graphql
import { GET_LPA_LOGS } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";

// contants
import { BRAND_COLORS, PLATFORM_NAME_MAP } from "../../client-config";
const STATUS = {
	Enabled: "Online",
	Disabled: "Offline",
	"Not live": "Not Live",
	Disconnected: "Disconnected",
	ACK_SUCCESS: "Success",
	ACK_FAILED: "Failed",
	FAILED: "Failed",
	VALIDATION_FAILED: "Failed",
	NA: "NA"
};
const ACTIONS = {
	PUBLISH: "Publish",
	ENABLE: "Enable",
	DISABLE: "Disable",
	LIVE: "Taken Live",
	DISCONNECT: "Disconnection",
	VERIFY: "Verification",
	NA: "NA",
	REQ_TO_GO_LIVE: "Request to go live"
};
const VERIFICATION_STATE = {
	passing: "Success",
	error: "Failed",
	warning: "Warning"
};
const PRISM_VERIFICATION_STATE = {
	created: "Created",
	processing: "Processing",
	success: "Success",
	failed: "Failed",
	error: "Error",
	terminated: "Terminated"
};

const LpaLogs = ({
	platform,
	brand,
	location,
	biz,
	isPrismEnabled = false,
	handleCatalogueVerification,
	fromBrands = false,
	isMultibrandEnabled = false
}) => {
	const [loading, setLoading] = useState(false);
	const [lpaLogs, setLpaLogs] = useState([]);
	const [verificationStatusLog, setVerificationStatusLog] = useState({});

	const fetchLpaLogs = useCallback(async () => {
		setLoading(true);
		try {
			const variables = {
				platform: parseInt(platform.id),
				locations: [parseInt(location.id)],
				action: "all"
			};
			if (isMultibrandEnabled && brand !== null) {
				variables.brand = brand?.id;
			}
			const resp = await client.query({
				query: GET_LPA_LOGS,
				variables,
				fetchPolicy: "no-cache"
			});
			const brandColorMapping = {};
			const logs =
				resp?.data?.lpaLogs?.map((log) => {
					if (brandColorMapping[log?.brand?.id]) {
						return { ...log, brand: { ...log?.brand, color: brandColorMapping[log?.brand?.id] } };
					} else {
						const color = BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)];
						brandColorMapping[log?.brand?.id] = color;
						return { ...log, brand: { ...log?.brand, color: color } };
					}
				}) || [];
			setLpaLogs(logs);
			if (logs?.length > 0) {
				setVerificationStatusLog(logs?.[0] || {});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [platform, brand, location, isMultibrandEnabled]);

	useEffect(() => {
		fetchLpaLogs();
	}, []);

	const handleOpenUrl = (url) => {
		if (url) {
			window
				.open(
					["http://", "https://"].some((protocol) => url?.includes(protocol)) ? url : `http://${url}`,
					"_blank"
				)
				.focus();
		}
	};

	return (
		<div className="lpa-logs-container">
			<div className="header">
				{location.name}, {PLATFORM_NAME_MAP[platform?.platformName?.toLowerCase()] || platform.platformName}
			</div>
			<div className="logs">
				{lpaLogs.map((log, i) => (
					<div className="log-container" key={i}>
						{log?.brand?.id ? (
							<div className="brand-container">
								<div className={"brand-initials " + log?.brand?.color}>
									{log?.brand?.image ? (
										<img src={log?.brand?.image} alt="" />
									) : (
										extractInitials(log?.brand?.name?.split(" "))
									)}
								</div>
								<div className="brand-info">
									<div className="brand-name">{log?.brand?.name}</div>
									<div className="brand-status">
										<div
											className={
												"platform-status " + STATUS[log?.locationPlatform?.state]?.toLowerCase()
											}
										>
											{STATUS[log?.locationPlatform?.state]}
										</div>
										{log?.locationPlatform?.outletUrl && (
											<div className="ext-link">
												<img
													src="/assets/icons/icon-external-link.svg"
													alt=""
													onClick={() => handleOpenUrl(log.locationPlatform.outletUrl)}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						) : (
							<div className="brand-container">
								<div className={"brand-initials blue"}>
									{biz?.logo ? (
										<img src={biz.logo.replace("http://", "https://")} alt="" />
									) : (
										extractInitials(biz?.name?.split(" "))
									)}
								</div>
								<div className="brand-info">
									<div className="brand-name">{biz?.name}</div>
									<div className="brand-status">
										<div
											className={
												"platform-status " + STATUS[log?.locationPlatform?.state]?.toLowerCase()
											}
										>
											{STATUS[log?.locationPlatform?.state]}
										</div>
										{log?.locationPlatform?.outletUrl && (
											<div className="ext-link">
												<img
													src="/assets/icons/icon-external-link.svg"
													alt=""
													onClick={() => handleOpenUrl(log.locationPlatform.outletUrl)}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						)}
						<div className="action-container">
							<div className="action">
								{STATUS[log.finalStatus] === "Failed" && <img src="/assets/icons/alert.svg" alt="" />}
								<div>
									{ACTIONS[log.action] ?? log?.action}{" "}
									{STATUS[log.finalStatus] === "Failed" || STATUS[log.finalStatus] === "Success"
										? STATUS[log.finalStatus]
										: ""}
								</div>
							</div>
							<div className="date-time">
								On {moment(log.created).format("DD MMM, YYYY")} at{" "}
								{moment(log.created).format("hh:mm A")}
							</div>
						</div>
					</div>
				))}
				{loading && lpaLogs.length === 0 && (
					<div className="P(10px)">
						<div className="shimmer H(40px) Mb(10px)" />
						<div className="shimmer H(40px)" />
					</div>
				)}
				{!loading && lpaLogs.length === 0 && <div className="no-items-placeholder">No logs found</div>}
			</div>
			{isPrismEnabled && !fromBrands ? (
				(verificationStatusLog && verificationStatusLog?.verificationStatus === "FAILED") ||
				verificationStatusLog?.finalStatus === "VALIDATION_FAILED" ? (
					<div className="actions">
						<div
							className="link-text"
							onClick={() =>
								handleCatalogueVerification("error", {
									id: location.id,
									brand: verificationStatusLog?.brand || null,
									name: location.name,
									publish: verificationStatusLog?.created,
									platform: platform
								})
							}
						>
							Fix Errors
						</div>
					</div>
				) : null
			) : !fromBrands ? (
				verificationStatusLog.verificationStatus &&
				verificationStatusLog.verificationStatus !== "passing" &&
				verificationStatusLog?.brand?.id !== "all" ? (
					<div className="actions">
						<div
							className="link-text"
							onClick={() =>
								handleCatalogueVerification(verificationStatusLog.verificationStatus, {
									id: location.id,
									brand: verificationStatusLog?.brand || null,
									name: location.name,
									publish: verificationStatusLog.created,
									platform: platform
								})
							}
						>
							{`Fix ${verificationStatusLog.verificationStatus}s`}
						</div>
					</div>
				) : null
			) : null}
		</div>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail,
	isPrismEnabled: store.login.loggedInbizDetail.modulesEnabled.includes("PRISM"),
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(LpaLogs);
