import React, { Component } from "react";

// components
import { Button } from "../components/_commons/Button";
import CreateCampaignWhat from "../components/CreateCampaign/CreateCampaignWhat";
import CreateCampaignWhen from "../components/CreateCampaign/CreateCampaignWhen";
import CreateCampaignWho from "../components/CreateCampaign/CreateCampaignWho";
import CreateCampaignTrack from "../components/CreateCampaign/CreateCampaignTrack";
import CreateCampaignPreview from "../components/CreateCampaign/CreateCampaignPreview";
import { GlobalConfirmModal, InfiniteTopBar } from "../components/SiteComp";
import { LowCreditsAlert } from "../components/_commons/LowCreditsAlert";
import { BuyCreditsFlow } from "../components/_commons/BuyCreditsFlow";
import { CreditsReloadSuccessful } from "../components/_commons/CreditsReloadSuccessful";

// actions
import { getAndSetBizInfo } from "../actions/updateBizInfo";
import { toggleGlobalLoader } from "../actions/actions";

// graphql
import { CREATE_CAMPAIGN, GET_CAMPAIGN_DETAILS, DELETE_CAMPAIGN } from "../graphql/campaigns";
import { SAVE_SEGMENT } from "../graphql/segments";
import { SAVE_EMAIL_TEMPLATE } from "../graphql/misc";

// client
import { client } from "../client";
import { store } from "../store/configureStore";
import history from "../history";

// utils
import { lS } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// third party
import { Prompt } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";

@connect((store) => {
	return {
		campaignUpdateState: store.campaignUpdateState,
		login: store.login
	};
})
class CreateCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabs: ["What", "Who", "When", "Track", "Preview"],
			selectedTab: "What",
			isFetchingDetails: false,
			lastSaved: new Date().getTime(),
			saving: false,
			showDiscardModal: false,
			modalBusy: false,
			showSaveCampaignModal: false,
			blockNavigation: true
		};
	}

	async componentDidMount() {
		const savedCampaign = lS.get("savedCampaign");
		const campaignId = savedCampaign ? savedCampaign.object.id : null;

		this.setState({
			isFetchingDetails: true
		});

		store.dispatch(toggleGlobalLoader(true));
		if (!campaignId) {
			await this.createCampaign();
		} else {
			await this.fetchCampaignDetails(campaignId);
		}
		store.dispatch(toggleGlobalLoader(false));

		this.setState({
			isFetchingDetails: false
		});
	}

	componentWillUnmount() {
		lS.remove("savedCampaign");
		store.dispatch({
			type: "SEGMENT_RESET"
		});
		store.dispatch({
			type: "EMAIL_TEMPLATE_RESET"
		});
		store.dispatch({
			type: "CREATE_CAMPAIGN_RESET"
		});
	}

	createCampaign = async () => {
		try {
			const resp = await client.mutate({
				mutation: CREATE_CAMPAIGN
			});
			lS.set("savedCampaign", resp.data.saveCampaign);
			const campaignId = resp.data.saveCampaign.object.id;
			const filters = store.getState().createSegment.filters;
			const segmentId = await this.createSegment(campaignId, filters);
			if (!segmentId) {
				throw {
					message: "couldn't create segment"
				};
			}
			const medium = store.getState().createCampaign.medium;

			// if campaign medium is email then create a new email template
			// this can happen when an email campaign is cloned.
			let emailTemplateId;
			if (medium === "EMAIL") {
				emailTemplateId = await this.createEmailTemplate();
				if (!emailTemplateId) {
					throw {
						message: "couldn't create emailTemplate"
					};
				}
			}

			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					id: Number(resp.data.saveCampaign.object.id),
					segmentId: Number(segmentId),
					emailTemplateId: medium === "EMAIL" ? Number(emailTemplateId) : undefined
				}
			});
		} catch (error) {
			alert("Sorry, something went horribly wrong!");
			this.setState(
				{
					blockNavigation: false
				},
				() => history.push("/campaigns/")
			);
		}
	};

	createSegment = async (campaignId, filters) => {
		let segmentId;
		try {
			const resp = await client.mutate({
				mutation: SAVE_SEGMENT,
				variables: {
					title: `segment-campaign-${campaignId}`,
					isImplicit: true,
					isActive: true,
					filters
				}
			});
			segmentId = resp.data.saveSegment.object.id;
			store.dispatch({
				type: "SEGMENT_UPDATE",
				payload: {
					id: segmentId,
					title: resp.data.saveSegment.object.title,
					isImplicit: resp.data.saveSegment.object.isImplicit,
					isActive: resp.data.saveSegment.object.isActive,
					usersPreview: resp.data.saveSegment.object.usersPreview
				}
			});
			return segmentId;
		} catch (error) {
			console.log(error);
			return segmentId;
		}
	};

	createEmailTemplate = async () => {
		let templateId;
		try {
			const emailTemplate = store.getState().emailTemplate;
			const variables = {
				subject: emailTemplate.subject,
				html: emailTemplate.html,
				isEditable: emailTemplate.isEditable
			};
			const resp = await client.mutate({
				mutation: SAVE_EMAIL_TEMPLATE,
				variables
			});
			templateId = resp.data.saveEmailTemplate.object.id;
			store.dispatch({
				type: "EMAIL_TEMPLATE_UPDATE",
				payload: resp.data.saveEmailTemplate.object
			});
			return templateId;
		} catch (error) {
			console.log(error);
			return templateId;
		}
	};

	fetchCampaignDetails = async (id) => {
		try {
			const resp = await client.query({
				query: GET_CAMPAIGN_DETAILS,
				variables: {
					id: Number(id)
				},
				fetchPolicy: "network-only"
			});
			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					id: Number(resp.data.campaign.id),
					name: resp.data.campaign.name,
					description: resp.data.campaign.description,
					medium: resp.data.campaign.medium,
					imageId: resp.data.campaign.imageId,
					imageUrl: resp.data.campaign.imageUrl,
					campaignExecutionType: resp.data.campaign.campaignExecutionType,
					message: resp.data.campaign.message,
					messageType: resp.data.campaign.messageType,
					subject: resp.data.campaign.subject,
					scheduledTime: resp.data.campaign.scheduledTime,
					includeInNotifications: resp.data.campaign.includeInNotifications,
					segmentId: resp.data.campaign.segment ? resp.data.campaign.segment.id : null,
					emailTemplateId: resp.data.campaign.emailTemplate ? resp.data.campaign.emailTemplate.id : null,
					status: resp.data.campaign.status,
					cronExpression: resp.data.campaign.cronExpression,
					conversionConfig: resp.data.campaign.conversionConfig,
					startAt: resp.data.campaign.startAt,
					endAt: resp.data.campaign.endAt
				}
			});
			const segmentPayload = {
				...resp.data.campaign.segment
			};
			if (segmentPayload.id) {
				store.dispatch({
					type: "SEGMENT_UPDATE",
					payload: segmentPayload
				});
			}
			const emailTemplatePayload = {
				...resp.data.campaign.emailTemplate
			};
			if (emailTemplatePayload.id) {
				store.dispatch({
					type: "EMAIL_TEMPLATE_UPDATE",
					payload: emailTemplatePayload
				});
			}
			this.setState({
				isFetchingDetails: false
			});
		} catch (error) {
			// console.log(error);
			// store.dispatch({
			// 	type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			// 	payload: {
			// 		message: error.message || "Failed to fetch details, please refresh.",
			// 		timeout: 5000,
			// 	}
			// })
			alert("Sorry, something went horribly wrong!");
			this.setState({
				blockNavigation: false
			});
			history.push("/campaigns/");
		}
	};

	switchTab = (selectedTab) => {
		this.setState({
			selectedTab
		});
	};

	nextTab = () => {
		const currTab = this.state.tabs.indexOf(this.state.selectedTab);
		let selectedTab = "";
		if (currTab != 4) {
			selectedTab = this.state.tabs[currTab + 1];
			this.setState({
				selectedTab
			});
		} else {
			const variables = store.getState().createCampaign;
			const segment = store.getState().createSegment;
			if (variables.medium === "EMAIL" && !variables.emailTemplateId) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please upload an email template.",
						timeout: 5000,
						error: true
					}
				});
				this.switchTab("What");
				return false;
			} else if (variables.medium !== "EMAIL" && !variables.message) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please enter valid message for campaign",
						timeout: 5000,
						error: true
					}
				});
				this.switchTab("What");
				return false;
			}
			if (variables.conversionConfig.event === "COUPON_USED" && !variables.conversionConfig.value) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please enter a valid coupon code",
						timeout: 5000,
						error: true
					}
				});
				this.switchTab("Track");
				return false;
			}

			let requiredCredits = segment.usersPreview.creditsRequired;
			let availableCredits = this.props.login.loggedInbizDetail.credits;
			if (requiredCredits > availableCredits) {
				store.dispatch({
					type: "CREDITS_STATE_UPDATE",
					payload: {
						showLowCreditsBar: true,
						source: "campaign_creation"
					}
				});
				return false;
			}

			this.setState({
				showSaveCampaignModal: true
			});
		}
	};

	showDiscardModal = () => {
		this.setState({
			showDiscardModal: true
		});
	};
	confirmHandler = () => {
		this.setState({
			modalBusy: true
		});
		client
			.mutate({
				mutation: DELETE_CAMPAIGN,
				variables: {
					id: store.getState().createCampaign.id
				}
			})
			.then((resp) => {
				// track this event
				// PubSub.publish(TRACK_EVENT, {
				// 	tracker: 'mixpanel',
				// 	eventName: 'campaign_delete',
				// 	eventMeta: {
				// 		source: 'campaign_creation',
				// 	},
				// });

				store.dispatch({
					type: "SEGMENT_RESET"
				});
				lS.remove("savedCampaign");
				this.setState({
					blockNavigation: false
				});
				history.push("/campaigns/");
			})
			.catch((error) => {
				this.setState({
					modalBusy: false
				});
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Sorry, could not delete the campaign",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
			});
	};

	cancelHandler = () => {
		this.setState({
			showDiscardModal: false
		});
	};
	handleSaveCampaignModal = () => {
		this.setState({
			showSaveCampaignModal: false
		});
	};

	disableNavigationBlock = (id) => {
		getAndSetBizInfo();
		this.setState(
			{
				blockNavigation: false
			},
			() => {
				history.push(`/campaigns/${id}`);
			}
		);
	};
	render() {
		const { tabs, selectedTab, isFetchingDetails } = this.state;
		return (
			<div>
				<Prompt when={this.state.blockNavigation} message={"Are you sure, you want to leave?"} />
				<GlobalConfirmModal
					backdropHandler={() => {
						return;
					}}
					confirmBtnEnabled={true}
					show={this.state.showDiscardModal}
					confirmHandler={this.confirmHandler}
					cancelHandler={this.cancelHandler}
					modalBusy={this.state.modalBusy}
				>
					<div className="discard-confirm-modal">
						<div className="title">Delete campaign?</div>
						<div className="modal-text">Are you sure that you want to delete the campaign?</div>
					</div>
				</GlobalConfirmModal>
				<LowCreditsAlert />
				<BuyCreditsFlow />
				<CreditsReloadSuccessful />
				<div className="campaign-section section-container-common">
					<CreateCampaignHeader
						campaignUpdateState={this.props.campaignUpdateState}
						nextTab={this.nextTab}
						showDiscardModal={this.showDiscardModal}
						selectedTab={selectedTab}
						tabs={tabs}
					/>
					<CreateCampaignTopbar
						tabs={tabs}
						selectedTab={selectedTab}
						switchTab={this.switchTab}
						isStickyOnTop={true}
					/>
					{selectedTab === tabs[0] && <CreateCampaignWhat isFetchingDetails={isFetchingDetails} />}
					{selectedTab === tabs[1] && (
						<CreateCampaignWho isFetchingDetails={isFetchingDetails} isSegmentWithCampaign={true} />
					)}
					{selectedTab === tabs[2] && <CreateCampaignWhen isFetchingDetails={isFetchingDetails} />}
					{selectedTab === tabs[3] && <CreateCampaignTrack isFetchingDetails={isFetchingDetails} />}
					{selectedTab === tabs[4] && (
						<CreateCampaignPreview
							switchTab={this.switchTab}
							isFetchingDetails={isFetchingDetails}
							showSaveCampaignModal={this.state.showSaveCampaignModal}
							handleSaveCampaignModal={this.handleSaveCampaignModal}
							disableNavigationBlock={this.disableNavigationBlock}
						/>
					)}
					{window.isMobile ? (
						<CreateCampaignFooterForMobile
							campaignUpdateState={this.props.campaignUpdateState}
							nextTab={this.nextTab}
							showDiscardModal={this.showDiscardModal}
							selectedTab={selectedTab}
							tabs={tabs}
						/>
					) : null}
				</div>
			</div>
		);
	}
}
export default CreateCampaign;

const CreateCampaignFooterForMobile = ({ nextTab, campaignUpdateState, selectedTab, tabs, showDiscardModal }) => {
	return (
		<div>
			<div className="header create-campaign-header">
				<div className="filter-in-header create-campaign-next">
					<div className="filter-in-header-btn-holder filter-in-header-btn-holder--mobile">
						<div onClick={showDiscardModal} className="discard">
							Delete
						</div>
						<div>
							<Button clickHandler={nextTab}>{selectedTab == tabs[4] ? "Schedule" : "Next"}</Button>
						</div>
					</div>
					<div className="last-saved-helper-text last-saved-helper-text--mobile">
						{campaignUpdateState.saving ? (
							<span>Saving..</span>
						) : (
							<span>
								Last saved :{" "}
								{campaignUpdateState.lastSaved
									? moment(new Date(campaignUpdateState.lastSaved)).fromNow()
									: "--"}
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
const CreateCampaignHeader = ({ nextTab, campaignUpdateState, selectedTab, tabs, showDiscardModal }) => {
	return (
		<div>
			<div className="header create-campaign-header">
				<div className="header-text">Start your campaign</div>
				<div className="filter-in-header create-campaign-next">
					<div className="filter-in-header-btn-holder">
						<div onClick={showDiscardModal} className="discard">
							Delete
						</div>
						<div>
							<Button clickHandler={nextTab}>{selectedTab == tabs[4] ? "Save and run" : "Next"}</Button>
						</div>
					</div>
					<div className="last-saved-helper-text">
						{campaignUpdateState.saving ? (
							<span>Saving..</span>
						) : (
							<span>
								Last saved :{" "}
								{campaignUpdateState.lastSaved
									? moment(new Date(campaignUpdateState.lastSaved)).fromNow()
									: "--"}
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const CreateCampaignTopbar = ({ tabs, selectedTab, switchTab, isStickyOnTop }) => {
	let index = tabs.indexOf(selectedTab);
	return (
		<InfiniteTopBar
			clickHandler={(e, i) => {
				let toSwitchTab = tabs[i];
				switchTab(toSwitchTab);
			}}
			tabs={tabs}
			selected={index}
			isStickyOnTop={isStickyOnTop}
		/>
	);
};
