import React, { useState, useRef } from "react";
import { connect } from "react-redux";

// components
import { BackNavigation } from "../_commons/BackNavigation";
import BulkOperation from "../_commons/BulkOperation";
import { BulkOperationSuccess } from "../_commons/BulkOperationSuccess";
import { BulkOperationFailure } from "../_commons/BulkOperationFailure";

// clients
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { downloadTemplate, handleBulkExportDownload } from "../../actions/actions";

// utils
import history from "../../history";
import { lS, scroll } from "../../atlas-utils";

// constants
const modifiersData = [
	{
		fieldName: "CRM Title",
		description: "Text to easily identify the modifier",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Description",
		description: "A brief description of the modifier",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Food Type",
		description: "Veg, Non-Veg, Eggetarian etc",
		isRequired: true,
		acceptedValues: [
			"Numeric label",
			"1 - Vegetarian",
			"2 - Non-Vegetarian",
			"3 - Eggetarian",
			"4 - Not Applicable"
		]
	},
	{
		fieldName: "Handle",
		description: "Unique identifier for the modifier. (If left blank it will be auto generated)",
		isRequired: false,
		reqdDesc: "Defaults to -1",
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Is Enabled",
		description:
			"Archive a modifier by marking it as inactive. Restore an archived modifier by marking it as active",
		isRequired: true,
		acceptedValues: ["Positive integer", "0 - Inactive", "1 - Active"]
	},
	{
		fieldName: "Price",
		description: "Sales Price of modifier",
		isRequired: true,
		acceptedValues: "Decimal format"
	},
	{
		fieldName: "Ref Id",
		description:
			"A reference ID for the modifier. This can be an identifier for the modifier in your ERP/PoS system",
		isRequired: false,
		reqdDesc: "Defaults to -1",
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Sort Order",
		description:
			"Control ordering of modifiers as they are displayed to your customers. Values closer to zero will push the modifier to top while higher values will push it towards bottom",
		isRequired: false,
		reqdDesc: "Defaults to 0",
		acceptedValues: "Positive integer"
	},
	{
		fieldName: "Title",
		description: "Name of the modifier",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Weight",
		description: "Weight of item (in gms)",
		isRequired: false,
		acceptedValues: "Decimal format"
	}
];
const olaData = [
	{
		fieldName: "Current Stock",
		description: "Count of modifiers in stock at the location",
		isRequired: false,
		acceptedValues: ["Positive integer", "0 when stocked out"]
	},
	{
		fieldName: "Handle",
		description:
			"Unique identifier for the modifier-location association. (If left blank it will be auto generated)",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Is Enabled",
		description: "Indicates whether the modifier-location association is to be archived",
		isRequired: true,
		acceptedValues: ["Positive integer", "0 - Inactive", "1 - Active"]
	},
	{
		fieldName: "Price",
		description: "Price of modifier at the location",
		isRequired: false,
		acceptedValues: "Decimal format"
	},
	{
		fieldName: "CRM Title (Read Only)",
		description: "CRM title of modifier",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Option Handle",
		description: "Unique identifier for the modifier",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Store Handle",
		description: "Unique identifier for the location",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	}
];

const csfData = [
	{
		fieldName: "Ref ID",
		description:
			"A reference ID for the custom field. This can be an identifier for the item in your ERP/PoS system",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Group",
		description: "Example: nutrition, ingredients etc.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Key",
		description: "Example: proteins, vitamin A, eggs etc.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Value",
		description: "Example: 20gm, 4ml etc.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Item Handle",
		description: "A reference ID for the modifier at the location.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	}
];

const FORM_TABS = [
	{
		label: "Modifiers",
		value: "modifiers"
	},
	{
		label: "Modifier-Location associations",
		value: "ola"
	},
	{
		label: "Modifier custom fields",
		value: "csf"
	}
];

const BulkModifiers = (props) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [downloading, setDownloading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [csvFile, setCsvFile] = useState(undefined);
	const [uploadedFileName, setUploadedFileName] = useState(undefined);
	const [validation, setValidation] = useState(undefined);
	const [bulkOpState, setBulkOpState] = useState("default");
	const [headerFields, setHeaderFields] = useState(undefined);
	const [failureResponse, setFailureResponse] = useState(undefined);
	const [failureData, setFailureData] = useState(undefined);
	const [successData, setSuccessData] = useState(undefined);
	const [downloadAction, setDownloadAction] = useState(false);
	const topRef = useRef();
	const bottomRef = useRef();
	const isMenuOverCatalogueEnabled = props.biz.isMenuOverCatalogueEnabled || false;
	const currLocation = {
		pathname: props.location.pathname,
		title: "Bulk actions on modifiers"
	};
	const prevLocation = {
		pathname: "/modifiers",
		title: "Modifiers"
	};

	const handleBackAction = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
	};

	const setCurrentTab = (tab) => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleUploadNewFile = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");

		// scroll to the bottom
		setTimeout(() => {
			bottomRef.current.scrollIntoView({ behavior: "smooth" });
		}, 100);
	};

	const handleSaveChanges = () => {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Changes saved successfuly",
				timeout: 2000,
				error: false
			}
		});
		setTimeout(() => {
			history.push("/modifiers");
		}, 100);
	};

	const csvToJson = (data) => {
		let sanitisedData = data
			.split("\n")
			.map((row) => row.trim())
			.filter((row) => row !== "")
			.map((row) => row.split(","));
		const headers = sanitisedData[0].map((header) => header.toLowerCase().split(" ").join("_"));

		const fields = {};
		headers.map((header) => {
			fields[header] = true;
		});
		setHeaderFields(fields);

		const tableData = [];
		if (headers.includes("invalid_headers")) {
			let obj = {
				invalid_headers: sanitisedData[1].join(", ")
			};
			tableData.push(obj);
		} else {
			sanitisedData.map((row, i) => {
				if (i !== 0) {
					let obj = {};
					headers.map((column, j) => {
						obj[column] = row[j];
					});
					tableData.push(obj);
				}
			});
		}
		setFailureData(tableData);
	};

	const auth = lS.get("auth");
	const headers = { "Content-Type": "application/json", Authorization: auth && `Bearer ${auth.token}` };

	const exportTemplate = async (emails, entity) => {
		setDownloading(true);
		const modelName =
			formTab === "ola" ? `itemoptionlocationassociation` : formTab === "csf" ? `optionmodelkeyvalue` : `option`;
		await handleBulkExportDownload(modelName, emails, entity);
		setDownloading(false);
	};

	const downloadErrorFile = () => {
		setDownloading(true);
		const file = new Blob([failureResponse], { type: "text/csv" });

		// process to auto download it
		const fileURL = URL.createObjectURL(file);
		const link = document.createElement("a");
		link.href = fileURL;
		link.download =
			formTab === "ola"
				? `Modifier-Location-Associations-Errors.csv`
				: formTab === "csf"
				? "Modifier-Custom-Field-Errors.csv"
				: `Modifiers-Errors.csv`;
		link.click();
		setDownloading(false);
	};

	const handleTemplateDownload = async () => {
		setDownloading(true);
		const modelName =
			formTab === "ola" ? `itemoptionlocationassociation` : formTab === "csf" ? `optionmodelkeyvalue` : `option`;
		await downloadTemplate(modelName);
		setDownloading(false);
	};

	const handleUploadFile = async (e) => {
		setUploading(true);
		setValidation(undefined);

		if (!csvFile) {
			var files = await e.target.files;
			setCsvFile(files[0]);
			setUploadedFileName(files[0].name);
			var formData = new FormData();
			formData.append(
				"model-name",
				formTab === "ola"
					? `itemoptionlocationassociation`
					: formTab === "csf"
					? `optionmodelkeyvalue`
					: `option`
			);
			formData.append("sheet", files[0]);
		} else {
			var formData = new FormData();
			formData.append(
				"model-name",
				formTab === "ola"
					? `itemoptionlocationassociation`
					: formTab === "csf"
					? `optionmodelkeyvalue`
					: `option`
			);
			formData.append("sheet", csvFile);
		}

		try {
			const res = await fetch(process.env.REACT_APP_API_URL_CONFIG_SHEET, {
				method: "POST",
				headers: { Authorization: auth && `Bearer ${auth.token}` },
				body: formData
			});
			const contentType = res.headers.get("content-type");
			const status = res.status;

			if (contentType === "text/csv" && status === 200) {
				const data = await res.text();
				setFailureResponse(data);
				csvToJson(data);
				setDownloadAction(false);
				setBulkOpState("failure");

				// scroll to the top
				scroll({ top: topRef.current.offset - 57, left: 0 });

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please correct the errors and try again",
						timeout: 2000,
						error: true
					}
				});
			} else if (contentType === "application/json") {
				const data = await res.json();
				if (status === 200) {
					const tableData = [{ message: data.message }];
					setSuccessData(tableData);
					setBulkOpState("success");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: data.message,
							timeout: 2000,
							error: false
						}
					});
				} else if (status === 400) {
					const fields = { reason: true };
					setHeaderFields(fields);

					const tableData = [{ reason: data.message }];
					setFailureData(tableData);

					setDownloadAction(true);
					setBulkOpState("failure");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Please correct the errors and try again",
							timeout: 2000,
							error: true
						}
					});
				}
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (err) {
			console.log(err);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
		setUploading(false);
	};

	return (
		<div>
			{bulkOpState === "default" && (
				<React.Fragment>
					<BackNavigation prevLocation={prevLocation} currLocation={currLocation} />
					<div className="section-container-common" ref={topRef}>
						<BulkOperation
							title="Bulk actions on modifiers"
							subTitle="Create or update modifiers and modifier locations associations in bulk"
							downloadTitle="Download Template"
							downloadTemplate={handleTemplateDownload}
							exportTitle={
								formTab === "ola"
									? "Modifier-Location Associations"
									: formTab === "csf"
									? "Modifiers Custom Fields"
									: "Modifiers"
							}
							exportTemplate={exportTemplate}
							data={formTab === "ola" ? olaData : formTab === "csf" ? csfData : modifiersData}
							showTopBar={true}
							tabs={isMenuOverCatalogueEnabled ? FORM_TABS.filter((t) => t.value !== "ola") : FORM_TABS}
							selectedTab={formTab}
							setCurrentTab={setCurrentTab}
							downloading={downloading}
							uploading={uploading}
							handleUploadFile={handleUploadFile}
							uploadedFileName={uploadedFileName}
							validation={validation}
						/>
					</div>
					<div ref={bottomRef}></div>
				</React.Fragment>
			)}
			{bulkOpState === "success" && (
				<React.Fragment>
					<div className="back-navigation-arrow" onClick={handleBackAction}>
						<img src="/assets/icons/ic_left_arrow_blue.svg" />
						<span className="navigation-title">Back</span>
					</div>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationSuccess
							title="Bulk actions on modifiers"
							subTitle="Overview of data import request"
							uploadedFileName={uploadedFileName}
							saveChanges={handleSaveChanges}
							data={successData || []}
						/>
					</div>
				</React.Fragment>
			)}
			{bulkOpState === "failure" && (
				<React.Fragment>
					<div className="back-navigation-arrow" onClick={handleBackAction}>
						<img src="/assets/icons/ic_left_arrow_blue.svg" />
						<span className="navigation-title">Back</span>
					</div>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationFailure
							title="Bulk actions on modifiers"
							subTitle="Overview of data import request"
							downloading={downloading}
							hideDownloadAction={downloadAction}
							uploadedFileName={uploadedFileName}
							downloadErrorFile={downloadErrorFile}
							uploadNewFile={handleUploadNewFile}
							headerFields={headerFields || {}}
							data={failureData || []}
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(BulkModifiers);
