import React from "react";

// components
import { Table } from "./Table";

// third party
import moment from "moment";
import { connect } from "react-redux";

// constants
import { PRESET_TYPES } from "../_commons/DateFilter";
import { fetchOperationalEfficiencyListView } from "../../actions/operationalEfficiency";

const ListView = ({
	operationalEfficiencyState,
	operationalEfficiencyListView,
	configItems,
	bizLocationId,
	sortHandler,
	handleNestedEntity
}) => {
	const { appliedDateFilter } = operationalEfficiencyState;
	let isCustomDateRange = false;
	let isComparing = false;
	const currentDates = appliedDateFilter.current.dateFilter.split(",");
	let currentStart = "";
	let currentEnd = "";
	let presetDateFilterLabel = "";
	if (currentDates.length === 2) {
		isCustomDateRange = true;
		currentStart = moment(currentDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		currentEnd = moment(currentDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	} else {
		presetDateFilterLabel = PRESET_TYPES.find((pt) => pt.value === appliedDateFilter.current.dateFilter).label;
	}
	const { data, loading, error } = operationalEfficiencyListView;
	return (
		<div>
			<Header loading={loading} data={data || {}} />
			<Table
				loading={loading}
				data={data || []}
				uniqueId={"orderId"}
				dimensions={configItems.dimensions}
				bizLocationId={bizLocationId}
				sortHandler={sortHandler}
				handleNestedEntity={handleNestedEntity}
			/>
		</div>
	);
};

export default connect((store, props) => ({
	operationalEfficiencyState: store.operationalEfficiencyState,
	operationalEfficiencyListView: store.operationalEfficiencyListView,
	configItems: store.configItems,
	bizLocationId: props.bizLocationId
}))(ListView);

export const Header = ({ data, loading }) => {
	let orders = data.orders || [];
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Order status updates</div>
				{orders.length !== 0 && !loading && (
					<div className="subtitle">
						List of orders with time-stamps of order status updates at{" "}
						{orders[0].outlet || "" + " - " + data[0].city || ""} location
					</div>
				)}
			</div>
		</div>
	);
};
