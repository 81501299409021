import React from "react";

const ExclamatoryIcon = ({ height = 14, width = 14, fill = "#D64949" }) => {
	return (
		<>
			<svg width={width} height={height} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M13.5 7C13.5 10.5898 10.5898 13.5 7 13.5C3.41025 13.5 0.5 10.5898 0.5 7C0.5 3.41025 3.41025 0.5 7 0.5C10.5898 0.5 13.5 3.41025 13.5 7ZM7.006 11.0895C7.335 11.0895 7.6875 10.707 7.6875 10.2548C7.6875 9.77925 7.335 9.40775 7.006 9.40775C6.5945 9.40775 6.3125 9.779 6.3125 10.2548C6.3125 10.707 6.5945 11.0895 7.006 11.0895ZM7.375 8.4975C7.375 8.4975 7.71875 6.65575 7.75 6.1215C7.7955 5.343 7.75 3.0025 7.75 3.0025H6.25C6.25 3.0025 6.2045 5.343 6.25 6.1215C6.28125 6.65575 6.625 8.4975 6.625 8.4975H7.375Z"
					fill={fill}
				/>
			</svg>
		</>
	);
};

export default React.memo(ExclamatoryIcon);
