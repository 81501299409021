import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SpecificItemIssueHeader from "../../../components/Periscope/ItemIssues/SpecificItemIssueHeader";
import { SelectFilterCustom } from "../../../components/_commons/SelectFilterCustom";
import { connect } from "react-redux";
import { fetchBizPlatforms, fetchStores, fetchStoresDebounced } from "../../../actions/actions";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import { store } from "../../../store/configureStore";
import { getPlatformNames, periscopeFilterEvent } from "../../../helpers/periscope";
import { Filters } from "../../../components/_commons/Filters";
import { SearchFilter } from "../../../components/_commons/SearchFilter";
import {
	fetchDebouncedPersicopeEnabledCities,
	fetchDebouncedSpecificItemIssue,
	fetchMenuCategoriesValues,
	fetchPeriscopeEnabledCities,
	fetchPeriscopeEnabledLocations,
	fetchSpecificItemIssue
} from "../../../actions/periscope";
import SpecificItemIssueTable from "./SpecificItemIssueTable";
import { lS, scroll } from "../../../atlas-utils";
import { ActionTypes } from "../../../actions/_types";
import { PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";

const SpecificItemIssue = ({
	login,
	configItems,
	periscopeSpecificItemIssueState,
	periscopeDataResponse,
	isMultibrandEnabled,
	periscopeFilters
}) => {
	const firstRender = useRef(true);
	const location = useLocation();
	const { itemName, brandName, platformId, brandId } = location.state || {};
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});
	const bizPlatformsLoading = configItems?.bizPlatforms?.isLoading;
	const stores = configItems?.stores?.items;

	const cities = periscopeFilters?.cities;
	const periscopeLocationIds = periscopeFilters?.locations;

	const [filteredLocations, setFilteredLocations] = useState([]);

	useEffect(() => {
		if (!stores || !periscopeLocationIds) return;

		const locations = stores.filter((store) => {
			return periscopeLocationIds.includes(String(store?.id)) || store?.id == "all";
		});
		setFilteredLocations(locations);
	}, [stores, periscopeLocationIds]);

	const currentFiltersCollection = periscopeSpecificItemIssueState?.currentFiltersCollection;
	const appliedFiltersCollection = periscopeSpecificItemIssueState?.appliedFiltersCollection;
	const [selectedCity, setSelectedCity] = useState(
		cities?.[0] || { value: "all", valueForDisplay: "All Cities", __typename: "CityType" }
	);
	const currentFilters = periscopeSpecificItemIssueState?.currentFilters;
	const appliedFilters = periscopeSpecificItemIssueState?.appliedFilters;
	const [optionUpdates, setOptionUpdates] = useState({
		platform_names: {}
	});
	const menuCategoriesFilterValues = periscopeSpecificItemIssueState?.menuCategoriesFilterValues || [];
	const offset = periscopeSpecificItemIssueState?.offset;
	const limit = periscopeSpecificItemIssueState?.limit;
	const [isCheckedAll, setIsCheckedAll] = useState({ platform_names: false });
	const handleCheckAll = (state, field, options, props) => {
		let _currentFilters = { ...currentFilters };
		const _optionUpdates = { ...optionUpdates };
		const _isCheckedAllUpdates = { ...isCheckedAll };
		_isCheckedAllUpdates[field] = state;
		_currentFilters[field] = state ? ["all"] : [];
		_optionUpdates[field] = {};
		setOptionUpdates(_optionUpdates);
		setIsCheckedAll(_isCheckedAllUpdates);
		if (_currentFilters.platform_names && _currentFilters.platform_names.length == 0) {
			_currentFilters.platform_names[0] = "all";
		}
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { currentFilters: _currentFilters }
		});
	};
	const filterCount = useMemo(() => {
		const allKeys = Object.keys(appliedFiltersCollection || {});
		return allKeys.reduce((acc, key) => {
			if (appliedFiltersCollection[key]) {
				acc += 1;
			}
			return acc;
		}, 0);
	}, [appliedFiltersCollection]);

	const handleCheck = (state, field, option, prop) => {
		let _currentFilters = { ...currentFilters };

		const _optionUpdates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[option.id]: state
			}
		};

		let lowerCasePlatformName = option.platformName.toLowerCase();
		setOptionUpdates(_optionUpdates);
		const _isCheckedAllUpdates = { ...isCheckedAll };
		_isCheckedAllUpdates[field] = false;
		setIsCheckedAll(_isCheckedAllUpdates);

		if (state) {
			_currentFilters[field] = [String(lowerCasePlatformName), ..._currentFilters[field]];
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== "all");
			_currentFilters[field] = [...new Set(_currentFilters[field])];
		} else {
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== String(lowerCasePlatformName));

			if (_currentFilters[field].length === 0) {
				handleCheckAll(true, field);
				return;
			}
		}

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { currentFilters: _currentFilters }
		});
	};

	const handleSelectFilterState = (isOpen = false, field = null) => {
		if (!isOpen) {
			const currentPlatforms = currentFilters?.platform_names || [];
			const appliedPlatforms = appliedFilters?.platform_names || [];
			let currentFiltersChanged = false;
			if (currentPlatforms?.length !== appliedPlatforms?.length) {
				currentFiltersChanged = true;
			} else {
				if (currentPlatforms) {
					currentPlatforms.forEach((platform, index) => {
						if (platform != appliedPlatforms[index]) {
							currentFiltersChanged = true;
							return;
						}
					});
				}
			}

			if (currentFiltersChanged) {
				store.dispatch({
					type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
					payload: { appliedFilters: { ...appliedFilters, ...currentFilters } }
				});
				store.dispatch({
					type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
					payload: { offset: 0 }
				});
			}
		}
	};

	const handleCityFilter = (val) => {
		periscopeFilterEvent("city");
		setSelectedCity(val);
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { offset: 0 }
		});
	};

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		}
		fetchBizPlatforms(false, false, true);
		fetchStores("", 50, null, true);
		fetchMenuCategoriesValues({ bizId: String(login?.loggedInbizDetail?.id) }, "specificItemIssues");

		fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true);
		fetchPeriscopeEnabledLocations(String(login?.loggedInbizDetail?.id));

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 157, left: 0 });
		}
	}, []);
	const previousFilterLocationRef = useRef(null);
	useEffect(() => {
		const locationValue = periscopeSpecificItemIssueState?.appliedFiltersCollection?.location?.id;
		if (
			!firstRender.current &&
			previousFilterLocationRef.current !== null &&
			previousFilterLocationRef.current !== locationValue
		) {
			periscopeFilterEvent("location");
		}
		previousFilterLocationRef.current = locationValue;
	}, [periscopeSpecificItemIssueState?.appliedFiltersCollection?.location?.id]);

	const previousFilterPlatformRef = useRef(null);
	useEffect(() => {
		const platformValue = periscopeSpecificItemIssueState?.appliedFilters?.platform_names.join(",");
		if (
			!firstRender.current &&
			previousFilterPlatformRef.current !== null &&
			previousFilterPlatformRef.current !== platformValue
		) {
			periscopeFilterEvent("platform");
		}
		previousFilterPlatformRef.current = platformValue;
	}, [periscopeSpecificItemIssueState?.appliedFilters?.platform_names.join(",")]);

	const [sortBy, setSortBy] = useState({ value: "most_offline", valueForDisplay: "Most Offline" });
	const handleSortby = (val) => {
		setSortBy(val);
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { offset: 0 }
		});
	};

	const [initialCall, setInitialCall] = useState(true);
	useEffect(() => {
		if (initialCall) {
			let data;
			if (platformId) {
				data = { [platformId]: true };
				setIsCheckedAll({
					platform_names: false
				});
				setInitialCall(false);
				setOptionUpdates({
					platform_names: data
				});
			}
		}
	}, [currentFilters?.platform_names, bizPlatforms]);

	const sortByDict = {
		most_offline: "Most Offline",
		"A-Z": "A-Z",
		"Z-A": "Z-A",
		lowest_rated: "Lowest Rated",
		highest_rated: "Highest Rated"
	};

	const [showFilters, setShowFilters] = useState(false);

	const filterSidebarCloseHandler = () => {
		setShowFilters(false);
		let currentFiltersCollection = { ...appliedFiltersCollection };
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { currentFiltersCollection }
		});
	};

	const applyFilters = () => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { offset: 0 }
		});
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_STATE_FILTERS_CHANGE,
			payload: { ...currentFiltersCollection }
		});
		setShowFilters(false);
	};

	const clearFilters = () => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { offset: 0, limit: 10 }
		});

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS_INITIAL_STATE
		});
		setShowFilters(false);
	};

	const setFilterr = (field, value) => {
		let currentFiltersCollection = {
			...periscopeSpecificItemIssueState.currentFiltersCollection,
			[field]: value
		};
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { currentFiltersCollection }
		});
	};

	const text = periscopeSpecificItemIssueState?.text;
	const [searchQuery, setSearchQuery] = useState("");
	const handleSearch = (field, value) => {
		setSearchQuery(value);
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
			payload: { search: value, offset: 0 }
		});
		textDebouncedFetchData(value);
	};

	const menuCategories = useMemo(() => {
		return (menuCategoriesFilterValues || []).map((menu) => ({
			id: menu,
			name: menu
		}));
	}, [menuCategoriesFilterValues]);

	const issueTypeOptions = [
		{ valueForDisplay: "All", value: "all" },
		{ valueForDisplay: "Missing Image", value: "missing_image" },
		{ valueForDisplay: "Missing Description", value: "missing_description" },
		{ valueForDisplay: "Missing Image and Description", value: "missing_image_and_description" }
	];

	const handleStoresDebounced = (searchText) => {
		let includeAll = true;
		if (searchText) {
			includeAll = false;
		}
		fetchStoresDebounced(searchText, 50, null, includeAll);
	};
	const filterOptions = [
		{
			field: "location",
			valueForDisplay: "Location",
			values: filteredLocations,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT",
			isAsync: true,
			handleAsyncSearch: handleStoresDebounced,
			isLoading: configItems?.stores?.isLoading
		},
		{
			hide: null,
			field: "issueType",
			valueForDisplay: "Issue type",
			type: "SINGLE_STRICT",
			values: issueTypeOptions
		},
		{
			field: "menuCategories",
			valueForDisplay: " Menu Category",
			values: menuCategories,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT"
		}
	];

	const appliedPlatformNames = appliedFilters?.platform_names;
	const defaultPlatforms = bizPlatforms && bizPlatforms.length > 0 ? getPlatformNames(bizPlatforms, true) : [];
	const sort = periscopeSpecificItemIssueState?.sort;
	const categoryValue = appliedFiltersCollection?.menuCategories?.id;
	const issueTypeValue = appliedFiltersCollection?.issueType?.value;
	const buildVariables = () => {
		let locationValue = String(appliedFiltersCollection?.location?.id || "all");
		let platformsNames =
			(appliedPlatformNames && appliedPlatformNames?.[0] === "all") ||
			(appliedPlatformNames && appliedPlatformNames?.length === 0)
				? defaultPlatforms
				: appliedPlatformNames;
		return {
			bizId: String(login?.loggedInbizDetail?.id),
			brand: isMultibrandEnabled ? String(brandId || "all") : null,
			location: locationValue,
			city: String(selectedCity?.value || "all"),
			itemName: itemName,
			platforms: platformsNames,
			category: categoryValue || "",
			issueType: issueTypeValue || "all",
			limit: limit,
			offset: offset,
			search: searchQuery,
			sortBy: sortBy.value,
			sort: sort
		};
	};

	const fetchData = (periscopeDataFetch = false) => {
		if (defaultPlatforms.length > 0 || (appliedPlatformNames && appliedPlatformNames.length > 0)) {
			const variables = buildVariables();
			if (variables?.platforms && variables?.platforms.length > 0) {
				fetchSpecificItemIssue(variables, periscopeDataFetch);
			}
		}
	};

	const textDebouncedFetchData = (textValue) => {
		let variables = buildVariables();
		variables = { ...variables, search: textValue, offset: 0 };
		fetchDebouncedSpecificItemIssue(variables);
	};

	useEffect(() => {
		fetchData();
	}, [
		appliedPlatformNames,
		appliedFiltersCollection?.location?.id,
		itemName,
		defaultPlatforms.join(),
		limit,
		offset,
		sortBy.value,
		sort,
		selectedCity?.value,
		appliedFiltersCollection?.sortby,
		issueTypeValue,
		categoryValue
	]);
	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;

	useEffect(() => {
		if (periscopeDataFetch) {
			fetchData(periscopeDataFetch);
		}
	}, [periscopeDataFetch]);

	const tableRef = useRef(null);

	const handleCitySearch = (text) => {
		let includeAll = true;
		if (text) {
			includeAll = false;
		}
		fetchDebouncedPersicopeEnabledCities(String(login?.loggedInbizDetail?.id), includeAll, text);
	};

	const handleFilterState = (isOpen, field) => {
		let filters = {
			cities: () => fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true)
		};

		if (isOpen) {
			const filterAction = filters[field];
			filterAction();
		}
	};

	return (
		<div className="periscope-container">
			<div
				className="specific-item-issue section-container-common-periscope"
				ref={tableRef}
				data-testid="specific-item-issue"
			>
				<SpecificItemIssueHeader itemName={itemName} brandName={brandName} />
				<div className="specific-issues-filters-div">
					<div className="specific-item-issues-left-filters">
						<div className="specificitemissue-platform-filter" style={{ width: "220px" }}>
							<SelectFilterCustom
								options={bizPlatforms?.filter((plf) => plf.id !== "all") || []}
								field={"platform_names"}
								labelKey={"platformName"}
								valueKey={"id"}
								iconKey={"image"}
								multi={true}
								showCheckBox={true}
								requiredLabel={true}
								showSelectAllOption
								showIcon={true}
								ellipsizedLength={40}
								isLoading={bizPlatformsLoading}
								optionUpdates={optionUpdates}
								handleCheck={handleCheck}
								isCheckedAll={isCheckedAll}
								handleCheckAll={handleCheckAll}
								placeholder={"Select platforms"}
								selectAllPlaceholder="All Platforms"
								countDisplayText={"platform"}
								handleSelectFilterState={handleSelectFilterState}
							/>
						</div>
						<div className="city-filter" style={{ width: "200px" }}>
							<SelectFilterCustom
								options={cities}
								field="cities"
								currValue={selectedCity || cities[0]}
								setFilter={(f, value) => {
									handleCityFilter(value);
								}}
								labelKey={"valueForDisplay"}
								valueKey={"value"}
								isSearchable={true}
								placeholder="All Cities"
								handleSearch={handleCitySearch}
								handleSelectFilterState={handleFilterState}
							/>
						</div>
						<div className="sortby" style={{ width: "180px" }}>
							<SelectFilter
								options={[
									{ value: "most_offline", valueForDisplay: "Most Offline" },
									{ value: "A-Z", valueForDisplay: "A-Z" },
									{ value: "Z-A", valueForDisplay: "Z-A" },
									{ value: "lowest_rated", valueForDisplay: "Lowest Rated" },
									{ value: "highest_rated", valueForDisplay: "Highest Rated" }
								]}
								field="sortBy"
								currValue={{
									value: sortBy,
									valueForDisplay: (
										<span className="sort-dropdown">
											<span className="status-heading">Sort by</span>
											<span className="sortby-status">{sortByDict[sortBy.value]}</span>
										</span>
									)
								}}
								labelKey="valueForDisplay"
								valueKey="value"
								setFilter={(f, v) => handleSortby(v)}
								isSearchable={false}
								isClearable={false}
							/>
						</div>
						<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={() => setShowFilters((prev) => !prev)}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div data-testid="filter-btn" className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
						{configItems?.dimensions?.width > 768 && (
							<Filters
								isOpen={showFilters}
								close={filterSidebarCloseHandler}
								options={filterOptions}
								apply={applyFilters}
								clear={clearFilters}
								currentFilters={currentFiltersCollection}
								setFilter={(f, value) => setFilterr(f, value)}
							/>
						)}
					</div>
					<div>
						<SearchFilter
							placeholder="Search"
							filterOption={{
								field: "name"
							}}
							setFilter={handleSearch}
							value={searchQuery}
						/>
					</div>
				</div>

				<SpecificItemIssueTable fetchData={fetchData} defaultPlatforms={defaultPlatforms} tableRef={tableRef} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	periscopeSpecificItemIssueState: state.periscopeSpecificItemIssueState,
	periscopeSpecificItemIssueData: state.periscopeSpecificItemIssueData,
	periscopeDataResponse: state.periscopeDataResponse,
	isMultibrandEnabled: state.login?.loggedInbizDetail?.isMultibrandEnabled,
	periscopeFilters: state.periscopeFilters
});

export default connect(mapStateToProps)(SpecificItemIssue);
