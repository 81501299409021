// action types
import moment from "moment";
import { ActionTypes } from "../actions/_types";

// utils
import { getSortOrder } from "../atlas-utils";

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "title",
		order: "ASC"
	},
	sortedField: "title",
	currentFilters: {},
	appliedFilters: {}
};
export const timingGroupsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.TIMING_GROUPS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.TIMING_GROUPS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.TIMING_GROUPS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const TIMING_GROUPS_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: []
	},
	error: false
};
function getMergedSlotArrForCustom(arr) {
	let mergedSlots = [];

	arr.forEach((item) => {
		item.slots.forEach((slot) => {
			if (!mergedSlots.includes(slot)) {
				mergedSlots.push(slot);
			}
		});
	});

	return mergedSlots;
}
function getMergedCustomSelectedDaysString(arr) {
	const threeLetterNotationDayMap = {
		MONDAY: "Mon",
		TUESDAY: "Tue",
		WEDNESDAY: "Wed",
		THURSDAY: "Thu",
		FRIDAY: "Fri",
		SATURDAY: "Sat",
		SUNDAY: "Sun"
	};

	let mergedDays = {};
	arr.forEach((item) => {
		Object.keys(item.selectDays).forEach((day) => {
			mergedDays[day] = item.selectDays[day];
		});
	});
	const sortedSelectDays = sortSelectDays(mergedDays);
	const selectedDays = Object.keys(sortedSelectDays)
		.filter((day) => mergedDays[day])
		.map((day) => threeLetterNotationDayMap[day]);

	return selectedDays?.length > 0 ? selectedDays.join(", ") : "Mon, Tue, Wed, Thu, Fri, Sat, Sun";
}

const getModifiedTimeSlots = (obj, startEndTimeNotaion) => {
	const timeSlots = [];
	(obj && obj.timeSlots ? obj.timeSlots : []).forEach((slot) => {
		let index = undefined;
		let timeSlot = undefined;
		timeSlots.forEach((obj, i) => {
			if (obj.startTime === slot.starttime && obj.endTime === slot.endtime) {
				index = i;
				timeSlot = obj;
			}
		});
		if (timeSlot) {
			timeSlots[index].selectDays[slot.day] = true;
			if (
				timeSlots[index] &&
				Object.values(timeSlots[index].selectDays).filter((val) => val === true).length === 7
			) {
				Object.keys(timeSlots[index].selectDays).forEach((day) => {
					timeSlots[index].selectDays[day] = false;
				});
				timeSlots[index].scheduleType = {
					label: "Daily",
					value: "Daily"
				};
			}
		} else {
			const newSlot = {
				startTime: slot.starttime,
				endTime: slot.endtime,
				scheduleType: { label: null, value: null },
				slots: startEndTimeNotaion
					? [`${slot.starttime} - ${slot.endtime}`]
					: [
							{
								startHours: slot.startHours,
								startMinutes: slot.startMinutes,
								endHours: slot.endHours,
								endMinutes: slot.endMinutes
							}
					  ],
				selectDays: {
					[slot.day]: true
				}
			};
			timeSlots.push(newSlot);
		}
	});
	const mergedTimeSlots = mergeTimeSlots(timeSlots);
	const modifiedTimeslots = addScheduleTypeToTimeslots(mergedTimeSlots);
	return modifiedTimeslots;
};

const modifiedObjectsArr = (objects = []) => {
	const arr = objects.map((obj) => {
		const modifiedTimeslots = getModifiedTimeSlots(obj, true);
		const isCustom = modifiedTimeslots.some((entry) => entry?.scheduleType?.label === "Custom");

		// spl edge case where user utilized 2 custom slots selecting weekdays in one and weekends in another
		const containesWeekdayAndWeekend =
			(modifiedTimeslots?.[0]?.scheduleType?.value === "Weekends" &&
				modifiedTimeslots?.[1]?.scheduleType?.value === "Weekdays") ||
			(modifiedTimeslots?.[1]?.scheduleType?.value === "Weekends" &&
				modifiedTimeslots?.[0]?.scheduleType?.value === "Weekdays");

		const scheduleType = isCustom
			? getMergedCustomSelectedDaysString(modifiedTimeslots)
			: containesWeekdayAndWeekend
			? "Mon, Tue, Wed, Thu, Fri, Sat, Sun"
			: modifiedTimeslots?.[0]?.scheduleType?.label;
		const slots =
			isCustom || containesWeekdayAndWeekend
				? getMergedSlotArrForCustom(modifiedTimeslots)
				: modifiedTimeslots?.[0]?.slots;
		return {
			...obj,
			scheduleType: scheduleType,
			slots: slots
		};
	});
	return arr;
};

export const timingGroupsList = (state = TIMING_GROUPS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_TIMING_GROUPS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_TIMING_GROUPS_LIST_SUCCESS:
			return {
				...state,
				data: {
					...action.payload,
					objects: modifiedObjectsArr(action?.payload?.objects)
				},
				loading: false
			};
		case ActionTypes.GET_TIMING_GROUPS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_TIMING_GROUPS_LIST:
			let foundInList = false;
			const updatedObjects = state.data.objects.map((obj) => {
				if (obj.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return obj;
			});
			if (!foundInList) {
				updatedObjects.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedObjects,
					count: state.data.count + 1
				}
			};
		default:
			return state;
	}
};

const getStructuredHolidayHourObjects = (objs = []) => {
	return objs?.map((obj) => {
		return {
			...obj,
			slots: obj?.timeSlots?.map((ts) => `${ts.starttime} - ${ts.endtime}`)
		};
	});
};

export const holidayScheduleList = (state = TIMING_GROUPS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_HOLIDAY_SCHEDULE_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_HOLIDAY_SCHEDULE_LIST_SUCCESS:
			return {
				...state,
				data: {
					...action.payload,
					objects: getStructuredHolidayHourObjects(action?.payload?.objects)
				},
				loading: false
			};
		case ActionTypes.GET_HOLIDAY_SCHEDULE_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_HOLIDAY_SCHEDULE_LIST:
			let foundInList = false;
			const updatedObjects = state.data.objects.map((obj) => {
				if (obj.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return obj;
			});
			if (!foundInList) {
				updatedObjects.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedObjects,
					count: state.data.count + 1
				}
			};
		default:
			return state;
	}
};

const TIMING_GROUP_DETAILS_INITIAL_STATE = {
	loading: false,
	data: {},
	timeSlots: [],
	error: {}
};

const DAYMAP = {
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 4,
	THURSDAY: 8,
	FRIDAY: 16,
	SATURDAY: 32,
	SUNDAY: 64
};
const sortSelectDays = (selectDays) => {
	const sortedKeys = Object.keys(selectDays).sort((day1, day2) => {
		const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
		return daysOfWeek.indexOf(day1) - daysOfWeek.indexOf(day2);
	});
	const sortedSelectDays = {};
	sortedKeys.forEach((key) => {
		sortedSelectDays[key] = selectDays[key];
	});
	return sortedSelectDays;
};
export const mergeTimeSlots = (timeSlots) => {
	const mergedTimeSlots = {};
	timeSlots.forEach((slot) => {
		const { startTime, endTime, scheduleType, slots, selectDays } = slot;
		const sortedSelectDays = sortSelectDays(selectDays);
		const key = JSON.stringify({ sortedSelectDays, scheduleType });
		if (!mergedTimeSlots[key]) {
			mergedTimeSlots[key] = {
				startTime,
				endTime,
				scheduleType,
				slots: [],
				selectDays
			};
		}
		mergedTimeSlots[key].slots.push(...slots);
	});
	return Object.values(mergedTimeSlots);
};

export const addScheduleTypeToTimeslots = (timeSlots) => {
	let modifiedTimeslots = timeSlots || [];
	modifiedTimeslots.forEach((timeslot) => {
		if (timeslot?.selectDays && !timeslot?.scheduleType?.label) {
			const selectedDaysArray = Object.entries(timeslot?.selectDays)
				.filter(([day, isSelected]) => isSelected === true)
				.map(([day]) => day);
			const sum = selectedDaysArray.reduce((acc, sd) => acc + DAYMAP[sd], 0);
			if (sum === 96) {
				timeslot.scheduleType = {
					label: "Weekends (Sat - Sun)",
					value: "Weekends"
				};
			} else if (sum === 31) {
				timeslot.scheduleType = {
					label: "Weekdays (Mon - Fri)",
					value: "Weekdays"
				};
			} else {
				timeslot.scheduleType = {
					label: "Custom",
					value: "Custom"
				};
			}
		}
		return timeslot;
	});
	return modifiedTimeslots;
};
export const timingGroupDetails = (state = TIMING_GROUP_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_TIMING_GROUPS_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_TIMING_GROUPS_DETAIL_SUCCESS:
			const modifiedTimeslots = getModifiedTimeSlots(action.payload);
			return {
				...state,
				loading: false,
				data: action.payload,
				timeSlots: modifiedTimeslots
			};
		case ActionTypes.GET_TIMING_GROUPS_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.RESET_TIMING_GROUPS_DETAIL:
			return TIMING_GROUP_DETAILS_INITIAL_STATE;
		case ActionTypes.UPDATE_TIMING_GROUPS_DETAIL:
			if ("addNewTimeSlot" in action.payload) {
				return {
					...state,
					timeSlots: [...state.timeSlots, action.payload.addNewTimeSlot]
				};
			} else if ("timeSlots" in action.payload) {
				return {
					...state,
					...action.payload
				};
			} else {
				return {
					...state,
					data: {
						...state.data,
						...action.payload
					}
				};
			}
		case ActionTypes.EDIT_TIMING_GROUPS_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_TIMING_GROUPS_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_TIMING_GROUPS_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

const getStructuredTimeslotsForHolidaySchedule = (timeslots = []) => {
	let structuredSlots = [];
	if (timeslots?.length > 0) {
		timeslots.map((slot) => {
			structuredSlots.push({
				startHours: slot?.startHours,
				startMinutes: slot?.startMinutes,
				endHours: slot?.endHours,
				endMinutes: slot?.endMinutes
			});
		});
	}
	return structuredSlots;
};

export const holidayScheduleDetails = (state = TIMING_GROUP_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_HOLIDAY_SCHEDULE_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_HOLIDAY_SCHEDULE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...action.payload,
					date: moment(action?.payload?.date),
					slots: getStructuredTimeslotsForHolidaySchedule(action.payload?.timeSlots)
				}
			};
		case ActionTypes.GET_HOLIDAY_SCHEDULE_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.RESET_HOLIDAY_SCHEDULE_DETAIL:
			return TIMING_GROUP_DETAILS_INITIAL_STATE;
		case ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};

		case ActionTypes.EDIT_HOLIDAY_SCHEDULE_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_HOLIDAY_SCHEDULE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_HOLIDAY_SCHEDULE_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
