import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CATALOGUE_PLATFORMS_LOGO, PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";
import { extractInitials, lS } from "../../../atlas-utils";
import { SelectFilterCustom } from "../../../components/_commons/SelectFilterCustom";
import { fetchBizPlatforms, fetchBrands, fetchStores, fetchStoresDebounced } from "../../../actions/actions";
import {
	fetchDashboardMetrics,
	fetchDebouncedPersicopeEnabledCities,
	fetchItemMetrics,
	fetchPeriscopeEnabledBrands,
	fetchPeriscopeEnabledCities,
	fetchPeriscopeEnabledLocations,
	fetchStoreMetrics
} from "../../../actions/periscope";
import { periscopeFilterEvent } from "../../../helpers/periscope";

const HomeList = ({ configItems, login, isMultibrandEnabled, periscopeDataResponse, periscopeFilters }) => {
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;

	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});
	const bizPlatformsLoading = configItems?.bizPlatforms?.isLoading;
	const brands = configItems?.brands?.items;
	const stores = configItems?.stores?.items;

	const cities = periscopeFilters?.cities;
	const periscopeBrandIds = periscopeFilters?.brands;
	const periscopeLocationIds = periscopeFilters?.locations;

	const [filteredBrands, setFilteredBrands] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);

	useEffect(() => {
		if (!isMultibrandEnabled || !brands || !periscopeBrandIds) return;

		const filteredBrands = brands.filter((brand) => {
			return periscopeBrandIds.includes(String(brand?.id)) || brand?.id === "all";
		});
		setFilteredBrands(filteredBrands);
	}, [isMultibrandEnabled, brands, periscopeBrandIds]);

	useEffect(() => {
		if (!stores || !periscopeLocationIds) return;

		const locations = stores.filter((store) => {
			return periscopeLocationIds.includes(String(store?.id)) || store?.id === "all";
		});
		setFilteredLocations(locations);
	}, [stores, periscopeLocationIds]);

	const [selectedPlatform, setSelectedPlatform] = useState(
		lS.get("platform") ? Object.values(lS.get("platform"))[0] : { id: "all", platformName: "All Platforms" }
	);

	const [selectedBrand, setSelectedBrand] = useState(
		isMultibrandEnabled
			? lS.get("brand") && lS.get("brand")?.id !== "all"
				? lS.get("brand")
				: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }
			: null
	);

	const [selectedCity, setSelectedCity] = useState({
		value: "all",
		valueForDisplay: "All Cities",
		__typename: "CityType"
	});
	const [locationSelected, setLocationSelected] = useState({ id: "all", name: "All Locations" });

	const handlePlatformsLabelOption = (plf) => {
		return (
			<React.Fragment>
				<div className="logo">
					<img
						src={
							CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
							"/assets/icons/icons8-globe-40.png"
						}
						alt=""
					/>
				</div>
				<div title={plf.platformName}>
					{plf.platformName && plf.platformName.length > 25
						? plf.platformName.slice(0, 25) + "..."
						: plf.platformName}
				</div>
			</React.Fragment>
		);
	};

	const handleFiltersLabelOption = (filter) => {
		return (
			<React.Fragment>
				<div className={"logo " + filter.color}>
					{filter.image ? <img src={filter.image} alt="" /> : extractInitials(filter?.name?.split(" "))}
				</div>
				<div title={filter.name}>
					{filter.name && filter.name.length > 18 ? filter.name.slice(0, 18) + "..." : filter.name}
				</div>
			</React.Fragment>
		);
	};

	const handlePlatform = (value) => {
		periscopeFilterEvent("platform");
		setSelectedPlatform(value);
		lS.set("platform", {
			...(lS.get("platform") ?? {}),
			[login.loggedInbizDetail.id]: {
				id: value.id,
				platformName: value.platformName
			}
		});
	};

	const handleBrand = (brand) => {
		periscopeFilterEvent("brand");
		setSelectedBrand(brand);
		lS.set("brand", brand);
		handleReset();
	};

	const handleLocation = (value) => {
		periscopeFilterEvent("location");
		setLocationSelected(value);
	};

	const handleCity = (city) => {
		periscopeFilterEvent("city");
		setSelectedCity(city);
	};

	useEffect(() => {
		fetchBizPlatforms(false, false, true);
		fetchBrands("", true);
		fetchStores("", 50, null, true);
		fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true);
		fetchPeriscopeEnabledBrands(String(login?.loggedInbizDetail?.id));
		fetchPeriscopeEnabledLocations(String(login?.loggedInbizDetail?.id));
	}, []);

	const fetchDashboardData = async (variables, periscopeDataFetch = false) => {
		fetchDashboardMetrics(variables, periscopeDataFetch);
		fetchItemMetrics(variables, periscopeDataFetch);
		fetchStoreMetrics(variables, periscopeDataFetch);
	};
	const getVariables = () => {
		const platformNames = [
			selectedPlatform?.id === "all" ? selectedPlatform.id : selectedPlatform.platformName?.toLowerCase()
		];
		return {
			bizId: String(login?.loggedInbizDetail?.id),
			brand: isMultibrandEnabled ? String(selectedBrand?.id || "all") : null,
			location: String(locationSelected?.id),
			city: String(selectedCity?.value),
			platform: String(selectedPlatform?.id),
			platforms: platformNames
		};
	};
	useEffect(() => {
		let variables = { ...getVariables() };
		fetchDashboardData(variables);
	}, [selectedBrand?.id, selectedCity?.value, selectedPlatform?.id, locationSelected?.id]);

	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;
	useEffect(() => {
		if (periscopeDataFetch) {
			let variables = { ...getVariables() };
			fetchDashboardData(variables, periscopeDataFetch);
			fetchItemMetrics(variables, periscopeDataFetch);
			fetchStoreMetrics(variables, periscopeDataFetch);
		}
	}, [periscopeDataFetch]);

	const handleStoreSearch = (text) => {
		let includeAll = true;
		if (text) {
			includeAll = false;
		}
		fetchStoresDebounced(text, 50, null, includeAll);
	};

	const handleCitySearch = (text) => {
		let includeAll = true;
		if (text) {
			includeAll = false;
		}
		fetchDebouncedPersicopeEnabledCities(String(login?.loggedInbizDetail?.id), includeAll, text);
	};

	const handleReset = () => {
		handleCity({
			value: "all",
			valueForDisplay: "All Cities",
			__typename: "CityType"
		});
		handlePlatform({ id: "all", platformName: "All Platforms" });
		handleLocation({ id: "all", name: "All Locations" });
	};

	const handleFilterState = (isOpen, field) => {
		let filters = {
			locations: () => fetchStores("", 50, null, true),
			cities: () => fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true)
		};
		if (isOpen) {
			const filterAction = filters[field];
			filterAction();
		}
	};

	return (
		<>
			<div className={"filter-div brand-state-filters"}>
				{isMultibrandEnabled && (
					<SelectFilterCustom
						options={filteredBrands}
						field="brands"
						currValue={selectedBrand}
						setFilter={(f, value) => handleBrand(value)}
						labelKey={"name"}
						valueKey={"id"}
						isSearchable={true}
						customLabel={true}
						customOptions={true}
						renderLabel={handleFiltersLabelOption}
						renderOptions={handleFiltersLabelOption}
						placeholder="Select brand"
					/>
				)}

				<SelectFilterCustom
					options={filteredLocations}
					field="locations"
					isLoading={configItems?.stores?.isLoading}
					currValue={locationSelected || filteredLocations[0]}
					setFilter={(f, value) => handleLocation(value)}
					labelKey={"name"}
					valueKey={"id"}
					isSearchable={true}
					renderOptions={handleFiltersLabelOption}
					placeholder="All Locations"
					handleSearch={handleStoreSearch}
					handleSelectFilterState={handleFilterState}
				/>

				<SelectFilterCustom
					options={cities}
					field="cities"
					currValue={selectedCity || cities[0]}
					setFilter={(f, value) => {
						handleCity(value);
					}}
					labelKey={"valueForDisplay"}
					valueKey={"value"}
					isSearchable={true}
					placeholder="All Cities"
					handleSearch={handleCitySearch}
					handleSelectFilterState={handleFilterState}
				/>

				<SelectFilterCustom
					options={bizPlatforms}
					isLoading={bizPlatformsLoading}
					field="platforms"
					currValue={selectedPlatform || null}
					setFilter={(f, value) => handlePlatform(value)}
					labelKey={"platformName"}
					valueKey={"id"}
					isSearchable={false}
					customLabel={true}
					customOptions={true}
					renderLabel={handlePlatformsLabelOption}
					renderOptions={handlePlatformsLabelOption}
					placeholder="Select platform"
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		configItems: state.configItems,
		login: state.login,
		isMultibrandEnabled: state.login?.loggedInbizDetail?.isMultibrandEnabled,
		periscopeDataResponse: state.periscopeDataResponse,
		periscopeFilters: state.periscopeFilters
	};
};

export default connect(mapStateToProps)(HomeList);
