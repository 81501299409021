import React, { useEffect } from "react";

const HelpContainer = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://s3.amazonaws.com/assets.helpjuice.com/helpjuice.min.js";
		document.body.appendChild(script);
	}, []);
	return (
		<div className="help-section section-container-common">
			<div id="helpjuice_js_widget_content" className="helpjuice-container">
				<img src="https://helpjuice.com/images/cube-theme/loading.gif" className="helpjuice-loading-spinner" />
			</div>
		</div>
	);
};
export default HelpContainer;
