import React, { useCallback, useEffect, useState } from "react";

// components
import DropdownCustom from "./DropdownCustom";
import { SelectFilter } from "./SelectFilter";

// third party
import DatePicker from "react-datepicker";
import moment from "moment";

// constants
import { TWELVE_HOUR_MAPPING } from "../../client-config";
import { CUSTOM_TYPES } from "./NewDateCompareFilter";

export const DateFilterDropdown = ({
	title,
	field,
	setFilter,
	setTimeRange,
	currValue,
	applValue,
	requiredLabel = false,
	validationMessage = "",
	presetType = {},
	dateFormat = "DD MMM, YYYY",
	dateFormatCalender = "DD MMMM",
	minDate,
	maxDate,
	placeholder = "Select Date",
	customLabel = "",
	range = false,
	compare = false,
	setDateRange,
	showSelectedDateRange = true,
	closeDateFilter,
	resetDateFilter,
	applyDateRange,
	changeCustomType,
	showCustomType = false,
	showArrow = true,
	showTime = false,
	align = "", // left or right
	classes = ""
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [currFocussedDateTime, setCurrFocussedDateTime] = useState("rangeStartDate");
	const [hoursList, setHoursList] = useState([...Array(12).keys()]);
	const [minutesSecondsList, setMinutesSecondsList] = useState([...Array(60).keys()]);

	const setTimingsList = () => {
		setHoursList(
			hoursList.map((hr) => ({
				label: hr + 1 < 10 ? `0${hr + 1}` : `${hr + 1}`,
				value: hr + 1 < 10 ? `0${hr + 1}` : `${hr + 1}`,
				index: hr
			}))
		);
		setMinutesSecondsList(
			minutesSecondsList.map((min) => ({
				label: min < 10 ? `0${min}` : `${min}`,
				value: min < 10 ? `0${min}` : `${min}`,
				index: min
			}))
		);
	};

	useEffect(() => {
		setTimingsList();
	}, []);

	useEffect(() => {
		if (currValue?.customTypeSelected?.value === CUSTOM_TYPES[0].value) {
			setCurrFocussedDateTime("rangeStartDate");
		} else if (currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value) {
			setCurrFocussedDateTime("rangeStartDate");
		} else if (currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value) {
			setCurrFocussedDateTime("rangeEndDate");
		}
	}, [currValue?.customTypeSelected]);

	const getDropdownLabel = () => {
		if (customLabel) {
			return <div>{customLabel}</div>;
		} else if (currValue) {
			if (range && applValue) {
				return (
					<div>
						{moment(applValue?.rangeStartDate).format(dateFormat)} —{" "}
						{moment(applValue?.rangeEndDate).format(dateFormat)}
					</div>
				);
			}
			return <div>{moment(currValue, "YYYY-MM-DD").format("DD MMM, YYYY")}</div>;
		}
		return <div>{placeholder}</div>;
	};

	const handleOpen = useCallback(() => {
		setIsOpen(!isOpen);
		if (range && !isOpen) {
			setDateRange();
		} else if (range && isOpen) {
			closeDateFilter();
			setTimeout(() => {
				setCurrFocussedDateTime("rangeStartDate");
			}, 50);
		}
	}, [isOpen, range, setDateRange, closeDateFilter]);

	const handleClose = useCallback(() => {
		if (isOpen) {
			setIsOpen(false);
			if (range) {
				closeDateFilter();
				setTimeout(() => {
					setCurrFocussedDateTime("rangeStartDate");
				}, 100);
			}
		}
	}, [isOpen, range, closeDateFilter]);

	const handleReset = useCallback(() => {
		resetDateFilter(compare);
	}, [compare, resetDateFilter]);

	const applySelectedDateRange = useCallback(() => {
		setIsOpen(false);
		applyDateRange();
	}, [applyDateRange]);

	const handleDate = useCallback(
		(field, date) => {
			setFilter(field, date);
			// close dropdown
			if (!range) {
				setIsOpen(false);
			}
			// change focus to start time
			if (
				showTime &&
				range &&
				currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value &&
				!compare &&
				currFocussedDateTime === "rangeStartDate"
			) {
				setTimeout(() => {
					setCurrFocussedDateTime("rangeStartTime");
				}, 50);
			}
			// change focus to end date
			if (
				!showTime &&
				range &&
				currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value &&
				!compare &&
				currFocussedDateTime === "rangeStartDate"
			) {
				setTimeout(() => {
					setCurrFocussedDateTime("rangeEndDate");
				}, 50);
			}
			// change focus to end time
			if (
				showTime &&
				range &&
				currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value &&
				!compare &&
				currFocussedDateTime === "rangeEndDate"
			) {
				setTimeout(() => {
					setCurrFocussedDateTime("rangeEndTime");
				}, 50);
			}
		},
		[setFilter, range, compare, showCustomType, currValue, currFocussedDateTime]
	);

	const handleTimeSlots = useCallback(
		(field, value) => {
			setTimeRange(currFocussedDateTime, field, value);
		},
		[currFocussedDateTime, setTimeRange]
	);

	const getCurrValue = useCallback(
		(type) => {
			if (currFocussedDateTime === "rangeStartDate") {
				return currValue?.rangeStartDate;
			} else if (currFocussedDateTime === "rangeEndDate") {
				return currValue?.rangeEndDate;
			} else if (currFocussedDateTime === "rangeStartTime" || currFocussedDateTime === "rangeEndTime") {
				let value = null;
				const time = currValue?.[currFocussedDateTime]?.split(":");
				switch (type) {
					case "hours":
						value = hoursList?.find((hr) => hr.value === TWELVE_HOUR_MAPPING[time[0]]);
						break;
					case "minutes":
						value = minutesSecondsList.find((min) => min.value === time[1]);
						break;
					case "seconds":
						value = minutesSecondsList.find((sec) => sec.value === time[2]);
						break;
					case "meridiem":
						value = parseInt(time[0]) < 12;
						break;
					default:
						break;
				}
				return value;
			}
		},
		[currValue, currFocussedDateTime]
	);

	if (currValue?.customTypeSelected && currValue?.customTypeSelected?.value !== CUSTOM_TYPES[0].value) {
		showSelectedDateRange = false;
	}

	const handleNextField = () => {
		switch (currFocussedDateTime) {
			case "rangeStartDate":
				if (showTime) {
					setTimeout(() => {
						setCurrFocussedDateTime("rangeStartTime");
					}, 50);
				} else {
					setTimeout(() => {
						setCurrFocussedDateTime("rangeEndDate");
					}, 50);
				}
				break;
			case "rangeStartTime":
				setTimeout(() => {
					setCurrFocussedDateTime("rangeEndDate");
				}, 50);
				break;
			case "rangeEndDate":
				if (showTime) {
					setTimeout(() => {
						setCurrFocussedDateTime("rangeEndTime");
					}, 50);
				}
				break;
			default:
				break;
		}
	};

	return (
		<div className={"date-filter-dropdown " + (isOpen ? "open " : "") + (range ? "range " : "") + classes}>
			<DropdownCustom
				title={title}
				selected={getDropdownLabel()}
				isOpen={isOpen}
				handleClick={handleOpen}
				handleOutsideClick={handleClose}
				clickEvent="mousedown"
				showArrow={showArrow}
				align={align}
				requiredLabel={requiredLabel}
			>
				{range && (
					<div>
						{showCustomType && (
							<div className="custom-type-selector">
								<SelectFilter
									options={CUSTOM_TYPES}
									field="Custom type"
									currValue={currValue?.customTypeSelected}
									setFilter={(f, v) => changeCustomType(v)}
									isClearable={false}
									isSearchable={false}
								/>
							</div>
						)}
						{!showTime && (
							<div className="date-range-selector">
								{(!showCustomType ||
									currValue?.customTypeSelected?.value !== CUSTOM_TYPES[2].value) && (
									<div
										className={
											"start-date-container" +
											(currFocussedDateTime === "rangeStartDate" ? " selected" : "")
										}
										onClick={() => setCurrFocussedDateTime("rangeStartDate")}
									>
										<div className="meta-info">
											{compare && presetType?.meta_info
												? presetType?.meta_info
												: showCustomType &&
												  currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value
												? "Date"
												: "Start Date"}
										</div>
										<div className={"date" + (currValue?.rangeStartDate ? "" : " placeholder")}>
											{currValue?.rangeStartDate
												? moment(currValue?.rangeStartDate).format(dateFormat)
												: "Select date"}
										</div>
									</div>
								)}
								{(!showCustomType || currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value) &&
									!compare && (
										<div
											className={
												"end-date-container" +
												(currFocussedDateTime === "rangeEndDate" ? " selected" : "") +
												(compare ? " disabled" : "")
											}
											onClick={() => setCurrFocussedDateTime("rangeEndDate")}
										>
											<div className="meta-info">
												{showCustomType &&
												currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value
													? "Date"
													: "End Date"}
											</div>
											<div className={"date" + (currValue?.rangeEndDate ? "" : " placeholder")}>
												{currValue?.rangeEndDate
													? moment(currValue?.rangeEndDate).format(dateFormat)
													: "Select date"}
											</div>
										</div>
									)}
							</div>
						)}
						{showTime && (
							<div className="date-time-range-selector">
								{(!showCustomType ||
									currValue?.customTypeSelected?.value !== CUSTOM_TYPES[2].value) && (
									<div className="start date-time">
										<div
											className={
												"start-date-container" +
												(currFocussedDateTime === "rangeStartDate" ? " selected" : "")
											}
											onClick={() => setCurrFocussedDateTime("rangeStartDate")}
										>
											<div className="meta-info">
												{compare && presetType?.meta_info
													? presetType?.meta_info
													: showCustomType &&
													  currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value
													? "Date"
													: "Start Date"}
											</div>
											<div className={"date" + (currValue?.rangeStartDate ? "" : " placeholder")}>
												{currValue?.rangeStartDate
													? moment(currValue?.rangeStartDate).format(dateFormat)
													: "Select date"}
											</div>
										</div>
										<div
											className={
												"start-time-container" +
												(currFocussedDateTime === "rangeStartTime" ? " selected" : "")
											}
											onClick={() => setCurrFocussedDateTime("rangeStartTime")}
										>
											<div className="meta-info">
												{compare && presetType?.meta_info
													? presetType?.meta_info
													: showCustomType &&
													  currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value
													? "Time"
													: "Start Time"}
											</div>
											<div className={"date" + (currValue?.rangeStartTime ? "" : " placeholder")}>
												{currValue?.rangeStartTime
													? moment(currValue?.rangeStartTime, "hh:mm:ss")
															.format("LTS")
															.replaceAll(":", " : ")
													: "Select time"}
											</div>
										</div>
									</div>
								)}
								{(!showCustomType || currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value) &&
									!compare && (
										<div className="end date-time">
											<div
												className={
													"end-date-container" +
													(currFocussedDateTime === "rangeEndDate" ? " selected" : "") +
													(compare ? " disabled" : "")
												}
												onClick={() => setCurrFocussedDateTime("rangeEndDate")}
											>
												<div className="meta-info">
													{showCustomType &&
													currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value
														? "Date"
														: "End Date"}
												</div>
												<div
													className={"date" + (currValue?.rangeEndDate ? "" : " placeholder")}
												>
													{currValue?.rangeEndDate
														? moment(currValue?.rangeEndDate).format(dateFormat)
														: "Select date"}
												</div>
											</div>
											<div
												className={
													"end-time-container" +
													(currFocussedDateTime === "rangeEndTime" ? " selected" : "")
												}
												onClick={() => setCurrFocussedDateTime("rangeEndTime")}
											>
												<div className="meta-info">
													{showCustomType &&
													currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value
														? "Time"
														: "End Time"}
												</div>
												<div
													className={"date" + (currValue?.rangeEndTime ? "" : " placeholder")}
												>
													{currValue?.rangeEndTime
														? moment(currValue?.rangeEndTime, "hh:mm:ss")
																.format("LTS")
																.replaceAll(":", " : ")
														: "Select time"}
												</div>
											</div>
										</div>
									)}
							</div>
						)}
					</div>
				)}
				{(currFocussedDateTime === "rangeStartDate" || currFocussedDateTime === "rangeEndDate") && (
					<DatePicker
						inline
						selected={range ? getCurrValue() : currValue}
						onChange={(date) => handleDate(range ? currFocussedDateTime : field, date)}
						dateFormat={dateFormat}
						dateFormatCalendar={dateFormatCalender}
						showMonthDropdown
						showYearDropdown
						yearDropdownItemNumber={10}
						selectsStart={range && currFocussedDateTime === "rangeStartDate"}
						selectsEnd={range && currFocussedDateTime === "rangeEndDate"}
						startDate={range && showSelectedDateRange ? currValue?.rangeStartDate : undefined}
						endDate={range && showSelectedDateRange ? currValue?.rangeEndDate : undefined}
						minDate={
							(range && currFocussedDateTime === "rangeEndDate") ||
							currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value
								? currValue?.rangeStartDate || minDate || moment().subtract(12, "months")
								: minDate || moment().subtract(12, "months")
						}
						maxDate={
							range && !compare && currFocussedDateTime === "rangeStartDate"
								? currValue?.rangeEndDate
								: maxDate || moment()
						}
					/>
				)}
				{showTime && (currFocussedDateTime === "rangeStartTime" || currFocussedDateTime === "rangeEndTime") && (
					<div className="time-selector">
						<SelectFilter
							title="HH"
							options={hoursList}
							field="hours"
							currValue={getCurrValue("hours")}
							setFilter={handleTimeSlots}
							labelKey="label"
							valueKey="value"
							isClearable={false}
							placeholder="HH"
							noOptionsMessage="- -"
						/>
						<span className="colon">:</span>
						<SelectFilter
							title="MM"
							options={minutesSecondsList}
							field="minutes"
							currValue={getCurrValue("minutes")}
							setFilter={handleTimeSlots}
							labelKey="label"
							valueKey="value"
							isClearable={false}
							placeholder="MM"
							noOptionsMessage="- -"
						/>
						<span className="colon">:</span>
						<SelectFilter
							title="SS"
							options={minutesSecondsList}
							field="seconds"
							currValue={getCurrValue("seconds")}
							setFilter={handleTimeSlots}
							labelKey="label"
							valueKey="value"
							isClearable={false}
							placeholder="MM"
							noOptionsMessage="- -"
						/>
						<div className="meridiem-container">
							<div className="meta-info">AM/PM</div>
							<div
								className="meridiem"
								onClick={() => handleTimeSlots("meridiem", !getCurrValue("meridiem"))}
							>
								{getCurrValue("meridiem") ? "AM" : "PM"}
							</div>
						</div>
					</div>
				)}
				{range && (
					<div className="actions-container">
						<img
							className="reset"
							src="/assets/icons/icon-blue-reset.svg"
							title="Reset to default"
							onClick={handleReset}
						/>
						<div
							className={
								"action apply" +
								(showCustomType || (currValue?.rangeStartDate && currValue?.rangeEndDate)
									? ""
									: " disabled")
							}
							onClick={
								!compare
									? showTime
										? showSelectedDateRange
											? currFocussedDateTime !== "rangeEndTime"
												? handleNextField
												: applySelectedDateRange
											: !["rangeStartTime", "rangeEndTime"].includes(currFocussedDateTime)
											? handleNextField
											: applySelectedDateRange
										: showSelectedDateRange
										? currFocussedDateTime !== "rangeEndDate"
											? handleNextField
											: applySelectedDateRange
										: applySelectedDateRange
									: applySelectedDateRange
							}
						>
							{!compare
								? showTime
									? showSelectedDateRange
										? currFocussedDateTime !== "rangeEndTime"
											? "Next"
											: "Apply"
										: !["rangeStartTime", "rangeEndTime"].includes(currFocussedDateTime)
										? "Next"
										: "Apply"
									: showSelectedDateRange
									? currFocussedDateTime !== "rangeEndDate"
										? "Next"
										: "Apply"
									: "Apply"
								: "Apply"}
						</div>
						<div className="action cancel" onClick={handleClose}>
							Cancel
						</div>
					</div>
				)}
			</DropdownCustom>
			{validationMessage && <div className="validation-message">{validationMessage}</div>}
		</div>
	);
};
