// action types
import { ActionTypes } from "../actions/_types";

export const ACCEPT_INVITE_INITIAL_STATE = {
	loading: true,
	emailLoading: false,
	phoneLoading: false,
	emailOtpLoading: false,
	phoneOtpLoading: false,
	data: {
		email: "",
		roles: [],
		firstName: "",
		lastName: "",
		phone: "",
		emailOtp: "",
		phoneOtp: "",
		username: "",
		password: "",
		confirmPassword: ""
	},
	error: {}
};
export const acceptInviteDetail = (state = ACCEPT_INVITE_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_INVITE_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_INVITE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.GET_INVITE_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_INVITE_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_INVITE_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_INVITE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_INVITE_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.OTP_GENERATION_REQUEST:
			return {
				...state,
				...action.payload,
				error: {}
			};
		case ActionTypes.OTP_GENERATION_SUCCESS:
			return {
				...state,
				emailLoading: false,
				phoneLoading: false
			};
		case ActionTypes.OTP_GENERATION_FAILURE:
			return {
				...state,
				emailLoading: false,
				phoneLoading: false,
				error: action.error
			};
		case ActionTypes.OTP_VALIDATION_REQUEST:
			return {
				...state,
				...action.payload,
				error: {}
			};
		case ActionTypes.OTP_VALIDATION_SUCCESS:
			return {
				...state,
				emailOtpLoading: false,
				phoneOtpLoading: false
			};
		case ActionTypes.OTP_VALIDATION_FAILURE:
			return {
				...state,
				emailOtpLoading: false,
				phoneOtpLoading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_INVITE_DETAIL_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
