export const campaignListState = (
	state = {
		limit: 10,
		offset: 0,
		sort: {
			field: "id",
			order: "DESC"
		}
	},
	action
) => {
	switch (action.type) {
		case "CAMPAIGN_LIST_STATE_CHANGE":
			return {
				...state,
				...action.payload
			};
		case "CAMPAIGN_LIST_STATE_CHANGE_SORT":
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				}
			};
		default:
			return state;
	}
};

const getSortOrder = (currSort, newSort) => {
	return currSort.order === "" || currSort.field !== newSort.field
		? "ASC"
		: currSort.order === "ASC"
		? "DESC"
		: "ASC";
};

export const campaignList = (
	state = {
		loading: true,
		data: {
			objects: [],
			filters: []
		},
		error: false
	},
	action
) => {
	switch (action.type) {
		case "GET_CAMPAIGN_LIST_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "GET_CAMPAIGN_LIST_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "GET_CAMPAIGN_LIST_FAILURE":
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const campaignStatus = (
	state = {
		loading: true,
		data: null,
		error: false,
		updating: false
	},
	action
) => {
	switch (action.type) {
		case "UPDATE_REPORT_REQUEST":
			return {
				...state,
				updating: true
			};
		case "UPDATE_REPORT_REQUEST_SUCCESS":
			return {
				...state,
				updating: false,
				data: action.payload
			};
		case "GET_CAMPAIGN_STATUS_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "GET_CAMPAIGN_STATUS_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "GET_CAMPAIGN_STATUS_FAILURE":
			return {
				...state,
				loading: false,
				error: action.error || true
			};
		default:
			return state;
	}
};
