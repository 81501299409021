import React from "react";
import { connect } from "react-redux";
import { getPlatformDetailViaName } from "../../../helpers/periscope";

const TablePlatform = ({
	periscopeItemIssuesState,
	tableName,
	configItems,
	periscopeSpecificItemIssueState,
	text = "",
	showSortOptions = true
}) => {
	let sortingData;
	if (text == "periscopeSpecificItemIssueState") {
		sortingData = periscopeSpecificItemIssueState?.sort;
	} else {
		sortingData = periscopeItemIssuesState?.sort;
	}
	let sortedPlatformName = "";
	let orderStatus = "";

	const bizPlatforms = configItems?.bizPlatforms?.items;
	if (sortingData) {
		sortedPlatformName = sortingData.field;
		orderStatus = sortingData.order;
	}
	const topImage = <img src="/assets/periscope/top-face.svg" />;
	const bottomFacedImage = <img src="/assets/periscope/down-face.svg" />;
	const topBlueImage = <img src="/assets/periscope/topBlue.svg" />;
	const bottomFacedBlueImage = <img src="/assets/periscope/bottomFacedBlue.svg" />;
	const currentPlatform = getPlatformDetailViaName(tableName, bizPlatforms);
	const platformImage = currentPlatform?.image;

	return (
		<>
			<div className="item-platform-head">
				<img src={platformImage} className="table-platform-img" />
				<span> {currentPlatform?.platformName}</span>
				{showSortOptions ? (
					<span style={{ display: "flex", flexDirection: "column" }}>
						{sortedPlatformName === tableName ? (
							orderStatus === "asc" ? (
								<>
									{topBlueImage}
									{bottomFacedImage}
								</>
							) : orderStatus === "desc" ? (
								<>
									{topImage}

									{bottomFacedBlueImage}
								</>
							) : (
								<>
									{topImage}
									{bottomFacedImage}
								</>
							)
						) : (
							<>
								{topImage}
								{bottomFacedImage}
							</>
						)}
					</span>
				) : (
					""
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	periscopeItemIssuesState: state.periscopeItemIssuesState,
	periscopeSpecificItemIssueState: state.periscopeSpecificItemIssueState,
	configItems: state.configItems
});
export default connect(mapStateToProps)(TablePlatform);
