import React, { useState } from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";

// assets
import HelpIcon from "../_commons/HelpIcon";

import history from "../../history";
import ContextMenu from "../_commons/ContextMenu";
import ChevronIcon from "../_icons/ChevronIcon";
import MultiActionsButton from "../_commons/MultiActionsButton";

export const Header = (props) => {
	const handleCreateNew = () => {
		if (props?.selectedTab === "regular") {
			history.push("/timing-groups/new");
		} else {
			history.push("/timing-groups/holiday-new");
		}
	};
	const actionsAvailable = [
		{
			title: "Regular Schedule",
			clickHandler: () => {
				history.push("/timing-groups/new");
			}
		},
		{
			title: "Holiday Schedule",
			clickHandler: () => {
				history.push("/timing-groups/holiday-new");
			}
		}
	];
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Schedules</div>
				<div className="subtitle">Manage your operational schedule for your business</div>
			</div>
			{!props.hideHeaderSectionRight && (
				<div className="header-action-button">
					<div className="filter-buttons">
						{props.isCatalogueManagement && (
							<div className="action-buttons-container">
								{props.isHolidayHourEnabled ? (
									<div>
										<MultiActionsButton
											title="Create New"
											instantAction={() => handleCreateNew()}
											actions={actionsAvailable}
										/>
									</div>
								) : (
									<Link to="/timing-groups/new">
										<Button>Create new</Button>
									</Link>
								)}
							</div>
						)}

						<div className="help-btn-container">
							<Button clickHandler={props.handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
