import React from "react";

// utils
import { printCurrency } from "../../atlas-utils";

// constants
import { NESTED_ENTITY_TYPES, FOOD_TYPE_MAP } from "../../client-config";
import Image from "../_commons/Image";
import MonogramGenerator from "../_commons/MonogramGenerator";

export const Table = ({
	id,
	itemTitle,
	itemDesc = "",
	foodType,
	itemPrice,
	imageUrl,
	locationsCount,
	style,
	currencySymbol,
	archived,
	openNestedEntity,
	index,
	brandsData,
	isMultibrandEnabled
}) => {
	// const dragRef = useRef(null);

	// const [{ isDragging }, drag] = useDrag(() => ({
	//     type: CATEGORY_TYPES.CATEGORYITEMS,
	// 	item: () => ({id, index}),
	//     collect: (monitor) => ({
	//       	isDragging: monitor.isDragging()
	// 	})
	// }))

	// const [{ handlerId, isOver }, drop] = useDrop({
	//     accept: CATEGORY_TYPES.CATEGORYITEMS,
	//     collect(monitor) {
	//         return {
	//             handlerId: monitor.getHandlerId(),
	// 			isOver: monitor.isOver()
	//         };
	//     },
	//     hover(item, monitor) {
	//         if (!dragRef.current) {
	//             return;
	//         }
	//         const dragIndex = item.index;
	//         const hoverIndex = index;
	//         // Don't replace items with themselves
	//         if (dragIndex === hoverIndex) {
	//             return;
	//         }
	//         // Determine rectangle on screen
	//         const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
	//         // Get vertical middle
	//         const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
	//         // Determine mouse position
	//         const clientOffset = monitor.getClientOffset();
	//         // Get pixels to the top
	//         const hoverClientY = clientOffset.y - hoverBoundingRect.top;
	//         // Only perform the move when the mouse has crossed half of the items height
	//         // When dragging downwards, only move when the cursor is below 50%
	//         // When dragging upwards, only move when the cursor is above 50%
	//         // Dragging downwards
	//         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
	//             return;
	//         }
	//         // Dragging upwards
	//         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
	//             return;
	//         }
	//         // Time to actually perform the action
	// 		// console.log("Mutation happens at this point", dragIndex, hoverIndex)
	//         // moveCard(dragIndex, hoverIndex);
	//         // Note: we're mutating the monitor item here!
	//         // Generally it's better to avoid mutations,
	//         // but it's good here for the sake of performance
	//         // to avoid expensive index searches.
	//         item.index = hoverIndex;
	//     },
	// });
	// drag(drop(dragRef))

	// const onDragStyle = {
	// 	cursor: 'move',
	// 	opacity: '0.5'
	// }

	// const dropOverStyle = {
	// 	borderTop: "2px solid #2f58f2",
	// 	borderBottom: "2px solid #2f58f2"
	// }

	return (
		<div key={index} className="at-table-row transaction-rows categories">
			{/* {
				isOver && (isOver !== isDragging) &&
				<span className="round-top"></span>
			} */}
			<div className="at-table-cell at-cell-text items" title={itemTitle}>
				<div className="item-image">
					<img
						src={imageUrl || "/assets/empty-dish.jpg"}
						alt="item image"
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
					/>
				</div>
				<div className="item-desc">
					<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<a
						role="button"
						// className={"link-text "+archived}
						onClick={(e) => openNestedEntity(e, NESTED_ENTITY_TYPES[1], id)}
					>
						<span className="hyperlink hyperlink--black-color">{itemTitle || id}</span>
					</a>
					<div className="text--light desc-text" title={itemDesc}>
						{itemDesc.length > 50 ? itemDesc.slice(0, 50) + "..." : itemDesc}
					</div>
				</div>
			</div>
			<div className="at-table-cell at-cell-text associated_locations">{locationsCount}</div>
			{isMultibrandEnabled && (
				<div className="at-table-cell at-cell-text brands">
					{(brandsData ?? []).map((brand) =>
						brand.image ? (
							<Image src={brand.image} className="brand-image" title={brand.name} />
						) : (
							<MonogramGenerator value={brand.name} size="xsmall" title={brand.name} />
						)
					)}
					{(brandsData ?? []).length === 0 && "--"}
				</div>
			)}
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{itemPrice || 0}
			</div>
			{/* {
				isOver && (isOver !== isDragging) &&
				<span className="round"></span>
			} */}
		</div>
	);
};
