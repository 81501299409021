import React, { useEffect, useState, useRef, useCallback } from "react";

// components
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import Popover from "../_commons/Popover";
import CreateIcon from "../_commons/CreateIcon";
import FormTable from "../_commons/FormTable";
import Image from "../_commons/Image";
import Placeholder from "../_commons/Placeholder";
import { OutsideClick } from "../_commons/OutsideClick";
import IlpaIdsViewSidebar from "./BrandEntityAssociations/IlpaIdsViewSidebar";
import CopyCatalogue from "../LocationEdit/CopyCatalogue";
import LocationItemsAssociation from "../LocationEdit/LocationItemsAssociation";
import ItemLocationCustomization from "../LocationEdit/ItemLocationCustomization";

// third party
import { Transition, config, animated } from "react-spring/renderprops";
import { connect } from "react-redux";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { scroll } from "../../atlas-utils";

// graphql
import { GET_BRAND_LOCATION_CATALOGUE } from "../../graphql/brands";
import { GET_BIZ_PLATFORMS_MINI } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchAssociatedBrandLocations } from "../../actions/actions";

// assets
import CopyIcon from "../_commons/CopyIcon";

// constants
import { CATALOGUE_PLATFORMS_LOGO, ITEM_ENTITY_ASSOCIATION_TYPES, NESTED_ENTITY_TYPES } from "../../client-config";

const columns = [
	{
		title: "Item name",
		dataIndex: "itemInfo",
		render: (data, renderValuesAndFunctions) => {
			return (
				<div className="item-info-container" title={data.itemTitle}>
					<img
						src={data.imageUrl || "/assets/empty-dish.jpg"}
						alt={data.itemTitle}
						className="item-image"
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
					/>
					<div className={"foodtype " + FOOD_TYPE_MAPPING[data.foodType]}></div>
					<div className="item-name">
						<span
							className="hyperlink hyperlink--black-color fixed-width"
							onClick={() =>
								renderValuesAndFunctions?.handleNestedEntity(true, NESTED_ENTITY_TYPES[1], data?.id)
							}
						>
							{data?.itemTitle.length > 27 ? data.itemTitle.slice(0, 25) + "..." : data?.itemTitle}
						</span>
					</div>
				</div>
			);
		}
	},
	{
		title: "Assoc. Locations",
		dataIndex: "assocLocations",
		render: (data) => data
	},
	{
		title: "Assoc. Platforms",
		dataIndex: "locationPlatforms",
		render: (data, renderValuesAndFunctions) => {
			return (
				<div className="platforms-list">
					{data.map((platform, i) => (
						<div
							className={"platform " + (platform.state === "active" ? "platform--enabled" : "")}
							key={i}
							title={platform?.platformName?.toLowerCase()}
						>
							<Image
								src={
									platform?.logo ||
									CATALOGUE_PLATFORMS_LOGO[platform.platformName.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
								}
								className="platform-img"
							/>
						</div>
					))}
				</div>
			);
		}
	},
	{
		title: "",
		dataIndex: "ilpaIds",
		render: (data, renderValuesAndFunctions) => {
			return (
				<>
					{renderValuesAndFunctions.fromLocations && !renderValuesAndFunctions.readOnly && (
						<span
							className="link-text hyperlink prices-link customise-link"
							onClick={() => {
								renderValuesAndFunctions.openItemCustomisation(data?.itemId);
							}}
						>
							Customise
						</span>
					)}
					<Transition
						native
						from={{ opacity: 0 }}
						enter={{ opacity: 1 }}
						leave={{ opacity: 0 }}
						items={renderValuesAndFunctions?.isIlpaLinkVisible}
						config={config.stiff}
					>
						{(isOpen) =>
							isOpen &&
							((props) => (
								<animated.span
									style={{ ...props }}
									className="link-text hyperlink"
									onClick={() => renderValuesAndFunctions.handleIlpaIdsViewer(data)}
								>
									View ILPA IDs
								</animated.span>
							))
						}
					</Transition>
				</>
			);
		}
	}
];

const columnsPlatformSelected = [
	{
		title: "Item name",
		dataIndex: "itemInfo",
		render: (data, renderValuesAndFunctions) => {
			return (
				<div className="item-info-container" title={data.itemTitle}>
					<img
						src={data.imageUrl || "/assets/empty-dish.jpg"}
						alt={data.itemTitle}
						className="item-image"
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
					/>
					<div className={"foodtype " + FOOD_TYPE_MAPPING[data.foodType]}></div>
					<div className="item-name">
						<span
							className="hyperlink hyperlink--black-color fixed-width"
							onClick={() =>
								renderValuesAndFunctions?.handleNestedEntity(true, NESTED_ENTITY_TYPES[1], data?.id)
							}
						>
							{data?.itemTitle.length > 27 ? data.itemTitle.slice(0, 25) + "..." : data?.itemTitle}
						</span>
					</div>
				</div>
			);
		}
	},
	{
		title: "",
		dataIndex: "ilpaIds",
		render: (data, renderValuesAndFunctions) => {
			return (
				<>
					{renderValuesAndFunctions.fromLocations && (
						<span
							className="link-text hyperlink prices-link customise-link"
							onClick={() => {
								renderValuesAndFunctions.openItemCustomisation(data?.itemId);
							}}
						>
							Customise
						</span>
					)}
					<Transition
						native
						from={{ opacity: 0 }}
						enter={{ opacity: 1 }}
						leave={{ opacity: 0 }}
						items={renderValuesAndFunctions?.isIlpaLinkVisible}
						config={config.stiff}
					>
						{(isOpen) =>
							isOpen &&
							((props) => (
								<animated.span
									style={{ ...props }}
									className="link-text hyperlink"
									onClick={() => renderValuesAndFunctions.handleIlpaIdsViewer(data)}
								>
									View ILPA IDs
								</animated.span>
							))
						}
					</Transition>
				</>
			);
		}
	}
];
const FOOD_TYPE_MAPPING = {
	VEGETARIAN: "veg",
	NON_VEGETARIAN: "non-veg"
};
const ILPA_IDS_INIT_STATE = { data: null, show: false };

const SEARCH_KEY_TYPES = [
	{
		value: "name",
		valueForDisplay: "Item Name"
	},
	{
		value: "ilpaId",
		valueForDisplay: "ILPA ID"
	}
];

const Catalogue = ({
	associatedBrandLocations,
	brandId,
	fromLocations = false,
	locationId,
	locationName,
	isMultibrandEnabled,
	selectedBrandLocation,
	brandLocations,
	handleBrandLocation,
	handleBrandsLabelOption,
	showPopover,
	renderPopover,
	handlePopoverClose,
	selectedTab,
	readOnly = false,
	currencySymbol,
	containerClassName,
	handleNestedEntity = () => {},
	isInternationalMerchant = false
}) => {
	const [isIlpaLinkVisible, setIsIlpaLinkVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState([]);
	const [categoriesBackup, setCategoriesBackup] = useState([]);
	const [ilpaIdsState, setIlpaIdsState] = useState(ILPA_IDS_INIT_STATE);
	const [isAvailable, setAvailiability] = useState({ value: true, valueForDisplay: "Available" });
	const [selectedLocation, setSelectedLocation] = useState();
	const [searchQuery, setSearchQuery] = useState("");
	const [catNavOpen, setCatNav] = useState(false);
	const [selectedCat, setSelectedCat] = useState(undefined);
	const [copyCatalogueSidebar, setCopyCatalogueSidebarState] = useState(false);
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [itemCustomizationState, setItemCustomisationState] = useState({ isOpen: false, itemId: null });
	const [searchKey, setSearchKey] = useState(SEARCH_KEY_TYPES[0]);
	const [selectedPlatform, setSelectedPlatform] = useState({ id: "allPlatforms", platformName: "All Platforms" });
	const [bizPlatforms, setBizPlatforms] = useState([]);
	const debounceRef = useRef();

	const placeholderContent = {
		placeholderText: "No items found!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
		redirectionLink: "/piper-academy/items",
		redirectionLinkText: "learn more about items"
	};

	const handleIlpaLinkVisibility = () => {
		setIsIlpaLinkVisible((current) => !current);
	};

	const handleIlpaIdsViewer = (data) => {
		setIlpaIdsState({
			show: true,
			data: { ...data }
		});
	};

	const closeIlpaIdsViewer = () => {
		setIlpaIdsState(ILPA_IDS_INIT_STATE);
	};

	const loginDetail = store.getState().login.loginDetail;
	const isInternalUser = !!loginDetail?.email?.includes("@urbanpiper.com");

	const rearrangeCategories = (categories) => {
		try {
			let rearrangedCategories = [];
			const getCategory = (category, parentCategory = "") => {
				const { items, name, subcategories } = category;
				rearrangedCategories = [
					...rearrangedCategories,
					{
						items: items.map((item) => ({
							ilpaIds: { itemId: item.id, ids: [...item.locationPlatforms], itemTitle: item.itemTitle },
							locationPlatforms: [...item.locationPlatforms],
							itemInfo: { ...item, locationPlatforms: null },
							assocLocations: item?.associatedLocationsCount
						})),
						name,
						parentCategory
					}
				];
				if (subcategories?.length > 0) {
					subcategories.forEach((subcategory) => getCategory(subcategory, name + " > "));
				}
			};
			categories.forEach((category) => getCategory(category));
			return rearrangedCategories;
		} catch (e) {
			console.log(e);
		}
	};

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				available: isAvailable?.value,
				brandId: brandId ? parseInt(brandId) : null,
				platforms: selectedPlatform?.id === "allPlatforms" ? [] : [parseInt(selectedPlatform?.id)]
			};
			if (fromLocations) {
				variables.locations = [locationId];
			} else if (selectedLocation) {
				variables.locations = [selectedLocation?.id];
			}
			variables.filters = [
				{
					field: "item_title",
					value: searchQuery
				}
			];
			const resp = await client.query({
				query: GET_BRAND_LOCATION_CATALOGUE,
				variables,
				fetchPolicy: "no-cache"
			});
			let catalogue = resp?.data?.locationCatalogue;
			if (catalogue.categories) {
				const restructuredCategories = rearrangeCategories(catalogue.categories);
				setCategories(restructuredCategories);
				if (categoriesBackup.length === 0 || !searchQuery) {
					setCategoriesBackup(restructuredCategories);
				}
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [
		brandId,
		selectedLocation,
		locationId,
		fromLocations,
		isAvailable,
		searchQuery,
		selectedPlatform,
		categoriesBackup
	]);

	const fetchBizPlatforms = async () => {
		try {
			const variables = {
				limit: 30,
				offset: 0,
				filters: [{ field: "is_enabled", value: true }],
				includeUrbanpiper: false
			};

			const resp = await client.query({
				query: GET_BIZ_PLATFORMS_MINI,
				variables,
				fetchPolicy: "no-cache"
			});

			if (resp.data.bizPlatforms.objects) {
				setBizPlatforms([
					{
						id: "allPlatforms",
						platformName: "All Platforms"
					},
					...resp.data.bizPlatforms.objects
				]);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Failed to fetch associated business platforms.",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const setFilter = (field, value) => {
		if (field === "locationId") {
			setSelectedLocation(value ? value : null);
			return;
		}
		if (field === "status") {
			setAvailiability(value);
		}
		if (field === "itemName") {
			setSearchQuery(value);
		}

		if (field === "searchKey") {
			setSearchKey(value);
			setSearchQuery("");
		}
	};

	const handleCategoryNavigation = (category) => {
		// set category
		setSelectedCat(category);

		// perform scroll
		try {
			const formContainer = document.querySelector(`.${containerClassName} .form-container`);
			const categoryContainer = formContainer.querySelector(
				`.categories-container[data-cat="${category
					.split(" ")
					.join("_")
					.replace(/[^a-zA-Z0-9_-]+/g, "")}"]`
			);

			if (categoryContainer && formContainer) {
				window.requestAnimationFrame(() => {
					scroll({ top: categoryContainer?.offsetTop - 180, left: 0 }, formContainer);
				});
			}
			window.requestAnimationFrame(() => {
				setCatNav(false);
			});
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchData();
	}, [selectedLocation, isAvailable, brandId, selectedPlatform]);

	useEffect(() => {
		if (!fromLocations) {
			fetchAssociatedBrandLocations(String(brandId), "");
		}
	}, [selectedTab]);

	useEffect(() => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}
		if (searchKey.value === "ilpaId") {
			return;
		}

		debounceRef.current = setTimeout(() => fetchData(brandId), 500);
	}, [searchQuery, searchKey]);

	useEffect(() => {
		if (fromLocations) {
			fetchBizPlatforms();
		}
	}, []);

	const openCopyCatalogueSidebar = () => {
		setCopyCatalogueSidebarState(true);
	};

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setCopyCatalogueSidebarState(false);
		if (refresh) {
			fetchData();
		}
	};

	const closeItemCustomisation = (refresh = false) => {
		setItemCustomisationState((current) => ({
			isOpen: false,
			itemId: null
		}));
		if (refresh) {
			fetchData();
		}
	};

	const openItemCustomisation = (itemId) => {
		setItemCustomisationState((current) => ({
			isOpen: true,
			itemId: itemId
		}));
	};

	const findByIlpaIds = (categories, searchQuery) => {
		if (!searchQuery) {
			return categories;
		}
		const updatedCategories = [];
		categories.forEach((category) => {
			updatedCategories.push({
				...category,
				items: [
					...(category?.items ?? []).filter((item) => {
						const ilpaIds = item?.ilpaIds?.ids || [];
						for (let i = 0; i < ilpaIds.length; i++) {
							const ilpaId = ilpaIds[i].ilpaId;
							if (String(ilpaId).includes(searchQuery)) {
								return true;
							}
						}
						return false;
					})
				]
			});
		});
		return updatedCategories.filter((category) => (category?.items ?? []).length > 0);
	};

	const handlePlatformsLabelOption = (plf) => {
		return (
			<React.Fragment>
				<div className="logo">
					<img
						src={
							plf?.logo ||
							CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
							"/assets/icons/icons8-globe-40.png"
						}
						alt=""
					/>
				</div>
				<div title={plf.platformName}>
					{plf.platformName && plf.platformName.length > 25
						? plf.platformName.slice(0, 25) + "..."
						: plf.platformName}
				</div>
			</React.Fragment>
		);
	};

	const handlePlatformSelection = (value) => {
		setSelectedPlatform(value);
	};

	const renderValuesAndFunctions = {
		isIlpaLinkVisible,
		handleIlpaIdsViewer,
		handleNestedEntity,
		openItemCustomisation,
		fromLocations,
		readOnly
	};

	const filteredCategories = searchKey.value === "ilpaId" ? findByIlpaIds(categoriesBackup, searchQuery) : categories;

	return (
		<div className="brand-catalogue-tab">
			{fromLocations && (
				<div className="header-container">
					{isMultibrandEnabled && fromLocations && brandLocations.length > 0 && selectedBrandLocation ? (
						<Popover
							open={showPopover}
							renderPopover={renderPopover}
							showClose={true}
							onClose={handlePopoverClose}
							position="down-left"
						>
							<SelectFilterCustom
								options={brandLocations || []}
								field="selectedBrandLocation"
								currValue={selectedBrandLocation}
								setFilter={(f, value) => handleBrandLocation(value)}
								labelKey={"brandName"}
								valueKey={"brandId"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								isClearable={false}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								classes="brand-selector"
							/>
						</Popover>
					) : (
						<div>
							<div className="header-text">Catalogue</div>
							<div className="header-subtext">Manage items associated to this location</div>
						</div>
					)}
					<div className="action-buttons">
						{!readOnly && (
							<div className="button">
								<Button type="secondary" clickHandler={openCopyCatalogueSidebar}>
									<CopyIcon />
									<span>Copy catalogue from another location</span>
								</Button>
							</div>
						)}
						{/* {
						!readOnly &&
						<div className='button'>
							<Button clickHandler={openAssociationSidebar}>
								Update
							</Button>
						</div>
					} */}
					</div>
				</div>
			)}
			<Filters
				handleIlpaLinkVisibility={handleIlpaLinkVisibility}
				isIlpaLinkVisible={isIlpaLinkVisible}
				associatedBrandLocations={associatedBrandLocations}
				setFilter={setFilter}
				selectedLocation={selectedLocation}
				isAvailable={isAvailable}
				searchQuery={searchQuery}
				isInternalUser={isInternalUser}
				fromLocations={fromLocations}
				isMultibrandEnabled={isMultibrandEnabled}
				selectedBrandLocation={selectedBrandLocation}
				brandLocations={brandLocations}
				handleBrandLocation={handleBrandLocation}
				handleBrandsLabelOption={handleBrandsLabelOption}
				showPopover={showPopover}
				renderPopover={renderPopover}
				handlePopoverClose={handlePopoverClose}
				searchKey={searchKey}
				bizPlatforms={bizPlatforms}
				selectedPlatform={selectedPlatform}
				handlePlatformsLabelOption={handlePlatformsLabelOption}
				handlePlatformSelection={handlePlatformSelection}
			/>

			{filteredCategories?.length === 0 && loading ? (
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			) : filteredCategories?.length === 0 ? (
				<Placeholder {...placeholderContent} />
			) : (
				filteredCategories.map((category, i) =>
					category?.items.length > 0 ? (
						<div
							data-cat={category.name
								.split(" ")
								.join("_")
								.replace(/[^a-zA-Z0-9_-]+/g, "")}
							className={`categories-container${loading ? " disabled" : ""}`}
							key={i}
							id={category.name.split(" ").join("_")}
						>
							<div className="categories-title">
								<div className="title">
									{category.parentCategory} {category.name}
								</div>
								<div className="items-count">{category?.items.length} Item(s)</div>
							</div>
							<FormTable
								placeholderContent={placeholderContent}
								dataSource={category.items}
								renderValuesAndFunctions={renderValuesAndFunctions}
								columns={selectedPlatform?.id === "allPlatforms" ? columns : columnsPlatformSelected}
								contentTableClass={`brand-catalogue--table ${
									selectedPlatform?.id === "allPlatforms" ? "" : "single-plf-mode"
								}`}
							/>
						</div>
					) : null
				)
			)}
			{!loading && filteredCategories.length !== 0 && (
				<CategoryNavigation
					rootCategories={filteredCategories}
					catNavOpen={catNavOpen}
					setCatNav={setCatNav}
					handleCategoryNavigation={handleCategoryNavigation}
					handleOutsideClick={() => setCatNav(false)}
					selectedCat={selectedCat}
				/>
			)}
			<IlpaIdsViewSidebar
				isOpen={ilpaIdsState?.show}
				close={closeIlpaIdsViewer}
				data={ilpaIdsState.data}
				brandId={brandId}
				selectedLocation={fromLocations ? { id: locationId } : selectedLocation}
			/>
			<LocationItemsAssociation
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={ITEM_ENTITY_ASSOCIATION_TYPES[5]}
				entityId={parseInt(locationId)}
				currencySymbol={currencySymbol}
			/>
			<CopyCatalogue
				isOpen={copyCatalogueSidebar}
				close={closeAssociationSidebar}
				entityId={parseInt(locationId)}
				brandId={brandId}
				locationName={locationName || "current location"}
			/>
			<ItemLocationCustomization
				isOpen={itemCustomizationState.isOpen}
				close={closeItemCustomisation}
				locationId={locationId}
				itemId={itemCustomizationState.itemId}
				handleNestedEntity={handleNestedEntity}
				info={{ source: "location", name: locationName }}
				isInternationalMerchant={isInternationalMerchant}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	associatedBrandLocations: store.configItems.associatedBrandLocations
});
export default connect(mapStateToProps)(Catalogue);

const Filters = ({
	handleIlpaLinkVisibility,
	isIlpaLinkVisible,
	associatedBrandLocations,
	setFilter,
	selectedLocation,
	isAvailable,
	searchQuery,
	isInternalUser,
	isMultibrandEnabled,
	fromLocations,
	selectedBrandLocation,
	brandLocations,
	handleBrandLocation,
	handleBrandsLabelOption,
	showPopover,
	renderPopover,
	handlePopoverClose,
	searchKey,
	bizPlatforms,
	selectedPlatform,
	handlePlatformsLabelOption,
	handlePlatformSelection
}) => (
	<div className="filters">
		<div className="dropdown-filters">
			{!fromLocations && (
				<SelectFilter
					options={associatedBrandLocations.items || []}
					placeholder="Location"
					currValue={selectedLocation}
					setFilter={setFilter}
					labelKey="name"
					valueKey="id"
					field="locationId"
				/>
			)}
			{fromLocations && (
				<div className="platform-selector-container">
					<SelectFilterCustom
						options={bizPlatforms}
						field="platforms"
						currValue={selectedPlatform || null}
						setFilter={(f, value) => handlePlatformSelection(value)}
						labelKey={"platformName"}
						valueKey={"id"}
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						renderLabel={handlePlatformsLabelOption}
						renderOptions={handlePlatformsLabelOption}
						placeholder="Select platform"
						classes="brand-selector"
					/>
				</div>
			)}
			<SelectFilter
				placeholder="Status"
				options={[
					{
						value: false,
						valueForDisplay: "Unavailable"
					},
					{
						value: true,
						valueForDisplay: "Available"
					}
				]}
				currValue={isAvailable}
				setFilter={setFilter}
				labelKey="valueForDisplay"
				valueKey="value"
				field="status"
				isClearable={false}
				classes="status-selector"
			/>
		</div>
		<div className="search-inspect-filter">
			<div className={"search-container"}>
				{isInternalUser && (
					<SelectFilter
						placeholder="Search Type"
						options={SEARCH_KEY_TYPES}
						currValue={searchKey}
						setFilter={setFilter}
						labelKey="valueForDisplay"
						valueKey="value"
						field="searchKey"
						isClearable={false}
						classes="search-type-selector"
					/>
				)}
				<SearchFilter
					placeholder="Search"
					value={searchQuery}
					setFilter={setFilter}
					filterOption={{ field: "itemName" }}
				/>
			</div>
			{isInternalUser && (
				<Button
					type="secondary"
					clickHandler={handleIlpaLinkVisibility}
					classes={"at-btn-inspect " + (isIlpaLinkVisible ? " inspect-mode" : "")}
				>
					<span>
						<ButtonIcon icon="code" />
					</span>
				</Button>
			)}
		</div>
	</div>
);

const CategoryNavigation = OutsideClick(
	({ catNavOpen, setCatNav, handleCategoryNavigation, rootCategories, selectedCat, nodeRef }) => {
		return (
			<div ref={nodeRef} className="category-navigation" onClick={() => setCatNav(!catNavOpen)}>
				<img src="/assets/icons/icon-catagory-navigation.svg" />
				<span>Categories</span>
				{catNavOpen && (
					<div className="categories-list" onClick={(e) => e.preventDefault()}>
						{rootCategories
							.filter((category) => category.items.length !== 0)
							.map((category, i) => (
								<div
									className="category-list-item"
									data-active={category.name === selectedCat ? true : false}
									key={i}
									onClick={() => handleCategoryNavigation(category.name)}
								>
									{category.name}
								</div>
							))}
					</div>
				)}
			</div>
		);
	}
);
