import React from "react";

export default React.memo(() => (
	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
		<path
			d="M0.333984 0.666016V3.33268H13.6673V0.666016H0.333984ZM2.33398 1.33268C2.70198 1.33268 3.00065 1.63135 3.00065 1.99935C3.00065 2.36735 2.70198 2.66602 2.33398 2.66602C1.96598 2.66602 1.66732 2.36735 1.66732 1.99935C1.66732 1.63135 1.96598 1.33268 2.33398 1.33268ZM0.333984 4.66602V7.33268H13.6673V4.66602H0.333984ZM2.33398 5.33268C2.70198 5.33268 3.00065 5.63135 3.00065 5.99935C3.00065 6.36735 2.70198 6.66602 2.33398 6.66602C1.96598 6.66602 1.66732 6.36735 1.66732 5.99935C1.66732 5.63135 1.96598 5.33268 2.33398 5.33268ZM0.333984 8.66602V11.3327H13.6673V8.66602H0.333984ZM2.33398 9.33268C2.70198 9.33268 3.00065 9.63135 3.00065 9.99935C3.00065 10.3673 2.70198 10.666 2.33398 10.666C1.96598 10.666 1.66732 10.3673 1.66732 9.99935C1.66732 9.63135 1.96598 9.33268 2.33398 9.33268Z"
			fill="#222222"
		/>
	</svg>
));
