import React from "react";

// components
import { SearchFilter } from "../_commons/SearchFilter";

const Header = ({
	title = "",
	description = "",
	showSearchFilter = false,
	currSearchFilter = "",
	handleSearch,
	loading = false
}) => {
	if (!title && loading) {
		return (
			<div className="section-header-container">
				<div className="left-header">
					<div className="section-title">
						<div className="title-shimmer W(300px)">
							<div className="shimmer H(30px)" />
						</div>
					</div>
					<div className="section-description">
						<div className="subtitle-shimmer W(600px) Pb(5px) Pt(5px)">
							<div className="shimmer H(20px)" />
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="section-header-container">
			<div className="left-header">
				<div className="section-title">{title}</div>
				{description && <div className="section-description">{description}</div>}
			</div>
			<div className="right-header">
				{showSearchFilter && (
					<SearchFilter
						filterOption={{ field: "currSearchFilter" }}
						value={currSearchFilter}
						setFilter={(field, value) => handleSearch(field, value.replace(/[\\+*()?]/g, ""))}
						placeholder="Search"
					/>
				)}
			</div>
		</div>
	);
};
export default Header;
