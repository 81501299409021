import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { CheckBox } from "../_commons/CheckBox";

const BasicInfo = ({ data = {}, handleForm, validations, isDeleted = false, readOnly = true, createGroup = false }) => {
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<InputWithLabel
					value={data.title}
					onChange={(e) => handleForm("title", e.target.value)}
					validationMessage={validations.title || ""}
					requiredLabel={true}
					readOnly={data.includesAll || isDeleted || readOnly}
				>
					Name
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={data.includesAll ? "System generated group. Contains all items." : data.description}
					onChange={(e) => handleForm("description", e.target.value)}
					readOnly={data.includesAll || isDeleted || readOnly}
				>
					Description
				</Textarea>
			</div>
			{(data.isUserGenerated || createGroup) && (
				<div className="form-row">
					<CheckBox
						checked={data.isUsedInDataFilter}
						title={"Use in data filters"}
						clickHandler={() => handleForm("isUsedInDataFilter", !data.isUsedInDataFilter)}
					>
						<div className="subtitle">Use in data filters</div>
					</CheckBox>
				</div>
			)}
		</React.Fragment>
	);
};
export default BasicInfo;
