// clients
import { client } from "../client";
import { store } from "../store/configureStore";
import moment from "moment";
import { toggleGlobalLoader } from "../actions/actions";
// graphql
import { INVOICES_LIST_VIEW, INVOICE_DETAIL_VIEW, INVOICES_TOTAL_OUTSTANDING } from "../graphql/invoices";

// actions
import { ActionTypes } from "./_types";

export const fetchInvoicesListView = async (props, fetchPolicy = "network-only") => {
	store.dispatch({
		type: ActionTypes.GET_INVOICES_LIST_VIEW_REQUEST
	});
	store.dispatch(toggleGlobalLoader(true));
	try {
		const { limit, offset } = props;
		const variables = {
			limit,
			offset
		};
		const resp = await client.query({
			query: INVOICES_LIST_VIEW,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_INVOICES_LIST_VIEW_SUCCESS,
			payload: resp.data.invoices
		});
		store.dispatch(toggleGlobalLoader(false));
	} catch (error) {
		console.log(error);
		store.dispatch(toggleGlobalLoader(false));
		store.dispatch({
			type: ActionTypes.GET_INVOICES_LIST_VIEW_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchInvoiceDetailView = async (props, fetchPolicy = "network-only") => {
	store.dispatch({
		type: ActionTypes.GET_INVOICE_DETAIL_VIEW_REQUEST
	});
	try {
		const { id } = props;
		const variables = {
			id
		};
		const resp = await client.query({
			query: INVOICE_DETAIL_VIEW,
			variables,
			fetchPolicy
		});
		let invoiceData = { ...resp.data.invoice, bizInfo: resp.data.biz };
		store.dispatch({
			type: ActionTypes.GET_INVOICE_DETAIL_VIEW_SUCCESS,
			payload: invoiceData
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_INVOICE_DETAIL_VIEW_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};
