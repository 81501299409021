import React, { useEffect, useState, useCallback } from "react";

// third party
import { connect } from "react-redux";
import { useSpring, animated as a } from "react-spring";

// actions
import { ActionTypes } from "../../actions/_types";

// graphql
import { GET_LOCATION_CATALOGUE } from "../../graphql/locations";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { printCurrency } from "../../atlas-utils";

// components
import { Button } from "../_commons/Button";
import { LoadingShimmer } from "../_commons/LoadingShimmer";

// constants
import { FOOD_TYPE_MAP } from "../../client-config";
import { marsDataSample } from "./mars";

const FOOD_TYPE = {
	1: "VEGETARIAN",
	2: "NON_VEGETARIAN",
	3: "EGGETARIAN",
	4: "N/A"
};

const CataloguePulled = ({
	handleSelectedTabUpdate,
	setStep,
	copyLocation,
	catalogueSettingMode,
	currencySymbol,
	merakiEnabled
}) => {
	const [loading, setLoading] = useState(false);
	const [rootCategories, setRootCategories] = useState([]);

	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});
	const categoryBasedItemMaps = {};

	marsDataSample.data[0].items.forEach((item) => {
		item.category_ref_ids.forEach((ref_id) => {
			categoryBasedItemMaps[ref_id] = [...(categoryBasedItemMaps[ref_id] ?? []), { ...item }];
		});
	});

	const categoryArraysWithItems = [];

	marsDataSample.data[0].categories.forEach((category) =>
		categoryArraysWithItems.push({ ...category, items: [...(categoryBasedItemMaps[category.ref_id] ?? [])] })
	);

	const fetchCatalogueDataFromExistingLocation = async () => {
		try {
			setLoading(true);
			if (copyLocation.id) {
				setLoading(true);
				const variables = {
					id: parseInt(copyLocation?.id),
					available: true
				};
				const resp = await client.query({
					query: GET_LOCATION_CATALOGUE,
					variables,
					fetchPolicy: "no-cache"
				});
				let rootCategories = resp.data.store.catalogue.categories;
				setRootCategories(rootCategories);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};
	useEffect(() => {
		if (catalogueSettingMode === "pullCatalogue") {
			setRootCategories(
				categoryArraysWithItems.map((category) => ({
					name: category.name,
					sortOrder: category.sortOrder,
					items: category.items.map((item) => ({
						id: 390976,
						itemTitle: item.title,
						itemDesc: item.description,
						imageUrl: item.img_url,
						foodType: FOOD_TYPE[item.food_type],
						itemPrice: item.price,
						isRecommended: item.recommended,
						available: item.active
					}))
				}))
			);
		} else if (catalogueSettingMode === "copyCatalogue") {
			fetchCatalogueDataFromExistingLocation();
		}
		return () => {
			setStep(1);
		};
	}, [copyLocation, catalogueSettingMode]);

	return (
		<a.div className="common-box-structure " style={contentProps}>
			<div className="header">
				Here's the catalogue we pulled
				<div className="sub-header">Note: You can make changes in catalogue later in locations</div>
			</div>
			<div className="catalogue-details">
				{loading ? (
					<div className="shimmer-width">
						<LoadingShimmer />
					</div>
				) : catalogueSettingMode === "pullCatalogue" ? (
					<div className="catalogue-list" id="scrollbar-catalogue-details">
						{rootCategories.map((category, i) => (
							<LocationCategory
								key={i}
								itemType={catalogueSettingMode === "pullCatalogue" ? "row" : i === 0 ? "grid" : "row"}
								currencySymbol={currencySymbol}
								{...category}
							/>
						))}
					</div>
				) : copyLocation ? (
					rootCategories.length !== 0 ? (
						<div className="catalogue-list" id="scrollbar-catalogue-details">
							{rootCategories.map((category, i) => (
								<LocationCategory
									key={i}
									itemType={"row"}
									currencySymbol={currencySymbol}
									{...category}
								/>
							))}
						</div>
					) : (
						<div className="empty-catalogue-message">No items found in this catalogue!</div>
					)
				) : (
					<div className="no-location-selected">No location selected!</div>
				)}
			</div>
			<div className="button-div">
				<Button clickHandler={() => setStep(1)} type="secondary">
					<span>Previous</span>
				</Button>
				<Button clickHandler={() => handleSelectedTabUpdate(6)}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
export default connect(mapStateToProps)(CataloguePulled);

const LocationCategory = ({
	itemType,
	name,
	items = [],
	subcategories = [],
	handleNestedEntity,
	parentCategories = [],
	handleItemCustomization,
	currencySymbol,
	readOnly,
	inspectMode,
	filters = [],
	ilpaId,
	ilpActionLogs
}) => {
	const renderCategoryName = useCallback(() => {
		if (parentCategories.length === 0) {
			return <span className="root-category">{name}</span>;
		}
		return (
			<React.Fragment>
				{parentCategories.map((category, i) => (
					<React.Fragment key={i}>
						<span className={i === 0 ? "root-category" : ""}>{category}</span>
						<img src="/assets/icons/icon-right-arrow.svg" />
					</React.Fragment>
				))}
				<span>{name}</span>
			</React.Fragment>
		);
	}, [parentCategories, name]);

	return (
		<React.Fragment>
			<div
				data-cat={name
					.split(" ")
					.join("_")
					.replace(/[^a-zA-Z0-9_-]+/g, "")}
				className="location-catalogue-category"
			>
				<div className="name">{renderCategoryName()}</div>
				<div className="item-count">{items.length} Item(s)</div>
				{items.length > 0 && (
					<LocationItems
						items={items}
						handleNestedEntity={handleNestedEntity}
						handleItemCustomization={handleItemCustomization}
						currencySymbol={currencySymbol}
						readOnly={readOnly}
						inspectMode={inspectMode}
						filters={filters}
						type={itemType}
						ilpaId={ilpaId}
						ilpActionLogs={ilpActionLogs}
					/>
				)}
			</div>
			{subcategories.map((category, i) => (
				<LocationCategory
					key={i}
					handleNestedEntity={handleNestedEntity}
					parentCategories={parentCategories.concat(name)}
					handleItemCustomization={handleItemCustomization}
					currencySymbol={currencySymbol}
					readOnly={readOnly}
					inspectMode={inspectMode}
					filters={filters}
					ilpaId={ilpaId}
					ilpActionLogs={ilpActionLogs}
					{...category}
				/>
			))}
		</React.Fragment>
	);
};

const LocationItems = ({
	type = "row",
	items = [],
	handleNestedEntity,
	handleItemCustomization,
	currencySymbol,
	readOnly,
	inspectMode,
	filters = [],
	ilpaId,
	ilpActionLogs
}) => (
	<div className="common-table-container location-category-items-table-container">
		<div className="transactions-list-table">
			<div className={`at-table-row-based ${type === "grid" ? "item-grid" : "item-row"}`}>
				{items.map((item, i) => (
					<Item
						key={i}
						handleNestedEntity={handleNestedEntity}
						handleItemCustomization={handleItemCustomization}
						currencySymbol={currencySymbol}
						readOnly={readOnly}
						inspectMode={inspectMode}
						filters={filters}
						ilpaId={ilpaId}
						ilpActionLogs={ilpActionLogs}
						{...item}
					/>
				))}
			</div>
		</div>
	</div>
);

const Item = ({
	id,
	itemTitle,
	itemDesc = "",
	imageUrl,
	foodType,
	itemPrice,
	markupPrice,
	isRecommended,
	available = true,
	locationPlatforms = [],
	handleNestedEntity,
	handleItemCustomization,
	currencySymbol,
	readOnly,
	inspectMode,
	filters = [],
	ilpaId,
	ilpActionLogs = {}
}) => {
	return (
		<div data-is-available={available} className="at-table-row transaction-rows">
			<div className="at-table-cell at-cell-text items" title={itemTitle}>
				<div className="item-image">
					<img
						src={imageUrl || "/assets/empty-dish.jpg"}
						alt="item image"
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
					/>
				</div>
				<div className="item-desc">
					<a
						role="button"
						className="link-text"
						// onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[1], id)}
					>
						<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
						<span>{itemTitle || id}</span>
					</a>
					{isRecommended && <img className="item-recommended" src="/assets/icons/star.svg" />}
					<div className="text--light desc-text" title={itemDesc}>
						{itemDesc.length > 50 ? itemDesc.slice(0, 50) + "..." : itemDesc}
					</div>
				</div>
				<div className="item-price">
					{markupPrice > itemPrice && (
						<div className="markup-price">
							{printCurrency(currencySymbol)}
							{markupPrice | 0}
						</div>
					)}
					<div>
						{printCurrency(currencySymbol)}
						{itemPrice | 0}
					</div>
				</div>
				{/* <div className="platform-images">
				{
					locationPlatforms.map((platform, i) => (
						<AssociatedPlatform key={i} filters={filters} {...platform} />
					))
				}
				</div> */}
			</div>
			{/* {
				readOnly &&
				<div className="at-table-cell at-cell-text customize">
					<div onClick={(() => handleItemCustomization(id))}>
						<img className="action-item" src="/assets/icons/icon-edit-dark.svg" />
					</div>
				</div>
			} */}
			{/* {
				inspectMode &&
				<div className="inspect-mode-container">
					<div className="header">ILPA IDs:</div>
					<div className="platforms-container">
						{
							locationPlatforms.map((platform, i) => (
								platform.isEnabled && platform.isItemAssociated && (!filters.length || filters.map((plf) => plf.platformName).includes(platform.platformName)) &&
								<div className="platform-ilpa-info" key={i}>
									<div className={ilpaId && String(platform.ilpaId).match(new RegExp(ilpaId, 'g')) !== null ? "highlight" : ""}>
										{`${platform.platformName}: ${platform.ilpaId}`}
									</div>
									<div className="action-timestamp">
										{
											ilpActionLogs[platform.ilpaId] ?
											<React.Fragment>
												{ILP_ACTION_MAP[ilpActionLogs[platform.ilpaId].action]}: {moment(ilpActionLogs[platform.ilpaId].created.split(' ').join('T')).format('DD MMM, YYYY hh:mm A')}
											</React.Fragment>
											:
											''
										}
									</div>
								</div>
							))
						}
						{
							locationPlatforms.length === 0 &&
							<div className="platform-ilpa-info">N/A</div>
						}
					</div>
				</div>
			} */}
		</div>
	);
};
