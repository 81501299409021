// utils
import { getSortOrder } from "../atlas-utils";

// action types
import { ActionTypes } from "../actions/_types";

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "",
		order: ""
	},
	sortedField: "",
	currentFilters: {},
	appliedFilters: {}
};
export const catalogueVerificationListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const ITEMS_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: "",
		status: "",
		formTabs: [
			{
				label: "Errors",
				value: "error",
				count: 0
			},
			{
				label: "Warnings",
				value: "warning",
				count: 0
			}
		],
		selectedTab: "error",
		location: {},
		platform: "",
		action: ""
	},
	error: false
};
export const catalogueVerificationList = (state = ITEMS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CATALOGUE_VERIFICATION_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CATALOGUE_VERIFICATION_LIST_SUCCESS:
			let payload = {};
			if (action.isPrismEnabled) {
				if (Object.keys(action.payload).length > 0) {
					payload = {
						...action.payload.report,
						status: action.payload.status,
						referenceId: action.payload.referenceId,
						searchFieldSelected:
							(action.payload.report.searchKeywords.length && action.payload.report.searchKeywords[0]) ||
							null,
						formTabs: [
							{
								label: "Errors",
								value: "error",
								count: action.payload?.report?.errorCount || 0
							},
							{
								label: "Warnings",
								value: "warning",
								count: action.payload?.report?.warningCount || 0
							}
						]
					};
				} else {
					payload = {
						objects: [],
						count: 0,
						errorCount: 0,
						warningCount: 0,
						status: "error",
						referenceId: "",
						formTabs: [
							{
								label: "Errors",
								value: "error",
								count: 0
							},
							{
								label: "Warnings",
								value: "warning",
								count: 0
							}
						]
					};
				}
			} else {
				payload = {
					...action.payload.entities,
					status: action.payload.overallStatus,
					searchFieldSelected:
						(action.payload.entities.searchKeywords.length && action.payload.entities.searchKeywords[0]) ||
						null,
					formTabs: [
						{
							label: "Errors",
							value: "error",
							count: action.payload.entities.errorCount
						},
						{
							label: "Warnings",
							value: "warning",
							count: action.payload.entities.warningCount
						}
					]
				};
			}
			return {
				...state,
				data: {
					...state.data,
					...payload
				},
				loading: false
			};
		case ActionTypes.GET_CATALOGUE_VERIFICATION_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CATALOGUE_VERIFICATION_LIST:
			let foundInList = false;
			const updatedItems = state.data.objects.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedItems.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedItems,
					count: state.data.count + 1
				}
			};
		case ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		default:
			return state;
	}
};
