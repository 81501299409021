import gql from "graphql-tag";

export const GET_EIS_CONFIG = gql`
	query eisConfig($locationId: Int, $brandId: Int) {
		eisConfig(locationId: $locationId, brandId: $brandId) {
			posName
			keys {
				key
				shortDesc
				value
				title
				readonly
				required
				encrypted
				type
			}
			keysV2 {
				groupKeys {
					key
					shortDesc
					value
					title
					readonly
					required
					encrypted
					type
					__typename
				}
				groupName
			}
		}
	}
`;

export const EDIT_EIS_CONFIG = gql`
	mutation saveEisConfig($input: ConfigurationsInput) {
		saveEisConfig(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
