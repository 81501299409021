// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_CATALOGUE_BACKUPS_LIST,
	GET_CATALOGUE_BACKUP_CONFIG,
	SAVE_CATALOGUE_BACKUP_CONFIG,
	GET_CATALOGUE_BACKUP
} from "../graphql/catalogueBackups";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// constants
const CATALOGUE_BACKUP_STATUS_API_URL = process.env.REACT_APP_CATALOGUE_BACKUP_STATUS;

export const fetchCatalogueBackupsList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().catalogueBackupsListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_REQUEST,
		payload: {
			loading: true
		}
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "by_system" && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		if (filtersObject.length > 0) {
			variables.filters = filtersObject;
		}

		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}

		const resp = await client.query({
			query: GET_CATALOGUE_BACKUPS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_SUCCESS,
			payload: { ...resp.data.catalogBackups },
			loading: "loading"
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchManualBackupsList = async () => {
	try {
		const variables = {
			limit: 10,
			offset: 0,
			sort: {
				field: "created",
				order: "DESC"
			},
			filters: [
				{
					field: "by_system",
					value: false
				}
			]
		};
		const resp = await client.query({
			query: GET_CATALOGUE_BACKUPS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		return resp.data.catalogBackups;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueBackupConfig = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_REQUEST,
		payload: {
			configLoading: true
		}
	});
	try {
		const variables = {
			keys: ["enable_auto_catalog_backup"]
		};
		const resp = await client.query({
			query: GET_CATALOGUE_BACKUP_CONFIG,
			variables,
			fetchPolicy: "no-cache"
		});
		let configurationValues = {};
		resp.data.configurationValues.forEach((config) => {
			configurationValues[config.key] = config.value === "1" ? true : false;
		});
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_SUCCESS,
			payload: {
				catalogueBackupConfig: configurationValues
			},
			loading: "configLoading"
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_FAILURE,
			error
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const updateCatalogueBackupConfig = async () => {
	const {
		data: { catalogueBackupConfig }
	} = store.getState().catalogueBackupsList;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_REQUEST,
		payload: {
			configLoading: true
		}
	});
	try {
		const resp = await client.mutate({
			mutation: SAVE_CATALOGUE_BACKUP_CONFIG,
			variables: {
				configurations: Object.keys(catalogueBackupConfig).map((config) => ({
					key: config,
					value: catalogueBackupConfig[config] ? "1" : "0"
				}))
			}
		});
		if (resp.data.saveConfigurationValues.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Catalogue backups configuration saved successfully",
					timeout: 3000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_SUCCESS,
				payload: {},
				loading: "configLoading"
			});
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				error: {
					message: "There was an error while saving catalogue backups configuration",
					timeout: 3000,
					error: true
				}
			});
			store.dispatch({
				type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_FAILURE,
				error: true
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_BACKUPS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchCatalogueBackupDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_CATALOGUE_BACKUP_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_CATALOGUE_BACKUP,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_CATALOGUE_BACKUP_DETAIL_SUCCESS,
			payload: resp.data.catalogBackup
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_CATALOGUE_BACKUP_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching backup details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueBackupsStatus = async (action = undefined) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const url = CATALOGUE_BACKUP_STATUS_API_URL;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await resp.json();
		if (data.status === null) {
			store.dispatch({
				type: ActionTypes.UPDATE_CATALOGUE_BACKUPS_STATUS,
				payload: {
					...data,
					restrict: false
				}
			});
			if (action) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: `Backup ${action}d successfully.`,
						timeout: 3000,
						error: false
					}
				});
			}
			store.dispatch(toggleGlobalLoader(false));
			return { ...data, inProcess: false };
		} else if (data.status !== null) {
			store.dispatch({
				type: ActionTypes.UPDATE_CATALOGUE_BACKUPS_STATUS,
				payload: {
					...data,
					restrict: true
				}
			});
			store.dispatch(toggleGlobalLoader(false));
			return { ...data, inProcess: true };
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Something went wrong. Unable to fetch backup status.",
				timeout: 3000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
