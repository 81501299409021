import React from "react";

const Item = ({ styles = {}, variations = {} }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			style={{ ...styles }}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.5 4.5L4.5 22C4.5 22 11.2125 21.514 16 25C20.7875 21.514 27.5 22 27.5 22L27.5 4.5C19.159 4.5 16 7 16 7C16 7 12.841 4.5 4.5 4.5ZM3.5 7.5C2.189 7.8565 1.5 8.5 1.5 8.5L1.5 27C1.5 27 6.7455 23.891 16 27.5C25.2545 23.891 30.5 27 30.5 27L30.5 8.5C30.5 8.5 29.811 7.8565 28.5 7.5L28.5 24C22.5735 22.2395 16 26 16 26C16 26 9.4265 22.2395 3.5 24L3.5 7.5ZM7.22461 8.03027L7.60352 8.08594L7.60352 10.9307L7.9834 10.9873L8.17285 8.16992L8.55273 8.22559L8.74219 11.0986L9.12109 11.1543L9.12109 8.30957L9.50098 8.36523C9.50098 8.36523 9.88086 10.3183 9.88086 12.4043C9.88086 12.4043 9.36384 12.7142 8.75684 12.7607L8.93164 19.0918C8.93164 19.0918 8.7418 19.2533 8.36231 19.1973C7.9828 19.1413 7.79395 18.9238 7.79395 18.9238L7.96875 12.6445C7.36175 12.42 6.84473 11.957 6.84473 11.957C6.84473 9.87103 7.22461 8.03027 7.22461 8.03027ZM24.5 8.50977L24.5 10.0059C21.6385 10.0704 19.3305 11.2078 18.5 11.6758L18.5 9.98145C19.7485 9.37445 21.9045 8.56077 24.5 8.50977ZM13.1201 8.91113L13.5 9.2002L13.5 19.8203C13.5 19.8203 13.3102 19.9608 12.9307 19.8613C12.5512 19.7618 12.3623 19.5225 12.3623 19.5225L12.6855 15.2412C12.136 15.0617 11.6035 14.3916 11.6035 14.3916C11.6035 14.3916 11.6031 11.5471 13.1201 8.91113ZM23.5 12.5664L23.5 14.0713C21.109 14.3323 19.2315 15.2633 18.5 15.6758L18.5 13.9814C19.5845 13.4544 21.3545 12.7719 23.5 12.5664ZM24.5 16.5098L24.5 18.0059C21.6385 18.0704 19.3305 19.2078 18.5 19.6758L18.5 17.9814C19.7485 17.3744 21.9045 16.5608 24.5 16.5098Z"
				fill="#363636"
				{...variations}
			/>
		</svg>
	);
};

export default React.memo(Item);
