import { ResponsiveBar } from "@nivo/bar";
import React, { memo, useCallback, useState } from "react";
import { MONTHS } from "../../../atlas-utils";
import { getColorByStatus } from "../../../helpers/periscope";
import TooltipWrapper from "./TooltipViewport";
import { throttle } from "lodash";

const GraphPeriscope = ({ otherParamters, modified, keys }) => {
	const [tooltipPosition, setTooltipPosition] = useState([0, 0]);
	const handleMouseMove = useCallback(
		throttle((event) => {
			if (
				event?.clientX &&
				event?.clientY &&
				tooltipPosition[0] !== event?.clientX &&
				tooltipPosition[1] !== event?.clientY
			) {
				setTooltipPosition([event.clientX, event.clientY]);
			}
		}, 500),
		[]
	);

	const handleMouseEnter = (data, event) => {
		const clientX = event?.clientX;
		const clientY = event?.clientY;
		if (clientX && clientY) {
			setTooltipPosition([clientX, clientY]);
		}
	};

	const handleMouseLeave = () => {
		setTooltipPosition([0, 0]);
	};

	const getColorForSidebar = (status) => {
		switch (status) {
			case "online":
				return "#0DA125";
			case "online (Storefront Offline)":
				return "#7ac183";
			case "offline":
				return "#D64949";
			case "removed":
				return "#8A8A8A";
			case "removed (Storefront Offline)":
				return "#f18989";
			case "unavailable":
				return "#8A8A8b";
			default:
				return "#8A8A8b";
		}
	};
	const CustomTooltip = ({ id, value, data }) => {
		const localId = id + "_data";
		const dateArray = (data?.[localId]?.day || "").split("-") || [];
		let year = "";
		let month = "";
		let day = "";

		if (dateArray.length > 0) {
			year = dateArray[0];
			month =
				Number(dateArray[1] - 1) >= 0 && Number(dateArray[1] - 1) <= 12 ? MONTHS[Number(dateArray[1] - 1)] : "";
			day = Number(dateArray[2]);
		}

		const dateStr = `${day} ${month} ${year}, ${data?.[localId]?.duration}`;

		return (
			<div className="custom-tooltip">
				<div
					className="custom-div"
					style={{
						background: id ? getColorForSidebar(data[`${id}_data`]?.["status"]) : ""
					}}
				></div>
				<div className="combine-data">
					<div className="date-str">{dateStr}</div>
					<div
						className="col-status"
						style={{
							background: id ? getColorByStatus(data[`${id}_data`]?.["status"]) : "",
							color: id ? getColorForSidebar(data[`${id}_data`]?.["status"]) : "",
							border: data[`${id}_data`]?.["status"] == "out_of_time_range" ? "1px solid #F5F5F5" : "none"
						}}
					>
						{id && data[`${id}_data`] ? (
							data[`${id}_data`]?.["status"] == "out_of_time_range" ? (
								<span style={{ textTransform: "none" }}>Out of time range</span>
							) : (
								data[`${id}_data`]?.["status"]
							)
						) : (
							""
						)}
					</div>
					<div>
						<div className="duration">Duration</div>
						<div className="duration-val">
							{id && data[`${id}_data`] && data[`${id}_data`]?.["totalTime"]}
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div
			className="main-graph"
			onMouseEnter={handleMouseEnter}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
		>
			<ResponsiveBar
				borderWidth={0.05}
				borderColor={(bar) => {
					if (bar.color.toLowerCase() == "#ffffff".toLowerCase()) {
						return "#8A8A8A";
					}
				}}
				animate={true}
				isInteractive={true}
				data={modified}
				{...otherParamters}
				keys={keys}
				colors={({ id, data }) => data[`${id}_color`] || "#FFFFFF"}
				indexBy="country"
				margin={{ top: 0, right: modified > 14 ? 20 : 0, bottom: 50, left: 0 }}
				pixelRatio={2}
				padding={0.1}
				innerPadding={0.6}
				minValue="auto"
				maxValue="auto"
				groupMode="stacked"
				layout="vertical"
				borderRadius={0}
				axisTop={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legendOffset: 0,
					truncateTickAt: 0,
					format: (data) => `"  ${data}`
				}}
				axisRight={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					tickValues: []
				}}
				axisBottom={{
					tickValues: []
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0
				}}
				enableGridX={false}
				enableGridY={false}
				enableLabel={false}
				enableTotals={false}
				totalsOffset={0}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: "color",
					modifiers: [["darker", 1.6]]
				}}
				labelPosition="middle"
				labelOffset={0}
				legends={[]}
				tooltip={({ id, value, color, data }) => (
					<TooltipWrapper position={tooltipPosition} length={modified.length} name={"graph-activity-logs"}>
						<CustomTooltip id={id} value={value} color={color} data={data} />
					</TooltipWrapper>
				)}
				motionConfig="wobbly"
			/>
		</div>
	);
};

export default memo(GraphPeriscope);
