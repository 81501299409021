import React, { useEffect, useRef } from "react";

// third party
import { Transition, config, animated } from "react-spring/renderprops";

// component
import { connect } from "react-redux";

// client
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
const TYPE_BASED_ICON_SOURCE = {
	info: "/assets/icons/info.png",
	success: "/assets/icons/icon-check.svg",
	warning: "/assets/icons/alert.svg",
	error: "/assets/icons/icon-cross.svg"
};

const GlobalNotifications = ({ notificationsContent }) => {
	useEffect(() => {
		let allShowed = notificationsContent.every((content) => !content.show);
		if (allShowed && notificationsContent.length !== 0) {
			setTimeout(() => {
				store.dispatch({
					type: ActionTypes.REFRESH_NOTIFICATIONS_CONTENT
				});
			}, 100);
		}
	}, [notificationsContent]);

	const close = (id) => {
		store.dispatch({
			type: ActionTypes.FLUSH_NOTIFICATIONS,
			payload: {
				id
			}
		});
	};

	const childComponent = () =>
		notificationsContent.map((content, index) => (
			<NotificationContent
				key={index}
				id={content.id}
				title={content.title}
				message={content.message}
				type={content.type}
				timeout={content.timeout}
				isClosable={content.isClosable}
				index={index}
				show={content.show}
				close={close}
				theme={content.theme}
			/>
		));

	return <div className="global-notifications-container">{childComponent()}</div>;
};
const mapStateToProps = (state) => ({
	notificationsContent: state.globalNotificationsState.notificationsContent
});
export default connect(mapStateToProps)(GlobalNotifications);

const NotificationContent = ({ id, message, type, timeout = 3000, isClosable = true, show, close, title, theme }) => {
	const timer = useRef(null);

	const handleClose = (id) => {
		clearTimeout(timer.current);
		close(id);
	};

	useEffect(() => {
		timer.current = setTimeout(() => {
			store.dispatch({
				type: ActionTypes.FLUSH_NOTIFICATIONS,
				payload: {
					id
				}
			});
		}, timeout);

		return () => clearInterval(timer.current);
	}, []);

	const handleMouseEnter = () => {
		if (timer.current) {
			clearInterval(timer.current);
		}
	};

	const handleMouseLeave = () => {
		timer.current = setTimeout(() => {
			store.dispatch({
				type: ActionTypes.FLUSH_NOTIFICATIONS,
				payload: {
					id
				}
			});
		}, 2000);
	};

	return (
		<Transition
			native
			from={{ height: 0, transform: "translate(400px, 0)" }}
			enter={{ height: "auto", transform: "translate(0, 0)" }}
			leave={{ height: 0, transform: "translate(400px, 0)" }}
			items={show}
			config={{ ...config.stiff, ...{ duration: 200 } }}
		>
			{(isOpen) =>
				isOpen &&
				((props) => (
					<animated.div style={{ ...props }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
						<div className={`notification-content ${theme === "dark" ? "notification-content--dark" : ""}`}>
							<NotificationIcon type={type} />
							<div>
								<NotificationHeader
									title={title}
									isClosable={isClosable}
									handleClose={handleClose}
									id={id}
								/>
								<NotificationBody message={message} />
							</div>
							{isClosable && (
								<div onClick={() => handleClose(id)} className="close-icon-container">
									<img
										src={
											theme === "dark"
												? "/assets/icons/cancel-light.svg"
												: "/assets/icons/cancel.png"
										}
										alt="close icon"
										className="close-icon"
									/>
								</div>
							)}
						</div>
					</animated.div>
				))
			}
		</Transition>
	);
};

const NotificationIcon = ({ type }) => (
	<div className="icon">
		<img src={TYPE_BASED_ICON_SOURCE[type]} alt={type} className="type-img" />
	</div>
);
const NotificationHeader = ({ title }) => (title ? <div className="header">{title}</div> : null);

const NotificationBody = ({ message = "" }) => (
	<div className="notification-body">
		<div className="message">{message}</div>
	</div>
);
