import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ENTITY_ITEM_TAGS, UPDATE_ENTITY_ITEM_TAGS } from "../../graphql/itemTagEntityAssociation";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../../client-config";
const FILTER_INITIAL_STATE = {
	title: "",
	crm_title: ""
};
const ITEM_TAGS_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const ItemTagEntityAssociations = ({ entityType, entityId, isOpen, close }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [itemTagsData, setItemTagsData] = useState(ITEM_TAGS_INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [itemTagsUpdates, setItemTagsUpdates] = useState({});
	const tableRef = useRef();
	const totalChanges = Object.keys(itemTagsUpdates).length;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				entityId,
				entityType: entityType.value,
				limit,
				offset,
				sort: {
					field: "title",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_ENTITY_ITEM_TAGS,
				variables,
				fetchPolicy: "no-cache"
			});
			setItemTagsData(resp.data.entityItemTagsAssociations);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [entityId, entityType, limit, offset, appliedFilters]);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const syncData = useCallback(() => {
		setItemTagsData({
			...itemTagsData,
			objects: itemTagsData.objects.map((loc) => {
				if (itemTagsUpdates[loc.id] !== undefined) {
					return {
						...loc,
						isAssociated: itemTagsUpdates[loc.id]
					};
				}
				return loc;
			})
		});
		setItemTagsUpdates({});
	}, [itemTagsData, itemTagsUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setItemTagsData(ITEM_TAGS_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setItemTagsUpdates({});
			setIsCheckedAll(false);
			close(refresh);
		},
		[close]
	);

	const handleUpdate = useCallback(async () => {
		const eventMeta = {};
		try {
			setConfirmLoading(true);
			const variables = {
				tagGroupId: entityId,
				tagsToAssociate: [],
				tagsToDisassociate: []
			};
			for (let id in itemTagsUpdates) {
				if (itemTagsUpdates[id]) {
					variables.tagsToAssociate.push(parseInt(id));
				} else {
					variables.tagsToDisassociate.push(parseInt(id));
				}
			}

			eventMeta.change_in_asscoiation = variables.tagsToAssociate.length - variables.tagsToDisassociate.length;

			const resp = await client.mutate({
				mutation: UPDATE_ENTITY_ITEM_TAGS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateItemTagGroupTags.status.success) {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.TAG_GROUP_TAG_ASSOCIATION, eventMeta);

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Tags association updated!",
						timeout: 2000,
						error: false
					}
				});
				syncData();
				handleClose(true);
			} else {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.TAG_GROUP_TAG_ASSOCIATION, eventMeta);

				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateItemTagGroupTags.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.TAG_GROUP_TAG_ASSOCIATION, eventMeta);

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [entityId, entityType, itemTagsUpdates, fetchData, syncData, handleClose]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setItemTagsUpdates({
			...itemTagsUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		itemTagsData.objects.forEach((loc) => {
			updates[loc.id] = toCheckAll;
		});
		setItemTagsUpdates({
			...itemTagsUpdates,
			...updates
		});
	};

	return (
		<div className="item-tag-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Tags"
				subTitle={`Manage tags association for this ${entityType.label}`}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
						{totalChanges === 0 ? "Update" : `Update ${totalChanges} Tag(s)`}
					</Button>
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-filter-container">
						<InputWithLabel
							value={currFilters.title}
							onChange={(e) => setFilter("title", e.target.value)}
							placeholder="Enter Title"
						>
							Title
						</InputWithLabel>
						<InputWithLabel
							value={currFilters.crm_title}
							onChange={(e) => setFilter("crm_title", e.target.value)}
							placeholder="Enter CRM Title"
						>
							CRM Title
						</InputWithLabel>
					</div>
					<Table
						data={itemTagsData.objects}
						loading={loading}
						handleCheck={handleCheck}
						itemTagsUpdates={itemTagsUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
					/>
					<Paginator limit={limit} offset={offset} count={itemTagsData.count} goToPage={handlePagination} />
				</div>
			</FormSidebar>
		</div>
	);
};
export default ItemTagEntityAssociations;

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	itemTagsUpdates
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container item-tags-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							itemTagsUpdates={itemTagsUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Tags found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text title`}>
			<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Title">
				Title
			</CheckBox>
		</div>
		<div className={`at-table-cell at-table-header at-header-text tag-group`}>Current Tag Group</div>
		<div className={`at-table-cell at-table-header at-header-text num-items`}>Associated Items</div>
	</div>
);

export const TableList = ({
	id,
	title,
	crmTitle,
	tagGroup,
	numItems,
	isAssociated,
	style,
	handleCheck,
	itemTagsUpdates
}) => {
	const status = itemTagsUpdates[id] === undefined ? isAssociated : itemTagsUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text title">
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} title={title || "--"}>
					{title || id}
				</CheckBox>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
			</div>
			<div className="at-table-cell at-cell-text tag-group">{(tagGroup && tagGroup.title) || "General"}</div>
			<div className="at-table-cell at-cell-text num-items">{numItems || 0}</div>
		</animated.div>
	);
};
