import React, { useState, useEffect, useRef, useCallback } from "react";

// third party
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import Compressor from "compressorjs";
import { connect } from "react-redux";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import BasicInfo from "../components/BrandsEdit/BasicInfo";
import Locations from "../components/BrandsEdit/Locations";
import Logs from "../components/BrandsEdit/Logs";
import Catalogue from "../components/BrandsEdit/Catalogue";
import HoverContentDisplayGrid from "../components/_commons/HoverContentDisplayGrid";
import { Modal } from "../components/_commons/Modal";
import PublishCatalogue from "../components/LocationsList/PublishCatalogue";
import StoreToggle from "../components/LocationsList/StoreToggle";
import { CheckBox } from "../components/_commons/CheckBox";
import { Loading } from "../components/_commons/Loading";
import ImageSizeWarningModal from "../components/_commons/ImageSizeWarningModal";
import CropModal from "../components/_commons/CropModal";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// utils
import history from "../history";
import {
	capitaliseText,
	canvasPreview,
	dataURLtoFile,
	generateUniqueId,
	adjustNestedContainer,
	getPrinterCompatibleImages
} from "../atlas-utils";

// actions
import { client } from "../client";
import { GET_BRAND_DETAILS, REMOVE_BRANDS_LOCATIONS, SAVE_BRAND } from "../graphql/brands";
import { store } from "../store/configureStore";
import { ActionTypes } from "../actions/_types";
import { fetchBrandsList } from "../actions/brands";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Locations",
		value: "locations"
	},
	{
		label: "Catalogue",
		value: "catalogue"
	},
	{
		label: "Logs",
		value: "logs"
	}
];

const HOVER_DATA_INIT_STATE = {
	show: false,
	position: { x: 0, y: 0 }
};

const CLICK_MENU_DATA_INIT_STATE = {
	show: false,
	anchorPoint: { x: 0, y: 0 },
	id: null
};

const BRAND_DETAILS_INIT_STATE = {
	basicInfo: {}
};

const MODAL_VIEW_INIT_STATE = {
	isVisible: false,
	type: "",
	isMulti: false,
	id: null,
	data: null
};

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const BrandsEdit = ({ match, access, biz }) => {
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;
	const [isFormOpen, setFormState] = useState(false);
	const [selectedTab, setSelectedTab] = useState(FORM_TABS[0].value);
	const [hoverData, setHoverData] = useState(HOVER_DATA_INIT_STATE);
	const [clickMenuData, setClickMenuData] = useState(CLICK_MENU_DATA_INIT_STATE);
	const formSidebarRef = useRef();
	const [isFormTouched, setFormTouched] = useState(false);
	const [basicInfo, setBasicInfo] = useState({ name: "", image: "" });
	const [brandDetails, setBrandDetails] = useState(BRAND_DETAILS_INIT_STATE);
	const [isLoading, setLoading] = useState(false);
	const [imageBase64, setImageBase64] = useState();
	const [modalView, setModalViewData] = useState(MODAL_VIEW_INIT_STATE);
	const [selectedLocations, setSelectedLocations] = useState({});
	const [file, setFile] = useState();
	const [origImgFile, setOrigImgFile] = useState(null);
	const [isImageSizeLarge, setIsImageSizeLarge] = useState(false);
	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const [isModalOpen, setModalOpen] = useState(false);
	const [imgSrc, setImgSrc] = useState();
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isModalBusy, setModalBusy] = useState(false);
	const aspect = 1;
	const scale = 1;
	const rotate = 0;
	const inputRef = useRef();
	const previewCanvasRef = useRef();
	const imgRef = useRef();
	const nestedRef = useRef();

	const fetchLocationsListRef = useRef();

	const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 90
				},
				aspect,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		);
	};

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}

		fetchBrandsList();
		setFormState(false);
		setTimeout(() => {
			history.push("/brands");
		}, 100);
	};

	const switchTab = (tab) => {
		setSelectedTab(tab.value);
	};
	const onImageLoad = (e) => {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	};

	const handleCropModalClose = () => {
		setModalOpen(false);
		setImgSrc("");
	};

	useEffect(() => {
		if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
			canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
		}
	}, [completedCrop]);

	const fetchBrandDetails = async (id) => {
		const variables = {
			id: parseInt(id)
		};
		try {
			setLoading(true);
			const resp = await client.query({
				query: GET_BRAND_DETAILS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.brand) {
				setBasicInfo((current) => ({
					...current,
					...resp.data.brand
				}));

				setBrandDetails((current) => ({
					...current,
					basicInfo: {
						...resp.data.brand
					}
				}));
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};

	const handleFileSizeAndUpdate = async (file) => {
		if (!file) {
			return;
		}
		setOrigImgFile(file);
		if (file && file.size > 1047520) {
			const result = await new Promise((resolve, reject) => {
				new Compressor(file, {
					quality: 0.6,
					maxWidth: 4000,
					success: resolve,
					error: reject
				});
			});
			if (result && result.size < 1047520) {
				file = result;
			} else {
				setIsImageSizeLarge(true);
				return;
			}
		}
		setFile(file);
		setFormTouched(true);
		setCrop(undefined); // Makes crop preview update between images.
		const reader = new FileReader();
		reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
		reader.readAsDataURL(file);
		setModalOpen(true);
	};

	const onImageChange = (e) => {
		handleFileSizeAndUpdate(e?.target?.files[0]);
	};

	const handleSubmit = async () => {
		if (selectedTab === FORM_TABS[0].value) {
			try {
				setLoading(true);
				const variables = {
					...basicInfo
				};
				if (file && previewCanvasRef.current) {
					variables.image = dataURLtoFile(
						previewCanvasRef.current.toDataURL(),
						generateUniqueId() + "_brand_logo.png"
					);

					// get image formats to support printer compatibility
					const { base64Image, starLineImage, escPosImage } = await getPrinterCompatibleImages(origImgFile);
					if (base64Image && starLineImage && escPosImage) {
						variables.base64Image = base64Image;
						variables.starLineImage = starLineImage;
						variables.escPosImage = escPosImage;
					} else {
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Image size is too large, please keep it below 1 MB",
								timeout: 3000,
								error: true
							}
						});
						setLoading(false);
						return;
					}
				}
				const resp = await client.mutate({
					mutation: SAVE_BRAND,
					variables
				});
				if (resp.data.saveBrand.status.success) {
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Brand details saved",
							timeout: 3000,
							error: false
						}
					});
					setFormTouched(false);
					// previewCanvasRef.current = null;
					fetchBrandDetails(match.params.id);
					// setImgSrc(null)
				} else {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Failed to update!",
							timeout: 5000,
							error: true
						}
					});
				}
				setLoading(false);
			} catch (e) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Failed to update!",
						timeout: 5000,
						error: true
					}
				});
				setLoading(false);
				console.log(e);
			}
		}
	};

	const removeImageData = () => {
		if (imageBase64) {
			setImageBase64(null);
			return;
		}

		if (basicInfo?.image) {
			setBasicInfo((current) => ({
				...current,
				image: ""
			}));
			setFormTouched(true);
		}
	};

	const handleCloseModal = () => {
		setModalViewData(MODAL_VIEW_INIT_STATE);
		try {
			setSelectedLocations({});
			if (fetchLocationsListRef.current.fetchAssociatedLocations) {
				fetchLocationsListRef.current.fetchAssociatedLocations(basicInfo?.id, true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const closeImageSizeWarningModal = () => {
		setIsImageSizeLarge(false);
	};

	const handleImageChange = () => {
		setIsImageSizeLarge(false);
		if (inputRef.current) {
			inputRef.current.click();
		}
	};
	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		fetchBrandDetails(match.params.id);
	}, [match.params.id]);

	return (
		<div className="brand-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title={basicInfo?.name || "Brand"}
				subTitle="Configure your brand, associated locations & catalogue here"
				// hideActions={true}
				isMonogramVisible
				formSidebarRef={formSidebarRef}
				submit={handleSubmit}
				hideActions={selectedTab !== FORM_TABS[0].value || !isFormTouched}
				submitTitle="Save"
				loading={isLoading}
				// isBreadcrumbsEnabled
				headerImageSource={previewCanvasRef?.current?.toDataURL() || imgSrc || basicInfo.image}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={selectedTab}
					switchTab={switchTab}
					isStickyOnTop={true}
					hiddenTabs={!access.isHubManagement ? ["logs"] : []}
				/>
				<div className="brand-details-form-content">
					{selectedTab === FORM_TABS[0].value && (
						<BasicInfo
							basicInfo={basicInfo}
							setBasicInfo={setBasicInfo}
							setFormTouched={setFormTouched}
							inputRef={inputRef}
							imageBase64={imageBase64}
							removeImageData={removeImageData}
							onImageChange={onImageChange}
							previewCanvasRef={previewCanvasRef}
							imgSrc={imgSrc}
							isCropModalOpen={isModalOpen}
							readOnly={!access.isAdmin}
						/>
					)}
					{selectedTab === FORM_TABS[1].value && (
						<Locations
							hoverData={hoverData}
							setHoverData={setHoverData}
							formSidebarRef={formSidebarRef}
							clickMenuData={clickMenuData}
							setClickMenuData={setClickMenuData}
							brandId={basicInfo?.id || ""}
							brandName={basicInfo?.name}
							brandImage={basicInfo?.image || ""}
							setModalViewData={setModalViewData}
							selectedLocations={selectedLocations}
							setSelectedLocations={setSelectedLocations}
							fetchLocationsListRef={fetchLocationsListRef}
							selectedTab={selectedTab}
							image={basicInfo?.image || ""}
							readOnly={!access.isAdmin}
							isMenuOverCatalogueEnabled={isMenuOverCatalogueEnabled}
						/>
					)}
					{selectedTab === FORM_TABS[2].value && (
						<Catalogue
							brandId={basicInfo?.id || ""}
							containerClassName="brand-edit-container"
							handleNestedEntity={handleNestedEntity}
							readOnly={!access.isCatalogueManagement}
						/>
					)}
					{selectedTab === FORM_TABS[3].value && (
						<Logs
							brandId={basicInfo?.id || ""}
							brandName={basicInfo?.name || ""}
							brandImage={basicInfo?.image || ""}
							isMultibrandEnabled={true}
						/>
					)}
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
			{modalView.isVisible && modalView.type === "publish" && (
				<PublishCatalogue
					isOpen
					close={handleCloseModal}
					isPlatformsProvided
					locations={[modalView.id]}
					platforms={modalView?.data[0]?.associatedPlatforms?.associatedPlatforms || []}
				/>
			)}
			{modalView.isVisible && modalView.type === "toggle" && (
				<StoreToggle
					isOpen
					close={handleCloseModal}
					isPlatformsProvided
					locations={[modalView.id]}
					platforms={modalView?.data[0]?.associatedPlatforms?.associatedPlatforms || []}
				/>
			)}
			{modalView.isVisible && modalView.type === "remove" && (
				<RemoveLocations
					isOpen
					data={modalView.data}
					close={handleCloseModal}
					brandName={basicInfo?.name || "Brand"}
					brandId={basicInfo?.id}
					isMulti={modalView.isMulti}
				/>
			)}
			<CropModal
				isOpen={isModalOpen}
				title="Crop & Adjust"
				classes="brand-edit-crop--modal"
				submitAction={() => {
					setModalOpen(false);
				}}
				cancelAction={handleCropModalClose}
				close={handleCropModalClose}
				imgRef={imgRef}
				imgSrc={imgSrc}
				onImageLoad={onImageLoad}
				crop={crop}
				setCrop={setCrop}
				setCompletedCrop={setCompletedCrop}
				aspect={aspect}
			/>
			<ImageSizeWarningModal
				isOpen={isImageSizeLarge}
				handleSubmit={handleImageChange}
				close={closeImageSizeWarningModal}
			/>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	biz: store.login.loggedInbizDetail,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(BrandsEdit);

const RemoveLocations = ({ isOpen, data, close, brandName, brandId, isMulti }) => {
	const dataObject = {
		...(data[0] ?? {})
	};
	const [isChecked, setChecked] = useState(false);
	const associatedPlatforms = dataObject?.associatedPlatforms?.associatedPlatforms;
	const [loading, setLoading] = useState(false);
	const [respState, setRespState] = useState("");

	let multiAssociatedPlatformsCount = 0;
	let multiItemsCount = 0;
	if (isMulti) {
		multiAssociatedPlatformsCount = data.length;
		data.forEach((loc) => {
			multiItemsCount += loc.ilaCount;
		});
	}

	const handleDeleteLocation = async () => {
		try {
			setLoading(true);
			const variables = {};
			variables.isActive = false;
			variables.brandIds = [brandId];
			variables.locationIds = isMulti ? [...data.map((loc) => loc.id)] : [dataObject.id];
			const resp = await client.mutate({
				mutation: REMOVE_BRANDS_LOCATIONS,
				variables
			});
			if (resp?.data?.removeBrandsLocations?.status?.success) {
				setRespState("success");
			} else {
				setRespState("failure");
			}
			setLoading(false);
		} catch (e) {
			console.log(e);
			setLoading(false);
			setRespState("failure");
		}
	};
	return (
		<Modal
			isOpen={isOpen}
			close={close}
			classes="remove-locations-modal"
			title="Remove Location?"
			showCancelAction
			cancelTitle="Close"
			disabled={!isChecked}
			showDeleteAction={respState !== "success"}
			deleteAction={handleDeleteLocation}
			hideActions={loading}
			deleteTitle="Remove"
		>
			{loading ? (
				<Loading />
			) : respState !== "" ? (
				<div className="remove-location-details">
					<div className={`end-result ${respState}`}>
						{respState === "success"
							? "Successfully removed brand location"
							: "Failed to remove brand location"}
					</div>
				</div>
			) : (
				<div className="remove-location-details">
					<div className="brand-platform-info">
						{brandName}{" "}
						{isMulti
							? `at selected ${(data ?? []).length} location(s)`
							: dataObject?.name?.name
							? `at ${dataObject?.name?.name}`
							: ""}{" "}
						is currently online on &nbsp;
						{isMulti ? (
							<span>{multiAssociatedPlatformsCount} platforms</span>
						) : (associatedPlatforms ?? []).length === 0 ? (
							"no platforms"
						) : (
							associatedPlatforms.map((platform, i) =>
								i !== associatedPlatforms.length - 1 ? (
									<span>
										{i !== 0 && ", "}
										{capitaliseText(platform.platformName)}
									</span>
								) : (
									<span> and {capitaliseText(platform.platformName)}.</span>
								)
							)
						)}
					</div>
					<div className="item-assoc-count">
						It is also associated with {isMulti ? multiItemsCount : dataObject?.ilaCount || 0} item(s).
					</div>
					<div className="warning">
						If you remove this location it will not be a part of your customer-facing catalogue on your apps
						and website.
					</div>
					<div className="confirm-process">
						<CheckBox
							checked={isChecked}
							clickHandler={() => {
								setChecked((current) => !current);
							}}
						/>
						Proceed with removing location(s) from brand.
					</div>
				</div>
			)}
		</Modal>
	);
};
