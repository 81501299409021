import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { Configurations } from "../Hub/Configurations";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { ButtonIcon } from "../_commons/ButtonIcon";

const Billing = (props) => {
	const {
		bizForm,
		updateBizForm,
		configValues,
		handleConfigValues,
		lockedFields,
		locationsList,
		currLocation,
		handleLocationSearch,
		handleCurrLocation,
		merakiDetails,
		showPaymentGatewayContainer,
		handlePaymentGatewayToggle,
		paymentGatewaysList,
		selectedPaymentConfig,
		handlePaymentConfig,
		handleConfigForm,
		bizName,
		brands,
		handleBrandsLabelOption,
		handleBrand,
		isMultibrandEnabled,
		confirmLoading
	} = props;

	const handleChange = (field, value) => {
		updateBizForm({ [field]: value });
	};

	return (
		<div className="bizprofile-container">
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-compliance.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Compliance</div>
							<div className="header-subtext">
								Manage info related to tax, legal & regulatory agencies
							</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={bizForm.registeredBusinessName || ""}
							onChange={(e) => handleChange("registeredBusinessName", e.target.value)}
						>
							Registered business name
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.gstin || ""}
							onChange={(e) => handleChange("gstin", e.target.value)}
							readOnly={lockedFields.gstin}
						>
							GSTIN
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.tin || ""}
							onChange={(e) => handleChange("tin", e.target.value)}
							readOnly={lockedFields.tin}
						>
							TIN
						</InputWithLabel>
					</div>
					<div className="form-row row-half">
						<InputWithLabel
							value={bizForm.fssaiLicenseNumber || "--"}
							onChange={(e) => handleChange("fssaiLicenseNumber", e.target.value)}
							readOnly={true}
						>
							FSSAI license number
						</InputWithLabel>
					</div>
				</div>
			</div>
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-address.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Address</div>
							<div className="header-subtext">Mailing address to contact your business</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={bizForm.addressLine1 || ""}
							onChange={(e) => handleChange("addressLine1", e.target.value)}
						>
							Address line 1
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.addressLine2 || ""}
							onChange={(e) => handleChange("addressLine2", e.target.value)}
						>
							Address line 2
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.city || ""}
							onChange={(e) => handleChange("city", e.target.value)}
						>
							City
						</InputWithLabel>
					</div>
					<div className="form-row row-half">
						<InputWithLabel
							value={bizForm.state || ""}
							onChange={(e) => handleChange("state", e.target.value)}
						>
							State
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.country || ""}
							onChange={(e) => handleChange("country", e.target.value)}
						>
							Country
						</InputWithLabel>
						<InputWithLabel
							value={bizForm.postalCode || ""}
							onChange={(e) => handleChange("postalCode", e.target.value)}
						>
							Postal code
						</InputWithLabel>
					</div>
				</div>
			</div>
			<div className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-google-config.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Google Configurations</div>
							<div className="header-subtext">Add API keys to integrate with Google services.</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={configValues.gecoding_api_key || ""}
							onChange={(e) => handleConfigValues("gecoding_api_key", e.target.value)}
							showCustomTooltip={true}
							tooltipInfo="Required only if the Biz supports Zipcodes"
						>
							Geocoding API Key
						</InputWithLabel>
						<InputWithLabel
							value={configValues.fcm_server_key || ""}
							onChange={(e) => handleConfigValues("fcm_server_key", e.target.value)}
							showCustomTooltip={true}
							tooltipInfo="The Firebase Cloud Messaging server key to be used to send push notification"
						>
							FCM Server Key
						</InputWithLabel>
					</div>
				</div>
			</div>
			<PaymentGateway
				locationsList={locationsList}
				currLocation={currLocation}
				handleLocationSearch={handleLocationSearch}
				handleCurrLocation={handleCurrLocation}
				merakiDetails={merakiDetails}
				showPaymentGatewayContainer={showPaymentGatewayContainer}
				handlePaymentGatewayToggle={handlePaymentGatewayToggle}
				paymentGatewaysList={paymentGatewaysList}
				selectedPaymentConfig={selectedPaymentConfig}
				handlePaymentConfig={handlePaymentConfig}
				handleConfigForm={handleConfigForm}
				bizName={bizName}
				brands={brands}
				handleBrandsLabelOption={handleBrandsLabelOption}
				handleBrand={handleBrand}
				isMultibrandEnabled={isMultibrandEnabled}
				confirmLoading={confirmLoading}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(Billing);

const PaymentGateway = ({
	locationsList,
	currLocation,
	handleLocationSearch,
	handleCurrLocation,
	merakiDetails,
	showPaymentGatewayContainer,
	handlePaymentGatewayToggle,
	paymentGatewaysList,
	selectedPaymentConfig,
	handlePaymentConfig,
	handleConfigForm,
	bizName,
	brands,
	handleBrandsLabelOption,
	handleBrand,
	isMultibrandEnabled = false,
	confirmLoading = false
}) => {
	const [filteredPaymentConfigList, setFilteredPaymentConfigList] = useState([]);

	useEffect(() => {
		setFilteredPaymentConfigList(
			paymentGatewaysList.filter((pg) => currLocation?.id === "default" || pg.supportLocationWiseConfig === true)
		);
	}, [currLocation, paymentGatewaysList]);

	const data = merakiDetails?.appsData;

	const { loading, error } = merakiDetails;

	return (
		<div apps="paymentGateway" className={`card-container ${loading || confirmLoading ? "loading" : ""}`}>
			<div className="card-header">
				<div className="header-container">
					<div className="app-icon">
						<img src="/assets/icons/icon-payment-gateway.svg" alt="" />
					</div>
					<div className="app-header">
						<div className="header-text">Payment Gateway</div>
						<div className="header-subtext">
							Facilitate a secure online payment experience for your customers
						</div>
					</div>
				</div>
				<div className="actions">
					{isMultibrandEnabled && (
						<SelectFilterCustom
							options={brands.items}
							isLoading={brands.isLoading}
							field="brands"
							currValue={brands.selectedBrand}
							setFilter={(f, value) => handleBrand(value)}
							labelKey="name"
							valueKey="id"
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							// isClearable={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							handleBrand={handleBrand}
						/>
					)}
					<SelectFilter
						options={locationsList.data}
						isLoading={locationsList.isLoading}
						field="currLocation"
						currValue={currLocation}
						setFilter={handleCurrLocation}
						labelKey="title"
						valueKey="id"
						isClearable={false}
						isAsync={true}
						currLocation={currLocation}
						handleSearch={handleLocationSearch}
					/>
				</div>
			</div>
			<div className="config-form-container">
				{!loading && !confirmLoading && (filteredPaymentConfigList ?? []).length === 0 && (
					<div className="empty-config-message">
						No payment gateway configured for <strong>{bizName || "this business"}</strong>
					</div>
				)}
				{(isMultibrandEnabled ? currLocation?.id : currLocation?.id !== "default") &&
					selectedPaymentConfig === null &&
					!loading &&
					(filteredPaymentConfigList ?? []).length !== 0 && (
						<div className="note">
							<ButtonIcon icon="alert" color="#C2831C" />
							<div>
								No payment gateway configured for this location. The default configurations will be used
								for transactions at this location.
							</div>
						</div>
					)}
				{(isMultibrandEnabled ? currLocation === null : currLocation?.id === "default") &&
					selectedPaymentConfig === null &&
					!loading &&
					(filteredPaymentConfigList ?? []).length !== 0 && (
						<div className="note">
							<ButtonIcon icon="alert" color="#C2831C" />
							<div>
								No default payment gateway configured. Individual location configurations will be used
								where available.
							</div>
						</div>
					)}
				{(isMultibrandEnabled ? currLocation?.id : currLocation?.id !== "default") &&
					selectedPaymentConfig !== null &&
					!loading &&
					(filteredPaymentConfigList ?? []).length !== 0 && (
						<div className="note">
							<ButtonIcon icon="alert" color="#C2831C" />
							<div>
								Payment gateway configured for this location will be used exclusively for transactions
								at this location.
							</div>
						</div>
					)}
				{(isMultibrandEnabled ? currLocation === null : currLocation?.id === "default") &&
					selectedPaymentConfig !== null &&
					!loading &&
					(filteredPaymentConfigList ?? []).length !== 0 && (
						<div className="note">
							<ButtonIcon icon="alert" color="#C2831C" />
							<div>
								Default payment gateway configured will be used for all locations without specific
								configurations.
							</div>
						</div>
					)}
				{(filteredPaymentConfigList ?? []).length > 0 && (
					<>
						<div className="config-form-row">
							<SelectFilter
								title="Active Payment Gateway"
								options={filteredPaymentConfigList}
								isLoading={loading}
								field="selectedPaymentConfig"
								currValue={selectedPaymentConfig}
								setFilter={handlePaymentConfig}
								labelKey="valueForDisplay"
								valueKey="value"
								isClearable={true}
								placeholder="Select Payment Gateway"
							/>
						</div>
						<div className="config-form-row">
							{data.businessConfigurations &&
								data.businessConfigurations.map(
									(gateway, i) =>
										gateway &&
										selectedPaymentConfig?.kvConfigName?.toLowerCase() ===
											gateway.name?.toLowerCase() && (
											<Configurations
												platform={gateway.name}
												options={
													gateway.keys?.filter((config) => config.key !== "is_pg_disabled") ||
													[]
												}
												handleForm={handleConfigForm}
												key={i}
												validations={error?.fields}
											/>
										)
								)}
						</div>
					</>
				)}
				{!loading && !confirmLoading && !data.businessConfigurations && (
					<div className="no-items-placeholder">No Configurations found!</div>
				)}
				{((loading && (!data.businessConfigurations || !filteredPaymentConfigList)) || confirmLoading) && (
					<div className="P(10px 0)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
			</div>
		</div>
	);
};
