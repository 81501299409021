import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTrail, config, animated } from "react-spring";

// clients
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_TRANSACTIONS } from "../../graphql/payment";

// utils
import { formatDate, commifyNumbers, printCurrency, scroll } from "../../atlas-utils";
import { ActionTypes } from "../../actions/_types";

// components
import { Paginator } from "../../components/_commons/Paginator";
import { Filters } from "../_commons/Filters";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";

// constants
import { BIZ_CREDIT_TRANSACTION_TYPE, MEDIUM_OPTIONS } from "../../client-config";
const USER_MAP = {
	sys_user_prime: "Prime",
	sys_user_atlas: "Atlas"
};

@connect((store) => ({
	login: store.login,
	biz: store.login.loggedInbizDetail,
	dimensions: store.configItems.dimensions,
	creditsState: store.creditsState
}))
export class Ledger extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: null,
			showFilters: false,
			currFilters: {},
			filterApplied: {},
			limit: 10,
			offset: 0,
			searchKW: ""
		};
	}

	componentWillReceiveProps(newProps) {
		if (this.props.login.loggedInbizDetail.credits != newProps.login.loggedInbizDetail.credits) {
			this.fetchTransactionList();
		}
		if (this.props.creditsState.appliedDateFilter != newProps.creditsState.appliedDateFilter) {
			this.fetchTransactionList();
		}
	}

	cancelSearch = () => {
		this.setState(
			{
				offset: 0,
				searchKW: ""
			},
			() => this.fetchTransactionList()
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.state;
		const offset = (page - 1) * limit;
		// set new offset then get new transactions results
		this.setState(
			{
				offset
			},
			() => this.fetchTransactionList()
		);
		// scroll to top of the list
		scroll({ top: this.tableRef.offsetTop - 57, left: 0 });
	};

	fetchTransactionList = async () => {
		const { limit, offset, searchKW } = this.state;
		const variables = {
			limit,
			offset,
			sort: {
				field: "created",
				order: "DESC"
			}
		};
		if (searchKW) {
			variables.search = [
				{
					key: "default",
					value: searchKW
				}
			];
		}
		store.dispatch({
			type: "TOGGLE_GLOBAL_LOADER",
			payload: true
		});
		this.setState({
			loading: true
		});
		try {
			const resp = await this.getTransactionData(variables);
			this.setState({
				loading: false,
				data: resp.data.bizCreditsTransactions
			});
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			this.setState({
				loading: false
			});
		}
		store.dispatch({
			type: "TOGGLE_GLOBAL_LOADER",
			payload: false
		});
	};

	getTransactionData = async (variables) => {
		let { filterApplied } = this.state;
		let filtersObject = [];
		Object.keys(filterApplied).forEach((f) => {
			if (filterApplied[f].value) {
				filtersObject.push(filterApplied[f]);
			}
		});
		// date filter
		const { appliedDateFilter } = store.getState().creditsState;
		if (appliedDateFilter.current.dateFilter) {
			filtersObject.push({
				field: "created",
				value: appliedDateFilter.current.dateFilter
			});
		}
		variables.filters = filtersObject;
		return await client.query({
			query: GET_TRANSACTIONS,
			variables,
			fetchPolicy: "no-cache" // we have to use no-cache to bypass IntrospectionFragmentMatcher
		});
	};

	componentDidMount() {
		this.fetchTransactionList();
	}

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false,
			currFilters: this.state.filterApplied
		});
	};

	showFilters = () => {
		this.setState({
			showFilters: true
		});
	};

	setFilter = (field, value) => {
		let currFilters = { ...this.state.currFilters };
		currFilters[field] = value;
		this.setState({
			currFilters
		});
	};

	applyFilters = () => {
		this.setState(
			{
				filterApplied: this.state.currFilters,
				showFilters: false,
				offset: 0
			},
			() => this.fetchTransactionList()
		);
	};

	clearFilters = () => {
		this.setState(
			{
				filterApplied: {},
				currFilters: {},
				showFilters: false,
				offset: 0
			},
			() => this.fetchTransactionList()
		);
	};

	search = () => {
		this.setState(
			{
				offset: 0,
				loading: true
			},
			() => this.fetchTransactionList()
		);
	};

	debouncedSearch = _.debounce(() => this.search(), 500);

	searchTransaction = (value) => {
		this.setState(
			{
				searchKW: value
			},
			() => this.debouncedSearch()
		);
	};

	updateCreditsState = (payload) => {
		store.dispatch({
			type: "CREDITS_STATE_UPDATE",
			payload
		});
	};

	render() {
		const {
			biz: { billingCurrencySymbol },
			dimensions,
			creditsState
		} = this.props;
		const { filterApplied } = this.state;
		const isMobile = dimensions.width <= 768;
		let filterCount = 0;
		for (let f in filterApplied) {
			if (filterApplied[f].value != "") {
				filterCount++;
			}
		}
		return (
			<div className="settings-section" ref={(ref) => (this.tableRef = ref)}>
				<Filters
					isOpen={this.state.showFilters}
					close={this.filterSidebarCloseHandler}
					apply={this.applyFilters}
					clear={this.clearFilters}
					options={this.state.data ? this.state.data.filters.filter((f) => f.type !== "DATE") : []}
					currentFilters={this.state.currFilters}
					setFilter={this.setFilter}
				/>
				<Header
					{...this.props.login}
					searchKW={this.state.searchKW}
					cancelSearch={this.cancelSearch}
					searchTransaction={this.searchTransaction}
					filterCount={filterCount}
					showFilters={this.showFilters}
					filterActive={this.state.showFilters}
					creditsState={creditsState}
					creditsUsed={this.state.data?.creditsUsed}
					currentDateFilter={this.props.creditsState.currentDateFilter}
					appliedDateFilter={this.props.creditsState.appliedDateFilter}
					updateCreditsState={this.updateCreditsState}
					loading={this.state.loading}
				/>
				<Table
					loading={this.state.loading}
					data={this.state.data}
					currencySymbol={billingCurrencySymbol}
					currLocation={this.props.currLocation}
					isMobile={isMobile}
				/>
				<Paginator
					limit={this.state.limit}
					offset={this.state.offset}
					count={this.state.data ? this.state.data.count : 0}
					goToPage={this.handlePagination}
				/>
			</div>
		);
	}
}

const Header = (props) => (
	<div className="ledger">
		<div className="header">
			<div className="header-text">Ledger</div>
			<div className="subheader-text">
				<span>Find a complete report for all your purchases and campaigns</span>
			</div>
		</div>
		<div className="filters-container">
			<div className="header-action-button">
				<NewDateCompareFilter
					showDropdown={true}
					loading={props.loading}
					currentDateFilter={props.currentDateFilter}
					appliedDateFilter={props.appliedDateFilter}
					updateState={props.updateCreditsState}
					includeAllTime={true}
					hideComparison={true}
				/>
				<div className="filter-buttons">
					<div className={(props.filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
						<div className="container" onClick={props.showFilters}>
							<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
							<div className="filter-title">
								Filter
								{props.filterCount > 0 && <span className="filter-count">{props.filterCount}</span>}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="search-input-holder">
				<input
					value={props.searchKW}
					onChange={(e) => props.searchTransaction(e.target.value)}
					className="search-input"
					type="text"
					placeholder="Search"
				/>
				<img className="search-icon" src="/assets/header-icons/icon-search.svg" />
				{props.searchKW ? (
					<div onClick={props.cancelSearch} className="dismiss-search">
						<img className="" src="/assets/icons/cancel.png" />
					</div>
				) : null}
			</div>
		</div>
		<div className="ledger-info">
			<div className="credit-balance">
				<span className="title">Current Balance:</span>
				<span
					className="balance"
					style={{ color: props.loggedInbizDetail.credits < 0 ? "#ff425c" : "#000000" }}
				>
					{commifyNumbers(props.loggedInbizDetail.credits)}
				</span>
			</div>
			{props.appliedDateFilter.current.dateFilter && (
				<div className="credits-used">
					<span className="title">
						Credits used
						{props.appliedDateFilter.current?.dateTypeSelected?.value === "range"
							? ` between ${props.appliedDateFilter.current?.rangeStartDate.format(
									"DD MMM, YYYY"
							  )} to ${props.appliedDateFilter.current?.rangeEndDate.format("DD MMM, YYYY")}:`
							: ` ${
									props.appliedDateFilter.current?.presetTypeSelected?.title?.includes("Last")
										? "in"
										: ""
							  } ${props.appliedDateFilter.current?.presetTypeSelected?.title?.toLowerCase()}:`}
					</span>
					&nbsp;&nbsp;
					{props.loading ? <span className="shimmer H(16px) W(60px)" /> : commifyNumbers(props.creditsUsed)}
				</div>
			)}
		</div>
	</div>
);

const Table = (props) => {
	let nodes = [];
	if (props.data) {
		nodes = props.data.objects;
	}
	const trail = useTrail(nodes.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div className={(props.loading ? "disabled" : "") + " credit-transactions-list-table"}>
			<div className="at-table-row-based at-table--5cols">
				<TableHeader isMobile={props.isMobile} />
				{trail.map(({ rotate }, i) => {
					if (nodes[i].purpose === "ONLINE_RELOAD" && nodes[i].status !== "PROCESSED") {
						return null;
					}
					return (
						<TableList
							key={nodes[i].id}
							currencySymbol={props.currencySymbol}
							currLocation={props.currLocation}
							isMobile={props.isMobile}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...nodes[i]}
						/>
					);
				})}
				{nodes.length == 0 && !props.loading && (
					<div className="no-items-placeholder Pb(50px)">No transaction history!</div>
				)}
				{nodes.length == 0 && props.loading && (
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
			</div>
		</div>
	);
};

const TableHeader = ({ isMobile }) => (
	<div className="at-table-row header-row">
		<div className="at-table-cell at-table-header at-header-text title">TITLE</div>
		<div className="at-table-cell at-table-header at-header-text txnid">Txn ID</div>
		{!isMobile && (
			<React.Fragment>
				<div className="at-table-cell at-table-header at-header-text datetime">Date & Time</div>
				<div className="at-table-cell at-table-header at-header-text user">User</div>
			</React.Fragment>
		)}
		<div className="at-table-cell at-table-header at-header-text credits">Credits</div>
	</div>
);

const TableList = (props) => (
	<animated.div
		className={(props.transactionType == "CREDIT" ? "credit" : "debit") + " at-table-row credit-transaction-rows"}
		style={props.style}
		key={props.id}
	>
		<div className="at-table-cell at-cell-text title">
			<MetaInfo {...props} />
		</div>
		<div className="at-table-cell at-cell-text txnid">{props.id}</div>
		{!props.isMobile && (
			<React.Fragment>
				<div className="at-table-cell at-cell-text datetime">{formatDate(props.created)}</div>
				<div className="at-table-cell at-cell-text user">
					{USER_MAP[props?.user?.username] || props?.user?.username}
				</div>
			</React.Fragment>
		)}
		<div className="at-table-cell at-cell-text credits">
			{props.transactionType == "CREDIT" ? "+ " : "- "}
			{props.credits}
		</div>
	</animated.div>
);

const MetaInfo = (props) => {
	const { purpose, purposeObject, currencySymbol, transactionType } = props;
	const purposeLabelObj = BIZ_CREDIT_TRANSACTION_TYPE.find((i) => i.value == purpose);
	const purposeLabel = purposeLabelObj ? purposeLabelObj.title[transactionType] : "--";

	let mediumStr = "--";
	if (purposeObject) {
		let mediumLabelObj = MEDIUM_OPTIONS.find((i) => i.value == purposeObject.medium);
		mediumStr = mediumLabelObj ? mediumLabelObj.label : "--";
	}

	if (purpose == "CAMPAIGN_SCHEDULED" || purpose == "CAMPAIGN_CANCELLED") {
		return (
			<div className="credit-transaction-meta-info">
				{!props.isMobile && (
					<React.Fragment>
						{props.transactionType == "CREDIT" ? (
							<img className="transaction-type-icon" src="/assets/icons/icon-credits-added.svg" />
						) : (
							<img className="transaction-type-icon" src="/assets/icons/icon-credits-used.svg" />
						)}
					</React.Fragment>
				)}
				<div className="info-container">
					<div className="transaction-purpose">{purposeLabel}</div>
					{props.purposeObject ? (
						<div>
							<div className="transaction-id">ID: {props.purposeObject && props.purposeObject.id}</div>
							<div className="transaction-notes">
								Channel: {mediumStr} | Title: {props.purposeObject && props.purposeObject.name}
							</div>
						</div>
					) : null}
					<div className="view-detail">
						<img src="/assets/icons/ext-link.png" />
						<Link
							to={{
								pathname: `/campaigns/${props.purposeObject && props.purposeObject.id}/`,
								state: props.currLocation
							}}
						>
							View campaign detail
						</Link>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="credit-transaction-meta-info">
				{props.transactionType == "CREDIT" ? (
					<img className="transaction-type-icon" src="/assets/icons/icon-credits-added.svg" />
				) : (
					<img className="transaction-type-icon" src="/assets/icons/icon-credits-used.svg" />
				)}
				<div className="info-container">
					<div className="transaction-purpose">{purposeLabel}</div>
					{["ONLINE_RELOAD"].indexOf(purpose) > -1 && (
						<div className="transaction-id">
							Amount: {printCurrency(currencySymbol)}
							{commifyNumbers((props.purposeObject && props.purposeObject.amount) || 0)}
						</div>
					)}
					{props.comments && (
						<div className="transaction-notes">
							{purpose === "E_BILL" ? props.comments : `Notes: ${props.comments}`}
						</div>
					)}
				</div>
			</div>
		);
	}
};
