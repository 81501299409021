import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { ModifierGroupsList } from "./ModifierGroupsList";
import ModifierGroupCreate from "./ModifierGroupCreate";
import ModifierGroupEdit from "./ModifierGroupEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/ModifierGroupsList/Header";

const ModifierGroupsContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="modifier-groups-container">
			{restrictView ? (
				<Route
					path="/modifier-groups"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/modifier-groups" component={ModifierGroupsList} />
					<Route
						exact
						path="/modifier-groups/new"
						render={() =>
							hasAccess ? (
								<ModifierGroupCreate hasAccess={hasAccess} />
							) : (
								<Redirect to="/modifier-groups" />
							)
						}
					/>
					<Route exact path="/modifier-groups/edit/:id" component={ModifierGroupEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default ModifierGroupsContainer;
