import React from "react";

// component
import Placeholder from "../_commons/Placeholder";

// third party
import moment from "moment";
import { useTrail, config, animated } from "react-spring";
import { Link } from "react-router-dom";

// constants
import { OPERATIONAL_EFFICIENCY_LOCATION_HEADER_FIELDS } from "../../client-config";

export const LocationTable = ({ data, loading, dimensions, uniqueId, hasAccess = false, sortHandler }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	if (data.length === 0 && !loading) {
		const placeholderContent = {
			placeholderText: "Crunching your numbers!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-ohi.svg",
			placeholderSubtext:
				"View insights about your operations with metrics and trends helping you to take data driven decisions for your business",
			size: "medium"
		};
		return <Placeholder {...placeholderContent} />;
	}
	return (
		<div className={(data.length > 0 && loading ? "disabled" : "") + " transaction-table-holder"}>
			<div className="transactions-list-table">
				<div className="at-table-row-based">
					<TableHeader
						headerFields={OPERATIONAL_EFFICIENCY_LOCATION_HEADER_FIELDS}
						sortHandler={sortHandler}
					/>
					{trails.map(({ rotate }, i) => (
						<TableListResponsive
							key={uniqueId ? data[i]["current"][uniqueId] : data[i]["current"].id}
							dimensions={dimensions}
							hasAccess={hasAccess}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && (
						<div className="no-items-placeholder">Unable to fetch Locations</div>
					)}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ headerFields, sortHandler }) => (
	<div className={`at-table-row transaction-header-row`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`${field.value} at-table-cell at-table-header at-header-text ${
						field.value !== "outlet" ? "clickable" : ""
					}`}
					style={field.value === "outlet" ? { width: "14%" } : { width: "10%" }}
					onClick={field.value === "outlet" ? undefined : () => sortHandler("percentage_" + field.value)}
				>
					{field.label}
					{field.value !== "outlet" && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

const TableListResponsive = ({ style, dimensions, ...rest }) => (
	<React.Fragment>
		<TableList {...rest} dimensions={dimensions} style={style} />
	</React.Fragment>
);
const humanReadableTime = (time) => {
	return time > 1 ? Math.floor(time) + " minutes" : Math.floor(time * 60) + " seconds";
};
const TableList = ({ current, previous, dimensions, hasAccess, style }) => {
	const {
		outlet,
		city,
		percentageAcknowledged,
		percentageFoodReady,
		percentageDispatched,
		percentageCancelled,
		averageAcknowledged,
		averageFoodReady,
		averageDispatched,
		averageCancelled,
		bizLocationId
	} = current;
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text outlet" title={outlet}>
				{hasAccess ? (
					<Link
						className="upiper-id"
						to={`/orderstatusupdates/${bizLocationId}`}
						style={{ textDecoration: "none" }}
					>
						<div className="date-date">{outlet || "--"}</div>
					</Link>
				) : (
					<div title={outlet}>{outlet || "--"}</div>
				)}
				<div className="date-time">{city || ""}</div>
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{Math.floor(percentageAcknowledged) + " %"}</div>
				{previous && (
					<div className="sub-text date-date">vs {Math.floor(previous.percentageAcknowledged) + " %"}</div>
				)}
				<div className="date-time">{humanReadableTime(averageAcknowledged)}</div>
				{previous && (
					<div className="sub-text date-time">vs {humanReadableTime(previous.averageAcknowledged)}</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{Math.floor(percentageFoodReady) + " %"}</div>
				{previous && (
					<div className="sub-text date-date">vs {Math.floor(previous.percentageFoodReady) + " %"}</div>
				)}
				<div className="date-time">{humanReadableTime(averageFoodReady)}</div>
				{previous && (
					<div className="sub-text date-time">vs {humanReadableTime(previous.averageFoodReady)}</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{Math.floor(percentageDispatched) + " %"}</div>
				{previous && (
					<div className="sub-text date-date">vs {Math.floor(previous.percentageDispatched) + " %"}</div>
				)}
				<div className="date-time">{humanReadableTime(averageDispatched)}</div>
				{previous && (
					<div className="sub-text date-time">vs {humanReadableTime(previous.averageDispatched)}</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text timestamp">
				<div className="date-date">{Math.floor(percentageCancelled) + " %"}</div>
				{previous && (
					<div className="sub-text date-date">vs {Math.floor(previous.percentageCancelled) + " %"}</div>
				)}
				<div className="date-time">{humanReadableTime(averageCancelled)}</div>
				{previous && (
					<div className="sub-text date-time">vs {humanReadableTime(previous.averageCancelled)}</div>
				)}
			</div>
		</animated.div>
	);
};
