import React from "react";

// components
import Button, { ButtonSize, ButtonVariant } from "@urbanpiper-engineering/horizon/dist/atoms/button";

function Footer({
	isContinueDisabled,
	handleBack,
	handleContinue,
	isLoading,
	showContinue = true,
	primaryBtnTxt = "Continue",
	secondaryBtnTxt = "Back"
}) {
	return (
		<div className="onboarding-footer">
			{showContinue ? (
				<Button
					disabled={isContinueDisabled || isLoading}
					variant={ButtonVariant.PRIMARY}
					onClick={handleContinue}
					size={ButtonSize.LG}
				>
					{primaryBtnTxt}
				</Button>
			) : null}
			<Button disabled={isLoading} onClick={handleBack} variant={ButtonVariant.Secondary}>
				{secondaryBtnTxt}
			</Button>
		</div>
	);
}

export default Footer;
