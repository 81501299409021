import React from "react";

// components
import { Button } from "../../_commons/Button";

// assets
import HelpIcon from "../../_commons/HelpIcon";

const SectionHeader = ({
	title,
	headerLeft,
	headerRight,
	showHelpBtn = false,
	handlePiperAcademy,
	isPageScrolled = false,
	newBtn = false
}) => {
	return (
		<div className={"analytics-section-header" + (isPageScrolled ? " scrolled" : "")}>
			<div className="section-header-left">
				<span>{title || headerLeft}</span>
				{newBtn && (
					<span className="new-btn">
						New <span class="diagonal-animation"></span>
					</span>
				)}
			</div>
			<div className="section-header-right">
				{showHelpBtn && handlePiperAcademy && (
					<div className="help-btn-container">
						<Button clickHandler={handlePiperAcademy} type="secondary">
							<HelpIcon />
							<span>Help</span>
						</Button>
					</div>
				)}
				{headerRight}
			</div>
		</div>
	);
};
export default SectionHeader;
