import React, { useEffect, useMemo, useState } from "react";
import { FormSidebar } from "../../_commons/FormSidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { getAllAppliedFilters, saveComparison, updateComparison, updateLastViewed } from "../../../actions/analytics";
import NotificationServices from "../../../services/NotificationService";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import InfoIcon from "../../_icons/InfoIcon";
import { RadioButton } from "../../_commons/RadioButton";
import { store } from "../../../store/configureStore";
import { ActionTypes } from "../../../actions/_types";
import { trackEvent } from "../../../atlas-utils";

const SaveComparison = ({
	formState,
	setFormState,
	name,
	isAdmin,
	compareAnalytics,
	analyticsFiltersState,
	userId,
	saveType,
	status = "draft",
	login,
	savedFor
}) => {
	const { metrics, id } = useParams();
	const [dataId, setDataId] = useState("");
	const [selectedValue, setSelectedValue] = useState("");
	const [comparisonName, setComparisonName] = useState("");
	const [loading, setLoading] = useState(false);
	const { search } = useLocation();
	const location = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [saveComparisonLoading, setSaveComparisonLoading] = useState(false);
	const hideActions = !comparisonName || !selectedValue.length;

	useEffect(() => {
		if (location?.state?.savedFor || savedFor) {
			setSelectedValue(location?.state?.savedFor || savedFor);
		}
	}, [savedFor]);

	useEffect(() => {
		if (name) {
			setComparisonName(name || "");
		}
	}, [name, formState]);

	useEffect(() => {
		let valueForDisplay = compareAnalytics?.columnData?.[metrics]?.data
			?.slice(1)
			?.map((column) => column.valueForDisplay)
			.join(" vs. ");
		setComparisonName(valueForDisplay);
	}, [JSON.stringify(compareAnalytics?.columnData?.[metrics]?.data)]);

	const getSaveComparisonVariables = (status, selectedValue) => {
		const { durationObject, bizId } = getAllAppliedFilters();
		const { brand_id, platform_names, location_id, location_group_id } = analyticsFiltersState.appliedFilters;
		let filters = [];
		if (brand_id[0] !== "all") {
			filters.push({ field: "brand_id", value: brand_id.join(",") });
		}
		if (platform_names[0] !== "all") {
			filters.push({ field: "platform_names", value: platform_names.join(",") });
		}
		if (location_id[0] !== "all") {
			filters.push({ field: "location_id", value: location_id.join(",") });
		}
		if (location_group_id[0] !== "all") {
			filters.push({ field: "location_group_id", value: location_group_id.join(",") });
		}
		let locations = [];
		let locationGroups = [];
		if (metrics === "location") {
			(compareAnalytics?.columnData?.[metrics]?.data || []).forEach((column, index) => {
				if (index > 0) {
					if (column.field === "Location Groups") {
						locationGroups.push(column.value);
					}
					if (column.field === "Locations") {
						locations.push(column.value);
					}
				}
			});
			locations.length && filters.push({ field: "location_id", value: locations.join(",") });
			locationGroups.length && filters.push({ field: "location_group_id", value: locationGroups.join(",") });
		}
		let metricHead = [];
		const getSelectedMetricValues = () => {
			const values = compareAnalytics?.columnData?.[metrics]?.data
				?.slice(1)
				?.map((column) => String(column.value));
			let valueForDisplay = compareAnalytics?.columnData?.[metrics]?.data
				?.slice(1)
				?.map((column) => column.valueForDisplay)
				.join(" vs. ");
			if (metrics == "location") {
				metricHead = compareAnalytics?.columnData?.[metrics]?.data
					?.slice(1)
					?.map((column) => String(column.field));
			}
			valueForDisplay = "Comparison of " + valueForDisplay;
			return { values, valueForDisplay };
		};
		const { valueForDisplay, values } = getSelectedMetricValues();
		let name = comparisonName || valueForDisplay;
		if (status == "draft") {
			name = valueForDisplay;
		}
		const variables = {
			bizId: String(bizId),
			type: metrics,
			savedFor: selectedValue,
			status: status,
			comparison: {
				name: name,
				metrics: values,
				filters: filters,
				duration: durationObject?.duration
			}
		};
		return variables;
	};

	const trackSavedComparisonEvent = () => {
		const visibility = selectedValue === "me" ? "self" : "everyone";
		let entity = {
			entity: metrics,
			visibility: visibility
		};
		trackEvent("saved_comparison_created", { ...entity, module: "compare" });
	};
	const handleUpdateSessionStatus = async (status, value, id, setVariables = {}) => {
		try {
			const variables = {
				comparisonId: id,
				input: getSaveComparisonVariables(status, value),
				...setVariables
			};
			const response = await updateComparison(variables);
			if (status === "saved") {
				trackSavedComparisonEvent();
			}
			return response;
		} catch (err) {
			console.log(err);
		}
	};

	const handleSessionStatus = async (status, value) => {
		try {
			const response = await saveComparison(getSaveComparisonVariables(status, value));
			if (status == "saved") {
				trackSavedComparisonEvent();
			}
			return response;
		} catch (err) {
			console.log(err);
		}
	};
	const selectedMetrics = compareAnalytics?.columnData?.[metrics]?.data?.slice(1)?.map((column) => column.value);

	const intermediateSave = async () => {
		if (selectedMetrics.length >= 2) {
			try {
				if (id || dataId) {
					if (status === "draft") {
						const resp = await handleUpdateSessionStatus(status, "me", id ? id : dataId);
					}
				} else {
					if (!saveComparisonLoading) {
						setSaveComparisonLoading(true);
						const resp = await handleSessionStatus("draft", "me");
						setDataId(resp?.data?.saveComparison?.comparisonId);
						setSaveComparisonLoading(false);
					}
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	const updateLastViewTime = async () => {
		if (id) {
			let variables = {
				input: {
					comparisonId: id,
					bizId: String(login.loggedInbizDetail?.id),
					status: "viewed"
				}
			};
			try {
				const response = await updateLastViewed(variables);
			} catch (err) {
				console.log(err);
			}
		}
	};

	useEffect(() => {
		updateLastViewTime();
	}, []);

	useEffect(() => {
		intermediateSave();
	}, [JSON.stringify(selectedMetrics), query.get("filters")]);

	const handleChange = (e) => {
		setComparisonName(e.target.value);
	};

	const handleCancel = () => {
		handleClose();
	};

	const history = useHistory();
	const handleRedirect = async () => {
		history.push("/analytics/compare");
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_INITIAL_COLUMN_DATA
		});
	};

	const sendNotification = (status, isUpdated = false) => {
		const message = `Comparison ${isUpdated ? "updated" : "saved"} successfully.`;
		if (status) {
			NotificationServices.pushNotification({
				message: message,
				timeout: 5000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
			setLoading(false);
			handleRedirect();
		} else {
			NotificationServices.pushNotification({
				message: `Something went wrong while ${isUpdated ? "updating" : "saving"} comparison.`,
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			setLoading(false);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			if (userId) {
				if (saveType == "Save as New") {
					if (status == "draft") {
						const res = await handleUpdateSessionStatus("saved", selectedValue, id);
						sendNotification(res?.data?.updateComparison?.status?.success, true);
					} else {
						const res = await handleSessionStatus("saved", selectedValue);
						if (!res) {
							throw new Error("Failed to Save Comparison");
						}
					}
				} else if (saveType == "Update Existing") {
					const res = await handleUpdateSessionStatus("saved", selectedValue, id);
					sendNotification(res?.data?.updateComparison?.status?.success, true);
				}
			} else {
				let res;
				if (dataId) {
					res = await handleUpdateSessionStatus("saved", selectedValue, dataId);
					sendNotification(res?.data?.updateComparison?.status?.success);
				} else {
					res = await handleSessionStatus("saved", selectedValue);
					sendNotification(res?.data?.saveComparison?.status?.success);
				}
			}
			handleRedirect();
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};
	const handleClose = () => {
		setFormState(false);
	};

	const handleRadioChange = (value) => {
		setSelectedValue(value);
	};

	return (
		<div>
			<FormSidebar
				isOpen={formState}
				close={handleCancel}
				submit={handleSubmit}
				title="Save Comparison"
				subTitle="Provide a name and choose who can see this comparison."
				loading={loading}
				submitTitle="Save"
				isNested={false}
				overlaySidebarClasses={"small"}
				hideActions={hideActions}
			>
				<div className="comparison-sidebar">
					<div className="input-text">
						<div className="text">Name</div>
						<input
							value={comparisonName}
							onChange={(e) => handleChange(e)}
							placeholder="Give a name to your comparison"
						/>
					</div>
					<div className="radio-buttons">
						<div className="radio-box">
							<RadioButton clickHandler={() => handleRadioChange("me")} checked={selectedValue === "me"}>
								<label htmlFor="saveForMe">Save for me</label>
							</RadioButton>
						</div>
						{isAdmin ? (
							<div className="radio-box">
								<RadioButton
									clickHandler={() => handleRadioChange("everyone")}
									checked={selectedValue === "everyone"}
								>
									<label htmlFor="saveForEveryOne">Save for everyone</label>
								</RadioButton>
							</div>
						) : null}
					</div>
					{!userId && id && (
						<div className="info-container">
							<div className="colored-div"></div>
							<div className="info-div">
								<InfoIcon styles={{ height: "24px", width: "28px" }} />
								<div className="info-msg">
									You have made changes to an existing comparison, it will be saved as a new
									comparison
								</div>
							</div>
						</div>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};

export default connect((store) => ({
	isAdmin: store?.login?.loginDetail?.access?.isAdmin,
	compareAnalytics: store?.compareAnalytics,
	analyticsFiltersState: store?.analyticsFiltersState,
	login: store?.login
}))(SaveComparison);
