import gql from "graphql-tag";

export const GET_CHARGES_LIST = gql`
	query charges($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		charges(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			objects {
				id
				title
				description
				value
				applicableOn
				itemsCount
				merchantRefId
				locationsCount
			}
			count
			hasPrevious
			hasNext
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const CREATE_CHARGE = gql`
	mutation createCharge(
		$title: String!
		$description: String
		$fieldType: FieldType
		$value: Float!
		$applicableOnOption: String!
		$rangeValues: [BillComponentRangeInputType]
		$merchantRefId: String
		$excludedPlatforms: [String]
		$fulfillmentModes: [Int]
	) {
		saveCharge(
			input: {
				title: $title
				description: $description
				fieldType: $fieldType
				value: $value
				applicableOn: $applicableOnOption
				rangeValues: $rangeValues
				merchantRefId: $merchantRefId
				excludedPlatforms: $excludedPlatforms
				fulfillmentModes: $fulfillmentModes
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
				value
				applicableOn
				merchantRefId
				itemsCount
				locationsCount
			}
		}
	}
`;

export const GET_CHARGE = gql`
	query fetchCharge($id: Int) {
		charge(id: $id) {
			id
			title
			description
			fieldType
			value
			applicableOn
			rangeValues {
				min
				max
				value
			}
			isActive
			merchantRefId
			platforms {
				id
				platformName
				isChargeAssociated
			}
			fulfillmentModes {
				id
				name
				nameForDisplay
			}
			ilgps {
				id
			}
		}
	}
`;

export const EDIT_CHARGE = gql`
	mutation createCharge(
		$id: Int
		$title: String!
		$description: String
		$fieldType: FieldType
		$value: Float!
		$applicableOn: String!
		$rangeValues: [BillComponentRangeInputType]
		$merchantRefId: String
		$excludedPlatforms: [String]
		$fulfillmentModes: [Int]
		$isActive: Boolean
	) {
		saveCharge(
			input: {
				id: $id
				title: $title
				description: $description
				fieldType: $fieldType
				value: $value
				applicableOn: $applicableOn
				rangeValues: $rangeValues
				merchantRefId: $merchantRefId
				excludedPlatforms: $excludedPlatforms
				fulfillmentModes: $fulfillmentModes
				isActive: $isActive
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
				value
				applicableOn
				merchantRefId
				itemsCount
				locationsCount
			}
		}
	}
`;

export const GET_CHARGE_ILGPS = gql`
	query fetchChargeILGPS($id: Int) {
		charge(id: $id) {
			id
			ilgps {
				id
				itemGroup {
					id
					title
					includesAll
				}
				locationGroup {
					id
					title
					includesAll
				}
			}
		}
	}
`;
