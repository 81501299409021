import React, { useState } from "react";

// third party
import { Transition, animated, config } from "react-spring/renderprops";

// component
import { Button } from "../_commons/Button";
import ContextMenu from "../_commons/ContextMenu";
import FormTable from "../_commons/FormTable";
import SubcategoriesAssociationDrawer from "./SubcategoriesAssociationDrawer";

const columns = [
	{
		title: "Name",
		dataIndex: "name",
		render: (data) => data
	},
	{
		title: "Assoc. Items",
		dataIndex: "paginatedItems",
		render: (data, renderValuesAndFunctions) => (
			<div className="assoc-items-content">
				{data.count}
				<span>
					<ContextMenu
						isOpen={renderValuesAndFunctions.contextMenuData?.id === data?.id}
						renderMenuItems={() => renderValuesAndFunctions.renderMenuItems()}
						handleOpenMenu={() => renderValuesAndFunctions.openContextMenu({ id: data?.id })}
						handleOutsideClick={
							renderValuesAndFunctions.contextMenuData?.id === data?.id
								? () => renderValuesAndFunctions.closeContextMenu()
								: () => {}
						}
						clickEvent="mousedown"
						disableContextMenu={!!Object.keys(renderValuesAndFunctions?.selectedSubcategories).length}
					/>
				</span>
			</div>
		)
	}
];

const AssociatedSubCategories = ({
	subcategories,
	readOnly,
	categoryId,
	fetchCategoryDetail,
	dispatch,
	match,
	setCategoriesRemoveModalState,
	canAssociateSubcategories
}) => {
	const [subcategoriesAssociationDrawerState, setSubcategoriesAssociationDrawerState] = useState(false);
	const [contextMenuData, setContextMenuData] = useState({ id: null });
	const [selectedSubcategories, setSelectedSubcategories] = useState({});
	const [allFieldsSelected, setAllFieldsSelected] = useState(false);

	const openSubcategoriesAssociationDrawer = () => {
		setSubcategoriesAssociationDrawerState(true);
	};

	const closeSubcategoriesAssociationDrawer = (success) => {
		setSubcategoriesAssociationDrawerState(false);
		if (success) {
			fetchCategoryDetail(parseInt(match.params.id), dispatch);
		}
	};

	const handleRemoveCategoriesClick = (isMulti) => {
		if (isMulti) {
			setCategoriesRemoveModalState((current) => ({
				...current,
				isOpen: true,
				data: subcategories.filter((subcategory) => selectedSubcategories[subcategory.id])
			}));
			return;
		}

		setCategoriesRemoveModalState((current) => ({
			...current,
			isOpen: true,
			data: subcategories.filter((subcategory) => contextMenuData?.id === subcategory.id)
		}));
		closeContextMenu();
	};

	const renderMenuItems = () => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => handleRemoveCategoriesClick(false)}>
					Remove
				</div>
			</React.Fragment>
		);
	};

	const openContextMenu = (data, props, isMulti) => {
		setContextMenuData({
			id: data?.id,
			data: data
		});
	};

	const closeContextMenu = () => {
		setContextMenuData({
			id: null
		});
	};

	const handleAllFieldsSelection = (state) => {
		if (!state) {
			setSelectedSubcategories({});
			setAllFieldsSelected(false);
			return;
		}

		if (!subcategories) {
			return;
		}

		const updatedSelectedCategories = {};
		subcategories.forEach((category) => {
			updatedSelectedCategories[category?.id] = true;
		});
		setAllFieldsSelected(true);
		setSelectedSubcategories(updatedSelectedCategories);
	};

	const handleSingleFieldSelection = (id) => {
		const updatedSelectedCategories = {
			...selectedSubcategories
		};

		if (selectedSubcategories[id]) {
			delete updatedSelectedCategories[id];
			setSelectedSubcategories(updatedSelectedCategories);
			if (allFieldsSelected) {
				setAllFieldsSelected(false);
			}
			return;
		}

		updatedSelectedCategories[id] = true;
		setSelectedSubcategories(updatedSelectedCategories);
	};

	const subcategoriesRetouched = (subcategories ?? []).map((subcategory) => ({
		...subcategory,
		paginatedItems: {
			...subcategory.paginatedItems,
			id: subcategory.id
		}
	}));

	const renderValuesAndFunctions = {
		contextMenuData,
		openContextMenu,
		closeContextMenu,
		renderMenuItems,
		selectedSubcategories
	};

	return (
		<div className="associated-subcat-tab">
			{!canAssociateSubcategories ? (
				<div className="alert">
					<img src="/assets/icons/alert.svg" />
					<span>Categories with associated items cannot hold subcategories under them.</span>
				</div>
			) : (
				<>
					<div className="header-container">
						<div>
							<div className="header-text">Subcategories</div>
							<div className="header-subtext">
								{`${subcategories?.length === 0 ? "No" : subcategories?.length} ${
									subcategories?.length === 1 ? "category" : "categories"
								} are associated to this category`}
							</div>
						</div>
						{!readOnly && (
							<div>
								<Button clickHandler={openSubcategoriesAssociationDrawer}>Update</Button>
							</div>
						)}
					</div>
					<BulkActions
						selectedCategoriesCount={Object.keys(selectedSubcategories).length}
						handleRemoveClick={handleRemoveCategoriesClick}
					/>
					<FormTable
						columns={columns}
						dataSource={subcategoriesRetouched}
						renderValuesAndFunctions={renderValuesAndFunctions}
						isSelectionEnabled
						handleAllFieldsSelection={handleAllFieldsSelection}
						handleSingleFieldSelection={handleSingleFieldSelection}
						selectedFields={selectedSubcategories}
						isAllFieldSelected={allFieldsSelected}
					/>
					<SubcategoriesAssociationDrawer
						isOpen={subcategoriesAssociationDrawerState}
						close={closeSubcategoriesAssociationDrawer}
						categoryId={categoryId}
					/>
				</>
			)}
		</div>
	);
};

export default AssociatedSubCategories;

const BulkActions = ({ selectedCategoriesCount, handleRemoveClick }) => {
	return (
		<Transition
			native
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0 }}
			items={selectedCategoriesCount > 0}
			config={config.stiff}
		>
			{(isOpen) =>
				isOpen &&
				((props) => (
					<animated.div className="bulk-actions-container" style={props}>
						<div className="selected-entity-counter">{selectedCategoriesCount} location(s) selected</div>
						<div className="actions-container">
							<div className="link-text hyperlink remove-loc" onClick={() => handleRemoveClick(true)}>
								Remove
							</div>
						</div>
					</animated.div>
				))
			}
		</Transition>
	);
};
