import React, { Component } from "react";

// components
import DateFilter from "./DateFilter";
import CheckboxGroupFilter from "./CheckboxGroupFilter";
import NumberRangeFilter from "./NumberRangeFilter";
import TimeRangeFilter from "./TimeRangeFilter";
import MultiSelectFilter from "./MultiSelectFilter";

// graphql
import { CART_CHANNELS, FULFILLMENT_MODES } from "../../client-config";

// third party
import { connect } from "react-redux";

// actions
import { fetchItemsDebounced, fetchStoresDebounced } from "../../actions/actions";

@connect((store) => ({
	configItems: store.configItems
}))
export default class CampaignCart extends Component {
	render() {
		const { filter, configItems } = this.props;
		const cart = filter.cart || {};
		const { items, stores } = configItems;

		return (
			<div className="filter-section-container">
				<DateFilter
					title="Abandoned Cart in"
					root="cart"
					field="abandoned_on"
					value={cart.abandoned_on}
					setFilter={this.props.setFilter}
				/>
				<TimeRangeFilter
					title="Time of day"
					root="cart"
					field="time_of_day"
					value={cart.time_of_day}
					setFilter={this.props.setFilter}
				/>
				<CheckboxGroupFilter
					title="Channel"
					root="cart"
					options={CART_CHANNELS}
					field="channel"
					value={cart.channel}
					setFilter={this.props.setFilter}
				/>
				<CheckboxGroupFilter
					title="Fulfillment Mode"
					root="cart"
					options={FULFILLMENT_MODES}
					field="fulfillment_mode"
					value={cart.fulfillment_mode}
					setFilter={this.props.setFilter}
				/>
				<NumberRangeFilter
					title="Cart value"
					root="cart"
					field="cart_value"
					value={cart.cart_value}
					setFilter={this.props.setFilter}
				/>
				<MultiSelectFilter
					title="Items"
					root="cart"
					options={items.items}
					field="items"
					value={cart.items}
					setFilter={this.props.setFilter}
					isLoading={items.isLoading}
					handleSearch={fetchItemsDebounced}
					labelKey="title"
					valueKey="id"
					excludable={true}
				/>
				<MultiSelectFilter
					title="Stores"
					root="cart"
					options={stores.items}
					field="stores"
					value={cart.stores}
					setFilter={this.props.setFilter}
					isLoading={stores.isLoading}
					handleSearch={fetchStoresDebounced}
					labelKey="name"
					valueKey="id"
					excludable={true}
				/>
			</div>
		);
	}
}
