import React, { useCallback, useState, useEffect } from "react";

// component
import { SelectFilter } from "../_commons/SelectFilter";
import { Textarea } from "../_commons/Textarea";
import { Button } from "../_commons/Button";

// utils
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import moment from "moment";

import { fetchCiLocationGroups, fetchCiLocationGroupsDebounced } from "../../actions/actions";
const BasicInfo = ({
	biz,
	data = {},
	languagesList = [],
	handleForm,
	file,
	imageBase64,
	validations,
	filters = [],
	isDeleted = false,
	readOnly = true,
	inputRef,
	handleImage,
	locationGroups
}) => {
	const [imageHover, setImageHover] = useState(false);
	const [toggleMarkups, setToggleMarkups] = useState(false);
	const [imageDimensions, setImageDimensions] = useState({ w: 0, h: 0 });

	const getImageDimensions = useCallback(() => {
		if (file) {
			const img = new Image();
			img.src = window.URL.createObjectURL(file);
			img.onload = function () {
				setImageDimensions({
					w: this.width || 0,
					h: this.height || 0
				});
			};
		} else if (data.image) {
			const img = new Image();
			img.src = data.image;
			img.onload = function () {
				setImageDimensions({
					w: this.width || 0,
					h: this.height || 0
				});
			};
		}
	}, [data, file]);
	useEffect(() => {
		fetchCiLocationGroups();
	}, []);

	const handleSupportedLanguages = useCallback(() => {
		const supportedLanguages = biz.supportedLanguages.map((lang) => ({
			...lang,
			valueForDisplay: languagesList.find((l) => l.value === lang.value)
				? languagesList.find((l) => l.value === lang.value).valueForDisplay
				: lang.valueForDisplay
		}));
		return supportedLanguages;
	}, [biz, languagesList]);

	const handleSupportedLocations = useCallback(() => {
		const supportedLocations = locationGroups.items.map((loc) => ({
			...loc,
			valueForDisplay: locationGroups.items.find((l) => l.value === loc.value)
				? locationGroups.items.find((l) => l.value === loc.value).valueForDisplay
				: loc.valueForDisplay
		}));
		return supportedLocations;
	}, [locationGroups]);

	const handleImageTypeCurrValue = (imgTypeArray) => {
		if (!imgTypeArray) {
			return;
		}
		const imgTypeOptions = filters.find((f) => f.field === "img_type")?.values || [];
		const updatedCurrImgType = imgTypeArray?.map((type) => {
			if (!type?.value) {
				return {
					value: type,
					valueForDisplay: imgTypeOptions.find((value) => type === value.value)?.valueForDisplay
				};
			}
			return type;
		});
		return updatedCurrImgType;
	};

	const handleLanguagesCurrValue = useCallback(
		(currLanguages = []) => {
			const languages = currLanguages.map((lang) => {
				if (!lang?.value) {
					const language = {
						valueForDisplay:
							handleSupportedLanguages().find((l) => l.value === lang)?.valueForDisplay || lang,
						value: lang
					};
					return language;
				}
				return lang;
			});
			return languages;
		},
		[handleSupportedLanguages]
	);

	const handleDownload = async (url) => {
		await fetch(url.includes("https://") ? url : url.replace("http://", "https://"), {
			method: "GET",
			cache: "no-cache"
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const fileURL = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = fileURL;
				link.download = url.replace(/^.*[\\\/]/, "");
				link.click();
			})
			.catch((error) => {
				console.log(error);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Something went wrong. Please try again later.",
						timeout: 3000,
						error: true
					}
				});
			});
	};

	const handleUrlOpen = (url) => {
		if (url) {
			window.open(url, "_blank");
		}
	};

	const handleFileChange = (e) => {
		handleImage(e.target.files);
	};

	const handleOnDrop = (e) => {
		try {
			e.stopPropagation();
			e.preventDefault();
			const file = e.dataTransfer.files;
			if (file.length > 0 && !file[0].type.includes("image")) {
				throw "Unexpected file format";
			}
			handleImage(file);
		} catch (e) {
			console.log(e);
		}
	};

	const triggerInputFieldClick = () => {
		inputRef.current.value = null;
		inputRef.current.click();
	};

	return (
		<React.Fragment>
			<div className="image-container">
				{file ? (
					<React.Fragment>
						<img className="preview-image" src={imageBase64} onLoad={getImageDimensions} />
						<div className="image-info">
							<div title={file.name}>
								Name:{" "}
								<span>
									{file.name && file.name.length > 20 ? "..." + file.name.slice(-20) : file.name}
								</span>
							</div>
							<div>
								Size:{" "}
								<span>
									{file.size > 1048576
										? `${Number((file.size / 1048576).toFixed(1))} MB`
										: `${Math.round(file.size / 1024)} KB`}
								</span>
							</div>
							<div>
								Dimensions: <span>{`${imageDimensions.w} x ${imageDimensions.h}`}</span>
							</div>
							<div>
								Created: <span>{moment().format("DD MMM, YYYY - hh:mm A")}</span>
							</div>
						</div>
					</React.Fragment>
				) : data.image ? (
					<React.Fragment>
						<div
							onDragOver={(e) => e.preventDefault()}
							onDragEnter={(e) => e.preventDefault()}
							onDragLeave={(e) => e.preventDefault()}
							onDrop={handleOnDrop}
							onClick={triggerInputFieldClick}
							onMouseEnter={() => setImageHover(true)}
							onMouseLeave={() => setImageHover(false)}
						>
							<input
								className="D(n)"
								ref={inputRef}
								type="file"
								accept="image/*"
								onChange={handleFileChange}
							/>
							<img
								className="preview-image"
								src={data.image}
								onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
								onLoad={getImageDimensions}
							/>
							{imageHover && (
								<div className="image-hover-content">
									<div className="replace-info">
										<img
											src="/assets/icons/icon-edit-light.svg"
											alt="Replace image"
											className="replace-icon"
										/>
										<div className="info">
											<span className="bold-text">Drag and drop</span> an image or{" "}
											<span className="bold-text">click</span> to replace
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="image-info-action">
							<div className="info">
								<div className="filename" title={data.image && data.image.split("/").slice(-1)[0]}>
									<span>{data.image && data.image.split("/").slice(-1)[0]}</span>
								</div>
								<div>
									<span className="img-dimension">{`${imageDimensions.w} x ${imageDimensions.h} px`}</span>
								</div>
							</div>
							<div className="action">
								<Button
									type="secondary"
									classes="preview-btn"
									clickHandler={() => handleUrlOpen(data.image)}
								>
									Preview
								</Button>
								<Button classes="download-btn" clickHandler={() => handleDownload(data.image)}>
									Download
								</Button>
							</div>
						</div>
					</React.Fragment>
				) : (
					<div className="no-items-placeholder">No Image found!</div>
				)}
			</div>
			<div className="form-row row-full">
				{!toggleMarkups && (
					<Textarea
						value={data.markups}
						onChange={(e) => handleForm({ markups: e.target.value })}
						readOnly={readOnly || isDeleted}
					>
						<div>Markups</div>
						{data.markups && (
							<div className="toggle" onClick={() => setToggleMarkups(true)}>
								Preview Mode
							</div>
						)}
					</Textarea>
				)}
				{toggleMarkups && (
					<div className="preview-container">
						<div className="preview-label">
							<div>Markups</div>
							<div className="toggle" onClick={() => setToggleMarkups(false)}>
								Write Mode
							</div>
						</div>
						<div className="preview" dangerouslySetInnerHTML={{ __html: data.markups }}></div>
					</div>
				)}
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Image Type"
					options={
						filters.find((f) => f.field === "img_type")
							? filters.find((f) => f.field === "img_type").values
							: []
					}
					readOnly={readOnly || isDeleted}
					field="imgType"
					currValue={handleImageTypeCurrValue(data.imgType)}
					setFilter={(field, value) => handleForm({ [field]: value })}
					labelKey="valueForDisplay"
					valueKey="value"
					multi={true}
					validationMessage={validations.imgType || ""}
					ellipsized={true}
				/>
				{
					<SelectFilter
						title="Location Group"
						options={handleSupportedLocations()}
						isLoading={locationGroups.isLoading}
						field="locationGroups"
						currValue={data.locationGroups}
						setFilter={(field, value) => handleForm({ [field]: value })}
						labelKey="title"
						valueKey="id"
						placeholder="Select"
						isAsync={true}
						multi={true}
						handleSearch={fetchCiLocationGroupsDebounced}
					/>
				}
			</div>
			<div className="form-row row-half">
				{biz.supportedLanguages && biz.supportedLanguages.length > 1 && (
					<SelectFilter
						title="Languages"
						options={handleSupportedLanguages()}
						readOnly={readOnly || isDeleted}
						field="supportedLanguages"
						currValue={handleLanguagesCurrValue(data.supportedLanguages)}
						setFilter={(field, value) => handleForm({ [field]: value })}
						labelKey="valueForDisplay"
						valueKey="value"
						multi={true}
						validationMessage={validations.supportedLanguages || ""}
					/>
				)}
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	filters: store.galleriesList.data.filters,
	biz: store.login.loggedInbizDetail,
	locationGroups: store.configItems.locationGroups
});
export default connect(mapStateToProps)(BasicInfo);
