import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ZeroPercent = ({ item }) => {
	return (
		<div className="action-required-small-title ai-center zero-percent  ">
			<div>
				<img className="validation" src="../../../assets/periscope/correct-icon.svg" />
			</div>
			<span className="info-issues"> No issues</span>
			<div className=" zero-arrow">
				<Link to={String(item.link)}>
					<img className="right-arrow" src="../../../assets/periscope/right-arrow-action.svg" />
				</Link>
			</div>
		</div>
	);
};

export default ZeroPercent;
