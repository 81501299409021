import React from "react";

// components
import AcceptInvite from "./AcceptInvite";

// third party
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const UserVerification = ({ loginDetail, biz }) => {
	const { firstName = "", lastName = "", token = "", verificationDetails } = loginDetail;

	let props = {
		match: { params: { uuid: verificationDetails ? verificationDetails.uuid : "" } },
		userVerification: true,
		bizIsdCode: biz.bizIsdCode,
		firstName,
		lastName,
		token,
		email: verificationDetails ? verificationDetails.email : "",
		phone: verificationDetails ? verificationDetails.phone : "",
		emailValidated: verificationDetails ? verificationDetails.emailValidated : false,
		phoneValidated: verificationDetails ? verificationDetails.phoneValidated : false,
		passwordSet: verificationDetails ? verificationDetails.passwordSet : false
	};

	if (verificationDetails) {
		return <AcceptInvite {...props} />;
	}

	return <Redirect to="/login" />;
};
const mapStateToProps = (store) => ({
	loginDetail: store.login.loginDetail,
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(UserVerification);
