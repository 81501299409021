import React from "react";
import SkeletonLoader from "../SkeletonLoader";

const ItemTableLoading = ({ tableData, error = false, refreshBtn }) => {
	let tableRowsHead = ["brandItem", ...tableData];
	let displayErrorDiv = (
		<div className="error-state">
			<div className="load-chart-div">
				<img className="load-chart-error-icon" src="/assets/periscope/loading-error.svg" />
				<div className="load-chart-info">
					<div className="chart-info">
						<span className="chart-main-text">Could not load this chart!</span>
						<span className="chart-para-info">
							We are facing some issues in fetching this data for you.
						</span>
					</div>
					<div className="try-again" onClick={() => refreshBtn()}>
						<img className="load-again-img" src="/assets/periscope/load-again.svg" />
						<span className="try-again-text">Try Again</span>
					</div>
				</div>
			</div>
		</div>
	);
	return (
		<tbody
			className={`item-table-loading sample-table  ${error == true ? "stop-moving" : ""} `}
			style={{ position: "relative" }}
		>
			{error ? displayErrorDiv : ""}

			{[...Array(8)].map((_, index) => {
				return (
					<tr key={index} className="all" style={{ borderBottom: "1px dashed #EAEAEA" }}>
						{tableRowsHead.map((table, index) => {
							if (table == "brandItem") {
								return (
									<td key={index} style={{ display: "flex" }}>
										<span>
											<SkeletonLoader classname={"intro-icon-loading"} />
										</span>
										<div className="all-loading-span">
											<span>
												<SkeletonLoader classname={"brand-image-loading-item"} />
											</span>
											<span>
												<SkeletonLoader classname={"branditem-text-loading"} />
											</span>
											<span>
												<SkeletonLoader classname={"star-loading-item"} />
											</span>
										</div>
									</td>
								);
							} else {
								return (
									<td key={index}>
										<span>
											<SkeletonLoader classname={"platform-loading-item"} />
										</span>
									</td>
								);
							}
						})}
					</tr>
				);
			})}
		</tbody>
	);
};

export default ItemTableLoading;
