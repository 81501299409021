import React from "react";

// third party
import { Transition, config, animated } from "react-spring/renderprops";

// component
import { CheckBox } from "./CheckBox";
import ChevronIcon from "../_icons/ChevronIcon";

const EntityAccordion = ({
	id,
	isExpanded,
	title,
	children,
	isSelectable,
	isSelected,
	handleFieldSelection,
	handleExpand,
	loading,
	className = "",
	showBorderBottom = false,
	isDragDropEnabled,
	ref,
	showChevron = true,
	parentAccordion = false
}) => {
	const checkboxClickhandler = (e) => {
		e.stopPropagation();
		handleFieldSelection(id);
	};
	return (
		<div className={`entity-accordion-container ${className}`}>
			<div
				className="accordion-title"
				style={{ borderBottom: isExpanded && showBorderBottom ? "1px solid #eaeaea" : "" }}
				onClick={handleExpand}
				ref={ref}
				data-state={isExpanded && parentAccordion ? "expanded" : "collapsed"}
			>
				<div className="title-expander-container">
					<div className={"dropdown-arrow" + (!showChevron ? " dropdown-arrow--invisible" : "")}>
						<ChevronIcon styles={{ rotate: isExpanded ? "0deg" : "-90deg" }} />
					</div>
					{title}
				</div>
				{isSelectable && <CheckBox checked={isSelected} clickHandler={checkboxClickhandler} />}
			</div>
			<Transition
				native
				from={{ height: 0, opacity: 0 }}
				enter={{ height: "auto", opacity: 1 }}
				leave={{ height: 0, opacity: 0, delay: 0 }}
				items={isExpanded}
				config={{ ...config.stiff, ...{ duration: 100 } }}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div
							className={"contents" + (loading ? " loading" : "") + (isExpanded ? " expanded" : "")}
							style={props}
						>
							{children}
						</animated.div>
					))
				}
			</Transition>
		</div>
	);
};
export default EntityAccordion;
