import React, { useEffect, useState } from "react";

// third party
import Compressor from "compressorjs";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import ImageRender from "../_commons/Image";
import { Textarea } from "../_commons/Textarea";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import UploaderV2 from "../_commons/UploaderV2";

// client
import { clientMenu } from "../../client-menu";
import { store } from "../../store/configureStore";

// services
import NotificationServices from "../../services/NotificationService";

// actions
import { fetchParentSectionsList } from "../../actions/menus";

// graphql
import { UPDATE_MENU } from "../../graphql/menus";
import { ActionTypes } from "../../actions/_types";

import { capitaliseText, trackEvent } from "../../atlas-utils";
import { fetchTimingGroupsDebounced, fetchTimingGroups } from "../../actions/actions";
import { connect } from "react-redux";
import { TRACKING_EVENT_NAMES } from "../../client-config";

const CreateSection = ({
	isOpen,
	hideCreateSection,
	sectionCreationData,
	handleSectionCreationForm,
	handleSectionCreation,
	selectedParentSectionData,
	isSubSectionCreation = false,
	isEditSectionMode = false,
	editSectionId = undefined,
	sectionsData = [],
	handleDetailsEditSection,
	menuDetailsData,
	parentSectionInfo,
	isNested = false,
	isFromMenuSection = false,
	message = {},
	timingGroups
}) => {
	const [editSectionInfo, setEditSectionInfo] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const handleEditSectionForm = (field, value) => {
		setEditSectionInfo((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleImage = (files) => {
		const imgFile = files[0];

		if (imgFile) {
			const image = new Image();
			image.src = window.URL.createObjectURL(imgFile);

			// proceed only if the uploaded file is a valid image
			image.onload = async function () {
				if (imgFile.size < 1047520) {
					if (isEditSectionMode) {
						handleEditSectionForm("image", imgFile);
					} else {
						handleSectionCreationForm("image", imgFile);
					}
				} else {
					const result = await new Promise((resolve, reject) => {
						new Compressor(imgFile, {
							quality: 0.6,
							maxWidth: 4000,
							success: resolve,
							error: reject
						});
					});
					if (result && result.size < 1047520) {
						if (isEditSectionMode) {
							handleEditSectionForm("image", result);
						} else {
							handleSectionCreationForm("image", result);
						}
					} else {
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Image size is too large, please keep it below 1 MB",
								timeout: 3000,
								error: true
							}
						});
					}
				}
			};

			// handle error if uploaded file is not an image
			image.onerror = function () {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Please upload a valid image.",
						timeout: 3000,
						error: true
					}
				});
			};
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Upload an image from your current device",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleClearImage = (e) => {
		e.stopPropagation();
		if (isEditSectionMode) {
			if (editSectionInfo?.imageUrl) {
				handleEditSectionForm("imageUrl", "");
			}
			handleEditSectionForm("image", null);
			return;
		}
		handleSectionCreationForm("image", null);
	};

	const handleEditSectionDetails = async () => {
		if (menuDetailsData.name === "") {
			NotificationServices.pushNotification({
				message: "Please fill required details",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}
		try {
			setIsLoading(true);
			const menuUpdationVariables = {
				id: menuDetailsData?.menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: null
						}
					})),
					optionGroups: menuDetailsData.optionGroups,
					items: menuDetailsData.items.map((item) => ({
						...item,
						overrides: { ...item.overrides, image: "" }
					})),
					categories: menuDetailsData.categories.map((category) =>
						category?.id === editSectionId
							? {
									...category,
									overrides: {
										...category.overrides,
										name: editSectionInfo?.name,
										image: editSectionInfo?.image,
										description: editSectionInfo?.description
									}
							  }
							: {
									...category,
									overrides: {
										...category.overrides,
										image: ""
									}
							  }
					)
				}
			};
			const categoryOverridesUpdateResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (categoryOverridesUpdateResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Updated section details successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				trackEvent(TRACKING_EVENT_NAMES.MENU_SECTION_UPDATE, {
					type: isSubSectionCreation ? "subsection" : "section",
					status: "success"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: categoryOverridesUpdateResp?.data?.updateMenuV2
				});
				fetchParentSectionsList(menuDetailsData?.menuId);
				setIsLoading(false);
				handleDetailsEditSection();
			} else {
				NotificationServices.pushNotification({
					message: "Failed to modify section details",
					timeout: 5000,
					type: "failure",
					isClosable: true,
					theme: "dark"
				});
				trackEvent(TRACKING_EVENT_NAMES.MENU_SECTION_UPDATE, {
					type: isSubSectionCreation ? "subsection" : "section",
					status: "failure"
				});
				setIsLoading(false);
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: "Failed to modify section details",
				timeout: 5000,
				type: "failure",
				isClosable: true,
				theme: "dark"
			});
			trackEvent(TRACKING_EVENT_NAMES.MENU_SECTION_UPDATE, {
				type: isSubSectionCreation ? "subsection" : "section",
				status: "failure"
			});
			setIsLoading(false);
			console.log(e);
		}
	};

	const handleClose = () => {
		if (isEditSectionMode) {
			handleDetailsEditSection();
			setEditSectionInfo({});
		} else {
			hideCreateSection();
		}
	};

	const handleSectionCreationClick = async () => {
		if (sectionCreationData?.name === "") {
			NotificationServices.pushNotification({
				message: "Please fill required details",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}
		setIsLoading(true);
		await handleSectionCreation();
		setIsLoading(false);
	};

	useEffect(() => {
		if (editSectionId && isEditSectionMode) {
			const sectionDetails = sectionsData.find((section) => section?.id === editSectionId);
			setEditSectionInfo({ ...sectionDetails, image: null });
		}
	}, [editSectionId, isEditSectionMode]);

	useEffect(() => {
		if (!isEditSectionMode) {
			fetchTimingGroups();
		}
	}, []);

	let styleInfo = {
		backgroundColor: "#FFF5F5",
		borderColor: "#D64949",
		icon: "/assets/icons/error-red-icon.svg"
	};
	if (isFromMenuSection && message) {
		if (message?.warningMsg?.length > 0) {
			styleInfo.backgroundColor = "#FEF6E9";
			styleInfo.borderColor = "#E5B917";
			styleInfo.icon = "/assets/icons/warning-icon.svg";
		}
	}
	return (
		<FormSidebar
			formSidebarClasses="create-section-container"
			isNested={isNested}
			isOpen={isOpen || isEditSectionMode}
			title={
				isEditSectionMode
					? editSectionInfo?.name
					: isSubSectionCreation
					? "Create a sub section"
					: "Create a Section"
			}
			subTitle={
				isSubSectionCreation ? (
					<span>
						Create a sub section for{" "}
						<strong>{selectedParentSectionData?.name || "the selected category"}</strong>
					</span>
				) : (
					""
				)
			}
			close={handleClose}
			submit={isEditSectionMode ? () => handleEditSectionDetails() : handleSectionCreationClick}
			loading={isLoading}
			submitTitle={isEditSectionMode ? "Save" : "Submit"}
		>
			{isFromMenuSection && (message?.errorMsg?.length > 0 || message?.warningMsg?.length > 0) && (
				<div
					className="item-error-msg-wrapper"
					style={{
						borderLeft: `6px solid ${styleInfo.borderColor}`,
						backgroundColor: styleInfo.backgroundColor
					}}
				>
					<img src={styleInfo?.icon} />
					<p>
						{capitaliseText(
							(message?.errorMsg?.length > 0 && message.errorMsg) ||
								(message?.warningMsg?.length > 0 && message.warningMsg)
						)}
					</p>
				</div>
			)}
			<div className="menu-creation-container">
				{isSubSectionCreation && !!parentSectionInfo?.items?.length && (
					<div className="subsection-info">
						<div className="info-icon-container">
							<ImageRender src="/assets/icons/info.png" className="info-icon" />
						</div>
						<div className="info-text">
							<div className="header">Sections can't hold items and sub-sections together</div>
							<div className="sub-header">
								All Items associated with the parent section will automatically be associated with the
								new sub-section
							</div>
						</div>
						{/* <Image
							src="/assets/icons/cancel.png"
							className='icon-close'
						/> */}
					</div>
				)}
				<div className="form-row row-half">
					<InputWithLabel
						requiredLabel
						value={isEditSectionMode ? editSectionInfo?.name : sectionCreationData?.name}
						onChange={(e) =>
							isEditSectionMode
								? handleEditSectionForm("name", e.target.value)
								: handleSectionCreationForm("name", e.target.value)
						}
					>
						Name
					</InputWithLabel>
					<SelectFilter
						title="Schedule association"
						options={timingGroups.items}
						isLoading={timingGroups.isLoading}
						field="timingsGroup"
						readOnly={isEditSectionMode ? true : false}
						currValue={isEditSectionMode ? editSectionInfo.timingsGroup : sectionCreationData?.timingsGroup}
						setFilter={isEditSectionMode ? handleEditSectionForm : handleSectionCreationForm}
						labelKey="title"
						valueKey="id"
						isAsync={true}
						handleSearch={fetchTimingGroupsDebounced}
						showCustomTooltip={false}
						tooltipInfo="Time duration during which the items of this category will be available. - Eg breakfast between 7 AM to 10 AM. Leave it empty if its available through out the store timings"
						tooltipPosition="up"
					/>
				</div>
				<div className="form-row row-full">
					<Textarea
						value={isEditSectionMode ? editSectionInfo?.description : sectionCreationData?.description}
						onChange={(e) =>
							isEditSectionMode
								? handleEditSectionForm("description", e.target.value)
								: handleSectionCreationForm("description", e.target.value)
						}
					>
						Description
					</Textarea>
				</div>
				<div className="form-row row-half">
					<UploaderV2
						onChange={handleImage}
						showDelete={true}
						handleDelete={handleClearImage}
						file={isEditSectionMode ? editSectionInfo?.image : sectionCreationData?.image}
						url={isEditSectionMode ? editSectionInfo?.imageUrl : undefined}
					>
						Cover Image
					</UploaderV2>
				</div>
			</div>
		</FormSidebar>
	);
};

const mapStateToProps = (store) => ({
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(CreateSection);
