import React from "react";
import { extractInitials } from "../../../atlas-utils";
import { connect } from "react-redux";
import { getBrandDetail } from "../../../helpers/periscope";

const LocationBrandCell = ({ props, configItems }) => {
	const brands = configItems?.brands?.items;
	let displayImage = "";
	let ratingText = "";
	let displayName = "";
	const currentBrandDetails = getBrandDetail(props.value.id, brands);
	if (props?.value?.name) {
		const {
			value: { name, rating }
		} = props;
		displayName = currentBrandDetails?.name;

		if (currentBrandDetails?.image.length > 0) {
			displayImage = currentBrandDetails?.image;
		}
		if (rating !== null && rating >= 0) {
			ratingText = (
				<span className={"brand-text-location rating-text-location"}>
					{rating} <img src="/assets/periscope/star-rating.svg" className="star-image-location" />
				</span>
			);
		}
	} else {
		if (currentBrandDetails?.image.length > 0) {
			displayImage = currentBrandDetails?.image;
		}
		displayName = currentBrandDetails?.name;
	}
	let classname = currentBrandDetails?.color;

	return (
		<div className="brand-cell">
			<span
				className={`image-info  logo  ${classname}`}
				style={{ ...(displayImage.length > 0 ? { background: "none" } : {}) }}
			>
				{displayImage ? (
					<img
						src={displayImage}
						style={{ background: "none", borderRadius: "2px" }}
						className="brand-image-location"
					/>
				) : (
					<p className="brand-image-location">{extractInitials(displayName?.split(" "))}</p>
				)}
			</span>
			<span className="brand-text-location inside-text-location">{displayName}</span>
			{ratingText}
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems
});
export default connect(mapStateToProps)(LocationBrandCell);
