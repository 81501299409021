import React from "react";

// component
import { Switch } from "../../_commons/Switch";

// utils
import { trackEvent } from "../../../atlas-utils";

const ShowComparison = ({
	loading = false,
	title = "Show Comparison",
	isChecked = false,
	clickHandler,
	module = "",
	metric = "",
	readOnly = false
}) => {
	const handleToggle = () => {
		clickHandler();
		// track event
		trackEvent("analytics_comparison_toggle", {
			module,
			metric,
			toggle: !isChecked
		});
	};

	return (
		<div className={"show-comparison" + (loading || readOnly ? " disabled" : "")}>
			<Switch title={title} checked={isChecked} clickHandler={handleToggle} readOnly={readOnly} />
		</div>
	);
};
export default ShowComparison;
