import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../../components/_commons/FormSidebar";
import { Button } from "../../components/_commons/Button";

// third party
import { connect } from "react-redux";

// utils
import history from "../../history";

// actions
import { fetchMerakiWebThemeDetails, createMerakiWebWorkspace } from "../../actions/merakiWeb";

// constants
const merakiEditorRoute = "/meraki-web/editor";

const ThemeDetails = ({ match, merakiWebThemeDetails, merakiWebWorkspace }) => {
	const [isSidebarOpen, setSidebarState] = useState(false);
	const { loading, data, error } = merakiWebThemeDetails;
	const [confirmLoading, setConfirmLoading] = useState(false);

	useEffect(() => {
		setTimeout(() => setSidebarState(true), 60);
	}, []);

	useEffect(() => {
		fetchMerakiWebThemeDetails(match.params.name);
	}, [match.params.name]);

	const handleCancel = () => {
		setSidebarState(false);
		setTimeout(() => {
			history.push("/meraki-web/themes");
		}, 100);
	};

	const setupWorkspace = async (theme) => {
		try {
			setConfirmLoading(true);
			await createMerakiWebWorkspace(theme);

			history.push(merakiEditorRoute + `?theme=${theme}`);

			setConfirmLoading(false);
		} catch (error) {
			console.log(error);
			setConfirmLoading(false);
		}
	};

	return (
		<div className="meraki-web-theme-details-container">
			<FormSidebar
				isOpen={isSidebarOpen}
				close={handleCancel}
				title={data.name || "Theme"}
				subTitle="Effortlessly set up your online store and start accepting orders"
				hideActions={true}
				loading={loading || confirmLoading}
				headerRight={
					<Button
						classes={merakiWebWorkspace.data.theme ? "disabled" : ""}
						clickHandler={() => setupWorkspace(data.name)}
					>
						Setup workspace
					</Button>
				}
			>
				<div className="form-content">
					<div className="theme-image-gallery">
						{data.gallery &&
							data.gallery.map((image, i) => (
								<img key={i} className="gallery-item" src={image.src} alt={image.title} />
							))}
					</div>
					<div className="theme-info">
						<div className="theme-description">{data.description || "--"}</div>
						<div>Key Features:</div>
						<ul>{data.features && data.features.map((feature, i) => <li key={i}>{feature.title}</li>)}</ul>
					</div>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	merakiWebThemeDetails: store.merakiWebThemeDetails,
	merakiWebWorkspace: store.merakiWebWorkspace
}))(ThemeDetails);
