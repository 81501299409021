import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { Textarea } from "../_commons/Textarea";

// third party
import { connect } from "react-redux";

// actions
import { fetchTimingGroupsDebounced } from "../../actions/actions";

const AdditionalInfo = ({ timingGroups, data = {}, handleForm, validations, readOnly = true }) => {
	return (
		<React.Fragment>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.itemWeight || ""}
					type="number"
					onChange={(e) => handleForm("itemWeight", e.target.value ? Number(e.target.value) : null)}
					validationMessage={validations.itemWeight || ""}
					readOnly={readOnly}
				>
					Weight (in gms)
				</InputWithLabel>
				<InputWithLabel
					value={data.serves}
					type="number"
					onChange={(e) => handleForm("serves", e.target.value ? parseInt(e.target.value) : null)}
					validationMessage={validations.serves || ""}
					readOnly={readOnly}
				>
					Serves
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<Switch
					title="Pre-order enabled"
					checked={data.preOrderEnabled}
					clickHandler={() => handleForm("preOrderEnabled", !data.preOrderEnabled)}
					validationMessage={validations.preOrderEnabled || ""}
					showCustomTooltip={true}
					tooltipInfo="If this item is available only during specific timings of the day, you can enable it for pre-ordering by customers before start of such timings"
					readOnly={readOnly}
				/>
				<SelectFilter
					title="Pre-order timings"
					options={timingGroups.items}
					readOnly={readOnly}
					isLoading={timingGroups.isLoading}
					field="timingsGroup"
					currValue={data.timingsGroup}
					setFilter={handleForm}
					labelKey="title"
					valueKey="id"
					isAsync={true}
					handleSearch={fetchTimingGroupsDebounced}
					showCustomTooltip={true}
					tooltipInfo="Timings during which customers can pre-order item"
					tooltipPosition="up"
				/>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={data.priceDescriptor}
					onChange={(e) => handleForm("priceDescriptor", e.target.value)}
					validationMessage={validations.priceDescriptor || ""}
					readOnly={readOnly}
				>
					Price description
				</Textarea>
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(AdditionalInfo);
