import React, { useState, useRef, useEffect, useCallback } from "react";

// third party
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Compressor from "compressorjs";
import { connect } from "react-redux";

// component
import DetailsEntry from "../components/BrandsCreate/DetailsEntry";
import Header from "../components/BrandsCreate/Header";
import RectangularProgressbar from "../components/BrandsCreate/RectangularProgressbar";
import Footer from "../components/BrandsCreate/Footer";
import LocationsAssocation from "../components/BrandsCreate/LocationsAssociation";
import EndResult from "../components/BrandsCreate/EndResult";
import ImageSizeWarningModal from "../components/_commons/ImageSizeWarningModal";
import CropModal from "../components/_commons/CropModal";
import BrandsCreationProgress from "../components/BrandsCreate/BrandsCreationProgress";

// utils
import { canvasPreview, dataURLtoFile, generateUniqueId, getPrinterCompatibleImages } from "../atlas-utils";

// client
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { CREATE_GALLERY } from "../graphql/gallery";
import { CREATE_BRAND, GET_ASSOCIATED_BRAND_LOCATIONS } from "../graphql/brands";

// actions
import { ActionTypes } from "../actions/_types";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
	return centerCrop(
		makeAspectCrop(
			{
				unit: "%",
				width: 90
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
};

const ASSOCIATED_LOCATIONS_DATA_INIT_STATE = {
	loading: true,
	data: {
		objects: [],
		filters: []
	}
};

const CURRENT_FILTERS_INIT_STATE = {
	is_active: true
};

// constant
const ASSOCIATION_STATUS_URL = process.env.REACT_APP_LOCATION_CREATE_WIZARD_URL;

const BrandsCreate = ({ brandsCreationLocationAssociationState }) => {
	const [currentProgressLevel, setCurrentProgressLevel] = useState(-1);
	const [isModalOpen, setModalOpen] = useState(false);
	const [brandName, setBrandName] = useState("");
	const [file, setFile] = useState(null);
	const [origImgFile, setOrigImgFile] = useState(null);
	const inputRef = useRef(null);

	// related to cropping
	const [imgSrc, setImgSrc] = useState("");
	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const [endResult, setEndResult] = useState("");
	const [globalLoading, setGlobalLoading] = useState(false);
	const [associatedLocationsData, setAssociatedLocationsData] = useState(ASSOCIATED_LOCATIONS_DATA_INIT_STATE);
	const aspect = 1;
	const scale = 1;
	const rotate = 0;
	const imgRef = useRef();
	const previewCanvasRef = useRef();

	// unorganised
	const [data, setData] = useState({
		imgType: [],
		markups: "",
		supportedLanguages: []
	});

	const [showFilters, setShowFilters] = useState(false);
	const [currentFilters, setCurrentFilers] = useState(CURRENT_FILTERS_INIT_STATE);
	const [cityFilter, setCurrentCityFilter] = useState(undefined);
	const limit = 10;
	const [offset, setOffset] = useState(0);
	const [selectedLocations, setSelectedLocations] = useState({});
	const [allSelected, setAllSelected] = useState(false);
	const [isImageSizeLarge, setIsImageSizeLarge] = useState(false);
	const [locationName, setLocationName] = useState("");
	const [isLocationAssociationInProgress, setLocationAssociationInProgress] = useState(true);
	const [successfullyAssociatedLocationsCount, setSuccessfullyAssociatedLocationsCount] = useState(0);

	const debounceRef = useRef();
	const canvasSaveRef = useRef();
	const imgRenderRef = useRef();
	const exponentialBackOffTimeoutRef = useRef();
	const maxTimeoutLimit = 128;
	let timeout = 1;
	const delayedStatusCheckApiCallRef = useRef();
	const { status } = brandsCreationLocationAssociationState;

	const handleFileSizeAndUpdate = async (file) => {
		if (!file) {
			return;
		}
		setOrigImgFile(file);
		if (file && file.size > 1047520) {
			const result = await new Promise((resolve, reject) => {
				new Compressor(file, {
					quality: 0.6,
					maxWidth: 4000,
					success: resolve,
					error: reject
				});
			});
			if (result && result.size < 1047520) {
				file = result;
			} else {
				setIsImageSizeLarge(true);
				return;
			}
		}
		setFile(file);
		imgRenderRef.current = null;
		setCrop(undefined); // Makes crop preview update between images.
		const reader = new FileReader();
		reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
		reader.readAsDataURL(file);
		setModalOpen(true);
	};

	const onFileChange = (e) => {
		handleFileSizeAndUpdate(e?.target?.files[0]);
	};

	const onImageLoad = (e) => {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	};

	useEffect(() => {
		if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
			canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
		}
	}, [completedCrop]);

	useEffect(() => {
		setTimeout(() => {
			setCurrentProgressLevel(0);
		}, 100);
	}, []);

	const handleCancel = () => {
		setImgSrc("");
	};

	const handlePreviousClick = () => {
		setCurrentProgressLevel((current) => current - 1);
	};

	const handleSubmit = async () => {
		if (previewCanvasRef.current) {
			console.log(previewCanvasRef.current.toDataURL());
		}
		console.log(file);
		if (file) {
			try {
				const variables = {
					...data
				};
				if (file) {
					variables.image = file;
				}
				if (variables.imgType.length) {
					variables.imgType = variables.imgType.map((type) => type.value);
				}
				if (variables.supportedLanguages.length) {
					variables.supportedLanguages = variables.supportedLanguages.map((lang) => lang.value);
				}
				const resp = await client.mutate({
					mutation: CREATE_GALLERY,
					variables
				});
				if (resp.data.saveGallery.status.success) {
					console.log("successfully uploaded image");
				} else {
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
			}
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please upload an image",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	const fetchAsyncLocationsAssociationStatus = async (referenceId) => {
		try {
			const url = `${ASSOCIATION_STATUS_URL}/?reference_id=${referenceId}&brand=True`;
			const token = store.getState().login.loginDetail.token;
			const resp = await fetch(url, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			const jsonifiedResp = await resp.json();

			if (jsonifiedResp?.status === "SUCCESS") {
				const locationsCount = Object.keys(selectedLocations).length;
				setSuccessfullyAssociatedLocationsCount(locationsCount);
				setTimeout(() => {
					setLocationAssociationInProgress(false);
				}, 500);
			} else if (jsonifiedResp?.status === "PROCESSING" || jsonifiedResp === "QUEUED") {
				if (Array.isArray(jsonifiedResp?.locations_data)) {
					setSuccessfullyAssociatedLocationsCount(jsonifiedResp.locations_data.length);
				}
				if (timeout > maxTimeoutLimit) {
					const cancelReqResp = await fetch(url, {
						headers: {
							Authorization: `Bearer ${token}`
						},
						body: JSON.stringify({
							reference_id: referenceId,
							action: "CANCEL"
						})
					});

					const cancelReqRespJson = cancelReqResp.json();
					if (cancelReqResp.status === "SUCCESS") {
						const locationsCount = Object.keys(selectedLocations).length;
						setSuccessfullyAssociatedLocationsCount(locationsCount);
						setTimeout(() => {
							setLocationAssociationInProgress(false);
						}, 500);
					} else if (cancelReqRespJson.status === "PROCESSING") {
						if (Array.isArray(cancelReqRespJson?.locations_data)) {
							setSuccessfullyAssociatedLocationsCount(cancelReqRespJson.locations_data.length);
						}
						timeout = 1;
						exponentialBackOffTimeoutRef.current = setTimeout(() => {
							fetchAsyncLocationsAssociationStatus(referenceId);
						}, timeout * 1000);
					} else {
						setEndResult("failure");
						setLocationAssociationInProgress(false);
					}
					return;
				}
				exponentialBackOffTimeoutRef.current = setTimeout(() => {
					fetchAsyncLocationsAssociationStatus(referenceId);
				}, timeout * 1000);
				timeout *= 2;
			} else {
				setEndResult("failure");
				setLocationAssociationInProgress(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const createBrand = async () => {
		try {
			setGlobalLoading(true);
			const variables = {};
			// get image formats to support printer compatibility
			if (origImgFile) {
				const { base64Image, starLineImage, escPosImage } = await getPrinterCompatibleImages(origImgFile);
				if (base64Image && starLineImage && escPosImage) {
					variables.base64Image = base64Image;
					variables.starLineImage = starLineImage;
					variables.escPosImage = escPosImage;
				} else {
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Image size is too large, please keep it below 1 MB",
							timeout: 3000,
							error: true
						}
					});
					setGlobalLoading(false);
					return;
				}
			}
			if (canvasSaveRef.current) {
				variables.image = canvasSaveRef.current;
			}
			variables.name = brandName;
			variables.locationIds = [...Object.keys(selectedLocations)];

			const resp = await client.mutate({
				mutation: CREATE_BRAND,
				variables
			});
			if (resp.data.saveBrand.status.success) {
				setEndResult("success");
				if (resp?.data?.saveBrand?.referenceId && !!Object.keys(selectedLocations).length) {
					delayedStatusCheckApiCallRef.current = setTimeout(() => {
						if (!status) {
							fetchAsyncLocationsAssociationStatus(resp?.data?.saveBrand?.referenceId);
						}
					}, 30000);
				}
			} else {
				setEndResult("failure");
			}
			setGlobalLoading(false);
		} catch (e) {
			setGlobalLoading(false);
			console.log(e);
			setEndResult("failure");
		}
	};

	const handleNextClick = () => {
		setCurrentProgressLevel((current) => {
			if (current === 1) {
				return current;
			}
			if (previewCanvasRef.current) {
				canvasSaveRef.current = dataURLtoFile(
					previewCanvasRef.current.toDataURL(),
					generateUniqueId() + "_brand_logo.png"
				);
				imgRenderRef.current = previewCanvasRef.current.toDataURL();
			}
			return current + 1;
		});
	};

	const fetchAssociatedBrandLocations = useCallback(async () => {
		try {
			setAssociatedLocationsData((current) => ({
				...current,
				loading: true
			}));
			const variables = {};
			variables.limit = 10;
			variables.offset = offset;
			variables.filters = [
				{
					field: "is_active",
					value: true
				},
				{
					field: "name",
					value: locationName
				}
			];
			if (cityFilter) {
				variables.filters.push({
					field: "city",
					value: cityFilter?.value
				});
			}
			variables.sort = { field: "name", order: "ASC" };
			const resp = await client.query({
				query: GET_ASSOCIATED_BRAND_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.stores) {
				setAssociatedLocationsData((current) => ({
					...current,
					loading: false,
					data: resp?.data?.stores
				}));
				let allSelected = true;
				resp.data.stores.objects.forEach((loc) => {
					if (!selectedLocations[loc.id]) {
						allSelected = false;
					}
				});
				setAllSelected(allSelected);
			}
		} catch (e) {
			setAssociatedLocationsData((current) => ({
				...current,
				loading: false
			}));
			console.log(e);
		}
	}, [cityFilter, locationName, offset]);

	const setFilter = (field, value) => {
		setCurrentFilers((current) => ({
			...current,
			[field]: value
		}));
	};

	const setAndApplyCityFilter = (field, value) => {
		if (!value) {
			const currentFiltersUpdated = {
				...currentFilters
			};
			if (currentFiltersUpdated.city) {
				delete currentFiltersUpdated.city;
			}
			setOffset(0);
			setCurrentCityFilter(undefined);
		} else {
			setOffset(0);
			setCurrentCityFilter(value);
		}
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * limit);
		setAllSelected(false);
	};

	const handleAllFieldSelection = (state) => {
		const updatedSelectedLocations = {
			...selectedLocations
		};
		associatedLocationsData.data.objects.forEach((loc) => {
			updatedSelectedLocations[loc.id] = state;
		});
		setSelectedLocations(updatedSelectedLocations);
		setAllSelected(state);
	};

	const handleSingleFieldSelection = (id) => {
		const updatedSelectedLocations = {
			...selectedLocations
		};
		if (updatedSelectedLocations[id]) {
			delete updatedSelectedLocations[id];
			setAllSelected(false);
		} else {
			updatedSelectedLocations[id] = true;
		}
		setSelectedLocations(updatedSelectedLocations);
	};

	const handleImageChange = () => {
		setIsImageSizeLarge(false);
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const handleImageSizeModalClose = () => {
		setIsImageSizeLarge(false);
	};

	const handleCropModalClose = () => {
		setModalOpen(false);
		setImgSrc("");
	};

	const filterOptions = [
		...(associatedLocationsData?.data?.filters.filter(
			(filter) => filter?.field !== "city" && filter?.field !== "name"
		) || [])
	];
	const cityFilterOptions = [
		...(associatedLocationsData?.data?.filters.filter((filter) => filter?.field === "city") || [])
	];

	useEffect(() => {
		fetchAssociatedBrandLocations();
	}, [cityFilter, offset]);

	useEffect(() => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}
		debounceRef.current = setTimeout(() => fetchAssociatedBrandLocations(), 500);
	}, [locationName]);

	const clearTimers = () => {
		if (exponentialBackOffTimeoutRef.current) {
			clearTimeout(exponentialBackOffTimeoutRef.current);
		}

		if (delayedStatusCheckApiCallRef.current) {
			clearTimeout(delayedStatusCheckApiCallRef.current);
		}
	};

	useEffect(() => {
		return () => clearTimers();
	}, []);

	if (globalLoading || endResult) {
		return (
			<div className="section-container-common brand-creation-flow">
				<BrandsCreationProgress
					endResult={endResult}
					createBrand={createBrand}
					totalLocationsSelected={Object.keys(selectedLocations).length}
					isLocationAssociationInProgress={isLocationAssociationInProgress}
					setLocationAssociationInProgress={setLocationAssociationInProgress}
					successfullyAssociatedLocationsCount={successfullyAssociatedLocationsCount}
					exponentialBackOffTimeoutRef={exponentialBackOffTimeoutRef}
					brandsCreationLocationAssociationState={brandsCreationLocationAssociationState}
				/>
			</div>
		);
	}

	return (
		<div className="section-container-common brand-creation-flow">
			<Header brandName={brandName} currentProgressLevel={currentProgressLevel} previewCanvasRef={imgRenderRef} />
			<RectangularProgressbar totalProgressLevels={2} currentProgressLevel={currentProgressLevel} />
			{currentProgressLevel === 0 && (
				<DetailsEntry
					brandName={brandName}
					setBrandName={setBrandName}
					files={file}
					setFiles={setFile}
					inputRef={inputRef}
					previewCanvasRef={previewCanvasRef}
					onFileChange={onFileChange}
					imgSrc={imgSrc}
					setImgSrc={setImgSrc}
					imgRenderRef={imgRenderRef}
				/>
			)}
			{currentProgressLevel === 1 && (
				<LocationsAssocation
					associatedLocationsData={associatedLocationsData}
					setFilter={setFilter}
					currentFilters={currentFilters}
					filterOptions={filterOptions}
					showFilters={showFilters}
					setShowFilters={setShowFilters}
					cityFilterOptions={cityFilterOptions}
					setAndApplyCityFilter={setAndApplyCityFilter}
					cityFilter={cityFilter}
					handlePagination={handlePagination}
					offset={offset}
					handleAllFieldSelection={handleAllFieldSelection}
					allSelected={allSelected}
					handleSingleFieldSelection={handleSingleFieldSelection}
					selectedLocations={selectedLocations}
					locationName={locationName}
					setLocationName={setLocationName}
				/>
			)}
			<Footer
				currentProgressLevel={currentProgressLevel}
				primaryAction={
					currentProgressLevel === 1
						? () => {
								handleNextClick();
								createBrand();
						  }
						: handleNextClick
				}
				secondaryAction={handlePreviousClick}
				isDisabled={currentProgressLevel === 0 && brandName === ""}
			/>
			<ImageSizeWarningModal
				isOpen={isImageSizeLarge}
				handleSubmit={handleImageChange}
				close={handleImageSizeModalClose}
			/>
			<CropModal
				isOpen={isModalOpen}
				title="Crop & Adjust"
				classes="brand-creation-flow--modal"
				submitAction={() => {
					setModalOpen(false);
				}}
				cancelAction={handleCropModalClose}
				close={handleCropModalClose}
				imgRef={imgRef}
				imgSrc={imgSrc}
				onImageLoad={onImageLoad}
				crop={crop}
				setCrop={setCrop}
				setCompletedCrop={setCompletedCrop}
				aspect={aspect}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	brandsCreationLocationAssociationState: store.brandsCreationLocationAssociationState
});
export default connect(mapStateToProps)(BrandsCreate);
