// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_CATALOGUE_VERIFICATION_LIST, GET_PRISM_VERIFICATION_REPORT } from "../graphql/catalogueVerification";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchCatalogueVerificationList = async () => {
	const { limit, offset, appliedFilters } = store.getState().catalogueVerificationListState;
	const isPrismEnabled = store.getState().login.loggedInbizDetail.modulesEnabled.includes("PRISM");
	const {
		searchFieldSelected = {},
		searchFieldValue = "",
		location,
		platform
	} = store.getState().catalogueVerificationList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CATALOGUE_VERIFICATION_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		if (isPrismEnabled) {
			variables.bizLocationId = parseInt(location.id);
			variables.platformName = platform?.platform?.name || platform?.platformName || null;
		}
		if (location?.brand?.id) {
			variables.brandId = location?.brand?.id;
		}
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if ((f === "channel" || f === "entity_type") && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "error_status") {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "error_status",
				value: "error"
			});
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: isPrismEnabled ? GET_PRISM_VERIFICATION_REPORT : GET_CATALOGUE_VERIFICATION_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_VERIFICATION_LIST_SUCCESS,
			payload: isPrismEnabled
				? { ...(resp.data.prismVerificationReport ?? {}) }
				: { ...resp.data.verificationLogResult },
			isPrismEnabled
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CATALOGUE_VERIFICATION_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
