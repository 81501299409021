import React, { Component } from "react";

// graphql
import { FILTERED_USERS_PREVIEW } from "../../graphql/segments";

// actions
import { fetchSuggestedSegment, fetchSegmentGroups } from "../../actions/segments";
import { createNewCampaign } from "../../actions/campaign";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";
import history from "../../history";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// components
import { Button } from "../_commons/Button";

// assets
import CreateIcon from "../_commons/CreateIcon";

// utils
import { getSuffixedNumber, commifyNumbers } from "../../atlas-utils";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";

@connect((store) => ({
	suggestedSegmentList: store.suggestedSegmentList,
	dimensions: store.configItems.dimensions
}))
export class SuggestedTargetCampaign extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedGroup: ""
		};
	}

	componentDidMount() {
		fetchSegmentGroups(this.fetchSegmentGroupsCallback);
	}

	fetchSegmentGroupsCallback = (segmentGroups) => {
		if (this.props.suggested) {
			segmentGroups = segmentGroups.filter((grp) => grp !== "My Segments");
		}
		this.setState(
			{
				selectedGroup: segmentGroups.length > 0 ? segmentGroups[0] : ""
			},
			() => {
				store.dispatch({
					type: "GET_SEGMENT_GROUPS_SUCCESS",
					payload: segmentGroups
				});
			}
		);
	};

	setFilter = (selectedGroup) => {
		this.setState({
			selectedGroup
		});
	};

	render() {
		const { loading, loadingSegmentGroups, segmentGroups } = this.props.suggestedSegmentList;
		const { alignment, dimensions } = this.props;

		return (
			<div>
				<div className="header">
					<div className="header-text">Suggested target audience for your campaign</div>
					<div className="subheader-text">
						List of customer segments that can be targeted by running a campaign
					</div>
					{alignment === "sidebar" && dimensions.width > 768 && (
						<div onClick={createNewCampaign} className="filter-in-header create-custom-campaign">
							<div className="at-btn">
								<CreateIcon />
								<span>Create campaign</span>
							</div>
						</div>
					)}
				</div>
				{alignment === "sidebar" && dimensions.width <= 768 && (
					<div className="P(10px)">
						<Button clickHandler={createNewCampaign} classes="W(100%)">
							Create Campaign
						</Button>
					</div>
				)}
				<div className={(loading || loadingSegmentGroups ? "disabled" : "") + " campaign-section-container"}>
					{alignment === "sidebar" && (
						<React.Fragment>
							<div className="sidebar-container">
								{loadingSegmentGroups && segmentGroups.length == 0 ? (
									<SidebarPlaceholder />
								) : (
									<CampaignSegmentsSidebar
										clickhandler={this.setFilter}
										groups={segmentGroups}
										filter={this.state.selectedGroup}
									/>
								)}
							</div>
							<div className="data-container height-transation">
								<CampaignSegmentsData
									selectedGroup={this.state.selectedGroup}
									alignment={alignment}
									onSelectFilter={this.props.onSelectFilter}
									activeSegments={true}
								/>
							</div>
						</React.Fragment>
					)}
					{alignment === "topbar" && (
						<React.Fragment>
							<div className="topbar-container">
								{
									<CampaignSegmentsTopbar
										clickhandler={this.setFilter}
										groups={segmentGroups}
										filter={this.state.selectedGroup}
									/>
								}
							</div>
							<div className="topbar-data-container">
								{
									<CampaignSegmentsData
										selectedGroup={this.state.selectedGroup}
										alignment={alignment}
										onSelectFilter={this.props.onSelectFilter}
										activeSegments={true}
									/>
								}
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

const SidebarPlaceholder = () => (
	<div className="Pstart(10px) Pend(10px)">
		<div className="shimmer shimmer--default H(55px) Mb(10px)" />
		<div className="shimmer shimmer--default H(55px)" />
	</div>
);

const SegmentsPlaceholder = () => (
	<div>
		<div className="shimmer shimmer--default H(65px) Mb(10px)" />
		<div className="shimmer shimmer--default H(65px) Mb(10px)" />
		<div className="shimmer shimmer--default H(65px)" />
	</div>
);

@connect((store) => ({
	suggestedSegmentList: store.suggestedSegmentList
}))
class CampaignSegmentsData extends Component {
	componentDidMount() {
		if (this.props.selectedGroup) {
			fetchSuggestedSegment(this.props);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.selectedGroup != nextProps.selectedGroup) {
			fetchSuggestedSegment(nextProps);
		}
	}

	render() {
		const { loading } = this.props.suggestedSegmentList;
		if (loading) {
			return (
				<div>
					<SegmentsPlaceholder />
				</div>
			);
		}
		let nodes = this.props.suggestedSegmentList.data.objects.map((segmentItem, d) => {
			return (
				<CampaignSegmentsDataItem
					onSelectFilter={this.props.onSelectFilter}
					alignment={this.props.alignment}
					key={segmentItem.id}
					{...segmentItem}
				/>
			);
		});
		return <div className="campaign-segments-data">{nodes}</div>;
	}
}

class CampaignSegmentsDataItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0
		};
	}

	// NOTE : it takes time to compute the targated user. So not added to parent segment query
	componentDidMount() {
		let i = setInterval(
			() => {
				this.setState({
					count: Math.ceil(Math.random(0, 100) * 100)
				});
			},
			Math.ceil(Math.random(30, 60) * 100)
		);
		client
			.query({
				query: FILTERED_USERS_PREVIEW,
				variables: {
					segmentId: this.props.id
				}
			})
			.then((resp) => {
				clearInterval(i);
				this.setState({
					count: resp.data.filteredUsersPreview.targeted
				});
			})
			.catch((err) => {
				clearInterval(i);
				console.log(err);
				this.setState({
					count: "--"
				});
			});
	}

	useSegment(filtersString) {
		if (this.props.alignment === "topbar") {
			this.props.onSelectFilter(filtersString);
		} else {
			// track this event
			// PubSub.publish(TRACK_EVENT, {
			// 	tracker: 'mixpanel',
			// 	eventName: 'campaign_create_start',
			// 	eventMeta: {
			// 		source: 'suggestions',
			// 	},
			// });

			const payload = {
				filters: filtersString,
				segmentType: "saved"
			};
			store.dispatch({
				type: "SEGMENT_UPDATE",
				payload
			});
			store.dispatch({
				type: "CREATE_CAMPAIGN_RESET"
			});
			store.dispatch({
				type: "EMAIL_TEMPLATE_RESET"
			});
			window.lS.remove("savedCampaign");
			history.push("/campaigns/new");
		}
	}

	render() {
		let { title, description, filters, alignment } = this.props;
		return (
			<div
				onClick={(e) => {
					this.useSegment(filters);
				}}
				className="segment-item-container"
			>
				<FilteredUserPreview count={this.state.count} />
				<div className="meaning">
					<div className="text--title">{title}</div>
					<div className="text--subtitle">{description}</div>
				</div>
				{alignment === "sidebar" && (
					<div className="action-icon">
						<img src="/assets/icons/icon-right.svg" alt="" />
					</div>
				)}
			</div>
		);
	}
}

const FilteredUserPreview = ({ count }) => {
	let countSuffixed = getSuffixedNumber(count);
	return (
		<div className="stats">
			<div className="amount" title={commifyNumbers(count)}>
				{countSuffixed}
			</div>
			<div className="title">Customers</div>
		</div>
	);
};

const CampaignSegmentsSidebar = (props) => {
	// TODO: make sidebar a generic component, along with its own css.
	let { clickhandler, groups, filter } = props;
	return (
		<div className="at-side-bar">
			{groups.map((i, d) => {
				return (
					<div
						onClick={() => {
							clickhandler(i);
						}}
						key={d}
						className={(i == filter ? "selected" : "") + " selectable sidebar-row sidebar-row--analytics"}
					>
						<div className="text text--small">{i}</div>
					</div>
				);
			})}
		</div>
	);
};

const CampaignSegmentsTopbar = (props) => {
	// TODO: make sidebar a generic component, along with its own css.
	let { clickhandler, groups, filter } = props;
	return (
		<div className="at-top-bar-v2 no-bottom-border">
			{groups.map((i, d) => {
				return (
					<div
						onClick={() => {
							clickhandler(i);
						}}
						key={d}
						className={
							(i == filter ? "selected" : "") + " selectable topbar-column topbar-column--analytics"
						}
					>
						<div className="text text--small">{i}</div>
					</div>
				);
			})}
		</div>
	);
};
