import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { CouponsList } from "./CouponsList";
import CouponCreate from "./CouponCreate";
import CouponEdit from "./CouponEdit";

const CouponsContainer = () => {
	return (
		<div className="coupons-container">
			<Route path="/coupons" component={CouponsList} />
			<Route exact path="/coupons/new" component={CouponCreate} />
			<Route exact path="/coupons/edit/:id" component={CouponEdit} />
		</div>
	);
};
export default CouponsContainer;
