import React from "react";

// third party
import CodeEditor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-dark.css";

const RawHTMLEditor = ({ rawHTMLContent, setRawHTMLContent }) => {
	return (
		<div className="meraki-web-raw-html-section section-container-common">
			<div className="raw-html-editor-container">
				<CodeEditor
					value={rawHTMLContent}
					onValueChange={(code) => setRawHTMLContent(code)}
					highlight={(code) => highlight(code, languages.markup)}
					padding={10}
					style={{
						fontFamily: '"Fira code", "Fira Mono", monospace',
						fontSize: 12
					}}
					placeholder="Place your html code here..."
				/>
			</div>
		</div>
	);
};
export default RawHTMLEditor;
