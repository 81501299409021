// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_CUSTOMER_FEEDBACK_LIST, FEEDBACK_REPLY, GET_CUSTOMER_FEEDBACK_NPS } from "../graphql/customerFeedback";

// utils
import { msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchCustomerFeedbackList = async () => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().customerFeedbackListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().customerFeedbackList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CUSTOMER_FEEDBACK_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// date filter
		if (appliedDateFilter.current.dateFilter) {
			filtersObject.push({
				field: "created",
				value: appliedDateFilter.current.dateFilter
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_CUSTOMER_FEEDBACK_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_FEEDBACK_LIST_SUCCESS,
			payload: { ...resp.data.feedbacks }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_FEEDBACK_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const feedbackReply = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: FEEDBACK_REPLY,
			variables
		});
		if (resp.data.feedbackReply.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.feedbackReply.status.messages),
					timeout: 5000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.feedbackReply.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const getDurationObject = (appliedDateFilter) => {
	const { dateFilter: currDateFilter } = appliedDateFilter.current;
	const currDates = currDateFilter.split(",");
	const obj = {};

	// for current date range
	if (currDates.length === 1) {
		obj.duration = {
			preset: currDateFilter
		};
	} else if (currDates[0] && currDates[1]) {
		obj.duration = {
			custom: {
				start: currDates[0],
				end: currDates[1]
			}
		};
	}

	return obj;
};

export const fetchCustomerFeedbackNps = async () => {
	const { appliedDateFilter } = store.getState().customerFeedbackNpsState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_CUSTOMER_FEEDBACK_NPS_REQUEST
	});
	try {
		const durationObject = getDurationObject(appliedDateFilter);
		const variables = {
			...durationObject
		};
		const resp = await client.query({
			query: GET_CUSTOMER_FEEDBACK_NPS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_FEEDBACK_NPS_SUCCESS,
			payload: { ...resp.data.nps }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_CUSTOMER_FEEDBACK_NPS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
