import React from "react";

const OrderClassificationComponent = ({ handleMouseEnter, handleMouseLeave, processingStatus }) => (
	<div className="order-classification-component">
		<div className="classification general order-total">
			<div className="title">Total Count</div>
			<div className="value">{processingStatus?.orderTotal || 0}</div>
		</div>
		<div className="classification missing">
			<div className="title">Missing in Payout</div>
			<div className="value ">{processingStatus?.missing_orders || 0}</div>
		</div>
		<div className="classification inconsistent">
			<div className="title">Inconsistent</div>
			<div className="value">{processingStatus?.inconsistent_orders || 0}</div>
		</div>
		<div className="classification resolved">
			<div className="title">Manually Reconciled</div>
			<div className="value">{processingStatus?.resolved_orders || 0}</div>
		</div>
		<div className="classification general">
			<div className="title">
				Others
				<span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					<img src="/assets/icons/info.png" alt="info" className="info-icon" />
				</span>
			</div>
			<div className="value">
				{(processingStatus?.reconciled_orders || 0) + (processingStatus?.open_orders || 0)}
			</div>
		</div>
	</div>
);
export default OrderClassificationComponent;
