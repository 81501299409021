import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";

// third party
import classNames from "classnames";

function ChooseOnboardingMethod({
	methods = [],
	setSelectedOnboardingMethod = () => {},
	headerText = "",
	subHeaderText = ""
}) {
	const handleCardSelection = (method) => {
		if (method.comingSoon) return;
		setSelectedOnboardingMethod(method);
	};
	return (
		<div className="choose-onboarding-method-container">
			<div className="choose-onboarding-method-header">
				<Text className="choose-onboarding-method-header__title" variant={TextVariant.HEADING3}>
					{headerText}
				</Text>
				{subHeaderText ? (
					<Text className="choose-onboarding-method-header__sub-title" variant={TextVariant.BODY2}>
						{subHeaderText}
					</Text>
				) : null}
			</div>

			<div className="choose-onboarding-method-body">
				{methods?.map((method) => (
					<OnboardingMethodCards key={method.id} method={method} onCardClick={handleCardSelection} />
				))}
			</div>
		</div>
	);
}

export default ChooseOnboardingMethod;

const OnboardingMethodCards = ({ method, onCardClick }) => {
	const { id, title, recommended, image, comingSoon } = method;
	return (
		<div
			className={"onboarding-method-card" + (comingSoon ? " coming-soon" : "")}
			onClick={() => onCardClick(method)}
		>
			<img className="onboarding-method-card__image" src={image} alt={title} />
			<div className="onboarding-method-card__content">
				{comingSoon ? (
					<Text
						className={classNames(`onboarding-method-card__content__coming-soon`, {
							"onboarding-method-card__content__coming-soon--hidden": !comingSoon
						})}
						variant={TextVariant.BODY1}
					>
						Coming Soon
					</Text>
				) : (
					<Text
						className={classNames(`onboarding-method-card__content__recommended`, {
							"onboarding-method-card__content__recommended--hidden": !recommended
						})}
						variant={TextVariant.BODY1}
					>
						Recommended
					</Text>
				)}
				<Text className="onboarding-method-card__content__title" variant={TextVariant.HEADING4}>
					{title}{" "}
					<img className="title-arrow-icon" src="/assets/icons/icon-right-two-black.svg" alt="arrow-right" />
				</Text>
			</div>
		</div>
	);
};
