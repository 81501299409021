import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import BasicInfo from "../components/UserEdit/BasicInfo";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// utils
import { store } from "../store/configureStore";
import history from "../history";
import { removeProp, adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchCiListGroups } from "../actions/actions";
import { fetchUsersList, fetchUserDetail, editUserDetail } from "../actions/users";
import { ActionTypes } from "../actions/_types";
import { fetchStores } from "../actions/actions";

// reducers
import { userDetailsReducer, USER_DETAILS_INITIAL_STATE } from "../reducers/users";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constants
import { NESTED_ENTITY_TYPES } from "../client-config";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const UserEdit = ({
	access,
	match,
	loggedInUsername,
	readOnly = false,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [userDetails, dispatch] = useReducer(userDetailsReducer, USER_DETAILS_INITIAL_STATE);
	const { loading, data, error } = userDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiListGroups();
		fetchStores("", 50);
	}, []);

	useEffect(() => {
		fetchUserDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_USERS_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchUsersList();
					history.push("/access");
				}
			}, 100);
		}
	};

	const handleForm = (field, value) => {
		dispatch({
			type: ActionTypes.UPDATE_USERS_DETAIL,
			payload: {
				[field]: value
			}
		});
		// remove validation
		if (error?.fields?.[field]) {
			dispatch({
				type: ActionTypes.EDIT_USERS_DETAIL_FAILURE,
				error: {
					...error,
					fields: {
						...(error.fields ?? {}),
						[field]: ""
					}
				}
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		// check if any roles are added
		if (data?.groups?.length === 0) {
			dispatch({
				type: ActionTypes.EDIT_USERS_DETAIL_FAILURE,
				error: {
					...error,
					fields: {
						...(error.fields ?? {}),
						groups: "This field is required."
					}
				}
			});
			return;
		}
		const sanitisedData = removeProp(data, "__typename");
		const resp = await editUserDetail(sanitisedData, dispatch);
		if (resp) {
			setFormTouched(false);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="users-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.firstName || "User"}
				subTitle="Edit this user"
				submitTitle="Save"
				loading={loading}
				disabled={!isFormTouched}
				isNested={isNested}
				hideActions={formTab === FORM_TABS[0].value && !isFormTouched}
				headerRight={
					<React.Fragment>
						{!readOnly && access.isAdmin && loggedInUsername !== data.username && (
							<Button
								classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
								clickHandler={() => setArchiveRestore(true)}
							>
								{data.isActive ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							readOnly={readOnly}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[16]}
						entityName={data.username}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						fieldName="isActive"
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	loggedInUsername: store.login.loginDetail.username
}))(UserEdit);
