import React, { useState, useEffect, useRef, useCallback } from "react";

// components
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { SearchFilter } from "../components/_commons/SearchFilter";
import ExportReport from "../components/Reports/ExportReport";
import Placeholder from "../components/_commons/Placeholder";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// utils
import { scroll, triggerSurvey } from "../atlas-utils";

// actions
import { fetchReportsList } from "../actions/reports";

// constant
const FORM_TABS = [
	{
		id: 1,
		label: "Ordering",
		value: "ordering"
	},
	{
		id: 2,
		label: "Loyalty",
		value: "loyalty"
	},
	{
		id: 3,
		label: "Miscellaneous",
		value: "general"
	}
];
const EXPORT_REPORT_INITIAL_STATE = {
	isOpen: false,
	id: null,
	title: null,
	group: null,
	filters: []
};

const ReportsContainer = ({ reportsList }) => {
	const [formTabs, setFormTabs] = useState([]);
	const [formTab, setFormTab] = useState(FORM_TABS[0]);
	const { loading, data, error } = reportsList;
	const [reports, setReports] = useState([]);
	const [currSearchFilter, setCurrSearchFilter] = useState("");
	const [appliedSearchFilter, setAppliedSearchFilter] = useState("");
	const [exportReport, setExportReport] = useState(EXPORT_REPORT_INITIAL_STATE);
	const topRef = useRef();

	useEffect(() => {
		fetchReportsList();
	}, []);

	const switchTab = (tab) => {
		setFormTab(tab);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleReports = useCallback(() => {
		let reports = data.find((r) => r.group === formTab.value)
			? data.find((r) => r.group === formTab.value).reports
			: [];
		if (appliedSearchFilter) {
			const regex = new RegExp(appliedSearchFilter, "gi");
			reports = reports.filter((r) => r.title.match(regex) !== null || r.description.match(regex) !== null);
		}

		// hide the tabs dynamically if there are no reports in group
		let formTabs = [];
		data.forEach((d) => {
			if (d.reports.length > 0) {
				if (FORM_TABS.find((t) => t.value === d.group)) {
					formTabs.push(FORM_TABS.find((t) => t.value === d.group));
				}
			}
		});
		formTabs.sort((a, b) => a.id - b.id);
		setFormTabs(formTabs);

		setReports(reports);
	}, [data, formTab, appliedSearchFilter]);

	useEffect(() => {
		handleReports();
	}, [handleReports]);

	const applySearchFilter = useCallback(
		debounce((searchKeywords) => {
			setAppliedSearchFilter(searchKeywords);
		}, 500),
		[]
	);

	const handleSearch = useCallback(
		(field, value) => {
			setCurrSearchFilter(value);
			applySearchFilter(value);
		},
		[applySearchFilter]
	);

	const openExportReportSidebar = (id, title, filters) => {
		setExportReport({
			isOpen: true,
			id,
			title,
			group: formTab.label,
			filters
		});
	};

	const closeExportReportSidebar = (refresh = false, surveyRequired = false) => {
		if (surveyRequired) {
			triggerSurvey("survey reports module");
		}
		setExportReport(EXPORT_REPORT_INITIAL_STATE);
		fetchReportsList();
	};

	return (
		<div className="reports-section section-container-common" ref={topRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Reports</div>
					<div className="subheader-text">Request your summary reports from here</div>
				</div>
				<SearchFilter
					filterOption={{ field: "currSearchFilter" }}
					value={currSearchFilter}
					setFilter={(field, value) => handleSearch(field, value.replace(/[\\+*()?]/g, ""))}
					placeholder="Search"
				/>
			</div>
			<Topbar tabs={formTabs} selectedTab={formTab.value} switchTab={switchTab} isStickyOnTop={true} />
			<div className="reports-list-container">
				<Reports data={reports || []} loading={loading} handleExport={openExportReportSidebar} />
				<ExportReport isOpen={exportReport.isOpen} close={closeExportReportSidebar} data={exportReport} />
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	reportsList: store.reportsList
});
export default connect(mapStateToProps)(ReportsContainer);

const Reports = ({ data = [], loading = false, handleExport }) => {
	const placeholderContent = {
		placeholderText: "Reports will be available soon!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-reports.svg",
		placeholderSubtext: "Download all kind of business reports to quickly share with your team",
		size: "medium"
	};
	return (
		<div>
			{data.length > 0 &&
				data.map((report, i) => (
					<div className="report-card-container" key={i} data-testid="report-card-container">
						<div className="header-container">
							<div>
								<div className="header-title">{report.title}</div>
								{report.description && <div className="header-desc">{report.description}</div>}
							</div>
						</div>
						<Button clickHandler={() => handleExport(report.id, report.title, report.filters)}>
							Export
						</Button>
					</div>
				))}
			{data.length === 0 && !loading && <Placeholder {...placeholderContent} />}
			{data.length === 0 && loading && (
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			)}
		</div>
	);
};
