import React from "react";
import { connect } from "react-redux";
import StackProgressBar from "./StackProgressBar";
import SkeletonLoader from "../SkeletonLoader";
import Tooltip from "./Tooltip";
import { removeTrailingZeros } from "../../../helpers/periscope";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../../history";
import Popover from "../../_commons/Popover";
import { trackEvent } from "../../../atlas-utils";

const ItemAvailability = ({ periscopeDashboard }) => {
	const itemMetrics = periscopeDashboard.itemMetrics;
	const totalItems = itemMetrics?.data?.totalItems;
	const offlineItems = itemMetrics?.data?.offlineItems || 0;
	const partialOfflineItems = itemMetrics?.data?.partialOfflineItems || 0;
	const isLoading = itemMetrics?.loading;
	const error = itemMetrics?.error;
	const dashboardMetrics = periscopeDashboard.dashboardMetrics;

	let offlineItemsPercent = dashboardMetrics?.data?.offlineItemsPercentage?.toFixed(2) || 0;
	if (offlineItemsPercent) {
		offlineItemsPercent = removeTrailingZeros(offlineItemsPercent);
	}

	const itemStackProgressBarData = [
		{
			itemOfflineCount: Math.ceil(itemMetrics?.data?.offlineItemsPercentage) || 0,
			itemPartialOfflineCount: Math.ceil(itemMetrics?.data?.partialOfflineItemsPercentage) || 0,
			itemRemainingCount:
				100 - (itemMetrics?.data?.offlineItemsPercentage + itemMetrics?.data?.partialOfflineItemsPercentage) ||
				0
		}
	];

	const progressBarKeys = ["itemOfflineCount", "itemPartialOfflineCount", "itemRemainingCount"];
	const keysColorMapping = {
		itemOfflineCount: "#B02B2B",
		itemPartialOfflineCount: "#FFA05B",
		itemRemainingCount: "#C6D2FF"
	};

	const renderPopover = (data) => {
		return (
			<div className="title-desc pop-info">
				<span className="main-title">{data.title}</span>
				<span className="info-desc">{data.description}</span>
			</div>
		);
	};

	const handleEvent = () => {
		trackEvent("periscope_module_accessed", {
			module: "item_availability",
			source: "home"
		});
	};

	return (
		<div className="  availability border">
			<div className="title-action-peri ai-center pad-left-16">
				Item Availability
				<div className="tooltip ml-4">
					<Popover
						data={{
							title: "Item Availability",
							description: "Breakdown of items that went offline on all or certain storefronts"
						}}
						renderPopover={renderPopover}
						position={"down-left"}
						showOnHover={true}
						showOnClick
					>
						<img src="../../../assets/periscope/tooltip-icon.svg" className="tool-peri" />
					</Popover>
				</div>
			</div>
			<div className="information-container pad-left-16">
				<div className="available-indicator-periscope justify-content-between ai-center">
					<div className="ai-center">
						<img className="main-icon-availability" src="../../../assets/periscope/spoon-white.svg" />
						<span className="small-size-text ">Total Items</span>
					</div>
					<span className="no-of-locations-peri">{totalItems}</span>
				</div>
				{isLoading || error ? (
					<div>
						<SkeletonLoader classname={`loader-availability ${error == true ? "stop-moving" : ""}`} />
					</div>
				) : (
					<>
						{offlineItems > 0 || partialOfflineItems > 0 ? (
							<div className="bargraph-dashboard">
								<div className="">
									<StackProgressBar
										outerDivClass={"item-loc-availability"}
										data={itemStackProgressBarData}
										keysColorMapping={keysColorMapping}
										keys={progressBarKeys}
										padding={1}
										borderRadius={2}
									/>
								</div>
								<div className="legend-peri ai-center">
									{offlineItems > 0 ? (
										<div
											className="graph-peri"
											onClick={() => {
												handleEvent();
												history.push("/periscope/item-availability?status=offline");
											}}
										>
											<div className="graph-text">
												<div className="circle offline"></div>
												<span className="status">Offline</span>
												<div className="tooltip">
													<Popover
														data={{
															title: "Offline Items",
															description: "Items that are offline on all storefronts"
														}}
														renderPopover={renderPopover}
														position={"down-left"}
														showOnHover={true}
														showOnClick
													>
														<img
															src="../../../assets/periscope/tooltip-icon.svg"
															className="tool-peri"
														/>
													</Popover>
												</div>
											</div>
											<div>
												<span className="info">{offlineItems}</span>
												{/* <img
													className="arrow-top"
													src="../../../assets/periscope/icons8-arrow-top.svg"
												/> */}
											</div>
										</div>
									) : (
										""
									)}

									{partialOfflineItems > 0 ? (
										<div
											className="graph-peri"
											onClick={() => {
												handleEvent();
												history.push("/periscope/item-availability?status=partial_offline");
											}}
										>
											<div className="graph-text ai-center">
												<div className="circle partial-offline"></div>
												<span className="status">Partially Offline</span>

												<div className="tooltip move-left">
													<Popover
														data={{
															title: "Partially Offline Items",
															description:
																"Items that are offline on one or more storefronts"
														}}
														renderPopover={renderPopover}
														position={"down-right"}
														showOnHover={true}
														showOnClick
													>
														<img
															src="../../../assets/periscope/tooltip-icon.svg"
															className="tool-peri"
														/>
													</Popover>
												</div>
											</div>
											<div>
												<span className="info"> {partialOfflineItems} </span>
												{/* <img
													className="arrow-top"
													src="../../../assets/periscope/icons8-arrow-top.svg"
												/> */}
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						) : (
							<div className="online-locations ">
								<div>
									<img className="validation" src="../../../assets/periscope/correct-icon.svg" />
								</div>
								<div className="display">
									<span className="total-locations">{totalItems}</span>
									<span className="online"> Items Available</span>
								</div>
							</div>
						)}
					</>
				)}
			</div>
			<div
				className="info-display-periscope justify-content-between pad-left-16"
				onClick={() => {
					handleEvent();
					history.push("/periscope/item-availability");
				}}
			>
				<div className="ai-center">
					<img className="image-info-peri " src="../../../assets/periscope/spoon-black.svg" />
					<span className="small-size-text ">Item unavailable across storefronts</span>
				</div>
				<div className="ai-center percentage-display-peri">
					<div
						className={`availability-percentage justify-content-center  ${
							offlineItemsPercent > 0 ? "availability-red" : "availability-green"
						} `}
					>
						{isLoading || error ? (
							<SkeletonLoader classname={`show-percentage ${error == true ? "stop-moving" : ""}`} />
						) : (
							<span>{offlineItemsPercent}%</span>
						)}
					</div>
					<img className="arrow-periscope" src="../../../assets/periscope/sidearrow.svg" />
				</div>
			</div>
			<div className="last-div-la-periscope pad-left-16" onClick={() => handleEvent()}>
				<Link to="/periscope/item-availability">
					<span className="ai-center medium-size-text detailed-info-peri hyperlink hyperlink--black-color">
						View all items
						<img className="right-arrow-peri" src="../../../assets/periscope/right-arrow.svg" />
					</span>
				</Link>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	periscopeDashboard: state.periscopeDashboard
});
export default connect(mapStateToProps)(ItemAvailability);
