import React, { useMemo } from "react";
import Bar from "../../../NivoCharts/Bar";
import { connect } from "react-redux";
import { calculatePercentages } from "../../../../helpers/analytics";
import { commifyNumbers } from "../../../../atlas-utils";

const MultiLineBar = ({ columnDataWidth = 0, rowTitle, currIndex, metric, compareAnalytics }) => {
	const currentData = compareAnalytics?.columnData?.[metric].data?.[currIndex];
	const tableData = compareAnalytics.tableData?.[metric]?.[currentData?.valueForDisplay];
	if (!tableData) {
		return null;
	}

	const data = tableData?.[rowTitle];
	let completed;
	let lost;
	if (data?.objects) {
		completed = data?.objects[0][0]?.Completed;
		lost = data?.objects[0][0]?.Lost;
	}

	return (
		<>
			{(data?.loading || data?.error) && (
				<div className="compare-loading">
					<div className={`${data?.loading ? "shimmer" : "error"}  W${100}% H(${"16px"}) `} />
					<div className="single-line-graph">
						<div className={` ${data?.loading ? "shimmer" : "error"} box`}></div>
						<div className={` ${data?.loading ? "shimmer" : "error"} box`}></div>
					</div>
				</div>
			)}
			{!data?.loading && !data?.error && (
				<>
					{(lost == undefined && completed == undefined) || (completed == 0 && lost == 0) ? (
						<div className="not-enough-state">
							<img src="/assets/analytics/compare/barChart.svg" className="bar-svg" />
							<div className="text-div">
								<div className="main-text">Not enough data</div>
								<div className="para">
									Try selecting another date range or check back later once more data is collected.
								</div>
							</div>
						</div>
					) : (
						<div className="compare-bar">
							<Bar
								height={55}
								data={data?.objects?.[0] || [{}]}
								animate={false}
								marginBottom={135}
								marginTop={-20}
								marginLeft={0}
								marginRight={0}
								keys={["Completed", "Lost"]}
								patternIds={[{ match: { id: "previous" }, id: "lines" }]}
								groupMode={"stacked"}
								layout="horizantal"
								enableGridY={false}
								showAxisBottom={false}
								showAxisLeft={false}
								enableLegends={false}
								padding={0}
								innerPadding={0}
								isInteractive={false}
								customOuterLabel
								renderCustomOuterLabel={(props, i, data) => {
									let values = data?.[0];
									const calculatePercentage = calculatePercentages(
										[{ value: values?.Completed }, { value: values?.Lost }],
										"value",
										false
									);
									return (
										<foreignObject
											key={i}
											width={columnDataWidth - 57}
											height="576px"
											transform={`translate(${10},
                              ${props?.y + 30 + (props?.height - 18) / 2}) 
                             `}
										>
											{data[0]?.Completed >= 0 && (
												<div className="single-line-graph">
													<div className="blocks">
														<div className="percent">
															Completed {calculatePercentage?.[0]?.percentage}%
														</div>
														<div className="indexValue">
															{commifyNumbers(Math.round(data[0]?.Completed))}
														</div>
													</div>
													<div className="blocks">
														<div className="percent">
															Lost {calculatePercentage?.[1]?.percentage}%
														</div>
														<div className="indexValue align-right">
															{commifyNumbers(Math.round(data[0]?.Lost))}
														</div>
													</div>
												</div>
											)}
										</foreignObject>
									);
								}}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	configItems: store.configItems,
	isMultibrandEnabled: store.login?.loggedInbizDetail?.isMultibrandEnabled,
	login: store.login,
	compareAnalytics: store.compareAnalytics
}))(MultiLineBar);
