import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ITEM_GROUPS, UPDATE_ITEM_GROUPS_ASSOCIATION } from "../../graphql/items";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
const FILTER_INITIAL_STATE = {
	title: ""
};
const ITEM_GROUP_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const ItemGroupItemAssociation = ({ itemId, isOpen, close }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [itemGroupData, setItemGroupData] = useState(ITEM_GROUP_INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [itemGroupUpdates, setItemGroupUpdates] = useState({});
	const tableRef = useRef();
	const totalChanges = Object.keys(itemGroupUpdates).length;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				itemId: parseInt(itemId),
				limit,
				offset
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_ITEM_GROUPS,
				variables,
				fetchPolicy: "no-cache"
			});
			setItemGroupData(resp.data.itemItemGroupsAssociations);
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [itemId, limit, offset, appliedFilters]);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const syncData = useCallback(() => {
		setItemGroupData({
			...itemGroupData,
			objects: itemGroupData.objects.map((item) => {
				if (itemGroupUpdates[item.id] !== undefined) {
					return {
						...item,
						isAssociated: itemGroupUpdates[item.id]
					};
				}
				return item;
			})
		});
		setItemGroupUpdates({});
	}, [itemGroupData, itemGroupUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setItemGroupData(ITEM_GROUP_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setItemGroupUpdates({});
			setIsCheckedAll(false);
			close(refresh);
		},
		[close]
	);

	const handleUpdate = useCallback(async () => {
		try {
			setConfirmLoading(true);
			const variables = {
				itemId: parseInt(itemId),
				itemGroupsToAssociate: [],
				itemGroupsToDisassociate: []
			};
			for (let id in itemGroupUpdates) {
				if (itemGroupUpdates[id]) {
					variables.itemGroupsToAssociate.push(parseInt(id));
				} else {
					variables.itemGroupsToDisassociate.push(parseInt(id));
				}
			}

			const resp = await client.mutate({
				mutation: UPDATE_ITEM_GROUPS_ASSOCIATION,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateItemGroupAssociation.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message:
							resp.data.updateItemGroupAssociation.status.messages[0].message === "Success"
								? "Item groups association updated!"
								: resp.data.updateItemGroupAssociation.status.messages[0].message,
						timeout: 2000,
						error: false
					}
				});
				handleClose(true);
				syncData();
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateItemGroupAssociation.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [itemId, itemGroupUpdates, fetchData, syncData, handleClose]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setItemGroupUpdates({
			...itemGroupUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		itemGroupData.objects.forEach((item) => {
			if (!item.includesAll) {
				updates[item.id] = toCheckAll;
			}
		});
		setItemGroupUpdates({
			...itemGroupUpdates,
			...updates
		});
	};

	return (
		<div className="item-group-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Item Groups"
				subTitle={<div>Manage item groups for this item</div>}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
						{totalChanges === 0 ? "Update" : `Update ${totalChanges} Item Group(s)`}
					</Button>
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-container">
						<InputWithLabel
							value={currFilters.title}
							onChange={(e) => setFilter("title", e.target.value)}
							placeholder="Enter Name"
						>
							Name
						</InputWithLabel>
					</div>
					<Table
						data={itemGroupData.objects || []}
						loading={loading}
						handleCheck={handleCheck}
						itemGroupUpdates={itemGroupUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
					/>
					<Paginator limit={limit} offset={offset} count={itemGroupData.count} goToPage={handlePagination} />
				</div>
			</FormSidebar>
		</div>
	);
};
export default ItemGroupItemAssociation;

export const Table = ({ data, loading, handleCheck, isCheckedAll, handleCheckAll, itemGroupUpdates, sortInfo }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container item-group-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader isCheckedAll={isCheckedAll} handleCheckAll={handleCheckAll} sortInfo={sortInfo} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							itemGroupUpdates={itemGroupUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No item groups found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, sortInfo }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<div className={"checkbox-sort" + (sortInfo?.field === "associated" ? " selected" : "")}>
				<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Name">
					<span>Name</span>
				</CheckBox>
			</div>
		</div>
	</div>
);

export const TableList = ({ id, title, isAssociated, includesAll, style, handleCheck, itemGroupUpdates }) => {
	const status = itemGroupUpdates[id] === undefined ? isAssociated : itemGroupUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name" title={title}>
				<CheckBox
					checked={status}
					clickHandler={() => handleCheck(id, !status)}
					title={title || id}
					readOnly={includesAll}
				>
					{title || id}
				</CheckBox>
			</div>
		</animated.div>
	);
};
