import React, { Component } from "react";

// components
import AnalyticsGraph from "./AnalyticsGraph";
import { OrderChannels, OrderChannelBlockPlaceholder } from "./OrderChannels";
import Placeholder from "../_commons/Placeholder";

// clients
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";

@connect((store) => ({
	dashboardState: store.dashboardState,
	anaOrderChannels: store.anaOrderChannels,
	selectedModule: store.selectedModule,
	anaGraphData: store.anaGraphData
}))
export default class AnalyticsData extends Component {
	changeCriteria = (criteriaValue) => {
		store.dispatch({
			type: "DASHBOARD_STATE_CHANGE",
			payload: {
				criteriaValue
			}
		});
	};

	render() {
		let { criteriaValue } = this.props.dashboardState;
		let {
			anaGraphData,
			topic,
			duration,
			anaOrderChannels,
			selectedModule: { productType }
		} = this.props;

		const placeholderContent = {
			placeholderText: "Crunching your numbers!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-dashboard.svg",
			placeholderSubtext:
				"View insights about your restaurant performance with metrics like sales, revenue, and trends helping you to take data driven decisions for your business",
			size: "medium"
		};

		return (
			<div>
				{productType === "ORDERING" && (
					<div>
						{anaOrderChannels.data.length > 0 ? (
							<OrderChannels
								orderChannels={anaOrderChannels.data}
								changeCriteria={this.changeCriteria}
								criteriaValue={criteriaValue}
								loading={anaOrderChannels.loading}
								topic={topic}
							/>
						) : (
							<OrderChannelBlockPlaceholder />
						)}
					</div>
				)}
				<div className={(anaGraphData.loading ? "disabled" : "") + " ana-graph-container"}>
					{anaGraphData.data.current.length ? (
						<AnalyticsGraph duration={duration} anaGraphData={anaGraphData} />
					) : anaGraphData.loading ? (
						<div className="no-items-placeholder">Fetching data!</div>
					) : (
						<Placeholder {...placeholderContent} />
					)}
				</div>
			</div>
		);
	}
}
