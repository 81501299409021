import React, { useCallback } from "react";

// third party
import { connect } from "react-redux";

// components
import { OrderChannels, OrderChannelBlockPlaceholder } from "../Dashboard/OrderChannels";
import AnalyticsGraph from "../Dashboard/AnalyticsGraph";
import Placeholder from "../_commons/Placeholder";

// utils
import { trackEvent } from "../../atlas-utils";
import { getDurationObject } from "../../actions/salesAnalytics";

const Trends = ({
	salesAnalyticsTrendsChannels,
	salesAnalyticsTrendsGraph,
	productType,
	salesAnalyticsState,
	updateSalesAnalyticsState
}) => {
	const { analyticsTopic, criteriaValue, appliedDateFilter } = salesAnalyticsState;

	const changeCriteria = useCallback((criteriaValue) => {
		updateSalesAnalyticsState({
			criteriaValue
		});

		// trackEvent('sales_analytics_channel_trends', {
		// 	channel: criteriaValue,
		// 	...getDurationObject(appliedDateFilter),
		// })
	}, []);

	const placeholderContent = {
		placeholderText: "Crunching your numbers!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-bizkpi.svg",
		placeholderSubtext:
			"View insights about your sales performance with metrics and trends helping you to take data driven decisions for your business",
		size: "medium"
	};

	return (
		<div className="sales-analytics-trends">
			{productType === "ORDERING" && (
				<div className="trends-channels-container">
					{salesAnalyticsTrendsChannels.data.length > 0 && !salesAnalyticsTrendsChannels.error ? (
						<OrderChannels
							orderChannels={salesAnalyticsTrendsChannels.data}
							changeCriteria={changeCriteria}
							criteriaValue={criteriaValue}
							loading={salesAnalyticsTrendsChannels.loading}
							topic={analyticsTopic}
							showComparison={appliedDateFilter.compare.dateFilter ? true : false}
							fromDashboard={false}
						/>
					) : salesAnalyticsTrendsChannels.loading ? (
						<OrderChannelBlockPlaceholder />
					) : (
						<div className="no-items-placeholder error">
							An error occurred while fetching the data, Please reload.
						</div>
					)}
				</div>
			)}
			<div className={(salesAnalyticsTrendsGraph.loading ? "disabled" : "") + " ana-graph-container"}>
				{salesAnalyticsTrendsGraph.data.current.length ? (
					<AnalyticsGraph
						duration={appliedDateFilter.current.dateFilter}
						anaGraphData={salesAnalyticsTrendsGraph}
						showYaxis={false}
						margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
					/>
				) : salesAnalyticsTrendsGraph.loading ? (
					<div className="no-items-placeholder">Fetching data!</div>
				) : (
					<Placeholder {...placeholderContent} />
				)}
			</div>
		</div>
	);
};
export default connect((store) => ({
	salesAnalyticsTrendsChannels: store.salesAnalyticsTrendsChannels,
	salesAnalyticsTrendsGraph: store.salesAnalyticsTrendsGraph,
	productType: store.selectedModule.productType,
	salesAnalyticsState: store.salesAnalyticsState
}))(Trends);
