// actions
import { ActionTypes } from "../actions/_types";

const NOTIFICATIONS_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		count: 0
	},
	error: false
};
export const notificationsList = (state = NOTIFICATIONS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_NOTIFICATIONS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_NOTIFICATIONS_LIST_SUCCESS:
			let updatedNotifications = [...state.data.objects];
			// add new notification on top to show newest/latest first
			updatedNotifications.unshift(action.payload);
			return {
				...state,
				data: {
					objects: updatedNotifications,
					count: action.payload?.overrideHide || action.payload.hide ? state.data.count : state.data.count + 1
				},
				loading: false
			};
		case ActionTypes.GET_NOTIFICATIONS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_NOTIFICATIONS_LIST:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		default:
			return state;
	}
};
