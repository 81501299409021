import React from "react";

// components
import OverlaySidebar from "../_commons/OverlaySidebar";
import { Button } from "../_commons/Button";

export const FilterSidebar = ({ isOpen, close, children, apply, clear, isNested = false, isFilters = false }) => {
	return (
		<OverlaySidebar showOverlaySidebar={isOpen} close={close} isNested={isNested} isFilters={isFilters}>
			<div className="filter-sidebar-container">
				<div className="header">
					<div className="header-left">
						<img className="icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
						<div className="title">Filter</div>
					</div>
					<div className="header-right" onClick={clear}>
						<img className="icon" src="/assets/icons/cancel.png" alt="" />
						<div className="title">Clear</div>
					</div>
				</div>
				<div className="filter-container">{children}</div>
				<div className="footer">
					<Button clickHandler={apply} classes="W(100%)">
						Apply
					</Button>
				</div>
			</div>
		</OverlaySidebar>
	);
};
