import { MEDIUM_OPTIONS, MESSAGE_TYPES } from "../client-config";

import { CAMPAIGN_EXECUTION_OPTIONS } from "../components/CreateCampaign/CreateCampaignWhen";

export const INITIAL_STATE = {
	name: "",
	description: "",
	medium: MEDIUM_OPTIONS[0].value,
	imageUrl: "",
	imageId: null,
	campaignExecutionType: CAMPAIGN_EXECUTION_OPTIONS[0].value,
	message: "",
	messageType: MESSAGE_TYPES[0].value,
	subject: "",
	scheduledTime: null,
	includeInNotifications: true,
	segmentId: "",
	emailTemplateId: undefined,
	cronExpression: undefined,
	startAt: undefined,
	endAt: undefined,
	conversionConfig: {
		event: "ONLINE_ORDER_PLACED",
		days: 1,
		value: ""
	},
	status: ""
};

export const campaignUpdateState = (state = { saving: false }, action) => {
	switch (action.type) {
		case "CAMPAIGN_UPDATE_STATE":
			return {
				saving: action.payload,
				lastSaved: new Date().getTime()
			};
		default:
			return state;
	}
};

export const createCampaign = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "CREATE_CAMPAIGN_STATE_UPDATE":
			return {
				...state,
				...action.payload
			};
		case "CREATE_CAMPAIGN_RESET":
			return INITIAL_STATE;
		default:
			return state;
	}
};

const INITIAL_STATE_EMAIL_TEMPLATE = {
	id: "",
	subject: "",
	html: "",
	isEditable: true
};

export const emailTemplate = (state = INITIAL_STATE_EMAIL_TEMPLATE, action) => {
	switch (action.type) {
		case "EMAIL_TEMPLATE_UPDATE":
			return {
				...state,
				...action.payload
			};
		case "EMAIL_TEMPLATE_RESET":
			return INITIAL_STATE_EMAIL_TEMPLATE;
		default:
			return state;
	}
};
