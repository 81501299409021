import React from "react";
import { SelectFilter } from "../_commons/SelectFilter";

const generateNumberList = (limit) => Array.from({ length: limit }, (_, index) => String(index).padStart(2, "0"));

const generateTimeList = (limit) => generateNumberList(limit).map((value) => ({ label: value, value, index: value }));

const hoursList = generateTimeList(24);
const minutesList = generateTimeList(60);

const TimeSlotFilter = ({ slot, label, hoursField, minutesField, timeslotIndex, slotIndex, handleTimeSlots }) => {
	return (
		<div className="timing-slot-wrapper--slot">
			<SelectFilter
				title={label}
				options={hoursList}
				field={hoursField}
				currValue={slot?.[hoursField]}
				setFilter={(field, value) => handleTimeSlots({ [field]: value }, timeslotIndex, slotIndex)}
				labelKey="label"
				valueKey="value"
				isClearable={false}
				placeholder="HH"
			/>
			<SelectFilter
				options={minutesList}
				field={minutesField}
				currValue={slot?.[minutesField]}
				setFilter={(field, value) => handleTimeSlots({ [field]: value }, timeslotIndex, slotIndex)}
				labelKey="label"
				valueKey="value"
				isClearable={false}
				placeholder="MM"
			/>
		</div>
	);
};

export default TimeSlotFilter;
