import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";

const BasicInfo = ({
	data = {},
	handleForm,
	validations,
	webhookEventTypes = {},
	webhookRetryModes = {},
	readOnly = true
}) => {
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<InputWithLabel
					value={data.url || ""}
					onChange={(e) => handleForm({ url: e.target.value })}
					validationMessage={validations.url || ""}
					requiredLabel={true}
					showCopyToClipboard={true}
					showIcon={true}
					classes="at-input--copy"
					readOnly={readOnly}
				>
					Webhook URL
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Event Type"
					options={webhookEventTypes.data}
					isLoading={webhookEventTypes.loading}
					readOnly={readOnly}
					field="eventType"
					currValue={data.eventType}
					setFilter={(field, value) => handleForm({ [field]: value })}
					labelKey="valueForDisplay"
					valueKey="value"
					validationMessage={validations.eventType || ""}
				/>
				<Switch
					title="Enable Retry"
					checked={data.retry}
					clickHandler={() => handleForm({ retry: !data.retry, retriesMode: webhookRetryModes.data[0] })}
					validationMessage={validations.retry || ""}
					readOnly={readOnly}
				/>
			</div>
			{data.retry && (
				<div className="form-row row-half">
					<SelectFilter
						title="Retry Mode"
						options={webhookRetryModes.data}
						isLoading={webhookRetryModes.loading}
						readOnly={readOnly}
						field="retriesMode"
						currValue={data.retriesMode}
						setFilter={(field, value) => handleForm({ [field]: value })}
						labelKey="valueForDisplay"
						valueKey="value"
						isClearable={false}
						validationMessage={validations.retriesMode || ""}
					/>
				</div>
			)}
		</React.Fragment>
	);
};
export default BasicInfo;
