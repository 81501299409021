import React, { Component, useState, useEffect, useCallback, useRef } from "react";

// third party
import { connect } from "react-redux";

// store
import { store } from "../store/configureStore";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchOperationalEfficiencyListView } from "../actions/operationalEfficiency";

// components
import ListView, { Table } from "../components/OperationalEfficiency/ListView";
import { Paginator } from "../components/_commons/Paginator";
import { BackNavigation } from "../components/_commons/BackNavigation";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// constants
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const RhiTransactions = ({
	operationalEfficiencyState,
	operationalEfficiencyTransactionsListState,
	operationalEfficiencyListView,
	location,
	params
}) => {
	const { currentDateFilter, appliedDateFilter, appliedFilters } = operationalEfficiencyState;
	const { bizLocationId } = params;
	let { limit, offset } = operationalEfficiencyTransactionsListState;
	const { data, loading, error } = operationalEfficiencyListView;
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const tableRef = useRef();
	let defaultPrevLocation = {
		pathname: "/rhi",
		title: "Overall Health"
	};
	let page = 1;

	useEffect(() => {
		fetchOperationalEfficiencyListView({
			bizLocationId,
			appliedDateFilter,
			appliedFilters,
			limit,
			offset,
			sortKey: "placed"
		});
	}, [appliedDateFilter, appliedFilters, limit, offset]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const paginationHandler = useCallback(
		async (page) => {
			offset = limit * (page - 1);
			store.dispatch({
				type: ActionTypes.OPERATIONAL_EFFICIENCY_TRANSACTION_LIST_STATE_CHANGE,
				payload: { offset }
			});
			const pglv = await fetchOperationalEfficiencyListView({
				bizLocationId,
				appliedDateFilter,
				appliedFilters,
				limit,
				offset,
				sortKey: "placed"
			});
			scroll({ top: tableRef?.current?.offset - 57, left: 0 });
		},
		[limit, offset, fetchOperationalEfficiencyListView, appliedFilters, appliedDateFilter]
	);

	const sortHandler = useCallback(
		async (key) => {
			store.dispatch({
				type: ActionTypes.OPERATIONAL_EFFICIENCY_TRANSACTION_LIST_STATE_CHANGE,
				payload: { offset: 0 }
			});
			const pglv = await fetchOperationalEfficiencyListView({
				bizLocationId,
				appliedDateFilter,
				appliedFilters,
				limit,
				offset,
				sortKey: key
			});
		},
		[limit, offset, fetchOperationalEfficiencyListView, appliedFilters, appliedDateFilter]
	);
	return (
		<div>
			<BackNavigation prevLocation={defaultPrevLocation} />
			<div className="transaction-section section-container-common" ref={tableRef}>
				<ListView
					bizLocationId={bizLocationId}
					sortHandler={sortHandler}
					handleNestedEntity={handleNestedEntity}
				/>
				<div>
					<Paginator
						limit={limit}
						offset={offset}
						count={data.totalCount}
						goToPage={paginationHandler}
						sortHandler={sortHandler}
					/>
				</div>
				<NestedEntityContainer
					show={nestedEntity.show}
					type={nestedEntity.type}
					id={nestedEntity.id}
					closeNestedContainer={() => handleNestedEntity(false)}
					nestedRef={nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		</div>
	);
};

export default connect((store, props) => ({
	operationalEfficiencyState: store.operationalEfficiencyState,
	operationalEfficiencyTransactionsListState: store.operationalEfficiencyTransactionsListState,
	operationalEfficiencyListView: store.operationalEfficiencyListView,
	params: props.match.params,
	location: props.location
}))(RhiTransactions);
