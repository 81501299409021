// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	SALES_ANALYTICS_TOPICS,
	SALES_ANALYTICS_OVERVIEW_DATASET_GRAPH,
	SALES_ANALYTICS_PERFORMANCE_DATASET_TAB,
	GET_ITEM_IMAGES
} from "../graphql/salesAnalytics";

// actions
import { ActionTypes } from "./_types";

export const getDurationObject = (appliedDateFilter) => {
	const { dateFilter: currDateFilter } = appliedDateFilter.current;
	const { dateFilter: compDateFilter } = appliedDateFilter.compare;
	const currDates = currDateFilter.split(",");
	const compDates = compDateFilter.split(",");
	const obj = {};

	// for current date range
	if (currDates.length === 1) {
		obj.duration = {
			preset: currDateFilter
		};
	} else if (currDates[0] && currDates[1]) {
		obj.duration = {
			custom: {
				start: currDates[0],
				end: currDates[1]
			}
		};
	}

	// for comparison date range
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		obj.comparisonDuration = {
			start: compDates[0],
			end: compDates[1]
		};
	}

	return obj;
};

export const fetchTopics = async (props, fetchPolicy = "cache-first") => {
	store.dispatch({
		type: ActionTypes.GET_SA_TOPICS_REQUEST
	});
	try {
		const { appliedDateFilter, productType, appliedFilters, selectedBrand = null } = props;
		const filters = appliedFilters ? Object.values(appliedFilters) : [];
		const durationObject = getDurationObject(appliedDateFilter);
		const variables = {
			productType,
			isAnalytics: true,
			...durationObject,
			filters
		};
		if (selectedBrand !== null) {
			variables.brand = selectedBrand.id;
		}
		const resp = await client.query({
			query: SALES_ANALYTICS_TOPICS,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_SA_TOPICS_SUCCESS,
			payload: resp.data.analytics.topics
		});
		store.dispatch({
			type: ActionTypes.UPDATE_SA_STATE,
			payload: { filters: resp.data.analytics.filters }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_SA_TOPICS_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchTrendsChannels = async (props, fetchPolicy = "cache-first") => {
	const { productType, appliedDateFilter, analyticsTopic, criteria, appliedFilters, selectedBrand = null } = props;
	const filters = appliedFilters ? Object.values(appliedFilters) : [];
	const durationObject = getDurationObject(appliedDateFilter);
	const variables = {
		productType,
		topic: analyticsTopic,
		groupBy: criteria,
		isAnalytics: true,
		...durationObject,
		filters
	};
	if (selectedBrand !== null) {
		variables.brand = selectedBrand.id;
	}
	store.dispatch({
		type: ActionTypes.GET_SA_TRENDS_CHANNELS_REQUEST
	});
	try {
		const resp = await client.query({
			query: SALES_ANALYTICS_TOPICS,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_SA_TRENDS_CHANNELS_SUCCESS,
			payload: resp.data.analytics.topics
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_SA_TRENDS_CHANNELS_FAILURE
		});
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchTrendsGraph = async (props, fetchPolicy = "cache-first") => {
	const {
		productType,
		appliedDateFilter,
		analyticsTopic,
		criteria,
		criteriaValue,
		appliedFilters,
		selectedBrand = null
	} = props;
	const durationObject = getDurationObject(appliedDateFilter);
	const filters = appliedFilters ? Object.values(appliedFilters) : [];
	const variables = {
		productType,
		topic: analyticsTopic,
		criteria,
		criteriaValue,
		dataFormat: "GRAPHICAL",
		isAnalytics: true,
		...durationObject,
		filters
	};
	if (selectedBrand !== null) {
		variables.brand = selectedBrand.id;
	}
	store.dispatch({
		type: ActionTypes.GET_SA_TRENDS_GRAPH_REQUEST
	});
	try {
		const resp = await client.query({
			query: SALES_ANALYTICS_OVERVIEW_DATASET_GRAPH,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_SA_TRENDS_GRAPH_SUCCESS,
			payload: resp.data.analytics.dataset[0]
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_SA_TRENDS_GRAPH_FAILURE,
			error
		});
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchPerformance = async (props, fetchPolicy = "cache-first") => {
	const {
		productType,
		appliedDateFilter,
		performanceTopic,
		analyticsTopic,
		performanceChannels,
		limit,
		offset,
		appliedFilters,
		search,
		selectedBrand = null
	} = props;
	const durationObject = getDurationObject(appliedDateFilter);
	const filters = appliedFilters ? Object.values(appliedFilters) : [];
	const variables = {
		productType,
		topic: performanceTopic,
		dataFormat: "TABULAR",
		isAnalytics: true,
		metricTopic: analyticsTopic,
		channels: performanceChannels.map((pc) => pc.value),
		limit,
		offset,
		filters,
		search,
		...durationObject
	};
	if (selectedBrand !== null) {
		variables.brand = selectedBrand.id;
	}
	store.dispatch({
		type: ActionTypes.GET_SA_PERFORMANCE_REQUEST
	});
	try {
		const resp = await client.query({
			query: SALES_ANALYTICS_PERFORMANCE_DATASET_TAB,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_SA_PERFORMANCE_SUCCESS,
			payload: resp.data.analytics.dataset[0]
		});
		if (variables.topic === "ITEMS_TOP_PERFORMING") {
			let itemIds = [];
			let getItemIds = resp.data.analytics?.dataset?.[0]?.rows?.forEach((item) => {
				itemIds.push(item[1].value);
			});
			return itemIds;
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_SA_PERFORMANCE_FAILURE,
			error: error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchItemImages = async (variables) => {
	try {
		const resp = await client.query({
			query: GET_ITEM_IMAGES,
			variables
		});
		return resp?.data?.items?.objects || [];
	} catch (error) {
		console.log(error);
	}
};
