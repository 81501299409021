// utils
import { getSortOrder } from "../atlas-utils";

// action types
import { ActionTypes } from "../actions/_types";

// constant
import {
	PRESET_TYPES,
	DATE_TYPES,
	COMPARE_DATE_TYPES,
	CUSTOM_TYPES
} from "../components/_commons/NewDateCompareFilter";

const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[1].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[1],
		customTypeSelected: CUSTOM_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined,
		rangeStartTime: "00:00:00",
		rangeEndTime: "23:59:59"
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	prevOffset: { 1: 0 },
	currPage: 1,
	sort: {
		field: "",
		order: ""
	},
	sortedField: "",
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE,
	currentFilters: {},
	appliedFilters: {}
};

export const auditEventsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.AUDIT_EVENTS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.AUDIT_EVENTS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.AUDIT_EVENTS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const AUDIT_EVENTS_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "--" },
		searchFieldValue: "",
		currEvent: { state: "", id: null }
	},
	error: false
};
export const auditEventsList = (state = AUDIT_EVENTS_LIST_INITIAL_STATE, action) => {
	let updatedEvents = [];
	switch (action.type) {
		case ActionTypes.GET_AUDIT_EVENTS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_AUDIT_EVENTS_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
					searchFieldSelected:
						action.payload?.searchKeywords?.length && state.data.searchFieldSelected.key === "default"
							? action.payload?.searchKeywords?.[0]
							: state.data.searchFieldSelected
				},
				loading: false
			};
		case ActionTypes.GET_AUDIT_EVENTS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.GET_RELATED_AUDIT_EVENTS_LIST_REQUEST:
			updatedEvents = state.data.objects.map((event) => {
				if (event.id === action.payload.id) {
					event.relatedEvents = {
						...event.relatedEvents,
						loading: true
					};
				}
				return event;
			});
			return {
				...state,
				data: {
					...state.data,
					objects: updatedEvents
				}
			};
		case ActionTypes.GET_RELATED_AUDIT_EVENTS_LIST_SUCCESS:
			updatedEvents = state.data.objects.map((event) => {
				if (event.id === action.payload.id) {
					const {
						id,
						traceId,
						limit,
						offset,
						data: { objects, count }
					} = action.payload;
					const updatedObjects = [...event.relatedEvents.objects, ...objects];
					event.relatedEvents = {
						id,
						traceId,
						limit,
						offset,
						objects: updatedObjects,
						count,
						loading: false
					};
				}
				return event;
			});
			return {
				...state,
				data: {
					...state.data,
					objects: updatedEvents
				}
			};
		case ActionTypes.GET_RELATED_AUDIT_EVENTS_LIST_FAILURE:
			updatedEvents = state.data.objects.map((event) => {
				if (event.id === action.payload.id) {
					event.relatedEvents = {
						...event.relatedEvents,
						...action.payload,
						loading: false
					};
				}
				return event;
			});
			return {
				...state,
				data: {
					...state.data,
					objects: updatedEvents
				}
			};
		case ActionTypes.UPDATE_AUDIT_EVENTS_LIST:
			updatedEvents = state.data.objects.map((event) => {
				if (event.id === action.payload.id) {
					event = {
						...event,
						...action.payload
					};
				}
				return event;
			});
			return {
				...state,
				data: {
					...state.data,
					objects: updatedEvents
				}
			};
		case ActionTypes.UPDATE_RELATED_AUDIT_EVENTS_LIST:
			updatedEvents = state.data.objects.map((event) => {
				if (event.id === action.payload.id) {
					event.relatedEvents = {
						...event.relatedEvents,
						...action.payload
					};
				}
				return event;
			});
			return {
				...state,
				data: {
					...state.data,
					objects: updatedEvents
				}
			};
		case ActionTypes.AUDIT_EVENTS_LIST_SEARCH:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		default:
			return state;
	}
};

export const AUDIT_EVENT_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	error: {}
};
export const auditEventDetailsReducer = (state = AUDIT_EVENT_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_AUDIT_EVENT_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_AUDIT_EVENT_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_AUDIT_EVENT_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_AUDIT_EVENT_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		default:
			return state;
	}
};
