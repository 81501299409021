import React from "react";
import { extractInitials } from "../../../atlas-utils";
import { getBrandDetail } from "../../../helpers/periscope";
import { connect } from "react-redux";
import { handleCreateCheckItem, handleRemoveImportantItem } from "../../../actions/periscope";
import { store } from "../../../store/configureStore";
import { ActionTypes } from "../../../actions/_types";

const BrandItem = ({ value, configItems, login, isMultibrandEnabled }) => {
	let displayImage = "";
	let displayDiv = "";
	const brands = configItems?.brands?.items;
	let currentBrand = getBrandDetail(value?.brand_id, brands);
	let color = currentBrand?.color;

	if (value?.brand_id && currentBrand?.image?.length > 0) {
		displayImage = getBrandDetail(value?.brand_id, brands)?.image;
	} else {
		displayDiv = (
			<span className={"brand-text-logo"}>
				{extractInitials(getBrandDetail(value?.brand_id, brands)?.name?.split(" "))}
			</span>
		);
	}

	const checkItem = async (val) => {
		if (val?.is_important) {
			let variables = {
				itemId: String(val?.important_item_id)
			};

			const respItemId = await handleRemoveImportantItem(variables);
			store.dispatch({
				type: ActionTypes.ITEM_ISSUES_ORIGINAL_DATA_CHANGE,
				payload: respItemId
			});
		} else {
			let variables = {
				bizId: String(login.loggedInbizDetail.id),
				brandId: isMultibrandEnabled ? val.brand_id : null,
				name: val.item
			};
			let receivedId = await handleCreateCheckItem(variables);
			store.dispatch({
				type: ActionTypes.ITEM_ISSUES_ORIGINAL_DATA_CHANGE_FALSE_VALUE,
				payload: receivedId
			});
		}
	};

	return (
		<div className="brandcell-item-isses">
			<div className="issue-important-item" onClick={() => checkItem(value)}>
				{value?.is_important == true ? (
					<>
						<img className="brand-label-img" src="/assets/periscope/brandLabelImportant.svg" />
					</>
				) : (
					<>
						<img className="brand-label-img" src="/assets/periscope/brandLabel.svg" />
					</>
				)}
			</div>
			<div className="issues-brand-item-text">
				<div className="brand-display-issues">
					{displayImage ? (
						<img style={{ borderRadius: "2px" }} src={displayImage} className="brand-image-location" />
					) : isMultibrandEnabled ? (
						<div className={`brand-img-text text-logo ${displayImage.length > 0 ? "" : color}`}>
							{displayDiv}
						</div>
					) : null}

					<span className="issues-brand-item">{value.item}</span>
				</div>
				<div className="issues-stars-text">
					<span> {value.rating} </span>
					<img className="issues-star" src="/assets/periscope/star-rating.svg" />
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	login: state.login,
	configItems: state.configItems,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(BrandItem);
