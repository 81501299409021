import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Paginator } from "../_commons/Paginator";
import ModifierEntityAssociations from "../EntityAssociations/ModifierEntityAssociations";
import DraggableModifierAccordion, { DRAGGABLE_ENTITY_TYPES } from "../_commons/DraggableModifierAccordion";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { fixedToTwoDecimal, printCurrency } from "../../atlas-utils";

// graphql
import {
	GET_MODIFIER_GROUP_MODIFIERS,
	GET_MODIFIER_GROUP_MODIFIERS_FROM_MENU_SERVICE
} from "../../graphql/modifierGroups";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { NESTED_ENTITY_TYPES, MODIFIER_ENTITY_ASSOCIATION_TYPES } from "../../client-config";
import { clientMenu } from "../../client-menu";
const MODIFIER_GROUPS_MODIFIERS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "title" },
	{ label: "Price", value: "price" }
];
const FOOD_TYPES = {
	VEGETARIAN: "Vegetarian",
	NON_VEGETARIAN: "Non-vegetarian",
	EGGETARIAN: "Eggetarian",
	NA: "NA"
};
const FILTER_INITIAL_STATE = {
	option_title: "",
	crm_title: ""
};

const Modifiers = ({
	modifierGroupId,
	setModalBusy,
	currencySymbol,
	handleNestedEntity,
	readOnly = true,
	isViewedFromMenuSection,
	menuId,
	reOrderedMenuModifiers,
	setReOrderedMenuModifiers,
	setBtnState = () => {},
	tab = null,
	handleEntityDissociation,
	menuDetailsData
}) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(100);
	const [modifiersList, setModifiersList] = useState({
		objects: [],
		count: 0
	});
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const fetchModifierGroupModifiers = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(modifierGroupId),
				limit,
				offset,
				sort: {
					field: "option_order",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}

			if (isViewedFromMenuSection) {
				const resp = await clientMenu.query({
					query: GET_MODIFIER_GROUP_MODIFIERS_FROM_MENU_SERVICE,
					variables: {
						menuId,
						optionGroupId: String(modifierGroupId),
						limit,
						offset,
						filters: {
							name: filtersObject.find((filter) => filter.field === "option_title")?.value ?? "",
							crmName: filtersObject.find((filter) => filter.field === "crm_title")?.value ?? ""
						}
					},
					fetchPolicy: "no-cache"
				});
				setModifiersList({
					objects: resp?.data?.optionGroup?.options?.objects,
					count: resp?.data?.optionGroup?.options?.totalObjectCount
				});
				setReOrderedMenuModifiers(resp?.data?.optionGroup?.options?.objects);
			} else {
				const resp = await client.query({
					query: GET_MODIFIER_GROUP_MODIFIERS,
					variables,
					fetchPolicy: "no-cache"
				});
				setModifiersList(resp.data.modifierGroup.paginatedModifiers);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
		setBtnState(true);
		// Why menuDetailsData in the dependency array?
		// we will never fully know what is updated in the nested entities, so need to refresh
		// the details on every menuDetailsData change
		// TODO: need to find a better way to do this
	}, [modifierGroupId, limit, offset, appliedFilters, isViewedFromMenuSection, menuDetailsData]);

	const handleRemoveEntity = async (entityId) => {
		setLoading(true);
		const respStatus = await handleEntityDissociation?.(entityId, modifierGroupId, DRAGGABLE_ENTITY_TYPES.MODIFIER);
		if (respStatus) {
			fetchModifierGroupModifiers();
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchModifierGroupModifiers();
	}, [fetchModifierGroupModifiers]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchModifierGroupModifiers();
		}
	};

	const getModifiersMap = () => {
		const modifiersMap = {};
		const temp = modifiersList?.objects.map((modifier) => {
			modifiersMap[modifier?.id] = true;
		});
		return modifiersMap;
	};

	if (loading) {
		return (
			<div className="P(10px 0)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"modifiers-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Nested Modifiers</div>
					<div className="header-subtext">
						{modifiersList.count} modifier(s) are associated to this modifier group
					</div>
				</div>
				{!readOnly && (
					<div>
						<Button clickHandler={openAssociationSidebar}>Update</Button>
					</div>
				)}
			</div>
			{isViewedFromMenuSection ? (
				<DraggableModifierAccordion
					type={DRAGGABLE_ENTITY_TYPES.MODIFIER}
					reOrderedMenuModifiers={reOrderedMenuModifiers}
					setReOrderedMenuModifiers={setReOrderedMenuModifiers}
					handleNestedEntity={handleNestedEntity}
					setFormTouched={(state) => setBtnState(!state)}
					handleRemoveEntity={handleRemoveEntity}
				/>
			) : (
				<React.Fragment>
					<div className="search-container">
						<InputWithLabel
							value={currFilters.option_title}
							onChange={(e) => setFilter("option_title", e.target.value)}
							placeholder="Enter Name"
						>
							Name
						</InputWithLabel>
						<InputWithLabel
							value={currFilters.crm_title}
							onChange={(e) => setFilter("crm_title", e.target.value)}
							placeholder="Enter CRM Title"
						>
							CRM Title
						</InputWithLabel>
					</div>
					<Table
						data={modifiersList.objects}
						loading={loading}
						currencySymbol={currencySymbol}
						handleNestedEntity={handleNestedEntity}
						isViewedFromMenuSection={isViewedFromMenuSection}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={modifiersList.count || 0}
						goToPage={handlePagination}
					/>
				</React.Fragment>
			)}

			<ModifierEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityId={parseInt(modifierGroupId)}
				entityType={MODIFIER_ENTITY_ASSOCIATION_TYPES[0]}
				currencySymbol={currencySymbol}
				isViewedFromMenuSection={isViewedFromMenuSection}
				selectedModifiers={getModifiersMap()}
			/>
		</div>
	);
};
export default Modifiers;

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	currencySymbol,
	handleNestedEntity,
	isViewedFromMenuSection
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container modifier-groups-edit-modifiers-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={MODIFIER_GROUPS_MODIFIERS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							handleNestedEntity={handleNestedEntity}
							currencySymbol={currencySymbol}
							isViewedFromMenuSection={isViewedFromMenuSection}
						/>
					))}
					{data.length === 0 && !loading && (
						<div className="no-items-placeholder">No Associated Modifiers found!</div>
					)}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	title,
	price,
	foodType,
	crmTitle,
	currencySymbol,
	style,
	handleNestedEntity,
	isViewedFromMenuSection,
	name,
	crmName
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text title" title={title}>
				<div className="title-desc">
					<div className={`food-type ${FOOD_TYPES[foodType]}`} />
					<a
						className="link-text"
						role="button"
						onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[6], id)}
					>
						{name || title || id}
					</a>
				</div>
				<div className="handle">{(crmTitle || crmName) && `CRM Title: ${crmTitle || crmName}`}</div>
			</div>
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{fixedToTwoDecimal(price) || 0}
			</div>
		</animated.div>
	);
};
