// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_AUDIT_EVENTS_LIST, GET_RELATED_AUDIT_EVENTS_LIST, GET_AUDIT_EVENT } from "../graphql/auditEvents";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// constant
import { EVENT_SOURCE_DESTINATION_MAP } from "../containers/AuditEventsList";
const eventDetailsOrder = {
	url: null,
	method: null,
	status_code: null,
	headers: null,
	request_headers: null,
	response_headers: null,
	request_payload: null,
	response_payload: null,
	source: null,
	destination: null,
	purpose: null,
	brand: null,
	location: null
};

const jsonParser = (data) => {
	let obj = {};
	try {
		obj = JSON.parse(data);
	} catch {
		obj = {};
	}
	return obj;
};

const parseAuditEventsData = (data, to = "form") => {
	let result = { ...data };
	let isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	switch (to) {
		case "list":
			if (result.objects.length > 0) {
				result["objects"] = result.objects.map((event) => {
					let parsedData = jsonParser(event.data);
					if (!["atlas", "prime", ""].includes(parsedData?.source?.toLowerCase())) {
						parsedData.user_full_name = "N.A.";
						parsedData.user = "";
					}
					event["data"] = parsedData;
					event["relatedEvents"] = {
						limit: 10,
						offset: 0,
						loading: false,
						count: 0,
						objects: []
					};
					return event;
				});
			}
			break;
		case "form":
			if (result.data) {
				let parsedData = jsonParser(result.data);
				// add following keys if parsedData.requests exists
				delete parsedData.root;
				if (parsedData.request) {
					parsedData["request_headers"] = parsedData?.request?.headers || null;
					parsedData["response_headers"] = parsedData?.headers;
					parsedData["request_payload"] = parsedData?.request?.payload || null;
					parsedData["response_payload"] = parsedData?.content;
					delete parsedData.request;
					delete parsedData.headers;
					delete parsedData.content;
				}
				if (parsedData.user_id) {
					parsedData["user_ID"] = parsedData?.user_id;
					delete parsedData.user_id;
				}
				// set user and usename
				if (!["atlas", "prime", ""].includes(parsedData?.source?.toLowerCase())) {
					delete parsedData.user;
					delete parsedData.user_ID;
					delete parsedData.user_full_name;
				} else {
					let source = parsedData.source
						? EVENT_SOURCE_DESTINATION_MAP[parsedData.source] || parsedData.source
						: undefined;
					source = source?.replace(/[._-]/g, " ") || "POS";

					parsedData.username = parsedData?.user === "SYSTEM" ? "" : parsedData?.user || "";
					parsedData.user =
						parsedData?.user_full_name ||
						(parsedData?.user === "SYSTEM" ? "System" : source ? `${source} User` : "");
					delete parsedData.user_full_name;
				}
				// add brand and location info
				if (isMultibrandEnabled) {
					parsedData.brand = parsedData?.additional_info?.brand_name || "N.A.";
				}
				parsedData.location = parsedData?.additional_info?.store_name || "N.A.";
				// rearrange the keys in parsed data in specific order
				result["data"] = Object.assign({ ...eventDetailsOrder }, parsedData);
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchAuditEventsList = async () => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().auditEventsListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().auditEventsList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_AUDIT_EVENTS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// date filter
		if (appliedDateFilter.current.dateFilter) {
			filtersObject.push({
				field: "timestamp",
				value: appliedDateFilter.current.dateFilter
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_AUDIT_EVENTS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_AUDIT_EVENTS_LIST_SUCCESS,
			payload: parseAuditEventsData(resp.data.auditTrails, "list")
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_AUDIT_EVENTS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchRelatedAuditEventsList = async (traceId, id, limit = 10, offset = 0) => {
	if (traceId && id) {
		store.dispatch({
			type: ActionTypes.GET_RELATED_AUDIT_EVENTS_LIST_REQUEST,
			payload: { traceId, id }
		});
		try {
			const { appliedFilters, appliedDateFilter } = store.getState().auditEventsListState;
			const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().auditEventsList.data;
			const variables = {
				limit,
				offset,
				traceId
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push(appliedFilters[f]);
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			// date filter
			if (appliedDateFilter.current.dateFilter) {
				filtersObject.push({
					field: "timestamp",
					value: appliedDateFilter.current.dateFilter
				});
			}
			// set filter
			variables.filters = filtersObject;
			// search filter
			if (searchFieldSelected && searchFieldValue) {
				variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
			}
			const resp = await client.query({
				query: GET_RELATED_AUDIT_EVENTS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			store.dispatch({
				type: ActionTypes.GET_RELATED_AUDIT_EVENTS_LIST_SUCCESS,
				payload: {
					id,
					traceId,
					limit,
					offset,
					data: parseAuditEventsData(
						{
							...resp.data.auditTrailsRelated
						},
						"list"
					)
				}
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.GET_RELATED_AUDIT_EVENTS_LIST_FAILURE,
				payload: { traceId, id, error }
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	}
};

export const fetchAuditEventDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_AUDIT_EVENT_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_AUDIT_EVENT,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_AUDIT_EVENT_DETAIL_SUCCESS,
			payload: parseAuditEventsData(resp.data.auditTrail, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_AUDIT_EVENT_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching audit event details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};
