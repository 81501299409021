import React from "react";
import BestItem from "../../../_icons/BestItem";
import { connect } from "react-redux";
import ExclamatoryIcon from "../../../_icons/ExclamatoryIcon";
import { commifyNumbers } from "../../../../atlas-utils";

const ItemStats = ({ rowTitle, currIndex, metric, login, compareAnalytics, status }) => {
	const columnData = compareAnalytics.columnData?.[metric].data?.[currIndex];
	const tableData = compareAnalytics.tableData?.[metric]?.[columnData?.valueForDisplay];
	if (!tableData) {
		return null;
	}
	const data = tableData?.[rowTitle];
	const objects = data?.objects || [];
	const loading = data?.loading;
	const error = data?.error;
	return (
		<div className="item-stats">
			{loading || error ? (
				<div className="item-stats-loading compare-loading">
					{Array.from({ length: 5 }).map((_, i) => {
						return (
							<div key={i} className={`${loading ? "shimmer" : "error"}   W${100}% loading-div   `}></div>
						);
					})}
					{error ? (
						<div className="error-info">
							<div className="exclamatory-div">
								<ExclamatoryIcon fill={"#EAEAEA"} width={72} height={72} />
							</div>
							<div className="text-div">
								<div className="main-text">Could not load this chart!</div>
								<div className="para">We are facing some issue while fetching this data for you</div>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			) : (
				<div className="item-stats">
					{objects.length > 0 ? (
						<>
							{objects.map((obj, index) => {
								return (
									<React.Fragment key={index}>
										<div className="item-display" key={index}>
											<div className="item-val">
												{status && (
													<div className="img-item">
														{status == "best" && <BestItem height={16} width={16} />}
														{status === "worst" && (
															<ExclamatoryIcon height={16} width={16} />
														)}
													</div>
												)}
												<span className="item-title">{obj.name}</span>
											</div>

											<div className="items-sold"> {commifyNumbers(obj.value)} Sold </div>
										</div>
									</React.Fragment>
								);
							})}
						</>
					) : (
						<div className="not-enough-state">
							<img src="/assets/analytics/compare/barChart.svg" className="bar-svg" />
							<div className="text-div">
								<div className="main-text">Not enough data</div>
								<div className="para">
									Try selecting another date range or check back later once more data is collected.
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	configItems: store.configItems,
	isMultibrandEnabled: store.login?.loggedInbizDetail?.isMultibrandEnabled,
	login: store.login,
	compareAnalytics: store.compareAnalytics
}))(ItemStats);
