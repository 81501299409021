import gql from "graphql-tag";

export const GET_AUDIT_EVENTS_LIST = gql`
	query getAuditEventsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		auditTrails(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			count
			nextOffset
			hasNext
			hasPrevious
			objects {
				id
				timestamp
				bizId
				event
				traceId
				data
				relatedCount
			}
			searchKeywords {
				key
				valueForDisplay
			}
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const GET_RELATED_AUDIT_EVENTS_LIST = gql`
	query getRelatedAuditEventsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
		$traceId: String!
	) {
		auditTrailsRelated(limit: $limit, offset: $offset, filters: $filters, search: $search, traceId: $traceId) {
			count
			objects {
				id
				timestamp
				bizId
				event
				traceId
				data
			}
		}
	}
`;

export const GET_AUDIT_EVENT = gql`
	query getAuditEvent($id: String) {
		auditTrail(id: $id) {
			id
			timestamp
			bizId
			event
			traceId
			data
		}
	}
`;
