import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import Select from "react-select-animated";

// components
import InlineSuggestions from "../_commons/InlineSuggestions";
import { CheckBox } from "../_commons/CheckBox";
import { InputWithLabel } from "../_commons/InputWithLabel";

// constants
import { MESSAGE_TYPES, CAMPAIGN_DYANAMIC_VARS, APP_PUSH_BANNER_SIZE_LIMIT } from "../../client-config";
import { ActionTypes } from "../../actions/_types";

// client
import { store } from "../../store/configureStore";
import { client } from "../../client";

// graphql
import { UPLOAD_IMAGE } from "../../graphql/misc";

@connect((store) => ({
	createCampaign: store.createCampaign
}))
export default class CampaignApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messageLength: 0,
			imageUploading: false,
			imageId: null
		};
	}

	changeMType = (messageType) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				messageType: messageType.value
			}
		});
	};

	flipNotificationHistory = (includeInNotifications) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				includeInNotifications
			}
		});
	};

	openTestSMSOverlay() {
		const {
			createCampaign: { message }
		} = this.props;
		store.dispatch({
			type: "SET_OVERLAY_SIDEBAR_STATE",
			payload: {
				active: true,
				medium: "APP_PUSH_NOTIFICATION",
				formattedSMS: message
			}
		});
	}

	updateContent = (content, messageLength) => {
		this.setState({
			messageLength
		});
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				message: content
			}
		});
	};

	handleSubjectChange = (e) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				subject: e.target.value
			}
		});
	};

	handleImageFile = async (e) => {
		let {
			target: {
				validity,
				files: [file]
			}
		} = e;

		// keep the file size below 1048576 Bytes(1MB)
		if (file.size > APP_PUSH_BANNER_SIZE_LIMIT) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Image size is too large, Please keep it below 1MB",
					timeout: 5000,
					error: true
				}
			});
			return;
		}

		this.setState({
			imageUploading: true
		});
		try {
			let _variable = {
				image: file
			};

			// this is prevent uploading junk images.
			if (this.state.imageId) {
				_variable.id = this.state.imageId;
			}

			let resp = await client.mutate({
				mutation: UPLOAD_IMAGE,
				variables: _variable
			});

			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					imageUrl: resp.data.uploadImage.image.replace("http://", "https://"),
					imageId: resp.data.uploadImage.id
				}
			});
			this.setState({
				imageId: resp.data.uploadImage.id,
				imageUploading: false
			});
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Image upload failed. Try again.",
					timeout: 5000,
					error: true
				}
			});
			this.setState({
				imageUploading: false
			});
		}
	};
	openImageUploader = () => {
		this.imageInput.click();
	};

	render() {
		const { messageLength } = this.state;
		const {
			createCampaign: { subject, message, messageType, includeInNotifications }
		} = this.props;

		return (
			<div className="campaign-app">
				<div className="meta-info">Message type</div>
				<Select
					searchable={false}
					className="at-dropdown"
					options={MESSAGE_TYPES}
					value={messageType}
					clearable={false}
					onChange={(m) => this.changeMType(m)}
				/>
				<InputWithLabel
					value={subject}
					onChange={this.handleSubjectChange}
					classes="subject-input"
					name="subject"
					placeholder="Type your notification subject here."
				>
					Subject
				</InputWithLabel>
				<input
					accept="image/*"
					className="D(n)"
					ref={(ref) => (this.imageInput = ref)}
					type="file"
					onChange={this.handleImageFile}
					onClick={() => {
						this.imageInput.value = null;
					}}
				/>
				<div className="template-opts Mb(15px)">
					<span onClick={this.openImageUploader} className="campaign-block-footer-text-container">
						{this.state.imageUploading
							? "Uploading.."
							: this.state.imageId
							? "Change notification banner"
							: "Upload / Change notification banner"}
					</span>
				</div>

				<div className="meta-info">Content (add dynamic fields with {"{ }"})</div>
				<InlineSuggestions vars={CAMPAIGN_DYANAMIC_VARS} updateContent={this.updateContent} content={message} />
				<div className="sms-meta">
					<div className="sms-count">{`${messageLength} Characters`}</div>
				</div>
				<div className="show-notification">
					<CheckBox
						checked={includeInNotifications}
						clickHandler={() => this.flipNotificationHistory(!includeInNotifications)}
					>
						Show in notification history?
					</CheckBox>
				</div>
				<div className="sms-send-test">
					<img
						src="/assets/icons/icon-push-notification.svg"
						alt=""
						style={{
							transform: "translateY(2px)"
						}}
					/>
					<span className="Cur(p)" onClick={() => this.openTestSMSOverlay()}>
						Send a test push notification
					</span>
				</div>
			</div>
		);
	}
}
