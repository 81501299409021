import React, { useEffect, useState } from "react";

// components
import { CheckBox } from "./CheckBox";
import { Loading } from "./Loading";
import { Modal } from "./Modal";

// actions
import { archiveRestoreBrand } from "../../actions/brands";
import { archiveRestoreItem } from "../../actions/items";
import { archiveRestoreMenu, deleteRule } from "../../actions/menus";
import { archiveRestoreApiAccess } from "../../actions/apiAccounts";
import { capitaliseText } from "../../atlas-utils";
import { deleteComparison, fetchSavedComparisons, updateLastViewed } from "../../actions/analytics";

const ArchiveRestoreModal = ({
	isOpen,
	close,
	entityType,
	entityName = "Entity",
	mode = "archive",
	dataObject = {}
}) => {
	const [isChecked, setChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const [respState, setRespState] = useState("");

	const entityTypeHandlerFunction = {
		brands: archiveRestoreBrand,
		itemsList: archiveRestoreItem,
		menus: archiveRestoreMenu,
		apiAccess: archiveRestoreApiAccess,
		menuRule: deleteRule,
		analyticsCompareDelete: deleteComparison,
		analyticsCompareRemoved: updateLastViewed
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const handlerFunction = entityTypeHandlerFunction[entityType];

			if (entityType === "itemsList") {
				const updatedDataObject = {
					...dataObject,
					isEnabled: !dataObject.isEnabled,
					foodType: { value: dataObject?.foodType }
				};
				const resp = await handlerFunction(updatedDataObject);
				setRespState(resp ? "success" : "failure");
				setLoading(false);
				return;
			}
			if (entityType == "analyticsCompareDelete" || entityType == "analyticsCompareRemoved") {
				const updatedDataObject = {
					...dataObject
				};
				const resp = await handlerFunction(updatedDataObject);
				close(true);
				setChecked(false);
				setLoading(false);
				const status =
					entityType == "analyticsCompareDelete"
						? resp?.data?.deleteComparison?.status?.success
						: resp?.data?.updateLastViewed?.status?.success;
				if (status) {
					fetchSavedComparisons(dataObject.refreshFetch);
				}
				setLoading(false);
				return;
			}
			const resp = await handlerFunction(mode === "restore", dataObject);
			setRespState(resp ?? "failure");
			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	};

	const handleClose = () => {
		if (respState === "success") {
			close(true);
		} else {
			close();
		}
		setRespState("");
		setChecked(false);
		setLoading(false);
	};

	const renderContent = () => {
		switch (entityType) {
			case "brands":
				return (
					<React.Fragment>
						{loading ? (
							<div className="entity-details">
								<Loading />
							</div>
						) : respState !== "" ? (
							<div className="entity-details">
								<div className={`end-result ${respState}`}>
									{respState === "success"
										? `Successfully ${mode === "archive" ? "archived" : "restored"} the ${
												dataObject?.isMulti ? "brands" : "brand"
										  }.`
										: `Failed to ${mode === "archive" ? "archive" : "restore"} ${
												dataObject?.isMulti ? "brands" : "brand"
										  }.`}
								</div>
							</div>
						) : (
							<div className="entity-details">
								<div className="brand-locations-count">
									{dataObject?.isMulti
										? `Selected ${Object.keys(dataObject?.selectedBrands).length}`
										: ""}{" "}
									{dataObject?.isMulti
										? `brand(s) ${
												Object.keys(dataObject?.selectedBrands).length > 1 ? "are " : "is "
										  }`
										: dataObject?.name
										? dataObject?.name + " is "
										: "Brand is "}
									currently associated with{" "}
									{dataObject?.isMulti
										? Object.keys(dataObject?.selectedBrands).reduce(
												(accumulator, current) =>
													accumulator +
													(dataObject?.selectedBrands[current]?.brandLocationsCount || 0),
												0
										  )
										: dataObject?.brandLocationsCount || 0}{" "}
									location(s).
								</div>
								<div className="item-assoc-count">
									{dataObject?.isMulti ? "They are" : "It is"} associated with{" "}
									{dataObject?.isMulti
										? Object.keys(dataObject?.selectedBrands).reduce(
												(accumulator, current) =>
													accumulator +
													(dataObject?.selectedBrands[current]?.brandItemsCount || 0),
												0
										  )
										: dataObject?.brandItemsCount}{" "}
									item(s)
								</div>
								<div className="warning">
									If you {mode === "archive" ? "archive" : "restore"}{" "}
									{dataObject?.isMulti ? "these brand(s), they" : "this brand, it"} will{" "}
									{mode === "archive" ? "not" : ""} be a part of your customer-facing catalogue on
									your apps and website.
								</div>
								<div className="confirm-process">
									<CheckBox
										checked={isChecked}
										clickHandler={() => {
											setChecked((current) => !current);
										}}
									/>
									Proceed with {mode === "archive" ? "archiving" : "restoring"}{" "}
									{dataObject?.isMulti
										? `selected ${Object.keys(dataObject?.selectedBrands).length} brand(s)`
										: dataObject?.name || "brand"}
									.
								</div>
							</div>
						)}
					</React.Fragment>
				);

			case "itemsList":
				return (
					<React.Fragment>
						{loading ? (
							<div className="entity-details">
								<Loading />
							</div>
						) : respState !== "" ? (
							<div className="entity-details">
								<div className={`end-result ${respState}`}>
									{respState === "success"
										? `Successfully ${mode === "archive" ? "archived" : "restored"} the ${
												dataObject?.isMulti ? "items" : "item"
										  }.`
										: `Failed to ${mode === "archive" ? "archive" : "restore"} ${
												dataObject?.isMulti ? "items" : "item"
										  }.`}
								</div>
							</div>
						) : (
							<div className="entity-details">
								<div className="brand-locations-count">
									{dataObject?.isMulti
										? `Selected ${Object.keys(dataObject?.selectedBrands).length}`
										: ""}{" "}
									{dataObject?.isMulti
										? `brand(s) ${
												Object.keys(dataObject?.selectedBrands).length > 1 ? "are " : "is "
										  }`
										: dataObject?.itemTitle
										? dataObject?.itemTitle + " is "
										: "Item is "}
									currently associated with{" "}
									{dataObject?.isMulti
										? Object.keys(dataObject?.selectedBrands).reduce(
												(accumulator, current) =>
													accumulator +
													(dataObject?.selectedBrands[current]?.brandLocationsCount || 0),
												0
										  )
										: dataObject?.locationsCount || 0}{" "}
									location(s).
								</div>
								{/* <div className='item-assoc-count'>{dataObject?.isMulti ? 'They are' : 'It is'} also associated with {dataObject?.isMulti ? Object.keys(dataObject?.selectedBrands).reduce((accumulator, current) => accumulator + (dataObject?.selectedBrands[current]?.brandItemsCount || 0), 0) : dataObject?.brandItemsCount} item(s)</div> */}
								<div className="warning">
									If you {mode === "archive" ? "archive" : "restore"}{" "}
									{dataObject?.isMulti ? "these item(s), they" : "this item, it"} will{" "}
									{mode === "archive" ? "not" : ""} be a part of your customer-facing catalogue on
									your apps and website.
								</div>
								<div className="confirm-process">
									<CheckBox
										checked={isChecked}
										clickHandler={() => {
											setChecked((current) => !current);
										}}
									/>
									Proceed with {mode === "archive" ? "archiving" : "restoring"}{" "}
									{dataObject?.isMulti
										? `selected ${Object.keys(dataObject?.selectedBrands).length} items(s)`
										: dataObject?.itemTitle || "item"}
									.
								</div>
							</div>
						)}
					</React.Fragment>
				);
			case "menus":
				return (
					<React.Fragment>
						{loading ? (
							<div className="entity-details">
								<Loading />
							</div>
						) : respState !== "" ? (
							<div className="entity-details">
								<div className={`end-result ${respState}`}>
									{respState === "success"
										? `Successfully ${mode === "archive" ? "archived" : "restored"} the ${
												dataObject?.isMulti ? "menus" : "menu"
										  }.`
										: `Failed to ${mode === "archive" ? "archive" : "restore"} ${
												dataObject?.isMulti ? "menus" : "menu"
										  }.`}
								</div>
							</div>
						) : (
							<div className="entity-details">
								<div className="brand-locations-count">
									{dataObject?.isMulti
										? `Selected ${Object.keys(dataObject?.selectedBrands).length}`
										: ""}{" "}
									{dataObject?.isMulti
										? `brand(s) ${
												Object.keys(dataObject?.selectedBrands).length > 1 ? "are " : "is "
										  }`
										: dataObject?.menuTitle
										? dataObject?.menuTitle + " is "
										: "Menu is "}
									currently published on{" "}
									{dataObject?.isMulti
										? Object.keys(dataObject?.selectedBrands).reduce(
												(accumulator, current) =>
													accumulator +
													(dataObject?.selectedBrands[current]?.brandLocationsCount || 0),
												0
										  )
										: dataObject?.locationCount || 0}{" "}
									location(s).
								</div>
								{/* <div className='item-assoc-count'>{dataObject?.isMulti ? 'They are' : 'It is'} also associated with {dataObject?.isMulti ? Object.keys(dataObject?.selectedBrands).reduce((accumulator, current) => accumulator + (dataObject?.selectedBrands[current]?.brandItemsCount || 0), 0) : dataObject?.brandItemsCount} item(s)</div> */}
								<div className="warning">
									If you {mode === "archive" ? "archive" : "restore"}{" "}
									{dataObject?.isMulti ? "these menu(s), they" : "this menu, it"} will{" "}
									{mode === "archive" ? "still" : ""} be a part of your customer-facing catalogue on
									your apps and website unless you remove it from the published locations.
								</div>
								<div className="confirm-process">
									<CheckBox
										checked={isChecked}
										clickHandler={() => {
											setChecked((current) => !current);
										}}
									/>
									Proceed with {mode === "archive" ? "archiving" : "restoring"}{" "}
									{dataObject?.isMulti
										? `selected ${Object.keys(dataObject?.selectedBrands).length} menu(s)`
										: dataObject?.itemTitle || "menu"}
									.
								</div>
							</div>
						)}
					</React.Fragment>
				);

			case "apiAccess":
				return (
					<React.Fragment>
						{loading ? (
							<div className="entity-details">
								<Loading />
							</div>
						) : respState !== "" ? (
							<div className="entity-details">
								<div className={`end-result ${respState}`}>
									{respState === "success"
										? `Successfully ${mode === "archive" ? "archived" : "restored"} the ${
												dataObject?.isMulti ? "API accesses" : "API access"
										  }.`
										: `Failed to ${mode === "archive" ? "archive" : "restore"} ${
												dataObject?.isMulti ? "API accesses" : "API Access"
										  }.`}
								</div>
							</div>
						) : (
							<div className="entity-details">
								<div className="brand-locations-count">
									{mode === "archive"
										? "Archiving API access can disrupt integrations"
										: "Restoring API access reinstates all previous integrations"}
								</div>
								<div className="warning">
									If you {mode === "archive" ? "archive" : "restore"}{" "}
									{dataObject?.isMulti ? "these API accesses, they" : "this API access, it"}
									{mode === "archive"
										? " will no longer be possible for the consumer systems to use this API access anymore until it's restored."
										: " will enable consumer systems to use this API access."}
								</div>
								<div className="confirm-process">
									<CheckBox
										checked={isChecked}
										clickHandler={() => {
											setChecked((current) => !current);
										}}
									/>
									Proceed with {mode === "archive" ? "archiving" : "restoring"} API access
								</div>
							</div>
						)}
					</React.Fragment>
				);

			case "menuRule":
				return (
					<React.Fragment>
						{loading ? (
							<div className="entity-details">
								<Loading />
							</div>
						) : respState !== "" ? (
							<div className="entity-details">
								<div className={`end-result ${respState}`}>
									{respState === "success"
										? `Successfully deleted configured ${dataObject?.isMulti ? "rules" : "rule"}.`
										: `Failed to delete rule configured.`}
								</div>
							</div>
						) : (
							<div className="entity-details">
								<div className="brand-locations-count">
									Deleting this rule will remove all the overriden values pertaining to the targetted
									item / modifier.
								</div>
								<div className="warning">
									If you delete {dataObject?.isMulti ? "these rules, they" : "this rule, the "}
									{"overrides made will no longer be available on the upcoming menu publishes"}
								</div>
								<div className="confirm-process">
									<CheckBox
										checked={isChecked}
										clickHandler={() => {
											setChecked((current) => !current);
										}}
									/>
									Proceed with deleting rule
								</div>
							</div>
						)}
					</React.Fragment>
				);

			case "analyticsCompareDelete":
				return (
					<React.Fragment>
						{loading ? (
							<Loading />
						) : (
							<div className="analytics-compare">
								<div className="info-text">Would you like to delete the comparison ?</div>

								<div className="confirm-process">
									<CheckBox
										checked={isChecked}
										clickHandler={() => {
											setChecked((current) => !current);
										}}
									/>
									Proceed with deleting comparison
								</div>
							</div>
						)}
					</React.Fragment>
				);

			case "analyticsCompareRemoved":
				return (
					<React.Fragment>
						{loading ? (
							<Loading />
						) : (
							<div className="analytics-compare">
								<div className="info-text">Would you like to remove the comparison ?</div>

								<div className="confirm-process">
									<CheckBox
										checked={isChecked}
										clickHandler={() => {
											setChecked((current) => !current);
										}}
									/>
									Proceed with removing comparison
								</div>
							</div>
						)}
					</React.Fragment>
				);

			default:
				return null;
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			close={handleClose}
			title={`${capitaliseText(mode)} ${entityName}${dataObject?.isMulti ? "(s)" : ""} ?`}
			classes="archive-restore--modal"
			showCancelAction
			disabled={!isChecked}
			showDeleteAction={(mode === "archive" || mode === "delete" || mode == "remove") && respState !== "success"}
			deleteAction={handleSubmit}
			showSubmitAction={mode === "restore" && respState !== "success"}
			deleteTitle={mode === "delete" ? "Delete" : mode === "remove" ? "Remove" : "Archive"}
			submitAction={handleSubmit}
			submitTitle="Restore"
			submitClass="restore-action"
			hideActions={loading}
			cancelTitle="Close"
		>
			{renderContent()}
		</Modal>
	);
};

export default ArchiveRestoreModal;
