import React from "react";

export class RadioButton extends React.Component {
	render() {
		return (
			<div
				onClick={this.props.clickHandler || void 0}
				className={"at-radiobutton " + (this.props.classes || "")}
				title={this.props.title || ""}
			>
				<input onChange={(e) => {}} type="radio" checked={this.props.checked} />
				<span className="checkmark"></span>
				{this.props.children}
			</div>
		);
	}
}
