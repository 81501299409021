import React, { useEffect, useRef, useState } from "react";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";
import Image from "../_commons/Image";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import { Paginator } from "../_commons/Paginator";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";
import { ActionTypes } from "../../actions/_types";
import { Button } from "../_commons/Button";
import ItemCreate from "../../containers/ItemCreate";

// third party
import { connect } from "react-redux";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { clientMenu } from "../../client-menu";

// graphql
import { GET_MENU_SECTION_ITEMS_LIST, GET_ITEM_TAGS } from "../../graphql/menus";
import { GET_ITEMS_LIST } from "../../graphql/items";

// utils
import { truncateText, printCurrency } from "../../atlas-utils";

// constants
import { FOODTYPE_MAPS } from "../../client-config";

const ITEMS_DATA_INIT_STATE = {
	limit: 10,
	offset: 0,
	totalObjectCount: 0,
	filters: [],
	objects: []
};

const FOOD_TYPE_OPTIONS = [
	{
		value: "VEGETARIAN",
		valueForDisplay: "Vegetarian"
	},
	{
		value: "NON_VEGETARIAN",
		valueForDisplay: "Non Vegetarian"
	},
	{
		value: "EGGETARIAN",
		valueForDisplay: "Eggetarian"
	},
	{
		value: "NA",
		valueForDisplay: "N/A"
	}
];

const AddItemsToSection = ({
	isAddItemsDrawerOpen,
	showHideAddItemsDrawer,
	handleItemAdditionToSelectedSection,
	temporaryPersistedData,
	menuId,
	categoryId,
	brand,
	menuDetailsData,
	currencySymbol
}) => {
	const [itemsData, setItemsData] = useState(ITEMS_DATA_INIT_STATE);
	const [selectedItems, setSelectedItems] = useState(new Map()); // Using Map for order preservation
	const [isAllFieldSelected, setIsAllFieldSelected] = useState(false);
	const [loadingStates, setLoadingState] = useState({ initialLoad: true, loading: false });
	const { offset } = itemsData;
	const [itemTagOptions, setItemTagOptions] = useState([]);
	const [selectedItemTag, setSelectedItemTag] = useState();
	const [selectedFoodType, setSelectedFoodType] = useState();
	const [itemSearchKeyword, setItemSearchKeyword] = useState("");
	const [isItemCreateFormOpen, setIsItemCreateFormOpen] = useState(false);
	const [foodTypeOptions, setFoodTypeOptions] = useState(FOOD_TYPE_OPTIONS);

	const debouncedItemSearchRef = useRef();

	const itemsTableColumn = [
		{
			title: "Item Name",
			dataIndex: "item",
			render: (data) => {
				let primaryTitle = "";
				let secondaryTitle = "";
				if (data?.titleInMenu && data?.titleInMenu !== data?.itemTitle) {
					primaryTitle = data?.titleInMenu;
					secondaryTitle = data?.itemTitle;
				} else {
					primaryTitle = data?.itemTitle;
				}

				return (
					<div className="item-details-container">
						<Image
							src={data?.imageUrl || "/assets/empty-dish.jpg"}
							alt={data?.itemTitle}
							className="item-img"
							errorOverrideImage="/assets/empty-dish.jpg"
						/>
						<FoodTypeIndicator foodType={FOODTYPE_MAPS[data?.foodType]} />
						<div className="item-title" title={primaryTitle}>
							<span className="primary-name">{truncateText(primaryTitle, 30)}</span>
							{secondaryTitle ? <span className="secondary-name">{secondaryTitle}</span> : null}
						</div>
					</div>
				);
			}
		},
		{
			title: "CRM TITLE",
			dataIndex: "crmData",
			render: (data) => {
				return (
					<div className="crm-data">
						<p className="crm-data-name">{data?.crmTitle}</p>
						<p className="crm-data-pos-id" title={data?.posId}>
							POS ID: {truncateText(data?.posId, 20)}
						</p>
					</div>
				);
			}
		},
		// {
		// 	title: 'Category',
		// 	dataIndex: 'category',
		// 	render: (data) => (data)
		// },
		{
			title: "Base Price",
			dataIndex: "price",
			render: (data) => (
				<div>
					{printCurrency(currencySymbol)}
					{data}
				</div>
			)
		}
	];

	useEffect(() => {
		setSelectedItems(temporaryPersistedData?.addItemsTempData?.selectedItemsUnderCategory || new Map());
	}, [temporaryPersistedData]);

	const fetchMenuSectionItemsList = async (searchKeyword = "") => {
		setLoadingState((current) => ({
			...current,
			loading: true
		}));
		try {
			const filters = [];
			if (selectedItemTag) {
				filters.push({
					field: "tags",
					value: selectedItemTag.value
				});
			}

			if (selectedFoodType) {
				filters.push({
					field: "food_type",
					value: selectedFoodType.value
				});
			}

			let variables = {
				limit: 10,
				offset: itemsData?.offset,
				// not sending brand as items will be filtered based on menuId
				// and we need all items here
				// brand: isNaN(brand) ? null : Number(brand),
				menuId,
				categoryId,
				filters
			};

			if (searchKeyword) {
				variables.search = [{ key: "default", value: searchKeyword }];
			}
			const resp = await client.query({
				query: GET_ITEMS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			let restructuredResp = {
				...resp?.data?.items
			};
			restructuredResp.objects = restructuredResp.objects.map((obj) => ({
				...obj,
				item: {
					itemTitle: obj?.itemTitle,
					foodType: obj?.foodType,
					imageUrl: obj?.imageUrl || "",
					tags: obj?.tags
				},
				price: obj.itemPrice,
				crmData: {
					crmTitle: obj.crmTitle,
					posId: obj.merchantRefId
				}
			}));

			restructuredResp.objects.forEach((obj) => {
				const itemsInMenu = menuDetailsData?.items || [];
				const itemInMenu = itemsInMenu.find((item) => String(item.id) === String(obj.id));
				if (itemInMenu) {
					obj.item.titleInMenu = itemInMenu?.overrides?.name || "";
				}
			});

			setFoodTypeOptions(
				restructuredResp?.filters?.find((filter) => filter.field === "food_type")?.values || FOOD_TYPE_OPTIONS
			);
			setItemTagOptions(restructuredResp?.filters?.find((filter) => filter.field === "tags")?.values || []);
			setItemsData(restructuredResp);
			setLoadingState((current) => ({
				...current,
				initialLoad: false
			}));
		} catch (e) {
			console.log(e);
		}
		setLoadingState((current) => ({
			...current,
			loading: false
		}));
	};

	const fetchMenuSectionItemsListdebounced = (searchKeyword) => {
		if (debouncedItemSearchRef.current) {
			clearTimeout(debouncedItemSearchRef.current);
		}
		debouncedItemSearchRef.current = setTimeout(() => {
			fetchMenuSectionItemsList(searchKeyword);
		}, 500);
	};

	const handleAllFieldsSelection = (state) => {
		if (!state) {
			setSelectedItems(new Map());
			setIsAllFieldSelected(false);
			return;
		}

		const updatedSelectedItems = new Map(selectedItems);
		(itemsData?.objects ?? []).forEach((item) => updatedSelectedItems.set(item.id, true));
		setSelectedItems(updatedSelectedItems);
		setIsAllFieldSelected(true);
	};

	const handleSingleFieldSelection = (id) => {
		const key = String(id);

		setSelectedItems((prevSelectedItems) => {
			const updatedSelectedItems = new Map(prevSelectedItems);

			if (updatedSelectedItems.has(key)) {
				updatedSelectedItems.delete(key);
				setIsAllFieldSelected(false);
			} else {
				updatedSelectedItems.set(key, true);
			}

			return updatedSelectedItems;
		});
	};

	const handleItemSearch = (value) => {
		setItemSearchKeyword(value);
		fetchMenuSectionItemsListdebounced(value);
	};

	const handleTagSelection = (field, value) => {
		setSelectedItemTag(value);
	};

	const handleFoodTypeSelection = (field, value) => {
		setSelectedFoodType(value);
	};

	const openItemCreationForm = () => {
		setIsItemCreateFormOpen(true);
	};
	const closeItemCreationForm = (refresh = false, createdItemId) => {
		if (refresh) {
			fetchMenuSectionItemsList(itemSearchKeyword);
			setSelectedItems((current) => {
				const updatedItems = new Map(current);
				updatedItems.set(createdItemId, true);
				return updatedItems;
			});
		}
		setIsItemCreateFormOpen(false);
	};

	const handlePagination = (page) => {
		setItemsData((current) => ({
			...current,
			offset: (page - 1) * 10
		}));
	};

	useEffect(() => {
		if (isAddItemsDrawerOpen) {
			fetchMenuSectionItemsList();
		}
	}, [isAddItemsDrawerOpen]);

	useEffect(() => {
		fetchMenuSectionItemsList(itemSearchKeyword);
	}, [offset, selectedItemTag, selectedFoodType]);

	const handleClose = () => {
		setSelectedItems(new Map());
		setIsAllFieldSelected(false);
		showHideAddItemsDrawer(false);
	};

	return (
		<FormSidebar
			isOpen={isAddItemsDrawerOpen}
			title="Add item to Section"
			subTitle="Add / Remove items from this section"
			hideSubmitAction={!itemsData?.objects?.length}
			close={handleClose}
			submitTitle="Save"
			disabled={selectedItems.size === 0}
			submit={() => handleItemAdditionToSelectedSection(Array.from(selectedItems.keys()))}
			loading={!loadingStates.initialLoad && loadingStates?.loading}
			headerRight={
				<Button type="secondary" onClick={openItemCreationForm}>
					Create New Item
				</Button>
			}
		>
			<div className="add-items-to-section-container">
				<div className="filters-search-container">
					<div className="filters-container">
						<SelectFilter
							placeholder="Type"
							options={foodTypeOptions}
							value={selectedFoodType}
							valueKey="value"
							labelKey="valueForDisplay"
							setFilter={handleFoodTypeSelection}
						/>
						<SelectFilter
							placeholder="Tags"
							currValue={selectedItemTag}
							options={itemTagOptions}
							valueKey="value"
							labelKey="valueForDisplay"
							setFilter={handleTagSelection}
						/>
					</div>
					<SearchFilter
						placeholder="Search Item"
						filterOption={{
							field: "name"
						}}
						setFilter={(f, v) => handleItemSearch(v)}
						value={itemSearchKeyword}
					/>
				</div>
				<FormTable
					columns={itemsTableColumn}
					dataSource={itemsData?.objects}
					isSelectionEnabled
					handleAllFieldsSelection={handleAllFieldsSelection}
					handleSingleFieldSelection={handleSingleFieldSelection}
					selectedFields={Object.fromEntries(selectedItems)}
					isAllFieldSelected={isAllFieldSelected}
					isLoading={loadingStates.initialLoad && loadingStates?.loading}
				/>
				<Paginator
					limit={itemsData?.limit}
					offset={itemsData?.offset}
					count={itemsData.count || 0}
					goToPage={handlePagination}
				/>
			</div>
			{isItemCreateFormOpen && (
				<ItemCreate
					isNested
					isViewedFromMenu
					isOpen={isItemCreateFormOpen}
					hasAccess
					close={closeItemCreationForm}
					temporaryPersistedData={temporaryPersistedData}
				/>
			)}
		</FormSidebar>
	);
};

export default connect((store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(AddItemsToSection);
