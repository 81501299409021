import React, { Component } from "react";

// components
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { CommonTable } from "../components/_commons/CommonTable";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchRolesList } from "../actions/roles";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES } from "../client-config";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Role",
		field: "name",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text name"} title={record.name} key={i}>
				<Link className={"hyperlink hyperlink--black-color"} to={`/unified-access/edit/${record.id}`}>
					{record.name || record.id}
				</Link>
			</div>
		)
	},
	{
		name: "Users",
		field: "users",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text users"} key={i}>
				{record.numUsers || 0}
			</div>
		)
	}
];

@connect((store) => ({
	rolesList: store.rolesList,
	rolesListState: store.rolesListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems
}))
export class UnifiedRolesList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			search: "",
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "roles_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch Roles list
		await fetchRolesList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updaterolesListState = (payload) => {
		store.dispatch({
			type: ActionTypes.ROLES_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updaterolesListState({
			currentFilters: this.props.rolesListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.rolesListState.currentFilters
		};
		currentFilters[field] = value;
		this.updaterolesListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.rolesListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updaterolesListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updaterolesListState({
			appliedFilters: {
				...this.props.rolesListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "roles_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchRolesList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}

		const { appliedFilters } = store.getState().rolesListState;
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updaterolesListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchRolesList();
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.rolesListState;
		const offset = (page - 1) * limit;
		this.updaterolesListState({
			offset
		});
		// fetch new Roles list
		fetchRolesList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.rolesListState;
		if (size && size?.value !== limit) {
			this.updaterolesListState({
				[field]: size.value
			});
			// fetch new Roles list
			await fetchRolesList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updaterolesListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.ROLES_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchRolesList();
	};

	render() {
		const { rolesList, rolesListState, configItems } = this.props;
		const { limit, offset, currentFilters, sortedField } = rolesListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "search") {
				if (currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}

		return (
			<div>
				<div ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={rolesList.data.filters || []}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					{/* <div className="information">
						<img src="/assets/icons/icon-wand.svg" alt="" />
						<div>All users across businesses for <b>Atlas & Prime</b> can be managed via a single screen</div>
						<div className="know-more">Know More</div>
					</div> */}
					<CommonTable
						loading={rolesList.loading}
						data={rolesList.data.objects || []}
						columns={columns}
						sortList={this.sortList}
						sortedField={sortedField}
						classes="roles-list-table-container"
						content="Roles"
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={rolesList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
					<NestedEntityContainer
						show={this.state.nestedEntity.show}
						type={this.state.nestedEntity.type}
						id={this.state.nestedEntity.id}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
						isNested={false}
						isForeignSource={true}
					/>
				</div>
			</div>
		);
	}
}
