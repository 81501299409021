import gql from "graphql-tag";

export const GET_WEBHOOKS_LIST = gql`
	query getWebhooksList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		webhooks(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				created
				url
				eventTypeDisplayName
			}
		}
	}
`;

export const WEBHOOK_EVENT_TYPES = gql`
	query getWebhookEventTypes {
		webhookEventTypes {
			value
			valueForDisplay
		}
	}
`;

export const WEBHOOK_RETRY_MODES = gql`
	query getWebhookRetryModes {
		webhookRetryModes {
			value
			valueForDisplay
		}
	}
`;

export const CREATE_WEBHOOK = gql`
	mutation createWebhook($url: String, $eventType: Event, $retriesMode: RetryMode, $headers: String) {
		saveWebhook(input: { url: $url, eventType: $eventType, retriesMode: $retriesMode, headers: $headers }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				created
				url
				eventTypeDisplayName
			}
		}
	}
`;

export const GET_WEBHOOK_DETAIL = gql`
	query getWebhookDetail($id: Int) {
		webhook(id: $id) {
			id
			url
			headers
			isActive
			created
			retriesMode
			eventType
			eventTypeDisplayName
		}
	}
`;

export const EDIT_WEBHOOK = gql`
	mutation createWebhook(
		$id: Int
		$url: String
		$eventType: Event
		$retriesMode: RetryMode
		$headers: String
		$isActive: Boolean
	) {
		saveWebhook(
			input: {
				id: $id
				url: $url
				eventType: $eventType
				retriesMode: $retriesMode
				headers: $headers
				isActive: $isActive
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				created
				url
				eventTypeDisplayName
			}
		}
	}
`;

export const GET_WEBHOOK_HISTORY = gql`
	query getWebhookHistory($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		webhook(id: $id) {
			deliveries(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					statusCode
					retriesCount
					lastExecuted
				}
			}
		}
	}
`;

export const GET_WEBHOOK_HISTORY_DETAIL = gql`
	query getWebhookHistoryDetail($id: String) {
		webhookDelivery(id: $id) {
			id
			statusCode
			headers
			payload
			warnings
		}
	}
`;

export const REDELIVER_WEBHOOK = gql`
	mutation redeliverWebhook($id: String) {
		redeliverWebhook(input: { id: $id }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
