import React from "react";
import { connect } from "react-redux";
import { getBrandDetail } from "../../../helpers/periscope";
import { extractInitials } from "../../../atlas-utils";
import { handleCreateCheckItem, handleRemoveImportantItem } from "../../../actions/periscope";
import { store } from "../../../store/configureStore";

const SpecificLocationBrandItem = ({ props, configItems, login, isMultibrandEnabled }) => {
	const brands = configItems?.brands?.items;
	let brandDetails = getBrandDetail(props.value.brand_id, brands);
	let image = brandDetails?.image;
	let classname;
	let textLogo;
	if (!image) {
		textLogo = isMultibrandEnabled ? extractInitials(props.value?.brand?.split(" ")) : "";
		classname = brandDetails?.color;
	}

	const checkItem = async (val) => {
		if (val?.is_important) {
			let variables = {
				itemId: String(val?.important_item_id)
			};
			const respItemId = await handleRemoveImportantItem(variables);

			if (respItemId) {
				store.dispatch({
					type: "ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE",
					payload: respItemId
				});
			}
		} else {
			let variables = {
				bizId: String(login.loggedInbizDetail.id),
				brandId: isMultibrandEnabled ? val.brand_id : null,
				name: val.item
			};
			let receivedId = await handleCreateCheckItem(variables);

			if (receivedId && receivedId?.id) {
				store.dispatch({
					type: "ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE_FALSE_VALUE",
					payload: receivedId
				});
			}
		}
	};

	return (
		<div className="specificitem-location-cell" style={{}}>
			<div className="select-star-div">
				<div className="star-div-width" onClick={() => checkItem(props?.value)}>
					{props?.value?.is_important ? (
						<>
							<img style={{ marginRight: "-1px" }} src="/assets/periscope/brandLabelImportant.svg" />
						</>
					) : (
						<>
							<img src="/assets/periscope/brandLabel.svg" />
						</>
					)}
				</div>
			</div>
			<div className="specificlocation-item-rating" style={{}}>
				{image ? (
					<img src={image} style={{ width: "20px", height: "20px", borderRadius: "2px" }} />
				) : isMultibrandEnabled ? (
					<div className={`image-info logo ${classname}`}>
						<span className="brand-image-location">{textLogo}</span>
					</div>
				) : null}

				<span className="locationitem-text">{props?.value?.item}</span>
				<div className="locationrating-div">
					<span className="locationrating-text"> {props?.value?.rating}</span>
					<img src="/assets/periscope/star-rating.svg" className="location-star-display" style={{}} />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(SpecificLocationBrandItem);
