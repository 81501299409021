import React, { useEffect, useState, useRef, useCallback } from "react";

// third party
import { connect } from "react-redux";
import _ from "lodash";

// components
import { Modal } from "../../components/_commons/Modal";
import { InputWithLabel } from "../../components/_commons/InputWithLabel";
import { SelectFilter } from "../../components/_commons/SelectFilter";
import { Switch } from "../../components/_commons/Switch";
import Header from "../../components/MerakiWebEditor/Header";
import Sidebar from "../../components/MerakiWebEditor/Sidebar";

// utils
import { store } from "../../store/configureStore";
import { objectMapToArray } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";
import {
	fetchMerakiWebWorkspace,
	fetchMerakiWebPagesList,
	createMerakiWebPage,
	fetchMerakiWebConfig,
	editMerakiWebConfig
} from "../../actions/merakiWeb";

import { EDITOR_FIELD_TYPES } from "../../components/MerakiWebEditor/editorConfig";
import { getPreviewUrl } from "../../services/common";
import Content from "./Content";

const CUSTOM_PAGE_FORM_INITIAL_STATE = {
	_id: null,
	name: "",
	path: "",
	content: "<p>This is the initial content of the page.</p>",
	enabled: true,
	seo: {
		en: {
			description: "",
			keywords: "",
			title: ""
		}
	},
	addToFooter: true,
	addToMenu: true,
	isHomePage: false,
	contentType: "generic-text-page"
};

export const CUSTOM_PAGE_TYPES = [
	{
		label: "Generic text page",
		value: "generic-text-page"
	},
	{
		label: "Raw html page",
		value: "raw-html-page"
	}
];

export const SIDEBAR_MODES = {
	themeSettings: "themeSettings",
	pageSettings: "pageSettings",
	addComponents: "addComponents"
};

export const CONTENT_MODES = {
	preview: {
		value: "preview",
		label: "Preview"
	},
	customPageEditor: {
		value: "customPageEditor",
		label: "Editor"
	}
};

export const PREVIEW_MODES = {
	desktop: "desktop",
	tablet: "tablet",
	phone: "phone"
};

const MerakiWebEditorContainer = ({ merakiWebWorkspace, biz, history }) => {
	const currentTheme = new URLSearchParams(history.location.search).get("theme");
	const tinymceRef = useRef(null);
	const [isEditorTouched, setEditorTouched] = useState(false);
	const [loadingPages, setLoadingPages] = useState(false);
	const [pages, setPages] = useState([]);
	const [selectedPage, setSelectedPage] = useState(undefined);
	const [contentMode, setContentMode] = useState(CONTENT_MODES.preview);
	const [previewMode, setPreviewMode] = useState(PREVIEW_MODES.desktop);
	const { loading: loadingWorkspace, data: WebWorkspaceData } = merakiWebWorkspace;
	const [isModalOpen, setModalOpen] = useState(false);
	const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState(undefined);
	const [customPageForm, setCustomPageForm] = useState(CUSTOM_PAGE_FORM_INITIAL_STATE);
	const [rawHTMLContent, setRawHTMLContent] = useState("");
	const [config, setConfig] = useState(null);
	const [sidebarMode, setSidebarMode] = useState(SIDEBAR_MODES.themeSettings);
	const [previewUrl, setPreviewUrl] = useState(getPreviewUrl(biz?.id, currentTheme));

	useEffect(() => {
		fetchMerakiWebWorkspace();
		fetchPagesList();
		fetchConfig();
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	React.useEffect(() => {
		if (!loadingWorkspace) {
			setPreviewUrl(getPreviewUrl(biz?.id, currentTheme));
		}
	}, [loadingWorkspace, biz, currentTheme]);

	const fetchPagesList = async () => {
		setLoadingPages(true);
		try {
			const pages = await fetchMerakiWebPagesList(currentTheme);
			const themePages = pages.theme.map((page) => {
				page.type = "theme";
				return page;
			});
			const customPages = pages.custom.map((page) => {
				page.type = "custom";
				return page;
			});
			const allPages = themePages.concat(customPages);
			allPages.sort((pg1, pg2) => (pg1._id === "landing_screen" ? -1 : 1));
			setPages(allPages);
			if (!selectedPage) {
				// check if landing page enabled, otherwise set menu page as selected
				const landingPage = allPages.find((pg) => pg._id === "landing_screen");
				const menuPage = allPages.find((pg) => pg._id === "menu");
				if (landingPage?.enabled) {
					handleSelectPage(landingPage);
				} else {
					handleSelectPage(menuPage);
				}
			} else {
				// update the selectedPage object
				const updatedSelectedPage = allPages.find((pg) => pg._id === selectedPage._id);
				if (updatedSelectedPage) {
					handleSelectPage(updatedSelectedPage);
				}
			}
		} catch (error) {}
		setLoadingPages(false);
	};

	const fetchConfig = useCallback(async () => {
		try {
			const data = await fetchMerakiWebConfig(currentTheme);
			setConfig(data);
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "There was an error while fetching the config object",
					timeout: 2000,
					error: true
				}
			});
		}
	}, [currentTheme]);

	const handleSelectPage = useCallback(
		(page) => {
			setSelectedPage(page);
			if (page.type === "custom") {
				if (page._id) {
					const previewUrl = getPreviewUrl(biz?.id, currentTheme) + page.path;
					setPreviewUrl(previewUrl);
					setContentMode(CONTENT_MODES.preview);
					if (page.contentType === "raw-html-page") {
						setRawHTMLContent(page.content);
					}
				} else {
					setRawHTMLContent(page.content);
					setContentMode(CONTENT_MODES.customPageEditor);
				}
			} else {
				// if theme page then set preview url to main page
				const previewUrl = getPreviewUrl(biz?.id, currentTheme);
				setPreviewUrl(previewUrl);
				setContentMode(CONTENT_MODES.preview);
			}
		},
		[biz]
	);

	const handlePreviewPageSelected = useCallback(
		(pageId) => {
			if (!pageId || pageId === selectedPage?._id) {
				return;
			}

			const page = pages.find((p) => p._id === pageId);
			// if landing page is disabled then dont auto switch page
			if (page?._id === "landing_screen" && !page?.enabled) {
				return;
			}
			if (page) {
				handleSelectPage(page);
			}
		},
		[pages, handleSelectPage, selectedPage]
	);

	const handleCreatePage = () => {
		setModalOpen(true);
		setCustomPageForm(CUSTOM_PAGE_FORM_INITIAL_STATE);
	};

	const handleCustomPageForm = (field, value) => {
		const update = {
			...customPageForm,
			[field]: value
		};
		if (field === "contentType") {
			update[field] = value?.value;
		}
		if (field === "name") {
			const path =
				"/" +
				value
					.toLowerCase()
					.replace(/ /g, "-")
					.replace(/[^\w-]+/g, "");
			update.path = path;
		}
		setCustomPageForm(update);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setCustomPageForm(CUSTOM_PAGE_FORM_INITIAL_STATE);
	};

	const handleCustomPageFormSubmit = async () => {
		setModalOpen(false);
		const updatedPages = pages.slice();
		const page = {
			...customPageForm,
			type: "custom"
		};
		updatedPages.push(page);
		setPages(updatedPages);
		handleSelectPage(page);
		if (!isEditorTouched) {
			setEditorTouched(true);
		}
	};

	const handleConfirmModal = () => {
		if (modalContent.field === "delete" && selectedPage.type === "custom") {
			let updatedConfig = { ...config };
			updatedConfig.customPages = updatedConfig.customPages.filter((page) => page._id !== selectedPage._id);

			let updatedPages = pages.filter((page) => page._id !== selectedPage._id);

			// reset modal content
			setModalContent(undefined);

			// select and navigate to default page
			handleSelectPage(updatedPages[0]);

			// close modal
			setConfirmModalOpen(false);

			if (!isEditorTouched) {
				setEditorTouched(true);
			}
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Page deleted!",
					timeout: 2000,
					error: false
				}
			});

			// update config and pages
			setConfig(updatedConfig);
			setPages(updatedPages);
		}
	};

	const handleLandingPageSelector = (variant) => {
		// update content of the selected page
		const updatedSelectedPage = {
			...selectedPage,
			variant
		};
		setSelectedPage(updatedSelectedPage);

		// update content of the selected page in config if exists
		const update = { ...config };
		const currPage = update?.theme.pages?.find((page) => page._id === selectedPage._id);
		if (currPage) {
			currPage.variant = variant;
			currPage.enabled = true;
			setConfig(update);
		}

		// enable save button on header
		if (!isEditorTouched) {
			setEditorTouched(true);
		}
	};

	const handleEditorChange = (content) => {
		// update content of the selected page
		const updatedSelectedPage = {
			...selectedPage,
			content
		};
		setSelectedPage(updatedSelectedPage);

		// update content of the selected page in config if exists
		const update = { ...config };
		const currPage = update?.customPages?.find((page) => page._id === selectedPage._id);
		if (currPage) {
			currPage.content = content;
			setConfig(update);
		}

		// raw-html custom page
		if (selectedPage.contentType === CUSTOM_PAGE_TYPES[1].value) {
			setRawHTMLContent(content);
		}

		// enable save button on header
		if (!isEditorTouched) {
			setEditorTouched(true);
		}
	};

	const handleSave = async () => {
		try {
			// create page if it doesnt exist - only in custom page mode
			// then update currently selected page
			let page;
			const update = { ...config };
			if (selectedPage?.type === "custom") {
				if (!selectedPage._id) {
					page = await createMerakiWebPage(selectedPage);
					page.type = "custom";
					update.customPages.push(page);
				} else {
					page = update?.customPages?.find((page) => page._id === selectedPage._id);
				}
				page.type = "custom";
			} else if (selectedPage?.type === "theme") {
				page = update?.theme?.pages?.find((page) => page._id === selectedPage._id);
				page.type = "theme";
			}
			handleSelectPage(page);

			// update the config
			await editMerakiWebConfig(update);
			await fetchPagesList();
			await fetchConfig();
			await fetchMerakiWebWorkspace();
			setEditorTouched(false);
		} catch (error) {}
	};

	const addCustomComponentToPage = (component) => {
		let page;
		const update = { ...config };
		if (selectedPage.type === "theme") {
			page = update.theme.pages.find((page) => page._id === selectedPage._id);
		} else if (selectedPage.type === "custom") {
			page = update.customPages.find((page) => page._id === selectedPage._id);
		}
		if (page) {
			if (!page.components) {
				page.components = [];
			}
			// allow only one instance of a component in the page
			if (page.components.find((cmp) => cmp._id === component._id)) {
				throw "This component already exists in the page";
			}
			page.components.push(component);
		}
		setConfig(update);
	};

	const handleSidebarInput = (cfg, value, selectedComponent) => {
		if (cfg.fieldType === EDITOR_FIELD_TYPES.dropdown) {
			value = value.value;
		}

		const update = { ...config };

		// if namespace is in root then override everything
		// and set value at root
		if (cfg.namespaceRoot) {
			_.set(update, cfg.namespace, value);
		} else if (sidebarMode === SIDEBAR_MODES.pageSettings) {
			if (selectedPage.type === "theme") {
				// set value for theme pages
				if (selectedComponent) {
					// set page component level settings
					if (selectedComponent?.component?.componentType === "global") {
						// handle theme.components
						const component = update?.theme?.components?.find(
							(component) => component._id === selectedComponent?.component?.id
						);
						_.set(component, cfg.namespace, value);
					} else if (selectedComponent?.component?.componentType === "local") {
						// handle page.components
						const currPage = update?.theme?.pages?.find((page) => page._id === selectedPage._id);
						const component = currPage
							? currPage.components?.find(
									(component) => component._id === selectedComponent?.component?.id
							  )
							: undefined;
						_.set(component, cfg.namespace, value);
					}
				} else {
					// set page level settings in the config
					const currPage = update?.theme?.pages?.find((page) => page._id === selectedPage._id);
					_.set(currPage, cfg.namespace, value);

					// update the value in currently selected page object as well
					const updatedSelectedPage = { ...selectedPage };
					_.set(updatedSelectedPage, cfg.namespace, value);
					setSelectedPage(updatedSelectedPage);
				}
			} else if (selectedPage.type === "custom") {
				// set value for custom pages
				if (selectedComponent) {
					// set page component level settings
					if (selectedComponent?.component?.componentType === "global") {
						// handle theme.components
						const component = update?.theme?.components?.find(
							(component) => component._id === selectedComponent?.component?.id
						);
						_.set(component, cfg.namespace, value);
					} else if (selectedComponent?.component?.componentType === "local") {
						// handle page.components
						const currPage = update?.customPages?.find((page) => page._id === selectedPage._id);
						const component = currPage?.components?.find(
							(component) => component._id === selectedComponent?.component?.id
						);
						_.set(component, cfg.namespace, value);
					}
				} else {
					// set page level settings in the config
					const currPage = update?.customPages?.find((page) => page._id === selectedPage._id);
					_.set(
						currPage,
						cfg.namespace,
						cfg.field === "path" ? (value[0] !== "/" ? `/${value}` : value) : value
					);
				}
			}
		} else if (sidebarMode === SIDEBAR_MODES.themeSettings) {
			_.set(update.theme, cfg.namespace, value);
		}

		setConfig(update);

		// enable save button on header
		if (!isEditorTouched) {
			setEditorTouched(true);
		}
	};

	// TODO
	// this method is only used for page delete
	// we can create a dedicated method for that instead
	const handleSidebarAction = async (cfg, selectedComponent) => {
		setConfirmModalOpen(true);
		setModalContent(cfg);
	};

	const handleContentMode = (mode) => {
		setContentMode(mode);
	};

	return (
		<div className={"meraki-web-editor-container " + (loadingWorkspace ? "disabled " : "")}>
			<Header
				workspace={WebWorkspaceData}
				previewMode={previewMode}
				setPreviewMode={setPreviewMode}
				pages={pages}
				biz={biz}
				selectedPage={selectedPage}
				handleSelectPage={handleSelectPage}
				handleCreatePage={handleCreatePage}
				handleSave={handleSave}
				contentMode={contentMode}
				history={history}
				contentModes={objectMapToArray(CONTENT_MODES)}
				handleContentMode={handleContentMode}
				isEditorTouched={isEditorTouched}
				themeName={currentTheme}
			/>
			<Sidebar
				selectedPage={selectedPage}
				config={config}
				setConfig={setConfig}
				sidebarMode={sidebarMode}
				setSidebarMode={setSidebarMode}
				history={history}
				handleSidebarInput={handleSidebarInput}
				addCustomComponentToPage={addCustomComponentToPage}
				handleSidebarAction={handleSidebarAction}
				isEditorTouched={isEditorTouched}
				setEditorTouched={(state) => setEditorTouched(state)}
			/>
			<Content
				contentMode={contentMode}
				previewMode={previewMode}
				tinymceRef={tinymceRef}
				selectedPage={selectedPage}
				handleEditorChange={handleEditorChange}
				rawHTMLContent={rawHTMLContent}
				previewUrl={previewUrl}
				config={config}
				workspace={WebWorkspaceData}
				handlePreviewPageSelected={handlePreviewPageSelected}
				handleLandingPageSelector={handleLandingPageSelector}
			/>

			<Modal
				isOpen={isModalOpen}
				close={handleModalClose}
				title="Custom page"
				subTitle="Enter the details for new custom page"
				showSubmitAction={true}
				submitTitle="Next"
				submitAction={handleCustomPageFormSubmit}
				showCancelAction={true}
				cancelTitle="Cancel"
				disabled={customPageForm.name === "" ? true : false}
			>
				<div className="custom-page-modal-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={customPageForm.name}
							onChange={(e) => handleCustomPageForm("name", e.target.value)}
						>
							Name
						</InputWithLabel>
						<SelectFilter
							title="Type"
							options={CUSTOM_PAGE_TYPES}
							field="contentType"
							currValue={CUSTOM_PAGE_TYPES.find((page) => page.value === customPageForm?.contentType)}
							setFilter={handleCustomPageForm}
							labelKey="label"
							valueKey="value"
							isSearchable={false}
						/>
					</div>
					<div className="form-row row-half">
						<Switch
							title="Appear in Menu"
							checked={customPageForm.addToMenu}
							clickHandler={() => handleCustomPageForm("addToMenu", !customPageForm.addToMenu)}
						/>
						<Switch
							title="Appear in Footer"
							checked={customPageForm.addToFooter}
							clickHandler={() => handleCustomPageForm("addToFooter", !customPageForm.addToFooter)}
						/>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={isConfirmModalOpen}
				close={() => {
					setConfirmModalOpen(false);
					setModalContent(undefined);
				}}
				showSubmitAction={true}
				submitTitle="Yes"
				submitAction={handleConfirmModal}
				showCancelAction={true}
				cancelTitle="Cancel"
			>
				<div className="custom-page-modal-container">
					{modalContent && modalContent.field === "delete" && (
						<div className="alert-msg">
							This action is irreversible! Are you sure you want to <b>delete</b> {selectedPage.name}{" "}
							page?
						</div>
					)}
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (store) => ({
	merakiWebWorkspace: store.merakiWebWorkspace,
	merakiWebThemesList: store.merakiWebThemesList,
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(MerakiWebEditorContainer);
