import React, { useState, useCallback, useEffect } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { SelectFilter } from "../_commons/SelectFilter";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";

// third party
import moment from "moment";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { feedbackReply } from "../../actions/customerFeedback";

// constants
const REPLY_OPTIONS = [
	{ label: "Email", value: "EMAIL" },
	{ label: "SMS", value: "SMS" }
];

const Replies = ({ isOpen = false, close, data = {}, isNested = false }) => {
	const [isFormTouched, setFormTouched] = useState(false);
	const [npsStatus, setNpsStatus] = useState({});
	const [currReplyOption, setCurrReplyOption] = useState(REPLY_OPTIONS[0]);
	const [isReplySent, setReplySent] = useState(false);
	const [replyData, setReplyData] = useState({
		subject: "",
		message: ""
	});
	const [loading, setLoading] = useState(false);

	const handleClose = useCallback(() => {
		// reset state before closing
		setLoading(false);
		setFormTouched(false);
		setReplyData({ subject: "", message: "" });
		setCurrReplyOption(REPLY_OPTIONS[0]);
		setReplySent(false);
		setNpsStatus({});
		close();
	}, [close]);

	const getNpsStatus = (rating) => {
		let npsStatus = {};
		if (rating >= 0 && rating <= 6) {
			npsStatus.face = "sad";
			npsStatus.type = "Detractor";
		} else if (rating >= 7 && rating <= 8) {
			npsStatus.face = "neutral";
			npsStatus.type = "Passive";
		} else {
			npsStatus.face = "happy";
			npsStatus.type = "Promoter";
		}
		setNpsStatus(npsStatus);
	};

	useEffect(() => {
		getNpsStatus(data.rating);
	}, [data]);

	const handleForm = (field, value) => {
		setReplyData({
			...replyData,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		if (replyData.message) {
			setLoading(true);
			const variables = {
				...replyData,
				feedbackId: data.id,
				medium: currReplyOption.value
			};
			const resp = await feedbackReply(variables);
			if (resp) {
				setFormTouched(false);
				setReplySent(true);
			}
			setLoading(false);
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Please fill all the required fields",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const renderTitle = () => {
		return (
			<div className="rating-info">
				{npsStatus.face && <img src={`/assets/icons/icon-${npsStatus.face}-face.svg`} alt="" />}
				<div>
					<div className="rating">Rating: {data.rating || "--"}</div>
					<div className="rating-type">{npsStatus.type || "--"}</div>
				</div>
			</div>
		);
	};

	return (
		<div className="replies-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSubmit}
				title={renderTitle()}
				submitTitle="Reply"
				loading={loading}
				hideActions={!isFormTouched}
				isNested={isNested}
			>
				<div className="form-content">
					{data.comments && (
						<div className="customer-comments">
							<div>{data.comments}</div>
							{data.name && <div className="customer-name">&#8212; {data.name}</div>}
						</div>
					)}
					{!isReplySent && data.replies.length > 0 && (
						<div className="last-reply-container">
							<div className="header-container">
								<div className="title">
									Last Reply
									<span>{`(${moment(data.replies.slice(-1)[0].created).format(
										"DD MMM, YYYY - hh:mm A"
									)})`}</span>
								</div>
								<div className="channel">via {data.replies.slice(-1)[0].channel}</div>
							</div>
							<div className="reply-body">
								{data.replies.slice(-1)[0].channel === "Email" && (
									<div className="subject-container">
										<div className="title">Subject:</div>
										<div>{data.replies.slice(-1)[0].subject}</div>
									</div>
								)}
								<div className="message-container">
									<div className="title">Message:</div>
									<div>{data.replies.slice(-1)[0].body}</div>
								</div>
							</div>
						</div>
					)}
					{!isReplySent && data.replies.length === 0 && (
						<div className="reply-action-container">
							<div className="header-container">
								<div className="title">Reply</div>
								<SelectFilter
									options={REPLY_OPTIONS}
									currValue={currReplyOption}
									field="currReplyOption"
									setFilter={(field, value) => setCurrReplyOption(value)}
									isClearable={false}
									isSearchable={false}
								/>
							</div>
							<div className="reply-body">
								{currReplyOption.value === "EMAIL" && (
									<InputWithLabel
										value={replyData.subject}
										onChange={(e) => handleForm("subject", e.target.value)}
										requiredLabel={true}
									>
										Subject
									</InputWithLabel>
								)}
								<Textarea
									value={replyData.message}
									onChange={(e) => handleForm("message", e.target.value)}
									requiredLabel={true}
								>
									Message
								</Textarea>
							</div>
						</div>
					)}
					{isReplySent && (
						<div className="last-reply-container">
							<div className="header-container">
								<div className="title">
									Last Reply
									<span>{`(${moment().format("DD MMM, YYYY - hh:mm A")})`}</span>
								</div>
								<div className="channel">via {currReplyOption.label}</div>
							</div>
							<div className="reply-body">
								{currReplyOption.value === "EMAIL" && (
									<div className="subject-container">
										<div className="title">Subject:</div>
										<div>{replyData.subject}</div>
									</div>
								)}
								<div className="message-container">
									<div className="title">Message:</div>
									<div>{replyData.message}</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
export default Replies;
