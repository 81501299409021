import React, { useState, useImperativeHandle } from "react";

// components
import ExternalLogDetail from "./ExternalLogDetail";

// third party
import { useTrail, config, animated } from "react-spring";
import moment from "moment";

// config
import { ORDER_EXTERNAL_LOGS_LIST_HEADER_FIELDS } from "../../client-config";

const Downstream = ({ loading = false, data = [], nestedRef, setModalBusy }) => {
	const [externalLogsDetailView, setExternalLogsDetailView] = useState(false);
	const [selectedLogDetail, setSelectedLogDetail] = useState({});

	const openLogDetailView = (key) => {
		setSelectedLogDetail(data.find((log, index) => index === key));
		setExternalLogsDetailView(true);
		setModalBusy(true);
	};

	const closeLogDetailView = () => {
		setExternalLogsDetailView(false);
		setModalBusy(false);
		setSelectedLogDetail({});
	};

	useImperativeHandle(
		nestedRef,
		() => ({
			closeLogDetailView
		}),
		[closeLogDetailView]
	);

	return (
		<div className={"downstream-container " + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Downstream</div>
					<div className="header-subtext">View external logs for this order</div>
				</div>
			</div>
			<Table data={data} loading={loading} handleLogDetailView={openLogDetailView} />
			<ExternalLogDetail
				isOpen={externalLogsDetailView}
				close={closeLogDetailView}
				data={selectedLogDetail}
				url={selectedLogDetail.requestUrl || ""}
			/>
		</div>
	);
};
export default Downstream;

export const Table = ({ data, loading, sortList, sortedField, handleLogDetailView }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container downstream-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={ORDER_EXTERNAL_LOGS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={i}
							handleLogDetailView={handleLogDetailView}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							index={i}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Logs found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({ index, created, requestUrl, responseStatusCode, purpose, style, handleLogDetailView }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text purpose" title={purpose}>
				<a role="button" className="link-text" onClick={() => handleLogDetailView(index)}>
					{purpose || "Order"}
				</a>
				<div className="desc-text">{created ? moment(created).format("DD MMM, YYYY - hh:mm:ss A") : ""}</div>
			</div>
			<div className="at-table-cell at-cell-text url" title={requestUrl}>
				{requestUrl || "--"}
			</div>
			<div
				className={
					"at-table-cell at-cell-text status-code " + (responseStatusCode === 200 ? "success" : "error")
				}
			>
				<span>{responseStatusCode}</span>
			</div>
		</animated.div>
	);
};
