import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { useTrail, config, animated } from "react-spring";

// graphql
import { GET_CAMPAIGN_LIST, DELETE_CAMPAIGN } from "../../graphql/campaigns";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";
import history from "../../history";

// component
import { GlobalConfirmModal } from "../../components/SiteComp";
import { Paginator } from "../_commons/Paginator";
import { Filters } from "../_commons/Filters";
import Placeholder from "../_commons/Placeholder";
import CreateIcon from "../_commons/CreateIcon";

// utils
import { lS, formatDate, scroll } from "../../atlas-utils";
import { ActionTypes } from "../../actions/_types";

// constants
import { CAMPAIGN_STATUS_LABELS } from "../../client-config";
import { TRACK_EVENT } from "../../atlas-utils/tracking";

@connect((store) => ({
	campaignListState: store.campaignListState,
	campaignList: store.campaignList,
	dimensions: store.configItems.dimensions
}))
export class CampaignList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hoveredCampaign: null,
			deleteCampaignList: [],
			showFilters: false,
			currFilters: {},
			filterApplied: {},
			cloneCampaignModalBusy: false,
			showCloneCampaignModal: false,
			clonedCampaignTitle: "",
			clonedCampaignDescription: "",
			sortField: ""
		};
	}

	componentDidMount() {
		this.fetchCampaignList();
	}

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.campaignListState;
		const offset = (page - 1) * limit;
		store.dispatch({
			type: "CAMPAIGN_LIST_STATE_CHANGE",
			payload: {
				offset
			}
		});
		// get new campaigns
		this.fetchCampaignList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.campaignListState;
		if (size.value !== limit) {
			store.dispatch({
				type: "CAMPAIGN_LIST_STATE_CHANGE",
				payload: {
					[field]: size.value
				}
			});
			// fetch new campaigns list
			await this.fetchCampaignList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef.offsetTop - 57, left: 0 });
		}
	};

	fetchCampaignList = async () => {
		const { limit, offset, sort } = store.getState().campaignListState;
		const variables = {
			limit,
			offset
		};
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		let { currFilters } = this.state;
		let filtersObject = [];
		Object.keys(currFilters).forEach((f) => {
			if (currFilters[f].value) {
				filtersObject.push(currFilters[f]);
			}
		});
		variables.filters = filtersObject;
		store.dispatch({
			type: "GET_CAMPAIGN_LIST_REQUEST"
		});
		store.dispatch({
			type: "TOGGLE_GLOBAL_LOADER",
			payload: true
		});
		try {
			const resp = await client.query({
				query: GET_CAMPAIGN_LIST,
				variables,
				fetchPolicy: "network-only"
			});
			store.dispatch({
				type: "GET_CAMPAIGN_LIST_SUCCESS",
				payload: resp.data.campaigns
			});
		} catch (error) {
			store.dispatch({
				type: "GET_CAMPAIGN_LIST_FAILURE",
				error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		store.dispatch({
			type: "TOGGLE_GLOBAL_LOADER",
			payload: false
		});
	};

	sortList = (field) => {
		this.setState({
			sortField: field
		});
		const sort = {
			field
		};
		store.dispatch({
			type: "CAMPAIGN_LIST_STATE_CHANGE_SORT",
			payload: {
				sort
			}
		});
		store.dispatch({
			type: "CAMPAIGN_LIST_STATE_CHANGE",
			payload: {
				offset: 0
			}
		});
		this.fetchCampaignList();
	};

	handleCampaignNavigation(i, j) {
		let { status, id } = j;
		if (status == "DRAFT") {
			let campaignObj = {
				object: {
					id
				}
			};
			lS.remove("savedCampaign");
			lS.set("savedCampaign", campaignObj);
			store.dispatch({
				type: "SEGMENT_RESET"
			});
			history.push(`/campaigns/new`);
		} else {
			// track this event
			// PubSub.publish(TRACK_EVENT, {
			// 	tracker: 'mixpanel',
			// 	eventName: 'campaign_conversion_view',
			// });

			history.push(`/campaigns/${id}`);
		}
	}

	mouseEnterHandler = (i, j) => {
		this.setState({
			hoveredCampaign: j.id
		});
	};

	deleteCamapaign = async (e, j) => {
		e.stopPropagation();
		if (!window.confirm("Are you sure?")) {
			return false;
		}
		// just to show fast UI responsiveness
		let deleteCampaignList = [...this.state.deleteCampaignList];
		deleteCampaignList.push(j.id);
		this.setState({
			deleteCampaignList
		});
		try {
			await client.mutate({
				mutation: DELETE_CAMPAIGN,
				variables: {
					id: Number(j.id)
				}
			});
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Campaign successfully deleted",
					timeout: 5000,
					error: false
				}
			});
			// track this event
			// PubSub.publish(TRACK_EVENT, {
			// 	tracker: 'mixpanel',
			// 	eventName: 'campaign_delete',
			// 	eventMeta: {
			// 		source: 'campaign_list_view',
			// 	},
			// });
		} catch (error) {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: error.message || "Sorry, could not delete the campaign",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	cloneCampaign = (e, campaign) => {
		e.stopPropagation();
		this.setState({
			showCloneCampaignModal: true,
			campaignToBeCloned: campaign
		});
	};

	cloneCampaignProceed = (e) => {
		e.stopPropagation();
		lS.remove("savedCampaign");
		let campaign = this.state.campaignToBeCloned;
		// clone campaign data
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				name: this.state.clonedCampaignTitle,
				description: this.state.clonedCampaignDescription,
				medium: campaign.medium,
				imageUrl: campaign.imageUrl,
				imageId: campaign.imageId,
				campaignExecutionType: campaign.campaignExecutionType,
				message: campaign.message,
				messageType: campaign.messageType,
				subject: campaign.subject,
				includeInNotifications: campaign.includeInNotifications,
				conversionConfig: campaign.conversionConfig
			}
		});

		// clone segment data
		let segmentPayload = {};
		if (campaign.segment) {
			segmentPayload = {
				title: campaign.segment.title,
				isImplicit: true,
				filters: campaign.segment.filters,
				segmentType: "saved"
			};
		}
		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload: segmentPayload
		});

		// clone email template data
		let emailTemplatePayload = {};
		if (campaign.emailTemplate) {
			emailTemplatePayload = {
				subject: campaign.emailTemplate.subject,
				html: campaign.emailTemplate.html,
				isEditable: campaign.emailTemplate.isEditable
			};
		}
		store.dispatch({
			type: "EMAIL_TEMPLATE_UPDATE",
			payload: emailTemplatePayload
		});

		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'campaign_clone',
		// });

		// finally route to create campaign page
		history.push("/campaigns/new");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	setFilter = (field, value) => {
		let currFilters = { ...this.state.currFilters };
		currFilters[field] = value;
		this.setState({
			currFilters
		});
	};

	applyFilters = () => {
		this.setState({
			filterApplied: this.state.currFilters,
			showFilters: false
		});
		store.dispatch({
			type: "CAMPAIGN_LIST_STATE_CHANGE",
			payload: {
				offset: 0
			}
		});
		this.fetchCampaignList();

		// track this event
		const { currFilters } = this.state;
		let filterCount = 0;
		for (let f in currFilters) {
			if (currFilters[f].value != "") {
				filterCount++;
			}
		}
		// if(filterCount > 0) {
		// 	PubSub.publish(TRACK_EVENT, {
		// 		tracker: 'mixpanel',
		// 		eventName: 'campaign_list_filter',
		// 		eventMeta: {
		// 			num_of_filters_applied: filterCount,
		// 		}
		// 	});
		// }
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false,
				filterApplied: {},
				currFilters: {}
			},
			() => {
				store.dispatch({
					type: "CAMPAIGN_LIST_STATE_CHANGE",
					payload: {
						offset: 0
					}
				});
				this.fetchCampaignList();
			}
		);
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false,
			currFilters: this.state.filterApplied
		});
	};

	handleCloneCampaignModal = () => {
		this.setState({
			showCloneCampaignModal: !this.state.showCloneCampaignModal
		});
	};

	changeClonedCampaignTitleDesc = (e, type) => {
		if (type == "title") {
			this.setState({
				clonedCampaignTitle: e.target.value
			});
		} else {
			this.setState({
				clonedCampaignDescription: e.target.value
			});
		}
	};

	render() {
		const { campaignList, campaignListState, dimensions } = this.props;
		const { showFilters, currFilters, sortField } = this.state;
		let filterCount = 0;
		for (let f in currFilters) {
			if (currFilters[f].value != "") {
				filterCount++;
			}
		}
		return (
			<div ref={(ref) => (this.tableRef = ref)}>
				<GlobalConfirmModal
					backdropHandler={this.handleCloneCampaignModal}
					confirmBtnEnabled={this.state.clonedCampaignTitle.trim()}
					show={this.state.showCloneCampaignModal}
					confirmHandler={this.cloneCampaignProceed}
					cancelHandler={this.handleCloneCampaignModal}
					modalBusy={this.state.cloneCampaignModalBusy}
				>
					<div className="discard-confirm-modal">
						<div className="title">Clone campaign</div>
						<div className="modal-text">Want to give your campaign a nice title?</div>
						<div className="save-campaign-title">
							<input
								onChange={(e) => {
									this.changeClonedCampaignTitleDesc(e, "title");
								}}
								value={this.state.clonedCampaignTitle}
								type="text"
								placeholder="Enter a campaign title"
							/>
						</div>
						<div className="modal-text Mt(15px) C(#ccc)">Campaign description</div>
						<div className="segment-description">
							<input
								value={this.state.clonedCampaignDescription}
								onChange={(e) => {
									this.changeClonedCampaignTitleDesc(e, "desc");
								}}
							/>
						</div>
					</div>
				</GlobalConfirmModal>
				{dimensions.width > 768 && (
					<Filters
						isOpen={showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={campaignList.data.filters}
						currentFilters={currFilters}
						setFilter={this.setFilter}
					/>
				)}
				<div className="header">
					<div className="header-text">Campaigns</div>
					<div className="subheader-text">All your past, running & scheduled campaigns are shown here</div>
					{dimensions.width > 768 && (
						<div className={(showFilters ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={this.flipShowFilters}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
					)}
				</div>
				<CampaignListTable
					mouseEnterHandler={this.mouseEnterHandler}
					clickHandler={this.handleCampaignNavigation}
					sortList={this.sortList}
					sortField={sortField}
					hoveredCampaign={this.state.hoveredCampaign}
					deleteCamapaign={this.deleteCamapaign}
					cloneCampaign={this.cloneCampaign}
					deleteCampaignList={this.state.deleteCampaignList}
					campaignList={campaignList}
					dimensions={dimensions}
				/>
				<Paginator
					limit={campaignListState.limit}
					offset={campaignListState.offset}
					count={campaignList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
			</div>
		);
	}
}

const CampaignListTable = (props) => {
	let {
		campaignList: { loading, data },
		sortList,
		clickHandler,
		mouseEnterHandler,
		hoveredCampaign,
		deleteCamapaign,
		cloneCampaign,
		deleteCampaignList,
		sortField,
		dimensions
	} = props;
	let filtered = data.objects.filter((i) => deleteCampaignList.indexOf(i.id) < 0);
	const trails = useTrail(filtered.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	if (loading && filtered.length == 0) {
		return (
			<div className="P(20px)">
				<div className="shimmer H(100px) Mb(20px)"></div>
				<div className="shimmer H(100px)"></div>
			</div>
		);
	}

	const placeholderContent = {
		placeholderText: "No campaigns created yet!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-campaign.svg",
		placeholderSubtext:
			"Manage your marketing campaigns and track their performance to achieve maximum growth for your business",
		placeholderButtonContent: (
			<>
				<CreateIcon />
				<span>Create your first Campaign</span>
			</>
		),
		placeholderButtonClickAction: () => {
			history.push("/campaigns/new");
		},
		size: "medium"
	};

	if (filtered.length === 0 && !loading) {
		return <Placeholder {...placeholderContent} />;
	}

	return (
		<div className={(loading ? "disabled " : "") + "at-table-row-based at-table--6cols campaign-list-table"}>
			<TableHeader sortList={sortList} sortField={sortField} dimensions={dimensions} />
			{trails.map(({ rotate }, i) => (
				<TableList
					key={filtered[i].id}
					_key={i}
					cloneCampaign={cloneCampaign}
					hoveredCampaign={hoveredCampaign}
					deleteCamapaign={deleteCamapaign}
					campaign={filtered[i]}
					clickHandler={clickHandler}
					mouseEnterHandler={mouseEnterHandler}
					dimensions={dimensions}
					style={{
						transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
					}}
				/>
			))}
		</div>
	);
};

const TableHeader = ({ sortList, sortField, dimensions }) => {
	if (dimensions.width <= 768) {
		return (
			<React.Fragment>
				<div className="at-table-row">
					<div className="at-table-cell at-table-header at-header-text title">TITLE</div>
					<div
						className={
							"at-table-cell at-table-header at-header-text medium clickable " +
							(sortField === "medium" ? "active" : "")
						}
						onClick={() => sortList("medium")}
					>
						<span>MEDIUM</span>
						&nbsp;&nbsp;
						<span>
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					</div>
					<div className="at-table-cell at-table-header at-header-text status">STATUS</div>
				</div>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			<div className="at-table-row">
				<div className="at-table-cell at-table-header at-header-text title">TITLE</div>
				<div className="at-table-cell at-table-header at-header-text execution-time">EXECUTION TIME</div>
				<div
					className={
						"at-table-cell at-table-header at-header-text medium clickable " +
						(sortField === "medium" ? "active" : "")
					}
					onClick={() => sortList("medium")}
				>
					<span>MEDIUM</span>
					&nbsp;&nbsp;
					<span>
						<img src="/assets/icons/icon-sort.svg" alt="" />
					</span>
				</div>
				<div
					className={
						"at-table-cell at-table-header at-header-text sent clickable " +
						(sortField === "totalSent" ? "active" : "")
					}
					onClick={() => sortList("totalSent")}
				>
					<span>SENT</span>
					&nbsp;&nbsp;
					<span>
						<img src="/assets/icons/icon-sort.svg" alt="" />
					</span>
				</div>
				<div className="at-table-cell at-table-header at-header-text status">STATUS</div>
				<div className="at-table-cell at-table-header at-header-text conversion">
					<span>CONVERSION</span>
					&nbsp;&nbsp;
				</div>
			</div>
		</React.Fragment>
	);
};

const TableList = (props) => {
	let {
		campaign,
		clickHandler,
		_key,
		mouseEnterHandler,
		hoveredCampaign,
		deleteCamapaign,
		cloneCampaign,
		dimensions,
		style
	} = props;
	const title = campaign.name;
	const runTime = campaign.runTime;
	const medium = campaign.mediumDisplayName ? campaign.mediumDisplayName : "-";
	const totalSent = campaign.totalSent ? campaign.totalSent : "-";
	const status = campaign.status
		? CAMPAIGN_STATUS_LABELS[campaign.status]
			? CAMPAIGN_STATUS_LABELS[campaign.status]
			: campaign.status
		: "-";
	const conversion = campaign.conversion ? campaign.conversion + " %" : "-";

	if (dimensions.width <= 768) {
		return (
			<animated.div
				onMouseEnter={(e) => {
					mouseEnterHandler(e, { id: campaign.id });
				}}
				onMouseLeave={(e) => {
					mouseEnterHandler(e, { id: null });
				}}
				onClick={(e) => {
					clickHandler(e, { id: campaign.id, status: campaign.status });
				}}
				className={
					(_key % 2 == 1 ? " striped" : "") +
					(hoveredCampaign == campaign.id ? " hovered" : "") +
					" at-table-row clickable"
				}
				style={style}
			>
				<div className="at-table-cell at-cell-text title" title={title}>
					{title}
				</div>
				<div className="at-table-cell at-cell-text medium" title={medium}>
					<MediumIcon medium={campaign.medium} displayName={medium} />
				</div>
				<div className={status && status.toLowerCase() + " at-table-cell at-cell-text status"} title={status}>
					{status}
				</div>
				<div className={(campaign.status == "DRAFT" ? "" : "clone-only") + " action-panel"}>
					<div onClick={(e) => cloneCampaign(e, campaign)}>Clone</div>
					<div
						onClick={(e) => {
							deleteCamapaign(e, { id: campaign.id });
						}}
					>
						Delete
					</div>
				</div>
			</animated.div>
		);
	}
	return (
		<animated.div
			onMouseEnter={(e) => {
				mouseEnterHandler(e, { id: campaign.id });
			}}
			onMouseLeave={(e) => {
				mouseEnterHandler(e, { id: null });
			}}
			onClick={(e) => {
				clickHandler(e, { id: campaign.id, status: campaign.status });
			}}
			className={
				(_key % 2 == 1 ? " striped" : "") +
				(hoveredCampaign == campaign.id ? " hovered" : "") +
				" at-table-row clickable"
			}
			style={style}
		>
			<div className="at-table-cell at-cell-text title" title={title}>
				{title}
			</div>
			<div className="at-table-cell at-cell-text execution-time" title={formatDate(runTime)}>
				{formatDate(runTime)}
			</div>
			<div className="at-table-cell at-cell-text medium" title={medium}>
				<MediumIcon medium={campaign.medium} displayName={medium} />
			</div>
			<div className="at-table-cell at-cell-text sent" title={totalSent}>
				{totalSent}
			</div>
			<div className={status && status.toLowerCase() + " at-table-cell at-cell-text status"} title={status}>
				{status}
			</div>
			<div className="at-table-cell at-cell-text conversion" title={conversion}>
				{conversion}
			</div>
			<div className={(campaign.status == "DRAFT" ? "" : "clone-only") + " action-panel"}>
				<div onClick={(e) => cloneCampaign(e, campaign)}>Clone</div>
				<div
					onClick={(e) => {
						deleteCamapaign(e, { id: campaign.id });
					}}
				>
					Delete
				</div>
			</div>
		</animated.div>
	);
};

const MediumIcon = ({ medium, displayName }) => {
	if (medium === "SMS") {
		return <img className="medium-icon" src="/assets/icons/campaign-medium/icon-SMS.png" title={displayName} />;
	}
	if (medium === "EMAIL") {
		return <img className="medium-icon" src="/assets/icons/campaign-medium/icon-email.png" title={displayName} />;
	}
	if (medium === "APP_PUSH_NOTIFICATION") {
		return (
			<img
				className="medium-icon"
				src="/assets/icons/campaign-medium/icon-push-notification.png"
				title={displayName}
			/>
		);
	}
	if (medium === "WEB_PUSH_NOTIFICATION") {
		return (
			<img
				className="medium-icon"
				src="/assets/icons/campaign-medium/icon-push-notification.png"
				title={displayName}
			/>
		);
	}
	return null;
};
