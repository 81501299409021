import gql from "graphql-tag";

export const GET_PLATFORMS_DISCOUNTS = gql`
	query fetchPlatformDiscounts {
		discountPlatforms {
			value
			valueForDisplay
			discountTypes {
				value
				valueForDisplay
			}
		}
	}
`;

export const GET_DISCOUNTS_LIST = gql`
	query getDiscountsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$entityType: PlatformDiscountEntityType!
	) {
		platformDiscounts(limit: $limit, offset: $offset, filters: $filters, sort: $sort, entityType: $entityType) {
			objects {
				... on ZomatoDiscountType {
					id
					title
					desc
					zomatoDiscountType: discountType
					platformName
					startsOn
					expiresOn
					isExpired
				}
				... on UberEatsDiscountType {
					id
					title
					desc
					uberEatsDiscountType: discountType
					platformName
					startsOn
					expiresOn
					isExpired
				}
			}
			count
			hasPrevious
			hasNext
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const CREATE_ZOMATO_DISCOUNT = gql`
	mutation createZomatoDiscount(
		$title: String!
		$desc: String
		$type: ZomatoDiscountTypeEnum
		$startsOn: String
		$expiresOn: String
		$value: Float
		$oneTimeUse: Boolean
		$minOrderAmount: Float
		$timingsGroup: Int
		$buyCount: Int
		$freeCount: Int
		$freebieLimitCount: Int
	) {
		saveZomatoDiscount(
			input: {
				title: $title
				desc: $desc
				type: $type
				startsOn: $startsOn
				expiresOn: $expiresOn
				value: $value
				oneTimeUse: $oneTimeUse
				minOrderAmount: $minOrderAmount
				timingsGroup: $timingsGroup
				buyCount: $buyCount
				freeCount: $freeCount
				freebieLimitCount: $freebieLimitCount
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				desc
				zomatoDiscountType: discountType
				platformName
				startsOn
				expiresOn
			}
		}
	}
`;

export const CREATE_UBEREATS_DISCOUNT = gql`
	mutation createUberEatsDiscount(
		$title: String!
		$desc: String
		$type: UberEatsDiscountTypeEnum
		$startsOn: String
		$expiresOn: String
		$value: Float
		$processOnCatalogue: Boolean
	) {
		saveUberEatsDiscount(
			input: {
				title: $title
				desc: $desc
				type: $type
				startsOn: $startsOn
				expiresOn: $expiresOn
				value: $value
				processOnCatalogue: $processOnCatalogue
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				desc
				uberEatsDiscountType: discountType
				platformName
				startsOn
				expiresOn
			}
		}
	}
`;

export const GET_DISCOUNT = gql`
	query getDiscount($id: Int, $entityType: PlatformDiscountEntityType!) {
		platformDiscount(id: $id, entityType: $entityType) {
			... on ZomatoDiscountType {
				id
				platformName
				title
				zomatoDiscountType: discountType
				startsOn
				expiresOn
				isActive
				value
				minOrderAmount
				oneTimeUse
				timingsGroup {
					id
					title
				}
				desc
				items {
					count
				}
				locations {
					count
				}
				buyCount
				freeCount
				freebieLimitCount
			}
			... on UberEatsDiscountType {
				id
				platformName
				title
				uberEatsDiscountType: discountType
				startsOn
				expiresOn
				isActive
				value
				processOnCatalogue
				desc
				items {
					count
				}
				locations {
					count
				}
			}
		}
	}
`;

export const EDIT_ZOMATO_DISCOUNT = gql`
	mutation editZomatoDiscount(
		$id: Int
		$title: String!
		$desc: String
		$type: ZomatoDiscountTypeEnum
		$startsOn: String
		$expiresOn: String
		$value: Float
		$oneTimeUse: Boolean
		$minOrderAmount: Float
		$timingsGroup: Int
		$buyCount: Int
		$freeCount: Int
		$freebieLimitCount: Int
		$isActive: Boolean
	) {
		saveZomatoDiscount(
			input: {
				id: $id
				title: $title
				desc: $desc
				type: $type
				startsOn: $startsOn
				expiresOn: $expiresOn
				value: $value
				oneTimeUse: $oneTimeUse
				minOrderAmount: $minOrderAmount
				timingsGroup: $timingsGroup
				buyCount: $buyCount
				freeCount: $freeCount
				freebieLimitCount: $freebieLimitCount
				isActive: $isActive
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				desc
				zomatoDiscountType: discountType
				platformName
				startsOn
				expiresOn
			}
		}
	}
`;

export const EDIT_UBEREATS_DISCOUNT = gql`
	mutation editUberEatsDiscount(
		$id: Int
		$title: String!
		$desc: String
		$type: UberEatsDiscountTypeEnum
		$startsOn: String
		$expiresOn: String
		$value: Float
		$processOnCatalogue: Boolean
		$isActive: Boolean
	) {
		saveUberEatsDiscount(
			input: {
				id: $id
				title: $title
				desc: $desc
				type: $type
				startsOn: $startsOn
				expiresOn: $expiresOn
				value: $value
				processOnCatalogue: $processOnCatalogue
				isActive: $isActive
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				desc
				uberEatsDiscountType: discountType
				platformName
				startsOn
				expiresOn
			}
		}
	}
`;

export const GET_DISCOUNT_ITEMS = gql`
	query getDiscountItems(
		$id: Int
		$entityType: PlatformDiscountEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		platformDiscount(id: $id, entityType: $entityType) {
			... on ZomatoDiscountType {
				items(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
					count
					filters {
						hide
						field
						valueForDisplay
						type
						values {
							valueForDisplay
							value
						}
					}
					objects {
						id
						itemTitle
						itemPrice
						crmTitle
						category
						foodType
						tags
					}
				}
			}
			... on UberEatsDiscountType {
				items(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
					count
					filters {
						hide
						field
						valueForDisplay
						type
						values {
							valueForDisplay
							value
						}
					}
					objects {
						id
						itemTitle
						itemPrice
						crmTitle
						category
						foodType
						tags
					}
				}
			}
		}
	}
`;

export const GET_DISCOUNT_LOCATIONS = gql`
	query getDiscountLocations(
		$id: Int
		$entityType: PlatformDiscountEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		platformDiscount(id: $id, entityType: $entityType) {
			... on ZomatoDiscountType {
				locations(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
					count
					filters {
						hide
						field
						valueForDisplay
						type
						values {
							valueForDisplay
							value
						}
					}
					objects {
						id
						title
						city
						tags
					}
				}
			}
			... on UberEatsDiscountType {
				locations(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
					count
					filters {
						hide
						field
						valueForDisplay
						type
						values {
							valueForDisplay
							value
						}
					}
					objects {
						id
						title
						city
						tags
					}
				}
			}
		}
	}
`;
