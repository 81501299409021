import React, { useState, useEffect, useCallback } from "react";

// components
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { CustomTable } from "../_commons/CustomTable";
import { InputWithLabel } from "../_commons/InputWithLabel";
import MerakiDetails from "./MerakiDetails";

// third party
import { connect } from "react-redux";

// actions
import { fetchBizPlatforms } from "../../actions/actions";

// assets
import { ButtonIcon } from "../_commons/ButtonIcon";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
const MERAKI_DETAILS_INIT_STATE = {
	isOpen: false,
	details: {}
};
const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, rest) => (
			<div className="table-cell name" key={i}>
				<div className="location-name">{record.bizLocationNickname || ""}</div>
			</div>
		)
	},
	{
		name: "Brands",
		field: "brands",
		render: (record, i, rest) => {
			if (rest.isMulti) {
				return (
					<div className="table-cell brands" key={i}>
						{record.brands.map((brand, j) => (
							<div className="brand-info" key={j}>
								<div className="brand-container">
									<div className={"brand-initials " + brand.color}>
										{brand.image ? (
											<img src={brand.image} alt="" />
										) : (
											rest.extractInitials(brand.name?.split(" "))
										)}
									</div>
									<div className="brand-name">{brand.name || ""}</div>
								</div>
								{brand?.platforms?.length > 1 &&
									brand?.platforms
										?.slice(0, -1)
										?.map((plf, k) => <div className="brand-container" key={k}></div>)}
							</div>
						))}
					</div>
				);
			}
			return (
				<div className="table-cell brands" title={record.name} key={i}>
					<div className="brand-container">
						<div className={"brand-initials " + record.color}>
							{record.image ? (
								<img src={record.image} alt="" />
							) : (
								rest.extractInitials(record.name?.split(" "))
							)}
						</div>
						<div className="brand-name">{record.name || ""}</div>
					</div>
				</div>
			);
		}
	},
	{
		name: "Platforms",
		field: "aggr-platforms",
		render: (record, i, rest) => {
			if (rest.isMulti) {
				return (
					<div className="table-cell aggr-platforms" key={i}>
						{record.brands?.length > 0 ? (
							record.brands?.map((brand, j) => (
								<div className="brand-platforms" key={j}>
									{brand?.platforms?.map((plf, k) => (
										<div className="plf-name" key={k}>
											<img
												src={
													plf?.logo ||
													CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
													"/assets/icons/icons8-globe-40.png"
												}
											/>
											<div>{plf.platformName}</div>
										</div>
									))}
								</div>
							))
						) : (
							<div className="brand-platforms">
								{record?.platforms?.map((plf, j) => (
									<div className="plf-name" key={j}>
										<img
											src={
												plf?.logo ||
												CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
												"/assets/icons/icons8-globe-40.png"
											}
										/>
										<div>{plf.platformName}</div>
									</div>
								))}
							</div>
						)}
					</div>
				);
			}
			return (
				<div className="table-cell aggr-platforms" key={i}>
					{record.platforms?.length > 0 ? (
						record.platforms?.map((plf, j) => (
							<div className="plf-name" key={j}>
								<img
									src={
										plf?.logo ||
										CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
										"/assets/icons/icons8-globe-40.png"
									}
								/>
								<div>{plf.platformName}</div>
							</div>
						))
					) : (
						<div className="plf-name">
							<img
								src={
									record?.logo ||
									CATALOGUE_PLATFORMS_LOGO[record.platformName.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
								}
							/>
							<div>{record.platformName}</div>
						</div>
					)}
				</div>
			);
		}
	},
	{
		name: "Platform ID",
		field: "platform-id",
		isRequired: true,
		render: (record, i, rest) => {
			if (rest.isMulti) {
				return (
					<div className="table-cell platform-id" key={i}>
						{record.brands?.length > 0 ? (
							record.brands?.map((brand, j) => (
								<div className="brand-platforms" key={j}>
									{brand?.platforms?.map((plf, k) => (
										<InputWithLabel
											key={k}
											value={plf.platformId}
											onChange={(e) =>
												rest.handleChange(
													"platformId",
													e.target.value,
													plf.id,
													brand.id,
													rest.rowIndex
												)
											}
											validationMessage={
												rest.validations?.[rest.rowIndex]?.platforms?.[brand.id]?.[plf.id]
													?.platformId || ""
											}
										/>
									))}
								</div>
							))
						) : (
							<div className="brand-platforms">
								{record?.platforms?.map((plf, j) =>
									plf.platformName === "Meraki" ? (
										<div className="platform-action">
											{record?.isMerakiDetailsSet ? (
												<div className="meraki-details-set">
													<img
														className="icon-check"
														src="/assets/icons/icon-check.svg"
														alt=""
													/>
													<div>Location and Delivery Area set</div>
													<span
														className="open"
														onClick={() => rest.handleOpenMerakiDetails(rest.rowIndex)}
													>
														Change
													</span>
													<span
														className="reset"
														onClick={() => rest.handleResetMerakiDetails(rest.rowIndex)}
													>
														Reset
													</span>
												</div>
											) : (
												<span
													className="open"
													onClick={() => rest.handleOpenMerakiDetails(rest.rowIndex)}
												>
													Set Location and Delivery Area
												</span>
											)}
										</div>
									) : (
										<InputWithLabel
											key={j}
											value={plf.platformId}
											onChange={(e) =>
												rest.handleChange(
													"platformId",
													e.target.value,
													plf.id,
													null,
													rest.rowIndex
												)
											}
											validationMessage={
												rest.validations?.[rest.rowIndex]?.platforms?.[plf.id]?.platformId || ""
											}
										/>
									)
								)}
							</div>
						)}
					</div>
				);
			}
			return (
				<div className="table-cell platform-id" key={i}>
					{record.platforms && record.platforms?.length > 0 ? (
						record.platforms?.map((plf, j) => (
							<InputWithLabel
								key={j}
								value={plf.platformId}
								onChange={(e) => rest.handleChange("platformId", e.target.value, plf.id, record.id)}
								validationMessage={rest.validations?.platforms?.[record.id]?.[plf.id]?.platformId || ""}
							/>
						))
					) : record.platformName === "Meraki" ? (
						<div className="platform-action">
							{rest?.formData?.isMerakiDetailsSet ? (
								<div className="meraki-details-set">
									<img className="icon-check" src="/assets/icons/icon-check.svg" alt="" />
									<div>Location and Delivery Area set</div>
									<span className="open" onClick={() => rest.handleOpenMerakiDetails(0)}>
										Change
									</span>
									<span className="reset" onClick={() => rest.handleResetMerakiDetails(0)}>
										Reset
									</span>
								</div>
							) : (
								<span className="open" onClick={() => rest.handleOpenMerakiDetails(0)}>
									Set Location and Delivery Area
								</span>
							)}
						</div>
					) : (
						<InputWithLabel
							value={record.platformId}
							onChange={(e) => rest.handleChange("platformId", e.target.value, record.id)}
							validationMessage={rest.validations?.platforms?.[record.id]?.platformId || ""}
						/>
					)}
				</div>
			);
		}
	},
	{
		name: "Platform URL",
		field: "platform-url",
		isRequired: true,
		render: (record, i, rest) => {
			if (rest.isMulti) {
				return (
					<div className="table-cell platform-url" key={i}>
						{record.brands?.length > 0 ? (
							record.brands?.map((brand, j) => (
								<div className="brand-platforms" key={j}>
									{brand?.platforms?.map((plf, k) => (
										<InputWithLabel
											key={k}
											value={plf.platformUrl}
											onChange={(e) =>
												rest.handleChange(
													"platformUrl",
													e.target.value,
													plf.id,
													brand.id,
													rest.rowIndex
												)
											}
											validationMessage={
												rest.validations?.[rest.rowIndex]?.platforms?.[brand.id]?.[plf.id]
													?.platformUrl || ""
											}
										/>
									))}
								</div>
							))
						) : (
							<div className="brand-platforms">
								{record?.platforms?.map((plf, j) =>
									plf.platformName !== "Meraki" ? (
										<InputWithLabel
											key={j}
											value={plf.platformUrl}
											onChange={(e) =>
												rest.handleChange(
													"platformUrl",
													e.target.value,
													plf.id,
													null,
													rest.rowIndex
												)
											}
											validationMessage={
												rest.validations?.[rest.rowIndex]?.platforms?.[plf.id]?.platformUrl ||
												""
											}
										/>
									) : null
								)}
							</div>
						)}
					</div>
				);
			}
			return (
				<div className="table-cell platform-url" key={i}>
					{record.platforms && record.platforms?.length > 0 ? (
						record.platforms?.map((plf, j) => (
							<InputWithLabel
								key={j}
								value={plf.platformUrl}
								onChange={(e) => rest.handleChange("platformUrl", e.target.value, plf.id, record.id)}
								validationMessage={
									rest.validations?.platforms?.[record.id]?.[plf.id]?.platformUrl || ""
								}
							/>
						))
					) : record.platformName !== "Meraki" ? (
						<InputWithLabel
							value={record.platformUrl}
							onChange={(e) => rest.handleChange("platformUrl", e.target.value, record.id)}
							validationMessage={rest.validations?.platforms?.[record.id]?.platformUrl || ""}
						/>
					) : null}
				</div>
			);
		}
	}
];

const PlatformAssociation = ({
	formData = {},
	handleFormData,
	isMulti = false,
	bizPlatforms,
	validations = {},
	setValidations,
	isMultibrandEnabled = false,
	isMerakiEnabled = false
}) => {
	const [platformUpdates, setPlatformUpdates] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [merakiDetails, setMerakiDetails] = useState(MERAKI_DETAILS_INIT_STATE);

	useEffect(() => {
		const includeUrbanpiper = !isMultibrandEnabled && isMerakiEnabled;
		fetchBizPlatforms(includeUrbanpiper, includeUrbanpiper);
		if (formData?.platforms && formData?.platforms?.length > 0) {
			const selectedPlatforms = {
				platforms: {}
			};
			formData.platforms.forEach((platform) => {
				selectedPlatforms.platforms[platform.id] = true;
			});
			setPlatformUpdates(selectedPlatforms);
		}
	}, []);

	const handleChange = useCallback(
		(field, value, platformId, brandId, index) => {
			if (isMulti) {
				const updatedMultiLocationDetails = formData.multiLocationDetails.map((loc, i) => {
					const location = { ...loc };
					if (i === index) {
						if (brandId) {
							const updatedBrands = location.brands.map((brnd) => {
								const brand = { ...brnd };
								if (parseInt(brandId) === parseInt(brand.id)) {
									const platforms = brand.platforms.map((plf) => {
										const platform = { ...plf };
										if (parseInt(platformId) === parseInt(platform.id)) {
											platform[field] = value;
											// reset validations
											setValidations({
												...validations,
												[i]: {
													...(validations?.[i] ?? {}),
													platforms: {
														...(validations?.[i]?.platforms ?? {}),
														[brandId]: {
															...(validations?.[i]?.platforms?.[brandId] ?? {}),
															[platformId]: {
																...(validations?.[i]?.platforms?.[brandId]?.[
																	platformId
																] ?? {}),
																[field]: ""
															}
														}
													}
												}
											});
										}
										return platform;
									});
									brand.platforms = platforms;
								}
								return brand;
							});
							location.brands = updatedBrands;
						} else {
							const platforms = location.platforms.map((plf) => {
								const platform = { ...plf };
								if (parseInt(platformId) === parseInt(platform.id)) {
									platform[field] = value;
									// reset validations
									setValidations({
										...validations,
										[i]: {
											...(validations?.[i] ?? {}),
											platforms: {
												...(validations?.[i]?.platforms ?? {}),
												[platformId]: {
													...(validations?.[i]?.platforms?.[platformId] ?? {}),
													[field]: ""
												}
											}
										}
									});
								}
								return platform;
							});
							location.platforms = platforms;
						}
					}
					return location;
				});
				handleFormData({ multiLocationDetails: updatedMultiLocationDetails });
			} else {
				if (brandId) {
					const brands = formData.brands.map((brnd) => {
						const brand = { ...brnd };
						if (parseInt(brandId) === parseInt(brand.id)) {
							const platforms = brand.platforms.map((plf) => {
								const platform = { ...plf };
								if (parseInt(platformId) === parseInt(platform.id)) {
									platform[field] = value;
									// reset validations
									setValidations({
										...validations,
										platforms: {
											...(validations?.platforms ?? {}),
											[brandId]: {
												...(validations?.platforms?.[brandId] ?? {}),
												[platformId]: {
													...(validations?.platforms?.[brandId]?.[platformId] ?? {}),
													[field]: ""
												}
											}
										}
									});
								}
								return platform;
							});
							brand.platforms = platforms;
						}
						return brand;
					});
					handleFormData({ brands: brands });
				} else if (platformId) {
					const platforms = formData.platforms.map((plf) => {
						if (parseInt(platformId) === parseInt(plf.id)) {
							plf[field] = value;
							// reset validations
							setValidations({
								...validations,
								platforms: {
									...(validations?.platforms ?? {}),
									[platformId]: {
										...(validations?.platforms?.[platformId] ?? {}),
										[field]: ""
									}
								}
							});
						}
						return plf;
					});
					handleFormData({ platforms: platforms });
				}
			}
		},
		[formData, handleFormData, isMulti, validations, setValidations]
	);

	const handleCheck = useCallback(
		(state, field, option, props) => {
			// update local platforms state
			setPlatformUpdates({
				...platformUpdates,
				[field]: {
					...platformUpdates[field],
					[option[props.valueKey]]: state
				}
			});
			setIsCheckedAll({
				...isCheckedAll,
				[field]: false
			});
			// update platforms in formData
			let platforms = formData.platforms ? [...formData.platforms] : [];
			if (state) {
				platforms.push({ ...option, platformId: "", platformUrl: "" });
			} else {
				platforms = [...platforms].filter((plf) => plf[props.valueKey] !== option[props.valueKey]);
			}
			if (isMulti) {
				if (isMultibrandEnabled) {
					const updatedMultiLocationDetails = formData.multiLocationDetails.map((loc) => {
						const location = { ...loc };
						const updatedBrands = location.brands.map((brnd) => {
							const brand = { ...brnd };
							let updatedPlatforms = brand.platforms ? [...brand.platforms] : [];
							if (state) {
								updatedPlatforms.push({ ...option, platformId: "", platformUrl: "" });
							} else {
								updatedPlatforms = updatedPlatforms.filter(
									(plf) => plf[props.valueKey] !== option[props.valueKey]
								);
							}
							brand.platforms = updatedPlatforms;
							return brand;
						});
						location.brands = updatedBrands;
						return location;
					});
					handleFormData({
						platforms: platforms,
						multiLocationDetails: updatedMultiLocationDetails
					});
					// remove platform in validations
					if (!state) {
						let updatedValidations = { ...validations };
						Object.keys(updatedValidations).forEach((locId) => {
							if (updatedValidations?.[locId]?.platforms) {
								Object.keys(updatedValidations[locId].platforms).forEach((brandId) => {
									delete updatedValidations[locId].platforms[brandId][option[props.valueKey]];
								});
							}
						});
						setValidations(updatedValidations);
					}
				} else {
					const updatedMultiLocationDetails = formData.multiLocationDetails.map((loc) => {
						const location = { ...loc };
						let updatedPlatforms = location.platforms ? [...location.platforms] : [];
						if (state) {
							updatedPlatforms.push({ ...option, platformId: "", platformUrl: "" });
						} else {
							updatedPlatforms = updatedPlatforms.filter(
								(plf) => plf[props.valueKey] !== option[props.valueKey]
							);
						}
						location.platforms = updatedPlatforms;
						return location;
					});
					handleFormData({
						platforms: platforms,
						multiLocationDetails: updatedMultiLocationDetails
					});
					// remove platform in validations
					if (!state) {
						let updatedValidations = { ...validations };
						Object.keys(updatedValidations).forEach((locId) => {
							if (updatedValidations?.[locId]?.platforms) {
								delete updatedValidations[locId].platforms[option[props.valueKey]];
							}
						});
						setValidations(updatedValidations);
					}
				}
			} else {
				if (isMultibrandEnabled) {
					// update platforms in brands in formData
					const updatedBrands = formData.brands.map((brnd) => {
						const brand = { ...brnd };
						let updatedPlatforms = brand.platforms ? [...brand.platforms] : [];
						if (state) {
							updatedPlatforms.push({ ...option, platformId: "", platformUrl: "" });
						} else {
							updatedPlatforms = updatedPlatforms.filter(
								(plf) => plf[props.valueKey] !== option[props.valueKey]
							);
						}
						brand.platforms = updatedPlatforms;
						return brand;
					});
					handleFormData({
						platforms: platforms,
						brands: updatedBrands
					});
					// remove platform in validations
					if (!state) {
						let updatedValidations = { ...validations };
						if (updatedValidations.platforms) {
							Object.keys(updatedValidations.platforms).forEach((brandId) => {
								delete updatedValidations.platforms[brandId][option[props.valueKey]];
							});
						}
						setValidations(updatedValidations);
					}
				} else {
					handleFormData({
						platforms: platforms
					});
					// remove platform in validations
					if (!state) {
						let updatedValidations = { ...validations };
						if (updatedValidations.platforms) {
							delete updatedValidations.platforms[option[props.valueKey]];
						}
						setValidations(updatedValidations);
					}
				}
			}
		},
		[
			formData,
			handleFormData,
			isMulti,
			platformUpdates,
			isCheckedAll,
			validations,
			setValidations,
			isMultibrandEnabled
		]
	);

	const handleCheckAll = useCallback(
		(state, field, options, props) => {
			// update local platforms state
			const filterOptions = {};
			options = options
				?.filter((opt) => (state ? !platformUpdates?.[field]?.[opt[props.valueKey]] : opt))
				?.map((opt) => {
					filterOptions[opt[props.valueKey]] = state;
					opt.platformId = "";
					opt.platformUrl = "";
					return opt;
				});
			setPlatformUpdates({
				...platformUpdates,
				[field]: {
					...platformUpdates[field],
					...filterOptions
				}
			});
			setIsCheckedAll({
				...isCheckedAll,
				[field]: state
			});
			// update platforms in formData
			let platforms = formData.platforms ? [...formData.platforms] : [];
			if (state) {
				platforms = [...platforms, ...options];
			} else {
				platforms = [];
			}
			if (isMulti) {
				if (isMultibrandEnabled) {
					const updatedMultiLocationDetails = formData.multiLocationDetails.map((loc) => {
						const location = { ...loc };
						const updatedBrands = location.brands.map((brnd) => {
							const brand = { ...brnd };
							let updatedPlatforms = brand.platforms ? [...brand.platforms] : [];
							if (state) {
								updatedPlatforms = [...updatedPlatforms, ...options];
							} else {
								updatedPlatforms = [];
							}
							brand.platforms = updatedPlatforms;
							return brand;
						});
						location.brands = updatedBrands;
						return location;
					});
					handleFormData({
						platforms: platforms,
						multiLocationDetails: updatedMultiLocationDetails
					});
					// remove platform in validations
					if (!state) {
						let updatedValidations = { ...validations };
						Object.keys(updatedValidations).forEach((locId) => {
							if (updatedValidations?.[locId]?.platforms) {
								Object.keys(updatedValidations[locId].platforms).forEach((brandId) => {
									updatedValidations[locId].platforms[brandId] = {};
								});
							}
						});
						setValidations(updatedValidations);
					}
				} else {
					const updatedMultiLocationDetails = formData.multiLocationDetails.map((loc) => {
						const location = { ...loc };
						let updatedPlatforms = location.platforms ? [...location.platforms] : [];
						if (state) {
							updatedPlatforms = [...updatedPlatforms, ...options];
						} else {
							updatedPlatforms = [];
						}
						location.platforms = updatedPlatforms;
						return location;
					});
					handleFormData({
						platforms: platforms,
						multiLocationDetails: updatedMultiLocationDetails
					});
					// remove platform in validations
					if (!state) {
						let updatedValidations = { ...validations };
						Object.keys(updatedValidations).forEach((locId) => {
							if (updatedValidations?.[locId]?.platforms) {
								updatedValidations[locId].platforms = {};
							}
						});
						setValidations(updatedValidations);
					}
				}
			} else {
				// update platforms in brands in formData
				const updatedBrands = formData.brands.map((brnd) => {
					const brand = { ...brnd };
					let updatedPlatforms = brand.platforms ? [...brand.platforms] : [];
					if (state) {
						updatedPlatforms = [...updatedPlatforms, ...options];
					} else {
						updatedPlatforms = [];
					}
					brand.platforms = updatedPlatforms;
					return brand;
				});
				handleFormData({
					platforms: platforms,
					brands: updatedBrands
				});
				// remove platform in validations
				if (!state) {
					let updatedValidations = { ...validations };
					if (updatedValidations.platforms) {
						Object.keys(updatedValidations.platforms).forEach((brandId) => {
							updatedValidations.platforms[brandId] = {};
						});
					}
					setValidations(updatedValidations);
				}
			}
		},
		[
			formData,
			handleFormData,
			isMulti,
			platformUpdates,
			isCheckedAll,
			validations,
			setValidations,
			isMultibrandEnabled
		]
	);

	const handleOpenMerakiDetails = (index) => {
		setMerakiDetails({
			isOpen: true,
			index
		});
	};

	const handleResetMerakiDetails = (index) => {
		if (isMulti) {
			const updatedMultiLocationDetails = formData.multiLocationDetails.map((loc, i) => {
				let location = { ...loc };
				if (i === index) {
					location = {
						...location,
						locLatitude: null,
						locLongitude: null,
						orderDeliveryRadius: null,
						polygons: [],
						isMerakiDetailsSet: false
					};
				}
				return location;
			});
			handleFormData({ multiLocationDetails: updatedMultiLocationDetails });
		} else {
			handleFormData({
				locLatitude: null,
				locLongitude: null,
				orderDeliveryRadius: null,
				polygons: [],
				isMerakiDetailsSet: false
			});
		}
	};

	const handleCloseMerakiDetails = () => {
		setMerakiDetails(MERAKI_DETAILS_INIT_STATE);
	};

	const extractInitials = (name) => {
		if (!name) {
			return "";
		}
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(1);
		}
		return initials.toUpperCase();
	};

	const handleDropdownLabel = (currValue, handleCheck, field, props) => {
		return (
			<React.Fragment>
				{currValue?.map?.((platform, i) => (
					<div className="platform-tag" key={i}>
						<div className="platform">{platform?.platformName}</div>
						<ButtonIcon
							icon="cross"
							clickHandler={(e) => {
								handleCheck(false, field, platform, props);
								e.stopPropagation();
							}}
						/>
					</div>
				))}
			</React.Fragment>
		);
	};

	return (
		<div className="platform-association">
			<div className="form-content">
				<div className="section-title">Associate platforms</div>
				<SelectFilterCustom
					title="Select Platforms"
					options={bizPlatforms.items}
					isLoading={bizPlatforms.isLoading}
					field="platforms"
					currValue={formData.platforms || []}
					setFilter={handleChange}
					labelKey={"platformName"}
					valueKey={"id"}
					customLabel={true}
					renderLabel={handleDropdownLabel}
					multi={true}
					showCheckBox={true}
					showSelectAllOption={true}
					optionUpdates={platformUpdates}
					handleCheck={handleCheck}
					isCheckedAll={isCheckedAll}
					handleCheckAll={handleCheckAll}
					placeholder="Select platforms"
				/>
			</div>
			{formData.platforms?.length > 0 && (
				<CustomTable
					columns={columns}
					data={
						isMulti
							? formData.multiLocationDetails
							: formData?.brands?.length > 0
							? formData?.brands
							: formData?.platforms || []
					}
					formData={formData}
					extractInitials={extractInitials}
					handleOpenMerakiDetails={handleOpenMerakiDetails}
					handleResetMerakiDetails={handleResetMerakiDetails}
					handleChange={handleChange}
					isMulti={isMulti}
					validations={validations}
					classes={
						"brands-platforms-list-table-container" +
						(!isMultibrandEnabled ? " non-multi-brand" : "") +
						(isMulti ? " multi" : "")
					}
					hideColumns={
						!isMulti
							? isMultibrandEnabled
								? ["name"]
								: ["name", "brands"]
							: isMultibrandEnabled
							? []
							: ["brands"]
					}
				/>
			)}
			{!isMultibrandEnabled && (
				<MerakiDetails
					isOpen={merakiDetails.isOpen}
					close={handleCloseMerakiDetails}
					index={merakiDetails.index}
					formData={formData}
					handleFormData={handleFormData}
					isMulti={isMulti}
				/>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	bizPlatforms: store.configItems.bizPlatforms,
	isMerakiEnabled: store.login.loggedInbizDetail?.modulesEnabled?.includes("Meraki") || false
});
export default connect(mapStateToProps)(PlatformAssociation);
