import React, { Component } from "react";

// components
import { Button } from "../components/_commons/Button";

// client
import history from "../history";

export default class Error extends Component {
	handleGoToOrders = () => {
		history.push("/orders");
	};

	render() {
		return (
			<div className="section-container-common">
				<div className="error-item">
					<div className="error-img-container">
						<img className="error-img" src="assets/error/error-404.png" alt="error-404" />
					</div>
					<div className="error-msg-container">
						<div className="title">Page not found ...</div>
						<div className="error-msg">We are unable to find the page you're looking for</div>
						<div className="orders-btn">
							<Button clickHandler={() => this.handleGoToOrders()}>Go to Orders</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
