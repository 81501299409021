import { ActionTypes } from "../actions/_types";

// utils
import { lS } from "../atlas-utils";

const INITIAL_STATE = {
	coupons: {
		isLoading: false,
		items: []
	},
	items: {
		isLoading: false,
		items: []
	},
	stores: {
		isLoading: false,
		items: []
	},
	brands: {
		isLoading: false,
		items: [],
		selectedBrand: lS.get("brand") ? lS.get("brand") : null
	},
	menus: {
		isLoading: false,
		items: []
	},
	tags: {
		isLoading: false,
		items: []
	},
	fulfillmentModes: {
		isLoading: false,
		items: []
	},
	timingGroups: {
		isLoading: false,
		items: []
	},
	cities: {
		isLoading: false,
		items: []
	},
	itemCategories: {
		isLoading: false,
		items: []
	},
	parentCategories: {
		isLoading: false,
		items: []
	},
	parentCategoryIds: {
		ids: {}
	},
	paymentModes: {
		isLoading: false,
		items: []
	},
	bizPlatforms: {
		isLoading: false,
		items: []
	},
	itemGroups: {
		isLoading: false,
		items: []
	},
	locationGroups: {
		isLoading: false,
		items: []
	},
	charges: {
		isLoading: false,
		items: []
	},
	modifierGroups: {
		isLoading: false,
		items: []
	},
	modifiers: {
		isLoading: false,
		items: []
	},
	listGroups: {
		isLoading: false,
		items: []
	},
	dimensions: {
		height: 0,
		width: 0
	},
	translation: {
		isLoading: false,
		status: false,
		field: ""
	},
	associatedBrandLocations: {
		isLoading: false,
		items: []
	}
};

export const configItems = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "UPDATE_DIMENSIONS":
			return {
				...state,
				dimensions: action.payload
			};
		case "GET_COUPONS_LIST_REQUEST":
			return {
				...state,
				coupons: {
					...state.coupons,
					isLoading: true
				}
			};
		case "GET_COUPONS_LIST_SUCCESS":
			return {
				...state,
				coupons: {
					...state.coupons,
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_COUPONS_LIST_FAILURE":
			return {
				...state,
				coupons: {
					...state.coupons,
					isLoading: false
				}
			};
		case "GET_ITEMS_LIST_REQUEST":
			return {
				...state,
				items: {
					...state.items,
					isLoading: true
				}
			};
		case "GET_ITEMS_LIST_SUCCESS":
			return {
				...state,
				items: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_ITEMS_LIST_FAILURE":
			return {
				...state,
				items: {
					...state.items,
					isLoading: false
				}
			};
		case "GET_STORES_LIST_REQUEST":
			return {
				...state,
				stores: {
					...state.stores,
					isLoading: true
				}
			};
		case "GET_STORES_LIST_SUCCESS":
			return {
				...state,
				stores: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_STORES_LIST_FAILURE":
			return {
				...state,
				stores: {
					...state.stores,
					isLoading: false
				}
			};
		case "GET_BRAND_LOCATIONS_LIST_REQUEST":
			return {
				...state,
				brands: {
					...state.brands,
					isLoading: true
				}
			};
		case "GET_BRAND_LOCATIONS_LIST_SUCCESS":
			return {
				...state,
				brands: {
					...state.brands,
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_BRAND_LOCATIONS_LIST_FAILURE":
			return {
				...state,
				brands: {
					...state.brands,
					isLoading: false
				}
			};
		case "GET_CI_MENUS_LIST_REQUEST":
			return {
				...state,
				menus: {
					...state.menus,
					isLoading: true
				}
			};
		case "GET_CI_MENUS_LIST_SUCCESS":
			return {
				...state,
				menus: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CI_MENUS_LIST_FAILURE":
			return {
				...state,
				menus: {
					...state.menus,
					isLoading: false
				}
			};
		case "GET_TAGS_LIST_REQUEST":
			return {
				...state,
				tags: {
					...state.tags,
					isLoading: true
				}
			};
		case "GET_TAGS_LIST_SUCCESS":
			return {
				...state,
				tags: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_TAGS_LIST_FAILURE":
			return {
				...state,
				tags: {
					...state.tags,
					isLoading: false
				}
			};
		case "GET_FULFILLMENT_MODES_LIST_REQUEST":
			return {
				...state,
				fulfillmentModes: {
					...state.fulfillmentModes,
					isLoading: true
				}
			};
		case "GET_FULFILLMENT_MODES_LIST_SUCCESS":
			return {
				...state,
				fulfillmentModes: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_FULFILLMENT_MODES_LIST_FAILURE":
			return {
				...state,
				fulfillmentModes: {
					...state.fulfillmentModes,
					isLoading: false
				}
			};
		case "GET_TIMING_LIST_REQUEST":
			return {
				...state,
				timingGroups: {
					...state.timingGroups,
					isLoading: true
				}
			};
		case "GET_TIMING_LIST_SUCCESS":
			return {
				...state,
				timingGroups: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_TIMING_LIST_FAILURE":
			return {
				...state,
				timingGroups: {
					...state.timingGroups,
					isLoading: false
				}
			};
		case "GET_CITIES_LIST_REQUEST":
			return {
				...state,
				cities: {
					...state.cities,
					isLoading: true
				}
			};
		case "GET_CITIES_LIST_SUCCESS":
			return {
				...state,
				cities: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CITIES_LIST_FAILURE":
			return {
				...state,
				cities: {
					...state.cities,
					isLoading: false
				}
			};
		case "GET_ITEM_CATEGORIES_LIST_REQUEST":
			return {
				...state,
				itemCategories: {
					...state.itemCategories,
					isLoading: true
				}
			};
		case "GET_ITEM_CATEGORIES_LIST_SUCCESS":
			return {
				...state,
				itemCategories: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_ITEM_CATEGORIES_LIST_FAILURE":
			return {
				...state,
				itemCategories: {
					...state.itemCategories,
					isLoading: false
				}
			};
		case "GET_PARENT_CATEGORIES_LIST_REQUEST":
			return {
				...state,
				parentCategories: {
					...state.parentCategories,
					isLoading: true
				}
			};
		case "GET_PARENT_CATEGORIES_LIST_SUCCESS":
			return {
				...state,
				parentCategories: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_PARENT_CATEGORIES_LIST_FAILURE":
			return {
				...state,
				parentCategories: {
					...state.parentCategories,
					isLoading: false
				}
			};
		case "RESET_PARENT_CATEGORIES_LIST":
			return {
				...state,
				parentCategories: {
					isLoading: false,
					items: []
				}
			};
		case "GET_PARENT_CATEGORY_IDS_REQUEST":
			return {
				...state,
				parentCategoryIds: {
					...state.parentCategoryIds
				}
			};
		case "GET_PARENT_CATEGORY_IDS_SUCCESS":
			let extractedIds = {};
			Array.isArray(action.payload) &&
				action.payload.forEach((category) => {
					extractedIds = { ...extractedIds, [category.id]: true };
				});
			return {
				...state,
				parentCategoryIds: {
					ids: extractedIds
				}
			};
		case "GET_PARENT_CATEGORY_IDS_FAILURE":
			return {
				...state
			};
		case "GET_PAYMENT_MODES_LIST_REQUEST":
			return {
				...state,
				paymentModes: {
					...state.paymentModes,
					isLoading: true
				}
			};
		case "GET_PAYMENT_MODES_LIST_SUCCESS":
			return {
				...state,
				paymentModes: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_PAYMENT_MODES_LIST_FAILURE":
			return {
				...state,
				paymentModes: {
					...state.paymentModes,
					isLoading: false
				}
			};
		case "GET_BIZ_PLATFORMS_LIST_REQUEST":
			return {
				...state,
				bizPlatforms: {
					...state.bizPlatforms,
					isLoading: true
				}
			};
		case "GET_BIZ_PLATFORMS_LIST_SUCCESS":
			return {
				...state,
				bizPlatforms: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_BIZ_PLATFORMS_LIST_FAILURE":
			return {
				...state,
				bizPlatforms: {
					...state.bizPlatforms,
					isLoading: false
				}
			};
		case "GET_CI_ITEM_GROUPS_LIST_REQUEST":
			return {
				...state,
				itemGroups: {
					...state.itemGroups,
					isLoading: true
				}
			};
		case "GET_CI_ITEM_GROUPS_LIST_SUCCESS":
			return {
				...state,
				itemGroups: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CI_ITEM_GROUPS_LIST_FAILURE":
			return {
				...state,
				itemGroups: {
					...state.itemGroups,
					isLoading: false
				}
			};
		case "GET_CI_LOCATION_GROUPS_LIST_REQUEST":
			return {
				...state,
				locationGroups: {
					...state.locationGroups,
					isLoading: true
				}
			};
		case "GET_CI_LOCATION_GROUPS_LIST_SUCCESS":
			return {
				...state,
				locationGroups: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CI_LOCATION_GROUPS_LIST_FAILURE":
			return {
				...state,
				locationGroups: {
					...state.locationGroups,
					isLoading: false
				}
			};
		case "GET_CI_CHARGES_LIST_REQUEST":
			return {
				...state,
				charges: {
					...state.charges,
					isLoading: true
				}
			};
		case "GET_CI_CHARGES_LIST_SUCCESS":
			return {
				...state,
				charges: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CI_CHARGES_LIST_FAILURE":
			return {
				...state,
				charges: {
					...state.charges,
					isLoading: false
				}
			};
		case "GET_CI_MODIFIER_GROUPS_LIST_REQUEST":
			return {
				...state,
				modifierGroups: {
					...state.modifierGroups,
					isLoading: true
				}
			};
		case "GET_CI_MODIFIER_GROUPS_LIST_SUCCESS":
			return {
				...state,
				modifierGroups: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CI_MODIFIER_GROUPS_LIST_FAILURE":
			return {
				...state,
				modifierGroups: {
					...state.modifierGroups,
					isLoading: false
				}
			};
		case "GET_CI_MODIFIERS_LIST_REQUEST":
			return {
				...state,
				modifiers: {
					...state.modifiers,
					isLoading: true
				}
			};
		case "GET_CI_MODIFIERS_LIST_SUCCESS":
			return {
				...state,
				modifiers: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CI_MODIFIERS_LIST_FAILURE":
			return {
				...state,
				modifiers: {
					...state.modifiers,
					isLoading: false
				}
			};
		case "GET_CI_LIST_GROUPS_REQUEST":
			return {
				...state,
				listGroups: {
					...state.listGroups,
					isLoading: true
				}
			};
		case "GET_CI_LIST_GROUPS_SUCCESS":
			return {
				...state,
				listGroups: {
					isLoading: false,
					items: action.payload
				}
			};
		case "GET_CI_LIST_GROUPS_FAILURE":
			return {
				...state,
				listGroups: {
					...state.listGroups,
					isLoading: false
				}
			};
		case "TRANSLATION_REQUEST":
			return {
				...state,
				translation: {
					isLoading: true,
					status: false,
					field: action.payload
				}
			};
		case "GET_TRANSLATION_SUCCESS":
			return {
				...state,
				translation: {
					isLoading: false,
					status: false,
					field: ""
				}
			};
		case "UPDATE_TRANSLATION_SUCCESS":
			return {
				...state,
				translation: {
					isLoading: false,
					status: true,
					field: action.payload
				}
			};
		case "TRANSLATION_FAILURE":
			return {
				...state,
				translation: {
					isLoading: false,
					status: false,
					field: ""
				}
			};
		case "RESET_TRANSLATION":
			return {
				...state,
				translation: {
					isLoading: false,
					status: false,
					field: ""
				}
			};

		case "GET_ASSOCIATED_BRAND_LOCATION_REQUEST":
			return {
				...state,
				associatedBrandLocations: {
					isLoading: true,
					items: []
				}
			};

		case "GET_ASSOCIATED_BRAND_LOCATION_SUCCESS":
			return {
				...state,
				associatedBrandLocations: {
					isLoading: false,
					items: action.payload
				}
			};

		case "GET_ASSOCIATED_BRAND_LOCATION_FAILURE":
			return {
				...state,
				associatedBrandLocations: {
					isLoading: false,
					items: []
				}
			};

		case ActionTypes.UPDATE_SELECTED_BRAND:
			lS.set("brand", action.payload);
			return {
				...state,
				brands: {
					...state.brands,
					selectedBrand: action.payload
				}
			};
		default:
			return state;
	}
};
