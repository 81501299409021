import gql from "graphql-tag";

export const GET_ACTION_ITEMS = gql`
	query getActionItems(
		$couponId: Int!
		$pActionId: Int!
		$paramType: ItemAssociationActionParamType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		couponActionItemAssociations(
			couponId: $couponId
			pActionId: $pActionId
			paramType: $paramType
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			objects {
				id
				itemTitle
				itemPrice
				crmTitle
				foodType
				isAssociated
				category
				tags
			}
		}
	}
`;

export const UPDATE_ACTION_ITEMS = gql`
	mutation updateActionItems(
		$couponId: Int!
		$pActionId: Int!
		$paramType: ItemAssociationActionParamType!
		$itemsToAssociate: [Int]
		$itemsToDisassociate: [Int]
	) {
		updateCouponActionItems(
			input: {
				couponId: $couponId
				pActionId: $pActionId
				paramType: $paramType
				itemsToAssociate: $itemsToAssociate
				itemsToDisassociate: $itemsToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
