// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_API_ROLES_LIST } from "../graphql/apiRoles";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchAPIRolesList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().rolesListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_API_ROLES_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_API_ROLES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_API_ROLES_LIST_SUCCESS,
			payload: { ...resp.data.groups }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_API_ROLES_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
