import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { TaxesList } from "./TaxesList";
import TaxCreate from "./TaxCreate";
import TaxEdit from "./TaxEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/TaxesList/Header";

const TaxesContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="taxes-container">
			{restrictView ? (
				<Route
					path="/taxes"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/taxes" component={TaxesList} />
					<Route
						exact
						path="/taxes/new"
						render={() => (hasAccess ? <TaxCreate hasAccess={hasAccess} /> : <Redirect to="/taxes" />)}
					/>
					<Route exact path="/taxes/edit/:id" component={TaxEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default TaxesContainer;
