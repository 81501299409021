import React, { useEffect, useRef, useState } from "react";

// third party
import { useSpring, animated as a } from "react-spring";

// components
import { Button } from "../_commons/Button";

// utils
import { validatePlatformUrl, decideMethodAndValidatePlatformId } from "../../atlas-utils";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { capitaliseText, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

export const LocationLinks = ({
	platformLocationLinks,
	handleSelectedTabUpdate,
	handleFormData,
	platforms,
	locationAddType,
	multiLocationDetails,
	locationDetailsArray,
	adjustStringLength,
	// validators,
	businessBasedDisabledFields
}) => {
	const initialFocusRef = useRef();
	const initValidationState = {
		...platforms
	};
	const [validation, setValidation] = useState({
		state: false,
		urlsValidation: { ...initValidationState },
		idsValidation: { ...initValidationState }
	});
	const [plaformwiseValidationError, setPlatformwiseValidationError] = useState({
		state: false,
		platformwiseUrlsValidationDetails: [...locationDetailsArray.map(() => ({ ...initValidationState }))] || [],
		platformwiseIdsValidationDetails: [...locationDetailsArray.map(() => ({ ...initValidationState }))] || []
	});
	const [selectedTab, setSelectedTab] = useState(0);
	const [animate, setAnimate] = useState(false);
	const platformWiseFlow = locationAddType === "multi";
	const scrollRef = useRef(0);
	const [scrollClass, setScrollClass] = useState("");
	useEffect(() => initialFocusRef.current?.focus(), []);

	const PLATFORMWISE_TABS = Object.keys(platforms).filter((platform) => platform !== "meraki");
	const handleChange = (platform, e, type) => {
		handleFormData("platformLocationLinks", {
			...platformLocationLinks,
			[platform]: { ...platformLocationLinks[platform], [type]: e.target.value }
		});
	};

	const handlePrevClick = () => {
		if (platformWiseFlow) {
			if (selectedTab === 0) {
				handleSelectedTabUpdate(5);
			} else {
				setSelectedTab((current) => current - 1);
			}
		} else {
			handleSelectedTabUpdate(5);
		}
	};

	const handleNextClick = () => {
		if (platformWiseFlow) {
			let currentLocationDetailsArray = [...locationDetailsArray];
			let gotoNext = true;
			let urlCorrupt = false;
			let idCorrupt = false;
			let urlsCorrectness = [...plaformwiseValidationError.platformwiseUrlsValidationDetails];
			let idsCorrectness = [...plaformwiseValidationError.platformwiseIdsValidationDetails];
			for (let i = 0; i < currentLocationDetailsArray.length; i++) {
				if (
					currentLocationDetailsArray[i].platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]?.id.length ===
						0 &&
					!businessBasedDisabledFields.platformId[PLATFORMWISE_TABS[selectedTab]]
				) {
					gotoNext = false;
				}
				if (
					currentLocationDetailsArray[i].platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]?.url.length ===
						0 &&
					!businessBasedDisabledFields.platformUrl[PLATFORMWISE_TABS[selectedTab]]
				) {
					gotoNext = false;
				}
				if (
					currentLocationDetailsArray[i].platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]?.url.length > 0
				) {
					urlsCorrectness[i][PLATFORMWISE_TABS[selectedTab]] = validatePlatformUrl(
						PLATFORMWISE_TABS[selectedTab],
						currentLocationDetailsArray[i]?.platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]?.url
					);
					if (!urlsCorrectness[i][PLATFORMWISE_TABS[selectedTab]]) {
						urlCorrupt = true;
					}
				}
				if (
					currentLocationDetailsArray[i].platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]?.id.length > 0
				) {
					idsCorrectness[i][PLATFORMWISE_TABS[selectedTab]] = decideMethodAndValidatePlatformId(
						PLATFORMWISE_TABS[selectedTab],
						currentLocationDetailsArray[i].platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]?.id
					);
					if (!idsCorrectness[i][PLATFORMWISE_TABS[selectedTab]]) {
						idCorrupt = true;
					}
				}
			}
			if (gotoNext && !urlCorrupt && !idCorrupt) {
				if (selectedTab === PLATFORMWISE_TABS.length - 1) {
					handleSelectedTabUpdate(7);
					trackEvent("wiz_loc_aggregator_platform_links_entry", {
						locationCount: locationDetailsArray.length,
						platformCount: PLATFORMWISE_TABS.length
					});
				} else {
					setSelectedTab((current) => current + 1);
					setPlatformwiseValidationError({ ...plaformwiseValidationError, state: false });
				}
			} else {
				setPlatformwiseValidationError({
					state: !gotoNext,
					platformwiseUrlsValidationDetails: [...urlsCorrectness],
					platformwiseIdsValidationDetails: [...idsCorrectness]
				});
			}
		} else {
			const platformKeyArray = Object.keys(platformLocationLinks).filter((platform) => platforms[platform]);
			let gotoNext = true;
			let urlCorrupt = false;
			let idCorrupt = false;

			let urlsCorrectness = {};
			let idsCorrectness = {};
			for (let i = 0; i < platformKeyArray.length; i++) {
				if (
					platformLocationLinks[platformKeyArray[i]]?.id.length === 0 &&
					!businessBasedDisabledFields.platformId[platformKeyArray[i]]
				) {
					gotoNext = false;
				}
				if (
					platformLocationLinks[platformKeyArray[i]]?.url.length === 0 &&
					!businessBasedDisabledFields.platformUrl[platformKeyArray[i]]
				) {
					gotoNext = false;
				}
				if (platformLocationLinks[platformKeyArray[i]].url.length > 0) {
					urlsCorrectness[platformKeyArray[i]] = validatePlatformUrl(
						platformKeyArray[i],
						platformLocationLinks[platformKeyArray[i]].url
					);
					if (!urlsCorrectness[platformKeyArray[i]]) {
						urlCorrupt = true;
					}
				}
				if (platformLocationLinks[platformKeyArray[i]].id.length > 0) {
					idsCorrectness[platformKeyArray[i]] = decideMethodAndValidatePlatformId(
						platformKeyArray[i],
						platformLocationLinks[platformKeyArray[i]].id
					);
					if (!idsCorrectness[platformKeyArray[i]]) {
						idCorrupt = true;
					}
				}
			}
			if (gotoNext && !urlCorrupt && !idCorrupt) {
				handleSelectedTabUpdate(7);
				trackEvent("wiz_loc_aggregator_platform_links_entry", {});
			} else {
				setValidation({
					...validation,
					state: !gotoNext,
					urlsValidation: { ...initValidationState, ...urlsCorrectness },
					idsValidation: { ...initValidationState, ...idsCorrectness }
				});
			}
		}
	};

	const clearText = (platform, type) => {
		handleFormData("platformLocationLinks", {
			...platformLocationLinks,
			[platform]: { ...platformLocationLinks[platform], [type]: "" }
		});
		setValidation({ ...validation, urlsValidation: { ...validation.urlsValidation, [platform]: true } });
	};

	const removeErrorNotifier = (platform, type) => {
		if (type === "url") {
			setValidation({ ...validation, urlsValidation: { ...validation.urlsValidation, [platform]: true } });
		} else if (type === "id") {
			setValidation({ ...validation, idsValidation: { ...validation.idsValidation, [platform]: true } });
		}
	};

	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const listenToScroll = (e) => {
		if (scrollRef.current?.scrollTop === 0) {
			setScrollClass("");
		} else {
			setScrollClass("show-border-bottom");
		}
	};

	const handleMultiLocationValueUpdates = (val, type, index) => {
		let updatedArray = JSON.parse(JSON.stringify(multiLocationDetails));
		updatedArray[index].platformLocationLinks = {
			...updatedArray[index].platformLocationLinks,
			[PLATFORMWISE_TABS[selectedTab]]: {
				...updatedArray[index].platformLocationLinks[PLATFORMWISE_TABS[selectedTab]],
				[type]: val
			}
		};
		handleFormData("multiLocationDetails", updatedArray);
	};

	const errorAddressed = (i, target, type = "url") => {
		if (type == "url") {
			let updatePlatformwiseUrlsValidationDetails = [
				...plaformwiseValidationError.platformwiseUrlsValidationDetails
			];
			updatePlatformwiseUrlsValidationDetails[i][target] = true;
			setPlatformwiseValidationError({
				...plaformwiseValidationError,
				platformwiseUrlsValidationDetails: [...updatePlatformwiseUrlsValidationDetails]
			});
		} else if (type === "id") {
			let updatePlatformwiseIdsValidationDetails = [
				...plaformwiseValidationError.platformwiseIdsValidationDetails
			];
			updatePlatformwiseIdsValidationDetails[i][target] = true;
			setPlatformwiseValidationError({
				...plaformwiseValidationError,
				platformwiseIdsValidationDetails: [...updatePlatformwiseIdsValidationDetails]
			});
		}
	};

	const handlePasteOperation = (pastedText, startIndex) => {
		const checkForNewLine = pastedText.includes("\n");

		if (!checkForNewLine) {
			/*
				adding a condition to check whether the user is not adding platform location links for multi locations,
				this induces the default behaviour of paste operation in case of multilocation links
			*/
			if (!platformWiseFlow) {
				const updatedPlatformLocationLinks = { ...platformLocationLinks };
				updatedPlatformLocationLinks[PLATFORMWISE_TABS[startIndex]] = {
					...updatedPlatformLocationLinks[PLATFORMWISE_TABS[startIndex]],
					id: pastedText
				};
				handleFormData("platformLocationLinks", { ...updatedPlatformLocationLinks });
			}
			return;
		}

		const rowWiseDataDistribution = pastedText.split("\n");
		let stopOperation = false;
		for (let i = 0; i < rowWiseDataDistribution.length; i++) {
			rowWiseDataDistribution[i] = rowWiseDataDistribution[i].replaceAll("\r", "");
			rowWiseDataDistribution[i] = rowWiseDataDistribution[i].split("\t");
			if (rowWiseDataDistribution[i].length !== 2) {
				stopOperation = true;
			}
		}

		if (!stopOperation) {
			if (!platformWiseFlow) {
				const updatedPlatformLocationLinks = { ...platformLocationLinks };
				let rowIndex = 0;
				PLATFORMWISE_TABS.forEach((platform, i) => {
					if (i >= startIndex) {
						updatedPlatformLocationLinks[platform] = {
							id: businessBasedDisabledFields.platformId[platform]
								? ""
								: rowWiseDataDistribution[rowIndex]
								? rowWiseDataDistribution[rowIndex][0]
								: "",
							url: businessBasedDisabledFields.platformUrl[platform]
								? ""
								: rowWiseDataDistribution[rowIndex]
								? rowWiseDataDistribution[rowIndex][1]
								: ""
						};
						rowIndex++;
					}
				});
				handleFormData("platformLocationLinks", { ...updatedPlatformLocationLinks });
			} else {
				let updatedArray = JSON.parse(JSON.stringify(multiLocationDetails));
				let rowIndex = 0;
				updatedArray = updatedArray.map((locationDetails, i) => {
					const updatedLocationDetails = { ...locationDetails };
					if (i >= startIndex) {
						updatedLocationDetails.platformLocationLinks = {
							...updatedLocationDetails.platformLocationLinks,
							[PLATFORMWISE_TABS[selectedTab]]: {
								id: businessBasedDisabledFields.platformId[PLATFORMWISE_TABS[selectedTab]]
									? ""
									: rowWiseDataDistribution[rowIndex]
									? rowWiseDataDistribution[rowIndex][0]
									: "",
								url: businessBasedDisabledFields.platformUrl[PLATFORMWISE_TABS[selectedTab]]
									? ""
									: rowWiseDataDistribution[rowIndex]
									? rowWiseDataDistribution[rowIndex][1]
									: ""
							}
						};
						rowIndex++;
					}
					return {
						...updatedLocationDetails
					};
				});
				handleFormData("multiLocationDetails", updatedArray);
			}
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Expected a tabular data with 2 columns.",
					timeout: 5000,
					error: true
				}
			});
			handleFormData("platformLocationLinks", { ...platformLocationLinks });
		}
	};

	return (
		<a.div className="common-box-structure " style={contentProps}>
			<div className={`header ${animate && "header-animate"}`}>
				Add the links of your location on aggregator platforms
			</div>
			{platformWiseFlow ? (
				<React.Fragment>
					<div className="platform-logos">
						{PLATFORMWISE_TABS.map((platform, i) => (
							<span key={i}>
								<img
									src={CATALOGUE_PLATFORMS_LOGO[platform]}
									className={`image ${selectedTab >= i && "selected"}`}
								/>
							</span>
						))}
					</div>
					<a.div
						className="links-entry-table multi-city-table multi-city-details-table"
						ref={scrollRef}
						onScroll={listenToScroll}
						id="style-scrollbar"
					>
						<div className={`row ${scrollClass}`}>
							<div className="col">Name</div>
							<div className="col">City</div>
							<div className="col platform-name">
								{capitaliseText(PLATFORMWISE_TABS[selectedTab])} Platform ID
							</div>
							<div className="col">Platform URL</div>
						</div>
						{locationDetailsArray.map((locationDetail, i) => (
							<div className="row" key={i}>
								<div className="col" title={locationDetail.locationName}>
									{adjustStringLength(locationDetail.locationName || "--")}
								</div>
								<div className="col">{adjustStringLength(locationDetail.city || "--")}</div>
								<div
									className={`col ${
										!plaformwiseValidationError.platformwiseIdsValidationDetails[i][
											PLATFORMWISE_TABS[selectedTab]
										]
											? " error-evaluator"
											: ""
									}`}
								>
									<input
										autoFocus={i === 0}
										value={
											businessBasedDisabledFields.platformId[PLATFORMWISE_TABS[selectedTab]]
												? "-- NOT APPLICABLE --"
												: locationDetail.platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]
														?.id
										}
										onChange={(e) => {
											handleMultiLocationValueUpdates(e.target.value, "id", i);
										}}
										onFocus={() => errorAddressed(i, PLATFORMWISE_TABS[selectedTab], "id")}
										disabled={Boolean(
											businessBasedDisabledFields.platformId[PLATFORMWISE_TABS[selectedTab]]
										)}
										onPaste={(e) => {
											handlePasteOperation(e.clipboardData.getData("Text"), i);
										}}
									/>
									<span
										className={`error-evaluator-content ${
											!plaformwiseValidationError.platformwiseIdsValidationDetails[i][
												PLATFORMWISE_TABS[selectedTab]
											]
												? "visible"
												: ""
										}`}
										onClick={(e) => {
											handleMultiLocationValueUpdates("", "id", i);
											errorAddressed(i, PLATFORMWISE_TABS[selectedTab], "id");
										}}
									>
										Invalid ID
									</span>
								</div>
								<div
									className={`col ${
										!plaformwiseValidationError.platformwiseUrlsValidationDetails[i][
											PLATFORMWISE_TABS[selectedTab]
										]
											? " error-evaluator"
											: ""
									}`}
								>
									<input
										className={`platform-url-input ${
											Boolean(
												businessBasedDisabledFields.platformUrl[PLATFORMWISE_TABS[selectedTab]]
											)
												? i === 0
													? "first-disabled-child"
													: i === locationDetailsArray.length - 1
													? "last-disabled-child"
													: ""
												: ""
										}`}
										value={
											businessBasedDisabledFields.platformUrl[PLATFORMWISE_TABS[selectedTab]]
												? "-- NOT APPLICABLE --"
												: locationDetail.platformLocationLinks[PLATFORMWISE_TABS[selectedTab]]
														?.url
										}
										onChange={(e) => {
											handleMultiLocationValueUpdates(e.target.value, "url", i);
										}}
										onFocus={() => errorAddressed(i, PLATFORMWISE_TABS[selectedTab])}
										disabled={Boolean(
											businessBasedDisabledFields.platformUrl[PLATFORMWISE_TABS[selectedTab]]
										)}
									/>
									<span
										className={`error-evaluator-content ${
											!plaformwiseValidationError.platformwiseUrlsValidationDetails[i][
												PLATFORMWISE_TABS[selectedTab]
											]
												? "visible"
												: ""
										}`}
										onClick={(e) => {
											handleMultiLocationValueUpdates("", "url", i);
											errorAddressed(i, PLATFORMWISE_TABS[selectedTab]);
										}}
									>
										Invalid URL
									</span>
								</div>
							</div>
						))}
					</a.div>
					<span
						className={`platformwise-validation-message ${
							plaformwiseValidationError.state ? "visible" : ""
						}`}
					>
						&nbsp;* All the fields in the table above must be filled
					</span>
				</React.Fragment>
			) : (
				<a.div className="links-entry-table links-entry-table-platforms" style={contentProps}>
					<div className="row">
						<div className="col">Platform</div>
						<div className="col">Platform ID</div>
						<div className="col">Platform URL</div>
					</div>
					{PLATFORMWISE_TABS.map((name, i) => (
						<div className="row" key={i}>
							<div className="col col-with-image">
								<img src={CATALOGUE_PLATFORMS_LOGO[name.toLowerCase()]} className="platform-img" />
								<span>{capitaliseText(name)}</span>
							</div>
							<div
								className={`col ${
									(platformLocationLinks[name.toLowerCase()]?.id.length > 0 &&
										!validation.idsValidation[name.toLowerCase()] &&
										"id-error-background") ||
									""
								}`}
							>
								<input
									className={`platform-id ${
										(platformLocationLinks[name.toLowerCase()]?.id.length > 0 &&
											!validation.idsValidation[name.toLowerCase()] &&
											"id-error") ||
										""
									}`}
									onChange={(e) => handleChange(name.toLowerCase(), e, "id")}
									value={
										businessBasedDisabledFields.platformId[name.toLowerCase()]
											? "-- NOT APPLICABLE --"
											: platformLocationLinks[name.toLowerCase()]?.id
									}
									ref={i === 0 ? initialFocusRef : null}
									onFocus={() => removeErrorNotifier(name.toLowerCase(), "id")}
									disabled={Boolean(businessBasedDisabledFields.platformId[name.toLowerCase()])}
									onPaste={(e) => {
										handlePasteOperation(e.clipboardData.getData("Text"), i);
									}}
								/>
								<span
									className={`id-evaluator ${
										(platformLocationLinks[name.toLowerCase()]?.id.length > 0 &&
											!validation.idsValidation[name.toLowerCase()] &&
											"id-evaluator-visible") ||
										""
									}`}
									onClick={() => {
										clearText(name.toLowerCase(), "id");
									}}
								>
									Invalid ID
								</span>
							</div>
							<div
								className={`col ${
									(platformLocationLinks[name.toLowerCase()]?.url.length > 0 &&
										!validation.urlsValidation[name.toLowerCase()] &&
										"url-error") ||
									""
								}`}
							>
								<input
									className={`platform-url-input ${
										(platformLocationLinks[name.toLowerCase()]?.url.length > 0 &&
											!validation.urlsValidation[name.toLowerCase()] &&
											"url-error") ||
										""
									}`}
									onChange={(e) => handleChange(name.toLowerCase(), e, "url")}
									value={
										businessBasedDisabledFields.platformUrl[name.toLowerCase()]
											? "-- NOT APPLICABLE --"
											: platformLocationLinks[name.toLowerCase()]?.url
									}
									onFocus={() => removeErrorNotifier(name.toLowerCase(), "url")}
									disabled={Boolean(businessBasedDisabledFields.platformUrl[name.toLowerCase()])}
								/>
								<span
									className={`url-evaluator ${
										(platformLocationLinks[name.toLowerCase()]?.url.length > 0 &&
											!validation.urlsValidation[name.toLowerCase()] &&
											"url-evaluator-visible") ||
										""
									}`}
									onClick={() => {
										clearText(name.toLowerCase(), "url");
									}}
								>
									Invalid URL
								</span>
							</div>
						</div>
					))}
					{validation.state && (
						<span className="validation-message">
							&nbsp;* All the fields in the table above must be filled
						</span>
					)}
				</a.div>
			)}
			<div className="button-div">
				<Button type="secondary" clickHandler={handlePrevClick}>
					<span>Previous</span>
				</Button>
				<Button clickHandler={handleNextClick}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
