import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../../components/Periscope/EarlyAccess/Header";
import { fetchBizPlatforms, fetchBrands } from "../../../actions/actions";
import { connect } from "react-redux";
import { store } from "../../../store/configureStore";
import { ActionTypes } from "../../../actions/_types";
import { getFutureDate } from "../../../helpers/periscope";

const GetStarted = () => {
	useEffect(() => {
		fetchBrands("", true);
		fetchBizPlatforms(false, false, true);
	}, []);

	const history = useHistory();
	const selectLocations = () => {
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
			payload: { dataSelected: {}, locations: {}, locationsPlatformMapping: [], offset: 0, isCheckedAll: false }
		});
		history.push("/periscope/early-access/get-started/select-location");
	};
	return (
		<div className="periscope-container">
			<div className="section-container-common get-started-periscope">
				<Header />
				<div className="get-started-content">
					<div className="get-started-div">
						<div className="location-info">
							<div className="select-location">
								<span className="location-get-started">Select Locations to Get Started</span>
								<span className="location-text">
									You can select up to 5 locations to monitor during your free trial.
								</span>
							</div>
							<button onClick={() => selectLocations()} className="locations-btn">
								Select Locations
							</button>
						</div>
						{/* <div className="plan-view">
							<span className="main-font">
								Your free trial access will end on {getFutureDate(6, "months")}.
							</span>
							<span className="text-display">
								Your subscription after the trial period will be billed per storefront, which includes
								each unique combination of brand, location, and platform
							</span>
						</div> */}
					</div>
					<img src="/assets/periscope/select-location.svg" />
				</div>
				{/* <div className="terms">
				By continuing, you agree to the <span className="bold-text">Terms & Conditions </span> and
				<span className="bold-text">Privacy Policy</span>
				of Periscope by UrbanPiper
			</div> */}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems
});

export default connect(mapStateToProps)(GetStarted);
