// utils
import { getSortOrder } from "../atlas-utils";

// action types
import { ActionTypes } from "../actions/_types";

// constant
import {
	PRESET_TYPES,
	DATE_TYPES,
	COMPARE_DATE_TYPES,
	CUSTOM_TYPES
} from "../components/_commons/NewDateCompareFilter";

const LIST_INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[9].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[9],
		customTypeSelected: CUSTOM_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const LIST_INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "",
		order: ""
	},
	sortedField: "",
	currentDateFilter: LIST_INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: LIST_INITIAL_DATE_FILTER_STATE,
	currentFilters: {},
	appliedFilters: {}
};
export const customerFeedbackListState = (state = LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMER_FEEDBACK_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.CUSTOMER_FEEDBACK_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.CUSTOMER_FEEDBACK_LIST_STATE_RESET:
			return LIST_INITIAL_STATE;
		default:
			return state;
	}
};

const CUSTOMER_FEEDBACK_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	error: false
};
export const customerFeedbackList = (state = CUSTOMER_FEEDBACK_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CUSTOMER_FEEDBACK_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CUSTOMER_FEEDBACK_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
					searchFieldSelected:
						(action.payload.searchKeywords.length && action.payload.searchKeywords[0]) || null
				},
				loading: false
			};
		case ActionTypes.GET_CUSTOMER_FEEDBACK_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CUSTOMER_FEEDBACK_LIST:
			let foundInList = false;
			const updatedItems = state.data.objects.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedItems.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedItems,
					count: state.data.count + 1
				}
			};
		case ActionTypes.CUSTOMER_FEEDBACK_LIST_SEARCH:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		default:
			return state;
	}
};

export const NPS_INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[9].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[9],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const NPS_INITIAL_STATE = {
	currentDateFilter: NPS_INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: NPS_INITIAL_DATE_FILTER_STATE
};
export const customerFeedbackNpsState = (state = NPS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMER_FEEDBACK_NPS_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.CUSTOMER_FEEDBACK_NPS_STATE_RESET:
			return NPS_INITIAL_STATE;
		default:
			return state;
	}
};

const CUSTOMER_FEEDBACK_NPS_INITIAL_STATE = {
	loading: false,
	data: {
		npsStatusMetrics: {},
		npsTrendsDataset: {
			current: [],
			previous: []
		},
		ratingFrequencyDataset: {
			current: [],
			previous: []
		}
	},
	error: false
};
export const customerFeedbackNps = (state = CUSTOMER_FEEDBACK_NPS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CUSTOMER_FEEDBACK_NPS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_CUSTOMER_FEEDBACK_NPS_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.GET_CUSTOMER_FEEDBACK_NPS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
