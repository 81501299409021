import React, { useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { CaptureLocation } from "../_commons/CaptureLocation";
import { CaptureLocationCircle } from "../_commons/CaptureLocationCircle";
import ManagePolygons from "./ManagePolygons";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_CONFIGURATION_VALUES } from "../../graphql/meraki";

// third party
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../../actions/_types";

const DirectPlatform = ({
	paymentModes,
	data,
	handleForm,
	setModalBusy,
	readOnly = true,
	validations,
	handleSubmit,
	isMerakiV2Enabled = false
}) => {
	const [showLocationMap, setLocationMap] = useState(false);
	const [showCircleMap, setCircleMap] = useState(false);
	const [openPolygons, setOpenPolygons] = useState(false);
	const [enableDeliveryCharge, setEnableDeliveryCharge] = useState(false);

	const handleLatLong = (location) => {
		handleForm("locLatitude", location.lat);
		handleForm("locLongitude", location.lng);
	};

	const handleRadius = (radius) => {
		handleForm("orderDeliveryRadius", radius);
	};

	const handlePolygons = (polygons) => {
		handleForm("polygons", polygons);
	};

	const getPolygonsFieldText = () => {
		if (!data.polygons || data.polygons.length === 0) {
			return "No polygons";
		} else if (data.polygons.length === 1) {
			return "1 polygon set!";
		}
		return `${data.polygons.length} polygons set!`;
	};

	const openPolygonsSidebar = () => {
		setOpenPolygons(true);
		setModalBusy(true);
	};

	const closePolygonsSidebar = (refresh = false) => {
		setOpenPolygons(false);
		setModalBusy(false);
	};

	// const fetchConfigurationValues = async () => {
	// 	try {
	// 		let resp = await client.query({
	// 			query: GET_CONFIGURATION_VALUES,
	// 			variables: {
	// 				keys: ['business_info_config_enable_polygon_based_delivery_charge']
	// 			},
	// 			fetchPolicy: 'no-cache',
	// 		});
	// 		let configurationValues = {};
	// 		resp.data.configurationValues.forEach((config) => {
	// 			configurationValues[config.key] = config.value === '1' ? true : false;
	// 		});
	// 		setEnableDeliveryCharge(configurationValues.business_info_config_enable_polygon_based_delivery_charge);
	// 	} catch (error) {
	// 		console.log(error);
	// 		store.dispatch({
	// 			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
	// 			payload: {
	// 				message: error.message || "Something went wrong.",
	// 				timeout: 2000,
	// 				error: true,
	// 				errObject: error,
	// 			}
	// 		});
	// 	}
	// };

	// useEffect(() => {
	// 	fetchConfigurationValues();
	// }, []);

	const savePolygonsDataToServer = (polygons) => {
		handleSubmit(polygons);
	};

	return (
		<div className="direct-platform-tab">
			<div className="form-row row-half">
				<Switch
					title="Hide name from UI"
					tooltipInfo="Enable this to hide the location name from the user interface when user is prompted to choose a store"
					showCustomTooltip
					checked={data.hideNameForUi}
					clickHandler={() => handleForm("hideNameForUi", !data.hideNameForUi)}
					validationMessage={validations.hideNameForUi || ""}
					readOnly={readOnly}
				/>
				{!isMerakiV2Enabled && (
					<Switch
						title="Enabled for ordering"
						tooltipInfo="Enable this setting to allow customers to place orders from this location"
						showCustomTooltip
						checked={data.isEnabledForOrdering}
						clickHandler={() => handleForm("isEnabledForOrdering", !data.isEnabledForOrdering)}
						validationMessage={validations.isEnabledForOrdering || ""}
						readOnly={readOnly}
					/>
				)}
			</div>
			{!isMerakiV2Enabled && (
				<div className="form-row row-half">
					<Switch
						tooltipInfo="Enable this setting to temporarily disable ordering for this location. A message regarding the same will be displayed to the customer on the UI"
						showCustomTooltip
						title="Temporarily disabled for ordering"
						checked={data.orderingDisabledTemporary}
						clickHandler={() => handleForm("orderingDisabledTemporary", !data.orderingDisabledTemporary)}
						validationMessage={validations.orderingDisabledTemporary || ""}
						readOnly={readOnly}
					/>
				</div>
			)}
			<div className="form-row row-half">
				<SelectFilter
					tooltipInfo="Enter the phone numbers to receive order notifications for this location"
					showCustomTooltip
					title="Notification phone numbers"
					field="primaryContactPhone"
					readOnly={readOnly}
					currValue={data.primaryContactPhone}
					setFilter={handleForm}
					validationMessage={validations.primaryContactPhone || ""}
					isClearable={false}
					multi={true}
					creatable={true}
					openMenuOnClick={false}
					placeholder="Add phone numbers by pressing Tab/Enter"
				/>
				<SelectFilter
					title="Notification emails"
					field="primaryContactEmail"
					tooltipInfo="Enter the email addresses to receive order notifications for this location"
					showCustomTooltip
					readOnly={readOnly}
					currValue={data.primaryContactEmail}
					setFilter={handleForm}
					validationMessage={validations.primaryContactEmail || ""}
					isClearable={false}
					multi={true}
					creatable={true}
					openMenuOnClick={false}
					placeholder="Add emails by pressing Tab/Enter"
				/>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Payment modes"
					tooltipInfo="Configure the payment modes to be shown on the UI for the location"
					showCustomTooltip
					options={paymentModes.items}
					readOnly={readOnly}
					isLoading={paymentModes.isLoading}
					field="paymentModesList"
					currValue={data.paymentModesList}
					setFilter={handleForm}
					labelKey="nameForDisplay"
					valueKey="id"
					multi={true}
					validationMessage={validations.paymentModesList || ""}
				/>
			</div>
			<ManagePolygons
				isOpen={openPolygons}
				close={closePolygonsSidebar}
				data={data}
				handlePolygons={handlePolygons}
				enableDeliveryCharge={enableDeliveryCharge}
				savePolygonsDataToServer={savePolygonsDataToServer}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	paymentModes: store.configItems.paymentModes
});
export default connect(mapStateToProps)(DirectPlatform);
