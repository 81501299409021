import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { Button } from "../components/_commons/Button";
import { Switch } from "../components/_commons/Switch";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BackupActions from "../components/CatalogueBackups/BackupActions";

// assets
import { ButtonIcon } from "../components/_commons/ButtonIcon";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

// clients
import { store } from "../store/configureStore";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";

// actions
import {
	fetchCatalogueBackupsList,
	fetchCatalogueBackupConfig,
	updateCatalogueBackupConfig
} from "../actions/catalogueBackups";
import { ActionTypes } from "../actions/_types";

// constants
import { NESTED_ENTITY_TYPES } from "../client-config";
const CATALOGUE_BACKUP_INIT_STATE = {
	id: null,
	name: "",
	action: "delete",
	from: "List"
};
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, ar, cs, ht, cb, rest) => (
			<div className={"at-table-cell at-cell-text name"} title={record.title} key={i}>
				<div className="item-desc">
					<Link className="link-text" to={`/backups/preview/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">{record.title || record.id}</span>
					</Link>
					{rest.cbStatus.restrict && rest.cbStatus.catalogue_backup_id === parseInt(record.id) && (
						<div className="status">
							<ButtonIcon icon="refresh" color="#2ecc71" />
							<div>{rest.cbStatus.status === "RESTORING" ? "Restoring backup" : "Creating backup"}</div>
						</div>
					)}
				</div>
				<div className="entities-count">
					<div>Categories: {record.categoriesCount}</div>
					<div>Items: {record.itemsCount}</div>
					<div>Modifier groups: {record.optionGroupsCount}</div>
					<div>Modifiers: {record.optionsCount}</div>
				</div>
			</div>
		)
	},
	{
		name: "Backup on",
		field: "backup-on",
		sortKey: "created",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text backup-on"} key={i}>
				{record.created ? moment(record.created).format("DD MMM, YYYY hh:mm A") : "--"}
			</div>
		)
	},
	{
		name: "User",
		field: "user",
		render: (record, i, ar, cs, ht, cb, rest) => (
			<div className={"at-table-cell at-cell-text user"} key={i}>
				{record?.takenBy === "System" ? (
					record?.takenBy
				) : record?.updatedBy && !record?.updatedBy?.username?.includes("tmp_") ? (
					<a
						role="button"
						className="link-text"
						onClick={(e) => rest.handleNestedEntity(e, NESTED_ENTITY_TYPES[9], record?.updatedBy?.id)}
					>
						{record?.updatedBy?.fullName || record?.updatedBy?.username}
					</a>
				) : (
					record?.creatorEmail ||
					record?.updatedBy?.email ||
					record?.updatedBy?.username ||
					record?.takenBy ||
					"--"
				)}
			</div>
		)
	},
	{
		name: "Actions",
		field: "actions",
		render: (record, i, a, cs, handleTask, cb, rest) => (
			<div className={"at-table-cell at-cell-text actions"} key={i}>
				<div className="actions-container">
					<div>
						<Link className="link-text action-item" to={`/backups/preview/${record.id}`}>
							PREVIEW
						</Link>
						<div
							className={"action-item" + (rest.cbStatus.restrict ? " disabled" : "")}
							onClick={() => handleTask(record, "restore")}
						>
							RESTORE
						</div>
					</div>
					{record.takenBy !== "System" && (
						<div
							className={
								"action-item delete" +
								(rest.cbStatus.restrict && rest.cbStatus.catalogue_backup_id === parseInt(record.id)
									? " disabled"
									: "")
							}
							onClick={() => handleTask(record, "delete")}
						>
							DELETE
						</div>
					)}
				</div>
			</div>
		)
	}
];

const CatalogueBackupsList = ({
	catalogueBackupsList,
	catalogueBackupsListState,
	catalogueBackupsStatus,
	configItems,
	userInfo
}) => {
	const { limit, offset, sortedField, currentFilters, appliedFilters } = catalogueBackupsListState;
	const { data, loading, configLoading } = catalogueBackupsList;
	const [openBackupActions, setOpenBackupActions] = useState(false);
	const [selectedBackup, setSelectedBackup] = useState(CATALOGUE_BACKUP_INIT_STATE);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [showFilters, setShowFilters] = useState(false);
	const nestedRef = useRef();
	const tableRef = useRef();

	useEffect(() => {
		fetchCatalogueBackupConfig();
	}, []);

	useEffect(() => {
		fetchCatalogueBackupsList();
	}, [appliedFilters, catalogueBackupsStatus.restrict]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const updatecatalogueBackupsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_BACKUPS_LIST_STATE_CHANGE,
			payload
		});
	};

	const updatecatalogueBackupConfig = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_CATALOGUE_BACKUP_CONFIG,
			payload: {
				catalogueBackupConfig: {
					...data.catalogueBackupConfig,
					[field]: value
				}
			}
		});
		updateCatalogueBackupConfig();
	};

	const sortList = (field) => {
		const sort = {
			field
		};
		updatecatalogueBackupsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.CATALOGUE_BACKUPS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchCatalogueBackupsList();
	};

	const handlePagination = async (page) => {
		// set new offset
		const offset = (page - 1) * limit;
		updatecatalogueBackupsListState({
			offset
		});
		// fetch new Charges list
		await fetchCatalogueBackupsList();
		// scroll to top of the list
		if (tableRef) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updatecatalogueBackupsListState({
				[field]: size.value
			});
			// fetch new Charges list
			await fetchCatalogueBackupsList();
		}
		// scroll to top of the list
		if (tableRef) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const setFilter = useCallback(
		(field, value) => {
			let updatedCurrentFilters = {
				...currentFilters
			};
			updatedCurrentFilters[field] = value;
			updatecatalogueBackupsListState({
				currentFilters: updatedCurrentFilters
			});
		},
		[currentFilters]
	);

	const applyFilters = useCallback(() => {
		setShowFilters(false);
		updatecatalogueBackupsListState({
			appliedFilters: currentFilters,
			offset: 0
		});
	}, [currentFilters]);

	const clearFilters = () => {
		setShowFilters(false);
		updatecatalogueBackupsListState({
			currentFilters: {},
			appliedFilters: {},
			offset: 0
		});
	};

	const closeFilterSidebar = useCallback(() => {
		setShowFilters(false);
		updatecatalogueBackupsListState({
			currentFilters: appliedFilters
		});
	}, [appliedFilters]);

	const handleActions = (data = {}, action = "delete") => {
		setSelectedBackup({
			...selectedBackup,
			...data,
			action
		});
		setOpenBackupActions(true);
	};

	const closeSidebars = (refresh = false) => {
		setOpenBackupActions(false);
		setSelectedBackup(CATALOGUE_BACKUP_INIT_STATE);
		if (refresh) {
			fetchCatalogueBackupsList();
		}
	};

	let filterCount = 0;
	for (let f in currentFilters) {
		if (currentFilters[f]?.value && currentFilters[f]?.value !== "") {
			filterCount++;
		}
	}

	const filterOptions = data.filters.map((f) => {
		if (f.field === "by_system") {
			f = {
				...f,
				type: "SINGLE_STRICT",
				valueForDisplay: "Backup Type",
				values: [
					{
						valueForDisplay: "Manual Backups",
						value: false
					},
					{
						valueForDisplay: "System Backups",
						value: true
					}
				]
			};
		}
		return f;
	});

	return (
		<div className="section-container-common catalogue-backups" ref={tableRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Backups</div>
					<div className="subheader-text">
						Take periodic backups of your catalogue and restore old backups
					</div>
				</div>
				<div className="filter-buttons">
					{configItems.dimensions.width > 768 && (
						<div className={(showFilters ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={() => setShowFilters(true)}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
					)}
					{catalogueBackupsStatus.restrict ? (
						<Button
							classes="btn--disabled"
							showCustomTooltip={true}
							tooltipInfo={
								catalogueBackupsStatus.status === "RESTORING"
									? "Your catalogue backup is currently being restored. This action is disabled."
									: "A backup of your catalogue is currently being processed. This action is disabled."
							}
						>
							Backup Now
						</Button>
					) : (
						<Link to="/backups/new">
							<Button>Backup Now</Button>
						</Link>
					)}
				</div>
			</div>
			{data.catalogueBackupConfig["enable_auto_catalog_backup"] && ( // || userInfo.email.includes('@urbanpiper.com') &&
				<div className="auto-backup">
					<div>
						<div className="header-text">
							{data.catalogueBackupConfig["enable_auto_catalog_backup"]
								? "Auto Backup has been enabled"
								: "Enable Auto Backup"}
						</div>
						<div className="subheader-text">
							{data.catalogueBackupConfig["enable_auto_catalog_backup"]
								? "Backup of your catalogue will be taken, on every Saturday at 4AM"
								: "Configure to take a backup of your catalogue, on every Saturday at 4AM"}
						</div>
					</div>
					{/* {
						userInfo.email.includes('@urbanpiper.com') &&
						<Switch
							title="Enable Auto Backup"
							checked={data.catalogueBackupConfig['enable_auto_catalog_backup']}
							clickHandler={() => updatecatalogueBackupConfig('enable_auto_catalog_backup', !data.catalogueBackupConfig['enable_auto_catalog_backup'])}
							readOnly={configLoading}
						/>
					} */}
				</div>
			)}
			{configItems.dimensions.width > 768 && (
				<Filters
					isOpen={showFilters}
					close={closeFilterSidebar}
					apply={applyFilters}
					clear={clearFilters}
					options={filterOptions}
					currentFilters={currentFilters}
					setFilter={setFilter}
				/>
			)}
			<CommonTable
				loading={loading}
				data={data.objects || []}
				columns={columns}
				sortList={sortList}
				sortedField={sortedField}
				handleTask={handleActions}
				handleNestedEntity={handleNestedEntity}
				classes="catalogueBackups-list-table-container"
				content="Backups"
				cbStatus={catalogueBackupsStatus}
				// hideColumns={catalogueBackupsStatus.restrict ? ['actions'] : []}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={data.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
			/>
			<BackupActions isOpen={openBackupActions} data={selectedBackup} close={closeSidebars} />
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
				readOnly={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	catalogueBackupsList: store.catalogueBackupsList,
	catalogueBackupsListState: store.catalogueBackupsListState,
	catalogueBackupsStatus: store.catalogueBackupsStatus,
	configItems: store.configItems,
	userInfo: store.login.loginDetail
}))(CatalogueBackupsList);
