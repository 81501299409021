import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { ChargesList } from "./ChargesList";
import ChargeCreate from "./ChargeCreate";
import ChargeEdit from "./ChargeEdit";
import BackupStatus from "../components/_commons/BackupStatus";
import { Header } from "../components/ChargesList/Header";

const ChargesContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="charges-container">
			{restrictView ? (
				<Route
					path="/charges"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route path="/charges" component={ChargesList} />
					<Route
						exact
						path="/charges/new"
						render={() => (hasAccess ? <ChargeCreate hasAccess={hasAccess} /> : <Redirect to="/charges" />)}
					/>
					<Route exact path="/charges/edit/:id" component={ChargeEdit} />
				</React.Fragment>
			)}
		</div>
	);
};
export default ChargesContainer;
