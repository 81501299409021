import React from "react";

// utils
import { commifyNumbers, getSuffixedNumber } from "../../atlas-utils";
import { printCurrency } from "../../atlas-utils";

const AnalyticsSidebar = ({ topics, topic, currencySymbol, clickHandler }) => (
	<div className="dashboard-side-bar dashboard-side-bar--analytics">
		{topics.map((tpc, i) => {
			return (
				<div
					className={
						(topic == tpc.topic ? "selected" : "") + " selectable sidebar-row sidebar-row--analytics"
					}
					key={i}
					onClick={() => clickHandler(tpc)}
				>
					<div className="text text--small">{tpc.displayName}</div>
					<div title={commifyNumbers(tpc.value)} className="text text--large">
						{tpc.topic == "SALES" || tpc.topic == "AVERAGE_SALES" ? printCurrency(currencySymbol) : null}
						{getSuffixedNumber(tpc.value)}
					</div>
				</div>
			);
		})}
	</div>
);
export default AnalyticsSidebar;
