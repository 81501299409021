import React, { useState, useCallback } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

const DisintegrateLpa = ({ isOpen, data = {}, close, submit }) => {
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [status, setStatus] = useState(false);

	const handleClose = useCallback(
		(success = false) => {
			setStatus(false);
			close(success);
		},
		[close]
	);

	const handleSubmit = useCallback(async () => {
		setConfirmLoading(true);
		try {
			await submit();
			setConfirmLoading(false);
			handleClose(true);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	}, [handleClose, submit]);

	return (
		<div className="archive-restore-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSubmit}
				title="Read this Carefully!"
				submitTitle="Disconnect"
				submitClass={!status ? "at-btn--danger disabled" : "at-btn--danger"}
				loading={confirmLoading}
				isNested={true}
			>
				<div className="form-content">
					<div className="alert">
						<img src="/assets/icons/alert.svg" />
						<span>Unexpected things can happen if you don't read this!</span>
					</div>
					<div className="entity-title">
						Before you disconnect your location at <span>"{data.location}"</span> from{" "}
						<span>"{data.platform}"</span> please consider:
					</div>
					<div className="message-item-container">
						<ul>
							<li>
								Order relay will stop from {data.platform} to your {data.location}.
							</li>
							<li>
								Services like catalogue updates, inventory control and location status updates etc.,
								will not be processed by UrbanPiper for this location and platform.
							</li>
							<li>
								You should inform your contact person at {data.platform}, to help you go back to their
								partner app and avail these services (if you have not already).
								<br />
								<br />
								<span>
									Consider performing this step before disintegrating, to avoid disruptions to order
									relay from {data.platform} to {data.location}.
								</span>
							</li>
							<li>
								Any adjustments will be made to your UrbanPiper invoices (should you be on the per-unit
								billing plan) from the next billing cycle.
							</li>
						</ul>
					</div>
					<CheckBox checked={status} clickHandler={() => setStatus(!status)} tabIndex={!isOpen ? "-1" : ""}>
						<span className="input-message">
							Proceed with disconnecting your location at <span>{data.location}</span> from{" "}
							<span>{data.platform}</span>
						</span>
					</CheckBox>
				</div>
			</FormSidebar>
		</div>
	);
};
export default DisintegrateLpa;
