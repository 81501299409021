const INITIAL_STATE = {
	medium: "",
	active: false,
	testMessageContact: "",
	testMessageLoading: false,
	testMessageStatus: null,
	testMessageError: false
};

export const campaignSideBar = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "SET_OVERLAY_SIDEBAR_STATE":
			return { ...state, ...action.payload };
		case "TOGGLE_OVERLAY_SIDE_BAR":
			return { ...state, active: action.payload };
		case "RESET_OVERLAY_SIDEBAR_STATE":
			return INITIAL_STATE;
		default:
			return state;
	}
};
