import React, { Component } from "react";
import { connect } from "react-redux";

// graphql
import { GET_ANALYTICS_SUMMARY, GET_ANALYTICS_DATASET_GRAPH } from "../../graphql/getAnalytics";

// third party
import PubSub from "pubsub-js";

// components
import AnalyticsData from "./AnalyticsData";
import AnalyticsSidebar from "./AnalyticsSidebar";

// client
import { client } from "../../client.js";
import { store } from "../../store/configureStore";

// constants
import { TOPIC_LIST_ANALYTICS, CRITERIA_ANALYTICS } from "../../client-config";
import { TRACK_EVENT } from "../../atlas-utils/tracking";
import { PRESET_TYPES } from "../_commons/DateCompareFilter";

// utils
import { toggleGlobalLoader } from "../../actions/actions";
import { ActionTypes } from "../../actions/_types";

@connect((store) => ({
	dashboardState: store.dashboardState,
	anaSummary: store.anaSummary,
	biz: store.login.loggedInbizDetail,
	dimensions: store.configItems.dimensions
}))
export default class Analytics extends Component {
	selectTopic = (tpc) => {
		let dashboardState = { ...this.props.dashboardState };
		dashboardState.analyticsTopic = tpc.topic;
		dashboardState.criteriaValue = "all";
		if (tpc.topic == "USER_SIGNUPS") {
			dashboardState.criteria = CRITERIA_ANALYTICS[1];
		} else {
			dashboardState.criteria = CRITERIA_ANALYTICS[0];
		}
		store.dispatch({
			type: "ANA_SUMMARY_STATE_CHANGE_RESET_CRITERIA",
			payload: dashboardState
		});
	};

	async componentDidMount() {
		// set tracking related info
		let eventName = "dash_quick_stats_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// get data
		store.dispatch(toggleGlobalLoader(true));
		await this.getData(this.props);
		store.dispatch(toggleGlobalLoader(false));

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		let is_data_present = false;
		const graphData = store.getState().anaGraphData.data;
		if (graphData && graphData.current && graphData.previous) {
			if (graphData.current.length || graphData.previous.length) {
				is_data_present = true;
			}
		}
		const { productType, dashboardState } = this.props;
		const { analyticsTopic, appliedDateFilter, criteriaValue } = dashboardState;
		const eventMeta = {
			topic: analyticsTopic,
			duration: appliedDateFilter.current.dateFilter,
			channel: criteriaValue,
			mode: productType,
			is_data_present,
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	getData = async (props) => {
		store.dispatch({
			type: "ANA_SUMMARY_REQUEST",
			payload: null
		});
		try {
			let resp = await this.getTopics(props);
			store.dispatch({
				type: "ANA_SUMMARY_SUCCESS",
				payload: resp.data.analytics.topics
			});
			// fetching order channels is only valid when product type is ORDERING
			if (props.productType === "ORDERING") {
				this.getOrderChannels(props);
			}
			await this.getAnaGraphData(props);
		} catch (error) {
			store.dispatch({
				type: "ANA_SUMMARY_FAILURE",
				payload: error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	async componentWillReceiveProps(newProps) {
		// set tracking related info
		let eventName = "";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch data now
		if (
			this.props.dashboardState.appliedDateFilter.current.dateFilter !==
				newProps.dashboardState.appliedDateFilter.current.dateFilter ||
			this.props.productType !== newProps.productType ||
			this.props.selectedBrand !== newProps.selectedBrand
		) {
			store.dispatch(toggleGlobalLoader(true));
			await this.getData(newProps);
			store.dispatch(toggleGlobalLoader(false));

			// set tracking related info
			if (this.props.productType !== newProps.productType) {
				eventName = "dash_switch_mode";
			} else {
				eventName = "dash_quick_stats_duration";
			}
		}
		if (this.props.dashboardState.anlyticsTopic !== newProps.dashboardState.analyticsTopic) {
			store.dispatch(toggleGlobalLoader(true));
			// fetching order channels is only valid when product type is ORDERING
			if (newProps.productType === "ORDERING") {
				await this.getOrderChannels(newProps);
			}
			await this.getAnaGraphData(newProps);
			store.dispatch(toggleGlobalLoader(false));

			// set tracking related info
			eventName = "dash_quick_stats_topic";
		}
		if (this.props.dashboardState.criteriaValue !== newProps.dashboardState.criteriaValue) {
			store.dispatch(toggleGlobalLoader(true));
			await this.getAnaGraphData(newProps);
			store.dispatch(toggleGlobalLoader(false));

			// set tracking related info
			eventName = "dash_quick_stats_channel";
		}

		// set tracking related info and send the event to be logged
		// checking for eventName before tracking this event to make sure that a change was performed
		if (eventName) {
			if (window.performance) {
				perfEnd = window.performance.now();
			}
			let is_data_present = false;
			const graphData = store.getState().anaGraphData.data;
			if (graphData && graphData.current && graphData.previous) {
				if (graphData.current.length || graphData.previous.length) {
					is_data_present = true;
				}
			}
			const { productType, dashboardState } = newProps;
			const { analyticsTopic, appliedDateFilter, criteriaValue } = dashboardState;
			const eventMeta = {
				topic: analyticsTopic,
				duration: appliedDateFilter.current.dateFilter,
				channel: criteriaValue,
				mode: productType,
				is_data_present,
				time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
			};
			// PubSub.publish(TRACK_EVENT, {
			// 	tracker: 'mixpanel',
			// 	eventName,
			// 	eventMeta,
			// });
		}
	}

	getAnaGraphData = async (props) => {
		const { productType, dashboardState, selectedBrand } = props;
		const { appliedDateFilter, analyticsTopic, criteria, criteriaValue } = dashboardState;
		const variables = {
			productType,
			duration: {
				preset: appliedDateFilter.current.dateFilter
			},
			topic: analyticsTopic,
			criteria,
			criteriaValue,
			dataFormat: "GRAPHICAL"
		};
		if (selectedBrand !== null) {
			variables.brand = selectedBrand.id;
		}

		store.dispatch({
			type: "ANA_GRAPH_DATA_REQUEST"
		});
		try {
			const resp = await client.query({
				query: GET_ANALYTICS_DATASET_GRAPH,
				variables: variables
			});
			store.dispatch({
				type: "ANA_GRAPH_DATA_SUCCESS",
				payload: resp.data.analytics.dataset[0]
			});
		} catch (error) {
			store.dispatch({
				type: "ANA_GRAPH_DATA_FAILURE",
				error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	getOrderChannels = async (props) => {
		const { productType, dashboardState, selectedBrand } = props;
		const { appliedDateFilter, criteria, analyticsTopic } = dashboardState;
		const variables = {
			productType,
			duration: {
				preset: appliedDateFilter.current.dateFilter
			},
			topic: analyticsTopic,
			groupBy: criteria
		};
		if (selectedBrand !== null) {
			variables.brand = selectedBrand.id;
		}
		store.dispatch({
			type: "ANA_ORDER_CHANNELS_REQUEST"
		});
		try {
			const resp = await client.query({
				query: GET_ANALYTICS_SUMMARY,
				variables: variables
			});
			store.dispatch({
				type: "ANA_ORDER_CHANNELS_SUCCESS",
				payload: resp.data.analytics.topics
			});
		} catch (error) {
			store.dispatch({
				type: "ANA_ORDER_CHANNELS_FAILURE"
			});
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	getTopics = async (props) => {
		const { appliedDateFilter } = props.dashboardState;
		const { productType, selectedBrand } = props;
		const variables = {
			productType,
			duration: {
				preset: appliedDateFilter.current.dateFilter
			}
		};
		if (selectedBrand !== null) {
			variables.brand = selectedBrand.id;
		}
		const data = await client.query({
			query: GET_ANALYTICS_SUMMARY,
			variables
		});
		return data;
	};

	render() {
		const { dimensions, dashboardState, anaSummary, productType } = this.props;
		const { currentDateFilter, appliedDateFilter, criteria, analyticsTopic } = dashboardState;
		const { loading, data } = anaSummary;
		const presetDateFilterLabel = PRESET_TYPES.find(
			(pt) => pt.value === appliedDateFilter.current.dateFilter
		).label;
		return (
			<div className="dashboard-parent-container">
				<div className="dashboard-section-container">
					<div className={(loading ? "disabled" : "") + " sidebar-container"}>
						<SidebarPure
							loading={loading}
							data={data}
							selectTopic={this.selectTopic}
							topic={analyticsTopic}
							currencySymbol={this.props.biz.currencySymbol}
							isMobile={dimensions.width <= 768}
						/>
					</div>
					<div className={(loading ? "disabled" : "") + " data-container"}>
						{this.props.anaSummary.error ? (
							<div className="Ta(c) C(#c0392b)">
								Failed to fetch the "Quick Stats" data, refresh the page
							</div>
						) : (
							<AnalyticsData
								productType={productType}
								duration={appliedDateFilter.current.dateFilter}
								topic={analyticsTopic}
								groupBy={criteria}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const DashboardSidebarPlaceholder = ({ isMobile }) => {
	if (!isMobile) {
		return (
			<div className="Pstart(10px) Pend(10px)">
				<div className="shimmer shimmer--default H(70px) Mb(15px)" />
				<div className="shimmer shimmer--default H(70px) Mb(15px)" />
				<div className="shimmer shimmer--default H(70px) Mb(15px)" />
				<div className="shimmer shimmer--default H(70px)" />
			</div>
		);
	}
	return null;
};

const SidebarPure = ({ loading, data, selectTopic, topic, currencySymbol, isMobile }) => {
	if (!data && loading) {
		return <DashboardSidebarPlaceholder isMobile={isMobile} />;
	}
	if (data) {
		let topics = data.filter(({ topic }) => {
			if (TOPIC_LIST_ANALYTICS.indexOf(topic) >= 0) {
				return true;
			}
			return false;
		});
		return (
			<AnalyticsSidebar
				clickHandler={selectTopic}
				topics={topics}
				topic={topic}
				currencySymbol={currencySymbol}
			/>
		);
	} else {
		return null;
	}
};
