import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import ModifierGroupEntityAssociations from "../EntityAssociations/ModifierGroupEntityAssociations";
import DraggableModifierAccordion, { DRAGGABLE_ENTITY_TYPES } from "../_commons/DraggableModifierAccordion";

// clients
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { clientMenu } from "../../client-menu";

// graphql
import { GET_ITEM_MODIFIER_GROUPS, GET_MENU_SERVICE_ITEM_MODIFIER_GROUPS } from "../../graphql/items";

// actions
import { ActionTypes } from "../../actions/_types";

// constant
import { NESTED_ENTITY_TYPES, MODIFIER_GROUP_ENTITY_ASSOCIATION_TYPES } from "../../client-config";

const ModifierGroups = ({
	itemId,
	handleNestedEntity,
	setModalBusy,
	readOnly = true,
	isFromMenuSection = false,
	menuId = "",
	setFormTouched = () => {},
	reOrderedMenuModifierGroups,
	setReOrderedMenuModifierGroups,
	menuDetailsData,
	handleEntityDissociation
}) => {
	const [loading, setLoading] = useState(false);
	const [modifierGroups, setModifierGroups] = useState([]);
	const [associationSidebar, setAssociationSidebar] = useState(false);

	const getHeaderText = () => {
		if (isFromMenuSection) {
			return "Associated Modifier Groups";
		}
		return `${modifierGroups.length} Associated Modifier Groups`;
	};

	const getSubHeaderText = () => {
		if (isFromMenuSection) {
			const numOfVariants = modifierGroups.filter((mg) => mg.isVariant).length || 0;
			const numOfAddOns = modifierGroups.filter((mg) => !mg.isVariant).length || 0;
			return `${numOfVariants} variant group(s) & ${numOfAddOns} add-on group(s) associated to this item`;
		}
		return "Manage modifier groups associated with this item";
	};

	const handleRemoveEntity = async (entityId) => {
		setLoading(true);
		const respStatus = await handleEntityDissociation(entityId, itemId, DRAGGABLE_ENTITY_TYPES.MODIFIER_GROUP);
		if (respStatus) {
			await fetchModifierGroups();
		}
		setLoading(false);
	};

	const fetchModifierGroups = useCallback(
		async (filters = {}) => {
			try {
				setLoading(true);
				if (isFromMenuSection) {
					const variables = {
						menuId,
						itemId: String(itemId),
						limit: 100,
						offset: 0
					};
					const resp = await clientMenu.query({
						query: GET_MENU_SERVICE_ITEM_MODIFIER_GROUPS,
						variables,
						fetchPolicy: "no-cache"
					});
					setModifierGroups(resp.data.item.optionGroups.objects);
					setReOrderedMenuModifierGroups(resp.data.item.optionGroups.objects);
				} else {
					const variables = {
						id: parseInt(itemId),
						limit: 100,
						offset: 0
					};
					const resp = await client.query({
						query: GET_ITEM_MODIFIER_GROUPS,
						variables,
						fetchPolicy: "no-cache"
					});
					setModifierGroups(resp.data.item.optionGroups.objects);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
			setFormTouched(false);
			// Why menuDetailsData in the dependency array?
			// we will never fully know what is updated in the nested entities, so need to refresh
			// the detials on every menuDetailsData change
			// TODO: need to find a better way to do this
		},
		[itemId, menuDetailsData]
	);

	useEffect(() => {
		fetchModifierGroups();
	}, [fetchModifierGroups]);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchModifierGroups();
		}
	};

	const generateModifierGroupMap = (modifierGroups) => {
		const modifierGroupMap = {};
		modifierGroups.forEach((modifierGroup) => {
			modifierGroupMap[isFromMenuSection ? `mg_${modifierGroup.id}` : modifierGroup.id] = true;
		});

		return { ...modifierGroupMap };
	};
	if (loading) {
		return (
			<div className="P(10px 0)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"item-modifier-group-tab" + (loading ? " no-click" : "")}>
			<div className={"header-container" + (!isFromMenuSection ? " header-container--padding" : "")}>
				<div>
					<div className="header-text">{getHeaderText()}</div>
					<div className="header-subtext">{getSubHeaderText()}</div>
				</div>
				<div>
					{!readOnly && (
						<div>
							<Button clickHandler={openAssociationSidebar}>Update</Button>
						</div>
					)}
				</div>
			</div>
			{modifierGroups.length !== 0 &&
				(isFromMenuSection ? (
					<DraggableModifierAccordion
						reOrderedMenuModifierGroups={reOrderedMenuModifierGroups}
						setReOrderedMenuModifierGroups={setReOrderedMenuModifierGroups}
						setFormTouched={setFormTouched}
						menuDetailsData={menuDetailsData}
						handleNestedEntity={handleNestedEntity}
						handleRemoveEntity={handleRemoveEntity}
					/>
				) : (
					modifierGroups.map((mg, i) => <Modifier key={i} {...mg} handleNestedEntity={handleNestedEntity} />)
				))}
			<ModifierGroupEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityId={itemId}
				entityType={MODIFIER_GROUP_ENTITY_ASSOCIATION_TYPES[0]}
				isFromMenuSection={isFromMenuSection}
				enabledModifierGroups={generateModifierGroupMap(modifierGroups)}
			/>
		</div>
	);
};
export default ModifierGroups;

const Modifier = ({
	id,
	optionGroupTitle,
	name,
	optionGroupDesc,
	description,
	optionGroupMaxSelectable,
	isVariant,
	handleNestedEntity,
	isFromMenuSection,
	maxSelectable
}) => {
	return (
		<div className={"header-container" + (!isFromMenuSection ? " header-container--padding" : "")}>
			<div>
				<div className="header-text">{isFromMenuSection ? name : optionGroupTitle}</div>
				<div className="header-subtext">
					{isFromMenuSection
						? description && description.length > 85
							? description.slice(0, 85) + "..."
							: description || "--"
						: optionGroupDesc && optionGroupDesc.length > 85
						? optionGroupDesc.slice(0, 85) + "..."
						: optionGroupDesc || "--"}
				</div>
				<div className="header-subtext">
					{!isVariant
						? `Up to ${
								isFromMenuSection
									? maxSelectable === -1
										? `any number`
										: maxSelectable
									: optionGroupMaxSelectable === -1
									? `any number`
									: optionGroupMaxSelectable
						  }`
						: "Variant"}
				</div>
			</div>
			<div>
				<a role="button" onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[5], id)}>
					<Button>View</Button>
				</a>
			</div>
		</div>
	);
};
