import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/ItemTagEdit/BasicInfo";
import AssociatedItems from "../components/ItemTagEdit/AssociatedItems";
import AssociatedModifiers from "../components/ItemTagEdit/AssociatedModifiers";

// utils
import history from "../history";
import { removeProp, adjustNestedContainer, findChangedKeysInTwoObjects, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchItemTagsList, fetchItemTagDetail, editItemTagDetail } from "../actions/itemTags";
import { ActionTypes } from "../actions/_types";

// reducers
import { itemTagDetailsReducer, ITEM_TAG_DETAILS_INITIAL_STATE } from "../reducers/itemTags";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_SOURCE, TRACKING_STATUS } from "../client-config";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Items",
		value: "items"
	},
	{
		label: "Modifiers",
		value: "modifiers"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const ItemTagEdit = ({
	tagGroups,
	selectedTagGroup,
	access,
	match,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef,
	currencySymbol
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isFormOpen, setFormState] = useState(false);
	const [itemDetails, dispatch] = useReducer(itemTagDetailsReducer, ITEM_TAG_DETAILS_INITIAL_STATE);
	const { loading, data, error, backupData } = itemDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchItemTagDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					if (selectedTagGroup) {
						fetchItemTagsList(selectedTagGroup.id);
					}
					history.push("/tags");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value) => {
		dispatch({
			type: ActionTypes.UPDATE_ITEM_TAG_DETAIL,
			payload: {
				[field]: value
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const fieldsUpdated = findChangedKeysInTwoObjects(data, backupData);
		const eventMeta = {
			fields_updated: fieldsUpdated,
			num_of_fields_updated: fieldsUpdated.length
		};

		const sanitisedData = removeProp(data, "__typename");
		const resp = await editItemTagDetail(sanitisedData, dispatch);
		if (resp) {
			eventMeta.status = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.TAG_BASIC_INFO_UPDATE, eventMeta);

			setFormTouched(false);
		} else {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.TAG_BASIC_INFO_UPDATE, eventMeta);
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_ITEM_TAG_DETAIL,
					payload: {
						isEnabled: !data.isEnabled
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	return (
		<div className="item-tag-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Tag"}
				subTitle="Edit this tag"
				submitTitle="Save"
				loading={loading}
				isNested={isNested}
				hideActions={formTab === FORM_TABS[1].value || !isFormTouched}
				headerRight={
					access.isCatalogueManagement &&
					!data.isSystem && (
						<Button
							classes={data.isEnabled ? "at-btn--danger" : "at-btn--success"}
							clickHandler={() => setArchiveRestore(true)}
						>
							{data.isEnabled ? "Archive" : "Restore"}
						</Button>
					)
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							tagGroups={tagGroups}
							handleForm={handleForm}
							validations={error.fields || {}}
							readOnly={!access.isCatalogueManagement}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<AssociatedItems
							itemTagId={parseInt(match.params.id)}
							isSystem={data.isSystem}
							handleNestedEntity={handleNestedEntity}
							nestedEntity={nestedEntity}
							setModalBusy={setModalBusy}
							readOnly={!access.isCatalogueManagement}
							currencySymbol={currencySymbol}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<AssociatedModifiers
							itemTagId={parseInt(match.params.id)}
							isSystem={data.isSystem}
							handleNestedEntity={handleNestedEntity}
							nestedEntity={nestedEntity}
							setModalBusy={setModalBusy}
							readOnly={!access.isCatalogueManagement}
							currencySymbol={currencySymbol}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[11]}
						entityName={data.title}
						object={data}
						mode={data.isEnabled ? "archive" : "restore"}
						fieldName="isEnabled"
						sourceOfTrigger={TRACKING_SOURCE.DETAIL_VIEW}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	tagGroups: store.tagGroupsList.tagGroups,
	selectedTagGroup: store.tagGroupsList.selectedTagGroup,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(ItemTagEdit);
