import React, { Component } from "react";

// utils
import { commifyNumbers, printCurrency } from "../../atlas-utils";

// third party
import { Link } from "react-router-dom";
import { useTrail, config, animated } from "react-spring";

export class StatusProgressBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				width: this.props.width
			});
		}, 500);
	}

	componentWillReceiveProps(newProps) {
		if (newProps.width != this.state.width) {
			this.setState({
				width: newProps.width
			});
		}
	}

	render() {
		return (
			<div className="status-progress-bar-container">
				<div
					style={{
						left: `${this.state.width}%`
					}}
					className="label"
				>
					<div className="label-content">
						<div className="text">{this.state.width}%</div>
						<div className="arrow-container">
							<div className="arrow"></div>
						</div>
					</div>
				</div>
				<div className="status-progress-bar-container-rail">
					<div
						style={{
							transform: `translateX(-10px)`,
							width: `${this.state.width}%`
						}}
						className="status-progress-bar"
					></div>
				</div>
			</div>
		);
	}
}

export const CampaignReport = ({
	updating,
	currency,
	currLocation,
	medium,
	reports,
	status,
	dimensions,
	fetchingCampaignStatus
}) => {
	const trails = useTrail(reports?.objects?.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	const isMobile = dimensions.width <= 768;
	return (
		<div>
			<div className={(updating ? "disabled" : "") + " report-table at-table-row-based at-table--5cols"}>
				<div className="at-table-row header-row">
					<div className="at-table-cell at-table-header at-header-text name">Name</div>
					<div className="at-table-cell at-table-header at-header-text">
						{medium === "EMAIL" ? "Email" : "Phone Number"}
					</div>
					{medium === "SMS" && !isMobile && (
						<div className="at-table-cell at-table-header at-header-text">Status</div>
					)}
					<div className="at-table-cell at-table-header at-header-text conv-revenue">
						Revenue ({printCurrency(currency)})
					</div>
					{!isMobile && (
						<div className="at-table-cell at-table-header at-header-text conv-count">Conversion count</div>
					)}
				</div>
				{trails.map(({ rotate }, i) => (
					<ReportListItem
						key={reports.objects[i].customer.id}
						currency={currency}
						report={reports.objects[i]}
						currLocation={currLocation}
						medium={medium}
						index={i}
						isMobile={isMobile}
						style={{
							transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
						}}
					/>
				))}
			</div>
			{reports.objects.length === 0 &&
				(status == "RUNNING" || fetchingCampaignStatus ? (
					<div className="campaign-report-placeholder">
						<div>Preparing results...</div>
					</div>
				) : (
					<div className="campaign-report-placeholder C(#ccc)">
						<div>No data to show</div>
					</div>
				))}
		</div>
	);
};

const ReportListItem = ({ report, currLocation, medium, currency, style, index, isMobile }) => {
	let deliveryStatus = "Pending";
	if (report.status) {
		deliveryStatus = report.status.split("_").join(" ").toLowerCase();
	} else if (report.dispatchedAt) {
		deliveryStatus = "Dispatched";
	}
	return (
		<animated.div className={(index % 2 == 1 ? "striped" : "") + " at-table-row"} style={style}>
			{report.customer.id ? (
				<Link
					to={{
						pathname: `/customers/${report.customer.id}`,
						state: currLocation
					}}
					className={"at-table-cell at-cell-text name"}
				>
					{(report.customer && report.customer.fullName) || "--"}
				</Link>
			) : (
				<div className={"at-table-cell at-cell-text name"}>
					{(report.customer && report.customer.fullName) || "--"}
				</div>
			)}
			<div className={"at-table-cell at-cell-text"}>
				{medium === "EMAIL"
					? (report.customer && report.customer.email) || "--"
					: (report.customer && report.customer.phone) || "--"}
			</div>
			{medium === "SMS" && !isMobile && (
				<div className={"at-table-cell at-cell-text"} style={{ textTransform: "capitalize" }}>
					{deliveryStatus}
				</div>
			)}
			<div className={"at-table-cell at-cell-text conv-revenue"}>
				{report.conversionRevenue ? (
					<span>
						{printCurrency(currency)}
						{commifyNumbers(report.conversionRevenue || 0)}
					</span>
				) : (
					"--"
				)}
			</div>
			{!isMobile && (
				<div className={"at-table-cell at-cell-text conv-count"}>{report.conversionCount || "--"}</div>
			)}
		</animated.div>
	);
};

export const PlaceHolder = () => <div className="shimmer H(100px) Mb(20px)" />;

export const CampaignHighLightBox = ({ title, count, uniqueCount, icon, currency, medium }) => (
	<div className="campaign-highlight-box">
		{title && (
			<div className="title-container">
				<div className="title">{title}</div>
				{medium && <div className="title">{medium}</div>}
			</div>
		)}
		<img src={icon} />
		<div className="count">
			{currency ? printCurrency(currency) : ""} {isNaN(count) ? "--" : commifyNumbers(count) || 0}
		</div>
		{uniqueCount && <div className="unique-count">Unique &mdash; {uniqueCount}</div>}
	</div>
);

export const Header = ({ name, description }) => (
	<div className="header">
		<div className="title">{name}</div>
		<div className="description">{description}</div>
	</div>
);
