import gql from "graphql-tag";

export const GET_CUSTOMER_FEEDBACK_LIST = gql`
	query getCustomerFeedbackList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		feedbacks(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				created
				choiceText
				comments
				rating
				objectId
				contentType
				customer {
					id
					fullName
					phone
				}
				bizLocation {
					id
					name
				}
				replies {
					subject
					body
					channel
					created
				}
			}
		}
	}
`;

export const FEEDBACK_REPLY = gql`
	mutation feedbackReply($feedbackId: Int!, $medium: FeedbackReplyChannel!, $subject: String, $message: String!) {
		feedbackReply(feedbackId: $feedbackId, medium: $medium, subject: $subject, message: $message) {
			status {
				success
				messages {
					field
					message
				}
			}
			reply {
				subject
				body
				created
				channel
			}
		}
	}
`;

export const GET_CUSTOMER_FEEDBACK_NPS = gql`
	query feedbackNps($duration: Duration!) {
		nps {
			npsStatusMetrics(duration: $duration) {
				current {
					percentagePromoters
					percentagePassives
					percentageDetractors
					promoters
					passives
					detractors
				}
			}
			npsTrendsDataset(duration: $duration) {
				current {
					timestamp
					value
				}
				previous {
					timestamp
					value
				}
				timeUnit
			}
			ratingFrequencyDataset(duration: $duration) {
				current {
					rating
					value
				}
				previous {
					rating
					value
				}
				timeUnit
			}
		}
	}
`;
