import React, { useEffect, useState } from "react";

// utils
import history from "../history";
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// components
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { Button } from "../components/_commons/Button";
import { Loading } from "../components/_commons/Loading";

// actions
import { ActionTypes } from "../actions/_types";
import { logout } from "../components/SiteComp";
import {
	fetchUserInviteDetail,
	generateOtp,
	verifyOtp,
	saveUserProfile,
	verifyExistingUserProfile
} from "../actions/acceptInvite";

const AcceptInvite = ({ match, acceptInviteDetail, userVerification = false, ...rest }) => {
	const { data, loading, phoneLoading, emailLoading, phoneOtpLoading, emailOtpLoading, error } = acceptInviteDetail;
	const {
		firstName,
		lastName,
		token,
		email,
		phone,
		bizIsdCode,
		emailValidated = false,
		phoneValidated = false,
		passwordSet = false
	} = rest;
	const [isEmailOtpGenerated, setEmailOtpGenerated] = useState(false);
	const [isPhoneOtpGenerated, setPhoneOtpGenerated] = useState(false);
	const [isEmailVerified, setEmailVerified] = useState(false);
	const [isPhoneVerified, setPhoneVerified] = useState(false);
	const [acceptInviteSuccess, setAcceptInviteSuccess] = useState(false);
	const [deliveryRole, setDeliveryRole] = useState(false);
	const [satelliteRole, setSatelliteRole] = useState(false);

	useEffect(() => {
		if (userVerification) {
			fetchUserInviteDetail(match.params.uuid);
			store.dispatch({
				type: ActionTypes.UPDATE_INVITE_DETAIL,
				payload: { firstName, lastName, email, phone }
			});
		} else {
			fetchUserInviteDetail(match.params.uuid);
		}
	}, [match.params.uuid, userVerification]);

	const redirectToLogin = () => {
		const satelliteOrderTaker = data?.roles.find((role) => role.name.toLowerCase() === "satellite order taker");
		const deliveryRole = data?.roles.find((role) => role.name.toLowerCase() === "delivery");
		if (satelliteOrderTaker) {
			setSatelliteRole(true);
		} else if (deliveryRole) {
			setDeliveryRole(true);
		} else {
			setAcceptInviteSuccess(true);
		}
	};

	const updateState = () => {
		// need to update both localstorage and store
		let loginDataInStore = { ...store.getState().login };
		loginDataInStore.loginDetail.verificationDetails.emailValidated = true;
		loginDataInStore.loginDetail.verificationDetails.phoneValidated = true;

		let loginDataInStorage = window.lS.get("auth");
		loginDataInStorage.userInfo.verificationDetails.emailValidated = true;
		loginDataInStorage.userInfo.verificationDetails.phoneValidated = true;

		window.lS.set("auth", loginDataInStorage);
		store.dispatch({
			type: ActionTypes.LOGIN_SUCCESS,
			payload: loginDataInStore
		});
	};

	const takeToSatellite = () => {
		window.location.href = "https://order-lite.urbanpiper.com/";
	};

	const handleForm = (field, value) => {
		let validatedVal = "";
		if (field === "phone") {
			validatedVal = value.replace(/[^0-9+]/g, "");
			if (isPhoneOtpGenerated) {
				setPhoneOtpGenerated(false);
			}
		}
		if (field === "bizIsdCode" && value === null) {
			return;
		}
		if (field === "bizIsdCode" && isPhoneOtpGenerated) {
			setPhoneOtpGenerated(false);
		}
		if (field === "email" && isEmailOtpGenerated) {
			setEmailOtpGenerated(false);
		}
		store.dispatch({
			type: ActionTypes.UPDATE_INVITE_DETAIL,
			payload: {
				[field]: field === "phone" ? validatedVal : value
			}
		});
	};

	const handleGenerateOtp = async (field = "phone") => {
		const variables = {
			uuid: match.params.uuid,
			field,
			value: field === "phone" ? `${data?.bizIsdCode?.code ? data.bizIsdCode.code : ""}${data.phone}` : data.email
		};
		const resp = await generateOtp(variables);
		if (resp && field === "phone") {
			setPhoneOtpGenerated(true);
		} else if (resp) {
			setEmailOtpGenerated(true);
		}
	};

	const handleVerifyOtp = async (field = "phone") => {
		const variables = {
			uuid: match.params.uuid,
			field,
			value:
				field === "phone" ? `${data?.bizIsdCode?.code ? data.bizIsdCode.code : ""}${data.phone}` : data.email,
			otp: field === "phone" ? data.phoneOtp : data.emailOtp
		};
		const resp = await verifyOtp(variables);
		if (resp && field === "phone") {
			setPhoneVerified(true);
		} else if (resp) {
			setEmailVerified(true);
		}
	};

	const handleSubmit = async () => {
		if (userVerification) {
			const variables = {
				uuid: match.params.uuid,
				firstName: data.firstName,
				lastName: data.lastName,
				authorizationToken: `Token ${token}`
			};
			if (!passwordSet) {
				variables.password = data.password;
				variables.confirmPassword = data.confirmPassword;
			}
			const resp = await verifyExistingUserProfile(variables);
			if (resp) {
				setAcceptInviteSuccess(true);
				updateState();
			}
		} else {
			const variables = {
				uuid: match.params.uuid,
				...data
			};
			const resp = await saveUserProfile(variables);
			if (resp) {
				redirectToLogin();
			}
		}
	};

	const validations = error.fields || {};

	if (validations.uuid) {
		return (
			<div className="accept-invite-container">
				<div className="page-container">
					<div className="atlas-logo">
						<img src="/assets/logo.svg" alt="" />
					</div>
					<div className="user-form-container">
						<div className="header">
							<img src="/assets/icons/alert.svg" />
							<div>
								<div className="text text--large">Invite is invalid!</div>
								<div className="text text--small subtitle">Please contact your administrator.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (!userVerification && validations.accepted) {
		return (
			<div className="accept-invite-container">
				<div className="page-container">
					<div className="atlas-logo">
						<img src="/assets/logo.svg" alt="" />
					</div>
					<div className="user-form-container">
						<div className="header">
							<img src="/assets/icons/icon-check.svg" />
							<div>
								<div className="text text--large">Invite has been already accepted!</div>
								<div className="text text--small subtitle">
									User already exists. Head over to Login.
								</div>
							</div>
						</div>
						<div className="user-form">
							<Link to="/login">
								<Button classes={(loading ? "disabled" : "") + " submit-btn"}>Login</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (validations.cancelled) {
		return (
			<div className="accept-invite-container">
				<div className="page-container">
					<div className="atlas-logo">
						<img src="/assets/logo.svg" alt="" />
					</div>
					<div className="user-form-container">
						<div className="header">
							<img src="/assets/icons/icon-cross.svg" />
							<div>
								<div className="text text--large">Invite has been cancelled!</div>
								<div className="text text--small subtitle">Please contact your administrator.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (deliveryRole) {
		return (
			<div className="accept-invite-container">
				<div className="page-container">
					<div className="atlas-logo">
						<img src="/assets/logo.svg" alt="" />
					</div>
					<div className="user-form-container">
						<div className="header">
							<img src="/assets/icons/icon-check.svg" />
							<div>
								<div className="text text--large">Invite has been accepted!</div>
								<div className="text text--small subtitle">
									Login to <strong>Comet App</strong> with username{" "}
									<strong>{data?.username ? data?.username : ""}</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (satelliteRole) {
		return (
			<div className="accept-invite-container">
				<div className="page-container">
					<div className="atlas-logo">
						<img src="/assets/logo.svg" alt="" />
					</div>
					<div className="user-form-container">
						<div className="header">
							<img src="/assets/icons/icon-check.svg" />
							<div>
								<div className="text text--large">Invite has been accepted!</div>
								<div className="text text--small subtitle">
									Login to <strong>Satellite</strong> with username{" "}
									<strong>{data?.username ? data?.username : ""}</strong>
								</div>
							</div>
						</div>
						<div className="user-form">
							<Button
								clickHandler={takeToSatellite}
								classes={(loading ? "disabled" : "") + " submit-btn"}
							>
								Visit Satellite
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (acceptInviteSuccess) {
		return (
			<div className="accept-invite-container">
				<div className="page-container">
					<div className="atlas-logo">
						<img src="/assets/logo.svg" alt="" />
					</div>
					<div className="user-form-container">
						<div className="header">
							<img src="/assets/icons/icon-check.svg" />
							<div>
								<div className="text text--large">
									{userVerification
										? "Your profile is verified and updated!"
										: "Invite has been accepted!"}
								</div>
								<div className="text text--small subtitle">
									{userVerification
										? "You can now head over to Atlas Dashboard."
										: "You can now head over to Login."}
								</div>
							</div>
						</div>
						<div className="user-form">
							{userVerification && (
								<Link to="/">
									<Button classes={(loading ? "disabled" : "") + " submit-btn"}>Dashboard</Button>
								</Link>
							)}
							{!userVerification && (
								<Link to="/login">
									<Button classes={(loading ? "disabled" : "") + " submit-btn"}>Login</Button>
								</Link>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="accept-invite-container">
			<div className="page-container">
				<div className="atlas-logo">
					<img src="/assets/logo.svg" alt="" />
				</div>
				<div className="user-form-container">
					<div className={(data.email && loading ? "active " : "") + "login-loader-container"}>
						<div className="infinite-load-bar" />
					</div>
					{(userVerification || data.email) && (
						<React.Fragment>
							<div className="text text--large">
								<div>Complete your Profile</div>
								{userVerification && (
									<div className="logout" onClick={logout}>
										Logout
									</div>
								)}
							</div>
							<div className="text text--small subtitle">Provide the following details</div>
							<div className="user-form">
								<InputWithLabel
									classes="user-text-input"
									placeholder="hello@example.com"
									value={data.email}
									onChange={(e) => handleForm("email", e.target.value)}
									showLoading={emailLoading}
									showAction={userVerification}
									actionText={
										data.email && (
											<div>
												{isEmailVerified || emailValidated ? (
													<img title="Verified" src="/assets/icons/icon-check.svg" />
												) : isEmailOtpGenerated ? (
													"Resend OTP"
												) : (
													"Verify with OTP"
												)}
											</div>
										)
									}
									action={
										!isEmailVerified && !emailValidated
											? () => handleGenerateOtp("email")
											: () => {}
									}
									onEnter={data.email ? () => handleGenerateOtp("email") : () => {}}
									validationMessage={validations.email || ""}
									readOnly={userVerification ? isEmailVerified || emailValidated : true}
								>
									Email
								</InputWithLabel>
								{isEmailOtpGenerated && !isEmailVerified && (
									<InputWithLabel
										classes="user-text-input"
										type="number"
										value={data.emailOtp}
										onChange={(e) => handleForm("emailOtp", e.target.value)}
										requiredLabel={true}
										showLoading={emailOtpLoading}
										showAction={true}
										actionText={data.emailOtp && data.emailOtp.length > 3 ? "Verify" : ""}
										action={() => handleVerifyOtp("email")}
										onEnter={
											data.emailOtp && data.emailOtp.length > 3
												? () => handleVerifyOtp("email")
												: () => {}
										}
										validationMessage={validations.emailValidated || ""}
									>
										OTP to verify Email
									</InputWithLabel>
								)}
								{!userVerification && (
									<SelectFilter
										title="Roles"
										readOnly={true}
										options={[]}
										field="roles"
										currValue={data.roles}
										setFilter={handleForm}
										labelKey="name"
										valueKey="name"
										multi={true}
										isClearable={false}
										validationMessage={validations.roles || ""}
										placeholder="No roles assigned"
									/>
								)}
								<InputWithLabel
									classes="user-text-input"
									value={data.firstName}
									onChange={(e) => handleForm("firstName", e.target.value)}
									validationMessage={validations.firstName || ""}
									requiredLabel={true}
								>
									First name
								</InputWithLabel>
								<InputWithLabel
									classes="user-text-input"
									value={data.lastName}
									onChange={(e) => handleForm("lastName", e.target.value)}
									validationMessage={validations.lastName || ""}
								>
									Last name
								</InputWithLabel>
								<InputWithLabel
									classes="user-text-input at-input--dropdown"
									value={data.phone}
									onChange={(e) => handleForm("phone", e.target.value)}
									requiredLabel={true}
									showLoading={phoneLoading}
									showAction={true}
									showDropdown={true}
									dropdownOptions={data.bizIsdCodes}
									dropdownField="bizIsdCode"
									dropdownCurrVal={data.bizIsdCode}
									customDropdownLabel={data?.bizIsdCode?.code || null}
									handleDropdown={handleForm}
									labelKey="name"
									valueKey="code"
									isClearable={false}
									dropdownPlaceholder="ISD"
									actionText={
										data.phone &&
										data.phone.length > 5 && (
											<div>
												{isPhoneVerified || phoneValidated ? (
													<img title="Verified" src="/assets/icons/icon-check.svg" />
												) : isPhoneOtpGenerated ? (
													"Resend OTP"
												) : (
													"Verify with OTP"
												)}
											</div>
										)
									}
									action={
										!isPhoneVerified && !phoneValidated
											? () => handleGenerateOtp("phone")
											: () => {}
									}
									onEnter={
										data.phone && data.phone.length > 5
											? () => handleGenerateOtp("phone")
											: () => {}
									}
									validationMessage={validations.phone || ""}
									readOnly={isPhoneVerified || phoneValidated}
								>
									Phone
								</InputWithLabel>
								{isPhoneOtpGenerated && !isPhoneVerified && (
									<InputWithLabel
										classes="user-text-input"
										type="number"
										value={data.phoneOtp}
										onChange={(e) => handleForm("phoneOtp", e.target.value)}
										requiredLabel={true}
										showLoading={phoneOtpLoading}
										showAction={true}
										actionText={data.phoneOtp && data.phoneOtp.length > 3 ? "Verify" : ""}
										action={() => handleVerifyOtp("phone")}
										onEnter={
											data.phoneOtp && data.phoneOtp.length > 3
												? () => handleVerifyOtp("phone")
												: () => {}
										}
										validationMessage={validations.phoneValidated || ""}
									>
										{userVerification ? "OTP to verify Phone" : "OTP"}
									</InputWithLabel>
								)}
								{!userVerification && (
									<InputWithLabel
										classes="user-text-input"
										value={data.username}
										onChange={(e) => handleForm("username", e.target.value)}
										showCustomTooltip={true}
										tooltipInfo="Only alphanumeric characters, +, -, _ and . are allowed"
										validationMessage={validations.username || ""}
										requiredLabel={true}
									>
										Username
									</InputWithLabel>
								)}
								{(!userVerification || !passwordSet) && (
									<InputWithLabel
										classes="user-text-input"
										value={data.password}
										type="password"
										onChange={(e) => handleForm("password", e.target.value)}
										validationMessage={validations.password1 || ""}
										requiredLabel={true}
									>
										Password
									</InputWithLabel>
								)}
								{(!userVerification || !passwordSet) && (
									<InputWithLabel
										classes="user-text-input"
										value={data.confirmPassword}
										type="password"
										onChange={(e) => handleForm("confirmPassword", e.target.value)}
										validationMessage={validations.password2 || ""}
										requiredLabel={true}
										onEnter={isPhoneVerified ? handleSubmit : () => {}}
									>
										Confirm Password
									</InputWithLabel>
								)}
								<Button
									clickHandler={handleSubmit}
									classes={
										(loading ||
										(!isPhoneVerified && !phoneValidated) ||
										(userVerification ? !isEmailVerified && !emailValidated : false)
											? "disabled"
											: "") + " submit-btn"
									}
								>
									Submit
								</Button>
								{((!isPhoneVerified && !phoneValidated) ||
									(userVerification ? !isEmailVerified && !emailValidated : false)) && (
									<div className="info-text">
										(Please verify your {userVerification ? "Email and" : ""} Phone number first,
										before submitting the form)
									</div>
								)}
							</div>
						</React.Fragment>
					)}
					{!data.email && loading && <Loading />}
					{!userVerification && !data.email && !loading && (
						<div className="no-items-placeholder">Oops, something went wrong!</div>
					)}
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	acceptInviteDetail: store.acceptInviteDetail
});
export default connect(mapStateToProps)(AcceptInvite);
