//client
import { client } from "../client";
import { store } from "../store/configureStore";

//graphql
import { GET_REFERRALS_LIST, GET_REFERRALS_STATS } from "../graphql/referrals";

//actions
import { ActionTypes } from "./_types";
import { toggleGlobalLoader } from "./actions";

export const fetchReferralsList = async () => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().referralsListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().referralsList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REFERRALS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let targetKeys = Object.keys(appliedFilters);
		targetKeys.length > 0 &&
			targetKeys.forEach((f) => {
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
		// date filter
		if (appliedDateFilter.current.dateFilter) {
			filtersObject.push({
				field: "created",
				value: appliedDateFilter.current.dateFilter
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		const resp = await client.query({
			query: GET_REFERRALS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});

		store.dispatch({
			type: ActionTypes.GET_REFERRALS_LIST_SUCCESS,
			payload: { ...resp.data.referrals }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REFERRALS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const getDurationObject = (appliedDateFilter) => {
	const { dateFilter: currDateFilter } = appliedDateFilter.current;
	const currDates = currDateFilter.split(",");
	const obj = {};

	// for current date range
	if (currDates.length === 1) {
		obj.duration = {
			preset: currDateFilter
		};
	} else if (currDates[0] && currDates[1]) {
		obj.duration = {
			custom: {
				start: currDates[0],
				end: currDates[1]
			}
		};
	}

	return obj;
};

export const fetchReferralsStats = async () => {
	const { appliedDateFilter } = store.getState().referralStatsState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REFERRALS_STATS_REQUEST
	});
	try {
		const durationObject = getDurationObject(appliedDateFilter);
		const variables = {
			...durationObject
		};
		const resp = await client.query({
			query: GET_REFERRALS_STATS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_REFERRALS_STATS_SUCCESS,
			payload: { ...resp.data }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REFERRALS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
