import React, { useState, useCallback } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { msaagesArrayToHtml } from "../../atlas-utils";

// graphql
import { REMOVE_BRANDS_LOCATIONS } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";

const RemoveBrands = ({ isOpen, close, isNested = true, data = [], locationId }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(false);

	const handleClose = useCallback(
		(success = false) => {
			setStatus(false);
			setLoading(false);
			close(success);
		},
		[close]
	);

	const handleSubmit = useCallback(async () => {
		setLoading(true);
		try {
			const variables = {
				brandIds: data.map((loc) => loc?.brand?.id),
				locationIds: [locationId],
				isActive: false
			};
			const resp = await client.mutate({
				mutation: REMOVE_BRANDS_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.removeBrandsLocations.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: `Removed ${data.length > 1 ? "brands" : "brand"} successfully`,
						timeout: 2000,
						error: false
					}
				});
				setTimeout(() => {
					handleClose(true);
				}, 300);
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.removeBrandsLocations.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [data, locationId, handleClose]);

	const renderMessageArchive = () => {
		let itemsCount = 0;
		data.forEach((loc) => {
			itemsCount += loc.ilaCount;
		});
		return (
			<div className="message-item-container">
				{data.length > 1 ? (
					<div>
						They are currently associated with <span>{itemsCount} Item(s).</span>
					</div>
				) : (
					<div>
						<span>"{data[0]?.brand?.name || "Selected brand"}"</span> is currently associated with{" "}
						<span>{data[0]?.ilaCount || itemsCount || 0} Item(s).</span>
					</div>
				)}
				{data.length > 1 ? (
					<div className="disclaimer">
						If you remove these {data.length} brands, they will not be part of customer facing catalogue on
						your apps and website.
					</div>
				) : (
					<div className="disclaimer">
						If you remove <span>"{data[0]?.brand?.name || "the selected brand"}"</span>, it will not be part
						of customer facing catalogue on your apps and website.
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="archive-restore-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSubmit}
				title="Read this Carefully!"
				submitTitle="Remove"
				submitClass="at-btn--danger"
				loading={loading}
				isNested={isNested}
				disabled={!status}
			>
				<div className="form-content">
					<div className="alert">
						<img src="/assets/icons/alert.svg" />
						<span>Unexpected things can happen if you don't read this!</span>
					</div>
					<div className="entity-title">
						Before you remove{" "}
						<span>{data.length > 1 ? `${data.length} brands` : `"${data[0]?.brand?.name}"`}</span> please
						consider:
					</div>
					{renderMessageArchive()}
					<CheckBox checked={status} clickHandler={() => setStatus(!status)} tabIndex={!isOpen ? "-1" : ""}>
						<span className="input-message">
							Proceed with removing{" "}
							<span>{data.length > 1 ? `${data.length} brands` : `"${data[0]?.brand?.name}"`}</span>
						</span>
					</CheckBox>
				</div>
			</FormSidebar>
		</div>
	);
};
export default RemoveBrands;
