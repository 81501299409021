import React, { Component } from "react";

// components
import { Header } from "../components/ModifierGroupsList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { Topbar } from "../components/_commons/Topbar";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchItemsDebounced } from "../actions/actions";
import { fetchModifierGroupsList } from "../actions/modifierGroups";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";
import history from "../history";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES } from "../client-config";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const MODIFIER_GROUP_TYPES = {
	"Add-On": false,
	Variant: true
};
const FORM_TABS = [
	{
		label: "All",
		value: "All"
	},
	{
		label: "Add-Ons",
		value: "Add-On"
	},
	{
		label: "Variants",
		value: "Variant"
	}
];

const columns = [
	{
		name: "Name",
		field: "name",
		sortKey: "option_group_title",
		render: (record, i, archived) => (
			<div className={"at-table-cell at-cell-text name"} title={record.optionGroupTitle} key={i}>
				<div className="name-desc">
					<Link className={"link-text " + archived} to={`/modifier-groups/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color ">
							{record.optionGroupTitle || record.id}
						</span>
					</Link>
					<div className="text--light desc-text" title={record.crmTitle}>
						{record.crmTitle && `CRM Title: ${record.crmTitle}`}
					</div>
				</div>
			</div>
		)
	},
	{
		name: "Type",
		field: "type",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text type"} key={i}>
				<div className="type-desc">
					{record.isVariant ? "Variant" : "Add-On" || "--"}
					{!record.isVariant && (
						<div className="text--light desc-text" title={record.optionGroupMaxSelectable}>
							Up to{" "}
							{record.optionGroupMaxSelectable === -1 ? `any number` : record.optionGroupMaxSelectable}
						</div>
					)}
				</div>
			</div>
		)
	},
	{
		name: "Associated Items",
		field: "associated-items",
		sortKey: "items_count",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text associated-items"} key={i}>
				{record.itemsCount | 0}
			</div>
		)
	},
	{
		name: "Modifiers",
		field: "modifiers",
		sortKey: "modifiers_count",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text modifiers"} key={i}>
				{record.modifiersCount | 0}
			</div>
		)
	}
];

@connect((store) => ({
	modifierGroupsList: store.modifierGroupsList,
	modifierGroupsListState: store.modifierGroupsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class ModifierGroupsList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			itemsLookup: {},
			archivedFilter: false,
			formTab: FORM_TABS[0].value,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "modifier_groups_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.modifierGroupsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Modifier Groups list
		await fetchModifierGroupsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });

		// the below code preloads the items dropdown
		const { configItems } = this.props;
		if (!configItems.items.items.length) {
			fetchItemsDebounced("");
		}
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "modifier-groups"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatemodifierGroupsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.MODIFIER_GROUPS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatemodifierGroupsListState({
			currentFilters: this.props.modifierGroupsListState.appliedFilters
		});
	};

	handleFormTab = async (tab) => {
		if (this.state.formTab !== tab.value) {
			this.setState({
				formTab: tab.value
			});
			if (tab.value === "All") {
				let currentFilters = {
					...this.props.modifierGroupsListState.currentFilters
				};
				delete currentFilters["is_type_variant"];
				this.updatemodifierGroupsListState({
					currentFilters,
					appliedFilters: currentFilters,
					offset: 0
				});
				// fetch Modifier Groups list
				await fetchModifierGroupsList();
			} else {
				this.setFilter("is_type_variant", MODIFIER_GROUP_TYPES[tab.value]);
				setTimeout(() => this.applyFilters(), 100);
			}
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.modifierGroupsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatemodifierGroupsListState({
			currentFilters
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.MODIFIER_GROUPS_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.MODIFIER_GROUPS_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.updatemodifierGroupsListState({
			offset: 0
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchModifierGroupsList(), 500);

	updateItemsLookup = (id, title) => {
		this.setState({
			itemsLookup: {
				...this.state.itemsLookup,
				[id]: title
			}
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatemodifierGroupsListState({
			appliedFilters: this.props.modifierGroupsListState.currentFilters,
			offset: 0
		});

		// set tracking related info
		const eventName = "modifier_groups_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchModifierGroupsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().modifierGroupsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false,
				formTab: FORM_TABS[0].value
			},
			async () => {
				this.updatemodifierGroupsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchModifierGroupsList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.modifierGroupsListState;
		const offset = (page - 1) * limit;
		this.updatemodifierGroupsListState({
			offset
		});
		// fetch new Modifier Groups list
		fetchModifierGroupsList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.modifierGroupsListState;
		if (size && size?.value !== limit) {
			this.updatemodifierGroupsListState({
				[field]: size.value
			});
			// fetch new Modifier Groups list
			await fetchModifierGroupsList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatemodifierGroupsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.MODIFIER_GROUPS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchModifierGroupsList();
	};

	render() {
		const { modifierGroupsList, modifierGroupsListState, configItems, access, biz } = this.props;
		const { limit, offset, currentFilters, sortedField } = modifierGroupsListState;
		const isMenuOverCatalogueEnabled = biz.isMenuOverCatalogueEnabled || false;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "option_group_title") {
				if (f === "is_enabled" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f].value && currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}

		const filterOptions = isMenuOverCatalogueEnabled
			? modifierGroupsList.data.filters.filter(
					(f) =>
						f.field !== "option_group_title" &&
						f.field !== "is_type_variant" &&
						f.field !== "items_count" &&
						f.field !== "modifiers_count" &&
						f.field !== "related_items"
			  )
			: modifierGroupsList.data.filters
					.map((f) => {
						if (f.type === "MULTIPLE" && f.field === "related_items") {
							f = {
								...f,
								isAsync: true,
								asyncOptions: this.props.configItems.items,
								asyncLookup: this.state.itemsLookup,
								updateAsyncLookup: this.updateItemsLookup,
								handleAsyncSearch: fetchItemsDebounced
							};
						}
						return f;
					})
					.filter((f) => f.field !== "option_group_title" && f.field !== "is_type_variant");

		const placeholderContent = {
			placeholderText: "No Modifier Groups added yet!",
			placeholderSubtext: "Manage all modifier groups here enabling customisation of items in your catalogue",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add a new Modifier Group</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/modifier-groups/new");
			},
			redirectionLink: "/piper-academy/modifier-groups",
			redirectionLinkText: "Learn more about Modifier Groups",
			size: "medium"
		};

		return (
			<div className="modifier-groups-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={filterOptions}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
					/>
				)}
				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
					searchKeywords={modifierGroupsList.data.searchKeywords}
					searchFieldSelected={modifierGroupsList.data.searchFieldSelected}
					searchFieldValue={modifierGroupsList.data.searchFieldValue}
					handleSearchField={this.handleSearchField}
					setFilter={this.setSearchFilter}
					applySearchFilter={this.applySearchFilter}
					handlePiperAcademy={this.handlePiperAcademy}
					isCatalogueManagement={access.isCatalogueManagement}
				/>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={this.state.formTab}
					switchTab={(tab) => this.handleFormTab(tab)}
					archived={this.state.archivedFilter ? "archived" : ""}
					isStickyOnTop={true}
				/>
				<CommonTable
					loading={modifierGroupsList.loading}
					data={modifierGroupsList.data.objects || []}
					columns={
						isMenuOverCatalogueEnabled
							? columns.filter((tab) => !["associated-items", "modifiers"].includes(tab.field))
							: columns
					}
					sortList={this.sortList}
					sortedField={sortedField}
					currencySymbol={this.props.biz.currencySymbol}
					archived={this.state.archivedFilter ? "archived" : ""}
					classes="modifier-groups-list-table-container"
					content="Modifier Groups"
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={modifierGroupsList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		);
	}
}
