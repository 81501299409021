import React from "react";

// components
import { Button } from "../../components/_commons/Button";

// third-party
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const MerakiWebLocked = ({ merakiWebSubscription }) => {
	if (merakiWebSubscription.loading) {
		return (
			<div className="section-container-common P(25px) H(255px)">
				<div className="P(10px 0)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			</div>
		);
	}

	return (
		<div className="meraki-web-locked section-container-common">
			<div className="meraki-web-info-container">
				<div className="intro-container">
					<div className="header">
						<img src="/assets/icons/icon-lock.svg" alt="" />
						<span>Design a beautiful online store</span>
					</div>
					<div className="description">
						The theme editor is the tool that will let you customize the contents and appearance your
						website, and preview these changes in real time.
					</div>
					<div className="actions">
						<Link to="/meraki-plans">
							<Button classes="subscribe">Subscribe to a Plan</Button>
						</Link>
						<a href="https://www.urbanpiper.com/contact" target="_blank">
							<Button type="secondary">Get in touch</Button>
						</a>
					</div>
				</div>
				<div className="meraki-frodo-demo-container">
					<img src="/assets/demo-frodo.png" alt="" />
				</div>
			</div>
			<div className="meraki-web-features-container">
				<div className="feature">
					<img src="/assets/icons/icon-brush.svg" alt="" />
					<div className="info">
						<div className="header">Customise your website</div>
						<div className="description">
							Have a complete control over the look and feel of your website, and make it look exactly how
							you want. At anytime you can sync your changes to your live store and test your new design.
						</div>
					</div>
				</div>
				<div className="feature">
					<img src="/assets/icons/icon-version.svg" alt="" />
					<div className="info">
						<div className="header">Version control</div>
						<div className="description">
							When you publish your changes, we create a new version for your Website. At anytime you can
							jump back to a previous version.
						</div>
					</div>
				</div>
			</div>
			<div className="meraki-web-features-container">
				<div className="feature">
					<img src="/assets/icons/icon-language.svg" alt="" />
					<div className="info">
						<div className="header">Sell in multiple languages</div>
						<div className="description">
							Customers all over the world enjoy viewing content in their native languages. Using the
							Meraki editor, you can transalate your website's content in any language.
						</div>
					</div>
				</div>
				<div className="feature">
					<img src="/assets/icons/icon-apps.svg" alt="" />
					<div className="info">
						<div className="header">Connect your favourite apps</div>
						<div className="description">
							Meraki editor allows you to connect your website to various apps like Google/Facebook Social
							login, Mixpanel, Google Analytics, Freshchat, etc.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	merakiWebSubscription: store.merakiWebSubscription
});
export default connect(mapStateToProps)(MerakiWebLocked);
