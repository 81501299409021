import React from "react";

// components
import { chartTooltip } from "./Tooltip";
import Loader from "./Loader";

// third party
import { ResponsivePie } from "@nivo/pie";

// utils
import { commifyNumbers, formatValueWithCurrency } from "../../atlas-utils";

// constants
import { ANALYTICS_DEFAULT_COLORS } from "../../client-config";

const Pie = ({
	height = 450,
	data = [],
	loading = false,
	colors,
	idKey = "id",
	valueKey = "value",
	marginTop = 40,
	marginBottom = 100,
	marginLeft = 80,
	marginRight = 80,
	startAngle = 0,
	endAngle = 360,
	innerRadius = 0.76,
	padAngle = 0,
	cornerRadius = 0,
	sortByValue = true,
	borderWidth = 1,
	activeOuterRadiusOffset = 8,
	enableArcLabels = false,
	arcLabel = "id",
	arcLabelsSkipAngle = 5,
	enableArcLinkLabels = true,
	arcLinkLabel = "label",
	commifyArcLinkLabel = false,
	arcLinkLabelsSkipAngle = 8,
	arcLinkLabelsDiagonalLength = 15,
	arcLinkLabelsStraightLength = 80,
	arcLinkLabelsThickness = 2,
	arcLinkLabelsTextColor = "#646464",
	enableLegends = true,
	legendItemWidth = 100,
	enableCenterMetric = false,
	renderCenterMetricLabel,
	renderCenterMetricValue,
	currency,
	currencySymbol,
	units = "",
	placeholder = "No data available"
}) => {
	const centeredMetric = (props) => {
		const { centerX, centerY } = props;
		return (
			<text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="central" className="center-metric-pie">
				<tspan x={centerX} dy="-1.2em" className="metric-label">
					{renderCenterMetricLabel(props)}
				</tspan>
				<tspan x={centerX} dy="1.1em" className="metric-value">
					{renderCenterMetricValue(props)}
				</tspan>
			</text>
		);
	};

	return (
		<div className={"nivo-chart-pie" + (data.length > 0 && loading ? " disabled" : "")} style={{ height: height }}>
			{data.length > 0 && (
				<ResponsivePie
					data={data}
					colors={colors?.length ? colors : ANALYTICS_DEFAULT_COLORS}
					id={idKey}
					value={valueKey}
					margin={{
						top: marginTop,
						right: marginRight,
						bottom: marginBottom,
						bottom: enableLegends ? marginBottom : marginBottom - 60,
						left: marginLeft
					}}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={innerRadius}
					padAngle={padAngle}
					cornerRadius={cornerRadius}
					sortByValue={sortByValue}
					activeOuterRadiusOffset={activeOuterRadiusOffset}
					borderWidth={borderWidth}
					borderColor={{
						from: "color",
						modifiers: [["darker", 0.2]]
					}}
					enableArcLabels={enableArcLabels}
					arcLabel={arcLabel}
					arcLabelsSkipAngle={arcLabelsSkipAngle}
					arcLabelsTextColor={{
						from: "color",
						modifiers: [["darker", 2]]
					}}
					enableArcLinkLabels={enableArcLinkLabels}
					// arcLinkLabel={(props) => `${currency ? formatValueWithCurrency(props[arcLinkLabel], currency) : commifyArcLinkLabel ? commifyNumbers(props[arcLinkLabel]) : props[arcLinkLabel]}${units} ${props.data?.percent ? `(${props.data?.percent})` : ''}`}
					arcLinkLabel={(props) => (
						<tspan dominantBaseline="auto">
							{currency && (
								<tspan style={{ fontSize: "14px", fill: "#8A8A8A" }}>
									{formatValueWithCurrency(props[arcLinkLabel], currency)}
								</tspan>
							)}
							<tspan style={{ fontSize: "14px", fill: "#8A8A8A" }}>
								{commifyArcLinkLabel ? commifyNumbers(props[arcLinkLabel]) : props[arcLinkLabel]}
								{units || ""}
							</tspan>
							{props.data?.percent && (
								<tspan
									dx="0.5em"
									dy="-0.05em"
									style={{ fontSize: "18px", fontWeight: "bold", fill: "#646464" }}
								>
									{props.data?.percent}
								</tspan>
							)}
						</tspan>
					)}
					arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
					arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
					arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
					arcLinkLabelsThickness={arcLinkLabelsThickness}
					// arcLinkLabelsTextColor={arcLinkLabelsTextColor}
					arcLinkLabelsColor={{ from: "color" }}
					tooltip={(props) => chartTooltip({ ...props, currencySymbol, units }, "pie")}
					legends={
						enableLegends
							? [
									{
										anchor: "bottom",
										direction: "row",
										justify: false,
										translateX: 0,
										translateY: 80,
										itemsSpacing: 10,
										itemWidth: legendItemWidth,
										itemHeight: 18,
										itemDirection: "left-to-right",
										itemOpacity: 1,
										symbolSize: 18,
										symbolShape: "circle"
									}
							  ]
							: []
					}
					theme={{
						axis: {
							ticks: {
								text: {
									fontSize: 12
								}
							}
						},
						// arc link label
						// labels: {
						// 	text: {
						// 		fontSize: 16,
						// 		fontWeight: "bold",
						// 	}
						// },
						legends: {
							// legends info of pie
							text: {
								fontSize: 12
							}
						}
					}}
					layers={["arcs", "arcLinkLabels", "arcLabels", "legends", enableCenterMetric ? centeredMetric : ""]}
				/>
			)}
			{data.length === 0 && loading && <Loader />}
			{data.length === 0 && !loading && <div className="no-items-placeholder">{placeholder}</div>}
		</div>
	);
};
export default Pie;
