import React, { useEffect, useCallback, useRef } from "react";

// third party
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTrail, config, animated } from "react-spring";
import moment from "moment";

// actions
import { fetchCustomerTimeline, updateTimelineListState } from "../../actions/customers";

// utils
import { scroll } from "../../atlas-utils";

// components
import { Paginator } from "../_commons/Paginator";

// constants
import {
	CUSTOMER_TIMELINE_HEADER_FIELDS,
	CUSTOMER_TIMELINE_EVENTS_MAP,
	NESTED_ENTITY_TYPES
} from "../../client-config";

const Timeline = ({ customerTimeline, customerId, handleNestedEntity }) => {
	const { loading, limit, offset, data } = customerTimeline;
	const tableRef = useRef();

	useEffect(() => {
		fetchCustomerTimeline(customerId);
	}, [customerId]);

	const handlePagination = useCallback(
		(page) => {
			const offset = (page - 1) * limit;
			updateTimelineListState({
				offset
			});
			fetchCustomerTimeline(customerId);
			// scroll to top of the list
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit, customerId]
	);

	return (
		<div ref={tableRef} className="customer-timeline customer-details-tab">
			<div className="subtitle">
				Browse through the complete timeline of interactions with the customer from the time they signed up.
			</div>
			<Table data={data.objects || []} loading={loading} handleNestedEntity={handleNestedEntity} />
			<Paginator limit={limit} offset={offset} count={data.count || 0} goToPage={handlePagination} />
		</div>
	);
};
export default withRouter(
	connect((store) => ({
		customerTimeline: store.customerTimeline
	}))(Timeline)
);

export const Table = ({ data, loading, handleNestedEntity }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") + " transaction-table-holder common-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleNestedEntity={handleNestedEntity}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No timeline history!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = () => {
	return (
		<div className="at-table-row transaction-header-row customer-timeline-table">
			{CUSTOMER_TIMELINE_HEADER_FIELDS.map((field, i) => {
				return (
					<div key={i} className={`at-table-cell at-table-header at-header-text ${field.value}`}>
						<span>{field.label}</span>
					</div>
				);
			})}
		</div>
	);
};

const TableList = ({ event, title, description, created, relatedObjId, handleNestedEntity, style }) => {
	const eventTitle = CUSTOMER_TIMELINE_EVENTS_MAP[event] || event;
	let isLinkable = false;
	let linkPath = "";
	let orderType = "";

	if (relatedObjId && ["ORDER_PLACED", "CUSTOMER_PURCHASE"].indexOf(event) > -1) {
		isLinkable = true;
		linkPath = event === "ORDER_PLACED" ? `/orders/online/${relatedObjId}` : `/orders/in-store/${relatedObjId}`;
		orderType = event === "ORDER_PLACED" ? "online" : "in-store";
	}

	return (
		<animated.div style={style} className="at-table-row transaction-rows customer-timeline-table">
			<div className="at-table-cell at-cell-text event" title={eventTitle}>
				{isLinkable ? (
					<a
						role="button"
						className="link-text"
						onClick={(e) => {
							handleNestedEntity(e, NESTED_ENTITY_TYPES[11], relatedObjId, orderType);
						}}
					>
						{eventTitle}
					</a>
				) : (
					<div>{eventTitle || "--"}</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text created">
				<div className="date-date">{moment(created).format("DD MMM, YYYY")}</div>
				<div className="date-time">{moment(created).format("hh:mm A")}</div>
			</div>
			<div className="at-table-cell at-cell-text notes" title={title || ""}>
				<div>{title || "--"}</div>
				<div className="comments">{description || ""}</div>
			</div>
		</animated.div>
	);
};
