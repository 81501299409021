import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/CouponEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";

// actions
import { fetchCouponsList } from "../actions/coupons";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_COUPON, GET_COUPON_TYPES } from "../graphql/coupons";

const CouponCreate = () => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [couponTypes, setCouponTypes] = useState([]);
	const [data, setData] = useState({
		rewardTitle: "",
		rewardDesc: "",
		rewardType: null,
		rewardValue: 0,
		redemptionCode: "",
		isSuggested: false,
		allowMultiUse: true,
		startsOn: "",
		expiresOn: "",
		merchantRefId: ""
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchCouponTypes();
	}, []);

	const handleCancel = () => {
		setFormState(false);
		fetchCouponsList();
		setTimeout(() => {
			history.push("/coupons");
		}, 100);
	};

	const fetchCouponTypes = async () => {
		try {
			const resp = await client.query({
				query: GET_COUPON_TYPES,
				fetchPolicy: "no-cache"
			});
			setCouponTypes(resp.data.couponTypes);
			setData({
				...data,
				rewardType: resp.data.couponTypes[0] ? resp.data.couponTypes[0] : null
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			if (variables.rewardType) {
				variables.rewardType = variables.rewardType.value;
			}
			if (variables.startsOn) {
				variables.startsOn = variables.startsOn.split(" ").join("T");
			}
			if (variables.expiresOn) {
				variables.expiresOn = variables.expiresOn.split(" ").join("T");
			}
			if (variables.merchantRefId === "") {
				variables.merchantRefId = "-1";
			}
			const resp = await client.mutate({
				mutation: CREATE_COUPON,
				variables
			});
			if (resp.data.saveCoupon.status.success) {
				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Coupon created",
						timeout: 5000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_COUPONS_LIST,
					payload: resp.data.saveCoupon.object
				});
				history.push(`/coupons/edit/${resp.data.saveCoupon.object.id}`);
			} else {
				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveCoupon.status.messages));
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Coupon"
			subTitle="Create a new coupon"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<BasicInfo
					data={data}
					handleForm={handleForm}
					validations={error.fields || {}}
					couponTypes={couponTypes}
				/>
			</div>
		</FormSidebar>
	);
};
export default CouponCreate;
