import React, { Component } from "react";

// components
import { Header } from "../components/APIAccountsList/Header";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { CommonTable } from "../components/_commons/CommonTable";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";
import moment from "moment";

// actions
import { fetchAPIAccountsList } from "../actions/apiAccounts";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll } from "../atlas-utils";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";

const columns = [
	{
		name: "Name",
		field: "name",
		sortKey: "full_name",
		render: (record, i, arch, curSym, extractInitials) => (
			<div className={"at-table-cell at-cell-text name"} title={record.fullName} key={i}>
				<div className="name-initials">
					<span>
						{record.fullName || record.firstName
							? extractInitials((record.fullName || record.firstName).split(" "))
							: extractInitials(record.username.split(" "))}
					</span>
				</div>
				<div className="name-desc">
					<Link className={"link-text"} to={`/api-access/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">
							{record.fullName || record.firstName || record.id}
						</span>
					</Link>
					<div className="text--light desc-text">{record.username || ""}</div>
				</div>
			</div>
		)
	},
	{
		name: "Roles",
		field: "roles",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text roles"} key={i}>
				{record.groups.length > 0 && (
					<div className="tags-container table-mode">
						<div className="list">
							{record.groups.map((role, i) => (
								<span key={i} className="tag-item" title={role.name}>
									{role.name}
								</span>
							))}
						</div>
					</div>
				)}
			</div>
		)
	},
	{
		name: "Created on",
		field: "created",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text created"} key={i}>
				{record.dateJoined ? moment(record.dateJoined).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	}
];

@connect((store) => ({
	apiAccountsList: store.apiAccountsList,
	apiAccountsListState: store.apiAccountsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems
}))
export class APIAccountsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			showFilters: false,
			search: ""
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "api_accounts_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch API Accounts list
		await fetchAPIAccountsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	}

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updateapiAccountsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.API_ACCOUNTS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateapiAccountsListState({
			currentFilters: this.props.apiAccountsListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.apiAccountsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateapiAccountsListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.apiAccountsListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updateapiAccountsListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateapiAccountsListState({
			appliedFilters: {
				...this.props.apiAccountsListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "api_accounts_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchAPIAccountsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}

		const { appliedFilters } = store.getState().apiAccountsListState;
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updateapiAccountsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchAPIAccountsList();
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.apiAccountsListState;
		const offset = (page - 1) * limit;
		this.updateapiAccountsListState({
			offset
		});
		// fetch new Timing Groups list
		fetchAPIAccountsList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.apiAccountsListState;
		if (size && size?.value !== limit) {
			this.updateapiAccountsListState({
				[field]: size.value
			});
			// fetch new Timing Groups list
			await fetchAPIAccountsList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateapiAccountsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.API_ACCOUNTS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchAPIAccountsList();
	};

	handleActiveFilter = (field, value) => {
		this.updateapiAccountsListState({
			isActive: value?.value
		});
		fetchAPIAccountsList();
	};

	extractInitials = (name) => {
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(name[0].length - 1);
		}
		return initials.toUpperCase();
	};

	render() {
		const { apiAccountsList, apiAccountsListState, configItems } = this.props;
		const { limit, offset, currentFilters, sortedField, isActive } = apiAccountsListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "search") {
				if (currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}

		const filterOptions = apiAccountsList.data.filters.filter((f) => f.field !== "search");
		const searchFilterOption = apiAccountsList.data.filters.find((fl) => fl.field === "search");

		return (
			<div>
				<div ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						filterOption={searchFilterOption}
						setFilter={this.setSearchFilter}
						value={this.state.search}
						handleActiveFilter={this.handleActiveFilter}
						isActive={isActive}
					/>
					<CommonTable
						loading={apiAccountsList.loading}
						data={apiAccountsList.data.objects || []}
						columns={columns}
						handleTask={this.extractInitials}
						sortList={this.sortList}
						sortedField={sortedField}
						classes="api-accounts-list-table-container"
						content="API accounts"
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={apiAccountsList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
				</div>
			</div>
		);
	}
}
