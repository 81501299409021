import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { DiscountsList } from "./DiscountsList";
import DiscountCreate from "./DiscountCreate";
import DiscountEdit from "./DiscountEdit";

const DiscountsContainer = () => {
	return (
		<div className="discounts-container">
			<Route path="/discounts" component={DiscountsList} />
			<Route exact path="/discounts/new" component={DiscountCreate} />
			<Route exact path="/discounts/edit/:id" component={DiscountEdit} />
		</div>
	);
};
export default DiscountsContainer;
