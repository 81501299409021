import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { APIRolesList } from "./APIRolesList";
import APIRoleEdit from "./APIRoleEdit";

const APIRolesContainer = () => {
	return (
		<div className="api-access-management roles">
			<Route path="/api-access" component={APIRolesList} />
			<Route exact path="/api-access/edit/:id" component={APIRoleEdit} />
		</div>
	);
};
export default APIRolesContainer;
