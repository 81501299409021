import React from "react";
import { Textarea } from "../_commons/Textarea";
import { InputWithLabel } from "../_commons/InputWithLabel";

const BasicDetails = ({ data, validations, readOnly, handleForm }) => {
	return (
		<div className="form-content">
			<div className="form-row row-half">
				<InputWithLabel
					value={data.title}
					onChange={(e) => handleForm("title", e.target.value)}
					validationMessage={validations.title || ""}
					readOnly={readOnly}
				>
					Name
				</InputWithLabel>
				<InputWithLabel
					value={data.crmTitle}
					onChange={(e) => handleForm("crmTitle", e.target.value)}
					readOnly={readOnly}
					tooltipInfo="This is an internal reference name and will not reflect on the ordering platform."
					showCustomTooltip
				>
					Internal Name
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={data.description}
					onChange={(e) => handleForm("description", e.target.value)}
					readOnly={readOnly}
				>
					Description
				</Textarea>
			</div>
		</div>
	);
};

export default BasicDetails;
