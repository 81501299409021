import React from "react";
import Bar from "../../components/NivoCharts/Bar";
import RowData from "../../components/Analytics/Compare/TableData/RowData";
import BreakdownInfo from "../../components/Analytics/Compare/TableData/BreakdownInfo";
import MultiLineBar from "../../components/Analytics/Compare/TableData/MultiLineBar";
import ItemStats from "../../components/Analytics/Compare/TableData/ItemStats";
import { store } from "../../store/configureStore";
import ProgressBar from "../../components/Analytics/Compare/TableData/ProgressBar";
import { commifyNumbers } from "../../atlas-utils";
import { roundAndFormatNumber } from "../../helpers/analytics";

export const metric = [
	"totalRevenue",
	"lostRevenue",
	"avgRevenuePerDay",
	"avgOrderValue",
	"totalOrders",
	"completedVsLost_Orders",
	"lostOrders",
	"avgOrdersPerDay",
	"bestSellingItems",
	"bestPerformingLocations",
	"worstSellingItems",
	"worstPerformingLocations",
	"orderCompletionTime",
	"locationAvailability",
	"itemAvailability"
];

export const metricContainer = {
	totalRevenue: {
		component: (props) => <TotalRevenue {...props} />,
		name: "Total Revenue",
		value: "",
		location: true,
		brand: true,
		platform: true,
		item: true
	},
	lostRevenue: {
		component: (props) => <LostRevenue {...props} />,
		name: "Lost Revenue",
		location: true,
		brand: true,
		platform: true,
		item: true
	},
	avgRevenuePerDay: {
		component: (props) => <AverageRevenuePerDay {...props} />,
		name: "Avg Revenue / Day",
		location: true,
		brand: true,
		platform: true,
		item: true
	},
	avgOrderValue: {
		component: (props) => <AvgOrderValue {...props} />,
		name: "Avg Order Value",
		location: true,
		brand: true,
		platform: true,
		item: true
	},
	totalOrders: {
		component: (props) => <TotalOrders {...props} />,
		name: "Total Orders",
		location: true,
		brand: true,
		platform: true,
		item: true
	},
	completedVsLost_Orders: {
		component: (props) => <CompletedVsLostOrders {...props} />,
		name: "Completed vs Lost Orders",
		location: true,
		brand: true,
		platform: true,
		item: true
	},
	lostOrders: {
		component: (props) => <LostOrders {...props} />,
		name: "Lost Orders",
		location: true,
		brand: true,
		platform: true,
		item: true
	},
	avgOrdersPerDay: {
		component: (props) => <AvgOrdersPerDay {...props} />,
		name: "Avg Orders / Day",
		location: true,
		brand: true,
		platform: true,
		item: true
	},

	bestSellingItems: {
		component: (props) => <BestSellingItems {...props} />,
		name: "Best Selling Items",
		location: true,
		brand: true,
		platform: true,
		item: false
	},

	bestPerformingLocations: {
		component: (props) => <BestPerformingLocations {...props} />,
		name: "Best performing locations",
		location: false,
		brand: false,
		platform: false,
		item: true
	},
	worstSellingItems: {
		component: (props) => <WorstSellingItems {...props} />,
		name: "Worst Selling Items",
		location: true,
		brand: true,
		platform: true,
		item: false
	},
	worstPerformingLocations: {
		component: (props) => <WorstPerformingLocations {...props} />,
		name: "Worst Performing Locations",
		location: false,
		brand: false,
		platform: false,
		item: true
	},
	orderCompletionTime: {
		component: (props) => <OrderCompletionTime {...props} />,
		name: "Order Completion Time",
		location: true,
		brand: true,
		platform: true,
		item: false
	},
	locationAvailability: {
		component: (props) => <LocationAvailability {...props} />,
		name: "Location Availability",
		location: true,
		brand: true,
		platform: true,
		item: false
	},
	itemAvailability: {
		component: (props) => <ItemAvailability {...props} />,
		name: "Item availability",
		location: false,
		brand: false,
		platform: false,
		item: true
	}
};
export const LostRevenue = (props) => {
	return (
		<>
			<BreakdownInfo
				rowTitle={props?.rowTitle}
				currIndex={props?.currIndex}
				metric={props?.metric}
				currency
				columnDataWidth={props?.columnDataWidth}
			/>
		</>
	);
};

export const TotalRevenue = (props) => {
	return (
		<>
			<BreakdownInfo
				rowTitle={props?.rowTitle}
				currIndex={props?.currIndex}
				metric={props?.metric}
				currency
				columnDataWidth={props?.columnDataWidth}
			/>
		</>
	);
};

export const TotalOrders = (props) => {
	return (
		<>
			<BreakdownInfo
				rowTitle={props?.rowTitle}
				currIndex={props?.currIndex}
				metric={props?.metric}
				columnDataWidth={props?.columnDataWidth}
			/>
		</>
	);
};

const CompletedVsLostOrders = (props) => {
	return (
		<div>
			<MultiLineBar
				rowTitle={props?.rowTitle}
				currIndex={props?.currIndex}
				metric={props?.metric}
				columnDataWidth={props?.columnDataWidth}
			/>
		</div>
	);
};

const LostOrders = (props) => {
	return (
		<>
			<BreakdownInfo
				rowTitle={props?.rowTitle}
				currIndex={props?.currIndex}
				metric={props?.metric}
				columnDataWidth={props?.columnDataWidth}
			/>
		</>
	);
};

const AvgOrdersPerDay = (props) => {
	return <RowData currency={false} rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} />;
};
const WorstPerformingLocations = (props) => {
	return <ItemStats rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} status="worst" />;
};

const BestSellingItems = (props) => {
	return <ItemStats rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} status={"best"} />;
};

const WorstSellingItems = (props) => {
	return (
		<ItemStats rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} status={"worst"} />
	);
};

const BestPerformingLocations = (props) => {
	return <ItemStats rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} status={"best"} />;
};

const OrderCompletionTime = (props) => {
	const compareAnalytics = store.getState()?.compareAnalytics;
	const currentData = compareAnalytics.columnData?.[props?.metric].data?.[props?.currIndex];
	const tableData = compareAnalytics.tableData?.[props?.metric]?.[currentData?.valueForDisplay];
	const data = tableData?.[props?.rowTitle];

	const totalSum =
		(data?.objects || []).length > 0
			? data?.objects.reduce((acc, sum) => {
					return acc + (sum?.value || 0);
			  }, 0)
			: 0;
	const suffix = totalSum <= 1 ? "min" : "mins";
	const convertValue = (val) => {
		if (val >= 1) {
			return commifyNumbers(roundAndFormatNumber(val, 0));
		} else {
			return commifyNumbers(roundAndFormatNumber(val * 60, 0));
		}
	};
	const getSuffix = (val) => {
		return val > 1 ? "mins" : val === 1 ? "min" : "sec";
	};
	return (
		<BreakdownInfo
			currency={false}
			rowTitle={props?.rowTitle}
			currIndex={props?.currIndex}
			metric={props?.metric}
			columnDataWidth={props?.columnDataWidth}
			displayText={"Total Time"}
			suffix={suffix}
			space={true}
			roundOff={1}
			getSuffix={getSuffix}
			changeValueTo={convertValue}
		/>
	);
};

const LocationAvailability = (props) => {
	return <ProgressBar rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} />;
};

const ItemAvailability = (props) => {
	return <ProgressBar rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} />;
};

export const AverageRevenuePerDay = (props) => {
	return (
		<div>
			<RowData rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} currency />
		</div>
	);
};

export const AvgOrderValue = (props) => {
	return (
		<div>
			<RowData rowTitle={props?.rowTitle} currIndex={props?.currIndex} metric={props?.metric} currency />
		</div>
	);
};
