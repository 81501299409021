import React from "react";

// components
import AnalyticsGraph from "../Dashboard/AnalyticsGraph";

export const Trends = ({ data = {}, loading = false, error = false, appliedFilter = {} }) => {
	return (
		<div className="trends">
			<div className="sub-title">
				<div className="highlight">Trends</div>
			</div>
			{!error && data.current && data.current.length ? (
				<AnalyticsGraph
					duration={appliedFilter.dateFilter}
					anaGraphData={{ data: { ...data } }}
					yAxisLabel="NPS"
					margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
				/>
			) : loading ? (
				<div className="no-items-placeholder">Fetching data!</div>
			) : (
				<div className="no-items-placeholder">No data available!</div>
			)}
		</div>
	);
};
