import React from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { SelectFilter } from "../_commons/SelectFilter";
import CreateIcon from "../_commons/CreateIcon";
import BulkActionIcon from "../_commons/BulkActionIcon";
import HelpIcon from "../_commons/HelpIcon";

// utils
import { extractInitials } from "../../atlas-utils";

export const Header = (props) => {
	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="header-section">
					<div className="title">Categories</div>
					<div className="subtitle">Manage your store categories</div>
				</div>
				<div className="action-buttons">
					{props.isCatalogueManagement && (
						<div className="bulk-actions-container">
							<Link to="/bulk/categories">
								<Button type="secondary">
									<BulkActionIcon />
									<span>Bulk Actions</span>
								</Button>
							</Link>
						</div>
					)}
					{props.isCatalogueManagement && (
						<div className="action-buttons-container">
							<Link to="/categories/new">
								<Button>
									<CreateIcon />
									<span>Create New</span>
								</Button>
							</Link>
						</div>
					)}
				</div>
			</div>
			{!props.hideHeaderSectionRight && (
				<div className="header-action-button">
					<div className="filter-buttons">
						{props.isMultibrandEnabled && (
							<SelectFilterCustom
								options={props.brands.items}
								isLoading={props.brands.isLoading}
								field="brands"
								currValue={props.brands.selectedBrand}
								setFilter={(f, value) => props.handleBrand(value)}
								labelKey="name"
								valueKey="id"
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								isClearable={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
							/>
						)}
						{/* {
							props.dimensions.width > 768 &&
							<div className={(props.filterActive ? "active":"")+" filter-in-header campaign-list-filter"}>
								<div className="container" onClick={props.flipShowFilters}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt=""/>
									<div className="filter-title">
										Filter
										{
											props.filterCount > 0 &&
											<span className="filter-count">{props.filterCount}</span>
										}
									</div>
								</div>
							</div>
						} */}
						{/* <div className="help-btn-container">
							<Button
								clickHandler={props.handlePiperAcademy}
								type="secondary"
							>
								<HelpIcon />
								<span>
									Help
								</span>
							</Button>
						</div> */}
					</div>
					<div className="filter-section-2">
						<SelectFilter
							options={[
								{ value: false, valueForDisplay: "Active" },
								{ value: true, valueForDisplay: <span style={{ color: "#ff425c" }}>Archived</span> }
							]}
							field={"is_active"}
							currValue={{
								value: "active",
								valueForDisplay: (
									<span>
										<span style={{ color: "#979797" }}>Status </span>
										{props.isActive ? "Active" : <span style={{ color: "#ff425c" }}>Archived</span>}
									</span>
								)
							}}
							labelKey="valueForDisplay"
							valueKey="value"
							setFilter={(field, value) => {
								props.setFilter(field, value.value);
							}}
							isSearchable={false}
							isClearable={false}
						/>
						{props.dimensions.width > 768 && (
							<SearchFilter
								filterOption={props.filterOption}
								value={props.value}
								setFilter={props.setFilter}
								placeholder="Search by name"
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
