import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { TransactionsList } from "./TransactionsList";
import { TransactionDetails } from "./TransactionDetails";

const TransactionsContainer = () => {
	return (
		<React.Fragment>
			<Route path="/orders" component={TransactionsList} />
			<Route exact path="/orders/:type/:id" component={TransactionDetails} />
		</React.Fragment>
	);
};
export default TransactionsContainer;
