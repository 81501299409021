// utils
import { getSortOrder } from "../atlas-utils";

// action types
import { ActionTypes } from "../actions/_types";

const INITIAL_DATE_FILTER_STATE = {
	starts_on: {
		dateFilter: "",
		dateTypeSelected: { label: "Presets", value: "preset_duration" },
		presetTypeSelected: { label: "All time", value: "" },
		customTypeSelected: { label: "Is between", value: "between" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	expires_on: {
		dateFilter: "",
		dateTypeSelected: { label: "Presets", value: "preset_duration" },
		presetTypeSelected: { label: "All time", value: "" },
		customTypeSelected: { label: "Is between", value: "between" },
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "title",
		order: "ASC"
	},
	sortedField: "title",
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE,
	currentFilters: {},
	appliedFilters: {}
};

export const couponsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.COUPONS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.COUPONS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.COUPONS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const COUPONS_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: []
	},
	error: false
};
export const couponsList = (state = COUPONS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_COUPONS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_COUPONS_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				loading: false
			};
		case ActionTypes.GET_COUPONS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_COUPONS_LIST:
			let foundInList = false;
			const updatedObjects = state.data.objects.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedObjects.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedObjects,
					count: state.data.count + 1
				}
			};
		default:
			return state;
	}
};

export const COUPON_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	error: {}
};
export const couponDetailsReducer = (state = COUPON_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_COUPON_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_COUPON_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_COUPON_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_COUPON_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_COUPON_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_COUPON_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_COUPON_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_COUPON_DETAIL_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
