// action types
import { ActionTypes } from "../actions/_types";

// utils
import { generateUniqueId } from "../atlas-utils";

const GLOBAL_NOTIFICATIONS_STATE = {
	notificationsContent: []
};
export const globalNotificationsState = (state = GLOBAL_NOTIFICATIONS_STATE, action) => {
	switch (action.type) {
		case ActionTypes.PUSH_NOTIFICATIONS:
			return {
				...state,
				notificationsContent: [
					...state.notificationsContent,
					{
						message: action.payload.message,
						timeout: action.payload.timeout || 5000,
						isClosable: action.payload.isClosable || false,
						type: action.payload.type || "info",
						id: generateUniqueId(),
						show: true,
						title: action.payload.title || "",
						theme: action.payload.theme || "dark"
					}
				]
			};
		case ActionTypes.FLUSH_NOTIFICATIONS:
			return {
				...state,
				notificationsContent: [
					...state.notificationsContent.map((content) =>
						content.id === action.payload.id ? { ...content, show: false } : { ...content }
					)
				]
			};
		case ActionTypes.REFRESH_NOTIFICATIONS_CONTENT:
			return {
				...state,
				notificationsContent: []
			};
		default:
			return state;
	}
};
