import React from "react";
import { Route, Redirect } from "react-router-dom";

// component
import BrandsList from "./BrandsList";
import BrandsEdit from "./BrandsEdit";
import BrandsCreate from "./BrandsCreate";
import BackupStatus from "../components/_commons/BackupStatus";
import Header from "../components/BrandsList/Header";

const Brands = ({ hasAccess = false, restrictView = false }) => {
	return (
		<React.Fragment>
			{restrictView ? (
				<Route
					path="/brands"
					render={() => <BackupStatus header={<Header hideHeaderSectionRight={true} />} />}
				/>
			) : (
				<React.Fragment>
					<Route
						exact={window?.location?.pathname === "/brands/new"}
						path="/brands"
						render={() => <BrandsList />}
					/>
					<Route
						exact
						path="/brands/new"
						render={() => (hasAccess ? <BrandsCreate hasAccess={hasAccess} /> : <Redirect to="/brands" />)}
					/>
					<Route exact path="/brands/edit/:id" component={BrandsEdit} />
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
export default Brands;
