import React from "react";

// component
import { SearchFilter } from "../_commons/SearchFilter";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<img src="/assets/icons/info.png" alt="" />
				<div className="subtitle">Assign roles for your API accounts here</div>
			</div>
			<div className="header-action-button">
				<div className="filter-buttons">
					{/* {
						props.dimensions.width > 768 &&
						<div className={(props.filterActive ? "active":"")+" filter-in-header campaign-list-filter"}>
							<div className="container" onClick={props.flipShowFilters}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt=""/>
								<div className="filter-title">
									Filter
									{
										props.filterCount > 0 &&
										<span className="filter-count">{props.filterCount}</span>
									}
								</div>
							</div>
						</div>
					} */}
					{/* {
						props.dimensions.width > 768 &&
						<SearchFilter
							filterOption={props.filterOption}
							value={props.value}
							setFilter={props.setFilter}
							placeholder="Search by name"
						/>
					} */}
				</div>
			</div>
		</div>
	);
};
