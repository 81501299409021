import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { SelectFilter } from "../_commons/SelectFilter";
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";
import { DateFilterDropdown } from "../_commons/DateFilterDropdown";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import ReportFilters from "./ReportFilters";

// utils
import { lS, trackEvent, getDateRangeFromPreset } from "../../atlas-utils";
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import moment from "moment";
import { debounce } from "lodash";
import { connect } from "react-redux";
import camelCase from "camelcase";

// graphql
import { GET_REPORT_FILTERS, GET_BRAND_LOCATIONS } from "../../graphql/reports";

// actions
import { ActionTypes } from "../../actions/_types";
import { generateReport } from "../../actions/reports";
import { fetchBrands } from "../../actions/actions";

// constant
import { PRESET_TYPES, DATE_TYPES, CUSTOM_TYPES } from "../_commons/NewDateCompareFilter";
const FILTERS_INITIAL_STATE = {
	stores: [],
	merakiChannels: [],
	hubChannels: [],
	orderStates: [],
	reportType: { label: "Daily", value: "daily" },
	timeMonthly: undefined,
	timeDaily: moment(),
	currentDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[1].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[1],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	},
	appliedDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[1].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[1],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	}
};

const ExportReport = ({
	isOpen = false,
	close,
	data = {},
	isNonHqReport,
	bizId,
	brands,
	associatedLocations = [],
	isMultibrandEnabled = false
}) => {
	const [isFormTouched, setFormTouched] = useState(false);
	const [reportFilters, setReportFilters] = useState({
		filters: []
	});
	const [currentFilters, setCurrentFilters] = useState(FILTERS_INITIAL_STATE);
	const [emailListFilter, setEmailListFilter] = useState(
		lS.get("report_emails") ? lS.get("report_emails")[bizId] || [] : []
	);
	const [currEmail, setCurrEmail] = useState("");
	const [validations, setValidations] = useState({
		email: "",
		brands: "",
		stores: "",
		startDate: ""
	});
	const [isCheckedAll, setIsCheckedAll] = useState({});
	const [optionUpdates, setOptionUpdates] = useState({});
	const [loading, setLoading] = useState(false);
	const [locationsList, setLocationsList] = useState([]);
	const [filteredBrands, setFilteredBrands] = useState([
		...new Set(associatedLocations?.map((loc) => loc?.brandName))
	]);
	const [filteredBrandLocations, setFilteredBrandLocations] = useState(associatedLocations?.map((loc) => loc?.id));
	const tagsRef = useRef();

	const fetchReportFilters = useCallback(async () => {
		try {
			setLoading(true);
			let resp = await client.query({
				query: GET_REPORT_FILTERS,
				fetchPolicy: "no-cache"
			});
			setReportFilters(resp.data.reportFilters);
			data.filters.forEach((f) => {
				const filter = resp.data.reportFilters.filters
					.filter((opt) => (isMultibrandEnabled ? opt.field !== "stores" : opt))
					.find((rf) => rf.field === f);
				const filterOptions = {};
				if (filter) {
					filter.values.forEach((v) => {
						filterOptions[v.value] = true;
					});
					setIsCheckedAll((isCheckedAll) => ({
						...isCheckedAll,
						[f.replace(/([-_]\w)/g, (f) => f[1].toUpperCase())]: f === "stores" ? !isNonHqReport : true
					}));
					setOptionUpdates((optionUpdates) => ({
						...optionUpdates,
						[f.replace(/([-_]\w)/g, (f) => f[1].toUpperCase())]: filterOptions
					}));
				}
			});
			setCurrentFilters((currentFilters) => ({
				...currentFilters,
				timeMonthly: resp.data.reportFilters.filters.find((f) => f.field === "months")
					? resp.data.reportFilters.filters.find((f) => f.field === "months").values[0]
					: undefined
			}));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [data]);

	useEffect(() => {
		if (isOpen) {
			setFormTouched(emailListFilter.length > 0);
			if (isMultibrandEnabled) {
				fetchBrands("", true);
			}
			fetchReportFilters();
		}
	}, [isOpen]);

	const fetchBrandLocations = useCallback(
		async (brandIds) => {
			try {
				const variables = {
					brandIds: Object.keys(brandIds).filter((id) => brandIds[id])
				};
				const resp = await client.query({
					query: GET_BRAND_LOCATIONS,
					variables
					// fetchPolicy: 'no-cache',
				});
				setLocationsList(
					resp.data.reportStoresList.map((location) => ({
						...location,
						bizLocationNickname: `${location.bizLocationNickname} (Brand: ${
							brands.items.find((brand) => brand.id === location.brandId)?.name || "--"
						})`
					}))
				);
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
			}
		},
		[brands]
	);

	const fetchBrandLocationsDebounced = useCallback(
		debounce((brandIds) => fetchBrandLocations(brandIds), 300),
		[fetchBrandLocations]
	);

	const handleClose = useCallback(
		(surveyRequired) => {
			// reset state before closing
			setLoading(false);
			setFormTouched(false);
			setReportFilters({
				filters: []
			});
			setCurrentFilters(FILTERS_INITIAL_STATE);
			setCurrEmail("");
			setValidations({
				email: "",
				brands: "",
				stores: "",
				startDate: ""
			});
			setEmailListFilter(lS.get("report_emails") ? lS.get("report_emails")[bizId] || [] : []);
			setIsCheckedAll({});
			setOptionUpdates({});
			close(true, surveyRequired);
		},
		[close]
	);

	const setFilter = (field, value) => {
		setCurrentFilters({
			...currentFilters,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const setDateRangeFilter = (payload) => {
		setCurrentFilters({
			...currentFilters,
			...payload
		});
		setValidations({
			...validations,
			startDate: ""
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const setEmailFilter = (tags) => {
		setEmailListFilter(tags);
		lS.set("report_emails", { ...lS.get("report_emails"), [bizId]: [...tags] });
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleTagInput = (val) => {
		setCurrEmail(val);
		if (validations?.email) {
			setValidations({
				email: "",
				brands: "",
				stores: "",
				startDate: ""
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCheck = (state, field, option, props) => {
		const updates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[option[props.valueKey]]: state
			}
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: false
		};
		if (field === "brands" && !state) {
			const updatedLocationsList = locationsList.filter((location) => {
				if (location.brandId === option[props.valueKey]) {
					updates.stores = {
						...(updates.stores ?? {}),
						[location.bizLocationId]: false
					};
				}
				return location.brandId !== option[props.valueKey];
			});
			isCheckedAllUpdates["stores"] = false;
			setLocationsList(updatedLocationsList);
		}
		setOptionUpdates(updates);
		setIsCheckedAll(isCheckedAllUpdates);
		if (!isFormTouched) {
			setFormTouched(true);
		}
		if (field === "brands" && state) {
			fetchBrandLocationsDebounced(updates["brands"]);
		}
		setValidations({
			...validations,
			[field]: ""
		});
	};

	const handleCheckAll = (state, field, options, props) => {
		const filterOptions = {};
		options.forEach((opt) => {
			filterOptions[opt[props.valueKey]] = state;
		});
		const updates = {
			...optionUpdates,
			[field]: filterOptions
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: state
		};
		if (field === "brands" && !state) {
			updates["stores"] = {};
			isCheckedAllUpdates["stores"] = state;
			setLocationsList([]);
		}
		setOptionUpdates(updates);
		setIsCheckedAll(isCheckedAllUpdates);
		if (!isFormTouched) {
			setFormTouched(true);
		}
		if (field === "brands" && state) {
			fetchBrandLocationsDebounced(filterOptions);
		}
		setValidations({
			...validations,
			[field]: ""
		});
	};

	const handleSubmit = async () => {
		const validationMessages = {};
		if (!currentFilters?.appliedDateFilter?.current?.dateFilter) {
			setValidations({ startDate: "This is a required field" });
			return;
		}
		if (isMultibrandEnabled && data?.filters?.includes("stores")) {
			if (
				!optionUpdates.brands ||
				Object.keys(optionUpdates.brands || {}).filter((id) => optionUpdates.brands[id] === true)?.length === 0
			) {
				validationMessages.brands = "This is a required field";
			}
			if (
				!optionUpdates.stores ||
				Object.keys(optionUpdates.stores || {}).filter((id) => optionUpdates.stores[id] === true)?.length === 0
			) {
				validationMessages.stores = "This is a required field";
			}
			if (validationMessages.brands || validationMessages.stores) {
				setValidations(validationMessages);
				return;
			}
		}
		if (emailListFilter.length > 0) {
			setLoading(true);
			const variables = {
				id: parseInt(data.id),
				emails: emailListFilter
			};
			const filters = {};
			Object.keys(optionUpdates)
				.filter((filter) => filter !== "brands")
				.forEach((filter) => {
					if (isMultibrandEnabled) {
						if (isCheckedAll[filter] && filter !== "stores") {
							filters[filter] = null;
						} else {
							const options = Object.keys(optionUpdates[filter]).filter(
								(opt) => optionUpdates[filter][opt] === true
							);
							filters[filter] = options;
						}
					} else {
						if (isCheckedAll[filter]) {
							filters[filter] = null;
						} else {
							const options = Object.keys(optionUpdates[filter]).filter(
								(opt) => optionUpdates[filter][opt] === true
							);
							filters[filter] = options;
						}
					}
				});
			const { appliedDateFilter } = currentFilters;
			if (appliedDateFilter?.current?.dateFilter) {
				if (appliedDateFilter?.current?.dateTypeSelected?.value === "preset_duration") {
					const { startDate, endDate } = getDateRangeFromPreset(appliedDateFilter?.current?.dateFilter);
					filters.startDate = startDate.format("YYYY-MM-DD");
					filters.endDate = endDate.format("YYYY-MM-DD");
				} else {
					const dateRange = appliedDateFilter?.current?.dateFilter?.split(",");
					filters.startDate = dateRange[0];
					filters.endDate = dateRange[1];
				}
			} else if (currentFilters?.reportType?.value === "daily") {
				filters.timeDaily = moment(currentFilters?.timeDaily).format("YYYY-MM-DD");
			} else if (currentFilters?.reportType?.value === "monthly") {
				filters.timeMonthly = currentFilters?.timeMonthly?.value;
			}
			variables.filters = filters;

			// track this event
			const eventMeta = {
				name: data.title,
				type: data.group
			};
			trackEvent("report_downloads", eventMeta);

			const resp = await generateReport(variables);
			if (resp?.success) {
				handleClose(true);
			} else if (resp?.messages?.length > 0) {
				const validations = {};
				resp.messages.forEach((msg) => {
					if (msg?.field) {
						validations[camelCase(msg.field)] = msg.message;
					}
				});
				setValidations(validations);
			}
			setLoading(false);
		} else {
			setValidations({
				...validations,
				email: "Atleast one email must be added"
			});
		}
	};

	return (
		<div className="export-report-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleSubmit}
				title={data.title || ""}
				subTitle={data.group || ""}
				submitTitle={emailListFilter.length > 1 ? "Send Emails" : "Send Email"}
				submitClass="send-email"
				loading={reportFilters.filters.length > 0 && loading}
				hideActions={!isFormTouched}
				isNested={false}
			>
				<div className="form-content">
					{(!loading || reportFilters.filters.length !== 0) && (
						<React.Fragment>
							<div className="form-fields-container row-half">
								<ReportFilters
									options={reportFilters.filters || []}
									filtersToDisplay={data.filters || []}
									setFilter={setFilter}
									currentFilters={currentFilters}
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									isCheckedAll={isCheckedAll}
									handleCheckAll={handleCheckAll}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							</div>
							{isMultibrandEnabled &&
								data?.filters?.includes("stores") &&
								reportFilters.filters.find((filter) => filter.field === "stores") && (
									<div className="form-fields-container row-half">
										<SelectFilterCustom
											title={"Brands"}
											options={
												brands.items?.filter(
													(brand) =>
														brand.id !== "all" &&
														(filteredBrands?.length > 0
															? filteredBrands?.includes(brand.name)
															: true)
												) || []
											}
											field={"brands"}
											currValue={currentFilters["brands"] || ""}
											setFilter={setFilter}
											labelKey={"name"}
											valueKey={"id"}
											iconKey={"image"}
											multi={true}
											showCheckBox={true}
											requiredLabel={true}
											showSelectAllOption={!isNonHqReport}
											showIcon={true}
											ellipsizedLength={40}
											optionUpdates={optionUpdates}
											handleCheck={handleCheck}
											isCheckedAll={isCheckedAll}
											handleCheckAll={handleCheckAll}
											placeholder={"Select Brands"}
											validationMessage={validations?.brands || ""}
										/>
										<SelectFilterCustom
											title={"Locations"}
											options={
												locationsList?.filter((loc) =>
													filteredBrandLocations?.length > 0
														? filteredBrandLocations?.includes(loc.bizLocationId)
														: loc
												) || []
											}
											field={"stores"}
											currValue={currentFilters["stores"] || ""}
											setFilter={setFilter}
											labelKey={"bizLocationNickname"}
											valueKey={"bizLocationId"}
											multi={true}
											showCheckBox={true}
											requiredLabel={true}
											showSelectAllOption={!isNonHqReport}
											ellipsizedLength={40}
											optionUpdates={optionUpdates}
											handleCheck={handleCheck}
											isCheckedAll={isCheckedAll}
											handleCheckAll={handleCheckAll}
											placeholder={"Select Locations"}
											validationMessage={validations?.stores || ""}
										/>
									</div>
								)}
							<div className="form-fields-container row-half">
								{/* <SelectFilter
									title="Report Type"
									options={[
										{ label: "Daily", value: "daily" },
										{ label: "Monthly", value: "monthly" }
									]}
									field="reportType"
									currValue={currentFilters.reportType}
									setFilter={setFilter}
									labelKey="label"
									valueKey="value"
									isClearable={false}
									isSearchable={false}
								/>
								{currentFilters?.reportType?.value === "daily" && (
									<DateFilterDropdown
										title="Date"
										field="timeDaily"
										currValue={currentFilters.timeDaily}
										setFilter={setFilter}
										minDate={
											reportFilters.allowedMonths
												? moment().subtract(reportFilters.allowedMonths, "months")
												: ""
										}
									/>
								)}
								{currentFilters?.reportType?.value === "monthly" && (
									<SelectFilter
										title="Month"
										options={
											reportFilters.filters.find((f) => f.field === "months")
												? reportFilters.filters.find((f) => f.field === "months").values
												: []
										}
										field="timeMonthly"
										currValue={currentFilters.timeMonthly}
										setFilter={setFilter}
										labelKey="valueForDisplay"
										valueKey="value"
										isClearable={false}
										isSearchable={false}
									/>
								)} */}
								{(data?.group?.toLowerCase() === "ordering" &&
									data?.title?.toLowerCase() === "menu snapshot across all stores") ||
								(data?.group?.toLowerCase() === "miscellaneous" &&
									data?.title?.toLowerCase() === "user signups") ? null : (
									<NewDateCompareFilter
										title="Date Range"
										requiredLabel={true}
										showDropdown={true}
										hideComparison={true}
										currentDateFilter={currentFilters?.currentDateFilter}
										appliedDateFilter={currentFilters?.appliedDateFilter}
										updateState={setDateRangeFilter}
										position={"left"}
										monthsShown={1}
										minDate={
											currentFilters?.currentDateFilter?.current?.rangeEndDate
												? moment(currentFilters?.currentDateFilter?.current?.rangeEndDate)
														.subtract(30, "days")
														.diff(
															moment().subtract(reportFilters.allowedMonths, "months"),
															"days"
														) >= 0
													? moment(
															currentFilters?.currentDateFilter?.current?.rangeEndDate
													  ).subtract(30, "days")
													: moment().subtract(12, "months")
												: reportFilters?.allowedMonths
												? moment().subtract(reportFilters?.allowedMonths, "months")
												: ""
										}
										maxDate={
											currentFilters?.currentDateFilter?.current?.rangeStartDate
												? moment(currentFilters?.currentDateFilter?.current?.rangeStartDate)
														.add(30, "days")
														.diff(moment(), "days") < 0
													? moment(
															currentFilters?.currentDateFilter?.current?.rangeStartDate
													  ).add(30, "days")
													: moment()
												: moment()
										}
										defaultCustomRange={10}
										isClearable={true}
										setDefaultRange={false}
										hidePresetTypes={["This year", "90 D"]}
										validationMessage={validations?.startDate || ""}
										showCustomTooltip={true}
										tooltipInfo="Date range is limited up to 31 days"
										tooltipPosition="up-left"
									/>
								)}
								<TagsInputWrapper
									ref={tagsRef}
									tags={emailListFilter}
									onChange={setEmailFilter}
									tagInput={currEmail}
									onChangeInput={handleTagInput}
									requiredLabel={true}
									placeholder={"Enter an email"}
									validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
									onValidationReject={() =>
										setValidations({
											...validations,
											email: "Please enter a valid email"
										})
									}
									validationMessage={validations?.email || ""}
									showDropdown={true}
								>
									Recipient Emails
								</TagsInputWrapper>
							</div>
						</React.Fragment>
					)}
					{reportFilters.filters.length === 0 && loading && (
						<div className="P(10px 0)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	isNonHqReport: store?.login?.loginDetail?.access?.isNonHqReport,
	bizId: store?.login?.loggedInbizDetail?.id,
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	associatedLocations: store.login.loginDetail.associatedLocations
});
export default connect(mapStateToProps)(ExportReport);
