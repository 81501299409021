import React from "react";
import { dateFormat } from "../../helpers/periscope";
import { connect } from "react-redux";

const MainHeader = ({ classname = "", headerText = "", periscopeDataResponse }) => {
	const timestamp = periscopeDataResponse?.timestamp;
	return (
		<div className={classname}>
			<div className="title-peri"> {headerText} </div>
			{timestamp ? <div className=" fetch-time "> {dateFormat(timestamp)} </div> : ""}
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeDataResponse: state.periscopeDataResponse
});

export default connect(mapStateToProps)(MainHeader);
