import React, { useState, useCallback } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CopyToClipboard } from "../_commons/CopyToClipboard";

// third party
import moment from "moment";

const ExternalLogDetail = ({ isOpen, close, data = {}, url = "" }) => {
	const [loading, setLoading] = useState(false);
	const [copyRequestBtn, setCopyRequestBtn] = useState(false);
	const [copyResponseBtn, setCopyResponseBtn] = useState(false);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setLoading(false);
			setCopyRequestBtn(false);
			setCopyResponseBtn(false);
			close(refresh);
		},
		[close]
	);

	const renderTitle = () => {
		return <div className="purpose">{data.purpose}</div>;
	};

	const renderSubTitle = () => {
		return (
			<div className="description">
				<div className={"status-code " + (data.responseStatusCode === 200 ? "success" : "error")}>
					Status code: <span>{data.responseStatusCode}</span>
				</div>
				<div title={url} className="request-url">
					{url && url.length > 70 ? url.slice(0, 70) + "..." : url}
				</div>
			</div>
		);
	};

	return (
		<div className="external-log-detail-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				title={renderTitle()}
				subTitle={renderSubTitle()}
				loading={loading}
				hideActions={true}
				isNested={true}
			>
				<div className="log-details">
					<div className="title">
						{data.created ? (
							<React.Fragment>
								Created:{" "}
								<span className="desc-text">
									{moment(data.created).format("DD MMM, YYYY - hh:mm:ss A")}
								</span>
							</React.Fragment>
						) : (
							""
						)}
					</div>
					<div className="title">Request Body</div>
					<div
						className="json-container payload"
						onMouseEnter={() => setCopyRequestBtn(true)}
						onMouseLeave={() => setCopyRequestBtn(false)}
					>
						{copyRequestBtn && <CopyToClipboard content={data.requestBody || ""} showIcon={true} />}
						{data.requestBody && <pre>{data.requestBody}</pre>}
					</div>
					<div className="title">Response Body</div>
					<div
						className="json-container payload"
						onMouseEnter={() => setCopyResponseBtn(true)}
						onMouseLeave={() => setCopyResponseBtn(false)}
					>
						{copyResponseBtn && <CopyToClipboard content={data.responseBody || ""} showIcon={true} />}
						{data.responseBody && <pre>{data.responseBody}</pre>}
					</div>
				</div>
			</FormSidebar>
		</div>
	);
};
export default ExternalLogDetail;
