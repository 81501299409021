// clients
import { store } from "../store/configureStore";
import { client } from "../client";
import history from "../history";

// third party
import PubSub from "pubsub-js";

// utils
import { lS } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// graphql
import { SAVE_CAMPAIGN } from "../graphql/campaigns";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";

export const createNewCampaign = () => {
	// track this event
	// PubSub.publish(TRACK_EVENT, {
	// 	tracker: 'mixpanel',
	// 	eventName: 'campaign_create_start',
	// 	eventMeta: {
	// 		source: 'custom_campaign',
	// 	},
	// });
	store.dispatch({
		type: "CREATE_CAMPAIGN_RESET"
	});
	store.dispatch({
		type: "EMAIL_TEMPLATE_RESET"
	});
	store.dispatch({
		type: "SEGMENT_RESET"
	});
	lS.remove("savedCampaign");
	history.push("/campaigns/new");
};

export const saveCampaign = async (variables) => {
	try {
		let { __typename, ...cg } = variables.conversionConfig;
		store.dispatch({
			type: "CAMPAIGN_UPDATE_STATE",
			payload: true
		});
		await client.mutate({
			mutation: SAVE_CAMPAIGN,
			variables: {
				id: variables.id,
				medium: variables.medium,
				messageType: variables.messageType,
				subject: variables.subject,
				message: variables.message,
				includeInNotifications: variables.includeInNotifications,
				campaignExecutionType: variables.campaignExecutionType,
				scheduledTime: variables.scheduledTime || undefined,
				cronExpression: variables.cronExpression || undefined,
				startAt: variables.startAt || undefined,
				endAt: variables.endAt || undefined,
				conversionConfig: cg.event && cg.days ? cg : undefined,
				name: variables.name,
				segmentId: Number(variables.segmentId) || undefined,
				emailTemplateId: Number(variables.emailTemplateId) || undefined,
				imageUrl: variables.imageUrl,
				imageId: variables.imageId
			}
		});
		store.dispatch({
			type: "CAMPAIGN_UPDATE_STATE",
			payload: false
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "CAMPAIGN_UPDATE_STATE",
			payload: false
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};
