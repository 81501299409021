import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import {
	GET_ENTITY_LOCATION_GROUPS,
	UPDATE_ENTITY_LOCATION_GROUPS
} from "../../graphql/locationGroupEntityAssociation";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../../client-config";
const FILTER_INITIAL_STATE = {
	title: ""
};
const LOCATION_GROUP_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const LocationGroupEntityAssociation = ({ entityId, isOpen, close, brand = null, isMultibrandEnabled = false }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [locationGroupData, setLocationGroupData] = useState(LOCATION_GROUP_INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [locationGroupUpdates, setLocationGroupUpdates] = useState({});
	const tableRef = useRef();
	const totalChanges = Object.keys(locationGroupUpdates).length;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				entityId: parseInt(entityId),
				limit,
				offset
			};
			if (isMultibrandEnabled && brand?.brandId) {
				variables.brandId = brand?.brandId;
			}
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_ENTITY_LOCATION_GROUPS,
				variables,
				fetchPolicy: "no-cache"
			});
			setLocationGroupData(resp.data.locationLocationGroupsAssociations);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [entityId, brand, isMultibrandEnabled, limit, offset, appliedFilters]);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const syncData = useCallback(() => {
		setLocationGroupData({
			...locationGroupData,
			objects: locationGroupData.objects.map((loc) => {
				if (locationGroupUpdates[loc.id] !== undefined) {
					return {
						...loc,
						isAssociated: locationGroupUpdates[loc.id]
					};
				}
				return loc;
			})
		});
		setLocationGroupUpdates({});
	}, [locationGroupData, locationGroupUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setLocationGroupData(LOCATION_GROUP_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setLocationGroupUpdates({});
			setIsCheckedAll(false);
			close(refresh);
		},
		[close]
	);

	const handleUpdate = useCallback(async () => {
		try {
			setConfirmLoading(true);
			const variables = {
				locationId: parseInt(entityId),
				locationGroupsToAssociate: [],
				locationGroupsToDisassociate: []
			};
			if (isMultibrandEnabled && brand?.brandId) {
				variables.brandId = brand?.brandId;
			}
			for (let id in locationGroupUpdates) {
				if (locationGroupUpdates[id]) {
					variables.locationGroupsToAssociate.push(parseInt(id));
				} else {
					variables.locationGroupsToDisassociate.push(parseInt(id));
				}
			}

			const eventMeta = {
				change_in_number_of_location_groups_associated:
					variables.locationGroupsToAssociate.length || -variables.locationGroupsToDisassociate.length
			};

			const resp = await client.mutate({
				mutation: UPDATE_ENTITY_LOCATION_GROUPS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.updateLocationGroupAssociation.status.success) {
				eventMeta["status"] = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_LOCATION_GROUPS_UPDATE, eventMeta);

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message:
							resp.data.updateLocationGroupAssociation.status.messages[0].message === "Success"
								? "Location groups association updated!"
								: resp.data.updateLocationGroupAssociation.status.messages[0].message,
						timeout: 2000,
						error: false
					}
				});
				handleClose(true);
				syncData();
			} else {
				eventMeta["status"] = TRACKING_STATUS.FAILED;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_LOCATION_GROUPS_UPDATE, eventMeta);

				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.updateLocationGroupAssociation.status.messages),
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [entityId, brand, isMultibrandEnabled, locationGroupUpdates, fetchData, syncData, handleClose]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setLocationGroupUpdates({
			...locationGroupUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		locationGroupData.objects.forEach((loc) => {
			if (!loc.includesAll) {
				updates[loc.id] = toCheckAll;
			}
		});
		setLocationGroupUpdates({
			...locationGroupUpdates,
			...updates
		});
	};

	return (
		<div className="location-group-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Location Groups"
				subTitle={
					<div>
						Manage location groups for this location{" "}
						{isMultibrandEnabled && brand?.brandName ? (
							<React.Fragment>
								for <span>{brand?.brandName}</span>
							</React.Fragment>
						) : (
							""
						)}
					</div>
				}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
						{totalChanges === 0 ? "Update" : `Update ${totalChanges} Location Group(s)`}
					</Button>
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-container">
						<InputWithLabel
							value={currFilters.title}
							onChange={(e) => setFilter("title", e.target.value)}
							placeholder="Enter Name"
						>
							Name
						</InputWithLabel>
					</div>
					<Table
						data={locationGroupData.objects || []}
						loading={loading}
						handleCheck={handleCheck}
						locationGroupUpdates={locationGroupUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={locationGroupData.count}
						goToPage={handlePagination}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default LocationGroupEntityAssociation;

export const Table = ({ data, loading, handleCheck, isCheckedAll, handleCheckAll, locationGroupUpdates, sortInfo }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container location-group-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader isCheckedAll={isCheckedAll} handleCheckAll={handleCheckAll} sortInfo={sortInfo} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							locationGroupUpdates={locationGroupUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && (
						<div className="no-items-placeholder">No Location groups found!</div>
					)}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, sortInfo }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<div className={"checkbox-sort" + (sortInfo?.field === "associated" ? " selected" : "")}>
				<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Name">
					<span>Name</span>
				</CheckBox>
			</div>
		</div>
	</div>
);

export const TableList = ({ id, title, isAssociated, includesAll, style, handleCheck, locationGroupUpdates }) => {
	const status = locationGroupUpdates[id] === undefined ? isAssociated : locationGroupUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name" title={title}>
				<CheckBox
					checked={status}
					clickHandler={() => handleCheck(id, !status)}
					title={title || id}
					readOnly={includesAll}
				>
					{title || id}
				</CheckBox>
			</div>
		</animated.div>
	);
};
