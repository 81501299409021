import React, { useRef, useState } from "react";

// utils
import { capitaliseTextStrict, commifyNumbers, printCurrency } from "../../atlas-utils";

// third party
import { Link } from "react-router-dom";
import moment from "moment";
import { useTrail } from "react-spring";
import { Transition, config, animated } from "react-spring/renderprops";

// components
import Image from "../_commons/Image";
import MonogramGenerator from "../_commons/MonogramGenerator";
import Placeholder from "../_commons/Placeholder";

// constants
import {
	STORE_TYPES,
	PAYMENT_MODE_MAP,
	ORDER_STATUS_MAP,
	ORDER_STATUS_BACKGROUND_COLOR_MAP,
	ORDER_STATUS_COLOR_DEFAULT,
	ORDER_CHANNEL_MAP,
	EXTERNAL_PLATFORMS_LOGO,
	CATALOGUE_PLATFORMS_LOGO,
	ORDER_STATUS_TEXT_COLOR_MAP,
	PLATFORM_NAME_MAP
} from "../../client-config";

export const Table = ({
	storeTypeSelected,
	currencySymbol,
	data,
	loading,
	sortList,
	sortedField,
	dimensions,
	appliedInlineStyles,
	tableColumnsSelected
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});

	const placeholderContent = {
		placeholderText: "No orders yet!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-orders.svg",
		placeholderSubtext: "View log of all your online orders allowing you to keep track of online business",
		size: "medium"
	};

	if (data && data.length === 0 && !loading) {
		return <Placeholder {...placeholderContent} />;
	}

	return (
		<div className={(data.length > 0 && loading ? "disabled" : "") + " transaction-table-holder"}>
			<div className="transactions-list-table">
				<div className="at-table-row-based">
					<TableHeader
						storeTypeSelected={storeTypeSelected}
						sortList={sortList}
						sortedField={sortedField}
						tableType={dimensions.width > 768 ? "desktop" : "mobile"}
						appliedInlineStyles={appliedInlineStyles}
						tableColumnsSelected={tableColumnsSelected}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							storeTypeSelected={storeTypeSelected}
							dimensions={dimensions}
							currencySymbol={currencySymbol}
							tableColumnsSelected={tableColumnsSelected}
							appliedInlineStyles={appliedInlineStyles}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No order history!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({
	storeTypeSelected,
	sortList,
	sortedField,
	tableType,
	appliedInlineStyles,
	tableColumnsSelected
}) => {
	const storeType = STORE_TYPES.find((store) => store.type === storeTypeSelected);
	return (
		<div className={`at-table-row transaction-header-row ${storeTypeSelected.toLowerCase()}`}>
			{storeType.fields[tableType]
				.filter((field) => tableColumnsSelected[field.value])
				.map((field, i) => (
					<div
						key={i}
						className={`at-table-cell at-table-header at-header-text ${field.value} ${
							field.searchKey ? "clickable" : ""
						} ${sortedField === field.searchKey ? "active" : ""}`}
						onClick={field.searchKey && (() => sortList(field.searchKey))}
						style={appliedInlineStyles}
					>
						<span>{field.label}</span>
						{field.searchKey && (
							<span>
								&nbsp;&nbsp;
								<img src="/assets/icons/icon-sort.svg" alt="" />
							</span>
						)}
					</div>
				))}
		</div>
	);
};

const TableList = ({ storeTypeSelected, ...props }) =>
	storeTypeSelected === STORE_TYPES[0].type ? <TableListOnline {...props} /> : <TableListInstore {...props} />;

const TableListOnline = ({
	id,
	merchantRefId,
	paymentMode,
	store,
	created,
	address,
	customer,
	payableAmount,
	status,
	currencySymbol,
	channel = "",
	channelLogo,
	childOrderId,
	externalPlatform,
	style,
	dimensions,
	tableColumnsSelected,
	appliedInlineStyles,
	complaints = []
}) => {
	const extPlatID = externalPlatform && externalPlatform.id;
	const extPlatLogo = channelLogo
		? channelLogo
		: channel && CATALOGUE_PLATFORMS_LOGO[(PLATFORM_NAME_MAP[channel] || channel).toLowerCase()];
	const ordChannelName = ORDER_CHANNEL_MAP[channel]
		? ORDER_CHANNEL_MAP[channel]
		: PLATFORM_NAME_MAP[channel] || channel;
	const storeTitle = store && store.title;
	const paymentModeTitle = PAYMENT_MODE_MAP[paymentMode] || paymentMode;
	const ordStatus = ORDER_STATUS_MAP[childOrderId ? "CANCELLED_FOR_MODIFICATION" : status] || status;
	const [isRefundStatusVisible, setIsRefundStatusVisible] = useState(false);
	const renderStatusRef = useRef();

	const handleMouseEnter = () => {
		if (renderStatusRef.current) {
			clearTimeout(renderStatusRef.current);
		}
		setIsRefundStatusVisible(true);
	};

	const handleMouseLeave = () => {
		renderStatusRef.current = setTimeout(() => {
			setIsRefundStatusVisible(false);
		}, 100);
	};

	const expiredRefundComplaints = complaints.filter((complaint) =>
		moment().isAfter(moment.unix(complaint.expiredAt))
	);
	const nonExpiredRefundComplaints = complaints
		.filter((complaint) => moment().isBefore(moment.unix(complaint.expiredAt)))
		.sort((a, b) => parseInt(a.expiredAt) - parseInt(b.expiredAt));
	const combinedRefundComplaints = [...nonExpiredRefundComplaints, ...expiredRefundComplaints];

	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows ordering"
		>
			{tableColumnsSelected?.id && (
				<div className="at-table-cell at-cell-text id" title={id} style={appliedInlineStyles}>
					<div className="icon-link--container">
						{extPlatLogo && dimensions.width > 768 && <img src={extPlatLogo} className="order-plt-icon" />}
						<Link className="order-id hyperlink hyperlink--black-color" to={`/orders/online/${id}`}>
							{id}
						</Link>
					</div>
					{extPlatID && channel && (
						<div className="external-id" title={extPlatID}>
							{ordChannelName}:&nbsp;{extPlatID}
						</div>
					)}
					{merchantRefId && (
						<div className="external-id" title={merchantRefId}>
							POS:&nbsp;{merchantRefId}
						</div>
					)}
					{combinedRefundComplaints.length > 0 && (
						<RefundStatus
							complaint={combinedRefundComplaints[0]}
							handleMouseEnter={handleMouseEnter}
							handleMouseLeave={handleMouseLeave}
							isRefundStatusVisible={isRefundStatusVisible}
							id={id}
						/>
					)}
				</div>
			)}
			{/* {
				tableColumnsSelected?.id &&
				<div className="at-table-cell at-cell-text id" title={id} style={appliedInlineStyles}>
					<div className='icon-link--container'>
						{
							(extPlatLogo && dimensions.width > 768) &&
							<img
								src={extPlatLogo}
								className="order-plt-icon"
							/>
						}
						<Link className="order-id hyperlink--no-action" to={`/orders/online/${id}`}>
							{id}
						</Link>
					</div>
					{
						(extPlatID && channel) &&
						<div
							className="external-id"
							title={extPlatID}
						>
							{ordChannelName}:&nbsp;{extPlatID}
						</div>
					}
					{
						merchantRefId &&
						<div
							className="external-id"
							title={merchantRefId}
						>
							POS:&nbsp;{merchantRefId}
						</div>
					}
				</div>
			} */}
			{tableColumnsSelected?.date && (
				<div className="at-table-cell at-cell-text date" style={{ ...appliedInlineStyles }}>
					<div className="date-date">{moment(created).format("DD MMM, YYYY")}</div>
					<div className="date-time">{moment(created).format("hh:mm A")}</div>
				</div>
			)}
			{tableColumnsSelected?.outlet && dimensions.width > 768 && (
				<div className="at-table-cell at-cell-text outlet" title={storeTitle} style={appliedInlineStyles}>
					{storeTitle ? storeTitle : "--"}
				</div>
			)}
			{tableColumnsSelected?.user && (
				<div
					className="at-table-cell at-cell-text user"
					title={address && address?.name ? address?.name : customer ? customer.fullName : ""}
					style={appliedInlineStyles}
				>
					{address && address?.name ? (
						<React.Fragment>
							<div className="name">{address?.name || "--"}</div>
							<div className="phone">{customer?.phone || ""}</div>
						</React.Fragment>
					) : customer ? (
						<React.Fragment>
							<div className="name">{customer.firstName || "--"}</div>
							<div className="phone">{customer.phone}</div>
						</React.Fragment>
					) : (
						"--"
					)}
				</div>
			)}
			{tableColumnsSelected?.payment && dimensions.width > 768 && (
				<div
					className="at-table-cell at-cell-text payment"
					title={paymentModeTitle}
					style={appliedInlineStyles}
				>
					{paymentModeTitle ? paymentModeTitle : "--"}
				</div>
			)}
			{tableColumnsSelected?.status && dimensions.width > 768 && (
				<div
					className="at-table-cell at-cell-text status"
					title={capitaliseTextStrict(ordStatus)}
					style={appliedInlineStyles}
				>
					<span
						className="status-value"
						style={{
							backgroundColor:
								ORDER_STATUS_BACKGROUND_COLOR_MAP[
									childOrderId ? "CANCELLED_FOR_MODIFICATION" : status
								] || ORDER_STATUS_COLOR_DEFAULT,
							color:
								ORDER_STATUS_TEXT_COLOR_MAP[childOrderId ? "CANCELLED_FOR_MODIFICATION" : status] ||
								"white"
						}}
					>
						{ordStatus ? capitaliseTextStrict(ordStatus) : "--"}
					</span>
				</div>
			)}
			{tableColumnsSelected.associatedBrands && (
				<div
					className="at-table-cell at-cell-text brand"
					title={store?.brand?.name}
					style={appliedInlineStyles}
				>
					{!store?.brand?.image ? (
						<MonogramGenerator size="xsmall" value={store?.brand?.name} />
					) : (
						<Image className="brand-icon" src={store?.brand?.image} />
					)}
				</div>
			)}
			{tableColumnsSelected?.amount && (
				<div className="at-table-cell at-cell-text amount" title={payableAmount} style={appliedInlineStyles}>
					{printCurrency(currencySymbol)}
					{commifyNumbers(payableAmount)}
				</div>
			)}
		</animated.div>
	);
};

const TableListInstore = ({ id, billNumber, store, created, user, amount, currencySymbol, style }) => (
	<animated.div
		// style={style}
		className="at-table-row transaction-rows loyalty"
	>
		<div className="at-table-cell at-cell-text id">
			<Link className="upiper-id transaction-id" to={`/orders/in-store/${id}`}>
				{id}
			</Link>
			{billNumber && (
				<div className="external-id" title={billNumber}>
					POS:&nbsp;{billNumber}
				</div>
			)}
		</div>
		<div className="at-table-cell at-cell-text date">
			{created ? (
				<React.Fragment>
					<div className="date-date">{moment(created).format("DD MMM, YYYY")}</div>
					<div className="date-time">{moment(created).format("hh:mm A")}</div>
				</React.Fragment>
			) : (
				"--"
			)}
		</div>
		<div className="at-table-cell at-cell-text outlet">{store && store.title ? store.title : "--"}</div>
		<div className="at-table-cell at-cell-text user" title={user ? user.fullName : ""}>
			{user && (
				<React.Fragment>
					<div className="name">{user.firstName}</div>
					<div className="phone">{user.phone}</div>
				</React.Fragment>
			)}
		</div>
		<div className="at-table-cell at-cell-text amount" title={amount}>
			{printCurrency(currencySymbol)}
			{commifyNumbers(amount)}
		</div>
	</animated.div>
);

const RefundStatus = ({ complaint, handleMouseEnter, handleMouseLeave, isRefundStatusVisible, id }) => {
	const { expiredAt } = complaint;
	const expiryTime = moment.unix(expiredAt);
	const now = moment();
	const isNotExpired = now.isBefore(expiryTime);

	return (
		<div className="refund-alert">
			<Link className="upiper-id transaction-id" to={`/orders/online/${id}`}>
				<img
					className="alert-icon"
					src="/assets/icons/icon-alert-red.svg"
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				/>
			</Link>
			<Transition
				native
				items={isRefundStatusVisible}
				from={{ opacity: 0 }}
				enter={{ opacity: 1 }}
				leave={{ opacity: 0 }}
				config={config.stiff}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div
							style={props}
							className="refund-message"
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							<div className="header">Refund Request</div>
							<div className="current-status">
								{isNotExpired ? `Take action within ${expiryTime.fromNow(true)}` : "Expired"}
							</div>
						</animated.div>
					))
				}
			</Transition>
		</div>
	);
};
