import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/DiscountEdit/BasicInfo";
import AssociatedItems from "../components/DiscountEdit/AssociatedItems";
import AssociatedLocations from "../components/DiscountEdit/AssociatedLocations";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { removeProp, adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// graphql
import { GET_PLATFORMS_DISCOUNTS } from "../graphql/discounts";

// actions
import { fetchTimingGroups } from "../actions/actions";
import { fetchDiscountsList, fetchDiscountDetail, editDiscountDetail } from "../actions/discounts";
import { ActionTypes } from "../actions/_types";

// reducers
import { discountDetailsReducer, DISCOUNT_DETAILS_INITIAL_STATE } from "../reducers/discounts";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Items",
		value: "items"
	},
	{
		label: "Locations",
		value: "locations"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const DiscountEdit = ({ biz, match, selectedPlatform, isNested = false, closeNestedContainer, connectedRef }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isFormOpen, setFormState] = useState(false);
	const [discountDetails, dispatch] = useReducer(discountDetailsReducer, DISCOUNT_DETAILS_INITIAL_STATE);
	const { loading, data, error } = discountDetails;
	const [platforms, setPlatforms] = useState([]);
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchTimingGroups();
	}, []);

	useEffect(() => {
		fetchPlatforms();
	}, []);

	useEffect(() => {
		if (selectedPlatform) {
			fetchDiscountDetail(parseInt(match.params.id), selectedPlatform, dispatch);
		}
	}, [match.params.id, selectedPlatform]);

	const fetchPlatforms = async () => {
		try {
			const resp = await client.query({
				query: GET_PLATFORMS_DISCOUNTS,
				fetchPolicy: "no-cache"
			});
			setPlatforms(resp.data.discountPlatforms);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchDiscountsList(selectedPlatform.value);
					history.push("/discounts");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (formData) => {
		dispatch({
			type: ActionTypes.UPDATE_DISCOUNT_DETAIL,
			payload: {
				...formData
			}
		});
		if (formTab === FORM_TABS[0].value && !isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		const resp = await editDiscountDetail(sanitisedData, dispatch);
		if (resp) {
			setFormTouched(false);
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_DISCOUNT_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	return (
		<div className="discount-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Discount"}
				subTitle="Edit this discount"
				submitTitle="Save"
				loading={loading}
				isNested={isNested}
				hideActions={
					(formTab === FORM_TABS[0].value && !isFormTouched) ||
					formTab === FORM_TABS[1].value ||
					formTab === FORM_TABS[2].value
				}
				headerRight={
					<Button
						classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
						clickHandler={() => setArchiveRestore(true)}
					>
						{data.isActive ? "Archive" : "Restore"}
					</Button>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							platforms={platforms}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<AssociatedItems
							discountId={parseInt(match.params.id)}
							entityType={selectedPlatform}
							setModalBusy={setModalBusy}
							currencySymbol={biz.currencySymbol}
							handleNestedEntity={handleNestedEntity}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<AssociatedLocations
							discountId={parseInt(match.params.id)}
							entityType={selectedPlatform}
							setModalBusy={setModalBusy}
							currencySymbol={biz.currencySymbol}
							handleNestedEntity={handleNestedEntity}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[12]}
						entityName={data.title}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						fieldName="isActive"
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail,
	selectedPlatform: store.discountsList.selectedPlatform
}))(DiscountEdit);
