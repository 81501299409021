import React from "react";
import StackProgressBar from "./StackProgressBar";
import { connect } from "react-redux";
import SkeletonLoader from "../SkeletonLoader";
import Tooltip from "./Tooltip";
import { removeTrailingZeros } from "../../../helpers/periscope";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../../history";
import Popover from "../../_commons/Popover";
import { trackEvent } from "../../../atlas-utils";

const LocationAvailability = (periscopeDashboard) => {
	const locationMetrics = periscopeDashboard.periscopeDashboard.locationMetrics;
	const totalLocations = locationMetrics?.data?.totalLocations;
	const isLoading = periscopeDashboard?.periscopeDashboard?.storeMetrics?.loading;

	const error = periscopeDashboard?.periscopeDashboard?.storeMetrics?.error;
	const dashboardMetrics = periscopeDashboard.periscopeDashboard.storeMetrics;
	const totalStorefronts = dashboardMetrics?.data?.totalStores;
	const offlineStorefronts = dashboardMetrics?.data?.offlineStores;
	const onlineStorefronts = totalStorefronts - offlineStorefronts;
	let offlineStorePercent = dashboardMetrics?.data?.offlineStorePercentage?.toFixed(2) || 0;
	if (offlineStorePercent) {
		offlineStorePercent = removeTrailingZeros(offlineStorePercent);
	}

	const locationStackProgressBarData = [
		{
			offlineCount: Math.ceil(dashboardMetrics?.data?.offlineStorePercentage) || 0,
			onlineCount: 100 - (Math.ceil(dashboardMetrics?.data?.offlineStorePercentage) || 0)
		}
	];
	const progressBarKeys = ["offlineCount", "onlineCount"];
	const keysColorMapping = {
		offlineCount: "#B02B2B",
		onlineCount: "#C6D2FF"
	};

	const renderPopover = (data) => {
		return (
			<div className="title-desc pop-info">
				<span className="main-title">{data.title}</span>
				<span className="info-desc">{data.description}</span>
			</div>
		);
	};

	const handleEvent = () => {
		trackEvent("periscope_module_accessed", {
			module: "storefront_availability",
			source: "home"
		});
	};

	return (
		<>
			<div className="location-periscope availability border ">
				<div className="title-action-peri ai-center pad-left-16">
					Storefront Availability
					<div className="tooltip ml-4">
						<Popover
							data={{
								title: "Storefront Availability",
								description:
									"Breakdown of all locations that have all or multiple storefronts offline across all brands and platforms (each location consists of multiple storefronts within each brand and platform)"
							}}
							renderPopover={renderPopover}
							position={"down-left"}
							showOnHover={true}
							showOnClick
						>
							<img src="../../../assets/periscope/tooltip-icon.svg" className="tool-peri" />
						</Popover>
					</div>
				</div>

				<div className="information-container pad-left-16">
					<div className="available-indicator-periscope justify-content-between ai-center">
						<div className="ai-center">
							<img className="main-icon-availability" src="../../../assets/periscope/location.svg" />
							<span className="small-size-text ">Total Storefronts</span>
						</div>
						<span className="no-of-locations-peri">{totalStorefronts}</span>
					</div>

					{isLoading || error ? (
						<div>
							<SkeletonLoader classname={`loader-availability ${error == true ? "stop-moving" : ""}`} />
						</div>
					) : (
						<>
							{offlineStorefronts > 0 ? (
								<div className="bargraph-dashboard">
									<div className="item-loc-availability">
										<StackProgressBar
											outerDivClass={"item-loc-availability"}
											data={locationStackProgressBarData}
											keys={progressBarKeys}
											keysColorMapping={keysColorMapping}
											padding={2}
											borderRadius={2}
										/>
									</div>
									<div className="legend-peri ai-center">
										<>
											{offlineStorefronts > 0 ? (
												<div
													className="graph-peri"
													onClick={() => {
														handleEvent();
														history.push(
															"/periscope/storefront-availability?status=offline"
														);
													}}
												>
													<div className="graph-text">
														<div className="circle offline"></div>
														<span className="status">Offline</span>
														<div className="tooltip">
															<Popover
																data={{
																	title: "Offline Storefronts",
																	description:
																		"Storefronts currently available for ordering"
																}}
																renderPopover={renderPopover}
																position={"down-left"}
																showOnHover={true}
																showOnClick
															>
																<img
																	src="../../../assets/periscope/tooltip-icon.svg"
																	className="tool-peri"
																/>
															</Popover>
														</div>
													</div>
													<div>
														<span className="info">{offlineStorefronts}</span>
														{/* <img
															className="arrow-top"
															src="../../../assets/periscope/icons8-arrow-top.svg"
														/> */}
													</div>
												</div>
											) : (
												""
											)}
										</>
										<>
											{onlineStorefronts > 0 ? (
												<div
													className="graph-peri"
													onClick={() => {
														handleEvent();
														history.push(
															"/periscope/storefront-availability?status=online"
														);
													}}
												>
													<div className="graph-text">
														<div className="circle online"></div>
														<span className="status">Online</span>
														<div className="tooltip">
															<Popover
																data={{
																	title: "Online Storefronts",
																	description:
																		"Storefronts currently available for ordering"
																}}
																renderPopover={renderPopover}
																position={"down-left"}
																showOnHover={true}
																showOnClick
															>
																<img
																	src="../../../assets/periscope/tooltip-icon.svg"
																	className="tool-peri"
																/>
															</Popover>
														</div>
													</div>
													<div>
														<span className="info">{onlineStorefronts}</span>
													</div>
												</div>
											) : (
												""
											)}
										</>
									</div>
								</div>
							) : (
								<div className="online-locations ">
									<div>
										<img className="validation" src="../../../assets/periscope/correct-icon.svg" />
									</div>
									<div className="display">
										<span className="total-locations">{totalLocations} </span>
										<span className="online">Online Storefronts</span>
									</div>
								</div>
							)}
						</>
					)}
				</div>

				<div
					className="info-display-periscope justify-content-between pad-left-16"
					onClick={() => {
						handleEvent();
						history.push("/periscope/storefront-availability");
					}}
				>
					<div className="ai-center">
						<img
							className="image-info-peri "
							src="../../../assets/periscope/offline-storefronts-bottom-2.svg"
						/>
						<span className="small-size-text ">Offline storefronts</span>
					</div>
					<div className="ai-center percentage-display-peri">
						<div
							className={` availability-percentage justify-content-center ${
								offlineStorePercent > 0 ? "availability-red" : "availability-green"
							} `}
						>
							{isLoading || error ? (
								<SkeletonLoader classname={`show-percentage ${error == true ? "stop-moving" : ""}`} />
							) : (
								<span>{offlineStorePercent}%</span>
							)}
						</div>
						<img className="arrow-periscope" src="../../../assets/periscope/sidearrow.svg" />
					</div>
				</div>

				<div
					data-testid="all-locations-btn"
					className="last-div-la-periscope pad-left-16"
					onClick={() => handleEvent()}
				>
					<Link to="/periscope/storefront-availability">
						<span className=" ai-center medium-size-text detailed-info-peri hyperlink hyperlink--black-color">
							View all storefronts
							<img className="right-arrow-peri" src="../../../assets/periscope/right-arrow.svg" />
						</span>
					</Link>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	periscopeDashboard: state.periscopeDashboard
});
export default connect(mapStateToProps)(LocationAvailability);
