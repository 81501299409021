import React from "react";

// NOTE: pass highlight as a prop to highlight a part of a content, here content should be an object/array of objects of structure { text : "xyz", hightlight: true/false} . IF HIGHLIGHT IS NOT USED, content should be either a string or array of strings.

export const TextHighlightChip = ({ content, highlight }) => {
	const getArrayContent = (data = []) => {
		if (data.length > 3) {
			const tempData = data.slice(0, 3).join(" , ");
			const text = tempData + ` + ${data.length - 3}`;
			return text;
		} else {
			return data.join(" , ");
		}
	};
	const isContentArray = Array.isArray(content);
	const arrayContent = isContentArray ? getArrayContent(content) : "";

	return (
		<>
			{highlight ? (
				<>
					{isContentArray ? (
						<span className="text-highlight-chip-container">
							{content.map((ct, index) => (
								<>
									<span
										key={index}
										style={{
											color: `${ct?.highlight ? "#D64949" : "inherit"}`
										}}
									>
										{ct?.text}
									</span>
									{index !== content.length - 1 && ", "}
								</>
							))}
						</span>
					) : (
						<span
							className="text-highlight-chip-container"
							style={{ color: `${content?.highlight ? "red" : "#f5f5f5"}` }}
						>
							{content?.text}
						</span>
					)}
				</>
			) : (
				<span className="text-highlight-chip-container">{isContentArray ? arrayContent : content}</span>
			)}
		</>
	);
};

export default TextHighlightChip;
