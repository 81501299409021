import React from "react";

const PlatformCell = ({ props }) => {
	return (
		<div style={{ display: "flex", alignItems: "center" }} className="location-platforms-table">
			{props == true ? (
				<span
					className="location-data-icons"
					style={{ width: "100%", display: "flex", justifyContent: "right" }}
				>
					<img
						src="/assets/periscope/online.svg"
						alt="Online"
						className="online-table-icon online-black-image"
					/>
					<img src="/assets/periscope/online-green.svg" className="online-green-image" />
				</span>
			) : props == false ? (
				<span className="location-data-icons" style={{ width: "100%" }}>
					<img src="/assets/periscope/offline.svg" alt="Offline" className="offline-table-icon" />
				</span>
			) : (
				<span className="location-data-icons" style={{ width: "100%" }}>
					<img
						src="/assets/periscope/partiallyOffline.svg"
						alt="Unknown"
						className="partiallyOffline-table-icon"
					/>
				</span>
			)}
		</div>
	);
};

export default PlatformCell;
