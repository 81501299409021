import React from "react";

// components
import Button, { ButtonSize, ButtonVariant } from "@urbanpiper-engineering/horizon/dist/atoms/button";

function Footer({ isContinueDisabled, handleBack, handleContinue, isLoading }) {
	return (
		<div className="onboarding-brand-footer">
			<Button
				disabled={isContinueDisabled || isLoading}
				variant={ButtonVariant.PRIMARY}
				onClick={handleContinue}
				size={ButtonSize.LG}
			>
				Continue
			</Button>
			<Button disabled={isLoading} onClick={handleBack} variant={ButtonVariant.Secondary}>
				Back
			</Button>
		</div>
	);
}

export default Footer;
