import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// components
import { GlobalConfirmModal } from "../SiteComp";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";

// clients
import { store } from "../../store/configureStore";

@connect((store) => ({
	creditsState: store.creditsState
}))
export class LowCreditsAlert extends Component {
	showBuyCredits = () => {
		store.dispatch({
			type: "CREDITS_STATE_UPDATE",
			payload: {
				showLowCreditsBar: false,
				showBuyCreditsFlow: true
			}
		});

		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'credit_purchase_start',
		// });
	};

	hideLowCredits = () => {
		store.dispatch({
			type: "CREDITS_STATE_UPDATE",
			payload: {
				showLowCreditsBar: false
			}
		});
	};

	render() {
		const { showLowCreditsBar } = this.props.creditsState;
		return (
			<GlobalConfirmModal
				backdropHandler={this.hideLowCredits}
				show={showLowCreditsBar}
				customButtons={true}
				noBackdrop={true}
			>
				<div className="low-credits-alert">
					<div className="alert-icon">
						<img src="/assets/icons/icon-alert.svg" alt="alert-icon" />
					</div>
					<div className="alert-area">
						<div className="alert-title">Low Credits</div>
						<div className="alert-subtitle">
							Your credits balance is lower than required amount to run this campaign. Reload credits to
							run this campaign.
						</div>
						<div className="alert-actions">
							<div className="buy-button" onClick={this.showBuyCredits}>
								Buy Credits
							</div>
							<div className="cancel-button" onClick={this.hideLowCredits}>
								Cancel
							</div>
						</div>
					</div>
				</div>
			</GlobalConfirmModal>
		);
	}
}
