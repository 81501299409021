export const topPerfTopics = (
	state = {
		loading: true,
		data: false,
		error: false
	},
	action
) => {
	switch (action.type) {
		case "TOP_PERF_TOPICS_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "TOP_PERF_TOPICS_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "TOP_PERF_TOPICS_FAILURE":
			return {
				...state,
				loading: false,
				error: true
			};
		default:
			return state;
	}
};

export const topPerfData = (
	state = {
		loading: true,
		data: false,
		error: false
	},
	action
) => {
	switch (action.type) {
		case "TOP_PERF_REQUEST":
			return {
				...state,
				loading: true,
				error: false
			};
		case "TOP_PERF_SUCCESS":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "TOP_PERF_FAILURE":
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
