import gql from "graphql-tag";

export const GET_GALLERIES_LIST = gql`
	query getGalleriesList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		galleries(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				image
				imgType
				created
			}
		}
	}
`;

export const CREATE_GALLERY = gql`
	mutation createGallery(
		$imgType: [String]
		$markups: String
		$image: Upload
		$supportedLanguages: [String]
		$locationGroupIds: [Int]
	) {
		saveGallery(
			input: {
				imgType: $imgType
				markups: $markups
				supportedLanguages: $supportedLanguages
				locationGroupIds: $locationGroupIds
			}
			image: $image
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				image
				imgType
				created
			}
		}
	}
`;

export const GET_GALLERY = gql`
	query getGallery($id: Int) {
		gallery(id: $id) {
			id
			image
			imgType
			markups
			created
			updated
			supportedLanguages
			locationGroups {
				id
				title
			}
		}
	}
`;

export const EDIT_GALLERY = gql`
	mutation edtGallery(
		$id: Int
		$imgType: [String]
		$markups: String
		$image: Upload
		$supportedLanguages: [String]
		$isDelete: Boolean
		$locationGroupIds: [Int]
	) {
		saveGallery(
			input: {
				id: $id
				imgType: $imgType
				markups: $markups
				supportedLanguages: $supportedLanguages
				isDelete: $isDelete
				locationGroupIds: $locationGroupIds
			}
			image: $image
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				image
				imgType
				created
			}
		}
	}
`;

export const EDIT_GALLERY_IMAGE = gql`
	mutation createGallery($imgType: [String], $markups: String, $image: Upload, $supportedLanguages: [String]) {
		saveGallery(
			input: { imgType: $imgType, markups: $markups, supportedLanguages: $supportedLanguages }
			image: $image
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				image
				imgType
				created
			}
		}
	}
`;

export const GET_LANGUAGES_LIST = gql`
	query getLanguagesList {
		languages {
			value
			valueForDisplay
		}
	}
`;
