import React, { useState } from "react";

// components
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";

const Headers = ({
	data = [],
	handleHeadersForm,
	handleAddHeader,
	handleDeleteHeader,
	readOnly = true,
	isEisWebhook
}) => {
	const eisEnvironmentOptions = [
		{
			value: "qa1",
			valueForDisplay: "qa1"
		},
		{
			value: "qa2",
			valueForDisplay: "qa2"
		},
		{
			value: "staging",
			valueForDisplay: "staging"
		},
		{
			value: "uat",
			valueForDisplay: "uat"
		}
	];

	const isNonProductionEnviroment = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG !== "prod";

	return (
		<div className="headers-container">
			{data.length > 0 &&
				data.map((header, i) => (
					<div className="key-value-pair" key={i}>
						<InputWithLabel
							value={header.key || ""}
							onChange={(e) => handleHeadersForm("key", e.target.value, i)}
							classes="key"
							readOnly={readOnly}
						>
							Key
						</InputWithLabel>
						{!!isEisWebhook && header.key === "environment" ? (
							<SelectFilter
								options={eisEnvironmentOptions}
								title="Value"
								labelKey="valueForDisplay"
								valueKey="value"
								currValue={(eisEnvironmentOptions ?? []).find((val) => val.value === header.value)}
								setFilter={(field, value) => handleHeadersForm("value", value.value, i)}
								isClearable={false}
							/>
						) : (
							<InputWithLabel
								value={header.value || ""}
								onChange={(e) => handleHeadersForm("value", e.target.value, i)}
								classes="value"
								readOnly={readOnly}
							>
								Value
							</InputWithLabel>
						)}
						{(!readOnly || (!!isEisWebhook && header.key === "environment")) && (
							<img
								className="delete-icon"
								onClick={() => handleDeleteHeader(i)}
								src="/assets/icons/delete.svg"
							/>
						)}
					</div>
				))}
			{data.length === 0 && <div className="no-items-placeholder">No Headers found!</div>}
			{isNonProductionEnviroment && !!isEisWebhook && !!!data?.find((val) => val.key === "environment") && (
				<Button type="secondary" clickHandler={() => handleAddHeader(true)}>
					Add Environment Header
				</Button>
			)}
			{!readOnly && (
				<Button type="secondary" clickHandler={() => handleAddHeader()}>
					{data.length > 0 ? "Add Another Header" : "Add Header"}
				</Button>
			)}
		</div>
	);
};
export default Headers;
