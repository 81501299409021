import React, { Component } from "react";

// components
import { NewDateCompareFilter } from "../components/_commons/NewDateCompareFilter";
import { Header } from "../components/CustomersList/Header";
import { Table } from "../components/CustomersList/Table";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { InputField } from "../components/_commons/InputField";

// clients
import { store } from "../store/configureStore";
import { client } from "../client";

// third party
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// constants
import { CUSTOMERS_LIST_SEARCH_FIELDS } from "../client-config";
import { TRACK_EVENT } from "../atlas-utils/tracking";

// actions
import { toggleGlobalLoader, fetchTags, fetchTagsDebounced, fetchStoresDebounced } from "../actions/actions";
import { fetchCustomersList } from "../actions/customers";

// utils
import { scroll } from "../atlas-utils";

@connect((store) => ({
	customersList: store.customersList,
	customersListState: store.customersListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	selectedModule: store.selectedModule
}))
export class CustomersList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			tagsLookup: {}
		};
	}

	async componentDidMount() {
		if (!this.props.configItems.stores.items.length) {
			fetchStoresDebounced("");
		}
		if (!this.props.configItems.tags.items.length) {
			fetchTags("");
		}

		// parse query string for filters
		const qs = queryString.parse(this.props.location.search, {
			arrayFormat: "bracket"
		});
		if (qs && qs.filters && qs.filters.length > 0) {
			const currentFilters = {};
			qs.filters.forEach((f) => {
				let parsedFilter = JSON.parse(f);
				if (parsedFilter.field === "tags") {
					this.setState({
						tagsLookup: {
							[parsedFilter.value]: parsedFilter.name
						}
					});
					delete parsedFilter.name;
				}
				currentFilters[parsedFilter.field] = parsedFilter;
			});
			this.updateCustomersListState({
				currentFilters,
				appliedFilters: currentFilters,
				offset: 0
			});
		}

		// set tracking related info
		const eventName = "customers_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch customer list
		await fetchCustomersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	async componentWillReceiveProps(newProps) {
		if (
			this.props.customersListState.appliedDateFilter.current.dateFilter !==
			newProps.customersListState.appliedDateFilter.current.dateFilter
		) {
			// set tracking related info
			const eventName = "customers_list_view_filter";
			let perfStart = 0;
			let perfEnd = 0;
			if (window.performance) {
				perfStart = window.performance.now();
			}

			// fetch data with updated applied filters
			await fetchCustomersList();

			// set tracking related info and send the event to be logged
			if (window.performance) {
				perfEnd = window.performance.now();
			}
			const { appliedDateFilter, appliedFilters } = newProps.customersListState;
			const eventMeta = {
				duration: appliedDateFilter.current.dateFilter,
				filters: JSON.stringify(Object.values(appliedFilters)),
				time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
			};
			PubSub.publish(TRACK_EVENT, {
				tracker: "mixpanel",
				eventName,
				eventMeta
			});
		}
	}

	searchCustomer = async () => {
		this.updateCustomersListState({
			offset: 0
		});

		// set tracking related info
		const eventName = "customers_list_view_search";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// perform search
		await fetchCustomersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { searchFieldSelected } = this.props.customersListState;
		const eventMeta = {
			search_field: searchFieldSelected.value,
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	searchCustomerDebounced = debounce(() => this.searchCustomer(), 300);

	cancelSearchCustomer = () => {
		this.updateCustomersListState({
			searchKW: "",
			offset: 0
		});
		fetchCustomersList();
	};

	handleSearchKW = (searchKW) => {
		this.updateCustomersListState({
			searchKW
		});
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	handleSearchField = (searchFieldSelected) => {
		this.updateCustomersListState({
			searchFieldSelected
		});
	};

	handleAsyncSearch = (e) => {
		this.handleSearchKW(e.target.value);
		this.searchCustomerDebounced();
	};

	updateCustomersListState = (payload) => {
		store.dispatch({
			type: "CUSTOMERS_LIST_STATE_CHANGE",
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateCustomersListState({
			currentFilters: this.props.customersListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.customersListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateCustomersListState({
			currentFilters
		});
	};

	updateStoresLookup = (id, title) => {
		this.setState({
			storesLookup: {
				...this.state.storesLookup,
				[id]: title
			}
		});
	};

	updateTagsLookup = (id, name) => {
		this.setState({
			tagsLookup: {
				...this.state.tagsLookup,
				[id]: name
			}
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateCustomersListState({
			appliedFilters: {
				...this.props.customersListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "customers_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchCustomersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedDateFilter, appliedFilters } = store.getState().customersListState;
		const eventMeta = {
			duration: appliedDateFilter.current.dateFilter,
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			() => {
				this.updateCustomersListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				fetchCustomersList();
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.customersListState;
		const offset = (page - 1) * limit;
		this.updateCustomersListState({
			offset
		});
		// get new transacitons results
		fetchCustomersList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.customersListState;
		if (size && size?.value !== limit) {
			this.updateCustomersListState({
				[field]: size.value
			});
			// fetch new customers list
			await fetchCustomersList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateCustomersListState({
			offset: 0
		});
		store.dispatch({
			type: "CUSTOMERS_LIST_STATE_CHANGE_SORT",
			payload: {
				sort
			}
		});
		fetchCustomersList();
	};

	render() {
		const {
			customersList,
			customersListState,
			configItems,
			selectedModule: { productTypeOptions }
		} = this.props;
		const {
			searchKW,
			limit,
			offset,
			currentFilters,
			searchFieldSelected,
			currentDateFilter,
			appliedDateFilter,
			sortedField
		} = customersListState;
		let filterCount = 0;
		for (let f in currentFilters) {
			if (currentFilters[f].value != "") {
				filterCount++;
			}
		}
		// map filters to mark them async when applicable
		const filtersOptions = customersList.data.filters
			.map((f) => {
				if (f.type === "MULTIPLE" && f.field === "signup_store") {
					f = {
						...f,
						isAsync: true,
						asyncOptions: this.props.configItems.stores,
						asyncLookup: this.state.storesLookup,
						updateAsyncLookup: this.updateStoresLookup,
						handleAsyncSearch: fetchStoresDebounced,
						labelKey: "name",
						valueKey: "id"
					};
				} else if (f.type === "MULTIPLE" && f.field === "tags") {
					f = {
						...f,
						isAsync: true,
						asyncOptions: this.props.configItems.tags,
						asyncLookup: this.state.tagsLookup,
						updateAsyncLookup: this.updateTagsLookup,
						handleAsyncSearch: fetchTagsDebounced,
						labelKey: "name",
						valueKey: "id"
					};
				}
				return f;
			})
			.filter((f) => f.type !== "DATE");

		return (
			<div className="transactions-container customers-list">
				<div className="transaction-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filtersOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						searchFieldSelected={searchFieldSelected}
						handleSearchField={this.handleSearchField}
						searchKW={searchKW}
						handleSearchKW={this.handleSearchKW}
						searchCustomer={this.searchCustomer}
						searchCustomerDebounced={this.searchCustomerDebounced}
						cancelSearchCustomer={this.cancelSearchCustomer}
						dimensions={configItems.dimensions}
					/>
					<div className="customers-filters">
						<NewDateCompareFilter
							showDropdown={true}
							loading={customersList.loading}
							currentDateFilter={currentDateFilter}
							appliedDateFilter={appliedDateFilter}
							updateState={this.updateCustomersListState}
							includeAllTime={true}
							hidePresetTypes={["15 D"]}
						/>
						{configItems.dimensions.width > 768 && (
							<div
								className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}
							>
								<div className="container" onClick={this.flipShowFilters}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
									</div>
								</div>
							</div>
						)}
						<div className="search-input-holder customer">
							<SelectFilter
								isSearchable={false}
								options={CUSTOMERS_LIST_SEARCH_FIELDS}
								currValue={searchFieldSelected}
								isClearable={false}
								setFilter={(field, value) => this.handleSearchField(value)}
								labelKey="title"
							/>
							<InputField
								classes="search-input"
								type="text"
								placeholder="Search"
								value={searchKW}
								onChange={this.handleAsyncSearch}
							/>
							{searchKW ? (
								<div onClick={this.cancelSearchCustomer} className="dismiss-search">
									<img className="" src="/assets/icons/cancel.png" />
								</div>
							) : null}
						</div>
					</div>
					<Table
						loading={customersList.loading}
						data={customersList.data.objects || []}
						currencySymbol={this.props.biz.currencySymbol}
						sortList={this.sortList}
						sortedField={sortedField}
						productTypeOptions={productTypeOptions}
						dimensions={configItems.dimensions}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={customersList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
				</div>
			</div>
		);
	}
}
