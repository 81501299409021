// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_USER_INVITE_DETAILS,
	USER_INVITE_OTP_GENERATION,
	USER_INVITE_OTP_VALIDATION,
	SAVE_USER_PROFILE,
	VERIFY_EXISTING_USER_PROFILE
} from "../graphql/acceptInvite";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { ActionTypes } from "./_types";

const parseUserInviteDetail = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			if (result["roles"]?.length) {
				result.roles = result.roles.map((role) => ({
					name: role
				}));
			}
			if (result["bizIsdCodes"]?.length) {
				result.bizIsdCodes = result.bizIsdCodes.map((isd) => ({
					...isd,
					name: `${isd.name} (${isd.code})`,
					country: isd.name
				}));
				result.bizIsdCode = result.bizIsdCodes.find((code) => code.default === true);
			}
			break;
		case "server":
			if (result["roles"]) {
				result.roles = result.roles.map((role) => role.name);
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchUserInviteDetail = async (uuid) => {
	store.dispatch({
		type: ActionTypes.GET_INVITE_DETAIL_REQUEST
	});
	try {
		const variables = {
			uuid
		};
		const resp = await client.query({
			query: GET_USER_INVITE_DETAILS,
			variables,
			fetchPolicy: "no-cache"
		});
		if (resp.data.userInviteDetails.status.success) {
			store.dispatch({
				type: ActionTypes.GET_INVITE_DETAIL_SUCCESS,
				payload: parseUserInviteDetail(resp.data.userInviteDetails.object, "form")
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.GET_INVITE_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.userInviteDetails.status.messages)
			});
			store.dispatch({
				type: ActionTypes.UPDATE_INVITE_DETAIL,
				payload: parseUserInviteDetail(resp.data.userInviteDetails.object, "form")
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_INVITE_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Invite details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const generateOtp = async (variables) => {
	store.dispatch({
		type: ActionTypes.OTP_GENERATION_REQUEST,
		payload: { [variables.field === "phone" ? "phoneLoading" : "emailLoading"]: true }
	});
	try {
		const resp = await client.mutate({
			mutation: USER_INVITE_OTP_GENERATION,
			variables
		});
		if (resp.data.userInviteOtpGeneration.status.success) {
			store.dispatch({
				type: ActionTypes.OTP_GENERATION_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "OTP has been sent successfully!",
					timeout: 5000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.OTP_GENERATION_FAILURE,
				error: parseErrorMessages(resp.data.userInviteOtpGeneration.status.messages)
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.userInviteOtpGeneration.status.messages),
					timeout: 5000,
					error: true
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.OTP_GENERATION_FAILURE,
			error: {
				message: "There was an error while generating OTP",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const verifyOtp = async (variables) => {
	store.dispatch({
		type: ActionTypes.OTP_VALIDATION_REQUEST,
		payload: { [variables.field === "phone" ? "phoneOtpLoading" : "emailOtpLoading"]: true }
	});
	try {
		const resp = await client.mutate({
			mutation: USER_INVITE_OTP_VALIDATION,
			variables
		});
		if (resp.data.userInviteOtpValidation.status.success) {
			store.dispatch({
				type: ActionTypes.OTP_VALIDATION_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: `${
						variables.field === "phone" ? "Phone number" : "Email"
					} has been successfully verified!`,
					timeout: 5000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.OTP_VALIDATION_FAILURE,
				error: parseErrorMessages(resp.data.userInviteOtpValidation.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.OTP_VALIDATION_FAILURE,
			error: {
				message: `There was an error while verifying your ${
					variables.field === "phone" ? "Phone number" : "Email"
				}`,
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const saveUserProfile = async (data) => {
	store.dispatch({
		type: ActionTypes.EDIT_INVITE_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: SAVE_USER_PROFILE,
			variables: parseUserInviteDetail(data, "server")
		});
		if (resp.data.saveUser.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_INVITE_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Your profile has been saved. User created successfully!",
					timeout: 3000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_INVITE_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveUser.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_INVITE_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving your profile details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const verifyExistingUserProfile = async (data) => {
	store.dispatch({
		type: ActionTypes.EDIT_INVITE_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: VERIFY_EXISTING_USER_PROFILE,
			variables: { input: data }
		});
		if (resp.data.updateExistingUser.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_INVITE_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Your profile has been verified and updated successfully!",
					timeout: 3000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_INVITE_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.updateExistingUser.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_INVITE_DETAIL_FAILURE,
			error: {
				message: "There was an error while updating your profile details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};
