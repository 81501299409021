import React, { useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Textarea } from "../_commons/Textarea";
import { Modal } from "../_commons/Modal";
import CascadeLocationsPrice from "../EntityAssociations/CascadeLocationsPrice";

// third party
import { connect } from "react-redux";

//utils
import { capitaliseTextStrict, printCurrency } from "../../atlas-utils";

// actions
import { fetchCiModifierGroupsDebounced } from "../../actions/actions";

// constants
import { FOOD_TYPES, LOCATION_ENTITY_ASSOCIATION_TYPES } from "../../client-config";

const BasicInfo = ({
	data,
	handleForm,
	validations,
	currencySymbol,
	showLanguages = false,
	currSelectedLang = {},
	handleLanguage,
	handleTranslation,
	saveTranslation,
	fetchItemTags,
	modifierGroups = {},
	handleNestedModifierGroups = undefined,
	readOnly = true,
	setModalBusy,
	cascadePriceModalOpen,
	handleModal,
	showCascade = true,
	isViewedFromMenuSection,
	menuSectionInfo,
	biz = {}
}) => {
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;
	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
		handleModal();
	};

	const closeAssociationSidebar = () => {
		setAssociationSidebar(false);
		setModalBusy(false);
	};

	return (
		<React.Fragment>
			<div className="form-row row-half">
				<InputWithLabel
					value={
						isViewedFromMenuSection
							? menuSectionInfo?.name
							: currSelectedLang.optionTitle
							? currSelectedLang.optionTitle.lang === "en"
								? data.optionTitle
								: currSelectedLang.optionTitle.value
							: data.optionTitle
					}
					onChange={(e) =>
						isViewedFromMenuSection
							? handleForm("name", e.target.value)
							: showLanguages
							? handleTranslation("optionTitle", e.target.value)
							: handleForm("optionTitle", e.target.value)
					}
					requiredLabel={!showLanguages}
					showLanguages={showLanguages}
					currSelectedLang={(currSelectedLang.optionTitle && currSelectedLang.optionTitle.lang) || ""}
					handleLanguage={(lang) => handleLanguage(lang, "optionTitle")}
					showLoading={showLanguages}
					showActionBtn={currSelectedLang.optionTitle && currSelectedLang.optionTitle.showActionBtn}
					action={() =>
						saveTranslation(
							"optionTitle",
							currSelectedLang.optionTitle
								? currSelectedLang.optionTitle.lang === "en"
									? data.optionTitle
									: currSelectedLang.optionTitle.value
								: data.optionTitle
						)
					}
					field="option_title"
					validationMessage={validations.optionTitle || ""}
					readOnly={isViewedFromMenuSection ? false : readOnly}
					classes={
						currSelectedLang.optionTitle && currSelectedLang.optionTitle.showActionBtn
							? "at-input--save"
							: ""
					}
				>
					Title
				</InputWithLabel>
				<SelectFilter
					title="Food Type"
					options={FOOD_TYPES}
					readOnly={readOnly}
					field="foodType"
					currValue={
						isViewedFromMenuSection
							? { value: data?.foodType, label: capitaliseTextStrict(data?.foodType) }
							: data.foodType
					}
					setFilter={handleForm}
					labelKey="label"
					valueKey="value"
					requiredLabel={true}
					validationMessage={validations.foodType || ""}
				/>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={isViewedFromMenuSection ? menuSectionInfo?.price : data.optionPrice}
					type="number"
					onChange={(e) =>
						isViewedFromMenuSection
							? handleForm("price", e.target.value ? Number(e.target.value) : null)
							: handleForm("optionPrice", e.target.value ? Number(e.target.value) : null)
					}
					requiredLabel={true}
					validationMessage={validations.optionPrice || ""}
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={currencySymbol}
					showCustomTooltip={true}
					tooltipInfo="Price of the modifier as it's displayed to your customers"
					readOnly={isViewedFromMenuSection ? false : readOnly}
					showCascade={isMenuOverCatalogueEnabled ? false : showCascade && !readOnly}
					showPriceCascadeSection={openAssociationSidebar}
				>
					Default Sales Price
				</InputWithLabel>
				<InputWithLabel
					value={isViewedFromMenuSection ? data?.sortOrder : data.optionOrder}
					type="number"
					onChange={(e) => handleForm("optionOrder", e.target.value ? parseInt(e.target.value) : null)}
					requiredLabel={true}
					showCustomTooltip={true}
					tooltipInfo="Control ordering of modifier as they are displayed to your customers. Values closer to zero will push the modifier to top while higher values will push it towards bottom"
					tooltipPosition="up"
					validationMessage={validations.optionOrder || ""}
					readOnly={readOnly}
				>
					Sort Order
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={
						isViewedFromMenuSection
							? menuSectionInfo?.description
							: currSelectedLang.optionDesc
							? currSelectedLang.optionDesc.lang === "en"
								? data.optionDesc
								: currSelectedLang.optionDesc.value
							: data.optionDesc
					}
					onChange={(e) =>
						isViewedFromMenuSection
							? handleForm("description", e.target.value)
							: showLanguages
							? handleTranslation("optionDesc", e.target.value)
							: handleForm("optionDesc", e.target.value)
					}
					showLanguages={showLanguages}
					currSelectedLang={(currSelectedLang.optionDesc && currSelectedLang.optionDesc.lang) || ""}
					handleLanguage={(lang) => handleLanguage(lang, "optionDesc")}
					showLoading={true}
					showActionBtn={currSelectedLang.optionDesc && currSelectedLang.optionDesc.showActionBtn}
					action={() =>
						saveTranslation(
							"optionDesc",
							currSelectedLang.optionDesc
								? currSelectedLang.optionDesc.lang === "en"
									? data.optionDesc
									: currSelectedLang.optionDesc.value
								: data.optionDesc
						)
					}
					field="option_desc"
					readOnly={isViewedFromMenuSection ? false : readOnly}
					classes={
						currSelectedLang.optionDesc && currSelectedLang.optionDesc.showActionBtn
							? "at-textarea--save"
							: ""
					}
				>
					Description
				</Textarea>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={isViewedFromMenuSection ? data?.crmName : data.crmTitle || ""}
					onChange={(e) => handleForm("crmTitle", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Short text to easily identify and lookup the modifier"
					validationMessage={validations.crmTitle || ""}
					readOnly={readOnly}
				>
					CRM Title
				</InputWithLabel>
				<SelectFilter
					title="Tags"
					loadOptions={fetchItemTags}
					readOnly={readOnly}
					field="tags"
					currValue={data.tags}
					setFilter={handleForm}
					labelKey="titleForDisplay"
					valueKey="title"
					validationMessage={validations.tags || ""}
					isClearable={false}
					multi={true}
					asyncCreatable={true}
					ellipsized={true}
					placeholder="Create tags by pressing Tab/Enter"
				/>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.optionWeight}
					type="number"
					onChange={(e) => handleForm("optionWeight", e.target.value ? Number(e.target.value) : null)}
					validationMessage={validations.optionWeight || ""}
					readOnly={readOnly}
				>
					Weight
				</InputWithLabel>
				<InputWithLabel
					value={data.merchantRefId === "-1" ? "" : data.merchantRefId}
					onChange={(e) => handleForm("merchantRefId", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
					tooltipPosition="up"
					validationMessage={validations.merchantRefId || ""}
					readOnly={readOnly}
				>
					POS ID
				</InputWithLabel>
			</div>
			<CascadeLocationsPrice
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={LOCATION_ENTITY_ASSOCIATION_TYPES[0]}
				entityId={data.id}
				modifierPriceCascade={true}
				salesPrice={data.optionPrice}
				currencySymbol={currencySymbol}
			/>
			<Modal
				isOpen={cascadePriceModalOpen}
				close={handleModal}
				title="Cascade Price"
				subTitle=""
				showSubmitAction={true}
				submitTitle="Yes"
				submitAction={openAssociationSidebar}
				showCancelAction={true}
				cancelTitle="Not Now"
			>
				<div className="modal-gap-correction">
					The sales price has been updated from{" "}
					<strong>
						{printCurrency(currencySymbol)}
						{data.currentOptionPrice}
					</strong>{" "}
					to{" "}
					<strong>
						{printCurrency(currencySymbol)}
						{data.optionPrice}
					</strong>
					, would you like to update the overridden prices at locations? (If any)
				</div>
			</Modal>
		</React.Fragment>
	);
};
export default connect((store) => ({
	modifierGroups: store.configItems.modifierGroups,
	biz: store.login.loggedInbizDetail
}))(BasicInfo);
