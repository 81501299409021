import React from "react";

const FailureIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="76" height="67" viewBox="0 0 76 67" fill="none">
		<path
			d="M38 0.75L75.5 67H0.5L38 0.75ZM38.0262 59.5C39.4963 59.5 41.0713 57.7938 41.0713 55.7775C41.0713 53.6562 39.4963 52 38.0262 52C36.1875 52 34.9275 53.6562 34.9275 55.7775C34.9287 57.7938 36.1888 59.5 38.0262 59.5ZM39.25 48.25C39.25 48.25 40.3438 40.2912 40.5 37.62C40.7275 33.7275 40.5 22.025 40.5 22.025H35.5C35.5 22.025 35.2725 33.7275 35.5 37.62C35.6562 40.2912 36.75 48.25 36.75 48.25H39.25Z"
			fill="#FF425C"
		/>
	</svg>
);
export default React.memo(FailureIcon);
