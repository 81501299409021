import React, { Component } from "react";

// components
import { Header } from "../components/UnifiedUsersList/Header";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { CheckBox } from "../components/_commons/CheckBox";
import { Modal } from "../components/_commons/Modal";
import { CustomTable } from "../components/_commons/CustomTable";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";
import moment from "moment";
import { Transition, animated, config } from "react-spring/renderprops";

// actions
import { fetchUsersList, archiveRestoreUnifiedUser } from "../actions/unifiedUsers";
import { ActionTypes } from "../actions/_types";
import { handleAuthUserLogout } from "../actions/actions";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES } from "../client-config";
const MODAL_CONTENT = {
	single_logout: ["User will need to log in again in order to perform any role assigned to them."],
	bulk_logout: ["Users will need to log in again in order to perform any role assigned to them."],
	single_archive: [
		"Archived users will not be able to perform any of their previously assigned roles or log into their UrbanPiper account.",
		"You can reactivate their account anytime."
	],
	bulk_archive: [
		"Archived users will not be able to perform any of their previously assigned roles or log into their UrbanPiper account.",
		"You can reactivate their account anytime."
	],
	single_reactivate: [
		"Reactivating this user will give them access to all old permissions and roles. They will receive an email notification for reactivation.",
		"Changes to role and permissions can be made post reactivation."
	],
	bulk_reactivate: [
		"Reactivating users will give them access to all old permissions and roles. They will receive an email notification for reactivation.",
		"Changes to role and permissions can be made post reactivation."
	]
};
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, rest) => {
			const { id, fullName, email, isdCode, phone, emailVerified, phoneVerified, uleEmail, ulePhone } =
				record.app === "atlas"
					? record.atlasData?.[0]
					: record.app === "prime"
					? record.primeData?.[0]
					: record.app === "auth_service"
					? record.atlasData?.[0] || record.primeData?.[0]
					: {};
			const status =
				rest.users[record.app === "auth_service" ? record.authUserId : id] === undefined
					? rest.toCheck
					: rest.users[record.app === "auth_service" ? record.authUserId : id];
			return (
				<div
					className={"table-cell name"}
					title={fullName.trim() || uleEmail || email || ulePhone || phone || record.authUserId || id}
					key={i}
				>
					{rest.checkbox && (
						<CheckBox
							checked={status}
							clickHandler={(e) =>
								rest.handleCheck(e, record.app === "auth_service" ? record.authUserId : id, !status)
							}
							title={record.title || (record.app === "auth_service" ? record.authUserId : id)}
							readOnly={rest.disableCheckbox}
						/>
					)}
					<div className="name-desc">
						<Link
							className={"hyperlink hyperlink--black-color " + rest.archived}
							to={`/unified-access/edit/${record.app === "auth_service" ? record.authUserId : id}/${
								record.app
							}`}
						>
							{fullName.trim() || uleEmail || email || ulePhone || phone || record.authUserId || id}
						</Link>
						<div className="text--light desc-text">
							{fullName.trim()
								? record.app === "auth_service"
									? uleEmail ||
									  (ulePhone ? `${!ulePhone?.includes("+") ? isdCode : ""} ${ulePhone}` : "")
									: emailVerified && email
									? email
									: phoneVerified && phone
									? `${!phone?.includes("+") ? isdCode : ""} ${phone}`
									: ""
								: record.app === "auth_service"
								? uleEmail && ulePhone
									? `${!ulePhone?.includes("+") ? isdCode : ""} ${ulePhone}`
									: ""
								: email && phoneVerified && phone
								? `${!phone?.includes("+") ? isdCode : ""} ${phone}`
								: ""}
						</div>
					</div>
				</div>
			);
		}
	},
	{
		name: "Last Active",
		field: "last-active",
		render: (record, i) => {
			const { atlasData, primeData } = record;
			return (
				<div className={"table-cell last-active"} key={i}>
					{atlasData?.[0]?.lastLoginAt
						? moment(atlasData?.[0]?.lastLoginAt).format("DD MMM, YYYY - hh:mm A")
						: primeData?.[0]?.lastLoginAt
						? moment(primeData?.[0]?.lastLoginAt).format("DD MMM, YYYY - hh:mm A")
						: "--"}
				</div>
			);
		}
	},
	{
		name: "Atlas",
		field: "atlas",
		render: (record, i) => {
			const { atlasData } = record;
			return (
				<div className={"table-cell atlas"} key={i}>
					{atlasData?.filter((app) => app.isActive)?.length > 0
						? atlasData
								?.filter((app) => app.isActive)
								?.map((app, j) => {
									return app.appBizName && app.roles && app.roles?.length > 0 ? (
										<div className="app" key={j}>
											{atlasData.length > 1 && <div className="app-name">{app.appBizName}</div>}
											<div className="roles-list">
												{app.roles.map((role, k) => (
													<span key={k} className="role" title={role.name}>
														{role.name}
														{k !== app.roles?.length - 1 ? ", " : ""}
													</span>
												))}
											</div>
										</div>
									) : (
										"--"
									);
								})
						: "--"}
				</div>
			);
		}
	},
	{
		name: "Prime",
		field: "prime",
		render: (record, i) => {
			const { primeData } = record;
			return (
				<div className={"table-cell prime"} key={i}>
					{primeData?.filter((app) => app.isActive)?.length > 0
						? primeData
								?.filter((app) => app.isActive)
								?.map((app, j) => {
									return app.appBizName && app.roles && app.roles?.length > 0 ? (
										<div className="app" key={j}>
											<div className="app-name">{app.appBizName}</div>
											<div className="roles-list">
												{app.roles.map((role, k) => (
													<span key={k} className="role" title={role.name}>
														{role.name}
														{k !== app.roles?.length - 1 ? ", " : ""}
													</span>
												))}
											</div>
										</div>
									) : (
										"--"
									);
								})
						: "--"}
				</div>
			);
		}
	}
];

@connect((store) => ({
	unifiedUsersList: store.unifiedUsersList,
	unifiedUsersListState: store.unifiedUsersListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	user: store.login.loginDetail,
	access: store.login.loginDetail.access
}))
export class UnifiedUsersList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			search: "",
			archivedFilter: false,
			toCheck: false,
			isCheckedAll: false,
			users: {},
			usersData: [],
			contextMenuData: {},
			contextMenu: { id: null },
			isModalOpen: false,
			modalContent: {
				type: "",
				title: "",
				content: [],
				btnTitle: ""
			},
			userActionLoading: false,
			userActionSuccess: {
				status: false,
				message: ""
			},
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "users_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.unifiedUsersListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Users list
		await fetchUsersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updateusersListState = (payload) => {
		store.dispatch({
			type: ActionTypes.UNIFIED_USERS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateusersListState({
			currentFilters: this.props.unifiedUsersListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.unifiedUsersListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateusersListState({
			currentFilters
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.UNIFIED_USERS_LIST_SEARCH,
			payload: { [field]: value }
		});
		if (this.props.unifiedUsersList.data.searchFieldValue) {
			this.applySearchFilter();
		}
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.UNIFIED_USERS_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.updateusersListState({
			offset: 0
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchUsersList(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateusersListState({
			appliedFilters: {
				...this.props.unifiedUsersListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "users_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchUsersList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}

		const { appliedFilters } = store.getState().unifiedUsersListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updateusersListState({
					currentFilters: {
						roles: { valueForDisplay: "All Roles", value: "" }
					},
					appliedFilters: {
						roles: { valueForDisplay: "All Roles", value: "" }
					},
					offset: 0
				});
				await fetchUsersList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.unifiedUsersListState;
		const offset = (page - 1) * limit;
		this.updateusersListState({
			offset
		});
		// fetch new Timing Groups list
		fetchUsersList();
		this.setState({
			isCheckedAll: false
		});
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.unifiedUsersListState;
		if (size && size?.value !== limit) {
			this.updateusersListState({
				[field]: size.value
			});
			// fetch new Timing Groups list
			await fetchUsersList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateusersListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.UNIFIED_USERS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchUsersList();
	};

	handleFilters = (field, value) => {
		let newCurrentFilters = {
			...this.props.unifiedUsersListState.currentFilters
		};
		newCurrentFilters[field] = value;
		this.updateusersListState({
			currentFilters: newCurrentFilters,
			appliedFilters: newCurrentFilters,
			offset: 0
		});
		this.applySearchFilter();
	};

	handleCheck = (e, userId, toSelect) => {
		e.stopPropagation();
		let userUpdates = this.state.usersData.filter((user) => {
			const { id } =
				user.app === "atlas"
					? user.atlasData?.[0]
					: user.app === "prime"
					? user.primeData?.[0]
					: user.app === "auth_service"
					? user.atlasData?.[0] || user.primeData?.[0]
					: {};
			return this.state.users[user.app === "auth_service" ? user.authUserId : id];
		});
		if (toSelect) {
			userUpdates.push(
				this.props.unifiedUsersList.data.objects.find((user) => {
					const { id } =
						user.app === "atlas"
							? user.atlasData?.[0]
							: user.app === "prime"
							? user.primeData?.[0]
							: user.app === "auth_service"
							? user.atlasData?.[0] || user.primeData?.[0]
							: {};
					return userId === (user.app === "auth_service" ? user.authUserId : id);
				})
			);
		} else {
			userUpdates = userUpdates.filter((user) => {
				const { id } =
					user.app === "atlas"
						? user.atlasData?.[0]
						: user.app === "prime"
						? user.primeData?.[0]
						: user.app === "auth_service"
						? user.atlasData?.[0] || user.primeData?.[0]
						: {};
				return userId !== (user.app === "auth_service" ? user.authUserId : id);
			});
		}
		this.setState({
			users: {
				...this.state.users,
				[userId]: toSelect
			},
			usersData: userUpdates
		});
		if (!toSelect) {
			this.setState({
				isCheckedAll: false
			});
		}
	};

	handleCheckAll = (e, toCheckAll) => {
		e.stopPropagation();
		if (toCheckAll) {
			let updates = {};
			this.props.unifiedUsersList.data.objects.forEach((user) => {
				const { id } =
					user.app === "atlas"
						? user.atlasData?.[0]
						: user.app === "prime"
						? user.primeData?.[0]
						: user.app === "auth_service"
						? user.atlasData?.[0] || user.primeData?.[0]
						: {};
				updates[user.app === "auth_service" ? user.authUserId : id] = toCheckAll;
			});
			let userUpdates = this.props.unifiedUsersList.data.objects.filter((user) => {
				const { id } =
					user.app === "atlas"
						? user.atlasData?.[0]
						: user.app === "prime"
						? user.primeData?.[0]
						: user.app === "auth_service"
						? user.atlasData?.[0] || user.primeData?.[0]
						: {};
				return !this.state.users[user.app === "auth_service" ? user.authUserId : id];
			});
			this.setState({
				isCheckedAll: toCheckAll,
				users: {
					...this.state.users,
					...updates
				},
				usersData: [
					...this.state.usersData.filter((user) => {
						const { id } =
							user.app === "atlas"
								? user.atlasData?.[0]
								: user.app === "prime"
								? user.primeData?.[0]
								: user.app === "auth_service"
								? user.atlasData?.[0] || user.primeData?.[0]
								: {};
						return this.state.users[user.app === "auth_service" ? user.authUserId : id];
					}),
					...userUpdates
				]
			});
		} else {
			let updates = {};
			this.props.unifiedUsersList.data.objects.forEach((user) => {
				const { id } =
					user.app === "atlas"
						? user.atlasData?.[0]
						: user.app === "prime"
						? user.primeData?.[0]
						: user.app === "auth_service"
						? user.atlasData?.[0] || user.primeData?.[0]
						: {};
				updates[user.app === "auth_service" ? user.authUserId : id] = toCheckAll;
			});
			let userUpdates = this.state.usersData.filter((user) => {
				const { id } =
					user.app === "atlas"
						? user.atlasData?.[0]
						: user.app === "prime"
						? user.primeData?.[0]
						: user.app === "auth_service"
						? user.atlasData?.[0] || user.primeData?.[0]
						: {};
				return !((user.app === "auth_service" ? user.authUserId : id) in updates);
			});
			this.setState({
				isCheckedAll: toCheckAll,
				users: {
					...this.state.users,
					...updates
				},
				usersData: [...userUpdates]
			});
		}
	};

	handleUserActions = async (action = "logout") => {
		this.setState({
			userActionLoading: true
		});
		if (action === "logout") {
			let appUserIds = [];
			this.state.usersData.forEach((user) => {
				const { id } =
					user.app === "atlas"
						? user.atlasData?.[0]
						: user.app === "prime"
						? user.primeData?.[0]
						: user.app === "auth_service"
						? user.atlasData?.[0] || user.primeData?.[0]
						: {};
				appUserIds.push(user.app === "auth_service" ? user.authUserId : id);
			});
			const variables = { appUserIds };
			const resp = await handleAuthUserLogout(variables);
			if (resp?.status?.success) {
				this.setState({
					userActionSuccess: {
						status: true,
						message: "User has been logged out from all devices successfully"
					}
				});
			}
		} else if (action === "reactivate") {
			const resp = await archiveRestoreUnifiedUser(
				{ ...(this.state.usersData?.[0] ?? {}), isActive: true },
				true
			);
			if (resp) {
				this.setState({
					userActionSuccess: {
						status: true,
						message: "User has been reactivated successfully"
					}
				});
			}
		} else if (action === "archive") {
			const resp = await archiveRestoreUnifiedUser(
				{ ...(this.state.usersData?.[0] ?? {}), isActive: false },
				true
			);
			if (resp) {
				this.setState({
					userActionSuccess: {
						status: true,
						message: "User has been archived successfully"
					}
				});
			}
		}
		this.clearSelection();
		this.setState({
			userActionLoading: false
		});
	};

	handleModal = (type = "logout") => {
		const { isModalOpen, usersData } = this.state;
		if (!isModalOpen) {
			this.setState({
				isModalOpen: true,
				modalContent: {
					type,
					title:
						type === "logout"
							? usersData.length > 1
								? `Log Out ${usersData.length} Users?`
								: "Log Out User?"
							: type === "reactivate"
							? usersData.length > 1
								? `Reactivate ${usersData.length} Users?`
								: "Reactivate User?"
							: usersData.length > 1
							? `Archive ${usersData.length} Users?`
							: "Archive User?",
					content:
						type === "logout"
							? usersData.length > 1
								? MODAL_CONTENT["bulk_logout"]
								: MODAL_CONTENT["single_logout"]
							: type === "reactivate"
							? usersData.length > 1
								? MODAL_CONTENT["bulk_reactivate"]
								: MODAL_CONTENT["single_reactivate"]
							: usersData.length > 1
							? MODAL_CONTENT["bulk_archive"]
							: MODAL_CONTENT["single_archive"],
					btnTitle:
						type === "logout" ? "Log Out of All Devices" : type === "reactivate" ? "Reactivate" : "Archive"
				},
				contextMenu: {
					id: null
				}
			});
		} else {
			if (this.state.userActionSuccess.status) {
				fetchUsersList();
			}
			this.setState({
				isModalOpen: false,
				userActionLoading: false,
				userActionSuccess: {
					status: false,
					message: ""
				},
				modalContent: {
					type: "",
					title: "",
					content: [],
					btnTitle: ""
				}
			});
		}
	};

	clearSelection = () => {
		this.setState({
			isCheckedAll: false,
			users: {},
			usersData: [],
			filtersUsed: {},
			contextMenu: {
				id: null
			}
		});
	};

	renderMenuItems = (record) => {
		if (this.state.archivedFilter) {
			return (
				<React.Fragment>
					<div
						className="action-item"
						onClick={() => this.handleModal("reactivate")}
						style={{ color: "#2ecc71" }}
					>
						Reactivate
					</div>
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				{/* <div className="action-item" onClick={() => this.handleModal('logout')}>Log out of all devices</div> */}
				<div className="action-item" onClick={() => this.handleModal("archive")} style={{ color: "#FF425C" }}>
					Archive
				</div>
			</React.Fragment>
		);
	};

	openContextMenu = (record = {}, rest) => {
		this.setState({
			contextMenu: {
				id:
					String(this.state.contextMenu.id) && this.state.contextMenu.id === rest.rowIndex
						? null
						: rest.rowIndex
			},
			contextMenuData: { ...record },
			usersData: [(this.props.unifiedUsersList.data.objects ?? []).find((user, i) => i === rest.rowIndex)]
		});
	};

	closeContextMenu = () => {
		this.setState({
			contextMenu: {
				id: null
			}
		});
	};

	extractInitials = (name) => {
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(name[0].length - 1);
		}
		return initials.toUpperCase();
	};

	handleContextMenuCondition = (data) => {
		// disable context menu if the user details in the table row is of the logged in user
		const atlasData = data.atlasData?.length > 0 ? data?.atlasData?.[0] : {};
		if (this.props.user.id === atlasData?.appUserId) {
			return true;
		}
		return false;
	};

	render() {
		const { unifiedUsersList, unifiedUsersListState, configItems, access } = this.props;
		const {
			toCheck,
			isCheckedAll,
			archivedFilter,
			contextMenu,
			users,
			usersData,
			isModalOpen,
			modalContent,
			userActionSuccess,
			userActionLoading
		} = this.state;
		const { limit, offset, currentFilters, appliedFilters } = unifiedUsersListState;

		let filterCount = 0;
		for (let f in appliedFilters) {
			if (f !== "roles") {
				if (f === "is_active" && appliedFilters[f]) {
					filterCount++;
				} else if (f !== "search") {
					if (appliedFilters[f].value && appliedFilters[f].value != "") {
						filterCount++;
					}
				}
			}
		}

		let switchActions = false;
		for (let id in users) {
			if (users[id] === true) {
				switchActions = true;
				break;
			}
		}

		const filterOptions = unifiedUsersList.data?.filters?.filter((f) => f.field !== "roles");

		return (
			<div>
				<div ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					{/* <div className="information">
						<img src="/assets/icons/icon-wand.svg" alt="" />
						<div>All users across businesses for <b>Atlas & Prime</b> can be managed via a single screen</div>
						<div className="know-more">Know More</div>
					</div> */}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						searchKeywords={unifiedUsersList.data.searchKeywords}
						searchFieldSelected={unifiedUsersList.data.searchFieldSelected}
						searchFieldValue={unifiedUsersList.data.searchFieldValue}
						handleSearchField={this.handleSearchField}
						handleFilters={this.handleFilters}
						appliedFilters={appliedFilters}
						filters={unifiedUsersList.data?.filters}
						setFilter={this.setSearchFilter}
						applySearchFilter={this.applySearchFilter}
						handlePiperAcademy={this.handlePiperAcademy}
						bizName={this.props.biz.name}
					/>
					<Transition
						native
						items={switchActions}
						from={{ opacity: 0, height: 0 }}
						enter={{ opacity: 1, height: "auto" }}
						leave={{ opacity: 0, height: 0 }}
						config={config.stiff}
					>
						{(isOpen) =>
							isOpen &&
							((props) => (
								<animated.div style={props}>
									<Actions
										handleModal={this.handleModal}
										archived={archivedFilter}
										clearSelection={this.clearSelection}
										count={usersData.length || 0}
									/>
								</animated.div>
							))
						}
					</Transition>
					<CustomTable
						loading={unifiedUsersList.loading}
						data={unifiedUsersList.data.objects || []}
						columns={columns}
						extractInitials={this.extractInitials}
						archived={archivedFilter ? "archived" : ""}
						handleCheck={this.handleCheck}
						handleCheckAll={this.handleCheckAll}
						// checkbox={access.isAdmin}
						toCheck={toCheck}
						isCheckedAll={isCheckedAll}
						users={users}
						showContextMenu={access.isAdmin}
						contextMenuCondition={this.handleContextMenuCondition}
						disableContextMenu={switchActions}
						renderMenuItems={this.renderMenuItems}
						openContextMenu={this.openContextMenu}
						closeContextMenu={this.closeContextMenu}
						contextMenuId={contextMenu.id}
						classes="users-list-table-container"
						content="Users"
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={unifiedUsersList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
					<Modal
						isOpen={isModalOpen}
						close={this.handleModal}
						title={modalContent.title}
						subTitle=""
						loading={userActionLoading}
						showSubmitAction={!userActionSuccess.status && modalContent.type === "reactivate"}
						submitTitle={modalContent.btnTitle}
						submitAction={() => this.handleUserActions(modalContent.type)}
						showDeleteAction={!userActionSuccess.status && modalContent.type !== "reactivate"}
						deleteTitle={modalContent.btnTitle}
						deleteAction={() => this.handleUserActions(modalContent.type)}
						deleteButtonWidth={modalContent.type === "logout" ? "200" : "100"}
						showCancelAction={true}
						cancelTitle={userActionSuccess.status ? "Dismiss" : "Cancel"}
					>
						{userActionSuccess.status ? (
							<div className="success">{userActionSuccess.message}</div>
						) : (
							modalContent.content.map((content, i) => (
								<div className="description" key={i}>
									{content}
								</div>
							))
						)}
					</Modal>
					<NestedEntityContainer
						show={this.state.nestedEntity.show}
						type={this.state.nestedEntity.type}
						id={this.state.nestedEntity.id}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
						isNested={false}
						isForeignSource={true}
					/>
				</div>
			</div>
		);
	}
}

const Actions = ({ handleModal, clearSelection, archived = false, count = 0 }) => {
	return (
		<div className="bulk-actions">
			<div className="count">
				{count} users(s) selected
				<span onClick={clearSelection}>Clear selection</span>
			</div>
			<div className="actions-container">
				{archived ? (
					<div className="action restore" onClick={() => handleModal("reactivate")}>
						Reactivate
					</div>
				) : (
					<React.Fragment>
						{/* <div className="action" onClick={() => handleModal('logout')}>Log out of all devices</div> */}
						<div className="action archive" onClick={() => handleModal("archive")}>
							Archive
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};
