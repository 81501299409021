import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/DiscountEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchTimingGroups } from "../actions/actions";
import { fetchDiscountsList } from "../actions/discounts";
import { ActionTypes } from "../actions/_types";

// graphql
import { GET_PLATFORMS_DISCOUNTS, CREATE_ZOMATO_DISCOUNT, CREATE_UBEREATS_DISCOUNT } from "../graphql/discounts";

// constant
export const DISCOUNT_MUTATIONS = {
	zomato: {
		mutation: CREATE_ZOMATO_DISCOUNT,
		field: "saveZomatoDiscount"
	},
	ubereats: {
		mutation: CREATE_UBEREATS_DISCOUNT,
		field: "saveUberEatsDiscount"
	}
};

const DiscountCreate = ({ selectedPlatform, configItems }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(true);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [platforms, setPlatforms] = useState([]);
	const [data, setData] = useState({
		platformName: "",
		title: "",
		type: null,
		startsOn: "",
		expiresOn: "",
		value: 0,
		minOrderAmount: 0,
		processOnCatalogue: false,
		timingsGroup: null,
		oneTimeUse: false,
		desc: ""
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchTimingGroups();
	}, []);

	useEffect(() => {
		if (configItems.bizPlatforms.items.length) {
			fetchPlatforms();
		} else {
			setConfirmLoading(false);
		}
	}, [configItems.bizPlatforms]);

	const handleCancel = () => {
		setFormState(false);
		if (selectedPlatform) {
			fetchDiscountsList(selectedPlatform);
		}
		setTimeout(() => {
			history.push("/discounts");
		}, 100);
	};

	const fetchPlatforms = async () => {
		setConfirmLoading(true);
		try {
			const resp = await client.query({
				query: GET_PLATFORMS_DISCOUNTS,
				fetchPolicy: "no-cache"
			});
			const platformsList = resp.data.discountPlatforms.filter(
				(plf) =>
					configItems.bizPlatforms.items.find(
						(p) => p.platformName.toLowerCase() === plf.valueForDisplay.toLowerCase()
					) !== undefined
			);
			setPlatforms(platformsList);
			setData({
				...data,
				platformName: platformsList.length ? platformsList[0] : "",
				type: platformsList.length && platformsList[0].discountTypes ? platformsList[0].discountTypes[0] : null
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	};

	const handleForm = (formData) => {
		setData({
			...data,
			...formData
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			if (variables.type) {
				variables.type = variables.type.value;
			}
			if (variables.startsOn) {
				variables.startsOn = variables.startsOn.split(" ").join("T");
			}
			if (variables.expiresOn) {
				variables.expiresOn = variables.expiresOn.split(" ").join("T");
			}
			if (variables.timingsGroup) {
				variables.timingsGroup = parseInt(variables.timingsGroup.id);
			}
			const api = DISCOUNT_MUTATIONS[data.platformName.value.toLowerCase()];
			if (api) {
				const resp = await client.mutate({
					mutation: api.mutation,
					variables
				});
				if (resp.data[api.field].status.success) {
					setConfirmLoading(false);
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Discount created",
							timeout: 5000,
							error: false
						}
					});
					store.dispatch({
						type: ActionTypes.UPDATE_DISCOUNTS_LIST,
						payload: {
							discount: resp.data[api.field].object,
							selectedPlatform: variables.platformName
						}
					});
					if (selectedPlatform !== variables.platformName.value) {
						fetchDiscountsList(variables.platformName.value);
					}
					history.push(`/discounts/edit/${resp.data[api.field].object.id}`);
				} else {
					setConfirmLoading(false);
					setError(parseErrorMessages(resp.data[api.field].status.messages));
				}
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Discount"
			subTitle="Create a new discount"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				{!confirmLoading && platforms.length === 0 && (
					<div className="no-items-placeholder">
						External platform association not enabled. Please contact Account Manager for more details.
					</div>
				)}
				{platforms.length > 0 && (
					<BasicInfo
						data={data}
						handleForm={handleForm}
						validations={error.fields || {}}
						platforms={platforms}
					/>
				)}
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	selectedPlatform: store.discountsList.selectedPlatform.value,
	configItems: store.configItems
});
export default connect(mapStateToProps)(DiscountCreate);
