import React from "react";

// component
import MonogramGenerator from "../_commons/MonogramGenerator";
import Image from "../_commons/Image";

// third party
import { Transition, animated, config } from "react-spring/renderprops";

const Header = ({ brandName, currentProgressLevel, imgSrc, previewCanvasRef }) => {
	return (
		<div className="brand-creation-flow--header">
			<div>Create Brand</div>
			<BrandIconAndLogo
				currentProgressLevel={currentProgressLevel}
				brandName={brandName}
				imgSrc={imgSrc}
				previewCanvasRef={previewCanvasRef}
			/>
		</div>
	);
};
export default Header;

const BrandIconAndLogo = ({ currentProgressLevel, brandName = "", previewCanvasRef }) => (
	<Transition
		native
		from={{ opacity: 0 }}
		enter={{ opacity: 1 }}
		leave={{ opacity: 0 }}
		items={currentProgressLevel > 0}
		config={config.stiff}
	>
		{(isOpen) =>
			isOpen &&
			((props) => (
				<animated.div className="brand-name" style={props}>
					<div className="logo-image-container">
						{previewCanvasRef.current ? (
							<Image src={previewCanvasRef.current} alt={brandName} className="logo-img" />
						) : !brandName ? (
							<img
								className="empty-img-placeholder"
								src="/assets/icons/icon-empty-img.svg"
								alt="empty logo"
							/>
						) : (
							<MonogramGenerator value={brandName} size="msmall" />
						)}
					</div>
					{brandName}
				</animated.div>
			))
		}
	</Transition>
);
