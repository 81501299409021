// action types
import { ActionTypes } from "../actions/_types";

const MENUS_PULL_INFO_INIT_STATE = {
	ongoingMenuPull: null,
	canPullMenu: false,
	canPullLocationMenu: false,
	menuPullPOS: null
};

export const menusPullInfoState = (state = MENUS_PULL_INFO_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MENU_PULL_INFO_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.GET_MENU_PULL_INFO_REQUEST:
		case ActionTypes.GET_MENU_PULL_INFO_FAILURE:
			return {
				...state,
				ongoingMenuPull: null,
				canPullMenu: false,
				canPullLocationMenu: false,
				menuPullPOS: null
			};
		case ActionTypes.GET_MENU_PULL_INFO_UPDATE:
			return {
				...state,
				ongoingMenuPull: {
					status: action.payload.status,
					message: action.payload.message
				}
			};
		default:
			return state;
	}
};
