import React from "react";

export const RightClickMenuContext = ({
	children,
	anchorPoint = { x: 0, y: 0 },
	isUnbordered = false,
	isPositionFixed
}) => {
	return (
		<ul
			className={`right-click-menu ${isUnbordered ? "right-click-menu--unbordered" : ""} ${
				isPositionFixed ? "right-click-menu--fixed-pos" : ""
			}`}
			style={{
				top: anchorPoint.y,
				left: anchorPoint.x
			}}
		>
			{children}
		</ul>
	);
};
