import React, { useState } from "react";

// components
import { Switch } from "../_commons/Switch";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";

// client
import { store } from "../../store/configureStore";

const BulkCoupons = ({ couponId, data = {}, handleForm, loading = false, showDownloadCsv = false }) => {
	const [currCode, setCurrCode] = useState("");
	const [confirmLoading, setConfirmLoading] = useState(false);

	const handleTagInput = (val) => {
		setCurrCode(val);
		if (data.codesValidationMsg) {
			handleForm("codesValidationMsg", "");
		}
	};

	const downloadBulkCouponCodes = async () => {
		try {
			setConfirmLoading(true);
			const token = store.getState().login.loginDetail.token;
			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`
			};
			const url = `${process.env.REACT_APP_API_URL_BULK_COUPONS}${couponId}`;
			const res = await fetch(url, { headers });
			const blob = await res.blob();

			// set the blob type to final csv
			const file = new Blob([blob], { type: "text/csv" });

			// process to auto download it
			const fileURL = URL.createObjectURL(file);
			const link = document.createElement("a");
			link.href = fileURL;
			link.download = "bulk_coupon_codes.csv";
			link.click();
		} catch (err) {
			console.log(err);
		}
		setConfirmLoading(false);
	};

	return (
		<div className={"bulk-coupons-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Bulk Coupons</div>
					<div className="header-subtext">
						Generating bulk coupons will overwrite redemption code. This step is irreversible.
					</div>
				</div>
				{showDownloadCsv && (
					<div className="link-text" onClick={downloadBulkCouponCodes}>
						{!confirmLoading && <img src="/assets/icons/icon-csv-download.svg" />}
						{confirmLoading && <div class="loader"></div>}
						<div>Download Codes</div>
					</div>
				)}
			</div>
			<div className="form-row row-half">
				<Switch
					title="Generate coupon codes"
					checked={data.generateCouponCodes}
					clickHandler={() => handleForm("generateCouponCodes", !data.generateCouponCodes)}
					readOnly={data.readOnly}
				/>
				<InputWithLabel
					value={data.maxUsage}
					type="number"
					onChange={(e) => handleForm("maxUsage", e.target.value ? parseInt(e.target.value) : null)}
					requiredLabel={true}
					readOnly={data.readOnly}
				>
					Max times coupon code can be used
				</InputWithLabel>
			</div>
			<div className={"form-row " + (data.generateCouponCodes ? "row-half" : "row-full")}>
				{data.generateCouponCodes ? (
					<InputWithLabel
						value={data.numCodes}
						type="number"
						onChange={(e) => handleForm("numCodes", e.target.value ? parseInt(e.target.value) : null)}
						requiredLabel={true}
						readOnly={data.readOnly}
					>
						Number of coupon codes to generate
					</InputWithLabel>
				) : (
					<TagsInputWrapper
						tags={data.staticCodes || []}
						onChange={(tags) => handleForm("staticCodes", tags)}
						tagInput={currCode}
						onChangeInput={handleTagInput}
						requiredLabel={true}
						validationMessage={data.codesValidationMsg}
						showDropdown={true}
						readOnly={data.readOnly}
					>
						Coupon codes
					</TagsInputWrapper>
				)}
			</div>
		</div>
	);
};
export default BulkCoupons;
