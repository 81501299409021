import React from "react";

const Tooltip = ({ cell, unitText = "locations" }) => {
	const totalLocations = cell.value.total;
	const onlineLocations = cell.value.available;
	let textDisplay = "";
	let classname = "";
	if (onlineLocations == 0 && totalLocations == 0) {
		classname = "all-offline-display";
		if (unitText == "locations") {
			textDisplay = (
				<span>
					{onlineLocations}/{totalLocations} locations online
				</span>
			);
		} else if (unitText == "items") {
			textDisplay = <span> The storefront does not exist </span>;
		}
	} else if (onlineLocations > 0 && onlineLocations !== totalLocations) {
		textDisplay = (
			<div style={{ width: "175px" }}>
				<span>
					{onlineLocations}/{totalLocations} {unitText} online
				</span>
				<br />
				<span style={{ color: "#8A8A8A" }}>Click on the cell to know more</span>
			</div>
		);
	} else if (onlineLocations == totalLocations && totalLocations > 0) {
		classname = "all-offline-display";
		textDisplay = `${totalLocations}/${totalLocations} ${unitText} online`;
	} else {
		classname = "all-offline-display";

		if (unitText == "locations") {
			textDisplay = (
				<span>
					{" "}
					{totalLocations}/{totalLocations} {unitText} offline{" "}
				</span>
			);
		} else if (unitText == "items") {
			textDisplay = (
				<span>
					This storefront is <span style={{ color: "#B02B2B" }}> Offline </span>
				</span>
			);
		}
	}
	return (
		<div className={`tooltiptext-item border ${classname}   `}>
			<div className="tooltip-description-item">{textDisplay}</div>
		</div>
	);
};

export default Tooltip;
