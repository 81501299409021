import React from "react";

// third party
import moment from "moment";
import { Link } from "react-router-dom";

// component
import { Button } from "../_commons/Button";
import { connect } from "react-redux";
import { createMerakiWebWorkspace, fetchMerakiWebThemesList } from "../../actions/merakiWeb";
import history from "../../history";
import { getPreviewUrl } from "../../services/common";
import ViewIconSVG from "../_commons/ViewIconSVG";

const viewIcon = "/assets/icons/icon-view-eye-gray.svg";
const merakiEditorRoute = "/meraki-web/editor";
const StatusTags = {
	"Publish failed": {
		label: "Publish failed",
		className: "failed-tag"
	},
	Published: {
		label: "Published",
		className: "published-tag"
	},
	"Publish scheduled": {
		label: "Publish scheduled",
		className: "inprogress-tag"
	},
	"Publish in progress": {
		label: "Publish in progress",
		className: "inprogress-tag"
	},
	Saved: {
		label: "Saved",
		className: "saved-tag"
	},
	New: {
		label: "New",
		className: "saved-tag"
	}
};

const Mockups = {
	desktop: "/assets/background/desktop_cut.png",
	mobile: "/assets/background/mobile_cut_1.png"
};
const DateFormat = "Do MMM YYYY hh:mm A";

const WorkspaceComponent = ({
	data,
	loading,
	error,
	merakiWebThemesList,
	publishConfig = { enabled: false, onClick: () => {} },
	bizId
}) => {
	const lastPublishData = React.useMemo(() => {
		if (data.lastPublish)
			return {
				published: true,
				data: {
					...data.lastPublish,
					theme: {
						unpublishedChanges: data.unpublishedChanges,
						...data.lastPublish.theme,
						...data.themeLibrary?.find?.((theme) => theme.name === data.theme.name)
					}
				}
			};

		return {
			published: false,
			data: {
				theme: data.themeLibrary?.find?.((theme) => theme.name === data.theme.name) ?? data.theme
			}
		};
	}, [data]);

	const availableThemes = React.useMemo(
		() => merakiWebThemesList?.data?.filter((theme) => theme?.name !== lastPublishData?.data?.theme?.name),
		[merakiWebThemesList, lastPublishData]
	);

	React.useEffect(() => {
		fetchMerakiWebThemesList();
	}, []);

	if (loading && !data.theme) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	if (!loading && error) {
		return <div className="workspace-container no-items-placeholder error">{error.message}</div>;
	}

	if (!loading && !data.theme) {
		return null;
	}

	return (
		<div className={`workspace-container${loading && data.theme ? " disabled" : ""}`}>
			<div className="base-width-full base-shadow-normal">
				<div className="base-padding-large">
					<div className="base-row-direction base-gap-normal base-align-items-flex-start">
						<div className="base-flex-1">
							<div className="base-row base-gap-normal">
								<h4 className="base-capitalize base-margin-vertical-normal">
									{lastPublishData.data?.theme?.name}
								</h4>

								<div
									className={`status-tag ${
										!!lastPublishData.data.theme.unpublishedChanges
											? StatusTags["Saved"].className
											: StatusTags[data.status].className
									}`}
								>
									{!!lastPublishData.data.theme.unpublishedChanges
										? StatusTags["Saved"].label
										: StatusTags[data.status].label}
								</div>
							</div>

							{[StatusTags["Publish in progress"].label, StatusTags["Publish scheduled"].label].includes(
								data.status
							) && (
								<div
									className="base-margin-vertical-normal base-padding-normal base-row base-gap-normal publish-info base-font-14"
									style={{ display: "inline-flex" }}
								>
									<img src="/assets/icons/icon-caution.svg" alt="" />

									<span>Publish has started. Please check back here after 5-10 mins.</span>
								</div>
							)}

							{!!lastPublishData.data?.theme?.lastSaved && (
								<p className="base-color-black-60 base-margin-top-small base-font-14">
									Last saved by {lastPublishData.data.theme?.lastSaved?.user} on{" "}
									{moment(lastPublishData.data.theme?.lastSaved?.date).format(DateFormat)}
								</p>
							)}

							<div className="base-row base-font-14">
								{!!lastPublishData.published && (
									<React.Fragment>
										<span>
											<b>Current version : {lastPublishData.data.version}</b>
										</span>

										<span>&nbsp;|&nbsp;</span>
									</React.Fragment>
								)}

								<span>
									<b>Unpublished changes : {lastPublishData.data.theme.unpublishedChanges}</b>
								</span>
							</div>
						</div>

						<RightCTA
							viewUrl={data.netlify.cname && publishConfig.enabled && `https://${data.netlify.cname}`}
							published={lastPublishData.published}
							onClickCustomize={() => customizeAvailableTheme(lastPublishData.data.theme.name)}
							disabled={data.status !== StatusTags["Publish failed"].label && !publishConfig.enabled}
							showCustomize
							onClickPublish={() => publishConfig.onClick(lastPublishData.data.theme.name)}
						/>
					</div>

					<div className="base-width-full base-margin-top-large base-row-direction base-justify-content-center base-gap-large mockup-container">
						<div className="base-width-half base-position-relative base-row-direction base-justify-content-center">
							<img className="image-desktop-top" src={Mockups.desktop} loading={"eager"} />

							<img
								className="image-desktop-bottom"
								src={
									lastPublishData?.data?.version > 1
										? data.screenshotUrl || data.theme.featureImage
										: data.theme?.featureImage
								}
								loading={"eager"}
							/>
						</div>

						<div className="base-width-15 base-position-relative base-row-direction base-justify-content-center">
							<img className="image-mobile-top" src={Mockups.mobile} loading={"eager"} />

							<img
								className="image-mobile-bottom"
								src={
									lastPublishData?.data?.version > 1
										? data.mobileScreenshotUrl || data.theme.featureImageMobile
										: data.theme?.featureImageMobile
								}
							/>
						</div>
					</div>
				</div>

				{!!availableThemes.length && (
					<React.Fragment>
						<div className="base-divider-large" />

						<div className="base-padding-large">
							<h3 className="base-margin-vertical-normal">Available theme</h3>

							<div className="base-border-small base-border-color-black-20 base-padding-large base-column-direction base-gap-large">
								{availableThemes.map((theme) => {
									const previewUrl = getPreviewUrl(bizId, theme.name);

									const themeLibraryData = data.themeLibrary?.find?.((th) => th.name === theme.name);

									return (
										<div className="base-row base-gap-normal" key={theme.name}>
											<div className="base-flex-1 base-row base-gap-normal">
												<div
													style={{ width: "20%" }}
													className="base-border-small base-border-color-black-20 base-border-radius-smaller base-overflow-hidden"
												>
													<img className="base-width-full" src={theme.featureImage} />
												</div>

												<div className="base-flex-1">
													<div className="base-row base-gap-normal">
														<h4 className="base-capitalize base-margin-vertical-small">
															{theme.name}
														</h4>

														{!!themeLibraryData?.unpublishedChanges && (
															<div
																className={`status-tag ${StatusTags["Saved"].className}`}
															>
																{StatusTags["Saved"].label}
															</div>
														)}
													</div>

													{!!themeLibraryData?.lastSaved &&
														!!themeLibraryData?.unpublishedChanges && (
															<p className="base-color-black-60 base-margin-top-small base-font-14">
																Last saved by {themeLibraryData?.lastSaved?.user} on{" "}
																{moment(themeLibraryData?.lastSaved?.date).format(
																	DateFormat
																)}
															</p>
														)}

													{themeLibraryData?.lastPublish && (
														<p className="base-color-black-60 base-capitalize base-margin-top-small base-font-14">
															Version {themeLibraryData?.lastPublish?.version}
														</p>
													)}

													<p className="base-margin-vertical-small base-font-14">
														<b>
															Unpublished changes :{" "}
															{themeLibraryData?.unpublishedChanges ?? 0}
														</b>
													</p>
												</div>
											</div>

											<RightCTA
												viewUrl={previewUrl}
												published
												unpublishedChanges={themeLibraryData?.unpublishedChanges}
												showCustomize={!!themeLibraryData}
												onPreviewClick={() => customizeAvailableTheme(theme.name, true)}
												onClickCustomize={() => customizeAvailableTheme(theme.name)}
												disabled={!publishConfig.enabled}
												secondary
												onClickPublish={() => publishConfig.onClick(theme.name)}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);

	function customizeAvailableTheme(theme, skipEditor = false) {
		createMerakiWebWorkspace(theme).then(() => {
			if (!skipEditor) history.push(merakiEditorRoute + `?theme=${theme}`);
		});
	}
};

function RightCTA({
	viewUrl = "",
	published = true,
	unpublishedChanges = 0,
	onClickCustomize = () => {},
	disabled = false,
	onClickPublish = () => {},
	onPreviewClick = () => {},
	secondary = false,
	showCustomize = false
}) {
	return (
		<React.Fragment>
			{!!published && viewUrl && (
				<a
					className="base-link"
					href={viewUrl}
					target="_blank"
					title={"View your store"}
					onClick={onPreviewClick}
				>
					<Button type={"secondary"} classes={"base-row"} style={{ display: "flex" }}>
						<div className={"base-row"}>
							<ViewIconSVG />
						</div>

						<span>Preview</span>
					</Button>
				</a>
			)}

			{!!showCustomize && (
				<Button type={"secondary"} clickHandler={onClickCustomize}>
					Customise
				</Button>
			)}

			{!secondary && (
				<Button
					type={secondary ? "secondary" : "primary"}
					classes={disabled ? "base-button-disabled" : ""}
					clickHandler={() => !disabled && onClickPublish()}
					showCustomTooltip={disabled}
					tooltipInfo={"Publish in progress"}
				>
					Publish
				</Button>
			)}
		</React.Fragment>
	);
}

const mapStateToProps = (store) => ({
	merakiWebThemesList: store.merakiWebThemesList,
	bizId: store.login?.loggedInbizDetail?.id
});

export const Workspace = connect(mapStateToProps)(WorkspaceComponent);
