import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { Textarea } from "../components/_commons/Textarea";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { Switch } from "../components/_commons/Switch";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBizPlatforms } from "../actions/actions";
import { fetchModifierGroupsList } from "../actions/modifierGroups";

// graphql
import { CREATE_MODIFIER_GROUP } from "../graphql/modifierGroups";
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../client-config";

// constants
const MODIFIER_GROUP_TYPES = [
	{ label: "Add-On", value: "Add On" },
	{ label: "Variant", value: "Variant" }
];

const ModifierGroupCreate = ({
	hasAccess = false,
	bizPlatforms = [],
	isFromMenuSection = false,
	isOpen,
	isNested = false,
	close = () => {}
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [data, setData] = useState({
		min: 0,
		max: 12,
		title: "",
		merchantRefId: "",
		crmTitle: "",
		sortOrder: 0,
		isDefault: false,
		treatAsAddon: false,
		multiOptionsEnabled: false,
		description: ""
	});
	const [isFormUntouched, setBtnState] = useState(true);
	const [modifierGroupType, setModifierGroupType] = useState(MODIFIER_GROUP_TYPES?.[0]);

	useEffect(() => {
		setTimeout(() => {
			if (!isFromMenuSection) {
				setFormState(true);
			}
		}, 60);
		fetchBizPlatforms(true);
	}, []);

	const handleCancel = () => {
		setFormState(false);
		close();
		setTimeout(() => {
			if (!isFromMenuSection) {
				history.push("/modifier-groups");
			}
		}, 100);
		if (!isFromMenuSection) {
			fetchModifierGroupsList();
		}
	};

	const handleModifierGroupType = (field, type) => {
		isFormUntouched && setBtnState(false);
		if (modifierGroupType?.value === "Add On") {
			setModifierGroupType(type);
			setData({
				...data,
				min: 1,
				max: 1
			});
		} else {
			setModifierGroupType(type);
			setData({
				...data,
				min: 0,
				max: 12,
				treatAsAddon: false
			});
		}
	};

	const handleForm = (field, value) => {
		isFormUntouched && setBtnState(false);
		setData({
			...data,
			[field]: value
		});
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			if (variables.merchantRefId === "") {
				variables.merchantRefId = "-1";
			}
			const resp = await client.mutate({
				mutation: CREATE_MODIFIER_GROUP,
				variables
			});
			if (resp.data.saveModifierGroup.status.success) {
				trackEvent(TRACKING_EVENT_NAMES.NEW_MODIFIER_GROUP_CREATION, {
					status: TRACKING_STATUS.SUCCESS
				});

				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Modifier Group created",
						timeout: 5000,
						error: false
					}
				});
				if (isFromMenuSection) {
					store.dispatch({
						type: ActionTypes.UPDATE_MODIFIER_GROUPS_LIST,
						payload: resp.data.saveModifierGroup.object
					});
					close(resp.data.saveModifierGroup.object.id);
				} else {
					history.push(`/modifier-groups/edit/${resp.data.saveModifierGroup.object.id}`);
				}
			} else {
				trackEvent(TRACKING_EVENT_NAMES.NEW_MODIFIER_GROUP_CREATION, {
					status: TRACKING_STATUS.FAILURE
				});

				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveModifierGroup.status.messages));
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.NEW_MODIFIER_GROUP_CREATION, {
				status: TRACKING_STATUS.FAILURE
			});

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	const validations = error.fields || {};

	useEffect(() => {
		setFormState(isOpen);
	}, [isOpen]);

	return (
		<FormSidebar
			isOpen={isFromMenuSection ? isOpen : isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Modifier Group"
			subTitle="Create a new modifier group"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={isFormUntouched}
			hideActions={isFormUntouched}
			isNested={isNested}
		>
			<div className="form-content">
				<div className="form-description">
					<div className="header-text">Select modifier group type</div>
					<div className="subtitle">
						<span className="highlight">Add-On:</span>
						More than one modifiers can be selected with the item (Eg: Pizza Toppings)
					</div>
					<div className="subtitle">
						<span className="highlight">Variant:</span>
						Only one modifier can be selected with the item (Eg: Size of pizza)
					</div>
				</div>
				<div className="form-row row-half">
					<InputWithLabel
						value={data.title}
						onChange={(e) => handleForm("title", e.target.value)}
						requiredLabel={true}
						validationMessage={validations.optionGroupTitle || ""}
					>
						Title
					</InputWithLabel>
					<SelectFilter
						title="Modifier Group Type"
						options={MODIFIER_GROUP_TYPES}
						field="modifierGroupType"
						currValue={modifierGroupType}
						setFilter={handleModifierGroupType}
						isSearchable={false}
						isClearable={false}
						labelKey="label"
						valueKey="value"
						requiredLabel={true}
					/>
				</div>
				{modifierGroupType?.value === "Add On" && (
					<div className="form-row row-half">
						<InputWithLabel
							value={data.min}
							type="number"
							onChange={(e) => handleForm("min", e.target.value ? Number(e.target.value) : null)}
							requiredLabel={true}
							validationMessage={validations.optionGroupMinSelectable || ""}
						>
							Min. Range of Modifiers
						</InputWithLabel>
						<InputWithLabel
							value={data.max}
							type="number"
							onChange={(e) => handleForm("max", e.target.value ? Number(e.target.value) : null)}
							requiredLabel={true}
							validationMessage={validations.optionGroupMaxSelectable || ""}
						>
							Max. Range of Modifiers
						</InputWithLabel>
					</div>
				)}
				<div className="form-row row-half">
					<InputWithLabel
						value={data.sortOrder}
						type="number"
						onChange={(e) => handleForm("sortOrder", e.target.value ? parseInt(e.target.value) : null)}
						requiredLabel={true}
						showCustomTooltip={true}
						tooltipInfo="Control ordering of modifier groups as they are displayed to your customers. Values closer to zero will push the modifier group to top while higher values will push it towards bottom"
						validationMessage={validations.sortOrder || ""}
					>
						Sort Order
					</InputWithLabel>
					<Switch
						title="Is Default"
						checked={data.isDefault}
						clickHandler={() => handleForm("isDefault", !data.isDefault)}
						showCustomTooltip={true}
						tooltipInfo="All modifiers in the group will be selected by default. User can modify their choice based on exclusion instead of inclusion (Reflects only on platforms that support this feature)"
						tooltipPosition="up"
						validationMessage={validations.isDefault || ""}
					/>
				</div>
				<div className="form-row row-half">
					<Switch
						title="Multi-Modifiers Enabled"
						checked={data.multiOptionsEnabled}
						clickHandler={() => handleForm("multiOptionsEnabled", !data.multiOptionsEnabled)}
						validationMessage={validations.multiOptionsEnabled || ""}
					/>
					{modifierGroupType?.value === "Variant" &&
						bizPlatforms?.length > 0 &&
						bizPlatforms.find((plf) => plf.platformName.toLowerCase() === "swiggy") && (
							<Switch
								title="Mark as Add-On"
								checked={data.treatAsAddon}
								clickHandler={() => handleForm("treatAsAddon", !data.treatAsAddon)}
								validationMessage={validations.treatAsAddon || ""}
							/>
						)}
				</div>
				<div className="form-row row-full">
					<Textarea value={data.description} onChange={(e) => handleForm("description", e.target.value)}>
						Description
					</Textarea>
				</div>
				<div className="form-row row-half">
					<InputWithLabel
						value={data.crmTitle}
						onChange={(e) => handleForm("crmTitle", e.target.value)}
						showCustomTooltip={true}
						tooltipInfo="Short text to easily identify and lookup the modifier group"
					>
						CRM Title
					</InputWithLabel>
					<InputWithLabel
						value={data.merchantRefId === "-1" ? "" : data.merchantRefId}
						onChange={(e) => handleForm("merchantRefId", e.target.value)}
						showCustomTooltip={true}
						tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
						tooltipPosition="up"
						validationMessage={validations.merchantRefId || ""}
					>
						POS ID
					</InputWithLabel>
				</div>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	bizPlatforms: store.configItems.bizPlatforms.items
});
export default connect(mapStateToProps)(ModifierGroupCreate);
