import gql from "graphql-tag";

export const GET_DSP_PLATFORMS_LIST = gql`
	query getDspPlatformsList($limit: Int, $offset: Int) {
		dspPlatformList(limit: $limit, offset: $offset) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				platform
				bizId
				isExpired
				activeStoresCount
				mappedStoresCount
				failedMenuAssociations
				inProgressMenuAssociations
				unmappedStoresCount
				syncStatus
				lastSynchedAt
			}
		}
	}
`;

export const SET_DSP_PLATFORM_AUTH = gql`
	mutation setDspPlatformAuth($platform: String!, $authCode: String!) {
		dspPlatformAuth(input: { platform: $platform, authCode: $authCode }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				platform
				bizId
				isExpired
				activeStoresCount
				mappedStoresCount
				unmappedStoresCount
				syncStatus
				lastSynchedAt
			}
		}
	}
`;

export const GET_DSP_PLATFORM_LOCATIONS_LIST = gql`
	query getDspPlatformLocationsList(
		$platform: String!
		$limit: Int
		$offset: Int
		$search: [SearchArgument]
		$filters: [ListFilterArgument]
	) {
		dspPlatformLocationList(
			platform: $platform
			limit: $limit
			offset: $offset
			search: $search
			filters: $filters
		) {
			count
			activeStoresCount
			mappedStoresCount
			unmappedStoresCount
			assignedLocationIds
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				externalId
				externalUrl
				address
				locationName
				address
				associatedMenu {
					menuId
					name
				}
				storeStatus
				platformName
				bizPlatform {
					id
					platformName
				}
				location {
					id
					name
					brand {
						id
						name
					}
					associatedBrandCount
					associatedBrandsData {
						id
						name
					}
				}
				dspStatus
				dspSuccess
				dspErrorMessage
			}
		}
	}
`;

export const GET_LOCATIONS = gql`
	query getStores(
		$limit: Int
		$offset: Int
		$search: [SearchArgument]
		$filters: [ListFilterArgument]
		$sort: SortInput
		$brand: String
		$platformId: Int
		$includeMenuDetails: Boolean
	) {
		stores(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			brand: $brand
			search: $search
			platform: $platformId
			includeMenuDetails: $includeMenuDetails
		) {
			count

			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				bizLocationId
				name
				brandLocation {
					id
					name
					isEnabledForOrdering
					associatedPlatforms {
						externalId
						outletUrl
						platformName
						state
						verificationStatus
					}
				}
				locationPlatforms {
					id
					platformName
					state
				}
				associatedPlatforms {
					externalId
					outletUrl
					platformName
					state
					verificationStatus
				}
				brandMenu {
					menu {
						menuId
						name
						progress {
							platform
							menuId
							menuAssociationStatus
							prismMenuVerificationStatus
							prismMenuVerificationReport
							menuPublishStatus
							menuPublishError
							batchId
							traceId
							imageUrl
							menuName
						}
					}
				}
				platform {
					externalId
					outletUrl
					platformName
					state
					verificationStatus
					menu {
						menuId
						name
						progress {
							platform
							menuId
							menuAssociationStatus
							prismMenuVerificationStatus
							prismMenuVerificationReport
							menuPublishStatus
							menuPublishError
							batchId
							traceId
							imageUrl
							menuName
						}
					}
				}
			}
			rtglProgress {
				total
				live
				failed
				notLive
				disabled
			}
		}
	}
`;

export const DSP_REVIEW_MENU_FIXES = gql`
	mutation dspReviewMenuFixes($menuId: String!, $platformId: Int!, $locationid: Int) {
		dspReviewMenuFixes(menuId: $menuId, platformId: $platformId, locationId: $locationid) {
			status {
				success
				messages {
					field
					message
				}
			}
			locationIds
		}
	}
`;

export const DSP_UPDATE_PLATFORM_LOCATIONS = gql`
	mutation dspUpdatePlatformLocations($platform: String!, $locations: [PlatformLocationInput]!) {
		dspUpdatePlatformLocations(input: { platform: $platform, locations: $locations }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DSP_ACTIVATE_STORES_PUBLISH_MENU = gql`
	mutation dspActivateStoresAndPublishMenu($platform: String!, $locationMenus: [LocationMenuInput]!) {
		dspActivateStoresAndPublishMenu(input: { platform: $platform, locationMenus: $locationMenus }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DSP_AGGREGATED_STATUS = gql`
	query dspAggregatedStatus($platform: String!) {
		dspAggregratedStatus(platform: $platform) {
			objects {
				status
				completed
				total
				iconState
			}
		}
	}
`;

export const DSP_PLATFORM_SWITCH_ACCOUNT = gql`
	mutation dspPlatformSwichAccount($platform: String!) {
		dspPlatformLogout(input: { platform: $platform }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_FAILED_LOCATIONS = gql`
	query dspFailedMenuPublishes($platform: String!, $brandId: Int, $limit: Int, $offset: Int) {
		dspFailedMenuPublishes(platform: $platform, brandId: $brandId, limit: $limit, offset: $offset) {
			objects {
				platform
				locationId
				menuId
				menuName
				locationName
				menuVerificationStatus
				menuPublishStatus
				menuAssociationStatus
				menuVerificationReport
				menuVerificationMessage
				batchId
				traceId
				imageUrl
			}
			count
			limit
			offset
		}
	}
`;
