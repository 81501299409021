import React, { useRef, useState } from "react";

// components
import { Topbar } from "./Topbar";

// third party
import { connect } from "react-redux";
import { useDrag, useDrop } from "react-dnd";

// client
import { store } from "../../store/configureStore";

// actions
import { editCategory, fetchCategoriesList } from "../../actions/categories";
import { fetchParentCategoryIds } from "../../actions/actions";
import { ActionTypes } from "../../actions/_types";

// config
import { CATEGORY_TYPES } from "../../client-config";

const SidebarContainer = ({ dimensions, children, parentCategoryIds, ...props }) => {
	return (
		<div className="common-sidebar-container">
			<div className="sidebar-tabs-container">
				{dimensions.width > 768 ? (
					<Sidebar parentCategoryIds={parentCategoryIds} {...props} />
				) : (
					<Topbar {...props} />
				)}
			</div>
			<div className="sidebar-content-container">{children}</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	dimensions: store.configItems.dimensions,
	parentCategoryIds: store?.configItems?.parentCategoryIds?.ids
});
export default connect(mapStateToProps)(SidebarContainer);

const Sidebar = ({
	tabs,
	selectedTab,
	switchTab,
	renderTab = false,
	header,
	archived = "",
	valueField = "value",
	labelField = "label",
	entity = "",
	dragEnabled = false,
	setUpdatingId,
	hasAccess,
	parentCategoryIds
}) => {
	const [showCentralNode, setShowCentralNode] = useState(undefined);
	const [updatingCategory, setUpdatingCategory] = useState(false);

	if (dragEnabled) {
		return (
			<div className="at-side-bar">
				{header && <div className="header">{header}</div>}
				{updatingCategory && (
					<div className="sidebar-content-container">
						<div className="P(10px)" style={{ width: "120%" }}>
							<div className="shimmer H(40px) Mb(10px)" />
							<div className="shimmer H(40px) Mb(10px)" />
							<div className="shimmer H(40px) Mb(10px)" />
							<div className="shimmer H(40px) Mb(10px)" />
						</div>
					</div>
				)}
				{!updatingCategory &&
					tabs.map((tab, i) =>
						renderTab ? (
							<DraggableTab
								tab={tab}
								renderTab={renderTab}
								switchTab={switchTab}
								valueField={valueField}
								selectedTab={selectedTab}
								i={i}
								key={i}
								id={tab.id}
								archived={archived}
								updating={true}
								setUpdatingId={setUpdatingId}
								hasAccess
								parentCategoryIds={parentCategoryIds}
								showCentralNode={showCentralNode}
								setShowCentralNode={setShowCentralNode}
								setUpdatingCategory={setUpdatingCategory}
							/>
						) : (
							<div
								className={
									(tab[valueField] === selectedTab ? archived + " selected " : "") +
									"selectable sidebar-row"
								}
								key={i}
								onClick={() => switchTab(tab)}
							>
								<div
									className={
										(tab[valueField] === selectedTab ? archived + " selected " : "") +
										"selectable sidebar-row"
									}
									key={i}
									onClick={() => switchTab(tab)}
								>
									<div className="text">{tab[labelField]}</div>
								</div>
							</div>
						)
					)}
				{tabs.length === 0 && entity && <div className="no-items-placeholder">No {entity} found!</div>}
			</div>
		);
	}

	return (
		<div className="at-side-bar">
			{header && <div className="header">{header}</div>}
			{tabs.map((tab, i) => (
				<div
					className={
						(tab[valueField] === selectedTab ? archived + " selected " : "") + "selectable sidebar-row"
					}
					key={i}
					onClick={() => switchTab(tab)}
				>
					{renderTab ? renderTab(tab) : <div className="text">{tab[labelField]}</div>}
				</div>
			))}
			{tabs.length === 0 && entity && <div className="no-items-placeholder">No {entity} found!</div>}
		</div>
	);
};

const DraggableTab = ({
	tab,
	renderTab,
	switchTab,
	valueField,
	selectedTab,
	id,
	archived,
	setUpdatingId,
	hasAccess,
	parentCategoryIds,
	showCentralNode,
	setShowCentralNode,
	setUpdatingCategory
}) => {
	const updateParentCategory = async (
		dragId,
		dropId,
		setUpdatingId,
		dropName,
		dragName,
		requiredSortOrder,
		merchantRefId,
		dragSubCategoriesCount = -1,
		droppedBetween = false,
		dropItemSortOrder = 0
	) => {
		if (dragId === dropId) {
			return;
		}
		if (!parentCategoryIds[dropId] && !droppedBetween) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: `"${dragName}" cannot be added to a category containing items. You can only add it to an empty category -or- to a category containing sub-categories.`,
					timeout: 10000,
					error: true
				}
			});
			return;
		}
		if (dragSubCategoriesCount > 0 && !droppedBetween) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: `Only one level of category nesting is allowed. "${dragName}" has sub-categories in it. Adding it in "${dropName}" will create multi-level nesting.`,
					timeout: 10000,
					error: true
				}
			});
			return;
		}
		if (droppedBetween) {
			setUpdatingCategory(true);
		}
		if (dragId !== dropId) {
			try {
				setUpdatingId(dropId);
				const variables = {
					id: dragId,
					name: dragName,
					sortOrder: droppedBetween ? Number(dropItemSortOrder) + 1 : requiredSortOrder,
					parent: droppedBetween ? null : parseInt(dropId),
					isActive: true,
					merchantRefId
				};
				await editCategory(variables, store.dispatch);
				await fetchCategoriesList(droppedBetween ? dragId : dropId);
				await fetchParentCategoryIds();
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 3000,
						error: true,
						errObject: error
					}
				});
			}
		}
		setUpdatingCategory(false);
		setUpdatingId(undefined);
	};

	const dragRef = useRef(null);
	const [{ isOver, handlerId }, drop] = useDrop(
		() => ({
			accept: CATEGORY_TYPES.CATEGORYLIST,
			drop: (item) =>
				updateParentCategory(
					item.id,
					tab.id,
					setUpdatingId,
					tab.name,
					item.name,
					item.sortOrder,
					item.merchantRefId,
					item.subcategoriesCount || -1,
					showCentralNode !== undefined,
					tab.sortOrder
				),
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				handlerId: monitor.getHandlerId()
			}),
			hover(item, monitor) {
				// Preventing for the dragged element
				if (item.id === tab.id) {
					return;
				}
				if (!dragRef.current) {
					return;
				}
				// Determine rectangle on screen
				const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
				// Get vertical middle
				const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
				// Determine mouse position
				const clientOffset = monitor.getClientOffset();
				// Get pixels to the top
				const hoverClientY = clientOffset.y - hoverBoundingRect.top;
				if (hoverClientY > hoverMiddleY && clientOffset.y < hoverBoundingRect.bottom) {
					setShowCentralNode(id);
				} else if (clientOffset.y > hoverBoundingRect.bottom) {
					setShowCentralNode(undefined);
				} else if (hoverClientY < hoverMiddleY) {
					setShowCentralNode(undefined);
				}
			}
		}),
		[updateParentCategory, tab]
	);

	const [{ isDragging, draggingItem }, drag] = useDrag(
		() => ({
			type: CATEGORY_TYPES.CATEGORYLIST,
			item: {
				id,
				name: tab.name,
				sortOrder: tab.sortOrder,
				merchantRefId: tab.merchantRefId,
				subcategoriesCount: tab?.subcategories?.length || -1
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
				draggingItem: monitor.getItem()
			})
		}),
		[tab]
	);

	drag(drop(dragRef));

	const onDragStyle = {
		cursor: "move",
		opacity: "0.5"
	};
	const dropOverStyle = {
		border:
			parentCategoryIds[id] && draggingItem?.subcategoriesCount <= 0 ? "2px solid #2f58f2" : "2px solid #ff425c",
		borderRadius: "2px",
		padding: "8px 23px"
	};

	if (draggingItem === null) {
		setShowCentralNode(undefined);
	}
	return (
		<React.Fragment>
			<div
				className={(tab[valueField] === selectedTab ? archived + " selected " : "") + "selectable sidebar-row"}
				ref={dragRef}
				style={isDragging ? { ...onDragStyle } : isOver && !showCentralNode ? { ...dropOverStyle } : {}}
				onClick={() => switchTab(tab)}
				data-handler-id={handlerId}
			>
				{renderTab(tab, hasAccess)}
			</div>
			<div className="root-category-dip" ref={drop}>
				{showCentralNode && showCentralNode === id && (
					<React.Fragment>
						<span className="left-circle"></span>
						<div className="split-line"></div>
						<span className="right-circle"></span>
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};
