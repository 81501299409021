import React, { useState, useImperativeHandle } from "react";

// third party
import { Transition, animated, config } from "react-spring/renderprops";

// components
import ChevronIcon from "../_icons/ChevronIcon";
import NotificationServices from "../../services/NotificationService";
import { OutsideClick } from "./OutsideClick";

const MultiActionsButton = ({
	title = "action button",
	actions,
	instantAction,
	renderOnTop = false,
	isDisabled = false,
	nodeRef
}) => {
	const [areActionsVisible, setActionsVisibility] = useState(false);

	const handleChevron = (e) => {
		e.stopPropagation();
		setActionsVisibility((current) => (actions.length ? !current : false));
	};

	const handleOutsideClick = () => {
		setActionsVisibility(false);
	};

	useImperativeHandle(
		nodeRef,
		() => ({
			handleOutsideClick
		}),
		[handleOutsideClick]
	);

	return (
		<div
			className={`at-multi-actions-button-container ${
				areActionsVisible ? "at-multi-actions-button-container--open" : ""
			} ${renderOnTop ? "top" : ""} ${isDisabled ? "disabled" : ""}`}
			ref={nodeRef}
		>
			<div className="button-container" onClick={areActionsVisible ? () => {} : instantAction ?? handleChevron}>
				<span className="button-title">{title}</span>
				<span className="chevron-container" onClick={handleChevron}>
					<ChevronIcon styles={{ rotate: areActionsVisible ? "-180deg" : "0deg" }} />
				</span>
			</div>
			<Actions actions={actions} areActionsVisible={areActionsVisible} renderOnTop={renderOnTop} />
		</div>
	);
};

export default OutsideClick(MultiActionsButton);

const Actions = ({ actions, areActionsVisible, renderOnTop }) => (
	<Transition
		native
		from={{
			opacity: 0,
			transform: "perspective(800px) rotateX(-90deg)",
			transformOrigin: renderOnTop ? "bottom" : "top"
		}}
		enter={{
			opacity: 1,
			transform: "perspective(800px) rotateX(0deg)",
			transformOrigin: renderOnTop ? "bottom" : "top"
		}}
		leave={{
			opacity: 0,
			transform: "perspective(800px) rotateX(-90deg)",
			transformOrigin: renderOnTop ? "bottom" : "top"
		}}
		items={areActionsVisible}
		config={config.stiff}
	>
		{(isOpen) =>
			isOpen &&
			((props) => (
				<animated.div style={props} className="actions-container">
					{(actions ?? [{ title: "no actions!" }]).map((action, i) => (
						<div key={i} className="action" onClick={action?.clickHandler ?? void 0}>
							{action.title}
						</div>
					))}
				</animated.div>
			))
		}
	</Transition>
);
