import React from "react";

export const Panels = ({
	classes,
	list = [],
	selected,
	clickHandler,
	requiredLabel,
	title,
	validationMessage,
	tooltip = null,
	showCustomTooltip = false,
	tooltipInfo = "",
	tooltipPosition = "up-left"
}) => (
	<div className={"at-panels-cnt " + (classes || "")} title={tooltip}>
		{title && (
			<div className={"meta-info"}>
				<span className={requiredLabel ? " required-sign" : ""}>{title}</span>
				{showCustomTooltip && tooltipInfo && (
					<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
						<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
					</span>
				)}
			</div>
		)}
		<div className="panels-list-container">
			{list.map((panel, i) => (
				<div
					key={i}
					className="panel-item"
					data-selected={panel.value === selected.value ? true : false}
					onClick={() => clickHandler(panel)}
				>
					{panel.label}
				</div>
			))}
		</div>
		{validationMessage && <div className="validation-message">{validationMessage}</div>}
	</div>
);
