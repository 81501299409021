import React, { Component } from "react";
import { connect } from "react-redux";
// components
import DateFilter from "./DateFilter";
import FixedRangeFilter from "./FixedRangeFilter";
import CheckboxGroupFilter from "./CheckboxGroupFilter";

const RATING_ARRAY = [
	{ label: "1", value: 1 },
	{ label: "2", value: 2 },
	{ label: "3", value: 3 },
	{ label: "4", value: 4 },
	{ label: "5", value: 5 },
	{ label: "6", value: 6 },
	{ label: "7", value: 7 },
	{ label: "8", value: 8 },
	{ label: "9", value: 9 },
	{ label: "10", value: 10 }
];

@connect((store) => {
	return {
		login: store.login
	};
})
export default class CampaignFeedback extends Component {
	render() {
		const { filter } = this.props;
		const feedback = filter.feedback || {};
		let feedbackChoiceTexts = this.props.login.loggedInbizDetail.feedbackChoiceTexts;
		let feedbackChoiceTextsFormatted = feedbackChoiceTexts.map((i) => {
			return {
				label: i,
				value: i
			};
		});
		return (
			<div className="filter-section-container">
				<DateFilter
					title="Given on"
					root="feedback"
					field="given_on"
					value={feedback.given_on}
					setFilter={this.props.setFilter}
				/>
				<FixedRangeFilter
					title="Rating"
					root="feedback"
					field="rating"
					value={feedback.rating}
					rangeArray={RATING_ARRAY}
					setFilter={this.props.setFilter}
				/>
				{feedbackChoiceTextsFormatted.length > 0 && (
					<CheckboxGroupFilter
						title="Feedback choices"
						root="feedback"
						options={feedbackChoiceTextsFormatted}
						field="choice_text"
						value={feedback.choice_text}
						setFilter={this.props.setFilter}
					/>
				)}
			</div>
		);
	}
}
