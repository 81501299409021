import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { Textarea } from "../_commons/Textarea";

// third party
import { connect } from "react-redux";
import moment from "moment";

const BasicInfo = ({ data = {}, couponTypes = [], handleForm, validations, biz }) => {
	return (
		<React.Fragment>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.rewardTitle || ""}
					onChange={(e) => handleForm("rewardTitle", e.target.value)}
					requiredLabel={true}
					validationMessage={validations.rewardTitle || ""}
				>
					Title
				</InputWithLabel>
				<SelectFilter
					title="Type"
					options={couponTypes}
					field="rewardType"
					currValue={data.rewardType}
					setFilter={handleForm}
					labelKey="valueForDisplay"
					valueKey="value"
					requiredLabel={true}
					isClearable={false}
					validationMessage={validations.rewardType || ""}
				/>
			</div>
			<div className="form-row row-half">
				{data.rewardType &&
				(data.rewardType.value === "CASH_DISCOUNT" || data.rewardType.value === "PERCENTAGE_DISCOUNT") ? (
					<InputWithLabel
						value={data.rewardValue || ""}
						type="number"
						onChange={(e) =>
							handleForm(
								"rewardValue",
								e.target.value ? (Number(e.target.value) < 0 ? 0 : Number(e.target.value)) : null
							)
						}
						validationMessage={validations.rewardValue || ""}
						showLabel={true}
						classes="at-input--label"
						currency={data.rewardType && data.rewardType.value === "CASH_DISCOUNT" && true}
						symbol={data.rewardType && data.rewardType.value === "PERCENTAGE_DISCOUNT" ? "%" : ""}
						currencySymbol={biz.currencySymbol}
					>
						Coupon value
					</InputWithLabel>
				) : (
					<InputWithLabel
						value={data.redemptionCode || ""}
						onChange={(e) => handleForm("redemptionCode", e.target.value)}
						validationMessage={validations.redemptionCode || ""}
					>
						Redemption code
					</InputWithLabel>
				)}
				{data.rewardType &&
				(data.rewardType.value === "CASH_DISCOUNT" || data.rewardType.value === "PERCENTAGE_DISCOUNT") ? (
					<InputWithLabel
						value={data.redemptionCode || ""}
						onChange={(e) => handleForm("redemptionCode", e.target.value)}
						validationMessage={validations.redemptionCode || ""}
					>
						Redemption code
					</InputWithLabel>
				) : (
					<InputWithLabel
						value={data.merchantRefId === "-1" ? "" : data.merchantRefId}
						onChange={(e) => handleForm("merchantRefId", e.target.value)}
						validationMessage={validations.merchantRefId || ""}
					>
						POS ID
					</InputWithLabel>
				)}
			</div>
			<div className="form-row row-half">
				<Switch
					title="Single use only"
					checked={!data.allowMultiUse}
					clickHandler={() => handleForm("allowMultiUse", !data.allowMultiUse)}
					validationMessage={validations.allowMultiUse || ""}
				/>
				<Switch
					title="Suggested"
					checked={data.isSuggested}
					clickHandler={() => handleForm("isSuggested", !data.isSuggested)}
					validationMessage={validations.isSuggested || ""}
				/>
			</div>
			{data.rewardType &&
				(data.rewardType.value === "CASH_DISCOUNT" || data.rewardType.value === "PERCENTAGE_DISCOUNT") && (
					<div className="form-row row-half">
						<InputWithLabel
							value={data.merchantRefId === "-1" ? "" : data.merchantRefId}
							onChange={(e) => handleForm("merchantRefId", e.target.value)}
							validationMessage={validations.merchantRefId || ""}
						>
							POS ID
						</InputWithLabel>
					</div>
				)}
			<div className="form-row row-half">
				<InputWithLabel
					value={data.startsOn || ""}
					onChange={(e) => handleForm("startsOn", e.target.value)}
					onFocus={(e) =>
						handleForm("startsOn", e.target.value ? e.target.value : moment().format("YYYY-MM-DD HH:mm:ss"))
					}
					validationMessage={validations.startsOn || ""}
					placeholder="YYYY-MM-DD HH:MM:SS"
				>
					Starts on
				</InputWithLabel>
				<InputWithLabel
					value={data.expiresOn || ""}
					onChange={(e) => handleForm("expiresOn", e.target.value)}
					onFocus={(e) =>
						handleForm(
							"expiresOn",
							e.target.value ? e.target.value : moment().format("YYYY-MM-DD HH:mm:ss")
						)
					}
					validationMessage={validations.expiresOn || ""}
					placeholder="YYYY-MM-DD HH:MM:SS"
				>
					Ends on
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={data.rewardDesc || ""}
					onChange={(e) => handleForm("rewardDesc", e.target.value)}
					validationMessage={validations.rewardDesc || ""}
					requiredLabel={true}
				>
					Description
				</Textarea>
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(BasicInfo);
