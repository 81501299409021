// action types
import { ActionTypes } from "../actions/_types";

// constants
import { CRITERIA_ANALYTICS, TOPIC_LIST_SA_OVERVIEW, TOPIC_LIST_SA_PERFORMANCE } from "../client-config";
import { PRESET_TYPES, DATE_TYPES, COMPARE_DATE_TYPES } from "../components/_commons/NewDateCompareFilter";

// clients
import { lS } from "../atlas-utils";
import { store } from "../store/configureStore";

// const productType = lS.get('productType') || 'ORDERING';
const productType = "ORDERING";
export const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[9].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[9],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
const SA_INITIAL_STATE = {
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedFilters: {},
	analyticsTopic: TOPIC_LIST_SA_OVERVIEW[0],
	performanceTopic: productType === "ORDERING" ? TOPIC_LIST_SA_PERFORMANCE[0] : TOPIC_LIST_SA_PERFORMANCE[5],
	criteria: CRITERIA_ANALYTICS[0],
	criteriaValue: "all",
	performanceChannels: [],
	limit: 10,
	offset: 0,
	search: ""
};
const PERFORMANCE_TOPIC_KEYS_MAP = {
	SALES: ["name", "sales", "percentOfTotal", "biz_item_id"],
	COUNT: ["name", "count", "percentOfTotal", "biz_item_id"],
	AVERAGE_SALES: ["name", "avg", "percentOfTotal", "biz_item_id"],
	ITEMS_COUNT: ["name", "count", "percentOfTotal", "biz_item_id"],
	ITEMS_AVERAGE_COUNT: ["name", "item_avg_count", "percentOfTotal", "biz_item_id"],
	LOST_SALES: ["name", "sales", "percentOfTotal", "biz_item_id"],
	LOST_ORDERS: ["name", "count", "percentOfTotal", "biz_item_id"]
};

export const salesAnalyticsState = (state = SA_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_SA_STATE:
			return {
				...state,
				...action.payload
			};
		case "SET_APP_MODULE":
			return {
				...state,
				performanceTopic:
					action.payload === "ORDERING" ? TOPIC_LIST_SA_PERFORMANCE[0] : TOPIC_LIST_SA_PERFORMANCE[5]
			};
		case ActionTypes.RESET_SA_STATE:
			return SA_INITIAL_STATE;
		default:
			return state;
	}
};

export const salesAnalyticsOverview = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_SA_TOPICS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_SA_TOPICS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_SA_TOPICS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const salesAnalyticsTrendsChannels = (
	state = {
		loading: false,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_SA_TRENDS_CHANNELS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_SA_TRENDS_CHANNELS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_SA_TRENDS_CHANNELS_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};
		default:
			return state;
	}
};

export const salesAnalyticsTrendsGraph = (
	state = {
		loading: true,
		data: {
			current: [],
			previous: []
		},
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_SA_TRENDS_GRAPH_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_SA_TRENDS_GRAPH_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case ActionTypes.GET_SA_TRENDS_GRAPH_FAILURE:
			return {
				...state,
				data: {
					current: [],
					previous: []
				},
				loading: false,
				error: true
			};
		default:
			return state;
	}
};

export const salesAnalyticsPerformance = (
	state = {
		loading: false,
		data: null,
		error: false,
		treemap: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_SA_PERFORMANCE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_SA_PERFORMANCE_SUCCESS:
			let treemapData = [];
			let others = [];
			let othersSales = 0;
			let othersPercent = 0;
			const { analyticsTopic } = store.getState().salesAnalyticsState;
			action.payload.rows.forEach((row, i) => {
				let obj = {};
				if (i < 9) {
					obj.name = row.find((obj) => obj.key === PERFORMANCE_TOPIC_KEYS_MAP[analyticsTopic][0])?.value;
					obj.sales = Number(
						row.find((obj) => obj.key === PERFORMANCE_TOPIC_KEYS_MAP[analyticsTopic][1])?.value
					);
					obj.percent = Number(
						row.find((obj) => obj.key === PERFORMANCE_TOPIC_KEYS_MAP[analyticsTopic][2])?.value
					);
					obj.itemId =
						Number(row.find((obj) => obj.key === PERFORMANCE_TOPIC_KEYS_MAP[analyticsTopic][3])?.value) ||
						undefined;
					treemapData.push(obj);
				} else {
					others.push(row.find((obj) => obj.key === PERFORMANCE_TOPIC_KEYS_MAP[analyticsTopic][0])?.value);
					othersSales += Number(
						row.find((obj) => obj.key === PERFORMANCE_TOPIC_KEYS_MAP[analyticsTopic][1])?.value
					);
					othersPercent += Number(
						row.find((obj) => obj.key === PERFORMANCE_TOPIC_KEYS_MAP[analyticsTopic][2])?.value
					);
				}
			});
			if (action.payload.rows.length >= 9) {
				treemapData.push({
					name: "Others",
					sales: othersSales,
					percent: othersPercent,
					itemId: undefined,
					others
				});
			}
			return {
				...state,
				data: action.payload,
				loading: false,
				treemap: treemapData
			};
		case ActionTypes.GET_SA_PERFORMANCE_FAILURE:
			return {
				...state,
				loading: false,
				data: null,
				error: action.error
			};
		default:
			return state;
	}
};
