import React, { useState, useEffect, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";

// utils
import history from "../history";

// constants
const FORM_TABS = [
	{
		label: "Zomato",
		value: "zomato"
	},
	{
		label: "Swiggy",
		value: "swiggy"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const PayoutUploadHelp = ({ match, isNested = false, isForeignSource = false, closeNestedContainer, connectedRef }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isModalBusy, setModalBusy] = useState(false);
	const [loading, setLoading] = useState(false);
	const nestedRef = useRef();

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	const handleCancel = async () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(async () => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					history.push("/payout-sheets");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const renderTitle = () => {
		return (
			<div className="payout-upload-custom-title">
				<div className="help-button-container">
					<img className="help-icon" src="/assets/header-icons/icon-help.svg" />
				</div>
				<div className="title">
					Not sure where to find payouts?
					<div className="sub-title">
						Don't worry! We've put together these platform specific help section for you.
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="payout-upload-help-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title={renderTitle()}
				submitTitle="Save"
				loading={loading}
				// disabled={!isFormTouched}
				hideActions={true}
				isNested={isNested}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<Platform setLoading={setLoading} videoUrl={"https://www.youtube.com/embed/khF4QOpxgDw"} />
					)}
					{formTab === FORM_TABS[1].value && (
						<Platform setLoading={setLoading} videoUrl={"https://www.youtube.com/embed/VQtACCtC1wg"} />
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
export default PayoutUploadHelp;

const Platform = ({ setLoading, videoUrl }) => {
	useEffect(() => {
		setLoading(true);
	}, []);
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<div className="video-container">
					<iframe
						width="100%"
						height="400"
						src={videoUrl}
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
						onLoad={() => setLoading(false)}
					></iframe>
				</div>
			</div>
		</React.Fragment>
	);
};
