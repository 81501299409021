import React, { Component } from "react";

// third party
import { connect } from "react-redux";

// utils
import { store } from "../../store/configureStore";

// components
import { GlobalConfirmModal } from "../SiteComp";

@connect((store) => ({
	creditsState: store.creditsState
}))
export class CreditsReloadSuccessful extends Component {
	close = () => {
		store.dispatch({
			type: "CREDITS_STATE_UPDATE",
			payload: {
				showCreditsReloadSuccess: false
			}
		});
	};

	render() {
		const { showCreditsReloadSuccess } = this.props.creditsState;
		return (
			<GlobalConfirmModal backdropHandler={this.close} show={showCreditsReloadSuccess} customButtons={true}>
				<div className="low-credits-alert">
					<div className="alert-icon">
						<img src="/assets/icons/icon-success.svg" alt="alert-icon" />
					</div>
					<div className="alert-area">
						<div className="alert-title">Reload Successful</div>
						<div className="alert-subtitle">
							You have successfully added 25,000 credits. Your current credits balance is 25,323.
						</div>
						<div className="alert-actions">
							<div className="buy-button" onClick={this.close}>
								Ok, thanks
							</div>
						</div>
					</div>
				</div>
			</GlobalConfirmModal>
		);
	}
}
