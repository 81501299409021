import React, { useCallback, useEffect, useRef, useState } from "react";

// third party
import { connect } from "react-redux";
import { useSpring, animated as a } from "react-spring";

// components
import {
	LocationCountSelection,
	PlatformSelection,
	CitySelection,
	DetailsEntry,
	CatalogueSetup,
	CataloguePulled,
	MultipleCitySelection,
	MerakiDetails,
	LocationLinks,
	SendValuesAndAwaitResponse
} from "../components/LocationCreateWizard";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import { Modal } from "../components/_commons/Modal";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { LocationCreateIcons } from "../components/_commons/LocationCreateIcons";
import { MerakiMultiLocationsDetailsEntry } from "../components/LocationCreateWizard/MerakiMultiLocationsDetailsEntry";
import { NumericalStepDisplay } from "../components/_commons/NumericalStepDisplay";

// graphql
import { GET_BIZ_PLATFORMS_MINI } from "../graphql/locations";

// actions
import { fetchTimingGroups, fetchTimingGroupsDebounced } from "../actions/actions";

// utils
import history from "../history";
import { adjustNestedContainer, lS, validators, adjustStringLength } from "../atlas-utils";
import { businessBasedDisabledFields } from "../client-config";

// clients
import { store } from "../store/configureStore";
import { client } from "../client";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const initData = {
	locationAddType: "",
	platforms: {},
	city: "",
	nickName: "",
	address: "",
	phone: "",
	email: "",
	catalogueSettingMode: "copyCatalogue",
	copyLocation: "",
	locationPolygon: [],
	locationRadius: "",
	locationLatLng: {},
	selectedPlatformAndUrl: {},
	storeTimings: null,
	platformLocationLinks: {},
	multiLocationDetails: [],
	referenceId: ""
};

const LocationCreateWizard = ({ bizId, timingGroups }) => {
	const [selectedTab, setSelectedTab] = useState(
		lS.get("up_atlas_location_create_wizard")
			? lS.get("up_atlas_location_create_wizard")[bizId]?.selectedTab
				? lS.get("up_atlas_location_create_wizard")[bizId].selectedTab
				: 1
			: 1
	);
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState(
		lS.get("up_atlas_location_create_wizard")
			? lS.get("up_atlas_location_create_wizard")[bizId] || initData
			: initData
	);
	const [stepCount, setStepCount] = useState(
		formData.platforms.meraki && Object.keys(formData.platforms).length > 1 ? 7 : 6
	);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [locationDetailsArray, setLocationDetailsArray] = useState([
		...formData.multiLocationDetails.filter((locDetails) => locDetails.locationName && locDetails.city)
	]);
	const [modalState, setModalState] = useState({ state: false, type: "", index: 0 });
	const [radiusMode, setRadiusMode] = useState(false);
	const [location, setLocation] = useState({});
	const [locationCaptured, setLocationCaptured] = useState(false);

	const nestedRef = useRef();
	const savePolygonsRef = useRef(null);

	const merakiEnabled = Boolean(formData.platforms.meraki);
	const locationAddType = formData.locationAddType;
	const platformsSelectedCount = Object.keys(formData.platforms).length;

	const contentProps = useSpring({
		from: { transform: "scale(0)", opacity: "0" },
		to: { transform: "scale(1)", opacity: "1" }
	});

	const handleSelectedTabUpdate = (targetValue) => {
		if (targetValue > (merakiEnabled ? 8 : 7)) {
			setSelectedTab(1);
			lS.set("up_atlas_location_create_wizard", {
				...lS.get("up_atlas_location_create_wizard"),
				[bizId]: { ...lS.get("up_atlas_location_create_wizard")[bizId], selectedTab: 1 }
			});
		} else {
			setSelectedTab(targetValue);
			lS.set("up_atlas_location_create_wizard", {
				...lS.get("up_atlas_location_create_wizard"),
				[bizId]: { ...lS.get("up_atlas_location_create_wizard")[bizId], selectedTab: targetValue }
			});
		}
	};

	const handleFormData = (field, value) => {
		lS.set("up_atlas_location_create_wizard", {
			...lS.get("up_atlas_location_create_wizard"),
			[bizId]: { ...formData, [field]: value }
		});
		setFormData((prev) => ({ ...prev, [field]: value }));
	};

	const revertBackToLocationsList = () => {
		handleSelectedTabUpdate(1);
		history.push("/locations");
		lS.set("up_atlas_location_create_wizard", {
			...lS.get("up_atlas_location_create_wizard"),
			[bizId]: { ...initData }
		});
		setFormData({ ...initData });
	};

	const openLocationDetails = (id) => {
		handleSelectedTabUpdate(1);
		history.push(`/locations/edit/${id}`);
		lS.set("up_atlas_location_create_wizard", {
			...lS.get("up_atlas_location_create_wizard"),
			[bizId]: { ...initData }
		});
		setFormData({ ...initData });
	};

	const initialiseData = () => {
		lS.set("up_atlas_location_create_wizard", {
			...lS.get("up_atlas_location_create_wizard"),
			[bizId]: { ...initData }
		});
		setFormData({ ...initData });
		getBizPlatforms();
		handleSelectedTabUpdate(1);
	};

	const handleNestedEntity = useCallback((toOpen) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type: "timing-group-create",
				id: null
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const setFilter = (opt, val) => {
		let updatedMultiLocationDetails = [...formData.multiLocationDetails];
		updatedMultiLocationDetails[modalState.index] = {
			...updatedMultiLocationDetails[modalState.index],
			storeTimings: val ? { ...val } : null
		};
		handleFormData("multiLocationDetails", updatedMultiLocationDetails);
	};

	const getBizPlatforms = async () => {
		try {
			const variables = {
				filters: [{ field: "is_enabled", value: true }],
				includeUrbanpiper: true
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS_MINI,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.bizPlatforms.objects) {
				let updatedPlatformLocationLinks = {};
				resp.data.bizPlatforms.objects.forEach((bizPlatform) => {
					updatedPlatformLocationLinks[bizPlatform.platformName.toLowerCase()] = {
						id: formData?.platformLocationLinks[bizPlatform.platformName.toLowerCase()]?.id || "",
						url: formData?.platformLocationLinks[bizPlatform.platformName.toLowerCase()]?.url || ""
					};
				});
				handleFormData("platformLocationLinks", updatedPlatformLocationLinks);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const closeModal = () => {
		setModalState({ ...modalState, state: false });
	};

	const modalSubmitAction = () => {
		if (modalState.type === "maps") {
			if (!locationCaptured) {
				setLocationCaptured(true);
			} else if (!radiusMode & locationCaptured) {
				savePolygonsRef.current.savePolygons();
				setModalState({ ...modalState, state: false });
				setLocationCaptured(false);
			} else {
				setLocationCaptured(false);
				setModalState({ ...modalState, state: false });
			}
		} else {
			setModalState({ ...modalState, state: false });
		}
	};

	useEffect(() => {
		if (lS.get("up_atlas_location_create_wizard") && lS.get("up_atlas_location_create_wizard")[bizId]) {
			store.dispatch({
				type: "UPDATE_WIZARD_DATA",
				payload: { data: lS.get("up_atlas_location_create_wizard")[bizId], id: bizId }
			});
		}
	}, []);

	useEffect(() => {
		fetchTimingGroups();
	}, []);

	useEffect(() => {
		setLocationDetailsArray(() => [
			...formData.multiLocationDetails.filter((locDetails) => locDetails.locationName && locDetails.city)
		]);
	}, [formData]);

	useEffect(() => {
		getBizPlatforms();
	}, []);

	return (
		<React.Fragment>
			<a.div className="section-container-common location-create-widget" style={contentProps}>
				<NumericalStepDisplay count={stepCount} selectedValue={selectedTab} />
				{selectedTab === 1 && (
					<LocationCountSelection
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						handleFormData={handleFormData}
					/>
				)}
				{selectedTab === 2 && (
					<PlatformSelection
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						platforms={formData.platforms}
						handleFormData={handleFormData}
						setStepCount={setStepCount}
						platformLocationLinks={formData.platformLocationLinks}
					/>
				)}
				{selectedTab === 3 && locationAddType === "single" && (
					<CitySelection
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						handleFormData={handleFormData}
						city={formData.city}
					/>
				)}
				{selectedTab === 3 && locationAddType === "multi" && (
					<MultipleCitySelection
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						handleFormData={handleFormData}
						multiLocationDetails={formData.multiLocationDetails}
						platformLocationLinks={formData.platformLocationLinks}
					/>
				)}
				{selectedTab === 4 && (
					<DetailsEntry
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						nickName={formData.nickName}
						address={formData.address}
						phone={formData.phone}
						email={formData.email}
						handleFormData={handleFormData}
						locationAddType={formData.locationAddType}
						merakiEnabled={merakiEnabled}
						handleNestedEntity={handleNestedEntity}
						setModalState={setModalState}
						storeTimings={formData.storeTimings}
						locationDetailsArray={locationDetailsArray}
						setLocationDetailsArray={setLocationDetailsArray}
						multiLocationDetails={formData.multiLocationDetails}
						emailValidator={validators.emailValidator}
						phoneNumberValidator={validators.phoneNumberValidator}
						adjustStringLength={adjustStringLength}
					/>
				)}
				{selectedTab === 5 && step === 1 && (
					<CatalogueSetup
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						setStep={setStep}
						handleFormData={handleFormData}
						catalogueSettingMode={formData.catalogueSettingMode}
						copyLocation={formData.copyLocation}
						selectedPlatformAndUrl={formData.selectedPlatformAndUrl}
						merakiEnabled={merakiEnabled}
					/>
				)}
				{selectedTab === 5 && step === 2 && (
					<CataloguePulled
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						setStep={setStep}
						catalogueSettingMode={formData.catalogueSettingMode}
						copyLocation={formData.copyLocation}
						merakiEnabled={merakiEnabled}
					/>
				)}
				{!(platformsSelectedCount === 1 && merakiEnabled) && selectedTab === 6 && (
					<LocationLinks
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						platformLocationLinks={formData.platformLocationLinks}
						handleFormData={handleFormData}
						platforms={formData.platforms}
						locationAddType={formData.locationAddType}
						merakiEnabled={merakiEnabled}
						multiLocationDetails={formData.multiLocationDetails}
						locationDetailsArray={locationDetailsArray}
						adjustStringLength={adjustStringLength}
						// validators={validators}
						businessBasedDisabledFields={businessBasedDisabledFields}
					/>
				)}
				{locationAddType === "single" &&
					merakiEnabled &&
					selectedTab === (platformsSelectedCount === 1 ? 6 : 7) && (
						<MerakiDetails
							handleSelectedTabUpdate={handleSelectedTabUpdate}
							locationLatLng={formData.locationLatLng}
							polygon={formData.locationPolygon}
							radius={formData.locationRadius}
							handleFormData={handleFormData}
							radiusMode={radiusMode}
							setRadiusMode={setRadiusMode}
							location={location}
							setLocation={setLocation}
							locationCaptured={locationCaptured}
							setLocationCaptured={setLocationCaptured}
							savePolygonsRef={savePolygonsRef}
							platformsSelectedCount={platformsSelectedCount}
						/>
					)}
				{locationAddType === "multi" &&
					merakiEnabled &&
					selectedTab === (platformsSelectedCount === 1 ? 6 : 7) && (
						<MerakiMultiLocationsDetailsEntry
							setModalState={setModalState}
							locationDetailsArray={locationDetailsArray}
							handleSelectedTabUpdate={handleSelectedTabUpdate}
							adjustStringLength={adjustStringLength}
							platformsSelectedCount={platformsSelectedCount}
						/>
					)}
				{selectedTab === (merakiEnabled && platformsSelectedCount > 1 ? 8 : 7) && (
					<SendValuesAndAwaitResponse
						locationAddType={formData?.locationAddType}
						city={formData.city}
						nickName={formData.nickName}
						address={formData.address}
						phone={formData.phone}
						email={formData.email}
						catalogueSettingMode={formData.catalogueSettingMode}
						copyLocation={formData.copyLocation}
						locationPolygon={formData.locationPolygon}
						locationRadius={formData.locationRadius}
						locationLatLng={formData.locationLatLng}
						selectedPlatformAndUrl={formData.selectedPlatformAndUrl}
						storeTimings={formData.storeTimings}
						platformLocationLinks={formData.platformLocationLinks}
						locationDetailsArray={locationDetailsArray}
						handleSelectedTabUpdate={handleSelectedTabUpdate}
						revertBackToLocationsList={revertBackToLocationsList}
						initialiseData={initialiseData}
						handleFormData={handleFormData}
						platforms={formData.platforms}
						businessBasedDisabledFields={businessBasedDisabledFields}
						openLocationDetails={openLocationDetails}
					/>
				)}
				<div onClick={revertBackToLocationsList}>
					<img src="/assets/icons/cancel.png" className="close-icon-main" />
				</div>
				{
					<div
						className={`refresh-icon-main ${
							(selectedTab <= 1 ||
								selectedTab === (merakiEnabled && platformsSelectedCount > 1 ? 8 : 7)) &&
							"refresh-icon-main-disabled"
						}`}
						onClick={initialiseData}
					>
						<LocationCreateIcons icon="refresh" />
					</div>
				}
			</a.div>
			<div className="entity-groups timing-groups">
				<NestedEntityContainer
					show={nestedEntity?.show}
					type={"timing-group-create"}
					closeNestedContainer={() => handleNestedEntity(false)}
					nestedRef={nestedRef}
					isNested={false}
					hasAccess={true}
					fromLocationCreateWizard={true}
					presetTitle={locationAddType === "single" ? formData.nickName : ""}
				/>
			</div>
			<Modal
				isOpen={modalState.state}
				close={closeModal}
				title={modalState.type === "maps" ? "Let's set your delivery area" : "Select your store timing"}
				showCancelAction={modalState.type === "maps" && locationCaptured}
				showSubmitAction={true}
				cancelTitle={"Previous"}
				submitTitle={modalState.type === "maps" ? (!locationCaptured ? "Next" : "Save") : "Save"}
				cancelAction={() => setLocationCaptured(false)}
				submitAction={modalSubmitAction}
				disabled={
					modalState.type === "maps"
						? !(
								formData?.multiLocationDetails[modalState.index]?.locationLatLng?.lng &&
								formData?.multiLocationDetails[modalState.index]?.locationLatLng?.lng
						  )
						: !formData?.multiLocationDetails[modalState.index]?.storeTimings
				}
			>
				{modalState.type === "maps" ? (
					<MerakiDetails
						locationLatLng={formData?.multiLocationDetails[modalState.index]?.locationLatLng}
						radius={formData?.multiLocationDetails[modalState.index]?.locationRadius}
						polygon={formData?.multiLocationDetails[modalState.index]?.locationPolygon}
						renderFromModal={true}
						radiusMode={radiusMode}
						setRadiusMode={setRadiusMode}
						locationCaptured={locationCaptured}
						setLocationCaptured={setLocationCaptured}
						handleFormData={handleFormData}
						multiLocationDetails={formData.multiLocationDetails}
						currentIndex={modalState.index}
						savePolygonsRef={savePolygonsRef}
					/>
				) : modalState.type === "timing-group" ? (
					<div className="timing-group-selector">
						<div className="label">Select store timing</div>
						<div>
							<SelectFilter
								options={timingGroups?.items}
								isLoading={timingGroups?.isLoading}
								field="orderingSlots"
								currValue={formData?.multiLocationDetails[modalState.index]?.storeTimings}
								setFilter={setFilter}
								labelKey="title"
								valueKey="id"
								isAsync={true}
								handleSearch={fetchTimingGroupsDebounced}
							/>
							<div className="add-timing-group">
								<span
									onClick={() => {
										handleNestedEntity(true);
										setModalState({ ...modalState, state: false });
									}}
								>
									+ Add new
								</span>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}
			</Modal>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	bizId: store?.login?.loggedInbizDetail?.id,
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(LocationCreateWizard);
