import React from "react";

const ToolTipIssues = ({ value }) => {
	let classname = "";
	let textDisplay;
	if (value?.item) {
		return null;
	}
	if (value.total > 0 && value.issue_count > 0) {
		classname = "missing-items-display";
		textDisplay = (
			<>
				<div className="missing-items-info">
					<div className="issues-text-info">
						<span className="missing-color-display" style={{ margin: "0 4px" }}>
							Missing details
						</span>
						<span className="issues-no-locations">at {value.issue_count} locations</span>
					</div>
					<span className="issues-click-cell">Click on cell to know more</span>
				</div>
			</>
		);
	} else if (value.total > 0 && value.issue_count == 0) {
		classname = "no-missing-details";
		textDisplay = <span>No issues found.</span>;
	} else {
		classname = "issues-nill-stores";
		textDisplay = <span>The storefront does not exist </span>;
	}
	return (
		<div className={`tooltiptext-item   ${classname}   `}>
			<div className="tooltip-description-item">{textDisplay}</div>
		</div>
	);
};

export default ToolTipIssues;
