import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Button } from "../components/_commons/Button";
import { Modal } from "../components/_commons/Modal";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/UnifiedInviteDetail/BasicInfo";

// utils
import { store } from "../store/configureStore";
import history from "../history";
import { adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchInviteDetail, userInviteActions, fetchPendingInvitesList } from "../actions/unifiedInvites";
import { ActionTypes } from "../actions/_types";

// reducers
import { inviteDetailsReducer, INVITE_DETAILS_INITIAL_STATE } from "../reducers/unifiedInvites";

// constants
import { MODAL_CONTENT } from "./UnifiedInvitesList";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const UnifiedInviteDetail = ({
	access,
	user,
	biz,
	match,
	readOnly = false,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [inviteDetails, dispatch] = useReducer(inviteDetailsReducer, INVITE_DETAILS_INITIAL_STATE);
	const { loading, data, error } = inviteDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isModalOpen, setModalOpen] = useState(false);
	const [inviteActionLoading, setInviteActionLoading] = useState(false);
	const [modalContent, setModalContent] = useState({
		type: "",
		title: "",
		content: [],
		btnTitle: ""
	});
	const [inviteActionSuccess, setInviteActionSuccess] = useState({
		status: false,
		message: ""
	});
	const nestedRef = useRef();

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchInviteDetail(match.params.id, dispatch);
	}, [match.params.id]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchPendingInvitesList();
					history.push("/unified-access");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleInviteActions = async (action = "resend") => {
		const { uuid } = data;
		const variables = {
			action,
			uuid,
			clientToken: user.token,
			bizId: biz.id
		};
		setInviteActionLoading(true);
		const resp = await userInviteActions(variables);
		if (resp) {
			setInviteActionSuccess({
				status: true,
				message:
					action === "resend"
						? "Invite has been re-sent successfully"
						: "Invite has been deleted successfully"
			});
			setInviteActionLoading(false);
			if (action === "cancel") {
				dispatch({
					type: ActionTypes.UPDATE_INVITE_DETAIL,
					payload: {
						status: "CANCELLED"
					}
				});
			}
		} else {
			setInviteActionLoading(false);
		}
	};

	const handleModal = (type = "resendInvite") => {
		if (!isModalOpen) {
			setModalOpen(true);
			setModalContent({
				type,
				title: type === "resendInvite" ? "Resend Invite?" : "Delete Invite?",
				content: type === "resendInvite" ? MODAL_CONTENT["single_resend"] : MODAL_CONTENT["single_delete"],
				btnTitle: type === "resendInvite" ? "Resend Invite" : "Delete Invite"
			});
		} else {
			setModalOpen(false);
			setInviteActionLoading(false);
			setInviteActionSuccess({
				status: false,
				message: ""
			});
			setModalContent({
				type: "",
				title: "",
				content: [],
				btnTitle: ""
			});
		}
	};

	const extractInitials = (name) => {
		if (!name) {
			return "";
		}
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(1);
		}
		return initials.toUpperCase();
	};

	return (
		<div className="unified-invite-detail-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title={data?.email || (data.phone ? `${data?.isdCode || ""} ${data?.phone || ""}` : "Invite")}
				subTitle="Manage user invite for this user"
				isNested={isNested}
				hideActions={true}
				headerRight={
					<React.Fragment>
						{!readOnly && access.isAdmin && data?.status === "CREATED" && (
							<Button classes={"at-btn--danger"} clickHandler={() => handleModal("deleteInvite")}>
								Delete Invite
							</Button>
						)}
						{!readOnly && access.isAdmin && data?.status === "CREATED" && (
							<Button classes="edit-roles" clickHandler={() => handleModal("resendInvite")}>
								Resend Invite
							</Button>
						)}
					</React.Fragment>
				}
			>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data || {}}
							extractInitials={extractInitials}
							loading={loading}
							readOnly={readOnly}
						/>
					)}
					<Modal
						isOpen={isModalOpen}
						close={handleModal}
						title={modalContent.title}
						subTitle=""
						loading={inviteActionLoading}
						showSubmitAction={modalContent.type === "resendInvite" && !inviteActionSuccess.status}
						submitTitle={modalContent.btnTitle}
						submitAction={() => handleInviteActions("resend")}
						submitButtonWidth="150"
						showDeleteAction={modalContent.type === "deleteInvite" && !inviteActionSuccess.status}
						deleteTitle={modalContent.btnTitle}
						deleteAction={() => handleInviteActions("cancel")}
						deleteButtonWidth="150"
						showCancelAction={true}
						cancelTitle={inviteActionSuccess.status ? "Dismiss" : "Cancel"}
					>
						{inviteActionSuccess.status ? (
							<div className="success">{inviteActionSuccess.message}</div>
						) : (
							modalContent.content.map((content, i) => (
								<div className="description" key={i}>
									{content}
								</div>
							))
						)}
					</Modal>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	user: store.login.loginDetail,
	biz: store.login.loggedInbizDetail
}))(UnifiedInviteDetail);
