import React from "react";

// actions
import { createGallery } from "../../actions/gallery";

// third party
import { Editor } from "@tinymce/tinymce-react";

const GenericPageEditor = ({ tinymceRef, value, onEditorChange }) => {
	const handleImageUpload = async (blobInfo, success, failure) => {
		try {
			const imageUrl = await createGallery(blobInfo.blob(), false);
			if (!imageUrl) {
				failure("There was an error while uploading the image", { remove: true });
				return;
			}
			success(imageUrl);
		} catch (error) {
			console.log(error);
			failure("There was an error while uploading the image", { remove: true });
		}
	};

	return (
		<div className="meraki-web-generic-text-section section-container-common">
			<Editor
				tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
				onInit={(evt, editor) => (tinymceRef.current = editor)}
				value={value}
				init={{
					height: "84vh",
					menubar: false,
					plugins: [
						"advlist autolink lists link image charmap print preview anchor",
						"searchreplace visualblocks fullscreen",
						"insertdatetime media table paste image media"
					],
					toolbar:
						"undo redo | formatselect | fontsizeselect | " +
						"bold italic backcolor | alignleft aligncenter " +
						"alignright alignjustify | bullist numlist outdent indent | " +
						"removeformat | media image | remove",
					content_style: "body { font-size: 14px }",
					fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
					file_picker_types: "image",
					images_upload_handler: handleImageUpload
				}}
				onEditorChange={onEditorChange}
			/>
		</div>
	);
};
export default GenericPageEditor;
