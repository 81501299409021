import React, { useEffect } from "react";

// components
import { ReferralsGraph } from "./ReferralsGraph";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";

// client
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import { fetchReferralsStats } from "../../actions/referrals";

// actions
import { ActionTypes } from "../../actions/_types";

const Stats = ({ referralStats, referralStatsState }) => {
	const { loading, data, error } = referralStats;
	const { currentDateFilter, appliedDateFilter } = referralStatsState;

	useEffect(() => {
		fetchReferralsStats();
	}, [appliedDateFilter]);

	const updateReferralStatsState = (payload) => {
		store.dispatch({
			type: ActionTypes.REFERRALS_STATS_STATE_CHANGE,
			payload
		});
	};

	return (
		<div className="referrals-nps-container">
			<NewDateCompareFilter
				showDropdown={true}
				loading={loading}
				currentDateFilter={currentDateFilter}
				appliedDateFilter={appliedDateFilter}
				updateState={updateReferralStatsState}
				hideComparison={true}
				hidePresetTypes={["15 D"]}
			/>
			<ReferralsGraph
				loading={loading}
				data={data?.referralStats || {}}
				error={error}
				appliedFilter={appliedDateFilter.current || {}}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	referralStats: store.referralStats,
	referralStatsState: store.referralStatsState
});
export default connect(mapStateToProps)(Stats);
