import React, { useRef, useEffect, useState } from "react";

// constants
import { DEFAULT_LOCATION } from "../../client-config";
const GMAP_KEY = process.env.REACT_APP_GMAP_KEY;

export const CaptureLocationCircle = ({ setRadius, latLong = {}, radius, height = "250px" }) => {
	const [isVisible, setVisible] = useState(false);
	const gmap = useRef();
	const marker = useRef();
	const circle = useRef();
	const gmapRef = useRef();

	useEffect(() => {
		setTimeout(() => {
			setVisible(true);
		}, 2000);
	}, []);

	const createGoogleMap = () => {
		gmap.current = new window.google.maps.Map(gmapRef.current, {
			zoom: 16,
			center: latLong.lat && latLong.lng ? latLong : DEFAULT_LOCATION,
			disableDefaultUI: true,
			fullscreenControl: true
		});
	};

	const createMarkerAndCircle = () => {
		marker.current = new window.google.maps.Marker({
			position: latLong.lat && latLong.lng ? latLong : DEFAULT_LOCATION,
			map: gmap.current
		});
		circle.current = new window.google.maps.Circle({
			map: gmap.current,
			center: latLong.lat && latLong.lng ? latLong : DEFAULT_LOCATION,
			editable: true,
			radius: radius || 50,
			draggable: false
		});
	};

	useEffect(() => {
		if (circle.current && typeof radius === "number") {
			if (radius !== circle.current.getRadius().toFixed(2)) {
				circle.current.setRadius(radius);
			}
		}
	}, [radius]);

	const initializeMap = () => {
		createGoogleMap();
		createMarkerAndCircle();
		circle.current.addListener("radius_changed", () => {
			const newRadius = circle.current.getRadius();
			setRadius(Number(newRadius.toFixed(2)));
		});
	};

	useEffect(() => {
		if (!window.google) {
			const gMapScript = document.createElement("script");
			gMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GMAP_KEY}&libraries=places,drawing`;
			window.document.body.appendChild(gMapScript);
			gMapScript.addEventListener("load", () => {
				initializeMap();
			});
		} else {
			initializeMap();
		}
	}, []);

	return (
		<div className="gmap-container">
			<input
				style={{ visibility: isVisible ? "visible" : "hidden" }}
				id="pac-input"
				className="controls"
				type="text"
				placeholder="Search Box"
			/>
			<div
				ref={gmapRef}
				style={{
					width: "100%",
					height: height
				}}
			/>
		</div>
	);
};
