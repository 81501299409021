import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/ChargeEdit/BasicInfo";
import ItemsAndLocations from "../components/ChargeEdit/ItemsAndLocations";

// client
import { store } from "../store/configureStore";

// utils
import history from "../history";
import { removeProp, adjustNestedContainer, findChangedKeysInTwoObjects, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchFulfillmentModes } from "../actions/actions";
import { fetchChargesList, fetchChargeDetail, editCharge } from "../actions/charges";
import { ActionTypes } from "../actions/_types";

// reducers
import { chargeDetailsReducer, CHARGE_DETAILS_INITIAL_STATE } from "../reducers/charges";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constants
import { NESTED_ENTITY_TYPES, TRACKING_EVENT_NAMES, TRACKING_SOURCE, TRACKING_STATUS } from "../client-config";
import { APPLICABLE_ON_OPTIONS } from "../containers/ChargeCreate";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Items & Locations",
		value: "items-locations"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const ChargeEdit = ({
	biz,
	access,
	readOnly = false,
	match,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [chargeDetails, dispatch] = useReducer(chargeDetailsReducer, CHARGE_DETAILS_INITIAL_STATE);
	const { loading, data, error, backupData } = chargeDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchFulfillmentModes();
	}, []);

	useEffect(() => {
		fetchChargeDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchChargesList();
					history.push("/charges");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value, index, range = false) => {
		if (range) {
			let updatedRangeValues = data.rangeValues.map((range, i) => {
				if (i === index) {
					return {
						...range,
						[field]: value
					};
				}
				return range;
			});
			dispatch({
				type: ActionTypes.UPDATE_CHARGE_DETAIL,
				payload: {
					rangeValues: updatedRangeValues
				}
			});
		} else if (field === "applicableOn") {
			dispatch({
				type: ActionTypes.UPDATE_CHARGE_DETAIL,
				payload: {
					[field]: value,
					fieldType: value && value?.types?.length ? value.types[0] : null,
					rangeValues: [
						{
							min: "",
							max: "",
							value: ""
						}
					]
				}
			});
		} else {
			dispatch({
				type: ActionTypes.UPDATE_CHARGE_DETAIL,
				payload: {
					[field]: value
				}
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleAddRange = () => {
		dispatch({
			type: ActionTypes.UPDATE_CHARGE_DETAIL,
			payload: {
				rangeValues: [
					...data.rangeValues,
					{
						min: "",
						max: "",
						value: ""
					}
				]
			}
		});
	};

	const handleDeleteRange = (index) => {
		let updatedRangeValues = data.rangeValues.filter((range, i) => i !== index);
		dispatch({
			type: ActionTypes.UPDATE_CHARGE_DETAIL,
			payload: {
				rangeValues: updatedRangeValues
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const fieldsUpdated = findChangedKeysInTwoObjects(data, backupData);
		const eventMeta = {
			fields_updated: fieldsUpdated,
			num_of_fields_updated: fieldsUpdated.length
		};
		const sanitisedData = removeProp(data, "__typename");
		const resp = await editCharge(sanitisedData, dispatch);
		if (resp) {
			eventMeta.status = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.CHARGES_BASIC_INFO_UPDATE, eventMeta);

			setFormTouched(false);
		} else {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.CHARGES_BASIC_INFO_UPDATE, eventMeta);
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_CHARGE_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "charges"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="charge-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Charge"}
				subTitle="Edit this charge"
				submitTitle="Save"
				loading={loading || confirmLoading}
				showMoreBtn={formTab === FORM_TABS[0].value}
				numRows={
					data?.applicableOn?.value === APPLICABLE_ON_OPTIONS[1].value &&
					data?.fieldType?.value === data?.applicableOn?.types[0]?.value
						? 4
						: 3
				}
				dependencies={[data.applicableOn, data.fieldType, data.rangeValues?.length]}
				hideActions={(formTab === FORM_TABS[0].value && !isFormTouched) || formTab === FORM_TABS[1].value}
				disabled={!isFormTouched}
				isNested={isNested}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && !readOnly && (
							<Button
								classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
								clickHandler={() => setArchiveRestore(true)}
							>
								{data.isActive ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							options={APPLICABLE_ON_OPTIONS}
							currencySymbol={biz.currencySymbol}
							handleForm={handleForm}
							handleAddRange={handleAddRange}
							handleDeleteRange={handleDeleteRange}
							validations={error.fields || {}}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<ItemsAndLocations
							entityId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							setConfirmLoading={setConfirmLoading}
							handleNestedEntity={handleNestedEntity}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[6]}
						entityName={data.title}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						sourceOfTrigger={TRACKING_SOURCE.DETAIL_VIEW}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={!access.isCatalogueManagement || readOnly}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access
});
export default connect(mapStateToProps)(ChargeEdit);
