import React from "react";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";
import { debounce } from "lodash";

// components
import { ButtonIcon } from "../components/_commons/ButtonIcon";
import ChartMetric from "../components/Analytics/Common/ChartMetric";
import Popover from "../components/_commons/Popover";

// third party
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

// graphql
import {
	GET_METRIC_DATA,
	GET_LINE_CHART_DATA,
	GET_GROUPED_LINE_CHART_DATA,
	GET_PIE_CHART_DATA,
	GET_BAR_CHART_DATA,
	GET_STACKED_BAR_CHART_DATA,
	GET_SANKEY_CHART_DATA,
	GET_HEATMAP_CHART_DATA,
	GET_AVAILABILITY_HEATMAP_CHART_DATA,
	GET_TABULAR_DATA,
	GET_ITEMS_LIST,
	GET_LOCATIONS,
	GET_LOCATION_GROUPS_LIST,
	GET_LOCATION,
	GET_ITEM,
	SAVE_COMPARISON,
	GET_COMPARISONS,
	UPDATE_COMPARISON,
	DELETE_COMAPARISON,
	GET_COMPARISON,
	UPDATE_LAST_VIEWED,
	GET_USER_COMPARE_VISIT,
	UPDATE_USER_COMPARE_VISIT_MUTATION,
	GET_STORES_LIST,
	GET_LOCATION_GROUP
} from "../graphql/analytics";

// utils
import {
	printCurrency,
	commifyNumbers,
	timeStampToDurPreset,
	formatDate,
	capitaliseTextStrict,
	lS
} from "../atlas-utils";

// helpers
import { calculatePercentages, getReadableDateFilter, parseValue, roundAndFormatNumber } from "../helpers/analytics";

// actions
import { ActionTypes } from "./_types";

// constants
import {
	ANALYTICS_DEFAULT_COLORS,
	ANALYTICS_SANKEY_COLORS,
	BRAND_COLORS,
	CATALOGUE_PLATFORMS_LOGO
} from "../client-config";
import { GET_BIZ_PLATFORMS, GET_BRANDS_LIST } from "../graphql/misc";
const BAR_CHART_KEY_MAP = {
	// lost revenue and lost orders bar chart
	"Platform (Pre-Ack)": "Cancelled Pre Acknowledgement",
	"Platform (Post-Ack)": "Cancelled Post Acknowledgement",
	"Merchant (Pre-Ack)": "Cancelled Pre Acknowledgement",
	"Merchant (Post-Ack)": "Cancelled Post Acknowledgement"
};

// generate random numbers for dummy data
export const getRandomNum = (min, max, decimals = false, nullableBelow) => {
	const num = Math.random() * (max - min + 1) + min;
	return decimals ? num.toFixed(1) : nullableBelow && Math.floor(num) < nullableBelow ? null : Math.floor(num);
};

// get nearest round value for 'yScaleMax' in line and bar charts
// this is to keep the lines and bars within the cartesian grid
export const getNearestRoundValue = (val, multiplier = 1.2) => {
	return Math.floor(val !== 0 ? val * multiplier : 1);
};

// get duration object from applied date filter
export const getDurationObject = (appliedDateFilter) => {
	const { dateFilter: currDateFilter } = appliedDateFilter.current;
	const { dateFilter: compDateFilter } = appliedDateFilter.compare;
	const currDates = currDateFilter.split(",");
	const compDates = compDateFilter.split(",");
	const obj = {};

	// for current date range
	if (currDates.length === 1) {
		obj.duration = {
			preset: currDateFilter
		};
	} else if (currDates[0] && currDates[1]) {
		obj.duration = {
			custom: {
				startDate: currDates[0],
				endDate: currDates[1]
			}
		};
	}

	// for comparison date range
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		obj.comparisonDuration = {
			startDate: compDates[0],
			endDate: compDates[1]
		};
	}

	return obj;
};

// get applied filters and applied date filter
export const getAllAppliedFilters = (includeBrand = true, includeLocation = true, includePlatform = true) => {
	const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	const { appliedFilters, appliedDateFilter } = store.getState().analyticsFiltersState;
	const bizId = store.getState().login.loggedInbizDetail.id;
	const bizPlatforms = store.getState().configItems.bizPlatforms;
	const durationObject = getDurationObject(appliedDateFilter);
	let filters = [];

	Object.keys(appliedFilters).forEach((f) => {
		const appliedFiltersExludingAll = appliedFilters[f]?.filter((entity) => entity !== "all");

		if (f === "brand_id" && includeBrand && isMultibrandEnabled && appliedFiltersExludingAll?.length) {
			filters.push({
				field: f,
				value: String(appliedFiltersExludingAll.join(","))
			});
		} else if (f === "location_id" && includeLocation && appliedFiltersExludingAll?.length) {
			filters.push({
				field: f,
				value: String(appliedFiltersExludingAll.join(","))
			});
		} else if (f === "platform_names" && includePlatform && appliedFiltersExludingAll?.length) {
			//we need to send platform names instead of id's
			const platformNames = [];
			appliedFilters[f].forEach((platformId) => {
				if (platformId === "prime") {
					platformNames.push("Prime");
				} else {
					const plf = bizPlatforms?.items?.find((plf) => String(plf.id) === String(platformId));
					if (plf) {
						if (plf.platformName === "Meraki") {
							platformNames.push("Urbanpiper");
						} else {
							platformNames.push(plf?.platform?.name || plf.platformName?.split(" ")?.join(""));
						}
					}
				}
			});

			filters.push({
				field: f,
				value: String(platformNames.join(","))
			});
		} else if (f === "location_group_id" && includeLocation && appliedFiltersExludingAll?.length) {
			filters.push({
				field: f,
				value: String(appliedFiltersExludingAll.join(","))
			});
		}
	});

	return { durationObject, filters, bizId, isMultibrandEnabled };
};

// encode applied filters and applied date filter
export const getEncodedAnalyticsFilters = () => {
	const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	const { appliedFilters, appliedDateFilter } = store.getState().analyticsFiltersState;

	// filters to be encoded
	const filters = {};

	// get applied filters
	if (isMultibrandEnabled && appliedFilters?.brand_id) {
		filters.brand_id = appliedFilters?.brand_id?.join(",");
	}
	if (appliedFilters?.location_id) {
		filters.location_id = appliedFilters?.location_id.length == 0 ? "all" : appliedFilters?.location_id?.join(",");
	}
	if (appliedFilters?.location_group_id) {
		filters.location_group_id =
			appliedFilters?.location_group_id.length === 0 ? "all" : appliedFilters?.location_group_id?.join(",");
	}
	if (appliedFilters?.platform_names) {
		filters.platform_names =
			appliedFilters?.platform_names.length === 0 ? "all" : appliedFilters?.platform_names?.join(",");
	}

	// get applied date filter
	const dateFilter = {};
	if (appliedDateFilter?.current?.dateFilter) {
		dateFilter.current = appliedDateFilter?.current?.dateFilter;
	}
	if (appliedDateFilter?.compare?.dateFilter) {
		dateFilter.compare = appliedDateFilter?.compare?.dateFilter;
	}
	filters.dateFilter = dateFilter;

	// encode filters
	const encodedFilters = encodeURIComponent(JSON.stringify(filters));

	return encodedFilters;
};

// get x-axis readable timestamps for line and bar charts based on duration presets
export const getReadableTimestamp = (timestamp, durationPreset) => {
	let period = "";
	if (durationPreset == "THIS_YEAR") {
		const diff = moment().dayOfYear();
		if (diff < 31) {
			period = "day";
		} else {
			period = "month";
		}
	} else if (durationPreset == "LAST_90_DAYS" || durationPreset == "LAST_30_DAYS" || durationPreset == "THIS_MONTH") {
		period = "90day";
	} else if (durationPreset == "TODAY" || durationPreset == "YESTERDAY") {
		period = "hour";
	} else if (durationPreset == "THIS_WEEK") {
		const diff = moment().diff(moment().day("Monday"), "d");
		if (diff < 2) {
			period = "hour";
		} else {
			period = "day";
		}
	} else if (durationPreset?.includes(",")) {
		const dates = durationPreset.split(",");
		const diff = moment(dates[1]).diff(moment(dates[0]), "d");
		if (diff < 2) {
			period = "hour";
		} else {
			period = "day";
		}
	} else {
		period = "day";
	}
	return timeStampToDurPreset(timestamp, period, true);
};

// get tooltip readable timestamps for line and bar charts based on duration preests
export const getReadableTooltipTime = (timestamp, durationPreset) => {
	switch (durationPreset) {
		case "TODAY":
			return formatDate(timestamp, "DD MMM h A");
		case "YESTERDAY":
			return formatDate(timestamp, "DD MMM h A");
		case "LAST_7_DAYS":
			return formatDate(timestamp, "DD MMM");
		case "THIS_WEEK":
			return formatDate(timestamp, "DD MMM");
		case "LAST_30_DAYS":
			return formatDate(timestamp, "DD MMM");
		case "THIS_MONTH":
			return formatDate(timestamp, "DD MMM");
		case "LAST_90_DAYS":
			return formatDate(timestamp, "DD MMM");
		case "THIS_YEAR":
			return formatDate(timestamp, "DD MMM YYYY");
		default:
			return formatDate(timestamp);
	}
};

// show secondary text in a table column based on metric
const getSecondaryText = (metric, record) => {
	switch (metric) {
		case "revenue_by_location":
			// city
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "revenue_by_item":
			// category
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "orders_by_location":
			// city
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "orders_by_item":
			// category
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "item_performance":
			// category
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		default:
			return null;
	}
};

const isPercentageChangeColorsInverted = (metric, column) => {
	let invertedColors = false;
	switch (metric) {
		case "revenue_by_location":
			if (["ORDER_LOST_REVENUE", "ORDER_LOST_ORDERS"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "revenue_by_item":
			if (column.key === "ITEM_LOST_ORDERS") {
				invertedColors = true;
			}
			break;
		case "orders_by_location":
			if (["ORDER_LOST_REVENUE", "ORDER_LOST_ORDERS"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "orders_by_item":
			if (column.key === "ITEM_LOST_ORDERS") {
				invertedColors = true;
			}
			break;
		case "lost_revenue":
			if (["lost_revenue", "lost_orders"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "lost_revenue_breakdown":
			if (column.key === "ORDER_LOST_REVENUE") {
				invertedColors = true;
			}
			break;
		case "lost_orders":
			if (["lost_revenue", "lost_orders"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "lost_orders_breakdown":
			if (column.key === "ORDER_LOST_ORDERS") {
				invertedColors = true;
			}
			break;
		default:
			break;
	}
	return invertedColors;
};

// get tabular data for analytics entity list screen
export const getTabularData = (dataset, metric, entity) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		isComparisonApplied = true;
	}

	const tabularData = {};

	// count
	tabularData.count = dataset.count || 0;

	// column value type map
	const columnValueTypeMap = {};

	// fields
	tabularData.fields = dataset.columns
		?.filter((col) => col.isPrimary)
		?.map((col) => {
			if (col.valueType) {
				columnValueTypeMap[col.key] = col.valueType;
			}
			return col;
		});

	// columns
	tabularData.columns =
		dataset.columns
			?.filter((col) => col.isPrimary)
			?.map((col) => ({
				name: col.displayName,
				subName: col.subDisplayName,
				field: col.key,
				classes: col.valueType !== "str" ? "align-right" : "",
				sortKey: col.isSortable ? col.key : null,
				render: (record, i, rest) => (
					<div
						className={`table-cell ${col.key?.toLowerCase()} ${
							col.valueType !== "str" ? "align-right" : ""
						}`}
						title={i === 0 && !record?.[`${col.key.toLowerCase()}_description`] ? record[col.key] : null}
						key={i}
					>
						{i === 0 ? (
							<>
								<div className="primary">
									{rest.legends && rest.legends[record[col.key]?.toLowerCase()] && (
										<div
											className="color"
											style={{ backgroundColor: rest.legends[record[col.key]?.toLowerCase()] }}
										></div>
									)}
									{record?.[`${col.key.toLowerCase()}_description`] ? (
										<ChartMetric
											size="small"
											label={record[col.key] || record["id"] || ""}
											description={record?.[`${col.key.toLowerCase()}_description`]}
										/>
									) : (
										<b>{record[col.key] || record["id"] || ""}</b>
									)}
								</div>
								{getSecondaryText(metric, record)}
							</>
						) : (
							<>
								<div className="primary">
									{col.type === "currency" ? printCurrency(rest.currencySymbol) : ""}
									{col.valueType === "str" ? record[col.key] : commifyNumbers(record[col.key])}
									{col.type === "percentage" ? "%" : ""}
									{col.type === "time" ? " mins" : ""}
								</div>
								{isComparisonApplied &&
									record?.[`${col.key.toLowerCase()}_percentage_change`] !== undefined &&
									rest.sortedField === col.key && (
										<div className="secondary-right">
											<Popover
												data={{
													compareValue: record?.[`${col.key.toLowerCase()}_compare_value`],
													compareDate: getReadableDateFilter(true),
													type: col.type
												}}
												showOnHover={true}
												renderPopover={(data) => (
													<div className="compare-info">
														<div className="compare-date">{data.compareDate}</div>
														<div className="compare-value">
															{col.type === "currency"
																? printCurrency(rest.currencySymbol)
																: ""}
															{commifyNumbers(Math.round(data.compareValue || 0))}
															{col.type === "percentage" ? "%" : ""}
															{col.type === "time" ? " mins" : ""}
														</div>
													</div>
												)}
												position={
													i === dataset.columns?.filter((col) => col.isPrimary)?.length - 1 ||
													col.key === rest?.lastColumn
														? "middle-left"
														: "middle-right"
												}
											>
												<div
													className={
														"rate " +
														(!isPercentageChangeColorsInverted(metric, col)
															? record?.[`${col.key.toLowerCase()}_percentage_change`] >=
															  0
																? "up"
																: "down"
															: record?.[`${col.key.toLowerCase()}_percentage_change`] > 0
															? "down"
															: "up")
													}
												>
													<ButtonIcon
														icon={
															!isPercentageChangeColorsInverted(metric, col)
																? record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] >= 0
																	? "up"
																	: "down"
																: record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] <= 0
																? "down"
																: "up"
														}
														classes="icon"
														color={
															!isPercentageChangeColorsInverted(metric, col)
																? record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] >= 0
																	? "#0DA125"
																	: "#D64949"
																: record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] > 0
																? "#D64949"
																: "#0DA125"
														}
													/>
													<div>{record?.[`${col.key.toLowerCase()}_percentage_change`]}%</div>
												</div>
											</Popover>
										</div>
									)}
							</>
						)}
					</div>
				)
			})) || [];

	// rows
	tabularData.rows =
		dataset.rows?.map((row) => {
			const rowData = {};
			row.forEach((obj) => {
				rowData[obj.key] =
					!columnValueTypeMap[obj.key] || columnValueTypeMap[obj.key] === "str"
						? obj.value
						: Math.round(obj.value);
				if (obj.compareValue !== null) {
					rowData[`${obj.key.toLowerCase()}_compare_value`] = isComparisonApplied
						? parseFloat(obj.compareValue).toFixed(1)
						: undefined;
				}
				if (obj.percentageChange !== null) {
					rowData[`${obj.key.toLowerCase()}_percentage_change`] = isComparisonApplied
						? parseFloat(obj.percentageChange).toFixed(1)
						: undefined;
				}
				if (obj.description) {
					rowData[`${obj.key.toLowerCase()}_description`] = obj.description;
				}
			});
			return rowData;
		}) || [];

	return tabularData;
};

// get metrics data
export const getMetricsData = (metricsData = []) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		isComparisonApplied = true;
	}

	const metrics = {};
	metricsData.forEach((metric) => {
		metrics[metric.selection?.toLowerCase()?.split(" ")?.join("_")] = {
			...metric,
			value: metric.value === "nan" ? "0" : Math.round(metric.value),
			compareValue: isComparisonApplied
				? !metric.compareValue || metric.compareValue === "nan"
					? 0
					: Math.round(metric.compareValue)
				: undefined,
			percentageChange: isComparisonApplied
				? metric.percentageChange === "nan"
					? "0.0"
					: parseFloat(metric.percentageChange) !== null
					? parseFloat(metric.percentageChange).toFixed(1)
					: undefined
				: undefined
		};
	});
	return metrics;
};

// get bar chart data
export const getBarChartData = (data = [], type, groupKeys = false, showComparison = true) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1] && showComparison) {
		isComparisonApplied = true;
	}

	const chartData = [];
	if (type === "grouped-stacked") {
		data.forEach((obj) => {
			const bar = {
				label: capitaliseTextStrict(obj.label, true),
				current: 0,
				previous: 0,
				dataset: {
					current: {},
					previous: {}
				}
			};
			obj.values.forEach((val) => {
				// current
				bar.dataset.current[val.key] = Math.round(val.value);
				bar.current = bar.current + Math.round(val.value);

				// previous
				bar.dataset.previous[val.key] = Math.round(val.compareValue);
				bar.previous = bar.previous + Math.round(val.compareValue);
			});
			chartData.push(bar);
		});
	} else {
		data.forEach((obj) => {
			const bar = {
				label: capitaliseTextStrict(obj.label, true)
			};
			obj.values.forEach((val) => {
				bar[groupKeys ? BAR_CHART_KEY_MAP[val.key] : val.key ? val.key : "value"] = Math.round(val.value);
				if (isComparisonApplied) {
					bar[groupKeys ? `${BAR_CHART_KEY_MAP[val.key]}*` : val.key ? `${val.key}*` : "compare"] =
						Math.round(val.compareValue);
				}
			});
			chartData.push(bar);
		});
	}
	return chartData;
};

// get pie chart data
export const getPieChartData = (data) => {
	let transformedData = cloneDeep(data);

	// group the bottom values into 'Others'
	if (data.length > 5) {
		transformedData = data.sort((a, b) => b.value - a.value);
		const othersValue = transformedData
			.slice(5)
			.reduce((acc, obj) => acc + (isNaN(obj.value) ? 0 : Number(obj.value)), 0);
		transformedData = transformedData.slice(0, 5);
		transformedData.push({
			id: "others",
			name: "Others",
			value: othersValue,
			compareValue: null
		});
	}

	let total = 0;
	transformedData.forEach((obj) => {
		total += Math.round(obj.value);
	});

	let pieChartData = [...transformedData];
	if (total) {
		pieChartData = pieChartData.map((obj) => ({
			...obj,
			name: capitaliseTextStrict(obj.name, true),
			percent: `${((Math.round(obj.value) / total) * 100).toFixed(2)}%`
		}));
	}
	return pieChartData;
};

// get heatmap chart data
export const getHeatmapChartData = (data = [], showComparison = false, calculatePercentage = true) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1] && showComparison) {
		isComparisonApplied = true;
	}

	let total = 0;
	data.forEach((obj) => {
		obj.data.forEach((cell) => {
			total += Number(cell?.value);
		});
	});

	const chartData = [];
	data.forEach((obj, i) => {
		const row = {};
		row.id = isComparisonApplied ? getReadableDateFilter(i !== 0) : obj.y;
		row.data = obj.data.map((cell) => ({
			...cell,
			x: cell.x,
			y: calculatePercentage ? Math.round(cell?.value) : cell?.value?.toFixed(2),
			percent: calculatePercentage
				? Math.round(total) > 0
					? `${((Number(cell?.value) / Math.round(total)) * 100).toFixed(2)}%`
					: "0.00%"
				: `${cell?.value?.toFixed(2)}%`
		}));
		chartData.push(row);
	});
	return chartData;
};

// verify selected option and get filtered list of options in compare filter
const verifyAndGetCompareFilter = (selected, options) => {
	const { current } = store.getState().analyticsFiltersState.appliedDateFilter;
	let validOptions = [];
	let currCompare = selected || {};
	let filteredOptions = [];
	let compareFilterDisabled = false;

	if (current.dateTypeSelected.value === "range" || ["TODAY", "YESTERDAY"].includes(current.dateFilter)) {
		// hours
		validOptions = ["Hours"];
		// disable compare filter when date filter is either Today, Yesterday or custom date selection
		compareFilterDisabled = true;
	} else if (["THIS_WEEK", "LAST_7_DAYS", "THIS_MONTH"].includes(current.dateFilter)) {
		// hours, days
		validOptions = ["Hours", "Days of the Week"];
	} else if (["LAST_30_DAYS", "LAST_15_DAYS"].includes(current.dateFilter)) {
		// hours, days, dates
		validOptions = ["Hours", "Days of the Week", "Dates"];
	} else if (current.dateFilter === "LAST_90_DAYS") {
		// days, weeks
		validOptions = ["Days of the Week", "Weeks"];
	} else if (current.dateFilter === "THIS_YEAR") {
		// days, dates, weeks, months
		validOptions = ["Days of the Week", "Dates", "Weeks", "Months"];
	}
	filteredOptions = options.filter((opt) => validOptions.includes(opt.label));
	currCompare = !validOptions.includes(selected?.label) ? filteredOptions[0] : selected;

	return { currCompare, filteredOptions, compareFilterDisabled };
};

// get table columns selector fields
export const getTableColumnsSelectorFields = (tableData, currSelectedColumns) => {
	const tableColumnsSelected = {
		columns: {
			...tableData.fields.reduce(
				(obj, col) => ({
					...obj,
					[col.key]:
						currSelectedColumns?.[col.key] !== undefined ? currSelectedColumns?.[col.key] : col.isDefault
				}),
				{}
			)
		}
	};
	return tableColumnsSelected;
};

// revenue analytics
export const updateRevenueAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_REVENUE_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

// analyticsFilterStateUpdate
export const updateAnalyticsFilterState = (data) => {
	store.dispatch({
		type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
		payload: data
	});
};

export const fetchGrossRevenueMetrics = async (metric) => {
	updateRevenueAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_gross_revenue_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchNetRevenueMetrics = async (metric) => {
	updateRevenueAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_net_revenue_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchNetRevenueChart = async (metric) => {
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Net Revenue (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Net Revenue",
					tooltipYName: `Net Revenue${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchGrossRevenueChart = async (metric) => {
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "gross_revenue_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => {
					const getID = (str) => {
						const ids = {
							ORDER_REVENUE: "Net Revenue",
							ORDER_TOTAL_TAXES: "Taxes",
							ORDER_MERCHANT_DISCOUNT: "Discounts",
							ORDER_TOTAL_CHARGES: "Charges",
							ORDER_GROSS_REVENUE: "Gross Revenue"
						};
						return ids[str];
					};
					const getColor = (str) => {
						const color = {
							ORDER_GROSS_REVENUE: "#20B2AA",
							ORDER_TOTAL_CHARGES: "#9FB3FF",
							ORDER_MERCHANT_DISCOUNT: "#FFA05B",
							ORDER_REVENUE: "#2543B6",
							ORDER_TOTAL_TAXES: "#9048C8"
						};
						return color[str];
					};
					const id = obj.id?.split("#")?.[0];
					return {
						...obj,
						id: `${getID(id)}${i % 2 != 0 ? "*" : ""}`,
						color: `${getColor(id)}`,
						data: obj.data.map((pt) => {
							if (Math.round(pt.y) > maxValue) {
								maxValue = Math.round(pt.y);
							}
							return pt;
						})
					};
			  })
			: [];
		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueBreakdownChart = async (metric) => {
	const { selectedChart, breakdownBy, graphData, legends } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const pieChartBreakdownQueries = {
			platform: "revenue_breakdown_plt_pie",
			brand: "revenue_breakdown_brand_pie"
		};
		const lineChartBreakdownQueries = {
			platform: "revenue_breakdown_plt_line",
			brand: "revenue_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "pie"
					? pieChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		if (selectedChart === "pie") {
			variables.sort = {
				field: "pie",
				order: "DESC"
			};
		}

		const resp = await client.query({
			query: selectedChart === "pie" ? GET_PIE_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const legendColors = { ...legends };
		if (selectedChart === "pie" && resp.data.getPieChartData?.objects) {
			resp.data.getPieChartData.objects.forEach((obj, i) => {
				legendColors[obj.name?.toLowerCase()] =
					i > 4 ? ANALYTICS_DEFAULT_COLORS[5] : ANALYTICS_DEFAULT_COLORS[String(i).slice(-1)];
			});
		} else if (resp.data.getGroupedLineChartData?.objects) {
			resp.data.getGroupedLineChartData.objects.forEach((obj, i) => {
				legendColors[obj.id?.split("#")?.[0]?.toLowerCase()] =
					ANALYTICS_DEFAULT_COLORS[
						durationObject?.comparisonDuration ? String(Math.floor(i / 2)).slice(-1) : String(i).slice(-1)
					];
			});
		}

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "pie"
						? resp.data.getPieChartData.isLocationOverlapping
						: resp.data.getGroupedLineChartData.isLocationOverlapping
			});
		}

		let maxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "pie"
					? getPieChartData(resp.data.getPieChartData?.objects || [])
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > maxValue) {
									maxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			legends: legendColors,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "revenue_breakdown_plt_tabular",
			brand: "revenue_breakdown_brand_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchAverageOrderValueMetrics = async (metric) => {
	updateRevenueAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_avg_order_value",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchAverageOrderValueChart = async (metric) => {
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_value_distribution",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		let totalOrders = {};
		resp.data.getLineChartData.objects.forEach((line, i) => {
			let total = 0;
			line.data.forEach((pt) => {
				total += Math.round(pt.y);
			});
			totalOrders[i] = total;
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Number of Orders (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Number of Orders",
					tooltipYName: `Number of Orders${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						const point = { ...pt };
						point.percent =
							totalOrders[i] > 0 ? ((Math.round(pt.y) / totalOrders[i]) * 100).toFixed(2) : 0.0;
						return point;
					})
			  }))
			: [];

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueByLocation = async (metric = "revenue_by_location", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_by_location_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateRevenueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueByItem = async (metric = "revenue_by_item", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_by_item_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateRevenueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueMetrics = async (metric) => {
	updateRevenueAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_lost_revenue",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueChart = async (metric) => {
	const { selectedChart, showComparison, graphData, yScaleMax, maxValue } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "bar" ? "revenue_lost_revenue_bar" : "revenue_lost_revenue_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_BAR_CHART_DATA : GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getBarChartData
						? getBarChartData(resp.data.getBarChartData?.objects, "grouped", true, showComparison).map(
								(bar) => {
									Object.keys(bar).forEach((key) => {
										if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
											chartMaxValue = Math.round(bar[key]);
										}
									});
									return bar;
								}
						  )
						: []
					: resp.data.getLineChartData
					? resp.data.getLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								resp.data.getLineChartData?.objects?.length > 1
									? `Lost Revenue (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
									: "Lost Revenue",
							tooltipYName: `Lost Revenue${i !== 0 ? "*" : ""}`,
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  }))
					: []
		};

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "bar"
						? resp.data.getBarChartData.isLocationOverlapping
						: resp.data.getLineChartData.isLocationOverlapping
			});
		}
		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueTable = async (metric) => {
	const { sort } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_lost_revenue_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueBreakdownChart = async (metric) => {
	const { selectedChart, showComparison, breakdownBy, graphData, yScaleMax, maxValue } =
		store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const barChartBreakdownQueries = {
			platform: "revenue_lost_revenue_plt_stacked_bar",
			brand: "revenue_lost_revenue_brand_stacked_bar"
		};
		const lineChartBreakdownQueries = {
			platform: "revenue_lost_revenue_breakdown_plt_line",
			brand: "revenue_lost_revenue_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "bar"
					? barChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_STACKED_BAR_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getStackedBarChartData
						? getBarChartData(
								resp.data.getStackedBarChartData?.objects,
								showComparison && durationObject?.comparisonDuration ? "grouped-stacked" : "stacked",
								false,
								showComparison
						  ).map((bar) => {
								let tempMaxValue = 0;
								Object.keys(bar).forEach((key) => {
									if (
										(!showComparison || !durationObject?.comparisonDuration) &&
										typeof bar[key] === "number"
									) {
										// stacked bar chart
										tempMaxValue += Math.round(bar[key]);
									} else if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
										// grouped-stacked bar chart
										chartMaxValue = Math.round(bar[key]);
									}
								});
								if (tempMaxValue > 0 && tempMaxValue > chartMaxValue) {
									chartMaxValue = tempMaxValue;
								}
								return bar;
						  })
						: []
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								showComparison && durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								showComparison && durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "bar"
						? resp.data.getStackedBarChartData.isLocationOverlapping
						: resp.data.getGroupedLineChartData.isLocationOverlapping
			});
		}
		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "revenue_lost_revenue_plt_breakdown_tabular",
			brand: "revenue_lost_revenue_brand_breakdown_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// order analytics
export const updateOrderAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_ORDER_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

export const fetchOrdersReceivedMetrics = async (metric) => {
	updateOrderAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_received_orders_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersReceivedChart = async (metric) => {
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_received_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Orders Received (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Orders Received",
					tooltipYName: `Orders Received${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return pt;
					})
			  }))
			: [];

		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderPerformanceMetrics = async (metric) => {
	updateOrderAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_overall_performance",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderPerformanceChart = async (metric) => {
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_performance_sankey",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const colors = {
			"Orders Received": ANALYTICS_SANKEY_COLORS["sankey1"],
			"Orders Completed": ANALYTICS_SANKEY_COLORS["sankeyPositive"],
			// 'Rejected': ANALYTICS_SANKEY_COLORS['sankeyIntermediate1'],
			"Cancelled Pre-Ack": ANALYTICS_SANKEY_COLORS["sankeyIntermediate2"],
			"Cancelled Post-Ack": ANALYTICS_SANKEY_COLORS["sankeyNegative"]
		};

		const resp = await client.query({
			query: GET_SANKEY_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const sankeyData = resp.data.getSankeyChartData.objects;

		// calculate total orders
		let totalOrders = 0;
		sankeyData.links.forEach((link) => {
			totalOrders += Math.round(link.value);
		});

		let maxValue = 0;
		const graphData = {
			...sankeyData,
			nodes: sankeyData.nodes.map((node) => ({
				...node,
				nodeColor: colors[node.id]
			})),
			links: sankeyData.links
				.map((link) => {
					if (Math.round(link.value) > maxValue) {
						maxValue = Math.round(link.value);
					}
					// calculate percentage values to show on sankey chart
					return {
						...link,
						value: totalOrders > 0 ? (Math.round(link.value) / totalOrders) * 100 : 0.0,
						count: Math.round(link.value),
						startColor: colors[link.target],
						endColor: colors[link.target]
					};
				})
				.filter((link) => link.value > 0)
		};
		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			maxValue
		});

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getSankeyChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersBreakdownChart = async (metric) => {
	const { selectedChart, breakdownBy, graphData, legends } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const pieChartBreakdownQueries = {
			platform: "order_received_breakdown_plt_pie",
			brand: "order_received_breakdown_brand_pie"
		};
		const lineChartBreakdownQueries = {
			platform: "order_breakdown_plt_line",
			brand: "order_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "pie"
					? pieChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		if (selectedChart === "pie") {
			variables.sort = {
				field: "pie",
				order: "DESC"
			};
		}

		const resp = await client.query({
			query: selectedChart === "pie" ? GET_PIE_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const legendColors = { ...legends };
		if (selectedChart === "pie" && resp.data.getPieChartData?.objects) {
			resp.data.getPieChartData.objects.forEach((obj, i) => {
				legendColors[obj.name?.toLowerCase()] =
					i > 4 ? ANALYTICS_DEFAULT_COLORS[5] : ANALYTICS_DEFAULT_COLORS[String(i).slice(-1)];
			});
		} else if (resp.data.getGroupedLineChartData?.objects) {
			resp.data.getGroupedLineChartData.objects.forEach((obj, i) => {
				legendColors[obj.id?.split("#")?.[0]?.toLowerCase()] =
					ANALYTICS_DEFAULT_COLORS[
						durationObject?.comparisonDuration ? String(Math.floor(i / 2)).slice(-1) : String(i).slice(-1)
					];
			});
		}

		let maxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "pie"
					? getPieChartData(resp.data.getPieChartData?.objects || [])
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > maxValue) {
									maxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "pie"
						? resp.data.getPieChartData.isLocationOverlapping
						: resp.data.getGroupedLineChartData.isLocationOverlapping
			});
		}

		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			legends: legendColors,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "order_breakdown_plt_tabular",
			brand: "order_breakdown_brand_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersByLocation = async (metric = "orders_by_location", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_by_location_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateOrderAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderFrequency = async (metric) => {
	const { compare, showComparison } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { loading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ORDER_CREATED_DATE_HOUR" },
			{ label: "Days of the Week", value: "ORDER_CREATED_WEEK_DAY" },
			{ label: "Dates", value: "ORDER_CREATED_DATE" },
			{ label: "Weeks", value: "ORDER_CREATED_MONTH_WEEK" },
			{ label: "Months", value: "ORDER_CREATED_MONTH" }
		];
		const { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		const variables = {
			query: "order_order_distribution_heatmap",
			filters: [...filters, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const graphData = getHeatmapChartData(resp.data.getHeatmapChartData?.objects, showComparison);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getHeatmapChartData.isLocationOverlapping
			});
		}
		updateOrderAnalyticsState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersByItem = async (metric = "orders_by_item", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_by_item_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateOrderAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersMetrics = async (metric) => {
	updateOrderAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_lost_orders",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersChart = async (metric) => {
	const { selectedChart, showComparison, graphData, yScaleMax, maxValue } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "bar" ? "lost_orders_bar" : "order_lost_orders_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_BAR_CHART_DATA : GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getBarChartData
						? getBarChartData(resp.data.getBarChartData?.objects, "grouped", true, showComparison).map(
								(bar) => {
									Object.keys(bar).forEach((key) => {
										if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
											chartMaxValue = Math.round(bar[key]);
										}
									});
									return bar;
								}
						  )
						: []
					: resp.data.getLineChartData
					? resp.data.getLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								resp.data.getLineChartData?.objects?.length > 1
									? `Lost Orders (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
									: "Lost Orders",
							tooltipYName: `Lost Orders${i !== 0 ? "*" : ""}`,
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  }))
					: []
		};

		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "bar"
						? resp.data.getBarChartData.isLocationOverlapping
						: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersTable = async (metric) => {
	const { sort } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_lost_order_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersBreakdownChart = async (metric) => {
	const { selectedChart, showComparison, breakdownBy, graphData, yScaleMax, maxValue } =
		store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const barChartBreakdownQueries = {
			platform: "order_lost_orders_plt_stacked_bar",
			brand: "order_lost_orders_brand_stacked_bar"
		};
		const lineChartBreakdownQueries = {
			platform: "order_lost_order_breakdown_plt_line",
			brand: "order_lost_order_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "bar"
					? barChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_STACKED_BAR_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getStackedBarChartData
						? getBarChartData(
								resp.data.getStackedBarChartData?.objects,
								showComparison && durationObject?.comparisonDuration ? "grouped-stacked" : "stacked",
								false,
								showComparison
						  ).map((bar) => {
								let tempMaxValue = 0;
								Object.keys(bar).forEach((key) => {
									if (
										(!showComparison || !durationObject?.comparisonDuration) &&
										typeof bar[key] === "number"
									) {
										// stacked bar chart
										tempMaxValue += Math.round(bar[key]);
									} else if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
										// grouped-stacked bar chart
										chartMaxValue = Math.round(bar[key]);
									}
								});
								if (tempMaxValue > 0 && tempMaxValue > chartMaxValue) {
									chartMaxValue = tempMaxValue;
								}
								return bar;
						  })
						: []
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								showComparison && durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								showComparison && durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "bar"
						? resp.data.getStackedBarChartData.isLocationOverlapping
						: resp.data.getGroupedLineChartData.isLocationOverlapping
			});
		}
		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "order_lost_order_plt_breakdown_tabular",
			brand: "order_lost_order_brand_breakdown_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// operations analytics
export const updateOperationsAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_OPERATIONS_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

export const fetchOperationsSummaryMetrics = async (metric) => {
	updateOperationsAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operations_order_completion_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOperationsAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderCompletionTimeMetrics = async (metric) => {
	updateOperationsAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_avg_completion_time",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOperationsAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderCompletionTimeChart = async (metric) => {
	updateOperationsAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_completion_bar",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_BAR_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const graphData = resp.data.getBarChartData
			? getBarChartData(resp.data.getBarChartData?.objects, "grouped")
					?.reverse()
					?.map((bar) => {
						let _bar = { ...bar };
						Object.keys(bar).forEach((key) => {
							let val = typeof bar[key] === "number" ? bar[key] / 60 : bar[key];
							_bar[key === "value" ? "time" : key] = val;
							if (typeof bar[key] === "number" && key !== "compare" && val > chartMaxValue) {
								chartMaxValue = val;
							}
						});
						_bar.percent =
							_bar.time > 0 ? (((_bar.time - _bar.compare) * 100) / _bar.time).toFixed(1) : 0.0;
						return _bar;
					})
			: [];

		updateOperationsAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			maxValue: getNearestRoundValue(chartMaxValue !== 1 ? chartMaxValue : 2, 1.5)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getBarChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderCompletionFunnelChart = async (metric) => {
	updateOperationsAnalyticsState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_completion_funnel_sankey",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const colors = {
			Received: ANALYTICS_SANKEY_COLORS["sankey1"],
			Acknowledged: ANALYTICS_SANKEY_COLORS["sankey2"],
			Prepared: ANALYTICS_SANKEY_COLORS["sankey3"],
			Dispatched: ANALYTICS_SANKEY_COLORS["sankey4"],
			Delivered: ANALYTICS_SANKEY_COLORS["sankeyPositive"],
			"Merchant Cancellation": ANALYTICS_SANKEY_COLORS["sankeyIntermediate1"],
			"Aggregator Cancellation": ANALYTICS_SANKEY_COLORS["sankeyIntermediate2"]
			// 'Rejected': ANALYTICS_SANKEY_COLORS['sankeyNegative'],
		};
		const nodesOrderByIndex = {
			Received: 0,
			Acknowledged: 1,
			Prepared: 2,
			Dispatched: 3,
			Delivered: 4,
			"Aggregator Cancellation": 5,
			"Merchant Cancellation": 6
			// 'Rejected': 7,
		};

		const resp = await client.query({
			query: GET_SANKEY_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const sankeyData = resp.data.getSankeyChartData.objects;

		// calculate total orders and percentage values to show on sankey cahrt
		let totalOrders = 0;
		let linkValues = [];
		sankeyData.links.slice(0, 3).forEach((link) => {
			totalOrders += Math.round(link.value);
		});
		sankeyData.links.slice(0, 3).forEach((link) => {
			linkValues.push(totalOrders > 0 ? (Math.round(link.value) / totalOrders) * 100 : 0);
		});
		sankeyData.links.slice(3, 6).forEach((link) => {
			linkValues.push(
				Math.round(sankeyData.links[0].value) > 0
					? (Math.round(link.value) / Math.round(sankeyData.links[0].value)) * linkValues[0]
					: 0
			);
		});
		sankeyData.links.slice(6, 9).forEach((link) => {
			linkValues.push(
				Math.round(sankeyData.links[3].value) > 0
					? (Math.round(link.value) / Math.round(sankeyData.links[3].value)) * linkValues[3]
					: 0
			);
		});
		sankeyData.links.slice(9).forEach((link) => {
			linkValues.push(
				Math.round(sankeyData.links[6].value) > 0
					? (Math.round(link.value) / Math.round(sankeyData.links[6].value)) * linkValues[6]
					: 0
			);
		});

		// re-arrange nodes order
		let nodes = ["", "", "", "", "", "", ""];
		sankeyData.nodes.forEach((node) => {
			nodes[nodesOrderByIndex[node.id]] = node;
		});

		let maxValue = 0;
		const graphData = {
			...sankeyData,
			nodes: nodes.map((node) => ({
				...node,
				nodeColor: colors[node.id]
			})),
			links: sankeyData.links
				.map((link, i) => {
					if (Math.round(link.value) > maxValue) {
						maxValue = Math.round(link.value);
					}
					return {
						...link,
						value: linkValues[i],
						count: Math.round(link.value),
						startColor: colors[link.target],
						endColor: colors[link.target]
					};
				})
				.filter((link) => link.value > 0)
		};
		updateOperationsAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			maxValue
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getSankeyChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRestaurantAvailability = async (metric) => {
	const { compare, showComparison } = store.getState().operationsAnalytics[metric];
	updateOperationsAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "LOCATION_ACTION_DATE_HOUR", type: "hour" },
			{ label: "Days of the Week", value: "LOCATION_ACTION_WEEK_DAY", type: "day" },
			{ label: "Dates", value: "LOCATION_ACTION_DATE", type: "day" },
			{ label: "Weeks", value: "LOCATION_ACTION_MONTH_WEEK", type: "week" },
			{ label: "Months", value: "LOCATION_ACTION_MONTH", type: "month" }
		];
		let { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		// remove Dates as an option as Avg & Compare will not work for this granularity; for: last 15 days, last 30 days
		if (["LAST_15_DAYS", "LAST_30_DAYS"].includes(durationObject?.duration?.preset)) {
			const validOptions = ["Hours", "Days of the Week"];
			filteredOptions = filteredOptions.filter((opt) => validOptions.includes(opt.label));
			currCompare = !validOptions.includes(currCompare?.label) ? filteredOptions[0] : currCompare;
		}

		const variables = {
			query: "operation_location_availability_heatmap",
			filters: [...filters, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_AVAILABILITY_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		let totalSum = 0;
		resp.data.getAvailabilityHeatmapChartData.objects.map((val) => {
			let dataValSum = 0;
			val.data.map((dataVal) => {
				dataValSum += parseFloat(dataVal?.value);
			});
			totalSum += dataValSum / val.data.length;
		});

		const graphData = getHeatmapChartData(
			resp.data.getAvailabilityHeatmapChartData?.objects,
			showComparison,
			false
		);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});

		updateOperationsAnalyticsState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// catalogue analytics
export const updateCatalogueAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_CATALOGUE_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

export const fetchCatalogueMetrics = async (metric) => {
	updateCatalogueAnalyticsState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_category_performance_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateCatalogueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateCatalogueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCategoryPerformanceTable = async (metric = "category_performance", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().catalogueAnalytics[metric];
	updateCatalogueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_category_performance_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateCatalogueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			searchFieldSelected: resp.data.getTabularData?.searchKeywords?.[0] || searchFieldSelected,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateCatalogueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchItemPerformanceTable = async (metric = "item_performance", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().catalogueAnalytics[metric];
	updateCatalogueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_item_performance_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateCatalogueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateCatalogueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// analytics entity detail
export const updateAnalyticsEntityDetailState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_ANALYTICS_ENTITY_DETAIL_STATE,
		payload: {
			metric,
			data
		}
	});
};

// entity detail - item performance
export const fetchRevenueTrendMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_revenue_metrics",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueTrendChart = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_revenue_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Net Revenue (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Net Revenue",
					tooltipYName: `Net Revenue${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchUnitsSoldMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_units_sold_metrics",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchUnitsSoldChart = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_units_sold_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Units Sold (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Units Sold",
					tooltipYName: `Units Sold${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueOrderFrequency = async (metric, itemId) => {
	const { compare, showComparison } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ORDER_ITEM_CREATED_DATE_HOUR" },
			{ label: "Days of the Week", value: "ORDER_ITEM_CREATED_WEEK_DAY" },
			{ label: "Dates", value: "ORDER_ITEM_CREATED_DATE" },
			{ label: "Weeks", value: "ORDER_ITEM_CREATED_MONTH_WEEK" },
			{ label: "Months", value: "ORDER_ITEM_CREATED_MONTH" }
		];
		const { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		const variables = {
			query: "catalogue_order_distribution_heatmap",
			filters: [...filters, { field: "item", value: itemId }, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(resp.data.getHeatmapChartData?.objects, showComparison);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLocationPerformanceTable = async (
	metric = "location_performance",
	applFilters = {},
	itemId
) => {
	const {
		limit = 10,
		offset = 0,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_location_performance_tabular",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchPopularAddOnsTable = async (metric = "popular_add_ons", applFilters = {}, itemId) => {
	const {
		limit = 10,
		offset = 0,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_popular_addon_tabular",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			searchFieldSelected: resp.data.getTabularData?.searchKeywords?.[0] || searchFieldSelected,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchVariantPerformanceChart = async (metric, itemId, variantId) => {
	const { selectedChart = "pie", graphData, legends } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "pie" ? "catalogue_variant_performance_pie" : "catalogue_variant_performance_line",
			filters: [...filters, { field: "item", value: itemId }, { field: "option_group", value: variantId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		if (selectedChart === "pie") {
			variables.sort = {
				field: "pie",
				order: "DESC"
			};
		}

		const resp = await client.query({
			query: selectedChart === "pie" ? GET_PIE_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const legendColors = { ...legends };
		if (selectedChart === "pie" && resp.data.getPieChartData?.objects) {
			resp.data.getPieChartData.objects.forEach((obj, i) => {
				legendColors[obj.name?.toLowerCase()] =
					i > 4 ? ANALYTICS_DEFAULT_COLORS[5] : ANALYTICS_DEFAULT_COLORS[String(i).slice(-1)];
			});
		} else if (resp.data.getGroupedLineChartData?.objects) {
			resp.data.getGroupedLineChartData.objects.forEach((obj, i) => {
				legendColors[obj.id?.split("#")?.[0]?.toLowerCase()] =
					ANALYTICS_DEFAULT_COLORS[
						durationObject?.comparisonDuration ? String(Math.floor(i / 2)).slice(-1) : String(i).slice(-1)
					];
			});
		}
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "pie"
						? resp.data.getPieChartData.isLocationOverlapping
						: resp.data.getGroupedLineChartData.isLocationOverlapping
			});
		}
		let maxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "pie"
					? getPieChartData(resp.data.getPieChartData?.objects || [])
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > maxValue) {
									maxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			legends: legendColors,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchVariantPerformanceTable = async (metric, itemId, variantId) => {
	const { sort, tableColumnsSelected } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_variant_performance_tabular",
			filters: [...filters, { field: "item", value: itemId }, { field: "option_group", value: variantId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateAnalyticsEntityDetailState(metric, {
			tableLoading: false,
			tabularData: {
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOfflineCountMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_offline_item_metrics",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOfflineCountChart = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_offline_items_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Offline Count (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Offline Count",
					tooltipYName: `Offline Count${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchItemAvailability = async (metric, itemId) => {
	const { compare, showComparison } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ITEM_ACTION_DATE_HOUR", type: "hour" },
			{ label: "Days of the Week", value: "ITEM_ACTION_WEEK_DAY", type: "day" },
			{ label: "Dates", value: "ITEM_ACTION_DATE", type: "hour" },
			{ label: "Weeks", value: "ITEM_ACTION_MONTH_WEEK", type: "week" },
			{ label: "Months", value: "ITEM_ACTION_MONTH", type: "month" }
		];
		let { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		// remove Dates as an option as Avg & Compare will not work for this granularity; for: last 15 days, last 30 days
		if (["LAST_15_DAYS", "LAST_30_DAYS"].includes(durationObject?.duration?.preset)) {
			const validOptions = ["Hours", "Days of the Week"];
			filteredOptions = filteredOptions.filter((opt) => validOptions.includes(opt.label));
			currCompare = !validOptions.includes(currCompare?.label) ? filteredOptions[0] : currCompare;
		}

		const variables = {
			query: "catalogue_item_availability_heatmap",
			filters: [...filters, { field: "item", value: itemId }, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_AVAILABILITY_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(
			resp.data.getAvailabilityHeatmapChartData?.objects,
			showComparison,
			false
		);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_lost_orders",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersChart = async (metric, itemId) => {
	const {
		selectedChart = "bar",
		showComparison,
		graphData,
		yScaleMax = "auto",
		maxValue = "auto"
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "bar" ? "catalogue_lost_order_bar" : "catalogue_lost_orders_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_BAR_CHART_DATA : GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getBarChartData
						? getBarChartData(resp.data.getBarChartData?.objects, "grouped", true, showComparison).map(
								(bar) => {
									Object.keys(bar).forEach((key) => {
										if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
											chartMaxValue = Math.round(bar[key]);
										}
									});
									return bar;
								}
						  )
						: []
					: resp.data.getLineChartData
					? resp.data.getLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								resp.data.getLineChartData?.objects?.length > 1
									? `Lost Orders (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
									: "Lost Orders",
							tooltipYName: `Lost Orders${i !== 0 ? "*" : ""}`,
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  }))
					: []
		};

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "bar"
						? resp.data.getBarChartData.isLocationOverlapping
						: resp.data.getLineChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersTable = async (metric, itemId) => {
	const { sort } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_lost_orders_tabular",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersBreakdownChart = async (metric, itemId) => {
	const {
		selectedChart = "bar",
		showComparison,
		breakdownBy,
		graphData,
		yScaleMax = "auto",
		maxValue = "auto"
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const currentPath = window.location.pathname;
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const barChartBreakdownQueries = {
			platform: "catalogue_lost_orders_breakdown_stacked_bar",
			brand: "catalogue_lost_orders_brand_breakdown_stacked_bar"
		};
		const lineChartBreakdownQueries = {
			platform: "catalogue_lost_orders_breakdown_line",
			brand: "catalogue_lost_orders_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy || { label: "Platform", value: "platform" };

		const variables = {
			query:
				selectedChart === "bar"
					? barChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_STACKED_BAR_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getStackedBarChartData
						? getBarChartData(
								resp.data.getStackedBarChartData?.objects,
								showComparison && durationObject?.comparisonDuration ? "grouped-stacked" : "stacked",
								false,
								showComparison
						  ).map((bar) => {
								let tempMaxValue = 0;
								Object.keys(bar).forEach((key) => {
									if (
										(!showComparison || !durationObject?.comparisonDuration) &&
										typeof bar[key] === "number"
									) {
										// stacked bar chart
										tempMaxValue += Math.round(bar[key]);
									} else if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
										// grouped-stacked bar chart
										chartMaxValue = Math.round(bar[key]);
									}
								});
								if (tempMaxValue > 0 && tempMaxValue > chartMaxValue) {
									chartMaxValue = tempMaxValue;
								}
								return bar;
						  })
						: []
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								showComparison && durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								showComparison && durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping:
					selectedChart === "bar"
						? resp.data.getStackedBarChartData.isLocationOverlapping
						: resp.data.getGroupedLineChartData.isLocationOverlapping
			});
		}

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersBreakdownTable = async (metric, itemId) => {
	const { sort, breakdownBy } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "catalogue_lost_orders_breakdown_tabular",
			brand: "catalogue_lost_orders_brand_breakdown_tabular"
		};
		const variables = {
			query: breakdownBy ? tableBreakdownQueries[breakdownBy?.value] : tableBreakdownQueries.platform,
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// metric apis map
export const analyticsMetrics = {
	// revenue
	"revenue-by-location": fetchRevenueByLocation,
	"revenue-by-item": fetchRevenueByItem,

	// orders
	"orders-by-location": fetchOrdersByLocation,
	"orders-by-item": fetchOrdersByItem,

	// catalogue
	"category-performance": fetchCategoryPerformanceTable,
	"item-performance": fetchItemPerformanceTable,
	"location-performance": fetchCatalogueLocationPerformanceTable,
	"popular-add-ons": fetchPopularAddOnsTable
};

// update analytics state map
export const updateAnalyticsState = {
	revenue: updateRevenueAnalyticsState,
	orders: updateOrderAnalyticsState,
	catalogue: updateCatalogueAnalyticsState,
	detail: updateAnalyticsEntityDetailState
};

export const fetchItems = async (searchText, limit = 50, offset = 0, is_enabled = true) => {
	if (searchText !== undefined) {
		try {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE,
				payload: { metric: "itemList", data: { loading: true, error: false } }
			});
			const resp = await client.query({
				query: GET_ITEMS_LIST,
				variables: {
					limit,
					offset,
					sort: {
						field: "title",
						order: "ASC"
					},
					filters: [
						{
							field: "title",
							value: searchText
						},
						{
							field: "is_enabled",
							value: is_enabled
						}
					]
				},
				fetchPolicy: "cache-first"
			});
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE,
				payload: { metric: "itemList", data: { ...resp.data.items, loading: false, error: false } }
			});
		} catch (error) {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE,
				payload: { metric: "itemList", data: { loading: false, error: true } }
			});
			console.log(error);
		}
	}
};
export const fetchItemsDebounced = debounce((searchText) => fetchItems(searchText), 300);

export const fetchLocationList = async (searchText = "", limit = 50, offset = 0, storeUpdate = true) => {
	if (searchText !== undefined) {
		try {
			let type = ActionTypes.UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE;
			if (storeUpdate) {
				let payload = { metric: "locationList", data: { loading: true, error: false } };
				store.dispatch({
					type,
					payload
				});
			}
			const resp = await client.query({
				query: GET_LOCATIONS,
				variables: {
					limit: limit,
					offset: offset,
					filters: [{ field: "is_active", value: true }],
					sort: { field: "name", order: "ASC" },
					search: [{ key: "default", value: searchText }]
				},
				fetchPolicy: "cache-first"
			});
			if (storeUpdate) {
				let payload = { metric: "locationList", data: { ...resp.data.stores, loading: false, error: false } };
				store.dispatch({
					type: ActionTypes.UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE,
					payload
				});
			} else {
				return resp;
			}
		} catch (error) {
			console.log(error);

			let type = ActionTypes.UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE;
			let data = { metric: "locationList", data: { loading: false, error: true } };
			store.dispatch({
				type,
				payload: data
			});
		}
	}
};
export const fetchLocationsListDebounced = debounce((...args) => {
	fetchLocationList(...args);
});

export const fetchLocationGroups = async (limit = 50, offset = 0, searchText = "", fetchPolicy = "cache-first") => {
	try {
		const variables = {
			limit,
			offset,
			filters: [
				{
					field: "includes_all",
					value: false
				},
				{
					field: "use_in_data_filter",
					value: true
				}
			],
			sort: { field: "title", order: "ASC" }
		};
		if (searchText) {
			variables.filters.push({ field: "title", value: searchText });
		}
		const resp = await client.query({
			query: GET_LOCATION_GROUPS_LIST,
			variables,
			fetchPolicy
		});
		return resp;
	} catch (err) {
		console.log(err);
	}
};

export const fetchLocationGroupDetail = async (id, fetchPolicy = "cache-first") => {
	try {
		const variables = { id };
		const resp = await client.query({
			query: GET_LOCATION_GROUP,
			variables,
			fetchPolicy
		});
		return resp;
	} catch (error) {
		console.log(error);
	}
};

export const fetchLocationGroupInFormat = async (id, fetchPolicy = "cache-first") => {
	try {
		const variables = { id };
		const resp = await client.query({
			query: GET_LOCATION_GROUP,
			variables,
			fetchPolicy
		});
		return {
			value: resp.data.locationGroup.id,
			valueForDisplay: resp.data.locationGroup.title,
			field: "Location Groups"
		};
	} catch (error) {
		console.log(error);
	}
};

export const fetchStores = async (
	limit = 50,
	offset = 0,
	includeAll = false,
	searchText = "",
	fetchPolicy = "cache-first"
) => {
	try {
		const variables = {
			limit,
			offset,
			sort: {
				field: "name",
				order: "ASC"
			}
		};
		let filters = [
			{
				field: "is_active",
				value: true
			}
		];
		if (searchText) {
			filters.push({ field: "name", value: searchText });
		}
		variables.filters = filters;
		const resp = await client.query({
			query: GET_STORES_LIST,
			variables,
			fetchPolicy
		});
		return resp;
	} catch (err) {}
};

export const fetchNetRevenue = async (filters, metric, name) => {
	try {
		const currentPath = window.location.pathname;
		let loadingState = { loading: true, error: false };
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: loadingState, feature: "avgOrderValue" }
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: loadingState, feature: "avgRevenuePerDay" }
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: loadingState, feature: "totalRevenue" }
		});
		const { durationObject, bizId } = getAllAppliedFilters();

		let variables = {
			query: metric == "item" ? "catalogue_revenue_metrics" : "revenue_net_revenue_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const objects = resp.data.getMetricData.objects;
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
		if (objects.length > 0) {
			objects.forEach((val) => {
				let ans = { ...val, loading: false, error: false, value: Math.round(val.value) };
				if (val.selection == "ORDER_AVG_ORDER_VALUE" || val.selection == "ITEM_AVG_ORDER_VALUE") {
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: { metric, name, data: ans, feature: "avgOrderValue" }
					});
				}
				if (val.selection == "ORDER_AVG_REVENUE_PER_DAY" || val.selection == "ITEM_AVG_REVENUE_PER_DAY") {
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: { metric, name, data: ans, feature: "avgRevenuePerDay" }
					});
				}
				if (val.selection == "ORDER_REVENUE" || val.selection == "ITEM_REVENUE") {
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: {
							metric,
							name,
							data: { value: Math.round(val.value), loading: false, error: false },
							feature: "totalRevenue"
						}
					});
				}
			});
		}
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: { loading: false, error: false }, feature: "avgOrderValue" }
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: { loading: false, error: false }, feature: "avgRevenuePerDay" }
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: false },
				feature: "totalRevenue"
			}
		});
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: { loading: false, error: true }, feature: "totalRevenue" }
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: { loading: false, error: true }, feature: "avgOrderValue" }
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: { loading: false, error: true }, feature: "avgRevenuePerDay" }
		});
	}
};

export const fetchRevenueBreakdownCompare = async (filters, metrics, name) => {
	try {
		const currentPath = window.location.pathname;
		let data = { objectLoading: true, objectError: false };
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { data: data, metric: metrics, name, feature: "totalRevenue" }
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const pieChartBreakdownQueries = {
			platform: "revenue_breakdown_plt_pie",
			brand: "revenue_breakdown_plt_pie",
			item: "item_revenue_platform_breakdown_pie",
			location: "revenue_breakdown_plt_pie"
		};

		const variables = {
			query: pieChartBreakdownQueries[metrics],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			},
			sort: { field: "pie", order: "DESC" }
		};

		const resp = await client.query({
			query: GET_PIE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const objects = resp.data.getPieChartData.objects;
		const finalData = calculatePercentages(objects);
		data = { objectLoading: false, objectError: false, objects: finalData };
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { data: data, metric: metrics, name, feature: "totalRevenue" }
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getPieChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				data: {
					objectLoading: false,
					objectError: true
				},
				metric: metrics,
				name,
				feature: "totalRevenue"
			}
		});
		console.log(error);
	}
};

export const fetchLostRevenueCompare = async (filters, metric, name) => {
	try {
		const currentPath = window.location.pathname;
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: true, error: false },
				feature: "lostRevenue"
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_lost_revenue",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const objects = resp.data.getMetricData.objects;

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
		if (objects.length > 0) {
			objects.forEach((val) => {
				if (val.selection == "ORDER_LOST_REVENUE") {
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: {
							metric,
							name,
							data: { value: Math.round(val.value), loading: false, error: false },
							feature: "lostRevenue"
						}
					});
				}
			});
		} else {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: {
					metric,
					name,
					data: { value: 0, loading: false, error: false },
					feature: "lostRevenue"
				}
			});
		}
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "lostRevenue"
			}
		});
		console.log(error);
	}
};

export const fetchLostRevenueBreakdownCompare = async (filters, metric, name) => {
	store.dispatch({
		type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
		payload: {
			metric,
			name,
			data: { objectLoading: true, objectError: false },
			feature: "lostRevenue"
		}
	});
	if (metric == "item") {
		try {
			const currentPath = window.location.pathname;
			const { durationObject, bizId } = getAllAppliedFilters();
			const variables = {
				query: "item_lost_revenue_breakdown_pie",
				filters,
				requiredFilters: {
					bizId,
					...durationObject
				}
			};

			const resp = await client.query({
				query: GET_PIE_CHART_DATA,
				variables,
				fetchPolicy: "cache-first"
			});

			const objects = resp?.data?.getPieChartData?.objects || [];
			let finalOutput = [];
			if (objects.length > 0) {
				objects.forEach((obj) => {
					const value = parseFloat(obj.value);
					const name = obj.name;
					finalOutput.push({ name, value });
				});
				finalOutput = calculatePercentages(finalOutput);
			}
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: {
					metric,
					name,
					data: { objectLoading: false, objectError: false, objects: finalOutput },
					feature: "lostRevenue"
				}
			});
			if (currentPath === window.location.pathname) {
				updateAnalyticsFilterState({
					isLocationOverlapping: resp.data.getPieChartData.isLocationOverlapping
				});
			}
		} catch (err) {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: {
					metric,
					name,
					data: {
						objectLoading: false,
						objectError: true
					},
					feature: "lostRevenue"
				}
			});
		}
	} else {
		try {
			const { durationObject, bizId } = getAllAppliedFilters();
			let variables = {
				filters,
				query: "revenue_lost_revenue_plt_breakdown_tabular",
				sort: { field: "ORDER_LOST_REVENUE", order: "ASC" },
				requiredFilters: {
					bizId,
					...durationObject
				}
			};
			const resp = await client.query({
				query: GET_TABULAR_DATA,
				variables,
				fetchPolicy: "cache-first"
			});
			const objects = resp.data.getTabularData.objects.rows || [];

			let finalOutput = [];
			if (objects.length > 0) {
				objects.forEach((val) => {
					let name, value;
					val.forEach((obj) => {
						if (obj.key === "platform") {
							name = obj.value;
						}
						if (obj.key === "ORDER_LOST_REVENUE") {
							value = obj.value;
						}
					});
					finalOutput.push({ name, value });
				});
				finalOutput = calculatePercentages(finalOutput);
			}
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: {
					metric,
					name,
					data: { objectLoading: false, objectError: false, objects: finalOutput },
					feature: "lostRevenue"
				}
			});
		} catch (err) {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: {
					metric,
					name,
					data: {
						objectLoading: false,
						objectError: true
					},
					feature: "lostRevenue"
				}
			});
		}
	}
};

export const fetchOrdersReceivedMetricsCompare = async (filters, metric, name) => {
	try {
		const currentPath = window.location.pathname;
		const initialData = { loading: true, error: false };
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: initialData,
				feature: "avgOrdersPerDay"
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: initialData,
				feature: "totalOrders"
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: initialData,
				feature: "completedVsLost_Orders"
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: initialData,
				feature: "lostOrders"
			}
		});
		let query = "order_received_orders_metrics";

		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query,
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let objects;

		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
		if ((resp.data?.getMetricData?.objects.length || []) > 0) {
			objects = resp.data?.getMetricData?.objects;
			let receivedOrders;
			objects.forEach((val, index) => {
				if (val.selection == "ORDER_AVG_ORDERS_PER_DAY") {
					let ans = { loading: false, error: false, value: Math.floor(Number(val.value)) };
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: { metric, name, data: ans, feature: "avgOrdersPerDay" }
					});
				}
				if (val.selection == "ORDER_RECEIVED_ORDERS") {
					receivedOrders = roundAndFormatNumber(val.value);
					let ans = { loading: false, error: false, value: roundAndFormatNumber(val.value) };
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: { metric, name, data: ans, feature: "totalOrders" }
					});
				} else if (val.selection == "ORDER_LOST_ORDERS") {
					const finalValues =
						receivedOrders >= 0
							? [
									[
										{
											label: "completedVsLost_Orders",
											Completed: Math.round(receivedOrders - Math.round(val.value)),
											Lost: val.value
										}
									]
							  ]
							: [{}];

					const data = { loading: false, error: false, objects: finalValues };
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: {
							metric,
							name,
							data,
							feature: "completedVsLost_Orders"
						}
					});

					let lostOrdersAnswer = { loading: false, error: false, value: roundAndFormatNumber(val.value) };
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: {
							metric,
							name,
							data: lostOrdersAnswer,
							feature: "lostOrders"
						}
					});
				}
			});
		} else {
			let ans = { loading: false, error: false, value: 0 };
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: { metric, name, data: ans, feature: "avgOrdersPerDay" }
			});
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: { metric, name, data: ans, feature: "totalOrders" }
			});
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: { metric, name, data: ans, feature: "lostOrders" }
			});
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: {
					metric,
					name,
					data: { loading: false, error: true },
					feature: "completedVsLost_Orders"
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "avgOrdersPerDay"
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "totalOrders"
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "completedVsLost_Orders"
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "lostOrders"
			}
		});
	}
};

export const fetchLostOrdersBreakdownTableCompare = async (filters, metric, name) => {
	try {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { objectLoading: true, objectError: false },
				feature: "lostOrders"
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "order_lost_order_plt_breakdown_tabular",
			brand: "order_lost_order_brand_breakdown_tabular"
		};
		let variables = {
			query: tableBreakdownQueries["platform"],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const objects = resp.data.getTabularData.objects.rows;
		let finalOutput = [];
		if ((objects || []).length > 0) {
			objects.forEach((val) => {
				let name, value;
				val.forEach((obj) => {
					if (obj.key === "platform") {
						name = obj.value;
					}
					if (obj.key == "ORDER_LOST_ORDERS") {
						value = Math.round(obj.value);
					}
				});
				finalOutput.push({ name, value });
			});
			finalOutput = calculatePercentages(finalOutput);
		}
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { objectLoading: false, objectError: false, objects: finalOutput },
				feature: "lostOrders"
			}
		});
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { objectLoading: false, objectError: true },
				feature: "lostOrders"
			}
		});
		console.log(error);
	}
};

export const fetchOrdersBreakdownCompare = async (filters, metric, name) => {
	try {
		const currentPath = window.location.pathname;
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: true, error: false, objectLoading: true, objectError: false },
				feature: "totalOrders"
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const pieChartBreakdownQueries = {
			platform: "order_received_breakdown_plt_pie",
			brand: "order_received_breakdown_plt_pie",
			item: "item_order_received_breakdown_pie",
			location: "order_received_breakdown_plt_pie"
		};
		let variables = {
			query: pieChartBreakdownQueries?.[metric],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		variables.sort = {
			field: "pie",
			order: "DESC"
		};
		const resp = await client.query({
			query: GET_PIE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const objects = resp.data.getPieChartData.objects;
		let finalOutput = [];
		let totalCount = 0;
		if ((objects || []).length > 0) {
			objects.forEach((val) => {
				const name = val?.name;
				const value = val?.value;
				totalCount += Number(value);
				finalOutput.push({ name, value });
			});
			finalOutput = calculatePercentages(finalOutput);
		}
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: {
					loading: false,
					error: false,
					value: totalCount,
					objects: finalOutput,
					objectLoading: false,
					objectError: false
				},
				feature: "totalOrders"
			}
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getPieChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true, objectLoading: false, objectError: true },
				feature: "totalOrders"
			}
		});
		console.log(error);
	}
};

export const fetchItemPerformanceTableCompare = async (filters, metric, name, sort = false) => {
	let feature = "bestSellingItems";
	if (sort) {
		feature = "worstSellingItems";
	}
	try {
		const { durationObject, bizId } = getAllAppliedFilters();
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: true, error: false },
				feature
			}
		});
		let variables = {
			query: "catalogue_item_performance_tabular",
			filters,
			requiredFilters: {
				bizId,
				...durationObject,
				limit: 5,
				offset: 0
			}
		};
		if (sort) {
			variables.sort = { field: "ITEM_UNITS_SOLD", order: "ASC" };
		} else {
			variables.sort = { field: "ITEM_UNITS_SOLD", order: "DESC" };
		}
		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const objects = resp.data.getTabularData.objects.rows;
		let ans = [];
		if ((objects.length || []) > 0) {
			objects.forEach((val) => {
				let itemName, unitsSold;
				val.forEach((obj) => {
					if (obj.key === "name") {
						itemName = obj.value;
					}
					if (obj.key === "ITEM_UNITS_SOLD") {
						unitsSold = obj.value;
					}
				});
				ans.push({ name: itemName, value: unitsSold });
			});
		}
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: false, objects: ans },
				feature
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature
			}
		});
	}
};

export const fetchOrderCompletionTotalTimeCompare = async (filters, metric, name) => {
	try {
		const currentPath = window.location.pathname;
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: {
					loading: true,
					error: false
				},
				feature: "orderCompletionTime"
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_avg_completion_time",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
		const total = getMetricsData(resp.data.getMetricData?.objects)?.order_avg_completion_time?.value;
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: {
					loading: false,
					error: false,
					value: Math.round(total / 60)
				},
				feature: "orderCompletionTime"
			}
		});
	} catch (err) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "orderCompletionTime"
			}
		});
	}
};
export const fetchOrderCompletionTimeChartCompare = async (filters, metric, name) => {
	try {
		const currentPath = window.location.pathname;
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: {
					objectLoading: true,
					objectError: false
				},
				feature: "orderCompletionTime"
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_completion_bar",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const resp = await client.query({
			query: GET_BAR_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		let chartMaxValue = 0;
		const graphData = resp.data.getBarChartData
			? getBarChartData(resp.data.getBarChartData?.objects, "grouped")
					?.reverse()
					?.map((bar) => {
						let _bar = { ...bar, name: bar.label };
						Object.keys(bar).forEach((key) => {
							let val = typeof bar[key] === "number" ? bar[key] / 60 : bar[key];
							_bar[key === "value" ? "time" : key] = val;
							if (typeof bar[key] === "number" && key !== "compare" && val > chartMaxValue) {
								chartMaxValue = val;
							}
						});
						_bar.value = _bar.time;
						_bar.percent =
							_bar.time > 0 ? (((_bar.time - _bar.compare) * 100) / _bar.time).toFixed(1) : 0.0;
						return _bar;
					})
			: [];

		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: {
					objectLoading: false,
					objectError: false,
					objects: graphData
				},
				feature: "orderCompletionTime"
			}
		});
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getBarChartData.isLocationOverlapping
			});
		}
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: {
					objectLoading: false,
					objectError: true
				},
				feature: "orderCompletionTime"
			}
		});
		console.log(error);
	}
};

export const fetchLostOrdersTableCompare = async (filters, metric, name) => {
	try {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: true, error: false },
				feature: "lostRevenue"
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_lost_orders_tabular",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const objects = resp.data.getTabularData.objects.rows || [];

		let lostRevenue = 0;
		if (objects.length > 0) {
			objects.forEach((obj) => {
				obj.forEach((val) => {
					if (val.key == "lost_revenue") {
						lostRevenue += parseValue(val.value);
					}
				});
			});
		}
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				name,
				metric,
				data: { loading: false, error: false, value: lostRevenue },
				feature: "lostRevenue"
			}
		});
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "lostRevenue"
			}
		});
	}
};

export const fetchCatalogueLostOrdersBreakdownTableCompare = async (filters, metric, name) => {
	try {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				data: { objectLoading: true, objectError: false, loading: true, error: false },
				metric,
				name,
				feature: "lostOrders"
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_lost_orders_breakdown_tabular",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			},
			sort: { field: "ITEM_LOST_ORDERS", order: "DESC" }
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const objects = resp?.data?.getTabularData?.objects?.rows || [];
		let ans = [];
		let totalLostOrders = 0;
		if (objects.length > 0) {
			objects.forEach((obj) => {
				let name, value;
				obj.forEach((dataObj) => {
					if (dataObj?.["key"] === "platform") {
						name = dataObj?.["value"];
					}
					if (dataObj?.["key"] === "ITEM_LOST_ORDERS") {
						value = Number(dataObj?.["value"]);
						totalLostOrders += Number(dataObj?.["value"]);
					}
				});
				ans.push({ name, value });
			});
		}
		let finalData = calculatePercentages(ans);
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				data: {
					objectLoading: false,
					objectError: false,
					objects: finalData,
					loading: false,
					error: false,
					value: totalLostOrders
				},
				metric,
				name,
				feature: "lostOrders"
			}
		});
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				data: {
					objectLoading: false,
					objectError: true,
					loading: false,
					error: true
				},
				metric,
				name,
				feature: "lostOrders"
			}
		});
	}
};

export const fetchUnitsSoldMetricsCompare = async (filters, metric, name) => {
	try {
		const currentPath = window.location.pathname;
		let ans = { loading: true, error: false };
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: ans, feature: "avgOrdersPerDay" }
		});
		let data = { loading: true, error: false };
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data,
				feature: "completedVsLost_Orders"
			}
		});
		await fetchCatalogueLostOrdersBreakdownTableCompare(filters, metric, name);
		const ordersLostData = store.getState().compareAnalytics?.tableData?.[metric]?.[name]?.["lostOrders"];
		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_units_sold_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const objects = resp.data.getMetricData.objects || [];
		if (currentPath === window.location.pathname) {
			updateAnalyticsFilterState({
				isLocationOverlapping: resp.data.getMetricData.isLocationOverlapping
			});
		}
		if (objects.length > 0) {
			objects.map((obj) => {
				if (obj.selection == "ITEM_AVG_ORDERS_PER_DAY") {
					let ans = { loading: false, error: false, value: Math.floor(Number(obj.value)) };
					store.dispatch({
						type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
						payload: { metric, name, data: ans, feature: "avgOrdersPerDay" }
					});
				}
				if (obj.selection == "ITEM_ORDER_COMPLETED_ORDERS") {
					if (ordersLostData?.error) {
						store.dispatch({
							payload: {
								data: { loading: false, error: true },
								metric,
								name,
								feature: "completedVsLost_Orders"
							},
							type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA
						});
					} else {
						const finalValues = [
							[
								{
									label: "completedVsLost_Orders",
									Completed: obj.value,
									Lost: ordersLostData?.value
								}
							]
						];
						let data = { loading: false, error: false, objects: finalValues };
						store.dispatch({
							type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
							payload: {
								metric,
								name,
								data,
								feature: "completedVsLost_Orders"
							}
						});
					}
				}
			});
		} else {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: { metric, name, data: { loading: false, error: false, value: 0 }, feature: "avgOrdersPerDay" }
			});
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
				payload: {
					metric,
					name,
					data: {
						loading: false,
						error: false,
						objects: [
							[
								{
									label: "completedVsLost_Orders",
									Completed: 0,
									Lost: 0
								}
							]
						]
					},
					feature: "completedVsLost_Orders"
				}
			});
		}
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: { metric, name, data: { loading: false, error: true, value: 0 }, feature: "avgOrdersPerDay" }
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "completedVsLost_Orders"
			}
		});
		console.log(error);
	}
};

export const fetchCatalogueLocationPerformanceTableCompare = async (filters, metric, name, sort = false) => {
	let feature = "bestPerformingLocations";
	if (sort) {
		feature = "worstPerformingLocations";
	}
	try {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: true, error: false },
				feature
			}
		});
		const { durationObject, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_location_performance_tabular",
			filters,
			requiredFilters: {
				bizId,
				...durationObject,
				limit: 5,
				offset: 0
			}
		};

		if (sort) {
			variables.sort = { field: "ITEM_ORDER_COMPLETED_ORDERS", order: "ASC" };
		} else {
			variables.sort = { field: "ITEM_ORDER_COMPLETED_ORDERS", order: "DESC" };
		}

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const objects = resp.data.getTabularData?.objects?.rows || [];
		const finalOutput = [];
		if (objects.length > 0) {
			objects.map((obj) => {
				let name = "";
				let value = "";
				obj.map((val) => {
					if (val.key == "name") {
						name = val.value;
					} else if (val.key == "ITEM_ORDER_COMPLETED_ORDERS") {
						value = roundAndFormatNumber(val.value, 0);
					}
				});
				finalOutput.push({ name, value });
			});
		}
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: false, objects: finalOutput },
				feature
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature
			}
		});
	}
};

export const fetchLocationDetail = async (id, brandId = null) => {
	try {
		const variables = { id };
		const resp = await client.query({
			query: GET_LOCATION,
			variables,
			fetchPolicy: "cache-first"
		});
		return { value: resp.data.store.id, valueForDisplay: resp.data.store.name };
	} catch (error) {
		console.log(error);
	}
};

export const fetchItem = async (id) => {
	try {
		const variables = { id };
		const resp = await client.query({
			query: GET_ITEM,
			variables,
			fetchPolicy: "cache-first"
		});
		return { value: resp.data.item.id, valueForDisplay: resp.data.item.itemTitle };
	} catch (err) {
		console.log(err);
	}
};

export const fetchBizPlatforms = async (includeUrbanpiper = false, showMeraki = false, includeAll = false) => {
	try {
		store.dispatch({
			type: "GET_BIZ_PLATFORMS_LIST_REQUEST"
		});
		const variables = {
			limit: 50,
			offset: 0,
			includeUrbanpiper: includeUrbanpiper,
			filters: [
				{
					field: "is_enabled",
					value: "true"
				}
			]
		};
		const resp = await client.query({
			query: GET_BIZ_PLATFORMS,
			variables,
			fetchPolicy: "cache-first"
		});
		let bizPlatforms = resp.data?.bizPlatforms?.objects || [];
		let present = false;
		bizPlatforms.forEach((plt) => {
			if (plt.id === "prime") {
				present = true;
			}
		});
		if (!present) {
			bizPlatforms.push({
				id: "prime",
				platformName: "Prime",
				image: CATALOGUE_PLATFORMS_LOGO["prime"]
			});
		}
		store.dispatch({
			type: "GET_BIZ_PLATFORMS_LIST_SUCCESS",
			payload: bizPlatforms.map((plf) => {
				if (showMeraki && plf.platformName.toLowerCase() === "urbanpiper") {
					plf.platformName = "Meraki";
				}
				plf.image =
					plf?.logo ||
					CATALOGUE_PLATFORMS_LOGO[plf?.platformName?.toLowerCase()] ||
					"/assets/icons/icons8-globe-40.png";
				return plf;
			})
		});
		return bizPlatforms;
	} catch (error) {
		store.dispatch({
			type: "GET_BIZ_PLATFORMS_LIST_FAILURE"
		});
		console.log(error);
	}
};

export const fetchBrands = async (searchText = "", includeAll = false, limit = 50) => {
	if (searchText !== undefined) {
		store.dispatch({
			type: "GET_BRAND_LOCATIONS_LIST_REQUEST"
		});
		try {
			const variables = {
				limit: limit
			};
			let filters = [
				{
					field: "is_active",
					value: true
				}
			];
			variables.filters = filters;
			if (searchText) {
				variables.search = [{ key: "default", value: searchText }];
			}
			const resp = await client.query({
				query: GET_BRANDS_LIST,
				variables,
				fetchPolicy: "cache-first"
			});
			let brands =
				resp?.data?.brands?.objects?.length > 0
					? resp.data.brands.objects.map((brand) => ({
							...brand,
							color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
					  }))
					: [];
			if (includeAll) {
				brands = [{ id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }, ...brands];
			}
			store.dispatch({
				type: "GET_BRAND_LOCATIONS_LIST_SUCCESS",
				payload: brands || []
			});
			return brands;
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_BRAND_LOCATIONS_LIST_FAILURE"
			});
		}
	}
};

export const fetchRestaurantAvailabilityCompare = async (filters, metric, name) => {
	try {
		const { durationObject, bizId } = getAllAppliedFilters();
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: true, error: false },
				feature: "locationAvailability"
			}
		});

		let currCompare = { label: "Days of the Week", value: "LOCATION_ACTION_WEEK_DAY", type: "day" };
		const variables = {
			query: "operation_location_availability_heatmap",
			filters: [...filters, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const resp = await client.query({
			query: GET_AVAILABILITY_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		let totalSum = 0;
		let totalLength = 0;
		resp.data.getAvailabilityHeatmapChartData.objects.map((val) => {
			let dataValSum = 0;
			val.data.map((dataVal) => {
				dataValSum += 100 - parseFloat(dataVal?.value);
			});
			totalSum += dataValSum;
			totalLength += val.data.length;
		});
		let value = Math.round(totalSum / totalLength);
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: false, value },
				feature: "locationAvailability"
			}
		});
	} catch (err) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "locationAvailability"
			}
		});
		console.log(err);
	}
};

export const fetchItemAvailabilityCompare = async (filters, metric, name) => {
	try {
		const { durationObject, bizId } = getAllAppliedFilters();
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: true, error: false },
				feature: "itemAvailability"
			}
		});

		let currCompare = { label: "Days of the Week", value: "LOCATION_ACTION_WEEK_DAY", type: "day" };
		const variables = {
			query: "catalogue_item_availability_heatmap",
			filters: [...filters, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_AVAILABILITY_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		let totalSum = 0;
		let totalLength = 0;
		resp.data.getAvailabilityHeatmapChartData.objects.map((val) => {
			let dataValSum = 0;
			val.data.map((dataVal) => {
				dataValSum += 100 - parseFloat(dataVal?.value);
			});
			totalSum += dataValSum;
			totalLength += val.data.length;
		});

		let value = Math.round(totalSum / totalLength);
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: false, value },
				feature: "itemAvailability"
			}
		});
	} catch (err) {
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_TABLE_DATA,
			payload: {
				metric,
				name,
				data: { loading: false, error: true },
				feature: "itemAvailability"
			}
		});
		console.log(err);
	}
};

export const saveComparison = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: SAVE_COMPARISON,
			variables: { input: variables }
		});
		return resp;
	} catch (error) {
		console.log(error);
		return;
	}
};

export const fetchSavedComparisons = async (variables, useCache = false) => {
	try {
		store.dispatch({
			type: ActionTypes.UPDATE_SAVED_COMPARISONS,
			payload: {
				data: {
					loading: true,
					error: false
				},
				view: variables.view
			}
		});

		const resp = await client.query({
			query: GET_COMPARISONS,
			variables: variables,
			fetchPolicy: useCache ? "cache-first" : "no-cache"
		});
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_CHANGE_STATE,
			payload: {
				savedCount: resp.data.getComparisons?.savedCount,
				recentlyViewedCount: resp.data.getComparisons?.recentlyViewedCount
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_SAVED_COMPARISONS,
			payload: {
				view: variables.view,
				data: {
					comparisons: resp?.data?.getComparisons?.objects,
					totalCount: resp?.data?.getComparisons?.totalCount,
					loading: false,
					error: false
				}
			}
		});
		return resp;
	} catch (error) {
		store.dispatch({
			type: ActionTypes.UPDATE_SAVED_COMPARISONS,
			payload: {
				view: variables.view,
				data: {
					loading: false,
					error: true,
					comparisons: []
				}
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
		console.log(error);
	}
};

export const fetchDebouncedSavedComparisons = debounce((...args) => {
	return fetchSavedComparisons(...args);
}, 300);

export const fetchSavedComparison = async (variables) => {
	try {
		const resp = await client.query({
			query: GET_COMPARISON,
			variables: variables,
			fetchPolicy: "no-cache"
		});
		return resp;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateComparison = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: UPDATE_COMPARISON,
			variables,
			fetchPolicy: "no-cache"
		});
		return resp;
	} catch (err) {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: err.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: err
			}
		});
		console.log(err);
	}
};

export const deleteComparison = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: DELETE_COMAPARISON,
			fetchPolicy: "no-cache",
			variables
		});
		return resp;
	} catch (err) {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: err.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: err
			}
		});
		console.log(err);
	}
};

export const updateLastViewed = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: UPDATE_LAST_VIEWED,
			variables: variables
		});
		return resp;
	} catch (err) {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: err.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: err
			}
		});
		console.log(err);
	}
};

export const fetchCompareUserStatus = async (variables) => {
	try {
		const resp = await client.query({
			query: GET_USER_COMPARE_VISIT,
			variables: variables,
			fetchPolicy: "no-cache"
		});
		const compare = lS.get("compare");
		if (resp.data.getUserCompareVisit) {
			const respData = {
				dismissed: resp.data.getUserCompareVisit?.dismissed,
				hasViewedCompare: resp.data.getUserCompareVisit?.hasViewedCompare
			};
			lS.set("compare", { ...compare, getUserCompareVisit: respData });
		} else {
			lS.set("compare", { ...compare, getUserCompareVisit: null });
		}
		return resp.data.getUserCompareVisit?.hasViewedCompare;
	} catch (err) {
		console.log(err);
	}
};

export const updateUserVisitOnCompare = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: UPDATE_USER_COMPARE_VISIT_MUTATION,
			variables: { input: variables }
		});
		fetchCompareUserStatus({ bizId: variables.bizId });
	} catch (err) {
		console.log(err);
	}
};
