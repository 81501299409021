import React from "react";

// third party
import { connect } from "react-redux";

// components
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// utils
import { capitaliseTextStrict } from "../../atlas-utils";

const ReportFilters = ({
	options = [],
	filtersToDisplay = [],
	setFilter,
	currentFilters,
	optionUpdates = {},
	handleCheck,
	isCheckedAll = {},
	handleCheckAll,
	showFields = false,
	isNonHqReport,
	isMultibrandEnabled = false,
	validations = {}
}) => {
	return (
		<React.Fragment>
			{options
				.filter((opt) => (isMultibrandEnabled ? opt.field !== "stores" : opt) && !opt.hide)
				.map((opt, i) => {
					if (opt.type === "MULTIPLE" && filtersToDisplay.includes(opt.field)) {
						return (
							<SelectFilterCustom
								key={i}
								title={opt.valueForDisplay}
								requiredLabel={opt.required}
								options={opt.values}
								field={opt.field.replace(/([-_]\w)/g, (f) => f[1].toUpperCase())}
								currValue={currentFilters[opt.field] || ""}
								setFilter={setFilter}
								labelKey={opt.labelKey || "valueForDisplay"}
								valueKey={opt.valueKey || "value"}
								multi={true}
								showCheckBox={true}
								showSelectAllOption={opt.field === "stores" ? !isNonHqReport : true}
								optionUpdates={optionUpdates}
								handleCheck={handleCheck}
								isCheckedAll={isCheckedAll}
								handleCheckAll={handleCheckAll}
								countDisplayText={
									showFields && capitaliseTextStrict(opt.field?.slice(0, -1)?.replace("_", " "), true)
								}
								ellipsizedLength={40}
								placeholder={`Select ${opt.valueForDisplay}`}
								validationMessage={
									validations?.[opt.field.replace(/([-_]\w)/g, (f) => f[1].toUpperCase())] || ""
								}
							/>
						);
					} else if (opt.type === "SINGLE_STRICT" && filtersToDisplay.includes(opt.field)) {
						return (
							<SelectFilter
								key={i}
								title={opt.valueForDisplay}
								options={opt.values}
								field={opt.field.replace(/([-_]\w)/g, (f) => f[1].toUpperCase())}
								currValue={currentFilters[opt.field] || ""}
								setFilter={setFilter}
								labelKey={opt.labelKey || "valueForDisplay"}
								valueKey={opt.valueKey || "value"}
								isClearable={false}
								placeholder={`Select ${opt.valueForDisplay}`}
								validationMessage={
									validations?.[opt.field.replace(/([-_]\w)/g, (f) => f[1].toUpperCase())] || ""
								}
							/>
						);
					}
				})}
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	isNonHqReport: store?.login?.loginDetail?.access?.isNonHqReport
});
export default connect(mapStateToProps)(ReportFilters);
