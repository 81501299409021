import React, { useState, useEffect, useCallback, useImperativeHandle } from "react";

// components
import { Paginator } from "../_commons/Paginator";
import HistoryDetail from "./HistoryDetail";

// third party
import { useTrail, config, animated } from "react-spring";
import moment from "moment";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_WEBHOOK_HISTORY } from "../../graphql/webhooks";

// actions
import { ActionTypes } from "../../actions/_types";

// config
import { WEBHOOK_HISTORY_LIST_HEADER_FIELDS } from "../../client-config";

// constant
const FILTER_INITIAL_STATE = {
	created: ""
};

const History = ({ webhookId, webhookUrl, setModalBusy, nestedRef, readOnly = true }) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [historyList, setHistoryList] = useState({
		objects: [],
		count: 0
	});
	const [loading, setLoading] = useState(false);
	const [historyDetailView, setHistoryDetailView] = useState(false);
	const [selectedHistoryDetail, setSelectedHistoryDetail] = useState({});

	const fetchWebhookHistory = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(webhookId),
				limit,
				offset,
				sort: {
					field: "last_executed",
					order: "DESC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_WEBHOOK_HISTORY,
				variables,
				fetchPolicy: "no-cache"
			});
			setHistoryList(resp.data.webhook.deliveries);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [webhookId, limit, offset, appliedFilters]);

	useEffect(() => {
		fetchWebhookHistory();
	}, [fetchWebhookHistory]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openHistoryDetailView = (id) => {
		setSelectedHistoryDetail(historyList.objects.find((h) => h.id === id));
		setHistoryDetailView(true);
		setModalBusy(true);
	};

	const closeHistoryDetailView = (refresh = false) => {
		setHistoryDetailView(false);
		setModalBusy(false);
		setSelectedHistoryDetail({});
		if (refresh) {
			fetchWebhookHistory();
		}
	};

	useImperativeHandle(
		nestedRef,
		() => ({
			closeHistoryDetailView
		}),
		[closeHistoryDetailView]
	);

	return (
		<div className={"history-container " + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">History</div>
					<div className="header-subtext">Deliveries to this webhook in the past 24 hours</div>
				</div>
			</div>
			<Table data={historyList.objects} loading={loading} handleHistoryDetailView={openHistoryDetailView} />
			<Paginator limit={limit} offset={offset} count={historyList.count || 0} goToPage={handlePagination} />
			<HistoryDetail
				isOpen={historyDetailView}
				close={closeHistoryDetailView}
				data={selectedHistoryDetail}
				webhookUrl={webhookUrl}
			/>
		</div>
	);
};
export default History;

export const Table = ({ data, loading, sortList, sortedField, handleHistoryDetailView }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container webhook-history-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={WEBHOOK_HISTORY_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleHistoryDetailView={handleHistoryDetailView}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No History found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({ id, lastExecuted, retriesCount, statusCode, style, handleHistoryDetailView }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text delivery-id" title={id}>
				<a role="button" className="link-text" onClick={() => handleHistoryDetailView(id)}>
					{id}
				</a>
			</div>
			<div className="at-table-cell at-cell-text last-delivery">
				{lastExecuted ? moment(lastExecuted).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
			<div className="at-table-cell at-cell-text retries-count">{retriesCount || 0}</div>
			<div className={"at-table-cell at-cell-text status-code " + (statusCode === 200 ? "success" : "error")}>
				<span>{statusCode}</span>
			</div>
		</animated.div>
	);
};
