import React from "react";

const SpecificLocationPlatformCell = ({ props, configItems }) => {
	const total = props?.value?.total;
	const available = props?.value?.available;
	let displayImage;
	if (total == available && total !== 0 && available !== 0) {
		displayImage = (
			<>
				<span className="specific-item-online-img">
					<img src="/assets/periscope/online.svg" style={{ width: "11.61px", height: "11.61px" }} />
				</span>
				<span className="specific-item-online-green-img">
					<img src="/assets/periscope/online-green.svg" style={{ width: "11.61px", height: "11.61px" }} />
				</span>
			</>
		);
	} else if (total == 0) {
		displayImage = <img src="/assets/periscope/partiallyOffline.svg" style={{ width: "5px", height: "22px" }} />;
	} else {
		displayImage = <img src="/assets/periscope/offline.svg" style={{ width: "11.61px", height: "11.61px" }} />;
	}
	return (
		<div
			style={{ display: "flex", justifyContent: "end", padding: "0px 16px" }}
			className="specificLocation-platform-img-div"
		>
			{displayImage}
		</div>
	);
};
export default SpecificLocationPlatformCell;
