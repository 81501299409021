import React, { useEffect, useState } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { SelectFilter } from "../_commons/SelectFilter";

const MenuPreviewConfigurationSelectionDrawer = ({
	isOpen = true,
	locationsData,
	platformsData,
	handleMenuPreviewLocationSelection,
	handleMenuPreviewPlatformSelection,
	closePreviewDrawer,
	selectedLocation,
	selectedPlatform,
	handleMenuPreview
}) => {
	return (
		<div className="menu-preview-configuration-selection">
			<FormSidebar
				isOpen={isOpen}
				overlaySidebarClasses="medium"
				title="Preview Menu"
				subTitle="Select menu location & platform"
				submitTitle="Show Preview"
				close={closePreviewDrawer}
				disabled={!selectedLocation || !selectedPlatform}
				submitButtonWidth={150}
				submit={handleMenuPreview}
			>
				<SelectFilter
					title="Select Location"
					placeholder="Locations"
					options={locationsData?.objects}
					currValue={selectedLocation}
					labelKey="name"
					valueKey="id"
					setFilter={handleMenuPreviewLocationSelection}
					isClearable={false}
				/>
				<SelectFilter
					title="Select Platform"
					placeholder="Platforms"
					options={platformsData?.objects}
					currValue={selectedPlatform}
					labelKey="platformName"
					valueKey="id"
					setFilter={handleMenuPreviewPlatformSelection}
					isClearable={false}
				/>
			</FormSidebar>
		</div>
	);
};
export default MenuPreviewConfigurationSelectionDrawer;
