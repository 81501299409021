import React, { useState, useCallback, useEffect } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// actions
import { fetchTags, fetchCities } from "../../actions/actions";

const DEFAULT_CONFIG = {
	name: {
		field: "name",
		placeholder: "Enter Name"
	},
	city: {
		field: "city"
	},
	tags: {
		field: "tags"
	}
};
const FILTER_INITIAL_STATE = {
	name: "",
	city: undefined,
	tags: undefined
};

const FormFilters = ({
	enableName = false,
	nameConfig = DEFAULT_CONFIG.name,
	enableCity = false,
	cityConfig = DEFAULT_CONFIG.city,
	enableTags = false,
	tagsConfig = DEFAULT_CONFIG.tags,
	configItems,
	apply,
	ref
}) => {
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);

	useEffect(() => {
		fetchCities();
		fetchTags("");
	}, []);

	const setFilter = (field, value) => {
		const newFilters = {
			...currFilters,
			[field]: value
		};
		setCurrFilters(newFilters);
		applyFilter(newFilters);
	};

	const applyFilter = useCallback(
		debounce((filters) => apply(filters), 500),
		[apply]
	);

	return (
		<div className="form-filters-container" ref={ref}>
			<div className="section-1">
				{enableCity && (
					<SelectFilter
						// title="City"
						options={configItems.cities.items}
						isLoading={configItems.cities.isLoading}
						field={cityConfig.field}
						currValue={currFilters[cityConfig.field] || undefined}
						setFilter={setFilter}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="City"
					/>
				)}
				{enableTags && (
					<SelectFilter
						// title="Tags"
						options={configItems.tags.items}
						isLoading={configItems.tags.isLoading}
						field={tagsConfig.field}
						currValue={currFilters[tagsConfig.field] || undefined}
						setFilter={setFilter}
						labelKey="name"
						valueKey="id"
						placeholder="Tags"
					/>
				)}
			</div>
			<div className="section-2">
				{enableName && (
					<InputWithLabel
						value={currFilters[nameConfig.field] || ""}
						onChange={(e) => setFilter(nameConfig.field, e.target.value)}
						placeholder={"Search"}
					></InputWithLabel>
				)}
			</div>
		</div>
	);
};
export default connect((store) => ({
	configItems: store.configItems
}))(FormFilters);
