import React, { useState, useCallback, useEffect } from "react";

// component
import { Switch } from "../_commons/Switch";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

const Logistics = ({ platform, platformId, locationId, brandId = null }) => {
	const [data, setData] = useState(undefined);
	const [actionResponse, setActionResponse] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const fetchData = useCallback(async () => {
		setLoading(true);
		try {
			setActionResponse(null);
			setData(null);

			const token = store.getState().login.loginDetail.token;
			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`
			};
			let url = process.env.REACT_APP_API_URL_LOGISTICS + `?location_id=${locationId}&platform_id=${platformId}`;
			if (brandId) {
				url = `${url}&brand_id=${brandId}`;
			}
			const res = await fetch(url, {
				method: "GET",
				headers: headers
			});
			if (res.status === 200) {
				const data = await res.json();
				setData(data.data);
			} else if (res.status === 503) {
				setActionResponse({
					status: false,
					message: "Request Failed, Not Authorized"
				});
			} else {
				const msg = await res.json();
				setActionResponse({
					status: false,
					message: msg.message
				});
			}
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	});

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div>
			{loading && (
				<div className="Pstart(25px) Pend(25px) Mb(25px)">
					<div className="shimmer H(100px)" />
				</div>
			)}
			{data && (
				<React.Fragment>
					<div className="form-row row-half">
						<div className="logis-text-cnt">
							{platform.platformName} Service Availability
							<img
								className="logis-service-img"
								src={
									data.aggregator_serviceability_status
										? "/assets/icons/icon-check.svg"
										: "/assets/icons/icon-cross.svg"
								}
							/>
						</div>
						<Switch
							title={`${platform.platformName} Delivery Enabled`}
							checked={data.aggregator_delivery_enabled}
							readOnly={true}
						/>
					</div>
					<div className="form-row row-half">
						<div className="logis-text-cnt">
							Self Delivery Service Availability
							<img
								className="logis-service-img"
								src={
									data.self_serviceability_status
										? "/assets/icons/icon-check.svg"
										: "/assets/icons/icon-cross.svg"
								}
							/>
						</div>
						<Switch title="Self Delivery Enabled" checked={data.self_delivery_enabled} readOnly={true} />
					</div>
				</React.Fragment>
			)}
			{actionResponse && (
				<div className="logistics-action-message">
					<div onClick={() => setActionResponse(null)} className="close">
						&times;
					</div>
					<div className={"logistics-response " + (actionResponse.status ? "success" : "fail")}>
						{actionResponse.message}
					</div>
				</div>
			)}
		</div>
	);
};
export default Logistics;
