import React from "react";

const PlatformCell = ({ props }) => {
	const totalLocations = props.value.total;
	const onlineLocations = props.value.available;
	if (onlineLocations == 0 && totalLocations > 0) {
		return (
			<div style={{ display: "flex", alignItems: "center", justifyContent: "right", height: "17px" }}>
				<img className="item-platform-offline" src="/assets/periscope/offline.svg" />
			</div>
		);
	} else if (totalLocations == onlineLocations && totalLocations !== 0 && onlineLocations !== 0) {
		return (
			<div
				className="hover-online-images"
				style={{ display: "flex", alignItems: "center", justifyContent: "right" }}
			>
				<img src="/assets/periscope/online.svg" className="correct-black-img" />
				<img
					src="/assets/periscope/online-green.svg"
					className="correct-green-img"
					style={{ transform: "translate(0.5px , 0)" }}
				/>
			</div>
		);
	} else if (onlineLocations == 0 && totalLocations == 0) {
		return (
			<div style={{ display: "flex", alignItems: "center", justifyContent: "right", height: "17px" }}>
				<img src="/assets/periscope/partiallyOffline.svg" />
			</div>
		);
	} else {
		let onlineLocationPercentage = ((props.value.available / props.value.total) * 100)
			.toFixed(2)
			.replace(/\.00$/, "");
		return (
			<>
				<span className="item-percent-display">{onlineLocationPercentage}%</span>
			</>
		);
	}
};

export default PlatformCell;
