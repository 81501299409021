import React, { useEffect } from "react";
import { ButtonIcon } from "../_commons/ButtonIcon";
import LightningIcon from "../_icons/LightningIcon";
import Lottie from "lottie-react";
import success from "../../lottie/success-icon.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "../_commons/Button";
import { scroll } from "../../atlas-utils";
import MailIcon from "../_icons/MailIcon";
import CalendarTime from "../_icons/CalendarTime";

const ScheduleFreePlanStarted = () => {
	const history = useHistory();
	const handleSelect = () => {
		history.push("/reports/list/schedules/free-trial-plan/new", {
			state: { pushTo: "/reports/list/schedules/free-trial-plan" }
		});
	};
	useEffect(() => {
		scroll();
	}, []);

	return (
		<div className="schedule-report-intro">
			<div className="free-trail-plan-wrap">
				<div className="free-trial-intro">
					<div className="heading">
						Free trial started
						<Lottie
							animationData={success}
							style={{
								width: "20px"
							}}
							loop={false}
						/>
					</div>
					<div className="modal-plan">
						<div className="free-trail">
							<LightningIcon fillColor="#6D7AA8" />{" "}
							<div>
								<span className="your-plan">Your plan</span> (Free Trial)
							</div>
						</div>

						<div className="all-plans">
							<div className="plan-benefits">YOUR PLAN BENEFITS</div>

							<div className="plans-info">
								<div className="plans">
									<div className="svg">
										<CalendarTime styles={{ height: "20px", width: "20px" }} color={"#6D7AA8"} />
									</div>

									<div className="text">
										Create schedule <span className="text-bold">Unlimited</span>
									</div>
								</div>
								<div className="plans">
									<div className="svg">
										<MailIcon variations={{ width: "18px", height: "14px" }} />
									</div>
									<div className="text">
										Email notification recipient <span className="text-bold">Multiple</span>
									</div>
								</div>
								<div className="plans">
									<ButtonIcon classes="svg" icon="resume" color="#6D7AA8" />
									<div className="text">
										Pause / Resume schedules at <span className="text-bold">No Cost </span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="btns">
						<Button onClick={handleSelect}>Schedule your first report</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ScheduleFreePlanStarted;
