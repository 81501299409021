import React, { useEffect, useState } from "react";
import ExclamatoryIcon from "../../_icons/ExclamatoryIcon";
import { debounce, throttle } from "lodash";

const Indicator = ({
	icon = "",
	header = "",
	description = "",
	handleClose = () => {},
	suggestion,
	combineHeaderAndDesc = false,
	showCloseBtn = false,
	hideHeadText = false,
	styles = {},
	headerVisibilityToggle = true,
	clickHandler = () => {}
}) => {
	const handleCloseFn = () => {
		handleClose();
	};
	const [showHeaderText, setShowHeaderText] = useState(true);
	let timeoutId = null;

	const handlePageScroll = () => {
		if (hideHeadText) {
			if (document.documentElement.scrollTop >= 50) {
				setShowHeaderText(false);
				if (timeoutId) {
					clearInterval(timeoutId);
				}
			} else if (document.documentElement.scrollTop < 50) {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				timeoutId = setTimeout(() => {
					setShowHeaderText(true);
				}, 5000);
			}
		}
	};
	useEffect(() => {
		window.addEventListener("scroll", handlePageScroll);
		return () => {
			window.removeEventListener("scroll", handlePageScroll);
		};
	}, []);

	return (
		<div className={"overlapping-groups "} style={styles}>
			<div className="info-main-div">
				{icon}
				<div
					className={`overlapping-info ${
						!(showHeaderText && headerVisibilityToggle) ? "hide-header-text" : ""
					}`}
				>
					<div className="overlap-content">
						{showHeaderText && headerVisibilityToggle && <span className="overlap-header">{header}</span>}
						<span className="overlap-details">
							{combineHeaderAndDesc &&
								(!(showHeaderText && headerVisibilityToggle)
									? header + " " + description
									: description)}
							{!combineHeaderAndDesc && description}
						</span>
					</div>
					{suggestion && (
						<div className="overlap-contact" onClick={clickHandler}>
							<span className="contact-info">{suggestion}</span>
						</div>
					)}
				</div>
			</div>
			{showCloseBtn && (
				<div className="close-btn" onClick={() => handleCloseFn()}>
					<img className="img" src="/assets/periscope/close-refresh-failed.svg" />
				</div>
			)}
		</div>
	);
};

export default Indicator;
