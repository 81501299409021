import React, { useState, useEffect, useReducer } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { JsonInput } from "../components/_commons/JsonInput";

// third party
import history from "../history";
import moment from "moment";

// client
import { store } from "../store/configureStore";

// utils
import { trackEvent, capitaliseTextStrict } from "../atlas-utils";

// reducers
import { auditEventDetailsReducer, AUDIT_EVENT_DETAILS_INITIAL_STATE } from "../reducers/auditEvents";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchAuditEventDetail } from "../actions/auditEvents";

// assets
import CopyIcon from "../components/_commons/CopyIcon";

// constants
import { EVENT_SOURCE_DESTINATION_MAP } from "./AuditEventsList";

const AuditEventsPreview = ({ match, isNested = false }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [auditEventDetails, dispatch] = useReducer(auditEventDetailsReducer, AUDIT_EVENT_DETAILS_INITIAL_STATE);
	const { loading, data } = auditEventDetails;

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchAuditEventDetail(match.params.id, dispatch);
	}, [match.params.id]);

	const handleCancel = () => {
		// update event state to "Last viewed"
		store.dispatch({
			type: ActionTypes.AUDIT_EVENTS_LIST_SEARCH,
			payload: { currEvent: { id: match.params.id, state: "Last viewed" } }
		});
		setFormState(false);
		setTimeout(() => {
			history.push("/activity-history");
		}, 100);
	};

	const copyTextToClipboard = (e, text, item) => {
		e.stopPropagation();
		navigator.clipboard.writeText(text);
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: `Copied to clipboard`,
				timeout: 2000,
				error: false
			}
		});
		// track event
		if (item) {
			const eventMeta = {
				item,
				from: "Detail"
			};
			trackEvent("event_trail_copy_item", eventMeta);
		}
	};

	const handleJsonCopy = (item) => {
		// track event
		if (item) {
			const eventMeta = {
				item: capitaliseTextStrict(item.replace(/[._-]/g, " "), true),
				from: "Detail"
			};
			trackEvent("event_trail_copy_item", eventMeta);
		}
	};

	const handleParseJson = (data, key) => {
		let parsedData;
		try {
			parsedData = JSON.parse(data);
		} catch {
			parsedData = {};
		}
		return parsedData;
	};

	const renderTitle = (event) => {
		if (/^webhook/g.test(String(event?.toLowerCase()))) {
			event = event?.toLowerCase()?.replace("webhook", "webhook - ");
		} else if (/^callback/g.test(String(event?.toLowerCase()))) {
			event = event?.toLowerCase()?.replace("callback", "callback - ");
		}
		return capitaliseTextStrict(event, true);
	};

	useEffect(() => {
		if (data?.data?.purpose || data?.data?.additional_info?.purpose || data?.event) {
			const purpose = renderTitle(
				(data?.data?.purpose || data?.data?.additional_info?.purpose || data?.event)?.replace(/[.\/_-]/g, " ")
			);
			if (purpose) {
				trackEvent("event_trail_event_preview", {
					purpose
				});
			}
		}
	}, [data?.data]);

	return (
		<div className="audit-events-preview-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title={renderTitle(
					(data?.data?.purpose || data?.data?.additional_info?.purpose || data?.event)?.replace(
						/[.\/_-]/g,
						" "
					) || "Activity"
				)}
				subTitle={
					data?.timestamp ? (
						<React.Fragment>
							{"View the details of the activity occurred on "}
							<span>
								{data.timestamp ? moment(parseInt(data.timestamp)).format("DD MMM, YYYY") : "--"}
							</span>
							{" at "}
							<span>{data.timestamp ? moment(parseInt(data.timestamp)).format("hh:mm:ss A") : "--"}</span>
						</React.Fragment>
					) : (
						"View the details of the event here"
					)
				}
				loading={loading}
				hideActions={true}
				isNested={isNested}
			>
				<div className="form-content">
					<div className="event-table-container">
						<div className="table-header">
							<div className="table-row">
								<div className="key">Key</div>
								<div className="value">Value</div>
							</div>
						</div>
						<div className="table-body">
							{data?.traceId && (
								<div className="table-row row_trace_id">
									<div className="key">Trace ID</div>
									<div className="value trace-id">
										<span>{data?.traceId}</span>
										<span
											title="Copy Trace ID"
											className="at-copy-icon"
											onClick={(e) => copyTextToClipboard(e, data?.traceId, "Trace ID")}
										>
											<CopyIcon />
										</span>
									</div>
								</div>
							)}
							{data?.data &&
								Object.keys(data?.data)
									?.filter((key) => !["request_id", "content_type", "additional_info"].includes(key))
									?.map((key, i) => {
										if (data?.data?.[key] || key === "source" || key === "destination") {
											return (
												<div className={"table-row"} key={i}>
													<div className={"key " + (key !== "content" ? key : "")}>
														{key.split("_").join(" ")}
													</div>
													<div className={"value " + (key !== "content" ? key : "")}>
														{typeof data?.data?.[key] === "object" ? (
															<JsonInput
																value={data?.data?.[key] || {}}
																setFilter={false}
																collapsed={1}
																copyCallback={() => handleJsonCopy(key)}
															/>
														) : typeof data?.data?.[key] === "string" &&
														  data?.data?.[key]?.match(/{.*}/g) !== null ? (
															<JsonInput
																value={handleParseJson(data?.data?.[key], key) || {}}
																setFilter={false}
																collapsed={1}
																copyCallback={() => handleJsonCopy(key)}
															/>
														) : typeof data?.data?.[key] === "boolean" ? (
															<span className="bool">{String(data?.data?.[key])}</span>
														) : (
															<React.Fragment>
																{key === "url" ? (
																	<a href={data?.data?.[key]} target="_blank">
																		{data?.data?.[key]}
																	</a>
																) : key === "status_code" ? (
																	<span
																		className={
																			String(data?.data?.[key])?.match(
																				/^2.*/g
																			) !== null
																				? "status success"
																				: "status error"
																		}
																	>
																		{data?.data?.[key]}
																	</span>
																) : key === "purpose" ? (
																	<span>
																		{renderTitle(
																			data?.data?.[key]?.replace(
																				/[.\/_-]/g,
																				" "
																			) || "--"
																		)}
																	</span>
																) : ["source", "destination"].includes(key) ? (
																	<span>
																		{EVENT_SOURCE_DESTINATION_MAP[
																			data?.data?.[key]
																		] ||
																			data?.data?.[key]?.replace(
																				/[.\/_-]/g,
																				" "
																			) ||
																			"POS"}
																	</span>
																) : (
																	<span>{data?.data?.[key] || "--"}</span>
																)}
																{key === "url" && (
																	<span
																		title="Copy Url"
																		className="at-copy-icon"
																		onClick={(e) =>
																			copyTextToClipboard(e, data?.data?.[key])
																		}
																	>
																		<CopyIcon />
																	</span>
																)}
															</React.Fragment>
														)}
													</div>
												</div>
											);
										}
									})}
							{Object.keys(data).length === 0 && !loading && (
								<div className="no-items-placeholder">No Event details found!</div>
							)}
							{Object.keys(data).length === 0 && loading && (
								<div className="P(10px)">
									<div className="shimmer H(60px) Mb(10px)" />
									<div className="shimmer H(60px) Mb(10px)" />
								</div>
							)}
						</div>
					</div>
				</div>
			</FormSidebar>
		</div>
	);
};
export default AuditEventsPreview;
