import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import moment from "moment";

// components
import { RadioButton } from "../_commons/RadioButton";
import CampaignSMS from "./CampaignSMS";
import CampaignEmail from "./CampaignEmail";
import CampaignApp from "./CampaignApp";
import CampaignWeb from "./CampaignWeb";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import OverlaySidebar from "../_commons/OverlaySidebar";
import { msaagesArrayToHtml } from "../../atlas-utils";
import { InfiniteTopBar } from "../../components/SiteComp";

// graphql
import { SEND_TEST_MESSAGE } from "../../graphql/misc";

// clients
import { store } from "../../store/configureStore";
import { client } from "../../client";

// constants
import { MEDIUM_OPTIONS } from "../../client-config";

@connect((store) => ({
	createCampaign: store.createCampaign,
	login: store.login
}))
class CreateCampaignWhat extends Component {
	constructor(props) {
		super(props);
	}

	switchMedium = (selectedMedium) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				medium: selectedMedium.value
			}
		});
	};

	render() {
		const { createCampaign, isFetchingDetails } = this.props;
		const selectedMedium = createCampaign.medium;
		return (
			<div className={"create-campaign-section " + (isFetchingDetails ? "disabled" : "")}>
				<div className="create-campaign-header">
					<Header />
				</div>
				<div className="create-campaign-forms-container">
					{window.isMobile ? (
						<SidebarInMobile
							mediumOptions={MEDIUM_OPTIONS}
							selectedMedium={selectedMedium}
							switchMedium={this.switchMedium}
						/>
					) : (
						<div className="sidebar-container">
							<Sidebar
								mediumOptions={MEDIUM_OPTIONS}
								selectedMedium={selectedMedium}
								switchMedium={this.switchMedium}
							/>
						</div>
					)}
					<div className="data-container">
						{selectedMedium === MEDIUM_OPTIONS[0].value && <CampaignSMS />}
						{selectedMedium === MEDIUM_OPTIONS[1].value && <CampaignEmail />}
						{selectedMedium === MEDIUM_OPTIONS[2].value && <CampaignApp />}
						{/* {
							(selectedMedium === MEDIUM_OPTIONS[3].value) &&
								<CampaignWeb />
						} */}
					</div>
				</div>
				{selectedMedium == "SMS" ? (
					<div className="sms-preview-on-phone">
						<div className="sms-content">
							<div className="meta-content">Inbox</div>
							<div className="sms-body-content">{this.props.createCampaign.message}</div>
						</div>
					</div>
				) : selectedMedium == "APP_PUSH_NOTIFICATION" ? (
					<div className="sms-preview-on-phone sms-preview-on-phone--push-notification">
						<div className="sms-content">
							<div className="meta-content">{moment(new Date()).format("hh:mm:a")}</div>
							<div className="push-body-content">
								<div className="notification-top-bar">
									<div className="icon"></div>
									<div className="biz-name">{this.props.login.loggedInbizDetail.name}</div>
								</div>
								<div className="notification-content">
									<div className="Mb(2px) Fw(600)">{this.props.createCampaign.subject}</div>
									<div className="Mb(3px) Fz(10px)">{this.props.createCampaign.message}</div>
									<div
										style={{
											backgroundImage: `url(${this.props.createCampaign.imageUrl})`
										}}
										className="notification-banner H(100px) Bgc(#f2f2f2)"
									></div>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<SendTestMessage />
			</div>
		);
	}
}

export default CreateCampaignWhat;

const Header = () => (
	<div>
		<div className="header-text">What do you want to send in this campaign?</div>
		<div className="subheader-text">
			Select the channel by which you want to send this campaign, and then compose your message
		</div>
	</div>
);

const Sidebar = ({ mediumOptions, selectedMedium, switchMedium }) => {
	return (
		<div className="at-side-bar">
			{mediumOptions.map((medium, i) => (
				<div
					className={(medium.value === selectedMedium ? "selected " : "") + "selectable sidebar-row"}
					key={i}
					onClick={() => switchMedium(medium)}
				>
					<RadioButton checked={medium.value === selectedMedium ? true : false}>
						<div className="text text--small">{medium.label}</div>
					</RadioButton>
				</div>
			))}
		</div>
	);
};

const SidebarInMobile = ({ mediumOptions, selectedMedium, switchMedium }) => {
	let optionsValuesFlat = mediumOptions.map((i) => {
		return i.value;
	});
	let optionsLabelsFlat = mediumOptions.map((i) => {
		return i.label;
	});
	let index = optionsValuesFlat.indexOf(selectedMedium);

	return (
		<InfiniteTopBar
			clickHandler={(e, i) => {
				let toSwitchTab = mediumOptions[i];
				switchMedium(toSwitchTab);
			}}
			tabs={optionsLabelsFlat}
			selected={index}
		/>
	);
};

@connect((store) => {
	return {
		campaignSideBar: store.campaignSideBar,
		createCampaign: store.createCampaign
	};
})
class SendTestMessage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timer: null
		};
	}

	closeSidebar() {
		store.dispatch({
			type: "RESET_OVERLAY_SIDEBAR_STATE"
		});
	}

	resetStatus = (timeout) => {
		if (this.state.timer) {
			clearTimeout(this.state.timer);
		}
		let timer = setTimeout(() => {
			store.dispatch({
				type: "SET_OVERLAY_SIDEBAR_STATE",
				payload: {
					testMessageStatus: null,
					testMessageLoading: false,
					testMessageError: false
				}
			});
		}, timeout || 5000);
		this.setState({
			timer: timer
		});
	};

	sendMessage = async () => {
		let { medium, testMessageContact } = this.props.campaignSideBar;
		let medium_str = "";
		switch (medium) {
			case "SMS":
				medium_str = "SMS";
				break;
			case "WEB_PUSH_NOTIFICATION":
				medium_str = "Push notification";
				break;
			case "APP_PUSH_NOTIFICATION":
				medium_str = "Push notification";
				break;
			case "EMAIL":
				medium_str = "Email";
				break;
			default:
				medium_str = "Notification";
		}

		let variables = {
			campaignId: this.props.createCampaign.id
		};
		if (medium == "EMAIL") {
			variables.emailIds = testMessageContact;
		} else {
			variables.phoneNumbers = testMessageContact;
		}

		store.dispatch({
			type: "SET_OVERLAY_SIDEBAR_STATE",
			payload: {
				testMessageLoading: true,
				testMessageStatus: null,
				testMessageError: false
			}
		});
		try {
			const resp = await client.mutate({
				mutation: SEND_TEST_MESSAGE,
				variables: variables
			});
			if (resp.data.sendTestMessage.status.success) {
				store.dispatch({
					type: "SET_OVERLAY_SIDEBAR_STATE",
					payload: {
						testMessageLoading: false,
						testMessageStatus: `${medium_str} successfully sent!`,
						testMessageError: false
					}
				});
			} else {
				store.dispatch({
					type: "SET_OVERLAY_SIDEBAR_STATE",
					payload: {
						testMessageLoading: false,
						testMessageStatus: msaagesArrayToHtml(resp.data.sendTestMessage.status.messages),
						testMessageError: true
					}
				});
			}
			this.resetStatus();
		} catch (err) {
			store.dispatch({
				type: "SET_OVERLAY_SIDEBAR_STATE",
				payload: {
					testMessageLoading: false,
					testMessageStatus: "Something went wrong! Try again.",
					testMessageError: true
				}
			});
			this.resetStatus();
		}
	};

	setTestMessageContact = (e) => {
		let payload = { ...this.props.campaignSideBar };
		payload.testMessageContact = e.target.value;
		store.dispatch({
			type: "SET_OVERLAY_SIDEBAR_STATE",
			payload: payload
		});
	};

	render() {
		let { testMessageLoading, testMessageStatus, testMessageContact, testMessageError, active, medium } =
			this.props.campaignSideBar;

		return (
			<OverlaySidebar showOverlaySidebar={active} close={this.closeSidebar}>
				<TestMessage
					testMessageContact={testMessageContact}
					handleChange={this.setTestMessageContact}
					sendMessage={this.sendMessage}
					testMessageLoading={testMessageLoading}
					testMessageStatus={testMessageStatus}
					testMessageError={testMessageError}
					medium={medium}
				/>
			</OverlaySidebar>
		);
	}
}

const TestMessage = (props) => {
	let {
		testMessageContact,
		handleChange,
		sendMessage,
		testMessageLoading,
		testMessageStatus,
		testMessageError,
		medium
	} = props;
	let medium_str = medium;
	switch (medium) {
		case "SMS":
			medium_str = "SMS";
			break;
		case "WEB_PUSH_NOTIFICATION":
			medium_str = "push notification";
			break;
		case "APP_PUSH_NOTIFICATION":
			medium_str = "push notification";
			break;
		case "EMAIL":
			medium_str = "email";
			break;
		default:
			medium_str = "notification";
	}

	return (
		<div className="overlay-sidebar-content">
			<div className="title">Send a test {medium_str}</div>
			<div className="subtitle">Enter the phone no on which you'd like to receive a test {medium_str}</div>

			<div className="main-content">
				<InputWithLabel
					onChange={handleChange}
					value={testMessageContact}
					classes="subject-input"
					name="subject"
					placeholder={"Enter " + (medium == "EMAIL" ? "email ids" : "Phone numbers")}
				>
					{medium == "EMAIL" ? "Email ids" : "Phone numbers"} seperated by comma.
				</InputWithLabel>
				<div className="btn-container">
					<Button classes={testMessageLoading ? "at-btn--disabled" : ""} clickHandler={sendMessage}>
						Send {medium_str}
					</Button>
				</div>
				{testMessageStatus ? (
					<div className={(testMessageError ? "inline-error" : "inline-success") + " Mt(20px)"}>
						{testMessageStatus}
					</div>
				) : null}
			</div>
		</div>
	);
};
