import gql from "graphql-tag";

export const GET_ENTITY_LOCATION_GROUPS = gql`
	query entityLocationGroupsAssociations(
		$entityId: Int!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$brandId: Int
	) {
		locationLocationGroupsAssociations(
			entityId: $entityId
			limit: $limit
			offset: $offset
			filters: $filters
			brandId: $brandId
		) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			objects {
				id
				title
				description
				isAssociated
				includesAll
			}
		}
	}
`;

export const UPDATE_ENTITY_LOCATION_GROUPS = gql`
	mutation updateEntityLocationGroups(
		$locationId: Int!
		$brandId: Int
		$locationGroupsToAssociate: [Int]
		$locationGroupsToDisassociate: [Int]
	) {
		updateLocationGroupAssociation(
			input: {
				locationId: $locationId
				brandId: $brandId
				locationGroupsToAssociate: $locationGroupsToAssociate
				locationGroupsToDisassociate: $locationGroupsToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
