import React, { useMemo } from "react";

import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import TimeSlotFilter from "./TimeSlotFilter";

export const DAYS = [
	{ label: "M", value: "MONDAY" },
	{ label: "T", value: "TUESDAY" },
	{ label: "W", value: "WEDNESDAY" },
	{ label: "T", value: "THURSDAY" },
	{ label: "F", value: "FRIDAY" },
	{ label: "S", value: "SATURDAY" },
	{ label: "S", value: "SUNDAY" }
];

export const scheduleTypeOptions = [
	{
		label: "Daily",
		value: "Daily"
	},
	{
		label: "Weekdays (Mon - Fri)",
		value: "Weekdays"
	},
	{
		label: "Weekends (Sat - Sun)",
		value: "Weekends"
	},
	{
		label: "Custom",
		value: "Custom"
	}
];

const customScheduleType = scheduleTypeOptions[3];

const getMergeSelectedDaysArr = (timeslots) => {
	let mergedDays = {};
	timeslots.forEach((timeslot) => {
		const selectedDays = timeslot.selectDays;
		for (const day in selectedDays) {
			if (selectedDays[day]) {
				mergedDays[day] = true;
			}
		}
	});
	return Object.keys(mergedDays);
};

const Days = ({ index, label, value, handleSelectDays, selectDays, readOnly, disabled }) => {
	return (
		<div
			className={
				"day-container " +
				(readOnly ? "read-only " : "") +
				(selectDays[value] ? "selected" : "") +
				(disabled && !selectDays[value] ? " disabled-days" : "")
			}
			onClick={() => handleSelectDays(value, selectDays[value] ? false : true, index)}
		>
			{label}
		</div>
	);
};
const RegularSchedule = ({
	timeSlots = [],
	readOnly = false,
	handleSelectDays = () => {},
	handleForm = () => {},
	handleTimeSlots = () => {},
	handleDeleteTimeSlot = () => {},
	handleDeleteSingleTiming = () => {},
	isCustomScheduleSelected = false,
	isEditFlow = false
}) => {
	const mergedDaysArr = useMemo(() => getMergeSelectedDaysArr(timeSlots), [timeSlots]) || [];
	const getScheduleOptions = () => {
		if (isEditFlow) {
			return scheduleTypeOptions;
		} else if (isCustomScheduleSelected) {
			return [customScheduleType];
		} else {
			return scheduleTypeOptions;
		}
	};
	const getScheduleType = (timeslot) => {
		if (isEditFlow) {
			return timeslot ? timeslot.scheduleType : null;
		} else if (isCustomScheduleSelected) {
			return customScheduleType;
		} else {
			return timeslot ? timeslot.scheduleType : null;
		}
	};
	const getArrForRegularSchedule = () => {
		if (isEditFlow) {
			return timeSlots.slice(1, timeSlots?.length);
		} else if (!isEditFlow && isCustomScheduleSelected) {
			return timeSlots.slice(1, timeSlots?.length);
		} else if (!isEditFlow && !isCustomScheduleSelected) {
			return [];
		}
	};

	return (
		<div className="form-content">
			<div className="form-row row-full">
				<div className="timings-section">
					<div className="timing-group-wrapper">
						<div className="timings-wrapper">
							<SelectFilter
								title="Repeat Schedule"
								options={scheduleTypeOptions}
								readOnly={readOnly}
								field="scheduleType"
								currValue={timeSlots?.[0]?.scheduleType}
								setFilter={(field, value) => handleTimeSlots({ [field]: value }, 0)}
								labelKey="label"
								valueKey="value"
								isClearable={false}
							/>
							{timeSlots?.[0]?.scheduleType?.label === "Custom" && (
								<div className="days-section">
									{DAYS.map((day, j) => (
										<Days
											key={j}
											index={0}
											{...day}
											handleSelectDays={handleSelectDays}
											selectDays={timeSlots?.[0]?.selectDays || {}}
											readOnly={readOnly}
										/>
									))}
								</div>
							)}
						</div>
						{timeSlots?.[0]?.slots?.map((slot, slotIndex) => (
							<div className="timing-slot-wrapper" key={slotIndex}>
								<TimeSlotFilter
									slot={slot}
									label={"Open time"}
									hoursField="startHours"
									minutesField="startMinutes"
									timeslotIndex={0}
									slotIndex={slotIndex}
									handleTimeSlots={handleTimeSlots}
								/>
								<TimeSlotFilter
									slot={slot}
									label={"Close time"}
									hoursField="endHours"
									minutesField="endMinutes"
									timeslotIndex={0}
									slotIndex={slotIndex}
									handleTimeSlots={handleTimeSlots}
								/>
								{timeSlots?.[0]?.slots?.length > 1 && !readOnly && (
									<img
										alt="del"
										className="cancel-timeslot"
										onClick={() => handleDeleteSingleTiming(slotIndex, 0)}
										src="/assets/icons/cancel-light.svg"
									/>
								)}
							</div>
						))}
						<div className="add-slot">
							<Button type="secondary" clickHandler={() => handleForm("addSlot", null, 0)}>
								+ Add Timings
							</Button>
						</div>
					</div>

					{getArrForRegularSchedule().map((timeslot, timeslotIndex) => (
						<div className="timing-group-wrapper" key={timeslotIndex + 1}>
							<div className="timings-wrapper">
								<SelectFilter
									title="Repeat Schedule"
									options={getScheduleOptions()}
									readOnly={true}
									field="scheduleType"
									currValue={getScheduleType(timeslot)}
									setFilter={(field, value) => handleTimeSlots({ [field]: value }, timeslotIndex + 1)}
									labelKey="label"
									valueKey="value"
									isClearable={false}
								/>

								{!isEditFlow && isCustomScheduleSelected && (
									<div className="days-section">
										{DAYS.map((day, j) => (
											<Days
												key={j}
												index={timeslotIndex + 1}
												{...day}
												handleSelectDays={handleSelectDays}
												selectDays={timeslot?.selectDays || {}}
												readOnly={readOnly}
												disabled={mergedDaysArr.includes(day?.value)}
											/>
										))}
									</div>
								)}

								{isEditFlow && timeslot?.scheduleType?.value === customScheduleType?.value && (
									<div className="days-section">
										{DAYS.map((day, j) => (
											<Days
												key={j}
												index={timeslotIndex + 1}
												{...day}
												handleSelectDays={handleSelectDays}
												selectDays={timeslot?.selectDays || {}}
												readOnly={readOnly}
												disabled={mergedDaysArr.includes(day?.value)}
											/>
										))}
									</div>
								)}

								{timeSlots?.length > 1 && !readOnly && (
									<img
										alt="del"
										className="cancel-timeslot"
										onClick={() => handleDeleteTimeSlot(timeslotIndex + 1)}
										src="/assets/icons/cancel-light.svg"
									/>
								)}
							</div>
							{timeslot?.slots?.map((slot, slotIndex) => (
								<div className="timing-slot-wrapper" key={slotIndex}>
									<TimeSlotFilter
										slot={slot}
										label={"Start time"}
										hoursField="startHours"
										minutesField="startMinutes"
										timeslotIndex={timeslotIndex + 1}
										slotIndex={slotIndex}
										handleTimeSlots={handleTimeSlots}
									/>
									<TimeSlotFilter
										slot={slot}
										label={"Close time"}
										hoursField="endHours"
										minutesField="endMinutes"
										timeslotIndex={timeslotIndex + 1}
										slotIndex={slotIndex}
										handleTimeSlots={handleTimeSlots}
									/>
									{timeslot.slots?.length > 1 && !readOnly && (
										<img
											alt="del"
											className="cancel-timeslot"
											onClick={() => handleDeleteSingleTiming(slotIndex, timeslotIndex + 1)}
											src="/assets/icons/cancel-light.svg"
										/>
									)}
								</div>
							))}
							<div className="add-slot">
								<Button
									type="secondary"
									clickHandler={() => handleForm("addSlot", null, timeslotIndex + 1)}
								>
									+ Add Timings
								</Button>
							</div>
						</div>
					))}
				</div>
			</div>
			{!readOnly && isCustomScheduleSelected && mergedDaysArr?.length > 0 && mergedDaysArr?.length < 7 && (
				<div className="add-another-slot">
					<Button type="secondary" clickHandler={() => handleForm("addNewTimeSlot")}>
						Add Schedule
					</Button>
					<p className="remaining-days">
						There are {7 - mergedDaysArr?.length} days remaining to be scheduled
					</p>
				</div>
			)}
		</div>
	);
};

export default RegularSchedule;
