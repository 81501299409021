import React from "react";

// third party
import { trackEvent } from "../../atlas-utils";

const LocationCountSelection = ({ handleFormData, formData = {} }) => {
	const handleSelect = (type) => {
		handleFormData({ locationAddType: type });
		trackEvent("wiz_loc_count_selection", { type: type });
	};

	return (
		<div className="location-count-selection">
			<div className="section-title">Select number of locations</div>
			<div className="types">
				<div
					onClick={() => handleSelect("single")}
					className={"selection-type" + (formData["locationAddType"] === "single" ? " selected" : "")}
				>
					<div className="single">
						<img className="store" src="/assets/icons/icon-store-location.svg" />
					</div>
					<div className="caption">Single</div>
				</div>
				<div
					onClick={() => handleSelect("multi")}
					className={"selection-type" + (formData["locationAddType"] === "multi" ? " selected" : "")}
				>
					<div className="multiple">
						<img className="store" src="/assets/icons/icon-store-location.svg" />
						<img className="store" src="/assets/icons/icon-store-location.svg" />
					</div>
					<div className="caption">More than one</div>
				</div>
			</div>
		</div>
	);
};
export default LocationCountSelection;
