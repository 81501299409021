import gql from "graphql-tag";

export const GET_CAMPAIGN_LIST = gql`
	query getCampaignList($limit: Int, $offset: Int, $sort: SortInput, $filters: [ListFilterArgument]) {
		campaigns(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
			count
			offset
			limit
			hasNext
			hasPrevious
			objects {
				id
				name
				description
				medium
				imageUrl
				imageId
				campaignExecutionType
				message
				messageType
				subject
				scheduledTime
				includeInNotifications
				segment {
					id
					title
					isImplicit
					filters
				}
				emailTemplate {
					id
					subject
					html
					isEditable
					created
					updated
				}
				cronExpression
				conversionConfig {
					event
					days
					value
				}
				startAt
				endAt
				totalSent
				status
				conversion
				mediumDisplayName
				runTime
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
		}
	}
`;

export const CREATE_CAMPAIGN = gql`
	mutation {
		saveCampaign(input: {}) {
			object {
				id
			}
		}
	}
`;

export const GET_CAMPAIGN_STATUS = gql`
	query getCampaignDetails(
		$id: Int!
		$limit: Int!
		$offset: Int!
		$execInfoLimit: Int
		$execInfoOffset: Int
		$execInfoSort: SortInput
	) {
		campaign(id: $id) {
			id
			name
			description
			status
			totalTargeted
			totalSent
			revenue
			conversionCount
			uniqueConversionCount
			runTime
			medium
			mediumDisplayName
			message
			startAt
			endAt
			scheduledTime
			cronExpression
			segment {
				id
				title
				filters
			}
			reports(limit: $limit, offset: $offset) {
				count
				objects {
					customer {
						id
						fullName
						email
						phone
					}
					conversionRevenue
					dispatchedAt
					status
					conversionCount
				}
			}
			conversionConfig {
				event
				days
			}
			executionInfo(limit: $execInfoLimit, offset: $execInfoOffset, sort: $execInfoSort) {
				offset
				hasNext
				hasPrevious
				objects {
					runTime
					errorMsg
					hasError
				}
			}
		}
	}
`;

export const GET_CAMPAIGN_PROGRESS = gql`
	query getCampaignProgress($id: Int!) {
		campaign(id: $id) {
			id
			totalTargeted
			totalSent
			status
			revenue
			conversionCount
		}
	}
`;

export const GET_CAMPAIGN_DETAILS = gql`
	query getCampaignDetails($id: Int!) {
		campaign(id: $id) {
			id
			name
			description
			message
			messageType
			subject
			emailTemplate {
				id
				subject
				html
				isEditable
			}
			runTime
			medium
			campaignExecutionType
			cronExpression
			mediumDisplayName
			scheduledTime
			includeInNotifications
			startAt
			endAt
			imageUrl
			imageId
			segment {
				id
				title
				isImplicit
				filters
				usersPreview {
					total
					targeted
					percentageTargeted
					creditsRequired
				}
			}
			status
			conversionConfig {
				event
				days
				value
			}
		}
	}
`;

export const SAVE_CAMPAIGN = gql`
	mutation saveCampaign(
		$id: Int
		$name: String
		$description: String
		$medium: CommunicationMedium
		$imageUrl: String
		$imageId: Int
		$campaignExecutionType: CampaignExecutionType
		$message: String
		$messageType: MessageType
		$subject: String
		$includeInNotifications: Boolean
		$segmentId: Int
		$emailTemplateId: Int
		$conversionConfig: CampaignConversionConfigInput
		$cronExpression: String
		$scheduledTime: DateTimeType
		$startAt: DateTimeType
		$endAt: DateTimeType
	) {
		saveCampaign(
			input: {
				id: $id
				name: $name
				description: $description
				medium: $medium
				imageUrl: $imageUrl
				imageId: $imageId
				campaignExecutionType: $campaignExecutionType
				message: $message
				messageType: $messageType
				subject: $subject
				includeInNotifications: $includeInNotifications
				segmentId: $segmentId
				emailTemplateId: $emailTemplateId
				conversionConfig: $conversionConfig
				cronExpression: $cronExpression
				scheduledTime: $scheduledTime
				startAt: $startAt
				endAt: $endAt
			}
		) {
			object {
				id
			}
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const STOP_CAMPAIGN = gql`
	mutation saveCampaign($id: Int, $status: CampaignStatusType) {
		saveCampaign(input: { id: $id, status: $status }) {
			object {
				status
			}
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DELETE_CAMPAIGN = gql`
	mutation deleteCampaign($id: Int) {
		deleteCampaign(id: $id) {
			id
		}
	}
`;

export const RUN_CAMPAIGN = gql`
	mutation runCampaign($id: Int!) {
		runCampaign(id: $id) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
