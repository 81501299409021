import React, { useState } from "react";
import { SelectFilterCustom } from "./SelectFilterCustom";

const TableColumnSelector = ({
	options,
	field = "tableColumnsSelected",
	labelKey = "valueForDisplay",
	valueKey = "value",
	selectedColumns,
	handleColumnSelection,
	...props
}) => {
	// const [optionUpdates, setOptionUpdates ]= useState({
	// 	tableColumnsSelected: {}
	// 	}
	// );
	const setFilter = (field, value) => {
		return;
	};

	return (
		<SelectFilterCustom
			options={options}
			placeholder={
				<span>
					<img src="/assets/icons/icon-table-columns.svg" alt="test" />
				</span>
			}
			labelKey={labelKey}
			valueKey={valueKey}
			showCheckBox
			multi
			isSearchable={false}
			handleCheck={handleColumnSelection}
			setFilter={setFilter}
			classes="table-column-selector"
			optionUpdates={selectedColumns}
			field={field}
			hideSelectedValues
			{...props}
		/>
	);
};
export default TableColumnSelector;
