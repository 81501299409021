import React from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import { SearchFilter } from "../_commons/SearchFilter";

// assets
import CreateIcon from "../_commons/CreateIcon";
import { SelectFilter } from "../_commons/SelectFilter";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-actions--api-list">
				<div className="archive-active-filter-container">
					<SelectFilter
						options={[
							{ value: true, valueForDisplay: "Active" },
							{ value: false, valueForDisplay: <span style={{ color: "#ff425c" }}>Archived</span> }
						]}
						field={"api-access-state"}
						currValue={{
							value: "active",
							valueForDisplay: (
								<span>
									<span style={{ color: "#979797" }}>Status </span>
									{props?.isActive ? "Active" : <span style={{ color: "#ff425c" }}>Archived</span>}
								</span>
							)
						}}
						labelKey="valueForDisplay"
						valueKey="value"
						setFilter={props?.handleActiveFilter}
						isSearchable={false}
						isClearable={false}
					/>
				</div>
				<div className="filter-buttons--api-list">
					{/* {
						props.dimensions.width > 768 &&
						<div className={(props.filterActive ? "active":"")+" filter-in-header campaign-list-filter"}>
							<div className="container" onClick={props.flipShowFilters}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt=""/>
								<div className="filter-title">
									Filter
									{
										props.filterCount > 0 &&
										<span className="filter-count">{props.filterCount}</span>
									}
								</div>
							</div>
						</div>
					} */}
					{props.dimensions.width > 768 && (
						<SearchFilter
							filterOption={props.filterOption}
							value={props.value}
							setFilter={props.setFilter}
							placeholder="Search by name/email"
						/>
					)}
					<div className="action-buttons-container">
						<Link to="/api-access/new">
							<Button>
								<CreateIcon />
								<span>Create</span>
							</Button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
