import React, { useState, useEffect, useCallback, useImperativeHandle, useRef } from "react";

// component
import { Button } from "../components/_commons/Button";
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import Permissions from "../components/RoleEdit/Permissions";
import Users from "../components/RoleEdit/Users";

// utils
import history from "../history";
import { store } from "../store/configureStore";
import { adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchRolesList, fetchRolePermissions } from "../actions/roles";
import { ActionTypes } from "../actions/_types";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constant
import { NESTED_ENTITY_TYPES } from "../client-config";

const FORM_TABS = [
	{
		label: "Permissions",
		value: "permissions"
	},
	{
		label: "Users",
		value: "users"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const RoleEdit = ({ match, roleDetails, isNested = false, closeNestedContainer, connectedRef }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const { loading, data, error } = roleDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchRolePermissions(parseInt(match.params.id));
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			store.dispatch({
				type: ActionTypes.RESET_ROLE_DETAIL
			});
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchRolesList();
					history.push("/access");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="roles-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title={data.name || "Role"}
				subTitle="View permissions and associated users for this role"
				isNested={isNested}
				hideActions={formTab === FORM_TABS[0].value || formTab === FORM_TABS[1].value}
				headerRight={
					<div className="help-btn-container">
						<Button clickHandler={handlePiperAcademy} type="secondary">
							<HelpIcon />
							<span>Help</span>
						</Button>
					</div>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && <Permissions data={data} loading={loading} />}
					{formTab === FORM_TABS[1].value && (
						<Users
							roleId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							handleNestedEntity={handleNestedEntity}
						/>
					)}
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	roleDetails: store.roleDetails
});
export default connect(mapStateToProps)(RoleEdit);
