import React, { useState } from "react";
import { FormSidebar } from "../_commons/FormSidebar";
import { Switch } from "../_commons/Switch";
import ContextMenu from "../_commons/ContextMenu";
import Image from "../_commons/Image";
import { capitaliseText, removeProp } from "../../atlas-utils";
import TextHighlightChip from "../_commons/TextHighlightChip";
import ArchiveRestoreModal from "../_commons/ArchiveRestoreModal";
import { BULK_UPDATE_RULE_STATUS } from "../../graphql/menus";
import { clientMenu } from "../../client-menu";
import NotificationServices from "../../services/NotificationService";

const OPERATION_UI_LABEL_MAP = {
	price: "Default price",
	description: "Description",
	image_url: "Image",
	markup_price: "Markup price",
	name: "Name"
};
const constructHighlightObject = (arrayOne = [], conflictArray = []) => {
	const result = arrayOne.map((item) => {
		const lowercasedItem = item.toLowerCase();
		const highlight = conflictArray.some((conflictItem) => lowercasedItem === conflictItem.toLowerCase());
		return {
			text: item,
			highlight: highlight
		};
	});

	return result;
};
const constructOperationHighlightObject = (arrayOne = [], conflictArray = []) => {
	const result = arrayOne.map((item) => {
		const highlight = conflictArray.some((conflictItem) => item === OPERATION_UI_LABEL_MAP?.[conflictItem]);
		return {
			text: item,
			highlight: highlight
		};
	});
	return result;
};
const constructLocationHiglightObject = (locations = [], conflictArray = []) => {
	const result = locations.map((loc) => {
		const highlight = conflictArray.some((conflictItem) => loc?.locationId === conflictItem);
		return {
			text: loc?.locationName,
			highlight: highlight
		};
	});
	return result;
};
const CONTEXT_MENU_INITIAL_STATE = {
	contextId: null
};

const ResolveConflictDrawer = ({
	isOpen,
	isNested = false,
	newRuleDetails,
	conflictingRuleDetails = [],
	conflictingValues = [],
	menuId,
	setResolveConflictDrawerOpen = {},
	fetchRules = {},
	handleNewRuleEditAction = {},
	setLatestModifiedRuleDetails = {}
}) => {
	const [contextMenuData, setContextMenuData] = useState(CONTEXT_MENU_INITIAL_STATE);
	const [deleteModalStates, setDeleteModalStates] = useState({
		isOpen: false,
		ruleId: null
	});

	const [newRuleStatus, setNewRuleStatus] = useState(newRuleDetails?.ruleObject?.status || "disabled");
	const [conflictRuleStatus, setConflictRuleStatus] = useState("active");

	const handleToggle = () => {
		setNewRuleStatus(newRuleStatus === "active" ? "disabled" : "active");
		setConflictRuleStatus(conflictRuleStatus === "active" ? "disabled" : "active");
	};
	const openDeleteModal = (record) => {
		setResolveConflictDrawerOpen && setResolveConflictDrawerOpen(false);
		setDeleteModalStates((current) => ({
			...current,
			isOpen: true,
			ruleId: record?.ruleId
		}));
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
	};
	const openContextMenu = (id) => {
		setContextMenuData((current) => ({ ...current, contextId: id }));
	};
	const handleClickOutsideContextMenu = () => {
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
	};

	const closeDeleteModal = (refresh) => {
		setDeleteModalStates((current) => ({
			...current,
			isOpen: false,
			ruleId: null
		}));
		if (refresh) {
			fetchRules && fetchRules();
		}
	};

	const RuleConflictHighlighter = ({ ruleInfo }) => {
		return (
			<div className="rule-description-conflict-highlighter">
				When
				{!!ruleInfo?.locations?.length && (
					<>
						<TextHighlightChip content={"Location"} />
						is
						<TextHighlightChip
							content={constructLocationHiglightObject(ruleInfo?.locations, conflictingValues)}
							highlight
						/>
					</>
				)}
				{!!ruleInfo?.locations?.length && !!ruleInfo?.platforms?.length && "+"}
				{!!ruleInfo?.platforms?.length && (
					<>
						<TextHighlightChip content={"Platform"} />
						is
						<TextHighlightChip
							content={constructHighlightObject(
								ruleInfo?.platforms?.map((plf) => capitaliseText(plf)),
								conflictingValues
							)}
							highlight
						/>
					</>
				)}
				<Image alt="right arrow" src="/assets/icons/icon-right-arrow-tailed.svg" />
				{ruleInfo?.operations?.some((op) => op.field === "sold_at") ? (
					<>&ensp; Do not sell </>
				) : (
					<>
						&ensp; Change
						<TextHighlightChip
							content={constructOperationHighlightObject(
								(ruleInfo?.operations ?? [])?.map(
									(operation) => OPERATION_UI_LABEL_MAP[operation?.field]
								),
								conflictingValues
							)}
							highlight
						/>
						of
					</>
				)}
				<TextHighlightChip content={ruleInfo?.entityName} />
			</div>
		);
	};
	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={() => {
						handleNewRuleEditAction && handleNewRuleEditAction(record);
					}}
				>
					Edit Rule
				</div>
				<div className="action-item action-item--archive" onClick={() => openDeleteModal(record)}>
					Delete Rule
				</div>
			</React.Fragment>
		);
	};
	const bulkUpdateRuleStatus = async ({ menuId, ruleIds, newStatus }) => {
		const variables = { menuId, ruleIds, newStatus };
		try {
			const ruleStatusUpdateResp = await clientMenu.mutate({
				mutation: BULK_UPDATE_RULE_STATUS,
				variables,
				fetchPolicy: "no-cache"
			});
			return ruleStatusUpdateResp?.data?.bulkUpdateRuleStatusV2?.success;
		} catch (e) {
			console.log(e);
		}
	};

	const handleResolveConflictSubmit = async () => {
		const updatePromises = [];

		updatePromises.push(
			bulkUpdateRuleStatus({
				menuId,
				ruleIds: [newRuleDetails?.ruleObject?.id],
				newStatus: newRuleStatus
			})
		);

		if (conflictingRuleDetails) {
			const conflictingRuleIds = conflictingRuleDetails?.map((ct) => ct?.id);
			updatePromises.push(
				bulkUpdateRuleStatus({
					menuId,
					ruleIds: conflictingRuleIds,
					newStatus: conflictRuleStatus
				})
			);
		}
		const res = await Promise.all(updatePromises);
		const allTrue = res.every((value) => value === true);
		if (allTrue) {
			NotificationServices.pushNotification({
				message: "Conflict resolved successfully!",
				timeout: 5000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
			setResolveConflictDrawerOpen && setResolveConflictDrawerOpen(false);
			fetchRules && fetchRules();
			setLatestModifiedRuleDetails({});
		} else {
			NotificationServices.pushNotification({
				message: "Failed to resolve rule",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};

	return (
		<>
			<ArchiveRestoreModal
				isOpen={deleteModalStates?.isOpen}
				mode="delete"
				entityType="menuRule"
				entityName="Rule"
				dataObject={{ menuId, ruleId: deleteModalStates?.ruleId }}
				close={closeDeleteModal}
			/>
			<FormSidebar
				isOpen={isOpen}
				title="Resolve Conflict"
				submitTitle={"Done"}
				submit={handleResolveConflictSubmit}
				hideCancelAction
				isNested={isNested}
				close={() => setResolveConflictDrawerOpen(false)}
			>
				<div className="resolve-conflict-drawer">
					{conflictingRuleDetails && conflictingRuleDetails?.length > 0 ? (
						<div className="conflict-message">
							<img src="/assets/icons/error-red-icon.svg" />
							<p>
								Either new rule or conflicting rule can be enabled at a time to avoid conflicts. You can
								also edit the new rule to resolve any conflicts.
							</p>
						</div>
					) : (
						<div className="resolve-message">
							<img src="/assets/icons/green-check-icon.svg" />
							<p>All conflicts resolved, new rule is ready to be saved.</p>
						</div>
					)}
					<div className="rule-card new-rule-card">
						<div className="new-rule-header">
							<p> New Rule</p>
							<Switch
								checked={newRuleStatus === "active"}
								clickHandler={(e) => {
									handleToggle();
								}}
							/>
						</div>
						<div className="new-rule-body">
							<div className="new-rule-name">
								<p>{capitaliseText(newRuleDetails?.ruleObject?.name)}</p>
								<ContextMenu
									isOpen={contextMenuData?.contextId === newRuleDetails?.ruleObject?.id}
									data={{
										ruleId: newRuleDetails?.ruleObject?.id
									}}
									renderMenuItems={renderMenuItems}
									handleOpenMenu={(e) => {
										e.stopPropagation();
										openContextMenu(newRuleDetails?.ruleObject?.id);
									}}
									handleOutsideClick={
										contextMenuData?.contextId === newRuleDetails?.ruleObject?.id
											? () => handleClickOutsideContextMenu()
											: () => {}
									}
								/>
							</div>
							<RuleConflictHighlighter ruleInfo={newRuleDetails?.ruleObject} />
						</div>
					</div>

					{conflictingRuleDetails && conflictingRuleDetails?.length > 0 && (
						<div className="rule-card">
							<div className="new-rule-header">
								<p>{conflictingRuleDetails?.length === 1 ? "Conflicting Rule" : "Conflicting Rules"}</p>
								<Switch
									checked={conflictRuleStatus === "active"}
									clickHandler={() => {
										handleToggle();
									}}
								/>
							</div>
							{conflictingRuleDetails?.map((conflict, i) => {
								return (
									<div className="new-rule-body" key={i}>
										<div className="new-rule-name">
											<p>{capitaliseText(conflict?.name)}</p>
										</div>
										<RuleConflictHighlighter ruleInfo={conflict} key={i} />
										{i + 1 !== conflictingRuleDetails.length && <div className="separator"></div>}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</FormSidebar>
		</>
	);
};

export default ResolveConflictDrawer;
