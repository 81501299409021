import React from "react";

const SpecificItemIssuePlatformCell = ({ value }) => {
	let display;
	let displayIcon;
	if (value?.missing_image_and_description) {
		display = "Missing image and description";
		displayIcon = true;
	} else if (value?.missing_image) {
		display = "Missing image";
		displayIcon = true;
	} else if (value?.missing_description) {
		display = "Missing description";
		displayIcon = true;
	} else {
		display = <img src="/assets/periscope/partiallyOffline.svg" />;
		displayIcon = false;
	}

	return (
		<div className="specificissue-platform-cell" style={{ display: "flex", justifyContent: "end" }}>
			{displayIcon ? (
				<>
					<img src="/assets/periscope/item-issues.svg" className="specific-missingitem-image" />
					<span className="issue-description-display">{display}</span>
				</>
			) : (
				display
			)}
		</div>
	);
};

export default SpecificItemIssuePlatformCell;
