import React, { useState } from "react";

const Image = ({ ...rest }) => {
	const [loading, setLoading] = useState(true);
	return (
		<img
			{...rest}
			onError={(e) => (e.target.src = rest.errorOverrideImage ?? "/assets/icons/icon-empty-img.svg")}
		/>
	);
};

export default Image;
