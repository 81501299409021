export const dynamicTextParser = (rawText, vars) => {
	// make array of variable a flat object
	let flatVars = {};
	vars.forEach((v, i) => {
		flatVars[v.value] = v.name;
	});

	// split rawText string into array with space delimiter
	const rawTextArray = rawText ? rawText.trim().split(" ") : [];

	// create html string
	let htmlString = "";
	rawTextArray.forEach((t, i) => {
		if (t[0] === "{" && t[t.length - 1] === "}") {
			htmlString += `<span contentEditable="false" class="dynamic-variables" data-val="${t}">${
				flatVars[t] ? flatVars[t] : t
			}</span>&nbsp;`;
		} else if (t === "\n") {
			htmlString += "\n";
		} else {
			// check if its the last item of text array
			if (i === rawTextArray.length - 1) {
				// dont add extra white space if its the last item
				htmlString += `${t}`;
			} else {
				htmlString += `${t} `;
			}
		}
	});

	return {
		__html: htmlString
	};
};
