// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// graphql
import { GET_GALLERIES_LIST, GET_GALLERY, EDIT_GALLERY, CREATE_GALLERY } from "../graphql/gallery";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchGalleriesList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().galleriesListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_GALLERIES_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_GALLERIES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_GALLERIES_LIST_SUCCESS,
			payload: { ...resp.data.galleries }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_GALLERIES_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseGalleryData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			break;
		case "server":
			if (result["imgType"].length) {
				result.imgType = result.imgType.map((type) => {
					if (type.value) {
						return type.value;
					}
					return type;
				});
			}
			if (result["supportedLanguages"].length) {
				result.supportedLanguages = result.supportedLanguages.map((lang) => {
					if (lang.value) {
						return lang.value;
					}
					return lang;
				});
			}
			if (!result["isDelete"]) {
				result.isDelete = false;
			}
			if (result.replacementImageContent) {
				result.image = result.replacementImageContent.file;
			}
			if (result.locationGroups.length > 0) {
				result.locationGroupIds = result.locationGroups.map((group) => group.id);
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchGalleryDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_GALLERY_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_GALLERY,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_GALLERY_DETAIL_SUCCESS,
			payload: parseGalleryData(resp.data.gallery, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_GALLERY_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Gallery image details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editGalleryDetail = async (data) => {
	store.dispatch({
		type: ActionTypes.EDIT_GALLERY_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_GALLERY,
			variables: parseGalleryData(data, "server")
		});
		if (resp.data.saveGallery.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_GALLERY_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Gallery image saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_GALLERIES_LIST,
				payload: resp.data.saveGallery.object
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_GALLERY_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveGallery.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_GALLERY_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Gallery image details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const deleteGallery = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_GALLERY,
			variables: parseGalleryData(data, "server")
		});
		if (resp.data.saveGallery.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveGallery.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};

export const createGallery = async (file, showError = true) => {
	try {
		const resp = await client.mutate({
			mutation: CREATE_GALLERY,
			variables: { image: file }
		});
		if (resp.data.saveGallery.status.success) {
			return resp.data.saveGallery.object.image.replace("http://", "https://");
		} else {
			return "";
		}
	} catch (error) {
		console.log(error);
		if (showError) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	}
};
