// third party
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { withScope, captureException } from "@sentry/browser";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";

// utils
import { lS } from "./atlas-utils";
import { logout } from "./components/SiteComp";

// client state
import { store } from "./store/configureStore";

// constants
const API_URL = process.env.REACT_APP_PERISCOPE_API_URL;

const ENV = process.env.NODE_ENV;

// initial state for apollo cache
const defaults = {};

// setup network links
const uploadLink = createUploadLink({ uri: API_URL });
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	// also update biz state if biz ids don't match in local storage and redux store
	let auth = lS.get("auth");
	return {
		headers: {
			...headers,
			Authorization: auth && `Bearer ${auth.token}`
		}
	};
});

const errorLink = onError((response) => {
	console.log(response);
	if (
		response.graphQLErrors &&
		response.graphQLErrors.length > 0 &&
		(response.graphQLErrors[0].message == "Invalid Token" ||
			response.graphQLErrors[0].message == "Token Expired" ||
			response.graphQLErrors[0].message == "User Is Not A Sub-Administrator Of Any Biz")
	) {
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: "Your session has been expired!",
				timeout: 5000,
				error: true
			}
		});
		logout();
	} else if (response.graphQLErrors && ENV === "production") {
		withScope((scope) => {
			scope.setExtra("Graphql query", response.operation.query.loc.source.body);
			scope.setExtra("Variables", response.operation.variables);
			captureException(response.graphQLErrors);
		});
	}
});

export const clientPersicope = new ApolloClient({
	cache: new InMemoryCache(),
	clientState: {
		defaults
	},
	link: ApolloLink.from([authLink, errorLink, uploadLink])
});

window.clientPersicope = clientPersicope;
