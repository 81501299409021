import React, { useEffect, useState } from "react";

// components
import { CommonTable } from "../_commons/CommonTable";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Configurations } from "./Configurations";
import OrderPreparationTime from "./OrderPreparationTime";

// third party
import { connect } from "react-redux";
import CircularSlider from "@fseehawer/react-circular-slider";

// actions
import { updateGetAndSetBizInfo } from "../../actions/updateBizInfo";

// clients
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

// utils
import { trackEvent } from "../../atlas-utils";

// config
import { CATALOGUE_PLATFORMS_LOGO, PLATFORM_NAME_MAP } from "../../client-config";
import { client } from "../../client";
import { GET_LOCATION_LEVEL_PREP_TIMES, UPDATE_LOCATION_LEVEL_PREP_TIMES } from "../../graphql/hub";

const columns = [
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div
				className={"at-table-cell at-cell-text platform"}
				title={PLATFORM_NAME_MAP[record?.name?.toLowerCase()] || record.name}
				key={i}
			>
				<div className="platform-info">
					<img
						src={
							record?.logo ||
							CATALOGUE_PLATFORMS_LOGO[record.name.toLowerCase()] ||
							"/assets/icons/icons8-globe-40.png"
						}
					/>
					<span>{PLATFORM_NAME_MAP[record?.name?.toLowerCase()] || record.name}</span>
				</div>
			</div>
		)
	},
	{
		name: "Auto Acknowledge Orders",
		field: "auto-confirmation",
		render: (record, i, a, c, h, cb, rest) => {
			const options = record.keys.filter((config) => config.key.includes("auto_confirmation"));
			return (
				<div className={"at-table-cell at-cell-text auto-confirmation"} key={i}>
					{options.length > 0 ? (
						(options[0]?.value === "" || options[0]?.value === null) &&
						rest?.selectedLocation?.id !== "default" ? (
							<React.Fragment>
								<div className="default">Default</div>
								<div
									className="link-text hover Mt(10px)"
									onClick={() => rest.handleForm(options[0]?.key, false, record.name)}
								>
									Update
								</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Configurations
									platform={record.name}
									options={options || []}
									handleForm={rest.handleForm}
									validations={rest.validations}
									hideTitle={true}
								/>
								{rest?.selectedLocation?.id !== "default" && (
									<div
										className="link-text hover Mt(10px)"
										onClick={() => rest.handleForm(options[0]?.key, null, record.name)}
									>
										Reset to Default
									</div>
								)}
							</React.Fragment>
						)
					) : (
						"--"
					)}
				</div>
			);
		}
	},
	{
		name: "Auto Mark Food Ready",
		field: "auto-mfr",
		render: (record, i, a, c, h, cb, rest) => {
			const options = record.keys.filter((config) => config.key.includes("auto_mfr"));
			return (
				<div className={"at-table-cell at-cell-text auto-mfr"} key={i}>
					{options.length > 0 ? (
						(options[0]?.value === "" || options[0]?.value === null) &&
						rest?.selectedLocation?.id !== "default" ? (
							<React.Fragment>
								<div className="default">Default</div>
								<div
									className="link-text hover Mt(10px)"
									onClick={() => rest.handleForm(options[0]?.key, false, record.name)}
								>
									Update
								</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Configurations
									platform={record.name}
									options={options}
									handleForm={rest.handleForm}
									validations={rest.validations}
									hideTitle={true}
								/>
								{rest?.selectedLocation?.id !== "default" && (
									<div
										className="link-text hover Mt(10px)"
										onClick={() => rest.handleForm(options[0]?.key, null, record.name)}
									>
										Reset to Default
									</div>
								)}
							</React.Fragment>
						)
					) : (
						"--"
					)}
				</div>
			);
		}
	}
];

const LOCATION_DATA_INIT_STATE = {
	loading: false,
	data: {}
};

const Automation = ({ biz = {}, data, loading, selectedLocation = {}, handleForm, validations }) => {
	const [initValue, setInitValue] = useState(0);
	const [sliderValue, setSliderValue] = useState(biz.orderPrepTime ? Math.round(Number(biz.orderPrepTime / 60)) : 0);
	const [sliderColor, setSliderColor] = useState("hsl(105, 100%, 45%)");
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(true);
	const [editMode, setEditMode] = useState(false);
	const [updatingValues, setUpdatingValues] = useState(false);
	const [locationData, setLocationData] = useState(LOCATION_DATA_INIT_STATE);

	const handleSliderColor = (val) => {
		const h = 105 - val;
		const s = 100;
		const l = 45;
		setSliderColor(`hsl(${h}, ${s}%, ${l}%)`);
		if (!confirmLoading) {
			setSliderValue(val);
			if (!isFormTouched) {
				setFormTouched(true);
			}
		}
	};

	useEffect(() => {
		if (initValue > sliderValue) {
			setTimeout(() => {
				setInitValue(initValue - 1);
			}, 10);
		} else if (initValue < sliderValue) {
			setTimeout(() => {
				setInitValue(initValue + 1);
			}, 10);
		} else {
			setConfirmLoading(false);
		}
	}, [initValue]);

	const handleCancel = () => {
		setFormTouched(false);
		setEditMode(false);
		if (sliderValue === initValue) {
			const resetState = biz.orderPrepTime ? Math.round(Number(biz.orderPrepTime / 60)) : 0;
			setSliderValue(resetState);
			if (initValue > resetState) {
				setConfirmLoading(true);
				setTimeout(() => {
					setInitValue(initValue - 1);
				}, 10);
			} else if (initValue < resetState) {
				setConfirmLoading(true);
				setTimeout(() => {
					setInitValue(initValue + 1);
				}, 10);
			}
		} else {
			setInitValue(sliderValue || 0);
			setSliderValue(biz.orderPrepTime ? Math.round(Number(biz.orderPrepTime / 60)) : 0);
			if (sliderValue !== Math.round(Number(biz.orderPrepTime / 60))) {
				setConfirmLoading(true);
			}
		}
	};

	// const handleSubmit = async () => {
	// 	if (editMode) {
	// 		setEditMode(false);
	// 		if (initValue > sliderValue) {
	// 			setConfirmLoading(true);
	// 			setTimeout(() => {
	// 				setInitValue(initValue - 1);
	// 			}, 10);
	// 		} else if (initValue < sliderValue) {
	// 			setConfirmLoading(true);
	// 			setTimeout(() => {
	// 				setInitValue(initValue + 1);
	// 			}, 10);
	// 		}
	// 	}
	// 	let data = {
	// 		...biz,
	// 		orderPrepTime: Number(sliderValue * 60),
	// 	};
	// 	delete data.logo;
	// 	const resp = await updateGetAndSetBizInfo(data);
	// 	if (resp) {
	// 		setFormTouched(false);
	// 		setInitValue(sliderValue);
	// 	}
	// }
	const handleSliderValue = (val) => {
		setSliderValue(isNaN(val) ? 0 : val > 90 ? 90 : val);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 27) {
			handleCancel();
		} else if (e.keyCode === 13) {
			setEditMode(false);
			if (sliderValue === null) {
				setSliderValue(0);
			}
			if (initValue > sliderValue) {
				setConfirmLoading(true);
				setTimeout(() => {
					setInitValue(initValue - 1);
				}, 10);
			} else if (initValue < sliderValue) {
				setConfirmLoading(true);
				setTimeout(() => {
					setInitValue(initValue + 1);
				}, 10);
			}
		}
	};

	const renderLabel = () => {
		return (
			<div className={"label-container " + (editMode ? "edit" : "")}>
				{editMode ? (
					<InputWithLabel
						value={sliderValue}
						onChange={(e) => handleSliderValue(e.target.value ? parseInt(e.target.value) : null)}
						onKeyUp={handleKeyPress}
						onEnter={handleKeyPress}
						autoFocus
					/>
				) : (
					<div className="slider-value" onClick={() => setEditMode(true)}>
						{confirmLoading ? initValue : sliderValue}
					</div>
				)}
				<div className="label">{sliderValue === 1 ? "minute" : "minutes"}</div>
			</div>
		);
	};

	const fetchLocationLevelOrderPrepTimes = async () => {
		setLocationData((current) => ({
			...current,
			loading: true
		}));
		try {
			const resp = await client.query({
				query: GET_LOCATION_LEVEL_PREP_TIMES,
				variables: { id: Number(selectedLocation?.id) },
				fetchPolicy: "no-cache"
			});

			if (resp?.data?.store) {
				setLocationData((current) => ({
					...current,
					data: resp.data.store
				}));
			}
		} catch (e) {
			console.log(e);
		}
		setLocationData((current) => ({
			...current,
			loading: false
		}));
	};

	const handlePrepTimesSubmit = async (orderPrepTime, busyOrderPrepTime) => {
		setUpdatingValues(true);
		if (selectedLocation?.id === "default") {
			trackEvent("food_prep_time_update", {
				prep_time_type: "biz_level"
			});
			let data = {
				...biz,
				orderPrepTime: Number(orderPrepTime),
				busyOrderPrepTime: Number(busyOrderPrepTime)
			};
			delete data.logo;
			const resp = await updateGetAndSetBizInfo(data);
			if (resp) {
				setFormTouched(false);
			}
		} else if (selectedLocation?.id) {
			trackEvent("food_prep_time_update", {
				prep_time_type: "location_level"
			});
			const variables = {
				...locationData?.data,
				excludedPlatforms: (locationData?.data?.excludedPlatforms ?? []).map((plf) => plf?.platformName),
				orderPrepTime: Number(orderPrepTime),
				busyOrderPrepTime: Number(busyOrderPrepTime)
			};
			const resp = await client.mutate({
				mutation: UPDATE_LOCATION_LEVEL_PREP_TIMES,
				variables
			});
			if (resp?.data?.store?.status?.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Saved changes",
						timeout: 5000,
						error: false
					}
				});
				setFormTouched(false);
			}
		}
		setUpdatingValues(false);
	};

	useEffect(() => {
		if (selectedLocation?.id && selectedLocation?.id !== "default") {
			fetchLocationLevelOrderPrepTimes();
		}
	}, [selectedLocation]);

	return (
		<React.Fragment>
			{
				selectedLocation && (
					<OrderPreparationTime
						orderPrepTime={
							selectedLocation?.id === "default"
								? biz?.orderPrepTime || 0
								: locationData?.data?.orderPrepTime || 0
						}
						busyOrderPrepTime={
							selectedLocation?.id === "default"
								? biz?.busyOrderPrepTime || 0
								: locationData?.data?.busyOrderPrepTime || 0
						}
						setFormTouched={setFormTouched}
						isFormTouched={isFormTouched}
						handlePrepTimesSubmit={handlePrepTimesSubmit}
						updatingValues={updatingValues}
						isLoading={locationData?.loading}
					/>
				)
				// <div className="card-container automation">
				// 	<div className="card-header non-platform">
				// 		<div className="header-container">
				// 			<div className="app-icon">
				// 				<img src="/assets/icons/icon-order-prep-time.svg" alt="" />
				// 			</div>
				// 			<div className="app-header">
				// 				<div className="header-text">Order Preparation Time</div>
				// 				<div className="header-subtext">
				// 					Minimum time duration after which all orders will be marked as "Food ready" automatically ( if Enabled ) for aggregators that support the "Food ready" order status
				// 				</div>
				// 			</div>
				// 			<div className="slider-container">
				// 				<CircularSlider
				// 					min={0}
				// 					max={90}
				// 					dataIndex={initValue}
				// 					renderLabelValue={renderLabel()}
				// 					valueFontSize="1.6rem"
				// 					labelFontSize="0.9rem"
				// 					width={130}
				// 					labelColor='#000000'
				// 					labelBottom={true}
				// 					knobColor='#2f58f2'
				// 					knobSize={30}
				// 					progressColorFrom={sliderColor}
				// 					progressColorTo={sliderColor}
				// 					progressSize={9}
				// 					trackColor='#eaeaea'
				// 					trackSize={9}
				// 					onChange={(val) => handleSliderColor(val)}
				// 				/>
				// 			</div>
				// 			<div className="actions-container">
				// 				<Button
				// 					clickHandler={handleSubmit}
				// 					classes={`W(100px) ` + ((sliderValue === null) || !isFormTouched ? "disabled" : "")}
				// 				>
				// 					Set
				// 				</Button>
				// 				<Button
				// 					clickHandler={handleCancel}
				// 					classes={`W(100px) Mend(10px) ` + (!editMode && !isFormTouched ? "disabled" : "")}
				// 					type="secondary"
				// 				>
				// 					Cancel
				// 				</Button>
				// 			</div>
				// 		</div>
				// 	</div>
				// </div>
			}
			<CommonTable
				loading={loading}
				data={data || []}
				columns={columns}
				sortedField=""
				handleForm={handleForm}
				validations={validations || {}}
				selectedLocation={selectedLocation}
				classes="automation-list-table-container"
				content="Configurations"
			/>
		</React.Fragment>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail
}))(Automation);
