import React, { Component } from "react";

// utils
import { commifyNumbers, printCurrency, scroll } from "../../atlas-utils";

// third party
import { connect } from "react-redux";
import { useTrail, config, animated } from "react-spring";

@connect((store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))
export class OrderChannels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPrevButton: true,
			showNextButton: true,
			scrollBy: 0
		};
	}

	componentDidMount() {
		this.updateRect();
		this.containerRef.addEventListener("scroll", this.updateRect);
	}

	componentWillUnmount() {
		this.containerRef.removeEventListener("scroll", this.updateRect);
	}

	componentWillReceiveProps(newProps) {
		if (this.props.topic != newProps.topic) {
			this.setState(
				{
					showPrevButton: false,
					showNextButton: true,
					scrollBy: 0
				},
				() => this.updateRect()
			);
			this.resetScroll();
		}
	}

	updateRect = () => {
		let containerRect = this.containerRef?.getBoundingClientRect();
		let wrapperRect = this.wrapperRef?.getBoundingClientRect();
		this.setState({
			showPrevButton: containerRect?.left > wrapperRect?.left ? true : false,
			showNextButton: containerRect?.right < wrapperRect?.right ? true : false
		});
	};

	scrollChannels = (direction) => {
		let diffBy = 0;
		let scrollBy = this.state.scrollBy;
		const containerRect = this.containerRef?.getBoundingClientRect();
		const wrapperRect = this.wrapperRef?.getBoundingClientRect();
		if (direction === "prev") {
			diffBy = containerRect.left - wrapperRect.left;
			scrollBy = diffBy <= 200 ? this.state.scrollBy - diffBy : 200;
		} else {
			diffBy = wrapperRect.right - containerRect.right;
			scrollBy = diffBy <= 200 ? this.state.scrollBy + diffBy : 200;
		}
		this.setState(
			{
				scrollBy
			},
			() => scroll({ left: scrollBy }, this.containerRef)
		);
	};

	componentDidUpdate(prevProps) {
		if (this.props.orderChannels.length != prevProps.orderChannels.length) {
			this.resetScroll();
			setTimeout(this.updateRect, 500);
		}
	}

	resetScroll = () => {
		scroll({ left: 0 }, this.containerRef);
	};

	render() {
		const { orderChannels, loading, criteriaValue, currencySymbol, showComparison, fromDashboard } = this.props;
		const { showPrevButton, showNextButton } = this.state;

		return (
			<div className="Pos(r)">
				<div
					className={(loading ? "disabled" : "") + " order-channel-block-container"}
					ref={(containerRef) => (this.containerRef = containerRef)}
				>
					{showPrevButton && <div className="scroll prev" onClick={() => this.scrollChannels("prev")}></div>}
					{showNextButton && <div className="scroll next" onClick={() => this.scrollChannels("next")}></div>}
					<div className="order-channels-wrapper" ref={(wrapperRef) => (this.wrapperRef = wrapperRef)}>
						<Channels
							orderChannels={orderChannels}
							clickHandler={this.props.changeCriteria}
							criteriaValue={criteriaValue}
							currencySymbol={currencySymbol}
							showComparison={showComparison}
							fromDashboard={fromDashboard}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const Channels = ({ orderChannels, criteriaValue, ...rest }) => {
	const trails = useTrail(orderChannels.length, {
		config: config.stiff,
		from: {
			opacity: 0
		},
		opacity: 1
	});
	return (
		<React.Fragment>
			{trails.map(({ opacity }, i) => (
				<OrderChannelBlockPure
					key={orderChannels[i].group}
					selected={criteriaValue == orderChannels[i].group}
					style={{
						opacity
					}}
					{...rest}
					{...orderChannels[i]}
				/>
			))}
		</React.Fragment>
	);
};

const OrderChannelBlockPure = ({
	displayName,
	value,
	percentChange,
	selected,
	clickHandler,
	group,
	topic,
	currencySymbol,
	comparisonValue,
	style,
	fromDashboard = true,
	showComparison = false
}) => (
	<animated.div
		style={style}
		onClick={() => clickHandler(group)}
		className={(selected ? "order-channel-block--selected" : "") + " order-channel-block"}
	>
		{(fromDashboard || showComparison) && (
			<div className={(percentChange < 0 ? "negative" : "") + " percent-change Mstart(8px)"}>
				{percentChange}%
			</div>
		)}
		<div className="value">
			{["SALES", "AVERAGE_SALES", "LOST_SALES"].indexOf(topic) > -1 ? printCurrency(currencySymbol) : null}
			{commifyNumbers(value)}
		</div>
		{showComparison && (
			<div className="comparison-value">
				<span>v/s </span>
				<span className="highlight">
					{["SALES", "AVERAGE_SALES", "LOST_SALES"].indexOf(topic) > -1
						? printCurrency(currencySymbol)
						: null}
					{commifyNumbers(comparisonValue)}
				</span>
			</div>
		)}
		<div className="display-name">{displayName}</div>
	</animated.div>
);

export const OrderChannelBlockPlaceholder = () => {
	return (
		<div className="order-channel-block-container">
			<div className="shimmer H(83px)" />
		</div>
	);
};
