import React, { useCallback, useEffect, useRef, useState } from "react";

// components
import { CommonTable } from "../components/_commons/CommonTable";
import { Paginator } from "../components/_commons/Paginator";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SearchFilter } from "../components/_commons/SearchFilter";
import { Filters } from "../components/_commons/Filters";
import { Button } from "../components/_commons/Button";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// assets
import CreateIcon from "../components/_commons/CreateIcon";
import HelpIcon from "../components/_commons/HelpIcon";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";

// utils
import { store } from "../store/configureStore";
import { scroll, adjustNestedContainer } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchPendingInvitesList, resendUserInvite, cancelUserInvite } from "../actions/pendingInvites";

// constants
import { NESTED_ENTITY_TYPES } from "../client-config";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Email",
		field: "email",
		sortKey: "email",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text email"} key={i}>
				{record.email || "--"}
			</div>
		)
	},
	{
		name: "Roles",
		field: "roles",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text roles"} key={i}>
				{record.roles.length > 0 && (
					<div className="tags-container table-mode">
						<div className="list">
							{record.roles.map((role, j) => (
								<span key={j} className="tag-item" title={role}>
									{role}
								</span>
							))}
						</div>
					</div>
				)}
			</div>
		)
	},
	{
		name: "Created on",
		field: "created_at",
		sortKey: "created_at",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text created_at"} key={i}>
				{record.createdAt ? moment(record.createdAt).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	},
	{
		name: "Status",
		field: "status",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text status"} key={i}>
				<div
					title={
						record.isCancelled
							? "Cancelled"
							: record.isAccepted
							? `Accepted${
									record.acceptedAt
										? ` on ${moment(record.acceptedAt).format("DD MMM, YYYY - hh:mm A")}`
										: ""
							  }`
							: "Pending"
					}
					className={
						"badge " + (record.isCancelled ? "cancelled" : record.isAccepted ? "accepted" : "pending")
					}
				>
					{record.isCancelled ? "Cancelled" : record.isAccepted ? "Accepted" : "Pending"}
				</div>
			</div>
		)
	},
	{
		name: "Action",
		field: "action",
		render: (record, i, a, cs, handleTask) => (
			<div className={"at-table-cell at-cell-text action"} key={i}>
				{!record.isCancelled && !record.isAccepted ? (
					<span className="action-item" onClick={() => handleTask(record.id, "resend")}>
						RESEND
					</span>
				) : (
					"--"
				)}
				{!record.isCancelled && !record.isAccepted && (
					<span className="action-item cancel" onClick={() => handleTask(record.uuid, "cancel")}>
						CANCEL
					</span>
				)}
			</div>
		)
	}
];

const PendingInvites = ({ pendingInvitesList, pendingInvitesListState, configItems }) => {
	const { data, loading } = pendingInvitesList;
	const { limit, offset, currentFilters, appliedFilters, currentDateFilter, appliedDateFilter, sort, sortedField } =
		pendingInvitesListState;
	const [showFilters, setShowFilters] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const [isOpen, setIsOpen] = useState({
		created_at: false,
		accepted_at: false
	});
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const topRef = useRef();

	useEffect(() => {
		fetchPendingInvitesList();
	}, [limit, offset, appliedFilters, appliedDateFilter, sort]);

	useEffect(() => {
		let filterCount = 0;
		for (let f in currentFilters) {
			if (typeof f !== "object" && currentFilters[f]) {
				filterCount++;
			} else if (currentFilters[f]?.value && currentFilters[f]?.value !== "") {
				filterCount++;
			}
		}
		for (let f in currentDateFilter) {
			if (currentDateFilter[f].dateFilter) {
				filterCount++;
			}
		}
		setFilterCount(filterCount);
	}, [currentFilters, currentDateFilter]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const handleActions = async (id, action = "cancel") => {
		if (action === "cancel") {
			const variables = {
				uuid: id
			};
			const resp = await cancelUserInvite(variables);
			if (resp) {
				await fetchPendingInvitesList();
				// scroll to the top
				scroll({ top: topRef?.current?.offset - 57, left: 0 });
			}
		} else if (action === "resend") {
			const variables = {
				id: parseInt(id)
			};
			const resp = await resendUserInvite(variables);
			if (resp) {
				await fetchPendingInvitesList();
				// scroll to the top
				scroll({ top: topRef?.current?.offset - 57, left: 0 });
			}
		}
	};

	const updatePendingInvitesListState = (payload) => {
		store.dispatch({
			type: ActionTypes.PENDING_INVITES_LIST_STATE_CHANGE,
			payload
		});
	};

	const updateDateFilter = useCallback(
		(payload, field) => {
			if (isOpen[field]) {
				store.dispatch({
					type: ActionTypes.PENDING_INVITES_LIST_STATE_CHANGE,
					payload: {
						currentDateFilter: {
							...currentDateFilter,
							[field]: payload.currentDateFilter
						}
					}
				});
			}
		},
		[isOpen, currentDateFilter]
	);

	const applySearchFilter = useCallback(
		debounce(() => fetchPendingInvitesList(), 500),
		[]
	);

	const handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.PENDING_INVITES_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	const setSearchFilter = useCallback(
		(field, value) => {
			store.dispatch({
				type: ActionTypes.PENDING_INVITES_LIST_SEARCH,
				payload: { [field]: value }
			});
			updatePendingInvitesListState({
				offset: 0
			});
			applySearchFilter();
		},
		[applySearchFilter]
	);

	const filterSidebarCloseHandler = useCallback(() => {
		setShowFilters(false);
		updatePendingInvitesListState({
			currentFilters: appliedFilters,
			currentDateFilter: appliedDateFilter
		});
	}, [appliedFilters, appliedDateFilter]);

	const setFilter = useCallback(
		(field, value) => {
			let newCurrentFilters = {
				...currentFilters
			};
			newCurrentFilters[field] = value;
			updatePendingInvitesListState({
				currentFilters: newCurrentFilters
			});
		},
		[currentFilters]
	);

	const applyFilters = useCallback(() => {
		setShowFilters(false);
		updatePendingInvitesListState({
			appliedFilters: {
				...currentFilters
			},
			appliedDateFilter: {
				...currentDateFilter
			},
			offset: 0
		});
	}, [currentFilters, currentDateFilter]);

	const clearFilters = () => {
		setShowFilters(false);
		store.dispatch({
			type: ActionTypes.PENDING_INVITES_LIST_STATE_RESET
		});
	};

	const handleDropdown = useCallback(
		(e, field) => {
			e.stopPropagation();
			if (!isOpen[field]) {
				let updatedIsOpen = {
					...isOpen
				};
				Object.keys(updatedIsOpen).forEach((df) => {
					updatedIsOpen[df] = false;
				});
				updatedIsOpen[field] = true;
				setIsOpen(updatedIsOpen);
			} else {
				closeDropdown(field);
			}
		},
		[isOpen]
	);

	const closeDropdown = useCallback(
		(field) => {
			if (isOpen[field]) {
				updatePendingInvitesListState({
					currentDateFilter: {
						...currentDateFilter,
						[field]: appliedDateFilter[field]
					}
				});
				setIsOpen({
					...isOpen,
					[field]: false
				});
			}
		},
		[isOpen, currentDateFilter, appliedDateFilter]
	);

	const applyDateRange = useCallback(
		(field) => {
			setIsOpen({
				...isOpen,
				[field]: false
			});
		},
		[isOpen]
	);

	const handlePagination = useCallback(
		(page) => {
			// set new offset
			const offset = (page - 1) * limit;
			updatePendingInvitesListState({
				offset
			});
			// scroll to the top
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		},
		[limit]
	);

	const handlePageSize = useCallback(
		(field, size) => {
			if (size.value !== limit) {
				updatePendingInvitesListState({
					[field]: size.value
				});
			}
			// scroll to the top
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		},
		[limit]
	);

	const sortList = (field) => {
		const sort = {
			field
		};
		store.dispatch({
			type: ActionTypes.PENDING_INVITES_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		updatePendingInvitesListState({
			offset: 0
		});
	};

	return (
		<div className="pending-invites-container" ref={topRef}>
			<div className="credits-section-header transactions-list-header">
				<div className="header-text">
					<div className="subtitle">Manage invites for your staff here</div>
				</div>
				<div className="header-action-button">
					<div className="filter-buttons">
						{configItems.dimensions.width > 768 && (
							<div className={(showFilters ? "active" : "") + " filter-in-header campaign-list-filter"}>
								<div className="container" onClick={() => setShowFilters(!showFilters)}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
									</div>
								</div>
							</div>
						)}
						{
							<div className="action-buttons-container">
								<Link to="/access/invite">
									<Button>
										<CreateIcon />
										<span>Invite</span>
									</Button>
								</Link>
							</div>
						}
						{data.searchKeywords && data.searchKeywords.length > 1
							? configItems.dimensions.width > 768 && (
									<div className="search-input-container">
										<SelectFilter
											options={data.searchKeywords}
											field="searchFieldSelected"
											currValue={data.searchFieldSelected}
											setFilter={handleSearchField}
											labelKey="valueForDisplay"
											valueKey="key"
											isSearchable={false}
											isClearable={false}
										/>
										<SearchFilter
											filterOption={{ field: "searchFieldValue" }}
											value={data.searchFieldValue}
											setFilter={setSearchFilter}
											placeholder="Search"
										/>
									</div>
							  )
							: configItems.dimensions.width > 768 && (
									<SearchFilter
										filterOption={{ field: "searchFieldValue" }}
										value={data.searchFieldValue}
										setFilter={setSearchFilter}
										placeholder="Search"
									/>
							  )}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Filters
				isOpen={showFilters}
				close={filterSidebarCloseHandler}
				apply={applyFilters}
				clear={clearFilters}
				options={data.filters || []}
				currentFilters={currentFilters}
				setFilter={setFilter}
				isDropdownOpen={isOpen}
				handleDropdown={handleDropdown}
				closeDropdown={closeDropdown}
				applyDateRange={applyDateRange}
				currentDateFilter={currentDateFilter}
				updateListState={updateDateFilter}
				storeRootField="pendingInvitesListState"
			/>
			<CommonTable
				loading={loading}
				data={data.objects || []}
				columns={columns}
				sortList={sortList}
				sortedField={sortedField}
				classes="pending-invites-list-table-container"
				content="Pending Invites"
				handleTask={handleActions}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={data.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
			/>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	pendingInvitesList: store.pendingInvitesList,
	pendingInvitesListState: store.pendingInvitesListState,
	configItems: store.configItems
}))(PendingInvites);
