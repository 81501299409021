import React, { useState, useRef, useCallback, useEffect } from "react";

// components
import { Topbar } from "../components/_commons/Topbar";
import { Filters } from "../components/_commons/Filters";
import { List } from "../components/CustomerFeedback/List";
import Nps from "../components/CustomerFeedback/Nps";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// clients
import { store } from "../store/configureStore";

// utils
import { scroll } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchCustomerFeedbackList } from "../actions/customerFeedback";

// constants
const FORM_TABS = [
	{
		label: "List",
		value: "list"
	},
	{
		label: "Stats",
		value: "nps"
	}
];

const CustomerFeedbackContainer = ({
	customerFeedbackList,
	customerFeedbackListState,
	customerFeedbackNpsState,
	configItems
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [showFilters, setShowFilters] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const { currentFilters = {}, appliedFilters = {} } =
		formTab === FORM_TABS[0].value ? customerFeedbackListState : customerFeedbackNpsState;
	const { data } = customerFeedbackList;
	const topRef = useRef();

	useEffect(() => {
		let filterCount = 0;
		for (let f in appliedFilters) {
			if (typeof f !== "object" && appliedFilters[f]) {
				filterCount++;
			} else if (appliedFilters[f].value && appliedFilters[f].value != "") {
				filterCount++;
			}
		}
		setFilterCount(filterCount);
	}, [appliedFilters]);

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const updateCustomerFeedbackState = useCallback(
		(payload) => {
			store.dispatch({
				type:
					formTab === FORM_TABS[0].value
						? ActionTypes.CUSTOMER_FEEDBACK_LIST_STATE_CHANGE
						: ActionTypes.CUSTOMER_FEEDBACK_NPS_STATE_CHANGE,
				payload
			});
		},
		[formTab]
	);

	const applySearchFilter = useCallback(
		debounce(() => fetchCustomerFeedbackList(), 500),
		[]
	);

	const handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.CUSTOMER_FEEDBACK_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	const setSearchFilter = useCallback(
		(field, value) => {
			store.dispatch({
				type: ActionTypes.CUSTOMER_FEEDBACK_LIST_SEARCH,
				payload: { [field]: value }
			});
			updateCustomerFeedbackState({
				offset: 0
			});
			applySearchFilter();
		},
		[applySearchFilter]
	);

	const filterSidebarCloseHandler = () => {
		setShowFilters(false);
		updateCustomerFeedbackState({
			currentFilters: appliedFilters
		});
	};

	const setFilter = useCallback(
		(field, value) => {
			updateCustomerFeedbackState({
				currentFilters: {
					...currentFilters,
					[field]: value
				}
			});
		},
		[currentFilters]
	);

	const applyFilters = useCallback(async () => {
		setShowFilters(false);
		updateCustomerFeedbackState({
			appliedFilters: {
				...currentFilters
			},
			offset: 0
		});
		await fetchCustomerFeedbackList();
	}, [currentFilters]);

	const clearFilters = async () => {
		setShowFilters(false);
		updateCustomerFeedbackState({
			currentFilters: {},
			appliedFilters: {},
			offset: 0
		});
		await fetchCustomerFeedbackList();
	};

	return (
		<div className="customer-feedback-section">
			<div className="section-container-common" ref={topRef}>
				<div className="settings-header no-border">
					<div>
						<div className="header-text">Feedback</div>
						<div className="subheader-text">Central repository of your customer feedback</div>
					</div>
				</div>
				<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
				<div className="customer-feedback-container">
					{formTab === FORM_TABS[0].value && (
						<List
							searchKeywords={data.searchKeywords}
							configItems={configItems}
							searchFieldSelected={data.searchFieldSelected}
							handleSearchField={handleSearchField}
							searchFieldValue={data.searchFieldValue}
							setSearchFilter={setSearchFilter}
							showFilters={showFilters}
							setShowFilters={setShowFilters}
							filterCount={filterCount}
						/>
					)}
					{formTab === FORM_TABS[1].value && <Nps />}
					<Filters
						isOpen={showFilters}
						close={filterSidebarCloseHandler}
						apply={applyFilters}
						clear={clearFilters}
						options={data.filters.filter((f) => f.type !== "DATE") || []}
						currentFilters={currentFilters}
						setFilter={setFilter}
					/>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	customerFeedbackList: store.customerFeedbackList,
	customerFeedbackListState: store.customerFeedbackListState,
	customerFeedbackNpsState: store.customerFeedbackNpsState,
	configItems: store.configItems
});
export default connect(mapStateToProps)(CustomerFeedbackContainer);
