// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_MERAKI_SETTINGS,
	EDIT_MERAKI_SETTINGS,
	GET_MERAKI_APP_SETTINGS,
	EDIT_MERAKI_APP_SETTINGS,
	SAVE_CONFIGURATION_VALUES
} from "../graphql/meraki";

// utils
import { parseErrorMessages } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const parseMerakiData = (data, to = "form") => {
	let result = { ...data };

	switch (to) {
		case "form":
			if (result["configurations"]) {
				result["businessConfigurations"] = result["configurations"].map((platform) => {
					platform.keys.map((config) => {
						if (config.type === "choice") {
							if (config.choices) {
								config.choices = config.choices.map((option) => ({
									label: option,
									value: option
								}));
							}
							if (config.value) {
								config.value = { label: config.value, value: config.value };
							}
						}
						return config;
					});
					return platform;
				});
				delete result.configurations;
			}
			if (result["maxOrderPendingTime"]) {
				result.maxOrderPendingTime = Number(result.maxOrderPendingTime) / 60;
			}
			if (result["minimumOrderDeliveryTime"]) {
				result.minimumOrderDeliveryTime = Number(result.minimumOrderDeliveryTime) / 60;
			}
			if (result["minimumOrderPickupTime"]) {
				result.minimumOrderPickupTime = Number(result.minimumOrderPickupTime) / 60;
			}
			break;
		case "server":
			if (result["storeTimings"]) {
				result["storeTimings"] = parseInt(result["storeTimings"].id);
			}
			if (result["orderingSlots"]) {
				result["orderingSlots"] = parseInt(result["orderingSlots"].id);
			}
			if (result["businessConfigurations"]) {
				result["configurations"] = [];
				// result["businessConfigurations"].map((platform) => {
				// 	platform.keys.map((config) => {
				// 		if (config.type === "choice") {
				// 			result["configurations"].push({
				// 				key: config.key,
				// 				value: config.value === null ? "" : config.value.value,
				// 			});
				// 		} else {
				// 			result["configurations"].push({
				// 				key: config.key,
				// 				value: config.value === null ? "" : config.value,
				// 			});
				// 		}
				// 	});
				// });
			}
			if (result["maxOrderPendingTime"]) {
				result.maxOrderPendingTime = Number(result.maxOrderPendingTime) * 60;
			}
			if (result["minimumOrderDeliveryTime"]) {
				result.minimumOrderDeliveryTime = Number(result.minimumOrderDeliveryTime) * 60;
			}
			if (result["minimumOrderPickupTime"]) {
				result.minimumOrderPickupTime = Number(result.minimumOrderPickupTime) * 60;
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchMerakiSettings = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MERAKI_SETTINGS_DETAIL_REQUEST
	});
	try {
		const resp = await client.query({
			query: GET_MERAKI_SETTINGS,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_MERAKI_SETTINGS_DETAIL_SUCCESS,
			payload: {
				settingsData: parseMerakiData(resp.data.bizSettings, "form")
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MERAKI_SETTINGS_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Meraki Settings"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const editMerakiSettings = async (data) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_MERAKI_SETTINGS,
			variables: parseMerakiData(data, "server")
		});
		if (resp.data.saveBizSettings.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Meraki Settings saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch(toggleGlobalLoader(false));
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveBizSettings.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Meraki Settings details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchMerakiAppSettings = async (id = "default", onlyConfigs = false, brand) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_MERAKI_SETTINGS_DETAIL_REQUEST
	});
	try {
		const variables = {};
		if (id !== "default") {
			variables.locationId = parseInt(id);
		}

		variables.brandId = !brand || brand?.id === "all" ? null : parseInt(brand?.id);

		const resp = await client.query({
			query: GET_MERAKI_APP_SETTINGS,
			variables,
			fetchPolicy: "no-cache"
		});
		if (id !== "default" && onlyConfigs) {
			store.dispatch({
				type: ActionTypes.GET_MERAKI_APPS_CONFIGURATIONS,
				payload: {
					appsData: parseMerakiData(resp.data.bizAppSettings, "form")
				}
			});
		} else {
			store.dispatch({
				type: ActionTypes.GET_MERAKI_SETTINGS_DETAIL_SUCCESS,
				payload: {
					appsData: parseMerakiData(resp.data.bizAppSettings, "form")
				}
			});
		}
		store.dispatch(toggleGlobalLoader(false));
		return resp.data.bizAppSettings.paymentGateway;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_MERAKI_SETTINGS_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Meraki Apps details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const editMerakiAppSettings = async (data, id = "default", isCalledFromSettings = false) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_REQUEST
	});
	try {
		const variables = parseMerakiData(data, "server");
		if (id !== "default") {
			variables.locationId = parseInt(id) || null;
		}

		const resp = await client.mutate({
			mutation: EDIT_MERAKI_APP_SETTINGS,
			variables
		});
		if (resp.data.saveBizAppSettings.status.success) {
			store.dispatch({
				type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: isCalledFromSettings ? "Business profile settings updated!" : "Meraki Apps saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch(toggleGlobalLoader(false));
			return resp.data.saveBizAppSettings;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveBizAppSettings.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Meraki Apps details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const editConfigurationValues = async (variables) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const resp = await client.mutate({
			mutation: SAVE_CONFIGURATION_VALUES,
			variables
		});
		if (resp.data.saveConfigurationValues.status.success) {
			store.dispatch(toggleGlobalLoader(false));
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: parseErrorMessages(resp.data.saveConfigurationValues.status.messages),
					timeout: 5000,
					error: true
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_MERAKI_SETTINGS_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Configuration values",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
