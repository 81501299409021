import React, { useState } from "react";

// components
import { Button } from "../../_commons/Button";
import { ButtonIcon } from "../../_commons/ButtonIcon";
import ContextMenu from "../../_commons/ContextMenu";

// assets
import TripleDotIcon from "../../_icons/TripleDotIcon";

const ShareReport = ({ switchToBtn = true }) => {
	const [loading, setLoading] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleShareReport = () => {
		setIsMenuOpen(false);
	};

	const handleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const renderMenuItems = () => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={handleShareReport}>
					<div className="icon">
						<ButtonIcon icon="link" color="#aaaaaa" />
					</div>
					<div className="text">Share Link</div>
				</div>
				<div className="action-item" onClick={handleShareReport}>
					<div className="icon">
						<ButtonIcon icon="pdf" color="#aaaaaa" />
					</div>
					<div className="text">Export as PDF</div>
				</div>
				<div className="action-item" onClick={handleShareReport}>
					<div className="icon">
						<ButtonIcon icon="csv" color="#aaaaaa" />
					</div>
					<div className="text">Export as CSV</div>
				</div>
				<div className="action-item" onClick={handleShareReport}>
					<div className="icon">
						<ButtonIcon icon="jpeg" color="#aaaaaa" />
					</div>
					<div className="text">Export as JPEG</div>
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="analytics-share-report">
			<ContextMenu
				isOpen={isMenuOpen}
				renderMenuItems={renderMenuItems}
				handleOpenMenu={handleMenu}
				handleOutsideClick={() => setIsMenuOpen(false)}
				clickEvent="mousedown"
				classes={!switchToBtn ? "with-dots" : ""}
			>
				<Button loading={loading} clickHandler={handleShareReport} classes={!switchToBtn ? "dots" : ""}>
					{switchToBtn ? (
						<React.Fragment>
							<span>
								<ButtonIcon icon="share" color="#ffffff" />
							</span>
							Share Report
						</React.Fragment>
					) : (
						<TripleDotIcon />
					)}
				</Button>
			</ContextMenu>
		</div>
	);
};
export default ShareReport;
