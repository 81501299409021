import React, { useEffect, useState } from "react";

// third party
import { useSpring, animated as a } from "react-spring";

// components
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";

export const SuccessMessage = ({
	handleSelectedTabUpdate,
	initialiseData,
	revertBackToLocationsList,
	locationAddType,
	successStatus,
	repeatProcess,
	addedLocationsData,
	openLocationDetails
}) => {
	const [selectedLocation, setSelectedLocation] = useState({});
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const handleClick = () => {
		if (selectedLocation) {
			if (selectedLocation.biz_location_id) {
				openLocationDetails(selectedLocation.biz_location_id);
			} else {
				revertBackToLocationsList();
			}
		} else {
			revertBackToLocationsList();
		}
	};

	useEffect(() => {
		if (addedLocationsData.length > 0) {
			setSelectedLocation(addedLocationsData[0]);
		}
	}, [addedLocationsData]);

	const updateLocationSelection = (field, value) => {
		if (value) {
			setSelectedLocation({ ...value });
		} else {
			setSelectedLocation(undefined);
		}
	};

	return (
		<React.Fragment>
			<a.div className="common-box-structure " style={contentProps}>
				<div className="header">
					{successStatus
						? `You have successfully added ${locationAddType === "single" ? "a" : ""} new ${
								locationAddType === "single" ? "location" : "locations"
						  }`
						: "Failed to add new locations."}
				</div>
				<div className="success-container">
					<div className="success-logo">
						{successStatus ? (
							<img src={`/assets/icons/icon-noun-tick.svg`} />
						) : (
							<img
								style={{ transform: "scale(5)", paddingTop: "12px" }}
								src={`/assets/icons/icon-error.svg`}
							/>
						)}
					</div>
					{successStatus ? (
						<div className="success-message">
							<strong>Team UrbanPiper</strong> wishes you great success with your new locations.
						</div>
					) : (
						<div className="success-message" style={{ paddingTop: "48px" }}>
							Adding new locations failed
						</div>
					)}
				</div>
				<div className="button-div single-button">
					<Button clickHandler={handleClick}>
						<span>{successStatus ? "Take me to location" : "Close"}</span>
					</Button>
					{locationAddType === "multi" && successStatus && (
						<SelectFilter
							options={addedLocationsData || []}
							currValue={selectedLocation}
							labelKey={"biz_location_nickname"}
							valueKey={"biz_location_id"}
							setFilter={updateLocationSelection}
						/>
					)}
					{!successStatus && (
						<Button clickHandler={repeatProcess}>
							<span>Retry</span>
						</Button>
					)}
				</div>
			</a.div>
			{successStatus ? (
				<>
					<img src="/assets/success-popup.gif" className="success-popup" />
					<img src="/assets/success-popup.gif" className="success-popup success-popup-left-aligned" />
				</>
			) : null}
		</React.Fragment>
	);
};
