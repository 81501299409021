import React, { useState, useCallback, useEffect } from "react";

// components
import { Switch } from "../_commons/Switch";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { CheckBox } from "../_commons/CheckBox";
import Popover from "../_commons/Popover";
import { App as UninvitedApp } from "../../containers/UnifiedUserInvite";
import { App as PendingInviteApp } from "../UnifiedInviteDetail/BasicInfo";

// third party
import { connect } from "react-redux";

// assets
import { ButtonIcon } from "../_commons/ButtonIcon";

const BasicInfo = ({
	data = {},
	handleForm,
	validations,
	loading = false,
	readOnly = true,
	editMode = false,
	extractInitials,
	handleData,
	userId,
	userType
}) => {
	const handleDropdownLabel = (currValue, handleCheck, field, props, isClearable = true) => {
		return (
			<React.Fragment>
				{currValue?.map?.((option, i) => (
					<div className="option-tag" key={i}>
						<div className="option">
							{option?.[props.labelKey]}
							{option?.brandName ? ` (${option?.brandName})` : ""}
						</div>
						{isClearable && (
							<ButtonIcon
								icon="cross"
								clickHandler={(e) => {
									handleCheck(false, field, option, props);
									e.stopPropagation();
								}}
							/>
						)}
					</div>
				))}
			</React.Fragment>
		);
	};

	const handleDropdownOption = (option, field, handleCheck, optionUpdates, props) => {
		const isChecked = optionUpdates[field] && optionUpdates[field]?.[option?.[props?.valueKey]] ? true : false;
		const title = `${option?.[props?.labelKey]}${option?.brandName ? ` (${option?.brandName})` : ""}`;
		return (
			<React.Fragment>
				<CheckBox
					checked={isChecked}
					clickHandler={() => handleCheck(!isChecked, field, option, props)}
					readOnly={option.readOnly || false}
					title={title}
				>
					{title}
				</CheckBox>
			</React.Fragment>
		);
	};

	// placeholder
	if (loading && Object.keys(data).length === 0) {
		return (
			<div className="user-info-and-roles">
				<div className="card-container">
					<div className="card-header">
						<div className="product">
							<div className="shimmer H(20px) W(100px)" />
						</div>
					</div>
					<div className="card-content">
						<div className="form-row row-half">
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
						</div>
						<div className="form-row row-half">
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
						</div>
					</div>
				</div>
				<div className="card-container">
					<div className="card-header">
						<div className="product">
							<div className="shimmer H(20px) W(100px)" />
						</div>
					</div>
					<div className="card-content">
						<div className="form-row row-half">
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
						</div>
						<div className="form-row row-half">
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
							<div>
								<div className="shimmer H(30px) W(100%)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	if (editMode) {
		return (
			<div className="edit-user-roles">
				<div className="sub-heading">Select platforms & roles for {data.fullName || "this user"}</div>
				{data?.appsUserData?.map((app, i) => (
					<App
						key={i}
						index={i}
						app={app}
						data={data}
						extractInitials={extractInitials}
						handleData={handleData}
						handleForm={handleForm}
						handleDropdownLabel={handleDropdownLabel}
						handleDropdownOption={handleDropdownOption}
						editMode={editMode}
						userId={userId}
					/>
				))}
				{data?.pendingInvites?.map((invite, i) =>
					invite?.invitedApps?.map((app, j) => (
						<PendingInviteApp
							key={`${i}${j}`}
							app={app}
							extractInitials={extractInitials}
							status={invite.status}
							editMode={editMode}
							handleDropdownLabel={(c, h, f, p) => handleDropdownLabel(c, h, f, p, false)}
							handleDropdownOption={handleDropdownOption}
						/>
					))
				)}
				{data.inviteData?.inviteData?.appsData?.length > 0 &&
					data.inviteData?.inviteData?.appsData?.map((app, i) => (
						<UninvitedApp
							key={i}
							index={i}
							app={app}
							data={data?.inviteData}
							extractInitials={extractInitials}
							handleData={(updates) => handleData(updates, true)}
							handleForm={(f, v, i) => handleForm(f, v, i, true)}
							handleDropdownLabel={handleDropdownLabel}
							handleDropdownOption={handleDropdownOption}
						/>
					))}
			</div>
		);
	}
	return (
		<div className="user-info-and-roles">
			{(data.fullName || data.email || data.phone) && (
				<div className="card-container">
					<div className="card-header">
						<div className="product">
							<div className="logo">
								<img className="user" src="/assets/icons/icon-user.svg" alt="" />
							</div>
							<div className="name">User Info</div>
						</div>
						{!data.isActive && <div className="archived">Archived</div>}
					</div>
					<div className="card-content">
						{data.fullName && (
							<div className="form-row row-half">
								<div className="full-name">
									<div className="meta-info">Full Name</div>
									<div className="info">{data.fullName}</div>
								</div>
							</div>
						)}
						{((data.emailVerified && data.email) || (data.phoneVerified && data.phone)) && (
							<div className="form-row row-half">
								{data.emailVerified && data.email && (
									<div className="email">
										<div className="meta-info">Email</div>
										<div className="info">{data.email}</div>
									</div>
								)}
								{data.phoneVerified && data.phone && (
									<div className="phone">
										<div className="meta-info">Phone Number</div>
										<div className="info">
											{data?.phone?.includes("+")
												? data.phone
												: `${data.isdCode || ""} ${data.phone}`}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			)}
			{data?.appsUserData
				?.filter((app) => app.isActive)
				.map((app, i) => (
					<App
						key={i}
						index={i}
						app={app}
						data={data}
						extractInitials={extractInitials}
						handleData={handleData}
						handleForm={handleForm}
						handleDropdownLabel={handleDropdownLabel}
						handleDropdownOption={handleDropdownOption}
						editMode={editMode}
						userId={userId}
					/>
				))}
			{data?.pendingInvites?.map((invite, i) =>
				invite.invitedApps.map((app, j) => (
					<PendingInviteApp
						key={`${i}${j}`}
						app={app}
						extractInitials={extractInitials}
						status={invite.status}
						editMode={editMode}
						handleDropdownLabel={handleDropdownLabel}
						handleDropdownOption={handleDropdownOption}
					/>
				))
			)}
		</div>
	);
};
export default connect((store) => ({
	listGroups: store.configItems.listGroups,
	access: store.login.loginDetail.access,
	loggedInUsername: store.login.loginDetail.username,
	locationsList: store.configItems.stores
}))(BasicInfo);

const App = ({
	editMode = false,
	app,
	index,
	data,
	handleData,
	handleForm,
	extractInitials,
	handleDropdownLabel,
	handleDropdownOption,
	userId
}) => {
	const [optionUpdates, setOptionUpdates] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState({});
	const showLocations =
		app.appName === "prime" ||
		app?.roles?.find((role) => role?.id === 53 || role?.id === 55 || role?.id === 66) !== undefined
			? true
			: false;

	useEffect(() => {
		if (editMode) {
			let optUpdates = {
				roles: {},
				locations: {}
			};
			let checkedAllUpdates = {
				locations: app.allLocations
			};
			if (app?.roles?.length) {
				app.roles.forEach((role) => {
					optUpdates.roles[role.id] = true;
				});
			}
			if (app?.locations?.length) {
				app.locations.forEach((location) => {
					optUpdates.locations[location.id] = true;
				});
			}
			setOptionUpdates(optUpdates);
			setIsCheckedAll(checkedAllUpdates);
		}
	}, [editMode]);

	const handleCheck = useCallback(
		(state, field, option, props) => {
			setOptionUpdates({
				...optionUpdates,
				[field]: {
					...optionUpdates[field],
					[option[props.valueKey]]: state
				}
			});
			setIsCheckedAll({
				...isCheckedAll,
				[field]: false
			});
			// update field in app data
			const updatedAppsData = data.appsUserData.map((app, i) => {
				let appData = { ...app };
				if (i === index) {
					let updates = [...(app[field] ?? [])];
					if (state) {
						updates.push({ ...option });
					} else {
						updates = [...updates].filter((opt) => opt[props.valueKey] !== option[props.valueKey]);
					}
					appData[field] = updates;
					appData.allLocations = false;
					// handle validations
					if (field === "roles") {
						appData.rolesValidation = "";
					}
					if (field === "locations" || field === "allLocations") {
						appData.locationsValidation = "";
					}
				}
				return appData;
			});
			handleData({
				...data,
				appsUserData: updatedAppsData
			});
		},
		[optionUpdates, isCheckedAll, data, handleData, index]
	);

	const handleCheckAll = useCallback(
		(state, field, options, props) => {
			const filterOptions = {};
			options = options.forEach((opt) => {
				filterOptions[opt[props.valueKey]] = false;
			});
			setOptionUpdates({
				...optionUpdates,
				[field]: {
					...optionUpdates[field],
					...filterOptions
				}
			});
			setIsCheckedAll({
				...isCheckedAll,
				[field]: state
			});
			// update field in app data
			const updatedAppsData = data.appsUserData.map((app, i) => {
				let appData = { ...app };
				if (i === index) {
					appData[field] = [];
					appData.allLocations = state;
					// handle validations
					if (field === "roles") {
						appData.rolesValidation = "";
					}
					if (field === "locations" || field === "allLocations") {
						appData.locationsValidation = "";
					}
				}
				return appData;
			});
			handleData({
				...data,
				appsUserData: updatedAppsData
			});
		},
		[optionUpdates, isCheckedAll, data, handleData, index]
	);

	const renderPopover = (locations = []) => {
		return (
			<div className="locations-list">
				{locations?.length > 0 &&
					locations?.map((location, i) => (
						<div className="name" key={i}>
							{location.name}
							{location?.brandName ? ` (${location?.brandName})` : ""}
						</div>
					))}
			</div>
		);
	};

	const handlePin = (val, i) => {
		// pin is a number
		const pin = val ? val.replace(/[^\d.-]+/g, "") : null;
		handleForm("pin", pin ? parseInt(pin) : null, i);
	};

	return (
		<div className="card-container">
			<div className="card-header">
				<div className="product">
					<div className="logo">
						<img src={`/assets/icons/${app.appName}-logo.svg`} alt="" />
					</div>
					<div className="name">
						<span>{app.appName}</span>
						{!app.isActive && <span> - {app.appBizName}</span>}
					</div>
				</div>
				{editMode && (app.appName === "atlas" ? app.appUserId !== userId : true) && (
					<Switch
						title="Access"
						checked={app.isActive}
						clickHandler={() => handleForm("isActive", !app.isActive, index)}
					/>
				)}
			</div>
			{editMode && app.appName === "atlas" ? (
				<div className="description">
					Comprehensive tool that empowers you to manage restaurant operations efficiently and provides
					valuable insights through detailed analytics.
				</div>
			) : editMode && app.appName === "prime" ? (
				<div className="description">
					Dedicated tool that helps you manage store operations like POS, stock management, online orders, and
					customer service.
				</div>
			) : null}
			{app.isActive && (
				<div className="card-content">
					{editMode && (
						<div className="biz-info">
							<div className="logo">
								{app?.appBizLogo ? (
									<img src={app?.appBizLogo} alt="" />
								) : (
									<div className="initials">{extractInitials(app?.appBizName)}</div>
								)}
							</div>
							<div className="name">{app?.appBizName}</div>
						</div>
					)}
					{editMode && (
						<div
							className={`form-row row-${app?.appName === "prime" && app.thickClient ? "half" : "full"}`}
						>
							{app?.appName === "atlas" ? (
								<SelectFilterCustom
									title="Roles"
									options={app?.rolesOptions}
									field="roles"
									currValue={app?.roles || []}
									labelKey={"name"}
									valueKey={"id"}
									customLabel={true}
									renderLabel={handleDropdownLabel}
									ellipsizedLength={100}
									multi={true}
									requiredLabel={true}
									showCheckBox={true}
									showSelectAllOption={false}
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									isCheckedAll={isCheckedAll}
									handleCheckAll={handleCheckAll}
									placeholder="Select Roles"
									validationMessage={app.rolesValidation || ""}
								/>
							) : (
								<SelectFilter
									title="Roles"
									options={app?.rolesOptions}
									field="roles"
									requiredLabel={true}
									currValue={app.roles || null}
									setFilter={(field, value) => handleForm(field, value, index)}
									labelKey="name"
									valueKey="id"
									validationMessage={app.rolesValidation || ""}
								/>
							)}
							{app?.appName === "prime" && app.thickClient && (
								<InputWithLabel
									value={app.pin || ""}
									type="password"
									onChange={(e) => handlePin(e.target.value, index)}
									showCustomTooltip={true}
									tooltipInfo="Pin must be a number with 4-10 digits"
									classes={"at-input--label"}
									showLabel={true}
									image={true}
									imageSrc={"/assets/left-nav/icon-lock.svg"}
								>
									Pin
								</InputWithLabel>
							)}
						</div>
					)}
					{editMode && showLocations && (
						<div className="form-row row-full">
							<SelectFilterCustom
								title="Locations"
								options={app.locationsOptions}
								field="locations"
								currValue={app.locations || []}
								labelKey={"name"}
								valueKey={"id"}
								customLabel={true}
								renderLabel={handleDropdownLabel}
								customOptions={app.appName === "atlas"}
								renderOptions={handleDropdownOption}
								customSearchLabel={app.appName === "atlas"}
								handleCustomSearchLabel={(option, props) =>
									`${option?.[props?.labelKey]}${option?.brandName ? ` (${option?.brandName})` : ""}`
								}
								ellipsizedLength={100}
								multi={true}
								requiredLabel={true}
								showCheckBox={true}
								showSelectAllOption={app.appName === "prime"}
								optionUpdates={optionUpdates}
								handleCheck={handleCheck}
								isCheckedAll={isCheckedAll}
								handleCheckAll={handleCheckAll}
								placeholder="Select Locations"
								validationMessage={app.locationsValidation || ""}
							/>
						</div>
					)}
					{!editMode && (
						<div className="form-row row-half">
							<div className="business">
								<div className="meta-info">Business</div>
								<div className="info">
									<div className="logo">
										{app?.appBizLogo ? (
											<img src={app?.appBizLogo} alt="" />
										) : (
											<div className="initials">{extractInitials(app?.appBizName)}</div>
										)}
									</div>
									<div className="name">{app?.appBizName}</div>
								</div>
							</div>
						</div>
					)}
					{!editMode && (
						<div className="form-row row-half">
							<div className="roles">
								<div className="meta-info">Roles</div>
								<div className="info">
									{app?.appName === "atlas" ? (
										app?.roles?.map((role, i) => (
											<span key={i} className="role" title={role.name}>
												{role.name}
												{i !== app?.roles?.length - 1 ? ", " : ""}
											</span>
										))
									) : (
										<span className="role" title={app?.roles?.name}>
											{app?.roles?.name}
										</span>
									)}
								</div>
							</div>
							{showLocations && (app?.locations?.length > 0 || app?.allLocations) && (
								<div className="locations">
									<div className="meta-info">Locations</div>
									<div className="info">
										{app?.allLocations
											? "All Locations"
											: app?.locations?.map((location, i) => {
													if (i < 8) {
														return (
															<span key={i} className="location" title={location.name}>
																{location.name}
																{location?.brandName ? ` (${location?.brandName})` : ""}
																{i !== app?.locations?.length - 1 && i !== 7
																	? ", "
																	: ""}
															</span>
														);
													}
											  })}
										{!app?.allLocations && app?.locations?.length > 8 && <span>...</span>}
										{app?.locations?.length > 8 && (
											<Popover
												showOnClick={true}
												renderPopover={() => renderPopover(app?.locations)}
												position="down-left"
											>
												<div className="view-all">View All</div>
											</Popover>
										)}
									</div>
								</div>
							)}
						</div>
					)}
					{!editMode && app.appName === "prime" && app.thickClient && (
						<div className="form-row row-half">
							<div className="pin">
								<div className="meta-info">PIN</div>
								<div className="info">
									{app.pin
										? String(app.pin)
												?.split("")
												?.map((digit) => <>&#x2022;</>)
										: "--"}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
			{/* {
				(app.appName === 'prime') &&
				<div className="card-footer">
					<img src="/assets/icons/icon-question.svg" alt="" />
					<div className="text">Want access to multiple business on Prime?</div>
					<div className="link-text">Contact Support</div>
				</div>
			} */}
		</div>
	);
};
