import React, { useEffect, useState, useMemo } from "react";

// components
import { NewDateCompareFilter } from "../components/_commons/NewDateCompareFilter";
import { Topbar } from "../components/_commons/Topbar";
import { Filters } from "../components/_commons/Filters";
import { SelectFilter } from "../components/_commons/SelectFilter";

// third party
import { connect } from "react-redux";
import moment from "moment";
import {
	XAxis,
	YAxis,
	CartesianGrid,
	Bar,
	BarChart,
	Tooltip,
	ResponsiveContainer,
	Legend,
	Sankey,
	Rectangle,
	Layer
} from "recharts";

// client
import { store } from "../store/configureStore";

// utils
import { capitaliseTextStrict } from "../atlas-utils";
import { trackEvent } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchReconciliationPayoutStatus, fetchReconciliationRevenueBreakdown } from "../actions/reconciliation";
import { fetchStoresDebounced } from "../actions/actions";
import Placeholder from "../components/_commons/Placeholder";

// constants
const FORM_TABS = [
	{ label: "Payout Status", value: "payoutStatus" },
	{ label: "Revenue Breakdown", value: "revenueBreakdown" }
];
const PAYOUT_STATUS_MAP = {
	reconciled: "Reconciled",
	missing: "Missing in Payout",
	inconsistent: "Inconsistent",
	resolved: "Manually Reconciled",
	open: "Missing in Atlas"
};
const PAYOUT_STATUS_COLORS = [
	"#0D7EA0", // reconciled
	"#3498db", // missing
	"#B2596E", // inconsistent
	"#3EC686", // Manually reconciled
	"#FF7557", // missing in Atlas
	"#F8BC3B"
];
const REVENUE_BREAKDOWN_COLORS = [
	"#0D7EA0",
	"#A8CBD9",
	"#FF7557",
	"#FF7557",
	"#FF7557",
	"#3EC686",
	"#FF7557",
	"#FF7557",
	"#FF7557",
	"#FF7557",
	"#FF7557",
	"#FF7557",
	"#3EC686",
	"#3EC686",
	"#3EC686",
	"#3EC686"
];

const ReconciliationStats = ({ reconciliationStats, reconciliationStatsState, configItems, currency }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [showFilters, setShowFilters] = useState(false);
	const [storesLookup, setStoresLookup] = useState({});
	const { data, loading, error } = reconciliationStats;
	const { currentDateFilter, appliedDateFilter, loadedDateFilter, currentFilters, appliedFilters } =
		reconciliationStatsState;

	const bizPlatforms = useMemo(() => {
		return data.filters.find((filter) => filter.field === "biz_platform_id") || {};
	}, [data.filters]);

	useEffect(() => {
		if (!configItems.stores.items.length) {
			fetchStoresDebounced("");
		}
		const eventName = "payout_stats_view";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		trackEvent(eventName, eventMeta);
	}, []);

	useEffect(() => {
		fetchReconciliationPayoutStatus();
		fetchReconciliationRevenueBreakdown();
	}, [appliedDateFilter, appliedFilters]);

	const updatereconciliationStatsState = (payload) => {
		store.dispatch({
			type: ActionTypes.RECONCILIATION_STATS_STATE_CHANGE,
			payload
		});
	};

	const closeFilterSidebar = () => {
		setShowFilters(false);
		updatereconciliationStatsState({
			currentFilters: appliedFilters
		});
	};

	const setFilter = (field, value) => {
		updatereconciliationStatsState({
			currentFilters: {
				...currentFilters,
				[field]: value
			}
		});
	};

	const applyFilters = () => {
		setShowFilters(false);
		updatereconciliationStatsState({
			appliedFilters: currentFilters
		});
	};

	const setBizPlatformsFilter = (field, value) => {
		if (value) {
			updatereconciliationStatsState({
				currentFilters: {
					...currentFilters,
					[field]: value
				},
				appliedFilters: {
					...currentFilters,
					[field]: value
				}
			});
		} else {
			let updatedCurrFilters = { ...currentFilters };
			delete updatedCurrFilters[field];
			updatereconciliationStatsState({
				currentFilters: updatedCurrFilters,
				appliedFilters: updatedCurrFilters
			});
		}
	};

	const clearFilters = () => {
		setShowFilters(false);
		updatereconciliationStatsState({
			currentFilters: {},
			appliedFilters: {}
		});
	};

	const updateItemsLookup = (id, name) => {
		setStoresLookup({
			...storesLookup,
			[id]: name
		});
	};

	const getLabel = (unit, category) => {
		if (!unit || !category) {
			return "--";
		}
		let label = "";
		// Currently for custom date filters, when applied, BE returns extra data which is not included in the
		// applied date range. For ex: When a custom date filter is applied "01 Jun, 2022 — 30 Jun, 2022", we
		// are getting additional data for "May 31, 2022" which should not be included as it's not in date range.
		// Ideally, this should be handled on BE, but due to some BE constraints, we are handling it on FE.
		// For now, we will check if a date is in range and determine if its data should be shown or discarded.
		// The check is done and valid only for units: DAY and MONTH.
		const {
			current: { dateFilter, dateTypeSelected }
		} = loadedDateFilter;
		const dates = dateFilter.split(",");
		switch (unit) {
			case "MONTH":
				if (
					dateTypeSelected.label === "Custom" &&
					!moment(category).isBetween(dates[0], dates[1], "month", "[]")
				) {
					label = undefined;
				} else {
					label = moment(category).format("MMM YYYY");
				}
				break;
			case "WEEK":
				category = category.split("-").join("-W");
				label =
					moment(category).format("DD MMM") + " - " + moment(category).add(6, "days").format("DD MMM, YYYY");
				break;
			case "DAY":
				if (
					dateTypeSelected.label === "Custom" &&
					!moment(category).isBetween(dates[0], dates[1], undefined, "[]")
				) {
					label = undefined;
				} else {
					label = moment(category).format("DD MMM, YYYY");
				}
				break;
			default:
				label = moment(category).format("DD MMM, YYYY");
				break;
		}
		return label;
	};

	const graphData = (data) => {
		let modifiedData = [];
		data.dataset.forEach((dataset) => {
			let obj = {};
			const label = getLabel(data?.timeUnit, dataset?.category);
			if (label !== undefined) {
				obj.name = label;
				dataset.current.forEach((attr) => {
					if (PAYOUT_STATUS_MAP[attr?.key]) {
						obj[PAYOUT_STATUS_MAP[attr?.key]] = attr?.value;
					}
				});
				modifiedData.push(obj);
			}
		});
		return modifiedData;
	};

	// sankey chart color gradients
	const colorGradients = [
		{
			source: "#0D7EA0",
			target: "#0D7EA0"
		},
		{
			source: "#0D7EA0",
			target: "#0D7EA0"
		},
		{
			source: "#0D7EA0",
			target: "#0D7EA0"
		},
		{
			source: "#0D7EA0",
			target: "#0D7EA0"
		},
		{
			source: "#0D7EA0",
			target: "#0D7EA0"
		},
		{
			source: "#0D7EA0",
			target: "#FF7557"
		},
		{
			source: "#FF7557",
			target: "#FF7557"
		},
		{
			source: "#FF7557",
			target: "#FF7557"
		},
		{
			source: "#FF7557",
			target: "#FF7557"
		},
		{
			source: "#FF7557",
			target: "#FF7557"
		},
		{
			source: "#FF7557",
			target: "#FF7557"
		},
		{
			source: "#3EC686",
			target: "#3EC686"
		},
		{
			source: "#3EC686",
			target: "#3EC686"
		},
		{
			source: "#3EC686",
			target: "#3EC686"
		},
		{
			source: "#3EC686",
			target: "#3EC686"
		}
	];

	let filterCount = 0;
	for (let f in appliedFilters) {
		if (f !== "biz_platform_id") {
			if (appliedFilters[f]) {
				filterCount++;
			} else if (appliedFilters[f]?.value && appliedFilters[f]?.value !== "") {
				filterCount++;
			}
		}
	}

	const filterOptions = data.filters
		.map((f) => {
			if (f.type === "MULTIPLE" && f.field === "biz_location_id") {
				f = {
					...f,
					isAsync: true,
					asyncOptions: configItems.stores,
					asyncLookup: storesLookup,
					updateAsyncLookup: updateItemsLookup,
					handleAsyncSearch: fetchStoresDebounced,
					isLoading: configItems.stores.isLoading,
					labelKey: "name",
					valueKey: "id"
				};
			}
			return f;
		})
		.filter((f) => f.field !== "biz_platform_id");

	const placeholderContent = {
		placeholderText: "Crunching your numbers!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-bizkpi.svg",
		placeholderSubtext:
			"View insights about your payout data with metrics and trends helping you to take data driven decisions for your business",
		size: "medium"
	};

	return (
		<div className="reconciliation-stats section-container-common">
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Stats</div>
					<div className="subheader-text">Reconcile payout stats from online platforms</div>
				</div>
			</div>
			<Topbar
				tabs={FORM_TABS}
				selectedTab={formTab}
				switchTab={(tab) => setFormTab(tab.value)}
				isStickyOnTop={true}
			/>
			<div className="recon-stats-filters">
				<SelectFilter
					options={bizPlatforms.values || []}
					field={bizPlatforms.field || "biz_platform_id"}
					currValue={currentFilters[bizPlatforms.field || "biz_platform_id"]}
					setFilter={(field, value) => setBizPlatformsFilter(field, value || null)}
					isSearchable={false}
					labelKey="valueForDisplay"
					valueKey="value"
					placeholder="Select platform"
				/>
				<NewDateCompareFilter
					showDropdown={true}
					loading={loading}
					currentDateFilter={currentDateFilter}
					appliedDateFilter={appliedDateFilter}
					updateState={updatereconciliationStatsState}
					includeAllTime={false}
					hideComparison={true}
					showSelectedDateRange={false}
					hidePresetTypes={["Today", "Yesterday", "15 D"]}
				/>
				{configItems.dimensions.width > 768 && (
					<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
						<div className="container" onClick={() => setShowFilters(true)}>
							<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
							<div className="filter-title">
								Filter
								{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
							</div>
						</div>
					</div>
				)}
			</div>
			{configItems.dimensions.width > 768 && (
				<Filters
					isOpen={showFilters}
					close={closeFilterSidebar}
					apply={applyFilters}
					clear={clearFilters}
					options={filterOptions || []}
					currentFilters={currentFilters}
					setFilter={setFilter}
				/>
			)}
			<div className="recon-graphs">
				{loading && (data?.dataset?.dataset.length === 0 || data.sankeyChart === null) && (
					<div>
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{formTab === FORM_TABS[0].value && (
					<React.Fragment>
						{data?.dataset?.dataset?.length !== 0 && (
							<ResponsiveContainer
								width="97%"
								height={
									data?.dataset?.dataset.length > 15
										? 1000
										: data?.dataset?.dataset.length > 5
										? 600
										: 400
								}
							>
								<BarChart
									width={1000}
									height={
										data?.dataset?.dataset.length > 15
											? 1000
											: data?.dataset?.dataset.length > 5
											? 600
											: 400
									}
									data={graphData(data?.dataset || [])}
									layout="vertical"
								>
									<CartesianGrid horizontal={false} strokeDasharray="3 3" />
									<XAxis
										type="number"
										tick={{ stroke: "#7B8A9A", strokeWidth: 0.1, fontSize: 12 }}
										label={{
											value: "Order count",
											position: "insideBottom",
											offset: "-20",
											fontSize: 14,
											fill: "#696a6b"
										}}
										allowDecimals={false}
									/>
									<YAxis
										dataKey="name"
										type="category"
										tick={{ stroke: "#7B8A9A", strokeWidth: 0.1, fontSize: 12 }}
										width={data?.dataset?.timeUnit === "WEEK" ? 150 : 100}
										label={{
											value: capitaliseTextStrict(data?.dataset?.timeUnit) || "",
											angle: -90,
											position: "insideLeft",
											fontSize: 14,
											fill: "#696a6b"
										}}
									/>
									<Tooltip cursor={{ fill: "#eee", fillOpacity: "0.4" }} />
									<Legend iconType="circle" verticalAlign="top" align="left" />
									{Object.values(PAYOUT_STATUS_MAP).map((status, i) => (
										<Bar
											dataKey={status}
											stackId="a"
											fill={PAYOUT_STATUS_COLORS[i]}
											maxBarSize={30}
										/>
									))}
								</BarChart>
							</ResponsiveContainer>
						)}
						{!loading && data?.dataset?.dataset?.length === 0 && <Placeholder {...placeholderContent} />}
					</React.Fragment>
				)}
				{formTab === FORM_TABS[1].value && (
					<React.Fragment>
						{data?.sankeyChart && (
							<ResponsiveContainer width="100%" height={800}>
								<Sankey
									width={1200}
									height={800}
									margin={{ top: 20, bottom: 20 }}
									data={data.sankeyChart}
									nodeWidth={10}
									nodePadding={60}
									// linkCurvature={0.61}
									iterations={0}
									link={<SankeyLink colorGradients={colorGradients} />}
									node={
										<SankeyNode
											containerWidth={700}
											colors={REVENUE_BREAKDOWN_COLORS}
											currency={currency}
										/>
									}
								>
									<Tooltip content={<SankeyTooltip currency={currency} />} />
								</Sankey>
							</ResponsiveContainer>
						)}
						{!loading && data?.sankeyChart === null && <Placeholder {...placeholderContent} />}
					</React.Fragment>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	reconciliationStats: store.reconciliationStats,
	reconciliationStatsState: store.reconciliationStatsState,
	configItems: store.configItems,
	currency: store.login.loggedInbizDetail.currency
});
export default connect(mapStateToProps)(ReconciliationStats);

const SankeyNode = ({ x, y, width, height, index, payload, containerWidth, colors, currency = "INR" }) => {
	const isOut = x + width + 6 > containerWidth;
	height = height > 0.4 ? height : 0.4;
	return (
		<Layer key={`CustomNode${index}`}>
			<Rectangle x={x} y={y} width={width} height={height} fill={colors[index % colors.length]} fillOpacity="1" />
			<text
				textAnchor={isOut ? "end" : "start"}
				x={isOut ? x - 6 : x + width + 6}
				y={y + height / 2}
				fontSize="14"
				stroke="#333"
			>
				{payload.depth !== 1 || payload.name !== "Order Level Adjustments" ? payload.name : ""}
			</text>
			<text
				textAnchor={isOut ? "end" : "start"}
				x={isOut ? x - 6 : x + width + 6}
				y={y + height / 2 + 13}
				fontSize="12"
				stroke="#333"
				strokeOpacity="0.5"
			>
				{payload.depth !== 1 || payload.name !== "Order Level Adjustments"
					? payload.value.toLocaleString(currency === "INR" ? "en-IN" : "en-US", {
							maximumFractionDigits: 0,
							style: "currency",
							currency: currency
					  })
					: ""}
			</text>
		</Layer>
	);
};

const SankeyLink = ({
	sourceX,
	targetX,
	sourceY,
	targetY,
	sourceControlX,
	targetControlX,
	linkWidth,
	index,
	colorGradients
}) => {
	const gradientID = `linkGradient${index}`;
	linkWidth = linkWidth > 0.4 ? linkWidth : 0.4;
	return (
		<Layer key={`CustomLink${index}`}>
			<defs>
				<linearGradient id={gradientID} x1="0" x2="0.2">
					<stop offset="0%" stopOpacity={0.4} stopColor={colorGradients[index]?.source} />
					<stop offset="100%" stopOpacity={0.4} stopColor={colorGradients[index]?.target} />
				</linearGradient>
			</defs>
			<path
				d={`
					M${sourceX},${sourceY + linkWidth / 2}
					C${sourceControlX},${sourceY + linkWidth / 2}
						${targetControlX},${targetY + linkWidth / 2}
						${targetX},${targetY + linkWidth / 2}
					L${targetX},${targetY - linkWidth / 2}
					C${targetControlX},${targetY - linkWidth / 2}
						${sourceControlX},${sourceY - linkWidth / 2}
						${sourceX},${sourceY - linkWidth / 2}
					Z
				`}
				fill={`url(#${gradientID})`}
				strokeWidth="0"
			/>
		</Layer>
	);
};

const SankeyTooltip = ({ active, payload, currency = "INR" }) => {
	if (active && payload?.length) {
		const value = payload[0]?.value?.toLocaleString(currency === "INR" ? "en-IN" : "en-US", {
			maximumFractionDigits: 0,
			style: "currency",
			currency: currency
		});
		return (
			<div className="sankey-tooltip">
				{payload[0]?.name === "Order Level Adjustments - Order Level Adjustments"
					? `Order Total - Order Level Adjustments : ${value}`
					: `${payload[0]?.name} : ${value}`}
			</div>
		);
	}
	return null;
};
