import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { Textarea } from "../_commons/Textarea";

// third party
import { connect } from "react-redux";
import moment from "moment";

// actions
import { fetchTimingGroupsDebounced } from "../../actions/actions";

const BasicInfo = ({ timingGroups, data = {}, platforms = [], handleForm, validations, biz }) => {
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<SelectFilter
					title="Platform"
					options={platforms}
					field="platformName"
					currValue={data.platformName}
					setFilter={(field, value) =>
						handleForm({
							[field]: value,
							type: value.discountTypes ? value.discountTypes[0] : null
						})
					}
					labelKey="valueForDisplay"
					valueKey="value"
					requiredLabel={true}
					isClearable={false}
				/>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.title || ""}
					onChange={(e) => handleForm({ title: e.target.value })}
					requiredLabel={true}
					validationMessage={validations.title || ""}
				>
					Title
				</InputWithLabel>
				<SelectFilter
					title="Type"
					options={data.platformName ? data.platformName.discountTypes : []}
					field="type"
					currValue={data.type}
					setFilter={(field, value) => handleForm({ [field]: value })}
					labelKey="valueForDisplay"
					valueKey="value"
					requiredLabel={true}
					isClearable={false}
					validationMessage={validations.type || ""}
				/>
			</div>
			{data?.type?.value === "BXGY" && (
				<div className="form-row row-half">
					<InputWithLabel
						value={data.buyCount || ""}
						type="number"
						onChange={(e) =>
							handleForm({
								buyCount: e.target.value
									? parseInt(e.target.value) < 0
										? 0
										: parseInt(e.target.value)
									: null
							})
						}
						validationMessage={validations.buyCount || ""}
					>
						Buy Count
					</InputWithLabel>
					<InputWithLabel
						value={data.freeCount || ""}
						type="number"
						onChange={(e) =>
							handleForm({
								freeCount: e.target.value
									? parseInt(e.target.value) < 0
										? 0
										: parseInt(e.target.value)
									: null
							})
						}
						validationMessage={validations.freeCount || ""}
					>
						Free Count
					</InputWithLabel>
				</div>
			)}
			{data?.type?.value === "FREEBIE" && (
				<div className="form-row row-half">
					<InputWithLabel
						value={data.freebieLimitCount || ""}
						type="number"
						onChange={(e) =>
							handleForm({
								freebieLimitCount: e.target.value
									? parseInt(e.target.value) < 0
										? 0
										: parseInt(e.target.value)
									: null
							})
						}
						validationMessage={validations.freebieLimitCount || ""}
					>
						Freebie Limit Count
					</InputWithLabel>
				</div>
			)}
			<div className="form-row row-half">
				<InputWithLabel
					value={data.startsOn || ""}
					onChange={(e) => handleForm({ startsOn: e.target.value })}
					onFocus={(e) =>
						handleForm({
							startsOn: e.target.value ? e.target.value : moment().format("YYYY-MM-DD HH:mm:ss")
						})
					}
					requiredLabel={true}
					validationMessage={validations.startsOn || ""}
					placeholder="YYYY-MM-DD HH:MM:SS"
				>
					Starts on
				</InputWithLabel>
				<InputWithLabel
					value={data.expiresOn || ""}
					onChange={(e) => handleForm({ expiresOn: e.target.value })}
					onFocus={(e) =>
						handleForm({
							expiresOn: e.target.value ? e.target.value : moment().format("YYYY-MM-DD HH:mm:ss")
						})
					}
					requiredLabel={true}
					validationMessage={validations.expiresOn || ""}
					placeholder="YYYY-MM-DD HH:MM:SS"
				>
					Ends on
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.value || ""}
					type="number"
					onChange={(e) =>
						handleForm({
							value: e.target.value ? (Number(e.target.value) < 0 ? 0 : Number(e.target.value)) : null
						})
					}
					validationMessage={validations.value || ""}
				>
					Value
				</InputWithLabel>
				{data.platformName && data.platformName.value === "ZOMATO" && (
					<InputWithLabel
						value={data.minOrderAmount || ""}
						type="number"
						onChange={(e) =>
							handleForm({
								minOrderAmount: e.target.value
									? Number(e.target.value) < 0
										? 0
										: Number(e.target.value)
									: null
							})
						}
						validationMessage={validations.minOrderAmount || ""}
						showLabel={true}
						classes="at-input--label"
						currency={true}
						currencySymbol={biz.currencySymbol}
					>
						Minimum order amount
					</InputWithLabel>
				)}
				{data.platformName && data.platformName.value === "UBEREATS" && (
					<Switch
						title="Process on catalogue"
						checked={data.processOnCatalogue}
						clickHandler={() => handleForm({ processOnCatalogue: !data.processOnCatalogue })}
						validationMessage={validations.processOnCatalogue || ""}
					/>
				)}
			</div>
			{data.platformName && data.platformName.value === "ZOMATO" && (
				<div className="form-row row-half">
					<SelectFilter
						title="Timings group"
						options={timingGroups.items}
						isLoading={timingGroups.isLoading}
						field="timingsGroup"
						currValue={data.timingsGroup}
						setFilter={(field, value) => handleForm({ [field]: value })}
						labelKey="title"
						valueKey="id"
						isAsync={true}
						handleSearch={fetchTimingGroupsDebounced}
					/>
					<Switch
						title="Single use only"
						checked={data.oneTimeUse}
						clickHandler={() => handleForm({ oneTimeUse: !data.oneTimeUse })}
						validationMessage={validations.oneTimeUse || ""}
					/>
				</div>
			)}
			<div className="form-row row-full">
				<Textarea value={data.desc || ""} onChange={(e) => handleForm({ desc: e.target.value })}>
					Description
				</Textarea>
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	timingGroups: store.configItems.timingGroups
});
export default connect(mapStateToProps)(BasicInfo);
