import gql from "graphql-tag";

export const GET_CATALOGUE_VERIFICATION_LIST = gql`
	query getCatalogueVerificationList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		verificationLogResult(filters: $filters) {
			overallStatus
			entities(limit: $limit, offset: $offset, filters: $filters, search: $search) {
				limit
				offset
				count
				errorCount
				warningCount
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				searchKeywords {
					key
					valueForDisplay
				}
				objects {
					entity
					entityId
					name
					status
					platformName
					crmTitle
					messages
				}
			}
		}
	}
`;

export const GET_PRISM_VERIFICATION_REPORT = gql`
	query getPrismVerificationReport(
		$bizLocationId: Int!
		$platformName: String!
		$brandId: Int
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		prismVerificationReport(bizLocationId: $bizLocationId, platformName: $platformName, brandId: $brandId) {
			referenceId
			status
			message
			report(limit: $limit, offset: $offset, filters: $filters, search: $search) {
				limit
				offset
				count
				errorCount
				warningCount
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				searchKeywords {
					key
					valueForDisplay
				}
				objects {
					entity
					entityId
					name
					status
					crmTitle
					messages
				}
			}
		}
	}
`;
