import React, { useEffect, useState } from "react";

const RangeSlider = ({ min = 0, max = 100, value = 20, setValue = () => {} }) => {
	const [inlineStyles, SetInlineStyles] = useState({
		backgroundSize: `${(value / max) * 100}% 100%`
	});

	const handleValueUpdate = (val) => {
		SetInlineStyles({
			backgroundSize: `${(val / max) * 100}% 100%`
		});

		setValue(val);
	};

	useEffect(() => {
		SetInlineStyles({
			backgroundSize: `${(value / max) * 100}% 100%`
		});
	}, [value]);
	return (
		<div className="range-slider-container">
			<input
				type="range"
				min={min}
				max={max}
				value={value}
				className="at-range-slider"
				style={inlineStyles}
				onChange={(e) => handleValueUpdate(e.target.value)}
				// ref={rangeSelectorComponentRef}
			/>
			<div className="current-value">{value}</div>
			<div className="max-value">{max}</div>
		</div>
	);
};

export default RangeSlider;
