import gql from "graphql-tag";

export const GET_ROLES_LIST = gql`
	query getRolesList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		groups(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				name
				numUsers
			}
		}
	}
`;

export const GET_ROLE_PERMISSIONS = gql`
	query getRolePermissions($id: Int) {
		group(id: $id) {
			id
			name
			permissions {
				title
				description
			}
		}
	}
`;

export const GET_ROLE_USERS = gql`
	query getRoleUsers($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		group(id: $id) {
			users(limit: $limit, offset: $offset, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					username
					phone
					isdCode
					email
					uleEmail
					ulePhone
					uleIsdCode
					fullName
				}
			}
		}
	}
`;
