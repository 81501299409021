import React, { useState, useEffect, useRef } from "react";

// components
import { Topbar } from "../_commons/Topbar";
import { Button } from "../_commons/Button";
import Users from "../../containers/Users";
import Roles from "../../containers/Roles";
import Invites from "../../containers/Invites";
import UnifiedUserInvite from "../../containers/UnifiedUserInvite";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";

// third party
import history from "../../history";
import { Link, Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// store
import { store } from "../../store/configureStore";

// utils
import { scroll, adjustNestedContainer, trackEvent } from "../../atlas-utils";

// assets
import CreateIcon from "../_commons/CreateIcon";
import HelpIcon from "../_commons/HelpIcon";

// config
import { NESTED_ENTITY_TYPES } from "../../client-config";

// constants
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const FORM_TABS = [
	{
		label: "Users",
		value: "users"
	},
	{
		label: "Atlas Roles",
		value: "roles"
	},
	{
		label: "Pending Invites",
		value: "invites"
	}
];
const HELP_SECTION_FORM_TABS = [
	{
		label: "Help Video",
		value: "help",
		render: "default"
	},
	{
		label: "Contact Support",
		value: "support",
		render: () => {
			const user = store.getState().login.loginDetail;
			const biz = store.getState().login.loggedInbizDetail;
			const url = `https://docs.google.com/forms/d/e/1FAIpQLSdvAVdphUPw0s93pOgdjVgarMEm8wrOhRvUlCDnoAdNkeNkiw/viewform?usp=pp_url&${`entry.485428648=${
				user.fullName || ""
			}&`}${`entry.1433034111=${
				user.email || user.verificationDetails.email || user.verificationDetails.phone
			}&`}${`entry.24550726=${biz.name}&`}entry.757657104=Atlas+website`;

			const handleOpenUrl = (url, target = "_self") => {
				if (url) {
					// track event
					const eventName = "user_access_contact_support";
					const eventMeta = {
						name: user.fullName,
						credential: user.email || user.verificationDetails.email || user.verificationDetails.phone,
						from: "Users List"
					};
					trackEvent(eventName, eventMeta);

					window.open(url, target).focus();
				}
			};

			return (
				<div className="contact-support">
					<img src="/assets/empty_states/graphics-empty-segment.svg" alt="" />
					<div className="sub-header-text">Trouble with user actions?</div>
					<div className="description">Raise a support ticket!</div>
					<Button clickHandler={() => handleOpenUrl(url, "_blank")}>Contact Support</Button>
				</div>
			);
		}
	}
];

const UnifiedUserManagementContainer = () => {
	const params = new URLSearchParams(useLocation().search);
	const [formTab, setFormTab] = useState(params.get("tab") || FORM_TABS[0].value);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const topRef = useRef();

	useEffect(() => {
		if (params.get("tab")) {
			setFormTab(params.get("tab"));
		}
	}, [useLocation().search]);

	const switchTab = (tab) => {
		setFormTab(tab.value);
		history.push("/unified-access");
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="unified-user-management-section section-container-common" ref={topRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Users</div>
					<div className="subheader-text">View and manage your user information, roles and staff invites</div>
				</div>
				<div className="actions">
					<div className="help-btn-container">
						<Button clickHandler={handlePiperAcademy} type="secondary">
							<HelpIcon />
							<span>Help</span>
						</Button>
					</div>
					<div className="action-buttons-container">
						<Link to="/unified-access/invite">
							<Button>
								<CreateIcon />
								<span>Invite New User</span>
							</Button>
						</Link>
					</div>
				</div>
			</div>
			<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
			<div className="billing-container">
				{formTab === FORM_TABS[0].value && <Users />}
				{formTab === FORM_TABS[1].value && <Roles />}
				{formTab === FORM_TABS[2].value && <Invites />}
				<Route exact path="/unified-access/invite" render={() => <UnifiedUserInvite currTab={formTab} />} />
			</div>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
				showTopBar={false}
				// tabs={HELP_SECTION_FORM_TABS}
			/>
		</div>
	);
};
export default UnifiedUserManagementContainer;
