import React, { Component } from "react";

// third party
import Select from "react-select-animated-v2";
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";

// clients
import { store } from "../../store/configureStore";

const MAX_DATE_RANGE_ALLOWED = 365;
const DATE_TYPES = [
	{ label: "Presets", value: "preset_duration" },
	{ label: "Custom", value: "range" }
];
export const PRESET_TYPES = [
	{ label: "All time", value: "" },
	{ label: "Today", value: "TODAY" },
	{ label: "Yesterday", value: "YESTERDAY" },
	{ label: "This week", value: "THIS_WEEK" },
	{ label: "This month", value: "THIS_MONTH" },
	{ label: "Last 7 days", value: "LAST_7_DAYS" },
	{ label: "Last 30 days", value: "LAST_30_DAYS" },
	{ label: "Last 90 days", value: "LAST_90_DAYS" },
	{ label: "This year", value: "THIS_YEAR" }
];
const CUSTOM_TYPES = [
	{ label: "Is between", value: "between" },
	{ label: "Is after", value: "greater_than" },
	{ label: "Is before", value: "less_than" }
];

@connect((store) => ({
	dimensions: store.configItems.dimensions
}))
export class DateFilter extends Component {
	changeDateType = (dateTypeSelected) => {
		switch (dateTypeSelected.value) {
			case DATE_TYPES[0].value:
				this.props.updateState({
					currentDateFilter: {
						...this.props.currentDateFilter,
						dateTypeSelected,
						presetTypeSelected: PRESET_TYPES[0],
						dateFilter: PRESET_TYPES[0].value
					}
				});
				break;
			case DATE_TYPES[1].value:
				const rangeStartDate = moment().subtract(45, "d");
				const rangeEndDate = moment();
				const start = rangeStartDate.format("YYYY-MM-DD");
				const end = rangeEndDate.format("YYYY-MM-DD");
				this.props.updateState({
					currentDateFilter: {
						...this.props.currentDateFilter,
						dateTypeSelected,
						rangeStartDate,
						rangeEndDate,
						customTypeSelected: CUSTOM_TYPES[0],
						dateFilter: `${start},${end}`
					}
				});
				break;
			default:
				break;
		}
	};

	changePresetType = (presetTypeSelected) => {
		this.props.updateState({
			currentDateFilter: {
				...this.props.currentDateFilter,
				presetTypeSelected,
				dateFilter: presetTypeSelected.value
			}
		});
	};

	changeCustomType = (customTypeSelected) => {
		let dateObject = {};
		switch (customTypeSelected.value) {
			case CUSTOM_TYPES[0].value:
				dateObject = {
					rangeStartDate: moment().subtract(45, "d"),
					rangeEndDate: moment()
				};
				break;
			case CUSTOM_TYPES[1].value:
				dateObject = {
					rangeStartDate: moment().subtract(45, "d"),
					rangeEndDate: undefined
				};
				break;
			case CUSTOM_TYPES[2].value:
				dateObject = {
					rangeStartDate: undefined,
					rangeEndDate: moment()
				};
			default:
				break;
		}
		this.props.updateState({
			currentDateFilter: {
				...this.props.currentDateFilter,
				...dateObject,
				customTypeSelected
			}
		});
		this.setCustomDate();
	};

	setCustomDate = () => {
		const { currentDateFilter } = store.getState()[this.props.storeRootField];
		if (this.props.field === undefined) {
			const { rangeStartDate, rangeEndDate } = currentDateFilter;
			const start = rangeStartDate ? rangeStartDate.format("YYYY-MM-DD") : "";
			const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
			this.props.updateState({
				currentDateFilter: {
					...currentDateFilter,
					dateFilter: `${start},${end}`
				}
			});
		} else {
			const { rangeStartDate, rangeEndDate } = currentDateFilter[this.props.field];
			const start = rangeStartDate ? rangeStartDate.format("YYYY-MM-DD") : "";
			const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
			this.props.updateState({
				currentDateFilter: {
					...currentDateFilter[this.props.field],
					dateFilter: `${start},${end}`
				}
			});
		}
	};

	handleStartEndDate = (e, whichDate) => {
		let { currentDateFilter } = this.props;
		const { rangeEndDate } = currentDateFilter;
		if (
			currentDateFilter.customTypeSelected.value === CUSTOM_TYPES[0].value &&
			whichDate === "rangeStartDate" &&
			rangeEndDate &&
			rangeEndDate.diff(e, "d") > MAX_DATE_RANGE_ALLOWED
		) {
			currentDateFilter.rangeEndDate = undefined;
		}
		this.props.updateState({
			currentDateFilter: {
				...currentDateFilter,
				[whichDate]: e
			}
		});
		this.setCustomDate();
	};

	render() {
		const { title, horizontalPosition, currentDateFilter, dimensions } = this.props;
		const { dateTypeSelected, presetTypeSelected, customTypeSelected, rangeStartDate, rangeEndDate, dateFilter } =
			currentDateFilter;
		const filterApplied = dateFilter || dateFilter === "" ? true : false;

		// set default value for date-picker formatting
		const dateFormat = "DD MMM, YYYY";
		const dateFormatCalender = "DD MMMM";

		let dateTypes = DATE_TYPES;
		if (dimensions.width <= 768) {
			dateTypes[1].disabled = true;
		} else {
			dateTypes[1].disabled = false;
		}
		let rangeEndDateMax;
		if (customTypeSelected.value === CUSTOM_TYPES[0].value && dateTypeSelected.value === DATE_TYPES[1].value) {
			let rangeEndDateMaxTemp = rangeStartDate.clone().add(MAX_DATE_RANGE_ALLOWED, "d");
			rangeEndDateMax = moment.min(rangeEndDateMaxTemp, moment());
		}

		return (
			<div
				className={
					"date-filter-container filter-section-item" +
					(filterApplied ? " filter-applied" : "") +
					(horizontalPosition ? " data-filter-horizontal" : "")
				}
			>
				{dateTypeSelected.value === DATE_TYPES[1].value && (
					<React.Fragment>
						{customTypeSelected.value !== CUSTOM_TYPES[2].value && (
							<div
								className={
									"calender-range-first " +
									(dateTypeSelected.value !== DATE_TYPES[1].value ||
									customTypeSelected.value === CUSTOM_TYPES[2].value
										? "disabled"
										: "")
								}
							>
								<DatePicker
									inline
									fixedHeight
									selected={rangeStartDate}
									selectsStart
									startDate={rangeStartDate}
									endDate={rangeEndDate}
									onChange={(e) => this.handleStartEndDate(e, "rangeStartDate")}
									dateFormat={dateFormat}
									dateFormatCalendar={dateFormatCalender}
									showYearDropdown
									scrollableYearDropdown
									yearDropdownItemNumber={10}
									maxDate={rangeEndDate || moment()}
								/>
							</div>
						)}
						{customTypeSelected.value !== CUSTOM_TYPES[1].value && (
							<div
								className={
									"calender-range-second " +
									(dateTypeSelected.value !== DATE_TYPES[1].value ||
									customTypeSelected.value === CUSTOM_TYPES[1].value
										? "disabled"
										: "")
								}
							>
								<DatePicker
									inline
									fixedHeight
									openToDate={rangeEndDate || rangeEndDateMax || moment()}
									selected={rangeEndDate}
									selectsEnd
									startDate={rangeStartDate}
									endDate={rangeEndDate}
									minDate={rangeStartDate}
									onChange={(e) => this.handleStartEndDate(e, "rangeEndDate")}
									dateFormat={dateFormat}
									dateFormatCalendar={dateFormatCalender}
									showYearDropdown
									scrollableYearDropdown
									yearDropdownItemNumber={10}
									maxDate={rangeEndDateMax || moment()}
								/>
							</div>
						)}
					</React.Fragment>
				)}
				<div className="date-filter-action-container">
					<div className="meta-info">{title}</div>
					<div className="type-selector">
						<Select
							className="at--dropdown"
							classNamePrefix="react-select"
							options={dateTypes}
							value={dateTypeSelected}
							onChange={(m) => this.changeDateType(m)}
							isSearchable={false}
							isClearable={false}
							getOptionLabel={(option) => option["label"]}
							getOptionValue={(option) => option["value"]}
						/>
						<div className="type-selected">
							{dateTypeSelected.value === DATE_TYPES[0].value && (
								<Select
									className="at--dropdown"
									classNamePrefix="react-select"
									options={PRESET_TYPES}
									value={presetTypeSelected}
									onChange={(m) => this.changePresetType(m)}
									isSearchable={false}
									isClearable={false}
									getOptionLabel={(option) => option["label"]}
									getOptionValue={(option) => option["value"]}
								/>
							)}
							{dateTypeSelected.value === DATE_TYPES[1].value && (
								<Select
									className="at--dropdown"
									classNamePrefix="react-select"
									options={CUSTOM_TYPES}
									value={customTypeSelected}
									onChange={(m) => this.changeCustomType(m)}
									isSearchable={false}
									isClearable={false}
									getOptionLabel={(option) => option["label"]}
									getOptionValue={(option) => option["value"]}
								/>
							)}
						</div>
					</div>
					<div className="date-filter-action">
						<div className="apply" onClick={this.props.applyDateRange}>
							Apply
						</div>
						<div className="cancel" onClick={this.props.handleDropdown}>
							Cancel
						</div>
					</div>
				</div>
			</div>
		);
	}
}
