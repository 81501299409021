import React, { useState, useEffect, useMemo, useRef } from "react";

// components
import Header from "./Header";
import Breadcrumbs from "../../_commons/Breadcrumbs";
import SectionHeader from "./SectionHeader";
import Filters from "./Filters";
import { Topbar } from "../../_commons/Topbar";
import { NestedEntityContainer } from "../../_commons/NestedEntityContainer";

// entity
import ItemPerformance from "../Catalogue/ItemPerformance";

// third party
import { useLocation } from "react-router-dom";
import history from "../../../history";

// utils
import { breadcrumbs, scroll, capitaliseTextStrict, trackEvent, adjustNestedContainer } from "../../../atlas-utils";

// store
import { store } from "../../../store/configureStore";

// actions
import { ActionTypes } from "../../../actions/_types";

// constants
import { ANALYTICS_SECTIONS, NESTED_ENTITY_TYPES } from "../../../client-config";
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const ENTITY_HELP_VIDEO_TIMESTAMP_MAP = {
	"item-performance": "03:51"
};

const EntityDetail = ({ match }) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [section, setSection] = useState(`${match.url.split("/")[2]}`);
	const [entity, setEntity] = useState(match.params.entity);
	const [initTabs, setInitTabs] = useState(ANALYTICS_SECTIONS[entity] ? ANALYTICS_SECTIONS[entity]?.["tabs"] : []);
	const [tabs, setTabs] = useState(ANALYTICS_SECTIONS[entity] ? ANALYTICS_SECTIONS[entity]?.["tabs"] : []);
	const [currTab, setCurrTab] = useState(query.get("metric") || (tabs.length > 0 ? tabs[0]?.value : undefined));
	const [bcLinks, setBcLinks] = useState(query.get("bc") ? breadcrumbs.decode(query.get("bc")) : []);
	const [isPageScrolled, setPageScrolled] = useState(false);
	const [entityLoading, setEntityLoading] = useState(true);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [filters, setFilters] = useState({
		showBrands: false,
		showLocations: false,
		showPlatforms: false,
		showDateCompare: false,
		module: capitaliseTextStrict(section)
	});
	const nestedRef = useRef();
	const refs = useRef({});

	const switchTab = (tab, tabClick = false) => {
		setCurrTab(tab.value);
		window.requestAnimationFrame(() => {
			scroll(
				{
					top: refs.current[tab.value]?.offsetTop - (document.querySelector(".with-banner") ? 325 : 265),
					left: 0
				},
				window,
				"instant"
			);
		});
		// track event
		if (tabClick) {
			trackEvent("analytics_tab_clicked", {
				module: capitaliseTextStrict(entity?.split("-")?.join(" ")),
				from: capitaliseTextStrict(section),
				tab: tab.label.includes("Variant Performance") ? "Variant Performance" : tab.label
			});
		}
	};

	const handlePageScroll = () => {
		if (document.documentElement.scrollTop > 60) {
			setPageScrolled(true);
		} else {
			setPageScrolled(false);
		}
	};

	useEffect(() => {
		// if metric param exists in url, scroll to that metric
		if (query.get("metric")) {
			switchTab({ value: query.get("metric") });
		} else {
			document.documentElement.scrollTop = 0;
		}
		window.addEventListener("scroll", handlePageScroll);
		return () => {
			window.removeEventListener("scroll", handlePageScroll);
			// reset entity detail state
			store.dispatch({
				type: ActionTypes.RESET_ANALYTICS_ENTITY_DETAIL_STATE
			});
		};
	}, []);

	const handleViewAction = (pathname, entity, api, metric) => {
		// update breadcrumbs
		let updatedBc = [...bcLinks];
		const entityName = window?.location?.pathname?.split("/")?.[5] || "";
		updatedBc.push({
			title: decodeURIComponent(entityName)?.replaceAll("percent", "%"),
			to: `${match.url}${`?metric=${metric}`}`
		});
		const url = `${pathname}/${entity}/${api}${`?filters=${query.get("filters")}`}${`&bc=${breadcrumbs.encode(
			updatedBc
		)}`}`;
		history.push(url);
	};

	const renderEntity = useMemo(() => {
		let EntityComponent = null;
		let props = {
			match,
			query,
			section,
			refs,
			setCurrTab,
			tabs: initTabs,
			setTabs,
			setInitTabs,
			handleViewAction
		};

		switch (entity) {
			case "item-performance":
				// show filters
				setFilters({
					showBrands: true,
					showLocations: true,
					showPlatforms: true,
					showDateCompare: true
				});
				// set component
				EntityComponent = ItemPerformance;
				break;
			default:
				break;
		}

		if (EntityComponent) {
			setEntityLoading(false);
			return <EntityComponent {...props} />;
		}

		setTimeout(() => {
			setEntityLoading(false);
		}, 500);
		return null;
	}, [entity, section, initTabs]);

	const getEntitySectionHeaderTitle = () => {
		const entityName = window?.location?.pathname?.split("/")?.[5] || "";
		const decodedEntityName = decodeURIComponent(entityName)?.replaceAll("percent", "%");
		return decodedEntityName;
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "analytics",
				start: ENTITY_HELP_VIDEO_TIMESTAMP_MAP[entity] || ""
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className={`analytics-entity-detail section-container-common` + (isPageScrolled ? " scrolled" : "")}>
			<Header>
				<Breadcrumbs
					connectedLinks={bcLinks?.map((link, i) => ({
						...link,
						to: `${link.to}${`${link.to.includes("?") ? "&" : "?"}filters=${query.get("filters")}`}${
							i > 0 ? `&bc=${breadcrumbs.encode(bcLinks.slice(0, i))}` : ""
						}`
					}))}
				/>
				<SectionHeader
					title={getEntitySectionHeaderTitle()}
					showHelpBtn={true}
					handlePiperAcademy={handlePiperAcademy}
					isPageScrolled={renderEntity && isPageScrolled}
				/>
				<Filters {...filters} />
				<Topbar
					tabs={tabs.filter((tab) => !tab.hidden)}
					selectedTab={currTab}
					switchTab={(tab) => switchTab(tab, true)}
				/>
			</Header>
			{entityLoading ? (
				<div className="entity-detail-loading Pstart(25px) Pend(25px)">
					<div className={`shimmer W(100%) H(600px)`} />
				</div>
			) : renderEntity ? (
				renderEntity
			) : (
				<div className="empty-search-placeholder">
					<img src="/assets/empty-states/analytics-search.svg" alt="" />
					<div className="message">No results found!</div>
				</div>
			)}
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default EntityDetail;
