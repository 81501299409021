import React from "react";

// components
import { FilterSidebar } from "./FilterSidebar";
import { SelectFilter } from "./SelectFilter";
import { MultiSelectFilter } from "./MultiSelectFilter";
import { NumberRangeFilter } from "./NumberRangeFilter";
import { InputWithLabel } from "./InputWithLabel";
import { Switch } from "./Switch";
import { DateFilter } from "./DateFilter";
import DropdownCustom from "./DropdownCustom";

// actions
import { getDateFilterLabel } from "../../actions/actions";

export const Filters = ({
	isOpen,
	close,
	apply,
	clear,
	options = [],
	currentFilters,
	setFilter,
	isNested = false,
	isFilters = false,
	...rest
}) => {
	return (
		<FilterSidebar
			isOpen={isOpen}
			close={close}
			apply={apply}
			clear={clear}
			isNested={isNested}
			isFilters={isFilters}
		>
			{options.map((opt, i) => {
				if (opt === null || opt.hide) {
					return null;
				} else if (opt.type === "MULTIPLE" && opt.isAsync) {
					return (
						<MultiSelectFilter
							key={i}
							title={opt.valueForDisplay}
							options={opt.asyncOptions.items || []}
							field={opt.field}
							currValue={currentFilters[opt.field] || {}}
							setFilter={setFilter}
							isLoading={opt.asyncOptions.isLoading}
							isAsync={true}
							asyncLookup={opt.asyncLookup}
							updateLookup={opt.updateAsyncLookup}
							handleSearch={opt.handleAsyncSearch}
							labelKey={opt.labelKey || "title"}
							valueKey={opt.valueKey || "id"}
						/>
					);
				} else if (opt.type === "MULTIPLE") {
					return (
						<MultiSelectFilter
							key={i}
							title={opt.valueForDisplay}
							options={opt.values || []}
							field={opt.field}
							currValue={currentFilters[opt.field] || {}}
							setFilter={setFilter}
							labelKey={opt.labelKey || "valueForDisplay"}
							valueKey={opt.valueKey || "value"}
							showCustomTooltip={opt.showCustomTooltip}
							tooltipInfo={opt.tooltipInfo}
							tooltipPosition={opt.tooltipPosition}
						/>
					);
				} else if (opt.type === "RANGE") {
					return (
						<NumberRangeFilter
							key={i}
							title={opt.valueForDisplay}
							field={opt.field}
							currValue={currentFilters[opt.field] || {}}
							setFilter={setFilter}
						/>
					);
				} else if (opt.type === "SINGLE") {
					return (
						<InputWithLabel
							key={i}
							isFilterItem={true}
							value={currentFilters[opt.field] || ""}
							onChange={(e) => setFilter(opt.field, e.target.value)}
							placeholder={`Enter ${opt.valueForDisplay}`}
						>
							{opt.valueForDisplay}
						</InputWithLabel>
					);
				} else if (opt.type === "SINGLE_STRICT") {
					return (
						<SelectFilter
							key={i}
							title={opt.valueForDisplay}
							options={opt.values || []}
							field={opt.field}
							currValue={currentFilters[opt.field] || null}
							setFilter={setFilter}
							isLoading={opt.isLoading}
							isAsync={opt.isAsync}
							handleSearch={opt.handleAsyncSearch}
							labelKey={opt.labelKey || "valueForDisplay"}
							valueKey={opt.valueKey || "value"}
							filterClass={true}
						/>
					);
				} else if (opt.type === "BOOL") {
					return (
						<Switch
							key={i}
							title={opt.valueForDisplay}
							checked={String(currentFilters[opt.field])?.toLowerCase() === "true" ? true : false}
							clickHandler={() =>
								setFilter(
									opt.field,
									String(!(String(currentFilters[opt.field])?.toLowerCase() === "true"))
								)
							}
							classes={"filter-section-item " + (currentFilters[opt.field] ? "active" : "")}
						/>
					);
				} else if (opt.type === "DATE") {
					return (
						<DropdownCustom
							key={i}
							title={opt.valueForDisplay}
							selected={getDateFilterLabel(rest.currentDateFilter[opt.field])}
							isOpen={rest.isDropdownOpen[opt.field]}
							handleClick={(e) => rest.handleDropdown(e, opt.field)}
							handleOutsideClick={() => rest.closeDropdown(opt.field)}
							clickEvent="mousedown"
						>
							{rest.isDropdownOpen[opt.field] && (
								<DateFilter
									title="Date Range"
									horizontalPosition={true}
									handleDropdown={(e) => rest.handleDropdown(e, opt.field)}
									applyDateRange={() => rest.applyDateRange(opt.field)}
									currentDateFilter={rest.currentDateFilter[opt.field]}
									updateState={(payload) => rest.updateListState(payload, opt.field)}
									storeRootField={rest.storeRootField}
									field={opt.field}
								/>
							)}
						</DropdownCustom>
					);
				}
			})}
		</FilterSidebar>
	);
};
