import React, { useState, useEffect, useCallback, useRef } from "react";

// third party
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTrail, config, animated } from "react-spring";
import moment from "moment";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchCustomerFeedbacks, updateFeedbacksListState } from "../../actions/customers";

// clients
import { store } from "../../store/configureStore";

// utils
import { scroll } from "../../atlas-utils";

// components
import { Paginator } from "../_commons/Paginator";
import Replies from "../CustomerFeedback/Replies";

// constants
import { CUSTOMER_FEEDBACKS_HEADER_FIELDS, NESTED_ENTITY_TYPES } from "../../client-config";
const REPLIES_INITIAL_STATE = {
	isOpen: false,
	id: null,
	name: null,
	comments: null,
	rating: null,
	replies: []
};

const Feedback = ({ customerFeedbacks, customerId, customerName, handleNestedEntity }) => {
	const [replies, setRplies] = useState(REPLIES_INITIAL_STATE);
	const { loading, limit, offset, sort, data } = customerFeedbacks;
	const tableRef = useRef();

	useEffect(() => {
		fetchCustomerFeedbacks(customerId);
	}, [customerId]);

	const handlePagination = useCallback(
		(page) => {
			const offset = (page - 1) * limit;
			updateFeedbacksListState({
				offset
			});
			fetchCustomerFeedbacks(customerId);
			// scroll to top of the list
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit, customerId]
	);

	const sortList = useCallback(
		(field) => {
			const sort = {
				field
			};
			updateFeedbacksListState({
				offset: 0
			});
			store.dispatch({
				type: ActionTypes.SORT_CUSTOMER_FEEDBACKS,
				payload: {
					sort
				}
			});
			fetchCustomerFeedbacks(customerId);
		},
		[customerId]
	);

	const openRepliesSidebar = (id, name, comments, rating, replies = []) => {
		setRplies({
			isOpen: true,
			id,
			name,
			comments,
			rating,
			replies
		});
	};

	const closeRepliesSidebar = useCallback(
		(refresh = false) => {
			setRplies(REPLIES_INITIAL_STATE);
			fetchCustomerFeedbacks(customerId);
		},
		[customerId]
	);

	return (
		<div ref={tableRef} className="customer-feedbacks customer-details-tab">
			<div className="subtitle">
				Browse through all the feedbacks given by the customer. Also, take actions on the feedbacks given by
				sending an SMS/ Email to the customer.
			</div>
			<Table
				data={data.objects || []}
				loading={loading}
				sortList={sortList}
				sortedField={sort.field}
				limit={limit}
				customerName={customerName}
				openRepliesSidebar={openRepliesSidebar}
				handleNestedEntity={handleNestedEntity}
			/>
			<Paginator limit={limit} offset={offset} count={data.count || 0} goToPage={handlePagination} />
			<Replies isOpen={replies.isOpen} close={closeRepliesSidebar} data={replies} isNested={true} />
		</div>
	);
};
export default withRouter(
	connect((store) => ({
		customerFeedbacks: store.customerFeedbacks
	}))(Feedback)
);

export const Table = ({ data, loading, sortList, sortedField, limit, ...rest }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") + " transaction-table-holder common-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader sortList={sortList} sortedField={sortedField} style={{ zIndex: limit + 1 }} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`),
								zIndex: limit - i
							}}
							{...data[i]}
							{...rest}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No feedback history!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, sortedField, style }) => {
	return (
		<div style={style} className={`at-table-row transaction-header-row customer-feedbacks-table`}>
			{CUSTOMER_FEEDBACKS_HEADER_FIELDS.map((field, i) => {
				return (
					<div
						key={i}
						className={`at-table-cell at-table-header at-header-text ${field.value} ${
							field.sortKey ? "clickable" : ""
						} ${sortedField === field.sortKey ? "active" : ""}`}
						onClick={field.sortKey && (() => sortList(field.sortKey))}
					>
						<span>{field.label}</span>
						{field.sortKey && (
							<span>
								&nbsp;&nbsp;
								<img src="/assets/icons/icon-sort.svg" alt="" />
							</span>
						)}
					</div>
				);
			})}
		</div>
	);
};

const TableList = ({
	id,
	rating,
	created,
	choiceText,
	comments,
	style,
	customerName,
	replies = [],
	objectId,
	contentType,
	bizLocation,
	openRepliesSidebar,
	handleNestedEntity
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows customer-feedbacks-table"
		>
			<div className="at-table-cell at-cell-text created">
				<div className="date-date">{moment(created).format("DD MMM, YYYY")}</div>
				<div className="date-time">{moment(created).format("hh:mm A")}</div>
			</div>
			<div className="at-table-cell at-cell-text notes" title={choiceText || ""}>
				<div className="notes-desc">
					<div className="choice-text">{choiceText || "--"}</div>
					<div className="comments">{comments || ""}</div>
				</div>
			</div>
			<div className="at-table-cell at-cell-text rating" title={rating}>
				<div className="rating-info">
					{rating && (
						<div className="rating-icon">
							<img
								src={`/assets/icons/icon-${
									rating >= 0 && rating <= 6
										? "sad"
										: rating >= 7 && rating <= 8
										? "neutral"
										: "happy"
								}-face.svg`}
							/>
						</div>
					)}
					<div>{rating || "N/A"}</div>
				</div>
				<div
					className="reply-icon"
					title="Reply"
					onClick={() => openRepliesSidebar(id, customerName, comments, rating, replies)}
				>
					<img src="/assets/icons/icon-reply.svg" />
				</div>
			</div>
			<div className="at-table-cell at-cell-text order">
				<div className="order-desc">
					{objectId ? (
						<a
							role="button"
							className="link-text"
							onClick={() =>
								handleNestedEntity(
									true,
									NESTED_ENTITY_TYPES[11],
									parseInt(objectId),
									contentType === "ORDER" ? "online" : "in-store"
								)
							}
						>
							{objectId}
						</a>
					) : (
						"--"
					)}
					<div className="type">{contentType || ""}</div>
				</div>
			</div>
			<div className="at-table-cell at-cell-text location">
				{bizLocation ? (
					<a
						role="button"
						className="link-text"
						onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[2], parseInt(bizLocation.id))}
					>
						{bizLocation.name || bizLocation.id}
					</a>
				) : (
					"--"
				)}
			</div>
		</animated.div>
	);
};
