import React from "react";

// components
import { Button } from "../components/_commons/Button";

// client
import history from "../history";

const AccessDenied = () => {
	const handleGoToDashboard = () => {
		history.push("/");
	};

	return (
		<div className="section-container-common">
			<div className="error-item">
				<div className="error-img-container">
					<img className="error-img" src="assets/error/error-403.svg" alt="error-403" />
					<div className="error-code">403</div>
				</div>
				<div className="error-msg-container">
					<div className="title">Access denied!</div>
					<div className="error-msg">Contact your administrator.</div>
					<div className="dashboard-btn">
						<Button clickHandler={handleGoToDashboard}>Dashboard</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AccessDenied;
