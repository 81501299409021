import React, { useState, useCallback } from "react";

// third party
import Select from "react-select-animated-v2";

// components
import { InputWithLabel } from "./InputWithLabel";

// constants
const RANGE_TYPES = [
	{ label: "Not specified", value: "--" },
	{ label: "Is between", value: "between" },
	{ label: "Is greater than", value: "greater_than" },
	{ label: "Is less than", value: "less_than" }
];

export const NumberRangeFilter = ({ title, field, currValue, setFilter }) => {
	const value = currValue.value ? currValue.value : "";
	const [tempRangeStart, setTempStart] = useState(100);
	const [tempRangeEnd, setTempEnd] = useState(200);
	const [rangeType, setRangeType] = useState("");
	const [focused, setFocus] = useState(false);
	const filterApplied = value ? true : false;

	// set current values according to the props received
	const values = value ? value.split(",") : [];
	const rangeStart = values[0] || tempRangeStart;
	const rangeEnd = values[1] || tempRangeEnd;

	// set default values
	let rangeTypeSelected = RANGE_TYPES[0].value;
	if (rangeType !== "" && value) {
		rangeTypeSelected = rangeType;
	} else {
		if (values[0] && values[1]) {
			rangeTypeSelected = RANGE_TYPES[1].value;
		} else if (values[0]) {
			rangeTypeSelected = RANGE_TYPES[2].value;
		} else if (values[1]) {
			rangeTypeSelected = RANGE_TYPES[3].value;
		}
	}

	const changeRangeType = useCallback(
		(rangeTypeSelected) => {
			let newValue = {
				field
			};
			setRangeType(rangeTypeSelected.value);
			switch (rangeTypeSelected.value) {
				case RANGE_TYPES[1].value:
					newValue.value = `${tempRangeStart},${tempRangeEnd}`;
					break;
				case RANGE_TYPES[2].value:
					newValue.value = `${tempRangeStart},`;
					break;
				case RANGE_TYPES[3].value:
					newValue.value = `,${tempRangeEnd}`;
					break;
				default:
					break;
			}
			setFilter(field, newValue);
		},
		[tempRangeStart, tempRangeEnd]
	);

	const handleRangeInput = useCallback(
		(e, whichInput) => {
			const inpVal = e.target.value;
			const values = value ? value.split(",") : [];
			const rangeStart = values[0] || "";
			const rangeEnd = values[1] || "";
			let newValue = {
				field
			};
			if (inpVal && inpVal >= 0) {
				switch (rangeTypeSelected) {
					case RANGE_TYPES[1].value:
						if (whichInput === "rangeStart") {
							newValue.value = `${Math.floor(inpVal)},${rangeEnd}`;
							setTempStart(Math.floor(inpVal));
						} else if (whichInput === "rangeEnd") {
							newValue.value = `${rangeStart},${Math.floor(inpVal)}`;
							setTempEnd(Math.floor(inpVal));
						}
						break;
					case RANGE_TYPES[2].value:
						newValue.value = `${Math.floor(inpVal)},`;
						setTempStart(Math.floor(inpVal));
						break;
					case RANGE_TYPES[3].value:
						newValue.value = `,${Math.floor(inpVal)}`;
						setTempEnd(Math.floor(inpVal));
						break;
				}
			} else {
				if (whichInput === "rangeStart") {
					setTempStart("");
					newValue.value = `,${rangeEnd}`;
				} else {
					setTempEnd("");
					newValue.value = `${rangeStart},`;
				}
			}
			setFilter(field, newValue);
		},
		[rangeTypeSelected, value]
	);

	return (
		<div
			className={
				"number-range-filter-container filter-section-item " +
				(filterApplied ? "filter-applied" : "") +
				(focused ? " active" : "")
			}
		>
			<div className="meta-info">{title}</div>
			<div className="type-selector">
				<Select
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					isSearchable={false}
					className="at--dropdown"
					classNamePrefix="react-select"
					options={RANGE_TYPES}
					value={RANGE_TYPES.find((type) => type.value === rangeTypeSelected)}
					isClearable={false}
					labelKey="label"
					valueKey="value"
					getOptionLabel={(option) => option["label"]}
					getOptionValue={(option) => option["value"]}
					onChange={(m) => changeRangeType(m)}
				/>
				<div className="type-selected">
					{rangeTypeSelected !== RANGE_TYPES[0].value && (
						<div>
							{(rangeTypeSelected === RANGE_TYPES[1].value ||
								rangeTypeSelected === RANGE_TYPES[2].value) && (
								<InputWithLabel
									type="number"
									classes="inline-input"
									value={rangeStart}
									onChange={(e) => handleRangeInput(e, "rangeStart")}
								/>
							)}
							{rangeTypeSelected === RANGE_TYPES[1].value && <span className="seperator">to</span>}
							{(rangeTypeSelected === RANGE_TYPES[1].value ||
								rangeTypeSelected === RANGE_TYPES[3].value) && (
								<InputWithLabel
									type="number"
									classes="inline-input range-second"
									value={rangeEnd}
									onChange={(e) => handleRangeInput(e, "rangeEnd")}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
