import React, { useState, useRef } from "react";

// third party
import { animated as a, useSpring } from "react-spring";
import { trackEvent } from "../../atlas-utils";

// components
import { Button } from "../_commons/Button";

export const MerakiMultiLocationsDetailsEntry = ({
	locationDetailsArray,
	handleSelectedTabUpdate,
	setModalState,
	adjustStringLength,
	platformsSelectedCount
}) => {
	const [scrollClass, setScrollClass] = useState("");
	const scrollRef = useRef(0);
	const [merakiFieldsIncomplete, setMerakiFieldsIncomplete] = useState(false);

	const listenToScroll = (e) => {
		if (scrollRef.current?.scrollTop === 0) {
			setScrollClass("");
		} else {
			setScrollClass("show-border-bottom");
		}
	};
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const updateValuesAndRedirectToModalView = (i, type) => {
		setModalState((current) => {
			return { ...current, state: true, type: type, index: i };
		});
	};
	const handleMerakiDetailsPrevClick = () => {
		handleSelectedTabUpdate(platformsSelectedCount === 1 ? 5 : 6);
	};

	const validateAndSwitchToNextTab = () => {
		let currentLocationDetailsArray = [...locationDetailsArray];
		let gotoNext = true;
		for (let i = 0; i < currentLocationDetailsArray.length; i++) {
			if (!currentLocationDetailsArray[i].locationLatLng?.lat || !currentLocationDetailsArray[i].storeTimings) {
				gotoNext = false;
			}
		}
		handleSelectedTabUpdate(platformsSelectedCount === 1 ? 7 : 8);
		trackEvent("wiz_loc_multi_loc_meraki_details", {
			locationCount: locationDetailsArray.length
		});
	};

	return (
		<a.div className="common-box-structure " style={contentProps}>
			<div className="header">Please add Meraki specific details</div>
			{
				<div
					className="links-entry-table multi-city-table multi-city-details-table"
					ref={scrollRef}
					onScroll={listenToScroll}
					id="style-scrollbar"
				>
					<div className={`row ${scrollClass}`}>
						<div className="col">Name</div>
						<div className="col">City</div>
						<div className="col">Location</div>
						<div className="col">Timings</div>
					</div>
					{locationDetailsArray.map((details, i) => (
						<a.div className="row" style={contentProps}>
							<div className="col">
								<div>{adjustStringLength(details?.locationName || "--")}</div>
							</div>
							<div className="col">
								<div>{adjustStringLength(details?.city || "--")}</div>
							</div>
							<div className="col loc-lat-lng">
								<span className="action-value-div">
									<span
										className={details?.locationLatLng?.lat ? "" : "set-loc"}
										onClick={
											!details?.locationLatLng?.lat
												? () => updateValuesAndRedirectToModalView(i, "maps")
												: () => {}
										}
									>
										{details?.locationLatLng?.lat ? "Location set" : "Set location"}
									</span>
									{details?.locationLatLng?.lat && (
										<span>
											<img
												src={"/assets/icons/icon-noun-tick.svg"}
												className="tick-icon"
												alt="tick"
											/>
										</span>
									)}
								</span>
								{details?.locationLatLng?.lat && (
									<span
										onClick={() => updateValuesAndRedirectToModalView(i, "maps")}
										className="edit-icon"
									>
										<img src={"/assets/icons/icon-edit.svg"} alt="edit icon" />
									</span>
								)}
							</div>
							<div className="col loc-lat-lng">
								<span className="action-value-div">
									<span
										className={details?.storeTimings ? "" : "set-loc"}
										onClick={
											!details?.storeTimings
												? () => updateValuesAndRedirectToModalView(i, "timing-group")
												: () => {}
										}
									>
										{details.storeTimings ? "Store timing added" : "Set store timing"}
									</span>
									{details.storeTimings && (
										<span>
											<img
												src={"/assets/icons/icon-noun-tick.svg"}
												className="tick-icon"
												alt="tick"
											/>
										</span>
									)}
								</span>
								{details.storeTimings && (
									<span
										onClick={() => updateValuesAndRedirectToModalView(i, "timing-group")}
										className="edit-icon"
									>
										<img src={"/assets/icons/icon-edit.svg"} alt="edit icon" />
									</span>
								)}
							</div>
						</a.div>
					))}
					<div className={`error ${merakiFieldsIncomplete ? "visible" : ""}`}>
						* Warning: All the fields must be filled before proceeding further.
					</div>
				</div>
			}
			<div className="button-div">
				<Button clickHandler={handleMerakiDetailsPrevClick} type="secondary">
					<span>Previous</span>
				</Button>
				<Button clickHandler={validateAndSwitchToNextTab}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
