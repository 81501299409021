// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_ITEM_TAGS_LIST,
	GET_TAG_GROUPS_LIST,
	GET_TAG_GROUP,
	EDIT_TAG_GROUP,
	GET_ITEM_TAG,
	EDIT_ITEM_TAG
} from "../graphql/itemTags";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchItemTagsList = async (tagGroupId) => {
	const { limit, offset, sort, appliedFilters } = store.getState().itemTagsListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_ITEM_TAGS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		variables.tagGroup = tagGroupId || -1;
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_enabled") {
					// this value is being reversed because the behaviour of
					// is_enabled filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(!appliedFilters[f])
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_enabled",
				value: "true"
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_ITEM_TAGS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_ITEM_TAGS_LIST_SUCCESS,
			payload: { ...resp.data.itemTags }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_ITEM_TAGS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchTagGroupsList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().tagGroupsListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_TAG_GROUPS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_TAG_GROUPS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_TAG_GROUPS_LIST_SUCCESS,
			payload: {
				tagGroups: resp.data.itemTagGroups.objects,
				selectedTagGroup: resp.data.itemTagGroups.objects.length
					? resp.data.itemTagGroups.objects[0]
					: { id: -1 }
			}
		});
		store.dispatch(toggleGlobalLoader(false));
		return resp.data.itemTagGroups.objects.length ? resp.data.itemTagGroups.objects[0] : { id: -1 };
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_TAG_GROUPS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseItemTagData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			break;
		case "server":
			if (result["tagGroup"]) {
				result["tagGroup"] = parseInt(result["tagGroup"].id);
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchTagGroupDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_TAG_GROUP_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_TAG_GROUP,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_TAG_GROUP_DETAIL_SUCCESS,
			payload: resp.data.itemTagGroup
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_TAG_GROUP_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Tag Group details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchItemTagDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_ITEM_TAG_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_ITEM_TAG,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_ITEM_TAG_DETAIL_SUCCESS,
			payload: parseItemTagData(resp.data.itemTag, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_ITEM_TAG_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Tag details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editTagGroupDetail = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_TAG_GROUP_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_TAG_GROUP,
			variables: data
		});
		if (resp.data.saveItemTagGroup.status.success) {
			dispatch({
				type: ActionTypes.EDIT_TAG_GROUP_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Tag Group details saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_TAG_GROUPS_LIST,
				payload: resp.data.saveItemTagGroup.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_TAG_GROUP_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveItemTagGroup.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_TAG_GROUP_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Tag Group details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editItemTagDetail = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_ITEM_TAG_DETAIL_REQUEST
	});
	try {
		const variables = parseItemTagData(data, "server");
		const resp = await client.mutate({
			mutation: EDIT_ITEM_TAG,
			variables
		});
		if (resp.data.saveItemTag.status.success) {
			dispatch({
				type: ActionTypes.EDIT_ITEM_TAG_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Tag details saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_ITEM_TAGS_LIST,
				payload: resp.data.saveItemTag.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_ITEM_TAG_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveItemTag.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_ITEM_TAG_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Tag details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreTagGroup = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_TAG_GROUP,
			variables: data
		});
		if (resp.data.saveItemTagGroup.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveItemTagGroup.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};

export const archiveRestoreItemTag = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_ITEM_TAG,
			variables: parseItemTagData(data, "server")
		});
		if (resp.data.saveItemTag.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveItemTag.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
