import React, { useState, useEffect } from "react";

//third party
import { connect } from "react-redux";

// component
import EntityAccordion from "./EntityAccordion";
import DragDropWrapper from "./DragDropWrapper";
import Image from "./Image";
import ContextMenu from "./ContextMenu";

// constants
import { NESTED_ENTITY_TYPES, MENU_REORDER_TYPES, FOOD_TYPE_MAP } from "../../client-config";

// utils
import { printCurrency } from "../SiteComp";

export const DRAGGABLE_ENTITY_TYPES = {
	MODIFIER_GROUP: "modifier_group",
	MODIFIER: "modifier",
	NESTED_MODIFIER_GROUP: "nested_modifier_group"
};

function DraggableModifierAccordion({
	reOrderedMenuModifierGroups = [],
	setReOrderedMenuModifierGroups,
	reOrderedMenuModifiers = [],
	setReOrderedMenuModifiers,
	setFormTouched,
	handleNestedEntity,
	currencySymbol,
	type = DRAGGABLE_ENTITY_TYPES.MODIFIER_GROUP,
	handleRemoveEntity
}) {
	const [contextMenuId, setContextMenuId] = useState(null);
	const [expandedEntity, setExpandedEntity] = useState(null);

	const handleDropEvent = (draggedSectionInfo, droppedSectionInfo) => {
		if (draggedSectionInfo.id === droppedSectionInfo.id) return;
		const draggedIndex = (
			type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? reOrderedMenuModifiers : reOrderedMenuModifierGroups
		).findIndex((item) => item.id === draggedSectionInfo.id);
		const droppedIndex = (
			type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? reOrderedMenuModifiers : reOrderedMenuModifierGroups
		).findIndex((item) => item.id === droppedSectionInfo.id);
		repositionArrayItem(
			type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? reOrderedMenuModifiers : reOrderedMenuModifierGroups,
			draggedIndex,
			droppedIndex
		);
	};

	function repositionArrayItem(arr, fromIndex, toIndex) {
		const element = arr[fromIndex];
		arr.splice(fromIndex, 1);
		arr.splice(toIndex, 0, element);
		type === DRAGGABLE_ENTITY_TYPES.MODIFIER
			? setReOrderedMenuModifiers([...arr])
			: setReOrderedMenuModifierGroups([...arr]);
		setFormTouched && setFormTouched(true);
	}

	const handleContextMenuClick = (e, id) => {
		e.stopPropagation();
		setContextMenuId(id);
	};

	const renderMenuItems = (data) => {
		const openNestedEntity = () => {
			handleNestedEntity(
				true,
				type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? NESTED_ENTITY_TYPES[6] : NESTED_ENTITY_TYPES[5],
				data.id
			);
		};

		const handleReorderModifiers = () => {
			const tab = type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? "nestedModifierGroups" : "nestedModifiers";
			handleNestedEntity(
				true,
				type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? NESTED_ENTITY_TYPES[6] : NESTED_ENTITY_TYPES[5],
				data.id,
				tab
			);
		};

		const handleRemove = () => {
			handleRemoveEntity && handleRemoveEntity(data.id);
		};

		return (
			<div onClick={(e) => e.stopPropagation()} className="draggable-entity-context-menu-modal">
				<p onClick={openNestedEntity}>Edit Details</p>
				<p onClick={handleReorderModifiers}>
					Reorder{" "}
					{type === DRAGGABLE_ENTITY_TYPES.MODIFIER
						? "Modifiers Groups"
						: data.isVariant
						? "Variants"
						: "Add-Ons"}
				</p>
				<p onClick={handleRemove} className="draggable-entity-context-menu-modal-remove">
					Remove
				</p>
			</div>
		);
	};

	return (
		<div className="draggable-modifier-accordion-container">
			{(type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? reOrderedMenuModifiers : reOrderedMenuModifierGroups)?.map(
				(entity) => {
					return (
						<DragDropWrapper
							key={entity.id}
							id={entity.id}
							dndType={
								type === DRAGGABLE_ENTITY_TYPES.MODIFIER
									? MENU_REORDER_TYPES.MODIFIERLIST
									: MENU_REORDER_TYPES.MODIFIERGROUPLIST
							}
							handleDrop={handleDropEvent}
							entityFields={{
								type: type,
								id: entity.id,
								name: entity.name
							}}
							onDragClasses="drag-styles-section"
							isCentralNodeVisible={true}
							entityDependencyContent={
								type === DRAGGABLE_ENTITY_TYPES.MODIFIER
									? reOrderedMenuModifiers
									: reOrderedMenuModifierGroups
							}
						>
							<EntityAccordion
								key={entity.id}
								className="draggable-entity-accordion"
								// parentAccordion={true} todo: need to fix the transition issue
								isExpanded={expandedEntity === entity.id}
								handleExpand={() => setExpandedEntity((prev) => (prev == entity.id ? null : entity.id))}
								showChevron={
									type === DRAGGABLE_ENTITY_TYPES.MODIFIER
										? entity?.nestedOptionGroups?.totalObjectCount
										: entity?.options?.totalObjectCount || false
								}
								title={
									<div className="draggable-entity-title">
										<Image
											className="drag-icon"
											alt="drag-icon"
											src="/assets/icons/icon-drag.svg"
										/>
										<div className="draggable-entity-title-left">
											<div className="draggable-entity-title-row">
												{entity?.foodType ? (
													<div className={`food-type ${FOOD_TYPE_MAP[entity.foodType]}`} />
												) : null}
												<p className="draggable-entity-name">{entity.name}</p>
												{type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? (
													<p className="draggable-entity-price">
														{printCurrency(currencySymbol)} {entity.price}
													</p>
												) : (
													<p className={entity.isVariant ? "variant" : "add-on"}>
														{entity.isVariant ? "Variant Group" : "Add-On Group"}
													</p>
												)}
											</div>
											{entity?.crmName ? (
												<p className="draggable-entity-crm-title">
													CRM Title: {entity.crmName}
												</p>
											) : null}
										</div>
										<ContextMenu
											classes="draggable-entity-context-menu"
											isOpen={contextMenuId === entity.id}
											data={entity}
											renderMenuItems={renderMenuItems}
											handleOpenMenu={(e) => handleContextMenuClick(e, entity.id)}
											handleOutsideClick={
												entity.id === contextMenuId ? () => setContextMenuId(null) : null
											}
											clickEvent="mousedown"
										/>
									</div>
								}
							>
								<NestedModifierGroupsAndModifiers
									nestedModifiers={entity?.nestedOptionGroups?.objects || []}
									options={entity?.options?.objects || []}
									currencySymbol={currencySymbol}
									type={
										type === DRAGGABLE_ENTITY_TYPES.MODIFIER
											? DRAGGABLE_ENTITY_TYPES.MODIFIER_GROUP
											: DRAGGABLE_ENTITY_TYPES.MODIFIER
									}
								/>
							</EntityAccordion>
						</DragDropWrapper>
					);
				}
			)}
		</div>
	);
}

const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
export default connect(mapStateToProps)(DraggableModifierAccordion);

const NestedModifierGroupsAndModifiers = ({ options = [], nestedModifiers = [], currencySymbol, type }) => {
	const [expandedEntity, setExpandedEntity] = useState(null);

	if (!options?.length && !nestedModifiers?.length) return null;

	return (type === DRAGGABLE_ENTITY_TYPES.MODIFIER ? options : nestedModifiers).map((entity, index) => {
		return (
			<EntityAccordion
				key={entity.id}
				showChevron={
					type === DRAGGABLE_ENTITY_TYPES.MODIFIER
						? entity?.nestedOptionGroups?.totalObjectCount
						: entity?.options?.totalObjectCount || false
				}
				className="nested-entity-accordion"
				isExpanded={expandedEntity === entity.id}
				handleExpand={() => setExpandedEntity((prev) => (prev == entity.id ? null : entity.id))}
				title={
					<div className="nested-entity-accordion-title">
						<div className="nested-entity-accordion-title-row">
							{entity?.foodType ? (
								<div className={`food-type ${FOOD_TYPE_MAP[entity.foodType]}`} />
							) : null}
							<p className="nested-entity-accordion-name">{entity.name}</p>
							{entity?.price ? (
								<p className="nested-entity-accordion-price">
									{printCurrency(currencySymbol)} {entity.price}
								</p>
							) : null}
							{entity?.isVariant !== undefined ? (
								<p className={entity.isVariant ? "variant" : "add-on"}>
									{entity.isVariant ? "Variant Group" : "Add-On Group"}
								</p>
							) : null}
						</div>
						{entity?.crmName ? (
							<p className="nested-entity-accordion-crm-title">CRM Title: {entity.crmName}</p>
						) : null}
					</div>
				}
			>
				<NestedModifierGroupsAndModifiers
					nestedModifiers={entity?.nestedOptionGroups?.objects || []}
					options={entity?.options?.objects || []}
					currencySymbol={currencySymbol}
					type={
						type === DRAGGABLE_ENTITY_TYPES.MODIFIER
							? DRAGGABLE_ENTITY_TYPES.MODIFIER_GROUP
							: DRAGGABLE_ENTITY_TYPES.MODIFIER
					}
				/>
			</EntityAccordion>
		);
	});
};
