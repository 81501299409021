import React, { useState, useMemo, useEffect, useRef } from "react";

// components
import { chartTooltip } from "./Tooltip";
import Loader from "./Loader";

// third party
import { ResponsiveHeatMap, ResponsiveHeatMapCanvas, HeatMapCanvas } from "@nivo/heatmap";
import { computeContinuousColorsLegend } from "@nivo/legends";
import { getContinuousColorScale } from "@nivo/colors";
import { animated, to } from "@react-spring/web";

// utils
import { idealTextColor, scroll } from "../../atlas-utils";

// constants
import { ANALYTICS_HEATMAP_COLORS } from "../../client-config";

const Heatmap = ({
	responsive = true,
	canvas = false,
	width = 1500,
	height = 600,
	data = [],
	loading = false,
	colors, // cool, warm
	minValue,
	maxValue,
	onClick,
	onMouseEnter,
	onMouseMove,
	onMouseLeave,
	pixelRatio = 3,
	marginTop = 40,
	marginBottom = 120,
	marginLeft = 80,
	marginRight = 10,
	borderWidth = 4,
	valueFormat,
	enableLegends = true,
	enableCustomLegends = false,
	customLegendBorderWidth = 2,
	showZeroLegend = false,
	showUnavailableLegend = false,
	legendTicks = [],
	legendCellLength = 125,
	legendCellThickness = 20,
	enableLabels = false,
	labelFontSize = 12,
	xInnerPadding = 0,
	yInnerPadding = 0,
	currencySymbol,
	units = "",
	tooltipLabel = null,
	renderCustomTooltipLabel,
	infoTip = "",
	placeholder = "No data available",
	scrollOffset = 0
}) => {
	const [cellHeight, setCellHeight] = useState(undefined);
	const customRef = useRef();
	const props = {
		data,
		margin: {
			top: responsive ? marginTop : marginTop + 55,
			right: responsive ? marginRight : marginRight + 25,
			bottom: responsive && enableLegends ? marginBottom : marginBottom - 115,
			left: responsive ? marginLeft : marginLeft + 25
		},
		valueFormat,
		xInnerPadding,
		yInnerPadding,
		axisTop: {
			tickSize: 0,
			tickPadding: 10,
			tickRotation: 0,
			legend: "",
			legendPosition: "middle"
		},
		axisLeft: {
			tickSize: 0,
			tickPadding: 10,
			tickRotation: 0,
			legend: "",
			legendPosition: "middle"
		},
		colors: {
			type: "quantize",
			colors: ANALYTICS_HEATMAP_COLORS[colors] || colors,
			steps: 4,
			domain: minValue !== undefined && maxValue !== undefined ? [minValue, maxValue] : undefined
		},
		emptyColor: "#D0D0D0",
		pixelRatio,
		borderWidth,
		borderColor: "#FFFFFF",
		cellComponent: (props) => <CustomCell props={props} units={units} labelFontSize={labelFontSize} />,
		renderCell: (ctx, props) => customCanvasCell(ctx, props, showZeroLegend, setCellHeight),
		inactiveOpacity: 1,
		enableLabels,
		tooltip: (props) =>
			chartTooltip(
				{
					...props,
					tooltipLabel,
					infoTip,
					currencySymbol,
					units,
					showZeroLegend,
					renderCustomLabel: renderCustomTooltipLabel
				},
				"heatmap"
			),
		legends:
			responsive && enableLegends
				? [
						{
							anchor: "bottom",
							translateX: 0,
							translateY: 80,
							length: 500,
							thickness: 20,
							direction: "row",
							tickPosition: "after",
							tickSize: 0,
							tickSpacing: 8,
							tickOverlap: false,
							tickFormat: valueFormat,
							titleAlign: "start",
							titleOffset: 4
						}
				  ]
				: [],
		hoverTarget: "cell",
		onClick,
		onMouseEnter,
		onMouseMove,
		onMouseLeave,
		theme: {
			text: {
				fontSize: labelFontSize
			},
			axis: {
				ticks: {
					text: {
						fontSize: 12,
						fontFamily: "Source Sans Pro"
					}
				}
			},
			legends: {
				ticks: {
					text: {
						fontSize: 12
					}
				}
			}
		},
		layers: ["grid", "axes", "cells", "legends", "annotations"]
	};

	const handleHorizontalScroll = () => {
		if (customRef?.current) {
			scroll({ top: 0, left: customRef?.current?.offsetLeft + scrollOffset }, customRef?.current);
		}
	};

	useEffect(() => {
		if (!responsive && scrollOffset) {
			handleHorizontalScroll();
		}
	}, [responsive, data?.length]);

	return (
		<div
			className={
				"nivo-chart-heatmap" +
				(data.length > 0 && loading ? " disabled" : "") +
				(onClick !== undefined ? " clickable" : "")
			}
		>
			{responsive && !canvas && (
				<div className="responsive" style={{ height: height }}>
					{data.length > 0 && <ResponsiveHeatMap {...props} />}
					{data.length === 0 && loading && <Loader />}
					{data.length === 0 && !loading && <div className="no-items-placeholder">{placeholder}</div>}
				</div>
			)}
			{responsive && canvas && (
				<div className="responsive" style={{ height: height }}>
					{data.length > 0 && <ResponsiveHeatMapCanvas {...props} />}
					{data.length === 0 && loading && <Loader />}
					{data.length === 0 && !loading && <div className="no-items-placeholder">{placeholder}</div>}
				</div>
			)}
			{!responsive && (
				<div className="custom" style={{ height: height }} ref={customRef}>
					{data.length > 0 && <HeatMapCanvas width={width} height={height - 50} {...props} />}
					{data.length === 0 && loading && <Loader />}
					{data.length === 0 && !loading && <div className="no-items-placeholder">{placeholder}</div>}
				</div>
			)}
			{!responsive && data.length > 0 && (
				<div
					className="label-container--left"
					style={{
						height: height - 50,
						width: props.margin.left - 5,
						paddingTop: props.margin.top,
						paddingBottom: props.margin.bottom
					}}
				>
					{data.map((row, i) => (
						<div key={i} className="label" style={{ height: cellHeight || "auto" }}>
							{row.id}
						</div>
					))}
				</div>
			)}
			{enableCustomLegends && data.length > 0 && (
				<CustomLegend
					data={data}
					colors={ANALYTICS_HEATMAP_COLORS[colors] || colors}
					minValue={minValue}
					maxValue={maxValue}
					legendTicks={legendTicks}
					legendCellLength={legendCellLength}
					legendCellThickness={legendCellThickness}
					borderWidth={customLegendBorderWidth}
					marginLeft={marginLeft}
					showZeroLegend={showZeroLegend}
					showUnavailableLegend={showUnavailableLegend}
				/>
			)}
		</div>
	);
};
export default Heatmap;

// for responsive heatmap - to include custom dashed border cell for null values
const CustomCell = ({ props, units, labelFontSize }) => {
	const { cell, animatedProps, enableLabels, borderWidth, onMouseEnter, onMouseMove, onMouseLeave } = props;

	const handlers = useMemo(
		() => ({
			onMouseEnter: onMouseEnter ? onMouseEnter(cell) : undefined,
			onMouseMove: onMouseMove ? onMouseMove(cell) : undefined,
			onMouseLeave: onMouseLeave ? onMouseLeave(cell) : undefined
		}),
		[cell, onMouseEnter, onMouseMove, onMouseLeave]
	);

	return (
		<animated.g
			data-testid={`cell.${cell.id}`}
			opacity={animatedProps.opacity}
			transform={to(
				[animatedProps.x, animatedProps.y, animatedProps.scale],
				(x, y, scale) => `translate(${x}, ${y}) scale(${scale})`
			)}
			{...handlers}
		>
			<animated.rect
				transform={to(
					[animatedProps.width, animatedProps.height],
					(width, height) => `translate(${width * -0.5}, ${height * -0.5})`
				)}
				key={cell.id}
				fill={cell.value !== null ? animatedProps.color || cell.color : "#FFFFFF"}
				width={animatedProps.width || cell.width}
				height={animatedProps.height || cell.height}
				stroke={cell.value !== null ? animatedProps.borderColor || cell.borderColor : "#D0D0D0"}
				strokeDasharray={cell.value !== null ? "0, 0" : "14, 5"}
				strokeWidth={borderWidth}
			/>
			{enableLabels && cell?.value && (
				<animated.text
					textAnchor="middle"
					dominantBaseline="central"
					fill={
						idealTextColor(cell.value !== null ? cell?.color : "#FFFFFF", "#FFFFFF", "#363636") ||
						animatedProps.labelTextColor
					}
					style={{
						fontSize: labelFontSize,
						userSelect: "none"
					}}
				>
					{cell.formattedValue}
					{units}
				</animated.text>
			)}
		</animated.g>
	);
};

// for heatmap canvas - to include custom dashed border cell for null values
const customCanvasCell = (
	ctx,
	{ cell, borderWidth, enableLabels, theme },
	showZeroLegend,
	setCellHeight,
	cellHeight
) => {
	let { x, y, width, height, color, borderColor, opacity, labelTextColor, label, value } = cell;

	// set cell height for sticky y-axis labels
	if (cellHeight !== height) {
		setCellHeight(height);
	}

	ctx.save();
	ctx.globalAlpha = opacity;

	if (value === 0 && showZeroLegend) {
		ctx.fillStyle = "#D0D0D0";
		color = "#D0D0D0";
	} else if (value !== null) {
		ctx.fillStyle = color;
	} else {
		ctx.fillStyle = "#FFFFFF";
	}

	if (value === 0 && showZeroLegend) {
		ctx.strokeStyle = "#D0D0D0";
		ctx.lineWidth = borderWidth;
	} else if (value !== null) {
		ctx.strokeStyle = borderColor;
		ctx.lineWidth = borderWidth;
	} else {
		ctx.strokeStyle = "#D0D0D0";
		ctx.lineWidth = borderWidth;
		ctx.setLineDash([14, 5]);
	}

	ctx.fillRect(x - width / 2, y - height / 2, width, height);
	if (borderWidth > 0) {
		ctx.strokeRect(x - width / 2, y - height / 2, width, height);
	}

	if (enableLabels) {
		ctx.fillStyle = idealTextColor(value !== null ? color : "#FFFFFF", "#FFFFFF", "#363636");
		ctx.font = `${theme.labels.text.fontWeight ? `${theme.labels.text.fontWeight} ` : ""}${
			theme.labels.text.fontSize
		}px ${theme.labels.text.fontFamily}`;
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillText(label || "N.A.", x, y);
	}

	ctx.restore();
};

// for horizontable scrollable heatmap - custom legend outside of heatmap component
const CustomLegend = ({
	data = [],
	colors,
	minValue,
	maxValue,
	legendTicks,
	legendCellLength,
	legendCellThickness,
	borderWidth,
	marginLeft,
	showZeroLegend,
	showUnavailableLegend
}) => {
	if (legendTicks.length === 0) {
		const allValues = [];
		data.forEach((obj) => {
			obj.data.forEach((cell) => {
				allValues.push(cell.y);
			});
		});

		const { ticks } = computeContinuousColorsLegend({
			scale: getContinuousColorScale(
				{
					type: "quantize",
					colors: ANALYTICS_HEATMAP_COLORS[colors] || colors,
					steps: 4,
					domain: [
						minValue === undefined ? Math.min(...allValues) : minValue,
						maxValue === undefined ? Math.max(...allValues) : maxValue
					]
				},
				{
					minValue: minValue === undefined ? Math.min(...allValues) : minValue,
					maxValue: maxValue === undefined ? Math.max(...allValues) : maxValue
				}
			)
		});
		legendTicks = ticks.map((tick) => tick.text);
	}

	return (
		<div className="custom-legend-heatmap" style={{ marginLeft: marginLeft > 125 ? 120 : marginLeft }}>
			<div className="available">
				<div className="cells-container">
					{colors.map((color, i) => (
						<div
							key={i}
							className="cell"
							style={{
								width: legendCellLength,
								height: legendCellThickness,
								backgroundColor: color
							}}
						/>
					))}
				</div>
				<div className="ticks-container">
					{legendTicks.map((tick, i) => (
						<div
							key={i}
							className="tick"
							style={{
								width: legendCellLength
							}}
						>
							{showZeroLegend && tick === "0" ? "1" : tick || ""}
						</div>
					))}
				</div>
			</div>
			{showZeroLegend && (
				<div className="unavailable">
					<div className="cells-container">
						<svg width={legendCellLength} height={legendCellThickness}>
							<g transform="translate(1,1)">
								<rect
									fill={"#D0D0D0"}
									width={legendCellLength - 2}
									height={legendCellThickness - 2}
									stroke={"#D0D0D0"}
									strokeWidth={borderWidth}
								/>
							</g>
						</svg>
					</div>
					<div className="ticks-container">
						<div className="tick" style={{ width: legendCellLength }}>
							0
						</div>
					</div>
				</div>
			)}
			{showUnavailableLegend && (
				<div className="unavailable">
					<div className="cells-container">
						<svg width={legendCellLength} height={legendCellThickness}>
							<g transform="translate(1,1)">
								<rect
									fill={"#FFFFFF"}
									width={legendCellLength - 2}
									height={legendCellThickness - 2}
									stroke={"#D0D0D0"}
									strokeDasharray={"14, 4.5"}
									strokeWidth={borderWidth}
								/>
							</g>
						</svg>
					</div>
					<div className="ticks-container">
						<div className="tick" style={{ width: legendCellLength }}>
							Data Unavailable
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
