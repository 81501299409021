import React, { useState, useEffect, useCallback } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CopyToClipboard } from "../_commons/CopyToClipboard";
import { CommonTable } from "../_commons/CommonTable";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_WEBHOOK_HISTORY_DETAIL } from "../../graphql/webhooks";

// actions
import { ActionTypes } from "../../actions/_types";
import { redeliverWebhook } from "../../actions/webhooks";

// constant
const columns = [
	{
		name: "Warnings",
		field: "warnings",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text warnings" key={i}>
				{record}
			</div>
		)
	}
];

const HistoryDetail = ({ isOpen, close, data = {}, webhookUrl = "" }) => {
	const [loading, setLoading] = useState(false);
	const [historyDetail, setHistoryDetail] = useState(null);
	const [copyHeadersBtn, setCopyHeadersBtn] = useState(false);
	const [copyPayloadBtn, setCopyPayloadBtn] = useState(false);

	const fetchWebhookHistoryDetail = useCallback(async () => {
		try {
			setLoading(true);
			const variables = { id: data.id };
			const resp = await client.query({
				query: GET_WEBHOOK_HISTORY_DETAIL,
				variables,
				fetchPolicy: "no-cache"
			});
			setHistoryDetail(resp.data.webhookDelivery);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [data]);

	useEffect(() => {
		if (isOpen) {
			fetchWebhookHistoryDetail();
		}
	}, [fetchWebhookHistoryDetail, isOpen]);

	const handleRedeliverWebhook = useCallback(async () => {
		setLoading(true);
		const variables = { id: data.id };
		await redeliverWebhook(variables);
		setLoading(false);
	}, [data]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setLoading(false);
			setHistoryDetail(null);
			setCopyHeadersBtn(false);
			setCopyPayloadBtn(false);
			close(refresh);
		},
		[close]
	);

	const renderTitle = () => {
		return (
			<div className="request-method">
				Request Method: <span>POST</span>
			</div>
		);
	};

	const renderSubTitle = () => {
		return (
			<div className="description">
				<div className={"status-code " + (data.statusCode === 200 ? "success" : "error")}>
					Status code: <span>{data.statusCode}</span>
				</div>
				<div title={webhookUrl} className="webhook-url">
					{webhookUrl && webhookUrl.length > 70 ? webhookUrl.slice(0, 70) + "..." : webhookUrl}
				</div>
			</div>
		);
	};

	return (
		<div className="history-detail-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				title={renderTitle()}
				subTitle={renderSubTitle()}
				loading={loading}
				hideActions={true}
				isNested={true}
			>
				<div className="history-details">
					<div className="title">
						Delivery ID: <span>{data.id}</span>
					</div>
					{historyDetail && (
						<React.Fragment>
							<div className="title">Headers</div>
							<div
								className="json-container headers"
								onMouseEnter={() => setCopyHeadersBtn(true)}
								onMouseLeave={() => setCopyHeadersBtn(false)}
							>
								{copyHeadersBtn && (
									<CopyToClipboard content={historyDetail.headers || ""} showIcon={true} />
								)}
								{historyDetail.headers && (
									<pre>{JSON.stringify(JSON.parse(historyDetail.headers), null, 4)}</pre>
								)}
							</div>
							<div className="title">
								<div>Payload</div>
								{data.statusCode !== 200 && (
									<div className="action-item" onClick={handleRedeliverWebhook}>
										<img src="/assets/icons/icon-publish-blue.svg" />
										<span>REDELIVER</span>
									</div>
								)}
							</div>
							<div
								className="json-container payload"
								onMouseEnter={() => setCopyPayloadBtn(true)}
								onMouseLeave={() => setCopyPayloadBtn(false)}
							>
								{copyPayloadBtn && (
									<CopyToClipboard content={historyDetail.payload || ""} showIcon={true} />
								)}
								{historyDetail.payload && (
									<pre>{JSON.stringify(JSON.parse(historyDetail.payload), null, 4)}</pre>
								)}
							</div>
							<div className="title">Warnings</div>
							<CommonTable
								data={(historyDetail.warnings && JSON.parse(historyDetail.warnings)) || []}
								columns={columns}
								hideTableHeader={true}
								bordered={true}
								sortedField=""
								content="Warnings"
								classes="warnings-table-container"
							/>
						</React.Fragment>
					)}
				</div>
				{!loading && !historyDetail && <div className="no-items-placeholder">No Delivery details found!</div>}
			</FormSidebar>
		</div>
	);
};
export default HistoryDetail;
