// action types
import { ActionTypes } from "../actions/_types";

export const CUSTOM_INTEGRATIONS_INITIAL_STATE = {
	loading: false,
	data: null,
	error: {}
};
export const customIntegrations = (state = CUSTOM_INTEGRATIONS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CUSTOM_INTEGRATIONS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_CUSTOM_INTEGRATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_CUSTOM_INTEGRATIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CUSTOM_INTEGRATIONS:
			return {
				...state,
				data: {
					...state.data,
					keysV2: state.data.keysV2.map((group) => {
						if (group.groupKeys && group.groupKeys.length > 0) {
							group.groupKeys = group.groupKeys.map((key) => {
								if (key.key === action.payload.field) {
									key.value = action.payload.value;
								}
								return key;
							});
						}
						return group;
					})
				}
			};
		case ActionTypes.EDIT_CUSTOM_INTEGRATIONS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_CUSTOM_INTEGRATIONS_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_CUSTOM_INTEGRATIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
