import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import Select from "react-select-animated";

// components
import InlineSuggestions from "../_commons/InlineSuggestions";
// client
import { store } from "../../store/configureStore";
import { CAMPAIGN_DYANAMIC_VARS } from "../../client-config";

import { MESSAGE_TYPES } from "../../client-config";

@connect((store) => ({
	createCampaign: store.createCampaign
}))
export default class CampaignSMS extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messageLength: 0,
			hasDynamicVars: false
		};
	}

	changeMType = (messageType) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				messageType: messageType.value
			}
		});
	};

	openTestSMSOverlay() {
		const {
			createCampaign: { message }
		} = this.props;
		store.dispatch({
			type: "SET_OVERLAY_SIDEBAR_STATE",
			payload: {
				active: true,
				medium: "SMS",
				formattedSMS: message
			}
		});
	}

	updateContent = (content, messageLength, hasDynamicVars = false) => {
		this.setState({
			messageLength,
			hasDynamicVars
		});
		console.log(content, messageLength, hasDynamicVars);
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				message: content
			}
		});
	};

	render() {
		const { messageLength, hasDynamicVars } = this.state;
		const {
			createCampaign: { message, messageType }
		} = this.props;

		return (
			<div className="campaign-sms">
				<div className="meta-info">Message type</div>
				<Select
					searchable={false}
					className="at-dropdown"
					options={MESSAGE_TYPES}
					value={messageType}
					clearable={false}
					onChange={(m) => this.changeMType(m)}
				/>
				<div className="meta-info">SMS content (add dynamic fields with {"{ }"})</div>
				<InlineSuggestions vars={CAMPAIGN_DYANAMIC_VARS} updateContent={this.updateContent} content={message} />
				<div className="sms-meta">
					<div className="sms-count">
						{`${messageLength} Characters | ${Math.ceil(messageLength / 160)} SMS ${
							messageLength > 0 && hasDynamicVars ? "(Estimate)" : ""
						}`}
					</div>
				</div>
				<div className="sms-send-test">
					<img
						src="/assets/icons/icon-sms.svg"
						alt=""
						style={{
							transform: "translateY(2px)"
						}}
					/>
					<span
						onClick={(e) => {
							this.openTestSMSOverlay(e);
						}}
						className="Cur(p)"
					>
						Send a test SMS
					</span>
				</div>
			</div>
		);
	}
}
