import React, { useEffect, useState, useImperativeHandle } from "react";

// components
import OverlaySidebar from "../_commons/OverlaySidebar";
import { Button } from "../_commons/Button";
import MultiActionsButton from "./MultiActionsButton";
import MonogramGenerator from "./MonogramGenerator";
import Breadcrumbs from "./Breadcrumbs";

// third party
import { connect } from "react-redux";

const mapStateToProps = (store) => ({
	breadcrumbsState: store.breadcrumbsState
});
export const FormSidebar = connect(mapStateToProps)(({
	isOpen,
	close,
	canExpand = false,
	expandDrawer = false,
	setIsDrawerExpanded,
	children,
	submit,
	cancel,
	title,
	subTitle,
	submitTitle = "Submit",
	submitClass = "",
	multiActionsSubmit = false,
	actions,
	cancelTitle = "Cancel",
	cancelClass = "",
	showSecondaryAction = false,
	secondaryAction,
	secondaryActionClass = "",
	secondaryActionTitle = "Cancel",
	disabled = false,
	loading = false,
	showMoreBtn = false,
	numRows = 5,
	dependencies = [],
	hideActions = false,
	hideSubmitAction = false,
	hideCancelAction = false,
	isNested = false,
	isFilters = false,
	hideHeader = false,
	headerLeft = null,
	headerRight = null,
	headerRightClasses = "",
	hideHeaderRight = false,
	submitButtonWidth = 100,
	connectedRef,
	overlaySidebarClasses = "large",
	formSidebarClasses = "",
	headerImageSource = "",
	isMonogramVisible = false,
	formSidebarRef,
	breadcrumbs = []
}) => {
	const [showMore, setShowMore] = useState(false);
	const [transition, setTransition] = useState(false);
	const [formContainerHeight, setFormContainerHeight] = useState(showMoreBtn ? "60%" : "100%");

	useEffect(() => {
		if (isOpen && showMoreBtn && !showMore) {
			let formRows = document.querySelectorAll(
				".form-sidebar-container .form-container.is-open .form-content > .form-row"
			);
			if (formRows.length) {
				// set the height of form-container based on the num of form-rows to be shown
				const row = formRows[numRows];
				setFormContainerHeight(row?.offsetTop ? `${row.offsetTop - 72}px` : "60%");
			}
		}
	}, [document.querySelectorAll(".form-sidebar-container .form-container.is-open .form-content > .form-row").length]);

	useEffect(() => {
		if (isOpen && showMoreBtn && !showMore && !loading) {
			let formRows = document.querySelectorAll(
				".form-sidebar-container .form-container.is-open .form-content > .form-row"
			);
			if (formRows.length) {
				// set the height of form-container based on the num of form-rows to be shown
				const row = formRows[numRows];
				setFormContainerHeight(row?.offsetTop ? `${row.offsetTop - 72}px` : "60%");
			}
		}
	}, [numRows, loading, ...dependencies]);

	const toggleShowForm = (state = false) => {
		setTransition(true);
		let formContainer = document.querySelectorAll(".form-sidebar-container .form-container.is-open");
		if (state) {
			setShowMore(state);
		} else {
			setShowMore(() => {
				if (formContainer.length) {
					// scroll to the top
					formContainer[formContainer.length - 1].scrollTop = 0;
				}
				return state;
			});
		}
		setTimeout(() => {
			if (formContainer.length && state) {
				// scroll down to show the remaining form fields if "Show More" is clicked
				formContainer[formContainer.length - 1].scrollTop = 450;
				// formContainer[formContainer.length - 1].scrollTop = formContainer[formContainer.length - 1].scrollHeight;
			}
			setTransition(false);
		}, 300);
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			toggleShowForm
		}),
		[toggleShowForm]
	);

	return (
		<OverlaySidebar
			showOverlaySidebar={isOpen}
			close={close}
			classes={overlaySidebarClasses}
			loading={loading}
			isNested={isNested}
			isOpen={isOpen}
			canExpand={canExpand}
			expandDrawer={expandDrawer}
			setIsDrawerExpanded={setIsDrawerExpanded}
			formSidebarRef={formSidebarRef}
			isFilters={isFilters}
		>
			<div className={"form-sidebar-container " + formSidebarClasses}>
				{!hideHeader && (
					<div className="header">
						<div className="header-left">
							{headerImageSource ? (
								<div className="image-container">
									<img src={headerImageSource} alt={title} />
								</div>
							) : (
								isMonogramVisible && <MonogramGenerator value={title} size="medium" />
							)}
							{(title || subTitle) && (
								<div className="title-content">
									<Breadcrumbs connectedLinks={breadcrumbs} />
									{title && <div className="title">{title}</div>}
									{subTitle && <div className="subtitle">{subTitle}</div>}
								</div>
							)}
							{headerLeft}
						</div>
						<div className={"header-right " + headerRightClasses}>{!hideHeaderRight && headerRight}</div>
					</div>
				)}
				<div
					className={
						"form-container" +
						(isOpen ? " is-open" : "") +
						(showMoreBtn && !showMore ? " limited" : "") +
						(transition ? " slide" : "")
					}
					style={{ height: showMoreBtn && !showMore ? formContainerHeight : "100%" }}
				>
					{children}
					{showMoreBtn && (
						<div
							className="show-more-btn"
							onClick={() => toggleShowForm(!showMore)}
							title={showMore ? "Click to collapse" : "Click to expand"}
						>
							<div>{showMore ? "Show Less" : "Show More"}</div>
							<div className={"arrow " + (showMore ? "up" : "down")}></div>
						</div>
					)}
				</div>
				{!hideActions && (
					<div className="footer">
						{!hideSubmitAction && (
							<Button
								clickHandler={submit}
								classes={`W(${submitButtonWidth}px) ${submitClass} ` + (disabled ? "disabled" : "")}
							>
								{submitTitle}
							</Button>
						)}
						{multiActionsSubmit && (
							<MultiActionsButton
								title={submitTitle}
								instantAction={submit}
								actions={actions}
								isDisabled={disabled}
								renderOnTop
							/>
						)}
						{!hideCancelAction && (
							<Button
								clickHandler={cancel ? cancel : () => close()}
								classes={`W(100px) Mend(10px) ${cancelClass}` + (loading ? "disabled" : "")}
								type="secondary"
							>
								{cancelTitle}
							</Button>
						)}
						{showSecondaryAction && (
							<Button
								clickHandler={secondaryAction}
								classes={`W(100px) Mend(10px) ${secondaryActionClass}` + (loading ? "disabled" : "")}
								type="secondary"
							>
								{secondaryActionTitle}
							</Button>
						)}
					</div>
				)}
			</div>
		</OverlaySidebar>
	);
});
