import React, { memo } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useTheme, useMotionConfig } from "@nivo/core";

const tooltipStyle = {
	pointerEvents: "none",
	position: "absolute",
	zIndex: 110,
	top: 0,
	left: 0,
	transform: "transition 1s"
};

const translate = (x, y) => {
	return `translate(${x}px, ${y}px)`;
};

const TooltipWrapper = memo(({ position, children, name = "graph-activity-logs" }) => {
	const theme = useTheme();
	const { animate, config: springConfig } = useMotionConfig();

	let to = undefined;
	let immediate = false;
	let x = Math.round(position[0]);
	let y = Math.round(position[1]);
	if (name === "graph-activity-logs") {
		if (position[0] < 940) {
			x = 0;
			if (position[1] < 450) {
				y = 30;
			} else {
				y = -160;
			}
		} else if (position[0] > 940 && position[0] < 1200) {
			x = -50;
			if (position[1] < 450) {
				y = 30;
			} else {
				y = -160;
			}
		} else {
			x = -170;
			if (position[1] < 450) {
				y = 30;
			} else {
				y = -160;
			}
		}
	}

	const animatedProps = useSpring({
		to,
		config: springConfig,
		immediate: !animate || immediate
	});

	const style = {
		...tooltipStyle,
		...theme.tooltip,
		transform: animatedProps.transform ?? translate(x, y)
	};

	return <animated.div style={style}>{children}</animated.div>;
});

TooltipWrapper.displayName = "TooltipWrapper";

export default TooltipWrapper;
