import gql from "graphql-tag";

export const GET_BIZ_INFO = gql`
	query getBizInfo {
		biz {
			__typename
			currency
			currencySymbol
			billingCurrency
			billingCurrencySymbol
			timezone
			id
			name
			modulesEnabled
			feedbackChoiceTexts
			credits
			logo
			address
			gstin
			billingPhones
			billingEmails
			contactEmails
			created
			contactPersonName
			bizWebsite
			bizIsdCode
			registeredBusinessName
			tin
			fssaiLicenseNumber
			isActive
			isTest
			addressLine1
			addressLine2
			city
			state
			country
			postalCode
			currentState
			isEnforcedOla
			orderPrepTime
			busyOrderPrepTime
			eisEnabled
			eisSupportEnabled
			description
			seoDescription
			supportedLanguages {
				valueForDisplay
				value
			}
			meraki {
				apiKey
				username
			}
			differentialPricingEnabled
			isReconEnabled
			isMultibrandEnabled
			isPgServiceEnabled
			isMenuOverCatalogueEnabled
			isBaseMenuValidationEnabled
			scheduledActionsAndNotifications {
				actions {
					action
					message
					applicable
				}
				notifications {
					action
					ctaPrimaryLink
					ctaPrimaryText
					ctaSecondaryLink
					ctaSecondaryText
					dismissible
					message
					type
					applicable
				}
			}
		}
		# invoices(
		# 	limit:1000,
		# 	offset:0,
		# 	filters: [
		# 		{
		# 			field: "status",
		# 			value: "overdue,pending"
		# 		}
		# 	]
		# ){
		# 	count,
		# 	objects{
		# 	  externalRefId,
		# 	  finalAmount,
		# 	  dueDate,
		# 	  currency,
		# 	  currencySymbol,
		# 	}
		#  }
	}
`;
export const UPDATE_BIZ_INFO = gql`
	mutation updateBizInfo(
		$addressLine1: String
		$addressLine2: String
		$city: String
		$state: String
		$country: String
		$postalCode: String
		$billingPhones: String
		$billingEmails: String
		$contactEmails: String
		$name: String
		$logo: String
		$base64Image: String
		$escPosImage: String
		$starLineImage: String
		$bizWebsite: String
		$gstin: String
		$contactPersonName: String
		$tin: String
		$registeredBusinessName: String
		$orderPrepTime: Int
		$busyOrderPrepTime: Int
		$isHubEnabled: Boolean
		$isOrderingEnabled: Boolean
		$isPrimeEnabled: Boolean
		$isCometEnabled: Boolean
		$isEisEnabled: Boolean
		$isPrismEnabled: Boolean
		$isReconEnabled: Boolean
		$isMultibrandEnabled: Boolean
		$isPgServiceEnabled: Boolean
		$description: String
		$seoDescription: String
		$isMenuOverCatalogueEnabled: Boolean
		$isBaseMenuValidationEnabled: Boolean
	) {
		saveBiz(
			addressLine1: $addressLine1
			addressLine2: $addressLine2
			city: $city
			state: $state
			country: $country
			postalCode: $postalCode
			billingPhones: $billingPhones
			billingEmails: $billingEmails
			contactEmails: $contactEmails
			name: $name
			logo: $logo
			base64Image: $base64Image
			escPosImage: $escPosImage
			starLineImage: $starLineImage
			bizWebsite: $bizWebsite
			gstin: $gstin
			contactPersonName: $contactPersonName
			tin: $tin
			registeredBusinessName: $registeredBusinessName
			orderPrepTime: $orderPrepTime
			busyOrderPrepTime: $busyOrderPrepTime
			isHubEnabled: $isHubEnabled
			isOrderingEnabled: $isOrderingEnabled
			isPrimeEnabled: $isPrimeEnabled
			isCometEnabled: $isCometEnabled
			isEisEnabled: $isEisEnabled
			isPrismEnabled: $isPrismEnabled
			isReconEnabled: $isReconEnabled
			isMultibrandEnabled: $isMultibrandEnabled
			isPgServiceEnabled: $isPgServiceEnabled
			isMenuOverCatalogueEnabled: $isMenuOverCatalogueEnabled
			isBaseMenuValidationEnabled: $isBaseMenuValidationEnabled
			description: $description
			seoDescription: $seoDescription
		) {
			object {
				__typename
				currency
				currencySymbol
				billingCurrency
				billingCurrencySymbol
				timezone
				id
				name
				modulesEnabled
				feedbackChoiceTexts
				credits
				logo
				address
				gstin
				billingPhones
				billingEmails
				contactEmails
				registeredBusinessName
				tin
				created
				contactPersonName
				fssaiLicenseNumber
				bizWebsite
				bizIsdCode
				isActive
				isTest
				addressLine1
				addressLine2
				city
				state
				country
				postalCode
				currentState
				isEnforcedOla
				orderPrepTime
				busyOrderPrepTime
				eisEnabled
				eisSupportEnabled
				description
				seoDescription
				isReconEnabled
				isMultibrandEnabled
				isMenuOverCatalogueEnabled
				isBaseMenuValidationEnabled
				isPgServiceEnabled
				supportedLanguages {
					valueForDisplay
					value
				}
				meraki {
					apiKey
					username
				}
			}
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_BIZ_LOGO = gql`
	mutation updateBizInfo($logo: String, $base64Image: String, $escPosImage: String, $starLineImage: String) {
		saveBiz(logo: $logo, base64Image: $base64Image, escPosImage: $escPosImage, starLineImage: $starLineImage) {
			object {
				__typename
				currency
				currencySymbol
				billingCurrency
				billingCurrencySymbol
				timezone
				id
				name
				modulesEnabled
				feedbackChoiceTexts
				credits
				logo
				address
				gstin
				billingPhones
				billingEmails
				contactEmails
				registeredBusinessName
				tin
				created
				contactPersonName
				fssaiLicenseNumber
				bizWebsite
				bizIsdCode
				isActive
				isTest
				addressLine1
				addressLine2
				city
				state
				country
				postalCode
				currentState
				isEnforcedOla
				orderPrepTime
				eisEnabled
				eisSupportEnabled
				description
				seoDescription
				isReconEnabled
				isMultibrandEnabled
				isPgServiceEnabled
				isMenuOverCatalogueEnabled
				isBaseMenuValidationEnabled
				supportedLanguages {
					valueForDisplay
					value
				}
				meraki {
					apiKey
					username
				}
			}
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_ANOUNCEMENT_LIST = gql`
	query getAnnouncements {
		announcements {
			objects {
				id
				header
				body
				link
				imageUrl
			}
		}
	}
`;

export const GET_COUPONS_LIST = gql`
	query getCoupons($limit: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		coupons(limit: $limit, filters: $filters, sort: $sort) {
			objects {
				title
				redemptionCode
			}
		}
	}
`;

export const GET_ITEMS_LIST = gql`
	query getItems($limit: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		items(limit: $limit, filters: $filters, sort: $sort) {
			objects {
				title
				id
			}
		}
	}
`;

export const GET_STORES_LIST = gql`
	query getStores($limit: Int, $filters: [ListFilterArgument], $sort: SortInput, $brand: String) {
		stores(limit: $limit, filters: $filters, sort: $sort, brand: $brand) {
			objects {
				id
				name
				brandLocation {
					id
					name
				}
				platforms {
					externalId
					platformName
					outletUrl
				}
			}
		}
	}
`;

export const GET_BRANDS_LIST = gql`
	query getBrandsList($limit: Int, $filters: [ListFilterArgument], $search: [SearchArgument]) {
		brands(limit: $limit, filters: $filters, search: $search) {
			count
			objects {
				id
				name
				image
			}
		}
	}
`;

export const GET_MENUS_LIST = gql`
	query getMenus(
		$limit: Int!
		$offset: Int!
		$brands: [String!]!
		$status: String!
		$publishStatus: String!
		$searchKeyword: String!
	) {
		menus(
			limit: $limit
			offset: $offset
			brands: $brands
			status: $status
			publishStatus: $publishStatus
			searchKeyword: $searchKeyword
		) {
			objects {
				bizId
				menuId
				imageUrl
				name
				brand
				brandName
			}
		}
	}
`;

export const SAVE_EMAIL_TEMPLATE = gql`
	mutation saveEmailTemplate($id: ID, $subject: String!, $html: String!, $isEditable: Boolean!) {
		saveEmailTemplate(input: { id: $id, subject: $subject, html: $html, isEditable: $isEditable }) {
			object {
				id
				subject
				html
				isEditable
			}
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const SEND_TEST_MESSAGE = gql`
	mutation sendTestMessage($campaignId: Int!, $emailIds: String, $phoneNumbers: String) {
		sendTestMessage(campaignId: $campaignId, phoneNumbers: $phoneNumbers, emailIds: $emailIds) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPLOAD_IMAGE = gql`
	mutation uploadImage($image: Upload!) {
		uploadImage(image: $image) {
			id
			image
		}
	}
`;

export const GET_TAGS_LIST = gql`
	query getTags($limit: Int, $offset: Int, $search: [SearchArgument]) {
		tags(limit: $limit, offset: $offset, search: $search) {
			count
			objects {
				id
				name
			}
		}
	}
`;

export const GET_FULFILLMENT_MODES_LIST = gql`
	query getFulfillmentModes($limit: Int, $offset: Int) {
		fulfillmentModes(limit: $limit, offset: $offset) {
			limit
			offset
			count
			objects {
				id
				name
				nameForDisplay
			}
		}
	}
`;

export const GET_TIMING_GROUPS_LIST = gql`
	query getTimingGroups($limit: Int, $offset: Int, $sort: SortInput, $filters: [ListFilterArgument]) {
		timingsGroup(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
			limit
			offset
			count
			objects {
				id
				title
			}
		}
	}
`;

export const GET_CITIES_LIST = gql`
	query getCities {
		cities {
			objects {
				value
				valueForDisplay
			}
		}
	}
`;

export const GET_ITEM_CATEGORIES = gql`
	query getItemCategories {
		itemCategories {
			id
			name
			isActive
		}
	}
`;

export const GET_PARENT_CATEGORIES = gql`
	query getParentCategories($subcategory: Int) {
		parentCategories(subcategory: $subcategory) {
			id
			name
			isActive
		}
	}
`;

export const GET_PARENT_CATEGORY_IDS = gql`
	query getParentCategories($subcategory: Int) {
		parentCategories(subcategory: $subcategory) {
			id
		}
	}
`;

export const GET_PAYMENT_MODES_LIST = gql`
	query getPaymentModesList($limit: Int, $offset: Int) {
		paymentModes(limit: $limit, offset: $offset) {
			limit
			offset
			count
			objects {
				id
				name
				nameForDisplay
			}
		}
	}
`;

export const GET_BIZ_PLATFORMS = gql`
	query getBizPlatforms(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$includeUrbanpiper: Boolean
	) {
		bizPlatforms(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			includeUrbanpiper: $includeUrbanpiper
		) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				platformName
				logo
				isEnabled
				isItemAssociated
				platform {
					name
				}
				featureFlags {
					name
					value
				}
			}
		}
	}
`;

export const GET_CI_ITEM_GROUPS_LIST = gql`
	query getItemGroupsList($limit: Int, $offset: Int, $sort: SortInput, $filters: [ListFilterArgument]) {
		itemGroups(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
			objects {
				id
				title
			}
		}
	}
`;

export const GET_CI_LOCATION_GROUPS_LIST = gql`
	query getLocationGroupsList($limit: Int, $offset: Int, $sort: SortInput, $filters: [ListFilterArgument]) {
		locationGroups(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
			objects {
				id
				title
			}
		}
	}
`;

export const GET_CI_CHARGES_LIST = gql`
	query charges($limit: Int, $offset: Int, $sort: SortInput) {
		charges(limit: $limit, offset: $offset, sort: $sort) {
			objects {
				id
				title
			}
		}
	}
`;

export const GET_CI_MODIFIER_GROUPS_LIST = gql`
	query getModifierGroups(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		modifierGroups(limit: $limit, offset: $offset, sort: $sort, filters: $filters, search: $search) {
			objects {
				id
				optionGroupTitle
				crmTitle
			}
		}
	}
`;

export const GET_CI_MODIFIERS_LIST = gql`
	query getModifiers(
		$limit: Int
		$offset: Int
		$sort: SortInput
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		modifiers(limit: $limit, offset: $offset, sort: $sort, filters: $filters, search: $search) {
			objects {
				id
				optionTitle
				crmTitle
			}
		}
	}
`;

export const GET_CI_LIST_GROUPS = gql`
	query getListGroups($limit: Int, $offset: Int, $sort: SortInput, $api: Boolean) {
		groups(limit: $limit, offset: $offset, sort: $sort, api: $api) {
			objects {
				id
				name
			}
		}
	}
`;

export const GET_TRANSLATION = gql`
	query getTranslation($entityId: Int, $entityType: TranslationEntityType!, $field: String!, $language: String!) {
		translation(entityId: $entityId, entityType: $entityType, field: $field, language: $language) {
			value
		}
	}
`;

export const UPDATE_TRANSLATION = gql`
	mutation updateTranslation(
		$entityId: Int
		$entityType: TranslationEntityType!
		$field: String!
		$language: String!
		$value: String
	) {
		saveTranslation(
			input: { entityId: $entityId, entityType: $entityType, field: $field, language: $language, value: $value }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			value
		}
	}
`;

export const GET_INFO_FOR_MIXPANEL = gql`
	query getInfoForMixpanel {
		configurations {
			name
		}
		physicalLocations: stores {
			count
		}
		brandLocations: stores(brand: "all") {
			count
		}
		brands {
			count
		}
	}
`;

export const URL_MENU_PULL = gql`
	mutation urlMenuPull($menuUrl: String!, $platform: String!, $brandId: Int) {
		urlMenuPull(input: { menuUrl: $menuUrl, platform: $platform, brandId: $brandId }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
