import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { RadioButton } from "../_commons/RadioButton";
import { CaptureLocation } from "../_commons/CaptureLocation";
import { CaptureLocationPolygon } from "../_commons/CaptureLocationPolygon";
import { CaptureLocationCircle } from "../_commons/CaptureLocationCircle";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

const MerakiDetails = ({ isOpen, close, index = 0, formData = {}, handleFormData, isMulti = false }) => {
	const [currStep, setCurrStep] = useState(0);
	const [deliveryAreaType, setDeliveryAreaType] = useState("polygon");
	const [location, setLocation] = useState({});
	const savePolygonsRef = useRef();

	useEffect(() => {
		if (isOpen) {
			setLocation(isMulti ? formData?.multiLocationDetails?.[index] || {} : formData || {});
			setCurrStep(1);
		}
	}, [index, isOpen]);

	const handleClose = useCallback(
		(onSave = false) => {
			setCurrStep(0);
			setDeliveryAreaType("polygon");
			setLocation({});
			close(onSave);
		},
		[close]
	);

	const handleNext = () => {
		setCurrStep(currStep + 1);
	};

	const handlePrevious = () => {
		setCurrStep(currStep - 1);
	};

	const handleDeliveryAreaType = (mode) => {
		setDeliveryAreaType(mode);
	};

	const handleLocationCoordinates = useCallback(
		(coordinates) => {
			setLocation({
				...location,
				locLatitude: coordinates.lat,
				locLongitude: coordinates.lng
			});
		},
		[location]
	);

	const updateDeliveryPolygons = useCallback(() => {
		const polygons = savePolygonsRef?.current?.presetPolygons;
		setLocation({
			...location,
			polygons: polygons.map((polygon) => ({ coordinates: polygon.coordinates }))
		});
	}, [location]);

	const handleDeliveryPolygons = useCallback(
		(polygons) => {
			setLocation({
				...location,
				polygons: [...polygons]
			});
		},
		[location]
	);

	const handleDeliveryRadius = useCallback(
		(radius) => {
			setLocation({
				...location,
				orderDeliveryRadius: radius
			});
		},
		[location]
	);

	const handleSave = useCallback(() => {
		if (isMulti) {
			const updatedMultiLocationDetails = formData.multiLocationDetails.map((loc, i) => {
				let updatedLocation = { ...loc };
				if (i === index) {
					updatedLocation = {
						...updatedLocation,
						...location,
						isMerakiDetailsSet: true
					};
				}
				return updatedLocation;
			});
			handleFormData({ multiLocationDetails: updatedMultiLocationDetails });
		} else {
			handleFormData({ ...location, isMerakiDetailsSet: true });
		}
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Location and Delivery Area details are saved",
				timeout: 2000,
				error: false
			}
		});
		handleClose(true);
	}, [isMulti, formData, index, location]);

	return (
		<div className="meraki-details">
			<FormSidebar
				isOpen={isOpen}
				submit={currStep === 2 ? handleSave : handleNext}
				close={handleClose}
				cancel={handlePrevious}
				submitTitle={currStep === 2 ? "Save" : "Next"}
				cancelTitle="Previous"
				hideCancelAction={currStep === 1}
				disabled={currStep === 1 && (!location.locLatitude || !location.locLongitude)}
				title={`${currStep === 1 ? "Drop a pin at your location" : "Set your delivery area"}`}
				subTitle={
					<div>
						{location?.bizLocationNickname || ""}, {location?.city?.value || ""}
					</div>
				}
				isNested={false}
			>
				<div className="form-content">
					{currStep === 1 && (
						<div className="location-drop-pin">
							<CaptureLocation
								latLong={{
									lat: location.locLatitude,
									lng: location.locLongitude
								}}
								setLocation={handleLocationCoordinates}
								fromLocationCreateWizard={true}
								height="500px"
							/>
						</div>
					)}
					{currStep === 2 && (
						<div className="location-delivery-area">
							<div className="options">
								<RadioButton
									checked={deliveryAreaType === "polygon"}
									clickHandler={() => handleDeliveryAreaType("polygon")}
								>
									Polygon
								</RadioButton>
								<RadioButton
									checked={deliveryAreaType === "radius"}
									clickHandler={() => handleDeliveryAreaType("radius")}
								>
									Radius
								</RadioButton>
							</div>
							{deliveryAreaType === "polygon" && (
								<CaptureLocationPolygon
									latLong={{
										lat: location.locLatitude,
										lng: location.locLongitude
									}}
									updateState={updateDeliveryPolygons}
									setPolygons={handleDeliveryPolygons}
									close={() => {}}
									locationName={location.bizLocationNickname}
									city={location.city.value}
									fromLocationCreateWizard={true}
									presetPolygons={location.polygons}
									savePolygonsRef={savePolygonsRef}
									height="500px"
								/>
							)}
							{deliveryAreaType === "radius" && (
								<CaptureLocationCircle
									latLong={{
										lat: location.locLatitude,
										lng: location.locLongitude
									}}
									setRadius={handleDeliveryRadius}
									radius={location.orderDeliveryRadius}
									height="500px"
								/>
							)}
						</div>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
export default MerakiDetails;
