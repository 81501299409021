import React from "react";

// third party
import { Route } from "react-router-dom";
import { connect } from "react-redux";

// components
import MerakiWeb from "./MerakiWeb";
import ThemesList from "./ThemesList";
import ThemeDetails from "./ThemeDetails";
import MerakiWebLocked from "./MerakiWebLocked";

const MerakiWebContainer = ({ merakiWebSubscription }) => {
	const { data } = merakiWebSubscription;
	return (
		<div className="meraki-web-container">
			<Route
				exact
				path="/meraki-web"
				component={
					data?.subscription === null || ["cancelled", "not-subscribed"].includes(data?.subscription?.status)
						? MerakiWebLocked
						: MerakiWeb
				}
			/>
			<Route path="/meraki-web/themes" component={ThemesList} />
			<Route exact path="/meraki-web/themes/:name" component={ThemeDetails} />
		</div>
	);
};
const mapStateToProps = (store) => ({
	merakiWebSubscription: store.merakiWebSubscription
});
export default connect(mapStateToProps)(MerakiWebContainer);
