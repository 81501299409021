import React from "react";

const CalendarTime = ({ styles = {}, variations = {}, color = "#6D7AA8" }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		style={{ ...styles }}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...variations}
	>
		<path
			d="M5 0C2.25 0 0 2.25 0 5C0 7.75 2.25 10 5 10C7.75 10 10 7.75 10 5C10 2.25 7.75 0 5 0ZM5 1.66667C6.83333 1.66667 8.33333 3.16667 8.33333 5C8.33333 6.83333 6.83333 8.33333 5 8.33333C3.16667 8.33333 1.66667 6.83333 1.66667 5C1.66667 3.16667 3.16667 1.66667 5 1.66667ZM13.3333 1.66667V3.33333H11.4486C11.5861 3.86667 11.6667 4.42333 11.6667 5C11.6667 5.885 11.4901 6.7275 11.1768 7.5H15.8333L15.835 16.6667H4.16667V11.6097C3.58417 11.5372 3.02583 11.3901 2.5 11.1768V16.6667C2.5 17.5875 3.24583 18.3333 4.16667 18.3333H15.8333C16.7542 18.3333 17.5 17.5875 17.5 16.6667V3.33333H15V1.66667H13.3333ZM5.75033 2.66602L4.33268 5.08301L6.16699 6.91732L6.91732 6.08398L5.66732 4.91699L6.66667 3.16732L5.75033 2.66602Z"
			fill={color}
		/>
	</svg>
);

export default React.memo(CalendarTime);
