import React, { useState } from "react";

// components
import { PageSwitch } from "./PageSwitch";

// utils
import { store } from "../../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { useTrail, config, animated } from "react-spring";

export const GalleryImages = ({ data, loading, handlePagination, limit, offset, count = 0 }) => {
	const [placeholder, setPlaceholder] = useState([...Array(7).keys()]);

	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});

	return (
		<React.Fragment>
			{trails.map(({ rotate }, i) => (
				<Image
					key={data[i].id}
					style={{
						transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, 0deg)`)
					}}
					data={data[i]}
				/>
			))}
			{count > 0 && (
				<PageSwitch handlePagination={handlePagination} limit={limit} offset={offset} count={count || 0} />
			)}
			{data.length === 0 &&
				loading &&
				placeholder.map((ph, i) => <div className="gallery-image shimmer" key={i}></div>)}
			{(data.length !== 0 || loading) && (
				<React.Fragment>
					<div className="hidden-block"></div>
					<div className="hidden-block"></div>
					<div className="hidden-block"></div>
					<div className="hidden-block"></div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

const Image = ({ data, style, key }) => {
	const [imageHover, setImageHover] = useState(false);

	const handleDownload = async (url) => {
		await fetch(url.includes("https://") ? url : url.replace("http://", "https://"))
			.then((resp) => resp.blob())
			.then((blob) => {
				// process to auto download it
				const fileURL = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = fileURL;
				link.download = url.replace(/^.*[\\\/]/, "");
				link.click();
			})
			.catch((error) => {
				console.log(error);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Something went wrong. Please try again later.",
						timeout: 3000,
						error: true
					}
				});
			});
	};

	return (
		<animated.div
			key={key}
			className="gallery-image"
			style={style}
			onMouseEnter={() => setImageHover(true)}
			onMouseLeave={() => setImageHover(false)}
		>
			<img src={data.image} onError={(e) => (e.target.src = "/assets/empty-dish.jpg")} alt="" />
			{imageHover && (
				<div className="image-hover-icons">
					<Link to={`/gallery/edit/${data.id}`}>
						<img title="Edit" className="edit-icon" src="/assets/icons/icon-settings-gallery.svg" alt="" />
					</Link>
					<a href={data.image} target="_blank">
						<img title="View" src="/assets/icons/icon-view.svg" alt="" />
					</a>
					<div onClick={() => handleDownload(data.image)}>
						<img title="Download" src="/assets/icons/icon-download.svg" alt="" />
					</div>
				</div>
			)}
		</animated.div>
	);
};
