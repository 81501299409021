import React, { Component } from "react";

// components
import { Header } from "../components/ItemTagsList/Header";
import { Filters } from "../components/_commons/Filters";
import { Topbar } from "../components/_commons/Topbar";
import { Paginator } from "../components/_commons/Paginator";
import { CommonTable } from "../components/_commons/CommonTable";
import { SearchFilter } from "../components/_commons/SearchFilter";
import SidebarContainer from "../components/_commons/SidebarContainer";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchItemTagsList, fetchTagGroupsList } from "../actions/itemTags";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll } from "../atlas-utils";
import history from "../history";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
const FORM_TABS = [
	{
		label: "Active",
		value: "active"
	},
	{
		label: "Archived",
		value: "archived"
	}
];

const columns = [
	{
		name: "Tags",
		field: "title",
		sortKey: "title",
		render: (record, i, archived) => (
			<div className={"at-table-cell at-cell-text title"} title={record.title} key={i}>
				<div className="title-desc">
					<Link className={"link-text " + archived} to={`/tags/tag/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">{record.title || record.id}</span>
					</Link>
					<div className="text--light desc-text">{record.crmTitle && `CRM Title: ${record.crmTitle}`}</div>
				</div>
			</div>
		)
	},
	{
		name: "Associated Items",
		field: "associated_items",
		sortKey: "num_items",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text associated_items"} key={i}>
				{record.numItems || 0}
			</div>
		)
	},
	{
		name: "Associated Modifiers",
		field: "associated_modifiers",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text associated_modifiers"} key={i}>
				{record.numOptions || 0}
			</div>
		)
	}
];

@connect((store) => ({
	itemTagsList: store.itemTagsList,
	itemTagsListState: store.itemTagsListState,
	tagGroupsList: store.tagGroupsList,
	tagGroupsListState: store.tagGroupsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class ItemTagsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			showFilters: false,
			title: "",
			archivedFilter: false,
			formTab: FORM_TABS[0].value
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "item_tags_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.itemTagsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch platforms list
		const selectedTagGroup = await fetchTagGroupsList();

		// fetch Tags list
		if (selectedTagGroup) {
			await fetchItemTagsList(selectedTagGroup.id);
		}

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	handleFormTab = async (tab) => {
		store.dispatch({
			type: ActionTypes.UPDATE_TAG_GROUPS_STATE,
			payload: {
				formTab: tab.value
			}
		});
		const { tagGroups } = this.props.tagGroupsList;
		const filteredTagGroups = await tagGroups.filter((tg) =>
			tab.value === "archived"
				? tg.isSystem === false && tg.isEnabled === false && tg.id !== -1
				: tg.isEnabled === true || tg.id === -1
		);
		if (filteredTagGroups.length) {
			store.dispatch({
				type: ActionTypes.UPDATE_TAG_GROUPS_STATE,
				payload: {
					selectedTagGroup: filteredTagGroups[0]
				}
			});
			await fetchItemTagsList(filteredTagGroups[0].id);
		} else {
			store.dispatch({
				type: ActionTypes.RESET_ITEM_TAGS_LIST
			});
		}
	};

	renderSideBarHeader = () => {
		return (
			<React.Fragment>
				<div className="header-text">Tag Groups</div>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={this.props.tagGroupsList.formTab}
					switchTab={(tab) => this.handleFormTab(tab)}
					archived={this.props.tagGroupsList.formTab === "archived" ? "archived" : ""}
				/>
			</React.Fragment>
		);
	};

	renderSideBar = (tagGroup) => {
		if (tagGroup.id !== this.props.tagGroupsList.selectedTagGroup.id) {
			return (
				<div className="text">
					<span>{tagGroup.title}</span>
					{tagGroup.isSystem && <span className="badge">System managed</span>}
				</div>
			);
		}
		return (
			<div className="custom-sidebar-item">
				<div className="text">
					<span>{tagGroup.title}</span>
					{tagGroup.isSystem && <span className="badge">System managed</span>}
				</div>
				{tagGroup.id !== -1 && (
					<div>
						<Link to={`/tags/group/edit/${tagGroup.id}`}>
							<img className="action-item" src="/assets/icons/icon-edit-dark.svg" />
						</Link>
					</div>
				)}
			</div>
		);
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updateitemTagsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.ITEM_TAGS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateitemTagsListState({
			currentFilters: this.props.itemTagsListState.appliedFilters
		});
	};

	switchTagGroup = (tab) => {
		store.dispatch({
			type: ActionTypes.UPDATE_TAG_GROUPS_STATE,
			payload: {
				selectedTagGroup: tab
			}
		});
		// fetch new Tags List
		fetchItemTagsList(tab.id);
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.itemTagsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateitemTagsListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.itemTagsListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updateitemTagsListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateitemTagsListState({
			appliedFilters: {
				...this.props.itemTagsListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "item_tags_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchItemTagsList(this.props.tagGroupsList.selectedTagGroup.id);

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}

		const { appliedFilters } = store.getState().itemTagsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updateitemTagsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchItemTagsList(this.props.tagGroupsList.selectedTagGroup.id);
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.itemTagsListState;
		const offset = (page - 1) * limit;
		this.updateitemTagsListState({
			offset
		});
		// fetch new Tags list
		fetchItemTagsList(this.props.tagGroupsList.selectedTagGroup.id);
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.itemTagsListState;
		if (size && size?.value !== limit) {
			this.updateitemTagsListState({
				[field]: size.value
			});
			// fetch new Tags list
			await fetchItemTagsList(this.props.tagGroupsList.selectedTagGroup.id);
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateitemTagsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.ITEM_TAGS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchItemTagsList(this.props.tagGroupsList.selectedTagGroup.id);
	};

	render() {
		const { itemTagsList, itemTagsListState, tagGroupsList, configItems, access } = this.props;
		const { limit, offset, currentFilters, sortedField } = itemTagsListState;
		const { tagGroups, selectedTagGroup, formTab } = tagGroupsList;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (f === "is_enabled" && currentFilters[f]) {
					filterCount++;
				} else if (f === "crm_title" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f].value && currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}

		let filterOptions = itemTagsList.filters;
		let searchFilterOption = [];
		if (filterOptions) {
			searchFilterOption = filterOptions.find((f) => f.field === "title");
			filterOptions = filterOptions.filter((f) => f.field !== "title");
		}

		const placeholderContent = {
			placeholderText: "No item tags added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-item-tags.svg",
			placeholderSubtext:
				"Manage all item tags to ensure all items are marked with proper information when being displayed to your customers",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add your first Item Tag</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/tags/tag/new");
			},
			size: "medium"
		};

		return (
			<div>
				<div className="item-tags-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions || []}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header isCatalogueManagement={access.isCatalogueManagement} />
					<SidebarContainer
						tabs={
							tagGroups.filter((tg) =>
								formTab === "archived"
									? tg.isSystem === false && tg.isEnabled === false && tg.id !== -1
									: tg.isEnabled === true || tg.id === -1
							) || []
						}
						selectedTab={selectedTagGroup ? selectedTagGroup.id : undefined}
						switchTab={this.switchTagGroup}
						valueField="id"
						labelField="title"
						header={this.renderSideBarHeader()}
						renderTab={this.renderSideBar}
						entity={!tagGroupsList.loading ? "Tag Groups" : ""}
						archived={formTab === "archived" ? "archived" : ""}
					>
						{!selectedTagGroup || (!itemTagsList.objects && itemTagsList.loading) ? (
							<div className="P(10px) loading">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						) : (
							<div>
								{configItems.dimensions.width > 768 && (
									<div className="filter-buttons">
										<div
											className={
												(this.state.showFilters ? "active" : "") +
												" filter-in-header campaign-list-filter"
											}
										>
											<div className="container" onClick={this.flipShowFilters}>
												<img
													className="filter-icon"
													src="/assets/icons/icon-sorting-options.svg"
													alt=""
												/>
												<div className="filter-title">
													Filter
													{filterCount > 0 && (
														<span className="filter-count">{filterCount}</span>
													)}
												</div>
											</div>
										</div>
										<SearchFilter
											filterOption={searchFilterOption}
											value={this.state.title}
											setFilter={this.setSearchFilter}
											placeholder="Search by name"
										/>
									</div>
								)}
								<CommonTable
									loading={itemTagsList.loading}
									data={itemTagsList.objects || []}
									columns={columns}
									sortList={this.sortList}
									sortedField={sortedField}
									archived={this.state.archivedFilter ? "archived" : ""}
									classes="item-tags-list-table-container"
									content="Tags"
									showPlaceholder
									placeholderContent={placeholderContent}
								/>
								<Paginator
									limit={limit}
									offset={offset}
									count={itemTagsList.count || 0}
									goToPage={this.handlePagination}
									setPageSize={this.handlePageSize}
									showPageSize={true}
								/>
							</div>
						)}
					</SidebarContainer>
				</div>
			</div>
		);
	}
}
