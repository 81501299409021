import React from "react";

// third party
import moment from "moment";

// component
import { CommonTable } from "../_commons/CommonTable";
import { Paginator } from "../_commons/Paginator";

// hooks
import { useMerakiWebVersions } from "../../hooks/merakiWeb";

// utils
import { scroll } from "../../atlas-utils";

const columns = [
	{
		name: "Version",
		field: "version",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text version"} key={i}>
				{record.version || "--"}
			</div>
		)
	},
	{
		name: "Comment",
		field: "comment",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text comment"} key={i}>
				{record.changelog || "--"}
			</div>
		)
	},
	{
		name: "Theme",
		field: "theme",
		render: (record, i) => (
			<span className={"at-table-cell at-cell-text theme base-capitalize"} key={i}>
				{record.theme || "--"}
			</span>
		)
	},
	{
		name: "Published on",
		field: "publishedOn",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text publishedOn"} key={i}>
				{moment(record.publishedOn).format("Do MMM YYYY - hh:mm A") || "--"}
			</div>
		)
	},
	{
		name: "Published By",
		field: "publishedBy",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text publishedBy"} key={i}>
				{record.publishedBy || "--"}
			</div>
		)
	},
	{
		name: "Action",
		field: "action",
		render: (record, i, _, _1, handleTask) => (
			<div className={"at-table-cell at-cell-text action"} key={i}>
				<a href={record.previewUrl} className="action-item" target="_blank">
					PREVIEW
				</a>
				{/* <span className="action-item" onClick={() => handleTask(record)}>RESTORE</span> */}
			</div>
		)
	}
];

export const Versions = ({ workspace }) => {
	const tableRef = React.useRef();
	const [loading, versions, count, restoreVersion, limit, offset, setOffset] = useMerakiWebVersions();
	const currentThemeData = React.useMemo(() => {
		return workspace.themeLibrary?.find((th) => th.name === workspace.theme.name) ?? {};
	}, [workspace]);

	if (loading && versions?.length === 0) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	const handleVersionRestore = (version) => {
		restoreVersion(version.version);
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * limit);
		scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
	};

	return (
		<div className={"versions-container " + (loading && versions?.length > 0 ? "disabled" : "")} ref={tableRef}>
			{versions?.length > 0 && (
				<div className="latest-version-status">
					<div className="version-info">
						<div className="version-no">
							You are working on Version {versions[0].version} -{" "}
							<span className="base-capitalize">{currentThemeData?.name}</span>
						</div>
						<div className="version-date">
							Last change made by {versions[0].publishedBy} on{" "}
							{moment(versions[0].publishedOn).format("Do MMM YYYY - hh:mm A")}
						</div>
					</div>
					<div className="unpublished-info">
						<div className="unpublished-count">{currentThemeData?.unpublishedChanges || 0}</div>
						<div className="unpublished-label">Unpublished changes</div>
					</div>
				</div>
			)}
			<CommonTable
				loading={loading}
				data={versions || []}
				columns={columns}
				classes="versions-list-table-container"
				content="Versions"
				sortedField=""
				handleTask={handleVersionRestore}
			/>
			<Paginator limit={limit} offset={offset} count={count} goToPage={handlePagination} />
		</div>
	);
};
