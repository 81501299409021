// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import { parseErrorMessages } from "../atlas-utils";

// graphql
import { GET_PENDING_INVITES_LIST, RESEND_USER_INVITE, CANCEL_USER_INVITE } from "../graphql/pendingInvites";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchPendingInvitesList = async () => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().pendingInvitesListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().pendingInvitesList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_PENDING_INVITES_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "status" && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]
				});
			}
		});
		// date filter
		Object.keys(appliedDateFilter).forEach((f) => {
			if (appliedDateFilter[f].dateFilter) {
				filtersObject.push({
					field: f,
					value: appliedDateFilter[f].dateFilter
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_PENDING_INVITES_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_PENDING_INVITES_LIST_SUCCESS,
			payload: { ...resp.data.userInvites }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_PENDING_INVITES_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const resendUserInvite = async (variables) => {
	store.dispatch({
		type: ActionTypes.RESEND_USER_INVITE_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: RESEND_USER_INVITE,
			variables
		});
		if (resp.data.userInviteResend.status.success) {
			store.dispatch({
				type: ActionTypes.RESEND_USER_INVITE_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Invite re-sent successfully!",
					timeout: 3000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.RESEND_USER_INVITE_FAILURE,
				error: parseErrorMessages(resp.data.userInviteResend.status.messages)
			});
			if (resp.data.userInviteResend.status.messages.length) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.userInviteResend.status.messages[0].message,
						timeout: 3000,
						error: true
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while while sending the Invite",
						timeout: 3000,
						error: true
					}
				});
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.RESEND_USER_INVITE_FAILURE,
			error: {
				message: "There was an error while sending the Invite",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const cancelUserInvite = async (variables) => {
	store.dispatch({
		type: ActionTypes.CANCEL_USER_INVITE_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: CANCEL_USER_INVITE,
			variables
		});
		if (resp.data.userInviteCancel.status.success) {
			store.dispatch({
				type: ActionTypes.CANCEL_USER_INVITE_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Invite cancelled successfully!",
					timeout: 3000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.CANCEL_USER_INVITE_FAILURE,
				error: parseErrorMessages(resp.data.userInviteCancel.status.messages)
			});
			if (resp.data.userInviteCancel.status.messages.length) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.userInviteCancel.status.messages[0].message,
						timeout: 3000,
						error: true
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while while cancelling the Invite",
						timeout: 3000,
						error: true
					}
				});
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.CANCEL_USER_INVITE_FAILURE,
			error: {
				message: "There was an error while cancelling the Invite",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};
