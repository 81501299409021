import React, { useEffect, useState, useCallback } from "react";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "lodash";

// components
import Header from "../components/SectionsList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Paginator } from "../components/_commons/Paginator";

// store
import { store } from "../store/configureStore";

// utils
import { capitaliseText } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchMenuCategoriesList } from "../actions/menus";

const columns = [
	{
		name: "Name",
		field: "name",
		render: (rec, i) => {
			return (
				<div className="at-table-cell at-cell-text name" key={i}>
					<Link className={"hyperlink hyperlink--black-color "} to={`/sections/edit/${rec?.id}`}>
						{capitaliseText(rec?.name?.toLowerCase())}
					</Link>
				</div>
			);
		}
	}
];

export const SectionList = ({ categoriesList, isLoading }) => {
	const [offset, setOffset] = useState(0);

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
		fetchMenuCategoriesList((page - 1) * 10, categoriesList?.data?.searchFieldValue);
	};

	const applySearch = useCallback(
		debounce((search) => {
			setOffset(0);
			fetchMenuCategoriesList(0, search);
		}, 500),
		[]
	);

	const handleSearch = useCallback(
		(field, value) => {
			store.dispatch({
				type: ActionTypes.GET_MENU_CATEGORIES_LIST_SUCCESS,
				payload: { [field]: value }
			});
			applySearch(value);
		},
		[applySearch]
	);

	useEffect(() => {
		fetchMenuCategoriesList(0, categoriesList?.data?.searchFieldValue);
	}, []);

	return (
		<div className="menu-section-list-container section-container-common">
			<Header searchFieldValue={categoriesList.data.searchFieldValue} handleSearch={handleSearch} />
			<CommonTable
				loading={isLoading}
				data={categoriesList.data.objects || []}
				columns={columns}
				classes="menu-section-list-table-container"
			/>
			<Paginator
				limit={10}
				offset={offset}
				goToPage={handlePagination}
				count={categoriesList.data.totalObjectCount || 0}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoading: state?.menuCategoriesList?.loading,
	categoriesList: state?.menuCategoriesList
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SectionList);
