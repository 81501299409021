import React, { useState } from "react";

// components
import { Topbar } from "../_commons/Topbar";
import { Textarea } from "../_commons/Textarea";

const FORM_TABS = {
	faq: [
		{
			label: "Write",
			value: "write"
		},
		{
			label: "Preview",
			value: "preview"
		}
	],
	tos: [
		{
			label: "Write",
			value: "write"
		},
		{
			label: "Preview",
			value: "preview"
		}
	],
	privacyPolicy: [
		{
			label: "Write",
			value: "write"
		},
		{
			label: "Preview",
			value: "preview"
		}
	]
};

const Templates = ({ data, handleForm, validations }) => {
	const [formTab, setFormTab] = useState({
		faq: FORM_TABS.faq[0].value,
		tos: FORM_TABS.tos[0].value,
		privacyPolicy: FORM_TABS.privacyPolicy[0].value
	});

	const switchTab = (template, tab) => {
		const modifiedFormTab = {
			...formTab,
			[template]: tab.value
		};
		setFormTab(modifiedFormTab);
	};

	return (
		<div className="templates">
			<div templates="faq" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-faq.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Frequently Asked Questions</div>
							<div className="header-subtext">Provide information on frequent questions or concerns</div>
						</div>
					</div>
				</div>
				<Topbar tabs={FORM_TABS.faq} selectedTab={formTab.faq} switchTab={(tab) => switchTab("faq", tab)} />
				<div className="form-container">
					<div className="form-row row-full">
						{formTab.faq === "write" && (
							<Textarea
								value={data.faq}
								onChange={(e) => handleForm("faq", e.target.value)}
								validationMessage={validations.faq || ""}
							/>
						)}
						{formTab.faq === "preview" && (
							<div className="preview" dangerouslySetInnerHTML={{ __html: data.faq }}></div>
						)}
					</div>
				</div>
			</div>
			<div templates="tos" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-terms-of-service.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Terms of Service</div>
							<div className="header-subtext">
								Create a legal agreement between you and your customers, for using your apps and
								websites
							</div>
						</div>
					</div>
				</div>
				<Topbar tabs={FORM_TABS.tos} selectedTab={formTab.tos} switchTab={(tab) => switchTab("tos", tab)} />
				<div className="form-container">
					<div className="form-row row-full">
						{formTab.tos === "write" && (
							<Textarea
								value={data.tos}
								onChange={(e) => handleForm("tos", e.target.value)}
								validationMessage={validations.tos || ""}
							/>
						)}
						{formTab.tos === "preview" && (
							<div className="preview" dangerouslySetInnerHTML={{ __html: data.tos }}></div>
						)}
					</div>
				</div>
			</div>
			<div templates="privacyPolicy" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-privacy-policy.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Privacy Policy</div>
							<div className="header-subtext">
								Share with your customers how you gather, use, disclose and manage their data
							</div>
						</div>
					</div>
				</div>
				<Topbar
					tabs={FORM_TABS.privacyPolicy}
					selectedTab={formTab.privacyPolicy}
					switchTab={(tab) => switchTab("privacyPolicy", tab)}
				/>
				<div className="form-container">
					<div className="form-row row-full">
						{formTab.privacyPolicy === "write" && (
							<Textarea
								value={data.privacyPolicy}
								onChange={(e) => handleForm("privacyPolicy", e.target.value)}
								validationMessage={validations.privacyPolicy || ""}
							/>
						)}
						{formTab.privacyPolicy === "preview" && (
							<div className="preview" dangerouslySetInnerHTML={{ __html: data.privacyPolicy }}></div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Templates;
