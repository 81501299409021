import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import _ from "lodash";

// graphql
import { INIT_INVOICE_PAYMENT, PAYMENT_CAPTURE } from "../../graphql/payment";

// clients
import { store } from "../../store/configureStore";
import { client } from "../../client";

// utils
import { msaagesArrayToHtml } from "../../atlas-utils";
import { ActionTypes } from "../../actions/_types";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";

//actions
import { toggleGlobalLoader } from "../../actions/actions";

@connect((store) => ({
	biz: store.login.loggedInbizDetail,
	userInfo: store.login.loginDetail,
	dimensions: store.configItems.dimensions
}))
export class InvoicePaymentFlow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			transactionInProgress: true
		};
	}

	handlePaymentSuccess = async (txnId, gatewayTxnId) => {
		try {
			let resp = await client.mutate({
				mutation: PAYMENT_CAPTURE,
				variables: {
					txnId: txnId,
					gatewayTxnId: gatewayTxnId
				}
			});
			if (resp.data.paymentGatewayCallback.status.success) {
				// track this event
				PubSub.publish(TRACK_EVENT, {
					tracker: "mixpanel",
					eventName: "invoice_payment_complete",
					eventMeta: {
						amount: this.props.finalAmount,
						source: "Invoice List"
					}
				});

				// payment capture is successful, update the amount of credits left in users account
				try {
					this.props.paymentSuccessCallback();
				} catch (error) {
					// payment was captured succesfully but users account is not updated in localStorage
					// se we reload the page forcefully to update biz info
					console.log(error);
					window.location.reload();
				}

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Invoice payment successful!",
						timeout: 5000,
						error: false
					}
				});

				this.setState({
					transactionInProgress: false
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.paymentGatewayCallback.status.messages),
						timeout: 5000,
						error: true
					}
				});
				this.setState({
					transactionInProgress: false
				});
			}
		} catch (error) {
			alert("Something went wrong, try again!");
			console.log(error);
			this.setState({
				transactionInProgress: false
			});
		}
	};

	initPayment = async () => {
		const { finalAmount, invoiceId, invoiceRefId, biz } = this.props;
		let payable = parseInt(finalAmount * 100);
		this.setState({
			transactionInProgress: true
		});
		try {
			store.dispatch(toggleGlobalLoader(true));
			let resp = await client.mutate({
				mutation: INIT_INVOICE_PAYMENT,
				variables: {
					amount: payable,
					purposeId: invoiceId,
					purpose: "biz_service_payment",
					comments: "init",
					gateway: "razorpay",
					currency: this.props.currency ? this.props.currency : biz.billingCurrency
				}
			});
			store.dispatch(toggleGlobalLoader(false));
			let key = resp.data.initiatePaymentTxn.gwData.key;
			let txnId = resp.data.initiatePaymentTxn.paymentTxn.txnId;
			// start payment gateway flow
			var rzp1 = new window.Razorpay({
				key: key,
				amount: payable,
				currency: this.props.currency ? this.props.currency : biz.billingCurrency,
				notes: {
					srvr_trx_id: txnId,
					invoice_id: invoiceId,
					invoice_ref_id: invoiceRefId,
					biz_name: biz.name
				},
				name: "Urbanpiper",
				handler: (response) => {
					this.handlePaymentSuccess(txnId, response.razorpay_payment_id);
				},
				theme: {
					color: "#2f58f2"
				},
				image: "/assets/up-logo.png",
				prefill: {
					email: this.props.userInfo.email
				},
				modal: {
					ondismiss: () => {
						this.setState({
							transactionInProgress: false
						});
					}
				}
			});
			rzp1.open();
		} catch (err) {
			console.log(err);
			store.dispatch(toggleGlobalLoader(false));
			this.setState({
				transactionInProgress: false
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: err.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: err
				}
			});
		}
	};

	render() {
		return (
			<a
				className="upiper-id"
				onClick={this.initPayment}
				style={{ textDecoration: "none", cursor: "pointer" }}
				target="_blank"
			>
				Pay Now
			</a>
		);
	}
}
