import gql from "graphql-tag";

export const GET_HUB_CONFIGURATIONS = gql`
	query getHubConfigurations($locationId: Int, $brandId: Int) {
		configurations(locationId: $locationId, brandId: $brandId) {
			name
			logo
			keys {
				key
				shortDesc
				description
				placeholder
				value
				type
				choices
				isEditable
			}
		}
	}
`;

export const EDIT_HUB_CONFIGURATIONS = gql`
	mutation editHubConfigurations($configurations: [ConfigurationKeyInput]!, $locationId: Int, $brandId: Int) {
		saveConfigurations(input: { configurations: $configurations, locationId: $locationId, brandId: $brandId }) {
			status {
				success
				messages {
					field
					message
				}
			}
			configurations {
				name
				keys {
					key
					value
				}
			}
		}
	}
`;

export const GET_LOCATIONS_LIST = gql`
	query getLocationsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$brand: String
	) {
		stores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search, brand: $brand) {
			count
			objects {
				id
				title
			}
		}
	}
`;

export const GET_LOCATION_LEVEL_PREP_TIMES = gql`
	query getLocationPrepTimes($id: Int) {
		store(id: $id) {
			name
			city
			contactPhone
			description
			excludedPlatforms {
				id
				platformName
			}
			id
			isActive
			merchantRefId
			sortOrder
			orderPrepTime
			busyOrderPrepTime
		}
	}
`;

export const UPDATE_LOCATION_LEVEL_PREP_TIMES = gql`
	mutation editStore(
		$id: Int
		$name: String
		$city: String
		$merchantRefId: String
		$sortOrder: Int
		$contactPhone: String
		$excludedPlatforms: [String]
		$isActive: Boolean
		$description: String
		$orderPrepTime: Int
		$busyOrderPrepTime: Int
	) {
		store(
			input: {
				id: $id
				bizLocationNickname: $name
				city: $city
				merchantBizLocationId: $merchantRefId
				sortOrder: $sortOrder
				contactPhone: $contactPhone
				excludedPlatforms: $excludedPlatforms
				isActive: $isActive
				description: $description
				orderPrepTime: $orderPrepTime
				busyOrderPrepTime: $busyOrderPrepTime
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
