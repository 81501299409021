export const EDITOR_FIELD_TYPES = {
	dropdown: "dropdown",
	boolean: "boolean",
	text: "text",
	number: "number",
	color: "color",
	section: "section",
	image: "image",
	button: "button",
	arrayOfObject: "arrayOfObject"
};

export const EDITOR_THEME_CONFIG_SECTIONS = ["Colors", "Miscellaneous"];

export const EDITOR_RENDER_THEME_CONFIG = [
	// We may later need the below code
	// {
	// 	field: "font",
	// 	section: "Font",
	// 	label: "Font family",
	// 	fieldType: EDITOR_FIELD_TYPES.dropdown,
	// 	namespace: "customizations.font",
	// 	options: [
	// 		{
	// 			label: "Source Sans Pro",
	// 			value: "Source Sans Pro",
	// 		},
	// 		{
	// 			label: "Open Sans",
	// 			value: "Open Sans",
	// 		},
	// 		{
	// 			label: "Arial",
	// 			value: "Arial",
	// 		},
	// 		{
	// 			label: "Consolas",
	// 			value: "Consolas",
	// 		},
	// 	],
	// },
	{
		field: "primaryColor",
		section: "Colors",
		label: "Primary Color",
		fieldType: EDITOR_FIELD_TYPES.color,
		namespace: "customizations.colors.primaryColor",
		showCustomTooltip: true,
		tooltipPosition: "down-right",
		tooltipInfo:
			"Used as the colour for highlighting primary elements like CTA buttons, text, and sections of the website.\nShould match the Brand's primary colour."
	},
	{
		field: "secondaryColor",
		section: "Colors",
		label: "Secondary Color",
		fieldType: EDITOR_FIELD_TYPES.color,
		namespace: "customizations.colors.secondaryColor",
		nullable: true,
		showCustomTooltip: true,
		tooltipPosition: "down-left",
		tooltipInfo:
			"Used to highlight secondary elements like icons, links and text.\nShould match the Brand's colour guideline and correspond to the 2nd most important colour."
	},
	{
		field: "tertiaryColor",
		section: "Colors",
		label: "Tertiary Color",
		fieldType: EDITOR_FIELD_TYPES.color,
		namespace: "customizations.colors.tertiaryColor",
		nullable: true,
		showCustomTooltip: true,
		tooltipPosition: "down-right",
		tooltipInfo:
			"Used very sparsely for elements that provide a supporting action besides a primary and secondary. Should conform to the Brand guidelines."
	},
	{
		field: "successColor",
		section: "Colors",
		label: "Success Color",
		fieldType: EDITOR_FIELD_TYPES.color,
		namespace: "customizations.colors.successColor",
		nullable: true
	},
	{
		field: "failureColor",
		section: "Colors",
		label: "Failure Color",
		fieldType: EDITOR_FIELD_TYPES.color,
		namespace: "customizations.colors.failureColor",
		nullable: true
	},
	{
		field: "primaryTextColor",
		section: "Colors",
		label: "Primary Text Color",
		fieldType: EDITOR_FIELD_TYPES.color,
		namespace: "customizations.colors.primaryTextColor",
		nullable: true,
		showCustomTooltip: true,
		tooltipPosition: "down-left",
		tooltipInfo:
			"Set the colour used for primary text elements like category names, items names, prices, and other key text. Should align with the brand's guidelines"
	},
	{
		field: "secondaryTextColor",
		section: "Colors",
		label: "Secondary Text Color",
		fieldType: EDITOR_FIELD_TYPES.color,
		namespace: "customizations.colors.secondaryTextColor",
		nullable: true,
		showCustomTooltip: true,
		tooltipInfo:
			"Set the colour used for secondary text elements such as category and item descriptions. Should align with the brand's guidelines",
		tooltipPosition: "down-left"
	},
	{
		field: "favicon",
		section: "Miscellaneous",
		label: "Favicon",
		description: "(Aspect ratio: 1:1)",
		aspectRatio: "1:1",
		fieldType: EDITOR_FIELD_TYPES.image,
		namespace: "biz.favicon",
		namespaceRoot: true
	}
];

export const EDITOR_RENDER_COMPONENT_CONFIG = {
	topbar: {
		id: "topbar",
		label: "Top Bar",
		icon: "/assets/meraki/components/icon-topbar.svg",
		componentType: "global",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: true
			},
			{
				field: "backgroundColor",
				label: "Background Color",
				fieldType: EDITOR_FIELD_TYPES.color,
				namespace: "customizations.backgroundColor",
				default: "#FFFFFF"
			},
			{
				field: "leftLink",
				label: "Left Link",
				fieldType: EDITOR_FIELD_TYPES.section,
				config: [
					{
						field: "title",
						label: "Title",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.leftLink.title",
						default: ""
					},
					{
						field: "androidAppLink",
						label: "Android App Link",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.leftLink.androidAppLink",
						default: ""
					},
					{
						field: "iOSAppLink",
						label: "iOS App Link",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.leftLink.iOSAppLink",
						default: ""
					}
				]
			},
			{
				field: "rightLink",
				label: "Right Link",
				fieldType: EDITOR_FIELD_TYPES.section,
				config: [
					{
						field: "title",
						label: "Title",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.rightLink.title",
						default: ""
					},
					{
						field: "link",
						label: "Link",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.rightLink.link",
						default: ""
					}
				]
			}
		]
	},
	heroBannerVariant1: {
		id: "heroBanner",
		label: "Hero banner",
		icon: "/assets/meraki/components/icon-hero-banner.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "enableLocationSearch",
				label: "Enable location search",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enableLocationSearch",
				default: true
			},
			{
				field: "logoUrl",
				label: "Logo image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.logoUrl",
				description: "Recommended size: 320 x 320 pixels"
			},
			{
				field: "backgroundImage",
				label: "Background image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.backgroundImage",
				description: "Recommended size: 1280 x 720 pixels"
			}
		]
	},
	heroBannerVariant2: {
		id: "heroBanner",
		label: "Hero banner",
		icon: "/assets/meraki/components/icon-hero-banner.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "subTitle",
				label: "Subtitle",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.subTitle",
				default: ""
			},
			{
				field: "subTitleColor",
				label: "Subtitle color",
				fieldType: EDITOR_FIELD_TYPES.color,
				namespace: "customizations.subTitleColor",
				default: "yellow"
			},
			{
				field: "enableLocationSearch",
				label: "Enable location search",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enableLocationSearch",
				default: true
			},
			{
				field: "logoUrl",
				label: "Logo image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.logoUrl",
				description: "Recommended size: 320 x 320 pixels"
			},
			{
				field: "mobileFeaturedImage",
				label: "Mobile Featured image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.mobileFeaturedImage"
			},
			{
				field: "backgroundImage",
				label: "Background image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.backgroundImage",
				description: "Recommended size: 1280 x 720 pixels"
			}
		]
	},
	heroBannerVariant3: {
		id: "heroBanner",
		label: "Hero banner",
		icon: "/assets/meraki/components/icon-hero-banner.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "subTitle",
				label: "Subtitle",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.subTitle",
				default: ""
			},
			{
				field: "subTitleColor",
				label: "Subtitle color",
				fieldType: EDITOR_FIELD_TYPES.color,
				namespace: "customizations.subTitleColor",
				default: "yellow"
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "enableLocationSearch",
				label: "Enable location search",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enableLocationSearch",
				default: true
			},
			{
				field: "logoUrl",
				label: "Logo image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.logoUrl",
				description: "Recommended size: 320 x 320 pixels"
			},
			{
				field: "featuredImage",
				label: "Featured image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.featuredImage"
			},
			{
				field: "backgroundImage",
				label: "Background image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.backgroundImage",
				description: "Recommended size: 1280 x 720 pixels"
			}
		]
	},
	aboutUsVariant1: {
		id: "aboutUs",
		label: "About US",
		icon: "/assets/meraki/components/icon-about-us.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "image",
				label: "Image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.image",
				default: "",
				description: "Recommended size: 500 x 500 pixels"
			},
			{
				field: "ctaUrl",
				label: "CTA Url",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.ctaUrl",
				default: ""
			}
		]
	},
	aboutUsVariant3: {
		id: "aboutUs",
		label: "About US",
		icon: "/assets/meraki/components/icon-about-us.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "image",
				label: "Image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.image",
				default: "",
				description: "Recommended size: 500 x 500 pixels"
			},
			{
				field: "imageTwo",
				label: "Image two",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.imageTwo",
				default: "",
				description: "Recommended size: 500 x 500 pixels"
			},
			{
				field: "ctaUrl",
				label: "CTA Url",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.ctaUrl",
				default: ""
			}
		]
	},
	appPromotion: {
		id: "appPromotion",
		label: "App Promotion",
		icon: "/assets/meraki/components/icon-app-promotions.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "availableOnAndroid",
				label: "Available on Android",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.availableOnAndroid"
			},
			{
				field: "androidAppUrl",
				label: "Android Link",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.androidAppUrl",
				default: ""
			},
			{
				field: "availableOnIOS",
				label: "Available on iOS",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.availableOnIOS"
			},
			{
				field: "iosAppUrl",
				label: "iOS Link",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.iosAppUrl",
				default: ""
			},
			{
				field: "phoneImage",
				label: "Preview image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.phoneImage"
			},
			{
				field: "phoneLargeImage",
				label: "Preview image large",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.phoneLargeImage",
				description: "Recommended size: 315 x 560 pixels"
			}
		]
	},
	brandValuesVariant1: {
		id: "brandValues",
		label: "Brand values",
		icon: "/assets/meraki/components/icon-brand-values.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "readMoreCtaUrl",
				label: "Read more URL",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.readMoreCtaUrl",
				default: ""
			},
			{
				field: "values",
				label: "Values",
				fieldType: EDITOR_FIELD_TYPES.arrayOfObject,
				namespace: "customizations.values",
				default: [],
				objectStructure: {
					allowCreate: true,
					allowDelete: true,
					labelKey: "label",
					valueKey: "id",
					schema: [
						{
							key: "label",
							type: "text",
							label: "Label"
						},
						{
							key: "description",
							type: "text",
							label: "Description"
						},
						{
							key: "icon",
							type: "image",
							label: "Icon",
							aspectRatio: "1:1",
							description: "Recommended size: 75 x 75 pixels"
						}
					]
				}
			}
		]
	},
	brandValuesVariant2: {
		id: "brandValues",
		label: "Brand values",
		icon: "/assets/meraki/components/icon-brand-values.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "readMoreCtaUrl",
				label: "Read more URL",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.readMoreCtaUrl",
				default: ""
			},
			{
				field: "values",
				label: "Values",
				fieldType: EDITOR_FIELD_TYPES.arrayOfObject,
				namespace: "customizations.values",
				default: [],
				objectStructure: {
					allowCreate: true,
					allowDelete: true,
					labelKey: "label",
					valueKey: "id",
					schema: [
						{
							key: "label",
							type: "text",
							label: "Label"
						},
						{
							key: "description",
							type: "text",
							label: "Description"
						},
						{
							key: "icon",
							type: "image",
							label: "Icon",
							aspectRatio: "1:1",
							description: "Recommended size: 75 x 75 pixels"
						}
					]
				}
			}
		]
	},
	brandValuesVariant3: {
		id: "brandValues",
		label: "Brand values",
		icon: "/assets/meraki/components/icon-brand-values.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "values",
				label: "Values",
				fieldType: EDITOR_FIELD_TYPES.arrayOfObject,
				namespace: "customizations.values",
				default: [],
				objectStructure: {
					allowCreate: true,
					allowDelete: true,
					labelKey: "label",
					valueKey: "id",
					schema: [
						{
							key: "label",
							type: "text",
							label: "Label"
						},
						{
							key: "description",
							type: "text",
							label: "Description"
						},
						{
							key: "icon",
							type: "image",
							label: "Icon",
							aspectRatio: "1:1",
							description: "Recommended size: 75 x 75 pixels"
						}
					]
				}
			}
		]
	},
	menuSection: {
		id: "menuSection",
		label: "Menu Section",
		icon: "/assets/meraki/components/icon-menu-section.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "mode",
				label: "Display Type",
				fieldType: EDITOR_FIELD_TYPES.dropdown,
				namespace: "customizations.mode",
				default: "",
				options: [
					{
						label: "Categories",
						value: "categories"
					},
					{
						label: "Items",
						value: "items"
					}
				]
			},
			{
				field: "displayNumber",
				label: "Display Number",
				fieldType: EDITOR_FIELD_TYPES.dropdown,
				namespace: "customizations.displayNumber",
				description: "(Applicable only on categories type)",
				default: "default",
				options: [
					{
						label: "Default(6)",
						value: "default"
					},
					{
						label: "Show All",
						value: "showAll"
					}
				]
			}
		]
	},
	recommendedItemsVariant1: {
		id: "recommendedItems",
		label: "Featured section",
		icon: "/assets/meraki/components/icon-items.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "backgroundImage",
				label: "Background Image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.backgroundImage",
				default: "",
				description: "Recommended size: 500 x 500 pixels"
			}
		]
	},
	recommendedItemsVariant2: {
		id: "recommendedItems",
		label: "Featured section",
		icon: "/assets/meraki/components/icon-items.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "ctaUrl",
				label: "CTA Url",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.ctaUrl",
				default: ""
			},
			{
				field: "featuredDishImage",
				label: "Featured Dish Image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.featuredDishImage",
				default: "",
				aspectRatio: "1:1",
				description: "Recommended size: 500 x 500 pixels"
			}
		]
	},
	recommendedItemsVariant3: {
		id: "recommendedItems",
		label: "Featured section",
		icon: "/assets/meraki/components/icon-items.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title",
				default: ""
			},
			{
				field: "description",
				label: "Description",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.description",
				default: ""
			},
			{
				field: "ctaUrl",
				label: "CTA Url",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.ctaUrl",
				default: ""
			},
			{
				field: "featuredDishImage",
				label: "Featured Dish Image",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "customizations.featuredDishImage",
				default: "",
				aspectRatio: "1:1",
				description: "Recommended size: 500 x 500 pixels"
			}
		]
	},
	testimonials: {
		id: "testimonials",
		label: "Testimonials",
		icon: "/assets/meraki/components/icon-testimonials.svg",
		componentType: "local",
		config: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.enabled",
				default: false
			},
			{
				field: "values",
				label: "Testimonials",
				fieldType: EDITOR_FIELD_TYPES.arrayOfObject,
				namespace: "customizations.values",
				default: [],
				objectStructure: {
					allowCreate: true,
					allowDelete: true,
					labelKey: "author_name",
					valueKey: "id",
					schema: [
						{
							key: "author_name",
							type: "text",
							label: "Author Name"
						},
						{
							key: "author_position",
							type: "text",
							label: "Author Position"
						},
						{
							key: "content",
							type: "text",
							label: "Content"
						},
						{
							key: "author_img",
							type: "image",
							label: "Author Image",
							aspectRatio: "1:1",
							description: "Recommended size: 100 x 100 pixels"
						}
					]
				}
			}
		]
	},
	header: {
		id: "header",
		label: "Header",
		icon: "/assets/meraki/components/icon-header.svg",
		componentType: "global",
		config: [
			{
				field: "backgroundColor",
				label: "Background Color",
				fieldType: EDITOR_FIELD_TYPES.color,
				namespace: "customizations.backgroundColor",
				default: "#FFFFFF",
				nullable: true,
				showCustomTooltip: true,
				tooltipPosition: "down-left",
				tooltipInfo: "Choose a color for the website header background. Should conform to the Brand guidelines"
			},
			{
				field: "logoUrl",
				label: "Brand Logo",
				description: "(Recommended size: 120 x 80 pixels)",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "biz.logoUrl",
				namespaceRoot: true
			},
			{
				field: "logoUrlSquare",
				label: "Brand Logo (square)",
				fieldType: EDITOR_FIELD_TYPES.image,
				namespace: "biz.logoUrlSquare",
				namespaceRoot: true,
				aspectRatio: "1:1",
				description: "(Aspect ratio: 1:1)",
				showCustomTooltip: true,
				tooltipPosition: "down-left",
				tooltipInfo: "Upload a square version of your brand logo which is used on the mobile website."
			}
		]
	},
	banners: {
		id: "banners",
		label: "Banners",
		icon: "/assets/meraki/components/icon-banners.svg",
		componentType: "local",
		config: []
	},
	categories: {
		id: "categories",
		label: "Categories",
		icon: "/assets/meraki/components/icon-categories.svg",
		componentType: "local",
		config: []
	},
	footer: {
		id: "footer",
		label: "Footer",
		icon: "/assets/meraki/components/icon-footer.svg",
		componentType: "global",
		config: [
			{
				field: "bgColorMode",
				label: "Background Color mode",
				fieldType: EDITOR_FIELD_TYPES.dropdown,
				namespace: "customizations.bgColorMode",
				default: "default",
				options: [
					{
						label: "Default color",
						value: "default"
					},
					{
						label: "Primary color",
						value: "primary"
					}
				]
			},
			{
				field: "copyrightText",
				label: "Copyright Text",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.copyrightText",
				default: ""
			},
			{
				field: "externalPageLinks",
				label: "External page links",
				fieldType: EDITOR_FIELD_TYPES.arrayOfObject,
				namespace: "customizations.externalPageLinks",
				default: [],
				objectStructure: {
					allowCreate: true,
					allowDelete: true,
					labelKey: "name",
					valueKey: "id",
					schema: [
						{
							key: "name",
							type: "text",
							label: "Name"
						},
						{
							key: "url",
							type: "text",
							label: "URL"
						}
					]
				}
			},
			{
				field: "socialMediaLinks",
				label: "Social Media links",
				fieldType: EDITOR_FIELD_TYPES.section,
				config: [
					{
						field: "facebook",
						label: "Facebook",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.socialMediaLinks.facebook",
						default: ""
					},
					{
						field: "googleplus",
						label: "Google plus",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.socialMediaLinks.googleplus",
						default: ""
					},
					{
						field: "linkedin",
						label: "Linkedin",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.socialMediaLinks.linkedin",
						default: ""
					},
					{
						field: "twitter",
						label: "Twitter",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.socialMediaLinks.twitter",
						default: ""
					},
					{
						field: "youtube",
						label: "Youtube",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.socialMediaLinks.youtube",
						default: ""
					},
					{
						field: "instagram",
						label: "Instagram",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.socialMediaLinks.instagram",
						default: ""
					}
				]
			},
			{
				field: "contactUs",
				label: "Contact",
				fieldType: EDITOR_FIELD_TYPES.section,
				config: [
					{
						field: "phone",
						label: "Phone",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.contactUs.phone",
						default: ""
					},
					{
						field: "email",
						label: "Email",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.contactUs.email",
						default: ""
					},
					{
						field: "address",
						label: "Address",
						fieldType: EDITOR_FIELD_TYPES.text,
						namespace: "customizations.contactUs.address",
						default: ""
					}
				]
			}
		]
	},
	items: {
		id: "items",
		label: "Items",
		icon: "/assets/meraki/components/icon-items.svg",
		componentType: "local",
		config: []
	},
	customerInfo: {
		id: "customerInfo",
		label: "Customer Info",
		icon: "/assets/meraki/components/icon-customer-info.svg",
		componentType: "local",
		config: []
	},
	image: {
		id: "image",
		label: "Image",
		icon: "/assets/meraki/components/icon-image.svg",
		componentType: "local",
		config: []
	},
	deliveryAddress: {
		id: "deliveryAddress",
		label: "Delivery Address",
		icon: "/assets/meraki/components/icon-delivery-address.svg",
		componentType: "local",
		config: []
	},
	itemInfo: {
		id: "itemInfo",
		label: "Item Info",
		icon: "/assets/meraki/components/icon-item-info.svg",
		componentType: "local",
		config: []
	},
	offers: {
		id: "offers",
		label: "Offers",
		icon: "/assets/meraki/components/icon-offer.svg",
		componentType: "local",
		config: []
	},
	settings: {
		id: "settings",
		label: "Settings",
		icon: "/assets/meraki/components/icon-settings.svg",
		componentType: "local",
		config: []
	},
	dateTime: {
		id: "dateTime",
		label: "Date & Time",
		icon: "/assets/meraki/components/icon-date-time.svg",
		componentType: "local",
		config: []
	},
	cart: {
		id: "cart",
		label: "Cart",
		icon: "/assets/meraki/components/icon-cart.svg",
		componentType: "local",
		config: []
	},
	referEarn: {
		id: "referEarn",
		label: "Refer & Earn",
		icon: "/assets/meraki/components/icon-refer-earn.svg",
		componentType: "local",
		config: []
	},
	orders: {
		id: "orders",
		label: "Orders",
		icon: "/assets/meraki/components/icon-orders.svg",
		componentType: "local",
		config: []
	},
	payments: {
		id: "payments",
		label: "Payments",
		icon: "/assets/meraki/components/icon-payments.svg",
		componentType: "local",
		config: []
	},
	form: {
		id: "form",
		label: "Form",
		icon: "/assets/meraki/components/icon-contact-form.svg",
		componentType: "local",
		default: {
			_id: "form",
			name: "Form",
			customizations: {
				title: "Contact US",
				backgroundColor: "#cbcbcd",
				fields: [
					{
						id: "1",
						label: "Name",
						type: "text",
						required: true
					},
					{
						id: "2",
						label: "Email",
						type: "email",
						required: true
					}
				]
			}
		},
		config: [
			{
				field: "title",
				label: "Title",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.title"
			},
			{
				field: "backgroundColor",
				label: "Background color",
				fieldType: EDITOR_FIELD_TYPES.color,
				namespace: "customizations.backgroundColor"
			},
			{
				field: "fields",
				label: "Fields",
				fieldType: EDITOR_FIELD_TYPES.arrayOfObject,
				namespace: "customizations.fields",
				default: [],
				objectStructure: {
					allowCreate: true,
					allowDelete: true,
					labelKey: "label",
					valueKey: "id",
					schema: [
						{
							key: "label",
							type: "text",
							label: "Field label"
						},
						{
							key: "type",
							type: "dropdown",
							label: "Field type",
							options: [
								{
									value: "text",
									label: "Text"
								},
								{
									value: "email",
									label: "Email"
								},
								{
									value: "tel",
									label: "Phone"
								}
							]
						},
						{
							key: "required",
							type: "boolean",
							label: "Field required"
						}
					]
				}
			}
		]
	}
};

export const LANDING_PAGE_VARIANTS = [
	{
		variant: "theme1",
		label: "Hubble",
		screenshot: "/assets/meraki/landing_page_1.svg"
	},
	{
		variant: "theme2",
		label: "Webb",
		screenshot: "/assets/meraki/landing_page_2.svg"
	},
	{
		variant: "theme3",
		label: "Grace",
		screenshot: "/assets/meraki/landing_page_3.svg"
	}
];

export const EDITOR_RENDER_PAGES_CONFIG = {
	landing_screen: {
		customizations: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "enabled",
				default: false
			}
		],
		componentsByVariants: {
			[LANDING_PAGE_VARIANTS[0].variant]: [
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.heroBannerVariant1
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.aboutUsVariant1
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.brandValuesVariant1
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.menuSection
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.testimonials
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.appPromotion
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.footer
				}
			],
			[LANDING_PAGE_VARIANTS[1].variant]: [
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.heroBannerVariant2
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.brandValuesVariant2
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.recommendedItemsVariant2
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.menuSection
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.testimonials
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.appPromotion
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.footer
				}
			],
			[LANDING_PAGE_VARIANTS[2].variant]: [
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.heroBannerVariant3
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.aboutUsVariant3
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.brandValuesVariant3
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.recommendedItemsVariant3
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.menuSection
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.testimonials
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.appPromotion
				},
				{
					component: EDITOR_RENDER_COMPONENT_CONFIG.footer
				}
			]
		}
	},
	menu: {
		customizations: [
			{
				field: "likeButton",
				label: "Show Like Button",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.likeButton",
				default: true,
				showCustomTooltip: true,
				tooltipPosition: "down-left",
				tooltipInfo:
					'Show a "like" icon for all items across the website. Users can click the icon to add the item to their favourites'
			},
			{
				field: "showItemLikes",
				label: "Show Likes Count",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.showItemLikes",
				default: true,
				nullable: true,
				showCustomTooltip: true,
				tooltipPosition: "down-left",
				tooltipInfo: "Show count of total likes for an item beside the like icon"
			},
			{
				field: "recommendedItems",
				label: "Recommended Items",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.recommendedItems",
				default: true,
				showCustomTooltip: true,
				tooltipPosition: "down-left",
				tooltipInfo:
					"Showcase a selection of recommended items on the menu. If enabled it is visible as the first category on the menu"
			},
			{
				field: "showFoodType",
				label: "Show Food Type",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.showFoodType",
				default: true,
				tooltipInfo:
					"Show labels on the item cards indicating whether a dish is vegetarian, eggetarian, or non-vegetarian.",
				showCustomTooltip: true,
				tooltipPosition: "down-left"
			},
			{
				field: "showItemSort",
				label: "Show Sort",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.showItemSort",
				default: true,
				showCustomTooltip: true,
				tooltipPosition: "down-right",
				tooltipInfo: "Show UI control to let users sort items on the menu page"
			},
			{
				field: "showItemFilter",
				label: "Show Filter",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.showItemFilter",
				default: true,
				showCustomTooltip: true,
				tooltipPosition: "down-right",
				tooltipInfo: "Show UI control to let users filter menu items by different tags"
			},
			{
				field: "showItemSearch",
				label: "Show Search",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.showItemSearch",
				default: true,
				showCustomTooltip: true,
				tooltipPosition: "down-right",
				tooltipInfo: "Show search bar on UI to let users search for specific menu items"
			}
		],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.banners
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.categories
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.items
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	item_detail: {
		customizations: [
			{
				field: "recommendedItems",
				label: "Recommended Items",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.recommendedItems",
				default: true,
				tooltipPosition: "down-left",
				tooltipInfo:
					"Show recommended items related to the current item on the page. The recommendations are also shown on the checkout page to upsell items",
				showCustomTooltip: true
			}
		],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.image
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.itemInfo
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	order_history: {
		customizations: [
			{
				field: "reorder",
				label: "Reorder",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.reorder",
				default: true,
				tooltipPosition: "down-right",
				tooltipInfo: 'Show a "Reorder" button for customers to quickly reorder from order history',
				showCustomTooltip: true
			}
		],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.orders
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	checkout: {
		customizations: [
			{
				field: "cashbackCredits",
				label: "Cashback Credits",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "customizations.cashbackCredits",
				default: true,
				tooltipPosition: "down-left",
				showCustomTooltip: true,
				tooltipInfo: "Show UI control to let users apply cashback credits during checkout"
			},
			{
				field: "message",
				label: "Message",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "customizations.message",
				default: true,
				tooltipPosition: "down-right",
				tooltipInfo:
					"Enter a message to be shown on the checkout page to inform customers about payment/delivery related information ",
				showCustomTooltip: true
			}
		],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.customerInfo
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.deliveryAddress
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.dateTime
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.payments
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.cart
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	favourite: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	cart: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.items
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	wallet: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	refer_earn: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	profile: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.settings
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.referEarn
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.offers
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	order_success: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	privacy: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	terms: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	store_locator: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	coupons: {
		customizations: [],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	page_not_found: {
		customizations: [
			{
				field: "enabled",
				label: "Enabled",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "enabled",
				default: true
			}
		],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		]
	},
	custom_pages: {
		customizations: [
			{
				field: "name",
				label: "Name",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "name"
			},
			{
				field: "path",
				label: "URL Path",
				fieldType: EDITOR_FIELD_TYPES.text,
				namespace: "path"
			},
			{
				field: "addToMenu",
				label: "Add to Menu",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "addToMenu"
			},
			{
				field: "addToFooter",
				label: "Add to Footer",
				fieldType: EDITOR_FIELD_TYPES.boolean,
				namespace: "addToFooter"
			},
			{
				field: "delete",
				label: "Delete",
				fieldType: EDITOR_FIELD_TYPES.button,
				actionClass: "danger",
				namespace: ""
			}
		],
		components: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.topbar
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.header
			},
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.footer
			}
		],
		addonComponents: [
			{
				component: EDITOR_RENDER_COMPONENT_CONFIG.form
			}
		]
	}
};
