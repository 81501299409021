import React from "react";
import { Transition, config, animated } from "react-spring/renderprops";

const HoverContentDisplayGrid = ({ show, classes, position = { x: 0, y: 0 }, children }) => {
	return (
		<Transition
			native
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0 }}
			items={show}
			config={config.stiff}
		>
			{(isOpen) =>
				isOpen &&
				((props) => (
					<animated.div style={props}>
						<div
							className={classes}
							style={{
								top: position?.y,
								left: position?.x
							}}
						>
							{children}
						</div>
					</animated.div>
				))
			}
		</Transition>
	);
};
export default HoverContentDisplayGrid;
