export const marsDataSample = {
	status: true,
	data: [
		{
			categories: [
				{
					ref_id: "c-1b956992-9a5e-4d9f-b442-f3ccd911027e",
					name: "Match Day Mania Combos",
					ref_title: "-Match Day Mania Combos",
					sort_order: 1,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-b777e448-f0da-438e-a9e5-dc6b4b0b000a",
					name: "Gourmet Burgers and Meals",
					ref_title: "-Gourmet Burgers and Meals",
					sort_order: 2,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-f5f760d2-2f4b-43ac-b5c0-8a42dd761c4d",
					name: "Chicken Sharers",
					ref_title: "-Chicken Sharers",
					sort_order: 3,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-b6f19bb3-11bc-4908-b6e7-f6234fad9bfd",
					name: "Mcspicy Fried Chicken",
					ref_title: "-Mcspicy Fried Chicken",
					sort_order: 4,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-ba0fb544-3398-4874-a83e-ddcbdb4de30a",
					name: "Combos for One: Up to 60% Off",
					ref_title: "-Combos for One: Up to 60% Off",
					sort_order: 5,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-322b8a2d-4d42-4418-9841-34da23844b5d",
					name: "McSaver Meals: FLAT Rs. 50 Off",
					ref_title: "-McSaver Meals: FLAT Rs. 50 Off",
					sort_order: 6,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01",
					name: "Party Pack Combos",
					ref_title: "-Party Pack Combos",
					sort_order: 7,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-871f7821-f4bb-43bd-85d9-05315395baa7",
					name: "Best Value Meals with Drinks",
					ref_title: "-Best Value Meals with Drinks",
					sort_order: 8,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-0843cbce-d8b5-44cc-adbb-eaefad6193ec",
					name: "Everyday Combos",
					ref_title: "-Everyday Combos",
					sort_order: 9,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-d0114618-8513-41a7-b0fc-20a2f3e357e1",
					name: "McSavers",
					ref_title: "-McSavers",
					sort_order: 10,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-38acbe51-131a-46f4-8c70-68f610f3bc13",
					name: "Burgers & Wraps",
					ref_title: "-Burgers & Wraps",
					sort_order: 11,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-053f5b1c-88e2-4756-91bf-398f167279bc",
					name: "McCafe",
					ref_title: "-McCafe",
					sort_order: 12,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-795d101f-6748-43b2-9ce5-729fb53fc0dc",
					name: "McCafe Cold Beverages",
					ref_title: "-McCafe Cold Beverages",
					sort_order: 13,
					active: true,
					translations: [],
					img_url: "",
					parent_ref_id: "c-053f5b1c-88e2-4756-91bf-398f167279bc"
				},
				{
					ref_id: "c-f6a2c29c-7405-43d9-8087-ae4b788a8067",
					name: "McCafe Hot Beverages",
					ref_title: "-McCafe Hot Beverages",
					sort_order: 14,
					active: true,
					translations: [],
					img_url: "",
					parent_ref_id: "c-053f5b1c-88e2-4756-91bf-398f167279bc"
				},
				{
					ref_id: "c-98b20d76-614e-4afc-b9e1-b8f82123fd14",
					name: "Beverages",
					ref_title: "-Beverages",
					sort_order: 15,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076",
					name: "Sides",
					ref_title: "-Sides",
					sort_order: 16,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-75042c4a-30c1-412d-914b-2031bad9cac7",
					name: "Desserts",
					ref_title: "-Desserts",
					sort_order: 17,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-a5b08bb8-fe89-4dd8-bc41-5a386f837ade",
					name: "Want to repeat?",
					ref_title: "-Want to repeat?",
					sort_order: 18,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-651392e6-d504-4ec5-bb31-d7a0f5e54005",
					name: "McBreakfast",
					ref_title: "-McBreakfast",
					sort_order: 19,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-ffb05336-bccd-48a7-9251-57eae6729558",
					name: "McBreakfast Meals",
					ref_title: "-McBreakfast Meals",
					sort_order: 20,
					active: true,
					translations: [],
					img_url: ""
				},
				{
					ref_id: "c-a91df310-c00a-401e-8cca-377a7327de65",
					name: "Breakfast Sides",
					ref_title: "-Breakfast Sides",
					sort_order: 21,
					active: true,
					translations: [],
					img_url: ""
				}
			],
			items: [
				{
					ref_id: "i-63522ee8-1661-4feb-a6c1-890c7bb05033",
					ref_title: "-Berry Lemonade Splash Regular",
					title: "Berry Lemonade Splash Regular",
					active: true,
					description:
						"A refreshing drink, made with the flavors of strawberries and blueberries  a definite crowd-pleaser. Now in a new, convenient and delivery friendly packaging.",
					sort_order: 0,
					price: 107.62,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/hmp9bpvremjbzrgvohqo.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-8f6bb11c-619d-4ab7-8bef-aa9100f28bab",
					ref_title: "-Pizza Mc Puff + McSpicy Chicken",
					title: "Pizza Mc Puff + McSpicy Chicken",
					active: true,
					description:
						"A combo of your favourite McSpicy Chicken Burger and Pizza McPuff. You can't go wrong with this.",
					sort_order: 1,
					price: 162.0,
					food_type: "2",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/xmoravgvexnuxloxgzeb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4ed940d9-3d0a-4b4b-860e-c72b23ed0ea5",
					ref_title: "-Flat White",
					title: "Flat White",
					active: true,
					description:
						"Our signature McCafe coffee made with 100% Arabica beans, topped with steamed 100% real milk.",
					sort_order: 2,
					price: 147.62,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gjm6yvj1pfgaieftcuf6.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-c98122ac-1e8f-4c17-825a-b7efcabe372f",
					ref_title: "-Moroccan Mint Green Tea",
					title: "Moroccan Mint Green Tea",
					active: true,
					description: "A refreshing tea, freshly brewed with a hint of Moroccon Mint flavour.",
					sort_order: 3,
					price: 157.14,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/edabzefcpshhuixwvzyr.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-acb6059c-8efe-4f40-ae19-e14c71a82414",
					ref_title: "-English Breakfast",
					title: "English Breakfast",
					active: true,
					description:
						"Relax. It's your time to enjoy a long, slow sip of delicious and delectable english breakfast tea",
					sort_order: 4,
					price: 131.42,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pr2xu3kdnt7hlgzioijw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-9d9016ac-ad96-4d25-94d2-c20c23697402",
					ref_title: "-Celebration box",
					title: "Celebration box",
					active: true,
					description:
						"Celebration box: 4 pcs of juicy, crispy McSpicy Fried chicken + 6 chicken McNuggets + 6 chicken strips",
					sort_order: 5,
					price: 640.0,
					food_type: "2",
					category_ref_ids: ["c-f5f760d2-2f4b-43ac-b5c0-8a42dd761c4d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/elq10kypb3rnbp2uubk0.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-b6671eec-621b-4222-b806-b7d8feeb03c5",
					ref_title: "-Duet box",
					title: "Duet box",
					active: true,
					description:
						"Duet box: 4 pcs of juicy, crispy McSpicy Fried chicken + 1 coke in a new, delivery friendly, reusable bottle",
					sort_order: 6,
					price: 355.01,
					food_type: "2",
					category_ref_ids: ["c-f5f760d2-2f4b-43ac-b5c0-8a42dd761c4d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/xhtht8xuy4tdt0tivr1m.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-e3529d2a-49d2-4f99-baf8-b2df3c85c6a5",
					ref_title: "-Snack box",
					title: "Snack box",
					active: true,
					description: "Snack box: 10 pcs Chicken strips",
					sort_order: 7,
					price: 320.0,
					food_type: "2",
					category_ref_ids: ["c-f5f760d2-2f4b-43ac-b5c0-8a42dd761c4d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ikxndphxohrzvimvmrxi.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-72e2320e-b8f7-453d-a2fa-a12638e17ba5",
					ref_title:
						"-McSpicy Chicken + American Cheese Supreme + McChicken + Chicken Maharaja + 3 Fries (L)",
					title: "McSpicy Chicken + American Cheese Supreme + McChicken + Chicken Maharaja + 3 Fries (L)",
					active: true,
					description:
						"Share a hearty meal of McSpicy Chicken Burger + American Cheese Supreme Chicken Burger + McChicken+  Maharaja Mac Chicken + 3 Fries (L)",
					sort_order: 8,
					price: 935.0,
					food_type: "2",
					category_ref_ids: ["c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/kfln59ry9ggdvdplwkgw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-41d09d64-2413-45a7-b4e6-eb6d17b52761",
					ref_title: "-2 McSpicy Chicken + McNuggets 20 Pcs + Fries (L) + Coke",
					title: "2 McSpicy Chicken + McNuggets 20 Pcs + Fries (L) + Coke",
					active: true,
					description:
						"Share a meal of 2 McSpicy Chicken burgers + Chicken McNuggets (20 Pcs) + Fries (L) + Coke (Now in a new, re-usable, delivery friendly packaging)",
					sort_order: 9,
					price: 829.01,
					food_type: "2",
					category_ref_ids: ["c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ndgysblgjdsd0bllwp9c.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-119e85b0-3294-40b4-88af-1bc138bd764d",
					ref_title: "-2 American Cheese Supreme Chicken + Chicken McNuggets 6 Pc + 2 Fries (L) + Coke",
					title: "2 American Cheese Supreme Chicken + Chicken McNuggets 6 Pc + 2 Fries (L) + Coke",
					active: true,
					description:
						"Share a meal of 2 American Cheese Supreme Chicken Burgers, Chicken McNuggets (6 pc) + 2 Fries (L) + Coke (Now in a new, re-usable, delivery friendly packaging)",
					sort_order: 10,
					price: 676.0,
					food_type: "2",
					category_ref_ids: ["c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/mdblsrtcf0mvwhb60jes.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-f5abc2c1-efc2-40c2-9f9f-927e05d69a56",
					ref_title: "-McSpicy Paneer + McSpicy Chicken + 2 Fries (L) + Coke",
					title: "McSpicy Paneer + McSpicy Chicken + 2 Fries (L) + Coke",
					active: true,
					description:
						"Share a meal of McSpicy Paneer Burger + McSpicy Chicken Burger + 2 Fries (L) + Coke (Now in a new, re-usable, delivery friendly packaging).",
					sort_order: 11,
					price: 610.0,
					food_type: "2",
					category_ref_ids: ["c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/hmo6f6l4j3ifqvhjpwmf.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-9215acb0-737d-4894-ba1c-3045ffe525a8",
					ref_title: "-McChicken + McVeggie + Fries (L) + 2 Veg Pizza McPuff + Coke",
					title: "McChicken + McVeggie + Fries (L) + 2 Veg Pizza McPuff + Coke",
					active: true,
					description:
						"Share a meal of McChciken Burger + McVeggie Burger + Fries (L) + 2 Veg Pizza McPuff + Coke (Now in a new, re-usable, delivery friendly packaging). There is something for everyone.",
					sort_order: 12,
					price: 476.0,
					food_type: "2",
					category_ref_ids: ["c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/hb6m5sfogibnlvkmulpc.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-a10edb57-8dfe-4751-9ed8-3c851cc05ff5",
					ref_title: "-2 McAloo Tikki + 2 Fries (L)",
					title: "2 McAloo Tikki + 2 Fries (L)",
					active: true,
					description: "Share a combo of 2 McAloo Tikki Burgers + 2 Fries (L)",
					sort_order: 13,
					price: 295.0,
					food_type: "1",
					category_ref_ids: ["c-1b956992-9a5e-4d9f-b442-f3ccd911027e"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/vbn7xgamzhtxafucpgu5.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-a7f8eec1-2de4-4bcd-8e8f-b166d8ce602c",
					ref_title: "-4 American Cheese Supreme Chicken + 2 Fries (M) + 2 Coke",
					title: "4 American Cheese Supreme Chicken + 2 Fries (M) + 2 Coke",
					active: true,
					description:
						"Share a hearty meal of 4 American Cheese Supreme Chicken + 2 Fries (M) + 2 Coke (Now in a new, re-usable, delivery friendly packaging)",
					sort_order: 14,
					price: 855.01,
					food_type: "2",
					category_ref_ids: ["c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pyodb7qc40q9v8os16kc.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-6bb533c5-0ef7-4935-99f7-462a91999564",
					ref_title: "-Latte (S)",
					title: "Latte (S)",
					active: true,
					description:
						"Treat yourself to the smooth and sophisticated Latte. Made with our signature McCafe cofee and 100% real milk steamed and topped with light foam",
					sort_order: 15,
					price: 141.9,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/msndsrkrrqydbaryzbh8.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-f6d720c7-ec24-42ac-9883-5f6fa0647806",
					ref_title: "-McCafe Americano Regular",
					title: "McCafe Americano Regular",
					active: true,
					description:
						"Our Americano is bold and robust, made with our signature McCafe coffee combined with hot water. For coffee aficionados who prefer a bolder cup.",
					sort_order: 16,
					price: 169.52,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/syeejtblyrapongs81sn.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-9a21847d-d80b-4573-a3ae-a3d81da039d2",
					ref_title: "-Green Apple Chiller",
					title: "Green Apple Chiller",
					active: true,
					description:
						"A perfect blend of aromatic teas, infused with green apple flavour. Now in a new, convenient and delivery friendly packaging",
					sort_order: 17,
					price: 147.62,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/yvudgpp29nuhubpyj4oh.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-93cab6bd-bf2a-4baf-ba40-cccffdd8d93b",
					ref_title: "-American Mud Pie",
					title: "American Mud Pie",
					active: true,
					description:
						"A classic American Mud Pie gets a makeover; wonderfully creamy and rich with Chocolate, this shake is more delicious than it sounds! Now in a new, convenient and delivery friendly packaging.",
					sort_order: 18,
					price: 175.24,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/sorurwnaz3kpj3h7awyh.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-8a9d5092-22b6-4363-b8e2-545f8fb3d380"],
					included_platforms: []
				},
				{
					ref_id: "i-a6dc74c7-e51f-4e77-aa13-4b944e6314e5",
					ref_title: "-Strawberry Chiller",
					title: "Strawberry Chiller",
					active: true,
					description:
						"A perfect blend of aromatic teas, infused with strawberry flavour . Now in a new, convenient and delivery friendly packaging.",
					sort_order: 19,
					price: 147.62,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/umswwfpjpmw4socadduf.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-7a4c1a48-92ef-425e-a69d-7be516ec0807",
					ref_title: "-Hot Chocolate (S)",
					title: "Hot Chocolate (S)",
					active: true,
					description:
						"A perfect silky treat made with sinful chocolate steamed with 100% real milk and topped with an even sweeter touch of more chocolate.",
					sort_order: 20,
					price: 153.34,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ibh6uwaenh8kyeyht30c.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-683b7021-e5db-4516-b821-b42ee28400cf",
					ref_title: "-McCafe-Classic Coffee",
					title: "McCafe-Classic Coffee",
					active: true,
					description:
						"An irrestible blend of our signature espresso and soft serve with whipped cream on top, a timeless combination! Now in a new, convenient and delivery friendly packaging.",
					sort_order: 21,
					price: 191.42,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/uuadpqsrwgkrm05mkeer.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-e8523741-f565-4960-8cca-218502cd8967",
					ref_title: "-McCafe-Ice Coffee",
					title: "McCafe-Ice Coffee",
					active: true,
					description:
						"An irrestible combination of our signature espresso and soft serve, which is to die for! Now in a new, convenient and delivery friendly packaging",
					sort_order: 22,
					price: 162.86,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/jgclb7ovo81gam2df0ev.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4dbaeb41-5d8d-4b14-8782-d2736c8a1291",
					ref_title: "-Lemon Chiller",
					title: "Lemon Chiller",
					active: true,
					description:
						"A perfect blend of aromatic teas, infused with lemon flavour.Now in a new, convenient and delivery friendly packaging.",
					sort_order: 23,
					price: 147.62,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/fxozvbu55ukds6w3xcjz.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-1b6905f2-18a7-4adb-ba77-f588352cf940",
					ref_title: "-Raw Mango Fruit Splash Regular",
					title: "Raw Mango Fruit Splash Regular",
					active: true,
					description:
						"Sweet & tangy Raw Mango crush with mango bits blended with chilled water. Now in a new, convenient and delivery friendly packaging.",
					sort_order: 24,
					price: 107.62,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/r7d7c0gej619xv62a4kw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-dcf05f4b-7915-4651-ac3d-efee6e20e828",
					ref_title: "-Strawberry Green Tea",
					title: "Strawberry Green Tea",
					active: true,
					description: "A refreshing tea, freshly brewed with a hint of strawberry flavour.",
					sort_order: 25,
					price: 123.8,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/edabzefcpshhuixwvzyr.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4d7a4dfb-68ee-4317-9628-a48837d35889",
					ref_title: "-McSpicy Paneer Burger",
					title: "McSpicy Paneer Burger",
					active: true,
					description:
						"Rich and filling cottage cheese patty coated in spicy, crispy batter topped with a creamy sauce and shredded lettuce will have you craving for more.",
					sort_order: 26,
					price: 165.0,
					food_type: "1",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/mo9kkro6n7junxlxqpo5.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-69da89ce-611b-489b-8e76-576b8618dd98"],
					included_platforms: []
				},
				{
					ref_id: "i-7d721519-715a-409d-adf9-8bbb4a04c23f",
					ref_title: "-McAloo Tikki Burger",
					title: "McAloo Tikki Burger",
					active: true,
					description:
						"The world's favorite Indian burger! Crunchy potato and peas patty  topped with delicious Tom Mayo and crunchy onions",
					sort_order: 27,
					price: 47.0,
					food_type: "1",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/clsixkep9clw9z0vq6yj.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-a02aafa3-48c5-4154-8010-49e8d67414e6"],
					included_platforms: []
				},
				{
					ref_id: "i-5187f995-794e-43eb-94ab-9d5db0a0cc39",
					ref_title: "-Mccafe Iced Americano",
					title: "Mccafe Iced Americano",
					active: true,
					description:
						"Feel refreshed with the blend of perfect espresso shot mixed with ice. Now in a new, convenient and delivery friendly packaging",
					sort_order: 28,
					price: 141.9,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/z8zxwwbbxhkxsggkmnfa.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-ad7ffc22-f92f-4c4d-ac92-3908f2fde257",
					ref_title: "-McVeggie Burger",
					title: "McVeggie Burger",
					active: true,
					description:
						"An everyday classic burger with a delectable patty filled with potatoes, carrots and tasty Indian spices; topped with crunchy lettuce and mayonaise.",
					sort_order: 29,
					price: 115.0,
					food_type: "1",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/n9bkfix37ohwikvjlzyg.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-27edab69-e3f5-4217-bed2-e5cbdd4ed073"],
					included_platforms: []
				},
				{
					ref_id: "i-00ed0581-55b8-4037-88df-2bc0ea255385",
					ref_title: "-Chocolate Shake",
					title: "Chocolate Shake",
					active: true,
					description:
						"The classic sinful chocolate shake, a treat for anytime you need one. Now in a new, convenient and delivery friendly packaging",
					sort_order: 30,
					price: 143.8,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/t2xma4bpwlju1wvnhjuf.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-fdade8c1-6227-4294-ad35-27ba3171f81a"],
					included_platforms: []
				},
				{
					ref_id: "i-45e19a64-2d86-4992-a538-efbdbc4c5499",
					ref_title: "-Strawberry Shake",
					title: "Strawberry Shake",
					active: true,
					description:
						"An all time favourite treat bringing together the perfect blend of creamy vanilla soft serve and strawberry flavor.Now in new, convenient and delivery friendly packaging.",
					sort_order: 31,
					price: 143.8,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pt5cwszhhrehciazfkza.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-6795f5bb-d8c4-4004-9d25-10ed687ce472"],
					included_platforms: []
				},
				{
					ref_id: "i-4247d60d-c96c-4c8f-93cb-d02ed454ab08",
					ref_title: "-Big Spicy Paneer Wrap",
					title: "Big Spicy Paneer Wrap",
					active: true,
					description:
						"Rich & filling paneer patty coated in spicy crispy batter, topped with tom mayo sauce wrapped with lettuce, onions and cheese",
					sort_order: 32,
					price: 195.0,
					food_type: "1",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gx7ymp2akvgma4hfdyem.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-b1bca4b7-26e7-4350-add2-b1af53d8dad8"],
					included_platforms: []
				},
				{
					ref_id: "i-5677616c-b146-433a-8725-1781ed9777cb",
					ref_title: "-Hashbrown",
					title: "Hashbrown",
					active: true,
					description:
						"A delicious hash brown is great on its own or as a side at breakfast time. Crispy on the outside and soft on the inside.",
					sort_order: 33,
					price: 40.0,
					food_type: "1",
					category_ref_ids: ["c-651392e6-d504-4ec5-bb31-d7a0f5e54005"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pocgsed6ptikmrswry3k.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-378f11df-9285-4ff8-bcfb-2afb990f1fe7",
					ref_title: "-McCafe-Mocha Frappe",
					title: "McCafe-Mocha Frappe",
					active: true,
					description:
						"A refreshing coffee beverage, blended with rich mocha sauce and our signature soft serve. Now in a new, convenient, delivery friendly packaging.",
					sort_order: 34,
					price: 231.42,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gq0lc49j5ruz19wszc19.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-7b26826d-62f4-4246-a646-509fefbf38e6"],
					included_platforms: []
				},
				{
					ref_id: "i-3faf5c28-fcd7-405a-9b25-2fab67457c3a",
					ref_title: "-McCafe-Chocolate Frappe",
					title: "McCafe-Chocolate Frappe",
					active: true,
					description:
						"A refreshing sweet treat made from chocolate sauce and Oreo cookie pieces which is blended with ice and garnished with whipped cream. Now in a new, convenient and delivery friendly packaging.",
					sort_order: 35,
					price: 235.24,
					food_type: "1",
					category_ref_ids: ["c-795d101f-6748-43b2-9ce5-729fb53fc0dc"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/welnzfofd0dfgvroom6k.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-4999e096-50a8-40fa-b9d6-26f66d46a8ea"],
					included_platforms: []
				},
				{
					ref_id: "i-e0fd49f1-3e5a-4dcb-970c-8348efcc6e55",
					ref_title: "-McSpicy Chicken Burger",
					title: "McSpicy Chicken Burger",
					active: true,
					description:
						"Tender and juicy chicken patty coated in spicy, crispy batter topped with a creamy sauce and crispy shredded lettuce will have you craving for more.",
					sort_order: 36,
					price: 165.0,
					food_type: "2",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/s2rtuocxasvytgxwkuso.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					included_platforms: []
				},
				{
					ref_id: "i-6ebe1241-41aa-40a6-92cd-5f77cea836fc",
					ref_title: "-4 McAloo Tikki + 2 Fries (M) + 2 Coke",
					title: "4 McAloo Tikki + 2 Fries (M) + 2 Coke",
					active: true,
					description:
						"Share a hearty meal of 4 McAloo Tikki Burger + 2 Fries (M) + 2 Coke (Now in a new, re-usable, delivery friendly packaging)",
					sort_order: 37,
					price: 429.01,
					food_type: "1",
					category_ref_ids: ["c-6ae30fa7-aa77-4402-a01c-dce2e8e8cf01"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/yfoqfqh8fmuxruquc619.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-43220b70-a9b9-486d-94d9-6cc277374874",
					ref_title: "-McSaver Chicken Kebab Burger Meal",
					title: "McSaver Chicken Kebab Burger Meal",
					active: true,
					description:
						"Enjoy a delicious meal of Chicken Kebab Burger+ Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 38,
					price: 215.01,
					food_type: "2",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/qricmibsroybqjn2qxtx.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					included_platforms: []
				},
				{
					ref_id: "i-c86a9981-6aa3-47c4-8730-db426cbcdcce",
					ref_title: "-McSaver Chicken Maharaja Mac Meal",
					title: "McSaver Chicken Maharaja Mac Meal",
					active: true,
					description:
						"Enjoy a deliciously filling meal of Maharaja Mac Chicken  + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 39,
					price: 339.01,
					food_type: "2",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/sy4cm6l7xs6vncfvpdog.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					included_platforms: []
				},
				{
					ref_id: "i-d68f34c2-d501-455f-9115-9bd8c1c8140c",
					ref_title: "-McSaver McChicken Meal",
					title: "McSaver McChicken Meal",
					active: true,
					description:
						"Enjoy your favourite meal of McChicken+ Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 40,
					price: 250.0,
					food_type: "2",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/bfqjp11n5hinttqsdkhr.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					included_platforms: []
				},
				{
					ref_id: "i-28e7d206-b235-4d12-88ac-acc9f3c49dbe",
					ref_title: "-McSaver McSpicy Chicken Meal",
					title: "McSaver McSpicy Chicken Meal",
					active: true,
					description:
						"Enjoy a delicious meal of crispy McSpicy Chicken Burger  + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 41,
					price: 299.01,
					food_type: "2",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/fvbsslnudlecazzbqwef.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					included_platforms: []
				},
				{
					ref_id: "i-e96bfe7b-0f60-45c3-b4fe-452e32db3c92",
					ref_title: "-McSaver American Cheese Supreme - Chicken Meal",
					title: "McSaver American Cheese Supreme - Chicken Meal",
					active: true,
					description:
						"Enjoy a combo of American Cheese Supreme Chicken burger + Fries (M) + Coke on this meal. Order now to experience a customizable, delicious meal.",
					sort_order: 42,
					price: 261.92,
					food_type: "2",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/rsjgkacsrlpjm56rdomd.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					included_platforms: []
				},
				{
					ref_id: "i-8846e756-4002-48ac-aaaf-025cf25f3a68",
					ref_title: "-McSaver Big Spicy Paneer Wrap Meal",
					title: "McSaver Big Spicy Paneer Wrap Meal",
					active: true,
					description:
						"Enjoy a deliciously filling meal of Big Spicy Paneer Wrap  + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 43,
					price: 330.0,
					food_type: "1",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/dykznzbdiwroyrjl9qvx.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					included_platforms: []
				},
				{
					ref_id: "i-dec564a9-4882-4a52-b943-85d2616a46b9",
					ref_title: "-Mexican McAloo Tikki Burger",
					title: "Mexican McAloo Tikki Burger",
					active: true,
					description:
						"A burger with a fusion of international tastes- your favourite aloo tikki patty, layered with shredded onion, jalapeno, served with delicious Chipotle sauce",
					sort_order: 44,
					price: 55.0,
					food_type: "1",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pgvmlyyzc2g2pmj6yrvh.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-b8ed2e1e-0594-4e24-82dc-21ef1eb1f9f7"],
					included_platforms: []
				},
				{
					ref_id: "i-fad683d4-f3bc-48e9-b1aa-e7d834cecef9",
					ref_title: "-Chicken Kebab Burger",
					title: "Chicken Kebab Burger",
					active: true,
					description:
						"A flavourful burger with a chicken kebab patty combined with tangy rassam sauce and shredded onions",
					sort_order: 45,
					price: 81.0,
					food_type: "2",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/z8tukhmutzlortfczuhw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-83f22f68-3fa9-4168-a038-1fb150a67fa7"],
					included_platforms: []
				},
				{
					ref_id: "i-303ff4d4-f7ab-4f0a-8f42-3c58a339f9f9",
					ref_title: "-New Chocolate Chip Muffin",
					title: "New Chocolate Chip Muffin",
					active: true,
					description:
						"Soft, fluffy, and blended with chocolate chips. This muffin is an overlaod of chocolate!",
					sort_order: 46,
					price: 115.0,
					food_type: "1",
					category_ref_ids: ["c-75042c4a-30c1-412d-914b-2031bad9cac7"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/qeyvwgwbjvzabfzizldu.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-40189759-58b7-4ed5-8410-d9c2b149fd24",
					ref_title: "-New Vanilla Chocolate Muffin",
					title: "New Vanilla Chocolate Muffin",
					active: true,
					description:
						"Sweet, soft, and fluffy vanilla flavoured chocolate chip muffins, best for a sweet snack or dessert!",
					sort_order: 47,
					price: 107.0,
					food_type: "2",
					category_ref_ids: ["c-75042c4a-30c1-412d-914b-2031bad9cac7"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/cimqlnkysbwrnmkztkbd.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-af6af9f4-a0ff-43db-9a6f-277c0c9cb1fb",
					ref_title: "-Chicken McNuggets",
					title: "Chicken McNuggets",
					active: true,
					description:
						"The famous McNuggets! Crisp and tender, tempura coated chicken bits, best enjoyed with mustard and ketchup. Pefect for a party!",
					sort_order: 48,
					price: 0.0,
					food_type: "2",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/txb7fjysenidt78xvshs.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-5f6c4c8c-bd64-4f58-90fd-d52da5b82ce9"],
					included_platforms: []
				},
				{
					ref_id: "i-f265a29d-7e8f-440c-b59a-d3f6d8ea2753",
					ref_title: "-McSpicy Fried Chicken",
					title: "McSpicy Fried Chicken",
					active: true,
					description: "Try the new McSpicy Fried chicken that is juicy, crunchy and spicy to the last bite!",
					sort_order: 49,
					price: 0.0,
					food_type: "2",
					category_ref_ids: ["c-b6f19bb3-11bc-4908-b6e7-f6234fad9bfd"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/g5ly67qgfmhx1n8gxufb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-a7b4212c-9882-4980-8efa-6c618a5e9b1b"],
					included_platforms: []
				},
				{
					ref_id: "i-a847615c-b82a-4d1c-a22f-aa32f980fb5a",
					ref_title: "-Cappuccino",
					title: "Cappuccino",
					active: true,
					description:
						"A refreshing shot of our signature McCafe coffee made with 100% arabica beans, topped with milk that is steamed to a thick, perfect foam.",
					sort_order: 50,
					price: 0.0,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/nbppczamjmcn4d1i5gjw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-e7918178-d558-431f-907a-8591e5a4adb4"],
					included_platforms: []
				},
				{
					ref_id: "i-4c35ad5f-a704-4fb2-a363-e03a247f440f",
					ref_title: "-Masala Wedges (L)",
					title: "Masala Wedges (L)",
					active: true,
					description:
						"Enjoy the Large pack of deliciously crispy, 100% natural potatoes, cut into wedges and seasoned.",
					sort_order: 51,
					price: 115.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pqujq1cyinbgg781whox.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-9abb6cc0-6746-482a-a57d-73d27883edb8",
					ref_title: "-McSaver Chicken McNuggets 9 Pcs Meal",
					title: "McSaver Chicken McNuggets 9 Pcs Meal",
					active: true,
					description:
						"Relish a delicious meal of juicy, Chicken McNuggets (9 pc)+ Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 52,
					price: 310.0,
					food_type: "2",
					category_ref_ids: ["c-871f7821-f4bb-43bd-85d9-05315395baa7"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/zwtsk0jhleakjqzcblia.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-05c128a1-ea4f-4de0-b4ca-15752dfed7e9",
					ref_title: "-McSaver McAloo Tikki Meal",
					title: "McSaver McAloo Tikki Meal",
					active: true,
					description:
						"Enjoy a delicious meal of McAloo Tikki Burger+ Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 53,
					price: 180.0,
					food_type: "1",
					category_ref_ids: ["c-871f7821-f4bb-43bd-85d9-05315395baa7"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/y1w2ec71uiwjm6nmkbhs.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					included_platforms: []
				},
				{
					ref_id: "i-d3e5da93-a882-4ff6-9294-3fd8118790b1",
					ref_title: "-McSaver Chicken McNuggets 6 Pcs Meal",
					title: "McSaver Chicken McNuggets 6 Pcs Meal",
					active: true,
					description:
						"Relish a delicious meal of juicy, Chicken McNuggets (6 pc)+ Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 54,
					price: 279.01,
					food_type: "2",
					category_ref_ids: ["c-871f7821-f4bb-43bd-85d9-05315395baa7"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/lzfzvsaotbn0qveftiwn.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-0e043c8d-4ea0-4e69-9be3-d2f513f0f052",
					ref_title: "-McSaver Mexican McAloo Tikki Meal",
					title: "McSaver Mexican McAloo Tikki Meal",
					active: true,
					description:
						"Enjoy a delicious meal of Mexican McAloo Tikki Burger+ Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 55,
					price: 185.01,
					food_type: "1",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/rulyawf85ppjxc2mq2lt.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					included_platforms: []
				},
				{
					ref_id: "i-5f854806-18e5-43a1-bc52-536c8ec6022e",
					ref_title: "-6 Pc Nuggets + McChicken + Coke",
					title: "6 Pc Nuggets + McChicken + Coke",
					active: true,
					description:
						"A delicious combo of McChicken, 6 pc Chicken McNuggets and Coke,now in a delivery friendly, reusable bottle.",
					sort_order: 56,
					price: 303.82,
					food_type: "2",
					category_ref_ids: ["c-1b956992-9a5e-4d9f-b442-f3ccd911027e"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/zu9eihxoge4cq35dzav4.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-6fb1cce7-f4e6-4699-a2e6-2b11fe7e249d",
					ref_title: "-McChicken + Fries (M)",
					title: "McChicken + Fries (M)",
					active: true,
					description: "McChicken + Fries (M)",
					sort_order: 57,
					price: 167.0,
					food_type: "2",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ibrlk8yqbesoak6otgqe.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-7add54fd-fcd0-4965-9052-10aa52945efe",
					ref_title: "-American Cheese Supreme Chicken + 6 Pc Nuggets + Coke",
					title: "American Cheese Supreme Chicken + 6 Pc Nuggets + Coke",
					active: true,
					description:
						"A delicious combo of American Cheese Supreme Chicken burger, 6 pc Chicken McNuggets and Coke,now in a delivery friendly, reusable bottle.",
					sort_order: 58,
					price: 313.34,
					food_type: "2",
					category_ref_ids: ["c-1b956992-9a5e-4d9f-b442-f3ccd911027e"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/v2sdtw2ad5483d0ihaim.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-01387043-dc5b-4730-a30d-982c91de6414",
					ref_title: "-McAloo Tikki + Veg Pizza McPuff + Coke",
					title: "McAloo Tikki + Veg Pizza McPuff + Coke",
					active: true,
					description:
						"Enjoy a combo of McAloo Tikki Burger, Veg Pizza McPuff and Coke, now in a delivery friendly reusable bottle",
					sort_order: 59,
					price: 138.1,
					food_type: "1",
					category_ref_ids: ["c-1b956992-9a5e-4d9f-b442-f3ccd911027e"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/yny6tg2wsz687bonly8z.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-1f25b41e-fc9c-4c7c-a44a-f20304ae31f9",
					ref_title: "-McAloo Tikki + Fries (M)",
					title: "McAloo Tikki + Fries (M)",
					active: true,
					description:
						"The world's favourite Indian Burger- McAloo Tikki + a side of crispy, golden, world famous fries (M)",
					sort_order: 60,
					price: 105.0,
					food_type: "1",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/hticekhpzizcwsnkzund.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-834d9326-934f-4e58-b958-f0d39644db34",
					ref_title: "-Masala Wedges (M) + Fries (M)",
					title: "Masala Wedges (M) + Fries (M)",
					active: true,
					description:
						"Deliciously crispy, potato masala wedges (M) + a side of crispy, golden, world famous fries (M)",
					sort_order: 61,
					price: 140.0,
					food_type: "1",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ttm2expxhqxp18hgmnhg.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-792ea2ac-261d-4415-97d4-e32a6da3afb6",
					ref_title: "-McVeggie + Fries (M)",
					title: "McVeggie + Fries (M)",
					active: true,
					description: "The everyday classic McVeggie + a side of crispy, golden, world famous fries (M)",
					sort_order: 62,
					price: 155.0,
					food_type: "1",
					category_ref_ids: ["c-1b956992-9a5e-4d9f-b442-f3ccd911027e"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/xmdsiwk5un2vgkgtimla.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-b9bfaa8f-07c6-4cff-bf9f-38bf1dd30839",
					ref_title: "-Sausage McMuffin 2 pc Meal",
					title: "Sausage McMuffin 2 pc Meal",
					active: true,
					description:
						"Sizzling aromatic sausage patty between the two slices of fresh English muffins + Coffee (R)",
					sort_order: 63,
					price: 215.0,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/eq1jt2lxnqql7pd6puws.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-eb5ee6ad-2c76-4c5d-8381-981754c60771",
					ref_title: "-Sausage & Egg McMuffin 2 pc Meal",
					title: "Sausage & Egg McMuffin 2 pc Meal",
					active: true,
					description:
						"Sizzling aromatic sausage accompanied by the freshly steamed egg between the two slices of fresh English muffins + Coffee (R)",
					sort_order: 64,
					price: 226.0,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/slizfqligcrvbkphlo6f.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-a951b799-d9ef-48b6-8829-6b494bf98c8b",
					ref_title: "-Veg McMuffin",
					title: "Veg McMuffin",
					active: true,
					description:
						"Grilled Spinach & Corn patty with a slice of cheese nestled between toasted English Muffin, a delicious start to your day.",
					sort_order: 65,
					price: 95.0,
					food_type: "1",
					category_ref_ids: ["c-651392e6-d504-4ec5-bb31-d7a0f5e54005"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/svzimxdayhi6egzfepgx.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-b3074623-0ef3-44a7-a583-cdf0c1e25e46",
					ref_title: "-Veg McMuffin 2 Pc Meal",
					title: "Veg McMuffin 2 Pc Meal",
					active: true,
					description: "Start your day with a combo of Veg McMuffin and a Coffee (S)",
					sort_order: 66,
					price: 205.0,
					food_type: "1",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/n9euuavbzsyehahrzbcb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-d3cdb163-8af7-4097-964c-2ce6a2d79ba8",
					ref_title: "-2 Coke",
					title: "2 Coke",
					active: true,
					description: "2 Coke",
					sort_order: 67,
					price: 149.0,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/k1ohqppaotx5kzxjrc6v.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-15918450-7df9-4bfe-9afa-836ffd7b7e00",
					ref_title: "-Fries (R)",
					title: "Fries (R)",
					active: true,
					description:
						"Fries  (R)- World Famous Fries, crispy golden, fried to perfection and lightly salted. Add to your order and enjoy it as is or with your favourite dips and seasoning.",
					sort_order: 68,
					price: 65.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/v1nnahltxrb9pynu30oy.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-acdb1104-f668-466a-a423-661132650c1a",
					ref_title: "-Fries (L)",
					title: "Fries (L)",
					active: true,
					description:
						"Fries  (L)- World Famous Fries, crispy golden, fried to perfection and lightly salted. Add to your order and enjoy it as is or with your favourite dips and seasoning.",
					sort_order: 69,
					price: 111.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/v1nnahltxrb9pynu30oy.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-8392b393-f14c-4f58-88c4-8e4a145d5e97",
					ref_title: "-American Cheese Supreme - Chicken + Veg Pizza McPuff",
					title: "American Cheese Supreme - Chicken + Veg Pizza McPuff",
					active: true,
					description: "A delicious American Cheese Supreme Chicken+ a crispy brown, delicious Pizza McPuff",
					sort_order: 70,
					price: 160.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/yywx71aejcw6duudm079.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-0ccb8e39-e4be-4c60-93a4-748f339ab534",
					ref_title: "-American Cheese Supreme - Chicken + Fries (R)",
					title: "American Cheese Supreme - Chicken + Fries (R)",
					active: true,
					description:
						"A delicious American Cheese Supreme Chicken+ a side of crispy, golden, world famous fries",
					sort_order: 71,
					price: 160.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/m4bisegad1ao53oph4li.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-ae02a57b-4660-4beb-a803-b7affa6dfaa2",
					ref_title: "-McAloo Tikki + Fries (R)",
					title: "McAloo Tikki + Fries (R)",
					active: true,
					description:
						"The world's favourite Indian Burger- McAloo Tikki + a side of crispy, golden, world famous fries (R)",
					sort_order: 72,
					price: 100.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/agjhn3c8vo8etkufnibn.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4e96bfb6-16a6-4e17-94ac-9e61aed69ad9",
					ref_title: "-Chicken Chatpata Kebab Burger + Veg Pizza McPuff",
					title: "Chicken Chatpata Kebab Burger + Veg Pizza McPuff",
					active: true,
					description: "",
					sort_order: 73,
					price: 120.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/yb2yxp1olihjtjqo4shn.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-c4ceb2a6-fb58-476c-a8ee-684ca3aa4ae2",
					ref_title: "-Chicken Chatpata Kebab Burger + Fries (R)",
					title: "Chicken Chatpata Kebab Burger + Fries (R)",
					active: true,
					description: "",
					sort_order: 74,
					price: 120.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/wzrikkxz1f8foclr11el.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-23be4cdc-fa0f-4734-abd5-c147a6641f0a",
					ref_title: "-Mexican McAloo Tikki Burger + Fries (R)",
					title: "Mexican McAloo Tikki Burger + Fries (R)",
					active: true,
					description:
						"A delicious burger with a fusion of international tastes, Mexicon McAloo Tikki + a side of crispy, golden, world famous fries",
					sort_order: 75,
					price: 100.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/f6s6bhbcbrnqswa1mhqw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-08f11164-b15b-455f-9c2d-eee34769a0c4",
					ref_title: "-McChicken Burger + Veg Pizza McPuff",
					title: "McChicken Burger + Veg Pizza McPuff",
					active: true,
					description: "Our all time favorite McChicken burger + crispy brown, delicious Pizza McPuff",
					sort_order: 76,
					price: 140.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/b03l1twchnzw8kckh1jl.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-492cd51f-198e-4d04-9e54-556321e61956",
					ref_title: "-McChicken Burger + Fries (R)",
					title: "McChicken Burger + Fries (R)",
					active: true,
					description: "Our all time favorite McChicken burger+ a side of crispy, golden, world famous fries",
					sort_order: 77,
					price: 140.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/wwaydacjp6myxjcolebl.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4e935d67-f685-4abe-a9a9-ede3e7968850",
					ref_title: "-McVeggie Burger + Veg Pizza McPuff",
					title: "McVeggie Burger + Veg Pizza McPuff",
					active: true,
					description: "The everyday classic McVeggie + crispy brown, delicious Pizza McPuff",
					sort_order: 78,
					price: 140.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/hssrdvxk2e7eztzwsu3l.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-142f304a-b544-4c91-9504-42b9345bc3fb",
					ref_title: "-McVeggie Burger + Fries (R)",
					title: "McVeggie Burger + Fries (R)",
					active: true,
					description: "The everyday classic McVeggie + a side of crispy, golden, world famous fries",
					sort_order: 79,
					price: 140.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/vrqlkynuypcwvmpogqit.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-2028e9b8-d885-495e-b344-f30e5a16711a",
					ref_title: "-Chicken Strips",
					title: "Chicken Strips",
					active: true,
					description:
						"Savour pieces of crispy tenders made with chicken; battered and breaded to perfection; with no artificial preservatives, colours or flavours",
					sort_order: 80,
					price: 0.0,
					food_type: "2",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/s2o97kf81bwydzddm6bb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-cf8d62b4-0109-4755-88e0-c3e21cdda22a"],
					included_platforms: []
				},
				{
					ref_id: "i-ee446a2c-c417-45b8-8fdf-beef99ede3d4",
					ref_title: "-Veg Pizza McPuff",
					title: "Veg Pizza McPuff",
					active: true,
					description:
						"Crispy brown crust with a generous helping of rich tomato sauce, mixed with carrots, bell peppers, beans, onions and mozzarella.",
					sort_order: 81,
					price: 45.0,
					food_type: "1",
					category_ref_ids: ["c-a91df310-c00a-401e-8cca-377a7327de65"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/tqfwvjif1s4scwb3vl8f.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-2aca8b7a-5063-4101-904d-171c3c9346dd",
					ref_title: "-Chicken Kebab Burger + Fries (L)",
					title: "Chicken Kebab Burger + Fries (L)",
					active: true,
					description:
						"The flavourful Chicken kebab burger + a side of crispy, golden, world famous fries (L)",
					sort_order: 82,
					price: 195.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/swqiwqnrhcqjhu2tawyu.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-2ac135d2-0d05-46cd-aed5-4b0463bee847",
					ref_title: "-Mexican McAloo Tikki Burger + Fries (L)",
					title: "Mexican McAloo Tikki Burger + Fries (L)",
					active: true,
					description:
						"A delicious burger with a fusion of international tastes, Mexicon McAloo Tikki + a side of crispy, golden, world famous fries (L)",
					sort_order: 83,
					price: 160.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pnybl11m7aipembd6wme.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-0e21c42e-4191-47ca-bc0f-31858210482d",
					ref_title: "-McChicken Burger + Fries (L)",
					title: "McChicken Burger + Fries (L)",
					active: true,
					description:
						"Our all time favorite McChicken burger+ a side of crispy, golden, world famous fries (L)",
					sort_order: 84,
					price: 209.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gv5zoewp1a9ptyb6ohe6.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-bbfcf89a-5782-4db2-bd51-359500612299",
					ref_title: "-McChicken Burger + Coke",
					title: "McChicken Burger + Coke",
					active: true,
					description:
						"Enjoy a delicious combo of the McChicken burger and a beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 85,
					price: 195.01,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/wdilhbxtb6svbxgqrnep.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-2f727343-5b19-4768-8d7b-84c566371c6a",
					ref_title: "-McChicken Burger + Masala Wedges (R)",
					title: "McChicken Burger + Masala Wedges (R)",
					active: true,
					description:
						"Our all time favorite McChicken burger paired with the deliciously crispy Masala Wedges",
					sort_order: 86,
					price: 159.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/vpd4remf2kuqectm5kfk.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-b4fb5fac-3e4d-43f5-aaee-b2a09673dd1f",
					ref_title: "-McVeggie Burger + Fries (L)",
					title: "McVeggie Burger + Fries (L)",
					active: true,
					description: "The everyday classic McVeggie burger + a side of crispy, golden, world famous fries",
					sort_order: 87,
					price: 206.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/btpmwifjagpcwhpkcy1m.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-39e258ec-d92a-406b-ab67-f0c3288529a7",
					ref_title: "-McVeggie Burger + Coke",
					title: "McVeggie Burger + Coke",
					active: true,
					description:
						"Enjoy a delicious combo of the McVeggie burger and a beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 88,
					price: 180.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ej0tt2cv1ptueqjv4j8k.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-64e9ddfb-df20-4053-909a-7aedd19a3749",
					ref_title: "-McVeggie Burger + Masala Wedges (R)",
					title: "McVeggie Burger + Masala Wedges (R)",
					active: true,
					description:
						"The everyday classic McVeggie burger paired with the deliciously crispy Masala Wedges",
					sort_order: 89,
					price: 146.0,
					food_type: "1",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gx6nhgm8kklg2zynybsl.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-ae9631a0-f066-4cc7-b076-110f02802896",
					ref_title: "-American Cheese Supreme Chicken + Coke",
					title: "American Cheese Supreme Chicken + Coke",
					active: true,
					description:
						"Enjoy a delicious combo of the American Cheese Supreme Chicken and a beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 90,
					price: 200.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/fo45suomv1uiy54oyqu5.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4cc2ae6f-dbc4-4520-afa7-86088aea8bd3",
					ref_title: "-American Cheese Supreme Chicken + Masala Wedges (R)",
					title: "American Cheese Supreme Chicken + Masala Wedges (R)",
					active: true,
					description:
						"A delicious American Cheese Supreme Chicken paired with the deliciously crispy Masala Wedges",
					sort_order: 91,
					price: 185.0,
					food_type: "2",
					category_ref_ids: ["c-0843cbce-d8b5-44cc-adbb-eaefad6193ec"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/excejvjimq5euwrzaymu.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-7de8410d-41c6-4dab-9b9f-328800b08766",
					ref_title: "-Sausage with Egg McMuffin 3 Pc Meal",
					title: "Sausage with Egg McMuffin 3 Pc Meal",
					active: true,
					description: "Start your day with a combo Egg McMuffin, crispy Hashbrown and a Coffee",
					sort_order: 92,
					price: 245.0,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/mge4xffuggps66lumiat.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-477e1f9d-898b-46a3-b871-116d0bed19e3",
					ref_title: "-Egg & Cheese McMuffin 3 Pc Meal",
					title: "Egg & Cheese McMuffin 3 Pc Meal",
					active: true,
					description:
						"Start your day with a combo of Egg and Cheese McMuffin, Crispy Hashbrown and a Coffee",
					sort_order: 93,
					price: 205.0,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ehsalxgnpognnb8aijzx.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-483d0b3b-14c0-42ff-9c68-347f19ca3dc4",
					ref_title: "-Sausage McMuffin 3 Pc Meal",
					title: "Sausage McMuffin 3 Pc Meal",
					active: true,
					description: "Start your day with a combo Sausage with Egg McMuffin, crispy Hashbrown and a Coffee",
					sort_order: 94,
					price: 232.0,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/xibxhkyqr91jnfvqt2tz.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-e9295d90-5dc9-40d3-a1a6-ca7492499fc4",
					ref_title: "-Tomato Ketchup Sachet",
					title: "Tomato Ketchup Sachet",
					active: true,
					description: "",
					sort_order: 95,
					price: 0.96,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pjzup5g7otti6k4i1pg8.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-8539dece-0852-4fa3-a6f6-91afac641f35",
					ref_title: "-McSaver Chicken Strips (2 Pc)",
					title: "McSaver Chicken Strips (2 Pc)",
					active: true,
					description:
						"Enjoy a snacking combo of Chicken Strips (2 pc) and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 96,
					price: 94.29,
					food_type: "2",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gbxwzoi7pijmgq031wto.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-d5e0c223-734f-47f2-8cd4-2816d439f125",
					ref_title: "-McSaver Chicken Nuggets (4 Pc)",
					title: "McSaver Chicken Nuggets (4 Pc)",
					active: true,
					description:
						"Enjoy a snacking combo of Chicken McNuggets (4pc) and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 97,
					price: 94.29,
					food_type: "2",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/fo4m5xs4twyhozjmlmkl.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-e3eaa848-9c10-4939-8ea5-05ccea2717c8",
					ref_title: "-McSaver McAloo Tikki",
					title: "McSaver McAloo Tikki",
					active: true,
					description:
						"Enjoy a snacking combo of McAloo Tikki Burger and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 98,
					price: 65.72,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/bzkhyrfudgawp3xg06nt.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-993dc12f-5faf-4e84-9a4b-a917b698dcfc",
					ref_title: "-McSaver Chicken Kebab Burger",
					title: "McSaver Chicken Kebab Burger",
					active: true,
					description:
						"Enjoy a snacking combo of Chicken Kebab Burger and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 99,
					price: 94.29,
					food_type: "2",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/tjj7xotwa5ifcdmci3qb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-542de109-d97d-4153-8d55-4a3168f37745",
					ref_title: "-McSaver Masala McEgg",
					title: "McSaver Masala McEgg",
					active: true,
					description:
						"Enjoy a snacking combo of Chicken Strips (2 pc) and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 100,
					price: 65.72,
					food_type: "2",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/odhdazxiw6av864rxfyg.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-0ccb08de-e94b-4726-8af7-f08f7d41aed5",
					ref_title: "-McSaver Pizza McPuff",
					title: "McSaver Pizza McPuff",
					active: true,
					description:
						"Enjoy a snacking combo of Pizza McPuff and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 101,
					price: 65.72,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/cinnbafytlfm9qiurxoc.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-90183855-3c76-4a88-87ad-be534205eb09",
					ref_title: "-McSaver Small Fries",
					title: "McSaver Small Fries",
					active: true,
					description:
						"Enjoy a snacking combo of Fries (R) and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 102,
					price: 65.72,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/p3fjt7f6bwolhdn4zqwm.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-f6339272-9fee-452a-8e8a-f8ddab8a0530",
					ref_title: "-McSaver Small Wedges",
					title: "McSaver Small Wedges",
					active: true,
					description:
						"Enjoy a snacking combo of Masala Wedges (R) and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 103,
					price: 65.72,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ildhtpwjq6micmqqxcvx.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-e9f1da73-67bc-40be-a1b0-03e67916ca8d",
					ref_title: "-McSaver Mexican McAloo Tikki",
					title: "McSaver Mexican McAloo Tikki",
					active: true,
					description:
						"Enjoy a snacking combo of Mexican McAloo Tikki Burger and beverage of your choice, in a new, delivery friendly, reusable bottle.",
					sort_order: 104,
					price: 94.29,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/jrv4wiyzjausorvdw6fb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-c9274704-1ebc-47c8-ad98-bcd331148924",
					ref_title: "-McSaver Masala McEgg Meal",
					title: "McSaver Masala McEgg Meal",
					active: true,
					description:
						"Enjoy a delicious meal of Masala McEgg  + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 105,
					price: 169.01,
					food_type: "2",
					category_ref_ids: ["c-871f7821-f4bb-43bd-85d9-05315395baa7"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/fq7hwqdh2yh7zbhz5ii7.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-5d317ade-47c5-4834-bf01-7a9bf468bca3",
					ref_title: "-Masala McEgg Burger",
					title: "Masala McEgg Burger",
					active: true,
					description:
						"Get your protein fix with the Masala McEgg Burger- steamed egg patty, topped with spicy masala seasoning, onions and habanero sauce",
					sort_order: 106,
					price: 50.0,
					food_type: "2",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/eq0u30r3xjufminyxsi1.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-6c0796cc-037f-4923-8197-f1715b8c7fa2"],
					included_platforms: []
				},
				{
					ref_id: "i-32d1a78d-714a-4d25-95de-9214c25c5225",
					ref_title: "-Chicken Maharaja Mac",
					title: "Chicken Maharaja Mac",
					active: true,
					description:
						"Created with chunky juicy grilled chicken patty paired with jalapeo, onion, slice of cheese, tomatoes & crunchy lettuce dressed with Habanero sauce.",
					sort_order: 107,
					price: 220.0,
					food_type: "2",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/umwf2je2jj7a9zppkng5.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-e2283bba-0a53-4da2-9dfa-36ed3ee94f2d",
					ref_title: "-McSaver McSpicy Paneer Meal",
					title: "McSaver McSpicy Paneer Meal",
					active: true,
					description:
						"Enjoy a delicious meal of crispy McSpicy Paneer Burger  + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 108,
					price: 299.01,
					food_type: "1",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/mlwipqvw74a2gmhpqrs0.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					included_platforms: []
				},
				{
					ref_id: "i-e76fe9d1-74a6-47c3-a06f-de55cc53ffdb",
					ref_title: "-McSaver McVeggie Meal",
					title: "McSaver McVeggie Meal",
					active: true,
					description:
						"Enjoy your favourite meal of McVeggie + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 109,
					price: 240.0,
					food_type: "1",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/qstrinknzlkqxsbyeq1z.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					included_platforms: []
				},
				{
					ref_id: "i-d6e7831c-64a6-43dc-87ab-59b380c0c455",
					ref_title: "-McSaver Big Spicy Chicken Wrap Meal",
					title: "McSaver Big Spicy Chicken Wrap Meal",
					active: true,
					description:
						"Enjoy a deliciously filling meal of Big Spicy Chicken Wrap  + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 110,
					price: 339.01,
					food_type: "2",
					category_ref_ids: ["c-322b8a2d-4d42-4418-9841-34da23844b5d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/tt9ixmyfwdzj0hirxiyk.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					included_platforms: []
				},
				{
					ref_id: "i-2d288d7c-0a48-46bd-b741-ea1e095a7cd9",
					ref_title: "-Piri Piri Spice Mix",
					title: "Piri Piri Spice Mix",
					active: true,
					description:
						"The perfect, taste bud tingling partner for our World Famous Fries. Shake Shake, and dive in!",
					sort_order: 111,
					price: 21.9,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ejgjwseakolmrbnexmqg.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-d99c44ce-ef2a-437e-9398-4729a4711c38",
					ref_title: "-McChicken Burger",
					title: "McChicken Burger",
					active: true,
					description:
						"Tender and juicy chicken patty cooked to perfection, with creamy mayonnaise and crunchy lettuce adding flavour to each bite.",
					sort_order: 112,
					price: 120.0,
					food_type: "2",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/jgmjbt3eizxjr3uw3q1p.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-55e309c6-7c81-453e-90a1-d58a9e895cb4"],
					included_platforms: []
				},
				{
					ref_id: "i-636f942d-b468-4781-9ac4-4366946b58f0",
					ref_title: "-Coke",
					title: "Coke",
					active: true,
					description:
						"The perfect companion to your burger, fries and everything nice; now in a new and convenient packaging.",
					sort_order: 113,
					price: 80.96,
					food_type: "1",
					category_ref_ids: ["c-98b20d76-614e-4afc-b9e1-b8f82123fd14"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/wikm78ywvzzunl7thvyg.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-d627c708-8541-4955-b3d6-83d839e163cb",
					ref_title: "-Fries (M)",
					title: "Fries (M)",
					active: true,
					description:
						"Fries  (M)- World Famous Fries, crispy golden, fried to perfection and lightly salted. Add to your order and enjoy it as is or with your favourite dips and seasoning.",
					sort_order: 114,
					price: 95.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/v1nnahltxrb9pynu30oy.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-3651b66a-57e7-495f-80ce-f35c8f77e96a",
					ref_title: "-American Cheese Supreme - Chicken",
					title: "American Cheese Supreme - Chicken",
					active: true,
					description:
						"A burger with grilled chicken patty covered with a slice of cheese, a dollop of spicy habanero sauce, jalapeos and shredded onions.",
					sort_order: 115,
					price: 139.0,
					food_type: "2",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/m1hogvovjoc0ukih4kjr.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-88e933c3-ea5b-4832-8151-7675d9bd728a"],
					included_platforms: []
				},
				{
					ref_id: "i-acb0c276-68aa-41f7-9230-85b43492b186",
					ref_title: "-Big Spicy Chicken Wrap",
					title: "Big Spicy Chicken Wrap",
					active: true,
					description:
						"Tender and juicy chicken patty coated in spicy, crispy batter, topped with a creamy sauce, wrapped with lettuce, onions, seasoning and cheese. A BIG indulgence.",
					sort_order: 116,
					price: 205.0,
					food_type: "2",
					category_ref_ids: ["c-38acbe51-131a-46f4-8c70-68f610f3bc13"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/xks8mud8anxnv0dqti0w.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-e6d072c1-305f-42de-9cc0-d25e55580c8d"],
					included_platforms: []
				},
				{
					ref_id: "i-6df2e7bc-2160-44d8-8c9f-9b8d0e43205e",
					ref_title: "-McCafe Americano Small",
					title: "McCafe Americano Small",
					active: true,
					description:
						"Our Americano is bold and robust, made with our signature McCafe coffee combined with hot water. For coffee aficionados who prefer a bolder cup.",
					sort_order: 117,
					price: 134.28,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/iix1hsre7dddxyu1sesm.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-e11280ce-45ff-4601-8d9d-9efd7efc7d28",
					ref_title: "-Masala Wedges (R)",
					title: "Masala Wedges (R)",
					active: true,
					description:
						"Enjoy the Small pack of deliciously crispy, 100% natural potatoes, cut into wedges and seasoned.",
					sort_order: 118,
					price: 45.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pqujq1cyinbgg781whox.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-b112bca4-ae34-4093-8c8d-64dcd5af0ca5",
					ref_title: "-Mocha (R)",
					title: "Mocha (R)",
					active: true,
					description:
						"A refreshing combination of chocolate, our signature McCafe coffee made with 100% Arabic beans and steamed milk, topped off with chocolate syrup.",
					sort_order: 119,
					price: 201.9,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/n802nzdyzj5fpyhy0la8.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-60b990c5-f1b8-4e62-8e5d-0d1a54a74293",
					ref_title: "-Mocha (S)",
					title: "Mocha (S)",
					active: true,
					description:
						"A refreshing combination of chocolate, our signature McCafe coffee made with 100% Arabic beans and steamed milk, topped off with chocolate syrup.",
					sort_order: 120,
					price: 175.24,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/k0xeix5irzutckjthsei.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-2fa76dbe-9a1e-4389-9481-23d34b16514e",
					ref_title: "-Hot Chocolate (R)",
					title: "Hot Chocolate (R)",
					active: true,
					description:
						"A perfect silky treat made with sinful chocolate steamed with 100% real milk and topped with an even sweeter touch of more chocolate.",
					sort_order: 121,
					price: 180.96,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ibh6uwaenh8kyeyht30c.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-daaf7f91-96a3-47d8-b094-3df248ea53a4",
					ref_title: "-Latte (R)",
					title: "Latte (R)",
					active: true,
					description:
						"Treat yourself to the smooth and sophisticated Latte. Made with our signature McCafe cofee and 100% real milk steamed and topped with light foam",
					sort_order: 122,
					price: 169.52,
					food_type: "1",
					category_ref_ids: ["c-f6a2c29c-7405-43d9-8087-ae4b788a8067"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/msndsrkrrqydbaryzbh8.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-7c3062bb-673e-4e04-afe6-491c092f97d8",
					ref_title: "-Family box",
					title: "Family box",
					active: true,
					description: "Family box: 4 pcs of juice, crispy McSpicy Fried chicken + 6 chicken McNuggets",
					sort_order: 123,
					price: 480.0,
					food_type: "2",
					category_ref_ids: ["c-f5f760d2-2f4b-43ac-b5c0-8a42dd761c4d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/opeez8rpdpxgprauzlan.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-de0d66a3-0698-4538-884b-079b4c86c39c",
					ref_title: "-Flaming Feast box",
					title: "Flaming Feast box",
					active: true,
					description: "Flamin' Feast box: 8 pcs of juice, crispy McSpicy Fried chicken",
					sort_order: 124,
					price: 660.0,
					food_type: "2",
					category_ref_ids: ["c-f5f760d2-2f4b-43ac-b5c0-8a42dd761c4d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/zgimjqhh0e2q54tcsrue.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-581688bc-54c9-4df1-98de-a29feab26dcb",
					ref_title: "-McSpicy Fried Chicken (One PC)",
					title: "McSpicy Fried Chicken (One PC)",
					active: true,
					description: "Try the new McSpicy Fried chicken that is juicy, crunchy and spicy to the last bite!",
					sort_order: 125,
					price: 119.0,
					food_type: "2",
					category_ref_ids: ["c-b6f19bb3-11bc-4908-b6e7-f6234fad9bfd"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/g5ly67qgfmhx1n8gxufb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-d8f65a65-2e67-4172-8460-44a3dee2030d",
					ref_title: "-Coke zero can",
					title: "Coke zero can",
					active: true,
					description:
						"The perfect diet companion to your burger, fries and everything nice; now in a new and convenient packaging. Regular serving size 300ml",
					sort_order: 126,
					price: 57.14,
					food_type: "1",
					category_ref_ids: ["c-98b20d76-614e-4afc-b9e1-b8f82123fd14"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/psjga7cpuehzqo0pfp6r.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-7e2f47a0-976a-4033-a97f-16c8c7b846d0",
					ref_title: "-Friendship Sharers",
					title: "Friendship Sharers",
					active: true,
					description: "Share your love for chicken with the Friendship Sharers: 5 pcs McSpicy Fried chicken",
					sort_order: 127,
					price: 425.0,
					food_type: "2",
					category_ref_ids: ["c-f5f760d2-2f4b-43ac-b5c0-8a42dd761c4d"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/xpcx7ktzijkqadhilksw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-7c160caa-e05c-4850-b45b-d4803e1a3739",
					ref_title: "-Egg & Cheese McMuffin",
					title: "Egg & Cheese McMuffin",
					active: true,
					description:
						"Fluffy soft egg topped with Cheese served between English muffins, a power packed start to your day",
					sort_order: 128,
					price: 75.0,
					food_type: "2",
					category_ref_ids: ["c-651392e6-d504-4ec5-bb31-d7a0f5e54005"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/sia7jaeglf36pwm851db.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-115d2239-4de1-462f-a314-736a942c39c3",
					ref_title: "-Sausage & Egg McMuffin",
					title: "Sausage & Egg McMuffin",
					active: true,
					description:
						"Sizzling aromatic sausage accompanied by the freshly steamed egg between two slices of fresh English muffins. A wholesome breakfast!",
					sort_order: 129,
					price: 116.0,
					food_type: "2",
					category_ref_ids: ["c-651392e6-d504-4ec5-bb31-d7a0f5e54005"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/hciicul8pkqvf2awdbcy.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-b1e96149-4f97-43a0-9bcb-d10f3f5c82c1",
					ref_title: "-Sausage McMuffin",
					title: "Sausage McMuffin",
					active: true,
					description:
						"Sizzling aromatic sausage patty between two slices of fresh English muffins. A wholesome breakfast!",
					sort_order: 130,
					price: 102.0,
					food_type: "2",
					category_ref_ids: ["c-651392e6-d504-4ec5-bb31-d7a0f5e54005"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/lkrvax79lmokkmgeevpq.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: true,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-0b67745d-d70a-4f60-9e81-597c05c368e9",
					ref_title: "-Chilli Sauce Sachet",
					title: "Chilli Sauce Sachet",
					active: true,
					description: "",
					sort_order: 131,
					price: 1.9,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/fxal2ytzgvl7myaearjs.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-cd46f2cb-8158-4ddc-97ab-444757621f6f",
					ref_title: "-Fanta",
					title: "Fanta",
					active: true,
					description: "Add a punch of orange to your order, now in a new and convenient packaging.",
					sort_order: 132,
					price: 80.96,
					food_type: "1",
					category_ref_ids: ["c-98b20d76-614e-4afc-b9e1-b8f82123fd14"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/oxvjmv0kul5sawdrpirk.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-5a203caa-d6bd-438c-9a8e-886313bf91a1",
					ref_title: "-Tom Mayo",
					title: "Tom Mayo",
					active: true,
					description: "Tom Mayo",
					sort_order: 133,
					price: 23.8,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/iwb6rszho0y5ghso2g1e.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-a4188640-f79b-4ca2-83a3-bbfe8b0d87e9",
					ref_title: "-Veg Mayo",
					title: "Veg Mayo",
					active: true,
					description: "Veg Mayo",
					sort_order: 134,
					price: 23.8,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/u9ywvnlohj91movqjhos.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-2866c692-d989-49e7-af27-4a0a0b385647",
					ref_title: "-Veg Pizza McPuff",
					title: "Veg Pizza McPuff",
					active: true,
					description:
						"Crispy brown crust with a generous helping of rich tomato sauce, mixed with carrots, bell peppers, beans, onions and mozzarella.",
					sort_order: 135,
					price: 45.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/tqfwvjif1s4scwb3vl8f.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-5c1587a7-e5c6-44f5-ae7d-384c60237c3e",
					ref_title: "-Masala Wedges (M)",
					title: "Masala Wedges (M)",
					active: true,
					description:
						"Enjoy the Medium pack of deliciously crispy, 100% natural potatoes, cut into wedges and seasoned.",
					sort_order: 136,
					price: 96.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/pqujq1cyinbgg781whox.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4f6aca31-60dc-41bf-a8e4-56d4642b0355",
					ref_title: "-Vedica Zen Water Bottle",
					title: "Vedica Zen Water Bottle",
					active: true,
					description: "",
					sort_order: 137,
					price: 47.62,
					food_type: "1",
					category_ref_ids: ["c-98b20d76-614e-4afc-b9e1-b8f82123fd14"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/lzv0zu1rg6vlllkijswd.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-831dc13e-d999-4a54-ba7c-f2c8fb1b2a4d",
					ref_title: "-Cheesy Nugget - Veg Bites - 9 pc",
					title: "Cheesy Nugget - Veg Bites - 9 pc",
					active: true,
					description:
						"Try the new delicious, pack of 9 Cheesy Veg Nuggets that will cheer you up in no time",
					sort_order: 138,
					price: 110.0,
					food_type: "1",
					category_ref_ids: ["c-d38d096c-0ca1-4d13-9bf4-a07a3bda8076"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/job8i6xdbfzebxoknsnu.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-91c88715-cba0-4c96-bb80-bb267b332d90",
					ref_title: "-Double Cheese McMuffin",
					title: "Double Cheese McMuffin",
					active: true,
					description:
						"Make your mornings Cheesy with the new Double Cheese McMuffin. Shredded onion, Jalapenos topped with Cheese (and more cheese) layered between toasted and buttered Muffin buns",
					sort_order: 139,
					price: 71.0,
					food_type: "1",
					category_ref_ids: ["c-651392e6-d504-4ec5-bb31-d7a0f5e54005"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/qzk8zttvtj46ce7mvxio.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-61a8344f-18bb-439a-811d-857e192ab479",
					ref_title: "-McSaver Double Cheese McMuffin Meal",
					title: "McSaver Double Cheese McMuffin Meal",
					active: true,
					description:
						"Start your morning with a delicious meal of Egg & Cheese McMuffin, Crispy Hashbrown and Beverage of your choice.",
					sort_order: 140,
					price: 122.86,
					food_type: "1",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/nlx590chxi4vaucnqe76.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-92fa900b-ec04-49ab-8c29-52b556c1bf71",
					ref_title: "-Double Cheese McMuffin 3 Pc Meal with Cappuccino (S)",
					title: "Double Cheese McMuffin 3 Pc Meal with Cappuccino (S)",
					active: true,
					description:
						"Try the delcious combo of Double Cheese McMuffin, crispy Hashbrown and a beverage of your choice (S) to brighten up your day.",
					sort_order: 141,
					price: 185.0,
					food_type: "1",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/hok6kpc7gzlknjigfhgq.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-9add4f47-3dd0-4dd6-8d5c-6212f95b1c5e",
					ref_title: "-McSaver Spicy Egg McMuffin Meal",
					title: "McSaver Spicy Egg McMuffin Meal",
					active: true,
					description:
						"Start your morning with a delicious meal of Egg & Cheese McMuffin, Crispy Hashbrown and Beverage of your choice.",
					sort_order: 142,
					price: 122.86,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/rldcpdnasyopsgzkr7xi.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-1a9bfdbf-014e-42fb-8883-da2b4f1cf3ee",
					ref_title: "-Spicy Egg McMuffin 3 Pc Meal with Cappuccino (S)",
					title: "Spicy Egg McMuffin 3 Pc Meal with Cappuccino (S)",
					active: true,
					description:
						"Try the delcious combo of Spicy Egg McMuffin, Crispy Hashbrown and a beverage of your choice (S) to brighten up your day.",
					sort_order: 143,
					price: 195.0,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/wonhek4zbhyvyk7gjypz.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-d24b84af-f895-4d96-95eb-17fde536f0cb",
					ref_title: "-Spicy Egg McMuffin",
					title: "Spicy Egg McMuffin",
					active: true,
					description:
						"Spice up your mornings with the new Spicy Egg McMuffin, Freshly cracked and steamed egg patty topped with spicy masala seasoning, shredded onions, topped with habanero sauce, layered between toasted and buttered Muffin buns.",
					sort_order: 144,
					price: 85.0,
					food_type: "2",
					category_ref_ids: ["c-651392e6-d504-4ec5-bb31-d7a0f5e54005"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/jo4wzklkutfky8wog79i.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-307df9df-26e0-468b-a8ad-faac139d0946",
					ref_title: "-McSaver Egg & Cheese McMuffin Meal",
					title: "McSaver Egg & Cheese McMuffin Meal",
					active: true,
					description:
						"Start your morning with a delicious meal of Egg & Cheese McMuffin, Crispy Hashbrown and Beverage of your choice.",
					sort_order: 145,
					price: 122.86,
					food_type: "2",
					category_ref_ids: ["c-ffb05336-bccd-48a7-9251-57eae6729558"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ec6ljbrq8sa1coasjnac.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-52de0cc3-714d-4afc-a99d-db606d32a8e2",
					ref_title: "-Nimbu Fizz",
					title: "Nimbu Fizz",
					active: true,
					description:
						"Try the new refreshing beverage with the zing of lemon flavour, now in  a new and convenient packaging",
					sort_order: 146,
					price: 80.96,
					food_type: "1",
					category_ref_ids: ["c-98b20d76-614e-4afc-b9e1-b8f82123fd14"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/wl26mkqgkeduvai2l182.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-2c938eed-20f9-4262-9dee-f811451b5c9d",
					ref_title: "-Mixed Fruit Beverage",
					title: "Mixed Fruit Beverage",
					active: true,
					description: "A refreshing beverage made with puree, pulp & juice from 6 delicious fruits",
					sort_order: 147,
					price: 66.66,
					food_type: "1",
					category_ref_ids: ["c-98b20d76-614e-4afc-b9e1-b8f82123fd14"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/wcrznb9p2pbzlqkmklmn.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-50bb1bb8-35ba-4388-aaca-551e062a99d0",
					ref_title: "-Trio Sharer",
					title: "Trio Sharer",
					active: true,
					description: "Share your love for chicken with the Friendship box: 3 pcs McSpicy Fried chicken",
					sort_order: 148,
					price: 305.0,
					food_type: "2",
					category_ref_ids: ["c-b6f19bb3-11bc-4908-b6e7-f6234fad9bfd"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/m5t2fkpwkx5l1xswx3dt.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-9ed493be-abcf-4243-81ca-4e69e4ef985d",
					ref_title: "-Duo Sharer",
					title: "Duo Sharer",
					active: true,
					description: "Try the new McSpicy Fried chicken that is juicy, crunchy and spicy to the last bite!",
					sort_order: 149,
					price: 215.0,
					food_type: "2",
					category_ref_ids: ["c-b6f19bb3-11bc-4908-b6e7-f6234fad9bfd"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/g5ly67qgfmhx1n8gxufb.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-29108ec4-5d41-4b0f-8e07-7894f540d476",
					ref_title: "-Cheese Lava American Chicken Burger",
					title: "Cheese Lava American Chicken Burger",
					active: true,
					description:
						"A sinfully oozing cheesy Veg patty and Flame Grilled Chicken patty, loaded with Roasted Chipotle sauce, shredded Onions, Jalapenos & a relishing salad, layered between freshly toasted Buns.",
					sort_order: 150,
					price: 249.0,
					food_type: "2",
					category_ref_ids: ["c-b777e448-f0da-438e-a9e5-dc6b4b0b000a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gvddwrnnook9cte9lnj9.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-82837d2b-5aea-47d5-b3de-5fc81b3a87ea"],
					included_platforms: []
				},
				{
					ref_id: "i-d083bb8f-3e88-4132-946b-6c4d9fbaa7fe",
					ref_title: "-Cheese Lava American Veg Burger",
					title: "Cheese Lava American Veg Burger",
					active: true,
					description:
						"A sinfully oozing cheesy Veg patty, loaded with Roasted Chipotle sauce, shredded Onions, Jalapenos & a relishing salad, layered between freshly toasted Buns.",
					sort_order: 151,
					price: 229.52,
					food_type: "1",
					category_ref_ids: ["c-b777e448-f0da-438e-a9e5-dc6b4b0b000a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/sypu7utmlxcmhzdl1qxe.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: ["og-58488c74-de51-404c-8a47-9180037cc09b"],
					included_platforms: []
				},
				{
					ref_id: "i-7454b3c0-7cdb-466f-87ef-761a7b5a4bd1",
					ref_title: "-McSaver Cheese Lava American Veg Meal",
					title: "McSaver Cheese Lava American Veg Meal",
					active: true,
					description:
						"Enjoy a deliciously filling meal of Cheese Lava American Veg Burger + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 152,
					price: 304.76,
					food_type: "1",
					category_ref_ids: ["c-b777e448-f0da-438e-a9e5-dc6b4b0b000a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/bnnqinvpiulec8wgllnj.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-4410f64c-80b8-4470-a899-304af9bb5076",
					ref_title: "-McSpicy Premium Chicken Burger",
					title: "McSpicy Premium Chicken Burger",
					active: true,
					description:
						"A spicy Chicken patty, shredded Lettuce topped with Jalapenos and Cheese slice, Spicy Habanero sauce & Cheese sauce layered between toasted Whole Wheat Buns.",
					sort_order: 153,
					price: 249.0,
					food_type: "2",
					category_ref_ids: ["c-b777e448-f0da-438e-a9e5-dc6b4b0b000a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/uanf5gkjo6zxjl9vfgai.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-3ae3af8f-e666-4f99-a708-09292eec963d",
					ref_title: "-McSaver McSpicy Premium Chicken Meal",
					title: "McSaver McSpicy Premium Chicken Meal",
					active: true,
					description:
						"Enjoy a deliciously filling meal of McSpicy Premium Chicken Burger + Fries (M) + Beverage of your choice in a new, delivery friendly, reusable bottle.",
					sort_order: 154,
					price: 304.76,
					food_type: "2",
					category_ref_ids: ["c-b777e448-f0da-438e-a9e5-dc6b4b0b000a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/vmquqitrihqvugkw1ing.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-79ebdd95-c774-417b-a086-528c037df95f",
					ref_title: "-Sprite",
					title: "Sprite",
					active: true,
					description:
						"A refreshing beverage to complement your order; now in a new and convenient packaging.",
					sort_order: 155,
					price: 80.96,
					food_type: "1",
					category_ref_ids: ["c-98b20d76-614e-4afc-b9e1-b8f82123fd14"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/tsj5za8ssep3m365awbo.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-a17e4558-4877-45e4-b99d-36a546125d09",
					ref_title: "-McSpicy Fried Chicken 1 PC + Nimbu Fizz",
					title: "McSpicy Fried Chicken 1 PC + Nimbu Fizz",
					active: true,
					description: "1pc McSpicy Fried Chicken + Nimbu Fizz",
					sort_order: 156,
					price: 166.66,
					food_type: "2",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/kyyqh8uvyx20pkfm3ab1.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-35021159-3b6e-4c86-a538-8f9305841098",
					ref_title: "-McSpicy Fried Chicken 2 PC + Nimbu Fizz",
					title: "McSpicy Fried Chicken 2 PC + Nimbu Fizz",
					active: true,
					description: "2pc McSpicy Fried Chicken + Nimbu Fizz",
					sort_order: 157,
					price: 237.14,
					food_type: "2",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/gven3qbnk29qsx0zzcla.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-1de4e974-d407-45c4-bf93-b87b7e477bc5",
					ref_title: "-Piri Piri Spiced Chicken Burger + Nimbu Fizz",
					title: "Piri Piri Spiced Chicken Burger + Nimbu Fizz",
					active: true,
					description: "Try the Spice and Ice combo of Piri Piri Spiced Chicken Burger and Nimbu Fizz",
					sort_order: 158,
					price: 238.1,
					food_type: "2",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/ngx8ev7ttkvg88glc7ut.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-1a66bfb5-e049-48f1-b0ea-c8dbe3fcf66f",
					ref_title: "-Piri Piri Spiced Veg Burger + Nimbu Fizz",
					title: "Piri Piri Spiced Veg Burger + Nimbu Fizz",
					active: true,
					description: "Try the Spice and Ice combo of McSpicy Fried Chicken and Nimbu Fizz",
					sort_order: 159,
					price: 214.28,
					food_type: "1",
					category_ref_ids: ["c-ba0fb544-3398-4874-a83e-ddcbdb4de30a"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/sitz6wckvoiwyfp1vls9.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-549ca995-9a58-48dd-9f7c-735855a4ce5f",
					ref_title: "-2 Fries (R)",
					title: "2 Fries (R)",
					active: true,
					description:
						"2 packs of Fries  (R)- World Famous Fries, crispy golden, fried to perfection and lightly salted. Add to your order and enjoy it as is or with your favourite dips and seasoning.",
					sort_order: 160,
					price: 100.0,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/mzicxqhwq15vpmdjbf61.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-f7f48f52-25df-47c7-8918-750bc505826e",
					ref_title: "-2 McVeggie Burger",
					title: "2 McVeggie Burger",
					active: true,
					description:
						"An everyday classic burger with a delectable patty filled with potatoes, carrots and tasty Indian spices; topped with crunchy lettuce and mayonaise.",
					sort_order: 161,
					price: 149.0,
					food_type: "1",
					category_ref_ids: ["c-d0114618-8513-41a7-b0fc-20a2f3e357e1"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/zvuain47oxrh13ykdboe.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				},
				{
					ref_id: "i-69b26c34-8e25-442f-abd5-b0d26587adc0",
					ref_title: "-Friendship Sharers",
					title: "Friendship Sharers",
					active: true,
					description: "Friendship box: 5 pcs of juicy, crispy McSpicy Fried chicken",
					sort_order: 162,
					price: 425.0,
					food_type: "2",
					category_ref_ids: ["c-b6f19bb3-11bc-4908-b6e7-f6234fad9bfd"],
					img_url: "https://res.cloudinary.com/swiggy/image/upload/xpcx7ktzijkqadhilksw.jpg",
					translations: [],
					locations: [],
					current_stock: -1,
					recommended: false,
					tags: {},
					option_group_ref_ids: [],
					included_platforms: []
				}
			],
			option_groups: [
				{
					ref_id: "og-8a9d5092-22b6-4363-b8e2-545f8fb3d380",
					title: "Extra Add On for your Drink",
					ref_title: "American Mud Pie",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 0,
					translations: []
				},
				{
					ref_id: "og-69da89ce-611b-489b-8e76-576b8618dd98",
					title: "Extra Add On for your Burger",
					ref_title: "McSpicy Paneer Burger",
					min_selectable: 0,
					max_selectable: 8,
					active: true,
					sort_order: 1,
					translations: []
				},
				{
					ref_id: "og-a02aafa3-48c5-4154-8010-49e8d67414e6",
					title: "Extra Add On for your Burger",
					ref_title: "McAloo Tikki Burger",
					min_selectable: 0,
					max_selectable: 8,
					active: true,
					sort_order: 2,
					translations: []
				},
				{
					ref_id: "og-27edab69-e3f5-4217-bed2-e5cbdd4ed073",
					title: "Extra Add-On For Your Burger",
					ref_title: "McVeggie Burger",
					min_selectable: 0,
					max_selectable: 12,
					active: true,
					sort_order: 3,
					translations: []
				},
				{
					ref_id: "og-fdade8c1-6227-4294-ad35-27ba3171f81a",
					title: "Extra Add On for your Drink",
					ref_title: "Chocolate Shake",
					min_selectable: 0,
					max_selectable: 2,
					active: true,
					sort_order: 4,
					translations: []
				},
				{
					ref_id: "og-6795f5bb-d8c4-4004-9d25-10ed687ce472",
					title: "Extra Add On for your Drink",
					ref_title: "Strawberry Shake",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 5,
					translations: []
				},
				{
					ref_id: "og-b1bca4b7-26e7-4350-add2-b1af53d8dad8",
					title: "Extra Add On for your Wrap",
					ref_title: "Big Spicy Paneer Wrap",
					min_selectable: 0,
					max_selectable: 3,
					active: true,
					sort_order: 6,
					translations: []
				},
				{
					ref_id: "og-7b26826d-62f4-4246-a646-509fefbf38e6",
					title: "Extra Add On for your Drink",
					ref_title: "McCafe-Mocha Frappe",
					min_selectable: 0,
					max_selectable: 2,
					active: true,
					sort_order: 7,
					translations: []
				},
				{
					ref_id: "og-4999e096-50a8-40fa-b9d6-26f66d46a8ea",
					title: "Extra Add On for your Drink",
					ref_title: "McCafe-Chocolate Frappe",
					min_selectable: 0,
					max_selectable: 2,
					active: true,
					sort_order: 8,
					translations: []
				},
				{
					ref_id: "og-d9390857-21f3-4ff9-a7b5-393b57dbffe7",
					title: "Extra Add On for your Burger",
					ref_title: "McSpicy Chicken Burger",
					min_selectable: 0,
					max_selectable: 9,
					active: true,
					sort_order: 9,
					translations: []
				},
				{
					ref_id: "og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0",
					title: "Choose Your Drink",
					ref_title: "McSaver Chicken Kebab Burger Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 10,
					translations: []
				},
				{
					ref_id: "og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf",
					title: "Choose Your Drink",
					ref_title: "McSaver Chicken Maharaja Mac Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 11,
					translations: []
				},
				{
					ref_id: "og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd",
					title: "Choose Your Drink",
					ref_title: "McSaver McChicken Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 12,
					translations: []
				},
				{
					ref_id: "og-ab38561b-d55d-4fef-b3ee-b37246b67066",
					title: "Choose Your Drink",
					ref_title: "McSaver McSpicy Chicken Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 13,
					translations: []
				},
				{
					ref_id: "og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8",
					title: "Choose Your Drink",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 14,
					translations: []
				},
				{
					ref_id: "og-022cc904-149a-40ea-81fc-33f5fbb14cda",
					title: "Choose Your Drink",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 15,
					translations: []
				},
				{
					ref_id: "og-b8ed2e1e-0594-4e24-82dc-21ef1eb1f9f7",
					title: "Extra Add On for your Burger",
					ref_title: "Mexican McAloo Tikki Burger",
					min_selectable: 0,
					max_selectable: 7,
					active: true,
					sort_order: 16,
					translations: []
				},
				{
					ref_id: "og-83f22f68-3fa9-4168-a038-1fb150a67fa7",
					title: "Extra Add-On For Your Burger",
					ref_title: "Chicken Kebab Burger",
					min_selectable: 0,
					max_selectable: 12,
					active: true,
					sort_order: 17,
					translations: []
				},
				{
					ref_id: "og-5f6c4c8c-bd64-4f58-90fd-d52da5b82ce9",
					title: "Choose your McNuggets",
					ref_title: "Chicken McNuggets",
					min_selectable: 1,
					max_selectable: 1,
					active: true,
					sort_order: 18,
					translations: []
				},
				{
					ref_id: "og-a7b4212c-9882-4980-8efa-6c618a5e9b1b",
					title: "Choose your Chicken Piece",
					ref_title: "McSpicy Fried Chicken",
					min_selectable: 1,
					max_selectable: 1,
					active: true,
					sort_order: 19,
					translations: []
				},
				{
					ref_id: "og-e7918178-d558-431f-907a-8591e5a4adb4",
					title: "Choose your Size",
					ref_title: "Cappuccino",
					min_selectable: 1,
					max_selectable: 1,
					active: true,
					sort_order: 20,
					translations: []
				},
				{
					ref_id: "og-67be69d8-bc0f-44cc-a7a9-d7118956a448",
					title: "Choose Your Drink",
					ref_title: "McSaver McAloo Tikki Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 21,
					translations: []
				},
				{
					ref_id: "og-f514e5c8-2e01-43db-9d31-20dac1b52dc4",
					title: "Choose Your Drink",
					ref_title: "McSaver Mexican McAloo Tikki Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 22,
					translations: []
				},
				{
					ref_id: "og-cf8d62b4-0109-4755-88e0-c3e21cdda22a",
					title: "Choose your Chicken Strips",
					ref_title: "Chicken Strips",
					min_selectable: 1,
					max_selectable: 1,
					active: true,
					sort_order: 23,
					translations: []
				},
				{
					ref_id: "og-6c0796cc-037f-4923-8197-f1715b8c7fa2",
					title: "Extra Add On for your Burger",
					ref_title: "Masala McEgg Burger",
					min_selectable: 0,
					max_selectable: 2,
					active: true,
					sort_order: 24,
					translations: []
				},
				{
					ref_id: "og-202434c2-7dc1-443a-bb02-9e07ab51528a",
					title: "Choose Your Drink",
					ref_title: "McSaver McSpicy Paneer Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 25,
					translations: []
				},
				{
					ref_id: "og-9be00ecb-ff7e-4673-8f34-009eaed8fd42",
					title: "Choose Your Drink",
					ref_title: "McSaver McVeggie Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 26,
					translations: []
				},
				{
					ref_id: "og-f2583812-998a-4c99-8bf7-54655c502e6d",
					title: "Choose Your Drink",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal",
					min_selectable: 0,
					max_selectable: 1,
					active: true,
					sort_order: 27,
					translations: []
				},
				{
					ref_id: "og-55e309c6-7c81-453e-90a1-d58a9e895cb4",
					title: "Extra Add-On For Your Burger",
					ref_title: "McChicken Burger",
					min_selectable: 0,
					max_selectable: 12,
					active: true,
					sort_order: 28,
					translations: []
				},
				{
					ref_id: "og-88e933c3-ea5b-4832-8151-7675d9bd728a",
					title: "Extra Add On for your Burger",
					ref_title: "American Cheese Supreme - Chicken",
					min_selectable: 0,
					max_selectable: 2,
					active: true,
					sort_order: 29,
					translations: []
				},
				{
					ref_id: "og-e6d072c1-305f-42de-9cc0-d25e55580c8d",
					title: "Extra Add On for your Wrap",
					ref_title: "Big Spicy Chicken Wrap",
					min_selectable: 0,
					max_selectable: 5,
					active: true,
					sort_order: 30,
					translations: []
				},
				{
					ref_id: "og-82837d2b-5aea-47d5-b3de-5fc81b3a87ea",
					title: "Extra Add-On For Your Burger",
					ref_title: "Cheese Lava American Chicken Burger",
					min_selectable: 0,
					max_selectable: 12,
					active: true,
					sort_order: 31,
					translations: []
				},
				{
					ref_id: "og-58488c74-de51-404c-8a47-9180037cc09b",
					title: "Extra Add-On For Your Burger",
					ref_title: "Cheese Lava American Veg Burger",
					min_selectable: 0,
					max_selectable: 12,
					active: true,
					sort_order: 32,
					translations: []
				}
			],
			options: [
				{
					ref_id: "o-3b4ddd2d-dda9-4463-99f8-6198bdc31326",
					title: "Chocolate Flavor",
					ref_title: "American Mud Pie-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 0,
					option_group_ref_ids: ["og-8a9d5092-22b6-4363-b8e2-545f8fb3d380"],
					translations: []
				},
				{
					ref_id: "o-f778165d-1fd0-4e33-bbb7-b85b74833787",
					title: "QP Bun to Whole Wheat Bun",
					ref_title: "McSpicy Paneer Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 1,
					option_group_ref_ids: ["og-69da89ce-611b-489b-8e76-576b8618dd98"],
					translations: []
				},
				{
					ref_id: "o-a913b1b5-7024-4d74-80b8-ca3f7973d0fb",
					title: "McSpicy Paneer Patty",
					ref_title: "McSpicy Paneer Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 86.66,
					sold_at_store: true,
					sort_order: 2,
					option_group_ref_ids: ["og-69da89ce-611b-489b-8e76-576b8618dd98"],
					translations: []
				},
				{
					ref_id: "o-2218ca92-9a1f-40de-abbc-ab995585358a",
					title: "Jalapeno Addon",
					ref_title: "McSpicy Paneer Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 12.38,
					sold_at_store: true,
					sort_order: 3,
					option_group_ref_ids: ["og-69da89ce-611b-489b-8e76-576b8618dd98"],
					translations: []
				},
				{
					ref_id: "o-03dd124a-d799-47a8-aa91-44cb6f3e15b0",
					title: "Cheese",
					ref_title: "McSpicy Paneer Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 19.04,
					sold_at_store: true,
					sort_order: 4,
					option_group_ref_ids: ["og-69da89ce-611b-489b-8e76-576b8618dd98"],
					translations: []
				},
				{
					ref_id: "o-73df5435-273b-4ad6-b4f0-6b73670268fb",
					title: "Onion Addon",
					ref_title: "McSpicy Paneer Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 5.72,
					sold_at_store: true,
					sort_order: 5,
					option_group_ref_ids: ["og-69da89ce-611b-489b-8e76-576b8618dd98"],
					translations: []
				},
				{
					ref_id: "o-e5137190-6f4c-4fd8-a96c-13b33b47c1a7",
					title: "Tomato Addon",
					ref_title: "McSpicy Paneer Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 5.72,
					sold_at_store: true,
					sort_order: 6,
					option_group_ref_ids: ["og-69da89ce-611b-489b-8e76-576b8618dd98"],
					translations: []
				},
				{
					ref_id: "o-00cb42ba-58ed-42cd-abb5-c8278f61de82",
					title: "McAloo Tikki Patty",
					ref_title: "McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 18.1,
					sold_at_store: true,
					sort_order: 7,
					option_group_ref_ids: ["og-a02aafa3-48c5-4154-8010-49e8d67414e6"],
					translations: []
				},
				{
					ref_id: "o-9887435f-421d-434c-974e-b20e4f41f514",
					title: "Regular Bun to Whole Wheat Bun",
					ref_title: "McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 8,
					option_group_ref_ids: ["og-a02aafa3-48c5-4154-8010-49e8d67414e6"],
					translations: []
				},
				{
					ref_id: "o-46172ea6-614f-41ea-a841-2e63dfdbf628",
					title: "Jalapeno Addon",
					ref_title: "McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 12.38,
					sold_at_store: true,
					sort_order: 9,
					option_group_ref_ids: ["og-a02aafa3-48c5-4154-8010-49e8d67414e6"],
					translations: []
				},
				{
					ref_id: "o-93f57092-e5ed-4347-822a-803f7e98a907",
					title: "Cheese",
					ref_title: "McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 19.04,
					sold_at_store: true,
					sort_order: 10,
					option_group_ref_ids: ["og-a02aafa3-48c5-4154-8010-49e8d67414e6"],
					translations: []
				},
				{
					ref_id: "o-467d9e9f-f2d5-4d14-84a3-f44da048f397",
					title: "McVeggie Patty",
					ref_title: "McVeggie Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 36.19,
					sold_at_store: true,
					sort_order: 11,
					option_group_ref_ids: ["og-27edab69-e3f5-4217-bed2-e5cbdd4ed073"],
					translations: []
				},
				{
					ref_id: "o-5e4ad67d-cc37-4412-a7c9-2de74644c312",
					title: "QP Bun to Whole Wheat Bun",
					ref_title: "McVeggie Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 12,
					option_group_ref_ids: ["og-27edab69-e3f5-4217-bed2-e5cbdd4ed073"],
					translations: []
				},
				{
					ref_id: "o-e07ad066-46d1-4b4d-a7c8-98b083cef7a7",
					title: "Chocolate Flavor",
					ref_title: "Chocolate Shake-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 13,
					option_group_ref_ids: ["og-fdade8c1-6227-4294-ad35-27ba3171f81a"],
					translations: []
				},
				{
					ref_id: "o-1d1b7d05-5963-4137-9aa5-90383cbfbdd6",
					title: "Whipped Cream",
					ref_title: "Chocolate Shake-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 14,
					option_group_ref_ids: ["og-fdade8c1-6227-4294-ad35-27ba3171f81a"],
					translations: []
				},
				{
					ref_id: "o-e8b07c24-db7b-4166-944f-7d24d901a073",
					title: "Whipped Cream",
					ref_title: "Strawberry Shake-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 15,
					option_group_ref_ids: ["og-6795f5bb-d8c4-4004-9d25-10ed687ce472"],
					translations: []
				},
				{
					ref_id: "o-7f12886a-4e5a-47de-86cb-435d7eaaeaec",
					title: "Jalapeno Addon",
					ref_title: "Big Spicy Paneer Wrap-Extra Add On for your Wrap",
					description: null,
					available: true,
					price: 12.38,
					sold_at_store: true,
					sort_order: 16,
					option_group_ref_ids: ["og-b1bca4b7-26e7-4350-add2-b1af53d8dad8"],
					translations: []
				},
				{
					ref_id: "o-f092d138-8eb5-4018-a716-45d74bcf3a45",
					title: "Chocolate Flavor",
					ref_title: "McCafe-Mocha Frappe-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 17,
					option_group_ref_ids: ["og-7b26826d-62f4-4246-a646-509fefbf38e6"],
					translations: []
				},
				{
					ref_id: "o-b8bcca92-cde8-427c-a11d-16ad24d51804",
					title: "Roasted Hazelnut Flavor",
					ref_title: "McCafe-Mocha Frappe-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 18,
					option_group_ref_ids: ["og-7b26826d-62f4-4246-a646-509fefbf38e6"],
					translations: []
				},
				{
					ref_id: "o-461dae9c-3ca5-4672-83bc-bce79bbcab80",
					title: "Chocolate Flavor",
					ref_title: "McCafe-Chocolate Frappe-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 19,
					option_group_ref_ids: ["og-4999e096-50a8-40fa-b9d6-26f66d46a8ea"],
					translations: []
				},
				{
					ref_id: "o-684f925d-ae9d-47a1-b9d6-b5586f74bb97",
					title: "Roasted Hazelnut Flavor",
					ref_title: "McCafe-Chocolate Frappe-Extra Add On for your Drink",
					description: null,
					available: true,
					price: 40.96,
					sold_at_store: true,
					sort_order: 20,
					option_group_ref_ids: ["og-4999e096-50a8-40fa-b9d6-26f66d46a8ea"],
					translations: []
				},
				{
					ref_id: "o-17908ab3-00ab-4a9a-b775-0011f680ae93",
					title: "QP Bun to Whole Wheat Bun",
					ref_title: "McSpicy Chicken Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 21,
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					translations: []
				},
				{
					ref_id: "o-4dec0b4d-0002-4cbe-9433-408d2326c860",
					title: "McSpicy Chicken Patty",
					ref_title: "McSpicy Chicken Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 22,
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					translations: []
				},
				{
					ref_id: "o-7d089794-70a6-4e15-a053-2892c5c4fce4",
					title: "Habanero Addon",
					ref_title: "McSpicy Chicken Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 12.38,
					sold_at_store: true,
					sort_order: 23,
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					translations: []
				},
				{
					ref_id: "o-11c058ae-40c0-4151-8f3a-784ac2207ef1",
					title: "Jalapeno Addon",
					ref_title: "McSpicy Chicken Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 12.38,
					sold_at_store: true,
					sort_order: 24,
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					translations: []
				},
				{
					ref_id: "o-833b2249-b587-4377-bfdd-97f12d8f5bf1",
					title: "Cheese",
					ref_title: "McSpicy Chicken Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 19.04,
					sold_at_store: true,
					sort_order: 25,
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					translations: []
				},
				{
					ref_id: "o-44c79ec5-297d-469b-a706-0c5c1dab7829",
					title: "Onion Addon",
					ref_title: "McSpicy Chicken Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 5.72,
					sold_at_store: true,
					sort_order: 26,
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					translations: []
				},
				{
					ref_id: "o-8de64692-aa0c-4a62-b2f4-3b6106e86ce9",
					title: "Tomato Addon",
					ref_title: "McSpicy Chicken Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 5.72,
					sold_at_store: true,
					sort_order: 27,
					option_group_ref_ids: ["og-d9390857-21f3-4ff9-a7b5-393b57dbffe7"],
					translations: []
				},
				{
					ref_id: "o-35e93287-e28f-4a06-8372-29928af2351a",
					title: "Masala chai latte (S)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 28,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-7a84c619-8d6c-44cd-b89f-12ad2ebf16f9",
					title: "Latte (S)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 29,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-857a3a0c-489f-4824-84bc-9e4e4c46fb77",
					title: "Cappuccino (S)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 30,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-1e24f8d0-e3aa-4e62-b395-574a9eac263f",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 31,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-8cdd2644-06d0-44b1-aee4-807b2c22449c",
					title: "Chocolate Shake",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 32,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-b134b67d-f155-40c1-b4db-db520817eb80",
					title: "Strawberry Shake",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 33,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-9e810beb-4c30-4d07-9337-b67f6ae753c6",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 34,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-73fc0bfd-0444-4231-8256-c9bc455e333c",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 35,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-5f209cf4-d0fe-472a-ba01-777a83f8e662",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 36,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-bb655088-23df-4102-a9c4-6c0c2f1144e3",
					title: "Cappuccino (R)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 37,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-4445cf79-577a-4d7b-9be2-03bf1b28efd7",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 38,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-68463533-382f-4725-9d23-235483b09b24",
					title: "American Mud Pie",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 39,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-7969adc8-be47-4054-8751-273def292c34",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 40,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-f8dc1bf5-f02b-484f-b9be-dfdf99f8c32d",
					title: "Masala chai latte (R)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 41,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-8bd4b680-6e83-4caa-b823-a802f42dc872",
					title: "Mocha (S)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 42,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-351394cb-e9b4-4658-90fa-a68577d59786",
					title: "Latte (R)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 43,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-3719920c-ce97-42af-b62d-c0b554f8ae9c",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 44,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-3826a6d7-34ef-4d7e-a111-4ad0ac05ea65",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 45,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-046f2674-22da-4421-b8f6-7e31c636f35d",
					title: "Mocha (R)",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 46,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-bca1c83b-f796-46f9-a77c-8c0fe151cb25",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 47,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-c76c681d-ad68-4f56-a7e2-a05360d5581a",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 48,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-bdfb8707-d623-4a6f-b139-1a358309191e",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 49,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-b18b9c77-308d-4bd8-91d3-5a42eba1cf30",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 50,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-a1145d88-c667-4ec0-9337-001f9d811c45",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 51,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-c123d04a-82dd-4c31-b514-d1b183dc7dac",
					title: "Mango Smoothie",
					ref_title: "McSaver Chicken Kebab Burger Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 52,
					option_group_ref_ids: ["og-b5baff89-9a69-4b0b-a79a-a43dea7e52c0"],
					translations: []
				},
				{
					ref_id: "o-be4cf8cd-1670-4b30-996f-091a10e8cdff",
					title: "Masala chai latte (S)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 53,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-837ca8d1-24b2-4274-acac-3e7d42cafedf",
					title: "Latte (S)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 54,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-6fe1adcc-7d49-4a7a-a24a-9f4c7f2c9db8",
					title: "Cappuccino (S)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 55,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-b60a8ba1-9e2f-4f01-9409-9c2c8bbd8768",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 56,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-f5924cbf-20ee-4770-a52f-d6d9358d8bfe",
					title: "Chocolate Shake",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 57,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-7645763c-7298-4544-b8b6-f39bb33efc61",
					title: "Strawberry Shake",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 58,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-e1116086-3bc6-46db-8cf1-719e8e2fb481",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 59,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-88ea27d9-68e8-455a-8dbf-377e12265209",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 60,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-5e81dbf1-63f0-4b1e-baf8-15583ff0448a",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 61,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-6855f7ed-7808-4f42-a410-5817657d1854",
					title: "Cappuccino (R)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 62,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-2194f8ea-f634-49ae-9985-f46a3f56a213",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 63,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-ef63b579-7974-43c1-a4ad-45553eaff9fa",
					title: "American Mud Pie",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 64,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-eb35ca59-d488-496e-822f-f221b8dca12c",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 65,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-0eb696cd-59b1-4fe0-a5c2-f8b7f6b77d41",
					title: "Masala chai latte (R)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 66,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-07dad91e-17aa-4329-a983-e82fb0515edb",
					title: "Mocha (S)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 67,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-861ca398-cb7c-4883-9d88-f0fdd3c1ea7f",
					title: "Latte (R)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 68,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-437ed46d-9fb4-4fbd-8e05-896886cdd6a4",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 69,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-4a69fe45-efe3-480b-8793-642583367f42",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 70,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-373cdb29-fc04-46c9-96d8-f54e03b2f47f",
					title: "Mocha (R)",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 71,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-8c9e731d-726f-441b-b560-8a90e011ff44",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 72,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-3824d51a-f7fa-415c-a606-c4934fde5e13",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 73,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-e82fabe0-66f0-4394-9d9a-bf81fe605358",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 74,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-015cd0b0-0a8d-45b3-86d9-ae66ff9a81c7",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 75,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-dcb14636-54a2-4d32-ad25-9c33e19aaae1",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 76,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-3d12c82a-7043-4c81-a471-806f2ffed9d9",
					title: "Mango Smoothie",
					ref_title: "McSaver Chicken Maharaja Mac Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 77,
					option_group_ref_ids: ["og-c98b3cfe-0dd0-4a4f-a839-4f516fd11acf"],
					translations: []
				},
				{
					ref_id: "o-af1d9e37-8c1f-4adf-abf5-af5ffb30d250",
					title: "Masala chai latte (S)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 78,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-be6427fd-f5fb-4218-a5df-bc9e6c28a324",
					title: "Latte (S)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 79,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-949b064f-0d7c-4825-bd3e-e8dbee38295f",
					title: "Cappuccino (S)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 80,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-1d1963cf-af0e-45f2-ac52-98ff40909da6",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 81,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-d3a711ec-b486-469b-87aa-a0f7636f54b9",
					title: "Chocolate Shake",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 82,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-64b5645a-0f7b-4c8b-a5bd-a8576927d5ee",
					title: "Strawberry Shake",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 83,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-8488cdde-89c4-4d7a-9fe3-959a751958d8",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 84,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-1cc527ec-17a2-4ad9-9f5d-7ebeb4b61ba6",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 85,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-cf1e365d-f918-4d46-aa74-40c15a2fc620",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 86,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-0b15339e-94df-461a-a194-5f0d4ff1390c",
					title: "Cappuccino (R)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 87,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-47f6809e-aefe-417b-b8f8-0eed061d7fdd",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 88,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-4f708535-1402-4a3a-b22f-debbead491b9",
					title: "American Mud Pie",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 89,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-7bc5b36c-7886-4363-ba3f-15a11aff7000",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 90,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-776ffd1e-ca3e-4510-90ba-6a5bd2ae67fc",
					title: "Masala chai latte (R)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 91,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-3cd21ac9-1fd3-40e5-965b-caa009a645e2",
					title: "Mocha (S)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 92,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-9ff3d099-674a-49ce-9930-1462088fce07",
					title: "Latte (R)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 93,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-9245722e-2b1a-4c47-9533-f9ee88208c42",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 94,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-f67540c6-b0af-4cfa-9eb3-54ddc5119024",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 95,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-4dafc9b5-0bac-4a41-b611-964408ebafb1",
					title: "Mocha (R)",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 96,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-879dc0b9-dfdb-43dc-89c5-3ff98d50bf53",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 97,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-95529266-a462-46e5-b614-78f2bcf1bcc8",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 98,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-4d3b4990-fa42-4852-8c96-1de3a171fa6e",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 99,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-13532132-0634-48ce-81fa-004f29f4be59",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 100,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-b6d19f9f-737b-4b62-ab15-81ebb5e0d10c",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 101,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-38b268da-5a1d-4202-ad47-35af749b2f27",
					title: "Mango Smoothie",
					ref_title: "McSaver McChicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 102,
					option_group_ref_ids: ["og-acf3b2a4-70fb-4a5e-8387-c2eb499f16bd"],
					translations: []
				},
				{
					ref_id: "o-0ac7ffd0-e53c-4ebd-b142-7f7c94c79e9e",
					title: "Masala chai latte (S)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 103,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-be7bd4de-2d10-4b36-b319-0bd85e5cc5ca",
					title: "Latte (S)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 104,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-641e7d47-daa9-41c9-9f84-96a1ffdb1e2d",
					title: "Cappuccino (S)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 105,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-4514a701-ad79-4ded-a304-d375878cf1c6",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 106,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-d36b886f-5e67-4b6a-b458-e58845b6ebc9",
					title: "Chocolate Shake",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 107,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-cfed4165-bdd5-4e82-8af4-7b9b97318f14",
					title: "Strawberry Shake",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 108,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-78086e9b-5277-4da6-a1bd-767e69aea86e",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 109,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-1e55b7d5-69f1-4341-985e-461a73f49bfa",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 110,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-cd0cc39a-c53f-49d6-91c4-aa80e88e23d1",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 111,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-6cc75fe3-3f97-45be-9602-9c984baafcd8",
					title: "Cappuccino (R)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 112,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-b1feacf8-905d-4b9b-9b5c-b5ae0846f532",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 113,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-b4d44956-9460-4a2c-a047-a975cb865119",
					title: "American Mud Pie",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 114,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-f184973d-8e0a-4d9c-8887-605bfae70931",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 115,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-e3c1db62-5591-4bf1-8ee2-63b98393f2ad",
					title: "Masala chai latte (R)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 116,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-29957d9f-986e-49ab-ad44-b96c5968c022",
					title: "Mocha (S)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 117,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-8fe62e28-f236-4296-b3b2-d993d8ebc2cb",
					title: "Latte (R)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 118,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-e7ebd33e-b038-4a90-90ce-613054e832d7",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 119,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-efeec1d5-61bb-4256-acc7-7d6d8e0348bb",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 120,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-74fec4ba-70ab-4991-bbb3-0e8066c1ab0f",
					title: "Mocha (R)",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 121,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-59e9a409-aae0-4ec9-9a01-24d28c618a1f",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 122,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-6211e92a-e967-4aaf-8da6-6e9a694cb9ba",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 123,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-57c161c8-83c6-4a52-be86-b9fc3e96c426",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 124,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-d7894661-1732-4957-b61e-7ecfef5bb1e7",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 125,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-1386c11a-097f-42aa-ad54-f858b73ecc4e",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 126,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-7ad6a4e5-bb2a-488a-b317-0c67c4a28451",
					title: "Mango Smoothie",
					ref_title: "McSaver McSpicy Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 127,
					option_group_ref_ids: ["og-ab38561b-d55d-4fef-b3ee-b37246b67066"],
					translations: []
				},
				{
					ref_id: "o-ec5d517d-f928-4f9a-814b-58e64609e860",
					title: "Masala chai latte (S)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 128,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-3b5891a2-ec91-4394-aad6-a059ae92f70c",
					title: "Latte (S)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 129,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-7db91cac-b53d-4448-a939-60125c9a3999",
					title: "Cappuccino (S)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 130,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-2abafb49-3712-4524-b193-993b03225ed2",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 131,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-586ce502-aef1-4600-b853-56da889833e5",
					title: "Chocolate Shake",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 132,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-d47a351c-2f72-4b70-b3a4-c53bc7d6142e",
					title: "Strawberry Shake",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 133,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-c2634751-2f50-4b65-a229-608baa489d9f",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 134,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-2ded3d71-550c-4b12-90ff-d5109eb9de51",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 135,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-9ccaea2f-52e7-4ca5-9ae8-8739960e1148",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 136,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-5a514870-efdd-4ef0-a3e6-68868a798a2e",
					title: "Cappuccino (R)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 137,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-34271cef-9d78-46b9-bf97-9d7440c2621b",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 138,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-c686d272-67fe-4320-aaf9-b77574bf3167",
					title: "American Mud Pie",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 139,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-2e11750c-ce09-41e0-9991-791f560eccd1",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 140,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-a8fc2c2b-8e27-4da0-b424-9edcbe99fdac",
					title: "Masala chai latte (R)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 141,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-65ef78a9-e918-44b9-940b-7d1ed6e96df5",
					title: "Mocha (S)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 142,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-a933a75b-ce4c-4531-9d95-255ffc468ca3",
					title: "Latte (R)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 143,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-8d1f7be3-3450-48e9-9293-a64e510a5407",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 144,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-3c479bf3-3bd6-4f43-9d09-1295bfacd450",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 145,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-83b38f61-8b5e-4146-8fe4-96245ba16838",
					title: "Mocha (R)",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 146,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-afa8e109-6354-4c6b-b6f0-5153d11493d5",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 147,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-d942ee47-7c62-443f-8c47-6ffdca95de25",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 148,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-af8040e5-c212-4f9b-a7f2-088112de8dc5",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 149,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-36ad6fb2-2301-4534-97b8-212e17292380",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 150,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-9f0dacad-ba6c-4430-bf16-8c342a94e3cb",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 151,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-ea0b2c29-0d35-47df-8370-40d2e065189f",
					title: "Mango Smoothie",
					ref_title: "McSaver American Cheese Supreme - Chicken Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 152,
					option_group_ref_ids: ["og-f3ab0d48-84e7-4477-84a1-8c2f696ba0e8"],
					translations: []
				},
				{
					ref_id: "o-77c9e283-5af7-442b-b8cb-e7292d28916e",
					title: "Masala chai latte (S)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 153,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-88f332b8-d3d6-45c2-930c-e3b040086c8e",
					title: "Latte (S)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 154,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-b8ed49b2-a60a-475a-a6e6-5535dc9eb290",
					title: "Cappuccino (S)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 155,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-9e751e53-7d8f-433b-ad5f-977ce641c646",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 156,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-36d9a704-c45d-4ecb-9dc3-e39b7775dc6e",
					title: "Chocolate Shake",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 157,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-afe9cc7e-c9f6-437a-93e1-14f6022a37a5",
					title: "Strawberry Shake",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 158,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-0a1168d0-64f4-46bf-a1c1-91f3dac95756",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 159,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-03279db9-cb00-4e24-9443-cafc581b9cc4",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 160,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-b9fbe08a-8878-410b-93d5-a828f7456616",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 161,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-f2442ce0-6dd0-442e-a8c9-e08f5b1fe00d",
					title: "Cappuccino (R)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 162,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-81acbc10-82cb-4a65-9ddb-2d293dc12837",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 163,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-1044746f-da77-44f4-808a-85ec5b9f32f9",
					title: "American Mud Pie",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 164,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-2699a947-4dac-452c-8870-0483e71fbf8b",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 165,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-ca6e7ddc-1c30-4272-b645-8d40d6c26a1d",
					title: "Masala chai latte (R)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 166,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-c2f103a8-b022-42a5-8a42-c678a4bab498",
					title: "Mocha (S)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 167,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-748278dd-755f-4a29-8ff7-582bfb8c47d0",
					title: "Latte (R)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 168,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-044aa04e-7283-4ce4-9a2d-3506857a8693",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 169,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-8b18b0f6-fc68-4179-9167-cb1207771f68",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 170,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-d33f7c15-c2db-4ff4-8345-edc4785dfddd",
					title: "Mocha (R)",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 171,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-56adf946-bce9-4cf8-a756-ed5435bc7656",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 172,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-66cabf6a-0bed-48f7-80c9-66889944c99a",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 173,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-74867f3d-c858-4613-9ff0-75f8abb9f2cc",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 174,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-026840f3-4507-4438-88a1-f9d2d676b750",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 175,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-a8201c10-86da-4147-857d-af0f3452a9ae",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 176,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-77b2a5e1-a722-435f-a2e3-5aa57b4d58e5",
					title: "Mango Smoothie",
					ref_title: "McSaver Big Spicy Paneer Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 177,
					option_group_ref_ids: ["og-022cc904-149a-40ea-81fc-33f5fbb14cda"],
					translations: []
				},
				{
					ref_id: "o-54b44f27-d464-4db7-b892-1b8946471c55",
					title: "McAloo Tikki Patty",
					ref_title: "Mexican McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 18.1,
					sold_at_store: true,
					sort_order: 178,
					option_group_ref_ids: ["og-b8ed2e1e-0594-4e24-82dc-21ef1eb1f9f7"],
					translations: []
				},
				{
					ref_id: "o-6e5fa66d-574c-4147-80ba-20fb2140143c",
					title: "Regular Bun to Whole Wheat Bun",
					ref_title: "Mexican McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 179,
					option_group_ref_ids: ["og-b8ed2e1e-0594-4e24-82dc-21ef1eb1f9f7"],
					translations: []
				},
				{
					ref_id: "o-f75b2b7c-77e0-4112-a650-4a113d710d27",
					title: "Tandoori Mayonnaise",
					ref_title: "Mexican McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 20.0,
					sold_at_store: true,
					sort_order: 180,
					option_group_ref_ids: ["og-b8ed2e1e-0594-4e24-82dc-21ef1eb1f9f7"],
					translations: []
				},
				{
					ref_id: "o-b07fc731-39ae-40e3-befb-eccd1571084f",
					title: "Cheese",
					ref_title: "Mexican McAloo Tikki Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 19.04,
					sold_at_store: true,
					sort_order: 181,
					option_group_ref_ids: ["og-b8ed2e1e-0594-4e24-82dc-21ef1eb1f9f7"],
					translations: []
				},
				{
					ref_id: "o-acc017be-5eb9-4789-af82-2dfa96d709a8",
					title: "Chicken Kebab Patty",
					ref_title: "Chicken Kebab Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 43.8,
					sold_at_store: true,
					sort_order: 182,
					option_group_ref_ids: ["og-83f22f68-3fa9-4168-a038-1fb150a67fa7"],
					translations: []
				},
				{
					ref_id: "o-f3ce89de-d24f-4ede-91e2-a329f016e13f",
					title: "Regular Bun to Whole Wheat Bun",
					ref_title: "Chicken Kebab Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 183,
					option_group_ref_ids: ["og-83f22f68-3fa9-4168-a038-1fb150a67fa7"],
					translations: []
				},
				{
					ref_id: "o-2cfc9b40-8eb4-4424-8657-fe801fd8aa6e",
					title: "6 Pcs",
					ref_title: "Chicken McNuggets-Choose your McNuggets",
					description: null,
					available: true,
					price: 144.99,
					sold_at_store: true,
					sort_order: 184,
					option_group_ref_ids: ["og-5f6c4c8c-bd64-4f58-90fd-d52da5b82ce9"],
					translations: []
				},
				{
					ref_id: "o-da61490f-7120-4dd6-ab6f-3112e8f59bdd",
					title: "9 Pcs",
					ref_title: "Chicken McNuggets-Choose your McNuggets",
					description: null,
					available: true,
					price: 179.99,
					sold_at_store: true,
					sort_order: 185,
					option_group_ref_ids: ["og-5f6c4c8c-bd64-4f58-90fd-d52da5b82ce9"],
					translations: []
				},
				{
					ref_id: "o-96ee03d6-5e97-4158-ab33-60f061b0a6e6",
					title: "20 Pcs",
					ref_title: "Chicken McNuggets-Choose your McNuggets",
					description: null,
					available: true,
					price: 319.98,
					sold_at_store: true,
					sort_order: 186,
					option_group_ref_ids: ["og-5f6c4c8c-bd64-4f58-90fd-d52da5b82ce9"],
					translations: []
				},
				{
					ref_id: "o-596560ca-9187-421e-bb61-20cc782d0cd2",
					title: "1 Pc",
					ref_title: "McSpicy Fried Chicken-Choose your Chicken Piece",
					description: null,
					available: true,
					price: 119.0,
					sold_at_store: true,
					sort_order: 187,
					option_group_ref_ids: ["og-a7b4212c-9882-4980-8efa-6c618a5e9b1b"],
					translations: []
				},
				{
					ref_id: "o-5d5e4daf-5755-469d-bdf2-90058747ab24",
					title: "2 Pc",
					ref_title: "McSpicy Fried Chicken-Choose your Chicken Piece",
					description: null,
					available: true,
					price: 215.0,
					sold_at_store: true,
					sort_order: 188,
					option_group_ref_ids: ["og-a7b4212c-9882-4980-8efa-6c618a5e9b1b"],
					translations: []
				},
				{
					ref_id: "o-adbc8abc-004f-4cfe-a375-3bd8e392ea36",
					title: "5 Pc",
					ref_title: "McSpicy Fried Chicken-Choose your Chicken Piece",
					description: null,
					available: true,
					price: 425.0,
					sold_at_store: true,
					sort_order: 189,
					option_group_ref_ids: ["og-a7b4212c-9882-4980-8efa-6c618a5e9b1b"],
					translations: []
				},
				{
					ref_id: "o-f44a221c-b039-4db5-aadb-0aa8476cfcc4",
					title: "Cappuccino (r)",
					ref_title: "Cappuccino-Choose your Size",
					description: null,
					available: true,
					price: 168.58,
					sold_at_store: true,
					sort_order: 190,
					option_group_ref_ids: ["og-e7918178-d558-431f-907a-8591e5a4adb4"],
					translations: []
				},
				{
					ref_id: "o-ef2821e1-41de-4197-8019-27bb41a35e44",
					title: "Cappuccino (s)",
					ref_title: "Cappuccino-Choose your Size",
					description: null,
					available: true,
					price: 142.86,
					sold_at_store: true,
					sort_order: 191,
					option_group_ref_ids: ["og-e7918178-d558-431f-907a-8591e5a4adb4"],
					translations: []
				},
				{
					ref_id: "o-a8497e25-701c-4137-aa15-9c32bbabaf74",
					title: "Masala chai latte (S)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 192,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-9031afae-a527-4107-9957-a6e8dbbcb5fd",
					title: "Latte (S)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 193,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-a350ec43-de89-4b69-b1f1-8e14784d07fe",
					title: "Cappuccino (S)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 194,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-60cf066d-6087-4698-9eaf-3bab459ff632",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 195,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-693d7a21-ef10-4490-973b-5bcb7d9f4af5",
					title: "Chocolate Shake",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 196,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-3ebef40d-c7a0-43db-8c29-ed4b9a3a683d",
					title: "Strawberry Shake",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 197,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-f81d7f46-54b4-419e-84f1-e33a1481303f",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 198,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-bcfeb160-4821-443b-85c1-9c94020539af",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 199,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-132e1b91-7795-4f90-b4be-18572e770885",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 200,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-a66fb555-bcea-48bb-b4c9-45a9d038dac9",
					title: "Cappuccino (R)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 201,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-24940014-f6f7-4beb-ab1a-990071a3b7c5",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 202,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-cbac9fad-30d7-4fa8-ac84-267eb74c8893",
					title: "American Mud Pie",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 203,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-83d84e8f-15b4-4da5-b467-ce4457ef25ec",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 204,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-ae7011fc-f101-4815-a0f8-8ae7d03a27b8",
					title: "Masala chai latte (R)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 205,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-01a7a5f7-1335-4be5-a5c6-f93906015100",
					title: "Mocha (S)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 206,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-ca854b2e-e4ec-4c52-834d-d158cd580564",
					title: "Latte (R)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 207,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-83791949-fc26-4150-b5ae-62763c5cd719",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 208,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-c487d7d1-063b-4052-9bcc-8db8827c5d4e",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 209,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-09d7e3b8-e13f-4ee1-b07a-46f885ffb890",
					title: "Mocha (R)",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 210,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-48672f4c-5881-4d4e-a546-27ddbd1b15dc",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 211,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-be5266b3-5cd3-4d55-834c-864caa58ba16",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 212,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-51bb248b-c863-49d0-940a-d5b73c5d6d9c",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 213,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-a685c726-608f-4121-90c7-efaab3c08e3a",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 214,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-664c9e21-a9b1-44f8-b4bd-1dda8c9b983d",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 215,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-5da85ea8-79a8-4400-a1f1-c65979e4455d",
					title: "Mango Smoothie",
					ref_title: "McSaver McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 216,
					option_group_ref_ids: ["og-67be69d8-bc0f-44cc-a7a9-d7118956a448"],
					translations: []
				},
				{
					ref_id: "o-ea69b45e-c0eb-4097-b7e4-d3168ead9f8e",
					title: "Masala chai latte (S)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 217,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-827c10c0-bc6a-4a64-bab8-4cf93045abf8",
					title: "Latte (S)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 218,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-1aeb4fd0-3e76-43ae-92cf-6c4e671e52c4",
					title: "Cappuccino (S)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 219,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-f2e89415-e155-470f-b590-1344c2f49912",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 220,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-c6cc3655-46cd-4166-ac07-cb3b6afda09e",
					title: "Chocolate Shake",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 221,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-76827b2a-a454-49c8-b678-5de4f398f4e3",
					title: "Strawberry Shake",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 222,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-7e326c8a-e2c6-474c-9316-bde02384a8ec",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 223,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-500bd249-3ea3-4248-8512-031137422aa3",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 224,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-fe359577-dc77-447c-bf31-260ed8421020",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 225,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-64cb56cd-8358-453a-9291-bdcca2472dbd",
					title: "Cappuccino (R)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 226,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-27154796-c23c-4a63-84b1-7869774110ab",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 227,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-b848087d-0726-48a8-a7a8-a2ab39bdfe25",
					title: "American Mud Pie",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 228,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-dc5c6f86-ebd7-4440-9ad5-4e37b14f673c",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 229,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-bac2ff9b-3dcd-4429-8c47-20cf3b630949",
					title: "Masala chai latte (R)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 230,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-d7435a3e-e767-41d9-ab58-45d3a0611545",
					title: "Mocha (S)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 231,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-c3d5a516-a9ef-4fca-ba73-db9de928d701",
					title: "Latte (R)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 232,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-07b443b8-9058-4093-8a34-e5064594daa2",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 233,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-eb224f4b-f0a3-4290-baa3-db089999f35a",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 234,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-a52ce2b8-7fb8-414d-bb30-ddbe7f22d422",
					title: "Mocha (R)",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 235,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-0879c8b5-7e3c-425e-9f96-8712f6458fe6",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 236,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-0a9bd134-f668-475b-9c6f-8301b73e3cec",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 237,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-0bbe4a06-08aa-4a79-a56d-99f986e79d3e",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 238,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-305cb710-e2e6-4e43-9ee7-14389db14118",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 239,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-cc3b2f5f-0b06-43ac-a99b-8bf2d16288b8",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 240,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-1c467b7b-b01c-4c7b-817f-65309345ecd4",
					title: "Mango Smoothie",
					ref_title: "McSaver Mexican McAloo Tikki Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 241,
					option_group_ref_ids: ["og-f514e5c8-2e01-43db-9d31-20dac1b52dc4"],
					translations: []
				},
				{
					ref_id: "o-e01c3547-fa5d-4728-ba61-cfa0da691927",
					title: "2 Pcs",
					ref_title: "Chicken Strips-Choose your Chicken Strips",
					description: null,
					available: true,
					price: 80.0,
					sold_at_store: true,
					sort_order: 242,
					option_group_ref_ids: ["og-cf8d62b4-0109-4755-88e0-c3e21cdda22a"],
					translations: []
				},
				{
					ref_id: "o-d0356ea0-025c-4eb4-af5e-86d4eb1680ca",
					title: "3 Pcs",
					ref_title: "Chicken Strips-Choose your Chicken Strips",
					description: null,
					available: true,
					price: 119.0,
					sold_at_store: true,
					sort_order: 243,
					option_group_ref_ids: ["og-cf8d62b4-0109-4755-88e0-c3e21cdda22a"],
					translations: []
				},
				{
					ref_id: "o-3b5077ed-3816-4f5b-b85f-dc363c482923",
					title: "5 pcs",
					ref_title: "Chicken Strips-Choose your Chicken Strips",
					description: null,
					available: true,
					price: 190.0,
					sold_at_store: true,
					sort_order: 244,
					option_group_ref_ids: ["og-cf8d62b4-0109-4755-88e0-c3e21cdda22a"],
					translations: []
				},
				{
					ref_id: "o-0dcd6197-692b-4525-9d32-ff3df4dc4790",
					title: "Egg Patty",
					ref_title: "Masala McEgg Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 19.04,
					sold_at_store: true,
					sort_order: 245,
					option_group_ref_ids: ["og-6c0796cc-037f-4923-8197-f1715b8c7fa2"],
					translations: []
				},
				{
					ref_id: "o-af8d1231-df48-41b6-93d0-66bffb94f578",
					title: "Regular Bun to Whole Wheat Bun",
					ref_title: "Masala McEgg Burger-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 246,
					option_group_ref_ids: ["og-6c0796cc-037f-4923-8197-f1715b8c7fa2"],
					translations: []
				},
				{
					ref_id: "o-5a150ed8-651f-43dd-9b9f-27d56e7dea01",
					title: "Masala chai latte (S)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 247,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-3e5349d6-591b-4b2b-8dbe-8e9f88f93e11",
					title: "Latte (S)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 248,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-ac5ec76b-719b-4d8f-9cad-c4aedae4bece",
					title: "Cappuccino (S)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 249,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-97c655a6-35fd-4956-a7da-3a021a45a3dd",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 250,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-90399125-f48a-4951-85e4-bc3c4b663dbe",
					title: "Chocolate Shake",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 251,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-25fb8327-4cd0-4c75-8c54-763f1c8967de",
					title: "Strawberry Shake",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 252,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-ba647474-8601-4f33-bbab-059a876ef3de",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 253,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-4ed500ca-ea4d-4289-b66c-de49c83c166d",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 254,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-a0534210-d9be-4d7a-b1ef-6ace7fc9da0d",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 255,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-99bfb4d5-5a2e-4f28-8e30-3f2fb0f6655c",
					title: "Cappuccino (R)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 256,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-63a20f1f-c7ee-4f3d-8562-52618c6f21d6",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 257,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-6c7c89a5-e756-4b45-b179-cf7a8ab50351",
					title: "American Mud Pie",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 258,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-ec062c18-6d56-4a1d-a570-c6275557bfc7",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 259,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-27c247a2-76d8-40b0-958f-239410d6f55b",
					title: "Masala chai latte (R)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 260,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-d29351c1-eb88-4d65-bd4a-2523c0e7ef83",
					title: "Mocha (S)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 261,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-b76e26fa-4f12-4ea2-926c-7a2372f63bc5",
					title: "Latte (R)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 262,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-b4f42107-79a6-4d14-b128-095b6b0ae6bf",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 263,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-77775040-7dbc-40a4-abc6-35616f44079f",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 264,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-d9936c0d-9a90-4a9e-84b7-fd7c492f5497",
					title: "Mocha (R)",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 265,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-506363fb-ef8e-46b6-9b3c-241914b32b16",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 266,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-4c8df4b0-f58d-48a4-bf1f-c94f04a7ace4",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 267,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-aa6cf8e3-0df8-4d0b-a17a-b506e475d253",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 268,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-8b671e2f-bbfa-47fd-989c-3d5e6204cd26",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 269,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-e1f07b40-edc3-48d2-b2d8-6b2903d1745e",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 270,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-ac0b22e6-a9a0-46fa-b7f3-2038a906d1e9",
					title: "Mango Smoothie",
					ref_title: "McSaver McSpicy Paneer Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 271,
					option_group_ref_ids: ["og-202434c2-7dc1-443a-bb02-9e07ab51528a"],
					translations: []
				},
				{
					ref_id: "o-adb75ee2-9ff8-443b-b9ee-eeceed81f27d",
					title: "Masala chai latte (S)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 272,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-588dd8d4-fe15-4dd2-8665-217dc8f72e20",
					title: "Latte (S)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 273,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-92d70a62-201d-408f-b3cd-0ff73bd6654b",
					title: "Cappuccino (S)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 274,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-869aa7a7-a3e7-4e11-8a49-a8ce56d7b3a9",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 275,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-141dc4a0-56d5-44c6-98f6-dbc4519e40c6",
					title: "Chocolate Shake",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 276,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-c607a04f-14b9-4f16-90b6-558e9d144d0c",
					title: "Strawberry Shake",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 277,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-db9d628e-7f30-4785-b4f8-a22e9576acb0",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 278,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-30a5b2ab-4539-41b7-bc90-28c599771c82",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 279,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-fb7033d7-1bc4-4c7c-8f84-f3fc3ddcddb3",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 280,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-dd8a141c-6e98-4b20-8eb2-2d5fcaf5c357",
					title: "Cappuccino (R)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 281,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-7dc43b3c-0f0c-4c91-af70-c2237f411f70",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 282,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-172964c1-1023-4d1b-8913-98c5bf3a489d",
					title: "American Mud Pie",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 283,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-9e780972-307e-46e9-9d4c-a974b048be95",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 284,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-14e4e5a9-b7c7-481a-8bf0-51c19b3adc7a",
					title: "Masala chai latte (R)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 285,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-8c53e487-3a6d-40f5-a9c4-a00cedf4523b",
					title: "Mocha (S)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 286,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-f5356c58-3d7c-4f0f-a96e-02fa61b39e5a",
					title: "Latte (R)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 287,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-bdcedb77-4954-4256-9753-553dae7c5990",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 288,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-d3c64239-362e-4308-ac3d-af29fe1e544a",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 289,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-576089c6-bfe9-457c-93e6-a272258dc0ac",
					title: "Mocha (R)",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 290,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-96ada25e-9384-47da-93be-4d8878c0f566",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 291,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-a15fb4ee-eccf-4522-ab74-bb215a7966e6",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 292,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-3d1b77ae-3ac0-4fec-a9a1-72267546daef",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 293,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-c7f38b69-e1c2-42ff-9601-e26f7c0f1bf4",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 294,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-daa9273e-a3b2-4f9d-8175-fbf61ee7dd09",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 295,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-96797193-a364-483c-9fb6-cbaa0630ebda",
					title: "Mango Smoothie",
					ref_title: "McSaver McVeggie Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 296,
					option_group_ref_ids: ["og-9be00ecb-ff7e-4673-8f34-009eaed8fd42"],
					translations: []
				},
				{
					ref_id: "o-e483abd3-3e6a-49ac-975b-6ce94eb2e64f",
					title: "Masala chai latte (S)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 48.56,
					sold_at_store: true,
					sort_order: 297,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-6542b1c8-f2bb-40a5-955a-9db4b1c9c5a3",
					title: "Latte (S)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 60.94,
					sold_at_store: true,
					sort_order: 298,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-79774601-d8ec-49ad-9746-7cec35791bb6",
					title: "Cappuccino (S)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 61.9,
					sold_at_store: true,
					sort_order: 299,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-c65c9170-8ded-41b1-9eb6-a521be147503",
					title: "Hot Chocolate (S)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 72.38,
					sold_at_store: true,
					sort_order: 300,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-a490c213-702e-4d12-99a0-ab7bdecfe64d",
					title: "Chocolate Shake",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 301,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-6c36b67b-49e2-42ae-b269-d94fdd65ebcd",
					title: "Strawberry Shake",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 62.84,
					sold_at_store: true,
					sort_order: 302,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-15d4e124-2f5a-4070-9d99-cd18153e5694",
					title: "McCafe-Strawberry Ice Tea ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 303,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-0ccbb5d3-eda5-44cd-aefa-2bc2f4b17436",
					title: "McCafe-Green Apple Tea ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 304,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-a3e13af8-b994-4c5f-b0cb-a198ca77b375",
					title: "McCafe-Lemon Ice Tea ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 66.66,
					sold_at_store: true,
					sort_order: 305,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-69b1d306-dd86-44ec-8210-eb6a8b8e9f3c",
					title: "Cappuccino (R)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 87.62,
					sold_at_store: true,
					sort_order: 306,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-cc0644f9-8ccd-4ce2-9b82-aac4574a76f8",
					title: "McCafe-Ice Coffee ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 81.9,
					sold_at_store: true,
					sort_order: 307,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-61603152-68ce-4a2e-8dd8-9da07c425713",
					title: "American Mud Pie",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 308,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-1f4d4214-fcb6-4af6-b84a-dc45b5529234",
					title: "Mango Smoothie (S)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 309,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-dedc5d5e-ecb0-4396-bfa3-39d32ba1dbc2",
					title: "Masala chai latte (R)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 80.94,
					sold_at_store: true,
					sort_order: 310,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-bcf15428-b05b-4178-b75f-6d76a080ea3c",
					title: "Mocha (S)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 94.28,
					sold_at_store: true,
					sort_order: 311,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-b1eac54c-59a0-4bf2-9194-ad5daf20fa9e",
					title: "Latte (R)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 88.56,
					sold_at_store: true,
					sort_order: 312,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-388e1ce3-cd29-40e0-ad81-4d7a845f6375",
					title: "Hot Chocolate (R)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 100.0,
					sold_at_store: true,
					sort_order: 313,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-c14b864b-7399-4194-9da7-8c17fbb10b39",
					title: "McCafe-Classic Coffee ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 110.46,
					sold_at_store: true,
					sort_order: 314,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-036f66cd-f9f5-494f-abae-56b0319748c6",
					title: "Mocha (R)",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 120.94,
					sold_at_store: true,
					sort_order: 315,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-7fc4573c-3253-4580-ab18-7cfe0a2e438b",
					title: "McCafe-Vanilla Coffee Frappe ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 121.9,
					sold_at_store: true,
					sort_order: 316,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-8260e2e4-0ef9-41dd-a0c5-ea41ac0ed14f",
					title: "McCafe-Mocha Frappe ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 150.46,
					sold_at_store: true,
					sort_order: 317,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-fb9edd87-3eb4-41cd-9669-97a88c2bb228",
					title: "McCafe-Chocolate Frappe ( R )",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 154.28,
					sold_at_store: true,
					sort_order: 318,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-12dadb7c-f333-40eb-a73d-c2056cc18e12",
					title: "Double Chocolate Frappe",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 159.04,
					sold_at_store: true,
					sort_order: 319,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-c5272e4c-466f-4fd4-98e7-313d2859b4e4",
					title: "Mixed Berry Smoothie",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 320,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-beebe595-ea05-416b-984c-7d9b19e0ab77",
					title: "Mango Smoothie",
					ref_title: "McSaver Big Spicy Chicken Wrap Meal-Choose Your Drink",
					description: null,
					available: true,
					price: 160.94,
					sold_at_store: true,
					sort_order: 321,
					option_group_ref_ids: ["og-f2583812-998a-4c99-8bf7-54655c502e6d"],
					translations: []
				},
				{
					ref_id: "o-278e4577-ac70-4896-b3a2-ffe99ba01a37",
					title: "McChicken Patty",
					ref_title: "McChicken Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 59.04,
					sold_at_store: true,
					sort_order: 322,
					option_group_ref_ids: ["og-55e309c6-7c81-453e-90a1-d58a9e895cb4"],
					translations: []
				},
				{
					ref_id: "o-aee6b319-0f1e-4177-94e0-a66645466837",
					title: "QP Bun to Whole Wheat Bun",
					ref_title: "McChicken Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 323,
					option_group_ref_ids: ["og-55e309c6-7c81-453e-90a1-d58a9e895cb4"],
					translations: []
				},
				{
					ref_id: "o-170602e7-81d0-449f-be3e-e07cc12b4008",
					title: "QP Bun to Whole Wheat Bun",
					ref_title: "American Cheese Supreme - Chicken-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 324,
					option_group_ref_ids: ["og-88e933c3-ea5b-4832-8151-7675d9bd728a"],
					translations: []
				},
				{
					ref_id: "o-2b78ff6a-a72b-41c7-aece-869078d78a1a",
					title: "Maharaja Mac Flame Grill Patty",
					ref_title: "American Cheese Supreme - Chicken-Extra Add On for your Burger",
					description: null,
					available: true,
					price: 69.52,
					sold_at_store: true,
					sort_order: 325,
					option_group_ref_ids: ["og-88e933c3-ea5b-4832-8151-7675d9bd728a"],
					translations: []
				},
				{
					ref_id: "o-71300077-47dc-4287-b84d-557c311604f6",
					title: "Jalapeno Addon",
					ref_title: "Big Spicy Chicken Wrap-Extra Add On for your Wrap",
					description: null,
					available: true,
					price: 12.38,
					sold_at_store: true,
					sort_order: 326,
					option_group_ref_ids: ["og-e6d072c1-305f-42de-9cc0-d25e55580c8d"],
					translations: []
				},
				{
					ref_id: "o-0f637c01-eea3-4a73-a293-42b70e844ad3",
					title: "QP Bun to Whole Wheat Bun",
					ref_title: "Cheese Lava American Chicken Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 327,
					option_group_ref_ids: ["og-82837d2b-5aea-47d5-b3de-5fc81b3a87ea"],
					translations: []
				},
				{
					ref_id: "o-b0be73c6-da29-4856-a5fc-4d1f7294ad0b",
					title: "QP Bun to Whole Wheat Bun",
					ref_title: "Cheese Lava American Veg Burger-Extra Add-On For Your Burger",
					description: null,
					available: true,
					price: 9.52,
					sold_at_store: true,
					sort_order: 328,
					option_group_ref_ids: ["og-58488c74-de51-404c-8a47-9180037cc09b"],
					translations: []
				}
			],
			flush: true
		},
		200
	]
};
