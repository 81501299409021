import React, { useState, useEffect } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import SelectFilters from "../components/Reports/SelectFilters";
import SetupSchedule from "../components/Reports/SetupSchedule";

// third party
import history from "../history";
import { connect } from "react-redux";

// store
import { store } from "../store/configureStore";

// utils
import { trackEvent } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import {
	fetchReportDetail,
	fetchScheduleReportDetail,
	fetchScheduleReportsList,
	createScheduleReport,
	editScheduleReport
} from "../actions/reports";

// constants
import { REPORTS_GROUP_MAP } from "../components/Reports/Overview";

const ScheduleReportEdit = ({
	match,
	reportDetail,
	scheduleReportCreate,
	scheduleReportDetail,
	isMultibrandEnabled = false,
	bizId
}) => {
	const [isFormOpen, setFormOpen] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const { data, optionUpdates, appliedFilters, emails, validations } = reportDetail;
	const { scheduleReportData } = scheduleReportCreate;
	const { loading } = scheduleReportDetail;

	useEffect(() => {
		setTimeout(() => setFormOpen(true), 60);
		createScheduleReport();
		fetchReportDetail(`codex##${match.params.reportId}`, false);
	}, [match.params.reportId]);

	useEffect(() => {
		if (data?.id) {
			fetchScheduleReportDetail(match.params.id);

			// track event
			trackEvent("schedule_report_detail_view", {
				report_name: data?.name
			});
		}
	}, [match.params.id, data?.id, data?.name]);

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload
		});
	};

	const handleClose = () => {
		setFormOpen(false);
		setFormTouched(false);

		// reset report detail and schedule states
		store.dispatch({
			type: ActionTypes.RESET_REPORT_DETAIL
		});
		store.dispatch({
			type: ActionTypes.RESET_SCHEDULE_REPORT_DETAIL_STATE
		});

		setTimeout(() => {
			history.push("/reports/list/schedules");
			fetchScheduleReportsList();
		}, 100);
	};

	const handleValidations = () => {
		// validations for required fields
		let validationsFound = false;
		const updatedValidations = { ...validations };
		const validationMessage = "This field is required.";

		data.filters
			.filter((f) => f.field !== "time_period")
			.forEach((f) => {
				const field = f?.field?.replace(/([-_]\w)/g, (f) => f[1]?.toUpperCase());
				if (f.required && Object.values(optionUpdates[field])?.filter((val) => val)?.length === 0) {
					validationsFound = true;
					updatedValidations[field] = validationMessage;
				}
			});

		scheduleReportData.scheduleReportFilters
			.filter((f) => f.field === "showDataFor")
			.forEach((f) => {
				if (!appliedFilters[f.field]) {
					validationsFound = true;
					updatedValidations[f.field] = validationMessage;
				}
			});

		if (!appliedFilters["exportFormat"]) {
			validationsFound = true;
			updatedValidations["exportFormat"] = validationMessage;
		}
		if (!appliedFilters["scheduleFor"]) {
			validationsFound = true;
			updatedValidations["scheduleFor"] = validationMessage;
		}
		if (!emails.length) {
			validationsFound = true;
			updatedValidations["emails"] = validationMessage;
		}

		return { validationsFound, updatedValidations };
	};

	const handleSubmit = async () => {
		const { validationsFound, updatedValidations } = handleValidations();
		if (validationsFound) {
			updateReportDetail({
				validations: updatedValidations
			});
			return;
		}

		// submit and schedule report
		const variables = {
			id: scheduleReportDetail?.scheduleReportDetail?.id,
			name: data?.name,
			reportId: data?.id,
			group: data?.group,
			filters: {},
			scheduleFor: appliedFilters?.scheduleFor?.value,
			showDataFor: appliedFilters?.showDataFor?.value,
			exportFormat: appliedFilters?.exportFormat?.id,
			status: scheduleReportDetail?.scheduleReportDetail?.status,
			emails
		};

		// add filters
		for (let filter in optionUpdates) {
			if (filter !== "brands" && filter !== "timePeriod") {
				variables.filters[filter] = [
					...(variables.filters[filter] ?? []),
					...Object.keys(optionUpdates[filter]).filter((key) => optionUpdates[filter][key])
				];
			}
		}

		const status = await editScheduleReport(variables);
		if (status?.success) {
			handleClose();

			// track event
			trackEvent("schedule_report_edit", {
				report_name: variables.name,
				report_category: REPORTS_GROUP_MAP[variables?.group]?.name || "",
				frequency: variables.scheduleFor,
				report_format: variables.exportFormat?.toUpperCase()
			});
		}
	};

	return (
		<div className="schedule-report-detail">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleClose}
				loading={loading}
				submitTitle="Confirm and Schedule"
				submitButtonWidth={200}
				submit={handleSubmit}
				cancelTitle="Cancel"
				cancel={handleClose}
				disabled={reportDetail?.loading || false}
				hideHeader={true}
				hideActions={false}
			>
				<div className="form-content">
					<SelectFilters
						reportDetail={reportDetail}
						scheduleReportCreate={scheduleReportCreate}
						isFormTouched={isFormTouched}
						setFormTouched={setFormTouched}
						isMultibrandEnabled={isMultibrandEnabled}
					/>
					<SetupSchedule
						reportDetail={reportDetail}
						scheduleReportCreate={scheduleReportCreate}
						isFormTouched={isFormTouched}
						setFormTouched={setFormTouched}
						isMultibrandEnabled={isMultibrandEnabled}
						bizId={bizId}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	reportDetail: store.reportDetail,
	scheduleReportDetail: store.scheduleReportDetail,
	scheduleReportCreate: store.scheduleReportCreate,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	bizId: store?.login?.loggedInbizDetail?.id
}))(ScheduleReportEdit);
