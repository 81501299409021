import React, { useState, useRef } from "react";

// components
import { BackNavigation } from "../_commons/BackNavigation";
import BulkOperation from "../_commons/BulkOperation";
import { BulkOperationSuccess } from "../_commons/BulkOperationSuccess";
import { BulkOperationFailure } from "../_commons/BulkOperationFailure";

// clients
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { downloadTemplate, handleBulkExportDownload } from "../../actions/actions";

// utils
import history from "../../history";
import { lS, scroll } from "../../atlas-utils";

// constatnt
const data = [
	{
		fieldName: "Description",
		description: "A brief description of the category",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Handle",
		description: "Unique identifier for the category. (If left blank it will be auto generated)",
		isRequired: false,
		reqdDesc: "Defaults to -1",
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Is Active",
		description:
			"Archive a category by marking it as inactive. Restore an archived category by marking it as active",
		isRequired: true,
		acceptedValues: ["Positive integer", "0 - Inactive", "1 - Active"]
	},
	{
		fieldName: "Name",
		description: "Name of the category",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Ref Id",
		description:
			"A reference ID for the category. This can be an identifier for the category in your ERP/PoS system",
		isRequired: false,
		reqdDesc: "Defaults to -1",
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Sort Order",
		description:
			"Control ordering of categories as they are displayed to your customers. Values closer to zero will push the modifier group to top while higher values will push it towards bottom",
		isRequired: false,
		reqdDesc: "Defaults to 0",
		acceptedValues: "Positive integer"
	}
];

const BulkCategories = (props) => {
	const [downloading, setDownloading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [csvFile, setCsvFile] = useState(undefined);
	const [uploadedFileName, setUploadedFileName] = useState(undefined);
	const [validation, setValidation] = useState(undefined);
	const [bulkOpState, setBulkOpState] = useState("default");
	const [headerFields, setHeaderFields] = useState(undefined);
	const [failureResponse, setFailureResponse] = useState(undefined);
	const [failureData, setFailureData] = useState(undefined);
	const [successData, setSuccessData] = useState(undefined);
	const [downloadAction, setDownloadAction] = useState(false);
	const topRef = useRef();
	const bottomRef = useRef();

	const currLocation = {
		pathname: props.location.pathname,
		title: "Bulk actions on categories"
	};
	const prevLocation = {
		pathname: "/categories",
		title: "Categories"
	};

	const handleBackAction = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
	};

	const handleUploadNewFile = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");

		// scroll to the bottom
		setTimeout(() => {
			bottomRef.current.scrollIntoView({ behavior: "smooth" });
		}, 100);
	};

	const handleSaveChanges = () => {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Changes saved successfuly",
				timeout: 2000,
				error: false
			}
		});
		setTimeout(() => {
			history.push("/categories");
		}, 100);
	};

	const csvToJson = (data) => {
		let sanitisedData = data
			.split("\n")
			.map((row) => row.trim())
			.filter((row) => row !== "")
			.map((row) => row.split(","));
		const headers = sanitisedData[0].map((header) => header.toLowerCase().split(" ").join("_"));

		const fields = {};
		headers.map((header) => {
			fields[header] = true;
		});
		setHeaderFields(fields);

		const tableData = [];
		if (headers.includes("invalid_headers")) {
			let obj = {
				invalid_headers: sanitisedData[1].join(", ")
			};
			tableData.push(obj);
		} else {
			sanitisedData.map((row, i) => {
				if (i !== 0) {
					let obj = {};
					headers.map((column, j) => {
						obj[column] = row[j];
					});
					tableData.push(obj);
				}
			});
		}
		setFailureData(tableData);
	};

	const auth = lS.get("auth");

	const exportTemplate = async (emails, entity) => {
		setDownloading(true);
		const modelName = "category";
		await handleBulkExportDownload(modelName, emails, entity);
		setDownloading(false);
	};

	const handleTemplateDownload = async () => {
		setDownloading(true);
		const modelName = "category";
		await downloadTemplate(modelName);
		setDownloading(false);
	};

	const downloadErrorFile = () => {
		setDownloading(true);
		const file = new Blob([failureResponse], { type: "text/csv" });

		// process to auto download it
		const fileURL = URL.createObjectURL(file);
		const link = document.createElement("a");
		link.href = fileURL;
		link.download = `Categories-Errors.csv`;
		link.click();
		setDownloading(false);
	};

	const handleUploadFile = async (e) => {
		setUploading(true);
		setValidation(undefined);

		if (!csvFile) {
			var files = await e.target.files;
			setCsvFile(files[0]);
			setUploadedFileName(files[0].name);
			var formData = new FormData();
			formData.append("model-name", "category");
			formData.append("sheet", files[0]);
		} else {
			var formData = new FormData();
			formData.append("model-name", "category");
			formData.append("sheet", csvFile);
		}

		try {
			const res = await fetch(process.env.REACT_APP_API_URL_CONFIG_SHEET, {
				method: "POST",
				headers: { Authorization: auth && `Bearer ${auth.token}` },
				body: formData
			});
			const contentType = res.headers.get("content-type");
			const status = res.status;

			if (contentType === "text/csv" && status === 200) {
				const data = await res.text();
				setFailureResponse(data);
				csvToJson(data);
				setDownloadAction(false);
				setBulkOpState("failure");

				// scroll to the top
				scroll({ top: topRef.current.offset - 57, left: 0 });

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please correct the errors and try again",
						timeout: 2000,
						error: true
					}
				});
			} else if (contentType === "application/json") {
				const data = await res.json();
				if (status === 200) {
					const tableData = [{ message: data.message }];
					setSuccessData(tableData);
					setBulkOpState("success");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: data.message,
							timeout: 2000,
							error: false
						}
					});
				} else if (status === 400) {
					const fields = { reason: true };
					setHeaderFields(fields);

					const tableData = [{ reason: data.message }];
					setFailureData(tableData);

					setDownloadAction(true);
					setBulkOpState("failure");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Please correct the errors and try again",
							timeout: 2000,
							error: true
						}
					});
				}
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (err) {
			console.log(err);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
		setUploading(false);
	};

	return (
		<div>
			{bulkOpState === "default" && (
				<React.Fragment>
					<BackNavigation prevLocation={prevLocation} currLocation={currLocation} />
					<div className="section-container-common" ref={topRef}>
						<BulkOperation
							title="Bulk actions on categories"
							subTitle="Create or update categories in bulk"
							downloadTitle="Download Template"
							downloadTemplate={handleTemplateDownload}
							exportTitle="Categories"
							exportTemplate={exportTemplate}
							data={data}
							downloading={downloading}
							uploading={uploading}
							handleUploadFile={handleUploadFile}
							uploadedFileName={uploadedFileName}
							validation={validation}
						/>
					</div>
					<div ref={bottomRef}></div>
				</React.Fragment>
			)}
			{bulkOpState === "success" && (
				<React.Fragment>
					<div className="back-navigation-arrow" onClick={handleBackAction}>
						<img src="/assets/icons/ic_left_arrow_blue.svg" />
						<span className="navigation-title">Back</span>
					</div>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationSuccess
							title="Bulk actions on categories"
							subTitle="Overview of data import request"
							uploadedFileName={uploadedFileName}
							saveChanges={handleSaveChanges}
							data={successData || []}
						/>
					</div>
				</React.Fragment>
			)}
			{bulkOpState === "failure" && (
				<React.Fragment>
					<div className="back-navigation-arrow" onClick={handleBackAction}>
						<img src="/assets/icons/ic_left_arrow_blue.svg" />
						<span className="navigation-title">Back</span>
					</div>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationFailure
							title="Bulk actions on categories"
							subTitle="Overview of data import request"
							downloading={downloading}
							hideDownloadAction={downloadAction}
							uploadedFileName={uploadedFileName}
							downloadErrorFile={downloadErrorFile}
							uploadNewFile={handleUploadNewFile}
							headerFields={headerFields || {}}
							data={failureData || []}
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};
export default BulkCategories;
