import React from "react";
import { connect } from "react-redux";
import { getPlatformDetailViaName } from "../../../helpers/periscope";

const SpecificItemTableHead = ({
	data,
	configItems,
	periscopeSpecificItemAvailabilityItemState,
	displaySortImages
}) => {
	let sortedPlatformName = "";
	let orderStatus = "";

	const locationState = periscopeSpecificItemAvailabilityItemState?.sort;

	if (locationState) {
		sortedPlatformName = locationState?.field;
		orderStatus = locationState?.order;
	}

	const topImage = <img src="/assets/periscope/top-face.svg" />;
	const bottomFacedImage = <img src="/assets/periscope/down-face.svg" />;
	const topBlueImage = <img src="/assets/periscope/topBlue.svg" />;
	const bottomFacedBlueImage = <img src="/assets/periscope/bottomFacedBlue.svg" />;

	if (data == "location") {
		return <span className="header-text-item">Location</span>;
	} else {
		const bizPlatforms = configItems.bizPlatforms.items;
		const platformDetail = getPlatformDetailViaName(data, bizPlatforms);
		const platformImage = platformDetail?.image;
		const name = platformDetail?.platformName;

		return (
			<div
				className="header-text-item"
				style={{ display: "flex", gap: "8px", justifyContent: "end", alignItems: "center" }}
			>
				<img src={platformImage} style={{ width: "20px", height: "20px", borderRadius: "1px" }} />
				{name}
				<span style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
					{displaySortImages ? (
						sortedPlatformName === data ? (
							orderStatus === "asc" ? (
								<>
									{topBlueImage}
									{bottomFacedImage}
								</>
							) : orderStatus === "desc" ? (
								<>
									{topImage}

									{bottomFacedBlueImage}
								</>
							) : (
								<>
									{topImage}
									{bottomFacedImage}
								</>
							)
						) : (
							<>
								{topImage}
								{bottomFacedImage}
							</>
						)
					) : (
						<></>
					)}
				</span>
			</div>
		);
	}
};

const mapStateToProps = (state) => ({
	configItems: state.configItems
});

export default connect(mapStateToProps)(SpecificItemTableHead);
