import React from "react";

const Platform = ({ variations = {}, styles = {} }) => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ ...styles }}
		>
			<path
				d="M13 1.3308e-07C7.60243 7.78257e-08 2.96391 3.30636 1.00098 8L1 8L1 8.00391C0.356939 9.54289 -1.14966e-07 11.2305 -1.3308e-07 13C-1.51195e-07 14.7695 0.356939 16.4571 1 17.9961L1 18L1.00098 18C2.96391 22.6936 7.60243 26 13 26C18.3976 26 23.0361 22.6936 24.999 18L25 18L25 17.9961C25.6431 16.4571 26 14.7695 26 13C26 11.2305 25.6431 9.54289 25 8.00391L25 8L24.999 8C23.0361 3.30636 18.3976 1.88335e-07 13 1.3308e-07ZM13 2C14.5214 2 16.1626 4.31596 16.9775 8L9.02246 8C9.83737 4.31596 11.4786 2 13 2ZM8.87695 2.80469C8.03212 4.15363 7.37424 5.929 6.9668 8L3.20605 8C4.40672 5.65781 6.42235 3.80098 8.87695 2.80469ZM17.123 2.80469C19.5776 3.80098 21.5933 5.65781 22.7939 8L19.0332 8C18.6258 5.929 17.9679 4.15363 17.123 2.80469ZM2.41895 10L6.66504 10C6.55997 10.9587 6.5 11.9589 6.5 13C6.5 14.0411 6.55997 15.0413 6.66504 16L2.41895 16C2.14797 15.0457 2 14.04 2 13C2 11.96 2.14797 10.9543 2.41895 10ZM8.68359 10L17.3164 10C17.4315 10.9425 17.5 11.9416 17.5 13C17.5 14.0584 17.4315 15.0575 17.3164 16L8.68359 16C8.56855 15.0575 8.5 14.0584 8.5 13C8.5 11.9416 8.56855 10.9425 8.68359 10ZM19.335 10L23.5811 10C23.852 10.9543 24 11.96 24 13C24 14.04 23.852 15.0457 23.5811 16L19.335 16C19.44 15.0413 19.5 14.0411 19.5 13C19.5 11.9589 19.44 10.9587 19.335 10ZM3.20605 18L6.9668 18C7.37424 20.071 8.03212 21.8464 8.87695 23.1953C6.42235 22.199 4.40672 20.3422 3.20605 18ZM9.02246 18L16.9775 18C16.1626 21.684 14.5214 24 13 24C11.4786 24 9.83737 21.684 9.02246 18ZM19.0332 18L22.7939 18C21.5933 20.3422 19.5776 22.199 17.123 23.1953C17.9679 21.8464 18.6258 20.071 19.0332 18Z"
				fill="#AAAAAA"
				{...variations}
			/>
		</svg>
	);
};

export default React.memo(Platform);

// .common-table-container .transactions-list-table .transaction-rows
