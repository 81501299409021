import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";

// component
import ParentCategoriesList from "./ParentCategoriesList";
import { Button } from "../_commons/Button";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";
import FixErrorsDrawer from "./FixErrorsDrawer";
import StatusAlertBox from "../_commons/StatusAlertBox";
import MultiActionsButton from "../_commons/MultiActionsButton";
import { addItemsTestCart, createTestCart, handleDestructiveSave, resetTestCart } from "../../actions/menus";
import { LocationPlatformSelector } from "./LocationPlatformSelector";
import { isFeatureFlagEnabled, trackEvent } from "../../atlas-utils";
import { TRACKING_EVENT_NAMES } from "../../client-config";
import RulePreviewDrawer from "./RulePreviewDrawer";
import { PreviewSections } from "./CategoriesSection";
import NotificationServices from "../../services/NotificationService";

const PreviewCart = ({
	showHideCustomisableItemDrawer,
	showHideCartPreview,
	showHideTestCart,
	showHideMenuPublish,
	renderCancelScheduledPublishModal,
	menuTitle,
	locationsData,
	handleMenuPreviewLocationSelection,
	platformsData,
	handleMenuPreviewPlatformSelection,
	selectedLocation,
	selectedPlatform,
	menuPreviewStates,
	menuPreviewValidationStatus,
	handleItemOverridesUpdate,
	menuId,
	fetchPreviewValidationStatus,
	menuDetailsData,
	parentSectionDetailsData,
	handleDetailsEditSection,
	currClickedSection,
	setCurrClickedSection,
	menuTestCart
}) => {
	const isTestOrderEnabled = isFeatureFlagEnabled("test-order-workflow");
	const [isFixErrorDrawerOpen, setIsFixErrorDrawerOpen] = useState(false);
	const [isRulePreviewDrawerOpen, setIsRulePreviewDrawerOpen] = useState(false);
	const [entityDataForRulePreview, setEntityDataForRulePreview] = useState(null);
	const { menuPreviewData, isLoading, selectedParentSection } = menuPreviewStates;

	const updatedSelectedSection = (id) => {
		store.dispatch({
			type: ActionTypes.UPDATE_MENU_PREVIEW_SELECTED_SECTION,
			payload: id
		});
	};

	let subCategories = {};
	(menuPreviewData?.objects ?? []).forEach((parentCategory) => {
		parentCategory.subCategories.forEach((subCategory) => {
			subCategories[subCategory?.id] = true;
		});
	});

	const restructuredParentSections = (menuPreviewData?.objects ?? []).filter(
		(section) => !subCategories[section?.id]
	);

	const selectedParentSectionData = menuPreviewData?.objects?.find(
		(section) => section?.id === selectedParentSection
	);
	useEffect(() => {
		trackEvent(TRACKING_EVENT_NAMES.MENU_PREVIEW, {
			platform: selectedPlatform?.platformName?.toLowerCase(),
			type: "latest_version"
		});
	}, []);

	if (isLoading && (menuPreviewData?.objects ?? []).length === 0) {
		return (
			<React.Fragment>
				<Header
					menuTitle={menuTitle}
					showHideCartPreview={showHideCartPreview}
					showHideTestCart={showHideTestCart}
				/>
				<LocationPlatformSelector
					selectedLocation={selectedLocation}
					locationsData={locationsData}
					selectedPlatform={selectedPlatform}
					platformsData={platformsData}
					handleMenuPreviewLocationSelection={handleMenuPreviewLocationSelection}
					handleMenuPreviewPlatformSelection={handleMenuPreviewPlatformSelection}
					isLoading={isLoading}
				/>
				<div className="shimmer-container">
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				</div>
			</React.Fragment>
		);
	}
	const handleRuleAppliedClick = (data) => {
		setEntityDataForRulePreview(data);
		setIsRulePreviewDrawerOpen(true);
	};

	const addOrCreateTestCart = async (itemId) => {
		const parsedItemId = parseInt(itemId, 10);
		const platformName = selectedPlatform?.platform?.name || selectedPlatform?.platformName;

		if (!menuTestCart?.data?.cartId) {
			const variables = {
				menuId: menuDetailsData?.menuId,
				items: [{ id: parsedItemId, quantity: 1 }],
				bizLocationId: selectedLocation?.id?.toString(),
				platform: platformName
			};
			await createTestCart(variables);
		} else {
			const updatedItems = [{ id: parsedItemId, quantity: 1 }];

			const variables = {
				cartId: menuTestCart.data.cartId,
				items: updatedItems
			};
			await addItemsTestCart(variables);
		}
	};

	const handleBackToEditing = async (open) => {
		showHideCartPreview(open);
		if (menuTestCart?.data?.cartId) {
			try {
				const variables = { cartId: menuTestCart?.data?.cartId };
				await resetTestCart({ ...variables });
			} catch (e) {
				console.log(e);
			}
		}
	};

	return (
		<React.Fragment>
			<Header
				menuTitle={menuTitle}
				showHideCartPreview={handleBackToEditing}
				showHideTestCart={showHideTestCart}
				showHideMenuPublishDrawer={showHideMenuPublish}
				scheduledPublishDetails={menuDetailsData?.scheduledPublishDetails}
				metadata={menuDetailsData?.metadata}
				menuId={menuId}
				renderCancelScheduledPublishModal={renderCancelScheduledPublishModal}
				menuTestCart={menuTestCart}
				isTestOrderEnabled={isTestOrderEnabled}
			/>
			{menuPreviewValidationStatus?.menuId === menuId && (
				<StatusAlertBox
					totalWarnings={menuPreviewValidationStatus.totalWarnings}
					totalErrors={menuPreviewValidationStatus.totalErrors}
					handleFixErrorDrawerOpen={() => setIsFixErrorDrawerOpen(true)}
					status={menuPreviewValidationStatus?.status}
					message={menuPreviewValidationStatus?.menuValidationStatusData?.message}
					isFromPreviewSection
				/>
			)}

			<LocationPlatformSelector
				selectedLocation={selectedLocation}
				locationsData={locationsData}
				selectedPlatform={selectedPlatform}
				platformsData={platformsData}
				handleMenuPreviewLocationSelection={handleMenuPreviewLocationSelection}
				handleMenuPreviewPlatformSelection={handleMenuPreviewPlatformSelection}
				isLoading={isLoading}
			/>
			{isFixErrorDrawerOpen && (
				<FixErrorsDrawer
					isOpen={isFixErrorDrawerOpen}
					menuTitle={menuTitle}
					menuId={menuId}
					handleDrawerClose={() => {
						fetchPreviewValidationStatus(
							menuId,
							selectedLocation?.id,
							selectedPlatform?.platformName?.toLowerCase()
						);
						setIsFixErrorDrawerOpen(false);
					}}
					handleItemOverridesUpdate={handleItemOverridesUpdate}
					selectedLocationId={selectedLocation?.id}
					selectedPlatform={selectedPlatform?.platformName?.toLowerCase()}
					menuDetailsData={menuDetailsData}
					parentSectionDetailsData={parentSectionDetailsData}
					handleDetailsEditSection={handleDetailsEditSection}
				/>
			)}
			<div className={`preview-cart-container ${isLoading ? "disabled" : ""}`}>
				<ParentCategoriesList
					isOnPreviewMode
					parentSections={restructuredParentSections}
					selectedSection={selectedParentSection}
					updatedSelectedSection={updatedSelectedSection}
					setCurrClickedSection={setCurrClickedSection}
					isMenuLocked
				/>
				<PreviewSections
					showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
					selectedParentSectionData={selectedParentSectionData}
					parentSections={menuPreviewData?.objects || []}
					filteredParentSections={restructuredParentSections}
					updatedSelectedSection={updatedSelectedSection}
					currClickedSection={currClickedSection}
					showRulesApplied
					handleRuleAppliedClick={handleRuleAppliedClick}
					addOrCreateTestCart={addOrCreateTestCart}
				/>
			</div>
			{isRulePreviewDrawerOpen && menuId && entityDataForRulePreview && (
				<RulePreviewDrawer
					entityData={{
						menuId,
						entityId: entityDataForRulePreview?.id,
						entityName: entityDataForRulePreview?.name
					}}
					isOpen={isRulePreviewDrawerOpen}
					handleClose={() => setIsRulePreviewDrawerOpen(false)}
				/>
			)}
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	menuPreviewStates: store.menuPreviewStates,
	menuPreviewValidationStatus: store.menuPreviewValidationStatus,
	menuTestCart: store.menuTestCart
});
export default connect(mapStateToProps)(PreviewCart);

const Header = ({
	showHideCartPreview,
	showHideTestCart,
	menuTitle,
	showHideMenuPublishDrawer,
	scheduledPublishDetails,
	metadata,
	menuId,
	renderCancelScheduledPublishModal,
	menuTestCart = {},
	isTestOrderEnabled = false
}) => {
	const defaultActions = [
		{
			title: "Publish Now",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE");
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "instant", "");
			}
		},
		{
			title: "Schedule Publish",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE");
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "scheduled", "");
			}
		}
	];

	const scheduledActions = [
		{
			title: "Edit Scheduled Details",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE");
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "scheduled", "", true);
			}
		},
		{
			title: "Publish Now",
			clickHandler: () => {
				if (metadata?.pendingChangesCount > 0) {
					handleDestructiveSave(menuId, "SAVE");
					trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
						type: "publish_triggered",
						no_of_changes: metadata?.pendingChangesCount
					});
				}
				showHideMenuPublishDrawer(true, "instant", "");
			}
		},
		{
			title: <span style={{ color: "#D64949" }}>Cancel Scheduled Publish</span>,
			clickHandler: renderCancelScheduledPublishModal
		}
	];
	const actionsAvailable = scheduledPublishDetails?.scheduledAt ? scheduledActions : defaultActions;

	return (
		<div className="preview-cart-header">
			<div className="header-text">
				<span className="revert-link" onClick={() => showHideCartPreview(false)}>
					<img src="/assets/icons/ic_left_arrow_blue.svg" />
					<span className="navigation-title">Back to Editing</span>
				</span>
				<div className="menu-title">{menuTitle} (Preview)</div>
			</div>
			<div className="header-ctas">
				{isTestOrderEnabled && menuTestCart?.data?.cartObject?.items?.length > 0 && (
					<Button
						type="secondary"
						clickHandler={() => showHideTestCart(true)}
						classes={"test-cart-btn-wrapper"}
					>
						Test Cart
						<div className="test-cart-items">{menuTestCart?.data?.cartObject?.items?.length}</div>
					</Button>
				)}
				<MultiActionsButton
					title="Publish"
					instantAction={() => {
						if (metadata?.pendingChangesCount > 0) {
							handleDestructiveSave(menuId, "SAVE");
							trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
								type: "publish_triggered",
								no_of_changes: metadata?.pendingChangesCount
							});
						}
						showHideMenuPublishDrawer(true, "instant", "");
					}}
					actions={actionsAvailable}
				/>
			</div>
		</div>
	);
};
