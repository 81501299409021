import React from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import { SearchFilter } from "../_commons/SearchFilter";

// assets
import CreateIcon from "../_commons/CreateIcon";
import HelpIcon from "../_commons/HelpIcon";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Tax Rates</div>
				<div className="subtitle">Manage all taxes collected at the time of sale from here</div>
			</div>
			{!props.hideHeaderSectionRight && (
				<div className="header-action-button">
					<div className="filter-buttons">
						{props.dimensions.width > 768 && (
							<div
								className={
									(props.filterActive ? "active" : "") + " filter-in-header campaign-list-filter"
								}
							>
								<div className="container" onClick={props.flipShowFilters}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{props.filterCount > 0 && (
											<span className="filter-count">{props.filterCount}</span>
										)}
									</div>
								</div>
							</div>
						)}
						{props.isCatalogueManagement && (
							<div className="action-buttons-container">
								<Link to="/taxes/new">
									<Button>
										<CreateIcon />
										<span>Add Tax Rate</span>
									</Button>
								</Link>
							</div>
						)}
						{props.dimensions.width > 768 && (
							<SearchFilter
								filterOption={props.filterOption}
								value={props.value}
								setFilter={props.setFilter}
								placeholder="Search by name"
							/>
						)}
						<div className="help-btn-container">
							<Button clickHandler={props.handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
