import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";
import { useSpring, animated as a } from "react-spring";

// components
import { Button } from "../_commons/Button";
import { RadioButton } from "../_commons/RadioButton";
import { SelectFilter } from "../_commons/SelectFilter";

// actions
import { fetchStores, fetchStoresDebounced } from "../../actions/actions";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";

// utils
import { trackEvent } from "../../atlas-utils";

const CatalogueSetup = ({
	catalogueSettingMode,
	setStep,
	handleFormData,
	handleSelectedTabUpdate,
	locationsList,
	copyLocation,
	selectedPlatformAndUrl,
	merakiEnabled
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const disabled =
		(catalogueSettingMode === "copyCatalogue" && copyLocation) ||
		(catalogueSettingMode === "pullCatalogue" && selectedPlatformAndUrl?.url) ||
		catalogueSettingMode === "doLater";

	useEffect(() => {
		fetchStores("", 50);
	}, [fetchStores]);

	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const getDropdownLabel = (platformName) => {
		if (platformName) {
			return (
				<div className="platform-detail">
					{platformName && (
						<div className="logo">
							<img
								src={
									CATALOGUE_PLATFORMS_LOGO[platformName?.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
								}
								alt=""
							/>
						</div>
					)}
					<div>
						{platformName && platformName.length > 16 ? platformName?.slice(0, 16) + "..." : platformName}
					</div>
				</div>
			);
		}
		return <div className="placeholder">Select platform</div>;
	};

	const handleCurrPlatform = (platform) => {
		setIsOpen(false);
		handleFormData("selectedPlatformAndUrl", {
			...selectedPlatformAndUrl,
			id: platform?.id,
			platformName: platform.platformName
		});
	};

	const handleRadioButtonSelection = (type) => {
		handleFormData("catalogueSettingMode", type);
	};

	const handleUpdateCopyLocation = (field, value) => {
		handleFormData("copyLocation", value ? { name: value?.name, id: value?.id } : "");
	};

	const handleUrlChange = (e) => {
		handleFormData("selectedPlatformAndUrl", { ...selectedPlatformAndUrl, url: e.target.value });
	};

	const handleStepSelection = () => {
		trackEvent("wiz_loc_catalogue_setup_mode", { catalogueSettingMode: catalogueSettingMode });
		if (catalogueSettingMode === "doLater") {
			handleSelectedTabUpdate(6);
		} else {
			setStep(2);
		}
	};

	return (
		<a.div className="common-box-structure " style={contentProps}>
			<div className="header">Let's set the catalogue</div>
			<div className="catalogue-setup">
				<div className="catalogue-option">
					<div
						onClick={() => handleRadioButtonSelection("copyCatalogue")}
						className="catalogue-radio-button-field"
					>
						<RadioButton checked={catalogueSettingMode === "copyCatalogue"}>
							Copy catalogue from an already existing location
						</RadioButton>
					</div>
					{catalogueSettingMode === "copyCatalogue" && (
						<div className="catalogue-select-dropdown">
							<SelectFilter
								currValue={copyLocation}
								labelKey={"name"}
								valueKey={"id"}
								options={locationsList?.items}
								setFilter={handleUpdateCopyLocation}
								isAsync={true}
								isSearchable={true}
								handleSearch={fetchStoresDebounced}
								isLoading={locationsList.isLoading}
							/>
						</div>
					)}
				</div>
				{/* <div className="catalogue-option">
					<div onClick={() => handleRadioButtonSelection('pullCatalogue')} className="catalogue-radio-button-field">
						<RadioButton checked={catalogueSettingMode === "pullCatalogue"}>
							Pull the catalogue from a platform location, on which this location is already live
						</RadioButton>
					</div>
					{
						(catalogueSettingMode === "pullCatalogue") &&
						<div className="catalogue-select-dropdown-with-input">
							<div className="platform-container">
								<DropdownCustom
									selected={getDropdownLabel(selectedPlatformAndUrl?.platformName)}
									isOpen={isOpen}
									handleClick={() => setIsOpen(!isOpen)}
									handleOutsideClick={() => setIsOpen(false)}
									clickEvent="mousedown"
								>
									<div className="dropdown-options">
										{
											AVAILABLE_PLATFORMS.length > 0 && AVAILABLE_PLATFORMS.map((plf, i) => (
												<div
													key={i}
													className={"option platform-detail " + (plf.platformName === platformName ? 'selected' : '')}
													onClick={() => handleCurrPlatform(plf)}
												>
													<div className="logo">
														<img src={CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] || '/assets/icons/icons8-globe-40.png'} alt='' />
													</div>
													<div>
														{plf.platformName && plf.platformName.length > 16 ? plf.platformName.slice(0, 16) + '...' : plf.platformName}
													</div>
												</div>
											))
										}
										{
											AVAILABLE_PLATFORMS.length === 0 &&
											<div className="option platform-detail no-hover">No results found</div>
										}
									</div>
								</DropdownCustom>
							</div>
							<InputWithLabel
								type="url"
								placeholder="Enter URL"
								value={selectedPlatformAndUrl?.url}
								onChange={handleUrlChange}
							/>
						</div>
					}
				</div> */}
				<div className="catalogue-option">
					<div onClick={() => handleRadioButtonSelection("doLater")} className="catalogue-radio-button-field">
						<RadioButton checked={catalogueSettingMode === "doLater"}>
							I'll set the catalogue for this location later
						</RadioButton>
					</div>
				</div>
			</div>
			<div className="button-div">
				<Button clickHandler={() => handleSelectedTabUpdate(4)} type="secondary">
					<span>Previous</span>
				</Button>
				<Button classes={!disabled && "disabled"} clickHandler={handleStepSelection}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
export default connect((store) => ({
	locationsList: store.configItems.stores
}))(CatalogueSetup);
