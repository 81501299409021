import React, { Component } from "react";

// third party
import { connect } from "react-redux";

// components
import DateFilter from "./DateFilter";
import NumberRangeFilter from "./NumberRangeFilter";
import TimeRangeFilter from "./TimeRangeFilter";
import MultiSelectFilter from "./MultiSelectFilter";

// actions
import { fetchStoresDebounced } from "../../actions/actions";

@connect((store) => ({
	configItems: store.configItems
}))
export default class CampaignInStore extends Component {
	render() {
		const { filter, configItems } = this.props;
		const in_store_purchase = filter.in_store_purchase || {};
		const { stores } = configItems;

		return (
			<div className="filter-section-container">
				<DateFilter
					title="Purchased on"
					root="in_store_purchase"
					field="purchased_on"
					value={in_store_purchase.purchased_on}
					setFilter={this.props.setFilter}
				/>
				<DateFilter
					title="Not Purchased in"
					root="in_store_purchase"
					field="inactive_on"
					value={in_store_purchase.inactive_on}
					setFilter={this.props.setFilter}
				/>
				<TimeRangeFilter
					title="Time of day"
					root="in_store_purchase"
					field="time_of_day"
					value={in_store_purchase.time_of_day}
					setFilter={this.props.setFilter}
				/>
				<NumberRangeFilter
					title="Purchase amount"
					root="in_store_purchase"
					field="purchase_amount"
					value={in_store_purchase.purchase_amount}
					setFilter={this.props.setFilter}
				/>
				<NumberRangeFilter
					title="Number of purchases"
					root="in_store_purchase"
					field="number_of_purchases"
					value={in_store_purchase.number_of_purchases}
					setFilter={this.props.setFilter}
				/>
				<MultiSelectFilter
					title="Stores"
					root="in_store_purchase"
					options={stores.items}
					field="stores"
					value={in_store_purchase.stores}
					setFilter={this.props.setFilter}
					isLoading={stores.isLoading}
					handleSearch={fetchStoresDebounced}
					labelKey="name"
					valueKey="id"
				/>
			</div>
		);
	}
}
