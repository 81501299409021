import React from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { RadioButton } from "../_commons/RadioButton";

// third party
import moment from "moment";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";

const BasicInfo = ({ data = {}, handleForm, validations, manualBackups = [], handlePreviewBackup }) => {
	return (
		<React.Fragment>
			<div className="form-row row-full">
				<InputWithLabel
					value={data.title}
					onChange={(e) => handleForm("title", e.target.value)}
					validationMessage={validations.title || ""}
					requiredLabel={true}
				>
					Name
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea value={data.description} onChange={(e) => handleForm("description", e.target.value)}>
					Description
				</Textarea>
			</div>
			{manualBackups.length > 0 && (
				<React.Fragment>
					<div className="meta-info required-sign">
						You have reached the limit on manual backups. Choose one to replace.
					</div>
					<div className="manual-backups-container">
						<div className="backup header">
							<div className="name">Name</div>
							<div className="created">Backup On</div>
							<div className="user">User</div>
						</div>
						{manualBackups.map((backup) => (
							<div className="backup">
								<div className="name">
									<RadioButton
										clickHandler={() => handleForm("deleteBackup", backup)}
										checked={data.deleteBackup.id === backup.id}
									>
										<a
											role="button"
											className="link-text"
											onClick={(e) => handlePreviewBackup(e, NESTED_ENTITY_TYPES[17], backup.id)}
										>
											{backup.title || backup.id}
										</a>
									</RadioButton>
								</div>
								<div className="created">
									{backup.created ? moment(backup.created).format("DD MMM, YYYY hh:mm A") : "--"}
								</div>
								<div className="user">
									{backup?.creatorEmail ||
										backup?.updatedBy?.fullName ||
										backup?.updatedBy?.email ||
										backup?.updatedBy?.username}
								</div>
							</div>
						))}
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
export default BasicInfo;
