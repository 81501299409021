import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import Select from "react-select-animated";

// components
import InlineSuggestions from "../_commons/InlineSuggestions";
import { InputWithLabel } from "../_commons/InputWithLabel";

// constants
import { MESSAGE_TYPES, CAMPAIGN_DYANAMIC_VARS } from "../../client-config";

// client
import { store } from "../../store/configureStore";

@connect((store) => ({
	createCampaign: store.createCampaign
}))
export default class CampaignWeb extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messageLength: 0
		};
	}

	changeMType = (messageType) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				messageType: messageType.value
			}
		});
	};

	openTestSMSOverlay() {
		const {
			createCampaign: { message }
		} = this.props;
		store.dispatch({
			type: "SET_OVERLAY_SIDEBAR_STATE",
			payload: {
				active: true,
				medium: "WEB_PUSH_NOTIFICATION",
				formattedSMS: message
			}
		});
	}

	updateContent = (content, messageLength) => {
		this.setState({
			messageLength
		});
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				message: content
			}
		});
	};
	handleSubjectChange = (e) => {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				subject: e.target.value
			}
		});
	};

	render() {
		const { messageLength } = this.state;
		const {
			createCampaign: { message, messageType, subject }
		} = this.props;

		return (
			<div className="campaign-app">
				<div className="meta-info">Message type</div>
				<Select
					searchable={false}
					className="at-dropdown"
					options={MESSAGE_TYPES}
					value={messageType}
					clearable={false}
					onChange={(m) => this.changeMType(m)}
				/>
				<InputWithLabel
					value={subject}
					onChange={this.handleSubjectChange}
					classes="subject-input"
					name="subject"
					placeholder="Type your notification subject here."
				>
					Subject
				</InputWithLabel>
				<div className="meta-info">Content (add dynamic fields with {"{ }"})</div>
				<InlineSuggestions vars={CAMPAIGN_DYANAMIC_VARS} updateContent={this.updateContent} content={message} />
				<div className="sms-meta">
					<div className="sms-count">{`${messageLength} Characters`}</div>
				</div>
				<div className="sms-send-test">
					<img
						src="/assets/icons/icon-push-notification.svg"
						alt=""
						style={{
							transform: "translateY(2px)"
						}}
					/>
					<span className="Cur(p)" onClick={() => this.openTestSMSOverlay()}>
						Send a test push notification
					</span>
				</div>
			</div>
		);
	}
}
