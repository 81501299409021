import React, { Component } from "react";

// third party
import Select from "react-select-animated";

// components
import { CheckBox } from "../_commons/CheckBox";

export default class MultiSelectFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: false
		};
	}

	handleSelect = (val) => {
		const { value } = this.props;
		let newValue = { ...value };
		newValue.type = "multiple";
		newValue.operator = value && value.operator ? value.operator : "in";
		let selectedOpts = value && value.values ? value.values : [];
		selectedOpts.push({
			title: val[this.props.labelKey],
			value: val[this.props.valueKey]
		});
		newValue.values = selectedOpts;
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	handleRemove = (opt) => {
		const { value } = this.props;
		let newValue = { ...value };
		newValue.values = value.values.filter((v) => v.value !== opt.value);
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	handleSearchInput = (inputValue) => {
		this.props.handleSearch(inputValue);
	};

	handleExclude = (currOperator) => {
		const { value } = this.props;
		let newValue = { ...value };
		newValue.operator = currOperator === "not_in" ? "in" : "not_in";
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	setFocus = (focused) => {
		this.setState({
			focused
		});
	};

	render() {
		const { title, options, value, isLoading, labelKey, valueKey, field, excludable } = this.props;
		const { focused } = this.state;
		const filterApplied = value && value.values && value.values.length > 0 ? true : false;
		const selectedOptions = value && value.values ? value.values : [];
		const operator = value && value.operator ? value.operator : "in";
		return (
			<div
				className={
					"multi-select-filter-container filter-section-item" +
					(filterApplied ? " filter-applied" : "") +
					(focused ? " active" : "")
				}
			>
				<div className="meta-info" style={{ width: "63%" }}>
					{title}
					{excludable && (
						<div style={{ float: "right" }}>
							<CheckBox
								checked={operator !== "in"}
								classes="checkbox-inline"
								clickHandler={() => this.handleExclude(operator)}
								title={"exclude"}
							>
								<span>Exclude</span>
							</CheckBox>
						</div>
					)}
				</div>
				<div className="selected-items">
					{selectedOptions.map((opt, i) => {
						if (field === "coupons") {
							return (
								<div title={opt.title} className="selected-item" key={i}>
									<div className="item-remove" onClick={() => this.handleRemove(opt)}>
										x
									</div>
									{opt.value ? (
										<div className="item-title">
											{opt.value && opt.value.length > 7
												? opt.value.substring(0, 7) + "..."
												: opt.value}
										</div>
									) : (
										<div className="item-title">
											{opt.title && opt.title.length > 7
												? opt.title.substring(0, 7) + "..."
												: opt.title}
										</div>
									)}
								</div>
							);
						} else {
							return (
								<div title={opt.title} className="selected-item" key={i}>
									<div className="item-remove" onClick={() => this.handleRemove(opt)}>
										x
									</div>
									<div className="item-title">
										{opt.title && opt.title.length > 10
											? opt.title.substring(0, 7) + "..."
											: opt.title}
									</div>
								</div>
							);
						}
					})}
				</div>
				<Select
					onFocus={() => this.setFocus(true)}
					onBlur={() => this.setFocus(false)}
					autosize={false}
					searchable={true}
					className="at-dropdown"
					clearable={true}
					options={options}
					onChange={this.handleSelect}
					onInputChange={this.handleSearchInput}
					isLoading={isLoading}
					labelKey={labelKey}
					valueKey={valueKey}
					placeholder="Search to find more"
				/>
			</div>
		);
	}
}
