import React, { useState, useCallback } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { InputWithLabel } from "../_commons/InputWithLabel";

// third party
import moment from "moment";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { parseErrorMessages, trackEvent, capitaliseText } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";

// graphql
import { RESTORE_CATALOGUE_BACKUP, DELETE_CATALOGUE_BACKUP } from "../../graphql/catalogueBackups";

const BackupActions = ({ isOpen, isNested = false, data = {}, close, createWorkflow = false }) => {
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [confirmInput, setConfirmInput] = useState("");
	const [error, setError] = useState({});

	const handleClose = useCallback(
		(success = false) => {
			setConfirmInput("");
			close(success);
		},
		[close]
	);

	const handleSubmit = useCallback(async () => {
		setConfirmLoading(true);
		try {
			const variables = {};
			if (data.action === "delete") {
				variables["ids"] = [parseInt(data.id)];
			} else {
				variables["id"] = parseInt(data.id);
			}
			const resp = await client.mutate({
				mutation: data.action === "delete" ? DELETE_CATALOGUE_BACKUP : RESTORE_CATALOGUE_BACKUP,
				variables
			});
			if (
				resp.data?.[data.action === "delete" ? "deleteCatalogBackups" : "restoreCatalogBackup"]?.status?.success
			) {
				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message:
							data.action === "delete"
								? "Backup deleted successfully."
								: "Your catalogue is currently being restored.",
						timeout: 3000,
						error: false
					}
				});
				// track event
				const eventMeta = {
					action: capitaliseText(data.action),
					from: data.from
				};
				trackEvent("catalogue_backups_actions", eventMeta);
				handleClose(true);
			} else {
				setConfirmLoading(false);
				setError(
					parseErrorMessages(
						resp.data?.[data.action === "delete" ? "deleteCatalogBackups" : "restoreCatalogBackup"]?.status
							?.messages
					)
				);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	}, [handleClose, data]);

	return (
		<div className="archive-restore-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={createWorkflow ? () => handleClose(true) : handleSubmit}
				title={`${data.action === "delete" ? "Delete" : "Restore"} ${data.title || "Catalogue Backup"}`}
				subTitle={
					<React.Fragment>
						Backup created by{" "}
						<span>
							{data.takenBy === "System"
								? data.takenBy
								: data?.updatedBy
								? data?.updatedBy?.fullName ||
								  data?.creatorEmail ||
								  data?.updatedBy?.email ||
								  data?.updatedBy?.username
								: data.takenBy}
						</span>{" "}
						on <span>{moment(data.created).format("DD MMM, YYYY")}</span> at{" "}
						<span>{moment(data.created).format("hh:mm A")}</span>
					</React.Fragment>
				}
				submitTitle={createWorkflow ? "Confirm" : data.action === "delete" ? "Delete" : "Restore"}
				submitClass={
					data.action === "delete"
						? confirmInput !== data?.action?.toUpperCase()
							? "at-btn--danger disabled"
							: "at-btn--danger"
						: confirmInput !== data?.action?.toUpperCase()
						? "disabled"
						: ""
				}
				loading={confirmLoading}
				isNested={isNested}
			>
				<div className="form-content">
					<div className="alert">
						<div className="title">
							<img src="/assets/icons/alert.svg" />
							<div>
								Before you {data.action} <span>"{data.title}" catalogue backup</span> please consider:
							</div>
						</div>
						<div className="message">
							{data.action === "delete" ? (
								<ul>
									<li>
										Data related to all catalogue entities such as{" "}
										<span>items, modifiers, taxes & charges</span> etc., in this backup taken on{" "}
										<span>{moment(data.created).format("DD MMM, YYYY")}</span> at{" "}
										<span>{moment(data.created).format("hh:mm A")}</span> will be deleted and lost
										forever.
									</li>
								</ul>
							) : (
								<ul>
									<li>
										Data related to all catalogue entities such as{" "}
										<span>items, modifiers, taxes & charges</span> etc., will be reverted to exactly
										as they were in this backup taken on{" "}
										<span>{moment(data.created).format("DD MMM, YYYY")}</span> at{" "}
										<span>{moment(data.created).format("hh:mm A")}</span>.
									</li>
									<li>The current catalogue data will be overwritten.</li>
									<li>
										If you consider to not loose the current catalogue config,{" "}
										<span>please take a backup now!</span>
									</li>
								</ul>
							)}
						</div>
					</div>
					<div className="text-input-message">
						Please type <span>"{data.action || "Restore"}"</span> to confirm:
					</div>
					<InputWithLabel
						value={confirmInput}
						onChange={(e) => setConfirmInput(e.target.value)}
						onPaste={(e) => e.preventDefault()}
						tabIndex={!isOpen ? "-1" : ""}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default BackupActions;
