import React, { Component } from "react";

// third party
import Select from "react-select-animated";

const DEFAULT_RANGE_ARRAY = [
	{ label: "1", value: 1 },
	{ label: "2", value: 2 },
	{ label: "3", value: 3 },
	{ label: "4", value: 4 },
	{ label: "5", value: 5 },
	{ label: "6", value: 6 },
	{ label: "7", value: 7 },
	{ label: "8", value: 8 },
	{ label: "9", value: 9 },
	{ label: "10", value: 10 }
];

export default class FixedRangeFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rangeTypes: [
				{ label: "Not specified", value: "--" },
				{ label: "Is equal to", value: "equal" },
				{ label: "Is between", value: "between" },
				{ label: "Is greater than", value: "greater_than" },
				{ label: "Is less than", value: "less_than" }
			],
			focused: false
		};
	}

	changeRangeType = (rangeTypeSelected) => {
		let newValue = {};
		const { rangeTypes } = this.state;
		let rangeArray = this.props.rangeArray || DEFAULT_RANGE_ARRAY;
		switch (rangeTypeSelected.value) {
			case rangeTypes[1].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [rangeArray[rangeArray.length - 1].value];
				break;
			case rangeTypes[2].value:
				newValue.type = "range";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [rangeArray[0].value, rangeArray[rangeArray.length - 1].value];
				break;
			case rangeTypes[3].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [rangeArray[0].value];
				break;
			case rangeTypes[4].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = [rangeArray[rangeArray.length - 1].value];
				break;
			default:
				break;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	handleRangeSelect = (e, whichInput) => {
		const { value } = this.props;
		let newValue = { ...value };
		if (whichInput === "rangeStart") {
			newValue.values[0] = e.value;
		} else if (whichInput === "rangeEnd") {
			newValue.values[1] = e.value;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	setFocus = (focused) => {
		this.setState({
			focused
		});
	};

	render() {
		const { title, value } = this.props;
		const filterApplied = value && value.values && value.values.length > 0 ? true : false;
		const { rangeTypes, focused } = this.state;
		let rangeArray = this.props.rangeArray || DEFAULT_RANGE_ARRAY;

		// set default values
		let rangeTypeSelected = rangeTypes[0].value;
		let rangeStart = rangeArray[0].value;
		let rangeEnd = rangeArray[rangeArray.length - 1];

		// set current values according to the props received
		if (value && value.values) {
			rangeTypeSelected = value.operator;
			if (value.type === "range") {
				rangeStart = value.values[0];
				rangeEnd = value.values[1];
			} else if (value.type === "absolute") {
				rangeStart = value.values[0];
			}
			// if operator is greater_than then remove the last number(largest) in the rangeArray
			if (value.operator === "greater_than") {
				rangeArray = rangeArray.slice(0, rangeArray.length - 1);
			} else if (value.operator === "less_than") {
				// if operator is less_than then remove the first number(smallest) in the rangeArray
				rangeArray = rangeArray.slice(1);
			}
		}

		let rangeEndArray = [];
		for (let i = rangeStart; i <= rangeArray[rangeArray.length - 1].value; i++) {
			rangeEndArray.push({
				label: `${i}`,
				value: i
			});
		}

		return (
			<div
				className={
					"fixed-range-filter-container filter-section-item" +
					(filterApplied ? " filter-applied" : "") +
					(focused ? " active" : "")
				}
			>
				<div className="meta-info">{title}</div>
				<div className="type-selector">
					<Select
						onFocus={() => this.setFocus(true)}
						onBlur={() => this.setFocus(false)}
						searchable={false}
						className="at-dropdown"
						options={rangeTypes}
						value={rangeTypeSelected}
						clearable={false}
						onChange={(m) => this.changeRangeType(m)}
					/>
					<div className="type-selected">
						{rangeTypeSelected !== rangeTypes[0].value && (
							<div>
								<Select
									searchable={false}
									className="at-dropdown"
									options={rangeArray}
									value={rangeStart}
									clearable={false}
									onChange={(m) => this.handleRangeSelect(m, "rangeStart")}
								/>
								{rangeTypeSelected === rangeTypes[2].value && (
									<React.Fragment>
										<div className="field-seperator">to</div>
										<Select
											searchable={false}
											className="at-dropdown"
											options={rangeEndArray}
											value={rangeEnd}
											clearable={false}
											onChange={(m) => this.handleRangeSelect(m, "rangeEnd")}
										/>
									</React.Fragment>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
