import React, { useState, useEffect, useCallback } from "react";

// components
import { ColorPicker } from "../_commons/ColorPicker";
import { Switch } from "../_commons/Switch";
import { SelectFilter } from "../_commons/SelectFilter";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Uploader } from "../_commons/Uploader";
import { Button } from "../_commons/Button";
import { Modal } from "../_commons/Modal";
import { ArrayOfObject } from "../_commons/ArrayOfObject";

// third party
import _ from "lodash";

// utils
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

// constants
import { SIDEBAR_MODES } from "../../containers/MerakiWebEditor/index";
import {
	EDITOR_FIELD_TYPES,
	EDITOR_THEME_CONFIG_SECTIONS,
	EDITOR_RENDER_THEME_CONFIG,
	EDITOR_RENDER_PAGES_CONFIG,
	EDITOR_RENDER_COMPONENT_CONFIG,
	LANDING_PAGE_VARIANTS
} from "./editorConfig";

const Sidebar = ({
	selectedPage,
	config,
	setConfig,
	sidebarMode,
	setSidebarMode,
	history,
	handleSidebarInput,
	addCustomComponentToPage,
	handleSidebarAction,
	isEditorTouched = false,
	setEditorTouched
}) => {
	const [showSettings, setShowSettings] = useState(false);
	const [selectedComponent, setSelectedComponent] = useState(undefined);
	const [isModalOpen, setModalOpen] = useState(false);

	const handleThemeSettings = () => {
		setSidebarMode(SIDEBAR_MODES.themeSettings);
	};

	const handleComponentSettings = (component) => {
		// if its a theme page and component is not in the config, add it
		if (selectedPage.type === "theme") {
			const update = { ...config };
			const page = update.theme.pages.find?.((page) => page._id === selectedPage._id);
			if (!page.components) {
				page.components = [];
			}

			const componentExists = page.components.find?.((c) => c._id === component.component.id);
			if (!componentExists) {
				const compObject = {
					_id: component.component.id,
					name: component.component.label,
					customizations: {}
				};
				component.component.config.forEach((config) => {
					compObject.customizations[config.field] = config.default || "";
				});
				page.components.push(compObject);
				setConfig(update);
			}
		}
		setSidebarMode(SIDEBAR_MODES.pageSettings);
		setSelectedComponent(component);
		setShowSettings(true);
	};

	const handleAddComponent = (component) => {
		try {
			addCustomComponentToPage(component.component.default);
			handleComponentSettings(component);
			setEditorTouched(true);
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: String(error) || "Something went wrong",
					timeout: 2000,
					error: true
				}
			});
		}
	};

	const showAddonComponents = () => {
		setSidebarMode(SIDEBAR_MODES.addComponents);
	};

	const handleNavigation = () => {
		if (sidebarMode === SIDEBAR_MODES.pageSettings) {
			if (showSettings) {
				setSelectedComponent(undefined);
				setShowSettings(false);
			} else {
				if (!isEditorTouched) {
					history.push("/meraki-web");
				} else {
					setModalOpen(true);
				}
			}
		} else if (sidebarMode === SIDEBAR_MODES.themeSettings) {
			setSidebarMode(SIDEBAR_MODES.pageSettings);
		} else if (sidebarMode === SIDEBAR_MODES.addComponents) {
			setSidebarMode(SIDEBAR_MODES.pageSettings);
		}
	};

	const handleModalSubmit = () => {
		setEditorTouched(false);
		setTimeout(() => {
			history.push("/meraki-web");
		}, 100);
	};

	useEffect(() => {
		setSidebarMode(SIDEBAR_MODES.pageSettings);
	}, []);

	useEffect(() => {
		if (showSettings) {
			setSelectedComponent(undefined);
			setShowSettings(false);
		}
	}, [selectedPage?._id]);

	useEffect(() => {
		if (showSettings) {
			setSelectedComponent(undefined);
			setShowSettings(false);
		}
	}, [selectedPage?.variant]);

	const getTitle = () => {
		if (sidebarMode === SIDEBAR_MODES.pageSettings) {
			if (showSettings) {
				if (selectedComponent) {
					return selectedComponent.component.label;
				}
				return "Design Settings";
			} else {
				return config?.theme?.name;
			}
		} else if (sidebarMode === SIDEBAR_MODES.themeSettings) {
			return "Theme Settings";
		} else if (sidebarMode === SIDEBAR_MODES.addComponents) {
			return "Add components";
		}
	};

	let configItems = null;
	if (selectedPage && (sidebarMode === SIDEBAR_MODES.pageSettings || sidebarMode === SIDEBAR_MODES.addComponents)) {
		if (selectedPage.type === "custom") {
			// need to deep clone this because we need to insert addon components
			// in the custom page
			configItems = JSON.parse(JSON.stringify(EDITOR_RENDER_PAGES_CONFIG.custom_pages));
			// insert addon components as well
			if (selectedPage.components) {
				selectedPage.components.forEach((cmp) => {
					if (EDITOR_RENDER_COMPONENT_CONFIG[cmp._id]) {
						configItems.components.push({
							component: EDITOR_RENDER_COMPONENT_CONFIG[cmp._id]
						});
					}
				});
			}
		} else {
			if (selectedPage._id === "landing_screen") {
				const variant = selectedPage.variant || LANDING_PAGE_VARIANTS[0].variant;
				configItems = {
					customizations: EDITOR_RENDER_PAGES_CONFIG[selectedPage._id].customizations,
					components: EDITOR_RENDER_PAGES_CONFIG[selectedPage._id].componentsByVariants[variant]
				};
			} else {
				configItems = EDITOR_RENDER_PAGES_CONFIG[selectedPage._id];
			}
		}
	} else if (sidebarMode === SIDEBAR_MODES.themeSettings) {
		configItems = EDITOR_RENDER_THEME_CONFIG;
	}

	return (
		<div className={"side-bar-holder"}>
			<div onClick={(e) => e.stopPropagation(e)} className="side-bar">
				<div className="back-navigation-container">
					<span className="link-text" onClick={handleNavigation}>
						<img className="back-arrow-img" src="/assets/meraki/header/meraki-editor-back-arrow.svg" />
						<span>{getTitle()}</span>
					</span>
				</div>
				<div className="sidebar-editor-container">
					{sidebarMode === SIDEBAR_MODES.pageSettings && configItems && !showSettings && (
						<React.Fragment>
							<div className="editor-section-navigation">Design Settings</div>

							<RenderComponents
								handleComponent={handleComponentSettings}
								showAddonComponents={showAddonComponents}
								components={configItems.components}
								showAddButton={selectedPage?.type === "custom"}
							/>
						</React.Fragment>
					)}
					{sidebarMode === SIDEBAR_MODES.addComponents && configItems && !showSettings && (
						<RenderComponents
							handleComponent={handleAddComponent}
							components={configItems.addonComponents}
							showAddButton={false}
						/>
					)}
					{sidebarMode === SIDEBAR_MODES.pageSettings &&
						configItems &&
						configItems.customizations.length > 0 &&
						!showSettings && (
							<React.Fragment>
								<div className="editor-section-navigation">Page Settings</div>
								<RenderEditor
									sidebarMode={sidebarMode}
									selectedPage={selectedPage}
									config={config}
									handleChange={handleSidebarInput}
									handleAction={handleSidebarAction}
									configItems={configItems.customizations}
								/>
							</React.Fragment>
						)}
					{sidebarMode === SIDEBAR_MODES.pageSettings && showSettings && (
						<RenderEditor
							sidebarMode={sidebarMode}
							selectedPage={selectedPage}
							config={config}
							handleChange={handleSidebarInput}
							handleAction={handleSidebarAction}
							configItems={selectedComponent.component.config}
							selectedComponent={selectedComponent}
						/>
					)}
					{sidebarMode === SIDEBAR_MODES.themeSettings && (
						<RenderEditor
							sidebarMode={sidebarMode}
							selectedPage={selectedPage}
							config={config}
							handleChange={handleSidebarInput}
							handleAction={handleSidebarAction}
							configItems={configItems}
						/>
					)}
				</div>
				<Modal
					isOpen={isModalOpen}
					close={() => setModalOpen(false)}
					showSubmitAction={true}
					submitTitle="Yes"
					submitAction={handleModalSubmit}
					showCancelAction={true}
					cancelTitle="Cancel"
				>
					<div className="custom-page-modal-container">
						<div className="alert-msg">
							Going back now will <b>discard unsaved changes</b>, are you sure you want to go back?
						</div>
					</div>
				</Modal>
				<div className="theme-settings-btn-container">
					{sidebarMode !== SIDEBAR_MODES.themeSettings && (
						<div className="theme-settings-btn" onClick={handleThemeSettings}>
							Theme Settings
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default Sidebar;

const RenderComponents = ({ handleComponent, showAddButton = false, showAddonComponents, components = [] }) => {
	return (
		<div className="page-components">
			{components
				?.filter((comp) => !!comp?.component?.config?.length)
				?.map((comp, i) => (
					<div
						key={i}
						className={"component-container" + (comp?.component?.config?.length === 0 ? " disabled" : "")}
						onClick={() => handleComponent(comp)}
					>
						{comp?.component.icon && <img src={comp?.component.icon} alt="" />}
						<div>{comp?.component.label}</div>
					</div>
				))}
			{showAddButton && (
				<div className={"component-container"} onClick={showAddonComponents}>
					<img src="/assets/meraki/components/icon-add-component.svg" alt="" />
					<div>Add components</div>
				</div>
			)}
		</div>
	);
};

const RenderEditor = ({
	sidebarMode,
	selectedPage,
	config,
	handleChange,
	handleAction,
	configItems,
	selectedComponent
}) => {
	const getValue = useCallback(
		(cfg, checkKey = false) => {
			const keyValueFinder = checkKey ? _.has : _.get;

			let value = null;

			if (sidebarMode === SIDEBAR_MODES.pageSettings) {
				if (selectedPage.type === "theme") {
					// pick value from theme pages
					if (selectedComponent) {
						// pick component settings
						if (selectedComponent?.component?.componentType === "global") {
							// pick theme.components value
							value = keyValueFinder(
								config?.theme?.components?.find?.(
									(component) => component?._id === selectedComponent?.component?.id
								),
								cfg.namespace
							);
						} else if (selectedComponent?.component?.componentType === "local") {
							// pick page.components value
							const currPage = config?.theme?.pages?.find?.((page) => page._id === selectedPage._id);
							const index = config?.theme?.pages?.indexOf(currPage);
							value = keyValueFinder(
								config?.theme?.pages[index]?.components?.find?.(
									(component) => component?._id === selectedComponent?.component?.id
								),
								cfg.namespace
							);
						}
					} else {
						// pick page settings
						const currPage = config?.theme?.pages?.find?.((page) => page._id === selectedPage._id);
						const index = config?.theme?.pages?.indexOf(currPage);
						value = keyValueFinder(config?.theme?.pages[index], cfg.namespace);
					}
				} else if (selectedPage.type === "custom") {
					// pick value from custom page
					if (selectedComponent) {
						// pick component settings
						if (selectedComponent?.component?.componentType === "global") {
							// pick theme.components value
							value = keyValueFinder(
								config?.theme?.components?.find?.(
									(component) => component?._id === selectedComponent?.component?.id
								),
								cfg.namespace
							);
						} else if (selectedComponent?.component?.componentType === "local") {
							// pick page.components value
							const currPage = config?.customPages?.find?.((page) => page._id === selectedPage._id);
							value = keyValueFinder(
								currPage?.components?.find?.(
									(component) => component?._id === selectedComponent?.component?.id
								),
								cfg.namespace
							);
						}
					} else {
						const currPage = config?.customPages?.find?.((page) => page._id === selectedPage._id);
						if (currPage) {
							value = keyValueFinder(currPage, cfg.namespace);
						} else {
							// this custom page is in the draft mode and not yet submitted
							// hence not found inside the root config
							value = keyValueFinder(selectedPage, cfg.namespace);
						}
					}
				}
			} else if (sidebarMode === SIDEBAR_MODES.themeSettings) {
				value = keyValueFinder(config?.theme, cfg.namespace);
			}

			// if namespace is in root then override everything
			// and pick value from root
			if (cfg.namespaceRoot) {
				value = keyValueFinder(config, cfg.namespace);
			}

			return value;
		},
		[sidebarMode, selectedPage, config, selectedComponent]
	);

	const filteredConfigItems = React.useMemo(
		() =>
			configItems?.filter((cfg) => {
				if (!!cfg.nullable) {
					const key = getValue(cfg, true);

					if (!key) return false;
				}

				return true;
			}),
		[configItems]
	);

	if (!selectedPage || !filteredConfigItems) {
		return null;
	}

	if (sidebarMode === SIDEBAR_MODES.themeSettings) {
		return (
			<React.Fragment>
				{EDITOR_THEME_CONFIG_SECTIONS.map((section) => (
					<div className="config-section-container" key={section}>
						<div className="header">{section}</div>
						{selectedPage &&
							filteredConfigItems.map((cfg, i) => {
								if (cfg.section === section) {
									return (
										<RenderConfig
											key={i}
											getValue={getValue}
											cfg={cfg}
											handleChange={handleChange}
											handleAction={handleAction}
											selectedPage={selectedPage}
										/>
									);
								}
							})}
					</div>
				))}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			{selectedPage &&
				filteredConfigItems?.map((cfg, i) => (
					<RenderConfig
						key={i}
						getValue={getValue}
						cfg={cfg}
						handleChange={handleChange}
						handleAction={handleAction}
						selectedComponent={selectedComponent}
						selectedPage={selectedPage}
					/>
				))}
		</React.Fragment>
	);
};

const RenderConfig = ({ getValue, cfg, handleChange, handleAction, selectedComponent, selectedPage }) => {
	const value = getValue(cfg);
	return (
		<div className={cfg.fieldType !== EDITOR_FIELD_TYPES.section ? "editor-control-item" : ""}>
			{cfg.fieldType === EDITOR_FIELD_TYPES.color && (
				<ColorPicker
					value={value || cfg.default}
					onChange={(value) => handleChange(cfg, value, selectedComponent)}
					showCustomTooltip={cfg?.showCustomTooltip}
					tooltipInfo={cfg?.tooltipInfo}
					tooltipPosition={cfg?.tooltipPosition}
				>
					{cfg.label}
				</ColorPicker>
			)}
			{cfg.fieldType === EDITOR_FIELD_TYPES.boolean && (
				<Switch
					title={cfg.label}
					checked={value || false}
					clickHandler={() => handleChange(cfg, !value, selectedComponent)}
					requiredLabel={cfg?.requiredLabel || false}
					showCustomTooltip={cfg?.showCustomTooltip || false}
					tooltipInfo={cfg?.tooltipInfo || ""}
					tooltipPosition={cfg?.tooltipPosition}
				/>
			)}
			{cfg.fieldType === EDITOR_FIELD_TYPES.text && (
				<InputWithLabel
					value={value || cfg.default}
					onChange={(e) => handleChange(cfg, e.target.value, selectedComponent)}
					tooltipInfo={cfg?.tooltipInfo}
					tooltipPosition={cfg?.tooltipPosition}
					showCustomTooltip={cfg?.showCustomTooltip}
				>
					{cfg.label}
				</InputWithLabel>
			)}
			{cfg.fieldType === EDITOR_FIELD_TYPES.dropdown && (
				<SelectFilter
					title={cfg.label}
					options={cfg.options}
					field={cfg.field}
					description={cfg.description}
					currValue={cfg.options.find((opt) => opt["value"] === (value || cfg.default))}
					setFilter={(field, value) => handleChange(cfg, value, selectedComponent)}
					isSearchable={false}
					isClearable={false}
					labelKey="label"
					valueKey="value"
				/>
			)}
			{cfg.fieldType === EDITOR_FIELD_TYPES.section && (
				<React.Fragment>
					<div className="editor-section-navigation Mt(30px)">{cfg.label}</div>
					{cfg.config.map((subCfg, i) => (
						<RenderConfig
							key={i}
							getValue={getValue}
							cfg={subCfg}
							handleChange={handleChange}
							handleAction={handleAction}
							selectedComponent={selectedComponent}
							selectedPage={selectedPage}
						/>
					))}
				</React.Fragment>
			)}
			{cfg.fieldType === EDITOR_FIELD_TYPES.button && (
				<Button
					clickHandler={() => handleAction(cfg, selectedComponent)}
					classes={cfg.actionClass ? `at-btn--${cfg.actionClass}` : ""}
				>
					{cfg.label}{" "}
					{selectedPage?.name?.length > 10 ? selectedPage?.name.slice(0, 10) + "..." : selectedPage.name}
				</Button>
			)}
			{cfg.fieldType === EDITOR_FIELD_TYPES.image && (
				<Uploader
					useCompressor={true}
					uploadToGallery={true}
					onChange={(file) => handleChange(cfg, file, selectedComponent)}
					file={typeof value !== "string" ? value : undefined}
					url={typeof value === "string" ? value : ""}
					aspectRatio={cfg.aspectRatio}
					description={cfg.description}
					showDelete={true}
					handleDelete={(e) => {
						e.stopPropagation();
						handleChange(cfg, "", selectedComponent);
					}}
					showCustomTooltip={cfg?.showCustomTooltip}
					tooltipPosition={cfg?.tooltipPosition}
					tooltipInfo={cfg?.tooltipInfo}
				>
					{cfg.label}
				</Uploader>
			)}
			{cfg.fieldType === EDITOR_FIELD_TYPES.arrayOfObject && (
				<ArrayOfObject
					objects={value || cfg.default}
					onChange={(value) => handleChange(cfg, value, selectedComponent)}
					objectStructure={cfg.objectStructure}
				>
					{cfg.label}
				</ArrayOfObject>
			)}
		</div>
	);
};
