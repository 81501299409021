import React, { Component } from "react";

// components
import { CheckBox } from "../_commons/CheckBox";

export default class CheckboxGroupFilter extends Component {
	handleCheckbox = (checkboxField, currValue) => {
		const { value } = this.props;
		let newValue = {
			type: "multiple",
			operator: value ? value.operator : "in"
		};
		let checkedList = value ? value.values : [];
		let newCheckedList = [];
		if (newValue.operator === "in") {
			if (currValue) {
				newCheckedList = checkedList.filter((c) => {
					if (c === checkboxField.value) return false;
					return true;
				});
			} else {
				newCheckedList = checkedList.slice();
				newCheckedList.push(checkboxField.value);
			}
		} else if (newValue.operator === "not in") {
			if (currValue) {
				newCheckedList = checkedList.slice();
				newCheckedList.push(checkboxField.value);
			} else {
				newCheckedList = checkedList.filter((c) => {
					if (c === checkboxField.value) return false;
					return true;
				});
			}
		}
		newValue.values = newCheckedList;
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	render() {
		const { title, options, value } = this.props;
		const filterApplied = value && value.values && value.values.length > 0 ? true : false;

		return (
			<div className={"checkbox-filter-container filter-section-item " + (filterApplied ? "filter-applied" : "")}>
				<div className="meta-info">{title}</div>
				<div className="checkbox-group">
					{options.map((opt, i) => {
						let isChecked = false;
						if (value && value.operator === "in") {
							let checkedValues = value.values || [];
							isChecked = checkedValues.indexOf(opt.value) !== -1 && true;
						} else if (value && value.operator === "not in") {
							let checkedValues = value.values || [];
							isChecked = checkedValues.indexOf(opt.value) === -1 && true;
						}
						return (
							<CheckBox
								checked={isChecked}
								classes="checkbox-inline"
								clickHandler={() => this.handleCheckbox(opt, isChecked)}
								key={i}
								title={opt.label}
							>
								{opt.label}
							</CheckBox>
						);
					})}
				</div>
			</div>
		);
	}
}
