import React, { Component } from "react";

// third party
import Select from "react-select-animated";

// constants
import { HOURS, MINUTES } from "../../client-config";

export default class TimeRangeFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rangeTypes: [
				{ label: "Not specified", value: "--" },
				{ label: "Is equal to", value: "equal" },
				{ label: "Is between", value: "between" },
				{ label: "Is greater than", value: "greater_than" },
				{ label: "Is less than", value: "less_than" }
			],
			focused: false
		};
	}

	changeRangeType = (rangeTypeSelected) => {
		let newValue = {};
		const { rangeTypes } = this.state;
		switch (rangeTypeSelected.value) {
			case rangeTypes[1].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = ["10:30"];
				break;
			case rangeTypes[2].value:
				newValue.type = "range";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = ["10:30", "14:30"];
				break;
			case rangeTypes[3].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = ["10:30"];
				break;
			case rangeTypes[4].value:
				newValue.type = "absolute";
				newValue.operator = rangeTypeSelected.value;
				newValue.values = ["10:30"];
				break;
			default:
				break;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	handleRangeInput = (e, whichInput) => {
		const { value } = this.props;
		let newValue = { ...value };
		let tempTime = "";
		let tempTimeHH = "";
		let tempTimeMM = "";
		switch (whichInput) {
			case "rangeStartHH":
				tempTime = value.values[0];
				tempTimeHH = e?.value;
				tempTimeMM = tempTime.split(":")[1];
				newValue.values[0] = `${tempTimeHH}:${tempTimeMM}`;
				break;
			case "rangeStartMM":
				tempTime = value.values[0];
				tempTimeHH = tempTime.split(":")[0];
				tempTimeMM = e.value;
				newValue.values[0] = `${tempTimeHH}:${tempTimeMM}`;
				break;
			case "rangeEndHH":
				tempTime = value.values[1];
				tempTimeHH = e.value;
				tempTimeMM = tempTime.split(":")[1];
				newValue.values[1] = `${tempTimeHH}:${tempTimeMM}`;
				break;
			case "rangeEndMM":
				tempTime = value.values[1];
				tempTimeHH = tempTime.split(":")[0];
				tempTimeMM = e.value;
				newValue.values[1] = `${tempTimeHH}:${tempTimeMM}`;
				break;
			default:
				return;
		}
		this.props.setFilter(this.props.root, this.props.field, newValue);
	};

	setFocus = (focused) => {
		this.setState({
			focused
		});
	};

	render() {
		const { title, value } = this.props;
		const filterApplied = value && value.values && value.values.length > 0 ? true : false;
		const { rangeTypes, focused } = this.state;

		// set default values
		let rangeTypeSelected = rangeTypes[0].value;
		let rangeStartHH = "10";
		let rangeStartMM = "30";
		let rangeEndHH = "14";
		let rangeEndMM = "30";

		// set current values according to the props received
		if (value && value.values) {
			rangeTypeSelected = value.operator;
			if (value.type === "range") {
				rangeStartHH = value.values[0].split(":")[0];
				rangeStartMM = value.values[0].split(":")[1];
				rangeEndHH = value.values[1].split(":")[0];
				rangeEndMM = value.values[1].split(":")[1];
			} else if (value.type === "absolute") {
				rangeStartHH = value.values[0].split(":")[0];
				rangeStartMM = value.values[0].split(":")[1];
			}
		}

		return (
			<div
				className={
					"time-range-filter-container filter-section-item" +
					(filterApplied ? " filter-applied" : "") +
					(focused ? " active" : "")
				}
			>
				<div className="meta-info">{title}</div>
				<div className="type-selector">
					<Select
						onFocus={() => this.setFocus(true)}
						onBlur={() => this.setFocus(false)}
						searchable={false}
						className="at-dropdown"
						options={rangeTypes}
						value={rangeTypeSelected}
						clearable={false}
						onChange={(m) => this.changeRangeType(m)}
					/>
					<div className="type-selected">
						{rangeTypeSelected !== rangeTypes[0].value && (
							<React.Fragment>
								<div className="time-range time-start">
									<div className="time-input-container">
										<div>
											<Select
												searchable={true}
												className="at-dropdown time-input"
												options={HOURS}
												value={rangeStartHH}
												clearable={false}
												onChange={(m) => this.handleRangeInput(m, "rangeStartHH")}
											/>
										</div>
										<div className="time-identifier">HH</div>
									</div>
									<div className="time-seperator clock">:</div>
									<div className="time-input-container">
										<div>
											<Select
												searchable={true}
												className="at-dropdown time-input"
												options={MINUTES}
												value={rangeStartMM}
												clearable={false}
												onChange={(m) => this.handleRangeInput(m, "rangeStartMM")}
											/>
										</div>
										<div className="time-identifier">MM</div>
									</div>
								</div>
								{rangeTypeSelected === rangeTypes[2].value && (
									<div className="time-range time-end">
										<div className="time-seperator">to</div>
										<div className="time-input-container">
											<div>
												<Select
													searchable={true}
													className="at-dropdown time-input"
													options={HOURS}
													value={rangeEndHH}
													clearable={false}
													onChange={(m) => this.handleRangeInput(m, "rangeEndHH")}
												/>
											</div>
											<div className="time-identifier">HH</div>
										</div>
										<div className="time-seperator clock">:</div>
										<div className="time-input-container">
											<div>
												<Select
													searchable={true}
													className="at-dropdown time-input"
													options={MINUTES}
													value={rangeEndMM}
													clearable={false}
													onChange={(m) => this.handleRangeInput(m, "rangeEndMM")}
												/>
											</div>
											<div className="time-identifier">MM</div>
										</div>
									</div>
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		);
	}
}
