import React from "react";

const FilterIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
		<path
			d="M5.66732 0.333984C4.79958 0.333984 4.06589 0.89315 3.78971 1.66732H0.333984V3.00065H3.78971C4.06589 3.77482 4.79958 4.33398 5.66732 4.33398C6.76998 4.33398 7.66732 3.43665 7.66732 2.33398C7.66732 1.23132 6.76998 0.333984 5.66732 0.333984ZM9.00065 1.66732V3.00065H13.6673V1.66732H9.00065ZM9.00065 5.00065C8.13291 5.00065 7.39923 5.55982 7.12305 6.33398H0.333984V7.66732H7.12305C7.39923 8.44149 8.13291 9.00065 9.00065 9.00065C10.1033 9.00065 11.0007 8.10332 11.0007 7.00065C11.0007 5.89798 10.1033 5.00065 9.00065 5.00065ZM12.334 6.33398V7.66732H13.6673V6.33398H12.334ZM3.66732 9.66732C2.79958 9.66732 2.06589 10.2265 1.78971 11.0007H0.333984V12.334H1.78971C2.06589 13.1082 2.79958 13.6673 3.66732 13.6673C4.76998 13.6673 5.66732 12.77 5.66732 11.6673C5.66732 10.5647 4.76998 9.66732 3.66732 9.66732ZM7.00065 11.0007V12.334H13.6673V11.0007H7.00065Z"
			fill="#222222"
		/>
	</svg>
);
export default React.memo(FilterIcon);
