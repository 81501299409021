// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_LOCATIONS_LIST,
	GET_PUBLISH_FAILURE_LOCATIONS_LIST,
	GET_LOCATION,
	EDIT_LOCATION,
	EDIT_LOCATION_MERAKI,
	EDIT_LOCATION_SELF_DELIVERY,
	GET_LPA_LOGS,
	ARCHIVE_RESTORE_LOCATION,
	GET_PRIME_VERIFICATION_STATUS,
	EDIT_LOCATION_SCHEDULES,
	EDIT_LOCATION_SCHEDULES_MULTI
} from "../graphql/locations";

// third party
import { findKey } from "lodash";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// constants
import { BRAND_COLORS, PLATFORM_NAME_MAP } from "../client-config";
import { FORM_TABS as LOCATION_FORM_TABS } from "../containers/LocationEdit";
const CITY_SEARCH_API = process.env.REACT_APP_CITY_SEARCH_API;

export const fetchLocationsList = async (platformId = null, brandId = null) => {
	const { limit, offset, sort, appliedFilters } = store.getState().locationsListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().locationsList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_LOCATIONS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		let applyPublishFailureFilter = appliedFilters.platforms ? true : false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "platforms") {
					return;
				} else if (f === "related_item_associations__item" && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].id
					});
				} else if ((f === "state" || f === "meraki_state") && appliedFilters[f] !== null) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(!appliedFilters[f])
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: "true"
			});
		}
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		if (applyPublishFailureFilter) {
			variables.platforms = appliedFilters.platforms.value.split(",");
		}
		// set platform id
		if (platformId && !["default", "allPlatforms", "meraki"].includes(platformId)) {
			variables.platform = parseInt(platformId);
		}
		// set platform id
		if (brandId) {
			variables.brand = String(brandId);
		} else {
			variables.brand = null;
		}
		const resp = await client.query({
			query: applyPublishFailureFilter ? GET_PUBLISH_FAILURE_LOCATIONS_LIST : GET_LOCATIONS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		if (applyPublishFailureFilter) {
			store.dispatch({
				type: ActionTypes.GET_LOCATIONS_LIST_SUCCESS,
				payload: { ...resp.data.publishFailureStores }
			});
		} else {
			// if (platformId && ['default', 'meraki'].includes(platformId)) {
			// 	resp.data.stores.filters.map((filter) => {
			// 		if (filter.field === 'state') {
			// 			filter.values = filter.values.filter((opt) => !['Not live', 'Disconnected'].includes(opt.valueForDisplay));
			// 		}
			// 		return filter;
			// 	});
			// }
			store.dispatch({
				type: ActionTypes.GET_LOCATIONS_LIST_SUCCESS,
				payload: {
					...resp.data.stores,
					objects: resp.data.stores.objects.map((store) => ({
						...store,
						associatedBrandsData: store.associatedBrandsData?.map((brand) => ({
							...brand,
							color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
						}))
					}))
				},
				selectedBrand: brandId
			});
			store.dispatch(toggleGlobalLoader(false));
			return resp.data.stores?.objects?.map((store) => store.id) || [];
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_LOCATIONS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
		store.dispatch(toggleGlobalLoader(false));
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseLocationData = (data, to = "form", brandId = null) => {
	const variableMap = {
		bizLocationNickname: "name",
		merchantBizLocationId: "merchantRefId",
		bizAddress: "address",
		locLatitude: "latitude",
		locLongitude: "longitude"
	};
	let result = { ...data };
	switch (to) {
		case "form":
			for (let x in variableMap) {
				result[x] = result[variableMap[x]];
				delete result[variableMap[x]];
			}
			if (result["tags"].length) {
				result.tags = result.tags.map((tag) => ({
					title: tag,
					titleForDisplay: tag
				}));
			}
			if (result["primaryContactPhone"]) {
				result.primaryContactPhone = result.primaryContactPhone.split(",").map((phone) => ({
					label: phone,
					value: phone
				}));
			}
			if (result["primaryContactEmail"]) {
				result.primaryContactEmail = result.primaryContactEmail.split(",").map((email) => ({
					label: email,
					value: email
				}));
			}
			if (result["minimumOrderDeliveryTime"]) {
				result.minimumOrderDeliveryTime = Number(result.minimumOrderDeliveryTime) / 60;
			}
			if (result["minimumOrderPickupTime"]) {
				result.minimumOrderPickupTime = Number(result.minimumOrderPickupTime) / 60;
			}
			if (result["brandLocations"]?.length > 0) {
				result.brandLocations = result.brandLocations
					?.filter((loc) => loc.isActive)
					?.map((loc) => ({
						...loc,
						brandId: loc.brand.id,
						brandName: loc.brand.name,
						brandImage: loc.brand.image,
						brandColor: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)],
						merchantBizLocationId: loc.merchantRefId
					}));
			}
			result.selectedBrandLocation =
				result["brandLocations"].length > 0
					? brandId && brandId !== "all"
						? result["brandLocations"]
								?.filter((loc) => loc.isActive)
								?.find((loc) => loc.brand.id === brandId) ||
						  result["brandLocations"]?.filter((loc) => loc.isActive)[0]
						: result["brandLocations"]?.filter((loc) => loc.isActive)[0]
					: null;
			result.currentExcludedPlatforms = result?.excludedPlatforms;
			break;
		case "server":
			if (result["storeTimings"]) {
				result["storeTimings"] = parseInt(result["storeTimings"].id);
			}
			if (result["orderingSlots"]) {
				result["orderingSlots"] = parseInt(result["orderingSlots"].id);
			}
			if (result["tags"]) {
				// pick tag's value if newly created else pick tag's title
				result.tags = result.tags.map((tag) => (tag["__isNew__"] ? tag.value : tag.title));
			}
			if (result["fulfillmentModesList"]) {
				result.fulfillmentModes = result.fulfillmentModesList.map((mode) => mode.id);
				delete result.fulfillmentModesList;
			}
			if (result["paymentModesList"]) {
				result.paymentModes = result.paymentModesList.map((mode) => mode.id);
				delete result.paymentModesList;
			}
			if (result["excludedPlatforms"]) {
				result.excludedPlatforms = result.excludedPlatforms.map((plf) => {
					const platform = plf.platformName?.split(" ")?.join("")?.toLowerCase();
					return findKey(PLATFORM_NAME_MAP, (val) => val === platform) || platform;
				});
			}
			if (result["primaryContactPhone"]) {
				result.primaryContactPhone = result.primaryContactPhone.map((phone) => phone.value).join(",");
			}
			if (result["primaryContactEmail"]) {
				result.primaryContactEmail = result.primaryContactEmail.map((email) => email.value).join(",");
			}
			if (result["minimumOrderDeliveryTime"]) {
				result.minimumOrderDeliveryTime = Number(result.minimumOrderDeliveryTime) * 60;
			}
			if (result["minimumOrderPickupTime"]) {
				result.minimumOrderPickupTime = Number(result.minimumOrderPickupTime) * 60;
			}
			if (result?.["orderPrepTime"] === null) {
				result.orderPrepTime = 0;
			}
			if (result?.["busyOrderPrepTime"] === null) {
				result.busyOrderPrepTime = 0;
			}
			if (result["brandLocations"]?.length > 0) {
				result.brandLocations = result.brandLocations.map((loc) => ({
					id: loc.id,
					brandId: loc.brandId,
					description: loc.description,
					seoDescription: loc.seoDescription,
					excludedPlatforms: loc.excludedPlatforms.map((plf) => plf.platformName),
					merchantBizLocationId: loc.merchantBizLocationId === "" ? "-1" : loc.merchantBizLocationId,
					orderingSlots: loc?.orderingSlots?.id
						? parseInt(loc?.orderingSlots?.id)
						: data["orderingSlots"]?.id
						? parseInt(data["orderingSlots"]?.id)
						: null,
					holidayHourIds:
						loc?.holidayHours || loc?.holidayHours?.objects
							? (loc?.holidayHours || loc?.holidayHours?.objects)?.length > 0
								? (loc?.holidayHours || loc?.holidayHours?.objects)?.map((hour) => parseInt(hour?.id))
								: data["holidayHours"]?.objects?.map((obj) => parseInt(obj?.id))
							: null
				}));
			}
			break;
		default:
			break;
	}
	return result;
};
export const fetchLpaLogs = async (platformId = null, locationsList = [], action = "publish") => {
	if (platformId && locationsList.length !== 0 && !["default", "allPlatforms", "meraki"].includes(platformId)) {
		store.dispatch({
			type: action === "publish" ? ActionTypes.SET_ILPA_LOGS_LOADING : ActionTypes.SET_ILPA_ACTION_LOGS_LOADING,
			payload: true
		});
		try {
			store.dispatch(toggleGlobalLoader(true));
			const variables = {
				platform: parseInt(platformId),
				locations: locationsList.map((loc) => parseInt(loc.id)),
				action
			};
			const resp = await client.query({
				query: GET_LPA_LOGS,
				variables,
				fetchPolicy: "no-cache"
			});
			let lpaLogs = resp?.data?.lpaLogs || [];
			let lpaLogsObject = {};
			if (lpaLogs.length > 0) {
				for (let i = 0; i < lpaLogs.length; i++) {
					lpaLogsObject[lpaLogs[i].location] = { ...lpaLogs[i] };
				}
			}
			store.dispatch({
				type: ActionTypes.GET_LOCATIONS_LIST_SUCCESS,
				payload: {
					...store.getState().locationsList?.data,
					[action === "toggle" ? "ilpaActionStatusLogs" : "ilpaLogs"]: { ...lpaLogsObject }
				}
			});
			store.dispatch({
				type:
					action === "publish" ? ActionTypes.SET_ILPA_LOGS_LOADING : ActionTypes.SET_ILPA_ACTION_LOGS_LOADING,
				payload: false
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			store.dispatch({
				type:
					action === "publish" ? ActionTypes.SET_ILPA_LOGS_LOADING : ActionTypes.SET_ILPA_ACTION_LOGS_LOADING,
				payload: false
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	} else {
		store.dispatch({
			type: action === "publish" ? ActionTypes.SET_ILPA_LOGS_LOADING : ActionTypes.SET_ILPA_ACTION_LOGS_LOADING,
			payload: false
		});
	}
};

export const fetchPrismVerificationStatus = async (platformName = undefined, locationsList = []) => {
	if (!["Default", "All platforms", "Meraki"].includes(platformName) && locationsList.length) {
		store.dispatch({
			type: ActionTypes.SET_VERIFICATION_STATUS_LOADING,
			payload: true
		});
		try {
			store.dispatch(toggleGlobalLoader(true));
			const variables = {
				platformName,
				locationIds: locationsList.map((loc) => parseInt(loc.id))
			};
			const resp = await client.query({
				query: GET_PRIME_VERIFICATION_STATUS,
				variables,
				fetchPolicy: "no-cache"
			});

			let prismVerificationStatus = resp?.data?.prismVerificationStatus || [];
			let prismVerificationObject = {};
			prismVerificationStatus.forEach((status) => {
				prismVerificationObject[status.bizLocationId] = status;
			});
			store.dispatch({
				type: ActionTypes.GET_LOCATIONS_LIST_SUCCESS,
				payload: {
					...store.getState().locationsList?.data,
					prismVerification: prismVerificationObject
				}
			});
			store.dispatch({
				type: ActionTypes.SET_VERIFICATION_STATUS_LOADING,
				payload: false
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "SHOW_GLOBAL_ERROR",
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			store.dispatch({
				type: ActionTypes.SET_VERIFICATION_STATUS_LOADING,
				payload: false
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	} else {
		store.dispatch({
			type: ActionTypes.SET_VERIFICATION_STATUS_LOADING,
			payload: false
		});
	}
};

export const fetchLocationDetail = async (id, dispatch, brandId = null) => {
	dispatch({
		type: ActionTypes.GET_LOCATION_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		if (brandId) {
			variables.brandId = brandId;
		}
		const resp = await client.query({
			query: GET_LOCATION,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_LOCATION_DETAIL_SUCCESS,
			payload: parseLocationData(resp.data.store, "form", brandId)
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_LOCATION_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching store details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editLocation = async (data, dispatch, formTab = "") => {
	dispatch({
		type: ActionTypes.EDIT_LOCATION_DETAIL_REQUEST
	});

	const getMutation = () => {
		let mutation;

		if ([LOCATION_FORM_TABS[3].value].includes(formTab)) {
			mutation = EDIT_LOCATION_MERAKI;
		} else if (formTab === LOCATION_FORM_TABS[4].value) {
			mutation = EDIT_LOCATION_SELF_DELIVERY;
		} else if (formTab === LOCATION_FORM_TABS[6].value && (data?.orderingSlots?.id || data?.holidayHourIds)) {
			mutation = EDIT_LOCATION_SCHEDULES;
		} else if (formTab === LOCATION_FORM_TABS[6].value && data?.brandLocations) {
			mutation = EDIT_LOCATION_SCHEDULES_MULTI;
		} else {
			mutation = EDIT_LOCATION;
		}
		return mutation;
	};
	try {
		const resp = await client.mutate({
			mutation: getMutation(),
			variables: parseLocationData(data, "server")
		});
		if (resp.data.store.status.success) {
			dispatch({
				type: ActionTypes.EDIT_LOCATION_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Location saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_LOCATIONS_LIST,
				payload: resp.data.store.object
			});
			return true;
		} else {
			// handle error message
			if (resp.data.store.status.messages.length && resp.data.store.status.messages[0].field === null) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.store.status.messages[0].message,
						timeout: 3000,
						error: true
					}
				});
			} else {
				dispatch({
					type: ActionTypes.EDIT_LOCATION_DETAIL_FAILURE,
					error: parseErrorMessages(resp.data.store.status.messages)
				});
			}
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_LOCATION_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Location details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreLocation = async (data, fromListView = false, brand = {}) => {
	try {
		const variables = fromListView
			? { locationIds: data.data.map((loc) => loc.id), isActive: data.isActive }
			: parseLocationData(data, "server");
		if (fromListView && brand !== null) {
			variables.brand = brand?.id;
		}
		if (variables.merchantBizLocationId === "") {
			variables.merchantBizLocationId = "-1";
		}
		const resp = await client.mutate({
			mutation: fromListView ? ARCHIVE_RESTORE_LOCATION : EDIT_LOCATION,
			variables
		});
		if (fromListView) {
			if (resp.data.archiveRestoreLocations.status.success) {
				return true;
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.store.status.messages),
						timeout: 5000,
						error: true
					}
				});
				return false;
			}
		} else {
			if (resp.data.store.status.success) {
				return true;
			} else {
				// handle error message
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.store.status.messages),
						timeout: 5000,
						error: true
					}
				});
				return false;
			}
		}
	} catch (error) {
		throw error;
	}
};

export const fetchCitiesList = async (searchCity) => {
	try {
		const country = store.getState()?.login?.loggedInbizDetail?.country || "India";
		const url = `${CITY_SEARCH_API}/?city=${searchCity}&limit=100&country=${country}`;
		const token = store.getState().login.loginDetail.token;
		const resp = await fetch(url, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const cityData = await resp.json();
		return cityData;
	} catch (error) {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Failed to search cities",
				timeout: 5000,
				error: true
			}
		});
		console.log(error);
	}
};
