import React from "react";

const RectangularProgressbar = ({ totalProgressLevels, currentProgressLevel }) => {
	return (
		<div className="rectangular-progress-bar--container">
			{[...new Array(totalProgressLevels)].map((progress, i) => {
				return (
					<div className="rectangular-progress-bar--content" key={i}>
						<div
							className="rectangular-progress-bar--loader"
							style={{ width: i <= currentProgressLevel ? "100%" : "0" }}
						></div>
					</div>
				);
			})}
			<div className="rectangular-progress-bar--numeric-display">
				{currentProgressLevel + 1 < totalProgressLevels ? currentProgressLevel + 1 : totalProgressLevels} of{" "}
				{totalProgressLevels}
			</div>
		</div>
	);
};
export default RectangularProgressbar;
