import React from "react";

// components
import { OutsideClick } from "./OutsideClick";

// third party
import { Transition, config, animated } from "react-spring/renderprops";

const DropdownCustom = ({
	title = "",
	selected,
	isOpen,
	handleClick,
	requiredLabel = false,
	children,
	nodeRef,
	classes = "",
	showArrow = true,
	currValue,
	multi = false,
	isClearable = false,
	clearCurrValue,
	align = "", // left or right
	selectedValueInlineStyles = {},
	showCustomTooltip = false,
	tooltipInfo,
	tooltipPosition = "up-left"
}) => {
	return (
		<div ref={nodeRef} className={"dropdown-custom " + classes}>
			{title && (
				<div className="meta-info">
					<span className={requiredLabel ? " required-sign" : ""}>{title}</span>
					{showCustomTooltip && tooltipInfo && (
						<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
							<img className="info" src="/assets/icons/info.png" onClick={(e) => e.stopPropagation()} />
						</span>
					)}
				</div>
			)}
			<div
				className={"container " + (isOpen ? "is-open " : "") + (multi ? "is-multi " : "")}
				onClick={handleClick}
			>
				<span style={selectedValueInlineStyles}>{selected}</span>
				{isClearable && (multi ? currValue?.length > 0 : currValue) && (
					<div className="dropdown-clear" onClick={(e) => clearCurrValue(e)}>
						<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
							<path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
						</svg>
					</div>
				)}
				{showArrow && (
					<div className={"dropdown-arrow" + (isOpen ? " is-open" : "")}>
						<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
							<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
						</svg>
					</div>
				)}
			</div>
			<Transition
				native
				items={isOpen}
				from={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
				enter={{ opacity: 1, transform: "perspective(800px) rotateX(0deg)", transformOrigin: "top" }}
				leave={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
				config={config.stiff}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div style={props} className={"dropdown-component " + align}>
							{children}
						</animated.div>
					))
				}
			</Transition>
		</div>
	);
};
export default OutsideClick(DropdownCustom);
