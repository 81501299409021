import React from "react";

// third party
import { connect } from "react-redux";

// component
import { Modal } from "../_commons/Modal";

// utils
import history from "../../history";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

const PendingChangesWarningModal = ({
	menuId,
	pendingChangesCount = 0,
	isOpen,
	targetPath,
	handleDestructiveSave,
	parentSectionSearchKey
}) => {
	const handleClose = () => {
		store.dispatch({
			type: ActionTypes.MENU_PENDING_CHANGES_MODAL_STATE,
			payload: false
		});
	};

	const handleCancel = () => {
		history.push(targetPath);
		store.dispatch({
			type: ActionTypes.MENU_PENDING_CHANGES_MODAL_STATE,
			payload: false
		});
	};

	const handleSave = () => {
		store.dispatch({
			type: ActionTypes.MENU_PENDING_CHANGES_MODAL_STATE,
			payload: false
		});
		handleDestructiveSave(menuId, "SAVE", parentSectionSearchKey);
	};

	return (
		<Modal
			isOpen={isOpen}
			submitTitle="Save"
			cancelTitle="Later"
			title="Unsaved changes"
			showCancelAction
			showSubmitAction
			classes="menu-pending-changes-warning--modal"
			close={handleClose}
			cancelAction={handleCancel}
			submitAction={handleSave}
		>
			You have <strong>&nbsp;{pendingChangesCount} unsaved changes</strong>. Would you like to save them ?
		</Modal>
	);
};
const mapStateToProps = (store) => ({
	isOpen: store.menuPendingChangesWarningStates.isPendingChangesWarningModalOpen,
	targetPath: store.menuPendingChangesWarningStates.targetPath
});
export default connect(mapStateToProps)(PendingChangesWarningModal);
