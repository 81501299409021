// action types
import { ActionTypes } from "../actions/_types";

const INITIAL_PIPER_ACADEMY_STATE = {
	location: "",
	currCourse: undefined,
	currLesson: undefined,
	start: ""
};

export const piperAcademyState = (state = INITIAL_PIPER_ACADEMY_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_PIPER_ACADEMY_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
