import React from "react";

// third party
import moment from "moment";

// constant
import { PRESET_TYPES } from "../_commons/DateCompareFilter";

export const StatusMetrics = ({ data = {}, loading = false, error = false, appliedFilter = {} }) => {
	let isCustomDateRange = false;
	const currentDates = appliedFilter.dateFilter.split(",");
	let currentStart = "";
	let currentEnd = "";
	let presetDateFilterLabel = "";
	if (currentDates.length === 2) {
		isCustomDateRange = true;
		currentStart = moment(currentDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		currentEnd = moment(currentDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	} else {
		presetDateFilterLabel = PRESET_TYPES.find((pt) => pt.value === appliedFilter.dateFilter).label;
	}

	return (
		<div className="status-metrics">
			{/* <div className="header-container">
				<div className="sub-title">
					Trends in net promoter score
					<span>{isCustomDateRange ? ' from ' : ' for '}</span>
					{
						isCustomDateRange ?
						<React.Fragment>
							<span className="highlight">{currentStart}</span>
							<span> to </span>
							<span className="highlight">{currentEnd}</span>
						</React.Fragment>
						:
						<span className="highlight">{presetDateFilterLabel}</span>
					}
				</div>
			</div> */}
			{!error && !loading ? (
				<MetricBlocks data={data.current || {}} />
			) : loading ? (
				<MetricsBlockPlaceholder />
			) : (
				<div className="no-items-placeholder error">
					An error occurred while fetching the data, Please reload.
				</div>
			)}
		</div>
	);
};

const MetricBlocks = ({ data = {} }) => {
	const nps = Math.floor(data.percentagePromoters - data.percentageDetractors);
	return (
		<div className="status-metrics-blocks-container">
			<div className="sub-title">
				<div className="highlight">
					NPS: {nps > 0 ? "+" : ""}
					{nps}
				</div>
			</div>
			<div className="metric-block">
				<div className="display-name promoter">PROMOTERS</div>
				<div className="value">{data.promoters || 0}</div>
				<div className="percentage">{Math.floor(data.percentagePromoters) || 0}%</div>
			</div>
			<div className="metric-block">
				<div className="display-name passive">PASSIVES</div>
				<div className="value">{data.passives || 0}</div>
				<div className="percentage">{Math.floor(data.percentagePassives) || 0}%</div>
			</div>
			<div className="metric-block">
				<div className="display-name detractor">DETRACTORS</div>
				<div className="value">{data.detractors || 0}</div>
				<div className="percentage">{Math.floor(data.percentageDetractors) || 0}%</div>
			</div>
		</div>
	);
};

const MetricsBlockPlaceholder = () => {
	return (
		<div className="status-metrics-block-container">
			<div className="shimmer H(140px)" />
		</div>
	);
};
