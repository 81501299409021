// action types
import { ActionTypes } from "../actions/_types";

// constants
import { TOPIC_LIST_SA_PERFORMANCE } from "../client-config";
import { PRESET_TYPES, DATE_TYPES, COMPARE_DATE_TYPES } from "../components/_commons/NewDateCompareFilter";

//third-party
import moment from "moment";

export const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[3].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[3],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter:
			moment().subtract(2, "week").format("YYYY-MM-DD") + "," + moment().subtract(1, "week").format("YYYY-MM-DD"),
		dateTypeSelected: COMPARE_DATE_TYPES[2],
		rangeStartDate: moment().subtract(2, "week"),
		rangeEndDate: moment().subtract(1, "week")
	}
};

const OE_INITIAL_STATE = {
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedFilters: {}
};

export const operationalEfficiencyState = (state = OE_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_OE_STATE:
			return {
				...state,
				...action.payload
			};
		case "SET_APP_MODULE":
			return {
				...state,
				performanceTopic:
					action.payload === "ORDERING" ? TOPIC_LIST_SA_PERFORMANCE[0] : TOPIC_LIST_SA_PERFORMANCE[5]
			};
		case ActionTypes.RESET_OE_STATE:
			return OE_INITIAL_STATE;
		default:
			return state;
	}
};

export const operationalEfficiencyTransactionsListState = (
	state = {
		limit: 10,
		offset: 0
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.OPERATIONAL_EFFICIENCY_TRANSACTION_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const operationalEfficiencyMetricsBizLocationLevelListState = (
	state = {
		limit: 10,
		offset: 0
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.OPERATIONAL_EFFICIENCY_METRICS_BIZ_LOCATION_LEVEL_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const operationalEfficiencyMetricsBizLocationLevel = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const operationalEfficiencyMetricsBizLevel = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_METRICS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const operationalEfficiencyFilters = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_FILTERS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_FILTERS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_FILTERS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const operationalEfficiencyListView = (
	state = {
		loading: true,
		data: [],
		error: false
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
