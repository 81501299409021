import React, { useState } from "react";

// components
import { CopyToClipboard } from "../_commons/CopyToClipboard";

const Upstream = ({ payload = "" }) => {
	const [copyPayloadBtn, setCopyPayloadBtn] = useState(false);
	return (
		<div className="upstream-container">
			{payload && (
				<React.Fragment>
					<div className="title">Aggregator Payload</div>
					<div
						className="json-container payload"
						onMouseEnter={() => setCopyPayloadBtn(true)}
						onMouseLeave={() => setCopyPayloadBtn(false)}
					>
						{copyPayloadBtn && <CopyToClipboard content={payload || ""} showIcon={true} />}
						{payload && <pre>{JSON.stringify(JSON.parse(payload), null, 4)}</pre>}
					</div>
				</React.Fragment>
			)}
			{!payload && <div className="no-items-placeholder">No Payload found</div>}
		</div>
	);
};
export default Upstream;
