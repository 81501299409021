// action types
import { ActionTypes } from "../actions/_types";

// constants
import {
	PRESET_TYPES,
	DATE_TYPES,
	CUSTOM_TYPES,
	COMPARE_DATE_TYPES
} from "../components/_commons/NewDateCompareFilter";

export const REPORTS_LIST_INITIAL_STATE = {
	loading: true,
	data: [],
	error: {}
};
export const reportsList = (state = REPORTS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_REPORTS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_REPORTS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_REPORTS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_REPORTS_LIST_STATE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_REPORTS_LIST:
			return REPORTS_LIST_INITIAL_STATE;
		default:
			return state;
	}
};

export const reportsNewList = (state = REPORTS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_REPORTS_NEW_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_REPORTS_NEW_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_REPORTS_NEW_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_REPORTS_NEW_LIST_STATE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_REPORTS_NEW_LIST:
			return REPORTS_LIST_INITIAL_STATE;
		default:
			return state;
	}
};

export const REPORT_DETAIL_INIT_STATE = {
	loading: true,
	data: {},
	previewData: {},
	error: {},
	emails: [],
	currentFilters: {},
	appliedFilters: {},
	currentDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[1].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[1],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	},
	appliedDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[1].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[1],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	},
	validations: {},
	optionUpdates: {},
	isCheckedAll: {}
};
export const reportDetail = (state = REPORT_DETAIL_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_REPORT_DETAIL_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.GET_REPORT_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
				// backupData: action.payload
			};
		case ActionTypes.GET_REPORT_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.GET_REPORT_PREVIEW_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.GET_REPORT_PREVIEW_SUCCESS:
			return {
				...state,
				loading: false,
				previewData: action.payload
			};
		case ActionTypes.GET_REPORT_PREVIEW_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_REPORT_DETAIL:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_REPORT_DETAIL:
			return REPORT_DETAIL_INIT_STATE;
		default:
			return state;
	}
};

const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[8].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[8],
		customTypeSelected: CUSTOM_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[2],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};
export const EXPORT_REPORT_HISTORY_INIT_STATE = {
	limit: 10,
	offset: 0,
	prevId: "",
	nextId: "",
	currPage: 1,
	loading: true,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [{ key: "name", valueForDisplay: "Name" }],
		searchFieldSelected: { key: "name", valueForDisplay: "Name" },
		searchFieldValue: ""
	},
	error: {},
	tableColumnsSelected: {
		columns: {
			"report-name": true,
			"downloaded-on": true,
			"filters-applied": false,
			"exported-to": true,
			size: true,
			"access-report": true
		}
	},
	currentFilters: {
		group: { valueForDisplay: "All", value: "all" },
		downloaded_by: { valueForDisplay: "Me", value: "only_me" }
	},
	appliedFilters: {
		group: { valueForDisplay: "All", value: "all" },
		downloaded_by: { valueForDisplay: "Me", value: "only_me" }
	},
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE
};
export const exportReportHistory = (state = EXPORT_REPORT_HISTORY_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_EXPORT_REPORT_HISTORY_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.GET_EXPORT_REPORT_HISTORY_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.GET_EXPORT_REPORT_HISTORY_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_EXPORT_REPORT_HISTORY:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_EXPORT_REPORT_HISTORY:
			return EXPORT_REPORT_HISTORY_INIT_STATE;
		default:
			return state;
	}
};

export const REPORT_PREVIEW_INIT_STATE = {
	limit: 20,
	loading: true,
	previewData: {
		message: "",
		tabularData: {
			fields: [],
			columns: [],
			rows: []
		}
	},
	error: {}
};
export const reportPreview = (state = REPORT_PREVIEW_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_REPORT_PREVIEW_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.GET_REPORT_PREVIEW_SUCCESS:
			return {
				...state,
				loading: false,
				previewData: action.payload
			};
		case ActionTypes.GET_REPORT_PREVIEW_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_REPORT_PREVIEW:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_REPORT_PREVIEW:
			return REPORT_PREVIEW_INIT_STATE;
		default:
			return state;
	}
};

export const SCHEDULE_REPORTS_LIST_INIT_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "",
		order: ""
	},
	loading: true,
	data: {
		objects: [],
		filters: [],
		searchKeywords: [{ key: "name", valueForDisplay: "Name" }],
		searchFieldSelected: { key: "name", valueForDisplay: "Name" },
		searchFieldValue: ""
	},
	error: {},
	currentFilters: {
		status: { valueForDisplay: "All", value: "all" },
		report_user: { valueForDisplay: "Me", value: "only_me" }
	},
	appliedFilters: {
		status: { valueForDisplay: "All", value: "all" },
		report_user: { valueForDisplay: "Me", value: "only_me" }
	}
};
export const scheduleReportsList = (state = SCHEDULE_REPORTS_LIST_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_SCHEDULE_REPORTS_LIST_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.GET_SCHEDULE_REPORTS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.GET_SCHEDULE_REPORTS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_SCHEDULE_REPORTS_LIST:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_SCHEDULE_REPORTS_LIST:
			return SCHEDULE_REPORTS_LIST_INIT_STATE;
		default:
			return state;
	}
};

export const SCHEDULE_REPORT_CREATE_INIT_STATE = {
	loading: true,
	scheduleReportData: {
		scheduleReportFilters: []
	},
	error: {}
};
export const scheduleReportCreate = (state = SCHEDULE_REPORT_CREATE_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SCHEDULE_REPORT_CREATE_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.SCHEDULE_REPORT_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				scheduleReportData: action.payload
			};
		case ActionTypes.SCHEDULE_REPORT_CREATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_SCHEDULE_REPORT_CREATE_STATE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_SCHEDULE_REPORT_CREATE_STATE:
			return SCHEDULE_REPORT_CREATE_INIT_STATE;
		default:
			return state;
	}
};

export const SCHEDULE_REPORT_DETAIL_INIT_STATE = {
	loading: true,
	scheduleReportDetail: {},
	error: {}
};
export const scheduleReportDetail = (state = SCHEDULE_REPORT_DETAIL_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SCHEDULE_REPORT_DETAIL_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.SCHEDULE_REPORT_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				scheduleReportDetail: {
					...state.scheduleReportDetail,
					...action.payload
				}
			};
		case ActionTypes.SCHEDULE_REPORT_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_SCHEDULE_REPORT_DETAIL_STATE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_SCHEDULE_REPORT_DETAIL_STATE:
			return SCHEDULE_REPORT_DETAIL_INIT_STATE;
		default:
			return state;
	}
};

export const SCHEDULE_REPORT_HISTORY_INIT_STATE = {
	limit: 10,
	offset: 0,
	prevId: "",
	nextId: "",
	currPage: 1,
	loading: true,
	data: {
		objects: [],
		filters: []
	},
	error: {},
	currentFilters: {
		downloaded_by: { valueForDisplay: "All", value: "all" }
	},
	appliedFilters: {
		downloaded_by: { valueForDisplay: "All", value: "all" }
	}
};
export const scheduleReportHistory = (state = SCHEDULE_REPORT_HISTORY_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_SCHEDULE_REPORT_HISTORY_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.GET_SCHEDULE_REPORT_HISTORY_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.GET_SCHEDULE_REPORT_HISTORY_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_SCHEDULE_REPORT_HISTORY:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_SCHEDULE_REPORT_HISTORY:
			return SCHEDULE_REPORT_HISTORY_INIT_STATE;
		default:
			return state;
	}
};
