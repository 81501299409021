import React, { useState, useEffect, useRef, useCallback } from "react";

// third party
import { connect } from "react-redux";

// utils
import { scroll } from "../../atlas-utils";

export const Topbar = connect((store) => ({
	dimensions: store.configItems.dimensions
}))(({
	selectedTab,
	switchTab,
	tabs,
	isStickyOnTop,
	renderTab,
	archived = "",
	isScrollable = false,
	valueField = "value",
	labelField = "label",
	classes = "",
	hiddenTabs = [],
	dimensions
}) => {
	const [scrollable, setScrollable] = useState(isScrollable);
	const topBarRef = useRef();

	const handleScroll = (direction = false) => {
		const topBar = document.getElementsByClassName("at-top-bar-wrapper-v2");
		const activeTopBar = topBar[topBar.length - 1];
		if (direction) {
			// scroll to the right
			scroll({ top: 0, left: activeTopBar.scrollLeft + 500 }, activeTopBar);
		} else {
			// scroll to the left
			scroll({ top: 0, left: activeTopBar.scrollLeft - 500 }, activeTopBar);
		}
	};

	const horizontalScroll = useCallback((e) => {
		const container = topBarRef.current;
		if (container) {
			container.scrollTo({
				top: 0,
				left: container.scrollLeft + e.deltaY,
				behavior: "auto"
			});
		}
	}, []);

	const checkTabsOverflow = () => {
		const container = topBarRef.current;
		const topbar = container?.querySelector(".at-top-bar-v2");
		if (container && topbar) {
			if (topbar?.clientWidth > container?.clientWidth) {
				setScrollable(true);
			} else {
				setScrollable(false);
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			checkTabsOverflow();
		}, 100);
	}, [tabs, dimensions, hiddenTabs]);

	const handleScrollToSelectedTab = () => {
		const container = topBarRef.current;
		const topbar = container.querySelector(".at-top-bar-v2");
		if (container && topbar) {
			const currSelectedTab = topbar.querySelector(".selected");
			if (currSelectedTab) {
				scroll({ top: 0, left: currSelectedTab.offsetLeft - currSelectedTab.scrollWidth }, container);
			}
		}
	};

	useEffect(() => {
		if (scrollable && selectedTab) {
			handleScrollToSelectedTab();
		}
	}, [scrollable, selectedTab]);

	if (tabs.length === 0) {
		return null;
	}
	return (
		<div
			className={"at-top-bar-wrapper-v2 " + (isStickyOnTop ? "wrapper-sticky-top " : "") + (classes || "")}
			ref={topBarRef}
			onWheel={horizontalScroll}
		>
			<div className="at-top-bar-v2">
				{scrollable && (
					<div className="topbar-column scroll-left" onClick={() => handleScroll(false)}>
						<div className="text">
							<img src="/assets/icons/icon-left-arrow.svg" alt="" />
						</div>
					</div>
				)}
				{tabs.map(
					(tab, i) =>
						!hiddenTabs.includes(tab[valueField]) && (
							<div
								className={`${
									tab[valueField] === selectedTab ? "selected " + archived : ""
								}  selectable topbar-column ${tab?.fillGreen ? "topbar-column-newbtn" : ""}`}
								key={i}
								onClick={() => switchTab(tab)}
							>
								{renderTab ? (
									renderTab(tab)
								) : (
									<div className="text">
										{tab[labelField]}
										{tab?.showCount && <span className="count">{tab?.count}</span>}
										{tab.isNew && !tab?.fillGreen && <span className="label new">New</span>}
										{tab.isNew && tab?.fillGreen && (
											<span className="label new new-btn">
												New <span class="diagonal-animation"></span>
											</span>
										)}
										{tab.isBeta && <span className="label beta">Beta</span>}
									</div>
								)}
							</div>
						)
				)}
				{scrollable && (
					<div className="topbar-column scroll-right" onClick={() => handleScroll(true)}>
						<div className="text">
							<img src="/assets/icons/icon-right-arrow.svg" alt="" />
						</div>
					</div>
				)}
			</div>
		</div>
	);
});
