import React, { useState, useEffect } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/APIAccountEdit/BasicInfo";

// third party
import { connect } from "react-redux";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// actions
import { fetchCiListGroups, fetchStores } from "../actions/actions";
import { fetchAPIAccountsList } from "../actions/apiAccounts";

// graphql
import { CREATE_API_ACCOUNT } from "../graphql/apiAccounts";

const APIAccountCreate = ({ listGroups }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [data, setData] = useState({
		fullName: "",
		groups: [],
		locations: ""
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiListGroups({ api: true });
		fetchStores("", 100);
	}, []);

	const handleCancel = () => {
		setFormState(false);
		setFormTouched(false);
		fetchAPIAccountsList();
		setTimeout(() => {
			history.push("/api-access");
		}, 100);
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				name: data.fullName,
				locations: [],
				roles: []
			};
			if (data.groups) {
				const roles = data.groups.map((grp) => parseInt(grp.id));
				variables.roles = roles;
			}
			if (data.locations) {
				const locations = data.locations.map((loc) => parseInt(loc.id));
				variables.locations = locations;
			}
			const resp = await client.mutate({
				mutation: CREATE_API_ACCOUNT,
				variables
			});
			if (resp.data.saveApiUser.status.success) {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "API account created",
						timeout: 5000,
						error: false
					}
				});
				setConfirmLoading(false);
				history.push(`/api-access/edit/${resp.data.saveApiUser.object.id}`);
			} else {
				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveApiUser.status.messages));
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	return (
		<div className="api-account-create-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={"API account"}
				subTitle={"Create a new API account"}
				loading={confirmLoading}
				submitTitle="Create"
				disabled={!isFormTouched}
				hideActions={!isFormTouched}
			>
				<div className="form-content">
					<BasicInfo data={data} handleForm={handleForm} validations={error.fields || {}} createMode={true} />
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	listGroups: store.configItems.listGroups
}))(APIAccountCreate);
