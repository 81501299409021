import React from "react";
import { extractInitials } from "../../../atlas-utils";
import { getBrandDetail } from "../../../helpers/periscope";
import { connect } from "react-redux";

const BrandCell = ({ props, configItems }) => {
	const brands = configItems?.brands?.items;

	let displayImage = "";
	let ratingText = "";
	let displayName = "";
	const currentBrandDetails = getBrandDetail(props.value.id, brands);

	if (props?.value?.name) {
		const {
			value: { name, rating }
		} = props;
		displayName = currentBrandDetails?.name ? currentBrandDetails?.name : name;

		if (currentBrandDetails?.image.length > 0) {
			displayImage = currentBrandDetails?.image;
		}
		if (rating !== null && rating >= 0) {
			ratingText = (
				<span className="brand-text-location rating-text-location">
					{rating} <img src="/assets/periscope/star-rating.svg" className="star-image-location" />{" "}
				</span>
			);
		}
	}
	let classname = currentBrandDetails?.color;

	return (
		<div className="brand-cell">
			<span className={`image-info  logo ${displayImage ? "" : classname}`}>
				{displayImage ? (
					<img src={displayImage} className="brand-image-location  " />
				) : (
					<span className="brand-image-location">{extractInitials(displayName?.split(" "))}</span>
				)}
			</span>
			<span className="brand-text-location inside-text-location brand-text-width ">{displayName}</span>
			{ratingText}
		</div>
	);
};
const mapStateToProps = (state) => ({
	configItems: state.configItems
});
export default connect(mapStateToProps)(BrandCell);
