import React from "react";

const TripleDotIcon = () => (
	<svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.333984 0.5V3.83333H3.66732V0.5H0.333984ZM0.333984 6.33333V9.66667H3.66732V6.33333H0.333984ZM0.333984 12.1667V15.5H3.66732V12.1667H0.333984Z"
			fill="#A1A5B2"
		/>
	</svg>
);
export default React.memo(TripleDotIcon);
