import React, { useEffect, useRef } from "react";

// third party
import { connect } from "react-redux";

const MetricSection = ({
	metric = "",
	children,
	metricSectionApi,
	state = {},
	dependencies = [],
	analyticsFiltersState,
	classes = "",
	metricCardInViewport = false
}) => {
	const { appliedFilters, appliedDateFilter } = analyticsFiltersState;
	const firstRender = useRef(true);
	const cardTimerRef = useRef();

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		if (metricCardInViewport) {
			// clear previous timer
			clearTimeout(cardTimerRef.current);

			if (metricSectionApi && metric) {
				metricSectionApi(metric);
			}
		}
		return () => {
			clearTimeout(cardTimerRef.current);
		};
	}, [
		Object.keys(appliedFilters).reduce((acc, f) => acc + appliedFilters[f]?.join(""), ""),
		...Object.keys(appliedDateFilter).reduce((arr, f) => [...arr, appliedDateFilter[f].dateFilter], []),
		...dependencies
	]);

	useEffect(() => {
		if (metricCardInViewport) {
			// clear previous timer
			clearTimeout(cardTimerRef.current);

			if (metricSectionApi && metric) {
				cardTimerRef.current = setTimeout(() => {
					metricSectionApi(metric);
				}, 300);
			}
		} else {
			clearTimeout(cardTimerRef.current);
		}
	}, [metricCardInViewport]);

	if (!children) {
		return null;
	}
	return (
		<div className={"metric-section " + classes}>
			{children(state?.[metric] || {}, appliedDateFilter, appliedFilters)}
		</div>
	);
};
export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState
}))(MetricSection);
