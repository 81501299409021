import React from "react";

// third party
import moment from "moment";

// utils
import { printCurrency, setFloatingPrecision, generateReadableAddress, commifyNumbers } from "../../atlas-utils";

// constants
import { ORDER_BILL_COMPONENTS, STORE_TYPES } from "../../client-config";

export const Invoice = ({ biz, data = {}, storeType }) => {
	if (storeType === STORE_TYPES[1].type) {
		return <div className="invoice-pre-formatted">{data.billDump}</div>;
	}
	const firstName = data.customer && data.customer.firstName ? data.customer.firstName : "";
	const phone = data.customer && data.customer.phone ? data.customer.phone : "";
	const deliveryAddress = generateReadableAddress(data.address);
	const items = data.items || [];
	const { currencySymbol, logo } = biz;
	const logoHttps = logo ? "https://" + logo.split("://")[1] : null;
	return (
		<div className="invoice-container">
			<div className="top-banner">
				<div className="banner-bcg left" />
				<img className="banner-img" src={logoHttps} />
				<div className="banner-bcg right" />
			</div>
			<div className="invoice-title">RETAIL INVOICE</div>
			<div className="customer-merchant-info">
				<div className="merchant">
					<div className="name">{biz.name}</div>
					<div>Date: {moment(data.created).format("DD MMM, YYYY - hh:mm A")}</div>
					<div>Order No: {data.id}</div>
				</div>
				<div className="customer">
					<div className="customer-title">BUYER DETAILS:</div>
					<div>{firstName ? firstName : phone}</div>
					<div>
						<span>Phone:</span> {phone}
					</div>
					{deliveryAddress && (
						<div>
							<span>Address:</span> {deliveryAddress}
						</div>
					)}
				</div>
			</div>
			<div className="seperator" />
			<div className="order-details">
				<div className="title">ORDER DETAILS</div>
				<div className="order-item-row header">
					<div className="item-name">Item</div>
					<div className="item-price">Price</div>
					<div className="item-qty">Qty</div>
					<div className="item-total">Total</div>
				</div>
				{items.map((item, i) => (
					<InvoiceItem key={i} currencySymbol={currencySymbol} {...item} />
				))}
			</div>
			<div className="seperator" />
			<div className="bill-details">
				{ORDER_BILL_COMPONENTS.map((billC, i) => (
					<InvoiceBillItem key={i} data={data} currencySymbol={currencySymbol} {...billC} />
				))}
			</div>
			<div className="seperator" />
			<div className="bill-details">
				<div className="bill-item-row">
					<div className="bill-type">Total payable</div>
					<div className="bill-type-amount">
						{printCurrency(currencySymbol)}
						{setFloatingPrecision(commifyNumbers(data.payableAmount?.toFixed(2)))}
					</div>
				</div>
			</div>
		</div>
	);
};

const InvoiceItem = ({
	title,
	price,
	quantity,
	total,
	currencySymbol,
	optionsToAdd,
	totalTax,
	totalCharge,
	taxes,
	charges
}) => (
	<div className="order-item-row">
		<div className="item-name">
			<div className="main">{title}</div>
			{optionsToAdd.map((option, i) => (
				<div key={i} className="additions">
					{option.title}
				</div>
			))}
		</div>
		<div className="item-price">
			{printCurrency(currencySymbol)}
			{setFloatingPrecision(commifyNumbers(price.toFixed(2)))}
		</div>
		<div className="item-qty">{quantity}</div>
		<div className="item-total">
			<div className="main">
				{printCurrency(currencySymbol)}
				{setFloatingPrecision(commifyNumbers(total.toFixed(2)))}
			</div>
			{optionsToAdd.map((option, i) => (
				<div key={i} className="additions">
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers(option.price.toFixed(2)))}
				</div>
			))}
			{taxes.map((tax, i) => (
				<div className="additions" key={i}>
					<span>
						{tax.title}@{tax.rate}%:
					</span>
					&nbsp;&nbsp;
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers(tax.value.toFixed(2)))}
				</div>
			))}
			{charges.map((charge, i) => (
				<div className="additions" key={i}>
					<span>{charge.title}:</span>&nbsp;&nbsp;
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers(charge.value.toFixed(2)))}
				</div>
			))}
			{/* {
				(totalTax > 0) &&
				<div className="additions">
					<span>Taxes:</span>&nbsp;&nbsp;
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers((totalTax).toFixed(2)))}
				</div>
			}
			{
				(totalCharge > 0) &&
				<div className="additions">
					<span>Charges:</span>&nbsp;&nbsp;
					{printCurrency(currencySymbol)}
					{setFloatingPrecision(commifyNumbers((totalCharge).toFixed(2)))}
				</div>
			} */}
		</div>
	</div>
);

const InvoiceBillItem = ({ field, label, type, data, currencySymbol }) => {
	if (!data || !data[field]) {
		return null;
	}
	return (
		<div className="bill-item-row">
			<div className="bill-type">{label}</div>
			<div className="bill-type-amount">
				{type === "credit" ? "-" : ""}
				{printCurrency(currencySymbol)}
				{setFloatingPrecision(commifyNumbers(data[field].toFixed(2)))}
			</div>
		</div>
	);
};

export const invoiceStyle = `
<style>
	@page {
		margin: 0mm;
		size: auto;
	}
	body {
		margin: 0;
		font-size: 14px;
		font-family: sans-serif;
	}
	.invoice-pre-formatted {
		margin: 10px;
		white-space: pre;
	}
	.invoice-container .top-banner {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 5px 0;
	}
	.top-banner .banner-bcg {
		height: 5px;
		-webkit-print-color-adjust: exact;
	}
	.top-banner .banner-bcg.left {
		width: 80%;
		background: linear-gradient(to right, #e67e22 0%, #ff4251 100%);
	}
	.top-banner .banner-bcg.right {
		flex: 1 1 0;
		background: linear-gradient(to right, #ff4251 0%, #ff425c 100%);
	}
	.top-banner .banner-img {
		max-width: 80px;
		margin: 0 5px;
	}
	.invoice-container .invoice-title {
		font-size: 20px;
		padding: 5px 15px;
	}
	.invoice-container .customer-merchant-info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 10px 15px;
	}
	.customer-merchant-info .merchant {
		width: 40%;
	}
	.customer-merchant-info .merchant .name {
		font-size: 16px;
		font-weight: bold;
	}
	.customer-merchant-info .customer {
		width: 40%;
	}
	.customer-merchant-info .customer-title {
		font-size: 16px;
	}
	.invoice-container .seperator {
		border-top: 1px solid rgba(0, 0, 0, 0.4);
	}
	.invoice-container .order-details {
		padding: 10px 15px;
	}
	.order-details .title {
		font-size: 20px;
		margin: 10px 0;
	}
	.order-details .order-item-row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-bottom: 10px;
	}
	.order-item-row.header {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.7)
	}
	.order-item-row .item-name {
		width: 55%;
	}
	.order-item-row .main {
		font-size: 14px;
		margin-bottom: 5px;
	}
	.order-item-row .additions {
		font-size: 12px;
		color: #7b8a9a;
		margin-bottom: 5px;
	}
	.order-item-row .additions:last-child {
		margin-bottom: 0;
	}
	.order-item-row .item-price {
		width: 15%;
		text-align: right;
	}
	.order-item-row .item-qty {
		width: 15%;
		text-align: right;
	}
	.order-item-row .item-total {
		width: 15%;
		text-align: right;
	}
	.invoice-container .bill-details {
		padding: 5px 15px;
	}
	.bill-details .bill-item-row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-bottom: 5px;
	}
	.bill-item-row .bill-type {
		margin-left: 55%;
		width: 15%;
		text-align: right;
	}
	.bill-item-row .bill-type-amount {
		width: 30%;
		text-align: right;
	}
</style>
`;
