import React, { useState } from "react";

// components
import { SelectFilter } from "../_commons/SelectFilter";

// config
import {
	ORDER_STATUS_MAP,
	DELIVERY_STATUS_MAP,
	ORDER_STATUS_BACKGROUND_COLOR_MAP,
	ORDER_STATUS_TEXT_COLOR_MAP,
	ORDER_STATUS_BACKGROUND_COLOR_DEFAULT,
	ORDER_STATUS_TEXT_COLOR_DEFAULT,
	DELIVERY_STATUS_BACKGROUND_COLOR_MAP,
	DELIVERY_STATUS_BACKGROUND_COLOR_DEFAULT,
	DELIVERY_STATUS_TEXT_COLOR_MAP,
	DELIVERY_STATUS_TEXT_COLOR_DEFAULT
} from "../../client-config";

// utils
import TimelineTrail from "../_commons/TimelineTrail";

// third party
import { useTrail, animated } from "react-spring";

// constants
const FINAL_STATES = ["DECLINED_BY_DP", "COMPLETED", "CANCELLED", "DELIVERED", "NO_RIDER_FOUND"];
const STATUS_LOGS = [
	{ label: "Order status", value: "order" },
	{ label: "Delivery status", value: "delivery" }
];

export const StatusLogs = ({ data, userEmail = "", openSidebar, handleNestedEntity }) => {
	const [selectedStatusLogs, setStatusLogs] = useState(STATUS_LOGS[0]);
	const statusLogs = data && data.statusUpdates ? data.statusUpdates : [];
	const deliveryLogs = data && data.delivery && data.delivery.statusUpdates ? data.delivery.statusUpdates : [];
	const parent = data && data.parentOrder ? data.parentOrder : null;
	const child = data && data.childOrderId ? data.childOrderId : null;

	const config = { mass: 5, tension: 2000, friction: 500 };
	const trailOrderItems = [
		...statusLogs.map((status, i) => (
			<TimelineTrail
				id={status.id}
				status={status}
				parent={parent}
				child={child}
				handleNestedEntity={handleNestedEntity}
				title={
					ORDER_STATUS_MAP[
						child && status.status === "CANCELLED" ? "CANCELLED_FOR_MODIFICATION" : status.status
					] || status.status
				}
				headNodeBackgroundColorLight={
					ORDER_STATUS_BACKGROUND_COLOR_MAP[
						child && status.status === "CANCELLED" ? "CANCELLED_FOR_MODIFICATION" : status.status
					] ?? ORDER_STATUS_BACKGROUND_COLOR_DEFAULT
				}
				headNodeBackgroundColorDark={
					ORDER_STATUS_TEXT_COLOR_MAP[
						child && status.status === "CANCELLED" ? "CANCELLED_FOR_MODIFICATION" : status.status
					] ?? ORDER_STATUS_TEXT_COLOR_DEFAULT
				}
				isFinal={FINAL_STATES.indexOf(status.status) >= 0}
				openStatusUpdate={openSidebar}
				clickable={userEmail.includes("@urbanpiper.com")}
				isLatest={i === statusLogs.length - 1}
			/>
		))
	];
	const trailDeliveryItems = [
		...deliveryLogs.map((log, i) => {
			return (
				<TimelineTrail
					key={i}
					headNodeBackgroundColorLight={
						DELIVERY_STATUS_BACKGROUND_COLOR_MAP[log.state] ?? DELIVERY_STATUS_BACKGROUND_COLOR_DEFAULT
					}
					headNodeBackgroundColorDark={
						DELIVERY_STATUS_TEXT_COLOR_MAP[log.state] ?? DELIVERY_STATUS_TEXT_COLOR_DEFAULT
					}
					title={DELIVERY_STATUS_MAP[log.state] || log.state}
					isFinal={FINAL_STATES.indexOf(log.state) >= 0}
					status={log}
					isLatest={i === deliveryLogs.length - 1}
				/>
			);
		})
	];

	const trailOrders = useTrail(trailOrderItems.length, {
		config,
		from: { opacity: 0, height: 0 },
		to: { opacity: selectedStatusLogs.value === "order" ? 1 : 0, height: "auto" }
	});
	const trailDelivery = useTrail(trailDeliveryItems.length, {
		config,
		from: { opacity: 0, height: 0 },
		to: { opacity: selectedStatusLogs.value === "delivery" ? 1 : 0, height: "auto" }
	});

	return (
		<div className="transaction-details-status">
			<div className="form-row row-half">
				<SelectFilter
					options={STATUS_LOGS}
					field="logs"
					currValue={selectedStatusLogs}
					setFilter={(field, value) => setStatusLogs(value)}
					isClearable={false}
					isSearchable={false}
				/>
			</div>
			{selectedStatusLogs.value === "order" &&
				trailOrders.map((styleProps, i) => (
					<animated.div
						key={i}
						style={{
							...styleProps
						}}
					>
						{trailOrderItems[i]}
					</animated.div>
				))}
			{selectedStatusLogs.value === "delivery" &&
				trailDelivery.map((styleProps, i) => (
					<animated.div
						key={i}
						style={{
							...styleProps
						}}
					>
						{trailDeliveryItems[i]}
					</animated.div>
				))}
		</div>
	);
};
