import React from "react";

function ViewIconSVG() {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="base-svg"
		>
			<path
				d="M0.666016 8.78121C0.666016 8.78121 3.33268 3.44788 7.99935 3.44788C12.666 3.44788 15.3327 8.78121 15.3327 8.78121C15.3327 8.78121 12.666 14.1145 7.99935 14.1145C3.33268 14.1145 0.666016 8.78121 0.666016 8.78121Z"
				fillOpacity={0}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8 10.7812C9.10457 10.7812 10 9.88582 10 8.78125C10 7.67668 9.10457 6.78125 8 6.78125C6.89543 6.78125 6 7.67668 6 8.78125C6 9.88582 6.89543 10.7812 8 10.7812Z"
				fillOpacity={0}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default React.memo(ViewIconSVG);
