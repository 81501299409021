import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { Button } from "../_commons/Button";
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { DateFilterDropdown } from "../_commons/DateFilterDropdown";

// third party
import moment from "moment";
import { connect } from "react-redux";

// store
import { store } from "../../store/configureStore";

// utils
import { lS, trackEvent } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";

// constant
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { PRESET_TYPES, DATE_TYPES, CUSTOM_TYPES, COMPARE_DATE_TYPES } from "../_commons/NewDateCompareFilter";
const DATE_INIT_STATE = {
	current: {
		dateFilter: PRESET_TYPES[8].value,
		dateTypeSelected: DATE_TYPES[1],
		presetTypeSelected: PRESET_TYPES[8],
		customTypeSelected: CUSTOM_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};

const ExportReport = ({ isOpen = false, close, availablePlatforms, selectedPlatform = null, bizId }) => {
	const [data, setData] = useState({
		emails: lS.get("report_emails") ? lS.get("report_emails")[bizId] || [] : [],
		platform: {
			...selectedPlatform,
			icon: CATALOGUE_PLATFORMS_LOGO[selectedPlatform.value.toLowerCase()] || "/assets/icons/icons8-globe-40.png"
		}
	});
	const [currEmail, setCurrEmail] = useState("");
	const [currDate, setCurrDate] = useState(DATE_INIT_STATE);
	const [applDate, setApplDate] = useState(DATE_INIT_STATE);
	const [validations, setValidations] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isReportSuccess, setReportSuccess] = useState(false);
	const tagsRef = useRef();

	useEffect(() => {
		if (isOpen) {
			setData({
				...data,
				emails: lS.get("report_emails") ? lS.get("report_emails")[bizId] || [] : [],
				platform: {
					...selectedPlatform,
					icon:
						CATALOGUE_PLATFORMS_LOGO[selectedPlatform.value.toLowerCase()] ||
						"/assets/icons/icons8-globe-40.png"
				}
			});
		}
	}, [selectedPlatform, isOpen]);

	const handleReset = () => {
		setCurrDate(DATE_INIT_STATE);
		setApplDate(DATE_INIT_STATE);
		setData({
			...data,
			emails: []
		});
		setCurrEmail("");
		setLoading(false);
		setFormTouched(false);
		setReportSuccess(false);
	};

	const handleClose = () => {
		handleReset();
		close();
	};

	const setDateRange = useCallback(
		(whichDate, date) => {
			const { rangeEndDate } = currDate?.current;
			if (whichDate === "rangeStartDate" && rangeEndDate && rangeEndDate.diff(date, "d") > 30) {
				currDate.current.rangeEndDate = date.clone().add(30, "d");
			}
			setCurrDate({
				...currDate,
				current: {
					...currDate?.current,
					[whichDate]: date
				}
			});
		},
		[currDate]
	);

	const updateDateRange = useCallback(
		(reset = false) => {
			if (currDate?.current?.rangeStartDate && currDate?.current?.rangeEndDate && !reset) {
				const start = currDate?.current?.rangeStartDate.format("YYYY-MM-DD");
				const end = currDate?.current?.rangeEndDate.format("YYYY-MM-DD");
				const dateFilter = `${start},${end}`;
				setCurrDate({
					...currDate,
					current: {
						...currDate?.current,
						dateTypeSelected: DATE_TYPES[1],
						dateFilter
					}
				});
			} else if (
				currDate?.current?.customTypeSelected?.label &&
				(currDate?.current?.rangeStartDate || currDate?.current?.rangeEndDate) &&
				!reset
			) {
				const start = currDate?.current?.rangeStartDate
					? currDate?.current?.rangeStartDate.format("YYYY-MM-DD")
					: "";
				const end = currDate?.current?.rangeEndDate ? currDate?.current?.rangeEndDate.format("YYYY-MM-DD") : "";
				const dateFilter = `${start},${end}`;
				setCurrDate({
					...currDate,
					current: {
						...currDate?.current,
						dateTypeSelected: DATE_TYPES[1],
						dateFilter
					}
				});
			} else {
				const rangeStartDate = moment().subtract(30, "d");
				const rangeEndDate = moment();
				const start = rangeStartDate.format("YYYY-MM-DD");
				const end = rangeEndDate.format("YYYY-MM-DD");
				const dateFilter = `${start},${end}`;
				const current = {
					...currDate?.current,
					dateTypeSelected: DATE_TYPES[1],
					rangeStartDate,
					rangeEndDate,
					dateFilter
				};
				setCurrDate({
					...currDate,
					current: {
						...current
					}
				});
			}
		},
		[currDate]
	);

	const closeDateFilter = useCallback(() => {
		setTimeout(() => {
			setCurrDate({
				...applDate
			});
		}, 100);
	}, [applDate]);

	const applyDateRange = useCallback(
		(dateTypeSelected) => {
			const updatedCurrentDateFilter = {
				...currDate,
				current: {
					...currDate?.current,
					dateTypeSelected
				}
			};
			setCurrDate({
				...updatedCurrentDateFilter
			});
			setApplDate({
				...updatedCurrentDateFilter
			});
			setValidations({
				...validations,
				dateRange: ""
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		},
		[currDate, applDate]
	);

	const resetDateFilter = useCallback(() => {
		updateDateRange(true);
	}, [updateDateRange]);

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (field === "emails") {
			lS.set("report_emails", { ...lS.get("report_emails"), [bizId]: [...value] });
		}
		setValidations({
			...validations,
			[field]: ""
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCurrEmail = (val) => {
		setCurrEmail(val);
		setValidations({
			...validations,
			currEmail: ""
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleValidations = () => {
		let isValidated = true;
		const validations = {};
		if (data.emails.length === 0) {
			isValidated = false;
			validations.currEmail = "Please enter a valid email";
		}
		if (!data.platform) {
			isValidated = false;
			validations.platform = "Please select a platform";
		}
		if (!applDate.current.rangeStartDate || !applDate.current.rangeEndDate) {
			isValidated = false;
			validations.dateRange = "Please select a date range";
		}
		setValidations(validations);
		return isValidated;
	};

	const handleExportReport = async () => {
		const isValidated = handleValidations();
		if (!isValidated) {
			return;
		}
		const variables = {
			start_date: applDate.current.rangeStartDate.format("YYYY-MM-DD"),
			end_date: applDate.current.rangeEndDate.format("YYYY-MM-DD"),
			platform: data.platform.valueForDisplay,
			emails: data.emails
		};
		const token = store.getState().login.loginDetail.token;
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		};
		setLoading(true);
		try {
			const resp = await fetch(process.env.REACT_APP_RECON_CONSOLIDATED_REPORT, {
				method: "post",
				body: JSON.stringify(variables),
				headers
			});
			const parsedResp = await resp.json();
			if (parsedResp?.reference_id && parsedResp?.message === "Consilidated report is queued for generation") {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: `Report requested successfully`,
						timeout: 3000,
						error: false
					}
				});
				setReportSuccess(true);
				setFormTouched(false);
				// track event
				trackEvent("reconciliation_consolidated_report_download", {
					platform: variables.platform,
					duration: applDate?.current?.rangeEndDate?.diff(applDate?.current?.rangeStartDate, "days") + 1
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 2000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	return (
		<div className="export-recon-reports">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				loading={loading}
				title="Export Reconciliation Report"
				subTitle="Request reports up to 31 days on selected platform for all locations"
				submitTitle="Export Reports"
				submit={handleExportReport}
				hideActions={!isFormTouched}
			>
				{!isReportSuccess && (
					<div className="form-content">
						<div className="form-row row-half">
							<DateFilterDropdown
								title="Select Date Range"
								field="timeDaily"
								currValue={currDate.current}
								requiredLabel={true}
								customLabel={
									applDate?.current?.rangeStartDate && applDate?.current?.rangeEndDate ? (
										<React.Fragment>
											<div
												className="custom-date-time-label"
												title={`${applDate?.current?.rangeEndDate.diff(
													applDate?.current?.rangeStartDate,
													"d"
												)} days`}
											>
												{moment(applDate?.current?.rangeStartDate).format("DD MMM, YYYY")}{" "}
												<span>—</span>{" "}
												{moment(applDate?.current?.rangeEndDate).format("DD MMM, YYYY")}
											</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div className="placeholder">DD MMM, YYYY — DD MMM, YYYY</div>
										</React.Fragment>
									)
								}
								setFilter={setDateRange}
								setDateRange={updateDateRange}
								closeDateFilter={closeDateFilter}
								applyDateRange={() => applyDateRange(DATE_TYPES[1])}
								resetDateFilter={resetDateFilter}
								range={true}
								classes="date-range"
								maxDate={
									currDate?.current?.rangeStartDate
										? moment(currDate?.current?.rangeStartDate)
												.add(30, "days")
												.diff(moment(), "days") < 0
											? moment(currDate?.current?.rangeStartDate).add(30, "days")
											: moment()
										: moment()
								}
								validationMessage={validations.dateRange || ""}
							/>
							<SelectFilterCustom
								title="Select Platform"
								options={availablePlatforms?.map((plf) => ({
									...plf,
									icon:
										CATALOGUE_PLATFORMS_LOGO[plf?.value?.toLowerCase()] ||
										"/assets/icons/icons8-globe-40.png"
								}))}
								field="platform"
								currValue={data.platform}
								setFilter={handleForm}
								labelKey="valueForDisplay"
								valueKey="value"
								requiredLabel={true}
								showIcon={true}
								isSearchable={false}
								placeholder="Select platform"
								validationMessage={validations.platform || ""}
							/>
						</div>
						<div className="form-row row-full">
							<TagsInputWrapper
								ref={tagsRef}
								tags={data.emails}
								onChange={(tags) => handleForm("emails", tags)}
								tagInput={currEmail}
								onChangeInput={handleCurrEmail}
								requiredLabel={true}
								placeholder={"Enter an email"}
								validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
								onValidationReject={() =>
									setValidations({
										...validations,
										currEmail: "Please enter a valid email"
									})
								}
								validationMessage={validations.currEmail || ""}
								showDropdown={true}
							>
								Recipient Emails
							</TagsInputWrapper>
						</div>
					</div>
				)}
				{isReportSuccess && (
					<div className="report-success">
						<div className="illustration">
							<img src="/assets/empty-states/recon-report.svg" alt="" />
						</div>
						<div className="message">Report Requested Successfully</div>
						<div className="description">
							You will receive an email in sometime on <span>{data.email}</span>
						</div>
						<Button clickHandler={handleReset}>Export Another Report</Button>
						<div className="link-text" onClick={handleClose}>
							Dismiss
						</div>
					</div>
				)}
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	bizId: store?.login?.loggedInbizDetail?.id
});
export default connect(mapStateToProps)(ExportReport);
