import React from "react";
import TagsInput from "react-tagsinput";

// third party
import { Transition, config, animated } from "react-spring/renderprops";

export class TagsInputWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
		this.tagsInputRef = null;
		this.setTagsInputRef = (elem) => {
			this.tagsInputRef = elem;
		};
	}

	handleOnChangeInput = (val) => {
		if (val) {
			this.setState({ isOpen: true });
		} else {
			this.setState({ isOpen: false });
		}
		this.props.onChangeInput(val);
	};

	handleAddTag = () => {
		if (this.tagsInputRef) {
			this.tagsInputRef.accept();
		}
	};

	render() {
		const {
			children,
			requiredLabel = false,
			validationMessage,
			showDropdown = false,
			action = "Add",
			...props
		} = this.props;
		const { isOpen } = this.state;
		return (
			<div className={"at-input-container " + (props.classes || "")}>
				{children && (
					<label className={"at-input-label" + (requiredLabel ? " required-sign" : "")}>{children}</label>
				)}
				<div className={"tags-input-container" + (props.readOnly ? " read-only" : "")}>
					<TagsInput
						value={props.tags.filter((x) => x !== "")}
						onChange={props.onChange}
						validationRegex={props.validationRegex}
						onValidationReject={props.onValidationReject}
						inputProps={{
							placeholder: props.placeholder,
							onFocus: () => this.setState({ isOpen: props.tagInput ? true : false }),
							onBlur: () => setTimeout(() => this.setState({ isOpen: false }), 150)
						}}
						inputValue={props.tagInput}
						onChangeInput={this.handleOnChangeInput}
						ref={this.setTagsInputRef}
					/>
				</div>
				{validationMessage ? <div className="validation-message">{validationMessage}</div> : null}
				{showDropdown && (
					<Transition
						native
						items={isOpen}
						from={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
						enter={{ opacity: 1, transform: "perspective(800px) rotateX(0deg)", transformOrigin: "top" }}
						leave={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
						config={config.stiff}
					>
						{(isOpen) =>
							isOpen &&
							((style) => (
								<animated.div style={style} className="dropdown-component">
									<div className="option" onClick={this.handleAddTag}>
										{`${action} "${props.tagInput}"`}
									</div>
								</animated.div>
							))
						}
					</Transition>
				)}
			</div>
		);
	}
}
