import React, { Component } from "react";

// components
import { Header } from "../components/ChargesList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchChargesList } from "../actions/charges";
import { fetchItemsDebounced, fetchStoresDebounced } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";
import history from "../history";

// constant
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES } from "../client-config";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Name",
		field: "name",
		sortKey: "title",
		render: (record, i, archived) => (
			<div className={"at-table-cell at-cell-text name"} title={record.title} key={i}>
				<div className="name-desc">
					<Link className={"link-text " + archived} to={`/charges/edit/${record.id}`}>
						<span className="hyperlink hyperlink--black-color">{record.title || record.id}</span>
					</Link>
					<div className="text--light desc-text" title={record.description}>
						{(record.description && record.description.length > 50
							? record.description.slice(0, 50) + "..."
							: record.description) || "--"}
					</div>
				</div>
			</div>
		)
	},
	{
		name: "Applicable On",
		field: "applicable-on",
		sortKey: "applicable_on",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text applicable-on"} key={i}>
				{record.applicableOn || "--"}
			</div>
		)
	},
	{
		name: "POS ID",
		field: "merchant-ref-id",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text merchant-ref-id"} key={i}>
				{record.merchantRefId && Number(record.merchantRefId) !== -1 ? record.merchantRefId : "--"}
			</div>
		)
	},
	{
		name: "Items",
		field: "items",
		sortKey: "items_count",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text items"} key={i}>
				{record.itemsCount | 0}
			</div>
		)
	},
	{
		name: "Locations",
		field: "locations",
		sortKey: "locations_count",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text locations"} key={i}>
				{record.locationsCount | 0}
			</div>
		)
	}
];

@connect((store) => ({
	chargesList: store.chargesList,
	chargesListState: store.chargesListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class ChargesList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			title: "",
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "charges_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.chargesListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Charges list
		await fetchChargesList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });

		// the below code preloads the items and locations dropdown
		const { configItems } = this.props;
		if (!configItems.items.items.length) {
			fetchItemsDebounced("");
		}
		if (!configItems.stores.items.length) {
			fetchStoresDebounced("");
		}
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "charges"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatechargesListState = (payload) => {
		store.dispatch({
			type: ActionTypes.CHARGES_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatechargesListState({
			currentFilters: this.props.chargesListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.chargesListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatechargesListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.chargesListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updatechargesListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	updateItemsLookup = (id, title) => {
		this.setState({
			itemsLookup: {
				...this.state.itemsLookup,
				[id]: title
			}
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatechargesListState({
			appliedFilters: {
				...this.props.chargesListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "charges_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchChargesList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().chargesListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updatechargesListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchChargesList();
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.chargesListState;
		const offset = (page - 1) * limit;
		this.updatechargesListState({
			offset
		});
		// fetch new Charges list
		fetchChargesList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.chargesListState;
		if (size && size?.value !== limit) {
			this.updatechargesListState({
				[field]: size.value
			});
			// fetch new Charges list
			await fetchChargesList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatechargesListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.CHARGES_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchChargesList();
	};

	updateItemsLookup = (id, title) => {
		store.dispatch({
			type: ActionTypes.CHARGES_LIST_UPDATE_ITEMS_LOOKUP,
			payload: {
				...this.props.chargesListState?.itemsLookup,
				[id]: title
			}
		});
	};

	updateStoresLookup = (id, name) => {
		store.dispatch({
			type: ActionTypes.CHARGES_LIST_UPDATE_STORES_LOOKUP,
			payload: {
				...this.props.chargesListState?.storesLookup,
				[id]: name
			}
		});
	};

	render() {
		const { chargesList, chargesListState, configItems, access } = this.props;
		const { limit, offset, currentFilters, sortedField } = chargesListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (f === "is_active" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f]?.value && currentFilters[f]?.value !== "") {
					filterCount++;
				}
			}
		}

		const filterOptions = chargesList.data.filters
			.filter((f) => f.field !== "title")
			.map((f) => {
				if (f.field === "items") {
					f = {
						...f,
						isAsync: true,
						asyncOptions: this.props.configItems.items,
						asyncLookup: this.props.chargesListState?.itemsLookup,
						updateAsyncLookup: this.updateItemsLookup,
						handleAsyncSearch: fetchItemsDebounced,
						labelKey: "title",
						valueKey: "id"
					};
				}
				if (f.field === "locations") {
					f = {
						...f,
						isAsync: true,
						asyncOptions: this.props.configItems.stores,
						asyncLookup: this.props.chargesListState?.storesLookup,
						updateAsyncLookup: this.updateStoresLookup,
						handleAsyncSearch: fetchStoresDebounced,
						labelKey: "name",
						valueKey: "id"
					};
				}
				return f;
			});

		const searchFilterOption = chargesList.data.filters.find((fl) => fl.field === "title");

		const placeholderContent = {
			placeholderText: "No charge rates added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-taxes.svg",
			placeholderSubtext:
				"Manage all charges collected at the time of sale to ensure all your services are priced correctly",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add a new Charge</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/charges/new");
			},
			redirectionLink: "/piper-academy/charges",
			redirectionLinkText: "learn more about Charges",
			size: "medium"
		};

		return (
			<div className="charges-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={filterOptions}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
					/>
				)}
				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
					filterOption={searchFilterOption}
					setFilter={this.setSearchFilter}
					value={this.state.title}
					handlePiperAcademy={this.handlePiperAcademy}
					isCatalogueManagement={access.isCatalogueManagement}
				/>
				<CommonTable
					loading={chargesList.loading}
					data={chargesList.data.objects || []}
					columns={columns}
					sortList={this.sortList}
					sortedField={sortedField}
					archived={this.state.archivedFilter ? "archived" : ""}
					classes="charges-list-table-container"
					content="Charges"
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={chargesList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		);
	}
}
