import gql from "graphql-tag";

export const OPERATIONAL_EFFICIENCY_METRICS = gql`
	query getOperationalEfficencyMetrics(
		$duration: Duration
		$comparisonDuration: CustomDateDuration
		$filters: [ListFilterArgument]
		$limit: Int!
		$offset: Int!
		$brand: String
	) {
		orderStatusMetrics {
			bizLevel(duration: $duration, comparisonDuration: $comparisonDuration, filters: $filters, brand: $brand) {
				current {
					percentageCancelled
					percentageFoodReady
					percentageDispatched
					percentageAcknowledged
					averageFoodReady
					averageDispatched
					averageAcknowledged
					averageCancelled
				}
				previous {
					percentageCancelled
					percentageFoodReady
					percentageDispatched
					percentageAcknowledged
					averageFoodReady
					averageDispatched
					averageAcknowledged
					averageCancelled
				}
			}
			bizLocationLevel(
				limit: $limit
				offset: $offset
				duration: $duration
				comparisonDuration: $comparisonDuration
				filters: $filters
				brand: $brand
			) {
				count
				rows {
					current {
						percentageCancelled
						percentageFoodReady
						percentageDispatched
						percentageAcknowledged
						averageFoodReady
						averageDispatched
						averageAcknowledged
						averageCancelled
						outlet
						bizLocationId
					}
					previous {
						percentageCancelled
						percentageFoodReady
						percentageDispatched
						percentageAcknowledged
						averageFoodReady
						averageDispatched
						averageAcknowledged
						averageCancelled
						outlet
						bizLocationId
					}
				}
			}
			filters {
				field
				valueForDisplay
				values {
					valueForDisplay
					value
				}
				type
			}
		}
	}
`;

export const OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL = gql`
	query getOperationalEfficencyMetricsLocationLevel(
		$duration: Duration
		$comparisonDuration: CustomDateDuration
		$filters: [ListFilterArgument]
		$limit: Int!
		$offset: Int!
		$sortKey: String
		$search: String
		$brand: String
	) {
		orderStatusMetrics {
			bizLocationLevel(
				limit: $limit
				offset: $offset
				duration: $duration
				comparisonDuration: $comparisonDuration
				filters: $filters
				sortKey: $sortKey
				search: $search
				brand: $brand
			) {
				count
				rows {
					current {
						percentageCancelled
						percentageFoodReady
						percentageDispatched
						percentageAcknowledged
						averageFoodReady
						averageDispatched
						averageAcknowledged
						averageCancelled
						outlet
						bizLocationId
					}
					previous {
						percentageCancelled
						percentageFoodReady
						percentageDispatched
						percentageAcknowledged
						averageFoodReady
						averageDispatched
						averageAcknowledged
						averageCancelled
						outlet
						bizLocationId
					}
				}
			}
		}
	}
`;

export const OPERATIONAL_EFFICIENCY_LIST_VIEW = gql`
	query getOperationalEfficencyOrders(
		$limit: Int!
		$offset: Int!
		$bizLocationId: Int!
		$duration: Duration
		$filters: [ListFilterArgument]
		$sortKey: String!
	) {
		orderStatusList {
			orders(
				duration: $duration
				limit: $limit
				offset: $offset
				filters: $filters
				bizLocation: $bizLocationId
				sortKey: $sortKey
			) {
				orderChannel
				orderId
				platformOrderId
				outlet
				city
				placed
				acknowledged
				foodReady
				dispatched
				completed
			}
			totalCount(duration: $duration, filters: $filters, bizLocation: $bizLocationId)
		}
	}
`;
