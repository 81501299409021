import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import _ from "lodash";
import moment from "moment";

// components
import OverlaySidebar from "./OverlaySidebar";
import { Button } from "./Button";

// graphql
import { CREATE_CREDITS_TXN, INIT_PAYMENT, PAYMENT_CAPTURE } from "../../graphql/payment";

// clients
import { store } from "../../store/configureStore";
import { client } from "../../client";

// actions
import { getAndSetBizInfo } from "../../actions/updateBizInfo";
import { ActionTypes } from "../../actions/_types";

// utils
import {
	commifyNumbers,
	getSuffixedNumber,
	printCurrency,
	msaagesArrayToHtml,
	setFloatingPrecision
} from "../../atlas-utils";

// constants
const CREDIT_INPUT_SUGGESTIONS = [
	{
		value: 10000,
		valueForDisplay: "10,000",
		amountPerCredits: 0.3,
		discountAmountPerCredits: 0.3,
		get totalAmount() {
			return this.value * this.amountPerCredits;
		},
		get discountedTotalAmount() {
			return (this.value * this.discountAmountPerCredits).toFixed(2);
		},
		get isDiscountApplicable() {
			return this.discountAmountPerCredits < this.amountPerCredits;
		}
	},
	{
		value: 20000,
		valueForDisplay: "20,000",
		amountPerCredits: 0.3,
		discountAmountPerCredits: 0.3,
		get totalAmount() {
			return this.value * this.amountPerCredits;
		},
		get discountedTotalAmount() {
			return (this.value * this.discountAmountPerCredits).toFixed(2);
		},
		get isDiscountApplicable() {
			return this.discountAmountPerCredits < this.amountPerCredits;
		}
	},
	{
		value: 50000,
		valueForDisplay: "50,000",
		amountPerCredits: 0.3,
		discountAmountPerCredits: 0.3,
		get totalAmount() {
			return this.value * this.amountPerCredits;
		},
		get discountedTotalAmount() {
			return (this.value * this.discountAmountPerCredits).toFixed(2);
		},
		get isDiscountApplicable() {
			return this.discountAmountPerCredits < this.amountPerCredits;
		}
	},
	{
		value: 500000,
		valueForDisplay: "500,000",
		amountPerCredits: 0.3,
		discountAmountPerCredits: 0.3,
		get totalAmount() {
			return this.value * this.amountPerCredits;
		},
		get discountedTotalAmount() {
			return (this.value * this.discountAmountPerCredits).toFixed(2);
		},
		get isDiscountApplicable() {
			return this.discountAmountPerCredits < this.amountPerCredits;
		}
	}
];

@connect((store) => ({
	creditsState: store.creditsState,
	biz: store.login.loggedInbizDetail,
	creditsCost: store.login.creditsCost,
	allCreditsCost: store.login.allCreditsCost,
	userInfo: store.login.loginDetail,
	dimensions: store.configItems.dimensions
}))
export class BuyCreditsFlow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			transactionInProgress: true,
			creditsLoading: false,
			creditsToBuy: 10000,
			creditInputSuggestions: CREDIT_INPUT_SUGGESTIONS
		};
	}

	componentDidMount() {
		this.preProcessPayment(this.state.creditsToBuy);
		this.initializeCreditInputSuggestions();
		if (this.props?.renderRechargeDrawer && !this.props.creditsState.isRechargeDrawerRenderedInitially) {
			setTimeout(() => {
				store.dispatch({
					type: "CREDITS_STATE_UPDATE",
					payload: {
						showBuyCreditsFlow: true,
						isRechargeDrawerRenderedInitially: true
					}
				});
			}, 500);
		}
	}

	initializeCreditInputSuggestions = () => {
		const allCreditsCost = this.props.allCreditsCost;
		const amountPerCredits = this.props.creditsCost.amountPerCredits;
		const creditInputSuggestions = this.state.creditInputSuggestions.map((suggestion) => {
			const value = suggestion.value;
			suggestion.amountPerCredits = amountPerCredits;
			suggestion.discountAmountPerCredits = amountPerCredits;

			for (let item = 0; item < allCreditsCost?.length; item++) {
				if (allCreditsCost[item].tier.lowerBound <= value && allCreditsCost[item].tier.upperBound >= value) {
					suggestion.discountAmountPerCredits = allCreditsCost[item].amountPerCredits;
					break;
				}
			}

			return suggestion;
		});
		this.setState({
			creditInputSuggestions
		});
	};

	changeCreditsToBuy = (e) => {
		let credits = parseInt(e.target.value);
		if (credits && credits > 0) {
			this.setState({
				creditsToBuy: credits
			});

			// dispatching this action here directly because
			// doing it in preProcessPayment method can make it seem
			// slow due to debounce of 500ms
			store.dispatch({
				type: "CREDITS_PRE_PROCESS_REQUEST"
			});

			this.preProcessPayment(credits);
		} else {
			this.setState({
				creditsToBuy: ""
			});
		}
	};

	preProcessPaymentTemp = async (credits) => {
		store.dispatch({
			type: "CREDITS_PRE_PROCESS_REQUEST"
		});
		try {
			const resp = await client.mutate({
				mutation: CREATE_CREDITS_TXN,
				variables: {
					credits,
					preProcess: true,
					provisional: false
				}
			});
			if (resp.data.createCreditsTxn.status.success) {
				store.dispatch({
					type: "CREDITS_PRE_PROCESS_SUCCESS",
					payload: {
						preProcess: resp.data.createCreditsTxn
					}
				});
			} else {
				store.dispatch({
					type: "CREDITS_PRE_PROCESS_FAILURE"
				});
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.createCreditsTxn.status.messages[0].message || "Something went wrong",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (err) {
			console.log(err);
			store.dispatch({
				type: "CREDITS_PRE_PROCESS_FAILURE"
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: err.message || "Something went wrong",
					timeout: 5000,
					error: true,
					errObject: err
				}
			});
		}
	};

	preProcessPayment = _.debounce((credits) => this.preProcessPaymentTemp(credits), 500);

	handlePaymentSuccess = async (txnId, gatewayTxnId) => {
		try {
			let resp = await client.mutate({
				mutation: PAYMENT_CAPTURE,
				variables: {
					txnId: txnId,
					gatewayTxnId: gatewayTxnId
				}
			});
			if (resp.data.paymentGatewayCallback.status.success) {
				// track this event
				// PubSub.publish(TRACK_EVENT, {
				// 	tracker: 'mixpanel',
				// 	eventName: 'credit_purchase_complete',
				// 	eventMeta: {
				// 		amount: this.state.creditsToBuy,
				// 		source: this.props.creditsState.source,
				// 	}
				// });

				// payment capture is successful, update the amount of credits left in users account
				try {
					await getAndSetBizInfo(true);
				} catch (error) {
					// payment was captured succesfully but users account is not updated in localStorage
					// se we reload the page forcefully to update biz info
					console.log(error);
					window.location.reload();
				}

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Reload successful!",
						timeout: 5000,
						error: false
					}
				});

				store.dispatch({
					type: "CREDITS_STATE_UPDATE",
					payload: {
						showBuyCreditsFlow: false
					}
				});

				this.setState({
					creditsLoading: false
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.paymentGatewayCallback.status.messages),
						timeout: 5000,
						error: true
					}
				});
				this.setState({
					creditsLoading: false
				});
			}
		} catch (error) {
			alert("Something went wrong, try again!");
			console.log(error);
			this.setState({
				creditsLoading: false
			});
		}
	};

	initPayment = async () => {
		const { creditsToBuy } = this.state;
		const { biz, userInfo } = this.props;
		const { netAmount } = this.props.creditsState.preProcess;
		const currentTimeStamp = moment().unix();
		const comments = `Topup of ${creditsToBuy} credits by ${
			userInfo.email || userInfo.phone
		} at ${currentTimeStamp}`;
		let payable = parseInt(netAmount * 100);

		this.setState({
			creditsLoading: true
		});

		try {
			let resp = await client.mutate({
				mutation: INIT_PAYMENT,
				variables: {
					amount: payable,
					purpose: "biz_credits_reload",
					comments: comments,
					gateway: "razorpay",
					currency: biz.billingCurrency
				}
			});

			let key = resp.data.initiatePaymentTxn.gwData.key;
			let txnId = resp.data.initiatePaymentTxn.paymentTxn.txnId;

			// send the transaction id to create an invoice for this transaction
			await client.mutate({
				mutation: CREATE_CREDITS_TXN,
				variables: {
					credits: creditsToBuy,
					txnId,
					preProcess: false,
					provisional: true
				}
			});

			// start payment gateway flow
			var rzp1 = new window.Razorpay({
				key: key,
				amount: payable,
				name: "UrbanPiper",
				currency: biz.billingCurrency,
				notes: {
					srvr_trx_id: txnId,
					biz_name: biz.name
				},
				handler: (response) => {
					this.handlePaymentSuccess(txnId, response.razorpay_payment_id);
				},
				theme: {
					color: "#2f58f2"
				},
				image: "/assets/up-logo.png",
				prefill: {
					email: this.props.userInfo.email
				},
				modal: {
					ondismiss: () => {
						this.setState({
							creditsLoading: false
						});
					}
				}
			});
			rzp1.open();
		} catch (err) {
			console.log(err);
			this.setState({
				creditsLoading: false
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: err.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: err
				}
			});
		}
	};

	closeSidebar = () => {
		if (!this.state.creditsLoading) {
			store.dispatch({
				type: "CREDITS_STATE_UPDATE",
				payload: {
					showBuyCreditsFlow: false
				}
			});
		}
	};

	selectCreditsToBuy = (amount) => {
		this.setState(
			{
				creditsToBuy: amount
			},
			() => {
				// dispatching this action here directly because
				// doing it in preProcessPayment method can make it seem
				// slow due to debounce of 500ms
				store.dispatch({
					type: "CREDITS_PRE_PROCESS_REQUEST"
				});
				this.preProcessPayment(this.state.creditsToBuy);
			}
		);
	};

	render() {
		const { dimensions, biz } = this.props;
		const { showBuyCreditsFlow, isPreProcessing, preProcess } = this.props.creditsState;
		const { credits, billingCurrencySymbol } = this.props.biz;
		const { creditsLoading, creditsToBuy, creditInputSuggestions } = this.state;
		const amountPerCredit = this.props.creditsCost.amountPerCredits;
		const totalSavings = creditsToBuy * amountPerCredit - preProcess.grossAmount;
		const isDiscountApplicable = preProcess.unitPrice < amountPerCredit;

		return (
			<OverlaySidebar
				classes="buy-credits-flow"
				showOverlaySidebar={showBuyCreditsFlow}
				close={this.closeSidebar}
			>
				<div className="overlay-sidebar-content">
					<div className="title">Piper Credits</div>
					<div className="subtitle">Select the amount by which you'd like to top up your piper credits</div>
					<div className={(creditsLoading ? "disabled" : "") + " main-content buy-credits-area"}>
						<div className="credit-balance">
							<span>Available credits:</span>{" "}
							<span className="count" title={commifyNumbers(credits ? credits : 0)}>
								{getSuffixedNumber(credits ? credits : 0)}
							</span>
						</div>
						<div className="credit-input-container">
							<div className="credit-input">
								<input
									value={creditsToBuy}
									onChange={(e) => {
										this.changeCreditsToBuy(e);
									}}
									type="number"
									placeholder="Credits"
								/>
								<div>
									<p className="meta-info">
										*Note: 1 Piper Credit ={" "}
										{isDiscountApplicable ? (
											<React.Fragment>
												<span className="markup-price">
													{printCurrency(billingCurrencySymbol)}
													{amountPerCredit}
												</span>
												<span className="actual-price">
													{printCurrency(billingCurrencySymbol)}
													{preProcess.unitPrice}
												</span>
											</React.Fragment>
										) : (
											<React.Fragment>
												{printCurrency(billingCurrencySymbol)}
												{amountPerCredit}
											</React.Fragment>
										)}
									</p>
									{totalSavings > 0 && !isPreProcessing ? (
										<p className="meta-info">
											You save {printCurrency(billingCurrencySymbol)}
											{totalSavings}
										</p>
									) : null}
								</div>
								<div data-active={creditsToBuy < 1000 ? true : false} className="error-message">
									*Minumum credits should be 1000
								</div>
							</div>
							<div
								className="credit-payment-amount"
								data-loading={isPreProcessing || !(creditsToBuy > 0)}
							>
								<div className="tax-details-item">
									<span className="tax-source">Amount</span>
									<span className="seperator">:</span>
									<span className="tax-value">
										{printCurrency(billingCurrencySymbol)}
										{setFloatingPrecision(commifyNumbers(preProcess.grossAmount.toFixed(2)))}
									</span>
								</div>
								<TaxComponents taxes={preProcess.taxes} currencySymbol={billingCurrencySymbol} />
								<div className="tax-details-item" data-type="net-pay">
									<span className="tax-source">Amount to Pay</span>
									<span className="seperator">:</span>
									<span className="tax-value">
										{printCurrency(billingCurrencySymbol)}
										{setFloatingPrecision(commifyNumbers(preProcess.netAmount.toFixed(2)))}
									</span>
								</div>
							</div>
						</div>
						{dimensions.width > 768 && (
							<div className="credit-inp-suggestions">
								{creditInputSuggestions.map((suggestion) => (
									<div
										key={suggestion.value}
										className="suggestion-item"
										onClick={() => {
											this.selectCreditsToBuy(suggestion.value);
										}}
									>
										<div className="credits-amt">{suggestion.valueForDisplay}</div>
										<div
											className={`currency-amt ${
												suggestion.isDiscountApplicable ? "markup-price" : ""
											}`}
										>
											{printCurrency(billingCurrencySymbol)} {suggestion.totalAmount}
										</div>
										{suggestion.isDiscountApplicable ? (
											<div className="currency-amt">
												{printCurrency(billingCurrencySymbol)}{" "}
												{suggestion.discountedTotalAmount}
											</div>
										) : null}
										<div className="currency-amt">
											{printCurrency(billingCurrencySymbol)} {suggestion.discountAmountPerCredits}
											/credit
										</div>
									</div>
								))}
							</div>
						)}
						<div>
							<Button
								clickHandler={this.initPayment}
								classes={
									creditsLoading || isPreProcessing || creditsToBuy < 1000 ? "at-btn--disabled" : ""
								}
							>
								Proceed to pay {printCurrency(billingCurrencySymbol)}{" "}
								<span className="Fw(600)">
									{setFloatingPrecision(commifyNumbers(preProcess.netAmount.toFixed(2)))}
								</span>
							</Button>
						</div>
					</div>
					<div className="loading-info">{creditsLoading ? "Please wait, processing your payment" : null}</div>
					{biz?.currency === "INR" && (
						<img
							className="piper-credits-rate-chart"
							src="/assets/piper-credits-rate-card.jpeg"
							alt="piper-credits-rate-chart"
						/>
					)}
				</div>
			</OverlaySidebar>
		);
	}
}

const TaxComponents = ({ taxes, currencySymbol }) => {
	return (
		<React.Fragment>
			{taxes.map((tax, i) => {
				if (tax.rateType === "PERCENTAGE") {
					return (
						<div key={i} className="tax-details-item">
							<span className="tax-source">{`${tax.title} @ ${tax.rate}%`}</span>
							<span className="seperator">:</span>
							<span className="tax-value">
								{printCurrency(currencySymbol)}
								{setFloatingPrecision(commifyNumbers(tax.value.toFixed(2)))}
							</span>
						</div>
					);
				} else {
					return (
						<div key={i} className="tax-details-item">
							<span className="tax-source">{`${tax.title}(${tax.rateType})`}</span>
							<span className="seperator">:</span>
							<span className="tax-value">
								{printCurrency(currencySymbol)}
								{setFloatingPrecision(commifyNumbers(tax.value.toFixed(2)))}
							</span>
						</div>
					);
				}
			})}
		</React.Fragment>
	);
};
