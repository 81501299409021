import React from "react";

const Header = () => {
	return (
		<div className="early-access-header">
			<div className="header-text">Periscope</div>
			<div className="enquiry">
				<img src="/assets/periscope/any-questions.svg" />
				<span className="questions">Have questions?</span>
				<span className="contact">Learn more</span>
			</div>
		</div>
	);
};

export default Header;
