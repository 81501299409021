import React, { useState, useCallback, useRef, useEffect } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";
import { SidebarPure } from "../Dashboard/TopPerforming";
import { SelectFilter } from "../_commons/SelectFilter";
import Placeholder from "../_commons/Placeholder";

// third party
import Select from "react-select-animated-v2";
import { connect } from "react-redux";
import _ from "lodash";
import { Treemap, Tooltip, ResponsiveContainer } from "recharts";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import {
	makeFlatObject,
	commifyNumbers,
	printCurrency,
	trackEvent,
	adjustNestedContainer,
	extractInitials
} from "../../atlas-utils";

// graphql
import { GET_BIZ_CHANNELS } from "../../graphql/salesAnalytics";

// actions
import { ActionTypes } from "../../actions/_types";
import { getDurationObject } from "../../actions/salesAnalytics";

// constants
import {
	SA_PERFORMANCE_CHANNELS,
	TOPIC_LIST_TOP_PERFORMING,
	TOPIC_LIST_SA_OVERVIEW_TYPE_CURRENCY,
	TREEMAP_COLORS,
	BRAND_COLORS
} from "../../client-config";
export const AVOID_TABLE_COLUMNS = {
	ITEMS_TOP_PERFORMING: "biz_item_id",
	STORES_TOP_PERFORMING: "biz_location_id",
	CATEGORIES_TOP_PERFORMING: "biz_category_id"
};
const VIEWS = [
	{ label: "Table", value: "table" },
	{ label: "Insights", value: "insights" }
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const Performance = ({
	salesAnalyticsState,
	salesAnalyticsPerformance,
	salesAnalyticsOverview,
	updateSalesAnalyticsState,
	currencySymbol,
	productType,
	itemImages,
	paginator,
	access,
	searchHandler,
	userEmail,
	isMultibrandEnabled = false
}) => {
	const { performanceChannels, performanceTopic, analyticsTopic } = salesAnalyticsState;
	const [currView, setCurrView] = useState(VIEWS[0]);
	const hiddenInp = useRef();
	const [searchKW, setSearchKW] = useState("");
	const [bizChannelsList, setBizChannelsList] = useState([]);
	const performSearch = useCallback(
		_.debounce((searchInput) => searchHandler(searchInput), 1000),
		[]
	);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const fetchBizPlatformsList = async () => {
		try {
			const variables = {
				includeHubChannels: true,
				includeUrbanpiper: false,
				includeInternalChannels: true
			};
			const resp = await client.query({
				query: GET_BIZ_CHANNELS,
				variables,
				fetchPolicy: "no-cache"
			});
			let bizChannels = resp.data.bizChannels.map((channel) => ({
				label: channel.valueForDisplay,
				value: channel.value ? channel.value.toUpperCase() : channel.value
			}));
			setBizChannelsList(bizChannels);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	useEffect(() => {
		fetchBizPlatformsList();
	}, []);

	const handleSearchOnEnter = () => {
		performSearch(searchKW);
	};
	const handleSearchKW = (searchInput) => {
		setSearchKW(searchInput);
		performSearch(searchInput);
	};
	const dismissSearch = () => {
		setSearchKW("");
		performSearch("");
	};

	const removeChannel = useCallback(
		(pc) => {
			updateSalesAnalyticsState({
				performanceChannels: performanceChannels.filter((p) => p.value !== pc.value)
			});
		},
		[performanceChannels]
	);

	const addChannel = useCallback(
		(channel) => {
			hiddenInp.current.focus();
			if (!performanceChannels.find((pc) => pc.value === channel.value)) {
				updateSalesAnalyticsState({
					performanceChannels: performanceChannels.concat([channel])
				});
			}

			// trackEvent('sales_analytics_performance_channel', {
			// 	topic: salesAnalyticsState.performanceTopic,
			// 	metricTopic: salesAnalyticsState.analyticsTopic,
			// 	channels: salesAnalyticsState.performanceChannels.map((pc) => pc.value),
			// 	...getDurationObject(salesAnalyticsState.appliedDateFilter),
			// });
		},
		[hiddenInp.current, performanceChannels, salesAnalyticsState]
	);

	const swithTab = useCallback(
		(tab) => {
			updateSalesAnalyticsState({
				performanceTopic: tab.topic,
				offset: 0
			});

			// trackEvent('sales_analytics_performance_topic', {
			// 	topic: tab.topic,
			// 	metricTopic: salesAnalyticsState.analyticsTopic,
			// 	channels: salesAnalyticsState.performanceChannels.map((pc) => pc.value),
			// 	...getDurationObject(salesAnalyticsState.appliedDateFilter),
			// });
		},
		[salesAnalyticsState]
	);

	const handleCurrView = (field, value) => {
		if (value.value === "insights") {
			store.dispatch({
				type: ActionTypes.UPDATE_SA_STATE,
				payload: { limit: 100 }
			});
		} else {
			store.dispatch({
				type: ActionTypes.UPDATE_SA_STATE,
				payload: { limit: 10 }
			});
		}
		setCurrView(value);
	};

	return (
		<div className="sales-analytics-performance">
			<Header
				hiddenInp={hiddenInp}
				performanceChannels={performanceChannels}
				bizChannels={bizChannelsList}
				addChannel={addChannel}
				removeChannel={removeChannel}
				productType={productType}
				searchKW={searchKW}
				handleSearchKW={handleSearchKW}
				dismissSearch={dismissSearch}
				handleSearchOnEnter={handleSearchOnEnter}
				currView={currView}
				handleCurrView={handleCurrView}
				userEmail={userEmail}
			/>
			<div className="performance-container">
				<div className="sidebar-container">
					<SidebarPure
						loading={salesAnalyticsOverview.loading}
						data={salesAnalyticsOverview.data}
						selectTopic={swithTab}
						topic={performanceTopic}
					/>
				</div>
				{currView.value === "table" && (
					<div className="data-container">
						{!salesAnalyticsPerformance.error ? (
							<Table
								salesAnalyticsPerformance={salesAnalyticsPerformance}
								analyticsTopic={analyticsTopic}
								currencySymbol={currencySymbol}
								performanceTopic={performanceTopic}
								handleNestedEntity={handleNestedEntity}
								hasAccess={!access.isNonHqAnalytics}
								isMultibrandEnabled={isMultibrandEnabled}
							/>
						) : (
							<div className="no-items-placeholder error">
								An error occurred while fetching the data, Please reload.
							</div>
						)}
						{paginator}
					</div>
				)}
				{currView.value === "insights" && (
					<div className={"data-container" + (salesAnalyticsPerformance.loading ? " loading" : "")}>
						{salesAnalyticsPerformance.treemap.length > 0 ? (
							<ResponsiveContainer width="100%" height={500}>
								<Treemap
									width={800}
									height={800}
									aspectRatio={16 / 9}
									data={salesAnalyticsPerformance.treemap || []}
									dataKey="sales"
									stroke="#fff"
									fill="#8884d8"
									content={
										<CustomizedContent
											colors={TREEMAP_COLORS}
											currencySymbol={currencySymbol}
											itemImages={itemImages}
										/>
									}
								>
									<Tooltip content={<CustomTooltip currencySymbol={currencySymbol} />} />
								</Treemap>
							</ResponsiveContainer>
						) : (
							<div className="no-items-placeholder">No data available!</div>
						)}
					</div>
				)}
			</div>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	productType: store.selectedModule.productType,
	salesAnalyticsState: store.salesAnalyticsState,
	salesAnalyticsPerformance: store.salesAnalyticsPerformance,
	salesAnalyticsOverview: store.salesAnalyticsOverview,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	access: store.login.loginDetail.access,
	userEmail: store.login.loginDetail.email
}))(Performance);

const CustomTooltip = (props) => {
	const { active, payload, currencySymbol } = props;
	if (active && payload?.length && (payload[0]?.payload?.width < 90 || payload[0]?.payload?.height < 90)) {
		const { name, others, sales, percent } = payload[0]?.payload;
		return (
			<div className="custom-treemap-tooltip">
				<div className="name" title={name === "Others" && others?.length ? others.join(", ") : name}>
					{name || "--"}
				</div>
				<div className="sales">
					{printCurrency(currencySymbol)}
					{sales || 0}
				</div>
				<div className="percent">{percent ? `${percent}%` : ""}</div>
			</div>
		);
	}
	return null;
};

const CustomizedContent = (props) => {
	const {
		root,
		depth,
		x,
		y,
		width,
		height,
		index,
		colors,
		itemId,
		name,
		sales,
		percent,
		others,
		currencySymbol,
		itemImages
	} = props;
	if (depth === 0) {
		return null;
	}
	if (depth === 1) {
		return (
			<switch>
				<g>
					<rect
						x={x}
						y={y}
						width={width}
						height={height}
						style={{
							fill: colors[index],
							stroke: "#fff",
							strokeWidth: 1 / (depth + 1e-10),
							strokeOpacity: 1 / (depth + 1e-10)
						}}
						fillOpacity={0.8}
					/>
					<foreignObject x={x} y={y} width={width} height={height}>
						<div className="area" xmlns="http://www.w3.org/1999/xhtml" style={{ margin: "auto" }}>
							{Object.keys(itemImages).length > 0 && itemId && itemImages[itemId] && (
								<img src={itemImages[itemId]} alt="" />
							)}
							{width >= 90 && height >= 90 && (
								<div className="details-container">
									<div className="name" title={others?.length ? others.join(", ") : name}>
										{name || "--"}
									</div>
									<div className="sales">
										{printCurrency(currencySymbol)}
										{sales || 0}
									</div>
									<div className="percent">{percent ? `${percent}%` : ""}</div>
								</div>
							)}
						</div>
					</foreignObject>
				</g>
			</switch>
		);
	}
	return null;
};

const Table = ({
	salesAnalyticsPerformance,
	currencySymbol,
	analyticsTopic,
	hasAccess = false,
	performanceTopic,
	handleNestedEntity,
	isMultibrandEnabled = false
}) => {
	const { data, loading } = salesAnalyticsPerformance;

	const placeholderContent = {
		placeholderText: "Crunching your numbers!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-bizkpi.svg",
		placeholderSubtext:
			"View insights about your sales performance with metrics and trends helping you to take data driven decisions for your business",
		size: "medium"
	};

	if ((!data || data.rows.length === 0) && loading) {
		return (
			<div className="P(10px)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	} else if ((data?.rows ?? []).length === 0 && !loading) {
		return <Placeholder {...placeholderContent} />;
	} else if (data) {
		const { rows, columns } = data;
		return (
			<div className={(rows.length > 0 && loading ? "disabled" : "") + " transactions-list-table"}>
				<div className="at-table-row-based">
					<TableHeader
						columns={columns}
						performanceTopic={performanceTopic}
						isMultibrandEnabled={isMultibrandEnabled}
					/>
					<TableList
						columns={columns}
						rows={rows}
						hasAccess={hasAccess}
						analyticsTopic={analyticsTopic}
						currencySymbol={currencySymbol}
						performanceTopic={performanceTopic}
						handleNestedEntity={handleNestedEntity}
						isMultibrandEnabled={isMultibrandEnabled}
					/>
				</div>
			</div>
		);
	}
	return null;
};

const TableHeader = ({ columns, performanceTopic, isMultibrandEnabled }) => {
	return (
		<div className="at-table-row transaction-header-row sales-analytics-performance-table">
			{columns
				.filter((c) => c.key !== AVOID_TABLE_COLUMNS[performanceTopic] && c.key.includes("id") === false)
				.map((c, i) => (
					<div key={i} className={c.key + " at-table-cell at-table-header at-header-text"}>
						{c.displayName}
					</div>
				))}
		</div>
	);
};

const TableList = ({
	columns,
	rows,
	currencySymbol,
	analyticsTopic,
	performanceTopic,
	handleNestedEntity,
	hasAccess,
	isMultibrandEnabled
}) => {
	const getValue = (row, isComparison = false, flatRow = {}, handleNestedEntity, hasAccess) => {
		if (!isComparison) {
			let val;
			switch (row.key) {
				case "name":
					if (flatRow[AVOID_TABLE_COLUMNS[performanceTopic]]) {
						return (
							<React.Fragment>
								{hasAccess ? (
									<a
										role="button"
										className="link-text"
										onClick={() =>
											handleNestedEntity(
												true,
												AVOID_TABLE_COLUMNS[performanceTopic].split("_")[1],
												flatRow[AVOID_TABLE_COLUMNS[performanceTopic]]?.value
											)
										}
										title={row.value}
									>
										{row.value}
									</a>
								) : (
									<div title={row.value}>{row.value}</div>
								)}
							</React.Fragment>
						);
					}
					return `${row.value}`;
				case "count":
					val = row.value;
					return `${commifyNumbers(val)}`;
				case "percentChange":
					val = Number(row.value);
					return <span className={(val < 0 ? "negative" : "positive") + " val-container"}>{val}%</span>;
				case "percentOfTotal":
					val = Number(row.value);
					return <span>{val}%</span>;
				case "brands":
					let brands = [];
					try {
						brands = JSON.parse(row.value);
					} catch (error) {
						brands = [];
					}
					brands = brands.map((brand) => ({
						...brand,
						color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
					}));
					return (
						<div className="brands-list">
							{brands?.length > 0
								? brands?.map((brand, j) => (
										<div key={j} className={"brand-initials " + brand.color} title={brand.name}>
											{brand.image ? (
												<img src={brand.image} alt="" />
											) : (
												extractInitials(brand.name?.split(" "))
											)}
										</div>
								  ))
								: "--"}
						</div>
					);
				case "sales":
					return (
						<span>
							{printCurrency(currencySymbol)}
							{commifyNumbers(row.value)}
						</span>
					);
				default:
					if (TOPIC_LIST_SA_OVERVIEW_TYPE_CURRENCY.indexOf(analyticsTopic) > -1) {
						return (
							<span>
								{printCurrency(currencySymbol)}
								{commifyNumbers(row.value)}
							</span>
						);
					} else {
						return <span>{commifyNumbers(row.value)}</span>;
					}
			}
		} else {
			if (TOPIC_LIST_SA_OVERVIEW_TYPE_CURRENCY.indexOf(analyticsTopic) > -1) {
				return (
					<span>
						{printCurrency(currencySymbol)}
						{commifyNumbers(row.comparisonValue)}
					</span>
				);
			} else {
				return <span>{commifyNumbers(row.comparisonValue)}</span>;
			}
		}
	};

	return (
		<React.Fragment>
			{rows.map((row, i) => (
				<TableListItem
					columns={columns}
					row={row}
					key={i}
					getValue={getValue}
					performanceTopic={performanceTopic}
					handleNestedEntity={handleNestedEntity}
					hasAccess={hasAccess}
					isMultibrandEnabled={isMultibrandEnabled}
				/>
			))}
		</React.Fragment>
	);
};

const TableListItem = ({
	columns,
	row,
	getValue,
	performanceTopic,
	handleNestedEntity,
	hasAccess,
	isMultibrandEnabled
}) => {
	let flatRow = makeFlatObject(row);
	return (
		<div className="at-table-row transaction-rows sales-analytics-performance-table">
			{columns
				.filter((c) => c.key !== AVOID_TABLE_COLUMNS[performanceTopic] && c.key.includes("id") === false)
				.map((c, i) => (
					<div
						key={i}
						className={
							c.key +
							" at-table-cell at-cell-text" +
							(AVOID_TABLE_COLUMNS[performanceTopic] && hasAccess ? " link-text" : "")
						}
					>
						{c.key === "percentOfTotal" ? (
							// <div
							// 	className="percent-bar"
							// 	style={{width: `${flatRow[c.key].value}%`}}
							// 	title={`${flatRow[c.key].value}%`}
							// />
							<div>{flatRow[c.key].value}%</div>
						) : (
							<React.Fragment>
								{getValue(flatRow[c.key], false, flatRow, handleNestedEntity, hasAccess)}
								{flatRow[c.key].comparisonValue && (
									<div className="comparison-value">v/s {getValue(flatRow[c.key], true)}</div>
								)}
							</React.Fragment>
						)}
					</div>
				))}
		</div>
	);
};

const Topbar = ({ salesAnalyticsOverview, performanceTopic, switchTab, isStickyOnTop }) => {
	const { data, loading } = salesAnalyticsOverview;
	if (!data && loading) {
		return <div className="shimmer H(25px)" />;
	} else if (data) {
		const tabs = data.filter(({ topic }) => TOPIC_LIST_TOP_PERFORMING.indexOf(topic) >= 0);
		return (
			<InfiniteTopBar
				clickHandler={switchTab}
				tabs={tabs}
				selected={performanceTopic}
				isStickyOnTop={isStickyOnTop}
			/>
		);
	}
	return null;
};

// reimplementing this instead of using the common topbar because we will need
// to convert to/from its value and displayName
const InfiniteTopBar = ({ selected, clickHandler, tabs, isStickyOnTop }) => (
	<div className={"at-top-bar-wrapper-v2 " + (isStickyOnTop ? "wrapper-sticky-top" : "")}>
		<div className="at-top-bar-v2">
			{tabs.map((tab) => (
				<div
					className={
						(tab.topic === selected ? "selected " : "") +
						" selectable topbar-column topbar-column--create-campaign"
					}
					key={tab.topic}
					onClick={() => clickHandler(tab)}
				>
					<div className="text text--small">{tab.displayName}</div>
				</div>
			))}
		</div>
	</div>
);

const Header = ({
	hiddenInp,
	performanceChannels,
	bizChannels = [],
	addChannel,
	removeChannel,
	productType,
	searchKW,
	handleSearchKW,
	dismissSearch,
	handleSearchOnEnter,
	currView,
	handleCurrView,
	userEmail
}) => {
	return (
		<div className="header-container">
			<div className="header-text">
				{/* <div className="title">Performance</div> */}
				{productType === "ORDERING" && (
					<React.Fragment>
						<input ref={hiddenInp} type="text" className="Op(0) W(0px) H(0px) Pos(a)" />
						<div className={"tags-container"}>
							<div className="title">Select sales channels:</div>
							<div className="list">
								{performanceChannels.map((channel, i) => {
									return (
										<div className="tag-item" key={i}>
											<span className="remove" onClick={() => removeChannel(channel)}>
												&times;
											</span>
											<span>{channel.label}</span>
										</div>
									);
								})}
								{performanceChannels.length < 3 && (
									<AddChannel
										options={bizChannels}
										handleSelect={addChannel}
										placeholder={
											performanceChannels.length === 0
												? "+ Add a channel"
												: "+ Add another channel"
										}
									/>
								)}
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
			<div className="header-action-button">
				<div className="filter-buttons">
					{userEmail.includes("@urbanpiper.com") && (
						<SelectFilter
							options={VIEWS}
							currValue={currView}
							setFilter={handleCurrView}
							isClearable={false}
						/>
					)}
					<div className="search-filter">
						{/* <div className="search-input-holder"> */}
						{/* <InputField
							classes="search-input"
							type="text"
							placeholder="Search"
							value={searchKW}
							onChange={(e) => handleSearchKW(e.target.value)}
							onEnter={() => handleSearchOnEnter()}
						/> */}
						<InputWithLabel
							classes="at-input--label"
							type="text"
							placeholder="Search"
							value={searchKW}
							showLabel={true}
							image={true}
							imageSrc="/assets/header-icons/icon-search.svg"
							onChange={(e) => handleSearchKW(e.target.value)}
							onEnter={() => handleSearchOnEnter()}
						/>
						{searchKW ? (
							<div onClick={dismissSearch} className="dismiss-search">
								<img className="" src="/assets/icons/cancel.png" />
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

const AddChannel = ({
	options,
	isLoading = false,
	labelKey = "label",
	valueKey = "value",
	handleSelect,
	handleSearch,
	placeholder
}) => {
	return (
		<div className="add-tags-control">
			<Select
				className="at--dropdown"
				classNamePrefix="react-select"
				isSearchable={true}
				isClearable={false}
				options={options}
				value={null}
				onChange={handleSelect}
				onInputChange={handleSearch}
				isLoading={isLoading}
				labelKey={labelKey}
				valueKey={valueKey}
				getOptionLabel={(option) => option[labelKey]}
				getOptionValue={(option) => option[valueKey]}
				placeholder={placeholder}
				noOptionsMessage={() => ""}
			/>
		</div>
	);
};
