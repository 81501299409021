import React from "react";
import { SelectFilter } from "../_commons/SelectFilter";

export const LocationPlatformSelector = ({
	locationsData,
	handleMenuPreviewLocationSelection,
	platformsData,
	handleMenuPreviewPlatformSelection,
	selectedLocation,
	selectedPlatform,
	isLoading
}) => {
	return (
		<div className={`location-platform-selector ${isLoading ? "disabled" : ""}`}>
			<SelectFilter
				placeholder="Locations"
				options={locationsData?.objects}
				currValue={{
					name: (
						<span>
							<span style={{ color: "#8A8A8A" }}>Location</span>&nbsp;&nbsp;
							{selectedLocation?.name}
						</span>
					),
					id: selectedLocation?.id
				}}
				labelKey="name"
				valueKey="id"
				setFilter={handleMenuPreviewLocationSelection}
				isClearable={false}
			/>
			<SelectFilter
				placeholder="Platforms"
				options={platformsData?.objects}
				currValue={{
					platformName: (
						<span>
							<span style={{ color: "#8A8A8A" }}>Platform</span>&nbsp;&nbsp;
							{selectedPlatform?.platformName}
						</span>
					),
					id: selectedPlatform?.id
				}}
				labelKey="platformName"
				valueKey="id"
				setFilter={handleMenuPreviewPlatformSelection}
				isClearable={false}
			/>
		</div>
	);
};
