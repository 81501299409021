import React from "react";

// components
import Popover from "../../_commons/Popover";
import { ButtonIcon } from "../../_commons/ButtonIcon";

// utils
import { commifyNumbers, printCurrency } from "../../../atlas-utils";

// helpers
import { getReadableDateFilter } from "../../../helpers/analytics";

const ChartMetric = ({
	size = "large",
	loading = false,
	label,
	description,
	descPosition = "down-left",
	value,
	compareValue,
	percentageChange,
	invertColors = false,
	units,
	currencySymbol
}) => {
	const renderPopover = (desc) => {
		return <div className="description">{desc}</div>;
	};

	return (
		<div className={"chart-metric-container " + size}>
			<div className="label-container">
				<div className="label">{label}</div>
				{description && (
					<Popover
						data={description}
						showOnHover={true}
						renderPopover={renderPopover}
						position={descPosition}
					>
						<ButtonIcon icon="info" color="#AAAAAA" />
					</Popover>
				)}
			</div>
			{value !== undefined && (
				<div className={"value-container" + (loading ? " disabled" : "")}>
					<div className="value">
						{currencySymbol ? printCurrency(currencySymbol) : ""}
						{commifyNumbers(value)}
						{units || ""}
					</div>
					{percentageChange !== undefined && (
						<Popover
							showOnHover={true}
							renderPopover={() => (
								<div className="compare-info">
									<div className="compare-date">{getReadableDateFilter(true)}</div>
									<div className="compare-value">
										{currencySymbol ? printCurrency(currencySymbol) : ""}
										{commifyNumbers(compareValue)}
										{units || ""}
									</div>
								</div>
							)}
							position="middle-right"
							classes={percentageChange >= 0 ? "pve" : "nve"}
						>
							<div
								className={
									"compare-value-container " +
									(!invertColors
										? percentageChange >= 0
											? "up"
											: "down"
										: percentageChange > 0
										? "down"
										: "up")
								}
							>
								<ButtonIcon
									icon={
										!invertColors
											? percentageChange >= 0
												? "up"
												: "down"
											: percentageChange <= 0
											? "down"
											: "up"
									}
									classes="arrow"
									color={
										!invertColors
											? percentageChange >= 0
												? "#0DA125"
												: "#D64949"
											: percentageChange > 0
											? "#D64949"
											: "#0DA125"
									}
								/>
								<div className="compare-value">{percentageChange}%</div>
							</div>
						</Popover>
					)}
				</div>
			)}
			{value === undefined && loading && (
				<div
					className={`shimmer W(${size === "large" ? "150px" : "120px"}) H(${
						size === "large" ? "30px" : "20px"
					})`}
				/>
			)}
		</div>
	);
};
export default ChartMetric;
