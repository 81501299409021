import React, { useState } from "react";

// third party
import { useSpring, animated as a } from "react-spring";
import { fetchCitiesList } from "../../actions/locations";
import { trackEvent } from "../../atlas-utils";
import { debounce } from "lodash";
import { connect } from "react-redux";

// components
import { Button } from "../_commons/Button";
import { LocationCreateIcons } from "../_commons/LocationCreateIcons";
import { SelectFilter } from "../_commons/SelectFilter";

// constant
import { countrySpecificPopularCities } from "../../client-config";

const CitySelection = ({ handleSelectedTabUpdate, city, handleFormData, bizCountry }) => {
	const country = bizCountry.toLowerCase() === "united kingdom" ? "uk" : bizCountry.toLowerCase();
	const INIT_CITY_ARRAY_VALUES = [...(countrySpecificPopularCities[country] ?? countrySpecificPopularCities.india)];
	const [citiesList, setCitiesList] = useState(INIT_CITY_ARRAY_VALUES);
	const [selectedCity, setSelectedCity] = useState(city ? { cityName: city } : undefined);
	const [searchingCities, setSearchingCities] = useState(false);
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const handleCityUpdate = (key, val) => {
		if (!val) {
			setSelectedCity(undefined);
			handleFormData("city", "");
			return;
		}
		setSelectedCity({
			cityName: val.cityName.split(",")[0]
		});
		handleFormData("city", val.cityName.split(",")[0]);
	};

	const handleCancel = () => {
		handleFormData("city", "");
	};

	const setDefinedCity = (selectedCity) => {
		if (selectedCity === city) {
			handleFormData("city", "");
			setSelectedCity(undefined);
		} else {
			setSelectedCity({
				cityName: selectedCity
			});
			handleFormData("city", selectedCity);
		}
	};

	const handleNextClick = () => {
		handleSelectedTabUpdate(4);
		trackEvent("wiz_loc_city_selection", { city: city });
	};

	const disabled = Boolean(city);

	const searchCities = async (searchCity) => {
		if (searchCity === "") {
			setCitiesList(INIT_CITY_ARRAY_VALUES);
			return;
		}
		setSearchingCities(true);
		const citySearchResult = await fetchCitiesList(searchCity);

		if (Array.isArray(citySearchResult?.data)) {
			const updatedCitiesList = [...citySearchResult.data];
			setCitiesList([
				...updatedCitiesList.map((city) => ({
					cityName: `${city.name}, ${city.state_name}, ${city.country_name}`
				}))
			]);
		}
		setSearchingCities(false);
	};

	const searchCitiesDebounced = debounce((searchCity) => searchCities(searchCity), 500);

	return (
		<a.div className="common-box-structure " style={contentProps}>
			<div className="header">In which city are you opening this location in?</div>
			<div className="city-selection-container">
				<div className="search-container">
					<SelectFilter
						currValue={selectedCity}
						options={citiesList}
						labelKey="cityName"
						valueKey="cityName"
						setFilter={handleCityUpdate}
						isLoading={searchingCities}
						isAsync={true}
						handleSearch={searchCitiesDebounced}
					/>
				</div>
			</div>
			<PopularCities setDefinedCity={setDefinedCity} city={city} country={country} />
			<div className="button-div">
				<Button clickHandler={() => handleSelectedTabUpdate(2)} type="secondary">
					<span>Previous</span>
				</Button>
				<Button classes={!disabled && "disabled"} clickHandler={handleNextClick}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
const mapStateToProps = (store) => ({
	bizCountry: store.login.loggedInbizDetail.country
});
export default connect(mapStateToProps)(CitySelection);

const PopularCities = ({ setDefinedCity, city, country }) => {
	return (
		<div className="popular-cities">
			<div className="title title--bold">Popular cities</div>
			<div className="cities-list">
				{(countrySpecificPopularCities[country] ?? countrySpecificPopularCities.india).map((cityName) => (
					<div
						onClick={() => setDefinedCity(cityName?.cityNameForIcon)}
						className={`city-div ${city === cityName?.cityNameForIcon ? "selected-city" : ""}`}
					>
						<LocationCreateIcons icon={cityName?.cityNameForIcon.toLowerCase()} />
						<span>{cityName?.cityNameForIcon}</span>
					</div>
				))}
			</div>
		</div>
	);
};
