import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
// actions
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

// utils
import { capitaliseText, extractInitials } from "../../atlas-utils";
import { client } from "../../client";

//graphql
import { GET_CITIES_LIST } from "../../graphql/misc";
import {
	GET_SCHEDULE_ENTITY_LOCATIONS,
	UPDATE_SCHEDULE_ENTITY_LOCATIONS,
	UPDATE_SCHEDULE_ENTITY_LOCATIONS_CASCADE
} from "../../graphql/timingGroups";
import { GET_LOCATIONS_LIST_FOR_TIMING_GROUP } from "../../graphql/locations.js";

// components
import { Button } from "../_commons/Button";
import { CommonTable } from "../_commons/CommonTable";
import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";
import { Paginator } from "../_commons/Paginator";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectAllFields } from "../_commons/SelectAllFields";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

const LocationAssociation = ({ biz, configItems, entityId, entityType, associatedLocations, refetchAssociations }) => {
	const [isLocationSelectionOpen, setIsLocationSelectionOpen] = useState(false);
	const [selectedBrand, setSelectedBrand] = useState(configItems?.brands?.items?.find((item) => item.id === "all"));

	const placeholderContent = {
		placeholderText: "No locations associated yet!",
		placeholderImageUrl: "/assets/empty_states/location-association.svg",
		placeholderSubtext: "View and manage all your restaurant locations ",
		placeholderButtonContent: (
			<>
				<span> + Associate Your First Location</span>
			</>
		),
		placeholderButtonClickAction: () => {
			setIsLocationSelectionOpen(true);
		},
		redirectionLink: "/piper-academy/timing-groups",
		redirectionLinkText: "Learn more about Location association",
		size: "medium"
	};

	const columns = [
		{
			name: "Location Name",
			field: "name",
			render: (record, i, archived) => (
				<div className="at-table-cell at-cell-text name" title={record.title}>
					<span>{capitaliseText(record?.name)}</span>
				</div>
			)
		},
		{
			name: "City",
			field: "city",
			render: (record, i, archived) => (
				<div className="at-table-cell at-cell-text city" title={record.city}>
					<span>{capitaliseText(record?.city)}</span>
				</div>
			)
		}
	];
	const handleClose = () => {
		refetchAssociations(selectedBrand?.id);
		setIsLocationSelectionOpen(false);
	};
	const handleBrand = (brand) => {
		setSelectedBrand(brand);
	};
	useEffect(() => {
		refetchAssociations(selectedBrand?.id);
	}, [selectedBrand]);

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};
	return (
		<div className="location-association-entity-wrapper">
			{associatedLocations?.length > 0 && (
				<div className="header-wrapper">
					<div>
						<p className="associated-locations-header">
							{biz?.isMultibrandEnabled ? "Associated Locations and Brands" : "Associated Locations"}
						</p>
						<p className="associated-locations-subheader">Manage locations associated with this Schedule</p>
					</div>
					<Button clickHandler={() => setIsLocationSelectionOpen(true)}>Update</Button>
				</div>
			)}

			{biz?.isMultibrandEnabled && (
				<div className="brand-location-filter">
					<div className="filter-text">Location details for:</div>

					<SelectFilterCustom
						options={configItems?.brands?.items}
						isLoading={configItems?.brands?.isLoading}
						field="brands"
						currValue={selectedBrand}
						setFilter={(f, value) => handleBrand(value)}
						labelKey={"name"}
						valueKey={"id"}
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						renderLabel={handleBrandsLabelOption}
						renderOptions={handleBrandsLabelOption}
						placeholder="Select brand"
					/>
				</div>
			)}
			{selectedBrand?.id === "all" && (
				<div className="all-brands-banner">
					<img src="/assets/icons/info.png" alt="info" />
					<p>
						Selecting 'All Brands' will cascade this schedule to all brands operating from the selected
						location
					</p>
				</div>
			)}
			<div>
				<CommonTable
					loading={false}
					data={associatedLocations || []}
					columns={columns}
					classes="location-association-list-container"
					content="Location Association"
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				{isLocationSelectionOpen && (
					<LocationSelectionDrawer
						handleClose={handleClose}
						entityId={entityId}
						entityType={entityType}
						isOpen={isLocationSelectionOpen}
						selectedBrand={selectedBrand}
					/>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems
});
export default connect(mapStateToProps)(LocationAssociation);

const LocationSelectionDrawer = ({ isOpen, entityId, entityType, handleClose = () => {}, selectedBrand }) => {
	const [loadingStates, setLoadingStates] = useState({
		loadingLocations: false,
		loadingCities: false,
		updateLocations: false
	});
	const [citiesData, setCitiesData] = useState([]);
	const [offset, setOffset] = useState(0);
	const debouncedSearchRef = useRef();
	const [appliedFilters, setAppliedFilters] = useState({
		city: null,
		searchKey: ""
	});
	const [locationsData, setLocationsData] = useState({});
	const [selectedLocations, setSelectedLocations] = useState({});
	const [isAllLocationsSelected, setIsAllLocationsSelected] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [disassociateAll, setDisAssociateAll] = useState(null);
	const { searchKey, city } = appliedFilters;

	const fetchCityList = async () => {
		setLoadingStates((current) => ({
			...current,
			loadingCities: true
		}));
		try {
			const variables = {};
			const respCities = await client.query({
				query: GET_CITIES_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			setCitiesData(respCities.data.cities.objects ?? []);
			setLoadingStates((current) => ({
				...current,
				loadingCities: false
			}));
		} catch (e) {
			setLoadingStates((current) => ({
				...current,
				loadingCities: false
			}));
			console.log(e);
		}
	};

	const fetchTimingGroupLocationAssociationList = async (offset = 0) => {
		setLoadingStates((current) => ({
			...current,
			loadingLocations: true
		}));
		const currentAssociatedLocations = {
			...selectedLocations
		};
		try {
			if (selectedBrand && selectedBrand?.id === "all") {
				const variables = {
					limit: 10,
					offset,
					filters: [
						{
							field: "is_active",
							value: "true"
						}
					],
					sort: {
						field: "name",
						order: "ASC"
					}
				};
				if (appliedFilters?.searchKey?.length > 0) {
					variables.search = [
						{
							key: "name",
							value: appliedFilters?.searchKey
						}
					];
				}
				if (selectedBrand && selectedBrand?.id !== "") {
					variables.brand = String(selectedBrand?.id);
				}
				const respLocations = await client.query({
					query: GET_LOCATIONS_LIST_FOR_TIMING_GROUP,
					variables,
					fetchPolicy: "no-cache"
				});
				setLocationsData(respLocations?.data?.stores);
				setLoadingStates((current) => ({
					...current,
					loadingLocations: false
				}));
			} else {
				const variables = {
					entityId,
					entityType,
					limit: 10,
					offset,
					sort: {
						field: "associated",
						order: "ASC"
					},
					filters: []
				};
				if (selectedBrand && selectedBrand?.id !== "all") {
					variables.brandId = parseInt(selectedBrand?.id);
				}
				if (appliedFilters?.city?.value?.length > 0) {
					variables.filters.push({
						field: "city",
						value: appliedFilters?.city?.value
					});
				}
				if (appliedFilters?.searchKey?.length > 0) {
					variables.filters.push({
						field: "name",
						value: appliedFilters?.searchKey
					});
				}
				const respLocations = await client.query({
					query: GET_SCHEDULE_ENTITY_LOCATIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				if (respLocations?.data?.entityLocationAssociations?.objects) {
					respLocations.data.entityLocationAssociations.objects.forEach((loc) => {
						if (loc?.isAssociated) {
							currentAssociatedLocations[loc.bizLocationId] = true;
						}
					});
				}
				if (Object.keys(currentAssociatedLocations).length > 0) {
					setSelectedLocations(currentAssociatedLocations);
				}
				setLocationsData(
					{
						...respLocations?.data?.entityLocationAssociations,
						objects: respLocations?.data?.entityLocationAssociations?.objects?.map((loc) => {
							return {
								...loc,
								id: loc?.bizLocationId,
								name: loc?.bizLocationNickname
							};
						})
					} ?? {}
				);
				setLoadingStates((current) => ({
					...current,
					loadingLocations: false
				}));
			}
		} catch (e) {
			setLoadingStates((current) => ({
				...current,
				loadingLocations: false
			}));
			console.log(e);
		}
	};
	const updateAssociations = async () => {
		setLoadingStates((current) => ({
			...current,
			updateLocations: true
		}));
		const locationsToAssociate = [];
		const locationsToDisassociate = [];
		if (!allSelected) {
			for (const [id, value] of Object.entries(selectedLocations)) {
				if (value) {
					locationsToAssociate.push(id);
				} else {
					locationsToDisassociate.push(id);
				}
			}
		}

		try {
			const variables = {
				entityId,
				entityType,
				locationsToAssociate,
				locationsToDisassociate,
				associateAll: allSelected,
				disassociateAll: locationsToAssociate?.length === 0 ? disassociateAll : false,
				filters: []
			};
			const respLocations = await client.mutate({
				mutation: UPDATE_SCHEDULE_ENTITY_LOCATIONS,
				variables
			});
			if (respLocations?.data?.updateEntityLocations?.status?.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Location Association updated!",
						timeout: 5000,
						error: false
					}
				});
				handleClose();
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
			setLoadingStates((current) => ({
				...current,
				updateLocations: false
			}));
		} catch (e) {
			console.log(e);
			setLoadingStates((current) => ({
				...current,
				updateLocations: false
			}));
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
	};
	const updateRootLocationAssociations = async () => {
		setLoadingStates((current) => ({
			...current,
			updateLocations: true
		}));
		const locationsToAssociate = [];
		const locationsToDisassociate = [];
		if (!allSelected) {
			for (const [id, value] of Object.entries(selectedLocations)) {
				if (value) {
					locationsToAssociate.push(id);
				} else {
					locationsToDisassociate.push(id);
				}
			}
		}

		try {
			const variables = {
				timingGroupId: entityId,
				entityType,
				rootLocationsToAssociate: locationsToAssociate,
				associateAll: allSelected,
				filters: []
			};
			const respLocations = await client.mutate({
				mutation: UPDATE_SCHEDULE_ENTITY_LOCATIONS_CASCADE,
				variables
			});
			if (respLocations?.data?.cascadeTgBrandLocations?.status?.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Location Association updated!",
						timeout: 5000,
						error: false
					}
				});
				handleClose();
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
			setLoadingStates((current) => ({
				...current,
				updateLocations: false
			}));
		} catch (e) {
			console.log(e);
			setLoadingStates((current) => ({
				...current,
				updateLocations: false
			}));
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
	};

	useEffect(() => {
		if (debouncedSearchRef.current) {
			clearTimeout(debouncedSearchRef.current);
			debouncedSearchRef.current = setTimeout(() => {
				fetchTimingGroupLocationAssociationList();
			}, 500);
		} else {
			debouncedSearchRef.current = setTimeout(() => {
				fetchTimingGroupLocationAssociationList();
			}, 500);
		}

		return () => {
			if (debouncedSearchRef.current) {
				clearTimeout(debouncedSearchRef.current);
			}
		};
	}, [searchKey]);

	useEffect(() => {
		fetchTimingGroupLocationAssociationList();
	}, [city]);

	const handleFilters = (field, value) => {
		setOffset(0);
		setAppliedFilters((current) => ({
			...current,
			[field]: value
		}));
	};

	useEffect(() => {
		fetchCityList();
		fetchTimingGroupLocationAssociationList();
	}, []);
	const handlePagination = async (page) => {
		setOffset((page - 1) * 10);
		await fetchTimingGroupLocationAssociationList((page - 1) * 10);
	};
	const locationsColumn = [
		{
			title: "location name",
			dataIndex: "name",
			render: (data) => data
		},
		{
			title: "city",
			dataIndex: "city",
			render: (data) => data
		}
	];
	const handleSingleFieldSelection = (id) => {
		if (!isFormTouched) {
			setFormTouched(true);
		}
		if (selectedLocations[id]) {
			const updatedSelectedLocation = {
				...selectedLocations
			};
			updatedSelectedLocation[id] = false;
			setSelectedLocations({
				...updatedSelectedLocation
			});
			setIsAllLocationsSelected(false);
			return;
		}
		setSelectedLocations((current) => ({
			...current,
			[id]: true
		}));
	};
	const handleAllFieldSelection = (state) => {
		setShowAllFieldSelector(true);
		if (state) {
			const updatedSelectedLocation = {
				...selectedLocations
			};
			locationsData.objects.forEach((loc) => {
				updatedSelectedLocation[loc?.id] = true;
			});
			setSelectedLocations(updatedSelectedLocation);
			setIsAllLocationsSelected(true);
		} else {
			const updatedSelectedLocation = {
				...selectedLocations
			};
			if (Object.keys(updatedSelectedLocation)) {
				for (const key in updatedSelectedLocation) {
					updatedSelectedLocation[key] = false;
				}
			}
			setSelectedLocations(updatedSelectedLocation);
			setIsAllLocationsSelected(false);
			setAllSelected(false);
			setShowAllFieldSelector(false);
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCompleteSelection = (selectAllLocations = false) => {
		if (!isFormTouched) {
			setFormTouched(true);
		}
		setAllSelected(selectAllLocations);
		if (!selectAllLocations) {
			setShowAllFieldSelector(false);
			setIsAllLocationsSelected(false);
			setSelectedLocations({});
			setDisAssociateAll(true);
		}
	};
	const totalPages = Math.ceil((locationsData?.totalObjectCount || locationsData?.count) / 10);

	const handleDrawerClose = () => {
		setCitiesData([]);
		setLocationsData({});
		handleClose();
	};
	return (
		<FormSidebar
			title="Select Locations"
			subTitle="Select which locations you want to associate this schedule to"
			isNested
			isOpen={isOpen}
			submitTitle={"Update"}
			submitButtonWidth={200}
			submit={selectedBrand?.id === "all" ? updateRootLocationAssociations : updateAssociations}
			close={handleDrawerClose}
			disabled={!isFormTouched || loadingStates?.updateLocations}
			hideActions={!isFormTouched}
			loading={loadingStates?.updateLocations}
		>
			<div className="filters-container">
				<div className="dropdown-filters-container">
					<SelectFilter
						placeholder="City"
						options={citiesData}
						valueKey="value"
						labelKey="valueForDisplay"
						setFilter={handleFilters}
						field="city"
					/>
				</div>
				<SearchFilter
					placeholder="Search"
					value={appliedFilters.searchKey}
					setFilter={handleFilters}
					filterOption={{ field: "searchKey" }}
				/>
			</div>
			{showAllFieldSelector && totalPages > 1 && (
				<SelectAllFields
					allSelected={allSelected}
					handleCompleteSelection={handleCompleteSelection}
					mainMessage={
						<span>
							{allSelected ? "All " : ""}
							<strong>
								{allSelected
									? locationsData?.totalObjectCount || locationsData?.count
									: locationsData?.objects?.length}{" "}
							</strong>
							location(s) {allSelected ? "on all pages are selected." : "on this page are selected."}
						</span>
					}
					linkMessage={
						allSelected ? (
							" Clear selection"
						) : (
							<span>
								Select <strong>{locationsData?.totalObjectCount || locationsData?.count}</strong>{" "}
								locations from all pages
							</span>
						)
					}
				/>
			)}
			<div className="location-form-table">
				<FormTable
					columns={locationsColumn}
					dataSource={locationsData?.objects}
					placeholderContent={{
						placeholderText: "No locations found!",
						placeholderImageUrl: "/assets/empty_states/graphics-empty-locations-main.svg"
					}}
					isSelectionEnabled
					handleSingleFieldSelection={handleSingleFieldSelection}
					handleAllFieldsSelection={handleAllFieldSelection}
					selectedFields={selectedLocations}
					isAllFieldSelected={isAllLocationsSelected}
					isLoading={loadingStates?.loadingLocations}
					contentTableClass={loadingStates?.loadingLocations || allSelected ? "disabled" : ""}
				/>
				<Paginator limit={10} offset={offset} count={locationsData?.count} goToPage={handlePagination} />
			</div>
		</FormSidebar>
	);
};
