import React from "react";

// third party
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// client
import { client } from "../client";
import { store } from "../store/configureStore";
import history from "../history";

// graphql
import { FILTERED_USERS_PREVIEW, DEACTIVE_SEGMENT, SAVE_SEGMENT } from "../graphql/segments";

// actions
import { fetchSuggestedSegment, fetchSegmentGroups } from "../actions/segments";
import { toggleGlobalLoader } from "../actions/actions";

// utils
import { commifyNumbers, lS } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";

// components
import CreateSegment from "./CreateSegment";
import { Button } from "../components/_commons/Button";
import { InfiniteTopBar } from "../components/SiteComp";
import OverlaySidebar from "../components/_commons/OverlaySidebar";
import { FiltersPreview } from "../components/CreateCampaign/CreateCampaignPreview";
import { DownArrow } from "../components/_commons/DownArrow";
import Placeholder from "../components/_commons/Placeholder";
import CreateIcon from "../components/_commons/CreateIcon";

const SegmentGalleryTopbar = ({ tabs, selectedTab, switchTab }) => {
	let index = tabs.indexOf(selectedTab);
	return (
		<InfiniteTopBar
			clickHandler={(e, i) => {
				let toSwitchTab = tabs[i];
				switchTab(toSwitchTab);
			}}
			tabs={tabs}
			selected={index}
		/>
	);
};

const SegmentsContainer = () => (
	<Switch>
		<Route exact path="/segments" component={Segments} />
		<Route exact path="/segments/new" component={CreateSegment} />
	</Switch>
);
export default SegmentsContainer;

@connect((store) => ({
	suggestedSegmentList: store.suggestedSegmentList
}))
export class Segments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showSegmentDetails: false,
			selectedSegmentFilter: {},
			selectedGroup: "",
			loadingSegmentId: null
		};
	}

	componentDidMount() {
		fetchSegmentGroups(this.fetchSegmentGroupsCallback);
	}

	fetchSegmentGroupsCallback = (segmentGroups) => {
		this.setState(
			{
				selectedGroup: segmentGroups.length > 0 ? segmentGroups[0] : ""
			},
			() => {
				store.dispatch({
					type: "GET_SEGMENT_GROUPS_SUCCESS",
					payload: segmentGroups
				});
			}
		);
	};

	flipSegmentDetails = () => {
		this.setState({
			showSegmentDetails: !this.state.showSegmentDetails
		});
	};

	setSegmentDetailsFilter = (selectedSegmentFilter) => {
		this.setState({
			selectedSegmentFilter: JSON.parse(selectedSegmentFilter),
			showSegmentDetails: true
		});
	};

	switchTab = (selectedGroup) => {
		this.setState({
			selectedGroup
		});
	};

	createNewSegment = () => {
		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'segment_create_start',
		// });

		lS.remove("savedSegment");
		store.dispatch({
			type: "SEGMENT_RESET"
		});
		history.push("/segments/new");
	};

	editSegment = (e, obj) => {
		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'segment_edit_start',
		// });

		e.stopPropagation();
		let segmentObj = {
			object: {
				id: Number(obj.id)
			}
		};
		lS.set("savedSegment", segmentObj);
		history.push("/segments/new?edit=true");
	};

	cloneSegment = async (e, obj) => {
		e.stopPropagation();
		this.setState({
			loadingSegmentId: obj.id
		});
		store.dispatch(toggleGlobalLoader(true));
		try {
			const resp = await client.mutate({
				mutation: SAVE_SEGMENT,
				variables: {
					title: `segment-clone-${obj.id}`,
					isImplicit: false,
					filters: obj.filters,
					isActive: true
				}
			});
			this.setState({
				loadingSegmentId: null
			});
			store.dispatch(toggleGlobalLoader(false));
			// track this event
			PubSub.publish(TRACK_EVENT, {
				tracker: "mixpanel",
				eventName: "segment_clone",
				eventMeta: {
					source: this.props.selectedGroup
				}
			});
			lS.set("savedSegment", resp.data.saveSegment);
			history.push("/segments/new");
		} catch (error) {
			console.log(error);
			this.setState({
				loadingSegmentId: null
			});
			store.dispatch(toggleGlobalLoader(false));
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	startCampaignWithSegment = (e, filters) => {
		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'campaign_create_start',
		// 	eventMeta: {
		// 		source: 'segments',
		// 	},
		// });

		e.stopPropagation();
		const payload = {
			filters,
			segmentType: "saved"
		};
		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload
		});
		lS.remove("savedCampaign");
		history.push("/campaigns/new");
	};

	toggleActiveSegment = async (e, obj) => {
		e.stopPropagation();
		this.setState({
			loadingSegmentId: obj.id
		});
		store.dispatch(toggleGlobalLoader(true));
		await client.mutate({
			mutation: DEACTIVE_SEGMENT,
			variables: {
				id: Number(obj.id),
				filters: obj.filters,
				title: obj.title,
				isImplicit: false,
				isActive: obj.activate
			}
		});
		store.dispatch(toggleGlobalLoader(false));
		let activeSegments = [...this.props.suggestedSegmentList.data.objects];
		let inactiveSegments = [...this.props.suggestedSegmentList.inactiveSegments.objects];
		if (obj.activate) {
			const statusToChange = inactiveSegments.find((cs) => cs.id === obj.id);
			if (statusToChange && statusToChange.id) {
				statusToChange.isActive = true;
				activeSegments.push(statusToChange);
				inactiveSegments = inactiveSegments.filter(
					(inActiveSegment) => inActiveSegment.id !== statusToChange.id
				);
			}
		} else {
			const statusToChange = activeSegments.find((cs) => cs.id === obj.id);
			if (statusToChange && statusToChange.id) {
				statusToChange.isActive = false;
				inactiveSegments.push(statusToChange);
				activeSegments = activeSegments.filter((activeSegment) => activeSegment.id !== statusToChange.id);
			}
		}
		this.setState({
			loadingSegmentId: null
		});
		store.dispatch({
			type: "GET_SUGG_SEGMENT_LIST_SUCCESS",
			payload: {
				objects: activeSegments
			}
		});
		store.dispatch({
			type: "GET_INACTIVE_SEGMENT_LIST_SUCCESS",
			payload: {
				objects: inactiveSegments
			}
		});

		// track this event
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName: obj.activate ? "segment_activate" : "segment_deactivate"
		});
	};

	render() {
		const { segmentGroups } = this.props.suggestedSegmentList;
		const { loadingSegmentId } = this.state;
		return (
			<div>
				<div className="campaign-section segment-gallery section-container-common">
					<div>
						<div className="header">
							<div className="header-text">Segment Gallery</div>
							<div className="subheader-text">Create & manage all your segments</div>
							{window.isMobile ? (
								<div className="Mt(10px)">
									<Button classes="W(100%)" clickHandler={this.createNewSegment}>
										Create segment{" "}
									</Button>
								</div>
							) : (
								<div
									onClick={() => {
										this.createNewSegment();
									}}
									className="filter-in-header create-custom-segment"
								>
									<div className="at-btn">
										<img src="/assets/icons/icon-create.svg" alt="" />
										Create segment
									</div>
								</div>
							)}
						</div>
					</div>
					<SegmentGalleryTopbar
						selectedTab={this.state.selectedGroup}
						tabs={segmentGroups}
						switchTab={this.switchTab}
					/>
					<SegmentBlocks
						selectedGroup={this.state.selectedGroup}
						setSegmentDetailsFilter={this.setSegmentDetailsFilter}
						toggleActiveSegment={this.toggleActiveSegment}
						loadingSegmentId={loadingSegmentId}
						editSegment={this.editSegment}
						cloneSegment={this.cloneSegment}
						startCampaignWithSegment={this.startCampaignWithSegment}
						activeSegments={true}
					/>
					{this.state.selectedGroup === "My Segments" && (
						<SegmentBlocks
							selectedGroup={this.state.selectedGroup}
							setSegmentDetailsFilter={this.setSegmentDetailsFilter}
							toggleActiveSegment={this.toggleActiveSegment}
							loadingSegmentId={loadingSegmentId}
							editSegment={this.editSegment}
							cloneSegment={this.cloneSegment}
							startCampaignWithSegment={this.startCampaignWithSegment}
							activeSegments={false}
						/>
					)}
				</div>
				<OverlaySidebar showOverlaySidebar={this.state.showSegmentDetails} close={this.flipSegmentDetails}>
					<SegmentDetails filters={this.state.selectedSegmentFilter} />
				</OverlaySidebar>
			</div>
		);
	}
}

const SegmentDetails = ({ filters }) => {
	const filtersCount = Object.keys(filters).length;
	return (
		<div className="overlay-sidebar-content">
			<div className="title">Segment details</div>
			<div className="main-content">
				{filtersCount > 0 ? (
					<FiltersPreview filters={filters} initialFiltersCount={10} />
				) : (
					<div className="meta-info">No filters applied!</div>
				)}
			</div>
		</div>
	);
};

@connect((store) => ({
	suggestedSegmentList: store.suggestedSegmentList,
	dimensions: store.configItems.dimensions
}))
class SegmentBlocks extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inactiveSegments: []
		};
	}

	componentDidMount() {
		if (this.props.selectedGroup) {
			fetchSuggestedSegment(this.props, "network-only");
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.selectedGroup != nextProps.selectedGroup) {
			fetchSuggestedSegment(nextProps, "network-only");
		}
	}

	render() {
		const { activeSegments, suggestedSegmentList, loadingSegmentId, dimensions } = this.props;
		const { loading, loadingSegmentGroups } = suggestedSegmentList;

		const placeholderContent = {
			placeholderText: "No segments added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-segment.svg",
			placeholderSubtext:
				"Manage your target audience segments here to ensure your business is positioned right for the correct audience",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add a new Segment</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/segments/new");
			},
			size: "medium"
		};

		if (loading || loadingSegmentGroups) {
			if (!activeSegments) {
				return (
					<div className="inactive-segment-container">
						<div className="header">In active segments</div>
						{dimensions.width > 768 ? (
							<div className="P(25px) D(f) Jc(sb)">
								<div className="shimmer H(200px) W(47%)" />
							</div>
						) : (
							<div className="P(10px) D(f) Jc(sb)">
								<div className="shimmer H(200px) W(100%)" />
							</div>
						)}
					</div>
				);
			}
			return dimensions.width > 768 ? (
				<div className="P(25px) D(f) Jc(sb)">
					<div className="shimmer H(200px) W(47%)" />
				</div>
			) : (
				<div className="P(10px) D(f) Jc(sb)">
					<div className="shimmer H(200px) W(100%)" />
				</div>
			);
		}

		if (activeSegments) {
			if (this.props.suggestedSegmentList.data.objects.length > 0) {
				const nodes = this.props.suggestedSegmentList.data.objects.map((segmentItem) => {
					return (
						<SegmentBlockNode
							key={segmentItem.id}
							startCampaignWithSegment={this.props.startCampaignWithSegment}
							setSegmentDetailsFilter={this.props.setSegmentDetailsFilter}
							selectedGroup={this.props.selectedGroup}
							toggleActiveSegment={this.props.toggleActiveSegment}
							loading={loadingSegmentId === segmentItem.id}
							editSegment={this.props.editSegment}
							cloneSegment={this.props.cloneSegment}
							{...segmentItem}
						/>
					);
				});
				return <div className="segment-container">{nodes}</div>;
			} else {
				return (
					<div className="segment-container">
						<div className="P(50px)">
							<Placeholder {...placeholderContent} />
						</div>
					</div>
				);
			}
		} else {
			if (this.props.suggestedSegmentList.inactiveSegments.objects.length > 0) {
				const nodes = this.props.suggestedSegmentList.inactiveSegments.objects.map((segmentItem) => {
					return (
						<SegmentBlockNode
							key={segmentItem.id}
							startCampaignWithSegment={this.props.startCampaignWithSegment}
							setSegmentDetailsFilter={this.props.setSegmentDetailsFilter}
							selectedGroup={this.props.selectedGroup}
							toggleActiveSegment={this.props.toggleActiveSegment}
							loading={loadingSegmentId === segmentItem.id}
							editSegment={this.props.editSegment}
							cloneSegment={this.props.cloneSegment}
							{...segmentItem}
						/>
					);
				});
				return (
					<div className="inactive-segment-container">
						<div className="header">In active segments</div>
						<div className="segment-container">{nodes}</div>
					</div>
				);
			} else {
				return null;
			}
		}
	}
}

@connect((store) => ({
	suggestedSegmentList: store.suggestedSegmentList
}))
class SegmentBlockNode extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			count: "--",
			percentage: "--"
		};
	}

	componentDidMount() {
		client
			.query({
				query: FILTERED_USERS_PREVIEW,
				variables: {
					segmentId: Number(this.props.id)
				},
				fetchPolicy: "network-only"
			})
			.then((resp) => {
				this.setState({
					count: resp.data.filteredUsersPreview.targeted,
					percentage: resp.data.filteredUsersPreview.percentageTargeted
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	expandMenu() {
		this.setState({
			expandMenu: !this.state.expandMenu
		});
	}

	render() {
		const { id, title, description, startCampaignWithSegment, filters, isActive, loading } = this.props;
		return (
			<div
				className={(loading ? "disabled" : "") + " segment-block-container"}
				onClick={() => this.props.setSegmentDetailsFilter(filters)}
				title="Click to see more details"
			>
				<div className={(!isActive ? "in-active" : "active") + "  top-section"}>
					<div className="title" title={title}>
						{title}
					</div>
					<div className="description" title={description}>
						{description}
					</div>
				</div>
				<div className={(!isActive ? "in-active" : "active") + " bottom-section"}>
					<div className="row">
						<div>Number of customers in this segment</div>
						<div>{commifyNumbers(this.state.count)}</div>
					</div>
					<div className="row">
						<div>Percentage of total customers</div>
						<div>{this.state.percentage}%</div>
					</div>
				</div>
				<div className="footer">
					{isActive ? (
						<SegmentFooterBtns
							editSegment={this.props.editSegment}
							cloneSegment={this.props.cloneSegment}
							toggleActiveSegment={this.props.toggleActiveSegment}
							{...this.props}
						/>
					) : (
						<div
							onClick={(e) => {
								this.props.toggleActiveSegment(e, { filters, title, id, activate: true });
							}}
							className="edit-clone-btn edit-clone-btn--in-active"
						>
							<span title="">Activate</span>
						</div>
					)}
					{isActive ? (
						<div className="start-campaign-btn" onClick={(e) => startCampaignWithSegment(e, filters)}>
							<div className="btn-text">Start campaign</div>
							<img className="btn-icon" src="/assets/icons/ic_right_arrow_blue.svg" alt="" />
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

class SegmentFooterBtns extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expand: false
		};
	}

	expand(e) {
		e.stopPropagation();
		this.setState({
			expand: !this.state.expand
		});
	}

	render() {
		let { group, cloneSegment, editSegment, id, filters } = this.props;
		return (
			<div className="edit-clone-btn">
				{group == "My Segments" ? (
					<span>
						<span onClick={(e) => editSegment(e, { id })} title="">
							Edit
						</span>
						<DownArrow
							handleClick={(e) => this.expand(e)}
							expanded={this.state.expand}
							title=""
							color="black"
							classes="edit-clone--arrow"
						/>
						{this.state.expand ? <EditCloneFloat {...this.props} /> : null}
					</span>
				) : (
					<span onClick={(e) => cloneSegment(e, { id, filters })} title="">
						Clone
					</span>
				)}
			</div>
		);
	}
}

const EditCloneFloat = ({ filters, title, id, group, toggleActiveSegment, cloneSegment }) => (
	<div
		style={{
			height: "67px"
		}}
		className="edit-clone-btn--floating"
	>
		<div
			onClick={(e) => {
				toggleActiveSegment(e, { filters, title, id, activate: false });
			}}
			title=""
			style={{
				display: group == "My Segments" ? "inline-block" : "none"
			}}
		>
			Deactivate
		</div>
		<div
			onClick={(e) => {
				cloneSegment(e, { id, filters });
			}}
			title=""
		>
			Clone
		</div>
	</div>
);
