import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// graphql
import { GET_ANALYTICS_SUMMARY, GET_ANALYTICS_DATASET_TAB } from "../../graphql/getAnalytics";

// components
import TopPerformingTable from "./TopPerformingTable";
import TopPerformingSidebar from "./TopPerformingSidebar";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";

// utils
import { adjustNestedContainer } from "../../atlas-utils";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { toggleGlobalLoader } from "../../actions/actions";

// constants
import { TOPIC_LIST_TOP_PERFORMING } from "../../client-config";
import { TRACK_EVENT } from "../../atlas-utils/tracking";
import { ActionTypes } from "../../actions/_types";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

@connect((store) => ({
	dashboardState: store.dashboardState,
	topPerfTopics: store.topPerfTopics
}))
export default class TopPerforming extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			loading: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		let eventName = "dash_top_performing_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// get data
		this.setState({
			loading: true
		});
		store.dispatch(toggleGlobalLoader(true));
		await this.getData(this.props);
		this.setState({
			loading: false
		});
		store.dispatch(toggleGlobalLoader(false));

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		let is_data_present = false;
		const tableData = store.getState().topPerfData.data;
		if (tableData && tableData.rows && tableData.rows.length) {
			is_data_present = true;
		}
		const { productType, dashboardState } = this.props;
		const { topPerfTopic, appliedDateFilter } = dashboardState;
		const eventMeta = {
			topic: topPerfTopic,
			duration: appliedDateFilter.current.dateFilter,
			mode: productType,
			is_data_present,
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });
	}

	async componentWillReceiveProps(newProps) {
		if (this.props.productType !== newProps.productType || this.props.selectedBrand !== newProps.selectedBrand) {
			this.setState({
				loading: true
			});
			store.dispatch(toggleGlobalLoader(true));
			await this.getData(newProps);
			this.setState({
				loading: false
			});
			store.dispatch(toggleGlobalLoader(false));
		} else {
			// set tracking related info
			let eventName = "";
			let perfStart = 0;
			let perfEnd = 0;
			if (window.performance) {
				perfStart = window.performance.now();
			}

			if (
				this.props.dashboardState.appliedDateFilter.current.dateFilter !==
				newProps.dashboardState.appliedDateFilter.current.dateFilter
			) {
				this.setState({
					loading: true
				});
				store.dispatch(toggleGlobalLoader(true));
				await this.getData(newProps);
				this.setState({
					loading: false
				});
				store.dispatch(toggleGlobalLoader(false));

				// set tracking related info
				eventName = "dash_top_performing_duration";
			}
			if (
				this.props.dashboardState.topPerfTopic !== newProps.dashboardState.topPerfTopic ||
				this.props.selectedBrand !== newProps.selectedBrand
			) {
				this.setState({
					loading: true
				});
				store.dispatch(toggleGlobalLoader(true));
				await this.fetchTopPerforming(newProps);
				this.setState({
					loading: false
				});
				store.dispatch(toggleGlobalLoader(false));

				//set tracking related info
				eventName = "dash_top_performing_topic";
			}

			// set tracking related info and send the event to be logged
			// checking for eventName before tracking this event to make sure that a change was performed
			if (eventName) {
				if (window.performance) {
					perfEnd = window.performance.now();
				}
				let is_data_present = false;
				const tableData = store.getState().topPerfData.data;
				if (tableData && tableData.rows && tableData.rows.length) {
					is_data_present = true;
				}
				const { productType, dashboardState } = newProps;
				const { topPerfTopic, appliedDateFilter } = dashboardState;
				const eventMeta = {
					topic: topPerfTopic,
					duration: appliedDateFilter.current.dateFilter,
					mode: productType,
					is_data_present,
					time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
				};
				// PubSub.publish(TRACK_EVENT, {
				// 	tracker: 'mixpanel',
				// 	eventName,
				// 	eventMeta,
				// });
			}
		}
	}

	getData = async (props) => {
		store.dispatch({
			type: "TOP_PERF_TOPICS_REQUEST"
		});
		try {
			let resp = await this.getTopics(props);
			store.dispatch({
				type: "TOP_PERF_TOPICS_SUCCESS",
				payload: resp.data.analytics.topics
			});
			await this.fetchTopPerforming(props);
		} catch (error) {
			store.dispatch({
				type: "TOP_PERF_TOPICS_FAILURE",
				payload: error
			});
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	fetchTopPerforming = async (props) => {
		const {
			dashboardState: { topPerfTopic, appliedDateFilter },
			productType,
			selectedBrand
		} = props;
		const variables = {
			productType,
			duration: {
				preset: appliedDateFilter.current.dateFilter
			},
			topic: topPerfTopic,
			dataFormat: "TABULAR"
		};
		if (selectedBrand !== null) {
			variables.brand = selectedBrand.id;
		}
		store.dispatch({
			type: "TOP_PERF_REQUEST"
		});
		try {
			let resp = await client.query({
				query: GET_ANALYTICS_DATASET_TAB,
				variables
			});
			store.dispatch({
				type: "TOP_PERF_SUCCESS",
				payload: resp.data.analytics.dataset[0]
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "TOP_PERF_FAILURE",
				error: error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	getTopics = async (props) => {
		const {
			productType,
			selectedBrand,
			dashboardState: { appliedDateFilter }
		} = props;
		const variables = {
			productType,
			duration: {
				preset: appliedDateFilter.current.dateFilter
			}
		};
		if (selectedBrand !== null) {
			variables.brand = selectedBrand.id;
		}
		let data = await client.query({
			query: GET_ANALYTICS_SUMMARY,
			variables: variables
		});
		return data;
	};

	selectTopic = (tpc) => {
		store.dispatch({
			type: "DASHBOARD_STATE_CHANGE",
			payload: {
				topPerfTopic: tpc.topic
			}
		});
	};

	handleViewEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewEntity.bind(this);

	render() {
		let { appliedDateFilter, topPerfTopic } = this.props.dashboardState;
		let { isMultibrandEnabled } = this.props;
		let { loading, data } = this.props.topPerfTopics;
		let productType = this.props.productType;
		return (
			<div className="dashboard-parent-container">
				<div className="dashboard-section-container">
					<div className="sidebar-container">
						<SidebarPure
							loading={loading}
							data={data}
							selectTopic={this.selectTopic}
							topic={topPerfTopic}
						/>
					</div>
					<div className="data-container">
						{this.props.topPerfTopics.error ? (
							<div className="Ta(c) C(#c0392b)">
								Failed to fetch the "Top performing" data, refresh the page
							</div>
						) : (
							<TopPerformingTable
								productType={productType}
								duration={appliedDateFilter.current.dateFilter}
								topic={topPerfTopic}
								handleNestedEntity={this.handleNestedEntity}
								isMultibrandEnabled={isMultibrandEnabled}
							/>
						)}
					</div>
				</div>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		);
	}
}

export const SidebarPlaceholder = () => (
	<div className="Pstart(10px) Pend(10px)">
		<div className="shimmer shimmer--default H(50px) Mb(10px)" />
		<div className="shimmer shimmer--default H(50px) Mb(10px)" />
		<div className="shimmer shimmer--default H(50px)" />
	</div>
);

export const SidebarPure = ({ loading, data, selectTopic, topic }) => {
	if (loading && !data) {
		return <SidebarPlaceholder />;
	}
	if (data) {
		let topics = data.filter(({ topic }) => {
			if (TOPIC_LIST_TOP_PERFORMING.indexOf(topic) >= 0) {
				return true;
			}
			return false;
		});
		return <TopPerformingSidebar clickHandler={selectTopic} topics={topics} topic={topic} />;
	} else {
		return null;
	}
};
