// action types
import { ActionTypes } from "../actions/_types";

const MERAKI_WEB_WORKSPACE_INITIAL_STATE = {
	loading: true,
	data: {},
	error: null
};
export const merakiWebWorkspace = (state = MERAKI_WEB_WORKSPACE_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MERAKI_WEB_WORKSPACE_REQUEST:
			return {
				...state,
				loading: true,
				error: null
			};
		case ActionTypes.GET_MERAKI_WEB_WORKSPACE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_MERAKI_WEB_WORKSPACE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

const MERAKI_WEB_THEMES_LIST_INITIAL_STATE = {
	loading: true,
	data: [],
	error: {}
};
export const merakiWebThemesList = (state = MERAKI_WEB_THEMES_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MERAKI_WEB_THEMES_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_MERAKI_WEB_THEMES_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_MERAKI_WEB_THEMES_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

const MERAKI_WEB_THEME_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	error: {}
};
export const merakiWebThemeDetails = (state = MERAKI_WEB_THEME_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MERAKI_WEB_THEME_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_MERAKI_WEB_THEME_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_MERAKI_WEB_THEME_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

const MERAKI_WEB_SUBSCRIPTION_INITIAL_STATE = {
	loading: true,
	data: { subscription: null },
	error: {}
};
export const merakiWebSubscription = (state = MERAKI_WEB_SUBSCRIPTION_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
