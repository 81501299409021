import React, { useCallback } from "react";

// components
import { CopyToClipboard } from "./CopyToClipboard";
import { Button } from "./Button";
import { SelectFilter } from "./SelectFilter";

// third party
import Lottie from "lottie-react";

import tickAnimation from "../../lottie/tick.json";
import crossAnimation from "../../lottie/failure.json";

// utils
import { printCurrency } from "../../atlas-utils";

export const InputField = ({
	onEnter,
	onKeyUp,
	classes,
	showLabel = false,
	labelClasses,
	currency = false,
	currencySymbol = "",
	image = false,
	imageSrc = "",
	symbol = "",
	showCopyToClipboard = false,
	copyToClipboardLabel = "",
	showIcon = false,
	showLanguages = false,
	showLoading = false,
	actionState = {},
	showActionBtn = false,
	showAction = false,
	actionText = "",
	action,
	readOnly,
	showDropdown = false,
	dropdownOptions = [],
	dropdownField,
	dropdownCurrVal = undefined,
	customDropdownLabel,
	handleDropdown,
	labelKey,
	valueKey,
	isClearable = false,
	dropdownPlaceholder = "",
	showEndResult = false,
	endResultType = "",
	showCharacterLimit = false,
	characterLimit,
	isValueUnitRequired = false,
	valueUnit = "units",
	isPaddingRequired = false,
	endIcon,
	dispatch,
	autoFocus = false,
	...rest
}) => {
	const handler = useCallback(
		(e) => {
			if (e.keyCode === 13) {
				onEnter && onEnter(e);
			} else {
				onKeyUp && onKeyUp(e);
			}
		},
		[onEnter, onKeyUp]
	);

	const ANIMATION_TYPE = {
		success: tickAnimation,
		failure: crossAnimation
	};

	return (
		<div className="at-input-field">
			{showLabel && (
				<div
					style={readOnly ? { backgroundColor: "#eaeaea", color: "#777" } : {}}
					className={"at-label " + (labelClasses || "")}
				>
					{currency && printCurrency(currencySymbol)}
					{image && (
						<span>
							<img src={imageSrc} alt="" />
						</span>
					)}
					{symbol && <span>{symbol}</span>}
				</div>
			)}
			{showDropdown && (
				<SelectFilter
					readOnly={readOnly}
					options={dropdownOptions}
					field={dropdownField}
					currValue={dropdownCurrVal}
					customDropdownLabel={customDropdownLabel}
					setFilter={handleDropdown}
					labelKey={labelKey}
					valueKey={valueKey}
					isClearable={isClearable}
					placeholder={dropdownPlaceholder}
				/>
			)}
			<input
				style={readOnly ? { backgroundColor: "#eaeaea", color: "#777" } : {}}
				autoComplete="off"
				onKeyUp={(onKeyUp || onEnter) && handler}
				readOnly={readOnly || false}
				className={
					"at-input " + (classes || "") + (symbol || isPaddingRequired ? " at-input--symbol-enabled" : "")
				}
				onWheel={(e) => e.target.blur()}
				autoFocus={autoFocus}
				{...rest}
			/>
			{showCopyToClipboard && (
				<div className="at-copy-to-clipboard">
					<CopyToClipboard content={rest.value} label={copyToClipboardLabel} showIcon={showIcon} />
				</div>
			)}
			{true && <div className="end-icon">{endIcon}</div>}
			{showActionBtn && (
				<Button
					classes={
						(actionState.status && rest.field === actionState.field ? "action-success" : "") +
						(actionState.isLoading ? " no-click" : "")
					}
					clickHandler={action}
				>
					{!actionState.isLoading
						? actionState.status && rest.field === actionState.field
							? "Saved"
							: "Save"
						: !actionState.status && rest.field === actionState.field
						? ""
						: "Save"}
				</Button>
			)}
			{showAction && actionText && !showLoading && (
				<div className="action" onClick={action}>
					{actionText}
				</div>
			)}
			{showLoading && rest.field === actionState.field && actionState.isLoading && (
				<div className="loading-container">
					<div className="at-input-loading"></div>
				</div>
			)}
			{showLoading && !showLanguages && (
				<div className="loading-container">
					<div className="at-input-loading"></div>
				</div>
			)}
			{showEndResult && (
				<Lottie
					animationData={ANIMATION_TYPE[endResultType]}
					style={{
						position: "absolute",
						height: endResultType === "failure" ? "20px" : "25px",
						width: endResultType == "failure" ? "20px" : "25px",
						top: endResultType == "failure" ? "30px" : "27px",
						right: endResultType == "failure" ? "10px" : "8px",
						background: "white"
					}}
					loop={false}
				/>
			)}
			{showCharacterLimit && (
				<div
					style={{
						color: rest?.value?.length === characterLimit ? "#ff425c" : "#7b8a9a"
					}}
					className="character-limit"
				>
					{rest?.value?.length || 0}/{characterLimit}
				</div>
			)}
			{isValueUnitRequired && <div className="input-value-unit">{valueUnit}</div>}
		</div>
	);
};
