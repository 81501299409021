import React from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { JsonInput } from "../_commons/JsonInput";

export const Configurations = ({ index, config = {}, configValue, handleForm, validations = {} }) => {
	return (
		<React.Fragment>
			{config.type === "choice" && (
				<SelectFilter
					key={index}
					title={config.title}
					options={config.choices || []}
					field={config.key}
					currValue={configValue}
					setFilter={(field, value) => handleForm(field, value)}
					readOnly={config.readOnly}
					labelKey="label"
					valueKey="value"
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				/>
			)}
			{config.type === "boolean" && (
				<Switch
					key={index}
					title={config.title}
					checked={configValue ? String(configValue).toLowerCase() === "true" : false}
					clickHandler={() => handleForm(config.key, String(!(String(configValue).toLowerCase() === "true")))}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				/>
			)}
			{config.type === "char" && (
				<InputWithLabel
					key={index}
					value={configValue || ""}
					type={config.encrypted ? "password" : "text"}
					onChange={(e) => handleForm(config.key, e.target.value)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</InputWithLabel>
			)}
			{config.type === "decimal" && (
				<InputWithLabel
					key={index}
					type={config.encrypted ? "password" : "number"}
					value={config.value || ""}
					onChange={(e) => handleForm(config.key, e.target.value ? Number(e.target.value) : null)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</InputWithLabel>
			)}
			{config.type === "int" && (
				<InputWithLabel
					key={index}
					type={config.encrypted ? "password" : "number"}
					value={config.value || ""}
					onChange={(e) => handleForm(config.key, e.target.value ? parseInt(e.target.value) : null)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</InputWithLabel>
			)}
			{config.type === "float" && (
				<InputWithLabel
					key={index}
					type={config.encrypted ? "password" : "number"}
					value={config.value || ""}
					onChange={(e) => handleForm(config.key, e.target.value ? Number(e.target.value) : null)}
					requiredLabel={config.required}
					readOnly={config.readonly}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				>
					{config.title}
				</InputWithLabel>
			)}
			{config.type === "dict" && (
				<JsonInput
					key={index}
					title={config.title}
					field={config.key}
					value={config.value || {}}
					setFilter={(field, value) => handleForm(field, value)}
					requiredLabel={config.required}
					collapsed={true}
					readOnly={config.readonly}
					displayDataTypes={true}
					showCustomTooltip={config.shortDesc ? true : false}
					tooltipInfo={config.shortDesc}
					validationMessage={validations[config.key] || ""}
				/>
			)}
		</React.Fragment>
	);
};
