import React from "react";

const MonogramGenerator = ({ size = "small", value, title = "" }) => {
	if (!value) {
		return null;
	}

	const valueArray = value.split(" ");
	const valueArrayLength = valueArray.length;

	const monochromeColorPalette = [
		{ backgroundColor: "#D9D9D9", textColor: "#5D441E" },
		{ backgroundColor: "#F8EDF8", textColor: "#753674" }
	];

	return (
		<div
			className={`monochrome-generator ${size}`}
			style={{
				backgroundColor: monochromeColorPalette[0].backgroundColor,
				color: monochromeColorPalette[0].textColor
			}}
			title={title}
		>
			{valueArrayLength === 1
				? valueArray[0].length === 1
					? valueArray[0][0] || ""
					: (valueArray[0][0] || "") + "" + (valueArray[0][1] || "")
				: (valueArray[0][0] || "") + "" + (valueArray[1][0] || "")}
		</div>
	);
};
export default MonogramGenerator;
