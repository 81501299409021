import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { WebhooksList } from "./WebhooksList";
import WebhookCreate from "./WebhookCreate";
import WebhookEdit from "./WebhookEdit";

const WebhooksContainer = ({ hasAccess = false }) => {
	return (
		<div className="webhooks-container">
			<Route path="/webhooks" component={WebhooksList} />
			<Route
				exact
				path="/webhooks/new"
				render={() => (hasAccess ? <WebhookCreate hasAccess={hasAccess} /> : <Redirect to="/webhooks" />)}
			/>
			<Route exact path="/webhooks/edit/:id" component={WebhookEdit} />
		</div>
	);
};
export default WebhooksContainer;
