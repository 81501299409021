import React, { useState } from "react";

// third party
import { Route } from "react-router-dom";

// components
import { ReconciliationPayoutsList } from "./ReconciliationPayoutsList";
import ReconciliationPayoutDetail from "./ReconciliationPayoutDetail";
import ReconciliationUpload from "./ReconciliationUpload";

const ReconciliationContainer = ({ hasAccess = false }) => {
	return (
		<div className="reconciliation-container">
			<Route path="/payout-sheets" component={ReconciliationPayoutsList} />
			<Route exact path="/payout-sheets/upload" component={ReconciliationUpload} />
			<Route path="/payout-sheets/details/:id" component={ReconciliationPayoutDetail} />
		</div>
	);
};
export default ReconciliationContainer;
