import React, { useState, useEffect } from "react";

// component
import { SelectFilter } from "../_commons/SelectFilter";
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";

// third party
import { connect } from "react-redux";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { COPY_CATALOGUE } from "../../graphql/locations";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchStores, fetchStoresDebounced } from "../../actions/actions";
import { trackEvent } from "../../atlas-utils";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../../client-config";

const CopyCatalogue = ({ entityId, brandId = null, isOpen = false, close, locationName, storesList }) => {
	const [selectedValue, setSelectedValue] = useState(null);
	const [checkedStatus, setCheckedStatus] = useState(false);
	const [copying, setCopying] = useState(false);

	useEffect(() => {
		if (isOpen) {
			fetchStores("", 20, brandId);
		}
	}, [isOpen, brandId]);

	const setSelection = (field, opt) => {
		setSelectedValue(opt);
	};

	const handleClose = (refresh = false) => {
		setSelectedValue(null);
		setCheckedStatus(false);
		setCopying(false);
		close(refresh);
	};

	const handleCheck = () => {
		setCheckedStatus((prev) => !prev);
	};

	const handleCopyCatalogue = async () => {
		setCopying(true);
		const variables = {
			fromLocation: parseInt(selectedValue?.id),
			toLocation: parseInt(entityId)
		};
		if (brandId) {
			variables.brandId = parseInt(brandId);
		}
		try {
			const resp = await client.mutate({
				mutation: COPY_CATALOGUE,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp?.data?.copyCatalogue?.status?.success) {
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_COPY_CATALOGUE_FROM_ANOTHER_LOCATION, {
					status: TRACKING_STATUS.SUCCESS
				});

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Catalogue copied successfully!",
						timeout: 2000,
						error: false
					}
				});
				handleClose(true);
			} else {
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_COPY_CATALOGUE_FROM_ANOTHER_LOCATION, {
					status: TRACKING_STATUS.FAILURE
				});

				setCopying(false);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Oops! Something went wrong. Copy catalogue failed!",
						timeout: 2000,
						error: true
					}
				});
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.LOCATION_COPY_CATALOGUE_FROM_ANOTHER_LOCATION, {
				status: TRACKING_STATUS.FAILURE
			});

			setCopying(false);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Oops! Something went wrong. Copy catalogue failed!",
					timeout: 2000,
					error: true
				}
			});
			console.log(error);
		}
	};

	return (
		<div className="copy-catalogue-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={handleCopyCatalogue}
				title="Copy Catalogue"
				subTitle={
					<div>
						Copy catalogue from another location to <span>{locationName}</span>
					</div>
				}
				submitTitle="Copy Catalogue"
				loading={copying}
				hideActions={!checkedStatus}
				isNested={true}
				submitButtonWidth={200}
			>
				<div className="form-content">
					<div className="select-filter">
						<SelectFilter
							title={"Select a location"}
							options={
								storesList.items.filter((store) => parseInt(store.id) !== parseInt(entityId)) || []
							}
							isLoading={storesList?.isLoading}
							labelKey="name"
							valueKey="id"
							isAsync={true}
							currValue={selectedValue}
							setFilter={setSelection}
							handleSearch={(text) => fetchStoresDebounced(text, 20, brandId)}
							placeholder="Select a location"
							classes={checkedStatus ? "disabled" : ""}
						/>
					</div>
					{selectedValue?.id && (
						<React.Fragment>
							<div className="alert">
								<img src="/assets/icons/alert.svg" />
								<span>
									Warning! Current catalogue configurations for <strong>{locationName}</strong> will
									be overwritten.
								</span>
							</div>
							<CheckBox checked={checkedStatus} clickHandler={handleCheck}>
								<span className="input-message">
									<span>
										Proceed with copying catalogue from{" "}
										<strong>{selectedValue?.name || "selected location"}</strong> to{" "}
										<strong>{locationName}</strong>
									</span>
								</span>
							</CheckBox>
						</React.Fragment>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	storesList: store.configItems.stores
}))(CopyCatalogue);
