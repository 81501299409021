import React, { useState, useEffect, useCallback } from "react";

// components
import { CustomTable } from "../_commons/CustomTable";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";

// constants
const columns = [
	{
		name: "",
		field: "num",
		render: (record, i, rest) => (
			<div className="table-cell num" key={i}>
				{rest.rowIndex + 1}
			</div>
		)
	},
	{
		name: "Name",
		field: "name",
		isRequired: true,
		render: (record, i, rest) => (
			<div className="table-cell name" key={i}>
				<InputWithLabel
					value={record.bizLocationNickname}
					onChange={(e) => rest.handleChange("bizLocationNickname", e.target.value, rest.rowIndex)}
					placeholder={rest.rowIndex === 0 ? "Ex: Indiranagar Store" : ""}
					validationMessage={rest.validations?.[rest.rowIndex]?.bizLocationNickname || ""}
				/>
			</div>
		)
	},
	{
		name: "Address",
		field: "address",
		render: (record, i, rest) => (
			<div className="table-cell address" key={i}>
				<InputWithLabel
					value={record.bizAddress}
					onChange={(e) => rest.handleChange("bizAddress", e.target.value, rest.rowIndex)}
					placeholder={rest.rowIndex === 0 ? "Ex: Unit#123, Forum Mall, Indiranagar" : ""}
					validationMessage={rest.validations?.[rest.rowIndex]?.bizAddress || ""}
				/>
			</div>
		)
	},
	{
		name: "City",
		field: "city",
		isRequired: true,
		render: (record, i, rest) => (
			<div className="table-cell city" key={i}>
				<SelectFilter
					options={rest.citiesList.items}
					isLoading={rest.citiesList.isLoading}
					field="city"
					currValue={record.city}
					setFilter={(field, value) => rest.handleChange(field, value, rest.rowIndex)}
					labelKey="valueForDisplay"
					valueKey="value"
					isAsync={true}
					handleSearch={rest.searchCitiesDebounced}
				/>
			</div>
		)
	},
	{
		name: "Phone",
		field: "phone",
		render: (record, i, rest) => (
			<div className="table-cell phone" key={i}>
				<InputWithLabel
					value={record.primaryContactPhone}
					onChange={(e) => rest.handleChange("primaryContactPhone", e.target.value || null, rest.rowIndex)}
					classes={"at-input--label"}
					showLabel={true}
					image={true}
					imageSrc={"/assets/icons/icon-phone-small.svg"}
					placeholder={rest.rowIndex === 0 ? "Ex: 9876543210" : ""}
					validationMessage={rest.validations?.[rest.rowIndex]?.primaryContactPhone || ""}
				/>
			</div>
		)
	},
	{
		name: "Email",
		field: "email",
		render: (record, i, rest) => (
			<div className="table-cell email" key={i}>
				<InputWithLabel
					value={record.primaryContactEmail}
					onChange={(e) => rest.handleChange("primaryContactEmail", e.target.value, rest.rowIndex)}
					classes={"at-input--label"}
					showLabel={true}
					image={true}
					imageSrc={"/assets/icons/icon-mail.svg"}
					placeholder={rest.rowIndex === 0 ? "Ex: hello@gmail.com" : ""}
					validationMessage={rest.validations?.[rest.rowIndex]?.primaryContactEmail || ""}
				/>
			</div>
		)
	}
];

const MultiLocationDetails = ({
	formData = {},
	handleFormData,
	validations = {},
	setMultiFormValidations,
	citiesList,
	searchCitiesDebounced,
	isMultibrandEnabled = false
}) => {
	const [addMoreRows, setAddMoreRows] = useState(5);

	const handleChange = useCallback(
		(field, value, index) => {
			const multiLocationDetails = formData.multiLocationDetails.map((loc, i) => {
				let location = { ...loc };
				if (i === index) {
					location[field] = value;
				}
				return location;
			});
			handleFormData({ multiLocationDetails: multiLocationDetails });
			// reset validations
			let updatedMultiFormValidations = { ...validations };
			updatedMultiFormValidations[index] = {
				...(updatedMultiFormValidations[index] ?? {}),
				[field]: ""
			};
			setMultiFormValidations(updatedMultiFormValidations);
		},
		[formData, handleFormData, validations, setMultiFormValidations]
	);

	const handleAddRows = useCallback(() => {
		if (addMoreRows) {
			const newLocation = {
				bizLocationNickname: "",
				bizAddress: "",
				city: null,
				primaryContactPhone: null,
				primaryContactEmail: "",
				sortOrder: 0,
				merchantBizLocationId: "-1",
				locLatitude: null,
				locLongitude: null,
				orderDeliveryRadius: null,
				polygons: [],
				isMerakiDetailsSet: false,
				brands: [],
				platforms: !isMultibrandEnabled
					? formData.platforms?.map((plf) => ({ ...plf, platformId: "", platformUrl: "" })) || []
					: []
			};
			const newLocations = [...Array(addMoreRows).keys()].map((loc) => newLocation);
			handleFormData({ multiLocationDetails: [...formData.multiLocationDetails, ...newLocations] });
		}
	}, [formData, handleFormData, addMoreRows, isMultibrandEnabled]);

	useEffect(() => {
		if (formData?.multiLocationDetails?.length === 0) {
			handleAddRows();
		}
	}, []);

	return (
		<div className="multi-location-details">
			<div className="section-title">Fill in details</div>
			<CustomTable
				columns={columns}
				data={formData?.multiLocationDetails || []}
				handleChange={handleChange}
				citiesList={citiesList}
				searchCitiesDebounced={searchCitiesDebounced}
				validations={validations}
				classes="multi-locations-list-table-container"
			/>
			<div className={"add-rows" + (formData?.multiLocationDetails?.length === 100 ? " disabled" : "")}>
				<InputWithLabel
					value={addMoreRows}
					type="number"
					onChange={(e) =>
						setAddMoreRows(
							e.target.value && parseInt(e.target.value) <= 100 ? parseInt(e.target.value) : ""
						)
					}
				>
					Add more rows
				</InputWithLabel>
				<Button classes={`W(60px)` + (!addMoreRows ? " disabled" : "")} clickHandler={handleAddRows}>
					Add
				</Button>
			</div>
		</div>
	);
};
export default MultiLocationDetails;
