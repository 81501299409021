import React from "react";

const TableLocationBrand = ({ tableName }) => {
	const tableNameDisplay = tableName[0].toUpperCase() + tableName.slice(1);
	return (
		<div>
			<span className="header-text-location">{tableNameDisplay}</span>
		</div>
	);
};

export default TableLocationBrand;
