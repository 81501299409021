import React from "react";

// components
import OverlaySidebar from "../_commons/OverlaySidebar";
import { FiltersPreview } from "../CreateCampaign/CreateCampaignPreview";

// third party
import moment from "moment";

// utils
import { getReadableCron, formatDate, commifyNumbers } from "../../atlas-utils";

// constants
import { MEDIUM_OPTIONS, CONVERSION_TRACKING_TYPE } from "../../client-config";

export const InfoSidebar = ({ showCampaignDetail, handleShowCampaignDetail, data = {} }) => {
	const {
		name,
		cronExpression,
		scheduledTime,
		runTime,
		startAt,
		endAt,
		executionInfo,
		medium,
		message,
		totalTargeted,
		conversionConfig,
		segment
	} = data;
	// generate human readable timestamp from cron expression
	const readableCron = cronExpression ? getReadableCron(cronExpression) : "";
	// recurring campaign execution info
	const executionInfoArr = executionInfo && executionInfo.objects ? executionInfo.objects : [];
	// campaign medium label
	let mediumStr = "";
	if (medium) {
		const mediumObj = MEDIUM_OPTIONS.find((m) => m.value === medium);
		mediumStr = (mediumObj && mediumObj.label) || medium;
	}
	// get conversion tracking type
	let conversionTrackingType = "";
	if (conversionConfig && conversionConfig.event) {
		const convType = CONVERSION_TRACKING_TYPE.find((conv) => conv.value == conversionConfig.event);
		conversionTrackingType = convType ? convType.title : "Not available";
	}

	return (
		<OverlaySidebar showOverlaySidebar={showCampaignDetail} close={() => handleShowCampaignDetail(false)}>
			<div className="campaign-detail-container">
				<div className="basic-info">
					<img src="/assets/icons/info-black.png" />
					Basic info
				</div>
				<div className="meta-detail">
					<div>
						<div>Title</div>
						<div>{name}</div>
					</div>
					<div>
						<div>Execution type</div>
						<div>{readableCron ? "Recurring - " + readableCron : "One time"}</div>
					</div>
					{scheduledTime && (
						<div>
							<div>Scheduled at</div>
							<div>{formatDate(scheduledTime)}</div>
						</div>
					)}
					<div>
						<div>{readableCron ? "Last e" : " E"}xecuted at</div>
						<div>{formatDate(runTime)}</div>
					</div>
					{readableCron && (
						<React.Fragment>
							<div>
								<div>Starts on</div>
								<div>{moment(startAt).format("DD-MMM-YYYY")}</div>
							</div>
							<div>
								<div>Ends on</div>
								<div>{moment(endAt).format("DD-MMM-YYYY")}</div>
							</div>
						</React.Fragment>
					)}
					{executionInfoArr.length > 0 && (
						<div>
							<div>Execution Info</div>
							{readableCron ? (
								<div>
									{executionInfoArr.map((exec, i) => (
										<div className="Mb(10px)">
											<div>Round {executionInfoArr.length - i}:</div>
											<div>
												Time - <span className="C(#7b8a9a)">{formatDate(exec.runTime)}</span>
											</div>
											<div>
												Status -{" "}
												<span className="C(#7b8a9a)">
													{exec.hasError ? "Failed!" : "Successful"}
												</span>
											</div>
											{exec.hasError && (
												<div>
													Reason -
													<span className="C(#7b8a9a)">
														{exec.errorMsg
															? exec.errorMsg
															: "Error Occurred!! Please contact urbanpiper support regarding this."}
													</span>
												</div>
											)}
										</div>
									))}
								</div>
							) : (
								<div>
									Status -{" "}
									{executionInfoArr[0].hasError ? executionInfoArr[0].hasError : "Successful"}
								</div>
							)}
						</div>
					)}
					<div>
						<div>Channel</div>
						<div>{mediumStr}</div>
					</div>
					<div>
						<div>Content</div>
						<div
							style={{
								whiteSpace: "pre-wrap",
								wordBreak: "break-word"
							}}
						>
							{message || "Email (Preview not available)"}
						</div>
					</div>
					<div>
						<div>Targeted users</div>
						<div>{commifyNumbers(totalTargeted)}</div>
					</div>
					<div>
						<div>Conversion tracking</div>
						<div>{conversionTrackingType}</div>
					</div>
					{conversionConfig && (
						<div>
							<div>Conversion period</div>
							<div>
								{conversionConfig.days} {conversionConfig.days == 1 ? "day" : "days"}
							</div>
						</div>
					)}
					<div>
						<div>Segment filters</div>
						{Object.keys(JSON.parse(segment?.filters)).length > 0 ? (
							<FiltersPreview filters={JSON.parse(segment?.filters)} initialFiltersCount={5} />
						) : (
							<div>No filters applied!</div>
						)}
					</div>
				</div>
			</div>
		</OverlaySidebar>
	);
};
