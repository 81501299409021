import React, { useState, useEffect, useCallback } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/ItemEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, scroll, trackEvent } from "../atlas-utils";

// third party
import { debounce } from "lodash";

// actions
import { fetchItemCategories, fetchCiItemGroups, fetchTimingGroups, fetchFulfillmentModes } from "../actions/actions";
import { fetchItemsList, verifyHandle } from "../actions/items";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_ITEM, GET_ITEM_TAGS } from "../graphql/items";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../client-config";

const ItemCreate = ({
	hasAccess = false,
	isNested = false,
	isViewedFromMenu,
	isOpen = false,
	close = () => {},
	temporaryPersistedData = {}
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [itemTags, setItemTags] = useState([]);
	const [itemDataCount, setItemDataCount] = useState(0);
	const [data, setData] = useState({
		itemTitle: "",
		merchantRefId: "",
		crmTitle: "",
		itemDiscreteCategory: isViewedFromMenu
			? {
					id: temporaryPersistedData?.addItemsTempData?.categoryDetail?.id,
					name: temporaryPersistedData?.addItemsTempData?.categoryDetail?.name,
					isActive: true
			  }
			: undefined,
		foodType: undefined,
		sortOrder: 0,
		isRecommended: false,
		itemPrice: 0,
		markupPrice: 0,
		itemDesc: "",
		tags: [],
		fulfillmentModes: [],
		serves: 1,
		itemWeight: null,
		priceDescriptor: "",
		preOrderEnabled: false,
		timingsGroup: null,
		itemGroups: { objects: [] }
	});

	useEffect(() => {
		if (isViewedFromMenu) {
			if (isOpen) {
				setFormState(true);
			}
			return;
		}
		setTimeout(() => setFormState(true), 60);
		fetchItemCategories();
		fetchCiItemGroups("", false);
	}, [isOpen]);

	useEffect(() => {
		fetchTimingGroups();
		fetchFulfillmentModes();
	}, []);

	const handleCancel = (refresh = false, createdItemId) => {
		setFormState(false);
		if (isViewedFromMenu) {
			close(refresh, createdItemId);
			setData({
				itemTitle: "",
				merchantRefId: "",
				crmTitle: "",
				itemDiscreteCategory: undefined,
				foodType: undefined,
				sortOrder: 0,
				isRecommended: false,
				itemPrice: 0,
				markupPrice: 0,
				itemDesc: "",
				tags: [],
				fulfillmentModes: [],
				serves: 1,
				itemWeight: null,
				priceDescriptor: "",
				preOrderEnabled: false,
				timingsGroup: null,
				itemGroups: { objects: [] }
			});
			return;
		}
		fetchItemsList();
		setTimeout(() => {
			history.push("/items");
		}, 100);
	};

	const applyFilter = useCallback(
		debounce(async (value) => {
			const res = await verifyHandle(value);
			setItemDataCount(res);
		}, 500),
		[verifyHandle]
	);

	const fetchItemTags = async (searchText = "") => {
		try {
			const variables = {
				limit: 200,
				offset: 0,
				sort: { field: "title", order: "ASC" }
			};
			if (searchText !== "") {
				variables.search = [{ key: "default", value: searchText }];
			}
			const resp = await client.query({
				query: GET_ITEM_TAGS,
				variables
			});
			// get unique tag groups and its tags
			let tagGroups = {};
			resp.data.itemTags.objects.forEach((tag) => {
				tag = {
					...tag,
					titleForDisplay: tag?.tagGroup ? `${tag.title} (${tag?.tagGroup?.title})` : `${tag.title} (General)`
				};
				if (tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"]) {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"].push(tag);
				} else {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"] = [tag];
				}
			});
			// combine all tag groups' tags using concat
			// return { options: [].concat.apply([], Object.values(tagGroups)) };
			return [].concat.apply([], Object.values(tagGroups));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			return { options: [] };
		}
	};

	const handleForm = (field, value) => {
		// enforce positive value for these fields
		if (["sortOrder", "itemPrice", "markupPrice"].includes(field)) {
			if (value && value < 0) {
				value = 0;
			}
		}
		setData({
			...data,
			[field]: field === "itemGroups" ? { objects: value } : value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		if (itemDataCount === 0) {
			setConfirmLoading(true);
			try {
				const variables = {
					...data
				};
				if (variables.itemDiscreteCategory) {
					variables.itemDiscreteCategory = parseInt(variables.itemDiscreteCategory.id);
				}
				if (variables.foodType) {
					variables.foodType = variables.foodType.value;
				}
				if (variables.fulfillmentModes.length) {
					variables.fulfillmentModes = variables.fulfillmentModes.map((fl) => fl.id);
				}
				if (variables.tags.length) {
					variables.tags = variables.tags.map((tag) => tag.title);
				}
				if (variables.timingsGroup) {
					variables.timingsGroup = parseInt(variables.timingsGroup.id);
				}
				if (variables.itemGroups) {
					variables.itemGroups =
						variables?.itemGroups?.objects.map((itemGroup) => parseInt(itemGroup.id)) || [];
				}
				if (variables.merchantRefId === "") {
					variables.merchantRefId = "-1";
				}
				if (!variables.serves) {
					variables.serves = null;
				}
				const resp = await client.mutate({
					mutation: CREATE_ITEM,
					variables
				});
				if (resp.data.saveItem.status.success) {
					trackEvent(TRACKING_EVENT_NAMES.NEW_ITEM_CREATION, {
						status: TRACKING_STATUS.SUCCESS
					});

					setConfirmLoading(false);
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Item created",
							timeout: 5000,
							error: false
						}
					});
					store.dispatch({
						type: ActionTypes.UPDATE_CATALOGUE_ITEMS_LIST,
						payload: resp.data.saveItem.object
					});
					if (isViewedFromMenu) {
						handleCancel(true, resp.data.saveItem.object.id);
					} else {
						history.push(`/items/edit/${resp.data.saveItem.object.id}`);
					}
				} else {
					trackEvent(TRACKING_EVENT_NAMES.NEW_ITEM_CREATION, {
						status: TRACKING_STATUS.FAILURE
					});

					setConfirmLoading(false);
					setError(parseErrorMessages(resp.data.saveItem.status.messages));
				}
			} catch (error) {
				trackEvent(TRACKING_EVENT_NAMES.NEW_ITEM_CREATION, {
					status: TRACKING_STATUS.FAILURE
				});

				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
				setConfirmLoading(false);
			}
		}
	};

	const scrollDown = () => {
		setTimeout(() => {
			const formContainer = document.getElementsByClassName("form-container")[0];
			if (formContainer) {
				scroll({ top: formContainer.scrollHeight, left: 0 }, formContainer);
			}
		}, 275);
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Item"
			subTitle="Create a new item"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
			isNested={isNested}
		>
			<div className="form-content">
				<BasicInfo
					data={data}
					handleForm={handleForm}
					validations={error.fields || {}}
					count={itemDataCount}
					itemTags={itemTags}
					scrollDown={scrollDown}
					showDropdownNull={false}
					readOnly={!hasAccess}
					showCascade={false}
					fetchItemTags={fetchItemTags}
				/>
			</div>
		</FormSidebar>
	);
};
export default ItemCreate;
