import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import { GalleriesList } from "./GalleriesList";
import GalleryCreate from "./GalleryCreate";
import GalleryEdit from "./GalleryEdit";

const GalleryContainer = ({ hasAccess = false }) => {
	return (
		<div className="gallery-container">
			<Route path="/gallery" component={GalleriesList} />
			<Route
				exact
				path="/gallery/new"
				render={() => (hasAccess ? <GalleryCreate hasAccess={hasAccess} /> : <Redirect to="/gallery" />)}
			/>
			<Route exact path="/gallery/edit/:id" component={GalleryEdit} />
		</div>
	);
};
export default GalleryContainer;
