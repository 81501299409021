import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { lS } from "../../../atlas-utils";

const WaitingPage = ({ periscopeDataResponse }) => {
	const history = useHistory();
	const goToPeriscopeHome = () => {
		history.push("/periscope/home");
	};

	const goToOrders = () => {
		history.push("/orders");
	};
	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;

	useEffect(() => {
		const localStorage = lS.get("periscope");
		if (localStorage.bizSubscription) {
			goToPeriscopeHome();
		}
	}, []);

	useEffect(() => {
		if (periscopeDataFetch) {
			goToPeriscopeHome();
		}
	}, [periscopeDataFetch]);
	return (
		<div className="periscope-container">
			<div className="section-container-common waiting-page">
				<div className="heading-periscope">Periscope</div>
				<div className="waiting-page-container">
					<img src="/assets/empty_states/graphics-loading.svg" className="graphics-loading" />
					<div className="waiting-text-div">
						<div className="text-div">
							<span className="header-text">We are gathering data from your selected stores.</span>
							<span className="description">
								This process may take a few minutes. We'll notify you once your dashboard is ready!
							</span>
						</div>
						<button className="back-to-home" onClick={goToOrders}>
							Back to Home
						</button>
						<div className="enquiry">
							<img src="/assets/periscope/any-questions.svg" className="question-img" />
							<span className="questions">Have questions?</span>
							<span className="about">Learn more about Periscope</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	periscopeDataResponse: state.periscopeDataResponse
});
export default connect(mapStateToProps)(WaitingPage);
