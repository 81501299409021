import React from "react";

// third party
import Lottie from "lottie-react";

// component
import { Button } from "../_commons/Button";
import SuccessIcon from "../_icons/SuccessIcon";
import FailureIcon from "../_icons/FailureIcon";

// utils
import history from "../../history";

// lottie animation
import confettiAnimation from "../../lottie/confetti.json";

const EndResult = ({ endResult, createBrand }) => {
	const goToLocations = () => {
		history.push("/locations");
	};

	const goToBrands = () => {
		history.push("/brands");
	};

	if (endResult === "success") {
		return (
			<div className="brand-creation-flow--end-result">
				<div className="icon-text-container">
					<div className="icon">
						<SuccessIcon />
					</div>
					<div className="text">
						<div className="main-text">You have successfully created a new brand!</div>
						<div className="sub-text">Set-up catalogue for your brand in locations</div>
					</div>
				</div>
				<div className="btn-container">
					<Button clickHandler={goToLocations}>Go to Locations</Button>
					<Button type="secondary" clickHandler={goToBrands}>
						Back to Brands
					</Button>
				</div>
				<Lottie
					animationData={confettiAnimation}
					style={{
						position: "absolute",
						height: "100%",
						width: "100%"
					}}
				/>
			</div>
		);
	}

	if (endResult === "failure") {
		return (
			<div className="brand-creation-flow--end-result">
				<div className="icon-text-container">
					<div className="icon">
						<FailureIcon />
					</div>
					<div className="text">
						<div className="main-text">There was an error!</div>
						<div className="sub-text">Your brand was not created yet. Please try again later.</div>
					</div>
				</div>
				<div className="btn-container">
					<Button clickHandler={() => createBrand()}>Try Again</Button>
					<Button type="secondary" clickHandler={() => goToBrands()}>
						Back to Brands
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className="P(10px) shimmer-container">
			<div className="shimmer H(60px) Mb(10px)" />
			<div className="shimmer H(60px) Mb(10px)" />
		</div>
	);
};

export default EndResult;
