import React, { Component } from "react";
import { connect } from "react-redux";

// components
import { RadioButton } from "../_commons/RadioButton";
import { InputField } from "../_commons/InputField";

// third party
import Select from "react-select-animated";
import DatePicker from "react-datepicker";
import moment from "moment";

// client
import { store } from "../../store/configureStore";

// utils
import { parseCron } from "../../atlas-utils";

// constants - TODO: move to a seperate file
import { CRON_WEEK_OPTIONS } from "../../client-config";
export const CAMPAIGN_EXECUTION_OPTIONS = [
	{ label: "One time", value: "ONE_OFF" },
	{ label: "Recurring", value: "RECURRING" }
];

window.moment = moment;

const HOURS = [];
const MINUTES = [];
const MONTH_DAYS = [];
for (let i = 0; i < 24; i++) {
	let obj = {
		label: i < 10 ? `0${i}` : `${i}`,
		value: i
	};
	HOURS.push(obj);
}
for (let i = 0; i < 60; i++) {
	let obj = {
		label: i < 10 ? `0${i}` : `${i}`,
		value: i
	};
	MINUTES.push(obj);
}
for (let i = 1; i < 32; i++) {
	let obj = {
		label: i,
		value: i
	};
	MONTH_DAYS.push(obj);
}

@connect((store) => ({
	createCampaign: store.createCampaign
}))
class CreateCampaignWhen extends Component {
	constructor(props) {
		super(props);
	}
	switchExecutionType(selectedExecutionType) {
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				campaignExecutionType: selectedExecutionType.value
			}
		});
	}

	render() {
		const { createCampaign, isFetchingDetails } = this.props;
		const campaignExecutionType = createCampaign.campaignExecutionType;
		return (
			<div className={"create-campaign-section " + (isFetchingDetails ? "disabled" : "")}>
				<div className="create-campaign-header">
					<Header />
				</div>
				<div className="create-campaign-forms-container">
					<div className="sidebar-container">
						<Sidebar
							mediumOptions={CAMPAIGN_EXECUTION_OPTIONS}
							selectedMedium={campaignExecutionType}
							switchMedium={this.switchExecutionType}
						/>
					</div>
					<div className="data-container">
						{campaignExecutionType === CAMPAIGN_EXECUTION_OPTIONS[0].value && (
							<OneTime {...createCampaign} />
						)}
						{campaignExecutionType === CAMPAIGN_EXECUTION_OPTIONS[1].value && (
							<Recurring {...createCampaign} />
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default CreateCampaignWhen;

const Header = () => (
	<div>
		<div className="header-text">When do you want to run this campaign?</div>
		<div className="subheader-text">
			Select the nature of this campaign, and tell us when you want to run this campaign?
		</div>
	</div>
);

const Sidebar = ({ mediumOptions, selectedMedium, switchMedium }) => {
	return (
		<div className="at-side-bar">
			{mediumOptions.map((medium, i) => (
				<div
					className={(medium.value === selectedMedium ? "selected " : "") + "selectable sidebar-row"}
					key={i}
					onClick={() => switchMedium(medium)}
				>
					<RadioButton checked={medium.value === selectedMedium ? true : false}>
						<div className="text text--small">{medium.label}</div>
					</RadioButton>
				</div>
			))}
		</div>
	);
};

class OneTime extends React.Component {
	constructor(props) {
		super(props);
		// we need to set minTime properly
		// set minTime to current time, but
		// if the scheduled date is after today then allow all times to be selected
		let minTime = moment();
		if (this.props.scheduledTime) {
			const scheduledTime = moment(new Date(this.props.scheduledTime));
			if (scheduledTime.isAfter(minTime, "day")) {
				minTime = moment().startOf("day");
			}
		}

		this.state = {
			scheduledFor: "NOW",
			minTime,
			maxTime: moment().endOf("day")
		};
	}

	roundDate = (date, duration, method) => {
		duration = duration || moment.duration(15, "minutes");
		method = method || "ceil";
		return moment(Math[method](date.valueOf() / duration.valueOf()) * duration.valueOf());
	};

	changeSF = (e) => {
		this.setState({
			scheduledFor: e.value
		});
		if (e.value == "NOW") {
			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					scheduledTime: null
				}
			});
		} else {
			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					scheduledTime: this.roundDate(moment()).valueOf()
				}
			});
		}
	};

	handleChange = (date) => {
		// if selected date is after today then allow all the times to be selected
		// otherwise set minTime to now
		if (date.isAfter(moment(), "day")) {
			this.setState({
				minTime: moment().startOf("day")
			});
		} else {
			this.setState({
				minTime: moment()
			});
		}

		// if selected date is in past then set time to now
		// otherwise set scheduledTime according to user input
		if (date.isBefore(moment())) {
			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					scheduledTime: this.roundDate(moment()).valueOf()
				}
			});
		} else {
			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					scheduledTime: date.valueOf()
				}
			});
		}
	};

	componentWillReceiveProps(nextProps) {
		if (
			this.props.scheduledTime != nextProps.scheduledTime &&
			nextProps.scheduledTime > new Date().getTime() &&
			this.state.scheduledFor === "NOW"
		) {
			this.setState({
				scheduledFor: "LATER"
			});
		}
	}

	componentDidMount() {
		if (this.props.scheduledTime > new Date().getTime()) {
			this.setState({
				scheduledFor: "LATER"
			});
		}
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: {
				scheduledTime: this.props.scheduledTime,
				cronExpression: undefined,
				startAt: undefined,
				endAt: undefined
			}
		});
	}

	render() {
		return (
			<div>
				<div className="campaign--input-with-label">
					<div className="label">Schedule for</div>
					<div className="input-block-container">
						<Select
							searchable={false}
							className="at-dropdown"
							options={[
								{
									label: "Now",
									value: "NOW"
								},
								{
									label: "Later",
									value: "LATER"
								}
							]}
							value={this.state.scheduledFor}
							clearable={false}
							onChange={(e) => {
								this.changeSF(e);
							}}
						/>
					</div>
				</div>
				{this.state.scheduledFor == "LATER" ? (
					<div className="campaign--input-with-label Mt(25px)">
						<div className="label">Select date and time</div>
						<div className="input-container at-input-container">
							<DatePicker
								customInput={<CustomDatePickerInput />}
								selected={moment(new Date(this.props.scheduledTime || new Date().getTime()))}
								onChange={this.handleChange}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								dateFormat="DD MMM, YYYY h:mm A"
								timeCaption="Time"
								minDate={moment()}
								minTime={this.state.minTime}
								maxTime={this.state.maxTime}
							/>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

class Recurring extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			s1: S1_OPTIONS[0].value,
			s2: null,
			s3: 1
		};
	}
	componentDidMount() {
		// lets get the cron expression and parse it.
		if (this.props.cronExpression) {
			//parse the cron exp and retain state
			let cron = this.props.cronExpression;
			const cronObj = parseCron(cron);
			let { dayOfMonth, month, dayofWeek } = cronObj;

			let _state = {};
			if (month == "*" && dayOfMonth == "*/1") {
				_state.s1 = "DAY";
			} else if (month == "*/1" && dayofWeek == "*") {
				_state.s1 = "MONTH";
				_state.s3 = dayOfMonth;
			} else {
				_state.s1 = "WEEK";
				_state.s2 = dayofWeek;
			}
			this.setState(_state);
		} else {
			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					cronExpression: "30 12 */1 * *",
					startAt: new Date().getTime(),
					endAt: new Date().getTime() + 48 * 3600 * 1000,
					scheduledTime: null
				}
			});
		}
	}
	handleCron(e, t) {
		let cron = this.props.cronExpression;

		if (cron) {
			let l = parseCron(cron);
			if (t == "s1") {
				let val = this.state.s1;
				if (val == "DAY") {
					l.dayOfMonth = "*/1";
					l.month = "*";
					l.dayofWeek = "*";
				} else if (val == "MONTH") {
					l.dayOfMonth = this.state.s3;
					l.month = "*/1";
					l.dayofWeek = "*";
				} else if (val == "WEEK") {
					l.dayOfMonth = "*/1";
					l.month = "*/1";
					l.dayofWeek = this.state.s2;
				}
			} else {
				if (t == "s2") {
					l.dayofWeek = this.state.s2;
				}
				if (t == "s3") {
					l.dayOfMonth = this.state.s3;
				}
			}
			let newCron = createCron(l);

			store.dispatch({
				type: "CREATE_CAMPAIGN_STATE_UPDATE",
				payload: {
					cronExpression: newCron
				}
			});
		}
	}
	handleChange = (e, t) => {
		switch (t) {
			case "s1":
				this.setState(
					{
						s1: e.value,
						s2: getS2_OPTIONS(e.value)[0] && getS2_OPTIONS(e.value)[0].value
					},
					() => {
						this.handleCron(e, t);
					}
				);
				break;
			case "s2":
				this.setState(
					{
						s2: e.value
					},
					() => {
						this.handleCron(e, t);
					}
				);

				break;
			case "s3":
				let input = e.value;
				this.setState(
					{
						s3: input
					},
					() => {
						this.handleCron(e, t);
					}
				);
				break;
			case "cron":
				// console.log(e);
				store.dispatch({
					type: "CREATE_CAMPAIGN_STATE_UPDATE",
					payload: {
						cronExpression: e
					}
				});
				break;
			default:
		}
	};

	handleStartEndDate(date, type) {
		let payload = {
			[type]: date.valueOf()
		};

		if (type == "startAt" && this.props.endAt < date.valueOf()) {
			payload.endAt = date.valueOf() + 48 * 3600 * 1000;
		}
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: payload
		});
	}

	render() {
		return (
			<div>
				<div className="campaign--input-with-label">
					<div className="label">Set interval</div>
					<div className="cron-exp-container">
						<span>Every</span>
						<span>
							<CRON_SELECT_COMP
								type="s1"
								value={this.state.s1}
								options={S1_OPTIONS}
								handleChange={this.handleChange}
							/>
						</span>
						{this.state.s1 == "DAY" ? (
							""
						) : (
							<span>
								<span>on</span>
								{this.state.s1 == "WEEK" ? (
									<span>
										<CRON_SELECT_COMP
											type="s2"
											value={this.state.s2}
											options={getS2_OPTIONS(this.state.s1)}
											handleChange={this.handleChange}
										/>
									</span>
								) : (
									<span className="at-input-container">
										<Select
											style={{
												width: "50px"
											}}
											searchable={false}
											className="at-dropdown"
											options={MONTH_DAYS}
											value={this.state.s3}
											clearable={false}
											onChange={(e) => {
												this.handleChange(e, "s3");
											}}
										/>
									</span>
								)}
							</span>
						)}
						<span>at</span>
						<span className="at-input-container">
							{this.props.cronExpression ? (
								<TimeSelectorForCampaign
									cronExpression={this.props.cronExpression}
									handleChange={this.handleChange}
								/>
							) : null}
						</span>
					</div>
				</div>
				{this.props.startAt && this.props.endAt ? (
					<div>
						<div className="campaign--input-with-label Mt(30px)">
							<div className="label">Start from</div>
							<div className="input-block-container  at-input-container">
								<DatePicker
									customInput={<CustomDatePickerInput />}
									selected={moment(this.props.startAt)}
									onChange={(e) => this.handleStartEndDate(e, "startAt")}
									dateFormat="DD MMM, YYYY"
									minDate={moment()}
								/>
							</div>
						</div>
						<div className="campaign--input-with-label">
							<div className="label">Stop after</div>
							<div className="input-block-container at-input-container">
								<DatePicker
									customInput={<CustomDatePickerInput />}
									selected={moment(this.props.endAt)}
									onChange={(e) => this.handleStartEndDate(e, "endAt")}
									dateFormat="DD MMM, YYYY"
									minDate={moment(this.props.startAt)}
								/>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

class TimeSelectorForCampaign extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hr: 0,
			min: 0,
			cronExpression: this.props.cronExpression
		};
	}
	componentDidMount() {
		let parsedCron = parseCron(this.state.cronExpression);
		this.setState(parsedCron);
	}

	componentWillReceiveProps(newProps) {
		if (this.props.cronExpression != newProps.cronExpression) {
			this.setState({
				cronExpression: newProps.cronExpression
			});
		}
	}

	handleChange = (e, type) => {
		this.setState({
			[type]: e.value
		});

		let cronObj = parseCron(this.state.cronExpression);
		switch (type) {
			case "hr":
				cronObj.hr = e.value;
				break;
			case "min":
				cronObj.min = e.value;
				break;
			default:
		}

		let cronExpression = createCron(cronObj);
		this.setState({
			cronExpression
		});
		this.props.handleChange(cronExpression, "cron");
	};

	render() {
		let props = this.props;
		return (
			<div className="time-picker-campaign">
				<div className="hrs">
					<Select
						style={{
							width: "50px"
						}}
						searchable={false}
						className="at-dropdown"
						options={HOURS}
						value={this.state.hr}
						clearable={false}
						onChange={(e) => {
							this.handleChange(e, "hr");
						}}
					/>
				</div>
				<div>:</div>
				<div className="min">
					<Select
						style={{
							width: "50px"
						}}
						searchable={false}
						className="at-dropdown"
						options={MINUTES}
						value={this.state.min}
						clearable={false}
						onChange={(e) => {
							this.handleChange(e, "min");
						}}
					/>
				</div>
			</div>
		);
	}
}

const CustomDatePickerInput = (props) => {
	return (
		<InputField
			style={{
				width: "150px"
			}}
			readOnly={true}
			onClick={props.onClick}
			value={props.value}
		/>
	);
};

const CRON_SELECT_COMP = (props) => {
	return (
		<Select
			style={{
				width: "150px"
			}}
			searchable={false}
			className="at-dropdown"
			options={props.options}
			value={props.value}
			clearable={false}
			onChange={(e) => {
				props.handleChange(e, props.type);
			}}
		/>
	);
};

const S1_OPTIONS = [
	{
		label: "Day",
		value: "DAY"
	},
	{
		label: "Week",
		value: "WEEK"
	},
	{
		label: "Month",
		value: "MONTH"
	}
];

const MONTH_OPTIONS = [
	{
		label: "Every day",
		value: "EVERY_DAY_OF_THE_MONTH"
	},
	{
		label: "Select a day",
		value: "SELECT_A_DAY"
	}
];

let getS2_OPTIONS = (filter) => {
	switch (filter) {
		case "MONTH":
			return MONTH_OPTIONS;
		case "WEEK":
			return CRON_WEEK_OPTIONS;
		default:
			return [];
	}
};

const createCron = (values) => {
	let { min, hr, dayOfMonth, month, dayofWeek } = values;
	return `${min === undefined ? "*" : min} ${hr === undefined ? "*" : hr} ${
		dayOfMonth === undefined ? "*/1" : dayOfMonth
	} ${month === undefined ? "*" : month} ${dayofWeek === undefined ? "*" : dayofWeek}`;
};
