import React from "react";

const SpecificItemLocationCell = ({ props }) => {
	const rating = props.value.rating?.toFixed(2).replace(/(\.0+|(\.[1-9]*[1-9])0+)$/, "$2");
	return (
		<div className="specificitem-location-cell">
			<span className="specificitem-location-text">{props.value.location_name}</span>
			<div className="specificitem-rating-display">
				<span className="specificitem-ratings-text">{rating}</span>
				<img src="/assets/periscope/star-rating.svg" className="star" />
			</div>
		</div>
	);
};

export default SpecificItemLocationCell;
