import React, { useState, useMemo } from "react";

// components
import DropdownCustom from "../_commons/DropdownCustom";

// config
import { MERAKI_WEB_EDITOR_PAGES_LIST } from "../../client-config";

export const PageSelector = ({ pages = [], selectedPage = {}, handleSelectPage, handleCreatePage }) => {
	const [isOpen, setIsOpen] = useState(false);

	const themePages = useMemo(() => {
		return pages.filter((page) => page.type === "theme");
	}, [pages]);

	const customPages = useMemo(() => {
		return pages.filter((page) => page.type === "custom");
	}, [pages]);

	const handleDropdown = (e) => {
		e.stopPropagation();
		setIsOpen(!isOpen);
	};

	const selectPage = (page) => {
		handleSelectPage(page);
		setIsOpen(false);
	};

	const createPage = () => {
		handleCreatePage();
		setIsOpen(false);
	};

	return (
		<div className="page-selector-container">
			<DropdownCustom
				selected={selectedPage.name || "--"}
				isOpen={isOpen}
				handleClick={handleDropdown}
				handleOutsideClick={() => setIsOpen(false)}
				clickEvent="mousedown"
			>
				<div className="pages-list-container">
					<div className="page-group-title">THEME PAGES</div>
					<div>
						{themePages.map((page, i) => (
							<div
								key={i}
								data-selected={selectedPage._id === page._id ? true : false}
								className="page-group-item"
								onClick={() => selectPage(page)}
							>
								<img src={MERAKI_WEB_EDITOR_PAGES_LIST[page._id] || "/assets/meraki/pages/menu.svg"} />
								<div>{page.name}</div>
							</div>
						))}
					</div>
					{customPages.length > 0 && (
						<React.Fragment>
							<div className="page-group-title">CUSTOM PAGES</div>
							<div>
								{customPages.map((page, i) => (
									<div
										key={i}
										data-selected={selectedPage._id === page._id ? true : false}
										className="page-group-item"
										onClick={() => selectPage(page)}
									>
										<img src={MERAKI_WEB_EDITOR_PAGES_LIST["custom_page"]} />
										<div>{page.name}</div>
									</div>
								))}
							</div>
						</React.Fragment>
					)}
					{handleCreatePage && (
						<div className="create-page-btn" onClick={createPage}>
							+ Add a new custom page
						</div>
					)}
				</div>
			</DropdownCustom>
		</div>
	);
};
