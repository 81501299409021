import gql from "graphql-tag";

const CREATE_CREDITS_TXN = gql`
	mutation createCreditsTxn($credits: Float!, $txnId: String, $preProcess: Boolean!, $provisional: Boolean!) {
		createCreditsTxn(credits: $credits, txnId: $txnId, preProcess: $preProcess, provisional: $provisional) {
			status {
				success
				messages {
					field
					message
				}
			}
			invoiceId
			credits
			grossAmount
			totalTax
			unitPrice
			taxes {
				title
				description
				rateType
				rate
				value
			}
			netAmount
		}
	}
`;

const PAYMENT_CAPTURE = gql`
	mutation paymentCapture($txnId: String!, $gatewayTxnId: String!) {
		paymentGatewayCallback(txnId: $txnId, gatewayTxnId: $gatewayTxnId) {
			status {
				success
				messages {
					field
					message
				}
			}
			paymentTxn {
				state
			}
		}
	}
`;
const INIT_PAYMENT = gql`
	mutation paymentInit(
		$amount: Float!
		$purpose: PaymentTransactionPurpose!
		$comments: String!
		$gateway: GatewayNames!
		$currency: String!
	) {
		initiatePaymentTxn(
			initiatePaymentData: {
				amount: $amount
				purpose: $purpose
				comments: $comments
				gateway: $gateway
				channel: "atlas"
				currency: $currency
			}
		) {
			paymentTxn {
				txnId
				gwTxnId
			}
			status {
				success
				messages {
					field
					message
				}
			}
			gwData {
				gwName
				key
			}
		}
	}
`;

const INIT_INVOICE_PAYMENT = gql`
	mutation paymentInit(
		$amount: Float!
		$purpose: PaymentTransactionPurpose!
		$purposeId: Int!
		$comments: String!
		$gateway: GatewayNames!
		$currency: String!
	) {
		initiatePaymentTxn(
			initiatePaymentData: {
				amount: $amount
				purpose: $purpose
				purposeId: $purposeId
				comments: $comments
				gateway: $gateway
				channel: "atlas"
				currency: $currency
			}
		) {
			paymentTxn {
				txnId
				gwTxnId
			}
			status {
				success
				messages {
					field
					message
				}
			}
			gwData {
				gwName
				key
			}
		}
	}
`;

const GET_TRANSACTIONS = gql`
	query getBizCreditsTransactions(
		$limit: Int!
		$offset: Int!
		$sort: SortInput
		$filters: [ListFilterArgument]
		$search: [SearchArgument]
	) {
		bizCreditsTransactions(limit: $limit, offset: $offset, sort: $sort, filters: $filters, search: $search) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			creditsUsed
			objects {
				id
				credits
				transactionType
				user {
					username
				}
				purpose
				comments
				created
				status
				purposeObject {
					... on CampaignType {
						id
						name
						medium
					}
					... on PaymentTransactionType {
						txnId
						amount
					}
					... on BizCreditsGrantTransactionType {
						id
					}
				}
			}
		}
	}
`;

export { GET_TRANSACTIONS, INIT_PAYMENT, PAYMENT_CAPTURE, CREATE_CREDITS_TXN, INIT_INVOICE_PAYMENT };
