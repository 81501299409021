import React, { useState, useEffect, useCallback, useImperativeHandle } from "react";

// components
import HistoryDetail from "../WebhookEdit/HistoryDetail";

// third party
import { useTrail, config, animated } from "react-spring";
import moment from "moment";

// client
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_WEBHOOK_DELIVERIES } from "../../graphql/transactions";

// actions
import { ActionTypes } from "../../actions/_types";

// config
import { ORDER_WEBHOOK_HISTORY_LIST_HEADER_FIELDS } from "../../client-config";

export const Webhooks = ({ orderId, nestedRef, setModalBusy }) => {
	const [webhookDeliveriesList, setWebhookDeliveriesList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [historyDetailView, setHistoryDetailView] = useState(false);
	const [selectedHistoryDetail, setSelectedHistoryDetail] = useState({});

	const fetchWebhookDeliveries = useCallback(async () => {
		try {
			setLoading(true);
			const variables = { id: orderId };
			const resp = await client.query({
				query: GET_WEBHOOK_DELIVERIES,
				variables,
				fetchPolicy: "no-cache"
			});
			setWebhookDeliveriesList(resp.data.order.webhookDeliveries);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		fetchWebhookDeliveries();
	}, []);

	const openHistoryDetailView = (id) => {
		setSelectedHistoryDetail(webhookDeliveriesList.find((h) => h.id === id));
		setHistoryDetailView(true);
		setModalBusy(true);
	};

	const closeHistoryDetailView = () => {
		setHistoryDetailView(false);
		setModalBusy(false);
		setSelectedHistoryDetail({});
	};

	useImperativeHandle(
		nestedRef,
		() => ({
			closeHistoryDetailView
		}),
		[closeHistoryDetailView]
	);

	return (
		<div className={"webhooks-container " + (loading ? " no-click" : "")}>
			<div className="header-container">
				<div>
					<div className="header-text">Webhooks</div>
					<div className="header-subtext">View webhook deliveries for this order</div>
				</div>
			</div>
			<Table data={webhookDeliveriesList} loading={loading} handleHistoryDetailView={openHistoryDetailView} />
			<HistoryDetail
				isOpen={historyDetailView}
				close={closeHistoryDetailView}
				data={selectedHistoryDetail}
				webhookUrl={selectedHistoryDetail.url || ""}
			/>
		</div>
	);
};

export const Table = ({ data, loading, sortList, sortedField, handleHistoryDetailView }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container webhook-history-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={ORDER_WEBHOOK_HISTORY_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleHistoryDetailView={handleHistoryDetailView}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Webhooks found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	lastExecuted,
	url,
	statusCode,
	eventDetails,
	webhook,
	style,
	handleHistoryDetailView
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text event" title={webhook ? webhook.eventTypeDisplayName : null}>
				{webhook ? (
					<a role="button" className="link-text" onClick={() => handleHistoryDetailView(id)}>
						{webhook.eventTypeDisplayName || "Details"}
					</a>
				) : (
					"--"
				)}
				<div className="desc-text">{eventDetails || "--"}</div>
			</div>
			<div className="at-table-cell at-cell-text url" title={url}>
				{url || "--"}
			</div>
			<div className="at-table-cell at-cell-text delivery">
				<div title={id}>{id}</div>
				<div className="desc-text">
					{lastExecuted ? moment(lastExecuted).format("DD MMM, YYYY - hh:mm A") : ""}
				</div>
			</div>
			<div className={"at-table-cell at-cell-text status-code " + (statusCode === 200 ? "success" : "error")}>
				<span>{statusCode}</span>
			</div>
		</animated.div>
	);
};
