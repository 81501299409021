import React, { useState, useCallback, useEffect } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { CheckBox } from "../_commons/CheckBox";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// graphql
import { GET_BIZ_PLATFORMS, LOCATION_ACTION } from "../../graphql/locations";

// utils
import { trackEvent, triggerSurvey, extractInitials } from "../../atlas-utils";
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import SatismeterService from "../../services/SatismeterService";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchBrands } from "../../actions/actions";

// constants
import { CATALOGUE_PLATFORMS_LOGO, TRACKING_EVENT_NAMES, TRACKING_SOURCE } from "../../client-config";

const PublishCatalogue = ({
	isNested = false,
	locations = [],
	isOpen = false,
	close,
	brands = [],
	selectedBrand = null,
	isMultibrandEnabled = false,
	fromBrands = false
}) => {
	const [loading, setLoading] = useState(false);
	const [isInventoryLoading, setIsInventoryLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [bizPlatformsList, setBizPlatformsList] = useState([]);
	const [bizInventoryPlatformsList, setBizInventoryPlatformsList] = useState([]);
	const [publishResponse, setPublishResponse] = useState(null);
	const [brand, setBrand] = useState(selectedBrand);
	const [platformUpdates, setPlatformUpdates] = useState({});

	useEffect(() => {
		if (isOpen) {
			fetchBizPlatformsList();
			fetchBizInventoryPlatformsList();
			if (isMultibrandEnabled) {
				fetchBrands("", true);
			}
		}
	}, [isOpen]);

	useEffect(() => {
		setBrand(selectedBrand);
	}, [selectedBrand]);

	const fetchBizPlatformsList = async () => {
		try {
			setLoading(true);
			const variables = {
				limit: 30,
				offset: 0
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizPlatformsList(resp.data.bizPlatforms.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const fetchBizInventoryPlatformsList = async () => {
		try {
			setIsInventoryLoading(true);
			const variables = {
				limit: 10,
				offset: 0,
				filters: [
					{
						field: "platform__kind",
						value: "inventory"
					}
				]
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizInventoryPlatformsList(resp.data.bizPlatforms.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setIsInventoryLoading(false);
	};

	const handleSelectBrand = (brand) => {
		setBrand(brand);
	};

	const handleSelectPlatform = (platform, isSelected) => {
		setPlatformUpdates({
			...platformUpdates,
			[platform.id]: isSelected
		});
	};

	const handleSelectAllPlatforms = () => {
		let updates = {};
		[...bizPlatformsList, ...bizInventoryPlatformsList].forEach((plf) => {
			updates[plf.id] = true;
		});
		setPlatformUpdates(updates);
	};

	const handleClose = useCallback(
		(success = false) => {
			// reset state before closing
			if (publishResponse?.status) {
				triggerSurvey("survey publish store");
			}
			setBizPlatformsList([]);
			setPlatformUpdates({});
			setPublishResponse(null);
			setBrand(selectedBrand);
			close(success);
		},
		[close]
	);

	const handlePublish = useCallback(async () => {
		try {
			setConfirmLoading(true);
			const variables = {
				locationIds: locations.map((loc) => parseInt(loc.id)),
				platformIds: Object.keys(platformUpdates).filter((platform) => platformUpdates[platform]),
				action: "publish"
			};
			if (brand) {
				variables.brand = brand?.id;
			}
			// track publish catalogue event
			const eventMeta = {
				num_locations: locations.length,
				platforms: bizPlatformsList.filter((plf) => platformUpdates[plf.id]).map((plf) => plf.platformName),
				action: "publish",
				num_of_entities: locations.length,
				source: TRACKING_SOURCE.LIST_VIEW_BULK
			};
			trackEvent(TRACKING_EVENT_NAMES.PUBLISH_CATALOGUE, eventMeta);

			// satismeter event
			SatismeterService.menuPublish();

			const resp = await client.mutate({
				mutation: LOCATION_ACTION,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.locationAction.status.success) {
				// handle success message
				const pubResp = {
					status: true,
					message: resp.data.locationAction.status.messages[0].message
				};
				setPublishResponse(pubResp);
			} else {
				// handle error message
				const pubResp = {
					status: false,
					message: resp.data.locationAction.status.messages[0].message
				};
				setPublishResponse(pubResp);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [locations, brand, platformUpdates]);

	const handleDisabled = () => {
		let disabled = false;
		if (isMultibrandEnabled && brand === null) {
			disabled = true;
		}
		disabled = Object.values(platformUpdates).filter((val) => val === true).length === 0;
		return confirmLoading || disabled;
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 85 ? brand.name.slice(0, 85) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="locations-publish-catalogue-container">
			<FormSidebar
				isOpen={isOpen}
				loading={loading}
				submit={handlePublish}
				close={() => handleClose(publishResponse?.status)}
				title="Publish Catalogue"
				subTitle={`Select ${isMultibrandEnabled ? "brand(s) and" : ""} platform(s) to publish catalogue`}
				submitTitle="Publish"
				cancelTitle={publishResponse !== null ? "Close" : "Cancel"}
				hideSubmitAction={publishResponse !== null}
				disabled={handleDisabled()}
				isNested={isNested}
				overlaySidebarClasses="medium"
			>
				{!confirmLoading && !publishResponse && (
					<React.Fragment>
						{isMultibrandEnabled && (
							<div className="section brand-selection">
								<div className="section-header">Brands</div>
								<SelectFilterCustom
									options={brands.items}
									isLoading={brands.isLoading}
									field="brands"
									currValue={brand}
									setFilter={(f, value) => handleSelectBrand(value)}
									labelKey={"name"}
									valueKey={"id"}
									ellipsizedLength={200}
									isSearchable={false}
									customLabel={true}
									customOptions={true}
									isClearable={false}
									renderLabel={handleBrandsLabelOption}
									renderOptions={handleBrandsLabelOption}
									placeholder="Select brand"
									classes={fromBrands ? "brands disabled" : "brands"}
								/>
							</div>
						)}
						<div className="section platforms-selection">
							<div className="section-header">
								<div>
									{bizInventoryPlatformsList?.length > 0 ? "Online Ordering Platforms" : "Platforms"}
								</div>
								{(bizPlatformsList.length > 0 || bizInventoryPlatformsList.length > 0) && (
									<div className="select-all" onClick={handleSelectAllPlatforms}>
										Select All
									</div>
								)}
							</div>
							{loading && bizPlatformsList.length === 0 && (
								<div>
									<div className="shimmer H(25px) Mb(10px)" />
									<div className="shimmer H(25px) Mb(10px)" />
									<div className="shimmer H(25px) Mb(10px)" />
								</div>
							)}
							{!loading && bizPlatformsList === 0 && (
								<div className="no-items-placeholder">
									{bizInventoryPlatformsList?.length > 0
										? "No online ordering platforms found"
										: "No platforms found"}
								</div>
							)}
							{!loading &&
								bizPlatformsList.map((plf, i) => (
									<CheckBox
										key={i}
										checked={platformUpdates[plf.id]}
										clickHandler={() => handleSelectPlatform(plf, !platformUpdates[plf.id])}
									>
										<div className="platform-info">
											<div className="platform-name" title={plf.platformName}>
												{plf.platformName}
											</div>
											<div className="platform-logo">
												<img
													src={
														plf?.logo ||
														CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
														"/assets/icons/icons8-globe-40.png"
													}
													alt=""
												/>
											</div>
										</div>
									</CheckBox>
								))}
						</div>
						{!isInventoryLoading && bizInventoryPlatformsList?.length > 0 && (
							<div className="section platforms-selection">
								<div className="section-header">
									<div>Inventory Platforms</div>
								</div>
								{isInventoryLoading ? (
									<div>
										<div className="shimmer H(25px) Mb(10px)" />
										<div className="shimmer H(25px) Mb(10px)" />
										<div className="shimmer H(25px) Mb(10px)" />
									</div>
								) : (
									bizInventoryPlatformsList.map((plf, i) => (
										<CheckBox
											key={i}
											checked={platformUpdates[plf.id]}
											clickHandler={() => handleSelectPlatform(plf, !platformUpdates[plf.id])}
										>
											<div className="platform-info">
												<div className="platform-name" title={plf.platformName}>
													{plf.platformName}
												</div>
												<div className="platform-logo">
													<img
														src={
															plf?.logo ||
															CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
															"/assets/icons/icons8-globe-40.png"
														}
														alt=""
													/>
												</div>
											</div>
										</CheckBox>
									))
								)}
							</div>
						)}
					</React.Fragment>
				)}
				{confirmLoading && (
					<div className="P(25px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
				{!confirmLoading && publishResponse && (
					<div className="publish-response-section">
						<div className={"publish-response " + (publishResponse?.status ? "success" : "fail")}>
							{publishResponse?.message}
						</div>
					</div>
				)}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(PublishCatalogue);
