import React, { useState } from "react";

// components
import { Loading } from "../_commons/Loading";
import { Modal } from "../_commons/Modal";

// actions
import { cancelScheduledPublish, fetchMenuDetails } from "../../actions/menus";

const CancelScheduledPublishModal = ({ menuId, isOpen, setCancelScheduledPublishModalState }) => {
	const [isLoading, setLoading] = useState(false);
	const [respState, setRespState] = useState("");
	const handleCancelRequest = async () => {
		setLoading(true);
		const resp = await cancelScheduledPublish(menuId);
		if (resp === "success") {
			fetchMenuDetails(menuId);
		}
		setRespState(resp);
		setLoading(false);
	};

	const handleClose = () => {
		if (isLoading) {
			return;
		}
		setRespState("");
		setCancelScheduledPublishModalState({
			isOpen: false
		});
	};

	if (isLoading) {
		return (
			<Modal
				isOpen={isOpen}
				close={handleClose}
				title={"Cancel scheduled publish?"}
				submitAction={handleCancelRequest}
				submitTitle="Cancel Publish Request"
				cancelTitle="Close"
				classes="published-version-restore--modal"
				disabled
			>
				<Loading />
			</Modal>
		);
	}

	if (respState) {
		return (
			<Modal
				isOpen={isOpen}
				close={handleClose}
				title={"Restore Menu Configuration?"}
				showSubmitAction={respState === "failure"}
				submitAction={handleCancelRequest}
				submitTitle="Try Again"
				showCancelAction
				cancelTitle="Dismiss"
				classes="published-version-restore--modal"
			>
				<div className={`published-version-restore--modal--${respState}`}>
					{respState === "failure"
						? "Failed to cancel scheduled publish"
						: "Scheduled publish cancelled successfully!"}
				</div>
			</Modal>
		);
	}

	return (
		<Modal
			isOpen={isOpen}
			close={handleClose}
			title={"Cancel scheduled publish?"}
			showSubmitAction
			showCancelAction
			submitAction={handleCancelRequest}
			submitTitle="Cancel Publish Request"
			cancelTitle="Close"
			classes="published-version-restore--modal"
			submitButtonWidth="200"
		>
			<span className="body-main-text">Are you sure you want to cancel the scheduled Publish ?</span>
			<br />
			<span className="body-subtext">
				If you cancel the scheduled publish, the menus on the aggregators will not be updated at the planned
				time.
			</span>
		</Modal>
	);
};
export default CancelScheduledPublishModal;
