import React from "react";

// components
import { InputWithLabel } from "./InputWithLabel";

export const SearchFilter = ({
	title = "",
	filterOption = {},
	value,
	setFilter,
	placeholder = "",
	autoFocus = false
}) => {
	return (
		<div className="search-filter">
			<InputWithLabel
				value={value || ""}
				onChange={(e) => filterOption.field && setFilter(filterOption.field, e.target.value)}
				placeholder={placeholder}
				classes="at-input--label"
				showLabel={true}
				image={true}
				imageSrc="/assets/header-icons/icon-search.svg"
				autoFocus={autoFocus}
			>
				{title}
			</InputWithLabel>
			{value ? (
				<div onClick={() => setFilter(filterOption.field, "")} className="cancel-search">
					<img src="/assets/icons/cancel.png" alt="" />
				</div>
			) : null}
		</div>
	);
};
