// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_COUPONS_LIST,
	GET_COUPON,
	EDIT_COUPON,
	UPDATE_COUPON_RULES,
	UPDATE_COUPON_ACTIONS,
	UPDATE_COUPON_ACTION_INCLUDE_OPTION,
	CREATE_BULK_COUPONS
} from "../graphql/coupons";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

const customErrorMessages = (messages = []) => {
	const error = {
		message: "",
		fields: {}
	};
	if (messages.length === 0) {
		error.message = "Something went wrong, please try again.";
	} else {
		messages.forEach((msg) => {
			error.fields[msg.field] = msg.message;
		});
	}
	return error;
};

export const fetchCouponsList = async () => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().couponsListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_COUPONS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(!appliedFilters[f])
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: "true"
			});
		}
		// date filter
		Object.keys(appliedDateFilter).forEach((f) => {
			if (appliedDateFilter[f].dateFilter) {
				filtersObject.push({
					field: f,
					value: appliedDateFilter[f].dateFilter
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_COUPONS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_COUPONS_LIST_SUCCESS,
			payload: { ...resp.data.coupons }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_COUPONS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseCouponData = (to = "form", data, couponTypes, isRules = false, isActions = false) => {
	let result = { ...JSON.parse(JSON.stringify(data)) };
	switch (to) {
		case "form":
			if (result["rewardType"]) {
				result["rewardType"] =
					result["rewardType"] === "FREEBIE"
						? { valueForDisplay: "Freebie", value: "FREEBIE" }
						: couponTypes.find((ct) => ct.value === result["rewardType"]);
			}
			if (result["startsOn"]) {
				result["startsOn"] = result["startsOn"].split("T").join(" ");
			}
			if (result["expiresOn"]) {
				result["expiresOn"] = result["expiresOn"].split("T").join(" ");
			}
			break;
		case "server":
			if (isRules) {
				result = [];
				data.forEach((rule, i) => {
					rule.ruleApplyOrder = i;
					if (rule.isNewRule) {
						rule.ruleName = rule.ruleName.ruleName;
					}
					delete rule.isNewRule;
					rule.parameters.forEach((param) => {
						if (param.multiple) {
							param.paramValue = param.paramValue.length
								? param.paramValue.map((val) => val.value).join(",")
								: [];
						}
						delete param.multiple;
					});
					result.push(rule);
				});
			} else if (isActions) {
				result.pActionId = result.actionName;
				result.parameters.map((param) => {
					if (param.multiple) {
						if (param.paramValue && Array.isArray(param.paramValue)) {
							param.paramValue = param.paramValue.length
								? param.paramValue.map((val) => val.value).join(",")
								: "";
						} else if (param.paramValue && !Array.isArray(param.paramValue)) {
							param.paramValue =
								String(param.paramValue.value).charAt(0).toUpperCase() +
								String(param.paramValue.value).slice(1);
						} else {
							param.paramValue = "";
						}
					}
					delete param.multiple;
				});
				result.parameters = result.parameters.filter((param) => param.paramValue !== "");
				// make sure parameter: 'PA_COUPON_RESTRICT_INCLUSION_TO_SINGLE_ITEM' is included for 'Item Specific' action
				if (
					result.actionName === 29 &&
					result.parameters.find(
						(param) => param.paramName === "PA_COUPON_RESTRICT_INCLUSION_TO_SINGLE_ITEM"
					) === undefined
				) {
					result.parameters.push({
						paramName: "PA_COUPON_RESTRICT_INCLUSION_TO_SINGLE_ITEM",
						paramValue: "False"
					});
				}
				delete result.actionName;
				delete result.uiText;
			} else {
				if (result["rewardType"]) {
					result["rewardType"] = result["rewardType"].value;
				}
				if (result["startsOn"]) {
					result["startsOn"] = result["startsOn"].split(" ").join("T");
				}
				if (result["expiresOn"]) {
					result["expiresOn"] = result["expiresOn"].split(" ").join("T");
				}
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchCouponDetail = async (id, couponTypes, dispatch) => {
	dispatch({
		type: ActionTypes.GET_COUPON_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_COUPON,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_COUPON_DETAIL_SUCCESS,
			payload: parseCouponData("form", resp.data.coupon, couponTypes)
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_COUPON_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Coupon details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editCouponDetail = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_COUPON_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_COUPON,
			variables: parseCouponData("server", data)
		});
		if (resp.data.saveCoupon.status.success) {
			dispatch({
				type: ActionTypes.EDIT_COUPON_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Coupon saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_COUPONS_LIST,
				payload: resp.data.saveCoupon.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_COUPON_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.saveCoupon.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_COUPON_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Coupon details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreCoupon = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_COUPON,
			variables: parseCouponData("server", data)
		});
		if (resp.data.saveCoupon.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveCoupon.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};

export const updateCouponRules = async (id, data, rulesToDelete, ct) => {
	try {
		const resp = await client.mutate({
			mutation: UPDATE_COUPON_RULES,
			variables: {
				id: id,
				rules: parseCouponData("server", data, ct, true),
				rulesToDelete
			}
		});
		if (resp.data.saveCouponRules.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Coupon rules saved!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			if (resp.data.saveCouponRules.status.messages.length) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.saveCouponRules.status.messages[0].message,
						timeout: 2000,
						error: true
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while saving Coupon rules",
						timeout: 2000,
						error: true
					}
				});
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateCouponActions = async (id, data, dispatch, ct) => {
	dispatch({
		type: ActionTypes.UPDATE_COUPON_DETAIL_STATE,
		payload: {
			error: {}
		}
	});
	try {
		const resp = await client.mutate({
			mutation: UPDATE_COUPON_ACTIONS,
			variables: {
				id: id,
				action: parseCouponData("server", data, ct, false, true)
			}
		});
		if (resp.data.saveCouponAction.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Coupon actions saved!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			if (resp.data.saveCouponAction.status.messages.length) {
				dispatch({
					type: ActionTypes.UPDATE_COUPON_DETAIL_STATE,
					payload: {
						error: customErrorMessages(resp.data.saveCouponAction.status.messages)
					}
				});
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.saveCouponAction.status.messages[0].message,
						timeout: 2000,
						error: true
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while saving Coupon actions",
						timeout: 2000,
						error: true
					}
				});
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const updateCouponActionIncludeOption = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: UPDATE_COUPON_ACTION_INCLUDE_OPTION,
			variables
		});
		if (resp.data.updateCouponActionIncludeOption.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Included Modifiers of Items have been saved!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			if (resp.data.updateCouponActionIncludeOption.status.messages.length) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.updateCouponActionIncludeOption.status.messages[0].message,
						timeout: 2000,
						error: true
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while saving included Modifiers of Items",
						timeout: 2000,
						error: true
					}
				});
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const generateBulkCoupons = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: CREATE_BULK_COUPONS,
			variables: { input: variables }
		});
		if (resp.data.saveBulkCoupons.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Coupon codes successfully generated",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			if (resp.data.saveBulkCoupons.status.messages.length) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.saveBulkCoupons.status.messages[0].message,
						timeout: 2000,
						error: true
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while generating coupon codes",
						timeout: 2000,
						error: true
					}
				});
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};
