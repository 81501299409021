import React, { useState, useEffect, useCallback, useMemo } from "react";

// components
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// third party
import { connect } from "react-redux";
import { Area, AreaChart } from "recharts";
import moment from "moment";
import { useTrail, config, animated } from "react-spring";

// clients
import { store } from "../../store/configureStore";
import { client } from "../../client";

// utils
import { commifyNumbers, printCurrency, getSuffixedNumber, extractInitials, trackEvent } from "../../atlas-utils";
import { getDurationObject } from "../../actions/salesAnalytics";
import { ActionTypes } from "../../actions/_types";

// graphql
import { SALES_ANALYTICS_OVERVIEW_DATASET_GRAPH } from "../../graphql/salesAnalytics";

// constants
import { PRESET_TYPES } from "../_commons/DateCompareFilter";
import { CRITERIA_ANALYTICS, TOPIC_LIST_SA_OVERVIEW, TOPIC_LIST_SA_OVERVIEW_LABELS } from "../../client-config";

const Overview = ({
	salesAnalyticsState,
	salesAnalyticsOverview,
	productType,
	productTypeOptions,
	updateSalesAnalyticsState,
	currencySymbol,
	filterCount,
	filterActive,
	flipShowFilters,
	handleStoreType,
	isMultibrandEnabled = false,
	selectedBrand,
	brands,
	handleBrand
}) => {
	const { currentDateFilter, appliedDateFilter } = salesAnalyticsState;
	let isCustomDateRange = false;
	let isComparing = false;
	const currentDates = appliedDateFilter.current.dateFilter.split(",");
	const compareDates = appliedDateFilter.compare.dateFilter.split(",");
	let currentStart = "";
	let currentEnd = "";
	let compareStart = "";
	let compareEnd = "";
	let presetDateFilterLabel = "";
	if (currentDates.length === 2) {
		isCustomDateRange = true;
		currentStart = moment(currentDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		currentEnd = moment(currentDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	} else {
		presetDateFilterLabel = PRESET_TYPES.find((pt) => pt.value === appliedDateFilter.current.dateFilter).label;
	}
	if (compareDates.length === 2) {
		isComparing = true;
		compareStart = moment(compareDates[0], "YYYY-MM-DD").format("DD MMM, YYYY");
		compareEnd = moment(compareDates[1], "YYYY-MM-DD").format("DD MMM, YYYY");
	}
	const { data, loading, error } = salesAnalyticsOverview;

	const filteredKPIS = useMemo(() => data.filter(({ topic }) => TOPIC_LIST_SA_OVERVIEW.indexOf(topic) >= 0), [data]);

	const trails = useTrail(filteredKPIS.length, {
		config: config.stiff,
		from: {
			opacity: 0
		},
		opacity: 1
	});

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="sales-analytics-overview">
			<div className="credits-section-header transactions-list-header">
				<div className="header-text">
					<div className="title">Business KPIs</div>
					<div className="subtitle">
						View key performance indicators of your{" "}
						<span className="highlight">{productType === "ORDERING" ? "online" : "in-store"}</span> business
					</div>
				</div>
				<div className="header-action-button"></div>
			</div>
			<div className="sales-analytics-filters">
				{isMultibrandEnabled && (
					<SelectFilterCustom
						options={brands.items}
						isLoading={brands.isLoading}
						field="brands"
						currValue={selectedBrand}
						setFilter={(f, value) => handleBrand(value)}
						labelKey={"name"}
						valueKey={"id"}
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						isClearable={true}
						renderLabel={handleBrandsLabelOption}
						renderOptions={handleBrandsLabelOption}
						placeholder="Select brand"
						classes="brand-filter"
					/>
				)}
				<NewDateCompareFilter
					showDropdown={true}
					loading={loading}
					currentDateFilter={currentDateFilter}
					appliedDateFilter={appliedDateFilter}
					updateState={updateSalesAnalyticsState}
					hidePresetTypes={["15 D"]}
				/>
				{/* <SelectFilter
					options={productTypeOptions}
					currValue={productTypeOptions.find((opt) => opt.value === productType)}
					setFilter={(field, value) => handleStoreType(value.value)}
					isClearable={false}
				/> */}
				<div className="filter-buttons">
					<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
						<div className="container" onClick={flipShowFilters}>
							<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
							<div className="filter-title">
								Filter
								{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
							</div>
						</div>
					</div>
				</div>
			</div>
			{!data.length && loading && <div className="shimmer H(100px) W(100px) Mstart(25px) Mb(25px)" />}
			{data.length > 0 && !error && (
				<div className={"overview-data-items" + (loading ? " disabled" : "")}>
					{trails.map(({ opacity }, i) => (
						<OverviewItem
							data={filteredKPIS[i]}
							salesAnalyticsState={salesAnalyticsState}
							productType={productType}
							updateSalesAnalyticsState={updateSalesAnalyticsState}
							currencySymbol={currencySymbol}
							key={filteredKPIS[i].topic}
							kpiLabel={TOPIC_LIST_SA_OVERVIEW_LABELS[filteredKPIS[i].topic]}
							selectedBrand={selectedBrand}
							isLastItem={i === filteredKPIS.length - 1}
							style={{
								opacity
							}}
						/>
					))}
				</div>
			)}
			{error && (
				<div className="no-items-placeholder error">
					An error occurred while fetching the data, Please reload.
				</div>
			)}
		</div>
	);
};
export default connect((store) => ({
	salesAnalyticsState: store.salesAnalyticsState,
	salesAnalyticsOverview: store.salesAnalyticsOverview,
	productType: store.selectedModule.productType,
	productTypeOptions: store.selectedModule.productTypeOptions,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(Overview);

const OverviewItem = ({
	data,
	productType,
	salesAnalyticsState,
	updateSalesAnalyticsState,
	currencySymbol,
	style,
	kpiLabel,
	isLastItem,
	selectedBrand = null
}) => {
	const [graphData, setGraphData] = useState({ current: [], previous: [] });
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const { appliedDateFilter } = salesAnalyticsState;
	const isActive = data.topic === salesAnalyticsState.analyticsTopic;

	useEffect(() => {
		if (!getDurationObject(appliedDateFilter).comparisonDuration) {
			getAnaGraphData();
		}
	}, [appliedDateFilter, productType, selectedBrand]);

	const getAnaGraphData = useCallback(async () => {
		const durationObject = getDurationObject(appliedDateFilter);
		const variables = {
			productType,
			topic: data.topic,
			criteria: CRITERIA_ANALYTICS[0],
			criteriaValue: "all",
			dataFormat: "GRAPHICAL",
			isAnalytics: true,
			isThumbnail: true,
			...durationObject
		};
		if (selectedBrand !== null) {
			variables.brand = selectedBrand.id;
		}
		setLoading(true);
		setError(false);
		try {
			if (productType === "LOYALTY" && (variables.topic === "LOST_ORDERS" || variables.topic === "LOST_SALES")) {
				return;
			}
			const resp = await client.query({
				query: SALES_ANALYTICS_OVERVIEW_DATASET_GRAPH,
				variables
			});
			setGraphData(resp.data.analytics.dataset[0]);
			setLoading(false);
		} catch (error) {
			setError(error);
			setLoading(false);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	}, [appliedDateFilter.current.dateFilter, data.topic, productType, selectedBrand]);

	const handleAnalyticsTopic = useCallback(() => {
		const payload = {
			analyticsTopic: data.topic,
			criteriaValue: "all",
			offset: 0
		};
		if (data.topic == "USER_SIGNUPS") {
			payload.criteria = CRITERIA_ANALYTICS[1];
		} else {
			payload.criteria = CRITERIA_ANALYTICS[0];
		}
		updateSalesAnalyticsState(payload);

		// set tracking related info
		// trackEvent('sales_analytics_kpi_overview', {
		// 	kpi: payload.analyticsTopic,
		// 	...getDurationObject(appliedDateFilter),
		// });
	}, [data.topic, appliedDateFilter]);

	return (
		<animated.div
			className={"overview-item" + (loading ? " disabled" : "")}
			onClick={handleAnalyticsTopic}
			title={commifyNumbers(data.value)}
			style={style}
			data-active={isActive}
		>
			{appliedDateFilter.compare.dateFilter && <OverviewPercent data={data} />}
			<div
				className="overview-item-title"
				aria-label={kpiLabel}
				data-balloon-pos={isLastItem ? "up-right" : "up-left"}
			>
				{data.displayName}
				<img
					className="title-info"
					src={isActive ? "/assets/icons/info-white.svg" : "/assets/icons/info.png"}
				/>
			</div>
			<div
				className={"overview-item-value"}
				data-active={data.topic === salesAnalyticsState.analyticsTopic ? true : false}
			>
				{["SALES", "AVERAGE_SALES", "LOST_SALES"].indexOf(data.topic) > -1
					? printCurrency(currencySymbol)
					: null}
				{getSuffixedNumber(data.value)}
			</div>
			{!appliedDateFilter.compare.dateFilter ? (
				<React.Fragment>
					{graphData.current.length ? (
						<AnalyticsLineChart
							topic={data.topic}
							data={graphData.current}
							width={100}
							height={65}
							isActive={data.topic === salesAnalyticsState.analyticsTopic}
						/>
					) : loading ? (
						<div className="shimmer H(65px) W(100px)"></div>
					) : (
						<div className="no-items-placeholder Mih(65px)">No data!</div>
					)}
				</React.Fragment>
			) : (
				<div className="comparison-value">
					<span>v/s </span>
					<span className="highlight">
						{["SALES", "AVERAGE_SALES", "LOST_SALES"].indexOf(data.topic) > -1
							? printCurrency(currencySymbol)
							: null}
						{getSuffixedNumber(data.comparisonValue)}
					</span>
				</div>
			)}
		</animated.div>
	);
};

const OverviewPercent = ({ data }) => {
	const isPositive = data.percentChange >= 0 ? true : false;
	return (
		<div className={"percent-change " + (isPositive ? "positive" : "negative")}>
			{isPositive ? <img src="/assets/icons/up.png" /> : <img src="/assets/icons/down-tiny.png" />}
			<span>{data.percentChange}%</span>
		</div>
	);
};

const AnalyticsLineChart = ({ data, width, height, isActive = false, topic }) => (
	<AreaChart width={width} height={height} data={data} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
		<defs>
			<linearGradient id={topic} x1="0" y1="0" x2="0" y2="1">
				<stop offset="5%" stopColor={isActive ? "#f0f0f0" : "#8884d8"} stopOpacity={1} />
				<stop offset="95%" stopColor={isActive ? "#f0f0f0" : "#8884d8"} stopOpacity={0.3} />
			</linearGradient>
		</defs>
		<Area
			type="monotone"
			dataKey="value"
			stroke={isActive ? "#ffffff" : "#2f58f2"}
			strokeWidth={1.2}
			fill={`url(#${topic})`}
			fillOpacity={0.5}
			activeDot={{ r: 5 }}
		/>
	</AreaChart>
);
