import React, { useState, useCallback, useEffect, useRef, useImperativeHandle } from "react";

// components
import { Button } from "../_commons/Button";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import LocationEntityAssociations from "../EntityAssociations/LocationEntityAssociations";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";
import { connect } from "react-redux";
import SatismeterService from "../../services/SatismeterService";

// store
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { extractInitials, scroll, trackEvent } from "../../atlas-utils";

// graphql
import { GET_MODIFIER_LOCATIONS, UPDATE_MODIFIER_SPECIFIC_DETAILS } from "../../graphql/modifiers";

// actions
import { ActionTypes } from "../../actions/_types";
import { updateModifierLocations } from "../../actions/modifiers";
import { fetchBrands } from "../../actions/actions";

// config
import {
	MODIFIERS_LOCATIONS_LIST_HEADER_FIELDS,
	LOCATION_ENTITY_ASSOCIATION_TYPES,
	NESTED_ENTITY_TYPES,
	TRACKING_ENTITY,
	TRACKING_ACTION,
	TRACKING_EVENT_NAMES,
	TRACKING_SOURCE,
	TRACKING_MISC
} from "../../client-config";

// constant
const FILTER_INITIAL_STATE = {
	name: ""
};

const AssociatedLocations = ({
	modifierId,
	currencySymbol,
	isEnforcedOla = false,
	setModalBusy,
	isFormTouched = false,
	setFormTouched,
	dispatch,
	handleNestedEntity,
	readOnly = true,
	connectedRef,
	brands,
	isMultibrandEnabled = false
}) => {
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [loading, setLoading] = useState(false);
	const [locationsList, setLocationsList] = useState({
		objects: [],
		count: 0
	});
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState(FILTER_INITIAL_STATE);
	const [associatedCitiesList, setAssociatedCitiesList] = useState([]);
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [stockLoadingId, setStockLoadingId] = useState(undefined);
	const [locationsPricesList, setLocationsPricesList] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [locationUpdates, setLocationUpdates] = useState({});
	const [selectAllLocations, setSelectAllLocations] = useState(false);
	const totalChanges = Object.values(locationUpdates).filter((val) => val.status === true).length;
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const [hasPriceChanged, setHasPriceChanged] = useState(false);
	const brandsWithoutAll = brands?.items.filter((brand) => brand.id !== "all");
	const [selectedBrand, setSelectedBrand] = useState(
		!brands.selectedBrand || brands.selectedBrand?.id === "all" ? brandsWithoutAll[0] || null : brands.selectedBrand
	);
	const totalPages = Math.ceil(locationsList.count / limit);
	const tableRef = useRef();

	const fetchModifierLocations = useCallback(
		async (name = "", resetSelection = false) => {
			try {
				setLoading(true);
				const variables = {
					id: parseInt(modifierId),
					limit,
					offset,
					brandId: selectedBrand?.id ? parseInt(selectedBrand?.id) : null
				};
				if (appliedFilters.city) {
					variables.city = appliedFilters.city;
				}
				if (appliedFilters.name) {
					variables.name = appliedFilters.name;
				}
				if (name) {
					variables.name = name;
				}
				const resp = await client.query({
					query: GET_MODIFIER_LOCATIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				if (associatedCitiesList.length === 0 && resp.data.modifier.associatedCities) {
					setAssociatedCitiesList(
						resp.data.modifier.associatedCities.map((value) => ({ value, valueForDisplay: value }))
					);
				}
				setLocationsList({
					objects: resp.data.modifier.associatedLocations,
					count: resp.data.modifier.numAssociatedLocations
				});
				if (resetSelection) {
					setLocationUpdates({});
					setLocationsPricesList({});
					setAllSelected(false);
					setSelectAllLocations(false);
					setShowAllFieldSelector(false);
					setIsCheckedAll(false);
				}
				// scroll to top
				const formContainer = document.querySelectorAll(".form-container")[0];
				if (tableRef && formContainer) {
					scroll({ top: tableRef?.current?.offsetTop - 200, left: 0 }, formContainer);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[modifierId, limit, offset, appliedFilters, selectedBrand]
	);

	const applyFilter = debounce((filters) => setAppliedFilters(filters), 500);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter]
	);

	const handleForm = (field, value, id) => {
		// enforce positive value for price field
		if (value && value < 0) {
			value = 0;
		}

		// update price change flag
		if (field === "price") {
			setHasPriceChanged(true);
		}
		let updatedObj;
		const associatedLocations = locationsList.objects.map((obj) => {
			if (obj.location.id === id) {
				obj[field] = value;
				updatedObj = {
					locationId: id,
					price: obj.price,
					currentStock: obj.currentStock
				};
			}
			return obj;
		});
		setLocationsPricesList({
			...locationsPricesList,
			[id]: updatedObj
		});
		setLocationsList({
			...locationsList,
			objects: associatedLocations
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const updateModifierAvailability = async (id, price, currentStock, isAvailable) => {
		try {
			const variables = {
				optionId: modifierId,
				olas: [
					{
						locationId: id,
						price: price,
						available: isAvailable,
						currentStock: currentStock
					}
				],
				associateAll: false,
				disassociateAll: false,
				filters: []
			};
			setStockLoadingId(id);
			const resp = await client.mutate({
				mutation: UPDATE_MODIFIER_SPECIFIC_DETAILS,
				variables
			});
			if (resp.data?.updateModifierLocationFields.status.success) {
				await fetchModifierLocations(appliedFilters.name, false);
				// track event
				const eventMeta = {
					entity: TRACKING_ENTITY.MODIFIER,
					source: TRACKING_SOURCE.DETAIL_VIEW,
					action: isAvailable ? TRACKING_ACTION.SET_ONLINE : TRACKING_ACTION.SET_OFFLINE,
					// platforms: bizPlatforms,
					snoozed_until: isAvailable ? null : TRACKING_MISC.INDEFINITELY,
					bulk: false,
					number_of_entities: 1
				};
				trackEvent(TRACKING_EVENT_NAMES.AVAILABILITY, eventMeta);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Modifier availability at location updated successfully!",
						timeout: 2000,
						error: false
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while updating modifier availability at location",
						timeout: 2000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "There was an error while updating modifier availability at location",
					timeout: 2000,
					error: true
				}
			});
		}
		setStockLoadingId(undefined);
	};

	const updateBulkModifierAvailability = async (isAvailable) => {
		setLoading(true);
		try {
			const variables = {
				optionId: modifierId,
				olas: !allSelected
					? Object.values(locationUpdates)
							.filter((obj) => obj.status === true)
							.map((obj) => ({
								locationId: obj.locationId,
								price: obj.price,
								available: isAvailable,
								currentStock: obj.currentStock
							}))
					: [],
				associateAll: allSelected ? isAvailable : false,
				disassociateAll: allSelected ? !isAvailable : false,
				filters: []
			};
			if (appliedFilters?.name) {
				variables.filters.push({ field: "name", value: appliedFilters?.name });
			}
			if (appliedFilters?.city) {
				variables.filters.push({ field: "city", value: appliedFilters?.city });
			}
			const resp = await client.mutate({
				mutation: UPDATE_MODIFIER_SPECIFIC_DETAILS,
				variables
			});
			if (resp.data?.updateModifierLocationFields.status.success) {
				await fetchModifierLocations(appliedFilters.name, true);
				// track event
				const eventMeta = {
					entity: TRACKING_ENTITY.MODIFIER,
					source: TRACKING_SOURCE.DETAIL_VIEW,
					action: isAvailable ? TRACKING_ACTION.SET_ONLINE : TRACKING_ACTION.SET_OFFLINE,
					// platforms: platforms,
					snoozed_until: isAvailable ? null : TRACKING_MISC.INDEFINITELY,
					bulk: true,
					number_of_entities: variables.olas.length
				};
				trackEvent(TRACKING_EVENT_NAMES.AVAILABILITY, eventMeta);

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Modifier availability at location(s) updated successfully!",
						timeout: 2000,
						error: false
					}
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "There was an error while updating modifier availability at location(s)",
						timeout: 2000,
						error: true
					}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "There was an error while updating modifier availability at location(s)",
					timeout: 2000,
					error: true
				}
			});
		}
		setLoading(false);
	};

	const handleSubmit = async () => {
		setLoading(true);
		let olas = [];
		Object.keys(locationsPricesList).forEach((id) => {
			olas.push(locationsPricesList[id]);
		});
		const data = {
			optionId: parseInt(modifierId),
			olas
		};
		const resp = await updateModifierLocations(data, dispatch);
		if (resp) {
			setFormTouched(false);
			await fetchModifierLocations(appliedFilters.name, true);
			if (hasPriceChanged) {
				// track satismeter event
				SatismeterService.menuPublish();
				setHasPriceChanged(false);
			}
		}
		setLoading(false);
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleSubmit
		}),
		[handleSubmit]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
		},
		[limit]
	);

	const handleCheck = (locId, isChecked, available, currentStock, price) => {
		setLocationUpdates({
			...locationUpdates,
			[parseInt(locId)]: {
				locationId: locId,
				status: isChecked,
				price,
				available,
				currentStock
			}
		});
		if (!isChecked) {
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
			setAllSelected(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		locationsList.objects.forEach((loc) => {
			updates[parseInt(loc.location.id)] = {
				locationId: loc.location.id,
				status: toCheckAll,
				price: loc.price,
				available: loc.available,
				currentStock: loc.currentStock
			};
		});
		setLocationUpdates({
			...locationUpdates,
			...updates
		});
		if (toCheckAll && totalPages > 1) {
			setShowAllFieldSelector(true);
		}
		if (!toCheckAll) {
			setShowAllFieldSelector(false);
			setAllSelected(false);
		}
	};

	const handleCompleteSelection = (selectAllLocations = false) => {
		setAllSelected(selectAllLocations);
		handleCheckAll(selectAllLocations);
		setSelectAllLocations(selectAllLocations);
		if (!selectAllLocations) {
			setShowAllFieldSelector(false);
			setLocationUpdates({});
		}
	};

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchModifierLocations("", false);
			setLocationUpdates({});
			setLocationsPricesList({});
			setAllSelected(false);
			setSelectAllLocations(false);
			setShowAllFieldSelector(false);
			setIsCheckedAll(false);
		}
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrandSelection = (field, value) => {
		setSelectedBrand(value);
	};

	useEffect(() => {
		fetchModifierLocations("", false);
	}, [fetchModifierLocations, appliedFilters, selectedBrand]);

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	return (
		<div className={"associated-locations-tab" + (loading ? " no-click" : "")} ref={tableRef}>
			<div className="header-container">
				<div>
					<div className="header-text">
						{isEnforcedOla ? "Associated Locations" : "Restrict to Locations"}
					</div>
					<div className="header-subtext">
						{isEnforcedOla
							? "This modifier is available at all locations the associated item is available at"
							: "This modifier is only available at locations it is restricted to"}
					</div>
				</div>
				{!readOnly && (
					<div>
						<Button clickHandler={openAssociationSidebar}>{isEnforcedOla ? "Update" : "Restrict"}</Button>
					</div>
				)}
			</div>
			<div className="filter-section">
				{isMultibrandEnabled && (
					<div className="brand-selector">
						<div className="filter-text">Location details for:</div>
						<SelectFilterCustom
							options={brandsWithoutAll}
							isLoading={brands.isLoading}
							field="brands"
							currValue={selectedBrand}
							setFilter={handleBrandSelection}
							labelKey="name"
							valueKey="id"
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
						/>
					</div>
				)}
				<div className="search-container">
					<InputWithLabel
						value={currFilters.name}
						onChange={(e) => setFilter("name", e.target.value)}
						placeholder="Name"
					></InputWithLabel>
					<SelectFilter
						options={associatedCitiesList}
						setFilter={(field, value) => setFilter("city", value?.value || undefined)}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="City"
						currValue={
							currFilters.city
								? { value: currFilters.city, valueForDisplay: currFilters.city }
								: undefined
						}
					/>
				</div>
			</div>
			{showAllFieldSelector && totalPages > 1 && (
				<SelectAllFields
					allSelected={allSelected}
					handleCompleteSelection={handleCompleteSelection}
					mainMessage={
						<span>
							{allSelected ? "All " : ""}
							<strong>{allSelected ? locationsList.count : locationsList?.objects?.length} </strong>{" "}
							location(s) {allSelected ? "on all pages are selected." : "on this page are selected."}
						</span>
					}
					linkMessage={
						allSelected ? (
							" Clear selection"
						) : (
							<span>
								{" "}
								Select <strong>{locationsList.count}</strong> locations from all pages
							</span>
						)
					}
				/>
			)}
			{totalChanges > 0 && (
				<div className="toggle-item-availability">
					<Button
						classes={"at-btn--danger" + (loading ? " disabled" : "")}
						clickHandler={() => updateBulkModifierAvailability(false)}
					>
						Mark {allSelected ? `all ${locationsList.count}` : totalChanges} location(s) unavailable
					</Button>
					<Button
						classes={loading ? "disabled" : ""}
						clickHandler={() => updateBulkModifierAvailability(true)}
					>
						Mark {allSelected ? `all ${locationsList.count}` : totalChanges} location(s) available
					</Button>
				</div>
			)}
			<Table
				data={locationsList.objects}
				loading={loading}
				currencySymbol={currencySymbol}
				handleForm={handleForm}
				handleStockInOut={updateModifierAvailability}
				stockLoadingId={stockLoadingId}
				handleNestedEntity={handleNestedEntity}
				handleCheck={handleCheck}
				locationUpdates={locationUpdates}
				isCheckedAll={isCheckedAll}
				handleCheckAll={handleCheckAll}
				readOnly={readOnly}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={locationsList.count}
				goToPage={handlePagination}
				readOnly={allSelected}
			/>
			<LocationEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={LOCATION_ENTITY_ASSOCIATION_TYPES[1]}
				entityId={modifierId}
				selectedBrand={selectedBrand}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(AssociatedLocations);

export const Table = ({
	data,
	loading,
	currencySymbol,
	handleForm,
	handleStockInOut,
	stockLoadingId,
	sortList,
	sortedField,
	handleNestedEntity,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	locationUpdates,
	readOnly
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container modifiers-edit-locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						readOnly={readOnly}
						headerFields={MODIFIERS_LOCATIONS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={i}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							currencySymbol={currencySymbol}
							{...data[i]}
							handleForm={handleForm}
							handleNestedEntity={handleNestedEntity}
							handleStockInOut={handleStockInOut}
							stockLoadingId={stockLoadingId}
							handleCheck={handleCheck}
							locationUpdates={locationUpdates}
							readOnly={readOnly}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Locations found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, sortList, headerFields, readOnly, sortInfo }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					{field.value === "name" ? (
						<div className={"checkbox-sort" + (sortInfo?.field === "associated" ? " selected" : "")}>
							<CheckBox
								checked={isCheckedAll}
								clickHandler={() => handleCheckAll(!isCheckedAll)}
								title={field.label}
								readOnly={readOnly}
							>
								<span className="text">{field.label}</span>
							</CheckBox>
						</div>
					) : (
						<span>{field.label}</span>
					)}
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	location,
	price,
	available,
	currentStock,
	currencySymbol,
	handleForm,
	handleStockInOut,
	stockLoadingId,
	style,
	handleNestedEntity,
	locationUpdates = {},
	handleCheck,
	readOnly
}) => {
	const status = locationUpdates[location.id]?.status ? true : false;
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text name" title={location.name}>
				<CheckBox
					checked={status}
					clickHandler={() => handleCheck(location.id, !status, available, currentStock, price)}
					readOnly={readOnly}
				>
					<div className="location-desc">
						<a
							role="button"
							className="link-text"
							onClick={(e) => {
								handleNestedEntity(true, NESTED_ENTITY_TYPES[2], location.id);
								e.stopPropagation();
							}}
						>
							{location.name
								? location.name.length <= 20
									? location.name
									: `${location.name.slice(0, 20)}...`
								: location.id}
						</a>
						<div
							className="availableAtLocation"
							data-is-available={available}
							title="Click to toggle availability status"
							onClick={
								!readOnly
									? (e) => {
											handleStockInOut(location.id, price, currentStock, !available);
											e.stopPropagation();
									  }
									: (e) => e.stopPropagation()
							}
						>
							{stockLoadingId === location.id ? (
								<div className={"loader " + (available ? "out" : "in")}>
									<div></div>
									<div></div>
									<div></div>
								</div>
							) : (
								<div>{available ? "Available" : "Not available"}</div>
							)}
							{/* {
								!readOnly &&
								<div className={"stock "+(available ? "out" : "in")}>
									<img src={available ? "/assets/icons/icon-red-arrow.svg" : "/assets/icons/icon-green-arrow.svg"} alt="" />
								</div>
							} */}
						</div>
					</div>
				</CheckBox>
				<div className="tags">
					{location.tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{location.tags.map(
									(tag, i) =>
										i < 2 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{location.tags.length > 2 && (
									<span className="tag-item more-tags" title={location.tags.slice(2).join(", ")}>
										+{location.tags.length - 2} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text city">{location.city}</div>
			<div className="at-table-cell at-cell-text stock-count">
				<InputWithLabel
					value={currentStock}
					onChange={(e) =>
						handleForm("currentStock", e.target.value ? parseInt(e.target.value) : null, location.id)
					}
					type="number"
					placeholder="Enter stock count"
					readOnly={readOnly}
				/>
			</div>
			<div className="at-table-cell at-cell-text price">
				<InputWithLabel
					value={price}
					onChange={(e) => handleForm("price", e.target.value ? Number(e.target.value) : null, location.id)}
					type="number"
					placeholder="Enter Price"
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={currencySymbol}
					readOnly={readOnly}
				/>
			</div>
		</animated.div>
	);
};
