import React, { useState, useRef } from "react";

// components
import { Topbar } from "../../components/_commons/Topbar";
import Users from "../../containers/Users";
import Roles from "../../containers/Roles";
import Invites from "../../containers/Invites";
import UserInvite from "../../containers/UserInvite";

// third party
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// utils
import { scroll } from "../../atlas-utils";

const FORM_TABS = [
	{
		label: "Users",
		value: "users"
	},
	{
		label: "Roles",
		value: "roles"
	},
	{
		label: "Invites",
		value: "invites"
	}
];

const UserManagementContainer = () => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const topRef = useRef();

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	return (
		<div className="user-management-section section-container-common" ref={topRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Users</div>
					<div className="subheader-text">View and manage your user information, roles and staff invites</div>
				</div>
			</div>
			<Topbar tabs={FORM_TABS} selectedTab={formTab} switchTab={switchTab} isStickyOnTop={true} />
			<div className="billing-container">
				{formTab === FORM_TABS[0].value && <Users />}
				{formTab === FORM_TABS[1].value && <Roles />}
				{formTab === FORM_TABS[2].value && <Invites />}
				<Route exact path="/access/invite" render={() => <UserInvite currTab={formTab} />} />
			</div>
		</div>
	);
};
export default UserManagementContainer;
