import React, { useEffect, useRef, useState } from "react";

// third party
import { useSpring, animated as a } from "react-spring";
import { debounce } from "lodash";

// components
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";

// actions
import { fetchCitiesList } from "../../actions/locations";
import { ActionTypes } from "../../actions/_types";

// utils
import { trackEvent } from "../../atlas-utils";

// clients
import { store } from "../../store/configureStore";

// constants
import { countrySpecificPopularCities } from "../../client-config";
import { connect } from "react-redux";

const MultipleCitySelection = ({
	handleSelectedTabUpdate,
	handleFormData,
	multiLocationDetails,
	platformLocationLinks,
	bizCountry
}) => {
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});
	let availablePlatforms = {};
	Object.keys(platformLocationLinks).map((platform) => {
		availablePlatforms = {
			...availablePlatforms,
			[platform]: {
				id: "",
				url: ""
			}
		};
	});

	const valueEntryPrototype = {
		locationName: "",
		city: "",
		address: "",
		email: "",
		phone: "",
		locationPolygon: [],
		locationRadius: "",
		locationLatLng: {},
		storeTimings: null,
		platformLocationLinks: JSON.parse(JSON.stringify(availablePlatforms))
	};

	const initArrayValue = [];
	for (let i = 0; i < 100; i++) {
		initArrayValue.push({ ...valueEntryPrototype });
	}

	const country = bizCountry.toLowerCase() === "united kingdom" ? "uk" : bizCountry.toLowerCase();
	const INIT_CITY_ARRAY_VALUES = [...(countrySpecificPopularCities[country] ?? countrySpecificPopularCities.india)];

	const [locationDetailsArray, setLocationDetailsArray] = useState(
		multiLocationDetails.length === 100
			? [...multiLocationDetails]
			: [...multiLocationDetails, ...initArrayValue.slice(multiLocationDetails.length || 0, 100)]
	);
	const scrollRef = useRef(0);
	const [scrollClass, setScrollClass] = useState("");
	const [gotoNextDisabled, setGotoNextDisabled] = useState(false);
	const [warning, setWarning] = useState(false);
	const [citiesList, setCitiesList] = useState(INIT_CITY_ARRAY_VALUES);
	const [searchingCities, setSearchingCities] = useState({
		state: false,
		index: undefined
	});
	const [selectedCityValues, setSelectedCityValues] = useState([
		...locationDetailsArray.map((locationDetails) =>
			locationDetails.city ? { cityName: locationDetails.city } : undefined
		)
	]);

	const handleChange = (e, type, index) => {
		let newLocationDetailsArray = [...locationDetailsArray];
		newLocationDetailsArray[index] = { ...newLocationDetailsArray[index], [type]: e.target.value };
		setLocationDetailsArray(newLocationDetailsArray);
		handleFormData("multiLocationDetails", newLocationDetailsArray);
		if (
			!newLocationDetailsArray[index].city &&
			!newLocationDetailsArray[index].locationName &&
			!newLocationDetailsArray[index].address
		) {
			setWarning(false);
		} else if (
			!gotoNextDisabled &&
			(!newLocationDetailsArray[index].city ||
				!newLocationDetailsArray[index].locationName ||
				!newLocationDetailsArray[index].address)
		) {
			setWarning(true);
		} else {
			setWarning(false);
		}
	};

	const listenToScroll = (e) => {
		if (scrollRef.current?.scrollTop === 0) {
			setScrollClass("");
		} else {
			setScrollClass("show-border-bottom");
		}
	};

	const handleNextClick = () => {
		handleFormData("multiLocationDetails", [
			...locationDetailsArray.filter((locDetails) => locDetails.locationName && locDetails.city)
		]);
		handleSelectedTabUpdate(4);
		trackEvent("wiz_loc_tabular_location_details_entry", {
			cities: locationDetailsArray.map((locDetails) => locDetails?.city)
		});
	};

	const handlePasteOperation = (pastedText, startIndex) => {
		const checkForNewLine = pastedText.includes("\n");

		if (!checkForNewLine) {
			const newLocationDetailsArray = [...locationDetailsArray];
			newLocationDetailsArray[startIndex] = { ...newLocationDetailsArray[startIndex], locationName: pastedText };
			handleFormData("multiLocationDetails", [...newLocationDetailsArray]);
			setLocationDetailsArray([...newLocationDetailsArray]);
			return;
		}

		const rowWiseDataDistribution = pastedText.split("\n");
		let stopOperation = false;
		for (let i = 0; i < rowWiseDataDistribution.length; i++) {
			rowWiseDataDistribution[i] = rowWiseDataDistribution[i].split("\t");
			if (rowWiseDataDistribution[i].length !== 2) {
				stopOperation = true;
			}
		}

		if (!stopOperation) {
			const newLocationDetailsArray = [...locationDetailsArray];
			for (let i = 0; i < rowWiseDataDistribution.length; i++) {
				if (startIndex + i === 100) {
					break;
				}
				newLocationDetailsArray[startIndex + i] = {
					locationName: rowWiseDataDistribution[i][0],
					city: "",
					address: rowWiseDataDistribution[i][1],
					email: "",
					phone: "",
					locationPolygon: [],
					locationRadius: "",
					locationLatLng: {},
					storeTimings: null,
					platformLocationLinks: {
						zomato: { id: "", url: "" },
						swiggy: { id: "", url: "" },
						foodpanda: { id: "", url: "" },
						amazon: { id: "", url: "" },
						talabat: { id: "", url: "" },
						ubereats: { id: "", url: "" }
					}
				};
			}
			handleFormData("multiLocationDetails", [...newLocationDetailsArray]);
			setLocationDetailsArray([...newLocationDetailsArray]);
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Expected a tabular data with 2 columns.",
					timeout: 5000,
					error: true
				}
			});
			handleChange({ target: { value: "" } }, "locationName", 0);
		}
	};

	const setSelectFilter = (key, val, index) => {
		if (!val) {
			setSelectedCityValues((current) => [
				...current.map((selectedCity, i) => {
					if (i === index) {
						return undefined;
					} else {
						return selectedCity;
					}
				})
			]);
			handleChange({ target: { value: "" } }, "city", index);
			return;
		}
		setSelectedCityValues((current) => [
			...current.map((selectedCity, i) => {
				if (i === index) {
					return {
						cityName: val.cityName.split(",")[0]
					};
				} else {
					return selectedCity;
				}
			})
		]);
		handleChange({ target: { value: val.cityName.split(",")[0] } }, "city", index);
	};

	const searchCities = async (searchCity, index) => {
		if (searchCity === "") {
			setCitiesList(INIT_CITY_ARRAY_VALUES);
			return;
		}

		setSearchingCities({
			state: true,
			index
		});
		const citySearchResult = await fetchCitiesList(searchCity);

		if (Array.isArray(citySearchResult?.data)) {
			const updatedCitiesList = [...citySearchResult.data];
			setCitiesList([
				...updatedCitiesList.map((city) => ({
					cityName: `${city.name}, ${city.state_name}, ${city.country_name}`
				}))
			]);
		}
		setSearchingCities(false);
	};

	const searchCitiesDebounced = debounce((searchCity, index) => searchCities(searchCity, index), 500);

	useEffect(() => {
		for (let i = 0; i < locationDetailsArray.length; i++) {
			if (locationDetailsArray[i].city && locationDetailsArray[i].locationName) {
				setGotoNextDisabled(false);
				break;
			} else {
				setGotoNextDisabled(true);
			}
		}
	}, [locationDetailsArray]);

	return (
		<a.div className="common-box-structure " style={contentProps}>
			<div className="header">Provide location details</div>
			{/* <div className="csv-file-reader-icon">
				<img src={"/assets/icons/icon-csv-file.svg"} />
				<img className="search-icon" src="/assets/header-icons/icon-search.svg" />
			</div> */}
			{
				<div
					className="links-entry-table multi-city-table"
					ref={scrollRef}
					onScroll={listenToScroll}
					id="style-scrollbar"
				>
					<div className={`row ${scrollClass}`}>
						<div className="col"></div>
						<div className="col">Name</div>
						<div className="col">Address</div>
						<div className="col">City</div>
					</div>
					{locationDetailsArray.map((details, i) => (
						<a.div key={i} className="row" style={contentProps}>
							<div className="col">{i + 1}</div>
							<div className="col">
								<input
									autoFocus={i === 0}
									placeholder={i === 0 && "eg: Indiranagar Store"}
									value={details.locationName}
									onChange={(e) => handleChange(e, "locationName", i)}
									onPaste={(e) => {
										handlePasteOperation(e.clipboardData.getData("Text"), i);
									}}
								/>
							</div>
							<div className="col">
								<input
									onChange={(e) => handleChange(e, "address", i)}
									className="platform-url-input"
									placeholder={i === 0 && "eg: Unit #123, Forum Mall, Indiranagar"}
									value={details.address}
								/>
							</div>
							<div className="col">
								<SelectFilter
									currValue={selectedCityValues[i]}
									options={citiesList}
									labelKey="cityName"
									valueKey="cityName"
									setFilter={(key, val) => {
										setSelectFilter(key, val, i);
									}}
									isAsync={true}
									isLoading={searchingCities.state && searchingCities.index === i}
									handleSearch={(searchText) => searchCitiesDebounced(searchText, i)}
								/>
							</div>
						</a.div>
					))}
				</div>
			}
			{/* <div className="warning-message" style={{opacity: warning ? '1' : '0'}}>
				*Warning: one or more fields are partially filled and such fields won't be considered.
			</div> */}
			<div className="button-div">
				<Button clickHandler={() => handleSelectedTabUpdate(2)} type="secondary">
					<span>Previous</span>
				</Button>
				<Button classes={gotoNextDisabled ? "disabled" : ""} clickHandler={handleNextClick}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
const mapStateToProps = (store) => ({
	bizCountry: store.login.loggedInbizDetail.country
});
export default connect(mapStateToProps)(MultipleCitySelection);
