import React, { useState, useEffect, useMemo, useCallback } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { FormContainer } from "../../containers/Meraki";
import { CommonTable } from "../_commons/CommonTable";

// third party
import { connect } from "react-redux";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchMerakiWebLabels, editMerakiWebLabels } from "../../actions/merakiWeb";

const columns = [
	{
		name: "Section",
		field: "page",
		sortKey: "",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text page"} key={i}>
				{record.page || "--"}
			</div>
		)
	},
	{
		name: "Label",
		field: "label",
		sortKey: "",
		render: (record, i) => (
			<div className={"at-table-cell at-cell-text label"} key={i}>
				{record.labelValue || "--"}
			</div>
		)
	},
	{
		name: "Language",
		field: "language",
		sortKey: "",
		render: (record, i, _, _1, handleTranslation) => (
			<div className={"at-table-cell at-cell-text language"} key={i}>
				<InputWithLabel
					value={record.labelCustomValue}
					onChange={(e) => handleTranslation(record, e.target.value)}
					placeholder="Search label"
				/>
			</div>
		)
	}
];

const supportedLanguages = [
	{
		valueForDisplay: "English",
		value: "en"
	},
	{
		valueForDisplay: "Arabic",
		value: "ar"
	},
	{
		valueForDisplay: "Spanish",
		value: "es"
	}
];

const FILTER_INITIAL_STATE = {
	label: "",
	page: undefined,
	lang: undefined
};
const Labels = () => {
	const [loading, setLoading] = useState(false);
	const [loadingCustomLabels, setLoadingCustomLabels] = useState(false);
	const [pages, setPages] = useState([]);
	const [defaultLabels, setDefaultLabels] = useState({});
	const [currentLabels, setCurrentLabels] = useState({});
	const [customLabels, setCustomLabels] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [filters, setFilters] = useState(FILTER_INITIAL_STATE);
	const [labelCustomizations, setLabelCustomizations] = useState({});

	const getLabelTranslation = useCallback(
		(page, labelKey) => {
			if (labelCustomizations[page] && labelCustomizations[page][labelKey] !== undefined) {
				return labelCustomizations[page][labelKey];
			}
			if (customLabels[page] && customLabels[page][labelKey]) {
				return customLabels[page][labelKey];
			}
			return currentLabels[page][labelKey];
		},
		[labelCustomizations, customLabels, currentLabels]
	);

	const labelRows = useMemo(() => {
		let data = [];
		if (filters.lang) {
			Object.keys(currentLabels).forEach((page) => {
				if (filters.page && filters.page.value !== page) {
					return;
				}
				Object.keys(currentLabels[page]).forEach((labelKey) => {
					const defaultLabelValue = defaultLabels?.[page]?.[labelKey];
					if (filters.label && !defaultLabelValue?.toLowerCase()?.includes(filters.label.toLowerCase())) {
						return;
					}
					data.push({
						page,
						labelKey,
						labelValue: defaultLabelValue,
						labelCustomValue: getLabelTranslation(page, labelKey)
					});
				});
			});
		}
		return data.slice(0, 20);
	}, [defaultLabels, currentLabels, filters, getLabelTranslation]);

	const handleFilter = useCallback(
		(field, value) => {
			if (field === "lang") {
				setLabelCustomizations({});
				setFormTouched(false);
			}
			const newFilters = {
				...filters,
				[field]: value
			};
			setFilters(newFilters);
		},
		[filters]
	);

	const fetchDefaultLabels = useCallback(async () => {
		setLoading(true);
		try {
			const defaultLabels = await fetchMerakiWebLabels("default", "en");
			const pages = Object.keys(defaultLabels).map((pg) => ({
				value: pg,
				label: pg[0].toUpperCase() + pg.slice(1)
			}));
			setPages(pages);
			setDefaultLabels(defaultLabels);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, []);

	const fetchCustomLabels = useCallback(async () => {
		if (!filters.lang) {
			return;
		}
		try {
			const customLabels = await fetchMerakiWebLabels("custom", filters.lang.value);
			setCustomLabels(customLabels.data || {});
		} catch (error) {
			console.log(error);
		}
	}, [filters.lang]);

	const fetchCurrentLabels = useCallback(async () => {
		if (!filters.lang) {
			return;
		}
		try {
			const currentLabels = await fetchMerakiWebLabels("default", filters.lang.value);
			setCurrentLabels(currentLabels);
		} catch (error) {
			console.log(error);
		}
	}, [filters.lang]);

	const fetchLabelsData = useCallback(async () => {
		setLoadingCustomLabels(true);
		await fetchCustomLabels();
		await fetchCurrentLabels();
		setLoadingCustomLabels(false);
	}, [fetchCurrentLabels, fetchCustomLabels]);

	useEffect(() => {
		fetchDefaultLabels();
		// set default language
		handleFilter("lang", supportedLanguages[0]);
	}, []);

	useEffect(() => {
		fetchLabelsData();
	}, [fetchLabelsData]);

	const saveLabels = async () => {
		setLoading(true);
		try {
			let updates = [];
			Object.keys(labelCustomizations).forEach((page) => {
				Object.keys(labelCustomizations[page]).forEach((labelKey) => {
					updates.push({
						page,
						labelKey,
						labelValue: labelCustomizations[page][labelKey]
					});
				});
			});
			const data = {
				lang: filters.lang.value,
				updates
			};
			await editMerakiWebLabels(data);
			await fetchCustomLabels();
			setLabelCustomizations({});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Label customizations saved!",
					timeout: 2000,
					error: false
				}
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const handleLabelsField = (record, value) => {
		const updatedLabels = {
			...labelCustomizations
		};
		if (updatedLabels[record.page]) {
			updatedLabels[record.page] = {
				...labelCustomizations[record.page],
				[record.labelKey]: value
			};
		} else {
			updatedLabels[record.page] = {
				[record.labelKey]: value
			};
		}
		setLabelCustomizations(updatedLabels);
		setFormTouched(true);
	};

	const handleCancel = async () => {
		setLabelCustomizations({});
		setFormTouched(false);
	};

	const handleSubmit = async () => {
		await saveLabels();
		setFormTouched(false);
	};

	if ((loading || loadingCustomLabels) && labelRows.length === 0) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	if (supportedLanguages) {
		columns[2].name = filters.lang ? filters.lang.valueForDisplay : supportedLanguages[0].valueForDisplay;
	}

	return (
		<div
			className={
				"labels-container " + ((loading || loadingCustomLabels) && labelRows.length !== 0 ? "disabled" : "")
			}
		>
			<FormContainer cancel={handleCancel} submit={handleSubmit} submitTitle="Save" hideActions={!isFormTouched}>
				<div className="form-content">
					<div className="label-filters-container">
						<SelectFilter
							title="Section"
							options={pages}
							field="page"
							currValue={filters.page}
							setFilter={handleFilter}
							placeholder="Select section"
							isSearchable={false}
						/>
						<InputWithLabel
							value={filters.label}
							onChange={(e) => handleFilter("label", e.target.value)}
							placeholder="Search label"
						>
							Label
						</InputWithLabel>
						<SelectFilter
							title="Language"
							options={supportedLanguages}
							field="lang"
							currValue={filters.lang}
							setFilter={handleFilter}
							labelKey="valueForDisplay"
							valueKey="value"
							isClearable={false}
							isSearchable={false}
						/>
					</div>
					<CommonTable
						loading={loading}
						data={labelRows || []}
						columns={columns}
						classes="labels-list-table-container"
						content="Labels"
						handleTask={handleLabelsField}
						bordered={true}
					/>
				</div>
			</FormContainer>
		</div>
	);
};
export default connect((store) => ({
	// supportedLanguages: store.login.loggedInbizDetail.supportedLanguages
}))(Labels);
