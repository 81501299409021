import React from "react";

const SuccessIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
		<path
			d="M33 0.5C15.0512 0.5 0.5 15.0512 0.5 33C0.5 50.9487 15.0512 65.5 33 65.5C50.9487 65.5 65.5 50.9487 65.5 33C65.5 15.0512 50.9487 0.5 33 0.5ZM29.3512 46.435L16.34 34.8687L19.6613 31.1312L29.15 39.565L48 19.25L52.2675 23.5175L29.3512 46.435Z"
			fill="#2ECC71"
		/>
	</svg>
);
export default React.memo(SuccessIcon);
