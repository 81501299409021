import React, { useState, useCallback, useEffect } from "react";

// third party
import { Link } from "react-router-dom";

// component
import { Button } from "../_commons/Button";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { printCurrency } from "../../atlas-utils";

// graphql
import { GET_ITEM_TAXES_AND_CHARGES } from "../../graphql/items";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";

const TaxesAndCharges = ({ itemId, match, currencySymbol, handleNestedEntity }) => {
	const [loading, setLoading] = useState(false);
	const [taxesData, setTaxesData] = useState([]);
	const [chargesData, setChargesData] = useState([]);

	const fetchTaxesAndCharges = useCallback(
		async (filters = {}) => {
			try {
				setLoading(true);
				const variables = {
					id: parseInt(itemId),
					limit: 100,
					offset: 0
				};
				if (match?.params?.path === "locations" && match?.params?.locationId) {
					variables.filters = [{ field: "locations", value: match.params?.locationId }];
				}
				const resp = await client.query({
					query: GET_ITEM_TAXES_AND_CHARGES,
					variables,
					fetchPolicy: "no-cache"
				});
				setTaxesData(resp.data.item.taxes.objects);
				setChargesData(resp.data.item.charges.objects);
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[itemId, match]
	);

	useEffect(() => {
		fetchTaxesAndCharges();
	}, [fetchTaxesAndCharges]);

	if (loading) {
		return (
			<div className="P(10px 0)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"item-taxes-and-charges-tab" + (loading ? " no-click" : "")}>
			<div className="taxes-container">
				<div className="taxes-header-text">Taxes Applicable</div>
				<div className="taxes-list">
					{taxesData.length === 0 ? (
						<div className="header-container">
							<div className="header-text">No taxes applicable</div>
							<Link className="link-text" to="/piper-academy/taxes">
								Learn how to add taxes
							</Link>
						</div>
					) : (
						taxesData.map((taxes, i) => (
							<TaxesAndChargesData
								key={i}
								{...taxes}
								entity="taxes"
								currencySymbol={currencySymbol}
								handleNestedEntity={handleNestedEntity}
							/>
						))
					)}
				</div>
			</div>
			<div className="charges-container">
				<div className="charges-header-text">Charges Applicable</div>
				<div className="charges-list">
					{chargesData.length === 0 ? (
						<div className="header-container">
							<div className="header-text">No charges applicable</div>
							<Link className="link-text" to="/piper-academy/charges">
								Learn how to add charges
							</Link>
						</div>
					) : (
						chargesData.map((charges, i) => (
							<TaxesAndChargesData
								key={i}
								{...charges}
								entity="charges"
								currencySymbol={currencySymbol}
								handleNestedEntity={handleNestedEntity}
							/>
						))
					)}
				</div>
			</div>
		</div>
	);
};
export default TaxesAndCharges;

const TaxesAndChargesData = ({ id, entity, title, applicableOn, value, currencySymbol, handleNestedEntity }) => {
	return (
		<div className="header-container">
			{entity === "taxes" && (
				<div>
					<div className="header-text">{title}</div>
					<div className="header-subtext">
						{value}% on {applicableOn || "--"}
					</div>
				</div>
			)}
			{entity === "charges" && (
				<div>
					<div className="header-text">{title}</div>
					<div className="header-subtext">
						{applicableOn === "Item Quantity" || applicableOn === "Flat Charge" ? (
							<span>
								{printCurrency(currencySymbol)}
								{value} per {applicableOn || "--"}
							</span>
						) : (
							<span>
								{value}% on {applicableOn || "--"}
							</span>
						)}
					</div>
				</div>
			)}
			<div>
				<a
					role="button"
					onClick={() =>
						handleNestedEntity(
							true,
							entity === "taxes" ? NESTED_ENTITY_TYPES[3] : NESTED_ENTITY_TYPES[4],
							id
						)
					}
				>
					<Button>View</Button>
				</a>
			</div>
		</div>
	);
};
