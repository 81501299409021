import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import PendingInvites from "./PendingInvites";
import UnifiedInvitesList from "./UnifiedInvitesList";
import UnifiedInviteDetail from "./UnifiedInviteDetail";

const InvitesContainer = () => {
	return (
		<React.Fragment>
			<div className="user-management invites">
				<Route path="/access" component={PendingInvites} />
			</div>
			<div className="unified-user-management invites">
				<Route path="/unified-access" component={UnifiedInvitesList} />
				<Route exact path="/unified-access/preview/:id" component={UnifiedInviteDetail} />
			</div>
		</React.Fragment>
	);
};
export default InvitesContainer;
