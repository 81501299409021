import React, { useEffect, useState } from "react";
// third party
import { connect } from "react-redux";
// component
import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";
import Image from "../_commons/Image";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import {
	placeTestCart,
	removeItemsTestCartDebounced,
	resetTestCart,
	addItemsTestCartDebounced
} from "../../actions/menus";
import NotificationServices from "../../services/NotificationService";
import CustomisableItemDrawer from "./CustomisableItemDrawer";
import Placeholder from "../_commons/Placeholder";
import { printCurrency } from "../SiteComp";

const TestCart = ({
	isTestCartOpen,
	showHideTestCart,
	menuTestCart,
	menuId,
	selectedLocation,
	selectedPlatform,
	currencySymbol
}) => {
	const [testOrderStep, setTestOrderStep] = useState(1);
	const [cartItems, setCartItems] = useState(menuTestCart?.data?.cartObject?.items || []);
	const billDetails = menuTestCart?.data?.cartObject?.meta || [];

	useEffect(() => {
		setCartItems(menuTestCart?.data?.cartObject?.items);
	}, [menuTestCart]);

	const stepwiseComponents = {
		1: () => <ItemQuantitySelectionTable />,
		2: () => <TestOrderSuccess />,
		3: () => <TestOrderFailed />
	};

	const [customisableDrawerDetails, setCustomisableDrawerDetails] = useState({
		isOpen: false,
		itemId: null,
		selectedOptions: {}
	});
	const itemsTableColumn = [
		{
			title: "Product",
			dataIndex: "item",
			render: (item, renderValuesAndFunctions, data) => {
				return (
					<div className="item-details-container">
						<Image
							src={data?.imgUrl || "/assets/empty-dish.jpg"}
							alt={data?.title}
							className="item-img"
							errorOverrideImage="/assets/empty-dish.jpg"
						/>
						<FoodTypeIndicator foodType={data?.foodType} />
						<div className="item-title">
							<span className="title">{data?.title}</span>
							{data?.options?.length > 0 && (
								<>
									<span className="addons">
										Add-Ons: {data?.options?.map((opt) => opt.title)?.join(", ")}
									</span>
									<span
										className="hyperlink"
										onClick={() =>
											renderValuesAndFunctions.handleCustomiseClick(data?.id, data.options)
										}
									>
										Customise &gt;
									</span>
								</>
							)}
						</div>
					</div>
				);
			}
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			render: (data, renderValuesAndFunctions, itemData) => {
				return (
					<div className="quantity-wrapper">
						<img
							src="/assets/icons/icon-minus.svg"
							className="minus-icon"
							onClick={() => {
								renderValuesAndFunctions.updateTestCartItems(itemData?.id, true);
							}}
						/>
						<span>{data}</span>
						<img
							src="/assets/icons/icon-plus.svg"
							className="plus-icon"
							onClick={() => {
								renderValuesAndFunctions.updateTestCartItems(itemData?.id);
							}}
						/>
					</div>
				);
			}
		},
		{
			title: "Price",
			dataIndex: "pricePerUnit",
			render: (data) => (
				<div>
					{printCurrency(currencySymbol)}
					{data}
					{/* <ContextMenu /> */}
				</div>
			)
		},
		{
			title: "total",
			dataIndex: "subtotal",
			render: (data) => {
				return (
					<div>
						{printCurrency(currencySymbol)}
						{data}
					</div>
				);
			}
		}
	];

	const handleSubmitAction = async () => {
		if (menuTestCart?.data?.cartId) {
			const res = await placeTestCart({ cartId: menuTestCart?.data?.cartId });
			if (res?.success) {
				NotificationServices.pushNotification({
					message: "Test order placed successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});

				await resetTestCart({ cartId: menuTestCart?.data?.cartId });
				setTestOrderStep(2);
			} else {
				NotificationServices.pushNotification({
					message: res?.error || "Something went wrong",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				setTestOrderStep(3);
			}
		}
	};

	const updateTestCartItems = (itemId, remove = false) => {
		const parsedItemId = parseInt(itemId, 10);
		const cartId = menuTestCart?.data?.cartId;
		const menuItem = cartItems.find((item) => item.id === parsedItemId);

		if (!cartId || !menuItem) return;

		const isRemovingLastItem = remove && menuItem.quantity === 1;
		const originalItem = menuTestCart?.data?.cartObject?.items?.find((item) => item.id === parsedItemId);

		if (isRemovingLastItem) {
			const updatedItems = [{ id: parsedItemId, quantity: originalItem?.quantity }];

			const remainingItems = cartItems.filter((item) => item.id !== parsedItemId);
			setCartItems(remainingItems);
			removeItemsTestCartDebounced({ cartId, items: updatedItems });

			if (remainingItems.length === 0) {
				setTimeout(() => showHideTestCart(false), 1000);
			}
			return;
		}

		const newCartItems = cartItems
			.map((item) => {
				if (item.id === parsedItemId) {
					const newQuantity = remove ? item.quantity - 1 : item.quantity + 1;
					return newQuantity > 0 ? { ...item, quantity: newQuantity } : null;
				}
				return item;
			})
			.filter(Boolean);

		setCartItems(newCartItems);

		const updatedItems = cartItems
			.filter((item) => item.id === parsedItemId)
			.map(({ id, quantity }) => {
				const quantityChange = remove
					? originalItem?.quantity - (quantity - 1)
					: quantity + 1 - originalItem?.quantity;

				return { id, quantity: quantityChange };
			});

		if (remove) {
			removeItemsTestCartDebounced({ cartId, items: updatedItems });
		} else {
			addItemsTestCartDebounced({ cartId, items: updatedItems });
		}

		if (newCartItems.length === 0) {
			setTimeout(() => showHideTestCart(false), 1000);
		}
	};

	const handleCustomiseClick = (itemId, selectedOptions) => {
		const selectedAddons = selectedOptions.map((opt) => opt.id);
		const selectedAddonsObj = selectedAddons.reduce((obj, id) => {
			obj[id] = true;
			return obj;
		}, {});
		setCustomisableDrawerDetails({ itemId: String(itemId), selectedOptions: selectedAddonsObj, isOpen: true });
	};
	const showHideCustomisableItemDrawer = (isOpen = false, itemId) => {
		if (!isOpen) {
			setCustomisableDrawerDetails({ isOpen, itemId: null });
			return;
		}
		setCustomisableDrawerDetails({ isOpen, itemId });
	};
	const renderValuesAndFunctions = {
		updateTestCartItems,
		handleCustomiseClick
	};
	const ItemQuantitySelectionTable = () => {
		return (
			<>
				<div className="item-qty-selection-table">
					<FormTable
						columns={itemsTableColumn}
						dataSource={cartItems}
						renderValuesAndFunctions={renderValuesAndFunctions}
					/>
				</div>
				<div className="bill-details">
					<div className="bill-section">
						<p className="bill-section-title"> Item Total</p>
						<p className="bill-section-price">
							{printCurrency(currencySymbol)}
							{billDetails?.subTotal}
						</p>
					</div>
					<div className="divider"></div>
					<div className="bill-section">
						<p className="bill-section-title"> Charges</p>
						<p className="bill-section-price">
							{printCurrency(currencySymbol)}
							{billDetails?.totalCharges}
						</p>
					</div>
					<div className="bill-section">
						<p className="bill-section-title">Taxes</p>
						<p className="bill-section-price">
							{printCurrency(currencySymbol)}
							{billDetails?.totalTaxes}
						</p>
					</div>
					<div className="divider"></div>
					<div className="bill-section">
						<p className="bill-subtotal-title">Subtotal</p>
						<p>
							{printCurrency(currencySymbol)}
							{billDetails?.total}
						</p>
					</div>
				</div>
			</>
		);
	};
	const TestOrderSuccess = () => {
		return (
			<>
				<Placeholder
					placeholderImageUrl={"/assets/empty-states/test-cart-success.svg"}
					placeholderText={"Test Order Sent!"}
					placeholderSubtext={`Order pushed to ${selectedLocation?.name} , ${selectedPlatform?.platformName}`}
					secondaryButtonContent={"Close"}
					secondaryButtonClickAction={() => showHideTestCart(false)}
				/>
			</>
		);
	};
	const TestOrderFailed = () => {
		return (
			<>
				<Placeholder
					placeholderImageUrl={"/assets/empty-states/test-cart-failed.svg"}
					placeholderText={"Test Order Failed"}
					placeholderSubtext={"Order was not pushed due to some errors."}
					placeholderButtonContent={"Retry"}
					placeholderButtonClickAction={handleSubmitAction}
					secondaryButtonContent={"Close"}
					secondaryButtonClickAction={() => showHideTestCart(false)}
				/>
			</>
		);
	};

	return (
		<FormSidebar
			isOpen={isTestCartOpen}
			title="Test Cart"
			subTitle={testOrderStep === 1 && "Push a test order to your POS"}
			submitTitle={"Place Test Order"}
			submit={handleSubmitAction}
			submitButtonWidth={200}
			close={() => showHideTestCart(false)}
			hideActions={testOrderStep === 2 || testOrderStep === 3 || cartItems?.length === 0}
		>
			<div className="test-cart-container">{stepwiseComponents[testOrderStep]()}</div>

			{customisableDrawerDetails?.isOpen && (
				<CustomisableItemDrawer
					isOpen={customisableDrawerDetails?.isOpen}
					showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
					itemId={customisableDrawerDetails?.itemId}
					menuId={menuId}
					selectedLocation={selectedLocation}
					selectedPlatform={selectedPlatform}
					prevSelectedAddonsAndVariants={customisableDrawerDetails?.selectedOptions}
					isNested
				/>
			)}
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	menuTestCart: store.menuTestCart,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
export default connect(mapStateToProps)(TestCart);
