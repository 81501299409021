import React, { useState, useEffect } from "react";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { Switch } from "../_commons/Switch";

// third party
import { connect } from "react-redux";

// constant
const paymentGatewayMap = {
	paytm: "PayTM",
	paypal: "PayPal",
	simpl: "Simpl"
};

const Apps = ({
	biz,
	data = {},
	loading,
	handleForm,
	validations = {},
	paymentConfigList = [],
	selectedPaymentConfig = {},
	handlePaymentConfig,
	handleConfigForm,
	locationsList = [],
	selectedLocation = {},
	handleLocation,
	handleLocationSearch,
	searchingLocation,
	showPaymentGatewayContainer = false,
	togglePaymentGateway
}) => {
	const [filteredPaymentConfigList, setFilteredPaymentConfigList] = useState([]);

	useEffect(() => {
		// if selected location is default then show all payment config ( which are available for dropdown (i.e. forDropdown is true) )
		// else only show payment gateways which support location wise configuration (i.e. supportLocationWiseConfig is true)
		setFilteredPaymentConfigList(
			paymentConfigList.filter((pg) => selectedLocation.id === "default" || pg.supportLocationWiseConfig === true)
		);
	}, [selectedLocation]);

	return (
		<div className="apps">
			<div apps="prepaidWallet" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-wallet.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Prepaid Wallet</div>
							<div className="header-subtext">
								Self-branded wallets to provide your customers a seamless payment experience
							</div>
						</div>
					</div>
					<Switch
						title="Enabled"
						checked={data.isPaymentsEnabled}
						clickHandler={() => handleForm("isPaymentsEnabled", !data.isPaymentsEnabled)}
						validationMessage={validations.isPaymentsEnabled || ""}
					/>
				</div>
				{data && data.isPaymentsEnabled && (
					<div className="form-container">
						<div className="form-row row-half">
							<Switch
								title="Credit allowed"
								checked={data.isPrepaidCreditAllowed}
								clickHandler={() => handleForm("isPrepaidCreditAllowed", !data.isPrepaidCreditAllowed)}
								validationMessage={validations.isPrepaidCreditAllowed || ""}
							/>
							<Switch
								title="Integrate with order checkout"
								checked={data.isOrderPayByPpcEnabled}
								clickHandler={() => handleForm("isOrderPayByPpcEnabled", !data.isOrderPayByPpcEnabled)}
								validationMessage={validations.isOrderPayByPpcEnabled || ""}
							/>
							<InputWithLabel
								value={data.minimumWalletCreditThreshold}
								type="number"
								onChange={(e) =>
									handleForm(
										"minimumWalletCreditThreshold",
										e.target.value ? Number(e.target.value) : null
									)
								}
								requiredLabel={true}
								validationMessage={validations.minimumWalletCreditThreshold || ""}
								showLabel={true}
								classes="at-input--label"
								currency={true}
								currencySymbol={biz.currencySymbol}
							>
								Minimum wallet credit
							</InputWithLabel>
						</div>
						<div className="form-row row-half">
							<InputWithLabel
								value={data.maxPrepaidTransactionAmt}
								type="number"
								onChange={(e) =>
									handleForm(
										"maxPrepaidTransactionAmt",
										e.target.value ? Number(e.target.value) : null
									)
								}
								requiredLabel={true}
								validationMessage={validations.maxPrepaidTransactionAmt || ""}
								showLabel={true}
								classes="at-input--label"
								currency={true}
								currencySymbol={biz.currencySymbol}
							>
								Maximum transaction value
							</InputWithLabel>
							<InputWithLabel
								value={data.maxAllowedPrepaidBalance}
								type="number"
								onChange={(e) =>
									handleForm(
										"maxAllowedPrepaidBalance",
										e.target.value ? Number(e.target.value) : null
									)
								}
								requiredLabel={true}
								validationMessage={validations.maxAllowedPrepaidBalance || ""}
								showLabel={true}
								classes="at-input--label"
								currency={true}
								currencySymbol={biz.currencySymbol}
							>
								Maximum prepaid balance
							</InputWithLabel>
							<Switch
								title="Bypass OTP for phone"
								checked={data.bypassOtpCheckForPrepaid}
								clickHandler={() =>
									handleForm("bypassOtpCheckForPrepaid", !data.bypassOtpCheckForPrepaid)
								}
								validationMessage={validations.bypassOtpCheckForPrepaid || ""}
							/>
						</div>
					</div>
				)}
			</div>
			<div apps="loyalty" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-loyalty.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Loyalty</div>
							<div className="header-subtext">
								Make your customers keep coming back by creating a loyalty program with in-built
								cashback, wallet support and a wide array of customisable options
							</div>
						</div>
					</div>
					<Switch
						title="Enabled"
						checked={data.isLoyaltyEnabled}
						clickHandler={() => handleForm("isLoyaltyEnabled", !data.isLoyaltyEnabled)}
						validationMessage={validations.isLoyaltyEnabled || ""}
					/>
				</div>
				{data && data.isLoyaltyEnabled && (
					<div className="form-container">
						<div className="form-row row-half">
							<InputWithLabel
								value={data.pointBalanceMapping}
								type="number"
								onChange={(e) =>
									handleForm("pointBalanceMapping", e.target.value ? Number(e.target.value) : null)
								}
								requiredLabel={true}
								validationMessage={validations.pointBalanceMapping || ""}
							>
								Points: Amount cashback ratio
							</InputWithLabel>
							<InputWithLabel
								value={data.pointUseThreshold}
								type="number"
								onChange={(e) =>
									handleForm("pointUseThreshold", e.target.value ? Number(e.target.value) : null)
								}
								requiredLabel={true}
								validationMessage={validations.pointUseThreshold || ""}
							>
								Min. points threshold to use
							</InputWithLabel>
							<Switch
								title="Loyalty Points Expiration Enabled"
								checked={data.loyaltyPointsExpirationEnabled}
								clickHandler={() =>
									handleForm("loyaltyPointsExpirationEnabled", !data.loyaltyPointsExpirationEnabled)
								}
								validationMessage={validations.loyaltyPointsExpirationEnabled || ""}
							/>
						</div>
						<div className="form-row row-half">
							<InputWithLabel
								value={data.defaultPointsExpiryDuration}
								type="number"
								onChange={(e) =>
									handleForm(
										"defaultPointsExpiryDuration",
										e.target.value ? Number(e.target.value) : null
									)
								}
								validationMessage={validations.defaultPointsExpiryDuration || ""}
							>
								Default points expiry duration (in days)
							</InputWithLabel>
						</div>
					</div>
				)}
			</div>
			<div apps="referral" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-referrals.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Referral</div>
							<div className="header-subtext">
								Grow the size of your customer base through incentivising your existing customers to
								introduce their family, friends and contacts
							</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={data.referralValidity}
							type="number"
							onChange={(e) =>
								handleForm("referralValidity", e.target.value ? Number(e.target.value) : null)
							}
							requiredLabel={true}
							validationMessage={validations.referralValidity || ""}
						>
							Validity for a referral to be active (in secs)
						</InputWithLabel>
					</div>
					<div className="form-row row-full">
						<Textarea
							value={data.referralUiLabel}
							onChange={(e) => handleForm("referralUiLabel", e.target.value)}
						>
							Referral UI custom text
						</Textarea>
					</div>
					<div className="form-row row-full">
						<Textarea
							value={data.referralShareLabel}
							onChange={(e) => handleForm("referralShareLabel", e.target.value)}
						>
							Referral share text
						</Textarea>
					</div>
				</div>
			</div>
			<div apps="smsGateway" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-sms-gateway.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">SMS Gateway</div>
							<div className="header-subtext">Configure your SMS integration</div>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div className="form-row row-half">
						<InputWithLabel
							value={data.smsSenderId}
							onChange={(e) => handleForm("smsSenderId", e.target.value)}
							requiredLabel={true}
							validationMessage={validations.smsSenderId || ""}
						>
							Outbound sender SMS id
						</InputWithLabel>
						<InputWithLabel
							value={data.smsKeyword}
							onChange={(e) => handleForm("smsKeyword", e.target.value)}
							validationMessage={validations.smsKeyword || ""}
						>
							Inbound SMS keyword
						</InputWithLabel>
					</div>
				</div>
			</div>
			{/* <div apps="paymentGateway" className={"card-container "+(loading ? "loading" : "")}>
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-payment-gateway.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Payment Gateway</div>
							<div className="header-subtext">
								Facilitate a secure online payment experience for your customers
							</div>
						</div>
					</div>
					<div className="actions">
						<SelectFilter
							options={locationsList}
							isLoading={searchingLocation || loading}
							field="selectedLocation"
							currValue={selectedLocation}
							setFilter={handleLocation}
							labelKey="title"
							valueKey="id"
							isClearable={false}
							isAsync={true}
							handleSearch={handleLocationSearch}
						/>
						<Switch
							title="Enabled"
							checked={showPaymentGatewayContainer}
							clickHandler={() => togglePaymentGateway(!showPaymentGatewayContainer)}
						/>
					</div>
				</div>
				{
					showPaymentGatewayContainer &&
					<div className="config-form-container">
						<div className="config-form-row">
							<SelectFilter
								title="Payment Gateway"
								options={filteredPaymentConfigList}
								isLoading={loading}
								field="selectedPaymentConfig"
								currValue={selectedPaymentConfig}
								setFilter={handlePaymentConfig}
								labelKey="valueForDisplay"
								valueKey="value"
								isClearable={false}
							/>
						</div>
						<div className="config-form-row">
							{
								data.businessConfigurations && data.businessConfigurations.map((gateway) => (
									gateway && (selectedPaymentConfig?.kvConfigName === gateway.name) &&
									<Configurations
										platform={gateway.name}
										options={gateway.keys}
										handleForm={handleConfigForm}
										validations={validations}
									/>
								))
							}
						</div>
						{
							(!loading && !data.businessConfigurations) &&
							<div className="no-items-placeholder">No Configurations found!</div>
						}
						{
							(loading && !data.businessConfigurations) &&
							<div className="P(10px 0)">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						}
					</div>
				}
			</div> */}
			{/* <div apps="paytm" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-paytm.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Paytm</div>
							<div className="header-subtext">
								Seamlessly Integrate your App or Website with Paytm Payment Gateway to accept Online Payments
							</div>
						</div>
					</div>
					<Switch
						title="Enabled"
						checked={data.isPaytmEnabled}
						clickHandler={() => handleForm('isPaytmEnabled', !data.isPaytmEnabled)}
						validationMessage={validations.isPaytmEnabled || ''}
					/>
				</div>
				{
					data && data.isPaytmEnabled &&
					<div className="config-form-container">
						{
							<div className="config-form-row">
								{
									data.businessConfigurations.map((gateway) => (
										gateway && (paymentGatewayMap['paytm'] === gateway.name) &&
										<Configurations
											platform={gateway.name}
											options={gateway.keys}
											handleForm={handleConfigForm}
											validations={validations}
										/>
									))
								}
							</div>
						}
						{
							(!loading && data.businessConfigurations.length === 0) &&
							<div className="no-items-placeholder">No Configurations found!</div>
						}
						{
							(loading && data.businessConfigurations.length === 0) &&
							<div className="P(10px 0)">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						}
					</div>
				}
			</div>
			<div apps="simpl" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-simpl.png" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">Simpl</div>
							<div className="header-subtext">
								The Simpl app brings the convenience of a khata online to your customers, so they can check out with 1 tap and pay later
							</div>
						</div>
					</div>
					<Switch
						title="Enabled"
						checked={data.isSimplEnabled}
						clickHandler={() => handleForm('isSimplEnabled', !data.isSimplEnabled)}
						validationMessage={validations.isSimplEnabled || ''}
					/>
				</div>
				{
					data && data.isSimplEnabled &&
					<div className="config-form-container">
						{
							<div className="config-form-row">
								{
									data.businessConfigurations.map((gateway) => (
										gateway && (paymentGatewayMap['simpl'] === gateway.name) &&
										<Configurations
											platform={gateway.name}
											options={gateway.keys}
											handleForm={handleConfigForm}
											validations={validations}
										/>
									))
								}
							</div>
						}
						{
							(!loading && data.businessConfigurations.length === 0) &&
							<div className="no-items-placeholder">No Configurations found!</div>
						}
						{
							(loading && data.businessConfigurations.length === 0) &&
							<div className="P(10px 0)">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						}
					</div>
				}
			</div>
			<div apps="paypal" className="card-container">
				<div className="card-header">
					<div className="header-container">
						<div className="app-icon">
							<img src="/assets/icons/icon-paypal.svg" alt="" />
						</div>
						<div className="app-header">
							<div className="header-text">PayPal</div>
							<div className="header-subtext">
								Give your customers a fast checkout experience that lets them pay the way they want, without leaving your website
							</div>
						</div>
					</div>
					<Switch
						title="Enabled"
						checked={data.isPaypalEnabled}
						clickHandler={() => handleForm('isPaypalEnabled', !data.isPaypalEnabled)}
						validationMessage={validations.isPaypalEnabled || ''}
					/>
				</div>
				{
					data && data.isPaypalEnabled &&
					<div className="config-form-container">
						{
							<div className="config-form-row">
								{
									data.businessConfigurations.map((gateway) => (
										gateway && (paymentGatewayMap['paypal'] === gateway.name) &&
										<Configurations
											platform={gateway.name}
											options={gateway.keys}
											handleForm={handleConfigForm}
											validations={validations}
										/>
									))
								}
							</div>
						}
						{
							(!loading && data.businessConfigurations.length === 0) &&
							<div className="no-items-placeholder">No Configurations found!</div>
						}
						{
							(loading && data.businessConfigurations.length === 0) &&
							<div className="P(10px 0)">
								<div className="shimmer H(60px) Mb(10px)" />
								<div className="shimmer H(60px) Mb(10px)" />
							</div>
						}
					</div>
				}
			</div> */}
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(Apps);
