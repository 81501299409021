import React, { useCallback } from "react";

// components
import { Button } from "../_commons/Button";
import { Loading } from "../_commons/Loading";

export const Modal = ({
	isOpen,
	close,
	children,
	title,
	subTitle,
	headerLeft = null,
	submitAction,
	submitClass = "",
	classes = "",
	submitTitle = "Submit",
	deleteAction,
	deleteTitle = "Delete",
	cancelTitle = "Cancel",
	disabled = false,
	loading = false,
	hideActions = false,
	showCancelAction = false,
	showSubmitAction = false,
	showDeleteAction = false,
	headerRight = null,
	showHeaderRight = false,
	showCloseIcon = true,
	cancelAction = null,
	cancelButtonWidth = "100",
	deleteButtonWidth = "100",
	submitButtonWidth = "100",
	overlayOpacity = "0.3",
	modalContainerStyles = {},
	outsideClickClose = true,
	submitBtnLoading = false,
	deleteBtnLoading = false
}) => {
	const handleClose = useCallback(() => {
		if (loading) {
			return;
		}
		close();
	}, [loading, close]);

	const handleCancel = () => {
		if (!cancelAction) {
			close();
		} else {
			cancelAction();
		}
	};
	return (
		<div className={classes}>
			{isOpen && (
				<div className="at-modal">
					<div
						className={(isOpen ? "backlayer-show" : "") + " at-modal--backdrop"}
						onClick={outsideClickClose ? handleClose : () => {}}
						style={{ opacity: overlayOpacity }}
					></div>
					<div
						className={(isOpen ? "content-show" : "") + " modal-container"}
						style={{ ...modalContainerStyles }}
					>
						{showCloseIcon && (
							<div className="modal-close">
								<img src="/assets/icons/cancel.png" alt="" onClick={handleClose} />
							</div>
						)}
						<div className="modal-header">
							<div className="header-left">
								{title && <div className="title">{title}</div>}
								{subTitle && <div className="subtitle">{subTitle}</div>}
								{headerLeft}
							</div>
							{showHeaderRight && <div className="header-right">{headerRight}</div>}
						</div>
						<div className="modal-content">
							{children}
							{loading && <Loading />}
						</div>
						{!hideActions && (
							<div className="modal-footer">
								{showCancelAction && (
									<Button
										clickHandler={handleCancel}
										classes={
											`W(${cancelButtonWidth}px) Mend(10px) ` +
											(loading || submitBtnLoading || deleteBtnLoading ? "disabled" : "")
										}
										type="secondary"
									>
										{cancelTitle}
									</Button>
								)}
								{showDeleteAction && (
									<Button
										clickHandler={deleteAction}
										classes={
											`W(${deleteButtonWidth}px) Mend(10px) at-btn--danger ` +
											(loading || disabled ? "disabled" : "")
										}
										loading={deleteBtnLoading}
									>
										{deleteTitle}
									</Button>
								)}
								{showSubmitAction && (
									<Button
										clickHandler={submitAction}
										classes={
											`W(${submitButtonWidth}px) ${submitClass} ` +
											(loading || disabled ? "disabled" : "")
										}
										loading={submitBtnLoading}
									>
										{submitTitle}
									</Button>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
