import React, { useState } from "react";

// component
import { Modal } from "../_commons/Modal";
import { Loading } from "../_commons/Loading";

// client
import { clientMenu } from "../../client-menu";

// graphql
import { RESTORE_MENU_VERSION } from "../../graphql/menus";

// services
import NotificationServices from "../../services/NotificationService";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";
import { fetchParentSectionsList } from "../../actions/menus";
import { trackEvent } from "../../atlas-utils";
import { TRACKING_EVENT_NAMES } from "../../client-config";

const PublishedVersionRestoreModal = ({
	isOpen,
	close,
	pendingChangesCount,
	version,
	menuId,
	setSelectedTab,
	switchToPublishHistoryListView
}) => {
	const [restoringVersionProcessState, setRestoringVersionProcessState] = useState({
		isLoading: false,
		respState: ""
	});

	const handleClose = (refresh = false) => {
		if (restoringVersionProcessState?.isLoading) {
			return;
		}
		setRestoringVersionProcessState((current) => ({
			respState: "",
			isLoading: false
		}));
		close(refresh);
	};

	const handleVersionRestore = async () => {
		try {
			setRestoringVersionProcessState((current) => ({
				...current,
				isLoading: true
			}));
			const variables = {
				id: menuId,
				version
			};
			const respRestoreVersion = await clientMenu.mutate({
				mutation: RESTORE_MENU_VERSION,
				variables
			});

			if (respRestoreVersion?.data?.restoreMenuVersion) {
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: respRestoreVersion?.data?.restoreMenuVersion
				});
				NotificationServices.pushNotification({
					message: "Restored menu successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				trackEvent(TRACKING_EVENT_NAMES.MENU_OLDVERSION_RESTORE, {
					status: "success"
				});
				setSelectedTab("config");
				fetchParentSectionsList(menuId);
				switchToPublishHistoryListView();
				close();
			} else {
				NotificationServices.pushNotification({
					message: "Failed to restore menu version",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				trackEvent(TRACKING_EVENT_NAMES.MENU_OLDVERSION_RESTORE, {
					status: "failure"
				});
			}
			setRestoringVersionProcessState((current) => ({
				...current,
				isLoading: false
			}));
		} catch (e) {
			console.log(e);
			NotificationServices.pushNotification({
				message: "Failed to restore menu version",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			setRestoringVersionProcessState((current) => ({
				...current,
				isLoading: false,
				respState: "failure"
			}));
		}
	};

	if (restoringVersionProcessState?.isLoading) {
		return (
			<Modal
				isOpen={isOpen}
				close={handleClose}
				title={"Restore Menu Configuration?"}
				showSubmitAction
				submitAction={handleVersionRestore}
				submitTitle="Restore"
				cancelTitle="Cancel"
				classes="published-version-restore--modal"
				disabled
			>
				<Loading />
			</Modal>
		);
	}

	if (restoringVersionProcessState?.respState === "failure") {
		return (
			<Modal
				isOpen={isOpen}
				close={handleClose}
				title={"Restore Menu Configuration?"}
				showSubmitAction
				submitAction={handleVersionRestore}
				submitTitle="Try Again"
				showCancelAction
				cancelTitle="Cancel"
				classes="published-version-restore--modal"
			>
				<div className="published-version-restore--modal--failure">Failed to restore menu version</div>
			</Modal>
		);
	}

	return (
		<Modal
			isOpen={isOpen}
			close={close}
			showSubmitAction
			showCancelAction
			submitAction={handleVersionRestore}
			submitTitle="Restore"
			cancelTitle="Cancel"
			classes="published-version-restore--modal"
		>
			<span className="body-main-text">
				{!!pendingChangesCount
					? "There are unpublished changes in your current version."
					: "Are you sure you want to restore this menu ?"}
			</span>
			<br />
			<span className="body-subtext">
				{!!pendingChangesCount
					? "If you restore this menu configuration, it will discard your unpublished changes."
					: "Restoring to this version will override your existing menu with contents from this version"}
			</span>
		</Modal>
	);
};

export default PublishedVersionRestoreModal;
