import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { UsersList } from "./UsersList";
import { UnifiedUsersList } from "./UnifiedUsersList";
import UserEdit from "./UserEdit";
import UnifiedUserEdit from "./UnifiedUserEdit";
import UnifiedInviteDetail from "./UnifiedInviteDetail";

const UsersContainer = () => {
	return (
		<React.Fragment>
			<div className="user-management users">
				<Route path="/access" component={UsersList} />
				<Route exact path="/access/edit/:id" component={UserEdit} />
			</div>
			<div className="unified-user-management users">
				<Route path="/unified-access" component={UnifiedUsersList} />
				<Route exact path="/unified-access/preview/:id" component={UnifiedInviteDetail} />
				<Route exact path="/unified-access/edit/:id/:app" component={UnifiedUserEdit} />
			</div>
		</React.Fragment>
	);
};
export default UsersContainer;
