import React, { useState, useEffect, useReducer, useCallback, useRef, useImperativeHandle } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Button } from "../components/_commons/Button";
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { Switch } from "../components/_commons/Switch";
import { JsonInput } from "../components/_commons/JsonInput";
import { Paginator } from "../components/_commons/Paginator";
import { Accordion } from "../components/_commons/Accordion";
import { Topbar } from "../components/_commons/Topbar";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BackupActions from "../components/CatalogueBackups/BackupActions";

// assets
import { ButtonIcon } from "../components/_commons/ButtonIcon";

// client
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";
import moment from "moment";

// utils
import history from "../history";
import { adjustNestedContainer, printCurrency } from "../atlas-utils";

// reducers
import { catalogueBackupDetailsReducer, CATALOGUE_BACKUP_DETAILS_INITIAL_STATE } from "../reducers/catalogueBackups";

// graphql
import {
	GET_CATEGORIES_LIST_FROM_BACKUP,
	GET_ITEMS_LIST_FROM_BACKUP,
	GET_MODIFIER_GROUPS_LIST_FROM_BACKUP,
	GET_MODIFIERS_LIST_FROM_BACKUP,
	GET_TAXES_LIST_FROM_BACKUP,
	GET_CHARGES_LIST_FROM_BACKUP,
	GET_ITEM_GROUPS_LIST_FROM_BACKUP,
	GET_LOCATION_GROUPS_LIST_FROM_BACKUP,
	GET_TIMING_GROUPS_LIST_FROM_BACKUP
} from "../graphql/catalogueBackups";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchCatalogueBackupDetail, fetchCatalogueBackupsList } from "../actions/catalogueBackups";

// constants
import { CATALOGUE_BACKUP_ENTITIES_LIST_HEADER_FIELDS, FOOD_TYPE_MAP, NESTED_ENTITY_TYPES } from "../client-config";
import { APPLICABLE_ON_OPTIONS as CHARGES_APPLICABLE_ON_OPTIONS } from "./ChargeCreate";
import { APPLICABLE_ON_OPTIONS as TAXES_APPLICABLE_ON_OPTIONS } from "./TaxCreate";
const ENTITIES_GQL_MAP = {
	categories: GET_CATEGORIES_LIST_FROM_BACKUP,
	items: GET_ITEMS_LIST_FROM_BACKUP,
	optionGroups: GET_MODIFIER_GROUPS_LIST_FROM_BACKUP,
	options: GET_MODIFIERS_LIST_FROM_BACKUP,
	taxes: GET_TAXES_LIST_FROM_BACKUP,
	charges: GET_CHARGES_LIST_FROM_BACKUP,
	itemGroups: GET_ITEM_GROUPS_LIST_FROM_BACKUP,
	locationGroups: GET_LOCATION_GROUPS_LIST_FROM_BACKUP,
	timingGroups: GET_TIMING_GROUPS_LIST_FROM_BACKUP
};
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const NESTED_ENTITY_MAP = {
	categories: 0,
	items: 1,
	optionGroups: 5,
	options: 6,
	taxes: 3,
	charges: 4,
	itemGroups: 7,
	locationGroups: 8,
	timingGroups: 16
};
const CATALOGUE_BACKUP_ACTION_INIT_STATE = {
	id: null,
	name: "",
	action: "delete",
	from: "Preview"
};
const INIT_FILTERS = {
	categories: { search: "" },
	items: { search: "" },
	optionGroups: { search: "" },
	options: { search: "" },
	taxes: { search: "" },
	charges: { search: "" },
	itemGroups: { search: "" },
	locationGroups: { search: "" },
	timingGroups: { search: "" }
};

const CatalogueBackupPreview = ({
	match,
	catalogueBackupsStatus,
	isNested = false,
	closeNestedContainer,
	connectedRef,
	backupActions = true,
	currencySymbol,
	userInfo
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isModalBusy, setModalBusy] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [parsedData, setParsedData] = useState({});
	const [currFilters, setCurrFilters] = useState(INIT_FILTERS);
	const [applFilters, setApplFilters] = useState(INIT_FILTERS);
	const [isBackupActionsOpen, setBackupActionsOpen] = useState(false);
	const [catalogueBackupDetails, dispatch] = useReducer(
		catalogueBackupDetailsReducer,
		CATALOGUE_BACKUP_DETAILS_INITIAL_STATE
	);
	const { loading, data, error } = catalogueBackupDetails;
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [backupActionData, setBackupActionData] = useState(CATALOGUE_BACKUP_ACTION_INIT_STATE);
	const [expandedEntity, setExpandedEntity] = useState({});
	const [viewRawJson, setViewRawJson] = useState(false);
	const nestedRef = useRef();
	const tableRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCatalogueBackupDetail(parseInt(match.params.id), dispatch);
	}, [match.params.id]);

	const fetchEntitiesList = useCallback(async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				id: parseInt(match.params.id),
				limit,
				offset
			};
			const entityFilter = applFilters[data.selectedTab.value];
			if (entityFilter.search) {
				variables.search = entityFilter.search;
			}
			const resp = await client.query({
				query: ENTITIES_GQL_MAP[data.selectedTab.value],
				variables,
				fetchPolicy: "no-cache"
			});
			const entityData = {
				objects: resp?.data?.catalogBackup?.[data.selectedTab.value]?.objects || [],
				count: resp?.data?.catalogBackup?.[data.selectedTab.value]?.count || 0
			};
			try {
				entityData.json = JSON.parse(resp?.data?.catalogBackup?.[data.selectedTab.value]?.json);
			} catch {
				entityData.json = [];
			}
			setParsedData({
				[data.selectedTab.value]: { ...entityData }
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [limit, offset, data.selectedTab, applFilters, parsedData, data]);

	useEffect(() => {
		fetchEntitiesList();
	}, [limit, offset, data.selectedTab.value, applFilters]);

	const handleFormTab = (tab) => {
		dispatch({
			type: ActionTypes.UPDATE_CATALOGUE_BACKUP_DETAIL,
			payload: {
				selectedTab: tab
			}
		});
		setOffset(0);
	};

	const handleCancel = (close = false) => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy || close) {
			setFormState(false);
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchCatalogueBackupsList();
					history.push("/backups");
				}
			}, 100);
		} else {
			closeBackupActions();
		}
	};

	const applyFilter = useCallback(
		debounce((filters) => {
			setApplFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[data.selectedTab.value]: {
					[field]: value
				}
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters);
		},
		[currFilters, applyFilter, data.selectedTab]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			if (tableRef?.current) {
				tableRef.current.scrollIntoView();
			}
		},
		[limit]
	);

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleExpandAccordion = useCallback(
		(id, state = false) => {
			setExpandedEntity({
				...expandedEntity,
				[id]: state
			});
		},
		[expandedEntity]
	);

	const openBackupActions = useCallback(
		(action = "restore") => {
			setBackupActionData({
				...backupActionData,
				...data,
				action
			});
			setBackupActionsOpen(true);
			setModalBusy(true);
		},
		[data, backupActionData]
	);

	const closeBackupActions = (refresh = false) => {
		setBackupActionsOpen(false);
		setModalBusy(false);
		setBackupActionData(CATALOGUE_BACKUP_ACTION_INIT_STATE);
		if (refresh) {
			setTimeout(() => {
				handleCancel(true);
			}, 500);
		}
	};

	const renderTitle = () => (
		<React.Fragment>
			<div>{data.title || "Backup"}</div>
			{catalogueBackupsStatus.restrict &&
				catalogueBackupsStatus.catalogue_backup_id === parseInt(match.params.id) && (
					<div className="status">
						<ButtonIcon icon="refresh" color="#2ecc71" />
						<div>
							{catalogueBackupsStatus.status === "RESTORING" ? "Restoring backup" : "Creating backup"}
						</div>
					</div>
				)}
		</React.Fragment>
	);

	const renderTab = (tab) => {
		return (
			<React.Fragment>
				<div className={"count " + tab.value}>{tab.count}</div>
				<div className="text">{tab.label}</div>
			</React.Fragment>
		);
	};

	return (
		<div className="preview-backup-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title={renderTitle()}
				subTitle={
					data?.id ? (
						<React.Fragment>
							{"Backup created by "}
							<span>
								{data?.takenBy === "System"
									? data?.takenBy
									: data?.updatedBy && !data?.updatedBy?.username?.includes("tmp_")
									? data?.updatedBy?.fullName || data?.updatedBy?.username
									: data?.creatorEmail ||
									  data?.updatedBy?.email ||
									  data?.updatedBy?.username ||
									  data?.takenBy ||
									  "--"}
							</span>
							{" on "}
							<span>{data.created ? moment(data.created).format("DD MMM, YYYY") : "--"}</span>
							{" at "}
							<span>{data.created ? moment(data.created).format("hh:mm A") : "--"}</span>
						</React.Fragment>
					) : (
						"Preview your catalougue in this backup"
					)
				}
				loading={loading}
				hideActions={true}
				isNested={isNested}
				headerRight={
					backupActions && (
						<React.Fragment>
							{data.takenBy !== "System" && (
								<Button
									classes={
										catalogueBackupsStatus.restrict &&
										catalogueBackupsStatus.catalogue_backup_id === parseInt(match.params.id)
											? "at-btn--danger Mstart(10px) btn--disabled"
											: "at-btn--danger Mstart(10px)"
									}
									clickHandler={
										catalogueBackupsStatus.restrict &&
										catalogueBackupsStatus.catalogue_backup_id === parseInt(match.params.id)
											? () => {}
											: () => openBackupActions("delete")
									}
									showCustomTooltip={
										catalogueBackupsStatus.restrict &&
										catalogueBackupsStatus.catalogue_backup_id === parseInt(match.params.id)
									}
									tooltipInfo={
										catalogueBackupsStatus.status === "RESTORING"
											? "Your catalogue backup is currently being restored. This action is disabled."
											: "A backup of your catalogue is currently being processed. This action is disabled."
									}
								>
									Delete
								</Button>
							)}
							<Button
								classes={
									catalogueBackupsStatus.restrict
										? "at-btn--success btn--disabled"
										: "at-btn--success"
								}
								clickHandler={
									catalogueBackupsStatus.restrict ? () => {} : () => openBackupActions("restore")
								}
								showCustomTooltip={catalogueBackupsStatus.restrict}
								tooltipInfo={
									catalogueBackupsStatus.status === "RESTORING"
										? "Your catalogue backup is currently being restored. This action is disabled."
										: "A backup of your catalogue is currently being processed. This action is disabled."
								}
							>
								Restore
							</Button>
						</React.Fragment>
					)
				}
			>
				{data.description && (
					<div className="description-container">
						<span>Description:</span> {data.description}
					</div>
				)}
				<Topbar
					tabs={data.formTabs}
					selectedTab={data.selectedTab.value}
					switchTab={(tab) => handleFormTab(tab)}
					renderTab={renderTab}
					isStickyOnTop={true}
					isScrollable={true}
					classes="custom-topbar"
				/>
				<div className="form-content" ref={tableRef}>
					<div className="search-container">
						<InputWithLabel
							value={currFilters?.[data.selectedTab.value]?.search}
							onChange={(e) => setFilter("search", e.target.value)}
							placeholder="Enter Name"
							readOnly={
								parseInt(match.params.id) === catalogueBackupsStatus?.catalogue_backup_id &&
								catalogueBackupsStatus?.status === "BACKING_UP"
							}
							classes="at-input--label"
							showLabel={true}
							image={true}
							imageSrc="/assets/header-icons/icon-search.svg"
						/>
						{userInfo.email.includes("@urbanpiper.com") && (
							<Switch
								title="Raw JSON Preview"
								checked={viewRawJson}
								clickHandler={() => setViewRawJson(!viewRawJson)}
								readOnly={
									parseInt(match.params.id) === catalogueBackupsStatus?.catalogue_backup_id &&
									catalogueBackupsStatus?.status === "BACKING_UP"
								}
							/>
						)}
					</div>
					{viewRawJson ? (
						<JsonInput
							value={{ [data.selectedTab.key]: parsedData?.[data.selectedTab.value]?.json || [] }}
							setFilter={false}
							collapsed={2}
							loading={confirmLoading}
						/>
					) : (
						<div className="entity-table-container">
							<Table
								data={parsedData?.[data.selectedTab.value]?.objects || []}
								backupId={parseInt(match.params.id)}
								loading={confirmLoading}
								entity={data.selectedTab}
								currencySymbol={currencySymbol}
								expandedEntity={expandedEntity}
								handleExpandAccordion={handleExpandAccordion}
								handleNestedEntity={handleNestedEntity}
								backupsStatus={catalogueBackupsStatus}
							/>
							{!(
								parseInt(match.params.id) === catalogueBackupsStatus?.catalogue_backup_id &&
								catalogueBackupsStatus?.status === "BACKING_UP"
							) && (
								<Paginator
									limit={limit}
									offset={offset}
									count={parsedData?.[data.selectedTab.value]?.count || 0}
									goToPage={handlePagination}
								/>
							)}
						</div>
					)}
					<BackupActions
						isOpen={isBackupActionsOpen}
						isNested={true}
						data={backupActionData}
						close={closeBackupActions}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={true}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	catalogueBackupsStatus: store.catalogueBackupsStatus,
	userInfo: store.login.loginDetail
});
export default connect(mapStateToProps)(CatalogueBackupPreview);

export const Table = ({
	data,
	backupId,
	loading,
	currencySymbol,
	sortList,
	sortedField,
	expandedEntity,
	handleExpandAccordion,
	handleNestedEntity,
	backupsStatus,
	entity
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				` transaction-table-holder common-table-container entity-table ${entity.value}`
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						headerFields={CATALOGUE_BACKUP_ENTITIES_LIST_HEADER_FIELDS[entity.value]}
					/>
					{backupId === backupsStatus?.catalogue_backup_id && backupsStatus?.status === "BACKING_UP" ? (
						<div className="sync-status-container">
							<div className="sync-icon">
								<img src="/assets/icons/icon-sync.svg" alt="" />
							</div>
							<div className="message">
								<div>
									Your catalogue backup is currently being processed. This usually takes about a
									minute.
								</div>
								<div>Please wait for the backup to finish.</div>
							</div>
						</div>
					) : (
						<React.Fragment>
							{trails.map(({ rotate }, i) => (
								<TableList
									key={data[i].id}
									style={{
										transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
									}}
									{...data[i]}
									currencySymbol={currencySymbol}
									expandedEntity={expandedEntity}
									handleExpandAccordion={handleExpandAccordion}
									handleNestedEntity={handleNestedEntity}
									entity={entity}
								/>
							))}
							{data.length === 0 && !loading && (
								<div className="no-items-placeholder">No {entity.label} found!</div>
							)}
							{data.length === 0 && loading && (
								<div className="P(10px)">
									<div className="shimmer H(60px) Mb(10px)" />
									<div className="shimmer H(60px) Mb(10px)" />
								</div>
							)}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row entity-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	name,
	title,
	subCategories = [],
	description,
	price,
	category,
	foodType,
	applicableOn,
	isVariant,
	currencySymbol,
	style,
	expandedEntity = {},
	handleNestedEntity,
	handleExpandAccordion,
	entity
}) => {
	return (
		<animated.div
			// style={style}
			className={
				"at-table-row transaction-rows entity-list-table" +
				(entity.value === "categories" && subCategories.length > 0 ? " no-pad" : "")
			}
		>
			{
				// entity.value === 'categories' && subCategories.length > 0 ?
				// <Accordion
				// 	inTable={true}
				// 	tableRow={
				// 		<React.Fragment>
				// 			<div className="at-table-cell at-cell-text title" title={name}>
				// 				<div className="item-desc">
				// 					<b>{name || id}</b>
				// 				</div>
				// 			</div>
				// 			<div className="at-table-cell at-cell-text description">
				// 				{description || '--'}
				// 			</div>
				// 		</React.Fragment>
				// 	}
				// 	id={id}
				// 	isExpanded={expandedEntity[id] || false}
				// 	allowRowExpansion={true}
				// 	handleExpand={handleExpandAccordion}
				// >
				// 	{
				// 		subCategories.map((subcat, j) => (
				// 			<div className="link">
				// 				<div
				// 					key={j}
				// 					className="subcat-container"
				// 				>
				// 					<b>{subcat.name || subcat.id}</b>
				// 				</div>
				// 			</div>
				// 		))
				// 	}
				// </Accordion>
				// :
				<React.Fragment>
					{entity.value !== "categories" && (
						<div className="at-table-cell at-cell-text name" title={title}>
							<div className="item-desc">
								{(entity.value === "items" || entity.value === "options") && (
									<div
										className={`food-type ${foodType ? FOOD_TYPE_MAP[`A_${foodType}`] : "none"}`}
									/>
								)}
								<b>{title || id}</b>
							</div>
						</div>
					)}
					{entity.value === "categories" && (
						<div className="at-table-cell at-cell-text title" title={name}>
							<div className="item-desc">
								<b>{name || id}</b>
							</div>
						</div>
					)}
					{entity.value === "items" && (
						<div className="at-table-cell at-cell-text category">{category?.name || "--"}</div>
					)}
					{entity.value === "optionGroups" && (
						<div className="at-table-cell at-cell-text type">{isVariant ? "Variant" : "Add-On"}</div>
					)}
					{!["items", "options", "optionGroups", "taxes", "charges"].includes(entity.value) && (
						<div className="at-table-cell at-cell-text description">{description || "--"}</div>
					)}
					{(entity.value === "items" || entity.value === "options") && (
						<div className="at-table-cell at-cell-text price">
							{printCurrency(currencySymbol)}
							{price || 0}
						</div>
					)}
					{entity.value === "taxes" && (
						<div className="at-table-cell at-cell-text applicable-on">
							{applicableOn
								? TAXES_APPLICABLE_ON_OPTIONS.find((opt) => opt.value === applicableOn)?.label || "--"
								: "--"}
						</div>
					)}
					{entity.value === "charges" && (
						<div className="at-table-cell at-cell-text applicable-on">
							{applicableOn
								? CHARGES_APPLICABLE_ON_OPTIONS.find((opt) => opt.value === applicableOn)?.label || "--"
								: "--"}
						</div>
					)}
				</React.Fragment>
			}
		</animated.div>
	);
};
