import React, { useState, useEffect, useRef, useMemo } from "react";

// components
import Header from "../../components/Analytics/Common/Header";
import SectionHeader from "../../components/Analytics/Common/SectionHeader";
import Filters from "../../components/Analytics/Common/Filters";
import { Topbar } from "../../components/_commons/Topbar";
import MetricCard from "../../components/Analytics/Common/MetricCard";
import MetricSection from "../../components/Analytics/Common/MetricSection";
import MetricHeader from "../../components/Analytics/Common/MetricHeader";
import ChartMetric from "../../components/Analytics/Common/ChartMetric";
import MultiChartMetrics from "../../components/Analytics/Common/MultiChartMetrics";
import { CustomTable } from "../../components/_commons/CustomTable";
import { SelectFilter } from "../../components/_commons/SelectFilter";
import { NestedEntityContainer } from "../../components/_commons/NestedEntityContainer";

// third party
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import history from "../../history";

// store
import { store } from "../../store/configureStore";

// utils
import { scroll, getSortOrder, breadcrumbs, trackEvent, adjustNestedContainer } from "../../atlas-utils";

// actions
import {
	updateCatalogueAnalyticsState,
	fetchCatalogueMetrics,
	fetchCategoryPerformanceTable,
	fetchItemPerformanceTable
} from "../../actions/analytics";

// constants
import { ANALYTICS_SECTIONS, NESTED_ENTITY_TYPES } from "../../client-config";
const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const Catalogue = ({ match, catalogueAnalytics, currencySymbol }) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [currTab, setCurrTab] = useState(
		query.get("metric") || ANALYTICS_SECTIONS["catalogue"]?.["tabs"]?.[0]?.value
	);
	const [isPageScrolled, setPageScrolled] = useState(document?.documentElement?.scrollTop > 60 || false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const refs = {
		catalogue_metrics: useRef(),
		category_performance: useRef(),
		item_performance: useRef()
	};

	const switchTab = (tab, tabClick = false) => {
		setCurrTab(tab.value);
		window.requestAnimationFrame(() => {
			scroll(
				{
					top: refs[tab.value]?.current?.offsetTop - (document.querySelector(".with-banner") ? 290 : 230),
					left: 0
				},
				window,
				"instant"
			);
		});
		// track event
		if (tabClick) {
			trackEvent("analytics_tab_clicked", {
				module: "Catalogue",
				tab: tab.label
			});
		}
	};

	const handlePageScroll = () => {
		if (document.documentElement.scrollTop > 60) {
			setPageScrolled(true);
		} else {
			setPageScrolled(false);
		}
	};

	useEffect(() => {
		// if metric param exists in url, scroll to that metric
		if (query.get("metric")) {
			switchTab({ value: query.get("metric") });
		} else {
			document.documentElement.scrollTop = 0;
		}
		trackEvent("analytics_module", { module: "Catalogue" });
		window.addEventListener("scroll", handlePageScroll);
		return () => {
			window.removeEventListener("scroll", handlePageScroll);
		};
	}, []);

	const handleTableRowClick = (rowData, metric, entity) => {
		if (rowData.name && rowData.item) {
			// create breadcrumbs
			let bc = [];
			bc.push({
				title: "Catalogue Analytics",
				to: `${match.url}?metric=${metric}`
			});
			const encodedName = encodeURIComponent(rowData?.name?.replaceAll("%", "percent"));
			const url = `${match.url}/view/${entity}/${encodedName}/${rowData.item}${`?filters=${query.get(
				"filters"
			)}`}${`&bc=${breadcrumbs.encode(bc)}`}`;
			history.push(url);
		}
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "analytics",
				start: "03:36"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className={"analytics-catalogue section-container-common" + (isPageScrolled ? " scrolled" : "")}>
			<Header>
				<SectionHeader
					title="Catalogue Analytics"
					showHelpBtn={true}
					handlePiperAcademy={handlePiperAcademy}
					isPageScrolled={isPageScrolled}
				/>
				<Filters showBrands showLocations showPlatforms showDateCompare module="Catalogue" />
				<Topbar
					tabs={ANALYTICS_SECTIONS["catalogue"]["tabs"]}
					selectedTab={currTab}
					switchTab={(tab) => switchTab(tab, true)}
				/>
			</Header>
			<MetricCard
				metric="catalogue_metrics"
				tabMetric="category_performance"
				cardRef={refs["catalogue_metrics"]}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchCatalogueMetrics}
						state={catalogueAnalytics}
					>
						{({ metricsLoading, metrics }) => (
							<MultiChartMetrics>
								<ChartMetric
									size="large"
									loading={metricsLoading}
									label="Catalogue Items"
									description="Total items listed across all platforms and locations."
									value={metrics?.item_count?.value}
									percentageChange={metrics?.item_count?.percentageChange}
									compareValue={metrics?.item_count?.compareValue}
								/>
								{/* <ChartMetric
									size='large'
									loading={metricsLoading}
									label="Offline Items"
									description="Items currently not available for order."
									value={metrics?.order_avg_order_per_hour?.value}
									percentageChange={metrics?.order_avg_order_per_hour?.percentageChange}
									compareValue={metrics?.order_avg_order_per_hour?.compareValue}
								/> */}
								{/* <ChartMetric
									size='large'
									loading={metricsLoading}
									label="Offline Count"
									description="Count of times items were made unavailable."
									value={metrics?.order_avg_order_per_hour?.value}
									percentageChange={metrics?.order_avg_order_per_hour?.percentageChange}
									compareValue={metrics?.order_avg_order_per_hour?.compareValue}
								/> */}
								{/* <ChartMetric
									size='large'
									loading={metricsLoading}
									label="Offline Time"
									description="Cumulative time items were unavailable for order."
									descPosition="down-right"
									value={metrics?.order_failure_rate?.value ? Math.round(metrics?.order_failure_rate?.value / 60000) : metrics?.order_failure_rate?.value}
									percentageChange={metrics?.order_failure_rate?.percentageChange ? Math.round(metrics?.order_failure_rate?.percentageChange / 60000) : metrics?.order_failure_rate?.percentageChange}
									compareValue={metrics?.order_failure_rate?.compareValue}
									units=" mins"
								/> */}
							</MultiChartMetrics>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="category_performance" cardRef={refs["category_performance"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchCategoryPerformanceTable}
						state={catalogueAnalytics}
						dependencies={[catalogueAnalytics[metric]?.sort]}
					>
						{({ loading, tabularData, tableColumnsSelected, sort, sortedField }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Category Performance"
											description="Overview of sales performance by food category."
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateCatalogueAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="category-performance-table-container"
									content="Categories"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									viewAction="View All"
									viewActionVariant="link"
									viewActionHref={`/analytics/catalogue/list/category-performance/category-performance${`?filters=${query.get(
										"filters"
									)}`}${`&bc=${breadcrumbs.encode([
										{
											title: "Catalogue Analytics",
											to: "/analytics/catalogue?metric=category_performance"
										}
									])}`}`}
									handleViewAction={() =>
										trackEvent("analytics_link_text_clicked", {
											module: "Catalogue",
											type: "View All",
											origin: metric
										})
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="item_performance" cardRef={refs["item_performance"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={(metric) =>
							fetchItemPerformanceTable(metric, { category: catalogueAnalytics[metric]?.category })
						}
						state={catalogueAnalytics}
						dependencies={[catalogueAnalytics[metric]?.sort, catalogueAnalytics[metric]?.category?.value]}
					>
						{({ loading, tabularData, tableColumnsSelected, category, sort, sortedField, filters }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Item Performance"
											description="Sales and revenue data for individual menu items."
										/>
									</div>
									<div className="header-right">
										<SelectFilter
											isLoading={loading}
											options={
												filters?.length > 0
													? [
															{ valueForDisplay: "All", value: "all" },
															...(filters?.find((f) => f.field === "category")?.values ??
																[])
													  ]
													: []
											}
											currValue={category}
											field="category"
											setFilter={(field, value) =>
												updateCatalogueAnalyticsState(metric, {
													[field]: value
												})
											}
											isClearable={false}
											customDropdownLabel={
												category ? (
													<div className="custom-value">
														Category <span>{category["valueForDisplay"]}</span>
													</div>
												) : null
											}
											labelKey="valueForDisplay"
											valueKey="value"
											placeholder="Select category"
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateCatalogueAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="item-performance-table-container"
									content="Items"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									rowClickHandler={(rowData) =>
										handleTableRowClick(rowData, metric, tabularData?.rowLink?.entity)
									}
									viewAction="View All"
									viewActionVariant="link"
									viewActionHref={`/analytics/catalogue/list/item-performance/item-performance${`?filters=${query.get(
										"filters"
									)}`}${`&bc=${breadcrumbs.encode([
										{
											title: "Catalogue Analytics",
											to: "/analytics/catalogue?metric=item_performance"
										}
									])}`}`}
									handleViewAction={() =>
										trackEvent("analytics_link_text_clicked", {
											module: "Catalogue",
											type: "View All",
											origin: metric
										})
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	catalogueAnalytics: store.catalogueAnalytics,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(Catalogue);
