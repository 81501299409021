import React from "react";

const PlatformCell = ({ props }) => {
	let total = props.cell.value.total;
	let issueCount = props.cell.value.issue_count;
	if (total > 0) {
		if (issueCount > 0) {
			return (
				<span style={{ display: "flex", gap: "4px" }}>
					{issueCount}
					<img src="/assets/periscope/item-issues.svg" />
				</span>
			);
		} else {
			return (
				<>
					<img src="/assets/periscope/online.svg" className="online-black-image" />
					<img src="/assets/periscope/online-green.svg" className="online-green-image" />
				</>
			);
		}
	} else {
		return (
			<>
				<img src="/assets/periscope/partiallyOffline.svg" />
			</>
		);
	}
};

export default PlatformCell;
