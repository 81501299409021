import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, trackEvent, getTrackingSource } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ENTITY_LOCATIONS, UPDATE_ENTITY_LOCATIONS } from "../../graphql/locationEntityAssociations";

// actions
import { ActionTypes } from "../../actions/_types";
import { connect } from "react-redux";
import { TRACKING_STATUS, TRACKING_TYPE } from "../../client-config";

// constants
const FILTER_INITIAL_STATE = {
	name: "",
	city: undefined,
	tags: undefined
};
const LOCATION_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const SORT_INIT_STATE = {
	field: "name",
	order: "ASC"
};

const LocationEntityAssociations = ({ entityType, entityId, isOpen, close, selectedBrand }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [locationData, setLocationData] = useState(LOCATION_INITIAL_DATA);
	const [cityfilters, setCityfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [locationUpdates, setLocationUpdates] = useState({});
	const [selectAllLocations, setSelectAllLocations] = useState(false);
	const [eventTracking, setEventTracking] = useState({
		type: `${entityType.label} first`,
		filters: {}
	});
	const tableRef = useRef();
	const totalChanges = Object.keys(locationUpdates).length;
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const [sortInfo, setSortInfo] = useState(SORT_INIT_STATE);
	const totalPages = Math.ceil(locationData.count / limit);

	const fetchData = useCallback(
		async (resetSelection = false) => {
			try {
				setLoading(true);
				const variables = {
					entityId,
					entityType: entityType.value,
					limit,
					offset,
					sort: sortInfo
				};
				// sidebar filters
				let filtersObject = [];
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				});
				if (filtersObject.length > 0) {
					variables.filters = filtersObject;
				}
				variables.brandId = selectedBrand
					? selectedBrand.id === "all"
						? null
						: String(selectedBrand?.id)
					: null;
				const resp = await client.query({
					query: GET_ENTITY_LOCATIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				setLocationData(resp.data.entityLocationAssociations);
				setCityfilters(resp.data.entityLocationAssociations.filters.find((fl) => fl.field === "city"));
				setTagfilters(resp.data.entityLocationAssociations.filters.find((fl) => fl.field === "tags"));
				if (resetSelection) {
					setAllSelected(false);
					setSelectAllLocations(false);
					setShowAllFieldSelector(false);
					setIsCheckedAll(false);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[entityId, entityType, limit, offset, appliedFilters, sortInfo, selectedBrand]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen, selectedBrand]);

	const applyFilter = useCallback(
		debounce((filters, field) => {
			setAppliedFilters(filters);
			// track filters which are being used
			setEventTracking({
				...eventTracking,
				filters: {
					...eventTracking["filters"],
					[field]: true
				}
			});
			setOffset(0);
		}, 500),
		[eventTracking]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters, field);
		},
		[currFilters, applyFilter]
	);

	const syncData = useCallback(() => {
		setLocationData({
			...locationData,
			objects: locationData.objects.map((loc) => {
				if (locationUpdates[loc.bizLocationId] !== undefined) {
					return {
						...loc,
						isAssociated: locationUpdates[loc.bizLocationId]
					};
				}
				return loc;
			})
		});
		setLocationUpdates({});
	}, [locationData, locationUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setLocationData(LOCATION_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setLocationUpdates({});
			setAllSelected(false);
			setShowAllFieldSelector(false);
			setIsCheckedAll(false);
			setSelectAllLocations(false);
			setSortInfo(SORT_INIT_STATE);
			setEventTracking({
				type: `${entityType.label} first`,
				filters: {}
			});
			close(refresh);
		},
		[close, entityType]
	);

	const handleUpdate = useCallback(
		async (associateAll = false, disassociateAll = false) => {
			let eventMeta = {};
			if (entityType.value === "LOCATION_GROUP") {
				eventMeta.type = TRACKING_TYPE.EXISTING_PAIR_UPDATED;
				eventMeta.source = getTrackingSource();
			}

			try {
				setConfirmLoading(true);
				const variables = {
					entityId,
					entityType: entityType.value,
					locationsToAssociate: [],
					locationsToDisassociate: [],
					associateAll: associateAll,
					disassociateAll: disassociateAll,
					brandId: !selectedBrand ? null : String(selectedBrand?.id)
				};

				if (!associateAll && !disassociateAll) {
					for (let bizLocationId in locationUpdates) {
						if (locationUpdates[bizLocationId]) {
							variables.locationsToAssociate.push(parseInt(bizLocationId));
						} else {
							variables.locationsToDisassociate.push(parseInt(bizLocationId));
						}
					}

					eventMeta = {
						change_in_num_of_locations:
							variables.locationsToAssociate.length - variables.locationsToDisassociate.length
					};
				} else {
					eventMeta = {
						change_in_num_of_locations: associateAll ? locationData.count : -locationData.count
					};
				}
				// sidebar filters
				let filtersObject = [];
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				});
				variables.filters = filtersObject;
				const resp = await client.mutate({
					mutation: UPDATE_ENTITY_LOCATIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				if (resp.data.updateEntityLocations.status.success) {
					if (entityType.event) {
						eventMeta["status"] = TRACKING_STATUS.SUCCESS;
						trackEvent(entityType.event, eventMeta);
					}

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message:
								resp.data.updateEntityLocations.status.messages[0].message === "Success"
									? "Location association updated!"
									: resp.data.updateEntityLocations.status.messages[0].message,
							timeout: 2000,
							error: false
						}
					});
					handleClose(true);
					syncData();
				} else {
					if (entityType.event) {
						eventMeta["status"] = TRACKING_STATUS.FAILURE;
						trackEvent(entityType.event, eventMeta);
					}

					// handle error message
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: msaagesArrayToHtml(resp.data.updateEntityLocations.status.messages),
							timeout: 5000,
							error: true
						}
					});
				}
			} catch (error) {
				if (entityType.event) {
					eventMeta["status"] = TRACKING_STATUS.FAILURE;
					trackEvent(entityType.event, eventMeta);
				}

				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setConfirmLoading(false);
		},
		[entityId, entityType, locationUpdates, fetchData, syncData, handleClose, eventTracking, selectedBrand]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (bizLocationId, toAssociate) => {
		setLocationUpdates({
			...locationUpdates,
			[bizLocationId]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		locationData.objects.forEach((loc) => {
			updates[loc.bizLocationId] = toCheckAll;
		});
		setLocationUpdates({
			...locationUpdates,
			...updates
		});
		if (toCheckAll && Math.ceil(locationData.count / limit) > 1) {
			setShowAllFieldSelector(true);
		}
		if (!toCheckAll) {
			setShowAllFieldSelector(false);
		}
	};

	const handleCompleteSelection = (selectAllLocations = false) => {
		setAllSelected(selectAllLocations);
		handleCheckAll(selectAllLocations);
		setSelectAllLocations(selectAllLocations);
		if (!selectAllLocations) {
			setShowAllFieldSelector(false);
			setLocationUpdates({});
		}
	};

	const handleSort = (e) => {
		e.stopPropagation();
		setOffset(0);
		setLocationUpdates({});
		if (sortInfo?.field === "name") {
			setSortInfo({ field: "associated", order: "ASC" });
		} else if (sortInfo?.field === "associated" && sortInfo?.order === "ASC") {
			setSortInfo({ field: "associated", order: "DESC" });
		} else {
			setSortInfo({ field: "associated", order: "ASC" });
		}
	};

	return (
		<div className="location-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Locations"
				subTitle={
					<>
						Manage locations where this {entityType.label} is present {selectedBrand?.name ? "for" : ""}{" "}
						<strong>{selectedBrand?.name ? selectedBrand?.name : ""}</strong>
					</>
				}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					selectAllLocations ? (
						<React.Fragment>
							<Button
								clickHandler={() => handleUpdate(true, false)}
								classes={totalChanges === 0 ? "disabled" : ""}
							>
								{totalChanges === 0
									? "Associate"
									: `Associate ${allSelected ? locationData.count : totalChanges} Locations`}
							</Button>
							<Button
								clickHandler={() => handleUpdate(false, true)}
								classes={`margin-right at-btn--danger ` + (totalChanges === 0 ? "disabled" : "")}
							>
								{totalChanges === 0
									? "Dissociate"
									: `Dissociate ${allSelected ? locationData.count : totalChanges} Locations`}
							</Button>
						</React.Fragment>
					) : (
						<Button
							clickHandler={() => handleUpdate(false, false)}
							classes={totalChanges === 0 ? "disabled" : ""}
						>
							{totalChanges === 0
								? "Update"
								: `Update ${allSelected ? locationData.count : totalChanges} Location(s)`}
						</Button>
					)
				}
				headerRightClasses={selectAllLocations ? "W(400px)" : ""}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-container">
						<InputWithLabel
							value={currFilters.name}
							onChange={(e) => setFilter("name", e.target.value)}
							placeholder="Name"
						></InputWithLabel>
						<SelectFilter
							placeholder="City"
							options={cityfilters.values || []}
							field={cityfilters.field || ""}
							currValue={currFilters.city || ""}
							setFilter={setFilter}
							labelKey="valueForDisplay"
							valueKey="value"
						/>
						<SelectFilter
							placeholder="Tags"
							options={tagfilters.values || []}
							field={tagfilters.field || ""}
							currValue={currFilters.tags || ""}
							setFilter={setFilter}
							labelKey="valueForDisplay"
							valueKey="value"
						/>
					</div>
					{showAllFieldSelector && totalPages > 1 && (
						<SelectAllFields
							allSelected={allSelected}
							handleCompleteSelection={handleCompleteSelection}
							mainMessage={
								<span>
									{allSelected ? "All " : ""}
									<strong>
										{allSelected ? locationData.count : locationData?.objects?.length}{" "}
									</strong>{" "}
									location(s){" "}
									{allSelected ? "on all pages are selected." : "on this page are selected."}
								</span>
							}
							linkMessage={
								allSelected ? (
									" Clear selection"
								) : (
									<span>
										{" "}
										Select <strong>{locationData.count}</strong> locations from all pages
									</span>
								)
							}
						/>
					)}
					<Table
						data={locationData.objects}
						loading={loading}
						handleCheck={handleCheck}
						locationUpdates={locationUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						allSelected={allSelected}
						handleSort={handleSort}
						sortInfo={sortInfo}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={locationData.count}
						goToPage={handlePagination}
						readOnly={allSelected}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default LocationEntityAssociations;

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	locationUpdates,
	allSelected,
	handleSort,
	sortInfo
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && (loading || allSelected) ? "disabled" : "") +
				" transaction-table-holder common-table-container locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						handleSort={handleSort}
						sortInfo={sortInfo}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].bizLocationId}
							handleCheck={handleCheck}
							locationUpdates={locationUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Locations found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, handleSort, sortInfo }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<div className={"checkbox-sort" + (sortInfo?.field === "associated" ? " selected" : "")}>
				<img onClick={handleSort} src="/assets/icons/icon-sort.svg" className="sort-button" alt="" />
				<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Name">
					<span>Name</span>
				</CheckBox>
			</div>
		</div>
		<div className={`at-table-cell at-table-header at-header-text city city-header-spacing`}>City</div>
	</div>
);

export const TableList = ({
	bizLocationId,
	bizLocationNickname,
	isAssociated,
	city,
	tags,
	style,
	handleCheck,
	locationUpdates
}) => {
	const status = locationUpdates[bizLocationId] === undefined ? isAssociated : locationUpdates[bizLocationId];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name " title={bizLocationNickname}>
				<CheckBox
					checked={status}
					clickHandler={() => handleCheck(bizLocationId, !status)}
					title={bizLocationNickname || "--"}
				>
					{bizLocationNickname || "--"}
				</CheckBox>
				<div className="tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 3 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 3 && (
									<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
										+{tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text city">{city || "--"}</div>
		</animated.div>
	);
};
