import React from "react";

const MailIcon = ({ styles = {}, variations = {}, color = "#6D7AA8" }) => (
	<svg
		width="18"
		height="14"
		style={{ ...styles }}
		viewBox="0 0 18 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...variations}
	>
		<path
			d="M0.5625 0.4375V1.74976L9 7.64697L17.4375 1.75342V0.4375H0.5625ZM0.5625 3.00647V13.5625H17.4375V4.10632L9 8.875L0.5625 3.00647Z"
			fill={color}
		/>
	</svg>
);

export default React.memo(MailIcon);
