import React, { Component } from "react";

// third party
import { connect } from "react-redux";

// components
import { InputWithLabel } from "../_commons/InputWithLabel";
import Select from "react-select-animated";

// client
import { store } from "../../store/configureStore";
import { CONVERSION_TRACKING_TYPE } from "../../client-config";
@connect((store) => ({
	createCampaign: store.createCampaign
}))
class CreateCampaignTrack extends Component {
	changeCT(e, type) {
		let payload = {};
		switch (type) {
			case "days":
				payload = {
					conversionConfig: {
						...this.props.createCampaign.conversionConfig,
						days: e.value
					}
				};
				break;
			case "event":
				payload = {
					conversionConfig: {
						...this.props.createCampaign.conversionConfig,
						event: e.value
					}
				};
				break;
			case "value":
				payload = {
					conversionConfig: {
						...this.props.createCampaign.conversionConfig,
						value: String(e.target.value).toUpperCase()
					}
				};
				break;
			default:
				break;
		}
		store.dispatch({
			type: "CREATE_CAMPAIGN_STATE_UPDATE",
			payload: payload
		});
	}

	render() {
		const { createCampaign, isFetchingDetails } = this.props;
		return (
			<div className={"create-campaign-section " + (isFetchingDetails ? "disabled" : "")}>
				<div className="create-campaign-header">
					<Header />
				</div>
				<div className="create-campaign-forms-container">
					<div className="data-container Mstart(25px)">
						<div className="campaign--input-with-label">
							<div className="label">Conversion time</div>
							<div className="input-block-container">
								<Select
									searchable={false}
									className="at-dropdown"
									options={[
										{
											label: "1 day",
											value: 1
										},
										{
											label: "5 days",
											value: 5
										},
										{
											label: "10 days",
											value: 10
										}
									]}
									value={createCampaign.conversionConfig.days || 1}
									clearable={false}
									onChange={(e) => {
										this.changeCT(e, "days");
									}}
								/>
							</div>
						</div>
						<div className="campaign--input-with-label campaign-track">
							<div className="label">Conversion Events</div>
							<div>
								<div className="input-block-container">
									<Select
										searchable={false}
										className="at-dropdown"
										options={CONVERSION_TRACKING_TYPE}
										value={createCampaign.conversionConfig.event}
										clearable={false}
										onChange={(e) => {
											this.changeCT(e, "event");
										}}
										labelKey="title"
										valueKey="value"
									/>
									{createCampaign.conversionConfig.event === "COUPON_USED" && (
										<InputWithLabel
											value={createCampaign.conversionConfig.value || ""}
											type="text"
											classes="inline-input"
											onChange={(e) => this.changeCT(e, "value")}
											placeholder="Code"
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CreateCampaignTrack;

const Header = () => (
	<div>
		<div className="header-text">Setup tracking on your campaign</div>
		<div className="subheader-text">Configure how this campaign should be evaluated</div>
	</div>
);
