import React, { Component } from "react";

// third party
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";

// components
import { SuggestedTargetCampaign } from "../components/Campaign/SuggestedTargetCampaign";
import { CampaignList } from "../components/Campaign/CampaignList";
import CreateCampaign from "./CreateCampaign";
import CampaignDetail from "./CampaignDetail";
import { BuyCreditsFlow } from "../components/_commons/BuyCreditsFlow";

// utils
import { commifyNumbers, getSuffixedNumber } from "../atlas-utils";

// clients
import { store } from "../store/configureStore";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";

const CampaignContainer = () => (
	<Switch>
		<Route exact path="/campaigns" component={Campaign} />
		<Route exact path="/campaigns/new" component={CreateCampaign} />
		<Route exact path="/campaigns/:id" component={CampaignDetail} />
	</Switch>
);

export default CampaignContainer;

@connect((store) => ({
	biz: store.login.loggedInbizDetail
}))
class Campaign extends Component {
	showBuyCredits = () => {
		store.dispatch({
			type: "CREDITS_STATE_UPDATE",
			payload: {
				showBuyCreditsFlow: true,
				source: "campaign_list_view"
			}
		});

		// track this event
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName: 'credit_purchase_start',
		// });
	};

	render() {
		const { biz } = this.props;
		const credits = biz.credits ? biz.credits : 0;
		return (
			<div>
				<div className="credits-counter">
					<div className="inline-text" title={commifyNumbers(credits)}>
						You have {credits > 10000 && <span className="count blue">{getSuffixedNumber(credits)}</span>}
						{credits > 1000 && credits <= 10000 && (
							<span className="count green">{getSuffixedNumber(credits)}</span>
						)}
						{credits > 0 && credits <= 1000 && (
							<span className="count orange">{getSuffixedNumber(credits)}</span>
						)}
						{credits <= 0 && <span className="count red">{getSuffixedNumber(credits)}</span>} credits left
					</div>
					<div className="inline-text seperator">|</div>
					<div className="inline-text buy-button" onClick={this.showBuyCredits}>
						Buy Credits
					</div>
				</div>
				<BuyCreditsFlow />
				<div className="campaign-section section-container-common">
					<SuggestedTargetCampaign alignment="sidebar" suggested={true} />
				</div>
				<div className="campaign-section campaign-section--campaign-list section-container-common">
					<CampaignList />
				</div>
			</div>
		);
	}
}
