import gql from "graphql-tag";

const GET_ANALYTICS_SUMMARY = gql`
	query getAnalyticsSummary(
		$productType: ProductType!
		$duration: Duration!
		$topic: Topic
		$groupBy: Criteria
		$brand: String
	) {
		analytics {
			topics(productType: $productType, duration: $duration, topic: $topic, groupBy: $groupBy, brand: $brand) {
				displayName
				value
				percentChange
				topic
				criteria
				group
			}
		}
	}
`;

const GET_ANALYTICS_DATASET_GRAPH = gql`
	query getAnalyticsDataset(
		$productType: ProductType!
		$duration: Duration!
		$topic: Topic!
		$criteria: Criteria
		$criteriaValue: String
		$dataFormat: DataFormat!
		$brand: String
	) {
		analytics {
			dataset(
				productType: $productType
				duration: $duration
				topic: $topic
				criteria: $criteria
				criteriaValue: $criteriaValue
				dataFormat: $dataFormat
				brand: $brand
			) {
				... on GraphDataset {
					current {
						timestamp
						value
					}
					previous {
						timestamp
						value
					}
					timeUnit
				}
			}
		}
	}
`;

const GET_ANALYTICS_DATASET_TAB = gql`
	query getAnalyticsDataset(
		$productType: ProductType!
		$duration: Duration!
		$topic: Topic!
		$criteria: Criteria
		$criteriaValue: String
		$dataFormat: DataFormat!
		$brand: String
	) {
		analytics {
			dataset(
				productType: $productType
				duration: $duration
				topic: $topic
				criteria: $criteria
				criteriaValue: $criteriaValue
				dataFormat: $dataFormat
				brand: $brand
			) {
				... on TabularDataset {
					columns {
						key
						displayName
					}
					rows {
						key
						value
					}
				}
			}
		}
	}
`;

export { GET_ANALYTICS_SUMMARY, GET_ANALYTICS_DATASET_GRAPH, GET_ANALYTICS_DATASET_TAB };
