import React from "react";
import { connect } from "react-redux";
import { fetchMerakiWebWorkspace, publishMerakiWebWorkspace } from "../../actions/merakiWeb";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Modal } from "../_commons/Modal";

const WorkspaceDisabledStates = ["Publish scheduled", "Publish in progress"];

const PublishWrapper = ({ children, merakiWebWorkspace, onPublishComplete = () => {} }) => {
	const { data } = merakiWebWorkspace;
	const [isModalOpen, setModalOpen] = React.useState(false);
	const [loadingPublish, setLoadingPublish] = React.useState(false);
	const [publishChangelog, setPublishChangelog] = React.useState("");
	const [currentPublishingTheme, setCurrentPublishingTheme] = React.useState(null);

	const handlePublishOpen = (theme) => {
		setCurrentPublishingTheme(theme);
		setModalOpen(true);
		setPublishChangelog("");
		setLoadingPublish(false);
	};

	const handlePublishSubmit = async () => {
		setLoadingPublish(true);
		const payload = {
			changelog: publishChangelog
		};

		const resp = await publishMerakiWebWorkspace(payload, currentPublishingTheme);

		if (resp?.success) {
			fetchMerakiWebWorkspace();
			onPublishComplete();
			setModalOpen(false);
		}

		setLoadingPublish(false);
	};

	const handlePublishClose = () => {
		setModalOpen(false);
		setPublishChangelog("");
	};

	return (
		<React.Fragment>
			{children({
				handlePublishOpen,
				publishEnabled: !WorkspaceDisabledStates.includes(data.status) && !loadingPublish
			})}

			<Modal
				isOpen={isModalOpen}
				loading={loadingPublish}
				close={handlePublishClose}
				title={`Publish ${currentPublishingTheme?.toUpperCase?.()} site`}
				subTitle={"Please provide a brief changelog message"}
				showSubmitAction={true}
				submitTitle={"Publish"}
				submitAction={handlePublishSubmit}
				showCancelAction={true}
				cancelTitle={"Cancel"}
				disabled={publishChangelog === "" || loadingPublish}
			>
				<div className={"base-padding-large"}>
					<InputWithLabel
						value={publishChangelog}
						onChange={(e) => setPublishChangelog(e.target.value || "")}
						placeholder={"Type your message here"}
						autoFocus
					/>
				</div>
			</Modal>
		</React.Fragment>
	);
};

const mapStateToProps = (store) => ({
	merakiWebWorkspace: store.merakiWebWorkspace
});

export default connect(mapStateToProps)(PublishWrapper);
