import React, { useRef } from "react";

// third party
import { debounce } from "lodash";

const Preview = ({ url, config, selectedPage, handlePageSelected }) => {
	const iframeRef = useRef();

	React.useEffect(() => {
		debounce(() => {
			postIframeMessage(iframeRef, {
				type: "meraki/config-updated",
				config
			});
		}, 100)();
	}, [config]);

	React.useEffect(() => {
		// if landing page is disabled then dont auto switch page
		if (selectedPage?._id === "landing_screen" && !selectedPage?.enabled) {
			return;
		}
		debounce(() => {
			postIframeMessage(iframeRef, {
				type: "meraki/page-updated",
				page: selectedPage
			});
		}, 100)();
	}, [selectedPage]);

	React.useEffect(() => {
		function listener(e) {
			if (e?.data?.type === "meraki/navigate") {
				if (e?.data?.pageId) {
					debounce(() => {
						handlePageSelected(e.data.pageId);
					}, 100)();
				}
			}
		}

		window.addEventListener("message", listener);

		return () => window.removeEventListener("message", listener);
	}, [handlePageSelected]);

	return (
		<div className="meraki-web-editor-section section-container-common">
			<div
				className="preview-container"
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					e.bubbles = false;
				}}
			>
				<iframe
					ref={iframeRef}
					style={{
						height: "100%",
						width: "100%",
						border: "0px"
					}}
					src={url}
					onLoad={() => {
						postIframeMessage(iframeRef, {
							type: "meraki/config-updated",
							config
						});
					}}
				/>
			</div>
		</div>
	);
};

/**
 * Post a message to iframe
 */
function postIframeMessage(ref, message) {
	if (!ref.current || !ref.current.contentWindow) {
		return;
	}

	ref.current.contentWindow.postMessage(message, "*");
}

export default Preview;
