import React from "react";

// components
import { InputField } from "../_commons/InputField";
import { SelectFilter } from "../_commons/SelectFilter";
import TableColumnSelector from "../_commons/TableColumnSelector";
import { Button } from "../_commons/Button";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// utils
import { extractInitials } from "../../atlas-utils";

// constants
import {
	ONLINE_TRANSACTIONS_LIST_SEARCH_FIELDS,
	IN_STORE_TRANSACTIONS_LIST_SEARCH_FIELDS,
	STORE_TYPES
} from "../../client-config";

export const Header = (props) => {
	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="credits-section-header transactions-list-header orders-list-header">
			<div className="header-text">
				<div className="title">Orders</div>
				<div className="subtitle">List of all your online orders</div>
			</div>
			<div className="header--actions">
				<div className="filter-buttons">
					{props.isMultibrandEnabled && (
						<SelectFilterCustom
							options={props.brands.items}
							isLoading={props.brands.isLoading}
							field="brands"
							currValue={props.brands.selectedBrand}
							setFilter={props.handleBrand}
							labelKey="name"
							valueKey="id"
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							classes="brand-filter"
							isClearable
						/>
					)}
					<NewDateCompareFilter
						loading={props.loading}
						currentDateFilter={props.currentDateFilter}
						appliedDateFilter={props.appliedDateFilter}
						updateState={props.updateState}
						includeAllTime={true}
						hidePresetTypes={["15 D"]}
						showDropdown
					/>
					{/* <div className="product-type">
						<SelectFilter
							options={props.productTypeOptions}
							currValue={props.productTypeOptions.find((opt) => opt.value === props.storeType)}
							setFilter={(field, value) => props.handleStoreType(value?.value)}
							isClearable={false}
						/>
					</div> */}

					{/* {
						props.dimensions.width > 768 &&
						<div className={(props.filterActive ? "active":"")+" filter-in-header campaign-list-filter"}>
							<div className="container" onClick={props.flipShowFilters}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt=""/>
								<div className="filter-title">
									Filters
									{
										props.filterCount > 0 &&
										<span className="filter-count">{props.filterCount}</span>
									}
								</div>
							</div>
						</div>
					} */}
					{
						<Button type="filter" filterCount={props.filterCount || 0} clickHandler={props.flipShowFilters}>
							Filter
						</Button>
					}
				</div>
				<div className="section-2-filters">
					<div className="search-input-holder">
						<SelectFilter
							isSearchable={false}
							options={
								props.storeType === STORE_TYPES[0].type
									? ONLINE_TRANSACTIONS_LIST_SEARCH_FIELDS
									: IN_STORE_TRANSACTIONS_LIST_SEARCH_FIELDS
							}
							currValue={props.searchFieldSelected}
							isClearable={false}
							setFilter={(field, value) => props.handleSearchField(value)}
							labelKey="title"
						/>
						<div className="search-input-container">
							<InputField
								classes="search-input"
								type="text"
								placeholder="Search"
								value={props.searchKW}
								onChange={(e) => props.handleSearchKW(e.target.value)}
								onEnter={props.searchTransaction}
							/>
							{props.searchKW ? (
								<div onClick={props.cancelSearchTransaction} className="dismiss-search">
									<img className="" src="/assets/icons/cancel.png" />
								</div>
							) : null}
						</div>
						<TableColumnSelector
							options={props.tableFields}
							handleColumnSelection={props.handleColumnSelection}
							selectedColumns={props.selectedColumns}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
