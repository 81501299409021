import React from "react";

// third party
import { useSpring, animated as a } from "react-spring";

// components
import { Button } from "../_commons/Button";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { trackEvent } from "../../atlas-utils";

export const PlatformSelection = ({
	handleSelectedTabUpdate,
	platforms,
	handleFormData,
	setStepCount,
	platformLocationLinks
}) => {
	const contentProps = useSpring({
		config: { duration: 1 },
		from: { opacity: "0" },
		to: { opacity: "1" }
	});

	const handleClick = (platformName) => {
		let updatedPlatforms = { ...platforms };

		if (platforms[platformName]) {
			delete updatedPlatforms[platformName];
		} else {
			updatedPlatforms[platformName] = true;
		}

		if (Object.keys(updatedPlatforms).length > 1 && updatedPlatforms.meraki) {
			setStepCount(7);
		} else {
			setStepCount(6);
		}
		handleFormData("platforms", updatedPlatforms);
	};

	const handleNextClick = () => {
		handleSelectedTabUpdate(3);
		trackEvent("wiz_loc_platform_selection", {
			platforms: Object.keys(platforms),
			count: Object.keys(platforms).length
		});
	};

	const disabledCheck = Object.keys(platforms).length > 0;

	return (
		<a.div className={`common-box-structure`} style={contentProps}>
			<div className="header">Select the platforms you're planning to go live on</div>
			<div className="location-create-platforms-section">
				{Object.keys(platformLocationLinks).length > 0 && (
					<div className="platforms-widget platforms-widget-big-div">
						<div className="title">Popular Platforms</div>
						<div className="available-platforms">
							{Object.keys(platformLocationLinks)
								.filter((platform) => platform !== "urbanpiper")
								.map((platform, i) => (
									<div
										key={i}
										onClick={() => handleClick(platform)}
										className={`icon-with-selector  ${platforms[platform] ? "selected" : ""}`}
										title={platform}
									>
										<div className="overridden-layer" />
										<img
											src={
												CATALOGUE_PLATFORMS_LOGO[platform]
													? CATALOGUE_PLATFORMS_LOGO[platform]
													: CATALOGUE_PLATFORMS_LOGO.default
											}
										/>
									</div>
								))}
						</div>
					</div>
				)}
				<div className="platforms-widget">
					<div className="title">Your Platforms</div>
					<div className="available-platforms">
						<div
							onClick={() => handleClick("meraki")}
							className={`icon-with-selector  ${platforms.meraki ? "selected" : ""}`}
						>
							<div className="overridden-layer" />
							<img src="/assets/icons/icon-meraki.svg" className="meraki-logo-size-adjust" />
						</div>
					</div>
				</div>
			</div>
			<div className="button-div">
				<Button clickHandler={() => handleSelectedTabUpdate(1)} type="secondary">
					<span>Previous</span>
				</Button>
				<Button classes={!disabledCheck && "disabled"} clickHandler={handleNextClick}>
					<span>Next</span>
				</Button>
			</div>
		</a.div>
	);
};
