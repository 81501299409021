import React from "react";

// components
import { SearchFilter } from "../_commons/SearchFilter";

// Category/Sections Header in context of Menu
const Header = ({ searchFieldValue, handleSearch }) => {
	return (
		<div className="header-container">
			<div className="header-text">
				<div className="title">Sections</div>
				<div className="subtitle">Central repository for all your sections</div>
			</div>
			<SearchFilter
				filterOption={{ field: "searchFieldValue" }}
				value={searchFieldValue}
				setFilter={handleSearch}
				placeholder="Search"
			/>
		</div>
	);
};

export default Header;
