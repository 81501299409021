import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const StackProgressBar = ({
	data,
	keys,
	keysColorMapping,
	outerDivClass = "",
	innerDivClass = "",
	padding = 0,
	borderRadius = 0
}) => {
	const fill = keys.map((key) => {
		return {
			match: {
				id: key
			},
			id: key
		};
	});

	const defs = keys.map((key) => {
		return {
			id: key,
			type: "patternLines",
			background: keysColorMapping[key],
			color: keysColorMapping[key],
			size: 0,
			padding: padding,
			stagger: false
		};
	});
	if (innerDivClass == "") {
		innerDivClass = outerDivClass;
	}

	return (
		<div className={outerDivClass}>
			<div className={innerDivClass}>
				<ResponsiveBar
					data={data}
					keys={keys}
					indexBy=""
					margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
					padding={0}
					innerPadding={padding}
					layout="horizontal"
					colors={{ scheme: "nivo" }}
					fill={fill}
					defs={defs}
					borderColor={{
						from: "color",
						modifiers: [["darker", "1"]]
					}}
					axisTop={null}
					axisRight={null}
					axisBottom={null}
					axisLeft={null}
					enableGridX={false}
					enableGridY={false}
					enableLabel={false}
					legends={[]}
					isInteractive={false}
					animate={false}
					borderRadius={borderRadius}
				/>
			</div>
		</div>
	);
};

export default StackProgressBar;
