// action types
import { ActionTypes } from "../actions/_types";

// utils
import { getSortOrder } from "../atlas-utils";

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "title",
		order: "ASC"
	},
	sortedField: "title",
	currentFilters: {},
	appliedFilters: {}
};
export const itemGroupsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.ITEM_GROUPS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.ITEM_GROUPS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.ITEM_GROUPS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const ITEM_GROUPS_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: []
	},
	error: false
};
export const itemGroupsList = (state = ITEM_GROUPS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_ITEM_GROUPS_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.GET_ITEM_GROUPS_LIST_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case ActionTypes.GET_ITEM_GROUPS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_ITEM_GROUPS_LIST:
			let foundInList = false;
			const updatedObjects = state.data.objects.map((obj) => {
				if (obj.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return obj;
			});
			if (!foundInList) {
				updatedObjects.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedObjects,
					count: state.data.count + 1
				}
			};
		default:
			return state;
	}
};

const ITEM_GROUP_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {},
	error: {}
};
export const itemGroupDetails = (state = ITEM_GROUP_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_ITEM_GROUPS_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_ITEM_GROUPS_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ActionTypes.GET_ITEM_GROUPS_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.RESET_ITEM_GROUPS_DETAIL:
			return ITEM_GROUP_DETAILS_INITIAL_STATE;
		case ActionTypes.UPDATE_ITEM_GROUPS_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_ITEM_GROUPS_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_ITEM_GROUPS_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_ITEM_GROUPS_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
