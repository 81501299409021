// utils
import { lS } from "../atlas-utils";

// constants
import {
	PRODUCT_TYPE_MODULE_MAPPING,
	TOPIC_LIST_TOP_PERFORMING,
	TOPIC_LIST_ANALYTICS,
	DASHBOARD_DURATION_PRESETS,
	CRITERIA_ANALYTICS
} from "../client-config";
import { PRESET_TYPES, DATE_TYPES, COMPARE_DATE_TYPES } from "../components/_commons/NewDateCompareFilter";

const modulesEnabled = lS.get("auth") ? lS.get("auth").biz.modulesEnabled : [];
// let productType = lS.get('productType') || 'ORDERING';
let productType = "ORDERING";
let productTypeOptions = [
	{
		label: "Online",
		value: "ORDERING",
		disabled: false
	},
	{
		label: "In-Store",
		value: "LOYALTY",
		disabled: false
	}
];
let activeProductType = "ORDERING";

// update producType options based on the modules enabled for the business
// productTypeOptions = productTypeOptions.map((prd) => {
// 	if(modulesEnabled.indexOf(PRODUCT_TYPE_MODULE_MAPPING[prd.value]) === -1) {
// 		prd.disabled = true;
// 		return prd;
// 	}
// 	prd.disabled = false;
// 	activeProductType = prd.value;
// 	return prd;
// });

// if currently selected producType is disabled then change product type to active one
if (productTypeOptions.find((prd) => prd.value === productType).disabled) {
	productType = activeProductType;
	lS.set("productType", productType);
}

// reducer
export const selectedModule = (
	state = {
		productType,
		productTypeOptions
	},
	action
) => {
	switch (action.type) {
		case "SET_APP_MODULE":
			lS.set("productType", action.payload);
			return {
				...state,
				productType: action.payload
			};
		case "SET_APP_MODULE_OPTIONS":
			return {
				...state,
				productTypeOptions: action.payload
			};
		default:
			return state;
	}
};

// dashboard initial date filter state
export const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[1].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[1],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateFilter: "",
		dateTypeSelected: COMPARE_DATE_TYPES[0],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	}
};

// reducer
export const dashboardState = (
	state = {
		// duration: DASHBOARD_DURATION_PRESETS[0].value,
		currentDateFilter: INITIAL_DATE_FILTER_STATE,
		appliedDateFilter: INITIAL_DATE_FILTER_STATE,
		topPerfTopic: productType === "ORDERING" ? TOPIC_LIST_TOP_PERFORMING[0] : TOPIC_LIST_TOP_PERFORMING[5],
		analyticsTopic: TOPIC_LIST_ANALYTICS[0],
		criteria: CRITERIA_ANALYTICS[0],
		criteriaValue: "all" //it should not be hardcoded
	},
	action
) => {
	switch (action.type) {
		case "DASHBOARD_STATE_CHANGE":
			return {
				...state,
				...action.payload
			};
		case "SET_APP_MODULE":
			return {
				...state,
				topPerfTopic:
					action.payload === "ORDERING" ? TOPIC_LIST_TOP_PERFORMING[0] : TOPIC_LIST_TOP_PERFORMING[5]
			};
		case "ANA_SUMMARY_STATE_CHANGE_RESET_CRITERIA":
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
