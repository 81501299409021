import React from "react";
import ReactCrop from "react-image-crop";
import { Modal } from "./Modal";
const CropModal = ({
	isOpen,
	close,
	cancelAction,
	submitAction,
	classes,
	imgRef,
	imgSrc,
	onImageLoad,
	crop,
	aspect,
	setCompletedCrop,
	setCrop,
	title
}) => {
	return (
		<Modal
			isOpen={isOpen}
			title={title}
			classes={classes}
			showCancelAction
			showSubmitAction
			submitAction={submitAction}
			cancelAction={cancelAction}
			close={close}
		>
			{
				<ReactCrop
					crop={crop}
					onChange={(_, percentCrop) => setCrop(percentCrop)}
					onComplete={(c) => setCompletedCrop(c)}
					aspect={aspect}
				>
					<img
						ref={imgRef}
						alt="Crop me"
						src={imgSrc}
						onLoad={onImageLoad}
						style={{ maxHeight: "400px", width: "fit-content", objectFit: "contain" }}
					/>
				</ReactCrop>
			}
		</Modal>
	);
};

export default CropModal;
