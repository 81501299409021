import React, { useState, useEffect, useRef, useCallback } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";

// utils
import history from "../history";

// graphql
import Upload from "../components/ReconciliationUpload/Upload";
import { fetchPayoutSheetsList } from "../actions/reconciliation";
import HelpIcon from "../components/_commons/HelpIcon";
import { Button } from "../components/_commons/Button";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import { adjustNestedContainer, trackEvent } from "../atlas-utils";
import { NESTED_ENTITY_TYPES } from "../client-config";

const INIT_STATE = {
	uploading: false,
	file: undefined,
	inputRef: null,
	successData: undefined,
	selectedPlatform: "",
	locationName: "---",
	submitTitle: "",
	error: undefined
};

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const ReconciliationUpload = ({
	hasAccess = false,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState(INIT_STATE.error);
	const [submitTitle, setSubmitTitle] = useState(INIT_STATE.submitTitle);
	const [uploading, setUploading] = useState(INIT_STATE.uploading);
	const [files, setFiles] = useState(INIT_STATE.file);
	const inputRef = useRef(INIT_STATE.inputRef);
	const [successData, setSuccessData] = useState(INIT_STATE.successData);
	const [locationName, setLocationName] = useState(INIT_STATE.locationName);
	const [isModalBusy, setModalBusy] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const [isHelpSidebarOpen, setHelpSidebarOpen] = useState(false);

	const handleCancel = async () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchPayoutSheetsList();
					history.push("/payout-sheets");
				}
			}, 100);
		}
	};

	const handleSubmit = () => {
		setError(INIT_STATE.error);
		setUploading(INIT_STATE.uploading);
		setFiles(INIT_STATE.file);
		setSuccessData(INIT_STATE.successData);
		setLocationName(INIT_STATE.locationName);
		setSubmitTitle(INIT_STATE.submitTitle);
	};

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
			setTimeout(() => {
				setHelpSidebarOpen(false);
			}, 100);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen, !toOpen);
	}, []);

	const openUploadHelpSection = () => {
		setHelpSidebarOpen(true);
		setTimeout(() => handleNestedEntity(true, NESTED_ENTITY_TYPES[14], ""), 100);
	};

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		trackEvent("recon_upload_btn_list", {});
	}, []);

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Upload"
			subTitle="Upload a payout for reconciliation"
			submitTitle={submitTitle}
			cancelTitle="Dismiss"
			loading={confirmLoading}
			disabled={false}
			hideActions={!submitTitle}
			submitButtonWidth={200}
			headerRight={
				<div className="help-btn-container">
					<Button clickHandler={openUploadHelpSection} type="secondary">
						<HelpIcon />
						<span>Help</span>
					</Button>
				</div>
			}
			overlaySidebarClasses={!isHelpSidebarOpen && "medium"}
		>
			<div className="form-content">
				<Upload
					setSubmitTitle={setSubmitTitle}
					uploading={uploading}
					setUploading={setUploading}
					files={files}
					setFiles={setFiles}
					inputRef={inputRef}
					successData={successData}
					setSuccessData={setSuccessData}
					locationName={locationName}
					setLocationName={setLocationName}
					error={error}
					setError={setError}
				/>
				<NestedEntityContainer
					show={nestedEntity.show}
					type={nestedEntity.type}
					id={nestedEntity.id}
					closeNestedContainer={() => handleNestedEntity(false)}
					nestedRef={nestedRef}
				/>
			</div>
		</FormSidebar>
	);
};
export default ReconciliationUpload;
