import React from "react";

const LocationCell = ({ props }) => {
	let ratingText = "";
	let displayName = "";
	if (props?.value?.name) {
		const {
			value: { name, rating }
		} = props;
		displayName = name;
		if (rating !== null && rating >= 0) {
			ratingText = (
				<span className="brand-text-location rating-text-location">
					{rating} <img src="/assets/periscope/star-rating.svg" className="star-image-location" />
				</span>
			);
		}
	}

	return (
		<div className="inside-text-location   location-width-display location-display-main">
			<span className="brand-text-location inside-text-location locationcell-display  ">{displayName}</span>
			{ratingText}
		</div>
	);
};

export default LocationCell;
