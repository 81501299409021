import React from "react";

const Brand = ({ variations = {}, styles = {} }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ ...styles }}
		>
			<path
				d="M1 1.0237e-08L10 1.0237e-07C10.5525 1.08025e-07 11 0.4475 11 1L11 10C11 10.5525 10.5525 11 10 11L1 11C0.4475 11 -1.08025e-07 10.5525 -1.0237e-07 10L-1.0237e-08 1C-4.58104e-09 0.4475 0.4475 4.58104e-09 1 1.0237e-08ZM1 13L10 13C10.5525 13 11 13.4475 11 14L11 23C11 23.5525 10.5525 24 10 24L1 24C0.4475 24 -2.41106e-07 23.5525 -2.3545e-07 23L-1.43317e-07 14C-1.37661e-07 13.4475 0.4475 13 1 13ZM14 1.43317e-07L23 2.3545e-07C23.5525 2.41106e-07 24 0.4475 24 1L24 10C24 10.5525 23.5525 11 23 11L14 11C13.4475 11 13 10.5525 13 10L13 1C13 0.4475 13.4475 1.37661e-07 14 1.43317e-07ZM14 13L23 13C23.5525 13 24 13.4475 24 14L24 23C24 23.5525 23.5525 24 23 24L14 24C13.4475 24 13 23.5525 13 23L13 14C13 13.4475 13.4475 13 14 13Z"
				fill="#AAAAAA"
				{...variations}
			/>
		</svg>
	);
};

export default React.memo(Brand);
