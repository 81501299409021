import gql from "graphql-tag";

export const GET_COUPONS_LIST = gql`
	query fetchCouponsList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		coupons(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			objects {
				id
				title
				rewardDesc
				isExpired
				rewardTypeDisplay
				startsOn
				expiresOn
			}
			count
			hasPrevious
			hasNext
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const GET_COUPON_TYPES = gql`
	query getCouponTypes {
		couponTypes {
			value
			valueForDisplay
		}
	}
`;

export const CREATE_COUPON = gql`
	mutation createCoupon(
		$rewardTitle: String
		$rewardDesc: String
		$rewardType: Type
		$rewardValue: String
		$redemptionCode: String
		$isSuggested: Boolean
		$allowMultiUse: Boolean
		$startsOn: String
		$expiresOn: String
		$merchantRefId: String
	) {
		saveCoupon(
			input: {
				rewardTitle: $rewardTitle
				rewardDesc: $rewardDesc
				rewardType: $rewardType
				rewardValue: $rewardValue
				redemptionCode: $redemptionCode
				isSuggested: $isSuggested
				allowMultiUse: $allowMultiUse
				startsOn: $startsOn
				expiresOn: $expiresOn
				merchantRefId: $merchantRefId
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				rewardDesc
				isExpired
				rewardTypeDisplay
				startsOn
				expiresOn
			}
		}
	}
`;

export const GET_COUPON = gql`
	query fetchCouponDetails($id: Int) {
		coupon(id: $id) {
			id
			rewardTitle
			rewardDesc
			rewardType
			rewardValue
			redemptionCode
			isSuggested
			allowMultiUse
			startsOn
			expiresOn
			merchantRefId
			hasValidationCodes
			isActive
		}
	}
`;

export const EDIT_COUPON = gql`
	mutation editCouponDetails(
		$id: Int
		$rewardTitle: String
		$rewardDesc: String
		$rewardType: Type
		$rewardValue: String
		$redemptionCode: String
		$isSuggested: Boolean
		$allowMultiUse: Boolean
		$startsOn: String
		$expiresOn: String
		$merchantRefId: String
		$isActive: Boolean
	) {
		saveCoupon(
			input: {
				id: $id
				rewardTitle: $rewardTitle
				rewardDesc: $rewardDesc
				rewardType: $rewardType
				rewardValue: $rewardValue
				redemptionCode: $redemptionCode
				isSuggested: $isSuggested
				allowMultiUse: $allowMultiUse
				startsOn: $startsOn
				expiresOn: $expiresOn
				merchantRefId: $merchantRefId
				isActive: $isActive
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				rewardDesc
				isExpired
				rewardTypeDisplay
				startsOn
				expiresOn
			}
		}
	}
`;

export const GET_ORDERING_RULES = gql`
	query getOrderingRules {
		orderingRules {
			ruleName
			uiText
			description
			parameters {
				name
				uiText
				inputType
				multiple
				choices {
					value
					valueForDisplay
				}
			}
		}
	}
`;

export const GET_COUPON_RULES = gql`
	query getCouponRules($id: Int) {
		coupon(id: $id) {
			rules {
				id
				ruleName
				ruleApplyOrder
				parameters {
					id
					paramName
					paramValue
				}
			}
		}
	}
`;

export const UPDATE_COUPON_RULES = gql`
	mutation updateCouponRules($id: Int!, $rules: [RuleInputType]!, $rulesToDelete: [Int]) {
		saveCouponRules(input: { id: $id, rules: $rules, rulesToDelete: $rulesToDelete }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_PIPER_ACTIONS = gql`
	query listPiperActions {
		piperActions {
			actionName
			uiText
			parameters {
				name
				uiText
				inputType
				multiple
				required
				choices {
					value
					valueForDisplay
				}
			}
		}
	}
`;

export const GET_COUPON_ACTIONS = gql`
	query getCouponActions($id: Int) {
		coupon(id: $id) {
			actions {
				id
				actionName
				uiText
				parameters {
					paramName
					paramValue
				}
			}
		}
	}
`;

export const UPDATE_COUPON_ACTIONS = gql`
	mutation updateCouponActions($id: Int!, $action: ActionInputType) {
		saveCouponAction(input: { id: $id, action: $action }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_COUPON_ACTION_INCLUDE_OPTION = gql`
	mutation updateCouponActionIncludeOption(
		$couponId: Int!
		$pActionId: Int!
		$paramType: ItemAssociationActionParamType!
		$paramValue: [ParamValueIncludeOptionInputType]
	) {
		updateCouponActionIncludeOption(
			input: { couponId: $couponId, pActionId: $pActionId, paramType: $paramType, paramValue: $paramValue }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const CREATE_BULK_COUPONS = gql`
	mutation createBulkCoupons($input: BulkCouponInput) {
		saveBulkCoupons(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				rewardDesc
			}
		}
	}
`;
