import React from "react";
import { connect } from "react-redux";

// third party
import { useTrail, config, animated } from "react-spring";
import { Link } from "react-router-dom";

// utils
import { printCurrency } from "../../atlas-utils";

// constants
import { NESTED_ENTITY_TYPES, FOOD_TYPE_MAP } from "../../client-config";
import Image from "../_commons/Image";
import MonogramGenerator from "../_commons/MonogramGenerator";

const mapStateToProps = (store) => ({
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export const ItemsTable = connect(mapStateToProps)(({
	data,
	loading,
	sortList,
	sortedField,
	currencySymbol,
	headerFields,
	classes = "",
	RenderTable,
	archived,
	underRootCategory,
	emptyScreenType = "simple",
	categoryName,
	showTableHeader = true,
	openNestedEntity,
	isMultibrandEnabled
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	const TableComponent = RenderTable || TableList;

	if (emptyScreenType === "actionable" && data.length === 0 && !loading) {
		return <EmptyScreen categoryName={categoryName} type={emptyScreenType} underRootCategory={underRootCategory} />;
	}

	return (
		<div className={(data.length > 0 && loading ? "disabled" : "") + ` common-table-container ${classes}`}>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					{showTableHeader && (
						<TableHeader sortList={sortList} sortedField={sortedField} headerFields={headerFields} />
					)}
					{trails.map(({ rotate }, i) => (
						<TableComponent
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							currencySymbol={currencySymbol}
							archived={archived}
							openNestedEntity={openNestedEntity}
							index={i}
							isMultibrandEnabled={isMultibrandEnabled}
						/>
					))}
					{data.length === 0 && !loading && (
						<EmptyScreen type={emptyScreenType} underRootCategory={underRootCategory} />
					)}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
});

const EmptyScreen = ({ type, underRootCategory, categoryName }) => {
	if (type === "actionable") {
		return (
			<div className="actionable-empty-screen">
				<img className="empty-img" src="/assets/empty-screen.png" />
				{underRootCategory ? (
					<div>
						<div className="empty-msg">
							You have not added any items or subcategories to
							<span className="font--semibold"> {categoryName}</span>. Here are some useful links:
						</div>
						<ul>
							<li>
								<Link className="link-text" to="/piper-academy/categories?start=02:20">
									Learn how to add items to a category
								</Link>
							</li>
							<li>
								<Link className="link-text" to="/piper-academy/categories?start=03:23">
									Learn how to add subcategories to a category
								</Link>
							</li>
						</ul>
					</div>
				) : (
					<div>
						<div className="empty-msg">
							You have not added any items to
							<span className="font--semibold"> {categoryName}</span>. Here are some useful links:
						</div>
						<ul>
							<li>
								<Link className="link-text" to="/piper-academy/categories?start=02:20">
									Learn how to add items to a category
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		);
	}
	return <div className="no-items-placeholder">No Items found!</div>;
};

const TableHeader = ({ sortList, sortedField = "", headerFields }) => (
	<div className={`at-table-row transaction-header-row categories`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={
						`at-table-cell at-table-header at-header-text ${field.value}` +
						(field.sortKey ? " clickable" : "") +
						(sortedField === field.sortKey ? " active" : "")
					}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

const TableList = ({
	id,
	itemTitle,
	foodType,
	crmTitle,
	tags,
	itemPrice,
	currencySymbol,
	style,
	openNestedEntity,
	imageUrl,
	brandsData,
	isMultibrandEnabled,
	locationsCount
}) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows categories-edit"
		>
			<div className="at-table-cell at-cell-text items" title={itemTitle}>
				<Image
					src={imageUrl || "/assets/empty-dish.jpg"}
					alt={itemTitle}
					className="item-image"
					errorOverrideImage="/assets/empty-dish.jpg"
				/>
				<div className="item-desc">
					{/* <span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} /> */}
					<a
						role="button"
						className="item--anchor"
						onClick={(e) => openNestedEntity(e, NESTED_ENTITY_TYPES[1], id)}
					>
						<span className="hyperlink hyperlink--black-color">{itemTitle || id}</span>
					</a>
					{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
					<div className="text--light item_tags">
						{tags.length > 0 && (
							<div className="tags-container table-mode">
								<div className="list">
									{tags.map(
										(tag, i) =>
											i < 3 && (
												<span key={i} className="tag-item" title={tag}>
													{tag}
												</span>
											)
									)}
									{tags.length > 3 && (
										<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
											+{tags.length - 3} more
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="at-table-cell at-cell-text locationsCount">{locationsCount || "--"}</div>
			{isMultibrandEnabled && (
				<div className="at-table-cell at-cell-text brands">
					{(brandsData ?? []).map((brand) =>
						brand.image ? (
							<Image src={brand.image} className="brand-image" title={brand.name} />
						) : (
							<MonogramGenerator value={brand.name} size="xsmall" title={brand.name} />
						)
					)}
				</div>
			)}
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{itemPrice || 0}
			</div>
		</animated.div>
	);
};
