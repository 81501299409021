import React from "react";

// third party
import { connect } from "react-redux";

const BackupStatus = ({ header = null, catalogueBackupsStatus, isLocations = false }) => {
	return (
		<div className="sync-status-container section-container-common">
			{header}
			<div className="sync-icon">
				<img src="/assets/icons/icon-sync.svg" alt="" />
			</div>
			<div className="message">
				{catalogueBackupsStatus.status === "BACKING_UP" && (
					<React.Fragment>
						<div>
							A backup of your catalogue is currently being processed. This usually takes about a minute.
						</div>
						<div>Please wait for the backup to finish.</div>
					</React.Fragment>
				)}
				{catalogueBackupsStatus.status === "RESTORING" && (
					<React.Fragment>
						<div>
							Your catalogue backup is currently being restored. This usually takes between 5 to 10
							minutes.
						</div>
						<div>Please wait for it to finish.</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};
export default connect((store) => ({
	catalogueBackupsStatus: store.catalogueBackupsStatus
}))(BackupStatus);
