// utils
import { getSortOrder } from "../atlas-utils";

// action types
import { ActionTypes } from "../actions/_types";

const INITIAL_STATE = {
	limit: 10,
	offset: 0,
	sort: {
		field: "created",
		order: "DESC"
	},
	sortedField: "created",
	currentFilters: {},
	appliedFilters: {}
};

export const catalogueBackupsListState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CATALOGUE_BACKUPS_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.CATALOGUE_BACKUPS_LIST_STATE_CHANGE_SORT:
			return {
				...state,
				sort: {
					field: action.payload.sort.field,
					order: getSortOrder(state.sort, action.payload.sort)
				},
				sortedField: action.payload.sort.field
			};
		case ActionTypes.CATALOGUE_BACKUPS_LIST_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

const CATALOGUE_BACKUPS_LIST_INITIAL_STATE = {
	loading: false,
	configLoading: false,
	data: {
		catalogueBackupConfig: {},
		objects: [],
		filters: []
	},
	error: false
};
export const catalogueBackupsList = (state = CATALOGUE_BACKUPS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CATALOGUE_BACKUPS_LIST_REQUEST:
			return {
				...state,
				...action.payload,
				error: false
			};
		case ActionTypes.GET_CATALOGUE_BACKUPS_LIST_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				},
				[action.loading]: false
			};
		case ActionTypes.GET_CATALOGUE_BACKUPS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				configLoading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CATALOGUE_BACKUP_CONFIG:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.UPDATE_CATALOGUE_BACKUPS_LIST:
			let foundInList = false;
			const updatedCharges = state.data.objects.map((location) => {
				if (location.id === action.payload.id) {
					foundInList = true;
					return action.payload;
				}
				return location;
			});
			if (!foundInList) {
				updatedCharges.unshift(action.payload);
			}
			return {
				...state,
				data: {
					...state.data,
					objects: updatedCharges,
					count: state.data.count + 1
				}
			};
		default:
			return state;
	}
};

export const CATALOGUE_BACKUP_DETAILS_INITIAL_STATE = {
	loading: true,
	data: {
		formTabs: [
			{
				label: "Categories",
				key: "categories",
				value: "categories",
				sort: "sort_order",
				count: 0
			},
			{
				label: "Items",
				key: "items",
				value: "items",
				sort: "sort_order",
				count: 0
			},
			{
				label: "Modifier Groups",
				key: "option_groups",
				value: "optionGroups",
				sort: "sort_order",
				count: 0
			},
			{
				label: "Modifiers",
				key: "options",
				value: "options",
				sort: "option_order",
				count: 0
			},
			{
				label: "Taxes",
				key: "taxes",
				value: "taxes",
				sort: "title",
				count: 0
			},
			{
				label: "Charges",
				key: "charges",
				value: "charges",
				sort: "title",
				count: 0
			},
			{
				label: "Item Groups",
				key: "item_groups",
				value: "itemGroups",
				sort: "title",
				count: 0
			},
			{
				label: "Location Groups",
				key: "location_groups",
				value: "locationGroups",
				sort: "title",
				count: 0
			},
			{
				label: "Timing Groups",
				key: "timing_groups",
				value: "timingGroups",
				sort: "title",
				count: 0
			}
		],
		selectedTab: {
			label: "Categories",
			key: "categories",
			value: "categories",
			sort: "sort_order",
			count: 0
		}
	},
	error: {}
};
export const catalogueBackupDetailsReducer = (state = CATALOGUE_BACKUP_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_CATALOGUE_BACKUP_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_CATALOGUE_BACKUP_DETAIL_SUCCESS:
			let payload = {
				...state.data,
				...action.payload
			};
			try {
				payload.backupJson = JSON.parse(payload.backupJson);
			} catch {
				payload.backupJson = {};
			}
			payload.formTabs = [
				{
					label: "Categories",
					key: "categories",
					value: "categories",
					sort: "sort_order",
					count: action.payload?.categoriesCount || 0
				},
				{
					label: "Items",
					key: "items",
					value: "items",
					sort: "sort_order",
					count: action.payload?.itemsCount || 0
				},
				{
					label: "Modifier Groups",
					key: "option_groups",
					value: "optionGroups",
					sort: "sort_order",
					count: action.payload?.optionGroupsCount || 0
				},
				{
					label: "Modifiers",
					key: "options",
					value: "options",
					sort: "option_order",
					count: action.payload?.optionsCount || 0
				},
				{
					label: "Taxes",
					key: "taxes",
					value: "taxes",
					sort: "title",
					count: action.payload?.taxesCount || 0
				},
				{
					label: "Charges",
					key: "charges",
					value: "charges",
					sort: "title",
					count: action.payload?.chargesCount || 0
				},
				{
					label: "Item Groups",
					key: "item_groups",
					value: "itemGroups",
					sort: "title",
					count: action.payload?.itemGroupsCount || 0
				},
				{
					label: "Location Groups",
					key: "location_groups",
					value: "locationGroups",
					sort: "title",
					count: action.payload?.locationGroupsCount || 0
				},
				{
					label: "Timing Groups",
					key: "timing_groups",
					value: "timingGroups",
					sort: "title",
					count: action.payload?.timingGroupsCount || 0
				}
			];
			payload.selectedTab = {
				label: "Categories",
				key: "categories",
				value: "categories",
				sort: "sort_order",
				count: action.payload?.categoriesCount || 0
			};
			return {
				...state,
				loading: false,
				data: { ...payload }
			};
		case ActionTypes.GET_CATALOGUE_BACKUP_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_CATALOGUE_BACKUP_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.EDIT_CATALOGUE_BACKUP_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_CATALOGUE_BACKUP_DETAIL_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_CATALOGUE_BACKUP_DETAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

export const CATALOGUE_BACKUPS_STATUS_INITIAL_STATE = {
	status: null,
	catalogue_backup_id: null,
	name: "",
	message: "",
	restrict: false

	// for dev testing
	// status: 'RESTORING',
	// catalogue_backup_id: 320,
	// name: 'Backup',
	// message: '',
	// restrict: true,
};
export const catalogueBackupsStatus = (state = CATALOGUE_BACKUPS_STATUS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_CATALOGUE_BACKUPS_STATUS:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
