// clients
import { store } from "../store/configureStore";
import { clientOnboarding } from "../client-onboarding";

// third party
import { debounce } from "lodash";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// graphql
import { GET_USER_FLOW_STEPS, SUBSCRIBE_TO_USER_STEPS, GET_USER_STEPS_STATUS } from "../graphql/onboarding";

// services
import NotificationServices from "../services/NotificationService";

// constants
const FLOW_STATES = {
	IN_PROGRESS: "inprogress",
	COMPLETED: "completed",
	ACTIVE: "active"
};

export const fetchOnboardingFlows = async () => {
	try {
		store.dispatch(toggleGlobalLoader(true));
		store.dispatch({
			type: ActionTypes.GET_USER_FLOW_STEPS_REQUEST
		});
		const response = await clientOnboarding.query({
			query: GET_USER_FLOW_STEPS,
			variables: { status: FLOW_STATES.IN_PROGRESS }
		});
		store.dispatch({
			type: ActionTypes.GET_USER_FLOW_STEPS_SUCCESS,
			payload: response.data.identifier_flows
		});
	} catch (err) {
		console.error(err);
		store.dispatch({
			type: ActionTypes.GET_USER_FLOW_STEPS_FAILURE
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchUserStepStatus = async () => {
	try {
		store.dispatch(toggleGlobalLoader(true));
		const response = await clientOnboarding.query({
			query: GET_USER_STEPS_STATUS
		});
		store.dispatch({
			type: ActionTypes.GET_USER_STEPS_SUCCESS,
			payload: response.data.user_steps
		});
	} catch (err) {
		console.error(err);
		store.dispatch({
			type: ActionTypes.GET_USER_STEPS_FAILURE
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const subscribeToFlowSteps = async (flowName) => {
	try {
		store.dispatch(toggleGlobalLoader(true));
		store.dispatch({
			type: ActionTypes.GET_USER_STEPS_REQUEST
		});
		await clientOnboarding
			.subscribe({
				query: SUBSCRIBE_TO_USER_STEPS,
				variables: { flowName }
			})
			.subscribe({
				next(response) {
					// Notify your application with the new arrived data
					store.dispatch({
						type: ActionTypes.GET_USER_STEPS_SUCCESS,
						payload: response.data.identifier_steps
					});
				}
			});
	} catch (err) {
		console.error(err);
		store.dispatch({
			type: ActionTypes.GET_USER_STEPS_FAILURE
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const unsubscribeToFlowSteps = async () => {
	try {
		await clientOnboarding.unsubscribe();
	} catch (err) {
		console.error(err);
	}
};
