import React, { useState, useEffect, useCallback, useImperativeHandle, useRef } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import Users from "../components/APIRoleEdit/Users";

// utils
import history from "../history";
import { store } from "../store/configureStore";
import { adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchAPIRolesList } from "../actions/apiRoles";
import { ActionTypes } from "../actions/_types";

const FORM_TABS = [
	{
		label: "Users",
		value: "users"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const APIRoleEdit = ({ match, apiRoleDetails, isNested = false, closeNestedContainer, connectedRef }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const { loading, data, error } = apiRoleDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			store.dispatch({
				type: ActionTypes.RESET_API_ROLE_DETAIL
			});
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchAPIRolesList();
					history.push("/api-access");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	return (
		<div className="api-role-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title={data.name || "API Role"}
				subTitle="View associated users for this API role"
				isNested={isNested}
				hideActions={formTab === FORM_TABS[0].value || formTab === FORM_TABS[1].value}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<Users
							roleId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							handleNestedEntity={handleNestedEntity}
						/>
					)}
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	apiRoleDetails: store.apiRoleDetails
});
export default connect(mapStateToProps)(APIRoleEdit);
