import React from "react";

// third party
import { useTrail, config, animated } from "react-spring";

const Permissions = ({ data, loading }) => {
	return (
		<div className="role-permissions-tab">
			<div className="header-container">
				<div>
					<div className="header-text">Permissions</div>
					<div className="header-subtext">
						{(data.permissions && data.permissions.length) || 0} permission(s) depending upon this role
					</div>
				</div>
			</div>
			<Table data={data.permissions || []} loading={loading} />
		</div>
	);
};
export default Permissions;

const Table = ({ data, loading }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container permissions-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={i}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							permission={data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Permissons found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = () => (
	<div className={`at-table-row transaction-header-row`}>
		<div className="at-table-cell at-table-header at-header-text permission">Permission</div>
		<div className="at-table-cell at-table-header at-header-text description">Description</div>
	</div>
);

const TableList = ({ style, permission }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text permission" title={permission}>
				{permission["title"]}
			</div>
			<div className="at-table-cell at-cell-text description">{permission["description"]}</div>
		</animated.div>
	);
};
