import React from "react";

// third party
import { Route, Redirect } from "react-router-dom";

// components
import CatalogueBackupsList from "./CatalogueBackupsList";
import CatalogueBackupCreate from "./CatalogueBackupCreate";
import CatalogueBackupPreview from "./CatalogueBackupPreview";

const CatalogueBackupsContainer = ({ hasAccess = false, restrictView = false }) => {
	return (
		<div className="catalogue-backups-container">
			<Route path="/backups" component={CatalogueBackupsList} />
			<Route
				exact
				path="/backups/new"
				render={() =>
					hasAccess && !restrictView ? (
						<CatalogueBackupCreate hasAccess={hasAccess} />
					) : (
						<Redirect to="/backups" />
					)
				}
			/>
			<Route exact path="/backups/preview/:id" component={CatalogueBackupPreview} />
		</div>
	);
};
export default CatalogueBackupsContainer;
