import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import {
	scroll,
	msaagesArrayToHtml,
	printCurrency,
	trackEvent,
	getTrackingSource,
	fixedToTwoDecimal
} from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import { GET_ENTITY_ITEMS, UPDATE_ENTITY_ITEMS } from "../../graphql/itemEntityAssociations";
import { UPDATE_MENU } from "../../graphql/menus";

// client
import { clientMenu } from "../../client-menu";

// actions
import { ActionTypes } from "../../actions/_types";

// services
import NotificationServices from "../../services/NotificationService";

// constants
import { connect } from "react-redux";
import {
	FOOD_TYPE_MAP,
	TRACKING_ENTITY,
	TRACKING_EVENT_NAMES,
	TRACKING_SOURCE,
	TRACKING_STATUS,
	TRACKING_TYPE
} from "../../client-config";
const FILTER_INITIAL_STATE = {
	item_title: ""
};
const ITEMS_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};
const SORT_INIT_STATE = {
	field: "title",
	order: "ASC"
};

const ItemEntityAssociations = ({
	entityType,
	entityId,
	currencySymbol,
	isOpen,
	close,
	selectedBrand,
	isViewedFromMenuSection,
	menuDetailsData
}) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [itemsData, setItemsData] = useState(ITEMS_INITIAL_DATA);
	const [categoryfilters, setCategoryfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [itemsUpdates, setItemsUpdates] = useState({});
	const [selectAllItems, setSelectAllItems] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const [sortInfo, setSortInfo] = useState(SORT_INIT_STATE);
	const [eventTracking, setEventTracking] = useState({
		type: `${entityType.label} first`,
		filters: {}
	});
	const tableRef = useRef();
	const totalChanges = Object.keys(itemsUpdates).length;
	const totalPages = Math.ceil(itemsData.count / limit);

	const fetchData = useCallback(
		async (resetSelection = false) => {
			try {
				setLoading(true);
				const variables = {
					entityId,
					entityType: entityType.value,
					limit,
					offset,
					sort: sortInfo,
					brandId: !selectedBrand ? null : selectedBrand?.id === "all" ? null : parseInt(selectedBrand.id)
				};
				// sidebar filters
				let filtersObject = [];
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				});
				if (filtersObject.length > 0) {
					variables.filters = filtersObject;
				}
				const resp = await client.query({
					query: GET_ENTITY_ITEMS,
					variables,
					fetchPolicy: "no-cache"
				});
				setItemsData(resp.data.entityItemAssociations);
				setCategoryfilters(
					resp.data.entityItemAssociations.filters.find((fl) => fl.field === "item_discrete_category_id")
				);
				setTagfilters(resp.data.entityItemAssociations.filters.find((fl) => fl.field === "tags"));
				if (resetSelection) {
					setAllSelected(false);
					setSelectAllItems(false);
					setShowAllFieldSelector(false);
					setIsCheckedAll(false);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[entityId, entityType, limit, offset, appliedFilters, sortInfo, selectedBrand]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const applyFilter = useCallback(
		debounce((filters, field) => {
			setAppliedFilters(filters);
			// track filters which are being used
			setEventTracking({
				...eventTracking,
				filters: {
					...eventTracking["filters"],
					[field]: true
				}
			});
			setOffset(0);
		}, 500),
		[eventTracking]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters, field);
		},
		[currFilters, applyFilter]
	);

	const syncData = useCallback(() => {
		setItemsData({
			...itemsData,
			objects: itemsData.objects.map((loc) => {
				if (itemsUpdates[loc.id] !== undefined) {
					return {
						...loc,
						isAssociated: itemsUpdates[loc.id]
					};
				}
				return loc;
			})
		});
		setItemsUpdates({});
	}, [itemsData, itemsUpdates]);

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setItemsData(ITEMS_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setItemsUpdates({});
			setIsCheckedAll(false);
			setAllSelected(false);
			setSelectAllItems(false);
			setShowAllFieldSelector(false);
			setSortInfo(SORT_INIT_STATE);
			setEventTracking({
				type: `${entityType.label} first`,
				filters: {}
			});
			close(refresh);
		},
		[close, entityType]
	);

	const handleUpdateItemOptionGroupAssociationInMenuService = async () => {
		const menuId = menuDetailsData?.menuId;
		const strigifiedOptionGroupId = String(entityId);
		const updatedItems = [];
		Object.keys(itemsUpdates).forEach((itemId) => {
			if (itemsUpdates[itemId]) {
				updatedItems.push({
					id: String(itemId),
					nestedOptionGroups: [],
					overrides: {
						name: null,
						description: null,
						price: null,
						image: null,
						imageUrl: null,
						isRecommended: null
					}
				});
			}
		});
		try {
			// const menuUpdationVariables = {
			// 	id: menuId,
			// 	menuData: {
			// 		image: null,
			// 		name: menuDetailsData.name,
			// 		description: menuDetailsData.description,
			// 		brand: menuDetailsData.brand,
			// 		options: [ ...menuDetailsData.options.map(option => ({
			// 			...option,
			// 			overrides: {
			// 				...option.overrides,
			// 				image: null,
			// 			}
			// 		})), ...updatedModifiers],
			// 		optionGroups: menuDetailsData.optionGroups.map(optionGroup => optionGroup?.id === strigifiedOptionGroupId ? {
			// 			...optionGroup,
			// 			options: updatedModifiers.map(modifier => modifier.id)
			// 		} : optionGroup),
			// 		items: menuDetailsData.items.map(item => ({...item, overrides: {...item.overrides, image: ''}})),
			// 		categories: menuDetailsData.categories.map(category => ({
			// 			...category,
			// 			overrides: {
			// 				...category.overrides,
			// 				image: '',
			// 			}
			// 		}))
			// 	}
			// }

			const modifierOptionGroupAssocResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: {} //menuUpdationVariables,
			});

			if (modifierOptionGroupAssocResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Associated modifier group and modifiers successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				// fetchMenuDetails(menuId)
				// fetchParentSectionsList(menuId)
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: modifierOptionGroupAssocResp?.data?.updateMenuV2
				});
				handleClose(true);
				return true;
			} else {
				NotificationServices.pushNotification({
					message: "Failed to associate modifier groups to modifiers",
					timeout: 5000,
					type: "failure",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: "Failed to associate modifier groups to modifiers",
				timeout: 5000,
				type: "failure",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
		}
	};

	const handleUpdate = useCallback(
		async (associateAll = false, disassociateAll = false) => {
			const eventMeta = {};
			if (entityType.value === "ITEM_GROUP") {
				eventMeta.type = TRACKING_TYPE.EXISTING_PAIR_UPDATED;
				eventMeta.source = getTrackingSource();
			}

			if (entityType.value === "ITEM_TAGS") {
				eventMeta.entity = TRACKING_ENTITY.ITEM;
			}

			try {
				setConfirmLoading(true);
				const variables = {
					entityId,
					entityType: entityType.value,
					itemsToAssociate: [],
					itemsToDisassociate: [],
					associateAll: associateAll,
					disassociateAll: disassociateAll
				};
				if (!associateAll && !disassociateAll) {
					for (let id in itemsUpdates) {
						if (itemsUpdates[id]) {
							variables.itemsToAssociate.push(parseInt(id));
						} else {
							variables.itemsToDisassociate.push(parseInt(id));
						}
					}

					if (entityType.changeInAssociatedEntity) {
						eventMeta.change_in_associated_entity =
							variables.itemsToAssociate.length - variables.itemsToDisassociate.length;
					}
				} else {
					if (entityType.changeInAssociatedEntity) {
						eventMeta.change_in_associated_entity = associateAll ? itemsData.count : -itemsData.count;
					}
				}
				// sidebar filters
				let filtersObject = [];
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				});
				variables.filters = filtersObject;

				if (isViewedFromMenuSection) {
					handleUpdateItemOptionGroupAssociationInMenuService();
					return;
				}
				const resp = await client.mutate({
					mutation: UPDATE_ENTITY_ITEMS,
					variables,
					fetchPolicy: "no-cache"
				});

				if (resp.data.updateEntityItems.status.success) {
					if (entityType.event) {
						trackEvent(entityType.event, {
							...eventMeta,
							status: TRACKING_STATUS.SUCCESS
						});
					}

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Item association updated!",
							timeout: 2000,
							error: false
						}
					});
					syncData();
					handleClose(true);
				} else {
					if (entityType.event) {
						trackEvent(entityType.event, {
							...eventMeta,
							status: TRACKING_STATUS.FAILURE
						});
					}

					// handle error message
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: msaagesArrayToHtml(resp.data.updateEntityItems.status.messages),
							timeout: 5000,
							error: true
						}
					});
				}
			} catch (error) {
				if (entityType.event) {
					trackEvent(entityType.event, {
						...eventMeta,
						status: TRACKING_STATUS.FAILURE
					});
				}

				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setConfirmLoading(false);
		},
		[entityId, entityType, itemsUpdates, fetchData, syncData, handleClose, eventTracking]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setItemsUpdates({
			...itemsUpdates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		itemsData.objects.forEach((loc) => {
			updates[loc.id] = toCheckAll;
		});
		setItemsUpdates({
			...itemsUpdates,
			...updates
		});
		if (toCheckAll && Math.ceil(itemsData.count / limit) > 1) {
			setShowAllFieldSelector(true);
		}
		if (!toCheckAll) {
			setShowAllFieldSelector(false);
		}
	};

	const handleCompleteSelection = (selectAllItems = false) => {
		setAllSelected(selectAllItems);
		handleCheckAll(selectAllItems);
		setSelectAllItems(selectAllItems);
		if (!selectAllItems) {
			setShowAllFieldSelector(false);
			setItemsUpdates({});
		}
	};

	const handleSort = (e) => {
		e.stopPropagation();
		setOffset(0);
		setItemsUpdates({});
		if (sortInfo?.field === "title") {
			setSortInfo({ field: "associated", order: "ASC" });
		} else if (sortInfo?.field === "associated" && sortInfo?.order === "ASC") {
			setSortInfo({ field: "associated", order: "DESC" });
		} else {
			setSortInfo({ field: "associated", order: "ASC" });
		}
	};

	return (
		<div className="item-entity-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Associate Items"
				subTitle={`Manage items where this ${entityType.label} is present`}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				isNested={true}
				headerRight={
					selectAllItems ? (
						<React.Fragment>
							<Button
								clickHandler={() => handleUpdate(true, false)}
								classes={totalChanges === 0 ? "disabled" : ""}
							>
								{totalChanges === 0
									? "Associate"
									: `Associate ${allSelected ? itemsData.count : totalChanges} Items`}
							</Button>
							<Button
								clickHandler={() => handleUpdate(false, true)}
								classes={`margin-right at-btn--danger ` + (totalChanges === 0 ? "disabled" : "")}
							>
								{totalChanges === 0
									? "Dissociate"
									: `Dissociate ${allSelected ? itemsData.count : totalChanges} Items`}
							</Button>
						</React.Fragment>
					) : (
						<Button
							clickHandler={() => handleUpdate(false, false)}
							classes={totalChanges === 0 ? "disabled" : ""}
						>
							{totalChanges === 0
								? "Update"
								: `Update ${allSelected ? itemsData.count : totalChanges} Item(s)`}
						</Button>
					)
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className="search-filter-container">
						<InputWithLabel
							value={currFilters.name}
							onChange={(e) => setFilter("item_title", e.target.value)}
							placeholder="Name"
						></InputWithLabel>
						<SelectFilter
							placeholder="Category"
							options={categoryfilters.values || []}
							field={categoryfilters.field || ""}
							currValue={currFilters.item_discrete_category_id || ""}
							setFilter={setFilter}
							labelKey="valueForDisplay"
							valueKey="value"
						/>
						<SelectFilter
							placeholder="Tags"
							options={tagfilters.values || []}
							field={tagfilters.field || ""}
							currValue={currFilters.tags || ""}
							setFilter={setFilter}
							labelKey="valueForDisplay"
							valueKey="value"
						/>
					</div>
					<div className="search-filter-container">
						<InputWithLabel
							value={currFilters.crm_title}
							onChange={(e) => setFilter("crm_title", e.target.value)}
							placeholder="Enter CRM Title"
						></InputWithLabel>
					</div>
					{showAllFieldSelector && totalPages > 1 && (
						<SelectAllFields
							allSelected={allSelected}
							handleCompleteSelection={handleCompleteSelection}
							mainMessage={
								<span>
									{allSelected ? "All " : ""}
									<strong>{allSelected ? itemsData.count : itemsData?.objects?.length} </strong>{" "}
									item(s) {allSelected ? "on all pages are selected." : "on this page are selected."}
								</span>
							}
							linkMessage={
								allSelected ? (
									" Clear selection"
								) : (
									<span>
										{" "}
										Select <strong>{itemsData.count}</strong> items from all pages
									</span>
								)
							}
						/>
					)}
					<Table
						data={itemsData.objects}
						loading={loading}
						currencySymbol={currencySymbol}
						handleCheck={handleCheck}
						itemsUpdates={itemsUpdates}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						allSelected={allSelected}
						handleSort={handleSort}
						sortInfo={sortInfo}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={itemsData.count}
						goToPage={handlePagination}
						readOnly={allSelected}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	menuDetailsData: store.menuDetailsState.menuDetailsData
}))(ItemEntityAssociations);

export const Table = ({
	data,
	loading,
	currencySymbol,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	itemsUpdates,
	allSelected,
	handleSort,
	sortInfo
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && (loading || allSelected) ? "disabled" : "") +
				" transaction-table-holder common-table-container items-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						handleSort={handleSort}
						sortInfo={sortInfo}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleCheck={handleCheck}
							itemsUpdates={itemsUpdates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							currencySymbol={currencySymbol}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Items found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, handleSort, sortInfo }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text title`}>
			<div className={"checkbox-sort" + (sortInfo?.field === "associated" ? " selected" : "")}>
				{/* <img onClick={handleSort} src="/assets/icons/icon-sort.svg" className="sort-button" alt=""/> */}
				<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Name">
					<span>Name</span>
				</CheckBox>
			</div>
		</div>
		<div className={`at-table-cell at-table-header at-header-text category`}>Category</div>
		<div className={`at-table-cell at-table-header at-header-text price`}>Price</div>
	</div>
);

export const TableList = ({
	id,
	itemTitle,
	isAssociated,
	itemPrice,
	crmTitle,
	foodType,
	category,
	tags,
	currencySymbol,
	style,
	handleCheck,
	itemsUpdates
}) => {
	const status = itemsUpdates[id] === undefined ? isAssociated : itemsUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text title">
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} title={itemTitle || "--"}>
					<span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />
					<span>{itemTitle || id}</span>
				</CheckBox>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
				<div className="text--light item_tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 2 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 2 && (
									<span className="tag-item more-tags" title={tags.slice(2).join(", ")}>
										+{tags.length - 2} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text category">{category || "--"}</div>
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{fixedToTwoDecimal(itemPrice) || 0}
			</div>
		</animated.div>
	);
};
