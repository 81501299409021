import React from "react";

// components
import { InfiniteLoader } from "../SiteComp";
import { Button } from "../_commons/Button";
import { Panels } from "../_commons/Panels";
import { PageSelector } from "../MerakiWeb/PageSelector";

// third party
import { connect } from "react-redux";

// constants
import { PREVIEW_MODES } from "../../containers/MerakiWebEditor";
import PublishWrapper from "../MerakiWeb/PublishWrapper";
import { getPreviewUrl } from "../../services/common";

const Header = ({
	globalLoaderState,
	workspace = {},
	previewMode,
	setPreviewMode,
	pages,
	selectedPage,
	handleSelectPage,
	handleCreatePage,
	handleSave,
	handleContentMode,
	contentMode,
	contentModes,
	isEditorTouched = false,
	themeName,
	history,
	biz = {}
}) => {
	const previewURL =
		themeName === workspace?.theme?.name && workspace.netlify
			? `https://${workspace.netlify.cname}`
			: getPreviewUrl(biz?.id, themeName);

	return (
		<div className="site-header">
			<div className="header-section-container">
				<div className="header-section header-section--left">
					<PageSelector
						pages={pages?.filter?.(
							(page) =>
								(page?.type && page?.type === "custom") ||
								page?.components?.length ||
								Object.keys(page?.customizations).length
						)}
						selectedPage={selectedPage}
						handleSelectPage={handleSelectPage}
						handleCreatePage={handleCreatePage}
					/>
					{selectedPage && selectedPage.type === "custom" && (
						<div className="editor-view-panel">
							<Panels list={contentModes} selected={contentMode} clickHandler={handleContentMode} />
						</div>
					)}
				</div>
				<div className="header-section header-section--right">
					<div className="preview-mode-container">
						<img
							src={`/assets/meraki/header/preview-desktop${
								previewMode === PREVIEW_MODES.desktop ? "-selected" : ""
							}.svg`}
							className={
								"preview-mode-icon " + (previewMode === PREVIEW_MODES.desktop ? "mode-selected " : "")
							}
							onClick={() => setPreviewMode(PREVIEW_MODES.desktop)}
						/>
						<img
							src={`/assets/meraki/header/preview-tablet${
								previewMode === PREVIEW_MODES.tablet ? "-selected" : ""
							}.svg`}
							className={
								"preview-mode-icon " + (previewMode === PREVIEW_MODES.tablet ? "mode-selected " : "")
							}
							onClick={() => setPreviewMode(PREVIEW_MODES.tablet)}
						/>
						<img
							src={`/assets/meraki/header/preview-phone${
								previewMode === PREVIEW_MODES.phone ? "-selected" : ""
							}.svg`}
							className={
								"preview-mode-icon " + (previewMode === PREVIEW_MODES.phone ? "mode-selected " : "")
							}
							onClick={() => setPreviewMode(PREVIEW_MODES.phone)}
						/>
					</div>
					<div className="store-url">
						<a href={previewURL} target="_blank">
							<img src="/assets/icons/icon-view-eye.svg" />
							<span>View your store</span>
						</a>
					</div>

					<PublishWrapper onPublishComplete={() => history.push("/meraki-web")}>
						{({ handlePublishOpen, publishEnabled }) => {
							const themeLibraryData = workspace.themeLibrary?.find?.((th) => th.name === themeName);

							const disabled = !isEditorTouched && !publishEnabled;

							return (
								<Button
									clickHandler={() =>
										disabled ? null : isEditorTouched ? handleSave() : handlePublishOpen(themeName)
									}
									classes={disabled ? "base-button-disabled" : ""}
									persistChildrenWhileLoading
									showCustomTooltip={disabled}
									tooltipInfo={
										!themeLibraryData?.unpublishedChanges
											? "No changes to publish"
											: "Publish in progress"
									}
									loading={globalLoaderState.active}
								>
									{isEditorTouched ? "Save" : "Publish"}
								</Button>
							);
						}}
					</PublishWrapper>
				</div>
				<div className="Cf" />
			</div>
			<div className={(globalLoaderState.active ? "active " : "") + "global-loader-container"}>
				<InfiniteLoader />
			</div>
		</div>
	);
};
export default connect((store) => ({
	globalLoaderState: store.globalLoaderState
}))(Header);
