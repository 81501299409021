import React, { useCallback, useEffect, useRef, useState } from "react";

// components
import { CustomTable } from "../components/_commons/CustomTable";
import { Paginator } from "../components/_commons/Paginator";
import { CheckBox } from "../components/_commons/CheckBox";
import { Modal } from "../components/_commons/Modal";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SearchFilter } from "../components/_commons/SearchFilter";
import { Filters } from "../components/_commons/Filters";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import ContextMenu from "../components/_commons/ContextMenu";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import { Transition, animated, config } from "react-spring/renderprops";

// utils
import { store } from "../store/configureStore";
import { scroll, adjustNestedContainer } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchPendingInvitesList, userInviteActions } from "../actions/unifiedInvites";

// constants
import { NESTED_ENTITY_TYPES } from "../client-config";
export const MODAL_CONTENT = {
	single_resend: ["Please confirm that you would like to resend the invite to this person."],
	bulk_resend: ["Please confirm that you would like to resend the invites to these people."],
	single_delete: [
		"The registration link sent will become invalid.",
		"You will have to create a new invite if you want them to be a part of your business in the future."
	],
	bulk_delete: [
		"The registration links sent will become invalid.",
		"You will have to create new invites if you want them to be a part of your business in the future."
	]
};
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Email/ Mobile Number",
		field: "email",
		render: (record, i, rest) => {
			const status = rest.invites[record.id] === undefined ? rest.toCheck : rest.invites[record.id];
			return (
				<div className={"table-cell email"} key={i}>
					{rest.checkbox && (
						<CheckBox
							checked={status}
							clickHandler={(e) => rest.handleCheck(e, record.id, !status)}
							title={record.email || record.id}
							readOnly={rest.disableCheckbox}
						/>
					)}
					<Link
						className={"hyperlink hyperlink--black-color " + rest.archived}
						to={`/unified-access/preview/${record.uuid}`}
					>
						{record.email || `${record.isdCode} ${record.phone}` || record.id || "--"}
					</Link>
				</div>
			);
		}
	},
	{
		name: "Created At",
		field: "created-at",
		render: (record, i) => (
			<div className={"table-cell created-at"} key={i}>
				{record.createdAt ? moment(record.createdAt).format("DD MMM, YYYY - hh:mm A") : "--"}
			</div>
		)
	},
	{
		name: "Atlas",
		field: "atlas",
		render: (record, i) => {
			const atlasApps = record?.invitedApps?.filter((app) => app.appName === "atlas") || [];
			return (
				<div className={"table-cell atlas"} key={i}>
					{atlasApps?.length > 0
						? atlasApps?.map((app, j) => (
								<div key={j} className="app">
									<div className="roles-list">
										{app?.roles?.map((role, k) => (
											<span key={k} className="role">
												{role.name}
												{k !== app?.roles?.length - 1 ? ", " : ""}
											</span>
										))}
										{app?.roles?.length === 0 && <span>--</span>}
									</div>
								</div>
						  ))
						: "--"}
				</div>
			);
		}
	},
	{
		name: "Prime",
		field: "prime",
		render: (record, i) => {
			const primeApps = record?.invitedApps?.filter((app) => app.appName === "prime") || [];
			return (
				<div className={"table-cell prime"} key={i}>
					{primeApps?.length > 0
						? primeApps?.map((app, j) => (
								<div key={j} className="app">
									<div className="app-biz-name">{app?.appBizName}</div>
									<div className="roles-list">
										{app?.roles?.map((role, k) => (
											<span key={k} className="role">
												{role?.name}
												{k !== app?.roles?.length - 1 ? ", " : ""}
											</span>
										))}
										{app?.roles?.length === 0 && <span>--</span>}
									</div>
								</div>
						  ))
						: "--"}
				</div>
			);
		}
	},
	{
		name: "Status",
		field: "status",
		render: (record, i, rest) => (
			<div className={"table-cell status"} key={i}>
				<div className="badge">{record.status === "CREATED" ? "PENDING" : record.status}</div>
				{record.status !== "CANCELLED" && (
					<ContextMenu
						isOpen={
							rest.contextMenuId === record.id || (!record.id && rest.contextMenuId === rest.rowIndex)
						}
						data={record}
						disableContextMenu={rest.disableContextMenu}
						renderMenuItems={rest.renderMenuItems}
						handleOpenMenu={() => rest.openContextMenu(record, rest)}
						handleOutsideClick={
							rest.contextMenuId === record.id || (!record.id && rest.contextMenuId === rest.rowIndex)
								? () => rest.closeContextMenu(record, rest)
								: () => {}
						}
						clickEvent="mousedown"
						rest={rest}
					/>
				)}
			</div>
		)
	}
];

const UnifiedInvitesList = ({ unifiedInvitesList, unifiedInvitesListState, configItems, access, biz, user }) => {
	const { data, loading } = unifiedInvitesList;
	const { limit, offset, currentFilters, appliedFilters, currentDateFilter, appliedDateFilter, sort, sortedField } =
		unifiedInvitesListState;
	const [showFilters, setShowFilters] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const [isOpen, setIsOpen] = useState({
		created_at: false,
		accepted_at: false
	});
	const [toCheck, setToCheck] = useState(false);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [invites, setInvites] = useState({});
	const [invitesData, setInvitesData] = useState([]);
	const [contextMenu, setContextMenu] = useState({ id: null });
	const [contextMenuData, setContextMenuData] = useState({});
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isModalOpen, setModalOpen] = useState(false);
	const [inviteActionLoading, setInviteActionLoading] = useState(false);
	const [modalContent, setModalContent] = useState({
		type: "",
		title: "",
		content: [],
		btnTitle: ""
	});
	const [inviteActionSuccess, setInviteActionSuccess] = useState({
		status: false,
		message: ""
	});
	const nestedRef = useRef();
	const topRef = useRef();

	useEffect(() => {
		fetchPendingInvitesList();
	}, [limit, offset, appliedFilters, appliedDateFilter, sort]);

	useEffect(() => {
		let filterCount = 0;
		for (let f in appliedFilters) {
			if (typeof appliedFilters[f] !== "object" && appliedFilters[f]) {
				filterCount++;
			} else if (f !== "roles" && appliedFilters[f]?.value && appliedFilters[f]?.value !== "") {
				filterCount++;
			}
		}
		for (let f in currentDateFilter) {
			if (currentDateFilter[f].dateFilter) {
				filterCount++;
			}
		}
		setFilterCount(filterCount);
	}, [appliedFilters, currentDateFilter]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const updatePendingInvitesListState = (payload) => {
		store.dispatch({
			type: ActionTypes.UNIFIED_INVITES_LIST_STATE_CHANGE,
			payload
		});
	};

	const updateDateFilter = useCallback(
		(payload, field) => {
			if (isOpen[field]) {
				store.dispatch({
					type: ActionTypes.UNIFIED_INVITES_LIST_STATE_CHANGE,
					payload: {
						currentDateFilter: {
							...currentDateFilter,
							[field]: payload.currentDateFilter
						}
					}
				});
			}
		},
		[isOpen, currentDateFilter]
	);

	const applySearchFilter = useCallback(
		debounce(() => fetchPendingInvitesList(), 500),
		[]
	);

	const handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.UNIFIED_INVITES_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	const setSearchFilter = useCallback(
		(field, value) => {
			store.dispatch({
				type: ActionTypes.UNIFIED_INVITES_LIST_SEARCH,
				payload: { [field]: value }
			});
			updatePendingInvitesListState({
				offset: 0
			});
			applySearchFilter();
		},
		[applySearchFilter]
	);

	const filterSidebarCloseHandler = useCallback(() => {
		setShowFilters(false);
		updatePendingInvitesListState({
			currentFilters: appliedFilters,
			currentDateFilter: appliedDateFilter
		});
	}, [appliedFilters, appliedDateFilter]);

	const setFilter = useCallback(
		(field, value) => {
			let newCurrentFilters = {
				...currentFilters
			};
			newCurrentFilters[field] = value;
			updatePendingInvitesListState({
				currentFilters: newCurrentFilters
			});
		},
		[currentFilters]
	);

	const applyFilters = useCallback(() => {
		setShowFilters(false);
		updatePendingInvitesListState({
			appliedFilters: {
				...currentFilters
			},
			appliedDateFilter: {
				...currentDateFilter
			},
			offset: 0
		});
	}, [currentFilters, currentDateFilter]);

	const clearFilters = () => {
		setShowFilters(false);
		store.dispatch({
			type: ActionTypes.UNIFIED_INVITES_LIST_STATE_RESET
		});
	};

	const handleDropdown = useCallback(
		(e, field) => {
			e.stopPropagation();
			if (!isOpen[field]) {
				let updatedIsOpen = {
					...isOpen
				};
				Object.keys(updatedIsOpen).forEach((df) => {
					updatedIsOpen[df] = false;
				});
				updatedIsOpen[field] = true;
				setIsOpen(updatedIsOpen);
			} else {
				closeDropdown(field);
			}
		},
		[isOpen]
	);

	const closeDropdown = useCallback(
		(field) => {
			if (isOpen[field]) {
				updatePendingInvitesListState({
					currentDateFilter: {
						...currentDateFilter,
						[field]: appliedDateFilter[field]
					}
				});
				setIsOpen({
					...isOpen,
					[field]: false
				});
			}
		},
		[isOpen, currentDateFilter, appliedDateFilter]
	);

	const applyDateRange = useCallback(
		(field) => {
			setIsOpen({
				...isOpen,
				[field]: false
			});
		},
		[isOpen]
	);

	const handlePagination = useCallback(
		(page) => {
			// set new offset
			const offset = (page - 1) * limit;
			updatePendingInvitesListState({
				offset
			});
			// scroll to the top
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		},
		[limit]
	);

	const handlePageSize = useCallback(
		(field, size) => {
			if (size.value !== limit) {
				updatePendingInvitesListState({
					[field]: size.value
				});
			}
			// scroll to the top
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		},
		[limit]
	);

	const sortList = (field) => {
		const sort = {
			field
		};
		store.dispatch({
			type: ActionTypes.UNIFIED_INVITES_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		updatePendingInvitesListState({
			offset: 0
		});
	};

	const handleFilters = (field, value) => {
		let newCurrentFilters = {
			...currentFilters
		};
		newCurrentFilters[field] = value;
		updatePendingInvitesListState({
			currentFilters: newCurrentFilters,
			appliedFilters: newCurrentFilters,
			offset: 0
		});
	};

	const handleCheck = (e, id, toSelect) => {
		e.stopPropagation();
		let inviteUpdates = invitesData.filter((loc) => invites[loc.id]);
		if (toSelect) {
			inviteUpdates.push(data.objects.find((loc) => loc.id === id));
		} else {
			inviteUpdates = inviteUpdates.filter((loc) => loc.id !== id);
		}
		setInvites({
			...invites,
			[id]: toSelect
		});
		setInvitesData(inviteUpdates);
		if (!toSelect) {
			setIsCheckedAll(false);
		}
	};

	const handleCheckAll = (e, toCheckAll) => {
		e.stopPropagation();
		if (toCheckAll) {
			let updates = {};
			data.objects.forEach((invite) => {
				updates[invite.id] = toCheckAll;
			});
			let inviteUpdates = data.objects.filter((invite) => !invites[invite.id]);
			setIsCheckedAll(toCheckAll);
			setInvites({
				...invites,
				...updates
			});
			setInvitesData([...invitesData.filter((invite) => invites[invite.id]), ...inviteUpdates]);
		} else {
			let updates = {};
			data.objects.forEach((invite) => {
				updates[invite.id] = toCheckAll;
			});
			let inviteUpdates = invitesData.filter((invite) => !(invite.id in updates));
			setIsCheckedAll(toCheckAll);
			setInvites({
				...invites,
				...updates
			});
			setInvitesData([...inviteUpdates]);
		}
	};

	const handleInviteActions = async (action = "resend") => {
		const { uuid } = invitesData[0];
		const variables = {
			action,
			uuid,
			clientToken: user.token,
			bizId: biz.id
		};
		setInviteActionLoading(true);
		const resp = await userInviteActions(variables);
		if (resp) {
			setInviteActionSuccess({
				status: true,
				message:
					action === "resend"
						? "Invite has been re-sent successfully"
						: "Invite has been deleted successfully"
			});
			setInviteActionLoading(false);
			await fetchPendingInvitesList();
			// scroll to the top
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		} else {
			setInviteActionLoading(false);
		}
	};

	const handleModal = (type = "resendInvite") => {
		if (!isModalOpen) {
			setModalOpen(true);
			setModalContent({
				type,
				title:
					type === "resendInvite"
						? invitesData.length > 1
							? `Resend ${invitesData.length} Invites?`
							: "Resend Invite?"
						: invitesData.length > 1
						? `Delete ${invitesData.length} Invites?`
						: "Delete Invite?",
				content:
					type === "resendInvite"
						? invitesData.length > 1
							? MODAL_CONTENT["bulk_resend"]
							: MODAL_CONTENT["single_resend"]
						: invitesData.length > 1
						? MODAL_CONTENT["bulk_delete"]
						: MODAL_CONTENT["single_delete"],
				btnTitle:
					type === "resendInvite"
						? invitesData.length > 1
							? `Resend Invites`
							: "Resend Invite"
						: invitesData.length > 1
						? `Delete Invites`
						: "Delete Invite"
			});
			setContextMenu({ id: null });
		} else {
			setModalOpen(false);
			setInviteActionLoading(false);
			setInviteActionSuccess({
				status: false,
				message: ""
			});
			setModalContent({
				type: "",
				title: "",
				content: [],
				btnTitle: ""
			});
		}
	};

	const clearSelection = () => {
		setIsCheckedAll(false);
		setInvites({});
		setInvitesData([]);
		setContextMenu({ id: null });
	};

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => handleModal("resendInvite")}>
					Resend Invite
				</div>
				<div className="action-item" onClick={() => handleModal("deleteInvite")} style={{ color: "#FF425C" }}>
					Delete Invite
				</div>
			</React.Fragment>
		);
	};

	const openContextMenu = useCallback(
		(record = {}) => {
			setContextMenu({
				id: contextMenu.id && contextMenu.id === record.id ? null : record.id
			});
			setContextMenuData({ ...record });
			setInvitesData([(data.objects ?? []).find((invite) => invite.id === record.id)]);
		},
		[contextMenu, data]
	);

	const closeContextMenu = () => {
		setContextMenu({
			id: null
		});
	};

	let switchActions = false;
	for (let id in invites) {
		if (invites[id] === true) {
			switchActions = true;
			break;
		}
	}

	return (
		<div className="unified-invites-container" ref={topRef}>
			{/* <div className="information">
				<img src="/assets/icons/icon-wand.svg" alt="" />
				<div>All users across businesses for <b>Atlas & Prime</b> can be managed via a single screen</div>
				<div className="know-more">Know More</div>
			</div> */}
			<div className="filters">
				<div className="filter-buttons">
					<SelectFilter
						options={[
							{ valueForDisplay: "All Roles", value: "" },
							...(data?.filters?.find((filter) => filter.field === "roles")?.values ?? [])
						]}
						field="roles"
						currValue={appliedFilters.roles || null}
						setFilter={handleFilters}
						labelKey="valueForDisplay"
						valueKey="value"
						isClearable={false}
						isSearchable={true}
						placeholder="Select Roles"
					/>
					{configItems.dimensions.width > 768 && (
						<div className={(showFilters ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={() => setShowFilters(!showFilters)}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
					)}
				</div>
				{data.searchKeywords && data.searchKeywords.length > 1
					? configItems.dimensions.width > 768 && (
							<div className="search-input-container">
								<SelectFilter
									options={data.searchKeywords}
									field="searchFieldSelected"
									currValue={data.searchFieldSelected}
									setFilter={handleSearchField}
									labelKey="valueForDisplay"
									valueKey="key"
									isSearchable={false}
									isClearable={false}
								/>
								<SearchFilter
									filterOption={{ field: "searchFieldValue" }}
									value={data.searchFieldValue}
									setFilter={setSearchFilter}
									placeholder="Search"
								/>
							</div>
					  )
					: configItems.dimensions.width > 768 && (
							<SearchFilter
								filterOption={{ field: "searchFieldValue" }}
								value={data.searchFieldValue}
								setFilter={setSearchFilter}
								placeholder="Search"
							/>
					  )}
			</div>
			<Filters
				isOpen={showFilters}
				close={filterSidebarCloseHandler}
				apply={applyFilters}
				clear={clearFilters}
				options={data.filters.filter((filter) => filter.field !== "roles") || []}
				currentFilters={currentFilters}
				setFilter={setFilter}
				isDropdownOpen={isOpen}
				handleDropdown={handleDropdown}
				closeDropdown={closeDropdown}
				applyDateRange={applyDateRange}
				currentDateFilter={currentDateFilter}
				updateListState={updateDateFilter}
				storeRootField="unifiedInvitesListState"
			/>
			<Transition
				native
				items={switchActions}
				from={{ opacity: 0, height: 0 }}
				enter={{ opacity: 1, height: "auto" }}
				leave={{ opacity: 0, height: 0 }}
				config={config.stiff}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div style={props}>
							<Actions
								handleModal={handleModal}
								clearSelection={clearSelection}
								count={invitesData.length || 0}
							/>
						</animated.div>
					))
				}
			</Transition>
			<CustomTable
				loading={loading}
				data={data.objects || []}
				columns={columns}
				// checkbox={access.isAdmin}
				toCheck={toCheck}
				isCheckedAll={isCheckedAll}
				handleCheck={handleCheck}
				handleCheckAll={handleCheckAll}
				invites={invites}
				disableContextMenu={switchActions}
				renderMenuItems={renderMenuItems}
				openContextMenu={openContextMenu}
				closeContextMenu={closeContextMenu}
				contextMenuId={contextMenu.id}
				classes="pending-invites-list-table-container"
				content="Invites"
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={data.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
			/>
			<Modal
				isOpen={isModalOpen}
				close={handleModal}
				title={modalContent.title}
				subTitle=""
				loading={inviteActionLoading}
				showSubmitAction={modalContent.type === "resendInvite" && !inviteActionSuccess.status}
				submitTitle={modalContent.btnTitle}
				submitAction={() => handleInviteActions("resend")}
				submitButtonWidth="150"
				showDeleteAction={modalContent.type === "deleteInvite" && !inviteActionSuccess.status}
				deleteTitle={modalContent.btnTitle}
				deleteAction={() => handleInviteActions("cancel")}
				deleteButtonWidth="150"
				showCancelAction={true}
				cancelTitle={inviteActionSuccess.status ? "Dismiss" : "Cancel"}
			>
				{inviteActionSuccess.status ? (
					<div className="success">{inviteActionSuccess.message}</div>
				) : (
					modalContent.content.map((content, i) => (
						<div className="description" key={i}>
							{content}
						</div>
					))
				)}
			</Modal>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	unifiedInvitesList: store.unifiedInvitesList,
	unifiedInvitesListState: store.unifiedInvitesListState,
	configItems: store.configItems,
	access: store.login.loginDetail.access,
	user: store.login.loginDetail,
	biz: store.login.loggedInbizDetail
}))(UnifiedInvitesList);

const Actions = ({ handleModal, clearSelection, count = 0 }) => {
	return (
		<div className="bulk-actions">
			<div className="count">
				{count} invite(s) selected
				<span onClick={clearSelection}>Clear selection</span>
			</div>
			<div className="actions-container">
				<div className="action" onClick={() => handleModal("resendInvite")}>
					Resend Invite{count > 1 ? "s" : ""}
				</div>
				<div className="action archive" onClick={() => handleModal("deleteInvite")}>
					Delete Invite{count > 1 ? "s" : ""}
				</div>
			</div>
		</div>
	);
};
