import React, { useState, useEffect, useCallback, useRef } from "react";

// third party
import { connect } from "react-redux";
import moment from "moment";

// clients
import { store } from "../store/configureStore";

// actions
import {
	fetchOperationalEfficiencyMetrics,
	fetchOperationalEfficiencyMetricsLocationLevel,
	handlePagination
} from "../actions/operationalEfficiency";
import { toggleGlobalLoader } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// components
import Overview from "../components/OperationalEfficiency/Overview";

// utils
import LocationView from "../components/OperationalEfficiency/LocationView";
import { Paginator } from "../components/_commons/Paginator";
import { scroll, trackEvent, triggerSurvey } from "../atlas-utils";
import { Filters } from "../components/_commons/Filters";

const Operations = ({
	operationalEfficiencyState,
	operationalEfficiencyMetricsBizLocationLevel,
	operationalEfficiencyMetricsBizLocationLevelListState,
	operationalEfficiencyFilters,
	configItems,
	selectedBrand
}) => {
	let [locationListCount, setLocationListCount] = useState(0);
	useEffect(() => {
		setLocationListCount(operationalEfficiencyMetricsBizLocationLevel.data.count || 0);
	}, [operationalEfficiencyMetricsBizLocationLevel]);
	let { limit, offset } = operationalEfficiencyMetricsBizLocationLevelListState;
	const { currentDateFilter, appliedDateFilter, appliedFilters } = operationalEfficiencyState;
	const isFirstRunOE = useRef(true);
	const [showFilters, setShowFilters] = useState(false);
	const [currentFilters, setCurrentFilters] = useState({});
	const [filterCount, setFilterCount] = useState(0);
	const [searchString, setSearchString] = useState("");
	const [initLoad, setInitLoad] = useState(true);
	let filtersOptions = operationalEfficiencyFilters.data;
	const tableRef = useRef();

	useEffect(() => {
		triggerSurvey("survey analytics module");
	}, []);

	useEffect(() => {
		if (isFirstRunOE.current) {
			isFirstRunOE.current = false;
			return;
		}
		fetchOperationalEfficiencyMetrics(
			{
				appliedDateFilter,
				appliedFilters
			},
			"network-only"
		);
	}, [appliedDateFilter]);

	const getData = useCallback(async () => {
		const { operationalEfficiencyState } = store.getState();
		const { appliedDateFilter, appliedFilters } = operationalEfficiencyState;
		store.dispatch(toggleGlobalLoader(true));
		let dataFetchPromises = [];
		const ftpc = fetchOperationalEfficiencyMetrics(
			{
				appliedDateFilter,
				appliedFilters
			},
			"no-cache",
			selectedBrand
		);
		dataFetchPromises.push(ftpc);

		// set tracking related info
		const eventName = "operations_default_view";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		try {
			await Promise.all(dataFetchPromises);
		} catch (error) {
			console.log(error);
		}
		store.dispatch(toggleGlobalLoader(false));

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		trackEvent(eventName, {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		});
	}, []);

	useEffect(() => {
		getData();
	}, []);

	const updateOperationalEfficiencyState = useCallback((payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_OE_STATE,
			payload
		});
	}, []);

	const paginationHandler = useCallback(
		async (page) => {
			offset = limit * (page - 1);
			store.dispatch({
				type: ActionTypes.OPERATIONAL_EFFICIENCY_METRICS_BIZ_LOCATION_LEVEL_LIST_STATE_CHANGE,
				payload: { offset }
			});
			const pglv = await fetchOperationalEfficiencyMetricsLocationLevel({
				appliedDateFilter,
				limit,
				offset,
				appliedFilters,
				sortKey: "percentage_cancelled",
				search: searchString
			});
			scroll({ top: tableRef?.current?.offsetTop - 57, left: 0 });
		},
		[limit, offset, fetchOperationalEfficiencyMetricsLocationLevel, appliedFilters, appliedDateFilter, searchString]
	);

	const handlePageSize = useCallback(
		async (field, size) => {
			// set new limit
			if (size.value !== limit) {
				store.dispatch({
					type: ActionTypes.OPERATIONAL_EFFICIENCY_METRICS_BIZ_LOCATION_LEVEL_LIST_STATE_CHANGE,
					payload: {
						[field]: size.value
					}
				});
				const pglv = await fetchOperationalEfficiencyMetricsLocationLevel({
					appliedDateFilter,
					limit: size.value,
					offset,
					appliedFilters,
					sortKey: "percentage_cancelled",
					search: searchString
				});
			}
			// scroll to top of the list
			scroll({ top: tableRef?.current?.offsetTop - 57, left: 0 });
		},
		[limit, offset, fetchOperationalEfficiencyMetricsLocationLevel, appliedFilters, appliedDateFilter, searchString]
	);

	const applyFilters = useCallback(
		async (currentFilters) => {
			setFilterCount(Object.entries(currentFilters).length || 0);
			setShowFilters(false);
			updateOperationalEfficiencyState({
				appliedFilters: {
					...currentFilters
				}
			});
			const filters_metrics_load = await fetchOperationalEfficiencyMetrics({
				appliedDateFilter,
				appliedFilters: currentFilters
			});
		},
		[fetchOperationalEfficiencyMetrics, appliedDateFilter, limit, offset]
	);

	const setFilter = useCallback(
		(field, value) => {
			const f = {
				...currentFilters,
				[field]: value
			};
			if (value.value.length === 0) {
				delete f[field];
			}
			setCurrentFilters(f);
		},
		[currentFilters]
	);

	const filterSidebarCloseHandler = useCallback(() => {
		setShowFilters(false);
	}, [showFilters]);

	const clearFilters = useCallback(() => {
		setShowFilters(false);
		setCurrentFilters({});
		applyFilters({});
	}, [applyFilters]);

	const flipShowFilters = useCallback(() => {
		setShowFilters(!showFilters);
	}, [showFilters]);

	const sortHandler = useCallback(
		async (key) => {
			store.dispatch({
				type: ActionTypes.OPERATIONAL_EFFICIENCY_TRANSACTION_LIST_STATE_CHANGE,
				payload: { offset: 0 }
			});
			const pglv = await fetchOperationalEfficiencyMetricsLocationLevel({
				appliedDateFilter,
				limit: 10,
				offset: 0,
				appliedFilters,
				sortKey: key,
				search: searchString
			});
		},
		[limit, offset, fetchOperationalEfficiencyMetricsLocationLevel, appliedFilters, appliedDateFilter, searchString]
	);

	const searchHandler = useCallback(
		async (searchInput) => {
			setSearchString(searchInput);
			store.dispatch({
				type: ActionTypes.OPERATIONAL_EFFICIENCY_TRANSACTION_LIST_STATE_CHANGE,
				payload: { offset: 0 }
			});
			const pglv = await fetchOperationalEfficiencyMetricsLocationLevel({
				appliedDateFilter,
				limit: 10,
				offset: 0,
				appliedFilters,
				search: searchInput
			});
		},
		[limit, offset, fetchOperationalEfficiencyMetricsLocationLevel, appliedFilters, appliedDateFilter]
	);

	const handleBrandChange = useCallback(() => {
		getData();
	}, [selectedBrand]);

	return (
		<div className="operational-efficiency-container">
			<div className="transaction-section section-container-common">
				<Filters
					isOpen={showFilters}
					close={filterSidebarCloseHandler}
					apply={() => applyFilters(currentFilters)}
					clear={clearFilters}
					options={filtersOptions}
					currentFilters={currentFilters}
					setFilter={setFilter}
				/>
				<Overview
					filterCount={filterCount}
					flipShowFilters={flipShowFilters}
					filterActive={showFilters}
					updateOperationsState={updateOperationalEfficiencyState}
					handleBrandChange={handleBrandChange}
				/>
			</div>
			<div className="transaction-section section-container-common" ref={tableRef}>
				<LocationView sortHandler={sortHandler} searchHandler={searchHandler} />
				<div>
					<Paginator
						limit={limit}
						offset={offset}
						count={locationListCount}
						goToPage={paginationHandler}
						setPageSize={handlePageSize}
						showPageSize={true}
					/>
				</div>
			</div>
		</div>
	);
};
export default connect((store) => ({
	operationalEfficiencyState: store.operationalEfficiencyState,
	operationalEfficiencyMetricsBizLocationLevel: store.operationalEfficiencyMetricsBizLocationLevel,
	operationalEfficiencyMetricsBizLocationLevelListState: store.operationalEfficiencyMetricsBizLocationLevelListState,
	operationalEfficiencyFilters: store.operationalEfficiencyFilters,
	configItems: store.configItems,
	selectedBrand: store.configItems.brands.selectedBrand
}))(Operations);
