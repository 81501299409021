import React, { useState, useCallback, useEffect, useRef } from "react";

// components
import { Topbar } from "../../components/_commons/Topbar";
import { Button } from "../../components/_commons/Button";
import { Workspace } from "../../components/MerakiWeb/Workspace";
import { Pages } from "../../components/MerakiWeb/Pages";
import { Versions } from "../../components/MerakiWeb/Versions";
import { Domains } from "../../components/MerakiWeb/Domains";
import { Apps } from "../../components/MerakiWeb/Apps";
import Settings from "../../components/MerakiWeb/Settings";
import { Modal } from "../../components/_commons/Modal";
import { InputWithLabel } from "../../components/_commons/InputWithLabel";
import { NestedEntityContainer } from "../../components/_commons/NestedEntityContainer";
import Labels from "../../components/MerakiWeb/Labels";
import RawConfig from "../../components/MerakiWeb/RawConfig";

// third party
import { connect } from "react-redux";

// utils
import { store } from "../../store/configureStore";
import { scroll, adjustNestedContainer } from "../../atlas-utils";

// actions
import { fetchMerakiWebWorkspace, publishMerakiWebWorkspace } from "../../actions/merakiWeb";

// assets
import HelpIcon from "../../components/_commons/HelpIcon";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";
import PublishWrapper from "../../components/MerakiWeb/PublishWrapper";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const FORM_TABS = [
	{
		label: "Workspace",
		value: "workspace"
	},
	{
		label: "Pages",
		value: "pages"
	},
	{
		label: "Labels",
		value: "labels"
	},
	{
		label: "Versions",
		value: "versions"
	},
	{
		label: "Domains",
		value: "domains"
	},
	{
		label: "Integrations",
		value: "apps"
	},
	{
		label: "Configs",
		value: "settings"
	},
	{
		label: "Raw config",
		value: "rawConfig"
	}
];

const MerakiWeb = ({ merakiWebWorkspace, dimensions, loginDetail }) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const { loading, data, error } = merakiWebWorkspace;
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const topRef = useRef();

	const isInternalUser = loginDetail?.email.includes("@urbanpiper.com") || false;

	const formTabsToRender = React.useMemo(() => {
		if (isInternalUser) {
			return FORM_TABS;
		}
		return FORM_TABS.filter((tab) => tab.value !== FORM_TABS[7].value);
	}, [isInternalUser]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });

		if (tab.value !== formTab && tab.value === FORM_TABS[0].value) {
			fetchMerakiWebWorkspace();
		}
	};

	useEffect(() => {
		fetchMerakiWebWorkspace();
	}, []);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "meraki"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="meraki-web-section section-container-common" ref={topRef}>
			<div className="section-header-common">
				<div className="header-text">
					<div className="title">Meraki</div>
					<div className="subtitle">Meraki theme editor</div>
				</div>
				<div className="header-action-container">
					<div className="help-btn-container">
						<Button clickHandler={handlePiperAcademy} type="secondary">
							<HelpIcon />
							<span>Help</span>
						</Button>
					</div>
				</div>
			</div>
			<div className="meraki-web-container">
				<Topbar
					tabs={formTabsToRender}
					selectedTab={formTab}
					switchTab={switchTab}
					isStickyOnTop={true}
					isScrollable={dimensions.width < 768}
				/>
				{formTab === FORM_TABS[0].value && (
					<PublishWrapper>
						{({ handlePublishOpen, publishEnabled }) => (
							<Workspace
								data={data}
								loading={loading}
								error={error}
								publishConfig={{
									enabled: publishEnabled,
									onClick: handlePublishOpen
								}}
							/>
						)}
					</PublishWrapper>
				)}
				{formTab === FORM_TABS[1].value && <Pages />}
				{formTab === FORM_TABS[2].value && <Labels />}
				{formTab === FORM_TABS[3].value && <Versions workspace={data} />}
				{formTab === FORM_TABS[4].value && <Domains workspace={data} loading={loading} />}
				{formTab === FORM_TABS[5].value && <Apps />}
				{formTab === FORM_TABS[6].value && <Settings />}
				{formTab === FORM_TABS[7].value && isInternalUser && <RawConfig />}
				<NestedEntityContainer
					show={nestedEntity.show}
					type={nestedEntity.type}
					id={nestedEntity.id}
					closeNestedContainer={() => handleNestedEntity(false)}
					nestedRef={nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	merakiWebWorkspace: store.merakiWebWorkspace,
	dimensions: store.configItems.dimensions,
	loginDetail: store.login.loginDetail
});

export default connect(mapStateToProps)(MerakiWeb);
