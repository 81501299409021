import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import Breadcrumbs from "../_commons/Breadcrumbs";

// util
import history from "../../history";

// third party
import classNames from "classnames";

function Header({
	title = "",
	description = "",
	rightHeader = "",
	onCtaClick = () => {
		history.push("/onboarding");
	},
	totalSteps = 0,
	currentStep,
	loading = false,
	hideBreadcrumbs = false,
	breadcrumbsTitle = "Setup"
}) {
	return (
		<div className="onboarding-setup-header">
			{!hideBreadcrumbs && (
				<Breadcrumbs
					connectedLinks={[
						{
							title: breadcrumbsTitle,
							onClick: onCtaClick
						}
					]}
				/>
			)}
			<div className="header-container">
				<div className="left">
					<Text variant={TextVariant.HEADING2}>{title}</Text>
					{description && <Text variant={TextVariant.BODY3}>{description}</Text>}
				</div>
				{rightHeader && <div className="right">{rightHeader}</div>}
			</div>
			{totalSteps || loading ? (
				<div className="onboarding-steps">
					{loading ? (
						<div className="infinite-load-bar" />
					) : (
						Array.from({ length: totalSteps }).map((_, index) => (
							<div
								key={index}
								className={classNames("onboarding-steps__step", {
									"onboarding-steps__step--active": index <= currentStep ? true : false
								})}
							></div>
						))
					)}
				</div>
			) : null}
		</div>
	);
}

export default Header;
