import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { Textarea } from "../components/_commons/Textarea";
import { SelectFilter } from "../components/_commons/SelectFilter";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, trackEvent } from "../atlas-utils";
import { connect } from "react-redux";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchCiCharges } from "../actions/actions";
import { fetchTaxesList } from "../actions/taxes";

// graphql
import { CREATE_TAX } from "../graphql/taxes";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../client-config";
export const APPLICABLE_ON_OPTIONS = [
	{ label: "Item Price", value: "item.price" },
	{ label: "Charge", value: "derived_field.charge" }
];

export const getApplicableOn = (applicableOnOption, data) => {
	if (applicableOnOption.value === APPLICABLE_ON_OPTIONS[1].value) {
		return `${applicableOnOption.value}.${data.applicableCharge}`;
	}
	return applicableOnOption.value;
};

const TaxCreate = ({ charges, hasAccess = false }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);
	const [data, setData] = useState({
		title: "",
		description: "",
		merchantRefId: "",
		value: 5,
		applicableCharge: null
	});

	const [applicableOnOption, setApplicableOnOption] = useState(APPLICABLE_ON_OPTIONS[0]);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiCharges();
	}, []);

	const handleCancel = () => {
		setFormState(false);
		fetchTaxesList();
		setTimeout(() => {
			history.push("/taxes");
		}, 100);
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleApplicableOnOption = (field, value) => {
		setApplicableOnOption(value);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const validateData = (applicableOnOption, data) => {
		if (applicableOnOption.value === APPLICABLE_ON_OPTIONS[1].value && !data.applicableCharge) {
			setError({
				fields: {
					applicableCharge: "This field is mandatory for taxes applicable on charge"
				}
			});
			return false;
		}
		return true;
	};

	const handleSubmit = async () => {
		const isValid = validateData(applicableOnOption, data);
		if (!isValid) {
			return;
		}
		setConfirmLoading(true);
		// default value is -1 if no merchantRefId is passed
		if (data.merchantRefId === "") {
			data.merchantRefId = "-1";
		}
		try {
			const variables = {
				...data,
				applicableOnOption: getApplicableOn(applicableOnOption, data)
			};
			delete variables["applicableCharge"];
			const resp = await client.mutate({
				mutation: CREATE_TAX,
				variables
			});
			if (resp.data.saveTax.status.success) {
				trackEvent(TRACKING_EVENT_NAMES.NEW_TAX_CREATION, {
					status: TRACKING_STATUS.SUCCESS
				});

				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Tax Rate created",
						timeout: 5000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_TAXES_LIST,
					payload: resp.data.saveTax.object
				});
				history.push(`/taxes/edit/${resp.data.saveTax.object.id}`);
			} else {
				trackEvent(TRACKING_EVENT_NAMES.NEW_TAX_CREATION, {
					status: TRACKING_STATUS.FAILURE
				});

				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveTax.status.messages));
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.NEW_TAX_CREATION, {
				status: TRACKING_STATUS.FAILURE
			});

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	const validations = error.fields || {};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Tax Rate"
			subTitle="Create a new tax rate"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<div className="form-row row-full">
					<InputWithLabel
						value={data.title}
						onChange={(e) => handleForm("title", e.target.value)}
						requiredLabel={true}
						validationMessage={validations.title || ""}
					>
						Name
					</InputWithLabel>
				</div>
				<div className="form-row row-half">
					<SelectFilter
						title="Applicable on"
						options={APPLICABLE_ON_OPTIONS}
						field="applicableOnOption"
						currValue={applicableOnOption}
						setFilter={handleApplicableOnOption}
						isSearchable={false}
						isClearable={false}
						labelKey="label"
						valueKey="value"
						requiredLabel={true}
						validationMessage={validations.applicableOn || ""}
					/>
					{applicableOnOption.value === APPLICABLE_ON_OPTIONS[1].value ? (
						<SelectFilter
							title="Charge"
							options={charges.items}
							isLoading={charges.isLoading}
							field="applicableCharge"
							currValue={charges.items.find((val) => val.id === data.applicableCharge) || null}
							setFilter={(field, value) => handleForm(field, value?.id || null)}
							labelKey="title"
							valueKey="id"
							requiredLabel={true}
							validationMessage={validations.applicableCharge || ""}
						/>
					) : (
						<InputWithLabel
							value={data.value}
							type="number"
							onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
							requiredLabel={true}
							validationMessage={validations.value || ""}
							showLabel={true}
							classes="at-input--label"
							symbol="%"
						>
							Tax Percentage
						</InputWithLabel>
					)}
				</div>
				{applicableOnOption.value === APPLICABLE_ON_OPTIONS[1].value && (
					<div className="form-row row-half">
						<InputWithLabel
							value={data.value}
							type="number"
							onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
							requiredLabel={true}
							validationMessage={validations.value || ""}
							showLabel={true}
							classes="at-input--label"
							symbol="%"
						>
							Tax Percentage
						</InputWithLabel>
					</div>
				)}
				<div className="form-row row-full">
					<Textarea value={data.description} onChange={(e) => handleForm("description", e.target.value)}>
						Description
					</Textarea>
				</div>
				<div className="form-row row-half">
					<InputWithLabel
						value={data.merchantRefId}
						onChange={(e) => handleForm("merchantRefId", e.target.value)}
						showCustomTooltip={true}
						tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
						validationMessage={validations.merchantRefId || ""}
					>
						POS ID
					</InputWithLabel>
				</div>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	charges: store.configItems.charges
});
export default connect(mapStateToProps)(TaxCreate);
