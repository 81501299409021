// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// graphql
import { GET_WEBHOOKS_LIST, GET_WEBHOOK_DETAIL, EDIT_WEBHOOK, REDELIVER_WEBHOOK } from "../graphql/webhooks";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchWebhooksList = async () => {
	const { limit, offset, sort, appliedFilters, appliedDateFilter } = store.getState().webhooksListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().webhooksList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_WEBHOOKS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(!appliedFilters[f])
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});
		// always fetch only active/enabled items, unless filter is already applied by the user
		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: "true"
			});
		}
		// date filter
		Object.keys(appliedDateFilter).forEach((f) => {
			if (appliedDateFilter[f].dateFilter) {
				filtersObject.push({
					field: f,
					value: appliedDateFilter[f].dateFilter
				});
			}
		});
		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_WEBHOOKS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_WEBHOOKS_LIST_SUCCESS,
			payload: { ...resp.data.webhooks }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_WEBHOOKS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseWebhookData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			if (result["headers"]) {
				const headers = JSON.parse(result["headers"]);
				result["headers"] = Object.keys(headers).map((key) => ({
					key: key,
					value: headers[key]
				}));
			}
			if (result["eventType"]) {
				result["eventType"] = {
					valueForDisplay: result["eventTypeDisplayName"],
					value: result["eventType"]
				};
			}
			if (result["retriesMode"] !== "NONE") {
				result["retry"] = true;
				result["retriesMode"] = {
					valueForDisplay:
						result["retriesMode"].charAt(0).toUpperCase() + result["retriesMode"].slice(1).toLowerCase(),
					value: result["retriesMode"]
				};
			} else {
				result["retry"] = false;
				result["retriesMode"] = null;
			}
			break;
		case "server":
			if (result["headers"]) {
				let headersObj = {};
				result["headers"].forEach((header) => {
					headersObj[header.key] = header.value;
				});
				result["headers"] = JSON.stringify(headersObj);
			}
			if (result["eventType"]) {
				result["eventType"] = result["eventType"].value;
			}
			if (result["retry"] && result["retriesMode"]) {
				result["retriesMode"] = result["retriesMode"].value;
			} else {
				result["retriesMode"] = "NONE";
			}
			break;
		default:
			break;
	}
	return result;
};

export const fetchWebhookDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_WEBHOOK_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_WEBHOOK_DETAIL,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_WEBHOOK_DETAIL_SUCCESS,
			payload: parseWebhookData(resp.data.webhook, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_WEBHOOK_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Webhook details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editWebhook = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_WEBHOOK_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_WEBHOOK,
			variables: parseWebhookData(data, "server")
		});
		if (resp.data.saveWebhook.status.success) {
			dispatch({
				type: ActionTypes.EDIT_WEBHOOK_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Webhook saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_WEBHOOKS_LIST,
				payload: resp.data.saveWebhook.object
			});
			return true;
		} else {
			// handle error message
			if (
				resp.data.saveWebhook.status?.messages.length > 0 &&
				resp.data.saveWebhook.status?.messages[0].field === "__all__"
			) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.saveWebhook.status?.messages[0].message || "Something went wrong.",
						timeout: 2000,
						error: true
					}
				});
			} else {
				dispatch({
					type: ActionTypes.EDIT_WEBHOOK_DETAIL_FAILURE,
					error: parseErrorMessages(resp.data.saveWebhook.status.messages)
				});
			}
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_WEBHOOK_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Webhook details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreWebhook = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_WEBHOOK,
			variables: parseWebhookData(data, "server")
		});
		if (resp.data.saveWebhook.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveWebhook.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};

export const redeliverWebhook = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: REDELIVER_WEBHOOK,
			variables
		});
		if (resp.data.redeliverWebhook.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.redeliverWebhook.status.messages),
					timeout: 5000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.redeliverWebhook.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};
