import React from "react";

const Tooltip = ({ value }) => {
	let description = "";
	if (value == true) {
		description = (
			<div>
				This storefront is <span style={{ color: "#0DA125" }}>Online</span>
			</div>
		);
	} else if (value == false) {
		description = (
			<span>
				This storefront is <span style={{ color: "#B02B2B" }}>offline</span>
			</span>
		);
	} else {
		description = <span> This storefront does not exist </span>;
	}
	return (
		<div
			className="tooltiptext-location border"
			style={{
				...(value === true ? { top: "141%" } : value === false ? { top: "125%" } : { top: "116%" })
			}}
		>
			<div
				className="tooltip-description-location"
				style={{ ...(value == null ? { width: "171px" } : { width: "145px" }) }}
			>
				{description}
			</div>
		</div>
	);
};

export default Tooltip;
