import React from "react";

// utils
import { capitaliseText } from "../../../atlas-utils";

const ChartSelector = ({ options = [], selectedChart, clickHandler = () => {} }) => {
	return (
		<div className="chart-selector">
			{options.map((chart, i) => (
				<div
					key={i}
					className={"chart" + (chart === selectedChart ? " selected" : "")}
					onClick={() => clickHandler(chart)}
					title={capitaliseText(chart)}
				>
					<img src={`/assets/analytics/${chart}${chart === selectedChart ? "-blue" : "-grey"}.svg`} alt="" />
				</div>
			))}
		</div>
	);
};
export default ChartSelector;
