import React, { Component } from "react";

// third party
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import _ from "lodash";
import queryString from "query-string";

// components
import { RadioButton } from "../_commons/RadioButton";
import { Button } from "../_commons/Button";
import CampaignUser from "./CampaignUser";
import CampaignCart from "./CampaignCart";
import CampaignOrder from "./CampaignOrder";
import CampaignInStore from "./CampaignInStore";
import CampaignFeedback from "./CampaignFeedback";
import OverlaySidebar from "../_commons/OverlaySidebar";
import { SuggestedTargetCampaign } from "../Campaign/SuggestedTargetCampaign";
import { commifyNumbers } from "../../atlas-utils";
import { GlobalConfirmModal } from "../../components/SiteComp";
import { LowCreditsAlert } from "../../components/_commons/LowCreditsAlert";

// client
import { store } from "../../store/configureStore";
import { client } from "../../client";

// graphql
import { SAVE_SEGMENT, FILTERED_USERS_PREVIEW } from "../../graphql/segments";

// actions
import { fetchCoupons, fetchItems, fetchStores } from "../../actions/actions";
import { ActionTypes } from "../../actions/_types";

// constants
import { TRACK_EVENT } from "../../atlas-utils/tracking";
export const FILTER_ROOT_OPTIONS = [
	{ label: "User", value: "user" },
	{ label: "Cart", value: "cart" },
	{ label: "Order", value: "order" },
	{ label: "In-store", value: "in_store_purchase" },
	{ label: "Feedback", value: "feedback" }
];
const EMPTY_FILTER = {};
const MATCH_OPTIONS = [
	{ label: "All", value: "AND" },
	{ label: "Any", value: "OR" }
];

@connect((store) => ({
	createSegment: store.createSegment,
	createCampaign: store.createCampaign,
	configItems: store.configItems,
	login: store.login
}))
export default class CreateCampaignWho extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRootFilter: FILTER_ROOT_OPTIONS[0],
			sidebarActive: false,
			showSaveSegmentModal: false,
			isFetchingUsersPreview: false,
			modalBusy: false
		};
	}

	async componentDidMount() {
		// the below code preloads the coupons, items and stores dropdowns
		const { configItems } = this.props;
		if (!configItems.coupons.items.length) {
			fetchCoupons("");
		}
		if (!configItems.items.items.length) {
			fetchItems("");
		}
		if (!configItems.stores.items.length) {
			fetchStores("");
		}

		if (this.props.createCampaign.id) {
			// The below code can be refactored to use fetchUsersPreview function
			this.setState({
				isFetchingUsersPreview: true
			});
			try {
				const resp = await client.query({
					query: FILTERED_USERS_PREVIEW,
					variables: {
						segmentId: this.props.createCampaign.segmentId,
						medium: this.props.createCampaign.medium,
						campaignId: this.props.createCampaign.id
					},
					fetchPolicy: "network-only"
				});

				if (this.props.isSegmentWithCampaign) {
					let requiredCredits = resp.data.filteredUsersPreview.creditsRequired;
					let availableCredits = this.props.login.loggedInbizDetail.credits;
					if (requiredCredits > availableCredits) {
						store.dispatch({
							type: "CREDITS_STATE_UPDATE",
							payload: {
								showLowCreditsBar: true,
								source: "campaign_creation"
							}
						});
					}
				}

				const usersPreview = {
					total: resp.data.filteredUsersPreview.total,
					targeted: resp.data.filteredUsersPreview.targeted,
					percentageTargeted: resp.data.filteredUsersPreview.percentageTargeted,
					creditsRequired: resp.data.filteredUsersPreview.creditsRequired
				};

				store.dispatch({
					type: "SEGMENT_UPDATE",
					payload: {
						usersPreview
					}
				});
			} catch (error) {
				console.log(error);
			}
			this.setState({
				isFetchingUsersPreview: false
			});
		}
	}

	handleSaveSegmentModal = () => {
		this.setState({
			showSaveSegmentModal: !this.state.showSaveSegmentModal
		});
	};

	setFilter = (root, fieldName, newValue) => {
		let filters = JSON.parse(this.props.createSegment.filters);
		if (!filters.condition) {
			filters.condition = MATCH_OPTIONS[0].value;
		}
		if (!filters.groups) {
			filters.groups = {};
		}
		if (!filters.groups[root]) {
			filters.groups[root] = {};
		}
		if (JSON.stringify(newValue) === "{}" || JSON.stringify(newValue) === "[]") {
			delete filters.groups[root][fieldName];
		} else {
			filters.groups[root][fieldName] = newValue;
		}
		const filtersString = JSON.stringify(filters);
		const changeObject = {
			root,
			fieldName
		};
		this.saveFilter(filtersString, changeObject);
	};

	saveFilter = (filtersString, changeObject) => {
		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload: {
				filters: filtersString
			}
		});
		if (this.props.isSegmentWithCampaign) {
			this.saveSegment(filtersString);
		} else {
			this.fetchUsersPreview(filtersString, changeObject);
		}
	};

	changeSegmentTitle = (e) => {
		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload: {
				title: e.target.value
			}
		});
	};

	changeSegmentDescription = (e) => {
		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload: {
				description: e.target.value
			}
		});
	};

	saveSegment = _.debounce((filters) => this.saveSegmentTemp(filters), 500);

	saveSegmentTemp = async (filters) => {
		// set tracking related info
		// to check if this event is to be tracked or not
		let toTrack = false;
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		this.setState({
			isFetchingUsersPreview: true,
			modalBusy: true
		});
		let filtersObj = JSON.parse(filters);
		try {
			const segment = this.props.createSegment;
			if (segment.id) {
				if (!this.props.isSegmentWithCampaign) {
					if (!filtersObj.condition) {
						filtersObj.condition = MATCH_OPTIONS[0].value;
					}
				}
				const resp = await client.mutate({
					mutation: SAVE_SEGMENT,
					variables: {
						id: segment.id,
						title: segment.title,
						isImplicit: segment.isImplicit,
						isActive: segment.isActive,
						filters: JSON.stringify(filtersObj),
						description: segment.description,
						medium: this.props.createCampaign.medium,
						campaignId: this.props.createCampaign.id
					},
					fetchPolicy: "no-cache"
				});
				store.dispatch({
					type: "SEGMENT_UPDATE",
					payload: {
						usersPreview: resp.data.saveSegment.object.usersPreview
					}
				});
				if (!this.props.isSegmentWithCampaign) {
					// track this event
					toTrack = true;

					this.setState({
						showSaveSegmentModal: false,
						modalBusy: false
					});
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Segment successfully saved.",
							timeout: 5000,
							error: false
						}
					});
				} else {
					// lets check what is required credit.
					let requiredCredits = resp.data.saveSegment.object.usersPreview.creditsRequired;
					let availableCredits = this.props.login.loggedInbizDetail.credits;
					if (requiredCredits > availableCredits) {
						store.dispatch({
							type: "CREDITS_STATE_UPDATE",
							payload: {
								showLowCreditsBar: true,
								source: "campaign_creation"
							}
						});
					} else {
						store.dispatch({
							type: "CREDITS_STATE_UPDATE",
							payload: {
								showLowCreditsBar: false
							}
						});
					}
				}
			} else {
				if (this.props.isSegmentWithCampaign) {
					// if this segment is being saved as part of a campaign
					// then throw error for null segment id
					throw {
						message: "segmentId doesn't exist"
					};
				} else {
					// if its standalone segment then create a segment and then save it.
					let filtersObj = JSON.parse(filters);
					if (!filtersObj.condition) {
						filtersObj.condition = MATCH_OPTIONS[0].value;
					}
					await this.props.createSegmentAndSave(JSON.stringify(filtersObj));
					this.setState({
						showSaveSegmentModal: false,
						modalBusy: false
					});
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Segment successfully saved.",
							timeout: 5000,
							error: false
						}
					});
				}
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		this.setState({
			isFetchingUsersPreview: false,
			modalBusy: false
		});

		// track this event
		if (toTrack) {
			if (window.performance) {
				perfEnd = window.performance.now();
			}

			// calculate number of filters applied
			let filterCount = 0;
			FILTER_ROOT_OPTIONS.forEach((f, i) => {
				if (filtersObj.groups && filtersObj.groups[f.value]) {
					Object.keys(filtersObj.groups[f.value]).forEach((fSeciton) => {
						if (
							filtersObj.groups[f.value][fSeciton].values &&
							filtersObj.groups[f.value][fSeciton].values.length > 0
						) {
							filterCount++;
						}
					});
				}
			});

			// check if the segment is being edited
			const queryParams = queryString.parse(this.props.location.search);
			const isNew = queryParams.edit && queryParams.edit == "true" ? false : true;

			const eventMeta = {
				num_of_filters_applied: filterCount,
				percentage_customers: store.getState().createSegment.usersPreview.percentageTargeted,
				is_new: isNew,
				time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
			};
			PubSub.publish(TRACK_EVENT, {
				tracker: "mixpanel",
				eventName: "segment_save",
				eventMeta
			});
			console.log(eventMeta);
		}
	};

	fetchUsersPreview = _.debounce((filters, changeObject) => this.fetchUsersPreviewTemp(filters, changeObject), 500);

	fetchUsersPreviewTemp = async (filters, changeObject) => {
		// set tracking related info
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch latest segment data
		this.setState({
			isFetchingUsersPreview: true
		});
		await this.props.fetchUsersPreview(filters);
		this.setState({
			isFetchingUsersPreview: false
		});

		// track this event
		// checking for changeObject to make sure we are
		// tracking only the actual changes in segment
		if (changeObject) {
			if (window.performance) {
				perfEnd = window.performance.now();
			}
			// PubSub.publish(TRACK_EVENT, {
			// 	tracker: 'mixpanel',
			// 	eventName: 'segment_apply_filter',
			// 	eventMeta: {
			// 		type: changeObject.root,
			// 		value: changeObject.fieldName,
			// 		percentage_customers: store.getState().createSegment.usersPreview.percentageTargeted,
			// 		time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1)),
			// 	},
			// });
		}
	};

	switchRootFilter = (selectedRootFilter) => {
		this.setState({
			selectedRootFilter
		});
	};

	handleFilterMatchOption = (applyFilters, conditionUpdate) => {
		let filters = {};
		if (applyFilters) {
			filters = JSON.parse(this.props.createSegment.filters);
			let condition = MATCH_OPTIONS[0].value;
			if (conditionUpdate) {
				condition = conditionUpdate;
			} else if (filters.condition) {
				condition = filters.condition;
			}
			filters.condition = condition;

			// Update segment type. To be used in tracking.
			store.dispatch({
				type: "SEGMENT_UPDATE",
				payload: {
					segmentType: "ad_hoc"
				}
			});
		} else {
			filters = EMPTY_FILTER;

			// Update segment type. To be used in tracking.
			store.dispatch({
				type: "SEGMENT_UPDATE",
				payload: {
					segmentType: "all"
				}
			});
		}
		this.saveFilter(JSON.stringify(filters));
	};

	toggleSidebar = () => {
		this.setState({
			sidebarActive: !this.state.sidebarActive
		});
	};

	render() {
		const { isFetchingDetails, createSegment, isSegmentWithCampaign } = this.props;
		const { isFetchingUsersPreview } = this.state;
		const filters = JSON.parse(createSegment.filters);
		const { selectedRootFilter } = this.state;
		const applyFilters = filters.condition ? true : false;
		const selectedMatchOption = filters.condition ? filters.condition : MATCH_OPTIONS[0].value;
		const filtersGroup = filters.groups ? filters.groups : {};

		return (
			<div className={"create-campaign-section " + (isFetchingDetails ? "disabled" : "")}>
				<div className="create-campaign-header">
					{!isSegmentWithCampaign ? (
						<div className="create-segment--segment-title">{this.props.createSegment.title}</div>
					) : null}
					<Header toggleSidebar={this.toggleSidebar} isSegmentWithCampaign={isSegmentWithCampaign} />
				</div>
				<div className="match-options-container">
					{/* Disable ANY rule temporarily


					{isSegmentWithCampaign ?
						<React.Fragment>
							<RadioButton
								checked={!applyFilters}
								classes="match-options-radio"
								clickHandler={() => this.handleFilterMatchOption(false)}
							>
								Select all customers
							</RadioButton>
							<RadioButton
								checked={isSegmentWithCampaign ? applyFilters : true}
								classes="match-options-radio"
								clickHandler={() => this.handleFilterMatchOption(true)}
							>
								<div className="text-inline">Select customers who match</div>
								<Select
									searchable={false}
									className="at-dropdown dropdown-inline"
									options={MATCH_OPTIONS}
									value={selectedMatchOption}
									clearable={false}
									onChange={(m) => this.handleFilterMatchOption(true, m.value)}
								/>
								<div className="text-inline">of the conditions below</div>
							</RadioButton>
						</React.Fragment>
						:
						<div className="match-options-radio">
							<div className="text-inline">Select customers who match</div>
							<Select
								searchable={false}
								className="at-dropdown dropdown-inline"
								options={MATCH_OPTIONS}
								value={selectedMatchOption}
								clearable={false}
								onChange={(m) => this.handleFilterMatchOption(true, m.value)}
							/>
							<div className="text-inline">of the conditions below</div>
						</div>
					} */}
					{isSegmentWithCampaign ? (
						<React.Fragment>
							<RadioButton
								checked={!applyFilters}
								classes="match-options-radio"
								clickHandler={() => this.handleFilterMatchOption(false)}
							>
								Select all customers
							</RadioButton>
							<RadioButton
								checked={isSegmentWithCampaign ? applyFilters : true}
								classes="match-options-radio"
								clickHandler={() => this.handleFilterMatchOption(true)}
							>
								Select all customers who match the conditions below
							</RadioButton>
						</React.Fragment>
					) : (
						<div className="match-options-radio">Select all customers who match the conditions below</div>
					)}
				</div>
				<div className={"create-campaign-forms-container "}>
					{!window.isMobile ? (
						<div
							className={
								"sidebar-container " + (!applyFilters && isSegmentWithCampaign ? "disabled" : "")
							}
						>
							<Sidebar
								filterRootOptions={FILTER_ROOT_OPTIONS}
								selectedRootFilter={selectedRootFilter}
								switchRootFilter={this.switchRootFilter}
								isSegmentWithCampaign={isSegmentWithCampaign}
								isFetchingDetails={isFetchingDetails}
								saveSegmentModal={this.handleSaveSegmentModal}
								filters={filters}
							/>
						</div>
					) : null}
					<div className="data-container">
						<div className="create-campaign-who-container">
							<div
								className={
									"filters-container " + (!applyFilters && isSegmentWithCampaign ? "disabled" : "")
								}
							>
								{(selectedRootFilter.value === FILTER_ROOT_OPTIONS[0].value || window.isMobile) && (
									<CampaignUser setFilter={this.setFilter} filter={filtersGroup} />
								)}
								{(selectedRootFilter.value === FILTER_ROOT_OPTIONS[1].value || window.isMobile) && (
									<CampaignCart setFilter={this.setFilter} filter={filtersGroup} />
								)}
								{(selectedRootFilter.value === FILTER_ROOT_OPTIONS[2].value || window.isMobile) && (
									<CampaignOrder setFilter={this.setFilter} filter={filtersGroup} />
								)}
								{(selectedRootFilter.value === FILTER_ROOT_OPTIONS[3].value || window.isMobile) && (
									<CampaignInStore setFilter={this.setFilter} filter={filtersGroup} />
								)}
								{(selectedRootFilter.value === FILTER_ROOT_OPTIONS[4].value || window.isMobile) && (
									<CampaignFeedback setFilter={this.setFilter} filter={filtersGroup} />
								)}
							</div>
							<div className="segment-size-container">
								<SegmentSize
									usersPreview={createSegment.usersPreview}
									isFetchingUsersPreview={isFetchingUsersPreview}
								/>
								{isSegmentWithCampaign ? (
									<div className="credits-stats">
										<div
											className="numbers Mb(5px)"
											title={commifyNumbers(createSegment.usersPreview.creditsRequired)}
										>
											<span className="inline-text title">Credits Required</span>
											<span className="inline-text seperator">:</span>
											<span className="inline-text value">
												{commifyNumbers(createSegment.usersPreview.creditsRequired)}
											</span>
										</div>
										<div
											className="numbers"
											title={commifyNumbers(this.props.login.loggedInbizDetail.credits)}
										>
											<span className="inline-text title">Credits Balance</span>
											<span className="inline-text seperator">:</span>
											{this.props.login.loggedInbizDetail.credits > 10000 && (
												<span className="inline-text value blue">
													{commifyNumbers(this.props.login.loggedInbizDetail.credits)}
												</span>
											)}
											{this.props.login.loggedInbizDetail.credits > 1000 &&
												this.props.login.loggedInbizDetail.credits <= 10000 && (
													<span className="inline-text value green">
														{commifyNumbers(this.props.login.loggedInbizDetail.credits)}
													</span>
												)}
											{this.props.login.loggedInbizDetail.credits > 0 &&
												this.props.login.loggedInbizDetail.credits <= 1000 && (
													<span className="inline-text value orange">
														{commifyNumbers(this.props.login.loggedInbizDetail.credits)}
													</span>
												)}
											{this.props.login.loggedInbizDetail.credits <= 0 && (
												<span className="inline-text value red">
													{commifyNumbers(this.props.login.loggedInbizDetail.credits)}
												</span>
											)}
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
				{isSegmentWithCampaign && (
					<SuggestedSegments
						saveFilter={this.saveFilter}
						toggleSidebar={this.toggleSidebar}
						sidebarActive={this.state.sidebarActive}
					/>
				)}
				{!isSegmentWithCampaign && (
					<GlobalConfirmModal
						backdropHandler={() => {
							return;
						}}
						show={this.state.showSaveSegmentModal}
						confirmBtnEnabled={createSegment.title.trim()}
						confirmHandler={() => this.saveSegment(createSegment.filters)}
						cancelHandler={this.handleSaveSegmentModal}
						modalBusy={this.state.modalBusy}
					>
						<div className="discard-confirm-modal">
							<div className="title">Save segment</div>
							<div className="modal-text C(#ccc)">Want to give your segment a nice title ?</div>
							<div className="save-campaign-title">
								<input
									onChange={(e) => {
										this.changeSegmentTitle(e);
									}}
									value={createSegment.title}
									type="text"
									placeholder="Enter a segment title"
								/>
							</div>
							<div className="modal-text Mt(15px) C(#ccc)">Segment description</div>
							<div className="segment-description">
								<input
									value={createSegment.description}
									type="text"
									onChange={(e) => {
										this.changeSegmentDescription(e);
									}}
									placeholder="Describe your segment"
								/>
							</div>
						</div>
					</GlobalConfirmModal>
				)}
				<LowCreditsAlert />
			</div>
		);
	}
}

class SuggestedSegments extends Component {
	onSelectFilter = (filtersString) => {
		this.props.saveFilter(filtersString);
		this.props.toggleSidebar();

		// Update segment type. To be used in tracking.
		store.dispatch({
			type: "SEGMENT_UPDATE",
			payload: {
				segmentType: "saved"
			}
		});
	};

	render() {
		const { sidebarActive } = this.props;
		return (
			<OverlaySidebar
				showOverlaySidebar={sidebarActive}
				close={this.props.toggleSidebar}
				classes="suggested-segments-container"
			>
				<SuggestedTargetCampaign suggested={false} alignment="topbar" onSelectFilter={this.onSelectFilter} />
			</OverlaySidebar>
		);
	}
}

const Header = ({ toggleSidebar, isSegmentWithCampaign }) => (
	<div>
		<div className="header-text-container">
			<div className="header-text">Who is your target audience?</div>
			<div className="subheader-text">Define your audience by applying filters</div>
		</div>
		{isSegmentWithCampaign && (
			<div onClick={toggleSidebar} className="segment-gallery-btn">
				<div>
					<img src="/assets/icons/icon-segment.svg" alt="" />
				</div>
				<div className="btn-text">{window.isMobile ? "Choose form gallery" : "Segment Gallery"}</div>
			</div>
		)}
	</div>
);

const Sidebar = ({
	filterRootOptions,
	selectedRootFilter,
	switchRootFilter,
	isSegmentWithCampaign,
	saveSegmentModal,
	filters
}) => {
	return (
		<div className="at-side-bar create-campaign-who-sidebar">
			{filterRootOptions.map((f, i) => {
				let filterCount = 0;
				if (filters.groups && filters.groups[f.value]) {
					Object.keys(filters.groups[f.value]).forEach((fSeciton) => {
						if (
							filters.groups[f.value][fSeciton].values &&
							filters.groups[f.value][fSeciton].values.length > 0
						) {
							filterCount++;
						}
					});
				}
				return (
					<div
						className={(f.value === selectedRootFilter.value ? "selected " : "") + "selectable sidebar-row"}
						key={i}
						onClick={() => switchRootFilter(f)}
					>
						<div className="sidebar-item">
							<div className="text text--small item-title">{f.label}</div>
							{filterCount > 0 && <div className="item-filter-count">{filterCount}</div>}
						</div>
					</div>
				);
			})}
			{!isSegmentWithCampaign && (
				<Button classes="save-segment-button" clickHandler={saveSegmentModal}>
					Save
				</Button>
			)}
		</div>
	);
};

const SegmentSize = ({ usersPreview, isFetchingUsersPreview }) => {
	const { total, targeted, percentageTargeted } = usersPreview;
	const rotateStyle = {
		transform: `rotate(${-135 + 1.8 * percentageTargeted}deg)`
	};
	return (
		<div className={"segment-size " + (isFetchingUsersPreview ? "disabled" : "")}>
			<div className="title">Target Preview</div>
			<div className="progress">
				<div className="bar-overflow">
					<div className="bar" style={rotateStyle}></div>
				</div>
				<span className="progress-count">{commifyNumbers(targeted)}</span>
			</div>
			<div className="subtitle-dark">Customers</div>
			<div className="subtitle-light">
				Targeting {percentageTargeted}% of {commifyNumbers(total)} customers
			</div>
		</div>
	);
};
