import React from "react";

// third party
import { Route } from "react-router-dom";

// components
import { RolesList } from "./RolesList";
import RoleEdit from "./RoleEdit";
import { UnifiedRolesList } from "./UnifiedRolesList";
import UnifiedRoleEdit from "./UnifiedRoleEdit";

const RolesContainer = () => {
	return (
		<React.Fragment>
			<div className="user-management roles">
				<Route path="/access" component={RolesList} />
				<Route exact path="/access/edit/:id" component={RoleEdit} />
			</div>
			<div className="unified-user-management roles">
				<Route path="/unified-access" component={UnifiedRolesList} />
				<Route exact path="/unified-access/edit/:id" component={UnifiedRoleEdit} />
			</div>
		</React.Fragment>
	);
};
export default RolesContainer;
