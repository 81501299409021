import React, { useEffect, useState } from "react";

// components
import { Button } from "../../components/_commons/Button";

// third party
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// actions
import { fetchMerakiWebThemesList, createMerakiWebWorkspace, fetchMerakiWebWorkspace } from "../../actions/merakiWeb";
import history from "../../history";
import { adjustNestedContainer } from "../../atlas-utils";
import { NESTED_ENTITY_TYPES } from "../../client-config";
// utils
import { store } from "../../store/configureStore";
import { NestedEntityContainer } from "../../components/_commons/NestedEntityContainer";

const merakiEditorRoute = "/meraki-web/editor";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const ThemesList = ({ merakiWebThemesList, merakiWebWorkspace }) => {
	const { loading, data = [], error } = merakiWebThemesList;
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [currentTheme, setCurrentTheme] = React.useState(merakiWebWorkspace?.theme?.name ?? data[0]?.name);
	const nestedRef = React.useRef();

	const handleNestedEntity = React.useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({ show: true, type, id });
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "meraki"
			}
		});

		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	useEffect(() => {
		fetchMerakiWebWorkspace();
		fetchMerakiWebThemesList();
	}, []);

	React.useEffect(() => {
		if (!loading) {
			setCurrentTheme(data[0]?.name);
		}
	}, [data, loading]);

	React.useEffect(() => {
		if (!!merakiWebWorkspace.data.status && merakiWebWorkspace.data.status !== "New") {
			history.replace("/meraki-web");
		}
	}, [merakiWebWorkspace]);

	const setupWorkspace = async () => {
		try {
			setConfirmLoading(true);
			await createMerakiWebWorkspace(currentTheme);
			history.push(merakiEditorRoute + `?theme=${currentTheme}`);
			setConfirmLoading(false);
		} catch (error) {
			setConfirmLoading(false);
		}
	};

	if (loading && !data.length === 0) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className="meraki-web-themes-section section-container-common">
			<div className="section-header-common">
				<div className="header-text">
					<div className="title">Meraki</div>
					<div className="subtitle">
						Selling online with your own website has never been easier, faster, or more scalable. Choose a
						website theme that suits your brand needs the best.
					</div>
				</div>
			</div>

			<div className={"base-padding-smaller"} />

			<div className={"theme-selector-container " + (confirmLoading ? "disabled" : "")}>
				<div className="header base-align-center">
					<div className="header-text">
						<div className="title">Select a theme</div>
						{/* <div className="subtext">
							Set up your store in minutes and bring your brand to life.
						</div> */}
					</div>

					<div className={"base-row base-gap-normal"}>
						<Link
							className="B1 Br(4px) P(10px) D(f) base-row base-gap-normal base-link"
							to={`/meraki-web/themes/${currentTheme}`}
							title={"View details of theme"}
						>
							<span>View details</span>
						</Link>

						<Button clickHandler={setupWorkspace}>Setup workspace</Button>
					</div>
				</div>

				<div className={"base-padding-normal"} />

				<div className="base-row base-gap-large">
					{data?.map((theme) => {
						return (
							<div
								className={`base-padding-large base-width-half base-shadow-normal base-cursor-pointer base-border-normal base-transition ${
									currentTheme === theme.name
										? "base-background-color-blue-10"
										: "base-border-color-white"
								}`}
								key={theme.name}
								onClick={() => setCurrentTheme(theme.name)}
							>
								<h4 className="base-capitalize base-margin-vertical-normal">
									<b>{theme.name}</b>
								</h4>

								<div className={"base-padding-normal"} />

								<div className="base-row">
									<div
										className="base-position-relative base-row-direction base-justify-content-center mockup-container"
										style={{ width: "82%" }}
									>
										<img
											className="image-desktop-top"
											src={"/assets/background/desktop_full.png"}
										/>

										<img className="feature-image-bottom" src={theme.featureImage} />
									</div>

									<div
										className="base-position-relative base-row-direction base-justify-content-center mockup-container"
										style={{ width: "18%" }}
									>
										<img
											className="image-mobile-top"
											src={"/assets/background/mobile_full.png"}
											loading={"eager"}
										/>

										<img className="feature-image-mobile-bottom" src={theme.featureImageMobile} />
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className="meraki-guide-container">
				<div className="title">How does it work?</div>

				<div className="subtext">
					Go over our easy-to-follow help videos to know more about how to set up your own website using the
					Meraki editor.
				</div>

				<Button classes="at-btn--inverted" clickHandler={handlePiperAcademy}>
					Watch tutorial
				</Button>
			</div>

			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource
			/>
		</div>
	);
};

const mapStateToProps = (store) => ({
	merakiWebThemesList: store.merakiWebThemesList,
	merakiWebWorkspace: store.merakiWebWorkspace
});

export default connect(mapStateToProps)(ThemesList);
