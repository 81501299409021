import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";

// component
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import LocationEntityAssociations from "../EntityAssociations/LocationEntityAssociations";
import FormFilters from "../_commons/FormFilters";
import ItemLocationCustomization from "../LocationEdit/ItemLocationCustomization";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";
import Image from "../_commons/Image";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { Switch } from "../_commons/Switch";

// third party
import { useTrail, config, animated } from "react-spring";
import { connect } from "react-redux";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { extractInitials, parseFilters, printCurrency, scroll, trackEvent } from "../../atlas-utils";

// graphql
import { GET_ITEM_LOCATIONS, UPDATE_ITEM_AVAILABILITY } from "../../graphql/items";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchBrands } from "../../actions/actions";

// config
import {
	ITEMS_LOCATIONS_LIST_HEADER_FIELDS,
	LOCATION_ENTITY_ASSOCIATION_TYPES,
	NESTED_ENTITY_TYPES,
	TRACKING_ACTION,
	TRACKING_ENTITY,
	TRACKING_EVENT_NAMES,
	TRACKING_MISC,
	TRACKING_SOURCE
} from "../../client-config";

// constants
const ITEM_LOCATION_CUSTOMIZATION_INITIAL_STATE = {
	isOpen: false,
	locationId: null,
	locationName: null
};
const PLATFORM_MODES = [
	{ label: "Hub", value: "hub" },
	{ label: "Meraki", value: "meraki" }
];

const AssociatedLocations = ({
	itemId,
	setModalBusy,
	handleNestedEntity,
	currencySymbol,
	isInternationalMerchant = false,
	isDrawerExpanded = false,
	handleItemLocationFields,
	readOnly = true,
	brands,
	isMultibrandEnabled
}) => {
	const { items: brandsList } = brands;
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [stockLoadingId, setStockLoadingId] = useState(undefined);
	const [locationsList, setLocationsList] = useState({
		objects: [],
		count: 0,
		filters: []
	});
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [itemLocationCustomization, setItemLocationCustomization] = useState(
		ITEM_LOCATION_CUSTOMIZATION_INITIAL_STATE
	);
	const [filters, setFilters] = useState({});
	const [mode, setMode] = useState(PLATFORM_MODES[0]);
	const [ilpaPrices, setIlpaPrices] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [locationUpdates, setLocationUpdates] = useState({});
	const [selectAllLocations, setSelectAllLocations] = useState(false);
	const totalChanges = Object.values(locationUpdates).filter((val) => val === true).length;
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const brandsWithoutAll = brands?.items.filter((brand) => brand.id !== "all");
	const [selectedBrand, setSelectedBrand] = useState(
		!brands.selectedBrand || brands.selectedBrand?.id === "all" ? brandsWithoutAll[0] || null : brands.selectedBrand
	);
	const totalPages = Math.ceil(locationsList.count / limit);
	const tableRef = useRef();
	const columnarTableRef = useRef();

	const handleForm = (field, value, id) => {
		// enforce positive value for price field
		if (value && value < 0) {
			value = 0;
		}
		let updatedObj;
		const newData = locationsList.objects.map((l) => {
			if (l.id === id) {
				l[field] = value;
				updatedObj = {
					id: parseInt(id),
					itemRefId: l.itemRefId || "",
					price: l.price,
					externalPrice: l.externalPrice,
					currentStock: l.currentStock
				};
			}
			return l;
		});
		setLocationsList(newData);
		handleItemLocationFields(id, updatedObj);
	};

	const fetchItemLocations = useCallback(
		async (filters = {}, resetSelection = false, brand) => {
			try {
				setLoading(true);
				const variables = {
					id: parseInt(itemId),
					limit,
					offset,
					brandId: selectedBrand?.id ? parseInt(selectedBrand?.id) : null
				};
				// sidebar filters
				const filtersObject = parseFilters(filters);
				if (filtersObject.length > 0) {
					variables.filters = filtersObject;
				}
				const resp = await client.query({
					query: GET_ITEM_LOCATIONS,
					variables,
					fetchPolicy: "no-cache"
				});
				setLocationsList(resp.data.item.associatedItemLocations);

				// get aggregator prices of item at associated locations for international merchants
				const ilpaPrices = {};
				resp.data.item.associatedItemLocations.objects.forEach((loc) => {
					loc.associatedItemLocationPlatforms.forEach((ilpa) => {
						let ilp = {
							...ilpaPrices[ilpa.bizPlatform.platformName]
						};
						if (ilp?.prices?.length) {
							ilp.prices.push({
								id: loc.location.id,
								name: loc.location.name,
								ilpaPrice: ilpa.price,
								externalPrice: loc.externalPrice,
								actualPrice: ilpa.actualPrice
							});
						} else {
							ilp.prices = [
								{
									id: loc.location.id,
									name: loc.location.name,
									ilpaPrice: ilpa.price,
									externalPrice: loc.externalPrice,
									actualPrice: ilpa.actualPrice
								}
							];
						}
						ilpaPrices[ilpa.bizPlatform.platformName] = ilp;
					});
				});
				setIlpaPrices(ilpaPrices);
				if (resetSelection) {
					setLocationUpdates({});
					setAllSelected(false);
					setSelectAllLocations(false);
					setShowAllFieldSelector(false);
					setIsCheckedAll(false);
				}
				// scroll to top
				const formContainer = document.querySelectorAll(".form-container")[0];
				if (tableRef && formContainer) {
					scroll({ top: tableRef?.current?.offsetTop - 200, left: 0 }, formContainer);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setLoading(false);
		},
		[itemId, limit, offset, selectedBrand]
	);

	useEffect(() => {
		fetchItemLocations({}, false);
	}, [fetchItemLocations, selectedBrand]);

	const applyFilter = useCallback(
		(filters) => {
			setFilters({ ...filters });
			fetchItemLocations(filters);
		},
		[fetchItemLocations]
	);

	const updateItemAvailability = async (id, isAvailable) => {
		setLoading(true);
		try {
			setStockLoadingId(id);
			const variables = {
				ilaIds: [parseInt(id)],
				action: isAvailable ? "enable" : "disable",
				itemId: parseInt(itemId),
				associateAll: false,
				disassociateAll: false,
				filters: []
			};
			const resp = await client.mutate({
				mutation: UPDATE_ITEM_AVAILABILITY,
				variables
			});
			if (resp.data.itemAction.status.success) {
				await fetchItemLocations(filters, true);
				// track event
				const eventMeta = {
					entity: TRACKING_ENTITY.ITEM,
					source: TRACKING_SOURCE.DETAIL_VIEW,
					action: isAvailable ? TRACKING_ACTION.SET_ONLINE : TRACKING_ACTION.SET_OFFLINE,
					platforms: Object.keys(ilpaPrices),
					snoozed_until: isAvailable ? null : TRACKING_MISC.INDEFINITELY,
					bulk: false,
					number_of_entities: 1
				};
				trackEvent(TRACKING_EVENT_NAMES.AVAILABILITY, eventMeta);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Updated item availability at location",
						timeout: 3000,
						error: false
					}
				});
			} else {
				// handle error message
				if (resp.data.itemAction.status.messages.length) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: resp.data.itemAction.status.messages[0].message,
							timeout: 2000,
							error: true
						}
					});
				} else {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "There was an error while updating item availability at location",
							timeout: 2000,
							error: true
						}
					});
				}
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setStockLoadingId(undefined);
		setLoading(false);
	};

	const updateBulkItemAvailability = async (isAvailable) => {
		setLoading(true);
		try {
			const variables = {
				ilaIds: !allSelected ? Object.keys(locationUpdates).filter((val) => locationUpdates[val] === true) : [],
				action: isAvailable ? "enable" : "disable",
				itemId: parseInt(itemId),
				associateAll: allSelected ? isAvailable : false,
				disassociateAll: allSelected ? !isAvailable : false
			};
			// add filters
			const filtersObject = parseFilters(filters);
			console.log(filters);
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.mutate({
				mutation: UPDATE_ITEM_AVAILABILITY,
				variables
			});
			if (resp.data.itemAction.status.success) {
				await fetchItemLocations(filters, true);
				// track event
				const eventMeta = {
					entity: TRACKING_ENTITY.ITEM,
					source: TRACKING_SOURCE.DETAIL_VIEW,
					action: isAvailable ? TRACKING_ACTION.SET_ONLINE : TRACKING_ACTION.SET_OFFLINE,
					platforms: Object.keys(ilpaPrices),
					number_of_entities: !allSelected
						? Object.keys(locationUpdates).filter((val) => locationUpdates[val] === true).length
						: locationsList.count,
					snoozed_until: isAvailable ? null : TRACKING_MISC.INDEFINITELY,
					bulk: true
				};
				trackEvent(TRACKING_EVENT_NAMES.AVAILABILITY, eventMeta);

				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Updated item availability at location(s)",
						timeout: 3000,
						error: false
					}
				});
			} else {
				// handle error message
				if (resp.data.itemAction.status.messages.length) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: resp.data.itemAction.status.messages[0].message,
							timeout: 2000,
							error: true
						}
					});
				} else {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "There was an error while updating item availability at locations(s)",
							timeout: 2000,
							error: true
						}
					});
				}
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			setFilters({});
			fetchItemLocations({}, true, selectedBrand);
			setLocationUpdates({});
			setAllSelected(false);
			setSelectAllLocations(false);
			setShowAllFieldSelector(false);
			setIsCheckedAll(false);
		}
	};

	const handleItemLocationCustomization = (locationId, locationName) => {
		setItemLocationCustomization({
			isOpen: true,
			locationId,
			locationName
		});
	};

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
		},
		[limit]
	);

	const handleCheck = (locId, isAvailable) => {
		setLocationUpdates({
			...locationUpdates,
			[parseInt(locId)]: isAvailable
		});
		if (!isAvailable) {
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
			setAllSelected(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		locationsList.objects.forEach((loc) => {
			updates[parseInt(loc.id)] = toCheckAll;
		});
		setLocationUpdates({
			...locationUpdates,
			...updates
		});
		if (toCheckAll && totalPages > 1) {
			setShowAllFieldSelector(true);
		}
		if (!toCheckAll) {
			setShowAllFieldSelector(false);
			setAllSelected(false);
		}
	};

	const handleCompleteSelection = (selectAllLocations = false) => {
		setAllSelected(selectAllLocations);
		handleCheckAll(selectAllLocations);
		setSelectAllLocations(selectAllLocations);
		if (!selectAllLocations) {
			setShowAllFieldSelector(false);
			setLocationUpdates({});
		}
	};

	const horizontalScroll = useCallback((e) => {
		const container = columnarTableRef.current;
		if (container) {
			container.scrollTo({
				top: 0,
				left: container?.scrollLeft + e.deltaY,
				behaviour: "smooth"
			});
		}
	}, []);

	const closeItemCustomization = () => {
		setItemLocationCustomization(ITEM_LOCATION_CUSTOMIZATION_INITIAL_STATE);
		fetchItemLocations();
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrandSelection = (field, value) => {
		setSelectedBrand(value);
	};

	useEffect(() => {
		setSelectedBrand(
			!brands.selectedBrand || brands.selectedBrand?.id === "all"
				? brandsWithoutAll[0] || null
				: brands.selectedBrand
		);
	}, [brandsList]);

	useEffect(() => {
		fetchBrands("", true);
	}, []);

	return (
		<div className={"associated-locations-tab" + (loading ? " no-click" : "")}>
			<div className="header-container">
				{isMultibrandEnabled && (
					<div className="platform-modes">
						<div className="filter-text">Location details for:</div>
						<SelectFilterCustom
							options={brandsWithoutAll}
							isLoading={brands.isLoading}
							field="brands"
							currValue={selectedBrand}
							setFilter={handleBrandSelection}
							labelKey="name"
							valueKey="id"
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							classes="dropdown-custom-item-details"
						/>
					</div>
				)}
				{/* <div>
					<div className="header-text">Associated Locations</div>
					<div className="header-subtext">Manage locations where this item is sold at, toggle availability and override prices</div>
				</div> */}
				<div>
					{!readOnly && (
						<div>
							<Button clickHandler={openAssociationSidebar}>Add Locations</Button>
						</div>
					)}
				</div>
			</div>
			<FormFilters
				enableName={true}
				nameConfig={{
					field: "location__biz_location_nickname",
					placeholder: "Enter Name"
				}}
				enableCity={true}
				cityConfig={{
					field: "location__city"
				}}
				enableTags={true}
				apply={applyFilter}
				ref={tableRef}
			/>
			<div className="filters-container">
				{isInternationalMerchant && (
					<div className="platform-modes">
						<div className="filter-text">Displaying prices for:</div>
						<SelectFilter
							options={PLATFORM_MODES}
							field="mode"
							currValue={mode}
							setFilter={(field, value) => setMode(value)}
							labelKey="label"
							valueKey="value"
							isClearable={false}
							isSearchable={false}
						/>
					</div>
				)}
			</div>
			{showAllFieldSelector && totalPages > 1 && (
				<SelectAllFields
					allSelected={allSelected}
					handleCompleteSelection={handleCompleteSelection}
					mainMessage={
						<span>
							{allSelected ? "All " : ""}
							<strong>{allSelected ? locationsList.count : locationsList?.objects?.length} </strong>{" "}
							location(s) {allSelected ? "on all pages are selected." : "on this page are selected."}
						</span>
					}
					linkMessage={
						allSelected ? (
							" Clear selection"
						) : (
							<span>
								{" "}
								Select <strong>{locationsList.count}</strong> locations from all pages
							</span>
						)
					}
				/>
			)}
			{totalChanges > 0 && (
				<div className="toggle-item-availability">
					<Button
						classes={"at-btn--danger" + (loading ? " disabled" : "")}
						clickHandler={() => updateBulkItemAvailability(false)}
					>
						Mark {allSelected ? `all ${locationsList.count}` : totalChanges} location(s) unavailable
					</Button>
					<Button classes={loading ? "disabled" : ""} clickHandler={() => updateBulkItemAvailability(true)}>
						Mark {allSelected ? `all ${locationsList.count}` : totalChanges} location(s) available
					</Button>
				</div>
			)}
			{isInternationalMerchant ? (
				<ColumnarTable
					data={locationsList.objects}
					ilpaPrices={ilpaPrices || {}}
					loading={loading}
					handleNestedEntity={handleNestedEntity}
					handleItemLocationCustomization={handleItemLocationCustomization}
					currencySymbol={currencySymbol}
					selectedMode={mode}
					isDrawerExpanded={isDrawerExpanded}
					updateItemAvailability={updateItemAvailability}
					stockLoadingId={stockLoadingId}
					handleCheck={handleCheck}
					locationUpdates={locationUpdates}
					isCheckedAll={isCheckedAll}
					handleCheckAll={handleCheckAll}
					tableRef={columnarTableRef}
					horizontalScroll={horizontalScroll}
					readOnly={readOnly}
				/>
			) : (
				<Table
					data={locationsList.objects}
					ilpaPrices={ilpaPrices || {}}
					loading={loading}
					handleNestedEntity={handleNestedEntity}
					handleItemLocationCustomization={handleItemLocationCustomization}
					currencySymbol={currencySymbol}
					selectedMode={mode}
					handleForm={handleForm}
					updateItemAvailability={updateItemAvailability}
					stockLoadingId={stockLoadingId}
					handleCheck={handleCheck}
					locationUpdates={locationUpdates}
					isCheckedAll={isCheckedAll}
					handleCheckAll={handleCheckAll}
					readOnly={readOnly}
				/>
			)}
			<Paginator
				limit={limit}
				offset={offset}
				count={locationsList.count}
				goToPage={handlePagination}
				readOnly={allSelected}
			/>
			<LocationEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={LOCATION_ENTITY_ASSOCIATION_TYPES[0]}
				entityId={itemId}
				selectedBrand={selectedBrand}
			/>
			<ItemLocationCustomization
				isOpen={itemLocationCustomization.isOpen}
				close={closeItemCustomization}
				itemId={itemId}
				isInternationalMerchant={isInternationalMerchant}
				locationId={itemLocationCustomization.locationId}
				info={{ source: "item", name: itemLocationCustomization.locationName }}
				handleNestedEntity={handleNestedEntity}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(AssociatedLocations);

const ColumnarTable = ({
	data,
	ilpaPrices,
	loading,
	handleNestedEntity,
	handleItemLocationCustomization,
	currencySymbol,
	isDrawerExpanded = false,
	selectedMode,
	updateItemAvailability,
	stockLoadingId,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	locationUpdates,
	tableRef,
	horizontalScroll,
	readOnly
}) => {
	const [showInfo, setShowInfo] = useState(false);

	const table = useMemo(
		() => document.querySelectorAll(".item-locations-table-container > .columnar-table-container > .table-content"),
		[isDrawerExpanded]
	);

	const handleScroll = (direction = false) => {
		if (direction && table[0]) {
			// scroll to the right
			scroll({ top: 0, left: table[0]?.scrollLeft + 200 }, table[0]);
		} else {
			// scroll to the left
			scroll({ top: 0, left: table[0]?.scrollLeft - 200 }, table[0]);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (table[0]) {
				setShowInfo(table?.[0]?.offsetWidth !== table?.[0]?.scrollWidth);
			}
		}, 100);
	}, [table, isDrawerExpanded]);

	return (
		<div className="item-locations-table-container">
			{(showInfo || Object.keys(ilpaPrices).length > 6) && selectedMode.value === "hub" && (
				<div
					className="table-info"
					aria-label="Scroll to the right to see more columns in the table"
					data-balloon-pos="up-right"
				>
					<img src="/assets/icons/info.png" alt="" />
				</div>
			)}
			<div className="columnar-table-container">
				{(showInfo || Object.keys(ilpaPrices).length > 6) && selectedMode.value === "hub" && (
					<React.Fragment>
						<div className="scroll-left" onClick={() => handleScroll(false)}>
							<img src="/assets/icons/icon-left-arrow.svg" alt="" />
						</div>
						<div className="scroll-right" onClick={() => handleScroll(true)}>
							<img src="/assets/icons/icon-right-arrow.svg" alt="" />
						</div>
					</React.Fragment>
				)}
				<div className={"table-content" + (loading ? " loading" : "")} ref={tableRef}>
					<div className="at-table-column name">
						<div className="at-table-header">
							<div className="header-text">
								<CheckBox
									checked={isCheckedAll}
									clickHandler={() => handleCheckAll(!isCheckedAll)}
									title="Name"
									readOnly={readOnly}
								>
									<span className="text">Name</span>
								</CheckBox>
							</div>
						</div>
						{data.map((loc, i) => (
							<div className="at-table-cell" key={i}>
								<div className="cell-text">
									<div className="location">
										<CheckBox
											checked={locationUpdates[loc.id] ? true : false}
											clickHandler={() =>
												handleCheck(loc.id, !(locationUpdates[loc.id] ? true : false))
											}
											readOnly={readOnly}
										>
											<a
												role="button"
												className="link-text"
												onClick={(e) => {
													handleNestedEntity(true, NESTED_ENTITY_TYPES[2], loc.location.id);
													e.stopPropagation();
												}}
												title={loc.location.name}
											>
												<span className="hyperlink hyperlink--black-color">
													{loc.location.name
														? loc.location.name.length <= 25
															? loc.location.name
															: `${loc.location.name.slice(0, 25)}...`
														: loc.location.id}
												</span>
											</a>
										</CheckBox>
										{/* <div
												className="availableAtLocation"
												data-is-available={loc.available}
												title='Click to toggle availability status'
												onClick={!readOnly ? () => updateItemAvailability(loc.id, !loc.available) : () => {}}
											>
												{
													stockLoadingId === loc.id ?
													<div className={"loader "+(loc.available ? "out" : "in")}>
														<div></div>
														<div></div>
														<div></div>
													</div>
													:
													<div>{loc.available ? 'Available' : 'Not available'}</div>
												}
												{
													!readOnly &&
													<div className={"stock "+(loc.available ? "out" : "in")}>
														<img src={loc.available ? "/assets/icons/icon-red-arrow.svg" : "/assets/icons/icon-green-arrow.svg"} alt="" />
													</div>
												}
											</div> */}
									</div>
									<div className="city-name">{loc.location.city || "--"}</div>
									<div className="tags">
										{loc.location.tags.length > 0 && (
											<div className="tags-container table-mode">
												<div className="list">
													{loc.location.tags.map(
														(tag, i) =>
															i < 2 && (
																<span key={i} className="tag-item" title={tag}>
																	{tag}
																</span>
															)
													)}
													{loc.location.tags.length > 2 && (
														<span
															className="tag-item more-tags"
															title={loc.location.tags.slice(2).join(", ")}
														>
															+{loc.location.tags.length - 2} more
														</span>
													)}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="at-table-column availability">
						<div className="at-table-header">
							<div className="header-text">
								<span className="text">availability</span>
							</div>
						</div>
						{data.map((loc, i) => (
							<div className="at-table-cell" key={i}>
								<div className="cell-text">
									<div className="availability">
										{/* <div
												className="availableAtLocation"
												data-is-available={loc.available}
												title='Click to toggle availability status'
												onClick={!readOnly ? () => updateItemAvailability(loc.id, !loc.available) : () => {}}
											>

											</div> */}
										<Switch
											checked={loc.available}
											clickHandler={
												!readOnly
													? () => updateItemAvailability(loc.id, !loc.available)
													: () => {}
											}
											classes={readOnly ? "disabled" : ""}
										/>
									</div>
								</div>
							</div>
						))}
					</div>
					{selectedMode.value === "meraki" && (
						<React.Fragment>
							<div className="at-table-column stock-count">
								<div className="at-table-header">
									<div className="header-text">Stock Count</div>
								</div>
								{data.map((loc, i) => (
									<div className="at-table-cell" key={i}>
										<div className="cell-text">{loc.currentStock || "--"}</div>
									</div>
								))}
							</div>
							<div className="at-table-column price">
								<div className="at-table-header">
									<div className="header-text col">
										<div>Meraki Price</div>
										<div className="desc">(Your apps & website)</div>
									</div>
								</div>
								{data.map((loc, i) => (
									<div className="at-table-cell" key={i}>
										<div className="cell-text">
											{printCurrency(currencySymbol)}
											{loc.price || 0}
											{loc?.actualPrice !== 0 && !loc?.actualPrice && (
												<div className="inherited-price">(Inherited)</div>
											)}
										</div>
									</div>
								))}
							</div>
						</React.Fragment>
					)}
					{selectedMode.value === "hub" &&
						Object.keys(ilpaPrices).map((plf, i) => (
							<div className={`at-table-column ${plf}`} key={i}>
								<div className="at-table-header">
									<div className="header-text col">
										<div>{plf}</div>
										<div className="desc">price</div>
									</div>
								</div>
								{data.map((loc, j) =>
									ilpaPrices[plf].prices.find((location) => loc.location.id === location.id) ? (
										<div className="at-table-cell" key={j}>
											<div className="cell-text" title={`${plf} price`}>
												{printCurrency(currencySymbol)}
												{ilpaPrices[plf].prices.find(
													(location) => loc.location.id === location.id
												)?.ilpaPrice || 0}
												{ilpaPrices[plf].prices.find(
													(location) => loc.location.id === location.id
												)?.actualPrice !== 0 &&
													!ilpaPrices[plf].prices.find(
														(location) => loc.location.id === location.id
													)?.actualPrice && (
														<div className="inherited-price">(Inherited)</div>
													)}
											</div>
										</div>
									) : (
										<div className="at-table-cell" key={j}>
											<div className="cell-text not-found" title={`${plf} price`}>
												Not found
											</div>
										</div>
									)
								)}
							</div>
						))}
					<div className="at-table-column customize">
						<div className="at-table-header">
							<div className="header-text"></div>
						</div>
						{data.map((loc, i) => (
							<div className="at-table-cell" key={i}>
								<div className="cell-text">
									{!readOnly && (
										<div
											onClick={() =>
												handleItemLocationCustomization(loc.location.id, loc.location.name)
											}
										>
											<img className="action-item" src="/assets/icons/icon-edit-dark.svg" />
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
				{data.length === 0 && !loading && <div className="no-items-placeholder">No Locations found!</div>}
				{data.length === 0 && loading && (
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
			</div>
		</div>
	);
};

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	handleNestedEntity,
	handleItemLocationCustomization,
	currencySymbol,
	handleForm,
	updateItemAvailability,
	stockLoadingId,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	locationUpdates,
	readOnly
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container items-edit-locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						readOnly={readOnly}
						headerFields={ITEMS_LOCATIONS_LIST_HEADER_FIELDS}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							handleNestedEntity={handleNestedEntity}
							handleItemLocationCustomization={handleItemLocationCustomization}
							currencySymbol={currencySymbol}
							handleForm={handleForm}
							updateItemAvailability={updateItemAvailability}
							stockLoadingId={stockLoadingId}
							handleCheck={handleCheck}
							locationUpdates={locationUpdates}
							readOnly={readOnly}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Locations found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, sortList, headerFields, readOnly, sortInfo }) => (
	<div className={`at-table-row transaction-header-row items-list-table`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					{field.value === "name" ? (
						<div className={"checkbox-sort" + (sortInfo?.field === "associated" ? " selected" : "")}>
							<CheckBox
								checked={isCheckedAll}
								clickHandler={() => handleCheckAll(!isCheckedAll)}
								title={field.label}
								readOnly={readOnly}
							>
								<span className="text">{field.label}</span>
							</CheckBox>
						</div>
					) : (
						<span>{field.label}</span>
					)}
					{field.subText && <span className="at-header-subtext">{field.subText}</span>}
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({
	id,
	style,
	handleNestedEntity,
	price,
	currentStock,
	available,
	location,
	externalPrice,
	handleForm,
	updateItemAvailability,
	stockLoadingId,
	currencySymbol,
	readOnly,
	handleItemLocationCustomization,
	locationUpdates = {},
	handleCheck
}) => {
	const status = locationUpdates[id] ? true : false;
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows items-list-table"
		>
			<div className="at-table-cell at-cell-text name" title={location.name}>
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} readOnly={readOnly}>
					<div className="location-desc">
						<a
							role="button"
							className="link-text"
							onClick={(e) => {
								handleNestedEntity(true, NESTED_ENTITY_TYPES[2], location.id);
								e.stopPropagation();
							}}
							title={location.name}
						>
							<span className="hyperlink hyperlink--black-color">
								{location.name
									? location.name.length <= 20
										? location.name
										: `${location.name.slice(0, 20)}...`
									: location.id}
							</span>
						</a>
					</div>
				</CheckBox>
				<div className="city-name">{location.city || "--"}</div>
				<div className="tags">
					{location.tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{location.tags.map(
									(tag, i) =>
										i < 2 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{location.tags.length > 2 && (
									<span className="tag-item more-tags" title={location.tags.slice(2).join(", ")}>
										+{location.tags.length - 2} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="availability">
				<div className="availability-toggle-switch">
					<Switch checked={available} clickHandler={() => updateItemAvailability(id, !available)} />
				</div>
			</div>
			<div className="at-table-cell at-cell-text stock-count">{currentStock || "--"}</div>
			<div className="at-table-cell at-cell-text price">
				{printCurrency(currencySymbol)}
				{price || 0}
			</div>
			<div className="at-table-cell at-cell-text aggregator_price">
				<div>
					{printCurrency(currencySymbol)}
					{externalPrice || 0}
				</div>
				{!readOnly && (
					<div onClick={() => handleItemLocationCustomization(location.id, location.name)}>
						<img className="action-item" src="/assets/icons/icon-edit-dark.svg" />
					</div>
				)}
			</div>
		</animated.div>
	);
};
