import React from "react";

const BestItem = ({ height = 16, width = 10, fill = "none" }) => {
	return (
		<svg height={height} width={width} viewBox="0 0 10 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5 0.0195312L3.92969 1.02539L2.5 0.689453L2.07617 2.09521L0.669922 2.51904L1.00586 3.94922L0 5.01953L1.00586 6.08984L0.669922 7.51953L2.07617 7.94336L2.5 9.34961L3.92969 9.01367L5 10.0195L6.07031 9.01367L7.5 9.34961L7.92383 7.94336L9.33008 7.51953L8.99414 6.08984L10 5.01953L8.99414 3.94922L9.33008 2.51953L7.92383 2.0957L7.5 0.689453L6.07031 1.0249L5 0.0195312ZM5 2.37354C6.4475 2.37354 7.625 3.55104 7.625 4.99854C7.625 6.44604 6.4475 7.62354 5 7.62354C3.5525 7.62354 2.375 6.44604 2.375 4.99854C2.375 3.55104 3.5525 2.37354 5 2.37354ZM5 3.12354C4.50272 3.12354 4.02581 3.32108 3.67417 3.67271C3.32254 4.02434 3.125 4.50125 3.125 4.99854C3.125 5.49582 3.32254 5.97273 3.67417 6.32436C4.02581 6.67599 4.50272 6.87354 5 6.87354C5.49728 6.87354 5.97419 6.67599 6.32582 6.32436C6.67746 5.97273 6.875 5.49582 6.875 4.99854C6.875 4.50125 6.67746 4.02434 6.32582 3.67271C5.97419 3.32108 5.49728 3.12354 5 3.12354ZM3.70947 9.83594L2.25 10.3901V15.4985L3 15.9985L5 14.4985L7 15.9985L7.75 15.4985V10.3901L6.29053 9.83594L5 10.5483L3.70947 9.83594Z"
				fill="#0B6E1B"
			/>
		</svg>
	);
};

export default React.memo(BestItem);
