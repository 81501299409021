import React, { useState, useCallback, useEffect } from "react";

// component
import { Button } from "../_commons/Button";
import { Paginator } from "../_commons/Paginator";
import LocationEntityAssociations from "../EntityAssociations/LocationEntityAssociations";
import FormFilters from "../_commons/FormFilters";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

// third party
import { useTrail, config, animated } from "react-spring";
import { connect } from "react-redux";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { extractInitials } from "../../atlas-utils";

// graphql
import { GET_LOCATION_GROUP_LOCATIONS } from "../../graphql/locationGroups";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchBrands } from "../../actions/actions";

// constants
import { LOCATION_ENTITY_ASSOCIATION_TYPES, NESTED_ENTITY_TYPES } from "../../client-config";

const TABLE_FIELDS = [
	{ label: "Name", value: "name" },
	{ label: "Location", value: "location" },
	{ label: "Address", value: "address" }
];

const Locations = ({
	groupId,
	setModalBusy,
	handleNestedEntity,
	includesAll,
	isDeleted = false,
	readOnly = true,
	isMultibrandEnabled,
	brands
}) => {
	const { items: brandsList } = brands;
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const [data, setData] = useState({
		objects: [],
		count: 0
	});
	const brandsWithoutAll = brands?.items.filter((brand) => brand.id !== "all");
	const [selectedBrand, setSelectedBrand] = useState(
		!brands.selectedBrand || brands.selectedBrand?.id === "all" ? brandsWithoutAll[0] || null : brands.selectedBrand
	);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(groupId),
				limit,
				offset,
				brandId: selectedBrand?.id ? parseInt(selectedBrand?.id) : null
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value || appliedFilters[f].id) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value || appliedFilters[f].id
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_LOCATION_GROUP_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			setData(resp.data.locationGroup.locations);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [groupId, limit, offset, appliedFilters, selectedBrand]);

	useEffect(() => {
		if (!isDeleted) {
			fetchData();
		}
	}, [fetchData, limit, offset, isDeleted, selectedBrand]);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
		},
		[limit]
	);

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
	};

	const closeAssociationSidebar = (refresh = false) => {
		setAssociationSidebar(false);
		setModalBusy(false);
		if (refresh) {
			fetchData();
		}
	};

	const applyFilter = useCallback(
		(filters) => {
			if (!isDeleted) {
				setAppliedFilters(filters);
				setOffset(0);
			}
		},
		[fetchData]
	);

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrandSelection = (field, value) => {
		setSelectedBrand(value);
	};

	useEffect(() => {
		setSelectedBrand(
			!brands.selectedBrand || brands.selectedBrand?.id === "all"
				? brandsWithoutAll[0] || null
				: brands.selectedBrand
		);
	}, [brandsList]);

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	return (
		<div className={loading ? "no-click" : ""}>
			<div className="related-entities-header-container">
				<div>
					<div className="header-text">Associated Locations</div>
					<div className="header-subtext">
						{includesAll
							? "All locations are associated to this group"
							: `${data.count} location(s) are associated to this Location group`}
					</div>
				</div>
				{!includesAll && !isDeleted && !readOnly && (
					<div>
						<Button clickHandler={openAssociationSidebar}>Update</Button>
					</div>
				)}
			</div>
			<div className="main-filters-container">
				{isMultibrandEnabled && (
					<SelectFilterCustom
						options={brandsWithoutAll}
						isLoading={brands.isLoading}
						field="brands"
						currValue={selectedBrand}
						setFilter={handleBrandSelection}
						labelKey="name"
						valueKey="id"
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						isClearable={false}
						renderLabel={handleBrandsLabelOption}
						renderOptions={handleBrandsLabelOption}
						placeholder="Select brand"
					/>
				)}
				<FormFilters enableName={true} enableCity={true} enableTags={true} apply={applyFilter} />
			</div>
			<Table data={data.objects} loading={loading} handleNestedEntity={handleNestedEntity} />
			<Paginator limit={limit} offset={offset} count={data.count || 0} goToPage={handlePagination} />
			<LocationEntityAssociations
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={LOCATION_ENTITY_ASSOCIATION_TYPES[3]}
				entityId={parseInt(groupId)}
				selectedBrand={selectedBrand}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(Locations);

export const Table = ({ data, loading, sortList, sortedField, handleNestedEntity }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container location-group-locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader sortList={sortList} sortedField={sortedField} headerFields={TABLE_FIELDS} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							handleNestedEntity={handleNestedEntity}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Locations found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ sortList, headerFields }) => (
	<div className={`at-table-row transaction-header-row`}>
		{headerFields.map((field, i) => {
			return (
				<div
					key={i}
					className={`at-table-cell at-table-header at-header-text ${field.value}`}
					onClick={field.sortKey && (() => sortList(field.sortKey))}
				>
					<span>{field.label}</span>
					{field.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
				</div>
			);
		})}
	</div>
);

export const TableList = ({ style, title, id, city, address, tags, handleNestedEntity }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name">
				<a
					role="button"
					className="link-text"
					onClick={() => handleNestedEntity(true, NESTED_ENTITY_TYPES[2], id)}
				>
					{title || id}
				</a>
				<div className="tags">
					{tags.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 2 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 2 && (
									<span className="tag-item more-tags" title={tags.slice(2).join(", ")}>
										+{tags.length - 2} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text location">{city}</div>
			<div className="at-table-cell at-cell-text address">{address || "--"}</div>
		</animated.div>
	);
};
