import React from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import CreateIcon from "../_commons/CreateIcon";
import HelpIcon from "../_commons/HelpIcon";

export const Header = (props) => {
	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Payouts</div>
				<div className="subtitle">
					Reconcile payouts from online platforms with data from your Point of Sale and Bank
				</div>
			</div>
			<div className="header-action-button">
				<div className="action-buttons-container">
					<Link to="/payout-sheets/upload">
						<Button>
							<CreateIcon />
							<span>Upload</span>
						</Button>
					</Link>
					<Button type="secondary" clickHandler={props.handleExportReport}>
						Export Reconciliation Report
					</Button>
				</div>
				<div className="help-btn-container">
					<Button clickHandler={props.handlePiperAcademy} type="secondary">
						<HelpIcon />
						<span>Help</span>
					</Button>
				</div>
			</div>
		</div>
	);
};
